import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { Document } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';

import { useFormContext } from 'features/Form/hooks';

import workModeHook from 'features/Form/hooks/workModeHook';
import { validateTenderProtocol } from './validate';
import { TenderProtocol } from 'types/models/TenderProtocol';
import { getMockTenderProtocol } from 'features/Form/looks/tender/TenderProtocolForm/helpers';
import { ValueOf } from 'types/helpers';
import { useReportsHook, Reports } from 'features/BuildReportPopup';
import { IconButtonProps, buttonIcons } from 'components';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id: initialId },
  } = useFormContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [tenderProtocol, setTenderProtocol] = useState<TenderProtocol>(getMockTenderProtocol());

  const { methods: getTenderProtocol } = BackendAPI.useBackendAPI('GetTenderProtocol');
  const { methods: saveTenderProtocol } = BackendAPI.useBackendAPI('SaveTenderProtocol');

  const loadTenderProtocol = useCallback(
    (id: string) => {
      getTenderProtocol.callAPI(
        { id },
        {
          onSuccessfullCall: ({ data }) => {
            setTenderProtocol(data);
          },
        },
      );
    },
    [getTenderProtocol],
  );

  const updateRequests = useCallback(() => {
    getTenderProtocol.callAPI(
      { id: initialId || tenderProtocol?.id || '' },
      {
        onSuccessfullCall: ({ data }) => {
          if (!tenderProtocol.tender) return;
          setTenderProtocol(prevState => ({
            ...prevState,
            ...(prevState.tender
              ? {
                  tender: {
                    ...prevState.tender,
                    requests: data.tender?.requests || [],
                    nirRequests: data.tender?.nirRequests || [],
                    mobileRequests: data.tender?.mobileRequests || [],
                    programRequests: data.tender?.programRequests || [],
                  },
                }
              : null),
          }));
        },
      },
    );
  }, [getTenderProtocol, initialId, tenderProtocol?.id, tenderProtocol.tender]);

  const handleSave = useCallback(
    (needClose?: boolean) => {
      saveTenderProtocol.callAPI(
        {
          ...tenderProtocol,
          id: initialId || tenderProtocol?.id || '',
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Протокол успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              loadTenderProtocol(data.id);
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      saveTenderProtocol,
      tenderProtocol,
      initialId,
      tableStreams.reloadTable,
      onClose,
      loadTenderProtocol,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  const onSubmit = useCallback(
    needClose => {
      const validationInfo = validateTenderProtocol(tenderProtocol);
      if (validationInfo.some(x => !x.isValid)) {
        validationInfo.forEach(({ isValid, invalidMessage }) => {
          if (!isValid) {
            setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
          }
        });
        return;
      }
      handleSave(needClose);
    },
    [tenderProtocol, handleSave],
  );

  const handleDocumentsFieldChange = useCallback((value: Document[]) => {
    setTenderProtocol(prevState => ({ ...prevState, documents: value }));
  }, []);

  const makeChangeHandler = useCallback(
    (key: keyof TenderProtocol) => (value: ValueOf<TenderProtocol>) => {
      setTenderProtocol({ ...tenderProtocol, [key]: value });
    },
    [tenderProtocol],
  );

  const { getReports, handleSetCurrentReport, isReportOpen, onReportClose, currentReport } = useReportsHook({
    reports: [Reports.TenderProtocol],
  });

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        isHidden: !!viewMode,
        onClick: () => onSubmit(true),
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        isHidden: !!viewMode,
        onClick: () => onSubmit(false),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [viewMode, getReports, handleSetCurrentReport, onSubmit],
  );

  useLayoutEffect(() => {
    if (initialId) {
      loadTenderProtocol(initialId);
    }
    // eslint-disable-next-line
  }, []);

  return {
    workMode,
    tenderProtocol,
    setTenderProtocol,
    handleDocumentsFieldChange,
    makeChangeHandler,
    updateRequests,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
