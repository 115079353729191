import { makeStream } from 'StreamRx';
import { PublicationProject, PublicationMobileRequest } from 'types/models/Form';

export const projectsStreams = {
  setProjects: makeStream<Array<PublicationProject>>(),
  accept: makeStream<string>(),
  cancelAcceptance: makeStream<string>(),
};

export const mobileRequestsStream = {
  setMobileRequests: makeStream<Array<PublicationMobileRequest>>(),
  accept: makeStream<string>(),
  cancelAcceptance: makeStream<string>(),
};
