import React from 'react';

import { FormComponent, Reference, TextArea, TextAreaMode } from 'components';

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';

import { useController } from './controller';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  setProject(project: Project.Project | null): void;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};

export function TextFields(props: Props) {
  const { disabled, makeChangeHandler, setProject, project } = props;

  const { handleChangeSpecific } = useController({ project, setProject });
  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Характер проекта">
          <Reference
            disabled={disabled}
            name="RefProjectSpecific"
            onChange={handleChangeSpecific}
            value={project?.specification ?? null}
            relationTableModalTitle='Справочник "Характеры проектов"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Вид исследования">
          <Reference
            name="RefKindResearch"
            onChange={makeChangeHandler('kindResearch')}
            value={project?.kindResearch ?? null}
            disabled={!project?.specification?.id || disabled}
            relationTableModalTitle='Справочник "Виды исследований"'
            filters={[{ key: 'projectSpecific', values: [project?.specification?.id ?? ''] }]}
            defaultValue={
              project?.specification?.id
                ? {
                    type: 'REFERENCE',
                    referenceName: 'RefProjectSpecific',
                    id: project.specification.id,
                    fieldName: 'projectSpecific',
                    isDisabled: true,
                  }
                : undefined
            }
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Цель">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Цель' }}
            value={project?.goal ?? ''}
            onChange={makeChangeHandler('goal')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field
          label="Аннотация"
          // eslint-disable-next-line max-len
          tooltip="В краткой свободной форме опишите планируемые результаты работы, основные ожидаемые характеристики и параметры объекта исследования или разработки. Текст должен быть кратким и точным, не должен содержать сложных формул, таблиц, рисунков и аббревиатур без их расшифровки(кроме общепринятых), а также сокращенных слов (кроме общепринятых). Объем - до 21845 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Аннотация' }}
            value={project?.annotation ?? ''}
            onChange={makeChangeHandler('annotation')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field
          label="Ожидаемые результаты"
          // eslint-disable-next-line max-len
          tooltip="В краткой свободной форме дается описание нового материала, устройства, продукта и других результатов проекта, а также возможного метода использования результатов проекта. Объем - до 21845 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Ожидаемые результаты' }}
            value={project?.expectedResults ?? ''}
            onChange={makeChangeHandler('expectedResults')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field
          label="Научный задел"
          // eslint-disable-next-line max-len
          tooltip="Укажите основные ранее полученные результаты, связанные непосредственно с темой НИКОТР (за последние 3 года). А также верифицированные ссылки на публикации, реализованные НИР по теме проекта, РИДы, защищенные диссертации, доклады по тематике и другие результаты. Объем - до 21845 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Научный задел' }}
            value={project?.scientificGroundwork ?? ''}
            onChange={makeChangeHandler('scientificGroundwork')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field
          label="Обоснование междисциплинарного подхода"
          // eslint-disable-next-line max-len
          tooltip="Данное поле заполняется только при наличии указания разных тематических рубрик первого уровня (ГРНТИ и Кодов Международной Классификации). В краткой свободной форме обосновывается междисциплинарный характер работы, относящийся к разным тематическим уровням. Объем - до 21845 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Обоснование междисциплинарного подхода' }}
            value={project?.interdisciplinaryAproachJustification ?? ''}
            onChange={makeChangeHandler('interdisciplinaryAproachJustification')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Ключевые слова">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Ключевые слова' }}
            value={project?.keyWords ?? ''}
            onChange={makeChangeHandler('keyWords')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
