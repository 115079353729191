import { useCallback } from 'react';

export type YearProps = {
  min?: number;
  max?: number;
};

export const YearMask = (year: YearProps) => {
  const { min, max } = year;
  return {
    // Mask
    mask: (value: string = '') => [/[1-2]/, value[0] === '1' ? /[9]/ : /[0]/, /[0-9]/, /[0-9]/],

    // Validate
    pipe: useCallback(
      (conformedValue: any) => {
        const preparedValue = parseFloat(conformedValue.replaceAll('_', '') || 0);

        if (conformedValue.length < 4) {
          return false;
        }

        if (Number(conformedValue).toString() === conformedValue) {
          if (typeof min === 'number' && preparedValue < min) {
            return { value: String(min), indexesOfPipedChars: [] };
          }
          if (typeof max === 'number' && preparedValue > max) {
            return { value: String(max), indexesOfPipedChars: [] };
          }
        }

        return { value: conformedValue, indexesOfPipedChars: [] };
      },
      [max, min],
    ),
  };
};
