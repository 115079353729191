import { useState, useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';
import { tabsStreams } from 'components/Tabs/streams';

import { Form, Event, Document } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SaveFormData, streams as localFormStreams } from 'features/Form/streams';
import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { useStream } from 'StreamRx';
import { checkBibliographicRecord } from 'utils/Validators';
import { ParticipantList } from 'utils/Enums/ParticipantList';
import { DataStatusInfo, EventProject } from 'types/models/Event';
import { useAppDataContext } from 'features/AppData/context';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { EventFormat, EventModule, RecordStatus } from 'utils/Enums';
import { Item } from 'types/models/common';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { validate } from './validate';
import { useFormContext, useLocalFormStreams } from 'features/Form/hooks';
import { projectsStreams } from './streams';
import { formatToRequest } from './setup/formatToRequest';
import { setByResponse } from './setup/setByResponse';
import { showNotification } from 'features/Notifications';

type Props = {
  onClose: () => void;
};
type RadioItem = {
  label: string;
  value: string;
};

export function useController({ onClose }: Props) {
  const tabsId = 'EventFormExposition';

  const {
    look: { type, id, viewMode, editMode, relatedTableState, name, controllerIsForm, withHeaderId },
  } = useFormContext<Form.EventFormLook>();
  const { isProfile } = usePrivatePageContext();
  const { currentPerson, initialCountryCode, initialLanguageCode, settings, userPermission } = useAppDataContext();

  const initialParticipantValue: string = 'TSU';
  const initialParticipantList: RadioItem[] = ParticipantList();

  const initialFormatValue: Item = EventFormat.OFFLINE;
  const initialFormatList: RadioItem[] = useMemo(() => Object.values(EventFormat), []);

  const streams = useLocalFormStreams();
  const tableStreams = useLocalTableStreams();

  const suchLikeEventStreams = useLocalSuchLikeEventStreams();
  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const organizationName = settings?.organization?.shortName || '';

  const [eventId, setEventId] = useState<string | undefined>(id);
  const [eventData, setEventData] = useState<Event.Data | null>(null);
  const [needClose, setNeedClose] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Event.Department[]>([]);
  const [compilations, setCompilations] = useState<Event.Source[]>([]);
  const [sourceMagazine, setSourceMagazine] = useState<Form.Original | null>(null);
  const [magazineReleases, setMagazineReleases] = useState<Event.Source[]>([]);
  const [enterprises, setEnterprises] = useState<Event.Enterprise[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [committeMembers, setCommitteMembers] = useState<Event.Member[]>([]);
  const [participant, setParticipant] = useState<string>(initialParticipantValue);
  const [format, setFormat] = useState<Item>(initialFormatValue);
  const [memberCounts, setMemberCounts] = useState<Event.MemberCount[]>([]);
  const [projects, setProjects] = useState<EventProject[]>([]);
  const [status, setStatus] = useState<DataStatusInfo>({});
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);
  const [isTitleWarningPopup, setIsTitleWarningPopup] = useState(false);
  const [titleWarningText, setTitleWarningText] = useState<string>('');
  const [participations, setParticipations] = useState<Event.Participations[]>([]);
  const [isDocumentsWarningPopupOpen, setIsDocumentsWarningPopupOpen] = useState<boolean>(false);
  const [isAuthorsPopupOpen, setIsAuthorsPopupOpen] = useState<boolean>(false);

  const onParticipantChange = (val: string) => {
    setParticipant(val);
  };
  const onFormatChange = (val: string) => {
    setFormat(initialFormatList.find(x => x.value === val) || initialFormatValue);
  };

  const termsList = useMemo<Form.EditionTypes>(
    () => [
      { label: 'Период', id: 'PERIOD' },
      { label: 'Дата', id: 'DATA' },
    ],
    [],
  );

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название выставки',
      tooltipText:
        // eslint-disable-next-line max-len
        'Если существует название выставки на английском и русском языке, то указать здесь нужно оба этих названия через дефис, для исключения дублирования одного мероприятия на разных языках.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    eventSeries: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Серия мероприятий',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          eventSeries: { ...prevState.eventSeries, value },
        }));
      },
    },
    expositionType: {
      value: { label: '', id: '' },
      isValid: true,
      required: true,
      title: 'Тип выставки',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          expositionType: { ...prevState.expositionType, value },
        }));
      },
    },
    eventStatus: {
      value: { label: '', id: '' },
      isValid: true,
      required: true,
      title: 'Уровень мероприятия',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          eventStatus: { ...prevState.eventStatus, value },
        }));
      },
    },
    isStudent: {
      value: false,
      isValid: true,
      title: 'Студенческая',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isStudent: {
            ...prevState.isStudent,
            value: !prevState.isStudent.value,
          },
        }));
      },
    },
    orderNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Приказ о проведении №',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          orderNumber: { ...prevState.orderNumber, value },
        }));
      },
    },
    orderDate: {
      value: '',
      isValid: true,
      title: 'от',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          orderDate: { ...prevState.orderDate, value: nextDate || '' },
        }));
      },
    },
    termsType: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Сроки проведения',
      required: true,
      onChange: (nextTermTypeLabel: string) => {
        const nextValue = termsList.find(({ label }) => label === nextTermTypeLabel) || { id: '', label: '' };
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          endDate: { ...prevState.endDate, required: nextValue.id === termsList[0].id },
        }));
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          termsType: { ...prevState.termsType, value: nextValue },
        }));
      },
    },
    startDate: {
      value: ' ',
      isValid: true,
      required: true,
      title: 'Дата начала мероприятия',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          startDate: { ...prevState.startDate, value: nextDate || '' },
        }));
      },
    },
    endDate: {
      value: '',
      isValid: true,
      title: 'Дата завершения мероприятия',
      required: true,
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          endDate: { ...prevState.endDate, value: nextDate || '' },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      title: 'Описание выставки',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
    },
    keyWords: {
      value: '',
      isValid: true,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          keyWords: { ...prevState.keyWords, value },
        }));
      },
    },
    site: {
      value: '',
      isValid: true,
      required: false,
      title: 'Сайт выставки',
      tooltipText:
        // eslint-disable-next-line max-len
        'Указать ссылку на официальный сайт. Если официального сайта мероприятия не существует, то допускается указание ссылки на новость о проведении мероприятия. ',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          site: { ...prevState.site, value },
        }));
      },
    },
    country: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Страна',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          country: { ...prevState.country, value },
        }));
      },
    },
    city: {
      value: '',
      isValid: true,
      title: 'Место проведения',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          city: { ...prevState.city, value },
        }));
      },
    },
    address: {
      value: '',
      isValid: true,
      title: 'Адрес',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          address: { ...prevState.address, value },
        }));
      },
    },
    contact: {
      value: '',
      isValid: true,
      title: 'Контактная информация',
      tooltipText: 'Указать е-mail и номер телефона контактных лиц, отвечающих за организацию мероприятия',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          contact: { ...prevState.contact, value },
        }));
      },
    },
    memberCountPlan: {
      value: '',
      isValid: true,
      title: 'план',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          memberCountPlan: { ...prevState.memberCountPlan, value },
        }));
      },
    },
    memberCountFact: {
      value: '',
      isValid: true,
      title: 'ФАКТ',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          memberCountFact: { ...prevState.memberCountFact, value },
        }));
      },
    },
  });

  useEffect(() => {
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      site: { ...prevState.site, required: format.value !== EventFormat.OFFLINE.value },
    }));
  }, [format]);

  const isSelectedPeriod = useMemo<boolean>(() => formFields.termsType.value?.id === 'PERIOD', [formFields.termsType.value]);
  const isTSUSelected = useMemo<boolean>(() => participant === 'TSU' || participant === 'SubTSU', [participant]);
  const isOtherSelected = useMemo<boolean>(() => participant === 'Other' || participant === 'SubTSU', [participant]);

  const { methods: getEvent, state: getEventState } = BackendAPI.useBackendAPI('GetEvent');
  const { methods: saveEvent, state: saveEventState } = BackendAPI.useBackendAPI('SaveEvent');

  const { isLoading } = useIsLoading({
    callStates: [getEventState, saveEventState],
  });

  const calculateFactMemberCount = () => {
    let counter = 0;
    memberCounts.forEach(member => {
      counter += parseInt(member.amount, 10);
    });
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      memberCountFact: { ...prevState.memberCountFact, value: counter },
    }));
  };

  const fetchEvent = useCallback(() => {
    if (type) {
      getEvent.callAPI(
        {
          simpleFields: {
            type: type?.toUpperCase() || '',
            domain: 'EVENT',
            isStudent: false,
            memberCountPlan: 0,
            memberCountFact: 0,
          },
          attrIdFields: {},
          arrayFields: {
            Grntis: { translation: [] },
            MinistryEducations: { translation: [] },
            CriticalTechnologies: { translation: [] },
            Pnmitrs: { translation: [] },
            Pnrs: { translation: [] },
            Pnis: { translation: [] },
            Partners: { translation: [] },
          },
          documents,
          id: eventId,
          projects,
          memberCounts,
          format: EventFormat.OFFLINE,
        },
        {
          onSuccessfullCall: (result: any) => {
            const data = result.data as Event.Data;

            setByResponse({ setFormFields, data, eventId, name, initialCountryCode });
            if (data.magazineReleases.length)
              setSourceMagazine({
                id: data.magazineReleases[0]?.upperSourceId || '',
                name: data.magazineReleases[0]?.upperSourceName || '',
                status: data.magazineReleases[0]?.upperSourceStatus || '',
              });
            setMagazineReleases(data.magazineReleases);
            setStatus(data.status);
            setParticipations(data.participations);
            setDocuments(data.documents);
            setDepartments(data.departments);
            setEnterprises(data.enterprises);
            setMemberCounts(data.memberCounts);
            setCommitteMembers(data.committeMembers);
            setProjects(data.projects);
            setCompilations(data.compilations);
            setParticipant(
              initialParticipantList[
                data.departments?.length > 0 ? (data.enterprises.length > 0 ? 2 : 0) : data.enterprises.length > 0 ? 1 : 0
              ].value,
            );
            setFormat(data.format || initialFormatValue);
            ReactDOM.unstable_batchedUpdates(() => {
              setEventData(data);
            });
          },
        },
      );
    }
  }, [
    type,
    getEvent,
    documents,
    eventId,
    projects,
    memberCounts,
    name,
    initialCountryCode,
    initialParticipantList,
    initialFormatValue,
  ]);

  useStream(
    () => localFormStreams.saveForm,
    ({ onSuccess, onError, publicationMessageType, publicationMessage }: SaveFormData) => {
      const isMessage = !!publicationMessageType?.id;
      const fieldsForRequest = formatToRequest({
        projects,
        type,
        departments,
        enterprises,
        memberCounts,
        documents,
        eventId,
        formFields,
        compilations,
        committeMembers,
        format,
      });
      saveEvent.callAPI(
        {
          ...fieldsForRequest,
          feedline: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
        },
        {
          onSuccessfullCall: (result: any) => {
            const data = result.data as Event.Data;
            if (data.success) {
              setEventId(data.status.id);
              if (needClose) {
                onClose();

                tableStreams.reloadTable.push();
              } else {
                updateWorkModeAfterSaveAndContinue();
                if (workMode === 'editMode') {
                  fetchEvent();
                }
              }
              onSuccess();
            } else {
              onError();
            }

            showNotification({
              theme: data.success ? 'success' : 'danger',
              message: eventId
                ? data.success
                  ? 'Выставка успешно отредактирована!'
                  : data.message || 'Произошла ошибка при редактировании выставки!'
                : data.success
                ? 'Выставка успешно сохранена!'
                : data.message || 'Произошла ошибка при сохранении выставки!',
            });
          },
          onFailedCall: () => {},
        },
      );
    },
    [workMode, formFields, departments, enterprises, memberCounts, compilations, projects, tableStreams.reloadTable, needClose],
  );

  const startCheckDuplicate = useCallback(() => {
    const onSuccess = () => {
      if (needClose) {
        onClose();
      }
    };

    const onError = () => {
      // eslint-disable-next-line
      console.error('ERR');
    };

    const successfullCallback = ({
      publicationMessage,
      publicationMessageType,
    }: {
      publicationMessage: string;
      publicationMessageType: ReferenceItem;
    }) => {
      streams.saveForm.push({ formFields, publicationMessage, publicationMessageType, onSuccess, onError });
    };

    suchLikeEventStreams.getSuchLikeEvents.push({
      searchValue: formFields.name.value as string,
      successfullCallback,
      currentId: eventId || null,
      isDefaultSave: !needClose,
    });
  }, [formFields, eventId, needClose, onClose, streams.saveForm, suchLikeEventStreams.getSuchLikeEvents]);

  const onCloseDocumentsWarningPopup = useCallback(() => {
    setIsDocumentsWarningPopupOpen(false);
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 4, tabsId });
  }, [tabsId]);

  const onConfirmDocumentsWarningPopup = useCallback(() => {
    setIsDocumentsWarningPopupOpen(false);
    startCheckDuplicate();
  }, [startCheckDuplicate]);

  const checkDocuments = useCallback(() => {
    const isDocumentsValid = Boolean(documents.length) || status.status === RecordStatus.APPROVED;
    if (isDocumentsValid) {
      startCheckDuplicate();
    } else {
      setIsDocumentsWarningPopupOpen(true);
    }
  }, [documents.length, startCheckDuplicate, status]);

  const checkBiblio = useCallback(() => {
    const result = checkBibliographicRecord({
      textLanguageId: initialLanguageCode?.id ?? '',
      bibliographicRecord: formFields.name.value,
      cyrillicLanguageCode: initialLanguageCode?.id ?? '',
    });
    if (result.isValid) return true;
    setTitleWarningText(result.text);
    setIsTitleWarningPopup(true);
    return false;
  }, [formFields.name.value, initialLanguageCode?.id]);

  const handleFormSubmit = useCallback(
    (needCloseArg: boolean) => {
      setNeedClose(needCloseArg);
      const { isFormValid, invalidFieldKeys, nextNotification } = validate(formFields);
      if (!isFormValid) {
        if (invalidFieldKeys.length) {
          invalidFieldKeys.forEach((key: string) => {
            const preparedKey = key as keyof typeof formFields;
            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              [preparedKey]: { ...prevState[preparedKey], isValid: false },
            }));
          });
        }
        if (nextNotification.message) {
          showNotification(nextNotification);
        }
      } else {
        if (isProfile && !committeMembers.find(x => x.person?.id === currentPerson?.id)) setIsAuthorsPopupOpen(true);
        else if (checkBiblio()) checkDocuments();
      }
    },
    [formFields, isProfile, committeMembers, checkBiblio, checkDocuments, currentPerson?.id],
  );

  const onConfirmTitleWarningPopup = useCallback(() => {
    setIsTitleWarningPopup(false);
    checkDocuments();
  }, [checkDocuments]);

  const onCloseTitleWarningPopup = () => {
    setIsTitleWarningPopup(false);
  };

  const onConfirmAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
    if (checkBiblio()) checkDocuments();
  };

  const onResetAuthorsPopup = () => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 1, tabsId });
    getEvent.callAPI(
      {
        simpleFields: {
          type: type?.toUpperCase() || '',
        },
        attrIdFields: {},
        arrayFields: {},
        arm: 'pc',
        eventModules: [EventModule.COMITTE_MEMBERS],
      },
      {
        onSuccessfullCall: (result: any) => {
          const authorCommitteMember = result?.data?.committeMembers[0];
          if (authorCommitteMember) setCommitteMembers((prevState: Event.Member[]) => [...prevState, authorCommitteMember]);
          setIsAuthorsPopupOpen(false);
          if (checkBiblio()) checkDocuments();
        },
      },
    );
  };
  const onCloseAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
  };

  const closeAndContinueConfirmPopup = () => {
    setIsConfirmPopupOpen(false);
    startCheckDuplicate();
  };

  useStream(
    () => projectsStreams.accept,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy,
        accepted,
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.accept, currentPerson],
  );

  useStream(
    () => projectsStreams.cancelAcceptance,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy: null,
        accepted: '',
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.cancelAcceptance],
  );

  useEffect(() => {
    if (eventId || workMode === 'addMode') {
      fetchEvent();
    }
    // eslint-disable-next-line
  }, [eventId]);

  useEffect(() => {
    if (controllerIsForm) {
      controllerIsForm.setSourceEvent({
        id: status.id,
        name: status.name,
        fullName: eventData?.fullName || '',
        status: status.text,
        startDate: formFields.startDate.value,
        endDate: formFields.endDate.value,
        eventStatus: formFields.eventStatus.value,
        format,
        entity: {
          compilations,
          isCompilationPlanned: !!compilations,
          magazineReleases,
          isMagazineReleasePlanned: !!magazineReleases,
          magazineSource: sourceMagazine,
          participations: [],
        },
      });
    }
    // eslint-disable-next-line
  }, [compilations, magazineReleases, sourceMagazine, status, eventData]);

  return {
    isLoading,
    isDocumentsWarningPopupOpen,
    tabsId,
    formFields,
    fetchEvent,
    documents,
    setDocuments,
    handleFormSubmit,
    eventId,
    eventData,
    needClose,
    termsList,
    isSelectedPeriod,
    enterprises,
    changeEnterprises: setEnterprises as (enterprises: Event.Enterprise[] | null) => void,
    departments,
    changeDepartments: setDepartments as (departments: Event.Department[] | null) => void,
    refs: {
      participantVal: participant,
      participantDefault: initialParticipantValue,
      participantChange: onParticipantChange,
      participantList: initialParticipantList,
      formatVal: format,
      formatDefault: initialFormatValue,
      formatChange: onFormatChange,
      formatList: initialFormatList,
    },
    relatedTableState,
    isTSUSelected,
    isOtherSelected,
    committeMembers,
    changeCommitteMembers: setCommitteMembers as (committeMember: Event.Member[] | null) => void,
    memberCounts,
    changeMemberCounts: setMemberCounts as (memberCounts: Event.MemberCount[] | null) => void,
    calculateFactMemberCount,
    compilations,
    changeCompilations: (val: Event.Source[]) => setCompilations(val),
    projects,
    changeProjects: (val: EventProject[]) => setProjects(val),
    isConfirmPopupOpen,
    closeConfirmPopup: () => setIsConfirmPopupOpen(false),
    closeAndContinueConfirmPopup,
    isTitleWarningPopup,
    onConfirmTitleWarningPopup,
    onCloseTitleWarningPopup,
    titleWarningText,
    participations,
    controllerIsForm,
    workMode,
    onCloseDocumentsWarningPopup,
    onConfirmDocumentsWarningPopup,
    withHeaderId,
    isAuthorsPopupOpen,
    onCloseAuthorsPopup,
    onConfirmAuthorsPopup,
    onResetAuthorsPopup,
    organizationName,
    status,
    userPermission,
  };
}
