import React from 'react';
import { setup } from 'bem-cn';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('divider');

type Props = {
  isVertical?: boolean;
  isFlex?: boolean;
  classMix?: string | string[];
};

/**
 * Divider component (simple line as hr)
 *
 * @param isVertical - (optional) Direction (false by default)
 * @param isFlex - (optional) Flexible to full space of parent (false by default)
 * @param classMixin - (optional) Mixin class(-es) for external customization
 * @returns JSX
 */
export const Divider = React.memo(({ isVertical = false, isFlex = false, classMix }: Props) => {
  return <hr className={b({ 'is-vertical': isVertical, 'is-flex': isFlex }).mix(classMix)} />;
});
