import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  programId: string;
  handleOpenConfirm?: (confirmText: string) => void;
} & State;

export function GetProgramIndicesList({ programId, program, getProgram, handleOpenConfirm }: Props): Table.Specification<State> {
  const requestData = { programId };
  return {
    apiID: 'GetProgramIndicesList',
    requestData,
    header: {
      firstLevel: {
        title: 'Показатели результативности Программы',
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel({ handleOpenConfirm }),
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({ program, getProgram }),
  };
}
