import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { setup } from 'bem-cn';
import { useStream } from 'StreamRx';
import { tabsStreams } from './streams';

import { Content, RequiredBadge } from 'components';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('form-tab');

type Props = {
  children: React.ReactNode[] | ReactElement | ReactElement[];
  id?: string | number;
  onTabClick?: () => void;
  isStrip?: boolean;
  defaultTabIndex?: number;
  specialStyles?: React.CSSProperties;
};

/**
 * Tabs component
 *
 * @param id - (optional) Uniq id for tabs
 * @param onTabClick - (optional) Callback onclick title tab
 * @param isStrip - (optional) Visible mode for render
 * @param defaultTabIndex - (optional) Set active tab with index
 * @returns JSX
 */
export const Tabs = React.memo(
  ({ children, id, onTabClick = () => {}, isStrip = false, defaultTabIndex = 0, specialStyles }: Props) => {
    const [selectedTab, setSelectedTab] = useState(defaultTabIndex);

    const tabs = useMemo(() => (Array.isArray(children) ? children : [children]), [children]);
    const preparedTabs = useMemo(() => tabs.filter(tab => tab && (tab as ReactElement)?.props?.isVisible !== false), [tabs]);

    useStream(
      () => tabsStreams.setCurrentTab,
      ({ nextSelectedTab, tabsId = null }) => {
        if (id === tabsId && nextSelectedTab !== selectedTab) {
          setSelectedTab(nextSelectedTab);
        }
      },
      [id, selectedTab],
    );

    return (
      <div className={b()} style={specialStyles}>
        <div className={b('line', { 'is-strip': isStrip })}>
          {preparedTabs.map((item, index) => (
            <div
              key={index}
              className={b('item', { selected: index === selectedTab })}
              onClick={() => {
                setSelectedTab(index);
                onTabClick();
              }}
            >
              {(item as ReactElement)?.props?.title}
              {(item as ReactElement)?.props?.isRequired && (
                <div className={b('item', { badge: true })}>
                  <RequiredBadge />
                </div>
              )}
            </div>
          ))}
        </div>
        {isStrip ? <Content>{preparedTabs[selectedTab]}</Content> : preparedTabs[selectedTab]}
      </div>
    );
  },
);

type TabProps = {
  children: ReactElement | ReactNode | (ReactElement | ReactNode)[];
  title: string;
  isRequired?: boolean;
  isVisible?: boolean;
  isDisabled?: boolean;
};

/**
 * Tab component for Tabs (See in file)
 *
 * @param title - For key of tab
 * @param isRequired - (optional) Add badge `isRequired` (red star)
 * @param isVisible - (optional) Set tab is visible
 * @param isDisabled - (optional) Disable this tab (view mode only) (mix class)
 * @returns JSX
 */
export const Tab = React.memo(({ children, title, isDisabled }: TabProps) => (
  <div className={b('inner').mix(isDisabled ? 'disabled' : undefined)} key={title}>
    {children}
  </div>
));
