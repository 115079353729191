import { Table as T } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type RequestData = object;

export function GetQnaQuestionList(): T.Specification<object, RequestData> {
  return {
    apiID: 'GetQnaQuestionList',
    isFiltersHidden: true,
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel,
      },
      firstLevel: {
        title: 'Вопросы и ответы',
      },
    },
    requestData: {},
    isWithQna: false,
  };
}
