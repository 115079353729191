import { compareDesc, parse } from 'date-fns';

import * as R from 'ramda';

import { Program, Project, Table } from 'types/models';

import { Option } from 'components';

import { formatEducation, formatJob } from 'features/SelectPerson/helpers';

import { formatStr } from 'utils/Constants/FormatStr';

import { calcNdsSumm, getYear } from 'utils/Helpers';

import {
  EditableIndicator,
  FinancingType,
  IndicatorKey,
  GetPeriodIdByKeyArguments,
  CompareJobsByKeysArguments,
  PeriodKeysWithOriginalId,
  FormatApprovememntsArguments,
} from '../model';

import { getMockEditableIndicator } from './getMockModels';
import { ProjectScientistRole } from 'utils/Enums';

export function getIndicators(stages: Project.Stage[], type: IndicatorKey) {
  const indicators = stages.flatMap(stage => stage[type].map<EditableIndicator>(x => ({ ...x, stageNumber: stage.number })));
  return indicators;
}

export function updateStageIndicators(
  indicators: EditableIndicator[],
  stages: Project.Stage[],
  type: IndicatorKey,
  mode?: 'reset' | 'add',
): Project.Stage[] {
  const mapIndicators = indicators.reduce<Record<string, Project.Indicator[]>>((acc, indicator) => {
    const stage = stages.find(x => x.number === indicator.stageNumber);
    return stage ? { ...acc, [stage.number]: [...(acc[stage.number] ?? []), R.omit(['stage'], indicator)] } : acc;
  }, {});

  return stages.map(stage => ({
    ...stage,
    [type]: mode === 'add' ? [...stage[type], ...(mapIndicators[stage.number] ?? [])] : mapIndicators[stage.number] ?? [],
  }));
}

export function getStagesOptions(stages: Project.Stage[]) {
  return stages.map<Option>(x => ({
    label: `Этап №${x.number} (${x.startDate} - ${x.endDate})`,
    value: x.number || '',
  }));
}

export function generateIndicatorsByStages(indicators: Program.Indicator[], selectedStages: Project.Stage[]) {
  return selectedStages.flatMap(stage =>
    indicators.map<EditableIndicator>(indicator => ({
      ...getMockEditableIndicator(),
      fact: '0',
      plan: '0',
      ref: indicator.refResultItem,
      stageNumber: stage.number,
      year: String(getYear(stage.startDate)),
    })),
  );
}

export function removeDublicatesIndicators(type: IndicatorKey, indicators: EditableIndicator[], stages: Project.Stage[]) {
  return indicators.filter(
    x => !stages.find(stage => stage.number === x.stageNumber && stage[type].find(y => y.ref?.id === x.ref?.id)),
  );
}

export function getStageTitle(stage: Project.Stage) {
  return `Этап №${stage.number} ${!!stage.name ? `- ${stage.name} ` : ''} (${stage.startDate} - ${stage.endDate})`;
}

export const sortStages = R.sort<Project.Stage>((a, b) => Number(a.number) - Number(b.number));

export const computeFinancingsByYear = R.pipe(
  R.groupBy<Project.Stage>(x => String(getYear(x.startDate))),
  R.values,
  R.map(stages =>
    stages.reduce<Project.FinancingByYear>(
      (prev, stage) => ({
        ...prev,
        amountAccomplice: String(Number(prev.amountAccomplice ?? 0) + Number(stage.amountAccomplice)),
        amountCofinancing1: String(Number(prev.amountCofinancing1 ?? 0) + Number(stage.amountCofinancing1)),
        amountCofinancing2: String(Number(prev.amountCofinancing2 ?? 0) + Number(stage.amountCofinancing2)),
        amountLocal: String(Number(prev.amountLocal ?? 0) + Number(stage.amountLocal)),
        amountMain: String(Number(prev.amountMain ?? 0) + Number(stage.amountMain)),
        stages: R.sort((a, b) => a.localeCompare(b), [...(prev.stages ?? []), stage.number]),
        year: String(getYear(stage.startDate)),
      }),
      {} as Project.FinancingByYear,
    ),
  ),
);

export function formatRole(performer: Project.Performer) {
  return `${performer.jobPeriods.map(x => `${x.role?.label ?? ''} (${x.startDate} ${x.endDate})`).join(' ')}`;
}

export function formatDegree(performer: Project.Performer) {
  return performer.jobPeriods.map(periodJob => periodJob.degree?.refDegree?.label ?? '').join(' ');
}

export function formatRank(performer: Project.Performer) {
  return performer.jobPeriods.map(periodJob => periodJob.rank?.refRank?.label ?? '').join(' ');
}

export function formatAcademicRank(performer: Project.Performer) {
  return performer.jobPeriods.map(periodJob => periodJob.academicRank?.refAcademicRank?.label ?? '').join(' ');
}

export function formatPerformerJob(performer: Project.Performer) {
  return performer.jobPeriods.map(periodJob => (periodJob.job ? formatJob(periodJob.job) : '')).join(' ');
}

export function formatPerformerEducation(performer: Project.Performer) {
  return performer.jobPeriods.map(periodJob => (periodJob.education ? formatEducation(periodJob.education) : '')).join(' ');
}

export function formatContacts(performer: Project.Performer) {
  if (!performer.person?.scientist) {
    return '';
  }
  const { mobilePhone, phone, email } = performer.person.scientist;

  return `${mobilePhone}, ${phone}, ${email}`;
}

export function formatSNILS(performer: Project.Performer) {
  if (!performer.person?.scientist) {
    return '';
  }
  const { snils, inn } = performer.person.scientist;

  return `СНИЛС: ${snils} ИНН: ${inn}`;
}

export function formatJobPeriods(performer: Project.Performer) {
  return performer.jobPeriods.map(period => `${period.startDate} - ${period.endDate}`).join(' ');
}

export function formatStages(performer: Project.Performer) {
  return performer.stages.map(x => (x.stage ? getStageTitle(x.stage) : '')).join(' ');
}

export function formatPerformerOrders(performer: Project.Performer) {
  const orders = new Map();
  performer.jobPeriods.forEach(p => {
    if (p.startDateOrder) {
      orders.set(p.startDateOrder.id, p.startDateOrder);
    }
    if (p.endDateOrder) {
      orders.set(p.endDateOrder.id, p.endDateOrder);
    }
  });

  const tokens: string[] = [];
  orders.forEach(o => {
    if (!!o.type?.label && !!o.number && !!o.date) {
      tokens.push(`${o.type.label} №${o.number} от ${o.date}`);
    }
  });
  return tokens.join(', ');
}

export function formatApprovememnts({ stages, isDetailed = false }: FormatApprovememntsArguments) {
  const approvements: string[] = [];
  stages.forEach(stage => {
    stage.approvements.forEach(approvement => {
      const approvementText = `${approvement.source}${isDetailed ? ` ${approvement.approvedDate}` : ''}`;
      const isSourceAlredyIn = approvements.includes(approvementText);
      if (!isSourceAlredyIn) {
        approvements.push(approvementText);
      }
    });
  });

  return approvements.join(', ');
}

const getPeriodIdByKey = ({ jobPeriod, key }: GetPeriodIdByKeyArguments) => {
  const jobPeriodKeyId = jobPeriod[key]
    ? 'originalId' in jobPeriod[key]!
      ? (jobPeriod[key] as PeriodKeysWithOriginalId)!.originalId
      : (jobPeriod[key] as Project.JobPeriod['citizenship'])!.id
    : null;

  return jobPeriodKeyId;
};

const comparePeriodsByKeys = ({ period, otherPeriod, key }: CompareJobsByKeysArguments): boolean => {
  const isJobPeriodKey = Boolean(period[key]);
  const isOtherJobPeriodKey = Boolean(otherPeriod[key]);

  const otherJobPeriodKeyId = getPeriodIdByKey({ jobPeriod: otherPeriod, key });
  const jobPeriodKeyId = getPeriodIdByKey({ jobPeriod: period, key });

  return Boolean(
    (isJobPeriodKey && isOtherJobPeriodKey && otherJobPeriodKeyId !== jobPeriodKeyId) ||
      (isJobPeriodKey && !isOtherJobPeriodKey) ||
      (!isJobPeriodKey && isOtherJobPeriodKey),
  );
};
export function getIsHistoryChanged(performer: Project.Performer): boolean {
  const { jobPeriods } = performer;

  const isHistoryChanged = jobPeriods.some(period =>
    jobPeriods
      .filter(otherPeriod => period !== otherPeriod)
      .some(
        otherPeriod =>
          comparePeriodsByKeys({ period, otherPeriod, key: 'job' }) ||
          comparePeriodsByKeys({ period, otherPeriod, key: 'education' }) ||
          comparePeriodsByKeys({ period, otherPeriod, key: 'degree' }) ||
          comparePeriodsByKeys({ period, otherPeriod, key: 'rank' }) ||
          comparePeriodsByKeys({ period, otherPeriod, key: 'academicRank' }) ||
          comparePeriodsByKeys({ period, otherPeriod, key: 'citizenship' }),
      ),
  );

  return isHistoryChanged;
}

export function formatCreated(performer: Project.Performer) {
  if (!performer.createdBy || !performer.createdDate) {
    return '';
  }

  return `${performer.createdBy} (${performer.createdDate})`;
}

export function getStageReport(stage: Project.Stage): Project.StageReport | null {
  if (!stage.reports.length) {
    return null;
  }

  const [report] = stage.reports;

  return report;
}

export function getOptionsFromEnum(val: Table.Enum) {
  return val.values;
}

export function calcSumFinancings(stages: Project.Stage[], financings: Project.Financing[]) {
  const sums = stages.reduce<Record<FinancingType, number>>(
    (acc, stage) => ({
      COFINANCING_1: acc.COFINANCING_1 + Number(stage.amountCofinancing1),
      COFINANCING_2: acc.COFINANCING_2 + Number(stage.amountCofinancing2),
      MAIN: acc.MAIN + Number(stage.amountMain),
      LOCAL: acc.LOCAL + Number(stage.amountLocal),
      ACCOMPLICE: acc.ACCOMPLICE + Number(stage.amountAccomplice),
    }),
    {
      COFINANCING_1: 0,
      COFINANCING_2: 0,
      MAIN: 0,
      LOCAL: 0,
      ACCOMPLICE: 0,
    },
  );

  return financings.map<Project.Financing>(financing => {
    const sum = sums[financing.type?.value!];
    return { ...financing, amount: String(sum) };
  });
}

export function getPerformerByRole(role: ProjectScientistRole, performers: Project.Performer[]) {
  return performers.find(performer => performer.jobPeriods.find(x => x.role?.value === role)) ?? null;
}

export const financingTypeMapStageSum: Record<FinancingType, keyof Project.Stage> = {
  ACCOMPLICE: 'amountAccomplice',
  COFINANCING_1: 'amountCofinancing1',
  COFINANCING_2: 'amountCofinancing2',
  LOCAL: 'amountLocal',
  MAIN: 'amountMain',
};

export const getStageTotalNds = (stage: Project.Stage) => {
  const mainNds = Number(calcNdsSumm(stage.amountMain || '0', stage.mainNdsPercent || '0'));
  const sf1Nds = Number(calcNdsSumm(stage.amountCofinancing1 || '0', stage.cofinancing1NdsPercent || '0'));
  const sf2Nds = Number(calcNdsSumm(stage.amountCofinancing2 || '0', stage.cofinancing2NdsPercent || '0'));
  const localNds = Number(calcNdsSumm(stage.amountLocal || '0', stage.localNdsPercent || '0'));
  const accompliceNds = Number(calcNdsSumm(stage.amountAccomplice || '0', stage.accompliceNdsPercent || '0'));

  const ndsValue = [mainNds, sf1Nds, sf2Nds, localNds, accompliceNds].reduce<number>((accum, current) => accum + current, 0);
  return ndsValue;
};

export const getStageNdsPercent = (stage: Project.Stage): number => {
  const mainNdsPercent = Number(stage.mainNdsPercent);
  const sf1NdsPercent = Number(stage.cofinancing1NdsPercent);
  const sf2NdsPercent = Number(stage.cofinancing2NdsPercent);
  const localNdsPercent = Number(stage.localNdsPercent);
  const accompliceNdsPercent = Number(stage.accompliceNdsPercent);

  let totalAmount: number = 0;
  if (mainNdsPercent) {
    totalAmount += Number(stage.amountMain);
  }
  if (sf1NdsPercent) {
    totalAmount += Number(stage.amountCofinancing1);
  }
  if (sf2NdsPercent) {
    totalAmount += Number(stage.amountCofinancing2);
  }
  if (localNdsPercent) {
    totalAmount += Number(stage.amountLocal);
  }
  if (accompliceNdsPercent) {
    totalAmount += Number(stage.amountAccomplice);
  }
  const totalNdsValue = getStageTotalNds(stage);
  const divisor = totalAmount - totalNdsValue;
  return divisor !== 0 ? (totalNdsValue / divisor) * 100 : 0;
};

export const getFinancingByYearNdsPercent = (financingByYear: Project.FinancingByYear, stages: Project.Stage[]) => {
  const { totalNdsAmount, totalAmount } = financingByYear.stages.reduce<{
    totalAmount: number;
    totalNdsAmount: number;
  }>(
    (accum, stageNumber) => {
      const nextAccum = { ...accum };
      const financingByYearStage = stages.find(({ number }) => number === stageNumber);
      if (financingByYearStage) {
        const mainNdsPercent = Number(financingByYearStage.mainNdsPercent);
        const sf1NdsPercent = Number(financingByYearStage.cofinancing1NdsPercent);
        const sf2NdsPercent = Number(financingByYearStage.cofinancing2NdsPercent);
        const localNdsPercent = Number(financingByYearStage.localNdsPercent);
        const accompliceNdsPercent = Number(financingByYearStage.accompliceNdsPercent);

        if (mainNdsPercent) {
          nextAccum.totalAmount += Number(financingByYearStage.amountMain);
        }
        if (sf1NdsPercent) {
          nextAccum.totalAmount += Number(financingByYearStage.amountCofinancing1);
        }
        if (sf2NdsPercent) {
          nextAccum.totalAmount += Number(financingByYearStage.amountCofinancing2);
        }
        if (localNdsPercent) {
          nextAccum.totalAmount += Number(financingByYearStage.amountLocal);
        }
        if (accompliceNdsPercent) {
          nextAccum.totalAmount += Number(financingByYearStage.amountAccomplice);
        }

        nextAccum.totalNdsAmount += getStageTotalNds(financingByYearStage);

        return nextAccum;
      }

      return accum;
    },
    { totalAmount: 0, totalNdsAmount: 0 },
  );

  const divisor = totalAmount - totalNdsAmount;
  const ndsPercent = divisor !== 0 ? (totalNdsAmount / divisor) * 100 : 0;

  return ndsPercent;
};

export const getFinancingByYearNdsAmount = (financingByYear: Project.FinancingByYear, stages: Project.Stage[]) => {
  const totalNdsAmount = financingByYear.stages.reduce<number>((accum, stageNumber) => {
    const financingByYearStage = stages.find(({ number }) => number === stageNumber);

    if (financingByYearStage) {
      const nextTotalNdsAmount = accum + getStageTotalNds(financingByYearStage);
      return nextTotalNdsAmount;
    }
    return accum;
  }, 0);

  return totalNdsAmount;
};

export const getPerformerIndexByLastJobPeriodRole = (
  project: Project.Project | null,
  ...projectRoles: ProjectScientistRole[]
): number[] => {
  const projectCopy = R.clone(project);
  projectCopy?.performers?.map(performer => ({
    ...performer,
    jobPerdiods: performer.jobPeriods
      .filter(({ role }) => projectRoles.some(projectRole => projectRole === role?.value))
      .sort((a, b) => {
        const aEndDate = parse(a.endDate, formatStr, new Date());
        const bEndDate = parse(b.endDate, formatStr, new Date());

        return compareDesc(aEndDate, bEndDate);
      }),
  }));

  projectCopy?.performers?.forEach((performer, index) => {
    const nextJobPeriods = performer.jobPeriods[0] ? [performer.jobPeriods[0]] : [];
    projectCopy.performers[index].jobPeriods = nextJobPeriods;
  });

  const performerIndexes = projectCopy?.performers
    .reduce<number[]>((accumulator, current, index) => {
      const isProjectRole = projectRoles.some(
        role => Boolean(current.jobPeriods.length) && current.jobPeriods[0].role?.value === role,
      );
      return [...accumulator, ...(isProjectRole ? [index] : [])];
    }, [])
    .sort((aIndex, bIndex) => {
      const aEndDate = parse(projectCopy?.performers[aIndex].jobPeriods[0].endDate, formatStr, new Date());
      const bEndDate = parse(projectCopy?.performers[bIndex].jobPeriods[0].endDate, formatStr, new Date());

      return compareDesc(aEndDate, bEndDate);
    });

  return performerIndexes || [];
};

export function getPerformersPeriodsByRole(
  performers: Project.Performer[],
  ...roles: ProjectScientistRole[]
): Project.JobPeriod[] {
  return performers.flatMap(performer =>
    performer.jobPeriods.filter(jobPeriod => jobPeriod.role?.value && roles.includes(jobPeriod.role.value)),
  );
}

export * from './getMockModels';
export * from './getPreffiledProject';
export { usePermissions } from 'utils/Helpers/projects/usePermissions';
