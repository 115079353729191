import React, { useEffect } from 'react';

import { Tabs, Tab, ConfirmPopup, FormComponent } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { ParticipationHeader } from 'features/Form/views/ModalHeader/participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Permits } from 'utils/Permissions/Permits';
import { Component as SimilarParticipations } from '../views/SimilarParticipations';
import { EditParticipationDepartments } from '../views/EditParticipationDepartments';
import { ControlPanel } from '../views/ControlPanel';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
};

const ParticipationFormConference = ({ onClose }: Props) => {
  const {
    isLoading,
    isProfile,
    mainReporterName,
    participationData,
    formFields,
    participationId,
    relatedTableState,
    workMode,
    documents,
    mobileRequests,
    awards,
    event,
    publication,
    defaultIsReportPublished,
    isEventSelectWarningOpen,
    members,
    isDocumentsPopupOpened,
    isReportPopupOpened,
    isProjectReportPopupOpened,
    participationMessage,
    participationMessageType,
    isAdminMessagePopupOpen,
    tabsId,
    disabledEvent,
    isSimilarParticipationOpen,
    eventPublications,
    financingProjects,
    isEditDepartmentsOpen,
    isEditDepartmentsToAddedFormOpen,
    isApprovePopupOpen,
    onUpdatePublicationReportTheme,
    afterSubmitDepartmentsForm,
    afterSubmitApproveParticipation,
    handleOpenApprovePopup,
    handleCloseApprovePopup,
    setDocuments,
    setAwards,
    setEvent,
    onChangeIsReportPublished,
    setIsEventSelectWarningOpen,
    changeMembers,
    handleFormSubmit,
    goToDocumentsAction,
    goToReportAction,
    confirmDocumentPopup,
    closeReportPopup,
    goToProjectReportAction,
    closeProjectReportAction,
    setParticipationMessage,
    setParticipationMessageType,
    saveForm,
    handleCloseSimilarParticipation,
    acceptSimilarParticipation,
    openEditDepartmentsForm,
    closeEditDepartmentsForm,
    closeEditDepartmentsToAddedForm,
    afterSubmitEditDepartmentsToAddedForm,
    sourcePublicationId,
    onCloseBySourcePublication,
    isOpenBySourcePublication,
    isAuthorsPopupOpen,
    onConfirmAuthorsPopup,
    onCloseAuthorsPopup,
    onResetAuthorsPopup,
  } = useController({ onClose });

  const {
    look: { setTitle },
  } = useFormContext();

  const { PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT, PARTICIPATION_CONFERENCE_APPROVE } = Permits;

  useEffect(() => {
    if (setTitle) {
      setTitle(
        ParticipationHeader({
          workMode,
          departments: participationData?.departments || [],
          status: participationData?.status.label || '',
          participationId: participationId || '',
          participationTypeCode: ParticipationTypes.PARTICIPATION_CONFERENCE.code,
        }),
      );
    }
  }, [participationData?.departments, participationData?.status.label, participationId, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        permits={{
          approve: PARTICIPATION_CONFERENCE_APPROVE,
          edit: PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT,
        }}
        type={ParticipationTypes.PARTICIPATION_CONFERENCE}
        isDisabled={isLoading}
        addCallback={handleFormSubmit}
        isProfile={isProfile}
        workMode={workMode}
        id={participationId || ''}
        isApprovePopupOpen={isApprovePopupOpen}
        handleOpenApprovePopup={handleOpenApprovePopup}
        handleCloseApprovePopup={handleCloseApprovePopup}
        isEditDepartmentsOpen={isEditDepartmentsOpen}
        openEditDepartmentsForm={openEditDepartmentsForm}
        closeEditDepartmentsForm={closeEditDepartmentsForm}
        afterSubmitDepartmentsForm={afterSubmitDepartmentsForm}
        afterSubmitApproveParticipation={afterSubmitApproveParticipation}
      />

      <ConfirmPopup
        title="Предупреждение"
        okButtonText="Принять"
        isOpen={isEventSelectWarningOpen}
        onClose={() => setIsEventSelectWarningOpen(false)}
        text="Не выбрано мероприятие."
        onConfirm={() => setIsEventSelectWarningOpen(false)}
      />
      <EditParticipationDepartments
        id={participationId || ''}
        isOpen={isEditDepartmentsToAddedFormOpen}
        onClose={closeEditDepartmentsToAddedForm}
        afterSubmit={afterSubmitEditDepartmentsToAddedForm}
        type={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        participantsTitle="Список участников:"
        hideToolbarButtons
        hideActionButtons={false}
      />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="Описание">
            <TabsContent.AboutComponent
              onUpdatePublicationReportTheme={onUpdatePublicationReportTheme}
              mainReporterName={mainReporterName}
              isProject={Boolean(financingProjects.length)}
              workMode={workMode}
              defaultIsReportPublished={defaultIsReportPublished}
              onChangeIsReportPublished={onChangeIsReportPublished}
              formFields={formFields}
              event={event}
              publication={publication}
              setEvent={setEvent}
              relatedTableState={relatedTableState}
              members={members}
              changeMembers={changeMembers}
              disabledEvent={disabledEvent}
              eventPublications={eventPublications}
              sourcePublicationId={sourcePublicationId}
              isDisabledPublicationInfo={sourcePublicationId !== null && !!sourcePublicationId}
            />
          </Tab>
          <Tab title="Награды">
            <TabsContent.Awards workMode={workMode} members={members} awards={awards} setAwards={setAwards} />
          </Tab>
          <Tab title="Связи с проектами">
            <TabsContent.FinancialSupportComponent
              financingProjects={financingProjects}
              relatedTableState={relatedTableState}
              workMode={workMode}
              mobileRequests={mobileRequests}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.DocumentsComponent documents={documents} setDocuments={setDocuments} workMode={workMode} />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent
              participationId={participationId}
              relatedTableState={relatedTableState}
              workMode={workMode}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <ConfirmPopup
        title="Внимание"
        // eslint-disable-next-line
        text="Сведения о Типе доклада, Дате и Основном докладчике являются обязательными для доклада."
        isOpen={isReportPopupOpened}
        okButtonText="Вернуться к докладу"
        resetButtonText="Закрыть"
        onClose={closeReportPopup}
        onConfirm={goToReportAction}
        icon="info"
      />
      <ConfirmPopup
        title="Внимание"
        text={
          // eslint-disable-next-line max-len
          'На вкладке Документы прикрепите скан-копию программы мероприятия или файл с документом подтверждающим участие.</br><strong>Наличие прикрепленного документа - это обязательное условие для утверждения участия</strong>'
        }
        isOpen={isDocumentsPopupOpened}
        okButtonText="Продолжить"
        resetButtonText="Перейти на вкладку документы"
        onClose={goToDocumentsAction}
        onConfirm={confirmDocumentPopup}
        icon="info"
      />
      <ConfirmPopup
        title="Внимание"
        // eslint-disable-next-line
        text="При выбранном научном проекте. Сведения о Типе доклада, Дате и Основном докладчике являются обязательными для доклада."
        isOpen={isProjectReportPopupOpened}
        okButtonText="Вернуться к докладу"
        resetButtonText="Закрыть"
        onClose={closeProjectReportAction}
        onConfirm={goToProjectReportAction}
        icon="info"
      />

      <AdministratorMessagePopup
        title="Добавьте стандартное и/или текстовое сообщение администратору"
        isOpen={isAdminMessagePopupOpen}
        onConfirm={() => {
          saveForm(true);
        }}
        publicationMessage={participationMessage}
        updatePublicationMessage={setParticipationMessage}
        publicationMessageType={participationMessageType}
        setPublicationMessageType={setParticipationMessageType}
        onConfirmWithoutMessage={() => {
          saveForm(false);
        }}
        fieldVisibleMode="SYSTEM"
        command="RefParticipationFeedlineMessage"
      />
      <SimilarParticipations
        eventStatus={event?.status || ''}
        eventId={event?.id || ''}
        workMode={workMode}
        isOpen={isSimilarParticipationOpen}
        acceptSimilarParticipation={acceptSimilarParticipation}
        onClose={handleCloseSimilarParticipation}
        sourceEvent={event?.entity?.participations || []}
        eventName={event?.fullName || ''}
        type={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
      />
      <ConfirmPopup
        title="Создать запись об участии в конференции на основе метаданных публикации"
        isOpen={isOpenBySourcePublication}
        okButtonText="Закрыть"
        onClose={onCloseBySourcePublication}
        onConfirm={onCloseBySourcePublication}
        icon="info"
        size="medium"
      >
        <div className="modal-help">
          <p>
            Уточните список участников конференции, укажите основного докладчика, добавьте сведения о наградах и файл с
            приглашением или программой конференции, в которой есть информация о докладе.
          </p>
          <p>
            Если участие в конференции - в планах работ по научному проекту или при поддержке Программы академической мобильности,
            то укажите связь с проектом на вкладке Финансовая поддержка
          </p>
        </div>
      </ConfirmPopup>
      <ConfirmPopup
        title="Внимание!"
        icon="info"
        isOpen={isAuthorsPopupOpen}
        okButtonText="Нет"
        resetButtonText="Добавить"
        onConfirm={onConfirmAuthorsPopup}
        onClose={onCloseAuthorsPopup}
        onReset={onResetAuthorsPopup}
      >
        <div>
          <div>
            <strong>
              В списке «Мои участия», отображаются только те записи об участии, в которых вы указаны в Сведениях об участниках
              конференции, иначе отобразится в списке «Все участия»
            </strong>
          </div>
          <br />
          <span>Вашей персоны нет в списке участников конференции. Добавить?</span>
        </div>
      </ConfirmPopup>
    </FormComponent.Template>
  );
};

export const Component = React.memo(ParticipationFormConference);
