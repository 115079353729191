import { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { ProjectFinancingType } from 'utils/Enums';
import { Project } from 'types/models';
import { BASE_NDS } from 'utils/Constants/BaseNds';
import { calcNdsSumm, formatNumber } from 'utils/Helpers';
import { computeFinancingsByYear, financingTypeMapStageSum } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  projectId?: string;
  setStages(stages: Project.Stage[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  stages: Project.Stage[];
};

const useController = ({ projectId, setStages, setFinancingsByYear, stages: projectStages }: Props) => {
  const {
    methods: CheckActiveProjectCodesForFinancingAPI,
    state: CheckActiveProjectCodesForFinancingState,
  } = BackendAPI.useBackendAPI('CheckActiveProjectCodesForFinancing');

  const isLoading = useMemo(() => CheckActiveProjectCodesForFinancingState.kind === 'pending', [
    CheckActiveProjectCodesForFinancingState.kind,
  ]);

  const { enumMap } = useAppDataContext();

  const [codes, setCodes] = useState<string>('');
  const [isDeleteWarningPopupOpen, setIsDeleteWarningPopupOpen] = useState<boolean>(false);

  const getFinancingNdsProps = (financing: Project.Financing, stages: Project.Stage[]) => {
    let amount = 0;
    let ndsAmount = 0;
    stages.forEach(stageItem => {
      switch (financing.type?.value) {
        case ProjectFinancingType.MAIN:
          amount += Number(stageItem.amountMain);
          ndsAmount += Number(calcNdsSumm(stageItem.amountMain || '0', stageItem.mainNdsPercent || '0'));
          break;
        case ProjectFinancingType.COFINANCING_1:
          amount += Number(stageItem.amountCofinancing1);
          ndsAmount += Number(calcNdsSumm(stageItem.amountCofinancing1 || '0', stageItem.cofinancing1NdsPercent || '0'));
          break;
        case ProjectFinancingType.COFINANCING_2:
          amount += Number(stageItem.amountCofinancing2);
          ndsAmount += Number(calcNdsSumm(stageItem.amountCofinancing2 || '0', stageItem.cofinancing2NdsPercent || '0'));
          break;
        case ProjectFinancingType.LOCAL:
          amount += Number(stageItem.amountLocal);
          ndsAmount += Number(calcNdsSumm(stageItem.amountLocal || '0', stageItem.localNdsPercent || '0'));
          break;
        case ProjectFinancingType.ACCOMPLICE:
          amount += Number(stageItem.amountAccomplice);
          ndsAmount += Number(calcNdsSumm(stageItem.amountAccomplice || '0', stageItem.accompliceNdsPercent || '0'));
          break;
      }
    });

    const divisor = amount - ndsAmount;
    const ndsPercent = divisor !== 0 ? (ndsAmount / divisor) * 100 : 0;
    return {
      nds: ndsAmount.toString(),
      ndsPercent: ndsPercent.toString(),
    };
  };

  const ndsPercentFormatter = useCallback(
    (row: Project.Financing) => formatNumber(getFinancingNdsProps(row, projectStages).ndsPercent),
    [projectStages],
  );

  const ndsFormatter = useCallback((row: Project.Financing) => formatNumber(getFinancingNdsProps(row, projectStages).nds), [
    projectStages,
  ]);

  const deleteWarningText = useMemo(
    () => `Выбранный источник финансирования не может быть удален, т.к. на него уже заведен шифр <b>${codes}</b>`,
    [codes],
  );

  const onCloseDeleteWarningPopup = useCallback(() => {
    setIsDeleteWarningPopupOpen(false);
  }, []);

  const checkActiveProjectCodesForFinancing = useCallback(
    (financing: Project.Financing, continueCallback: () => void) => {
      CheckActiveProjectCodesForFinancingAPI.callAPI(
        {
          projectId: projectId ?? '-1',
          projectFinancingId: financing.id ?? '-1,',
        },
        {
          onSuccessfullCall: ({ data: { value, codes: responseCodes } }) => {
            if (value) {
              setCodes(responseCodes);
              setIsDeleteWarningPopupOpen(true);
            } else {
              continueCallback();
            }
          },
        },
      );
    },
    [CheckActiveProjectCodesForFinancingAPI, projectId],
  );

  const onPreDelete = useCallback(
    (financing: Project.Financing, continueDelete: () => void) => {
      const financingType = financing?.type?.value;
      if (!financingType) {
        continueDelete();
        return;
      }

      const foundStage = projectStages.find(stage => {
        const field = financingTypeMapStageSum[financingType];
        const sum = stage[field];
        return Number(sum) > 0;
      });
      if (foundStage) {
        // eslint-disable-next-line max-len
        showNotification({
          message: `Невозможно удалить ${
            financing.type?.label ?? ''
          }, т.к. в календарном плане имеются этапы с ненулевыми суммами по данному типу`,
          theme: 'danger',
        });
      } else if (financing.id && projectId) {
        checkActiveProjectCodesForFinancing(financing, continueDelete);
      } else {
        continueDelete();
      }
    },
    [checkActiveProjectCodesForFinancing, projectId, projectStages],
  );

  const onPreSubmit = useCallback(
    (financing: Project.Financing, submit: (financing: Project.Financing) => void) => {
      const stagesToSet = projectStages.map(stage => {
        const nextStage = R.clone(stage);
        switch (financing.type?.value) {
          case ProjectFinancingType.MAIN:
            nextStage.mainNdsPercent = financing.isNds
              ? Number(nextStage.mainNdsPercent)
                ? nextStage.mainNdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.COFINANCING_1:
            nextStage.cofinancing1NdsPercent = financing.isNds
              ? Number(nextStage.cofinancing1NdsPercent)
                ? nextStage.cofinancing1NdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.COFINANCING_2:
            nextStage.cofinancing2NdsPercent = financing.isNds
              ? Number(nextStage.cofinancing2NdsPercent)
                ? nextStage.cofinancing2NdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.LOCAL:
            nextStage.localNdsPercent = financing.isNds
              ? Number(nextStage.localNdsPercent)
                ? nextStage.localNdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.ACCOMPLICE:
            nextStage.accompliceNdsPercent = financing.isNds
              ? Number(nextStage.accompliceNdsPercent)
                ? nextStage.accompliceNdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
        }
        return nextStage;
      });

      const financingsByYearToSet = computeFinancingsByYear(stagesToSet);

      setFinancingsByYear(financingsByYearToSet);
      setStages(stagesToSet);

      submit(financing);
    },
    [projectStages, setFinancingsByYear, setStages],
  );

  return {
    isLoading,
    enumMap,
    isDeleteWarningPopupOpen,
    ndsPercentFormatter,
    ndsFormatter,
    deleteWarningText,
    onCloseDeleteWarningPopup,
    onPreDelete,
    onPreSubmit,
  };
};

export default useController;
