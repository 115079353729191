import { StateRegistrationReport } from 'types/models';

export const validate = (projectStateRegistrationReport: StateRegistrationReport) => {
  return [
    {
      isValid: Boolean(projectStateRegistrationReport.citisDate),
      invalidMessage: 'Заполните поле "Зарегистрировать отчет в ЕГИСУ до"',
    },
    { isValid: Boolean(projectStateRegistrationReport.stage?.id), invalidMessage: 'Заполните поле "Этап проекта"' },
    { isValid: Boolean(projectStateRegistrationReport.reportType?.value), invalidMessage: 'Заполните поле "Тип отчета"' },
  ];
};
