import React, { useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { block } from 'bem-cn';

import { ListEdit } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Author, Award } from 'types/models';
import { AuthorshipFields, ChangeProps } from './AuthorshipFields/AuthorshipFields';
import {
  formatAuthor,
  getMockExtendedMember,
  validate,
  MAIN_REPORTER_TOOLTIP_TEXT,
  formatAuthorAward,
  USER_ROLES,
  ALT_PARTICIPANTS_TOOLTIP_TEXT,
} from './helpers';
import { EventTypeName, eventsList } from './eventTypes';
import { ExtendedMember } from './model';
import { showNotification } from 'features/Notifications';

const b = block('about-authorship');

type Props = {
  authors: Author[];
  setAuthors(authors: Author[]): void;
  disabled?: boolean;
  isRequired?: boolean;
  eventType: EventTypeName;
  extraToolbarButtons?: ExtraToolbarButton<ExtendedMember>[];
  awards?: Award[];
};

function MembersList(props: Props) {
  const { authors, setAuthors, disabled = false, eventType, isRequired = true, extraToolbarButtons, awards } = props;
  const extendedMembers = useMemo<ExtendedMember[]>(
    () =>
      authors.map((author, authorIndex) => {
        const isAuthorSupervisor = author.role?.customFields?.code === USER_ROLES.SUPERVISOR.code;
        const authorParticipants = authors.filter(({ supervisorId }, authorInnerIndex) => {
          const isCurrentAuthor = authorInnerIndex === authorIndex;
          if (isCurrentAuthor) {
            return false;
          }

          return Boolean(supervisorId) && supervisorId === author.person?.id;
        });

        const authorParticipantIds = authorParticipants.map(authorItem => ({
          personId: authorItem.person?.id ?? '',
          roleId: authorItem?.role?.id ?? '',
        }));

        return {
          ...author,
          participantIds: isAuthorSupervisor ? authorParticipantIds : [],
        };
      }),
    [authors],
  );

  const setExtendedMembers = useCallback(
    (nextExtendedMembers: ExtendedMember[]) => {
      const preparedExtendedMembers: ExtendedMember[] = R.clone(nextExtendedMembers).reduce<ExtendedMember[]>(
        (accum, extendedMember) => [...accum, R.pickBy<ExtendedMember>((_, key) => key !== 'supervisorId')(extendedMember)],
        [],
      );

      preparedExtendedMembers.forEach(nextExtendedMember => {
        nextExtendedMember.participantIds.forEach(({ personId, roleId }) => {
          const participantIndex = preparedExtendedMembers.findIndex(extendedMember => {
            const isEqualPersonId = extendedMember.person?.id === personId;
            const isEqualRoleId = extendedMember.role?.id === roleId;

            return isEqualPersonId && isEqualRoleId;
          });

          if (participantIndex !== -1) {
            preparedExtendedMembers[participantIndex].supervisorId = nextExtendedMember.person?.id;
          }
        });
      });

      const preparedMembers: ExtendedMember[] = preparedExtendedMembers.map(extendedMember =>
        R.pickBy<ExtendedMember>((_, key) => key !== 'participantIds')(extendedMember),
      );
      setAuthors(preparedMembers);
    },
    [setAuthors],
  );

  const handleChangeField = (rowSetter: React.Dispatch<React.SetStateAction<Author | null>>, changeProps: ChangeProps[]) => {
    rowSetter(prevAuthor =>
      changeProps.reduce<Author | null>((acc, { key, value }) => ({ ...acc, [key]: value } as any), {
        ...getMockExtendedMember(),
        ...prevAuthor,
      }),
    );
  };

  const isConferenceEvent = useMemo(() => eventType === 'CONFERENCE', [eventType]);
  const columns = useMemo(
    () =>
      [{ label: 'Участник', formatValue: formatAuthor }].concat(
        awards ? [{ label: 'Награда', formatValue: value => formatAuthorAward({ author: value, awards }) }] : [],
      ),
    [awards],
  );

  const onPreSubmit = useCallback((extendedMember: ExtendedMember, submit: (extendedMember: ExtendedMember) => void) => {
    const isSupervisor = extendedMember.role?.customFields?.code === USER_ROLES.SUPERVISOR.code;
    if (!isSupervisor) {
      submit({ ...extendedMember, participantIds: [] });
    } else {
      submit(extendedMember);
    }
  }, []);

  return (
    <div className={b()}>
      <ListEdit
        maxHeight="none"
        title={eventsList[eventType].title}
        defaultRowsCount={10}
        isRequired={isRequired}
        rows={extendedMembers}
        isCanMovingRows
        withoutHead={!awards}
        extraToolbarButtons={extraToolbarButtons}
        tooltipText={isConferenceEvent ? MAIN_REPORTER_TOOLTIP_TEXT : ALT_PARTICIPANTS_TOOLTIP_TEXT}
        columns={columns}
        onChange={setExtendedMembers}
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        withMessages
        specification={{
          mode: 'customModalComponent',
          modalTitle: eventsList[eventType].modalTitle,
          onPreSubmit,
          validation: {
            checkIsValid: (member, index, list, mode) => validate(member, list, mode, index).every(x => x.isValid),
            onInvalidate: (member, mode, index, list) => {
              const info = validate(member, list, mode, index);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
          renderComponent: (extendedMember, setExtendedMember, index, mode) => (
            <>
              <AuthorshipFields
                extendedMember={extendedMember ?? getMockExtendedMember()}
                extendedMembers={extendedMembers}
                eventType={eventType}
                onChange={R.partial(handleChangeField, [setExtendedMember])}
                index={index}
                mode={mode}
              />
            </>
          ),
        }}
      />
    </div>
  );
}

export { MembersList };
