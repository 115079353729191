import { Table } from 'types/models';
import { Filters } from './filters';

export const GetParticipationForAwardPaymentRequest: Table.Specification = {
  apiID: 'GetParticipationForAwardPaymentRequest',
  header: {
    firstLevel: {
      Panel: Filters,
      title: 'Участия в выставках',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      hasSelectButton: true,
    },
  },
};
