import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { block } from 'bem-cn';

import { Button, ButtonMode, Checkbox } from 'components';

import { Props as ColumnPickerProps } from '../';
import { Column } from 'types/models/Table';

import './styles.scss';

type Props = { onClose: () => void } & ColumnPickerProps;

const b = block('popover-content');

export const PopoverContent = ({ columns: tableColumns, onClose, onHiddenColumnsChange }: Props) => {
  const [columns, setColumns] = useState<Column[]>([]);

  useEffect(() => {
    setColumns([...tableColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const areAllBoxesChecked = useMemo(() => columns.every(({ hidden }) => !hidden), [columns]);
  const handlePickAllCheckboxChange = useCallback(() => {
    const nextHiddenColumnNames = columns.map(column => ({ ...column, hidden: areAllBoxesChecked }));
    setColumns(nextHiddenColumnNames);
  }, [areAllBoxesChecked, columns]);

  const createChangeIsHiddenCallback = useCallback(
    (index: number) => (nextIsChecked: boolean) => {
      setColumns(prevColumns =>
        prevColumns.map((column, prevIndex) => (prevIndex === index ? { ...column, hidden: !nextIsChecked } : column)),
      );
    },
    [],
  );

  const onSubmit = useCallback(() => {
    const nextHiddenColumns = columns.filter(({ hidden }) => !!hidden).map(({ name }) => name);
    onHiddenColumnsChange(nextHiddenColumns);
    onClose();
  }, [columns, onClose, onHiddenColumnsChange]);

  return (
    <div className={b()}>
      <div className={b('pick-all-label')}>
        <Checkbox label="Выбрать все" checked={areAllBoxesChecked} onChange={handlePickAllCheckboxChange} />
      </div>
      <div className={b('list')}>
        {columns.map(({ name, caption, hidden }, index) => (
          <div className={b('checkbox')} key={name}>
            <Checkbox key={name} label={caption} checked={!hidden} onChange={createChangeIsHiddenCallback(index)} />
          </div>
        ))}
      </div>
      <div className={b('button')}>
        <Button mode={ButtonMode.primary} text="Применить" onClick={onSubmit} />
      </div>
    </div>
  );
};
