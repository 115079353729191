import React, { useEffect, useState } from 'react';
import './style.scss';

interface IconProps {
  name: string;
  url: string;
}

const IconRender = (props: IconProps) => {
  const { name, url } = props;

  const [sizes, setSizes] = useState<number[]>([0, 0]);

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setSizes([img.naturalWidth, img.naturalHeight]);
    };
  }, [url]);

  return (
    <div className="icon-demo">
      <img src={url} alt={name} />
      <div className="text">
        <strong>{name}</strong>
        <div>
          width:{sizes[0]}px, height:{sizes[1]}px
        </div>
      </div>
    </div>
  );
};

interface IconsListProps {
  icons: object;
}

export const IconsDemo = (props: IconsListProps) => {
  const { icons } = props;
  const iconsList = Object.entries(icons);

  return (
    <div className="gallery">
      {iconsList.map((icon, index) => {
        const [name, url] = icon;
        return <IconRender key={index} name={name} url={url} />;
      })}
    </div>
  );
};
