import { Form } from 'types/models';
import * as regexp from 'utils/Regexps';
import { isISBNValid, isISSNValid } from '.';

const isStringFieldWithValidationTypeValid = (stringField: Form.Field) => {
  const { validationType, value } = stringField;

  let isValid = true;
  switch (validationType) {
    case 'float':
      isValid = new RegExp(regexp.floatRegExp).test(value);
      break;
    case 'number':
      isValid = new RegExp(regexp.numberRegExp).test(value);
      break;
    case 'year':
      isValid = new RegExp(regexp.finalYearRegExp).test(value);
      break;
    case 'issn':
      isValid = isISSNValid(stringField);
      break;
    case 'isbn':
      isValid = isISBNValid(stringField);
      break;
    case 'doi':
      isValid = new RegExp(regexp.doiRegExp).test(stringField.value);
      break;
    default:
      break;
  }

  return isValid;
};

export { isStringFieldWithValidationTypeValid };
