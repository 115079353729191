import { useCallback, useMemo } from 'react';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { SecurityDocumentRefElement, Project } from 'types/models';

type Props = {
  disabled?: boolean;
  onChange(refs: Project.RefElement[]): void;
};

export function useController(props: Props) {
  const { disabled, onChange } = props;

  const setActual = useCallback(
    (row: SecurityDocumentRefElement | null, isActual: boolean, index: number | null, list: SecurityDocumentRefElement[]) => {
      const preparedList = list.map(item => {
        if (index !== null && item.ref?.id === list[index!].ref?.id) {
          return { ...item, isActual };
        }
        return item;
      });
      onChange(preparedList);
    },
    [onChange],
  );

  const handleClassifierChange = (refs: Project.RefElement[]) => {
    const newRefs = refs.map((ref: Project.RefElement) => ({
      ...ref,
      isProject: ref.id ? ref.isProject : false,
    }));
    onChange(newRefs);
  };

  const extraToolbarButtons = useMemo<ExtraToolbarButton<SecurityDocumentRefElement>[]>(
    () => [
      {
        title: 'Отметить как актуальный для охранного документа',
        icons: buttonIcons.like,
        checkIsDisabled: () => disabled === true,
        onClick: (row: SecurityDocumentRefElement | null, index, list) => {
          setActual(row, true, index, list);
        },
      },
      {
        title: 'Отметить как неактуальный для охранного документа',
        icons: buttonIcons.dislike,
        checkIsDisabled: () => disabled === true,
        onClick: (row: SecurityDocumentRefElement | null, index, list) => {
          setActual(row, false, index, list);
        },
      },
    ],
    [disabled, setActual],
  );

  return {
    extraToolbarButtons,
    handleClassifierChange,
  };
}
