import { getMockPartner } from 'features/EditPartner/helpers';
import { Table } from 'types/models';
import { Affiliation } from 'utils/Enums/Affiliation';

export const tableRowConverter = (affiliationEntry: Table.Entry) => ({
  id: null,
  createdBy: null,
  createdDate: null,
  modifiedBy: null,
  modifiedDate: null,
  count: null,
  sources: null,
  partner:
    affiliationEntry.Type === Affiliation.PARTNER
      ? {
          ...getMockPartner(),
          id: affiliationEntry.id,
          shortName: affiliationEntry.ShortName,
          fullName: affiliationEntry.FullName,
        }
      : null,
  enterprise:
    affiliationEntry.Type === Affiliation.ENTERPRISE
      ? { id: affiliationEntry.id, label: affiliationEntry.ShortName || affiliationEntry.FullName }
      : null,
  enterpriseCustomer:
    affiliationEntry.Type === Affiliation.ENTERPRISE_CUSTOMER
      ? { id: affiliationEntry.id, label: affiliationEntry.ShortName || affiliationEntry.FullName }
      : null,
});
