import React, { Dispatch, SetStateAction } from 'react';

import { CustomSelect, FormComponent, TextArea } from 'components';

import { useController } from './controller';
import { Community, Table } from 'types/models';

type Props = {
  workMode: Table.WorkMode;
  community: Community.Community | null;
  setCommunity: Dispatch<SetStateAction<Community.Community | null>>;
};

export function MagazineAboutFields({ workMode, community, setCommunity }: Props) {
  const {
    modalTableMagazineConverter,
    magazineTableConfig,
    handleMagazineFieldChange,
    handleNoteFieldChange,
    publishingLabel,
  } = useController({
    community,
    setCommunity,
  });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field>
          <CustomSelect
            title="Журнал"
            original={community?.magazine || { id: '', name: '' }}
            relationTableModalTitle={`Журналы`}
            onChange={val => handleMagazineFieldChange(val)}
            modalTableRowConverter={modalTableMagazineConverter}
            modalTableSpecification={magazineTableConfig}
            isRequired={true}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Издательство">
          <div>{publishingLabel}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            settings={{ rows: 3 }}
            value={community?.note}
            onChange={handleNoteFieldChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
