import React from 'react';

import { buttonIcons, IconButtonProps, Toolbar as SharedToolbar } from 'components';

import { Permits } from 'utils/Permissions';
import { Table } from 'types/models';

export type Props = {
  handleSave: () => void;
  handleSaveAsDraft: () => void;
  handleSaveAndContinue: () => void;
  workMode: Table.WorkMode;
  isOnlyEditing?: boolean;
  handleOpenPublicationOriginalTranslatesRelations: () => void;
  isDisabled: boolean;
};

function Toolbar({
  handleSave,
  handleSaveAsDraft,
  handleSaveAndContinue,
  workMode,
  isOnlyEditing,
  handleOpenPublicationOriginalTranslatesRelations,
  isDisabled,
}: Props) {
  const viewMode = isOnlyEditing ? false : workMode === 'viewMode';

  const buttons: IconButtonProps[] = [
    {
      icons: buttonIcons.save,
      title: 'Сохранить',
      isDisabled: !!viewMode || isDisabled,
      onClick: handleSave,
    },
    {
      icons: buttonIcons.saveDraft,
      title: 'Сохранить как черновик',
      isDisabled: !!viewMode || isDisabled,
      isHidden: isOnlyEditing,
      onClick: handleSaveAsDraft,
    },
    {
      icons: buttonIcons.saveAndContinue,
      title: 'Сохранить и продолжить',
      isDisabled: !!viewMode || isDisabled,
      isHidden: isOnlyEditing,
      onClick: handleSaveAndContinue,
    },
    {
      icons: buttonIcons.articleRelations,
      title: 'Указать статью-оригинал/переводную статью',
      isHidden: isOnlyEditing || workMode === 'addMode',
      isDisabled: !!viewMode || isDisabled,
      onClick: handleOpenPublicationOriginalTranslatesRelations,
      permissionName: Permits.PUBLICATION_ARTICLE_TRANSLATION_EDIT,
    },
  ];

  return <SharedToolbar buttons={buttons} mode="form" />;
}

export const ControlPanel = React.memo(Toolbar);
