import { useMemo, useState, useCallback } from 'react';

import { Program, Table as T } from 'types/models';
import { IconButtonProps, buttonIcons } from 'components';
import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';

import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: T.State;
  handleOpenConfirm?: (confirmText: string) => void;
} & State;

export const useController = ({ tableState, program, getProgram, handleOpenConfirm }: Props) => {
  const selectedRowsLength = tableState.selectedRows.length;
  const tableStreams = useLocalTableStreams();

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  const { methods: getReferenceElementsAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const { methods: saveProgramAPI } = BackendAPI.useBackendAPI('SaveProgram');

  const handleSubmitAddForm = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      if (program && row) {
        getReferenceElementsAPI.callAPI(
          { childIds: [row.id], filters: [], referenceName: 'RefDomainKnowledge' },
          {
            onSuccessfullCall: ({ data }: any) => {
              const refItem = data[0];
              const newDomainKnowledge: Program.DomainKnowledge = {
                id: refItem.id,
                label: refItem.label,
              };
              const newDomainKnowledges = program.domainKnowledges;
              program.domainKnowledges.push(newDomainKnowledge);
              const newProgram = { ...program, domainKnowledges: newDomainKnowledges };
              saveProgramAPI.callAPI(newProgram, {
                onSuccessfullCall: () => {
                  showNotification({ message: 'Область знаний успешно добавлена', theme: 'success' });
                  getProgram.callAPI({ id: program.id });
                  tableStreams.reloadTable.push();
                  setIsAddFormOpen(false);
                },
              });
            },
          },
        );
      }
    },
    [getProgram, getReferenceElementsAPI, program, saveProgramAPI, tableStreams.reloadTable],
  );

  const handleAddButtonClick = useCallback(() => {
    if (handleOpenConfirm && !program) {
      handleOpenConfirm('Для добавления области знаний программы необходимо сохранить данные');
    } else {
      setIsAddFormOpen(true);
    }
  }, [handleOpenConfirm, program]);

  const handleCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    if (program && tableState.selectedRows[0]) {
      const selectedDomainKnowledgeId = tableState.selectedRows[0].id;
      const newDomainKnowledges = program.domainKnowledges.filter(record => record.id !== selectedDomainKnowledgeId);
      const newProgram = { ...program, domainKnowledges: newDomainKnowledges };
      saveProgramAPI.callAPI(newProgram, {
        onSuccessfullCall: () => {
          showNotification({ message: 'Область знаний успешно удалена', theme: 'success' });
          getProgram.callAPI({ id: program.id });
          tableStreams.reloadTable.push();
        },
      });
    }
  }, [getProgram, program, saveProgramAPI, tableState.selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'delete',
        isDisabled: !(selectedRowsLength === 1),
        onClick: handleDeleteButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
    ],
    [handleAddButtonClick, selectedRowsLength, handleDeleteButtonClick],
  );

  return {
    buttons,
    handleCloseAddForm,
    isAddFormOpen,
    handleSubmitAddForm,
  };
};
