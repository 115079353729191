import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SecurityDocument, Document, SecurityDocumentRefElement, SecurityDocumentTypes } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';

import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { getOptionsFromEnum } from 'features/Form/looks/project/ProjectForm/helpers';
import { SecurityDocumentFormLook } from 'types/models/Form/look/securityDocument';
import { usePatentFormController } from 'features/Form/looks/securityDocumentPatent/PatentForm/patentFormController';
import { Department, RefElement } from 'types/models/Project';
import { getMockSecurityDocument } from './helpers/getMockModels';
import { validate } from './validate';
import { ReferenceItem } from 'components';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, documentType },
  } = useFormContext<SecurityDocumentFormLook>();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = useState(false);

  const [patent, setPatent] = useState<SecurityDocument | null | undefined>(getMockSecurityDocument(documentType || ''));
  const [documents, setDocuments] = useState<Document[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [mpks, setMpks] = useState<ReferenceItem[]>([]);

  const { patentFormFields, setPatentFormFields } = usePatentFormController({ patent, setPatent });
  const { methods: getPatent } = BackendAPI.useBackendAPI('GetSecurityDocument', {
    onSuccessfullCall: ({ data }) => {
      setPatent(data);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setPatentFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('requestNumber'), data.requestNumber),
          R.set(getValueLens('requestDate'), data.requestDate),
          R.set(getValueLens('requestSendDate'), data.requestSendDate),
          R.set(getValueLens('documentDate'), data.documentDate),
          R.set(getValueLens('documentReceiveDate'), data.documentReceiveDate),
          R.set(getValueLens('isRus'), data.isRus),
          R.set(getValueLens('isWorldwide'), data.isWorldwide),
          R.set(getValueLens('requestStatus'), data.requestStatus),
          R.set(getValueLens('project'), data.project),
          R.set(getValueLens('balanceOrderNumber'), data.balanceOrderNumber),
          R.set(getValueLens('loDate'), data.loDate),
          R.set(getValueLens('hiDate'), data.hiDate),
          R.set(getValueLens('balancePrice'), data.balancePrice),
          R.set(getValueLens('intellectualPropertyKind'), data.intellectualPropertyKind),
          R.set(getValueLens('intellectualPropertyName'), data.intellectualPropertyName),
          R.set(getValueLens('intellectualPropertyKindNonRegistered'), data.intellectualPropertyKindNonRegistered),
          R.set(getValueLens('referat'), data.referat),
          R.set(getValueLens('documentNumber'), data.documentNumber),
          R.set(getValueLens('internalNumber'), data.internalNumber),
          R.set(getValueLens('documentStartDate'), data.documentStartDate),
          R.set(getValueLens('documentEndDate'), data.documentEndDate),
          R.set(getValueLens('stateNumber'), data.stateNumber),
          R.set(getValueLens('stateRegistrationDate'), data.stateRegistrationDate),
          R.set(getValueLens('type'), data.type),
        )(prevFields),
      );
      setDocuments(data.documents);
      setDepartments(data.departments);
      setMpks(data.mpks ?? []);
      if (patent?.type?.value === SecurityDocumentTypes.NON_REGISTERED) {
        const getDisabledLens = (fieldName: string) => R.lensPath([fieldName, 'disabled']);
        setPatentFormFields(prevFields => (R.pipe as any)(R.set(getDisabledLens('documentDate'), false))(prevFields));
      }
    },
  });

  useLayoutEffect(() => {
    if (patent?.type?.value === SecurityDocumentTypes.NON_REGISTERED) {
      const getDisabledLens = (fieldName: string) => R.lensPath([fieldName, 'disabled']);
      setPatentFormFields(prevFields => (R.pipe as any)(R.set(getDisabledLens('documentDate'), false))(prevFields));
    }
  }, [patent, setPatentFormFields]);

  const { methods: savePatent } = BackendAPI.useBackendAPI('SaveSecurityDocument');

  const onSubmit = useCallback(
    needClose => {
      const documentSaveMsgs: Record<string, string> = {
        PATENT: 'Патент успешно сохранен',
        EVIDENCE: 'Свидетельство успешно сохранено',
        KNOW_HOW: 'Ноу-хау успешно сохранено',
        NON_REGISTERED: 'Объект авторского права успешно сохранен',
      };
      const errors = validate(patent, patentFormFields);
      if (errors && errors.length > 0) {
        showNotification({ message: `Заполните пол${errors.length > 1 ? 'я' : 'е'} ${errors.join(', ')}`, theme: 'danger' });
        return false;
      }

      if (Date.parse(patentFormFields.hiDate.value) < Date.parse(patentFormFields.loDate.value)) {
        showNotification({ message: 'Дата окончания должна быть позже даты начала', theme: 'danger' });
        return false;
      }
      if (patent?.type?.value === 'NON_REGISTERED') {
        if (
          parse(patentFormFields.documentStartDate.value || '', formatStr, new Date()).getTime() <
          parse(patentFormFields.loDate.value || '', formatStr, new Date()).getTime()
        ) {
          showNotification({
            message: 'Дата предоставления в распоряжение организации должна быть больше или равна дате начала',
            theme: 'danger',
          });
          return false;
        }
      }
      if (
        parse(patentFormFields.documentReceiveDate.value || '', formatStr, new Date()).getTime() <
        parse(patentFormFields.documentStartDate.value || '', formatStr, new Date()).getTime()
      ) {
        showNotification({
          message: 'Дата получения охранного документа не должна быть меньше Даты публикации документа',
          theme: 'danger',
        });
        return false;
      }
      savePatent.callAPI(
        {
          hiDate: patentFormFields.hiDate.value,
          loDate: patentFormFields.loDate.value,
          balanceOrderNumber: patentFormFields.balanceOrderNumber.value,
          balancePrice: patentFormFields.balancePrice.value,
          documentNumber: patentFormFields.documentNumber.value,
          internalNumber: patentFormFields.internalNumber.value,
          documentStartDate: patentFormFields.documentStartDate.value,
          documentEndDate: patentFormFields.documentEndDate.value,
          isRus: patentFormFields.isRus.value,
          isWorldwide: patentFormFields.isWorldwide.value,
          contracts: patent?.contracts || [],
          domainKnowledges: patent?.domainKnowledges || [],
          grntis: patent?.grntis || [],
          intellectualPropertyKind: patentFormFields.intellectualPropertyKind.value,
          intellectualPropertyName: patentFormFields.intellectualPropertyName.value,
          intellectualPropertyKindNonRegistered: patentFormFields.intellectualPropertyKindNonRegistered.value,
          referat: patentFormFields.referat.value,
          lksetss: patent?.lksetss || [],
          members: patent?.members || [],
          countries: patent?.countries || [],
          ntiEteTechnologies: patent?.ntiEteTechnologies || [],
          ntiMarkets: patent?.ntiMarkets || [],
          ntrStrategies: patent?.ntrStrategies || [],
          oecds: patent?.oecds || [],
          okveds: patent?.okveds || [],
          payments: patent?.payments || [],
          pnis: patent?.pnis || [],
          pnmitrs: patent?.pnmitrs || [],
          pnrs: patent?.pnrs || [],
          mpks,
          priorityTechnologies: patent?.priorityTechnologies || [],
          project: patentFormFields.project.value || patent?.project || null,
          requestDate: patentFormFields.requestDate.value,
          requestSendDate: patentFormFields.requestSendDate.value,
          documentDate: patentFormFields.documentDate.value,
          documentReceiveDate: patentFormFields.documentReceiveDate.value,
          requestNumber: patentFormFields.requestNumber.value,
          stateRegistrationDate: patentFormFields.stateRegistrationDate.value,
          stateNumber: patentFormFields.stateNumber.value,
          scienceBrunches: patent?.scienceBrunches || [],
          scienceDomainInterrests: patent?.scienceDomainInterrests || [],
          technologyPlatforms: patent?.technologyPlatforms || [],
          type: documentType ? { value: documentType || '', label: '' } : patent?.type || null,
          udks: patent?.udks || [],
          id: workMode === 'editMode' ? patent?.id || null : null,
          requestStatus: patentFormFields.requestStatus.value,
          documents,
          departments,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: documentSaveMsgs[patent?.type?.value ?? 'PATENT'], theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              getPatent.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      patent,
      getPatent,
      savePatent,
      tableStreams,
      patentFormFields,
      documentType,
      workMode,
      updateWorkModeAfterSaveAndContinue,
      documents,
      departments,
      mpks,
      onClose,
    ],
  );

  const makeChangeHandler = (key: keyof SecurityDocument) => (val: ValueOf<SecurityDocument>) => {
    setPatent((prev: SecurityDocument | null | undefined) => ({ ...prev!, [key]: val }));
  };

  const handleClassifierChange = (key: keyof SecurityDocument) => (val: ValueOf<SecurityDocument>) => {
    setPatent((prev: SecurityDocument | null | undefined) => {
      const prevElements = prev && prev[key] ? (prev[key] as SecurityDocumentRefElement[]) : [];
      const elements = val as RefElement[];
      if (prevElements.length > elements.length) {
        let deleted: SecurityDocumentRefElement | null = null;
        prevElements.forEach(el => {
          const hash = JSON.stringify(el);
          let found = false;
          elements.forEach(newEl => {
            const newHash = JSON.stringify(newEl);
            if (hash === newHash) {
              found = true;
            }
          });
          if (!found) {
            deleted = el;
            found = false;
          }
        });
        if (deleted && (deleted as SecurityDocumentRefElement).isProject) {
          showNotification({ message: 'Удаление возможно только элементов, отсутствующих в проекте', theme: 'danger' });
          return prev;
        }
      }

      return { ...prev!, [key]: val };
    });
  };

  const { enumMap } = useAppDataContext();
  const initialRequestStatus = useMemo(
    () => getOptionsFromEnum(enumMap.SecurityDocumentRequestStatus).filter(o => o.value === 'REGISTERED')[0],
    [enumMap],
  );
  useLayoutEffect(() => {
    if (id) {
      getPatent.callAPI({ id });
    } else {
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setPatentFormFields(prevFields => (R.pipe as any)(R.set(getValueLens('requestStatus'), initialRequestStatus))(prevFields));
      setPatentFormFields(prevFields => (R.pipe as any)(R.set(getValueLens('type'), { value: documentType }))(prevFields));
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    needClose => {
      onSubmit(needClose);
    },
    [onSubmit],
  );
  return {
    patentId: id ?? null,
    patent,
    workMode,
    formFields: patentFormFields,
    handleFormSubmit,
    setPatent,
    makeChangeHandler,
    handleClassifierChange,
    documents,
    setDocuments,
    departments,
    setDepartments,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
    mpks,
    setMpks,
  };
}
