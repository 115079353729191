import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetPaymentTermPresets: Table.Specification = {
  apiID: 'GetPaymentTermPresets',
  header: {
    firstLevel: {
      title: 'Настройки условий оплаты',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

export const GetPaymentTermPresetsSpecification = (): Table.Specification => ({
  apiID: 'GetPaymentTermPresets',
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
    },
  },
});
