import React from 'react';

import { FormComponent, ListEdit, Tabs, Tab, ReferenceItem, TextArea, TextInput } from 'components';

import { DataKind } from 'components/ListEdit/model';
import {
  GetRntdForSubjectList,
  GetSubjectFinancingList,
  GetSubjectPublicationList,
  GetSubjectConferenceList,
  GetSubjectExpositionList,
  GetSubjectSecurityDocumentContractList,
  GetForeignSecurityDocumentList,
} from 'features/Table/specifications';
import { Documents } from 'features/Documents';
import { DataGrid } from 'features/Table';
import { SecurityDocumentMemberType } from 'utils/Enums';
import { useController } from './controller';
import { getIsCurrentSecurityDocument, getSecurityDocumentInfo, getSecurityDocumentMembers } from './helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { ItemListEdit } from 'features/Form/looks/programRequest/ProgramRequestForm/ItemListEdit/ItemListEdit';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  onClose: () => void;
};

function SubjectViewForm({ onClose }: Props) {
  const {
    subject,
    handleCodeFieldChange,
    handleNameFieldChange,
    handleDescriptionFieldChange,
    handleRntdsFieldChange,
    onSubmit,
    isSubject,
    subjectId,
    viewMode,
    handleDocumentsChange,
    handleForeignSecurityDocumentsChange,
    getApplicantsInfo,
    getAuthorsInfo,
  } = useController({
    onClose,
  });

  const { settings } = useAppDataContext();

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            <FormComponent.Field label="Код" isRequired>
              <TextInput value={subject.code} onChange={handleCodeFieldChange} isDisabled={viewMode} />
            </FormComponent.Field>

            <FormComponent.Field label="Название" isRequired>
              <TextArea settings={{ rows: 3 }} value={subject.name} onChange={handleNameFieldChange} isDisabled={!!viewMode} />
            </FormComponent.Field>

            <FormComponent.Field label="Описание">
              <TextArea
                settings={{ rows: 3 }}
                value={subject.description}
                onChange={handleDescriptionFieldChange}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>

            <FormComponent.Field>
              <ListEdit
                isSortEnabled
                isVisibleFilters
                rows={subject.rntds}
                onChange={handleRntdsFieldChange}
                columns={[
                  { label: 'РИД', formatValue: ref => `${ref.type.label}: ${ref.name}` },
                  {
                    label: 'Охранный документ',
                    formatValue: ref => getSecurityDocumentInfo(ref.securityDocuments),
                  },
                  {
                    label: 'Правообладатели',
                    formatValue: ref => getSecurityDocumentMembers(ref.securityDocuments, SecurityDocumentMemberType.APPLICANT),
                  },
                  {
                    label: 'Авторы',
                    formatValue: ref => getSecurityDocumentMembers(ref.securityDocuments, SecurityDocumentMemberType.AUTHOR),
                  },
                  {
                    label: 'Действует',
                    formatValue: ref => getIsCurrentSecurityDocument(ref.securityDocuments),
                    dataKind: DataKind.BOOLEAN,
                  },
                ]}
                isDeleteConfirmEnabled
                title={`Список РИД в составе ${isSubject ? 'тематики' : 'продукта'}`}
                withMessages
                maxHeight="none"
                defaultRowsCount={12}
                isToolbarDisabled={viewMode}
                specification={{
                  mode: 'relationTableModal',
                  relationTableModalTitle: 'РИД',
                  modalTableRowConverter: row => ({
                    id: row.id,
                    code: row.Code,
                    name: row.Name,
                    type: { id: row['id:RntdType'], label: row.RntdType },
                    commercializationProtocol: row.CommercializationProtocol,
                    commercializationDate: row.CommercializationDate,
                    description: '',
                    securityDocuments: row.SecurityDocuments,
                  }),
                  modalTableSpecification: GetRntdForSubjectList(),
                }}
              />
            </FormComponent.Field>
          </Tab>
          <Tab title="ОИС других правообладателей" isVisible={!isSubject}>
            <ListEdit
              rows={subject.foreignSecurityDocuments}
              onChange={handleForeignSecurityDocumentsChange}
              columns={[
                { label: 'Название ОИС', formatValue: ref => `${ref.kind?.label}: ${ref.name}` },
                {
                  label: 'Документ',
                  formatValue: ref => `${ref.type?.label} № ${ref.number} (${ref.startDate} - ${ref.endDate})`,
                },
                {
                  label: 'Правообладатели',
                  formatValue: ref => getApplicantsInfo(ref),
                },
                {
                  label: 'Авторы',
                  formatValue: ref => getAuthorsInfo(ref),
                },
              ]}
              isDeleteConfirmEnabled
              withMessages
              maxHeight="none"
              defaultRowsCount={10}
              isToolbarDisabled={viewMode}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'РИД',
                modalTableRowConverter: row => ({
                  id: row.id,
                  type: { value: row['id.Type'], label: row.Type },
                  kind: { id: row['id.Kind'], label: row.Kind },
                  name: row.Name,
                  number: row.Number,
                  startDate: row.StartDate,
                  endDate: row.EndDate,
                  applicants: row.Applicants,
                  authors: row.Authors,
                  members: [],
                }),
                modalTableSpecification: GetForeignSecurityDocumentList({
                  shortName: settings?.organization?.shortName,
                  hasSelectButton: true,
                }),
              }}
            />
          </Tab>
          <Tab title="Классификаторы">
            <FormComponent.Line>
              <ItemListEdit
                title="ГРНТИ"
                refName="RefGrnti"
                rows={subject.grntis || []}
                onChange={() => {}}
                modalTitle='Справочник "ГРНТИ"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="УДК"
                refName="RefUdk"
                rows={subject.udks || []}
                onChange={() => {}}
                modalTitle='Справочник "УДК"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="ОКВЭД"
                refName="RefOkved"
                rows={subject.okveds || []}
                onChange={() => {}}
                modalTitle='Справочник "ОКВЭД"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="ЛКСЭЦ"
                refName="RefLksetss"
                rows={subject.lksetss || []}
                onChange={() => {}}
                modalTitle='Справочник "ЛКСЭЦ"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Отрасли науки"
                refName="RefScienceBrunch"
                rows={subject.scienceBrunches || []}
                onChange={() => {}}
                modalTitle='Справочник "Отрасли науки"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Области научных интересов"
                refName="RefScienceDomainInterest"
                rows={subject.scienceDomainInterrests || []}
                onChange={() => {}}
                modalTitle='Справочник "Области научных интересов"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title={`ПНР ${settings?.organization?.shortName}`}
                refName="RefPnr"
                rows={subject.pnrs || []}
                onChange={() => {}}
                modalTitle='Справочник "ПНР"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Приоритетные направления модернизации и технического развития экономики"
                refName="RefPnr"
                rows={subject.pnmitrs || []}
                onChange={() => {}}
                modalTitle='Справочник "ПНР модернизации РФ"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Приоритетные направления науки, технологий и техники"
                refName="RefPni"
                rows={subject.pnis || []}
                onChange={() => {}}
                modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Стратегии НТР"
                refName="RefNtrStrategy"
                rows={subject.ntrStrategies || []}
                onChange={() => {}}
                modalTitle='Справочник "Стратегии НТР"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Технологические платформы"
                refName="RefTechnologyPlatform"
                rows={subject.technologyPlatforms || []}
                onChange={() => {}}
                modalTitle='Справочник "Технологические платформы"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Критические технологии"
                refName="RefPriorityTechnology"
                rows={subject.priorityTechnologies || []}
                onChange={() => {}}
                modalTitle='Справочник "Критические технологии"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <ItemListEdit
                title="Области знаний"
                refName="RefDomainKnowledge"
                rows={subject.domainKnowledges || []}
                onChange={() => {}}
                modalTitle='Справочник "Области знаний"'
                isToolbarHidden
                columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
              />
            </FormComponent.Line>
          </Tab>
          <Tab title="Популяризация">
            <Tabs>
              <Tab title="Публикации">
                <DataGrid specification={GetSubjectPublicationList({ subjectId })} />
              </Tab>
              <Tab title="Участие в конференциях">
                <DataGrid specification={GetSubjectConferenceList({ subjectId })} />
              </Tab>
              <Tab title="Участие в выставках">
                <DataGrid specification={GetSubjectExpositionList({ subjectId })} />
              </Tab>
            </Tabs>
          </Tab>
          <Tab title="Коммерциализация">
            <DataGrid specification={GetSubjectSecurityDocumentContractList({ subjectId })} />
          </Tab>
          <Tab title="Финансирование">
            <DataGrid specification={GetSubjectFinancingList({ subjectId, isSubject })} />
          </Tab>
          <Tab title="Документы">
            <Documents
              documents={subject.documents}
              setDocuments={handleDocumentsChange}
              disabled={!!viewMode}
              sourceLabelForLocalDocs={isSubject ? 'Текущая тематика' : 'Текущий продукт для коммерциализации'}
              isShowSource
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(SubjectViewForm);
