import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

export type Arguments = {
  hasToolbarButtonMode?: boolean;
  isPersonalMode?: boolean;
};

export function GetPersonalAllSecurityDocumentList({
  hasToolbarButtonMode = false,
  isPersonalMode = false,
}: Arguments): T.Specification<State> {
  return {
    apiID: 'GetPersonalAllSecurityDocumentList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: isPersonalMode ? 'Список патентов и свидетельств' : 'Объекты интеллектуальной собственности университета',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController({ hasToolbarButtonMode }),
    isWithQna: true,
  };
}
