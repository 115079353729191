import { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';

import { Project } from 'types/models';
import { getMockPerformerStage } from 'features/Form/looks/project/ProjectForm/helpers';

type Arguments = {
  isOpen: boolean;
  onClose(): void;
  stages: Project.Stage[];
  performerStages: Project.PerformerStage[];
  setPerformerStages(performersStages: Project.PerformerStage[]): void;
};

export function useController(args: Arguments) {
  const { isOpen, onClose, stages, performerStages, setPerformerStages } = args;

  const [mapSelectedStages, setMapSelectedStages] = useState<Record<string, boolean> | null>(null);
  const [mapSelectedApproved, setMapSelectedApproved] = useState<Record<string, boolean> | null>(null);

  useEffect(() => {
    if (isOpen) {
      const selectedStages = stages.reduce(
        (acc, stage) => ({ ...acc, [stage.number]: Boolean(performerStages.find(x => x.stage?.number === stage.number)) }),
        {},
      );
      const selectedApproved = stages.reduce(
        (acc, stage) => ({
          ...acc,
          [stage.number]: Boolean(performerStages.find(x => x.approved && stage.number === x.stage?.number)),
        }),
        {},
      );
      setMapSelectedStages(selectedStages);
      setMapSelectedApproved(selectedApproved);
    } else {
      setMapSelectedStages(null);
      setMapSelectedApproved(null);
    }
  }, [isOpen, performerStages, stages]);

  const setStageInPerformer = useCallback(
    (stage: Project.Stage, isChecked: boolean) => {
      const updatedMapSelectedStages = { ...mapSelectedStages, [stage.number]: isChecked };
      setMapSelectedStages(updatedMapSelectedStages);
    },
    [mapSelectedStages],
  );

  const setIsApprovedInPerformerStage = useCallback(
    (stage: Project.Stage, isChecked: boolean) => {
      const updatedMapSelectedApprove = { ...mapSelectedApproved, [stage.number]: isChecked };
      setMapSelectedApproved(updatedMapSelectedApprove);
    },
    [mapSelectedApproved],
  );

  const handleSubmit = useCallback(() => {
    const updatedPerformerStages = stages.reduce<Project.PerformerStage[]>((acc, stage) => {
      const isSelectedStage = Boolean(mapSelectedStages?.[stage.number]);
      const isApproved = Boolean(mapSelectedApproved?.[stage.number]);
      const foundPerformerStage = performerStages.find(x => x.stage?.number === stage.number);

      const isNeedDeleteStage = !isSelectedStage && foundPerformerStage;
      if (isNeedDeleteStage) {
        return R.reject(x => Boolean((x as any)?.stage?.number === stage.number), acc);
      }

      const isNeedEditStage = isSelectedStage && foundPerformerStage;
      if (isNeedEditStage) {
        return acc.map<Project.PerformerStage>(performerStage =>
          performerStage.stage?.number === stage.number ? { ...performerStage, approved: isApproved } : performerStage,
        );
      }

      const isNeedAddStage = isSelectedStage && !foundPerformerStage;
      if (isNeedAddStage) {
        const newPerformerStage: Project.PerformerStage = { ...getMockPerformerStage(), stage, approved: isApproved };
        return [...acc, newPerformerStage];
      }

      return acc;
    }, performerStages);
    setPerformerStages(updatedPerformerStages);
    onClose();
  }, [mapSelectedApproved, mapSelectedStages, performerStages, setPerformerStages, stages, onClose]);

  return {
    handleSubmit,
    setIsApprovedInPerformerStage,
    setStageInPerformer,
    mapSelectedApproved,
    mapSelectedStages,
  };
}
