import React from 'react';

import { FormComponent, Modal, Tooltip } from 'components';

import { DataGrid } from 'features/Table';
import { Specification } from 'types/models/Table';

type Props<CustomState extends {}, Deps extends {}> = {
  isOpen: boolean;
  onClose(): void;
  specification: Specification<CustomState, Deps>;
  relationTableModalTitle: string;
  helpText?: string;
  tooltipText?: string;
};

function RelationTableModal<CustomState extends {}, Deps extends {}>(props: Props<CustomState, Deps>) {
  const { isOpen, onClose, specification, relationTableModalTitle, helpText, tooltipText } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={relationTableModalTitle} size={['extralarge', 'large-height']}>
      <FormComponent.Wrapper>
        <FormComponent.Description mode="warning">
          {tooltipText && <Tooltip text={tooltipText} />}
          {helpText}
        </FormComponent.Description>
        <DataGrid<CustomState, Deps> specification={specification} />
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { RelationTableModal };
