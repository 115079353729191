import { Specification } from 'types/models/Table';
import { ExpositionTypes } from 'utils/Enums/ExpositionTypes';

type Props = {
  expositionType: ExpositionTypes;
  year: number;
};
export function GetDashboardChartExpositionList({ expositionType, year }: Props): Specification {
  return {
    apiID: 'GetDashboardChartExpositionList',
    workMode: 'viewMode',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData: {
      expositionType,
      year: String(year),
    },
  };
}
