import React from 'react';
import { Modal } from 'components';
import { Project } from 'types/models/Project';
import { Component } from './component';

type Props = {
  onClose: () => void;
  projectId: string;
  isOpen: boolean;
  submitCallback: (preparedProject: Project) => void;
};

export const Container = ({ projectId, isOpen, submitCallback, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Копирование проекта" size="medium">
    <Component onClose={onClose} projectId={projectId} submitCallback={submitCallback} />
  </Modal>
);
