import React, { memo, useEffect } from 'react';

import { Tabs, Tab, FormComponent, ConfirmPopup } from 'components';

import { Documents } from 'features/Documents';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import OtherProjectTabs from './Tabs';
import { OtherProject } from 'utils/Enums/OtherProject';
import useController from './controller';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
};

const OtherProjectForm = ({ onClose }: Props) => {
  const {
    otherProject,
    workMode,
    isDisabled,
    isHistoryWarningOpen,
    otherProjectErrors,
    onSubmitHistoryWarning,
    onCloseHistoryWarning,
    onSave,
    makeChangeHandler,
    makeResetErrorHandler,
  } = useController({ onClose });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          const id = otherProject.id;
          const status = otherProject.status?.label;

          if (workMode === 'addMode') {
            return `Добавление ${OtherProject.OTHER_PROJECT.genitiveCaseLabel}`;
          }

          const enterprise = otherProject.enterprise;
          const enterpriseShortName =
            enterprise?.enterprise?.label ?? enterprise?.enterpriseCustomer?.label ?? enterprise?.partner?.shortName ?? '';

          const details = [enterpriseShortName ? `${enterpriseShortName};` : '', status ? `Статус: ${status}` : '']
            .filter(x => x)
            .join('; ');

          return [
            workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
            OtherProject.OTHER_PROJECT.genitiveCaseLabel || '',
            id ? `ID ${id}` : '',
            details ? `(${details})` : '',
          ]
            .filter(x => x)
            .join(' ');
        })(),
      );
    }
  }, [otherProject.enterprise, otherProject.id, otherProject.status?.label, setTitle, workMode]);

  return (
    <>
      <FormComponent.Template>
        <ControlPanel handleSave={onSave} isDisabled={isDisabled} />

        <FormComponent.Template>
          <Tabs>
            <Tab title="О проекте">
              <OtherProjectTabs.AboutProject
                isDisabled={isDisabled}
                makeResetErrorHandler={makeResetErrorHandler}
                makeChangeHandler={makeChangeHandler}
                otherProjectErrors={otherProjectErrors}
                otherProject={otherProject}
              />
            </Tab>
            <Tab title="Документы">
              <FormComponent.Template>
                <Documents
                  title="Документы"
                  disabled={isDisabled}
                  documents={otherProject.documents}
                  setDocuments={makeChangeHandler('documents')}
                />
              </FormComponent.Template>
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>
      <ConfirmPopup
        isOpen={isHistoryWarningOpen}
        onClose={onCloseHistoryWarning}
        onConfirm={onSubmitHistoryWarning}
        title="Внимание"
        icon="info"
        text="Укажите исторический срез для участника проекта"
        okButtonText="Продолжить сохранение"
        resetButtonText="Отмена"
      />
    </>
  );
};

export const Component = memo(OtherProjectForm);
