import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Color } from 'constants/colors';

type Props = {
  hasSelectButton?: boolean;
};

export const GetConferenceEventList = ({ hasSelectButton = true }: Props): Table.Specification => {
  return {
    apiID: 'GetConferenceEventList',
    header: {
      firstLevel: {
        title: 'Конференции',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: hasSelectButton,
      },
    },
    highlightEntryPredicate: entry => ({ rowTheme: !!entry.LibraryNote ? Color.danger : '' }),
    isWithQna: true,
  };
};
