import { Table } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Permits } from 'utils/Permissions';
import { isHasPermission, isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { ProjectType } from 'utils/Enums';

export function useController(tableState: Table.State) {
  const { userPermission } = useAppDataContext();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const projectType = useMemo(() => {
    const [row] = tableState.selectedRows;
    if (row) {
      return row['id:ProjectType'] as ProjectType;
    }
  }, [tableState.selectedRows]);

  const reportId = useMemo(() => {
    const [row] = tableState.selectedRows;
    if (row) {
      return row.ReportID;
    }
  }, [tableState.selectedRows]);

  const viewPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_VIEW,
      GRANT: Permits.GRANT_VIEW,
      GOVERMENT_CONTRACT: Permits.GOVERMENT_CONTRACT_VIEW,
      NIR: Permits.RESEARCH_JOB_VIEW,
    }),
    [],
  );

  const addPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: 'EconomicContractProjectStateRegistrationEdit',
      GRANT: 'GrantProjectStateRegistrationEdit',
      GOVERMENT_CONTRACT: 'GovContractProjectStateRegistrationEdit',
      NIR: 'ResearchJobProjectStateRegistrationEdit',
    }),
    [],
  );

  const editPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: 'EconomicContractProjectStateRegistrationEdit',
      GRANT: 'GrantProjectStateRegistrationEdit',
      GOVERMENT_CONTRACT: 'GovContractProjectStateRegistrationEdit',
      NIR: 'ResearchJobProjectStateRegistrationEdit',
    }),
    [],
  );

  const isViewDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED) {
      return !isHasPermission(userPermission, viewPermitsMap[projectType]);
    }
    return true;
  }, [projectType, rowsAvailability.SINGLE_SELECTED, userPermission, viewPermitsMap]);

  const isAddDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED) {
      return !isHasPermission(userPermission, addPermitsMap[projectType]);
    }
    return true;
  }, [addPermitsMap, projectType, rowsAvailability.SINGLE_SELECTED, userPermission]);

  const isEditDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED && reportId) {
      return !isHasPermission(userPermission, editPermitsMap[projectType]);
    }
    return true;
  }, [editPermitsMap, projectType, reportId, rowsAvailability.SINGLE_SELECTED, userPermission]);

  const isViewHidden = useMemo(() => !isHasSomePermission(userPermission, Object.values(viewPermitsMap)), [
    userPermission,
    viewPermitsMap,
  ]);

  const isAddHidden = useMemo(() => !isHasSomePermission(userPermission, Object.values(addPermitsMap)), [
    addPermitsMap,
    userPermission,
  ]);

  const isEditHidden = useMemo(() => !isHasSomePermission(userPermission, Object.values(editPermitsMap)), [
    editPermitsMap,
    userPermission,
  ]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isHidden: isViewHidden,
        isDisabled: isViewDisabled,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isHidden: isAddHidden,
        isDisabled: isAddDisabled,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isHidden: isEditHidden,
        isDisabled: isEditDisabled,
        onClick: handleEditButtonClick,
      },
    ],
    [
      isViewHidden,
      isViewDisabled,
      isAddHidden,
      isAddDisabled,
      isEditHidden,
      isEditDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      handleEditButtonClick,
    ],
  );

  return {
    isViewDisabled,
    isAddDisabled,
    isEditDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    buttons,
  };
}
