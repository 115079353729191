import { FormComponent, ListEdit, ReferenceItem, TextArea, TextAreaMode } from 'components';

import { Event } from 'types/models';
import { showNotification } from 'features/Notifications';
import { FinOrganizationsInputs } from './FinOrganizationsInputs/FinOrganizationsInputs';
import { useController } from './controller';

type Props = {
  eventData?: Event.Data | null;
  setEventDataField: (field: keyof Event.Data) => (value: ReferenceItem[] | string) => void;
  disabled?: boolean;
  departments: Event.Department[];
  departmentOptions: Event.Department[];
  handleAttractedDepartmentsChange: (value: Event.Department[]) => void;
  standalone?: boolean;
};

export function FinOrganizations(props: Props) {
  const {
    eventData,
    setEventDataField,
    disabled,
    departments,
    departmentOptions,
    handleAttractedDepartmentsChange,
    standalone,
  } = props;

  const { handleDepartmentsChange } = useController({ handleAttractedDepartmentsChange });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Привлечённые средства">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Привлечённые средства' }}
            value={eventData?.attractedFinancing || ''}
            onChange={setEventDataField('attractedFinancing')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field
          label="Собственные средства подразделений"
          tooltip="Выбрать из списка организаторов подразделение, оказывающее финансовую поддержку."
        >
          <ListEdit
            withMessages
            isDeleteConfirmEnabled
            rows={departments.filter(d => d.isFinancing)}
            isToolbarDisabled={disabled}
            visibleToolbarButtons={['add', 'delete']}
            onChange={value => handleDepartmentsChange(value)}
            defaultRowsCount={standalone ? 3 : 5}
            columns={[{ label: 'Наименование', formatValue: d => d.name }]}
            specification={{
              mode: 'customModalComponent',
              modalTitle: 'Список подразделений университета - организаторы мероприятия',
              isCheckSubmitIcon: true,
              validation: {
                checkIsValid: row => !!row,
                onInvalidate: () => showNotification({ message: 'Выберите подразделение', theme: 'danger' }),
              },
              renderComponent: (row, onChange, selectedRowIndex, mode, submitCustomComponent) => (
                <FinOrganizationsInputs
                  departmentOptions={departmentOptions}
                  onDepartmentChange={onChange}
                  submitCustomComponent={submitCustomComponent}
                />
              ),
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
