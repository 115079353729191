import React, { memo } from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table as T } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { RoleFormTemplate } from '../RoleFormTemplate/RoleFormTemplate';

type Props = {
  tableState: T.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { userId } = customState;
  const {
    buttons,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleCloseRoleForm,
    isRoleFormOpen,
    submitCallback,
  } = useController({
    userId,
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <RoleFormTemplate isOpen={isRoleFormOpen} onClose={handleCloseRoleForm} onSubmitTable={submitCallback} />
      {userId && (
        <ConfirmPopup
          title="Предупреждение"
          text="Вы точно хотите удалить выбранную роль у пользователя?"
          isOpen={isDeleteConfirmPopupOpen}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
        />
      )}
    </>
  );
}

export const Component = memo(LeftPanelForThirdLevel);
