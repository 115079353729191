import React from 'react';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal title="Договоры на ИС" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>В таблице отображается список договоров на собственности университета. </p>
            <p>
              Источник информации — функциональный модуль&nbsp;
              <strong>Коммерциализация</strong>: на форме договора должна быть указана организация в поле&nbsp;
              <strong>"Заключён с"</strong>
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
