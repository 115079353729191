import { convertDocumentToRequest } from 'services/BackendAPI/configurations/commonConverters';
import { Department, Nominee } from 'types/models/Payment';
import { SecurityDocumentPaymentRequestSaveInput } from './types';

export function convertSecurityDocumentPaymentRequestToXML(p: SecurityDocumentPaymentRequestSaveInput) {
  return {
    _attr: {
      ...(p.id ? { id: p.id } : {}),
      ...(p.saveComment ? { saveComment: p.saveComment } : {}),
    },
    Status: p.status?.value || 'DRAFT',
    RegulationPayment: {
      Paragraph: p.regulationParagraph,
      Name: p.regulationName,
    },
    MaxNomineesMoney: p.maxNomineesMoney,
    MaxFundMoney: p.maxFundMoney,
    Patent: { _attr: { id: p.patent?.id } },
    ...(p.nominees.length ? { Nominee: p.nominees.map(convertNomineeToXML) } : {}),
    ...(p.departments?.length
      ? {
          Department: p.departments.map(convertNomineeDepartmentToXML),
        }
      : {}),
    year: p.year,
    quarter: p.quarter?.value,
    month: p.month,
    DistributionMethodBetweenFunds: p.distributionMethodBetweenFunds,
    DistributionMethodBetweenNominees: p.distributionMethodBetweenNominees,
    Documents:
      p.documents && p.documents.length
        ? { Document: p.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)) }
        : {},
  };
}

export function convertNomineeToXML(n: Nominee) {
  return {
    _attr: { id: n?.id ?? n?.person?.id },
    Affiliated: n.isAffiliated,
    Payment: { _attr: { money: n.factMoney } },
    Department: { _attr: { id: n.topLevelDepartment?.id } },
    Job: { _attr: { id: n.job?.originalId } },
  };
}
export function convertNomineeDepartmentToXML(d: Department) {
  return {
    _attr: { id: d?.topLevelDepartment.id },
    AmountMoney: d.amountMoney,
  };
}
