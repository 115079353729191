import React from 'react';
import { FormComponent, TextGridSelect } from 'components';
import { Person } from 'types/models';
import { GetReferenceScientistList } from 'features/Table/specifications';
import useController from './controller';

type Props = {
  scientist: Person.Scientist | null;
  setScientist: (scientist: Person.Scientist | null) => void;
  label: string;
  modalTitle: string;
  isRequired: boolean;
  isDisabled?: boolean;
};
export const SelectScientist = ({ scientist, setScientist, label, modalTitle, isRequired, isDisabled }: Props) => {
  const { tableRowConverter } = useController();
  return (
    <FormComponent.Field label={label} isRequired={isRequired}>
      <TextGridSelect
        disabled={isDisabled}
        specification={GetReferenceScientistList({ hasSelectButton: true })}
        title={modalTitle}
        value={scientist}
        valueFormatter={x => x.fullName}
        onChange={setScientist}
        settings={{
          mode: 'selectTableRow',
          tableRowConverter,
        }}
      />
    </FormComponent.Field>
  );
};
