import * as T from './types';

import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { ProjectType } from 'utils/Enums';
import { makeParametersXMLElement, makeTableRequestXMLConverter } from '../commonConverters';

export const tableEconomicAgreementsConfigurations = {
  GetEconomicAgreementsList: makeBackendTableAPIConfiguration(
    `GetProjectList?type=${ProjectType.ECONOMIC_AGREEMENT}`,
    makeTableRequestXMLConverter('GetProjectList', ({ panelState, isTableExtended }: T.GetEconomicAgreementsListRequest) =>
      makeParametersXMLElement(
        [
          { name: 'type', value: ProjectType.ECONOMIC_AGREEMENT },
          { name: 'extended', value: (!!isTableExtended).toString() },
          { name: 'withoutStateRegistration', value: (!!panelState.isWithoutStateRegistration).toString() },
        ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ),
    ),
  ),
};

export const economicAgreementsConfigurations = {
  ...tableEconomicAgreementsConfigurations,
};
