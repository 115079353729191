import React, { useCallback, useEffect, useState } from 'react';

import { ConfirmPopup, Radio } from 'components';

import { Event } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  unconnectedParticipationPublications: Event.Source[];
  openParticipationFormByPublication: (val: Event.Source | null) => void;
};

const PublicationConnect = ({
  isOpen,
  onClose,
  unconnectedParticipationPublications,
  openParticipationFormByPublication,
}: Props) => {
  const { settings } = useAppDataContext();
  const [selectedPublication, setSelectedPublication] = useState<Event.Source | null>(null);

  const onChaneSelectedPublication = (id: string) => {
    setSelectedPublication(unconnectedParticipationPublications.find(pub => pub.id === id) || null);
  };

  const setPublicationFirst = useCallback(() => {
    setSelectedPublication(unconnectedParticipationPublications[0]);
  }, [unconnectedParticipationPublications, setSelectedPublication]);

  const openParticipationModal = useCallback(() => {
    openParticipationFormByPublication(selectedPublication);
    onClose();
  }, [selectedPublication, openParticipationFormByPublication, onClose]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedPublication(null);
    } else {
      setPublicationFirst();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <ConfirmPopup
      title="Создать запись об участии в конференции на основе метаданных публикации"
      isOpen={isOpen}
      okButtonText="Добавить запись об участии в конференции"
      resetButtonText="Закрыть"
      onClose={onClose}
      onConfirm={openParticipationModal}
      icon="none"
      size="medium"
    >
      <div className="modal-help">
        <p>
          <strong className="primary">Внимание:</strong> Для отчётности по научной деятельности (персональная, по подразделению,
          отчёт по НИР и ОКР, в заявках на внутренний конкурс и прочее) показатели Участия в научных мероприятиях (доклады,
          награды, уровень мероприятия и пр.) рассчитываются по данным записей &quot;Участия в конференциях&quot;
        </p>
        <p>
          <strong>
            С конференцией связаны аффилированные с {settings?.organization?.shortName} публикации без связи с докладами на
            конференции:
          </strong>
        </p>
        <Radio
          value={selectedPublication?.id}
          list={unconnectedParticipationPublications.map(({ id, bibliographicRecord, status }) => ({
            label: `${bibliographicRecord} (ID ${id} Статус записи: ${status})`,
            value: id,
          }))}
          onChange={onChaneSelectedPublication}
          isInRow={false}
        />
        <p>
          <strong>
            Отметьте публикацию в списке для создания записи об участии в конференции на основе метаданных публикации.
          </strong>
        </p>
      </div>
    </ConfirmPopup>
  );
};
export default React.memo(PublicationConnect);
