import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps, Toolbar } from 'components';

import { Event, Table } from 'types/models';
import { AwardsModal } from 'features/AwardsModal';
// eslint-disable-next-line max-len
import { TypeControllerIsForm } from 'features/Table/specifications/GetExpositionEventList/LeftPanelForThirdLevel/modalTemplates/ExpositionParticipationTemplate/models/typeControllerIsForm';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { ParticipationTypeCode } from 'types/models/Participation';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';

type Props = {
  setIsFormOpen: TypeControllerIsForm;
  participationType: ParticipationTypeCode;
  selectedRowIndex: number | null;
  isApproved: boolean;
  participations: Event.Participations[];
  workMode: Table.WorkMode;
  fetch?: () => void;
};
const ControlPanelParticipations = ({
  setIsFormOpen,
  selectedRowIndex,
  isApproved,
  participations,
  fetch,
  workMode,
  participationType,
}: Props) => {
  const { userPermission } = useAppDataContext();
  const { isProfile } = usePrivatePageContext();

  const [isFetch, setFetch] = useState(false);

  const isAddMode = useMemo<boolean>(() => workMode === 'addMode', [workMode]);
  const isViewMode = useMemo<boolean>(() => workMode === 'viewMode', [workMode]);

  const isToolbarDisabled = useMemo<boolean>(() => isAddMode || isViewMode, [isAddMode, isViewMode]);

  const isNoAwards = useMemo(() => (selectedRowIndex !== null ? participations[selectedRowIndex]?.awards?.length === 0 : true), [
    participations,
    selectedRowIndex,
  ]);

  const isEditPermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits[`${participationType}_EDIT_${isApproved ? 'APPROVED' : 'ADDED'}`]);
  }, [isApproved, participationType, userPermission]);

  const isDeletePermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits[`${participationType}_DELETE_${isApproved ? 'APPROVED' : 'ADDED'}`]);
  }, [isApproved, participationType, userPermission]);

  const closeAwards = useCallback(() => {
    setIsFormOpen?.setIsShowAwards(false);
  }, [setIsFormOpen]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsFormOpen?.setDelete(true);
  }, [setIsFormOpen]);

  useEffect(() => {
    if (fetch && !setIsFormOpen?.isEdit && !setIsFormOpen?.isAdd && !setIsFormOpen?.isDelete && isFetch) {
      fetch();
    }
    if (!isFetch) {
      setFetch(true);
    }
    // eslint-disable-next-line
  }, [setIsFormOpen?.isEdit, setIsFormOpen?.isAdd, setIsFormOpen?.isDelete]);

  const toolbar = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.medal,
        title: isNoAwards ? 'У участника нет наград' : 'Награды',
        code: 'awards',
        isDisabled: selectedRowIndex === null || isNoAwards || isToolbarDisabled,
        onClick: () => setIsFormOpen?.setIsShowAwards(true),
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: selectedRowIndex === null || isToolbarDisabled,
        permissionName: Permits[`${participationType}_VIEW`],
        onClick: () => setIsFormOpen?.setView(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'open',
        permissionName: Permits[`${participationType}_ADD`],
        profilePermissionName: Permits[`${participationType}_ADD`],
        isDisabled: isToolbarDisabled,
        onClick: () => setIsFormOpen?.setAdd(true),
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        permissionName: [Permits[`${participationType}_EDIT_ADDED`], Permits[`${participationType}_EDIT_APPROVED`]],
        profilePermissionName: [Permits[`${participationType}_EDIT_ADDED`], Permits[`${participationType}_EDIT_APPROVED`]],
        isDisabled: selectedRowIndex === null || isToolbarDisabled || !isEditPermitted,
        onClick: () => setIsFormOpen?.setEdit(true),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: [Permits[`${participationType}_DELETE_ADDED`], Permits[`${participationType}_DELETE_APPROVED`]],
        profilePermissionName: [Permits[`${participationType}_DELETE_ADDED`], Permits[`${participationType}_DELETE_APPROVED`]],
        isDisabled: selectedRowIndex === null || isToolbarDisabled || !isDeletePermitted,
        onClick: handleDeleteButtonClick,
      },
      {
        icons: buttonIcons.approve,
        title: isApproved ? 'Выбранное участие уже утверждено' : 'Утвердить',
        code: 'sourceScore',
        permissionName: Permits[`${participationType}_APPROVE`],
        profilePermissionName: Permits[`${participationType}_APPROVE`],
        isDisabled: isApproved || selectedRowIndex === null || isToolbarDisabled,
        isHidden: isProfile,
        onClick: () => {
          setIsFormOpen?.setIsApproveParticipationModal(true);
        },
      },
      {
        icons: buttonIcons.editRelations,
        title: 'Подразделения',
        code: 'editRelations',
        permissionName: Permits[`${participationType}_DEPARTMENT_EDIT`],
        profilePermissionName: Permits[`${participationType}_DEPARTMENT_EDIT`],
        isDisabled: selectedRowIndex === null || isToolbarDisabled,
        onClick: () => {
          setIsFormOpen?.setIsEditSubdivisionModal(true);
        },
      },
    ],
    [
      isNoAwards,
      selectedRowIndex,
      isToolbarDisabled,
      participationType,
      isEditPermitted,
      isDeletePermitted,
      handleDeleteButtonClick,
      isApproved,
      isProfile,
      setIsFormOpen,
    ],
  );
  return (
    <>
      <Toolbar buttons={toolbar} mode="list" />

      {selectedRowIndex !== null && !isNoAwards && (
        <AwardsModal isOpen={setIsFormOpen?.isShowAwards} onClose={closeAwards} id={participations[selectedRowIndex]?.id} />
      )}
    </>
  );
};

export default React.memo(ControlPanelParticipations);
