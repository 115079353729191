import { useCallback } from 'react';

import { MobileRequest } from 'types/models';

type Props = {
  entry: MobileRequest.TripPlan | null;
  onFieldChange(row: MobileRequest.TripPlan | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handleQuestionChange = useCallback(
    (value: string) => {
      onFieldChange({
        id: entry?.id || null,
        question: value,
        implementationForm: entry?.implementationForm || '',
      });
    },
    [entry?.id, entry?.implementationForm, onFieldChange],
  );

  const handleImplementationFormChange = useCallback(
    (value: string) => {
      onFieldChange({
        id: entry?.id || null,
        question: entry?.question || '',
        implementationForm: value,
      });
    },
    [entry?.id, entry?.question, onFieldChange],
  );

  return {
    handleQuestionChange,
    handleImplementationFormChange,
  };
}
