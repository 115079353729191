import { convertFile, convertItem, convertMaybeArray, getText } from '../commonConverters';
import { ProjectAssignment, ProjectAssignmentMessage, ProjectScientist } from 'types/models/Project';
import { getMockScientist } from 'features/Form/looks/person/helpers';

export function convertProjectAssignment(pa: any): ProjectAssignment {
  return {
    id: pa?._attributes?.id ?? null,
    status: pa.Status ? convertItem(pa.Status) : null,
    assignment: getText(pa.Assignment),
    date: getText(pa.Date),
    upToDate: getText(pa.UpToDate),
    projectId: pa.Project?._attributes?.id ?? '',
    projectScientists: convertMaybeArray(pa?.ProjectScientists.ProjectScientist || [], convertProjectScientist),
    file: convertFile(pa.File),
  };
}
export function convertProjectScientist(ps: any): ProjectScientist {
  return {
    id: ps?._attributes?.id ?? null,
    person: {
      ...getMockScientist({ initialCountryCode: null }),
      fullName: getText(ps.Fio),
      id: ps.Person?.id ? getText(ps.Person?.id) : '',
    },
  };
}
export function convertProjectAssignmentMessage(pam: any): ProjectAssignmentMessage {
  return {
    id: pam?._attributes?.id ?? null,
    message: getText(pam.Message),
    file: convertFile(pam.File),
  };
}
