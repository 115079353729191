import { Participation, Rid, Table } from 'types/models';
import { FormComponent, ListEdit, SectionTitle } from 'components';
import React from 'react';

import { ValueOf } from 'types/helpers';

import { GetExhibitionParticipationForRntd } from 'features/Table/specifications';

import { getMockParticipation } from 'features/Form/looks/rntd/RntdForm/helpers/getMockModels';
import { useController } from './controller';

import { Component as ParticipationForm } from './ParticipationForm';

type Props = {
  rid?: Rid | null;
  makeChangeHandler: (key: keyof Rid) => (val: ValueOf<Rid>) => void;
  disabled: boolean;
};

export function ExhibitionParticipation({ rid, makeChangeHandler, disabled }: Props) {
  const {
    formatEventName,
    formatOrganizer,
    formatSupervisor,
    handleParticipationChange,
    handleParticipationSave,
    isRntdExhibPartOpenForm,
    setIsRntdExhibPartOpenForm,
    participationId,
    participationFormMode,
  } = useController({
    rid,
    makeChangeHandler,
  });
  return (
    <FormComponent.Wrapper>
      <SectionTitle title="Список выставок, на которых был представлен РИД" />
      <ListEdit
        rows={rid?.participations || []}
        isToolbarDisabled={disabled}
        defaultRowsCount={15}
        maxHeight="none"
        visibleToolbarButtons={[]}
        isDeleteConfirmEnabled
        columns={[
          { label: 'Выставка', formatValue: x => formatEventName(x), styles: { width: '10%' } },
          { label: 'Организатор', formatValue: x => formatOrganizer(x) },
          { label: 'Уровень мероприятия', formatValue: x => x.event?.eventStatus?.label || '' },
          { label: 'Руководитель', formatValue: x => formatSupervisor(x) || '' },
          { label: 'Участники', formatValue: x => x.members?.map(m => m.person?.fullName).join(' ') || '' },
          { label: 'Тип экспоната', formatValue: x => x.exhibitType?.label || '' },
          { label: 'Экспонат', formatValue: x => x.development || '' },
          { label: 'Награды', formatValue: x => x.awards?.map(a => a?.name).join(' ') },
          { label: 'Коллективная экспозиция', formatValue: x => x.collectiveExhibitName || '' },
        ]}
        onChange={handleParticipationChange}
        specification={{
          mode: 'relationTableModal',
          relationTableModalTitle: 'Участия в выставках',
          modalTableRowConverter: (row: Table.Entry): Participation => getMockParticipation(row),
          modalTableSpecification: GetExhibitionParticipationForRntd,
        }}
      />
      <ParticipationForm
        isOpen={isRntdExhibPartOpenForm}
        onClose={() => setIsRntdExhibPartOpenForm(false)}
        id={participationId}
        mode={participationFormMode}
        onSave={handleParticipationSave}
      />
    </FormComponent.Wrapper>
  );
}
