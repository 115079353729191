import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Form } from './Form/Form';

export const GetProjectStateRegistrationList: Table.Specification<State> = {
  apiID: 'GetProjectStateRegistrationList',
  header: {
    firstLevel: {
      title: 'Госрегистрация, проекты',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: LeftPanelForThirdLevel,
      withTemplatesPanel: true,
    },
  },
  useCustomController: makeUseCustomController(),
  NeighborTableComponent: Form,
};
