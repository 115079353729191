import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, ConfirmPopup, Modal, Toolbar, buttonIcons, ListEditTable } from 'components';

import { Estimate, Project, Report, Table } from 'types/models';
import { DataGrid } from 'features/Table';
import { ProjectCode } from 'types/models/Project';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import * as modalTemplates from './modalTemplates';
import { useLocalTableStreams } from 'features/Table/hooks';
import { GetUnaccountedExpensesList } from 'features/Table/specifications/GetUnaccountedExpensesList';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
import * as BackendAPI from 'services/BackendAPI';
// eslint-disable-next-line max-len
import { Component as ContestRequestViewTemplate } from 'features/Table/specifications/GetContestRequestList/LeftPanelForThirdLevel/modalTemplates/ContestRequestViewTemplate';

type WrapperProps = {
  projectCode?: ProjectCode | null;
  isDisabled?: boolean;
};

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({ projectCode, isDisabled }: WrapperProps) =>
  React.memo(({ tableState }: Props) => {
    const tableStreams = useLocalTableStreams();

    const [selectedRow] = tableState.selectedRows;

    const [isViewFormOpen, setIsViewFormOpen] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isUnaccountedModalOpen, setIsUnaccountedModalOpen] = useState(false);
    const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
    const [nextPublicationName, setNextPublicationName] = useState('');
    const [nextPublicationId, setNextPublicationId] = useState('');
    const [isCreateEstimateByContestRequestModalOpen, setIsCreateEstimateByContestRequestModalOpen] = useState<boolean>(false);
    const [selectedContestRequestIndex, setSelectedContestRequestIndex] = useState<number | null>(null);
    const [isContestRequestFormOpen, setIsContestRequestFormOpen] = useState<boolean>(false);
    const [amountWarningMessage, setAmountWarningMessage] = useState<string>('');
    const [estimate, setEstimate] = useState<Estimate | null>(null);

    const { methods: createEstimateByContestRequestAPI } = BackendAPI.useBackendAPI('CreateEstimateByContestRequest');
    const { methods: getContestRequestAPI } = BackendAPI.useBackendAPI('GetContestRequest');

    const contestRequests = useMemo(() => projectCode?.project?.contestRequests || [], []);
    const selectedContestRequest = useMemo(() => {
      if (!!selectedContestRequestIndex || selectedContestRequestIndex === 0) return contestRequests[selectedContestRequestIndex];
      return null;
    }, [contestRequests, selectedContestRequestIndex]);

    const handleCloseDeleteConfirmPopup = useCallback(() => {
      setIsDeleteConfirmPopupOpen(false);
    }, []);

    const handleConfirmDeleteConfirmPopup = useCallback(() => {
      tableStreams.deleteRow.push({
        deleteRowId: selectedRow?.id,
        command: 'DeleteEntity',
        deletedItemPropName: 'Estimate',
      });
      handleCloseDeleteConfirmPopup();
    }, [handleCloseDeleteConfirmPopup, selectedRow?.id, tableStreams.deleteRow]);

    const reports = useMemo<Report[]>(
      () => [...(!projectCode ? [Reports.NirRequestTotalEstimate] : []), Reports.ProjectEstimateByCodeForViu],
      [],
    );
    const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });
    const handleDeleteButtonClick = useCallback(() => {
      setIsDeleteConfirmPopupOpen(true);
    }, []);

    const handleViewButtonClick = useCallback(() => {
      setIsViewFormOpen(true);
    }, []);
    const handleTemplateCloseViewForm = useCallback(() => {
      setIsViewFormOpen(false);
    }, []);
    const handleAddButtonClick = useCallback(() => {
      setNextPublicationName('');
      setNextPublicationId('');
      setIsAddFormOpen(true);
    }, []);
    const handleSaveAndEdit = useCallback(() => {
      setIsAddFormOpen(false);
      setIsEditFormOpen(true);
    }, []);

    const handleTemplateCloseAddForm = useCallback(() => {
      setIsAddFormOpen(false);
    }, []);

    const handleEditButtonClick = useCallback(() => {
      setIsEditFormOpen(true);
    }, []);
    const handleTemplateCloseEditForm = useCallback(() => {
      setIsEditFormOpen(false);
      setNextPublicationId('');
      setEstimate(null);
      setAmountWarningMessage('');
    }, []);
    const handleUnaccountedClick = useCallback(() => {
      setIsUnaccountedModalOpen(true);
    }, []);

    const createEstimateByContestRequest = useCallback(() => {
      if (!selectedContestRequest || !projectCode) return;
      createEstimateByContestRequestAPI.callAPI(
        {
          contestRequestId: selectedContestRequest.id || contestRequests[0].id || '',
          projectCodeId: projectCode.id || '',
        },
        {
          onSuccessfullCall: ({ data: estimateData }) => {
            setEstimate(estimateData);
            showNotification({ message: 'Смета создана', theme: 'success' });
            setSelectedContestRequestIndex(null);
            setIsCreateEstimateByContestRequestModalOpen(false);
            tableStreams.reloadTable.push();
            setIsEditFormOpen(true);
            getContestRequestAPI.callAPI(
              { requestId: selectedContestRequest?.id || undefined },
              {
                onSuccessfullCall: ({ data: contestRequestData }) => {
                  const estimateTaxPositions = estimateData.positions.filter(x => !!x.kindConsumption?.taxOwner);
                  const messages: string[] = [];
                  estimateTaxPositions.forEach(estimateTaxPosition => {
                    // requestStageEstimatePositions?
                    const contestRequestTaxPositions = contestRequestData.requestStageEstimatePositions.filter(
                      contestRequestEstimatePosition =>
                        estimateTaxPosition.kindConsumption?.id === contestRequestEstimatePosition.kindConsumption.id,
                    );
                    const totalByContestRequest = contestRequestTaxPositions.reduce(
                      (sum, x) => sum + parseFloat(x.amount) || 0,
                      0,
                    );
                    if (totalByContestRequest !== estimateTaxPosition.amount)
                      messages.push(
                        // eslint-disable-next-line max-len
                        `Для вида расхода <strong>"${estimateTaxPosition.kindConsumption?.name}"</strong> в заявке общая сумма <strong>${totalByContestRequest} руб.</strong>, сумма позиции созданной сметы шифра для данного вида расхода составляет <strong>${estimateTaxPosition.amount} руб.</strong>`,
                      );
                  });
                  if (!!messages.length) {
                    setAmountWarningMessage(messages.join('<br />'));
                  }
                },
              },
            );
          },
        },
      );
    }, [
      contestRequests,
      createEstimateByContestRequestAPI,
      getContestRequestAPI,
      selectedContestRequest,
      tableStreams.reloadTable,
    ]);

    const handleCreateFromRequestButtonClick = useCallback(() => {
      if (!!contestRequests.length) {
        setIsCreateEstimateByContestRequestModalOpen(true);
      } else {
        showNotification({ message: 'Для данного проекта нет заявок на продление в статусе "Поддержана"', theme: 'danger' });
      }
    }, [contestRequests.length]);

    const buttons: IconButtonProps[] = useMemo(
      () => [
        {
          icons: buttonIcons.loop,
          title: 'Просмотр',
          isDisabled: !selectedRow,
          permissionName: Permits.ESTIMATE_VIEW,
          onClick: handleViewButtonClick,
        },
        {
          icons: buttonIcons.plus,
          title: 'Добавить',
          isDisabled: isDisabled,
          permissionName: Permits.ESTIMATE_ADD,
          onClick: handleAddButtonClick,
        },
        {
          icons: buttonIcons.edit,
          title: 'Редактировать',
          isDisabled: isDisabled || !selectedRow,
          permissionName: Permits.ESTIMATE_EDIT,
          onClick: handleEditButtonClick,
        },
        {
          icons: buttonIcons.delete,
          title: 'Удалить',
          isDisabled: isDisabled || !selectedRow,
          permissionName: Permits.ESTIMATE_DELETE,
          onClick: handleDeleteButtonClick,
        },
        {
          icons: buttonIcons.clone,
          title: 'Создать на основе заявки',
          isDisabled: isDisabled,
          permissionName: Permits.ESTIMATE_ADD,
          onClick: handleCreateFromRequestButtonClick,
        },
        {
          icons: buttonIcons.book,
          title: 'Неучтеные расходы',
          permissionName: Permits.ESTIMATE_VIEW,
          onClick: handleUnaccountedClick,
        },
        {
          icons: buttonIcons.print,
          title: 'Печать документов',
          code: 'reports',
          onClick: () => {},
          getExpandedList: getReports,
          expandedItemCallback: handleSetCurrentReport,
        },
      ],
      [
        selectedRow,
        handleViewButtonClick,
        handleAddButtonClick,
        handleEditButtonClick,
        handleDeleteButtonClick,
        handleCreateFromRequestButtonClick,
        handleUnaccountedClick,
        getReports,
        handleSetCurrentReport,
      ],
    );

    return (
      <>
        <modalTemplates.EstimateViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />

        <modalTemplates.EstimateEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={estimate?.id || nextPublicationId || selectedRow?.id}
          relatedTableState={tableState}
          estimate={estimate || undefined}
          amountWarningMessage={amountWarningMessage}
        />

        <modalTemplates.EstimateAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          projectCode={projectCode}
          relatedTableState={tableState}
          name={nextPublicationName}
          setNextPublicationId={setNextPublicationId}
          saveAndEdit={handleSaveAndEdit}
        />

        {selectedRow && tableState.selectedRows.length > 0 && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранную смету? (${selectedRow?.id})`}
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            onConfirm={handleConfirmDeleteConfirmPopup}
            okButtonText="Да"
          />
        )}

        <Modal
          isOpen={isUnaccountedModalOpen}
          onClose={() => setIsUnaccountedModalOpen(false)}
          title="Неучтенные расходы"
          size="large"
        >
          {!!projectCode && <DataGrid specification={GetUnaccountedExpensesList({ projectCodeId: projectCode?.id })} />}
        </Modal>
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          projectCode={projectCode || undefined}
        />
        <ConfirmPopup
          title="Список заявок для проекта"
          isOpen={isCreateEstimateByContestRequestModalOpen}
          onClose={() => {
            setIsCreateEstimateByContestRequestModalOpen(false);
            setSelectedContestRequestIndex(null);
          }}
          size="medium"
          okButtonText="Создать смету"
          onConfirm={() => createEstimateByContestRequest()}
          isDisabled={selectedContestRequest === null}
        >
          <Toolbar
            buttons={[
              {
                icons: buttonIcons.loop,
                title: 'Просмотр',
                isDisabled: selectedContestRequest === null,
                onClick: () => setIsContestRequestFormOpen(true),
              },
            ]}
          />
          <ListEditTable<Project.ContestRequest>
            rows={contestRequests}
            columns={[
              {
                label: 'ID',
                formatValue: row => row.id || '',
                styles: { width: '10%' },
              },
              {
                label: 'Год (Этап проекта)',
                formatValue: row => row.year || '',
                styles: { width: '10%' },
              },
              {
                label: 'Тип',
                formatValue: row => row.type?.label || '',
              },
              {
                label: 'Статус',
                formatValue: row => row.status?.label || '',
              },
            ]}
            selectedRowIndex={selectedContestRequestIndex}
            selectRow={setSelectedContestRequestIndex}
          />
        </ConfirmPopup>
        <ContestRequestViewTemplate
          isOpen={isContestRequestFormOpen}
          onClose={() => setIsContestRequestFormOpen(false)}
          id={selectedContestRequest?.id || undefined}
          relatedTableState={tableState}
        />
        <Toolbar buttons={buttons} />
      </>
    );
  });

export const Component = LeftPanelForThirdLevel;
