import * as R from 'ramda';

import { EmployeeNominee, EmployeePayment } from 'types/models/Payment';
import { Settings } from 'types/models/common';
import { convertServerPerson } from '../author/converters';

import { convertDocument, convertItem, convertMaybeArray, getText } from '../commonConverters';

export function convertEmployeePayment(employee: any, settings: Settings): EmployeePayment {
  const serverNominees = employee.nominees.employeePaymentRequestNominee || [];
  const serverDocuments = employee.documents.Document || [];
  const documents = serverDocuments ? convertMaybeArray(serverDocuments, R.partial(convertDocument, ['local'])) : [];
  return {
    documents,
    id: employee._attributes.id,
    amountMoney: Number(getText(employee.amountMoney)),
    status: convertItem(employee.status),
    fund: {
      month: employee.fund.id.month,
      year: getText(employee.fund.id.year),
      quarter: convertItem(employee.fund.id.quarter),
      type: convertItem(employee.fund.id.type),
      commonSumm:
        Number(getText(employee.fund.planIncome)) +
        Number(getText(employee.fund.factIncome)) -
        Number(getText(employee.fund.planExpense)) -
        Number(getText(employee.fund.factExpense)) +
        Number(getText(employee.amountMoney)),
      departmentId: getText(employee.fund.id.departmentId),
      department: convertMaybeArray(employee.fund.department, convertEmployeeDepartment),
    },
    nominees: convertMaybeArray(serverNominees, n => convertEmployeeNominee(n, settings)),
  };
}

export function convertEmployeeNominee(nominee: any, settings: Settings): EmployeeNominee {
  return {
    id: nominee._attributes.id,
    amountMoney: Number(getText(nominee.amountMoney)),
    employee: convertServerPerson(nominee.employee, settings),
    job: {
      id: nominee.job._attributes.id,
      refAppointment: {
        id: nominee.job.refAppointment._attributes.id,
        label: nominee.job.refAppointment._attributes.label,
      },
    },
  };
}
export function convertEmployeeDepartment(department: any): { label: string } {
  return {
    label: getText(department.name),
  };
}
