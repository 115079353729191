import React from 'react';
import * as R from 'ramda';

import { ListEdit, SectionTitle } from 'components';

import { showErrors } from 'utils/Validators';
import { Project } from 'types/models';
import { getMockPerformerStage, getStageTitle, formatApprovememnts } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';
import { validate } from './validate';

type Props = {
  stages: Project.Stage[];
  setStages(stages: Project.PerformerStage[]): void;
  disabled: boolean;
  performer: Project.Performer;
};

export function Stages(props: Props) {
  const { stages, disabled, performer, setStages } = props;

  return (
    <>
      <SectionTitle title="Этапы" />
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        rows={performer.stages}
        onChange={setStages}
        defaultRowsCount={10}
        maxHeight="70vh"
        columns={[
          { label: 'Этап', formatValue: x => (x.stage ? getStageTitle(x.stage) : '') },
          { label: 'Подтверждено', formatValue: x => formatApprovememnts({ stages: [x], isDetailed: true }) },
          { label: 'Методы расчёта', formatValue: x => x.paymentMethods },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (performerStage, setPerformerStage) => (
            <Fields
              stages={stages}
              performerStage={performerStage || getMockPerformerStage()}
              setPerformerStage={setPerformerStage}
            />
          ),
          validation: {
            checkIsValid: performerStage => validate(performerStage).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      />
    </>
  );
}
