import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as R from 'ramda';

import { block } from 'bem-cn';

import { buttonIcons, IconButton, TextInput, Toolbar, Tooltip } from 'components';

import { formatNumber } from 'utils/Helpers';

import { Column, DataKind } from '../model';

import './style.scss';
import { SortIcon } from './SortIcon';
import { BooleanFilter } from './filter/BooleanFilter';
import { Color } from 'constants/colors';

const b = block('list-edit-table');

type Props<Row> = {
  columnsFilters?: Record<number, string>;
  setColumnsFilters?: React.Dispatch<Record<number, string>>;
  columnsSorting?: Record<number, SortType>;
  setColumnsSorting?: React.Dispatch<Record<number, SortType>>;
  rows: Row[];
  columns: Column<Row>[];
  selectedRowIndex: number | null;
  withoutHead?: boolean;
  selectRow(index: number | null): void;
  onSelectRows?(indexes: number[]): void;
  onDoubleRowClick?(index: number): void;
  getRowTheme?(row: Row): Color | '';
  defaultRowsCount?: number;
  tableStyles?: React.CSSProperties;
  columnIndexesForSumTotal?: number[];
  columnIndexesForIntegerTotal?: number[];
  selectedRowsIndexes?: number[];
  isVisibleFilters?: boolean;
  isSortEnabled?: boolean;
};

export type SortType = 'desc' | 'asc' | 'none';

type EmptyRowsProps = {
  rowsCount: number;
  columnsCount: number;
};

function EmptyRows({ rowsCount, columnsCount }: EmptyRowsProps) {
  return (
    <>
      {R.range(0, rowsCount).map((_, index) => (
        <tr key={index} className={b('row', { empty: true })}>
          {R.range(0, columnsCount).map(columnIndex => (
            <td key={columnIndex} className={b('column')} />
          ))}
        </tr>
      ))}
    </>
  );
}

type TotalRowProps<Row> = {
  columns: Column<Row>[];
  rows: Row[];
  columnIndexesForSumTotal: number[];
  columnIndexesForIntegerTotal: number[];
};

function TotalRow<Row>({ columns, rows, columnIndexesForSumTotal, columnIndexesForIntegerTotal }: TotalRowProps<Row>) {
  return (
    <tr key={rows.length + 1} className={b('row', { total: true })}>
      {columns.map((column, i) => (
        <td
          key={`${column.label}-${i}-${rows.length + 1}`.toString()}
          style={{
            width: `${Math.floor(100 / columns.length)}%`,
            ...(column.styles || {}),
          }}
          className={b('column')}
        >
          {columnIndexesForSumTotal.includes(i)
            ? formatNumber(
                rows.reduce((sum, row, rowIndex) => {
                  const val = column.formatValue(row, rowIndex);
                  const num = Number(val.toString().replaceAll(' ', ''));
                  if (isNaN(num)) {
                    return sum;
                  }
                  return sum + num;
                }, 0),
                columnIndexesForIntegerTotal.some(num => num === i) ? 0 : 2,
              )
            : ''}
        </td>
      ))}
    </tr>
  );
}

function Component<Row>(props: Props<Row>) {
  const {
    columnsFilters,
    setColumnsFilters,
    columnsSorting,
    setColumnsSorting,
    rows,
    columns,
    withoutHead = false,
    isVisibleFilters = false,
    isSortEnabled = false,
    selectedRowIndex,
    defaultRowsCount,
    getRowTheme,
    selectRow,
    onDoubleRowClick,
    tableStyles,
    onSelectRows,
    columnIndexesForSumTotal = [],
    columnIndexesForIntegerTotal = [],
    selectedRowsIndexes = [],
  } = props;

  const tableRef = useRef<HTMLTableElement | null>(null);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(() => {
    const unselectedClass = 'list-edit-table_unselected';
    function onKeyDown(event: KeyboardEvent) {
      if (event.shiftKey) {
        tableRef.current?.classList.add(unselectedClass);
      }
    }

    function onKeyUp(event: KeyboardEvent) {
      if (event.key === 'Shift') {
        tableRef.current?.classList.remove(unselectedClass);
      }
    }

    window.onkeydown = onKeyDown;
    window.onkeyup = onKeyUp;
    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, rowIndex: number) => {
    selectRow(selectedRowIndex !== null && rowIndex === selectedRowIndex ? null : rowIndex);

    if (onSelectRows) {
      if (event.detail > 1) {
        return;
      }

      const getSelectedIndexes = () => {
        if (event.ctrlKey) {
          const isNeedAddIndex = !selectedRowsIndexes.includes(rowIndex);
          const indexes = isNeedAddIndex
            ? [...selectedRowsIndexes, rowIndex]
            : R.reject(selectedIndex => selectedIndex === rowIndex, selectedRowsIndexes);
          return indexes;
        }

        if (event.shiftKey && selectedRowIndex !== null) {
          const from = selectedRowIndex < rowIndex ? selectedRowIndex : rowIndex;
          const to = selectedRowIndex > rowIndex ? selectedRowIndex : rowIndex;
          const range = R.range(from, to + 1);
          return range;
        }

        if (selectedRowsIndexes.length > 1) {
          return [rowIndex];
        }

        if (!selectedRowsIndexes.length) {
          return [rowIndex];
        }

        return selectedRowsIndexes.includes(rowIndex) ? [] : [rowIndex];
      };

      onSelectRows(getSelectedIndexes());
    }
  };

  const makeHandleChangeColumnFilter = (index: number) => (val: string) => {
    if (setColumnsFilters) {
      setColumnsFilters({ ...columnsFilters, [index]: val });
    }
  };

  const changeColumnSorting = useCallback(
    (index: number) => {
      if (columnsSorting && setColumnsSorting) {
        const directionSort: Record<SortType, SortType> = {
          asc: 'desc',
          desc: 'none',
          none: 'asc',
        };

        const nextSortType: SortType = directionSort[columnsSorting[index] || 'none'] || 'asc';

        const nextColumnsSorting = nextSortType === 'none' ? {} : { [index]: nextSortType };
        setColumnsSorting(nextColumnsSorting);
      }
    },
    [columnsSorting, setColumnsSorting],
  );

  const onColumnClick = useCallback(
    (index: number) => () => {
      if (isSortEnabled) {
        changeColumnSorting(index);
      }
    },
    [changeColumnSorting, isSortEnabled],
  );

  const emptyRowCount = useMemo(() => {
    return (defaultRowsCount || 3) - rows.length;
  }, [defaultRowsCount, rows]);

  return (
    <>
      {isVisibleFilters && (
        <div className={b('toolbar')}>
          <Toolbar
            buttons={[
              {
                icons: buttonIcons.resetFilter,
                title: 'Очистить все поля фильтрации',
                onClick: () => {
                  if (setColumnsFilters) {
                    setColumnsFilters({});
                  }
                },
              },
              {
                icons: buttonIcons.filter,
                title: 'Показать/спрятать фильтр',
                onClick: () => {
                  setIsOpenFilters(prev => !prev);
                },
              },
            ]}
          />
        </div>
      )}

      <table className={b()} ref={tableRef} style={tableStyles}>
        {!withoutHead && (
          <>
            <thead>
              <tr className={b('row', { head: true })}>
                {columns.map((column, index) => (
                  <td
                    role="presentation"
                    className={b('column', { 'with-sort': isSortEnabled })}
                    key={column.label}
                    style={column.styles}
                    onClick={onColumnClick(index)}
                  >
                    {column.label}
                    {column.tooltip && <Tooltip text={column.tooltip} />}
                    {isSortEnabled && Boolean(columnsSorting) && Boolean(columnsSorting![index]) && (
                      <div className={b('sort-icon')}>
                        <SortIcon sortType={columnsSorting![index]} />
                      </div>
                    )}
                  </td>
                ))}
              </tr>
              {isOpenFilters && (
                <tr className={b('row', { head: true, filter: true })}>
                  {columns.map((column, index) => {
                    return (
                      <td key={index} className={b('column')}>
                        {column.dataKind === DataKind.BOOLEAN ? (
                          <BooleanFilter
                            value={columnsFilters ? columnsFilters[index] : ''}
                            updateValue={makeHandleChangeColumnFilter(index)}
                          />
                        ) : (
                          <TextInput
                            value={columnsFilters ? columnsFilters[index] ?? '' : ''}
                            onChange={makeHandleChangeColumnFilter(index)}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              )}
            </thead>
          </>
        )}
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              className={b('row', {
                selected: onSelectRows ? selectedRowsIndexes.includes(index) : index === selectedRowIndex,
                hightlight: getRowTheme ? getRowTheme(row) : '',
              })}
              onDoubleClick={() => {
                if (onDoubleRowClick) {
                  onDoubleRowClick(index);
                }
              }}
              onClick={event => handleRowClick(event, index)}
            >
              {columns.map((column, i) => (
                <td
                  key={`${column.label}-${i}-${index}`.toString()}
                  style={{
                    width: `${Math.floor(100 / columns.length)}%`,
                    ...(column.styles || {}),
                  }}
                  className={b('column')}
                  dangerouslySetInnerHTML={
                    typeof column.formatValue(row, index) !== 'boolean'
                      ? { __html: String(column.formatValue(row, index)) }
                      : undefined
                  }
                >
                  {typeof column.formatValue(row, index) === 'boolean' && column.formatValue(row, index) ? (
                    <div className={b('icon-center')}>
                      <IconButton icons={buttonIcons.check} />
                    </div>
                  ) : undefined}
                </td>
              ))}
            </tr>
          ))}
          {emptyRowCount > 0 && <EmptyRows columnsCount={columns.length} rowsCount={emptyRowCount} />}
          {columnIndexesForSumTotal.length > 0 && (
            <TotalRow
              columns={columns}
              rows={rows}
              columnIndexesForSumTotal={columnIndexesForSumTotal}
              columnIndexesForIntegerTotal={columnIndexesForIntegerTotal}
            />
          )}
        </tbody>
      </table>
    </>
  );
}

export const ListEditTable = React.memo(Component) as typeof Component;
