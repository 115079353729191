import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isWithoutCalendarPlan',
      label: 'Шифры без календарных планов',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isWithoutOverheads',
      label: 'Не перечисленные накладные',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isAuditDocuments',
      label: 'Документы для аудита',
      checked: false,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});
