import { showNotification } from 'features/Notifications';

export type ValidationError = {
  isValid: boolean;
  invalidMessage: string;
};

export function showErrors<T>(checkIsValid: (item: T) => ValidationError[], item: T) {
  const info = checkIsValid(item);
  info.forEach(({ isValid, invalidMessage }) => {
    if (!isValid) {
      setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
    }
  });
}
