import { useCallback, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { useFormContext } from 'features/Form/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import * as BackendAPI from 'services/BackendAPI';
import { Item } from 'types/models/common';
import { getEnum } from 'utils/Helpers';

type Props = {
  onClose: () => void;
};

const useController = ({ onClose }: Props) => {
  const {
    look: { id },
  } = useFormContext();

  const { enumMap } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const errorReasonOptions = getEnum('PublicationLibraryLogErrorReason', enumMap);

  const [errorReason, setErrorReason] = useState<Item>({ value: '', label: '' });
  const [errorReasonDetail, setErrorReasonDetail] = useState<string>('');
  const [libraryNote, setLibraryNote] = useState<string>('');

  const handleErrorReasonChange = useCallback(
    (value: string) => {
      const selectedID = errorReasonOptions.filter(e => e.label === value).length
        ? errorReasonOptions.filter(e => e.label === value)[0].value
        : '';
      setErrorReason({ value: selectedID, label: value });
    },
    [errorReasonOptions],
  );

  const { methods: getPublicationLibraryLog } = BackendAPI.useBackendAPI('GetPublicationLibraryLog');
  const { methods: processPublicationLibraryLog } = BackendAPI.useBackendAPI('ProcessPublicationLibraryLog');

  const processLog = useCallback(() => {
    if (!errorReason) {
      showNotification({ theme: 'danger', message: 'Не заполнена причина ошибки' });
      return;
    }
    if (!id) {
      showNotification({ theme: 'danger', message: 'Произошла ошибка при загрузке' });
      return;
    }
    processPublicationLibraryLog.callAPI(
      {
        id,
        errorReason: {
          value: errorReason.value,
          label: errorReason.label,
          ordinal: errorReason.ordinal || 0,
          position: errorReason.position || 0,
        },
        errorReasonDetail: errorReasonDetail,
        libraryNote: libraryNote,
      },
      {
        onSuccessfullCall({ data }) {
          if (data.success) {
            onClose();
            tableStreams.reloadTable.push();
          }
          showNotification({
            theme: data.success ? 'success' : 'danger',
            message: data.success ? 'Успешно сохранено' : data.message,
          });
        },
      },
    );
  }, [errorReason, errorReasonDetail, libraryNote, id, onClose, processPublicationLibraryLog, tableStreams.reloadTable]);

  useLayoutEffect(() => {
    if (id) {
      getPublicationLibraryLog.callAPI(
        { publicationLibraryLogId: id },
        {
          onSuccessfullCall({ data }) {
            const { data: fieldsData, status } = data;
            if (!status.success) {
              showNotification({ theme: 'danger', message: status.errorMessage });
              return;
            }
            ReactDOM.unstable_batchedUpdates(() => {
              setErrorReason({
                value: fieldsData?.errorReason?.key,
                label: fieldsData?.errorReason?.label,
              });
              setErrorReasonDetail(fieldsData?.errorReasonDetail);
              setLibraryNote(fieldsData?.libraryNote);
            });
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback(() => {
    processLog();
  }, [processLog]);

  const handleCancelButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    errorReasonOptions,
    handleFormSubmit,
    handleCancelButtonClick,
    errorReason,
    handleErrorReasonChange,
    errorReasonDetail,
    setErrorReasonDetail,
    libraryNote,
    setLibraryNote,
  };
};

export default useController;
