import React from 'react';

import { FormComponent, Radio, Reference, TextArea, TextInput, TextInputMode, UploadFile } from 'components';

import { MipPartner } from 'types/models';
import { SelectScientist } from './SelectScientist';
import useController from './controller';

type Props = {
  founder: MipPartner.Founder;
  setFounder: (founder: MipPartner.Founder) => void;
  amount: string;
  isDisabled?: boolean;
};

export const Fields = ({ founder, setFounder, amount, isDisabled }: Props) => {
  const { isScientist, amountPercent, onChangeFile, onIsScientistChange, makeHandler } = useController({
    founder,
    amount,
    setFounder,
  });

  return (
    <>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Тип учредителя">
          <Radio
            isDisabled={isDisabled}
            value={`${isScientist}`}
            list={[
              { label: 'Физ. лицо', value: 'true' },
              { label: 'Юр. лицо', value: 'false' },
            ]}
            onChange={onIsScientistChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {isScientist ? (
        <FormComponent.Line>
          <SelectScientist
            isDisabled={isDisabled}
            isRequired
            label="ФИО"
            setScientist={makeHandler('scientist')}
            scientist={founder.scientist}
            modalTitle='Справочник "Персоны"'
          />
        </FormComponent.Line>
      ) : (
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Организиция">
            <Reference
              disabled={isDisabled}
              name="RefEnterpriseCustomer"
              value={founder.enterpriseCustomer && { id: founder.enterpriseCustomer.id, label: founder.enterpriseCustomer.label }}
              onChange={ref => makeHandler('enterpriseCustomer')(ref?.id ? { id: ref.id, label: ref.label } : null)}
              relationTableModalTitle='Справочник "Организации"'
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Контактная информация">
          <TextArea value={founder.info ?? ''} onChange={makeHandler('info')} isDisabled={isDisabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      {isScientist && (
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Пасспортные данные">
            <TextArea value={founder.passport ?? ''} onChange={makeHandler('passport')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Основание владения (скан документа)">
          <UploadFile file={founder.file} onChange={onChangeFile} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field isRequired label="Сумма">
          <TextInput
            mode={TextInputMode.number}
            value={founder.amount ?? ''}
            onChange={makeHandler('amount')}
            isDisabled={isDisabled}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Доля (%)">
          <>{amountPercent}</>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Вносимые активы">
          <TextInput value={founder.insertionAsserts ?? ''} onChange={makeHandler('insertionAsserts')} isDisabled={isDisabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};
