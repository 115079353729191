import { PublicationProject, PublicationMobileRequest } from 'types/models/Form';
import { Arm, Author, Document, FileInfo, Form } from 'types/models';
import * as XML from 'xml-js';

import { Settings } from 'types/models/common';
import { convertFormResponseXMLElementToOutput } from '../makeBackendFormAPIConfiguration';
import { convertDocumentsToRequest } from './converters';
import { convertAuthorsToRequest } from './author/requestConverters';
import { convertAffiliationToRequest } from './publication/convertToRequest';
import { makeBackendAPIConfiguration } from '../makeBackendAPIConfiguration';
import { ReferenceItem } from 'components';
import { Community } from 'types/models';
import { convertMemberToXML } from 'services/BackendAPI/configurations/community/requestConverters';
import { convertFileToXML } from './commonConverters';

type PublicationFeedline = {
  messageId: string;
  detail: string;
};

type PublicationArrayField = {
  id: string;
};

export type PublicationInput = {
  // next fields setup in field value
  arm?: Arm;
  simpleFields: {
    isLocal?: boolean;
    presetAuthorCategory?: string;
    status: string;
    bibliographicRecord?: string;
    typeEdition?: string;
    type?: string;
    name?: string;
    shortName?: string;
    libraryLink?: string;
    textLanguages?: string;
    doi?: string;
    issuesByYear?: string;
    url?: string;
    year?: string;
    issn?: string;
    eIssn?: string;
    articleNumber?: string;
    chapterNumber?: string;
    electronicType?: string;
    isElectronic?: boolean;
    isbn?: string;
    eIsbn?: string;
    issue?: string;
    volume?: string;
    address?: string;
    defencePlace?: string;
    month?: string;
    printPageCount?: string;
    tirage?: string;
    pageCount?: string;
    startPage?: string;
    endPage?: string;
    day?: string;
    electronicMediaSource?: string;
    seriesTitle?: string;
    electronicSystemRequirements?: string;
    udk?: string;
    urlDate?: string;
    annotation?: string;
    authorsKeyWords?: string;
    authorsCount?: string;
    fractionalCount?: string;
    republishData?: string;
    volumeNumber?: string;
    councilCipher?: string;
    defenceDate?: string;
    isPreprint?: boolean;
    foreignName?: string;
    foreignAnnotation?: string;
    foreignAuthorsKeyWords?: string;
    foreignAuthors?: string;
    publicationDate?: string;
    note?: string;
  };

  // next fields setup in field._attr.id
  attrIdFields: {
    publisher?: string;
    original?: string;
    magazine?: string;
    magazineRelease?: string;
    compilation?: string;
    publicationOther?: string;
    monograph?: string;
    categoryEdition?: string;
    textLanguage?: string;
    sourceType?: string;
    grnti?: string;
    study?: string;
    originalLanguage?: string;
    grif?: string;
    vakSpeciality?: string;
    degree?: string;
    printArticle?: string;
    council?: string;
  };

  // next fields setup in Fields.Field: [
  //  { _attr: { id }}, { _attr: { id }}
  // ]
  arrayFields: {
    translations?: { translation: PublicationArrayField[] };
    events?: { event: PublicationArrayField[] };
  };

  // other fields START
  id?: string;
  file?: FileInfo;
  feedLine?: PublicationFeedline;
  projects?: PublicationProject[];
  communityMembers?: Community.Member[];
  mobileRequests?: { mobileRequest?: PublicationMobileRequest[] };
  authors?: Author[];
  isBibliographicFetch?: boolean;
  documents?: Document[];
  affiliation?: Form.Affiliation | null;
  department?: ReferenceItem | null;
  // other fields END
};

export type PublicationProcessInput = {
  publicationId: string;
  magazineReleaseId: string;
};

function convertRequestForBibliographicFetch(preparedInput: any) {
  return {
    ...preparedInput,
    Members: {
      ...(preparedInput.Members?.Member?.length
        ? {
            Member: preparedInput.Members?.Member?.map((m: any) => ({
              ...m,
              _attr: {},
              MemberAffiliations: m.MemberAffiliations?.MemberAffiliation?.map((a: any) => ({
                MemberAffiliation: {
                  ...a,
                  _attr: {},
                },
              })),
            })),
          }
        : {}),
    },
    Projects: {
      ...(preparedInput.Projects?.Project?.length
        ? {
            Project: preparedInput.Projects?.Project?.map((m: any) => ({
              ...m,
              _attr: {},
            })),
          }
        : {}),
    },
    Departments: {
      ...(preparedInput.Departments?.Department?.length
        ? {
            Department: preparedInput.Departments?.Department?.map((m: any) => ({
              ...m,
              _attr: {},
            })),
          }
        : {}),
    },
    MobileRequests: {
      ...(preparedInput.MobileRequests?.MobileRequest?.length
        ? {
            MobileRequest: preparedInput.MobileRequests?.MobileRequest?.map((m: any) => ({
              ...m,
              _attr: {},
            })),
          }
        : {}),
    },
    CitationSystems: {
      ...(preparedInput.CitationSystems?.CitationSystem?.length
        ? {
            CitationSystem: preparedInput.CitationSystems?.CitationSystem?.map((m: any) => ({
              ...m,
              _attr: {},
            })),
          }
        : {}),
    },
  };
}

function makeRequestXMLConverter(input: PublicationInput) {
  const {
    simpleFields,
    arrayFields,
    attrIdFields,
    projects,
    mobileRequests,
    id,
    file,
    feedLine,
    authors,
    documents,
    isBibliographicFetch,
    affiliation,
    department,
    communityMembers,
  } = input;

  let preparedFormInput: any = {};

  // fill simple fields
  Object.keys(simpleFields).forEach(key => {
    const simpleFieldKey = key as keyof PublicationInput['simpleFields'];

    const isKeyValueEmpty =
      simpleFields[simpleFieldKey] !== undefined && simpleFields[simpleFieldKey] !== '' && simpleFields[simpleFieldKey] !== null;

    if (isKeyValueEmpty) {
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      preparedFormInput[preparedKey] = simpleFields[simpleFieldKey];
    }
  });

  // fill array fields
  Object.keys(arrayFields).forEach(key => {
    const arrayFieldKey = key as keyof PublicationInput['arrayFields'];
    const arrayFieldValue = arrayFields[arrayFieldKey];
    const isKeyValueEmpty = !arrayFieldValue;

    if (!isKeyValueEmpty) {
      const arrayInnerFieldKey = Object.keys(arrayFields[arrayFieldKey]!)[0];
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      const preparedInnerKey = arrayInnerFieldKey[0].toUpperCase() + arrayInnerFieldKey.substring(1);
      const innerValue: PublicationArrayField[] = arrayFields[arrayFieldKey]![arrayInnerFieldKey as keyof typeof arrayFieldValue];

      preparedFormInput[preparedKey] = {};

      if (innerValue.length) {
        const preapredInnerValue = innerValue.map(innerValueMember => ({ _attr: { id: innerValueMember.id } }));
        preparedFormInput[preparedKey] = {
          [preparedInnerKey]: preapredInnerValue,
        };
      }
    }
  });

  // fill attributeid fields
  Object.keys(attrIdFields).forEach(key => {
    const attrIdFieldKey = key as keyof PublicationInput['attrIdFields'];

    const isKeyValueEmpty = !!attrIdFields[attrIdFieldKey];

    if (isKeyValueEmpty) {
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      preparedFormInput[preparedKey] = { _attr: { id: attrIdFields[attrIdFieldKey] } };
    }
  });

  // START fill rest fields
  if (id !== undefined) {
    preparedFormInput._attr = { id };
  }

  if (!projects?.length) preparedFormInput.Projects = {};
  else
    preparedFormInput.Projects = {
      Project: projects.map(project => ({
        _attr: project?.id ? { id: project.id } : null,
        Project: {
          _attr: { id: project?.project?.id },
        },
        IsAccepted: !!project?.accepted,
      })),
    };

  if (communityMembers !== undefined) {
    preparedFormInput.CommunityMembers = {};
    if (communityMembers?.length) {
      preparedFormInput.CommunityMembers = communityMembers.length
        ? { CommunityMember: communityMembers.map(convertMemberToXML) }
        : {};
    }
  }

  if (mobileRequests !== undefined) {
    preparedFormInput.MobileRequests = {};
    if (mobileRequests?.mobileRequest?.length) {
      const requestData = mobileRequests.mobileRequest.map((req: PublicationMobileRequest) => ({
        _attr: req.id ? { id: req.id } : null,
        MobileRequest: {
          _attr: { id: req.mobileRequest?.id },
        },
        IsAccepted: !!req.accepted,
      }));
      preparedFormInput.MobileRequests = {
        MobileRequest: requestData,
      };
    }
  }

  preparedFormInput = {
    ...preparedFormInput,
    ...convertFileToXML('File', file),
  };

  if (feedLine !== undefined) {
    preparedFormInput.PublicationFeedline = {
      Message: { _attr: { id: feedLine.messageId } },
      Detail: feedLine.detail,
    };
  }

  if (affiliation) {
    preparedFormInput = {
      ...preparedFormInput,
      ...convertAffiliationToRequest(affiliation),
    };
  }

  if (authors) {
    preparedFormInput = {
      ...preparedFormInput,
      ...convertAuthorsToRequest(authors),
    };
  }
  if (documents) {
    preparedFormInput = {
      ...preparedFormInput,
      ...convertDocumentsToRequest(documents, 'local'),
      // ...convertMobileRequestsToRequest(mobileRequests?.mobileRequest),
    };
  }
  if (isBibliographicFetch) {
    preparedFormInput = convertRequestForBibliographicFetch(preparedFormInput);
  }
  if (department?.id) {
    preparedFormInput = {
      ...preparedFormInput,
      Department: {
        Department: { _attr: { id: department.id } },
      },
    };
  }

  // END fill rest fields
  return {
    Publication: {
      ...preparedFormInput,
    },
  };
}

export const backendFormApiConfigurations = {
  GetPublication: makeBackendAPIConfiguration({
    id: 'GetPublication',
    endpoint: '/msa/service/commands/GetPublication',
    convertInputToXMLElement: (input: PublicationInput) => ({
      Request: {
        _attr: {
          commandName: 'GetPublication',
          ...(input.arm ? { workbench: input.arm } : {}),
        },
        ...makeRequestXMLConverter(input),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertFormResponseXMLElementToOutput(response, settings),
  }),
  SavePublication: makeBackendAPIConfiguration({
    id: 'SavePublication',
    endpoint: '/msa/service/commands/SavePublication',
    convertInputToXMLElement: (input: PublicationInput) => ({
      Request: {
        _attr: {
          commandName: 'SavePublication',
          ...(input.arm ? { workbench: input.arm } : {}),
        },
        ...makeRequestXMLConverter(input),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertFormResponseXMLElementToOutput(response, settings),
  }),
  PublicationPreprintProcess: makeBackendAPIConfiguration({
    id: 'PublicationPreprintProcess',
    endpoint: '/msa/service/commands/PublicationPreprintProcess',
    convertInputToXMLElement: (input: PublicationProcessInput) => ({
      Request: {
        _attr: {
          commandName: 'PublicationPreprintProcess',
        },
        Publication: {
          _attr: { id: input?.publicationId },
          MagazineRelease: {
            _attr: { id: input.magazineReleaseId },
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertFormResponseXMLElementToOutput(response, settings),
  }),
};
