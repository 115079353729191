import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';

export function useController(tableState: Table.State) {
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    if (selectedRow?.id) {
      tableStreams.deleteRow.push({
        deleteRowId: selectedRow.id,
        command: 'DeleteEntity',
        deletedItemPropName: 'ProtocolCommission',
      });
      setIsDeleteConfirmPopupOpen(false);
    }
  }, [tableStreams.deleteRow, selectedRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        permissionName: Permits.PROTOCOL_COMMISSION_VIEW,
        isDisabled: !selectedRow,
        onClick: () => setIsViewFormOpen(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        permissionName: Permits.PROTOCOL_COMMISSION_ADD,
        onClick: () => setIsAddFormOpen(true),
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        permissionName: Permits.PROTOCOL_COMMISSION_EDIT,
        isDisabled: !selectedRow,
        onClick: () => setIsEditFormOpen(true),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        permissionName: Permits.PROTOCOL_COMMISSION_DELETE,
        isDisabled: !selectedRow,
        onClick: handleDeleteButtonClick,
      },
    ],
    [selectedRow, handleDeleteButtonClick],
  );

  return {
    buttons,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isViewFormOpen,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsViewFormOpen,
  };
}
