import { Exponent } from 'utils/Permissions/Exponent';
import { IncentivePayment } from './IncentivePayment';
import { Fund } from './Fund';
import { User } from './User';
import { Actions } from './Actions';
import { ScientificIndexAndIndicator } from './ScientificIndexAndIndicator';
import { Scientist } from './Scientist';
import { Event } from './Event';
import { RecordStatus } from '../Enums/RecordStatus';
import { Midfixes } from './Midfixes';
import { Recognition } from './Recognition';
import { Participation } from './Participation';
import { Program } from './Program';
import { ProgramEvent } from './ProgramEvent';
import { Tender } from './Tender';
import { Lot } from './Lot';
import { ProgramRequest } from './ProgramRequest';
import { ProjectLock } from './ProjectLock';
import { PatentResearch } from './PatentResearch';
import { ProjectStateRegistration } from './ProjectStateRegistration';
import { SecurityDocument } from './SecurityDocument';
import { SecurityDocumentContract } from './SecurityDocumentContract';
import { Rntd } from './Rntd';
import { Magazine } from './Magazine';
import { Compilation } from './Compilation';
import { Publication } from './Publication';
import { MobileRequest } from './MobileRequest';
import { MobileRequestTab } from './MobileRequestTab';
import { TenderProtocol, TenderProtocolTabs } from 'utils/Permissions/TenderProtocol';
import { ProtocolCommission } from './ProtocolCommission';
import { MobileExpenseNormative } from './MobileExpenseNormative';
import { MobileKindConsumption } from './MobileKindConsumption';
import { ProjectCode } from './ProjectCode';
import { Estimate } from './Estimate';
import { FundCard } from './FundCard';
import { KindConsumption } from './KindConsumption';
import { Partner } from './Partner';
import { ContestRequest } from './ContestRequest';
import { ContestRequestStatus } from '../Enums/ContestRequestStatus';
import { Report } from './Report';
import { ContestRequestKindConsumption } from './ContestRequestKindConsumption';
import { NirRequest } from './NirRequest';
import { NirRequestKindConsumption } from './NirRequestKindConsumption';
import { NirRequestStatus } from '../Enums/NirRequestStatus';
import { MsaWorkbenches } from '../Common/MsaWorkbenches';
import { Qna } from './Qna';
import { CommunityInfo } from './CommunityInfo';
import { PersonalCabinet } from './PersonalCabinet';

import { Notice } from './Notice';
import { HelpfulStuff } from './HelpfulStuff';
import { UsefulLink } from './UsefulLink';

import { TotalAnalytics } from './TotalAnalytics';
import { IntellectualPropertyAnalytics } from './IntellectualPropertyAnalytics';
import { GovContractAnalytics } from './GovContractAnalytics';
import { EconomicContractAnalytics } from './EconomicContractAnalytics';
import { ResearchJobAnalytics } from './ResearchJobAnalytics';
import { GrantAnalytics } from './GrantAnalytics';
import { Subject } from './Subject';
import { Mip } from './Mip';
import { JointEvent } from './JointEvent';
import { Community } from './Community';
import { NirFinancing } from './NirFinancing';
import { Person } from './Person';
import { RequestStatus } from 'utils/Enums/RequestStatus';
import { ProgramRequestTab } from './ProgramRequestTab';
import { ContestRequestTab } from './ContestRequestTab';
import { NirRequestTab } from './NirRequestTab';
import { ConferenceTab } from './ConferenceTab';
import { MobileRequestStatus } from 'utils/Enums';
import { PurchaseRequest } from 'utils/Permissions/PurchaseRequest';
import { PurchaseRequestApprover, PurchaseRequestStatus } from 'types/models/PurchaseRequest';
import { Salary } from './Salary';
import { ProjectPerformerOrder } from 'utils/Permissions/ProjectPerformerOrder';
import { RequestKindConsumption } from 'utils/Permissions/RequestKindConsumption';
import { Feedline } from './Feedline';

export class Permits {
  private static SEPARATOR: string = ':';

  /** Личный кабинет * */
  static PERSONAL_CABINET: string = PersonalCabinet.MODULE;

  /** Стимулирующие выплаты * */
  static getNomineeRequestCRUDPermission(type: string, action: string, status: string | null = null): string {
    if (status) {
      return this.build(IncentivePayment.MODULE, IncentivePayment.NOMINEE_REQUEST_BRANCH, type, action, status);
    }
    return this.build(IncentivePayment.MODULE, IncentivePayment.NOMINEE_REQUEST_BRANCH, type, action);
  }

  static PATENT_REQUEST_STATUS_TO_AGREEMENT: string = 'IPPatentRequestsStatusChangeToAgreement';

  static PATENT_REQUEST_STATUS_TO_PAID: string = 'IPPatentRequestsStatusChangeToPaid';

  static PATENT_REQUEST_STATUS_TO_REJECTED: string = 'IPPatentRequestsStatusChangeToRejected';

  static PATENT_REQUEST_STATUS_TO_REVISION: string = 'IPPatentRequestsStatusChangeToRevision';

  static PATENT_REQUEST_STATUS_TO_POSTPONE: string = 'IPPatentRequestsStatusChangeToPostpone';

  static PATENT_REQUEST_STATUS_TO_PAYMENT_APPROVED: string = 'IPPatentRequestsStatusChangeToPaymentApproved';

  /** Фонды */
  static IP_FUND_VIEW_BY_DEPARTMENT = 'IP' + Fund.MODULE + Actions.VIEW + 'ByDepartment';

  static IP_FUND_VIEW_FULL_LIST = 'IP' + Fund.MODULE + Actions.VIEW + 'FullList';

  /** Пользователи * */
  static USER_MERGE: string = this.build(User.MODULE, Actions.MERGE);

  /** Наукометрические показатели * */
  static SCIENTIFIC_INDEX_AND_INDICATOR_EDIT: string = this.build(ScientificIndexAndIndicator.MODULE, Actions.EDIT);

  static HIRSH_INDEX_EDIT: string = 'HirschIndexEdit';

  /* Персоны */
  static SCIENTIST_VIEW: string = this.build(Scientist.MODULE, Actions.VIEW);

  static SCIENTIST_ADD: string = this.build(Scientist.MODULE, Actions.ADD);

  static SCIENTIST_EDIT: string = this.build(Scientist.MODULE, Actions.EDIT);

  static SCIENTIST_JOB_EDUCATION_DEGREE_RANK_DELETE: string = this.build(
    Scientist.MODULE,
    'JobEducationDegreeRank',
    Actions.DELETE,
  );

  static SCIENTIST_DELETE: string = this.build(Scientist.MODULE, Actions.DELETE);

  static SCIENTIST_MERGE: string = this.build(Scientist.MODULE, Actions.MERGE);

  static SCIENTIST_EGLISH_FULL_NAME_EDIT: string = this.build(Scientist.MODULE, Scientist.ENGLISH_FULL_NAME_BLOCK, Actions.EDIT);

  static SCIENTIST_IDENTIFIER_AUTHOR_EDIT: string = this.build(Scientist.MODULE, Scientist.IDENTIFIER_AUTHOR, Actions.EDIT);

  static PERSON_LOCAL_JOB_CREATE: string = 'PersonLocalJobCreate';

  static PERSON_LOCAL_EDUCATION_CREATE: string = 'PersonLocalEducationCreate';

  static PERSON_DUPLICATES_PROCESS: string = this.build(Scientist.MODULE, 'Duplicates', 'Process');

  static PERSON_PRIVATE_DATA_SNILS_INN_ACCESS: string = this.build(
    Scientist.MODULE,
    Scientist.PRIVATE_DATA,
    Scientist.SNILS_INN,
    Actions.ACCESS,
  );

  static PERSON_PRIVATE_DATA_CONTACTS_ACCESS: string = this.build(
    Scientist.MODULE,
    Scientist.PRIVATE_DATA,
    Scientist.CONTACTS,
    Actions.ACCESS,
  );

  static PERSON_PRIVATE_DATA_BIRTH_DATE_ACCESS: string = this.build(
    Scientist.MODULE,
    Scientist.PRIVATE_DATA,
    Scientist.BIRTH_DATE,
    Actions.ACCESS,
  );

  /* Мероприятия */
  static CONTEST_LIST_VIEW: string = this.build(Event.CONTEST, Midfixes.LIST, Actions.VIEW);

  static CONTEST_VIEW: string = this.build(Event.CONTEST, Actions.VIEW);

  static CONTEST_ADD: string = this.build(Event.CONTEST, Actions.ADD);

  static CONTEST_DELETE_ADDED: string = this.build(Event.CONTEST, Actions.DELETE, RecordStatus.ADDED);

  static CONTEST_DELETE_APPROVED: string = this.build(Event.CONTEST, Actions.DELETE, RecordStatus.APPROVED);

  static CONTEST_EDIT_ADDED: string = this.build(Event.CONTEST, Actions.EDIT, RecordStatus.ADDED);

  static CONTEST_EDIT_APPROVED_ABOUT: string = this.build(Event.CONTEST, Actions.EDIT, RecordStatus.APPROVED, 'About');

  static CONTEST_EDIT_APPROVED_ORGANIZATION: string = this.build(
    Event.CONTEST,
    Actions.EDIT,
    RecordStatus.APPROVED,
    'Organization',
  );

  static CONTEST_EDIT_APPROVED_FINANCING: string = this.build(Event.CONTEST, Actions.EDIT, RecordStatus.APPROVED, 'Financing');

  static CONTEST_APPROVE: string = this.build(Event.CONTEST, Actions.APPROVE);

  static CONTEST_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.CONTEST, 'ScienceProjectAccepting');

  static CONFERENCE_LIST_VIEW: string = this.build(Event.CONFERENCE, Midfixes.LIST, Actions.VIEW);

  static CONFERENCE_VIEW: string = this.build(Event.CONFERENCE, Actions.VIEW);

  static CONFERENCE_ADD: string = this.build(Event.CONFERENCE, Actions.ADD);

  static CONFERENCE_DELETE_ADDED: string = this.build(Event.CONFERENCE, Actions.DELETE, RecordStatus.ADDED);

  static CONFERENCE_DELETE_APPROVED: string = this.build(Event.CONFERENCE, Actions.DELETE, RecordStatus.APPROVED);

  static CONFERENCE_EDIT_ADDED: string = this.build(Event.CONFERENCE, Actions.EDIT, RecordStatus.ADDED);

  static CONFERENCE_EDIT_APPROVED_ABOUT: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    RecordStatus.APPROVED,
    ConferenceTab.ABOUT,
  );

  static CONFERENCE_EDIT_APPROVED_ORGANIZATION: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    RecordStatus.APPROVED,
    ConferenceTab.ORGANIZATION,
  );

  static CONFERENCE_EDIT_APPROVED_FINANCING: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    RecordStatus.APPROVED,
    ConferenceTab.FINANCING,
  );

  static CONFERENCE_EDIT_APPROVED_CLASSIFICATORS: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    RecordStatus.APPROVED,
    ConferenceTab.CLASSIFICATORS,
  );

  static CONFERENCE_APPROVE: string = this.build(Event.CONFERENCE, Actions.APPROVE);

  static CONFERENCE_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.CONFERENCE, 'ScienceProjectAccepting');

  static EXPOSITION_LIST_VIEW: string = this.build(Event.EXPOSITION, Midfixes.LIST, Actions.VIEW);

  static EXPOSITION_VIEW: string = this.build(Event.EXPOSITION, Actions.VIEW);

  static EXPOSITION_ADD: string = this.build(Event.EXPOSITION, Actions.ADD);

  static EXPOSITION_DELETE_ADDED: string = this.build(Event.EXPOSITION, Actions.DELETE, RecordStatus.ADDED);

  static EXPOSITION_DELETE_APPROVED: string = this.build(Event.EXPOSITION, Actions.DELETE, RecordStatus.APPROVED);

  static EXPOSITION_EDIT_ADDED: string = this.build(Event.EXPOSITION, Actions.EDIT, RecordStatus.ADDED);

  static EXPOSITION_EDIT_APPROVED_ABOUT: string = this.build(Event.EXPOSITION, Actions.EDIT, RecordStatus.APPROVED, 'About');

  static EXPOSITION_EDIT_APPROVED_ORGANIZATION: string = this.build(
    Event.EXPOSITION,
    Actions.EDIT,
    RecordStatus.APPROVED,
    'Organization',
  );

  static EXPOSITION_EDIT_APPROVED_FINANCING: string = this.build(
    Event.EXPOSITION,
    Actions.EDIT,
    RecordStatus.APPROVED,
    'Financing',
  );

  static EXPOSITION_APPROVE: string = this.build(Event.EXPOSITION, Actions.APPROVE);

  static EXPOSITION_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.EXPOSITION, 'ScienceProjectAccepting');

  static CONCERT_LIST_VIEW: string = this.build(Event.CONCERT, Midfixes.LIST, Actions.VIEW);

  static CONCERT_VIEW: string = this.build(Event.CONCERT, Actions.VIEW);

  static CONCERT_ADD: string = this.build(Event.CONCERT, Actions.ADD);

  static CONCERT_DELETE_ADDED: string = this.build(Event.CONCERT, Actions.DELETE, RecordStatus.ADDED);

  static CONCERT_DELETE_APPROVED: string = this.build(Event.CONCERT, Actions.DELETE, RecordStatus.APPROVED);

  static CONCERT_EDIT_ADDED: string = this.build(Event.CONCERT, Actions.EDIT, RecordStatus.ADDED);

  static CONCERT_EDIT_APPROVED_ABOUT: string = this.build(Event.CONCERT, Actions.EDIT, RecordStatus.APPROVED, 'About');

  static CONCERT_EDIT_APPROVED_FINANCING: string = this.build(Event.CONCERT, Actions.EDIT, RecordStatus.APPROVED, 'Financing');

  static CONCERT_APPROVE: string = this.build(Event.CONCERT, Actions.APPROVE);

  static CONCERT_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.CONCERT, 'ScienceProjectAccepting');

  /** Признания * */
  static RECOGNITION_INFO_VIEW: string = this.build(Recognition.MODULE, Midfixes.INFO, Actions.VIEW);

  static RECOGNITION_ADD: string = this.build(Recognition.MODULE, Actions.ADD);

  static RECOGNITION_ADDED_EDIT: string = this.build(Recognition.MODULE, RecordStatus.ADDED, Actions.EDIT);

  static RECOGNITION_APPROVED_EDIT: string = this.build(Recognition.MODULE, RecordStatus.APPROVED, Actions.EDIT);

  static RECOGNITION_ADDED_DELETE: string = this.build(Recognition.MODULE, RecordStatus.ADDED, Actions.DELETE);

  static RECOGNITION_APPROVED_DELETE: string = this.build(Recognition.MODULE, RecordStatus.APPROVED, Actions.DELETE);

  static RECOGNITION_APPROVE: string = this.build(Recognition.MODULE, Actions.APPROVE);

  static RECOGNITION_DEPARTMENT_EDIT: string = this.build(Recognition.MODULE, 'DepartmentEdit');

  /** Участия * */
  static PARTICIPATION_CONTEST_LIST_VIEW: string = this.build(Participation.MODULE, Event.CONTEST, Midfixes.LIST, Actions.VIEW);

  static PARTICIPATION_CONTEST_VIEW: string = this.build(Participation.MODULE, Event.CONTEST, Actions.VIEW);

  static PARTICIPATION_CONTEST_ADD: string = this.build(Participation.MODULE, Event.CONTEST, Actions.ADD);

  static PARTICIPATION_CONTEST_EDIT_ADDED: string = this.build(
    Participation.MODULE,
    Event.CONTEST,
    Actions.EDIT,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_CONTEST_EDIT_APPROVED: string = this.build(
    Participation.MODULE,
    Event.CONTEST,
    Actions.EDIT,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_CONTEST_DELETE_ADDED: string = this.build(
    Participation.MODULE,
    Event.CONTEST,
    Actions.DELETE,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_CONTEST_DELETE_APPROVED: string = this.build(
    Participation.MODULE,
    Event.CONTEST,
    Actions.DELETE,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_CONTEST_APPROVE: string = this.build(Participation.MODULE, Event.CONTEST, Actions.APPROVE);

  static PARTICIPATION_CONTEST_BIND_TO_DEPARTMENT: string = this.build(Participation.MODULE, Event.CONTEST, 'BindToDepartment');

  static PARTICIPATION_CONTEST_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.CONTEST, 'DepartmentEdit');

  static PARTICIPATION_CONFERENCE_LIST_VIEW: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Midfixes.LIST,
    Actions.VIEW,
  );

  static PARTICIPATION_CONFERENCE_VIEW: string = this.build(Participation.MODULE, Event.CONFERENCE, Actions.VIEW);

  static PARTICIPATION_CONFERENCE_ADD: string = this.build(Participation.MODULE, Event.CONFERENCE, Actions.ADD);

  static PARTICIPATION_CONFERENCE_EDIT_ADDED: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Actions.EDIT,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_CONFERENCE_EDIT_APPROVED: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Actions.EDIT,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_CONFERENCE_DELETE_ADDED: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Actions.DELETE,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_CONFERENCE_DELETE_APPROVED: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Actions.DELETE,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_CONFERENCE_APPROVE: string = this.build(Participation.MODULE, Event.CONFERENCE, Actions.APPROVE);

  static PARTICIPATION_CONFERENCE_BIND_TO_DEPARTMENT: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    'BindToDepartment',
  );

  static PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.CONFERENCE, 'DepartmentEdit');

  static PARTICIPATION_CONFERENCE_PROJECTS_EDIT: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    'Projects',
    Actions.EDIT,
  );

  static PARTICIPATION_CONFERENCE_PROJECTS_ACCEPTING: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    'Projects',
    Actions.ACCEPTING,
  );

  static PARTICIPATION_EXPOSITION_LIST_VIEW: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Midfixes.LIST,
    Actions.VIEW,
  );

  static PARTICIPATION_EXPOSITION_VIEW: string = this.build(Participation.MODULE, Event.EXPOSITION, Actions.VIEW);

  static PARTICIPATION_EXPOSITION_ADD: string = this.build(Participation.MODULE, Event.EXPOSITION, Actions.ADD);

  static PARTICIPATION_EXPOSITION_EDIT_ADDED: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Actions.EDIT,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_EXPOSITION_EDIT_APPROVED: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Actions.EDIT,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_EXPOSITION_DELETE_ADDED: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Actions.DELETE,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_EXPOSITION_DELETE_APPROVED: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Actions.DELETE,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_EXPOSITION_APPROVE: string = this.build(Participation.MODULE, Event.EXPOSITION, Actions.APPROVE);

  static PARTICIPATION_EXPOSITION_BIND_TO_DEPARTMENT: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    'BindToDepartment',
  );

  static PARTICIPATION_EXPOSITION_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.EXPOSITION, 'DepartmentEdit');

  static PARTICIPATION_EXPOSITION_PROJECTS_EDIT: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    'Projects',
    Actions.EDIT,
  );

  static PARTICIPATION_EXPOSITION_PROJECTS_ACCEPTING: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    'Projects',
    Actions.ACCEPTING,
  );

  static PARTICIPATION_CONCERT_LIST_VIEW: string = this.build(Participation.MODULE, Event.CONCERT, Midfixes.LIST, Actions.VIEW);

  static PARTICIPATION_CONCERT_VIEW: string = this.build(Participation.MODULE, Event.CONCERT, Actions.VIEW);

  static PARTICIPATION_CONCERT_ADD: string = this.build(Participation.MODULE, Event.CONCERT, Actions.ADD);

  static PARTICIPATION_CONCERT_EDIT_ADDED: string = this.build(
    Participation.MODULE,
    Event.CONCERT,
    Actions.EDIT,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_CONCERT_EDIT_APPROVED: string = this.build(
    Participation.MODULE,
    Event.CONCERT,
    Actions.EDIT,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_CONCERT_DELETE_ADDED: string = this.build(
    Participation.MODULE,
    Event.CONCERT,
    Actions.DELETE,
    RecordStatus.ADDED,
  );

  static PARTICIPATION_CONCERT_DELETE_APPROVED: string = this.build(
    Participation.MODULE,
    Event.CONCERT,
    Actions.DELETE,
    RecordStatus.APPROVED,
  );

  static PARTICIPATION_CONCERT_APPROVE: string = this.build(Participation.MODULE, Event.CONCERT, Actions.APPROVE);

  static PARTICIPATION_CONCERT_BIND_TO_DEPARTMENT: string = this.build(Participation.MODULE, Event.CONCERT, 'BindToDepartment');

  static PARTICIPATION_CONCERT_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.CONCERT, 'DepartmentEdit');

  /** Программы  и мероприятия УНП* */
  static PROGRAM_VIEW: string = this.build(Program.MODULE, Actions.VIEW);

  static PROGRAM_ADD: string = this.build(Program.MODULE, Actions.ADD);

  static PROGRAM_EDIT: string = this.build(Program.MODULE, Actions.EDIT);

  static PROGRAM_DELETE: string = this.build(Program.MODULE, Actions.DELETE);

  static PROGRAM_INDICATOR_ADD: string = this.build(Program.MODULE, Program.INDICATORS, Actions.ADD);

  static PROGRAM_INDICATOR_DELETE: string = this.build(Program.MODULE, Program.INDICATORS, Actions.DELETE);

  static PROGRAM_INDEX_ADD: string = this.build(Program.MODULE, Program.INDICES, Actions.ADD);

  static PROGRAM_INDEX_DELETE: string = this.build(Program.MODULE, Program.INDICES, Actions.DELETE);

  static PROGRAM_DOMAIN_KNOWLEDGES_ADD: string = this.build(Program.MODULE, Program.DOMAIN_KNOWLEDGES, Actions.ADD);

  static PROGRAM_DOMAIN_KNOWLEDGES_DELETE: string = this.build(Program.MODULE, Program.DOMAIN_KNOWLEDGES, Actions.DELETE);

  static PROGRAM_EVENT_VIEW: string = this.build(ProgramEvent.MODULE, Actions.VIEW);

  static PROGRAM_EVENT_ADD: string = this.build(ProgramEvent.MODULE, Actions.ADD);

  static PROGRAM_EVENT_EDIT: string = this.build(ProgramEvent.MODULE, Actions.EDIT);

  static PROGRAM_EVENT_DELETE: string = this.build(ProgramEvent.MODULE, Actions.DELETE);

  /** Конкурсы * */
  static TENDER_VIEW: string = this.build(Tender.MODULE, Actions.VIEW);

  static TENDER_ADD: string = this.build(Tender.MODULE, Actions.ADD);

  static TENDER_EDIT: string = this.build(Tender.MODULE, Actions.EDIT);

  static TENDER_DELETE: string = this.build(Tender.MODULE, Actions.DELETE);

  static LOT_VIEW: string = this.build(Lot.MODULE, Actions.VIEW);

  static LOT_ADD: string = this.build(Lot.MODULE, Actions.ADD);

  static LOT_EDIT: string = this.build(Lot.MODULE, Actions.EDIT);

  static LOT_DELETE: string = this.build(Lot.MODULE, Actions.DELETE);

  /** Заявки * */
  static PROGRAM_REQUEST_VIEW_ALL: string = this.build(ProgramRequest.MODULE, Actions.VIEW, 'All');

  static PROGRAM_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(ProgramRequest.MODULE, Actions.VIEW, 'ByManagement');

  static PROGRAM_REQUEST_ADD: string = this.build(ProgramRequest.MODULE, Actions.ADD);

  static PROGRAM_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_SENT_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_SENT_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_SENT_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_SENT_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_SENT_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_DELETE_DRAFT: string = this.build(ProgramRequest.MODULE, Actions.DELETE, RequestStatus.DRAFT);

  static PROGRAM_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.DRAFT,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.REQUEST,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_RECOMENDED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.RECOMENDED,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_SENT: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.SENT,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.REVISION,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.REJECTED,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_APPROVED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.APPROVED,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_TENDER_CANCELED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.TENDER_CANCELED,
  );

  static PROGRAM_REQUEST_AGREE: string = this.build(ProgramRequest.MODULE, Actions.AGREE);

  /** Блокировки проектов * */
  static PROJECT_LOCK_VIEW_LIST: string = this.build(ProjectLock.MODULE, Actions.VIEW, Midfixes.LIST);

  static PROJECT_LOCK_ADD: string = this.build(ProjectLock.MODULE, Actions.ADD);

  static PROJECT_LOCK_DELETE: string = this.build(ProjectLock.MODULE, Actions.DELETE);

  /** Госрегистрация проектов * */
  static PROJECT_STATE_REGISTRATION_VIEW_FULL_LIST: string = this.build(
    ProjectStateRegistration.MODULE,
    Actions.VIEW,
    Midfixes.FULL,
    Midfixes.LIST,
  );

  static PROJECT_STATE_REGISTRATION_VIEW_BY_DEPARTMENT: string = this.build(
    ProjectStateRegistration.MODULE,
    Actions.VIEW,
    'ByDepartment',
  );

  static PROJECT_STATE_REGISTRATION_VIEW_BY_MANAGEMENT: string = this.build(
    ProjectStateRegistration.MODULE,
    Actions.VIEW,
    'ByManagement',
  );

  /** Патентные исследования * */
  static PATENT_RESEARCH_VIEW: string = PatentResearch.MODULE + Actions.VIEW;

  static PATENT_RESEARCH_ADD: string = PatentResearch.MODULE + Actions.ADD;

  static PATENT_RESEARCH_EDIT: string = PatentResearch.MODULE + Actions.EDIT;

  static PATENT_RESEARCH_DELETE: string = PatentResearch.MODULE + Actions.DELETE;

  /** Охранные документы * */
  static PATENT_VIEW: string = SecurityDocument.MODULE_PATENT + Actions.VIEW;

  static PATENT_ADD: string = SecurityDocument.MODULE_PATENT + Actions.ADD;

  static PATENT_EDIT: string = SecurityDocument.MODULE_PATENT + Actions.EDIT;

  static PATENT_DELETE: string = SecurityDocument.MODULE_PATENT + Actions.DELETE;

  static EVIDENCE_VIEW: string = SecurityDocument.MODULE_EVIDENCE + Actions.VIEW;

  static EVIDENCE_ADD: string = SecurityDocument.MODULE_EVIDENCE + Actions.ADD;

  static EVIDENCE_EDIT: string = SecurityDocument.MODULE_EVIDENCE + Actions.EDIT;

  static EVIDENCE_DELETE: string = SecurityDocument.MODULE_EVIDENCE + Actions.DELETE;

  static KNOW_HOW_VIEW: string = SecurityDocument.MODULE_KNOW_HOW + Actions.VIEW;

  static KNOW_HOW_ADD: string = SecurityDocument.MODULE_KNOW_HOW + Actions.ADD;

  static KNOW_HOW_EDIT: string = SecurityDocument.MODULE_KNOW_HOW + Actions.EDIT;

  static KNOW_HOW_DELETE: string = SecurityDocument.MODULE_KNOW_HOW + Actions.DELETE;

  static NON_REGISTERED_VIEW: string = SecurityDocument.NON_REGISTERED + Actions.VIEW;

  static NON_REGISTERED_ADD: string = SecurityDocument.NON_REGISTERED + Actions.ADD;

  static NON_REGISTERED_EDIT: string = SecurityDocument.NON_REGISTERED + Actions.EDIT;

  static NON_REGISTERED_DELETE: string = SecurityDocument.NON_REGISTERED + Actions.DELETE;

  /** ОИС не аффилированные с Университетом * */

  static FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST: string = this.build(
    SecurityDocument.FOREIGN_SECURITY_DOCUMENT,
    Actions.VIEW,
    'FullList',
  );

  static FOREIGN_SECURITY_DOCUMENT_APPROVE: string = this.build(SecurityDocument.FOREIGN_SECURITY_DOCUMENT, Actions.APPROVE);

  /** Договоры на использование ИС * */
  static CONTRACT_VIEW: string = SecurityDocumentContract.MODULE + Actions.VIEW;

  static CONTRACT_ADD: string = SecurityDocumentContract.MODULE + Actions.ADD;

  static CONTRACT_EDIT: string = SecurityDocumentContract.MODULE + Actions.EDIT;

  static CONTRACT_DELETE: string = SecurityDocumentContract.MODULE + Actions.DELETE;

  static CONTRACT_PAYMENT_LOG_VIEW: string = 'ContractPaymentLogView';

  /** РИД * */
  static RNTD_VIEW: string = Rntd.MODULE + Actions.VIEW;

  static RNTD_ADD: string = Rntd.MODULE + Actions.ADD;

  static RNTD_EDIT: string = Rntd.MODULE + Actions.EDIT;

  static RNTD_DELETE: string = Rntd.MODULE + Actions.DELETE;

  /* Объявления */
  static NOTICE_SYSTEM_ADD: string = this.build(Notice.MODULE, MsaWorkbenches.SYSTEM, Actions.ADD);

  static NOTICE_SYSTEM_EDIT: string = this.build(Notice.MODULE, MsaWorkbenches.SYSTEM, Actions.EDIT);

  /* Полезные ссылки */
  static USEFUL_LINK_ADD: string = UsefulLink.MODULE + Actions.ADD;

  static USEFUL_LINK_LIST_EDIT: string = UsefulLink.MODULE + Midfixes.LIST + Actions.EDIT;

  /* Полезные ссылки */
  static HELPFUL_STUFF_ADD: string = HelpfulStuff.MODULE + Actions.ADD;

  static HELPFUL_STUFF_LIST_EDIT: string = HelpfulStuff.MODULE + Midfixes.LIST + Actions.EDIT;

  /**	Журналы * */
  static MAGAZINE_VIEW: string = Magazine.MODULE + Actions.VIEW;

  static MAGAZINE_ADD: string = Magazine.MODULE + Actions.ADD;

  static MAGAZINE_EDIT: string = Magazine.MODULE + Actions.EDIT;

  static MAGAZINE_DELETE: string = Magazine.MODULE + Actions.DELETE;

  static MAGAZINE_INFO_VIEW: string = Magazine.MODULE + Actions.INFO_VIEW;

  static MAGAZINE_APPROVE: string = Magazine.MODULE + Actions.APPROVE;

  static MAGAZINE_LIBRARY_FIELD_EDIT: string = this.build(Magazine.MODULE, 'Library', 'Field', Actions.EDIT);

  static MAGAZINE_LIBRARY_ID_EDIT: string = `${Magazine.MODULE}LibraryId${Actions.EDIT}`;

  static MAGAZINE_ARTICLES_EDIT: string = `${Magazine.MODULE}Articles${Actions.EDIT}`;

  static MAGAZINE_RELEASE_EDIT: string = `${Magazine.MODULE}Release${Actions.EDIT}`;

  static MAGAZINE_RELEASE_APPROVE: string = `${Magazine.MODULE}Release${Actions.APPROVE}`;

  static MAGAZINE_CITATION_SYSTEM_EDIT: string = Magazine.MODULE + Magazine.CITATION_SYSTEM + Actions.EDIT;

  static MAGAZINE_CITATION_SYSTEM_INDEX_EDIT: string = Magazine.MODULE + Magazine.CITATION_SYSTEM_INDEX + Actions.EDIT;

  static MAGAZINE_VAK_EDIT: string = Magazine.MODULE + Magazine.VAK + Actions.EDIT;

  /** Сборники */
  static COMPILATION_VIEW: string = Compilation.MODULE + Actions.VIEW;

  static COMPILATION_ADD: string = Compilation.MODULE + Actions.ADD;

  static COMPILATION_EDIT: string = Compilation.MODULE + Actions.EDIT;

  static COMPILATION_DELETE: string = Compilation.MODULE + Actions.DELETE;

  static COMPILATION_APPROVE: string = Compilation.MODULE + Actions.APPROVE;

  static COMPILATION_DEPARTMENTS_EDIT: string = `${Compilation.MODULE}Departments${Actions.EDIT}`;

  static COMPILATION_INFO_VIEW: string = Compilation.MODULE + Actions.INFO_VIEW;

  static COMPILATION_VIEW_IN_LIBRARY: string = `${Compilation.MODULE + Actions.VIEW}InLibrary`;

  static COMPILATION_EXPORT: string = Compilation.MODULE + Actions.EXPORT;

  static COMPILATION_ARTICLES_EDIT: string = `${Compilation.MODULE}Articles${Actions.EDIT}`;

  static COMPILATION_CITATION_SYSTEM_EDIT: string = Compilation.MODULE + Compilation.CITATION_SYSTEM + Actions.EDIT;

  static COMPILATION_CITATION_SYSTEM_INDEX_EDIT: string = Compilation.MODULE + Compilation.CITATION_SYSTEM_INDEX + Actions.EDIT;

  static COMPILATION_VAK_EDIT: string = Compilation.MODULE + Compilation.VAK + Actions.EDIT;

  /** Публикации * */
  static PUBLICATION_VIEW: string = Publication.MODULE + Actions.VIEW;

  static PUBLICATION_ADD: string = Publication.MODULE + Actions.ADD;

  static PUBLICATION_EDIT: string = Publication.MODULE + Actions.EDIT;

  static PUBLICATION_TRANSFORM: string = Publication.MODULE + Actions.TRANSFORM;

  static PUBLICATION_DELETE: string = Publication.MODULE + Actions.DELETE;

  static PUBLICATION_STRICTED_EDIT: string = `${Publication.MODULE}Stricted${Actions.EDIT}`;

  static PUBLICATION_DEPARTMENTS_EDIT: string = `${Publication.MODULE}Departments${Actions.EDIT}`;

  static PUBLICATION_CONFIRM: string = `${Publication.MODULE}Confirm`;

  static PUBLICATION_APPROVE: string = Publication.MODULE + Actions.APPROVE;

  static PUBLICATION_EXPORT: string = Publication.MODULE + Actions.EXPORT;

  static PUBLICATION_ARTICLE_TRANSLATION_EDIT: string = `${Publication.MODULE}ArticleTranslation${Actions.EDIT}`;

  static PUBLICATION_DOI_EDIT: string = `${Publication.MODULE}Doi${Actions.EDIT}`;

  static PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT: string = `${Publication.MODULE}ScienceAndMobileProject${Actions.EDIT}`;

  static PUBLICATION_SCIENCE_PROJECT_ACCEPTING: string = `${Publication.MODULE}ScienceProjectAccepting`;

  static PUBLICATION_CITATION_SYSTEM_EDIT: string = Publication.MODULE + Publication.CITATION_SYSTEM + Actions.EDIT;

  static PUBLICATION_CITATION_SYSTEM_INDEX_EDIT: string = Publication.MODULE + Publication.CITATION_SYSTEM_INDEX + Actions.EDIT;

  static PUBLICATION_INFO_VIEW: string = Publication.MODULE + Midfixes.INFO + Actions.VIEW;

  static PUBLICATION_FULL_DRAFT_LIST_VIEW: string =
    Publication.MODULE + Midfixes.FULL + RecordStatus.DRAFT_LOWER_CASE + Midfixes.LIST + Actions.VIEW;

  /** Список публикаций в каталоге Научной библиотеки" * */

  static PUBLICATION_LIBRARY_VIEW_LIST: string = this.build(Publication.MODULE, Publication.LIBRARY, Actions.VIEW, 'List');

  static PUBLICATION_LIBRARY_FIELD_EDIT: string = this.build(Publication.MODULE, Publication.LIBRARY, 'Field', Actions.EDIT);

  /** Журнал публикаций, отправленных в библиотеку * */
  static PUBLICATION_LIBRARY_LOG_VIEW_LIST: string = this.build(
    Publication.MODULE,
    Publication.LIBRARY,
    Publication.LOG,
    Actions.VIEW,
    'List',
  );

  static VIEW_PUBLICATION_IN_LIBRARY: string = `${Actions.VIEW + Publication.MODULE}InLibrary`;

  static PUBLICATION_LIBRARY_LOG_PROCESS: string = this.build(
    Publication.MODULE,
    Publication.LIBRARY,
    Publication.LOG,
    Actions.PROCESS,
  );

  /** МОБИЛЬНОСТЬ * */
  static MOBILE_REQUEST_VIEW: string = this.build(MobileRequest.MODULE, Actions.VIEW);

  static MOBILE_REQUEST_ADD: string = this.build(MobileRequest.MODULE, Actions.ADD);

  static MOBILE_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_REVISION_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_SENT_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_SENT_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_SENT_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_SENT_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_DELETE_DRAFT: string = this.build(MobileRequest.MODULE, Actions.DELETE, MobileRequestStatus.DRAFT);

  static MOBILE_REQUEST_AGREE: string = this.build(MobileRequest.MODULE, Actions.AGREE);

  static MOBILE_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.DRAFT,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.REQUEST,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_RECOMENDED: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.RECOMENDED,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_SENT: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.SENT,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.REVISION,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.REJECTED,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_APPROVED: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.APPROVED,
  );

  /* TenderProtocol Start */
  static TENDER_PROTOCOL_ADD: string = this.build(TenderProtocol.MODULE, Actions.ADD);

  /* Global view */
  static TENDER_PROTOCOL_VIEW_FULL_LIST: string = this.build(TenderProtocol.MODULE, Actions.VIEW, 'Full', 'List');

  static TENDER_PROTOCOL_VIEW_BY_COMISSION_MEMBERSHIP: string = this.build(
    TenderProtocol.MODULE,
    Actions.VIEW,
    'ByComissionMembership',
    'List',
  );

  /* Tabs view */
  static TENDER_PROTOCOL_VIEW_PROTOCOL: string = this.build(TenderProtocol.MODULE, Actions.VIEW, TenderProtocolTabs.PROTOCOL);

  static TENDER_PROTOCOL_VIEW_PROTOCOL_REQUESTS: string = this.build(
    TenderProtocol.MODULE,
    Actions.VIEW,
    TenderProtocolTabs.PROTOCOL_REQUESTS,
  );

  static TENDER_PROTOCOL_VIEW_APPROVER_REQUESTS: string = this.build(
    TenderProtocol.MODULE,
    Actions.VIEW,
    TenderProtocolTabs.APPROVER_REQUESTS,
  );

  static TENDER_PROTOCOL_VIEW_TENDER: string = this.build(TenderProtocol.MODULE, Actions.VIEW, TenderProtocolTabs.TENDER);

  static TENDER_PROTOCOL_VIEW_DOCUMENTS: string = this.build(TenderProtocol.MODULE, Actions.VIEW, TenderProtocolTabs.DOCUMENTS);

  /* Tabs edit */
  static TENDER_PROTOCOL_EDIT_PROTOCOL: string = this.build(TenderProtocol.MODULE, Actions.EDIT, TenderProtocolTabs.PROTOCOL);

  static TENDER_PROTOCOL_EDIT_DOCUMENTS: string = this.build(TenderProtocol.MODULE, Actions.EDIT, TenderProtocolTabs.DOCUMENTS);
  /* TenderProtocol End */

  static PROTOCOL_COMMISSION_VIEW: string = this.build(ProtocolCommission.MODULE, Actions.VIEW);

  static PROTOCOL_COMMISSION_ADD: string = this.build(ProtocolCommission.MODULE, Actions.ADD);

  static PROTOCOL_COMMISSION_EDIT: string = this.build(ProtocolCommission.MODULE, Actions.EDIT);

  static PROTOCOL_COMMISSION_DELETE: string = this.build(ProtocolCommission.MODULE, Actions.DELETE);

  static MOBILE_EXPENSE_NORMATIVE_VIEW: string = this.build(MobileExpenseNormative.MODULE, Actions.VIEW);

  static MOBILE_EXPENSE_NORMATIVE_ADD: string = this.build(MobileExpenseNormative.MODULE, Actions.ADD);

  static MOBILE_EXPENSE_NORMATIVE_EDIT: string = this.build(MobileExpenseNormative.MODULE, Actions.EDIT);

  static MOBILE_EXPENSE_NORMATIVE_DELETE: string = this.build(MobileExpenseNormative.MODULE, Actions.DELETE);

  static MOBILE_KIND_CONSUMPTION_VIEW: string = this.build(MobileKindConsumption.MODULE, Actions.VIEW);

  static MOBILE_KIND_CONSUMPTION_ADD: string = this.build(MobileKindConsumption.MODULE, Actions.ADD);

  static MOBILE_KIND_CONSUMPTION_EDIT: string = this.build(MobileKindConsumption.MODULE, Actions.EDIT);

  static MOBILE_KIND_CONSUMPTION_DELETE: string = this.build(MobileKindConsumption.MODULE, Actions.DELETE);

  /** Проекты * */
  static GOVERMENT_CONTRACT_VIEW_FULL_LIST: string = 'GovContractViewFullList';

  static GOVERMENT_CONTRACT_VIEW_BY_DEPARTMENT: string = 'GovContractViewByDepartment';

  static GOVERMENT_CONTRACT_VIEW_BY_MANAGEMENT: string = 'GovContractViewByManagement';

  static GOVERMENT_CONTRACT_EXECUTORS_APPROVED_ADD: string = 'GovContractExecutorsApprovedAdd';

  static GOVERMENT_CONTRACT_EXECUTORS_APPROVED_EDIT: string = 'GovContractExecutorsApprovedEdit';

  static GOVERMENT_CONTRACT_VIEW: string = 'GovContractView';

  static GOVERMENT_CONTRACT_ADD: string = 'GovContractAdd';

  static GOVERMENT_CONTRACT_EDIT: string = 'GovContractEdit';

  static GOVERMENT_CONTRACT_LOCKED_EDIT: string = 'ResearchJobLockedEdit';

  static GOVERMENT_CONTRACT_DELETE: string = 'GovContractDelete';

  static ECONOMIC_CONTRACT_VIEW_FULL_LIST: string = 'EconomicContractViewFullList';

  static ECONOMIC_CONTRACT_VIEW_BY_DEPARTMENT: string = 'EconomicContractViewByDepartment';

  static ECONOMIC_CONTRACT_VIEW_BY_MANAGEMENT: string = 'EconomicContractViewByManagement';

  static ECONOMIC_CONTRACT_ADD: string = 'EconomicContractAdd';

  static ECONOMIC_CONTRACT_VIEW: string = 'EconomicContractView';

  static ECONOMIC_CONTRACT_DELETE: string = 'EconomicContractDelete';

  static ECONOMIC_CONTRACT_LOCKED_EDIT: string = 'EconomicContractLockedEdit';

  static ECONOMIC_CONTRACT_EDIT: string = 'EconomicContractEdit';

  static GRANT_VIEW_FULL_LIST: string = 'GrantViewFullList';

  static GRANT_VIEW_BY_DEPARTMENT: string = 'GrantViewByDepartment';

  static GRANT_VIEW_BY_MANAGEMENT: string = 'GrantViewByManagement';

  static GRANT_VIEW: string = 'GrantView';

  static GRANT_ADD: string = 'GrantAdd';

  static GRANT_EDIT: string = 'GrantEdit';

  static GRANT_DELETE: string = 'GrantDelete';

  static GRANT_LOCKED_EDIT: string = 'GrantLockedEdit';

  static RESEARCH_JOB_VIEW: string = 'ResearchJobView';

  static RESEARCH_JOB_ADD: string = 'ResearchJobAdd';

  static RESEARCH_JOB_EDIT: string = 'ResearchJobEdit';

  static RESEARCH_JOB_DELETE: string = 'ResearchJobDelete';

  static RESEARCH_JOB_VIEW_FULL_LIST: string = 'ResearchJobViewFullList';

  static RESEARCH_JOB_VIEW_BY_DEPARTMENT: string = 'ResearchJobViewByDepartment';

  static RESEARCH_JOB_VIEW_BY_MANAGEMENT: string = 'ResearchJobViewByManagement';

  static ECONOMIC_CONTRACT_EXECUTORS_APPROVED_ADD: string = 'EconomicContractExecutorsApprovedAdd';

  static ECONOMIC_CONTRACT_EXECUTORS_APPROVED_EDIT: string = 'EconomicContractExecutorsApprovedEdit';

  static RESEARCH_JOB_EXECUTORS_APPROVED_ADD: string = 'ResearchJobExecutorsApprovedAdd';

  static RESEARCH_JOB_EXECUTORS_APPROVED_EDIT: string = 'ResearchJobExecutorsApprovedEdit';

  static GRANT_EXECUTORS_APPROVED_ADD: string = 'GrantExecutorsApprovedAdd';

  static GRANT_EXECUTORS_APPROVED_EDIT: string = 'GrantExecutorsApprovedEdit';

  static FOREIGN_PROJECT_VIEW_FULL_LIST: string = 'ForeignProject:View:FullList';

  static FOREIGN_PROJECT_APPROVE: string = 'ForeignProject:Approve';

  /** Финансирование * */
  /** Шифры * */
  static PROJECT_CODE_VIEW: string = ProjectCode.MODULE + Actions.VIEW;

  static PROJECT_CODE_ADD: string = ProjectCode.MODULE + Actions.ADD;

  static PROJECT_CODE_EDIT: string = ProjectCode.MODULE + Actions.EDIT;

  static PROJECT_CODE_DELETE: string = ProjectCode.MODULE + Actions.DELETE;

  static PROJECT_CODE_VIEW_DEPARTMENT: string = `${ProjectCode.MODULE + Actions.VIEW}Department`;

  static PROJECT_CODE_BY_DEPARTMENT: string = `${ProjectCode.MODULE + Actions.VIEW}ByDepartment`;

  static PROJECT_CODE_VIEW_CURATED_DEPARTMENT: string = `${ProjectCode.MODULE + Actions.VIEW}CuratedDepartment`;

  static PROJECT_CODE_VIEW_FULL_LIST: string = `${ProjectCode.MODULE + Actions.VIEW}FullList`;

  static PROJECT_CODE_VIEW_PROJECT_MANAGEMENT: string = `${ProjectCode.MODULE + Actions.VIEW}ByProjectManagement`;

  static PROJECT_CODE_VIEW_CODE_MANAGEMENT: string = `${ProjectCode.MODULE + Actions.VIEW}ByCodeManagement`;

  static PROJECT_CODE_FINANCING: string = `${ProjectCode.MODULE}Financing`;

  static PROJECT_CODE_PROCESSING_EDIT = 'ProjectCodeProcessingEdit';

  /** Сметы * */
  static ESTIMATE_BY_DEPARTMENT: string = `${Estimate.MODULE + Actions.VIEW}ByDepartment`;

  static ESTIMATE_VIEW_CURATED_DEPARTMENT: string = `${Estimate.MODULE + Actions.VIEW}CuratedDepartment`;

  static ESTIMATE_VIEW_FULL_LIST: string = `${Estimate.MODULE + Actions.VIEW}FullList`;

  static ESTIMATE_VIEW_PROJECT_MANAGEMENT: string = `${Estimate.MODULE + Actions.VIEW}ByProjectManagement`;

  static ESTIMATE_VIEW_CODE_MANAGEMENT: string = `${Estimate.MODULE + Actions.VIEW}ByCodeManagement`;

  static ESTIMATE_VIEW: string = Estimate.MODULE + Actions.VIEW;

  static ESTIMATE_ADD: string = Estimate.MODULE + Actions.ADD;

  static ESTIMATE_EDIT: string = Estimate.MODULE + Actions.EDIT;

  static ESTIMATE_DELETE: string = Estimate.MODULE + Actions.DELETE;

  static ESTIMATE_CHANGE_STATUS: string = `${Estimate.MODULE}ChangeStatus`;

  static ESTIMATE_EXECUTION_VIEW: string = `${Estimate.MODULE}ExecutionView`;

  static COSTS_MAGAZINE_VIEW: string = 'CostsMagazineView';

  /* Поступления */
  static NIR_FINANCING_BY_DEPARTMENT: string = `${NirFinancing.MODULE + Actions.VIEW}ByDepartment`;

  static NIR_FINANCING_VIEW_CURATED_DEPARTMENT: string = `${NirFinancing.MODULE + Actions.VIEW}CuratedDepartment`;

  static NIR_FINANCING_VIEW_FULL_LIST: string = `${NirFinancing.MODULE + Actions.VIEW}FullList`;

  static NIR_FINANCING_VIEW_PROJECT_MANAGEMENT: string = `${NirFinancing.MODULE + Actions.VIEW}ByProjectManagement`;

  static NIR_FINANCING_VIEW_CODE_MANAGEMENT: string = `${NirFinancing.MODULE + Actions.VIEW}ByCodeManagement`;

  static INCOMING_LOG_VIEW: string = 'IncomingLogView';

  /** Карточки ЦФ (централизованный фонд) * */
  static FUND_CARD_VIEW_LIST: string = FundCard.MODULE + Actions.VIEW + Midfixes.LIST;

  static FUND_CARD_ADD: string = FundCard.MODULE + Actions.ADD;

  static FUND_CARD_EDIT: string = FundCard.MODULE + Actions.EDIT;

  static FUND_CARD_DELETE: string = FundCard.MODULE + Actions.DELETE;

  static FUND_CARD_POSITIONS_VIEW: string = `${FundCard.MODULE}Positions${Actions.VIEW}`;

  /** Партнёры * */
  static PARTNER_VIEW: string = this.build(Partner.MODULE, Actions.VIEW);

  static PARTNER_ADD: string = this.build(Partner.MODULE, Actions.ADD);

  static PARTNER_EDIT: string = this.build(Partner.MODULE, Actions.EDIT);

  static PARTNER_DELETE: string = this.build(Partner.MODULE, Actions.DELETE);

  static PARTNER_CONTACT_ACCESS: string = this.build(Partner.MODULE, 'Contact', Actions.ACCESS);

  static PARTNER_DOCUMENT_ACCESS: string = this.build(Partner.MODULE, 'Document', Actions.ACCESS);

  static MIP_VIEW: string = Mip.MODULE + Actions.VIEW;

  static MIP_ADD: string = Mip.MODULE + Actions.ADD;

  static MIP_EDIT: string = Mip.MODULE + Actions.EDIT;

  static MIP_DELETE: string = Mip.MODULE + Actions.DELETE;

  static EXPONENT_VIEW: string = Exponent.MODULE + Actions.VIEW;

  static EXPONENT_ADD: string = Exponent.MODULE + Actions.ADD;

  static EXPONENT_EDIT: string = Exponent.MODULE + Actions.EDIT;

  static EXPONENT_DELETE: string = Exponent.MODULE + Actions.DELETE;

  /** Совместные с партнёрами мероприятия * */
  static PARTNER_EVENT_VIEW: string = this.build(Partner.MODULE, Partner.EVENT, Actions.VIEW);

  static PARTNER_EVENT_ADD: string = this.build(Partner.MODULE, Partner.EVENT, Actions.ADD);

  static PARTNER_EVENT_EDIT: string = this.build(Partner.MODULE, Partner.EVENT, Actions.EDIT);

  static PARTNER_EVENT_DELETE: string = this.build(Partner.MODULE, Partner.EVENT, Actions.DELETE);

  /** Сметы мероприятий * */
  static PARTNER_EVENT_ESTIMATE_VIEW_FULL_LIST: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Actions.VIEW,
    'FullList',
  );

  static PARTNER_EVENT_ESTIMATE_VIEW_BY_DEPARTMENT: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Actions.VIEW,
    'ByDepartment',
  );

  static PARTNER_EVENT_ESTIMATE_VIEW_BY_USER: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Actions.VIEW,
    'ByUser',
  );

  static PARTNER_EVENT_ESTIMATE_ADD: string = this.build(Partner.MODULE, Partner.EVENT, Partner.ESTIMATE, Actions.ADD);

  static PARTNER_EVENT_ESTIMATE_EDIT: string = this.build(Partner.MODULE, Partner.EVENT, Partner.ESTIMATE, Actions.EDIT);

  static PARTNER_EVENT_ESTIMATE_DELETE: string = this.build(Partner.MODULE, Partner.EVENT, Partner.ESTIMATE, Actions.DELETE);

  /** Виды расходов для смет мероприятий * */
  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_VIEW: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.VIEW,
  );

  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_ADD: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.ADD,
  );

  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_EDIT: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.EDIT,
  );

  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_DELETE: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.DELETE,
  );

  /** Заявки на этап проекта по конкурсу * */
  static CONTEST_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(ContestRequest.MODULE, Actions.VIEW, 'ByManagement');

  static CONTEST_REQUEST_VIEW_ALL: string = this.build(ContestRequest.MODULE, Actions.VIEW, Actions.ALL);

  static CONTEST_REQUEST_ADD: string = this.build(ContestRequest.MODULE, Actions.ADD);

  static CONTEST_REQUEST_EDIT_BASE: string = this.build(ContestRequest.MODULE, Actions.EDIT);

  static CONTEST_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_REVISION_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_REVISION_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_AGREED_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_AGREED_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_DELETE_DRAFT: string = this.build(ContestRequest.MODULE, Actions.DELETE, ContestRequestStatus.DRAFT);

  static CONTEST_REQUEST_AGREE: string = this.build(ContestRequest.MODULE, Actions.AGREE);

  static CONTEST_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.DRAFT,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.REQUEST,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.REVISION,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.REJECTED,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_AGREED: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.AGREED,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.AGREED_WITH_CONDITION,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_SUPPORTED: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.SUPPORTED,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.ACTUALIZATION,
  );

  static CONTEST_REQUEST_PUSH_FEEDLINE_MESSAGE: string = this.build(ContestRequest.MODULE, 'PushFeedlineMessage');

  /** Виды расходов для бухгалтерии * */
  static KIND_CONSUMPTION_VIEW: string = KindConsumption.MODULE + Actions.VIEW;

  static KIND_CONSUMPTION_ADD: string = KindConsumption.MODULE + Actions.ADD;

  static KIND_CONSUMPTION_EDIT: string = KindConsumption.MODULE + Actions.EDIT;

  static KIND_CONSUMPTION_DELETE: string = KindConsumption.MODULE + Actions.DELETE;

  /** Виды расходов для заявок на этап проекта по конкурсу * */
  static CONTEST_REQUEST_KIND_CONSUMPTION_VIEW: string = this.build(ContestRequestKindConsumption.MODULE, Actions.VIEW);

  static CONTEST_REQUEST_KIND_CONSUMPTION_ADD: string = this.build(ContestRequestKindConsumption.MODULE, Actions.ADD);

  static CONTEST_REQUEST_KIND_CONSUMPTION_EDIT: string = this.build(ContestRequestKindConsumption.MODULE, Actions.EDIT);

  static CONTEST_REQUEST_KIND_CONSUMPTION_DELETE: string = this.build(ContestRequestKindConsumption.MODULE, Actions.DELETE);

  /** Заявки на НИР и ОКР * */
  static NIR_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(NirRequest.MODULE, Actions.VIEW, 'ByManagement');

  static NIR_REQUEST_VIEW_ALL: string = this.build(NirRequest.MODULE, Actions.VIEW, Actions.ALL);

  static NIR_REQUEST_ADD: string = this.build(NirRequest.MODULE, Actions.ADD);

  static NIR_REQUEST_EDIT_AGREED_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_AGREED_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_AGREED_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_AGREED_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_AGREED_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_AGREED_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_AGREED_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_DRAFT_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_DRAFT_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_DRAFT_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_DRAFT_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_REJECTED_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_REJECTED_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_REJECTED_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_REJECTED_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_REQUEST_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_REQUEST_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_REQUEST_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_REQUEST_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_REVISION_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_REVISION_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_REVISION_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_REVISION_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_DELETE_DRAFT: string = this.build(NirRequest.MODULE, Actions.DELETE, NirRequestStatus.DRAFT);

  static NIR_REQUEST_AGREE: string = this.build(NirRequest.MODULE, Actions.AGREE);

  static NIR_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.DRAFT);

  static NIR_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.REQUEST);

  static NIR_REQUEST_CHANGE_STATUS_REVISION: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.REVISION);

  static NIR_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.REJECTED);

  static NIR_REQUEST_CHANGE_STATUS_AGREED: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.AGREED);

  static NIR_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION: string = this.build(
    NirRequest.MODULE,
    'ChangeStatus',
    NirRequestStatus.AGREED_WITH_CONDITION,
  );

  static NIR_REQUEST_CHANGE_STATUS_SUPPORTED: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.SUPPORTED);

  static NIR_REQUEST_PUSH_FEEDLINE_MESSAGE: string = this.build(NirRequest.MODULE, 'PushFeedlineMessage');

  /** Виды расходов для заявок НИР и ОКР * */
  static NIR_REQUEST_KIND_CONSUMPTION_VIEW: string = this.build(NirRequestKindConsumption.MODULE, Actions.VIEW);

  static NIR_REQUEST_KIND_CONSUMPTION_ADD: string = this.build(NirRequestKindConsumption.MODULE, Actions.ADD);

  static NIR_REQUEST_KIND_CONSUMPTION_EDIT: string = this.build(NirRequestKindConsumption.MODULE, Actions.EDIT);

  static NIR_REQUEST_KIND_CONSUMPTION_DELETE: string = this.build(NirRequestKindConsumption.MODULE, Actions.DELETE);

  static REQUEST_KIND_CONSUMPTION_VIEW: string = this.build(RequestKindConsumption.MODULE, Actions.VIEW);

  static REQUEST_KIND_CONSUMPTION_ADD: string = this.build(RequestKindConsumption.MODULE, Actions.ADD);

  static REQUEST_KIND_CONSUMPTION_EDIT: string = this.build(RequestKindConsumption.MODULE, Actions.EDIT);

  static REQUEST_KIND_CONSUMPTION_DELETE: string = this.build(RequestKindConsumption.MODULE, Actions.DELETE);

  /** Отчёты * */
  static REPORT_SYSTEM_ACCESS: string = this.build(Report.MODULE, MsaWorkbenches.SYSTEM, Report.ACCESS);

  static REPORT_PC_ACCESS: string = this.build(Report.MODULE, MsaWorkbenches.PC, Report.ACCESS);

  static REPORT_ADMIN_ACCESS: string = this.build(Report.MODULE, MsaWorkbenches.ADMIN, Report.ACCESS);

  /* Вопросы и ответы */
  static QNA_ADMINISTRATE: string = this.build(Qna.MODULE, Actions.ADMINISTRATE);

  /* Feedline Start */
  static PROGRAM_REQUEST_FEEDLINE_AGREE: string = this.build(ProgramRequest.MODULE, Feedline.MODULE, Actions.AGREE);

  static NIR_REQUEST_FEEDLINE_AGREE: string = this.build(NirRequest.MODULE, Feedline.MODULE, Actions.AGREE);

  static MOBILE_REQUEST_FEEDLINE_AGREE: string = this.build(MobileRequest.MODULE, Feedline.MODULE, Actions.AGREE);
  /* Feedline End */

  /** НПК * */
  static COMMUNITY_INFO_VIEW: string = `${CommunityInfo.MODULE}${Actions.VIEW}`;

  static COMMUNITY_APPROVE: string = `${Community.MODULE}${Actions.APPROVE}`;

  static ADMIN: string = 'admin';

  /** Аналитика * */
  static TOTAL_ANALYTICS_VIEW: string = TotalAnalytics.MODULE + Actions.VIEW;

  static INTELLECTUAL_PROPERTY_ANALYTICS_VIEW: string = IntellectualPropertyAnalytics.MODULE + Actions.VIEW;

  static GOV_CONTRACT_ANALYTICS_VIEW: string = GovContractAnalytics.MODULE + Actions.VIEW;

  static ECONOMIC_CONTRACT_ANALYTICS_VIEW: string = EconomicContractAnalytics.MODULE + Actions.VIEW;

  static RESEARCH_JOB_ANALYTICS_VIEW: string = ResearchJobAnalytics.MODULE + Actions.VIEW;

  static GRANT_ANALYTICS_VIEW: string = GrantAnalytics.MODULE + Actions.VIEW;

  /** Тематики * */

  static SUBJECT_VIEW: string = Subject.MODULE + Actions.VIEW;

  static SUBJECT_ADD: string = Subject.MODULE + Actions.ADD;

  static SUBJECT_EDIT: string = Subject.MODULE + Actions.EDIT;

  static SUBJECT_DELETE: string = Subject.MODULE + Actions.DELETE;

  /** Мероприятия с партнёрами * */
  static JOINT_EVENT_VIEW: string = this.build(JointEvent.MODULE, Actions.VIEW);

  static JOINT_EVENT_ADD: string = this.build(JointEvent.MODULE, Actions.ADD);

  static JOINT_EVENT_EDIT: string = this.build(JointEvent.MODULE, Actions.EDIT);

  static JOINT_EVENT_DELETE: string = this.build(JointEvent.MODULE, Actions.DELETE);

  /** Персоны * */

  static PERSON_VIEW: string = this.build(Person.MODULE, Actions.VIEW);

  static PERSON_ADD: string = this.build(Person.MODULE, Actions.ADD);

  static PERSON_EDIT: string = this.build(Person.MODULE, Actions.EDIT);

  static PERSON_DELETE: string = this.build(Person.MODULE, Actions.DELETE);

  /** Закупки * */

  static PURCHASE_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(PurchaseRequest.MODULE, Actions.VIEW, 'ByManagement');

  static PURCHASE_REQUEST_VIEW_ALL: string = this.build(PurchaseRequest.MODULE, Actions.VIEW, Actions.ALL);

  static PURCHASE_REQUEST_ADD: string = this.build(PurchaseRequest.MODULE, Actions.ADD);

  static PURCHASE_REQUEST_EDIT: string = this.build(PurchaseRequest.MODULE, Actions.EDIT);

  static PURCHASE_REQUEST_DELETE: string = this.build(PurchaseRequest.MODULE, Actions.DELETE);

  static PURCHASE_REQUEST_AGREE_MANAGEMENT_COMMITTEE: string = this.build(
    PurchaseRequest.MODULE,
    Actions.AGREE,
    PurchaseRequestApprover.MANAGEMENT_COMMITTEE,
  );

  static PURCHASE_REQUEST_AGREE_PRICE_COMMISSION: string = this.build(
    PurchaseRequest.MODULE,
    Actions.AGREE,
    PurchaseRequestApprover.PRICE_COMMISSION,
  );

  static PURCHASE_REQUEST_AGREE_LOGISTIC_MANAGEMENT: string = this.build(
    PurchaseRequest.MODULE,
    Actions.AGREE,
    PurchaseRequestApprover.LOGISTIC_MANAGEMENT,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.DRAFT,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_CONSIDERATION: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.CONSIDERATION,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.REVISION,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.REJECTED,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_IN_ACTION_PROVIDER: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.IN_ACTION_PROVIDER,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_COMPLETE: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.COMPLETE,
  );
  /** Зарплата коллективу / Зарплата исполнителям проектов * */

  static SALARY_FULL_ACCESS: string = this.build(Salary.MODULE, Midfixes.FULL, Actions.ACCESS);

  static ENCODING_SALARY_ACCESS: string = this.build(Salary.ENCODING, Salary.MODULE, Actions.ACCESS);

  static PROJECT_PERFORMER_ORDER_FULL_ACCESS: string = this.build(ProjectPerformerOrder.MODULE, Midfixes.FULL, Actions.ACCESS);

  /** Разблокировка заблокированных записей * */
  static FORCE_UNLOCK: string = 'ForceUnlock';

  static build(...parts: string[]): string {
    return parts.join(this.SEPARATOR);
  }
}
