import { useState, useLayoutEffect, useCallback } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { Form, Document } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { formatNumber } from 'utils/Helpers';

import { useFormContext } from 'features/Form/hooks';

import workModeHook from 'features/Form/hooks/workModeHook';
import { CalendarPlanByStage, CalendarPlanByYear, FinancingByYear, Performer, ProjectCode, Project } from 'types/models/Project';

import { Option, ReferenceItem } from 'components';
import { showNotification } from 'features/Notifications';
import { showErrorsMessages } from 'utils/Common';
import { ProjectCodeFormLook } from 'types/models/Form/look/projectCode';
import { getProjectCodeMock } from 'features/Form/looks/projectCode/ProjectCodeForm/helpers/getMockModels';
import { formatStr } from 'utils/Constants/FormatStr';
import { parse } from 'date-fns';
import { useAppDataContext } from 'features/AppData/context';
import { StagesTagnameUndistributed } from 'utils/Enums/StagesTagname';
import { validate } from './validate';
import { calculateNdsSumByYear } from './helpers/calculateNdsValues';
import { ProjectFinancingType } from 'utils/Enums';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, projectId, replaceMode },
  } = useFormContext<ProjectCodeFormLook>();
  const { initialOrganizationCode } = useAppDataContext();
  const isTSU = initialOrganizationCode?.code === 'TSU';

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [projectCode, setProjectCode] = useState<ProjectCode | null>(getProjectCodeMock(null));
  const [documents, setDocuments] = useState<Document[]>([]);
  const [calendarPlansByYear, setCalendarPlansByYear] = useState<CalendarPlanByYear[]>([]);
  const [financingsByYear, setFinancingsByYear] = useState<FinancingByYear[]>([]);
  const [calendarPlansByStages, setCalendarPlansByStages] = useState<CalendarPlanByStage[]>([]);
  const [financingTagnameByProjectType, setFinancingTagnameByProjectType] = useState<string | undefined>(undefined);

  // const fmtManualPart = useCallback((code: string) => {
  //   return code.length <= 1 ? `0${code}` : code;
  // }, []);

  const [formFields, setFormFields] = useState<Form.Fields>({
    department: {
      value: '',
      isValid: true,
      required: true,
      title: 'Подразделение',
      onChange: (option: any) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          department: {
            ...prevState.department,
            value: { id: option?.value || '', label: option.label },
          },
          codeDepartmentPart: {
            ...prevState.codeDepartmentPart,
            value: option?.customFields?.unit?.customFields?.code,
          },
        }));
      },
    },
    financing: {
      value: '',
      isValid: true,
      required: true,
      title: 'Источник финансирования',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          financing: {
            ...prevState.financing,
            value,
          },
        }));
      },
    },
    responsiblePerformer: {
      value: '',
      isValid: true,
      required: true,
      title: 'Отв. исполнитель',
      onChange: (value: Performer) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          responsiblePerformer: {
            ...prevState.responsiblePerformer,
            value,
          },
        }));
      },
    },
    // TSU
    letter: {
      value: '',
      isValid: true,
      required: true,
      title: 'Шифр',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          letter: {
            ...prevState.letter,
            value,
          },
          codeManualPart: {
            ...prevState.codeManualPart,
            value: null,
          },
        }));
      },
    },
    // TSU
    codeDepartmentPart: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          codeDepartmentPart: {
            ...prevState.codeDepartmentPart,
            value,
          },
        }));
      },
    },
    // TSU
    codeManualPart: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (val: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          codeManualPart: {
            ...prevState.codeManualPart,
            value: val,
          },
        }));
      },
    },
    // TSU
    codeYearPart: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          codeYearPart: {
            ...prevState.codeYearPart,
            value,
          },
        }));
      },
    },
    // SFU
    code: {
      value: '',
      isValid: true,
      required: true,
      title: 'Шифр',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: {
            ...prevState.code,
            value,
          },
        }));
      },
    },
    finAmount: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          finAmount: {
            ...prevState.finAmount,
            value,
          },
        }));
      },
    },
    isNds: {
      value: false,
      isValid: true,
      required: true,
      title: 'В т.ч. НДС',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isNds: {
            ...prevState.isNds,
            value: !prevState.isNds.value,
          },
        }));
      },
    },
    factAmount: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          factAmount: {
            ...prevState.factAmount,
            value,
          },
        }));
      },
    },
    financeProvide: {
      value: '',
      isValid: true,
      required: true,
      title: 'Вид фин. обеспечения',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          financeProvide: {
            ...prevState.financeProvide,
            value,
          },
        }));
      },
    },
    isOutsideBudget: {
      value: false,
      isValid: true,
      required: true,
      title: 'Использовать для ВБ',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isOutsideBudget: {
            ...prevState.isOutsideBudget,
            value: !prevState.isOutsideBudget.value,
          },
        }));
      },
    },
    isRefersToEconomicAgreement: {
      value: false,
      isValid: true,
      required: true,
      title: 'Относится к ХД',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isRefersToEconomicAgreement: {
            ...prevState.isRefersToEconomicAgreement,
            value: !prevState.isRefersToEconomicAgreement.value,
          },
        }));
      },
    },
    fundCard: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: any) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fundCard: {
            ...prevState.fundCard,
            value: value?.id ? { id: value.id, code: value.name } : null,
          },
        }));
      },
    },
  });

  const calculateNdsSummary = (projectCodeToCalc: ProjectCode) => {
    return (
      projectCodeToCalc?.project?.financingsByYear
        .map(value => formatNumber(calculateNdsSumByYear({ row: value, projectCode: projectCodeToCalc }).amount, 2))
        .reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.replace(' ', '')), 0)
        .toString() || ''
    );
  };

  const convertFinancingTypeToTagname = (val: string) => {
    switch (val) {
      case ProjectFinancingType.MAIN:
        return StagesTagnameUndistributed.MAIN;
      case ProjectFinancingType.LOCAL:
        return StagesTagnameUndistributed.LOCAL;
      case ProjectFinancingType.COFINANCING_1:
        return StagesTagnameUndistributed.COFINANCING_1;
      case ProjectFinancingType.COFINANCING_2:
        return StagesTagnameUndistributed.COFINANCING_2;
      case ProjectFinancingType.ACCOMPLICE:
        return StagesTagnameUndistributed.ACCOMPLICE;

      default:
        return '';
    }
  };

  const { methods: getProjectCode } = BackendAPI.useBackendAPI('GetProjectCode', {
    onSuccessfullCall: ({ data }) => {
      setProjectCode(prevState => (prevState?.id ? { ...data, id: prevState.id } : data));
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('department'), data.department),
          R.set(getValueLens('financing'), { id: data.financing?.id, label: data.financing?.source?.label }),
          workMode !== 'addMode'
            ? R.set(getValueLens('responsiblePerformer'), {
                label: data.responsiblePerformer?.person?.fullName,
                value: data.responsiblePerformer?.id,
              })
            : // @ts-ignore-next-line
              R.without({
                // @ts-ignore-next-line
                label: data.responsiblePerformer?.person?.fullName,
                // @ts-ignore-next-line
                value: data.responsiblePerformer?.id,
              }),
          // @ts-ignore-next-line
          workMode !== 'addMode' ? R.set(getValueLens('code'), data.code) : R.without('code'),
          // @ts-ignore-next-line
          workMode !== 'addMode' ? R.set(getValueLens('letter'), data.letter) : R.without(data.letter),
          R.set(getValueLens('codeDepartmentPart'), data.codeDepartmentPart),
          // @ts-ignore-next-line
          workMode !== 'addMode' ? R.set(getValueLens('codeManualPart'), data.codeManualPart) : R.without(data.codeManualPart),
          R.set(getValueLens('codeYearPart'), data.codeYearPart),
          R.set(getValueLens('isNds'), data.financing?.isNds),
          R.set(getValueLens('factAmount'), data ? formatNumber(calculateNdsSummary(data)) : ''),
          // @ts-ignore-next-line
          workMode !== 'addMode' ? R.set(getValueLens('financeProvide'), data.financeProvide) : R.without(data.financeProvide),
          // @ts-ignore-next-line
          workMode !== 'addMode' ? R.set(getValueLens('isOutsideBudget'), data.isOutsideBudget) : R.without(data.isOutsideBudget),
          workMode !== 'addMode'
            ? R.set(getValueLens('isRefersToEconomicAgreement'), data.isRefersToEconomicAgreement)
            : // @ts-ignore-next-line
              R.without(data.isRefersToEconomicAgreement),
          R.set(getValueLens('fundCard'), data.fundCard),
          R.set(getValueLens('codeDepartmentPart'), data.department?.unit?.customFields?.code || ''),
          R.set(
            getValueLens('codeYearPart'),
            data.project?.startDate
              ? parse(data.project.startDate, formatStr, new Date()).getFullYear().toString().substring(2, 4)
              : '',
          ),
        )(prevFields),
      );
      setDocuments(data?.documents || []);
      setCalendarPlansByYear(data?.calendarPlansByYears || []);
      setFinancingsByYear(data?.project?.financingsByYear || []);
      setCalendarPlansByStages(data?.calendarPlansByStages || []);
      setFinancingTagnameByProjectType(convertFinancingTypeToTagname(data?.financing?.type?.value ?? ''));
    },
    onFailedCall: () => {},
  });

  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject');

  const findPerformerByJobPeriodRole = useCallback(
    (role: string, targetProject?: Project | null) => {
      let primeLastJobPeriod: any = null;
      let lastPerformerByRole: any = null;
      const lookupProject = targetProject || projectCode?.project;
      if (lookupProject?.performers && Array.isArray(lookupProject?.performers)) {
        lookupProject.performers.forEach((performer: Performer) => {
          const jobPeriods = performer.jobPeriods.filter(period => period.role?.value === role);
          let lastJobPeriod: any = null;
          jobPeriods.forEach(period => {
            if (
              !lastJobPeriod ||
              parse(period.endDate, formatStr, new Date()).getTime() >
                parse(lastJobPeriod.endDate, formatStr, new Date()).getTime()
            ) {
              lastJobPeriod = period;
            }
          });
          if (lastJobPeriod) {
            if (!primeLastJobPeriod) {
              primeLastJobPeriod = lastJobPeriod;
              lastPerformerByRole = performer;
            } else if (
              parse(lastJobPeriod.endDate, formatStr, new Date()).getTime() >
              parse(primeLastJobPeriod.endDate, formatStr, new Date()).getTime()
            ) {
              primeLastJobPeriod = lastJobPeriod;
              lastPerformerByRole = performer;
            }
          }
        });
      }
      return lastPerformerByRole;
    },
    [projectCode],
  );

  const loadProject = useCallback(
    ({ loadProjectId }) => {
      getProject.callAPI(
        { id: loadProjectId },
        {
          onSuccessfullCall: ({ data }) => {
            setProjectCode(prevState => ({
              ...prevState,
              project: data,
            }));

            if (!projectCode?.id) {
              setProjectCode({
                ...projectCode,
                ...{ project: data, year: parse(data.startDate, formatStr, new Date()).getFullYear().toString() },
              });
              const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
              setFormFields(prevFields =>
                (R.pipe as any)(
                  R.set(
                    getValueLens('codeYearPart'),
                    parse(data.startDate, formatStr, new Date()).getFullYear().toString().substring(2, 4),
                  ),
                  R.set(getValueLens('responsiblePerformer'), {
                    ...findPerformerByJobPeriodRole('RESPONSIBLE_PERFORMER', data),
                  }),
                )(prevFields),
              );
              if (data?.documents) {
                setDocuments(data?.documents.map(doc => ({ ...doc, docType: 'foreign' })));
              }
            }
          },
        },
      );
    },
    [getProject, projectCode, findPerformerByJobPeriodRole],
  );

  const { methods: saveProjectCode } = BackendAPI.useBackendAPI('SaveProjectCode');
  const { methods: replaceProjectCode } = BackendAPI.useBackendAPI('ReplaceProjectCode');

  const onSubmit = useCallback(
    needClose => {
      const validationInfo = validate(formFields, isTSU);
      const errorMessages = [...validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage)];

      if (errorMessages.length) {
        showErrorsMessages(errorMessages);
        return;
      }
      const apiMethod = replaceMode ? replaceProjectCode : saveProjectCode;
      apiMethod.callAPI(
        {
          id: (editMode && id) || projectCode?.id || null,
          accompliceAmount: projectCode?.accompliceAmount || '',
          calendarPlansByStages: projectCode?.calendarPlansByStages || [],
          calendarPlansByYears: projectCode?.calendarPlansByYears || [],
          calendarPlansTotalAmount: projectCode?.calendarPlansTotalAmount,
          code: formFields.code?.value || '',
          codeDepartmentPart: formFields.codeDepartmentPart?.value,
          codeManualPart: formFields.codeManualPart?.value,
          codeYearPart: formFields.codeYearPart?.value,
          responsiblePerformer: { ...formFields.responsiblePerformer.value, id: formFields.responsiblePerformer.value?.value },
          department: formFields.department.value,
          documents,
          expenseFactAmount: projectCode?.expenseFactAmount || '',
          expensePlanAmount: projectCode?.expensePlanAmount || '',
          financeProvide: formFields.financeProvide.value,
          financing: {
            ...(projectCode?.financing || { id: formFields.financing?.value?.value }),
            isNds: formFields.isNds.value,
            factAmount: formFields.factAmount.value,
            source: formFields.financing.value,
            amount: formFields.finAmount.value,
          },
          fundCard: formFields.fundCard.value,
          incomeFactAmount: projectCode?.incomeFactAmount || '',
          incomePlanAmount: projectCode?.incomePlanAmount || '',
          isActive: projectCode?.isActive,
          isFundCardUsed: projectCode?.isFundCardUsed,
          isOutsideBudget: formFields.isOutsideBudget.value,
          isRefersToEconomicAgreement: formFields.isRefersToEconomicAgreement.value,
          letter: formFields.letter?.value,
          overheadFactAmount: projectCode?.overheadFactAmount || '',
          overheadPercent: projectCode?.overheadPercent || '',
          project: projectCode?.project,
          receivingDocuments: projectCode?.receivingDocuments,
          year: projectCode?.year || '',
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Шифр успешно сохранен', theme: 'success' });
            if (needClose || replaceMode) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data?.id) {
              getProjectCode.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      formFields,
      isTSU,
      replaceMode,
      replaceProjectCode,
      saveProjectCode,
      editMode,
      id,
      projectCode?.id,
      projectCode?.accompliceAmount,
      projectCode?.calendarPlansByStages,
      projectCode?.calendarPlansByYears,
      projectCode?.calendarPlansTotalAmount,
      projectCode?.expenseFactAmount,
      projectCode?.expensePlanAmount,
      projectCode?.financing,
      projectCode?.incomeFactAmount,
      projectCode?.incomePlanAmount,
      projectCode?.isActive,
      projectCode?.isFundCardUsed,
      projectCode?.overheadFactAmount,
      projectCode?.overheadPercent,
      projectCode?.project,
      projectCode?.receivingDocuments,
      projectCode?.year,
      documents,
      tableStreams.reloadTable,
      onClose,
      getProjectCode,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  useLayoutEffect(() => {
    if (id) {
      getProjectCode.callAPI({ id });
    } else if (projectId && workMode === 'addMode') {
      loadProject({ loadProjectId: projectId });
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    needClose => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  const handleFinPlanChange = useCallback(
    (value: CalendarPlanByYear[]) => {
      if (!formFields.financing.value?.id) {
        showNotification({ message: 'Выберите источник финансирования', theme: 'danger' });
        return;
      }
      getProjectCode.callAPI({
        projectCode: {
          ...projectCode,
          ...{
            calendarPlansByYears: value,
            financing: { ...formFields.financing.value, id: formFields.financing.value?.id },
            codeYearPart: formFields.codeYearPart?.value,
            year: `20${formFields.codeYearPart?.value}`,
          },
          prototypeId: projectCode?.id || '',
        },
      });
    },
    [formFields.financing.value, formFields.codeYearPart?.value, getProjectCode, projectCode],
  );

  const handleDocumentsChange = useCallback((docs: Document[]) => {
    setDocuments(docs);
  }, []);

  const handleFinancingSourceChange = (val: Option<string> | null) => {
    getProjectCode.callAPI({
      projectCode: {
        ...projectCode,
        ...{
          financing: { ...formFields.financing.value, id: val?.value || null },
          department: formFields.department.value,
        },
      },
    });
  };

  const { methods: generateProjectCodeAPI } = BackendAPI.useBackendAPI('GenerateProjectCode');

  const generateProjectCode = useCallback(() => {
    generateProjectCodeAPI.callAPI(
      {
        departmentCode: formFields.codeDepartmentPart?.value,
        letterId: formFields.letter?.value?.id,
        year: formFields.codeYearPart?.value,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            codeManualPart: {
              ...prevState.codeManualPart,
              value: data.length == 1 ? `0${data.toString()}` : data.toString(),
            },
          }));
        },
      },
    );
  }, [
    formFields.codeDepartmentPart?.value,
    formFields.codeYearPart?.value,
    formFields.letter?.value?.id,
    generateProjectCodeAPI,
  ]);

  // useEffect(() => {
  //   if (
  //     formFields.letter?.value?.id &&
  //     formFields.codeYearPart?.value &&
  //     formFields.codeDepartmentPart?.value &&
  //     !id &&
  //     !projectCode?.id
  //   )
  //     generateProjectCode();
  // eslint-disable-next-line react-hooks/exhaustive-deps, max-len
  // }, [formFields.codeDepartmentPart?.value, formFields.codeYearPart?.value, formFields.letter?.value?.id, id, projectCode?.id]);

  const { userPermission } = useAppDataContext();

  return {
    projectCodeId: id ?? null,
    projectCode,
    replaceMode,
    formFields,
    workMode,
    handleFormSubmit,
    handleFinPlanChange,
    handleDocumentsChange,
    documents,
    userPermission,
    findPerformerByJobPeriodRole,
    calendarPlansByYear,
    financingsByYear,
    calendarPlansByStages,
    handleFinancingSourceChange,
    setProjectCode,
    financingTagnameByProjectType,
    onSubmit,
    generateProjectCode,
  };
}
