import { ReferenceItem } from 'components';
import { makeStream } from 'StreamRx';

import { Event, Form as F } from 'types/models';
import { FormStreamData } from './types';
import { PublicationProject } from 'types/models/Form';

export const streams = {
  setFilter: makeStream<SetFilterData>(),
  requestFilterData: makeStream<SetFilterData>(),
  requestTableData: makeStream<RequestTableDataData>(),
  saveForm: makeStream<SaveFormData>(),
  loadForm: makeStream<LoadFormData>(),
  fetchRelease: makeStream<FetchRelease>(),
  saveRelease: makeStream<SaveRelease>(),
};

export type SetFilterData = FormStreamData<{
  columnName: string;
}>;

export type LoadFormData = FormStreamData<{
  currentId: string;
}>;

export type SaveFormData = FormStreamData<{
  formFields: F.Fields;
  publicationMessage?: F.PublicationMessage;
  publicationMessageType?: ReferenceItem;
  onSuccess: () => void;
  onError: () => void;
}>;

export type FetchRelease = FormStreamData<{
  releaseId?: string;
}>;

export type SaveRelease = FormStreamData<object>;

export type RequestTableDataData = FormStreamData<{
  offset: number;
  rowQuantity?: number;
  panelState?: any;
}>;

export const eventsStreams = {
  getEvent: makeStream<string>(),
  removeEvent: makeStream<string>(),
  setEvents: makeStream<Array<Event.Event>>(),
};

export const projectsStreams = {
  setProjects: makeStream<Array<PublicationProject>>(),
  accept: makeStream<string>(),
  cancelAcceptance: makeStream<string>(),
};

export const mobileRequestsStream = {
  setMobileRequests: makeStream<Array<F.PublicationMobileRequest>>(),
  accept: makeStream<string>(),
  cancelAcceptance: makeStream<string>(),
};
