import { Lot } from 'types/models/Tender';

import { Form } from 'types/models';
import { TenderRequestType } from 'utils/Enums';

export function validateContractAmounts(lots: Lot[]) {
  let isLimitAmountMatches = Boolean(
    lots.filter(
      lot =>
        parseFloat(String(lot.limitAmount)) !== parseFloat(String(lot.contractAmount)) * parseFloat(String(lot.contractCount)),
    ).length,
  );

  return [
    {
      isValid: !isLimitAmountMatches,
      invalidMessage: 'Итоговая Сумма всех контрактов не совпадает с Предельной ценой лота!',
    },
  ];
}
export function validateTender(lots: Lot[], formFields: Form.Fields, requestType: TenderRequestType) {
  const errorMessages: string[] = [
    requestType === TenderRequestType.NIR_REQUEST &&
      !(formFields.program.value?.id && formFields.programEvent.value?.id) &&
      'Для внутреннего конкурса должны быть указаны программа и мероприятие программы',
    !!formFields.isMarketplace.value && !formFields.type.value?.value && 'Выберите вид тендера',
    !formFields.name.value && 'Укажите название курса',
    !!formFields.isMarketplace.value && !formFields.kindTrade.value?.id && 'Выберите форму торгов',
    // !formFields.dateExamenation.value && 'Укажите дату рассмотрения заявок',
    !formFields.dateSummingUp.value && 'Укажите дату подведения итогов',
  ].filter(x => x) as string[];

  return [
    {
      isValid: !errorMessages.length,
      invalidMessage: errorMessages.join('. '),
    },
  ];
}
