import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useMemo } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Report } from 'types/models';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  viewMode?: boolean;
  isDisabled: boolean;
};

export function useController(props: Props) {
  const { onSave, onSaveDraft, viewMode, isDisabled } = props;

  const reports = useMemo<Report[]>(() => [Reports.EventConsolidatedListRequest, Reports.DepartmentConferenceReport], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        isDisabled: !!viewMode || isDisabled,
        onClick: () => {
          onSave(true);
        },
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        isDisabled: !!viewMode || isDisabled,
        onClick: () => {
          onSaveDraft();
        },
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [viewMode, isDisabled, getReports, handleSetCurrentReport, onSave, onSaveDraft],
  );
  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
