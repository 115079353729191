import * as R from 'ramda';
import * as o from 'rxjs/operators';
import { useContext } from 'react';

import { StreamRx } from 'StreamRx';

import { streams } from './streams';
import { GridContextType, GridContext } from './context';
import { APIIDHolder } from './types';

type TableStreams<T> = {
  [K in keyof T]: T[K] extends StreamRx<infer Data> ? StreamRx<{ [DK in keyof Omit<Data, 'apiID'>]: Data[DK] }> : never;
};

export const useTableContext = <CustomState extends {} = {}, RequestData extends {} = {}>() => {
  const context = useContext<GridContextType<CustomState, RequestData>>(GridContext);

  if (context === null) {
    throw Error('no context provided');
  }

  return context;
};

export const useLocalStreams = <T>(localStreams: T): TableStreams<T> => {
  const { specification } = useTableContext();
  const id = specification?.uniqID ?? specification?.apiID ?? '';
  return R.map((stream: StreamRx<APIIDHolder>): StreamRx<any> => {
    return {
      push: (data: any) => stream.push({ ...data, apiID: id }),
      observable: stream.observable.pipe(o.filter(data => data.apiID === id)),
    };
  }, localStreams as any) as any;
};

export const useLocalTableStreams = () => {
  return useLocalStreams(streams);
};
