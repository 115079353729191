import { isHasPermission, isHasSomePermission } from 'features/AppData';
import * as PageLayout from 'features/PageLayout';
import { specifications } from 'features/Table';

import { SecurityDocumentTypes, UserPermission } from 'types/models';

import { Permits } from 'utils/Permissions';

import { CommunityType, ProjectType, SubjectType } from 'utils/Enums';
import { MsaWorkbenches } from 'utils/Common/MsaWorkbenches';
import * as icons from './icons';
import * as pages from './pages';

import { makeContent, makeUnimplementedContent, makeMultitabContent } from './makeContent';

import { PrivatePage } from './PrivatePage';
import { makeScientistPage } from './ScientistsPage';
import { getEnumItemLabel } from 'utils/Helpers';
import { EnumMap } from 'types/models/Table';

const govermentContractPermissions = [
  Permits.GOVERMENT_CONTRACT_VIEW_FULL_LIST,
  Permits.GOVERMENT_CONTRACT_VIEW_BY_DEPARTMENT,
  Permits.GOVERMENT_CONTRACT_VIEW_BY_MANAGEMENT,
];

const economicContractPermissions = [
  Permits.ECONOMIC_CONTRACT_VIEW_FULL_LIST,
  Permits.ECONOMIC_CONTRACT_VIEW_BY_DEPARTMENT,
  Permits.ECONOMIC_CONTRACT_VIEW_BY_MANAGEMENT,
];

const researchJobPermissions = [
  Permits.RESEARCH_JOB_VIEW_FULL_LIST,
  Permits.RESEARCH_JOB_VIEW_BY_DEPARTMENT,
  Permits.RESEARCH_JOB_VIEW_BY_MANAGEMENT,
];

const stateRegistrationReportsPermissions = [
  Permits.PROJECT_STATE_REGISTRATION_VIEW_FULL_LIST,
  Permits.PROJECT_STATE_REGISTRATION_VIEW_BY_DEPARTMENT,
  Permits.PROJECT_STATE_REGISTRATION_VIEW_BY_MANAGEMENT,
];

const projectCodePermissions = [
  Permits.PROJECT_CODE_VIEW_FULL_LIST,
  Permits.PROJECT_CODE_BY_DEPARTMENT,
  Permits.PROJECT_CODE_VIEW_PROJECT_MANAGEMENT,
  Permits.PROJECT_CODE_VIEW_CODE_MANAGEMENT,
  Permits.PROJECT_CODE_VIEW_CURATED_DEPARTMENT,
];

const estimatePermissions = [
  Permits.ESTIMATE_VIEW_FULL_LIST,
  Permits.ESTIMATE_BY_DEPARTMENT,
  Permits.ESTIMATE_VIEW_PROJECT_MANAGEMENT,
  Permits.ESTIMATE_VIEW_CODE_MANAGEMENT,
  Permits.ESTIMATE_VIEW_CURATED_DEPARTMENT,
];

const nirFinancingPermissions = [
  Permits.NIR_FINANCING_VIEW_FULL_LIST,
  Permits.NIR_FINANCING_BY_DEPARTMENT,
  Permits.NIR_FINANCING_VIEW_PROJECT_MANAGEMENT,
  Permits.NIR_FINANCING_VIEW_CODE_MANAGEMENT,
  Permits.NIR_FINANCING_VIEW_CURATED_DEPARTMENT,
];

const grantPermissions = [Permits.GRANT_VIEW_FULL_LIST, Permits.GRANT_VIEW_BY_DEPARTMENT, Permits.GRANT_VIEW_BY_MANAGEMENT];

export function getEntries(userPermission: UserPermission | null, enums: EnumMap, shortName: string): PageLayout.TS.Entry[] {
  return [
    {
      kind: 'terminal',
      Icon: icons.Account,
      label: 'Личный кабинет',
      routeElement: 'account',
      Content: PrivatePage,
      isAccepted: isHasPermission(userPermission, MsaWorkbenches.PC),
    },
    {
      kind: 'with-subentries',
      Icon: icons.Publications,
      label: 'Публикации',
      routeElement: 'publications',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.PUBLICATION_INFO_VIEW,
        Permits.MAGAZINE_INFO_VIEW,
        Permits.COMPILATION_INFO_VIEW,
        Permits.VIEW_PUBLICATION_IN_LIBRARY,
        Permits.SCIENTIFIC_INDEX_AND_INDICATOR_EDIT,
      ]),
      subEntries: [
        {
          label: 'Публикации',
          routeElement: 'list',
          Content: makeContent(specifications.GetPublicationExtendedList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Журналы',
          routeElement: 'magazine-list',
          Content: makeContent(specifications.GetMagazineList),
          isAccepted: isHasPermission(userPermission, Permits.MAGAZINE_INFO_VIEW),
        },
        {
          label: 'Сборники',
          routeElement: 'compilation-list',
          Content: makeContent(specifications.GetCompilationList),
          isAccepted: isHasPermission(userPermission, Permits.COMPILATION_INFO_VIEW),
        },
        {
          label: 'Препринты',
          routeElement: 'preprint-list',
          Content: makeContent(specifications.GetPreprintPublicationList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Авторефераты и диссертации',
          routeElement: 'synopsis-dissertation-list',
          Content: makeContent(specifications.GetBaseDissertationPublicationList({})),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Публикации в СМИ',
          routeElement: 'mass-media-publications',
          Content: makeUnimplementedContent('Публикации в СМИ'),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Анализ эффективности по публикациям',
          routeElement: 'efficiency-analysis',
          Content: makeMultitabContent({
            specifications: [
              { title: 'Статьи в журналах', specification: specifications.GetDashboardDetailMagazineArticleList() },
              { title: 'Данные по журналам за 5 лет', specification: specifications.GetDashboardDetailMagazineList() },
              {
                title: 'Публикации Scopus, WoS по факультетам',
                specification: specifications.GetReportByDepartmentMappingPublicationList(),
              },
              {
                title: 'Публикации Scopus, WoS по проектам',
                specification: specifications.GetProjectScopusWosPublicationList(),
              },
            ],
          }),
        },
        {
          label: 'Справочник "Наукометрические показатели"',
          routeElement: 'scientificindices',
          Content: makeContent(specifications.GetScientificIndicesList),
          isAccepted: isHasPermission(userPermission, Permits.SCIENTIFIC_INDEX_AND_INDICATOR_EDIT),
        },
        {
          label: 'Аудит передачи публикаций в НБ',
          routeElement: 'library-publication-log-list',
          Content: makeContent(specifications.GetLibraryPublicationLogList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_LIBRARY_LOG_VIEW_LIST),
        },
        {
          label: 'Список публикаций в каталоге Научной библиотеки',
          routeElement: 'publication-library-list',
          Content: makeContent(specifications.GetPublicationLibraryList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_LIBRARY_VIEW_LIST),
        },
      ],
    },
    {
      kind: 'with-subentries',
      linesPerColumn: 4,
      Icon: icons.Activities,
      label: 'Мероприятия',
      routeElement: 'activities',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.CONTEST_LIST_VIEW,
        Permits.CONFERENCE_LIST_VIEW,
        Permits.EXPOSITION_LIST_VIEW,
        Permits.CONCERT_LIST_VIEW,
        Permits.PARTICIPATION_CONFERENCE_LIST_VIEW,
        Permits.PARTICIPATION_CONTEST_LIST_VIEW,
        Permits.PARTICIPATION_EXPOSITION_LIST_VIEW,
        Permits.PARTICIPATION_CONCERT_LIST_VIEW,
      ]),
      subEntries: [
        {
          label: 'Конференции',
          routeElement: 'conferences',
          Content: makeContent(specifications.GetConferenceEventList({ hasSelectButton: false })),
          isAccepted: isHasPermission(userPermission, Permits.CONFERENCE_LIST_VIEW),
        },
        {
          label: 'Олимпиады и конкурсы',
          routeElement: 'contests',
          isAccepted: isHasPermission(userPermission, Permits.CONTEST_LIST_VIEW),
          Content: makeContent(specifications.GetContestEventList({ hasSelectButton: false })),
        },
        {
          label: 'Выставки',
          routeElement: 'expositions',
          isAccepted: isHasPermission(userPermission, Permits.EXPOSITION_LIST_VIEW),
          Content: makeContent(specifications.GetExpositionEventList({ hasSelectButton: false })),
        },
        {
          label: 'Творческие мероприятия',
          routeElement: 'concerts',
          isAccepted: isHasPermission(userPermission, Permits.CONCERT_LIST_VIEW),
          Content: makeContent(specifications.GetConcertEventList),
        },
        {
          label: 'Участие в конференциях',
          routeElement: 'conferences-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_CONFERENCE_LIST_VIEW),
          Content: makeContent(specifications.GetConferenceParticipationList),
        },
        {
          label: 'Участие в олимпиадах и конкурсах',
          routeElement: 'contests-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_CONTEST_LIST_VIEW),
          Content: makeContent(specifications.GetContestParticipationList),
        },
        {
          label: 'Участие в выставках',
          routeElement: 'expositions-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_EXPOSITION_LIST_VIEW),
          Content: makeContent(specifications.GetExpositionParticipationList),
        },
        {
          label: 'Участие в творческих мероприятиях',
          routeElement: 'concert-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_CONCERT_LIST_VIEW),
          Content: makeContent(specifications.GetConcertParticipationList),
        },
      ],
    },
    {
      kind: 'with-subentries',
      Icon: icons.ActivityParticipations,
      label: 'Признания',
      routeElement: 'personal-achievments',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.RECOGNITION_INFO_VIEW,
        'HirschIndexView',
        Permits.COMMUNITY_INFO_VIEW,
      ]),
      subEntries: [
        {
          label: 'Признания и награды',
          routeElement: 'recognition',
          isAccepted: isHasPermission(userPermission, Permits.RECOGNITION_INFO_VIEW),
          Content: makeContent(specifications.GetRecognitionList),
        },
        {
          routeElement: 'scientist-statistics',
          label: 'Наукометрические показатели персон',
          isAccepted: isHasPermission(userPermission, 'HirschIndexView'),
          Content: makeContent(specifications.GetScientistStatistics({})),
        },
        {
          routeElement: 'community',
          label: 'Диссертационные советы',
          isAccepted: isHasSomePermission(userPermission, [Permits.COMMUNITY_INFO_VIEW]),
          Content: makeContent(
            specifications.GetDissertationCouncilList({
              communityTypes: [CommunityType.DISSERTATION_COUNCIL],
              title: 'Диссертационные советы',
            }),
          ),
        },
        {
          routeElement: 'scientific-communities',
          label: 'Научные сообщества и организации',
          isAccepted: isHasSomePermission(userPermission, [Permits.COMMUNITY_INFO_VIEW]),
          Content: makeContent(
            specifications.GetCommunityList({
              communityTypes: [
                CommunityType.SCIENTIFIC_COUNCIL,
                CommunityType.COLLABORATION,
                CommunityType.FUND,
                CommunityType.MAGAZINE_REDACTION,
              ],
              enumFilterValues: {
                enumName: 'CommunityType',
                excludeValues: [CommunityType.DISSERTATION_COUNCIL],
              },
            }),
          ),
        },
        {
          label: 'Членство в научных сообществах и организациях',
          routeElement: 'membership',
          // isAccepted: isHasPermission(userPermission, Permits.RECOGNITION_INFO_VIEW),
          Content: makeContent(specifications.GetCommunityMemberList({})),
        },
      ],
    },
    {
      kind: 'with-subentries',
      label: 'ОИС',
      Icon: icons.Activities,
      routeElement: 'intellectual-properties',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.PATENT_VIEW,
        Permits.EVIDENCE_VIEW,
        Permits.KNOW_HOW_VIEW,
        Permits.PATENT_RESEARCH_VIEW,
        Permits.CONTRACT_VIEW,
        Permits.CONTRACT_PAYMENT_LOG_VIEW,
      ]),
      subEntries: [
        {
          label: 'Патенты',
          routeElement: 'patent',
          isAccepted: isHasPermission(userPermission, Permits.PATENT_VIEW),
          Content: makeContent(
            specifications.GetSecurityDocumentList({
              documentType: SecurityDocumentTypes.PATENT,
            }),
          ),
        },
        {
          label: 'Свидетельства',
          routeElement: 'evidence',
          isAccepted: isHasPermission(userPermission, Permits.EVIDENCE_VIEW),
          Content: makeContent(
            specifications.GetSecurityDocumentList({
              documentType: SecurityDocumentTypes.EVIDENCE,
            }),
          ),
        },
        {
          label: 'Ноу-хау',
          routeElement: 'know-how',
          isAccepted: isHasPermission(userPermission, Permits.KNOW_HOW_VIEW),
          Content: makeContent(
            specifications.GetSecurityDocumentList({
              documentType: SecurityDocumentTypes.KNOW_HOW,
            }),
          ),
        },
        {
          label: 'Патентные исследования',
          routeElement: 'patent-research',
          isAccepted: isHasPermission(userPermission, Permits.PATENT_RESEARCH_VIEW),
          Content: makeContent(specifications.GetPatentResearchList),
        },
        {
          label: 'График оплаты пошлин',
          routeElement: 'securitydocument-payments',
          isAccepted: isHasSomePermission(userPermission, [Permits.PATENT_VIEW, Permits.EVIDENCE_VIEW]),
          Content: makeContent(specifications.GetSecurityDocumentPaymentList()),
        },
        {
          label: 'Объекты авторского права',
          routeElement: 'non-registered',
          isAccepted: isHasPermission(userPermission, Permits.NON_REGISTERED_VIEW),
          Content: makeContent(
            specifications.GetSecurityDocumentList({
              documentType: SecurityDocumentTypes.NON_REGISTERED,
            }),
          ),
        },
        {
          label: 'Аналитика по ОИС',
          routeElement: 'intellectual-property',
          isAccepted: isHasPermission(userPermission, Permits.INTELLECTUAL_PROPERTY_ANALYTICS_VIEW),
          Content: makeMultitabContent({
            specifications: [
              { title: 'Анализ по ОИС', specification: specifications.GetSecurityDocumentAnalyticList },
              { title: 'Анализ по авторам', specification: specifications.GetSecurityDocumentMemberAnalyticList },
            ],
          }),
        },
      ],
    },
    {
      kind: 'with-subentries',
      Icon: icons.Payments,
      label: 'Стимулирующие',
      routeElement: 'payments',
      isAccepted: isHasSomePermission(userPermission, [
        'IPArticleRequestsViewFullList',
        'IPArticleRequestsViewByDepartment',
        'IPMonographRequestsViewFullList',
        'IPMonographRequestsViewByDepartment',
        'IPTextBookRequestsViewFullList',
        'IPTextBookRequestsViewByDepartment',
        'IPPatentIssueRequestsViewFullList',
        'IPPatentIssueRequestsViewByDepartment',
        'IPPatentRequestsViewFullList', // Объекты ИС
        'IPPatentRequestsViewByDepartment', // Объекты ИС
        // 'IPExhibitRequestsViewFullList', Подготовка экспоната
        // 'IPExhibitRequestsViewByDepartment', Подготовка экспоната
        'IPExhibitionAwardRequestsViewFullList',
        'IPExhibitionAwardRequestsViewByDepartment',
        'IPConferenceRequestsViewFullList',
        'IPConferenceRequestsViewByDepartment',
        'IPFundViewFullList',
        'IPFundViewByDepartment',
        'IPPaymentTermPresetAdd',
        'IPPaymentTermPresetEdit',
        'IPPaymentTermPresetDelete',
      ]),
      subEntries: [
        {
          label: 'Статьи',
          routeElement: 'articles',
          isAccepted: isHasSomePermission(userPermission, ['IPArticleRequestsViewFullList', 'IPArticleRequestsViewByDepartment']),
          Content: makeContent(specifications.GetArticlePaymentRequests()),
        },
        {
          label: 'Монографии',
          routeElement: 'monograph',
          Content: makeContent(specifications.GetMonographPaymentRequests),
          isAccepted: isHasSomePermission(userPermission, [
            'IPMonographRequestsViewFullList',
            'IPMonographRequestsViewByDepartment',
          ]),
        },
        {
          label: 'Учебники и учебные пособия',
          routeElement: 'textbook',
          isAccepted: isHasSomePermission(userPermission, [
            'IPTextBookRequestsViewFullList',
            'IPTextBookRequestsViewByDepartment',
          ]),
          Content: makeContent(specifications.GetTextBookPaymentRequests),
        },
        // {
        //   label: 'Объекты ИС',
        //   routeElement: 'patent',
        //   isAccepted: isHasSomePermission(userPermission, [
        //     'IPPatentRequestsViewFullList',
        //     'IPPatentRequestsViewByDepartment',
        //   ]),
        //   Content: makeContent(specifications.GetPatentPaymentRequests),
        // },
        // {
        //   label: 'Подготовка экспоната',
        //   routeElement: 'exhibit',
        //   isAccepted: isHasSomePermission(userPermission, [
        //     'IPExhibitRequestsViewFullList',
        //     'IPExhibitRequestsViewByDepartment',
        //   ]),
        //   Content: makeContent(specifications.GetExhibitPaymentRequests),
        // },
        {
          label: 'Награды выставки',
          routeElement: 'exhibition-awards',
          isAccepted: isHasSomePermission(userPermission, [
            'IPExhibitionAwardRequestsViewFullList',
            'IPExhibitionAwardRequestsViewByDepartment',
          ]),
          Content: makeContent(specifications.GetExhibitionAwardsPaymentRequests()),
        },
        {
          label: 'Конференции',
          routeElement: 'conference',
          isAccepted: isHasSomePermission(userPermission, [
            'IPConferenceRequestsViewFullList',
            'IPConferenceRequestsViewByDepartment',
          ]),
          Content: makeContent(specifications.GetConferencePaymentRequests()),
        },
        {
          label: 'Объекты ИС',
          routeElement: 'inc-payments',
          isAccepted: isHasSomePermission(userPermission, ['IPPatentRequestsViewFullList', 'IPPatentRequestsViewByDepartment']),
          Content: makeContent(specifications.GetPatentPaymentRequests),
        },
        {
          label: 'Заявки на получение патента',
          routeElement: 'patent-issue',
          isAccepted: isHasSomePermission(userPermission, [
            'IPPatentIssueRequestsViewFullList',
            'IPPatentIssueRequestsViewByDepartment',
          ]),
          Content: makeContent(specifications.GetPatentIssuePaymentRequests),
        },
        {
          label: 'Фонд подразделений',
          routeElement: 'funds-departments',
          isAccepted: isHasSomePermission(userPermission, ['IPFundViewFullList', 'IPFundViewByDepartment']),
          Content: pages.FundsDepartments,
        },
        {
          label: 'Настройка',
          routeElement: 'terms',
          isAccepted: isHasSomePermission(userPermission, [
            'IPPaymentTermPresetAdd',
            'IPPaymentTermPresetEdit',
            'IPPaymentTermPresetDelete',
          ]),
          Content: makeContent(specifications.GetPaymentTermPresets),
        },
      ],
    },
    // {
    //   kind: 'terminal',
    //   Icon: icons.NIROKRApplications,
    //   label: 'Заявки на НИР и ОКР',
    //   isAccepted: isHasPermission(userPermission, 'PNRView'),
    //   routeElement: 'nir-okr-applications',
    //   Content: makeUnimplementedContent('Заявки на НИР и ОКР'),
    // },
    // {
    //   kind: 'terminal',
    //   Icon: icons.Projects,
    //   label: 'Проекты',
    //   routeElement: 'projects',
    //   Content: makeUnimplementedContent('Проекты'),
    // },
    // {
    //   kind: 'terminal',
    //   Icon: icons.Finances,
    //   label: 'Финансирование',
    //   routeElement: 'finances',
    //   Content: makeUnimplementedContent('Финансирование'),
    // },
    // {
    //   kind: 'terminal',
    //   Icon: icons.IntellectualProperty,
    //   label: 'Интеллектуальная собственность',
    //   routeElement: 'intellectual-property',
    //   Content: makeUnimplementedContent('Интеллектуальная собственность'),
    // },
    {
      kind: 'with-subentries',
      Icon: icons.Commercialization,
      label: 'Коммерциализация',
      routeElement: 'commercialization',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.SUBJECT_VIEW,
        Permits.MIP_VIEW,
        Permits.RNTD_VIEW,
        Permits.EXPONENT_VIEW,
        Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST,
      ]),
      subEntries: [
        {
          label: 'РИД',
          routeElement: 'rid',
          Content: makeContent(specifications.GetRntdList),
          isAccepted: isHasPermission(userPermission, Permits.RNTD_VIEW),
        },
        {
          label: 'МИП',
          routeElement: 'mip',
          Content: makeMultitabContent({
            specifications: [
              { title: 'Партнеры', specification: specifications.GetPartnerMipList() },
              { title: 'Показатели МИП', specification: specifications.GetPartnerMipIndicatorList },
            ],
          }),
          isAccepted: isHasPermission(userPermission, Permits.MIP_VIEW),
        },
        {
          label: 'Разработки (выставочные экспонаты)',
          routeElement: 'exponent',
          Content: makeContent(specifications.GetExponentList({})),
          isAccepted: isHasPermission(userPermission, Permits.EXPONENT_VIEW),
        },
        {
          Content: makeContent(specifications.GetSubjectList({ subjectType: SubjectType.SUBJECT })),
          label: 'Тематики',
          routeElement: 'subject',
          isAccepted: isHasSomePermission(userPermission, [Permits.SUBJECT_VIEW]),
        },
        {
          Content: makeContent(specifications.GetSubjectList({ subjectType: SubjectType.PRODUCT })),
          label: 'Продукты для коммерциализации',
          routeElement: 'product',
          isAccepted: isHasSomePermission(userPermission, [Permits.SUBJECT_VIEW]),
        },
        {
          Content: makeContent(specifications.GetForeignSecurityDocumentList({ shortName: shortName })),
          label: `Реестр ОИС не аффилированных с ${shortName}`,
          routeElement: 'foreign-security-document',
          isAccepted: isHasSomePermission(userPermission, [Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST]),
        },
        {
          label: 'Договоры о распоряжении интеллектуальным правом',
          routeElement: 'security-document-contracts',
          isAccepted: isHasPermission(userPermission, Permits.CONTRACT_VIEW),
          Content: makeContent(specifications.GetSecurityDocumentContractList({})),
        },
        {
          label: 'План-график поступления лицензионных платежей',
          routeElement: 'contract-payment-log',
          isAccepted: isHasPermission(userPermission, Permits.CONTRACT_PAYMENT_LOG_VIEW),
          Content: pages.ContractPayments,
        },
      ],
    },
    {
      kind: 'with-subentries',
      Icon: icons.Partners,
      label: 'Партнёры',
      routeElement: 'partners',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.PARTNER_VIEW,
        Permits.PARTNER_ADD,
        Permits.PARTNER_EDIT,
        Permits.PARTNER_DELETE,
        Permits.JOINT_EVENT_VIEW,
        Permits.JOINT_EVENT_ADD,
        Permits.JOINT_EVENT_EDIT,
        Permits.JOINT_EVENT_DELETE,
      ]),
      subEntries: [
        {
          label: 'Список организаций',
          routeElement: 'partners',
          Content: makeContent(specifications.GetPartnerList({ withMenu: true })),
          isAccepted: isHasSomePermission(userPermission, [
            Permits.PARTNER_VIEW,
            Permits.PARTNER_ADD,
            Permits.PARTNER_EDIT,
            Permits.PARTNER_DELETE,
          ]),
        },
        {
          label: 'Мероприятия с партнёрами',
          routeElement: 'joint-events',
          Content: makeContent(specifications.GetJointEventList()),
          isAccepted: isHasSomePermission(userPermission, [
            Permits.JOINT_EVENT_VIEW,
            Permits.JOINT_EVENT_ADD,
            Permits.JOINT_EVENT_EDIT,
            Permits.JOINT_EVENT_DELETE,
          ]),
        },
      ],
    },
    {
      kind: 'with-subentries',
      Icon: icons.Scientists,
      label: 'Поиск',
      routeElement: 'thematic-search',
      // TODO Уточнить права на доступ к пункту
      isAccepted: true,
      subEntries: [
        {
          Content: makeContent(specifications.GetThematicalSearchProjectStateRegistrationReportList),
          label: 'Архив отчётов по результатам научных исследований',
          routeElement: 'reports-archive',
          // TODO Уточнить права на доступ к пункту
          isAccepted: true,
        },
        {
          Content: makeContent(specifications.GetPersonalAllProjectList({})),
          label: 'Список научных проектов',
          isAccepted: true,
          // TODO Уточнить права на доступ к пункту
          routeElement: 'scientist-projects-list',
        },
        {
          Content: makeContent(specifications.GetPersonalAllSecurityDocumentList({})),
          label: 'Объекты интеллектуальной собственности',
          isAccepted: true,
          // TODO Уточнить права на доступ к пункту
          routeElement: 'intellectual-properties-list',
        },
        {
          Content: makeContent(specifications.GetScientistStatistics({ isSearchMode: true })),
          label: 'Поиск персон по научным интересам',
          isAccepted: true,
          // TODO Уточнить права на доступ к пункту
          routeElement: 'scientist-statistics',
        },
      ],
    },
    // {
    //   kind: 'terminal',
    //   Icon: icons.Mobility,
    //   label: 'Мобильность',
    //   routeElement: 'mobility',
    //   Content: makeUnimplementedContent('Мобильность'),
    // },
    // {
    //   kind: 'terminal',
    //   Icon: icons.BusinessTrips,
    //   label: 'Командировки',
    //   routeElement: 'business-trips',
    //   Content: makeUnimplementedContent('Командировки'),
    // },
    // {
    //   kind: 'terminal',
    //   Icon: icons.Hardware,
    //   label: 'Оборудование',
    //   routeElement: 'hardware',
    //   Content: makeUnimplementedContent('Оборудование'),
    // },
    // {
    //   kind: 'terminal',
    //   Icon: icons.Analytics,
    //   label: 'Аналитика',
    //   routeElement: 'analytics',
    //   Content: makeUnimplementedContent('Аналитика'),
    // },

    {
      kind: 'with-subentries',
      Icon: icons.Scientists,
      label: 'Заявки',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.PROGRAM_VIEW,
        Permits.TENDER_VIEW,
        Permits.PROGRAM_REQUEST_VIEW_ALL,
        Permits.PROGRAM_REQUEST_VIEW_BY_MANAGEMENT,
        Permits.CONTEST_REQUEST_VIEW_BY_MANAGEMENT,
        Permits.CONTEST_REQUEST_VIEW_ALL,
        Permits.MOBILE_REQUEST_VIEW,
      ]),
      routeElement: 'requests',
      subEntries: [
        {
          label: 'Заявки на конкурс',
          routeElement: 'program-requests',
          Content: makeContent(specifications.GetRequestList({ hasSelectButton: false })),
          isAccepted: isHasSomePermission(userPermission, [
            Permits.PROGRAM_REQUEST_VIEW_ALL,
            Permits.PROGRAM_REQUEST_VIEW_BY_MANAGEMENT,
          ]),
        },
        {
          label: 'Заявки на внутренний конкурс',
          routeElement: 'nir-requests',
          isAccepted: isHasSomePermission(userPermission, [
            'NirRequest:View:ByManagement',
            'NirRequest:View:All',
            'NirRequest:Add',
            'NirRequest:Delete:DRAFT',
            'NirRequest:Agree',
            'NirRequest:PushFeedlineMessage',
          ]),
          Content: makeContent(specifications.GetNirRequestList({ isForProject: false, hasSelectButton: false })),
        },
        {
          label: 'Заявки по академической мобильности',
          routeElement: 'mobile-requests',
          isAccepted: isHasPermission(userPermission, Permits.MOBILE_REQUEST_VIEW),
          Content: makeContent(specifications.GetMobileRequestList({ type: 'NPR' })),
        },
        {
          routeElement: 'contest-requests',
          label: 'Заявки для актуализации проектов',
          Content: makeContent(specifications.GetContestRequestList()),
          isAccepted: isHasSomePermission(userPermission, [
            Permits.CONTEST_REQUEST_VIEW_BY_MANAGEMENT,
            Permits.CONTEST_REQUEST_VIEW_ALL,
          ]),
        },
        {
          label: 'Программы',
          routeElement: 'programs',
          isAccepted: isHasPermission(userPermission, Permits.PROGRAM_VIEW),
          Content: makeContent(specifications.GetProgramList({ hasSelectButton: false })),
        },
        {
          label: 'Конкурсы',
          routeElement: 'tenders',
          isAccepted: isHasPermission(userPermission, Permits.TENDER_VIEW),
          Content: makeContent(specifications.GetTenderList()),
        },
        {
          label: 'Список участников заявок на конкурсы',
          routeElement: 'request-performers',
          isAccepted: isHasPermission(userPermission, Permits.TENDER_VIEW),
          Content: makeContent(specifications.GetRequestPerformerList()),
        },
        {
          label: 'Конкурсные комиссии',
          routeElement: 'protocol-commissions',
          isAccepted: isHasPermission(userPermission, Permits.PROTOCOL_COMMISSION_VIEW),
          Content: makeContent(specifications.GetProtocolCommissionList({})),
        },
        {
          label: 'Экспертиза заявок',
          routeElement: 'protocols',
          isAccepted: isHasSomePermission(userPermission, [
            Permits.TENDER_PROTOCOL_VIEW_FULL_LIST,
            Permits.TENDER_PROTOCOL_VIEW_BY_COMISSION_MEMBERSHIP,
          ]),
          Content: makeContent(specifications.GetTenderProtocolList()),
        },
      ],
    },
    {
      kind: 'with-subentries',
      Icon: icons.Projects,
      label: 'Проекты',
      routeElement: 'projects',
      isAccepted: isHasSomePermission(userPermission, [
        ...researchJobPermissions,
        ...govermentContractPermissions,
        ...economicContractPermissions,
        ...grantPermissions,
        ...stateRegistrationReportsPermissions,
        Permits.FOREIGN_PROJECT_VIEW_FULL_LIST,
        Permits.TOTAL_ANALYTICS_VIEW,
      ]),
      subEntries: [
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.NIR, enums)}`,
          routeElement: 'nir-projects',
          Content: makeContent(specifications.GetProjectList(enums)),
          isAccepted: isHasSomePermission(userPermission, researchJobPermissions),
        },
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.GRANT, enums)}`,
          routeElement: 'grants',
          Content: makeContent(specifications.GetGrantList(enums)),
          isAccepted: isHasSomePermission(userPermission, grantPermissions),
        },
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enums)}`,
          routeElement: 'goverment-contracts',
          Content: makeContent(specifications.GetGovermentContractsList(enums)),
          isAccepted: isHasSomePermission(userPermission, govermentContractPermissions),
        },
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.ECONOMIC_AGREEMENT, enums)}`,
          routeElement: 'economic-contracts',
          Content: makeContent(specifications.GetEconomicAgreementsList(enums)),
          isAccepted: isHasSomePermission(userPermission, economicContractPermissions),
        },
        {
          label: 'Госрегистрация проектов и отчетов',
          routeElement: 'state-registration',
          isAccepted: isHasSomePermission(userPermission, stateRegistrationReportsPermissions),
          Content: makeMultitabContent({
            specifications: [
              { title: 'Проекты', specification: specifications.GetProjectStateRegistrationList },
              { title: 'Отчеты', specification: specifications.GetProjectStateRegistrationReportList },
            ],
          }),
        },
        {
          label: 'Аналитика по всем типам проектов',
          routeElement: 'analytics',
          isAccepted: isHasPermission(userPermission, Permits.TOTAL_ANALYTICS_VIEW),
          Content: makeContent(specifications.GetAnalyticProjectList),
        },
        {
          label: 'Проекты сотрудников, выполненные в других организациях',
          routeElement: 'foreign',
          isAccepted: isHasPermission(userPermission, Permits.FOREIGN_PROJECT_VIEW_FULL_LIST),
          Content: makeContent(specifications.GetOtherProjectList()),
        },
        {
          label: 'Приказы',
          routeElement: 'project-orders',
          isAccepted: isHasPermission(userPermission, Permits.PROJECT_PERFORMER_ORDER_FULL_ACCESS),
          Content: makeMultitabContent({
            specifications: [
              { title: 'Приказы', specification: specifications.GetProjectPerformerOrderList({ projectSelectable: true }) },
              { title: 'Список участников проектов', specification: specifications.GetProjectPerformerJobPeriodList() },
            ],
          }),
        },
      ],
    },
    {
      kind: 'with-subentries',
      label: 'Закупки',
      Icon: icons.References,
      routeElement: 'purchases',
      isAccepted: isHasSomePermission(userPermission, [
        Permits.PURCHASE_REQUEST_VIEW_ALL,
        Permits.PURCHASE_REQUEST_VIEW_BY_MANAGEMENT,
      ]),
      subEntries: [
        {
          label: 'Список заявок на закупки',
          routeElement: 'purchase-request',
          Content: makeContent(specifications.GetPurchaseRequestList({ withTemplatesPanel: true, projectSelectable: true })),
        },
        {
          label: 'Список всех товаров, услуг, работ из заявок',
          routeElement: 'purchase-request-unit',
          Content: makeContent(specifications.GetPurchaseRequestUnitList({ withTemplatesPanel: true })),
        },
      ],
    },
    {
      kind: 'with-subentries',
      label: 'Финансы',
      Icon: icons.Activities,
      routeElement: 'finance',
      isAccepted: isHasSomePermission(userPermission, [
        ...projectCodePermissions,
        ...estimatePermissions,
        ...nirFinancingPermissions,
        Permits.INCOMING_LOG_VIEW,
        Permits.COSTS_MAGAZINE_VIEW,
      ]),
      subEntries: [
        {
          label: 'Шифры проектов',
          routeElement: 'project-codes',
          isAccepted: isHasSomePermission(userPermission, projectCodePermissions),
          Content: makeContent(specifications.GetProjectCodeList),
        },
        {
          label: 'Поступления',
          routeElement: 'receipts',
          isAccepted: isHasSomePermission(userPermission, nirFinancingPermissions),
          Content: pages.Receipts,
        },
        {
          label: 'Сметы',
          routeElement: 'estimations',
          isAccepted: isHasSomePermission(userPermission, estimatePermissions),
          Content: makeContent(specifications.GetEstimateList({ projectCode: { id: '0' } })),
        },
        {
          label: 'Централизованный фонд',
          routeElement: 'fundcards',
          isAccepted: isHasPermission(userPermission, Permits.FUND_CARD_VIEW_LIST),
          // Content: makeContent(specifications.GetFundCardList()),
          Content: makeMultitabContent({
            specifications: [
              {
                title: 'Шифры Централизованного фонда',
                specification: specifications.GetFundCardList(),
              },
              {
                title: 'Распределение проектов по шифрам Централизованного фонда',
                specification: specifications.GetProjectCodeOverheadList,
              },
            ],
          }),
        },
        {
          label: 'Реестр приходных документов',
          routeElement: 'receive-docs',
          isAccepted: isHasPermission(userPermission, Permits.INCOMING_LOG_VIEW),
          Content: makeContent(specifications.GetReceivingDocumentsLog),
        },
        {
          label: 'Реестр расходных документов',
          routeElement: 'expense-docs',
          isAccepted: isHasPermission(userPermission, Permits.COSTS_MAGAZINE_VIEW),
          Content: makeContent(specifications.GetExpenseLogList),
        },

        {
          label: 'Виды расходов для заявок на внутренние конкурсы',
          routeElement: 'expense-nir-accounting',
          isAccepted: isHasPermission(userPermission, Permits.NIR_REQUEST_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetNirRequestKindConsumptionList({})),
        },
        {
          label: 'Виды расходов для заявок на конкурсы',
          routeElement: 'expense-accounting',
          isAccepted: isHasPermission(userPermission, Permits.NIR_REQUEST_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetRequestKindConsumptionList({})),
        },
        {
          label: 'Виды расходов для заявок по академической мобильности',
          routeElement: 'expense-mobile',
          isAccepted: isHasPermission(userPermission, Permits.MOBILE_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetMobileKindConsumptionList()),
        },
        {
          label: 'Виды расходов для заявок на актуализацию проекта',
          routeElement: 'expense-contest-request',
          isAccepted: isHasPermission(userPermission, Permits.CONTEST_REQUEST_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetContestRequestKindConsumptionList()),
        },
        {
          label: 'Виды расходов из бухгалтерии',
          routeElement: 'expense-kind-consumption',
          isAccepted: isHasPermission(userPermission, Permits.KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetKindConsumptionList({})),
        },
        {
          label: 'Оплата коллективам',
          routeElement: 'salary',
          isAccepted: isHasPermission(userPermission, Permits.SALARY_FULL_ACCESS),
          Content: makeMultitabContent({
            specifications: [
              { title: 'Оплата коллективам', specification: specifications.GetSalaryList() },
              {
                title: 'Список участников проектов',
                specification: specifications.GetProjectPerformerJobPeriodList({ title: 'Список участников проектов' }),
              },
            ],
          }),
        },
      ],
    },
    /* Hidden for all (temporary) */
    // {
    //   kind: 'with-subentries',
    //   label: 'Аналитика',
    //   Icon: icons.Activities,
    //   routeElement: 'analytics',
    //   isAccepted: isHasSomePermission(userPermission, [
    //     Permits.TOTAL_ANALYTICS_VIEW,
    //     Permits.INTELLECTUAL_PROPERTY_ANALYTICS_VIEW,
    //     Permits.GOV_CONTRACT_ANALYTICS_VIEW,
    //     Permits.ECONOMIC_CONTRACT_ANALYTICS_VIEW,
    //     Permits.RESEARCH_JOB_ANALYTICS_VIEW,
    //     Permits.GRANT_ANALYTICS_VIEW,
    //   ]),
    //   subEntries: [
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enums),
    //       routeElement: 'goverment-contracts',
    //       isAccepted: isHasPermission(userPermission, Permits.GOV_CONTRACT_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enums),
    //             specification: specifications.GetGosContractProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetGosContractReportList },
    //           { title: 'Характеристика', specification: specifications.GetGosContactCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetGosContactThematicalSearchList },
    //           // { title: 'Реестр заявок', specification: specifications.GetGosContractRequestList },
    //         ],
    //       }),
    //     },
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.ECONOMIC_AGREEMENT, enums),
    //       routeElement: 'economic-agreements',
    //       isAccepted: isHasPermission(userPermission, Permits.ECONOMIC_CONTRACT_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.ECONOMIC_AGREEMENT, enums),
    //             specification: specifications.GetHozDogovorProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetHozDogovorReportList },
    //           { title: 'Характеристика', specification: specifications.GetHozDogovorCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetHozDogovorThematicalSearchList },
    //         ],
    //       }),
    //     },
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.NIR, enums),
    //       routeElement: 'nir',
    //       isAccepted: isHasPermission(userPermission, Permits.RESEARCH_JOB_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.NIR, enums),
    //             specification: specifications.GetNirProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetNirReportList },
    //           { title: 'Характеристика', specification: specifications.GetNirCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetNirThematicalSearchList },
    //         ],
    //       }),
    //     },
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.GRANT, enums),
    //       routeElement: 'grants',
    //       isAccepted: isHasPermission(userPermission, Permits.GRANT_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.GRANT, enums),
    //             specification: specifications.GetGrantProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetGrantReportList },
    //           { title: 'Характеристика', specification: specifications.GetGrantCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetGrantThematicalSearchList },
    //         ],
    //       }),
    //     },
    //   ],
    // },
    {
      kind: 'with-subentries',
      Icon: icons.Scientists,
      label: 'Персоны',
      routeElement: 'scientists',
      subEntries: [
        {
          Content: makeScientistPage(),
          label: 'Справочник "Персоны"',
          routeElement: 'detailed-scientist-list',
        },
        {
          Content: makeContent(specifications.GetScientistDuplicateList),
          label: 'Дубликаты персон',
          isAccepted: isHasPermission(userPermission, Permits.PERSON_DUPLICATES_PROCESS),
          routeElement: 'scientist-duplicate-list',
        },
      ],
    },

    // {
    //   kind: 'with-subentries',
    //   label: 'ОИС',
    //   Icon: icons.Activities,
    //   routeElement: 'intellectual-properties',
    //   isAccepted: isHasSomePermission(userPermission, [
    //     Permits.PATENT_RESEARCH_VIEW,
    //     Permits.PATENT_VIEW,
    //     Permits.EVIDENCE_VIEW,
    //     Permits.KNOW_HOW_VIEW,
    //     Permits.CONTRACT_VIEW,
    //   ]),
    //   subEntries: [
    //     {
    //       label: 'Патенты',
    //       routeElement: 'patent',
    //       isAccepted: isHasSomePermission(userPermission, [
    //         Permits.PATENT_VIEW,
    //         Permits.PATENT_ADD,
    //         Permits.PATENT_EDIT,
    //         Permits.PATENT_DELETE,
    //       ]),
    //       Content: makeContent(
    //         specifications.GetSecurityDocumentList({
    //           documentType: SecurityDocumentTypes.PATENT,
    //           activeRequests: 'false',
    //           activeSecurityDocuments: 'false',
    //         }),
    //       ),
    //     },
    //     {
    //       label: 'Свидетельства',
    //       routeElement: 'evidence',
    //       Content: makeContent(
    //         specifications.GetSecurityDocumentList({
    //           documentType: SecurityDocumentTypes.EVIDENCE,
    //           activeRequests: 'false',
    //           activeSecurityDocuments: 'false',
    //         }),
    //       ),
    //     },
    //     {
    //       label: 'Ноу-хау',
    //       routeElement: 'know-how',
    //       Content: makeContent(
    //         specifications.GetSecurityDocumentList({
    //           documentType: SecurityDocumentTypes.KNOW_HOW,
    //           activeRequests: 'false',
    //           activeSecurityDocuments: 'false',
    //         }),
    //       ),
    //     },
    //     {
    //       label: 'Патентные исследования',
    //       routeElement: 'patent-research',
    //       isAccepted: isHasSomePermission(userPermission, [
    //         Permits.PATENT_RESEARCH_VIEW,
    //         Permits.PATENT_RESEARCH_ADD,
    //         Permits.PATENT_RESEARCH_EDIT,
    //         Permits.PATENT_RESEARCH_DELETE,
    //       ]),
    //       Content: makeContent(specifications.GetPatentResearchList),
    //     },
    //     {
    //       label: 'Договоры на ИС',
    //       routeElement: 'security-document-contracts',
    //       isAccepted: isHasSomePermission(userPermission, [
    //         Permits.CONTRACTS_FOR_USE_VIEW,
    //         Permits.CONTRACTS_FOR_USE_ADD,
    //         Permits.CONTRACTS_FOR_USE_EDIT,
    //         Permits.CONTRACTS_FOR_USE_DELETE,
    //       ]),
    //       Content: makeContent(specifications.GetSecurityDocumentContractList),
    //     },
    //     {
    //       label: 'Журнал поступления лицензионных платежей',
    //       routeElement: 'contract-payment-log',
    //       isAccepted: isHasPermission(userPermission, Permits.CONTRACT_PAYMENT_LOG_VIEW),
    //       Content: makeContent(specifications.GetContractPaymentLogList()),
    //     },
    //   ],
    // },

    {
      kind: 'terminal',
      Icon: icons.Reports,
      label: 'Отчёты',
      routeElement: 'reports',
      Content: pages.Reports,
    },
    {
      kind: 'terminal',
      Icon: icons.References,
      label: 'Справочники',
      routeElement: 'references',
      Content: makeContent(specifications.GetReferences()),
    },
    {
      kind: 'terminal',
      Icon: icons.Administration,
      label: 'Администрирование',
      isAccepted: isHasPermission(userPermission, Permits.ADMIN),
      routeElement: 'administration',
      Content: pages.Administration,
    },
  ];
}
