import React from 'react';

import { Project } from 'types/models/Project';
import { Modal, FormComponent } from 'components';

type Props = {
  isOpen: boolean;
  onClose(): void;
  project: Project | null;
};

function ProjectInfoModal(props: Props) {
  const { isOpen, onClose, project } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Описание проекта" size="medium">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Характер проекта">
            <div>{project?.specification?.label}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Цель">
            <div>{project?.goal}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Аннотация">
            <div>{project?.annotation}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ожидаемые результаты">
            <div>{project?.expectedResults}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ключевые слова">
            <div>{project?.keyWords}</div>
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { ProjectInfoModal };
