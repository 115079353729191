import { useCallback, useState, useMemo } from 'react';
import { ReferenceItem } from 'components';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Payment, Report } from 'types/models';

import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

import * as BackendAPI from 'services/BackendAPI';

import { State, Mode, Term } from './model';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

type Arguments = {
  event: Payment.EventType;
};

export function useController({ event }: Arguments): State {
  const [isOpenSelectInstanceModal, setIsOpenSelectInctanceModal] = useState(false);
  const [instanceValue, setInstanceValue] = useState<ReferenceItem | null>(null);
  const [authorPayment, setAuthorPayment] = useState<Payment.AuthorPayment | null>(null);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenCommentForm, setIsOpenCommentForm] = useState(false);
  const [comment, setComment] = useState('');
  const [mode, setMode] = useState<Mode | null>(null);
  const [termPreset, setTermPreset] = useState<Payment.TermPreset | null>(null);
  const [isOpenTermPresetModal, setIsOpenTermPresetModal] = useState(false);

  const tableStreams = useLocalTableStreams();

  const reports = useMemo<Report[]>(() => [Reports.MemorandumAccounting, Reports.RequestListIncentivePaymentsPeriod], []);

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport, getReports } = useReportsHook({ reports });

  const { methods: getPaymentTermPreset } = BackendAPI.useBackendAPI('GetPaymentTermPreset');
  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeAuthorPaymentRequestStatus');
  const { methods: deletePaymentAPI } = BackendAPI.useBackendAPI('DeleteAuthorPaymentRequest');
  const { methods: changeTermAPI } = BackendAPI.useBackendAPI('ChangeAuthorPaymentRequestTerm');
  const { methods: getAuthorPaymentRequest } = BackendAPI.useBackendAPI('GetAuthorPaymentRequest', {
    onSuccessfullCall: ({ data }) => {
      if (data === null) {
        return;
      }
      setInstanceValue(null);
      setAuthorPayment(data);
      if (!data.regulationName || !data.regulationParagraph) {
        getPaymentTermPreset.callAPI(
          { type: event },
          {
            onSuccessfullCall: ({ data: term }) => {
              setTermPreset(term);
              toggleVisibleForm();
            },
          },
        );
      } else {
        toggleVisibleForm();
      }
    },
  });

  const onClickPrint = useCallback(() => {
    handleSetCurrentReport({
      name: Reports.MemorandumAccounting.caption,
      value: Reports.MemorandumAccounting.name,
    });
  }, [handleSetCurrentReport]);

  const resetState = useCallback(() => {
    setAuthorPayment(null);
    setMode(null);
  }, []);

  const cancelPayment = useCallback(() => {
    resetState();
    setIsOpenForm(false);
  }, [resetState]);

  const toggleVisibleForm = useCallback(() => {
    setIsOpenForm(prevIsOpen => !prevIsOpen);
  }, []);

  const openSelectInstanceModal = useCallback(() => {
    setIsOpenSelectInctanceModal(true);
  }, []);

  const closeSelectInstanceModal = useCallback(() => {
    setIsOpenSelectInctanceModal(false);
  }, []);

  const closeCommentForm = useCallback(() => {
    setIsOpenCommentForm(false);
    setComment('');
  }, []);

  const openCommentForm = useCallback(() => {
    setIsOpenCommentForm(true);
  }, []);

  const reloadTable = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams.reloadTable]);

  const changeStatus = useCallback(
    (paymentId: string, status: AuthorPaymentRequestStatus) => {
      changeStatusAPI.callAPI(
        { comment, status, paymentId },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Статус заявки успешно изменен', theme: 'success' });
            reloadTable();
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comment, reloadTable],
  );

  const loadAuthorPayment = useCallback((foundation: ReferenceItem, modeWork: Mode) => {
    getAuthorPaymentRequest.callAPI({ foundation, type: event, mode: modeWork });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePayment = useCallback(
    (paymentId: string) => {
      deletePaymentAPI.callAPI(
        { paymentId },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Заявка успешно удалена', theme: 'success' });
            reloadTable();
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reloadTable],
  );

  const getTermPreset = useCallback(() => {
    getPaymentTermPreset.callAPI(
      { type: event },
      {
        onSuccessfullCall: ({ data }) => {
          setTermPreset(data);
          setIsOpenTermPresetModal(true);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTerm = useCallback(
    (paymentId: string, term: Term) => {
      changeTermAPI.callAPI(
        { paymentId, ...term },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Условия оплаты успешно изменены', theme: 'success' });
            reloadTable();
            setIsOpenTermPresetModal(false);
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reloadTable],
  );

  return {
    isOpenSelectInstanceModal,
    isOpenForm,
    instanceValue,
    authorPayment,
    mode,
    termPreset,
    setMode,
    setInstanceValue,
    openSelectInstanceModal,
    closeSelectInstanceModal,
    loadAuthorPayment,
    toggleVisibleForm,
    cancelPayment,
    event,
    comment,
    setComment,
    isOpenCommentForm,
    closeCommentForm,
    changeStatus,
    openCommentForm,
    deletePayment,
    isOpenTermPresetModal,
    setIsOpenTermPresetModal,
    getTermPreset,
    changeTerm,
    onClickPrint,
    onReportClose,
    isReportOpen,
    currentReport,
    getReports,
    handleSetCurrentReport,
  };
}
