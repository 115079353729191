import React from 'react';
import * as R from 'ramda';
import { block } from 'bem-cn';

import { TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { Result } from 'types/models/ContestRequest';

const b = block('cr-performance-indicators');

type Props = {
  indicator: Result;
  indicators: Result[];
  setIndicators(indicators: Result[]): void;
  disabled: boolean;
  notePlaceholders?: Record<string, string>;
};

export function Line(props: Props) {
  const { indicator, indicators, setIndicators, disabled, notePlaceholders } = props;

  const makeHandleChange = (key: keyof Result, id: string) => (val: string) => {
    const index = indicators.findIndex(x => x.resultItem.id === id);
    const updatedInideces = R.over(R.lensIndex(index), (x: Result) => ({ ...x, [key]: val }), indicators);
    setIndicators(updatedInideces);
  };
  const placeholderText =
    notePlaceholders && indicator?.id && notePlaceholders[indicator?.resultItem?.label]
      ? notePlaceholders[indicator?.resultItem?.label]
      : '';

  return (
    <div className={b('row')}>
      <div className={b('column')}>{indicator.resultItem.label}</div>
      <div className={b('column')}>
        <TextInput
          mode={TextInputMode.number}
          value={indicator.plan.toString()}
          onChange={makeHandleChange('plan', indicator.resultItem.id)}
          isDisabled={disabled}
        />
      </div>
      <div className={b('column')}>
        <TextArea
          mode={TextAreaMode.modal}
          settings={{ rows: 3 }}
          value={indicator.note}
          onChange={makeHandleChange('note', indicator.resultItem.id)}
          isDisabled={disabled}
          placeholder={placeholderText}
        />
      </div>
    </div>
  );
}
