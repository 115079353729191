import React from 'react';

import {
  buttonIcons,
  CustomSelect,
  FormComponent,
  InputSelect,
  ListEdit,
  Reference,
  SectionTitle,
  Checkbox,
  ReferenceItem,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
} from 'components';

import {
  Author,
  Form,
  SecurityDocument,
  SecurityDocumentMember,
  SecurityDocumentMemberTypes,
  SecurityDocumentTypes,
} from 'types/models';
import { Departments } from 'features/Departments';
import { formatJob } from 'features/SelectPerson/helpers';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { validatePatentMember } from './validate';
import { useController } from './controller';
import { MemberInputs } from './MemberInputs/MemberInputs';
import { CountryInput } from './CountryInput/CountryInput';
import { Department } from 'types/models/Project';
import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  patent?: SecurityDocument | null;
  setPatent?: React.Dispatch<React.SetStateAction<SecurityDocument | null | undefined>>;
  departments?: Department[] | null;
  setDepartments?: React.Dispatch<React.SetStateAction<Department[]>>;
  mpks?: ReferenceItem[] | null;
  setMpks?: React.Dispatch<React.SetStateAction<ReferenceItem[]>>;
  formFields: Form.Fields;
  disabled?: boolean;
};

export function Document({ patent, departments, formFields, disabled, setPatent, setDepartments, mpks, setMpks }: Props) {
  const {
    requestStatusOptions,
    projectRowConverter,
    projectSpecification,
    projectOriginal,
    handleAuthorsChange,
    handleApplicantChange,
    handleAdmittedChange,
    handleCountriesChange,
    cloneAuthorsToAdmittedPersons,
    documentTitles,
    isPatentDocument,
    isKnowHowDocument,
    isEvidenceDocument,
    leftColumnWidth,
    rightColumnWidth,
    authorsList,
    admittedPersonList,
    declinedTypeTitle,
    applicantPersonList,
    documentDatesTooltipText,
  } = useController({
    patent,
    setPatent,
  });
  const { settings } = useAppDataContext();
  const countriesComponent = () => (
    <FormComponent.Line>
      <ListEdit
        withMessages
        title="Страны"
        isDeleteConfirmEnabled
        rows={patent?.countries || []}
        isToolbarDisabled={disabled || formFields.isWorldwide.value}
        withoutHead
        onChange={handleCountriesChange}
        maxHeight="300px"
        defaultRowsCount={3}
        columns={[{ label: '', formatValue: x => x?.label || '' }]}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, onChange) => <CountryInput country={row} onCountryChange={onChange} />,
        }}
      />
    </FormComponent.Line>
  );
  const admittedPersonComponent = () => (
    <FormComponent.Line>
      <ListEdit
        withMessages
        title="Допущенные лица"
        isDeleteConfirmEnabled
        rows={admittedPersonList}
        isToolbarDisabled={disabled}
        onChange={handleAdmittedChange}
        maxHeight="300px"
        defaultRowsCount={5}
        columns={[
          { label: 'ФИО', formatValue: x => x?.name || '' },
          {
            label: 'Место работы',
            formatValue: x => (x?.person?.scientistJobs[0] && formatJob(x?.person.scientistJobs[0])) || '',
          },
        ]}
        extraToolbarButtons={[
          {
            icons: buttonIcons.clone,
            title: 'Скопировать авторов',
            onClick: cloneAuthorsToAdmittedPersons,
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, onChange) => (
            <MemberInputs
              member={row}
              onMemberChange={onChange as any}
              memberType={SecurityDocumentMemberTypes.ADMITTED_PERSON}
            />
          ),
          validation: {
            checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
            onInvalidate: (row, mode, index, list) => {
              const validationInfo = validatePatentMember(row, list, mode, index);
              showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
    </FormComponent.Line>
  );
  const applicantsComponent = () => (
    <ListEdit
      withMessages
      title={`Правообладатели, кроме ${settings?.organization?.shortName}`}
      isDeleteConfirmEnabled
      rows={applicantPersonList}
      isToolbarDisabled={disabled}
      onChange={handleApplicantChange}
      maxHeight="300px"
      defaultRowsCount={5}
      columns={[
        { label: 'ФИО персоны / Название организации', formatValue: x => x?.name || '', styles: { width: '42%' } },
        {
          label: 'Договор',
          formatValue: x =>
            x?.contractDate ? `№ ${x?.contractNumber} от ${x?.contractDate}` : x?.contractNumber ? `№ ${x?.contractNumber}` : '',
          styles: { width: '42%' },
        },
        { label: 'Доля', formatValue: x => x?.contractPortion ?? '', styles: { width: '16%' } },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs
            member={row}
            onMemberChange={onChange}
            memberType={SecurityDocumentMemberTypes.APPLICANT}
            enterpriseRef="RefEnterpriseCustomer"
          />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );
  const convertSecurityDocumentToAuthor = (member: SecurityDocumentMember): Author => ({
    ...member,
    category: null,
    historyLabel: '',
    affilateCount: null,
    isAffilated: false,
    memberAffilations: [],
    position: 0,
    enterprise: null,
  });
  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        historyLabel: '',
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      settings,
    );

  const authorsComponent = () => (
    <ListEdit
      isRequired
      title={`Авторы${authorsList.length ? `, всего: ${authorsList.length}` : ''}`}
      withMessages
      isDeleteConfirmEnabled
      rows={authorsList}
      isToolbarDisabled={disabled}
      onChange={handleAuthorsChange}
      maxHeight="none"
      columnIndexesForSumTotal={[3]}
      defaultRowsCount={8}
      columns={[
        { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '25%' } },
        {
          label: 'Должность',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.rank || result.error || '';
          },
          styles: { width: '25%' },
        },
        {
          label: 'Место работы',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.job || result.error || '';
          },
          styles: { width: '35%' },
        },
        { label: 'Вклад, %', formatValue: x => formatNumber(x?.contribution || '0', 2), styles: { width: '15%' } },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs member={row} onMemberChange={onChange as any} memberType={SecurityDocumentMemberTypes.AUTHOR} />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );

  const onIsWorldwideChange = (checked: boolean) => {
    formFields.isWorldwide.onChange(checked);
    if (checked) handleCountriesChange([]);
  };

  return (
    <>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column specialStyles={{ width: leftColumnWidth }}>
          {isKnowHowDocument ? (
            <>
              <SectionTitle title={documentTitles[patent?.type?.value ?? SecurityDocumentTypes.PATENT]} />
              <FormComponent.Line lineSize="doublePadded" hasGap>
                <FormComponent.Field label={isKnowHowDocument ? 'Номер приказа' : formFields.documentNumber.title} isRequired>
                  <TextInput
                    value={formFields.documentNumber.value}
                    onChange={formFields.documentNumber.onChange}
                    isDisabled={disabled}
                    placeholder={isKnowHowDocument ? 'Номер приказа' : formFields.documentNumber.title}
                  />
                </FormComponent.Field>
                <FormComponent.Field label={formFields.internalNumber.title} isRequired={formFields.internalNumber.required}>
                  <TextInput
                    value={formFields.internalNumber.value}
                    onChange={formFields.internalNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label="Дата приказа" isRequired={formFields.documentDate.required}>
                  <TextDateTime
                    value={formFields.documentDate.value}
                    onChange={formFields.documentDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          ) : (
            <>
              <SectionTitle title={documentTitles[SecurityDocumentTypes.PATENT]} />
              <FormComponent.Line lineSize="doublePadded" hasGap>
                <FormComponent.Field label={formFields.requestNumber.title} isRequired={formFields.requestNumber.required}>
                  <TextInput
                    value={formFields.requestNumber.value}
                    onChange={formFields.requestNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
                <FormComponent.Field label={formFields.internalNumber.title} isRequired={formFields.internalNumber.required}>
                  <TextInput
                    value={formFields.internalNumber.value}
                    onChange={formFields.internalNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label={formFields.requestDate.title} isRequired={formFields.requestDate.required}>
                  <TextDateTime
                    value={formFields.requestDate.value}
                    onChange={formFields.requestDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label={formFields.requestSendDate.title} isRequired={formFields.requestSendDate.required}>
                  <TextDateTime
                    value={formFields.requestSendDate.value}
                    onChange={formFields.requestSendDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label={formFields.requestStatus.title} isRequired={formFields.requestStatus.required}>
                  <InputSelect
                    disabled={disabled}
                    options={requestStatusOptions}
                    value={formFields.requestStatus.value}
                    onSelectChange={formFields.requestStatus.onChange}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
          {!isKnowHowDocument && (
            <FormComponent.Line>
              <FormComponent.Field
                label={formFields.intellectualPropertyKind.title}
                isRequired={formFields.intellectualPropertyKind.required}
              >
                <Reference
                  name={isEvidenceDocument ? 'RefIntellectualPropertyKindEvidence' : 'RefIntellectualPropertyKind'}
                  value={formFields.intellectualPropertyKind.value ?? null}
                  relationTableModalTitle='Справочник "Виды ОИС"'
                  onChange={formFields.intellectualPropertyKind.onChange}
                  disabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field
              label={formFields.intellectualPropertyName.title}
              isRequired={formFields.intellectualPropertyName.required}
            >
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.intellectualPropertyName.title }}
                value={formFields.intellectualPropertyName.value}
                onChange={formFields.intellectualPropertyName.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.referat.title} isRequired={formFields.referat.required}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.referat.title }}
                value={formFields.referat.value}
                onChange={formFields.referat.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {isKnowHowDocument && (
            <FormComponent.Line>
              <FormComponent.Field label="Дата начала" isRequired>
                <TextDateTime
                  value={formFields.loDate.value}
                  onChange={formFields.loDate.onChange}
                  isDisabled={disabled || formFields.loDate.disabled}
                />
              </FormComponent.Field>
              <FormComponent.Field label="Дата окончания">
                <TextDateTime
                  value={formFields.hiDate.value}
                  onChange={formFields.hiDate.onChange}
                  isDisabled={disabled || formFields.hiDate.disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line>
            <FormComponent.Field>
              <CustomSelect
                rowsNumber={3}
                disabled={patent?.project?.id !== undefined}
                title={formFields.project.title}
                relationTableModalTitle="Проекты"
                original={formFields.project.value ? projectOriginal(formFields.project.value) : null}
                onChange={formFields.project.onChange}
                onDeleteFunction={() => {}}
                modalTableSpecification={projectSpecification}
                modalTableRowConverter={projectRowConverter}
                isRequired={formFields.project.required}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: rightColumnWidth }}>
          {isPatentDocument && <SectionTitle title="Патент" />}
          {isEvidenceDocument && <SectionTitle title="Свидетельство" />}

          {!!patent?.intellectualPropertyKind?.label && (
            <FormComponent.Line>
              <FormComponent.Field label="На охрану ОИС">
                <FormComponent.Description mode="info">
                  {patent?.intellectualPropertyKind?.label}: {patent?.intellectualPropertyName}
                </FormComponent.Description>
              </FormComponent.Field>
            </FormComponent.Line>
          )}

          {isKnowHowDocument ? (
            admittedPersonComponent()
          ) : (
            <>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label={formFields.documentNumber.title} isRequired={formFields.documentNumber.required}>
                  <TextInput
                    value={formFields.documentNumber.value}
                    onChange={formFields.documentNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label={formFields.documentDate.title} isRequired={formFields.documentDate.required}>
                  <TextDateTime
                    value={formFields.documentDate.value}
                    onChange={formFields.documentDate.onChange}
                    isDisabled={disabled || formFields.documentDate.disabled}
                    isError={!formFields.documentDate.isValid}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field
                  label={formFields.documentReceiveDate.title}
                  isRequired={formFields.documentReceiveDate.required}
                >
                  <TextDateTime
                    value={formFields.documentReceiveDate.value}
                    onChange={formFields.documentReceiveDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label="Сроки действия" tooltip={documentDatesTooltipText}>
                  <TextDateTime
                    isRange
                    value={`${formFields.documentStartDate.value || ''}-${formFields.documentEndDate.value || ''}`}
                    onChange={(value: string) => {
                      const dates = value.split('-');
                      formFields.documentStartDate.onChange(dates[0] || '');
                      formFields.documentEndDate.onChange(dates[1] || '');
                    }}
                    isDisabled={disabled || formFields.documentStartDate.disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <ListEdit
                  title="МПК"
                  withMessages
                  rows={mpks || []}
                  onChange={m => setMpks?.(m)}
                  defaultRowsCount={3}
                  maxHeight="350px"
                  columns={[{ label: 'Название', formatValue: x => `${x?.label || ''}` }]}
                  isToolbarDisabled={disabled}
                  specification={{
                    mode: 'relationTableModal',
                    relationTableModalTitle: 'Справочник "МПК"',
                    modalTableRowConverter: row => ({ label: row.label, id: row.id }),
                    modalTableSpecification: GetReferenceElementList({
                      requestData: {
                        filters: [],
                        name: 'RefMpk',
                      },
                    }),
                  }}
                />
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label={formFields.stateNumber.title} isRequired={formFields.stateNumber.required}>
                  <TextInput
                    value={formFields.stateNumber.value}
                    onChange={formFields.stateNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field
                  label={formFields.stateRegistrationDate.title}
                  isRequired={formFields.stateRegistrationDate.required}
                >
                  <TextDateTime
                    value={formFields.stateRegistrationDate.value}
                    onChange={formFields.stateRegistrationDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column specialStyles={{ width: leftColumnWidth }}>
          <FormComponent.Line>{authorsComponent()}</FormComponent.Line>

          <FormComponent.Line>
            <Departments
              title="Связь с Подразделениями"
              departments={departments || []}
              setDepartments={deps => setDepartments?.(deps)}
              disabled={disabled ?? false}
              filterGovernances={true}
            />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: rightColumnWidth }}>
          <SectionTitle title={`Территория действия ${declinedTypeTitle}`} />
          <FormComponent.Line>
            <FormComponent.Field>
              {!isKnowHowDocument && (
                <Checkbox
                  label={formFields.isRus.title}
                  checked={!!formFields.isRus.value}
                  onChange={formFields.isRus.onChange}
                  isDisabled={disabled}
                />
              )}
              <Checkbox
                label={formFields.isWorldwide.title}
                checked={isKnowHowDocument || !!formFields.isWorldwide.value}
                onChange={onIsWorldwideChange}
                isDisabled={disabled || isKnowHowDocument}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>{!isKnowHowDocument && countriesComponent()}</FormComponent.Line>
          <FormComponent.Line>{!isKnowHowDocument && applicantsComponent()}</FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}
