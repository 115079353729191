import React, { useCallback, useMemo } from 'react';

import { buttonIcons, Toolbar as SharedToolbar, IconButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { State, PublicationType } from '../types';
import { streams } from '../streams';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const {
    customState: { setMode, openEditor, publicationType, citationSystems, isOpenEditor, workMode },
    tableState,
  } = props;

  const [selectedRow] = tableState.selectedRows;

  const getPermission = useCallback((type: PublicationType) => {
    const mapPublicationTypes = {
      [PublicationTypes.COMPILATION_CONFERENCE.code]: Permits.COMPILATION_CITATION_SYSTEM_INDEX_EDIT,
      [PublicationTypes.COMPILATION_OTHER.code]: Permits.COMPILATION_CITATION_SYSTEM_INDEX_EDIT,
      [PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code]: Permits.COMPILATION_CITATION_SYSTEM_INDEX_EDIT,
      [PublicationTypes.MAGAZINE.code]: Permits.MAGAZINE_CITATION_SYSTEM_INDEX_EDIT,
    };
    return mapPublicationTypes[type as keyof typeof mapPublicationTypes] || Permits.PUBLICATION_CITATION_SYSTEM_INDEX_EDIT;
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isDisabled: workMode === 'viewMode' || !citationSystems.length || isOpenEditor,
        onClick: () => {
          openEditor();
          setMode('add');
        },
        permissionName: getPermission(publicationType),
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: !selectedRow || workMode === 'viewMode' || !citationSystems.length || isOpenEditor,
        onClick: () => {
          openEditor();
          setMode('edit');
          if (tableState?.selectedRows?.length > 0) {
            streams.getCitationSystemIndex.push(selectedRow.id);
          }
        },
        permissionName: getPermission(publicationType),
      },
      {
        icons: buttonIcons.delete,
        isDisabled: !selectedRow || workMode === 'viewMode' || !citationSystems.length || isOpenEditor,
        title: 'Удалить',
        onClick: () => {
          if (tableState?.selectedRows?.length > 0) {
            streams.deleteCitationSystemIndex.push(selectedRow.id);
          }
        },
        permissionName: getPermission(publicationType),
      },
    ],
    [
      selectedRow,
      workMode,
      citationSystems.length,
      isOpenEditor,
      getPermission,
      publicationType,
      openEditor,
      setMode,
      tableState?.selectedRows?.length,
    ],
  );

  return <SharedToolbar buttons={buttons} />;
}

export { Toolbar };
