import { Table } from 'types/models';
import { ProjectCode } from 'types/models/Project';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  projectCode?: ProjectCode | null;
  hideTitle?: boolean;
  isDisabled?: boolean;
};

type Arguments = {
  projectCodeId?: string | null;
};

export function GetEstimateList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetEstimateList',
    isFiltersHidden: true,
    requestData: { projectCodeId: args?.projectCode?.id || '-1' },
    header: {
      firstLevel: {
        title: args?.hideTitle ? undefined : 'Сметы',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel({
          projectCode: args?.projectCode?.id === '0' ? null : args?.projectCode,
          isDisabled: args?.isDisabled,
        }),
      },
    },
  };
}
