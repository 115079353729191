import { Dispatch, SetStateAction, useCallback, useLayoutEffect, useState } from 'react';
import { Affiliation, Table, Community, Vak, Grnti, Table as T } from 'types/models';
import { getMockPartner } from 'features/EditPartner/helpers';
import { Original } from 'types/models/Form';
import { GetSimpleMagazineListSpecification } from 'features/Table/specifications';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  community: Community.Community | null;
  setCommunity: Dispatch<SetStateAction<Community.Community | null>>;
};

export function useController(props: Props) {
  const { community, setCommunity } = props;

  const [publishingLabel, setPublishingLabel] = useState<string>('');

  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication');

  const loadMagazine = useCallback(
    (magazineId?: string) => {
      if (!magazineId) {
        return false;
      }
      getPublication.callAPI(
        {
          simpleFields: {
            status: '',
            typeEdition: '',
          },
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          attrIdFields: {},
          id: magazineId,
        },
        {
          onSuccessfullCall({ data }) {
            if (data.fields.publisherLabel) {
              setPublishingLabel(`${data.fields.publisherLabel} (${data.fields.typeEditionText})`);
            } else {
              setPublishingLabel(data.fields.typeEditionText);
            }
            if (community) {
              setCommunity({
                ...community,
                magazine: {
                  id: magazineId || '',
                  name: data.fields.foreignName
                    ? `"${data.fields.name}" ("${data.fields.foreignName}")`
                    : `"${data.fields.name}"`,
                  type: { label: '', value: data.type || '' },
                },
              });
            }
          },
        },
      );
    },
    [getPublication, community, setCommunity],
  );

  const handleAffiliationFieldChange = useCallback(
    (e: Affiliation) => {
      if (community) {
        setCommunity({ ...community, affiliation: e });
      }
    },
    [community, setCommunity],
  );

  const handleNoteFieldChange = useCallback(
    (value: string) => {
      if (community) {
        setCommunity({ ...community, note: value });
      }
    },
    [community, setCommunity],
  );

  const handleIsLocalFieldChange = useCallback(
    (e: string) => {
      if (community) {
        setCommunity({ ...community, isLocal: e === 'true' });
      }
    },
    [community, setCommunity],
  );

  const handleFoundationDateFieldChange = useCallback(
    (value: string) => {
      if (community) {
        setCommunity({ ...community, foundationDate: value });
      }
    },
    [community, setCommunity],
  );

  const handleCodeFieldChange = useCallback(
    (value: string) => {
      if (community) {
        setCommunity({ ...community, code: value });
      }
    },
    [community, setCommunity],
  );

  const handleOrderNumberFieldChange = useCallback(
    (value: string) => {
      if (community) {
        setCommunity({ ...community, orderNumber: value });
      }
    },
    [community, setCommunity],
  );

  const handleOrderDateFieldChange = useCallback(
    (value: string) => {
      if (community) {
        setCommunity({ ...community, orderDate: value });
      }
    },
    [community, setCommunity],
  );

  const handleVaksFieldChange = useCallback(
    (e: Vak[]) => {
      if (community) {
        setCommunity({ ...community, vaks: e });
      }
    },
    [community, setCommunity],
  );

  const affiliationRowConverter = useCallback((e: Table.Entry): Affiliation => {
    const value: Affiliation = {
      id: null,
      createdBy: null,
      createdDate: null,
      modifiedBy: null,
      modifiedDate: null,
      count: null,
      sources: null,
      partner: e.Type === 'PARTNER' ? getMockPartner() : null,
      enterprise: e.Type === 'ENTERPRISE' ? { id: e.id, label: e.ShortName } : null,
      enterpriseCustomer: e.Type === 'ENTERPRISE_CUSTOMER' ? { id: e.id, label: e.ShortName } : null,
    };
    if (value.partner) {
      value.partner.id = e.id;
      value.partner.shortName = e.ShortName;
    }
    return value;
  }, []);

  const handleGrntisFieldChange = useCallback(
    (e: Grnti[]) => {
      if (community) {
        setCommunity({ ...community, grntis: e });
      }
    },
    [community, setCommunity],
  );

  const handleMagazineFieldChange = useCallback(
    (m: Original | null) => {
      if (m?.id) {
        loadMagazine(m.id);
      }
      if (community) {
        setCommunity({ ...community, magazine: { id: m?.id || '', type: { label: '', value: '' }, name: m?.name || '' } });
      }
    },
    [community, setCommunity, loadMagazine],
  );

  const modalTableMagazineConverter = useCallback<(row: T.Entry) => Original>(row => {
    return {
      id: row.id,
      name: row.name,
    };
  }, []);

  const magazineTableConfig = GetSimpleMagazineListSpecification({
    hasSelectButton: true,
    deps: {},
  });

  useLayoutEffect(() => {
    loadMagazine(community?.magazine?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleAffiliationFieldChange,
    handleNoteFieldChange,
    handleIsLocalFieldChange,
    handleFoundationDateFieldChange,
    handleCodeFieldChange,
    affiliationRowConverter,
    handleOrderNumberFieldChange,
    handleOrderDateFieldChange,
    handleVaksFieldChange,
    handleGrntisFieldChange,
    modalTableMagazineConverter,
    magazineTableConfig,
    handleMagazineFieldChange,
    publishingLabel,
  };
}
