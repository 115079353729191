import React, { useCallback, useState } from 'react';
import * as R from 'ramda';

import { buttonIcons, ListEdit } from 'components';

import { NirRequest } from 'types/models';
import { EnumValue } from 'types/models/Table';
import { showErrorsMessages } from 'utils/Common';
import { Member, PersonHistoryModal } from 'features/SelectPerson';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { BuildReportPopup, useReportsHook, Reports } from 'features/BuildReportPopup';
import { PortfolioCard } from 'features/Form/looks/person';
import {
  formatDegreeRank,
  formatIdentifiers,
  formatIndexHirsh,
  getMockPerformer,
} from 'features/Form/looks/nirRequest/NirRequestForm/helpers';
import { Fields } from './Fields/Fields';
import { validate } from './validate';

type Props = {
  performers: NirRequest.Performer[];
  setPerformers(performers: NirRequest.Performer[]): void;
  roles: EnumValue[];
  disabled: boolean;
  tenderType: NirRequest.TenderType | null;
};

export function TeamComposition(props: Props) {
  const { performers, setPerformers, roles, disabled, tenderType } = props;

  const [isOpenHistoryPersonModal, setIsOpenHistoryPersonModal] = useState(false);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);
  const [selectedPerformerIndex, setSelectedPerformerIndex] = useState<number | null>(null);

  const formatJobOrEducation = (performer: NirRequest.Performer) => {
    if (performer.job) {
      return formatJob(performer.job);
    }

    return performer.education ? formatEducation(performer.education) : '';
  };

  const submitHistoryPersonModal = useCallback(
    (member: Partial<Member>) => {
      if (selectedPerformerIndex !== null) {
        const updatedPerformers = R.over(
          R.lensIndex(selectedPerformerIndex),
          (prevPerformer: NirRequest.Performer) => ({ ...prevPerformer, ...member }),
          performers,
        );
        setPerformers(updatedPerformers);
      }
    },
    [selectedPerformerIndex, performers, setPerformers],
  );

  const handleCloseHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(false);
    setSelectedPerformerIndex(null);
  }, [setIsOpenHistoryPersonModal, setSelectedPerformerIndex]);

  const handleOpenHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(true);
  }, [setIsOpenHistoryPersonModal]);

  const closePersonModal = useCallback(() => {
    setIsOpenPersonModal(false);
    setSelectedPerformerIndex(null);
  }, []);

  const openPersonModal = useCallback(() => {
    setIsOpenPersonModal(true);
  }, []);

  const onClickHistoryIcon = useCallback(
    (selectedPerformer: NirRequest.Performer | null, selectedRowIndex: number | null) => {
      if (selectedPerformer !== null && selectedRowIndex !== null) {
        setSelectedPerformerIndex(selectedRowIndex);
        handleOpenHistoryModal();
      }
    },
    [setSelectedPerformerIndex, handleOpenHistoryModal],
  );

  const onClickInfoIcon = useCallback(
    (selectedPerformer: NirRequest.Performer | null, selectedRowIndex: number | null) => {
      if (selectedPerformer !== null && selectedRowIndex !== null) {
        setSelectedPerformerIndex(selectedRowIndex);
        openPersonModal();
      }
    },
    [openPersonModal],
  );

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const onClickPrint = useCallback(
    (selectedPerformer: NirRequest.Performer | null, selectedRowIndex: number | null) => {
      setSelectedPerformerIndex(selectedRowIndex);
      handleSetCurrentReport({ name: Reports.Personal.caption, value: Reports.Personal.name });
    },
    [handleSetCurrentReport],
  );

  const selectedPerformer = selectedPerformerIndex !== null ? performers[selectedPerformerIndex] : null;

  const participationMapTitle: Record<NirRequest.TenderType, string> = {
    IG: 'Участие в международных научных сообществах',
    LMU: 'Участие в международных научных сообществах',
    ML: 'Участие в международных научных сообществах',
    ONG: 'Участие в международных научных сообществах',
    PD: 'Участие в международных научных сообществах',
    PP: 'Участие в хоздоговорах',
  };

  return (
    <>
      <ListEdit
        rows={performers}
        defaultRowsCount={10}
        isFullScreenedTable
        withMessages
        isVisibleFilters
        isToolbarDisabled={disabled}
        extraToolbarButtons={[
          {
            icons: buttonIcons.person,
            title: 'Просмотр сведений о персоне',
            code: 'personInfo',
            checkIsDisabled: row => row === null || disabled,
            onClick: onClickInfoIcon,
          },
          {
            icons: buttonIcons.clock,
            title: 'История персоны',
            code: 'historyPerson',
            checkIsDisabled: row => row === null || disabled,
            onClick: onClickHistoryIcon,
          },
          {
            icons: buttonIcons.print,
            title: 'Персональный отчет',
            code: 'personReport',
            onClick: onClickPrint,
            checkIsDisabled: row => row === null || disabled,
          },
        ]}
        isDeleteConfirmEnabled
        columns={[
          { label: 'Роль', formatValue: x => roles.find(role => role.value === x.role?.value)?.label ?? '' },
          { label: 'ФИО', formatValue: x => x.person?.fullName ?? '' },
          { label: 'Дата рождения', formatValue: x => x.person?.scientist?.dateBirth ?? '' },
          { label: 'Степень, звание', formatValue: formatDegreeRank },
          { label: 'Место работы / обучения', formatValue: formatJobOrEducation, styles: { width: '20%' } },
          { label: 'Гражданство', formatValue: x => x.citizenship?.label ?? '' },
          {
            label: participationMapTitle[tenderType ?? 'LMU'],
            formatValue: x => !!x.collaborationParticipation || !!x.economicContractParticipationExperience,
            styles: { width: '20%', textAlign: 'center' },
          },
          { label: 'Индексы Хирша', formatValue: formatIndexHirsh },
          { label: 'Идентификаторы', formatValue: formatIdentifiers },
          {
            label: 'Публикаций Scopus/Wos Q1, Q2',
            formatValue: x =>
              [
                x.personQ1Q2PublicationQty || '0',
                x.foreignPerformerQ1Q2PublicationQty ? `Примечание ${x.foreignPerformerQ1Q2PublicationQty}` : '',
              ]
                .filter(i => i)
                .join(', '),
          },
        ]}
        onChange={setPerformers}
        specification={{
          mode: 'customComponent',
          renderComponent: (performer, setPerformer) => (
            <Fields
              tenderType={tenderType}
              roles={roles}
              performer={performer || getMockPerformer()}
              setPerformer={setPerformer}
            />
          ),
          validation: {
            checkIsValid: (performer, index, list, mode) => validate(performer, list, mode).every(x => x.isValid),
            onInvalidate: (performer, mode, index, list) => {
              const validationInfo = validate(performer, list, mode);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
      <PersonHistoryModal
        isOpen={isOpenHistoryPersonModal}
        member={selectedPerformer}
        onClose={handleCloseHistoryModal}
        onSubmit={submitHistoryPersonModal}
        isLoadPersonAfterOpen
        mode="edit"
      />
      <PortfolioCard isOpen={isOpenPersonModal} id={selectedPerformer?.person?.id ?? null} onClose={closePersonModal} />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        scientistId={selectedPerformer?.person?.scientist?.id ?? ''}
      />
    </>
  );
}
