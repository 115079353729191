import { Program } from 'types/models';

type Props = {
  program: Program.Program | null;
  getProgram: any;
};

export type State = Props;

export function makeUseCustomController({ program, getProgram }: Props) {
  return function useCustomController(): State {
    return {
      program,
      getProgram,
    };
  };
}
