import React, { useCallback, useState, useMemo } from 'react';
import block from 'bem-cn';
import { streams } from './streams';

import { Button, ButtonMode, Checkbox, Column, ConfirmPopup, FormComponent, ListEditTable, TextInput } from 'components';

import { LoaderLocal } from 'features/LoaderLocal';
import { SuchLikePublication } from 'types/models';
import { SuchLikeMode } from 'types/models/SuchLikePublication';
import { EventTypeCode } from 'types/models/Event';
import { EventTypes } from 'utils/Enums/EventTypes';
import { replaceSpacesWithSpace } from 'utils/Helpers/replaceSpacesWithSpace';
import { useLocalStreams } from './hooks';
import { eventNameMap } from './helpres';

import '../style.scss';

type Props = {
  eventTypeCode: EventTypeCode;
  startDate?: string;
  eventName?: string;
  suchLikePublicationList: SuchLikePublication.State['suchLikePublicationList'];
  customContinueHandle?: (searchText: string) => void;
  isSearchableComponent?: boolean;
  onClose: () => void;
  isLoading: boolean;
  sendEmptyMessageWarning: () => void;
  onMessagePopupOpen: () => void;
  isConfirmPopupOpen: boolean;
  updateIsConfirmPopupOpen: (nextIsConfirmPopupOpen: boolean) => void;
  selectedRowIndex: number | null;
  mode?: SuchLikeMode;
  setSelectedRowIndex: (index: number) => void;
};

type RowKeys = 'id' | 'name' | 'status' | 'startDate' | 'endDate' | 'created' | 'eventType';

type RowItem = {
  [Key in RowKeys]: string;
};

const b = block('such-like-publication');

const SuchLikeEventComponent = ({
  eventTypeCode,
  suchLikePublicationList,
  isSearchableComponent,
  startDate,
  eventName,
  customContinueHandle,
  onClose,
  onMessagePopupOpen,
  isConfirmPopupOpen,
  updateIsConfirmPopupOpen,
  selectedRowIndex,
  setSelectedRowIndex,
  isLoading,
  sendEmptyMessageWarning,
  mode,
}: Props) => {
  const isPublicationExistDefault = false;
  const localStreams = useLocalStreams(streams);
  const [isPublicationExist, setIsPublicationExist] = useState<boolean>(isPublicationExistDefault);
  const [searchText, setSearchText] = useState('');
  const [isDuplicateWarningOpen, setIsDuplicateWarningOpen] = useState<boolean>(false);
  const [duplicate, setDuplicate] = useState<RowItem | null>(null);

  const openDuplicateWarning = useCallback(() => {
    setIsDuplicateWarningOpen(true);
  }, []);

  const closeDuplicateWarning = useCallback(() => {
    setIsDuplicateWarningOpen(false);
    setDuplicate(null);
  }, []);

  const onConfirmButtonClick = useCallback(() => {
    onMessagePopupOpen();
    updateIsConfirmPopupOpen(false);
  }, [onMessagePopupOpen, updateIsConfirmPopupOpen]);

  const preparedRows: RowItem[] = useMemo(
    () =>
      suchLikePublicationList.map(suchLikePublicationItem => ({
        id: suchLikePublicationItem.id,
        name: suchLikePublicationItem.name,
        eventType: suchLikePublicationItem.type.label,
        status: suchLikePublicationItem.status.label,
        startDate: suchLikePublicationItem.startDate,
        endDate: suchLikePublicationItem.endDate,
        created: `${suchLikePublicationItem.createdBy.name} (${suchLikePublicationItem.createdDate})`,
      })),
    [suchLikePublicationList],
  );

  const afterSuccessfullSearch = () => {
    setIsPublicationExist(suchLikePublicationList.length === 0);
  };

  const searchByInputValue = () => {
    if (searchText.length) {
      localStreams.getSuchLikeEvents.push({
        isSearching: true,
        searchValue: searchText,
        successfullCallback: afterSuccessfullSearch,
        currentId: null,
      });
    } else {
      sendEmptyMessageWarning();
    }
  };

  const handleContinue = () => {
    const nextDuplicate = preparedRows.find(
      event => event.name === replaceSpacesWithSpace(eventName || '') && event.startDate === startDate,
    );
    const isEditMode = mode === 'edit';
    if (nextDuplicate && isEditMode) {
      setDuplicate(nextDuplicate);
      openDuplicateWarning();
    } else if (customContinueHandle) {
      onClose();
      customContinueHandle(searchText);
    } else {
      updateIsConfirmPopupOpen(true);
    }
  };

  const columns = useMemo<Column<RowItem>[]>(
    () => [
      { label: 'Статус', formatValue: row => row.status },
      {
        label: eventNameMap[eventTypeCode].upperCaseNominative,
        formatValue: row => `Тип: ${row.eventType}, Название: ${row.name}`,
      },
      { label: 'Период', formatValue: row => `${row.startDate}${row.endDate ? ` - ${row.endDate}` : ''}` },
      { label: 'Создано', formatValue: row => row.created },
    ],
    [eventTypeCode],
  );

  return (
    <div className={b()}>
      <LoaderLocal isShow={isLoading} />

      <div className={b('content')}>
        <div className={b('content-toolbar')} />
        {isSearchableComponent && (
          <div className={b('content-search')}>
            <div className={b('content-search-field')}>
              <FormComponent.Field label={`Название ${eventNameMap[eventTypeCode].genitive}`}>
                <TextInput value={searchText} onChange={setSearchText} />
              </FormComponent.Field>
            </div>
            <div className={b('content-search-button')}>
              <Button mode={ButtonMode.secondary} text="Найти" onClick={searchByInputValue} classMixin="suchlike-button" />
            </div>
          </div>
        )}
        <div className={b('content-table')}>
          <ListEditTable
            rows={preparedRows}
            columns={columns}
            selectedRowIndex={selectedRowIndex}
            selectRow={setSelectedRowIndex}
          />
        </div>
      </div>
      <div className={b('actions')}>
        <div className={b('all-count')}>
          <span className={b('all-count-text')}>Всего:</span>
          <span className={b('all-count-value')}>{preparedRows.length}</span>
        </div>
        <div className={b('actions-checkbox')}>
          <Checkbox
            label={`В списке ${eventTypeCode === EventTypes.EVENT_CONTEST.code ? 'нужного' : 'нужной'} ${
              eventNameMap[eventTypeCode].genitive
            } нет`}
            checked={!!isPublicationExist}
            onChange={setIsPublicationExist}
          />
        </div>
        <ul className={b('actions-button-list')}>
          <li className={b('actions-button')}>
            <Button
              mode={ButtonMode.primary}
              text={
                mode === 'add'
                  ? `Продолжить добавление ${eventNameMap[eventTypeCode].genitive}`
                  : `Продолжить сохранение ${eventNameMap[eventTypeCode].nominative}`
              }
              onClick={handleContinue}
              isDisabled={!isPublicationExist}
            />
          </li>
          <li className={b('actions-button')}>
            <Button
              mode={ButtonMode.secondary}
              text={
                mode === 'add'
                  ? `Отменить добавление ${eventNameMap[eventTypeCode].genitive}`
                  : `Не сохранять ${eventNameMap[eventTypeCode].nominative}`
              }
              onClick={onClose}
            />
          </li>
        </ul>
      </div>
      <div className={b('confirm-create')}>
        <ConfirmPopup
          isOpen={isConfirmPopupOpen}
          title={`Подтверждение сохранения ${eventNameMap[eventTypeCode].genitive}`}
          // eslint-disable-next-line max-len
          text={`Вы уверены, что добавляемое ${eventNameMap[eventTypeCode].nominative} не является дубликатом уже существующего в системе?`}
          okButtonText="Да"
          resetButtonText="Нет"
          onConfirm={onConfirmButtonClick}
          onClose={() => {
            updateIsConfirmPopupOpen(false);
          }}
        />
        <ConfirmPopup
          isOpen={isDuplicateWarningOpen}
          title={`Найден дубликат ${eventNameMap[eventTypeCode].genitive}`}
          icon="warning"
          // eslint-disable-next-line max-len
          okButtonText="Ок"
          onConfirm={closeDuplicateWarning}
          onClose={closeDuplicateWarning}
        >
          <div>
            <div>
              <span>{eventNameMap[eventTypeCode].upperCaseNominative} не может быть сохранена, существует дубликат</span>
            </div>
            <ul>
              <li>
                <strong>ID:</strong> {duplicate?.id}
              </li>
              <li>
                <strong>Название:</strong> {duplicate?.name}
              </li>
            </ul>
          </div>
        </ConfirmPopup>
      </div>
    </div>
  );
};

export const Component = React.memo(SuchLikeEventComponent);
