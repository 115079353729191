import React from 'react';

import { Table as T } from 'types/models';
import { Filters } from './filters';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export type Props = {
  recognitionsFilter: string;
  setRecognitionsFilter: React.Dispatch<string>;
};

export function GetPersonalRecognitionList({ recognitionsFilter }: Props): T.Specification {
  return {
    apiID: 'GetPersonalRecognitionList',
    isFiltersHidden: true,
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel({ recognitionsFilter }),
      },
      firstLevel: {
        title: 'Признания и награды',
        Panel: Filters,
      },
    },
    isWithQna: true,
  };
}
