import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { CalendarPlanByYear, FinancingByYear, ProjectCode, Stage } from 'types/models/Project';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { GetProjectBoAccompliceList } from 'features/Table/specifications';
import { Form } from 'types/models';

type Props = {
  projectCode: ProjectCode | null;
  disabled?: boolean;
  handleFinPlanChange(plans: CalendarPlanByYear[]): void;
  financingsByYear: FinancingByYear[];
  calendarPlansByYear: CalendarPlanByYear[];
  financingTagnameByProjectType?: string;
  formFields: Form.Fields;
  handleSave: (needClose?: boolean) => void;
};

type FormatedStage = {
  [k: string]: Stage[];
};

export function useController(props: Props) {
  const {
    projectCode,
    disabled,
    handleFinPlanChange,
    calendarPlansByYear,
    financingsByYear,
    financingTagnameByProjectType,
    formFields,
    handleSave,
  } = props;

  const [isAccompliceModalOpen, setIsAccompliceModalOpen] = useState<boolean>(false);
  const [isConfirmDistributePopupOpen, setIsConfirmDistributePopupOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | null>(projectCode?.project?.id || null);
  const [projectCodeId, setProjectCodeId] = useState<string | null>(projectCode?.id || null);

  const handleAccompliceModalClose = useCallback(() => {
    setIsAccompliceModalOpen(false);
  }, [setIsAccompliceModalOpen]);

  const isLockedPlanByYear = useCallback(
    (item: CalendarPlanByYear) => {
      let isLocked = false;
      projectCode?.project?.stages.forEach(stage => {
        if (parse(stage.startDate, formatStr, 0).getFullYear() > parseInt(item.year, 10) && stage.isLocked) {
          isLocked = true;
        }
      });
      return isLocked;
    },
    [projectCode],
  );

  const groupStagesByYear = (arr: Stage[]) => {
    const temp = arr.reduce((acc: FormatedStage, elem: Stage) => {
      const param = elem.startDate.split('.')[2];
      if (!acc[param]) {
        acc[param] = [];
      }
      acc[param].push(elem);
      return acc;
    }, {});
    return temp;
  };

  const calculateUndistributedByYear = useCallback(
    (row: FinancingByYear) => {
      const groupedStages = groupStagesByYear(projectCode?.project?.stages || [])[row.year];
      return financingTagnameByProjectType
        ? groupedStages.reduce((acc: number, elem: Stage) => {
            const elementUndistributed = elem[financingTagnameByProjectType as keyof Stage];
            return acc + (typeof elementUndistributed === 'string' ? parseFloat(elementUndistributed) : 0);
          }, 0)
        : 0;
    },
    [projectCode, financingTagnameByProjectType],
  );

  const distribute = useCallback(() => {
    const calendarPlansToUpdate = calendarPlansByYear?.map((calendarPlanByYear: CalendarPlanByYear) => {
      const projectFinancingByYear = financingsByYear.filter(f => f.year === calendarPlanByYear.year)[0];
      if (!isLockedPlanByYear(calendarPlanByYear) || disabled) {
        return {
          ...calendarPlanByYear,
          amount: projectFinancingByYear ? calendarPlanByYear.amount + calculateUndistributedByYear(projectFinancingByYear) : 0,
        };
      }
      return calendarPlanByYear;
    });
    handleFinPlanChange(calendarPlansToUpdate || []);
  }, [disabled, isLockedPlanByYear, calculateUndistributedByYear, handleFinPlanChange, calendarPlansByYear, financingsByYear]);

  const handleConfirmDistributePopup = useCallback(() => {
    setIsConfirmDistributePopupOpen(false);
    distribute();
  }, [setIsConfirmDistributePopupOpen, distribute]);

  const isThereUndistributedByYear = useMemo(
    () => !!financingsByYear.filter(projectFinancingByYear => calculateUndistributedByYear(projectFinancingByYear) > 0).length,
    [financingsByYear, calculateUndistributedByYear],
  );

  const handleDistributeClick = useCallback(() => {
    let hasLockedCalendarPlans = false;
    projectCode?.calendarPlansByYears?.forEach((calendarPlanByYear: CalendarPlanByYear) => {
      const projectFinancingByYear = projectCode?.project?.financingsByYear.filter(f => f.year === calendarPlanByYear.year)[0];
      if (
        isLockedPlanByYear(calendarPlanByYear) &&
        projectFinancingByYear &&
        calculateUndistributedByYear(projectFinancingByYear) > 0
      ) {
        hasLockedCalendarPlans = true;
      }
    });
    if (!hasLockedCalendarPlans || disabled) {
      distribute();
    } else {
      setIsConfirmDistributePopupOpen(true);
    }
  }, [projectCode, disabled, distribute, isLockedPlanByYear, calculateUndistributedByYear]);

  const codeCalPlanByYearExtraButtons = useMemo<ExtraToolbarButton<CalendarPlanByYear>[]>(
    () => [
      {
        icons: buttonIcons.clone,
        title: 'Расчеты с соисполнителями',
        isDisabled: disabled,
        onClick: () => {
          setProjectId(projectCode?.project?.id || '');
          setProjectCodeId(projectCode?.id || '');
          setIsAccompliceModalOpen(true);
        },
      },
      {
        icons: buttonIcons.financialMove,
        title: 'Добавить нераспределенные суммы',
        checkIsDisabled: () => disabled || !isThereUndistributedByYear,
        onClick: () => {
          handleDistributeClick();
        },
      },
    ],
    [
      setProjectId,
      setProjectCodeId,
      setIsAccompliceModalOpen,
      handleDistributeClick,
      disabled,
      projectCode,
      isThereUndistributedByYear,
    ],
  );

  const projectCalPlanByYearExtraButtons = useMemo<ExtraToolbarButton<FinancingByYear>[]>(
    () => [
      {
        icons: buttonIcons.clone,
        title: 'Расчеты с соисполнителями',
        isDisabled: disabled,
        onClick: () => {
          setProjectId(projectCode?.project?.id || '');
          setProjectCodeId(null);
          setIsAccompliceModalOpen(true);
        },
      },
    ],
    [setProjectId, setProjectCodeId, disabled, projectCode],
  );

  const calcStageNds = useCallback(
    (row: Stage) => (parseFloat(row.amountMain) * parseFloat(row.mainNdsPercent)) / (100 + parseFloat(row.mainNdsPercent)),
    [],
  );

  const projectByAccompliceSpecification = GetProjectBoAccompliceList({ projectId, projectCodeId });

  const sortByYear = useCallback(
    (entries: any) => entries.sort((x1: any, x2: any) => parseInt(x2.year, 10) - parseInt(x1.year, 10)) || [],
    [],
  );

  const sortByStage = useCallback(
    (entries: any) =>
      entries.sort(
        (x1: any, x2: any) => parseInt(x2?.stage?.number || x2?.number, 10) - parseInt(x1?.stage?.number || x1?.number, 10),
      ) || [],
    [],
  );

  const finSourceTitle = useMemo(() => {
    const finSource = projectCode?.project?.financings?.find(f => f.id === formFields.financing?.value?.id);
    return `Финансирование шифра по годам ${
      finSource ? '(' + finSource.type?.label + ': ' + finSource?.source?.label + ')' : ''
    }`;
  }, [formFields, projectCode]);

  useLayoutEffect(() => {
    handleSave?.(false);
    // eslint-disable-next-line
  }, []);

  return {
    codeCalPlanByYearExtraButtons,
    projectCalPlanByYearExtraButtons,
    isAccompliceModalOpen,
    setIsAccompliceModalOpen,
    handleAccompliceModalClose,
    projectId,
    projectCodeId,
    projectByAccompliceSpecification,
    isConfirmDistributePopupOpen,
    setIsConfirmDistributePopupOpen,
    handleConfirmDistributePopup,
    calcStageNds,
    sortByYear,
    sortByStage,
    calculateUndistributedByYear,
    finSourceTitle,
  };
}
