import { Tender } from 'types/models';
import * as R from 'ramda';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { RadioItem } from 'features/BuildReportPopup/models';
import { defaultLotValue } from './helpers';
import ReactDOM from 'react-dom';
import { ValueOf } from 'types/helpers';

type Props = {
  lot: Tender.Lot | null;
  onLotChange(row: Tender.Lot): void;
  viewMode: boolean;
  tenderName: string;
  tenderStartDate: string;
  lotsAmount: number;
};

export function useController({ lot, onLotChange, tenderName, tenderStartDate, lotsAmount }: Props) {
  const handleLotPricesChange = useCallback(
    value => {
      onLotChange(R.set(R.lensProp('prices'), value, lot!));
    },
    [lot, onLotChange],
  );

  const handleAmountChange = useCallback(
    (fieldName: keyof Tender.Lot) => (value: string | number) => {
      if (lot) {
        ReactDOM.unstable_batchedUpdates(() => {
          const updatedLog = { ...lot, [fieldName]: value };
          onLotChange({ ...updatedLog, contractAmount: updatedLog.limitAmount / updatedLog.contractCount });
        });
      }
    },
    [lot, onLotChange],
  );

  const handleChange = useCallback(
    (fieldName: keyof Tender.Lot) => (value: ValueOf<Tender.Lot>) => {
      if (lot) {
        onLotChange({ ...lot, [fieldName]: value });
      }
    },
    [lot, onLotChange],
  );

  useEffect(() => {
    if (!lot) {
      onLotChange({
        ...defaultLotValue,
        number: String(lotsAmount + 1),
        code: lotsAmount === 0 ? '' : '',
        contractCount: 1,
        theme: tenderName,
        startDate: tenderStartDate,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleAmountRadioChange = useCallback(
    (fieldName: keyof Tender.Lot) => (value: string) => {
      let restValue: boolean = false;
      if (fieldName === 'isSupportContract' || fieldName === 'isSupportRequest' || fieldName === 'isSupportWarranty') {
        restValue = value === 'true';
        if (fieldName === 'isSupportContract') {
          setContractSupportAmountDisabled(!restValue);
          if (lot) {
            onLotChange({ ...lot, ...{ [fieldName]: restValue, contractSupportAmount: 0 } });
          }
        }
        if (fieldName === 'isSupportRequest') {
          setRequestSupportAmountDisabled(!restValue);
          if (lot) {
            onLotChange({ ...lot, ...{ [fieldName]: restValue, requestSupportAmount: 0 } });
          }
        }
        if (fieldName === 'isSupportWarranty') {
          setWarrantySupportAmountDisabled(!restValue);
          if (lot) {
            onLotChange({ ...lot, ...{ [fieldName]: restValue, warrantySupportAmount: 0 } });
          }
        }
      } else {
        onLotChange(R.set(R.lensProp(fieldName), restValue as never, lot!));
      }
    },
    [lot, onLotChange],
  );

  const handleRangeDateChange = useCallback(
    (startDate: string, endDate: string) => {
      onLotChange({ ...lot, startDate, endDate } as Tender.Lot);
    },
    [lot, onLotChange],
  );

  const initialRadioList: RadioItem[] = useMemo(
    () => [
      { label: 'Да', value: 'true' },
      { label: 'Нет', value: 'false' },
    ],
    [],
  );

  const [yesNoRadios] = useState<RadioItem[]>(initialRadioList);

  const [requestSupportAmountDisabled, setRequestSupportAmountDisabled] = useState<boolean>(true);
  const [contractSupportAmountDisabled, setContractSupportAmountDisabled] = useState<boolean>(true);
  const [warrantySupportAmountDisabled, setWarrantySupportAmountDisabled] = useState<boolean>(true);

  return {
    handleLotPricesChange,
    handleChange,
    handleAmountRadioChange,
    handleRangeDateChange,
    yesNoRadios,
    requestSupportAmountDisabled,
    contractSupportAmountDisabled,
    warrantySupportAmountDisabled,
    handleAmountChange,
  };
}
