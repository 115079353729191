import React from 'react';

import { FormComponent } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { AffiliatedPublications, NotAffiliatedPublications } from 'features/Form/looks/nirRequest/NirRequestForm/views';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function PublicationsScopusWoS(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;
  const { settings } = useAppDataContext();
  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <AffiliatedPublications
            title="Статьи в журналах WoS и Scopus"
            publications={nirRequest?.collectivePublications ?? []}
            setPublications={makeChangeHandler('collectivePublications')}
            scientistIDs={(nirRequest?.performers ?? []).map(x => x.person?.id ?? '').filter(Boolean)}
            disabled={disabled}
            visibleToolbarButtons={['add', 'delete']}
          />
        </FormComponent.Column>
        <FormComponent.Column>
          <NotAffiliatedPublications
            title={`Статьи в журналах WoS и Scopus (Не аффилированные с ${settings?.organization?.shortName})`}
            publications={nirRequest?.notAffiliatedCollectivePublications ?? []}
            setPublications={makeChangeHandler('notAffiliatedCollectivePublications')}
            disabled={disabled}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
