import { useCallback, useState, useLayoutEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form, Table, Table as T } from 'types/models';
import { Original } from 'types/models/Form';
import { GetMagazineReleaseListSpecification } from 'features/Table/specifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { showNotification } from 'features/Notifications';

type Props = {
  publicationId: string;
  onClose: () => void;
  onSuccess: () => void;
  relatedTableState: Table.State;
};

const useController = ({ publicationId, onClose, onSuccess }: Props) => {
  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: publicationPreprintProcess } = BackendAPI.useBackendAPI('PublicationPreprintProcess');

  const [magazineArticlePublication, setMagazineArticlePublication] = useState<Form.Publication | null>(null);

  const [preprintOriginal, setPreprintOriginal] = useState<Original | null>(null);

  const handlePreprintChange = useCallback((preprint: Original) => {
    setPreprintOriginal(preprint);
  }, []);

  const isPreprint = useMemo(() => {
    return magazineArticlePublication?.type === PublicationTypes.PREPRINT_ARTICLE.code;
  }, [magazineArticlePublication]);

  const submitForm = useCallback(() => {
    if (preprintOriginal === null) {
      showNotification({ message: `Выберите ${isPreprint ? 'журнал' : 'препринт'}`, theme: 'danger' });
      return;
    }

    publicationPreprintProcess.callAPI(
      { publicationId, magazineReleaseId: preprintOriginal?.id || '' },
      {
        onSuccessfullCall: () => {
          onClose();
          onSuccess();
        },
      },
    );
  }, [onSuccess, onClose, publicationId, publicationPreprintProcess, preprintOriginal, isPreprint]);

  const modalTableMagazineConverter = useCallback<(row: T.Entry) => Original>(row => {
    return {
      id: row.id,
      name: row.label,
    };
  }, []);

  const magazineTableConfig = GetMagazineReleaseListSpecification({
    deps: {},
    hasSelectButton: true,
    isPreprint: !isPreprint,
  });

  const preprintDialogTitle = useMemo(() => {
    return `Выберите базу ${isPreprint ? 'журналов' : 'препринтов'}`;
  }, [isPreprint]);

  const preprintButtonLabel = useMemo(() => {
    return `Продолжить редактирование ${isPreprint ? 'журнала' : 'препринта'}`;
  }, [isPreprint]);

  useLayoutEffect(() => {
    getPublication.callAPI(
      {
        id: publicationId,
        simpleFields: {
          status: 'ADDED',
        },
        attrIdFields: {},
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
        projects: [],
      },
      {
        onSuccessfullCall: (magazineArticlePublicationDataResult: any) => {
          const magazineArticlePublicationData = magazineArticlePublicationDataResult.data as Form.Publication;
          setMagazineArticlePublication(magazineArticlePublicationData);
        },
      },
    );
  }, []); // eslint-disable-line

  return {
    submitForm,
    magazineArticlePublication,
    modalTableMagazineConverter,
    magazineTableConfig,
    handlePreprintChange,
    preprintOriginal,
    preprintDialogTitle,
    preprintButtonLabel,
  };
};

export default useController;
