import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent(
    [
      {
        type: FilterComponentType.constant,
        streamKey: 'isRequests',
        value: false,
      },
      {
        type: FilterComponentType.checkbox,
        streamKey: 'isActive',
        label: 'Действующие',
        checked: false,
      },
    ],
    'personal',
  );
});

export const FiltersForRequest = React.memo(() => {
  return FiltersComponent(
    [
      {
        type: FilterComponentType.constant,
        streamKey: 'isRequests',
        value: true,
      },
      {
        type: FilterComponentType.radio,
        streamKey: 'isConsiderationRequests',
        list: [
          {
            label: 'Все заявки',
            value: 'false',
          },
          {
            label: 'Заявки на рассмотрении',
            value: 'true',
          },
        ],
        value: 'false',
      },
    ],
    'personal',
  );
});
