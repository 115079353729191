import { useCallback, useLayoutEffect, useState, useRef } from 'react';
import * as R from 'ramda';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Document, Form, SecurityDocument } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';

import { useAppDataContext } from 'features/AppData/context';
import { SecurityDocumentPaymentRequestFormLook } from 'types/models/Form/look/securityDocument';
import { usePatentFormController } from 'features/Form/looks/securityDocumentPatent/PatentForm/patentFormController';
import { AuthorPayment, DistributionMethodBetweenFunds, Nominee, Department } from 'types/models/Payment';
import { ValueOf } from 'types/helpers';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { validate } from './validate';
import { getEmptyAuthorPayment } from './helpers';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, securityDocumentId },
  } = useFormContext<SecurityDocumentPaymentRequestFormLook>();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [authorPayment, setAuthorPayment] = useState<AuthorPayment>(getEmptyAuthorPayment());
  const [isOpenCommentForm, setIsOpenCommentForm] = useState<boolean>();
  const [isOpenConfirmStatusForm, setIsOpenConfirmStatusForm] = useState<boolean>();
  const [isOpenStatusForm, setIsOpenStatusForm] = useState<boolean>();
  const [isNeedClose, setIsNeedClose] = useState<boolean>();
  const [comment, setComment] = useState<string | null>();
  const [newStatus, setNewStatus] = useState<string | null>();
  const [securityDocument, setSecurityDocument] = useState<SecurityDocument | null>();
  const [patentDocuments, setPatentDocuments] = useState<Document[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);

  const { userToken } = useAppDataContext();
  const [formFields, setFormFields] = useState<Form.Fields>({
    regulationParagraph: {
      value: '',
      isValid: true,
      required: true,
      title: 'Пункт положения',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationParagraph: { ...prevState.regulationParagraph, value },
        }));
      },
    },
    regulationName: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationName: { ...prevState.regulationName, value },
        }));
      },
    },
    factFundMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма фонда, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          factFundMoney: { ...prevState.factFundMoney, value },
        }));
      },
    },
    factNomineesMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма номинантам, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          factNomineesMoney: { ...prevState.factNomineesMoney, value },
        }));
      },
    },
    maxFundMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма фонда, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          maxFundMoney: { ...prevState.maxFundMoney, value },
        }));
      },
    },
    maxNomineesMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма номинантам, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          maxNomineesMoney: { ...prevState.maxNomineesMoney, value },
        }));
      },
    },
  });

  const { methods: getPaymentTermPreset } = BackendAPI.useBackendAPI('GetPaymentTermPreset', {
    onSuccessfullCall: ({ data }) => {
      setAuthorPayment(prev =>
        (R.pipe as any)(
          R.set(R.lensProp('regulationParagraph'), data?.regulationParagraph),
          R.set(R.lensProp('regulationName'), data?.regulationName),
        )(prev),
      );
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('regulationParagraph'), data?.regulationParagraph),
          R.set(getValueLens('regulationName'), data?.regulationName),
        )(prevFields),
      );
    },
  });

  const { methods: getSecurityDocument } = BackendAPI.useBackendAPI('GetSecurityDocument', {
    onSuccessfullCall: ({ data }) => {
      setSecurityDocument(data);
      setPatentDocuments(data.documents);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setPatentFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('requestNumber'), data.requestNumber),
          R.set(getValueLens('requestDate'), data.requestDate),
          R.set(getValueLens('requestStatus'), data.requestStatus),
          R.set(getValueLens('project'), data.project),
          R.set(getValueLens('balanceOrderNumber'), data.balanceOrderNumber),
          R.set(getValueLens('loDate'), data.loDate),
          R.set(getValueLens('hiDate'), data.hiDate),
          R.set(getValueLens('balancePrice'), data.balancePrice),
          R.set(getValueLens('intellectualPropertyKind'), data.intellectualPropertyKind),
          R.set(getValueLens('intellectualPropertyName'), data.intellectualPropertyName),
          R.set(getValueLens('documentNumber'), data.documentNumber),
          R.set(getValueLens('documentStartDate'), data.documentStartDate),
          R.set(getValueLens('documentEndDate'), data.documentEndDate),
          R.set(getValueLens('stateNumber'), data.stateNumber),
          R.set(getValueLens('stateRegistrationDate'), data.stateRegistrationDate),
        )(prevFields),
      );
    },
  });

  const { methods: getAuthorPaymentRequest } = BackendAPI.useBackendAPI('GetAuthorPaymentRequest');

  const loadAuthorPaymentRequest = useCallback(
    data => {
      setAuthorPayment(data);
      setDocuments(data?.documents || []);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('regulationParagraph'), data?.regulationParagraph),
          R.set(getValueLens('regulationName'), data?.regulationName),
          R.set(getValueLens('factFundMoney'), data?.factFundMoney),
          R.set(getValueLens('factNomineesMoney'), data?.factNomineesMoney),
          R.set(getValueLens('maxFundMoney'), data?.maxFundMoney),
          R.set(getValueLens('maxNomineesMoney'), data?.maxNomineesMoney),
        )(prevFields),
      );
      if (data?.patent?.id) {
        getSecurityDocument.callAPI({ id: data?.patent?.id || '' });
      }
      if (!id) {
        getPaymentTermPreset.callAPI({ type: 'SECURITY_DOCUMENT' });
      }
    },
    [setAuthorPayment, setDocuments, setFormFields, getSecurityDocument, getPaymentTermPreset, id],
  );

  const { methods: savePaymentRequest } = BackendAPI.useBackendAPI('SaveSecurityDocumentPaymentRequest');

  const handleSaveResponse = useCallback(
    respData => {
      getAuthorPaymentRequest.callAPI(
        {
          foundation: { id: respData.id, label: '' },
          mode: 'edit',
          type: 'SECURITY_DOCUMENT',
        },
        { onSuccessfullCall: ({ data }) => loadAuthorPaymentRequest(data) },
      );
      updateWorkModeAfterSaveAndContinue();
    },
    [getAuthorPaymentRequest, updateWorkModeAfterSaveAndContinue, loadAuthorPaymentRequest],
  );

  const onSubmit = useCallback(
    (needClose, onSuccessCb?: () => void) => {
      const errors = validate(authorPayment || null, formFields);
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          showNotification({ message: error.invalidMessage, theme: 'danger' });
        });
        return false;
      }
      savePaymentRequest.callAPI(
        {
          ...authorPayment,
          id: authorPayment?.id || null,
          ...(comment ? { saveComment: comment } : {}),
          regulationParagraph: authorPayment?.regulationParagraph || '',
          regulationName: authorPayment?.regulationName || '',
          creator: null,
          distributionMethodBetweenFunds: authorPayment?.distributionMethodBetweenFunds || '',
          distributionMethodBetweenNominees: authorPayment?.distributionMethodBetweenNominees?.value || '',
          documents,
          factFundMoney: authorPayment?.factFundMoney || '0',
          factNomineesMoney: authorPayment?.factNomineesMoney || '0',
          foreignDocuments: [],
          maxFundMoney: authorPayment?.maxFundMoney || '0',
          maxNomineesMoney: authorPayment?.maxNomineesMoney || '0',
          month: authorPayment?.month || '',
          nominees: authorPayment?.nominees || [],
          patent: securityDocument || null,
          quarter: authorPayment?.quarter || null,
          remainderFundMoney: authorPayment?.remainderFundMoney || '0',
          remainderNomineesMoney: authorPayment?.remainderNomineesMoney || '0',
          requestsByFoundation: [],
          status: authorPayment?.status || null,
          statusChangedDate: '',
          statusChangedTime: '',
          statusChanger: null,
          type: authorPayment?.type || null,
          year: authorPayment?.year || '',
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });
            setComment('');
            onSuccessCb?.();
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              handleSaveResponse(data);
            }
          },
        },
      );
    },
    [
      authorPayment,
      savePaymentRequest,
      tableStreams,
      formFields,
      onClose,
      handleSaveResponse,
      setComment,
      comment,
      documents,
      securityDocument,
    ],
  );

  const { patentFormFields, setPatentFormFields } = usePatentFormController({
    patent: securityDocument,
    setPatent: setSecurityDocument,
  });

  useLayoutEffect(() => {
    getAuthorPaymentRequest.callAPI(
      {
        foundation: { id: id || securityDocumentId || '', label: '' },
        type: 'SECURITY_DOCUMENT',
        mode: id ? 'edit' : 'add',
      },
      { onSuccessfullCall: ({ data }) => loadAuthorPaymentRequest(data) },
    );
    if (securityDocumentId || authorPayment?.patent?.id) {
      getSecurityDocument.callAPI({ id: securityDocumentId || authorPayment?.patent?.id || '' });
    }
    // eslint-disable-next-line
  }, []);

  const { methods: distributeMoneyAPI } = BackendAPI.useBackendAPI('DistributeMoney', {
    onSuccessfullCall: ({ data }) => {
      if (data) {
        setAuthorPayment({
          ...data,
          id: authorPayment?.id ?? null,
          regulationName: authorPayment?.regulationName ?? '',
          regulationParagraph: authorPayment?.regulationParagraph ?? '',
          status: authorPayment?.status ?? data.status,
        });
      }
    },
    onFailedCall: () => {},
  });

  const timeoutState = useRef<any>();

  const changeAuthorPayment = useCallback(
    (key: keyof AuthorPayment, val: ValueOf<AuthorPayment>) => {
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);

      setFormFields(prevFields => (R.pipe as any)(R.set(getValueLens(key), val))(prevFields));
      setAuthorPayment(prev => ({ ...prev!, [key]: val }));
      const newAuthorPayment = { ...authorPayment, [key]: val };
      if (key === 'maxNomineesMoney' || key === 'maxFundMoney') {
        clearTimeout(timeoutState.current);
        timeoutState.current = setTimeout(() => {
          distributeMoneyAPI.callAPI({
            type: 'SECURITY_DOCUMENT',
            foundationId: authorPayment?.patent?.id || '',
            foundationType: 'SECURITY_DOCUMENT',
            maxFundMoney: newAuthorPayment?.maxFundMoney || '0',
            maxNomineesMoney: newAuthorPayment?.maxNomineesMoney || '0',
            isManualEdit: newAuthorPayment?.isManualEdit ?? false,
            nominees: newAuthorPayment?.nominees ?? [],
            departments: newAuthorPayment?.departments ?? [],
            distributionMethod: newAuthorPayment?.distributionMethodBetweenFunds ?? 'ALL',
          });
        }, 500);
      }
    },
    [setFormFields, authorPayment, setAuthorPayment, distributeMoneyAPI],
  );

  const nomineesChangeHandler = useCallback(
    (val: Nominee[]) => {
      setAuthorPayment(prevState => ({ ...prevState, nominees: val }));
      distributeMoneyAPI.callAPI({
        type: 'SECURITY_DOCUMENT',
        foundationId: authorPayment?.patent?.id || '',
        foundationType: 'SECURITY_DOCUMENT',
        maxFundMoney: authorPayment?.maxFundMoney || '0',
        maxNomineesMoney: authorPayment?.maxNomineesMoney || '0',
        isManualEdit: authorPayment?.isManualEdit ?? false,
        nominees: val ?? [],
        departments: authorPayment?.departments ?? [],
        distributionMethod: authorPayment?.distributionMethodBetweenFunds ?? 'ALL',
      });
    },
    [authorPayment, distributeMoneyAPI],
  );

  const distributionChangeHandler = useCallback(
    (key: keyof AuthorPayment, val: ValueOf<AuthorPayment>) => {
      changeAuthorPayment(key, val);
      if (key !== 'isManualEdit') {
        distributeMoneyAPI.callAPI({
          type: 'SECURITY_DOCUMENT',
          foundationId: authorPayment?.patent?.id || '',
          foundationType: 'SECURITY_DOCUMENT',
          maxFundMoney: authorPayment?.maxFundMoney || '0',
          maxNomineesMoney: authorPayment?.maxNomineesMoney || '0',
          isManualEdit: authorPayment?.isManualEdit ?? false,
          nominees: authorPayment?.nominees ?? [],
          departments: key === 'departments' ? (val as Department[]) : authorPayment?.departments ?? [],
          distributionMethod:
            key === 'distributionMethodBetweenFunds' && val
              ? (val as DistributionMethodBetweenFunds)
              : authorPayment?.distributionMethodBetweenFunds ?? 'ALL',
        });
      }
    },
    [authorPayment, distributeMoneyAPI, changeAuthorPayment],
  );

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeAuthorPaymentRequestStatus');

  const handleStatusChange = useCallback(
    (forceStatus?: string) => {
      changeStatusAPI.callAPI(
        { comment: comment || '', status: forceStatus || newStatus || '', paymentId: authorPayment?.id || '' },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Статус заявки успешно изменен', theme: 'success' });
            getAuthorPaymentRequest.callAPI(
              {
                foundation: { id: authorPayment?.id || '', label: '' },
                mode: 'edit',
                type: 'SECURITY_DOCUMENT',
              },
              { onSuccessfullCall: ({ data }) => loadAuthorPaymentRequest(data) },
            );
            updateWorkModeAfterSaveAndContinue();
            setComment('');
          },
        },
      );
    },
    [
      comment,
      newStatus,
      authorPayment,
      setComment,
      loadAuthorPaymentRequest,
      changeStatusAPI,
      getAuthorPaymentRequest,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  const changePaymentStatus = useCallback(
    (status: string) => {
      setNewStatus(status);
      if (checkPaymentByStatus(authorPayment?.factNomineesMoney ?? null, authorPayment?.status?.value ?? null)) {
        setIsOpenConfirmStatusForm(true);
      } else {
        setIsOpenStatusForm(true);
      }
    },
    [setNewStatus, authorPayment],
  );

  const handleFormSubmit = useCallback(
    (onSuccessCb?: () => void) => {
      onSubmit(isNeedClose, onSuccessCb);
    },
    [onSubmit, isNeedClose],
  );

  const onSave = useCallback(
    (needClose?: boolean, onSuccessCb?: () => void, isWithComment?: boolean) => {
      setIsNeedClose(needClose);
      if (isWithComment) {
        setIsOpenCommentForm(true);
      } else {
        handleFormSubmit(onSuccessCb);
      }
    },
    [setIsNeedClose, handleFormSubmit],
  );

  const onSaveDraft = useCallback(
    (onSuccessCb?: () => void) => {
      setIsNeedClose(false);
      handleFormSubmit(onSuccessCb);
    },
    [setIsNeedClose, handleFormSubmit],
  );

  const onChangeConfirm = useCallback(
    (status: AuthorPaymentRequestStatus) => {
      setNewStatus(status);
      setIsOpenConfirmStatusForm(true);
    },
    [setNewStatus, setIsOpenConfirmStatusForm],
  );

  return {
    paymentId: id ?? null,
    authorPayment,
    setAuthorPayment,
    workMode,
    formFields,
    patentFormFields,
    userToken,
    securityDocument,
    patentDocuments,
    documents,
    setDocuments,
    changeAuthorPayment,
    distributionChangeHandler,
    isOpenCommentForm,
    setIsOpenCommentForm,
    isOpenStatusForm,
    setIsOpenStatusForm,
    isOpenConfirmStatusForm,
    setIsOpenConfirmStatusForm,
    comment,
    setComment,
    newStatus,
    setNewStatus,
    changePaymentStatus,
    handleStatusChange,
    handleFormSubmit,
    isNeedClose,
    setIsNeedClose,
    onSave,
    onSaveDraft,
    onChangeConfirm,
    nomineesChangeHandler,
  };
}
