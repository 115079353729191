import React from 'react';
import * as R from 'ramda';

import { buttonIcons, ConfirmPopup, ListEdit, SectionTitle } from 'components';

import { Project } from 'types/models';
import { showErrors } from 'utils/Validators';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { PersonHistoryModal } from 'features/SelectPerson';
import { ScientistPerson } from 'types/models/Person';
import { getMockJobPeriod, getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';
import { validate } from './validate';
import { useController } from './controller';

type Props = {
  jobPeriods: Project.JobPeriod[];
  setJobPeriods(jobPeriods: Project.JobPeriod[]): void;
  disabled: boolean;
  person: ScientistPerson | null;
  projectStages: Project.Stage[];
  performerStages: Project.PerformerStage[];
  startProjectDate?: string;
  endProjectDate?: string;
  setPerformerStages: (performerStages: Project.PerformerStage[]) => void;
  projectId: string | null;
};

export function JobPeriods(props: Props) {
  const {
    jobPeriods,
    person,
    disabled,
    performerStages,
    projectStages,
    startProjectDate,
    endProjectDate,
    setJobPeriods,
    setPerformerStages,
    projectId,
  } = props;

  const {
    personHistoryMode,
    performerStagesToAdd,
    selectedJobPeriodIndex,
    isOpenHistoryPersonModal,
    isAddPerformerStagesPoupOpen,
    onJobPeriodSubmit,
    onClickHistoryIcon,
    closePersonHistoryMode,
    submitHistoryPersonModal,
    closeAddPerformerStagesPoup,
    confirmAddPerformerStagesPopup,
  } = useController({ person, jobPeriods, performerStages, projectStages, setJobPeriods, setPerformerStages });

  return (
    <>
      <SectionTitle title="Периоды работы" isRequired />
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        rows={jobPeriods}
        onChange={setJobPeriods}
        defaultRowsCount={10}
        extraToolbarButtons={[
          {
            icons: buttonIcons.clock,
            title: 'История персоны',
            code: 'historyPerson',
            checkIsDisabled: row => row === null || disabled,
            onClick: onClickHistoryIcon,
          },
        ]}
        maxHeight="70vh"
        columns={[
          { label: 'Роль', formatValue: x => x.role?.label ?? '' },
          { label: 'Период работы', formatValue: x => `${x.startDate} - ${x.endDate}` },
          { label: 'Ученая степень', formatValue: x => x.degree?.refDegree?.label ?? '' },
          { label: 'Ученое звание', formatValue: x => x.rank?.refRank?.label ?? '' },
          { label: 'Ак звание', formatValue: x => x.academicRank?.refAcademicRank?.label ?? '' },
          { label: 'Место работы', formatValue: x => (x.job ? formatJob(x.job) : '') },
          { label: 'Место обучения', formatValue: x => (x.education ? formatEducation(x.education) : '') },
          { label: 'Примечание', formatValue: x => x.note },
        ]}
        specification={{
          mode: 'customComponent',
          onPreSubmit: onJobPeriodSubmit,
          renderComponent: (jobPeriod, setJobPeriod) => (
            <Fields
              jobPeriod={jobPeriod || { ...getMockJobPeriod(), endDate: endProjectDate ?? '', startDate: startProjectDate ?? '' }}
              setJobPeriod={setJobPeriod}
              projectId={projectId}
            />
          ),
          validation: {
            checkIsValid: jobPeriod => validate(jobPeriod).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      />
      <PersonHistoryModal
        isOpen={isOpenHistoryPersonModal}
        member={selectedJobPeriodIndex !== null ? jobPeriods[selectedJobPeriodIndex] : null}
        onClose={closePersonHistoryMode}
        onSubmit={submitHistoryPersonModal}
        isLoadPersonAfterOpen
        mode={personHistoryMode}
      />
      <ConfirmPopup
        isOpen={isAddPerformerStagesPoupOpen}
        onConfirm={confirmAddPerformerStagesPopup}
        onClose={closeAddPerformerStagesPoup}
        title="Внимание"
        icon="info"
        okButtonText="Да"
        resetButtonText="Нет"
      >
        <div>
          <span>Данный период работы пересекается с {performerStagesToAdd.length === 1 ? 'этапом' : 'этапами'}</span>
          <ul>
            {performerStagesToAdd.map(({ stage }) => (
              <li>{(stage && getStageTitle(stage)) || ''}</li>
            ))}
          </ul>
          <span>Подставить {performerStagesToAdd.length === 1 ? 'указанный этап' : 'указанные этапы'} для участника?</span>
        </div>
      </ConfirmPopup>
    </>
  );
}
