import React, { ReactElement } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-column-wrapper');

type Props = {
  children: ReactElement | false | (ReactElement | false)[];
  fitContent?: boolean;
  customStyles?: React.CSSProperties;
};

export function ColumnWrapper(props: Props) {
  const { children, fitContent, customStyles } = props;

  return (
    <div className={b({ fitContent })} style={customStyles}>
      {children}
    </div>
  );
}
