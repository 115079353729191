import { Option } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { HistoryEntity, LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import * as BackendAPI from 'services/BackendAPI';
import { ValueOf } from 'types/helpers';
import { Entry } from 'types/models/Table';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showErrorsMessages } from 'utils/Common';
import { validate } from './helpers/validate';
import { getMockEditRelationFields } from './helpers/getMockModels';
import { EditRelationFields } from './types';

type Props = {
  historyEntities: HistoryEntity[];
  type: LinkedHistoryType;
  onClose: () => void;
};

const useController = ({ type, historyEntities, onClose }: Props) => {
  const tableStreams = useLocalTableStreams();

  const [fields, setFields] = useState<EditRelationFields>(getMockEditRelationFields());
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);
  const { methods: ChangePersonHistoryElementLinkAPI, state: ChangePersonHistoryElementLinkState } = BackendAPI.useBackendAPI(
    'ChangePersonHistoryElementLink',
  );

  const isLoading = useMemo(() => getIsPending(ChangePersonHistoryElementLinkState), [ChangePersonHistoryElementLinkState]);

  const preparedTitle = useMemo(() => {
    switch (type) {
      case LinkedHistoryType.JOB:
        return 'Выберите работу для замены';
      case LinkedHistoryType.EDUCATION:
        return 'Выберите обучение для замены';
      case LinkedHistoryType.DEGREE:
        return 'Выберите ученую степень для замены';
      case LinkedHistoryType.RANK:
        return 'Выберите ученок звание для замены';
      case LinkedHistoryType.ACADEMIC_RANK:
        return 'Выберите академическое звание для замены';
      default:
        return 'Выберите элемент для замены';
    }
  }, [type]);

  const entityLabel = useMemo(() => {
    switch (type) {
      case LinkedHistoryType.JOB:
        return 'Работа на замену';
      case LinkedHistoryType.EDUCATION:
        return 'Обучение на замену';
      case LinkedHistoryType.DEGREE:
        return 'Ученая степень на замену';
      case LinkedHistoryType.RANK:
        return 'Ученое звание на замену';
      case LinkedHistoryType.ACADEMIC_RANK:
        return 'Академическое звание на замену';
      default:
        return 'Сущность на замену';
    }
  }, [type]);

  const confirmTextEntity = useMemo(() => {
    switch (type) {
      case LinkedHistoryType.JOB:
        return 'работы';
      case LinkedHistoryType.EDUCATION:
        return 'обучения';
      case LinkedHistoryType.DEGREE:
        return 'ученой степени';
      case LinkedHistoryType.RANK:
        return 'ученого звания';
      case LinkedHistoryType.ACADEMIC_RANK:
        return 'академического звания';
      default:
        return 'сущности';
    }
  }, [type]);

  const confirmTitle = useMemo(() => {
    switch (type) {
      case LinkedHistoryType.JOB:
        return 'Замена связи работы в историческом срезе';
      case LinkedHistoryType.EDUCATION:
        return 'Замена связи обучения в историческом срезе';
      case LinkedHistoryType.DEGREE:
        return 'Замена связи ученой степени в историческом срезе';
      case LinkedHistoryType.RANK:
        return 'Замена связи ученого звания в историческом срезе';
      case LinkedHistoryType.ACADEMIC_RANK:
        return 'Замена связи академического звания в историческом срезе';
      default:
        return 'Замена связи сущности в историческом срезе';
    }
  }, [type]);

  const tableTitle = useMemo(() => {
    switch (type) {
      case LinkedHistoryType.JOB:
        return 'Доступные работы';
      case LinkedHistoryType.EDUCATION:
        return 'Доступные обучения';
      case LinkedHistoryType.DEGREE:
        return 'Доступные ученые степени';
      case LinkedHistoryType.RANK:
        return 'Доступные ученые звания';
      case LinkedHistoryType.ACADEMIC_RANK:
        return 'Доступные академические звания';
      default:
        return 'Доступные сущности';
    }
  }, [type]);

  const makeFieldChangeHandler = useCallback(
    (key: keyof EditRelationFields) => (value: ValueOf<EditRelationFields>) => {
      setFields(prevFields => ({ ...prevFields!, [key]: value }));
    },
    [],
  );

  const tableRowConverter = useCallback(
    (row: Entry): Option<string> => ({
      value: row.id,
      label: row.label,
    }),
    [],
  );

  const onIsNeedDeleteRelationChange = useCallback((nextIsNeedDeleteRelation: boolean) => {
    if (nextIsNeedDeleteRelation) {
      setFields(prevFields => ({ ...prevFields, entityToReplace: null, isNeedDeleteRelation: nextIsNeedDeleteRelation }));
    } else {
      setFields(prevFields => ({ ...prevFields, isNeedDeleteRelation: nextIsNeedDeleteRelation, isNeedHistorySlice: false }));
    }
  }, []);

  const onContinueClick = useCallback(() => {
    const validateInfo = validate({ ...fields, type });
    const invalidMessages = validateInfo.filter(({ isValid }) => !isValid);

    const hasInvalidMessages = invalidMessages.length;

    if (hasInvalidMessages) {
      showErrorsMessages(invalidMessages.map(({ invalidMessage }) => invalidMessage));
    } else {
      setIsConfirmPopupOpen(true);
    }
  }, [fields, type]);

  const closeConfirmPopup = useCallback(() => {
    setIsConfirmPopupOpen(false);
  }, []);

  const onSubmit = useCallback(() => {
    setIsConfirmPopupOpen(false);
    ChangePersonHistoryElementLinkAPI.callAPI(
      {
        id: fields.entityToReplace?.value,
        historyEntities,
        keepHistory: fields.isNeedHistorySlice,
        nullify: fields.isNeedDeleteRelation,
        type,
      },
      {
        onSuccessfullCall: () => {
          onClose();
          setFields(getMockEditRelationFields());
          showNotification({ message: 'Связь успешно изменена', theme: 'success' });
          tableStreams.reloadTable.push();
        },
      },
    );
  }, [
    ChangePersonHistoryElementLinkAPI,
    fields.entityToReplace?.value,
    fields.isNeedDeleteRelation,
    fields.isNeedHistorySlice,
    historyEntities,
    onClose,
    tableStreams.reloadTable,
    type,
  ]);

  return {
    fields,
    preparedTitle,
    entityLabel,
    tableTitle,
    isLoading,
    confirmTextEntity,
    confirmTitle,
    isConfirmPopupOpen,
    closeConfirmPopup,
    onSubmit,
    onContinueClick,
    onIsNeedDeleteRelationChange,
    tableRowConverter,
    makeFieldChangeHandler,
  };
};

export default useController;
