import { makeStream } from 'StreamRx';

import { Table } from 'types/models';

export const streams = {
  setEditedBiblio: makeStream<Table.Entry>(),
  saveBibliographicName: makeStream(),
  deleteBibliographicName: makeStream<Table.Entry>(),
  setDefaultBiblioName: makeStream<Table.Entry>(),
};
