import React, { useMemo } from 'react';

import { Table } from 'types/models';
import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, ConfirmPopup, RelationTableModal } from 'components';
import { Permits } from 'utils/Permissions';
import { ProjectEditPartial, ProjectEditPartialList, SelectRequestModal } from 'features/Table/views';

import { CopyProject } from 'features/Form/looks/project/ProjectForm/views/CopyProject';
import { State } from '../makeUseCustomController';
import { ProjectType } from 'utils/Enums';
import { GetProjectCodeChangesInProject } from 'features/Table/specifications/GetProjectCodeChangesInProject';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;
  const {
    isCopyOpen,
    submitCopyForm,
    isOpenSelectRequestModal,
    setIsOpenSelectRequestModal,
    handleSubmitSelectRequestModal,
    setMode,
    deleteProject,
    isOpenConfirmDeleteModal,
    setIsOpenConfirmDeleteModal,
    setIsOpenForm,
    setIsCopyOpen,
    isOpenCodeChangesModal,
    setIsOpenCodeChangesModal,
    paramType,
    setParamType,
    isOpenEditPartial,
    setIsOpenEditPartial,
  } = customState;

  const rows = tableState.selectedRows;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: rows.length !== 1,
        permissionName: Permits.ECONOMIC_CONTRACT_VIEW,
        onClick: () => {
          setMode('view');
          setIsOpenForm(true);
        },
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isDisabled: false,
        permissionName: Permits.ECONOMIC_CONTRACT_ADD,
        onClick: () => {
          setMode('add');
          setIsOpenSelectRequestModal(true);
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: rows.length !== 1,
        permissionName: Permits.ECONOMIC_CONTRACT_EDIT,
        onClick: () => {
          setMode('edit');
          setIsOpenForm(true);
        },
      },
      // {
      //   icons: buttonIcons.clone,
      //   title: 'Дублировать проект',
      //   isDisabled: rows.length !== 1,
      //   permissionName: Permits.ECONOMIC_CONTRACT_ADD,
      //   onClick: () => setIsCopyOpen(true),
      // },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        isDisabled: !rows[0],
        permissionName: Permits.ECONOMIC_CONTRACT_DELETE,
        onClick: () => setIsOpenConfirmDeleteModal(true),
      },
      {
        icons: buttonIcons.clock,
        title: 'История изменений',
        isDisabled: rows.length !== 1,
        onClick: () => setIsOpenCodeChangesModal(true),
      },
      {
        icons: buttonIcons.preprintRelation,
        title: 'Редактировать группу проектов',
        isDisabled: rows.length < 1,
        permissionName: Permits.RESEARCH_JOB_EDIT,
        getExpandedList: () => ProjectEditPartialList,
        expandedItemCallback: params => {
          setParamType(params.value);
          setIsOpenEditPartial(true);
        },
      },
    ],
    [
      rows,
      setMode,
      setIsOpenForm,
      setIsOpenSelectRequestModal,
      setIsOpenConfirmDeleteModal,
      setIsOpenCodeChangesModal,
      setParamType,
      setIsOpenEditPartial,
    ],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />
      {isOpenSelectRequestModal && (
        <SelectRequestModal
          isOpen={isOpenSelectRequestModal}
          onClose={() => setIsOpenSelectRequestModal(false)}
          onSubmit={handleSubmitSelectRequestModal}
          projectType={ProjectType.ECONOMIC_AGREEMENT}
        />
      )}
      {isOpenConfirmDeleteModal && (
        <ConfirmPopup
          title="Удаление сообщения"
          text="Вы точно хотите удалить выбранный проект?"
          isOpen={isOpenConfirmDeleteModal}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={() => setIsOpenConfirmDeleteModal(false)}
          onConfirm={() => {
            deleteProject(rows[0].id);
            setIsOpenConfirmDeleteModal(false);
          }}
          icon="warning"
        />
      )}
      {isCopyOpen && (
        <CopyProject
          isOpen={isCopyOpen}
          onClose={() => setIsCopyOpen(false)}
          projectId={rows[0]?.id ?? ''}
          submitCallback={submitCopyForm}
        />
      )}
      <RelationTableModal
        isOpen={isOpenCodeChangesModal}
        onClose={() => setIsOpenCodeChangesModal(false)}
        relationTableModalTitle="Журнал изменений в проекте"
        specification={GetProjectCodeChangesInProject({ projectId: rows[0]?.id })}
      />
      {isOpenEditPartial && (
        <ProjectEditPartial
          isOpen={isOpenEditPartial}
          onClose={() => setIsOpenEditPartial(false)}
          projectType={ProjectType.NIR}
          projectIds={rows.map(i => i.id)}
          paramType={paramType}
        />
      )}
    </>
  );
}

export { Toolbar };
