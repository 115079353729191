import { getEnum } from 'utils/Helpers';
import { Form as F, Project, Table as T } from 'types/models';
import { useCallback, useMemo } from 'react';
import { Option } from 'components';
import { ValueOf } from 'types/helpers';
import { Member } from 'features/SelectPerson';
import { Scientist } from 'types/models/Person';
import { Original } from 'types/models/Form';
import { PurchaseRequest, PurchaseRequestStatus } from 'types/models/PurchaseRequest';

type Props = {
  project: Project.Project | null;
  purchaseRequest: PurchaseRequest | null;
  makeChangeHandler: (key: keyof PurchaseRequest) => (val: ValueOf<PurchaseRequest>) => void;
};

export function useController(props: Props) {
  const { makeChangeHandler, project, purchaseRequest } = props;

  const contractKindOptions = getEnum('PurchaseRequestContractKind');

  const projectPerformerOptions = useMemo(() => {
    return project?.performers.map(x => ({ label: x.person?.fullName || '', value: x.id || '' })) || [];
  }, [project]);

  const handleApplicantChange = useCallback(
    (selectedOption: Option) => {
      const performer = project?.performers.find(p => p.id === selectedOption.value);
      if (performer) {
        makeChangeHandler('applicant')({ id: performer.id || '', person: performer?.person?.scientist });
      }
    },
    [project, makeChangeHandler],
  );

  const handleFinResponsibleChange = useCallback(
    (member: Member) => {
      makeChangeHandler('financeResponsible')(member);
    },
    [makeChangeHandler],
  );

  const handleReqResponsibleChange = useCallback(
    (member: Member) => {
      makeChangeHandler('requestResponsible')(member);
    },
    [makeChangeHandler],
  );

  const projectRowConverter = useCallback<(row: T.Entry) => F.Translation>(row => {
    const dates = row.period ? row.period.split(' - ') : ['', ''];
    return {
      id: row.id,
      name: row.name || row.Name,
      type: { label: row.projectType },
      number: row.projectNumber || row.ProjectNumber,
      startDate: dates[0],
      endDate: dates[1],
    };
  }, []);

  const formatScientist = useCallback((scientist: Scientist | null) => {
    return [
      scientist?.phone ? `Тел.: ${scientist?.phone}` : '',
      scientist?.mobilePhone && scientist?.phone !== scientist?.mobilePhone ? `Моб.: ${scientist?.mobilePhone}` : '',
      scientist?.email ? `e-mail: ${scientist?.email}` : '',
    ]
      .filter(x => x)
      .join(', ');
  }, []);

  const projectOriginal = useMemo((): Original => {
    return {
      id: purchaseRequest?.project?.id || '',
      name: purchaseRequest?.project ? `${purchaseRequest?.project.number}, ${purchaseRequest?.project.name}` : '',
    };
  }, [purchaseRequest]);

  const displayRevFields: boolean = useMemo(() => {
    return (purchaseRequest?.status?.value && purchaseRequest?.status?.value !== PurchaseRequestStatus.DRAFT) || false;
  }, [purchaseRequest]);

  const projectTooltipTxt =
    'Выберете проект из списка проектов в которых вы являетесь руководителем или ответственным исполнителем';

  const purchaseRequestNumberTooltipTxt = 'Заполняется при регистрации заявки на закупку в соответствии с внутренним регламентом';

  const purchaseRequestSendDateTooltipTxt = 'Дата подачи заявки на рассмотрение';

  const purchaseRequestApplicantTxt = 'Заявителем может быть только руководитель или ответственный исполнитель проекта';

  const purchaseRequestDepartmentTxt = 'Ответственное подразделение за приемку товара/услуг/работ';

  return {
    contractKindOptions,
    projectPerformerOptions,
    handleApplicantChange,
    handleFinResponsibleChange,
    handleReqResponsibleChange,
    projectRowConverter,
    formatScientist,
    projectOriginal,
    displayRevFields,
    projectTooltipTxt,
    purchaseRequestNumberTooltipTxt,
    purchaseRequestSendDateTooltipTxt,
    purchaseRequestApplicantTxt,
    purchaseRequestDepartmentTxt,
  };
}
