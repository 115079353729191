import { useState, useLayoutEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import * as BackendAPI from 'services/BackendAPI';

import type { Recognition } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  onClose(): void;
};

export function useController({ onClose }: Props) {
  const [recognitionInfo, setRecognitionInfo] = useState<Recognition | null>();

  const {
    look: { id },
  } = useFormContext();
  const tableStreams = useLocalTableStreams();
  const isApproved: boolean = recognitionInfo?.status.value === 'APPROVED';

  const { methods: getRecognition } = BackendAPI.useBackendAPI('GetRecognition', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Recognition;
      ReactDOM.unstable_batchedUpdates(() => {
        setRecognitionInfo(preparedData);
      });
    },
  });

  const { methods: approveRecognition } = BackendAPI.useBackendAPI('ApproveRecognition', {
    onSuccessfullCall: () => {
      showNotification({
        message: !isApproved ? 'Награда утверждена' : 'Награда переведена в статус "Добавлена"',
        theme: 'success',
      });
      tableStreams.reloadTable.push();
      onClose();
    },
  });

  const handleConfirmButtonClick = useCallback(() => {
    if (id) approveRecognition.callAPI({ id, isApproved });
  }, [approveRecognition, id, isApproved]);

  useLayoutEffect(() => {
    if (id) getRecognition.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    recognitionInfo,
    handleConfirmButtonClick,
    isApproved,
  };
}
