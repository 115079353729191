import React from 'react';

import { ConfirmPopup, TextArea, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { TenderRequestType } from 'utils/Enums';

// eslint-disable-next-line max-len
import { Component as RequestViewTemplate } from 'features/Table/specifications/GetRequestList/LeftPanelForThirdLevel/modalTemplates/RequestViewTemplate';
// eslint-disable-next-line max-len
import { Component as NirRequestViewTemplate } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel/modalTemplates/NirRequestViewTemplate';
// eslint-disable-next-line max-len
import { Component as MobileRequestViewTemplate } from 'features/Table/specifications/GetMobileRequestList/LeftPanelForThirdLevel/modalTemplates/MobileRequestViewTemplate';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { buttons, isViewFormOpen, setIsViewFormOpen, statusChangeInfo } = useController({ tableState, customState });
  const type = customState.type;
  return (
    <>
      {isViewFormOpen && !!type && (
        <>
          {type === TenderRequestType.PROGRAM_REQUEST && (
            <RequestViewTemplate
              isOpen={isViewFormOpen}
              onClose={() => setIsViewFormOpen(false)}
              id={tableState.selectedRows[0]?.id}
              relatedTableState={tableState}
            />
          )}
          {type === TenderRequestType.NIR_REQUEST && (
            <NirRequestViewTemplate
              isOpen
              onClose={() => setIsViewFormOpen(false)}
              relatedTableState={tableState}
              nirRequest={null}
              id={tableState.selectedRows[0]?.id}
            />
          )}
          {type === TenderRequestType.MOBILE_REQUEST && (
            <MobileRequestViewTemplate
              isOpen
              onClose={() => setIsViewFormOpen(false)}
              relatedTableState={tableState}
              id={tableState.selectedRows[0]?.id}
              type="NPR"
            />
          )}
        </>
      )}
      <Toolbar buttons={buttons} />
      {statusChangeInfo?.isStatusModalOpen && (
        <ConfirmPopup
          title="Смена статуса"
          isOpen={statusChangeInfo.isStatusModalOpen}
          onClose={() => statusChangeInfo.setIsStatusModalOpen(false)}
          onConfirm={statusChangeInfo.changeStatus}
          okButtonText={statusChangeInfo.statusLabel}
        >
          <TextArea settings={{ rows: 5 }} value={statusChangeInfo.statusMessage} onChange={statusChangeInfo.setStatusMessage} />
        </ConfirmPopup>
      )}
      {statusChangeInfo?.isApprovementModalOpen && (
        <ExpertEstimateModal
          isOpen={statusChangeInfo.isApprovementModalOpen}
          onClose={() => statusChangeInfo.setIsApprovementModalOpen(false)}
          onSubmit={statusChangeInfo.saveApprovement}
        />
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
