import React, { memo, useEffect } from 'react';

import { Event, Table } from 'types/models';
import { Component as EventParticipations } from 'features/Form/looks/event/views/EventConferenceParticipations';

type Props = {
  participations: Event.Participations[];
  setIsFormOpen: any;
  selectedRowIndex: number | null;
  setSelectedRowIndex: React.Dispatch<number | null>;
  workMode: Table.WorkMode;
  fetch: () => void;
  checkIsThereNoConnectedPublications: () => void;
};

const Participations = ({
  workMode,
  participations,
  setIsFormOpen,
  selectedRowIndex,
  setSelectedRowIndex,
  fetch,
  checkIsThereNoConnectedPublications,
}: Props) => {
  useEffect(() => {
    checkIsThereNoConnectedPublications();
    // eslint-disable-next-line
  }, []);
  return (
    <EventParticipations
      workMode={workMode}
      participations={participations}
      setIsFormOpen={setIsFormOpen}
      selectedRowIndex={selectedRowIndex}
      setSelectedRowIndex={setSelectedRowIndex}
      fetch={fetch}
    />
  );
};

export const Component = memo(Participations);
