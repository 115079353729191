import { SetStateAction, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useStream } from 'StreamRx';
import * as BackendAPI from 'services/BackendAPI';

import { useLocalTableStreams } from 'features/Table/hooks';

import { ScientificIndex as S, Table } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { useAppDataContext } from 'features/AppData/context';
import { streams } from './streams';
import { getFieldsFromScientificIndex, getFieldsFromServerChilds, provideSavedValuesInFields } from './helpers';
import { ChildField, Field, Mode, PublicationType, State } from './types';
import { showNotification } from 'features/Notifications';
import { IndicatorsDeps } from '.';

export function makeUseCustomController(
  deps: Table.TemplatesTableDependencies,
  { citationSystems, publicationId, publicationType, workMode }: IndicatorsDeps,
) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    const [isOpenEditor, setIsOpenEditor] = useState(false);
    const [mode, setMode] = useState<Mode>(null);
    const [citationSystem, setCitationSystem] = useState<CitationSystem | null>(null);
    const [selectedScientificIndex, setSelectedScientificIndex] = useState<S.ScientificIndex | null>(null);
    const [publicationCitationSystemIndex, setPublicationCitationSystemIndex] = useState<string | null>(null);
    const [fields, setFields] = useState<Record<string, Field>>({});
    const [childFields, setChildFields] = useState<ChildField[]>([]);

    const { scientificIndexes, enumMap } = useAppDataContext();

    const tableStreams = useLocalTableStreams();

    const { methods: getCitationSystemIndex } = BackendAPI.useBackendAPI('GetPublicationCitationSystemIndex', {
      onSuccessfullCall: ({ data: { scientificIndex, publicationCitationSystemIndex: index, savedValues, savedChilds } }) => {
        ReactDOM.unstable_batchedUpdates(() => {
          const initialFields = getFieldsFromScientificIndex(scientificIndex);
          const foundCitationSystem = citationSystems.find(x => x.id === scientificIndex.citationSystem.id) ?? null;
          const updatedFields = provideSavedValuesInFields(savedValues, initialFields.parentFields);
          const savedChildFields = getFieldsFromServerChilds(savedChilds);
          setChildFields(savedChildFields);
          setPublicationCitationSystemIndex(index);
          setFields(updatedFields as SetStateAction<Record<string, Field>>);
          setCitationSystem(foundCitationSystem);
          setSelectedScientificIndex(scientificIndex);
        });
      },
    });

    const { methods: saveCitationSystemIndexApi } = BackendAPI.useBackendAPI('SavePublicationCitationSystemIndex', {
      onSuccessfullCall: () => {
        reloadTable();
        showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });
      },
      onFailedCall: () => {},
    });

    const { methods: deleteRowAPI } = BackendAPI.useBackendAPI('DeleteModel', {
      onSuccessfullCall: () => {
        reloadTable();
        showNotification({ message: 'Элемент успешно удален', theme: 'success' });
      },
    });

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push();
    }, [tableStreams.reloadTable]);

    const saveCitationSystemIndex = useCallback(() => {
      streams.saveCitationSystemIndex.push();
    }, []);

    const deleteCitationSystemIndex = useCallback(() => {
      if (selectedRows[0]) streams.deleteCitationSystemIndex.push(selectedRows[0].id);
    }, [selectedRows]);

    const getCitationSystemInfo = useCallback(() => {
      if (selectedRows[0]) streams.getCitationSystemIndex.push(selectedRows[0].id);
    }, [selectedRows]);

    const openEditor = useCallback(() => {
      setIsOpenEditor(true);
      tableStreams.toggleSecondLevelPanel.push();
    }, [tableStreams.toggleSecondLevelPanel]);

    const closeEditor = useCallback(() => {
      setIsOpenEditor(false);
      tableStreams.toggleSecondLevelPanel.push();
    }, [tableStreams.toggleSecondLevelPanel]);

    useEffect(() => {
      if (mode === 'edit' && selectedRows) {
        getCitationSystemIndex.callAPI({ citationSystemIndexId: selectedRows[0].id });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    useStream(
      () => streams.deleteCitationSystemIndex,
      (rowId: string) => {
        deleteRowAPI.callAPI({ entityName: 'PublicationCitationSystemIndex', rowId, commandName: 'DeleteEntity' });
      },
      [],
    );

    useStream(
      () => streams.getCitationSystemIndex,
      (id: string) => {
        getCitationSystemIndex.callAPI({ citationSystemIndexId: id });
      },
      [],
    );

    useStream(
      () => streams.saveCitationSystemIndex,
      () => {
        if (citationSystem && selectedScientificIndex) {
          saveCitationSystemIndexApi.callAPI({
            publicationCitationSystemIndex,
            publicationCitationSystemId: citationSystem?.publicationCitationSystemId,
            scientificIndexId: selectedScientificIndex?.id,
            values: Object.values(fields),
            childs: childFields,
          });
        }
      },
      [fields, citationSystem, selectedScientificIndex, childFields, publicationCitationSystemIndex],
    );

    useEffect(() => {
      if (!citationSystems || citationSystems.length === 0) {
        setCitationSystem(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citationSystems]);

    return {
      mode,
      isOpenEditor,
      citationSystem,
      selectedScientificIndex,
      fields,
      childFields,
      enumMap,
      publicationId,
      citationSystems,
      publicationType: publicationType as PublicationType,
      scientificIndexes: scientificIndexes || [],
      setMode,
      setSelectedScientificIndex,
      setCitationSystem,
      setFields,
      setChildFields,
      saveCitationSystemIndex,
      deleteCitationSystemIndex,
      setPublicationCitationSystemIndex,
      getCitationSystemInfo,
      openEditor,
      closeEditor,
      workMode,
    };
  };
}
