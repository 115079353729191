import React from 'react';

import { Toolbar, Reference, FormComponent, TextArea, TextDateTime, TextInput, TextInputMode } from 'components';

import { State } from '../makeUseCustomController';
import { SecondLevelProps } from 'features/Table/types';

export const ComponentForSecondLevel = ({ customState }: SecondLevelProps<State>) => {
  const { isChangeFormOpen, buttons, formFields } = customState;

  return (
    <>
      {isChangeFormOpen && (
        <FormComponent.Template>
          <Toolbar buttons={buttons} mode="form" />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field>
                <Reference
                  value={formFields.citationSystem.value}
                  title={formFields.citationSystem.title}
                  relationTableModalTitle='Справочник "Системы цитирования"'
                  name="RefCitationSystem"
                  onChange={formFields.citationSystem.onChange}
                  theme="big"
                  isRequired={formFields.citationSystem.required}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.date.title} isRequired={!!formFields.date.required}>
                <TextDateTime
                  value={formFields.date.value}
                  onChange={formFields.date.onChange}
                  customStyles={{ maxWidth: '100%' }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label={formFields.hirshIndexValue.title}>
                <TextInput
                  mode={TextInputMode.number}
                  value={formFields.hirshIndexValue.value}
                  onChange={formFields.hirshIndexValue.onChange}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label={formFields.identifier.title}>
                <TextInput value={formFields.identifier.value} onChange={formFields.identifier.onChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label={formFields.note.title}>
                <TextArea settings={{ rows: 3 }} value={formFields.note.value} onChange={formFields.note.onChange} />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </FormComponent.Template>
      )}
    </>
  );
};
