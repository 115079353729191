import { Person } from 'types/models';
import * as R from 'ramda';

export const copyAcademicRank = (academicRank: Person.ScientistAcademicRank | null) => {
  if (academicRank?.originalId) {
    const copy: Person.ScientistAcademicRank = R.clone(academicRank);
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyJob = (job: Person.ScientistJob | null) => {
  if (job?.originalId) {
    const copy: Person.ScientistJob = R.clone(job);
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyEducation = (education: Person.ScientistEducation | null) => {
  if (education?.originalId) {
    const copy: Person.ScientistEducation = R.clone(education);
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyDegree = (degree: Person.ScientistDegree | null) => {
  if (degree?.originalId) {
    const copy: Person.ScientistDegree = R.clone(degree);
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyRank = (rank: Person.ScientistRank | null) => {
  if (rank?.originalId) {
    const copy: Person.ScientistRank = R.clone(rank);
    copy.id = null;
    return copy;
  }

  return null;
};
