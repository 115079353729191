export const SnilsMask = () => {
  return {
    // Mask
    mask: () => [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
    ],
  };
};
