import {
  SecurityDocumentMemberTypes,
  SecurityDocumentMember,
  SecurityDocumentRefElement,
  SecurityDocumentPayment,
} from 'types/models';
import { convertDocumentToRequest, convertFileToXML, convertReferenceItemToXML, getMaybeField } from '../commonConverters';
import { SecurityDocumentSaveInput } from './types';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { convertDepartmentToXML } from 'services/BackendAPI/configurations/rntd/requestConverters';

export function convertSecurityDocumentToXML(p: SecurityDocumentSaveInput) {
  const authors = p.members.filter(m => m.type?.value === SecurityDocumentMemberTypes.AUTHOR);
  const applicants = p.members.filter(m => m.type?.value === SecurityDocumentMemberTypes.APPLICANT);
  const admitted = p.members.filter(m => m.type?.value === SecurityDocumentMemberTypes.ADMITTED_PERSON);
  return {
    _attr: p.id ? { id: p.id } : {},
    Type: p.type?.value ?? 'PATENT',
    ...getMaybeField('RequestNumber', p.requestNumber),
    ...getMaybeField('RequestStatus', p.requestStatus?.value),
    ...getMaybeField('RequestDate', p.requestDate),
    ...getMaybeField('RequestSendDate', p.requestSendDate),
    ...getMaybeField('DocumentDate', p.documentDate),
    ...getMaybeField('DocumentReceiveDate', p.documentReceiveDate),
    ...getMaybeField('IsRus', p.isRus),
    ...getMaybeField('IsWorldwide', p.isWorldwide),
    ...getMaybeField('DocumentNumber', p.documentNumber),
    ...getMaybeField('InternalNumber', p.internalNumber),
    ...(p.type?.value !== 'KNOW_HOW' ? getMaybeField('DocumentStartDate', p.documentStartDate) : {}),
    ...(p.type?.value !== 'KNOW_HOW' ? getMaybeField('DocumentEndDate', p.documentEndDate) : {}),
    ...getMaybeField('StateNumber', p.stateNumber),
    ...getMaybeField('StateRegistrationDate', p.stateRegistrationDate),
    ...getMaybeField('IntellectualPropertyName', p.intellectualPropertyName),
    ...getMaybeField('Referat', p.referat),
    ...getMaybeField(p.type?.value === 'EVIDENCE' ? 'IntellectualPropertyKindEvidence' : 'IntellectualPropertyKind', {
      _attr: { id: p.intellectualPropertyKind?.id },
    }),
    ...getMaybeField('IntellectualPropertyKindNonRegistered', {
      _attr: { id: p.intellectualPropertyKindNonRegistered?.id },
    }),
    ...(p.balanceOrderNumber ? { BalanceOrderNumber: p.balanceOrderNumber } : {}),
    ...(p.loDate ? (p.type?.value === 'KNOW_HOW' ? { DocumentStartDate: p.loDate } : { BalanceLoDate: p.loDate }) : {}),
    ...(p.hiDate ? (p.type?.value === 'KNOW_HOW' ? { DocumentEndDate: p.hiDate } : { BalanceHiDate: p.hiDate }) : {}),
    ...(p.balancePrice ? { BalancePrice: p.balancePrice } : {}),
    ...(p.project?.id ? { Project: { _attr: { id: p.project?.id } } } : {}),
    Authors: authors && authors.length ? { Author: authors.map(convertAuthorToXML) } : {},
    Countries: p.countries && p.countries.length ? { Country: p.countries.map(convertReferenceItemToXML) } : {},
    Applicants: applicants && applicants.length ? { Applicant: applicants.map(convertApplicantToXML) } : {},
    AdmittedPersons: admitted && admitted.length ? { AdmittedPerson: admitted.map(convertAuthorToXML) } : {},
    Grntis: p.grntis && p.grntis.length ? { Grnti: p.grntis.map(convertSecurityDocumentRefElement) } : {},
    Udks: p.udks && p.udks.length ? { Udk: p.udks.map(convertSecurityDocumentRefElement) } : {},
    Okveds: p.okveds && p.okveds.length ? { Okved: p.okveds.map(convertSecurityDocumentRefElement) } : {},
    Lksetss: p.lksetss && p.lksetss.length ? { Lksets: p.lksetss.map(convertSecurityDocumentRefElement) } : {},
    ScienceBrunches:
      p.scienceBrunches && p.scienceBrunches.length
        ? { ScienceBrunch: p.scienceBrunches.map(convertSecurityDocumentRefElement) }
        : {},
    ScienceDomainInterrests:
      p.scienceDomainInterrests && p.scienceDomainInterrests.length
        ? { ScienceDomainInterrest: p.scienceDomainInterrests.map(convertSecurityDocumentRefElement) }
        : {},
    Pnrs: p.pnrs && p.pnrs.length ? { Pnr: p.pnrs.map(convertSecurityDocumentRefElement) } : {},
    Pnmitrs: p.pnmitrs && p.pnmitrs.length ? { Pnmitr: p.pnmitrs.map(convertSecurityDocumentRefElement) } : {},
    Pnis: p.pnis && p.pnis.length ? { Pni: p.pnis.map(convertSecurityDocumentRefElement) } : {},
    NtrStrategies:
      p.ntrStrategies && p.ntrStrategies.length ? { NtrStrategy: p.ntrStrategies.map(convertSecurityDocumentRefElement) } : {},
    TechnologyPlatforms:
      p.technologyPlatforms && p.technologyPlatforms.length
        ? { TechnologyPlatform: p.technologyPlatforms.map(convertSecurityDocumentRefElement) }
        : {},
    PriorityTechnologies:
      p.priorityTechnologies && p.priorityTechnologies.length
        ? { PriorityTechnology: p.priorityTechnologies.map(convertSecurityDocumentRefElement) }
        : {},
    DomainKnowledges:
      p.domainKnowledges && p.domainKnowledges.length
        ? { DomainKnowledge: p.domainKnowledges.map(convertSecurityDocumentRefElement) }
        : {},
    Oecds: p.oecds && p.oecds.length ? { Oecd: p.oecds.map(convertSecurityDocumentRefElement) } : {},
    NtiEteTechnologies:
      p.ntiEteTechnologies && p.ntiEteTechnologies.length
        ? { NtiEteTechnology: p.ntiEteTechnologies.map(convertSecurityDocumentRefElement) }
        : {},
    NtiMarkets: p.ntiMarkets && p.ntiMarkets.length ? { NtiMarket: p.ntiMarkets.map(convertSecurityDocumentRefElement) } : {},
    Payments: p.payments && p.payments.length ? { Payment: p.payments.map(convertPaymentToXML) } : {},
    Documents:
      p.documents && p.documents.length
        ? { Document: p.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)) }
        : {},
    Departments: p.departments && p.departments.length ? { Department: p.departments.map(convertDepartmentToXML) } : {},
    Mpks: p.mpks && p.mpks.length ? { Mpk: p.mpks.map(convertReferenceItemToXML) } : {},
  };
}

export function convertAuthorToXML(a: SecurityDocumentMember) {
  return {
    _attr: {
      ...(a.id ? { id: a.id } : {}),
      ...(a.person?.scientist?.id ? { scientistId: a.person?.scientist?.id } : {}),
      ...(a.contribution.toString()
        ? a.enterprise?.id
          ? { contractPortion: a.contribution }
          : { contribution: a.contribution }
        : {}),
      ...(a.contractNumber ? { contractNumber: a.contractNumber } : {}),
      ...(a.contractDate ? { contractDate: a.contractDate } : {}),
      ...(a.contractPortion ? { contractPortion: a.contractPortion } : {}),
      ...(a.contractNote ? { contractNote: a.contractNote } : {}),
      ...(a.enterprise?.id ? { enterpriseId: a.enterprise?.id } : {}),
    },
    ...getHistoricalSliceXML(a),
  };
}

export function convertApplicantToXML(a: SecurityDocumentMember) {
  return {
    _attr: {
      ...(a.id ? { id: a.id } : {}),
      ...(a.person?.scientist?.id ? { scientistId: a.person?.scientist?.id } : {}),
      contractPortion: a.contractPortion,
      ...(a.contractNumber ? { contractNumber: a.contractNumber } : {}),
      ...(a.contractDate ? { contractDate: a.contractDate } : {}),
      ...(a.contractPortion ? { contractPortion: a.contractPortion } : {}),
      ...(a.contractNote ? { contractNote: a.contractNote } : {}),
      ...(a.enterprise?.id ? { enterpriseId: a.enterprise?.id } : {}),
      type: a.enterprise?.id ? 'enterprise' : 'person',
    },
  };
}

export function convertSecurityDocumentRefElement(p: SecurityDocumentRefElement) {
  return {
    _attr: p.id ? { id: p.id } : {},
    Element: { _attr: p.ref?.id ? { id: p.ref?.id } : {} },
    IsActual: p.isActual,
  };
}
export function convertPaymentToXML(p: SecurityDocumentPayment) {
  return {
    _attr: p.id ? { id: p.id } : {},
    ...(p.paymentPurposeRequest?.id ? { PaymentPurposeRequest: { _attr: { id: p.paymentPurposeRequest?.id } } } : {}),
    ...(p.paymentPurpose?.id ? { PaymentPurpose: { _attr: { id: p.paymentPurpose?.id } } } : {}),
    ...getMaybeField('PayDate', p.payDate),
    ...(p.payBeforeDate ? { PayBeforeDate: p.payBeforeDate } : {}),
    Amount: p.amount || '0.00',
    ...convertFileToXML('File', p.file),
  };
}
