import { useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';

const useController = () => {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
    ],
    [setIsHelpFormOpen],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
};

export default useController;
