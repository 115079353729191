import React from 'react';

import { FormComponent, TextArea, TextAreaMode, TextInput, TextInputMode, Checkbox, CustomSelect } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function RequestMobileKindConsumptionForm({ viewMode, editMode, onClose }: Props) {
  const {
    requestKindConsumption,
    formFields,
    handleFormSubmit,
    modalTableRowKindConsumptionConverter,
    kindConsumptionTableConfig,
    incrimentKindConsumptionsTooltipTxt,
    requestKindConsumptionTableConfig,
    workMode,
    modalTableRowRequestKindConsumptionConverter,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.name.title} isRequired={formFields.name.required}>
            <TextInput value={formFields.name.value} onChange={formFields.name.onChange} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.position.title}
            isRequired={formFields.position.required}
            tooltip={formFields.position.tooltipText}
          >
            <TextInput
              mode={TextInputMode.number}
              value={formFields.position.value}
              onChange={formFields.position.onChange}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
          <FormComponent.Field label="" labelWidth={10}>
            <Checkbox
              label="Активный"
              checked={!!formFields.isActive.value}
              onChange={formFields.isActive.onChange}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label={formFields.description.title}>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3 }}
              value={formFields?.description.value || ''}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields?.kindConsumption.title} tooltip={formFields.kindConsumption.tooltipText}>
            <CustomSelect
              rowsNumber={1}
              original={formFields?.kindConsumption?.value}
              relationTableModalTitle={formFields?.kindConsumption.title}
              onChange={formFields?.kindConsumption.onChange}
              modalTableRowConverter={modalTableRowKindConsumptionConverter}
              modalTableSpecification={kindConsumptionTableConfig}
              disabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          {(requestKindConsumption?.incrimentKindConsumptions !== undefined &&
            requestKindConsumption?.incrimentKindConsumptions?.length <= 0) ||
          workMode === 'addMode' ? (
            <FormComponent.Field label="Входит в группу" tooltip={formFields?.incrimenator?.tooltipText}>
              <CustomSelect
                rowsNumber={1}
                original={
                  formFields?.incrimenator?.value
                    ? {
                        id: formFields?.incrimenator?.value?.id || '',
                        name: formFields?.incrimenator?.value?.name || '',
                      }
                    : { id: '', name: '' }
                }
                relationTableModalTitle={'Входит в группу'}
                onChange={formFields.incrimenator.onChange}
                modalTableRowConverter={modalTableRowRequestKindConsumptionConverter}
                modalTableSpecification={requestKindConsumptionTableConfig}
                disabled={viewMode}
              />
            </FormComponent.Field>
          ) : (
            <FormComponent.Field label="Суммирует" tooltip={incrimentKindConsumptionsTooltipTxt}>
              <div>{requestKindConsumption?.incrimentKindConsumptions?.map(kc => kc.name)?.join(', ')}</div>
            </FormComponent.Field>
          )}
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(RequestMobileKindConsumptionForm);
