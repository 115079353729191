import React, { useEffect, useState } from 'react';

import { Tabs, Tab, FormComponent } from 'components';

import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { EventTypes } from 'utils/Enums/EventTypes';
import { DocumentsWarningPopup } from 'features/Form/looks/event/views/DocumentsWarningPopup';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useFormContext } from 'features/Form/hooks';
import { EventHeader } from 'features/Form/views/ModalHeader';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const EventFormConcert = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    isDocumentsWarningPopupOpen,
    tabsId,
    changeDepartments,
    changeEnterprises,
    changeProjects,
    controllerIsForm,
    departments,
    documents,
    enterprises,
    eventId,
    formFields,
    fetchEvent,
    handleFormSubmit,
    isOtherSelected,
    isSelectedPeriod,
    isTSUSelected,
    participations,
    projects,
    refs,
    relatedTableState,
    setDocuments,
    termsList,
    workMode,
    onCloseDocumentsWarningPopup,
    onConfirmDocumentsWarningPopup,
    status,
    userPermission,
    withHeaderId,
  } = useController({
    onClose,
  });

  const {
    look: { id, setTitle },
  } = useFormContext();

  const isApproved = status.status === 'APPROVED';
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  useEffect(() => {
    if (controllerIsForm && !(selectedRowIndex === null)) {
      controllerIsForm.setParticipation(participations[selectedRowIndex] || null);
    }
    // eslint-disable-next-line
  }, [selectedRowIndex]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        EventHeader({
          workMode,
          departments,
          status: status.text || '',
          eventId: id || '',
          eventTypeCode: EventTypes.EVENT_CONCERT.code,
        }),
      );
    }
  }, [departments, id, setTitle, status.text, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
        isDisabled={isLoading}
      />

      <SuchLikeEvent
        mode="edit"
        handleCloseOuterForm={onClose}
        command="RefEventFeedlineMessage"
        eventTypeCode={EventTypes.EVENT_CONCERT.code}
        eventName={formFields.name.value as string}
        startDate={formFields.startDate.value as string}
        endDate={formFields.endDate.value as string}
      />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab
            title="О мероприятии"
            isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.CONCERT_EDIT_APPROVED_ABOUT)}
          >
            <TabsContent.AboutComponent
              eventId={eventId}
              changeDepartments={changeDepartments}
              changeEnterprises={changeEnterprises}
              departments={departments}
              enterprises={enterprises}
              formFields={formFields}
              isOtherSelected={isOtherSelected}
              isSelectedPeriod={isSelectedPeriod}
              isTSUSelected={isTSUSelected}
              refs={refs}
              termsList={termsList}
              viewMode={viewMode}
              withHeaderId={workMode !== 'addMode' ? withHeaderId : undefined}
            />
          </Tab>
          <Tab title="Участники">
            <TabsContent.Participations
              participations={participations}
              selectedRowIndex={selectedRowIndex}
              setIsFormOpen={controllerIsForm}
              setSelectedRowIndex={setSelectedRowIndex}
              workMode={workMode}
              fetch={fetchEvent}
            />
          </Tab>
          <Tab
            title="Финансовая поддержка"
            isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.CONCERT_EDIT_APPROVED_FINANCING)}
          >
            <TabsContent.FinancialSupport
              projects={projects}
              relatedTableState={relatedTableState}
              setProjects={changeProjects}
              workMode={workMode}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={eventId} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <DocumentsWarningPopup
        isOpen={isDocumentsWarningPopupOpen}
        onConfirm={onConfirmDocumentsWarningPopup}
        onClose={onCloseDocumentsWarningPopup}
      />
    </FormComponent.Template>
  );
};

export const Component = React.memo(EventFormConcert);
