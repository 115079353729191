import React from 'react';
import { FormComponent, Tab, Tabs } from 'components';
import {
  GetPersonalAllProjectList,
  GetPersonalOtherProjectList,
  GetPersonalProjectList,
  GetPersonalContestRequestList,
  GetPersonalProjectAssignmentList,
} from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { GetPersonalPurchaseRequestList } from 'features/Table/specifications/GetPersonalPurchaseRequestList';
import { isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';

type Props = {
  personId: string | null;
};

export function RequestAndProjects(props: Props) {
  const { personId } = props;

  if (!personId) {
    return null;
  }

  const { userPermission } = useAppDataContext();

  return (
    <Tabs>
      <Tab title="Мои научные проекты">
        <DataGrid specification={GetPersonalProjectList({ hasToolbarButtonMode: true })} hasPersonalMode />
      </Tab>
      <Tab
        title="Актуализация моих научных проектов"
        isVisible={isHasSomePermission(userPermission, [
          Permits.CONTEST_REQUEST_VIEW_ALL,
          Permits.CONTEST_REQUEST_VIEW_BY_MANAGEMENT,
        ])}
      >
        <DataGrid specification={GetPersonalContestRequestList()} />
      </Tab>
      <Tab title="Поручения по проектам">
        <DataGrid specification={GetPersonalProjectAssignmentList({ isFiltersHidden: true })} hasPersonalMode />
      </Tab>
      <Tab
        title="Закупки по проектам"
        isVisible={isHasSomePermission(userPermission, [
          Permits.PURCHASE_REQUEST_VIEW_ALL,
          Permits.PURCHASE_REQUEST_VIEW_BY_MANAGEMENT,
        ])}
      >
        <DataGrid specification={GetPersonalPurchaseRequestList()} hasPersonalMode />
      </Tab>
      <Tab title="Мои проекты в других организациях">
        <FormComponent.Description mode="warning">
          В этом разделе вы можете добавить информацию о своём участии в научном проекте, выполненном в другом университете
          (организации).
        </FormComponent.Description>
        <DataGrid specification={GetPersonalOtherProjectList()} />
      </Tab>
      <Tab title="Список научных проектов Университета">
        <DataGrid
          specification={GetPersonalAllProjectList({ hasToolbarButtonMode: true, defaultYearChecked: false })}
          hasPersonalMode
        />
      </Tab>
    </Tabs>
  );
}
