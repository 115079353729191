import React from 'react';

import { Person, UserPermission } from 'types/models';
import { ReferenceItem } from 'components';
import { UpperForm } from './UpperForm';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;

  onChange(fieldName: keyof Person.Scientist, value: string | ReferenceItem | null): void;
};

function Component(props: Props) {
  const { person, userPermission, onChange } = props;
  const scientist = person?.scientist ?? null;
  return <UpperForm userPermission={userPermission} scientist={scientist} person={person} onChange={onChange} />;
}

export const Main = React.memo(Component);
