import React, { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import * as R from 'ramda';

import { buttonIcons, ConfirmPopup, ListEdit, FormComponent } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Person } from 'types/models';
import { EnumMap } from 'types/models/Table';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { showNotification } from 'features/Notifications';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';
import { useAppDataContext } from 'features/AppData/context';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { format } from 'date-fns';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { LinkedHistoryEntities } from 'features/Form/views/LinkedHistoryEntities';
import { validate } from './validate';
import { Fields } from './Fields/Fields';
import { Color } from 'constants/colors';

type Props = {
  person: Person.ScientistPerson | null;
  enumMap: EnumMap;
  disabled: boolean;
  onChangeEducations(educations: Person.ScientistEducation[]): void;
};

function Component(props: Props) {
  const { person, onChangeEducations, enumMap, disabled } = props;
  const isEditButtonDisabled = useCallback(eduItem => !(eduItem && !eduItem.isTSU), []);
  const { currentPerson, userPermission } = useAppDataContext();

  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [isDeleteHistoryWarningOpen, setIsDeleteHistoryWarningOpen] = useState<boolean>(false);
  const [isEntityRelationOpen, setIsEntityRelationOpen] = useState<boolean>(false);

  const { methods: GetIsLinkedHistoryAPI, state: GetIsLinkedHistoryState } = BackendAPI.useBackendAPI('IsLinkedHistory');

  const isLinkedHistoryPending = useMemo(() => getIsPending(GetIsLinkedHistoryState), [GetIsLinkedHistoryState]);

  const onDeleteButtonClick = useCallback((nextDeletedIndex: number) => {
    setSelectedIndex(nextDeletedIndex);
    setIsDeleteConfirmOpen(true);
  }, []);

  const onDeleteReset = useCallback(() => {
    setSelectedIndex(null);
    setIsDeleteConfirmOpen(false);
  }, []);

  const selectedEducation = useMemo(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      return person.scientistEducations[selectedIndex!];
    }

    return null;
  }, [selectedIndex, person]);

  const removeEducation = useCallback(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      const scientistEducationsCopy = R.clone(person?.scientistEducations || []);
      const isReadOnlyEducation = Boolean(selectedEducation?.isReadOnly);

      if (isReadOnlyEducation) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.SCIENTIST_JOB_EDUCATION_DEGREE_RANK_DELETE);
        if (!isUserHasPermission) {
          showNotification({ message: 'Нет прав на удаление обучений пришедших по интеграции', theme: 'danger' });
          return;
        }
        const deletionDate = `${format(new Date(), formatDateTimeStr)}`;
        const deletedBy = currentPerson?.fullName || '';
        const nextScientistEducation: Person.ScientistEducation = {
          ...selectedEducation!,
          isActive: false,
          deletionDate,
          deletedBy,
        };
        const nextScientistEducations = R.pipe<
          Person.ScientistEducation[],
          Person.ScientistEducation[],
          Person.ScientistEducation[]
        >(
          R.remove(selectedIndex, 1),
          R.insert(selectedIndex, nextScientistEducation),
        )(scientistEducationsCopy);
        onChangeEducations(nextScientistEducations);

        showNotification({ message: 'Обучение помечено удаленным', theme: 'success' });
      } else {
        const nextScientistEducations = R.remove<Person.ScientistEducation>(selectedIndex, 1)(scientistEducationsCopy);
        onChangeEducations(nextScientistEducations);
        showNotification({ message: 'Обучение удалено', theme: 'success' });
      }
    }
  }, [currentPerson?.fullName, onChangeEducations, person, selectedEducation, selectedIndex, userPermission]);

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmOpen(false);

    if (selectedIndex === null) {
      return;
    }

    if (selectedEducation?.id) {
      GetIsLinkedHistoryAPI.callAPI(
        { type: LinkedHistoryType.EDUCATION, id: selectedEducation.id },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.isSuccess) {
              setIsDeleteHistoryWarningOpen(true);
            } else {
              removeEducation();
            }
          },
        },
      );
    } else {
      removeEducation();
    }
  }, [selectedIndex, selectedEducation?.id, GetIsLinkedHistoryAPI, removeEducation]);

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);
  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.ScientistEducation>[]>(
    () => [
      {
        title: 'Удалить',
        icons: buttonIcons.delete,
        isDisabled: false,
        checkIsDisabled: (education: Person.ScientistEducation | null) => {
          if (education === null || isLinkedHistoryPending) {
            return true;
          }

          const isEducationReadonly = education?.isReadOnly;
          const isEducationActive = education?.isActive;
          if (isEducationReadonly) {
            if (isEducationActive) {
              return false;
            }

            return true;
          }

          return false;
        },
        onClick: (_, educationIndex: number | null) => {
          if (educationIndex !== null) {
            onDeleteButtonClick(educationIndex);
          }
        },
      },
      {
        icons: buttonIcons.toolbarQuestion,
        title: 'Задать вопрос администратору по сведениям об обучении ',
        code: 'question',
        isDisabled: false,
        onClick: handleQuestionButtonClick,
      },
    ],
    [handleQuestionButtonClick, isLinkedHistoryPending, onDeleteButtonClick],
  );

  const closeDeleteHistoryWarning = useCallback(() => {
    setIsDeleteHistoryWarningOpen(false);
  }, []);

  const confirmDeleteHistoryWarning = useCallback(() => {
    closeDeleteHistoryWarning();
    setIsEntityRelationOpen(true);
  }, [closeDeleteHistoryWarning]);

  const closeEntityRelation = useCallback(() => {
    setIsEntityRelationOpen(false);
  }, []);

  return (
    <>
      <FormComponent.Description mode="warning">
        Сведения об обучении в университете подгружаются из базы данных деканатов университета. При желании, Вы можете добавить
        сведения об обучении в других университетах (статус — Аспирант/Докторант/Соискатель/Студент другой организации)
      </FormComponent.Description>
      <ListEdit
        isRequired
        withMessages
        isPersonalTable
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        visibleToolbarButtons={['add', 'edit']}
        rows={person?.scientistEducations ?? []}
        onChange={onChangeEducations}
        getIsEditDisabled={isEditButtonDisabled}
        extraToolbarButtons={extraToolbarButtons}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        columns={[
          { label: 'Статус', formatValue: row => row.status.label },
          { label: 'Организация', formatValue: row => row.enterprise },
          { label: 'Специальность', formatValue: row => row.refSpeciality?.label ?? row.speciality ?? '' },
          { label: 'Подразделение', formatValue: row => row.refDepartment?.label ?? row.department ?? '' },
          { label: 'Дата начала', formatValue: row => row.dateReceipt ?? '' },
          { label: 'Дата окончания', formatValue: row => row.dateDismissal ?? '' },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) => (creationSource ? `${creationSource} (${creationDate})` : ''),
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationSource ? `${modificationSource} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletedBy && deletionDate ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        specification={{
          mode: 'customComponent',
          validation: {
            checkIsValid: education => validate(education).every(x => x.isValid),
            onInvalidate: education => {
              const info = validate(education);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
          renderComponent: (education, setEducation) => (
            <Fields education={education} enumMap={enumMap} onChange={setEducation} />
          ),
        }}
      />
      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isDeleteConfirmOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={onDeleteConfirm}
        onClose={onDeleteReset}
        icon="warning"
      />
      <ConfirmPopup
        title="Удаление обучения"
        isOpen={isDeleteHistoryWarningOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={confirmDeleteHistoryWarning}
        onClose={closeDeleteHistoryWarning}
        icon="warning"
      >
        <span>
          Выбранный на удаление элемент используется в историческом срезе.
          <br />
          Удаление на данный момент невозможно. Вы можете изменить связь в историческом срезе, указав другой элемент. Продолжить?
        </span>
      </ConfirmPopup>
      <LinkedHistoryEntities
        personId={person?.id}
        isOpen={isEntityRelationOpen}
        onClose={closeEntityRelation}
        id={selectedEducation?.id ?? '-1'}
        type={LinkedHistoryType.EDUCATION}
      />
    </>
  );
}

export const EducationDetails = React.memo(Component);
