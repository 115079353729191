import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { block } from 'bem-cn';

import { buttonIcons, Toolbar, FormComponent } from 'components';

import { NirRequest, Person } from 'types/models';
import { formatJob } from 'features/SelectPerson/helpers';
import { BuildReportPopup, useReportsHook, Reports } from 'features/BuildReportPopup';
import { ValueOf } from 'types/helpers';
import { PortfolioCard } from 'features/Form/looks/person';
import { EditPerformerFields } from 'features/Form/looks/nirRequest/NirRequestForm/views';
import {
  formatDegreeRank,
  formatIdentifiers,
  formatIndexHirsh,
  getHeadNirRequest,
} from 'features/Form/looks/nirRequest/NirRequestForm/helpers';

import './style.scss';

const b = block('nir-leader-info');

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function LeaderInfo(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;

  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);

  const nirHead = getHeadNirRequest(nirRequest);

  const onChangePerformer = (updatedPerformer: NirRequest.Performer) => {
    const index = nirRequest?.performers.findIndex(x => x.role?.value === 'LEADER') ?? 0;
    const performers = R.set(R.lensIndex(index), updatedPerformer, nirRequest?.performers ?? []);
    makeChangeHandler('performers')(performers);
  };

  const formatName = (person: Person.ScientistPerson) => {
    const { fullName, scientist } = person;
    const englishFirstName = scientist?.englishFirstName ?? '';
    const englishLastName = scientist?.englishLastName ?? '';
    const englishPatronymic = scientist?.englishPatronymic ?? '';

    return `${fullName} (${englishLastName} ${englishFirstName} ${englishPatronymic})`;
  };

  const closePersonModal = useCallback(() => {
    setIsOpenPersonModal(false);
  }, []);

  const openPersonModal = useCallback(() => {
    setIsOpenPersonModal(true);
  }, []);

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  return (
    <div className={b()}>
      <div className={b('toolbar')}>
        <Toolbar
          buttons={[
            {
              icons: buttonIcons.info,
              title: 'Просмотр сведений о персоне',
              code: 'personInfo',
              onClick: openPersonModal,
            },
            {
              icons: buttonIcons.print,
              title: 'Персональный отчет',
              code: 'personReport',
              onClick: () => handleSetCurrentReport({ name: Reports.Personal.caption, value: Reports.Personal.name }),
            },
          ]}
        />
      </div>
      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column specialStyles={{ width: '400px' }}>
            <FormComponent.Line>
              <FormComponent.Field label="Фамилия, имя, отчество">
                <span>{nirHead?.person ? formatName(nirHead.person) : ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Дата рождения">
                <span>{nirHead?.person?.scientist?.dateBirth ?? ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Контакты">
                <span>{nirHead?.person?.scientist?.mobilePhone ?? ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Ученая степень звание">
                <span>{nirHead ? formatDegreeRank(nirHead) : ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Основное место работы">
                <span>{nirHead?.job ? formatJob(nirHead.job) : ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Гражданство">
                <span>{nirHead?.citizenship?.label ?? ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Участия в международных научных сообществах">
                <span />
              </FormComponent.Field>
            </FormComponent.Line>

            <FormComponent.Line>
              <FormComponent.Field label="Идентификаторы автора в базах цитирования">
                <span>{nirHead ? formatIdentifiers(nirHead) : ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Индекс Хирша автора">
                <span>{nirHead ? formatIndexHirsh(nirHead) : ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Публикаций Scopus/WoS (с Q1, Q2)">
                <span>{nirHead?.foreignPerformerQ1Q2PublicationQty ?? ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column specialStyles={{ width: 'calc(100% - 400px)' }}>
            <>
              {nirHead && <EditPerformerFields disabled={disabled} performer={nirHead} onChangePerformer={onChangePerformer} />}
            </>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Wrapper>
      <PortfolioCard
        isOpen={isOpenPersonModal}
        id={isOpenPersonModal ? nirHead?.person?.id ?? null : null}
        onClose={closePersonModal}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        scientistId={nirHead?.person?.scientist?.id ?? ''}
      />
    </div>
  );
}
