import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as R from 'ramda';
import { setup } from 'bem-cn';
import { isValid as isValidDate } from 'date-fns';

import { RequiredBadge, ConfirmPopup, Tooltip, RelationTableModal } from 'components';

import { usePrivatePageContext } from 'App/PrivatePage/context';
import { ListEditTable, SortType } from './ListEditTable/ListEditTable';
import { CollapsibleComponent } from './CollapsibleComponent/CollapsibleComponent';
import { ModalComponent } from './ModalComponent/ModalComponent';
import { ToolbarButtons } from './ToolbarButtons/ToolbarButtons';
import { SubmitTable } from 'features/Table/streams';
import { formatStringToDate } from 'utils/Helpers';
import { showNotification } from 'features/Notifications';
import { Color } from 'constants/colors';
import { createSlot } from 'react-slotify';

import * as M from './model';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('list-edit');

export const HeadRightSlot = createSlot();

export type ExtendedRow<Row> = { extendedRowId: number } & Row;

type Props<TableState extends {}, Row> = {
  specification: M.ModeSpecification<Row, TableState>;
  title?: string;
  tooltipText?: string;
  rows: Row[];
  onChange(rows: Row[]): void;
  defaultRowsCount?: number;
  columns: M.Column<Row>[];
  isRequired?: boolean;
  isUniq?: boolean;
  withMessages?: boolean;
  isToolbarDisabled?: boolean;
  isDeleteConfirmEnabled?: boolean;
  maxHeight?: string;
  isCanMovingRows?: boolean;
  isSaveAndAddCustomComponent?: boolean;
  extraToolbarButtons?: M.ExtraToolbarButton<Row>[];
  extraFieldsToolbarButtons?: M.ExtraToolbarButton<Row>[];
  withAcceptButtons?: boolean;
  withoutHead?: boolean;
  onAccept?(row: Row): void;
  onAcceptanceCancel?(row: Row): void;
  getRowTheme?(row: Row): Color | '';
  editPermissionName?: string;
  addPermissionName?: string;
  acceptPermissionName?: string;
  hideToolbar?: boolean;
  onOpenedHandler?: () => void;
  onClosedHandler?: () => void;
  visibleToolbarButtons?: M.DefaultToolbarButton[];
  isFullScreenedTable?: boolean;
  isPersonalTable?: boolean;
  columnIndexesForSumTotal?: number[];
  columnIndexesForIntegerTotal?: number[];
  isVisibleFilters?: boolean;
  isSortEnabled?: boolean;
  getIsDeleteDisabled?: (row: Row | null) => boolean;
  getIsEditDisabled?: (row: Row | null) => boolean;
  getIsAddDisabled?: () => boolean;
  isModalHintHidden?: boolean;
  editButtonTitle?: string;
  onSelectedRowIndexCallback?: (selectedRowIndex: null | number) => void;
  onSelectedRowsCallback?: (selectedRows: ExtendedRow<Row>[]) => void;
  children?: JSX.Element;
};

const getUniq = <T,>(arr: T[]) => R.uniqWith<T, T>(R.equals, arr);

const Component = <CustomTableState extends {}, Row>(props: Props<CustomTableState, Row>) => {
  const {
    acceptPermissionName,
    addPermissionName,
    columnIndexesForSumTotal = [],
    columnIndexesForIntegerTotal = [],
    columns,
    defaultRowsCount,
    editButtonTitle,
    editPermissionName,
    extraFieldsToolbarButtons = [],
    extraToolbarButtons = [],
    getIsAddDisabled,
    getIsDeleteDisabled,
    getIsEditDisabled,
    getRowTheme,
    hideToolbar = false,
    isCanMovingRows = false,
    isDeleteConfirmEnabled,
    isFullScreenedTable = false,
    isModalHintHidden = false,
    isPersonalTable = false,
    isRequired = false,
    isSaveAndAddCustomComponent = false,
    isToolbarDisabled = false,
    isUniq = true,
    isVisibleFilters = false,
    isSortEnabled = false,
    maxHeight,
    onAccept,
    onAcceptanceCancel,
    onChange,
    onClosedHandler,
    onOpenedHandler,
    rows,
    specification,
    title,
    tooltipText,
    visibleToolbarButtons,
    withAcceptButtons = false,
    withMessages,
    withoutHead = false,
    onSelectedRowIndexCallback,
    onSelectedRowsCallback,
    children,
  } = props;
  const listEditObj = useRef<HTMLElement | null>(null);
  const MINIMAL_ROWS_COUNT = 3;
  const ROW_MIN_HEIGHT = 26;

  const { isProfile } = usePrivatePageContext();

  useLayoutEffect(() => {
    const parentElementHeight = (listEditObj as any)?.current?.clientHeight || 0;

    let sumFullHeight = 0;
    if ((listEditObj as any)?.current) {
      const childRows = (listEditObj as any)?.current?.parentElement?.querySelectorAll(
        '.list-edit-table tr:not(.list-edit-table__row_empty)',
      );
      if (childRows) {
        const tableChildren = Array.from(childRows) as HTMLElement[];
        if (tableChildren) {
          sumFullHeight = tableChildren.reduce((prevValue, nextValue) => prevValue + nextValue.clientHeight, 0);
        }
      }
    }
    const rowsFromHeight = Math.round(
      (parentElementHeight - sumFullHeight - ROW_MIN_HEIGHT + (isVisibleFilters ? 26 : 0)) / ROW_MIN_HEIGHT,
    );
    setFullScreenRowsCount(rowsFromHeight < MINIMAL_ROWS_COUNT ? MINIMAL_ROWS_COUNT : rowsFromHeight);
  }, [rows, listEditObj, isVisibleFilters]);

  const defaultSortInfo = columns.find(x => x.defaultSort)?.defaultSort;
  const defaultSort: Record<number, SortType> = defaultSortInfo
    ? { [columns.findIndex(x => x.defaultSort)]: defaultSortInfo }
    : {};

  const [fullScreenRowsCount, setFullScreenRowsCount] = useState(MINIMAL_ROWS_COUNT);
  const [isOpenTableModal, setIsOpenTableModal] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);
  const [mode, setMode] = useState<M.Mode>(null);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState<boolean>(false);
  const [columnsFilters, setColumnsFilters] = useState<Record<number, string>>({});
  const [columnsSorting, setColumnsSorting] = useState<Record<number, SortType>>(defaultSort);

  const [isCollapsible, setIsCollapsible] = useState(false);
  const [customEditRow, setCustomEditRow] = useState<Row | null>(null);

  const [isAcceptButtonDisabled, setIsAcceptButtonDisabled] = useState<boolean>(false);
  const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState<boolean>(false);

  const extendedRows: ExtendedRow<Row>[] = rows.map((row, index) => ({ extendedRowId: index + 1, ...row }));

  const filteredRows = useMemo<ExtendedRow<Row>[]>(
    () =>
      extendedRows.filter((row, rowIndex) => {
        const accessedColumns = columns.filter((column, index) => {
          const filterValue = columnsFilters[index];
          if (filterValue) {
            switch (column.dataKind) {
              case M.DataKind.BOOLEAN:
                switch (filterValue) {
                  case 'true':
                    return !!column.formatValue(row, rowIndex);
                  case 'false':
                    return !column.formatValue(row, rowIndex);
                }
                break;
              default:
                const value = String(column.formatValue(row, rowIndex)).toLowerCase();
                return value.includes(filterValue.toLowerCase());
            }
          }
          return true;
        });
        return accessedColumns.length === columns.length;
      }),
    [extendedRows, columns, columnsFilters],
  );

  const sortedRows = useMemo<ExtendedRow<Row>[]>(() => {
    let filteredRowsCopy = R.clone(filteredRows);
    const columnsSortingEntries = Object.entries(columnsSorting);

    if (columnsSortingEntries.length) {
      const [columnIndex, sortType] = columnsSortingEntries[0];
      const column = columnIndex ? columns[Number(columnIndex)] : null;

      const isSortType = sortType !== 'none';
      if (column && isSortType) {
        filteredRowsCopy = filteredRowsCopy
          .map((filteredRow, index) => ({ filteredRow, index }))
          .sort((aRow, bRow) => {
            const isAsc = sortType === 'asc';
            const aValue = column.formatValue(aRow.filteredRow, aRow.index);
            const bValue = column.formatValue(bRow.filteredRow, bRow.index);
            if (aValue === bValue) return 0;
            if (aValue && !bValue) return isAsc ? 1 : -1;
            if (!aValue && bValue) return isAsc ? -1 : 1;
            switch (column.dataKind) {
              case M.DataKind.BOOLEAN:
                const isABoolean = ['true', 'false'].includes(String(aValue));
                const isBBoolean = ['true', 'false'].includes(String(bValue));
                const boolResult = (() => {
                  if (!isABoolean || !isBBoolean) {
                    if (!isABoolean && !isBBoolean) return 0;
                    if (isABoolean) return 1;
                    return -1;
                  }
                  if (aValue === bValue) return 0;
                  if (aValue) return 1;
                  return -1;
                })();
                return isAsc ? boolResult : -boolResult;
              case M.DataKind.INT:
                const aInt = parseInt(String(aValue));
                const bInt = parseInt(String(bValue));
                const isAInt = !isNaN(aInt);
                const isBInt = !isNaN(bInt);
                const intResult = (() => {
                  if (!isAInt || !isBInt) {
                    if (!isAInt && !isBInt) return 0;
                    if (isAInt) return 1;
                    return -1;
                  }
                  if (aInt === bInt) return 0;
                  if (aInt > bInt) return 1;
                  return -1;
                })();
                return isAsc ? intResult : -intResult;
              case M.DataKind.FLOAT:
                const aFloat = parseFloat(String(aValue));
                const bFloat = parseFloat(String(bValue));
                const isAFloat = !isNaN(aFloat);
                const isBFloat = !isNaN(bFloat);
                const floatResult = (() => {
                  if (!isAFloat || !isBFloat) {
                    if (!isAFloat && !isBFloat) return 0;
                    if (isAFloat) return 1;
                    return -1;
                  }
                  if (aFloat === bFloat) return 0;
                  if (aFloat > bFloat) return 1;
                  return -1;
                })();
                return isAsc ? floatResult : -floatResult;
              case M.DataKind.DATE:
                const aDate = formatStringToDate(String(aValue));
                const bDate = formatStringToDate(String(bValue));
                const isADate = isValidDate(aDate);
                const isBDate = isValidDate(bDate);
                const dateResult = (() => {
                  if (!isADate || !isBDate) {
                    if (!isADate && !isBDate) return 0;
                    if (isADate) return 1;
                    return -1;
                  }
                  if (aDate.getTime() === bDate.getTime()) return 0;
                  if (aDate > bDate) return 1;
                  return -1;
                })();
                return isAsc ? dateResult : -dateResult;
              case M.DataKind.RANGE:
                const rangeRegex = /^(\d{1,2}\.\d{1,2}\.\d{4})?\s?-\s?(\d{1,2}\.\d{1,2}\.\d{4})?$/;
                const isARange = new RegExp(rangeRegex).test(String(aValue));
                const isBRange = new RegExp(rangeRegex).test(String(bValue));
                const aRange = String(aValue)
                  .split('-')
                  .map(x => formatStringToDate(x.trim()));
                const bRange = String(bValue)
                  .split('-')
                  .map(x => formatStringToDate(x.trim()));
                const rangeResult = (() => {
                  if (!isARange || !isBRange) {
                    if (!isARange && !isBRange) return 0;
                    if (isARange) return 1;
                    return -1;
                  }
                  if (aRange[0].getTime() === bRange[0].getTime()) {
                    if (aRange[1].getTime() === bRange[1].getTime()) return 0;
                    if (aRange[1] > bRange[1]) return 1;
                    return -1;
                  }
                  if (aRange[0] > bRange[0]) return 1;
                  return -1;
                })();
                return isAsc ? rangeResult : -rangeResult;
              default:
                return isAsc ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
            }
          })
          .map(({ filteredRow }) => filteredRow);
        return filteredRowsCopy;
      }
    }

    return filteredRows;
  }, [columns, columnsSorting, filteredRows]);

  const calculatedSelectedRowIndex = useMemo(() => {
    const isValidSelectedRowIndex = selectedRowIndex !== null ? sortedRows.length - 1 >= selectedRowIndex : false;
    if (isValidSelectedRowIndex) {
      const { extendedRowId: extendedRowIdToFound } = sortedRows[selectedRowIndex!];
      const index = extendedRows.findIndex(({ extendedRowId }) => extendedRowIdToFound === extendedRowId);
      return index === -1 ? null : index;
    }

    return null;
  }, [extendedRows, selectedRowIndex, sortedRows]);

  const handleSelectedRows = useCallback(
    (indexes: number[]) => {
      setSelectedRowsIndexes(indexes);
      onSelectedRowsCallback?.(indexes.map(i => sortedRows[i]));
    },
    [setSelectedRowsIndexes, onSelectedRowsCallback, sortedRows],
  );

  const closeCollapsible = useCallback(() => {
    setIsCollapsible(false);
    setCustomEditRow(null);
    if (onClosedHandler) onClosedHandler();
  }, [setIsCollapsible, setCustomEditRow, onClosedHandler]);

  const openCollapsible = useCallback(() => {
    setIsCollapsible(true);
    if (onOpenedHandler) onOpenedHandler();
  }, [setIsCollapsible, onOpenedHandler]);

  const closeTableModal = useCallback(() => {
    setIsOpenTableModal(false);
    setMode(null);
    setSelectedRowIndex(null);
  }, [setMode, setIsOpenTableModal, setSelectedRowIndex]);

  const openTableModal = useCallback(() => {
    setIsOpenTableModal(true);
  }, [setIsOpenTableModal]);

  const showNotifications = useCallback(
    (changedRowsAmount: number) => {
      if (!withMessages) {
        return;
      }
      if (mode === 'add') {
        if (changedRowsAmount === 0) {
          showNotification({ message: 'Не добавлен ни один элемент, все они уже есть в списке', theme: 'danger' });
        }
        if (changedRowsAmount === 1) {
          showNotification({ message: 'Выбранный элемент успешно добавлен', theme: 'success' });
        } else showNotification({ message: 'Выбранные элементы успешно добавлены', theme: 'success' });
      } else {
        showNotification({ message: 'Выбранный элемент успешно изменен', theme: 'success' });
      }
    },
    [withMessages, mode],
  );

  const onSubmitTable = useCallback(
    ({ selectedRows }: SubmitTable) => {
      const submit = (newRows: Row[]) => {
        const updatedRows: Row[] =
          mode === 'add'
            ? (getUniq([...rows, ...newRows]) as Row[])
            : R.pipe<Row[], Row[], Row[], Row[]>(
                R.remove(calculatedSelectedRowIndex || 0, 1),
                R.insertAll(calculatedSelectedRowIndex || 0, newRows),
                getUniq,
              )(rows);
        const changedRowsAmount = updatedRows.length - rows.length;

        showNotifications(changedRowsAmount);
        onChange(updatedRows);
        closeTableModal();
      };

      if (specification.mode === 'relationTableModal') {
        const { modalTableRowConverter } = specification;
        const newRows = selectedRows.map(modalTableRowConverter);
        if (mode === 'add') {
          if (specification.onPreSubmit) {
            specification.onPreSubmit(newRows, submit);
          } else {
            submit(newRows);
          }
        } else if (mode === 'edit') {
          if (specification.onPreEdit) {
            specification.onPreEdit(newRows, submit);
          } else {
            submit(newRows);
          }
        }
      }
      if (specification.mode === 'loadInstanceTableModal') {
        const { onStartLoad } = specification;
        const isValid = specification.validation?.checkIsValid(selectedRows, calculatedSelectedRowIndex, rows, mode) ?? false;
        const isEmptyValidator = specification.validation === undefined;
        if (isValid || isEmptyValidator) {
          onStartLoad(selectedRows, submit);
        } else if (specification.validation?.onInvalidate) {
          specification.validation.onInvalidate(selectedRows, calculatedSelectedRowIndex, rows, mode);
        }
      }
    },
    [specification, mode, rows, calculatedSelectedRowIndex, showNotifications, onChange, closeTableModal],
  );

  const isOpenCustomComponent = useMemo(() => specification.mode === 'customComponent' && isCollapsible, [
    specification,
    isCollapsible,
  ]);

  const submitCustomComponent = useCallback(() => {
    if (specification.mode === 'customComponent' || specification.mode === 'customModalComponent') {
      const isValid = specification.validation?.checkIsValid(customEditRow, calculatedSelectedRowIndex, rows, mode) ?? false;
      const isEmptyValidator = specification.validation === undefined;
      if (isValid || isEmptyValidator) {
        if (customEditRow) {
          const updateRows = (row: Row) => {
            if (isUniq) {
              return mode === 'add'
                ? getUniq([...rows, row])
                : R.pipe<Row[], Row[], Row[], Row[]>(
                    R.remove(calculatedSelectedRowIndex || 0, 1),
                    R.insert(calculatedSelectedRowIndex || 0, row),
                    getUniq,
                  )(rows);
            }

            return mode === 'add'
              ? [...rows, row]
              : R.pipe<Row[], Row[], Row[]>(
                  R.remove(calculatedSelectedRowIndex || 0, 1),
                  R.insert(calculatedSelectedRowIndex || 0, row),
                )(rows);
          };

          if (specification.onPreSubmit) {
            specification.onPreSubmit(
              customEditRow,
              row => {
                onChange(updateRows(row));
                closeCollapsible();
              },
              calculatedSelectedRowIndex,
              mode,
            );
          } else {
            onChange(updateRows(customEditRow));
            closeCollapsible();
          }
        }
        if (withMessages) showNotifications(1);
      } else if (specification.validation?.onInvalidate) {
        specification.validation.onInvalidate(customEditRow, mode, calculatedSelectedRowIndex, rows);
      }
    }
  }, [
    specification,
    customEditRow,
    rows,
    mode,
    withMessages,
    showNotifications,
    isUniq,
    calculatedSelectedRowIndex,
    onChange,
    closeCollapsible,
  ]);

  const saveAndAddCustomComponent = useCallback(() => {
    if (specification.mode === 'customComponent' || specification.mode === 'customModalComponent') {
      const isValid = specification.validation?.checkIsValid(customEditRow, calculatedSelectedRowIndex, rows, mode) ?? false;
      const isEmptyValidator = specification.validation === undefined;
      if (isValid || isEmptyValidator) {
        if (customEditRow) {
          const updateRows = (row: Row) => {
            if (isUniq) {
              return mode === 'add'
                ? getUniq([...rows, row])
                : R.pipe<Row[], Row[], Row[], Row[]>(
                    R.remove(calculatedSelectedRowIndex || 0, 1),
                    R.insert(calculatedSelectedRowIndex || 0, row),
                    getUniq,
                  )(rows);
            }

            return mode === 'add'
              ? [...rows, row]
              : R.pipe<Row[], Row[], Row[]>(
                  R.remove(calculatedSelectedRowIndex || 0, 1),
                  R.insert(calculatedSelectedRowIndex || 0, row),
                )(rows);
          };

          if (specification.onPreSubmit) {
            specification.onPreSubmit(
              customEditRow,
              row => {
                onChange(updateRows(row));
                setCustomEditRow(null);
                setMode('add');
              },
              calculatedSelectedRowIndex,
              mode,
            );
          } else {
            onChange(updateRows(customEditRow));
            setCustomEditRow(null);
            setMode('add');
          }
        }
        if (withMessages) showNotifications(1);
      } else if (specification.validation?.onInvalidate) {
        specification.validation.onInvalidate(customEditRow, mode, calculatedSelectedRowIndex, rows);
      }
    }
  }, [specification, customEditRow, rows, mode, withMessages, showNotifications, isUniq, calculatedSelectedRowIndex, onChange]);

  const deleteRow = useCallback(() => {
    if (calculatedSelectedRowIndex !== null) {
      const continueDelete = () => {
        const updatedRows = R.remove(calculatedSelectedRowIndex, 1, rows);
        if (withMessages) {
          showNotification({ message: 'Выбранный элемент успешно удален', theme: 'success' });
        }
        setSelectedRowIndex(null);
        onChange(updatedRows);
        if (customEditRow !== null) {
          setCustomEditRow(null);
        }
      };
      if (
        (specification.mode === 'customComponent' || specification.mode === 'customModalComponent') &&
        specification.onPreDelete
      ) {
        specification.onPreDelete(rows[calculatedSelectedRowIndex], continueDelete, calculatedSelectedRowIndex);
      } else {
        continueDelete();
      }
    }
  }, [calculatedSelectedRowIndex, specification, rows, withMessages, onChange, customEditRow]);

  const addRow = useCallback(
    (modeAdd: 'add' | 'dublicate') => {
      setMode('add');
      if (specification.mode === 'relationTableModal' || specification.mode === 'loadInstanceTableModal') {
        openTableModal();
      } else {
        openCollapsible();
      }
      if (modeAdd === 'dublicate') {
        const selectedRow = calculatedSelectedRowIndex !== null ? rows[calculatedSelectedRowIndex] : null;
        setCustomEditRow(selectedRow);
      }
    },
    [specification.mode, rows, calculatedSelectedRowIndex, setMode, openTableModal, openCollapsible],
  );

  const onView = useCallback(() => {
    setMode('view');
    if (specification.mode === 'relationTableModal' || specification.mode === 'loadInstanceTableModal') {
      openTableModal();
    } else {
      setCustomEditRow(calculatedSelectedRowIndex !== null ? rows[calculatedSelectedRowIndex] : null);
      openCollapsible();
    }
  }, [calculatedSelectedRowIndex, openCollapsible, openTableModal, rows, specification.mode]);

  const editRow = useCallback(() => {
    setMode('edit');
    if (specification.mode === 'relationTableModal' || specification.mode === 'loadInstanceTableModal') {
      openTableModal();
    } else {
      const selectedRow = calculatedSelectedRowIndex !== null ? rows[calculatedSelectedRowIndex] : null;
      const continueEdit = () => {
        setCustomEditRow(selectedRow);
        openCollapsible();
      };
      if (specification.onPreEdit) {
        specification.onPreEdit(selectedRow, continueEdit);
      } else {
        continueEdit();
      }
    }
  }, [specification, calculatedSelectedRowIndex, rows, setMode, openTableModal, setCustomEditRow, openCollapsible]);

  const accept = useCallback(() => {
    const selectedRow = calculatedSelectedRowIndex !== null ? rows[calculatedSelectedRowIndex] : null;
    if (onAccept && selectedRow !== null) {
      onAccept(selectedRow);
    }
  }, [calculatedSelectedRowIndex, rows, onAccept]);

  const cancelAcceptance = useCallback(() => {
    const selectedRow = calculatedSelectedRowIndex !== null ? rows[calculatedSelectedRowIndex] : null;
    if (onAcceptanceCancel && selectedRow !== null) {
      onAcceptanceCancel(selectedRow);
    }
  }, [calculatedSelectedRowIndex, rows, onAcceptanceCancel]);

  const startDelete = useCallback(() => {
    if (isDeleteConfirmEnabled) {
      setIsConfirmPopupOpened(true);
    } else {
      deleteRow();
    }
  }, [deleteRow, setIsConfirmPopupOpened, isDeleteConfirmEnabled]);

  const confirmDelete = useCallback(() => {
    deleteRow();
    setIsConfirmPopupOpened(false);
  }, [deleteRow]);

  const resetDelete = useCallback(() => {
    setIsConfirmPopupOpened(false);
  }, []);

  const renderComponent = (specMode: M.ModeSpecification<Row>['mode']) => {
    type ModalSpecifications =
      | M.LoadInstanceTableModalSpecification<Row, CustomTableState>
      | M.RelationTableModalSpecification<Row, CustomTableState>;

    const renderTableModal = (spec: ModalSpecifications) => (
      <RelationTableModal
        specification={{
          ...spec.modalTableSpecification,
          onSubmitTable,
          isCanSelectOnlyOneRow: mode === 'edit',
        }}
        relationTableModalTitle={spec.relationTableModalTitle}
        isOpen={isOpenTableModal}
        onClose={closeTableModal}
        helpText={
          isModalHintHidden
            ? ''
            : `При нажатой клавише CTRL выделите мышкой несколько записей и нажмите кнопку Выбрать на панели инструментов`
        }
      />
    );

    const mapRender: Record<M.ModeSpecification<Row>['mode'], (spec: any) => JSX.Element> = {
      loadInstanceTableModal: renderTableModal,
      relationTableModal: renderTableModal,
      customComponent: (spec: M.CustomComponentSpecification<Row>) => (
        <CollapsibleComponent
          closeCollapsible={closeCollapsible}
          customEditRow={customEditRow}
          isCollapse={isCollapsible}
          isSaveAndAddCustomComponent={isSaveAndAddCustomComponent}
          saveAndAddCustomComponent={saveAndAddCustomComponent}
          submitCustomComponent={submitCustomComponent}
          renderComponent={spec.renderComponent}
          onChange={setCustomEditRow}
          selectedRowIndex={calculatedSelectedRowIndex}
          mode={mode}
          extraFieldsToolbarButtons={extraFieldsToolbarButtons}
          rows={rows}
        />
      ),
      customModalComponent: (spec: M.CustomModalComponentSpecification<Row>) => (
        <ModalComponent
          isSaveAndAddCustomComponent={isSaveAndAddCustomComponent}
          saveAndAddCustomComponent={saveAndAddCustomComponent}
          isOpen={isCollapsible}
          customEditRow={customEditRow}
          modalTitle={spec.modalTitle}
          modalEditTitle={spec.modalEditTitle}
          onClose={closeCollapsible}
          renderComponent={spec.renderComponent}
          onChange={setCustomEditRow}
          submitCustomComponent={submitCustomComponent}
          selectedRowIndex={calculatedSelectedRowIndex}
          mode={mode}
          isCheck={spec.isCheckSubmitIcon}
          isToolbarHidden={spec.isToolbarHidden}
          isFullScreenModal={spec.isFullScreenModal}
        />
      ),
    };
    return mapRender[specMode](specification);
  };

  const moveUpRow = useCallback(() => {
    if (calculatedSelectedRowIndex !== null && calculatedSelectedRowIndex !== 0) {
      const nextPosition = calculatedSelectedRowIndex - 1;
      const updatedRows = R.move(calculatedSelectedRowIndex, nextPosition, rows);
      setSelectedRowIndex(nextPosition);
      onChange(updatedRows);
    }
  }, [calculatedSelectedRowIndex, rows, onChange, setSelectedRowIndex]);

  const moveDownRow = useCallback(() => {
    if (calculatedSelectedRowIndex !== null && calculatedSelectedRowIndex !== rows.length - 1) {
      const nextPosition = calculatedSelectedRowIndex + 1;
      const updatedRows = R.move(calculatedSelectedRowIndex, nextPosition, rows);
      setSelectedRowIndex(nextPosition);
      onChange(updatedRows);
    }
  }, [calculatedSelectedRowIndex, rows, onChange, setSelectedRowIndex]);

  const selectedRow = useMemo(() => {
    if (calculatedSelectedRowIndex !== null) {
      return extendedRows[calculatedSelectedRowIndex];
    }

    return null;
  }, [calculatedSelectedRowIndex, extendedRows]);

  // TODO: necessarily remove after refactor projects
  useEffect(() => {
    if (calculatedSelectedRowIndex !== null) {
      const currentRow = extendedRows[calculatedSelectedRowIndex];
      if (withAcceptButtons && currentRow && 'accepted' in currentRow) {
        const isAccepted = !!((currentRow as unknown) as { accepted: string }).accepted;
        setIsAcceptButtonDisabled(isAccepted);
        setIsCancelButtonDisabled(!isAccepted);
      }
    }
  }, [selectedRowIndex, extendedRows, withAcceptButtons, calculatedSelectedRowIndex]);
  const isTransformButtonsDisabled = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (selectedRowIndex !== null && !!extendedRows[calculatedSelectedRowIndex]?.accepted) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return !!extendedRows[selectedRowIndex].accepted;
    }
    return false;
  }, [calculatedSelectedRowIndex, extendedRows, selectedRowIndex]);

  useEffect(() => {
    if (onSelectedRowIndexCallback) {
      onSelectedRowIndexCallback(calculatedSelectedRowIndex);
    }
  }, [onSelectedRowIndexCallback, calculatedSelectedRowIndex]);

  return (
    <div
      className={b({
        'fullscreen-height': isFullScreenedTable,
        'personal-height': isPersonalTable,
        'not-focused': isOpenCustomComponent,
      })}
    >
      <div className={b('head')}>
        {title && (
          <span className={b('title')}>
            {title}
            <span className={b('title-tooltip')}>{tooltipText && <Tooltip text={tooltipText} />}</span>
            {isRequired && <RequiredBadge />}
          </span>
        )}
        {children && (
          <span className={b('head-right')}>
            <HeadRightSlot.Renderer childs={children}></HeadRightSlot.Renderer>
          </span>
        )}
      </div>

      {renderComponent(specification.mode)}
      {!hideToolbar && (
        <ToolbarButtons
          onAdd={addRow}
          onEdit={editRow}
          onDelete={startDelete}
          onView={onView}
          moveUpRow={moveUpRow}
          moveDownRow={moveDownRow}
          selectedRowIndex={calculatedSelectedRowIndex}
          selectedRowsIndexes={selectedRowsIndexes}
          isToolbarDisabled={isToolbarDisabled}
          isOpenCustomComponent={isOpenCustomComponent}
          isCanMovingRows={isCanMovingRows}
          extraToolbarButtons={extraToolbarButtons}
          rows={extendedRows}
          withAcceptButtons={withAcceptButtons}
          onAccept={accept}
          onAcceptanceCancel={cancelAcceptance}
          isAcceptButtonDisabled={isAcceptButtonDisabled}
          isCancelButtonDisabled={isCancelButtonDisabled}
          addPermissionName={addPermissionName}
          editPermissionName={editPermissionName}
          acceptPermissionName={acceptPermissionName}
          visibleToolbarButtons={visibleToolbarButtons}
          isTransformButtonsDisabled={isTransformButtonsDisabled}
          isDeleteButtonDisabled={getIsDeleteDisabled && getIsDeleteDisabled(selectedRow)}
          isEditButtonDisabled={getIsEditDisabled && getIsEditDisabled(selectedRow)}
          isAddButtonDisabled={getIsAddDisabled && getIsAddDisabled()}
          isProfile={isProfile}
          editButtonTitle={editButtonTitle}
        />
      )}
      <div
        className={b('table', {
          'fullscreen-height': isFullScreenedTable,
          'personal-height': isPersonalTable,
          'not-focused': isOpenCustomComponent,
        })}
        ref={obj => {
          listEditObj.current = obj;
          return false;
        }}
        style={{ maxHeight }}
      >
        <ListEditTable
          columnsFilters={columnsFilters}
          setColumnsFilters={setColumnsFilters}
          columnsSorting={columnsSorting}
          setColumnsSorting={setColumnsSorting}
          rows={sortedRows}
          defaultRowsCount={isFullScreenedTable ? fullScreenRowsCount : defaultRowsCount}
          withoutHead={withoutHead}
          columns={columns}
          selectRow={setSelectedRowIndex}
          selectedRowIndex={selectedRowIndex}
          onSelectRows={handleSelectedRows}
          selectedRowsIndexes={selectedRowsIndexes}
          getRowTheme={getRowTheme}
          columnIndexesForSumTotal={columnIndexesForSumTotal}
          columnIndexesForIntegerTotal={columnIndexesForIntegerTotal}
          isVisibleFilters={isVisibleFilters}
          isSortEnabled={isSortEnabled}
        />
      </div>
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isConfirmPopupOpened}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={resetDelete}
        onConfirm={confirmDelete}
        icon="warning"
      />
    </div>
  );
};

export const ListEdit = React.memo(Component) as typeof Component;
