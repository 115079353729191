import { useCallback, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';

import { CollectionItem, CollectionValue, Field, FieldType, FieldValue, Merge } from 'types/models/Merge';
import { Row } from 'react-table';
import {
  ColumnType,
  MergeFieldRadios,
  MergeFieldRadioValue,
  SetValueArguments,
  UpdateEntityMergeFieldRadioArguments,
} from 'features/MergePerson/types';
import { ActionType } from 'services/BackendAPI/configurations/MergeScientist/types';
import { validate } from 'features/MergePerson/helpers/validate';
import { showErrorsMessages } from 'utils/Common';
import { showNotification } from 'features/Notifications';
import { getMockMerge } from 'features/MergePerson/helpers/getMockModels';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  refName: string;
  selectedEntries?: Row[];
};

export function useController(props: Props) {
  const { refName, selectedEntries } = props;
  const { methods: getMerge } = BackendAPI.useBackendAPI('RefMerge');
  const [merge, setMerge] = useState<Merge>(getMockMerge());

  const [personId, setPersonId] = useState<string | null>(null);
  const [isPersonViewFormOpen, setIsPersonViewFormOpen] = useState<boolean>(false);
  const [mergeFieldRadios, setMergeFieldRadios] = useState<MergeFieldRadios | null>(null);

  const { methods: saveMergeRequestAPI } = BackendAPI.useBackendAPI('SaveRefMerge');

  const setValue = useCallback(
    (setValueArguments: SetValueArguments) => (nextValue: FieldValue) => {
      const mergeCopy = R.clone(merge);
      if (setValueArguments.type === ColumnType.ENTITY) {
        const entityIndex = mergeCopy.entities.findIndex(({ id }) => setValueArguments.entityId === id);
        const isEntityIndex = entityIndex !== -1;

        const fieldIndex = isEntityIndex
          ? mergeCopy.entities[entityIndex].fields.findIndex(({ name }) => name === setValueArguments.fieldName)
          : -1;
        const isFieldIndex = fieldIndex !== -1;

        if (isFieldIndex && isEntityIndex) {
          mergeCopy.entities[entityIndex].fields[fieldIndex].value = nextValue;
        }
      } else {
        const commonFieldIndex = mergeCopy.common.findIndex(({ name }) => setValueArguments.fieldName === name);
        const isCommonFieldIndex = commonFieldIndex !== -1;

        if (isCommonFieldIndex) {
          mergeCopy.common[commonFieldIndex].value = nextValue;
        }
      }
      setMerge(mergeCopy);
    },
    [merge],
  );

  const onPersonClick = useCallback((nextPersonId: string) => {
    setPersonId(nextPersonId);
    setIsPersonViewFormOpen(true);
  }, []);

  const closePersontViewForm = useCallback(() => {
    setIsPersonViewFormOpen(false);
  }, []);

  const updateEntityMergeFieldRadio = useCallback(
    ({ fieldName, entityId, value }: UpdateEntityMergeFieldRadioArguments) => () => {
      if (!mergeFieldRadios) {
        return;
      }

      if (value !== undefined) {
        setValue({ type: ColumnType.COMMON, fieldName })(value);
      }

      setMergeFieldRadios(prevMergeFieldRadios =>
        merge.entities.reduce<MergeFieldRadios>(
          (accum, current) => ({
            ...accum,
            [current.id]: current.fields.reduce<Record<string, MergeFieldRadioValue>>(
              (fieldsAccum, fieldsCurrent) => ({
                ...fieldsAccum,
                [fieldsCurrent.name]:
                  current.id === entityId && fieldsCurrent.name === fieldName
                    ? MergeFieldRadioValue.SELECTED
                    : current.id !== entityId && fieldsCurrent.name === fieldName
                    ? MergeFieldRadioValue.UNSELECTED
                    : prevMergeFieldRadios?.[current.id][fieldsCurrent.name] || MergeFieldRadioValue.UNSELECTED,
              }),
              {},
            ),
          }),
          {},
        ),
      );
    },
    [merge.entities, mergeFieldRadios, setValue],
  );

  const extendedCommonColumn = useMemo(
    () =>
      merge.common.map(field => {
        const isCollection = field.type === FieldType.COLLECTION;
        if (isCollection) {
          const extendedCollectionItems = merge.entities.reduce<CollectionItem[]>((accum, currentItem) => {
            const currentEntityField = currentItem.fields.find(fieldItem => fieldItem.name === field.name);
            const collection: CollectionItem[] = currentEntityField?.collection || [];
            const selectedIds: string[] = (currentEntityField?.value as CollectionValue).selectedIds || [];

            return [...accum, ...collection.filter(collectionItem => selectedIds.some(id => collectionItem.id === id))];
          }, []);

          return {
            ...field,
            collection: extendedCollectionItems,
          };
        }

        return field;
      }),
    [merge.common, merge.entities],
  );

  const getIsConflicted = useCallback(
    ({ isConflicted, name }: Field) => {
      const isSomeRadioSelected = merge.entities.some(({ id }) => mergeFieldRadios?.[id][name] === MergeFieldRadioValue.SELECTED);

      return isConflicted && !isSomeRadioSelected;
    },
    [merge.entities, mergeFieldRadios],
  );

  const saveMerge = useCallback(() => {
    const preparedMerge: Merge = { ...merge, common: extendedCommonColumn };

    const validationInfo = validate({ merge: preparedMerge, mergeFieldRadios: mergeFieldRadios! });
    const errors = validationInfo.filter(({ isValid }) => !isValid);
    const hasErrors = Boolean(errors.length);

    if (hasErrors) {
      showErrorsMessages(errors.map(({ invalidMessage }) => invalidMessage));
    } else {
      saveMergeRequestAPI.callAPI(
        {
          merge: preparedMerge,
          action: ActionType.MERGE,
          refName,
        },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Элементы справочника успешно объединены', theme: 'success' });
          },
        },
      );
    }
  }, [saveMergeRequestAPI, extendedCommonColumn, merge, mergeFieldRadios, refName]);

  const onCommonRadioClick = useCallback(
    (nextCommonId: string) => {
      setMerge({ ...merge, commonId: nextCommonId });
    },
    [merge],
  );

  const setIsCollectionConflicResolved = useCallback(
    ({ fieldName, entityId }: UpdateEntityMergeFieldRadioArguments) => {
      const isAlredyResolved = mergeFieldRadios?.[entityId][fieldName] === MergeFieldRadioValue.SELECTED;

      return isAlredyResolved ? () => {} : updateEntityMergeFieldRadio({ fieldName, entityId });
    },
    [mergeFieldRadios, updateEntityMergeFieldRadio],
  );

  useEffect(() => {
    if (selectedEntries && selectedEntries?.length > 0) {
      getMerge.callAPI(
        {
          refName,
          action: 'CHECK',
          selectedItems: selectedEntries,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setMerge(data);
            setMergeFieldRadios(
              data.entities.reduce<MergeFieldRadios>(
                (accum, current) => ({
                  ...accum,
                  [current.id]: current.fields.reduce<Record<string, MergeFieldRadioValue>>(
                    (fieldsAccum, fieldsCurrent) => ({ ...fieldsAccum, [fieldsCurrent.name]: MergeFieldRadioValue.UNSELECTED }),
                    {},
                  ),
                }),
                {},
              ),
            );
          },
        },
      );
    }
    }, [refName, selectedEntries]); // eslint-disable-line

  return {
    personId,
    isPersonViewFormOpen,
    merge,
    mergeFieldRadios,
    extendedCommonColumn,
    setIsCollectionConflicResolved,
    onCommonRadioClick,
    getIsConflicted,
    updateEntityMergeFieldRadio,
    closePersontViewForm,
    onPersonClick,
    setValue,
    saveMerge,
  };
}
