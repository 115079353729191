import React, { memo } from 'react';
import { ConfirmPopup } from 'components';

type Props = {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
};

const CantSaveInPreviousStatusPopup = memo(({ isOpen, text, onClose, onConfirm }: Props) => (
  <ConfirmPopup
    title="Предупреждение"
    icon="info"
    text={text}
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    okButtonText='Сохранить в статусе "Черновик"'
    resetButtonText="Продолжить заполнение"
    size="medium"
  />
));

export { CantSaveInPreviousStatusPopup };
