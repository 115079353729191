import React from 'react';

import { Form } from 'features/Form';
import { Form as F, Table } from 'types/models';

type Props = {
  tableState: Table.State;
  eventId: string | null;
};

export function ViewConference(props: Props) {
  const { tableState, eventId } = props;

  if (eventId === null) {
    return null;
  }

  const baseLook: F.Look = {
    apiID: 'GetEvent',
    template: 'EventFormConference',
    type: 'conference',
    relatedTableState: tableState,
    viewMode: true,
    id: eventId,
    name: '',
  };

  return <Form<F.EventFormLook> look={{ ...baseLook, isIncentivePayments: true }} onClose={() => {}} />;
}
