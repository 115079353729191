import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SubmitTable } from 'features/Table/streams';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function LeftPanelForThirdLevel({ tableState, onSubmitTable }: Props) {
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const tableStreams = useLocalTableStreams();
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  // const handleViewButtonClick = useCallback(() => {
  //   setIsViewFormOpen(true);
  // }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      // {
      //   icons: buttonIcons.loop,
      //   title: 'Просмотр',
      //   code: 'view',
      //   isDisabled: isViewButtonDisabled,
      //   onClick: handleViewButtonClick,
      //   permissionName: 'MagazineView',
      // },
      {
        icons: buttonIcons.check,
        title: 'Выбрать',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: () => {
          if (onSubmitTable) {
            onSubmitTable(tableState);
          } else {
            tableStreams.submitTable.push(tableState);
          }
        },
      },
    ],
    [onSubmitTable, rowsAvailability.SINGLE_SELECTED, tableStreams.submitTable, tableState],
  );

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.PrintPublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
