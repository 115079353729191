import React, { useCallback, useMemo, useState } from 'react';

import { Table } from 'types/models';
import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, ConfirmPopup } from 'components';

import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;
  const { openEditor, setEditedBiblio, setMode, deleteBibliographicName, setDefaultBiblioName } = customState;

  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState<boolean>(false);
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.approve,
        title: 'Использовать по умолчанию',
        code: 'check',
        isDisabled: false,
        onClick: setDefaultBiblioName,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: () => {
          setMode('add');
          openEditor();
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: !tableState.selectedRows.length,
        onClick: () => {
          setMode('edit');
          setEditedBiblio();
          openEditor();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: !tableState.selectedRows.length,
        onClick: () => {
          setIsConfirmPopupOpened(true);
        },
      },
    ],
    [tableState.selectedRows, openEditor, setEditedBiblio, setMode, setDefaultBiblioName],
  );

  const cancelDelete = useCallback(() => {
    setIsConfirmPopupOpened(false);
  }, []);

  const confirmDelete = useCallback(() => {
    deleteBibliographicName();
    setIsConfirmPopupOpened(false);
  }, [deleteBibliographicName]);

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isConfirmPopupOpened}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={cancelDelete}
        onConfirm={confirmDelete}
        icon="warning"
      />
    </>
  );
}

export { Toolbar };
