import { Dispatch, SetStateAction } from 'react';
import { Event, Form } from 'types/models';
import * as R from 'ramda';
import { InitialConstants } from 'utils/Enums/InitialConstants';
import { ReferenceItem } from 'components';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  data: Event.Data;
  eventId: string | undefined;
  name: string | undefined;
  initialCountryCode: ReferenceItem | null;
};

const INITIAL_CITY = InitialConstants.INITIAL_CITY;

const setByResponse = ({ setFormFields, data, eventId, name, initialCountryCode }: Props) => {
  const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
  const getRequiredLens = (fieldName: string) => R.lensPath([fieldName, 'required']);

  setFormFields(prevFields =>
    (R.pipe as any)(
      R.set(getValueLens('eventSeries'), {
        id: data.fields.eventSeriesId || '',
        label: data.fields.eventSeriesLabel || '',
      }),
      R.set(getValueLens('expositionType'), {
        id: data.fields.expositionTypeId || '',
        label: data.fields.expositionTypeLabel || '',
      }),
      R.set(getValueLens('eventStatus'), {
        id: data.fields.eventStatusId || '',
        label: data.fields.eventStatusLabel || '',
      }),
      R.set(getValueLens('isStudent'), data.fields.isStudent === 'true'),
      R.set(getValueLens('orderNumber'), data.fields.orderNumber),
      R.set(getValueLens('orderDate'), data.fields.orderDate),
      R.set(getValueLens('name'), name || data.fields.name),
      R.set(getValueLens('startDate'), data.fields.startDate),
      R.set(getValueLens('endDate'), data.fields.endDate),
      R.set(getRequiredLens('endDate'), eventId ? !!data.fields.endDate : true),
      R.set(getValueLens('termsType'), {
        label: eventId ? (data.fields.endDate ? 'Период' : 'Дата') : 'Период',
        id: eventId ? (data.fields.endDate ? 'PERIOD' : 'DATE') : 'PERIOD',
      }),
      R.set(getValueLens('description'), data.fields.description),
      R.set(getValueLens('keyWords'), data.fields.keyWords),
      R.set(getValueLens('site'), data.fields.site),
      R.set(getValueLens('country'), {
        id: eventId ? data.fields.countryId : initialCountryCode?.id,
        label: eventId ? data.fields.countryLabel : initialCountryCode?.label,
      }),
      R.set(getValueLens('city'), eventId ? data.fields.city : INITIAL_CITY),
      R.set(getValueLens('address'), data.fields.address),
      R.set(getValueLens('contact'), data.fields.contact),
      R.set(getValueLens('memberCountPlan'), data.fields.memberCountPlan),
      R.set(getValueLens('memberCountFact'), data.fields.memberCountFact),
    )(prevFields),
  );
};

export { setByResponse };
