import React, { useMemo } from 'react';
import block from 'bem-cn';

import {
  buttonIcons,
  FormComponent,
  Checkbox,
  IconButton,
  Reference,
  ReferenceItem,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
} from 'components';

import {
  CollectionValue,
  EnumerationValue,
  Field as FieldType,
  FieldType as FieldTypeEnum,
  FieldValue,
} from 'types/models/Merge';
import { CollectionField } from './CustomFields/CollectionField';
import { EnumerationSelect } from './CustomFields/EnumerationSelect';

import './style.scss';

const b = block('field');

type Props = {
  field: FieldType;
  isConflictedMarkerShows?: boolean;
  isConflicted?: boolean;
  isLabelShows?: boolean;
  isCommon?: boolean;
  setIsCollectionConflictResolved?: () => void;
  setValue: (fieldValue: FieldValue) => void;
  isDisabled: boolean;
};

export const Field = ({
  field,
  setValue,
  isDisabled,
  isConflicted,
  isLabelShows,
  isConflictedMarkerShows,
  isCommon,
  setIsCollectionConflictResolved,
}: Props) => {
  const fieldLabel = useMemo(() => (isLabelShows ? field.caption : undefined), [field.caption, isLabelShows]);

  const component = () => {
    switch (field.type) {
      case FieldTypeEnum.STRING:
        return (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <TextInput value={field.value ? (field.value as string) : ''} onChange={setValue} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      case FieldTypeEnum.TEXT:
        return (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: fieldLabel }}
                value={field.value ? (field.value as string) : ''}
                onChange={setValue}
                isDisabled={!!isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      case FieldTypeEnum.INTEGER:
        return (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <TextInput value={field.value ? (field.value as string) : ''} onChange={setValue} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      case FieldTypeEnum.DATE:
        return (
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <TextDateTime value={field.value ? (field.value as string) : ''} onChange={setValue} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      case FieldTypeEnum.REFERENCE:
        return (
          <FormComponent.Line>
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <Reference
                name={field.reference!}
                value={field.value ? (field.value as ReferenceItem) : null}
                onChange={setValue}
                relationTableModalTitle={field.caption}
                disabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      case FieldTypeEnum.BOOLEAN:
        return (
          <FormComponent.Line>
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <Checkbox checked={!!field.value} onChange={setValue} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      case FieldTypeEnum.ENUMERATION:
        return (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <EnumerationSelect
                enumerationName={field.enumeration!}
                value={field.value ? (field.value as EnumerationValue) : null}
                setValue={setValue}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      case FieldTypeEnum.COLLECTION:
        return (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <CollectionField
                relationType={field.relationType!}
                value={field.value as CollectionValue}
                collection={field.collection || []}
                setValue={value => {
                  setValue(value);
                  if (setIsCollectionConflictResolved) {
                    setIsCollectionConflictResolved();
                  }
                }}
                isCommon={isCommon}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      default:
        return (
          <FormComponent.Line>
            <FormComponent.Field label={fieldLabel} isRequired={field.isRequired}>
              <>Не обслуживаемый тип поля</>
            </FormComponent.Field>
          </FormComponent.Line>
        );
    }
  };

  return (
    <div className={b()}>
      {component()}
      {isConflictedMarkerShows && (
        <div className={b('icon')}>
          <IconButton icons={isConflicted ? buttonIcons.delete : buttonIcons.check} hasNoHover />
        </div>
      )}
    </div>
  );
};
