import { Person } from 'types/models';
import { JobStatus } from 'utils/Enums';

export function validate(job: Person.ScientistJob | null) {
  if (!job) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidStatus = !!job.status;
  const isValidEnteprise = !!job.enterpise;
  const isValidAppointment = !!job.appointment || !!job.refAppointment?.id;
  const isValidStaffState = job.status.value !== JobStatus.LOCAL || !!job.staffState?.id;

  return [
    { isValid: isValidStatus, invalidMessage: 'Заполните статус' },
    { isValid: isValidEnteprise, invalidMessage: 'Заполните организацию' },
    { isValid: isValidAppointment, invalidMessage: 'Заполните должность' },
    { isValid: isValidStaffState, invalidMessage: 'Заполните штатное состояние' },
  ];
}
