import React, { memo } from 'react';
import { Modal } from 'components';
import { Table } from 'types/models';
import { Component as FinancicalSupportPopupContainerComponent } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  publicationId: string;
  relatedTableState: Table.State | undefined;
  workMode: Table.WorkMode;
  onSuccess: () => void;
};

const FinancicalSupportPopupContainer = ({ isOpen, onClose, publicationId, relatedTableState, workMode, onSuccess }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title="Редактирование связей публикации с проектами и программами финансирования"
    size="large"
  >
    <FinancicalSupportPopupContainerComponent
      publicationId={publicationId}
      workMode={workMode}
      relatedTableState={relatedTableState}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  </Modal>
);

export const Container = memo(FinancicalSupportPopupContainer);
