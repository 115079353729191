import React, { ReactElement, ReactNode } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('component-header');

type Props = {
  children: ReactElement | ReactNode | string | false | (ReactElement | ReactNode | string | false)[];
};

/**
 * Header component (h2)
 *
 * @returns JSX
 */
export const Header = React.memo(({ children }: Props) => {
  return <h2 className={b()}>{children}</h2>;
});
