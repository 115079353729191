import { Table as T } from 'types/models';

type RequestData = {
  scientistIDs: string[];
};

export function GetNirRequestSecurityDocumentList(requestData: RequestData): T.Specification<object, RequestData> {
  return {
    apiID: 'GetNirRequestSecurityDocumentList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
    requestData,
  };
}
