import React from 'react';
import { Toolbar, FormComponent } from 'components';
import { Modal } from 'components';
import useController from './controller';

function LeftPanelForThirdLevel() {
  const { buttons, isHelpModalOpen, setIsHelpModalOpen } = useController();

  return (
    <>
      <Toolbar buttons={buttons} />
      <Modal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Вкладка Интеллектуальная собственность"
        size="medium"
      >
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              На вкладке отображаются сведения о документах на правовую охрану РИД, полученных в проекте
              <ul>
                <li>Заявке на патент</li>
                <li>Заявке на получение свидетельства</li>
                <li>Полученном патенте</li>
                <li>Полученном свидетельстве</li>
                <li>Ноу-хау</li>
              </ul>
              Для отображения на этой вкладке на форме документа должно быть указано, что РИД получен в рамках проекта.
            </p>
            <p>
              <strong className="primary">Важно!</strong> Все записи подлежат регистрации в федеральной системе учёта результатов
              по научным проекта ЕГИСУ НИОКТР, в соответствии с Федеральным законом № 77-ФЗ.
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
