import { createContext, useContext } from 'react';

export type MainScientistsContentContextType = {
  isMainScientistsContent: boolean;
};

export const MainScientistsContentContext = createContext<MainScientistsContentContextType>({
  isMainScientistsContent: false,
});

export function useMainScientistsContentContext() {
  return useContext<MainScientistsContentContextType>(MainScientistsContentContext);
}
