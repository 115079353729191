import React from 'react';

import { FormComponent, SectionTitle } from 'components';

import { Table } from 'types/models';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { Projects, Requests } from 'features/Form/views';
import { projectsStreams, mobileRequestsStream } from 'features/Form/looks/publication/PublicationThesis/streams';

type Props = {
  relatedTableState: Table.State | undefined;
  workMode: Table.WorkMode;
  projects: PublicationProject[];
  mobileRequests: PublicationMobileRequest[];
};

const setLocalProjects = (rows: PublicationProject[]) => {
  projectsStreams.setProjects.push(rows);
};

const setLocalProjectsAccept = (id: string) => {
  projectsStreams.accept.push(id);
};

const setLocalProjectsCancelAcception = (id: string) => {
  projectsStreams.cancelAcceptance.push(id);
};

const setLocalMobileRequest = (rows: PublicationMobileRequest[]) => {
  mobileRequestsStream.setMobileRequests.push(rows);
};

const setLocalMobileRequestAccept = (id: string) => {
  mobileRequestsStream.accept.push(id);
};

const setLocalMobileRequestCancelAcception = (id: string) => {
  mobileRequestsStream.cancelAcceptance.push(id);
};

function Events({ relatedTableState, workMode, projects, mobileRequests }: Props) {
  return (
    <>
      <SectionTitle title="Опубликовано при финансовой поддержке" />
      <FormComponent.Line>
        <Projects
          setLocalProjects={setLocalProjects}
          setLocalProjectsAccept={setLocalProjectsAccept}
          setLocalProjectsCancelAcception={setLocalProjectsCancelAcception}
          workMode={workMode}
          projects={projects}
          relatedTableState={relatedTableState}
        />
      </FormComponent.Line>
      <FormComponent.Line>
        <Requests
          setLocalMobileRequest={setLocalMobileRequest}
          setLocalMobileRequestAccept={setLocalMobileRequestAccept}
          setLocalMobileRequestCancelAcception={setLocalMobileRequestCancelAcception}
          workMode={workMode}
          requests={mobileRequests}
          relatedTableState={relatedTableState}
        />
      </FormComponent.Line>
    </>
  );
}

export const Component = React.memo(Events);
