import { Table } from 'types/models';
import { Filters } from './filters';

export function GetUserSessions(): Table.Specification {
  return {
    apiID: 'GetUserSessions',
    header: {
      firstLevel: {
        title: 'Сессии пользователей',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
