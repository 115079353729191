import React from 'react';

import { FormComponent, Tabs, Tab } from 'components';
import { DataGrid } from 'features/Table';
import { GetUserPermissionList, GetUserRoleList } from 'features/Table/specifications';

import { useController } from './controller';
import { PortfolioCard } from 'features/Form/looks/person';

function UserEditForm() {
  const { userId, userInfo, isOpenPersonModal, setIsOpenPersonModal } = useController();

  return (
    <FormComponent.Template>
      <Tabs>
        <Tab title="Информация">
          <FormComponent.Field label="Логин">
            <div>{userInfo.login}</div>
          </FormComponent.Field>
          <FormComponent.Field label="Имя">
            <div>{userInfo.fullName}</div>
          </FormComponent.Field>
          <FormComponent.Field label="E-Mail пользователя">
            <div>{userInfo.email}</div>
          </FormComponent.Field>
          <FormComponent.Field label="Факультет/Институт">
            <div>{userInfo.parentDepartment}</div>
          </FormComponent.Field>
          <FormComponent.Field label="Подразделение">
            <div>{userInfo.department}</div>
          </FormComponent.Field>
          <FormComponent.Field label="Персона">
            <div onClick={() => setIsOpenPersonModal(true)}>
              <a href="javascript:void(0);">{userInfo.person || 'портфолио'}</a>
            </div>
          </FormComponent.Field>
          <FormComponent.Field label="Контакты">
            <div>{userInfo.contacts}</div>
          </FormComponent.Field>
        </Tab>
        <Tab title="Роли">
          <DataGrid specification={GetUserRoleList({ userId: userId || '-1', isWithoutHeader: true })} />
        </Tab>
        <Tab title="Права">
          <DataGrid
            specification={GetUserPermissionList({
              userId: userId || '-1',
              isWithoutHeader: true,
            })}
          />
        </Tab>
      </Tabs>
      <PortfolioCard
        isOpen={isOpenPersonModal}
        id={userInfo.personId}
        onClose={() => setIsOpenPersonModal(false)}
        shortMode={true}
      />
    </FormComponent.Template>
  );
}

export const Component = React.memo(UserEditForm);
