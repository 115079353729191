import React from 'react';

import { Checkbox, FormComponent, Reference, TextInput, TextGridSelect } from 'components';

import { EventType } from 'types/models/Payment';
import { GetScientistJobs } from 'features/Table/specifications';
import { EditableNominee } from 'features/IncentivePayments/model';
import { formatEditableJob } from 'features/IncentivePayments/helpers';
import { ScientistJob } from 'types/models/Person';
import { ValueOf } from 'types/helpers';

type Props = {
  nominee: EditableNominee | null;
  setNominee(nominee: EditableNominee | null): void;
  eventType: EventType | null;
};

export function Fields(props: Props) {
  const { nominee, setNominee, eventType } = props;

  if (!nominee) {
    return null;
  }
  const changeField = (key: keyof EditableNominee, val: ValueOf<EditableNominee>) => {
    setNominee({ ...nominee, [key]: val });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="">
          <strong>{nominee.person.fullName}</strong>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label="">
          <Checkbox
            label="Аффилирован"
            checked={!!nominee.isAffiliated}
            onChange={checked => {
              setNominee({
                ...nominee,
                isAffiliated: checked,
                isPaid: checked ? nominee.isPaid : false,
              });
            }}
          />
        </FormComponent.Field>
        <FormComponent.Field>
          <Checkbox
            label="К оплате"
            checked={!!nominee.isPaid}
            onChange={checked => {
              setNominee({
                ...nominee,
                isPaid: checked,
                isAffiliated: checked ? true : nominee.isAffiliated,
              });
            }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="План, руб." isRequired={nominee.isPaid} labelSize="fit">
          <TextInput
            value={nominee.planMoney}
            onChange={value => changeField('planMoney', value)}
            isDisabled={eventType !== 'MEDAL'}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Факт, руб" isRequired={nominee.isPaid} labelSize="fit">
          <TextInput
            value={nominee.factMoney ?? ''}
            onChange={value => changeField('factMoney', value)}
            isDisabled={!nominee.isPaid}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сведения о работе" isRequired={nominee.isAffiliated}>
          <TextGridSelect
            isTextarea
            value={nominee.job}
            onChange={value => changeField('job', value)}
            specification={GetScientistJobs({ personId: nominee.person.id })}
            settings={{
              mode: 'selectTableRow',
              tableRowConverter: job => (({ appointment: job.appointment, id: job.id } as unknown) as ScientistJob),
            }}
            valueFormatter={formatEditableJob}
            title="Должности"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Относится к факультету" isRequired={nominee.isAffiliated}>
          <Reference
            name="RefDepartment"
            relationTableModalTitle="Подразделения"
            value={
              nominee.topLevelDepartment ? { id: nominee.topLevelDepartment.id, label: nominee.topLevelDepartment.name } : null
            }
            onChange={ref => changeField('topLevelDepartment', ref ? { id: ref.id, name: ref.label } : null)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
