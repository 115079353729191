import { Project } from 'types/models';

import { convertFileToXML, getMaybeField } from '../commonConverters';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { convertDocumentsToRequest } from '../converters';
import { convertDepartmentToXML } from 'services/BackendAPI/configurations/rntd/requestConverters';

const getIdField = (id: string) => (id ? { _attr: { id } } : null);

export function convertProjectToRequest(job: Project.Project) {
  return {
    Type: job.type?.value,
    ...getMaybeField('ProgramRequest', getIdField(job.programRequest?.value ?? '')),
    ...getMaybeField('NirRequest', getIdField(job.nirRequest?.value ?? '')),
    ...getMaybeField('Program', getIdField(job.program?.id ?? '')),
    ...getMaybeField('ProgramEvent', getIdField(job.programEvent?.value ?? '')),
    Name: job.name,
    Number: job.number,
    ...getMaybeField('NumberPrefix', getIdField(job.numberPrefix?.id ?? '')),
    IsSpecTheme: job.isSpecTheme,
    StartDate: job.startDate,
    EndDate: job.endDate,
    IsFinancingStopped: job.isFinancingStopped,
    ...getMaybeField('PeriodNote', job.periodNote ?? ''),
    ...getMaybeField('ContractStatus', job.projectContractStatus?.value ?? ''),
    ...getMaybeField('ContractKind', getIdField(job.contractKind?.id ?? '')),
    ...getMaybeField('ContractNumber', job.contractNumber ?? ''),
    ...getMaybeField('ContractDate', job.contractDate ?? ''),
    IsYouth: job.isYouth,
    IsBuyMaterial: job.isBuyMaterial,
    OverheadPercent: job.overheadPercent,
    ...getMaybeField('Contract', getIdField(job.securityDocumentContract?.id ?? '')),
    ...getMaybeField('FinancialNote', job.financialNote),
    ...getMaybeField('ExpendituresBudgets', getIdField(job.expendituresBudgets?.id ?? '')),
    ...getMaybeField('ActivityKind', getIdField(job.activityKind?.id ?? '')),
    ...getMaybeField('OkvedIncome', getIdField(job.okvedIncome?.id ?? '')),
    ...getMaybeField('Specification', getIdField(job.specification?.id ?? '')),
    ...getMaybeField('NationalProjectExpenseCode', getIdField(job.nationalProjectExpenseCode?.id ?? '')),
    ...getMaybeField('KindResearch', getIdField(job.kindResearch?.id ?? '')),
    ...getMaybeField('Goal', job.goal),
    ...getMaybeField('Annotation', job.annotation),
    ...getMaybeField('ExpectedResults', job.expectedResults),
    ...getMaybeField('ScientificGroundwork', job.scientificGroundwork),
    ...getMaybeField('InterdisciplinaryAproachJustification', job.interdisciplinaryAproachJustification),
    ...getMaybeField('Okved', getIdField(job.okved?.id ?? '')),
    ...getMaybeField('Result', job.result),
    ...getMaybeField('KeyWords', job.keyWords),
    ...convertResourceUsageDepartments(job.resourceUsageDepartments),
    ...convertStateRegistrationReports(job.stateRegistrationReports),
    ...convertCustomersToRequest(job.customers),
    ...convertDepartmentsToRequest(job.departments),
    ...convertFinancingsToRequest(job.financings),
    ...convertFinancingsByYearToRequest(job.financingsByYear),
    ...convertStagesToRequest(job.stages),
    PartnerCountries: {},
    ...convertRefElementsToRequest('Grntis', 'Grnti', job.grntis),
    ...convertRefElementsToRequest('Udks', 'Udk', job.udks),
    ...convertRefElementsToRequest('Lksetss', 'Lksets', job.lksetss),
    ...convertRefElementsToRequest('ScienceBrunches', 'ScienceBrunch', job.scienceBrunches),
    ...convertRefElementsToRequest('ScienceDomainInterrests', 'ScienceDomainInterrest', job.scienceDomainInterrests),
    ...convertRefElementsToRequest('CriticalTechnologies', 'CriticalTechnology', job.criticalTechnologies),
    ...convertRefElementsToRequest('Pnrs', 'Pnr', job.pnrs),
    ...convertRefElementsToRequest('Pnmitrs', 'Pnmitr', job.pnmitrs),
    ...convertRefElementsToRequest('Pnis', 'Pni', job.pnis),
    ...convertRefElementsToRequest('NtrStrategies', 'NtrStrategy', job.ntrStrategies),
    ...convertRefElementsToRequest('TechPlatforms', 'TechPlatform', job.techPlatforms),
    ...convertRefElementsToRequest('WorkSpaces', 'WorkSpace', job.workSpaces),
    ...convertRefElementsToRequest('DomainKnowledges', 'DomainKnowledges', job.domainKnowledges),
    ...convertRefElementsToRequest('Oecds', 'Oecd', job.oecds),
    ...convertRefElementsToRequest('NtiEteTechnologies', 'NtiEteTechnology', job.ntiEteTechnologies),
    ...convertRefElementsToRequest('NtiMarkets', 'NtiMarket', job.ntiMarkets),
    ...convertPerformersToRequest(job.performers),
    ...convertPartnersToRequest(job.partners),
    ContestRequests: { ContestRequest: job.contestRequests.map(convertContestRequest) },
    ...convertDocumentsToRequest(job.documents, 'local'),
    IsNirRequestDataConsumed: false,
    ...getMaybeField('InternationalProgram', getIdField(job.internationalProgram?.id ?? '')),
    ...getMaybeField('ForeignEnterprise', getIdField(job.foreignEnterprise?.id ?? '')),
    ...convertRefElementsToRequest('PartnerCountries', 'PartnerCountry', job.partnerCountries),
    ...getMaybeField('StopMoneyReason', job.isFinancingStopped ? job.stopMoneyReason?.value : null),
    ...getMaybeField('StopMoneyDate', job.isFinancingStopped ? job.stopMoneyDate : null),
    ...getMaybeField('StateNumber', job.stateNumber),
    ...getMaybeField('StateRegistrationDate', job.stateRegistrationDate),
    ...getMaybeField('StateRegistrationAnnotation', job.stateRegistrationAnnotation),
    ...getMaybeField('StateRegistrationScientificGroundwork', job.stateRegistrationScientificGroundwork),
    ...getMaybeField(
      'StateRegistrationInterdisciplinaryAproachJustification',
      job.stateRegistrationInterdisciplinaryAproachJustification,
    ),
    ...getMaybeField('IsRequestProvision', job.isRequestProvision),
    ...getMaybeField('RequestProvisionAmount', job.requestProvisionAmount || '0.00'),
    ...getMaybeField('RequestProvisionNote', job.requestProvisionNote),
    ...getMaybeField('RequestProvisionType', job.requestProvisionType?.value),

    ...getMaybeField('IsContractProvision', job.isContractProvision),
    ...getMaybeField('ContractProvisionAmount', job.contractProvisionAmount || '0.00'),
    ...getMaybeField('ContractProvisionNote', job.contractProvisionNote),
    ...getMaybeField('ContractProvisionType', job.contractProvisionType?.value),
    ...convertActsToRequest(job.acts),
    ...convertRefElementsToRequest('Vaks', 'Vak', job.vaks),
    ...getMaybeField('NirRequestDataCopyDetail', job.newNirRequestDataCopyDetail),
    ...getMaybeField('ProgramRequestDataCopyDetail', job.newProgramRequestDataCopyDetail),
    ...(job.securityDocumentContract?.id
      ? { SecurityDocumentContract: { _attr: { id: job.securityDocumentContract.id } } }
      : null),
    WorkSpaces: null, // Заглушка
    ...getMaybeField('StateRegistrationEgisuUrl', job.stateRegistrationEgisuUrl),
  };
}

function convertContestRequest(r: Project.ContestRequest) {
  return {
    _attr: { id: r.id },
    ...getMaybeField('DataCopyDetail', r.newDataCopyDetail),
    ...getMaybeField('ProjectNote', r.projectNote),
    ...(r.projectStageId ? { ProjectStage: { _attr: { id: r.projectStageId } } } : {}),
  };
}

export const convertResourceUsageDepartments = (resourceUsageDepartments: Project.ResourceUsageDepartment[]) => {
  if (!resourceUsageDepartments) {
    return { ResourceUsageDepartments: {} };
  }

  return {
    ResourceUsageDepartments: {
      ResourceUsageDepartment: resourceUsageDepartments.map(resourceUsageDepartment => ({
        _attr: resourceUsageDepartment.id ? { id: resourceUsageDepartment.id } : {},
        Type: resourceUsageDepartment.type?.value,
        Department: {
          _attr: resourceUsageDepartment.department?.id ? { id: resourceUsageDepartment.department.id } : {},
        },
        ...getMaybeField('Note', resourceUsageDepartment.note),
      })),
    },
  };
};

export function convertCustomersToRequest(customers: Project.Customer[]) {
  if (!customers.length) {
    return { Customers: {} };
  }

  return {
    Customers: {
      Customer: customers.map((x, index) => ({
        _attr: x.id ? { id: x.id } : {},
        Element: { _attr: { id: x.ref.id } },
        Position: index,
      })),
    },
  };
}

export function convertDepartmentsToRequest(departments: Project.Department[]) {
  if (!departments.length) {
    return { Departments: {} };
  }

  return {
    Departments: {
      Department: departments.map((x, index) => ({
        _attr: x.id ? { id: x.id } : {},
        Unit: { _attr: { id: x.unit!.id } },
        Governance: { _attr: { id: x.governance!.id } },
        Position: index,
      })),
    },
  };
}

export function convertFinancingsToRequest(financings: Project.Financing[]) {
  if (!financings.length) {
    return { Financings: {} };
  }

  return {
    Financings: {
      Financing: financings.map(x => ({
        _attr: x.id ? { id: x.id } : {},
        Type: x.type?.value ?? '',
        Source: { _attr: { id: x.source!.id } },
        ...getMaybeField('Amount', x.amount),
        IsNds: x.isNds,
      })),
    },
  };
}

export function convertStagesToRequest(stages: Project.Stage[]) {
  if (!stages.length) {
    return { Stages: {} };
  }

  return {
    Stages: {
      Stage: stages.map(x => ({
        _attr: x.id ? { id: x.id } : {},
        Number: x.number,
        StartDate: x.startDate,
        EndDate: x.endDate,
        AmountMain: x.amountMain || '0.00',
        AmountCofinancing1: x.amountCofinancing1 || '0.00',
        AmountCofinancing2: x.amountCofinancing2 || '0.00',
        AmountLocal: x.amountLocal || '0.00',
        AmountAccomplice: x.amountAccomplice || '0.00',
        MainNdsPercent: x.mainNdsPercent || '0.00',
        Cofinancing1NdsPercent: x.cofinancing1NdsPercent || '0.00',
        Cofinancing2NdsPercent: x.cofinancing2NdsPercent || '0.00',
        LocalNdsPercent: x.localNdsPercent || '0.00',
        AccompliceNdsPercent: x.accompliceNdsPercent || '0.00',
        ...getMaybeField('Name', x.name),
        ...getMaybeField('KindWork', getIdField(x.kindWork?.id ?? '')),
        IsPatentResearchPlanned: x.isPatentResearchPlanned,
        ...getMaybeField('WorkDescription', x.workDescription),
        ...getMaybeField('Task', x.task),
        ...getMaybeField('Annotation', x.annotation),
        ...getMaybeField('Goal', x.goal),
        ...getMaybeField('ExpectedResults', x.expectedResults),
        Reports: convertReportsToRequest(x.reports),
        Indicators: convertIndicatorsToRequest(x.indicators),
        Indices: convertIndicatorsToRequest(x.indices),
        ...getMaybeField('ObtainedResults', x.obtainedResults),
        ...getMaybeField('UniversityEffect', x.universityEffect),
        ...getMaybeField('RegionalEffect', x.regionalEffect),
        ...getMaybeField('NationalEffect', x.nationalEffect),
      })),
    },
  };
}

export function convertReportsToRequest(reports: Project.StageReport[]) {
  if (!reports.length) {
    return {};
  }

  return {
    Report: reports.map(report => ({
      _attr: report.id ? { id: report.id } : {},
      ...getMaybeField('Type', report.type?.value),
      ...getMaybeField('Status', report.status?.value),
      ...convertFileToXML('File', report.file),
      ...getMaybeField('Udk', getIdField(report.udk?.id ?? '')),
      ...getMaybeField('Name', report.name),
      ...getMaybeField('ReportDate', report.reportDate),
      ...getMaybeField('SendCustomerDate', report.sendCustomerDate),
      ...getMaybeField('ReportOntiDate', report.reportOntiDate),
      ...getMaybeField('RegistrationOntiDate', report.registrationOntiDate),
      ...getMaybeField('OntiNumber', report.ontiNumber),
      ...getMaybeField('RegistrationDate', report.registrationDate),
      ...getMaybeField('Number', report.number),
      ...getMaybeField('Referat', report.referat),
      ...getMaybeField('KeyWords', report.keyWords),
    })),
  };
}

export function convertIndicatorsToRequest(indicators: Project.Indicator[]) {
  if (!indicators.length) {
    return {};
  }

  return {
    Result: indicators.map(indicator => ({
      _attr: indicator.id ? { id: indicator.id } : {},
      ResultItem: { _attr: { id: indicator.ref?.id ?? '' } },
      Plan: indicator.plan,
      Fact: indicator.fact,
      Year: indicator.year,
      ...getMaybeField('Note', indicator.note),
    })),
  };
}

export function convertRefElementsToRequest(listKey: string, itemKey: string, refElements: Project.RefElement[]) {
  if (!refElements.length) {
    return {
      [listKey]: {},
    };
  }

  return {
    [listKey]: {
      [itemKey]: refElements.map((x, index) => ({
        _attr: x.id ? { id: x.id } : {},
        Element: { _attr: { id: x.ref?.id } },
        Position: index,
      })),
    },
  };
}

export function convertFinancingsByYearToRequest(financings: Project.FinancingByYear[]) {
  if (!financings.length) {
    return { FinancingByYears: {} };
  }

  return {
    FinancingByYears: {
      FinancingByYear: financings.map(x => ({
        Year: x.year,
        AmountMain: x.amountMain,
        AmountCofinancing1: x.amountCofinancing1,
        AmountCofinancing2: x.amountCofinancing2,
        AmountLocal: x.amountLocal,
        AmountAccomplice: x.amountAccomplice,
      })),
    },
  };
}

export function convertStateRegistrationReports(reports: Project.StateRegistrationReport[]) {
  if (!reports.length) {
    return { StateRegistrationReports: {} };
  }

  return {
    StateRegistrationReports: {
      StateRegistrationReport: reports.map(report => ({
        _attr: report.id ? { id: report.id } : {},
        ReportType: report.reportType?.value ?? '',
        CitisDate: report.citisDate,
        Stage: { _attr: report.stageId ? { id: report.stageId } : { number: report.stageNumber } },
        ...getMaybeField('StageName', report.stageName),
        ...getMaybeField('ReportName', report.reportName),
        ...getMaybeField('ExpectedResults', report.expectedResults),
        ...getMaybeField('InterdisciplinaryAproachJustification', report.interdisciplinaryAproachJustification),
        ...getMaybeField('ReportIkNumber', report.reportIkNumber),
        ...getMaybeField('ReportArchiveNumber', report.reportArchiveNumber),
        ...getMaybeField('ReportCustomerSendDate', report.reportCustomerSendDate),
        ...getMaybeField('KeyWords', report.keyWords),
        ...getMaybeField('Referat', report.referat),
        ...convertFileToXML('File', report.file),
      })),
    },
  };
}

export function convertPerformersToRequest(performers: Project.Performer[]) {
  if (!performers.length) {
    return { Performers: {} };
  }

  return {
    Performers: {
      Performer: performers.map(performer => ({
        _attr: performer.id ? { id: performer.id } : {},
        Person: { _attr: { id: performer.person?.id } },
        ...convertJobPeriodsToRequest(performer.jobPeriods),
        ...convertPerformerStagesToRequest(performer.stages),
      })),
    },
  };
}

export function convertJobPeriodsToRequest(jobPeriods: Project.JobPeriod[]) {
  if (!jobPeriods.length) {
    return { JobPeriods: {} };
  }

  return {
    JobPeriods: {
      JobPeriod: jobPeriods.map(jobPeriod => ({
        _attr: jobPeriod.id ? { id: jobPeriod.id } : {},
        Role: jobPeriod.role?.value,
        StartDate: jobPeriod.startDate,
        EndDate: jobPeriod.endDate,
        ...getHistoricalSliceXML(jobPeriod),
        ...(jobPeriod.person ? { Person: { _attr: { id: jobPeriod.person.id } } } : {}),
        ...getMaybeField('Note', jobPeriod.note),
        ...(jobPeriod.startDateOrder ? { StartDateOrder: { _attr: { id: jobPeriod.startDateOrder.id } } } : {}),
        ...(jobPeriod.endDateOrder ? { EndDateOrder: { _attr: { id: jobPeriod.endDateOrder.id } } } : {}),
      })),
    },
  };
}

export function convertPerformerStagesToRequest(stages: Project.PerformerStage[]) {
  if (!stages.length) {
    return { Stages: {} };
  }

  return {
    Stages: {
      Stage: stages.map(stage => ({
        _attr: stage.id ? { id: stage.id } : {},
        Stage: { _attr: stage.stage?.id ? { id: stage.stage?.id } : { number: stage.stage?.number ?? '' } },
        Approved: stage.approved,
        ...getMaybeField('JobDescription', stage.jobDescription),
      })),
    },
  };
}

export function convertPartnersToRequest(partners: Project.Partner[]) {
  if (!partners.length) {
    return { Partners: {} };
  }

  return {
    Partners: {
      Partner: partners.map(partner => ({
        _attr: partner.id ? { id: partner.id } : {},
        Partner: { _attr: { id: partner.partner?.id } },
        Role: partner.role?.value,
        ContractType: partner.contractType?.value,
        ContractNumber: partner.contractNumber,
        StartDate: partner.startDate,
        EndDate: partner.endDate,

        ...getMaybeField('ContractName', partner.contractName),
        ...getMaybeField('LeaderFullName', partner.leaderFullName),
        ...getMaybeField('LeaderDegree', partner.leaderDegree),
        ...getMaybeField('LeaderRank', partner.leaderRank),
        ...getMaybeField('LeaderJobEnterprise', getIdField(partner.leaderJobEnterprise?.id ?? '')),
        ...getMaybeField('LeaderAppointment', partner.leaderAppointment),
        ...getMaybeField('LeaderContacts', partner.leaderContacts),

        ...getMaybeField('KindWork', partner.kindWork),
        ...convertPartnerFinancingsToRequest(partner.financings),
        ...getMaybeField('Note', partner.note),
      })),
    },
  };
}

export function convertPartnerFinancingsToRequest(financings: Project.PartnerFinancing[]) {
  if (!financings.length) {
    return { Financings: {} };
  }

  return {
    Financings: {
      Financing: financings.map(financing => ({
        _attr: financing.id ? { id: financing.id } : {},
        Year: financing.year,
        Amount: financing.amount,
      })),
    },
  };
}

export function convertActsToRequest(acts?: Project.Act[]) {
  if (!acts || !acts.length) {
    return { Acts: {} };
  }

  return {
    Acts: {
      Act: acts.map(a => ({
        _attr: a.id ? { id: a.id } : {},
        Stage: { _attr: a?.stage?.id ? { id: a?.stage?.id } : { number: a.stage?.number } },
        Number: a.number,
        ...(a?.council?.id ? { Council: { _attr: { id: a?.council?.id } } } : {}),
        ...getMaybeField('DefaultBaseProgram', a.defaultBaseProgram),
        ...getMaybeField('ConsiderationDocuments', a.сonsiderationDocuments),
        ...getMaybeField('Conclusion', a.conclusion),
        ...getMaybeField('Note', a.note),
        Members: a.members && a.members.length ? { Member: a.members.map(convertActMemberToXML) } : {},
        Departments: a.departments && a.departments.length ? { Department: a.departments.map(convertActDepartmentToXML) } : {},
      })),
    },
  };
}
export function convertActMemberToXML(m: Project.ActMember) {
  return {
    _attr: m.id ? { id: m.id } : {},
    CouncilMember: {
      _attr: m?.councilMember?.id ? { id: m.councilMember?.id } : {},
      ...getHistoricalSliceXML(m),
    },
  };
}
export function convertActDepartmentToXML(d: Project.ActDepartment) {
  return {
    _attr: d.id ? { id: d.id } : {},
    Department: convertDepartmentToXML(d.department),
  };
}
