import { useEffect, useMemo, useState, useCallback } from 'react';

import { ReferenceItem } from 'components';

import { useAppDataContext } from 'features/AppData/context';
import * as BackendAPI from 'services/BackendAPI';

type Item = {
  name: string;
  data: number[];
};

export function useController() {
  const [rusAndOtherByPeriod, setRusAndOtherByPeriod] = useState<Item[]>([
    {
      name: 'Q1',
      data: [0, 0, 0, 0, 0, 0],
    },
  ]);

  const { userSystemDepartment } = useAppDataContext();
  const [actualDepartment, setActualDepartment] = useState<ReferenceItem | null>(userSystemDepartment);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actualYear, setActualYear] = useState(new Date().getFullYear());

  const categories = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const { methods: GetDashboardChartTotalPublicationListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartTotalPublicationListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        const chartAttrRes = data.Response.Grid.GroupingRows.TotalRow._attributes;
        setRusAndOtherByPeriod([
          {
            name: 'Публикации',
            data: categories.map(i => Number(chartAttrRes[`Year${i}`]) || 0),
          },
        ]);
      },
    },
  );

  useEffect(() => {
    setIsLoading(true);
    GetDashboardChartTotalPublicationListStatic.callAPI(
      {
        parameters: [
          {
            name: 'department',
            value: actualDepartment ? actualDepartment.id : '-1',
          },
        ],
      },
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualDepartment]);

  const getModalTitle = useMemo(
    () =>
      `Общее количество публикаций${actualDepartment ? ` подразделения ${actualDepartment.label}` : ''}${
        actualYear ? ` за ${actualYear}г.` : ''
      }`,
    [actualDepartment, actualYear],
  );

  const seriesClickHandler = useCallback(
    (year: number) => {
      setActualYear(year);
      setIsModalOpen(true);
    },
    [setActualYear, setIsModalOpen],
  );

  return {
    setActualDepartment,
    actualDepartment,
    isLoading,
    categories,
    rusAndOtherByPeriod,
    seriesClickHandler,
    getModalTitle,
    isModalOpen,
    setIsModalOpen,
    actualYear,
  };
}
