import { useCallback, useLayoutEffect, useState, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Column, buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Participation, Department } from 'types/models/Participation';
import { showNotification } from 'features/Notifications';
import { Author } from 'types/models';

type Props = {
  id: string;
  onClose: () => void;
  afterSubmit: (departments: Department[]) => void;
};

const useController = ({ id, onClose, afterSubmit }: Props) => {
  const { methods: getParticipation } = BackendAPI.useBackendAPI('GetParticipation');
  const { methods: saveParticipation } = BackendAPI.useBackendAPI('SaveParticipation');

  const [participationData, setParticipationData] = useState<null | Participation>(null);
  const [participationDepartments, setParticipationDepartments] = useState<Department[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const departmentColumns = useMemo<Column<Department>[]>(() => [{ label: 'Название', formatValue: row => row.name }], []);

  useLayoutEffect(() => {
    setIsLoading(true);
    getParticipation.callAPI(
      {
        participationId: id,
        modules: [
          'ID',
          'ACCESS_MODE',
          'MAIN',
          'EVENT',
          'DEPARTMENTS',
          'PUBLICATION',
          'PROJECTS',
          'MEMBERS',
          'AWARDS',
          'MOBILE_REQUESTS',
          'DOCUMENTS',
          'INCENTIVE_PAYMENTS',
        ],
        eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
        IsCollectiveExhibit: false,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setIsLoading(false);
          setParticipationData(data);
          setParticipationDepartments(data.departments);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Department>[]>(
    () => [
      {
        icons: buttonIcons.clone,
        title: 'Добавить подразделения участиников',
        onClick: () => {
          if (participationData?.members) {
            setParticipationDepartments(getUniqOnly(checkDepartaments(participationData.members, participationDepartments)));
          }
        },
      },
    ],
    [participationData, participationDepartments],
  );

  const checkDepartaments = (members: Author[], prevDepartments: Department[]) => {
    let departments: Department[] = [...prevDepartments];
    members
      .map(({ job, education }) => {
        return {
          job: job?.refDepartment
            ? ({
                id: job?.refDepartment?.id,
                code: job?.refDepartment?.code,
                name: job?.refDepartment?.label,
              } as Department)
            : null,
          education: education?.refDepartment
            ? ({
                id: education?.refDepartment?.id,
                code: education?.refDepartment?.code,
                name: education?.refDepartment?.shortName,
              } as Department)
            : null,
        };
      })
      .map(item => {
        if (item.job?.id && item.job?.name) {
          departments.push(item.job);
        }
        if (item.education?.id && item.education?.name) {
          departments.push(item.education);
        }
      });

    return departments;
  };

  const getUniqOnly = (departList: Department[]) => {
    let uniqIdArray = Array.from(new Set(departList.map(x => x.id)));
    const result = departList.filter(prevItem => {
      if (uniqIdArray.indexOf(prevItem.id) > -1) {
        uniqIdArray = uniqIdArray.filter(item => item !== prevItem.id);
        return true;
      }
      return false;
    });
    return result;
  };

  const handleSaveParticipation = useCallback(() => {
    if (participationDepartments.length) {
      setIsLoading(true);
      saveParticipation.callAPI(
        {
          participationId: id,
          simpleFields: {
            participationProcedure: 'WITHOUT_REQUEST',
            isInTeam: false,
            IsCollectiveExhibit: false,
            note: participationData?.note || '',
            development: participationData?.development,
            collectiveExhibitName: participationData?.collectiveExhibitName,
            isCollectiveExhibit: participationData?.isCollectiveExhibit,
            reportTheme: participationData?.reportTheme,
            reportDate: participationData?.reportDate,
          },
          attrIdFields: {
            event: participationData?.event?.id,
            exhibitType: participationData?.exhibitType?.id,
            reportType: participationData?.reportType?.id,
            publication: participationData?.publication?.status?.id,
            form: participationData?.form?.id,
            exponent: participationData?.exponent?.id || undefined,
          },
          otherFields: {
            departments: participationDepartments,
            documents: participationData?.documents,
            awards: participationData?.awards,
            members: participationData?.members,
            mobileRequests: participationData?.mobileRequests,
            presentationProject: participationData?.presentationProject || null,
            financingProjects: participationData?.financingProjects || [],
            participationPresentationForm: (participationData?.presentationForms || []).map(x => ({
              isChecked: true,
              value: x,
              label: '',
              ordinal: 0,
              position: 0,
            })),
          },
        },
        {
          onSuccessfullCall: () => {
            setIsLoading(false);
            showNotification({ message: 'Связь участия с подразделением успешно сохранена', theme: 'success' });
            afterSubmit(participationDepartments);
            onClose();
          },
          onFailedCall: () => {
            setIsLoading(false);
          },
        },
      );
    } else {
      showNotification({ message: 'Нужно указать хотя бы одно подразделение', theme: 'danger' });
    }
  }, [
    participationDepartments,
    saveParticipation,
    id,
    participationData?.note,
    participationData?.development,
    participationData?.collectiveExhibitName,
    participationData?.isCollectiveExhibit,
    participationData?.reportTheme,
    participationData?.reportDate,
    participationData?.event?.id,
    participationData?.exhibitType?.id,
    participationData?.reportType?.id,
    participationData?.publication?.status?.id,
    participationData?.form?.id,
    participationData?.exponent?.id,
    participationData?.documents,
    participationData?.awards,
    participationData?.members,
    participationData?.mobileRequests,
    participationData?.presentationProject,
    participationData?.financingProjects,
    participationData?.presentationForms,
    afterSubmit,
    onClose,
  ]);

  return {
    participationData,
    departmentColumns,
    participationDepartments,
    setParticipationDepartments,
    handleSaveParticipation,
    isLoading,
    checkDepartaments,
    getUniqOnly,
    extraToolbarButtons,
  };
};

export default useController;
