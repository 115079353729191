import React, { memo, useMemo } from 'react';
import { setup } from 'bem-cn';
import * as icons from 'icons';

import { Button, ButtonMode, Modal } from 'components';

import { Languages } from 'utils/Constants';
import { useAppDataContext } from 'features/AppData/context';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('bibliographic-record-error-popup');

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  textLanguageId: string;
  errorText: string;
};

const BibliographicRecordErrorPopup = ({ isOpen, onClose, errorText, textLanguageId, onConfirm }: Props) => {
  const { initialLanguageCode } = useAppDataContext();
  const topText = useMemo(() => {
    const isCyrillic = textLanguageId === initialLanguageCode?.id;
    const currentLanguage = Languages.find(lang => lang.id === textLanguageId);

    const text = `В библиографической записи публикации на ${
      currentLanguage ? currentLanguage.accusative : 'выбранном'
    } языке обнаружены ${isCyrillic ? 'латинские' : 'русские'} символы:`;

    return text;
  }, [textLanguageId, initialLanguageCode]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Ошибка построения библиографической записи" size="small">
      <div className={b()}>
        <div className={b('body')}>
          <div className={b('icon-wrapper')}>
            <img src={icons.WarningConfirm} alt="" className={b('icon')} />
          </div>
          <div className={b('text-content')}>
            <p className={b('text')}>{topText}</p>
            <p className={b('text', { bold: true })} dangerouslySetInnerHTML={{ __html: errorText }} />
            <p className={b('text')}>Вы уверены, что библиографическая запись соответствует указанной в источнике?</p>
          </div>
        </div>
        <ul className={b('action-list')}>
          <li className={b('action-item')}>
            <Button mode={ButtonMode.primary} text="Да, соответствует" onClick={onConfirm} />
          </li>
          <li className={b('action-item')}>
            <Button mode={ButtonMode.secondary} text="Нет" onClick={onClose} />
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export const Component = memo(BibliographicRecordErrorPopup);
