import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { validate } from './validate';
import { ValueOf } from 'types/helpers';
import { WorkMode } from 'types/models/Table';
import { KindConsumption } from 'types/models/KindConsumption';
import { GetKindConsumptionSelectList } from 'features/Table/specifications/GetKindConsumptionSelectList';
import { Table } from 'types/models';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export enum KindConsumptionRadioOptions {
  empty = 'empty',
  isCalculateTax = 'isCalculateTax',
  isSendOverhead = 'isSendOverhead',
  isAccomplicePayment = 'isAccomplicePayment',
}

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const workMode = useMemo((): WorkMode => (editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode'), [editMode, viewMode]);

  const [selectedRadio, setSelectedRadio] = useState<KindConsumptionRadioOptions>(KindConsumptionRadioOptions.empty);
  const [kindConsumption, setKindConsumption] = useState<KindConsumption | null>();
  const [kindConsumptionId, setKindConsumptionId] = useState<string>();

  const { methods: getKindConsumption } = BackendAPI.useBackendAPI('GetKindConsumption', {
    onSuccessfullCall: ({ data }) => {
      setKindConsumption(data);
      if (data.isCalculateTax) {
        setSelectedRadio(KindConsumptionRadioOptions.isCalculateTax);
      } else if (data.isAccomplicePayment) {
        setSelectedRadio(KindConsumptionRadioOptions.isAccomplicePayment);
      } else if (data.isSendOverhead) {
        setSelectedRadio(KindConsumptionRadioOptions.isSendOverhead);
      }
    },
  });
  const { methods: saveKindConsumption } = BackendAPI.useBackendAPI('SaveKindConsumption', {
    onSuccessfullCall: () => {
      tableStreams.reloadTable.push();
    },
  });

  const kindConsumptionLabel = useMemo(() => {
    return kindConsumption?.parent
      ? `${kindConsumption.parent.code} ${kindConsumption.parent.name} / ${kindConsumption?.code} ${kindConsumption?.name}`
      : `${kindConsumption?.code} ${kindConsumption?.name}` || '';
  }, [kindConsumption]);

  const handleRadioChange = useCallback(
    (value: KindConsumptionRadioOptions): void => {
      setSelectedRadio(value);
      if (kindConsumption?.id) {
        setKindConsumption({
          ...kindConsumption,
          isAccomplicePayment: value === KindConsumptionRadioOptions.isAccomplicePayment,
          isCalculateTax: value === KindConsumptionRadioOptions.isCalculateTax,
          isSendOverhead: value === KindConsumptionRadioOptions.isSendOverhead,
        });
      } else {
        setKindConsumption(null);
      }
    },
    [kindConsumption],
  );

  const onSubmit = useCallback((): boolean => {
    const errors: { isValid: boolean; invalidMessage: string }[] = validate({
      kindConsumption,
    });
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        showNotification({ message: error.invalidMessage, theme: 'danger' });
      });
      return false;
    }
    if (kindConsumption) {
      saveKindConsumption.callAPI({
        ...kindConsumption,
        id: id || kindConsumption?.id || '',
      });
    }
    return true;
  }, [kindConsumption, id, saveKindConsumption]);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const makeChangeHandler = useCallback(
    (key: keyof KindConsumption) => (value: ValueOf<KindConsumption> | Table.Entry) => {
      if (kindConsumption) {
        setKindConsumption({ ...kindConsumption, [key]: value });
      }
    },
    [kindConsumption, setKindConsumption],
  );
  const taxSpecification = GetKindConsumptionSelectList({ setKindConsumptionId });

  const taxTableRowConverter = useCallback<(row: Table.Entry) => KindConsumption>(row => {
    return {
      id: row.id || '',
      name: row.name,
      code: row.code,
      kvr: { id: '', label: row.kvr },
      kosgu: { id: '', label: row.kosgu },
      isActive: row.isActive === 'true',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTaxChange = useCallback(
    (rows: KindConsumption | null) => {
      if (rows != null) {
        makeChangeHandler('tax')({
          ...rows,
          id: rows?.id || '',
          name: `${rows?.code} ${rows?.name}`,
        });
      } else {
        makeChangeHandler('tax')(null);
      }
    },
    [makeChangeHandler],
  );

  useLayoutEffect(() => {
    if (id) getKindConsumption.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    id,
    viewMode,
    editMode,
    workMode,
    handleFormSubmit,
    kindConsumption,
    makeChangeHandler,
    selectedRadio,
    handleRadioChange,
    kindConsumptionLabel,
    taxSpecification,
    taxTableRowConverter,
    handleTaxChange,
    kindConsumptionId,
  };
}
