import { ListEdit, SectionTitle, FormComponent, Reference, ReferenceItem } from 'components';

import React from 'react';
import { ProgramRequest } from 'types/models/ProgramRequest';
import { ItemListEdit } from 'features/Form/looks/programRequest/ProgramRequestForm/ItemListEdit/ItemListEdit';
import { GetProgramDomainKnowledgeSelectList } from 'features/Table/specifications/GetProgramDomainKnowledgeSelectList';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { useController } from './controller';
import { GetReferenceElementList } from 'features/Table/specifications';
import { Specification } from 'types/models/Table';

type Props = {
  viewMode?: boolean;
  requestInfo: ProgramRequest | null;
  makeChangeHandler(key: keyof ProgramRequest): (val: ValueOf<ProgramRequest>) => void;
  okveds: ReferenceItem[];
  handleOkvedChange(value: ReferenceItem | null): void;
  okvedParentLabel: string;
  lksetss: ReferenceItem[];
  handleLksetsChange(value: ReferenceItem | null): void;
  lksetsParentLabel: string;
  scienceBrunches: ReferenceItem[];
  handleScienceBrunchChange(value: ReferenceItem | null): void;
  scienceBrunchParentLabel: string;
  domainKnowledges: ReferenceItem[];
  handleDomainKnowledgesChange(value: ReferenceItem[]): void;
};

export function Classifiers(props: Props) {
  const {
    viewMode,
    requestInfo,
    makeChangeHandler,
    okveds,
    handleOkvedChange,
    okvedParentLabel,
    lksetss,
    handleLksetsChange,
    lksetsParentLabel,
    scienceBrunches,
    handleScienceBrunchChange,
    scienceBrunchParentLabel,
    domainKnowledges,
    handleDomainKnowledgesChange,
  } = props;

  const { domainKnowledgeTableRowConverter, scienceDomainInterrestsFilters } = useController({
    requestInfo,
  });

  const { settings } = useAppDataContext();

  const programDomains = requestInfo?.tender?.program?.domainKnowledges;

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <ItemListEdit
              title="ГРНТИ"
              rows={requestInfo?.grntis || []}
              onChange={value => makeChangeHandler('grntis')(value)}
              modalTitle='Справочник "ГРНТИ"'
              refName="RefGrnti"
              disabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="УДК"
              rows={requestInfo?.udks || []}
              onChange={value => makeChangeHandler('udks')(value)}
              modalTitle='Справочник "УДК"'
              refName="RefUdk"
              disabled={!!viewMode}
            />
          </FormComponent.Line>

          <SectionTitle title="2-Наука" />
          <FormComponent.Line>
            <FormComponent.Field label="ОКВЭД">
              <Reference
                name="RefOkved2"
                onChange={value => handleOkvedChange(value)}
                value={
                  okveds.length > 0
                    ? {
                        id: okveds[0]?.id ? okveds[0]?.id : '',
                        label: okveds[0].label,
                      }
                    : null
                }
                relationTableModalTitle="ОКВЭД"
                disabled={!!viewMode}
              />

              <FormComponent.Description>{okvedParentLabel}</FormComponent.Description>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="ЛКСЭЦ">
              <Reference
                name="RefLksets"
                onChange={value => handleLksetsChange(value)}
                value={
                  lksetss.length > 0
                    ? {
                        id: lksetss[0]?.id ? lksetss[0]?.id : '',
                        label: lksetss[0].label,
                      }
                    : null
                }
                relationTableModalTitle="Классификатор социально-экономических целей"
                disabled={!!viewMode}
              />

              <FormComponent.Description>{lksetsParentLabel}</FormComponent.Description>
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field label="Отрасль науки">
              <Reference
                name="RefScienceBrunch"
                onChange={value => handleScienceBrunchChange(value)}
                value={
                  scienceBrunches.length > 0
                    ? {
                        id: scienceBrunches[0]?.id ? scienceBrunches[0]?.id : '',
                        label: scienceBrunches[0].label,
                      }
                    : null
                }
                relationTableModalTitle='Справочник "Отрасли науки"'
                disabled={!!viewMode}
              />

              <FormComponent.Description>{scienceBrunchParentLabel}</FormComponent.Description>
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <ItemListEdit
              title="Области научных интересов"
              rows={requestInfo?.scienceDomainInterrests || []}
              onChange={value => makeChangeHandler('scienceDomainInterrests')(value)}
              modalTitle="Список областей научных интересов по условиям конкурса"
              refName="RefScienceDomainInterest"
              disabled={!!viewMode}
              filters={scienceDomainInterrestsFilters}
              isLeftPanelHidden
              // eslint-disable-next-line max-len
              tooltipTitle="В таблице отображается список допустимых областей научных интересов по условиям конкурса. Удалите те области, которые не соответствуют теме вашей заявки."
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Критические технологии"
              rows={requestInfo?.criticalTechnologies || []}
              onChange={value => makeChangeHandler('criticalTechnologies')(value)}
              modalTitle='Справочник "Критические технологии"'
              refName="RefPriorityTechnology"
              disabled={!!viewMode}
            />
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <ItemListEdit
              title={`Приоритетные направления развития ${settings?.organization?.shortName}`}
              rows={requestInfo?.pnrs || []}
              onChange={value => makeChangeHandler('pnrs')(value)}
              modalTitle={`Справочник "ПНР ${settings?.organization?.shortName}"`}
              refName="RefPnr"
              disabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Приоритетные направления модернизации и технического развития экономики России"
              rows={requestInfo?.pnmitrs || []}
              onChange={value => makeChangeHandler('pnmitrs')(value)}
              modalTitle='Справочник "ПНР модернизации РФ"'
              refName="RefPnmitr"
              disabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Приоритетные направления развития науки, технологий и техники"
              rows={requestInfo?.pnis || []}
              onChange={value => makeChangeHandler('pnis')(value)}
              modalTitle="Приоритетные направления науки, технологий и техники"
              refName="RefPni"
              disabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Стратегии научно-технического развития РФ"
              rows={requestInfo?.ntrStrategies || []}
              onChange={value => makeChangeHandler('ntrStrategies')(value)}
              modalTitle='Справочник "Стратегии научно-технического развития РФ"'
              refName="RefNtrStrategy"
              disabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Технологические платформы"
              rows={requestInfo?.technologyPlatforms || []}
              onChange={value => makeChangeHandler('technologyPlatforms')(value)}
              modalTitle='Справочник "Технологические платформы"'
              refName="RefTechnologyPlatform"
              disabled={!!viewMode}
            />
          </FormComponent.Line>

          <FormComponent.Line>
            <ListEdit
              visibleToolbarButtons={['add', 'delete']}
              title="Области знаний"
              rows={domainKnowledges}
              onChange={handleDomainKnowledgesChange}
              withMessages
              isDeleteConfirmEnabled
              columns={[{ label: 'Название', formatValue: row => row?.label }]}
              withoutHead={true}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "Области знаний"',
                ...(programDomains?.length
                  ? {
                      modalTableRowConverter: domainKnowledgeTableRowConverter,
                      modalTableSpecification: GetProgramDomainKnowledgeSelectList({
                        programId: requestInfo?.tender?.program?.id || '-1',
                      }),
                    }
                  : {
                      modalTableRowConverter: row => ({ id: row.id, label: row.label, code: row.code }),
                      modalTableSpecification: GetReferenceElementList({
                        removeEdit: true,
                        requestData: {
                          filters: [],
                          name: 'RefDomainKnowledge',
                        },
                      }) as Specification<object, object>,
                    }),
              }}
              isToolbarDisabled={!!viewMode}
            />
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
