import { useMemo, useCallback, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { MipEnterpriseCustomer } from 'features/Form/views/AddMip/types';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

const useController = ({ tableState, customState: { loadMipPartner, openForm, setMode, deleteMipPartner } }: Props) => {
  const [isPreAddFormOpen, setIsPreAddFormOpen] = useState<boolean>(false);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState<boolean>(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState<boolean>(false);

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState.selectedRows]);

  const isViewButtonDisabled = useMemo(() => !row, [row]);
  const isEditButtonDisabled = useMemo(() => !row, [row]);
  const isDeleteButtonDisabled = useMemo(() => !row, [row]);

  const onViewClick = useCallback(() => {
    if (row) {
      setMode('view');
      loadMipPartner(row.id, openForm);
    }
  }, [loadMipPartner, openForm, row, setMode]);

  const onEditClick = useCallback(() => {
    if (row) {
      setMode('edit');
      loadMipPartner(row.id, openForm);
    }
  }, [loadMipPartner, openForm, row, setMode]);

  const onAddClick = useCallback(() => {
    setIsPreAddFormOpen(true);
  }, []);

  const onDeleteClick = useCallback(() => {
    setIsConfirmDeletePopupOpen(true);
  }, []);

  const onDeleteConfirm = useCallback(() => {
    if (row) {
      deleteMipPartner({ partnerId: row.id, type: row.Type, fullName: row.FullName, shortName: row.ShortName });
      setIsConfirmDeletePopupOpen(false);
    }
  }, [deleteMipPartner, row]);

  const onPreAddClose = useCallback(() => {
    setIsPreAddFormOpen(false);
  }, []);

  const onPreAddSubmit = useCallback(
    (enterprise: MipEnterpriseCustomer | null) => {
      setIsPreAddFormOpen(false);
      if (enterprise?.value?.partnerId) {
        setMode('edit');
        loadMipPartner(enterprise.value?.partnerId, () => {
          openForm();
          setTimeout(() => {
            setIsInfoPopupOpen(true);
          });
        });
      } else if (enterprise?.value?.id) {
        setMode('add');
        loadMipPartner(enterprise.value?.id, openForm, 'ENTERPRISE_CUSTOMER');
      }
    },
    [loadMipPartner, openForm, setMode],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        permissionName: Permits.MIP_VIEW,
        isDisabled: isViewButtonDisabled,
        onClick: onViewClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавление',
        permissionName: Permits.MIP_ADD,
        isDisabled: false,
        onClick: onAddClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактирование',
        permissionName: Permits.MIP_EDIT,
        isDisabled: isEditButtonDisabled,
        onClick: onEditClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удаление',
        permissionName: Permits.MIP_DELETE,
        isDisabled: isDeleteButtonDisabled,
        onClick: onDeleteClick,
      },
    ],
    [isViewButtonDisabled, onViewClick, onAddClick, isEditButtonDisabled, onEditClick, isDeleteButtonDisabled, onDeleteClick],
  );

  const onCloseInfoPopup = useCallback(() => {
    setIsInfoPopupOpen(false);
  }, []);

  const onDeleteClose = useCallback(() => {
    setIsConfirmDeletePopupOpen(false);
  }, []);

  return {
    buttons,
    row,
    isPreAddFormOpen,
    isInfoPopupOpen,
    isConfirmDeletePopupOpen,
    onDeleteClose,
    onDeleteConfirm,
    onCloseInfoPopup,
    onPreAddSubmit,
    onPreAddClose,
  };
};

export default useController;
