import React from 'react';
import { FormComponent, ListEdit, Modal, SectionTitle } from 'components';
import { getMockCharterCapital } from 'features/EditMipPartner/helpers';
import { MipPartner } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { formatNumber } from 'utils/Helpers';
import { Founders } from './Founders';
import { Fields } from './Fields';
import { validate } from './validate';
import { useController } from './controller';

type Props = {
  charterCapitals: MipPartner.CharterCapital[];
  setCharterCapitals: (charterCapitals: MipPartner.CharterCapital[]) => void;
  isDisabled: boolean;
};

export const CharterCapitals = ({ charterCapitals, setCharterCapitals, isDisabled }: Props) => {
  const {
    selectedRowIndex,
    setSelectedRowIndex,
    isHelpFormOpen,
    setIsHelpFormOpen,
    extraToolbarButtons,
    formatFounders,
    formatAsserts,
  } = useController();

  return (
    <>
      <SectionTitle title="Уставной капитал" />
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        rows={charterCapitals}
        onChange={setCharterCapitals}
        isToolbarDisabled={isDisabled}
        defaultRowsCount={5}
        onSelectedRowIndexCallback={setSelectedRowIndex}
        extraToolbarButtons={extraToolbarButtons}
        maxHeight="none"
        columns={[
          { label: 'Дата изменения', formatValue: x => x.editDate, styles: { width: '20%' } },
          { label: 'Сумма', formatValue: x => formatNumber(x.amount), styles: { width: '20%' } },
          { label: 'Учредители', formatValue: formatFounders, styles: { width: '30%' } },
          { label: 'Активы', formatValue: formatAsserts, styles: { width: '30%' } },
        ]}
        columnIndexesForSumTotal={[1]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Уставной капитал',
          renderComponent: (charterCapital, setCharterCapital) => (
            <Fields charterCapital={charterCapital ?? getMockCharterCapital()} setCharterCapital={setCharterCapital} />
          ),
          validation: {
            checkIsValid: charterCapital => validate(charterCapital).every(x => x.isValid),
            onInvalidate: charterCapital => {
              const validationInfo = validate(charterCapital);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
      <Founders charterCapitals={charterCapitals} selectedRowIndex={selectedRowIndex} />
      <Modal title="Уставной капитал" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              В таблице необходимо вводить полную информацию о всех изменениях в уставном капитале: сумма, учредители, сведения о
              внесённых в состав активах. Для выбранной строки в таблице <strong>Уставной капитал</strong>. В таблице&nbsp;
              <strong>Учредители</strong> отображается информация о каждом учредителе, его доле.
            </p>
            <p>Вкладка доступна для пользователей с правом на Редактирование МИП</p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
