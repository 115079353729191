import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { ProjectType } from 'utils/Enums';

export function usePermissions(projectType: ProjectType) {
  const { userPermission } = useAppDataContext();

  const stateRegistrationVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectStateRegistrationView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectStateRegistrationView',
    GRANT: 'GrantProjectStateRegistrationView',
    GOVERMENT_CONTRACT: 'GovContractProjectStateRegistrationView',
  };

  const stateRegistrationEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectStateRegistrationEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectStateRegistrationEdit',
    GRANT: 'GrantProjectStateRegistrationEdit',
    GOVERMENT_CONTRACT: 'GovContractProjectStateRegistrationEdit',
  };

  const documentsVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectFilesView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectFilesView',
    GOVERMENT_CONTRACT: 'GovContractProjectFilesView',
    GRANT: 'GrantProjectFilesView',
  };

  const documentsEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectFilesEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectFilesEdit',
    GOVERMENT_CONTRACT: 'GovContractProjectFilesEdit',
    GRANT: 'GrantProjectFilesEdit',
  };

  const executorsVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobExecutorsView',
    GRANT: 'GrantExecutorsView',
    GOVERMENT_CONTRACT: 'GovContractExecutorsView',
    ECONOMIC_AGREEMENT: 'EconomicContractExecutorsView',
  };

  const executorsEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobExecutorsEdit',
    GRANT: 'GrantExecutorsEdit',
    GOVERMENT_CONTRACT: 'GovContractExecutorsEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractExecutorsEdit',
  };

  const calendarPlanVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobCalendarPlanView',
    GRANT: 'GrantCalendarPlanView',
    GOVERMENT_CONTRACT: 'GovContractCalendarPlanView',
    ECONOMIC_AGREEMENT: 'EconomicContractCalendarPlanView',
  };

  const calendarPlanEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobCalendarPlanEdit',
    GRANT: 'GrantCalendarPlanEdit',
    GOVERMENT_CONTRACT: 'GovContractCalendarPlanEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractCalendarPlanEdit',
  };

  const classifiersVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectClassificatorsView',
    GRANT: 'GrantProjectClassificatorsView',
    GOVERMENT_CONTRACT: 'GovContractProjectClassificatorsView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectClassificatorsView',
  };

  const classifiersEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectClassificatorsEdit',
    GRANT: 'GrantProjectClassificatorsEdit',
    GOVERMENT_CONTRACT: 'GovContractProjectClassificatorsEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectClassificatorsEdit',
  };

  const aboutProjectVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobAboutProjectView',
    GRANT: 'GrantAboutProjectView',
    GOVERMENT_CONTRACT: 'GovContractAboutProjectView',
    ECONOMIC_AGREEMENT: 'EconomicContractAboutProjectView',
  };

  const aboutProjectEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobAboutProjectEdit',
    GRANT: 'GrantAboutProjectEdit',
    GOVERMENT_CONTRACT: 'GovContractAboutProjectEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractAboutProjectEdit',
  };

  const projectDescriptionVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectDescriptionView',
    GRANT: 'GrantProjectDescriptionView',
    GOVERMENT_CONTRACT: 'GovContractProjectDescriptionView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectDescriptionView',
  };

  const projectDescriptionEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectDescriptionEdit',
    GRANT: 'GrantProjectDescriptionEdit',
    GOVERMENT_CONTRACT: 'GovContractProjectDescriptionEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectDescriptionEdit',
  };

  const projectFeaturesVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectFeaturesView',
    GRANT: 'GrantProjectFeaturesView',
    GOVERMENT_CONTRACT: 'GovContractProjectFeaturesView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectFeaturesView',
  };

  const projectFeaturesEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectFeaturesEdit',
    GRANT: 'GrantProjectFeaturesEdit',
    GOVERMENT_CONTRACT: 'GovContractProjectFeaturesEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectFeaturesEdit',
  };

  const resultsVisibilityRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectResultsView',
    GRANT: 'GrantProjectResultsView',
    GOVERMENT_CONTRACT: 'GovContractProjectResultsView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectResultsView',
  };

  const resultsEditableRuleMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectResultsEdit',
    GRANT: 'GrantProjectResultsEdit',
    GOVERMENT_CONTRACT: 'GovContractProjectResultsEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectResultsEdit',
  };

  const lockedEditMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobLockedEdit',
    GRANT: 'GrantLockedEdit',
    GOVERMENT_CONTRACT: 'GovContractLockedEdit',
    ECONOMIC_AGREEMENT: 'EconomicContractLockedEdit',
  };

  const isVisibleStateRegistration = isHasPermission(userPermission, stateRegistrationVisibilityRuleMap[projectType]);
  const isEditableStateRegistration = isHasPermission(userPermission, stateRegistrationEditableRuleMap[projectType]);

  const isVisibleDocuments = isHasPermission(userPermission, documentsVisibilityRuleMap[projectType]);
  const isEditableDocuments = isHasPermission(userPermission, documentsEditableRuleMap[projectType]);

  const isVisibleExecutors = isHasPermission(userPermission, executorsVisibilityRuleMap[projectType]);
  const isEditableExecutors = isHasPermission(userPermission, executorsEditableRuleMap[projectType]);

  const isVisibleCalendarPlan = isHasPermission(userPermission, calendarPlanVisibilityRuleMap[projectType]);
  const isEditableCalendarPlan = isHasPermission(userPermission, calendarPlanEditableRuleMap[projectType]);

  const isVisibleClassifiers = isHasPermission(userPermission, classifiersVisibilityRuleMap[projectType]);
  const isEditableClassifiers = isHasPermission(userPermission, classifiersEditableRuleMap[projectType]);

  const isVisibleAboutProject = isHasPermission(userPermission, aboutProjectVisibilityRuleMap[projectType]);
  const isEditableAboutProject = isHasPermission(userPermission, aboutProjectEditableRuleMap[projectType]);

  const isVisibleProjectDescription = isHasPermission(userPermission, projectDescriptionVisibilityRuleMap[projectType]);
  const isEditableProjectDescription = isHasPermission(userPermission, projectDescriptionEditableRuleMap[projectType]);

  const isVisibleProjectFeatures = isHasPermission(userPermission, projectFeaturesVisibilityRuleMap[projectType]);
  const isEditableProjectFeatures = isHasPermission(userPermission, projectFeaturesEditableRuleMap[projectType]);

  const isVisibleResults = isHasPermission(userPermission, resultsVisibilityRuleMap[projectType]);
  const isEditableResults = isHasPermission(userPermission, resultsEditableRuleMap[projectType]);

  const isEditableLocked = isHasPermission(userPermission, lockedEditMap[projectType]);

  return {
    isEditableLocked,
    isVisibleStateRegistration,
    isEditableStateRegistration,
    isVisibleDocuments,
    isEditableDocuments,
    isVisibleExecutors,
    isEditableExecutors,
    isVisibleCalendarPlan,
    isEditableCalendarPlan,
    isVisibleClassifiers,
    isEditableClassifiers,
    isVisibleAboutProject,
    isEditableAboutProject,
    isVisibleProjectDescription,
    isEditableProjectDescription,
    isVisibleProjectFeatures,
    isEditableProjectFeatures,
    isVisibleResults,
    isEditableResults,
  };
}
