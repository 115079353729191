import React, { useMemo } from 'react';

import { Checkbox, FormComponent, SectionTitle } from 'components';

import { Event, Form, Table } from 'types/models';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { projectsStreams, mobileRequestsStream, eventsStreams } from 'features/Form/looks/compilation/CompilationForm/streams';
import { Conferences } from 'features/Form/views/Сonferences';
import { Requests } from 'features/Form/views/Requests';
import { Projects } from 'features/Form/views/Projects';

type Props = {
  relatedTableState: Table.State | undefined;
  workMode: Table.WorkMode;
  events: Event.Event[];
  projects: PublicationProject[];
  mobileRequests: PublicationMobileRequest[];
  formFields: Form.Fields;
  publicationType: string | undefined;
  conferenceEventId?: string;
};

const setLocalProjects = (rows: PublicationProject[]) => {
  projectsStreams.setProjects.push(rows);
};

const setLocalProjectsAccept = (id: string) => {
  projectsStreams.accept.push(id);
};

const setLocalProjectsCancelAcception = (id: string) => {
  projectsStreams.cancelAcceptance.push(id);
};

const setLocalMobileRequest = (rows: PublicationMobileRequest[]) => {
  mobileRequestsStream.setMobileRequests.push(rows);
};

const setLocalMobileRequestAccept = (id: string) => {
  mobileRequestsStream.accept.push(id);
};

const setLocalMobileRequestCancelAcception = (id: string) => {
  mobileRequestsStream.cancelAcceptance.push(id);
};

const removeLocalEvents = (id: string) => {
  eventsStreams.removeEvent.push(id);
};

const getLocalEvent = (id: string) => {
  eventsStreams.getEvent.push(id);
};

function Events({
  relatedTableState,
  workMode,
  events,
  projects,
  mobileRequests,
  formFields,
  publicationType,
  conferenceEventId,
}: Props) {
  const eventsArray = useMemo(() => Object.values(events), [events]);

  return (
    <form>
      {publicationType !== 'OTHER_BOOK' && (
        <>
          <SectionTitle title="Конференции" />
          <FormComponent.Line>
            <Checkbox
              label={formFields.isConfirencesPublished.title}
              checked={!!formFields.isConfirencesPublished.value}
              onChange={formFields.isConfirencesPublished.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <Conferences
              disabled={Boolean(conferenceEventId)}
              workMode={workMode}
              removeLocalEvents={removeLocalEvents}
              getLocalEvent={getLocalEvent}
              formFields={formFields}
              events={eventsArray}
              relatedTableState={relatedTableState}
            />
          </FormComponent.Line>
        </>
      )}
      <SectionTitle title="Опубликовано при финансовой поддержке" />
      <FormComponent.Line>
        <Projects
          setLocalProjects={setLocalProjects}
          setLocalProjectsAccept={setLocalProjectsAccept}
          setLocalProjectsCancelAcception={setLocalProjectsCancelAcception}
          workMode={workMode}
          projects={projects}
          relatedTableState={relatedTableState}
        />
      </FormComponent.Line>
      <FormComponent.Line>
        <Requests
          setLocalMobileRequest={setLocalMobileRequest}
          setLocalMobileRequestAccept={setLocalMobileRequestAccept}
          setLocalMobileRequestCancelAcception={setLocalMobileRequestCancelAcception}
          workMode={workMode}
          requests={mobileRequests}
          relatedTableState={relatedTableState}
        />
      </FormComponent.Line>
    </form>
  );
}

export const Component = React.memo(Events);
