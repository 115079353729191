import { Member } from 'features/SelectPerson';
import { MobileRequest, PersonHistory } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { MobileRequestFinancingType } from 'utils/Enums';
import { getMockPerson } from 'features/Form/looks/person/helpers';

export function getMockPersonHistory(): PersonHistory {
  return {
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    historyLabel: '',
    job: null,
    rank: null,
  };
}

export function getMockMobileRequest(id: string | null): MobileRequest.MobileRequest {
  return {
    ...getMockPersonHistory(),
    id,
    visa: null,
    activityKind: null,
    advertisingMaterialTypes: [],
    applicant: null,
    applicantContact: '',
    applicantDepartment: null,
    applicantLanguageCompetence: '',
    applicantNote: '',
    applicantSupervisors: [],
    businessTripEndDate: '',
    businessTripStartDate: '',
    code: '',
    description: '',
    estimatePositions: [],
    eventMaterialLevel: '',
    eventReportName: '',
    financings: [],
    isEventReportAccepted: false,
    language: null,
    mobileProtocol: null,
    number: '',
    pnrs: [],
    purpose: null,
    routes: [],
    sendDate: '',
    status: null,
    theme: '',
    traineeEndDate: '',
    traineeNecessityDocument: '',
    traineeStartDate: '',
    tripPlanes: [],
    useMaterialObligations: [],
    documents: [],
    specialNote: '',
    tender: null,
    lot: null,
    applicantEducationYear: null,
    applicantGroupNumber: null,
    scienceDomainInterrest: null,
    tenderParticipant: null,
  };
}

export function getMockApplicantSupervisor(): MobileRequest.ApplicantSupervisor {
  return {
    ...getMockPersonHistory(),
    id: null,
    role: null,
    supervisor: null,
  };
}

export function convertMobileRequestToMember(mobileRequest: MobileRequest.MobileRequest): Member {
  const { initialCountryCode } = useAppDataContext();
  const result: Member = {
    ...mobileRequest,
    person: {
      ...getMockPerson({ initialCountryCode: mobileRequest.applicant?.scientist?.citizenship || initialCountryCode }),
      ...mobileRequest.applicant,
      id: mobileRequest.applicant?.id || '-1',
    },
  };
  return result;
}

export function convertMemberToMobileRequest(
  prevMobileRequest: MobileRequest.MobileRequest,
  member: Member,
): MobileRequest.MobileRequest {
  const result: MobileRequest.MobileRequest = {
    ...prevMobileRequest,
    ...member,
    applicant: member.person
      ? {
          ...member.person,
        }
      : null,
  };
  return result;
}

export function getFinancingSourceLabel(financing: MobileRequest.Financing): string {
  const result =
    financing.type?.value === MobileRequestFinancingType.SF1
      ? financing.sf1Source?.projectCodeValue || financing.sf1Source?.fundCardValue || ''
      : financing.type?.value === MobileRequestFinancingType.SF2
      ? financing.department?.label || ''
      : financing.type?.value === MobileRequestFinancingType.SF3
      ? financing.sf3Source?.label || ''
      : '';
  return result;
}

export function getEstimatePositionsAmount(estimatePositions: MobileRequest.EstimatePosition[]): number {
  const result: number = estimatePositions.map(x => parseFloat(x.amount)).reduce((a, b) => a + b, 0);
  return result;
}

export function getFinancingsAmount(financings: MobileRequest.Financing[]): number {
  const result: number = financings.map(x => parseFloat(x.amount)).reduce((a, b) => a + b, 0);
  return result;
}
