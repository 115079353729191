import React, { useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import еntityTypes from '../еntityTypes';
// eslint-disable-next-line max-len
import { getParticipationTypeByEventType } from 'features/Table/specifications/GetProjectParticipationEventList/LeftPanelForThirdLevel/helpers';

type Props = {
  isParticipation: boolean;
} & F.TemplateProps;

const ViewTemplate: React.FC<Props> = ({ isParticipation, isOpen, onClose, id, relatedTableState }: Props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const selectedType =
    relatedTableState && relatedTableState?.selectedRows.length
      ? isParticipation
        ? getParticipationTypeByEventType(relatedTableState.selectedRows[0]['id:EventType'])
        : relatedTableState.selectedRows[0]['id:EventType']
      : '';

  const look: F.Look = {
    apiID: 'GetPublication',
    template: еntityTypes.filter(type => type.code === selectedType)[0]?.template,
    type: selectedType,
    viewMode: true,
    id: String(id),
    relatedTableState,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'medium-height']} viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ViewTemplate);
