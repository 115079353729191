import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setup } from 'bem-cn';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('slider');

export type SliderParams = {
  step?: number;
  min?: number;
  max?: number;
};

const defaultParams: SliderParams = {
  step: 1,
  min: 0,
  max: 100,
};

export type Props = {
  value: number;
  label?: string;
  params?: SliderParams;
  onChange?(val: number): void;
  isDisabled?: boolean;
  classMixin?: string | string[];
};

/**
 * File uploader/viewer
 *
 * @param value - Value for slider
 * @param label - (optional) Label for render on right side
 * @param params - (optional) Params for slider
 *   @param params.step - (optional) Step of range (1 by default)
 *   @param params.min - (optional) Min of range (0 by default)
 *   @param params.max - (optional) Max of range (100 by default)
 * @param onChange - (optional) Callback on mouse up selected range
 * @param isDisabled - (optional) Set is disabled slider
 * @param classMixin - (optional) Mixin class(-es) for external customization
 * @returns JSX
 */
export const Slider = React.memo((props: Props) => {
  const { value = 0, label, onChange = () => {}, isDisabled = false, classMixin } = props;

  const { min = 0, max = 0, step = 1 } = {
    ...defaultParams,
    ...(props?.params as SliderParams),
  };

  type EventMousePosition = {
    clientX: number;
    clientY: number;
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const lineRef = useRef<HTMLDivElement | null>(null);
  const [isMouseDowned, setIsMouseDowneded] = useState<boolean>(false);
  const [startLineWidth, setStartLineWidth] = useState<number>(0);
  const [mouseStart, setMouseStart] = useState<number>(0);
  const [mouseDistance, setMouseDistance] = useState<number>(0);

  const containerWidth = containerRef.current?.getBoundingClientRect().width || 0;

  const calcValue = useCallback(
    (val: number) => {
      const result: number = min + (val / (containerWidth || 1)) * (max - min);
      return Math.round(result / step) * step;
    },
    [containerWidth, max, min, step],
  );

  const lineWidth = useMemo(() => {
    const result: number = startLineWidth + mouseDistance;

    if (result < 0) {
      return 0;
    }
    if (result > containerWidth) {
      return containerWidth;
    }
    return result;
  }, [containerWidth, mouseDistance, startLineWidth]);

  const handleMouseDown = (e: EventMousePosition) => {
    setIsMouseDowneded(true);
    setStartLineWidth(lineRef.current?.getBoundingClientRect().width || 0);
    setMouseStart(e.clientX);
  };

  useEffect(() => {
    const handleMouseMove = (e: EventMousePosition) => {
      if (isMouseDowned) {
        setMouseDistance(e.clientX - mouseStart);
      }
    };

    const handleMouseUp = () => {
      if (isMouseDowned) {
        setIsMouseDowneded(false);
        onChange(calcValue(lineWidth));
        setMouseDistance(0);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [calcValue, containerRef, isMouseDowned, lineWidth, mouseStart, onChange]);

  useEffect(() => {
    if (value) {
      setStartLineWidth(Math.round(((value - min) * containerWidth) / (max - min)));
    }
  }, [containerWidth, max, min, value]);

  return (
    <div className={b({ 'is-disabled': isDisabled }).mix(classMixin)}>
      <div ref={containerRef} className={b('container', { 'is-active': isMouseDowned })}>
        <div ref={lineRef} className={b('line')} style={{ width: `${lineWidth}px` }}>
          <div className={b('point')} onMouseDown={handleMouseDown}></div>
          <div className={b('bubble')}>
            <strong>{calcValue(lineWidth)}</strong>
          </div>
        </div>
      </div>
      <div className={b('label')}>
        {value}
        {label ? ` ${label}` : ''}
      </div>
    </div>
  );
});
