import { CheckboxSettings, ClonePerformersData, Period } from './model';

export const getDefaultPeriod = (): Period => ({
  from: '',
  to: '',
});

export const getDefaultCheckboxSettings = (): CheckboxSettings => ({
  leader: {
    isChecked: false,
  },
  responsiblePerformer: {
    isChecked: false,
  },
  performers: {
    isChecked: false,
  },
  assistances: {
    isChecked: false,
  },
});

export const getClonePerformersData = (): ClonePerformersData => ({
  leader: null,
  responsiblePerformer: null,
  performers: [],
  assistances: [],
});
