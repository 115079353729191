import { useCallback } from 'react';

import { showNotification } from 'features/Notifications';

export const IsbnMask = () => {
  return {
    // Mask (max 17 symbols)
    mask: (value: string = '') => {
      const preparedValue = value.replaceAll('_', '');
      return [
        /[1-9]/,
        /[0-9-]/,
        /[0-9-]/,
        /[0-9-]/,
        /[0-9-]/,
        /[0-9-]/,
        /[0-9-]/,
        /[0-9-]/,
        /[0-9-]/,
        /[0-9-]/,
        ...(preparedValue.indexOf('-') === -1
          ? preparedValue.length <= 10
            ? []
            : [/[0-9]/, /[0-9]/, /[0-9]/]
          : [
              /[0-9-]/,
              /[0-9-]/,
              ...(preparedValue.length <= 13
                ? [/[0-9]/]
                : [/[0-9-]/, ...(preparedValue.length <= 14 ? [/[0-9]/] : [/[0-9-]/, /[0-9-]/, /[0-9-]/, /[0-9]/])]),
            ]),
      ];
    },

    // Validate
    pipe: useCallback((conformedValue: any) => {
      const clearedValue = conformedValue.replaceAll('-', '');

      if (conformedValue.indexOf('_') > -1) {
        return { value: conformedValue, indexesOfPipedChars: [] };
      }

      if (clearedValue.length > 13) {
        showNotification({
          message: 'ISBN может состоять только из 10 или 13 символов без учёта разделителей',
          theme: 'danger',
        });
        return false;
      }

      if (conformedValue.length - clearedValue.length > 4) {
        showNotification({
          message: 'Количество разделителей для ISBN не может превышать 4х',
          theme: 'danger',
        });
        return false;
      }

      return { value: conformedValue, indexesOfPipedChars: [] };
    }, []),
  };
};
