import React, { useEffect, useState } from 'react';

import { Button, ButtonMode, Modal, TextArea, TextInput, TextInputMode, FormComponent } from 'components';

import { showErrors } from 'utils/Validators';
import { Payment } from 'types/models';
import { Term } from 'features/IncentivePayments/model';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(term: Term): void;
  termPreset: Payment.TermPreset | null;
};

export function TermPresetModal(props: Props) {
  const { isOpen, onClose, onSubmit, termPreset } = props;

  useEffect(() => {
    setFields(prev => ({
      ...prev,
      regulationName: termPreset?.regulationName ?? '',
      regulationParagraph: termPreset?.regulationParagraph ?? '',
    }));
  }, [termPreset]);

  const [fields, setFields] = useState<Term>({
    regulationParagraph: '',
    regulationName: '',
    maxNomineesMoney: '0',
    maxFundMoney: '0',
  });

  const changeField = (key: keyof Term, val: string) => {
    setFields(prevFields => ({ ...prevFields, [key]: val }));
  };

  const handleSubmit = () => {
    const validationInfo = validateForm(fields);
    if (validationInfo.some(x => !x.isValid)) {
      showErrors(validateForm, fields);
      return;
    }
    onSubmit(fields);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Изменение условий оплаты" size="large">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Пункт положения">
            <TextInput value={fields.regulationParagraph} onChange={value => changeField('regulationParagraph', value)} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Название">
            <TextArea
              settings={{ isFixed: true }}
              value={fields.regulationName}
              onChange={value => changeField('regulationName', value)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Сумма номинантам, руб:">
            <TextInput
              mode={TextInputMode.number}
              value={fields.maxNomineesMoney}
              onChange={value => changeField('maxNomineesMoney', value)}
            />
          </FormComponent.Field>
          <FormComponent.Field isRequired label="Сумма фонда, руб:">
            <TextInput
              mode={TextInputMode.number}
              value={fields.maxFundMoney}
              onChange={value => changeField('maxFundMoney', value)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Изменить" onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}

function validateForm(fields: Record<string, string>) {
  const { regulationName, regulationParagraph, maxFundMoney, maxNomineesMoney } = fields;
  const isValidRegularParagraph = Boolean(regulationParagraph);
  const isValidRegulationName = Boolean(regulationName);
  const isValidMaxNomineesMoney = Boolean(maxNomineesMoney);
  const isValidMaxFundMoney = Boolean(maxFundMoney);
  const isMaxFundMoneyMoreZero = Number(maxFundMoney) > 0;

  return [
    { isValid: isValidRegularParagraph, invalidMessage: 'Не указан пункт положения' },
    { isValid: isValidRegulationName, invalidMessage: 'Не указано название условия оплаты' },
    { isValid: isValidMaxNomineesMoney, invalidMessage: 'Не указана сумма по номинантам' },
    { isValid: isValidMaxFundMoney, invalidMessage: 'Заполните сумму фонда' },
    { isValid: isMaxFundMoneyMoreZero, invalidMessage: 'Сумма фонда должна быть больше нуля' },
  ];
}
