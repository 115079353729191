import { useCallback, useMemo } from 'react';
import { useAppDataContext } from 'features/AppData/context';
import { Option } from 'components';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getEnum } from 'utils/Helpers';

type Props = {
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};
const useController = ({ makeChangeHandler }: Props) => {
  const { enumMap } = useAppDataContext();
  const provisionTypeOptions = useMemo<Option[]>(() => {
    return getEnum('ProvisionType', enumMap);
  }, [enumMap]);

  const onChangeIsContractProvision = useCallback(
    (nextIsContractProvision: boolean) => {
      makeChangeHandler('isContractProvision')(nextIsContractProvision);
      if (!nextIsContractProvision) {
        makeChangeHandler('contractProvisionNote')('');
        makeChangeHandler('contractProvisionAmount')('0.00');
        makeChangeHandler('contractProvisionType')(null);
      }
    },
    [makeChangeHandler],
  );
  const onChangeIsRequestProvision = useCallback(
    (nextIsRequestProvision: boolean) => {
      makeChangeHandler('isRequestProvision')(nextIsRequestProvision);
      if (!nextIsRequestProvision) {
        makeChangeHandler('requestProvisionNote')('');
        makeChangeHandler('requestProvisionAmount')('0.00');
        makeChangeHandler('requestProvisionType')(null);
      }
    },
    [makeChangeHandler],
  );
  return {
    provisionTypeOptions,
    onChangeIsRequestProvision,
    onChangeIsContractProvision,
  };
};

export default useController;
