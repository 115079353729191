import React, { useMemo } from 'react';

import { Table } from 'types/models';
import { buttonIcons, ConfirmPopup, IconButtonProps, RelationTableModal, Toolbar as SharedToolbar } from 'components';

import { getIsCanEdit } from 'utils/Helpers/feedline/getIsCanEdit';
import { getIsCanDelete } from 'utils/Helpers/feedline/getIsCanDelete';
import { State } from '../makeUseCustomController';
import { getMockFeedline } from '../helpers';

import { MessageModal } from './MessageModal/MessageModal';
import { GetProjectCodeChangesInProject } from '../..';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar({ customState, tableState }: Props) {
  const {
    loadProjectFeedline,
    isOpenMessageModal,
    toggleVisibleMessageModal,
    feedline,
    setFeedline,
    deleteFeedline,
    handleSubmitMessageModal,
    closeMessageModal,
    toggleVisibleCodeChangesModal,
    isOpenCodeChangesModal,
    projectId,
    isOpenConfirmModal,
    setIsOpenConfirmModal,
    disabled,
  } = customState;

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const isDeleteButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanDelete(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const [row] = tableState.selectedRows;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isDisabled: disabled,
        onClick: () => {
          toggleVisibleMessageModal();
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: isEditButtonDisabled || disabled,
        onClick: () => {
          toggleVisibleMessageModal();
          loadProjectFeedline(row.id);
        },
      },
      {
        icons: buttonIcons.delete,
        isDisabled: isDeleteButtonDisabled || disabled,
        title: 'Удалить',
        onClick: () => {
          setIsOpenConfirmModal(true);
        },
      },
      {
        icons: buttonIcons.clock,
        title: 'История',
        isDisabled: disabled,
        onClick: toggleVisibleCodeChangesModal,
      },
    ],
    [
      row,
      setIsOpenConfirmModal,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      loadProjectFeedline,
      toggleVisibleMessageModal,
      toggleVisibleCodeChangesModal,
      disabled,
    ],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <MessageModal
        isOpen={isOpenMessageModal}
        onClose={closeMessageModal}
        feedline={feedline || getMockFeedline()}
        changeFeedline={setFeedline}
        onSubmit={handleSubmitMessageModal}
      />
      <RelationTableModal
        isOpen={isOpenCodeChangesModal}
        onClose={toggleVisibleCodeChangesModal}
        relationTableModalTitle="Журнал изменений в проекте"
        specification={GetProjectCodeChangesInProject({ projectId })}
      />
      <ConfirmPopup
        icon="warning"
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isOpenConfirmModal}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmModal(false)}
        onConfirm={() => {
          deleteFeedline(row.id);
          setIsOpenConfirmModal(false);
        }}
      />
    </>
  );
}

export { Toolbar };
