import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';

type Props = {
  saveForm?(): void;
};

export function useController(props: Props) {
  const { saveForm } = props;
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'save',
        isDisabled: false,
        isHidden: saveForm === undefined,
        onClick: saveForm,
      },
      {
        icons: buttonIcons.toolbarQuestion,
        title: 'Написать сообщение администратору',
        code: 'save',
        isDisabled: false,
        isHidden: false,
        onClick: handleHelpButtonClick,
      },
    ],
    [saveForm, handleHelpButtonClick],
  );

  return {
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
  };
}
