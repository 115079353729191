import { TEXT_DEBOUNCE_TIME } from 'features/Table/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Props as ComponentProps } from './index';

type Props = object & ComponentProps;

const useController = ({ setColumnFilter, value }: Props) => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const inputDebounce = useDebouncedCallback((newValue: string) => {
    setColumnFilter(newValue);
  }, TEXT_DEBOUNCE_TIME);

  const onInputChange = useCallback(
    (nextValue: string) => {
      setInputValue(nextValue);
      inputDebounce(nextValue);
    },
    [inputDebounce],
  );

  return {
    inputValue,
    onInputChange,
  };
};

export default useController;
