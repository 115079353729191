import { useCallback, useState } from 'react';
import * as R from 'ramda';

import { Column } from 'components/ListEdit/model';

import { Performer } from 'types/models/ProgramRequest';
import { showNotification } from 'features/Notifications';
import { useReportsHook } from 'features/BuildReportPopup';
import { Member } from 'features/SelectPerson';
import { useAppDataContext } from 'features/AppData/context';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { getEnum, getEnumItem } from 'utils/Helpers';
import { RequestPerformerRole } from 'utils/Enums';
import { Item } from 'types/models/common';

type Props = {
  rows: Performer[];
  onChange(items: Performer[]): void;
  isEditPerformersOnly?: boolean;
  columns?: Column<Performer>[];
  isMultiRoles?: boolean;
};

export function useController(props: Props) {
  const { rows, onChange, isEditPerformersOnly, columns } = props;

  const { enumMap } = useAppDataContext();
  const roles = getEnum('RequestPerformerRole', enumMap) as Item<RequestPerformerRole>[];

  const formatJobAndEducation = (performer: Performer) => {
    const tokens = [];
    if (performer.job) {
      tokens.push(formatJob(performer.job, false));
    }
    if (performer.education) {
      tokens.push(formatEducation(performer.education));
    }
    return tokens.join('/');
  };

  const listEditColumns =
    columns && columns.length > 0
      ? columns
      : [
          { label: 'Роль', formatValue: (row: Performer) => roles.find(role => role.value === row.role?.value)?.label || '' },
          { label: 'ФИО', formatValue: (row: Performer) => row?.person?.fullName || '' },
          { label: 'Дата рождения', formatValue: (row: Performer) => row?.person?.scientist?.dateBirth || '' },
          {
            label: 'Степень, звание',
            formatValue: (row: Performer) =>
              [
                row?.degree?.refDegree?.label || '',
                row?.rank?.refRank?.label || '',
                row?.academicRank?.refAcademicRank?.label || '',
              ]
                .filter(x => x)
                .join(', '),
          },
          {
            label: 'Место работы/обучения',
            formatValue: formatJobAndEducation,
          },
          { label: 'Телефон', formatValue: (row: Performer) => row?.person?.scientist?.phone ?? '' },
          { label: 'Моб. телефон', formatValue: (row: Performer) => row?.person?.scientist?.mobilePhone ?? '' },
          { label: 'E-mail', formatValue: (row: Performer) => row?.person?.scientist?.email ?? '' },
        ];

  const [scientistId, setScientistId] = useState<string | null>(null);

  const [isOpenHistoryPersonModal, setIsOpenHistoryPersonModal] = useState(false);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);
  const [selectedPerformerIndex, setSelectedPerformerIndex] = useState<number | null>(null);

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const submitHistoryPersonModal = useCallback(
    (member: Partial<Member>) => {
      if (selectedPerformerIndex !== null) {
        const updatedPerformers = R.over(
          R.lensIndex(selectedPerformerIndex),
          (prevPerformer: Performer) => ({ ...prevPerformer, ...member }),
          rows,
        );
        onChange(updatedPerformers);
      }
    },
    [selectedPerformerIndex, rows, onChange],
  );

  const handleCloseHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(false);
    setSelectedPerformerIndex(null);
  }, [setIsOpenHistoryPersonModal, setSelectedPerformerIndex]);

  const handleOpenHistoryModal = useCallback(
    (row: Performer | null, idx: number | null) => {
      setSelectedPerformerIndex(idx);
      setIsOpenHistoryPersonModal(true);
    },
    [setIsOpenHistoryPersonModal],
  );

  const selectedPerformer = selectedPerformerIndex !== null ? rows[selectedPerformerIndex] : null;

  const closePersonModal = useCallback(() => {
    setIsOpenPersonModal(false);
    setSelectedPerformerIndex(null);
  }, []);

  const makePreSubmitCallback = (row: Performer, submit: (row: Performer) => void) => {
    if (isEditPerformersOnly) {
      submit({ ...row, ...{ role: getEnumItem('RequestPerformerRole', RequestPerformerRole.PERFORMER, enumMap) } });
    } else {
      submit(row);
    }
  };

  const makePreCallback = (row: Performer | null, cb: () => void) => {
    if (isEditPerformersOnly) {
      if (row?.role?.value !== 'PERFORMER') {
        showNotification({ message: 'Запрещено редактировать/удалять руководителя и отв. исполнителя', theme: 'danger' });
      } else {
        cb();
      }
    } else {
      cb();
    }
  };

  return {
    listEditColumns,
    makePreSubmitCallback,
    makePreCallback,
    closePersonModal,
    selectedPerformer,
    isOpenHistoryPersonModal,
    handleOpenHistoryModal,
    submitHistoryPersonModal,
    isReportOpen,
    onReportClose,
    handleSetCurrentReport,
    currentReport,
    isOpenPersonModal,
    setIsOpenPersonModal,
    setSelectedPerformerIndex,
    setScientistId,
    handleCloseHistoryModal,
    scientistId,
  };
}
