import React, { useState, useCallback, useMemo } from 'react';

import { ConfirmPopup, IconButton, IconButtonProps, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { settings } = useAppDataContext();
  const { isSearchMode } = customState;
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isPersonButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isPublicationButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isPersonFormOpen, setIsPersonFormOpen] = useState(false);
  const [isPublicationFormOpen, setIsPublicationFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handlePersonButtonClick = useCallback(() => {
    setIsPersonFormOpen(true);
  }, []);
  const handleTemplateClosePersonForm = useCallback(() => {
    setIsPersonFormOpen(false);
  }, []);
  const handlePublicationButtonClick = useCallback(() => {
    setIsPublicationFormOpen(true);
  }, []);
  const handleTemplateClosePublicationForm = useCallback(() => {
    setIsPublicationFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        isDisabled: false,
        // isHidden: !isSearchMode,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Добавить/Редактировать индекс Хирша персоны',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        isHidden: isSearchMode,
      },
      {
        icons: buttonIcons.person,
        title: 'Информация о персоне',
        code: 'view',
        isDisabled: isPersonButtonDisabled,
        onClick: handlePersonButtonClick,
      },
      {
        icons: buttonIcons.viewList,
        title: 'Список публикаций автора для расчёта совокупного импакт-фактора',
        code: 'view',
        isDisabled: isPublicationButtonDisabled,
        onClick: handlePublicationButtonClick,
        isHidden: isSearchMode,
      },
    ],
    [
      isSearchMode,
      isViewButtonDisabled,
      handleViewButtonClick,
      isPersonButtonDisabled,
      handlePersonButtonClick,
      isPublicationButtonDisabled,
      handlePublicationButtonClick,
      handleHelpButtonClick,
    ],
  );

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.ScientistHirshIndexTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isPersonButtonDisabled && (
        <modalTemplates.ScientistPersonTemplate.Component
          isOpen={isPersonFormOpen}
          onClose={handleTemplateClosePersonForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isPublicationButtonDisabled && (
        <modalTemplates.ScientistPublicationTemplate.Component
          isOpen={isPublicationFormOpen}
          onClose={handleTemplateClosePublicationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <ConfirmPopup
        title={isSearchMode ? 'Поиск персон по научным интересам' : 'Помощь в Наукометрических показателях персон'}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {isSearchMode ? (
          <div className="modal-help">
            <p>
              <strong className="primary">
                Раздел Поиск персон по научным интересам содержит информацию о научных интересах сотрудников, аспирантов и
                студентов университета.
              </strong>
            </p>
            <p>
              Используйте строку поиска по словам в столбцах таблицы для поиска персон по тематике или направлению исследований.
            </p>
            <p>
              Список персон вы можете выгрузить в файл с помощью кнопки
              <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
            </p>
            <p>
              Посмотреть портфолио персоны — с помощью кнопки
              <IconButton icons={buttonIcons.person} hasNoHover />
            </p>
          </div>
        ) : (
          <div className="modal-help">
            <p>
              Раздел содержит сведения о наукометрических показателях сотрудников, аспирантов, студентов&nbsp;
              {settings?.organization?.shortName}: индекс Хирша в основных базах индексации публикаций (Scopus, WoS, РИНЦ)
              совокупный, пятилетний и средневзвешенный импакт- фактор, статистика по публикациям, в том числе по годам.
            </p>
            <p>
              Импакт-фактор для персон пересчитывается 1 раз в сутки, ночью, на основе данных об импакт- факторе издания
              публикации. Значения индекса Хирша обновляют в системе ответственные лица не реже двух раз в год.
            </p>
            <p>
              <strong className="primary">В разделе можно использовать фильтры для сортировки информации:</strong>
            </p>
            <ul>
              <li>Фильтр «С публикациями» отображает список персон, имеющих публикации</li>
              <li>Фильтр «Все» отображает список персон, из состава Справочника персон</li>
              <li>
                Фильтр «Год» показывает количество публикаций за указанный в фильтре год; если фильтр отключен, то отображается
                общее количество публикаций.
              </li>
            </ul>
            <p>
              <strong className="primary">В разделе Наукометрические показатели персон вы можете:</strong>
            </p>
            <ul>
              <li>
                Открыть карточку персоны для просмотра — с помощью кнопки
                <IconButton icons={buttonIcons.person} hasNoHover />
              </li>
              <li>
                Добавить/редактировать индекс Хирша персоны — с помощью кнопки (при наличии права)
                <IconButton icons={buttonIcons.edit} hasNoHover />
              </li>
              <li>
                Посмотреть список публикаций автора, которые использовались для расчета совокупного импакт-фактора нажатием кнопки
                <IconButton icons={buttonIcons.viewList} hasNoHover />
              </li>
              <li>
                Выгрузить данные в формат Еxcel — с помощью кнопки
                <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
              </li>
              <li>
                Задать вопрос администратору — с помощью кнопки
                <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
              </li>
            </ul>
          </div>
        )}
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
