import { Table } from 'types/models';
import { Filters } from './filters';

export const GetMonographForIncentivePayment: Table.Specification = {
  apiID: 'GetMonographForIncentivePayment',
  header: {
    firstLevel: {
      title: 'Список монографий и глав монографий',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      hasSelectButton: true,
    },
  },
};
