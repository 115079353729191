import { ReferenceItem } from 'components';
import { CollectionValue, Entity, EnumerationValue, Field, FieldType } from 'types/models/Merge';
import { getMaybeField } from '../commonConverters';
import { MergeScientistRequest, SaveMergeRequest } from './types';

export const convertMergeScientistToRequest = ({ action, entityIds }: MergeScientistRequest) => ({
  Merge: {
    _attr: {
      action,
      entity: 'Scientist',
    },
    Entity: entityIds.map(convertEntityIdToRequest),
  },
});

export const convertSaveMergeToRequest = ({ merge, action }: SaveMergeRequest) => ({
  Merge: {
    _attr: {
      action,
      entity: 'Scientist',
    },
    Entity: convertEntitiesToRequest(merge.entities),
    ...convertCommonToRequest({ fields: merge.common, commonId: merge.commonId }),
  },
});

const convertEntityIdToRequest = (entityId: string) => ({ _attr: { id: entityId } });

export const convertEntitiesToRequest = (entities: Entity[]) => entities.map(({ id }) => convertEntityIdToRequest(id));

export const convertCommonToRequest = ({ fields, commonId }: { fields: Field[]; commonId: string | null }) => {
  const baseCommon = {
    Common: {
      _attr: {
        ...getMaybeField('id', commonId),
      },
    },
  };

  if (!fields.length) {
    return baseCommon;
  }

  return {
    Common: {
      ...baseCommon.Common,
      Field: fields
        .filter(({ isConflicted, isForceEditable }) => isConflicted || isForceEditable)
        .map(field => {
          const getPreparedField = (fieldToPrepare: Field) => {
            switch (fieldToPrepare.type) {
              case FieldType.STRING:
              case FieldType.TEXT:
              case FieldType.DATE:
                return { ...getMaybeField('_text', fieldToPrepare.value) };
              case FieldType.BOOLEAN:
                return { _text: Boolean(fieldToPrepare.value) };
              case FieldType.ENUMERATION:
                const enumerationValue = fieldToPrepare.value ? (fieldToPrepare.value as EnumerationValue) : null;
                return { ...getMaybeField('_text', enumerationValue?.value) };
              case FieldType.REFERENCE:
                const referenceValue = fieldToPrepare.value ? (fieldToPrepare.value as ReferenceItem) : null;
                return { ...getMaybeField('_text', referenceValue?.id) };
              case FieldType.COLLECTION:
                return { Id: (fieldToPrepare.collection || []).map(({ id }) => ({ _text: id })) };
              default:
                return {};
            }
          };
          const preparedField = getPreparedField(field);

          const isPreparedFieldEmpty = Object.keys(preparedField).length === 0 && preparedField.constructor === Object;

          if (isPreparedFieldEmpty) {
            return null;
          }

          return {
            _attr: {
              name: field.name,
              type: field.type,
              ...getMaybeField('reference', field.reference),
              ...getMaybeField('index', field.type === FieldType.COLLECTION && '-1'),
              ...getMaybeField('enumeration', field.enumeration),
              ...getMaybeField('alternativeId', (field.value as CollectionValue).analogId),
            },
            ...preparedField,
          };
        })
        .filter(field => Boolean(field)),
    },
  };
};
