import React from 'react';
import { block } from 'bem-cn';

import { buttonIcons, IconButton, RelationTableModal, TextInput } from 'components';

import useController from './controller';

import './style.scss';

const b = block('grid-reference-filter');

export type Props = {
  value: string;
  caption: string;
  reference: string;
  setColumnFilter: (nextValue: string) => void;
};

export const ReferenceFilter = ({ value, caption, reference, setColumnFilter }: Props) => {
  const {
    selectedRefs,
    specification,
    textFieldValue,
    isRelationTableOpen,
    reset,
    openRelationTable,
    closeRelationTable,
  } = useController({ value, reference, setColumnFilter });

  return (
    <div className={b()} title={textFieldValue || 'Выберите значение'}>
      <TextInput value={textFieldValue} onChange={() => {}} onClick={openRelationTable} isDisabled />

      <div className={b('icons')}>
        <IconButton icons={buttonIcons.list} onClick={openRelationTable} title="Выбрать" />
        <IconButton icons={buttonIcons.delete} isDisabled={!selectedRefs.length} onClick={reset} title="Очистить" />
      </div>

      <RelationTableModal
        specification={specification}
        relationTableModalTitle={caption}
        isOpen={isRelationTableOpen}
        onClose={closeRelationTable}
      />
    </div>
  );
};
