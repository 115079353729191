import React, { useMemo } from 'react';
import { setup } from 'bem-cn';

import Select, { ActionMeta, InputActionMeta } from 'react-select';
import { buttonIcons, IconButtonProps, RequiredBadge, Toolbar, Tooltip } from '..';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('input-select');

export type Option<T = string> = {
  value: T;
  label: string;
};

export type Props<T> = {
  options: Option<T>[];
  value: Option<T> | Option<T>[] | null;
  onSelectChange(option: Option<T> | null, actionMeta: ActionMeta<Option<T>>): void;
  onInputChange?(newValue: string, actionMeta: InputActionMeta): void;
  onMenuOpen?(): void;
  onClick?(): void;
  label?: string;
  tooltipText?: string;
  compactLabel?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  isError?: boolean;
  isLoading?: boolean;
  isOutsideClearable?: boolean;
  isSearchable?: boolean;
  labelWidth?: number;
  isMaxMenuHeightDisabled?: boolean;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  classMixin?: string | string[];
};

export function InputSelect<T = string>(props: Props<T>) {
  const {
    compactLabel,
    label,
    isRequired,
    value,
    options,
    onSelectChange,
    onInputChange,
    onMenuOpen,
    onClick,
    onFocus,
    onKeyDown,
    tooltipText,
    isClearable,
    isError,
    disabled,
    placeholder,
    isLoading,
    isOutsideClearable,
    isSearchable,
    labelWidth,
    isMaxMenuHeightDisabled,
    classMixin,
  } = props;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.delete,
        title: 'Очистить',
        code: 'remove',
        isDisabled: disabled || !value,
        onClick: () => onSelectChange(null, { action: 'clear', removedValues: [] }),
      },
    ],
    [value, disabled, onSelectChange],
  );

  return (
    <div className={b({}).mix(classMixin)}>
      {Boolean(label) && (
        <span
          className={b('label', { 'compact-label': Boolean(compactLabel), tooltip: Boolean(tooltipText) })}
          style={labelWidth ? { width: labelWidth } : {}}
        >
          {label}
          {Boolean(isRequired) && (
            <div className={b('required-badge')}>
              <RequiredBadge />
            </div>
          )}
          {Boolean(tooltipText) && (
            <div className={b('tooltip')}>
              <Tooltip text={tooltipText!} />
            </div>
          )}
        </span>
      )}
      <div className={b('select', { error: isError })} onClick={onClick} onKeyDown={onKeyDown}>
        <Select
          options={options}
          isLoading={isLoading}
          value={value}
          onChange={onSelectChange}
          onInputChange={onInputChange}
          onFocus={onFocus}
          isClearable={!!isClearable}
          isDisabled={disabled}
          isSearchable={isSearchable}
          noOptionsMessage={() => 'Не найдено'}
          loadingMessage={() => 'Загрузка...'}
          menuPortalTarget={document.body}
          placeholder={placeholder ?? ''}
          onMenuOpen={onMenuOpen}
          maxMenuHeight={isMaxMenuHeightDisabled ? undefined : 340}
          classNamePrefix="select-field"
          menuPosition="fixed"
        />
      </div>
      {isOutsideClearable && (
        <div className={b('icons')}>
          <Toolbar buttons={buttons} />
        </div>
      )}
    </div>
  );
}
