import React, { memo, useMemo } from 'react';
import { Modal } from 'components';
import { Table } from 'types/models';
import { Component as PublicationTransformComponent } from './component';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  publicationId: string;
  relatedTableState: Table.State;
  onSuccess: () => void;
};

const PublicationTransformPopup = ({ isOpen, onClose, publicationId, relatedTableState, onSuccess }: Props) => {
  const title = useMemo(() => {
    return relatedTableState.selectedRows[0] &&
      relatedTableState.selectedRows[0]['id:PublicationType'] === PublicationTypes.MAGAZINE_ARTICLE.code
      ? 'Трансформация статьи в журнале в препринт'
      : 'Трансформация препринта в статью в журнале';
  }, [relatedTableState]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="medium">
      <PublicationTransformComponent
        relatedTableState={relatedTableState}
        onClose={onClose}
        publicationId={publicationId}
        onSuccess={onSuccess}
      />
    </Modal>
  );
};

export const Container = memo(PublicationTransformPopup);
