import React, { memo } from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import * as modalTemplates from './modalTemplates';

type WrappedProps = {
  projectId?: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ projectId }: WrappedProps) =>
  memo(({ tableState }: Props) => {
    const {
      isViewFormOpen,
      isViewButtonDisabled,
      handleTemplateCloseViewForm,
      buttons,
      isReportOpen,
      onReportClose,
      currentReport,
      isHelpFormOpen,
      setIsHelpFormOpen,
    } = useController(tableState);

    return (
      <>
        {!isViewButtonDisabled && (
          <modalTemplates.PublicationViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
          />
        )}
        {isReportOpen && (
          <BuildReportPopup
            isOpen={isReportOpen}
            onClose={onReportClose}
            reportName={currentReport?.name || ''}
            reportCaption={currentReport?.caption || ''}
            projectId={projectId}
          />
        )}
        <ConfirmPopup
          title="Список публикаций по теме проекта"
          isOpen={isHelpFormOpen}
          onClose={() => setIsHelpFormOpen(false)}
          size="medium"
        >
          <div className="modal-help">
            <p>
              Для отображения на этой вкладке списка публикаций по теме проекта необходимо:
              <ul>
                <li>
                  В списке публикаций (пункт меню <strong>Публикации</strong>, или в&nbsp;
                  <strong>личном кабинете вкладка Публикации)</strong> выделить публикацию, нажать кнопку&nbsp;
                  <strong>Финансовая поддержка публикации;</strong>
                </li>
                <li>
                  На форме, в разделе <strong>Проекты</strong> указать проект;
                </li>
                <li>Сохранить форму.</li>
              </ul>
            </p>
            <p>
              <strong className="primary">Важно!</strong> Запись с отметкой <strong>Принят к отчёту</strong> подлежат регистрации
              в федеральной системе учёта результатов по научным проекта ЕГИСУ НИОКТР, в соответствии с Федеральным законом №
              77-ФЗ.
            </p>
          </div>
        </ConfirmPopup>
        <Toolbar buttons={buttons} />
      </>
    );
  });
