import React, { useCallback } from 'react';
import * as R from 'ramda';

import { Reference, ReferenceItem } from 'components';

import { CitationSystem } from 'types/models/Form';
import { Field } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/types';

import './style.scss';

type Props = {
  field: Field;
  onChangeField(fieldId: string, value: ReferenceItem): void;
  citationSystems: CitationSystem[];
  citationSystem: ReferenceItem | null;
};

function Component(props: Props) {
  const { field, onChangeField, citationSystem, citationSystems } = props;

  if (field.metaType === null) {
    return null;
  }

  const onChange = useCallback(
    (ref: ReferenceItem) => {
      onChangeField(field.valueId, ref);
    },
    [field.valueId, onChangeField],
  );

  const foundCitationSystem = citationSystems.find(x => x.id === citationSystem?.id) ?? null;

  return (
    <Reference
      name={field.metaType}
      value={(field.value as ReferenceItem) || null}
      onChange={onChange}
      relationTableModalTitle={field.name}
      filters={[
        { key: 'citationSystem', values: citationSystem ? [citationSystem.id] : [] },
        {
          key: 'id',
          values:
            foundCitationSystem && foundCitationSystem?.thematicCategories.length
              ? foundCitationSystem.thematicCategories.map(x => x.id)
              : ['-1'],
        },
      ]}
    />
  );
}

export const ReferenceField = React.memo(Component, (prevProps, nextProps) =>
  R.equals(prevProps.field.value, nextProps.field.value),
);
