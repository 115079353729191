import { SaveProgramEventRequest } from './types';

export function convertProgramEventToXML(programEvent: SaveProgramEventRequest) {
  const { programEventId, programId, directionId, name, code, nationalProjectExpenseCode } = programEvent;
  return {
    ProgramEvent: {
      _attr: programEventId ? { id: programEventId } : {},
      Program: { _attr: { id: programId } },
      ...(directionId ? { Direction: { _attr: { id: directionId } } } : {}),
      Name: name,
      Code: code,
      ...(nationalProjectExpenseCode ? { NationalProjectExpenseCode: nationalProjectExpenseCode } : {}),
    },
  };
}
