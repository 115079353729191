import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, ConfirmPopup, InputSelect, Modal, FormComponent, Toolbar, buttonIcons, TextArea } from 'components';

import { Report, Table } from 'types/models';
import { SelectProjectPopup } from 'features/Form/views/SelectProjectPopup';
import { getEnum } from 'utils/Helpers';
import { ContestRequestType } from 'utils/Enums';
import { Item } from 'types/models/common';

import * as modalTemplates from './modalTemplates';
import { Permits } from 'utils/Permissions';
import { ContestRequestStatus } from 'utils/Enums/ContestRequestStatus';

import { showNotification } from 'features/Notifications';

import { useLocalTableStreams } from 'features/Table/hooks';
import { ContestRequestTab } from 'utils/Permissions/ContestRequestTab';
import { isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
import { getContestRequestStatusButtons } from '../helpers';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const requestStatus = selectedRow?.['id:Status'] as ContestRequestStatus;

  const typeOptions = getEnum('ContestRequestType') as Array<Item<ContestRequestType>>;

  const reports: Report[] = [
    Reports.ContestRequest,
    Reports.ContestRequestEstimate,
    Reports.ContestRequestPerformerCompositionMemo,
    Reports.ContestRequestTechnicalTask,
    Reports.ContestRequestProjectResults,
    Reports.ProjectIndicators,
    Reports.ContestRequestPerformerCompositionChangeMemo,
  ];

  const [projectId, setProjectId] = useState<string | null>(null);
  const [isSelectFormOpen, setIsSelectFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isTypeFormOpen, setIsTypeFormOpen] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<Item<ContestRequestType> | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const isEditPermitted = useMemo(() => {
    return isHasSomePermission(
      userPermission,
      (Object.keys(ContestRequestTab) as Array<ContestRequestTab>).map(
        tabKey => Permits[`CONTEST_REQUEST_EDIT_${requestStatus}_${tabKey}`],
      ),
    );
  }, [requestStatus, userPermission]);

  const onSubmitSelectProject = useCallback((id: string) => {
    setProjectId(id);
    setIsSelectFormOpen(false);
    setIsAddFormOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: selectedRow?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ContestRequest',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, selectedRow?.id, tableStreams.deleteRow]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const onSelectProjectFormClose = useCallback(() => {
    setIsSelectFormOpen(false);
  }, []);

  const confirmTypeSelect = useCallback(() => {
    if (requestType) {
      setIsTypeFormOpen(false);
      setIsSelectFormOpen(true);
    } else showNotification({ message: 'Укажите тип заявки', theme: 'danger' });
  }, [requestType]);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons: statusChangeButtons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = getContestRequestStatusButtons({
    rows: tableState.selectedRows.map(x => ({
      id: x.id,
      status: (x?.['id:Status'] as ContestRequestStatus) || null,
      type: x?.['id:Type'] as ContestRequestType,
    })),
  });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        isDisabled: false,
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: !selectedRow || tableState.selectedRows.length > 1,
        onClick: () => setIsViewFormOpen(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        onClick: () => setIsTypeFormOpen(true),
        permissionName: Permits.CONTEST_REQUEST_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: !selectedRow || !isEditPermitted || tableState.selectedRows.length > 1,
        onClick: () => setIsEditFormOpen(true),
        permissionName: (Object.keys(ContestRequestStatus) as Array<ContestRequestStatus>).flatMap(statusKey =>
          (Object.keys(ContestRequestTab) as Array<ContestRequestTab>).map(
            tabKey => Permits[`CONTEST_REQUEST_EDIT_${statusKey}_${tabKey}`],
          ),
        ),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        isDisabled: !selectedRow || requestStatus !== 'DRAFT',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permissionName: Permits.CONTEST_REQUEST_DELETE_DRAFT,
      },
      ...statusChangeButtons,
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        isDisabled: tableState.selectedRows.length > 1,
        code: 'reports',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      getReports,
      handleSetCurrentReport,
      isEditPermitted,
      requestStatus,
      selectedRow,
      statusChangeButtons,
      tableState.selectedRows.length,
    ],
  );

  return (
    <>
      {isAddFormOpen && requestType && (
        <modalTemplates.ContestRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          projectId={projectId || ''}
          relatedTableState={tableState}
          requestType={requestType.value}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.ContestRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}
      {isViewFormOpen && (
        <modalTemplates.ContestRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную заявку? (ID ${selectedRow?.id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isTypeFormOpen && (
        <ConfirmPopup
          title="Выберите тип заявки"
          isOpen={isTypeFormOpen}
          onClose={() => setIsTypeFormOpen(false)}
          onConfirm={confirmTypeSelect}
          okButtonText="Продолжить"
          onReset={() => setIsTypeFormOpen(false)}
          resetButtonText="Отмена"
        >
          <div>
            <div>
              <strong>Добавить заявку может только руководитель проекта или ответственный исполнитель</strong>
            </div>
            <br />
            <div>Укажите форму заявки:</div>
            <br />
            <div>Для участия в конкурсе на продление завершённого проекта - Заявка на продление проекта</div>
            <div>Для процедуры актуализации данных этапа текущего проекта - Заявка на актуализацию данных этапа</div>
            <br />
            <InputSelect value={requestType} options={typeOptions} onSelectChange={setRequestType} />
          </div>
        </ConfirmPopup>
      )}
      {isSelectFormOpen && (
        <SelectProjectPopup isOpen={isSelectFormOpen} onClose={onSelectProjectFormClose} onSubmit={onSubmitSelectProject} />
      )}
      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          contestRequestId={selectedRow?.id || ''}
          projectId={selectedRow?.ProjectId || ''}
        />
      )}
      <Modal
        title="Настройки Системы для проведения заявочной компании"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        <FormComponent.Wrapper>
          <div className="modal-help">
            <ol>
              <li>
                Роли для пользователей:
                <ul>
                  <li>
                    Конкурсная комиссия по заявкам на продление проекта и заявкам на актуализацию данных: просмотр заявок,
                    согласование и визирование
                  </li>
                  <li>
                    Админ Конкурсной комиссии по заявкам: имеет право просматривать все заявки в любом статусе и приложенные к ним
                    документы и управлять статусами заявок
                  </li>
                </ul>
              </li>
              <li>Объявления о конкурсе: на странице Новости &rarr; Объявления</li>
              <li>
                Подача заявки на продление завершённого проекта: Личный кабинет пользователя Системы &rarr; вкладка НИОКР &rarr;
                Актуализация моих проектов
              </li>
              <li>Добавить заявку на продление проекта имеет право только руководитель или ответственный исполнитель проекта</li>
              <li>
                Показатели и индикаторы результативности в заявке отображаются из списка актуальных показателей и индикаторов
                Программы, указанной в проекте. Добавленные в Программу индикаторы и показатели будут автоматически отображаться в
                заявках со статусом «Черновик», «Подана на конкурс» «На согласование». Порядок размещения показателей и
                индикаторов в Заявке зависит от значения Код (отсортированы от меньшего к большему)
              </li>
              <li>
                Название статьи затрат и порядок размещения на вкладке Смета зависит от справочника Виды расходов для заявок на
                продление проекта
              </li>
              <li>
                Статусы заявки на продление завершённого проекта: Черновик, Подана на конкурс, На доработку, Согласована с
                условием, Поддержана, Отклонена
              </li>
              <li>Статусы заявки на актуализацию данных этапа: Черновик, На согласование, Согласована</li>
            </ol>
          </div>
        </FormComponent.Wrapper>
      </Modal>
      {isStatusModalOpen && (
        <ConfirmPopup
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          onConfirm={changeStatus}
          okButtonText={statusLabel}
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </ConfirmPopup>
      )}
      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
