import { useState, useCallback, useMemo } from 'react';
import { MobileRequestStatus } from 'utils/Enums';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { IconButtonProps, buttonIcons } from 'components';
import { Permits } from 'utils/Permissions';

type StatusArgs = {
  rows: { id: string; status: MobileRequestStatus | null }[];
  callback?(): void;
};

export function getMobileRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<MobileRequestStatus>(MobileRequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeMobileRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddMobileRequestApprovement');

  const handleButtonClick = useCallback((newStatus: MobileRequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push();
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, mobileRequestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
          },
        },
      );
    },
    [addApprovementAPI, rows],
  );

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.book,
        title: 'В черновик',
        isDisabled: !rows.length || rows.some(x => !!x.status && [MobileRequestStatus.DRAFT].includes(x.status)),
        onClick: () => handleButtonClick(MobileRequestStatus.DRAFT, 'В черновик'),
        permissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_DRAFT,
        profilePermissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_DRAFT,
      },
      {
        icons: buttonIcons.medal,
        title: 'На согласование',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [MobileRequestStatus.DRAFT, MobileRequestStatus.REVISION].includes(x.status)),
        onClick: () => handleButtonClick(MobileRequestStatus.REQUEST, 'На согласование'),
        permissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_REQUEST,
        profilePermissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_REQUEST,
      },
      {
        icons: buttonIcons.like,
        title: 'Рекомендовать на конкурс',
        isDisabled: !rows.length || !rows.every(x => !!x.status && [MobileRequestStatus.REQUEST].includes(x.status)),
        onClick: () => handleButtonClick(MobileRequestStatus.RECOMENDED, 'Рекомендовать на конкурс'),
        permissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_RECOMENDED,
        profilePermissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_RECOMENDED,
      },
      {
        icons: buttonIcons.activation,
        title: 'Подать на конкурс',
        isDisabled: !rows.length || !rows.every(x => !!x.status && [MobileRequestStatus.RECOMENDED].includes(x.status)),
        onClick: () => handleButtonClick(MobileRequestStatus.SENT, 'Подать на конкурс'),
        permissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_SENT,
        profilePermissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_SENT,
      },
      {
        icons: buttonIcons.star,
        title: 'Поддержать',
        isDisabled: !rows.length || !rows.every(x => !!x.status && [MobileRequestStatus.SENT].includes(x.status)),
        onClick: () => handleButtonClick(MobileRequestStatus.APPROVED, 'Поддержать'),
        permissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_APPROVED,
        profilePermissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_APPROVED,
      },
      {
        icons: buttonIcons.update,
        title: 'На доработку',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [MobileRequestStatus.REQUEST, MobileRequestStatus.REJECTED].includes(x.status)),
        onClick: () => handleButtonClick(MobileRequestStatus.REVISION, 'На доработку'),
        permissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_REVISION,
        profilePermissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_REVISION,
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отклонить',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [MobileRequestStatus.REQUEST, MobileRequestStatus.SENT].includes(x.status)),
        onClick: () => handleButtonClick(MobileRequestStatus.REJECTED, 'Отклонить'),
        permissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_REJECTED,
        profilePermissionName: Permits.MOBILE_REQUEST_CHANGE_STATUS_REJECTED,
      },
      {
        icons: buttonIcons.approve,
        title: 'Экспертная оценка',
        isDisabled: rows.length !== 1 || (!!rows[0]?.status && rows[0].status === MobileRequestStatus.DRAFT),
        onClick: () => setIsApprovementModalOpen(true),
        permissionName: Permits.MOBILE_REQUEST_AGREE,
      },
    ],
    [rows, handleButtonClick],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}
