import React from 'react';

import { Tab, Tabs } from 'components';
import { ParticipationConferences } from './ParticipationConferences';
import { ParticipationContests } from './ParticipationContests';
import { ParticipationExpositions } from './ParticipationExpositions';
import { ParticipationConcerts } from './ParticipationConcerts';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';

export function ParticipationsEvents() {
  const { initialOrganizationCode, userPermission } = useAppDataContext();
  return (
    <Tabs>
      <Tab title="Участие в конференциях">
        <ParticipationConferences />
      </Tab>
      <Tab title="Участие в олимпиадах и конкурсах">
        <ParticipationContests />
      </Tab>
      <Tab title="Участие в выставках">
        <ParticipationExpositions />
      </Tab>
      {initialOrganizationCode?.code === 'TSU' && (
        <Tab
          title="Участие в творческих мероприятиях"
          isVisible={isHasPermission(userPermission, Permits.PARTICIPATION_CONCERT_LIST_VIEW)}
        >
          <ParticipationConcerts />
        </Tab>
      )}
    </Tabs>
  );
}
