import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { ProjectType } from 'utils/Enums';
import { makeParametersXMLElement, makeTableRequestXMLConverter } from '../commonConverters';

import * as T from './types';

export const tableGovermentContractsConfigurations = {
  GetGovermentContractsList: makeBackendTableAPIConfiguration(
    `GetProjectList?type=${ProjectType.GOVERMENT_CONTRACT}`,
    makeTableRequestXMLConverter('GetProjectList', ({ panelState, isTableExtended }: T.GetGovermentContractsListRequest) =>
      makeParametersXMLElement(
        [
          { name: 'type', value: ProjectType.GOVERMENT_CONTRACT },
          { name: 'extended', value: (!!isTableExtended).toString() },
          { name: 'withoutStateRegistration', value: (!!panelState.isWithoutStateRegistration).toString() },
        ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ),
    ),
  ),
};

export const govermentContractsConfigurations = {
  ...tableGovermentContractsConfigurations,
};
