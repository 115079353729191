import { useCallback, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { EventTypeCode } from 'types/models/Event';
import { DetailProps } from '.';

export type State = {
  loadEvent(eventId: string): void;
  event: Event | null;
  eventType: EventTypeCode | undefined;
};

export function makeUseCustomController({ eventType }: DetailProps) {
  return function useCustomController(): State {
    const [event, setEvent] = useState<Event | null>(null);
    const { methods: loadEventAPI } = BackendAPI.useBackendAPI('GetEventById');

    const loadEvent = useCallback((eventId: string) => {
      loadEventAPI.callAPI(
        { eventId },
        {
          onSuccessfullCall: ({ data }: any) => {
            setEvent(data);
          },
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
      loadEvent,
      event,
      eventType,
    };
  };
}
