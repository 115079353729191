import { useState, useEffect, useCallback, useMemo } from 'react';

import { useStream } from 'StreamRx';
import * as BackendAPI from 'services/BackendAPI';
import { Form as F, Table as T } from 'types/models';
import { showNotification } from 'features/Notifications';
import { ReferenceItem, IconButtonProps, buttonIcons } from 'components';

import { TemplatesTableDependencies } from 'types/models/Table';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams as panelStreams } from './streams';

export type State = {
  isChangeFormOpen: boolean;
  selectedRow: Record<string, string> | undefined;
  toolbarButtons: IconButtonProps[];
  buttons: IconButtonProps[];
  formFields: F.Fields;
};

export function makeUseCustomController(deps: TemplatesTableDependencies, scientistId: string) {
  return function useCustomController({ selectedRows }: T.UseCustomControllerProps): State {
    const tableStreams = useLocalTableStreams();
    const selectedRow = useMemo(() => selectedRows[0], [selectedRows]);
    const [isChangeFormOpen, setIsChangeFormOpen] = useState(false);
    const [isSettingEditing, setIsSettingEditing] = useState(false);
    const [settingHirshIndexID, setSettingHirshIndexID] = useState('');
    const [formFields, setFormFields] = useState<F.Fields>({
      date: {
        value: '',
        isValid: true,
        required: true,
        title: 'Дата рассчета',
        onChange: (nextDate: string) => {
          setFormFields((prevState: F.Fields) => ({
            ...prevState,
            date: { ...prevState.date, value: nextDate || '' },
          }));
        },
      },
      citationSystem: {
        value: { label: '', id: '' },
        isValid: true,
        required: true,
        title: 'Система цитирования',
        onChange: (value: ReferenceItem) => {
          setFormFields((prevState: F.Fields) => ({
            ...prevState,
            citationSystem: { ...prevState.citationSystem, value },
          }));
        },
      },
      identifier: {
        isValid: true,
        value: '',
        title: 'Идентификатор',
        onChange: (value: string) => {
          setFormFields((prevState: F.Fields) => ({
            ...prevState,
            identifier: { ...prevState.identifier, value },
          }));
        },
      },
      hirshIndexValue: {
        isValid: true,
        value: '',
        title: 'Значение',
        onChange: (value: string) => {
          setFormFields((prevState: F.Fields) => ({
            ...prevState,
            hirshIndexValue: { ...prevState.hirshIndexValue, value },
          }));
        },
        onFocus: () => {
          setFormFields((prevState: F.Fields) => ({
            ...prevState,
            hirshIndexValue: { ...prevState.hirshIndexValue, isValid: true },
          }));
        },
      },
      note: {
        isValid: true,
        value: '',
        title: 'Примечание',
        onChange: (value: string) => {
          setFormFields((prevState: F.Fields) => ({
            ...prevState,
            note: { ...prevState.note, value },
          }));
        },
      },
    });

    useEffect(() => {
      if (isSettingEditing && selectedRow) {
        setSettingHirshIndexID(selectedRow.id);
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          citationSystem: {
            ...prevState.citationSystem,
            value: {
              id: selectedRow['id:citationSystem'] || '',
              label: selectedRow.citationSystem || '',
            },
          },
          date: {
            ...prevState.date,
            value: selectedRow.date,
          },
          hirshIndexValue: {
            ...prevState.hirshIndexValue,
            value: selectedRow.value,
          },
          identifier: {
            ...prevState.identifier,
            value: selectedRow.identifier,
          },
          note: {
            ...prevState.note,
            value: selectedRow.note,
          },
        }));
      }
    }, [isSettingEditing, selectedRow]);

    const { methods: addNewScientistHirshIndexAPIMethods } = BackendAPI.useBackendAPI('SaveScientistHirshIndex', {
      onSuccessfullCall: () => {
        showNotification({ message: 'Запись успешно сохранена', theme: 'success' });
        tableStreams.reloadTable.push();
      },
    });

    const { methods: deleteScientistHirshIndexAPIMethods } = BackendAPI.useBackendAPI('DeleteScientistHirshIndex', {
      onSuccessfullCall: () => {
        showNotification({ message: 'Запись успешно удалена', theme: 'success' });
        tableStreams.reloadTable.push();
      },
    });

    const resetComponentState = useCallback(() => {
      panelStreams.toggleSettingEditing.push(false);
      setSettingHirshIndexID('');
      setFormFields((prevState: F.Fields) => ({
        ...prevState,
        citationSystem: {
          ...prevState.citationSystem,
          value: {
            id: '',
            label: '',
          },
        },
        date: {
          ...prevState.date,
          value: '',
        },
        hirshIndexValue: {
          ...prevState.hirshIndexValue,
          value: '',
        },
        identifier: {
          ...prevState.identifier,
          value: '',
        },
        note: {
          ...prevState.note,
          value: '',
        },
      }));
    }, []);

    const validate = useCallback((fields: F.Fields) => {
      let result = true;
      if (!fields.citationSystem.value?.label || !fields.date.value) {
        showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
        result = false;
      }
      if (!!fields.hirshIndexValue.value && !Number(fields.hirshIndexValue.value)) {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          hirshIndexValue: {
            ...prevState.hirshIndexValue,
            isValid: false,
          },
        }));
        showNotification({ message: 'Поле "Значение" имеет числовой формат', theme: 'danger' });
        result = false;
      }
      return result;
    }, []);

    const saveForm = useCallback(
      (reset: boolean = false) => {
        if (validate(formFields)) {
          panelStreams.addNewScientistHirshIndex.push({
            hirshIndexID: isSettingEditing ? settingHirshIndexID : undefined,
            scientistID: scientistId,
            citationSystemID: formFields.citationSystem.value?.id,
            date: formFields.date.value,
            value: formFields.hirshIndexValue.value,
            identifier: formFields.identifier.value,
            note: formFields.note.value,
          });
          if (reset) {
            panelStreams.toggleChangeForm.push();
            resetComponentState();
          }
        }
      },
      [formFields, isSettingEditing, resetComponentState, settingHirshIndexID, validate],
    );

    const handleSaveButtonClick = useCallback(() => {
      saveForm(true);
    }, [saveForm]);

    const handleSaveAndContinueButtonClick = useCallback(() => {
      saveForm();
    }, [saveForm]);

    const handleCancelButtonClick = useCallback(() => {
      panelStreams.toggleChangeForm.push();
      resetComponentState();
    }, [resetComponentState]);

    const buttons = useMemo<IconButtonProps[]>(
      () => [
        {
          icons: buttonIcons.save,
          title: 'Сохранить',
          code: 'save',
          onClick: handleSaveButtonClick,
        },
        {
          icons: buttonIcons.saveAndContinue,
          title: 'Сохранить и добавить',
          code: 'save',
          onClick: handleSaveAndContinueButtonClick,
        },
        {
          icons: buttonIcons.cancel,
          title: 'Отмена',
          code: 'cancel',
          onClick: handleCancelButtonClick,
        },
      ],
      [handleCancelButtonClick, handleSaveAndContinueButtonClick, handleSaveButtonClick],
    );

    const handleToolbarSaveButtonClick = useCallback(() => {
      panelStreams.toggleChangeForm.push();
    }, []);

    const handleToolbarEditButtonClick = useCallback(() => {
      panelStreams.toggleSettingEditing.push(true);
      panelStreams.toggleChangeForm.push();
    }, []);

    const handleToolbarDeleteButtonClick = useCallback(() => {
      if (selectedRow) {
        panelStreams.deleteScientistHirshIndex.push({ hirshIndexID: selectedRow.id });
      }
    }, [selectedRow]);

    const isToolbarButtonDisabled = useMemo<boolean>(() => selectedRow === undefined || isChangeFormOpen, [
      isChangeFormOpen,
      selectedRow,
    ]);

    const toolbarButtons = useMemo<IconButtonProps[]>(
      () => [
        {
          icons: buttonIcons.plus,
          title: 'Добавить',
          code: 'add',
          isDisabled: isChangeFormOpen,
          onClick: handleToolbarSaveButtonClick,
          permissionName: Permits.HIRSH_INDEX_EDIT,
        },
        {
          icons: buttonIcons.edit,
          title: 'Редактировать',
          code: 'edit',
          isDisabled: isToolbarButtonDisabled,
          onClick: handleToolbarEditButtonClick,
          permissionName: Permits.HIRSH_INDEX_EDIT,
        },
        {
          icons: buttonIcons.delete,
          title: 'Удалить',
          code: 'remove',
          isDisabled: isToolbarButtonDisabled,
          onClick: handleToolbarDeleteButtonClick,
          permissionName: Permits.HIRSH_INDEX_EDIT,
        },
      ],
      [
        handleToolbarDeleteButtonClick,
        handleToolbarEditButtonClick,
        handleToolbarSaveButtonClick,
        isToolbarButtonDisabled,
        isChangeFormOpen,
      ],
    );

    useStream(
      () => panelStreams.addNewScientistHirshIndex,
      ({ hirshIndexID, scientistID, date, citationSystemID, value, identifier, note }) => {
        addNewScientistHirshIndexAPIMethods.callAPI({
          hirshIndexID,
          scientistID,
          date,
          citationSystemID,
          value,
          identifier,
          note,
        });
      },
      [deps.relatedTableAPIID],
    );

    useStream(
      () => panelStreams.deleteScientistHirshIndex,
      ({ hirshIndexID }) => {
        deleteScientistHirshIndexAPIMethods.callAPI({
          hirshIndexID,
        });
      },
      [],
    );

    useStream(
      () => panelStreams.toggleChangeForm,
      () => {
        setIsChangeFormOpen(prev => !prev);
        tableStreams.toggleSecondLevelPanel.push();
      },
      [setIsChangeFormOpen],
    );

    useStream(
      () => panelStreams.toggleSettingEditing,
      isEditing => setIsSettingEditing(isEditing),
      [setIsSettingEditing],
    );

    return {
      isChangeFormOpen,
      selectedRow,
      toolbarButtons,
      buttons,
      formFields,
    };
  };
}
