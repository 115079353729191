import { Table, Button } from 'types/models';
import { TemplatesTableDependencies } from 'types/models/Table';
import { GetShortEventListRequest } from 'services/BackendAPI/configurations/types';
import { LeftPanelForThirdLevelWrapper } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Filters } from './filters';
import { eventNameMap } from 'features/SuchLike/SuchLikeEvent/helpres';

type Deps = {
  templatesTableDependencies?: TemplatesTableDependencies;
  requestData: GetShortEventListRequest;
  buttonsToHide?: Button.Button[];
  hasSelectButton?: boolean;
  isDisableSource?: boolean;
  isMagazineReleasesPlanned?: boolean;
  isCompilationsPlanned?: boolean;
  hasFilter?: boolean;
};

export function GetShortEventList(deps: Deps): Table.Specification<State> {
  return {
    apiID: 'GetShortEventList',
    templatesTableDependencies: deps.templatesTableDependencies,
    requestData: deps.requestData,

    useCustomController: makeUseCustomController({
      isDisableSource: deps.isDisableSource,
      isMagazineReleasesPlanned: deps.isMagazineReleasesPlanned,
      isCompilationsPlanned: deps.isCompilationsPlanned,
      eventType: deps.requestData.eventType,
    }),
    header: {
      firstLevel: {
        ...(deps?.hasFilter
          ? {
              title: eventNameMap[deps.requestData.eventType].labelPlural,
              Panel: Filters,
            }
          : {}),
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevelWrapper({ buttonsToHide: deps.buttonsToHide, eventType: deps.requestData.eventType }),
        hasSelectButton: deps.hasSelectButton,
      },
    },
  };
}
