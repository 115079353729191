import React, { useCallback } from 'react';

import { CustomSelect, FormComponent, Reference, SectionTitle, TextArea, TextInput } from 'components';

import { Table as T, Form as F, Author, Award } from 'types/models';
import { ParticipationEventSource } from 'types/models/Participation';
import { AwardsComponent } from 'features/Form/views';
import { GetShortEventList } from 'features/Table/specifications';
import { EventFormat } from 'utils/Enums';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { MembersList } from 'features/Form/looks/participation/views/MembersList';

type Props = {
  formFields: F.Fields;
  workMode: T.WorkMode;
  event: ParticipationEventSource | null;
  setEvent: (event: ParticipationEventSource | null) => void;
  relatedTableState: T.State | undefined;
  members: Author[];
  disabledEvent?: boolean;
  changeMembers: (val: Author[]) => void;
  awards: Award[];
  setAwards: (awards: Award[]) => void;
};

function About({
  formFields,
  workMode,
  event,
  setEvent,
  relatedTableState,
  // reporterId,
  members,
  disabledEvent,
  changeMembers,
  awards,
  setAwards,
}: Props) {
  const modalTableRowToEventConventer = useCallback<(row: T.Entry) => ParticipationEventSource>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      fullName: row.fullName,
      startDate: row.startDate,
      endDate: row.endDate,
      entity: null,
      eventStatus: null,
      format: EventFormat.OFFLINE,
    }),
    [],
  );

  const shortEventTableConfig = GetShortEventList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
    },
    requestData: {
      eventType: 'CONTEST',
      allEvents: true,
    },
    hasSelectButton: true,
    hasFilter: true,
    buttonsToHide: ['approve'],
  });
  return (
    <form>
      <input type="submit" hidden />
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Название конкурса" isRequired>
              <CustomSelect
                disabled={workMode === 'viewMode' || disabledEvent}
                isRequired
                isStatusText
                original={event}
                relationTableModalTitle="Конкурсы"
                onDeleteFunction={() => setEvent(null)}
                onChange={setEvent}
                modalTableRowConverter={modalTableRowToEventConventer}
                modalTableSpecification={shortEventTableConfig}
                isShowFullName
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Уровень мероприятия">
              <div>{event?.eventStatus?.label}</div>
            </FormComponent.Field>
            <FormComponent.Field label="Формат проведения">
              <div>{event?.format?.label}</div>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Формат участия" isRequired={formFields.form.required}>
              <Reference
                disabled={workMode === 'viewMode'}
                name="RefParticipationForm"
                value={formFields.form.value ?? null}
                onChange={formFields.form.onChange}
                filters={[{ key: 'type', values: ['CONTEST'] }]}
                relationTableModalTitle='Справочник "Форматы участия"'
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={formFields.teamName.title}>
              <TextInput
                value={formFields.teamName.value}
                onChange={formFields.teamName.onChange}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <MembersList eventType="CONTEST" disabled={workMode === 'viewMode'} authors={members} setAuthors={changeMembers} />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <SectionTitle title="Название конкурсного проекта (работы)" />
          <FormComponent.Line>
            <FormComponent.Field>
              <TextArea
                settings={{ rows: 3 }}
                value={formFields.note.value}
                onChange={formFields.note.onChange}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <AwardsComponent
              disabled={workMode === 'viewMode'}
              recipients={members}
              awards={awards}
              setAwards={setAwards}
              participationTypeCode={ParticipationTypes.PARTICIPATION_CONTEST.code}
              tooltip="После проведения мероприятия добавить награды, полученные участником в конкурсе."
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </form>
  );
}

export const Component = React.memo(About);
