import React, { useMemo } from 'react';

import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons } from 'components';

import { sendMailToAdmin } from 'shared/sendMailToAdmin';

function Toolbar() {
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.personEmail,
        title: 'Написать администратору',
        code: 'personEmail',
        isDisabled: false,
        onClick: sendMailToAdmin,
      },
    ],
    [],
  );

  return <SharedToolbar buttons={buttons} />;
}

export { Toolbar };
