import React from 'react';

import { Toolbar as SharedToolbar } from 'components';
import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from '../modalTemplates';

type Props = {
  tableState: Table.State;
};

function Toolbar({ tableState }: Props) {
  const { buttons, isViewFormOpen, handleTemplateCloseViewForm } = useController({ tableState });
  return (
    <>
      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
