import { Table as T } from 'types/models';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Toolbar } from './Toolbar/Toolbar';

export type DetailProps = {
  isViewMode?: boolean;
};
export function GetPersonalColleagueList({ isViewMode }: DetailProps): T.Specification<State> {
  return {
    apiID: 'GetPersonalColleagueList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Результаты совместной научной деятельности',
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController({ isViewMode }),
  };
}
