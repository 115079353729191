import { ContestRequest, Result, Performer } from 'types/models/ContestRequest';
import {
  convertDocumentToRequest,
  convertReferenceItemToXML,
  getMaybeField,
} from 'services/BackendAPI/configurations/commonConverters';
import { EstimatePosition } from 'types/models/NirRequest';
import { getHistoricalSliceXML } from '../author/requestConverters';

export function convertContestRequestToXML(rc: ContestRequest) {
  return {
    _attr: rc.id ? { id: rc.id } : {},
    Project: { _attr: rc.project?.id ? { id: rc.project?.id } : {} },
    RequestStageUniversityEffect: rc.requestStageUniversityEffect,
    RequestStageRegionalEffect: rc.requestStageRegionalEffect,
    RequestStageNationalEffect: rc.requestStageNationalEffect,
    RequestStageAvailableScientificGroundwork: rc.requestStageAvailableScientificGroundwork,
    RequestStageAdditionalInformation: rc.requestStageAdditionalInformation,
    RequestStageNumber: rc.requestStageNumber || 1,
    RequestStageName: rc.requestStageName,
    RequestStageStartDate: rc.requestStageStartDate,
    RequestStageEndDate: rc.requestStageEndDate,
    RequestStagePlanAmount: rc.requestStagePlanAmount || 0,
    ...getMaybeField('Type', rc.type?.value),
    ...(rc?.requestStageKindWork?.id
      ? { RequestStageKindWork: { _attr: rc?.requestStageKindWork?.id ? { id: rc.requestStageKindWork.id } : {} } }
      : {}),
    ...(rc.requestStageGoal ? { RequestStageGoal: rc.requestStageGoal } : {}),
    ...(rc.requestStageAnnotation ? { RequestStageAnnotation: rc.requestStageAnnotation } : {}),
    ...(rc.requestStagePreviousYearAnnotation
      ? { RequestStagePreviousYearAnnotation: rc.requestStagePreviousYearAnnotation }
      : {}),
    ...(rc.requestStageExpectedResults ? { RequestStageExpectedResults: rc.requestStageExpectedResults } : {}),
    ...(rc.interdisciplinaryAproachJustification
      ? { InterdisciplinaryAproachJustification: rc.interdisciplinaryAproachJustification }
      : {}),
    ...(rc.requestStageWorkPlan ? { RequestStageWorkPlan: rc.requestStageWorkPlan } : {}),
    ...(rc.requestStageExpectedResultsWorth ? { RequestStageExpectedResultsWorth: rc.requestStageExpectedResultsWorth } : {}),
    ...(rc.requestStageExpectedResultsSuppose
      ? { RequestStageExpectedResultsSuppose: rc.requestStageExpectedResultsSuppose }
      : {}),
    ...(rc.requestStageExpectedResultsEducationalSuppose
      ? { RequestStageExpectedResultsEducationalSuppose: rc.requestStageExpectedResultsEducationalSuppose }
      : {}),
    IsRequestStageHasPatentResearch: rc.isRequestStageHasPatentResearch || false,
    ...(rc.requestStageCollaborations ? { RequestStageCollaborations: rc.requestStageCollaborations } : {}),
    ...(rc.requestStageRAN ? { RequestStageRAN: rc.requestStageRAN } : {}),
    ...(rc.requestStagePartnerEnterprises ? { RequestStagePartnerEnterprises: rc.requestStagePartnerEnterprises } : {}),
    RequestStageKeyWords: rc.requestStageKeyWords,
    RequestStagePerformers:
      rc.requestStagePerformers && rc.requestStagePerformers.length
        ? { RequestStagePerformer: rc.requestStagePerformers.map(convertPerformerToXml) }
        : {},
    RequestStageIndicators:
      rc.requestStageIndicators && rc.requestStageIndicators.length
        ? { Result: rc.requestStageIndicators.map(convertIndicatorToXml) }
        : {},
    RequestStageIndices:
      rc.requestStageIndices && rc.requestStageIndices.length
        ? { Result: rc.requestStageIndices.map(convertIndicatorToXml) }
        : {},
    RequestStageEstimatePositions:
      rc.requestStageEstimatePositions && rc.requestStageEstimatePositions.length
        ? { Position: rc.requestStageEstimatePositions.map(convertPositionToXml) }
        : {},
    IsPrepareSave: false,
    Documents:
      rc.documents && rc.documents.length
        ? { Document: rc.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)) }
        : {},
  };
}

function convertPerformerToXml(performer: Performer) {
  return {
    _attr: { id: performer.id },
    ...(performer.person ? { Person: { _attr: { id: performer.person.id } } } : {}),
    Role: performer.role?.value,
    ...getHistoricalSliceXML(performer),
  };
}

export function convertPositionToXml(pos: EstimatePosition) {
  return {
    _attr: pos.id ? { id: pos.id } : {},
    KindConsumption: { _attr: { id: pos.kindConsumption.id } },
    Amount: pos.amount || 0,
    ...(pos?.note ? { Note: pos.note } : {}),
  };
}

export function convertIndicatorToXml(ind: Result) {
  return {
    _attr: ind.id ? { id: ind.id } : {},
    ResultItem: convertReferenceItemToXML(ind.resultItem),
    Year: ind.year,
    ...(ind?.note ? { Note: ind.note } : {}),
    Plan: ind.plan || 0,
  };
}
