import React, { useMemo, useCallback } from 'react';

import { Form as F, Table } from 'types/models';

import { NirRequestLocalType } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel';
import { Modal } from 'components';
import { SelectTenderTypeModal } from 'features/Form/views/SelectTenderTypeModal/SelectTenderTypeModal';
import { DataGrid } from 'features/Table';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { NirRequestTenderType, TenderRequestType } from 'utils/Enums';
// eslint-disable-next-line max-len
import { Component as ProgramRequestAddTemplate } from 'features/Table/specifications/GetRequestList/LeftPanelForThirdLevel/modalTemplates/RequestAddTemplate';
// eslint-disable-next-line max-len
import { Component as MobileRequestAddTemplate } from 'features/Table/specifications/GetMobileRequestList/LeftPanelForThirdLevel/modalTemplates/MobileRequestAddTemplate';
import { TenderInfo } from 'types/models/Form/look/programRequest';
// eslint-disable-next-line max-len
import { Component as NirRequestAddTemplate } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel/modalTemplates/NirRequestAddTemplate';

type DetailProps = {
  tenderRequestType: TenderRequestType | null;
  setTenderRequestType: React.Dispatch<TenderRequestType | null>;
  tenderInfo: TenderInfo | null;
  setTenderInfo: React.Dispatch<TenderInfo | null>;
  nirRequestTenderType: NirRequestTenderType | null;
  handleSubmitTenderTypeModal(e: NirRequestTenderType): void;
};

const RequestAddTemplate: React.FC<F.TemplateProps & DetailProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  tenderRequestType,
  setTenderRequestType,
  tenderInfo,
  setTenderInfo,
  nirRequestTenderType,
  handleSubmitTenderTypeModal,
}: F.TemplateProps & DetailProps) => {
  const nirRequest = useMemo<NirRequestLocalType>(
    () => ({
      id: null,
      specification: 'BASIC_RESEARCH',
      tenderType: nirRequestTenderType ?? null,
      ...tenderInfo,
    }),
    [nirRequestTenderType, tenderInfo],
  );

  const selectRow = useCallback(
    (row: Table.Entry) => {
      setTenderRequestType((row['id:RequestType'] as TenderRequestType) || null);
      setTenderInfo({
        tenderId: row.id,
        lotId: row.lotId,
      });
    },
    [setTenderInfo, setTenderRequestType],
  );

  return (
    <>
      {isOpen && (
        <>
          {tenderRequestType ? (
            <>
              {tenderRequestType === TenderRequestType.NIR_REQUEST && (
                <>
                  {nirRequestTenderType ? (
                    <NirRequestAddTemplate
                      isOpen
                      onClose={onClose}
                      relatedTableState={relatedTableState}
                      nirRequest={nirRequest}
                    />
                  ) : (
                    <SelectTenderTypeModal isOpen onClose={onClose} onSubmit={handleSubmitTenderTypeModal} />
                  )}
                </>
              )}
              {tenderRequestType === TenderRequestType.PROGRAM_REQUEST && (
                <ProgramRequestAddTemplate
                  isOpen
                  onClose={onClose}
                  tenderInfo={tenderInfo}
                  relatedTableState={relatedTableState}
                />
              )}
              {tenderRequestType === TenderRequestType.MOBILE_REQUEST && (
                <MobileRequestAddTemplate
                  isOpen
                  onClose={onClose}
                  tenderInfo={tenderInfo}
                  relatedTableState={relatedTableState}
                  type="NPR"
                />
              )}
            </>
          ) : (
            <Modal
              isOpen
              onClose={onClose}
              title="Список открытых конкурсов на текущую дату"
              size={['extralarge', 'large-height']}
            >
              <DataGrid specification={GetTenderListForRequest({ isPc: true, selectRow })} />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export const Component = React.memo(RequestAddTemplate);
