import React from 'react';

import { Reference, FormComponent } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
};

function RecognitionForm({ onClose }: Props) {
  const { formFields, recognitionInfo, saveFormSubmit } = useController({ onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveFormSubmit} />

      {!!recognitionInfo && (
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Награжден:">
              <span>
                {recognitionInfo.person?.shortName}
                &nbsp;
                {recognitionInfo.historyLabel}
              </span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Награда:">
              <span>{recognitionInfo.award.label}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.department.title} isRequired={formFields.department.required}>
              <Reference
                value={formFields.department.value}
                relationTableModalTitle='Справочник "Подразделения"'
                refButtonTitle='Справочник "Подразделения"'
                name="RefDepartment"
                onChange={formFields.department.onChange}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      )}
    </FormComponent.Template>
  );
}

export const Component = React.memo(RecognitionForm);
