import * as XML from 'xml-js';
import * as T from './types';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { Program } from 'types/models';
import { makeParametersXMLElement, makeTableRequestXMLConverter } from '../commonConverters';
import { convertProgram, convertSaveProgramResponse } from './converters';
import { convertProgramToXML } from './requestConverters';

export const tableProgramsConfigurations = {
  GetProgramList: makeBackendTableAPIConfiguration(
    'GetProgramList',
    makeTableRequestXMLConverter('GetProgramList', ({ panelState }: T.GetProgramListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetResultElementByProgramList: makeBackendTableAPIConfiguration(
    'GetResultElementByProgramList',
    makeTableRequestXMLConverter('GetResultElementByProgramList', ({ requestData }: T.GetResultElementByProgramListRequest) =>
      makeParametersXMLElement([
        { name: 'programId', value: requestData?.programId },
        { name: 'resultType', value: requestData?.type },
      ]),
    ),
  ),
};

const configurations = {
  GetProgram: makeBackendAPIConfiguration({
    id: 'GetProgram',
    endpoint: '/msa/service/commands/GetProgram',
    convertInputToXMLElement: ({ id }: T.GetProgramRequest) => ({
      Request: {
        _attr: { commandName: 'GetProgram' },
        Program: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertProgram(response.Response.Program),
  }),
  SaveProgram: makeBackendAPIConfiguration({
    id: 'SaveProgram',
    endpoint: '/msa/service/commands/SaveProgram',
    convertInputToXMLElement: (inputData: Program.Program) => {
      return {
        Request: {
          _attr: {
            commandName: 'SaveProgram',
          },
          Program: { ...convertProgramToXML(inputData) },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertSaveProgramResponse(response.Response),
  }),
  DeleteProgram: makeBackendAPIConfiguration({
    id: 'DeleteProgram',
    endpoint: '/msa/service/commands/DeleteProgram',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'DeleteProgram',
          },
          Program: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const programsConfigurations = { ...tableProgramsConfigurations, ...configurations };
