import React, { Dispatch, memo, SetStateAction } from 'react';
import { Form } from 'types/models';

import { ListEdit, FormComponent } from 'components';

import { useController } from './controller';
import { Member } from 'types/models/Community';

type Props = {
  publicationInfo: Form.Publication | null;
  setPublicationInfo: Dispatch<SetStateAction<Form.Publication | null>> | null;
};

const EditorialMembers = ({ publicationInfo, setPublicationInfo }: Props) => {
  const { formatPersonWithHistory, handleMembersFieldChange } = useController({
    publicationInfo,
    setPublicationInfo,
  });
  return (
    <FormComponent.Wrapper>
      {!publicationInfo?.communityMembers?.length && (
        <FormComponent.Description mode="warning">
          Добавление сведений о редакции журнала и его членах - в пункте меню Признания -&gt; Научные сообщества и организации
        </FormComponent.Description>
      )}

      <ListEdit
        withMessages
        defaultRowsCount={9}
        maxHeight="35vh"
        isDeleteConfirmEnabled
        hideToolbar={true}
        rows={publicationInfo?.communityMembers || []}
        onChange={handleMembersFieldChange}
        columns={[
          { label: 'Персона', formatValue: (m: Member) => formatPersonWithHistory(m), styles: { width: '25%' } },
          { label: 'Роль', formatValue: (m: Member) => m.role?.label || '', styles: { width: '15%' } },
          { label: 'Дата ввода в состав', formatValue: (m: Member) => m.joinDate || '', styles: { width: '15%' } },
          { label: 'Дата вывода из состава', formatValue: (m: Member) => m.leaveDate || '', styles: { width: '15%' } },
          { label: 'Примечание', formatValue: (m: Member) => m.description, styles: { width: '15%' } },
          {
            label: 'Контакты',
            formatValue: (m: Member) =>
              [m?.scientist?.scientist?.phone, m?.scientist?.scientist?.mobilePhone, m?.scientist?.scientist?.mobilePhone]
                .filter(f => !!f)
                .join(', '),
            styles: { width: '15%' },
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: () => <></>,
          validation: {
            checkIsValid: () => true,
          },
        }}
      />
    </FormComponent.Wrapper>
  );
};

export const EditorialMembersComponent = memo(EditorialMembers);
