import React from 'react';

import { Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetFullUserList, GetRoleList, GetUserSessions, GetLockedEntityList } from 'features/Table/specifications';
import { Component as UserActivation } from './Tabs/UserActivation';
import { useAppDataContext } from 'features/AppData/context';

export function Administration() {
  const { initialOrganizationCode } = useAppDataContext();
  return (
    <Tabs id="administration" isStrip>
      <Tab title="Управление пользователями">
        <DataGrid specification={GetFullUserList()} />
      </Tab>
      <Tab title="Роли и права">
        <DataGrid specification={GetRoleList({})} />
      </Tab>
      <Tab title="Сессии пользователей">
        <DataGrid specification={GetUserSessions()} />
      </Tab>
      <Tab title="Заблокированные записи">
        <DataGrid specification={GetLockedEntityList()} />
      </Tab>
      {initialOrganizationCode?.code !== 'TSU' && (
        <Tab title="Активация пользователей">
          <UserActivation />
        </Tab>
      )}
    </Tabs>
  );
}
