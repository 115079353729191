import React from 'react';
import block from 'bem-cn';

import { buttonIcons, FormComponent, IconButton } from 'components';

import { UsefulLink } from 'types/models/UsefulLink';

import '../style.scss';

const b = block('useful');

type Props = {
  props: UsefulLink;
};

function Block({ props }: Props) {
  const { createdDate, caption, url } = props;

  return (
    <FormComponent.Link href={url} classMixin={b('block').toString()}>
      <div className={b('block-icon')}>
        <IconButton icons={buttonIcons.downloadShareLink} />
      </div>
      <div className={b('block-data')}>
        <em>({createdDate})</em> {caption}
      </div>
    </FormComponent.Link>
  );
}

export const Component = React.memo(Block);
