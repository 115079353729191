import { Arguments } from '.';

export type State = {
  hasToolbarButtonMode: boolean;
  hasSelectButton: boolean;
  defaultYearChecked: boolean;
};

export function makeUseCustomController({
  hasToolbarButtonMode = false,
  hasSelectButton = false,
  defaultYearChecked = true,
}: Arguments) {
  return function useCustomController(): State {
    return {
      hasToolbarButtonMode,
      hasSelectButton,
      defaultYearChecked,
    };
  };
}
