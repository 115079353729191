import { useAppDataContext } from 'features/AppData/context';
import { isHasSomePermission } from 'features/AppData';
import { usePrivatePageContext } from 'App/PrivatePage/context';

export type Props = {
  children: JSX.Element;
  name?: string | string[];
  profileName?: string | string[];
};

function Permission({ children, name, profileName }: Props) {
  const { userPermission } = useAppDataContext();
  const { isProfile } = usePrivatePageContext();
  const hasAppAccess = !name?.length || isHasSomePermission(userPermission, typeof name === 'string' ? [name] : name);
  const hasProfileAccess =
    !profileName?.length || isHasSomePermission(userPermission, typeof profileName === 'string' ? [profileName] : profileName);

  if (isProfile ? !hasProfileAccess : !hasAppAccess) {
    return null;
  }

  return children;
}

export { Permission };
