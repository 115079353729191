import React, { useEffect, useState } from 'react';
import * as TabsContent from './Tabs';

import { Tabs, Tab, ConfirmPopup, FormComponent } from 'components';

import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { EventTypes } from 'utils/Enums/EventTypes';
import { DocumentsWarningPopup } from 'features/Form/looks/event/views/DocumentsWarningPopup';
import { Toolbar } from './Toolbar/Toolbar';
import { Component as TitleErrorPopup } from 'features/Form/views/TitleErrorPopup';
import PublicationConnect from '../views/PublicationConnect';
import { useController } from './controller';
import { EventHeader } from 'features/Form/views/ModalHeader';
import { useFormContext } from 'features/Form/hooks';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { RecordStatus } from 'utils/Enums';

type Props = {
  onClose: () => void;
};

const EventFormConference = ({ onClose }: Props) => {
  const docHint =
    // eslint-disable-next-line max-len
    'Не забудьте добавить подтверждающий документ: приказ, пригласительное письмо или программу конференции на вкладке «Документы»';

  const classifiersHint =
    'Раздел необходимо обязательно заполнить в случае, если Университет является организатором (соорганизатором) мероприятия';

  const materialHint = 'Необходимо обязательно заполнить, если есть участие в конференции с опубликованным докладом';

  const {
    calculateFactMemberCount,
    changeCommitteMembers,
    changeCompilations,
    changeDepartments,
    changeEnterprises,
    changeMagazineReleases,
    changeMemberCounts,
    changeProjects,
    changeSourceMagazine,
    checkIsThereNoConnectedPublications,
    closeAndContinueConfirmPopup,
    closeConfirmPopup,
    committeMembers,
    compilations,
    confirmPopupText,
    controllerIsForm,
    departmentOptions,
    departments,
    documents,
    enterprises,
    eventData,
    eventId,
    fetchEvent,
    formFields,
    handleAttractedDepartmentsChange,
    handleFormSubmit,
    isAuthorsPopupOpen,
    isConfirmPopupOpen,
    isDisableSource,
    isDocumentsWarningPopupOpen,
    isIncentivePayments,
    isLoading,
    isOpenPublicationConnectWindow,
    isOtherSelected,
    isSelectedPeriod,
    isTitleWarningPopup,
    isTSUSelected,
    magazineReleases,
    memberCounts,
    onCloseAuthorsPopup,
    onCloseDocumentsWarningPopup,
    onClosePublicationConnectWindow,
    onCloseTitleWarningPopup,
    onConfirmAuthorsPopup,
    onConfirmDocumentsWarningPopup,
    onConfirmTitleWarningPopup,
    onDeleteSourceMagazine,
    onResetAuthorsPopup,
    openParticipationFormByPublication,
    participationPublications,
    participations,
    projects,
    refs,
    relatedTableState,
    setDocuments,
    setEventDataField,
    withHeaderId,
    sourceMagazine,
    tabsId,
    termsList,
    titleWarningText,
    unconnectedParticipationPublications,
    workMode,
    organizationName,
    status,
    userPermission,
  } = useController({
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  const isApproved = status.status === RecordStatus.APPROVED;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  useEffect(() => {
    if (controllerIsForm && !(selectedRowIndex === null)) {
      controllerIsForm.setParticipation(participations[selectedRowIndex]);
    }
    // eslint-disable-next-line
  }, [selectedRowIndex]);

  const renderAboutInfo = (props?: { disabled: boolean }) => (
    <TabsContent.AboutComponent
      formFields={formFields}
      viewMode={
        workMode === 'viewMode' ||
        props?.disabled ||
        (isApproved && !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_ABOUT)) ||
        false
      }
      termsList={termsList}
      isSelectedPeriod={isSelectedPeriod}
      departments={departments}
      changeDepartments={changeDepartments}
      enterprises={enterprises}
      changeEnterprises={changeEnterprises}
      refs={refs}
      isTSUSelected={isTSUSelected}
      isOtherSelected={isOtherSelected}
      isDisableSource={isDisableSource}
      eventId={eventId}
      withHeaderId={workMode !== 'addMode' ? withHeaderId : undefined}
      hint={docHint}
    />
  );

  const renderMaterialInfo = (props?: { disabled: boolean }) => (
    <TabsContent.MaterialComponent
      eventId={eventId}
      viewMode={workMode === 'viewMode' || props?.disabled || false}
      refs={refs}
      changeSourceMagazine={changeSourceMagazine}
      changeMagazineReleases={changeMagazineReleases}
      onDeleteSourceMagazine={onDeleteSourceMagazine}
      sourceMagazine={sourceMagazine}
      magazineReleases={magazineReleases}
      relatedTableState={relatedTableState}
      compilations={compilations}
      changeCompilations={changeCompilations}
      isDisableSource={isDisableSource}
      participationPublications={participationPublications}
      hint={materialHint}
    />
  );

  const renderOrganization = (props?: { disabled: boolean }) => (
    <TabsContent.OrganizationComponent
      formFields={formFields}
      viewMode={
        workMode === 'viewMode' ||
        props?.disabled ||
        (isApproved && !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_ORGANIZATION)) ||
        false
      }
      committeMembers={committeMembers}
      changeCommitteMembers={changeCommitteMembers}
      memberCounts={memberCounts}
      changeMemberCounts={changeMemberCounts}
      calculateFactMemberCount={calculateFactMemberCount}
    />
  );

  const renderDefault = () => (
    <Tabs id={tabsId}>
      <Tab title="О мероприятии">{renderAboutInfo()}</Tab>
      <Tab title="Классификаторы">
        <TabsContent.Classifiers
          eventData={eventData}
          setEventDataField={setEventDataField}
          viewMode={
            workMode === 'viewMode' ||
            (isApproved && !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_CLASSIFICATORS))
          }
          hint={classifiersHint}
        />
      </Tab>
      <Tab title="Опубликованные материалы">{renderMaterialInfo()}</Tab>
      <Tab title="Организация мероприятия">{renderOrganization()}</Tab>
      <Tab title="Участники">
        <TabsContent.Participations
          workMode={workMode}
          participations={participations}
          setIsFormOpen={controllerIsForm}
          selectedRowIndex={selectedRowIndex}
          setSelectedRowIndex={setSelectedRowIndex}
          fetch={fetchEvent}
          checkIsThereNoConnectedPublications={checkIsThereNoConnectedPublications}
        />
      </Tab>
      <Tab title="Финансирование">
        <TabsContent.FinancialSupport
          eventData={eventData}
          projects={projects}
          setProjects={changeProjects}
          workMode={
            isApproved && !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_FINANCING) ? 'viewMode' : workMode
          }
          relatedTableState={relatedTableState}
          incentivePaymentRequest={eventData?.status?.incentivePaymentRequest || ''}
          setEventDataField={setEventDataField}
          departments={departments}
          departmentOptions={departmentOptions}
          handleAttractedDepartmentsChange={handleAttractedDepartmentsChange}
        />
      </Tab>
      <Tab title="Документы">
        <TabsContent.Documents
          documents={documents}
          setDocuments={setDocuments}
          viewMode={workMode === 'viewMode'}
          hint={docHint}
        />
      </Tab>
      <Tab title="Журнал сообщений">
        <TabsContent.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={eventId} />
      </Tab>
    </Tabs>
  );

  if (isIncentivePayments) {
    return renderDefault();
  }

  useEffect(() => {
    if (setTitle) {
      setTitle(
        EventHeader({
          workMode,
          departments,
          status: eventData?.status.text || '',
          eventId: eventId,
          eventTypeCode: EventTypes.EVENT_CONFERENCE.code,
        }),
      );
    }
  }, [departments, eventData?.status.text, eventId, setTitle, workMode]);

  return (
    <>
      <ConfirmPopup
        title="Внимание!"
        icon="info"
        isOpen={isAuthorsPopupOpen}
        okButtonText="Нет"
        resetButtonText="Добавить"
        onConfirm={onConfirmAuthorsPopup}
        onClose={onCloseAuthorsPopup}
        onReset={onResetAuthorsPopup}
      >
        <div>
          <div>
            <strong>В списке «Мои конференции», отображаются только те конференции, в которых вы в составе оргкомитета.</strong>
          </div>
          <br />
          <div>В списке «Конференции {organizationName}» — организованные Университетом</div>
          <br />
          <div>В списке «Другие конференции» — организованные другими организациями</div>
          <br />
          <span>Вашей персоны нет в списке участников оргкомитета. Добавить?</span>
        </div>
      </ConfirmPopup>

      <FormComponent.Template>
        <Toolbar
          isDisabled={isLoading}
          onSave={needClose => handleFormSubmit(needClose)}
          onSaveDraft={() => handleFormSubmit(false)}
          viewMode={workMode === 'viewMode'}
          eventId={eventId || ''}
        />

        <SuchLikeEvent
          mode="edit"
          eventName={formFields.name.value as string}
          startDate={formFields.startDate.value as string}
          endDate={formFields.endDate.value as string}
          handleCloseOuterForm={onClose}
          command="RefEventFeedlineMessage"
          eventTypeCode={EventTypes.EVENT_CONFERENCE.code}
        />

        <FormComponent.Template>
          {!isDisableSource ? (
            renderDefault()
          ) : (
            <Tabs id={tabsId}>
              <Tab title="О мероприятии">{renderAboutInfo()}</Tab>
              <Tab title="Финансирование">
                <TabsContent.FinancialSupport
                  eventData={eventData}
                  projects={projects}
                  setProjects={changeProjects}
                  workMode={
                    isApproved && !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_FINANCING)
                      ? 'viewMode'
                      : workMode
                  }
                  relatedTableState={relatedTableState}
                  incentivePaymentRequest={eventData?.status?.incentivePaymentRequest || ''}
                  setEventDataField={setEventDataField}
                  departments={departments}
                  departmentOptions={departmentOptions}
                  handleAttractedDepartmentsChange={handleAttractedDepartmentsChange}
                />
              </Tab>
              <Tab title="Документы">
                <TabsContent.Documents
                  documents={documents}
                  setDocuments={setDocuments}
                  viewMode={workMode === 'viewMode'}
                  hint={docHint}
                />
              </Tab>
            </Tabs>
          )}
        </FormComponent.Template>

        <ConfirmPopup
          title="Обратите внимание"
          text={confirmPopupText}
          isOpen={isConfirmPopupOpen}
          okButtonText="Указать"
          resetButtonText="Продолжить"
          onClose={closeAndContinueConfirmPopup}
          onConfirm={closeConfirmPopup}
          icon="info"
        />
        <DocumentsWarningPopup
          isOpen={isDocumentsWarningPopupOpen}
          onConfirm={onConfirmDocumentsWarningPopup}
          onClose={onCloseDocumentsWarningPopup}
        />
        <TitleErrorPopup
          onConfirm={onConfirmTitleWarningPopup}
          onClose={onCloseTitleWarningPopup}
          errorText={titleWarningText}
          isOpen={isTitleWarningPopup}
        />
        <PublicationConnect
          onClose={onClosePublicationConnectWindow}
          isOpen={isOpenPublicationConnectWindow}
          unconnectedParticipationPublications={unconnectedParticipationPublications}
          openParticipationFormByPublication={openParticipationFormByPublication}
        />
      </FormComponent.Template>
    </>
  );
};

export const Component = React.memo(EventFormConference);
