import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const PurchaseRequestAddTemplate: React.FC<F.TemplateProps & { projectId?: string; hasPersonalMode: boolean }> = ({
  isOpen,
  onClose,
  hasPersonalMode,
}: F.TemplateProps & { projectId?: string; hasPersonalMode: boolean }) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look & { projectId?: string; hasPersonalMode: boolean } = {
    apiID: 'GetPurchaseRequest',
    template: 'PurchaseRequestForm',
    projectId: '-1',
    setTitle,
    hasPersonalMode,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(PurchaseRequestAddTemplate);
