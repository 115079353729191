import { Project } from 'types/models';

export function validate(performerStage: Project.PerformerStage | null) {
  if (!performerStage) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidStage = performerStage.stage !== null;

  return [{ isValid: isValidStage, invalidMessage: 'Выберите этап' }];
}
