import { isFloatValid } from 'utils/Validators';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';

type Props = {
  number?: string;
  kindId?: string;
  startDate?: string;
  endDate?: string;
  conclusionDate?: string;
  enterpriseCustomerId?: string;
  price?: string;
};
export function validate(props: Props) {
  const { number, kindId, startDate, endDate, conclusionDate, enterpriseCustomerId, price } = props;
  const validations = [];
  if (!(number && kindId && startDate && endDate && conclusionDate && enterpriseCustomerId)) {
    validations.push({
      isValid: false,
      invalidMessage: 'Заполните обязательные поля',
    });
  }
  if (price && !isFloatValid(price)) {
    validations.push({
      isValid: false,
      invalidMessage: 'Неверный формат',
    });
  }
  if (startDate && endDate) {
    const fromTime = parse(startDate || '', formatStr, new Date()).getTime();
    const toTime = parse(endDate || '', formatStr, new Date()).getTime();
    if (fromTime > toTime) {
      validations.push({
        isValid: false,
        invalidMessage: 'Ошибка в датах проекта',
      });
    }
  }
  return validations;
}
