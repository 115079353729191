import React from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { templateArgs } from 'features/Table/specifications/GetMobileKindConsumptionList/LeftPanelForThirdLevel/controller';

const MobileKindConsumptionAddTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
}: F.TemplateProps) => {
  const { apiID, template } = templateArgs;
  const look: F.Look = {
    apiID,
    template,
    relatedTableState,
    name,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Виды расходов для заявок по академической мобильности" size="medium">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MobileKindConsumptionAddTemplate);
