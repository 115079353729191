import React, { useCallback, useState, useMemo } from 'react';
import block from 'bem-cn';

import { Select, Button, ButtonMode, Checkbox, FormComponent } from 'components';

// eslint-disable-next-line max-len
import publicationTypes from 'features/Table/specifications/GetConferencePublicationList/LeftPanelForThirdLevel/modalTemplates/publicationTypes';
import { PublicationType } from 'types/models/Publication/publicationTypes';

const b = block('toolbar-form-template');

type Props = {
  onClose: () => void;
  onSelect: (val: PublicationType) => void;
  isElectronic: boolean;
  setIsElectronic: (nextIsElectronic: boolean) => void;
  electronicPublicationType: PublicationType;
  setElectronicPublicationType: (nextElectronicPublicationType: PublicationType) => void;
};

const MagazineAddTemplate: React.FC<Props> = ({
  onClose,
  onSelect,
  isElectronic,
  setIsElectronic,
  electronicPublicationType,
  setElectronicPublicationType,
}) => {
  const preparedPublicationTypes = useMemo<PublicationType[]>(() => {
    const list: PublicationType[] = [
      publicationTypes.magazineArticle,
      publicationTypes.articleConference,
      publicationTypes.thesis,
    ];

    return list;
  }, []);

  const [publicationType, setPublicationType] = useState(preparedPublicationTypes[0]);

  const onChangePublicationType = (val: string) => {
    setPublicationType(preparedPublicationTypes.filter(type => type.label === val)[0]);
  };

  const continueWithSelectedType = () => {
    onSelect(publicationType);
  };

  const renderEntry = (x: string) => (
    <div className={b('item-text')} title={x}>
      {x}
    </div>
  );

  const electronicPublicationTypes = useMemo<PublicationType[]>(
    () => [
      {
        id: '1',
        label: 'Сетевой ресурс',
        labelDeclined: 'Сетевой ресурс',
        text: 'Публикация размещена в сети Интернет',
        code: 'WEB',
      },
      {
        id: '2',
        label: 'Эл. ресурс локального доступа',
        labelDeclined: 'Эл. ресурс локального доступа',
        text: 'Публикация размещена на электронном носителе. Например: CD-диске',
        code: 'LOCAL',
      },
    ],
    [],
  );

  const onChangeElectronicPublicationType = useCallback(
    (val: string) => {
      const selectedItemIndex = electronicPublicationTypes.findIndex(({ label }) => val === label);
      if (selectedItemIndex !== -1) {
        setElectronicPublicationType({ ...electronicPublicationTypes[selectedItemIndex] });
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  return (
    <FormComponent.Wrapper>
      <div className={b('wrapper')}>
        <div className={b('field')}>
          <div className={b('field-title')}>Тип публикации</div>
          <div className={b('field-select')}>
            <Select.Component
              value={publicationType.label}
              onChange={onChangePublicationType}
              entries={preparedPublicationTypes.map(e => e.label)}
              renderEntry={renderEntry}
              variant="outlined"
              defaultPlaceholder="Не задан"
            />
          </div>
        </div>
        {publicationType.text && <p className="field-text">{publicationType.text}</p>}
        <div className={b('checkbox')}>
          <Checkbox
            label="Электронное издание, не имеющее печатного аналога"
            checked={!!isElectronic}
            onChange={setIsElectronic}
          />
        </div>
        {!isElectronic && (
          <div className={b('fields-wrapper')}>
            <div className={b('field')}>
              <div className={`${b('field-select')} ${b('field-select--full-width')}`}>
                <Select.Component
                  value={electronicPublicationType.label}
                  onChange={onChangeElectronicPublicationType}
                  entries={electronicPublicationTypes.map(electronicPulicationType => electronicPulicationType.label)}
                  renderEntry={renderEntry}
                  variant="outlined"
                  defaultPlaceholder="Не задан"
                />
              </div>
            </div>
            {electronicPublicationType.code && <p className="field-text">{electronicPublicationType.text}</p>}
          </div>
        )}
      </div>
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.primary}
          text="Продолжить"
          onClick={continueWithSelectedType}
          isDisabled={isElectronic && !electronicPublicationType.code}
        />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(MagazineAddTemplate);
