import { Table as T, Table } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps, ReferenceItem } from 'components';
import { getIsCanEdit } from 'utils/Helpers/feedline/getIsCanEdit';
import { getIsCanDelete } from 'utils/Helpers/feedline/getIsCanDelete';
import { State } from '../makeUseCustomController';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams } from '../streams';

type Props = {
  tableState: Table.State;
  customState: State;
  workMode: T.WorkMode;
};
export function useController({
  tableState,
  customState: { isFormOpen, messageLabel, publicationMessage, foreignSecurityDocumentId },
  workMode,
}: Props) {
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = useMemo(
    () => workMode === 'viewMode' || !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)),
    [rowsAvailability.SINGLE_SELECTED, tableState.selectedRows, workMode],
  );

  const isDeleteButtonDisabled = useMemo(
    () => workMode === 'viewMode' || !(rowsAvailability.SINGLE_SELECTED && getIsCanDelete(tableState.selectedRows)),
    [rowsAvailability.SINGLE_SELECTED, tableState.selectedRows, workMode],
  );

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleAddButtonClick = useCallback(() => {
    streams.toggleMessageForm.push();
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    streams.clearMessageState.push();
    streams.clearMessageLabelState.push();
    streams.toggleMessageForm.push();
  }, []);

  const handleMessageChange = useCallback((message: string) => {
    streams.changeMessageState.push({
      message,
    });
  }, []);

  const handleMessageLabelChange = useCallback((label: ReferenceItem) => {
    streams.changeMessageLabelState.push(label);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    const editingMessage = tableState.selectedRows[0];
    streams.changeMessageState.push({
      id: editingMessage.id,
      message: editingMessage.Detail,
    });
    streams.changeMessageLabelState.push({
      id: editingMessage['id:Message'],
      label: editingMessage.Message,
    });
    streams.toggleMessageForm.push();
  }, [tableState.selectedRows]);

  const handleFormSubmit = useCallback(() => {
    streams.addNewGridSetting.push({
      message: publicationMessage.message,
      messageId: publicationMessage.id,
      labelId: messageLabel.id,
      foreignSecurityDocumentId: foreignSecurityDocumentId || '',
    });
    streams.toggleMessageForm.push();
  }, [publicationMessage.message, publicationMessage.id, messageLabel.id, foreignSecurityDocumentId]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ForeignSecurityDocumentFeedline',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);
  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: workMode === 'viewMode',
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
      },
    ],
    [
      handleAddButtonClick,
      handleEditButtonClick,
      handleDeleteButtonClick,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      workMode,
    ],
  );
  return {
    handleFormSubmit,
    handleTemplateCloseAddForm,
    isFormOpen,
    publicationMessage,
    handleMessageChange,
    messageLabel,
    handleMessageLabelChange,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
  };
}
