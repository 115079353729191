import React from 'react';
import { block } from 'bem-cn';
import { NavLink } from 'react-router-dom';

import Logo from './Logo.svg';
import './style.scss';

const b = block('page-layout');

export const LogoComponent = () => (
  <div className={b('logo')}>
    <NavLink
      className={b('logo-link').toString()}
      key="home"
      to="/"
      title="Перейти на главную"
      onMouseDownCapture={() => {
        window.location.hash = '/';
      }}
    >
      <img className={b('logo-img')} src={Logo} alt="logo" />
    </NavLink>
  </div>
);
