import React, { useCallback, useMemo } from 'react';

import { buttonIcons, IconButtonProps, Toolbar as SharedToolbar } from 'components';

import { Table, RecordStatus } from 'types/models';
import { Department, ParticipationType } from 'types/models/Participation';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';

type Permits = {
  approve: string;
  edit: string;
};

export type Props = {
  permits: Permits;
  type: ParticipationType;
  isDisabled: boolean;
  isProfile: boolean;
  addCallback: (needClose: boolean) => void;
  workMode: Table.WorkMode;
  id: string;
  isEditDepartmentsOpen: boolean;
  isApprovePopupOpen: boolean;
  isOnlyEditing?: boolean;
  afterSubmitDepartmentsForm: (departments: Department[]) => void;
  afterSubmitApproveParticipation: ({ nextRecordStatus }: { nextRecordStatus: RecordStatus.Status }) => void;
  handleOpenApprovePopup: () => void;
  handleCloseApprovePopup: () => void;
  openEditDepartmentsForm: () => void;
  closeEditDepartmentsForm: () => void;
};

function Toolbar({
  permits,
  type,
  isDisabled,
  id,
  workMode,
  isEditDepartmentsOpen,
  isApprovePopupOpen,
  isProfile,
  isOnlyEditing = false,
  afterSubmitDepartmentsForm,
  handleOpenApprovePopup,
  handleCloseApprovePopup,
  afterSubmitApproveParticipation,
  addCallback,
  openEditDepartmentsForm,
  closeEditDepartmentsForm,
}: Props) {
  const handleAddAndContinueButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      addCallback(false);
    },
    [addCallback],
  );

  const handleAddButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      addCallback(true);
    },
    [addCallback],
  );

  const handleEditRelationsClick = useCallback(() => {
    openEditDepartmentsForm();
  }, [openEditDepartmentsForm]);

  const buttons = useMemo<IconButtonProps[]>(() => {
    const requiredButtons: IconButtonProps[] = [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'add',
        isDisabled: (workMode === 'viewMode' && !isOnlyEditing) || isDisabled,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        code: 'addAndSave',
        isDisabled: workMode === 'viewMode' || isDisabled,
        isHidden: isOnlyEditing,
        onClick: handleAddAndContinueButtonClick,
      },
    ];
    const optionalButtons: IconButtonProps[] = [
      {
        icons: buttonIcons.approve,
        title: 'Процедура утверждения',
        code: 'approve',
        isDisabled: workMode === 'viewMode' || workMode === 'addMode' || isDisabled,
        isHidden: isOnlyEditing || isProfile,
        onClick: handleOpenApprovePopup,
        permissionName: permits.approve,
        profilePermissionName: permits.approve,
      },
      {
        icons: buttonIcons.editRelations,
        title: 'Редактировать связь с подразделением',
        code: 'editDepartments',
        isDisabled: workMode === 'viewMode' || workMode === 'addMode' || isDisabled,
        isHidden: isOnlyEditing,
        onClick: handleEditRelationsClick,
        permissionName: permits.edit,
        profilePermissionName: permits.edit,
      },
    ];

    if (workMode === 'editMode') {
      return [...requiredButtons, ...optionalButtons];
    }

    return [...requiredButtons];
  }, [
    handleAddAndContinueButtonClick,
    handleAddButtonClick,
    handleEditRelationsClick,
    handleOpenApprovePopup,
    isDisabled,
    isOnlyEditing,
    isProfile,
    permits.approve,
    permits.edit,
    workMode,
  ]);

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />
      <EditParticipationDepartments
        type={type.code}
        id={id}
        isOpen={isEditDepartmentsOpen}
        afterSubmit={afterSubmitDepartmentsForm}
        onClose={closeEditDepartmentsForm}
      />
      <ApproveParticipation
        type={type.code}
        id={id}
        isOpen={isApprovePopupOpen}
        afterSubmit={afterSubmitApproveParticipation}
        onClose={handleCloseApprovePopup}
      />
    </>
  );
}

export const ControlPanel = React.memo(Toolbar);
