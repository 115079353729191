import { Community } from 'types/models';
import { Item } from 'types/models/common';
import { CommunityType, CommunityMemberRole, CommunityMemberCooperationType } from 'utils/Enums';

export function getMockCommunity(): Community.Community {
  return {
    id: null,
    type: null,
    foundationDate: '',
    code: '',
    isLocal: true,
    vaks: [],
    grntis: [],
    affiliation: null,
    members: [],
    documents: [],
    isApproved: false,
    orderNumber: '',
    orderDate: '',
    note: '',
  };
}

export function getRoleOptionsByCommunityType(allOptions: Item[], selectedCommunityType: Community.CommunityType): Item[] {
  const optionsBySelectedType =
    selectedCommunityType === CommunityType.DISSERTATION_COUNCIL
      ? allOptions.filter(x =>
          [
            CommunityMemberRole.CHAIRMAN,
            CommunityMemberRole.CHAIRMAN_DEPUTY,
            CommunityMemberRole.SCIENTIFIC_SECRETARY,
            CommunityMemberRole.MEMBER,
          ].includes((x.value as Community.CommunityMemberRole) || ''),
        )
      : selectedCommunityType === CommunityType.COLLABORATION
      ? allOptions.filter(x => x.value === CommunityMemberRole.PARTICIPANT)
      : selectedCommunityType === CommunityType.SCIENTIFIC_COUNCIL
      ? allOptions.filter(x =>
          [CommunityMemberRole.CHAIRMAN, CommunityMemberRole.CHAIRMAN_DEPUTY, CommunityMemberRole.MEMBER].includes(
            (x.value as Community.CommunityMemberRole) || '',
          ),
        )
      : selectedCommunityType === CommunityType.FUND
      ? allOptions.filter(x => x.value === CommunityMemberRole.EXPERT)
      : selectedCommunityType === CommunityType.MAGAZINE_REDACTION
      ? allOptions.filter(x =>
          [
            CommunityMemberRole.CHIEF_EDITOR,
            CommunityMemberRole.DEPUTY_CHIEF_EDITOR,
            CommunityMemberRole.EXECUTIVE_SECRETARY,
            CommunityMemberRole.EDITORIAL_COUNCIL,
            CommunityMemberRole.MEMBER_EDITORIAL_BOARD,
          ].includes((x.value as Community.CommunityMemberRole) || ''),
        )
      : allOptions;
  return optionsBySelectedType;
}

export function getCooperationTypeOptionsByCommunityType(
  allOptions: Item[],
  selectedCommunityType: Community.CommunityType,
): Item[] {
  const optionsBySelectedType =
    selectedCommunityType === CommunityType.DISSERTATION_COUNCIL
      ? allOptions.filter(x => x.value === CommunityMemberCooperationType.DISSERTATION_COUNCIL_PARTICIPATION)
      : selectedCommunityType === CommunityType.COLLABORATION
      ? allOptions.filter(x => x.value === CommunityMemberCooperationType.COLLABORATION_PARTICIPATION)
      : selectedCommunityType === CommunityType.SCIENTIFIC_COUNCIL
      ? allOptions.filter(x => x.value === CommunityMemberCooperationType.SCIENTIFIC_COUNCIL_PARTICIPATION)
      : selectedCommunityType === CommunityType.FUND
      ? allOptions.filter(x => x.value === CommunityMemberCooperationType.FUND_EXPERT)
      : selectedCommunityType === CommunityType.MAGAZINE_REDACTION
      ? allOptions.filter(x => x.value === CommunityMemberCooperationType.EDITORIAL_BOARD_MEMBER)
      : allOptions;
  return optionsBySelectedType;
}
