import { FinancingByYear, ProjectCode } from 'types/models/Project';
import { calcNdsSumm } from 'utils/Helpers';

type Props = {
  row: FinancingByYear;
  projectCode: ProjectCode | null;
};

export const calculateNdsSumByYear = ({ row, projectCode }: Props) => {
  const rowSum =
    parseFloat(row.amountMain) +
    parseFloat(row.amountCofinancing1) +
    parseFloat(row.amountCofinancing2) +
    parseFloat(row.amountLocal);
  const distributedPart = projectCode?.calendarPlansByYears?.filter(plan => plan.year === row.year)[0];

  return {
    percent: distributedPart?.ndsPercent || 0,
    amount: calcNdsSumm(String(rowSum), String(distributedPart?.ndsPercent || 0)),
  };
};
