import React from 'react';

import { FormComponent, Reference } from 'components';

import { Department } from 'types/models/Department';
import { useController } from './controller';

type Props = {
  department: Department | null;
  onDepartmentChange(row: Department): void;
};

function DepartmentInputs({ department, onDepartmentChange }: Props) {
  const { makeChangeHandler } = useController({
    department,
    onDepartmentChange,
  });
  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Факультет/Институт">
          <Reference
            name="RefDepartment"
            onChange={makeChangeHandler('governance')}
            value={department?.governance ?? null}
            relationTableModalTitle='Справочник "Подразделения"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Подразделение">
          <Reference
            name="RefDepartment"
            onChange={makeChangeHandler('unit')}
            value={department?.unit ?? null}
            relationTableModalTitle='Справочник "Подразделения"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { DepartmentInputs };
