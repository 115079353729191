import { useCallback } from 'react';
import { UserActivationKind, UserRole } from 'types/models/UserActivation';
import { Role, Table as T } from 'types/models';

type Props = {
  item: UserActivationKind | null;
  setItem(item: UserActivationKind | null): void;
};

export function useController({ item, setItem }: Props) {
  const makeHandler = (key: keyof UserActivationKind) => (value: string) => {
    setItem({
      ...item,
      [key]: value,
    } as UserActivationKind);
  };

  const handleRoleChange = useCallback(
    (value: UserRole) => {
      setItem({
        ...item,
        roles: [{ ...value }],
      } as UserActivationKind);
    },
    [item, setItem],
  );

  const tableRowConverter = useCallback(
    (row: T.Entry): Role => ({
      id: row.id,
      name: row.name,
      description: '',
    }),
    [],
  );
  return {
    handleRoleChange,
    tableRowConverter,
    makeHandler,
  };
}
