import React, { useCallback, useEffect, useRef } from 'react';
import * as R from 'ramda';

import { FormComponent, Reference, ReferenceItem, TextInput } from 'components';

import { SelectPerson, PersonRef, Member } from 'features/SelectPerson';
import { Author, Person } from 'types/models';
import { MemberAffilation } from 'types/models/Author';
import { AffilationField, SelectBiblioName } from '../Fields';
import { getBiblioName, getMockAuthor, isCollectiveAuthor } from '../helpers';
import { MemberAffilations } from '../MemberAffiliations/MemberAffiliations';

export type ChangeProps = {
  key: keyof Author;
  value: Author[keyof Author];
};

type Props = {
  author: Author | null;
  language: ReferenceItem;
  onChange(args: ChangeProps[]): void;
  withoutControllPanel?: boolean;
  filters?: string[];
};

function AuthorshipFields(props: Props) {
  const { author, language, onChange, withoutControllPanel, filters = [] } = props;

  const scientistPerson = author?.person ?? null;
  const personRef = useRef<PersonRef | null>(null);

  useEffect(() => {
    if (scientistPerson) {
      const biblio = scientistPerson.bibliographicNames.find(x => x.name === author?.name) ?? null;
      if (!biblio) {
        const name = getBiblioName(scientistPerson, language);
        makeChange('name')(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scientistPerson]);

  useEffect(() => {
    if (author === null) {
      const changeProps = Object.entries(getMockAuthor()).map(([key, val]) => ({ [key]: val })) as ChangeProps[];
      onChange(changeProps);
    }
  }, [author, onChange]);

  const setPersonRef = useCallback((ref: PersonRef) => {
    personRef.current = ref;
  }, []);

  const resetPerson = useCallback(() => {
    onChange([
      { key: 'person', value: null },
      { key: 'degree', value: null },
      { key: 'rank', value: null },
      { key: 'academicRank', value: null },
      { key: 'job', value: null },
      { key: 'education', value: null },
      { key: 'citizenship', value: null },
    ]);
  }, [onChange]);

  const onAfterAddBiblioName = useCallback(() => {
    if (personRef.current) {
      personRef.current.reloadPersonBiblographicNames();
    }
  }, []);

  const makeChange = (key: keyof Author) => (val: Author[keyof Author]) => onChange([{ key, value: val }]);

  const handleChangeIsAffilation = useCallback(
    (isAffilated: boolean) => {
      const changes = (R.concat(
        [{ key: 'isAffilated', value: isAffilated }],
        !isAffilated ? [{ key: 'affilateCount', value: null }] : [],
      ) as unknown) as ChangeProps[];
      onChange(changes);
    },
    [onChange],
  );

  const handleChangeMembersAffilations = useCallback(
    (memberAffilations: MemberAffilation[]) => {
      onChange([{ key: 'memberAffilations', value: memberAffilations }]);
    },
    [onChange],
  );

  const onUpdateBibliographicNames = useCallback(
    (bibliographicNames: Person.BibliographicName[]) => {
      if (author?.person) {
        onChange([{ key: 'person', value: { ...author.person, bibliographicNames } }]);
      }
    },
    [onChange, author?.person],
  );

  const updateAuthor = useCallback(
    (updatedMember: Member | null) => {
      if (updatedMember) {
        const changeProps = Object.entries(updatedMember).map<ChangeProps>(([key, value]) => ({
          key: key as keyof Member,
          value,
        }));
        onChange(changeProps);
      }
    },
    [onChange],
  );

  const handleChangeEnterprise = useCallback((ref: ReferenceItem | null) => {
    makeChange('enterprise')(ref);
    makeChange('name')(ref?.shortName ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCategory = (category: ReferenceItem | null) => {
    makeChange('category')(category);
    if (category === null) {
      handleChangeEnterprise(null);
      resetPerson();
      makeChange('name')('');
      return;
    }
    const prevCategory = author?.category ?? null;
    if (!isCollectiveAuthor(prevCategory) && isCollectiveAuthor(category)) {
      resetPerson();
      makeChange('name')('');
    }

    if (isCollectiveAuthor(prevCategory) && !isCollectiveAuthor(category)) {
      makeChange('name')('');
      handleChangeEnterprise(null);
    }
  };

  const renderCollectiveAuthorFields = () => (
    <>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Организация">
          <Reference
            name="RefEnterprise"
            relationTableModalTitle='Справочник "Организации"'
            value={author?.enterprise ?? null}
            onChange={handleChangeEnterprise}
            withoutDropdown
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Используемое наименование">
          <TextInput value={author?.name ?? ''} onChange={makeChange('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );

  const renderPersonFields = () => (
    <>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Персона">
          <SelectPerson
            getPersonRef={setPersonRef}
            member={author}
            onUpdateMember={updateAuthor}
            onUpdateBibliographicNames={onUpdateBibliographicNames}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field isRequired label="ФИО в библ. записи">
          <SelectBiblioName
            scientistPerson={scientistPerson}
            selectedBiblioName={author?.name ?? ''}
            onAfterAddBiblioName={onAfterAddBiblioName}
            onChangeBiblioName={makeChange('name')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Категория ответственности" isRequired>
          <Reference
            name="RefCategory"
            relationTableModalTitle='Справочник "Категории ответственности"'
            value={author?.category ?? null}
            onChange={handleChangeCategory}
            withoutControlPanel={withoutControllPanel}
            filters={[{ key: 'code', values: filters }]}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {author && isCollectiveAuthor(author.category) ? renderCollectiveAuthorFields() : renderPersonFields()}

      <FormComponent.Line>
        <AffilationField
          amountAffilation={String(author?.affilateCount ?? '')}
          isAffilated={author?.isAffilated ?? false}
          onChangeAmountAffilation={makeChange('affilateCount')}
          onChangeIsAffilation={handleChangeIsAffilation}
        />
      </FormComponent.Line>

      {(!author || !isCollectiveAuthor(author.category)) && (
        <MemberAffilations author={author} handleChangeMembersAffilations={handleChangeMembersAffilations} />
      )}
    </FormComponent.Wrapper>
  );
}

export { AuthorshipFields };
