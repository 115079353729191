import React, { useCallback, useMemo } from 'react';
import block from 'bem-cn';

import { Select, Button, ButtonMode, Checkbox, FormComponent } from 'components';

import publicationTypes, { PublicationType } from 'types/models/Publication/publicationTypes';
import { SelectProp } from '..';

const b = block('toolbar-form-template');

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  publicationType: PublicationType | null;
  electronicPublicationType: SelectProp;
  setPublicationType: (nextPublicationType: PublicationType) => void;
  setElectronicPublicationType: (nextElectronicPublicationType: SelectProp) => void;
  isElectronic: boolean;
  setIsElectronic: (nextIsElectronic: boolean) => void;
  compilationTypeCode: string;
};

const CompilationAddTemplate: React.FC<Props> = ({
  onClose,
  onConfirm,
  publicationType,
  electronicPublicationType,
  setPublicationType,
  setElectronicPublicationType,
  isElectronic,
  setIsElectronic,
  compilationTypeCode,
}) => {
  const NumPublicationTypes = useMemo(() => publicationTypes.filter(e => e.code === compilationTypeCode), [compilationTypeCode]);

  const electronicPublicationTypes = useMemo(
    () => [
      {
        id: '1',
        label: 'Сетевой ресурс',
        text: 'Публикация размещена в сети Интернет',
        code: 'WEB',
      },
      {
        id: '2',
        label: 'Эл. ресурс локального доступа',
        text: 'Публикация размещена на электронном носителе. Например: CD-диске',
        code: 'LOCAL',
      },
    ],
    [],
  );

  const onChangePublicationType = useCallback(
    (val: string) => {
      const selectedItemIndex = NumPublicationTypes.filter(type => type.label === val);
      if (selectedItemIndex.length) {
        setPublicationType(selectedItemIndex[0]);
      }
    },
    [NumPublicationTypes, setPublicationType],
  );

  const onChangeElectronicPublicationType = useCallback(
    (val: string) => {
      const selectedItemIndex = electronicPublicationTypes.findIndex(({ label }) => val === label);
      if (selectedItemIndex !== -1) {
        setElectronicPublicationType({ ...electronicPublicationTypes[selectedItemIndex] });
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  const continueWithSelectedType = () => {
    onConfirm();
  };

  const renderEntry = (x: string) => (
    <div className={b('item-text')} title={x}>
      {x}
    </div>
  );

  return (
    <FormComponent.Wrapper>
      <div className={b('wrapper')}>
        <div className={b('fields-wrapper')}>
          <div className={b('field')}>
            <div className={b('field-title')}>Тип публикации</div>
            <div className={b('field-select')}>
              <Select.Component
                value={publicationType?.label || ''}
                onChange={onChangePublicationType}
                entries={NumPublicationTypes.map(e => e.label)}
                renderEntry={renderEntry}
                variant="outlined"
                defaultPlaceholder="Не задан"
              />
            </div>
          </div>
          {publicationType?.code && <p className="field-text">{publicationType?.text}</p>}
          <div className={b('checkbox')}>
            <Checkbox
              label="Электронное издание, не имеющее печатного аналога"
              checked={!!isElectronic}
              onChange={setIsElectronic}
            />
          </div>
        </div>

        {isElectronic && (
          <div className={b('fields-wrapper')}>
            <div className={b('field')}>
              <div className={`${b('field-select')} ${b('field-select--full-width')}`}>
                <Select.Component
                  value={electronicPublicationType.label}
                  onChange={onChangeElectronicPublicationType}
                  entries={electronicPublicationTypes.map(electronicPulicationType => electronicPulicationType.label)}
                  renderEntry={renderEntry}
                  variant="outlined"
                  defaultPlaceholder="Не задан"
                />
              </div>
            </div>
            {electronicPublicationType.code && <p className="field-text">{electronicPublicationType.text}</p>}
          </div>
        )}
      </div>
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.primary}
          text="Продолжить"
          onClick={continueWithSelectedType}
          isDisabled={isElectronic && !electronicPublicationType.code}
        />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(CompilationAddTemplate);
