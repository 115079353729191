import React, { useEffect, useState } from 'react';

import { Tabs, Tab, ConfirmPopup, FormComponent } from 'components';

import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { DocumentsWarningPopup } from 'features/Form/looks/event/views/DocumentsWarningPopup';
import { EventTypes } from 'utils/Enums/EventTypes';
import { useController } from './controller';
import { Component as TitleErrorPopup } from 'features/Form/views/TitleErrorPopup';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { EventHeader } from 'features/Form/views/ModalHeader';
import { useFormContext } from 'features/Form/hooks';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const EventFormContest = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    isDocumentsWarningPopupOpen,
    tabsId,
    calculateFactMemberCount,
    changeCommitteMembers,
    changeDepartments,
    changeEnterprises,
    changeMemberCounts,
    changeProjects,
    committeMembers,
    controllerIsForm,
    departments,
    documents,
    enterprises,
    eventId,
    formFields,
    fetchEvent,
    handleFormSubmit,
    isIncentivePayments,
    isDisableSource,
    isOtherSelected,
    isSelectedPeriod,
    isTitleWarningPopup,
    isTSUSelected,
    memberCounts,
    onCloseTitleWarningPopup,
    onConfirmTitleWarningPopup,
    participations,
    projects,
    eventData,
    workMode,
    refs,
    relatedTableState,
    setDocuments,
    termsList,
    titleWarningText,
    onCloseDocumentsWarningPopup,
    onConfirmDocumentsWarningPopup,
    withHeaderId,
    isAuthorsPopupOpen,
    onCloseAuthorsPopup,
    onConfirmAuthorsPopup,
    onResetAuthorsPopup,
    organizationName,
    status,
    userPermission,
  } = useController({
    onClose,
  });

  const {
    look: { id, setTitle },
  } = useFormContext();

  const isApproved = status.status === 'APPROVED';
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  useEffect(() => {
    if (controllerIsForm && !(selectedRowIndex === null)) {
      controllerIsForm.setParticipation(participations[selectedRowIndex] || null);
    }
    // eslint-disable-next-line
  }, [selectedRowIndex]);

  const renderAboutInfo = (props?: { disabled: boolean }) => (
    <TabsContent.AboutComponent
      changeDepartments={changeDepartments}
      changeEnterprises={changeEnterprises}
      departments={departments}
      enterprises={enterprises}
      formFields={formFields}
      isOtherSelected={isOtherSelected}
      isSelectedPeriod={isSelectedPeriod}
      isTSUSelected={isTSUSelected}
      refs={refs}
      relatedTableState={relatedTableState}
      termsList={termsList}
      viewMode={viewMode || props?.disabled || false}
      withHeaderId={withHeaderId}
    />
  );

  const renderOrganization = (props?: { disabled: boolean }) => (
    <TabsContent.OrganizationComponent
      calculateFactMemberCount={calculateFactMemberCount}
      changeCommitteMembers={changeCommitteMembers}
      changeMemberCounts={changeMemberCounts}
      committeMembers={committeMembers}
      formFields={formFields}
      memberCounts={memberCounts}
      viewMode={viewMode || props?.disabled || false}
    />
  );

  const renderDefault = () => (
    <Tabs id={tabsId}>
      <Tab
        title="О мероприятии"
        isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.CONTEST_EDIT_APPROVED_ABOUT)}
      >
        {renderAboutInfo()}
      </Tab>
      <Tab
        title="Организация мероприятия"
        isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.CONTEST_EDIT_APPROVED_ORGANIZATION)}
      >
        {renderOrganization()}
      </Tab>
      <Tab title="Участники">
        <TabsContent.Participations
          participations={participations}
          selectedRowIndex={selectedRowIndex}
          setIsFormOpen={controllerIsForm}
          setSelectedRowIndex={setSelectedRowIndex}
          workMode={workMode}
          fetch={fetchEvent}
        />
      </Tab>
      <Tab
        title="Финансовая поддержка"
        isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.CONTEST_EDIT_APPROVED_FINANCING)}
      >
        <TabsContent.FinancialSupport
          incentivePaymentRequest={eventData?.status?.incentivePaymentRequest || ''}
          projects={projects}
          relatedTableState={relatedTableState}
          setProjects={changeProjects}
          workMode={workMode}
        />
      </Tab>
      <Tab title="Документы">
        <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
      </Tab>
      <Tab title="Журнал сообщений">
        <TabsContent.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={eventId} />
      </Tab>
    </Tabs>
  );

  if (isIncentivePayments) {
    return renderDefault();
  }

  useEffect(() => {
    if (setTitle) {
      setTitle(
        EventHeader({
          workMode,
          departments,
          status: status.text || '',
          eventId: id || '',
          eventTypeCode: EventTypes.EVENT_CONTEST.code,
        }),
      );
    }
  }, [departments, id, setTitle, status.text, workMode]);

  return (
    <>
      <ConfirmPopup
        title="Внимание!"
        icon="info"
        isOpen={isAuthorsPopupOpen}
        okButtonText="Нет"
        resetButtonText="Добавить"
        onConfirm={onConfirmAuthorsPopup}
        onClose={onCloseAuthorsPopup}
        onReset={onResetAuthorsPopup}
      >
        <div>
          <div>
            <strong>В списке «Мои конкурсы», отображаются только те конкурсы, в которых вы в составе оргкомитета.</strong>
          </div>
          <br />
          <div>В списке «Конкурсы {organizationName}» — организованные Университетом</div>
          <br />
          <div>В списке «Другие конкурсы» — организованные другими организациями</div>
          <br />
          <span>Вашей персоны нет в списке участников оргкомитета. Добавить?</span>
        </div>
      </ConfirmPopup>

      <FormComponent.Template>
        <ControlPanel
          handleSave={() => handleFormSubmit(true)}
          handleSaveAndContinue={() => handleFormSubmit(false)}
          viewMode={viewMode}
          isDisabled={isLoading}
        />

        <SuchLikeEvent
          mode="edit"
          eventName={formFields.name.value as string}
          startDate={formFields.startDate.value as string}
          endDate={formFields.endDate.value as string}
          handleCloseOuterForm={onClose}
          command="RefEventFeedlineMessage"
          eventTypeCode={EventTypes.EVENT_CONTEST.code}
        />

        <FormComponent.Template>
          {!isDisableSource ? (
            renderDefault()
          ) : (
            <Tabs>
              <Tab
                title="О мероприятии"
                isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.CONTEST_EDIT_APPROVED_ABOUT)}
              >
                {renderAboutInfo()}
              </Tab>
            </Tabs>
          )}
        </FormComponent.Template>

        <DocumentsWarningPopup
          isOpen={isDocumentsWarningPopupOpen}
          onConfirm={onConfirmDocumentsWarningPopup}
          onClose={onCloseDocumentsWarningPopup}
        />
        <TitleErrorPopup
          errorText={titleWarningText}
          isOpen={isTitleWarningPopup}
          onClose={onCloseTitleWarningPopup}
          onConfirm={onConfirmTitleWarningPopup}
        />
      </FormComponent.Template>
    </>
  );
};

export const Component = React.memo(EventFormContest);
