import React from 'react';
import * as R from 'ramda';

import { Form as F, Participation, Table } from 'types/models';

import { Form } from 'features/Form';

type Props = {
  tableState: Table.State;
  participation: Participation | null;
  onClose: () => void;
};

export function ViewParticipation({ tableState, participation, onClose }: Props) {
  if (participation === null) {
    return null;
  }

  const look: F.PublicationFormLook = {
    apiID: 'GetParticipation',
    template: 'ParticipationFormExposition',
    id: participation.id,
    relatedTableState: R.over(R.lensPath(['selectedRows', 0]), x => ({ ...x, id: participation.id }), tableState),
    viewMode: true,
    isRequestPaymentView: true,
  };

  return <Form look={look} onClose={onClose} />;
}
