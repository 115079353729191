import { useCallback, useMemo } from 'react';
import { Report, Table } from 'types/models';

import { buttonIcons, IconButtonProps } from 'components';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Permits } from 'utils/Permissions';
import { isHasPermission, isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { State } from '../makeUseCustomController';
import { ProjectType } from 'utils/Enums';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function useController({ customState, tableState }: Props) {
  const { userPermission } = useAppDataContext();
  const { toggleVisibleForm, setMode } = customState;
  const reports = useMemo<Report[]>(
    () => [Reports.ProjectIkrbs, Reports.ProjectResultsDetail, Reports.ProjectPerformersForCitis],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleViewButtonClick = useCallback(() => {
    setMode('view');
    toggleVisibleForm();
  }, [setMode, toggleVisibleForm]);

  const handleEditButtonClick = useCallback(() => {
    setMode('edit');
    toggleVisibleForm();
  }, [setMode, toggleVisibleForm]);

  const projectId = useMemo(() => {
    const [row] = tableState.selectedRows;
    if (row) {
      return row.ProjectID as string;
    }
  }, [tableState.selectedRows]);

  const projectType = useMemo(() => {
    const [row] = tableState.selectedRows;
    if (row) {
      return row['id:ProjectType'] as ProjectType;
    }
  }, [tableState.selectedRows]);

  const viewPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_VIEW,
      GRANT: Permits.GRANT_VIEW,
      GOVERMENT_CONTRACT: Permits.GOVERMENT_CONTRACT_VIEW,
      NIR: Permits.RESEARCH_JOB_VIEW,
    }),
    [],
  );

  const editPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_EDIT,
      GRANT: Permits.GRANT_EDIT,
      GOVERMENT_CONTRACT: Permits.GOVERMENT_CONTRACT_EDIT,
      NIR: Permits.RESEARCH_JOB_EDIT,
    }),
    [],
  );

  const isViewHidden = useMemo(() => !isHasSomePermission(userPermission, Object.values(viewPermitsMap)), [
    userPermission,
    viewPermitsMap,
  ]);

  const isEditHidden = useMemo(() => !isHasSomePermission(userPermission, Object.values(editPermitsMap)), [
    editPermitsMap,
    userPermission,
  ]);

  const isViewDisabled = useMemo(() => {
    if (projectType) {
      return !isHasPermission(userPermission, viewPermitsMap[projectType]);
    }
    return true;
  }, [projectType, userPermission, viewPermitsMap]);

  const isEditDisabled = useMemo(() => {
    if (projectType) {
      return !isHasPermission(userPermission, editPermitsMap[projectType]);
    }
    return true;
  }, [editPermitsMap, projectType, userPermission]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isHidden: isViewHidden,
        isDisabled: isViewDisabled,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактирование',
        isHidden: isEditHidden,
        isDisabled: isEditDisabled,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      getReports,
      handleEditButtonClick,
      handleSetCurrentReport,
      handleViewButtonClick,
      isEditDisabled,
      isEditHidden,
      isViewDisabled,
      isViewHidden,
    ],
  );

  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    projectId,
  };
}
