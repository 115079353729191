import React, { useMemo, useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Modal } from 'components';

const MagazineEditTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  disabledEvent,
}: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const participationTypes = useMemo(
    () => [
      ParticipationTypes.PARTICIPATION_CONFERENCE,
      ParticipationTypes.PARTICIPATION_EXPOSITION,
      ParticipationTypes.PARTICIPATION_CONTEST,
      ParticipationTypes.PARTICIPATION_CONCERT,
    ],
    [],
  );

  const selectedType = useMemo(() => {
    if (relatedTableState && relatedTableState?.selectedRows.length) {
      return relatedTableState.selectedRows[0]['id:typ'];
    }
    return '';
  }, [relatedTableState]);

  const template = useMemo(
    () => participationTypes.find(type => type.eventCode === selectedType)?.template,

    [selectedType, participationTypes],
  );

  const look: F.ParticipationFormLook = {
    apiID: 'GetParticipation',
    template,
    type: selectedType,
    viewMode: true,
    editMode: false,
    id: String(id),
    relatedTableState,
    disabledEvent,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineEditTemplate);
