import { Dispatch, SetStateAction } from 'react';
import { Form } from 'types/models';
import * as R from 'ramda';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  preparedData: Form.Publication;
  name: string | undefined;
};

const setByResponse = ({ setFormFields, preparedData, name }: Props) => {
  const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
  const getRequiredLens = (fieldName: string) => R.lensPath([fieldName, 'required']);

  setFormFields(prevFields =>
    (R.pipe as any)(
      R.set(getValueLens('name'), preparedData.fields.name || name),
      R.set(getValueLens('doi'), preparedData.fields.doi),
      R.set(getValueLens('affiliation'), preparedData.affiliation),
      R.set(getValueLens('isLocal'), preparedData.isLocal),
      R.set(getValueLens('publicationDate'), preparedData.fields.publicationDate),
      R.set(getValueLens('doi'), preparedData.fields.doi),
      R.set(getValueLens('textLanguage'), {
        id: preparedData.fields.textLanguageId || '',
        label: preparedData.fields.textLanguageLabel || '',
      }),
      R.set(getValueLens('printPageCount'), preparedData.fields.printPageCount || undefined),
      R.set(getValueLens('vakSpeciality'), {
        id: preparedData.fields.vakSpecialityId || '',
        label: preparedData.fields.vakSpecialityLabel || '',
      }),
      R.set(getValueLens('degree'), {
        id: preparedData.fields.degreeId || '',
        label: preparedData.fields.degreeLabel || '',
      }),
      R.set(getValueLens('council'), preparedData.council),
      R.set(getValueLens('address'), preparedData.fields.address),
      R.set(getValueLens('defencePlace'), preparedData.fields.defencePlace),
      R.set(getValueLens('year'), preparedData.fields.year || new Date().getFullYear()),
      R.set(getValueLens('pageCount'), preparedData.fields.pageCount),
      R.set(getRequiredLens('pageCount'), preparedData.fields.isElectronic === 'false'),

      R.set(getValueLens('file'), preparedData.file),
      R.set(getValueLens('libraryLink'), preparedData.fields.libraryLink),
      R.set(getValueLens('defenceDate'), preparedData.fields.defenceDate),
      R.set(getValueLens('bibliographicRecord'), preparedData.fields.bibliographicRecord),

      R.set(getValueLens('foreignName'), preparedData.fields.foreignName),
      R.set(getValueLens('foreignAnnotation'), preparedData.fields.foreignAnnotation),
      R.set(getValueLens('foreignAuthorsKeyWords'), preparedData.fields.foreignAuthorsKeyWords),
      R.set(getValueLens('foreignAuthors'), preparedData.fields.foreignAuthors),
    )(prevFields),
  );
};

export { setByResponse };
