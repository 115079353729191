import React, { useCallback, useMemo } from 'react';

import { buttonIcons, CustomSelect, FormComponent, IconButtonProps, ListEdit } from 'components';

import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';
import { Table } from 'types/models';
import { PublicationProject } from 'types/models/Form';
import { Permits } from 'utils/Permissions';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';

type Props = {
  workMode: Table.WorkMode;
  projects: PublicationProject[];
  setLocalProjects: (rows: PublicationProject[]) => void;
  setLocalProjectsAccept: (id: string) => void;
  setLocalProjectsCancelAcception: (id: string) => void;
  relatedTableState: Table.State | undefined;
  isSingle?: boolean;
  isRequired?: boolean;
  selectTitle?: string | null;
  tooltipText?: string | undefined;
};

function Projects({
  workMode,
  projects,
  relatedTableState,
  setLocalProjects,
  setLocalProjectsAccept,
  setLocalProjectsCancelAcception,
  isSingle = false,
  isRequired = false,
  selectTitle,
  tooltipText,
}: Props) {
  const specifications = GetFullScienceProjectList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const project = useMemo<PublicationProject | null>(() => projects[0] || null, [projects]);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => PublicationProject>(row => {
    return {
      id: null,
      project: { id: row.id, value: row.completeLabel },
      accepted: '',
      acceptedBy: null,
    };
  }, []);

  const onChange = useCallback(
    (row: PublicationProject | null) => {
      if (row) {
        setLocalProjects([row]);
      }
    },
    [setLocalProjects],
  );

  const acceptButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.like,
        title: 'Принять к отчету',
        code: 'accept',
        isDisabled: !(projects.length && !project?.accepted) || workMode === 'viewMode',
        permissionName: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING,
        profilePermissionName: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING,
        onClick: (event: any) => {
          event?.currentTarget.blur();
          if (projects.length && !project?.accepted) {
            setLocalProjectsAccept(project?.project?.id || '');
          }
        },
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отменить принятие к отчету',
        code: 'cancel',
        isDisabled: !(projects.length && !!project?.accepted) || workMode === 'viewMode',
        permissionName: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING,
        profilePermissionName: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING,
        onClick: (event: any) => {
          event?.preventDefault();
          if (projects.length && !!project?.accepted) {
            setLocalProjectsCancelAcception(project.project?.id || '');
          }
        },
      },
    ],
    [project?.accepted, project?.project?.id, projects.length, setLocalProjectsAccept, setLocalProjectsCancelAcception, workMode],
  );

  const getIsEditProjectButtonDisabled = useCallback((row: PublicationProject | null) => !!row?.accepted, []);

  const getIsDeleteProjectButtonDisabled = useCallback((row: PublicationProject | null) => !!row?.accepted, []);

  return (
    <div>
      {isSingle ? (
        <FormComponent.Field tooltip={tooltipText} label={selectTitle ?? 'Сведение о проекте'} isRequired={isRequired}>
          <CustomSelect
            disabled={workMode === 'viewMode' || !!project?.accepted}
            original={!!project?.project ? { id: project?.project.id, name: project?.project.value } : null}
            relationTableModalTitle="Проекты"
            onChange={onChange}
            modalTableRowConverter={modalTableRowConverter}
            onDeleteFunction={() => setLocalProjects([])}
            modalTableSpecification={specifications}
            textareaPlaceholder="Выберите элемент из справочника"
            editPermissionName={Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT}
            customButtons={acceptButtons}
            isStatusText={!!project?.accepted}
            customStatus={!!project?.accepted ? `Принят к отчету, ${getAcceptInfo(project)}` : ''}
          />
        </FormComponent.Field>
      ) : (
        <ListEdit<object, PublicationProject>
          specification={{
            mode: 'relationTableModal',
            modalTableRowConverter,
            relationTableModalTitle: 'Проекты',
            modalTableSpecification: specifications,
          }}
          isModalHintHidden
          title="Проекты"
          rows={projects}
          columns={[
            {
              label: 'Название проекта',
              formatValue: row => row.project?.value || '',
              styles: { width: '75%' },
            },
            { label: 'Принят к отчету', formatValue: getAcceptInfo, styles: { width: '25%' } },
          ]}
          isToolbarDisabled={workMode === 'viewMode'}
          onChange={rows => setLocalProjects(rows)}
          withAcceptButtons
          onAccept={row => setLocalProjectsAccept(row.project?.id || '')}
          onAcceptanceCancel={row => setLocalProjectsCancelAcception(row.project?.id || '')}
          addPermissionName={Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT}
          editPermissionName={Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT}
          acceptPermissionName={Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING}
          getIsDeleteDisabled={getIsDeleteProjectButtonDisabled}
          getIsEditDisabled={getIsEditProjectButtonDisabled}
          visibleToolbarButtons={['add', 'delete']}
        />
      )}
    </div>
  );
}

export { Projects };
