import { Table } from 'types/models';
import { Toolbar } from './Toolbar';

export function GetPersonalOtherProjectList(): Table.Specification<object, object> {
  return {
    apiID: 'GetPersonalOtherProjectList',
    isFiltersHidden: true,
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: Toolbar,
      },
    },
    isWithQna: true,
  };
}
