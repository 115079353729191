import { useMemo } from 'react';
import { Report } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { buttonIcons, IconButtonProps } from 'components';
import { PurchaseRequest, PurchaseRequestStatus } from 'types/models/PurchaseRequest';

type Props = {
  purchaseRequest: PurchaseRequest | undefined;
  workMode: string;
  onSave(needClose: boolean, onSuccessCb?: (id: string | null) => void): void;
  onSaveAndContinue(needClose: boolean, onSuccessCb?: (id: string | null) => void): void;
  approvementButtons: IconButtonProps[];
};

export function useController(props: Props) {
  const { purchaseRequest, workMode, onSave, onSaveAndContinue, approvementButtons } = props;

  const reports = useMemo<Report[]>(() => {
    return [Reports.ServiceNotePurchase];
  }, []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const disabled = useMemo(() => {
    return workMode === 'viewMode';
  }, [workMode]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'save',
        isHidden: disabled,
        onClick: () => onSave(true),
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        code: 'saveDraft',
        isHidden: disabled,
        onClick: () => onSaveAndContinue(false),
      },
      ...approvementButtons.map(b => {
        return {
          ...b,
          isHidden: disabled || purchaseRequest?.status?.value !== PurchaseRequestStatus.CONSIDERATION,
        };
      }),
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [disabled, approvementButtons, getReports, handleSetCurrentReport, onSave, onSaveAndContinue, purchaseRequest?.status?.value],
  );

  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
