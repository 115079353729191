import { ListEdit } from 'components';
import { GetShortEventList } from 'features/Table/specifications';
import React, { memo, useCallback, useMemo } from 'react';
import { Event, Form, Table } from 'types/models';

type Props = {
  formFields: Form.Fields;
  events: Event.Event[];
  workMode: Table.WorkMode;
  disabled?: boolean;
  relatedTableState: Table.State | undefined;
  removeLocalEvents: (id: string) => void;
  getLocalEvent: (id: string) => void;
};

export const Conferences = memo(
  ({ formFields, workMode, events, relatedTableState, removeLocalEvents, getLocalEvent, disabled }: Props) => {
    const modalTableRowConverter = useCallback<(row: Table.Entry) => Event.Event>(
      row => ({
        id: row.id,
        name: row.name,
        status: { label: row.status, status: row['id:status'] },
        city: '',
        country: '',
        endDate: '',
        startDate: '',
        shortDescription: '',
        compilations: [],
        participationPublications: [],
      }),
      [],
    );

    const specifications = useMemo(() => {
      return GetShortEventList({
        templatesTableDependencies: relatedTableState && {
          relatedTableAPIID: 'GetMagazineList',
          relatedTableState,
        },
        requestData: {
          eventType: 'CONFERENCE',
          allEvents: true,
        },
        isDisableSource: true,
        isCompilationsPlanned: true,
        hasSelectButton: true,
        hasFilter: true,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (rows: Event.Event[]) => {
      const newRow = rows.find(({ id }) => !events.find(event => event.id === id));
      const removedRow = events.find(({ id }) => !rows.find(event => event.id === id));
      if (removedRow?.id) {
        removeLocalEvents(removedRow.id);
      }
      if (newRow?.id) {
        getLocalEvent(newRow.id);
      }
    };

    return (
      <div>
        <ListEdit
          specification={{
            mode: 'relationTableModal',
            modalTableRowConverter,
            relationTableModalTitle: 'Конференции',
            modalTableSpecification: specifications,
          }}
          isToolbarDisabled={!formFields.isConfirencesPublished.value || workMode === 'viewMode' || disabled}
          title="Конференции"
          isRequired={!!formFields.isConfirencesPublished.value}
          rows={events}
          onChange={onChange}
          visibleToolbarButtons={['add', 'delete']}
          columns={[
            {
              formatValue: row => row.shortDescription,
              label: 'Наименование, место и сроки проведения',
              styles: { width: '85%' },
            },
            {
              formatValue: row => row.status.label,
              label: 'Статус записи',
              styles: { width: '15%' },
            },
          ]}
        />
      </div>
    );
  },
);
