import React from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { CustomProps } from 'features/Table/specifications/GetSubjectList/LeftPanelForThirdLevel/controller';

const SubjectAddTemplate: React.FC<F.TemplateProps & CustomProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  subjectType,
  isSubject,
}: F.TemplateProps & CustomProps) => {
  const look: F.SubjectFormLook = {
    apiID: 'GetSubject',
    template: 'SubjectForm',
    relatedTableState,
    name,
    subjectType,
    isSubject,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Добавление ${isSubject ? 'тематики' : 'продукта'}`} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(SubjectAddTemplate);
