import React, { useState, useEffect, useCallback } from 'react';
import { block } from 'bem-cn';
import { useStream } from 'StreamRx';

import { Reference, ReferenceFilter, ListEdit, FormComponent, Collapse, TextArea, TextInput, TextInputMode } from 'components';

import { Form as F } from 'types/models';
import { GetReferenceElementList } from 'features/Table/specifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { streams as panelStreams } from '../streams';
import { PeriodInputs } from './PeriodInputs/PeriodInputs';
import { State } from '../makeUseCustomController';
import { Periods, Period, ThematicCategories, CitationSystem, SourceType } from '../types';
import { SecondLevelProps } from 'features/Table/types';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { showNotification } from 'features/Notifications';

import 'features/Table/specifications/style.scss';
import './style.scss';

type PeriodsValidationArgs = { row: Period | null; index: number | null; list: Periods; mode: 'view' | 'add' | 'edit' | null };

const b = block('component-for-second-level');

export const ComponentForSecondLevel = ({ customState }: SecondLevelProps<State>) => {
  const {
    isChangeFormOpen,
    selectedRow,
    isSettingEditing,
    publicationCitationSystem,
    citationSystemType,
    relatedRecordId,
    relatedTableSelectedRow,
    relatedTableApiId,
  } = customState;
  const publicationYear = relatedTableSelectedRow?.PublicationYear || '';
  const [settingPublicationCitationSystemID, setSettingPublicationCitationSystemID] = useState('');
  const [settingCitationSystem, setSettingCitationSystem] = useState<CitationSystem>({ id: '', label: '' });
  const [settingIdentifier, setSettingIdentifier] = useState('');
  const [settingUrl, setSettingUrl] = useState('');
  const [settingName, setSettingName] = useState('');
  const [settingSourceType, setSettingSourceType] = useState<SourceType>({ id: '', label: '' });
  const [settingPeriods, setSettingPeriods] = useState<Periods>([]);
  const [settingPeriodSingle, setSettingPeriodSingle] = useState<string>(publicationYear);
  const [settingThematicCategories, setSettingThematicCategories] = useState<ThematicCategories>([]);
  const [settingKeyWords, setSettingKeyWords] = useState('');

  useEffect(() => {
    if (isSettingEditing && selectedRow) {
      panelStreams.getPublicationCitationSystem.push({
        publicationCitationSystemID: selectedRow.id,
        sourceID: selectedRow.SourceId,
      });
    }
  }, [isSettingEditing, selectedRow]);

  useEffect(() => {
    if (publicationCitationSystem) {
      setSettingPublicationCitationSystemID(
        publicationCitationSystem?._attributes?.id ? String(publicationCitationSystem?._attributes?.id) : '',
      );
      if (publicationCitationSystem.CitationSystem)
        setSettingCitationSystem({
          id: publicationCitationSystem.CitationSystem._attributes.id,
          label: publicationCitationSystem.CitationSystem._attributes.label,
        });
      else setSettingCitationSystem({ id: '', label: '' });
      setSettingIdentifier(publicationCitationSystem.Identifier?._text || '');
      setSettingUrl(publicationCitationSystem.Url?._text || '');
      setSettingName(publicationCitationSystem.Name?._text || '');
      if (publicationCitationSystem.SourceType)
        setSettingSourceType({
          id: publicationCitationSystem.SourceType._attributes.id,
          label: publicationCitationSystem.SourceType._attributes.label,
        });
      else setSettingSourceType({ id: '', label: '' });
      if (publicationCitationSystem.Periods && publicationCitationSystem.Periods.Period)
        if (publicationCitationSystem.Periods.Period[0]) {
          const periods: Periods = [];
          Array.prototype.forEach.call(publicationCitationSystem.Periods.Period, (e: any) => {
            periods.push({
              id: e._attributes.id,
              startYear: e.StartYear._text,
              endYear: relatedTableApiId === 'GetMagazineList' ? e.EndYear?._text || '' : '',
            });
          });
          periods.sort((x, z) => (x.startYear < z.startYear ? 1 : -1));
          setSettingPeriods(periods);
          setSettingPeriodSingle(periods[0].startYear);
        } else {
          const period: Period = {
            id: publicationCitationSystem.Periods.Period._attributes.id,
            startYear: publicationCitationSystem.Periods.Period.StartYear._text,
            endYear: relatedTableApiId === 'GetMagazineList' ? publicationCitationSystem.Periods.Period.EndYear?._text || '' : '',
          };
          setSettingPeriods([period]);
          setSettingPeriodSingle(period.startYear);
        }
      else {
        setSettingPeriods([]);
        setSettingPeriodSingle(publicationYear);
      }
      if (publicationCitationSystem.ThematicCategories.ThematicCategory)
        if (publicationCitationSystem.ThematicCategories.ThematicCategory[0]) {
          const thematicCategories: ThematicCategories = [];
          Array.prototype.forEach.call(publicationCitationSystem.ThematicCategories.ThematicCategory, (e: any) => {
            thematicCategories.push({
              id: e._attributes.id,
              code: e.ThematicCategory.code._text,
              name: e.ThematicCategory.name._text,
              value: {
                id: e.ThematicCategory._attributes.id,
                label: e.ThematicCategory._attributes.label,
              },
            });
          });
          thematicCategories.sort((x, z) => (x.code > z.code ? 1 : -1));
          setSettingThematicCategories(thematicCategories);
        } else
          setSettingThematicCategories([
            {
              id: publicationCitationSystem.ThematicCategories.ThematicCategory._attributes.id,
              code: publicationCitationSystem.ThematicCategories.ThematicCategory.ThematicCategory.code._text,
              name: publicationCitationSystem.ThematicCategories.ThematicCategory.ThematicCategory.name._text,
              value: {
                id: publicationCitationSystem.ThematicCategories.ThematicCategory.ThematicCategory._attributes.id,
                label: publicationCitationSystem.ThematicCategories.ThematicCategory.ThematicCategory._attributes.label,
              },
            },
          ]);
      else setSettingThematicCategories([]);
      setSettingKeyWords(publicationCitationSystem.KeyWords?._text || '');
    }
  }, [publicationCitationSystem, publicationYear, relatedTableApiId]);

  const resetComponentState = useCallback(() => {
    panelStreams.toggleSettingEditing.push(false);
    setSettingPublicationCitationSystemID('');
    setSettingCitationSystem({ id: '', label: '' });
    setSettingIdentifier('');
    setSettingUrl('');
    setSettingName('');
    setSettingSourceType({ id: '', label: '' });
    setSettingPeriods([]);
    setSettingPeriodSingle(publicationYear);
    setSettingThematicCategories([]);
    setSettingKeyWords('');
  }, [publicationYear]);

  const handleCitationSystemFieldChange = useCallback((value: any) => {
    setSettingCitationSystem({ id: value.id, label: value.label });
    setSettingSourceType({ id: '', label: '' });
  }, []);

  const handleSourceTypeFieldChange = useCallback((value: any) => {
    setSettingSourceType({ id: value.id, label: value.label });
  }, []);

  const handleIdentifierFieldChange = useCallback((value: string) => {
    setSettingIdentifier(value);
  }, []);

  const handleUrlFieldChange = useCallback((value: string) => {
    setSettingUrl(value);
  }, []);

  const handleNameFieldChange = useCallback((value: string) => {
    setSettingName(value);
  }, []);

  const handlePeriodsFieldChange = useCallback((value: Periods) => {
    setSettingPeriods(value);
  }, []);

  const handlePeriodSingleFieldChange = useCallback((value: string) => {
    setSettingPeriodSingle(value);
  }, []);

  const handleThematicCategoriesFieldChange = useCallback((value: ThematicCategories) => {
    setSettingThematicCategories(value);
  }, []);

  const handleKeyWordsFieldChange = useCallback((value: string) => {
    setSettingKeyWords(value);
  }, []);

  const throwErrorNotification = useCallback((message: string) => {
    showNotification({ message, theme: 'danger' });
  }, []);

  function periodIntereSectionCheck({ row, index, list, mode }: PeriodsValidationArgs): boolean {
    let tmp = true;
    if (!row) {
      return false;
    }
    Array.prototype.forEach.call(list, (record: Period, currentIndex) => {
      if (
        (mode === 'edit' ? index === null || index !== currentIndex : true) &&
        (row.startYear < record.endYear || !record.endYear) &&
        (record.startYear < row.endYear || !row.endYear)
      )
        tmp = false;
    });
    return tmp;
  }

  function periodValidation({ row, index, list, mode }: PeriodsValidationArgs): boolean {
    let tmp = true;
    const currentDate = new Date();
    const startYear = Number(row?.startYear) || 0;
    const endYear = Number(row?.endYear) || 0;
    const currentYear = currentDate.getFullYear();

    if (!row) {
      throwErrorNotification('Заполните обязательные поля');
      return false;
    }

    if (!startYear) {
      throwErrorNotification('Заполните обязательные поля');
      tmp = false;
    } else if (startYear < 1900 || startYear > currentYear || (endYear && (endYear < 1900 || endYear > currentYear))) {
      throwErrorNotification('Временной промежуток может быть между 1900 и текущим годом');
      tmp = false;
    } else if (endYear && startYear > endYear) {
      throwErrorNotification('Год окончания не может быть меньше года начала');
      tmp = false;
    } else if (!periodIntereSectionCheck({ row, index, list, mode })) {
      throwErrorNotification('Введенный период пересекается или совпадает с уже существующим');
      tmp = false;
    }
    return tmp;
  }

  const periodSingleValidation = useCallback(
    (startYearString: string) => {
      let tmp = true;
      const startYear = Number(startYearString);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      if (!startYear) {
        throwErrorNotification('Заполните обязательные поля');
        tmp = false;
      } else if (startYear < Number(publicationYear) || startYear > currentYear) {
        throwErrorNotification('Временной промежуток может быть между годом издания и текущим годом');
        tmp = false;
      }
      return tmp;
    },
    [publicationYear, throwErrorNotification],
  );

  const saveForm = useCallback(
    (reset: boolean = false) => {
      if (
        settingCitationSystem &&
        settingIdentifier &&
        (citationSystemType === 'CHILD_GRID' ||
          citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code ||
          (settingPeriods && settingPeriods[0] && citationSystemType === 'MAGAZINE') ||
          (settingPeriodSingle && citationSystemType === 'PARENTAL_GRID'))
      ) {
        if (citationSystemType !== 'PARENTAL_GRID' || periodSingleValidation(settingPeriodSingle)) {
          const citationSystemObject = {
            publicationID: relatedRecordId,
            publicationCitationSystemID: settingPublicationCitationSystemID,
            citationSystem: settingCitationSystem,
            identifier: settingIdentifier,
            url: settingUrl,
            name: settingName,
            sourceType: settingSourceType,
            ...(citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID'
              ? {
                  periods:
                    citationSystemType === 'MAGAZINE'
                      ? settingPeriods
                      : [{ id: '', startYear: settingPeriodSingle, endYear: '' }],
                }
              : {}),
            thematicCategories: settingThematicCategories,
            keyWords: settingKeyWords,
            citationSystemType,
          };
          if (reset) {
            panelStreams.savePublicationCitationSystem.push(citationSystemObject);
          } else {
            panelStreams.saveAndContinuePublicationCitationSystem.push(citationSystemObject);
          }
        }
      } else {
        showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      }
    },
    [
      settingCitationSystem,
      settingIdentifier,
      citationSystemType,
      settingPeriods,
      settingPeriodSingle,
      periodSingleValidation,
      relatedRecordId,
      settingPublicationCitationSystemID,
      settingUrl,
      settingName,
      settingSourceType,
      settingThematicCategories,
      settingKeyWords,
    ],
  );

  const handleSaveButtonClick = useCallback(() => {
    saveForm(true);
  }, [saveForm]);

  const handleSaveAndContinueButtonClick = useCallback(() => {
    saveForm();
  }, [saveForm]);

  const handleCancelButtonClick = useCallback(() => {
    panelStreams.toggleChangeForm.push();
    resetComponentState();
  }, [resetComponentState]);

  const formFields = {
    citationSystem: {
      value: settingCitationSystem,
      required: true,
      isValid: true,
      title: 'Система цитирования',
      onChange: handleCitationSystemFieldChange,
    },
    sourceType: {
      value: settingSourceType,
      isValid: true,
      title: citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID' ? 'Тип издания' : 'Тип статьи',
      onChange: handleSourceTypeFieldChange,
    },
    identifier: {
      value: settingIdentifier,
      required: true,
      isValid: true,
      title: 'Идентификатор',
      onChange: handleIdentifierFieldChange,
    },
    url: {
      value: settingUrl,
      isValid: true,
      title: 'URL',
      onChange: handleUrlFieldChange,
    },
    name: {
      value: settingName,
      isValid: true,
      title: 'Название в БЦ',
      onChange: handleNameFieldChange,
    },
    periods: {
      value: settingPeriods,
      required: true,
      isValid: true,
      title: 'Периоды',
      onChange: handlePeriodsFieldChange,
    },
    periodSingle: {
      value: settingPeriodSingle,
      required: true,
      isValid: true,
      title: 'Индексируется с',
      onChange: handlePeriodSingleFieldChange,
    },
    thematicCategories: {
      value: settingThematicCategories,
      isValid: true,
      title: 'Тематические категории',
      onChange: handleThematicCategoriesFieldChange,
    },
    keyWords: {
      value: settingKeyWords,
      isValid: true,
      title: 'Ключевые слова',
      onChange: handleKeyWordsFieldChange,
    },
  } as F.Fields;

  const sourceTypeFilterItem: ReferenceFilter = {
    key: 'citationSystem',
    values: [settingCitationSystem?.id] as string[],
  };
  const sourceTypeFilter: ReferenceFilter[] = [sourceTypeFilterItem];

  useStream(
    () => panelStreams.toggleResetNeeded,
    isNeeded => {
      if (isNeeded) {
        panelStreams.toggleChangeForm.push();
        resetComponentState();
      }
    },
    [],
  );

  return (
    <Collapse
      isCollapse={
        isChangeFormOpen &&
        Boolean(
          !isSettingEditing ||
            settingPublicationCitationSystemID ||
            (!settingPublicationCitationSystemID &&
              (citationSystemType === 'CHILD_GRID' || citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code) &&
              settingCitationSystem.label),
        )
      }
    >
      <FormComponent.Template>
        <form>
          <ControlPanel
            handleSave={handleSaveButtonClick}
            handleSaveAsDraft={handleSaveAndContinueButtonClick}
            handleCancel={handleCancelButtonClick}
          />

          <FormComponent.Wrapper>
            <FormComponent.ColumnWrapper>
              <FormComponent.Column>
                <FormComponent.Line lineSize="padded">
                  <FormComponent.Field label={formFields.citationSystem.title}>
                    <Reference
                      name="RefCitationSystem"
                      isRequired
                      value={formFields.citationSystem.value}
                      onChange={formFields.citationSystem.onChange}
                      disabled={isSettingEditing}
                      relationTableModalTitle='Справочник "Системы цитирования"'
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="triplePadded">
                  <FormComponent.Field label={formFields.identifier.title} isRequired>
                    <TextInput value={formFields.identifier.value} onChange={formFields.identifier.onChange} />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column>
                <FormComponent.Line>
                  <FormComponent.Field label={formFields.sourceType.title}>
                    <>
                      {citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID' ? (
                        <Reference
                          key={settingCitationSystem.label}
                          name="RefCitationSystemSourceType"
                          value={formFields.sourceType.value}
                          refButtonTitle="Типы изданий в базах цитирования"
                          filters={sourceTypeFilter}
                          onChange={formFields.sourceType.onChange}
                          disabled={!settingCitationSystem.label}
                          relationTableModalTitle="Типы изданий в базах цитирования"
                        />
                      ) : (
                        <Reference
                          key={settingCitationSystem.label}
                          name="RefCitationSystemArticleType"
                          value={formFields.sourceType.value}
                          refButtonTitle="Типы статей в базах цитирования"
                          filters={sourceTypeFilter}
                          onChange={formFields.sourceType.onChange}
                          disabled={!settingCitationSystem.label}
                          relationTableModalTitle="Типы статей в базах цитирования"
                        />
                      )}
                    </>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="padded">
                  <FormComponent.Field label={formFields.url.title}>
                    <TextInput mode={TextInputMode.url} value={formFields.url.value} onChange={formFields.url.onChange} />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>

            {(citationSystemType === 'CHILD_GRID' || citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code) && (
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label={formFields.keyWords.title}>
                  <TextArea settings={{ rows: 3 }} value={formFields.keyWords.value} onChange={formFields.keyWords.onChange} />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
            {(citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID') && (
              <>
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label={formFields.name.title}>
                    <TextInput value={formFields.name.value} onChange={formFields.name.onChange} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="doublePadded">
                  <div className={b('line')}>
                    <div className={b('line_periods')}>
                      {citationSystemType === 'MAGAZINE' ? (
                        <ListEdit
                          title="Периоды"
                          isRequired
                          rows={settingPeriods}
                          onChange={handlePeriodsFieldChange}
                          columns={[
                            { label: 'С', formatValue: row => row.startYear },
                            { label: 'По', formatValue: row => row.endYear },
                          ]}
                          specification={{
                            mode: 'customComponent',
                            validation: {
                              checkIsValid: (row, index, list, mode) => periodValidation({ row, index, list, mode }),
                            },
                            renderComponent: (row, onChange) => <PeriodInputs value={row} onChange={onChange as any} />,
                          }}
                          withMessages
                          isDeleteConfirmEnabled
                        />
                      ) : (
                        <FormComponent.Field label={formFields.periodSingle.title} isRequired>
                          <TextInput value={formFields.periodSingle.value} onChange={formFields.periodSingle.onChange} />
                        </FormComponent.Field>
                      )}
                    </div>
                    <div className={b('line_thematic-categories')}>
                      <ListEdit
                        title="Тематические категории"
                        isToolbarDisabled={!settingCitationSystem.label}
                        rows={settingThematicCategories}
                        onChange={handleThematicCategoriesFieldChange}
                        columns={[
                          { label: 'Код', formatValue: row => row.code },
                          { label: 'Наименование', formatValue: row => row.name },
                        ]}
                        specification={{
                          mode: 'relationTableModal',
                          relationTableModalTitle: 'Справочник "Тематические категории"',
                          modalTableRowConverter: row => ({
                            value: { id: row.id, label: row.label },
                            code: row.code,
                            name: row.name,
                            id: '',
                          }),
                          modalTableSpecification: GetReferenceElementList({
                            requestData: {
                              filters: [{ key: 'citationSystem', values: [settingCitationSystem.id] }],
                              name: 'RefThematicCategory',
                            },
                          }),
                        }}
                        withMessages
                        isDeleteConfirmEnabled
                      />
                    </div>
                  </div>
                </FormComponent.Line>
              </>
            )}
          </FormComponent.Wrapper>
        </form>
      </FormComponent.Template>
    </Collapse>
  );
};
