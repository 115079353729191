import React, { useCallback } from 'react';

import { FormComponent, InputSelect, TextInput } from 'components';

import { JointEvent } from 'types/models';
import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';

import { FlatParticipantAward } from '../helpers';
import { getEnum } from 'utils/Helpers';

type Props = {
  row: FlatParticipantAward | null;
  onChange(e: FlatParticipantAward): void;
  participants: JointEvent.Participant[];
};

export function AwardFields({ row, onChange, participants }: Props) {
  const { enumMap } = useAppDataContext();

  const categoryOptions = getEnum('AwardCategory', enumMap);

  const participantValue: Item = {
    label: row?.person?.shortName || row?.person?.fullName || '',
    value: row?.person?.id || '',
  };
  const participantsOptions: Item[] = participants.map(x => ({
    label: x.person?.shortName || x.person?.fullName || '',
    value: x.person?.id || '',
  }));
  const handlePersonChange = useCallback(
    (e: Item) => {
      const value = participants.find(x => x.person?.id === e.value) || null;
      onChange({
        ...row,
        id: row?.id || null,
        award: row?.award || { id: null, category: null, name: '' },
        person: value?.person || null,
        academicRank: value?.academicRank || null,
        citizenship: value?.citizenship || null,
        degree: value?.degree || null,
        education: value?.education || null,
        historyLabel: value?.historyLabel || '',
        job: value?.job || null,
        rank: value?.rank || null,
      });
    },
    [onChange, participants, row],
  );

  const handleCategoryChahge = useCallback(
    (e: Item) => {
      onChange({
        ...row,
        award: {
          ...row?.award,
          category: e,
        } as JointEvent.Award,
      } as FlatParticipantAward);
    },
    [onChange, row],
  );

  const handleNameChange = useCallback(
    (value: string) => {
      onChange({
        ...row,
        award: {
          ...row?.award,
          name: value,
        } as JointEvent.Award,
      } as FlatParticipantAward);
    },
    [onChange, row],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Получатель награды" isRequired>
        <InputSelect value={participantValue} options={participantsOptions} onSelectChange={handlePersonChange} />
      </FormComponent.Field>
      <FormComponent.Field label="Категория награды" isRequired>
        <InputSelect value={row?.award?.category || null} options={categoryOptions} onSelectChange={handleCategoryChahge} />
      </FormComponent.Field>
      <FormComponent.Field label="Название награды" isRequired>
        <TextInput value={row?.award?.name || ''} onChange={handleNameChange} />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}
