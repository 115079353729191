type EventTypeParameter = {
  title: string;
  modalTitle: string;
};
export type EventTypeName = 'CONFERENCE' | 'EXPOSITION' | 'CONTEST' | 'CONCERT';

export const eventsList: Record<EventTypeName, EventTypeParameter> = {
  CONFERENCE: {
    title: 'Сведения об участниках конференции',
    modalTitle: 'Добавление сведений об участнике конференции',
  },
  EXPOSITION: {
    title: 'Сведения об участниках выставки',
    modalTitle: 'Добавление сведений об участнике выставки',
  },
  CONTEST: {
    title: 'Сведения об участниках конкурса',
    modalTitle: 'Добавление сведений об участнике конкурса',
  },
  CONCERT: {
    title: 'Сведения об участниках творческого мероприятия',
    modalTitle: 'Добавление сведений об участнике творческого мероприятия',
  },
};
