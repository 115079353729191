import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, Toolbar, buttonIcons, ConfirmPopup } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import * as modalTemplates from './modalTemplates';
import { useLocalTableStreams } from 'features/Table/hooks';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const tableStreams = useLocalTableStreams();

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState]);

  const { userPermission } = useAppDataContext();

  const isCreator = useMemo(() => {
    return !!(row !== null && row.IsCreator === 'true');
  }, [row]);

  const isDeleteButtonDisabled = useMemo(() => {
    if (row) {
      if (isCreator || isHasPermission(userPermission, Permits.QNA_ADMINISTRATE)) {
        return false;
      }
    }
    return true;
  }, [row, isCreator, userPermission]);

  const handleAddButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0 && tableState.selectedRows.length < 2) {
      setIsAddFormOpen(true);
    } else {
      showNotification({ message: 'Выберите один вопрос', theme: 'danger' });
    }
  }, [tableState]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);
  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteQnaQuestion',
      deletedItemPropName: 'QnaQuestion',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Ответить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
      },
    ],
    [handleAddButtonClick, handleDeleteButtonClick, isDeleteButtonDisabled],
  );

  return (
    <>
      <modalTemplates.QnaAnswerAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text="Вы точно хотите удалить выбранный вопрос?"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
