import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';
import { Table } from 'types/models';
import { DataGrid } from 'features/Table';
import {
  GetScientistColleaguePublicationList,
  GetScientistColleagueParticipationList,
  GetScientistColleagueEventList,
  GetScientistColleagueRequestAndProjectList,
  GetScientistColleagueSecurityDocumentList,
} from 'features/Table/specifications';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const {
    tableState,
    customState: { scientistId },
  } = props;
  const {
    activePerson,
    buttons,
    isPublicationListOpen,
    handleClosePublicationsForm,
    isParticipationListOpen,
    handleCloseParticipationsForm,
    isEventListOpen,
    handleCloseEventsForm,
    isRequestListOpen,
    handleCloseRequestsForm,
    isDocumentListOpen,
    handleCloseDocumentsForm,
  } = useController({ tableState });
  return (
    <>
      <Modal
        isOpen={isPublicationListOpen}
        onClose={handleClosePublicationsForm}
        title="Список совместных публикаций"
        size="extralarge"
      >
        <DataGrid
          specification={GetScientistColleaguePublicationList({
            colleagueScientistId: activePerson,
            scientistId: scientistId || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isParticipationListOpen}
        onClose={handleCloseParticipationsForm}
        title="Список соучастий в мероприятиях"
        size="extralarge"
      >
        <DataGrid
          specification={GetScientistColleagueParticipationList({
            colleagueScientistId: activePerson,
            scientistId: scientistId || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isEventListOpen}
        onClose={handleCloseEventsForm}
        title="Список совместно организованных мероприятий"
        size="extralarge"
      >
        <DataGrid
          specification={GetScientistColleagueEventList({
            colleagueScientistId: activePerson,
            scientistId: scientistId || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isRequestListOpen}
        onClose={handleCloseRequestsForm}
        title="Список заявок и проектов НИР и ОКР"
        size="extralarge"
      >
        <DataGrid
          specification={GetScientistColleagueRequestAndProjectList({
            colleagueScientistId: activePerson,
            scientistId: scientistId || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isDocumentListOpen}
        onClose={handleCloseDocumentsForm}
        title="Список объектов интеллектуальной собственности"
        size="extralarge"
      >
        <DataGrid
          specification={GetScientistColleagueSecurityDocumentList({
            colleagueScientistId: activePerson,
            scientistId: scientistId || '',
          })}
        />
      </Modal>

      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
