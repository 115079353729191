import React from 'react';

import { ConfirmPopup, TextGridSelect } from 'components';

import { Partner } from 'types/models';

import { GetPartnerList } from 'features/Table/specifications';
import useController from './controller';

type Props = {
  partner: Partner | null;
  setPartner(partner: Partner | null): void;
};

export function SelectPartner({ partner, setPartner }: Props) {
  const { isLoading, isInfoPopupOpen, onSelectRow, onCloseInfoPopup, onConfirmInfoPopup } = useController();

  return (
    <>
      <TextGridSelect
        title="Партнёры"
        specification={GetPartnerList({
          withMenu: true,
          showButtons: ['add'],
          parameters: [{ name: 'extended', value: false }],
          hasSelectButton: true,
        })}
        value={partner}
        valueFormatter={x => (x as Partner)?.fullName ?? ''}
        onChange={setPartner}
        settings={{
          mode: 'loadTableRow',
          onSelectRow,
        }}
      />
      <ConfirmPopup
        isOpen={isInfoPopupOpen}
        onConfirm={onConfirmInfoPopup}
        onClose={onCloseInfoPopup}
        icon="info"
        title="Выбран элемент справочника"
        okButtonText="Да"
        resetButtonText="Отмена"
        isDisabled={isLoading}
      >
        <span>
          Вы выбрали элемент справочника организаций-заказчиков, на данный момент выбранная организация не заведена в системе как
          партнёр.
          <br />
          Создать партнёра из выбранной организации со статусом &quot;Действующий&quot;?
        </span>
      </ConfirmPopup>
    </>
  );
}
