import { useState, useLayoutEffect, useCallback } from 'react';

import { Rntd } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import type { Subject, Document } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { SubjectFormLook } from 'types/models/Form';
import { SecurityDocumentMemberType, SubjectType } from 'utils/Enums';
import { ForeignSecurityDocument } from 'types/models/Subject';

import { getMockSubject } from './helpers';

import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
};

export function useController({ onClose }: Props) {
  const [subject, setSubject] = useState<Subject>(getMockSubject());

  const tableStreams = useLocalTableStreams();

  const {
    look: { id, subjectType, isSubject, viewMode },
  } = useFormContext<SubjectFormLook>();

  const { methods: getSubject } = BackendAPI.useBackendAPI('GetSubject', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Subject;
      setSubject(preparedData);
    },
  });

  const { methods: saveSubject } = BackendAPI.useBackendAPI('SaveSubject', {
    onSuccessfullCall: () => {
      if (subjectType === SubjectType.SUBJECT) showNotification({ message: 'Тематика успешно сохранена', theme: 'success' });
      if (subjectType === SubjectType.PRODUCT) showNotification({ message: 'Продукт успешно сохранен', theme: 'success' });
      onClose();
      tableStreams.reloadTable.push();
    },
  });

  const getApplicantsInfo = useCallback((ref: ForeignSecurityDocument) => {
    const value: string | undefined = ref.members
      .filter(x => x.type?.value === SecurityDocumentMemberType.APPLICANT)
      ?.map(x => x.name)
      .join(', ');
    return value || ref.applicants || '';
  }, []);

  const getAuthorsInfo = useCallback((ref: ForeignSecurityDocument) => {
    const value: string | undefined = ref.members
      .filter(x => x.type?.value === SecurityDocumentMemberType.AUTHOR)
      ?.map(x => x.name)
      .join(', ');
    return value || ref.authors || '';
  }, []);

  const handleCodeFieldChange = useCallback(
    (value: string) => {
      setSubject({ ...subject, code: value });
    },
    [subject],
  );

  const handleNameFieldChange = useCallback(
    (value: string) => {
      setSubject({ ...subject, name: value });
    },
    [subject],
  );

  const handleDescriptionFieldChange = useCallback(
    (value: string) => {
      setSubject({ ...subject, description: value });
    },
    [subject],
  );

  const handleRntdsFieldChange = useCallback(
    (e: Rntd[]) => {
      setSubject({ ...subject, rntds: e });
    },
    [subject],
  );

  const handleDocumentsChange = useCallback((e: Document[]) => {
    setSubject((prevState: Subject) => ({ ...prevState, documents: e }));
  }, []);

  const handleForeignSecurityDocumentsChange = useCallback((e: ForeignSecurityDocument[]) => {
    setSubject((prevState: Subject) => ({ ...prevState, foreignSecurityDocuments: e }));
  }, []);

  const onSubmit = useCallback(() => {
    if (subject.code && subject.name) {
      saveSubject.callAPI({ ...subject, type: { value: subjectType, label: '' } });
    } else showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
  }, [saveSubject, subject, subjectType]);

  useLayoutEffect(() => {
    if (id) getSubject.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    subject,
    handleCodeFieldChange,
    handleNameFieldChange,
    handleDescriptionFieldChange,
    handleRntdsFieldChange,
    onSubmit,
    isSubject,
    subjectId: id ?? '-1',
    viewMode,
    handleDocumentsChange,
    handleForeignSecurityDocumentsChange,
    getApplicantsInfo,
    getAuthorsInfo,
  };
}
