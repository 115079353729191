export type State = {
  enterpriseCustomerIds: string[];
  enterpriseIds: string[];
};

export function makeUseCustomController(enterpriseCustomerIds: string[], enterpriseIds: string[]) {
  return function useCustomController(): State {
    return {
      enterpriseCustomerIds,
      enterpriseIds,
    };
  };
}
