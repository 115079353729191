import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Tender } from 'types/models';
import { convertTenderToXML } from 'services/BackendAPI/configurations/tenders/requestConverters';
import { convertMaybeArray, convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { convertTender } from './converters';
import { Settings } from 'types/models/common';

export const tenderConfigurations = {
  SaveTender: makeBackendAPIConfiguration({
    id: 'SaveTender',
    endpoint: '/msa/service/commands/SaveTender',
    convertInputToXMLElement: (inputData: Tender.Tender) => ({
      Request: {
        _attr: {
          commandName: 'SaveTender',
        },
        Tender: { ...convertTenderToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetTender: makeBackendAPIConfiguration({
    id: 'GetTender',
    endpoint: '/msa/service/commands/GetTender',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetTender',
          },
          Tender: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertTender(response.Response.Tender, settings),
  }),
  GetNoticeTenders: makeBackendAPIConfiguration({
    id: 'GetNoticeTenders',
    endpoint: '/msa/service/commands/GetNoticeTenders',
    convertInputToXMLElement: () => {
      return {
        Request: {
          _attr: {
            commandName: 'GetNoticeTenders',
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      response.Response.Tender ? convertMaybeArray(response.Response.Tender || [], i => convertTender(i, settings)) : [],
  }),
};
