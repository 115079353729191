import { ReferenceItem } from 'components';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

import { Person } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';
import { JobStatus } from 'utils/Enums';
import { getEnumItem } from 'utils/Helpers';
import { Permits } from 'utils/Permissions';

type Props = {
  initialCountryCode: ReferenceItem | null;
};

export const getMockEducation = (): Person.ScientistEducation => {
  return {
    originalId: '',
    id: null,
    status: { label: '', value: '' },
    enterprise: '',
    deletionDate: '',
    deletedBy: '',
    creationDate: '',
    creationSource: '',
    modificationDate: '',
    modificationSource: '',
    dateReceipt: '',
    dateDismissal: null,
    refSpeciality: null,
    refDepartment: null,
    department: null,
    speciality: null,
    isTSU: false,
    isReadOnly: false,
    isActive: false,
  };
};

export const getMockJob = (): Person.ScientistJob => {
  const { userPermission, enumMap, settings } = useAppDataContext();

  return {
    originalId: '',
    id: null,
    enterpise: settings?.organization?.shortName || '',
    creationDate: '',
    creationSource: '',
    modificationDate: '',
    modificationSource: '',
    deletionDate: '',
    deletedBy: '',
    dateReceipt: '',
    dateDismissal: null,
    department: null,
    isActive: false,
    isReadOnly: false,
    status: isHasPermission(userPermission, Permits.PERSON_LOCAL_JOB_CREATE)
      ? getEnumItem('JobStatus', JobStatus.LOCAL, enumMap)
      : getEnumItem('JobStatus', JobStatus.OTHER, enumMap),
    appointmentCategory: null,
    staffGroup: null,
    appointment: null,
    refAppointment: null,
    refDepartment: null,
    civilContractName: null,
    civilContractNumber: null,
    civilContractDate: null,
    staffState: null,
  };
};

export function getMockPerson({ initialCountryCode }: Props): Person.ScientistPerson {
  return {
    academicRanks: [],
    bibliographicNames: [],
    degrees: [],
    fullName: '',
    id: '',
    identifiers: [],
    ranks: [],
    scientistEducations: [],
    scientistJobs: [],
    shortName: '',
    keywordsEnglish: '',
    keywordsRus: '',
    hirshIndexes: [],
    grntis: [],
    udks: [],
    oecds: [],
    domainKnowledges: [],
    activities: '',
    scientificResults: '',
    vakSpecialities: [],
    communityMembers: [],
    changes: [],
    affiliations: [],
    calculations: null,
    avatar: EMPTY_FILE,
    scientist: getMockScientist({ initialCountryCode }),
  };
}

export const getMockScientist = ({ initialCountryCode }: Props): Person.Scientist => ({
  id: '',
  fullName: '',
  averageFiveYearImpactFactor: '',
  averageImpactFactor: '',
  calculateFiveYearImpactFactor: '',
  calculateImpactFactor: '',
  citations: '',
  dateBirth: '01.01.1900',
  citizenship: initialCountryCode,
  englishFirstName: '',
  englishLastName: '',
  englishPatronymic: '',
  firstName: '',
  fromBus: false,
  gender: 'MALE',
  guid: '',
  inn: '',
  lastName: '',
  patronymic: '',
  status: '',
  statusFull: '',
  snils: '',
  mobilePhone: '',
  email: '',
  phone: '',
  mobilePhoneIntegration: '',
  phoneIntegration: '',
  emailIntegration: '',
  note: '',
});

export function validateMainFields(person: Person.ScientistPerson) {
  const isValidLastName = Boolean(person.scientist?.lastName);
  const isValidFirstName = Boolean(person.scientist?.firstName);
  const isValidDateBirth = Boolean(person.scientist?.dateBirth);
  const isFilledJobsOrEducation = Boolean(person.scientistEducations.length || person.scientistJobs.length);

  return [
    { isValid: isValidLastName, invalidMessage: 'Заполните фамилию' },
    { isValid: isValidFirstName, invalidMessage: 'Заполните имя' },
    { isValid: isValidDateBirth, invalidMessage: 'Заполните дату рождения' },
    { isValid: isFilledJobsOrEducation, invalidMessage: 'Заполните сведения о работе или об обучении' },
  ];
}
