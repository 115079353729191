import React, { useCallback, useMemo, useState } from 'react';

import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, IconButton, ConfirmPopup } from 'components';

function Toolbar() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
    ],
    [handleHelpButtonClick],
  );

  return (
    <>
      <ConfirmPopup
        title="Объекты интеллектуальной собственности университета"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Раздел содержит список патентов, свидетельств на изобретения, промышленные образцы, базы данных, программные продукты
            и прочие объекты, в которых университет является правообладателем.
          </p>
          <p>
            В списке не отображаются ноу-хау (секрет производства — это сведения любого характера, о результатах интеллектуальной
            деятельности в научно-технической сфере и о способах осуществления профессиональной деятельности, имеющие
            действительную или потенциальную коммерческую ценность)
          </p>
          <p>
            Фильтр <strong>Действующие</strong> показывает список охранных документов, у которых на текущую дату не завершён срок
            действия.
          </p>
          <p>Используйте строку поиска по словам в столбцах таблицы для отбора ОИС по тематике, направлениям исследований.</p>
          <p>
            Список выбранных ОИС вы можете выгрузить в файл с помощью кнопки Выгрузить в файл&nbsp;
            <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
          </p>
        </div>
      </ConfirmPopup>
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
