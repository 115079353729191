import { formatJob, formatEducation } from 'features/Form/views/AboutAuthorship/helpers';

import { Payment } from 'types/models';
import { ArticleType } from 'types/models/Article';

import { EditableNominee, LocalAuthorPayment, ShortJob } from './model';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

export function validateForm(authorPayment: LocalAuthorPayment | null) {
  if (!authorPayment) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const { regulationName, regulationParagraph, maxFundMoney, maxNomineesMoney } = authorPayment;
  const isValidRegularParagraph = Boolean(regulationParagraph);
  const isValidRegulationName = Boolean(regulationName);
  const isValidMaxNomineesMoney = Boolean(maxNomineesMoney);
  const isValidMaxFundMoney = Boolean(maxFundMoney);
  const isMaxFundMoneyMoreZero = Number(maxFundMoney) > 0;
  const isValidMonth = Boolean(authorPayment.month);
  const isValidYear = Boolean(authorPayment.year);
  const isValidQuarter = Boolean(authorPayment.quarter && authorPayment.quarter.value);

  return [
    { isValid: isValidRegularParagraph, invalidMessage: 'Не указан пункт положения' },
    { isValid: isValidRegulationName, invalidMessage: 'Не указано название условия оплаты' },
    { isValid: isValidMaxNomineesMoney, invalidMessage: 'Не указана сумма по номинантам' },
    { isValid: isValidMaxFundMoney, invalidMessage: 'Заполните сумму фонда' },
    { isValid: isMaxFundMoneyMoreZero, invalidMessage: 'Сумма фонда должна быть больше нуля' },
    { isValid: isValidMonth, invalidMessage: 'Выберите месяц' },
    { isValid: isValidYear, invalidMessage: 'Введите год' },
    { isValid: isValidQuarter, invalidMessage: 'Выберите квартал' },
  ];
}

export function isEditableNomineeJob(arg: any): arg is ShortJob {
  return arg.appointment && typeof arg.appointment === 'string';
}

export function formatEditableJob(job: EditableNominee['job']) {
  if (job === null) {
    return '';
  }

  return isEditableNomineeJob(job) ? job.appointment : formatJob(job, { withoutStatus: true });
}

export function formatInfoSource(nominee: Payment.Nominee) {
  const { foundationJob, foundationEducation } = nominee;
  const jobsString = foundationJob ? formatJob(foundationJob, { withoutStatus: true }) : '';
  const educationString = foundationEducation ? formatEducation(foundationEducation) : '';
  return [jobsString, educationString].filter(i => !!i).join('; ');
}

export const mapTitle: Record<AuthorPaymentRequestStatus | 'null', string> = {
  AGREEMENT: 'Смена статуса заявки на "На согласовании"',
  REVISION: 'Смена статуса заявки на "На доработку"',
  PAID: 'Смена статуса заявки на "Передана на оплату"',
  PAYMENT_APPROVED: 'Смена статуса заявки на "Оплата одобрена"',
  POSTPONE: 'Смена статуса заявки на "Отложена"',
  REJECTED: 'Смена статуса заявки на "Отклонена"',
  DRAFT: '',
  null: 'Сохранение заявки',
};

export function checkPaymentByStatus(factNomineesMoney: string | null, status: AuthorPaymentRequestStatus | null) {
  if (status === 'PAYMENT_APPROVED') {
    return `Вы уверены, что хотите перевести выбранную заявку в статус "Оплата одобрена"? 
      Данный статус является конечным, и редактировать заявку в данном статусе невозможно.`;
  }

  const isZeroFactNomineesMoney = factNomineesMoney !== null && Number(factNomineesMoney) === 0;
  if (isZeroFactNomineesMoney) {
    return 'Вы уверены, что хотите сохранить заявку с нулевой фактической суммой по номинантам ?';
  }

  return '';
}

export function getFoundationId(payment: LocalAuthorPayment | null, eventType: Payment.EventType) {
  const map: Record<Payment.EventType, string> = {
    ARTICLE: payment?.article?.id ?? '',
    CONFERENCE: payment?.event?.id ?? '',
    MONOGRAPH: payment?.publication?.id ?? '',
    TEXTBOOK: payment?.publication?.id ?? '',
    MEDAL: payment?.participation?.id ?? '',
  };
  return map[eventType];
}

export function getFoundationType(payment: LocalAuthorPayment | null, eventType: Payment.EventType) {
  const map: Record<Payment.EventType, ArticleType | null> = {
    ARTICLE: payment?.article?.type.value ?? null,
    CONFERENCE: null,
    MONOGRAPH: null,
    TEXTBOOK: null,
    MEDAL: null,
  };
  return map[eventType];
}

export function isValidNominee(nominee: EditableNominee) {
  return nominee.topLevelDepartment !== null && nominee.job !== null;
}
