import React, { ReactElement } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-column');

type Props = {
  children: ReactElement | false | (ReactElement | false)[];
  specialStyles?: React.CSSProperties;
  hasNoWrap?: boolean;
};

export function Column(props: Props) {
  const { children, specialStyles, hasNoWrap } = props;

  return (
    <div className={b({ 'special-styled': !!specialStyles, 'wrap-styled': !hasNoWrap })} style={specialStyles}>
      <div className={b('inner')}>{children}</div>
    </div>
  );
}
