import React, { memo, useCallback, useMemo, useState } from 'react';

import { buttonIcons, ConfirmPopup, IconButtonProps, Toolbar } from 'components';
import { showNotification } from 'features/Notifications';
import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams as panelStreams } from '../streams';
import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function LeftPanelForSecondLevelControls({ customState, onSubmitTable, tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const { isChangeFormOpen, selectedRow, currentSetting } = customState;

  const isSelectedRowForeign = useMemo(() => Boolean(selectedRow) && selectedRow!.isForeign === 'true', [selectedRow]);

  const isSelectedCurrentSetting = useMemo(() => (selectedRow ? currentSetting?.name === selectedRow?.name : false), [
    currentSetting?.name,
    selectedRow,
  ]);

  const handleEditButtonClick = useCallback(() => {
    if (isSelectedRowForeign) {
      showNotification({ message: 'Редактирование возможно только собственных шаблонов', theme: 'danger' });
    } else {
      panelStreams.toggleSettingEditing.push(true);
      panelStreams.toggleChangeForm.push();
    }
  }, [isSelectedRowForeign]);

  const handleDeleteButtonClick = useCallback(() => {
    if (isSelectedRowForeign) {
      showNotification({ message: 'Удаление возможно только собственных шаблонов', theme: 'danger' });
    } else {
      setIsConfirmOpen(true);
    }
  }, [isSelectedRowForeign]);

  const closeConfirmPopup = useCallback(() => {
    setIsConfirmOpen(false);
  }, []);

  const deleteTemplate = useCallback(() => {
    if (selectedRow) {
      panelStreams.deleteTableSetting.push({ settingName: selectedRow.name });
      setIsConfirmOpen(false);
    }
  }, [selectedRow]);

  const handleSelectButtonClick = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable(tableState);
    } else {
      tableStreams.submitTable.push(tableState);
    }
  }, [tableStreams, onSubmitTable, tableState]);

  const isButtonDisabled = selectedRow === undefined || isChangeFormOpen;

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.check,
        title: 'Выбрать',
        code: 'select',
        isDisabled: isButtonDisabled,
        onClick: handleSelectButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать описание шаблона',
        code: 'edit',
        isDisabled: isButtonDisabled,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить шаблон',
        code: 'remove_template',
        isDisabled: isButtonDisabled,
        onClick: handleDeleteButtonClick,
      },
    ],
    [isButtonDisabled, handleSelectButtonClick, handleEditButtonClick, handleDeleteButtonClick],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <ConfirmPopup
        isOpen={isConfirmOpen}
        title="Предупреждение"
        okButtonText="Удалить"
        resetButtonText="Отмена"
        onClose={closeConfirmPopup}
        icon="warning"
        onConfirm={deleteTemplate}
      >
        <>
          {isSelectedCurrentSetting ? (
            <span>
              Удаляемый шаблон в данный момент используется.
              <br />
              Вы действительно хотите удалить выбранный шаблон?
            </span>
          ) : (
            <span>Вы действительно хотите удалить выбранный шаблон?</span>
          )}
        </>
      </ConfirmPopup>
    </>
  );
}

export const Component = memo(LeftPanelForSecondLevelControls);
