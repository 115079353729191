import { buttonIcons, IconButtonProps } from 'components';
import { useState, useCallback, useMemo } from 'react';
import { Table, Report } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { isHasPermission } from 'features/AppData';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const { userPermission, userSystemDepartment } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;

  const { methods: BindParticipationToCurrentUserDepartment } = BackendAPI.useBackendAPI(
    'BindParticipationToCurrentUserDepartment',
  );
  const { methods: deleteParticipation } = BackendAPI.useBackendAPI('DeleteParticipation');
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [isEditDepartmentsOpen, setIsEditDepartmentsOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);
  const [isFinancicalSupportPopupOpen, setIsFinancicalSupportPopupOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const { settings } = useAppDataContext();

  const isEditPermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`PARTICIPATION_EXPOSITION_EDIT_${selectedRow?.['id:RecordStatus']}` as keyof Permits],
    );
  }, [selectedRow, userPermission]);

  const isDeletePermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`PARTICIPATION_EXPOSITION_DELETE_${selectedRow?.['id:RecordStatus']}` as keyof Permits],
    );
  }, [selectedRow, userPermission]);

  const handleOpenWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(true);
  }, []);

  const handleCloseWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(false);
  }, []);

  const handleAttachParticipation = useCallback(() => {
    if (selectedRow) {
      BindParticipationToCurrentUserDepartment.callAPI(
        { participationId: selectedRow.id },
        {
          onSuccessfullCall: data => {
            if (data.data.success) {
              showNotification({ message: 'Участие успешно привязано', theme: 'success' });
              handleCloseWarningPopup();
            }
          },
          onFailedCall: () => {
            handleCloseWarningPopup();
          },
        },
      );
    }
  }, [selectedRow, BindParticipationToCurrentUserDepartment, handleCloseWarningPopup]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCloseFinancicalSupportPopup = useCallback(() => {
    setIsFinancicalSupportPopupOpen(false);
  }, []);

  const handleOpenFinancicalSupportPopup = useCallback(() => {
    setIsFinancicalSupportPopupOpen(true);
  }, []);

  const handleOpenApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleCloseApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const handleOpenEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(true);
  }, []);

  const handleCloseEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(false);
  }, []);

  const handleOpenConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(true);
  }, []);

  const handleCloseConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(false);
  }, []);

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams.reloadTable]);

  const submitFinancialPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const handleDeleteParticipation = useCallback(() => {
    deleteParticipation.callAPI(
      { participationId: tableState.selectedRows[0].id || '' },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Участие успешно удалено', theme: 'success' });
          updateTable();
          setIsConfirmDeleteOpen(false);
        },
        onFailedCall: () => {},
      },
    );
  }, [deleteParticipation, tableState.selectedRows, updateTable]);

  const afterSubmitEditDepartments = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const reports = useMemo<Report[]>(
    () => [Reports.DepartmentNTRExpositionsAwards, Reports.DepartmentExpositionParticipation, Reports.AllExpositionsAwards],
    [],
  );

  const { getReports, handleSetCurrentReport, isReportOpen, onReportClose, currentReport } = useReportsHook({ reports });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
        permissionName: Permits.PARTICIPATION_EXPOSITION_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавление',
        code: 'add',
        onClick: handleAddButtonClick,
        permissionName: Permits.PARTICIPATION_EXPOSITION_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактирование',
        code: 'edit',
        isDisabled: !selectedRow || !isEditPermitted,
        onClick: handleEditButtonClick,
        permissionName: [Permits.PARTICIPATION_EXPOSITION_EDIT_ADDED, Permits.PARTICIPATION_EXPOSITION_EDIT_APPROVED],
      },
      {
        icons: buttonIcons.delete,
        title: 'Удаление',
        code: 'delete',
        isDisabled: !selectedRow || !isDeletePermitted,
        onClick: handleOpenConfirmDelete,
        permissionName: [Permits.PARTICIPATION_EXPOSITION_DELETE_ADDED, Permits.PARTICIPATION_EXPOSITION_DELETE_APPROVED],
      },
      {
        icons: buttonIcons.attach,
        title: 'Привязать к подразделению',
        isDisabled: !selectedRow,
        onClick: handleOpenWarningPopup,
        permissionName: Permits.PARTICIPATION_EXPOSITION_BIND_TO_DEPARTMENT,
      },
      {
        icons: buttonIcons.approve,
        title: 'Процедура утверждения',
        code: 'approve',
        isDisabled: !selectedRow,
        onClick: handleOpenApprovePopup,
        permissionName: Permits.PARTICIPATION_EXPOSITION_APPROVE,
      },
      {
        icons: buttonIcons.editRelations,
        title: 'Редактировать связь с подразделением',
        code: 'editDepartments',
        isDisabled: !selectedRow,
        onClick: handleOpenEditDepartments,
        permissionName: Permits.PARTICIPATION_EXPOSITION_DEPARTMENT_EDIT,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
      {
        icons: buttonIcons.financialSupport,
        title: 'Финансовая поддержка участия',
        isDisabled: !selectedRow,
        onClick: handleOpenFinancicalSupportPopup,
        permissionName: Permits.PARTICIPATION_EXPOSITION_VIEW,
      },
    ],
    [
      selectedRow,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditPermitted,
      handleEditButtonClick,
      isDeletePermitted,
      handleOpenConfirmDelete,
      handleOpenWarningPopup,
      handleOpenApprovePopup,
      handleOpenEditDepartments,
      getReports,
      handleSetCurrentReport,
      handleOpenFinancicalSupportPopup,
    ],
  );

  const participationInfoText = useMemo(() => {
    if (selectedRow) {
      return `
      Вы действительно хотите привязать данное участие к своему подразделению (${userSystemDepartment?.label})?
      </br>
      </br>
      <b>Информация об участии</b>
      </br>
      </br>
      <b>Выставка:</b>
      </br>
      '${selectedRow.EventFullName || ''}'
      </br>
      ${
        selectedRow.Participants
          ? `
          </br>
          <b>Участники:</b>
          </br>
           ${selectedRow.Participants}
          `
          : ''
      }
      `;
    }
    return '';
  }, [selectedRow, userSystemDepartment?.label]);

  const afterSubmitApproveParticipation = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams]);

  return {
    buttons,
    isReportOpen,
    currentReport,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    isWarningPopupOpen,
    isApprovePopupOpen,
    isConfirmDeleteOpen,
    isEditDepartmentsOpen,
    participationInfoText,
    isFinancicalSupportPopupOpen,
    submitFinancialPopupCallback,
    handleCloseFinancicalSupportPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    handleCloseWarningPopup,
    handleAttachParticipation,
    handleCloseApprovePopup,
    handleCloseEditDepartments,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    afterSubmitEditDepartments,
    afterSubmitApproveParticipation,
    onReportClose,
    settings,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
};

export default useController;
