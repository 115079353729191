import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { RequestTypes } from 'utils/Enums/RequestTypes';
import { Modal } from 'components';

const ContestRequestViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look = {
    apiID: 'GetContestRequest',
    template: RequestTypes.CONTEST_REQUEST.template,
    viewMode: true,
    id,
    relatedTableState,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ContestRequestViewTemplate);
