import React, { useState } from 'react';

import { FormComponent, Radio, ConfirmPopup } from 'components';

import { getEnum } from 'utils/Helpers';
import { NirRequestTenderType } from 'utils/Enums';
import { Item } from 'types/models/common';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(tenderType: NirRequestTenderType): void;
};

export function SelectTenderTypeModal(props: Props) {
  const { isOpen, onClose, onSubmit } = props;

  const [tenderType, setTenderType] = useState<NirRequestTenderType>(NirRequestTenderType.LMU);
  const tenderTypeOptions = getEnum('NirRequestTenderType') as Array<Item<NirRequestTenderType>>;

  return (
    <ConfirmPopup
      title="Выберите тип конкурса"
      isOpen={isOpen}
      onClose={onClose}
      okButtonText="Продолжить"
      onConfirm={() => onSubmit(tenderType)}
      resetButtonText="Отмена"
      onReset={onClose}
    >
      <FormComponent.Wrapper>
        <FormComponent.Field>
          <Radio
            value={tenderType}
            list={tenderTypeOptions}
            onChange={e => setTenderType(e as NirRequestTenderType)}
            isInRow={false}
          />
        </FormComponent.Field>
      </FormComponent.Wrapper>
    </ConfirmPopup>
  );
}
