import React from 'react';

import { Reference, FormComponent, CustomSelect, SectionTitle, Checkbox, TextDateTime, TextInput } from 'components';

import { Table, Form } from 'types/models';
import { GetPartnerList, GetDissertationCouncilList } from 'features/Table/specifications';
import { CommunityType } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { convertAffiliation, convertCouncil } from 'features/Form/looks/publication/PublicationFormDissertation/helpers';

type Props = {
  workMode: Table.WorkMode;
  formFields: Form.Fields;
};

const Defence = ({ workMode, formFields }: Props) => {
  const { settings } = useAppDataContext();

  return (
    <form>
      <SectionTitle title="Сведения о защите" />
      <FormComponent.Line hasGap>
        <FormComponent.Field label={formFields.vakSpeciality.title}>
          <Reference
            value={formFields.vakSpeciality.value}
            theme="big"
            disabled={workMode === 'viewMode'}
            relationTableModalTitle={formFields.vakSpeciality.title}
            name="RefVakSpeciality"
            onChange={formFields.vakSpeciality.onChange}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.degree.title} isRequired>
          <Reference
            value={formFields.degree.value}
            theme="big"
            disabled={workMode === 'viewMode'}
            relationTableModalTitle={formFields.degree.title}
            name="RefDegree"
            onChange={formFields.degree.onChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field align="center" label={formFields.council.title}>
          <CustomSelect
            disabled={workMode === 'viewMode'}
            original={formFields.council.value}
            relationTableModalTitle="Диссертационный совет"
            onChange={formFields.council.onChange}
            modalTableRowConverter={convertCouncil}
            modalTableSpecification={GetDissertationCouncilList({
              communityTypes: [CommunityType.DISSERTATION_COUNCIL],
              hasSelectButton: true,
              isLocal: true,
            })}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Организация, на базе которой выполнена работа">
          <FormComponent.Line hasGap>
            <FormComponent.Field labelSize="fit">
              <Checkbox
                label={settings?.organization?.shortName}
                checked={formFields.isLocal.value}
                onChange={formFields.isLocal.onChange}
              />
            </FormComponent.Field>
            <FormComponent.Field label="" labelSize="fit" align="center">
              <CustomSelect
                disabled={workMode === 'viewMode'}
                original={
                  formFields.affiliation.value
                    ? {
                        name: formFields.affiliation.value?.organization.name,
                        id: formFields.affiliation.value?.organization.id,
                      }
                    : null
                }
                relationTableModalTitle="Партнёры"
                onChange={formFields.affiliation.onChange}
                modalTableRowConverter={convertAffiliation}
                modalTableSpecification={GetPartnerList({ withMenu: false })}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap lineSize="padded">
        <FormComponent.Field>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <FormComponent.Field label={formFields.defencePlace.title} isRequired>
                <TextInput
                  value={formFields.defencePlace.value}
                  onChange={formFields.defencePlace.onChange}
                  isDisabled={workMode === 'viewMode'}
                />
              </FormComponent.Field>
            </FormComponent.Column>
            <FormComponent.Column>
              <FormComponent.Field label={formFields.defenceDate.title} isRequired={!!formFields.defenceDate.required}>
                <TextDateTime
                  value={formFields.defenceDate.value}
                  onChange={formFields.defenceDate.onChange}
                  isDisabled={workMode === 'viewMode'}
                />
              </FormComponent.Field>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Field>
      </FormComponent.Line>
    </form>
  );
};

export const Component = React.memo(Defence);
