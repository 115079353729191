import { useState, useCallback, useMemo } from 'react';
import { Report } from 'types/models';

type Props = {
  reports: Report[];
};

const reportsHook = ({ reports }: Props) => {
  const [currentReport, setCurrentReport] = useState<Report | null>(null);
  const [isReportOpen, setIsReportOpen] = useState<boolean>(false);

  const reportsAsMap = useMemo(() => {
    const reportsMap = new Map<string, string>();
    reports.forEach(report => {
      reportsMap.set(report.caption, report.name);
    });

    return reportsMap;
  }, [reports]);

  const getReports = useCallback(() => reportsAsMap, [reportsAsMap]);

  const handleSetCurrentReport = useCallback(({ name, value }: { name: string; value: string }) => {
    const nextCurrentReport: Report = { name: value, caption: name };
    if (nextCurrentReport) {
      setCurrentReport(nextCurrentReport);
      setIsReportOpen(true);
    }
  }, []);

  const onReportClose = useCallback(() => {
    setIsReportOpen(false);
  }, []);

  return {
    isReportOpen,
    onReportClose,
    getReports,
    handleSetCurrentReport,
    currentReport,
  };
};

export default reportsHook;
