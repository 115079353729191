import React from 'react';

import { Modal } from 'components';
import { TemplateFormComponent, Props as ComponentProps } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & ComponentProps;

export const TemplateFormContainer = ({ isOpen, relatedColumns, onClose, successfullCallback }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавление шаблона" size="medium">
      <TemplateFormComponent onClose={onClose} relatedColumns={relatedColumns} successfullCallback={successfullCallback} />
    </Modal>
  );
};
