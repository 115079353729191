import { Form } from 'types/models';

const isOutOfMaxValueRange = (formField: Form.Field) => {
  let isValid = true;

  if (formField.maxValue) {
    const floatValue = formField.value ? parseFloat(formField.value) : 0;
    if (typeof floatValue === 'number') {
      isValid = floatValue < formField.maxValue;
    } else {
      isValid = false;
    }
  }

  return isValid;
};

export { isOutOfMaxValueRange };
