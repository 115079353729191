import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type CustomProps = {
  releaseId: string;
};
export function GetPrintPublicationListSpecification(deps: Table.Dependencies & CustomProps): Table.Specification {
  return {
    apiID: 'GetPrintPublicationList',
    templatesTableDependencies: deps.templatesTableDependencies,

    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    requestData: {
      releaseId: deps.releaseId,
      year: deps.templatesTableDependencies?.year,
    },
  };
}
