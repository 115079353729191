import React from 'react';

import { Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar({ tableState, customState }: Props) {
  const { buttons, currentReport, isReportOpen, onReportClose } = useController({ tableState, customState });
  return (
    <>
      <SharedToolbar buttons={buttons} />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        projectId={tableState.selectedRows.length > 0 ? tableState.selectedRows[0].ProjectID : ''}
      />
    </>
  );
}

export { Toolbar };
