import { Document, MipPartner } from 'types/models';
import { convertDocumentToRequest, convertFileToXML, getMaybeField } from '../commonConverters';
import { convertPartnerToRequest } from '../partners/requestConverters';

export function convertMipPartnerToRequest(mipPartner: MipPartner.MipPartner) {
  return {
    MipPartner: {
      ...convertPartnerToRequest(mipPartner).Partner,
      ...getMaybeField('TerminatedDate', mipPartner.terminatedDate),
      ...getMaybeField('TerminatedNote', mipPartner.terminatedNote),
      ...getMaybeField('RegistrationDate', mipPartner.registrationDate),
      ...convertMipPartnerDocuments(mipPartner.mipDocuments),
      ...convertMipPartnerIndicators(mipPartner.indicators),
      ...convertMipPartnerCharterCapitalss(mipPartner.charterCapitals),
      IsFl217: mipPartner.isFl217,
      IsFl273: mipPartner.isFl273,
    },
  };
}

const convertMipPartnerDocuments = (mipDocuments: Document[]) => {
  if (!mipDocuments.length) {
    return { Document: {} };
  }
  return {
    MipDocuments: {
      Document: mipDocuments.map(convertDocumentToRequest),
    },
  };
};

export const convertIndicatorToRequest = (indicator: MipPartner.Indicator) => ({
  _attr: {
    ...(indicator.id ? { id: indicator.id } : {}),
  },
  ...(indicator.partner ? { Partner: { _attr: { id: indicator.partner.id } } } : {}),
  Year: indicator.year,
  Profit: indicator.profit,
  Revenue: indicator.revenue,
  ...getMaybeField('ReportDate', indicator.reportDate),
  AverageHeadcount: indicator.averageHeadcount,
});

const convertFounderRequest = (founder: MipPartner.Founder) => {
  return {
    _attr: {
      ...(founder.id ? { id: founder.id } : {}),
    },
    ...(founder.scientist ? { Scientist: { _attr: { id: founder.scientist.id } } } : {}),
    ...(founder.enterpriseCustomer?.id ? { EnterpriseCustomer: { _attr: { id: founder.enterpriseCustomer.id } } } : {}),
    ...getMaybeField('Info', founder.info),
    ...getMaybeField('Passport', founder.passport),
    ...convertFileToXML('File', founder.file),
    Amount: founder.amount,
    ...getMaybeField('InsertionAsserts', founder.insertionAsserts),
  };
};

const convertCharterCapitalToRequest = (charterCapital: MipPartner.CharterCapital) => ({
  _attr: {
    ...(charterCapital.id ? { id: charterCapital.id } : {}),
  },
  Amount: charterCapital.amount,
  EditDate: charterCapital.editDate,
  Founders: {
    Founder: charterCapital.founders.map(convertFounderRequest),
  },
});

const convertMipPartnerIndicators = (indicators: MipPartner.Indicator[]) => {
  if (!indicators.length) {
    return { Indicators: {} };
  }
  return {
    Indicators: {
      Indicator: indicators.map(convertIndicatorToRequest),
    },
  };
};

const convertMipPartnerCharterCapitalss = (charterCapitals: MipPartner.CharterCapital[]) => {
  if (!charterCapitals.length) {
    return { CharterCapitals: {} };
  }
  return {
    CharterCapitals: {
      CharterCapital: charterCapitals.map(convertCharterCapitalToRequest),
    },
  };
};
