import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

export function useController() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        onClick: handleHelpButtonClick,
      },
    ],
    [handleHelpButtonClick],
  );

  return { buttons, isHelpFormOpen, handleTemplateCloseHelpForm };
}
