import { Project } from 'types/models';
import * as R from 'ramda';

import { parse, addDays, subDays, format } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { getMockStage, getMockPerformerStage } from './getMockModels';

import { getPerformerIndexByLastJobPeriodRole } from '.';
import { ProjectFinancingType, ProjectScientistRole } from 'utils/Enums';

import { Period } from '../Tabs/AboutProject/Stages/type';

type GetInitialProjectWithStages = {
  initialProject: Project.Project;
  isAddOtherToStages?: boolean;
  periodDuration: Period;
  financingValues: Record<ProjectFinancingType, string>;
};

export const getInitialProjectWithStages = ({
  initialProject,
  isAddOtherToStages = false,
  periodDuration,
  financingValues,
}: GetInitialProjectWithStages): Project.Project => {
  const preparedProject = R.clone(initialProject);

  const projectLeaderIndexes = getPerformerIndexByLastJobPeriodRole(preparedProject, ProjectScientistRole.LEADER);
  const projectResponsiblePerformerIndexes = getPerformerIndexByLastJobPeriodRole(
    preparedProject,
    ProjectScientistRole.RESPONSIBLE_PERFORMER,
  );
  const projectOtherPerformersIndexes = getPerformerIndexByLastJobPeriodRole(
    preparedProject,
    ProjectScientistRole.PERFORMER,
    ProjectScientistRole.ASSISTANCE,
  );

  if (!preparedProject.startDate || !preparedProject.endDate) {
    return preparedProject;
  }

  const startDate = parse(preparedProject.startDate, formatStr, new Date());
  const endDate = parse(preparedProject.endDate, formatStr, new Date());

  let periodEndDate = startDate;
  let periods: { startDate: Date; endDate: Date }[] = [];
  while (periodEndDate < endDate) {
    let newEnd: Date = new Date();
    const year = periodEndDate.getFullYear();
    if (periodDuration === Period.quarter) {
      if (periodEndDate < new Date(year, 3)) newEnd = subDays(new Date(year, 3), 1);
      else if (periodEndDate < new Date(year, 6)) newEnd = subDays(new Date(year, 6), 1);
      else if (periodEndDate < new Date(year, 9)) newEnd = subDays(new Date(year, 9), 1);
      else if (periodEndDate < new Date(year + 1, 0)) newEnd = subDays(new Date(year + 1, 0), 1);
    } else if (periodDuration === Period.halfYear) {
      if (periodEndDate < new Date(year, 6)) newEnd = subDays(new Date(year, 6), 1);
      else if (periodEndDate < new Date(year + 1, 0)) newEnd = subDays(new Date(year + 1, 0), 1);
    } else newEnd = subDays(new Date(year + 1, 0), 1);
    periods.push({ startDate: periodEndDate, endDate: newEnd < endDate ? newEnd : endDate });
    periodEndDate = addDays(newEnd, 1);
  }

  let stageNumber: number = 1;
  periods.forEach(period => {
    const stage: Project.Stage = {
      ...getMockStage(),
      startDate: format(period.startDate, formatStr),
      endDate: format(period.endDate, formatStr),
      amountMain: financingValues.MAIN,
      amountCofinancing1: financingValues.COFINANCING_1,
      amountCofinancing2: financingValues.COFINANCING_2,
      amountLocal: financingValues.LOCAL,
      amountAccomplice: financingValues.ACCOMPLICE,
    };

    const isProjectLeaderIndexes = Boolean(projectLeaderIndexes.length);
    const isProjectResponsiblePerformerIndexes = Boolean(projectResponsiblePerformerIndexes.length);
    const isProjectOtherPerformersIndexes = Boolean(projectOtherPerformersIndexes.length);

    if (isProjectLeaderIndexes) {
      const [leaderIndex] = projectLeaderIndexes;
      preparedProject.performers[leaderIndex].stages = [
        ...preparedProject.performers[leaderIndex].stages,
        { ...getMockPerformerStage(), stage },
      ];
    }

    if (isProjectResponsiblePerformerIndexes) {
      const [responsiblePerformerIndex] = projectResponsiblePerformerIndexes;
      preparedProject.performers[responsiblePerformerIndex].stages = [
        ...preparedProject.performers[responsiblePerformerIndex].stages,
        { ...getMockPerformerStage(), stage },
      ];
    }

    if (isProjectOtherPerformersIndexes && isAddOtherToStages) {
      projectOtherPerformersIndexes.forEach(index => {
        preparedProject.performers[index].stages = [
          ...preparedProject.performers[index].stages,
          { ...getMockPerformerStage(), stage },
        ];
      });
    }

    stage.number = stageNumber.toString();
    stageNumber += 1;
    preparedProject.stages.push(stage);
  });

  return preparedProject;
};
