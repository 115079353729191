import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import LeftPanelForSpecification from './LeftPanelForThirdLevel/LeftPanelForSpecification';

export function GetMagazineReleaseList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
  // publicationType?: string,
): T.Specification {
  // const toolbarEnabled: boolean =
  //   workMode === 'editMode' ||
  //   (((workMode === 'addMode' && !!templatesTableDependencies.relatedRecordId) || workMode === 'viewMode') &&
  //     (templatesTableDependencies.relatedTableAPIID === 'GetMagazineList' || publicationType === 'MAGAZINE'));
  return {
    apiID: 'GetMagazineReleaseList',
    templatesTableDependencies,
    workMode,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel({ workMode }),
        // isClearToolbar: !toolbarEnabled,
      },
    },
  };
}

export function GetMagazineReleaseListSpecification({
  deps,
  filterMagazineReleaseId,
  eventId,
  hasSelectButton,
  isDisableEventSource = false,
  isPreprint = undefined,
}: {
  deps: T.Dependencies;
  hasSelectButton?: boolean;
  filterMagazineReleaseId?: string;
  eventId?: string;
  isDisableEventSource?: boolean;
  isPreprint?: boolean;
}): T.Specification {
  return {
    apiID: 'GetMagazineReleaseList',
    templatesTableDependencies: deps.templatesTableDependencies,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForSpecification({ eventId, isDisableEventSource }),
        hasSelectButton,
      },
    },
    requestData: { filterMagazineReleaseId, isPreprint },
  };
}
