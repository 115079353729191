import React from 'react';

import { buttonIcons, ConfirmPopup, FormComponent, IconButton, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};
function Toolbar({ tableState, customState }: Props) {
  const {
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isOpenNoAccessWindow,
    handleCloseNoAccessWindow,
  } = useController({ tableState, customState });
  return (
    <>
      <ConfirmPopup
        title="Доступ ограничен"
        isOpen={isOpenNoAccessWindow}
        onClose={handleCloseNoAccessWindow}
        size="small"
        text="Эта функция доступна только для руководителя или ответственного исполнителя проекта"
        icon="warning"
      />
      <ConfirmPopup title="Мои научные проекты" isOpen={isHelpFormOpen} onClose={handleTemplateCloseHelpForm} size="medium">
        <div className="modal-help">
          <p>
            <strong className="primary">
              Раздел Мои научные проекты содержит список научных проектов, в которых вы указаны в составе участников коллектива
              проекта.
            </strong>
          </p>

          <p>
            Вы можете выгрузить список проектов в файл с помощью кнопки <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
          </p>
          <p>
            Если ваша роль в проекте – «руководитель проекта» или «ответственный исполнитель», то у вас есть дополнительные права:
          </p>
          <ul>
            <li>
              Открыть форму проекта для просмотра с помощью кнопки <IconButton icons={buttonIcons.loop} hasNoHover />
            </li>
            <li>
              Заполнить данные о проекте для госрегистрации с помощью кнопки <IconButton icons={buttonIcons.edit} hasNoHover />
            </li>
            <li>
              Распечатать отчётные формы проекта с помощью кнопки <IconButton icons={buttonIcons.print} hasNoHover />
            </li>
            <li>
              Задать вопрос администратору с помощью кнопки <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
            </li>
          </ul>
          <p>
            Процедуру госрегистрации в <FormComponent.Link href="https://rosrid.ru" label="ЕГИСУ НИОКТР" />{' '}
            научно-исследовательских, опытно-конструкторских и технологических работ гражданского назначения, выполняемых в
            Университете и научных отчётов по этим проектам, осуществляют сотрудники Научного управления. Вам лишь необходимо
            заполнить поля в карточке проекта: Аннотация, Научный задел, Обоснование междисциплинарного подхода. Для заполнения в
            проекте данных для горегистрации проекта откройте форму проекта с помощью кнопки{' '}
            <IconButton icons={buttonIcons.edit} hasNoHover />.
          </p>
          <p>
            Если у вас есть вопросы к сотрудникам Научного управления по вопросам госрегистрации - напишите сообщение с помощью
            кнопки Задать вопрос. Укажите тему сообщения «Госрегистрация». В течение рабочего дня вы получите ответ на ваш вопрос.
            Все ваши вопросы и ответы на них — в разделе Задать вопрос
          </p>
        </div>
      </ConfirmPopup>
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
