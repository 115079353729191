import React, { useCallback, useMemo } from 'react';
import { buttonIcons, IconButtonProps, Toolbar } from 'components';

import { DefaultToolbarButton, ExtraToolbarButton } from '../model';

type Props<Row> = {
  onAdd(mode: 'add' | 'dublicate'): void;
  onEdit(): void;
  onDelete(): void;
  onView(): void;
  moveDownRow(): void;
  moveUpRow(): void;
  rows: Row[];
  isOpenCustomComponent: boolean;
  isToolbarDisabled: boolean;
  selectedRowIndex: number | null;
  isCanMovingRows: boolean;
  extraToolbarButtons: ExtraToolbarButton<Row>[];
  visibleToolbarButtons?: DefaultToolbarButton[];
  isEditButtonDisabled?: boolean;
  isAddButtonDisabled?: boolean;
  isDeleteButtonDisabled?: boolean;
  // TODO: remove after refactor projects
  onAccept: () => void;
  onAcceptanceCancel: () => void;
  isAcceptButtonDisabled?: boolean;
  isCancelButtonDisabled?: boolean;
  isTransformButtonsDisabled?: boolean;
  withAcceptButtons?: boolean;
  addPermissionName?: string;
  editPermissionName?: string;
  acceptPermissionName?: string;
  isProfile?: boolean;
  editButtonTitle?: string;
  selectedRowsIndexes?: number[];
};

function ToolbarButtons<Row>(props: Props<Row>) {
  const {
    onAdd,
    onEdit,
    onDelete,
    onView,
    moveDownRow,
    moveUpRow,
    selectedRowIndex,
    isOpenCustomComponent,
    isToolbarDisabled,
    isCanMovingRows,
    extraToolbarButtons,
    rows,
    visibleToolbarButtons,
    isAddButtonDisabled = false,
    isDeleteButtonDisabled = false,
    isEditButtonDisabled = false,

    // TODO: remove
    onAccept,
    onAcceptanceCancel,
    isAcceptButtonDisabled = false,
    isCancelButtonDisabled = false,
    withAcceptButtons = false,
    addPermissionName,
    editPermissionName,
    acceptPermissionName,
    isTransformButtonsDisabled,
    isProfile,
    editButtonTitle,
    selectedRowsIndexes,
  } = props;
  const movingButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.arrowUp,
        title: 'Вверх',
        code: 'moveUp',
        isDisabled:
          (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
          selectedRowIndex === null ||
          isToolbarDisabled ||
          isOpenCustomComponent,
        onClick: moveUpRow,
      },
      {
        icons: buttonIcons.arrowDown,
        title: 'Вниз',
        code: 'moveDown',
        isDisabled:
          (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
          selectedRowIndex === null ||
          isToolbarDisabled ||
          isOpenCustomComponent,
        onClick: moveDownRow,
      },
    ],
    [selectedRowIndex, selectedRowsIndexes, isToolbarDisabled, isOpenCustomComponent, moveUpRow, moveDownRow],
  );
  const defaultToolbarButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled:
          isToolbarDisabled ||
          (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
          selectedRowIndex === null ||
          isOpenCustomComponent,
        isHidden: !(visibleToolbarButtons || []).includes('view'),
        onClick: (event: any) => {
          event?.currentTarget.blur();
          onView();
        },
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: isToolbarDisabled || isAddButtonDisabled || isOpenCustomComponent,
        isHidden: visibleToolbarButtons ? !visibleToolbarButtons.includes('add') : false,
        permissionName: addPermissionName,
        profilePermissionName: addPermissionName,
        onClick: (event: any) => {
          event?.currentTarget.blur();
          onAdd('add');
        },
      },
      {
        icons: buttonIcons.edit,
        title: editButtonTitle || 'Редактировать',
        code: 'edit',
        isDisabled:
          (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
          selectedRowIndex === null ||
          isToolbarDisabled ||
          (isTransformButtonsDisabled && isProfile) ||
          isEditButtonDisabled ||
          isOpenCustomComponent,
        isHidden: visibleToolbarButtons ? !visibleToolbarButtons.includes('edit') : false,
        permissionName: editPermissionName,
        profilePermissionName: editPermissionName,
        onClick: (event: any) => {
          event?.currentTarget.blur();
          onEdit();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled:
          (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
          selectedRowIndex === null ||
          isToolbarDisabled ||
          (isTransformButtonsDisabled && isProfile) ||
          isDeleteButtonDisabled ||
          isOpenCustomComponent,
        isHidden: visibleToolbarButtons ? !visibleToolbarButtons.includes('delete') : false,
        permissionName: editPermissionName,
        profilePermissionName: editPermissionName,
        onClick: (event: any) => {
          event?.preventDefault();
          onDelete();
        },
      },
      {
        icons: buttonIcons.clone,
        title: 'Дублировать',
        isDisabled:
          (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
          selectedRowIndex === null ||
          isToolbarDisabled ||
          (isTransformButtonsDisabled && isProfile) ||
          isOpenCustomComponent,
        isHidden: visibleToolbarButtons ? !visibleToolbarButtons.includes('dublicate') : true,
        permissionName: addPermissionName,
        profilePermissionName: addPermissionName,
        onClick: (event: any) => {
          event?.preventDefault();
          onAdd('dublicate');
        },
      },
    ],
    [
      selectedRowsIndexes,
      isToolbarDisabled,
      selectedRowIndex,
      isOpenCustomComponent,
      visibleToolbarButtons,
      isAddButtonDisabled,
      addPermissionName,
      editButtonTitle,
      isTransformButtonsDisabled,
      isProfile,
      isEditButtonDisabled,
      editPermissionName,
      isDeleteButtonDisabled,
      onView,
      onAdd,
      onEdit,
      onDelete,
    ],
  );

  const convertExtraToButton = useCallback(
    (item: ExtraToolbarButton<Row>): IconButtonProps => {
      return {
        ...item,
        isDisabled: (() => {
          if (isOpenCustomComponent) {
            return isOpenCustomComponent;
          }

          if (item.checkIsDisabled) {
            const row = selectedRowIndex !== null ? rows[selectedRowIndex] : null;
            return item.checkIsDisabled(row);
          }
          return false;
        })(),
        onClick: () => item.onClick(selectedRowIndex !== null ? rows[selectedRowIndex] : null, selectedRowIndex, rows),
      };
    },
    [isOpenCustomComponent, rows, selectedRowIndex],
  );

  const extraButtonsBefore = useMemo<IconButtonProps[]>(
    () => (extraToolbarButtons.filter(x => x.isBeforeDefaultButtons) || []).map(convertExtraToButton),
    [convertExtraToButton, extraToolbarButtons],
  );

  const extraButtonsAfter = useMemo<IconButtonProps[]>(
    () => (extraToolbarButtons.filter(x => !x.isBeforeDefaultButtons) || []).map(convertExtraToButton),
    [convertExtraToButton, extraToolbarButtons],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [...extraButtonsBefore, ...defaultToolbarButtons, ...extraButtonsAfter, ...(isCanMovingRows ? movingButtons : [])],
    [extraButtonsBefore, defaultToolbarButtons, extraButtonsAfter, isCanMovingRows, movingButtons],
  );

  const acceptButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.like,
        title: 'Принять к отчету',
        code: 'accept',
        isDisabled:
          selectedRowIndex === null ||
          isToolbarDisabled ||
          isAcceptButtonDisabled ||
          (isTransformButtonsDisabled && isProfile) ||
          isOpenCustomComponent,
        permissionName: acceptPermissionName,
        profilePermissionName: acceptPermissionName,
        onClick: (event: any) => {
          event?.currentTarget.blur();
          onAccept();
        },
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отменить принятие к отчету',
        code: 'cancel',
        isDisabled:
          selectedRowIndex === null ||
          isToolbarDisabled ||
          isCancelButtonDisabled ||
          (isTransformButtonsDisabled && isProfile) ||
          isOpenCustomComponent,
        permissionName: acceptPermissionName,
        profilePermissionName: acceptPermissionName,
        onClick: (event: any) => {
          event?.preventDefault();
          onAcceptanceCancel();
        },
      },
    ],
    [
      selectedRowIndex,
      isToolbarDisabled,
      isAcceptButtonDisabled,
      isTransformButtonsDisabled,
      isProfile,
      isOpenCustomComponent,
      acceptPermissionName,
      isCancelButtonDisabled,
      onAccept,
      onAcceptanceCancel,
    ],
  );

  const toolbarButtons = withAcceptButtons ? [...buttons, ...acceptButtons] : buttons;

  return <Toolbar buttons={toolbarButtons} mode="list" />;
}

export { ToolbarButtons };
