import React, { useCallback } from 'react';
import * as R from 'ramda';

import { FormComponent, InputSelect, Option, Radio, ReferenceItem, TextInput, TextDateTime } from 'components';

import { Person } from 'types/models';
import { EnumMap } from 'types/models/Table';
import { useAppDataContext } from 'features/AppData/context';
import { getEnum } from 'utils/Helpers';

type Props = {
  education: Person.ScientistEducation | null;
  enumMap: EnumMap;
  onChange(education: Person.ScientistEducation): void;
};

export function Fields(props: Props) {
  const { education, onChange, enumMap } = props;

  const { settings } = useAppDataContext();

  const makeChangeHandler = (fieldName: keyof Person.ScientistEducation) => (value: Option | null | ReferenceItem | string) => {
    onChange(R.set(R.lensProp(fieldName), value, education!));
  };

  const handleChangeIsTSU = useCallback(
    (value: string) => {
      // :TODO change to org from settings
      const isTSU = value === 'isTSU';
      const updateEducation = R.pipe(
        R.set(R.lensProp('isTSU'), isTSU),
        R.ifElse(() => isTSU, R.set(R.lensProp('enterprise'), settings?.organization?.shortName), R.identity),
      );
      onChange(updateEducation(education));
    },
    [onChange, education, settings],
  );

  const options = getEnum('EducationStatus', enumMap);
  const isDisabled = education?.isReadOnly ?? false;

  return (
    <>
      <FormComponent.Line hasGap>
        <FormComponent.Field label="Статус" isRequired>
          <InputSelect
            options={options}
            value={options.find(x => x.value === education?.status?.value) ?? null}
            onSelectChange={makeChangeHandler('status')}
            disabled={isDisabled}
          />
        </FormComponent.Field>
        <Radio
          isDisabled={true}
          value={education?.isTSU ? 'isTSU' : 'isNotTSU'}
          list={[
            { label: settings?.organization?.shortName, value: 'isTSU' },
            { label: 'Другой организации', value: 'isNotTSU' },
          ]}
          onChange={handleChangeIsTSU}
        />
        <FormComponent.Field label="Период">
          <TextDateTime
            isRange
            value={`${education?.dateReceipt || ''}-${education?.dateDismissal || ''}`}
            onChange={(value: string) => {
              const dates = value.split('-');
              onChange({ ...education, dateReceipt: dates[0] || '', dateDismissal: dates[1] || '' } as Person.ScientistEducation);
            }}
            isDisabled={isDisabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Field label="Организация" isRequired tooltip="Введите полное имя организации не используя аббревиатуру">
        <TextInput
          value={education?.enterprise}
          onChange={makeChangeHandler('enterprise')}
          isDisabled={education?.isTSU || isDisabled}
        />
      </FormComponent.Field>
      {!!education?.isTSU && (
        <>
          <FormComponent.Field label="Специальность">
            <TextInput value={education?.speciality ?? ''} onChange={makeChangeHandler('speciality')} isDisabled={isDisabled} />
          </FormComponent.Field>
          <FormComponent.Field label="Подразделение">
            <TextInput value={education?.department ?? ''} onChange={makeChangeHandler('department')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </>
      )}
    </>
  );
}
