import React, { ReactElement, memo } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-scroll');

type Props = {
  children: ReactElement | string;
  maxHeight: number;
};

export const Scroll = memo(({ children, maxHeight }: Props) => (
  <div className={b()} style={{ maxHeight }}>
    {children}
  </div>
));
