import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Partner, Table } from 'types/models';

export type ToolbarButton = 'view' | 'add' | 'edit' | 'remove' | 'relations' | 'approve' | 'print' | 'select';

type Props = {
  uniqID: string;
  showButtons?: ToolbarButton[];
};

export type DeleteProps = {
  partnerId: string;
  type: string;
  fullName: string;
  shortName: string;
};
export type State = {
  loadPartner(partnerId: string | null, onLoad: (partner: Partner) => void, type?: string): void;
  deletePartner(deleteProps: DeleteProps): void;
  partner: Partner | null;
  isOpenForm: boolean;
  closeForm(): void;
  openForm(): void;
  mode: Mode | null;
  setMode(mode: Mode | null): void;
  openRelationsModal(): void;
  closeRelationsModal(): void;
  isOpenRelationsForm: boolean;
  setPartnerHead(partnerId: string, enterprisesIds: string[], headId: string, relation: string): void;
  reloadTable(): void;
  uniqID: string;
  showButtons?: ToolbarButton[];
};

export type Mode = 'edit' | 'add' | 'view';

export function makeUseCustomController({ uniqID, showButtons }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    const [partner, setPartner] = useState<Partner | null>(null);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenRelationsForm, setIsOpenRelationsForm] = useState(false);

    const [mode, setMode] = useState<Mode | null>(null);

    const tableStreams = useLocalTableStreams();

    const { methods: getPartnerAPI } = BackendAPI.useBackendAPI('GetPartner');
    const { methods: deletePartnerAPI } = BackendAPI.useBackendAPI('DeletePartner');
    const { methods: setPartnerHeadAPI } = BackendAPI.useBackendAPI('SetPartnerHead');

    const [row] = selectedRows;

    const loadPartner = useCallback(
      (id: string | null, onLoad: (partner: Partner) => void, type?: string) => {
        getPartnerAPI.callAPI(
          {
            partnerId: id,
            enterpriseCustomerId: type === 'ENTERPRISE_CUSTOMER' ? id : null,
            enterpriseId: type === 'ENTERPRISE' ? id : null,
            shortName: type === 'RAW' ? row.ShortName ?? null : null,
            fullName: type === 'RAW' ? row.FullName : null,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setPartner(data);
              onLoad(data);
            },
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [row],
    );

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push();
    }, [tableStreams.reloadTable]);

    const closeForm = useCallback(() => {
      setIsOpenForm(false);
      setPartner(null);
      setMode(null);
    }, []);

    const openForm = useCallback(() => {
      setIsOpenForm(true);
    }, []);

    const openRelationsModal = useCallback(() => {
      setIsOpenRelationsForm(true);
    }, []);

    const closeRelationsModal = useCallback(() => {
      setIsOpenRelationsForm(false);
      setPartner(null);
    }, []);

    const deletePartner = useCallback(
      (deleteProps: DeleteProps) => {
        deletePartnerAPI.callAPI(deleteProps, {
          onSuccessfullCall: () => {
            showNotification({ message: 'Партнёр успешно удалён', theme: 'success' });
            reloadTable();
          },
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reloadTable],
    );

    const setPartnerHead = useCallback(
      (partnerId: string, enterprisesIds: string[], headId: string, relation: string) => {
        setPartnerHeadAPI.callAPI(
          { partnerId, enterprisesIds, headId, relation },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Головная организация успешно назначена', theme: 'success' });
              reloadTable();
            },
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reloadTable],
    );

    return {
      loadPartner,
      closeForm,
      partner,
      isOpenForm,
      openForm,
      mode,
      setMode,
      deletePartner,
      closeRelationsModal,
      openRelationsModal,
      isOpenRelationsForm,
      setPartnerHead,
      reloadTable,
      uniqID,
      showButtons,
    };
  };
}
