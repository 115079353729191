import React, { useCallback, useMemo, useState } from 'react';

import { buttonIcons, FormComponent, ListEdit } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { Fields } from './Fields';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';

import { Person } from 'types/models';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';

type Props = {
  person: Person.ScientistPerson | null;
  onChangeHirshIndexes(indexes: Person.HirshIndex[]): void;
};

function Component(props: Props) {
  const { person, onChangeHirshIndexes } = props;
  const { userPermission } = useAppDataContext();

  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);

  const isPermitted = useMemo<boolean>(() => {
    return isHasPermission(userPermission, Permits.HIRSH_INDEX_EDIT);
  }, [userPermission]);

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);
  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.HirshIndex>[]>(
    () => [
      {
        icons: buttonIcons.toolbarQuestion,
        title: 'Задать вопрос ',
        code: 'question',
        isDisabled: false,
        onClick: handleQuestionButtonClick,
      },
    ],
    [handleQuestionButtonClick],
  );
  return (
    <>
      {!isPermitted && (
        <FormComponent.Description mode="warning">
          Таблица не доступна для редактирования. Для добавления или редактирования данных напишите письмо администратору
        </FormComponent.Description>
      )}
      <ListEdit
        withMessages
        defaultRowsCount={6}
        isDeleteConfirmEnabled
        isFullScreenedTable
        rows={person?.hirshIndexes ?? []}
        onChange={onChangeHirshIndexes}
        extraToolbarButtons={extraToolbarButtons}
        hideToolbar={!isPermitted}
        columns={[
          { label: 'Дата расчета', formatValue: row => row.date },
          { label: 'Система цитирования', formatValue: row => row.citationSystem?.label || '' },
          { label: 'Значение', formatValue: row => row.value ?? '' },
          { label: 'Примечание', formatValue: row => row.note ?? '' },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, setRow) => <Fields hirshIndex={row} setHirshIndex={setRow} />,
        }}
      />
      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
    </>
  );
}

export const HirshIndexes = React.memo(Component);
