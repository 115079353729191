import { ReferenceItem } from 'components';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as BackendAPI from 'services/BackendAPI';
import { ScientificIndex, Table, UserPermission, Person } from 'types/models';
import { MsaWorkbenches } from 'utils/Common/MsaWorkbenches';
import { App as AppEvents } from 'utils/Events';
import { Settings } from 'types/models/common';
import { isHasPermission, isHasSomePermission } from '.';
import { redirectWithPermissions } from 'features/AppData/helpers';

export function useController() {
  const [pxRatio, setPxRatio] = useState<number>(0);
  const [scientificIndexes, setScientificIndexes] = useState<ScientificIndex.ScientificIndex[]>([]);
  const [enumMap, setEnumMap] = useState<Table.EnumMap>({});
  const [userPermission, setUserPermission] = useState<UserPermission | null>(null);
  const [currentPerson, setCurrentPerson] = useState<Person.ScientistPerson | null>(null);
  const [userToken, setUserToken] = useState<null | string>(null);
  const [isUserNotAuthError, setIsUserNotAuthError] = useState(false);

  const [isHasPermissionToAccount, setIsHasPermissionToAccount] = useState<boolean | null>(null);
  const [isHasPermissionToMain, setIsHasPermissionToMain] = useState<boolean | null>(null);
  const [isHasAnyPermission, setIsHasAnyPermission] = useState(true);

  const [initialCountryCode, setInitialCountryCode] = useState<ReferenceItem | null>(null);
  const [initialLanguageCode, setInitialLanguageCode] = useState<ReferenceItem | null>(null);
  const [initialOrganizationCode, setInitialOrganizationCode] = useState<ReferenceItem | null>(null);

  const history = useHistory();
  const [userSystemDepartment, setUserSystemDepartment] = useState<ReferenceItem | null>(null);
  const [settings, setSettings] = useState<Settings | null>(null);

  const { methods: GetScientificIndexes, state: GetScientificIndexesState } = BackendAPI.useBackendAPI('GetScientifiIndices', {
    onSuccessfullCall: ({ data }) => {
      setScientificIndexes(data);
    },
    onFailedCall: () => {},
  });

  const { methods: getCurrentPersonAPI, state: getCurrentPersonAPIState } = BackendAPI.useBackendAPI('GetCurrentPerson', {
    onSuccessfullCall: ({ data }) => {
      setCurrentPerson(data);
    },
    onFailedCall: () => {},
  });

  const { methods: enumAPIMethods, state: enumAPIMethodsState } = BackendAPI.useBackendAPI('GetAllSystemEnum', {
    onSuccessfullCall: ({ data }) => {
      setEnumMap(data);
    },
    onFailedCall: () => {},
  });

  const { methods: GetCurrentUser, state: GetCurrentUserState } = BackendAPI.useBackendAPI('GetCurrentUser', {
    onSuccessfullCall: ({ data }) => {
      setUserPermission(data);
      const hasPermissionToMain = isHasPermission(data, MsaWorkbenches.SYSTEM);
      setIsHasPermissionToMain(hasPermissionToMain);
      const hasPermissionToAccount = isHasPermission(data, MsaWorkbenches.PC);
      setIsHasPermissionToAccount(hasPermissionToAccount);

      setIsHasAnyPermission(isHasSomePermission(data, [MsaWorkbenches.SYSTEM, MsaWorkbenches.PC]));
      const isAccountPage = location.pathname.includes('account');
      redirectWithPermissions(isAccountPage, hasPermissionToMain, hasPermissionToAccount, history);
    },
    onFailedCall: () => {},
  });

  const { methods: GetCurrentUserSystemDepartment, state: GetCurrentUserSystemDepartmentState } = BackendAPI.useBackendAPI(
    'GetCurrentUserSystemDepartment',
    {
      onSuccessfullCall: ({ data }) => {
        setUserSystemDepartment(data);
      },
      onFailedCall: () => {},
    },
  );

  const { methods: getSettings, state: getSettingsState } = BackendAPI.useBackendAPI('GetSettings', {
    onSuccessfullCall: ({ data }) => {
      setSettings(data);
    },
    onFailedCall: () => {},
  });

  const isPending = useCallback(<T>(backendApiState: BackendAPI.TS.CallState<T>) => backendApiState.kind === 'pending', []);

  const isLoading = useMemo(
    () =>
      isPending(GetScientificIndexesState) ||
      isPending(getCurrentPersonAPIState) ||
      isPending(enumAPIMethodsState) ||
      isPending(GetCurrentUserState) ||
      isPending(GetCurrentUserSystemDepartmentState) ||
      isPending(getSettingsState),
    [
      GetCurrentUserState,
      GetCurrentUserSystemDepartmentState,
      GetScientificIndexesState,
      enumAPIMethodsState,
      getCurrentPersonAPIState,
      getSettingsState,
      isPending,
    ],
  );

  /** On zoom event */
  const handleZooming = useCallback(() => {
    const calcPxRatio: number = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    const isZoom = pxRatio !== calcPxRatio;
    if (isZoom) {
      setPxRatio(calcPxRatio);
    }
    document.dispatchEvent(
      new CustomEvent(AppEvents.RESIZING, {
        detail: {
          isZoom,
        },
      }),
    );
  }, [pxRatio]);

  useEffect(() => {
    window.addEventListener('resize', handleZooming);
    return () => {
      window.removeEventListener('resize', handleZooming);
    };
  }, [handleZooming]);

  const handleUserNotAuth = useCallback(() => {
    setIsUserNotAuthError(true);
  }, []);

  const handleAcceptUnavailableApp = useCallback(() => {
    setScientificIndexes([]);
    setCurrentPerson(null);
    setEnumMap({});
    setUserPermission(null);
    setUserSystemDepartment(null);
    setUserToken(null);
    localStorage.removeItem('token');
    history.push('/signin');
  }, [history]);

  const handleAcceptUserNotAuth = useCallback(() => {
    setScientificIndexes([]);
    setCurrentPerson(null);
    setEnumMap({});
    setUserPermission(null);
    setUserSystemDepartment(null);
    setUserToken(null);
    localStorage.removeItem('token');
    history.push('/signin');
    setIsUserNotAuthError(false);
  }, [history]);

  const location = useLocation();
  const isHasNoAnyPermission = useMemo(() => location.pathname.indexOf('signin') === -1 && !isHasAnyPermission, [
    location,
    isHasAnyPermission,
  ]);

  useEffect(() => {
    document.addEventListener(AppEvents.NOT_AUTH, handleUserNotAuth);
    return () => {
      document.removeEventListener(AppEvents.NOT_AUTH, handleUserNotAuth);
    };
  }, [handleUserNotAuth]);

  const { methods: getReferenceElement } = BackendAPI.useBackendAPI('GetReferenceElement');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isSigninPage = history.location.pathname === '/signin';
    const isRegisterPage = history.location.pathname === '/register';
    if (token) {
      setUserToken(token);
      fetchInitialData(token);
      if (isSigninPage) {
        history.push('/');
      }
    } else if (!isSigninPage && !isRegisterPage) {
      history.push('/signin');
    }
  }, []); // eslint-disable-line

  const getCurrentUserPromise = useCallback(
    (token: string | null) =>
      new Promise<boolean>(resolve => {
        GetCurrentUser.callAPI(
          {},
          {
            onSuccessfullCall: () => resolve(true),
            onFailedCall: () => resolve(false),
          },
          token || undefined,
        );
      }),
    [GetCurrentUser],
  );

  const fetchInitialData = useCallback(
    async (token: string | null) => {
      await getCurrentUserPromise(token);
      GetScientificIndexes.callAPI({}, undefined, token || undefined);
      enumAPIMethods.callAPI({}, undefined, token || undefined);
      getSettings.callAPI({ prefix: 'Organization' }, undefined, token || undefined);
      getCurrentPersonAPI.callAPI({}, undefined, token || undefined);
      GetCurrentUserSystemDepartment.callAPI({}, undefined, token || undefined);
      getReferenceElement.callAPI(
        {
          refName: 'RefCountry',
          elementSettings: { key: 'isoCode', value: 'RUS' },
        },
        {
          onSuccessfullCall: data => {
            if (data.data.id) {
              setInitialCountryCode({ id: data.data.id, label: data.data.label });
            }
          },
        },
        token || undefined,
      );
      getReferenceElement.callAPI(
        {
          refName: 'RefLanguage',
          elementSettings: { key: 'isoCode', value: 'rus' },
        },
        {
          onSuccessfullCall: data => {
            if (data.data.id) {
              setInitialLanguageCode({ id: data.data.id, label: data.data.label });
            }
          },
        },
        token || undefined,
      );
      getReferenceElement.callAPI(
        {
          refName: 'RefEnterprise',
          elementSettings: { key: 'code', value: 'TSU' },
        },
        {
          onSuccessfullCall: data => {
            if (data.data.id) {
              setInitialOrganizationCode({ id: data.data.id, label: data.data.label, code: 'TSU' });
            }
          },
        },
        token || undefined,
      );
    },
    [
      GetCurrentUserSystemDepartment,
      GetScientificIndexes,
      enumAPIMethods,
      getCurrentPersonAPI,
      getCurrentUserPromise,
      getReferenceElement,
      getSettings,
    ],
  );

  return {
    scientificIndexes,
    enumMap,
    userPermission,
    isHasPermissionToAccount,
    isHasPermissionToMain,
    currentPerson,
    userToken,
    userSystemDepartment,
    isUserNotAuthError,
    isLoading,
    setUserToken,
    fetchInitialData,
    handleAcceptUnavailableApp,
    handleAcceptUserNotAuth,
    isHasNoAnyPermission,
    initialCountryCode,
    initialLanguageCode,
    initialOrganizationCode,
    settings,
  };
}
