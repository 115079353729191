import React, { useCallback, useEffect, useRef, useState } from 'react';
import { block } from 'bem-cn';
import { Select as MaterialSelect, MenuItem, SelectProps, MenuProps } from '@material-ui/core';

import './style.scss';

export * as helpers from './helpers';

export type PropsWithMultiple<T> = Omit<SelectProps, 'onChange' | 'renderValue' | 'classes'> & {
  value: T[];
  entries: T[];
  multiple: true;
  title?: string;
  isNotSetMaxWidth?: boolean;
  defaultPlaceholder?: string;
  renderValue(entries: T[]): React.ReactNode;
  renderEntry(entry: T): React.ReactNode;
  onChange(value: T[]): void;
  width?: string;
};

export type Props<T> = Omit<SelectProps, 'onChange' | 'renderValue' | 'classes'> & {
  value: T;
  entries: T[];
  multiple?: false;
  title?: string;
  defaultPlaceholder?: string;
  isNotSetMaxWidth?: boolean;
  renderEntry(entry: T): React.ReactNode;
  onChange(value: T): void;
  renderValue?(entry: T): React.ReactNode;
  width?: string;
};

const b = block('select');

const selectClasses: SelectProps['classes'] = {
  root: b('root').toString(),
  iconOutlined: b('icon').toString(),
  selectMenu: b('menu').toString(),
};

const menuClasses: MenuProps['classes'] = {
  paper: b('paper').toString(),
  list: b('menu-list').toString(),
};

function Select<T>(props: Props<T> | PropsWithMultiple<T>) {
  const {
    entries,
    renderEntry,
    renderValue,
    onChange,
    value,
    multiple,
    title,
    defaultPlaceholder,
    isNotSetMaxWidth,
    width,
    ...restProps
  } = props;
  const selectRef = useRef<HTMLDivElement | null>();
  const [selectWidth, setSelectWidth] = useState(0);

  useEffect(() => {
    if (selectRef.current) {
      // it's necessary to synchronize the width of the select and its items,
      // because user has an opportunity to change the width manually
      setSelectWidth(selectRef.current.getBoundingClientRect().width);
    }
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
      onChange(e.target.value as any);
    },
    [onChange],
  );

  return (
    <div className={b()} style={{ width }}>
      <MaterialSelect
        displayEmpty
        ref={selectRef}
        renderValue={renderValue as any}
        multiple={multiple}
        value={value || ''}
        onChange={handleChange}
        title={title}
        classes={selectClasses}
        variant="outlined"
        MenuProps={{
          classes: menuClasses,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          elevation: 0,
          anchorReference: 'anchorEl',
          getContentAnchorEl: null,
          PaperProps: {
            square: true,
          },
        }}
        {...restProps}
      >
        {defaultPlaceholder && (value as unknown) === '' && (
          <MenuItem value="" disabled style={{ maxWidth: selectWidth }}>
            {defaultPlaceholder}
          </MenuItem>
        )}
        {entries.map((entry: T, index: number) => (
          <MenuItem
            className={b('menu-item').toString()}
            value={entry as any}
            key={index}
            style={{ maxWidth: isNotSetMaxWidth ? undefined : selectWidth }}
          >
            {renderEntry(entry)}
          </MenuItem>
        ))}
      </MaterialSelect>
    </div>
  );
}

export const Component = React.memo(Select) as typeof Select;
