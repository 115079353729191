import React from 'react';

import { SectionTitle, FormComponent, ReferenceItem, Reference } from 'components';
import { RefElement } from 'types/models/Project';

import { RefList } from 'features/Form/looks/project/ProjectForm/views';

export type Props = {
  disabled: boolean;
  internationalProgram: ReferenceItem | null;
  setInternationalProgram(internationalProgram: ReferenceItem): void;
  foreignEnterprise: ReferenceItem | null;
  setForeignEnterprise(foreignEnterprise: ReferenceItem): void;
  partnerCountries: RefElement[];
  setPartnerCountries(partnerCountries: RefElement[]): void;
};

export function InternationalPartnership(props: Props) {
  const {
    disabled,
    internationalProgram,
    setInternationalProgram,
    foreignEnterprise,
    setForeignEnterprise,
    partnerCountries,
    setPartnerCountries,
  } = props;

  return (
    <>
      <SectionTitle title="Международное сотрудничество" />
      <FormComponent.Field label="Международная программа">
        <Reference
          name="RefInternationalProgram"
          value={internationalProgram}
          onChange={setInternationalProgram}
          relationTableModalTitle='Справочник "Научные программы"'
          disabled={disabled}
        />
      </FormComponent.Field>
      <FormComponent.Field label="В интересах иностранной компании">
        <Reference
          name="RefEnterpriseCustomer"
          value={foreignEnterprise}
          onChange={setForeignEnterprise}
          relationTableModalTitle='Справочник "Организации"'
          disabled={disabled}
        />
      </FormComponent.Field>
      <RefList
        title="Страны-партнёры"
        modalTitle='Справочник "Cтраны"'
        refName="RefCountry"
        rows={partnerCountries}
        onChange={setPartnerCountries}
        disabled={disabled}
      />
    </>
  );
}
