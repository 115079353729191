import React from 'react';

import { Table } from 'types/models';
import { PublicationMobileRequest } from 'types/models/Form';
import { Projects } from 'features/Form/looks/participation/views/Projects';
import { Requests } from 'features/Form/looks/participation/views/Requests';
import { ParticipationProject } from 'types/models/Participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { projectsStreams, mobileRequestsStream } from 'features/Form/looks/participation/ParticipationFormConference/streams';

type Props = {
  relatedTableState: Table.State | undefined;
  workMode: Table.WorkMode;
  financingProjects: ParticipationProject[];
  mobileRequests: PublicationMobileRequest[];
};

const setLocalProjects = (rows: ParticipationProject[]) => {
  projectsStreams.setProjects.push(rows);
};

const setLocalProjectsAccept = (id: string) => {
  projectsStreams.accept.push(id);
};

const setLocalProjectsCancelAcception = (id: string) => {
  projectsStreams.cancelAcceptance.push(id);
};

const setLocalMobileRequest = (rows: PublicationMobileRequest[]) => {
  mobileRequestsStream.setMobileRequests.push(rows);
};

const setLocalMobileRequestAccept = (id: string) => {
  mobileRequestsStream.accept.push(id);
};

const setLocalMobileRequestCancelAcception = (id: string) => {
  mobileRequestsStream.cancelAcceptance.push(id);
};

function FinancialSupport({ relatedTableState, workMode, financingProjects, mobileRequests }: Props) {
  return (
    <>
      <Projects
        participationTypeCode={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        setLocalProjects={setLocalProjects}
        setLocalProjectsAccept={setLocalProjectsAccept}
        setLocalProjectsCancelAcception={setLocalProjectsCancelAcception}
        workMode={workMode}
        isDisabled={workMode === 'viewMode'}
        projects={financingProjects}
        relatedTableState={relatedTableState}
        isMaximized
        // eslint-disable-next-line max-len
        tooltipProjectText="Отметка о принятии к отчету свидетельствует о том, что участие в конференции является одним из результатов проекта. "
        // eslint-disable-next-line max-len
        tooltipText='Указать проект (грант на организацию проведения мероприятия), связанный с докладом. Сведения об участии будет отображаться в карточке проекта на вкладке "Результаты".'
      />
      <Requests
        participationTypeCode={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        setLocalMobileRequest={setLocalMobileRequest}
        setLocalMobileRequestAccept={setLocalMobileRequestAccept}
        setLocalMobileRequestCancelAcception={setLocalMobileRequestCancelAcception}
        workMode={workMode}
        requests={mobileRequests}
        relatedTableState={relatedTableState}
        isMaximized
      />
    </>
  );
}

export const Component = React.memo(FinancialSupport);
