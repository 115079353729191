import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'recognitionsFilter',
        list: [
          {
            label: 'Мои награды',
            value: 'mine',
            hint: 'Список ваших персональных наград',
          },
          {
            label: 'Все награды',
            value: 'all',
            hint: 'Список персон, имеющих награды',
          },
        ],
        value: 'mine',
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Показывать награды за год',
        checked: false,
      },
    ],
    'personal',
  );
});
