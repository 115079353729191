import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { useAppDataContext } from 'features/AppData/context';

export const Filters = React.memo(() => {
  const { settings } = useAppDataContext();

  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'byDraft',
      label: 'Черновики',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'byAffiliation',
      label: `С аффилиацией ${settings?.organization?.shortName}`,
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'byMine',
      label: 'Созданные мной',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'byDepartment',
      label: 'По подразделению',
      checked: false,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});
