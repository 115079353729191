import React from 'react';
import { DataGrid } from 'features/Table';
import { GetProjectPublicationList } from 'features/Table/specifications';

type Props = {
  projectId: string | null;
};
export function ResultsProjectPublications({ projectId }: Props) {
  return (
    <>
      <DataGrid specification={GetProjectPublicationList({ projectId: projectId || '-1' })} />
    </>
  );
}
