import React, { useCallback, useMemo } from 'react';

import { buttonIcons, CustomSelect, IconButtonProps, ListEdit } from 'components';

import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { EventProject, EventTypeCode } from 'types/models/Event';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';

type Props = {
  workMode: Table.WorkMode;
  projects: EventProject[];
  setLocalProjects: (rows: EventProject[]) => void;
  setLocalProjectsAccept: (id: string) => void;
  setLocalProjectsCancelAcception: (id: string) => void;
  relatedTableState: Table.State | undefined;
  eventType: EventTypeCode;
  isSingle?: boolean;
  standalone?: boolean;
};

function Projects({
  eventType,
  workMode,
  projects,
  relatedTableState,
  setLocalProjects,
  setLocalProjectsAccept,
  setLocalProjectsCancelAcception,
  isSingle = false,
  standalone,
}: Props) {
  const { isProfile } = usePrivatePageContext();

  const toolbarEnabled: boolean = useMemo(() => workMode === 'editMode' || workMode === 'addMode', [workMode]);

  const specifications = GetFullScienceProjectList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const project = useMemo<EventProject | null>(() => projects[0] || null, [projects]);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => EventProject>(
    row => ({
      id: null,
      project: {
        id: row.id,
        number: row.projectNumber,
        name: row.name,
        program: row.Program,
        leader: row.Leader,
        governances: row.governance,
        financings: row.financing,
      },
      accepted: '',
      acceptedBy: null,
    }),
    [],
  );

  const onChange = useCallback(
    (row: EventProject | null) => {
      if (row) {
        setLocalProjects([row]);
      }
    },
    [setLocalProjects],
  );

  const acceptRule = useMemo(() => {
    switch (eventType) {
      case 'CONCERT':
        return Permits.CONCERT_SCIENCE_PROJECT_ACCEPTING;
      case 'CONTEST':
        return Permits.CONTEST_SCIENCE_PROJECT_ACCEPTING;
      case 'CONFERENCE':
        return Permits.CONFERENCE_SCIENCE_PROJECT_ACCEPTING;
      case 'EXPOSITION':
        return Permits.EXPOSITION_SCIENCE_PROJECT_ACCEPTING;
    }
  }, [eventType]);

  const acceptButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.like,
        title: 'Принять к отчету',
        code: 'accept',
        isDisabled: !(projects.length && !project?.accepted),
        permissionName: acceptRule,
        isHidden: isProfile,
        onClick: (event: any) => {
          event?.currentTarget.blur();
          if (projects.length && !project?.accepted) {
            setLocalProjectsAccept(project?.project?.id || '');
          }
        },
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отменить принятие к отчету',
        code: 'cancel',
        isDisabled: !(projects.length && !!project?.accepted),
        permissionName: acceptRule,
        isHidden: isProfile,
        onClick: (event: any) => {
          event?.preventDefault();
          if (projects.length && !!project?.accepted) {
            setLocalProjectsCancelAcception(project.project?.id || '');
          }
        },
      },
    ],
    [
      projects.length,
      project?.accepted,
      project?.project?.id,
      acceptRule,
      isProfile,
      setLocalProjectsAccept,
      setLocalProjectsCancelAcception,
    ],
  );

  const getIsEditProjectButtonDisabled = useCallback((row: EventProject | null) => !!row?.accepted, []);

  const getIsDeleteProjectButtonDisabled = useCallback((row: EventProject | null) => !!row?.accepted, []);

  return (
    <>
      {isSingle ? (
        <CustomSelect
          disabled={!toolbarEnabled || !!project?.accepted}
          title="Сведение о проекте"
          original={!!project?.project ? { id: project.project.id || '', name: project.project.name || '' } : null}
          relationTableModalTitle="Научные проекты"
          onChange={onChange}
          modalTableRowConverter={modalTableRowConverter}
          onDeleteFunction={() => setLocalProjects([])}
          modalTableSpecification={specifications}
          textareaPlaceholder="Выберите элемент из справочника"
          customButtons={acceptButtons}
          isStatusText={!!project?.accepted}
          customStatus={!!project?.accepted ? `Принят к отчету, ${getAcceptInfo(project)}` : ''}
        />
      ) : (
        <ListEdit
          isFullScreenedTable={!standalone}
          specification={{
            mode: 'relationTableModal',
            modalTableRowConverter,
            relationTableModalTitle: 'Научные проекты',
            modalTableSpecification: specifications,
          }}
          rows={projects}
          columns={[
            { label: 'Номер проекта', formatValue: row => row.project?.number || '' },
            { label: 'Название проекта', formatValue: row => row.project?.name || '' },
            { label: 'Программа/Тип гранта', formatValue: row => row.project?.program || '' },
            { label: 'Рук-ль, отв. исполнитель', formatValue: row => row.project?.leader || '' },
            { label: 'Факультет/Институт', formatValue: row => row.project?.governances || '' },
            { label: 'Финансирование', formatValue: row => row.project?.financings || '' },
            { label: 'Принят к отчету', formatValue: getAcceptInfo },
          ]}
          isToolbarDisabled={!toolbarEnabled}
          onChange={rows => setLocalProjects(rows)}
          withAcceptButtons={!isProfile}
          onAccept={row => setLocalProjectsAccept(row.project?.id || '')}
          onAcceptanceCancel={row => setLocalProjectsCancelAcception(row.project?.id || '')}
          acceptPermissionName={acceptRule}
          getIsDeleteDisabled={getIsDeleteProjectButtonDisabled}
          getIsEditDisabled={getIsEditProjectButtonDisabled}
          defaultRowsCount={standalone ? 2 : undefined}
        />
      )}
    </>
  );
}

export { Projects };
