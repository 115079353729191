import React from 'react';
import block from 'bem-cn';

import { DataGrid } from 'features/Table';
import { GetProjectCodeListByYear, GetReceivingDocumentList } from 'features/Table/specifications';

import { useController } from './controller';

import './style.scss';

const b = block('receipts');

export function Receipts() {
  const { projectCodeId, setProjectCodeId } = useController();
  return (
    <div className={b()}>
      <div className={b('grid-container')}>
        <DataGrid specification={GetProjectCodeListByYear({ setProjectCodeId })} />
      </div>
      <div className={b('grid-container')} key={projectCodeId}>
        <DataGrid
          specification={GetReceivingDocumentList({
            projectCodeId,
            title: 'Детализация по сумме в столбце факт (приходные документы)',
            isFiltersHidden: true,
          })}
        />
      </div>
    </div>
  );
}
