import React from 'react';
import { setup } from 'bem-cn';

import { IconButton, buttonIcons, Button, ButtonMode, Popover } from 'components';

import { DayPicker } from 'react-day-picker';
import { ru } from 'date-fns/locale';
import { isValidDate } from './helpers';
import { Mask } from './mask';
import { Timepicker } from './Timepicker';

import useController, { Tabs } from './controller';

import 'react-day-picker/dist/style.css';
import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('datetime');
const ti = block('text-input');

type PickerType =
  | 'date' // datepicker
  | 'time'; // timepicker

type RangeButton =
  | 'singeDate' // single date range
  | 'toInfinite' // timepicker
  | 'fromInfinite'; // timepicker

type ModeType =
  | 'day' // show days
  | 'month' // show months
  | 'year'; // show years

export type Props = {
  isRange?: boolean;
  type?: PickerType | PickerType[];
  value: string | null | undefined;
  onChange(nextValue: string): void;
  mode?: ModeType[];
  isDisabled?: boolean;
  isError?: boolean;
  rangeButtons?: RangeButton[];
  customStyles?: React.CSSProperties;
};

/**
 * Text input with custom DateTimePicker (date, time or both)
 * Delimite dates for range is '-'
 * @param isRange - (optional) Single or range. Is single by default
 * @param type - (optional) Type of picker "date" | "time" | {[ "date", "time" ]}. Is "date" by default
 * @param value - Value for render
 * @param onChange - Callback on change
 * @param mode - (optional) Mode for date only
 * @param isDisabled - (optional) If disabled needed
 * @param isError - (optional) If show error is needed
 * @param rangeButton - (optional) List of buttons for visible. Is [] by default
 * @param customStyles - (optional) Custom styles to picker. Is {{ maxWidth: '200px' }} by default
 * @returns JSX
 */
export const TextDateTime = ({
  isRange = false,
  type = 'date',
  value = '',
  onChange,
  mode,
  isDisabled = false,
  isError = false,
  rangeButtons = ['singeDate', 'toInfinite', 'fromInfinite'],
  customStyles,
}: Props) => {
  const isType = (needle: PickerType) => {
    if (typeof type === 'string') {
      return type === needle;
    }
    return type.includes(needle);
  };

  const isVisibleButton = (needle: RangeButton) => {
    return rangeButtons.includes(needle);
  };

  const isWithDate: boolean = isType('date');
  const isWithTime: boolean = isType('time');

  const {
    isOpen,
    dateValue,
    timeValue,
    currentTab,
    defaultMonth,
    dateFilterRef,
    rangeDateValue,
    rangeTimeValue,
    inputDateValue,
    canAcceptRange,
    canAcceptToDate,
    isInputValueEmpty,
    canAcceptFromDate,
    canAcceptSingleRange,
    reset,
    onSubmit,
    submitRange,
    submitFromDateRange,
    submitToDateRange,
    submitSingleRange,
    openCalendar,
    closeCalendar,
    onInputChange,
    setCurrentTab,
    updateTimeValue,
    onMultipleDateChange,
    updateRangeTimeValue,
  } = useController({ isRange, isWithDate, isWithTime, value, onChange, mode });

  const modeHeader = {
    day: 'день',
    month: 'месяц',
    year: 'год',
  };

  const Header = () => {
    const tabs = [
      ...(isWithDate
        ? [
            {
              id: Tabs.DATE,
              label: (mode || []).map(i => modeHeader[i] || 'часть').join(', ') || 'Дата',
            },
          ]
        : []),
      ...(isWithTime ? [{ id: Tabs.TIME, label: 'Время' }] : []),
    ];

    return (
      <header className={b('header')}>
        {tabs.map(tab => (
          <button
            key={tab.id}
            type="button"
            onClick={() => setCurrentTab(tab.id)}
            className={b('header-tab', { active: currentTab === tab.id })}
          >
            {tab.label}
          </button>
        ))}
      </header>
    );
  };

  const Footer = () => (
    <footer className={b('footer', { 'is-range': isRange })}>
      {isRange ? (
        <>
          {isVisibleButton('singeDate') && (
            <Button
              mode={ButtonMode.primary}
              text="В рамках одной даты"
              onClick={submitSingleRange}
              isDisabled={mode ? false : !canAcceptSingleRange}
            />
          )}
          {isVisibleButton('toInfinite') && (
            <Button
              mode={ButtonMode.primary}
              text="От выбранной даты"
              onClick={submitFromDateRange}
              isDisabled={mode ? false : !canAcceptFromDate}
            />
          )}
          {isVisibleButton('fromInfinite') && (
            <Button
              mode={ButtonMode.primary}
              text="До выбранной даты"
              onClick={submitToDateRange}
              isDisabled={mode ? false : !canAcceptToDate}
            />
          )}
          <Button
            mode={ButtonMode.primary}
            text="Принять диапазон"
            onClick={submitRange}
            isDisabled={mode ? false : !canAcceptRange}
          />
        </>
      ) : (
        (isWithTime || mode) && (
          <Button mode={ButtonMode.primary} text="Применить" onClick={() => onSubmit(dateValue)} isDisabled={!dateValue} />
        )
      )}
    </footer>
  );

  return (
    <div ref={dateFilterRef} className={b({ 'is-range': isRange })} style={customStyles}>
      <Mask
        className={ti({ 'is-disabled': isDisabled, 'is-error': isError }).toString()}
        isRange={!!isRange}
        isWithDate={!!isWithDate}
        isWithTime={!!isWithTime}
        value={inputDateValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e.target.value || '')}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e.target.value || '')}
      />
      <IconButton icons={buttonIcons.calendar} onClick={openCalendar} isDisabled={!!isDisabled} />
      <IconButton icons={buttonIcons.delete} onClick={reset} isDisabled={!!isInputValueEmpty || !!isDisabled} />

      <Popover
        isOpen={isOpen}
        onClose={closeCalendar}
        anchorRef={dateFilterRef.current}
        position={{
          offset: 4,
          anchor: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          content: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
      >
        <div className={b('picker')} data-mode={(mode || []).join(' ')}>
          {isRange ? (
            <>
              <Header />
              <div className={b('content')}>
                {isWithDate && (
                  <div className={b('date-wrapper', { visible: currentTab === Tabs.DATE })}>
                    <DayPicker
                      captionLayout="dropdown"
                      mode="range"
                      fromYear={1900}
                      toYear={2099}
                      pagedNavigation
                      locale={ru}
                      selected={rangeDateValue}
                      onSelect={onMultipleDateChange}
                      defaultMonth={defaultMonth.from}
                      onMonthChange={date => (!!mode ? onMultipleDateChange({ from: date, to: rangeDateValue.to }) : null)}
                    />
                    <DayPicker
                      captionLayout="dropdown"
                      mode="range"
                      fromYear={1900}
                      toYear={2099}
                      pagedNavigation
                      locale={ru}
                      selected={rangeDateValue}
                      onSelect={onMultipleDateChange}
                      defaultMonth={defaultMonth.to}
                      onMonthChange={date => (!!mode ? onMultipleDateChange({ from: rangeDateValue.from, to: date }) : null)}
                    />
                  </div>
                )}
                {isWithTime && (
                  <div className={b('time-wrapper', { visible: currentTab === Tabs.TIME }, { range: true })}>
                    <Timepicker
                      setHours={updateRangeTimeValue('from', 'hour')}
                      setMinutes={updateRangeTimeValue('from', 'minute')}
                      selectedMinute={rangeTimeValue.from.minute}
                      selectedHour={rangeTimeValue.from.hour}
                    />
                    <span className={b('time-separator', { 'is-range': isRange })}>&ndash;</span>
                    <Timepicker
                      setHours={updateRangeTimeValue('to', 'hour')}
                      setMinutes={updateRangeTimeValue('to', 'minute')}
                      selectedMinute={rangeTimeValue.to.minute}
                      selectedHour={rangeTimeValue.to.hour}
                    />
                  </div>
                )}
              </div>
              <Footer />
            </>
          ) : (
            <>
              <Header />
              <div className={b('content')}>
                {isWithDate && (
                  <div className={b('date-wrapper', { visible: currentTab === Tabs.DATE })}>
                    <DayPicker
                      captionLayout="dropdown"
                      mode="single"
                      fromYear={1900}
                      toYear={2099}
                      locale={ru}
                      selected={dateValue}
                      onSelect={date => onSubmit(date)}
                      defaultMonth={isValidDate(dateValue) ? dateValue : new Date()}
                      showOutsideDays
                      onMonthChange={date => (mode ? onSubmit(date, false) : null)}
                    />
                  </div>
                )}
                {isWithTime && (
                  <div className={b('time-wrapper', { visible: currentTab === Tabs.TIME })}>
                    <Timepicker
                      setHours={updateTimeValue('hour')}
                      setMinutes={updateTimeValue('minute')}
                      selectedMinute={timeValue.minute}
                      selectedHour={timeValue.hour}
                    />
                  </div>
                )}
              </div>
              <Footer />
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};
