import React from 'react';

import { Tabs, Tab } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

import { StateForm } from './StateForm/StateForm';
import { StateReports } from './StateReports/StateReports';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
};

export function StateRegistration(props: Props) {
  const { disabled, project, makeChangeHandler, setProject } = props;

  return (
    <>
      <Tabs>
        <Tab title="Регистрация проекта">
          <StateForm disabled={disabled} project={project} makeChangeHandler={makeChangeHandler} />
        </Tab>
        <Tab title="Регистрация отчетов">
          <StateReports
            disabled={disabled}
            stages={project?.stages ?? []}
            reports={project?.stateRegistrationReports ?? []}
            udkList={project?.udks || []}
            isFieldsHidden={false}
            setProject={setProject}
          />
        </Tab>
      </Tabs>
    </>
  );
}
