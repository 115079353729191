import { StateRegistrationReport } from 'types/models';
import { convertFileToXML, convertReferenceItemToXML, getMaybeField } from '../commonConverters';

type Props = {
  stateRegistrationReport: StateRegistrationReport;
  projectId?: string;
};

export function convertStateRegistrationReportToXML({ stateRegistrationReport, projectId }: Props) {
  const {
    id,
    stage,
    reportType,
    citisDate,
    stageName,
    reportName,
    expectedResults,
    interdisciplinaryAproachJustification,
    reportIkNumber,
    reportArchiveNumber,
    reportCustomerSendDate,
    keyWords,
    referat,
    udks,
    file,
  } = stateRegistrationReport;
  return {
    _attr: stateRegistrationReport.id ? { id } : {},
    ...(projectId ? { Project: { _attr: { id: projectId } } } : {}),
    ...(stage ? { Stage: { _attr: { id: stage.id } } } : {}),
    ...getMaybeField('ReportType', reportType?.value),
    ...getMaybeField('CitisDate', citisDate),
    ...getMaybeField('StageName', stageName),
    ...getMaybeField('ReportName', reportName),
    ...getMaybeField('ExpectedResults', expectedResults),
    ...getMaybeField('InterdisciplinaryAproachJustification', interdisciplinaryAproachJustification),
    ...getMaybeField('ReportIkNumber', reportIkNumber),
    ...getMaybeField('ReportArchiveNumber', reportArchiveNumber),
    ...getMaybeField('ReportCustomerSendDate', reportCustomerSendDate),
    ...getMaybeField('KeyWords', keyWords),
    ...getMaybeField('Referat', referat),
    Udks: udks && udks.length ? { Udk: udks.map(convertReferenceItemToXML) } : {},
    ...convertFileToXML('File', file),
  };
}
