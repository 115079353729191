import React from 'react';

import { Checkbox, FormComponent, ListEdit, Radio } from 'components';

import { showErrors } from 'utils/Validators';
import { formatNumber } from 'utils/Helpers';
import { ValueOf } from 'types/helpers';
import { SecurityDocumentPaymentRequest } from 'types/models';
import { Fields } from './Fields/Fields';
import { validate } from './validate';
import { LocalAuthorPayment } from 'features/IncentivePayments/model';

type Props = {
  authorPayment: LocalAuthorPayment | SecurityDocumentPaymentRequest | null;
  disabled: boolean;
  changeField(key: keyof LocalAuthorPayment, value: ValueOf<LocalAuthorPayment>): void;
};

export function Departments(props: Props) {
  const { authorPayment, changeField, disabled } = props;

  return (
    <>
      <FormComponent.Line hasFreeFormat>
        <strong>Рассчитать фонд факультета(института):&nbsp;&nbsp;</strong>
        <Radio
          isDisabled={disabled}
          value={authorPayment?.distributionMethodBetweenFunds ?? undefined}
          list={[
            { label: 'По аффилированным', value: 'AFFILIATED' },
            { label: 'По назначенным к оплате', value: 'PAID' },
            { label: 'По всем номинантам', value: 'ALL' },
          ]}
          onChange={val => changeField('distributionMethodBetweenFunds', val)}
        />
        <Checkbox
          label="Редактировать вручную"
          checked={authorPayment?.isManualEdit}
          onChange={checked => changeField('isManualEdit', checked)}
        />
      </FormComponent.Line>

      <ListEdit
        rows={authorPayment?.departments ?? []}
        onChange={value => changeField('departments', value)}
        defaultRowsCount={6}
        maxHeight="auto"
        title="Фонд факультета (института)"
        visibleToolbarButtons={['edit']}
        isToolbarDisabled={!authorPayment?.isManualEdit || disabled}
        columns={[
          { label: '№', formatValue: (_, index) => String(index + 1) },
          { label: 'Факультет/Институт', formatValue: x => x.topLevelDepartment.name },
          { label: 'Количество авторов', formatValue: x => x.nomineeQuantity },
          { label: 'Доля(номинация)', formatValue: x => x.fraction },
          { label: 'Сумма, руб.', formatValue: x => formatNumber(x.amountMoney) },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (department, setDepartment) => <Fields department={department} setDepartment={setDepartment} />,
          validation: {
            checkIsValid: author => validate(author).every(x => x.isValid),
            onInvalidate: val => showErrors(validate, val),
          },
        }}
      />
    </>
  );
}
