import React from 'react';

import { Toolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};
function LeftPanelForThirdLevel({ customState, tableState }: Props) {
  const { buttons, isReportOpen, onReportClose, currentReport, projectId } = useController({ customState, tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        projectId={projectId}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
