import React from 'react';
import * as R from 'ramda';

import { FormComponent, ReferenceItem, SectionTitle, TextInput } from 'components';

import { Person } from 'types/models';

type Props = {
  scientist: Person.Scientist | null;
  onChange(fieldName: keyof Person.Scientist, value: string | ReferenceItem | null): void;
};

export function Contacts(props: Props) {
  const { scientist, onChange } = props;
  const getFieldHandler = (fieldName: keyof Person.Scientist) => R.partial(onChange, [fieldName]);

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column specialStyles={{ width: '70%' }}>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <SectionTitle
              title="Актуальные контакты"
              tooltip="Введите свои контактные данные. Они необходимы для связи с вами и формирования отчетных форм"
            />
            <FormComponent.Line>
              <FormComponent.Field label="Телефон">
                <TextInput value={scientist?.phoneOther || ''} onChange={getFieldHandler('phoneOther')} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Моб. телефон">
                <TextInput value={scientist?.mobilePhoneOther || ''} onChange={getFieldHandler('mobilePhoneOther')} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="E-mail">
                <TextInput value={scientist?.emailOther || ''} onChange={getFieldHandler('emailOther')} />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column>
            <SectionTitle
              title="Контакты из других систем"
              // eslint-disable-next-line max-len
              tooltip="Сведения о контактах загружаются из информационных систем кадрового учёта сотрудников и обучающихся. Исправить данные можно только в системах-источниках"
            />

            <FormComponent.Line>
              <FormComponent.Field label="Телефон:">
                <span>{scientist?.phoneIntegration || ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Моб. Телефон:">
                <span>{scientist?.mobilePhoneIntegration || ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="E-mail:">
                <span>{scientist?.emailIntegration || ''}</span>
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
