import React from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

type Props = {
  onSelect: (val: boolean) => void;
};

const MagazineAddTemplate: React.FC<Props> = ({ onSelect }) => (
  <FormComponent.Wrapper>
    <FormComponent.Wrapper>
      <p style={{ margin: 0 }}>Журнал какой категории вы хотите создать?</p>
    </FormComponent.Wrapper>
    <FormComponent.Actions>
      <Button mode={ButtonMode.secondary} text="Препринт" onClick={() => onSelect(true)} />
      <Button mode={ButtonMode.secondary} text="Обычный" onClick={() => onSelect(false)} />
    </FormComponent.Actions>
  </FormComponent.Wrapper>
);
export default React.memo(MagazineAddTemplate);
