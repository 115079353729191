import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isReportOpen,
    isViewFormOpen,
    buttons,
    currentReport,
    event,
    isParticipation,
    onReportClose,
    handleTemplateCloseViewForm,
    isHelpFormOpen,
    setIsHelpFormOpen,
  } = useController(tableState);

  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.ViewTemplate.Component
          isOpen={isViewFormOpen}
          isParticipation={isParticipation}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        event={event}
        conferenceEventId={event.id}
        contestEventId={event.id}
        exhibitionEventId={event.id}
        isEventParameterHidden
      />
      <ConfirmPopup
        title="Мероприятия и  участия в мероприятиях"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        <div className="modal-help">
          <p>
            На вкладке отображаются сведения о:
            <ul>
              <li>Проведённой конференции</li>
              <li>Участии в конференции с докладом по тематике проекта</li>
              <li>Презентации проекта (разработки) на выставке</li>
            </ul>
          </p>
          <p>
            Для отображения на этой вкладке сведений о конференции необходимо:
            <ul>
              <li>
                В списке конференций (пункт меню <strong>Конференции</strong>, или в&nbsp;
                <strong>личном кабинете вкладка Мероприятия, Конференции);</strong> выделить конференцию, нажать кнопку&nbsp;
                <strong>Финансовая поддержка конференции;</strong>
              </li>
              <li>
                На форме, в разделе <strong>Организовано при финансовой поддержке</strong> указать проект;
              </li>
              <li>Сохранить форму.</li>
            </ul>
          </p>
          <p>Для отображения на этой вкладке сведений об участии в конференции с докладом необходимо:</p>
          <ul>
            <li>
              В списке конференций (пункт меню <strong>Участие в конференции</strong>, или в&nbsp;
              <strong>личном кабинете вкладка Участие в мероприятия, Участие в конференции);</strong> выделить участие в
              конференции, нажать кнопку <strong>Связи с проектами;</strong>
            </li>
            <li>
              На форме, в разделе <strong>Проект</strong> указать проект;
            </li>
            <li>Сохранить форму.</li>
          </ul>
          <p>
            Для отображения на этой вкладке сведений о презентации проекта (разработки) на выставке необходимо:
            <ul>
              <li>
                В списке участий в выставке (пункт меню <strong>Участие в выставках</strong>, или в{' '}
                <strong>личном кабинете вкладка Участие в мероприятия, Участие в выставках);</strong> выделить участие в выставке,
                нажать кнопку <strong>Связи с проектами;</strong>
              </li>
              <li>
                На форме, в разделе <strong>Проект</strong> указать проект;
              </li>
              <li>Сохранить форму.</li>
            </ul>
            <strong className="primary">Важно!</strong> Все записи (об участии в конференции, докладе, участие в выставке с
            презентацией по тематике проекта) с отметкой <strong>Принят к отчёту</strong> подлежат регистрации в федеральной
            системе учёта результатов по научным проекта ЕГИСУ НИОКТР, в соответствии с Федеральным законом № 77-ФЗ.
          </p>
        </div>
      </ConfirmPopup>
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
