import { useCallback, useMemo, useState } from 'react';

import { ReferenceItem } from 'components';

import { Parameter, Table, ExtendedParameterListValue } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { BuilderProps, EnumProps } from 'features/BuildReportPopup/models';
import { ALL_RADIO_VALUE, PARTIAL_RADIO_VALUE, USER_RADIO_VALUE } from './helpers';

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = {
  enumProps?: EnumProps<CustomState, RequestData>;
} & BuilderProps;

const useController = <CustomState extends {} = {}, RequestData extends {} = {}>({
  parameters,
  setParameterValueByName,
  parameterName,
  enumProps,
}: Props<CustomState, RequestData>) => {
  const { enumMap } = useAppDataContext();
  const [refs, setRefs] = useState<ReferenceItem[]>([]);
  const [refsRadioValue, setRefsRadioValue] = useState<ExtendedParameterListValue['radioValue']>(ALL_RADIO_VALUE);
  const [userResultRadioValue, setUserResultRadioValue] = useState<ExtendedParameterListValue['userResultRadioValue']>(
    USER_RADIO_VALUE,
  );
  const currentParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameterName, parameters],
  );

  const setNextParameterValue = useCallback(
    ({
      nextRefs,
      nextRefsRadioValue,
      nextUserResultRadioValue,
    }: {
      nextRefs?: ReferenceItem[];
      nextRefsRadioValue?: ExtendedParameterListValue['radioValue'];
      nextUserResultRadioValue?: ExtendedParameterListValue['userResultRadioValue'];
    }) => {
      const usedRefs = nextRefs || refs;
      const usedRadioValue = nextRefsRadioValue || refsRadioValue;
      const usedUserResultRadioValue = nextUserResultRadioValue || userResultRadioValue;

      const nextParameterValue: ExtendedParameterListValue = {
        userResultRadioValue: usedUserResultRadioValue,
        radioValue: usedRadioValue,
        list: usedRefs,
      };
      setParameterValueByName({ name: parameterName, value: nextParameterValue });
    },
    [refs, refsRadioValue, userResultRadioValue, setParameterValueByName, parameterName],
  );

  const canBuild = useMemo<boolean>(() => !!currentParameter, [currentParameter]);

  const handleChangeRefs = useCallback(
    (nextRefs: ReferenceItem[]) => {
      setRefs(nextRefs);
      setNextParameterValue({ nextRefs });
    },
    [setNextParameterValue],
  );

  const handleChangeRefsRadioValue = useCallback(
    (nextValue: string) => {
      if (nextValue === ALL_RADIO_VALUE || nextValue === PARTIAL_RADIO_VALUE) {
        setNextParameterValue({ nextRefsRadioValue: nextValue });
        setRefsRadioValue(nextValue);
      }
    },
    [setNextParameterValue],
  );

  const handleChangeUserResultRadioValue = useCallback(
    (nextValue: ExtendedParameterListValue['userResultRadioValue']) => {
      setNextParameterValue({ nextUserResultRadioValue: nextValue });
      setUserResultRadioValue(nextValue);
    },
    [setNextParameterValue],
  );

  const enumValues = useMemo(() => {
    const currentEnumValues: Table.EnumValue[] = [];

    if (enumProps?.mode?.component === 'DefaultSelect') {
      currentEnumValues.push(...(enumMap[enumProps.mode.enumName]?.values || []));
    }

    return currentEnumValues;
  }, [enumMap, enumProps]);

  return {
    refs,
    refsRadioValue,
    currentParameter,
    handleChangeRefs,
    handleChangeRefsRadioValue,
    canBuild,
    enumMap,
    enumValues,
    handleChangeUserResultRadioValue,
    userResultRadioValue,
  };
};

export default useController;
