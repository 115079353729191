import React from 'react';

import { FormComponent, InputSelect, TextArea, TextAreaMode, TextInput, TextInputMode, Radio } from 'components';

import { formatNumber, getEnum } from 'utils/Helpers';
import { Unit } from 'types/models/PurchaseRequest';

type Props = {
  unit: Unit | null;
  setUnit(unit: any): void;
};

function UnitFields(props: Props) {
  const { unit, setUnit } = props;

  const typeOptions = getEnum('PurchaseRequestUnitType');

  return (
    <>
      <FormComponent.Template>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Тип" isRequired>
              <InputSelect
                options={typeOptions}
                value={typeOptions.find(x => x.value === unit?.type?.value) ?? null}
                onSelectChange={(e: any) => setUnit((prev: any) => ({ ...prev!, type: e }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Наименование" isRequired>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3 }}
                value={unit?.name || ''}
                onChange={val => setUnit((prev: any) => ({ ...prev!, name: val }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Количество">
              <TextInput
                mode={TextInputMode.number}
                value={unit?.quantity ? formatNumber(unit?.quantity || 0, 0) : ''}
                onChange={e => setUnit((prev: any) => ({ ...prev!, quantity: Number(e) }))}
                settings={{ decimal: false }}
                placeholder="0"
              />
            </FormComponent.Field>
            <FormComponent.Field label="Eдиница измерения">
              <TextInput value={unit?.unit || ''} onChange={e => setUnit((prev: any) => ({ ...prev!, unit: e }))} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ориентировочная цена за ед.">
              <TextInput
                mode={TextInputMode.number}
                value={unit?.perUnitPrice ? formatNumber(unit?.perUnitPrice?.toString(), 2) : undefined}
                onChange={e => setUnit((prev: any) => ({ ...prev!, perUnitPrice: parseFloat(e) }))}
                placeholder="0.00"
              />
            </FormComponent.Field>
            <FormComponent.Field label="Включая НДС">
              <Radio
                value={unit?.isNds ? 'true' : 'false'}
                list={[
                  { label: 'Включая НДС', value: 'true' },
                  { label: 'Без НДС', value: 'false' },
                ]}
                onChange={(e: any) => setUnit((prev: any) => ({ ...prev!, isNds: e === 'true' }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </>
  );
}

export { UnitFields };
