import React, { useMemo } from 'react';

import { IconButtonProps, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';

import { Permits } from 'utils/Permissions';
import * as modalTemplates from './modalTemplates';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export const LeftPanelForThirdLevel = React.memo(({ customState, tableState }: Props) => {
  const {
    isEditButtonDisabled,
    isViewButtonDisabled,
    handleEditButtonClick,
    handleAddButtonClick,
    handleViewButtonClick,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleCloseEditForm,
  } = useController({ tableState });

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        permissionName: Permits.COMPILATION_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.COMPILATION_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: Permits.COMPILATION_EDIT,
      },
    ],
    [isEditButtonDisabled, isViewButtonDisabled, handleEditButtonClick, handleAddButtonClick, handleViewButtonClick],
  );

  return (
    <>
      <modalTemplates.CompilationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        eventId={customState.eventId}
        compilationTypeCode={customState.compilationTypeCode}
      />
      {!isViewButtonDisabled && (
        <modalTemplates.CompilationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.CompilationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          eventId={customState.eventId}
        />
      )}
      <Toolbar buttons={buttons} />
    </>
  );
});
