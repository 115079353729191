import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetPublicationLibraryList: Table.Specification = {
  apiID: 'GetPublicationLibraryList',
  header: {
    firstLevel: {
      title: 'Список публикаций в каталоге Научной библиотеки',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};
