import { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';
import { tabsStreams } from 'components/Tabs/streams';

import { Form, Author, Document, RecordStatus as RS } from 'types/models';
import { ParticipationEventSource, Participation, Department } from 'types/models/Participation';
import { useLocalTableStreams } from 'features/Table/hooks';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { EMPTY_TABLE_STATE } from 'features/Form/constants';
import { useFormContext } from 'features/Form/hooks';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { useAppDataContext } from 'features/AppData/context';
import { EventFormat } from 'utils/Enums';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { validate, validateDocuments } from './validate';
import { formatToRequest } from './setup/formatToRequest';
import { setByResponse } from './setup/setByResponse';
import { showNotification } from 'features/Notifications';

type Props = {
  onClose: () => void;
};

const useController = ({ onClose }: Props) => {
  const {
    look: { id, relatedTableState, editMode, viewMode, sourceEvent, arm, disabledEvent },
  } = useFormContext<Form.ParticipationFormLook>();
  const { currentPerson } = useAppDataContext();
  const { isProfile } = usePrivatePageContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const tableStreams = useLocalTableStreams();

  const { methods: getParticipation, state: getParticipationState } = BackendAPI.useBackendAPI('GetParticipation');
  const { methods: saveParticipation, state: saveParticipationState } = BackendAPI.useBackendAPI('SaveParticipation');
  const { methods: getEvent, state: getEventState } = BackendAPI.useBackendAPI('GetEvent');
  const { isLoading } = useIsLoading({ callStates: [getParticipationState, saveParticipationState, getEventState] });

  const tabsId = 'ParticipationFormConcert';

  // popups
  const [isEventSelectWarningOpen, setIsEventSelectWarningOpen] = useState<boolean>(false);

  // fields
  const [members, setMembers] = useState<Author[]>([]);
  const [needClose, setNeedClose] = useState<boolean>(false);
  const [participationMessage, setParticipationMessage] = useState<string>('');
  const [participationMessageType, setParticipationMessageType] = useState<ReferenceItem>({ id: '', label: '' });
  const [isAdminMessagePopupOpen, setIsAdminMessagePopupOpen] = useState<boolean>(false);
  const [currentParticipationId, setCurrentParticipationId] = useState<null | string>(id || null);
  const [isSimilarParticipationOpen, setIsSimilarParticipationOpen] = useState<boolean>(false);
  const [isEditDepartmentsOpen, setIsEditDepartmentsOpen] = useState<boolean>(false);
  const [isEditDepartmentsToAddedFormOpen, setIsEditDepartmentsToAddedFormOpen] = useState<boolean>(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);
  const [participationData, setParticipationData] = useState<null | Participation>(null);
  const [isDocumentsPopupOpened, setIsDocumentsPopupOpened] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [event, setEvent] = useState<ParticipationEventSource | null>(null);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isAuthorsPopupOpen, setIsAuthorsPopupOpen] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<Form.Fields>({
    note: {
      isValid: true,
      value: '',
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
    },
    teamName: {
      isValid: true,
      value: '',
      title: 'Название коллектива участников',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          teamName: { ...prevState.teamName, value },
        }));
      },
    },
    form: {
      isValid: true,
      required: true,
      value: '',
      title: 'Формат участия',
      onChange: (value: ReferenceItem | null) => {
        const preparedValue: ReferenceItem = value || { id: '', label: '' };
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          form: { ...prevState.form, value: preparedValue, isValid: true },
        }));
      },
    },
  });

  const loadParticipation = useCallback(
    (participationId: string | null, callback?: () => void) => {
      getParticipation.callAPI(
        {
          participationId: participationId || undefined,
          modules: [
            'ID',
            'ACCESS_MODE',
            'MAIN',
            'EVENT',
            'DEPARTMENTS',
            'PUBLICATION',
            'PROJECTS',
            'MEMBERS',
            'AWARDS',
            'MOBILE_REQUESTS',
            'DOCUMENTS',
          ],
          arm,
          eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
          IsCollectiveExhibit: false,
          eventType: 'CONCERT',
        },
        {
          onSuccessfullCall: ({ data }) => {
            setDepartments(data.departments);
            setParticipationData(data);
            setDocuments(data.documents);
            setMembers(data.members);
            setEvent(sourceEvent || data.event);
            setByResponse({ setFormFields, data });
            if (typeof callback === 'function') {
              callback();
            }
          },
          onFailedCall: () => {
            if (typeof callback === 'function') {
              callback();
            }
          },
        },
      );
    },
    [getParticipation, arm, sourceEvent],
  );

  const handleSetEvent = useCallback(
    (nextEvent: ParticipationEventSource | null) => {
      if (nextEvent) {
        getEvent.callAPI(
          {
            id: nextEvent.id,
            simpleFields: {
              type: '',
              domain: 'EVENT',
              isInSummaryPlan: false,
              isCompilationPlanned: true,
              IsMagazineReleasePlanned: false,
              isStudent: false,
              memberCountPlan: 0,
              memberCountFact: 0,
            },
            attrIdFields: {},
            arrayFields: {
              Grntis: { translation: [] },
              MinistryEducations: { translation: [] },
              CriticalTechnologies: { translation: [] },
              Pnmitrs: { translation: [] },
              Pnrs: { translation: [] },
              Pnis: { translation: [] },
              Partners: { translation: [] },
            },
            format: EventFormat.OFFLINE,
          },
          {
            onSuccessfullCall: ({ data }) => {
              if (data.participations.length) {
                setIsSimilarParticipationOpen(true);
              }

              setEvent(() => ({
                name: data.fields.name,
                status: data.status.text,
                id: data.status.id,
                isOnline: data.fields.isOnline === 'true',
                site: data.fields.site,
                fullName: data.fullName,
                startDate: data.fields.startDate,
                endDate: data.fields.endDate,
                eventStatus: { label: data.fields.eventStatusLabel, id: data.fields.eventStatusId },
                format: data.format,
                entity: {
                  magazineSource: {
                    id: data.magazineReleases[0]?.upperSourceId || '',
                    name: data.magazineReleases[0]?.upperSourceName || '',
                    status: data.magazineReleases[0]?.upperSourceStatus || '',
                  },
                  isMagazineReleasePlanned: data.fields.isMagazineReleasePlanned === 'true',
                  isCompilationPlanned: data.fields.isMagazineReleasePlanned === 'true',
                  magazineReleases: data.magazineReleases,
                  compilations: data.compilations,
                  participations: data.participations,
                },
              }));

              if (data.participations.length) {
                setIsSimilarParticipationOpen(true);
              }
            },
          },
        );
      } else {
        setEvent(null);
      }
    },
    [getEvent],
  );

  const updateMembers = (membersArray: Author[]) => {
    setMembers(membersArray);
  };

  const afterSaveAction = useCallback(() => {
    onClose();
    tableStreams.reloadTable.push();
  }, [onClose, tableStreams.reloadTable]);

  const afterSaveAndContinueAction = useCallback(
    (nextParticipationId: string) => {
      updateWorkModeAfterSaveAndContinue();
      loadParticipation(nextParticipationId, () => {});
    },
    [loadParticipation, updateWorkModeAfterSaveAndContinue],
  );

  const openEditDepartmentsForm = useCallback(() => {
    setIsEditDepartmentsOpen(true);
  }, []);

  const closeEditDepartmentsForm = useCallback(() => {
    setIsEditDepartmentsOpen(false);
  }, []);

  const openEditDepartmentsToAddedForm = useCallback(() => {
    setIsEditDepartmentsToAddedFormOpen(true);
  }, []);

  const editDepartmentsAction = useCallback(() => {
    if (needClose) {
      afterSaveAction();
    } else if (currentParticipationId) {
      afterSaveAndContinueAction(currentParticipationId);
    }

    setIsEditDepartmentsToAddedFormOpen(false);
  }, [afterSaveAction, afterSaveAndContinueAction, currentParticipationId, needClose]);

  const closeEditDepartmentsToAddedForm = useCallback(() => {
    editDepartmentsAction();
  }, [editDepartmentsAction]);

  const afterSubmitEditDepartmentsToAddedForm = useCallback(() => {
    editDepartmentsAction();
  }, [editDepartmentsAction]);

  const handleOpenApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleCloseApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const afterSubmitApproveParticipation = useCallback(
    ({ nextRecordStatus }: { nextRecordStatus: RS.Status }) => {
      const nextParticipationData = participationData ? { ...participationData, status: nextRecordStatus } : null;
      setParticipationData(nextParticipationData);
    },
    [participationData],
  );

  const afterSubmitDepartmentsForm = useCallback(
    (nextDepartments: Department[]) => {
      const nextParticipationData = participationData ? { ...participationData, departments: nextDepartments } : null;
      setParticipationData(nextParticipationData);
      setDepartments(nextDepartments);
    },
    [participationData, setDepartments],
  );

  const saveForm = useCallback(
    (withMessage?: boolean) => {
      setIsAdminMessagePopupOpen(false);
      saveParticipation.callAPI(
        formatToRequest({
          formFields,
          members,
          event,
          documents,
          participationData,
          participationMessageType,
          participationMessage,
          currentParticipationId,
          withMessage,
          departments,
        }),
        {
          onSuccessfullCall: (result: any) => {
            const nextParticipationId = result.data?.id;
            showNotification({
              theme: 'success',
              message: currentParticipationId
                ? 'Участие в мероприятии успешно отредактировано'
                : 'Участие в мероприятии успешно сохранено',
            });
            setCurrentParticipationId(nextParticipationId);
            setParticipationMessage('');
            setParticipationMessageType({ id: '', label: '' });

            const isAddMode = !currentParticipationId;

            if (isAddMode) {
              openEditDepartmentsToAddedForm();
            } else if (needClose) {
              afterSaveAction();
            } else {
              afterSaveAndContinueAction(nextParticipationId);
            }
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      saveParticipation,
      formFields,
      members,
      event,
      documents,
      participationData,
      participationMessageType,
      participationMessage,
      currentParticipationId,
      departments,
      needClose,
      openEditDepartmentsToAddedForm,
      afterSaveAction,
      afterSaveAndContinueAction,
    ],
  );

  const handleFormSubmit = useCallback(
    (needCloseArg: boolean) => {
      setNeedClose(needCloseArg);
      const isValidDocuemnts = validateDocuments({ documents });
      const { isFormValid, invalidFieldKeys, nextNotification } = validate({ formFields, event, members });

      if (!isFormValid) {
        showNotification(nextNotification);
        if (invalidFieldKeys.length) {
          invalidFieldKeys.forEach((key: string) => {
            const preparedKey = key as keyof typeof formFields;
            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              [preparedKey]: { ...prevState[preparedKey], isValid: false },
            }));
          });
        }
        if (nextNotification.message) {
          showNotification(nextNotification);
        }
      } else if (isProfile && !members.find(x => x.person?.id === currentPerson?.id)) {
        setIsAuthorsPopupOpen(true);
      } else if (!isValidDocuemnts) {
        setIsDocumentsPopupOpened(true);
      } else if (needCloseArg) {
        setIsAdminMessagePopupOpen(true);
      } else {
        saveForm(false);
      }
    },
    [documents, formFields, event, members, isProfile, currentPerson?.id, saveForm],
  );

  const onConfirmAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
    if (!validateDocuments({ documents })) {
      setIsDocumentsPopupOpened(true);
    } else if (needClose) {
      setIsAdminMessagePopupOpen(true);
    } else {
      saveForm(false);
    }
  };

  const onResetAuthorsPopup = () => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 0, tabsId });
    getParticipation.callAPI(
      {
        arm: 'pc',
        eventType: 'CONCERT',
        modules: ['MEMBERS'],
        eventModules: ['MAIN'],
      },
      {
        onSuccessfullCall: (result: any) => {
          const authorMember = result?.data?.members[0];
          if (authorMember) setMembers((prevState: Author[]) => [...prevState, authorMember]);
          setIsAuthorsPopupOpen(false);
          if (!validateDocuments({ documents })) {
            setIsDocumentsPopupOpened(true);
          } else if (needClose) {
            setIsAdminMessagePopupOpen(true);
          } else {
            saveForm(false);
          }
        },
      },
    );
  };
  const onCloseAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
  };

  const goToDocumentsAction = useCallback(() => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 1, tabsId });
    setIsDocumentsPopupOpened(false);
  }, [tabsId]); // isDocumentsPopupOpened

  const confirmDocumentPopup = useCallback(() => {
    setIsDocumentsPopupOpened(false);
    if (needClose) {
      setIsAdminMessagePopupOpen(true);
    } else {
      saveForm(false);
    }
  }, [needClose, saveForm]);

  const handleCloseSimilarParticipation = useCallback(() => {
    setIsSimilarParticipationOpen(false);
  }, []);

  const acceptSimilarParticipation = useCallback(() => {
    setIsSimilarParticipationOpen(false);
    onClose();
  }, [onClose]);

  useLayoutEffect(() => {
    loadParticipation(currentParticipationId);
  }, []); // eslint-disable-line

  const actualTableState = useMemo(() => relatedTableState || EMPTY_TABLE_STATE, [relatedTableState]);

  return {
    isLoading,
    formFields,
    participationId: currentParticipationId,
    relatedTableState: actualTableState,
    workMode,
    documents,
    event,
    isEventSelectWarningOpen,
    members,
    isDocumentsPopupOpened,
    participationMessage,
    participationMessageType,
    isAdminMessagePopupOpen,
    disabledEvent,
    isSimilarParticipationOpen,
    tabsId,
    isEditDepartmentsOpen,
    isEditDepartmentsToAddedFormOpen,
    isApprovePopupOpen,
    participationData,
    afterSubmitDepartmentsForm,
    afterSubmitApproveParticipation,
    handleOpenApprovePopup,
    handleCloseApprovePopup,
    openEditDepartmentsForm,
    closeEditDepartmentsForm,
    closeEditDepartmentsToAddedForm,
    afterSubmitEditDepartmentsToAddedForm,
    setDocuments: (nextDocuments: Document[]) => setDocuments(nextDocuments),
    setEvent: handleSetEvent,
    setIsEventSelectWarningOpen,
    changeMembers: (val: Author[]) => updateMembers(val),
    handleFormSubmit,
    goToDocumentsAction,
    confirmDocumentPopup,
    setParticipationMessage,
    setParticipationMessageType,
    saveForm,
    acceptSimilarParticipation,
    handleCloseSimilarParticipation,
    isProfile,
    isAuthorsPopupOpen,
    onConfirmAuthorsPopup,
    onResetAuthorsPopup,
    onCloseAuthorsPopup,
  };
};

export { useController };
