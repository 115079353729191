import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const ExpositionAddTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  controllerIsForm,
  withHeaderId,
}: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look = {
    apiID: 'GetEvent',
    template: 'EventFormExposition',
    type: 'Exposition',
    relatedTableState,
    name,
    controllerIsForm,
    withHeaderId,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ExpositionAddTemplate);
