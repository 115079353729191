import { useCallback, useEffect } from 'react';

import { Performer } from 'types/models/ProgramRequest';
import { useAppDataContext } from 'features/AppData/context';
import { Member } from 'features/SelectPerson';
import { RequestPerformerRole } from 'utils/Enums';
import { getEnum } from 'utils/Helpers';
import { Item } from 'types/models/common';

type Props = {
  entry: Performer | null;
  onFieldChange(row: Performer | null): void;
  isEditPerformersOnly?: boolean;
};

export function useController({ entry, onFieldChange, isEditPerformersOnly }: Props) {
  const { enumMap } = useAppDataContext();
  const roleOptions = getEnum('RequestPerformerRole', enumMap).filter(r =>
    isEditPerformersOnly ? r.value !== RequestPerformerRole.LEADER : true,
  ) as Item<RequestPerformerRole>[];

  const handleRoleChange = useCallback(
    () => (option: any) => {
      onFieldChange({ ...entry!, role: roleOptions.find(x => x.value === option.value) || null });
    },
    [onFieldChange, entry, roleOptions],
  );

  useEffect(() => {
    if (isEditPerformersOnly) {
      const performerRole = roleOptions.find(({ value }) => value === RequestPerformerRole.PERFORMER);
      if (performerRole) {
        handleRoleChange()(performerRole);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePersonChange = useCallback(
    () => (value: Member | null) => {
      const nextPerformer: Performer | null = value
        ? {
            id: entry?.id || null,
            role: entry?.role || null,
            historyLabel: entry?.historyLabel || '',
            ...value,
          }
        : null;
      onFieldChange(nextPerformer);
    },
    [onFieldChange, entry],
  );

  return {
    handlePersonChange,
    handleRoleChange,
    roleOptions,
  };
}
