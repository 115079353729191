import { useMemo, useState, useEffect, useCallback } from 'react';
import { ReferenceItem } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { JointEvent } from 'types/models';
import { Item } from 'types/models/common';
import { getEnum } from 'utils/Helpers';

type Props = {
  document: JointEvent.Enterprise | null;
  onChange(row: JointEvent.Enterprise): void;
};

export function useController({ document, onChange }: Props) {
  const { enumMap } = useAppDataContext();

  const roles = useMemo(() => getEnum('EventEnterpriseRole', enumMap), [enumMap]);
  const defaultRole = useMemo(() => roles[0] || { label: '', value: '' }, [roles]);

  const [organization, setOrganization] = useState<ReferenceItem>({ id: '', label: '' });
  const [role, setRole] = useState<Item>(defaultRole);

  const emptyRow = useMemo<JointEvent.Enterprise>(
    () => ({
      id: null,
      role: defaultRole,
      element: { id: '', label: '' },
      position: '',
    }),
    [defaultRole],
  );

  const handleChangeOrganization = useCallback(
    (value: ReferenceItem) => {
      if (document) {
        onChange({ ...document, element: value });
      } else {
        onChange({ ...emptyRow, element: value });
      }
      setOrganization(value);
    },
    [document, emptyRow, onChange],
  );

  const handleChangeRole = useCallback(
    (value: Item) => {
      if (document) {
        onChange({ ...document, role: value });
      } else {
        onChange({ ...emptyRow, role: value });
      }
      setRole(value);
    },
    [document, emptyRow, onChange],
  );

  useEffect(() => {
    if (document) {
      if (document.role) {
        setRole(document.role);
      }
      if (document.element) {
        setOrganization(document.element);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    role,
    roles,
    organization,
    handleChangeRole,
    handleChangeOrganization,
  };
}
