import React from 'react';

import { FormComponent, Modal } from 'components';
import { DataGrid } from 'features/Table';
import { GetEnterpriseCustomerSelectList } from 'features/Table/specifications';
import useController from './controller';
import { MipEnterpriseCustomer } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (enterprise: MipEnterpriseCustomer | null) => void;
};

export const AddMip = ({ isOpen, onClose, onSubmit }: Props) => {
  const { onGridSubmit } = useController({ onSubmit });
  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Список организаций из справочника "Заказчики"' size="large">
      <FormComponent.Wrapper>
        <FormComponent.Description mode="warning">
          Проверьте список организаций, возможно, МИП уже присутствует в этом списке, если это так, то выберите её. Если
          отсутствует, то добавьте организацию с помощью кнопки Добавить, а после сохранения, выберите её и откроется форма для
          редактирования МИП. Краткие сведения о МИП сохранятся в справочнике "Заказчики"
        </FormComponent.Description>
        <DataGrid
          specification={{
            ...GetEnterpriseCustomerSelectList(),
            onSubmitTable: onGridSubmit,
          }}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
};
