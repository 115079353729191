import React, { useEffect } from 'react';

import { FormComponent, Tabs, Tab } from 'components';

import { Contracts } from 'features/Form/looks/securityDocumentPatent/PatentForm/Tabs/Contracts/Contracts';
import { GetContractPaymentLogList, GetSecurityDocumentIncentivePaymentList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { ProjectInfoModal } from 'features/ProjectInfoModal';
import { SecurityDocumentTypes } from 'types/models';
import { useController } from './controller';
import { Documents } from 'features/Documents';
import { Document } from './Tabs/Document/Document';
import { Classifiers } from './Tabs/Classifiers/Classifiers';
import { Nma } from './Tabs/Nma/Nma';
import { Payments } from './Tabs/Payments/Payments';
import { Toolbar } from './Toolbar/Toolbar';
import { useFormContext } from 'features/Form/hooks';
import { NotRegistredDocument } from './Tabs/NotRegistredDocument/NotRegistredDocument';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function PatentForm({ viewMode, editMode, onClose }: Props) {
  const {
    patentId,
    patent,
    setPatent,
    formFields,
    handleClassifierChange,
    workMode,
    documents,
    setDocuments,
    departments,
    setDepartments,
    handleFormSubmit,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
    mpks,
    setMpks,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      const EntityTitles: Record<string, string> = {
        PATENT: 'патента',
        EVIDENCE: 'свидетельства',
        KNOW_HOW: 'ноу-хау',
        NON_REGISTERED: 'объекта авторского права',
      };

      setTitle(
        [
          (() => {
            if (workMode === 'addMode') {
              return 'Добавление';
            }
            if (workMode === 'viewMode') {
              return 'Просмотр';
            }
            return 'Редактирование';
          })(),
          patent?.type?.value ? EntityTitles[patent?.type?.value] : '',
          patent?.id ? `(ID ${patent?.id})` : '',
        ]
          .filter(x => x)
          .join(' '),
      );
    }
  }, [patent?.id, patent?.type?.value, setTitle, workMode]);
  return (
    <FormComponent.Template>
      <Toolbar
        patent={patent}
        formFields={formFields}
        onSave={needClose => handleFormSubmit(needClose)}
        onSaveDraft={() => handleFormSubmit(false)}
        onViewProjectInfo={() => {
          setIsProjectInfoModalOpen(true);
        }}
        disabled={viewMode}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            {!!patent?.type?.value && patent?.type?.value !== SecurityDocumentTypes.NON_REGISTERED && (
              <Document
                patent={patent}
                setPatent={setPatent}
                formFields={formFields}
                disabled={viewMode ?? false}
                departments={departments}
                setDepartments={setDepartments}
                mpks={mpks}
                setMpks={setMpks}
              />
            )}
            {!!patent?.type?.value && patent?.type?.value === SecurityDocumentTypes.NON_REGISTERED && (
              <NotRegistredDocument
                patent={patent}
                setPatent={setPatent}
                formFields={formFields}
                disabled={viewMode ?? false}
                departments={departments}
                setDepartments={setDepartments}
                mpks={mpks}
                setMpks={setMpks}
              />
            )}
          </Tab>
          <Tab title="Классификаторы">
            <Classifiers patent={patent} makeChangeHandler={handleClassifierChange} disabled={viewMode ?? false} />
          </Tab>
          <Tab title="НМА">
            <Nma formFields={formFields} disabled={viewMode ?? false} />
          </Tab>
          <Tab
            title="Оплата пошлины"
            isVisible={
              patent?.type?.value !== SecurityDocumentTypes.KNOW_HOW &&
              patent?.type?.value !== SecurityDocumentTypes.NON_REGISTERED
            }
          >
            <Payments patent={patent} setPatent={setPatent} disabled={viewMode ?? false} />
          </Tab>
          <Tab title="Документы">
            <Documents
              isCanIsSharedEdit
              sharedLabel="Отображать в списке документов проекта и РИД"
              documents={documents}
              setDocuments={setDocuments}
              disabled={workMode === 'viewMode'}
            />
          </Tab>
          <Tab title="Договоры на использование ИС">
            <Contracts patent={patent} disabled={viewMode ?? false} />
          </Tab>
          <Tab title="Лицензионные отчисления">
            <DataGrid
              specification={GetContractPaymentLogList({
                isHideFirstLevel: true,
                securityDocument: patentId ?? '-2',
                isWithTemplatesPanel: false,
                isFiltersHidden: true,
              })}
            />
          </Tab>
          <Tab title="Стимулирующие выплаты" isVisible={patent?.type?.value !== SecurityDocumentTypes.NON_REGISTERED}>
            <DataGrid
              specification={GetSecurityDocumentIncentivePaymentList({
                securityDocumentId: patentId || '-2',
                isFiltersHidden: true,
              })}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <ProjectInfoModal
        isOpen={isProjectInfoModalOpen}
        onClose={() => setIsProjectInfoModalOpen(false)}
        project={patent?.project ?? null}
      />
    </FormComponent.Template>
  );
}

export const Component = React.memo(PatentForm);
