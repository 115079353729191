import React, { useEffect } from 'react';

import { Tabs, Tab, ConfirmPopup, FormComponent } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { ParticipationHeader } from 'features/Form/views/ModalHeader/participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Permits } from 'utils/Permissions/Permits';
import { ControlPanel } from '../views/ControlPanel';
import { useController } from './controller';
import { Component as SimilarParticipations } from '../views/SimilarParticipations';
import { EditParticipationDepartments } from '../views/EditParticipationDepartments';
import { useFormContext } from 'features/Form/hooks';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const ParticipationFormExposition = ({ onClose, viewMode }: Props) => {
  const {
    isLoading,
    // isInfoOpen,
    // onInfoClose,
    isProfile,
    participationData,
    formFields,
    presentationProject,
    participationId,
    relatedTableState,
    workMode,
    documents,
    mobileRequests,
    awards,
    event,
    isEventSelectWarningOpen,
    members,
    isDocumentsPopupOpened,
    participationMessage,
    participationMessageType,
    isAdminMessagePopupOpen,
    tabsId,
    enterprise,
    disabledEvent,
    isRequestPaymentView,
    isSimilarParticipationOpen,
    isEditDepartmentsOpen,
    isEditDepartmentsToAddedFormOpen,
    isApprovePopupOpen,
    financingProjects,
    changeFinancingProjects,
    setFinancingProjectsAccept,
    setFinancingProjectsCancelAcception,
    afterSubmitDepartmentsForm,
    afterSubmitApproveParticipation,
    handleOpenApprovePopup,
    handleCloseApprovePopup,
    setDocuments,
    setAwards,
    setEvent,
    setIsEventSelectWarningOpen,
    changeMembers,
    handleFormSubmit,
    goToDocumentsAction,
    confirmDocumentPopup,
    setParticipationMessage,
    setParticipationMessageType,
    saveForm,
    openEditDepartmentsForm,
    closeEditDepartmentsForm,
    closeEditDepartmentsToAddedForm,
    afterSubmitEditDepartmentsToAddedForm,
    acceptSimilarParticipation,
    handleCloseSimilarParticipation,
    paymentString,
    extraToolbarButtons,
    changeCurrentExponent,
    setLocalProjects,
    setLocalProjectsAccept,
    setLocalProjectsCancelAcception,
    isVisibleProjectField,
    participationPresentationForm,
    handlePresentationFormChange,
    isVisiblePresentationForm,
    isVisibleExponentSection,
    isVisibleExponentField,
    exponentProduct,
    isVisibleExpositionFields,
    isAuthorsPopupOpen,
    onConfirmAuthorsPopup,
    onCloseAuthorsPopup,
    onResetAuthorsPopup,
  } = useController({ onClose });

  const {
    look: { setTitle },
  } = useFormContext();

  const { PARTICIPATION_EXPOSITION_DEPARTMENT_EDIT, PARTICIPATION_EXPOSITION_APPROVE } = Permits;

  useEffect(() => {
    if (setTitle) {
      setTitle(
        ParticipationHeader({
          workMode,
          departments: participationData?.departments || [],
          status: participationData?.status.label || '',
          participationId: participationId || '',
          participationTypeCode: ParticipationTypes.PARTICIPATION_EXPOSITION.code,
        }),
      );
    }
  }, [participationData?.departments, participationData?.status.label, participationId, setTitle, workMode]);

  return (
    <FormComponent.Template>
      {!viewMode && (
        <ControlPanel
          permits={{
            approve: PARTICIPATION_EXPOSITION_APPROVE,
            edit: PARTICIPATION_EXPOSITION_DEPARTMENT_EDIT,
          }}
          type={ParticipationTypes.PARTICIPATION_EXPOSITION}
          isDisabled={isLoading}
          isProfile={isProfile}
          isOnlyEditing={isRequestPaymentView}
          addCallback={handleFormSubmit}
          workMode={workMode}
          id={participationId || ''}
          isApprovePopupOpen={isApprovePopupOpen}
          afterSubmitDepartmentsForm={afterSubmitDepartmentsForm}
          handleOpenApprovePopup={handleOpenApprovePopup}
          handleCloseApprovePopup={handleCloseApprovePopup}
          isEditDepartmentsOpen={isEditDepartmentsOpen}
          openEditDepartmentsForm={openEditDepartmentsForm}
          closeEditDepartmentsForm={closeEditDepartmentsForm}
          afterSubmitApproveParticipation={afterSubmitApproveParticipation}
        />
      )}

      <ConfirmPopup
        title="Предупреждение"
        okButtonText="Принять"
        isOpen={isEventSelectWarningOpen}
        onClose={() => setIsEventSelectWarningOpen(false)}
        text="Не выбрано мероприятие."
        onConfirm={() => setIsEventSelectWarningOpen(false)}
      />
      <EditParticipationDepartments
        id={participationId || ''}
        isOpen={isEditDepartmentsToAddedFormOpen}
        onClose={closeEditDepartmentsToAddedForm}
        afterSubmit={afterSubmitEditDepartmentsToAddedForm}
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
        participantsTitle="Список участников:"
        hideToolbarButtons
        hideActionButtons={false}
      />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="Описание">
            <TabsContent.AboutComponent
              workMode={workMode}
              formFields={formFields}
              event={event}
              setEvent={setEvent}
              relatedTableState={relatedTableState}
              members={members}
              changeMembers={changeMembers}
              disabledEvent={disabledEvent}
              enterprise={enterprise}
              id={participationId || ''}
              extraToolbarButtons={extraToolbarButtons}
              changeCurrentExponent={changeCurrentExponent}
              awards={awards}
              presentationProject={presentationProject}
              setLocalProjects={setLocalProjects}
              setLocalProjectsAccept={setLocalProjectsAccept}
              setLocalProjectsCancelAcception={setLocalProjectsCancelAcception}
              isVisibleProjectField={isVisibleProjectField}
              participationPresentationForm={participationPresentationForm}
              handlePresentationFormChange={handlePresentationFormChange}
              isVisiblePresentationForm={isVisiblePresentationForm}
              isVisibleExponentSection={isVisibleExponentSection}
              isVisibleExponentField={isVisibleExponentField}
              exponentProduct={exponentProduct}
              isVisibleExpositionFields={isVisibleExpositionFields}
            />
          </Tab>
          <Tab title="Награды">
            <TabsContent.Awards workMode={workMode} members={members} awards={awards} setAwards={setAwards} />
          </Tab>
          <Tab title="Связи с проектами">
            <TabsContent.FinancialSupportComponent
              financingProjects={financingProjects}
              setFinancingProjects={changeFinancingProjects}
              setFinancingProjectsAccept={setFinancingProjectsAccept}
              setFinancingProjectsCancelAcception={setFinancingProjectsCancelAcception}
              relatedTableState={relatedTableState}
              workMode={workMode}
              mobileRequests={mobileRequests}
              paymentString={paymentString}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.DocumentsComponent documents={documents} setDocuments={setDocuments} workMode={workMode} />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent
              participationId={participationId}
              relatedTableState={relatedTableState}
              workMode={workMode}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <ConfirmPopup
        title="Внимание"
        text={
          // eslint-disable-next-line max-len
          'На вкладке Документы прикрепите скан-копию программы мероприятия или файл с документом подтверждающим участие.</br><strong>Наличие прикрепленного документа - это обязательное условие для утверждения участия</strong>'
        }
        isOpen={isDocumentsPopupOpened}
        okButtonText="Продолжить"
        resetButtonText="Перейти на вкладку документы"
        onClose={goToDocumentsAction}
        onConfirm={confirmDocumentPopup}
        icon="info"
      />
      <ConfirmPopup
        title="Внимание!"
        icon="info"
        isOpen={isAuthorsPopupOpen}
        okButtonText="Нет"
        resetButtonText="Добавить"
        onConfirm={onConfirmAuthorsPopup}
        onClose={onCloseAuthorsPopup}
        onReset={onResetAuthorsPopup}
      >
        <div>
          <div>
            <strong>
              В списке «Мои участия», отображаются только те записи об участии, в которых вы указаны в Сведениях об участниках
              выставки, иначе отобразится в списке «Все участия»
            </strong>
          </div>
          <br />
          <span>Вашей персоны нет в списке участников выставки. Добавить?</span>
        </div>
      </ConfirmPopup>

      <AdministratorMessagePopup
        title="Добавьте стандартное и/или текстовое сообщение администратору"
        isOpen={isAdminMessagePopupOpen}
        onConfirm={() => {
          saveForm(true);
        }}
        publicationMessage={participationMessage}
        updatePublicationMessage={setParticipationMessage}
        publicationMessageType={participationMessageType}
        setPublicationMessageType={setParticipationMessageType}
        onConfirmWithoutMessage={() => {
          saveForm(false);
        }}
        fieldVisibleMode="SYSTEM"
        command="RefParticipationFeedlineMessage"
      />
      <SimilarParticipations
        eventStatus={event?.status || ''}
        eventId={event?.id || ''}
        workMode={workMode}
        isOpen={isSimilarParticipationOpen}
        acceptSimilarParticipation={acceptSimilarParticipation}
        onClose={handleCloseSimilarParticipation}
        sourceEvent={event?.entity?.participations || []}
        eventName={event?.fullName || ''}
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
      />
    </FormComponent.Template>
  );
};

export const Component = React.memo(ParticipationFormExposition);
