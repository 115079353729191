import { useCallback, useMemo, useState } from 'react';

import { IconButtonProps, buttonIcons } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { RequestStatus } from 'utils/Enums/RequestStatus';
import { ProgramRequestTab } from 'utils/Permissions/ProgramRequestTab';
import { NirRequestTab } from 'utils/Permissions/NirRequestTab';
import { isHasPermission, isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { MobileRequestStatus, NirRequestStatus, NirRequestTenderType, RequestType, TenderRequestType } from 'utils/Enums';
import { TenderInfo } from 'types/models/Form/look/programRequest';
import { showNotification } from 'features/Notifications';
import { MobileRequestTab } from 'utils/Permissions/MobileRequestTab';

type Props = {
  tableState: Table.State;
};
export function useController(props: Props) {
  const { tableState } = props;
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [tenderInfo, setTenderInfo] = useState<TenderInfo | null>(null);
  const [nirRequestTenderType, setNirRequestTenderType] = useState<NirRequestTenderType | null>(null);
  const [tenderRequestType, setTenderRequestType] = useState<TenderRequestType | null>(null);

  const requestType = useMemo<RequestType>(() => selectedRow?.['id:Type'] as RequestType, [selectedRow]);

  const isEditPermitted = useMemo(() => {
    if (!selectedRow) return false;
    if (requestType === RequestType.PROGRAM_REQUEST) {
      return isHasSomePermission(
        userPermission,
        (Object.keys(ProgramRequestTab) as Array<ProgramRequestTab>).map(
          tabKey => Permits[`PROGRAM_REQUEST_EDIT_${selectedRow?.[`id:ProgramRequestStatus`] as RequestStatus}_${tabKey}`],
        ),
      );
    }
    if (requestType === RequestType.NIR_REQUEST) {
      return isHasSomePermission(
        userPermission,
        (Object.keys(NirRequestTab) as Array<NirRequestTab>).map(
          tabKey => Permits[`NIR_REQUEST_EDIT_${selectedRow?.[`id:NirRequestStatus`] as NirRequestStatus}_${tabKey}`],
        ),
      );
    }
    if (requestType === RequestType.MOBILE_REQUEST) {
      return isHasSomePermission(
        userPermission,
        (Object.keys(MobileRequestTab) as Array<MobileRequestTab>).map(
          tabKey => Permits[`MOBILE_REQUEST_EDIT_${selectedRow?.[`id:MobileRequestStatus`] as MobileRequestStatus}_${tabKey}`],
        ),
      );
    }
    return false;
  }, [requestType, selectedRow, userPermission]);

  const isDeletePermitted = useMemo(() => {
    if (requestType === RequestType.PROGRAM_REQUEST) return isHasPermission(userPermission, Permits.PROGRAM_REQUEST_DELETE_DRAFT);
    if (requestType === RequestType.NIR_REQUEST) return isHasPermission(userPermission, Permits.NIR_REQUEST_DELETE_DRAFT);
    if (requestType === RequestType.MOBILE_REQUEST) return isHasPermission(userPermission, Permits.MOBILE_REQUEST_DELETE_DRAFT);
    return false;
  }, [requestType, userPermission]);

  const handleSubmitTenderTypeModal = useCallback(
    (tenderType: NirRequestTenderType) => {
      setNirRequestTenderType(tenderType);
    },
    [setNirRequestTenderType],
  );

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);
  const handleViewButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true' || requestType === RequestType.MOBILE_REQUEST)
      setIsViewFormOpen(true);
    else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [requestType, selectedRow?.IsLeaderOrResponsiblePerformer]);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true' || requestType === RequestType.MOBILE_REQUEST)
      setIsEditFormOpen(true);
    else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [requestType, selectedRow?.IsLeaderOrResponsiblePerformer]);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setTenderInfo(null);
    setNirRequestTenderType(null);
    setTenderRequestType(null);
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: selectedRow?.id,
      command:
        requestType === RequestType.PROGRAM_REQUEST
          ? 'DeleteRequest'
          : requestType === RequestType.NIR_REQUEST
          ? 'DeleteNirRequest'
          : requestType === RequestType.MOBILE_REQUEST
          ? 'DeleteMobileRequest'
          : 'UNKNOWN_TYPE',
      deletedItemPropName:
        requestType === RequestType.PROGRAM_REQUEST
          ? 'RequestInfo'
          : requestType === RequestType.NIR_REQUEST
          ? 'NirRequest'
          : requestType === RequestType.MOBILE_REQUEST
          ? 'MobileRequest'
          : 'UNKNOWN_TYPE',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, requestType, selectedRow?.id, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true' || requestType === RequestType.MOBILE_REQUEST)
      setIsDeleteConfirmPopupOpen(true);
    else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [requestType, selectedRow?.IsLeaderOrResponsiblePerformer]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        onClick: handleAddButtonClick,
        profilePermissionName: [Permits.PROGRAM_REQUEST_ADD, Permits.NIR_REQUEST_ADD, Permits.MOBILE_REQUEST_ADD],
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: !selectedRow || !isEditPermitted,
        onClick: handleEditButtonClick,
        profilePermissionName: [
          ...(Object.keys(RequestStatus) as Array<RequestStatus>).flatMap(statusKey =>
            (Object.keys(ProgramRequestTab) as Array<ProgramRequestTab>).map(
              tabKey => Permits[`PROGRAM_REQUEST_EDIT_${statusKey}_${tabKey}`],
            ),
          ),
          ...(Object.keys(NirRequestStatus) as Array<NirRequestStatus>).flatMap(statusKey =>
            (Object.keys(NirRequestTab) as Array<NirRequestTab>).map(
              tabKey => Permits[`NIR_REQUEST_EDIT_${statusKey}_${tabKey}`],
            ),
          ),
          ...(Object.keys(MobileRequestStatus) as Array<MobileRequestStatus>).flatMap(statusKey =>
            (Object.keys(MobileRequestTab) as Array<MobileRequestTab>).map(
              tabKey => Permits[`MOBILE_REQUEST_EDIT_${statusKey}_${tabKey}`],
            ),
          ),
        ],
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled:
          ![
            selectedRow?.['id:ProgramRequestStatus'],
            selectedRow?.['id:NirRequestStatus'],
            selectedRow?.['id:MobileRequestStatus'],
          ].includes('DRAFT') || !isDeletePermitted,
        profilePermissionName: [
          Permits.PROGRAM_REQUEST_DELETE_DRAFT,
          Permits.MOBILE_REQUEST_DELETE_DRAFT,
          Permits.NIR_REQUEST_DELETE_DRAFT,
        ],
      },
    ],
    [
      handleAddButtonClick,
      handleDeleteButtonClick,
      handleEditButtonClick,
      handleHelpButtonClick,
      handleViewButtonClick,
      isDeletePermitted,
      isEditPermitted,
      selectedRow,
    ],
  );
  return {
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    buttons,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    requestType,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    tenderInfo,
    setTenderInfo,
    nirRequestTenderType,
    handleSubmitTenderTypeModal,
    tenderRequestType,
    setTenderRequestType,
  };
}
