import { Table } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { HistoryEntity } from 'services/BackendAPI/configurations/author/types';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const [isOpenRelationModal, setIsOpenRelationModal] = useState(false);

  const historyEntities = useMemo<HistoryEntity[]>(
    () => tableState.selectedRows.map(({ id, type, label }) => ({ id, type, label })),
    [tableState.selectedRows],
  );

  const isEditRelationDisabled = useMemo(() => historyEntities.length === 0, [historyEntities.length]);

  const onEditRelationsClick = useCallback(() => {
    setIsOpenRelationModal(true);
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.editRelations,
        title: 'Изменить связь',
        isDisabled: isEditRelationDisabled,
        onClick: onEditRelationsClick,
      },
    ],
    [isEditRelationDisabled, onEditRelationsClick],
  );

  const closeRelationsModal = useCallback(() => {
    setIsOpenRelationModal(false);
  }, []);

  return {
    isOpenRelationModal,
    buttons,
    historyEntities,
    closeRelationsModal,
  };
};

export default useController;
