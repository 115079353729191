import React, { useMemo } from 'react';

import { buttonIcons, IconButtonProps, Toolbar } from 'components';

import { Column } from 'types/models/Table';
import { ColumnPicker } from '../ColumnPicker';
import { ReloadTableArguments } from 'features/Table/types';

export type Props = {
  columns: Column[];
  isTableExtended: boolean;
  isTableExtendable: boolean;
  reloadTable: (reloadTableArguments: ReloadTableArguments) => Promise<void>;
  resetFilters: () => Promise<void>;
  toggleIsExtended: () => Promise<void>;
  toggleIsFiltersShown: () => void;
  onHiddenColumnsChange: (nextHiddenColumnNames: string[]) => void;
};

export const ControlPanel = ({
  columns,
  isTableExtended,
  isTableExtendable,
  reloadTable,
  resetFilters,
  toggleIsExtended,
  toggleIsFiltersShown,
  onHiddenColumnsChange,
}: Props) => {
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.closeFilter,
        title: 'Очистить все поля фильтрации',
        onClick: resetFilters,
      },
      {
        icons: buttonIcons.filter,
        title: 'Показать/спрятать фильтры',
        onClick: toggleIsFiltersShown,
      },
      ...(isTableExtendable
        ? [
            {
              title: isTableExtended ? 'Перейти в режим ввода' : 'Перейти в режим для анализа',
              icons: isTableExtended ? buttonIcons.narrow : buttonIcons.expand,
              onClick: toggleIsExtended,
            },
          ]
        : []),
      {
        icons: buttonIcons.update,
        title: 'Обновить',
        onClick: () => reloadTable({ needResetSelectedRows: true }),
      },
    ],
    [isTableExtendable, isTableExtended, reloadTable, resetFilters, toggleIsFiltersShown, toggleIsExtended],
  );

  return (
    <>
      <ColumnPicker columns={columns} onHiddenColumnsChange={onHiddenColumnsChange} />
      <Toolbar buttons={buttons} />
    </>
  );
};
