import React from 'react';

import { ListEdit, buttonIcons, IconButton, Modal } from 'components';
import { Project } from 'types/models';
import { validateAct } from './validate';
import { ActFields } from 'features/Form/looks/project/ProjectForm/views/Acts/ActFields';
import { showNotification } from 'features/Notifications';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { calcStageTotalAmount } from 'features/Form/looks/project/ProjectForm/views/Acts/helpers';
import { useController } from './controller';

type Props = {
  project: Project.Project | null;
  acts: Project.Act[];
  onChange(refs: Project.Act[]): void;
  disabled?: boolean;
};

export function Acts(props: Props) {
  const { project, acts, onChange, disabled } = props;

  const {
    button,
    onClickPrint,
    isProjectNotSavedPopupOpen,
    setIsProjectNotSavedPopupOpen,
    projectActId,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController();

  return (
    <>
      <ListEdit
        rows={acts ?? []}
        columns={[
          { label: 'Номер акта', formatValue: a => a.number },
          { label: 'Этап', formatValue: a => `${a?.stage?.label}` },
          { label: 'Подразделение', formatValue: a => `${a?.departments.map(d => d?.department?.unit?.label).join(', ')}` },
          { label: 'Сумма', formatValue: a => calcStageTotalAmount(project, a) },
          { label: 'Совет', formatValue: a => `${a?.council?.type?.label || ''}` },
        ]}
        onChange={onChange}
        defaultRowsCount={26}
        maxHeight="none"
        isDeleteConfirmEnabled
        extraToolbarButtons={[
          {
            icons: buttonIcons.print,
            title: 'Печать АКТа',
            checkIsDisabled: row => !row,
            onClick: onClickPrint,
          },
        ]}
        isToolbarDisabled={disabled}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Добавление АКТа',
          modalEditTitle: 'Редактирование АКТа',
          isFullScreenModal: true,
          renderComponent: (act, setAct) => <ActFields act={act} project={project} onActChange={setAct} />,
          validation: {
            checkIsValid: act => validateAct(act).every(x => x.isValid),
            onInvalidate: act => {
              const info = validateAct(act);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        projectActId={projectActId ?? ''}
      />
      <Modal
        isOpen={isProjectNotSavedPopupOpen}
        onClose={() => setIsProjectNotSavedPopupOpen(false)}
        title="Проект не сохранен"
        size="small"
      >
        <>
          Выполните команду "Сохранить и продолжить" проект <IconButton {...button} /> и затем повторите команду Печать АКТа
        </>
      </Modal>
    </>
  );
}
