import React, { useState, useCallback, useMemo } from 'react';

import { ConfirmPopup, IconButtonProps, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const tableStreams = useLocalTableStreams();

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    if (tableState.selectedRows.length) {
      setIsDeleteConfirmPopupOpen(true);
    }
  }, [tableState]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);
  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteQnaFrequentQuestion',
      deletedItemPropName: 'QnaFrequentQuestion',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Изменить',
        code: 'edit',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'delete',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: handleDeleteButtonClick,
      },
    ],
    [handleAddButtonClick, rowsAvailability.SINGLE_SELECTED, handleEditButtonClick, handleDeleteButtonClick],
  );

  return (
    <>
      <modalTemplates.QnaFrequentAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        id={undefined}
        relatedTableState={tableState}
      />
      <modalTemplates.QnaFrequentEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={handleTemplateCloseEditForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный вопрос?"
        isOpen={isDeleteConfirmPopupOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={handleCloseDeleteConfirmPopup}
        onConfirm={handleConfirmDeleteConfirmPopup}
      />

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
