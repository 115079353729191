import { PurchaseRequest } from 'types/models/PurchaseRequest';

export function validate(pr: PurchaseRequest | null) {
  if (!pr) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  return [
    { isValid: Boolean(pr.project), invalidMessage: 'Не указан проект' },
    { isValid: Boolean(pr.applicant), invalidMessage: 'Выберите заявителя' },
    { isValid: Boolean(pr.contractKind), invalidMessage: 'Выберите вид договора' },
    { isValid: Boolean(pr.name), invalidMessage: 'Укажите наименование закупки' },
  ];
}
