import * as XML from 'xml-js';
import * as T from './types';

import {
  convertSaveResponseXMLElementToOutput,
  makeParametersXMLElement,
  makeTableRequestXMLConverter,
} from 'services/BackendAPI/configurations/commonConverters';
import { ContestRequest } from 'types/models/ContestRequest';
import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { Settings } from 'types/models/common';
import { convertContestRequest } from './converters';
import { convertContestRequestToXML } from './requestConverters';
import { ContestRequestType } from 'utils/Enums';

export const contestRequestConfigurations = {
  SaveContestRequest: makeBackendAPIConfiguration({
    id: 'SaveContestRequest',
    endpoint: '/msa/service/commands/SaveContestRequest',
    convertInputToXMLElement: (inputData: ContestRequest) => ({
      Request: {
        _attr: {
          commandName: 'SaveContestRequest',
        },
        ContestRequest: { ...convertContestRequestToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetContestRequest: makeBackendAPIConfiguration({
    id: 'GetContestRequest',
    endpoint: '/msa/service/commands/GetContestRequest',
    convertInputToXMLElement: (inputData: { requestId?: string; projectId?: string; requestType?: ContestRequestType }) => {
      const { requestId, projectId, requestType } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetContestRequest',
          },
          ContestRequest: {
            ...(requestId
              ? { _attr: { id: requestId } }
              : {
                  Project: { _attr: { id: projectId } },
                  Type: requestType,
                }),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertContestRequest(response.Response.ContestRequest, settings),
  }),
  PushContestRequestMessage: makeBackendAPIConfiguration({
    id: 'PushContestRequestMessage',
    endpoint: '/msa/service/commands/PushContestRequestMessage',
    convertInputToXMLElement: ({ message, contestRequestId }: T.SendMessageRequest) => ({
      Request: {
        _attr: { commandName: 'PushContestRequestMessage' },
        ContestRequest: { _attr: { id: contestRequestId, message } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  AddContestRequestApprovement: makeBackendAPIConfiguration({
    id: 'AddContestRequestApprovement',
    endpoint: '/msa/service/commands/AddContestRequestApprovement',
    convertInputToXMLElement: ({ message, contestRequestId, status }: T.ChangeApprovementRequest) => ({
      Request: {
        _attr: { commandName: 'AddContestRequestApprovement' },
        ContestRequest: {
          _attr: { id: contestRequestId },
          Approvement: {
            Resolution: status,
            ...(message ? { ApprovementMessage: message } : {}),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ChangeContestRequestStatus: makeBackendAPIConfiguration({
    id: 'ChangeContestRequestStatus',
    endpoint: '/msa/service/commands/ChangeContestRequestStatus',
    convertInputToXMLElement: ({ message, ids, status }: { ids: string[]; status: string; message: string }) => ({
      Request: {
        _attr: { commandName: 'ChangeContestRequestStatus' },
        ContestRequest: ids.map(id => ({
          _attr: { id },
          Status: status,
          ...(message ? { Message: message } : {}),
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetContestRequestFeedlineList: makeBackendTableAPIConfiguration(
    'GetContestRequestFeedlineList',
    makeTableRequestXMLConverter('GetContestRequestFeedlineList', ({ requestData }: T.GetContestRequestById) =>
      makeParametersXMLElement([{ name: 'contestRequestId', value: requestData?.contestRequestId }]),
    ),
  ),
  GetContestRequestChangeList: makeBackendTableAPIConfiguration(
    'GetContestRequestChangeList',
    makeTableRequestXMLConverter('GetContestRequestChangeList', ({ requestData }: T.GetContestRequestById) =>
      makeParametersXMLElement([{ name: 'contestRequestId', value: requestData?.contestRequestId }]),
    ),
  ),
};

export const tableContestRequestConfigurations = {
  ...contestRequestConfigurations,
};
