import React from 'react';

import { Person } from 'types/models';
import { EnumMap } from 'types/models/Table';

import { showNotification } from 'features/Notifications';

import { ListEdit, ConfirmPopup } from 'components';
import { useMainScientistsContentContext } from 'App/ScientistsPage/context';
import { LinkedHistoryEntities } from 'features/Form/views/LinkedHistoryEntities';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { Fields } from './Fields/Fields';
import useController from './controller';
import { validate } from './validate';
import { Color } from 'constants/colors';
import { JobStatus } from 'utils/Enums';
import { MergePersonCollection } from 'features/MergePersonCollection';
import { DataKind } from 'components/ListEdit/model';

type Props = {
  person: Person.ScientistPerson | null;
  enumMap: EnumMap;
  disabled: boolean;
  isAddMode?: boolean;
  onChangeJobs(jobs: Person.ScientistJob[]): void;
  onUpdate?: () => void;
};

function Component(props: Props) {
  const { isMainScientistsContent } = useMainScientistsContentContext();
  const { person, onChangeJobs, enumMap, disabled, isAddMode, onUpdate } = props;
  const {
    extraToolbarButtons,
    isDeleteConfirmOpen,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedJob,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    jobIds,
  } = useController({
    person,
    onChangeJobs,
  });
  return (
    <div>
      <ListEdit
        isRequired
        withMessages
        isFullScreenedTable
        isDeleteConfirmEnabled
        rows={person?.scientistJobs ?? []}
        visibleToolbarButtons={['add', 'edit']}
        onChange={onChangeJobs}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        extraToolbarButtons={extraToolbarButtons}
        hideToolbar={isAddMode ? false : disabled || !isMainScientistsContent}
        isToolbarDisabled={isLinkedHistoryPending}
        onSelectedRowsCallback={handleSelectedRows}
        columns={[
          {
            label: 'Организация',
            formatValue: row => {
              if (row.status.value === JobStatus.CIVIL_CONTRACT) {
                return `Договор ГПХ ${row.civilContractNumber ? `№${row.civilContractNumber}` : ''} ${
                  row.civilContractDate ? `от ${row.civilContractDate}` : ''
                }`;
              }
              return row.enterpise;
            },
          },
          { label: 'Должность', formatValue: row => row.refAppointment?.label || row.appointment || '' },
          {
            label: 'Штатное состояние',
            formatValue: row =>
              `${row.status.label}${row.status.value === JobStatus.LOCAL ? `: ${row.staffState?.label || ''}` : ''}`,
          },
          { label: 'Подразделение', formatValue: row => (row.refDepartment?.label ?? row.department) || '' },
          { label: 'Табельный номер', formatValue: row => row.employeeNumber ?? '' },
          { label: 'Дата приема', formatValue: row => row.dateReceipt ?? '', dataKind: DataKind.DATE, defaultSort: 'desc' },
          { label: 'Дата увольнения', formatValue: row => row.dateDismissal ?? '' },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) => (creationSource ? `${creationSource} (${creationDate})` : ''),
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationSource ? `${modificationSource} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletionDate && deletedBy ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (job, setJob) => <Fields enumMap={enumMap} job={job || undefined} onChange={setJob} />,
          validation: {
            checkIsValid: job => validate(job).every(x => x.isValid),
            onInvalidate: job => {
              const info = validate(job);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isDeleteConfirmOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={onDeleteConfirm}
        onClose={onDeleteReset}
        icon="warning"
      />

      <ConfirmPopup
        title="Удаление работы"
        isOpen={isDeleteHistoryWarningOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={confirmDeleteHistoryWarning}
        onClose={closeDeleteHistoryWarning}
        icon="warning"
      >
        <span>
          Выбранный на удаление элемент используется в историческом срезе.
          <br />
          Удаление на данный момент невозможно. Вы можете изменить связь в историческом срезе, указав другой элемент. Продолжить?
        </span>
      </ConfirmPopup>
      <LinkedHistoryEntities
        personId={person?.id}
        isOpen={isEntityRelationOpen}
        onClose={closeEntityRelation}
        id={selectedJob?.id ?? '-1'}
        type={LinkedHistoryType.JOB}
      />
      <MergePersonCollection
        entity="ScientistJob"
        entityIds={jobIds}
        isOpen={isMergeDuplicatesOpen}
        onClose={handleCloseMergeDuplicatesForm}
        onUpdate={onUpdate}
      />
    </div>
  );
}

export const JobDetails = React.memo(Component);
