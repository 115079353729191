import { SecurityDocument } from 'types/models';
import { Tab, Tabs } from 'components';
import React from 'react';

import { useController } from './controller';
import { PaymentByRequest } from './PaymentByRequest';
import { PaymentByDocument } from './PaymentByDocument';

type Props = {
  patent?: SecurityDocument | null;
  setPatent?: React.Dispatch<React.SetStateAction<SecurityDocument | null | undefined>>;
  disabled?: boolean;
};

export function Payments({ patent, setPatent, disabled }: Props) {
  const {
    payments,
    requestPayments,
    handleRequestPaymentsChange,
    handlePaymentsChange,
    paymentExtraToolbarButtons,
    paymentTitleByType,
  } = useController({ patent, setPatent, disabled });

  return (
    <Tabs>
      <Tab title="Пошлины по заявке">
        <PaymentByRequest
          requestPayments={requestPayments}
          handleRequestPaymentsChange={handleRequestPaymentsChange}
          disabled={disabled}
        />
      </Tab>
      <Tab title={paymentTitleByType}>
        <PaymentByDocument
          disabled={disabled}
          payments={payments}
          handlePaymentsChange={handlePaymentsChange}
          paymentExtraToolbarButtons={paymentExtraToolbarButtons}
        />
      </Tab>
    </Tabs>
  );
}
