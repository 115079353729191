import React, { memo } from 'react';
import { FormComponent, CustomSelect } from 'components';
import { Form, Table } from 'types/models';
import useController from './controller';

type Props = {
  printArticle: Form.PrintArticle | null;
  handleChangePrintArticle: (nextPublication: Form.PrintArticle | null) => void;
  isDisabled: boolean;
  relatedTableState?: Table.State;
  releaseId?: string;
  releaseYear?: string;
};

export const EditPreprintRelation = memo(
  ({ printArticle, handleChangePrintArticle, isDisabled, relatedTableState, releaseId, releaseYear }: Props) => {
    const { modalTableRowToPrintPublicationConventer, printPublicationTableConfig } = useController({
      relatedTableState,
      releaseId,
      releaseYear,
    });

    return (
      <FormComponent.Line>
        <FormComponent.Field>
          <CustomSelect
            disabled={isDisabled}
            isRequired
            isStatusText
            title="Публикация"
            original={printArticle}
            relationTableModalTitle="Выбор печатной публикации"
            onChange={handleChangePrintArticle}
            modalTableRowConverter={modalTableRowToPrintPublicationConventer}
            modalTableSpecification={printPublicationTableConfig}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    );
  },
);
