import { showNotification } from 'features/Notifications';
import { MobileRequest } from 'types/models';
import { MobileRequestFinancingType, MobileRequestStatus } from 'utils/Enums';
import { getEstimatePositionsAmount, getFinancingsAmount } from './helpers';

export function validateFinancing(financing: MobileRequest.Financing): boolean {
  const result =
    !!financing.type &&
    !!financing.amount &&
    (financing.type?.value === MobileRequestFinancingType.SF1
      ? !!financing.sf1Source?.projectCodeId || !!financing.sf1Source?.fundCardId
      : financing.type?.value === MobileRequestFinancingType.SF2
      ? !!financing.department?.id
      : financing.type?.value === MobileRequestFinancingType.SF3
      ? !!financing.sf3Source?.value && !!financing.note
      : true);
  return result;
}

export function validateMobileRequest({
  mobileRequest,
  nextStatus,
}: {
  mobileRequest: MobileRequest.MobileRequest;
  nextStatus?: MobileRequestStatus;
}): boolean {
  const {
    applicant,
    applicantSupervisors,
    businessTripStartDate,
    businessTripEndDate,
    traineeStartDate,
    traineeEndDate,
    routes,
    purpose,
    activityKind,
    visa,
    theme,
    pnrs,
    tripPlanes,
    useMaterialObligations,
    estimatePositions,
    financings,
    status,
  } = mobileRequest;
  const errorMessages: string[] = [];

  if (nextStatus ? nextStatus === MobileRequestStatus.DRAFT : status?.value === MobileRequestStatus.DRAFT) {
    if (!applicant?.id || !purpose?.value) {
      showNotification({ message: 'Укажите ФИО заявителя и цель командировки', theme: 'danger' });
      return false;
    }
    return true;
  }

  if (!applicant?.id) errorMessages.push('ФИО заявителя');
  if (!applicantSupervisors.length) errorMessages.push('руководителя');
  if (!businessTripStartDate || !businessTripEndDate) errorMessages.push('период командировки');
  if (!traineeStartDate || !traineeEndDate) errorMessages.push('период участия в мероприятии');
  if (!routes.length) errorMessages.push('место проведения мероприятия');
  if (!purpose?.value) errorMessages.push('цель командировки');
  if (!activityKind?.id) errorMessages.push('вид деятельности');
  if (!visa?.id) errorMessages.push('направление деятельности');
  if (!theme) errorMessages.push('название мероприятия');
  if (!pnrs.length) errorMessages.push('ПНР и САЕ');
  if (!tripPlanes.length) errorMessages.push('план поездки');
  if (!useMaterialObligations.length) errorMessages.push('описание результатов');
  if (!estimatePositions.length) errorMessages.push('смету расходов');
  const emptyFieldsMessage: string | null = errorMessages.length ? `Укажите ${errorMessages.join(', ')}` : null;

  const estimatePositionsAmount: number = getEstimatePositionsAmount(estimatePositions);
  const financingsAmount: number = getFinancingsAmount(financings);
  const estimateMessage: string | null =
    estimatePositionsAmount > financingsAmount
      ? 'Распределена не вся сумма по источникам финансирования'
      : estimatePositionsAmount < financingsAmount
      ? 'Сумма по источникам финансирования превышает общую сумму по смете расходов'
      : null;

  const resultErrorMessage: string = [emptyFieldsMessage, estimateMessage].filter(x => x).join('. ');

  if (resultErrorMessage) {
    showNotification({ message: resultErrorMessage, theme: 'danger' });
    return false;
  }
  return true;
}
