import React, { memo } from 'react';
import { Modal, ReferenceItem } from 'components';
import { Component as BuildReportPopupComponent } from './component';
import { ProjectCode } from 'types/models/Project';

type Props = {
  reportCaption: string;
  reportName: string;
  isOpen: boolean;
  onClose: () => void;
  scientist?: string;
  scientistId?: string;
  nirRequestId?: string;
  requestId?: string;
  projectId?: string;
  stageId?: string;
  securityDocumentId?: string;
  defaultRequestStatus?: string;
  requestType?: string;
  requestIds?: string;
  authorRequestStatus?: string;
  paymentRequestPeriod?: string;
  requestStatus?: string;
  userDepartment?: string;
  department?: string;
  regulationParagraph?: string;
  event?: ReferenceItem;
  isEventParameterHidden?: boolean;
  requestTypeLabel?: string;
  mobileRequestId?: string;
  projectActId?: string;
  contestRequestId?: string;
  exhibitionEventId?: string;
  contestEventId?: string;
  conferenceEventId?: string;
  mobileProtocolId?: string;
  tenderProtocolId?: string;
  systemDepartMentLabel?: string;
  projectCode?: ProjectCode | null;
  purchaseRequestId?: string;
  projectPerformerOrderId?: string;
};

const BuildReportPopupContainer = ({
  reportCaption,
  reportName,
  isOpen,
  onClose,
  scientist,
  scientistId,
  nirRequestId,
  requestId,
  projectId,
  stageId,
  securityDocumentId,
  requestType,
  requestIds,
  defaultRequestStatus,
  authorRequestStatus,
  requestStatus,
  userDepartment,
  paymentRequestPeriod,
  department,
  regulationParagraph,
  event,
  isEventParameterHidden,
  requestTypeLabel,
  mobileRequestId,
  projectActId,
  contestRequestId,
  exhibitionEventId,
  contestEventId,
  conferenceEventId,
  mobileProtocolId,
  tenderProtocolId,
  systemDepartMentLabel,
  projectCode,
  purchaseRequestId,
  projectPerformerOrderId,
}: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title={`Параметры отчета "${reportCaption}"${systemDepartMentLabel ? `. Подразделение: ${systemDepartMentLabel}` : ''}`}
    size="large"
  >
    <BuildReportPopupComponent
      reportName={reportName}
      onClose={onClose}
      reportCaption={reportCaption}
      scientist={scientist}
      scientistId={scientistId}
      nirRequestId={nirRequestId}
      requestId={requestId}
      projectId={projectId}
      stageId={stageId}
      securityDocumentId={securityDocumentId}
      defaultRequestStatus={defaultRequestStatus}
      requestType={requestType}
      requestIds={requestIds}
      authorRequestStatus={authorRequestStatus}
      paymentRequestPeriod={paymentRequestPeriod}
      userDepartment={userDepartment}
      requestStatus={requestStatus}
      event={event}
      department={department}
      regulationParagraph={regulationParagraph}
      isEventParameterHidden={isEventParameterHidden}
      requestTypeLabel={requestTypeLabel}
      mobileRequestId={mobileRequestId}
      projectActId={projectActId}
      contestRequestId={contestRequestId}
      exhibitionEventId={exhibitionEventId}
      contestEventId={contestEventId}
      conferenceEventId={conferenceEventId}
      mobileProtocolId={mobileProtocolId}
      projectCode={projectCode}
      tenderProtocolId={tenderProtocolId}
      purchaseRequestId={purchaseRequestId}
      projectPerformerOrderId={projectPerformerOrderId}
    />
  </Modal>
);

export const Container = memo(BuildReportPopupContainer);
