import { Rid } from 'types/models';
import { FormComponent, ReferenceItem } from 'components';
import React from 'react';

import { ValueOf } from 'types/helpers';
import { ItemListEdit } from 'features/Form/looks/programRequest/ProgramRequestForm/ItemListEdit/ItemListEdit';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  rid?: Rid | null;
  makeChangeHandler: (key: keyof Rid) => (val: ValueOf<Rid>) => void;
  disabled: boolean;
};

export function Classifiers({ rid, makeChangeHandler, disabled }: Props) {
  const { settings } = useAppDataContext();

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <ItemListEdit
              title="ГРНТИ"
              refName="RefGrnti"
              rows={rid?.grntis ?? []}
              onChange={makeChangeHandler('grntis')}
              modalTitle='Справочник "ГРНТИ"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="УДК"
              refName="RefUdk"
              rows={rid?.udks ?? []}
              onChange={makeChangeHandler('udks')}
              modalTitle='Справочник "УДК"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="ОКВЭД"
              refName="RefOkved"
              rows={rid?.okveds ?? []}
              onChange={makeChangeHandler('okveds')}
              modalTitle='Справочник "ОКВЭД"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="ЛКСЭЦ"
              refName="RefLksetss"
              rows={rid?.lksetss ?? []}
              onChange={makeChangeHandler('lksetss')}
              modalTitle='Справочник "ЛКСЭЦ"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Отрасли науки"
              refName="RefScienceBrunch"
              rows={rid?.scienceBrunches ?? []}
              onChange={makeChangeHandler('scienceBrunches')}
              modalTitle='Справочник "Отрасли науки"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Области научных интересов"
              refName="RefScienceDomainInterest"
              rows={rid?.scienceDomainInterrests ?? []}
              onChange={makeChangeHandler('scienceDomainInterrests')}
              modalTitle='Справочник "Области научных интересов"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <ItemListEdit
              title={`ПНР ${settings?.organization?.shortName}`}
              refName="RefPnr"
              rows={rid?.pnrs ?? []}
              onChange={makeChangeHandler('pnrs')}
              modalTitle='Справочник "ПНР"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Приоритетные направления модернизации и технического развития экономики"
              refName="RefPnr"
              rows={rid?.pnmitrs ?? []}
              onChange={makeChangeHandler('pnmitrs')}
              modalTitle='Справочник "ПНР модернизации РФ"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Приоритетные направления науки, технологий и техники"
              refName="RefPni"
              rows={rid?.pnis ?? []}
              onChange={makeChangeHandler('pnis')}
              modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Стратегии НТР"
              refName="RefNtrStrategy"
              rows={rid?.ntrStrategies ?? []}
              onChange={makeChangeHandler('ntrStrategies')}
              modalTitle='Справочник "Стратегии НТР"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Технологические платформы"
              refName="RefTechnologyPlatform"
              rows={rid?.technologyPlatforms ?? []}
              onChange={makeChangeHandler('technologyPlatforms')}
              modalTitle='Справочник "Технологические платформы"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Критические технологии"
              refName="RefPriorityTechnology"
              rows={rid?.priorityTechnologies ?? []}
              onChange={makeChangeHandler('priorityTechnologies')}
              modalTitle='Справочник "Критические технологии"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ItemListEdit
              title="Области знаний"
              refName="RefDomainKnowledge"
              rows={rid?.domainKnowledges ?? []}
              onChange={makeChangeHandler('domainKnowledges')}
              modalTitle='Справочник "Области знаний"'
              disabled={disabled}
              isToolbarHidden
              columns={[{ label: '', formatValue: (x: ReferenceItem) => x.label ?? '' }]}
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
