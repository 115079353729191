import { convertServerPerson } from 'services/BackendAPI/configurations/author/converters';
import { Settings } from 'types/models/common';
import { convertMaybeArray, getId, getText, convertItem } from '../commonConverters';
import { convertPersonHistory } from '../converters';
import { CommissionMember, ProtocolCommission } from 'types/models/ProtocolCommission';

export function convertProtocolCommission(mobileCommission: any, settings: Settings): ProtocolCommission {
  const members = mobileCommission.Members?.Member || [];
  return {
    id: getId(mobileCommission),
    name: getText(mobileCommission.Name),
    orderNumber: getText(mobileCommission.OrderNumber),
    orderDate: getText(mobileCommission.OrderDate),
    ...(mobileCommission.Note ? { note: getText(mobileCommission.Note) } : { note: null }),
    members: convertMaybeArray(members, m => convertCommissionMember(m, settings)),
  };
}
export function convertCommissionMember(member: any, settings: Settings): CommissionMember {
  return {
    id: getId(member),
    priority: getText(member.Priority),
    protocolName: getText(member.ProtocolName),
    person: convertServerPerson(member.Person, settings),
    role: convertItem(member.Role),
    ...convertPersonHistory(member),
  };
}
