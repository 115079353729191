import * as R from 'ramda';

import { StreamRx } from 'StreamRx';

import { streams } from './streams';

type SuchLikePublicationStreams<T> = {
  [K in keyof T]: T[K];
};

export function useLocalStreams<T>(localStreams: T): SuchLikePublicationStreams<T> {
  return R.map(
    (stream): StreamRx<any> => ({
      push: (data: any) => stream.push(data),
      observable: stream.observable,
    }),
    localStreams as any,
  ) as any;
}

export function useLocalSuchLikePublicationStreams() {
  return useLocalStreams(streams);
}
