import { useCallback, useState, useLayoutEffect } from 'react';
import { Form } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { useAppDataContext } from 'features/AppData/context';
import { useStream } from 'StreamRx';
import { showNotification } from 'features/Notifications';
import { projectsStreams, mobileRequestsStream } from './streams';
import { PublicationProject } from 'types/models/Form';

type Props = {
  publicationId: string;
  onClose: () => void;
  onSuccess: () => void;
};

const useController = ({ publicationId, onClose, onSuccess }: Props) => {
  const { currentPerson } = useAppDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [publication, setPublication] = useState<Form.Publication | null>(null);
  const [projects, setProjects] = useState<PublicationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<Form.PublicationMobileRequest[]>([]);

  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublication } = BackendAPI.useBackendAPI('SavePublication');

  useLayoutEffect(() => {
    setIsLoading(true);
    getPublication.callAPI(
      {
        id: publicationId,
        simpleFields: {
          status: 'ADDED',
        },
        attrIdFields: {},
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
        projects: [],
      },
      {
        onSuccessfullCall: (result: any) => {
          const data = result.data as Form.Publication;
          setIsLoading(false);
          setPublication(data);
          setMobileRequests(data.mobileRequests);
          setProjects(data.projects);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
  }, []); // eslint-disable-line

  const submitForm = useCallback(() => {
    if (publication === null) {
      return;
    }
    setIsLoading(true);
    savePublication.callAPI(
      {
        id: publicationId,
        simpleFields: {
          type: publication.type,
          status: publication.status.status,
          isElectronic: publication.fields.isElectronic === 'true',
          electronicType: publication.fields.electronicType,
          doi: publication.fields.doi,
          name: publication.fields.name,
          year: publication.fields.year,
          month: publication.fields.month,
          day: publication.fields.day,
          tirage: publication.fields.tirage,
          seriesTitle: publication.fields.seriesTitle,
          issn: publication.fields.ISSN,
          isbn: publication.fields.ISBN,
          volume: publication.fields.volume,
          issue: publication.fields.issue,
          printPageCount: publication.fields.printPageCount,
          url: publication.fields.url,
          libraryLink: publication.fields.libraryLink,
          typeEdition: publication.fields.typeEditionCode,
          pageCount: publication.fields.pageCount,
          address: publication.fields.address,
          bibliographicRecord: publication.fields.bibliographicRecord,
          electronicMediaSource: publication.fields.electronicMediaSource,
          electronicSystemRequirements: publication.fields.electronicSystemRequirements,
          udk: publication.fields.udk,
          urlDate: publication.fields.urlDate,
          annotation: publication.fields.annotation,
          authorsKeyWords: publication.fields.authorsKeyWords,
          authorsCount: publication.fields.authorsCount,
          fractionalCount: publication.fields.fractionalCount,
          shortName: publication.fields.shortName,
          eIssn: publication.fields.EISSN,
          startPage: publication.fields.startPage,
          endPage: publication.fields.endPage,
          articleNumber: publication.fields.articleNumber,
          defencePlace: publication.fields.defencePlace,
          eIsbn: publication.fields.EISBN,
          volumeNumber: publication.fields.volumeNumber,
          republishData: publication.fields.republishData,
          defenceDate: publication.fields.defenceDate,
          councilCipher: publication.fields.councilCipher,
          foreignName: publication.fields.foreignName,
          foreignAnnotation: publication.fields.foreignAnnotation,
          foreignAuthorsKeyWords: publication.fields.foreignAuthorsKeyWords,
          foreignAuthors: publication.fields.foreignAuthors,
          isLocal: publication.isLocal,
        },
        attrIdFields: {
          sourceType: publication.fields.sourceTypeId,
          publisher: publication.fields.publisherId,
          categoryEdition: publication.fields.categoryEditionId,
          textLanguage: publication.fields.textLanguageId,
          grnti: publication.fields.grntiId,
          study: publication.fields.studyId,
          original: publication.original?.id,
          compilation: publication.compilation?.id,
          magazine: publication.magazine?.id,
          grif: publication.fields.grifId,
          vakSpeciality: publication.fields.vakSpecialityId,
          degree: publication.fields.degreeId,
          council: publication.council?.id,
          originalLanguage: publication.fields.originalLanguageId,
          monograph: publication.monograph?.id,
          magazineRelease: publication.magazineRelease?.id,
          printArticle: publication.printArticle?.id,
          publicationOther: publication.publicationOther?.id,
        },
        affiliation: publication.affiliation,
        arrayFields: {
          translations: { translation: publication.translations },
          events: { event: publication.events as any },
        },
        mobileRequests: { mobileRequest: mobileRequests },
        projects,
        file: publication.file,
        authors: publication.members,
      },
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
          showNotification({ message: 'Публикация успешно отредактирована', theme: 'success' });
          onSuccess();
          onClose();
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
  }, [mobileRequests, projects, publication, publicationId, savePublication, onClose, onSuccess]);

  useStream(
    () => projectsStreams.setProjects,
    (projectsData: Array<PublicationProject>) => setProjects(projectsData),
    [projects, setProjects, projectsStreams.setProjects],
  );

  useStream(
    () => projectsStreams.accept,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy,
        accepted,
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.accept, currentPerson],
  );

  useStream(
    () => projectsStreams.cancelAcceptance,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy: null,
        accepted: '',
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.cancelAcceptance],
  );

  useStream(
    () => mobileRequestsStream.setMobileRequests,
    (requests: Array<Form.PublicationMobileRequest>) => setMobileRequests(requests),
    [mobileRequests, setMobileRequests, mobileRequestsStream.setMobileRequests],
  );

  useStream(
    () => mobileRequestsStream.accept,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy,
        accepted,
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.accept, currentPerson],
  );

  useStream(
    () => mobileRequestsStream.cancelAcceptance,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy: null,
        accepted: '',
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.cancelAcceptance],
  );
  return {
    mobileRequests,
    projects,
    publication,
    isLoading,
    submitForm,
  };
};

export default useController;
