import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { subjectType, isSubject } = customState;
  const {
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    subjectName,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  } = useController(tableState);
  return (
    <>
      {!isEditButtonDisabled && (
        <modalTemplates.SubjectEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          subjectType={subjectType}
          isSubject={isSubject}
        />
      )}
      <modalTemplates.SubjectAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        subjectType={subjectType}
        isSubject={isSubject}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранн${isSubject ? 'ую тематику' : 'ый продукт'} "${subjectName}"?`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ConfirmPopup
        title={`Помощь в разделе ${isSubject ? 'Тематики РИД' : 'Продукты для коммерциализации'}`}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {isSubject ? (
          <div className="modal-help">
            <p>
              <strong className="primary">
                Раздел предназначен для решения задач по коммерциализации и трансфера технологий.
              </strong>
            </p>
            <p>
              <strong>Тематика</strong> – это инструмент для формирования временных коллекций РИД. Критерии для объединения в
              тематики могут быть любые: по характеристикам, отраслям, или по сотрудникам, которые занимаются продвижением на
              рынок. В зависимости от целей РИД может быть в составе нескольких тематик. Сборка консолидированной информации для
              описании Тематики осуществляется автоматически на основе метаданных РИД.
            </p>
            <p>
              <strong className="primary">Особенности заполнения информации о Тематике</strong>
            </p>
            <ul>
              <li>
                Поле <strong>Код</strong> заполняется в соответствии с принятыми внутренними правилами учётной политики.
              </li>
              <li>
                На вкладке <strong>Классификаторы</strong> отображаются консолидированные списки элементов классификаторов из
                данных всех РИДов, указанных на вкладке <strong>Описание</strong>
              </li>
              <li>
                Если на форме РИД указан проект в поле <strong>Получен в рамках выполнения проекта</strong>, то информация о
                вещественных результатах проекта (публикации, участие в конференции, участие в выставке) будет отображаться в
                разделе <strong>Популяризация</strong>
              </li>
              <li>
                Вкладка <strong>Публикации</strong>: источник данных — публикации, у которых на вкладке&nbsp;
                <strong>Финансовое сопровождение</strong>&nbsp;указан проект (функциональный блок <strong>Публикации</strong>)
              </li>
              <li>
                Вкладка <strong>Участие в конференциях</strong>: источник данных — записи об участии в конференции с докладом, у
                которых на вкладке <strong>Финансовое сопровождение</strong> указан проект (функциональный блок&nbsp;
                <strong>Мероприятия, Участия в конференции</strong>)
              </li>
              <li>
                Вкладка <strong>Участие в выставках</strong>: источник данных — записи об участии в научно-технических выставках,
                форма участия — Презентация разработки (функциональный блок: <strong>Мероприятия, Участия в выставке</strong>).
              </li>
              <li>
                Общий список разработок, представленных на выставках — в функциональном блоке&nbsp;
                <strong>Коммерциализация, Список разработок (выставочные экспонаты).</strong>
              </li>
              <li>
                Вкладка <strong>Коммерциализация</strong> содержит информацию о договорах о распоряжении интеллектуальным правом.
                Источник информации — функциональный блок&nbsp;
                <strong>Коммерциализация, Договоры о распоряжении интеллектуальным правом.</strong>
              </li>
              <li>
                Вкладка <strong>Финансирование</strong> – содержит информацию о проектах, в рамках которых были получены РИД из
                состава Тематики. Источник информации — <strong>РИД</strong>.
              </li>
              <li>
                Вкладка <strong>Документы</strong> содержит список документов, прикреплённых на форме РИДов из состава Тематики.
                Можно прикрепить документы, связанные с Тематикой, например, рекламные, маркетинговые.
              </li>
            </ul>
          </div>
        ) : (
          <div className="modal-help">
            <p>
              <strong className="primary">
                Раздел предназначен для решения задач по коммерциализации и трансфера технологий.
              </strong>
            </p>
            <p>
              <strong>Продукт для коммерциализации</strong> – это коллекция из взаимодополняющих РИД, которая продвигается как
              коммерческий продукт. В продукт могут быть добавлены ОИС, не аффилированные с университетом. Сборка
              консолидированной информации для описании Продукта осуществляется автоматически на основе метаданных РИД.
            </p>
            <br />
            <p>
              <strong className="primary">Особенности заполнения информации о Продукте</strong>
            </p>
            <ul>
              <li>
                Поле <strong>Код</strong> заполняется в соответствии с принятыми внутренними правилами учётной политики.
              </li>
              <li>
                На вкладке <strong>Классификаторы</strong> отображаются консолидированные списки элементов классификаторов из
                данных всех РИДов, указанных на вкладке <strong>Описание</strong>
              </li>
              <li>
                Если на форме РИД указан проект в поле <strong>Получен в рамках выполнения проекта</strong>, то информация о
                вещественных результатах проекта (публикации, участие в конференции, участие в выставке) будет отображаться в
                разделе&nbsp;
                <strong>Популяризация</strong>
              </li>
              <li>
                Вкладка <strong>Публикации</strong>: источник данных — публикации, у которых на вкладке&nbsp;
                <strong>Финансовое сопровождение</strong>&nbsp;указан проект (функциональный блок <strong>Публикации</strong>)
              </li>
              <li>
                Вкладка <strong>Участие в конференциях</strong>: источник данных — записи об участии в конференции с докладом, у
                которых на вкладке <strong>Финансовое сопровождение</strong> указан проект (функциональный блок&nbsp;
                <strong>Мероприятия, Участия в конференции</strong>)
              </li>
              <li>
                Вкладка <strong>Участие в выставках</strong>: источник данных — записи об участии в научно-технических выставках,
                форма участия — Презентация разработки (функциональный блок: <strong>Мероприятия, Участия в выставке</strong>).
              </li>
              <li>
                Общий список разработок, представленных на выставках — в функциональном блоке&nbsp;
                <strong>Коммерциализация, Список разработок (выставочные экспонаты)</strong>.
              </li>
              <li>
                Вкладка <strong>Коммерциализация</strong> содержит информацию о договорах о распоряжении интеллектуальным правом.
                Источник информации — функциональный блок&nbsp;
                <strong>Коммерциализация, Договоры о распоряжении интеллектуальным правом</strong>.
              </li>
              <li>
                Вкладка <strong>Финансирование</strong> – содержит информацию о проектах, в рамках которых были получены РИД из
                состава Продукта. Источник информации — <strong>РИД</strong>.
              </li>
              <li>
                Вкладка <strong>Документы</strong> содержит список документов, прикреплённых на форме РИДов из списка Продукта.
                Можно прикрепить документы, связанные с продуктом, например, рекламные, маркетинговые.
              </li>
            </ul>
          </div>
        )}
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
