import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isDissertationManagement',
      label: 'Диссертации',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isSynopsisManagement',
      label: 'Авторефераты',
      checked: false,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: false,
    },
  ]);
});
