import { useCallback, useMemo } from 'react';
import { CollectionItem, CollectionValue } from 'types/models/Merge';
import * as R from 'ramda';
import { RadioItem } from 'components';

type Props = {
  value: CollectionValue;
  setValue: (collectionValue: CollectionValue) => void;
  collection: CollectionItem[];
};
const useController = ({ value, setValue, collection }: Props) => {
  const radioButtons = useMemo<RadioItem[]>(
    () => collection.map(collectionItem => ({ label: collectionItem.caption, value: collectionItem.id })),
    [collection],
  );

  const onRadioChange = useCallback(
    (analogId: string) => {
      setValue({ ...value, analogId });
    },
    [setValue, value],
  );

  const onValueChange = useCallback(
    (collectionItemId: string) => (nextValue: boolean) => {
      const valueCopy = R.clone(value);
      if (nextValue) {
        valueCopy.selectedIds.push(collectionItemId);
      } else {
        valueCopy.selectedIds = value.selectedIds.filter(item => item !== collectionItemId);
      }

      setValue(valueCopy);
    },
    [setValue, value],
  );

  const getIsCollectionItemChecked = useCallback(
    (collectionItemId: string) => value.selectedIds.some(id => id === collectionItemId),
    [value.selectedIds],
  );

  const isAllSelected = useMemo(
    () => Boolean(collection.length) && collection.every(({ id }) => value.selectedIds.some(selectedId => selectedId === id)),
    [collection, value.selectedIds],
  );

  const onAllSelectedClick = useCallback(
    (nextIsAllSelected: boolean) => {
      if (nextIsAllSelected) {
        setValue({ ...value, selectedIds: collection.map(({ id }) => id) });
      } else {
        setValue({ ...value, selectedIds: [] });
      }
    },
    [collection, setValue, value],
  );

  return {
    radioButtons,
    isAllSelected,
    onAllSelectedClick,
    onRadioChange,
    onValueChange,
    getIsCollectionItemChecked,
  };
};

export default useController;
