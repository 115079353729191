import { useEffect, useState } from 'react';
import * as R from 'ramda';

import * as BackendAPI from 'services/BackendAPI';

import { usePrevious } from 'shared/react/usePrevious';

import { ReferenceItem, ReferenceFilter } from '.';

type Arguments = {
  referenceName: string;
  filters: ReferenceFilter[];
  value: ReferenceItem | null;
  fieldVisibleMode?: string;
};

export function useController({ referenceName, filters, fieldVisibleMode, value }: Arguments) {
  const [referencesList, setReferencesList] = useState<ReferenceItem[]>([]);
  const [isStartedLoadReferences, setIsStartedLoadReferences] = useState(false);
  const [isOpenTableModal, setIsOpenTableModal] = useState(false);

  const { methods: getReferencesSelectList, state } = BackendAPI.useBackendAPI('GetReferenceElements', {
    onSuccessfullCall: ({ data }) => {
      setReferencesList(data);
    },
  });

  useEffect(() => {
    if (value && value.id && value.label && !referencesList.find(x => x.id === value.id)) {
      setReferencesList([value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (!R.equals(prevFilters, filters)) {
      setIsStartedLoadReferences(false);
    }
  }, [filters, prevFilters]);

  useEffect(() => {
    if (isStartedLoadReferences) {
      getReferencesSelectList.callAPI({ referenceName, fieldVisibleMode, filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartedLoadReferences, referenceName]);

  return {
    referencesList,
    isStartedLoadReferences,
    isOpenTableModal,
    isLoadingReferences: state.kind === 'pending',
    setIsStartedLoadReferences,
    setIsOpenTableModal,
  };
}
