import { useCallback, useEffect, useRef, useState } from 'react';

import { Report, Table } from 'types/models';
import { EmployeePayment, EmployeePaymentStatus } from 'types/models/Payment';

import { showNotification } from 'features/Notifications';
import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams } from 'App/pages/FundsDepartments/streams';

import { useStream } from 'StreamRx';

import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { calcRemainderAmount, checkIsBudgetOver, getInitialEmployeePayment } from './helpers';

export type State = {
  selectedRequest: Table.Entry | null;
  isOpenRequestForm: boolean;
  employeePayment: EmployeePayment | null;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  closeFormRequest(): void;
  openFormRequest(mode: Mode): void;
  saveEmployeePayment(): void;
  comment: string;
  setComment(comment: string): void;
  toggleVisibleCommentForm(): void;
  isOpenCommentForm: boolean;
  startSaveEmployeePayment(): void;
  isOpenConfirmDelete: boolean;
  setIsOpenConfirmDelete(isOpen: boolean): void;
  deleteEmployeePayment(): void;
  changeEmployeePaymentStatus(status: EmployeePaymentStatus): void;
  onPrintClick(): void;
  onReportClose: () => void;
  isReportOpen: boolean;
  currentReport: Report | null;
  mode: Mode | null;
  selectedFund: Table.Entry | null;
};

export type Mode = 'add' | 'edit' | 'view';

type Arguments = {
  selectedFund: Table.Entry | null;
  year: string;
};

export function makeUseCustomController({ selectedFund, year }: Arguments) {
  return function useCustomController({ selectedRows, entryPages }: Table.UseCustomControllerProps): State {
    const [selectedRequest, setSelectedRequest] = useState<Table.Entry | null>(null);
    const [isOpenRequestForm, setIsOpenRequestForm] = useState(false);
    const [employeePayment, setEmployeePayment] = useState<EmployeePayment | null>(null);
    const [comment, setComment] = useState<string>('');
    const [isOpenCommentForm, setIsOpenCommentForm] = useState(false);
    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
    const [mode, setMode] = useState<Mode | null>(null);
    const id = useRef<string | null>(null);

    const tableStreams = useLocalTableStreams();

    const { methods: getEmployeePaymentAPI } = BackendAPI.useBackendAPI('GetEmployeePaymentRequest');
    const { methods: saveEmployeePaymentAPI } = BackendAPI.useBackendAPI('SaveEmployeePaymentRequest');
    const { methods: deleteEmployeePaymentAPI } = BackendAPI.useBackendAPI('DeleteEmployeePaymentRequest');
    const { methods: changeEmployeePaymentStatusAPI } = BackendAPI.useBackendAPI('ChangeEmployeePaymentRequestStatus');

    const reloadTable = useCallback(() => {
      tableStreams.updateFirstLevelPanelState.push({
        state: {
          year,
          fundType: selectedFund?.['id:type'] ?? null,
          fundFaculty: selectedFund?.['id:faculty'] ?? null,
          quarter: selectedFund?.['id:quarter'] ?? null,
          month: selectedFund?.month ?? null,
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFund]);

    const [row] = selectedRows;
    useEffect(() => {
      setSelectedRequest(row ?? null);
    }, [row]);

    useEffect(() => {
      reloadTable();
    }, [reloadTable]);

    useStream(
      () => tableStreams.getPanelState,
      data => data.onStateReceive({ year }),
      [year],
    );

    const reloadTables = useCallback(() => {
      reloadTable();
      streams.reloadFundsTable.push();
    }, [reloadTable]);

    const closeFormRequest = useCallback(() => {
      setIsOpenRequestForm(false);
      setEmployeePayment(null);
      setComment('');
      setMode(null);
    }, []);

    const toggleVisibleCommentForm = useCallback(() => {
      setIsOpenCommentForm(prev => !prev);
    }, []);

    const openFormRequest = useCallback(
      (x: Mode) => {
        if (x === 'add') {
          if (checkIsBudgetOver(selectedFund, entryPages)) {
            showNotification({ message: 'В фонде не осталось средств', theme: 'danger' });
            return;
          }
          setIsOpenRequestForm(true);
          if (selectedFund) {
            setEmployeePayment(getInitialEmployeePayment(selectedFund));
          }
        } else if (selectedRequest) {
          getEmployeePaymentAPI.callAPI(
            { id: selectedRequest.id },
            {
              onSuccessfullCall: ({ data }) => {
                setEmployeePayment(data);
                setIsOpenRequestForm(true);
              },
            },
          );
        }
        setMode(x);
      },
      [selectedRequest, entryPages, getEmployeePaymentAPI],
    );

    const saveEmployeePayment = useCallback(
      (onSuccessCb?: () => void) => {
        if (employeePayment) {
          saveEmployeePaymentAPI.callAPI(
            { comment, employeePayment },
            {
              onSuccessfullCall: ({ data }) => {
                if (onSuccessCb) {
                  id.current = data.id;
                  onSuccessCb();
                } else {
                  showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
                  closeFormRequest();
                  reloadTables();
                }
              },
            },
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [employeePayment, comment, closeFormRequest, reloadTables],
    );

    const startSaveEmployeePayment = useCallback(
      (onSuccessCb?: () => void) => {
        const errorMsg = getErrorMsg(employeePayment);
        if (errorMsg) {
          showNotification({ message: errorMsg, theme: 'danger' });
        } else if (onSuccessCb) {
          saveEmployeePayment(onSuccessCb);
        } else {
          toggleVisibleCommentForm();
        }
      },
      [employeePayment, toggleVisibleCommentForm, saveEmployeePayment],
    );

    const deleteEmployeePayment = useCallback(() => {
      deleteEmployeePaymentAPI.callAPI(
        { id: row.id },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Заявка успешно удалена', theme: 'success' });
            reloadTables();
          },
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row, reloadTables]);

    const changeEmployeePaymentStatus = useCallback(
      (status: EmployeePaymentStatus) => {
        changeEmployeePaymentStatusAPI.callAPI(
          { id: row?.id ?? id.current ?? '', status, comment },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Статус заявки успешно изменен', theme: 'success' });
              reloadTables();
              closeFormRequest();
              id.current = null;
            },
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [row, comment, selectedFund, reloadTables, closeFormRequest, id.current],
    );

    const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

    const onPrintClick = useCallback(() => {
      handleSetCurrentReport({ name: Reports.AppToMemorandum.caption, value: Reports.AppToMemorandum.name });
    }, [handleSetCurrentReport]);

    useEffect(() => {
      tableStreams.loadInitialTable.push();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
      comment,
      selectedRequest,
      isOpenRequestForm,
      employeePayment,
      isOpenCommentForm,
      isOpenConfirmDelete,
      mode,
      selectedFund,
      isReportOpen,
      currentReport,
      onReportClose,
      setComment,
      openFormRequest,
      closeFormRequest,
      setEmployeePayment,
      saveEmployeePayment,
      deleteEmployeePayment,
      setIsOpenConfirmDelete,
      startSaveEmployeePayment,
      toggleVisibleCommentForm,
      changeEmployeePaymentStatus,
      onPrintClick,
    };
  };
}

export function getErrorMsg(employeePayment: EmployeePayment | null) {
  const isSumLowerFund = (employeePayment?.amountMoney ?? 0) <= (employeePayment?.fund?.commonSumm ?? 0);
  const isAllDistributed = calcRemainderAmount(employeePayment) === 0;

  if (!isSumLowerFund) {
    return 'Сумма больше фонда';
  }

  if (!isAllDistributed) {
    showNotification({ message: 'Сумма по сотрудникам не равна сумме оплаты по факультету', theme: 'danger' });
  }

  return null;
}
