import { ReferenceItem } from 'components';
import { useCallback, useMemo, useState, useRef } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Partner, Table } from 'types/models';
import { getIsPending } from 'utils/Helpers/getIsPending';

type GetRefProps = {
  refName: string;
  code: string;
};

const useController = () => {
  const { methods: getPartnerAPI, state: getPartnerState } = BackendAPI.useBackendAPI('GetPartner');
  const { methods: savePartnerAPI, state: savePartnerState } = BackendAPI.useBackendAPI('SavePartner');
  const { methods: getReferenceElementAPI, state: getReferenceElementState } = BackendAPI.useBackendAPI('GetReferenceElement');

  const isLoading = useMemo(
    () => getIsPending(getPartnerState) || getIsPending(savePartnerState) || getIsPending(getReferenceElementState),
    [getPartnerState, getReferenceElementState, savePartnerState],
  );

  const [submitedRow, setSubmitedRow] = useState<Table.Entry | null>(null);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState<boolean>(false);
  const onSuccessSelect = useRef<null | ((partner: Partner) => void)>(null);

  const getPartner = useCallback<(tableRow: Table.Entry) => Promise<Partner | null>>(
    (tableRow: Table.Entry) =>
      new Promise(resolve => {
        getPartnerAPI.callAPI(
          {
            partnerId: tableRow.id,
            enterpriseCustomerId: tableRow.Type === 'ENTERPRISE_CUSTOMER' ? tableRow.id : null,
            enterpriseId: tableRow.Type === 'ENTERPRISE' ? tableRow.id : null,
            shortName: tableRow.Type === 'RAW' ? tableRow.ShortName ?? null : null,
            fullName: tableRow.Type === 'RAW' ? tableRow.FullName : null,
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => resolve(null),
          },
        );
      }),
    [getPartnerAPI],
  );

  const getReference = useCallback<({ refName, code }: GetRefProps) => Promise<ReferenceItem | null>>(
    ({ refName, code }: GetRefProps) =>
      new Promise(resolve => {
        getReferenceElementAPI.callAPI(
          {
            refName,
            code,
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getReferenceElementAPI],
  );

  const onSelectRow = useCallback(
    (tableRow: Table.Entry, onSuccess: (partner: Partner) => void) => {
      const onSelect = async () => {
        const isPartner = tableRow.Type === 'PARTNER';

        if (isPartner) {
          const selectedPartner = await getPartner(tableRow);
          if (selectedPartner) {
            onSuccess(selectedPartner);
          }
        } else {
          setSubmitedRow(tableRow);
          onSuccessSelect.current = onSuccess;
          setIsInfoPopupOpen(true);
        }
      };

      onSelect();
    },
    [getPartner],
  );

  const onCloseInfoPopup = useCallback(() => {
    setIsInfoPopupOpen(false);
  }, []);

  const onConfirmInfoPopup = useCallback(async () => {
    if (submitedRow) {
      const [entepriseStatus, selectedPartner] = await Promise.all([
        getReference({ refName: 'RefEnterpriseStatus', code: 'ACTIVE' }),
        getPartner(submitedRow),
      ]);

      if (selectedPartner && entepriseStatus) {
        const nextPartner: Partner = { ...selectedPartner, state: entepriseStatus };
        savePartnerAPI.callAPI(
          {
            partner: nextPartner,
          },
          {
            onSuccessfullCall: ({ data }) => {
              if (onSuccessSelect.current) {
                onSuccessSelect.current({ ...nextPartner, id: data.id ?? '-1' });
              }
            },
          },
        );
      }
    }
    setIsInfoPopupOpen(false);
  }, [getPartner, getReference, onSuccessSelect, savePartnerAPI, submitedRow]);
  return {
    isLoading,
    isInfoPopupOpen,
    onSelectRow,
    onCloseInfoPopup,
    onConfirmInfoPopup,
  };
};

export default useController;
