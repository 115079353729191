import { format } from 'date-fns';
import { Document, UserPermission } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';
import { formatStr } from 'utils/Constants/FormatStr';

type Arguments = {
  user: UserPermission | null;
  projectDocumentInitType?: string;
  defaultSharedValue: boolean;
};

export function getMockDocument({ user, projectDocumentInitType, defaultSharedValue }: Arguments): Document {
  return {
    docType: 'local',
    createdBy: user?.userName ?? '',
    createdDate: format(new Date(), formatStr),
    date: '',
    file: EMPTY_FILE,
    id: '',
    isShared: defaultSharedValue,
    isInfo: null,
    name: '',
    type: '',
    isCommonAward: false,
    endDate: null,
    isAutoProlongation: null,
    isUnexpirable: null,
    number: null,
    partnerDocumentType: null,
    responsible: null,
    responsibleJob: null,
    startDate: null,
    responsibleFio: null,
    responsibleJobAppointment: null,
    amount: null,
    projectDocumentType: projectDocumentInitType ?? '',
  };
}
