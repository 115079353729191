import React, { useCallback, useState } from 'react';
import { block } from 'bem-cn';

import { Button, ButtonMode, FormComponent, Modal } from 'components';

import { showNotification } from 'features/Notifications';
import { Program, Project } from 'types/models';
import { SelectStages } from './SelectStages/SelectStages';
import { Selectivity, SelectMode } from './SelectMode/SelectMode';
import { SelectIndicators } from './SelectIndicators/SelectIndicators';
import { IndicatorKey } from 'features/Form/looks/project/ProjectForm/model';

import './style.scss';

const b = block('add-indicators-modal');

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(stages: Project.Stage[], indicators: Program.Indicator[]): void;
  stages: Project.Stage[];
  indicators: Program.Indicator[];
  program: Program.Program | null;
  type: IndicatorKey;
};

export function AddIndicatorsModal(props: Props) {
  const { isOpen, stages, indicators, onClose, onSubmit, program, type } = props;

  const [stagesSelectivity, setStagesSelectivity] = useState<Selectivity>('all');
  const [indicatorsSelectivity, setIndicatorsSelectivity] = useState<Selectivity>('all');

  const [selectedStages, setSelectedStages] = useState<Project.Stage[]>([]);
  const [selectedIndicators, setSelectedIndicators] = useState<Program.Indicator[]>([]);

  const handleSubmit = useCallback(() => {
    const isAllStagesSelected = stagesSelectivity === 'all';
    const isAllIndicatorsSelected = indicatorsSelectivity === 'all';
    const stagesForSubmit = isAllStagesSelected ? stages : selectedStages;
    const indicatorsForSubmit = isAllIndicatorsSelected ? indicators : selectedIndicators;
    if ((!isAllStagesSelected && !stagesForSubmit.length) || (!isAllIndicatorsSelected && !indicatorsForSubmit.length)) {
      showNotification({
        message: 'Если выбрана опция "Выборочно", необходимо выбрать хотя бы один элемент из списка',
        theme: 'danger',
      });
      return;
    }
    onSubmit(stagesForSubmit, indicatorsForSubmit);
    onClose();
  }, [stagesSelectivity, indicatorsSelectivity, selectedStages, selectedIndicators, stages, indicators, onClose, onSubmit]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Добавить из программы ${program?.shortName}`} size="large">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <SelectMode value={stagesSelectivity} onChange={setStagesSelectivity} />
        </FormComponent.Line>

        <div className={b('select-table')}>
          <SelectStages disabled={stagesSelectivity === 'all'} stages={stages} onSelectStages={setSelectedStages} />
        </div>
        {type === 'indicators' ? (
          <FormComponent.Description mode="warning">
            Список индикаторов программы формируется на форме Программы, вкладке Индикаторы (п.м. Заявки, Программы)
          </FormComponent.Description>
        ) : (
          <FormComponent.Description mode="warning">
            Список Показателей программы формируется на форме Программы, вкладке Показатели (п.м. Заявки, Программы)
          </FormComponent.Description>
        )}
        <FormComponent.Line>
          <SelectMode value={indicatorsSelectivity} onChange={setIndicatorsSelectivity} />
        </FormComponent.Line>

        <div className={b('select-table')}>
          <SelectIndicators
            disabled={indicatorsSelectivity === 'all'}
            indicators={indicators}
            onSelectIndicators={setSelectedIndicators}
            type={type}
          />
        </div>

        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Добавить" onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
