import React from 'react';

import { ConfirmPopup, Toolbar, TextArea } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState: { requestId } }: Props) {
  const {
    buttons,
    handleConfirmDeleteModal,
    confirmModal,
    isModalOpen,
    setIsModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    message,
    setMessage,
    editId,
  } = useController(tableState, requestId);
  return (
    <>
      <ConfirmPopup
        title={editId ? 'Редактирование сообщения' : 'Добавление сообщения'}
        isOpen={isModalOpen}
        okButtonText={editId ? 'Сохранить' : 'Добавить'}
        resetButtonText="Отмена"
        onConfirm={confirmModal}
        onClose={() => setIsModalOpen(false)}
        onReset={() => setIsModalOpen(false)}
      >
        <TextArea settings={{ rows: 5 }} value={message} onChange={setMessage} />
      </ConfirmPopup>
      <ConfirmPopup
        title="Удаление сообщения"
        text="Вы действительно хотите удалить выбранную запись?"
        isOpen={isDeleteModalOpen}
        okButtonText="Удалить"
        resetButtonText="Отмена"
        onConfirm={handleConfirmDeleteModal}
        onClose={() => setIsDeleteModalOpen(false)}
        onReset={() => setIsDeleteModalOpen(false)}
      />

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
