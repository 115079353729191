import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { JointEvent, Table as T, Event, Document } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { useFormContext } from 'features/Form/hooks';
import { EventFormat } from 'utils/Enums';
import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { ReferenceItem, buttonIcons } from 'components';
import { ParticipantList } from 'utils/Enums/ParticipantList';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { SaveJointEvent } from 'services/BackendAPI/configurations/jointEvent/types';
import {
  getMockJointEvent,
  convertToJointEventMemberCountType,
  FlatParticipantAward,
  convertAwardListToParticipants,
} from './helpers';
import { getEnum } from 'utils/Helpers';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, onClose }: Props) {
  const {
    look: { id, relatedTableState },
  } = useFormContext();

  const { enumMap, settings, currentPerson } = useAppDataContext();

  const tableStreams = useLocalTableStreams();

  const jointEventTypeOptions = getEnum('JointEventType', enumMap);

  const formatDefault: Item = EventFormat.OFFLINE;
  const formatList: Item[] = useMemo(() => Object.values(EventFormat), []);

  const participantDefault: string = 'SubTSU';
  const participantList: Item[] = ParticipantList();

  const [jointEvent, setJointEvent] = useState<JointEvent.JointEvent>(getMockJointEvent);
  const [participant, setParticipant] = useState<string>(participantDefault);
  const [feedlineMessage, setFeedlineMessage] = useState<string>('');
  const [feedlineMessageType, setFeedlineMessageType] = useState<ReferenceItem>({ id: '', label: '' });
  const [isFeedlineMessagePopupOpen, setIsFeedlineMessagePopupOpen] = useState<boolean>(false);
  const [needClose, setNeedClose] = useState<boolean>(false);

  const isTSUSelected = participant === 'TSU' || participant === 'SubTSU';
  const isOtherSelected = participant === 'Other' || participant === 'SubTSU';

  const { methods: getJointEvent } = BackendAPI.useBackendAPI('GetJointEvent', {
    onSuccessfullCall: ({ data }) => {
      setJointEvent(data);
      setParticipant(
        data.departments.length === 0 && data.enterprises.length !== 0
          ? 'Other'
          : data.departments.length !== 0 && data.enterprises.length !== 0
          ? 'SubTSU'
          : 'TSU',
      );
    },
  });
  const { methods: saveJointEvent } = BackendAPI.useBackendAPI('SaveJointEvent');

  const departmentRowConverter = useCallback<(row: T.Entry) => JointEvent.Department>(
    row => ({ id: null, element: { id: row.id, label: row.name }, position: '', isFinancing: false }),
    [],
  );

  const projectRowConverter = useCallback<(row: T.Entry) => JointEvent.JointEventProject>(
    row => ({
      id: null,
      accepted: false,
      acceptedBy: null,
      project: {
        id: row.id || '',
        number: row.projectNumber || '',
        name: row.name || '',
        program: row.Program || '',
        leader: row.Leader || '',
        financings: row.financing || '',
        governances: row.governance || '',
      },
    }),
    [],
  );

  const handleTypeChange = useCallback((e: Item) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, type: e }));
  }, []);

  const handleLevelChange = useCallback((e: ReferenceItem) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, level: e }));
  }, []);

  const handleIsStudentChange = useCallback((checked: boolean) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, isStudent: checked }));
  }, []);

  const handleOrderNumberChange = useCallback((value: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, orderNumber: value }));
  }, []);

  const handleOrderDateChange = useCallback((e: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, orderDate: e }));
  }, []);

  const handleNameChange = useCallback((value: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, name: value }));
  }, []);

  const handleRangeDateChange = useCallback((start: string, end: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, startDate: start, endDate: end }));
  }, []);

  const handleDescriptionChange = useCallback((value: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, description: value }));
  }, []);

  const handleKeyWordsChange = useCallback((value: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, keyWords: value }));
  }, []);

  const handleFormatChange = useCallback(
    (e: string) => {
      const value: Item | null = formatList.find(x => x.value === e) || formatDefault;
      setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, format: value }));
    },
    [formatDefault, formatList],
  );

  const handleSiteChange = useCallback((value: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, site: value }));
  }, []);

  const handleParticipantChange = useCallback((e: string) => {
    setParticipant(e);
  }, []);

  const handleDepartmentsChange = useCallback((e: JointEvent.Department[]) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, departments: e }));
  }, []);

  const handleEnterprisesChange = useCallback((e: JointEvent.Enterprise[]) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, enterprises: e }));
  }, []);

  const handleProjectsChange = useCallback((e: JointEvent.JointEventProject[]) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, projects: e }));
  }, []);

  const handleProjectAccept = useCallback(
    (projectId: string) => {
      const projectIndex = jointEvent.projects.findIndex(project => project.project?.id === projectId);
      const nextProjects = jointEvent.projects.map((x, i) => {
        if (i === projectIndex)
          return {
            ...x,
            accepted: true,
            acceptedBy: { id: currentPerson?.id || null, fullName: currentPerson?.fullName || '' },
          };
        return x;
      });
      setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, projects: nextProjects }));
    },
    [currentPerson?.fullName, currentPerson?.id, jointEvent.projects],
  );

  const handleProjectCancelAcceptance = useCallback(
    (projectId: string) => {
      const projectIndex = jointEvent.projects.findIndex(project => project.project?.id === projectId);
      const nextProjects = jointEvent.projects.map((x, i) => {
        if (i === projectIndex)
          return {
            ...x,
            accepted: false,
            acceptedBy: null,
          };
        return x;
      });
      setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, projects: nextProjects }));
    },
    [jointEvent.projects],
  );

  const handleCommitteMembersChange = useCallback((e: JointEvent.CommitteMember[]) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, committeMembers: e }));
  }, []);

  const handleMemberCountFactChange = useCallback((value: string) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, memberCountFact: value || '0' }));
  }, []);

  const handleMemberCountChange = useCallback((e: Event.MemberCount[]) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({
      ...prevState,
      memberCounts: e.map(convertToJointEventMemberCountType),
    }));
  }, []);

  const handleParticipantsChange = useCallback((e: JointEvent.Participant[]) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, participants: e }));
  }, []);

  const handleAwardsChange = useCallback(
    (e: FlatParticipantAward[]) => {
      setJointEvent((prevState: JointEvent.JointEvent) => ({
        ...prevState,
        participants: convertAwardListToParticipants(jointEvent.participants, e),
      }));
    },
    [jointEvent.participants],
  );

  const handleDocumentsChange = useCallback((e: Document[]) => {
    setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, documents: e }));
  }, []);

  const handleCalculateButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur();
      let counter = 0;
      jointEvent.memberCounts.forEach(member => {
        counter += parseInt(member.amount, 10);
      });
      setJointEvent((prevState: JointEvent.JointEvent) => ({ ...prevState, memberCountFact: String(counter) }));
    },
    [jointEvent.memberCounts],
  );

  const handleFeedlineMessageChange = useCallback((e: string) => {
    setFeedlineMessage(e);
  }, []);

  const handleFeedlineMessageTypeChange = useCallback((e: ReferenceItem) => {
    setFeedlineMessageType(e);
  }, []);

  const calculateButton = {
    icons: buttonIcons.calc,
    title: 'Рассчитать',
    code: 'calculate',
    isDisabled: viewMode,
    onClick: handleCalculateButtonClick,
  };

  const onSubmit = useCallback(
    (withMessage: boolean) => {
      const value: JointEvent.JointEvent = {
        ...jointEvent,
        departments: isTSUSelected ? jointEvent.departments.map((x, i) => ({ ...x, position: String(i) })) : [],
        enterprises: isOtherSelected ? jointEvent.enterprises.map((x, i) => ({ ...x, position: String(i) })) : [],
      };
      const args: SaveJointEvent = {
        jointEvent: value,
        feedlineMessage: withMessage ? feedlineMessage : undefined,
        feedlineMessageType: withMessage ? feedlineMessageType : undefined,
      };
      saveJointEvent.callAPI(args, {
        onSuccessfullCall: () => {
          showNotification({ message: 'Мероприятие сохранено', theme: 'success' });
          if (needClose) {
            onClose();
            tableStreams.reloadTable.push();
          } else if (id) getJointEvent.callAPI({ id });
          setIsFeedlineMessagePopupOpen(false);
        },
        onFailedCall: () => {
          setIsFeedlineMessagePopupOpen(false);
        },
      });
    },
    [
      feedlineMessage,
      feedlineMessageType,
      getJointEvent,
      id,
      isOtherSelected,
      isTSUSelected,
      jointEvent,
      needClose,
      onClose,
      saveJointEvent,
      tableStreams.reloadTable,
    ],
  );

  const handleSaveButtonClick = useCallback(
    (e: boolean) => {
      const isValid: boolean =
        !!jointEvent.type?.value &&
        !!jointEvent.level?.id &&
        !!jointEvent.name &&
        !!jointEvent.startDate &&
        !!jointEvent.format?.value &&
        !!jointEvent.memberCountPlan &&
        !!jointEvent.memberCountFact &&
        isTSUSelected
          ? !!jointEvent.departments.length
          : true && isOtherSelected
          ? !!jointEvent.enterprises.length
          : true;
      if (isValid) {
        setNeedClose(e);
        setIsFeedlineMessagePopupOpen(true);
      } else showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
    },
    [
      isOtherSelected,
      isTSUSelected,
      jointEvent.departments.length,
      jointEvent.enterprises.length,
      jointEvent.format?.value,
      jointEvent.level?.id,
      jointEvent.memberCountFact,
      jointEvent.memberCountPlan,
      jointEvent.name,
      jointEvent.startDate,
      jointEvent.type?.value,
    ],
  );

  useLayoutEffect(() => {
    if (id) getJointEvent.callAPI({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    feedlineMessage,
    feedlineMessageType,
    calculateButton,
    departmentRowConverter,
    formatDefault,
    formatList,
    handleFeedlineMessageChange,
    handleFeedlineMessageTypeChange,
    handleAwardsChange,
    handleCommitteMembersChange,
    handleDepartmentsChange,
    handleDescriptionChange,
    handleDocumentsChange,
    handleEnterprisesChange,
    handleFormatChange,
    handleIsStudentChange,
    handleKeyWordsChange,
    handleLevelChange,
    handleMemberCountChange,
    handleMemberCountFactChange,
    handleNameChange,
    handleOrderDateChange,
    handleOrderNumberChange,
    handleParticipantChange,
    handleParticipantsChange,
    handleProjectAccept,
    handleProjectCancelAcceptance,
    handleProjectsChange,
    handleRangeDateChange,
    handleSiteChange,
    handleTypeChange,
    isFeedlineMessagePopupOpen,
    isOtherSelected,
    isTSUSelected,
    jointEvent,
    jointEventId: id ?? null,
    jointEventTypeOptions,
    onSubmit,
    participant,
    participantDefault,
    participantList,
    projectRowConverter,
    relatedTableState,
    settings,
    handleSaveButtonClick,
  };
}
