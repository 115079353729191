import { Table, Recognition } from 'types/models';
import { Departments } from 'features/Table/views';

type Props = {
  workMode: Table.WorkMode;
  recognitionId: string;
  department: Recognition['department'] | null;
  status: string;
};

export const RecognitionHeader = ({ workMode, recognitionId = '', department, status }: Props): string | JSX.Element => {
  const hint = (() => {
    // eslint-disable-next-line max-len
    return 'Ваша персональная награда может быть добавлена в отчётность подразделения. Для этого необходимо указать подразделение на форме Редактирование связи с подразделением.';
  })();

  const baseTitle = (() => {
    const base = ['персональной награды (признания)', recognitionId ? `(ID ${recognitionId})` : ''].filter(x => x).join(' ');

    return [base, workMode !== 'addMode' && status ? `Статус записи: ${status}` : ''].filter(x => x).join('. ');
  })();

  const title = (() => {
    const action = (() => {
      if (workMode === 'addMode') {
        return 'Добавление';
      }
      if (workMode === 'viewMode') {
        return 'Просмотр';
      }
      return 'Редактирование';
    })();

    return [action, baseTitle].filter(x => x).join(' ');
  })();

  if (department) {
    return (
      <>
        <h3>{title}</h3>
        <h4>
          <Departments hint={hint} departments={department?.label} />
        </h4>
      </>
    );
  }

  return title;
};
