import { Table } from 'types/models';

type Arguments = {
  projectCodeId?: string | null;
};

export function GetUnaccountedExpensesList(args?: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetUnaccountedExpensesList',
    requestData: args,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
