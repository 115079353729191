import { Person } from 'types/models';

export function validate(item: Person.BibliographicName | null) {
  if (!item) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidName = Boolean(item.name);
  const isValidLanguage = Boolean(item.language && item.language.id);

  return [
    { isValid: isValidName, invalidMessage: 'Введите ФИО' },
    { isValid: isValidLanguage, invalidMessage: 'Укажите язык' },
  ];
}
