import React from 'react';

import { ListEdit } from 'components';

import { Project } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { validate } from './validate';
import { getMockResourceUsageDepartment } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';

type Props = {
  resourceUsageDepartments: Project.ResourceUsageDepartment[];
  onResourceUsageDepartmentsChange: (resourceUsageDepartment: Project.ResourceUsageDepartment[]) => void;
  disabled: boolean;
};

export const ResourceUsageDepartments = ({ resourceUsageDepartments, onResourceUsageDepartmentsChange, disabled }: Props) => (
  <ListEdit
    title="Использование ресурсов"
    withMessages
    isDeleteConfirmEnabled
    rows={resourceUsageDepartments}
    onChange={onResourceUsageDepartmentsChange}
    isToolbarDisabled={disabled}
    defaultRowsCount={10}
    maxHeight="none"
    columns={[
      { label: 'Тип ресурса', formatValue: x => x.type?.label ?? '' },
      { label: 'Подразделение', formatValue: x => x.department?.label ?? '' },
      { label: 'Примечание', formatValue: x => x.note ?? '' },
    ]}
    specification={{
      mode: 'customComponent',
      renderComponent: (resourceUsageDepartment, onResourceUsageDepartmentChange) => (
        <Fields
          resourceUsageDepartment={resourceUsageDepartment ?? getMockResourceUsageDepartment()}
          setResourceUsageDepartment={onResourceUsageDepartmentChange}
        />
      ),
      validation: {
        checkIsValid: (resourceUsageDepartment, index, list, mode) =>
          validate(resourceUsageDepartment, list, mode, index).every(x => x.isValid),
        onInvalidate: (resourceUsageDepartment, mode, index, list) => {
          const validationInfo = validate(resourceUsageDepartment, list, mode, index);
          showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
        },
      },
    }}
  />
);
