import React from 'react';

import { FormComponent, Reference, TextInput } from 'components';

import { Partner } from 'types/models';
import { ValueOf } from 'types/helpers';
import { SuggestionInput } from './SuggestionInput';

type Props = {
  partner: Partner | null;
  makeChangeHandler: (key: keyof Partner) => (value: ValueOf<Partner>) => void;
  disabled: boolean;
};

export function AddressAndDetails(props: Props) {
  const { makeChangeHandler, partner, disabled } = props;

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Страна">
              <Reference
                name="RefCountry"
                onChange={makeChangeHandler('country')}
                value={partner?.country ?? null}
                relationTableModalTitle="Страны"
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Регион">
              <SuggestionInput
                fieldName="ref_addresses.district"
                value={partner?.district ?? ''}
                onChange={makeChangeHandler('district')}
                limit={20}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Город/Нас. пункт">
              <SuggestionInput
                fieldName="ref_addresses.city"
                value={partner?.city ?? ''}
                onChange={makeChangeHandler('city')}
                limit={20}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Индекс">
              <TextInput value={partner?.postCode ?? ''} onChange={makeChangeHandler('postCode')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Улица">
              <TextInput value={partner?.street ?? ''} onChange={makeChangeHandler('street')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line hasGap lineSize="doublePadded">
            <FormComponent.Field label="Дом, корпус">
              <TextInput value={partner?.house ?? ''} onChange={makeChangeHandler('house')} isDisabled={disabled} />
            </FormComponent.Field>
            <FormComponent.Field label="Офис" labelSize="fit">
              <TextInput value={partner?.office ?? ''} onChange={makeChangeHandler('office')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="E-Mail">
              <TextInput value={partner?.email ?? ''} onChange={makeChangeHandler('email')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Телефон">
              <TextInput value={partner?.phone ?? ''} onChange={makeChangeHandler('phone')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Веб сайт">
              <TextInput value={partner?.webSite ?? ''} onChange={makeChangeHandler('webSite')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}
