import { useState, useCallback, useMemo } from 'react';
import { ContestRequestStatus, ContestRequestType } from 'utils/Enums';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { IconButtonProps, buttonIcons } from 'components';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { usePrivatePageContext } from 'App/PrivatePage/context';

type StatusArgs = {
  rows: { id: string; status: ContestRequestStatus | null; type: ContestRequestType }[];
  callback?(): void;
};

export function getContestRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();
  const { isProfile } = usePrivatePageContext();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<ContestRequestStatus>(ContestRequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeContestRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddContestRequestApprovement');

  const handleButtonClick = useCallback((newStatus: ContestRequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push();
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, contestRequestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
          },
        },
      );
    },
    [addApprovementAPI, rows],
  );

  const isAllActualization = !!rows.length && rows.every(x => x.type === ContestRequestType.ACTUALIZATION);
  const isAllExtension = !!rows.length && rows.every(x => x.type === ContestRequestType.EXTENSION);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.book,
        title: 'В черновик',
        isDisabled: !(isAllExtension && rows.every(x => !!x.status && [ContestRequestStatus.REJECTED].includes(x.status))),
        isHidden: isProfile,
        onClick: () => handleButtonClick(ContestRequestStatus.DRAFT, 'В черновик'),
        permissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_DRAFT,
        profilePermissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_DRAFT,
      },
      {
        icons: buttonIcons.medal,
        title: isAllActualization ? 'Подать на согласование актуальные данные этапа проекта' : 'Подать на конкурс',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [ContestRequestStatus.DRAFT, ContestRequestStatus.REVISION].includes(x.status)) ||
          !(
            (isAllActualization && isHasPermission(userPermission, Permits.CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION)) ||
            (isAllExtension && isHasPermission(userPermission, Permits.CONTEST_REQUEST_CHANGE_STATUS_REQUEST))
          ),
        onClick: () =>
          handleButtonClick(
            isAllActualization ? ContestRequestStatus.ACTUALIZATION : ContestRequestStatus.REQUEST,
            isAllActualization ? 'Подать на согласование актуальные данные этапа проекта' : 'Подать на конкурс',
          ),
        permissionName: [Permits.CONTEST_REQUEST_CHANGE_STATUS_REQUEST, Permits.CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION],
        profilePermissionName: [
          Permits.CONTEST_REQUEST_CHANGE_STATUS_REQUEST,
          Permits.CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION,
        ],
      },
      {
        icons: buttonIcons.like,
        title: 'Присвоить заявке статус "Согласована"',
        code: 'approve',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [ContestRequestStatus.REQUEST, ContestRequestStatus.ACTUALIZATION].includes(x.status)),
        isHidden: isProfile,
        onClick: () => handleButtonClick(ContestRequestStatus.AGREED, 'Присвоить заявке статус "Согласована"'),
        permissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED,
        profilePermissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED,
      },
      {
        icons: buttonIcons.yellowLike,
        title: 'Присвоить заявке статус "Согласована с условием"',
        code: 'agreed_with_condition',
        isDisabled: !(
          isAllExtension &&
          rows.every(
            x =>
              !!x.status &&
              [ContestRequestStatus.REQUEST, ContestRequestStatus.AGREED, ContestRequestStatus.SUPPORTED].includes(x.status),
          )
        ),
        isHidden: isProfile,
        onClick: () =>
          handleButtonClick(ContestRequestStatus.AGREED_WITH_CONDITION, 'Присвоить заявке статус "Согласована с условием"'),
        permissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION,
        profilePermissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION,
      },
      {
        icons: buttonIcons.star,
        title: 'Поддержать',
        code: 'support',
        isDisabled: !(
          isAllExtension &&
          rows.every(
            x => !!x.status && [ContestRequestStatus.AGREED, ContestRequestStatus.AGREED_WITH_CONDITION].includes(x.status),
          )
        ),
        isHidden: isProfile,
        onClick: () => handleButtonClick(ContestRequestStatus.SUPPORTED, 'Поддержать'),
        permissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_SUPPORTED,
        profilePermissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_SUPPORTED,
      },
      {
        icons: buttonIcons.update,
        title: 'Отправить на доработку',
        code: 'revison',
        isDisabled:
          !rows.length ||
          !rows.every(
            x =>
              !!x.status &&
              [ContestRequestStatus.REQUEST, ContestRequestStatus.REJECTED, ContestRequestStatus.ACTUALIZATION].includes(
                x.status,
              ),
          ),
        isHidden: isProfile,
        onClick: () => handleButtonClick(ContestRequestStatus.REVISION, 'Отправить на доработку'),
        permissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_REVISION,
        profilePermissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_REVISION,
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отклонить',
        code: 'reject',
        isDisabled: !(
          isAllExtension &&
          rows.every(
            x => !!x.status && [ContestRequestStatus.REQUEST, ContestRequestStatus.AGREED_WITH_CONDITION].includes(x.status),
          )
        ),
        isHidden: isProfile,
        onClick: () => handleButtonClick(ContestRequestStatus.REJECTED, 'Отклонить'),
        permissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_REJECTED,
        profilePermissionName: Permits.CONTEST_REQUEST_CHANGE_STATUS_REJECTED,
      },
      {
        icons: buttonIcons.approve,
        title: 'Экспертная оценка',
        isDisabled: !!rows[0]?.status && rows[0].status === ContestRequestStatus.DRAFT,
        isHidden: isProfile,
        onClick: () => setIsApprovementModalOpen(true),
        permissionName: Permits.CONTEST_REQUEST_AGREE,
        profilePermissionName: Permits.CONTEST_REQUEST_AGREE,
      },
    ],
    [isAllExtension, rows, isProfile, isAllActualization, userPermission, handleButtonClick],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}
