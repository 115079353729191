import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isForApprovement',
      label: 'На подтверждение статуса "Партнёр"',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isCurated',
      label: 'Курируемые',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isDeleted',
      label: 'Удалённые',
      checked: false,
    },
  ]);
});
