import React from 'react';

import { FormComponent, TextGridSelect } from 'components';
import { ExtraButton } from 'components/TextGridSelect/TextGridSelect';

import { Item } from 'types/models/common';
import { GetNirRequestList } from 'features/Table/specifications';
import { NirRequestStatus } from 'utils/Enums';
import useController from './controller';

type Props = {
  nir: Item<string> | null;
  setNir: (contestRequest: Item<string> | null) => void;
  label: string;
  modalTitle: string;
  extraButtons?: ExtraButton<Item>[];
  isRequired?: boolean;
  isDisabled?: boolean;
};
export const SelectNir = ({ nir, setNir, label, modalTitle, extraButtons, isRequired, isDisabled }: Props) => {
  const { onSelectRow } = useController();
  return (
    <FormComponent.Field label={label} isRequired={isRequired}>
      <TextGridSelect
        specification={GetNirRequestList({
          isForProject: true,
          isClearToolbar: true,
          statuses: [NirRequestStatus.SUPPORTED, NirRequestStatus.AGREED_WITH_CONDITION],
          enumFilterValues: {
            enumName: 'NirRequestStatus',
            excludeValues: [
              NirRequestStatus.REQUEST,
              NirRequestStatus.AGREED,
              NirRequestStatus.DRAFT,
              NirRequestStatus.REJECTED,
              NirRequestStatus.REVISION,
            ],
          },
        })}
        disabled={isDisabled}
        title={modalTitle}
        value={nir}
        valueFormatter={x => x.label}
        onChange={setNir}
        settings={{
          mode: 'loadTableRow',
          onSelectRow,
        }}
        extraButtons={extraButtons}
      />
    </FormComponent.Field>
  );
};
