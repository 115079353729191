import { Table as T } from 'types/models';
import { Filters } from './filters';

type Arguments = {
  projectId?: string;
  withTemplatesPanel?: boolean;
};

type RequestData = {
  projectId?: string;
};

export function GetPurchaseRequestUnitList({ projectId, withTemplatesPanel }: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetPurchaseRequestUnitList',
    requestData: { projectId },
    header: {
      firstLevel: {
        title: 'Список всех товаров, услуг, работ из заявок',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: !!withTemplatesPanel,
        LeftPanel: undefined,
      },
    },
  };
}
