import React, { useCallback } from 'react';

import { Table } from 'types/models';

import { EditProject } from 'features/Form/looks/project/ProjectForm';

import { State } from '../makeUseCustomController';

import './style.scss';
import { ProjectType } from 'utils/Enums';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function Form({ customState, tableState }: Props) {
  const {
    isOpenForm,
    programRequestId,
    nirRequestId,
    securityDocumentContractId,
    mode,
    reloadTable,
    closeForm,
    copiedProject,
  } = customState;
  const [row] = tableState.selectedRows;

  const onSussessSave = useCallback(() => {
    reloadTable();
    closeForm();
  }, [closeForm, reloadTable]);

  return (
    <EditProject
      id={row?.id ?? null}
      isOpen={isOpenForm}
      onClose={() => closeForm()}
      mode={mode}
      onSuccessfullSave={onSussessSave}
      programRequestId={programRequestId}
      nirRequestId={nirRequestId}
      securityDocumentContractId={securityDocumentContractId}
      projectType={ProjectType.GOVERMENT_CONTRACT}
      copiedProject={copiedProject}
    />
  );
}
