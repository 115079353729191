import React from 'react';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal title="Мероприятия" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div>
            Таблица содержит список организованных совместно с университетом мероприятий и мероприятий организации, в которых
            приняли участие сотрудники, аспиранты, студенты университета). Источник информации — функциональный модуль{' '}
            <strong>Мероприятия: Конференции, Выставки, Олимпиады и конкурсы.</strong>
            Условие для отображения данных в таблице является выбор организации:
            <ul>
              <li>
                на форме редактирования мероприятия(конференции, конкурса, олимпиады),.», на вкладке Организация мероприятия, в
                разделе <strong>Организаторы мероприятия</strong>
              </li>
            </ul>
            <div>
              <div>
                <strong className="state-danger">ВАЖНО!</strong> В случае, если для организации указаны организации на
                вкладках&nbsp;
                <strong>Связи с другими организациями</strong> и <strong>Варианты наименования</strong>, то в списке будут
                отображаться записи связанных организаций. В столбце <strong>Организация</strong> отображается оригинальное
                название организации. В столбце <strong>Роль</strong> отображается роль организации в проекте.
              </div>
            </div>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
