import * as R from 'ramda';
import { MipPartner } from 'types/models';
import { Settings } from 'types/models/common';
import { convertDocument, convertFile, convertMaybeArray, convertReference, getId, getText } from '../commonConverters';
import { convertServerPartner } from '../partners/converters';
import { convertServerScientist } from '../author/converters';

export const convertServerMipPartner = (mipPartner: any, settings: Settings): MipPartner.MipPartner => {
  const mipDocuments = mipPartner.MipDocuments?.Document || [];

  return {
    ...convertServerPartner(mipPartner, settings),
    terminatedDate: getText(mipPartner?.TerminatedDate),
    terminatedNote: getText(mipPartner?.TerminatedNote),
    registrationDate: getText(mipPartner?.RegistrationDate),
    isFl217: getText(mipPartner?.IsFl217) === 'true',
    isFl273: getText(mipPartner?.IsFl273) === 'true',
    mipDocuments: convertMaybeArray(mipDocuments, R.partial(convertDocument, ['local'])),
    indicators: convertServerIndicators(mipPartner, settings),
    charterCapitals: convertServerChapterCapitals(mipPartner),
  };
};

const convertServerIndicators = (mipPartner: any, settings: Settings): MipPartner.Indicator[] => {
  const indicators = mipPartner?.Indicators?.Indicator || [];
  return convertMaybeArray(indicators, indicator => convertServerIndicator(indicator, settings));
};

const convertServerChapterCapitals = (mipPartner: any): MipPartner.CharterCapital[] => {
  const charterCapitals = mipPartner?.CharterCapitals?.CharterCapital || [];
  return convertMaybeArray(charterCapitals, convertServerChapterCapital);
};

const convertServerFounder = (mipPartnerFounder: any): MipPartner.Founder => ({
  id: getId(mipPartnerFounder) || null,
  amount: getText(mipPartnerFounder?.Amount),
  scientist: mipPartnerFounder?.Scientist ? convertServerScientist(mipPartnerFounder?.Scientist) : null,
  info: getText(mipPartnerFounder?.Info),
  passport: getText(mipPartnerFounder?.Passport),
  enterpriseCustomer: convertReference(mipPartnerFounder?.EnterpriseCustomer),
  file: convertFile(mipPartnerFounder.File),
  insertionAsserts: getText(mipPartnerFounder?.InsertionAsserts),
});

const convertServerChapterCapital = (mipPartnerChapterCapital: any): MipPartner.CharterCapital => {
  const founders = mipPartnerChapterCapital?.Founders?.Founder || [];
  return {
    id: getId(mipPartnerChapterCapital) || null,
    editDate: getText(mipPartnerChapterCapital?.EditDate),
    amount: getText(mipPartnerChapterCapital?.Amount),
    founders: convertMaybeArray(founders, convertServerFounder),
  };
};

export const convertServerIndicator = (indicator: any, settings: Settings): MipPartner.Indicator => {
  return {
    id: getId(indicator) || null,
    year: getText(indicator?.Year),
    profit: getText(indicator?.Profit),
    revenue: getText(indicator?.Revenue),
    reportDate: getText(indicator?.ReportDate),
    averageHeadcount: getText(indicator?.AverageHeadcount),
    ...(indicator.Partner ? { partner: convertServerMipPartner(indicator.Partner, settings) } : {}),
  };
};
