import { Table } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};
export function useController({ tableState, customState }: Props) {
  const { enterpriseCustomerIds, enterpriseIds } = customState;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);
  const [scientistId, setScientistId] = useState<string | null>(null);

  const closeModal = useCallback(() => {
    setScientistId(null);
    setIsOpenModal(false);
  }, []);

  const togglePersonModal = useCallback(() => {
    setIsOpenPersonModal(prev => !prev);
  }, []);

  const [row] = tableState.selectedRows;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.viewList,
        title: 'Просмотр списка публикаций',
        code: 'viewList',
        isDisabled: !row,
        onClick: () => {
          if (row) {
            setScientistId(row.id);
            setIsOpenModal(true);
          }
        },
      },
      {
        icons: buttonIcons.person,
        title: 'Просмотр портфолио',
        isDisabled: !row,
        onClick: () => {
          if (row) {
            togglePersonModal();
          }
        },
      },
    ],
    [row, togglePersonModal],
  );

  return {
    buttons,
    isOpenModal,
    isOpenPersonModal,
    closeModal,
    togglePersonModal,
    enterpriseCustomerIds,
    enterpriseIds,
    scientistId,
  };
}
