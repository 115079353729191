import { useState, useCallback, useEffect, useMemo, useLayoutEffect } from 'react';
import { useStream } from 'StreamRx';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Author, Document, Form, FileInfo, RecordStatus as RS } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import useSavePublicationHook from 'features/Form/hooks/savePublicationHook';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { useAppDataContext } from 'features/AppData/context';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { EventFormat } from 'utils/Enums';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { validate } from './validate';
import { mobileRequestsStream, projectsStreams } from './streams';
import { useFormContext } from 'features/Form/hooks';
import { setByResponse } from './setup/setByResponse';
import { formatToRequest } from './setup/formatToRequest';
import { ParticipationEventSource } from 'types/models/Participation';
import { ModePublicationHeader, PublicationHeader } from 'features/Form/views/ModalHeader/publication';
import { showNotification } from 'features/Notifications';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  onClose: () => void;
};

const tabsId = 'PublicationThesis';

export function useController({ onClose }: Props) {
  const { currentPerson, initialLanguageCode, settings, userSystemDepartment } = useAppDataContext();

  const {
    look: {
      type,
      id,
      isElectronic,
      electronicType,
      relatedTableState,
      name,
      viewMode,
      editMode,
      arm,
      handleChangeParticipation,
      setPublication,
      setPublicationConferenceSource,
      eventId,
      disableAddParticipationAfterSave,
      setTitle,
    },
  } = useFormContext<Form.PublicationFormLook>();

  const { isProfile } = usePrivatePageContext();
  const tableStreams = useLocalTableStreams();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const [isRecordElectronic, setIsRecordElectronic] = useState<boolean>(!!isElectronic);
  const [recordElectronicType, setRecordElectronicType] = useState<string>(electronicType || '');
  const [isConferenceConnection, setIsConferenceConnection] = useState(false);
  const [sourcePublicationDate, setSourcePublicationDate] = useState('');
  const [sourcePublicationEvents, setSourcePublicationEvents] = useState<string[]>([]);
  const [sourcePublicationReports, setSourcePublicationReports] = useState<string[]>([]);
  const [publicationId, setPublicationId] = useState<string | undefined>(id);
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const [projects, setProjects] = useState<PublicationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<PublicationMobileRequest[]>([]);
  const [sourceCompilation, setSourceCompilation] = useState<Form.Compilation | null>(null);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [topMessage, setTopMessage] = useState<string>('');
  const [userDepartments, setUserDepartments] = useState<ReferenceItem[]>([]);
  const [publicationDepartment, setPublicationDepartment] = useState<ReferenceItem | null>(null);
  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      required: true,
      isValid: true,
      title: 'Название',
      maxSymbols: 2049,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    typeEdition: {
      value: '',
      isValid: true,
      title: 'SEND_DATA',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          typeEdition: { ...prevState.typeEdition, value },
        }));
      },
    },
    year: {
      value: '',
      isValid: true,
      title: 'SEND_DATA',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          year: { ...prevState.year, value },
        }));
      },
    },
    annotation: {
      value: '',
      isValid: true,
      required: true,
      title: 'Аннотация',
      tooltipText: 'Если у публикации отсутствует аннотация, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, isValid: true },
        }));
      },
    },
    authorsKeyWords: {
      value: '',
      isValid: true,
      required: true,
      title: 'Авторские ключевые слова',
      tooltipText: 'Если у публикации отсутствуют ключевые слова, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsKeyWords: { ...prevState.authorsKeyWords, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsKeyWords: { ...prevState.authorsKeyWords, isValid: true },
        }));
      },
    },
    bibliographicRecord: {
      value: '',
      isValid: true,
      title: 'Библиографическая запись',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          bibliographicRecord: { ...prevState.bibliographicRecord, value },
        }));
      },
    },
    doi: {
      value: '',
      isValid: true,
      title: 'DOI',
      tooltipText: 'Цифровой идентификатор объекта, представленного в Интернете, пример заполнения: 10.1007/b136753',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, isValid: true },
        }));
      },
    },
    authorsCount: {
      value: '',
      isValid: true,
      required: true,
      title: 'Всего авторов в публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, isValid: true },
        }));
      },
    },
    fractionalScore: {
      value: '',
      isValid: true,
      required: false,
      title: 'Фракционный счёт университета',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, isValid: true },
        }));
      },
    },
    textLanguage: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Язык текста',
      required: true,
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          textLanguage: {
            ...prevState.textLanguage,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          textLanguage: { ...prevState.textLanguage, isValid: true },
        }));
      },
    },
    pageFrom: {
      value: '',
      isValid: true,
      title: 'Страницы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageFrom: { ...prevState.pageFrom, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageFrom: { ...prevState.pageFrom, isValid: true },
        }));
      },
    },
    pageTo: {
      value: '',
      isValid: true,
      title: '-',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageTo: { ...prevState.pageTo, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageTo: { ...prevState.pageTo, isValid: true },
        }));
      },
    },
    printPageCount: {
      value: '',
      isValid: true,
      title: 'Печатных листов',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, isValid: true },
        }));
      },
    },
    articleNumber: {
      value: '',
      isValid: true,
      title: 'Номер статьи',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          articleNumber: { ...prevState.articleNumber, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          articleNumber: { ...prevState.articleNumber, isValid: true },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      required: true,
      title: 'Файл',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    grnti: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'ГРНТИ',
      tooltipText:
        // eslint-disable-next-line max-len
        'Выберите коды ГРНТИ областей знаний из справочника ГРНТИ. Если код отсутствует в справочнике, добавьте его',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          grnti: { ...prevState.grnti, value },
        }));
      },
    },
    udk: {
      value: '',
      isValid: true,
      title: 'УДК',
      tooltipText: 'Универсальная десятичная классификация. Введите код УДК, который присвоен публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          udk: { ...prevState.udk, value },
        }));
      },
    },
    study: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Область науки',
      tooltipText:
        // eslint-disable-next-line max-len
        'Область науки выбрать из справочника. Если область науки отсутствует в справочнике, напишите запрос администратору на добавление области в справочник',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          study: { ...prevState.study, value },
        }));
      },
    },
    publicationLink: {
      value: '',
      isValid: true,
      tooltipText: 'Например: http://www.nir.ru/res/inv/ukazat25/index.php',
      title: 'Ссылка на публикацию',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationLink: { ...prevState.publicationLink, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationLink: { ...prevState.publicationLink, isValid: true },
        }));
      },
    },
    date: {
      value: '',
      isValid: true,
      title: 'Дата обращения',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          date: { ...prevState.date, value: nextDate || '' },
        }));
      },
    },
    electronicMediaSource: {
      value: '',
      isValid: true,
      required: false,
      title: 'Электронный носитель',
      tooltipText: 'Например: 1 электрон. опт диск (CD-R)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicMediaSource: {
            ...prevState.electronicMediaSource,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicMediaSource: {
            ...prevState.electronicMediaSource,
            isValid: true,
          },
        }));
      },
    },
    electronicSystemRequirements: {
      value: '',
      isValid: true,
      title: 'Системные требования',
      tooltipText: 'Например: Windows XP; CD-ROM; дисковод; мышь;',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicSystemRequirements: {
            ...prevState.electronicSystemRequirements,
            value,
            isValid: true,
          },
        }));
      },
    },
    foreignName: {
      value: '',
      required: false,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, isValid: true },
        }));
      },
    },
    foreignAnnotation: {
      value: '',
      required: false,
      isValid: true,
      title: 'Аннотация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, isValid: true },
        }));
      },
    },
    foreignAuthorsKeyWords: {
      value: '',
      required: false,
      isValid: true,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, isValid: true },
        }));
      },
    },
    foreignAuthors: {
      value: '',
      required: false,
      isValid: true,
      title: 'Авторы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, isValid: true },
        }));
      },
    },
  });

  const { methods: getPublication, state: getPublicationState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublicationAPI, state: savePublicationAPIState } = BackendAPI.useBackendAPI('SavePublication');
  const { methods: getUserDepartments, state: getUserDepartmentsState } = BackendAPI.useBackendAPI('GetUserDepartments');

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PUBLCIATION_THESIS', []);

  const [conferenceSource, setConferenceSource] = useState<ParticipationEventSource | null>(null);

  const loadSourceCompilation = useCallback(
    (compilation: Form.Compilation) => {
      getPublication.callAPI(
        {
          simpleFields: {
            status: RecordStatus.DRAFT,
            typeEdition: 'LOCAL',
            type: compilation.type,
            isElectronic: false,
            electronicType: '',
          },
          attrIdFields: {},
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          mobileRequests: {},
          projects: [],
          file: undefined,
          id: compilation.id,
          arm,
        },
        {
          onSuccessfullCall: ({ data }) => {
            const nextSourcePublicationEvents = data.events.map(
              event => `${event.shortDescription} (ID: ${event.id}. Статус: ${event.status.label || 'нет'})`,
            );

            if (data.events.length) {
              const [event] = data.events;
              setConferenceSource({
                entity: null,
                id: event.id,
                name: event.name,
                fullName: event.name,
                status: event.status.label,
                startDate: event.startDate,
                endDate: event.endDate,
                eventStatus: null,
                format: EventFormat.OFFLINE,
              });
            }
            setSourcePublicationDate(data.fields.publicationDate);
            const nextSourcePublicationReports = data.participations.map(
              participation => `${participation.reportTheme} (${participation.reporter})`,
            );

            setSourcePublicationEvents(nextSourcePublicationEvents);
            setSourcePublicationReports(nextSourcePublicationReports);

            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              year: {
                ...prevState.year,
                value: data.fields.year,
              },
              typeEdition: {
                ...prevState.typeEdidion,
                value: {
                  id: data.fields.typeEditionCode,
                  label: data.fields.typeEditionText,
                },
              },
            }));
          },
        },
      );
    },
    [arm, getPublication],
  );

  const fetchPublication = useCallback(() => {
    if (type) {
      getPublication.callAPI(
        {
          simpleFields: {
            status: RecordStatus.DRAFT,
            typeEdition: 'LOCAL',
            type,
            isElectronic: isRecordElectronic,
            electronicType: (!!isRecordElectronic && recordElectronicType) || '',
            presetAuthorCategory: arm === 'pc' ? 'author' : '',
          },
          attrIdFields: {},
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          mobileRequests: { mobileRequest: [] },
          projects: [],
          file: undefined,
          id: publicationId,
          arm,
        },
        {
          onSuccessfullCall: (result: any) => {
            const preparedData = result.data as Form.Publication;

            if (preparedData.compilation) {
              setSourceCompilation(preparedData.compilation);
              loadSourceCompilation(preparedData.compilation);
            }

            setIsRecordElectronic(preparedData.fields.isElectronic === 'true');
            setRecordElectronicType(preparedData.fields.electronicType);
            setByResponse({ setFormFields, preparedData, name });
            setPublicationInfo(preparedData);
            setProjects(preparedData.projects);
            setMobileRequests(preparedData.mobileRequests);
            setAuthors(preparedData.members);
            setDocuments(preparedData.documents);
          },
        },
      );
    }
  }, [type, getPublication, isRecordElectronic, recordElectronicType, arm, publicationId, name, loadSourceCompilation]);

  const prepareFormFields = useCallback(() => {
    // const isAuthorsCountEmpty = !formFields.authorsCount?.value;
    const preparedFormFields = R.clone(formFields);
    /* Удалено в рамках 543, чтобы отключить автоматический подсчет авторов
    if (isAuthorsCountEmpty && authors.length) {
      const nextValue = authors.length;
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        authorsCount: { ...prevState.authorsCount, value: nextValue },
      }));

      preparedFormFields.authorsCount.value = nextValue;
    }
    */
    const draftValidationResult = validate({
      formFields: preparedFormFields,
      authors,
      sourceCompilation,
      isDraft: true,
    });
    const validationResult = validate({
      formFields: preparedFormFields,
      authors,
      sourceCompilation,
      isDraft: false,
    });

    return {
      draftValidationResult,
      validationResult,
      preparedFormFields,
    };
  }, [authors, formFields, sourceCompilation]);

  const fetchBibliographicRecord = useCallback<Form.FetchBibliographicRecordAction>(
    () =>
      new Promise(resolve => {
        if (!formFields.name?.value) {
          showNotification({ theme: 'danger', message: 'Не заполнено название' });
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            name: { ...prevState.name, isValid: false },
          }));

          resolve(null);
          return;
        }
        const fieldsForRequest = formatToRequest({
          isElectronic: isRecordElectronic,
          electronicType: recordElectronicType,
          formFields,
          projects,
          mobileRequests,
          sourceCompilation,
          type,
          authors,
          status: 'ADDED',
        });
        getPublication.callAPI(
          {
            ...fieldsForRequest,
            isBibliographicFetch: true,
            arm,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                bibliographicRecord: { ...prevState.bibliographicRecord, value: data.fields.bibliographicRecord },
              }));
              resolve(data.fields.bibliographicRecord);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [
      arm,
      authors,
      formFields,
      getPublication,
      isRecordElectronic,
      mobileRequests,
      projects,
      recordElectronicType,
      sourceCompilation,
      type,
    ],
  );

  const onCreateBibliographicRecordClick = useCallback(() => {
    fetchBibliographicRecord();
  }, [fetchBibliographicRecord]);

  const savePublication = useCallback(
    ({ preparedFormFields, nextStatus, publicationMessage, publicationMessageType, isNeedClose }: Form.SavePublicationData) => {
      const currentFormFields = preparedFormFields || formFields;
      const isMessage = !!publicationMessageType?.id;
      const fieldsForRequest = formatToRequest({
        isElectronic: isRecordElectronic,
        electronicType: recordElectronicType,
        formFields: currentFormFields,
        projects,
        mobileRequests,
        sourceCompilation,
        type,
        authors,
        status: nextStatus,
      });
      savePublicationAPI.callAPI(
        {
          id: publicationId,
          ...fieldsForRequest,
          documents,

          feedLine: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
          arm,
          department: publicationDepartment,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setPublicationId(data.status.id);
            if (isNeedClose) {
              const isCanCreateParticipation =
                conferenceSource &&
                !sourcePublicationReports.length &&
                !publicationInfo?.participations.length &&
                !disableAddParticipationAfterSave;
              if (isCanCreateParticipation) {
                if (setPublication && setPublicationConferenceSource) {
                  setPublication({
                    id: data.status.id,
                    name: formFields.name.value,
                  });
                  setPublicationConferenceSource(conferenceSource);
                }
                setIsConferenceConnection(true);
              } else {
                onClose();
                tableStreams.reloadTable.push();
              }
            } else {
              updateWorkModeAfterSaveAndContinue();
              if (workMode === 'editMode') {
                fetchPublication();
              }
            }

            showNotification({
              theme: data.success ? 'success' : 'danger',
              message: publicationId
                ? data.success
                  ? nextStatus === RecordStatus.DRAFT
                    ? 'Черновик успешно сохранен!'
                    : 'Публикация успешно отредактирована!'
                  : data.message || 'Произошла ошибка при редактировании публикации!'
                : data.success
                ? nextStatus === RecordStatus.DRAFT
                  ? 'Черновик успешно сохранен!'
                  : 'Публикация успешно сохранена!'
                : data.message || 'Произошла ошибка при сохранении публикации!',
            });
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      formFields,
      isRecordElectronic,
      recordElectronicType,
      projects,
      mobileRequests,
      sourceCompilation,
      type,
      authors,
      savePublicationAPI,
      publicationId,
      documents,
      arm,
      publicationDepartment,
      conferenceSource,
      sourcePublicationReports,
      publicationInfo?.participations.length,
      disableAddParticipationAfterSave,
      setPublication,
      setPublicationConferenceSource,
      onClose,
      tableStreams.reloadTable,
      updateWorkModeAfterSaveAndContinue,
      workMode,
      fetchPublication,
    ],
  );

  const startCheckDuplicates = useCallback(
    ({ preparedFormFields, nextStatus, isNeedClose }: Form.StartCheckDuplicatesData) => {
      const successfullCallback = ({
        publicationMessage,
        publicationMessageType,
      }: {
        publicationMessage: string;
        publicationMessageType: ReferenceItem;
      }) => {
        savePublication({
          preparedFormFields,
          publicationMessage,
          publicationMessageType,
          isNeedClose,
          nextStatus,
        });
      };

      suchLikePublicationStreams.getSuchLikePublications.push({
        category: 'PUBLICATION',
        searchValue: formFields.name?.value as string,
        successfullCallback,
        publicationType: publicationInfo?.type as Form.PublicationTypeCode,
        currentId: publicationId || null,
        isDefaultSave: !isNeedClose,
        componentId: SUCH_LIKE_PUBLICATION_ID,
      });
    },
    [
      publicationInfo?.type,
      SUCH_LIKE_PUBLICATION_ID,
      formFields.name?.value,
      publicationId,
      savePublication,
      suchLikePublicationStreams.getSuchLikePublications,
    ],
  );

  const {
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    loadPersonAuthorState,
  } = useSavePublicationHook({
    currentStatus: (publicationInfo?.status?.status as RS.Code) || RecordStatus.DRAFT,
    currentStatusText: publicationInfo?.status.text || 'Черновик',
    setFormFields,
    prepareFormFields,
    startCheckDuplicates,
    savePublication,
    fetchBibliographicRecord,
    initialLanguageCode: initialLanguageCode?.id ?? '',
    authors,
    setAuthors,
    isProfile,
    currentPerson,
    type,
    tabsId,
    authorsTabIndex: 1,
  });

  const { isLoading } = useIsLoading({
    callStates: [getPublicationState, savePublicationAPIState, loadPersonAuthorState, getUserDepartmentsState],
  });

  useStream(
    () => projectsStreams.setProjects,
    (projectsData: Array<PublicationProject>) => setProjects(projectsData),
    [projects, setProjects, projectsStreams.setProjects],
  );

  useStream(
    () => projectsStreams.accept,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy,
        accepted,
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.accept, currentPerson],
  );

  useStream(
    () => projectsStreams.cancelAcceptance,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy: null,
        accepted: '',
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.cancelAcceptance],
  );

  useStream(
    () => mobileRequestsStream.setMobileRequests,
    (requests: Array<PublicationMobileRequest>) => setMobileRequests(requests),
    [mobileRequests, setMobileRequests, mobileRequestsStream.setMobileRequests],
  );

  useStream(
    () => mobileRequestsStream.accept,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy,
        accepted,
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.accept, currentPerson],
  );

  useStream(
    () => mobileRequestsStream.cancelAcceptance,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy: null,
        accepted: '',
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.cancelAcceptance],
  );

  useEffect(() => {
    if (publicationId || workMode === 'addMode') fetchPublication();
    // eslint-disable-next-line
  }, [publicationId]);

  const onDeleteSourceCompilation = () => {
    setSourceCompilation(null);
    setSourcePublicationDate('');
  };

  const compilationType = 'COMPILATION_CONFERENCE';
  const handleCloseConferenceConnectionModal = () => {
    setIsConferenceConnection(false);
    onClose();
    tableStreams.reloadTable.push();
  };
  const handleOpenAddConferenceParticipation = () => {
    onClose();
    setIsConferenceConnection(false);
    handleChangeParticipation!();
  };
  const changeSourceCompilation = (compilation: Form.Compilation | null) => {
    setSourceCompilation(compilation);
    if (compilation) {
      loadSourceCompilation(compilation);
    }
  };

  const addCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        const citationSystemIndex = publicationInfo.citationSystems.findIndex(
          citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
        );
        const isNewCitationSystem = citationSystemIndex === -1;

        if (isNewCitationSystem) {
          setPublicationInfo(prevState => ({
            ...prevState!,
            citationSystems: [...prevState!.citationSystems, citationSystem],
          }));
        } else {
          setPublicationInfo(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState!.citationSystems));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return {
              ...prevState!,
              citationSystems: nextCitationSystems,
            };
          });
        }
      }
    },
    [publicationInfo],
  );

  const editCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      const citationSystemToUpdateIndex = (publicationInfo?.citationSystems || []).findIndex(
        citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
      );

      if (citationSystemToUpdateIndex !== -1) {
        const clonedCitationSystems: Form.CitationSystem[] = publicationInfo?.citationSystems
          ? R.clone(publicationInfo.citationSystems)
          : [];
        clonedCitationSystems.splice(citationSystemToUpdateIndex, 1, citationSystem);

        setPublicationInfo(prevState => prevState && { ...prevState!, citationSystems: clonedCitationSystems });
      }
    },
    [publicationInfo?.citationSystems],
  );

  const removeCitationSystem = useCallback(
    (citationSystemId: string) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        setPublicationInfo(prevState => ({
          ...prevState!,
          citationSystems: [
            ...prevState!.citationSystems.filter(
              (system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId,
            ),
          ],
        }));
      }
    },
    [publicationInfo],
  );

  useEffect(() => {
    if (setTitle) {
      setTitle(
        PublicationHeader({
          settings,
          mode: ModePublicationHeader.default,
          workMode,
          publicationId: publicationInfo?.status.id || '',
          publicationTypeCode: publicationInfo?.type || '',
          publicationElectronicTypeCode: isElectronic ? electronicType : '',
          departments: publicationInfo?.departments || [],
          status: publicationInfo?.status.text || '',
          publicationTypeEdition: {
            id: publicationInfo?.fields.typeEditionCode || '',
            label: publicationInfo?.fields.typeEditionText || '',
          },
          isPreprint: false,
        }),
      );
    }
  }, [
    electronicType,
    isElectronic,
    publicationInfo?.departments,
    publicationInfo?.fields.typeEditionCode,
    publicationInfo?.fields.typeEditionText,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
    workMode,
  ]);

  useLayoutEffect(() => {
    if (!id)
      getUserDepartments.callAPI(
        { isParent: false },
        {
          onSuccessfullCall: ({ data }) => {
            const d = data.map(x => x.ref);
            setUserDepartments(d);
            setPublicationDepartment(d.find(x => x.id === userSystemDepartment?.id) || null);
          },
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    workMode,
    formFields,
    isElectronic: isRecordElectronic,
    electronicType: recordElectronicType,
    compilationType,
    authors,
    documents,
    projects,
    mobileRequests,
    relatedTableState,
    sourceCompilation,
    publicationInfo,
    publicationId,
    topMessage,
    sourcePublicationEvents,
    sourcePublicationReports,
    onCreateBibliographicRecordClick,
    setAuthors,
    setDocuments,
    changeSourceCompilation,
    onDeleteSourceCompilation,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    setTopMessage,
    handleCloseConferenceConnectionModal,
    handleOpenAddConferenceParticipation,
    isConferenceConnection,
    eventId,
    sourcePublicationDate,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
  };
}
