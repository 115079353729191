import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, CustomSelect } from 'components';

import useController from './controller';

type Props = {
  articlePublicationIds: string[];
  onClose: () => void;
  sourcePublicationId: string;
};

export const Component = memo(({ onClose, articlePublicationIds, sourcePublicationId }: Props) => {
  const {
    sourceCompilation,
    sourceCompilationTableConfig,
    handleSubmit,
    changeSourceCompilation,
    modalTableRowToSourceCompilationConventer,
  } = useController({ articlePublicationIds, sourcePublicationId, onClose });
  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field>
            <CustomSelect
              isRequired
              title="Название сборника"
              isStatusText
              original={sourceCompilation}
              relationTableModalTitle="Сборники"
              onChange={changeSourceCompilation}
              modalTableRowConverter={modalTableRowToSourceCompilationConventer}
              modalTableSpecification={sourceCompilationTableConfig}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Продолжить" onClick={handleSubmit} isDisabled={sourceCompilation === null} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
});
