import React from 'react';
import { DataGrid } from 'features/Table';
import { GetScientistHirshIndexesList } from 'features/Table/specifications';
import { Table as T } from 'types/models';

type Props = {
  relatedTableState: T.State | undefined;
  workMode: T.WorkMode;
};

function HirshIndexesList({ relatedTableState, workMode }: Props) {
  return (
    <>
      {relatedTableState && (
        <DataGrid
          specification={GetScientistHirshIndexesList(
            {
              relatedTableAPIID: 'GetScientistStatistics',
              relatedTableState,
              relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
            },
            workMode,
            relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
          )}
        />
      )}
    </>
  );
}

export { HirshIndexesList };
