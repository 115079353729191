import { useState } from 'react';

export type State = {
  isOpenModal: boolean;
  setIsOpenModal(isOpen: boolean): void;
  isOpenMergeModal: boolean;
  setIsOpenMergeModal(isOpen: boolean): void;
};

export function makeUseCustomController() {
  return function useCustomController(): State {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenMergeModal, setIsOpenMergeModal] = useState(false);

    return {
      isOpenModal,
      setIsOpenModal,
      isOpenMergeModal,
      setIsOpenMergeModal,
    };
  };
}
