import { Parameter, DateValue } from 'types/models';

const validateDate = (dateParameter: Parameter) => {
  const value = dateParameter.value as DateValue;
  const { required } = dateParameter;
  if (required) {
    const isValid = !!value;
    return { isValid, message: isValid ? '' : `Заполните поле "${dateParameter.description}"` };
  }

  return { isValid: true, message: '' };
};

export default validateDate;
