import { useCallback, useMemo, useState } from 'react';

import { Table } from 'types/models';
import { buttonIcons, IconButtonProps } from 'components';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';

type ButtonsAvailability = {
  isViewButtonDisabled: boolean;
  isEditButtonDisabled: boolean;
  isDeleteButtonDisabled: boolean;
};

export function useController(tableState: Table.State) {
  const tableStreams = useLocalTableStreams();

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ProgramEvent',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttonsAvailability = useMemo<ButtonsAvailability>(() => {
    const selectedRowsLength = tableState.selectedRows.length;
    return {
      isViewButtonDisabled: selectedRowsLength !== 1,
      isEditButtonDisabled: selectedRowsLength !== 1,
      isDeleteButtonDisabled: !selectedRowsLength,
    };
  }, [tableState.selectedRows.length]);

  const { isViewButtonDisabled, isEditButtonDisabled, isDeleteButtonDisabled } = buttonsAvailability;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        permissionName: Permits.PROGRAM_EVENT_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.PROGRAM_EVENT_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: Permits.PROGRAM_EVENT_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
        permissionName: Permits.PROGRAM_EVENT_DELETE,
      },
    ],
    [
      handleAddButtonClick,
      handleDeleteButtonClick,
      handleEditButtonClick,
      handleViewButtonClick,
      isDeleteButtonDisabled,
      isEditButtonDisabled,
      isViewButtonDisabled,
    ],
  );

  return {
    buttons,
    buttonsAvailability,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    isAddFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    isViewButtonDisabled,
    isViewFormOpen,
    nextPublicationName,
  };
}
