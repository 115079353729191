const isDev = process.env.NODE_ENV === 'development';

const domain = window.location.hostname;

export async function createUserOnPortal(data: object, userToken: string | null) {
  return new Promise((resolve, reject) => {
    const reqOpts: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Auth: userToken || '',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    };
    const url = isDev ? '/user/save' : `//${domain}/user/save`;
    fetch(url, reqOpts).then(response => {
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    });
  });
}

export async function loadPortalRoles(userToken: string | null) {
  return new Promise((resolve, reject) => {
    const reqOpts: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Auth: userToken || '',
      },
      redirect: 'follow',
    };
    const url = isDev ? '/role/all' : `//${domain}/role/all`;
    fetch(url, reqOpts).then(response => {
      if (response.status === 200) {
        resolve(response.json());
      }
      reject(response);
    });
  });
}

export async function deleteUserOnPortal(login: string, userToken: string | null) {
  return new Promise((resolve, reject) => {
    const reqOpts: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Auth: userToken || '',
      },
      body: login,
      redirect: 'follow',
    };
    const url = isDev ? '/user/deleteByLogin' : `//${domain}/user/deleteByLogin`;
    fetch(url, reqOpts).then(response => {
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    });
  });
}
