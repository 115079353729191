import React, { useCallback } from 'react';

import { FormComponent, TextInput, TextInputMode, TextGridSelect } from 'components';

import { isNumberValid } from 'utils/Validators';
import { formatNumber } from 'utils/Helpers';
import { EmployeeJob, EmployeeNominee } from 'types/models/Payment';
import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { GetScientistJobs } from 'features/Table/specifications';
import { calcShare } from 'features/Table/specifications/GetRequestFundList/helpers';

type Props = {
  nominee: EmployeeNominee;
  setNominee(nominee: EmployeeNominee): void;
  commonAmount: number;
};

export function Fields(props: Props) {
  const { nominee, setNominee, commonAmount } = props;

  const setEmployeePerson = useCallback(
    (member: Member) => {
      setNominee({ ...nominee, employee: member.person, job: null });
    },
    [setNominee, nominee],
  );

  const setAmount = useCallback(
    (amount: string) => {
      if (isNumberValid(amount)) {
        setNominee({ ...nominee, amountMoney: Number(amount) });
      }
    },
    [nominee, setNominee],
  );

  const setJob = useCallback(
    (job: EmployeeJob | null) => {
      setNominee({ ...nominee, job });
    },
    [nominee, setNominee],
  );

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО сотрудника" isRequired>
          <SelectPerson
            member={getMemberWithoutHistory(nominee.employee)}
            onUpdateMember={setEmployeePerson}
            withHistory={false}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Должность" isRequired>
          <TextGridSelect
            value={nominee.job}
            onChange={setJob}
            disabled={nominee.employee === null}
            specification={GetScientistJobs({ personId: nominee.employee?.id ?? '' })}
            settings={{
              mode: 'selectTableRow',
              tableRowConverter: job => ({ id: job.id, refAppointment: { id: job.appointmentId, label: job.appointment } }),
            }}
            valueFormatter={job => job?.refAppointment.label ?? ''}
            title="Должности"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма" isRequired>
          <TextInput
            mode={TextInputMode.number}
            value={String(nominee.amountMoney)}
            onChange={setAmount}
            settings={{ max: commonAmount }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="">
          <span>Доля {formatNumber(calcShare(nominee.amountMoney, commonAmount), 2)}</span>
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}
