import React from 'react';

import { FormComponent, IconButton, Reference, TextInput, TextDateTime } from 'components';

import { SelectPerson } from 'features/SelectPerson';
import { PortfolioCard } from 'features/Form/looks/person';
import { REG_STATUS_NAME } from './changeStatusController';
import { Toolbar } from './Toolbar/Toolbar';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function UserEditForm({ viewMode, editMode, onClose }: Props) {
  const {
    handleFormSubmit,
    formFields,
    user,
    member,
    workMode,
    handleChangeStatus,
    isOpenPersonModal,
    setIsOpenPersonModal,
    personInfoButton,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });
  return (
    <FormComponent.Template>
      <Toolbar
        user={user}
        disabledEdit={workMode === 'viewMode'}
        onSave={needClose => handleFormSubmit(needClose)}
        onSaveDraft={() => handleFormSubmit(false)}
        onActivate={() => handleChangeStatus(true)}
        onDeactivate={() => handleChangeStatus(false)}
      />

      <FormComponent.Wrapper>
        <FormComponent.Line lineSize="triplePadded">
          <FormComponent.Field label="Имя">
            <TextInput
              value={formFields.userFullName.value}
              onChange={formFields.userFullName.onChange}
              isDisabled={viewMode || user?.status?.value === REG_STATUS_NAME}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="triplePadded">
          <FormComponent.Field label="E-Mail">
            <TextInput value={formFields.email.value} onChange={formFields.email.onChange} isDisabled />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.person.title} isRequired={formFields.person.required}>
            <FormComponent.Line hasFreeFormat growIndexes={[1, 0]}>
              <SelectPerson member={member ?? null} onUpdateMember={formFields.person.onChange} withHistory={false} />
              <IconButton {...personInfoButton} key={personInfoButton.title} isDisabled={!member?.person?.id} />
            </FormComponent.Line>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Дата рождения">
            <TextDateTime
              value={formFields.birthDate.value}
              onChange={formFields.birthDate.onChange}
              isDisabled={viewMode || user?.status?.value === REG_STATUS_NAME}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <PortfolioCard
          isOpen={isOpenPersonModal}
          id={isOpenPersonModal ? member?.person?.id ?? null : null}
          onClose={() => setIsOpenPersonModal(false)}
          shortMode={true}
        />
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Подразделение">
            <Reference
              name="RefDepartment"
              onChange={formFields.department.onChange}
              value={formFields.department.value}
              relationTableModalTitle='Справочник "Подразделения"'
              isRequired
              disabled={viewMode || user?.status?.value === REG_STATUS_NAME}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(UserEditForm);
