export type State = {
  requestId: string;
};

export function makeUseCustomController(deps: State) {
  return function useCustomController(): State {
    return {
      requestId: deps.requestId,
    };
  };
}
