import React from 'react';

import { SectionTitle, FormComponent, InputSelect, TextDateTime, TextInput, Reference } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

import { Customers } from './Customers/Customers';
import { Departments } from './Departments/Departments';
import { Financings } from './Financings/Financings';
import { Stages } from './Stages/Stages';
import { FinancingsByYear } from './FinancingsByYear/FinancingsByYear';
import { TaskNirFields } from './TaskNirFields/TaskNirFields';
import { EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';
import { getEnum } from 'utils/Helpers';

export type Props = {
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
  setProject(job: Project.Project): void;
  disabled: boolean;
  indicators: EditableIndicator[];
  indices: EditableIndicator[];
  isEditableLocked: boolean;
};

export function AboutProject(props: Props) {
  const { disabled, project, isEditableLocked, indicators, indices, makeChangeHandler, setProject } = props;

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <TaskNirFields
          disabled={disabled}
          project={project}
          makeChangeHandler={makeChangeHandler}
          setProject={setProject}
          indicators={indicators}
          indices={indices}
        />
        <SectionTitle isRequired title="Проект выполняется на базе" />
        <FormComponent.Line>
          <Departments
            projectId={project?.id ?? undefined}
            departments={project?.departments ?? []}
            setDepartments={makeChangeHandler('departments')}
            disabled={disabled}
          />
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label="Статус договора" isRequired>
            <InputSelect
              options={getEnum('ProjectContractStatus')}
              value={getEnum('ProjectContractStatus').find(x => x.value === project?.projectContractStatus?.value) ?? null}
              onSelectChange={makeChangeHandler('projectContractStatus')}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Номер договора">
            <TextInput
              value={project?.contractNumber || ''}
              onChange={makeChangeHandler('contractNumber')}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид договора">
            <Reference
              disabled={disabled}
              value={project?.contractKind ?? null}
              relationTableModalTitle='Справочник "Виды договоров"'
              name="RefContractKind"
              onChange={makeChangeHandler('contractKind')}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата договора">
            <TextDateTime value={project?.contractDate} onChange={makeChangeHandler('contractDate')} />
          </FormComponent.Field>
        </FormComponent.Line>
        {!!project?.securityDocumentContract?.conclusionDate && !!project?.securityDocumentContract?.number && (
          <FormComponent.Field label="Регистрация в Роспатенте">
            №{project.securityDocumentContract.number} от {project.securityDocumentContract.conclusionDate}
          </FormComponent.Field>
        )}
        <SectionTitle isRequired title="Заказчики" />
        <FormComponent.Line>
          <Customers disabled={disabled} customers={project?.customers ?? []} setCustomers={makeChangeHandler('customers')} />
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <SectionTitle isRequired title="Финансирование проекта" />
        <FormComponent.Line>
          <Financings
            disabled={disabled}
            financings={project?.financings ?? []}
            setFinancingsByYear={makeChangeHandler('financingsByYear')}
            setFinancings={makeChangeHandler('financings')}
            setStages={makeChangeHandler('stages')}
            stages={project?.stages ?? []}
            projectId={project?.id ?? undefined}
          />
        </FormComponent.Line>
        <SectionTitle isRequired title="Календарный план" />
        <FormComponent.Line>
          <Stages
            isEditableLocked={isEditableLocked}
            project={project!}
            financings={project?.financings ?? []}
            stages={project?.stages ?? []}
            performers={project?.performers ?? []}
            setPerformers={makeChangeHandler('performers')}
            setStages={makeChangeHandler('stages')}
            setFinancings={makeChangeHandler('financings')}
            setFinancingsByYear={makeChangeHandler('financingsByYear')}
            disabled={disabled}
          />
        </FormComponent.Line>
        <SectionTitle title="Распределение по годам" />
        <FormComponent.Line>
          <FinancingsByYear financingsByYear={project?.financingsByYear ?? []} stages={project?.stages ?? []} />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
