import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isPlanned',
      label: 'Планируемые',
      checked: true,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isInWork',
      label: 'В работе',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isFinished',
      label: 'Завершенные',
      checked: false,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});
