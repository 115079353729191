import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';

const NirRequestStatus = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    enumProps={{
      fieldLabel: 'Статус заявки',
      listEditTitle: 'Статусы заявок',
      mode: { component: 'DefaultSelect', enumName: 'NirRequestStatus' },
    }}
  />
);

const Component = memo(NirRequestStatus);

export { Component as NirRequestStatus };
