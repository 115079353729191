import { Table as T } from 'types/models';

export type DetailProps = {
  colleagueScientistId: string;
  scientistId: string;
};
export function GetScientistColleagueRequestAndProjectList({
  colleagueScientistId,
  scientistId,
}: DetailProps): T.Specification<object> {
  return {
    apiID: 'GetScientistColleagueRequestAndProjectList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { colleagueScientistId, scientistId },
  };
}
