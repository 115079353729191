import React, { memo } from 'react';
import { setup } from 'bem-cn';
import * as icons from 'icons';

import { Button, ButtonMode, Modal } from 'components';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('text-error-popup');

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  errorText: string;
};

const TitleErrorPopup = ({ isOpen, onClose, errorText, onConfirm }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Внимание" size="small">
    <div className={b()}>
      <div className={b('body')}>
        <div className={b('icon-wrapper')}>
          <img src={icons.InfoConfirm} alt="" className={b('icon')} />
        </div>
        <div className={b('text-content')}>
          <p className={b('text')}>В наименовании обнаружены латинские символы</p>
          <p className={b('text', { bold: true })} dangerouslySetInnerHTML={{ __html: errorText }} />
          <p className={b('text')}>Вы уверены, что наименование верно?</p>
        </div>
      </div>
      <ul className={b('action-list')}>
        <li className={b('action-item')}>
          <Button mode={ButtonMode.primary} text="Да, верно" onClick={onConfirm} />
        </li>
        <li className={b('action-item')}>
          <Button mode={ButtonMode.secondary} text="Нет" onClick={onClose} />
        </li>
      </ul>
    </div>
  </Modal>
);

export const Component = memo(TitleErrorPopup);
