import React, { useMemo, useState } from 'react';

import { FormComponent } from 'components';

import { DataGrid } from 'features/Table';
import { GetFunds, GetRequestFundList } from 'features/Table/specifications';
import { Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';

export function FundsDepartments() {
  const { userPermission } = useAppDataContext();
  const [selectedFund, setSelectedFund] = useState<Table.Entry | null>(null);
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const isOnlyByDepartmentParameter = useMemo(() => {
    return (
      isHasPermission(userPermission, Permits.IP_FUND_VIEW_BY_DEPARTMENT) &&
      !isHasPermission(userPermission, Permits.IP_FUND_VIEW_FULL_LIST)
    );
  }, [userPermission]);

  return (
    <FormComponent.Template>
      <DataGrid
        specification={GetFunds({ selectedFund, selectFund: setSelectedFund, year, setYear, isOnlyByDepartmentParameter })}
      />
      <DataGrid
        specification={{
          ...GetRequestFundList({ selectedFund, year, isOnlyByDepartmentParameter }),
          isNotInitialLoadTable: true,
        }}
      />
    </FormComponent.Template>
  );
}
