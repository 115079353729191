import { Parameter, ParameterListValue } from 'types/models';

const queryBuilder = (parameter: Parameter) => {
  const value = parameter.value && (parameter.value as ParameterListValue);
  if (value) {
    const additionalValues = (value.additionalValues || [])
      .map(({ value: additionalValue, key }) => `${key}=${additionalValue ? 1 : 0}`)
      .join('&');
    const parentValue = value.parentId ? `${value.parentId}:` : '';

    const query = value.list.length
      ? `&${parentValue}${parameter.name}=${value.list.map(ref => ref.id).join(',')}&${additionalValues}`
      : `&${parameter.name}=-1`;
    return query;
  }

  return '-1';
};

export default queryBuilder;
