import * as R from 'ramda';
import { StreamRx } from 'StreamRx';
import { streams } from './streams';

type LoaderStreams<T> = {
  [K in keyof T]: T[K];
};

export function useLocalStreams<T>(localStreams: T): LoaderStreams<T> {
  return R.map(
    (stream): StreamRx<any> => ({
      push: (data: any) => stream.push(data),
      observable: stream.observable,
    }),
    localStreams as any,
  ) as any;
}

export function useLocalLoaderStreams() {
  return useLocalStreams(streams);
}
