import React from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { Table } from 'types/models';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { Requests } from 'features/Form/views/Requests';
import { Projects } from 'features/Form/views/Projects';
import { projectsStreams, mobileRequestsStream } from './streams';
import useController from './controller';

type Props = {
  relatedTableState: Table.State | undefined;
  publicationId: string;
  workMode: Table.WorkMode;
  onClose: () => void;
  onSuccess: () => void;
};

const setLocalProjects = (rows: PublicationProject[]) => {
  projectsStreams.setProjects.push(rows);
};

const setLocalProjectsAccept = (id: string) => {
  projectsStreams.accept.push(id);
};

const setLocalProjectsCancelAcception = (id: string) => {
  projectsStreams.cancelAcceptance.push(id);
};

const setLocalMobileRequest = (rows: PublicationMobileRequest[]) => {
  mobileRequestsStream.setMobileRequests.push(rows);
};

const setLocalMobileRequestAccept = (id: string) => {
  mobileRequestsStream.accept.push(id);
};

const setLocalMobileRequestCancelAcception = (id: string) => {
  mobileRequestsStream.cancelAcceptance.push(id);
};

function FinancicalSupportPopupComponent({ relatedTableState, workMode, publicationId, onClose, onSuccess }: Props) {
  const { projects, mobileRequests, publication, submitForm } = useController({ publicationId, onClose, onSuccess });

  return (
    <FormComponent.Wrapper>
      {!!publication && (
        <FormComponent.Wrapper>
          <h4 className="primary">{publication.status.typeLabel}</h4>
          <FormComponent.Line>
            <span>
              <strong> {publication.fields.bibliographicRecord}</strong>
            </span>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      )}
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <Projects
            setLocalProjects={setLocalProjects}
            setLocalProjectsAccept={setLocalProjectsAccept}
            setLocalProjectsCancelAcception={setLocalProjectsCancelAcception}
            workMode={workMode}
            projects={projects}
            relatedTableState={relatedTableState}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <Requests
            setLocalMobileRequest={setLocalMobileRequest}
            setLocalMobileRequestAccept={setLocalMobileRequestAccept}
            setLocalMobileRequestCancelAcception={setLocalMobileRequestCancelAcception}
            workMode={workMode}
            requests={mobileRequests}
            relatedTableState={relatedTableState}
          />
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Сохранить" onClick={submitForm} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Wrapper>
  );
}

export const Component = React.memo(FinancicalSupportPopupComponent);
