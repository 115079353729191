import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Member } from 'features/SelectPerson';
import { downloadFile, getAuthToken } from 'utils/Helpers';

type Props = {
  tableState: Table.State;
  recognitionsFilter: string;
};

export const useController = ({ tableState, recognitionsFilter }: Props) => {
  const token = getAuthToken();
  const { currentPerson, userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRow = selectedRowsLength ? tableState.selectedRows[0] : null;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDownloadFileDisabled = !(rowsAvailability.SINGLE_SELECTED && tableState.selectedRows[0]?.FileId);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const member = useMemo(
    (): Member => ({
      person: currentPerson || null,
      job: currentPerson && currentPerson.scientistJobs.length ? currentPerson.scientistJobs[0] : null,
      education: null,
      degree: currentPerson && currentPerson.degrees.length ? currentPerson.degrees[0] : null,
      citizenship: null,
      rank: null,
      academicRank: currentPerson && currentPerson.academicRanks !== null ? currentPerson.academicRanks[0] : null,
    }),
    [currentPerson],
  );

  const isEditPermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`RECOGNITION_${selectedRow?.['id:status']}_EDIT` as keyof Permits] || recognitionsFilter === 'mine',
    );
  }, [selectedRow, userPermission, recognitionsFilter]);

  const isDeletePermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`RECOGNITION_${selectedRow?.['id:status']}_DELETE` as keyof Permits] || recognitionsFilter === 'mine',
    );
  }, [selectedRow, userPermission, recognitionsFilter]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);
  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteRecognition',
      deletedItemPropName: 'Recognition',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const handleDownloadFile = useCallback(() => {
    downloadFile(tableState.selectedRows[0]?.FileId, token);
  }, [tableState.selectedRows, token]);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        isHidden: recognitionsFilter === 'all',
        profilePermissionName: Permits.RECOGNITION_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled || !isEditPermitted,
        onClick: handleEditButtonClick,
        isHidden: recognitionsFilter === 'all',
        profilePermissionName: [Permits.RECOGNITION_ADDED_EDIT, Permits.RECOGNITION_APPROVED_EDIT],
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled || !isDeletePermitted,
        onClick: handleDeleteButtonClick,
        isHidden: recognitionsFilter === 'all',
        profilePermissionName: [Permits.RECOGNITION_ADDED_DELETE, Permits.RECOGNITION_APPROVED_DELETE],
      },
      {
        icons: buttonIcons.download,
        title: 'Скачать прикрепленный файл',
        code: 'downloadFile',
        isDisabled: isDownloadFileDisabled,
        onClick: handleDownloadFile,
        isHidden: recognitionsFilter === 'all',
      },
    ],
    [
      handleHelpButtonClick,
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      recognitionsFilter,
      isEditButtonDisabled,
      isEditPermitted,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      isDeletePermitted,
      handleDeleteButtonClick,
      isDownloadFileDisabled,
      handleDownloadFile,
    ],
  );
  return {
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    isDeleteButtonDisabled,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    member,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  };
};
