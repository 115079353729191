import React from 'react';
import { renderToString } from 'react-dom/server';
import block from 'bem-cn';
import { format, parse } from 'date-fns';
import { formatStr, formatStrDay, formatStrMonthAsWordGenitive, formatStrYear } from 'utils/Constants/FormatStr';

import { Toolbar, Modal, Radio, ListEditTable, ConfirmPopup, FormComponent, TextArea, TextAreaMode, Header } from 'components';

import { NoticeKind } from 'utils/Enums';
import { Component as Block } from './block';
import { useController } from './controller';
import { Notice, NoticeTender } from 'types/models/Notice';
import { ru } from 'date-fns/locale';
import { useAppDataContext } from 'features/AppData/context';
// import { Statistic } from '../statistic';

import './style.scss';

const b = block('notices');

type Props = {
  isHome?: boolean;
  isTenders?: boolean;
};

function Notices(props: Props) {
  const { isHome = false, isTenders = false } = props;
  const {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setAddModalCaption,
    setAddModalData,
    setAddModalKind,
    addModalButtons,
    kindOptions,
    addModalKind,
    handleAddModalKindChange,
    addModalCaption,
    handleAddModalCaptionChange,
    addModalData,
    handleAddModalDataChange,
    isEditModalOpen,
    setIsEditModalOpen,
    editModalButtons,
    editModalKind,
    handleEditModalKindChange,
    selectedRowIndex,
    editModalCaption,
    handleEditModalCaptionChange,
    editModalData,
    handleEditModalDataChange,
    list,
    GetIndex,
    setSelectedRowIndex,
    buttons,
    listTenders,
  } = useController(isHome, isTenders);

  const { settings } = useAppDataContext();

  function getNotices() {
    if (isTenders) {
      return listTenders
        .sort((a: NoticeTender, c: NoticeTender) => {
          return (
            parse(c.announcementDate, formatStr, new Date()).getTime() -
            parse(a.announcementDate, formatStr, new Date()).getTime()
          );
        })
        .map((tender: NoticeTender) => {
          const formattedUniversityDate = tender.dateUniversity
            ? format(
                parse(tender.dateUniversity, formatStr, new Date()),
                [formatStrDay, formatStrMonthAsWordGenitive, formatStrYear].join(' '),
                {
                  locale: ru,
                },
              )
            : '';

          return ({
            id: tender.id,
            createdDate: tender.announcementDate,
            caption: tender.name,
            data: (() => {
              return [
                renderToString(
                  <p>
                    Участники: <strong>{tender.participants.join(' # ') || 'Любые'}</strong>
                  </p>,
                ),
                renderToString(
                  <p>
                    Категории конкурса: <strong>{tender.categories.join(' # ') || 'Любые'}</strong>
                  </p>,
                ),
                renderToString(
                  <p>
                    Области науки: <strong>{tender.interests.join(' # ') || 'Любые'}</strong>
                  </p>,
                ),
                renderToString(
                  <p>
                    Ссылка на сайт конкурса:{' '}
                    <strong>
                      <a href={tender.site} rel="noopener noreferrer" target="_blank">
                        {tender.site}
                      </a>
                    </strong>
                  </p>,
                ),
                renderToString(
                  <blockquote>
                    <em>Заявки, поданные от {settings?.organization?.shortName}, необходимо зарегистрировать </em>
                    <strong>
                      <em>
                        в Личном кабинете, на вкладке "Заявки на конкурсы"
                        {formattedUniversityDate ? ` в срок до ${formattedUniversityDate} года.` : ''}
                      </em>
                    </strong>
                  </blockquote>,
                ),
                tender.description,
              ]
                .filter(x => x)
                .join('');
            })(),
            kind: NoticeKind.OTHER,
          } as unknown) as Notice;
        });
    }

    return list;
  }

  const notices = (
    <>
      {!isTenders && (
        <div className={b('toolbar')}>
          <Toolbar buttons={buttons} />
        </div>
      )}

      <div className={b('blocks')}>
        {getNotices().map(e => (
          <Block props={e} key={e.id + e.createdDate} />
        ))}
      </div>
    </>
  );

  return (
    <>
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isConfirmPopupOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsConfirmPopupOpen(false)}
        onConfirm={handleDeleteButtonClick}
        icon="warning"
      />
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setAddModalCaption('');
          setAddModalData('');
          setAddModalKind(NoticeKind.OTHER);
        }}
        title="Добавление объявления"
        size="large"
      >
        <Toolbar buttons={addModalButtons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Тип обновления">
              <Radio value={addModalKind} list={kindOptions} onChange={handleAddModalKindChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Заголовок" isRequired>
              <TextArea settings={{ rows: 5 }} value={addModalCaption} onChange={handleAddModalCaptionChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Текст">
              <TextArea
                mode={TextAreaMode.wysiwyg}
                settings={{ rows: 15, title: 'Текст' }}
                value={addModalData}
                onChange={handleAddModalDataChange}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        title="Редактирование списка объявлений"
        size="large"
      >
        <Toolbar buttons={editModalButtons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Тип обновления">
              <Radio
                isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
                value={editModalKind}
                list={kindOptions}
                onChange={handleEditModalKindChange}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Заголовок" isRequired>
              <TextArea
                settings={{ rows: 5 }}
                value={editModalCaption}
                onChange={handleEditModalCaptionChange}
                isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Текст">
              <TextArea
                mode={TextAreaMode.wysiwyg}
                settings={{ rows: 15, title: 'Текст' }}
                value={editModalData}
                onChange={handleEditModalDataChange}
                isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Scroll maxHeight={220}>
            <ListEditTable
              rows={list}
              columns={[
                { label: '№', formatValue: row => (GetIndex(row.id) + 1).toString(), styles: { width: '5%' } },
                { label: 'Заголовок', formatValue: row => row.caption },
                { label: 'Дата', formatValue: row => row.createdDate, styles: { width: '15%' } },
              ]}
              selectedRowIndex={selectedRowIndex}
              selectRow={setSelectedRowIndex}
            />
          </FormComponent.Scroll>
        </FormComponent.Wrapper>
      </Modal>

      {/* Hidden statistic for all (temporary) */}
      {isHome && false ? (
        // <FormComponent.ColumnWrapper>
        //   <FormComponent.Column specialStyles={{ width: '65%' }}>
        //     <Header>Объявления{isTenders ? ' о конкурсах' : ''}</Header>
        //     {notices}
        //   </FormComponent.Column>
        //   <FormComponent.Column specialStyles={{ width: '35%' }}>
        //     <Header>Статистика</Header>
        //     <Statistic />
        //   </FormComponent.Column>
        // </FormComponent.ColumnWrapper>
        <>
          <Header>Объявления{isTenders ? ' о конкурсах' : ''}</Header>
          {notices}
        </>
      ) : (
        notices
      )}
    </>
  );
}

export const Component = React.memo(Notices);
