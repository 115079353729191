import { buttonIcons, ConfirmPopup, IconButtonProps, Toolbar } from 'components';
import React, { useMemo } from 'react';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import useController from './controller';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants/TextConstants';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { isFormOpen, messageLabel, publicationMessage, command } = customState;
  const {
    handleAddButtonClick,
    handleEditButtonClick,
    handleDeleteButtonClick,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    handleFormSubmit,
    handleTemplateCloseAddForm,
    handleMessageChange,
    handleMessageLabelChange,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
  } = useController({ tableState, publicationMessage, messageLabel });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
      },
    ],
    [handleAddButtonClick, handleEditButtonClick, handleDeleteButtonClick, isEditButtonDisabled, isDeleteButtonDisabled],
  );

  return (
    <>
      <AdministratorMessagePopup
        title={ADD_PUB_FEEDBACK_LABEL}
        onConfirm={handleFormSubmit}
        onConfirmWithoutMessage={handleTemplateCloseAddForm}
        isOpen={isFormOpen}
        publicationMessage={publicationMessage.message}
        updatePublicationMessage={handleMessageChange}
        publicationMessageType={messageLabel}
        setPublicationMessageType={handleMessageLabelChange}
        relationTableModalTitle="Сообщения журнала"
        cancelButtonText="Отмена"
        submitButtonText="Сохранить"
        command={command}
      />
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text="Вы уверены что хотите удалить выбранное сообщение?"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
          resetButtonText="Нет"
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
