import * as R from 'ramda';

import { Project } from 'types/models';

export function validate(financing: Project.Financing | null, list: Project.Financing[], mode: 'view' | 'edit' | 'add' | null) {
  const isValidType = Boolean(financing?.type?.value);
  const isValidSource = Boolean(financing?.source?.id);

  const financings = mode === 'edit' ? R.reject(x => Boolean((x as any)?.type?.value === financing?.type?.value), list) : list;

  const isHasSameTypeFinance = financings.find(x => x.type?.value === financing?.type?.value);
  const isValidSameTypeFinance = !isHasSameTypeFinance;

  return [
    { isValid: isValidType, invalidMessage: 'Выберите вид финансирования' },
    { isValid: isValidSource, invalidMessage: 'Выберите источник финансирования' },
    { isValid: isValidSameTypeFinance, invalidMessage: 'Источник финансирования с выбранным типом уже добавлен' },
  ];
}
