import { Dispatch, SetStateAction } from 'react';
import { Form } from 'types/models';
import * as R from 'ramda';
import { ElectronicType } from 'utils/Enums/ElectronicType';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  preparedData: Form.Publication;
  name: string | undefined;
  initialTypeEditionItem: {
    label: string;
    value: string;
    ordinal: number;
  } | null;
};

const setByResponse = ({ setFormFields, preparedData, name, initialTypeEditionItem }: Props) => {
  const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
  const getRequiredLens = (fieldName: string) => R.lensPath([fieldName, 'required']);
  setFormFields(prevFields =>
    (R.pipe as any)(
      R.set(getValueLens('name'), preparedData.fields.name || name),
      R.set(getValueLens('issn'), preparedData.fields.ISSN),
      R.set(getValueLens('isbn'), preparedData.fields.ISBN),
      R.set(getValueLens('textLanguage'), {
        id: preparedData.fields.textLanguageId || '',
        label: preparedData.fields.textLanguageLabel || '',
      }),
      R.set(getValueLens('publisher'), {
        id: preparedData.fields.publisherId || '',
        label: preparedData.fields.publisherLabel || '',
        foreignName: preparedData.fields.publisherForeignName || '',
      }),
      R.set(getValueLens('address'), preparedData.fields.address),
      R.set(getValueLens('year'), preparedData.fields.year || new Date().getFullYear()),
      R.set(getValueLens('month'), preparedData.fields.month),
      R.set(getValueLens('day'), preparedData.fields.day),
      R.set(getValueLens('volume'), preparedData.fields.volume),
      R.set(getValueLens('issue'), preparedData.fields.issue),
      R.set(getValueLens('publicationDate'), preparedData.fields.publicationDate),
      R.set(getValueLens('pageCount'), preparedData.fields.pageCount),
      R.set(getRequiredLens('pageCount'), preparedData.fields.isElectronic === 'false'),

      R.set(getValueLens('printPageCount'), preparedData.fields.printPageCount),
      R.set(getValueLens('tirage'), preparedData.fields.tirage),
      R.set(getValueLens('categoryEdition'), {
        label: preparedData.fields.categoryEditionLabel || '',
        id: preparedData.fields.categoryEditionId || '',
      }),
      R.set(getValueLens('typeEdition'), {
        label: initialTypeEditionItem ? initialTypeEditionItem.label : preparedData.fields.typeEditionText || '',
        id: initialTypeEditionItem ? initialTypeEditionItem.value : preparedData.fields.typeEditionCode || '',
      }),
      R.set(getValueLens('sourceType'), {
        id: preparedData.fields.sourceTypeId || '',
        label: preparedData.fields.sourceTypeLabel || '',
      }),
      R.set(getValueLens('seriesTitle'), preparedData.fields.seriesTitle),
      R.set(getValueLens('bibliographicRecord'), preparedData.fields.bibliographicRecord),
      R.set(getValueLens('doi'), preparedData.fields.doi),
      R.set(getValueLens('libraryLink'), preparedData.fields.libraryLink),
      R.set(getValueLens('file'), preparedData.file),
      R.set(getValueLens('electronicMediaSource'), preparedData.fields.electronicMediaSource),
      R.set(
        getRequiredLens('electronicMediaSource'),
        preparedData.fields.isElectronic === 'true' && preparedData.fields.electronicType === ElectronicType.LOCAL.code,
      ),
      R.set(getValueLens('electronicSystemRequirements'), preparedData.fields.electronicSystemRequirements),
      R.set(getValueLens('grnti'), {
        id: preparedData.fields.grntiId || '',
        label: preparedData.fields.grntiLabel || '',
      }),
      R.set(getValueLens('udk'), preparedData.fields.udk),
      R.set(getValueLens('study'), {
        id: preparedData.fields.studyId || '',
        label: preparedData.fields.studyLabel || '',
      }),
      R.set(getValueLens('annotation'), preparedData.fields.annotation),
      R.set(getValueLens('authorsKeyWords'), preparedData.fields.authorsKeyWords),
      R.set(getValueLens('authorsCount'), preparedData.fields.authorsCount),
      R.set(getValueLens('fractionalScore'), preparedData.fields.fractionalCount),
      R.set(getValueLens('isConfirencesPublished'), preparedData.events.length > 0),
      R.set(getValueLens('url'), preparedData.fields.url),
      R.set(
        getRequiredLens('url'),
        preparedData.fields.isElectronic === 'true' && preparedData.fields.electronicType === ElectronicType.WEB.code,
      ),
      R.set(getValueLens('date'), preparedData.fields.urlDate),
      R.set(getRequiredLens('date'), preparedData.fields.isElectronic === 'true'),
      R.set(getValueLens('foreignName'), preparedData.fields.foreignName),
      R.set(getValueLens('foreignAnnotation'), preparedData.fields.foreignAnnotation),
      R.set(getValueLens('foreignAuthorsKeyWords'), preparedData.fields.foreignAuthorsKeyWords),
      R.set(getValueLens('foreignAuthors'), preparedData.fields.foreignAuthors),
    )(prevFields),
  );
};

export { setByResponse };
