import { Person, Enterprise, Author, Suggestion, Affiliation } from 'types/models';
import { MemberAffilation } from 'types/models/Author';
import { Change, HirshIndex, CommunityMember } from 'types/models/Person';

import { Settings } from 'types/models/common';
import { convertFile, convertIdItem, convertItem, convertReference, getId, getLabel } from '../commonConverters';
import { convertServerPartner } from '../partners/converters';
import { convertPersonHistory } from '../converters';
import { IsLinkedHistoryResponse } from './types';

export function convertServerBibliographicName(serverBibliographicName: any): Person.BibliographicName {
  return {
    id: serverBibliographicName?._attributes.id || '',
    createdBy: serverBibliographicName.CreatedBy._text,
    createdDate: serverBibliographicName.CreatedDate._text,
    name: serverBibliographicName.BibliographicName._text,
    modifiedBy: serverBibliographicName.ModifiedBy._text,
    modifiedDate: serverBibliographicName.ModifiedDate._text,
    isDefault: serverBibliographicName.IsDefault._text === 'true',
    language: {
      id: serverBibliographicName.Language?._attributes.id || '',
      label: serverBibliographicName.Language?._attributes.label || '',
      isoCode: serverBibliographicName.Language?.isoCode._text || '',
    },
  };
}

export function convertServerScientist(serverScientist: any): Person.Scientist {
  return {
    fullName: serverScientist._attributes.fullName,
    averageFiveYearImpactFactor: getText(serverScientist.AverageFiveYearImpactFactor),
    averageImpactFactor: getText(serverScientist.AverageImpactFactor),
    calculateFiveYearImpactFactor: getText(serverScientist.CalculateFiveYearImpactFactor),
    calculateImpactFactor: getText(serverScientist.CalculateImpactFactor),
    citations: getText(serverScientist.Citations),
    citizenship: serverScientist.Citizenship ? convertReference(serverScientist.Citizenship) : null,
    dateBirth: getText(serverScientist.DateBirth),
    englishFirstName: getText(serverScientist.EnglishFirstName),
    englishLastName: getText(serverScientist.EnglishLastName),
    englishPatronymic: getText(serverScientist.EnglishPatronymic),
    firstName: getText(serverScientist.FirstName),
    fromBus: getText(serverScientist.FromBus) === 'true',
    id: serverScientist?._attributes.id || '',
    gender: getText(serverScientist.Gender),
    guid: getText(serverScientist.Guid),
    inn: getText(serverScientist.Inn),
    lastName: getText(serverScientist.LastName),
    patronymic: getText(serverScientist.Patronymic),
    snils: getText(serverScientist.Snils),
    status: getText(serverScientist.Status),
    statusFull: getText(serverScientist.StatusFull),
    mobilePhone: getText(serverScientist.MobilePhone),
    email: getText(serverScientist.EMail),
    phone: getText(serverScientist.Phone),
    mobilePhoneOther: getText(serverScientist.MobilePhoneOther),
    emailOther: getText(serverScientist.EMailOther),
    phoneOther: getText(serverScientist.PhoneOther),
    phoneIntegration: getText(serverScientist.PhoneIntegration),
    emailIntegration: getText(serverScientist.EMailIntegration),
    mobilePhoneIntegration: getText(serverScientist.MobilePhoneIntegration),
    note: getText(serverScientist.Note),
    languageCommunication: getText(serverScientist.LanguageCommunication),
  };
}

export function getText(item: any): string {
  return item?._text ?? '';
}

export function convertMaybeArray<T, U>(maybeArr: T[] | undefined, converter: (item: T) => U): U[] {
  if (!maybeArr) {
    return [];
  }
  if (Array.isArray(maybeArr) && !maybeArr.length) {
    return [];
  }

  return Array.isArray(maybeArr) ? maybeArr.map(converter) : [converter(maybeArr)];
}

export function convertServerEnterpise(serverEnterprise: any): Enterprise {
  return {
    id: serverEnterprise?._attributes.id || '',
    label: serverEnterprise?._attributes.label || '',
    shortName: getText(serverEnterprise.shortName),
    address: getText(serverEnterprise.address),
  };
}

export function convertServerScientistJob(serverScientistJob: any): Person.ScientistJob {
  const originalId = serverScientistJob?._attributes.originalId || '';
  const id = serverScientistJob?._attributes.id || '';

  return {
    id: id,
    originalId,
    appointmentCategory: serverScientistJob.AppointmentCategory ? convertReference(serverScientistJob.AppointmentCategory) : null,
    staffGroup: serverScientistJob.StaffGroup ? convertReference(serverScientistJob.StaffGroup) : null,
    status: {
      label: serverScientistJob.Status?._attributes.label,
      value: getText(serverScientistJob.Status),
    },
    isActive: getText(serverScientistJob.Active) === 'true',
    employeeNumber: getText(serverScientistJob.EmployeeNumber),
    isReadOnly: getText(serverScientistJob.ReadOnly) === 'true',
    appointment: getText(serverScientistJob.Appointment),
    department: getText(serverScientistJob.Department),
    creationDate: getText(serverScientistJob.CreationDate),
    creationSource: getText(serverScientistJob.CreationSource),
    dateDismissal: getText(serverScientistJob.HiDate) || null,
    dateReceipt: getText(serverScientistJob.LoDate),
    enterpise: getText(serverScientistJob.Enterprise),
    modificationDate: getText(serverScientistJob.ModificationDate),
    modificationSource: getText(serverScientistJob.ModificationSource),
    deletionDate: getText(serverScientistJob.DeletionDate),
    deletedBy: getText(serverScientistJob.DeletedBy),
    refAppointment: serverScientistJob.RefAppointment ? convertReference(serverScientistJob.RefAppointment) : null,
    refDepartment: serverScientistJob.RefDepartment ? convertReference(serverScientistJob.RefDepartment) : null,
    civilContractDate: getText(serverScientistJob.CivilContractDate),
    civilContractNumber: getText(serverScientistJob.CivilContractNumber),
    civilContractName: getText(serverScientistJob.CivilContractName),
    staffState: serverScientistJob.StaffState ? convertReference(serverScientistJob.StaffState) : null,
  };
}

export function convertMember(member: any, settings: Settings): Author {
  return {
    id: member._attributes ? member._attributes.id : null,
    ...convertPersonHistory(member),
    person: member.Person ? convertServerPerson(member.Person, settings) : null,
    category: member.Category ? convertReference(member.Category) : null,
    isAffilated: member.IsAffilated ? getText(member.IsAffilated) === 'true' : false,
    name: member.Name ? getText(member.Name) : '',
    affilateCount: member.AffilateCount ? Number(getText(member.AffilateCount)) : null,
    position: Number(getText(member.Position)),
    enterprise: member.Enterprise ? convertServerEnterpise(member.Enterprise) : null,
    role: member.Role ? convertReference(member.Role) : null,
    memberAffilations: member.MemberAffiliations?.MemberAffiliation
      ? convertMaybeArray(member.MemberAffiliations?.MemberAffiliation, a => convertServerMemberAffilation(a, settings))
      : [],
    isReporter: member.IsReporter?._text ? member.IsReporter?._text === 'true' : null,
    supervisorId: member.Supervisor ? member.Supervisor._attributes.id : '',
  };
}

export function convertServerMemberAffilation(serverMemberAffilation: any, settings: Settings): MemberAffilation {
  const getAttributesInstance = (instance: any) => {
    if (instance?.EnterpriseCustomer) {
      const id = instance.EnterpriseCustomer._attributes.id;
      const label = getLabel(instance.EnterpriseCustomer);
      const shortLabel = getText(instance.EnterpriseCustomer.ShortName);
      return { id, label, shortLabel };
    }
    if (instance?.Partner) {
      const id = instance.Partner._attributes.id;
      const label = getText(instance.Partner.FullName);
      const shortLabel = getText(instance.Partner.ShortName);
      return { id, label, shortLabel };
    }
    if (instance?.Enterprise) {
      const id = getId(instance.Enterprise);
      const label = getLabel(instance.Enterprise);
      const shortLabel = getText(instance.Enterprise.name);
      return { id, label, shortLabel };
    }
    return { id: '', label: '', shortLabel: '' };
  };

  const getType = (instance: any): MemberAffilation['type'] => {
    if (instance?.EnterpriseCustomer) {
      return 'ENTERPRISE_CUSTOMER';
    }
    if (instance?.Partner && !instance?.PartnerEnterprise) {
      return 'PARTNER';
    }
    if (instance?.Enterprise) {
      return 'ENTERPRISE';
    }
    return 'PARTNER';
  };
  const attributes = getAttributesInstance(serverMemberAffilation.Affiliation);

  return {
    memberAffilationId: serverMemberAffilation?._attributes?.id ?? null,
    affilationId: serverMemberAffilation.Affiliation?._attributes?.id ?? null,
    id: attributes.id,
    label: attributes.label ?? '',
    shortLabel: attributes.shortLabel,
    type: getType(serverMemberAffilation.Affiliation) as any,
    partner: serverMemberAffilation?.Affiliation?.Partner
      ? convertServerPartner(serverMemberAffilation?.Affiliation?.Partner, settings)
      : null,
  };
}

export function convertServerScientistEducation(serverScientistEducation: any): Person.ScientistEducation {
  const originalId = serverScientistEducation?._attributes.originalId;
  const id = serverScientistEducation?._attributes.id;

  return {
    id: id,
    originalId,
    status: {
      label: serverScientistEducation.Status?._attributes.label,
      value: getText(serverScientistEducation.Status),
    },
    dateDismissal: getText(serverScientistEducation.HiDate) || null,
    dateReceipt: getText(serverScientistEducation.LoDate),
    creationDate: getText(serverScientistEducation.CreationDate),
    creationSource: getText(serverScientistEducation.CreationSource),
    modificationDate: getText(serverScientistEducation.ModificationDate),
    modificationSource: getText(serverScientistEducation.ModificationSource),
    enterprise: getText(serverScientistEducation.Enterprise),
    refSpeciality: serverScientistEducation.RefSpeciality ? convertReference(serverScientistEducation.RefSpeciality) : null,
    refDepartment: serverScientistEducation.RefDepartment ? convertReference(serverScientistEducation.RefDepartment) : null,
    department: getText(serverScientistEducation.Department),
    speciality: getText(serverScientistEducation.Speciality),
    isTSU: getText(serverScientistEducation.IsTSU) === 'true',
    isReadOnly: getText(serverScientistEducation.ReadOnly) === 'true',
    isActive: getText(serverScientistEducation.Active) === 'true',
    deletionDate: getText(serverScientistEducation.DeletionDate),
    deletedBy: getText(serverScientistEducation.DeletedBy),
  };
}

export function convertServerScientistDegree(serverScientistDegree: any): Person.ScientistDegree {
  const originalId = serverScientistDegree?._attributes.originalId;
  const id = serverScientistDegree?._attributes.id;
  return {
    id: id,
    originalId,
    refDegree: convertReference(serverScientistDegree.Degree),
    ...convertBaseHonorFields(serverScientistDegree),
  };
}

export function convertBaseHonorFields(item: any): Person.BaseHonor {
  return {
    dateAssign: getText(item.DateAssign),
    createdBy: getText(item.CreatedBy),
    creationDate: getText(item.CreationDate),
    creationSource: getText(item.CreationSource),
    isActive: getText(item.Active) === 'true',
    isReadOnly: getText(item.ReadOnly) === 'true',
    modificationDate: getText(item.ModificationDate),
    modificationSource: getText(item.ModificationSource),
    modifiedBy: getText(item.ModifiedBy),
    deletionDate: getText(item.DeletionDate),
    deletedBy: getText(item.DeletedBy),
  };
}

export function convertServerScientistRank(rank: any): Person.ScientistRank {
  const originalId = rank?._attributes.originalId;
  const id = rank?._attributes.id;
  return {
    id: id,
    originalId,
    refRank: convertReference(rank.Rank),
    ...convertBaseHonorFields(rank),
  };
}

export function convertServerScientistAcademicRank(rank: any): Person.ScientistAcademicRank {
  const originalId = rank?._attributes.originalId;
  const id = rank?._attributes.id;
  return {
    id: id,
    originalId,
    refAcademicRank: convertReference(rank.AcademicRank),
    academy: convertReference(rank.Academy),
    ...convertBaseHonorFields(rank),
  };
}

export function convertServerIdentifier(serverIdentifier: any): Person.Identifier {
  const id = serverIdentifier?._attributes.id || null;
  return {
    id: id,
    authorName: serverIdentifier.AuthorName ? getText(serverIdentifier.AuthorName) : null,
    citationSystem: convertReference(serverIdentifier.CitationSystem),
    createdBy: getText(serverIdentifier.CreatedBy),
    createdDate: getText(serverIdentifier.CreatedDate),
    identifier: getText(serverIdentifier.Identifier),
    link: getText(serverIdentifier.Link),
    modifiedBy: getText(serverIdentifier.ModifiedBy),
    modifiedDate: getText(serverIdentifier.ModifiedDate),
  };
}

export function convertServerHirshIndex(hirshIndex: any): HirshIndex {
  return {
    id: hirshIndex?._attributes.id ? hirshIndex._attributes.id : null,
    citationSystem: convertReference(hirshIndex.CitationSystem),
    date: getText(hirshIndex.Date),
    value: hirshIndex.Value ? getText(hirshIndex.Value) : null,
    note: hirshIndex.Note ? getText(hirshIndex.Note) : null,
  };
}

export function convertServerCommunityMember(communityMember: any, settings?: Settings): CommunityMember {
  return {
    id: communityMember?._attributes.id,
    startDate: getText(communityMember.StartDate),
    endDate: getText(communityMember.EndDate),
    joinDate: getText(communityMember.JoinDate),
    leaveDate: getText(communityMember.LeaveDate),
    cooperationType: convertItem(communityMember.CooperationType),
    organization:
      getText(communityMember.Community.IsLocal) === 'true'
        ? settings?.organization?.fullName
        : getText(communityMember.Community.Affiliation?.Partner?.shortName),
    vakSpecialty: {
      id: communityMember.VakSpeciality?._attributes.id,
      label: communityMember.VakSpeciality?._attributes.label || '',
    },
    community: {
      id: communityMember.Community?._attributes.id,
      label: communityMember.Community?.Type?._attributes?.label || '',
    },
    role: {
      label: communityMember.Role?._attributes.label,
      value: getText(communityMember.Role),
    },
  };
}

export function convertServerSuggestion(suggestion: any): Suggestion {
  return {
    id: suggestion?._attributes.id,
    label: getText(suggestion),
  };
}

export function convertServerChange(change: any): Change {
  return {
    id: change?._attributes ? change._attributes.id : null,
    birthDate: getText(change.BirthDate),
    citizenship: convertReference(change.Citizenship),
    createdBy: getText(change.CreatedBy),
    createdDate: getText(change.CreatedDate),
    firstName: getText(change.FirstName),
    lastName: getText(change.LastName),
    modifiedBy: getText(change.ModifiedBy),
    modifiedDate: getText(change.ModifiedDate),
    patronymic: getText(change.Patronymic),
  };
}

export function convertServerAffiliation(affiliation: any, settings: Settings): Affiliation {
  return {
    id: affiliation?.Affiliation?._attributes ? affiliation?.Affiliation._attributes.id : null,
    createdBy: convertIdItem(affiliation.Affiliation.CreatedBy),
    createdDate: getText(affiliation.Affiliation.CreatedDate),
    modifiedBy: convertIdItem(affiliation.Affiliation.ModifiedBy),
    modifiedDate: getText(affiliation.Affiliation.ModifiedDate),
    partner: affiliation.Affiliation.Partner ? convertServerPartner(affiliation.Affiliation.Partner, settings) : null,
    enterprise: affiliation.Affiliation.Enterprise ? convertReference(affiliation.Affiliation.Enterprise) : null,
    enterpriseCustomer: affiliation.Affiliation.EnterpriseCustomer
      ? convertReference(affiliation.Affiliation.EnterpriseCustomer)
      : null,
    count: getText(affiliation.Count),
    sources: getText(affiliation.Sources),
  };
}

const convertToNumber = (val: string) => parseInt(val, 10) || 0;

function convertPersonCalculations(calculation: any): Person.Calculation | null {
  if (calculation) {
    const publication = calculation.Publications;
    const participation = calculation.Participations;
    const project = calculation.Projects;
    const event = calculation.Event;
    const securityDocument = calculation.SecurityDocuments;
    return {
      publications: {
        all: convertToNumber(publication?._attributes?.all),
        allByYear0: convertToNumber(publication?._attributes?.allByYear0),
        allByYear1: convertToNumber(publication?._attributes?.allByYear1),
        allByYear2: convertToNumber(publication?._attributes?.allByYear2),
        allByYear3: convertToNumber(publication?._attributes?.allByYear3),
        allByYear4: convertToNumber(publication?._attributes?.allByYear4),
        allByYear5: convertToNumber(publication?._attributes?.allByYear5),
        allMonographs: convertToNumber(publication?._attributes?.allMonographs),
        monographsByYear0: convertToNumber(publication?._attributes?.monographsByYear0),
        monographsByYear1: convertToNumber(publication?._attributes?.monographsByYear1),
        monographsByYear2: convertToNumber(publication?._attributes?.monographsByYear2),
        monographsByYear3: convertToNumber(publication?._attributes?.monographsByYear3),
        monographsByYear4: convertToNumber(publication?._attributes?.monographsByYear4),
        monographsByYear5: convertToNumber(publication?._attributes?.monographsByYear5),
        q1q2ScopusWos: convertToNumber(publication?._attributes?.q1q2ScopusWos),
        q1q2ScopusWosByYear0: convertToNumber(publication?._attributes?.q1q2ScopusWosByYear0),
        q1q2ScopusWosByYear1: convertToNumber(publication?._attributes?.q1q2ScopusWosByYear1),
        q1q2ScopusWosByYear2: convertToNumber(publication?._attributes?.q1q2ScopusWosByYear2),
        q1q2ScopusWosByYear3: convertToNumber(publication?._attributes?.q1q2ScopusWosByYear3),
        q1q2ScopusWosByYear4: convertToNumber(publication?._attributes?.q1q2ScopusWosByYear4),
        q1q2ScopusWosByYear5: convertToNumber(publication?._attributes?.q1q2ScopusWosByYear5),
        scopusWos: convertToNumber(publication?._attributes?.scopusWos),
        scopusWosByYear0: convertToNumber(publication?._attributes?.scopusWosByYear0),
        scopusWosByYear1: convertToNumber(publication?._attributes?.scopusWosByYear1),
        scopusWosByYear2: convertToNumber(publication?._attributes?.scopusWosByYear2),
        scopusWosByYear3: convertToNumber(publication?._attributes?.scopusWosByYear3),
        scopusWosByYear4: convertToNumber(publication?._attributes?.scopusWosByYear4),
        scopusWosByYear5: convertToNumber(publication?._attributes?.scopusWosByYear5),
      },
      participations: {
        all: convertToNumber(participation?._attributes?.all),
        byYear0: convertToNumber(participation?._attributes?.byYear0),
        byYear1: convertToNumber(participation?._attributes?.byYear1),
        byYear2: convertToNumber(participation?._attributes?.byYear2),
        byYear3: convertToNumber(participation?._attributes?.byYear3),
        byYear4: convertToNumber(participation?._attributes?.byYear4),
        byYear5: convertToNumber(participation?._attributes?.byYear5),
        reporterByYear0: convertToNumber(participation?._attributes?.reporterByYear0),
        reporterByYear1: convertToNumber(participation?._attributes?.reporterByYear1),
        reporterByYear2: convertToNumber(participation?._attributes?.reporterByYear2),
        reporterByYear3: convertToNumber(participation?._attributes?.reporterByYear3),
        reporterByYear4: convertToNumber(participation?._attributes?.reporterByYear4),
        reporterByYear5: convertToNumber(participation?._attributes?.reporterByYear5),
      },
      projects: {
        all: convertToNumber(project?._attributes?.all),
        leader: convertToNumber(project?._attributes?.leader),
        leaderByYear0: convertToNumber(project?._attributes?.leaderByYear0),
        leaderByYear1: convertToNumber(project?._attributes?.leaderByYear1),
        leaderByYear2: convertToNumber(project?._attributes?.leaderByYear2),
        leaderByYear3: convertToNumber(project?._attributes?.leaderByYear3),
        leaderByYear4: convertToNumber(project?._attributes?.leaderByYear4),
        leaderByYear5: convertToNumber(project?._attributes?.leaderByYear5),
        notLeader: convertToNumber(project?._attributes?.notLeader),
        notLeaderByYear0: convertToNumber(project?._attributes?.notLeaderByYear0),
        notLeaderByYear1: convertToNumber(project?._attributes?.notLeaderByYear1),
        notLeaderByYear2: convertToNumber(project?._attributes?.notLeaderByYear2),
        notLeaderByYear3: convertToNumber(project?._attributes?.notLeaderByYear3),
        notLeaderByYear4: convertToNumber(project?._attributes?.notLeaderByYear4),
        notLeaderByYear5: convertToNumber(project?._attributes?.notLeaderByYear5),
      },
      event: {
        all: convertToNumber(event?._attributes?.all),
        byYear0: convertToNumber(event?._attributes?.byYear0),
        byYear1: convertToNumber(event?._attributes?.byYear1),
        byYear2: convertToNumber(event?._attributes?.byYear2),
        byYear3: convertToNumber(event?._attributes?.byYear3),
        byYear4: convertToNumber(event?._attributes?.byYear4),
        byYear5: convertToNumber(event?._attributes?.byYear5),
      },
      securityDocuments: {
        all: convertToNumber(securityDocument?._attributes?.all),
        allEvidenceRequests: convertToNumber(securityDocument?._attributes?.allEvidenceRequests),
        evidenceRequestsByYear0: convertToNumber(securityDocument?._attributes?.evidenceRequestsByYear0),
        evidenceRequestsByYear1: convertToNumber(securityDocument?._attributes?.evidenceRequestsByYear1),
        evidenceRequestsByYear2: convertToNumber(securityDocument?._attributes?.evidenceRequestsByYear2),
        evidenceRequestsByYear3: convertToNumber(securityDocument?._attributes?.evidenceRequestsByYear3),
        evidenceRequestsByYear4: convertToNumber(securityDocument?._attributes?.evidenceRequestsByYear4),
        evidenceRequestsByYear5: convertToNumber(securityDocument?._attributes?.evidenceRequestsByYear5),
        allEvidences: convertToNumber(securityDocument?._attributes?.allEvidences),
        evidencesByYear0: convertToNumber(securityDocument?._attributes?.evidencesByYear0),
        evidencesByYear1: convertToNumber(securityDocument?._attributes?.evidencesByYear1),
        evidencesByYear2: convertToNumber(securityDocument?._attributes?.evidencesByYear2),
        evidencesByYear3: convertToNumber(securityDocument?._attributes?.evidencesByYear3),
        evidencesByYear4: convertToNumber(securityDocument?._attributes?.evidencesByYear4),
        evidencesByYear5: convertToNumber(securityDocument?._attributes?.evidencesByYear5),
        allKnowHows: convertToNumber(securityDocument?._attributes?.allKnowHows),
        knowHowsByYear0: convertToNumber(securityDocument?._attributes?.knowHowsByYear0),
        knowHowsByYear1: convertToNumber(securityDocument?._attributes?.knowHowsByYear1),
        knowHowsByYear2: convertToNumber(securityDocument?._attributes?.knowHowsByYear2),
        knowHowsByYear3: convertToNumber(securityDocument?._attributes?.knowHowsByYear3),
        knowHowsByYear4: convertToNumber(securityDocument?._attributes?.knowHowsByYear4),
        knowHowsByYear5: convertToNumber(securityDocument?._attributes?.knowHowsByYear5),
        allPatentRequests: convertToNumber(securityDocument?._attributes?.allPatentRequests),
        patentRequestsByYear0: convertToNumber(securityDocument?._attributes?.patentRequestsByYear0),
        patentRequestsByYear1: convertToNumber(securityDocument?._attributes?.patentRequestsByYear1),
        patentRequestsByYear2: convertToNumber(securityDocument?._attributes?.patentRequestsByYear2),
        patentRequestsByYear3: convertToNumber(securityDocument?._attributes?.patentRequestsByYear3),
        patentRequestsByYear4: convertToNumber(securityDocument?._attributes?.patentRequestsByYear4),
        patentRequestsByYear5: convertToNumber(securityDocument?._attributes?.patentRequestsByYear5),
        allPatents: convertToNumber(securityDocument?._attributes?.allPatents),
        patentsByYear0: convertToNumber(securityDocument?._attributes?.patentsByYear0),
        patentsByYear1: convertToNumber(securityDocument?._attributes?.patentsByYear1),
        patentsByYear2: convertToNumber(securityDocument?._attributes?.patentsByYear2),
        patentsByYear3: convertToNumber(securityDocument?._attributes?.patentsByYear3),
        patentsByYear4: convertToNumber(securityDocument?._attributes?.patentsByYear4),
        patentsByYear5: convertToNumber(securityDocument?._attributes?.patentsByYear5),
      },
    };
  }
  return null;
}

export function convertServerPerson(serverPerson: any, settings: Settings): Person.ScientistPerson {
  const serverBibliographicNames = serverPerson.BibliographicNames?.BibliographicName || [];
  const serverScientist = serverPerson.Scientist;
  const serverScientistJobs = serverPerson.ScientistJobs?.ScientistJob || [];
  const serverScientistEducations = serverPerson.ScientistEducations?.ScientistEducation || [];
  const serverScientistDegrees = serverPerson.ScientistDegrees?.ScientistDegree || [];
  const serverScientistRanks = serverPerson.ScientistRanks?.ScientistRank || [];
  const serverScientistAcademicRanks = serverPerson.ScientistAcademicRanks?.ScientistAcademicRank || [];
  const serverIdentifiers = serverPerson.Identifiers?.Identifier || [];
  const serverHirshIndexes = serverPerson.HirshIndexes?.HirshIndex || [];
  const serverGrnti = serverPerson.Grntis?.Grnti || [];
  const serverUdk = serverPerson.Udks?.Udk || [];
  const serverOecds = serverPerson.Oecds?.Oecd || [];
  const serverDomainKnowledges = serverPerson.DomainKnowledges?.DomainKnowledge || [];
  const serverVakSpecialities = serverPerson.VakSpecialities?.VakSpeciality || [];

  const serverCommunityMembers = serverPerson.CommunityMembers?.CommunityMember || [];
  const serverChanges = serverPerson.Changes?.Change || [];
  const serverAffiliations = serverPerson.Affiliations?.Affiliation || [];
  const serverCalculation = serverPerson.Calculation || null;
  return {
    id: serverPerson?._attributes.id || '',
    fullName: serverPerson?._attributes.fullName || '',
    shortName: serverPerson?._attributes.shortName || '',
    scientist: serverScientist ? convertServerScientist(serverScientist) : null,
    bibliographicNames: convertMaybeArray(serverBibliographicNames, convertServerBibliographicName),
    scientistJobs: convertMaybeArray(serverScientistJobs, convertServerScientistJob),
    scientistEducations: convertMaybeArray(serverScientistEducations, convertServerScientistEducation),
    degrees: convertMaybeArray(serverScientistDegrees, convertServerScientistDegree),
    ranks: convertMaybeArray(serverScientistRanks, convertServerScientistRank),
    academicRanks: convertMaybeArray(serverScientistAcademicRanks, convertServerScientistAcademicRank),
    identifiers: convertMaybeArray(serverIdentifiers, convertServerIdentifier),
    hirshIndexes: convertMaybeArray(serverHirshIndexes, convertServerHirshIndex),
    grntis: convertMaybeArray(serverGrnti, convertReference),
    udks: convertMaybeArray(serverUdk, convertReference),
    oecds: convertMaybeArray(serverOecds, convertReference),
    domainKnowledges: convertMaybeArray(serverDomainKnowledges, convertReference),
    vakSpecialities: convertMaybeArray(serverVakSpecialities, convertReference),
    communityMembers: convertMaybeArray(serverCommunityMembers, c => convertServerCommunityMember(c, settings)),
    changes: convertMaybeArray(serverChanges, convertServerChange).sort(
      (a, b) => parseInt(b?.id || '0') - parseInt(a?.id || '0'),
    ),
    affiliations: convertMaybeArray(serverAffiliations, a => convertServerAffiliation(a, settings)),
    keywordsEnglish: serverScientist?.KeyWordEng?._text || '',
    keywordsRus: serverScientist?.KeyWordRu?._text || '',
    activities: serverScientist?.Activities?._text || '',
    scientificResults: serverScientist?.ScientificResults?._text || '',
    calculations: convertPersonCalculations(serverCalculation),
    avatar: convertFile(serverScientist?.Avatar),
  };
}

export const convertIsLinkedHistory = (response: any): IsLinkedHistoryResponse => ({
  isSuccess: getText(response?.Response) === 'true',
});
