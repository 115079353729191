import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Params = {
  withTemplatesPanel?: boolean;
  isFiltersHidden?: boolean;
  projectSelectable?: boolean;
};

type RequestData = {
  projectId?: string;
};

export function GetPurchaseRequestList({
  projectId,
  withTemplatesPanel,
  isFiltersHidden,
  projectSelectable,
}: RequestData & Params): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetPurchaseRequestList',
    requestData: { projectId },
    header: {
      firstLevel: {
        title: projectId ? 'Заявки на закупки для проекта' : 'Список заявок на закупки для научных проектов',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: !!withTemplatesPanel,
      },
    },
    isFiltersHidden,
    useCustomController: makeUseCustomController({ projectId: projectId ?? '-1', projectSelectable: projectSelectable || false }),
  };
}
export function GetPurchaseRequestListForSelect(): Table.Specification {
  return {
    apiID: 'GetPurchaseRequestList',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        hasSelectButton: true,
      },
    },
  };
}
