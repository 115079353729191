import * as XML from 'xml-js';
import * as T from './types';
import * as converters from './converters';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { Settings } from 'types/models/common';
import { makeParametersXMLElement, makeTableRequestXMLConverter } from '../commonConverters';
import { convertDepartmentsToRequest, convertNomineesToRequest, convertPaymentToRequest } from './requestConverters';
import { GetArticlePaymentRequestsInput } from '../types';

export const tablePaymentsConfigurations = {
  GetConferencePaymentRequests: makeBackendTableAPIConfiguration(
    'GetConferencePaymentRequests',
    makeTableRequestXMLConverter('GetConferencePaymentRequests', ({ panelState }: T.GetConferencePaymentRequest) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        {
          name: 'isAllRequests',
          value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserDepartmentRequests',
          value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserRequests',
          value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
        },
      ]),
    ),
  ),
  GetConferenceForIncentivePayment: makeBackendTableAPIConfiguration(
    'GetConferenceForIncentivePayment',
    makeTableRequestXMLConverter('GetConferenceForIncentivePayment', ({ panelState }: T.GetForIncentivePaymentRequest) =>
      makeParametersXMLElement([{ name: 'isCurrent', value: String(panelState.isCurrent) }]),
    ),
  ),
  GetArticleForIncentivePayment: makeBackendTableAPIConfiguration(
    'GetArticleForIncentivePayment',
    makeTableRequestXMLConverter('GetArticleForIncentivePayment', ({ panelState }: T.GetForIncentivePaymentRequest) =>
      makeParametersXMLElement([{ name: 'isCurrent', value: String(panelState.isCurrent) }]),
    ),
  ),
  GetMonographForIncentivePayment: makeBackendTableAPIConfiguration(
    'GetMonographForIncentivePayment',
    makeTableRequestXMLConverter('GetMonographForIncentivePayment', ({ panelState }: T.GetForIncentivePaymentRequest) =>
      makeParametersXMLElement([
        { name: 'isCurrent', value: String(panelState.isCurrent) },
        { name: 'isMonographOnly', value: 'false' },
      ]),
    ),
  ),
  GetTextBookForIncentivePayment: makeBackendTableAPIConfiguration(
    'GetTextBookForIncentivePayment',
    makeTableRequestXMLConverter('GetTextBookForIncentivePayment', ({ panelState }: T.GetForIncentivePaymentRequest) =>
      makeParametersXMLElement([{ name: 'isCurrent', value: String(panelState.isCurrent) }]),
    ),
  ),
  GetParticipationForAwardPaymentRequest: makeBackendTableAPIConfiguration(
    'GetParticipationForAwardPaymentRequest',
    makeTableRequestXMLConverter('GetParticipationForAwardPaymentRequest', ({ panelState }: T.GetForIncentivePaymentRequest) =>
      makeParametersXMLElement([{ name: 'isCurrent', value: String(panelState.isCurrent) }]),
    ),
  ),
  GetAuthorPaymentRequestFeedlineList: makeBackendTableAPIConfiguration(
    'GetAuthorPaymentRequestFeedlineList',
    makeTableRequestXMLConverter('GetAuthorPaymentRequestFeedlineList', ({ requestData }: T.GetPaymentFeedlineListRequest) =>
      makeParametersXMLElement([{ name: 'requestId', value: requestData?.paymentId }]),
    ),
  ),
  GetArticlePaymentRequests: makeBackendTableAPIConfiguration(
    'GetArticlePaymentRequests',
    makeTableRequestXMLConverter('GetArticlePaymentRequests', ({ panelState }: GetArticlePaymentRequestsInput) => {
      return makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        {
          name: 'isAllRequests',
          value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserDepartmentRequests',
          value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserRequests',
          value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
        },
      ]);
    }),
  ),
};

const configurations = {
  GetAuthorPaymentRequest: makeBackendAPIConfiguration({
    id: 'GetAuthorPaymentRequest',
    endpoint: '/msa/service/commands/GetAuthorPaymentRequest',
    convertInputToXMLElement: ({ foundation, type, mode }: T.GetAuthorPaymentRequest) => ({
      Request: {
        _attr: { commandName: 'GetAuthorPaymentRequest' },
        AuthorPaymentRequest: {
          ...(mode === 'add'
            ? {
                Foundation: {
                  _attr: {
                    id: foundation.id,
                    ...(foundation.customFields?.type ? { type: foundation.customFields.type } : {}),
                  },
                },
              }
            : {}),
          ...(mode === 'edit' || mode === 'view' ? { _attr: { id: foundation.id } } : {}),
          Type: type,
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      response.Response.authorPaymentRequest
        ? converters.convertServerAuthorPayment(response.Response.authorPaymentRequest, settings)
        : null,
  }),
  GetPaymentTermPreset: makeBackendAPIConfiguration({
    id: 'GetPaymentTermPreset',
    endpoint: '/msa/service/commands/GetPaymentTermPreset',
    convertInputToXMLElement: ({ id, type }: T.GetPaymentTermPresetRequest) => ({
      Request: {
        _attr: { commandName: 'GetPaymentTermPreset' },
        GetPaymentTermPreset: { _attr: { ...(id ? { id } : {}), ...(type ? { type } : {}) } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      converters.convertTermPreset(response.Response.paymentTermPreset),
  }),
  DistributeMoney: makeBackendAPIConfiguration({
    id: 'DistributeMoney',
    endpoint: '/msa/service/commands/DistributeMoney',
    convertInputToXMLElement: (args: T.DistributeMoneyRequest) => {
      const {
        type,
        distributionMethod,
        foundationId,
        foundationType,
        maxFundMoney,
        maxNomineesMoney,
        nominees,
        departments,
        isManualEdit,
      } = args;

      return {
        Request: {
          _attr: { commandName: 'DistributeMoney' },
          PaymentRequest: {
            Type: type,
            MaxNomineesMoney: maxNomineesMoney,
            MaxFundMoney: maxFundMoney,
            Foundation: { _attr: { id: foundationId, ...(foundationType !== null ? { type: foundationType } : {}) } },
            Nominee: convertNomineesToRequest(nominees, type || ''),
            ...(isManualEdit ? { Department: convertDepartmentsToRequest(departments) } : {}),
            DistributionMethodBetweenFunds: {
              _attr: { manual: String(isManualEdit) },
              _text: distributionMethod,
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) => {
      if (response.Response.authorPaymentRequest) {
        return converters.convertServerAuthorPayment(response.Response.authorPaymentRequest, settings);
      }
      return null;
    },
  }),
  SaveConferencePaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveConferencePaymentRequest',
    endpoint: '/msa/service/commands/SaveConferencePaymentRequest',
    convertInputToXMLElement: (args: T.SavePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'SaveConferencePaymentRequest' },
        PaymentRequest: {
          ...convertPaymentToRequest(args).PaymentRequest,
          Conference: { _attr: { id: args.authorPayment.event?.id ?? '' } },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => converters.convertSavePaymentResponse(response.Response),
  }),
  SaveArticlePaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveArticlePaymentRequest',
    endpoint: '/msa/service/commands/SaveArticlePaymentRequest',
    convertInputToXMLElement: (args: T.SavePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'SaveArticlePaymentRequest' },
        PaymentRequest: {
          ...convertPaymentToRequest(args).PaymentRequest,
          Publication: {
            _attr: {
              id: args.authorPayment.article?.id ?? '',
              type: args.authorPayment.article?.type.value ?? '',
            },
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => converters.convertSavePaymentResponse(response.Response),
  }),
  SaveMonographPaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveMonographPaymentRequest',
    endpoint: '/msa/service/commands/SaveMonographPaymentRequest',
    convertInputToXMLElement: (args: T.SavePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'SaveMonographPaymentRequest' },
        PaymentRequest: {
          ...convertPaymentToRequest(args).PaymentRequest,
          Publication: { _attr: { id: args.authorPayment.publication?.id ?? '' } },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => converters.convertSavePaymentResponse(response.Response),
  }),
  SaveTextBookPaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveTextBookPaymentRequest',
    endpoint: '/msa/service/commands/SaveTextBookPaymentRequest',
    convertInputToXMLElement: (args: T.SavePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'SaveTextBookPaymentRequest' },
        PaymentRequest: {
          ...convertPaymentToRequest(args).PaymentRequest,
          Publication: { _attr: { id: args.authorPayment.publication?.id ?? '' } },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => converters.convertSavePaymentResponse(response.Response),
  }),
  SaveExhibitionAwardsPaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveExhibitionAwardsPaymentRequest',
    endpoint: '/msa/service/commands/SaveExhibitionAwardsPaymentRequest',
    convertInputToXMLElement: (args: T.SavePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'SaveExhibitionAwardsPaymentRequest' },
        PaymentRequest: {
          ...convertPaymentToRequest(args).PaymentRequest,
          Participation: { _attr: { id: args.authorPayment.participation?.id ?? '' } },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => converters.convertSavePaymentResponse(response.Response),
  }),
  GetAuthorPaymentRequestFeedline: makeBackendAPIConfiguration({
    id: 'GetAuthorPaymentRequestFeedline',
    endpoint: '/msa/service/commands/GetAuthorPaymentRequestFeedline',
    convertInputToXMLElement: ({ id }: T.GetPaymentFeedlineRequest) => ({
      Request: {
        _attr: { commandName: 'GetAuthorPaymentRequestFeedline' },
        PaymentRequestFeedline: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      converters.convertServerPaymentFeedline(response.Response.PaymentRequestFeedline),
  }),
  SaveAuthorPaymentRequestFeedline: makeBackendAPIConfiguration({
    id: 'SaveAuthorPaymentRequestFeedline',
    endpoint: '/msa/service/commands/SaveAuthorPaymentRequestFeedline',
    convertInputToXMLElement: ({ authorPaymentId, paymentFeedline, paymentStatus }: T.SavePaymentFeedlineRequest) => ({
      Request: {
        _attr: { commandName: 'SaveAuthorPaymentRequestFeedline' },
        PaymentRequestFeedline: {
          _attr: paymentFeedline.id ? { id: paymentFeedline.id } : {},
          PaymentRequest: { _attr: { id: authorPaymentId } },
          Status: paymentStatus,
          MessageCategory: { _attr: { id: paymentFeedline.messageCategory.id } },
          ...(paymentFeedline.message ? { Message: paymentFeedline.message } : {}),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteAuthorPaymentRequestFeedline: makeBackendAPIConfiguration({
    id: 'DeleteAuthorPaymentRequestFeedline',
    endpoint: '/msa/service/commands/DeleteAuthorPaymentRequestFeedline',
    convertInputToXMLElement: ({ feedlineId }: T.DeletePaymentFeedlineRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteAuthorPaymentRequestFeedline' },
        PaymentRequestFeedline: {
          _attr: { id: feedlineId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ChangeAuthorPaymentRequestStatus: makeBackendAPIConfiguration({
    id: 'ChangeAuthorPaymentRequestStatus',
    endpoint: '/msa/service/commands/ChangeAuthorPaymentRequestStatus',
    convertInputToXMLElement: ({ paymentId, status, comment }: T.ChangePaymentStatusRequest) => ({
      Request: {
        _attr: { commandName: 'ChangeAuthorPaymentRequestStatus' },
        Ids: { Id: paymentId },
        Status: status,
        ...(comment ? { Comment: comment } : {}),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteAuthorPaymentRequest: makeBackendAPIConfiguration({
    id: 'DeleteAuthorPaymentRequest',
    endpoint: '/msa/service/commands/DeleteAuthorPaymentRequest',
    convertInputToXMLElement: ({ paymentId }: T.DeletePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteAuthorPaymentRequest' },
        Ids: { Id: paymentId },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ChangeAuthorPaymentRequestTerm: makeBackendAPIConfiguration({
    id: 'ChangeAuthorPaymentRequestTerm',
    endpoint: '/msa/service/commands/ChangeAuthorPaymentRequestTerm',
    convertInputToXMLElement: (args: T.ChangeTermPresetPaymentRequest) => ({
      Request: {
        _attr: { commandName: 'ChangeAuthorPaymentRequestTerm' },
        Ids: { Id: args.paymentId },
        RegulationPayment: {
          Paragraph: args.regulationParagraph,
          Name: args.regulationName,
        },
        MaxNomineesMoney: args.maxNomineesMoney,
        MaxFundMoney: args.maxFundMoney,
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const paymentsConfigurations = { ...tablePaymentsConfigurations, ...configurations };
