import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import publicationTypes from 'types/models/Publication/publicationTypes';

const MagazineViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const selectedType =
    relatedTableState && relatedTableState?.selectedRows.length ? relatedTableState.selectedRows[0]['id:PublicationType'] : '';
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    apiID: 'GetPublication',
    template: publicationTypes.filter(type => type.code === selectedType)[0]?.template,
    type: selectedType,
    viewMode: true,
    id: String(id),
    relatedTableState,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineViewTemplate);
