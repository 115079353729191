import React, { useCallback } from 'react';
import { FormComponent, Reference, ReferenceItem } from 'components';
import { JointEvent } from 'types/models';
import { SelectPerson } from 'features/SelectPerson';

type Props = {
  committeMember: JointEvent.CommitteMember | null;
  onChange(e: JointEvent.CommitteMember): void;
};

export function CommitteMemberFields({ committeMember, onChange }: Props) {
  const handleCommitteMemberChange = useCallback(
    (e: JointEvent.CommitteMember) => {
      onChange(e);
    },
    [onChange],
  );
  const handleRoleChange = useCallback(
    (role: ReferenceItem) => {
      if (committeMember) onChange({ ...committeMember, role });
    },
    [committeMember, onChange],
  );
  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired>
          <SelectPerson member={committeMember} onUpdateMember={handleCommitteMemberChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      {committeMember && (
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field isRequired label="Роль">
            <Reference
              value={committeMember?.role || null}
              leftAlignedTitle
              relationTableModalTitle="Роли участников оргкомитета"
              theme="small"
              refButtonTitle="Роли участников оргкомитета"
              name="RefOrgCommitte"
              onChange={handleRoleChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
}
