import React, { useState, useEffect, useMemo } from 'react';

import { Form, SecurityDocument, SecurityDocumentTypes } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import * as R from 'ramda';
import { formatStr } from 'utils/Constants/FormatStr';
import { format, parse } from 'date-fns';

type Props = {
  patent?: SecurityDocument | null;
  setPatent?: React.Dispatch<React.SetStateAction<SecurityDocument | null | undefined>>;
};

export function usePatentFormController({ patent, setPatent }: Props) {
  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject', {
    onSuccessfullCall: ({ data }) => {
      if (setPatent) {
        setPatent(prevPatent =>
          (R.pipe as any)(
            R.set(
              R.lensProp('oecds'),
              data.oecds.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('ntiEteTechnologies'),
              data.ntiEteTechnologies.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('grntis'),
              data.grntis.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('udks'),
              data.udks.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('lksetss'),
              data.lksetss.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('scienceBrunches'),
              data.scienceBrunches.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('scienceDomainInterrests'),
              data.scienceDomainInterrests.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('ntiMarkets'),
              data.ntiMarkets.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('pnrs'),
              data.pnrs.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('pnmitrs'),
              data.pnmitrs.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('pnis'),
              data.pnis.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('ntrStrategies'),
              data.ntrStrategies.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('technologyPlatforms'),
              data.techPlatforms.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('priorityTechnologies'),
              data.criticalTechnologies.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
            R.set(
              R.lensProp('domainKnowledges'),
              data.domainKnowledges.map(c => ({ ...c, isActual: true, isProject: true, id: undefined })),
            ),
          )(prevPatent),
        );
      }
    },
  });

  const parseDate = (value: string) => parse(value, formatStr, new Date());

  const calculateFields = (prevState: Form.Fields) => {
    let result = { ...prevState };

    const calculateAddedDate = (value: string | null, addYear: number): string | null => {
      if (value === null) {
        return value;
      }
      const startDate = parseDate(value);
      const endDate = R.clone(startDate);
      endDate.setFullYear(endDate.getFullYear() + addYear);
      return format(endDate ?? new Date(), formatStr);
    };

    if (isKnowhowDocument) {
      result = {
        ...result,
        loDate: {
          ...prevState.loDate,
          value: prevState.loDate.value,
          disabled: false,
        },
      };

      if (prevState?.documentDate?.value) {
        result = {
          ...result,
          loDate: {
            ...prevState.loDate,
            value: prevState.documentDate.value,
            disabled: Boolean(prevState.documentDate.value),
          },
        };
      }
      result = {
        ...result,
        hiDate: {
          ...prevState.loDate,
          value: calculateAddedDate(
            prevState?.documentDate?.value || prevState.loDate.value || null,
            prevState?.type?.value?.value === SecurityDocumentTypes.PATENT ? 10 : 5,
          ),
        },
      };

      return result;
    }

    result = {
      ...result,
      documentDate: {
        ...prevState.documentDate,
      },
      documentStartDate: {
        ...prevState?.documentStartDate,
        value: prevState.documentStartDate.value,
        disabled: false,
      },
    };

    if (prevState?.requestDate?.value) {
      result = {
        ...result,
        documentDate: {
          ...prevState.documentDate,
          value: prevState.requestDate.value,
          isValid: true,
        },
      };

      if (prevState?.documentNumber?.value) {
        result = {
          ...result,
          documentStartDate: {
            ...prevState.documentStartDate,
            value: prevState.requestDate.value,
            disabled: false,
          },
        };
      }
    }
    result = {
      ...result,
      documentEndDate: {
        ...prevState.documentEndDate,
        value: calculateAddedDate(
          prevState?.documentNumber?.value ? prevState?.requestDate?.value || null : prevState?.documentStartDate?.value || null,
          prevState?.type?.value?.value === SecurityDocumentTypes.PATENT ? 10 : 5,
        ),
      },
    };
    if (prevState.type.value.value == SecurityDocumentTypes.EVIDENCE) {
      result = {
        ...result,
        documentStartDate: { ...prevState.documentStartDate, value: prevState?.requestDate?.value },
        documentEndDate: {
          ...prevState.documentEndDate,
          value: calculateAddedDate(
            prevState?.requestDate?.value,
            prevState?.type?.value?.value === SecurityDocumentTypes.PATENT ? 10 : 5,
          ),
        },
      };
    }
    return result;
  };
  const isPatentDocument = useMemo(() => patent?.type?.value === SecurityDocumentTypes.PATENT, [patent]);
  const isKnowhowDocument = useMemo(() => patent?.type?.value === SecurityDocumentTypes.KNOW_HOW, [patent]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    requestNumber: {
      value: '',
      isValid: true,
      required: true,
      title: 'Номер заявки',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestNumber: { ...prevState.requestNumber, value },
        }));
      },
    },
    requestDate: {
      value: '',
      isValid: true,
      required: true,
      title: 'Дата приоритета',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestDate: { ...prevState.requestDate, value },
          documentDate: { ...prevState.documentDate, value },
        }));
        setFormFields(calculateFields);
      },
    },
    requestSendDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата отправки заявки',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestSendDate: { ...prevState.requestSendDate, value },
        }));
      },
    },
    requestStatus: {
      value: '',
      isValid: true,
      required: true,
      title: 'Статус',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStatus: { ...prevState.requestStatus, value },
        }));
      },
    },
    intellectualPropertyKind: {
      value: '',
      isValid: true,
      required: true,
      title: 'Вид ОИС',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          intellectualPropertyKind: { ...prevState.intellectualPropertyKind, value },
        }));
      },
    },
    intellectualPropertyKindNonRegistered: {
      value: '',
      isValid: true,
      required: false,
      title: 'Вид ОИС',
      onChange: value => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          intellectualPropertyKindNonRegistered: { ...prevState.intellectualPropertyKindNonRegistered, value },
        }));
      },
    },
    intellectualPropertyName: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название ОИС',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          intellectualPropertyName: { ...prevState.intellectualPropertyName, value },
        }));
      },
    },
    referat: {
      value: '',
      isValid: true,
      required: false,
      title: 'Реферат',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          referat: { ...prevState.referat, value },
        }));
      },
    },
    documentNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          documentNumber: { ...prevState.documentNumber, value },
        }));
        setFormFields(calculateFields);
      },
    },
    internalNumber: {
      value: '',
      isValid: true,
      required: false, // req. for non_registered in validations
      title: 'Внутренний номер',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            internalNumber: { ...prevState.internalNumber, value },
          };
        });
      },
    },
    documentDate: {
      value: '',
      isValid: true,
      disabled: false,
      title: 'Дата публикации документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            documentDate: {
              ...prevState.documentDate,
              value,
              isValid: prevState?.requestDate?.value && value ? parseDate(prevState.requestDate.value) <= parseDate(value) : true,
            },
            ...(prevState.type.value.value !== SecurityDocumentTypes.NON_REGISTERED
              ? {
                  loDate: {
                    ...prevState.loDate,
                    value,
                  },
                }
              : {}),
          };
        });
        if (isKnowhowDocument) {
          setFormFields(calculateFields);
        }
      },
    },
    documentReceiveDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата получения охранного документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          documentReceiveDate: { ...prevState.documentReceiveDate, value },
        }));
      },
    },
    documentStartDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            documentStartDate: { ...prevState.documentStartDate, value: value },
          };
        });
        setFormFields((prevState: Form.Fields) => {
          if (prevState.type.value.value !== SecurityDocumentTypes.NON_REGISTERED) {
            setFormFields(calculateFields);
          }
          return {
            ...prevState,
          };
        });
      },
    },
    documentEndDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Окончание срока действия',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          documentEndDate: { ...prevState.documentEndDate, value },
        }));
      },
    },
    stateNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер госрегистрации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          stateNumber: { ...prevState.stateNumber, value },
        }));
      },
    },
    stateRegistrationDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата госрегистрации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          stateRegistrationDate: { ...prevState.stateRegistrationDate, value },
        }));
      },
    },
    isRus: {
      value: false,
      isValid: true,
      title: 'Действует на территории РФ',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isRus: {
            ...prevState.isRus,
            value: !prevState.isRus.value,
          },
          isWorldwide: { ...prevState.isWorldwide, value: false },
        }));
      },
    },
    isWorldwide: {
      value: false,
      isValid: true,
      title: 'Действует по всему миру',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isWorldwide: {
            ...prevState.isWorldwide,
            value: !prevState.isWorldwide.value,
          },
          isRus: { ...prevState.isRus, value: false },
        }));
      },
    },
    project: {
      value: '',
      isValid: true,
      required: false,
      title: 'Получен в рамках научного проекта',
      onChange: value => {
        getProject.callAPI({ id: value.id });
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          project: { ...prevState.project, value },
        }));
      },
    },
    balanceOrderNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          balanceOrderNumber: { ...prevState.balanceOrderNumber, value },
        }));
      },
    },
    balancePrice: {
      value: '',
      isValid: true,
      required: false,
      title: 'Стоимость',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          balancePrice: { ...prevState.balancePrice, value },
        }));
      },
    },
    loDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата постановки на баланс',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            loDate: { ...prevState.loDate, value: value },
          };
        });
        setFormFields((prevState: Form.Fields) => {
          if (prevState.type.value.value !== SecurityDocumentTypes.NON_REGISTERED) {
            setFormFields(calculateFields);
          }
          return {
            ...prevState,
          };
        });
      },
    },
    hiDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата снятия с баланса',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          hiDate: { ...prevState.hiDate, value },
        }));
      },
    },
    type: {
      value: '',
      isValid: true,
      required: false,
      title: 'Тип документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          type: { ...prevState.type, value },
        }));
      },
    },
  });

  useEffect(() => {
    if (!patent?.id) {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        isRus: {
          ...prevState.isRus,
          value: isPatentDocument,
        },
        isWorldwide: { ...prevState.isWorldwide, value: !isPatentDocument },
      }));
    }
    // eslint-disable-next-line
  }, []);
  return {
    patentFormFields: formFields,
    setPatentFormFields: setFormFields,
    patent,
  };
}
