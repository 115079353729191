import { useMemo, useState, useCallback } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { Table } from 'types/models';
import { useReportsHook, Reports } from 'features/BuildReportPopup';
import { Permits } from 'utils/Permissions';
import { State, ToolbarButton } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  customState: State;
  onSubmitTable?(state: SubmitTable): void;
};
export function useController({
  tableState,
  customState: {
    isOpenRelationsForm,
    partner,
    showButtons,
    loadPartner,
    openForm,
    setMode,
    deletePartner,
    openRelationsModal,
    closeRelationsModal,
    setPartnerHead,
  },
}: Props) {
  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });
  const [isOpenConfirmDeletePartner, setIsOpenConfirmDeletePartner] = useState(false);
  const [isOpenApproveInfoModal, setIsOpenApproveInfoModal] = useState(false);
  const [row] = tableState.selectedRows;
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const getIsButtonShowed = useCallback(
    (buttonRole: ToolbarButton, isConfirmed?: boolean) => (showButtons ? showButtons.includes(buttonRole) : !isConfirmed),
    [showButtons],
  );

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isHidden: !getIsButtonShowed('view'),
        permissionName: Permits.PARTNER_VIEW,
        isDisabled: !row,
        onClick: () => {
          if (row) {
            setMode('view');
            loadPartner(row.id, openForm, row.Type);
          }
        },
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        permissionName: Permits.PARTNER_ADD,
        isHidden: !getIsButtonShowed('add'),
        isDisabled: false,
        onClick: () => {
          setMode('add');
          loadPartner(null, openForm);
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: !row,
        permissionName: Permits.PARTNER_EDIT,
        isHidden: !getIsButtonShowed('edit'),
        onClick: () => {
          if (row) {
            setMode('edit');
            loadPartner(row.id, openForm, row.Type);
          }
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isHidden: !getIsButtonShowed('remove'),
        permissionName: Permits.PARTNER_DELETE,
        isDisabled: !row,
        onClick: () => setIsOpenConfirmDeletePartner(true),
      },
      {
        icons: buttonIcons.articleRelations,
        title: 'Связи',
        code: 'relations',
        isHidden: !getIsButtonShowed('relations'),
        isDisabled: !row,
        onClick: () => {
          if (row) {
            loadPartner(row.id, () => openRelationsModal());
          }
        },
      },
      {
        icons: buttonIcons.approve,
        title: 'Подтвердить статус',
        isHidden: !getIsButtonShowed('approve'),
        code: 'approve',
        isDisabled: !row,
        onClick: () => setIsOpenApproveInfoModal(true),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать - файл отчёта: Реестр соглашений и договоров о сотрудничестве в группе отчётов Коммерциализация (УНП)',
        isHidden: !getIsButtonShowed('print'),
        onClick: () =>
          handleSetCurrentReport({
            name: Reports.PartnersContractsAndAgreements.caption,
            value: Reports.PartnersContractsAndAgreements.name,
          }),
      },
    ],
    [handleHelpButtonClick, getIsButtonShowed, row, setMode, loadPartner, openForm, openRelationsModal, handleSetCurrentReport],
  );

  return {
    buttons,
    isOpenConfirmDeletePartner,
    setIsOpenConfirmDeletePartner,
    deletePartner,
    row,
    isOpenApproveInfoModal,
    setMode,
    loadPartner,
    setIsOpenApproveInfoModal,
    isOpenRelationsForm,
    closeRelationsModal,
    partner,
    setPartnerHead,
    openForm,
    isReportOpen,
    onReportClose,
    currentReport,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  };
}
