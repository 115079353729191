import { Subject, Rntd } from 'types/models';
import { ForeignSecurityDocument } from 'types/models/Subject';
import { getMaybeField } from '../commonConverters';
import { convertDocumentsToRequest } from '../converters';

export function convertSubjectToXML(subject: Subject) {
  const { id, name, type, code, description, rntds, documents, foreignSecurityDocuments } = subject;
  return {
    _attr: subject.id ? { id } : {},
    ...getMaybeField('Name', name),
    ...getMaybeField('Type', type?.value),
    ...getMaybeField('Code', code),
    ...getMaybeField('Description', description),
    Rntds: rntds.length ? { Rntd: rntds.map(convertRntdToXML) } : {},
    ...(documents?.length ? convertDocumentsToRequest(documents, 'local') : {}),
    ForeignSecurityDocuments: foreignSecurityDocuments.length
      ? { ForeignSecurityDocument: foreignSecurityDocuments.map(convertForeignSecurityDocument) }
      : {},
  };
}

function convertRntdToXML(rntd: Rntd) {
  return {
    _attr: { id: rntd.id },
  };
}

function convertForeignSecurityDocument(foreignSecurityDocument: ForeignSecurityDocument) {
  return {
    _attr: { id: foreignSecurityDocument.id },
  };
}
