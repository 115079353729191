import * as regexp from 'utils/Regexps';
import { MipPartner } from 'types/models';
import { Mode } from 'components/ListEdit/model';

export function validateMipIndicator(
  indicator: MipPartner.Indicator | null,
  index: number | null,
  list: MipPartner.Indicator[],
  mode: Mode,
) {
  const indicators = mode === 'edit' ? list.filter((_, itemIndex) => index !== itemIndex) : list;

  const isYearValid = new RegExp(regexp.finalYearRegExp).test(indicator?.year ?? '');
  const isValidAverageHeadcount = Boolean(indicator?.averageHeadcount);
  const isHasSameYear = indicators.some(x => x.year === indicator?.year);
  const isValidSameYear = !isHasSameYear;

  return [
    { isValid: isYearValid, invalidMessage: indicator?.year ? 'Некорректно заполнено поле "Год"' : 'Заполните поле "Год"' },
    { isValid: isValidAverageHeadcount, invalidMessage: 'Заполните поле "Среднесписочная численность, чел."' },
    { isValid: isValidSameYear, invalidMessage: 'Показатель с указанным годом уже добавлен' },
  ];
}
