import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Column } from 'components';

import { Event } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { ParticipationTypeCode } from 'types/models/Participation';
import { Permits } from 'utils/Permissions';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';

type Props = {
  sourceEvent: Event.Participations[];
  acceptSimilarParticipation: () => void;
  eventName: string;
  type: ParticipationTypeCode;
};

const useController = ({ sourceEvent, acceptSimilarParticipation, eventName, type }: Props) => {
  const { userSystemDepartment } = useAppDataContext();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);
  const { methods: BindParticipationToCurrentUserDepartment } = BackendAPI.useBackendAPI(
    'BindParticipationToCurrentUserDepartment',
  );

  const title = useMemo(() => {
    switch (type) {
      case ParticipationTypes.PARTICIPATION_CONFERENCE.code:
        return 'Конференция:';
      case ParticipationTypes.PARTICIPATION_EXPOSITION.code:
        return 'Выставка:';
      case ParticipationTypes.PARTICIPATION_CONTEST.code:
        return 'Конкурс:';
      case ParticipationTypes.PARTICIPATION_CONCERT.code:
        return 'Творческое мероприятие:';
      default:
        return '';
    }
  }, [type]);

  const columns = useMemo<Column<Event.Participations>[]>(
    () => [
      { label: 'Статус', formatValue: row => row.status.name || '', styles: { width: '35px' } },
      { label: 'Руководитель', formatValue: row => row.supervisor || '', styles: { width: '35px' } },
      { label: 'Участники', formatValue: row => row.participant || '', styles: { width: '35px' } },
      {
        label: 'Награды',
        formatValue: row => row.awards || '',
        styles: { width: '35px' },
      },
      { label: 'Доклад', formatValue: row => row.report, styles: { width: '35px' } },
      {
        label: 'Подразделения',
        formatValue: row => (row.departments ? row.departments : ''),
        styles: { width: '35px' },
      },
      { label: 'Факультеты/Институты', formatValue: row => row.faculties || '', styles: { width: '35px' } },
      { label: 'Примечания', formatValue: row => row.note || '', styles: { width: '35px' } },
      { label: 'Добавлено', formatValue: row => row.createdBy || '', styles: { width: '35px' } },
      { label: 'Дата добавления', formatValue: row => row.createdDate || '', styles: { width: '35px' } },
      { label: 'Изменено', formatValue: row => row.modifiedBy || '', styles: { width: '35px' } },
      { label: 'Дата изменения', formatValue: row => row.modifiedDate || '', styles: { width: '35px' } },
    ],
    [],
  );

  const attachParticipation = useCallback(() => {
    if (selectedRowIndex !== null) {
      const participation = sourceEvent[selectedRowIndex];
      BindParticipationToCurrentUserDepartment.callAPI(
        { participationId: participation.id },
        {
          onSuccessfullCall: data => {
            if (data.data.success) {
              showNotification({ message: 'Участие успешно привязано', theme: 'success' });
              acceptSimilarParticipation();
            }
          },
        },
      );
    }
  }, [selectedRowIndex, sourceEvent, BindParticipationToCurrentUserDepartment, acceptSimilarParticipation]);

  const openWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(true);
  }, []);

  const closeWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(false);
  }, []);

  const attachParticipationPermission = useMemo<string>(() => Permits[`${type}_BIND_TO_DEPARTMENT`], [type]);

  const participationInfoText = useMemo(() => {
    if (selectedRowIndex !== null && sourceEvent[selectedRowIndex]) {
      const selectedRow = sourceEvent[selectedRowIndex];
      return `
      Вы действительно хотите привязать данное участие к своему подразделению (${userSystemDepartment?.label})?
      </br>
      </br>
      <b>Информация об участии</b>
      </br>
      </br>
      <b>${title}</b>
      </br>
      '${eventName}'
      </br>
      ${
        selectedRow.report
          ? `
            </br>
            <b>Тема доклада:</b>
            </br>
            '${selectedRow.report}'
            </br>
          `
          : ''
      }
      ${
        selectedRow.participant
          ? `
          </br>
          <b>Участники:</b>
          </br>
           ${selectedRow.participant}
          `
          : ''
      }
      `;
    }
    return '';
  }, [eventName, sourceEvent, selectedRowIndex, title, userSystemDepartment?.label]);

  return {
    columns,
    selectedRowIndex,
    setSelectedRowIndex,
    attachParticipation,
    isWarningPopupOpen,
    closeWarningPopup,
    participationInfoText,
    openWarningPopup,
    attachParticipationPermission,
  };
};

export default useController;
