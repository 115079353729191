import { StateRegistrationReport } from 'types/models';

import { getId, getText, convertItem, convertMaybeArray, convertReference, convertFile } from '../commonConverters';
import { convertStage } from '../project/converters';

export function convertStateRegistrationReport(stateRegistrationReport: any): StateRegistrationReport {
  const serverUdk = stateRegistrationReport.Udks?.Udk || [];
  return {
    id: getId(stateRegistrationReport),
    stage: convertStage(stateRegistrationReport.Stage),
    reportType: convertItem(stateRegistrationReport.ReportType),
    citisDate: getText(stateRegistrationReport.CitisDate),
    stageName: getText(stateRegistrationReport.StageName),
    expectedResults: getText(stateRegistrationReport.ExpectedResults),
    interdisciplinaryAproachJustification: getText(stateRegistrationReport.InterdisciplinaryAproachJustification),
    reportName: getText(stateRegistrationReport.ReportName),
    reportIkNumber: getText(stateRegistrationReport.ReportIkNumber),
    reportArchiveNumber: getText(stateRegistrationReport.ReportArchiveNumber),
    reportCustomerSendDate: getText(stateRegistrationReport.ReportCustomerSendDate),
    keyWords: getText(stateRegistrationReport.KeyWords),
    referat: getText(stateRegistrationReport.Referat),
    udks: convertMaybeArray(serverUdk, convertReference),
    file: convertFile(stateRegistrationReport.File),
  };
}
