import { useCallback, useState, useLayoutEffect } from 'react';
import { Form } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { useAppDataContext } from 'features/AppData/context';
import { useStream } from 'StreamRx';
import { showNotification } from 'features/Notifications';
import { Participation, ParticipationProject } from 'types/models/Participation';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { ParticipationProjectType } from 'utils/Enums/ParticipationProjectType';
import { projectsStreams, mobileRequestsStream } from './streams';
import { getEnumItem } from 'utils/Helpers';

type Props = {
  participationId: string;
  onClose: () => void;
  onSuccess: () => void;
};

const useController = ({ participationId, onClose, onSuccess }: Props) => {
  const { currentPerson, enumMap } = useAppDataContext();
  const [participation, setParticipation] = useState<null | Participation>(null);
  const [financingProjects, setFinancingProjects] = useState<ParticipationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<Form.PublicationMobileRequest[]>([]);

  const { methods: getParticipation, state: getParticipationState } = BackendAPI.useBackendAPI('GetParticipation');
  const { methods: saveParticipation, state: saveParticipationState } = BackendAPI.useBackendAPI('SaveParticipation');

  const { isLoading } = useIsLoading({ callStates: [getParticipationState, saveParticipationState] });

  useLayoutEffect(() => {
    getParticipation.callAPI(
      {
        participationId,
        modules: [
          'ID',
          'ACCESS_MODE',
          'MAIN',
          'EVENT',
          'DEPARTMENTS',
          'PUBLICATION',
          'PROJECTS',
          'MEMBERS',
          'AWARDS',
          'MOBILE_REQUESTS',
          'DOCUMENTS',
        ],
        eventType: 'CONFERENCE',
        eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
        IsCollectiveExhibit: false,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setParticipation(data);
          setFinancingProjects(data.financingProjects);
          setMobileRequests(data.mobileRequests);
        },
      },
    );
  }, []); // eslint-disable-line

  const submitForm = useCallback(() => {
    if (participation === null) {
      return;
    }
    saveParticipation.callAPI(
      {
        participationId,
        simpleFields: {
          participationProcedure: 'WITHOUT_REQUEST',
          reportTheme: participation?.reportTheme,
          reportDate: participation?.reportDate,
          development: participation?.development,
          note: participation?.note,
          isInTeam: false,
          collectiveExhibitName: participation?.collectiveExhibitName,
          IsCollectiveExhibit: Boolean(participation?.isCollectiveExhibit),
        },
        attrIdFields: {
          exponent: participation?.exponent?.id ?? undefined,
          event: participation?.event?.id,
          reportType: participation?.reportType?.id,
          publication: participation?.publication?.status?.id,
          form: participation?.form?.id,
        },
        otherFields: {
          participationPresentationForm: (participation?.presentationForms || []).map(key => ({
            value: key || '',
            label: '',
            key,
            ordinal: 1,
            position: 1,
            isChecked: true,
          })),
          documents: participation?.documents,
          departments: participation?.departments,
          awards: participation?.awards,
          members: participation?.members,
          mobileRequests,
          financingProjects,
          presentationProject: participation?.presentationProject,
        },
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Участие успешно отредактировано', theme: 'success' });
          onSuccess();
          onClose();
        },
      },
    );
  }, [financingProjects, mobileRequests, onClose, onSuccess, participation, participationId, saveParticipation]);

  useStream(
    () => mobileRequestsStream.setMobileRequests,
    (requests: Array<Form.PublicationMobileRequest>) => setMobileRequests(requests),
    [mobileRequests, setMobileRequests, mobileRequestsStream.setMobileRequests],
  );

  useStream(
    () => mobileRequestsStream.accept,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy,
        accepted,
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.accept, currentPerson],
  );

  useStream(
    () => mobileRequestsStream.cancelAcceptance,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy: null,
        accepted: '',
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.cancelAcceptance],
  );

  useStream(
    () => projectsStreams.setProjects,
    (projectsData: Array<ParticipationProject>) => {
      const nextProjects = projectsData.map(projectItem => ({
        ...projectItem,
        type: getEnumItem('ParticipationProjectType', ParticipationProjectType.FINANCING, enumMap),
      }));
      setFinancingProjects(nextProjects);
    },
    [],
  );

  useStream(
    () => projectsStreams.accept,
    (projectId: string) => {
      const projectIndex = financingProjects.findIndex(financingProject => financingProject.project?.id === projectId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;

      financingProjects[projectIndex] = {
        ...financingProjects[projectIndex],
        acceptedBy,
        accepted,
      };
      setFinancingProjects([...financingProjects]);
    },
    [financingProjects, currentPerson],
  );

  useStream(
    () => projectsStreams.cancelAcceptance,
    (projectId: string) => {
      const projectIndex = financingProjects.findIndex(financingProject => financingProject.project?.id === projectId);
      financingProjects[projectIndex] = {
        ...financingProjects[projectIndex],
        acceptedBy: null,
        accepted: '',
      };
      setFinancingProjects([...financingProjects]);
    },
    [financingProjects],
  );

  return {
    mobileRequests,
    financingProjects,
    participation,
    isLoading,
    submitForm,
  };
};

export default useController;
