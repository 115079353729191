import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { Table } from 'types/models';
import { State } from '../model';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
  setSelectedItems([]): void;
};

export function useController({ tableState, customState: { selectedEntries }, setSelectedItems }: Props) {
  const isMergeDisabled = useMemo(() => {
    return !tableState.selectedRows.length || tableState.selectedRows.length < 2 || tableState.selectedRows.length > 5;
  }, [tableState.selectedRows]);

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenFieldsMapModal, setIsOpenFieldsMapModal] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpenConfirmDeleteModal(false);
  }, [setIsOpenConfirmDeleteModal]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.merge,
        title: 'Открыть справочник для объединения дубликатов',
        isDisabled: isMergeDisabled,
        onClick: () => {
          setSelectedItems(tableState.selectedRows);
          setIsOpenFieldsMapModal(true);
        },
      },
    ],
    [setSelectedItems, tableState, isMergeDisabled, setIsOpenFieldsMapModal],
  );

  return {
    buttons,
    isOpenConfirmDeleteModal,
    closeModal,
    selectedEntries,
    isOpenFieldsMapModal,
    setIsOpenFieldsMapModal,
  };
}
