import { Author, Form } from 'types/models';
import { Notification } from 'features/Notifications';
import * as validator from 'utils/Validators';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

export function validate({ formFields, isDraft, authors }: { formFields: Form.Fields; isDraft: boolean; authors: Author[] }) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };
  if (isDraft) {
    const requiredDraftKeys = ['name'];
    const draftResult = validator.getError(formFields, requiredDraftKeys);
    result.nextNotification = draftResult.nextNotification;
    result.isFormValid = draftResult.isFormValid;
    result.invalidFieldKeys = draftResult.invalidFieldKeys;
  } else {
    const defaultResult = validator.getError(formFields);

    result.nextNotification = defaultResult.nextNotification;
    result.isFormValid = defaultResult.isFormValid;
    result.invalidFieldKeys = defaultResult.invalidFieldKeys;
    if (result.isFormValid) {
      const authorsResult: Result = validator.validateAuthors(authors);

      result.nextNotification = authorsResult.nextNotification;
      result.isFormValid = authorsResult.isFormValid;
      result.invalidFieldKeys = authorsResult.invalidFieldKeys;
    }
  }

  return result;
}
