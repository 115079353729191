import React from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { CalendarPlanByYear } from 'types/models/Project';
import { useController } from './controller';

type Props = {
  entry: CalendarPlanByYear | null;
  onFieldChange(row: CalendarPlanByYear): void;
};

function FinInputs({ entry, onFieldChange }: Props) {
  const { handleFieldChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма, план года">
          <TextInput
            mode={TextInputMode.number}
            value={entry?.amount ? entry?.amount.toString() : '0'}
            onChange={handleFieldChange('amount')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { FinInputs };
