import React, { memo, useCallback, useMemo } from 'react';

import {
  Reference,
  Checkbox,
  Select,
  ListEdit,
  Column,
  Radio,
  FormComponent,
  SectionTitle,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  TextDateTime,
} from 'components';

import { Form as F, Table as T, Event } from 'types/models';
import { GetReferenceElementList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { EnterprisesComponent } from 'features/Form/looks/event/views/EnterprisesComponent';

type Props = {
  eventId?: string;
  formFields: F.Fields;
  viewMode?: boolean;
  termsList: F.EditionTypes;
  isSelectedPeriod: boolean;
  changeDepartments: (departments: Event.Department[] | null) => void;
  departments: Event.Department[];
  changeEnterprises: (enterprises: Event.Enterprise[] | null) => void;
  enterprises: Event.Enterprise[];
  refs: any; //eslint-disable-line
  isTSUSelected: boolean;
  isOtherSelected: boolean;
  isDisableSource: boolean;
  withHeaderId?: boolean;
  hint?: string;
};

const About = ({
  viewMode,
  formFields,
  termsList,
  isSelectedPeriod,
  changeDepartments,
  departments,
  changeEnterprises,
  enterprises,
  refs,
  isTSUSelected,
  isOtherSelected,
  isDisableSource,
  eventId,
  withHeaderId,
  hint,
}: Props) => {
  const renderEntry = (x: string) => <span title={x}>{x}</span>;

  const columns = useMemo<Column<F.Translation>[]>(() => [{ label: '', formatValue: row => row.name }], []);
  const modalTableRowConverter = useCallback<(row: T.Entry) => F.Translation>(row => ({ id: row.id, name: row.name }), []);

  const translatesListTableConfig = GetReferenceElementList({
    requestData: {
      filters: [],
      name: 'RefDepartment',
    },
  });

  const { settings } = useAppDataContext();
  const conferenceId = eventId ? ` (ID ${eventId})` : '';

  return (
    <>
      <FormComponent.Description mode="warning">{hint}</FormComponent.Description>

      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <input type="submit" hidden />
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <SectionTitle title={'Конференция' + (withHeaderId ? conferenceId : '')} />
            <FormComponent.Line>
              <Reference
                tooltipText={formFields.eventSeries.tooltipText}
                disabled={!!viewMode}
                value={formFields.eventSeries.value}
                title={formFields.eventSeries.title}
                relationTableModalTitle={formFields.eventSeries.title}
                theme="big"
                refButtonTitle='Справочник "Серии мероприятия"'
                name="RefEventSeries"
                onChange={formFields.eventSeries.onChange}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <Reference
                tooltipText={formFields.conferenceType.tooltipText}
                disabled={!!viewMode}
                value={formFields.conferenceType.value}
                title={formFields.conferenceType.title}
                relationTableModalTitle={formFields.conferenceType.title}
                theme="big"
                isRequired
                refButtonTitle='Справочник "Типы мероприятия"'
                name="RefTypeConference"
                onChange={formFields.conferenceType.onChange}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <Reference
                tooltipText={formFields.eventStatus.tooltipText}
                disabled={!!viewMode}
                value={formFields.eventStatus.value}
                title={formFields.eventStatus.title}
                relationTableModalTitle={formFields.eventStatus.title}
                theme="big"
                isRequired
                refButtonTitle='Справочник "Уровни мероприятия"'
                name="RefEventStatus"
                onChange={formFields.eventStatus.onChange}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="">
                <Checkbox
                  label={formFields.isStudent.title}
                  checked={!!formFields.isStudent.value}
                  onChange={formFields.isStudent.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            {isTSUSelected && (
              <>
                <FormComponent.Line>
                  <FormComponent.Field label="">
                    <Checkbox
                      label={formFields.isInSummaryPlan.title}
                      checked={!!formFields.isInSummaryPlan.value}
                      onChange={formFields.isInSummaryPlan.onChange}
                      isDisabled={!!viewMode}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line hasGap hasFreeFormat>
                  <FormComponent.ColumnWrapper>
                    <FormComponent.Column specialStyles={{ width: '50%' }}>
                      <FormComponent.Field label={formFields.orderNumber.title} tooltip={formFields.orderNumber.tooltipText}>
                        <TextInput
                          value={formFields.orderNumber.value}
                          onChange={formFields.orderNumber.onChange}
                          isDisabled={!!viewMode}
                        />
                      </FormComponent.Field>
                    </FormComponent.Column>
                    <FormComponent.Column specialStyles={{ width: '50%' }}>
                      <FormComponent.Field label="от" isRequired={!!formFields.orderDate.required}>
                        <TextDateTime
                          value={formFields.orderDate.value}
                          onChange={formFields.orderDate.onChange}
                          isDisabled={!!viewMode}
                        />
                      </FormComponent.Field>
                    </FormComponent.Column>
                  </FormComponent.ColumnWrapper>
                </FormComponent.Line>
              </>
            )}
            <FormComponent.Line>
              <FormComponent.Field label="">
                <Checkbox
                  label={formFields.isCompilationPlanned.title}
                  checked={!!formFields.isCompilationPlanned.value}
                  onChange={formFields.isCompilationPlanned.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="">
                <Checkbox
                  label={formFields.isMagazinePlanned.title}
                  checked={!!formFields.isMagazinePlanned.value}
                  onChange={formFields.isMagazinePlanned.onChange}
                  isDisabled={viewMode || isDisableSource}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label={formFields.name.title} tooltip={formFields.name.tooltipText} isRequired>
                <TextArea
                  mode={TextAreaMode.modal}
                  settings={{ rows: 3 }}
                  value={formFields.name.value}
                  onChange={formFields.name.onChange}
                  isDisabled={!!viewMode}
                  // eslint-disable-next-line max-len
                  placeholder={`Важен правильный порядок ввода названия конференции: \n • Название конференции \n • Уровень и тип мероприятия \nЕсли существует название конференции на английском и русском языке, то указать нужно оба этих названия через слеш, например: Актуальные проблемы современной археометаллургии. Международный семинар / Actual problems of modern archeometallurgy. International Seminar.`}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field
                label={formFields.termsType.title}
                isRequired={formFields.termsType.required}
                // eslint-disable-next-line max-len
                tooltip="Из выпадающего списка выберите «Дата», если мероприятие в определенный день или выберите «Период», если сроки проведения от двух дней и более."
              >
                <FormComponent.ColumnWrapper>
                  <FormComponent.Column specialStyles={{ width: '20%' }}>
                    <Select.Component
                      disabled={!!viewMode}
                      value={formFields.termsType.value?.label}
                      variant="outlined"
                      title={formFields.termsType.title}
                      defaultPlaceholder="Не задан"
                      entries={termsList.map(({ label }) => label)}
                      onChange={formFields.termsType.onChange}
                      renderEntry={renderEntry}
                    />
                  </FormComponent.Column>
                  <FormComponent.Column specialStyles={{ width: '80%' }}>
                    <TextDateTime
                      isRange={isSelectedPeriod}
                      value={[
                        formFields.startDate.value || '',
                        isSelectedPeriod ? `-${formFields.endDate.value || ''}` : '',
                      ].join('')}
                      onChange={(value: string) => {
                        const dates = value.split('-');
                        formFields.startDate.onChange(dates[0] || '');
                        if (isSelectedPeriod) {
                          formFields.endDate.onChange(dates[1] || '');
                        }
                      }}
                      isDisabled={!!viewMode}
                    />
                  </FormComponent.Column>
                </FormComponent.ColumnWrapper>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label={formFields.description.title}>
                <TextArea
                  mode={TextAreaMode.modal}
                  settings={{ rows: 3, title: formFields.description.title }}
                  value={formFields.description.value}
                  onChange={formFields.description.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label={formFields.keyWords.title}>
                <TextArea
                  mode={TextAreaMode.modal}
                  settings={{ rows: 3, title: formFields.keyWords.title }}
                  value={formFields.keyWords.value}
                  onChange={formFields.keyWords.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column>
            <SectionTitle title="Место проведения" />
            <FormComponent.Line>
              <FormComponent.Field label="Формат проведения">
                <Radio list={refs.formatList} value={refs.formatVal.value} onChange={refs.formatChange} isDisabled={!!viewMode} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field
                label={formFields.site.title}
                isRequired={formFields.site.required}
                tooltip={formFields.site.tooltipText}
              >
                <TextInput mode={TextInputMode.url} value={formFields.site.value} onChange={formFields.site.onChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <Reference
                tooltipText={formFields.country.tooltipText}
                disabled={!!viewMode}
                value={formFields.country.value}
                title={formFields.country.title}
                relationTableModalTitle={formFields.country.title}
                theme="big"
                refButtonTitle='Справочник "Страны"'
                name="RefCountry"
                onChange={formFields.country.onChange}
              />
            </FormComponent.Line>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label={formFields.city.title} tooltip={formFields.city.tooltipText}>
                <TextInput value={formFields.city.value} onChange={formFields.city.onChange} isDisabled={!!viewMode} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label={formFields.address.title}>
                <TextInput value={formFields.address.value} onChange={formFields.address.onChange} isDisabled={!!viewMode} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label={formFields.contact.title} tooltip={formFields.contact.tooltipText}>
                <TextArea
                  mode={TextAreaMode.modal}
                  settings={{ rows: 3, title: formFields.contact.title }}
                  value={formFields.contact.value}
                  onChange={formFields.contact.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <SectionTitle title="Организаторы мероприятия" />
            <FormComponent.Line>
              <Radio
                isDisabled={!!viewMode}
                value={refs.participantVal}
                list={refs.participantList}
                onChange={refs.participantChange}
                isInRow={false}
              />
            </FormComponent.Line>
            {isTSUSelected && (
              <FormComponent.Line>
                <ListEdit
                  isRequired
                  maxHeight="350px"
                  title={`Подразделения ${settings?.organization?.shortName}`}
                  defaultRowsCount={isOtherSelected ? 3 : 8}
                  isToolbarDisabled={!!viewMode}
                  rows={departments}
                  withoutHead
                  isCanMovingRows
                  columns={columns}
                  onChange={changeDepartments}
                  specification={{
                    mode: 'relationTableModal',
                    modalTableRowConverter,
                    relationTableModalTitle: `Справочник "Подразделения ${settings?.organization?.shortName}"`,
                    modalTableSpecification: translatesListTableConfig,
                  }}
                />
              </FormComponent.Line>
            )}
            {isOtherSelected && (
              <FormComponent.Line>
                <EnterprisesComponent
                  enterprises={enterprises}
                  isMiniRows={isTSUSelected}
                  setEnterprises={changeEnterprises}
                  disabled={!!viewMode}
                />
              </FormComponent.Line>
            )}
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </form>
    </>
  );
};

export const AboutComponent = memo(About);
