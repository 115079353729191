import React from 'react';
import * as R from 'ramda';

import { buttonIcons, Checkbox, FormComponent, ListEdit } from 'components';
import { DataKind } from 'components/ListEdit/model';
import { HeadRightSlot } from 'components/ListEdit/ListEdit';

import { showNotification } from 'features/Notifications';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { Project } from 'types/models';
import { showErrors } from 'utils/Validators';
import * as helpers from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';
import { EditStagesModal } from './EditStagesModal/EditStagesModal';
import { validate } from './validate';
import { useController } from './controller';
import { Color } from 'constants/colors';
import { formatFioWithChanges } from 'services/BackendAPI/configurations/project/helpers';

export type Props = {
  disabled: boolean;
  performers: Project.Performer[];
  setPerformers(performers: Project.Performer[]): void;
  stages: Project.Stage[];
  projectId: string | null;
  startProjectDate?: string;
  endProjectDate?: string;
};

export function Performers(props: Props) {
  const { stages, disabled, performers, setPerformers, projectId, startProjectDate, endProjectDate } = props;

  const {
    currentReport,
    handleCloseEditStagesModal,
    isOpenEditStagesModal,
    isReportOpen,
    loadAuthors,
    onReportClose,
    performerStages,
    setPerformersStages,
    setIsOpenEditStagesModal,
    setSelectedPerformerIndex,
    filterBabyPerformers,
    todayIsWithinJob,
    performersForToday,
    filteredPerformers,
    showOnlyYoungPerformers,
    setShowOnlyYoungPerformers,
    showOnlyActualPerformers,
    setShowOnlyActualPerformers,
    projectIsNotOutdated,
    getReports,
    handleSetCurrentReport,
  } = useController({ performers, setPerformers, projectId, endProjectDate });

  return (
    <>
      <ListEdit
        title={`Участники научного коллектива (всего: ${performers.length}, из них до 39: ${
          filterBabyPerformers(performers)?.length || 0
        }; на текущую дату: ${performersForToday.length}, из них до 39: ${
          filterBabyPerformers(performersForToday)?.length || 0
        })`}
        withMessages
        isDeleteConfirmEnabled
        isVisibleFilters
        isSortEnabled
        isToolbarDisabled={disabled}
        rows={filteredPerformers}
        onChange={setPerformers}
        isFullScreenedTable
        getRowTheme={row => {
          if (projectIsNotOutdated && !todayIsWithinJob(row)) {
            return Color.danger;
          }
          return Color.primary;
        }}
        extraToolbarButtons={[
          {
            icons: buttonIcons.list,
            title: 'Распределение по этапам проекта',
            checkIsDisabled: row => row === null || disabled,
            onClick: (performer, index) => {
              if (!stages.length) {
                showNotification({ message: 'Для проектов с незаполненными этапами данная функция недоступна', theme: 'danger' });
              } else {
                setSelectedPerformerIndex(index);
                setIsOpenEditStagesModal(true);
              }
            },
          },
          {
            icons: buttonIcons.clone,
            title: 'Скопировать авторов РИД из раздела "Интеллектуальная собственность"',
            checkIsDisabled: () => disabled,
            onClick: () => {
              loadAuthors();
            },
          },
          {
            icons: buttonIcons.print,
            title: 'Печать документов',
            onClick: () => {},
            getExpandedList: getReports,
            expandedItemCallback: handleSetCurrentReport,
          },
        ]}
        columns={[
          {
            label: '№',
            formatValue: (_, index) => index + 1,
            styles: { width: '1%', textAlign: 'center' },
          },
          { label: 'Роль', formatValue: helpers.formatRole },
          { label: 'ФИО', formatValue: formatFioWithChanges },
          { label: 'Дата рождения', formatValue: x => x.person?.scientist?.dateBirth ?? '' },
          {
            label: 'Степень, звание',
            formatValue: x =>
              [helpers.formatDegree(x), helpers.formatRank(x), helpers.formatAcademicRank(x)].filter(i => i).join(', '),
          },
          { label: 'Место работы', formatValue: helpers.formatPerformerJob },
          { label: 'Место обучения', formatValue: helpers.formatPerformerEducation },
          { label: 'Контакты', formatValue: helpers.formatContacts },
          { label: 'СНИЛС/ИНН', formatValue: helpers.formatSNILS },
          { label: 'Период работы', formatValue: helpers.formatJobPeriods },
          { label: 'Этапы', formatValue: helpers.formatStages },
          { label: 'Приказы', formatValue: helpers.formatPerformerOrders },
          {
            label: 'Есть изменения в данных',
            formatValue: x => helpers.getIsHistoryChanged(x),
            dataKind: DataKind.BOOLEAN,
          },
          { label: 'Подтверждено', formatValue: x => helpers.formatApprovememnts({ stages: x.stages }) },
          { label: 'Создано', formatValue: helpers.formatCreated },
        ]}
        specification={{
          modalTitle: 'Участники научного коллектива',
          mode: 'customModalComponent',
          isFullScreenModal: true,
          renderComponent: (performer, setPerformer) => (
            <Fields
              stages={stages}
              disabled={disabled}
              performer={performer || helpers.getMockPerformer()}
              setPerformer={setPerformer}
              startProjectDate={startProjectDate}
              endProjectDate={endProjectDate}
              projectId={projectId}
            />
          ),
          validation: {
            checkIsValid: performer => validate(performer).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      >
        <HeadRightSlot>
          <FormComponent.Line>
            <FormComponent.Field>
              <Checkbox
                label="Состав коллектива на текущую дату"
                checked={!!showOnlyActualPerformers}
                onChange={setShowOnlyActualPerformers}
              />
            </FormComponent.Field>
            <FormComponent.Field>
              <Checkbox label="до 39 лет" checked={!!showOnlyYoungPerformers} onChange={setShowOnlyYoungPerformers} />
            </FormComponent.Field>
          </FormComponent.Line>
        </HeadRightSlot>
      </ListEdit>

      <EditStagesModal
        isOpen={isOpenEditStagesModal}
        onClose={handleCloseEditStagesModal}
        performerStages={performerStages}
        stages={stages}
        setPerformerStages={setPerformersStages}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        projectId={projectId ?? ''}
      />
    </>
  );
}
