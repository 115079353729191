import React, { useCallback, useMemo } from 'react';

import * as R from 'ramda';

import * as BackendAPI from 'services/BackendAPI';

import { InputSelect, FormComponent, TextGridSelect } from 'components';

import { Partner } from 'types/models';
import { ValueOf } from 'types/helpers';

import { Enum } from 'types/models/Table';

import { GetPartnerList } from 'features/Table/specifications';
import { getEnterpriseCustomersIds, getEnterpriseIds } from 'features/EditPartner/helpers';

type Props = {
  partner: Partner;
  setPartner(partner: Partner | null): void;
  relationValues: Enum;
  isOnlyVariant?: boolean;
  allChildsPartners: Partner[];
  mainPartner: Partner | null;
};

const getParametersIds = (ids: string[]) => (ids.length ? ids.join(',') : '-1');

export function Fields(props: Props) {
  const { partner, setPartner, mainPartner, allChildsPartners, relationValues, isOnlyVariant } = props;

  const makeHandler = (key: keyof Partner) => (value: ValueOf<Partner>) => {
    setPartner({ ...partner, [key]: value });
  };

  const relationOptions = useMemo(
    () =>
      relationValues.values
        .filter(x => (isOnlyVariant ? x.value === 'VARIANT' : x.value !== 'VARIANT'))
        .map(x => ({ label: x.label, value: x.value })),
    [isOnlyVariant, relationValues],
  );

  const changeOrganization = useCallback(
    (updatedPartner: Partner | null) => {
      const [onlyVariantOption] = relationOptions;
      setPartner(updatedPartner ? { ...updatedPartner, relation: isOnlyVariant ? onlyVariantOption : partner.relation } : null);
    },
    [partner, setPartner, isOnlyVariant, relationOptions],
  );

  const { methods: getPartnerAPI } = BackendAPI.useBackendAPI('GetPartner');

  const partnerIds = [
    ...allChildsPartners.filter(x => x.status?.value === 'PARTNER').map(x => x.id),
    mainPartner?.id ?? null,
  ].filter(x => x !== null) as string[];

  const childEnterpriseCustomerIds = allChildsPartners
    .filter(x => x.status?.value !== 'PARTNER')
    .flatMap(getEnterpriseCustomersIds);
  const childEnterpriseIds = allChildsPartners.filter(x => x.status?.value !== 'PARTNER').flatMap(getEnterpriseIds);

  const enterpriseIds = R.uniq([...getEnterpriseIds(mainPartner), ...childEnterpriseIds]);
  const enterpriseCustomerIds = R.uniq([...getEnterpriseCustomersIds(mainPartner), ...childEnterpriseCustomerIds]);

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Организация">
          <TextGridSelect
            specification={GetPartnerList({
              withMenu: false,
              uniqID: 'RelationPartnerList',
              parameters: [
                { name: 'extended', value: false },
                { name: 'forChild', value: true },
                { name: 'excludeIds', value: getParametersIds(partnerIds) },
                { name: 'excludeEnterpriseIds', value: getParametersIds(enterpriseIds) },
                { name: 'excludeEnterpriseCustomerIds', value: getParametersIds(enterpriseCustomerIds) },
              ],
            })}
            onChange={changeOrganization}
            value={partner}
            valueFormatter={x => x.fullName}
            title="Список организаций"
            settings={{
              mode: 'loadTableRow',
              onSelectRow: (tableRow, onSuccess) => {
                getPartnerAPI.callAPI(
                  {
                    partnerId: tableRow.id,
                    enterpriseCustomerId: tableRow.Type === 'ENTERPRISE_CUSTOMER' ? tableRow.id : null,
                    enterpriseId: tableRow.Type === 'ENTERPRISE' ? tableRow.id : null,
                    shortName: tableRow.Type === 'RAW' ? tableRow.ShortName ?? null : null,
                    fullName: tableRow.Type === 'RAW' ? tableRow.FullName : null,
                  },
                  { onSuccessfullCall: ({ data }) => onSuccess(data) },
                );
              },
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {isOnlyVariant ? null : (
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Форма отношений">
            <InputSelect
              options={relationOptions}
              value={relationOptions.find(x => x.value === partner.relation?.value) ?? null}
              onSelectChange={makeHandler('relation')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
}
