import React, { useState, useMemo } from 'react';

import { IconButtonProps, Modal, Toolbar, buttonIcons } from 'components';

import { Table, Report } from 'types/models';
import { DataGrid } from 'features/Table/container';
import { GetSalaryJobPaymentList } from 'features/Table/specifications';
import { State } from '../makeUseCustomController';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const [selectedRow] = tableState.selectedRows;

  const [isFormOpen, setIsFormOpen] = useState(false);

  const reports = useMemo<Report[]>(() => [Reports.ProjectPerformers], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        onClick: () => setIsFormOpen(true),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [getReports, handleSetCurrentReport, selectedRow],
  );

  const title = useMemo(
    () =>
      `${selectedRow?.Fio}${!!selectedRow?.Role ? `: ${selectedRow?.Role}` : ' отсутствует'} в проекте ${
        selectedRow?.ProjectNumber
      }${!!selectedRow?.ProjectJob ? `; ${selectedRow?.ProjectJob}` : ''}. Оплачено по источнику ${selectedRow?.ProjectCodeCode}`,
    [selectedRow?.Fio, selectedRow?.ProjectCodeCode, selectedRow?.ProjectJob, selectedRow?.ProjectNumber, selectedRow?.Role],
  );

  return (
    <>
      <Modal title={title} isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} size={['extralarge', 'extralarge-height']}>
        <DataGrid
          specification={GetSalaryJobPaymentList({
            salaryJobPaymentIds: selectedRow?.SalaryJobPaymentIds,
            projectCodeId: customState.projectCodeId,
          })}
        />
      </Modal>
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
