import React, { useState } from 'react';
import { setup } from 'bem-cn';

import { Checkbox, Icon } from 'components';

import { modes, types } from 'components/Icon/helpers';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});
const b = block('gallery-vector');

export const IconsVectorDemo = () => {
  const [isStandart, setIsStandart] = useState<boolean>(false);

  return (
    <div className={b({ 'is-standart': isStandart })}>
      <h1>Иконки ({Object.keys(types).length} шт.)</h1>
      <Checkbox label="Стандартный размер" checked={isStandart} onChange={setIsStandart} />
      <div className={b('content')}>
        {Object.keys(types).map(type => (
          <div className="icon-demo" key={type}>
            <Icon type={type} />
            <div className="text">
              <strong>{type}</strong>
            </div>
          </div>
        ))}
      </div>
      <h1>Модификаторы ({`${Object.keys(modes).length}`} шт.)</h1>
      <div className={b('content')}>
        {Object.keys(modes).map(mode => (
          <div className="icon-demo" key={`save${mode}`}>
            <Icon type="save" mode={mode} />
            <div className="text">
              <strong>{mode}</strong>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
