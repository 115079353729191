import { Form } from 'types/models';
import * as XML from 'xml-js';
import { makeBackendPublicationReleaseAPIConfiguration } from '../makeBackendPublicationReleaseAPIConfiguration';

type SaveReleaseInput = {
  magazineId: string;
  releaseId?: string;
  Year: string;
  PublicationDate?: string;
  Part?: string;
  Month?: string;
  Number?: string;
  Events: Form.Conference[];
};

type GetReleaseInput = {
  releaseId: string;
};

const convertSaveMagazineReleaseXMLElement = <Input extends SaveReleaseInput>(input: Input) => {
  const reqObject = {
    MagazineRelease: {
      _attr: {},
      Magazine: { _attr: { id: input.magazineId } },
      ...(input.Year ? { Year: input.Year } : {}),
      ...(input.Month ? { Month: input.Month } : {}),
      ...(input.Part ? { Part: input.Part } : {}),
      ...(input.Number ? { Number: input.Number } : {}),
      ...(input.PublicationDate ? { PublicationDate: input.PublicationDate } : {}),
      Events: {
        Event: input.Events.map(({ id }) => ({
          _attr: { id },
        })),
      },
    },
  };

  if (input.releaseId) {
    reqObject.MagazineRelease._attr = { id: input.releaseId };
  }
  return reqObject;
};

const convertGetMagazineReleaseXMLElement = <Input extends GetReleaseInput>(input: Input) => {
  const reqObject = {
    MagazineRelease: { _attr: { id: input.releaseId } },
  };

  return reqObject;
};

function makeRequestXMLConverter<Input>(
  commandName: string,
  makeChildren: (input: Input) => XML.ElementCompact,
): (input: Input) => XML.ElementCompact {
  return (input: Input) => ({
    Request: {
      _attr: { commandName },
      ...makeChildren(input),
    },
  });
}

export const backendPublicationReleaseApiConfigurations = {
  GetMagazineRelease: makeBackendPublicationReleaseAPIConfiguration(
    'GetMagazineRelease',
    makeRequestXMLConverter('GetMagazineRelease', (input: GetReleaseInput) => convertGetMagazineReleaseXMLElement(input)),
  ),
  SaveMagazineRelease: makeBackendPublicationReleaseAPIConfiguration(
    'SaveMagazineRelease',
    makeRequestXMLConverter('SaveMagazineRelease', (input: SaveReleaseInput) => convertSaveMagazineReleaseXMLElement(input)),
  ),
};
