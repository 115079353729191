import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  type: string;
};

type RequesData = {
  type: string;
};

export function GetMobileRequestList({ type }: Props): Table.Specification<State, RequesData> {
  return {
    apiID: 'GetMobileRequestList',
    header: {
      firstLevel: {
        title:
          // eslint-disable-next-line max-len
          'Заявки на индивидуальную финансовую поддержку участия в программе краткосрочной международной академической мобильности',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({ type }),
    requestData: { type },
  };
}
