import { Table } from 'types/models';

export const GetProgramRequestListForProject: Table.Specification = {
  apiID: 'GetProgramRequestListForProject',
  header: {
    thirdLevel: {
      hasSelectButton: true,
    },
  },
};
