import { useState, useMemo, useCallback } from 'react';
import { Table } from 'types/models';
import { IconButtonProps, buttonIcons } from 'components';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams, useTableContext } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { Table as T } from 'types/models';

type Props = {
  tableState: Table.State;
  workMode: T.WorkMode;
};

const useController = ({ tableState, workMode }: Props) => {
  const isAddMode = workMode === 'addMode';
  const { isProfile } = usePrivatePageContext();
  const tableStreams = useLocalTableStreams();
  const tableContext = useTableContext();
  const relatedTableId = useMemo(() => tableContext.specification.templatesTableDependencies?.relatedRecordId || null, [
    tableContext,
  ]);
  const magazineId = useMemo(() => relatedTableId || useFormContext().look.id, [relatedTableId]);
  const selectedRowsLength = useMemo<number>(() => tableState.selectedRows.length, [tableState.selectedRows]);
  const rowsAvailability = useMemo<Table.ToolbarStateRowsAvailability>(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );
  const isApproveButtonDisabled = useMemo<boolean>(() => !rowsAvailability.MULTIPLE_SELECTED, [
    rowsAvailability.MULTIPLE_SELECTED,
  ]);

  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isArticleFormOpen, setisArticleFormOpen] = useState<boolean>(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState<boolean>(false);

  const openViewForm = useCallback(() => {
    setIsViewFormOpen(true);
  }, [setIsViewFormOpen]);

  const closeViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, [setIsViewFormOpen]);

  const openAddForm = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const closeAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const openArticleForm = useCallback(() => {
    setisArticleFormOpen(true);
  }, [setisArticleFormOpen]);

  const closeArticleForm = useCallback(() => {
    setisArticleFormOpen(false);
  }, [setisArticleFormOpen]);

  const openEditForm = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const closeEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const openConfirmDeletePopup = useCallback(() => {
    setIsConfirmDeletePopupOpen(true);
  }, [setIsConfirmDeletePopupOpen]);

  const closeConfirmDeletePopup = useCallback(() => {
    setIsConfirmDeletePopupOpen(false);
  }, [setIsConfirmDeletePopupOpen]);

  const removeRelease = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'MagazineRelease',
    });
  }, [tableState.selectedRows, tableStreams]);

  const approveRelease = useCallback(() => {
    tableStreams.approveRow.push({
      approveRowId: tableState.selectedRows.map(item => item.id),
      command: 'ApproveMagazineRelease',
      approveItemName: 'MagazineReleases',
      approveInnerItemName: 'MagazineRelease',
    });
  }, [tableState.selectedRows, tableStreams.approveRow]);

  const confirmDeleteRelease = useCallback(() => {
    removeRelease();
    closeConfirmDeletePopup();
  }, [removeRelease, closeConfirmDeletePopup]);

  const toolbarButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: isAddMode || !rowsAvailability.SINGLE_SELECTED,
        onClick: openViewForm,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isDisabled: isAddMode || !magazineId,
        onClick: openAddForm,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: isAddMode || !rowsAvailability.SINGLE_SELECTED,
        onClick: openEditForm,
        permissionName: Permits.MAGAZINE_RELEASE_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        isDisabled: isAddMode || !rowsAvailability.SINGLE_SELECTED,
        onClick: openConfirmDeletePopup,
      },
      {
        icons: buttonIcons.approve,
        title: 'Утверждение выпуска',
        isDisabled: isAddMode || isApproveButtonDisabled,
        onClick: approveRelease,
        isHidden: isProfile,
        permissionName: Permits.MAGAZINE_RELEASE_APPROVE,
      },
      {
        icons: buttonIcons.info,
        title: 'Информация о статьях',
        isDisabled: isAddMode || !rowsAvailability.SINGLE_SELECTED,
        onClick: openArticleForm,
      },
    ],
    [
      isAddMode,
      rowsAvailability.SINGLE_SELECTED,
      openViewForm,
      magazineId,
      openAddForm,
      openEditForm,
      openConfirmDeletePopup,
      isApproveButtonDisabled,
      approveRelease,
      isProfile,
      openArticleForm,
    ],
  );

  return {
    isAddFormOpen,
    closeAddForm,
    isViewFormOpen,
    closeViewForm,
    isEditFormOpen,
    closeEditForm,
    isArticleFormOpen,
    closeArticleForm,
    toolbarButtons,
    isConfirmDeletePopupOpen,
    confirmDeleteRelease,
    closeConfirmDeletePopup,
    relatedTableId,
  };
};

export default useController;
