import React, { useMemo } from 'react';

import { Tab, Tabs, FormComponent, Modal, ConfirmPopup } from 'components';

import { Documents } from 'features/Documents';
import { DataGrid } from 'features/Table';
import { GetProjectFeedlineList, GetProjectPerformerOrderList } from 'features/Table/specifications';
import { ProjectType } from 'utils/Enums';
import { usePermissions } from 'utils/Helpers/projects/usePermissions';
import { Mode } from 'features/IncentivePayments';
import {
  AllPerformers,
  Classifiers,
  CalendarPlan,
  ResultsProject,
  FeaturesProject,
  StateRegistration,
  DescriptionProject,
  AboutProject,
  Requests,
  Reports,
} from './Tabs';
import { Toolbar } from './Toolbar/Toolbar';
import { useController } from './controller';
import { Performers } from './Tabs/AllPerformers/Tabs';
import { Acts } from './views/Acts/Acts';
import { Project } from 'types/models';
import { GetProjectAssignmentList } from 'features/Table/specifications/GetProjectAssignmentList';
import { getEnumItemLabel } from 'utils/Helpers';
import { GetPurchaseRequestList } from 'features/Table/specifications/GetPurchaseRequestList';

type Props = {
  id: string | null;
  isOpen: boolean;
  disabled: boolean;
  onClose(): void;
  onSuccessfullSave?(): void;
  mode: Mode;
  nirRequestId?: string;
  projectType?: ProjectType;
  securityDocumentContractId?: string;
  programRequestId?: string;
  copiedProject?: Project.Project;
};

const Component: React.FC<Props> = (props: Props) => {
  const {
    id,
    isOpen,
    onClose,
    mode,
    disabled,
    onSuccessfullSave,
    nirRequestId,
    projectType: initialProjectType,
    securityDocumentContractId,
    programRequestId,
    copiedProject,
  } = props;

  const {
    enumMap,
    project,
    projectType,
    makeChangeHandler,
    setProject,
    handleSave,
    getPatentResearchByStageId,
    copyFromNirRequest,
    indicators,
    indices,
    handleActsChange,
    acts,
    isEndDateReducedWarningOpen,
    setIsEndDateReducedWarningOpen,
    isEndDateEnlargedWarningOpen,
    setIsEndDateEnlargedWarningOpen,
    handleIsEndDateReducedWarningConfirm,
    handleIsEndDateEnlargeWarningConfirm,
    handleIsEndDateEnlargeWarningCancel,
    endDateEnlargedWarningTxt,
    endDateWarningTxt,
    isUnsavedWarningOpen,
    setIsUnsavedWarningOpen,
    handleUnsavedWarningConfirm,
    isOptionalValidateModalOpen,
    setIsOptionalValidateModalOpen,
    optionalValidateMessages,
    confirmOptionalValidatePopup,
    resetOptionalValidatePopup,
    tabsId,
    copyFromProgramRequest,
  } = useController({
    onSuccessfullSave,
    id,
    nirRequestId,
    projectType: initialProjectType,
    mode,
    securityDocumentContractId,
    programRequestId,
    copiedProject,
  });

  const permissions = usePermissions(projectType);

  const title = useMemo(() => {
    const typeValue = project?.type?.value as ProjectType;
    const additionalText = (() => {
      const result = [
        project?.specification?.label,
        [project?.id ? `ID ${project?.id}` : '', project?.status ? `статус ${project?.status}` : ''].filter(x => x).join(', '),
      ]
        .filter(x => x)
        .join('. ');
      if (result) {
        return `(${result})`;
      }
      return '';
    })();

    return [
      mode === 'view' ? 'Просмотр' : mode === 'edit' ? 'Редактирование' : 'Добавление',
      'проекта',
      project?.number ? `№ ${project?.number}` : '',
      additionalText,
      typeValue ? `Тип: ${getEnumItemLabel('ProjectType', typeValue, enumMap)}` : '',
    ]
      .filter(x => x)
      .join(' ');
  }, [project?.type?.value, project?.number, project?.specification?.label, project?.id, project?.status, mode, enumMap]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={!!project?.type?.label ? title : ''} size="full">
      <FormComponent.Template>
        <Toolbar
          projectId={project?.id ?? null}
          onSave={handleSave('save')}
          onSaveAndContinue={handleSave('saveAndContinue')}
          hidden={disabled}
        />

        <FormComponent.Template>
          <Tabs id={tabsId}>
            <Tab isVisible={permissions.isVisibleAboutProject} title="О проекте">
              <AboutProject
                isEditableLocked={permissions.isEditableLocked}
                project={project}
                makeChangeHandler={makeChangeHandler}
                setProject={setProject}
                indicators={indicators}
                indices={indices}
                disabled={disabled || !permissions.isEditableAboutProject}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleAboutProject} title="Заявки">
              <Requests
                disabled={disabled || !permissions.isEditableAboutProject}
                project={project}
                makeChangeHandler={makeChangeHandler}
                copyFromNirRequest={copyFromNirRequest}
                setProject={setProject}
                copyFormProgramRequest={copyFromProgramRequest}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleProjectFeatures} title="Особенности">
              <FeaturesProject
                disabled={disabled || !permissions.isEditableProjectFeatures}
                project={project}
                makeChangeHandler={makeChangeHandler}
                projectType={projectType}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleProjectDescription} title="Описание">
              <DescriptionProject
                disabled={disabled || !permissions.isEditableProjectDescription}
                project={project}
                setProject={setProject}
                makeChangeHandler={makeChangeHandler}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleClassifiers} title="Классификаторы">
              <Classifiers
                project={project}
                disabled={disabled || !permissions.isEditableClassifiers}
                makeChangeHandler={makeChangeHandler}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleStateRegistration} title="Госрегистрация">
              <StateRegistration
                disabled={disabled || !permissions.isEditableStateRegistration}
                makeChangeHandler={makeChangeHandler}
                project={project}
                setProject={setProject}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleExecutors} title="Коллектив">
              <Tabs>
                <Tab title="Состав коллектива">
                  <Performers
                    disabled={disabled || !permissions.isEditableExecutors}
                    performers={project?.performers ?? []}
                    stages={project?.stages ?? []}
                    setPerformers={makeChangeHandler('performers')}
                    projectId={project?.id ?? null}
                    startProjectDate={project?.startDate}
                    endProjectDate={project?.endDate}
                  />
                </Tab>
                <Tab title="Приказы по коллективу">
                  <DataGrid specification={GetProjectPerformerOrderList({ projectId: project?.id ?? null })} />
                </Tab>
              </Tabs>
            </Tab>
            <Tab isVisible={permissions.isVisibleExecutors} title="Партнёры">
              <AllPerformers
                disabled={disabled || !permissions.isEditableExecutors}
                projectId={project?.id ?? null}
                partners={project?.partners ?? []}
                setPartners={makeChangeHandler('partners')}
                internationalProgram={project?.internationalProgram || null}
                setInternationalProgram={makeChangeHandler('internationalProgram')}
                foreignEnterprise={project?.foreignEnterprise || null}
                setForeignEnterprise={makeChangeHandler('foreignEnterprise')}
                partnerCountries={project?.partnerCountries ?? []}
                setPartnerCountries={makeChangeHandler('partnerCountries')}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleCalendarPlan} title="Этапы">
              <Tabs>
                <Tab title="Этапы проекта">
                  <CalendarPlan
                    project={project}
                    disabled={disabled || !permissions.isEditableCalendarPlan}
                    setStages={makeChangeHandler('stages')}
                    getPatentResearchByStageId={getPatentResearchByStageId}
                  />
                </Tab>
                <Tab title="Отчёты">
                  <Reports project={project} disabled={disabled} setProject={setProject} />
                </Tab>
              </Tabs>
            </Tab>
            <Tab isVisible={permissions.isVisibleResults} title="Результаты">
              <ResultsProject
                project={project}
                makeChangeHandler={makeChangeHandler}
                disabled={disabled || !permissions.isEditableResults}
                indicators={indicators}
                indices={indices}
              />
            </Tab>
            <Tab isVisible={permissions.isVisibleDocuments} title="Документы">
              <Tabs>
                <Tab isVisible={permissions.isVisibleDocuments} title="Документы">
                  <Documents
                    isVisibleFilters
                    isSortEnabled
                    documents={project?.documents ?? []}
                    setDocuments={makeChangeHandler('documents')}
                    disabled={disabled || !permissions.isEditableDocuments}
                    isShowSource
                    sourceLabelForLocalDocs="Текущий проект"
                    fieldsType="projectDocument"
                    sharedLabel="Отображать на форме редактирования шифра"
                    defaultSharedValue={true}
                  />
                </Tab>
                <Tab title="Акты">
                  <Acts project={project} acts={acts} onChange={handleActsChange} disabled={disabled} />
                </Tab>
              </Tabs>
            </Tab>
            <Tab title="Журнал сообщений">
              <DataGrid specification={GetProjectFeedlineList({ projectId: project?.id || '-1', disabled })} />
            </Tab>
            <Tab title="Поручения">
              <DataGrid
                specification={GetProjectAssignmentList({ projectId: project?.id || '-1', isFiltersHidden: true, disabled })}
              />
            </Tab>
            {project?.id && (
              <Tab title="Закупки">
                <DataGrid specification={GetPurchaseRequestList({ projectId: project?.id || '-1', isFiltersHidden: true })} />
              </Tab>
            )}
          </Tabs>
        </FormComponent.Template>

        <ConfirmPopup
          isOpen={isEndDateReducedWarningOpen}
          onClose={() => setIsEndDateReducedWarningOpen(false)}
          title="Предупреждение"
          icon="warning"
          text={endDateWarningTxt}
          okButtonText="Ок"
          onConfirm={handleIsEndDateReducedWarningConfirm}
        />
        <ConfirmPopup
          isOpen={isEndDateEnlargedWarningOpen}
          onClose={() => setIsEndDateEnlargedWarningOpen(false)}
          title="Подтвердите изменение сроков"
          text={endDateEnlargedWarningTxt}
          icon="warning"
          okButtonText="Да"
          resetButtonText="Нет"
          onConfirm={handleIsEndDateEnlargeWarningConfirm}
          onReset={handleIsEndDateEnlargeWarningCancel}
        />
        <ConfirmPopup
          isOpen={isUnsavedWarningOpen}
          onClose={() => setIsUnsavedWarningOpen(false)}
          title="Необходимо сохранить документ"
          text="Для фиксации изменений необходимо сохранить документ"
          icon="warning"
          okButtonText="Сохранить и продолжить"
          resetButtonText="Отмена"
          onConfirm={handleUnsavedWarningConfirm}
          onReset={() => {
            setIsUnsavedWarningOpen(false);
          }}
        />
        <ConfirmPopup
          isOpen={isOptionalValidateModalOpen}
          onClose={() => setIsOptionalValidateModalOpen(false)}
          title="Внимание"
          okButtonText="Сохранить"
          onConfirm={resetOptionalValidatePopup}
          resetButtonText="Исправить"
          onReset={confirmOptionalValidatePopup}
          icon="warning"
        >
          <FormComponent.Wrapper>
            <div>
              <strong>Обнаружено несоответствие:</strong>
            </div>
            <br />
            <ul>
              {optionalValidateMessages.map((x, i) => (
                <>
                  <li>
                    <div key={i} dangerouslySetInnerHTML={{ __html: x || '' }} />
                  </li>
                  {optionalValidateMessages.length !== i + 1 && <br />}
                </>
              ))}
            </ul>
          </FormComponent.Wrapper>
        </ConfirmPopup>
      </FormComponent.Template>
    </Modal>
  );
};

export const Form = React.memo(Component) as typeof Component;
