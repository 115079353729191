import React from 'react';

import { FormComponent, Reference, SectionTitle, TextArea, TextAreaMode, TextDateTime, TextInput } from 'components';

import { Form, Rid } from 'types/models';

type Props = {
  rid?: Rid | null;
  formFields: Form.Fields;
  disabled: boolean;
};

export function Commercialization({ formFields, disabled }: Props) {
  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column>
          <SectionTitle title="Заключение о возможности коммерциализации РИД" />
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field
              label={formFields.commercializationProtocol.title}
              isRequired={formFields.commercializationProtocol.required}
            >
              <TextInput
                value={formFields.commercializationProtocol.value}
                onChange={formFields.commercializationProtocol.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
            <FormComponent.Field
              label={formFields.commercializationDate.title || ''}
              isRequired={formFields.commercializationDate.required}
            >
              <TextDateTime
                value={formFields.commercializationDate.value}
                onChange={formFields.commercializationDate.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.commercializationDecision.title}
              isRequired={formFields.commercializationDecision.required}
            >
              <Reference
                disabled={disabled}
                value={formFields.commercializationDecision.value}
                title=""
                relationTableModalTitle={formFields.commercializationDecision.title}
                name="RefDecision"
                onChange={formFields.commercializationDecision.onChange}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <SectionTitle title="Использование в собственном производстве" />
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field
              label={formFields.selfProductionNumber.title}
              isRequired={formFields.selfProductionNumber.required}
            >
              <TextInput
                value={formFields.selfProductionNumber.value}
                onChange={formFields.selfProductionNumber.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
            <FormComponent.Field label={formFields.selfProductionDate.title} isRequired={formFields.selfProductionDate.required}>
              <TextDateTime
                value={formFields.selfProductionDate.value}
                onChange={formFields.selfProductionDate.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.selfProductionNote.title} isRequired={formFields.selfProductionNote.required}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.selfProductionNote.title }}
                value={formFields.selfProductionNote.value}
                onChange={formFields.selfProductionNote.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <SectionTitle title="Решение ФСТЭК" />
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.fctekProtocol.title} isRequired={formFields.fctekProtocol.required}>
              <TextInput
                value={formFields.fctekProtocol.value}
                onChange={formFields.fctekProtocol.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
            <FormComponent.Field label={formFields.fctekDate.title || ''} isRequired={formFields.fctekDate.required}>
              <TextDateTime value={formFields.fctekDate.value} onChange={formFields.fctekDate.onChange} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.fctekDecision.title} isRequired={formFields.fctekDecision.required}>
              <Reference
                disabled={disabled}
                value={formFields.fctekDecision.value}
                title=""
                relationTableModalTitle={formFields.fctekDecision.title}
                name="RefDecision"
                onChange={formFields.fctekDecision.onChange}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
