import { useCallback, useEffect, useMemo, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';

import { Department, Publication, Form, Participation } from 'types/models';

import { showNotification } from 'features/Notifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { useLocalTableStreams } from 'features/Table/hooks';

type Arguments = {
  onClose: () => void;
  id: string;
  errorText: string;
  type: string;
};

export function useController(args: Arguments) {
  const { onClose, id, type } = args;
  const tableStreams = useLocalTableStreams();
  const [publication, setPublication] = useState<Publication | null>(null);
  const [participationData, setParticipationData] = useState<null | Participation>(null);
  const [departments, setDepartments] = useState<Department[]>([]);
  /**
   * error controller
   */
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [errorStatus, setErrorStatus] = useState<{ type: string; text: string }>({ type: '', text: '' });

  const { methods: getPublicationAPI } = BackendAPI.useBackendAPI('GetPublicationById');
  const { methods: SavePublicationDepartments } = BackendAPI.useBackendAPI('SavePublicationDepartments', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Связь с подразделениями успешно сохранена', theme: 'success' });
      tableStreams.reloadTable.push();
    },
  });
  const { methods: getParticipation } = BackendAPI.useBackendAPI('GetParticipation');
  const { methods: saveParticipation } = BackendAPI.useBackendAPI('SaveParticipation');

  const publicationType = useMemo<Form.PublicationType | null>(() => {
    const publicationTypesArray = Object.entries(PublicationTypes);

    const currentPublicationTypeEntrie = publicationTypesArray.find(([, { code }]) => code === publication?.type.value);
    if (currentPublicationTypeEntrie) {
      const currentPublicationType: Form.PublicationType = currentPublicationTypeEntrie[1];
      return currentPublicationType;
    }

    return null;
  }, [publication]);

  const GetPublicationById = useCallback(() => {
    getPublicationAPI.callAPI(
      { publicationId: id },
      {
        onSuccessfullCall: ({ data }) => {
          setPublication(data);
          setDepartments(data?.departments ?? []);
        },
      },
    );
  }, [getPublicationAPI, id]);
  const handleSaveParticipation = useCallback(() => {
    if (departments.length) {
      saveParticipation.callAPI(
        {
          participationId: id,
          simpleFields: {
            participationProcedure: 'WITHOUT_REQUEST',
            isInTeam: false,
            IsCollectiveExhibit: false,
            note: participationData?.note || '',
            development: participationData?.development,
            collectiveExhibitName: participationData?.collectiveExhibitName,
            isCollectiveExhibit: participationData?.isCollectiveExhibit,
            reportTheme: participationData?.reportTheme,
            reportDate: participationData?.reportDate,
          },
          attrIdFields: {
            event: participationData?.event?.id,
            exhibitType: participationData?.exhibitType?.id,
            reportType: participationData?.reportType?.id,
            publication: participationData?.publication?.status?.id,
            form: participationData?.form?.id,
          },
          otherFields: {
            departments: departments.map(d => ({
              id: d.ref.id,
              name: d.ref.label,
              code: d.ref.code,
            })),
            documents: participationData?.documents,
            awards: participationData?.awards,
            members: participationData?.members,
            mobileRequests: participationData?.mobileRequests,
            financingProjects: participationData?.financingProjects ?? [],
            presentationProject: participationData?.presentationProject ?? null,
          },
        },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Связь участия с подразделением успешно сохранено', theme: 'success' });
            onClose();
          },
          onFailedCall: () => {},
        },
      );
    } else {
      showNotification({ message: 'Нужно указать хотя бы одно подразделение', theme: 'danger' });
    }
  }, [
    departments,
    saveParticipation,
    id,
    participationData?.note,
    participationData?.development,
    participationData?.collectiveExhibitName,
    participationData?.isCollectiveExhibit,
    participationData?.reportTheme,
    participationData?.reportDate,
    participationData?.event?.id,
    participationData?.exhibitType?.id,
    participationData?.reportType?.id,
    participationData?.publication?.status?.id,
    participationData?.form?.id,
    participationData?.documents,
    participationData?.awards,
    participationData?.members,
    participationData?.mobileRequests,
    participationData?.financingProjects,
    participationData?.presentationProject,
    onClose,
  ]);
  const saveDepartments = useCallback(() => {
    if (type === 'PUBLICATION' && publication) {
      SavePublicationDepartments.callAPI({ publicationId: publication.id, departments });
    }
    if (type === 'PARTICIPATION' && participationData) {
      handleSaveParticipation();
    }
  }, [type, publication, participationData, SavePublicationDepartments, departments, handleSaveParticipation]);

  const GetParticipation = useCallback(() => {
    getParticipation.callAPI(
      {
        participationId: id,
        modules: [
          'ID',
          'ACCESS_MODE',
          'MAIN',
          'EVENT',
          'DEPARTMENTS',
          'PUBLICATION',
          'PROJECTS',
          'MEMBERS',
          'AWARDS',
          'MOBILE_REQUESTS',
          'DOCUMENTS',
        ],
        eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
        IsCollectiveExhibit: false,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setParticipationData(data);
          setDepartments(
            data.departments.map(d => ({
              id: null,
              ref: {
                id: d.id,
                label: d.name,
                code: d.code,
              },
            })),
          );
        },
      },
    );
  }, [getParticipation, id]);

  const authors = publication?.members ?? [];
  const biblioRecord = useMemo(() => publication?.bibliographicRecord ?? '', [publication?.bibliographicRecord]);

  const handleSubmit = useCallback(() => {
    saveDepartments();
    onClose();
  }, [onClose, saveDepartments]);

  const handleCloseErrorModal = useCallback(() => {
    setIsErrorModal(false);
    setErrorStatus({
      type: '',
      text: '',
    });
    onClose();
  }, [onClose]);

  const fetchDepartments = useCallback(() => {
    if (type === 'PUBLICATION') {
      GetPublicationById();
    }
    if (type === 'PARTICIPATION') {
      GetParticipation();
    }
  }, [GetParticipation, GetPublicationById, type]);

  useEffect(() => {
    fetchDepartments();
    // eslint-disable-next-line
  }, []);

  return {
    authors,
    departments,
    setDepartments,
    handleSubmit,
    biblioRecord,
    publicationType,
    isErrorModal,
    errorStatus,
    handleCloseErrorModal,
    participationData,
  };
}
