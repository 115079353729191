import { useCallback, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { Participation } from 'types/models';

export type State = {
  openAwards(): void;
  closeAwards(): void;
  loadParticipation(participationId: string): void;
  handleFetchParticipation(participationId: string, options: any): void;
  isShowAwards: boolean;
  participation: Participation | null;
};

export function makeUseCustomController() {
  return function useCustomController(): State {
    const [isShowAwards, setIsShowAwards] = useState(false);
    const [participation, setParticipation] = useState<Participation | null>(null);

    const closeAwards = useCallback(() => {
      setIsShowAwards(false);
      setParticipation(null);
    }, []);

    const openAwards = useCallback(() => {
      setIsShowAwards(true);
    }, []);
    const { methods: loadParticipationAPI } = BackendAPI.useBackendAPI('GetParticipation');

    const loadParticipation = useCallback((participationId: string) => {
      loadParticipationAPI.callAPI(
        {
          participationId,
          modules: ['AWARDS'],
          eventModules: ['MAIN'],
        },
        {
          onSuccessfullCall: ({ data }: any) => {
            setParticipation(data);
            openAwards();
          },
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFetchParticipation = useCallback((participationId: string, options) => {
      loadParticipationAPI.callAPI(
        {
          participationId: participationId || undefined,
          modules: [
            'ID',
            'ACCESS_MODE',
            'MAIN',
            'EVENT',
            'DEPARTMENTS',
            'PUBLICATION',
            'PROJECTS',
            'MEMBERS',
            'AWARDS',
            'MOBILE_REQUESTS',
            'DOCUMENTS',
          ],
          eventType: 'CONFERENCE',
          eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
          IsCollectiveExhibit: false,
        },
        options,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
      openAwards,
      closeAwards,
      loadParticipation,
      isShowAwards,
      participation,
      handleFetchParticipation,
    };
  };
}
