import React from 'react';
import { DataGrid } from 'features/Table';
import { GetEventFeedlineList } from 'features/Table/specifications/GetEventFeedlineList';
import { Table as T } from 'types/models';

type Props = {
  relatedTableState: T.State | undefined;
  workMode: T.WorkMode;
  publicationId: string | undefined;
};

function Messages({ relatedTableState, workMode, publicationId }: Props) {
  return (
    <>
      {relatedTableState && (
        <DataGrid
          specification={GetEventFeedlineList(
            {
              relatedTableAPIID: 'GetMagazineList',
              relatedTableState,
              relatedRecordId: publicationId || undefined,
            },
            workMode,
            'RefEventFeedlineMessage',
          )}
        />
      )}
    </>
  );
}

export { Messages };
