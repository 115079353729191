import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { State, makeUseCustomController } from './makeUseCustomController';

export type Arguments = {
  hasSelectButton?: boolean;
};

export const GetDetailedScientistList = ({ hasSelectButton }: Arguments): Table.Specification<State> => ({
  apiID: 'GetDetailedScientistList',
  header: {
    firstLevel: {
      title: 'Персоны',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: true,
      hasSelectButton,
    },
  },
  useCustomController: makeUseCustomController(),
  isCanSelectOnlyOneRow: true,
});

export const GetDetailedScientistListSpecification = ({ hasSelectButton }: Arguments): Table.Specification<State> => ({
  apiID: 'GetDetailedScientistList',
  header: {
    firstLevel: {
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: true,
      hasSelectButton,
    },
  },
  useCustomController: makeUseCustomController(),
  isCanSelectOnlyOneRow: true,
});
