import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';
import { EnumFilterValues } from 'types/models/Table';
import { MobileRequestStatus, NirRequestStatus, RequestStatus, TenderRequestType } from 'utils/Enums';
import { Filters } from './filters';

type RequestData = {
  protocolId: string | null;
};

type Args = {
  requestType?: TenderRequestType;
} & RequestData;

export function GetTenderProtocolApproverRequestList({
  protocolId,
  type,
  statusChangeCallback,
  requestType,
}: State & Args): Table.Specification<State, RequestData> {
  const enumFilterValues: EnumFilterValues | undefined =
    requestType === TenderRequestType.PROGRAM_REQUEST
      ? {
          enumName: 'RequestStatus',
          excludeValues: [RequestStatus.DRAFT, RequestStatus.SENT, RequestStatus.APPROVED, RequestStatus.TENDER_CANCELED],
        }
      : requestType === TenderRequestType.NIR_REQUEST
      ? {
          enumName: 'NirRequestStatus',
          excludeValues: [NirRequestStatus.DRAFT],
        }
      : requestType === TenderRequestType.MOBILE_REQUEST
      ? {
          enumName: 'MobileRequestStatus',
          excludeValues: [MobileRequestStatus.DRAFT, MobileRequestStatus.SENT, MobileRequestStatus.APPROVED],
        }
      : undefined;

  return {
    apiID: 'GetTenderProtocolApproverRequestList',
    requestData: { protocolId },
    header: {
      firstLevel: {
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ type, statusChangeCallback }),
    enumFilterValues,
  };
}
