import React, { useCallback, useState } from 'react';

import { Button, ButtonMode, Radio, TextArea, FormComponent, Modal } from 'components';

import { showNotification } from 'features/Notifications';
import { NirRequestApprovementResolution } from 'utils/Enums';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(message: string, visa: NirRequestApprovementResolution): void;
};

export function ExpertEstimateModal(props: Props) {
  const { isOpen, onClose, onSubmit } = props;

  const [message, setMessage] = useState('');
  const [visaValue, setVisaValue] = useState<NirRequestApprovementResolution>(NirRequestApprovementResolution.AGREE);

  const isRequiredMessage = visaValue === 'REJECT' || visaValue === 'REVISION';

  const handleSubmit = useCallback(() => {
    const submit = () => {
      onSubmit?.(message, visaValue);
      onClose();
    };
    if (isRequiredMessage) {
      if (message) {
        submit();
      } else {
        showNotification({ message: 'Для выбранной резолюции необходимо ввести комментарий', theme: 'danger' });
      }
    } else {
      submit();
    }
  }, [onClose, onSubmit, message, visaValue, isRequiredMessage]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Экспертная оценка" size="large">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Виза">
            <Radio
              value={visaValue}
              list={[
                { label: 'Согласовано', value: 'AGREE' },
                { label: 'Отклонить', value: 'REJECT' },
                { label: 'На доработку', value: 'REVISION' },
              ]}
              onChange={(val: NirRequestApprovementResolution) => setVisaValue(val)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Замечания (не более 1000 знаков с пробелами)" isRequired={isRequiredMessage}>
            <TextArea settings={{ rows: 4, isFixed: true }} value={message} onChange={setMessage} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Продолжить" onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
