import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons, currentReport, isReportOpen, onReportClose, isHelpFormOpen, setIsHelpFormOpen } = useController({
    tableState,
  });
  return (
    <>
      <Toolbar buttons={buttons} />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        securityDocumentId={tableState.selectedRows.length > 0 ? tableState.selectedRows[0]?.SecurityDocumentId : ''}
      />
      <ConfirmPopup
        title={'Помощь для Аналитика ОИС по авторам'}
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Таблица содержит авторов всех видов Объектов интеллектуальной собственности (патенты, заявки на патент, свидетельства,
            заявки на получение свидетельства, ноу-хау, объекты авторского права, не аффилированные с университетом).
          </p>
          <p>
            <strong className="primary">Важно:</strong> данные об авторе выводятся в том виде, каком они зафиксированы в документе
            (ОИС). Для идентификации в моменте добавлены столбцы с актуальными на текущую дату персональными данными: статус, ФИО,
            контакты
          </p>
          <p>
            <table className="list-edit-table">
              <thead>
                <tr className="list-edit-table__row list-edit-table__row_head">
                  <th className="list-edit-table__column">Название столбца</th>
                  <th className="list-edit-table__column">Содержание</th>
                </tr>
              </thead>
              <tbody>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column" colSpan={2}>
                    <strong>Источник данных – справочник персон</strong>
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Персона</td>
                  <td className="list-edit-table__column">Текущий статус, ФИО (предыдущие ФИО, если есть)</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Дата рождения</td>
                  <td className="list-edit-table__column">Дата рождения</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Контакты</td>
                  <td className="list-edit-table__column">Телефон, E-mail</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Идентификаторы</td>
                  <td className="list-edit-table__column">ID персоны в базах цитирования публикаций</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column" colSpan={2}>
                    <strong>Источник данных — Сведения об авторах на форме ОИС</strong>
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Автор</td>
                  <td className="list-edit-table__column">Фамилия Имя Отчество</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Гражданство</td>
                  <td className="list-edit-table__column">Страна</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Степень</td>
                  <td className="list-edit-table__column">уч.степень</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Звание</td>
                  <td className="list-edit-table__column">уч.степень, ак. звание</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Место работы</td>
                  <td className="list-edit-table__column">Организация, должность, подразделение</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Место обучения</td>
                  <td className="list-edit-table__column">Организация, Студент/аспирант, специальность, подразделение</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Творческий вклад</td>
                  <td className="list-edit-table__column">Процент творческого вклада автора</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column" colSpan={2}>
                    <strong>Источник данных — Сведения об ОИС, вкладка Описание</strong>
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Вид документа</td>
                  <td className="list-edit-table__column">Вид документа</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Вид ОИС</td>
                  <td className="list-edit-table__column">Вид ОИС</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Название ОИС</td>
                  <td className="list-edit-table__column">Название ОИС</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Номер документа</td>
                  <td className="list-edit-table__column">Номер документа</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Номер заявки</td>
                  <td className="list-edit-table__column">Номер заявки</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Начало действия</td>
                  <td className="list-edit-table__column">
                    Дата начала действия охранного документа ( для заявки – Дата подачи заявки)
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Окончание действия</td>
                  <td className="list-edit-table__column">
                    Дата начала действия охранного документа ( для ноу- хау и заявки – отсутствует)
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">МПК</td>
                  <td className="list-edit-table__column"></td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Правообладатели</td>
                  <td className="list-edit-table__column">Название организации/ ФИО для физ.лиц</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">ОИС связано - выводить</td>
                  <td className="list-edit-table__column">из раздела Связь с подразделениями в формате: Факультет/институт</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Получен в проекте</td>
                  <td className="list-edit-table__column">Тип проекта № номер проекта Название проекта (Руководитель проекта)</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Программа</td>
                  <td className="list-edit-table__column">Программа, связанная с проектом</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Программа</td>
                  <td className="list-edit-table__column">Программа</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column" colSpan={2}>
                    <strong>Источник данных — Сведения об ОИС, вкладка Классификаторы</strong>
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">ГРНТИ</td>
                  <td className="list-edit-table__column">Коды ГРНТИ</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">OECD</td>
                  <td className="list-edit-table__column">Коды международной классификации отраслей наук (OECD)</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Рынки НТР</td>
                  <td className="list-edit-table__column">Рынки Национальной технологической инициатив</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Стратегии НТР</td>
                  <td className="list-edit-table__column">Стратегии научно-технологического развития Российской Федерации</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Технологии НТИ</td>
                  <td className="list-edit-table__column">Сквозные технологии Национальной технологической инициативы</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>
      </ConfirmPopup>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
