import { Column } from 'components/ListEdit/model';

import { RefElement } from 'types/models/Project';

type Props = {
  columns?: Column<RefElement>[];
};

export function useController(props: Props) {
  const { columns } = props;
  const listEditColumns =
    columns && columns.length > 0
      ? columns
      : [
          { label: 'Наименование', formatValue: (x: RefElement) => x.ref?.label ?? '', styles: { width: '75%' } },
          { label: 'Создано', formatValue: (x: RefElement) => `${x.createdBy} ${x.createdDate}`, styles: { width: '25%' } },
        ];

  return {
    listEditColumns,
  };
}
