import React, { useCallback } from 'react';

import { Collapse, FormComponent } from 'components';

import { CitationSystem } from 'types/models/Form';
import { ScientificIndex } from 'types/models/ScientificIndex';
import { State, FieldValue } from '../types';
import { getFieldsFromScientificIndex } from '../helpers';
import { getInitialValidateErrorMessage, getValidateErrorMessage } from '../validate';
import { SelectScientificIndex } from './SelectScientificIndex/SelectScientificIndex';
import { Fields } from './Fields/Fields';
import { Childs } from './Childs/Childs';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { SecondLevelProps } from 'features/Table/types';
import { showNotification } from 'features/Notifications';

function Editor(props: SecondLevelProps<State>) {
  const { customState } = props;
  const {
    mode,
    fields,
    enumMap,
    childFields,
    isOpenEditor,
    citationSystem,
    citationSystems,
    scientificIndexes,
    selectedScientificIndex,
    publicationType,
    setMode,
    setFields,
    setChildFields,
    closeEditor,
    setCitationSystem,
    saveCitationSystemIndex,
    setSelectedScientificIndex,
    setPublicationCitationSystemIndex,
  } = customState;

  const resetState = useCallback(() => {
    setFields({});
    setChildFields([]);
    setCitationSystem(null);
    setSelectedScientificIndex(null);
    setPublicationCitationSystemIndex('');
  }, [setFields, setChildFields, setSelectedScientificIndex, setCitationSystem, setPublicationCitationSystemIndex]);

  const onChangeField = useCallback(
    (fieldId: string, value: FieldValue) => {
      setFields(prevFields => ({ ...prevFields, [fieldId]: { ...prevFields[fieldId], value } }));
    },
    [setFields],
  );

  const onCancel = useCallback(() => {
    resetState();
    setMode(null);
    closeEditor();
  }, [resetState, setMode, closeEditor]);

  const onSave = useCallback(() => {
    const messages = getValidateErrorMessage(fields);
    const initialErrorMessage = getInitialValidateErrorMessage({ selectedScientificIndex, citationSystem });

    if (initialErrorMessage.length) {
      showNotification({ theme: 'danger', message: initialErrorMessage });
      return;
    }

    if (messages.length) {
      showNotification({ theme: 'danger', message: messages.join('<br>') });
      return;
    }
    saveCitationSystemIndex();
    onCancel();
  }, [onCancel, saveCitationSystemIndex, fields, selectedScientificIndex, citationSystem]);

  const onChangeScientificIndex = useCallback(
    (index: ScientificIndex | null) => {
      if (index) {
        const { parentFields } = getFieldsFromScientificIndex(index);
        setFields(parentFields);
      } else if (selectedScientificIndex) {
        resetState();
      }
      setSelectedScientificIndex(index);
    },
    [setSelectedScientificIndex, setFields, resetState, selectedScientificIndex],
  );

  const onChangeCitationSystem = useCallback(
    (system: CitationSystem | null) => {
      setCitationSystem(system);
      onChangeScientificIndex(null);
    },
    [setCitationSystem, onChangeScientificIndex],
  );

  return (
    <Collapse isCollapse={isOpenEditor}>
      <ControlPanel handleSave={onSave} handleCancel={onCancel} />

      <FormComponent.Wrapper>
        <SelectScientificIndex
          citationSystem={citationSystem}
          scientificIndexes={scientificIndexes}
          selectedScientificIndex={selectedScientificIndex}
          citationSystems={citationSystems}
          onChangeCitationSystem={onChangeCitationSystem}
          onChangeScientificIndex={onChangeScientificIndex}
          publicationType={publicationType}
          mode={mode}
        />
        <Fields
          citationSystems={citationSystems}
          citationSystem={citationSystem}
          enumMap={enumMap}
          fields={fields}
          onChangeField={onChangeField}
        />
        <Childs
          enumMap={enumMap}
          childFields={childFields}
          setChildFields={setChildFields}
          citationSystems={citationSystems}
          citationSystem={citationSystem}
          selectedScientificIndex={selectedScientificIndex}
        />
      </FormComponent.Wrapper>
    </Collapse>
  );
}

export { Editor };
