import { Table as T } from 'types/models';
import { Filters } from './filters';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import React from 'react';

export type Props = {
  communitiesFilter: string;
  setCommunitiesFilter: React.Dispatch<string>;
};

export function GetPersonalDissertationCouncilMemberList({ communitiesFilter }: Props): T.Specification {
  return {
    apiID: 'GetPersonalDissertationCouncilMemberList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Работа в составе диссертационных советов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel({ communitiesFilter }),
      },
    },
    isWithQna: true,
  };
}
