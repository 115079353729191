import {
  useMemo,
  useEffect,
  useCallback,
  useState,
  useRef,
  MouseEvent as ReactMouseEvent,
  DragEvent,
  KeyboardEvent,
} from 'react';
import { useTable, Column, usePagination } from 'react-table';
import { getState, useStream } from 'StreamRx';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { Option } from 'components';

import { App as AppEvents } from 'utils/Events';
import { Entry, Column as TableColumn, Data as TableData, CurrentSetting, Setting, State } from 'types/models/Table';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { formatNumber, getHashObject } from 'utils/Helpers';
import {
  CONTROL_KEY,
  EMPTY_ROW_HEIGHT,
  ENTER_KEY,
  exportDataAsXLSX,
  getArrayFromRange,
  getMinWidthByColumn,
  getNextSelectedRowsIndices,
  getRequestColumnData,
  INITIAL_PAGE_INDEX,
  INITIAL_PAGE_SIZE,
  INITIAL_VISIBLE_ITEMS_COUNT,
  isEmptyRow,
  MAX_RESIZE_COLUMN_WIDTH,
  NOT_RESIZABLE_COLUMN_NAMES,
  PAGE_CHANGE_DEBOUNCE_TIME,
  PAGINATION_OPTIONS,
  SCROLL_OFFSET,
  SHIFT_KEY,
} from './helpers';
import {
  ActivateGridSettingArguments,
  LoadDataArguments,
  LoadSettingPresetsArguments,
  ReloadTableArguments,
  SaveAndChangeGridSettingArguments,
  SaveGridSettingsArguments,
  SetColumnFilterArguments,
  UpdateDataArguments,
} from './types';
import { useGridContext } from './context';
import { streams as globalSpecStreams } from 'features/Table/streams';
import { useLocalTableStreams, useLocalStreams } from './hooks';
import { useDebouncedCallback } from 'use-debounce';
import { showNotification } from 'features/Notifications';

const useController = <CustomState extends {} = {}, RequestData extends {} = {}>() => {
  const { specification, hasPersonalMode } = useGridContext<CustomState, RequestData>();
  const [entryPages, setEntryPages] = useState<Entry[]>([]);
  const [nextCurrentPage, setNextCurrentPage] = useState<string>('');
  const [totalRow, setTotalRow] = useState<Entry | null>(null);
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState<number>(0);
  const [gridName, setGridName] = useState<string>('');
  const [allEntriesCount, setAllEntriesCount] = useState<number>(0);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [isDragged, setIsDragged] = useState<boolean>(false);
  const [isExtendable, setIsExtendable] = useState<boolean>(false);
  const [isTextSelectDisabled, setIsTextSelectDisabled] = useState<boolean>(false);
  const [isExtended, setIsExtended] = useState<boolean>(false);
  const [visibleRowsCount, setVisibleRowsCount] = useState<number>(INITIAL_VISIBLE_ITEMS_COUNT);
  const [resizedTableContainerWidth, setResizedTableContainerWidth] = useState<number>(0);
  const [selectedRowIndices, setSelectedRowIndices] = useState<number[]>([]);
  const [isSecondLevelPanelOpen, setIsSecondLevelPanelOpen] = useState<boolean>(false);
  const [currentSetting, setCurrentSetting] = useState<CurrentSetting | null>(null);
  const [settingPresets, setSettingPresets] = useState<Setting[]>([]);
  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);
  const [reorderedColumnName, setReorderedColumnName] = useState<string | null>(null);
  const [dragOverColumnName, setDragOverColumnName] = useState<string | null>(null);

  const firstSelectedRowRef = useRef<HTMLTableRowElement | null>(null);
  const tableContainer = useRef<HTMLDivElement | null>(null);
  const resizerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  const getColumnIndexByAccessor = useCallback((accessor: string): number => columns.findIndex(({ name }) => name === accessor), [
    columns,
  ]);

  const streams = useLocalTableStreams();
  const specStreams = useLocalStreams(globalSpecStreams);

  const preparedColumns = useMemo<Column<Entry>[]>(
    () =>
      columns
        .filter(({ hidden }) => !hidden)
        .map(column => {
          const isNeedFormatNumber = column.type === 'MONEY';
          const footerTotalRowText = totalRow?.[column.name]
            ? isNeedFormatNumber
              ? formatNumber(totalRow[column.name])
              : totalRow[column.name]
            : '';

          return {
            Header: column.caption || '',
            Footer: footerTotalRowText,
            accessor: column.name,
            width: column.width,
            sortType: column.sortAscending,
          };
        }),
    [columns, totalRow],
  );

  const {
    rows,
    state: { pageIndex, pageSize },
    pageCount,
    canNextPage,
    pageOptions,
    footerGroups,
    headerGroups,
    canPreviousPage,
    gotoPage,
    prepareRow,
    setPageSize,
    getTableProps,
    getTableBodyProps,
  } = useTable(
    {
      data: entryPages,
      manualPagination: true,
      columns: preparedColumns,
      pageCount: controlledPageCount,
      initialState: { pageIndex: INITIAL_PAGE_INDEX, pageSize: INITIAL_PAGE_SIZE },
    },
    usePagination,
  );

  const selectedRows = useMemo(
    () => entryPages.filter((_, index) => selectedRowIndices.some(selectedRowIndex => selectedRowIndex === index)),
    [entryPages, selectedRowIndices],
  );

  const { isWithQna, isWithExport } = specification;

  const { methods: getGridDataAPI, state: getGridDataAPIState } = BackendAPI.useBackendAPI(specification.apiID);
  const { methods: getXLSXExportAPI, state: getXLSXExportAPIState } = BackendAPI.useBackendAPI(specification.apiID);
  const { methods: saveGridSettingAPI } = BackendAPI.useBackendAPI('SaveGridSetting');
  const { methods: getSettingPresetsAPI } = BackendAPI.useBackendAPI('GetAllGridSettings');
  const { methods: activateSettingAPI } = BackendAPI.useBackendAPI('ActivateGridSetting');

  const { methods: deleteModelAPI, state: deleteModelAPIState } = BackendAPI.useBackendAPI('DeleteModel');
  const { methods: approveItemAPI, state: approveItemAPIState } = BackendAPI.useBackendAPI('ApproveItem');

  const [tableState, setTableState] = useState<State>({
    selectedRows: [],
    isDisabledAdd: undefined,
    uniqID: undefined,
  });

  const saveGridSettings = useCallback(
    ({ columns: columnsToSaveSetting, settingName, shared, purpose, settingID }: SaveGridSettingsArguments) =>
      new Promise<void>((resolve, reject) => {
        saveGridSettingAPI.callAPI(
          {
            gridName,
            columns: columnsToSaveSetting,
            settingName,
            shared,
            purpose,
            settingID,
          },
          {
            onSuccessfullCall: () => {
              resolve();
            },
            onFailedCall: () => {
              reject(new Error('save grid settings error'));
            },
          },
        );
      }),
    [gridName, saveGridSettingAPI],
  );

  const loadData = useCallback(
    (loadDataArguments: LoadDataArguments) =>
      new Promise<TableData>((resolve, reject) => {
        const apiMethod = loadDataArguments.isExportXlsx ? getXLSXExportAPI : getGridDataAPI;
        apiMethod.callAPI(
          {
            requestColumnData: loadDataArguments.isExportXlsx
              ? loadDataArguments.columns
              : getRequestColumnData(loadDataArguments.columns || []),
            rowQuantity: loadDataArguments.pageSize,
            requestData: specification.requestData,
            //TODO: legacy. Сделать определение relatedRecordId в requestData
            relatedRecordId: specification.templatesTableDependencies?.relatedRecordId || '',
            isTableExtended: loadDataArguments.isExtended,
            isFullRefresh: loadDataArguments.isFullRefresh,
            hasSetting: loadDataArguments.hasSetting === undefined ? Boolean(currentSetting) : loadDataArguments.hasSetting,
            offset: loadDataArguments.pageSize && loadDataArguments.pageIndex * loadDataArguments.pageSize,
            panelState: loadDataArguments.panelState || getState(specStreams.getPanelState),
          },
          {
            onSuccessfullCall: ({ data: gridResponseData }) => {
              resolve(gridResponseData as TableData);
            },
            onFailedCall: () => {
              reject(new Error('get grid data error'));
            },
          },
        );
      }),
    [
      currentSetting,
      getGridDataAPI,
      getXLSXExportAPI,
      specStreams.getPanelState,
      specification.requestData,
      specification.templatesTableDependencies?.relatedRecordId,
    ],
  );

  const loadSettingPresets = useCallback(
    (loadSettingPresetsArguments: LoadSettingPresetsArguments) =>
      new Promise<Setting[]>((resolve, reject) => {
        getSettingPresetsAPI.callAPI(
          {
            relatedGridName: loadSettingPresetsArguments.gridName,
            isCurrentUserSettingsOnly: false,
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => reject(new Error('Ошибка при загрузке настроек пользователя')),
          },
        );
      }),
    [getSettingPresetsAPI],
  );

  const activateSetting = useCallback(
    ({ settingName, author }: ActivateGridSettingArguments) =>
      new Promise<void>((resolve, reject) => {
        activateSettingAPI.callAPI(
          {
            gridName,
            settingName,
            author,
          },
          {
            onSuccessfullCall: () => {
              resolve();
            },
            onFailedCall: () => {
              reject(new Error('Ошибка при активации настройки'));
            },
          },
        );
      }),
    [activateSettingAPI, gridName],
  );

  const updateData = useCallback(
    async (updateDataArguments: UpdateDataArguments) => {
      try {
        const gridResponseData = await loadData(updateDataArguments);
        tableContainer.current?.scrollTo({ top: 0 });
        setVisibleRowsCount(INITIAL_VISIBLE_ITEMS_COUNT);
        if (updateDataArguments.isFullRefresh) {
          setColumns(gridResponseData.columns);
        }
        setIsExtended(updateDataArguments.isExtended);
        gotoPage(updateDataArguments.pageIndex);
        setPageSize(updateDataArguments.pageSize);
        setEntryPages(gridResponseData.entries as Entry[]);
        setIsExtendable(gridResponseData.isTableExtendable);
        setGridName(gridResponseData.gridName);
        setTotalRow(Object.keys(gridResponseData.totalEntry).length ? gridResponseData.totalEntry : null);
        const nextControlledPageCount = Math.ceil(gridResponseData.entriesNumber / updateDataArguments.pageSize);
        setControlledPageCount(nextControlledPageCount);
        setAllEntriesCount(gridResponseData.entriesNumber);
        setCurrentSetting(gridResponseData.setting);
        if (!updateDataArguments.needResetSelectedRows) {
          const nextSelectedRowIndices = getNextSelectedRowsIndices({
            rows: gridResponseData.entries,
            selectedRows,
            uniqKeys: specification.uniqKeys,
          });
          setSelectedRowIndices(nextSelectedRowIndices);

          const [firstSelectedRowIndex] = nextSelectedRowIndices;

          if (typeof firstSelectedRowIndex === 'number') {
            const isFirstSelectedRowIndexInView = firstSelectedRowIndex + 1 <= INITIAL_VISIBLE_ITEMS_COUNT;
            if (!isFirstSelectedRowIndexInView) {
              const nextVisibleRowsCount =
                INITIAL_VISIBLE_ITEMS_COUNT * Math.ceil(firstSelectedRowIndex + 1 / INITIAL_VISIBLE_ITEMS_COUNT);
              setVisibleRowsCount(nextVisibleRowsCount);
            }
            if (firstSelectedRowRef.current && updateDataArguments.needScrollToFirstSelectedRow) {
              firstSelectedRowRef.current.scrollIntoView({ block: 'center' });
            }
          }
        } else {
          setSelectedRowIndices([]);
        }

        if (tableContainer.current && tableRef.current) {
          const tableContainerHeight = tableContainer.current.getBoundingClientRect().height;
          const childHeight = Array.from(tableRef.current.children).reduce<number>(
            (accum, current) => accum + current.getBoundingClientRect().height,
            0,
          );
          const emptyHeight = tableContainerHeight - childHeight;
          const hasEmptyHeight = emptyHeight > 0;
          const nextEmptyRowsCount = hasEmptyHeight ? Math.ceil(emptyHeight / EMPTY_ROW_HEIGHT) : 0;
          if (nextEmptyRowsCount) {
            const nextEmptyRows: Entry[] = [];
            for (let i = 0; i < nextEmptyRowsCount; i += 1) {
              nextEmptyRows.push({ EMPTY_ROW: 'EMPTY_ROW' });
            }

            setEntryPages([...gridResponseData.entries, ...nextEmptyRows]);
          }
        }

        return {
          entryPages: gridResponseData.entries,
          gridName: gridResponseData.gridName,
        };
      } catch (e) {
        console.error('update data error', e); // eslint-disable-line
      }
    },
    [gotoPage, loadData, selectedRows, setPageSize, specification.uniqKeys],
  );

  const resizeTableContainerObserver = useMemo(
    () =>
      new ResizeObserver(([table]) => {
        setResizedTableContainerWidth(table.target.clientWidth);
      }),
    [],
  );

  const visibleColumnsWidth = useMemo(
    () => columns.reduce((accum, { hidden, width }) => accum + (hidden ? 0 : parseFloat(width)), 0),
    [columns],
  );

  useEffect(() => {
    const newTableState = {
      selectedRows: selectedRows,
      isDisabledAdd: specification.templatesTableDependencies?.relatedTableState?.isDisabledAdd,
      uniqID: specification.uniqID,
    };
    if (getHashObject(tableState) !== getHashObject(newTableState)) {
      setTableState(newTableState);
    }
  }, [
    selectedRows,
    specification.templatesTableDependencies?.relatedTableState?.isDisabledAdd,
    specification.uniqID,
    tableState,
  ]);

  useEffect(() => {
    streams.updateEntryPagesCount.push({ nextEntryCount: allEntriesCount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEntriesCount]);

  useEffect(() => {
    const isTableContainerWidthMoreThanVisibleColumnsWidth = visibleColumnsWidth < resizedTableContainerWidth;
    const columnsToResize = columns.filter(
      ({ hidden, name }) =>
        !hidden && NOT_RESIZABLE_COLUMN_NAMES.every(notResizableColumnName => name !== notResizableColumnName),
    );

    if (isTableContainerWidthMoreThanVisibleColumnsWidth && columnsToResize.length && gridName && saveGridSettingAPI) {
      const restSpace = resizedTableContainerWidth - visibleColumnsWidth;

      const widthToAdd = restSpace / columnsToResize.length;

      const columnsToSet = columns.map(column => ({
        ...column,
        ...(columnsToResize.some(({ name }) => column.name === name)
          ? { width: `${parseFloat(column.width) + widthToAdd}` }
          : {}),
      }));

      setColumns(columnsToSet);
      // if (!currentSetting?.name) {
      //   saveGridSettings({ columns: columnsToSet });
      // }
    }
  }, [
    resizedTableContainerWidth,
    visibleColumnsWidth,
    columns.length,
    preparedColumns,
    columns,
    saveGridSettings,
    currentSetting?.name,
    gridName,
    saveGridSettingAPI,
  ]);

  useEffect(() => {
    if (tableContainer?.current) {
      resizeTableContainerObserver.observe(tableContainer.current);
    }
    return () => {
      resizeTableContainerObserver.disconnect();
    };
  }, [resizeTableContainerObserver]);

  const visibleRows = useMemo(() => rows.slice(0, visibleRowsCount), [rows, visibleRowsCount]);

  const checkIsNeedRenderRestItmes = useCallback(() => {
    if (tableContainer.current) {
      const isNeedRenderRestItems =
        tableContainer.current.offsetHeight + tableContainer.current.scrollTop >=
        tableContainer.current.scrollHeight - SCROLL_OFFSET;
      const isAllItemsInPage = visibleRowsCount >= rows.length;
      if (isNeedRenderRestItems && !isAllItemsInPage) {
        return true;
      }
    }
    return false;
  }, [rows.length, visibleRowsCount]);

  const createResizeColumnCallback = useCallback(
    (columnName: string) => (mouseDownEvent: ReactMouseEvent<HTMLButtonElement>) => {
      if (!resizerRef.current || !tableContainer.current) {
        return;
      }

      setIsResizing(true);

      const nextActiveResizeColumnIndex = getColumnIndexByAccessor(columnName);
      const { clientX: startClientX } = mouseDownEvent;
      const containerRect = tableContainer.current.getBoundingClientRect();
      const resizerRefPosition = startClientX - containerRect.left;
      resizerRef.current.style.left = `${resizerRefPosition}px`;
      const resizedColumn = columns[nextActiveResizeColumnIndex];
      const initialColumnWidth = parseFloat(resizedColumn.width);
      const neightbourPreparedColumnIndex = preparedColumns.findIndex(({ accessor }) => accessor === columnName) + 1;
      const neightbourPreparedColumn = preparedColumns[neightbourPreparedColumnIndex];

      const neightbourColumnIndex = getColumnIndexByAccessor(neightbourPreparedColumn?.accessor?.toString() ?? '');

      let nextColumnWidth: number = initialColumnWidth;
      let neightbourColumnWidth: number | null = null;

      const onMouseMove = (mouseMoveEvent: MouseEvent) => {
        mouseMoveEvent.preventDefault();
        if (!resizerRef.current || !tableContainer.current) {
          return;
        }
        const { clientX: moveClientX } = mouseMoveEvent;
        let width = moveClientX - startClientX;

        const isNextWidthLessThan0 = width < 0;

        if (isNextWidthLessThan0) {
          width = Math.abs(width);
          nextColumnWidth = initialColumnWidth - width;

          const isLastShowedColumn = preparedColumns[preparedColumns.length - 1].accessor === columnName;
          const nextTableWidthWithotCurrentColumn = preparedColumns
            .filter(({ accessor }) => accessor !== columnName)
            .reduce((accum, { width: preparedColumnWidth }) => parseFloat(`${preparedColumnWidth ?? 0}`) + accum, 0);

          const tableContainerWidth = tableContainer.current?.clientWidth;

          const nextTableWidth = nextTableWidthWithotCurrentColumn + nextColumnWidth;

          const isTableWidthLessThanContainerWidth = nextTableWidth < tableContainerWidth;
          let columnMinWidth = getMinWidthByColumn(resizedColumn);

          if (isTableWidthLessThanContainerWidth && isLastShowedColumn) {
            columnMinWidth = tableContainerWidth - nextTableWidthWithotCurrentColumn;
          }

          if (nextColumnWidth < columnMinWidth) {
            nextColumnWidth = columnMinWidth;
            width = initialColumnWidth - columnMinWidth;
          }

          if (isTableWidthLessThanContainerWidth && !isLastShowedColumn) {
            const currentNeightbourWidth = parseFloat(columns[neightbourColumnIndex].width);
            neightbourColumnWidth = currentNeightbourWidth + width;
          } else {
            neightbourColumnWidth = null;
          }

          resizerRef.current.style.transform = 'scaleX(-1) translate3d(0, 0, 0)';
          resizerRef.current.style.left = `${resizerRefPosition - width}px`;
        } else {
          nextColumnWidth = initialColumnWidth + width;
          const isNextColumnWidthMoreThanMaxResizeColumnWidth = nextColumnWidth >= initialColumnWidth + MAX_RESIZE_COLUMN_WIDTH;

          if (isNextColumnWidthMoreThanMaxResizeColumnWidth) {
            nextColumnWidth = initialColumnWidth + MAX_RESIZE_COLUMN_WIDTH;
            width = MAX_RESIZE_COLUMN_WIDTH;
          }

          resizerRef.current.style.transform = 'translate3d(0, 0, 0)';
          resizerRef.current.style.left = `${resizerRefPosition}px`;
        }

        resizerRef.current.style.width = `${width}px`;
      };

      const onMouseUp = () => {
        if (!resizerRef.current || !tableContainer.current) {
          return;
        }

        setIsResizing(false);
        resizerRef.current.style.left = `0px`;
        resizerRef.current.style.width = `0px`;
        const nextColumns = columns.map((prevColumn, index) => ({
          ...prevColumn,
          ...(index === nextActiveResizeColumnIndex ? { width: `${nextColumnWidth}` } : {}),
          ...(neightbourColumnWidth !== null && index === neightbourColumnIndex ? { width: `${neightbourColumnWidth}` } : {}),
        }));
        setColumns(nextColumns);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        if (!currentSetting?.name) {
          saveGridSettings({ columns: nextColumns });
        }
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [columns, currentSetting?.name, getColumnIndexByAccessor, preparedColumns, saveGridSettings],
  );

  const createOnDropCallback = useCallback(
    (columnName: string) => (dropEvent: DragEvent) => {
      dropEvent.stopPropagation();
      const columnIndex = getColumnIndexByAccessor(columnName);
      const movedElementIndex = parseInt(dropEvent.dataTransfer.getData('text/plain'), 10) || 0;
      const reorderedColumns = R.clone(columns);
      reorderedColumns.splice(columnIndex, 0, reorderedColumns.splice(movedElementIndex, 1)[0]);
      const preparedReorderedColumns = reorderedColumns.map((reorderedColumn, reorderedColumnIndex) => ({
        ...reorderedColumn,
        order: `${reorderedColumnIndex}`,
      }));
      setDragOverColumnName(null);
      setReorderedColumnName(null);
      setColumns(preparedReorderedColumns);
      if (!currentSetting?.name) {
        saveGridSettings({ columns: preparedReorderedColumns });
      }
    },
    [columns, currentSetting?.name, getColumnIndexByAccessor, saveGridSettings],
  );

  const createDragOverCallback = useCallback(
    (columnName: string) => (dragOverEvent: DragEvent) => {
      dragOverEvent.stopPropagation();
      dragOverEvent.preventDefault();
      if (columnName !== reorderedColumnName) {
        setDragOverColumnName(columnName);
      }
    },
    [reorderedColumnName],
  );

  const onDragLeave = useCallback((dragLeaveEvent: DragEvent) => {
    dragLeaveEvent.stopPropagation();
    dragLeaveEvent.preventDefault();
    setDragOverColumnName(null);
  }, []);

  const createDragStartCallback = useCallback(
    (columnName: string) => (dragStartEvent: DragEvent) => {
      setIsDragged(true);
      const columnIndex = getColumnIndexByAccessor(columnName);
      setReorderedColumnName(columnName);
      dragStartEvent.dataTransfer.setData('text/plain', columnIndex.toString());
    },
    [getColumnIndexByAccessor],
  );

  const onDragEnd = useCallback(() => {
    setIsDragged(false);
  }, []);

  const initialLoad = useCallback(async () => {
    const gridData = await updateData({ pageIndex, pageSize, isExtended, isFullRefresh: true });
    if (gridData) {
      const settingPresetsData = await loadSettingPresets({
        gridName: gridData.gridName,
        isExtended: false,
      });
      setSettingPresets(settingPresetsData);
      if (specification.initialSelectedRows) {
        const { uniqKey, selectedValues } = specification.initialSelectedRows;
        const selectedIndices = gridData.entryPages.reduce<number[]>((accum, row, columnIndex) => {
          const isMatchSomeInitialSelectedRowValue = selectedValues.some(selectedValue => row[uniqKey] === selectedValue);
          if (isMatchSomeInitialSelectedRowValue) {
            return [...accum, columnIndex];
          }

          return accum;
        }, []);
        setSelectedRowIndices(selectedIndices);
      }
    }
  }, [isExtended, loadSettingPresets, pageIndex, pageSize, specification.initialSelectedRows, updateData]);

  useEffect(() => {
    setIsFiltersShown(!specification.isFiltersHidden);
    if (!specification.isNotInitialLoadTable) {
      initialLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleIsExtended = useCallback(async () => {
    const nextIsExtended = !isExtended;
    const gridData = await updateData({ pageIndex: 0, pageSize, isExtended: nextIsExtended, isFullRefresh: true });
    if (gridData) {
      const settingPresetsData = await loadSettingPresets({ gridName: gridData.gridName, isExtended: nextIsExtended });
      setSettingPresets(settingPresetsData);
    }
  }, [isExtended, loadSettingPresets, pageSize, updateData]);

  const isLoading = useMemo(
    () =>
      getIsPending(getGridDataAPIState) ||
      getIsPending(deleteModelAPIState) ||
      getIsPending(approveItemAPIState) ||
      getIsPending(getXLSXExportAPIState),
    [approveItemAPIState, deleteModelAPIState, getGridDataAPIState, getXLSXExportAPIState],
  );

  const onContainerScroll = useCallback(() => {
    const isNeedRenderRestItems = checkIsNeedRenderRestItmes();
    if (isNeedRenderRestItems) {
      setVisibleRowsCount(visibleRowsCount + INITIAL_VISIBLE_ITEMS_COUNT);
    }
  }, [checkIsNeedRenderRestItmes, visibleRowsCount]);

  const onHiddenColumnsChange = useCallback(
    async (nextHiddenColumnNames: string[]) => {
      const isSomeHiddenColumnHasCustomSetting = columns.some(
        ({ name, sortAscending, filter }) =>
          nextHiddenColumnNames.some(hiddenColumnName => hiddenColumnName === name) && (sortAscending || filter),
      );

      const nextColumns: TableColumn[] = columns.map(prevColumn => {
        const isHiddenColumn = nextHiddenColumnNames.some(hiddenColumnName => hiddenColumnName === prevColumn.name);

        return {
          ...prevColumn,
          hidden: isHiddenColumn,
          sortAscending: isHiddenColumn ? undefined : prevColumn.sortAscending,
          sortOrder: isHiddenColumn ? undefined : prevColumn.sortOrder,
          filter: isHiddenColumn ? undefined : prevColumn.filter,
        };
      });

      setColumns(nextColumns);
      if (!currentSetting?.name) {
        await saveGridSettings({ columns: nextColumns });
      }

      if (isSomeHiddenColumnHasCustomSetting) {
        await updateData({ pageSize, pageIndex, columns: nextColumns, isExtended, isFullRefresh: false });
      }
    },
    [columns, currentSetting?.name, saveGridSettings, updateData, pageSize, pageIndex, isExtended],
  );

  const createSortColumnCallback = useCallback(
    (columnName: string) => () => {
      const columnIndex = getColumnIndexByAccessor(columnName);
      const isAscSorting = columns[columnIndex]?.sortAscending === 'true';
      const isDescSorting = columns[columnIndex]?.sortAscending === 'false';
      const currentMaxSortOrder =
        Math.max(...columns.map(({ sortOrder }) => (typeof sortOrder === 'number' ? sortOrder : -1))) ?? -1;
      const hasSortOrder = typeof columns[columnIndex]?.sortOrder === 'number';

      let nextSort: undefined | 'true' | 'false';
      let nextSortOrder: number | undefined;
      if (isAscSorting) {
        nextSort = 'false';
        nextSortOrder = hasSortOrder ? columns[columnIndex]?.sortOrder : currentMaxSortOrder + 1;
      } else if (isDescSorting) {
        nextSort = undefined;
      } else {
        nextSortOrder = hasSortOrder ? columns[columnIndex]?.sortOrder : currentMaxSortOrder + 1;
        nextSort = 'true';
      }

      const nextColumns = columns.map((column, index) => ({
        ...column,
        ...(index === columnIndex
          ? {
              sortOrder: nextSortOrder,
              sortAscending: nextSort,
            }
          : {}),
      }));

      setColumns(nextColumns);
      updateData({ pageIndex, pageSize, columns: nextColumns, isExtended, isFullRefresh: false });
    },
    [columns, getColumnIndexByAccessor, pageIndex, pageSize, updateData, isExtended],
  );

  const getColumnSortType = useCallback(
    (columnName: string) => {
      const columnIndex = getColumnIndexByAccessor(columnName);
      let sortType: undefined | 'asc' | 'desc';

      switch (columns[columnIndex]?.sortAscending) {
        case 'true':
          sortType = 'asc';
          break;
        case 'false':
          sortType = 'desc';
          break;
        default:
          break;
      }
      return sortType;
    },
    [columns, getColumnIndexByAccessor],
  );

  const setColumnFilter = useCallback(
    ({ columnName, nextValue }: SetColumnFilterArguments) => {
      const nextColumns: TableColumn[] = columns.map(column => ({
        ...column,
        ...(column.name === columnName ? { filter: nextValue } : {}),
      }));

      setColumns(nextColumns);
      const nextPageIndex = 0;
      updateData({ pageIndex: nextPageIndex, pageSize, columns: nextColumns, isExtended, isFullRefresh: false });
    },
    [columns, pageSize, updateData, isExtended],
  );

  const onPageChange = useCallback(
    (nextPageIndex: number) => {
      updateData({ pageIndex: nextPageIndex, pageSize, columns, isExtended, isFullRefresh: false });
    },
    [columns, pageSize, updateData, isExtended],
  );

  const onPageSizeChange = useCallback(
    (nextPageSize: number) => {
      const nextPageIndex = 0;
      updateData({ pageIndex: nextPageIndex, pageSize: nextPageSize, columns, isExtended, isFullRefresh: false });
    },
    [columns, updateData, isExtended],
  );

  const createOnRowClick = useCallback(
    (index: number) => (clickEvent: ReactMouseEvent<HTMLTableRowElement>) => {
      clickEvent.preventDefault();

      const isEmpty = isEmptyRow(entryPages[index]);

      if (isEmpty) {
        return;
      }

      const { ctrlKey, shiftKey } = clickEvent;
      const isAlredySelected = selectedRowIndices.some(selectedRowIndex => selectedRowIndex === index);
      if (ctrlKey && !specification.isCanSelectOnlyOneRow) {
        const nextSelectedRowIndices = isAlredySelected
          ? selectedRowIndices.filter(selectedRowIndex => selectedRowIndex !== index)
          : [...selectedRowIndices, index];
        setSelectedRowIndices(nextSelectedRowIndices);
      } else if (shiftKey && !specification.isCanSelectOnlyOneRow) {
        const [firstSelectedRowIndex] = selectedRowIndices;
        const lastSelectedRowIndex = selectedRowIndices[selectedRowIndices.length - 1];
        let preparedFirstSelectedRowIndex = typeof firstSelectedRowIndex === 'number' ? firstSelectedRowIndex : 0;
        preparedFirstSelectedRowIndex =
          index <= preparedFirstSelectedRowIndex && typeof lastSelectedRowIndex === 'number'
            ? lastSelectedRowIndex
            : preparedFirstSelectedRowIndex;

        const nextSelectedRowIndices = getArrayFromRange({ from: preparedFirstSelectedRowIndex, to: index });
        setSelectedRowIndices(nextSelectedRowIndices);
      } else {
        const isManySelected = selectedRowIndices.length > 1;
        if (isAlredySelected && isManySelected) {
          setSelectedRowIndices([index]);
        } else if (isAlredySelected) {
          setSelectedRowIndices([]);
        } else {
          setSelectedRowIndices([index]);
        }
      }
    },
    [entryPages, selectedRowIndices, specification.isCanSelectOnlyOneRow],
  );

  useStream(
    () => streams.loadInitialTable,
    () => {
      initialLoad();
    },
    [initialLoad],
  );

  useStream(
    () => streams.updateFirstLevelPanelState,
    data => {
      updateData({ pageIndex: 0, pageSize, columns, isExtended, panelState: data.state, isFullRefresh: false });
    },
    [updateData, pageSize, isExtended, columns],
  );

  const reloadTable = useCallback(
    async ({ needResetSelectedRows, needScrollToFirstSelectedRow }: ReloadTableArguments = {}) => {
      await updateData({
        pageIndex,
        pageSize,
        columns,
        isExtended,
        isFullRefresh: false,
        needResetSelectedRows,
        needScrollToFirstSelectedRow,
      });
    },
    [columns, isExtended, pageIndex, pageSize, updateData],
  );

  useStream(
    () => streams.reloadTable,
    () => {
      reloadTable({ needScrollToFirstSelectedRow: true });
    },
    [reloadTable],
  );

  useStream(
    () => streams.toggleSecondLevelPanel,
    () => setIsSecondLevelPanelOpen(prev => !prev),
    [],
  );

  useStream(
    () => streams.approveRow,
    async ({ approveRowId, command, approveItemName, approveInnerItemName, disapprove = false }) => {
      approveItemAPI.callAPI(
        {
          approveRowId,
          command,
          approveItemName,
          approveInnerItemName,
          disapprove,
        },
        {
          onSuccessfullCall: () => {
            reloadTable({ needScrollToFirstSelectedRow: true });
            showNotification({ message: 'Утверждение прошло успешно', theme: 'success' });
          },
        },
      );
    },
    [reloadTable],
  );

  useStream(
    () => streams.deleteRow,
    async ({ deleteRowId, command, deletedItemPropName }) => {
      deleteModelAPI.callAPI(
        {
          rowId: deleteRowId,
          commandName: command,
          entityName: deletedItemPropName,
        },
        {
          onSuccessfullCall: ({ data }) => {
            const isDeleted = data.isDeleted === null || data.isDeleted;
            if (isDeleted) {
              showNotification({ message: 'Запись успешно удалена', theme: 'success' });
              reloadTable();
            } else {
              showNotification({ message: 'Произошла ошибка при удалении записи', theme: 'danger' });
            }
          },
        },
      );
    },
    [reloadTable],
  );

  // Catch resize event with detail.isZoom as boolean
  const reloadTableDebounce = useDebouncedCallback(() => {
    reloadTable();
  }, 500);

  const handleEvent = useCallback(
    ev => {
      if (ev.detail.isZoom) {
        reloadTableDebounce();
      }
    },
    [reloadTableDebounce],
  );

  useEffect(() => {
    document.addEventListener(AppEvents.RESIZING, handleEvent);
    return () => {
      document.removeEventListener(AppEvents.RESIZING, handleEvent);
    };
  }, [handleEvent]);

  const onTableKeyDown = useCallback((keyDownEvent: KeyboardEvent) => {
    const isShiftKey = keyDownEvent.key === SHIFT_KEY;
    if (isShiftKey) {
      setIsTextSelectDisabled(true);
    }
  }, []);

  const onTableKeyUp = useCallback((keyUpEvent: KeyboardEvent) => {
    const isShiftKey = keyUpEvent.key === SHIFT_KEY;
    const isControlKey = keyUpEvent.key === CONTROL_KEY;
    if (isShiftKey || isControlKey) {
      setIsTextSelectDisabled(false);
    }
  }, []);

  const onTableBlur = useCallback(() => {
    setIsTextSelectDisabled(false);
  }, []);

  const onTableKeyPress = useCallback(
    (keyPressEvent: KeyboardEvent) => {
      keyPressEvent.stopPropagation();
      const { key } = keyPressEvent;
      const isEnterKeyDown = key === ENTER_KEY;
      if (isEnterKeyDown && selectedRows.length) {
        if (specification.onSubmitTable) {
          specification.onSubmitTable({ selectedRows });
        } else {
          streams.submitTable.push({ selectedRows });
        }
      }
    },
    [selectedRows, specification, streams.submitTable],
  );

  const createOnRowDoubleClick = useCallback(
    (index: number) => () => {
      const isEmpty = isEmptyRow(entryPages[index]);

      if (isEmpty) {
        return;
      }

      const nextSelectedRows = [entryPages[index]];
      setSelectedRowIndices([index]);

      if (specification.onSubmitTable) {
        specification.onSubmitTable({ selectedRows: nextSelectedRows });
      } else {
        streams.submitTable.push({ selectedRows: nextSelectedRows });
      }
    },
    [entryPages, specification, streams.submitTable],
  );

  const onSettingChange = useCallback(
    async ({ settingName, author }: ActivateGridSettingArguments) => {
      const isCurrentSetting =
        Boolean(currentSetting) && settingName === currentSetting?.name && author === currentSetting?.author;
      if (!isCurrentSetting) {
        await activateSetting({ settingName, author });
        await updateData({ pageSize, pageIndex: 0, isExtended, isFullRefresh: true, hasSetting: Boolean(settingName) });
      }
    },
    [activateSetting, currentSetting, isExtended, pageSize, updateData],
  );

  const saveAndChangeGridSetting = useCallback(
    async ({ purpose, settingID, settingName, shared, author }: SaveAndChangeGridSettingArguments) => {
      await saveGridSettings({ columns, settingName, shared, purpose, settingID });
      if (settingName && settingName !== currentSetting?.name) {
        await activateSetting({ settingName, author });
        await updateData({ pageSize, pageIndex: 0, isExtended, isFullRefresh: true, hasSetting: true });
      }
    },
    [activateSetting, columns, currentSetting?.name, isExtended, pageSize, saveGridSettings, updateData],
  );

  const reloadSettingPresets = useCallback(async () => {
    const settingPresetsData = await loadSettingPresets({ gridName, isExtended });
    setSettingPresets(settingPresetsData);
  }, [gridName, isExtended, loadSettingPresets]);

  const getXlsxFile = useCallback(async () => {
    const gridData = await loadData({
      isExtended,
      isExportXlsx: true,
      isFullRefresh: false,
      pageIndex: 0,
      columns,
      hasSetting: Boolean(currentSetting),
    });

    exportDataAsXLSX({ columns: gridData.columns, entries: gridData.entries });
  }, [columns, currentSetting, isExtended, loadData]);

  const toggleIsFiltersShown = useCallback(() => {
    setIsFiltersShown(!isFiltersShown);
  }, [isFiltersShown]);

  const resetFilters = useCallback(async () => {
    const columnsWithoutFilter = columns.map(column => ({ ...column, filter: undefined }));
    await updateData({
      columns: columnsWithoutFilter,
      isExtended,
      hasSetting: Boolean(currentSetting?.name),
      pageIndex: 0,
      pageSize,
      isFullRefresh: false,
    });
    setColumns(columnsWithoutFilter);
  }, [columns, currentSetting?.name, isExtended, pageSize, updateData]);

  const customState: CustomState =
    specification.useCustomController?.({
      selectedRows,
      entryPages,
      dataCallStateKind: getGridDataAPIState.kind,
    }) || ({} as CustomState);

  const pageChangeDebouncer = useDebouncedCallback((nextPageIndex: number) => {
    onPageChange(nextPageIndex);
  }, PAGE_CHANGE_DEBOUNCE_TIME);

  const onNextCurrentPageChange = useCallback(
    (nextPage: string) => {
      const parsedNextPage = parseInt(nextPage, 10) || 0;
      const preparedNextPage = parsedNextPage ? parsedNextPage - 1 : 0;

      pageChangeDebouncer(preparedNextPage);
      setNextCurrentPage(nextPage);
    },
    [pageChangeDebouncer],
  );

  const selectedPaginationOption: Option<number> | null = useMemo(() => {
    return PAGINATION_OPTIONS.find(({ value }) => value === pageSize) ?? null;
  }, [pageSize]);

  const getIsDragOverColumn = useCallback((columnName: string) => columnName === dragOverColumnName, [dragOverColumnName]);
  const getIsLeftDropIndicator = useCallback(
    (columnName: string) => {
      if (reorderedColumnName) {
        const columnIndex = getColumnIndexByAccessor(columnName);
        const draggedColumnIndex = getColumnIndexByAccessor(reorderedColumnName);

        const isLeftDropIndicator = columnIndex < draggedColumnIndex;
        return isLeftDropIndicator;
      }

      return false;
    },
    [getColumnIndexByAccessor, reorderedColumnName],
  );

  return {
    columns,
    tableRef,
    gridName,
    totalRow,
    pageIndex,
    pageCount,
    isDragged,
    isLoading,
    isWithQna,
    isExtended,
    resizerRef,
    isResizing,
    tableState,
    visibleRows,
    canNextPage,
    pageOptions,
    customState,
    selectedRows,
    isExtendable,
    headerGroups,
    footerGroups,
    isWithExport,
    specification,
    tableContainer,
    currentSetting,
    settingPresets,
    isFiltersShown,
    allEntriesCount,
    hasPersonalMode,
    canPreviousPage,
    nextCurrentPage,
    selectedRowIndices,
    visibleColumnsWidth,
    firstSelectedRowRef,
    reorderedColumnName,
    isTextSelectDisabled,
    isSecondLevelPanelOpen,
    selectedPaginationOption,
    onDragEnd,
    prepareRow,
    onTableBlur,
    getXlsxFile,
    onDragLeave,
    reloadTable,
    onPageChange,
    onTableKeyUp,
    resetFilters,
    getTableProps,
    onTableKeyDown,
    onTableKeyPress,
    onSettingChange,
    setColumnFilter,
    toggleIsExtended,
    onPageSizeChange,
    createOnRowClick,
    getTableBodyProps,
    getColumnSortType,
    onContainerScroll,
    getIsDragOverColumn,
    toggleIsFiltersShown,
    createOnDropCallback,
    reloadSettingPresets,
    onHiddenColumnsChange,
    createOnRowDoubleClick,
    getIsLeftDropIndicator,
    createDragOverCallback,
    onNextCurrentPageChange,
    createDragStartCallback,
    createSortColumnCallback,
    saveAndChangeGridSetting,
    getColumnIndexByAccessor,
    createResizeColumnCallback,
  };
};

export default useController;
