import React, { memo, useCallback, useMemo, useState } from 'react';

import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, ConfirmPopup } from 'components';

type Props = {
  isRequestMode?: boolean;
};

export const Toolbar = ({ isRequestMode }: Props) =>
  memo(() => {
    const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

    const handleHelpButtonClick = useCallback(() => {
      setIsHelpFormOpen(true);
    }, []);
    const handleTemplateCloseHelpForm = useCallback(() => {
      setIsHelpFormOpen(false);
    }, []);
    const buttons = useMemo<IconButtonProps[]>(
      () => [
        {
          icons: buttonIcons.toolbarHelp,
          title: 'Помощь',
          code: 'personEmail',
          isDisabled: false,
          onClick: handleHelpButtonClick,
        },
      ],
      [handleHelpButtonClick],
    );

    return (
      <>
        <ConfirmPopup
          title={
            isRequestMode
              ? 'Мои заявки на регистрацию объектов интеллектуальной  собственности'
              : 'Мои ОИС (объекты интеллектуальной  собственности)'
          }
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          {isRequestMode ? (
            <div className="modal-help">
              <p>
                Федеральная служба по интеллектуальной собственности (Роспатент) и Федеральный институт промышленной собственности
                (ФИПС) – это госорганы, защищающие интеллектуальные права авторов в России.
              </p>
              <p>
                Раздел содержит список заявок, поданных университетом на изобретения, промышленные образцы, базы данных,
                программные продукты и прочие объекты, в которых вы указаны в составе авторского коллектива.
              </p>
              <p>После получения охранного документа (патента, свидетельства) - заявка в списке не отображается.</p>
              <p>
                Сведения о полученных охранных документах отображаются в разделе <strong>Мои ОИС</strong>. Университет является
                правообладателем охранного документа.
              </p>

              <p>
                Сведения о ваших патентах и свидетельствах, в которых университету не принадлежат право собственности, вы можете
                добавить в разделе <strong>ОИС не аффилированные с Университетом</strong>.
              </p>
            </div>
          ) : (
            <div className="modal-help">
              <p>
                Раздел содержит список патентов, свидетельств и ноу-хау на изобретения, промышленные образцы, базы данных,
                программные продукты и прочие объекты, в которых вы указаны в составе авторского коллектива. Университет является
                правообладателем охранного документа.
              </p>

              <p>
                Сведения о ваших патентах и свидетельствах, в которых университету не принадлежат право собственности, вы можете
                добавить в разделе <strong>ОИС не аффилированные с Университетом</strong>.
              </p>
            </div>
          )}
        </ConfirmPopup>
        <SharedToolbar buttons={buttons} />
      </>
    );
  });
