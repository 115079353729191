export class TenderProtocol {
  static MODULE: string = 'TenderProtocol';
}

export class TenderProtocolTabs {
  static PROTOCOL: string = 'PROTOCOL';

  static PROTOCOL_REQUESTS: string = 'PROTOCOL_REQUESTS';

  static APPROVER_REQUESTS: string = 'APPROVER_REQUESTS';

  static TENDER: string = 'TENDER';

  static DOCUMENTS: string = 'DOCUMENTS';
}
