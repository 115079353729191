import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { Period } from './model';

export const validate = (period: Period) => {
  const { from, to } = period;
  const fromDate = parse(from, formatStr, new Date());
  const toDate = parse(to, formatStr, new Date());

  const isValidFrom = Boolean(fromDate.getFullYear());
  const isValidTo = Boolean(toDate.getFullYear());

  const isValidPeriod = toDate.getTime() - fromDate.getTime() > 0;

  const result = [
    { isValid: isValidFrom, invalidMessage: 'Не корректная дата начала периода' },
    { isValid: isValidTo, invalidMessage: 'Не корректная дата конца периода' },
  ];

  if (isValidFrom && isValidTo) {
    result.push({ isValid: isValidPeriod, invalidMessage: 'Дата конца периода должна быть больше даты начала' });
  }
  return result;
};
