import React, { useCallback, useState } from 'react';

import { Button, ButtonMode, ConfirmPopup, FormComponent, Modal } from 'components';

import { showNotification } from 'features/Notifications';
import { Project } from 'types/models';
import { SelectStages } from '../AddIndicatorsModal/SelectStages/SelectStages';
import { getYear } from 'utils/Helpers';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(stage: Project.Stage): void;
  stages: Project.Stage[];
  contestRequest: Project.FullContestRequest | null;
};

export function SelectStageModal(props: Props) {
  const { isOpen, stages, contestRequest, onClose, onSubmit } = props;

  const [selectedStages, setSelectedStages] = useState<Project.Stage[]>([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedStage] = selectedStages;

  const handleSubmit = useCallback(() => {
    if (selectedStage) {
      if (getYear(selectedStage.endDate) !== getYear(contestRequest?.endDate ?? '')) {
        setIsOpenConfirmModal(true);
        return;
      }
      onSubmit(selectedStage);
      onClose();
    } else {
      showNotification({ message: 'Выберите этап', theme: 'danger' });
    }
  }, [onSubmit, onClose, selectedStage, contestRequest?.endDate]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Этапы" size="large">
      <FormComponent.Wrapper>
        <SelectStages isSelectOnlyOne disabled={false} stages={stages} onSelectStages={setSelectedStages} />
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Выбрать" onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
        <ConfirmPopup
          title="Предупреждение"
          text={[
            `Период, выбранного этапа (${selectedStage?.startDate} - ${selectedStage?.endDate}) не совпадает с периодом,`,
            `указанным в заявке (${contestRequest?.startDate} - ${contestRequest?.endDate}) Этап выбран верно? Продолжить ?`,
          ].join(' ')}
          isOpen={isOpenConfirmModal}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={() => setIsOpenConfirmModal(false)}
          onConfirm={() => {
            onSubmit(selectedStage);
            onClose();
            setIsOpenConfirmModal(false);
          }}
          icon="warning"
        />
      </FormComponent.Wrapper>
    </Modal>
  );
}
