import React from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { Table } from 'types/models';
import { PublicationMobileRequest } from 'types/models/Form';
import { Projects } from 'features/Form/looks/participation/views/Projects';
import { Requests } from 'features/Form/looks/participation/views/Requests';
import { ParticipationProject, ParticipationTypeCode } from 'types/models/Participation';
import { projectsStreams, mobileRequestsStream } from './streams';
import useController from './controller';

type Props = {
  relatedTableState: Table.State | undefined;
  participationTypeCode: ParticipationTypeCode;
  participationId: string;
  workMode: Table.WorkMode;
  onClose: () => void;
  onSuccess: () => void;
};

const setLocalProjects = (rows: ParticipationProject[]) => {
  projectsStreams.setProjects.push(rows);
};

const setLocalProjectsAccept = (id: string) => {
  projectsStreams.accept.push(id);
};

const setLocalProjectsCancelAcception = (id: string) => {
  projectsStreams.cancelAcceptance.push(id);
};

const setLocalMobileRequest = (rows: PublicationMobileRequest[]) => {
  mobileRequestsStream.setMobileRequests.push(rows);
};

const setLocalMobileRequestAccept = (id: string) => {
  mobileRequestsStream.accept.push(id);
};

const setLocalMobileRequestCancelAcception = (id: string) => {
  mobileRequestsStream.cancelAcceptance.push(id);
};

function FinancicalSupportPopupComponent({
  relatedTableState,
  workMode,
  participationId,
  participationTypeCode,
  onClose,
  onSuccess,
}: Props) {
  const { mobileRequests, financingProjects, submitForm } = useController({
    participationId,
    onClose,
    onSuccess,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <Projects
            participationTypeCode={participationTypeCode}
            setLocalProjects={setLocalProjects}
            setLocalProjectsAccept={setLocalProjectsAccept}
            setLocalProjectsCancelAcception={setLocalProjectsCancelAcception}
            workMode={workMode}
            isDisabled={workMode === 'viewMode'}
            projects={financingProjects}
            relatedTableState={relatedTableState}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <Requests
            participationTypeCode={participationTypeCode}
            setLocalMobileRequest={setLocalMobileRequest}
            setLocalMobileRequestAccept={setLocalMobileRequestAccept}
            setLocalMobileRequestCancelAcception={setLocalMobileRequestCancelAcception}
            workMode={workMode}
            requests={mobileRequests}
            relatedTableState={relatedTableState}
          />
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Сохранить" onClick={submitForm} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Wrapper>
  );
}

export const Component = React.memo(FinancicalSupportPopupComponent);
