import React, { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';

import { Person, UserPermission } from 'types/models';
import { buttonIcons, FormComponent, ListEdit } from 'components';

import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { EditableHonor, Honor } from 'features/PersonCard/model';
import { formatName } from './helpers';
import { Color } from 'constants/colors';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  onChangeHonors(honors: Honor[]): void;
};

export function ScientistHonors(props: Props) {
  const { person, userPermission, onChangeHonors } = props;
  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);
  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);
  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.BaseHonor>[]>(
    () => [
      {
        icons: buttonIcons.toolbarQuestion,
        title: 'Задать вопрос',
        code: 'question',
        isDisabled: false,
        onClick: handleQuestionButtonClick,
      },
    ],
    [handleQuestionButtonClick],
  );
  const items: EditableHonor[] = [...(person?.degrees ?? []), ...(person?.academicRanks ?? []), ...(person?.ranks ?? [])].map(
    R.cond([
      [Person.isScientistDegree, item => ({ type: 'degree', ...item })],
      [Person.isScientistAcademicRank, item => ({ type: 'academicRank', ...item })],
      [Person.isScientistRank, item => ({ type: 'rank', ...item })],
    ]),
  );

  return (
    <>
      <FormComponent.Description mode="warning">
        Источником информации является информационная система Управления кадров университета
      </FormComponent.Description>

      <ListEdit
        withMessages
        isFullScreenedTable
        isDeleteConfirmEnabled
        rows={items}
        visibleToolbarButtons={[]}
        extraToolbarButtons={extraToolbarButtons}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        onChange={onChangeHonors}
        columns={[
          { label: 'Наименование', formatValue: formatName },
          { label: 'Дата получения', formatValue: row => row.dateAssign },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) =>
              creationDate ? `${creationSource ?? userPermission?.userName ?? ''} (${creationDate})` : '',
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationDate ? `${modificationSource ?? userPermission?.userName ?? ''} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletedBy && deletionDate ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: () => <></>,
        }}
      />

      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
    </>
  );
}
