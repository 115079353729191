import React, { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, TextGridSelect, buttonIcons } from 'components';
import { ExtraButton } from 'components/TextGridSelect/TextGridSelect';

import { GetSecurityDocumentContractListForProject } from 'features/Table/specifications';
import { SecurityDocumentContract } from 'types/models/SecurityDocumentContract';
import { Table } from 'types/models';
// eslint-disable-next-line max-len
import { SecurityDocumentContractViewTemplate } from 'features/Table/specifications/GetSecurityDocumentContractList/LeftPanelForThirdLevel/modalTemplates';

type Props = {
  securityDocumentContract: SecurityDocumentContract | null;
  setSecurityDocumentContract: (securityDocumentContract: SecurityDocumentContract | null) => void;
  label: string;
  modalTitle: string;
  isDisabled?: boolean;
  isRequired?: boolean;
};
export const SelectSecurityDocumentContract = ({
  securityDocumentContract,
  setSecurityDocumentContract,
  label,
  modalTitle,
  isDisabled,
  isRequired,
}: Props) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const { methods: getSecurityDocumentContract } = BackendAPI.useBackendAPI('GetSecurityDocumentContract');

  const onSelectRow = useCallback(
    (row: Table.Entry, onSuccessLoad: (loadedRow: SecurityDocumentContract) => void) => {
      getSecurityDocumentContract.callAPI(
        {
          id: row.id,
        },
        {
          onSuccessfullCall({ data }) {
            onSuccessLoad(data);
          },
        },
      );
    },
    [getSecurityDocumentContract],
  );

  const extraButtons = useMemo<ExtraButton<SecurityDocumentContract>[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        checkIsDisabled: row => !row?.id,
        onClick: () => setIsFormOpen(true),
      },
    ],
    [],
  );

  return (
    <>
      <FormComponent.Field label={label} isRequired={isRequired}>
        <TextGridSelect
          specification={GetSecurityDocumentContractListForProject}
          disabled={isDisabled}
          title={modalTitle}
          value={securityDocumentContract}
          valueFormatter={x =>
            x ? `${x.kind?.label || ''} №${x.number} от ${x.conclusionDate} (${x.startDate} - ${x.endDate})` : ''
          }
          onChange={val => setSecurityDocumentContract(val)}
          settings={{
            mode: 'loadTableRow',
            onSelectRow,
          }}
          extraButtons={extraButtons}
        />
      </FormComponent.Field>
      <SecurityDocumentContractViewTemplate.Component
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        id={securityDocumentContract?.id || undefined}
      />
    </>
  );
};
