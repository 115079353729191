import { Settings } from 'types/models/common';
import { Exponent, Participation, PartnerRef } from 'types/models/Exponent';
import { convertSubject } from 'services/BackendAPI/configurations/subject/converters';
import { convertMember } from 'services/BackendAPI/configurations/author/converters';
import { convertItem, convertMaybeArray, convertReference, getId, getText } from '../commonConverters';

export const convertServerExponent = (exp: any, settings: Settings): Exponent => {
  const participations = exp.Participations?.Participation || [];
  return {
    id: getId(exp) || null,
    name: getText(exp.Name),
    type: convertReference(exp.Type),
    subject: exp.Subject ? convertSubject(exp.Subject) : null,
    authors: convertMaybeArray(exp.Authors?.Author || [], m => convertMember(m, settings)),
    partners: convertMaybeArray(exp.Partners?.Partner || [], p => convertPartnerRef(p)),
    note: getText(exp.Note),
    participations: convertMaybeArray(participations, convertParticipation),
  };
};

export const convertPartnerRef = (p: any): PartnerRef => ({
  id: getId(p),
  name: getText(p.FullName),
  address: getText(p.Street),
});

function convertParticipation(participation: any): Participation {
  const presentationForms = participation.PresentationForms?.PresentationForm || [];
  return {
    id: getId(participation),
    presentationForms: convertMaybeArray(presentationForms, x => convertItem<string>(x)),
  };
}
