import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { Form } from './Form/Form';

import { makeUseCustomController, State, ToolbarButton } from './makeUseCustomController';

type Arguments = {
  withMenu: boolean;
  parameters?: Parameter[];
  uniqID?: string;
  showButtons?: ToolbarButton[];
  hasSelectButton?: boolean;
  isCanSelectOnlyOneRow?: boolean;
};

type RequestData = {
  parameters: Parameter[];
};

export type Parameter = {
  name: string;
  value: boolean | string;
};

export function GetPartnerList({
  withMenu,
  parameters = [],
  showButtons,
  uniqID = 'GetPartnerList',
  hasSelectButton,
  isCanSelectOnlyOneRow,
}: Arguments): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetPartnerList',
    uniqID,
    header: {
      firstLevel: withMenu
        ? {
            title: 'Список организаций',
            Panel: Filters,
          }
        : undefined,
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: withMenu ? Toolbar : undefined,
        hasSelectButton: hasSelectButton,
      },
    },
    requestData: { parameters },
    useCustomController: makeUseCustomController({ uniqID, showButtons }),
    NeighborTableComponent: Form,
    isCanSelectOnlyOneRow,
  };
}
