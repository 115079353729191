import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const MagazineIdentifiersTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetPublication',
    template: 'MagazineIdentifierForm',
    id: String(id),
    relatedTableState,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Идентификаторы журнала "${relatedTableState?.selectedRows[0]?.name}"`}
      size="large"
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineIdentifiersTemplate);
