import { buttonIcons, IconButtonProps, Option } from 'components';

import { useAppDataContext } from 'features/AppData/context';
import { SubmitTable } from 'features/Table/streams';
import { useCallback, useMemo, useState } from 'react';
import { CrudCallbackArguments, Operation, SettingOption } from './types';
import { Props as ComponentProps } from './';
import { Setting } from 'types/models/Table';

type Props = {} & Omit<ComponentProps, 'columns' | 'gridName' | 'isTableExtended'>;

const useController = ({
  currentSetting,
  settingPresets,
  changeCurrentSetting,
  saveAndChangeGridSetting,
  reloadSettingPresets,
}: Props) => {
  const { userPermission } = useAppDataContext();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);
  const [isSaveWarningModalOpen, setIsSaveWarningModalOpen] = useState<boolean>(false);
  const [isTemplateFormOpen, setIsTemplateFormOpen] = useState<boolean>(false);

  const openHelpModal = useCallback(() => {
    setIsHelpModalOpen(true);
  }, []);

  const closeHelpModal = useCallback(() => {
    setIsHelpModalOpen(false);
  }, []);

  const closeTemplateForm = useCallback(() => {
    setIsTemplateFormOpen(false);
  }, []);

  const openTemplateForm = useCallback(() => {
    setIsTemplateFormOpen(true);
  }, []);

  const handleTemplatesModalClose = useCallback(() => {
    setIsTemplatesModalOpen(false);
  }, []);

  const handleOpenTemplateButtonClick = useCallback(() => {
    setIsTemplatesModalOpen(true);
  }, []);

  const handleSaveOtherTemplate = useCallback(() => {
    setIsNotificationModalOpen(false);
    openTemplateForm();
  }, [openTemplateForm]);

  const onConfirmSaveWarningModal = useCallback(() => {
    setIsSaveWarningModalOpen(false);
    openTemplateForm();
  }, [openTemplateForm]);

  const onResetSaveWarningModal = useCallback(() => {
    setIsSaveWarningModalOpen(false);
  }, []);

  const onCloseSaveWarningModal = useCallback(() => {
    setIsSaveWarningModalOpen(false);
  }, []);

  const handleNotificationModalClose = useCallback(() => {
    setIsNotificationModalOpen(false);
  }, []);

  const userLogin = userPermission?.userLogin;

  const crudCallback = useCallback(
    async ({ operation, settingName, settingAuthor }: CrudCallbackArguments) => {
      const isDeleteOperation = operation === Operation.DELETE;
      const isCurrentSetting =
        Boolean(currentSetting) && currentSetting?.author === settingAuthor && currentSetting?.name === settingName;
      await reloadSettingPresets();
      if (isDeleteOperation && isCurrentSetting) {
        changeCurrentSetting({ settingName: '' });
      }
    },
    [changeCurrentSetting, currentSetting, reloadSettingPresets],
  );

  const submitCallback = useCallback(
    (tableState: SubmitTable) => {
      if (tableState.selectedRows.length) {
        const [row] = tableState.selectedRows;
        setIsTemplatesModalOpen(false);
        changeCurrentSetting({ settingName: row.name, author: row.authorLogin });
      }
    },
    [changeCurrentSetting],
  );

  const settingPresetOptions = useMemo<Option<SettingOption>[]>(
    () =>
      settingPresets.map(item => ({
        value: { author: item.author, name: item.name },
        label: item.name,
      })),
    [settingPresets],
  );

  const handleDiscardButtonClick = useCallback(() => {
    changeCurrentSetting({ settingName: '' });
  }, [changeCurrentSetting]);

  const handleSaveTemplateClick = useCallback(() => {
    if (currentSetting) {
      const isUserSetting = currentSetting.author === userLogin;
      if (isUserSetting) {
        setIsNotificationModalOpen(true);
      } else {
        setIsSaveWarningModalOpen(true);
      }
    } else {
      openTemplateForm();
    }
  }, [currentSetting, openTemplateForm, userLogin]);

  const handleSaveClick = useCallback(() => {
    saveAndChangeGridSetting({ settingName: currentSetting?.name ?? '', author: currentSetting?.author ?? '' });
    setIsNotificationModalOpen(false);
  }, [currentSetting?.author, currentSetting?.name, saveAndChangeGridSetting]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь в работе с шаблонами',
        onClick: openHelpModal,
      },
      {
        icons: buttonIcons.list,
        title: 'Список шаблонов',
        isDisabled: false,
        onClick: handleOpenTemplateButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Не использовать шаблон',
        isDisabled: !currentSetting,
        onClick: handleDiscardButtonClick,
      },
      {
        icons: buttonIcons.save,
        title: 'Сохранить шаблон',
        isDisabled: false,
        onClick: handleSaveTemplateClick,
      },
    ],
    [currentSetting, handleDiscardButtonClick, handleOpenTemplateButtonClick, handleSaveTemplateClick, openHelpModal],
  );

  const onChangeCurrentSetting = useCallback(
    (setting: Option<SettingOption>) => {
      if (currentSetting) {
        const isCurrentSetting = setting.label === currentSetting.name && setting.value?.author === currentSetting.author;
        if (isCurrentSetting) {
          return;
        }
      }
      changeCurrentSetting({ settingName: setting.label, author: setting.value?.author });
    },
    [changeCurrentSetting, currentSetting],
  );

  const successfullCallback = useCallback(
    async (setting: Setting) => {
      await saveAndChangeGridSetting({
        settingName: setting.name,
        purpose: setting.purpose,
        settingID: setting.id,
        shared: setting.isShared,
        author: setting.author,
      });
      await reloadSettingPresets();
    },
    [reloadSettingPresets, saveAndChangeGridSetting],
  );
  return {
    buttons,
    userLogin,
    isHelpModalOpen,
    isTemplateFormOpen,
    settingPresetOptions,
    isTemplatesModalOpen,
    isSaveWarningModalOpen,
    isNotificationModalOpen,
    crudCallback,
    closeHelpModal,
    submitCallback,
    handleSaveClick,
    closeTemplateForm,
    successfullCallback,
    onChangeCurrentSetting,
    onResetSaveWarningModal,
    handleSaveOtherTemplate,
    onCloseSaveWarningModal,
    onConfirmSaveWarningModal,
    handleTemplatesModalClose,
    handleNotificationModalClose,
  };
};

export default useController;
