import { useFormContext } from 'features/Form/hooks';

export function useController() {
  const {
    look: { id, relatedTableState },
  } = useFormContext();

  return {
    relatedTableState,
    id,
  };
}
