import { Form } from 'types/models';

export function validateProgram(formFields: Form.Fields) {
  const required = Object.keys(formFields)
    .filter((fieldName: string) => {
      if (typeof formFields[fieldName]?.value === 'object' && Object.hasOwn(formFields[fieldName]?.value, 'value')) {
        return formFields[fieldName].required && !formFields[fieldName]?.value?.value;
      } else {
        return formFields[fieldName].required && !formFields[fieldName]?.value;
      }
    })
    .map((fieldName: string) => {
      return {
        isValid: false,
        invalidMessage: `Заполните "${formFields[fieldName].title}"`,
      };
    });
  return required;
}
