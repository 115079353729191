import React from 'react';
import * as R from 'ramda';

import { FormComponent, ListEdit, Modal } from 'components';

import { showErrors } from 'utils/Validators';
import { useAppDataContext } from 'features/AppData/context';
import { Change } from 'types/models/Partner';
import { Fields } from './Fields/Fields';
import { getMockChange } from 'features/EditMipPartner/helpers';
import { validate } from './validate';
import { useController } from './controller';

type Props = {
  changes: Change[];
  setChanges(changes: Change[]): void;
  disabled: boolean;
};

export function Changes(props: Props) {
  const { changes, setChanges, disabled } = props;
  const { currentPerson } = useAppDataContext();
  const userName = currentPerson?.fullName ?? '';
  const { isHelpFormOpen, setIsHelpFormOpen, extraToolbarButtons } = useController();
  return (
    <>
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        rows={changes}
        onChange={setChanges}
        isToolbarDisabled={disabled}
        maxHeight="50vh"
        defaultRowsCount={15}
        extraToolbarButtons={extraToolbarButtons}
        columns={[
          { label: 'Категория изменения', formatValue: x => x.category?.label ?? '' },
          { label: 'Дата изменения', formatValue: x => x.changeDate },
          { label: 'Описание изменения', formatValue: x => x.description },
          { label: 'Создано', formatValue: x => `${x.createdBy} ${x.createdDate}` },
          { label: 'Изменено', formatValue: x => `${x.modifiedBy} ${x.modifiedDate}` },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (change, setChange) => (
            <Fields userName={userName} change={change || getMockChange(userName)} setChange={setChange} />
          ),
          validation: {
            checkIsValid: change => validate(change).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      />
      <Modal title="Журнал изменений" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Вкладка Журнал изменений предназначена для фиксирования ключевых атрибутов организации МИП в период его жизненного
              цикла: изменение названия или юридического адреса, выход учредителя, коды ОКВЭД и др. Справочник&nbsp;
              <strong>Категорий изменений</strong> можно пополнять по мере необходимости и в соответствии с политикой учёта МИП в
              университете. Вкладка доступна для пользователей с правом на Редактирование МИП
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}
