import React, { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, ConfirmPopup, ListEdit, FormComponent } from 'components';
import { DataKind, ExtraToolbarButton } from 'components/ListEdit/model';

import { showNotification } from 'features/Notifications';
import { Person } from 'types/models';
import { EnumMap } from 'types/models/Table';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { isHasPermission } from 'features/AppData';
import { format } from 'date-fns';
import { Permits } from 'utils/Permissions';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { useAppDataContext } from 'features/AppData/context';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { LinkedHistoryEntities } from 'features/Form/views/LinkedHistoryEntities';
import { Fields } from './Fields/Fields';
import { validate } from './validate';
import { Color } from 'constants/colors';
import { JobStatus } from 'utils/Enums';

type Props = {
  person: Person.ScientistPerson | null;
  enumMap: EnumMap;
  disabled: boolean;
  onChangeJobs(jobs: Person.ScientistJob[]): void;
};

function Component(props: Props) {
  const { person, onChangeJobs, enumMap, disabled } = props;
  const { currentPerson, userPermission } = useAppDataContext();
  const isEditButtonDisabled = useCallback(jobItem => !(jobItem && jobItem.status.value !== 'LOCAL'), []);

  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [isDeleteHistoryWarningOpen, setIsDeleteHistoryWarningOpen] = useState<boolean>(false);
  const [isEntityRelationOpen, setIsEntityRelationOpen] = useState<boolean>(false);

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);

  const { methods: GetIsLinkedHistoryAPI, state: GetIsLinkedHistoryState } = BackendAPI.useBackendAPI('IsLinkedHistory');
  const isLinkedHistoryPending = useMemo(() => getIsPending(GetIsLinkedHistoryState), [GetIsLinkedHistoryState]);

  const onDeleteButtonClick = useCallback((nextDeletedIndex: number) => {
    setSelectedIndex(nextDeletedIndex);
    setIsDeleteConfirmOpen(true);
  }, []);

  const onDeleteReset = useCallback(() => {
    setSelectedIndex(null);
    setIsDeleteConfirmOpen(false);
  }, []);

  const selectedJob = useMemo(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      return person.scientistJobs[selectedIndex!];
    }

    return null;
  }, [selectedIndex, person]);

  const removeJob = useCallback(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;
    if (isSelectedIndex && person) {
      const isReadonlyJob = Boolean(selectedJob?.isReadOnly);
      const scientistJobsCopy = R.clone(person?.scientistJobs || []);
      if (isReadonlyJob) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.SCIENTIST_JOB_EDUCATION_DEGREE_RANK_DELETE);
        if (!isUserHasPermission) {
          showNotification({ message: 'Нет прав на удаление работ пришедших по интеграции', theme: 'danger' });
          return;
        }
        const deletionDate = `${format(new Date(), formatDateTimeStr)}`;
        const deletedBy = currentPerson?.fullName || '';
        const nextScientistJob: Person.ScientistJob = { ...selectedJob!, isActive: false, deletionDate, deletedBy };
        const nextScientistJobs = R.pipe<Person.ScientistJob[], Person.ScientistJob[], Person.ScientistJob[]>(
          R.remove(selectedIndex, 1),
          R.insert(selectedIndex, nextScientistJob),
        )(scientistJobsCopy);
        onChangeJobs(nextScientistJobs);

        showNotification({ message: 'Работа помечена удаленной', theme: 'success' });
      } else {
        const nextScientistJobs = R.remove<Person.ScientistJob>(selectedIndex, 1)(scientistJobsCopy);
        onChangeJobs(nextScientistJobs);
        showNotification({ message: 'Работа удалена', theme: 'success' });
        setSelectedIndex(null);
      }
    }
  }, [currentPerson?.fullName, selectedIndex, selectedJob, onChangeJobs, person, userPermission]);

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmOpen(false);
    if (selectedIndex === null) {
      return;
    }

    if (selectedJob?.id) {
      GetIsLinkedHistoryAPI.callAPI(
        { type: LinkedHistoryType.JOB, id: selectedJob.id },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.isSuccess) {
              setIsDeleteHistoryWarningOpen(true);
            } else {
              removeJob();
            }
          },
        },
      );
    } else {
      removeJob();
    }
  }, [GetIsLinkedHistoryAPI, selectedIndex, selectedJob, removeJob]);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.ScientistJob>[]>(
    () => [
      {
        title: 'Удалить',
        icons: buttonIcons.delete,
        isDisabled: false,
        checkIsDisabled: (job: Person.ScientistJob | null) => {
          if (job === null || isLinkedHistoryPending || isEditButtonDisabled(job)) {
            return true;
          }

          const isJobReadonly = job?.isReadOnly;
          const isJobActive = job?.isActive;
          if (isJobReadonly) {
            if (isJobActive) {
              return false;
            }

            return true;
          }

          return false;
        },
        onClick: (_, jobIndex: number | null) => {
          if (jobIndex !== null) {
            onDeleteButtonClick(jobIndex);
          }
        },
      },
      {
        icons: buttonIcons.toolbarQuestion,
        title: 'Задать вопрос администратору по сведениям о работе ',
        code: 'question',
        isDisabled: false,
        onClick: handleQuestionButtonClick,
      },
    ],
    [handleQuestionButtonClick, isEditButtonDisabled, isLinkedHistoryPending, onDeleteButtonClick],
  );

  const closeDeleteHistoryWarning = useCallback(() => {
    setIsDeleteHistoryWarningOpen(false);
  }, []);

  const confirmDeleteHistoryWarning = useCallback(() => {
    closeDeleteHistoryWarning();
    setIsEntityRelationOpen(true);
  }, [closeDeleteHistoryWarning]);

  const closeEntityRelation = useCallback(() => {
    setIsEntityRelationOpen(false);
  }, []);
  return (
    <>
      <FormComponent.Description mode="warning">
        Сведения о работе подгружаются из базы данных Управления кадрами. При желании, Вы можете добавить сведения о работе в
        других организациях (статус — Сотрудник другой организации)
      </FormComponent.Description>
      <ListEdit
        isRequired
        withMessages
        isPersonalTable
        isDeleteConfirmEnabled
        rows={person?.scientistJobs ?? []}
        onChange={onChangeJobs}
        visibleToolbarButtons={['add', 'edit']}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        isToolbarDisabled={disabled}
        getIsEditDisabled={isEditButtonDisabled}
        extraToolbarButtons={extraToolbarButtons}
        columns={[
          { label: 'Организация', formatValue: row => row.enterpise },
          { label: 'Должность', formatValue: row => row.refAppointment?.label || row.appointment || '' },
          {
            label: 'Штатное состояние',
            formatValue: row =>
              `${row.status.label}${row.status.value === JobStatus.LOCAL ? `: ${row.staffState?.label || ''}` : ''}`,
          },
          { label: 'Подразделение', formatValue: row => row.refDepartment?.label || row.department || '' },
          { label: 'Табельный номер', formatValue: row => row.employeeNumber ?? '' },
          { label: 'Дата приема', formatValue: row => row.dateReceipt ?? '', dataKind: DataKind.DATE, defaultSort: 'desc' },
          { label: 'Дата увольнения', formatValue: row => row.dateDismissal ?? '', dataKind: DataKind.DATE },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) => (creationSource ? `${creationSource} (${creationDate})` : ''),
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationSource ? `${modificationSource} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletedBy && deletionDate ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (job, setJob) => <Fields enumMap={enumMap} job={job} onChange={setJob} />,
          validation: {
            checkIsValid: job => validate(job).every(x => x.isValid),
            onInvalidate: job => {
              const info = validate(job);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />
      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
      <ConfirmPopup
        title="Удаление работы"
        isOpen={isDeleteHistoryWarningOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={confirmDeleteHistoryWarning}
        onClose={closeDeleteHistoryWarning}
        icon="warning"
      >
        <span>
          Выбранный на удаление элемент используется в историческом срезе.
          <br />
          Удаление на данный момент невозможно. Вы можете изменить связь в историческом срезе, указав другой элемент. Продолжить?
        </span>
      </ConfirmPopup>
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isDeleteConfirmOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={onDeleteConfirm}
        onClose={onDeleteReset}
        icon="warning"
      />
      <LinkedHistoryEntities
        personId={person?.id}
        isOpen={isEntityRelationOpen}
        onClose={closeEntityRelation}
        id={selectedJob?.id ?? '-1'}
        type={LinkedHistoryType.JOB}
      />
    </>
  );
}

export const JobDetails = React.memo(Component);
