import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Form } from './Form/Form';

export const GetAnalyticProjectList: Table.Specification<State> = {
  apiID: 'GetAnalyticProjectList',
  header: {
    firstLevel: {
      title: 'Научные проекты (всех типов)',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: Toolbar,
    },
  },
  useCustomController: makeUseCustomController(),
  NeighborTableComponent: Form,
};
