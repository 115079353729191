import { ReferenceFilter } from 'components';

import { Table } from 'types/models';
import { makeUseCustomController } from './makeUseCustomController';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { State } from './model';

type RequestData = {
  filters: ReferenceFilter[];
  name: string;
};

type Deps = {
  requestData: RequestData;
};

export function GetMergeReferenceElementList(deps: Deps, uniqID?: string): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetReferenceElementList',
    requestData: deps.requestData,
    useCustomController: makeUseCustomController({
      name: deps.requestData.name,
    }),
    isNotInitialLoadTable: true,
    uniqID,
    header: {
      firstLevel: {
        title: '',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
        hasSelectButton: false,
      },
    },
  };
}
