import { useCallback, useMemo } from 'react';

import { Report, Table } from 'types/models';
import { buttonIcons, IconButtonProps } from 'components';

import { useReportsHook, Reports } from 'features/BuildReportPopup';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function useController({ customState, tableState }: Props) {
  const reports = useMemo<Report[]>(() => [Reports.ProjectResultsDetail], []);

  const { toggleVisibleForm, setMode } = customState;
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleViewButtonClick = useCallback(() => {
    setMode('view');
    toggleVisibleForm();
  }, [setMode, toggleVisibleForm]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: !tableState.selectedRows[0],
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        isDisabled: !tableState.selectedRows.length,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [getReports, handleSetCurrentReport, handleViewButtonClick, tableState.selectedRows],
  );

  return {
    buttons,
    currentReport,
    isReportOpen,
    onReportClose,
  };
}
