import { CalendarPlanByYear, ProjectCode } from 'types/models/Project';
import { convertDocumentToRequest } from 'services/BackendAPI/configurations/commonConverters';

export function convertProjectCodeToXML(pc: ProjectCode, replaceMode: boolean, isTSU?: boolean) {
  return {
    _attr: pc.prototypeId ? { prototypeId: pc.prototypeId } : !replaceMode && pc.id ? { id: pc.id } : { replacingId: pc.id },
    Project: { _attr: pc.project?.id ? { id: pc.project?.id } : {} },
    ...(!!isTSU
      ? {
          Year: pc.year,
          ...(pc.codeDepartmentPart ? { CodeDepartmentPart: pc.codeDepartmentPart } : {}),
          CodeYearPart: pc.codeYearPart,
          ...(pc.codeManualPart ? { CodeManualPart: pc.codeManualPart } : {}),
          ...(pc.letter?.id ? { Letter: { _attr: { id: pc.letter?.id } } } : {}),
        }
      : {
          Code: pc.code,
        }),
    ...(pc.department?.id ? { Department: { _attr: { id: pc.department?.id } } } : {}),
    Financing: { _attr: pc.financing?.id ? { id: pc.financing?.id } : {} },
    ...(pc.responsiblePerformer?.id ? { ResponsiblePerformer: { _attr: { id: pc.responsiblePerformer?.id } } } : {}),
    ...(pc.financeProvide?.id ? { FinanceProvide: { _attr: { id: pc.financeProvide?.id } } } : {}),
    ...(pc.isOutsideBudget ? { IsOutsideBudget: pc.isOutsideBudget } : {}),
    ...(pc.isRefersToEconomicAgreement ? { IsRefersToEconomicAgreement: pc.isRefersToEconomicAgreement === true } : {}),
    ...(pc.fundCard?.id ? { FundCard: { _attr: { id: pc.fundCard?.id } } } : {}),
    CalendarPlansByYears:
      pc.calendarPlansByYears && pc.calendarPlansByYears.length
        ? {
            CalendarPlanByYear: pc.calendarPlansByYears.map(convertCalendarPlanByYearToXml),
          }
        : {},
    Departments: {
      ...(pc.departments && pc.departments.length > 0
        ? {
            Department: pc.departments.map(d => ({ _attr: { id: d.id } })),
          }
        : {}),
    },
    Documents:
      pc.documents && pc.documents.length
        ? {
            Document: pc.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)),
          }
        : {},
  };
}

function convertCalendarPlanByYearToXml(cp: CalendarPlanByYear) {
  return {
    Year: cp.year,
    Amount: cp.amount,
  };
}
