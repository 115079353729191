import { useCallback, useEffect, useMemo, useState } from 'react';
import { ValueOf } from 'types/helpers';
import { MipPartner } from 'types/models';

import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from 'features/EditMipPartner/validate';
import { showErrors } from 'utils/Validators';
import { useLocalTableStreams } from 'features/Table/hooks';

type Arguments = {
  externalMipPartner: MipPartner.MipPartner | null;
  onSuccessSave(): void;
  mode: string | null;
};

export function useController(args: Arguments) {
  const { externalMipPartner, onSuccessSave, mode } = args;

  const tableStreams = useLocalTableStreams();

  const [mipPartner, setMipPartner] = useState<MipPartner.MipPartner | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const { methods: getMipPartnerAPI } = BackendAPI.useBackendAPI('GetPartnerMip');
  const { methods: saveMipPartnerAPI } = BackendAPI.useBackendAPI('SavePartnerMip');

  const viewMode: boolean = useMemo(() => mode === 'view', [mode]);

  const editMode: boolean = useMemo(() => mode === 'edit', [mode]);

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const loadMipPartner = useCallback(
    (id: string | null) => {
      getMipPartnerAPI.callAPI(
        {
          partnerId: id,
          enterpriseCustomerId: null,
          enterpriseId: null,
          shortName: null,
          fullName: null,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setMipPartner(data);
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onHelp = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const saveMipPartner = useCallback(
    (currentMipPartner: MipPartner.MipPartner, needClose?: boolean) => {
      if (currentMipPartner) {
        saveMipPartnerAPI.callAPI(
          { mipPartner: currentMipPartner },
          {
            onSuccessfullCall: ({ data }) => {
              showNotification({ message: 'МИП успешно сохранён', theme: 'success' });
              if (needClose) {
                tableStreams.reloadTable.push();
                onSuccessSave();
              } else if (data.id) {
                loadMipPartner(data.id);
                updateWorkModeAfterSaveAndContinue();
              }
            },
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSuccessSave, tableStreams],
  );

  const onSaveMipPartner = useCallback(
    (needClose?: boolean) => {
      if (mipPartner) {
        const validationInfo = validate(mipPartner);
        if (validationInfo.some(x => !x.isValid)) {
          showErrors(validate, mipPartner);
          return;
        }
        saveMipPartner(mipPartner, needClose);
      }
    },
    [saveMipPartner, mipPartner],
  );

  useEffect(() => {
    setMipPartner(externalMipPartner);
  }, [externalMipPartner]);

  const makeChangeHandler = (key: keyof MipPartner.MipPartner) => (value: ValueOf<MipPartner.MipPartner>) => {
    setMipPartner(prevMipPartner => ({ ...prevMipPartner!, [key]: value }));
  };

  return {
    mipPartner,
    setMipPartner,
    makeChangeHandler,
    saveMipPartner,
    isHelpFormOpen,
    setIsHelpFormOpen,
    onSaveMipPartner,
    onHelp,
    workMode,
  };
}
