import React, { useCallback } from 'react';

import { Select } from 'components';

import { Table as T } from 'types/models';
import { Field } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/types';

import './style.scss';

type Props = {
  field: Field;
  onChangeField(fieldId: string, value: string): void;
  enumMap: T.EnumMap;
};

function Component(props: Props) {
  const { field, onChangeField, enumMap } = props;

  if (field.metaType === null || !Object.values(enumMap).length) {
    return null;
  }

  const renderEntry = useCallback((x: string) => x, []);
  const onChange = useCallback(
    (val: string) => {
      onChangeField(field.valueId, val);
    },
    [field.valueId, onChangeField],
  );

  const entries = enumMap[field.metaType].values.map(x => x.label);
  return (
    <Select.Component
      value={field.value}
      onChange={onChange}
      entries={entries}
      renderEntry={renderEntry}
      variant="outlined"
      defaultPlaceholder=""
      isNotSetMaxWidth
    />
  );
}

export const SelectField = React.memo(Component);
