import React, { memo } from 'react';
import { Modal } from 'components';
import { Table } from 'types/models';
import { PublicationTypeCode } from 'types/models/Form';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Component } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  publicationTypeCode: PublicationTypeCode;
  publicationId: string;
  tableState: Table.State;
};

export const Container = memo(({ publicationTypeCode, isOpen, onClose, publicationId, tableState }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title={
      publicationTypeCode === PublicationTypes.MONOGRAPH.code
        ? `Главы ${PublicationTypes.MONOGRAPH.genitiveCaseLabel}`
        : `Статьи ${PublicationTypes[publicationTypeCode]?.genitiveCaseLabel}`
    }
    size="full"
  >
    <Component
      onClose={onClose}
      publicationTypeCode={publicationTypeCode}
      publicationId={publicationId}
      tableState={tableState}
    />
  </Modal>
));
