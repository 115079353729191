import React from 'react';

import { Table } from 'types/models';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { PublicationTypeCode } from 'types/models/Form';

import { Toolbar as SharedToolbar, ConfirmPopup, IconButton, buttonIcons } from 'components';
import { EditRelationsProfileModal } from 'features/EditRelationsProfileModal';
import * as modalTemplates from '../modalTemplates';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { useController } from './controller';
// eslint-disable-next-line max-len
import { Component as PublicationOriginalTranslatesRelations } from 'features/Form/views/PublicationOriginalTranslatesRelations';
import { Component as FinancicalSupportPopup } from 'features/Form/views/FinancicalSupportPopup';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import * as icons from 'icons';

type Props = {
  tableState: Table.State;
};

// const doiUrl = 'http://doi.org';
function Toolbar({ tableState }: Props) {
  const {
    SUCH_LIKE_PUBLICATION_ID,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    isAddFormOpenConferenceParticipation,
    handleCloseFormConferenceParticipation,
    handleOpenFormConferenceParticipation,
    publicationConferenceParticipation,
    setPublicationConferenceParticipation,
    publicationConferenceSource,
    setPublicationConferenceSource,
    handleTemplateCloseAddForm,
    nextPublicationName,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isCitationFormOpen,
    handleCloseCitationForm,
    isSourceCitationDisabled,
    isSourceCitationOpen,
    handleCloseSourceCitation,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    submitPublicationOriginalTranslatesRelationsOpen,
    isFinancialSupportOpen,
    handleCloseFinancialSupport,
    submitFinancialPopupCallback,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({ tableState });

  const { settings } = useAppDataContext();

  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <SuchLikePublication
          mode="add"
          isSearchable
          handleContinueStep={handleContinueStep}
          componentId={SUCH_LIKE_PUBLICATION_ID}
        />
      )}
      <modalTemplates.PublicationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        name={nextPublicationName}
        relatedTableState={tableState}
        handleChangeParticipation={handleOpenFormConferenceParticipation}
        setPublication={setPublicationConferenceParticipation}
        setPublicationConferenceSource={setPublicationConferenceSource}
      />
      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          handleChangeParticipation={handleOpenFormConferenceParticipation}
        />
      )}
      {isAddFormOpenConferenceParticipation && (
        <modalTemplates.ConferenceParticipationAddTemplate.Component
          isOpen={isAddFormOpenConferenceParticipation}
          onClose={handleCloseFormConferenceParticipation}
          sourcePublication={publicationConferenceParticipation}
          sourceEvent={publicationConferenceSource}
        />
      )}
      {!!tableState.selectedRows.length && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную публикацию? (${tableState.selectedRows[0].PublicationType})`}
          isOpen={isDeleteConfirmPopupOpen}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
        />
      )}
      {!!tableState.selectedRows.length && isOpenEditRelations && (
        <EditRelationsProfileModal
          title="Добавить связь публикации с подразделением"
          isOpen={isOpenEditRelations}
          onClose={handleCloseEditRelationsModal}
          id={tableState.selectedRows[0].id}
          type="PUBLICATION"
          errorText="У вас есть право добавить связь только со своими подразделениями.
           Внимание: право редактировать публикацию у вас появится при добавлении Основного подразделения.
            Если вы видите ошибки в публикации в статусе «Утверждена», то напишите письмо Администратору.
             В тексте письма обязательно укажите ID публикации."
        />
      )}
      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isSourceCitationDisabled && (
        <modalTemplates.SourcePublicationCitationSystemTemplate.Component
          isOpen={isSourceCitationOpen}
          onClose={handleCloseSourceCitation}
          id={tableState.selectedRows[0].SourceId}
          relatedTableState={tableState}
        />
      )}
      {!!tableState.selectedRows.length && (
        <PublicationOriginalTranslatesRelations
          isOpen={isPublicationOriginalTranslatesRelationsOpen}
          onClose={handleClosePublicationOriginalTranslatesRelations}
          onSuccess={submitPublicationOriginalTranslatesRelationsOpen}
          publicationId={tableState.selectedRows[0]!.id}
          relatedTableState={tableState}
        />
      )}
      {!!tableState.selectedRows.length && (
        <FinancicalSupportPopup
          isOpen={isFinancialSupportOpen}
          relatedTableState={tableState}
          onClose={handleCloseFinancialSupport}
          publicationId={tableState.selectedRows.length ? tableState.selectedRows[0].id : ''}
          workMode="editMode"
          onSuccess={submitFinancialPopupCallback}
        />
      )}

      <ConfirmPopup
        title="Помощь в работе с публикациями"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <h3>
            <strong className="primary">Вы можете добавить авторские публикации таких типов как:</strong>
          </h3>
          <ul>
            <li>Статья в сборнике материалов конференций, симпозиумов и др.</li>
            <li>Статья в сборнике научных трудов</li>
            <li>Статья в журнале</li>
            <li>Статья в справочном издании</li>
            <li>Статья в другом издании</li>
            <li>Тезисы</li>
            <li>Автореферат диссертации</li>
            <li>Диссертация</li>
            <li>Монография</li>
            <li>Глава в коллективной монографии</li>
            <li>Другое издание</li>
            <li>Другое учебное издание</li>
            <li>Сборник материалов конференций, симпозиумов и др.</li>
            <li>Сборник научных трудов</li>
            <li>Справочное издание</li>
            <li>Учебник</li>
            <li>Учебное пособие</li>
            <li>Учебно-методическое пособие</li>
            <li>Препринт</li>
          </ul>
          <p>
            <strong className="primary">Важно!</strong> Для каждого типа публикации используются форма, учитывающая особенности
            при описании сведений об издании, в том числе электронного или печатного; наукометрических показателях; связях с
            другими объектами системы: научными проектами, конференцией – для материалов конференций.
          </p>
          <p>
            Перед добавлением публикации необходимо выбрать:
            <ul className="dashed">
              <li>тип публикации,</li>
              <li>указать является ли издание электронным или печатным,</li>
              <li>проверить наличие этой публикации в базе системы.</li>
            </ul>
          </p>
          <p>
            <strong className="primary">Важно!</strong> Система автоматически добавляет данные о вас в сведения об авторах и
            привязывает публикацию к вашему подразделению.
          </p>
          <p>Добавленную вами публикацию проверит и утвердит ответственное лицо.</p>
          <br />
          <strong className="primary">Назначение функциональных элементов интерфейса</strong>
          <p>
            <table className="list-edit-table">
              <thead>
                <tr className="list-edit-table__row list-edit-table__row_head">
                  <th className="list-edit-table__column">Элемент</th>
                  <th className="list-edit-table__column">Описание интерфейса</th>
                </tr>
              </thead>
              <tbody>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Мои публикации</td>
                  <td className="list-edit-table__column">
                    Фильтр списка публикаций, в которых ваша персона указана в разделе Сведения об авторах
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Все публикации</td>
                  <td className="list-edit-table__column">Фильтр списка всех публикаций, которые есть в системе</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">Год</td>
                  <td className="list-edit-table__column">
                    Фильтр формирует список публикаций, сроки издания которых содержат указанный год, если фильтр отключен, то
                    список содержит все публикации базы данных. Фильтр накладывает условия отбора с учётом других фильтров
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Показать/спрятать фильтры: <IconButton icons={buttonIcons.filter} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Фильтр формирует список публикаций, сроки издания которых содержат указанный год, если фильтр отключен, то
                    список содержит все публикации базы данных. Фильтр накладывает условия отбора с учётом других фильтров
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Выбрать столбцы: : <IconButton icons={{ default: icons.PickColumns }} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Очищает установленные в текущей таблице параметры сортировки и поиска данных в столбцах.
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Очистить все поля фильтрации: <IconButton icons={buttonIcons.resetFilter} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Очищает установленные в текущей таблице параметры сортировки и поиска данных в столбцах.
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Обновить: <IconButton icons={buttonIcons.update} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Очищает установленные в текущей таблице параметры сортировки и поиска данных в столбцах.
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Просмотр: <IconButton icons={buttonIcons.loop} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Открывает форму просмотра публикации. Кнопка активна при выделении публикации в таблице. Доступна пользователю
                    в любом статусе Публикации
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Добавить: <IconButton icons={buttonIcons.plus} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">Открывает форму для добавления публикации</td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Редактировать: <IconButton icons={buttonIcons.edit} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Открывает форму редактирования публикации. Кнопка активна при выделении публикации в таблице. Пользователь
                    имеет право редактировать свою публикацию в статусах <strong>Черновик</strong> или <strong>Добавлена.</strong>
                    &nbsp; В статусе <strong>Утверждена</strong> для редактирования доступны только вкладки:&nbsp;
                    <strong>Документы, Журнал сообщений, Финансовая поддержка</strong>
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Удалить: <IconButton icons={buttonIcons.delete} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Пользователь имеет право удалить только ту публикацию, которую добавил и если она в статусах&nbsp;
                    <strong>Черновик</strong> или
                    <strong>Добавлена.</strong> С помощью кнопки удаляет выделенную в таблице публикацию.
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Редактировать связь публикации с подразделениями: &nbsp;
                    <IconButton icons={buttonIcons.editRelations} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Публикация может относиться к нескольким подразделениям и использоваться в отчёте каждого из указанных
                    подразделений
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Показатели публикации: &nbsp;
                    <IconButton icons={buttonIcons.citation} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Открывает форму с наукометрическими показателями публикации в базах цитирования. Добавить значение показателя
                    возможно только при наличии ID публикации в базе цитирования
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Показатели источника: &nbsp;
                    <IconButton icons={buttonIcons.sourceScore} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Открывает форму с наукометрическими показателями издания публикации (журнал, сборник, база препринтов) в базах
                    цитирования.
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Скачать файл, прикрепленный к публикации: &nbsp;
                    <IconButton icons={buttonIcons.download} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Скачанный файл помещается в системную папку <strong>Загрузки</strong> на устройстве пользователя
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Открыть публикацию во внешнем источнике: &nbsp;
                    <IconButton icons={buttonIcons.link} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Перейти по ссылке на страницу с публикацией в других Интернет-ресурсах (DOI, электронный каталог Научной
                    библиотеки, др.). Ссылка открывается в новом окне браузера
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Указать статью-оригинал/переводную статью: &nbsp;
                    <IconButton icons={buttonIcons.articleRelations} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Если в систему добавлены 2 варианта статьи в переводном журнале и оригинальном, желательно указать явную связь
                    между этими статьями. Функциональная кнопка открывает форму связи между оригинальной и переводной статьёй.
                    Библиографическая ссылка связанной статьи будет отображаться на форме публикации, на вкладке{' '}
                    <strong>О публикации</strong>
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Финансовая поддержка публикации: &nbsp;
                    <IconButton icons={buttonIcons.financialSupport} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    В случае, если статья является результатом выполнения научного проекта, необходимо выбрать из списка Научных
                    проектов. Ответственный по качеству научных отчётов при наличии прав, Принимает к отчёту по проекту указанную
                    публикацию. Принятые к отчёту по проекту публикации отображаются на форме проекта на вкладке{' '}
                    <strong>Результаты проекта, Публикации</strong>
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Печать документов: &nbsp;
                    <IconButton icons={buttonIcons.print} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Открывает список шаблонов для формирования регламентированных отчётов
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Выгрузить в файл: &nbsp;
                    <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Список публикаций выгружает из таблицы в файл .xlsx для последующей обработки в Excel
                  </td>
                </tr>
                <tr className="list-edit-table__row">
                  <td className="list-edit-table__column">
                    Задать вопрос администратору: &nbsp;
                    <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
                  </td>
                  <td className="list-edit-table__column">
                    Если вы видите ошибки в утверждённой записи - напишите письмо Администратору. Функциональная кнопка открывает
                    форму для добавления сообщения. Все сообщения хранятся в Системе, на странице&nbsp;
                    <strong>Главная, Вопросы от пользователей администраторам.</strong> Список сообщений конкретного пользователя
                    и ответы на них размещаются в <strong>Личном кабинете</strong> пользователя на вкладке&nbsp;
                    <strong>Новости, Вопрос - ответ</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>
      </ConfirmPopup>
      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={tableState.selectedRows[0]['id:PublicationType'] as PublicationTypeCode}
        />
      )}
      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0].id}
          publicationTypeCode={tableState.selectedRows[0]['id:PublicationType'] as PublicationTypeCode}
        />
      )}
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        department={settings?.userDepartment}
      />
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
