import React, { memo } from 'react';

import { FormComponent } from 'components';

import { Documents } from 'features/Documents';
import { Document } from 'types/models';

type Props = {
  documents: Document[];
  setDocuments: (docs: Document[]) => void;
  viewMode?: boolean;
  hint?: string;
};

const Docs = ({ documents, setDocuments, viewMode = false, hint }: Props) => (
  <>
    <FormComponent.Description mode="warning">{hint}</FormComponent.Description>

    <Documents
      isCanIsInfoEdit
      isCanIsSharedEdit
      documents={documents}
      setDocuments={setDocuments}
      disabled={viewMode}
      sourceLabelForLocalDocs="Текущая конференция"
      isShowSource
      // eslint-disable-next-line max-len
      tooltipDocumentName="Подтверждающий документ  для конференции других организаций - это Программа, Информационное письмо или скриншот экрана сайта конференции. Для конференции Организованной Университетом необходимо прикрепить такие документы, как Заявка на проведение конференции, Приказ на проведение конференции, Информационное письмо, Программа научного мероприятия, Отчет о проведении мероприятия (если Университет является организатором или соорганизатором конференции)."
      // eslint-disable-next-line max-len
      tooltip="Подтверждающий документ  для конференции других организаций - это Программа или Информационное письмо или скриншот экрана сайта конференции. Для конференции Организованной университетом необходимо прикрепить Заявка на проведение конференции, Приказ на проведение конференции, Информационное письмо, Программа научного мероприятия, Отчет о проведении мероприятия (если Университет является организатором или соорганизатором конференции)"
    />
  </>
);

export const Component = memo(Docs);
