import { Table as T, StateRegistrationReport, Project } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { Item } from 'types/models/common';
import { EMPTY_FILE } from 'utils/Constants';

export function getMockStateRegistrationReport(): StateRegistrationReport {
  const { enumMap } = useAppDataContext();
  const reportTypeEnum: T.EnumValue | null = enumMap.StageReportType.values.find(x => x.value === 'INTERMEDIATE') || null;
  const reportType: Item | null = reportTypeEnum?.value ? { label: reportTypeEnum.label, value: reportTypeEnum.value } : null;
  return {
    id: null,
    stage: null,
    reportType,
    citisDate: '',
    stageName: '',
    expectedResults: '',
    interdisciplinaryAproachJustification: '',
    reportName: '',
    reportIkNumber: '',
    reportArchiveNumber: '',
    reportCustomerSendDate: '',
    keyWords: '',
    referat: '',
    udks: [],
    file: EMPTY_FILE,
  };
}

export function getStageLabel(stage: Project.Stage): string {
  return `Этап № ${stage?.number ?? '-'} (${stage?.startDate ?? '-'} - ${stage?.endDate ?? '-'})`;
}
