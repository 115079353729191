import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertUser } from 'services/BackendAPI/configurations/users/converters';
import { convertUserToXML } from 'services/BackendAPI/configurations/users/requestConverters';
import { User } from 'types/models/User';

export const userConfigurations = {
  GetUser: makeBackendAPIConfiguration({
    id: 'GetUser',
    endpoint: '/msa/service/commands/GetUser',
    convertInputToXMLElement: ({ login }) => ({
      Request: {
        _attr: { commandName: 'GetUser' },
        User: {
          _attr: { login },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertUser(response.Response.User),
  }),
  Register: makeBackendAPIConfiguration({
    id: 'Register',
    endpoint: '/msa/service/register',
    convertInputToXMLElement: (inputData: {
      surname: string;
      firstName: string;
      secondName?: string;
      birthDate: string;
      email?: string;
      login: string;
      password: string;
    }) => {
      const { surname, firstName, secondName, birthDate, email, login, password } = inputData;
      return {
        UserRegisterRequest: {
          User: {
            LastName: surname,
            FirstName: firstName,
            ...(secondName ? { Patronymic: secondName } : {}),
            BirthDate: birthDate,
            ...(email ? { EMail: email } : {}),
            Login: login,
            Password: password,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ActivateUser: makeBackendAPIConfiguration({
    id: 'ActivateUser',
    endpoint: '/msa/service/commands/ActivateUser',
    convertInputToXMLElement: (inputData: { login: string; activate: boolean }) => {
      const { login, activate } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'ActivateUser',
          },
          User: {
            _attr: {
              login,
              activate,
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveUser: makeBackendAPIConfiguration({
    id: 'SaveUser',
    endpoint: '/msa/service/commands/SaveUser',
    convertInputToXMLElement: (inputData: User) => ({
      Request: {
        _attr: {
          commandName: 'SaveUser',
        },
        User: convertUserToXML(inputData),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const tableUserConfigurations = {
  ...userConfigurations,
};
