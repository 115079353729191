import React from 'react';
import { block } from 'bem-cn';

import { FormComponent, SectionTitle, Select } from 'components';

import { formatSumToLocaleString, getActualAmountValue, sortProgramsByAmount } from '../helpers';
import { Indicator, Program, useController } from './controller';

import '../style.scss';

const b = block('charts');

export function PublicationFinancingNumbers() {
  const {
    financingByPeriod,
    renderEntry,
    activeYearFilterLabel,
    handleChangeActiveYearFilter,
    periodYearOpts,
    actualYearIndex,
    getProjectCountText,
  } = useController();

  const ProgramComponent = ({ program, isPlan }: { program: Program; isPlan: boolean }) => (
    <div className={b('table-inner-row')}>
      <div className={b('table-cell')}>
        {formatSumToLocaleString(getActualAmountValue(program.data[actualYearIndex], isPlan)).summary}
      </div>
      <div className={b('table-cell')}>{`${program.programName} (${program.data[actualYearIndex].projectsCount})`}</div>
    </div>
  );

  const IndicatorComponent = ({ indicator, isPlan }: { indicator: Indicator; isPlan: boolean }) => (
    <>
      <div className={b('table-header')}>
        <span>{formatSumToLocaleString(getActualAmountValue(indicator.summary[actualYearIndex], isPlan)).value}</span>
        <span className={b('table-header', { small: true })}>
          {formatSumToLocaleString(getActualAmountValue(indicator.summary[actualYearIndex], isPlan)).measure}
        </span>
      </div>

      <div className={b('table-cell')}>
        {sortProgramsByAmount(indicator.programs, actualYearIndex, isPlan).map(
          (program: Program, index: number) =>
            !!getActualAmountValue(program.data[actualYearIndex], isPlan) && (
              <ProgramComponent program={program} isPlan={isPlan} key={index} />
            ),
        )}
      </div>
    </>
  );

  const IndicatorsComponent = ({ indicator }: { indicator: Indicator }) => (
    <div className={b('table-row')}>
      <div className={b('table-title')}>
        <SectionTitle
          title={`${indicator.name} (${indicator.summary[actualYearIndex].projectsCount} ${getProjectCountText(
            indicator.summary[actualYearIndex].projectsCount,
          )})`}
        />
      </div>
      <div className={b('table-row-item')}>
        <h4>Планирование</h4>
        <IndicatorComponent indicator={indicator} isPlan={true} />
      </div>
      <div className={b('table-row-item')}>
        <h4>Исполнение плана</h4>
        <IndicatorComponent indicator={indicator} isPlan={false} />
      </div>
    </div>
  );

  return (
    <div className={b()}>
      <h3>Наука в Цифрах</h3>
      <div className={b('params')}>
        <FormComponent.Line>
          <FormComponent.Field label="Период" hasLeftAlign>
            <Select.Component
              value={activeYearFilterLabel}
              variant="outlined"
              defaultPlaceholder="Не задан"
              entries={periodYearOpts.current.map(({ label }) => label)}
              onChange={handleChangeActiveYearFilter}
              renderEntry={renderEntry}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </div>

      <div className={b('table')}>
        {financingByPeriod.map(
          (indicator: Indicator, index: number) =>
            !!getActualAmountValue(indicator.summary[actualYearIndex], true) && (
              <IndicatorsComponent indicator={indicator} key={index} />
            ),
        )}
      </div>
    </div>
  );
}
