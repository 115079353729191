import React from 'react';

import { FormComponent, TextInput } from 'components';

type Period = {
  startYear: string;
  endYear: string;
};

type Props = {
  value: Period | null;
  onChange(row: Period): void;
};

function PeriodInputs({ value, onChange }: Props) {
  const handleChange = (key: keyof Period) => (val: string) => {
    if (Number.isInteger(Number(val))) {
      if (value) {
        onChange({ ...value, [key]: val });
      } else {
        const emptyRow: Period = { startYear: '', endYear: '' };
        onChange({ ...emptyRow, [key]: val });
      }
    }
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Field label="C" isRequired labelSize="fit">
            <TextInput value={value?.startYear ?? ''} onChange={handleChange('startYear')} />
          </FormComponent.Field>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Field label="По" labelSize="fit">
            <TextInput value={value?.endYear ?? ''} onChange={handleChange('endYear')} />
          </FormComponent.Field>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

export { PeriodInputs };
