import { useLayoutEffect, useMemo, useState, useCallback, useRef } from 'react';

import { ExpositionTypes } from 'utils/Enums/ExpositionTypes';
import * as BackendAPI from 'services/BackendAPI';

enum EXP {
  EXP = 'EXP',
  EXP_PART = 'EXP_PART',
  EXP_AWARD_TYPE = 'EXP_AWARD_TYPE',
}

type Item = {
  name: string;
  code: EXP;
  data: number[];
};

export function useController() {
  const [expositionData, setExpositionData] = useState<Item>({
    name: 'Количество выставок',
    code: EXP.EXP,
    data: [0, 0, 0, 0, 0, 0],
  });
  const [exhibitData, setExhibitData] = useState<Item>({
    name: 'Количество экспонатов',
    code: EXP.EXP_PART,
    data: [0, 0, 0, 0, 0, 0],
  });
  const [awardsData, setAwardsData] = useState<Item>({
    name: 'Количество наград',
    code: EXP.EXP_AWARD_TYPE,
    data: [0, 0, 0, 0, 0, 0],
  });

  const chartData = useMemo<Item[]>(() => [expositionData, exhibitData, awardsData], [expositionData, exhibitData, awardsData]);
  const [activePeriodFilter, setActivePeriodFilter] = useState<string>(ExpositionTypes.TECH);

  const periodOpts = useRef([
    {
      alias: ExpositionTypes.TECH,
      label: 'Научно-технические',
      labelDeclined: 'в научно-технических выставках',
    },
    {
      alias: ExpositionTypes.EDU,
      label: 'Образовательные',
      labelDeclined: 'в образовательных выставках',
    },
    {
      alias: ExpositionTypes.ART,
      label: 'Художественные',
      labelDeclined: 'в художественных выставках',
    },
    {
      alias: '',
      label: 'Все',
      labelDeclined: 'во всех выставках',
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actualYear, setActualYear] = useState(new Date().getFullYear());
  const [actualExpositionType, setActualExpositionType] = useState('');
  const [actualName, setActualName] = useState('');

  const categories = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const { methods: GetDashboardChartExpositionListStatic } = BackendAPI.useBackendAPI('GetDashboardChartExpositionListStatic', {
    onSuccessfullCall: ({ data }) => {
      const chartAttrRes = data.Response.Grid.GroupingRows.TotalRow._attributes;
      setExpositionData({
        name: 'Количество выставок',
        code: EXP.EXP,
        data: categories.map(i => Number(chartAttrRes[`Year${i}`]) || 0),
      });
    },
  });

  const { methods: GetDashboardChartExpositionParticipationListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartExpositionParticipationListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        const chartAttrRes = data.Response.Grid.GroupingRows.TotalRow._attributes;
        setExhibitData({
          name: 'Количество экспонатов',
          code: EXP.EXP_PART,
          data: categories.map(i => Number(chartAttrRes[`Year${i}`]) || 0),
        });
      },
    },
  );

  const { methods: GetDashboardChartExpositionParticipationAwardListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartExpositionParticipationAwardListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        const chartAttrRes = data.Response.Grid.GroupingRows.TotalRow._attributes;
        setAwardsData({
          name: 'Количество наград',
          code: EXP.EXP_AWARD_TYPE,
          data: categories.map(i => Number(chartAttrRes[`Year${i}`]) || 0),
        });
      },
    },
  );

  useLayoutEffect(() => {
    setIsLoading(true);

    GetDashboardChartExpositionListStatic.callAPI(
      {
        parameters: [
          {
            name: 'expositionType',
            value: activePeriodFilter || null,
          },
        ],
      },
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    GetDashboardChartExpositionParticipationListStatic.callAPI(
      {
        parameters: [
          {
            name: 'expositionType',
            value: activePeriodFilter || null,
          },
        ],
      },
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    GetDashboardChartExpositionParticipationAwardListStatic.callAPI(
      {
        parameters: [
          {
            name: 'expositionType',
            value: activePeriodFilter || null,
          },
        ],
      },
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePeriodFilter]);

  const activeFilterLabel = useMemo(() => periodOpts.current.filter(opt => opt.alias === activePeriodFilter)[0].label, [
    periodOpts,
    activePeriodFilter,
  ]);

  const activeFilterLabelDeclined = useMemo(
    () => periodOpts.current.filter(opt => opt.alias === activePeriodFilter)[0].labelDeclined,
    [periodOpts, activePeriodFilter],
  );

  const getModalTitle = useMemo(() => `${actualName} ${activeFilterLabelDeclined} ${actualYear ? ` за ${actualYear}г.` : ''}`, [
    actualYear,
    activeFilterLabelDeclined,
    actualName,
  ]);

  const renderEntry = useCallback(x => x, []);
  const handleChangeActiveFilter = useCallback(
    newLabel => {
      const data = periodOpts.current.filter(opt => opt.label === newLabel)[0];
      setActivePeriodFilter(data?.alias);
    },
    [setActivePeriodFilter],
  );

  const seriesClickHandler = useCallback(
    (item: Item, year: number) => {
      setActualName(item.name);
      setActualExpositionType(item.code);
      setActualYear(year);
      setIsModalOpen(true);
    },
    [setActualExpositionType, setActualYear, setIsModalOpen, setActualName],
  );

  return {
    activeFilterLabel,
    periodOpts,
    handleChangeActiveFilter,
    renderEntry,
    getModalTitle,
    isModalOpen,
    setIsModalOpen,
    isLoading,
    categories,
    chartData,
    seriesClickHandler,
    actualExpositionType,
    activePeriodFilter,
    actualYear,
    EXP,
  };
}
