import { ConfirmPopup, CustomSelect, FormComponent, ListEdit } from 'components';
import React, { memo } from 'react';
import { Form, Table } from 'types/models';
import useController from './controller';

type Props = {
  disabled?: boolean;
  isMagazineOriginal: boolean;
  isMagazineTranslate: boolean;
  magazinePublicationOriginal: Form.Original | null;
  magazinePublicationTranslates: Form.Translation[];
  magazineArticlePublicationOriginal: Form.Original | null;
  magazineArticlePublicationTranslates: Form.Translation[];
  isRelationsErrorPopupOpened: boolean;
  magazinePublicationName: string;
  magazineArticlePublication: Form.Publication | null;
  relatedTableState?: Table.State;
  setMagazineArticlePublicationTranslates: (translations: Form.Translation[]) => void;
  setMagazineArticlePublicationOriginal: (translations: Form.Original | null) => void;
  onRelationsErrorPopupClose: () => void;
  onRelationsErrorPopupSubmit: () => void;
};

export const EditPublicationOriginalTranslatesRelations = memo(
  ({
    disabled = false,
    relatedTableState,
    isMagazineOriginal,
    isMagazineTranslate,
    magazinePublicationOriginal,
    isRelationsErrorPopupOpened,
    magazinePublicationTranslates,
    magazinePublicationName,
    magazineArticlePublication,
    magazineArticlePublicationOriginal,
    magazineArticlePublicationTranslates,
    setMagazineArticlePublicationTranslates,
    setMagazineArticlePublicationOriginal,
    onRelationsErrorPopupClose,
    onRelationsErrorPopupSubmit,
  }: Props) => {
    const {
      originalsListTableConfig,
      modalTableRowToOriginalConventer,
      translatesColumns,
      modalTableRowConverterToTranslatesConverter,
      translatesListTableConfig,
    } = useController({
      publicationId: magazineArticlePublication?.status?.id || '',
      relatedTableState,
      magazinePublicationOriginal,
    });
    return (
      <>
        <strong className="primary">
          {isMagazineOriginal && 'Статья в журнале - оригинал'}
          {isMagazineTranslate && 'Статья в журнале - перевод'}
        </strong>
        <FormComponent.Line>
          <span>{magazineArticlePublication && magazineArticlePublication.fields.bibliographicRecord}</span>
        </FormComponent.Line>
        <>
          {isMagazineTranslate && originalsListTableConfig && (
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Оригинальный журнал:" labelSize="fit">
                  <span> {magazinePublicationOriginal && magazinePublicationOriginal.name}</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <CustomSelect
                  disabled={disabled}
                  title="Оригинальная статья"
                  original={
                    magazineArticlePublicationOriginal
                      ? {
                          name: magazineArticlePublicationOriginal.bibliographic || '',
                          id: magazineArticlePublicationOriginal.id,
                        }
                      : null
                  }
                  relationTableModalTitle={`Статьи в журнале "${magazinePublicationName}"`}
                  onChange={setMagazineArticlePublicationOriginal}
                  modalTableRowConverter={modalTableRowToOriginalConventer}
                  modalTableSpecification={originalsListTableConfig}
                />
              </FormComponent.Line>
            </>
          )}
          {isMagazineOriginal && (
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Переводной журнал:" labelSize="fit">
                  <span> {!!magazinePublicationTranslates.length && magazinePublicationTranslates[0].name}</span>
                </FormComponent.Field>
              </FormComponent.Line>

              <FormComponent.Line>
                <ListEdit
                  isToolbarDisabled={disabled}
                  title="Переводные статьи"
                  rows={magazineArticlePublicationTranslates}
                  withoutHead
                  withMessages
                  columns={translatesColumns}
                  onChange={setMagazineArticlePublicationTranslates}
                  specification={{
                    mode: 'relationTableModal',
                    modalTableRowConverter: modalTableRowConverterToTranslatesConverter,
                    relationTableModalTitle: 'Статьи',
                    modalTableSpecification: translatesListTableConfig,
                  }}
                />
              </FormComponent.Line>
            </>
          )}
        </>

        <ConfirmPopup
          title="Связь не указана"
          // eslint-disable-next-line max-len
          text="Журнал выбранной статьи не имеет переводов и сам не является переводом. Для того, чтобы установить связь с оригинальной статьёй или её переводом, необходимо, чтобы журнал, в котором опубликована данная статья, сам являлся оригиналом или переводом"
          isOpen={isRelationsErrorPopupOpened}
          okButtonText="Да"
          onClose={onRelationsErrorPopupClose}
          onConfirm={onRelationsErrorPopupSubmit}
          icon="warning"
        />
      </>
    );
  },
);
