import React, { useMemo } from 'react';

import {
  ListEdit,
  Radio,
  SectionTitle,
  FormComponent,
  Reference,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';

import { Tender } from 'types/models';
import { showNotification } from 'features/Notifications';
import { formatNumber } from 'utils/Helpers';
import { LotPriceInputs } from '../LotPriceInputs/LotPriceInputs';
import { useController } from './controller';

type Props = {
  lot: Tender.Lot | null;
  onLotChange(row: Tender.Lot): void;
  viewMode: boolean;
  tenderName: string;
  tenderStartDate: string;
  lotsAmount: number;
};

function LotInputs({ lot, onLotChange, viewMode, tenderName, tenderStartDate, lotsAmount }: Props) {
  const {
    handleLotPricesChange,
    handleChange,
    handleAmountRadioChange,
    handleRangeDateChange,
    yesNoRadios,
    requestSupportAmountDisabled,
    contractSupportAmountDisabled,
    warrantySupportAmountDisabled,
    handleAmountChange,
  } = useController({
    lot,
    onLotChange,
    viewMode,
    tenderName,
    tenderStartDate,
    lotsAmount,
  });

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column specialStyles={{ width: '50%' }}>
        <FormComponent.Line>
          <SectionTitle title="Описание лота" />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Номер лота" isRequired>
            <TextInput
              value={lot?.number ? lot.number.toString() : undefined}
              onChange={handleChange('number')}
              settings={{ maxLength: 20 }}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Шифр лота">
            <TextInput value={lot?.code} onChange={handleChange('code')} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тема лота" isRequired>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Тема лота' }}
              value={lot?.theme || ''}
              onChange={handleChange('theme')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Сроки выполнения работ">
            <TextDateTime
              isRange
              value={`${lot?.startDate || ''}-${lot?.endDate || ''}`}
              onChange={(value: string) => {
                const dates = value.split('-');
                handleRangeDateChange(dates[0] || '', dates[1] || '');
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="">
            <TextInput
              value={lot?.periodNote}
              onChange={handleChange('periodNote')}
              placeholder="Примечание о сроках выполнения работ"
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Предельная цена, руб.">
            <TextInput
              mode={TextInputMode.number}
              value={lot?.limitAmount ? lot?.limitAmount.toString() : ''}
              onChange={handleAmountChange('limitAmount')}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Источник финансирования">
            <Reference
              name="RefInvestmentSource"
              onChange={handleChange('investmentSource')}
              value={lot?.investmentSource ?? null}
              relationTableModalTitle="Источники финансирования"
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Примечание">
            <TextInput
              value={lot?.investmentSourceNote}
              onChange={handleChange('investmentSourceNote')}
              placeholder={'Например, требования к софинансированию'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Количество контрактов" isRequired>
            <TextInput
              mode={TextInputMode.number}
              value={lot?.contractCount ? lot?.contractCount.toString() : ''}
              onChange={handleAmountChange('contractCount')}
              settings={{ decimal: false }}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Цена 1 контракта, руб.">
            <TextInput
              mode={TextInputMode.number}
              value={lot?.contractAmount ? lot?.contractAmount.toString() : ''}
              onChange={handleChange('contractAmount')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <ListEdit
            title="Календарный план"
            rows={lot?.prices ? lot?.prices.sort((x, y) => Number(x.year) - Number(y.year)) : []}
            onChange={handleLotPricesChange}
            withMessages
            isDeleteConfirmEnabled
            isToolbarDisabled={!!viewMode}
            columns={[
              { label: 'Год', formatValue: row => (row?.year ? row.year : '') },
              { label: 'Сумма', formatValue: row => formatNumber((row?.amount || 0).toString(), 2) },
            ]}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) =>
                useMemo(() => <LotPriceInputs lot={lot} lotPrice={row} onLotPriceChange={onChange as any} />, [onChange, row]),
              validation: {
                checkIsValid: row => {
                  return !!(
                    row?.amount !== undefined &&
                    row?.year &&
                    parseInt(row?.year, 10) > 1900 &&
                    parseInt(row?.year, 10) < 3000
                  );
                },
                onInvalidate: () => showNotification({ message: 'Заполните правильно поля', theme: 'danger' }),
              },
            }}
            columnIndexesForSumTotal={[1]}
          />
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column specialStyles={{ width: '50%' }}>
        <FormComponent.Line>
          <SectionTitle title="Обеспечение заявки" />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <Radio
            value={lot?.isSupportRequest === true || lot?.isSupportRequest === undefined ? 'true' : 'false'}
            list={yesNoRadios}
            onChange={handleAmountRadioChange('isSupportRequest')}
          />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Сумма">
            <TextInput
              mode={TextInputMode.number}
              value={lot?.requestSupportAmount ? formatNumber(lot?.requestSupportAmount.toString(), 2) : ''}
              onChange={handleChange('requestSupportAmount')}
              isDisabled={requestSupportAmountDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <SectionTitle title="Обеспечение контракта" />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <Radio
            value={lot?.isSupportContract === true || lot?.isSupportContract === undefined ? 'true' : 'false'}
            list={yesNoRadios}
            onChange={handleAmountRadioChange('isSupportContract')}
          />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Сумма">
            <TextInput
              mode={TextInputMode.number}
              value={lot?.contractSupportAmount ? formatNumber(lot?.contractSupportAmount.toString(), 2) : ''}
              onChange={handleChange('contractSupportAmount')}
              isDisabled={contractSupportAmountDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <SectionTitle title="Обеспечение гарантийных обязательств" />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <Radio
            value={lot?.isSupportWarranty === true || lot?.isSupportWarranty === undefined ? 'true' : 'false'}
            list={yesNoRadios}
            onChange={handleAmountRadioChange('isSupportWarranty')}
          />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Сумма">
            <TextInput
              mode={TextInputMode.number}
              value={lot?.warrantySupportAmount ? formatNumber(lot?.warrantySupportAmount.toString(), 2) : ''}
              onChange={handleChange('warrantySupportAmount')}
              isDisabled={warrantySupportAmountDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дополнительная информация">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Дополнительная информация' }}
              value={lot?.note || ''}
              onChange={handleChange('note')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

export { LotInputs };
