import React, { useMemo, useState } from 'react';
import { setup } from 'bem-cn';

import { Button, ButtonMode, FormComponent, Select } from 'components';

import { Table as T } from 'types/models';
import compilationTypes from '../compilationTypes';
import compilationConferenceTypes from '../compilationConferenceTypes';
import { FetchCompilationProcess } from 'services/BackendAPI/configurations/publication/types';

const block = setup({ mod: '--' });
const b = block('toolbar-form-template');
type Props = {
  onClose: () => void;
  action: string;
  onSelect: FetchCompilationProcess;
  relatedTableState?: T.State;
  currentCompilationTypeLabel: string;
  id: string;
};

const ChangeCompilationTypes = (props: Props) => {
  const { onClose, relatedTableState, onSelect, action, id, currentCompilationTypeLabel } = props;

  const row = useMemo(() => {
    if (relatedTableState && relatedTableState.selectedRows.length) {
      return relatedTableState.selectedRows[0];
    }
    return null;
  }, [relatedTableState]);

  const [compilationType, setCompilationType] = useState(
    row ? compilationTypes.find(t => t.label === currentCompilationTypeLabel) : compilationTypes[0],
  );

  const [compilationConferenceType, setCompilationConferenceType] = useState(compilationConferenceTypes[0]);

  const isCompilationConference = useMemo(() => compilationType?.code === 'COMPILATION_CONFERENCE', [compilationType]);

  const onChangeCompilationType = (val: string) => {
    setCompilationType(compilationTypes.filter(type => type.label === val)[0]);
  };

  const onChangeCompilationConferenceType = (val: string) => {
    setCompilationConferenceType(compilationConferenceTypes.filter(type => type.label === val)[0]);
  };

  const continueWithSelectedType = () => {
    onSelect(id || '', action, {
      newType: compilationType?.code || '',
      newArticleType: isCompilationConference ? compilationConferenceType.code : '',
    });
    onClose();
  };

  const renderEntry = (x: string) => (
    <div className={b('item-text')} title={x}>
      {x}
    </div>
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Изменить тип сборника на">
          <Select.Component
            value={compilationType?.label || ''}
            onChange={onChangeCompilationType}
            entries={compilationTypes.map(e => e.label)}
            renderEntry={renderEntry}
            variant="outlined"
            defaultPlaceholder="Не задан"
            required
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {isCompilationConference && (
        <FormComponent.Line>
          <FormComponent.Field label="Изменить тип статей на">
            <Select.Component
              value={compilationConferenceType.label}
              onChange={onChangeCompilationConferenceType}
              entries={compilationConferenceTypes.map(e => e.label)}
              renderEntry={renderEntry}
              variant="outlined"
              defaultPlaceholder="Не задан"
              required
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <FormComponent.Actions>
        <Button
          mode={ButtonMode.primary}
          text="Выполнить операцию по изменению типа сборника"
          onClick={continueWithSelectedType}
        />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(ChangeCompilationTypes);
