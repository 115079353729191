import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    handleFormSubmit,
    handleTemplateCloseAddForm,
    isFormOpen,
    publicationMessage,
    handleMessageChange,
    messageLabel,
    handleMessageLabelChange,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
  } = useController({ tableState, customState });

  return (
    <>
      <AdministratorMessagePopup
        title={`
          Добавьте стандартное 
          и/или текстовое сообщение
          администратору`}
        onConfirm={handleFormSubmit}
        onConfirmWithoutMessage={handleTemplateCloseAddForm}
        isOpen={isFormOpen}
        publicationMessage={publicationMessage.message}
        updatePublicationMessage={handleMessageChange}
        publicationMessageType={messageLabel}
        setPublicationMessageType={handleMessageLabelChange}
        relationTableModalTitle="Сообщения журнала"
        cancelButtonText="Отмена"
        submitButtonText="Сохранить"
        command="RefParticipationFeedlineMessage"
      />
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text="Вы уверены что хотите удалить выбранное сообщение?"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
          resetButtonText="Нет"
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
