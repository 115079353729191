import { Person } from 'types/models';
import { JobStatus } from 'utils/Enums';

export function validate(job: Person.ScientistJob | null) {
  if (!job) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidStatus = Boolean(job.status);
  const isValidEnteprise = job.status.value === JobStatus.CIVIL_CONTRACT ? true : Boolean(job.enterpise);
  const isValidAppointment = job.status.value === JobStatus.CIVIL_CONTRACT ? true : Boolean(job.refAppointment?.id);
  const isValidStaffState = job.status.value !== JobStatus.LOCAL || !!job.staffState?.id;
  const isValidDateReceipt = job.status.value !== JobStatus.CIVIL_CONTRACT ? true : Boolean(job.dateReceipt);

  return [
    { isValid: isValidStatus, invalidMessage: 'Заполните статус' },
    { isValid: isValidEnteprise, invalidMessage: 'Заполните организацию' },
    { isValid: isValidAppointment, invalidMessage: 'Заполните должность' },
    { isValid: isValidStaffState, invalidMessage: 'Заполните штатное состояние' },
    { isValid: isValidDateReceipt, invalidMessage: 'Заполните дату приема' },
  ];
}
