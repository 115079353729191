import { CustomSelect, FormComponent, TextDateTime, TextInput } from 'components';

import { WorkMode } from 'types/models/Table';
import { ProtocolTender, TenderProtocol } from 'types/models/TenderProtocol';
import { useController } from './controller';
import { ProtocolCommission } from 'types/models/ProtocolCommission';

type Props = {
  workMode: WorkMode;
  tenderProtocol: TenderProtocol;
  changeTender(e: ProtocolTender | null): void;
  changeNumber(e: string): void;
  changeProtocolDate(e: string): void;
  changeProtocolCommission(e: ProtocolCommission | null): void;
};

export function ProtocolMainFields({
  workMode,
  tenderProtocol,
  changeTender,
  changeNumber,
  changeProtocolDate,
  changeProtocolCommission,
}: Props) {
  const {
    tenderSpecification,
    tenderModalTableRowConverter,
    protocolCommissionSpecification,
    protocolCommissionModalTableRowConverter,
    changeTenderObject,
  } = useController({ changeTender });

  return (
    <>
      <FormComponent.Line hasFreeFormat growIndexes={[10, 0]}>
        <FormComponent.Field label="Номер протокола">
          <TextInput value={tenderProtocol.number || ''} onChange={changeNumber} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата протокола">
          <TextDateTime value={tenderProtocol.protocolDate} onChange={changeProtocolDate} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Field label="Конкурс" isRequired>
        <CustomSelect
          disabled={workMode === 'viewMode'}
          isRequired
          original={
            tenderProtocol.tender?.id ? { id: tenderProtocol.tender.id, name: tenderProtocol.tender.name } : { id: '', name: '' }
          }
          relationTableModalTitle="Конкурс"
          onDeleteFunction={() => changeTender(null)}
          onChange={changeTenderObject}
          modalTableRowConverter={tenderModalTableRowConverter}
          modalTableSpecification={tenderSpecification}
        />
      </FormComponent.Field>
      <FormComponent.Field>
        <CustomSelect
          disabled={workMode === 'viewMode'}
          isRequired
          title="Конкурсная комиссия"
          original={
            tenderProtocol.protocolCommission?.id
              ? {
                  id: tenderProtocol.protocolCommission.id,
                  // eslint-disable-next-line max-len
                  name: `${tenderProtocol.protocolCommission.name} (№${tenderProtocol.protocolCommission.orderNumber} от ${tenderProtocol.protocolCommission.orderDate})`,
                }
              : { id: '', name: '' }
          }
          relationTableModalTitle="Конкурсная комиссия"
          onDeleteFunction={() => changeProtocolCommission(null)}
          onChange={changeProtocolCommission}
          modalTableRowConverter={protocolCommissionModalTableRowConverter}
          modalTableSpecification={protocolCommissionSpecification}
        />
      </FormComponent.Field>
    </>
  );
}
