import React from 'react';

import { FormComponent, Modal, Tab, Tabs } from 'components';

import { Documents } from 'features/Documents';
import { MipPartner } from 'types/models';
import { ValueOf } from 'types/helpers';
import { DataGrid } from 'features/Table';
import { GetPartnerMipPaymentList, GetPartnerMipSecurityDocumentContractList } from 'features/Table/specifications';
import { Changes } from './Changes/Changes';
import { Indicators } from '../Indicators';
import { CharterCapitals } from '../CharterCapitals';
import { useController } from './controller';

type Props = {
  mipPartner: MipPartner.MipPartner | null;
  makeChangeHandler: (key: keyof MipPartner.MipPartner) => (value: ValueOf<MipPartner.MipPartner>) => void;
  disabled: boolean;
};

export function MipDocuments(props: Props) {
  const { makeChangeHandler, mipPartner, disabled } = props;
  const { isHelpFormOpen, setIsHelpFormOpen, extraToolbarButtons } = useController();
  return (
    <>
      <Tabs>
        <Tab title="Уставной капитал">
          <CharterCapitals
            charterCapitals={mipPartner?.charterCapitals ?? []}
            setCharterCapitals={makeChangeHandler('charterCapitals')}
            isDisabled={disabled}
          />
        </Tab>
        <Tab title="Договоры на ИС">
          <DataGrid specification={GetPartnerMipSecurityDocumentContractList({ partnerId: mipPartner?.id ?? '-1' })} />
        </Tab>
        <Tab title="Показатели">
          <Indicators
            indicators={mipPartner?.indicators ?? []}
            onIndicatorsChange={makeChangeHandler('indicators')}
            disabled={disabled}
          />
        </Tab>
        <Tab title="Лицензионные платежи">
          <DataGrid specification={GetPartnerMipPaymentList({ partnerId: mipPartner?.id ?? '-1' })} />
        </Tab>
        <Tab title="Документы МИП">
          <Documents
            title="Архив документов МИП"
            documents={mipPartner?.mipDocuments ?? []}
            setDocuments={makeChangeHandler('mipDocuments')}
            disabled={disabled}
            additionalToolbarButtons={extraToolbarButtons}
          />
          <Modal title="Документы МИП" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
            <FormComponent.Wrapper>
              <div className="modal-help">
                <p>
                  Вкладка <strong>Документы МИП</strong> предназначена для хранения специализированных документов МИП (уставные
                  документы, приказ, договор и пр.). Вкладка доступна для пользователей с правом на Редактирование МИП
                </p>
              </div>
            </FormComponent.Wrapper>
          </Modal>
        </Tab>
        <Tab title="Журнал изменений">
          <Changes disabled={disabled} changes={mipPartner?.changes ?? []} setChanges={makeChangeHandler('changes')} />
        </Tab>
      </Tabs>
    </>
  );
}
