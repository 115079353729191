import React, { useMemo } from 'react';

import { IconButtonProps, buttonIcons, Toolbar, Collapse } from 'components';
import { CustomComponentSpecification, Mode, ExtraToolbarButton } from '../model';

type Props<Row> = {
  isCollapse: boolean;
  customEditRow: Row | null;
  selectedRowIndex: number | null;
  isSaveAndAddCustomComponent?: boolean;
  mode: Mode;
  onChange: React.Dispatch<React.SetStateAction<Row | null>>;
  submitCustomComponent(): void;
  saveAndAddCustomComponent?: () => void;
  closeCollapsible(): void;
  renderComponent: CustomComponentSpecification<Row>['renderComponent'];
  extraFieldsToolbarButtons: ExtraToolbarButton<Row>[];
  rows: Row[];
};

function CollapsibleComponent<Row>(props: Props<Row>) {
  const {
    isCollapse,
    customEditRow,
    selectedRowIndex,
    mode,
    saveAndAddCustomComponent,
    isSaveAndAddCustomComponent = false,
    onChange,
    submitCustomComponent,
    closeCollapsible,
    renderComponent,
    extraFieldsToolbarButtons,
    rows,
  } = props;

  const collapsibleButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'save',
        isDisabled: false,
        onClick: submitCustomComponent,
      },
      ...(isSaveAndAddCustomComponent
        ? [
            {
              icons: buttonIcons.saveAndContinue,
              title: 'Сохранить и продолжить',
              code: 'saveAndAdd',
              onClick: saveAndAddCustomComponent,
            },
          ]
        : []),
      {
        icons: buttonIcons.cancel,
        title: 'Отмена',
        code: 'cancel',
        isDisabled: false,
        onClick: closeCollapsible,
      },
      ...extraFieldsToolbarButtons.map<IconButtonProps>(item => ({
        ...item,
        isDisabled: (() => {
          if (item.checkIsDisabled) {
            return item.checkIsDisabled(customEditRow);
          }
          return false;
        })(),
        onClick: () => item.onClick(customEditRow, selectedRowIndex, rows),
      })),
    ],
    [
      submitCustomComponent,
      customEditRow,
      extraFieldsToolbarButtons,
      closeCollapsible,
      isSaveAndAddCustomComponent,
      saveAndAddCustomComponent,
      selectedRowIndex,
      rows,
    ],
  );

  return (
    <Collapse isCollapse={isCollapse}>
      <Toolbar buttons={collapsibleButtons} mode="list" />
      {renderComponent(customEditRow, onChange, selectedRowIndex, mode)}
    </Collapse>
  );
}

export { CollapsibleComponent };
