import React, { memo } from 'react';

import { ListEdit, FormComponent, InputSelect, TextInput, UploadFile } from 'components';

import { Award, Author } from 'types/models';
import { ParticipationTypeCode } from 'types/models/Participation';
import { formatAuthor } from './helpers';
import { Component as RecipientDirectory } from '../RecipientDirectory';
import useController from './controller';

type Props = {
  row: Award | null;
  onChange: (row: Award | null) => void;
  recipients: Author[];
  participationTypeCode: ParticipationTypeCode;
};

const AwardForm = ({ onChange, row, recipients, participationTypeCode }: Props) => {
  const {
    handleChange,
    handleFileChange,
    filteredRecipients,
    recipientDirectoryValidation,
    awardCategoryOptions,
  } = useController({
    onChange,
    award: row,
    recipients,
    participationTypeCode,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Категория награды">
          <InputSelect
            options={awardCategoryOptions}
            value={awardCategoryOptions.find(x => x.value === row?.category?.value) ?? null}
            onSelectChange={val => handleChange('category')(val)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Название награды" isRequired>
          <TextInput value={row?.name ?? ''} onChange={handleChange('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Номинации">
          <TextInput value={row?.nomination ?? ''} onChange={handleChange('nomination')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Файл копия документа">
          <UploadFile file={row?.file} onChange={handleFileChange} />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Description mode="warning">
        Внимание! Если не указать конкретных номинантов награды, то при сохранении участия, автоматически в номинанты будут
        добавлены все из списка участников.
      </FormComponent.Description>

      <ListEdit
        title="Номинанты. Выберите из списка участников мероприятия"
        rows={row?.recipients || []}
        onChange={(rows: Author[]) => {
          onChange({ ...row!, recipients: rows });
        }}
        columns={[{ label: '', formatValue: formatAuthor }]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Выберите номинантов из списка участников',
          renderComponent: (_, setRecipient) => (
            <RecipientDirectory recipients={filteredRecipients} setRecipient={setRecipient} />
          ),
          validation: recipientDirectoryValidation,
        }}
      />
    </FormComponent.Wrapper>
  );
};

export const Component = memo(AwardForm);
