import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Arguments = {
  projectId?: string | null;
  projectCodeId?: string | null;
  isDisabled?: boolean;
};

export function GetProjectCodeChangesInProject({ isDisabled, ...params }: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectCodeChangesInProject',
    isFiltersHidden: true,
    requestData: params,
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel({ isDisabled }),
      },
    },
  };
}
