import React from 'react';

import { FormComponent, Reference, ReferenceItem, TextInput } from 'components';

import {
  EditableMetadataField,
  EditableReferenceMetadataField,
} from 'features/Table/specifications/GetReferenceElementList/model';

type Props = {
  fields: EditableMetadataField[];
  handleChangeField(name: string, value: string | ReferenceItem | null): void;
  isViewMode: boolean;
};

function ThematicCategories(props: Props) {
  const { handleChangeField, fields, isViewMode } = props;

  const getComponent = (field: EditableMetadataField) => {
    const { name } = field;
    if (name === 'name' || name === 'code') {
      return (
        <TextInput
          value={(field.value as string) ?? ''}
          onChange={value => handleChangeField(name, value)}
          isDisabled={isViewMode}
        />
      );
    }

    if (name === 'citationSystem') {
      return (
        <Reference
          name={(field as EditableReferenceMetadataField).reference}
          value={(field as EditableReferenceMetadataField).value}
          relationTableModalTitle={field.caption}
          disabled={isViewMode}
          onChange={(reference: ReferenceItem) => {
            handleChangeField('thematicDomain', null);
            handleChangeField(name, reference);
          }}
        />
      );
    }

    if (name === 'thematicDomain') {
      const citationSystemField = fields.find(x => x.name === 'citationSystem') as EditableReferenceMetadataField | undefined;
      return (
        <Reference
          name={(field as EditableReferenceMetadataField).reference}
          value={(field as EditableReferenceMetadataField).value}
          relationTableModalTitle={field.caption}
          disabled={isViewMode}
          onChange={value => handleChangeField(field.name, value)}
          filters={
            citationSystemField && citationSystemField.value
              ? [{ key: 'citationSystem', values: [citationSystemField.value?.id] }]
              : []
          }
        />
      );
    }

    return null;
  };

  const renderField = (name: string) => {
    const field = fields.find(x => x.name === name);

    if (!field) {
      return null;
    }

    return (
      <FormComponent.Line>
        <FormComponent.Field label={field.caption} isRequired={field.required}>
          {getComponent(field)}
        </FormComponent.Field>
      </FormComponent.Line>
    );
  };

  return (
    <>
      {renderField('citationSystem')}
      {renderField('thematicDomain')}
      {renderField('code')}
      {renderField('name')}
    </>
  );
}

export { ThematicCategories };
