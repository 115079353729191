import { useState, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { useStream } from 'StreamRx';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Author, Document, Form, FileInfo, Table, RecordStatus as RS } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { Event } from 'types/models/Event';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { FieldSuggestionResponseData } from 'services/BackendAPI/makeBackendFieldSuggestionAPIConfiguration';
import { useAppDataContext } from 'features/AppData/context';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import useSavePublicationHook from 'features/Form/hooks/savePublicationHook';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { formatToRequest } from './setup/formatToRequest';
import { useFormContext } from 'features/Form/hooks';
import { validate } from './validate';
import { mobileRequestsStream, projectsStreams, eventsStreams } from './streams';
import { setByResponse } from './setup/setByResponse';
import { ModePublicationHeader, PublicationHeader } from 'features/Form/views/ModalHeader/publication';
import { showNotification } from 'features/Notifications';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  onClose: () => void;
};
const DEBOUNCE_TIME = 700;

const tabsId = 'PublicationFormOther';

export function useController({ onClose }: Props) {
  const { currentPerson, initialLanguageCode, settings, userSystemDepartment, enumMap } = useAppDataContext();

  const {
    look: { type, id, isElectronic, electronicType, name, initialTypeEdition, viewMode, editMode, arm, setTitle },
  } = useFormContext<Form.OtherFormLook>();

  const { isProfile } = usePrivatePageContext();
  const tableStreams = useLocalTableStreams();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const EditionSettings: Table.EnumValue[] = useMemo(() => enumMap?.TypeEdition?.values || [], [enumMap?.TypeEdition?.values]);
  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });
  const initialTypeEditionItem = useMemo(() => {
    const match = EditionSettings.find(editionType => editionType.value === initialTypeEdition);
    if (match) {
      return { ...match };
    }
    return null;
  }, [EditionSettings, initialTypeEdition]);

  const [publicationId, setPublicationId] = useState<string | undefined>(id);
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [projects, setProjects] = useState<PublicationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<PublicationMobileRequest[]>([]);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [topMessage, setTopMessage] = useState<string>('');
  const [isRecordElectronic, setIsRecordElectronic] = useState<boolean>(!!isElectronic);
  const [recordElectronicType, setRecordElectronicType] = useState<string>(electronicType || '');
  const [userDepartments, setUserDepartments] = useState<ReferenceItem[]>([]);
  const [publicationDepartment, setPublicationDepartment] = useState<ReferenceItem | null>(null);
  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      required: true,
      isValid: true,
      title: 'Название',
      maxSymbols: 2049,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    publicationDate: {
      value: '',
      isValid: true,
      title: 'Дата публикации',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationDate: { ...prevState.publicationDate, value: nextDate || '' },
        }));
      },
    },
    eisbn: {
      value: '',
      isValid: true,
      title: 'E-ISBN',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          eisbn: { ...prevState.eisbn, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          eisbn: { ...prevState.eisbn, isValid: true },
        }));
      },
    },
    isbn: {
      value: '',
      isValid: true,
      title: 'ISBN',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isbn: { ...prevState.isbn, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isbn: { ...prevState.isbn, isValid: true },
        }));
      },
    },
    textLanguage: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Язык текста',
      required: true,
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          textLanguage: {
            ...prevState.textLanguage,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          textLanguage: { ...prevState.textLanguage, isValid: true },
        }));
      },
    },
    publisher: {
      value: { label: '', id: '', foreignName: '' },
      isValid: true,
      title: 'Издательство',
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          publisher: {
            ...prevState.publisher,
            value,
          },
          address: {
            ...prevState.address,
            value: !prevState.address.value ? value.customFields?.city || '' : prevState.address.value,
          },
        }));
      },
    },
    address: {
      value: '',
      isValid: true,
      required: true,
      title: 'Место издания',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          address: { ...prevState.address, value, isValid: true },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          address: { ...prevState.address, isValid: true },
        }));
      },
    },
    year: {
      value: '',
      isValid: true,
      required: true,
      title: 'Год издания',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          year: { ...prevState.year, value, isValid: true },
        }));
      },
    },
    month: {
      value: '',
      isValid: true,
      title: 'Месяц',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          month: { ...prevState.month, value },
        }));
      },
    },
    day: {
      value: '',
      isValid: true,
      title: 'Число',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          day: { ...prevState.day, value },
        }));
      },
    },
    originalLanguage: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Перевод с языка',
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          originalLanguage: {
            ...prevState.originalLanguage,
            value,
            isValid: true,
          },
        }));
      },
    },
    volumeNumber: {
      value: '',
      isValid: true,
      title: 'Номер, название тома',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          volumeNumber: { ...prevState.volumeNumber, value },
        }));
      },
    },
    pageCount: {
      value: '',
      isValid: true,
      title: 'Количество страниц',
      maxValue: 100000,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageCount: { ...prevState.pageCount, value, isValid: true },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageCount: { ...prevState.pageCount, isValid: true },
        }));
      },
    },
    printPageCount: {
      value: '',
      isValid: true,
      title: 'Печатных листов',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, isValid: true },
        }));
      },
    },
    tirage: {
      value: '',
      isValid: true,
      title: 'Тираж',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          tirage: { ...prevState.tirage, value },
        }));
      },
    },
    republishData: {
      value: '',
      isValid: true,
      title: 'Сведения о переиздании',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          republishData: { ...prevState.republishData, value },
        }));
      },
    },
    categoryEdition: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Назначение издания',
      tooltipText: 'Вид издания по целевому назначению по ГОСТу 7.60-2003',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          categoryEdition: { ...prevState.categoryEdition, value },
        }));
      },
    },
    typeEdition: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Вид издания',
      required: true,
      onChange: (nextTypeEditionLabel: string) => {
        const selectedID = EditionSettings.filter(e => e.label === nextTypeEditionLabel).length
          ? EditionSettings.filter(e => e.label === nextTypeEditionLabel)[0].value
          : '';
        setFormFields((prevState: Form.Fields) => {
          const nextFormFields = { ...prevState };
          nextFormFields.typeEdition.value = { id: selectedID, label: nextTypeEditionLabel };
          return nextFormFields;
        });
      },
    },
    seriesTitle: {
      value: '',
      isValid: true,
      title: 'Книжная серия',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          seriesTitle: { ...prevState.seriesTitle, value },
        }));
      },
    },
    bibliographicRecord: {
      value: '',
      isValid: true,
      title: 'Библиографическая запись',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          bibliographicRecord: { ...prevState.bibliographicRecord, value },
        }));
      },
    },
    doi: {
      value: '',
      isValid: true,
      title: 'DOI',
      tooltipText: 'Цифровой идентификатор объекта, представленного в Интернете, пример заполнения: 10.1007/b136753',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, isValid: true },
        }));
      },
    },
    libraryLink: {
      value: '',
      isValid: true,
      title: 'Поле связи НБ',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          libraryLink: { ...prevState.libraryLink, value },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      required: true,
      title: 'Файл',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    electronicMediaSource: {
      value: '',
      isValid: true,
      required: false,
      title: 'Электронный носитель',
      tooltipText: 'Например: 1 электрон. опт диск (CD-R)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicMediaSource: {
            ...prevState.electronicMediaSource,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicMediaSource: {
            ...prevState.electronicMediaSource,
            isValid: true,
          },
        }));
      },
    },
    electronicSystemRequirements: {
      value: '',
      isValid: true,
      title: 'Системные требования',
      tooltipText: 'Например: Windows XP; CD-ROM; дисковод; мышь;',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicSystemRequirements: {
            ...prevState.electronicSystemRequirements,
            value,
            isValid: true,
          },
        }));
      },
    },
    grnti: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'ГРНТИ',
      tooltipText:
        // eslint-disable-next-line max-len
        'Выберите коды ГРНТИ областей знаний из справочника ГРНТИ. Если код отсутствует в справочнике, добавьте его',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          grnti: { ...prevState.grnti, value },
        }));
      },
    },
    udk: {
      value: '',
      isValid: true,
      title: 'УДК',
      tooltipText: 'Универсальная десятичная классификация. Введите код УДК, который присвоен публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          udk: { ...prevState.udk, value },
        }));
      },
    },
    study: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Область науки',
      tooltipText:
        // eslint-disable-next-line max-len
        'Область науки выбрать из справочника. Если область науки отсутствует в справочнике, напишите запрос администратору на добавление области в справочник',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          study: { ...prevState.study, value },
        }));
      },
    },
    annotation: {
      value: '',
      isValid: true,
      title: 'Аннотация',
      tooltipText: 'Если у публикации отсутствует аннотация, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, isValid: true },
        }));
      },
    },
    authorsKeyWords: {
      value: '',
      isValid: true,
      title: 'Авторские ключевые слова',
      tooltipText: 'Если у публикации отсутствуют ключевые слова, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsKeyWords: { ...prevState.authorsKeyWords, value },
        }));
      },
    },
    authorsCount: {
      value: '',
      isValid: true,
      title: 'Всего авторов в публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, isValid: true },
        }));
      },
    },
    fractionalScore: {
      value: '',
      isValid: true,
      title: 'Фракционный счёт университета',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, isValid: true },
        }));
      },
    },
    url: {
      value: '',
      isValid: true,
      required: !!isElectronic,
      tooltipText: 'Например: http://www.nir.ru/res/inv/ukazat25/index.php',
      title: 'Ссылка на публикацию',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          url: { ...prevState.url, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          url: { ...prevState.url, isValid: true },
        }));
      },
    },
    isConfirencesPublished: {
      value: false,
      isValid: true,
      title: 'В сборнике опубликованы материалы конференции',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => {
          if (prevState.isConfirencesPublished.value) {
            eventsStreams.setEvents.push([]);
          }
          return {
            ...prevState,
            isConfirencesPublished: {
              ...prevState.isConfirencesPublished,
              value: !prevState.isConfirencesPublished.value,
            },
          };
        });
      },
    },
    date: {
      value: '',
      isValid: true,
      title: 'Дата обращения',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          date: { ...prevState.date, value: nextDate || '' },
        }));
      },
    },
    foreignName: {
      value: '',
      required: false,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, isValid: true },
        }));
      },
    },
    foreignAnnotation: {
      value: '',
      required: false,
      isValid: true,
      title: 'Аннотация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, isValid: true },
        }));
      },
    },
    foreignAuthorsKeyWords: {
      value: '',
      required: false,
      isValid: true,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, isValid: true },
        }));
      },
    },
    foreignAuthors: {
      value: '',
      required: false,
      isValid: true,
      title: 'Авторы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, isValid: true },
        }));
      },
    },
  });

  const SUCH_LIKE_PUBLICATION_ID = 'PUBLICATION_FORM_OTHER';
  const { methods: getEventData, state: getEventDataState } = BackendAPI.useBackendAPI('GetEventById', {
    onSuccessfullCall: ({ data }) => {
      setEvents(prevEvents => [...prevEvents, data]);
    },
  });
  const { methods: getPublication, state: getPublicationState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublicationAPI, state: savePublicationAPIState } = BackendAPI.useBackendAPI('SavePublication');
  const { methods: getFieldSuggestion, state: getFieldSuggestionState } = BackendAPI.useBackendAPI('GetFieldSuggestion');
  const { methods: getUserDepartments, state: getUserDepartmentsState } = BackendAPI.useBackendAPI('GetUserDepartments');

  const [isAddressLoading, setIsAddressLoading] = useState<boolean>(false);
  const [addressOptions, setAddressOptions] = useState<{ value: string; label: string }[]>([]);
  const [selectTimeout, setSelectTimeout] = useState<null | NodeJS.Timeout>(null);

  const fetchPublication = useCallback(() => {
    if (type) {
      getPublication.callAPI(
        {
          simpleFields: {
            status: RecordStatus.DRAFT,
            typeEdition: initialTypeEditionItem?.value,
            type,
            isElectronic: isRecordElectronic,
            electronicType: (!!isRecordElectronic && recordElectronicType) || '',
            presetAuthorCategory: arm === 'pc' ? 'author' : '',
          },
          attrIdFields: {},
          arrayFields: {
            translations: { translation: [] },
            events: { event: events },
          },
          mobileRequests: { mobileRequest: mobileRequests },
          projects,
          file: undefined,
          id: publicationId,
          arm,
        },
        {
          onSuccessfullCall: ({ data }) => {
            const preparedData = data;

            setByResponse({ setFormFields, preparedData, name, initialTypeEditionItem });
            setIsRecordElectronic(preparedData.fields.isElectronic === 'true');
            setRecordElectronicType(preparedData.fields.electronicType);
            setAuthors(preparedData.members);
            setDocuments(preparedData.documents);
            setEvents(preparedData.events as any);
            setProjects(preparedData.projects);
            setMobileRequests(preparedData.mobileRequests);
            ReactDOM.unstable_batchedUpdates(() => {
              setPublicationInfo(preparedData);
            });
          },
        },
      );
    }
  }, [
    type,
    getPublication,
    isRecordElectronic,
    recordElectronicType,
    arm,
    events,
    mobileRequests,
    projects,
    publicationId,
    name,
    initialTypeEditionItem,
  ]);

  useEffect(() => {
    if (selectTimeout) {
      clearTimeout(selectTimeout);
    }
    if (formFields?.address.value) {
      setSelectTimeout(() =>
        setTimeout(() => {
          getFieldSuggestion.callAPI(
            {
              fieldName: 'Publication.address',
              filter: formFields.address?.value,
              limit: 7,
            },
            {
              onSuccessfullCall: ({ data }: any) => {
                setIsAddressLoading(false);
                if (data.success) {
                  setAddressOptions(() =>
                    (data as FieldSuggestionResponseData).fieldSuggestions.map(fieldSuggestion => ({
                      ...fieldSuggestion,
                      value: '',
                    })),
                  );
                }
              },
            },
          );
        }, DEBOUNCE_TIME),
      );
    } else {
      setIsAddressLoading(false);
    }
    return () => {
      if (selectTimeout !== null) {
        clearTimeout(selectTimeout);
      }
    };
  }, [formFields.address?.value]); // eslint-disable-line

  const prepareFormFields = useCallback(() => {
    const preparedFormFields = { ...formFields };

    const draftValidationResult = validate({
      formFields: preparedFormFields,
      events,
      isDraft: true,
      isEventsRequired: Boolean(formFields.isConfirencesPublished?.value),
    });

    const validationResult = validate({
      formFields: preparedFormFields,
      events,
      isDraft: false,
      isEventsRequired: Boolean(formFields.isConfirencesPublished?.value),
    });

    return {
      draftValidationResult,
      validationResult,
      preparedFormFields,
    };
  }, [events, formFields]);

  const fetchBibliographicRecord = useCallback<Form.FetchBibliographicRecordAction>(
    () =>
      new Promise(resolve => {
        if (!formFields.name?.value) {
          showNotification({ theme: 'danger', message: 'Не заполнено название' });
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            name: { ...prevState.name, isValid: false },
          }));

          resolve(null);
          return;
        }

        const fieldsForRequest = formatToRequest({
          isElectronic: isRecordElectronic,
          electronicType: recordElectronicType,
          formFields,
          projects,
          mobileRequests,
          status: 'ADDED',
          type,
          events,
          authors,
        });
        getPublication.callAPI(
          {
            ...fieldsForRequest,
            isBibliographicFetch: true,
            arm,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                bibliographicRecord: {
                  ...prevState.bibliographicRecord,
                  value: data.fields.bibliographicRecord,
                },
              }));
              resolve(data.fields.bibliographicRecord);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [arm, authors, events, formFields, getPublication, isRecordElectronic, mobileRequests, projects, recordElectronicType, type],
  );

  const savePublication = useCallback(
    ({ preparedFormFields, nextStatus, publicationMessage, publicationMessageType, isNeedClose }: Form.SavePublicationData) => {
      const isMessage = !!publicationMessageType?.id;
      const currentFormFields = preparedFormFields || formFields;
      const fieldsForRequest = formatToRequest({
        isElectronic: isRecordElectronic,
        electronicType: recordElectronicType,
        formFields: currentFormFields,
        projects,
        mobileRequests,
        status: nextStatus,
        type,
        events,
        authors,
      });

      savePublicationAPI.callAPI(
        {
          id: publicationId,
          documents,
          feedLine: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
          ...fieldsForRequest,
          arm,
          department: publicationDepartment,
        },
        {
          onSuccessfullCall: ({ data }) => {
            if (isNeedClose) {
              onClose();
              tableStreams.reloadTable.push();
            } else {
              updateWorkModeAfterSaveAndContinue();
              setPublicationId(data.status.id);
              if (workMode === 'editMode') {
                fetchPublication();
              }
            }

            showNotification({
              theme: data.success ? 'success' : 'danger',
              message: publicationId
                ? data.success
                  ? nextStatus === RecordStatus.DRAFT
                    ? 'Черновик успешно сохранен!'
                    : 'Другое издание успешно отредактировано!'
                  : data.message || 'Произошла ошибка при редактировании другого издания!'
                : data.success
                ? nextStatus === RecordStatus.DRAFT
                  ? 'Черновик успешно сохранен!'
                  : 'Другое издание успешно сохранен!'
                : data.message || 'Произошла ошибка при сохранении другого издания!',
            });
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      arm,
      authors,
      documents,
      events,
      fetchPublication,
      formFields,
      isRecordElectronic,
      mobileRequests,
      onClose,
      projects,
      publicationDepartment,
      publicationId,
      recordElectronicType,
      savePublicationAPI,
      tableStreams.reloadTable,
      type,
      updateWorkModeAfterSaveAndContinue,
      workMode,
    ],
  );

  const startCheckDuplicates = useCallback(
    ({ preparedFormFields, nextStatus, isNeedClose }: Form.StartCheckDuplicatesData) => {
      const successfullCallback = ({
        publicationMessage,
        publicationMessageType,
      }: {
        publicationMessage: string;
        publicationMessageType: ReferenceItem;
      }) => {
        savePublication({
          preparedFormFields,
          publicationMessage,
          publicationMessageType,
          isNeedClose,
          nextStatus,
        });
      };

      suchLikePublicationStreams.getSuchLikePublications.push({
        category: 'PUBLICATION',
        searchValue: formFields.name.value as string,
        publicationType: publicationInfo?.type as Form.PublicationTypeCode,
        successfullCallback,
        currentId: publicationId || null,
        isDefaultSave: !isNeedClose,
        componentId: SUCH_LIKE_PUBLICATION_ID,
      });
    },
    [
      formFields.name.value,
      publicationId,
      publicationInfo?.type,
      savePublication,
      suchLikePublicationStreams.getSuchLikePublications,
    ],
  );

  const {
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    loadPersonAuthorState,
  } = useSavePublicationHook({
    currentStatus: (publicationInfo?.status?.status as RS.Code) || RecordStatus.DRAFT,
    currentStatusText: publicationInfo?.status.text || 'Черновик',
    setFormFields,
    prepareFormFields,
    startCheckDuplicates,
    savePublication,
    fetchBibliographicRecord,
    initialLanguageCode: initialLanguageCode?.id ?? '',
    authors,
    setAuthors,
    isProfile,
    currentPerson,
    type,
    tabsId,
    authorsTabIndex: 1,
  });

  const { isLoading } = useIsLoading({
    callStates: [
      getEventDataState,
      getPublicationState,
      savePublicationAPIState,
      getFieldSuggestionState,
      loadPersonAuthorState,
      getUserDepartmentsState,
    ],
  });

  const onCreateBibliographicRecordClick = useCallback(() => {
    fetchBibliographicRecord();
  }, [fetchBibliographicRecord]);

  useEffect(() => {
    if (publicationId || workMode === 'addMode') {
      fetchPublication();
    }
    // eslint-disable-next-line
  }, [publicationId]);

  useStream(
    () => eventsStreams.getEvent,
    eventId => {
      if (eventId) {
        getEventData.callAPI({ eventId });
      }
    },
    [events, setEvents, eventsStreams.getEvent, getEventData],
  );

  useStream(
    () => eventsStreams.removeEvent,
    eventId => {
      const newEvents = events.filter(event => event.id !== eventId);
      setEvents(newEvents);
    },
    [events, setEvents, eventsStreams.removeEvent],
  );

  useStream(
    () => eventsStreams.setEvents,
    (eventsArray: Array<Event>) => setEvents(eventsArray),
    [setEvents, eventsStreams.setEvents],
  );

  useStream(
    () => projectsStreams.setProjects,
    (projectsData: Array<PublicationProject>) => setProjects(projectsData),
    [projects, setProjects, projectsStreams.setProjects],
  );

  useStream(
    () => projectsStreams.accept,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy,
        accepted,
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.accept, currentPerson],
  );

  useStream(
    () => projectsStreams.cancelAcceptance,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy: null,
        accepted: '',
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.cancelAcceptance],
  );

  useStream(
    () => mobileRequestsStream.setMobileRequests,
    (requests: Array<PublicationMobileRequest>) => setMobileRequests(requests),
    [mobileRequests, setMobileRequests, mobileRequestsStream.setMobileRequests],
  );

  useStream(
    () => mobileRequestsStream.accept,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy,
        accepted,
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.accept, currentPerson],
  );

  useStream(
    () => mobileRequestsStream.cancelAcceptance,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy: null,
        accepted: '',
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.cancelAcceptance],
  );

  const addCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        const citationSystemIndex = publicationInfo.citationSystems.findIndex(
          citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
        );
        const isNewCitationSystem = citationSystemIndex === -1;

        if (isNewCitationSystem) {
          setPublicationInfo(prevState => ({
            ...prevState!,
            citationSystems: [...prevState!.citationSystems, citationSystem],
          }));
        } else {
          setPublicationInfo(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState!.citationSystems));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return {
              ...prevState!,
              citationSystems: nextCitationSystems,
            };
          });
        }
      }
    },
    [publicationInfo],
  );

  const editCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      const citationSystemToUpdateIndex = (publicationInfo?.citationSystems || []).findIndex(
        citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
      );

      if (citationSystemToUpdateIndex !== -1) {
        const clonedCitationSystems: Form.CitationSystem[] = publicationInfo?.citationSystems
          ? R.clone(publicationInfo.citationSystems)
          : [];
        clonedCitationSystems.splice(citationSystemToUpdateIndex, 1, citationSystem);

        setPublicationInfo(prevState => prevState && { ...prevState!, citationSystems: clonedCitationSystems });
      }
    },
    [publicationInfo?.citationSystems],
  );

  const removeCitationSystem = useCallback(
    (citationSystemId: string) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        setPublicationInfo(prevState => ({
          ...prevState!,
          citationSystems: [
            ...prevState!.citationSystems.filter(
              (system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId,
            ),
          ],
        }));
      }
    },
    [publicationInfo],
  );

  useEffect(() => {
    if (setTitle) {
      setTitle(
        PublicationHeader({
          settings,
          mode: ModePublicationHeader.default,
          workMode,
          publicationId: publicationInfo?.status.id || '',
          publicationTypeCode: publicationInfo?.type || '',
          publicationElectronicTypeCode: isElectronic ? electronicType : '',
          departments: publicationInfo?.departments || [],
          status: publicationInfo?.status.text || '',
          publicationTypeEdition: {
            id: formFields.typeEdition?.value?.id || '',
            label: formFields.typeEdition?.value?.label || '',
          },
          isPreprint: false,
        }),
      );
    }
  }, [
    electronicType,
    formFields.typeEdition?.value?.id,
    formFields.typeEdition?.value?.label,
    isElectronic,
    publicationInfo?.departments,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
    workMode,
  ]);

  useLayoutEffect(() => {
    if (!id)
      getUserDepartments.callAPI(
        { isParent: false },
        {
          onSuccessfullCall: ({ data }) => {
            const d = data.map(x => x.ref);
            setUserDepartments(d);
            setPublicationDepartment(d.find(x => x.id === userSystemDepartment?.id) || null);
          },
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    workMode,
    isAddressLoading,
    addressOptions,
    setIsAddressLoading,
    formFields,
    onCreateBibliographicRecordClick,
    EditionSettings,
    authors,
    setAuthors,
    documents,
    setDocuments,
    events,
    isElectronic: isRecordElectronic,
    electronicType: recordElectronicType,
    projects,
    mobileRequests,
    publicationId,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    topMessage,
    setTopMessage,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
  };
}
