import React from 'react';
import { block } from 'bem-cn';

import { TextInput, TextInputMode } from 'components';

import { FILTER_STRUCRURE } from 'features/Table/helpers';
import { ColumnType } from 'types/models/Table';
import useController from './controller';

import './style.scss';

export type Props = {
  value: string;
  filterStructure: string;
  setColumnFilter: (nextValue: string) => void;
  columnType: ColumnType;
  columnPrecision?: number;
};

const b = block('grid-numeric-filter');

export const NumericFilter = ({ value, filterStructure, setColumnFilter, columnType, columnPrecision }: Props) => {
  const {
    title,
    inputValueTo,
    isMoneyFilter,
    inputValueFrom,
    isIntegerFilter,
    onToInputChange,
    onFromInputChange,
  } = useController({
    value,
    filterStructure,
    setColumnFilter,
    columnType,
  });

  return (
    <div className={b()} title={title || 'Введите значение'}>
      <TextInput
        mode={TextInputMode.number}
        value={inputValueFrom}
        onChange={onFromInputChange}
        settings={{
          isThousands: !!isMoneyFilter,
          decimal: { ...(isIntegerFilter ? false : { limit: columnPrecision }) },
        }}
      />
      {filterStructure === FILTER_STRUCRURE.range && (
        <>
          <div className={b('separator')}>&ndash;</div>
          <TextInput
            mode={TextInputMode.number}
            value={inputValueTo}
            onChange={onToInputChange}
            settings={{
              isThousands: !!isMoneyFilter,
              decimal: { ...(isIntegerFilter ? false : { limit: columnPrecision }) },
            }}
          />
        </>
      )}
    </div>
  );
};
