import { ElectronicTypeCode, PublicationTypeCode, Template } from 'types/models/Form';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

export type PublicationType = {
  id: string;
  label: string;
  labelDeclined: string;
  code: PublicationTypeCode | ElectronicTypeCode;
  template?: Template;
  parentCode?: PublicationTypeCode;
  text?: string;
  isCompilationPage?: boolean;
  isOnPaperOnly?: boolean;
  isHidden?: boolean;
};

export const otherUniversityPublicationTypes: String[] = [
  PublicationTypes.MAGAZINE.code,
  PublicationTypes.COMPILATION_CONFERENCE.code,
  PublicationTypes.COMPILATION_OTHER.code,
  PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
  PublicationTypes.OTHER_BOOK.code,
  PublicationTypes.MONOGRAPH.code,
];

const publicationTypes: PublicationType[] = [
  {
    id: '1',
    label: PublicationTypes.MAGAZINE_ARTICLE.label,
    labelDeclined: PublicationTypes.MAGAZINE_ARTICLE.genitiveCaseLabel,
    code: PublicationTypes.MAGAZINE_ARTICLE.code,
    template: PublicationTypes.MAGAZINE_ARTICLE.template,
  },
  {
    id: '2',
    label: PublicationTypes.MONOGRAPH.label,
    labelDeclined: PublicationTypes.MONOGRAPH.genitiveCaseLabel,
    code: PublicationTypes.MONOGRAPH.code,
    template: PublicationTypes.MONOGRAPH.template,
    text:
      // eslint-disable-next-line max-len
      'Научное или научно-популярное издание, содержащее полное и всестороннее исследование одной проблемы или темы и принадлежащее одному или нескольким авторам',
  },
  {
    id: '19',
    label: PublicationTypes.MONOGRAPH_CHAPTER.label,
    labelDeclined: PublicationTypes.MONOGRAPH_CHAPTER.genitiveCaseLabel,
    code: PublicationTypes.MONOGRAPH_CHAPTER.code,
    template: PublicationTypes.MONOGRAPH_CHAPTER.template,
    text:
      // eslint-disable-next-line max-len
      'Глава в научном или научно-популярном издании, содержащем полное и всестороннее исследование одной проблемы или темы и принадлежащее одному или нескольким авторам',
  },
  {
    id: '3',
    label: PublicationTypes.TEXTBOOK.label,
    labelDeclined: PublicationTypes.TEXTBOOK.genitiveCaseLabel,
    code: PublicationTypes.TEXTBOOK.code,
    template: PublicationTypes.TEXTBOOK.template,
    text:
      // eslint-disable-next-line max-len
      'Учебное издание, содержащее систематическое изложение учебной дисциплины, её раздела, части, соответствующее учебной программе, и официально утверждённое в качестве данного вида издания',
  },
  {
    id: '4',
    label: PublicationTypes.TUTORIAL.label,
    labelDeclined: PublicationTypes.TUTORIAL.genitiveCaseLabel,
    code: PublicationTypes.TUTORIAL.code,
    template: PublicationTypes.TUTORIAL.template,
    text:
      // eslint-disable-next-line max-len
      'Учебное издание, дополняющее или заменяющее частично или полностью учебник, официально утверждённое в качестве данного вида издания',
  },
  {
    id: '5',
    label: PublicationTypes.METHODICAL_EDITION.label,
    labelDeclined: PublicationTypes.METHODICAL_EDITION.genitiveCaseLabel,
    code: PublicationTypes.METHODICAL_EDITION.code,
    template: PublicationTypes.METHODICAL_EDITION.template,
    text:
      // eslint-disable-next-line max-len
      'Учебное издание, содержащее материалы по методике преподавания, изучения учебной дисциплины, её раздела, части или воспитания',
  },
  {
    id: '6',
    label: PublicationTypes.METHODICAL_EDITION_OTHER.label,
    labelDeclined: PublicationTypes.METHODICAL_EDITION_OTHER.genitiveCaseLabel,
    code: PublicationTypes.METHODICAL_EDITION_OTHER.code,
    template: PublicationTypes.METHODICAL_EDITION_OTHER.template,
    text:
      // eslint-disable-next-line max-len
      'Учебное наглядное пособие, рабочая тетрадь, самоучитель, хрестоматия, практикум, задачник, учебная программа, учебный комплект',
  },
  {
    id: '7',
    label: PublicationTypes.COMPILATION_SCIENTIFIC_WORK.label,
    labelDeclined: PublicationTypes.COMPILATION_SCIENTIFIC_WORK.genitiveCaseLabel,
    code: PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
    template: PublicationTypes.COMPILATION_SCIENTIFIC_WORK.template,
    text: 'Сборник, содержащий исследовательские материалы научных учреждений, учебных заведений или обществ',
    isCompilationPage: true,
  },
  {
    id: '8',
    label: PublicationTypes.COMPILATION_CONFERENCE.label,
    labelDeclined: PublicationTypes.COMPILATION_CONFERENCE.genitiveCaseLabel,
    code: PublicationTypes.COMPILATION_CONFERENCE.code,
    template: PublicationTypes.COMPILATION_CONFERENCE.template,
    text: 'Непериодический сборник, содержащий итоги конференции в виде докладов, рекомендаций, решений',
    isCompilationPage: true,
  },
  {
    id: '9',
    label: PublicationTypes.COMPILATION_OTHER.label,
    labelDeclined: PublicationTypes.COMPILATION_OTHER.genitiveCaseLabel,
    code: PublicationTypes.COMPILATION_OTHER.code,
    template: PublicationTypes.COMPILATION_OTHER.template,
    isCompilationPage: true,
    text:
      // eslint-disable-next-line max-len
      'Издание, содержащее краткие сведения научного или прикладного характера, расположенные в порядке, удобном для их быстрого отыскания, не предназначенное для сплошного чтения',
  },
  {
    id: '10',
    label: PublicationTypes.THESIS.label,
    labelDeclined: PublicationTypes.THESIS.genitiveCaseLabel,
    code: PublicationTypes.THESIS.code,
    template: PublicationTypes.THESIS.template,
  },
  {
    id: '11',
    label: PublicationTypes.ARTICLE_SCIENTIFIC_WORK.label,
    labelDeclined: PublicationTypes.ARTICLE_SCIENTIFIC_WORK.genitiveCaseLabel,
    code: PublicationTypes.ARTICLE_SCIENTIFIC_WORK.code,
    template: PublicationTypes.ARTICLE_SCIENTIFIC_WORK.template,
    parentCode: PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
    text: 'Статья в сборнике, содержащем исследовательские материалы научных учреждений, учебных заведений или обществ',
  },
  {
    id: '12',
    label: PublicationTypes.ARTICLE_CONFERENCE.label,
    labelDeclined: PublicationTypes.ARTICLE_CONFERENCE.genitiveCaseLabel,
    code: PublicationTypes.ARTICLE_CONFERENCE.code,
    template: PublicationTypes.ARTICLE_CONFERENCE.template,
    parentCode: PublicationTypes.COMPILATION_CONFERENCE.code,
    text: 'Статья в непериодическом сборнике, содержащем итоги конференции в виде докладов, рекомендаций, решений',
  },
  {
    id: '13',
    label: PublicationTypes.ARTICLE_OTHER.label,
    labelDeclined: PublicationTypes.ARTICLE_OTHER.genitiveCaseLabel,
    code: PublicationTypes.ARTICLE_OTHER.code,
    template: PublicationTypes.ARTICLE_OTHER.template,
    parentCode: PublicationTypes.COMPILATION_OTHER.code,
    text:
      // eslint-disable-next-line max-len
      'Статья в издании, содержащем краткие сведения научного или прикладного характера, расположенные в порядке, удобном для их быстрого отыскания, не предназначенное для сплошного чтения',
  },
  {
    id: '14',
    label: PublicationTypes.DISSERTATION.label,
    labelDeclined: PublicationTypes.DISSERTATION.genitiveCaseLabel,
    code: PublicationTypes.DISSERTATION.code,
    template: PublicationTypes.DISSERTATION.template,
    isOnPaperOnly: true,
  },
  {
    id: '15',
    label: PublicationTypes.SYNOPSIS.label,
    labelDeclined: PublicationTypes.SYNOPSIS.genitiveCaseLabel,
    code: PublicationTypes.SYNOPSIS.code,
    template: PublicationTypes.SYNOPSIS.template,
    text:
      // eslint-disable-next-line max-len
      'Научное издание, содержащее составленный автором реферат проведённого им исследования, представляемого на соискание учёной степени',
    isOnPaperOnly: true,
  },
  {
    id: '16',
    label: PublicationTypes.OTHER_BOOK.label,
    labelDeclined: PublicationTypes.OTHER_BOOK.genitiveCaseLabel,
    code: PublicationTypes.OTHER_BOOK.code,
    template: PublicationTypes.OTHER_BOOK.template,
    text:
      // eslint-disable-next-line max-len
      'Альманах, антология, документально-художественное издание, научно-художественное издание, песенник, инструкция, прейскурант, стандарт, уставное издание, инструктивно-методическое издание, практическое пособие, практическое руководство, памятка, промышленный каталог, номенклатурный каталог',
  },
  {
    id: '17',
    label: PublicationTypes.OTHER_PART.label,
    labelDeclined: PublicationTypes.OTHER_PART.genitiveCaseLabel,
    code: PublicationTypes.OTHER_PART.code,
    template: PublicationTypes.OTHER_PART.template,
    text:
      // eslint-disable-next-line max-len
      'Статья в альманахе, антологии, документально-художественном издании, научно-художественном издании, песеннике, инструкции, прейскуранте, стандарте, уставном издании, инструктивно-методическом издании, практическом пособии, практическом руководстве, памятке, промышленном каталоге, номенклатурном каталоге',
  },

  {
    id: '18',
    label: PublicationTypes.DEPOSITED.label,
    labelDeclined: PublicationTypes.DEPOSITED.genitiveCaseLabel,
    code: PublicationTypes.DEPOSITED.code,
    template: PublicationTypes.DEPOSITED.template,
    isHidden: true,
  },
  {
    id: '19',
    label: PublicationTypes.COMPILATION_ARTICLES.label,
    labelDeclined: PublicationTypes.COMPILATION_ARTICLES.genitiveCaseLabel,
    code: PublicationTypes.COMPILATION_ARTICLES.code,
    template: PublicationTypes.COMPILATION_ARTICLES.template,
    text: '',
    isHidden: true,
    isCompilationPage: true,
  },
  {
    id: '20',
    label: PublicationTypes.ARTICLE_ARTICLES.label,
    labelDeclined: PublicationTypes.ARTICLE_ARTICLES.genitiveCaseLabel,
    code: PublicationTypes.ARTICLE_ARTICLES.code,
    template: PublicationTypes.ARTICLE_ARTICLES.template,
    text: '',
    isHidden: true,
  },
  {
    id: '21',
    label: PublicationTypes.PREPRINT_ARTICLE.label,
    labelDeclined: PublicationTypes.PREPRINT_ARTICLE.genitiveCaseLabel,
    code: PublicationTypes.PREPRINT_ARTICLE.code,
    template: PublicationTypes.PREPRINT_ARTICLE.template,
    text: '',
  },
];

export default publicationTypes;
