import React, { useEffect, useMemo } from 'react';

import {
  FormComponent,
  Tabs,
  Tab,
  Reference,
  Checkbox,
  ListEdit,
  buttonIcons,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  CustomSelect,
} from 'components';

import { Documents } from 'features/Documents';
import { GetSecurityDocumentListForSecurityDocumentContract } from 'features/Table/specifications';
import { downloadFile, formatNumber, getAuthToken } from 'utils/Helpers';
import { PaymentFields } from './PaymentFields';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function SecurityDocumentContractForm({ viewMode, editMode, onClose }: Props) {
  const {
    documents,
    endDate,
    handleDocumentsFieldChange,
    handleEnterpriseCustomerFieldChange,
    handleFormSubmit,
    handleIsTransferedFieldChange,
    handleKindFieldChange,
    handleNumberFieldChange,
    handlePaymentConditionFieldChange,
    handlePaymentKindFieldChange,
    handlePriceFieldChange,
    handleRangeDateFieldChange,
    isTransfered,
    kind,
    number,
    paymentCondition,
    paymentKind,
    price,
    startDate,
    workMode,
    payments,
    handlePaymentsFieldChange,
    userToken,
    id,
    selectedEnterpriseCustomFields,
    conclusionDate,
    handleConclusionDateChange,
    handleRegistrationDateChange,
    handleRegistrationNumberChange,
    registrationDate,
    registrationNumber,
    note,
    handleNoteChange,
    securityDocuments,
    handleSecurityDocumentsChange,
    enterpriseCustomerOriginal,
    enterpriseCustomerRowConverter,
    enterpriseCustomerSpecification,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const token = getAuthToken();

  const {
    look: { setTitle },
  } = useFormContext();

  const disabled = useMemo(() => workMode === 'viewMode', [workMode]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          return [
            (() => {
              if (workMode === 'addMode') {
                return 'Добавление';
              }
              if (workMode === 'viewMode') {
                return 'Просмотр';
              }
              return 'Редактирование';
            })(),
            'договора о распоряжении интеллектуальным правом',
            id ? `(ID ${id})` : '',
          ]
            .filter(x => x)
            .join(' ');
        })(),
      );
    }
  }, [id, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Договор">
            <FormComponent.ColumnWrapper>
              <FormComponent.Column>
                <FormComponent.Line>
                  <FormComponent.Field>
                    <Reference
                      disabled={disabled}
                      isRequired
                      name="RefContractKind"
                      onChange={handleKindFieldChange}
                      relationTableModalTitle='Справочник "Виды договоров"'
                      theme="big"
                      title="Вид договора"
                      value={kind}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Номер договора" isRequired>
                    <TextInput value={number} onChange={handleNumberFieldChange} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="ОИС">
                    <ListEdit
                      rows={securityDocuments}
                      onChange={handleSecurityDocumentsChange}
                      columns={[{ label: 'Наименование', formatValue: x => x.value }]}
                      isDeleteConfirmEnabled
                      withMessages
                      isToolbarDisabled={viewMode}
                      specification={{
                        mode: 'relationTableModal',
                        relationTableModalTitle: 'Справочник "Список ОИС"',
                        modalTableRowConverter: row => {
                          return { id: row.id, name: row.Label, value: row.Label };
                        },
                        modalTableSpecification: GetSecurityDocumentListForSecurityDocumentContract,
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line hasFreeFormat>
                  <FormComponent.Field label="Дата заключения договора" isRequired>
                    <TextDateTime value={conclusionDate} onChange={handleConclusionDateChange} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="padded" hasFreeFormat>
                  <FormComponent.Field label="Срок действия" isRequired>
                    <TextDateTime
                      isRange
                      value={`${startDate || ''}-${endDate || ''}`}
                      onChange={(value: string) => {
                        const dates = value.split('-');
                        handleRangeDateFieldChange(dates[0] || '', dates[1] || '');
                      }}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Field>
                  <CustomSelect
                    disabled={disabled}
                    isRequired
                    title="Заключен с"
                    original={enterpriseCustomerOriginal}
                    relationTableModalTitle={'Список организаций из справочника "Заказчики"'}
                    onDeleteFunction={() => handleEnterpriseCustomerFieldChange(null)}
                    onChange={handleEnterpriseCustomerFieldChange}
                    modalTableRowConverter={enterpriseCustomerRowConverter}
                    modalTableSpecification={enterpriseCustomerSpecification}
                  />
                </FormComponent.Field>
                <FormComponent.Line>
                  <FormComponent.Field label="">
                    <FormComponent.Description classMixin="is-hint">
                      {[
                        selectedEnterpriseCustomFields?.name ? `${selectedEnterpriseCustomFields.name}` : '',
                        selectedEnterpriseCustomFields?.country ? `${selectedEnterpriseCustomFields.country?.label}` : '',
                        selectedEnterpriseCustomFields?.postCode ? `${selectedEnterpriseCustomFields.postCode}` : '',
                        selectedEnterpriseCustomFields?.district ? `${selectedEnterpriseCustomFields.district}` : '',
                        selectedEnterpriseCustomFields?.city ? `${selectedEnterpriseCustomFields.city}` : '',
                        selectedEnterpriseCustomFields?.street ? `${selectedEnterpriseCustomFields.street}` : '',
                      ]
                        .filter(x => x)
                        .join(', ')}
                    </FormComponent.Description>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line hasFreeFormat>
                  <FormComponent.Field label="Дата регистрации">
                    <TextDateTime value={registrationDate} onChange={handleRegistrationDateChange} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Номер регистрации">
                    <TextInput value={registrationNumber} onChange={handleRegistrationNumberChange} />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column>
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Стоимость">
                    <TextInput
                      mode={TextInputMode.number}
                      value={price ? formatNumber(price.toString()) : ''}
                      onChange={handlePriceFieldChange}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Field>
                  <Reference
                    disabled={disabled}
                    name="RefPaymentKind"
                    onChange={handlePaymentKindFieldChange}
                    relationTableModalTitle='Справочник "Виды оплаты"'
                    theme="big"
                    title="Вид оплаты"
                    value={paymentKind}
                    withoutDropdown
                  />
                </FormComponent.Field>
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Условия оплаты">
                    <TextInput value={paymentCondition} onChange={handlePaymentConditionFieldChange} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Field label="">
                  <Checkbox
                    label="Передано в уставной фонд предприятия"
                    checked={!!isTransfered}
                    onChange={handleIsTransferedFieldChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
                <FormComponent.Line lineSize="padded">
                  <FormComponent.Field label="Дополнительные сведения">
                    <TextArea
                      mode={TextAreaMode.modal}
                      settings={{ rows: 3 }}
                      value={note}
                      onChange={handleNoteChange}
                      isDisabled={!!disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </Tab>
          <Tab title="Платежи">
            <ListEdit
              title="Лицензионные отчисления по договору"
              visibleToolbarButtons={['edit']}
              editButtonTitle="Прикрепить файл"
              extraToolbarButtons={[
                {
                  icons: buttonIcons.download,
                  title: 'Скачать файл',
                  code: 'downloadFile',
                  checkIsDisabled: row => !row?.file?.id || !userToken,
                  onClick: row => downloadFile(row?.file?.id, token),
                },
              ]}
              withMessages
              isDeleteConfirmEnabled
              isToolbarDisabled={disabled}
              rows={payments}
              onChange={handlePaymentsFieldChange}
              columnIndexesForSumTotal={[2, 3]}
              columns={[
                { label: 'Дата', formatValue: x => x.receivingDocument?.cashDocument?.payDate || '' },
                { label: 'Номер документа', formatValue: x => x.receivingDocument?.cashDocument?.number || '' },
                {
                  label: 'Сумма',
                  formatValue: x => formatNumber(x.receivingDocument?.amount || ''),
                },
                { label: 'В т.ч. НДС', formatValue: x => formatNumber(x.receivingDocument?.nds || '') },
                { label: 'Файл', formatValue: x => x.file?.name || '' },
              ]}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onFieldChange) => (
                  <PaymentFields entry={row} onFieldChange={onFieldChange as any} workMode={workMode} />
                ),
              }}
            />
          </Tab>
          <Tab title="Прикрепленные документы">
            <Documents
              documents={documents}
              setDocuments={handleDocumentsFieldChange}
              disabled={disabled}
              isShowSource
              sourceLabelForLocalDocs="Текущий договор на ИС"
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(SecurityDocumentContractForm);
