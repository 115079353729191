import React, { useCallback } from 'react';

import { Reference, ReferenceItem, Option, InputSelect, FormComponent } from 'components';

import { ScientificIndex } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { Mode } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/types';

type Props = {
  scientificIndexes: ScientificIndex.ScientificIndex[];
  citationSystem: CitationSystem | null;
  selectedScientificIndex: ScientificIndex.ScientificIndex | null;
  citationSystems: CitationSystem[];
  mode: Mode;
  publicationType: string;
  onChangeCitationSystem(val: CitationSystem | null): void;
  onChangeScientificIndex(index: ScientificIndex.ScientificIndex | null): void;
};

function Component(props: Props) {
  const {
    onChangeCitationSystem,
    onChangeScientificIndex,
    citationSystem,
    scientificIndexes,
    selectedScientificIndex,
    citationSystems,
    publicationType,
    mode,
  } = props;

  const handleChangeCitationSystem = useCallback(
    (ref: ReferenceItem) => {
      const system = citationSystems.find(x => x.id === ref.id) ?? null;
      onChangeCitationSystem(system);
    },
    [onChangeCitationSystem, citationSystems],
  );

  const handleChangeScientificIndex = useCallback(
    (option: Option | null) => {
      const scientificIndex = scientificIndexes.find(x => x.id === option?.value) ?? null;
      onChangeScientificIndex(scientificIndex);
    },
    [onChangeScientificIndex, scientificIndexes],
  );

  function getPublicationDomain(val: string): string {
    return val === 'MAGAZINE' ? 'MAGAZINE' : 'PUBLICATION';
  }

  const options = scientificIndexes
    .filter(x => x.citationSystem.id === citationSystem?.id && x.domain.text === getPublicationDomain(publicationType))
    .map(x => ({ label: x.label, value: x.id }));

  return (
    <FormComponent.Line>
      <FormComponent.Field label="Система цитирования" isRequired>
        <Reference
          name="RefCitationSystem"
          value={citationSystem ? { id: citationSystem.id, label: citationSystem.label } : null}
          onChange={handleChangeCitationSystem}
          relationTableModalTitle='Справочник "Системы цитирования"'
          filters={[{ key: 'id', values: citationSystems.map(x => x.id) }]}
          disabled={mode === 'edit' || !citationSystems.length}
        />
      </FormComponent.Field>
      <FormComponent.Field label="Показатель" isRequired>
        <InputSelect
          options={options}
          value={selectedScientificIndex ? { value: selectedScientificIndex.id, label: selectedScientificIndex.label } : null}
          disabled={mode === 'edit' || !citationSystems.length}
          onSelectChange={handleChangeScientificIndex}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
}

export const SelectScientificIndex = React.memo(Component);
