import { Project } from 'types/models';

export function validate(report: Project.StateRegistrationReport | null) {
  if (!report) {
    return [];
  }

  const isValidStageName = report.stageName.length <= 2000;
  const isValidExpectedResults = report.expectedResults.length <= 5000;
  const isValidInterdisciplinaryAproachJustification = report.interdisciplinaryAproachJustification.length <= 3000;

  return [
    { isValid: isValidStageName, invalidMessage: 'Превышено максимальное количество знаков - Название этапа' },
    {
      isValid: isValidExpectedResults,
      invalidMessage: 'Превышено максимальное количество знаков - Полученные результаты и их применимость',
    },
    {
      isValid: isValidInterdisciplinaryAproachJustification,
      invalidMessage: 'Превышено максимальное количество знаков - Обоснование междисциплинарного подхода',
    },
  ];
}
