import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { UserEditFormLook } from 'types/models/Form/look/userEdit';
import { Modal } from 'components';

type UserEditTemplateProps = {
  login: string;
  connectedPerson: string;
} & F.TemplateProps;

const UserEditTemplate: React.FC<UserEditTemplateProps> = ({
  isOpen,
  onClose,
  id,
  login,
  connectedPerson,
  relatedTableState,
}: UserEditTemplateProps) => {
  const look: UserEditFormLook = {
    apiID: 'GetUser',
    template: 'UserEditForm',
    editMode: true,
    id,
    login,
    relatedTableState,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Редактирование пользователя ${login}${connectedPerson ? ` (${connectedPerson})` : ''}`}
      size={['extralarge', 'large-height']}
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(UserEditTemplate);
