import { useCallback, useState, useMemo } from 'react';

import { Option } from 'components';

import { Item } from 'types/models/common';
import { Project } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { getOptionsFromEnum, getStagesOptions } from 'features/Form/looks/project/ProjectForm/helpers';
import { format, parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';

type Arguments = {
  onClose(): void;
  onSubmit(stageNumber: string, reportType?: Item, date?: string): void;
  stages: Project.Stage[];
  reports: Project.StateRegistrationReport[];
};

export function useController(args: Arguments) {
  const { onClose, onSubmit, stages, reports } = args;

  const { enumMap } = useAppDataContext();

  const [stageNumberOption, setStageNumberOption] = useState<Option | null>(null);
  const [reportTypeOption, setReportTypeOption] = useState<Option | null>(null);
  const [date, setDate] = useState<string>('');

  const options = useMemo(
    () => getStagesOptions(stages).filter(option => !reports.map(report => report.stageNumber).includes(option.value)),
    [stages, reports],
  );
  const reportTypeOptions = getOptionsFromEnum(enumMap.StageReportType);

  const clearFormData = useCallback(() => {
    setStageNumberOption(null);
    setReportTypeOption(null);
    setDate('');
  }, []);

  const handleStageChange = useCallback(
    (value: Option | null) => {
      setStageNumberOption(value);
      const selectedStage = stages.filter(stage => stage.number == value?.value)[0];
      if (selectedStage && !!selectedStage.endDate) {
        const endDate = parse(selectedStage.endDate, formatStr, new Date());
        endDate.setDate(endDate.getDate() + 30);
        setDate(format(endDate, formatStr));
      }
    },
    [stages],
  );

  const handleSubmit = useCallback(() => {
    if (!stageNumberOption || !date || !reportTypeOption) {
      showNotification({ message: 'Заполните необходимые поля', theme: 'danger' });
      return;
    }
    onSubmit(stageNumberOption.value, reportTypeOption || undefined, date);
    clearFormData();
    onClose();
  }, [stageNumberOption, reportTypeOption, date, onSubmit, onClose, clearFormData]);

  return {
    options,
    reportTypeOptions,
    date,
    stageNumberOption,
    reportTypeOption,
    handleSubmit,
    handleStageChange,
    setReportTypeOption,
    setDate,
  };
}
