import React, { ReactNode, useRef, useState } from 'react';
import { setup } from 'bem-cn';

import { Icon, Popover } from 'components';

import { Props as PermissionProps, Permission } from 'components/Permission';
import { ButtonAttributes } from 'types/models/common';
import { Props as IconProps } from 'components/Icon';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('button-new');

export enum ButtonMode {
  /** Without styles */
  default = 'default',
  /** Primary color style */
  primary = 'primary',
  /** Secondary color style */
  secondary = 'secondary',
}

type ExpandedList = {
  /** @property {function} callback - List of items */
  list(): Map<string, string> | Promise<Map<string, string>>;
  /** @property {function} callback - (optional) Callback on list item click */
  callback?({ value, name }: { value: string; name: string }): void;
};

/**
 * Settings for input component
 */
export type Props = ButtonAttributes & {
  /** @property {ButtonMode} mode - (optional) Mode of button (See ButtonMode enum) (ButtonMode.default by default) */
  mode?: ButtonMode;
  /** @property {IconProps} icon - (optional) Icon props (See Icon props type) */
  icon?: IconProps;
  /** @property {string} text - (optional) Text for button */
  text?: string;
  /** @property {ButtonPermission} permission - (optional) Permissions for button (See ButtonPermission type) */
  permission?: Pick<PermissionProps, 'name' | 'profileName'>;
  /** @property {string} hint - (optional) Tooltip on button hover */
  hint?: string;
  /** @property {function} onClick - (optional) Callback on button click */
  onClick?(e?: React.MouseEvent<HTMLButtonElement>): void;
  /** @property {ExpandedList} expandedList - (optional) Show list on click (See ExpandedList type) */
  expandedList?: ExpandedList;
  /** @property {boolean} isHidden - (optional) Set is hidden button (false by default) */
  isHidden?: boolean;
  /** @property {boolean} isDisabled - (optional) Set is disabled button (false by default) */
  isDisabled?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
};

/** Examples
// src\features\Table\specifications\GetPublicationExtendedList\LeftPanelForThirdLevel\index.tsx
<Button
  icon={{ type: 'print' }}
  onClick={() => console.info(1)}
  expandedList={{ list: getReports, callback: handleSetCurrentReport }}
/>
 */

/**
 * App button (any)
 *
 * @param {ButtonMode} mode - (optional) Mode of button (See ButtonMode enum) (ButtonMode.default by default)
 * @param {IconProps} icon - (optional) Icon props (See Icon props type)
 * @param {string} text - (optional) Text for button
 * @param {Pick<PermissionProps>} permission - (optional) Permissions for button (See ButtonPermission type)
 * @param {string} hint - (optional) Tooltip on button hover
 * @param {function} onClick - (optional) Callback on button click
 * @param {ExpandedList} expandedList - (optional) Show list on click (See ExpandedList type)
 * @param {boolean} isHidden - (optional) Set is hidden button (false by default)
 * @param {boolean} isDisabled - (optional) Set is disabled button (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const Button = React.memo((props: Props) => {
  const {
    mode = ButtonMode.default,
    icon,
    text,
    permission,
    hint,
    onClick,
    expandedList,
    isHidden = false,
    isDisabled = false,
    classMixin,
    ...restProps
  } = props;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [listPopover, setListPopover] = useState<ReactNode[]>([]);
  const [isOpenedPopover, setIsOpenedPopover] = useState<boolean>(false);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDisabled && onClick) {
      onClick(e);
    }

    if (expandedList) {
      const { callback } = expandedList;

      const mapExpandedList: Map<string, string> = await expandedList.list();

      if (mapExpandedList.size) {
        const result: ReactNode[] = [];

        mapExpandedList?.forEach((value, name) => {
          result.push(
            callback ? (
              <div key={value + name} className={b('list-item')} onClick={() => callback({ value, name })}>
                {name}
              </div>
            ) : (
              <a key={value + name} className={b('list-item')} href={value} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            ),
          );
        });

        setListPopover(result);
      }
      setIsOpenedPopover(true);
    } else {
      setListPopover([]);
    }
  };

  const closePopover = () => setIsOpenedPopover(false);

  const button = (
    <>
      <button
        ref={buttonRef}
        type="button"
        className={b({ mode, 'is-hidden': isHidden, 'is-disabled': isDisabled }).mix(classMixin)}
        title={hint}
        onClick={handleClick}
        {...{ 'data-icon': !!icon, 'data-text': !!text }}
        {...(restProps as ButtonAttributes)}
      >
        {!!icon && <Icon {...icon} />}
        {!!text && <span>{text}</span>}
      </button>

      {!!listPopover.length && (
        <Popover
          isOpen={isOpenedPopover}
          onClose={closePopover}
          anchorRef={buttonRef.current}
          position={{
            offset: 0,
            anchor: {
              vertical: 'top',
              horizontal: 'right',
            },
            content: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          <div className={b('list')} onClick={closePopover}>
            {listPopover}
          </div>
        </Popover>
      )}
    </>
  );

  if (permission) {
    const key = [
      mode,
      [...(!!icon ? icon.type : [])],
      [...(!!icon && icon.mode ? icon.mode : [])],
      [...(classMixin ? classMixin : [])],
    ]
      .join('_')
      .replaceAll(' ', '-');

    return (
      <Permission key={key} {...permission}>
        {button}
      </Permission>
    );
  }

  return button;
});
