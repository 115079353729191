import * as R from 'ramda';

import { Article, CitationSystem, Payment } from 'types/models';
import { convertSecurityDocument } from 'services/BackendAPI/configurations/securityDocument/converters';
import { Settings } from 'types/models/common';
import * as authorConverters from '../author/converters';
import { convertDocument, convertIdItem, convertItem, convertMaybeArray, convertReference, getText } from '../commonConverters';
import { convertServerParticipation } from '../participation/converters';
import { convertServerDepartment, convertServerPublication } from '../publication/converters';

export function convertServerCitationSystem(citationSystem: any): CitationSystem {
  return {
    authors: getText(citationSystem.Authors),
    bibliographicRecord: getText(citationSystem.BibliographicRecord),
    identifier: getText(citationSystem.Identifier),
    keyWords: getText(citationSystem.KeyWords),
    name: getText(citationSystem.Name),
    url: getText(citationSystem.Url),
    createdBy: getText(citationSystem.CreatedBy),
    publicationType: convertReference(citationSystem.PublicationType),
    refCitationSystem: convertReference(citationSystem.CitationSystem),
    id: citationSystem._attributes.id,
  };
}

export function convertServerArticle(article: any, settings: Settings): Article {
  return {
    annotation: getText(article.Annotation),
    authorsCount: getText(article.AuthorsCount),
    authorsKeyWords: getText(article.AuthorsKeyWords),
    bibliographicRecord: getText(article.BibliographicRecord),
    id: article._attributes.id,
    name: getText(article.Name),
    citationSystems: convertMaybeArray(article.CitationSystems.CitationSystem || [], convertServerCitationSystem),
    createdBy: {
      id: article.CreatedBy._attributes.id,
      department: convertReference(article.CreatedBy.Department),
      user: convertIdItem(article.CreatedBy.User),
    },
    modifiedBy: {
      department: convertReference(article.ModifiedBy.Department),
      user: convertIdItem(article.ModifiedBy.User),
    },
    statusModifiedBy: {
      id: article.StatusModifiedBy._attributes.id,
      department: convertReference(article.StatusModifiedBy.Department),
      parentDepartment: convertReference(article.StatusModifiedBy.ParentDepartment),
      user: convertIdItem(article.StatusModifiedBy.User),
    },
    createdDate: getText(article.CreatedDate),
    doi: getText(article.Doi),
    members: convertMaybeArray(article.Members.Member || [], m => authorConverters.convertMember(m, settings)),
    endPage: getText(article.EndPage),
    isElectronic: getText(article.IsElectronic),
    year: getText(article.Year),
    file: article.File ? convertIdItem(article.File) : null,
    modifiedDate: getText(article.ModifiedDate),
    startPage: getText(article.StartPage),
    status: convertItem(article.Status),
    statusModifiedDate: getText(article.StatusModifiedDate),
    textLanguage: convertReference(article.TextLanguage),
    type: convertItem(article.Type),
    typeEdition: article.TypeEdition ? convertItem(article.TypeEdition) : null,
    departments: convertMaybeArray(article.Departments.Department || [], convertServerDepartment),
    magazine: article.Magazine ? { id: article.Magazine._attributes.id } : null,
  };
}

export function convertServerAuthorPayment(authorPayment: any, settings: Settings): Payment.AuthorPayment {
  const serverForeignDocuments = authorPayment.ForeignDocuments?.Document || [];
  const serverDocuments = authorPayment.Documents?.Document || [];
  const serverNominees = authorPayment.nominees.authorPaymentRequestNominee || [];
  const departments = authorPayment.FundDistribution;

  const foreignDocuments = convertMaybeArray(serverForeignDocuments, R.partial(convertDocument, ['foreign']));
  const localDocuments = convertMaybeArray(serverDocuments, R.partial(convertDocument, ['local']));

  return {
    id: authorPayment._attributes ? authorPayment._attributes.id : null,
    event: authorPayment.Event ? convertServerAuthorPaymentEvent(authorPayment.Event) : null,
    creator: convertIdItem(authorPayment.creator),
    distributionMethodBetweenFunds: getText(authorPayment.DistributionMethodBetweenFunds),
    isManualEdit: authorPayment.DistributionMethodBetweenFunds._attributes.manual === 'true',
    distributionMethodBetweenNominees: convertItem(authorPayment.DistributionMethodBetweenNominees),
    factFundMoney: getText(authorPayment.FactFundMoney),
    factNomineesMoney: getText(authorPayment.FactNomineesMoney),
    maxFundMoney: getText(authorPayment.MaxFundMoney),
    maxNomineesMoney: getText(authorPayment.MaxNomineesMoney),
    month: getText(authorPayment.month),
    quarter: convertItem(authorPayment.quarter),
    year: getText(authorPayment.year),
    type: convertItem<Payment.EventType>(authorPayment.type),
    remainderFundMoney: getText(authorPayment.RemainderFundMoney),
    remainderNomineesMoney: getText(authorPayment.RemainderNomineesMoney),
    status: convertItem(authorPayment.status),
    statusChanger: convertIdItem(authorPayment.statusChanger),
    nominees:
      convertMaybeArray(serverNominees, n => convertNominee(n, settings)).sort((x1, x2) => {
        return Number(x2.isAffiliated) - Number(x1.isAffiliated);
      }) || [],
    documents: [...foreignDocuments, ...localDocuments],
    departments: convertMaybeArray(departments, convertDepartment),
    regulationName: getText(authorPayment.regulationName) ?? null,
    regulationParagraph: getText(authorPayment.regulationParagraph) ?? null,
    article: authorPayment.article ? convertServerArticle(authorPayment.article, settings) : null,
    publication: authorPayment.publication ? convertServerPublication(authorPayment.publication, settings) : null,
    participation: authorPayment.participation ? convertServerParticipation(authorPayment.participation, settings) : null,
    statusChanged: {
      date: getText(authorPayment.statusChanged),
      time: authorPayment.statusChanged._attributes.time,
    },
    ...(authorPayment.patent ? { patent: convertSecurityDocument(authorPayment.patent, settings) } : {}),
  };
}

export function convertDepartment(department: any): Payment.Department {
  return {
    amountMoney: getText(department.AmountMoney),
    fraction: getText(department.Fraction),
    nomineeQuantity: getText(department.NomineeQuantity),
    topLevelDepartment: {
      id: department.TopLevelDeaprtment._attributes.id,
      name: getText(department.TopLevelDeaprtment),
    },
  };
}

export function convertNominee(serverNominee: any, settings: Settings): Payment.Nominee {
  return {
    person: authorConverters.convertServerPerson(serverNominee.nominee, settings),
    factMoney: getText(serverNominee.factMoney),
    isAffiliated: getText(serverNominee.isAffiliated) === 'true',
    isPaid: getText(serverNominee.isPaid) === 'true',
    name: getText(serverNominee.name),
    planMoney: getText(serverNominee.planMoney),
    weight: getText(serverNominee.weight),
    topLevelDepartment: serverNominee.topLevelDepartment
      ? {
          id: serverNominee.topLevelDepartment._attributes.id,
          name: getText(serverNominee.topLevelDepartment.name),
        }
      : null,
    job: serverNominee.job ? authorConverters.convertServerScientistJob(serverNominee.job) : null,
    education: serverNominee.education ? authorConverters.convertServerScientistEducation(serverNominee.education) : null,
    foundationJob: serverNominee.foundationJob ? authorConverters.convertServerScientistJob(serverNominee.foundationJob) : null,
    foundationEducation: serverNominee.foundationEducation
      ? authorConverters.convertServerScientistEducation(serverNominee.foundationEducation)
      : null,
    citizenship: serverNominee.citizenship ? convertReference(serverNominee.citizenship) : null,
    degree: serverNominee.degree ? authorConverters.convertServerScientistDegree(serverNominee.degree) : null,
    rank: serverNominee.rank ? authorConverters.convertServerScientistRank(serverNominee.rank) : null,
  };
}

export function convertServerAuthorPaymentEvent(event: any): Payment.Event {
  return {
    projects: getText(event.Projects),
    fullName: getText(event.FullName),
    name: getText(event.Name),
    id: event._attributes.id,
    isStudent: getText(event.IsStudent) === 'true',
    level: getText(event.Level),
    organizers: getText(event.Organizers),
  };
}

export function convertTermPreset(termPreset: any): Payment.TermPreset {
  return {
    id: termPreset._attributes.id,
    regulationName: getText(termPreset.regulationName),
    regulationParagraph: getText(termPreset.regulationParagraph),
    type: convertItem(termPreset.type),
  };
}

export function convertServerPaymentFeedline(paymentFeedline: any): Payment.PaymentFeedline {
  return {
    id: paymentFeedline._attributes.id,
    messageCategory: convertReference(paymentFeedline.MessageCategory),
    createdBy: getText(paymentFeedline.CreatedBy),
    createdDate: getText(paymentFeedline.CreatedDate),
    modifiedBy: getText(paymentFeedline.ModifiedBy),
    modifiedDate: getText(paymentFeedline.ModifiedDate),
    isSystem: getText(paymentFeedline.IsSystem) === 'true',
    message: getText(paymentFeedline.Message),
    status: convertItem(paymentFeedline.Status),
  };
}

export function convertSavePaymentResponse(response: any) {
  return {
    id: getText(response.Id),
  };
}
