import React from 'react';

import { FormComponent, Reference, TextInput } from 'components';

import { format } from 'date-fns';
import { Person } from 'types/models';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { ValueOf } from 'types/helpers';

type Props = {
  biblioName: Person.BibliographicName | null;
  onChange(biblioName: Person.BibliographicName): void;
};

function Fields(props: Props) {
  const { biblioName, onChange } = props;

  const makeChangeHandler = (fieldName: keyof Person.BibliographicName) => (value: ValueOf<Person.BibliographicName>) => {
    onChange({
      ...biblioName!,
      [fieldName]: value,
      modifiedDate: format(new Date(), formatDateTimeStr),
      createdDate: biblioName?.createdDate || format(new Date(), formatDateTimeStr),
    });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="ФИО" isRequired>
          <TextInput value={biblioName?.name ?? ''} onChange={makeChangeHandler('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Язык" isRequired>
          <Reference
            name="RefLanguage"
            value={biblioName?.language ?? null}
            onChange={makeChangeHandler('language')}
            relationTableModalTitle="Язык"
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { Fields };
