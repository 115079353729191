import { DetailProps } from '.';

export type State = {
  scientistId: string;
};

export function makeUseCustomController({ scientistId = '' }: DetailProps) {
  return function useCustomController(): State {
    return {
      scientistId,
    };
  };
}
