import { Parameter, ExtendedParameterListValue } from 'types/models';
import { PARTIAL_RADIO_VALUE, DEPARTMENT_RADIO_VALUE } from './DefaultParameterList/helpers';

const queryBuilder = (parameter: Parameter) => {
  const value = parameter.value && (parameter.value as ExtendedParameterListValue);

  if (value) {
    const query = `${
      value.list.length && value.radioValue === PARTIAL_RADIO_VALUE
        ? `&${parameter.name}=${value.userResultRadioValue}&DepartmentConsider=${
            value.userResultRadioValue === DEPARTMENT_RADIO_VALUE
          }`
        : value.radioValue === PARTIAL_RADIO_VALUE
        ? ''
        : `&${parameter.name}=-1`
    }`;

    return query;
  }

  return '-1';
};

export default queryBuilder;
