import React, { memo } from 'react';
import { Modal } from 'components';
import { Table } from 'types/models';
import { Component as PublicationOriginalTranslatesRelationsComponent } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  publicationId: string;
  relatedTableState: Table.State | undefined;
  onSuccess: () => void;
};

const PublicationOriginalTranslatesRelations = ({ isOpen, onClose, publicationId, relatedTableState, onSuccess }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Редактирование связи оригинала/перевода для статьи в журнале" size="medium">
    <PublicationOriginalTranslatesRelationsComponent
      relatedTableState={relatedTableState}
      onClose={onClose}
      publicationId={publicationId}
      onSuccess={onSuccess}
    />
  </Modal>
);

export const Container = memo(PublicationOriginalTranslatesRelations);
