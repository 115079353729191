import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { IconButtonProps, ConfirmPopup, InputSelect, Toolbar, buttonIcons, TextArea } from 'components';

import { Table } from 'types/models';
import { SelectProjectPopup } from 'features/Form/views/SelectProjectPopup';
import { getEnum } from 'utils/Helpers';
import { ContestRequestType } from 'utils/Enums';
import { Item } from 'types/models/common';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { ContestRequestStatus } from 'utils/Enums/ContestRequestStatus';
import { ContestRequestTab } from 'utils/Permissions/ContestRequestTab';
import { isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { getContestRequestStatusButtons } from 'features/Table/specifications/GetContestRequestList/helpers';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';

import './style.scss';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const requestStatus = selectedRow?.['id:Status'] as ContestRequestStatus;

  const typeOptions = getEnum('ContestRequestType') as Array<Item<ContestRequestType>>;

  const [projectId, setProjectId] = useState<string | null>(null);
  const [isSelectFormOpen, setIsSelectFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isTypeFormOpen, setIsTypeFormOpen] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<Item<ContestRequestType> | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const isEditPermitted = useMemo(() => {
    return isHasSomePermission(
      userPermission,
      (Object.keys(ContestRequestTab) as Array<ContestRequestTab>).map(
        tabKey => Permits[`CONTEST_REQUEST_EDIT_${requestStatus}_${tabKey}`],
      ),
    );
  }, [requestStatus, userPermission]);

  const onSubmitSelectProject = useCallback((id: string) => {
    setProjectId(id);
    setIsSelectFormOpen(false);
    setIsAddFormOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: selectedRow?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ContestRequest',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, selectedRow?.id, tableStreams.deleteRow]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const onSelectProjectFormClose = useCallback(() => {
    setIsSelectFormOpen(false);
  }, []);

  const confirmTypeSelect = useCallback(() => {
    if (requestType) {
      setIsTypeFormOpen(false);
      setIsSelectFormOpen(true);
    } else showNotification({ message: 'Укажите тип заявки', theme: 'danger' });
  }, [requestType]);

  const {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons: statusChangeButtons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = getContestRequestStatusButtons({
    rows: tableState.selectedRows.map(x => ({
      id: x.id,
      status: (x?.['id:Status'] as ContestRequestStatus) || null,
      type: x?.['id:Type'] as ContestRequestType,
    })),
  });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: !selectedRow,
        onClick: () => setIsViewFormOpen(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        onClick: () => setIsTypeFormOpen(true),
        profilePermissionName: Permits.CONTEST_REQUEST_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: !selectedRow || !isEditPermitted,
        onClick: () => setIsEditFormOpen(true),
        profilePermissionName: (Object.keys(ContestRequestStatus) as Array<ContestRequestStatus>).flatMap(statusKey =>
          (Object.keys(ContestRequestTab) as Array<ContestRequestTab>).map(
            tabKey => Permits[`CONTEST_REQUEST_EDIT_${statusKey}_${tabKey}`],
          ),
        ),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        isDisabled: !selectedRow || requestStatus !== 'DRAFT',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        profilePermissionName: Permits.CONTEST_REQUEST_DELETE_DRAFT,
      },
      ...statusChangeButtons,
    ],
    [isEditPermitted, requestStatus, selectedRow, statusChangeButtons],
  );

  return (
    <>
      {isAddFormOpen && requestType && (
        <modalTemplates.ContestRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          projectId={projectId || ''}
          relatedTableState={tableState}
          requestType={requestType.value}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.ContestRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}
      {isViewFormOpen && (
        <modalTemplates.ContestRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную заявку? (ID ${selectedRow?.id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isTypeFormOpen && (
        <ConfirmPopup
          title="Выберите тип заявки"
          isOpen={isTypeFormOpen}
          onClose={() => setIsTypeFormOpen(false)}
          onConfirm={confirmTypeSelect}
          okButtonText="Продолжить"
          onReset={() => setIsTypeFormOpen(false)}
          resetButtonText="Отмена"
        >
          <div className="modal-help">
            <p>
              <strong>Добавить заявку может только руководитель проекта или ответственный исполнитель</strong>
            </p>
            <p>Укажите форму заявки:</p>
            <br />
            <p>Для участия в конкурсе на продление завершённого проекта - Заявка на продление проекта</p>
            <p>Для процедуры актуализации данных этапа текущего проекта - Заявка на актуализацию данных этапа</p>
            <InputSelect value={requestType} options={typeOptions} onSelectChange={setRequestType} />
          </div>
        </ConfirmPopup>
      )}
      {isSelectFormOpen && (
        <SelectProjectPopup
          isOpen={isSelectFormOpen}
          onClose={onSelectProjectFormClose}
          onSubmit={onSubmitSelectProject}
          isPersonal
        />
      )}
      {isHelpFormOpen && (
        <ConfirmPopup
          title="О работе с заявками на актуализацию проекта"
          isOpen={isHelpFormOpen}
          onClose={() => setIsHelpFormOpen(false)}
          size="medium"
        >
          <div className="modal-help">
            <h3>
              <strong className="primary">Руководителю или ответственному исполнителю проекта</strong>
            </h3>
            <p>
              &emsp;Учёт сведений о выполняемых научных проектах в университете ведётся сотрудниками ответственного подразделения.
              Периодически проводятся процедуры продления сроков проекта или актуализации данных о проекте (состав коллектива,
              смета, плановые значения показателей результативности, описание темы проекта и пр.).
            </p>
            <p>&emsp;Сбор сведений о проекте (этапах проекта) осуществляется через оформление заявок двух типов:</p>
            <ul>
              <li>
                <strong>Заявка на продление проекта</strong> – для участия в конкурсе на продление проекта.
              </li>
              <li>
                <strong>Заявка на актуализацию данных этапа</strong> – по требованию сотрудников ответственного за НИР и ОКР
                подразделения.
              </li>
            </ul>
            <div className="warning">
              <div className="warning__left">
                <strong>Внимание:</strong>
              </div>
              <div className="warning__right">
                <ol>
                  <li>
                    Добавить заявку имеет право пользователь, у которого роль в проекте — руководитель проекта или ответственный
                    исполнитель. Удалить заявку можно только в статусе «Черновик».
                  </li>
                  <li>
                    Заявка будет отображаться в списке на вкладке Актуализация моих научных проектов в личном кабинете всех членов
                    коллектива, указанного в заявке, без права редактирования и удаления
                  </li>
                  <li>
                    Подробные инструкции будут размещены в разделе&nbsp;
                    <strong>Новости, на вкладке Справочные материалы и полезные ссылки.</strong>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </ConfirmPopup>
      )}
      {isStatusModalOpen && (
        <ConfirmPopup
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          onConfirm={changeStatus}
          okButtonText={statusLabel}
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </ConfirmPopup>
      )}
      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
