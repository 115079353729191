import React, { memo } from 'react';

import { DefaultCheckboxList } from '../DefaultCheckboxList';
import { BuilderProps, CheckboxItem } from 'features/BuildReportPopup/models';

const initialProjectTypeCheckboxes: CheckboxItem[] = [
  {
    value: '1',
    label: '',
    isChecked: false,
  },
];

const YoungScientists = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultCheckboxList
    initialCheckboxList={initialProjectTypeCheckboxes}
    parameterName={parameterName}
    parameters={parameters}
    setParameterValueByName={setParameterValueByName}
  />
);

const Component = memo(YoungScientists);

export { Component as YoungScientists };
