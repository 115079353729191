import { Table } from 'types/models';

export function GetRntdForSubjectList(): Table.Specification {
  return {
    apiID: 'GetRntdForSubjectList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}
