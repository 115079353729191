import { useCallback, useLayoutEffect, useState } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { useLocalTableStreams } from 'features/Table/hooks';
import { FileInfo, Form, SecurityDocumentPayment } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './validate';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [payment, setPayment] = useState<SecurityDocumentPayment | null>();

  const [formFields, setFormFields] = useState<Form.Fields>({
    paymentPurpose: {
      value: '',
      isValid: true,
      required: false,
      title: 'Назначение платежа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          paymentPurpose: { ...prevState.paymentPurpose, value },
        }));
      },
    },
    payBeforeDate: {
      value: '',
      isValid: true,
      required: true,
      title: 'Оплатить до',
      onChange: (value: string | null) => {
        const valueStr = value ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          payBeforeDate: { ...prevState.payBeforeDate, value: valueStr },
        }));
      },
    },
    payDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Оплачено',
      onChange: (value: string | null) => {
        const valueStr = value ?? undefined;
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          payDate: { ...prevState.payDate, value: valueStr },
        }));
      },
    },
    amount: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          amount: { ...prevState.amount, value },
        }));
      },
    },
    securityDocument: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          securityDocument: { ...prevState.securityDocument, value },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      required: false,
      title: 'Файл документа',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
  });
  const { methods: getPayment } = BackendAPI.useBackendAPI('GetSecurityDocumentPayment', {
    onSuccessfullCall: ({ data }) => {
      setPayment(data);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('paymentPurpose'), data?.paymentPurpose),
          R.set(getValueLens('payBeforeDate'), data?.payBeforeDate),
          R.set(getValueLens('payDate'), data?.payDate),
          R.set(getValueLens('amount'), data?.amount),
          R.set(getValueLens('securityDocument'), data?.securityDocument),
          R.set(getValueLens('file'), data?.file),
        )(prevFields),
      );
    },
  });
  const { methods: savePayment } = BackendAPI.useBackendAPI('SaveSecurityDocumentPayment');

  const onSubmit = useCallback(
    needClose => {
      const errors = validate(payment || null, formFields);
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          showNotification({ message: error.invalidMessage, theme: 'danger' });
        });
        return false;
      }
      savePayment.callAPI(
        {
          id: payment?.id || null,
          paymentPurpose: formFields.paymentPurpose.value,
          payBeforeDate: formFields.payBeforeDate.value,
          payDate: formFields.payDate.value,
          amount: formFields.amount.value,
          securityDocument: formFields.securityDocument.value,
          file: formFields.file.value,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              getPayment.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [payment, getPayment, savePayment, tableStreams, formFields, updateWorkModeAfterSaveAndContinue, onClose],
  );

  useLayoutEffect(() => {
    if (id) {
      getPayment.callAPI({ id });
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    needClose => {
      onSubmit(needClose);
    },
    [onSubmit],
  );
  return {
    paymentId: id ?? null,
    payment,
    workMode,
    formFields,
    handleFormSubmit,
    setPayment,
  };
}
