import React, { useEffect, useMemo } from 'react';

import { IconButtonProps, Tab, Tabs, FormComponent } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { AboutPurchaseRequest } from 'features/Form/looks/project/PurchaseRequestForm/Tabs';
import { Toolbar } from './Toolbar/Toolbar';

import { Form as F } from 'types/models';
import { DataGrid } from 'features/Table';
import { Documents } from 'features/Documents';
import { GetPurchaseRequestFeedlineList } from 'features/Table/specifications/GetPurchaseRequestFeedlineList';

type Props = {
  onClose(): void;
  viewMode?: boolean;
  editMode?: boolean;
};

function PurchaseRequestForm({ viewMode, editMode, onClose }: Props) {
  const {
    look: { setTitle, hasPersonalMode, approvementButtons = [], projectSelectable },
  } = useFormContext<
    F.Look & { projectId: string; hasPersonalMode: boolean; approvementButtons?: IconButtonProps[]; projectSelectable?: boolean }
  >();

  const { purchaseRequest, project, makeChangeHandler, handleSave, workMode, setPurchaseRequest } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const title = useMemo(() => {
    return [
      workMode === 'viewMode' ? 'Просмотр' : workMode === 'editMode' ? 'Редактирование' : 'Добавление',
      'заявки',
      purchaseRequest?.number ? `№ ${purchaseRequest?.number}` : '',
      purchaseRequest?.status?.label ? `Статус: ${purchaseRequest?.status?.label}` : '',
    ]
      .filter(x => x)
      .join(' ');
  }, [purchaseRequest, workMode]);

  useEffect(() => {
    if (setTitle) {
      setTitle(title);
    }
  }, [setTitle, title]);

  return (
    <FormComponent.Template>
      <Toolbar
        purchaseRequest={purchaseRequest}
        workMode={workMode}
        onSave={handleSave(true)}
        onSaveAndContinue={handleSave(false)}
        approvementButtons={approvementButtons}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Заявка на закупку">
            <AboutPurchaseRequest
              workMode={workMode}
              purchaseRequest={purchaseRequest}
              project={project}
              projectSelectable={projectSelectable || false}
              hasPersonalMode={hasPersonalMode}
              makeChangeHandler={makeChangeHandler}
              setPurchaseRequest={setPurchaseRequest}
            />
          </Tab>
          <Tab title="Документы">
            <Documents
              documents={purchaseRequest?.documents ?? []}
              disabled={workMode === 'viewMode'}
              setDocuments={makeChangeHandler('documents')}
            />
          </Tab>
          {purchaseRequest.id && (
            <Tab title="Журнал сообщений">
              <DataGrid
                specification={GetPurchaseRequestFeedlineList({
                  purchaseRequestId: purchaseRequest.id || '-1',
                  isDisabledToolbar: workMode === 'viewMode',
                })}
              />
            </Tab>
          )}
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(PurchaseRequestForm);
