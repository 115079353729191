import { EventType } from 'types/models/Payment';
import { EventTypeIncentivePayment } from 'utils/Enums';
import { EditableNominee } from 'features/IncentivePayments/model';

export function validate(nominee: EditableNominee | null, eventType: EventType | null) {
  if (!nominee) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidJob = nominee.isAffiliated ? Boolean(nominee.job) : true;
  const isValidDepartment = nominee.isAffiliated ? Boolean(nominee.topLevelDepartment?.id) : true;
  const isValidFactMoney = nominee.isPaid ? Boolean(nominee.factMoney) : true;
  const isValidPlanMoney = (() => {
    if (nominee.isPaid) {
      return eventType === EventTypeIncentivePayment.CONFERENCE ? true : Number(nominee.planMoney) > 0;
    }

    return true;
  })();

  return [
    { isValid: isValidJob, invalidMessage: 'Укажите сведения о работе' },
    { isValid: isValidDepartment, invalidMessage: 'Не выбран факультет/институт' },
    { isValid: isValidFactMoney, invalidMessage: 'Введите фактическую сумму' },
    { isValid: isValidPlanMoney, invalidMessage: 'План сумма должна быть больше нуля' },
  ];
}
