import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

type EstimatePositionEditTemplateProps = {
  estimateId: string;
} & F.TemplateProps;

const EstimatePositionEditTemplate: React.FC<EstimatePositionEditTemplateProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  estimateId,
}: EstimatePositionEditTemplateProps) => {
  const look: F.EstimatePositionLook = {
    apiID: 'GetKindConsumption',
    template: 'EstimatePositionForm',
    editMode: true,
    id: String(id),
    estimateId,
    relatedTableState,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактировать общую сумму расходов по статье затрат" size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(EstimatePositionEditTemplate);
