import React from 'react';

import { TextGridSelect, ReferenceItem, FormComponent, TextArea, TextInput } from 'components';

import { GetProgramDirectionList } from 'features/Table/specifications';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

function ProgramEventForm({ viewMode, editMode, onClose }: Props) {
  const { formFields, handleFormSubmit, programId } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.name.title} isRequired>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.name.value}
              onChange={formFields.name.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.code.title} isRequired>
            <TextInput value={formFields.code.value} onChange={formFields.code.onChange} isDisabled={!!viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Направление программы">
            <TextGridSelect<ReferenceItem>
              specification={GetProgramDirectionList(programId)}
              title="Направления программы"
              value={formFields.direction.value}
              valueFormatter={x => x.label ?? ''}
              disabled={!!viewMode}
              onChange={formFields.direction.onChange}
              settings={{ mode: 'selectTableRow', tableRowConverter: x => ({ id: x.id, label: x.Name }) }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </>
  );
}

export const Component = React.memo(ProgramEventForm);
