import { useMemo, useCallback } from 'react';
import { Option } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getEnum } from 'utils/Helpers';

type Props = {
  resourceUsageDepartment: Project.ResourceUsageDepartment;
  setResourceUsageDepartment: (resourceUsageDepartment: Project.ResourceUsageDepartment) => void;
};

const useController = ({ resourceUsageDepartment, setResourceUsageDepartment }: Props) => {
  const { enumMap } = useAppDataContext();

  const makeChangeHandler = useCallback(
    (key: keyof Project.ResourceUsageDepartment) => (value: ValueOf<Project.ResourceUsageDepartment>) => {
      setResourceUsageDepartment({ ...resourceUsageDepartment, [key]: value });
    },
    [resourceUsageDepartment, setResourceUsageDepartment],
  );

  const typeOptions = useMemo<Option[]>(() => {
    return getEnum('ProjectResourceUsageDepartmentType', enumMap);
  }, [enumMap]);
  return {
    makeChangeHandler,
    typeOptions,
  };
};

export default useController;
