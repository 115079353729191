import React from 'react';

import { Project } from 'types/models';

import { Reference, FormComponent } from 'components';

import { useController } from './controller';

type Props = {
  department: Project.Department;
  codes: string;
  setDepartment(department: Project.Department): void;
  filterGovernances?: boolean;
};

export function Fields(props: Props) {
  const { department, setDepartment, codes, filterGovernances } = props;

  const { makeChangeHandler, unitFilters } = useController({ department, setDepartment, filterGovernances });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Подразделение">
          <Reference
            name="RefDepartment"
            onChange={makeChangeHandler('unit')}
            value={department.unit}
            relationTableModalTitle='Справочник "Подразделения"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Факультет/Институт">
          <Reference
            name="RefDepartment"
            onChange={makeChangeHandler('governance')}
            value={department.governance}
            relationTableModalTitle='Справочник "Факультеты"'
            filters={unitFilters}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {!!codes && (
        <FormComponent.Line>
          <>
            Для подразделения создан(ы) шифр(ы) <b>&ldquo;{codes}&rdquo;</b> Для изменения подразделения, необходимо удалить шифр.
            Вы можете выбрать другое подразделение с аналогичным кодом.
          </>
        </FormComponent.Line>
      )}
    </FormComponent.Wrapper>
  );
}
