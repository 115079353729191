import * as XML from 'xml-js';
import * as R from 'ramda';

import { Table } from 'types/models';

import { DEFAULT_SETTING_NAME, getMinWidthByColumn } from 'features/Table/helpers';
import { Entry } from 'types/models/Table';
import { makeBackendAPIConfiguration } from './makeBackendAPIConfiguration';
import { convertMaybeArray, getAttribute, getId } from './configurations/commonConverters';

const convertCurrentSetting = (currentSetting: any): Table.CurrentSetting | null => {
  const settingAttributes = currentSetting?._attributes;
  const isValidSetting = currentSetting?._attributes && currentSetting?._attributes.name !== DEFAULT_SETTING_NAME;

  if (isValidSetting) {
    return {
      editable: settingAttributes.editable,
      name: settingAttributes.name,
      author: settingAttributes.author,
    };
  }

  return null;
};

export const convertSettings = (settings: any): Table.Setting[] => {
  const convertSetting = (setting: any): Table.Setting => ({
    id: getId(setting),
    name: getAttribute({ item: setting, attributeKey: 'name' }) ?? '',
    purpose: getAttribute({ item: setting, attributeKey: 'purpose' }) ?? '',
    author: getAttribute({ item: setting, attributeKey: 'authorLogin' }) ?? '',
    isShared: getAttribute({ item: setting, attributeKey: 'isShared' }) === 'true',
  });

  const convertedSettings = convertMaybeArray(settings?.Response?.Grid?.Rows?.Row, convertSetting);
  return convertedSettings;
};

function convertTableResponseXMLElementToOutput(response: XML.ElementCompact): Table.Data {
  const setting: Table.CurrentSetting | null = convertCurrentSetting(response.Response.Grid?.Setting);
  const byOrder = R.comparator<Table.Column>((a, b) => Number(a.order) < Number(b.order));
  const columns: Table.Column[] = R.sort<Table.Column>(
    byOrder,
    (response.Response.Grid?.Columns?.Column || [])
      .map((x: any) => ({
        ...x._attributes,
        sortOrder: x._attributes?.sortOrder && parseInt(x._attributes.sortOrder, 10),
        precision: x._attributes?.precision && parseInt(x._attributes.precision, 10),
      }))
      .filter((x: Table.InputColumn) => !('system' in x)),
  ).map(column => {
    const minColumnWidth = getMinWidthByColumn(column);
    const prearedColumnWidth = parseFloat(column.width) || 0;
    const nextColumnWidth = prearedColumnWidth < minColumnWidth ? minColumnWidth : prearedColumnWidth;

    return { ...column, width: nextColumnWidth.toString() };
  });

  const isTableExtendable = response.Response.Grid?._attributes?.extendable !== 'false';
  const getRowAttributes = (row: any) => (row instanceof Array ? row.map((x: any) => x._attributes) : [row._attributes]);

  const entries =
    response.Response.Grid && 'Rows' in response.Response.Grid ? getRowAttributes(response.Response.Grid?.Rows?.Row) : [];

  const entriesNumber = Number(response.Response.Grid?.Rows?._attributes.totalQuantity || 0);
  const totalEntry: Entry = response.Response.Grid?.GroupingRows?.TotalRow?._attributes || {};
  const gridName = response.Response.Grid?._attributes?.name;
  return { columns, entries, entriesNumber, setting, isTableExtendable, totalEntry, gridName };
}

export function makeBackendTableAPIConfiguration<T extends string, Input>(
  command: T,
  convertInputToXMLElement: (input: Input) => XML.ElementCompact,
  alternativeCommand?: string,
) {
  return makeBackendAPIConfiguration({
    id: command,
    endpoint: `/msa/service/commands/${alternativeCommand ?? command}`,
    convertInputToXMLElement,
    convertResponseXMLElementToOutput: convertTableResponseXMLElementToOutput,
  });
}
