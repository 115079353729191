import React from 'react';

import { FormComponent, TextDateTime, TextInput, TextInputMode } from 'components';

import { EstimatePositionItem } from 'types/models/Estimate';

type Props = {
  item: EstimatePositionItem | null;
  setItem(item: EstimatePositionItem | null): void;
};

export const Fields = ({ item, setItem }: Props) => {
  const makeHandler = (key: keyof EstimatePositionItem) => (value: string) => {
    setItem({
      ...item,
      id: item?.id || '',
      [key]: value,
    } as EstimatePositionItem);
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Дата">
          <TextDateTime value={item?.date} onChange={makeHandler('date')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap>
        <FormComponent.Field isRequired label="Сумма, руб.">
          <TextInput
            mode={TextInputMode.number}
            value={item?.amount ? item?.amount.toString() : '0'}
            onChange={makeHandler('amount')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap>
        <FormComponent.Field isRequired label="Примечание">
          <TextInput value={item?.note ?? ''} onChange={makeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
};
