import React, { useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Button, ButtonMode, Modal, FormComponent, Reference, ReferenceItem, Radio } from 'components';

import { Project } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { getEnumItemLabel } from 'utils/Helpers';
import { ProjectType } from 'utils/Enums';
import { RefList } from 'features/Form/looks/project/ProjectForm/views';
import { ProjectEditPartialType } from 'services/BackendAPI/configurations/project';
import { showNotification } from 'features/Notifications';

type Action =
  | 'EditGroupProjectsCriticalTechnologies'
  | 'EditGroupProjectsGrntis'
  | 'EditGroupProjectsNtrStrategies'
  | 'EditGroupProjectsPnis'
  | 'EditGroupProjectsPnmitrs'
  | 'EditGroupProjectsPnrs'
  | 'EditGroupProjectsScienceDomainInterrests'
  | 'EditGroupProjectsTechnologyPlatforms'
  | 'EditGroupProjectsUdks'
  | 'EditGroupProjectsLksetss'
  | 'EditGroupProjectsOkved'
  | 'EditGroupProjectsScienceBrunches'
  | 'EditGroupProjectsNationalProjectExpenseCode';

type Param = {
  name: string;
  action: string;
  source: string;
  isList: boolean;
};

const params: Param[] = [
  {
    name: 'Критические технологии',
    action: 'EditGroupProjectsCriticalTechnologies',
    source: 'RefPriorityTechnology',
    isList: true,
  },
  {
    name: 'ГРНТИ',
    action: 'EditGroupProjectsGrntis',
    source: 'RefGrnti',
    isList: true,
  },
  {
    name: 'Стратегии НТР',
    action: 'EditGroupProjectsNtrStrategies',
    source: 'RefNtrStrategy',
    isList: true,
  },
  {
    name: 'Приоритетные направления науки, технологий и техники',
    action: 'EditGroupProjectsPnis',
    source: 'RefPni',
    isList: true,
  },
  {
    name: 'ПНР модернизации РФ',
    action: 'EditGroupProjectsPnmitrs',
    source: 'RefPnmitr',
    isList: true,
  },
  {
    name: 'ПНР',
    action: 'EditGroupProjectsPnrs',
    source: 'RefPnr',
    isList: true,
  },
  {
    name: 'Области научных интересов',
    action: 'EditGroupProjectsScienceDomainInterrests',
    source: 'RefScienceDomainInterest',
    isList: true,
  },
  {
    name: 'Технологические платформы',
    action: 'EditGroupProjectsTechnologyPlatforms',
    source: 'RefTechnologyPlatform',
    isList: true,
  },
  {
    name: 'УДК',
    action: 'EditGroupProjectsUdks',
    source: 'RefUdk',
    isList: true,
  },
  {
    name: 'ЛКСЭЦ',
    action: 'EditGroupProjectsLksetss',
    source: 'RefLksets',
    isList: false,
  },
  {
    name: 'ОКВЭД',
    action: 'EditGroupProjectsOkved',
    source: 'RefOkved2',
    isList: false,
  },
  {
    name: 'Отрасль науки',
    action: 'EditGroupProjectsScienceBrunches',
    source: 'RefScienceBrunch',
    isList: false,
  },
  {
    name: 'Код расходов нацпроекта',
    action: 'EditGroupProjectsNationalProjectExpenseCode',
    source: 'RefNationalProjectExpenseCode',
    isList: false,
  },
];

export const ProjectEditPartialList = (() => {
  const result = new Map<string, string>();
  params.forEach(i => result.set(i.name, i.action));

  return result;
})();

type Props = {
  isOpen: boolean;
  onClose(): void;
  projectType: ProjectType;
  projectIds: string[];
  paramType: string;
};

export function ProjectEditPartial({ isOpen, onClose, projectType, projectIds, paramType }: Props) {
  const { enumMap } = useAppDataContext();

  const param: Param | undefined = params.find(i => i.action === paramType);

  if (!param) {
    return <></>;
  }

  const { methods: action } = BackendAPI.useBackendAPI(paramType as Action);

  const [mode, setMode] = useState<string>(param.isList ? 'ADD' : 'SET');
  const [value, setValue] = useState<ReferenceItem | Project.RefElement[] | null>(param.isList ? [] : null);

  const save = () => {
    const request: ProjectEditPartialType = {
      projectIds,
      mode,
      elementIds: (() => {
        if (param.isList) {
          const result: string[] = [];
          (value as Project.RefElement[]).forEach(i => {
            if (!!i.ref?.id) {
              result.push(i.ref.id);
            }
          });
          return result;
        }
        if (value && (value as ReferenceItem).id) {
          return [(value as ReferenceItem).id];
        }
        return [];
      })(),
    };
    action.callAPI(request, {
      onSuccessfullCall: () => {
        showNotification({ message: `Параметр "${param.name}" успешно обновлен`, theme: 'success' });
        onClose();
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Редактировать параметр "${param.name}" в проекте (${getEnumItemLabel('ProjectType', projectType, enumMap)})`}
      size="medium"
    >
      <FormComponent.Wrapper>
        {param.isList ? (
          <>
            <FormComponent.Description mode="warning">
              {mode === 'ADD'
                ? `Указанные ниже "${param.name}" будут добавлены к списку "${param.name}" в группу проектов`
                : `"${param.name}" в группе проектов будут удалены и добавлены "${param.name}" указанные ниже`}
            </FormComponent.Description>
            <FormComponent.Line>
              <Radio
                value={mode}
                list={[
                  { label: 'Добавить недостающие', value: 'ADD' },
                  { label: 'Установить указанные', value: 'SET' },
                ]}
                onChange={setMode}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <RefList
                title={param.name}
                modalTitle={`Справочник "${param.name}"`}
                refName={param.source}
                rows={value as Project.RefElement[]}
                onChange={setValue}
                isCanMovingRows
              />
            </FormComponent.Line>
          </>
        ) : (
          <FormComponent.Line>
            <FormComponent.Field label={param.name}>
              <Reference
                name={param.source}
                value={value as ReferenceItem | null}
                onChange={setValue}
                relationTableModalTitle={`Справочник "${param.name}"`}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Сохранить" onClick={save} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
