import React, { memo } from 'react';
import block from 'bem-cn';
import * as icons from 'icons';

import { Button, ButtonMode, Modal } from 'components';

import './style.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  duplicates: string;
  name: string;
  year: string;
};

const b = block('compilation-duplicate-popup');

const CompilationDuplicatePopup = ({ isOpen, onClose, onSubmit, duplicates, name, year }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Предупреждение" size="small">
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('icon-wrapper')}>
          <img className={b('icon')} src={icons.Warning} alt="" />
        </div>
        <div className={b('content-wrap')}>
          <p className={b('main-text')}>Сборник с такимм названием уже добавлен в систему</p>
          <ul className={b('list')}>
            <li className={b('item')}>
              <p className={b('text')}>
                <span className={b('label')}>Название: </span>
                {name}
              </p>
            </li>
            <li className="item">
              <p className={b('text')}>
                <span className={b('label')}>Год издания: </span>
                {year}
              </p>
            </li>
            <li className="item">
              <p className={b('text')}>
                <span className={b('label')}>Всего дубликатов: </span>
                {duplicates}
              </p>
            </li>
          </ul>
          <p className={b('main-text')}>Продолжить сохранение?</p>
        </div>
      </div>
      <ul className={b('actions-list')}>
        <li className={b('actions-item')}>
          <Button mode={ButtonMode.primary} text="Да" onClick={onSubmit} />
        </li>
        <li className={b('actions-item')}>
          <Button mode={ButtonMode.secondary} text="Нет" onClick={onClose} />
        </li>
      </ul>
    </div>
  </Modal>
);

export const Component = memo(CompilationDuplicatePopup);
