import { Table as T } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type RequestData = object;

export function GetQnaFrequentQuestionList(): T.Specification<object, RequestData> {
  return {
    apiID: 'GetQnaFrequentQuestionList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
      firstLevel: {
        title: 'Список часто задаваемых вопросов',
      },
    },
    requestData: {},
  };
}
