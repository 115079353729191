import { Table as T } from 'types/models';

export function GetNirRequestProjectList(): T.Specification {
  return {
    apiID: 'GetNirRequestProjectList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}
