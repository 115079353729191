import React, { memo } from 'react';

import { Toolbar } from 'components';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
};

function LeftPanelForThirdLevel({ customState }: Props) {
  const { toolbarButtons } = customState;

  return <Toolbar buttons={toolbarButtons} />;
}

export const Component = memo(LeftPanelForThirdLevel);
