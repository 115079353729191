import React from 'react';
import { block } from 'bem-cn';

import { TextInput } from 'components';

import useController from './controller';

export type Props = {
  value: string;
  setColumnFilter: (nextValue: string) => void;
};

const b = block('grid-text-filter');

export const TextFilter = ({ value, setColumnFilter }: Props) => {
  const { inputValue, onInputChange } = useController({ value, setColumnFilter });
  return (
    <div className={b()} title={value || 'Введите значение'}>
      <TextInput value={inputValue} onChange={onInputChange} />
    </div>
  );
};
