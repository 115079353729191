import React, { useCallback, useMemo } from 'react';

import { buttonIcons, IconButtonProps, Toolbar as SharedToolbar } from 'components';

import { EmployeePaymentStatus } from 'types/models/Payment';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { Mode } from 'features/Table/specifications/GetRequestFundList/makeUseCustomController';

type Props = {
  onSave(onSuccessCb?: () => void): void;
  mode: Mode | null;
  status: EmployeePaymentStatus | null;
  changeEmployeePaymentStatus(status: EmployeePaymentStatus): void;
  openConfirmApproveStatusModal(): void;
  openConfirmRevisionStatusModal(): void;
  openConfirmDeleteModal(): void;
  openMessageLogModal(): void;
};

export function Toolbar(props: Props) {
  const {
    onSave,
    mode,
    status,
    changeEmployeePaymentStatus,
    openConfirmApproveStatusModal,
    openConfirmRevisionStatusModal,
    openConfirmDeleteModal,
    openMessageLogModal,
  } = props;

  const changeStatusTo = useCallback(
    (x: EmployeePaymentStatus) => {
      onSave(() => changeEmployeePaymentStatus(x));
    },
    [changeEmployeePaymentStatus, onSave],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'save',
        isDisabled: false,
        isHidden: mode === 'view',
        onClick: () => onSave(),
      },
      {
        icons: buttonIcons.like,
        title: 'Подать на согласование',
        code: 'like',
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToSent',
        isHidden: status === 'PAID' || status === 'SENT',
        isDisabled: false,
        onClick: () => changeStatusTo('SENT'),
      },
      {
        icons: buttonIcons.paymentHandover,
        title: 'Передать на оплату',
        code: 'paymentHandover',
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToPaid',
        isHidden: !status || status === 'REJECTED' || status === 'PAID' || status === RecordStatus.DRAFT || mode === 'add',
        isDisabled: false,
        onClick: () => changeStatusTo('PAID'),
      },
      {
        icons: buttonIcons.conflictsNone,
        title: 'Одобрить оплату',
        code: 'conflictsNone',
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToPaymentApproved',
        isHidden: !status || status === 'REJECTED' || status === 'SENT' || status === RecordStatus.DRAFT || mode === 'add',
        isDisabled: false,
        onClick: () => onSave(openConfirmApproveStatusModal),
      },
      {
        icons: buttonIcons.update,
        title: 'Отправить на доработку',
        code: 'update',
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToRejected',
        isHidden: !status || status === 'REJECTED' || status === RecordStatus.DRAFT || mode === 'add',
        isDisabled: false,
        onClick: () => onSave(openConfirmRevisionStatusModal),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isHidden: !status || mode === 'add',
        isDisabled: false,
        onClick: openConfirmDeleteModal,
      },
      {
        icons: buttonIcons.comment,
        title: 'Журнал сообщений',
        code: 'feedline',
        isHidden: !status || mode === 'add',
        isDisabled: false,
        onClick: openMessageLogModal,
      },
    ],
    [
      onSave,
      openConfirmApproveStatusModal,
      changeStatusTo,
      mode,
      status,
      openConfirmRevisionStatusModal,
      openConfirmDeleteModal,
      openMessageLogModal,
    ],
  );

  return <SharedToolbar buttons={buttons} mode="form" />;
}
