import React from 'react';

import { ConfirmPopup } from 'components';

// eslint-disable-next-line max-len
import * as modalTemplates from 'features/Table/specifications/GetExpositionParticipationList/LeftPanelForThirdLevel/modalTemplates';

import { TypeControllerIsForm } from './models/typeControllerIsForm';
import { useLocalTableStreams } from 'features/Table/hooks';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';

type Props = {
  controllerIsForm: TypeControllerIsForm;
  sourceEvent: any;
};

const ExpositionParticipationTemplate = ({ controllerIsForm, sourceEvent }: Props) => {
  const {
    isAdd,
    isEdit,
    isView,
    isDelete,
    isEditSubdivisionModal,
    isApproveParticipationModal,
    setDelete,
    setEdit,
    setView,
    setAdd,
    setIsEditSubdivisionModal,
    setIsApproveParticipationModal,
    participation,
  } = controllerIsForm;
  const tableStreams = useLocalTableStreams();
  const handleConfirmDeleteConfirmPopup = () => {
    tableStreams.deleteRow.push({
      deleteRowId: participation?.id || '',
      command: 'DeleteParticipation',
      deletedItemPropName: 'Participation',
    });
    setDelete(false);
  };
  return (
    <>
      {isAdd && (
        <modalTemplates.ExpositionParticipationAddTemplate.Component
          isOpen={isAdd}
          onClose={() => setAdd(false)}
          disabledEvent
          sourceEvent={sourceEvent}
          disableUpdateAfterSubmit
        />
      )}

      {isView && (
        <modalTemplates.ExpositionParticipationViewTemplate.Component
          isOpen={isView}
          onClose={() => setView(false)}
          id={participation?.id || ''}
          disabledEvent
          disableUpdateAfterSubmit
        />
      )}
      {isEdit && (
        <modalTemplates.ExpositionParticipationEditTemplate.Component
          isOpen={isEdit}
          onClose={() => setEdit(false)}
          id={participation?.id || ''}
          disabledEvent
          disableUpdateAfterSubmit
        />
      )}
      {isDelete && (
        <ConfirmPopup
          title="Предупреждение"
          text="Вы действительно хотите удалить выбранное участие?"
          isOpen={isDelete}
          onClose={() => setDelete(false)}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isEditSubdivisionModal && (
        <EditParticipationDepartments
          afterSubmit={() => {}}
          onClose={() => {
            setIsEditSubdivisionModal(false);
          }}
          id={participation?.id || ''}
          isOpen={isEditSubdivisionModal}
          type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
        />
      )}
      {isApproveParticipationModal && (
        <ApproveParticipation
          onClose={() => {
            setIsApproveParticipationModal(false);
          }}
          isOpen={isApproveParticipationModal}
          id={participation?.id || ''}
          type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
        />
      )}
    </>
  );
};

export default React.memo(ExpositionParticipationTemplate);
