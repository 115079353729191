export class Publication {
  static MODULE: string = 'Publication';

  static CITATION_SYSTEM: string = 'CitationSystem';

  static CITATION_SYSTEM_INDEX: string = 'CitationSystemIndex';

  static VAK: string = 'Vak';

  static LIBRARY: string = 'Library';

  static LOG: string = 'Log';
}
