import React, { memo } from 'react';

import { ConfirmPopup, IconButton, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type WrapperProps = {
  communitiesFilter: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ communitiesFilter }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      buttons,
      handleCloseDeleteConfirmPopup,
      handleConfirmDeleteConfirmPopup,
      handleTemplateCloseAddForm,
      handleTemplateCloseEditForm,
      handleTemplateCloseViewForm,
      isAddFormOpen,
      isDeleteButtonDisabled,
      isDeleteConfirmPopupOpen,
      isEditButtonDisabled,
      isEditFormOpen,
      isViewButtonDisabled,
      isViewFormOpen,
      nextPublicationName,
      templateName,
      handleTemplateCloseHelpForm,
      isHelpFormOpen,
      communityTypes,
      organizationName,
    } = useController({ tableState, communitiesFilter });

    return (
      <>
        {!isViewButtonDisabled && (
          <modalTemplates.CommunityViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
          />
        )}
        {!isEditButtonDisabled && (
          <modalTemplates.CommunityEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
            memberEditLocked={true}
            communityTypes={communityTypes}
          />
        )}
        <modalTemplates.CommunityAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          templateName={templateName}
          memberEditLocked={true}
          communityTypes={communityTypes}
        />
        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранное участие?`}
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            onConfirm={handleConfirmDeleteConfirmPopup}
            okButtonText="Да"
          />
        )}
        <ConfirmPopup
          title="Помощь в разделе Члены научных сообществ и организаций"
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          <div className="modal-help">
            <p>
              <strong className="primary">
                Раздел Члены научных сообществ: Научно-технического совета, Редакции журнала, Коллаборации, Фонда/организации
              </strong>
              &nbsp;предназначен для ввода и хранения информации об участии в работе сообществ и организаций.
            </p>
            <ul>
              <li>
                <strong>Фильтр «Мои сообщества»</strong> содержит список сообществ и организаций, в работе которых вы принимали
                участие
              </li>
              <li>
                <strong>Фильтр «Все сообщества»</strong> содержит список участников всех научных сообществ в типе редакция
                журнала, фонд/организация, коллабарация и начуно –техническое сообщество {organizationName} и сообществ других
                организаций с участниками из {organizationName}
              </li>
            </ul>
            <p>
              Информацию о научных сообществах Университета и составе его участников в систему заносят ответственные лица. Если вы
              не видите запись о своем участии или являетесь членом научного сообщества сторонней организации, внесите об этом
              информацию в данном разделе Личного кабинета.
            </p>
            <p>
              <strong className="primary">В разделе Членство в научных сообществах и организациях вы можете:</strong>
            </p>
            <ul>
              <li>
                Посмотреть запись об участии в работе сообщества с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.loop} hasNoHover />
              </li>
              <li>
                Добавить новую запись о работе в мообществе с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover />
              </li>
              <li>
                Редактировать запись о работе в составе сообщества с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.edit} hasNoHover />
              </li>
              <li>
                Удалить запись, которую вы создавали, с помощью кнопки <IconButton icons={buttonIcons.delete} hasNoHover />
              </li>
              <li>
                Скачать прикрепленный файл сообщества с помощью кнопки <IconButton icons={buttonIcons.download} hasNoHover />
              </li>
              <li>
                Выгрузить список ваших участий в составах сообществ и организаций в файл Excel с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
              </li>
              <li>
                Задать вопрос или написать сообщение администратору в отдельном окне с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
              </li>
            </ul>
            <p>
              При сохранении записи присваивается статус <strong>Добавлена</strong>. Добавленную запись можно редактировать,
              вносить информацию или удалить.
            </p>
            <p>
              Добавленную вами запись об участии в работе сообщества проверит и переведет в статус <strong>Утверждена</strong>
              &nbsp; Администратор данных. Утвержденная запись недоступна для внесения изменений. Если вы нашли ошибку в
              утвержденной записи - напишите письмо Администратору.
            </p>
          </div>
        </ConfirmPopup>

        <Toolbar buttons={buttons} />
      </>
    );
  });
