import React, { useMemo } from 'react';

import { ConfirmPopup } from 'components';
import { useController } from './controller';
import { AppContext, AppDataContext } from './context';

type Props = {
  children: JSX.Element | JSX.Element[];
};

function AppData({ children }: Props) {
  const {
    scientificIndexes,
    enumMap,
    userPermission,
    isHasPermissionToMain,
    isHasPermissionToAccount,
    currentPerson,
    userToken,
    userSystemDepartment,
    isUserNotAuthError,
    setUserToken,
    fetchInitialData,
    handleAcceptUnavailableApp,
    handleAcceptUserNotAuth,
    isHasNoAnyPermission,
    initialCountryCode,
    initialLanguageCode,
    initialOrganizationCode,
    settings,
  } = useController();

  const context = useMemo<AppContext>(
    () => ({
      scientificIndexes,
      enumMap,
      userPermission,
      isHasPermissionToMain,
      isHasPermissionToAccount,
      currentPerson,
      userToken,
      setUserToken,
      fetchInitialData,
      userSystemDepartment,
      initialCountryCode,
      initialLanguageCode,
      initialOrganizationCode,
      settings,
    }),
    [
      scientificIndexes,
      enumMap,
      userPermission,
      isHasPermissionToAccount,
      isHasPermissionToMain,
      currentPerson,
      userToken,
      setUserToken,
      fetchInitialData,
      userSystemDepartment,
      initialCountryCode,
      initialLanguageCode,
      initialOrganizationCode,
      settings,
    ],
  );

  return (
    <AppDataContext.Provider value={context}>
      <>
        {children}
        <ConfirmPopup
          title="Аутентификация не пройдена"
          okButtonText="Вернуться на страницу авторизации"
          isOpen={isUserNotAuthError}
          onClose={handleAcceptUnavailableApp}
          icon="warning"
          text="Для продолжения работы войдите в систему, используя свой логин и пароль."
          onConfirm={handleAcceptUserNotAuth}
        />
        <ConfirmPopup
          title="Доступ к системе запрещен"
          okButtonText="Вернуться на страницу авторизации"
          isOpen={isHasNoAnyPermission}
          onClose={handleAcceptUserNotAuth}
          icon="warning"
          text="У вас недостаточно прав для доступа к системе. Обратитесь за помощью в тех поддержку"
          onConfirm={handleAcceptUserNotAuth}
        />
      </>
    </AppDataContext.Provider>
  );
}

export { AppData };
