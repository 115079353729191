import { SecurityDocument } from 'types/models';
import { FormComponent, ListEdit } from 'components';
import React from 'react';

type Props = {
  patent?: SecurityDocument | null;
  disabled?: boolean;
};

export function Contracts({ patent, disabled }: Props) {
  return (
    <FormComponent.Wrapper>
      <FormComponent.Description mode="warning">
        Подробная информация договоров на использование ИС доступна в разделе Коммерциализация {`->`} Договоры о распоряжении
        интеллектуальным правом
      </FormComponent.Description>
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        rows={patent?.contracts || []}
        isToolbarDisabled={disabled}
        visibleToolbarButtons={[]}
        isFullScreenedTable
        hideToolbar
        onChange={() => {}}
        columns={[
          {
            label: '№',
            formatValue: (_, index) => index + 1,
            styles: { width: '5%', textAlign: 'center' },
          },
          {
            label: 'Номер договора',
            formatValue: x => x?.number || '',
            styles: { width: '20%' },
          },
          {
            label: 'Вид договора',
            formatValue: x => x.kind?.label || '',
            styles: { width: '20%' },
          },
          {
            label: 'Заказчик',
            formatValue: x => x.enterpriseCustomer?.label || '',
            styles: { width: '25%' },
          },
          {
            label: 'Дата заключения',
            formatValue: x => x.conclusionDate || '',
            styles: { width: '10%' },
          },
          {
            label: 'Дата начала',
            formatValue: x => x.startDate || '',
            styles: { width: '10%' },
          },
          {
            label: 'Дата окончания',
            formatValue: x => x.endDate || '',
            styles: { width: '10%' },
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: () => <></>,
        }}
      />
    </FormComponent.Wrapper>
  );
}
