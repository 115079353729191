import React from 'react';
import * as R from 'ramda';

import { FormComponent, InputSelect, ListEdit, Reference, SectionTitle, TextInput, TextDateTime } from 'components';

import { ForeignSecurityDocument, Form, SecurityDocumentMemberTypes } from 'types/models';
import { formatJob } from 'features/SelectPerson/helpers';
import { showErrors } from 'utils/Validators';
import { MemberInputs } from 'features/Form/looks/securityDocumentPatent/PatentForm/Tabs/Document/MemberInputs/MemberInputs';
import { validatePatentMember } from 'features/Form/looks/securityDocumentPatent/PatentForm/Tabs/Document/validate';
import { showErrorsMessages } from 'utils/Common';
import { SecurityDocumentMemberType } from 'utils/Enums';
import { formatNumber } from 'utils/Helpers';
import { validateAuthor } from './validate';
import { useController } from './controller';
import { AuthorInputs } from './AuthorInputs/AuthorInputs';

type Props = {
  foreignSecurityDocument?: ForeignSecurityDocument | null;
  formFields: Form.Fields;
  disabled?: boolean;
  setForeignSecurityDocument: React.Dispatch<React.SetStateAction<ForeignSecurityDocument | null | undefined>>;
};

export function AboutIPK({ foreignSecurityDocument, formFields, disabled, setForeignSecurityDocument }: Props) {
  const { handleAuthorsChange, handleApplicantChange, documentTypeOptions, applicantPersonList } = useController({
    foreignSecurityDocument,
    setForeignSecurityDocument,
  });
  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column specialStyles={{ width: '65%' }}>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.documentType.title} isRequired={formFields.documentType.required}>
              <InputSelect
                options={documentTypeOptions}
                value={documentTypeOptions.find(x => x.value === formFields.documentType?.value?.value) ?? null}
                onSelectChange={formFields.documentType.onChange}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.number.title} isRequired={formFields.number.required}>
              <TextInput value={formFields.number.value} onChange={formFields.number.onChange} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сроки действия документа">
              <TextDateTime
                isRange
                value={`${formFields.startDate.value || ''}-${formFields.endDate.value || ''}`}
                onChange={(value: string) => {
                  const dates = value.split('-');
                  formFields.startDate.onChange(dates[0] || '');
                  formFields.endDate.onChange(dates[1] || '');
                }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.kind.title} isRequired={formFields.kind.required}>
              <Reference
                name="RefForeignSecurityDocumentKind"
                value={formFields.kind.value ?? null}
                relationTableModalTitle='Справочник "Виды ОИС"'
                onChange={formFields.kind.onChange}
                disabled={disabled || !formFields.documentType.value?.value}
                filters={[{ key: 'type', values: [formFields.documentType.value?.value] }]}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.name.title} isRequired={formFields.name.required}>
              <TextInput value={formFields.name.value} onChange={formFields.name.onChange} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: '690px' }}>
          <SectionTitle title="Авторы" isRequired />
          <FormComponent.Field>
            <ListEdit
              withMessages
              rows={foreignSecurityDocument?.members?.filter(m => m.type?.value === SecurityDocumentMemberType.AUTHOR) || []}
              isToolbarDisabled={disabled}
              maxHeight="80vh"
              onChange={handleAuthorsChange}
              columns={[
                { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '30%' } },
                {
                  label: 'Место работы',
                  formatValue: x => (x?.job && formatJob(x?.job)) || '',
                },
                { label: 'Вклад, %', formatValue: x => formatNumber(x?.contribution || '0', 1), styles: { width: '15%' } },
              ]}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <AuthorInputs author={row} onAuthorChange={onChange as any} />,
                validation: {
                  checkIsValid: a => validateAuthor(a).every(x => x.isValid),
                  onInvalidate: R.partial(showErrors, [validateAuthor]),
                },
              }}
            />
          </FormComponent.Field>
          <SectionTitle title="Правообладатели" />
          <ListEdit
            withMessages
            isDeleteConfirmEnabled
            rows={applicantPersonList}
            isToolbarDisabled={disabled}
            onChange={handleApplicantChange}
            maxHeight="300px"
            defaultRowsCount={5}
            columns={[{ label: 'ФИО персоны / Название организации', formatValue: x => x?.name || '' }]}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => (
                <MemberInputs
                  member={row}
                  onMemberChange={onChange as any}
                  memberType={SecurityDocumentMemberTypes.APPLICANT}
                  extraFields={{}}
                />
              ),
              validation: {
                checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
                onInvalidate: (row, mode, index, list) => {
                  const validationInfo = validatePatentMember(row, list, mode, index);
                  showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
                },
              },
            }}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
