import { Community } from 'types/models';

export class CommunityMemberCooperationType {
  static DISSERTATION_COUNCIL_PARTICIPATION: Community.CommunityMemberCooperationType = 'DISSERTATION_COUNCIL_PARTICIPATION';

  static SCIENTIFIC_COUNCIL_PARTICIPATION: Community.CommunityMemberCooperationType = 'SCIENTIFIC_COUNCIL_PARTICIPATION';

  static COLLABORATION_PARTICIPATION: Community.CommunityMemberCooperationType = 'COLLABORATION_PARTICIPATION';

  static FUND_EXPERT: Community.CommunityMemberCooperationType = 'FUND_EXPERT';

  static EDITORIAL_BOARD_MEMBER: Community.CommunityMemberCooperationType = 'EDITORIAL_BOARD_MEMBER';
}
