import React from 'react';
import { DataGrid } from 'features/Table';
import { GetCompilationArticleList } from 'features/Table/specifications';
import { FormComponent } from 'components';
import useController from './controller';

const MagazineArticle = () => {
  const { relatedTableState } = useController();

  return (
    <FormComponent.Template>
      {!!relatedTableState && (
        <DataGrid
          specification={GetCompilationArticleList({
            templatesTableDependencies: {
              relatedTableAPIID: 'GetMagazineList',
              relatedTableState,
              relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
            },
            workMode: 'editMode',
          })}
        />
      )}
    </FormComponent.Template>
  );
};

export const Component = React.memo(MagazineArticle);
