import { Parameter, EnumValue } from 'types/models';

const queryBuilder = (parameter: Parameter) => {
  const isParameterValueExist = !!parameter.value;
  if (isParameterValueExist) {
    const value = parameter.value as EnumValue;
    return `&${parameter.name}=${value}`;
  }
  return '';
};

export default queryBuilder;
