import React from 'react';

import { FormComponent, ListEditTable } from 'components';

import { Event } from 'types/models';

import { useController } from './controller';

type Props = {
  departmentOptions: Event.Department[];
  onDepartmentChange(row: Event.Department): void;
  submitCustomComponent?: () => void;
};

function FinOrganizationsInputs({ departmentOptions, onDepartmentChange, submitCustomComponent }: Props) {
  const { handleDepartmentChange, selectedRowIndex, setSelectedRowIndex } = useController({
    departmentOptions,
    onDepartmentChange,
    submitCustomComponent,
  });
  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <ListEditTable
          defaultRowsCount={15}
          rows={departmentOptions}
          columns={[{ label: '', styles: { width: '65%' }, formatValue: row => row?.name }]}
          onDoubleRowClick={handleDepartmentChange}
          selectedRowIndex={selectedRowIndex || 0}
          selectRow={setSelectedRowIndex}
        />
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { FinOrganizationsInputs };
