import { Table } from 'types/models';

export function GetProjectCodeSimpleList(): Table.Specification {
  return {
    apiID: 'GetProjectCodeSimpleList',
    header: {
      firstLevel: {
        title: 'Справочник "Шифры"',
      },
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
  };
}
