import React from 'react';

import { HashRouter, Route } from 'react-router-dom';
import { Version } from 'features/Version';
import { Notifications } from 'features/Notifications';
import { Loader } from 'features/Loader';
import { AppData } from 'features/AppData';
import { PageLayout } from 'features/PageLayout';
import { Auth } from 'features/Auth';
import './app.scss';

import { getEntries } from './entries';

import * as icons from '../icons';
import { IconsDemo } from './IconsPage';
import { IconsVectorDemo } from './IconsVectorPage';

function App() {
  return (
    <div className="App">
      <Version />
      <Notifications />
      <Loader />
      <HashRouter>
        <AppData>
          <PageLayout getEntries={getEntries} />
          <Route path="/signin" component={Auth} />
          <Route path="/register" component={Auth} />
          <Route path="/success" component={Auth} />
          <Route path="/icons-vector-demo">
            <IconsVectorDemo />
          </Route>
          <Route path="/icons-demo">
            <IconsDemo icons={icons} />
          </Route>
        </AppData>
      </HashRouter>
    </div>
  );
}

export default App;
