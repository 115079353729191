import React, { useState } from 'react';

import {
  SectionTitle,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  FormComponent,
  TextDateTime,
  ListEdit,
  Modal,
  buttonIcons,
  IconButton,
} from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { GetReferenceElementList } from 'features/Table/specifications';
import { DocumentList } from 'features/Form/looks/project/ProjectForm/Tabs/StateRegistration/StateForm/DocumentList';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
};

export function StateForm(props: Props) {
  const { disabled, project, makeChangeHandler } = props;
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const helpButton = {
    icons: buttonIcons.toolbarHelp,
    title: 'Помощь',
    onClick: () => setIsHelpModalOpen(true),
  };
  return (
    <>
      <IconButton {...helpButton} />
      <FormComponent.Line hasGap>
        <FormComponent.Field label="Номер госрегистрации">
          <TextInput value={project?.stateNumber} onChange={makeChangeHandler('stateNumber')} isDisabled={disabled} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата госрегистрации">
          <TextDateTime
            value={project?.stateRegistrationDate}
            onChange={makeChangeHandler('stateRegistrationDate')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ссылка на ЕГИСУ">
          <TextInput
            mode={TextInputMode.url}
            value={project?.stateRegistrationEgisuUrl}
            onChange={makeChangeHandler('stateRegistrationEgisuUrl')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Аннотация"
          // eslint-disable-next-line max-len
          tooltip="В краткой свободной форме опишите планируемые результаты работы, основные ожидаемые характеристики и параметры объекта исследования или разработки. Текст должен быть кратким и точным, не должен содержать сложных формул, таблиц, рисунков и аббревиатур без их расшифровки (кроме общепринятых), а также сокращенных слов (кроме общепринятых). Рекомендованный ЕГИСУ объём - до 1300 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Аннотация' }}
            value={project?.stateRegistrationAnnotation ?? ''}
            onChange={makeChangeHandler('stateRegistrationAnnotation')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Научный задел"
          // eslint-disable-next-line max-len
          tooltip="Укажите основные ранее полученные результаты, связанные непосредственно с темой НИОКР (за последние 3 года). А также верифицированные ссылки на публикации, реализованные НИР по теме проекта, РИДы, защищенные диссертации, доклады по тематике и другие результаты. Рекомендованный ЕГИСУ объём - до 7000 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Научный задел' }}
            value={project?.stateRegistrationScientificGroundwork ?? ''}
            onChange={makeChangeHandler('stateRegistrationScientificGroundwork')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Обоснование междисциплинарного подхода"
          // eslint-disable-next-line max-len
          tooltip="Данное поле заполняется только при наличии указания разных тематических рубрик первого уровня (ГРНТИ и Кодов Международной Классификации). В краткой свободной форме обосновывается междисциплинарный характер работы, относящийся к разным тематическим уровням. Рекомендованный ЕГИСУ объём - до 3000 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Обоснование междисциплинарного подхода' }}
            value={project?.stateRegistrationInterdisciplinaryAproachJustification ?? ''}
            onChange={makeChangeHandler('stateRegistrationInterdisciplinaryAproachJustification')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field>
          <ListEdit
            rows={project?.workSpaces || []}
            isToolbarDisabled={disabled}
            onChange={makeChangeHandler('workSpaces')}
            columns={[{ label: 'Наименование', formatValue: el => el?.ref?.label || '' }]}
            isDeleteConfirmEnabled
            title="Работа выполняется в рамках"
            withMessages
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Атрибуты проекта для регистрации в ЕГИСУ',
              modalTableRowConverter: row => {
                return {
                  id: null,
                  ref: {
                    label: row.label,
                    id: row.id,
                  },
                  isActual: false,
                  isProject: false,
                  createdBy: '',
                  createdDate: '',
                  position: '',
                };
              },
              modalTableSpecification: GetReferenceElementList({
                requestData: {
                  filters: [],
                  name: 'RefProjectWorkSpace',
                },
              }),
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <SectionTitle title="Документы из ЕГИСУ" />
      <FormComponent.Line>
        <DocumentList documents={project?.documents.filter(d => d.projectDocumentType === 'EGISU') || null} />
      </FormComponent.Line>
      <Modal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} title="Госрегистрация проекта" size="medium">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Информация на этой вкладке предназначена для регистрации проекта в Единой государственной информационной системе
              учета научно-исследовательских, опытно-конструкторских и технологических работ гражданского назначения (ЕГИСУ
              ЦИТиС). Поля для заполнения должны содержать краткое описание, ограниченное о знакам и соответствовать содержанию
              описания проекта в конкурсной документации
            </p>
            <p>Заполните поля и сохраните проект, используя кнопку &quot;Сохранить и продолжить&quot;.</p>
            <p>
              С помощью кнопки &quot;Печать документов&quot; постройте отчёт &quot;Информация для ИКРБС&quot; и проверьте данные о
              вашем проекте. О несоответствиях в данных о вашем проекте в построенном отчёте &quot;Информация для ИКРБС&quot;
              запишите в поле &quot;Примечание&quot;.
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}
