import React from 'react';
import { MobileRequest } from 'types/models';

import {
  FormComponent,
  InputSelect,
  Reference,
  TextGridSelect,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { GetNUFinancingSourceList } from 'features/Table/specifications';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.Financing | null;
  onFieldChange(row: MobileRequest.Financing | null): void;
};

function FinancingFields({ entry, onFieldChange }: Props) {
  const {
    typeOptions,
    sf3SourceOptions,
    handleTypeChange,
    handleSf1SourceChange,
    handleDepartmentChange,
    handleSf3SourceChange,
    handleAmountChange,
    handleNoteChange,
    isSf1Type,
    isSf2Type,
    isSf3Type,
    sf1SourceRowConverter,
  } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Тип источника" isRequired>
        <InputSelect options={typeOptions} value={entry?.type || null} onSelectChange={handleTypeChange} />
      </FormComponent.Field>
      {isSf1Type ? (
        <FormComponent.Field label="Источник" isRequired>
          <TextGridSelect<MobileRequest.Sf1Source>
            value={entry?.sf1Source || null}
            onChange={handleSf1SourceChange}
            specification={GetNUFinancingSourceList()}
            settings={{
              mode: 'selectTableRow',
              tableRowConverter: x => sf1SourceRowConverter(x),
            }}
            valueFormatter={x => x.projectCodeValue || x.fundCardValue}
            title="Виды расхода"
          />
        </FormComponent.Field>
      ) : (
        <div />
      )}
      {isSf2Type ? (
        <FormComponent.Field label="Подразделение" isRequired>
          <Reference
            value={entry?.department || null}
            name="RefDepartment"
            relationTableModalTitle='Справочник "Подразделения"'
            onChange={handleDepartmentChange}
          />
        </FormComponent.Field>
      ) : (
        <div />
      )}
      {isSf3Type ? (
        <FormComponent.Field label="Источник" isRequired>
          <InputSelect options={sf3SourceOptions} value={entry?.sf3Source || null} onSelectChange={handleSf3SourceChange} />
        </FormComponent.Field>
      ) : (
        <div />
      )}
      <FormComponent.Field label="Сумма, руб." isRequired>
        <TextInput mode={TextInputMode.number} value={entry?.amount} onChange={handleAmountChange} />
      </FormComponent.Field>
      <FormComponent.Field label="Примечание" isRequired={isSf3Type}>
        <TextArea
          mode={TextAreaMode.modal}
          settings={{ rows: 3, title: 'Примечание' }}
          value={entry?.note || ''}
          onChange={handleNoteChange}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}

export { FinancingFields };
