import { Parameter } from 'types/models';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateEnumeration = (enumParameter: Parameter): ValidatorResult => {
  const isValid = enumParameter.required && !!enumParameter.value;

  return { isValid, message: isValid ? '' : `Заполните поле "${enumParameter.description}"` };
};

export default validateEnumeration;
