import React, { useState } from 'react';

import { Form } from 'features/Form';
import { EntityLocker } from 'features/EntityLocker';
import { Form as F, PublicationTypeProps } from 'types/models';

import { Modal } from 'components';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

const MagazineEditTemplate: React.FC<F.TemplateProps & PublicationTypeProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  handleChangeParticipation,
  isHidden,
  setPublication,
  setPublicationConferenceSource,
}: F.TemplateProps & PublicationTypeProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    apiID: 'GetPublication',
    template: PublicationTypes.PREPRINT_ARTICLE.template,
    type: PublicationTypes.PREPRINT_ARTICLE.code,
    editMode: true,
    id: String(id),
    relatedTableState,
    setPublication,
    handleChangeParticipation,
    setPublicationConferenceSource,
    setTitle,
  };

  return (
    <EntityLocker onClose={onClose} isOpen={isOpen} entity="Publication" id={look.id}>
      <Modal isOpen={isOpen && !isHidden} onClose={onClose} title={title} size="full">
        <Form look={look} onClose={onClose} />
      </Modal>
    </EntityLocker>
  );
};

export const Component = React.memo(MagazineEditTemplate);
