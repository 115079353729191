import React from 'react';

import { FormComponent, Reference, TextArea, TextDateTime } from 'components';

import { PeriodableReferenceItem } from 'types/models/Partner';
import { ValueOf } from 'types/helpers';

type Props = {
  okved: PeriodableReferenceItem;
  setOkved(okved: PeriodableReferenceItem): void;
};

export function Fields(props: Props) {
  const { okved, setOkved } = props;

  const makeChangeHandler = (key: keyof PeriodableReferenceItem) => (value: ValueOf<PeriodableReferenceItem>) => {
    setOkved({ ...okved, [key]: value });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="ОКВЭД">
          <Reference
            name="RefOkved2"
            onChange={makeChangeHandler('ref')}
            value={okved.ref}
            relationTableModalTitle='Справочник "ОКВЭД"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap>
        <FormComponent.Field isRequired label="Срок действия">
          <TextDateTime
            isRange
            value={`${okved.startDate || ''}-${okved.endDate || ''}`}
            onChange={(value: string) => {
              const dates = value.split('-');
              setOkved({ ...okved, startDate: dates[0] || '', endDate: dates[1] || '' } as PeriodableReferenceItem);
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Примечание">
          <TextArea settings={{ isFixed: true }} value={okved.note ?? ''} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
