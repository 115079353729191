import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { Settings } from 'types/models/common';
import { convertSecurityDocument } from './converters';
import { convertSecurityDocumentToXML } from './requestConverters';
import { SecurityDocumentSaveInput } from './types';

const configurations = {
  GetSecurityDocument: makeBackendAPIConfiguration({
    id: 'GetSecurityDocument',
    endpoint: '/msa/service/commands/GetSecurityDocument',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetSecurityDocument' },
        SecurityDocument: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertSecurityDocument(response.Response.SecurityDocument, settings),
  }),
  SaveSecurityDocument: makeBackendAPIConfiguration({
    id: 'SaveSecurityDocument',
    endpoint: '/msa/service/commands/SaveSecurityDocument',
    convertInputToXMLElement: (inputData: SecurityDocumentSaveInput) => ({
      Request: {
        _attr: {
          commandName: 'SaveSecurityDocument',
        },
        SecurityDocument: { ...convertSecurityDocumentToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
};

export const securityDocumentConfiguration = { ...configurations };
