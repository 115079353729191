import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { FundCard } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { useFormContext } from 'features/Form/hooks';
import { IconButtonProps, buttonIcons } from 'components';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, onClose }: Props) {
  const {
    look: { id: initialId },
  } = useFormContext();

  const tableStreams = useLocalTableStreams();

  const [fundCard, setFundCard] = useState<FundCard>({ id: initialId || null, name: '', note: '' });

  const { methods: saveFundCard } = BackendAPI.useBackendAPI('SaveFundCard', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Шифр ЦФ сохранён', theme: 'success' });
      tableStreams.reloadTable.push();
      onClose();
    },
  });

  const { methods: getFundCard } = BackendAPI.useBackendAPI('GetFundCard', {
    onSuccessfullCall: ({ data }) => {
      setFundCard(data);
    },
  });

  const onSubmit = useCallback(() => {
    if (!fundCard.name) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }
    saveFundCard.callAPI(fundCard);
  }, [fundCard, saveFundCard]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'add',
        onClick: () => onSubmit(),
        isHidden: !!viewMode,
      },
    ],
    [onSubmit, viewMode],
  );

  useLayoutEffect(() => {
    if (initialId) getFundCard.callAPI({ id: initialId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    buttons,
    fundCard,
    setFundCard,
  };
}
