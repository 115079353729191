import React from 'react';
import { GetLinkedHistoryEntitiesRequestData } from 'services/BackendAPI/configurations/author/types';
import { DataGrid } from 'features/Table';
import { GetLinkedHistoryEntities } from 'features/Table/specifications';

type Props = {
  personId?: string;
} & GetLinkedHistoryEntitiesRequestData;

export const Component = ({ id, type, personId }: Props) => (
  <DataGrid
    specification={GetLinkedHistoryEntities({
      personId,
      id,
      type,
    })}
  />
);
