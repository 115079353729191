import { Project } from 'types/models';

export function validate(performer: Project.Performer | null) {
  if (!performer) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }
  const isValidPerson = Boolean(performer.person);
  const isValidJobPeriods = performer.jobPeriods.length > 0;

  return [
    { isValid: isValidPerson, invalidMessage: 'Не выбран исполнитель' },
    { isValid: isValidJobPeriods, invalidMessage: 'Не указан ни один период работы' },
  ];
}
