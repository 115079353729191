import React, { useCallback } from 'react';

import { Table } from 'types/models';

import { EditProject } from 'features/Form/looks/project/ProjectForm';

import './style.scss';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function Form({ customState, tableState }: Props) {
  const { isOpenForm, toggleVisibleForm, mode, reloadTable } = customState as State;
  const [row] = tableState.selectedRows;

  const onSussessSave = useCallback(() => {
    reloadTable();
    toggleVisibleForm();
  }, [reloadTable, toggleVisibleForm]);

  return (
    <>
      <EditProject
        id={row?.id ?? null}
        isOpen={isOpenForm}
        onClose={toggleVisibleForm}
        mode={mode}
        onSuccessfullSave={onSussessSave}
      />
    </>
  );
}
