import React from 'react';

import { DataGrid } from 'features/Table';
import { GetScientistHirshIndexesList } from 'features/Table/specifications';

import { useController } from './controller';

function ScientistDataForm() {
  const { relatedTableState, id } = useController();
  const workMode = 'editMode';
  return (
    <>
      {relatedTableState && (
        <DataGrid
          specification={GetScientistHirshIndexesList(
            {
              relatedTableAPIID: 'GetScientistStatistics',
              relatedTableState,
              relatedRecordId: id,
            },
            workMode,
            id || '-1',
          )}
        />
      )}
    </>
  );
}

export const Component = React.memo(ScientistDataForm);
