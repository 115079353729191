import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Toolbar as SharedToolbar, ConfirmPopup, Modal, TextArea } from 'components';

import { NirRequest, Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { SelectTenderTypeModal } from 'features/Form/views/SelectTenderTypeModal/SelectTenderTypeModal';
import { MessageModal } from 'features/Form/views/MessageModal/MessageModal';
import useController from './controller';
import { DataGrid } from 'features/Table';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { TenderRequestType } from 'utils/Enums';
import { TenderInfo } from 'types/models/Form/look/programRequest';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';

export type NirRequestLocalType = {
  specification: string;
  tenderType: NirRequest.TenderType | null;
} & TenderInfo;

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel(props: Props) {
  const { tableState } = props;
  const {
    isMessageModalOpen,
    handleCloseMessageModal,
    buttons,
    isConfirmDeleteModalOpen,
    setIsConfirmModalOpen,
    deleteNir,
    selectedRow,
    isReportOpen,
    onReportClose,
    currentReport,
    isSelectTenderTypeModalOpen,
    handleCloseTenderTypeModal,
    handleSubmitTenderTypeModal,
    handleTenderSelect,
    isTenderModalOpen,
    setIsTenderModalOpen,
    isViewFormOpen,
    isEditFormOpen,
    isAddFormOpen,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    sendMessage,
    nirRequest,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = useController({ tableState });

  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.NirRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          nirRequest={null}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.NirRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          nirRequest={null}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.NirRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={() => setIsAddFormOpen(false)}
          relatedTableState={tableState}
          nirRequest={nirRequest}
        />
      )}
      <MessageModal
        isOpen={isMessageModalOpen}
        onClose={handleCloseMessageModal}
        onSubmit={sendMessage}
        titleText="Добавление сообщения в журнал"
        submitText="Добавить"
      />
      {isStatusModalOpen && (
        <ConfirmPopup
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          onConfirm={changeStatus}
          okButtonText={statusLabel}
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </ConfirmPopup>
      )}
      <SharedToolbar buttons={buttons} />
      <ConfirmPopup
        title="Удаление заявки"
        okButtonText="Да"
        resetButtonText="Отмена"
        icon="warning"
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        text="Вы действительно хотите удалить выбранную заявку?"
        onConfirm={() => {
          deleteNir(selectedRow.id);
          setIsConfirmModalOpen(false);
        }}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        nirRequestId={selectedRow?.id ?? ''}
      />
      <SelectTenderTypeModal
        isOpen={isSelectTenderTypeModalOpen}
        onClose={handleCloseTenderTypeModal}
        onSubmit={handleSubmitTenderTypeModal}
      />
      <Modal
        isOpen={isTenderModalOpen}
        onClose={() => setIsTenderModalOpen(false)}
        title="Список конкурсов"
        size={['extralarge', 'large-height']}
      >
        <DataGrid
          specification={GetTenderListForRequest({
            tenderRequestType: TenderRequestType.NIR_REQUEST,
            selectRow: handleTenderSelect,
          })}
        />
      </Modal>
      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
