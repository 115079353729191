import { Table } from 'types/models';

export type Arguments = {
  projectId: string;
};

export function GetProjectCodeListByProject(args: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectCodeListByProject',
    requestData: args,
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
  };
}
