import React from 'react';
import block from 'bem-cn';

import { FormComponent, Checkbox, Radio } from 'components';

import { CollectionItem, CollectionValue, RelationType } from 'types/models/Merge';
import useController from './controller';

import './style.scss';

const b = block('collection');

type Props = {
  value: CollectionValue;
  relationType: RelationType;
  collection?: CollectionItem[];
  isCommon?: boolean;
  setValue: (collectionValue: CollectionValue) => void;
};

export const CollectionField = ({ value, collection, setValue, isCommon, relationType }: Props) => {
  const {
    radioButtons,
    isAllSelected,
    onAllSelectedClick,
    onRadioChange,
    onValueChange,
    getIsCollectionItemChecked,
  } = useController({
    value,
    setValue,
    collection: collection || [],
  });
  return (
    <div className={b({ 'without-checkbox': isCommon })}>
      {!isCommon && (
        <div className={b('checkbox')}>
          <Checkbox checked={isAllSelected} onChange={onAllSelectedClick} isDisabled={!collection?.length} />
        </div>
      )}
      <div className={b('body')}>
        {isCommon &&
          relationType === RelationType.MANY_TO_MANY &&
          (collection || []).map(({ caption, id }) => (
            <FormComponent.Line key={id}>
              <span className={b('collection-item-caption')}>{caption}</span>
            </FormComponent.Line>
          ))}
        {isCommon && relationType === RelationType.ONE_TO_MANY && (
          <Radio value={value.analogId ?? undefined} list={radioButtons} onChange={onRadioChange} />
        )}
        {!isCommon &&
          (collection || []).map(collectionItem => (
            <FormComponent.Line key={collectionItem.id}>
              <FormComponent.Field>
                <Checkbox
                  label={collectionItem.caption}
                  checked={getIsCollectionItemChecked(collectionItem.id)}
                  onChange={onValueChange(collectionItem.id)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          ))}
      </div>
    </div>
  );
};
