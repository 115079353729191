import React from 'react';
import { setup } from 'bem-cn';

import { Button, Props as ButtonProps } from 'components/Button';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});

const b = block('toolbar');

type ToolbarMode = 'form' | 'list';

type Props = {
  buttons: ButtonProps[];
  mode?: ToolbarMode;
};

/**
 * Toolbar panel
 *
 * @param buttons - List of buttons
 * @param mode - (optional) Mode of toolbar ('form' | 'list') paddings and colors
 * @returns JSX
 */
export const ToolbarNew = React.memo(({ buttons, mode }: Props) => {
  return (
    <div className={b({ mode })}>
      {buttons.map(x => (
        <Button {...x} />
      ))}
    </div>
  );
});
