import { convertItem, convertMaybeArray, convertReference, getId, getText } from '../commonConverters';
import {
  ContestRequestKindConsumption,
  Incrimenator,
  KindConsumption,
  MobileKindConsumption,
  MobileKindConsumptionType,
  NirRequestKindConsumption,
} from 'types/models/KindConsumption';
import { convertKindConsumption, convertTax } from 'services/BackendAPI/configurations/estimates/converters';

export function convertServerKindConsumption(kindConsumption: any): KindConsumption {
  return {
    isActive: getText(kindConsumption.IsActive) === 'true',
    kosgu: convertReference(kindConsumption.Kosgu),
    kvr: convertReference(kindConsumption.Kvr),
    parent: kindConsumption.Parent ? convertKindConsumption(kindConsumption.Parent) : undefined,
    tax: kindConsumption.Tax ? convertTax(kindConsumption.Tax) : undefined,
    id: kindConsumption?._attributes.id || '',
    name: getText(kindConsumption.Name),
    code: getText(kindConsumption.Code),
    isCalculateTax: getText(kindConsumption.IsCalculateTax) === 'true',
    isSendOverhead: getText(kindConsumption.IsSendOverhead) === 'true',
    isAccomplicePayment: getText(kindConsumption.IsAccomplicePayment) === 'true',
    percentTax: parseFloat(getText(kindConsumption.PercentTax)),
  };
}
export function convertServerMobileKindConsumption(kindConsumption: any): MobileKindConsumption {
  return {
    id: kindConsumption?._attributes.id || '',
    name: getText(kindConsumption.Name),
    code: getText(kindConsumption.Code),
    description: getText(kindConsumption.Description),
    types: kindConsumption.Types
      ? convertMaybeArray(kindConsumption.Types.Type || [], ct => convertItem<MobileKindConsumptionType>(ct))
      : [],
    kindConsumptions: kindConsumption.KindConsumptions.KindConsumption
      ? convertMaybeArray(kindConsumption.KindConsumptions.KindConsumption || [], kc => convertKindConsumption(kc))
      : [],
  };
}

export function convertServerNirRequestKindConsumption(nirRequestKindConsumption: any): NirRequestKindConsumption {
  const increments = nirRequestKindConsumption.IncrimentKindConsumptions.IncrimentKindConsumption || [];
  return {
    id: getId(nirRequestKindConsumption),
    name: getText(nirRequestKindConsumption.Name),
    description: getText(nirRequestKindConsumption.Description),
    incrimentKindConsumptions: increments ? convertMaybeArray(increments, kc => convertServerNirRequestKindConsumption(kc)) : [],
    ...(nirRequestKindConsumption.Incrimenator
      ? { incrimenator: convertIncrimenator(nirRequestKindConsumption.Incrimenator) }
      : {}),
    ...(nirRequestKindConsumption.KindConsumption
      ? { kindConsumption: convertKindConsumption(nirRequestKindConsumption.KindConsumption) }
      : {}),
    isActive: getText(nirRequestKindConsumption.IsActive) === 'true',
    position: parseInt(getText(nirRequestKindConsumption.Position), 10),
  };
}

export function convertIncrimenator(inc: any): Incrimenator {
  return {
    id: getId(inc),
    isActive: getText(inc.IsActive) === 'true',
    name: getText(inc.Name),
    position: parseInt(getText(inc.Posiition), 10),
    description: getText(inc.Description),
    ...(inc.KindConsumption ? { kindConsumption: convertKindConsumption(inc.KindConsumption) } : {}),
  };
}

export function convertServerContestRequestKindConsumption(requestKindConsumption: any): ContestRequestKindConsumption {
  const increments = requestKindConsumption.IncrimentKindConsumptions.IncrimentKindConsumption || [];
  return {
    id: getId(requestKindConsumption),
    name: getText(requestKindConsumption.Name),
    description: getText(requestKindConsumption.Description),
    incrimentKindConsumptions: increments ? convertMaybeArray(increments, kc => convertServerNirRequestKindConsumption(kc)) : [],
    ...(requestKindConsumption.Incrimenator ? { incrementor: convertIncrimenator(requestKindConsumption.Incrimenator) } : {}),
    ...(requestKindConsumption.KindConsumption
      ? { kindConsumption: convertKindConsumption(requestKindConsumption.KindConsumption) }
      : {}),
    isActive: getText(requestKindConsumption.IsActive) === 'true',
    position: parseInt(getText(requestKindConsumption.Position), 10),
  };
}
