import { Group, Report, ResponseData, Parameter } from 'types/models';
import { PrintFormat } from 'utils/Enums/PrintFormat';
import { GetReportTreeResponse, GetReportDocumentsResponse, GetServerReportParametersResponse } from './types';

import { convertMaybeArray, convertDocument } from '../commonConverters';
import { Parameters } from 'features/BuildReportPopup/Parameters';

export const convertReportGroup = (group: any): Group<Report> => ({
  id: group._attributes?.id as string,
  caption: group._attributes?.caption as string,
  list: convertMaybeArray(group.Report, convertReport),
});

export const convertReport = (report: any): Report => ({
  name: report._attributes?.name as string,
  caption: report._attributes?.caption as string,
});

export const convertSaveReportDocumentResponseXMLElementToOutput = (response: any): ResponseData => ({
  success: response.Response._attributes.success === 'true',
  message: response.Response._attributes.errorMessage || response.Response._attributes.serverErrorMessage || '',
});

export const convertDeleteReportDocumentResponseXMLElementToOutput = (response: any): ResponseData => ({
  success: response.Response._attributes.success === 'true',
  message: response.Response._attributes.errorMessage || response.Response._attributes.serverErrorMessage || '',
});

export const convertServerReportTree = (reportTree: any): GetReportTreeResponse => {
  const { Response } = reportTree;

  const serverGroups = Response?.Group;
  return {
    groups: convertMaybeArray(serverGroups, convertReportGroup),
  };
};

export const convertServerReportDocuments = (reportDocuments: any): GetReportDocumentsResponse => {
  const { Response } = reportDocuments;

  const serverDocuments = Response.Documents?.Document;
  return {
    documents: convertMaybeArray(serverDocuments, (document: any) => convertDocument('local', document)),
  };
};

export const convertAllowedFormatIds = (serverAllowedFormatIds: string) => {
  const FORMATS_SEPARATOR = ',';
  const allowedFormatIds = serverAllowedFormatIds.split(FORMATS_SEPARATOR);

  return allowedFormatIds;
};

export const convertGetReportParametersXMLElementToOutput = (reportParameters: any): GetServerReportParametersResponse => {
  const { Response } = reportParameters;

  const serverParametersWrapper = Response?.Parameters;
  const serverParameters = serverParametersWrapper?.Parameter;
  const serverAllowedFormatIds = serverParametersWrapper?._attributes?.allowedFormats;
  const serverDefaultFormatId = serverParametersWrapper?._attributes?.defaultFormat || PrintFormat.XLSX.id;

  return {
    defaultFormatId: serverDefaultFormatId,
    allowedFormatIds: serverAllowedFormatIds && convertAllowedFormatIds(serverAllowedFormatIds),
    isHasManual: (serverParametersWrapper?._attributes?.manual as string) === 'true',
    parameters: convertMaybeArray(serverParameters, convertReportParameter),
  };
};

export const convertReportParameter = (parameter: any): Parameter => {
  const name = (parameter._attributes?.name as string) || '';
  return {
    name,
    type: (parameter._attributes?.type as string) || '',
    prompting: parameter._attributes?.prompting === 'true',
    description: (parameter._attributes?.description as string) || '',
    required: parameter._attributes?.required === 'true',
    startDateRange: parameter._attributes?.startDateRange === 'true',
    endDateRange: parameter._attributes?.endDateRange === 'true',
    default: (parameter._attributes?.default as string) || '',
    isAbstractParameter: Object.values(Parameters).every(value => value !== name),
    value: null,
  };
};
