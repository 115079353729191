import { useCallback, useMemo, useState } from 'react';

import { buttonIcons } from 'components';
import { ExtraButton } from 'components/TextGridSelect/TextGridSelect';

import { Item } from 'types/models/common';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import {
  CopyFromNirRequest,
  CopyFromProgramRequest,
  NirRequestCopyOptions,
  ProgramRequestCopyOptions,
} from 'features/Form/looks/project/ProjectForm/types';
import { showNotification } from 'features/Notifications';
import { SecurityDocumentContract } from 'types/models/SecurityDocumentContract';

type Props = {
  disabled: boolean;
  copyFromNirRequest({ id, nextOverheadPercent }: CopyFromNirRequest): void;
  copyFormProgramRequest({ id }: CopyFromProgramRequest): void;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};

export function useController(props: Props) {
  const { disabled, copyFromNirRequest, copyFormProgramRequest, makeChangeHandler } = props;

  const [isResetOverheadPercentOpen, setIsResetOverheadPercentOpen] = useState<boolean>(false);
  const [isNirRequestOptionsPopupOpen, setIsNirRequestOptionsPopupOpen] = useState<boolean>(false);
  const [isProgramRequestOptionsPopupOpen, setIsProgramRequestOptionsPopupOpen] = useState<boolean>(false);
  const [nirRequestItem, setNirRequestItem] = useState<Item | null>(null);
  const [programRequestItem, setProgramRequestItem] = useState<Item | null>(null);
  const [nirRequestCopyOptions, setNirRequestCopyOptions] = useState<NirRequestCopyOptions>({
    isProject: false,
    isStage: false,
    isIndicator: false,
    isPerformers: false,
  });
  const [programRequestCopyOptions, setProgramRequestCopyOptions] = useState<ProgramRequestCopyOptions>({
    isProject: false,
    isClassifiers: false,
    isPerformers: false,
  });

  const confirmNirRequestOptions = useCallback(() => {
    if (
      !nirRequestCopyOptions.isIndicator &&
      !nirRequestCopyOptions.isPerformers &&
      !nirRequestCopyOptions.isProject &&
      !nirRequestCopyOptions.isStage
    ) {
      showNotification({ message: 'Выберите хотя бы одну опцию для копирования', theme: 'danger' });
      return;
    }
    setIsNirRequestOptionsPopupOpen(false);
    setIsResetOverheadPercentOpen(true);
  }, [nirRequestCopyOptions]);

  const confirmProgramRequestOptions = useCallback(() => {
    if (
      !programRequestCopyOptions.isProject &&
      !programRequestCopyOptions.isClassifiers &&
      !programRequestCopyOptions.isPerformers
    ) {
      showNotification({ message: 'Выберите хотя бы одну опцию для копирования', theme: 'danger' });
      return;
    }
    setIsProgramRequestOptionsPopupOpen(false);
    if (programRequestItem) copyFormProgramRequest({ id: programRequestItem.value, copyOptions: programRequestCopyOptions });
  }, [copyFormProgramRequest, programRequestCopyOptions, programRequestItem]);

  const onResetResetOverheadPercent = useCallback(() => {
    if (nirRequestItem) {
      copyFromNirRequest({ id: nirRequestItem.value, copyOptions: nirRequestCopyOptions });
    }

    setIsResetOverheadPercentOpen(false);
  }, [nirRequestCopyOptions, copyFromNirRequest, nirRequestItem]);

  const onConfirmResetOverheadPercent = useCallback(() => {
    if (nirRequestItem) {
      copyFromNirRequest({ id: nirRequestItem.value, nextOverheadPercent: '0.00', copyOptions: nirRequestCopyOptions });
    }

    setIsResetOverheadPercentOpen(false);
  }, [nirRequestCopyOptions, copyFromNirRequest, nirRequestItem]);

  const onResetOverheadPercentClose = useCallback(() => {
    setIsResetOverheadPercentOpen(false);
  }, []);

  const nirRequestExtraButtons = useMemo<ExtraButton<Item>[]>(
    () => [
      {
        icons: buttonIcons.clone,
        title: 'Скопировать данные из выбранной заявки в проект',
        code: 'copyToProject',
        checkIsDisabled: row => row === null || disabled,
        onClick: row => {
          setNirRequestItem(row);
          setIsNirRequestOptionsPopupOpen(true);
        },
      },
    ],
    [disabled],
  );

  const programRequestExtraButtons = useMemo<ExtraButton<Item>[]>(
    () => [
      {
        icons: buttonIcons.clone,
        title: 'Скопировать данные из выбранной заявки в проект',
        code: 'copyToProject',
        checkIsDisabled: row => row === null || disabled,
        onClick: row => {
          setProgramRequestItem(row);
          setIsProgramRequestOptionsPopupOpen(true);
        },
      },
    ],
    [disabled],
  );

  const setProgramRequest = useCallback(
    (programRequest: Item<string> | null) => {
      makeChangeHandler('programRequest')(programRequest);
    },
    [makeChangeHandler],
  );

  const setSecurityDocumentContract = useCallback(
    (securityDocumentContract: SecurityDocumentContract | null) => {
      makeChangeHandler('securityDocumentContract')(securityDocumentContract);
    },
    [makeChangeHandler],
  );

  const setNirRequest = useCallback(
    (nir: Item<string> | null) => {
      makeChangeHandler('nirRequest')(nir);
    },
    [makeChangeHandler],
  );
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const helpButton = {
    icons: buttonIcons.toolbarHelp,
    title: 'Помощь',
    onClick: () => setIsHelpModalOpen(true),
  };
  return {
    nirRequestExtraButtons,
    programRequestExtraButtons,
    isNirRequestOptionsPopupOpen,
    isResetOverheadPercentOpen,
    onResetResetOverheadPercent,
    onConfirmResetOverheadPercent,
    onResetOverheadPercentClose,
    setIsNirRequestOptionsPopupOpen,
    confirmNirRequestOptions,
    setProgramRequest,
    setSecurityDocumentContract,
    setNirRequest,
    nirRequestCopyOptions,
    setNirRequestCopyOptions,
    programRequestCopyOptions,
    setProgramRequestCopyOptions,
    isProgramRequestOptionsPopupOpen,
    setIsProgramRequestOptionsPopupOpen,
    confirmProgramRequestOptions,
    helpButton,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
}
