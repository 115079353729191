import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as R from 'ramda';

import { Form, Document, Tender } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';

import { ProgramRequest, Department, Finance, RequestPartner, Performer, EstimatePosition } from 'types/models/ProgramRequest';

import { useFormContext } from 'features/Form/hooks';
import { buttonIcons, IconButtonProps, ReferenceItem } from 'components';
import { parse } from 'date-fns';

import { ValueOf } from 'types/helpers';
import workModeHook from 'features/Form/hooks/workModeHook';

import { formatStr } from 'utils/Constants/FormatStr';
import { ProgramRequestFormLook } from 'types/models/Form/look/programRequest';
import { validate, ValidateType } from './validate';
import { RequestStatus } from 'utils/Enums/RequestStatus';
import { Permits } from 'utils/Permissions';
import { Item } from 'types/models/common';
import { getEnum } from 'utils/Helpers';
import { RequestApprovementResolution, RequestPerformerRole } from 'utils/Enums';
import { getMockRequestInfo } from './helpers';
import { LotPrice } from 'types/models/Tender';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode: initialEditMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id: initialId, tenderInfo, copyMode },
  } = useFormContext<ProgramRequestFormLook>();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode: initialEditMode });

  const [id, setId] = useState<string | null>(initialId || null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [performers, setPerformers] = useState<Performer[]>([]);
  const [finances, setFinances] = useState<Finance[]>([]);
  const [partners, setPartners] = useState<RequestPartner[]>([]);
  const [okveds, setOkveds] = useState<ReferenceItem[]>([]);
  const [okvedParentLabel, setOkvedParentLabel] = useState<string>('');
  const [lksetss, setLksetss] = useState<ReferenceItem[]>([]);
  const [lksetsParentLabel, setLksetsParentLabel] = useState<string>('');
  const [scienceBrunches, setScienceBrunches] = useState<ReferenceItem[]>([]);
  const [scienceBrunchParentLabel, setScienceBrunchParentLabel] = useState<string>('');
  const [domainKnowledges, setDomainKnowledges] = useState<ReferenceItem[]>([]);
  const [personId, setPersonId] = useState<string | null>(null);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState<boolean>(false);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<RequestStatus | null>(null);
  const [statusChangeMessage, setStatusChangeMessage] = useState<string>('');
  const [statusChangeName, setStatusChangeName] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);
  const [approvementStatus, setApprovementStatus] = useState<RequestApprovementResolution>(RequestApprovementResolution.AGREE);
  const [approvementMessage, setApprovementMessage] = useState<string>('');

  const provideKindOptions = getEnum('KindProvide');
  const approvementOptions = getEnum('RequestApprovementResolution') as Array<Item<RequestApprovementResolution>>;

  const [requestInfo, setRequestInfo] = useState<ProgramRequest>(getMockRequestInfo());

  const [isLotPriceWarningOpen, setIsLotPriceWarningOpen] = useState<boolean>(false);

  const updateManager = useCallback<(newManager: Performer | null) => void>(
    (newManager: Performer | null) => {
      const nextManager = newManager?.person?.id ? newManager : null;
      formFields.manager.onChange(nextManager);

      let perf = performers;

      perf = perf.filter(p => p.role?.value !== 'LEADER');

      if (nextManager) {
        perf.push({ ...nextManager, role: { label: nextManager.role?.label ?? '', value: RequestPerformerRole.LEADER } });
      }

      setPerformers(perf);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [performers],
  );

  const isValidDateRange = (from: string | undefined, to: string | undefined) => {
    const start = from ? parse(from, formatStr, new Date()) : null;
    const end = to ? parse(to, formatStr, new Date()) : null;
    if (!start || !end) {
      return false;
    }
    return end.getTime() - start.getTime() > 0;
  };

  const [formFields, setFormFields] = useState<Form.Fields>({
    requestDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Подана на конкурс',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestDate: { ...prevState.requestDate, value },
        }));
      },
    },
    tender: {
      value: null,
      isValid: true,
      required: true,
      title: 'Конкурс',
      onChange: (value: Tender.Tender | null) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          tender: { ...prevState.tender, value },
        }));
      },
    },
    lot: {
      value: null,
      isValid: true,
      required: false,
      title: 'Лот',
      onChange: (value: Tender.Lot | null) => {
        const isSupportRequest = Boolean(value?.isSupportRequest);
        const isSupportContract = Boolean(value?.isSupportContract);
        const isSupportWarranty = Boolean(value?.isSupportWarranty);

        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          lot: { ...prevState.lot, value },
          ...(isSupportRequest
            ? {}
            : {
                requestProvideAmount: { ...formFields.requestProvideAmount, value: '0.00' },
                requestProvideKind: { ...formFields.requestProvideKind, value: '' },
                requestProvideNote: { ...formFields.requestProvideNote, value: '' },
              }),
          ...(isSupportContract
            ? {}
            : {
                contractProvideAmount: { ...formFields.contractProvideAmount, value: '0.00' },
                contractProvideKind: { ...formFields.contractProvideKind, value: '' },
                contractProvideNote: { ...formFields.contractProvideNote, value: '' },
              }),
          ...(isSupportWarranty
            ? {}
            : {
                warrantyProvideAmount: { ...formFields.warrantyProvideAmount, value: '0.00' },
                warrantyProvideKind: { ...formFields.warrantyProvideKind, value: '' },
                warrantyProvideNote: { ...formFields.warrantyProvideNote, value: '' },
              }),
        }));
      },
    },
    code: {
      value: '',
      isValid: true,
      required: false,
      title: 'Шифр заявки',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: { ...prevState.code, value },
        }));
      },
    },
    theme: {
      value: '',
      isValid: true,
      required: true,
      title: 'Тема',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          theme: { ...prevState.theme, value },
        }));
      },
    },
    startDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Сроки выполнения "с"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          const isValid = value === null || value === '' || isValidDateRange(value, prevState.endDate.value);
          return {
            ...prevState,
            startDate: {
              ...prevState.startDate,
              value,
              isValid,
            },
            endDate: {
              ...prevState.endDate,
              isValid,
            },
          };
        });
      },
    },
    endDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Сроки выполнения "по"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          const isValid = value === null || value === '' || isValidDateRange(prevState.startDate.value, value);
          return {
            ...prevState,
            endDate: {
              ...prevState.endDate,
              value,
              isValid,
            },
            startDate: {
              ...prevState.startDate,
              isValid,
            },
          };
        });
      },
    },
    manager: {
      value: '',
      isValid: true,
      required: true,
      title: 'Руководитель проекта',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          manager: { ...prevState.manager, value },
        }));
      },
    },
    requestProvideAmount: {
      value: '',
      isValid: true,
      required: false,
      title: 'Сумма',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestProvideAmount: { ...prevState.requestProvideAmount, value },
        }));
      },
    },
    requestProvideKind: {
      value: '',
      isValid: true,
      required: false,
      title: 'Вид обеспечения',
      onChange: option => {
        const value = provideKindOptions.find(x => x.value === option.value)?.value;
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestProvideKind: { ...prevState.requestProvideKind, value },
        }));
      },
    },
    requestProvideNote: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestProvideNote: { ...prevState.requestProvideNote, value },
        }));
      },
    },
    contractProvideAmount: {
      value: '',
      isValid: true,
      required: false,
      title: 'Сумма',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          contractProvideAmount: { ...prevState.contractProvideAmount, value },
        }));
      },
    },
    contractProvideKind: {
      value: '',
      isValid: true,
      required: false,
      title: 'Вид обеспечения',
      onChange: option => {
        const value = provideKindOptions.find(x => x.value === option.value)?.value;
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          contractProvideKind: { ...prevState.contractProvideKind, value },
        }));
      },
    },
    contractProvideNote: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          contractProvideNote: { ...prevState.contractProvideNote, value },
        }));
      },
    },
    warrantyProvideAmount: {
      value: '',
      isValid: true,
      required: false,
      title: 'Сумма',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          warrantyProvideAmount: { ...prevState.warrantyProvideAmount, value },
        }));
      },
    },
    warrantyProvideKind: {
      value: '',
      isValid: true,
      required: false,
      title: 'Вид обеспечения',
      onChange: option => {
        const value = provideKindOptions.find(x => x.value === option.value)?.value;
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          warrantyProvideKind: { ...prevState.warrantyProvideKind, value },
        }));
      },
    },
    warrantyProvideNote: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          warrantyProvideNote: { ...prevState.warrantyProvideNote, value },
        }));
      },
    },
    keyWords: {
      value: '',
      isValid: true,
      required: false,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          keyWords: { ...prevState.keyWords, value },
        }));
      },
    },
    annotation: {
      value: '',
      isValid: true,
      required: false,
      title: 'Аннотация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, value },
        }));
      },
    },
    note: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечания',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
    },
    periodNote: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечания',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          periodNote: { ...prevState.periodNote, value },
        }));
      },
    },
    performerCount: {
      value: '',
      isValid: true,
      required: false,
      title: 'Общее число участников',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          performerCount: { ...prevState.performerCount, value },
        }));
      },
    },
  });

  const { methods: getRequest } = BackendAPI.useBackendAPI('GetRequest');

  const { methods: saveRequest } = BackendAPI.useBackendAPI('SaveRequest');

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeRequestStatus');

  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddRequestApprovement');

  const status = useMemo(() => requestInfo?.status?.value as RequestStatus, [requestInfo?.status?.value]);

  const handleOkvedChange = useCallback(
    (value: ReferenceItem | null) => {
      setOkveds(value ? [value] : []);
      setOkvedParentLabel((value?.customFields?.parent as ReferenceItem)?.label || '');
    },
    [setOkveds],
  );

  const handleScienceBrunchChange = useCallback(
    (value: ReferenceItem | null) => {
      setScienceBrunches(value ? [value] : []);
      setScienceBrunchParentLabel((value?.customFields?.study as ReferenceItem)?.label || '');
    },
    [setScienceBrunches],
  );

  const getterSuccessfullCallback = useCallback((data: ProgramRequest) => {
    const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
    setFormFields(prevFields =>
      (R.pipe as any)(
        R.set(getValueLens('tender'), data.tender),
        R.set(getValueLens('lot'), data.lot),
        R.set(getValueLens('requestDate'), data.requestDate),
        R.set(getValueLens('startDate'), data.startDate),
        R.set(getValueLens('endDate'), data.endDate),
        R.set(getValueLens('annotation'), data.annotation),
        R.set(getValueLens('note'), data.note),
        R.set(getValueLens('performerCount'), data.performerCount),
        R.set(getValueLens('periodNote'), data.periodNote),
      )(prevFields),
    );
    setRequestInfo(data);
    setFormFields(prevFields =>
      (R.pipe as any)(
        R.set(getValueLens('code'), data.code),
        R.set(getValueLens('theme'), data.theme),
        R.set(getValueLens('requestProvideAmount'), data.requestProvideAmount),
        R.set(getValueLens('requestProvideKind'), data.requestProvideKind?.name),
        R.set(getValueLens('requestProvideNote'), data.requestProvideNote),
        R.set(getValueLens('contractProvideAmount'), data.contractProvideAmount),
        R.set(getValueLens('contractProvideKind'), data.contractProvideKind?.name),
        R.set(getValueLens('contractProvideNote'), data.contractProvideNote),
        R.set(getValueLens('warrantyProvideAmount'), data.warrantyProvideAmount),
        R.set(getValueLens('warrantyProvideKind'), data.warrantyProvideKind?.name),
        R.set(getValueLens('warrantyProvideNote'), data.warrantyProvideNote),
        R.set(getValueLens('keyWords'), data.keyWords),
      )(prevFields),
    );
    if (data.performers && Array.isArray(data.performers)) {
      data.performers.forEach((perf: Performer) => {
        if (perf?.role?.value === 'LEADER') {
          formFields.manager.onChange(perf);
        }
      });
    }
    setFinances(data.finances);
    setDepartments(data.departments);
    setPerformers(data.performers);
    setPartners(data.partners);
    setDocuments(data.documents);
    setOkveds(data.okveds);
    if (data.okveds && data.okveds.length > 0) {
      setOkvedParentLabel((data?.okveds[0]?.customFields?.parent as ReferenceItem)?.label || '');
    }
    setLksetss(data.lksetss);
    if (data?.lksetss && data?.lksetss.length > 0) {
      setLksetsParentLabel((data?.lksetss[0]?.customFields?.parent as ReferenceItem)?.label || '');
    }
    setScienceBrunches(data.scienceBrunches);
    if (data?.scienceBrunches && data?.scienceBrunches.length > 0) {
      setScienceBrunchParentLabel((data?.scienceBrunches[0]?.customFields?.study as ReferenceItem)?.label || '');
    }
    setDomainKnowledges(data.domainKnowledges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (needClose: boolean) => {
      const errors = validate(formFields, ValidateType.REQUIRED);
      if (errors && errors.length > 0) {
        const message = `Заполните обязательные поля: ${errors.map(({ invalidMessage }) => invalidMessage).join(', ')}`;
        showNotification({ message, theme: 'danger' });
        return false;
      }
      const errorsValid = validate(formFields, ValidateType.ISVALID);
      if (errorsValid && errorsValid.length > 0) {
        const message = `Ошибки в полях: ${errorsValid.map(({ invalidMessage }) => invalidMessage).join(', ')}`;
        showNotification({ message, theme: 'danger' });
        return false;
      }
      const startYear = parse(formFields.startDate.value, formatStr, new Date()).getFullYear();
      const endYear = parse(formFields.endDate.value, formatStr, new Date()).getFullYear();
      const wrongFinance = finances.filter((fin: Finance) => {
        const finYear = parseInt(fin.year, 10);
        return finYear < startYear || finYear > endYear;
      });
      if (wrongFinance && wrongFinance.length > 0) {
        showNotification({
          message:
            'Среди указанных элементов финансирования имеются такие, года которых выходят за пределы периода сроков выполнения',
          theme: 'danger',
        });
        return false;
      }
      saveRequest.callAPI(
        {
          ...requestInfo,
          id: id ?? null,
          status: null,
          code: formFields.code.value,
          theme: formFields.theme.value,
          requestDate: formFields.requestDate.value,
          startDate: formFields.startDate.value,
          endDate: formFields.endDate.value,
          keyWords: formFields.keyWords.value,
          annotation: formFields.annotation.value,
          note: formFields.note.value,
          performers,
          partners,
          performerCount: formFields.performerCount.value,
          periodNote: formFields.periodNote.value,
          finances,
          documents,
          requestProvideAmount: formFields.requestProvideAmount.value,
          requestProvideKind: formFields.requestProvideKind.value,
          requestProvideNote: formFields.requestProvideNote.value,
          contractProvideAmount: formFields.contractProvideAmount.value,
          contractProvideKind: formFields.contractProvideKind.value,
          contractProvideNote: formFields.contractProvideNote.value,
          warrantyProvideAmount: formFields.warrantyProvideAmount.value,
          warrantyProvideKind: formFields.warrantyProvideKind.value,
          warrantyProvideNote: formFields.warrantyProvideNote.value,
          departments,
          grntis: requestInfo?.grntis || [],
          udks: requestInfo?.udks || [],
          scienceDomainInterrests: requestInfo?.scienceDomainInterrests || [],
          criticalTechnologies: requestInfo?.criticalTechnologies || [],
          pnrs: requestInfo?.pnrs || [],
          pnmitrs: requestInfo?.pnmitrs || [],
          pnis: requestInfo?.pnis || [],
          ntrStrategies: requestInfo?.ntrStrategies || [],
          technologyPlatforms: requestInfo?.technologyPlatforms || [],
          okveds,
          lksetss,
          scienceBrunches,
          domainKnowledges,
          tender: formFields.tender.value || null,
          lot: formFields.lot.value || null,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
            if (nextStatus) {
              changeStatusAPI.callAPI(
                { ids: [data.id || '-1'], message: statusChangeMessage, status: nextStatus || RequestStatus.DRAFT },
                {
                  onSuccessfullCall: () => {
                    showNotification({ message: 'Статус успешно изменен', theme: 'success' });
                    setIsStatusChangeModalOpen(false);
                    setNextStatus(null);
                    tableStreams.reloadTable.push();
                    onClose();
                  },
                  onFailedCall: () => setIsStatusChangeModalOpen(false),
                },
              );
            } else if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              updateWorkModeAfterSaveAndContinue();
              setId(data.id);
              getRequest.callAPI(
                { id: data.id },
                {
                  onSuccessfullCall: ({ data: getterData }) => {
                    getterSuccessfullCallback(getterData);
                  },
                },
              );
            }
          },
        },
      );
    },
    [
      formFields,
      finances,
      saveRequest,
      requestInfo,
      id,
      performers,
      partners,
      documents,
      departments,
      okveds,
      lksetss,
      scienceBrunches,
      domainKnowledges,
      nextStatus,
      changeStatusAPI,
      statusChangeMessage,
      tableStreams.reloadTable,
      onClose,
      updateWorkModeAfterSaveAndContinue,
      getRequest,
      getterSuccessfullCallback,
    ],
  );

  useLayoutEffect(() => {
    if (id) {
      getRequest.callAPI(
        { id: id },
        {
          onSuccessfullCall: ({ data }) => {
            if (copyMode) {
              setId(null);
              getterSuccessfullCallback({ ...data, id: null, performers: [], documents: [] });
            } else getterSuccessfullCallback(data);
          },
        },
      );
    } else if (tenderInfo) {
      getRequest.callAPI(
        {
          id: null,
          tenderId: tenderInfo?.tenderId ?? null,
          lotId: tenderInfo?.lotId ?? null,
        },
        {
          onSuccessfullCall: ({ data }) => getterSuccessfullCallback(data),
        },
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleDocumentsChange = useCallback(
    (value: Document[]) => {
      setDocuments(value);
    },
    [setDocuments],
  );

  const handleDepartmentsChange = useCallback(
    (value: Department[]) => {
      setDepartments(value);
    },
    [setDepartments],
  );

  const handlePartnersChange = useCallback(
    (value: RequestPartner[]) => {
      setPartners(value);
    },
    [setPartners],
  );

  const handleFinanceChange = useCallback(
    (value: Finance[]) => {
      value.forEach((finance: Finance) => {
        const tenderPrice = parseFloat(
          requestInfo?.lot?.prices.find((p: LotPrice) => p.year === finance?.year)?.amount?.toString() || '0',
        );
        if (tenderPrice != finance.fbAmount) {
          setIsLotPriceWarningOpen(true);
        }
      });
      setFinances(value);
      setRequestInfo(prev => ({ ...prev!, finances: value }));
    },
    [setFinances, requestInfo],
  );

  const handleLksetsChange = useCallback(
    (value: ReferenceItem | null) => {
      setLksetss(value ? [value] : []);
      setLksetsParentLabel((value?.customFields?.parent as ReferenceItem)?.label || '');
    },
    [setLksetss],
  );

  const handleDomainKnowledgesChange = useCallback(
    (value: ReferenceItem[]) => {
      setDomainKnowledges(value);
    },
    [setDomainKnowledges],
  );

  const handleEstimatePositionsChange = useCallback((e: EstimatePosition[]) => {
    setRequestInfo(prevState => ({ ...prevState, estimatePositions: e }));
  }, []);

  const makeChangeHandler = (key: keyof ProgramRequest) => (val: ValueOf<ProgramRequest>) => {
    setRequestInfo(prev => ({ ...prev!, [key]: val }));
  };

  const performersChangeHandler = useCallback(
    (val: Performer[]) => {
      setRequestInfo(prev => ({ ...prev!, performers: val }));
      setPerformers(val);
      val.forEach(perf => {
        if (perf?.role?.value === 'LEADER' && perf?.person?.id) {
          formFields.manager.onChange(perf);
        }
      });

      const isNoManager = !val.find(i => i?.role?.value === 'LEADER');

      if (isNoManager) {
        setPerformers(prev => prev.filter(p => p.role?.value !== 'LEADER'));
        formFields.manager.onChange(null);
      }
    },
    [formFields.manager],
  );

  const handleStatusChangeButtonClick = useCallback((toStatus: RequestStatus, name: string) => {
    setStatusChangeMessage('');
    setNextStatus(toStatus);
    setStatusChangeName(name);
    setIsStatusChangeModalOpen(true);
  }, []);

  const closeStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalOpen(false);
  }, []);

  const changeStatus = useCallback(() => {
    onSubmit(true);
  }, [onSubmit]);

  const handleStatusChangeMessageChange = useCallback((e: string) => {
    setStatusChangeMessage(e);
  }, []);

  const saveApprovement = useCallback(() => {
    if (!id) {
      showNotification({ message: ' Сохраните запись перед созданием резолюции', theme: 'danger' });
      return;
    }
    addApprovementAPI.callAPI(
      { message: approvementMessage, requestId: id, status: approvementStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
          setIsApprovementModalOpen(false);
        },
      },
    );
  }, [addApprovementAPI, approvementMessage, approvementStatus, id]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'add',
        onClick: () => onSubmit(true),
        isHidden: !!viewMode,
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        code: 'addAndSave',
        onClick: () => onSubmit(false),
        isHidden: !!viewMode,
      },
      {
        icons: buttonIcons.book,
        title: 'В черновик',
        isHidden: !!viewMode || [RequestStatus.DRAFT].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.DRAFT, 'В черновик'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_DRAFT,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_DRAFT,
      },
      {
        icons: buttonIcons.medal,
        title: 'На согласование',
        isHidden: !!viewMode || ![RequestStatus.DRAFT, RequestStatus.REVISION].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.REQUEST, 'На согласование'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REQUEST,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REQUEST,
      },
      {
        icons: buttonIcons.like,
        title: 'Рекомендовать на конкурс',
        isHidden: !!viewMode || ![RequestStatus.REQUEST].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.RECOMENDED, 'Рекомендовать на конкурс'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_RECOMENDED,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_RECOMENDED,
      },
      {
        icons: buttonIcons.activation,
        title: 'Подать на конкурс',
        isHidden: !!viewMode || ![RequestStatus.RECOMENDED].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.SENT, 'Подать на конкурс'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_SENT,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_SENT,
      },
      {
        icons: buttonIcons.star,
        title: 'Поддержать',
        isHidden: !!viewMode || ![RequestStatus.SENT].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.APPROVED, 'Поддержать'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_APPROVED,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_APPROVED,
      },
      {
        icons: buttonIcons.update,
        title: 'На доработку',
        isHidden: !!viewMode || ![RequestStatus.REQUEST, RequestStatus.REJECTED].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.REVISION, 'На доработку'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REVISION,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REVISION,
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отклонить',
        isHidden: !!viewMode || ![RequestStatus.REQUEST].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.REJECTED, 'Отклонить'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REJECTED,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REJECTED,
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отклонить в конкурсе',
        isHidden: !!viewMode || ![RequestStatus.SENT].includes(status),
        onClick: () => handleStatusChangeButtonClick(RequestStatus.TENDER_CANCELED, 'Отклонить в конкурсе'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_TENDER_CANCELED,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_TENDER_CANCELED,
      },
      {
        icons: buttonIcons.approve,
        title: 'Экспертная оценка',
        isHidden: !!viewMode || status === RequestStatus.DRAFT,
        onClick: () => setIsApprovementModalOpen(true),
        permissionName: Permits.PROGRAM_REQUEST_AGREE,
        profilePermissionName: Permits.PROGRAM_REQUEST_AGREE,
      },
    ],
    [viewMode, status, onSubmit, handleStatusChangeButtonClick],
  );

  return {
    requestId: id ?? null,
    requestInfo,
    formFields,
    documents,
    departments,
    performers,
    partners,
    handlePartnersChange,
    finances,
    okveds,
    lksetss,
    scienceBrunches,
    domainKnowledges,
    handleDomainKnowledgesChange,
    handleDocumentsChange,
    handleDepartmentsChange,
    handleFinanceChange,
    handleOkvedChange,
    handleLksetsChange,
    handleScienceBrunchChange,
    makeChangeHandler,
    performersChangeHandler,
    updateManager,
    provideKindOptions,
    workMode,
    isOpenPersonModal,
    setIsOpenPersonModal,
    personId,
    setPersonId,
    okvedParentLabel,
    lksetsParentLabel,
    scienceBrunchParentLabel,
    isStatusChangeModalOpen,
    closeStatusChangeModal,
    changeStatus,
    statusChangeMessage,
    handleStatusChangeMessageChange,
    statusChangeName,
    buttons,
    status,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    approvementStatus,
    setApprovementStatus,
    approvementMessage,
    setApprovementMessage,
    approvementOptions,
    saveApprovement,
    handleEstimatePositionsChange,
    setRequestInfo,
    isLotPriceWarningOpen,
    setIsLotPriceWarningOpen,
  };
}
