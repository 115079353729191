import React from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  financing: Project.PartnerFinancing;
  setFinancing(financing: Project.PartnerFinancing): void;
};

export function Fields(props: Props) {
  const { financing, setFinancing } = props;

  const makeChangeHandler = (key: keyof Project.PartnerFinancing) => (value: ValueOf<Project.PartnerFinancing>) => {
    setFinancing({ ...financing, [key]: value });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Год" isRequired>
          <TextInput mode={TextInputMode.year} value={financing.year} onChange={makeChangeHandler('year')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма" isRequired>
          <TextInput mode={TextInputMode.number} value={financing.amount} onChange={makeChangeHandler('amount')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
