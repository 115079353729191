import { useCallback } from 'react';

import { Performer } from 'types/models/ContestRequest';
import { useAppDataContext } from 'features/AppData/context';
import { Member } from 'features/SelectPerson';
import { ProjectScientistRole } from 'utils/Enums';
import { getEnum } from 'utils/Helpers';
import { Item } from 'types/models/common';

type Props = {
  entry: Performer | null;
  onFieldChange(row: Performer | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const { enumMap } = useAppDataContext();

  const roleOptions = getEnum('ProjectScientistRole', enumMap).filter(
    r => r.value !== ProjectScientistRole.LEADER,
  ) as Item<ProjectScientistRole>[];

  const handleRoleChange = useCallback(
    () => (option: any) => {
      onFieldChange({ ...entry!, role: roleOptions.find(x => x.value === option.value) || null });
    },
    [onFieldChange, entry, roleOptions],
  );

  const handlePersonChange = useCallback(
    () => (value: Member | null) => {
      const nextPerformer: Performer | null = value
        ? {
            id: entry?.id || null,
            role: entry?.role || null,
            historyLabel: entry?.historyLabel || '',
            ...value,
          }
        : null;
      onFieldChange(nextPerformer);
    },
    [onFieldChange, entry],
  );

  return {
    handlePersonChange,
    handleRoleChange,
    roleOptions,
  };
}
