import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { Template } from 'types/models/Form';

type Props = {
  tableState: Table.State;
};

export const templateArgs: { apiID: string; template?: Template } = {
  apiID: 'GetKindConsumption',
  template: 'KindConsumptionForm',
};

export function useController(props: Props) {
  const { tableState } = props;
  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: isEditButtonDisabled,
        permissionName: Permits.KIND_CONSUMPTION_EDIT,
        onClick: handleEditButtonClick,
      },
    ],
    [isEditButtonDisabled, handleEditButtonClick, setIsHelpModalOpen],
  );

  return {
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
}
