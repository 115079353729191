import { useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';

export function useController() {
  const {
    look: { type, id, relatedTableState },
  } = useFormContext();
  const { methods: getScientistData } = BackendAPI.useBackendAPI('GetScientistData');

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'SCIENTIST_DATA_FORM', []);

  const fetchPublication = useCallback(() => {
    if (type) {
      getScientistData.callAPI(
        {
          personId: String(id),
        },
        {
          onSuccessfullCall: ({ data }) => {
            if (data) {
              // ReactDOM.unstable_batchedUpdates(() => {
              //   setPublicationInfo(data);
              // });
            } else {
              showNotification({ message: 'Произошла ошибка при запросе персоны', theme: 'danger' });
            }
          },
        },
      );
    }
  }, [getScientistData, id, type]);

  useLayoutEffect(() => {
    fetchPublication();
    // eslint-disable-next-line
  }, []);

  return {
    SUCH_LIKE_PUBLICATION_ID,
    relatedTableState,
  };
}
