import React from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose(): void;
};

const MagazineIdentifierForm = ({ onClose }: Props) => {
  const { formFields, magazineName, saveForm, isHasEditFieldPermission, isHasEditDoiPermission } = useController({ onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveForm} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Журнал">
            <span>{magazineName}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.libraryId.title}>
            <TextInput
              value={formFields.libraryId.value}
              onChange={formFields.libraryId.onChange}
              isDisabled={!isHasEditFieldPermission}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.doi.title}>
            <TextInput
              mode={TextInputMode.doi}
              value={formFields.doi.value}
              onChange={formFields.doi.onChange}
              isDisabled={!isHasEditDoiPermission}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.libraryLink.title}>
            <TextInput
              value={formFields.libraryLink.value}
              onChange={formFields.libraryLink.onChange}
              isDisabled={!isHasEditFieldPermission}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
};

export const Component = React.memo(MagazineIdentifierForm);
