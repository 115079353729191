import React from 'react';
import * as R from 'ramda';

import { ConfirmPopup, ListEdit } from 'components';

import { showErrors } from 'utils/Validators';
import { Project } from 'types/models';
import useController from './controller';
import { getMockDepartment } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';
import { validate } from './validate';

type Props = {
  departments: Project.Department[];
  projectId?: string;
  setDepartments(departments: Project.Department[]): void;
  disabled: boolean;
};

export function Departments(props: Props) {
  const { departments, setDepartments, disabled, projectId } = props;
  const {
    isDeleteWarningOpen,
    onCloseDeleteWarningPopup,
    deleteWarningText,
    isLoading,
    onPreDelete,
    onPreEdit,
    codes,
  } = useController({ projectId });

  return (
    <>
      <ConfirmPopup
        isOpen={isDeleteWarningOpen}
        onClose={onCloseDeleteWarningPopup}
        onConfirm={onCloseDeleteWarningPopup}
        text={deleteWarningText}
        okButtonText="Да"
        icon="warning"
        title="Невозможно удалить элемент"
      />
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled || isLoading}
        rows={departments}
        onChange={setDepartments}
        defaultRowsCount={3}
        maxHeight="350px"
        columns={[
          { label: 'Факультет/Институт', formatValue: x => `(${x.governance?.customFields?.code}) ${x.governance?.label}` },
          { label: 'Подразделение', formatValue: x => `(${x.unit?.customFields?.code}) ${x.unit?.label}` },
        ]}
        specification={{
          mode: 'customComponent',
          onPreDelete,
          onPreEdit,
          renderComponent: (department, setDepartment) => (
            <Fields department={department || getMockDepartment()} setDepartment={setDepartment} codes={codes} />
          ),
          validation: {
            checkIsValid: department => validate(department).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      />
    </>
  );
}
