export type State = {
  programId?: string;
};

export function makeUseCustomController(deps: State) {
  return function useCustomController(): State {
    return {
      programId: deps.programId,
    };
  };
}
