import { makeStream } from 'StreamRx';
import { Event } from 'types/models';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';

export const eventsStreams = {
  getEvent: makeStream<string>(),
  removeEvent: makeStream<string>(),
  setEvents: makeStream<Array<Event.Event>>(),
};

export const projectsStreams = {
  setProjects: makeStream<Array<PublicationProject>>(),
  accept: makeStream<string>(),
  cancelAcceptance: makeStream<string>(),
};

export const mobileRequestsStream = {
  setMobileRequests: makeStream<Array<PublicationMobileRequest>>(),
  accept: makeStream<string>(),
  cancelAcceptance: makeStream<string>(),
};
