import React, { useMemo, useState } from 'react';
import * as R from 'ramda';

import { buttonIcons, ListEdit } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Person } from 'types/models';
import { EventType } from 'types/models/Payment';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { showNotification } from 'features/Notifications';
import { PortfolioCard } from 'features/Form/looks/person';
import { EditableNominee } from 'features/IncentivePayments/model';
import { formatEditableJob, formatInfoSource, isValidNominee } from 'features/IncentivePayments/helpers';
import { Fields } from './Fields/Fields';
import { validate } from './validate';
import { formatEducation } from 'features/Form/views/AboutAuthorship/helpers';

type Props = {
  nominees: EditableNominee[];
  disabled: boolean;
  setNominees(nominees: EditableNominee[]): void;
  eventType: EventType | null;
};

export function Nominees(props: Props) {
  const { setNominees, nominees, disabled, eventType } = props;

  const [isOpenPersonCard, setIsOpenPersonCard] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<Person.ScientistPerson | null>(null);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<EditableNominee>[]>(
    () => [
      {
        icons: buttonIcons.affilationAdd,
        title: 'Отметить аффилиацию',
        code: 'addAffilation',
        checkIsDisabled: row => row === null || disabled,
        onClick: (nominee, index, rows) => {
          if (nominee && !isValidNominee(nominee)) {
            showNotification({ message: 'Укажите Факультет / Институт и(или) сведения о работе у номинанта', theme: 'danger' });
            return;
          }
          if (index !== null) {
            setNominees(R.set(R.lensPath([index, 'isAffiliated']), true, rows));
          }
        },
      },
      {
        icons: buttonIcons.affilationDelete,
        title: 'Снять аффилиацию',
        code: 'cancelAffilation',
        checkIsDisabled: row => row === null || disabled,
        onClick: (_, index, rows) => {
          if (index !== null) {
            setNominees(R.over(R.lensIndex(index), nominee => ({ ...nominee, isAffiliated: false, isPaid: false }), rows));
          }
        },
      },
      {
        icons: buttonIcons.payment,
        title: 'Назначить к оплате',
        code: 'addPayment',
        checkIsDisabled: row => row === null || disabled,
        onClick: (editableNominee, index, rows) => {
          if (editableNominee && !isValidNominee(editableNominee)) {
            showNotification({ message: 'Укажите Факультет / Институт и(или) сведения о работе у номинанта', theme: 'danger' });
            return;
          }
          if (index !== null) {
            setNominees(
              R.over(
                R.lensIndex(index),
                nominee => ({ ...nominee, isAffiliated: true, isPaid: true, factMoney: nominee.planMoney }),
                rows,
              ),
            );
          }
        },
      },
      {
        icons: buttonIcons.paymentCancel,
        title: 'Снять назначение к оплате',
        code: 'cancelPayment',
        checkIsDisabled: row => row === null || disabled,
        onClick: (_, index, rows) => {
          if (index !== null) {
            setNominees(R.over(R.lensIndex(index), nominee => ({ ...nominee, isPaid: false, factMoney: '0' }), rows));
          }
        },
      },
    ],
    [setNominees, disabled],
  );

  return (
    <>
      <ListEdit
        title="Номинанты на оплату стимулирующих выплат"
        rows={nominees}
        onChange={setNominees}
        defaultRowsCount={7}
        columnIndexesForSumTotal={[3, 4]}
        isToolbarDisabled={disabled}
        extraToolbarButtons={extraToolbarButtons}
        maxHeight="40vh"
        visibleToolbarButtons={['edit']}
        extraFieldsToolbarButtons={[
          {
            icons: buttonIcons.person,
            title: 'Редактировать персону',
            code: 'editPerson',
            checkIsDisabled: row => row === null,
            onClick: nominee => {
              if (nominee) {
                setSelectedPerson(nominee.person);
                setIsOpenPersonCard(true);
              }
            },
          },
        ]}
        columns={[
          {
            label: 'Аффилирован',
            formatValue: x => x.isAffiliated,
            styles: { width: '5%' },
          },
          {
            label: 'К оплате',
            formatValue: x => x.isPaid,
            styles: { width: '6%' },
          },
          { label: 'ФИО номинанта', formatValue: x => x.person.fullName, styles: { width: '10%' } },
          { label: 'План, руб.', formatValue: x => formatNumber(x.planMoney), styles: { width: '5%' } },
          { label: 'Факт, руб.', formatValue: x => formatNumber(x.factMoney), styles: { width: '5%' } },
          { label: 'Сведения из источника', formatValue: formatInfoSource, styles: { width: '20%' } },
          {
            label: 'Сведения о работе на дату заявки',
            formatValue: x => formatEditableJob(x.job),
            styles: { width: '25%' },
          },
          {
            label: 'Сведения об учебе на дату заявки',
            formatValue: x => (x.education ? formatEducation(x.education) : ''),
            styles: { width: '15%' },
          },
          {
            label: 'Относится к Факультету / Институту',
            formatValue: x => x.topLevelDepartment?.name ?? '',
            styles: { width: '15%' },
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (nominee, setNominee) => <Fields eventType={eventType} nominee={nominee} setNominee={setNominee} />,
          validation: {
            checkIsValid: nominee => validate(nominee, eventType).every(x => x.isValid),
            onInvalidate: nominee => {
              const errorMessages = validate(nominee, eventType)
                .filter(x => !x.isValid)
                .map(x => x.invalidMessage);

              if (errorMessages.length) {
                showErrorsMessages(errorMessages);
              }
            },
          },
        }}
      />
      <PortfolioCard
        isOpen={isOpenPersonCard}
        id={selectedPerson?.id ?? null}
        onClose={() => {
          setIsOpenPersonCard(false);
          setSelectedPerson(null);
        }}
      />
    </>
  );
}
