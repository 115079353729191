import { Form } from 'types/models';

const isOutOfMaxSymbolsRange = (formField: Form.Field) => {
  let isValid = true;

  if (formField.maxSymbols) {
    const isString = typeof formField.value === 'string';

    isValid = isString ? formField.value?.length < formField.maxSymbols : false;
  }

  return isValid;
};

export { isOutOfMaxSymbolsRange };
