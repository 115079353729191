import * as R from 'ramda';

import { Customer, PatentResearch, Performer, Rntd } from 'types/models';
import { Settings } from 'types/models/common';
import { Member, SecurityDocument } from 'types/models/PatentResearch';

import { convertMaybeArray, convertReference, getId, getText, convertDocument, convertItem } from '../commonConverters';
import { convertPersonHistory } from '../converters';
import { convertProject, convertStage } from '../project/converters';
import { convertServerPerson } from '../author/converters';

export function convertPatentResearch(patentResearch: any, settings: Settings): PatentResearch {
  const customers = patentResearch.Customers?.Customer ?? [];
  const performers = patentResearch.Performers?.Performer ?? [];
  const documents = patentResearch.Documents?.Document || [];

  return {
    id: getId(patentResearch),
    number: getText(patentResearch.Number),
    name: getText(patentResearch.Name),
    target: getText(patentResearch.Target),
    loDate: getText(patentResearch.LoDate),
    hiDate: getText(patentResearch.HiDate),
    rntd: patentResearch.Rntd ? convertRntd(patentResearch.Rntd) : null,
    project: patentResearch.Project ? convertProject(patentResearch.Project, settings) : null,
    stage: patentResearch.Stage ? convertStage(patentResearch.Stage) : null,
    customers: convertMaybeArray(customers, c => convertCustomer(c, settings)),
    performers: convertMaybeArray(performers, p => convertPerformer(p, settings)),
    documents: convertMaybeArray(documents, R.partial(convertDocument, ['local'])),
  };
}

export function convertRntd(rntd: any): Rntd {
  const securityDocuments = rntd.SecurityDocuments?.SecurityDocument || [];
  return {
    id: getId(rntd),
    code: getText(rntd.Code),
    name: getText(rntd.Name),
    type: convertReference(rntd.Type),
    commercializationProtocol: getText(rntd.CommercializationProtocol),
    commercializationDate: getText(rntd.CommercializationDate),
    description: getText(rntd.Description),
    securityDocuments: convertMaybeArray(securityDocuments, convertSecurityDocument),
  };
}

function convertCustomer(customer: any, settings: Settings): Customer {
  return {
    id: getId(customer),
    person: convertServerPerson(customer.Person, settings),
    ...convertPersonHistory(customer),
  };
}

function convertPerformer(performer: any, settings: Settings): Performer {
  return {
    id: getId(performer),
    person: convertServerPerson(performer.Person, settings),
    ...convertPersonHistory(performer),
  };
}

function convertSecurityDocument(securityDocument: any): SecurityDocument {
  const members = securityDocument.Members?.Member || [];
  return {
    id: getId(securityDocument),
    type: convertItem(securityDocument.Type),
    intellectualPropertyName: getText(securityDocument.IntellectualPropertyName),
    requestNumber: getText(securityDocument.RequestNumber),
    requestDate: getText(securityDocument.RequestDate),
    documentNumber: getText(securityDocument.DocumentNumber),
    documentStartDate: getText(securityDocument.DocumentStartDate),
    documentEndDate: getText(securityDocument.DocumentEndDate),
    members: convertMaybeArray(members, convertMember),
  };
}

export function convertMember(member: any): Member {
  return {
    id: getId(member),
    type: convertItem(member.Type),
    name: getText(member.Name),
  };
}
