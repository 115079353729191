import React from 'react';

import { Radio } from 'components';

export type Selectivity = 'all' | 'selectively';

type Props = {
  value: Selectivity;
  onChange(val: Selectivity): void;
};

export function SelectMode(props: Props) {
  const { value, onChange } = props;

  return (
    <Radio
      value={value}
      list={[
        { label: 'Все', value: 'all' },
        { label: 'Выборочно', value: 'selectively' },
      ]}
      onChange={onChange}
    />
  );
}
