export type State = {
  eventId?: string;
  compilationTypeCode: string;
};

type Arguments = {
  eventId?: string;
  compilationTypeCode: string;
};

export function makeUseCustomController({ eventId, compilationTypeCode }: Arguments) {
  return function useCustomController(): State {
    return {
      eventId,
      compilationTypeCode,
    };
  };
}
