import * as XML from 'xml-js';
import type { Arm, FileInfo, Recognition } from 'types/models';
import { Settings } from 'types/models/common';
import { makeBackendAPIConfiguration } from '../makeBackendAPIConfiguration';
import {
  convertServerScientistAcademicRank,
  convertServerScientistDegree,
  convertServerScientistEducation,
  convertServerScientistJob,
  convertServerPerson,
  convertServerScientistRank,
} from './author/converters';
import { convertFile, convertFileToXML, convertReference, getText } from './commonConverters';

export const backendRecognitionAPIConfigurations = {
  GetRecognition: makeBackendAPIConfiguration({
    id: 'GetRecognition',
    endpoint: '/msa/service/commands/GetRecognition',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetRecognition',
          },
          Recognition: {
            _attr: id ? { id } : {},
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings): Recognition => {
      const recognition = response?.Response?.Recognition;
      const isAllowEdit = getText(recognition.AllowEdit) === 'true';
      const tmp: Recognition = {
        isAllowEdit,
        academicRank: recognition.AcademicRank ? convertServerScientistAcademicRank(recognition.AcademicRank) : null,
        award: { id: recognition.Award?._attributes?.id || '', label: recognition.Award?._attributes?.label || '' },
        awardCategory: {
          id: recognition.AwardCategory?._attributes?.id || '',
          label: recognition.AwardCategory?._attributes?.label || '',
        },
        citizenship: recognition.Citizenship ? convertReference(recognition.Citizenship) : null,
        dateGet: recognition.DateGet?._text || '',
        degree: recognition.Degree ? convertServerScientistDegree(recognition.Degree) : null,
        department: {
          code: recognition.Department?.code?._text || '',
          name: recognition.Department?.name?._text || '',
          parent: {
            id: recognition.Department?.parent?._attributes?.id || '',
            label: recognition.Department?.parent?._attributes?.label || '',
          },
          restricted: recognition.Department?.restricted?._text || false,
          id: recognition.Department?._attributes?.id || '',
          label: recognition.Department?._attributes?.label || '',
        },
        description: recognition.Description?._text || '',
        education: recognition.Education ? convertServerScientistEducation(recognition.Education) : null,
        faculty: {
          abbr: recognition.Faculty?.abbr?._text || '',
          code: recognition.Faculty?.code?._text || '',
          name: recognition.Faculty?.name?._text || '',
          parent: {
            id: recognition.Faculty?.parent?._attributes?.id || '',
            label: recognition.Faculty?.parent?._attributes?.label || '',
          },
          restricted: recognition.Faculty?.restricted?._text || false,
          id: recognition.Faculty?._attributes?.id || '',
          label: recognition.Faculty?._attributes?.label || '',
        },
        file: convertFile(recognition.File),
        historyLabel: recognition.HistoryLabel?._text || '',
        job: recognition.Job ? convertServerScientistJob(recognition.Job) : null,
        note: recognition.Note?._text || '',
        person: convertServerPerson(recognition.Person, settings),
        rank: recognition.Rank ? convertServerScientistRank(recognition.Rank) : null,
        status: {
          label: recognition.Status?._attributes?.label || '',
          ordinal: recognition.Status?._attributes?.ordinal || 0,
          value: recognition.Status?._text || '',
        },
        id: recognition._attributes?.id || '',
      };
      return tmp;
    },
  }),

  SaveRecognition: makeBackendAPIConfiguration({
    id: 'SaveRecognition',
    endpoint: '/msa/service/commands/SaveRecognition',
    convertInputToXMLElement: (inputData: {
      id: string | undefined;
      personId: string | undefined | null;
      jobId: string | undefined | null;
      degreeId: string | undefined | null;
      rankId: string | undefined | null;
      citizenshipId: string | undefined | null;
      dateGet: string;
      awardId: string;
      description: string;
      note: string;
      file: FileInfo;
      arm?: Arm;
    }) => {
      const { id, personId, jobId, degreeId, rankId, citizenshipId, dateGet, awardId, description, note, file, arm } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveRecognition',
            ...(arm ? { workbench: arm } : {}),
          },
          Recognition: {
            ...(id ? { _attr: { id } } : {}),
            ...(personId ? { Person: { _attr: { id: personId } } } : {}),
            ...(jobId ? { Job: { _attr: { id: jobId } } } : {}),
            ...(degreeId ? { Degree: { _attr: { id: degreeId } } } : {}),
            ...(rankId ? { Rank: { _attr: { id: rankId } } } : {}),
            ...(citizenshipId ? { Citizenship: { _attr: { id: citizenshipId } } } : {}),
            ...(dateGet ? { DateGet: dateGet } : {}),
            ...(awardId ? { Award: { _attr: { id: awardId } } } : {}),
            ...convertFileToXML('File', file),
            ...(description ? { Description: description } : {}),
            ...(note ? { Note: note } : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),

  ApproveRecognition: makeBackendAPIConfiguration({
    id: 'ApproveRecognition',
    endpoint: '/msa/service/commands/ApproveRecognition',
    convertInputToXMLElement: (inputData: { id: string; isApproved?: boolean }) => {
      const { id, isApproved } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'ApproveRecognition',
          },
          Recognition: {
            _attr: { id, disapprove: !!isApproved },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveRecognitionDepartment: makeBackendAPIConfiguration({
    id: 'SaveRecognitionDepartment',
    endpoint: '/msa/service/commands/SaveRecognitionDepartment',
    convertInputToXMLElement: (inputData: { id: string; departmentId: string }) => {
      const { id, departmentId } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveRecognitionDepartment',
          },
          Recognition: {
            _attr: { id },
            Department: departmentId,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
