import React, { useEffect } from 'react';

import { FormComponent, Tabs, Tab } from 'components';

import { Documents } from 'features/Documents';
import { AboutRntd } from './Tabs/AboutRntd/AboutRntd';
import { Commercialization } from './Tabs/Commercialization/Commercialization';
import { ExhibitionParticipation } from './Tabs/ExhibitionParticipation/ExhibitionParticipation';
import { Classifiers } from './Tabs/Classifiers/Classifiers';
import { useController } from './controller';

import { Toolbar } from './Toolbar/Toolbar';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function RntdForm({ viewMode, editMode, onClose }: Props) {
  const { rntd, formFields, makeChangeHandler, workMode, documents, setDocuments, handleFormSubmit, applicants } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (workMode === 'addMode') {
            return 'Добавление результата научно-технической деятельности';
          }
          return [workMode === 'viewMode' ? 'Просмотр' : 'Редактирование', 'РИД', rntd?.id ? `(ID ${rntd?.id})` : '']
            .filter(x => x)
            .join(' ');
        })(),
      );
    }
  }, [rntd?.id, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar rntd={rntd} onSave={needClose => handleFormSubmit(needClose)} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О РИД">
            <AboutRntd rntd={rntd} formFields={formFields} makeChangeHandler={makeChangeHandler} applicants={applicants} />
          </Tab>
          <Tab title="Классификаторы">
            <Classifiers rid={rntd} makeChangeHandler={makeChangeHandler} disabled />
          </Tab>
          <Tab title="Коммерциализация">
            <Commercialization rid={rntd} formFields={formFields} disabled={workMode === 'viewMode'} />
          </Tab>
          <Tab title="Участие в выставках">
            <ExhibitionParticipation rid={rntd} makeChangeHandler={makeChangeHandler} disabled={workMode === 'viewMode'} />
          </Tab>
          <Tab title="Документы">
            <Documents
              documents={documents}
              setDocuments={setDocuments}
              disabled={workMode === 'viewMode'}
              isShowSource
              sourceLabelForLocalDocs="Текущий РИД"
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(RntdForm);
