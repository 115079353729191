import React, { memo } from 'react';

import {
  FormComponent,
  SectionTitle,
  InputSelect,
  ReferenceItem,
  TextDateTime,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  UploadFile,
} from 'components';

import { Arm, Form as F, Table } from 'types/models';
import { LibraryField } from 'features/Form/views';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { getTitle } from 'features/Form/looks/publication/helpers';

type Props = {
  formFields: F.Fields;
  workMode: Table.WorkMode;
  electronicType: string;
  isElectronic: boolean;
  isShowDetailedTitle: boolean;
  publicationInfo: F.Publication | null;
  userDepartments: ReferenceItem[];
  publicationId?: string;
  publicationDepartment: ReferenceItem | null;
  setPublicationDepartment: React.Dispatch<ReferenceItem | null>;
  arm?: Arm;
};

const Bibliography = ({
  isShowDetailedTitle,
  publicationInfo,
  workMode,
  formFields,
  electronicType,
  isElectronic,
  userDepartments,
  publicationId,
  publicationDepartment,
  setPublicationDepartment,
  arm,
}: Props) => {
  return (
    <form>
      <input type="submit" hidden />
      <SectionTitle title={getTitle(publicationInfo, isShowDetailedTitle)} />
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label={formFields.name.title} isRequired>
          <TextArea
            settings={{ rows: 3, maxLength: 2049 }}
            value={formFields.name.value}
            onChange={formFields.name.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {isElectronic && electronicType === ElectronicType.LOCAL.code && (
        <FormComponent.Line hasGap lineSize="padded">
          <FormComponent.Field
            label={formFields.electronicMediaSource.title}
            tooltip={formFields.electronicMediaSource.tooltipText}
          >
            <TextInput
              value={formFields.electronicMediaSource.value}
              onChange={formFields.electronicMediaSource.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
          <FormComponent.Field
            label={formFields.electronicSystemRequirements.title}
            tooltip={formFields.electronicSystemRequirements.tooltipText}
          >
            <TextInput
              value={formFields.electronicSystemRequirements.value}
              onChange={formFields.electronicSystemRequirements.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label={formFields.annotation.title} tooltip={formFields.annotation.tooltipText} isRequired>
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3 }}
            value={formFields.annotation.value}
            onChange={formFields.annotation.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label={formFields.authorsKeyWords.title} tooltip={formFields.authorsKeyWords.tooltipText} isRequired>
          <TextInput
            value={formFields.authorsKeyWords.value}
            onChange={formFields.authorsKeyWords.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <SectionTitle title="Скан-копия выходных данных и идентификаторы публикации" />

      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label={formFields.doi.title} tooltip={formFields.doi.tooltipText}>
          <TextInput
            mode={TextInputMode.doi}
            value={formFields.doi.value}
            onChange={formFields.doi.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <LibraryField libraryUrl={publicationInfo?.status?.libraryUrl || ''} />
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Прикрепите файл"
          // eslint-disable-next-line max-len
          tooltip="Файл с выходными данными обязательно должен содержать титул, оборот титула, содержание и последний лист с информацией о типе издания, тираже, издательстве"
          isRequired
        >
          <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label={formFields.publicationLink.title}
          isRequired={formFields.publicationLink.required}
          tooltip={formFields.publicationLink.tooltipText}
        >
          <TextInput
            mode={TextInputMode.url}
            value={formFields.publicationLink.value}
            onChange={formFields.publicationLink.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.date.title} isRequired={!!formFields.date.required}>
          <TextDateTime value={formFields.date.value} onChange={formFields.date.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      {!publicationId && arm === 'pc' && (
        <>
          <SectionTitle title="Ответственное подразделение" />
          <FormComponent.Field
            label="Подразделение"
            tooltip="Публикация будет передана ответственному по подразделению для согласования и утверждения"
          >
            <InputSelect
              options={userDepartments.map(x => ({ value: x.id, label: x.label }))}
              value={{ value: publicationDepartment?.id, label: publicationDepartment?.label || '' }}
              onSelectChange={e => setPublicationDepartment(userDepartments.find(x => x.id === e?.value) || null)}
            />
          </FormComponent.Field>
        </>
      )}
    </form>
  );
};

export const BibliographyComponent = memo(Bibliography);
