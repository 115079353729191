import React, { memo } from 'react';
import { ListEditTable, Column, Toolbar, IconButtonProps } from 'components';
import { Report } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  reports: Report[];
  selectedRowIndex: number | null;
  setSelectedRowIndex: React.Dispatch<number | null>;
  toolbarButtons: IconButtonProps[];
  columns: Column<Report>[];
  isBuildReportPopupOpen: boolean;
  closeBuildReportPopup: () => void;
  onDoubleRowClick: React.Dispatch<number>;
};

const SetupReport = ({
  reports,
  selectedRowIndex,
  setSelectedRowIndex,
  toolbarButtons,
  columns,
  isBuildReportPopupOpen,
  closeBuildReportPopup,
  onDoubleRowClick,
}: Props) => (
  <>
    <BuildReportPopup
      isOpen={isBuildReportPopupOpen}
      onClose={closeBuildReportPopup}
      reportName={selectedRowIndex === null ? '' : reports[selectedRowIndex].name}
      reportCaption={selectedRowIndex === null ? '' : reports[selectedRowIndex].caption}
    />

    <Toolbar buttons={toolbarButtons} mode="list" />

    <ListEditTable
      defaultRowsCount={15}
      rows={reports}
      columns={columns}
      selectedRowIndex={selectedRowIndex}
      selectRow={setSelectedRowIndex}
      onDoubleRowClick={onDoubleRowClick}
    />
  </>
);

export const Component = memo(SetupReport);
