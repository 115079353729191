import { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { format } from 'date-fns';
import { useAppDataContext } from 'features/AppData/context';
import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { ScientistJob } from 'types/models/Person';

type Props = {
  person: Person.ScientistPerson | null;
  onChangeJobs(jobs: Person.ScientistJob[]): void;
};

const useController = ({ person, onChangeJobs }: Props) => {
  const { currentPerson, userPermission } = useAppDataContext();
  const { methods: GetIsLinkedHistoryAPI, state: GetIsLinkedHistoryState } = BackendAPI.useBackendAPI('IsLinkedHistory');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [jobIds, setJobIds] = useState<string[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<ScientistJob[]>([]);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [isDeleteHistoryWarningOpen, setIsDeleteHistoryWarningOpen] = useState<boolean>(false);
  const [isEntityRelationOpen, setIsEntityRelationOpen] = useState<boolean>(false);
  const [isMergeDuplicatesOpen, setIsMergeDuplicatesOpen] = useState<boolean>(false);

  const handleSelectedRows = useCallback((selectedRows: ScientistJob[]) => {
    setSelectedJobs(selectedRows);
  }, []);

  const handleCloseMergeDuplicatesForm = useCallback(() => {
    setIsMergeDuplicatesOpen(false);
  }, []);

  const isMergeDisabled = useCallback(() => {
    return selectedJobs.length !== 2;
  }, [selectedJobs]);

  const isLinkedHistoryPending = useMemo(() => getIsPending(GetIsLinkedHistoryState), [GetIsLinkedHistoryState]);

  const onDeleteButtonClick = useCallback((nextDeletedIndex: number) => {
    setSelectedIndex(nextDeletedIndex);
    setIsDeleteConfirmOpen(true);
  }, []);

  const onDeleteReset = useCallback(() => {
    setSelectedIndex(null);
    setIsDeleteConfirmOpen(false);
  }, []);

  const selectedJob = useMemo(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      return person.scientistJobs[selectedIndex!];
    }

    return null;
  }, [selectedIndex, person]);

  const removeJob = useCallback(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;
    if (isSelectedIndex && person) {
      const isReadonlyJob = Boolean(selectedJob?.isReadOnly);
      const scientistJobsCopy = R.clone(person?.scientistJobs || []);
      if (isReadonlyJob) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.SCIENTIST_JOB_EDUCATION_DEGREE_RANK_DELETE);
        if (!isUserHasPermission) {
          showNotification({ message: 'Нет прав на удаление работ пришедших по интеграции', theme: 'danger' });
          return;
        }
        const deletionDate = `${format(new Date(), formatDateTimeStr)}`;
        const deletedBy = currentPerson?.fullName || '';
        const nextScientistJob: Person.ScientistJob = { ...selectedJob!, isActive: false, deletionDate, deletedBy };
        const nextScientistJobs = R.pipe<Person.ScientistJob[], Person.ScientistJob[], Person.ScientistJob[]>(
          R.remove(selectedIndex, 1),
          R.insert(selectedIndex, nextScientistJob),
        )(scientistJobsCopy);
        onChangeJobs(nextScientistJobs);

        showNotification({ message: 'Работа помечена удаленной', theme: 'success' });
      } else {
        const nextScientistJobs = R.remove<Person.ScientistJob>(selectedIndex, 1)(scientistJobsCopy);
        onChangeJobs(nextScientistJobs);
        showNotification({ message: 'Работа удалена', theme: 'success' });
        setSelectedIndex(null);
      }
    }
  }, [currentPerson?.fullName, selectedIndex, selectedJob, onChangeJobs, person, userPermission]);

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmOpen(false);
    if (selectedIndex === null) {
      return;
    }

    if (selectedJob?.id) {
      GetIsLinkedHistoryAPI.callAPI(
        { type: LinkedHistoryType.JOB, id: selectedJob.id },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.isSuccess) {
              setIsDeleteHistoryWarningOpen(true);
            } else {
              removeJob();
            }
          },
        },
      );
    } else {
      removeJob();
    }
  }, [GetIsLinkedHistoryAPI, selectedIndex, selectedJob, removeJob]);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.ScientistJob>[]>(
    () => [
      {
        title: 'Удалить',
        icons: buttonIcons.delete,
        isDisabled: false,
        checkIsDisabled: (job: Person.ScientistJob | null) => {
          if (job === null || isLinkedHistoryPending || (selectedJobs && selectedJobs?.length > 1)) {
            return true;
          }

          const isJobReadonly = job?.isReadOnly;
          const isJobActive = job?.isActive;
          if (isJobReadonly) {
            if (isJobActive) {
              return false;
            }

            return true;
          }

          return false;
        },
        onClick: (_, jobIndex: number | null) => {
          if (jobIndex !== null) {
            onDeleteButtonClick(jobIndex);
          }
        },
      },
      {
        title: 'Связь',
        icons: buttonIcons.editRelations,
        checkIsDisabled: (job: Person.ScientistJob | null) => !job?.id || (selectedJobs && selectedJobs?.length > 1),
        onClick: (_, jobIndex: number | null) => {
          if (jobIndex !== null) {
            setSelectedIndex(jobIndex);
            setIsEntityRelationOpen(true);
          }
        },
      },
      {
        icons: buttonIcons.merge,
        title: 'Объединение дубликатов',
        checkIsDisabled: isMergeDisabled,
        isHidden: !isHasPermission(userPermission, Permits.SCIENTIST_MERGE),
        onClick: () => {
          const ids: string[] = selectedJobs
            .map((job: ScientistJob) => {
              return job?.id || '';
            })
            .filter(x => !!x);
          setJobIds(ids);
          setIsMergeDuplicatesOpen(true);
        },
      },
    ],
    [isLinkedHistoryPending, onDeleteButtonClick, isMergeDisabled, selectedJobs, userPermission],
  );

  const closeDeleteHistoryWarning = useCallback(() => {
    setIsDeleteHistoryWarningOpen(false);
  }, []);

  const confirmDeleteHistoryWarning = useCallback(() => {
    closeDeleteHistoryWarning();
    setIsEntityRelationOpen(true);
  }, [closeDeleteHistoryWarning]);

  const closeEntityRelation = useCallback(() => {
    setIsEntityRelationOpen(false);
  }, []);

  return {
    extraToolbarButtons,
    isDeleteConfirmOpen,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedJob,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    jobIds,
  };
};

export default useController;
