import React, { useCallback } from 'react';

import { InputSelect, Option, FormComponent, TextArea, TextInput } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { getEnum } from 'utils/Helpers';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function PaymentTermPresetForm({ viewMode, onClose }: Props) {
  const { formFields, onSubmit, enumMap } = useController();

  const options = getEnum('PaymentRequestType', enumMap);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const handleTypeFieldChange = useCallback(
    (e: Option) => {
      formFields.type.onChange(e);
    },
    [formFields.type],
  );

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.type.title} isRequired>
            <InputSelect
              options={options}
              value={formFields.type.value ?? null}
              onSelectChange={handleTypeFieldChange}
              isSearchable
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.regulationParagraph.title} isRequired>
            <TextInput
              value={formFields.regulationParagraph.value}
              onChange={formFields.regulationParagraph.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.regulationName.title} isRequired>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.regulationName.value}
              onChange={formFields.regulationName.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(PaymentTermPresetForm);
