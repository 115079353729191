import React, { useMemo, memo } from 'react';
import { block } from 'bem-cn';

import { IconButtonProps, Toolbar, buttonIcons } from 'components';
import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import { SubmitTable } from 'features/Table/streams';

type WrapperProps = {
  eventId?: string;
};

type Props = {
  tableState: Table.State;
  onSubmitTable?: (state: SubmitTable) => void;
};

const b = block('toolbar');

const LeftPanelForThirdLevel = ({ eventId }: WrapperProps) =>
  memo(({ tableState, onSubmitTable }: Props) => {
    const {
      SUCH_LIKE_PUBLICATION_ID,
      handleAddButtonClick,
      handleEditButtonClick,
      handleSelectClick,
      handleViewButtonClick,
      isEditButtonDisabled,
      isSelectButtonDisabled,
      isViewButtonDisabled,
      isAddFormOpen,
      handleTemplateCloseAddForm,
      nextPublicationName,
      isViewFormOpen,
      handleTemplateCloseViewForm,
      isEditFormOpen,
      handleCloseEditForm,
      handleContinueStep,
    } = useController({ tableState, onSubmitTable });

    const buttons: IconButtonProps[] = useMemo(
      () => [
        {
          icons: buttonIcons.check,
          title: 'Выбрать',
          isDisabled: isSelectButtonDisabled,
          onClick: handleSelectClick,
        },
        {
          icons: buttonIcons.loop,
          title: 'Просмотр',
          code: 'view',
          isDisabled: isViewButtonDisabled,
          onClick: handleViewButtonClick,
          permissionName: 'PublicationView',
        },
        {
          icons: buttonIcons.plus,
          title: 'Добавить',
          code: 'add',
          isDisabled: false,
          onClick: handleAddButtonClick,
          permissionName: 'PublicationAdd',
        },
        {
          icons: buttonIcons.edit,
          title: 'Редактировать',
          code: 'edit',
          isDisabled: isEditButtonDisabled,
          onClick: handleEditButtonClick,
          permissionName: 'PublicationEdit',
        },
      ],
      [
        handleAddButtonClick,
        handleEditButtonClick,
        handleSelectClick,
        handleViewButtonClick,
        isEditButtonDisabled,
        isSelectButtonDisabled,
        isViewButtonDisabled,
      ],
    );
    return (
      <>
        {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
          <div className={b('hidden')}>
            <SuchLikePublication
              mode="add"
              isSearchable
              handleContinueStep={handleContinueStep}
              componentId={SUCH_LIKE_PUBLICATION_ID}
            />
          </div>
        )}
        <modalTemplates.PublicationAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          name={nextPublicationName}
          relatedTableState={tableState}
          eventId={eventId}
        />
        {!isViewButtonDisabled && (
          <modalTemplates.PublicationViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
          />
        )}
        {!isEditButtonDisabled && (
          <modalTemplates.PublicationEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            eventId={eventId}
          />
        )}
        <Toolbar buttons={buttons} />
      </>
    );
  });

export const Component = LeftPanelForThirdLevel;
