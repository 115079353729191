import React from 'react';
import { Table } from 'types/models';
import { ConfirmPopup, Toolbar as SharedToolbar } from 'components';
import useController from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

export const Toolbar = ({ tableState }: Props) => {
  const {
    buttons,
    isViewFormOpen,
    row,
    isApproved,
    isApprovePopupOpen,
    onViewClose,
    onApprovePopupSubmit,
    onApprovePopupClose,
  } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <modalTemplates.OtherProjectViewTemplate.Component isOpen={isViewFormOpen} onClose={onViewClose} id={row?.id} />
      <ConfirmPopup
        isOpen={isApprovePopupOpen}
        onConfirm={onApprovePopupSubmit}
        onClose={onApprovePopupClose}
        title="Предупреждение"
        icon="info"
        text={`Перевести в статус ${isApproved ? '"Добавлена"' : '"Утверждена"'}</br>Тема: ${row?.Name}</br>ID: ${row?.id}`}
        okButtonText="Продолжить"
        resetButtonText="Отмена"
      />
    </>
  );
};
