import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetReportByDepartmentMappingPublicationList(): Table.Specification {
  return {
    apiID: 'GetReportByDepartmentMappingPublicationList',
    header: {
      firstLevel: {
        title: 'Список публикаций Scopus, WoS по факультетам / институтам',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    isWithQna: true,
  };
}
