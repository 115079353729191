import React from 'react';

import { FiltersComponent, FilterComponentType, FilterComponent } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './model';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { referenceMetadata, isFacultyFilter } = customState;

  if (!referenceMetadata?.restrictable) {
    return null;
  }

  const filters: FilterComponent[] = isFacultyFilter
    ? [
        {
          type: FilterComponentType.checkbox,
          streamKey: 'level',
          label: 'Факультет / Институт',
          checked: false,
        },
      ]
    : [];

  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'restricted',
      list: [
        { label: 'Все', value: 'All' },
        { label: 'Актуальные', value: 'Actual' },
      ],
      value: 'Actual',
    },
    ...filters,
  ]);
});
