import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Participation, Rid } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  rid?: Rid | null;
  makeChangeHandler: (key: keyof Rid) => (val: ValueOf<Rid>) => void;
};

export function useController(props: Props) {
  const { rid, makeChangeHandler } = props;

  const [isRntdExhibPartOpenForm, setIsRntdExhibPartOpenForm] = useState<boolean>(false);
  const [participationId, setParticipationId] = useState<string | undefined>(undefined);
  const [participationFormMode, setParticipationFormMode] = useState<string>('view');

  const formatOrganizer = useCallback((participation: Participation): string => {
    if (participation && participation.event) {
      if (participation?.event?.departments && participation?.event?.departments?.length > 0) {
        if (participation?.event?.enterprises && participation?.event?.enterprises?.length > 0) {
          return `${participation?.event?.departments
            ?.map(d => d.name)
            .join(', ')} совместно с ${participation?.event?.enterprises?.map(e => e.name).join(', ')}`;
        }
        return participation?.event?.departments.map(d => d.name).join(', ');
      }
      if (participation?.event?.enterprises && participation?.event?.enterprises.length > 0) {
        return participation?.event?.enterprises?.map(e => e.name).join(', ');
      }
    }
    return '';
  }, []);

  const formatSupervisor = useCallback(
    (participation: Participation): string =>
      participation.members
        ?.filter(m => m.role?.code === 'SUPERVISOR')
        .map(m => (m.person ? m.person.fullName : null))
        .join(', '),
    [],
  );

  const formatEventName = useCallback((participation: Participation): string => participation.event?.fullName || '', []);

  const handleParticipationChange = useCallback(
    (value: Participation[]) => {
      makeChangeHandler('participations')(value);
    },
    [makeChangeHandler],
  );

  const { methods: getParticipationAPI } = BackendAPI.useBackendAPI('GetParticipation');

  const handleParticipationSave = useCallback(
    (result: any) => {
      const participation = rid?.participations?.find(p => p.id === result?.data?.id);
      if (!participation) {
        getParticipationAPI.callAPI(
          {
            participationId: result?.data?.id,
            modules: [
              'ID',
              'ACCESS_MODE',
              'MAIN',
              'EVENT',
              'DEPARTMENTS',
              'PUBLICATION',
              'PROJECTS',
              'MEMBERS',
              'AWARDS',
              'MOBILE_REQUESTS',
              'DOCUMENTS',
            ],
            eventModules: ['MAIN'],
            IsCollectiveExhibit: false,
          },
          {
            onSuccessfullCall: ({ data }) => {
              makeChangeHandler('participations')([...(rid?.participations || []), data]);
            },
          },
        );
      }
    },
    [makeChangeHandler, rid, getParticipationAPI],
  );

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Participation>[]>(
    () => [
      {
        icons: buttonIcons.viewSource,
        title: 'Просмотр участия',
        onClick: entry => {
          setParticipationFormMode('view');
          setParticipationId(entry?.id);
          setIsRntdExhibPartOpenForm(true);
        },
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить новый',
        onClick: () => {
          setParticipationFormMode('add');
          setParticipationId(undefined);
          setIsRntdExhibPartOpenForm(true);
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать источник',
        onClick: entry => {
          setParticipationFormMode('edit');
          setParticipationId(entry?.id);
          setIsRntdExhibPartOpenForm(true);
        },
      },
    ],
    [setIsRntdExhibPartOpenForm, setParticipationFormMode, setParticipationId],
  );

  return {
    formatOrganizer,
    formatSupervisor,
    handleParticipationChange,
    handleParticipationSave,
    formatEventName,
    extraToolbarButtons,
    isRntdExhibPartOpenForm,
    setIsRntdExhibPartOpenForm,
    participationId,
    participationFormMode,
  };
}
