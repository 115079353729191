import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'requestTypeFilter',
      list: [
        {
          label: 'Заявки на НИОКР',
          value: 'true',
        },
        {
          label: 'Проекты',
          value: 'false',
        },
      ],
      value: 'false',
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});
