import { useCallback } from 'react';

import { Performer } from 'types/models';
import { Member } from 'features/SelectPerson';

type Props = {
  entry: Performer | null;
  onFieldChange(row: Performer | null): void;
};

export function useController({ onFieldChange }: Props) {
  const handlePersonChange = useCallback(
    (value: Performer | null) => {
      onFieldChange(value);
    },
    [onFieldChange],
  );

  function convertMemberToPerformer(e: Member | null): Performer | null {
    if (!e?.person) return null;
    return {
      ...e,
      id: null,
      person: e.person,
      historyLabel: '',
    };
  }

  return {
    handlePersonChange,
    convertMemberToPerformer,
  };
}
