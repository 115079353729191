import React from 'react';

import { DataGrid } from 'features/Table';
import { GetProjectCodeChangesInProject } from 'features/Table/specifications';

type Props = {
  projectId?: string | null;
  projectCodeId?: string | null;
  isDisabled?: boolean;
};

export function ChangesList({ projectId, projectCodeId, isDisabled }: Props) {
  return <DataGrid specification={GetProjectCodeChangesInProject({ projectId, projectCodeId, isDisabled })} />;
}
