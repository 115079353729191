import { FILTER_STRUCRURE } from 'features/Table/helpers';

export const formatNumbericToFilter = ({ from, to, filterStructure }: { from: string; to: string; filterStructure: string }) => {
  const isRange = filterStructure === FILTER_STRUCRURE.range;

  if (isRange) {
    if (!from && !to) {
      return '';
    }

    return `${from || 'EMPTY'}#${to || 'EMPTY'}`;
  }
  return from;
};
