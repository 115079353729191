import React from 'react';

import { Toolbar as Icons, ConfirmPopup } from 'components';

import { Table } from 'types/models';

import { State } from '../model';

import { HistoryModal } from './HistoryModal/HistoryModal';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function Toolbar({ onSubmitTable, tableState, customState }: Props) {
  const {
    buttons,
    isOpenConfirmDeleteModal,
    closeModal,
    confirmDelete,
    historyEntries,
    isOpenHistoryModal,
    closeHistoryModal,
  } = useController({ onSubmitTable, tableState, customState });

  return (
    <div>
      <Icons buttons={buttons} />
      <ConfirmPopup
        title="Удаление элемента справочника"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isOpenConfirmDeleteModal}
        onClose={closeModal}
        onConfirm={confirmDelete}
        okButtonText="Да"
        resetButtonText="Отмена"
      />
      <HistoryModal historyEntries={historyEntries} isOpen={isOpenHistoryModal} onClose={closeHistoryModal} />
    </div>
  );
}

export { Toolbar };
