import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Settings } from 'types/models/common';
import { convertProtocolCommissionToRequest } from './requestConverters';
import { convertProtocolCommission } from './converters';
import { ProtocolCommission } from 'types/models/ProtocolCommission';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';

const configurations = {
  SaveProtocolCommission: makeBackendAPIConfiguration({
    id: 'SaveProtocolCommission',
    endpoint: '/msa/service/commands/SaveProtocolCommission',
    convertInputToXMLElement: ({ commission }) => ({
      Request: {
        _attr: { commandName: 'SaveProtocolCommission' },
        ProtocolCommission: { ...convertProtocolCommissionToRequest(commission) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertSaveResponseXMLElementToOutput(response),
  }),
  GetProtocolCommission: makeBackendAPIConfiguration({
    id: 'GetProtocolCommission',
    endpoint: '/msa/service/commands/GetProtocolCommission',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetProtocolCommission',
          },
          ProtocolCommission: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings): ProtocolCommission =>
      convertProtocolCommission(response.Response.ProtocolCommission, settings),
  }),
};

export const protocolCommissionConfigurations = { ...configurations };
