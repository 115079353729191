import { useCallback } from 'react';
import { Member } from 'features/SelectPerson';
import { OtherProject } from 'types/models';
import { ValueOf } from 'types/helpers';
import { Item } from 'types/models/common';

type Props = {
  otherProject: OtherProject.OtherProject;
  makeChangeHandler: (key: keyof OtherProject.OtherProject) => (value: ValueOf<OtherProject.OtherProject>) => void;
  makeResetErrorHandler: (key: keyof OtherProject.OtherProject) => () => void;
};

const useController = ({ makeChangeHandler, makeResetErrorHandler, otherProject }: Props) => {
  const onProgramSelect = useCallback(
    (nextProgram: Item<string> | null) => {
      const changeProgram = makeChangeHandler('program');
      const changeProgramEvent = makeChangeHandler('programEvent');

      if (nextProgram?.value !== otherProject.program?.value) {
        changeProgramEvent(null);
      }

      changeProgram(nextProgram);
    },
    [makeChangeHandler, otherProject.program?.value],
  );

  const onUpdatePerformer = useCallback(
    (member: Member | null) => {
      const perfmerChangeHandler = makeChangeHandler('performer');
      if (member === null) {
        perfmerChangeHandler(member);
        return;
      }

      perfmerChangeHandler({
        id: member.person?.id ?? null,
        person: member.person,

        academicRank: member.academicRank,
        citizenship: member.citizenship,
        degree: member.degree,
        education: member.education,
        historyLabel: '',
        job: member.job,
        rank: member.rank,
      });
    },
    [makeChangeHandler],
  );

  const onUpdateRangeDate = useCallback(
    (start: string, end: string) => {
      makeResetErrorHandler('startDate')();
      makeChangeHandler('startDate')(start);
      makeResetErrorHandler('endDate')();
      makeChangeHandler('endDate')(end);
    },
    [makeChangeHandler, makeResetErrorHandler],
  );

  return {
    onUpdateRangeDate,
    onUpdatePerformer,
    onProgramSelect,
  };
};

export default useController;
