import { ReferenceItem } from 'components';
import { makeStream, GetStateStreamData } from 'StreamRx';
import { SuchLikePublication } from 'types/models';

import { SuchLikePublicationData } from './types';

export const streams = {
  getSuchLikeEvents: makeStream<GetSuchLikePublications>(),
  openSuchLikeEvents: makeStream(),
  suchLikePublicationState: makeStream<SuchLikePublicationState>(),
  submitSuchLikePublications: makeStream<SubmitSuchLikePublications>(),
};

export type GetSuchLikePublications = SuchLikePublicationData<{
  isSearchable?: boolean;
  isSearching?: boolean;
  isDefaultSave?: boolean;
  currentId: string | null;
  searchValue: SuchLikePublication.State['searchValue'];
  successfullCallback: ({
    publicationMessage,
    publicationMessageType,
  }: {
    publicationMessage: string;
    publicationMessageType: ReferenceItem;
  }) => void;
}>;

export type SubmitSuchLikePublications = SuchLikePublicationData<object>;

export type SuchLikePublicationState = SuchLikePublicationData<GetStateStreamData<SuchLikePublication.State>>;

export type AttachSuchLikePublication = SuchLikePublicationData<{ id: string }>;
