import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Report } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const reports = useMemo<Report[]>(() => [Reports.WosScopusPublicationByDepartmentsMapping], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [getReports, handleHelpButtonClick, handleSetCurrentReport],
  );

  return {
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
