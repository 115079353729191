import React from 'react';
import { ConfirmPopup, FormComponent, TextDateTime, TextGridSelect, TextInput, TextInputMode, Toolbar } from 'components';
import { Modal } from 'components';
import useController from './controller';
import { Table } from 'types/models';
import { GetFundCardList } from '../../GetFundCardList';
import { GetKindConsumptionList } from '../../GetKindConsumptionList';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
    isCreateModalOpen,
    closeCreateModal,
    createInfo,
    setCreateInfo,
    confirmCreateModal,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />
      <Modal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Распределение проектов по шифрам Централизованного фонда"
        size="medium"
      >
        <FormComponent.Wrapper>
          <p>Таблица содержит список шифров проектов и проектов без шифров</p>
          <p>Назначение таблицы:</p>
          <ol>
            <li>Оценка планового объёма накладных расходов по оперативным данным проектов</li>
            <li>Распределение шифров проектов по шифрам централизованного фонда накладных расходов</li>
          </ol>
          <p>
            <strong>ВАЖНО!</strong> В смете проекта на конкретный год допустимо указать другой, отличный от того, который указан в
            шифре проекта шифр централизованного фонда. Т.е. допустимо вести учёт накладных расходов на разных шифрах, указывая в
            смете шифра. По умолчанию при создании сметы шифр централизованного фонда подставляется тот, который назначен шифру
          </p>
          <h3>
            <strong>Особенности отображения информации в столбцах таблицы:</strong>
          </h3>
          <p>
            <strong>% НР, нормативный</strong> — нормативный процент накладных расходов, <strong>указанный в проекте</strong> на
            вкладке <strong>Особенности</strong>
          </p>
          <p>
            <strong>Смета</strong> — «Да», если есть смета за год , указанный в фильтре ГОД. Если в смете не указана статья затрат
            «Накладные расходы», то в сумма накладных расходов по шифру проекта ( а значит, и по проекту) считается равной нулю
          </p>
          <p>
            <strong>Шифр ЦФ</strong> — шифр централизованного фонда, если указан для шифра проекта
          </p>
          <p>
            <strong>Подразделение</strong> — название подразделения в шифре проекта
          </p>
          <p>
            <strong>Источник финансирования шифра</strong> — название источника финансирования шифра проекта
          </p>
          <p>
            <strong>Сумма, план проекта</strong> — плановая сумма из Календарного плана проекта. Для проектов без шифра — это
            общая сумма по всем источникам финансирования
          </p>
          <p>
            <strong>НР, план</strong> — сумма накладных расходов, вычисленная по формуле: Сумма, план проекта*
            <strong>% НР, нормативный</strong>
          </p>
        </FormComponent.Wrapper>
      </Modal>
      <ConfirmPopup
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        title="Создать смету для выделенной группы проектов"
        okButtonText="Продолжить"
        onConfirm={confirmCreateModal}
        size="medium"
      >
        <FormComponent.Line>
          <FormComponent.Field label="Год сметы" isRequired>
            <TextInput
              value={createInfo.year}
              onChange={e => setCreateInfo(prev => ({ ...prev, year: e }))}
              mode={TextInputMode.year}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата создания" isRequired>
            <TextDateTime value={createInfo.createdDate} onChange={e => setCreateInfo(prev => ({ ...prev, createdDate: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Шифр ЦФ" isRequired>
            <TextGridSelect
              specification={GetFundCardList()}
              title="Шифры ЦФ"
              value={createInfo.fundCard}
              valueFormatter={x => x.value ?? ''}
              onChange={e => setCreateInfo(prev => ({ ...prev, fundCard: e }))}
              settings={{ mode: 'selectTableRow', tableRowConverter: x => ({ id: x.id, value: x.Name }) }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид расхода" isRequired>
            <TextGridSelect
              specification={GetKindConsumptionList({})}
              title="Виды расходов"
              value={createInfo.kindConsumption}
              valueFormatter={x => x.value ?? ''}
              onChange={e => setCreateInfo(prev => ({ ...prev, kindConsumption: e }))}
              settings={{ mode: 'selectTableRow', tableRowConverter: x => ({ id: x.id, value: x.kindConsumption }) }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </ConfirmPopup>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
