import React, { useMemo, useState, useCallback } from 'react';

import { getMockPerson } from 'features/Form/looks/person/helpers';
import { Table } from 'types/models';
import { ConfirmPopup, IconButtonProps, Toolbar as SharedToolbar, buttonIcons } from 'components';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Component as Form } from '../Form';

import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;
  const { initialCountryCode } = useAppDataContext();
  const { openForm, loadScientist, setPerson, setMode, mode, closeForm, isOpenForm } = customState;
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const confirmDelete = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Scientist',
    });
    setIsDeleteModalOpen(false);
  }, [tableState.selectedRows, tableStreams.deleteRow]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: () => {
          setMode('add');
          setPerson(getMockPerson({ initialCountryCode }));
          openForm();
        },
        permissionName: Permits.SCIENTIST_ADD,
        profilePermissionName: Permits.SCIENTIST_ADD,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        permissionName: Permits.SCIENTIST_VIEW,
        profilePermissionName: Permits.SCIENTIST_VIEW,
        onClick: () => {
          if (selectedRow) loadScientist(selectedRow.id);
          setMode('view');
          openForm();
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        permissionName: Permits.SCIENTIST_EDIT,
        profilePermissionName: Permits.SCIENTIST_EDIT,
        isDisabled: !selectedRow,
        onClick: () => {
          if (selectedRow) loadScientist(selectedRow.id);
          setMode('edit');
          openForm();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: Permits.SCIENTIST_DELETE,
        profilePermissionName: Permits.SCIENTIST_DELETE,
        isDisabled: !selectedRow || selectedRow?.integrated === 'true',
        onClick: () => setIsDeleteModalOpen(true),
      },
    ],
    [selectedRow, setMode, openForm, loadScientist, setPerson, initialCountryCode],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Form isOpen={isOpenForm} onClose={closeForm} id={selectedRow?.id} mode={mode} />
      {isDeleteModalOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную персону? (${selectedRow?.FullFIO})`}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          okButtonText="Да"
        />
      )}
    </>
  );
}

export { Toolbar };
