import { useCallback, useMemo, useState, useEffect } from 'react';
import { BuilderProps } from 'features/BuildReportPopup/models';
import { Parameter } from 'types/models';

type Props = {} & BuilderProps;

const useController = ({ parameterName, setParameterValueByName, parameters }: Props) => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setParameterValueByName({ name: parameterName, value: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );

  const onInputChange = useCallback(
    (nextValue: string) => {
      setInputValue(nextValue);
      setParameterValueByName({ name: parameterName, value: nextValue });
    },
    [parameterName, setParameterValueByName],
  );

  return {
    inputValue,
    currentParameter,
    onInputChange,
  };
};

export default useController;
