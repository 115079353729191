import { ReferenceItem } from 'components';
import { OtherProject } from 'types/models';
import { Item } from 'types/models/common';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { getMaybeField } from '../commonConverters';
import { convertAffiliationToXML } from '../community/requestConverters';
import { convertDocumentsToRequest } from '../converters';

export const convertOtherProjectToXML = (otherProject: OtherProject.OtherProject) => ({
  _attr: {
    id: otherProject.id,
  },
  Name: otherProject.name,
  Number: otherProject.number,
  StartDate: otherProject.startDate,
  EndDate: otherProject.endDate,
  Amount: otherProject.amount ?? '0.00',
  ...getMaybeField('StateRegistrationNumber', otherProject.stateRegistrationNumber),
  ...getMaybeField('StateRegistrationDate', otherProject.stateRegistrationDate),
  ...convertProgramToRequest(otherProject.program),
  ...convertProgramEventToRequest(otherProject.programEvent),
  Leader: otherProject.leader,
  Role: otherProject.role?.value,
  ...getMaybeField('Enterprise', otherProject.enterprise && convertAffiliationToXML(otherProject.enterprise)),
  ...getMaybeField('Customer', otherProject.customer && convertAffiliationToXML(otherProject.customer)),
  ...getMaybeField('Note', otherProject.note),
  ...convertPerformerToRequest(otherProject.performer),
  ...(otherProject.documents.length ? convertDocumentsToRequest(otherProject.documents) : {}),
  ...convertFinancingsToRequest(otherProject.financings),
  ...convertGrntisToRequest(otherProject.grntis),
});

const convertProgramToRequest = (program: Item<string> | null) => {
  if (program) {
    return {
      Program: {
        _attr: { id: program.value },
      },
    };
  }

  return {};
};

const convertProgramEventToRequest = (programEvent: Item<string> | null) => {
  if (programEvent) {
    return {
      ProgramEvent: {
        _attr: { id: programEvent.value },
      },
    };
  }

  return {};
};

const convertPerformerToRequest = (performer: OtherProject.Performer | null) => {
  if (performer) {
    return {
      Performer: {
        _attr: {
          id: performer.person?.id,
        },
      },
      ...getHistoricalSliceXML(performer),
    };
  }

  return {};
};

const convertGrntisToRequest = (grntis: ReferenceItem[]) => {
  if (!grntis.length) {
    return {
      Grntis: {},
    };
  }

  return {
    Grntis: {
      Grnti: grntis.map(({ id }) => ({ _attr: { id } })),
    },
  };
};

const convertFinancingsToRequest = (financings: OtherProject.Financing[]) => {
  if (!financings.length) {
    return {
      Financings: {},
    };
  }

  return {
    Financings: {
      Financing: financings.map(({ amount, year, id }) => ({
        ...(id ? { _attr: { id } } : {}),
        Year: year,
        Amount: amount,
      })),
    },
  };
};
