import React, { memo } from 'react';

import { Button, ButtonMode, ConfirmPopup, FormComponent, ReferenceItem } from 'components';

import { Builder } from './builders';
import useController from './controller';
import { ProjectCode } from 'types/models/Project';

type Props = {
  reportCaption: string;
  reportName: string;
  onClose: () => void;
  scientist?: string;
  scientistId?: string;
  nirRequestId?: string;
  requestId?: string;
  projectId?: string;
  stageId?: string;
  securityDocumentId?: string;
  defaultRequestStatus?: string;
  requestType?: string;
  requestIds?: string;
  authorRequestStatus?: string;
  requestStatus?: string;
  paymentRequestPeriod?: string;
  userDepartment?: string;
  event?: ReferenceItem;
  department?: string;
  regulationParagraph?: string;
  isEventParameterHidden?: boolean;
  requestTypeLabel?: string;
  mobileRequestId?: string;
  projectActId?: string;
  contestRequestId?: string;
  exhibitionEventId?: string;
  contestEventId?: string;
  conferenceEventId?: string;
  mobileProtocolId?: string;
  tenderProtocolId?: string;
  projectCode?: ProjectCode | null;
  purchaseRequestId?: string;
  projectPerformerOrderId?: string;
};

const BuildReportPopupComponent = ({
  reportName,
  onClose,
  nirRequestId,
  requestId,
  scientist,
  scientistId,
  projectId,
  stageId,
  securityDocumentId,
  defaultRequestStatus,
  requestType,
  requestIds,
  paymentRequestPeriod,
  authorRequestStatus,
  requestStatus,
  userDepartment,
  department,
  regulationParagraph,
  event,
  isEventParameterHidden,
  requestTypeLabel,
  mobileRequestId,
  projectActId,
  contestRequestId,
  exhibitionEventId,
  contestEventId,
  conferenceEventId,
  mobileProtocolId,
  tenderProtocolId,
  projectCode,
  purchaseRequestId,
  projectPerformerOrderId,
}: Props) => {
  const {
    isHasManual,
    showManual,
    parameters,
    builderList,
    errorText,
    isErrorPopupOpen,
    handleConfirmError,
    setParameterValueByName,
    startPrint,
    isReportParametersLoading,
  } = useController({
    reportName,
    scientist,
    scientistId,
    nirRequestId,
    requestId,
    onClose,
    projectId,
    stageId,
    securityDocumentId,
    defaultRequestStatus,
    requestType,
    requestIds,
    department,
    regulationParagraph,
    authorRequestStatus,
    requestStatus,
    paymentRequestPeriod,
    userDepartment,
    event,
    isEventParameterHidden,
    requestTypeLabel,
    mobileRequestId,
    projectActId,
    contestRequestId,
    exhibitionEventId,
    contestEventId,
    conferenceEventId,
    mobileProtocolId,
    tenderProtocolId,
    projectCode,
    purchaseRequestId,
    projectPerformerOrderId,
  });

  return (
    <>
      <FormComponent.Wrapper>
        <Builder parameters={parameters} setParameterValueByName={setParameterValueByName} builders={builderList} />
        <FormComponent.Actions>
          {isHasManual && <Button key="manual" mode={ButtonMode.secondary} text="Описание отчета" onClick={showManual} />}
          <Button
            key="print"
            mode={ButtonMode.primary}
            text="Построить отчет"
            onClick={startPrint}
            isDisabled={isReportParametersLoading}
          />
        </FormComponent.Actions>
      </FormComponent.Wrapper>

      <ConfirmPopup
        title="Ошибка"
        icon="warning"
        onClose={handleConfirmError}
        isOpen={isErrorPopupOpen}
        text={errorText}
        onConfirm={handleConfirmError}
        okButtonText="Принять"
      />
    </>
  );
};

export const Component = memo(BuildReportPopupComponent);
