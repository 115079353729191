import React, { useCallback, useState } from 'react';

import { SectionTitle, FormComponent, ConfirmPopup } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { RefList } from 'features/Form/looks/project/ProjectForm/views';
import { TextFields } from './TextFields/TextFields';
import { ScienceSection } from './ScienceSection/ScienceSection';

type Props = {
  disabled: boolean;
  project: Project.Project;
  setProject(project: Project.Project): void;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};

const UDK_WARNING_TITLE = 'Изменения в УДК';

const UDK_WARNING_TEXT =
  // eslint-disable-next-line max-len
  'В списке УДК проекта произошли изменения, в отчете(ах) этапов были указаны элементы УДК, которые притерпели изменения. В данных отчетах элементы УДК были очищены. При необходимости укажите в отчетах новые элементы.';

export function DescriptionProject(props: Props) {
  const { project, makeChangeHandler, setProject, disabled } = props;
  const [isUdkWraningPopupOpen, setIsUdkWraningPopupOpen] = useState<boolean>(false);

  const onUdkWarningClose = useCallback(() => {
    setIsUdkWraningPopupOpen(false);
  }, []);

  const onUdkChange = useCallback(
    (nextUdks: Project.RefElement[]) => {
      let isStagesChanged: boolean = false;
      const nextStages = (project?.stages ?? []).map<Project.Stage>(stage => {
        const nextReports = stage.reports.map<Project.StageReport>(stageReport => {
          const isNextUdkContainsReportUdk = nextUdks.some(({ ref }) => stageReport?.udk?.id === ref?.id);
          if (Boolean(stageReport.udk) && !isNextUdkContainsReportUdk) {
            setIsUdkWraningPopupOpen(true);
            isStagesChanged = true;
            const nextStageReport = { ...stageReport, udk: null };

            return nextStageReport;
          }

          return stageReport;
        });
        return { ...stage, reports: nextReports };
      });
      if (isStagesChanged) {
        makeChangeHandler('stages')(nextStages);
      }
      makeChangeHandler('udks')(nextUdks);
    },
    [makeChangeHandler, project?.stages],
  );
  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <TextFields project={project} makeChangeHandler={makeChangeHandler} setProject={setProject} disabled={disabled} />
          <FormComponent.Line>
            <RefList
              modalTitle='Справочник "Критические технологии"'
              refName="RefPriorityTechnology"
              rows={project?.criticalTechnologies ?? []}
              onChange={makeChangeHandler('criticalTechnologies')}
              title="Критические технологии"
              disabled={disabled}
              isCanMovingRows
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              modalTitle='Справочник "Стратегии научно-технического развития РФ"'
              refName="RefNtrStrategy"
              rows={project?.ntrStrategies ?? []}
              onChange={makeChangeHandler('ntrStrategies')}
              title="Стратегии научно-технического развития РФ"
              disabled={disabled}
              isCanMovingRows
            />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <RefList
              modalTitle='Справочник "ГРНТИ"'
              refName="RefGrnti"
              rows={project?.grntis ?? []}
              onChange={makeChangeHandler('grntis')}
              title="ГРНТИ"
              disabled={disabled}
              isCanMovingRows
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              modalTitle='Справочник "УДК"'
              refName="RefUdk"
              rows={project?.udks ?? []}
              onChange={onUdkChange}
              title="УДК"
              disabled={disabled}
              isCanMovingRows
            />
          </FormComponent.Line>

          <SectionTitle title="2-Наука" />
          <ScienceSection disabled={disabled} makeChangeHandler={makeChangeHandler} project={project} />

          <FormComponent.Line>
            <RefList
              modalTitle='Справочник "Коды международной классификации отраслей наук"'
              refName="RefOecd"
              rows={project?.oecds ?? []}
              onChange={makeChangeHandler('oecds')}
              title="Коды международной классификации отраслей наук (OECD)"
              disabled={disabled}
              isCanMovingRows
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
              refName="RefPni"
              rows={project?.pnis ?? []}
              onChange={makeChangeHandler('pnis')}
              title="Приоритетные направления развития науки, технологий и техники"
              disabled={disabled}
              isCanMovingRows
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <ConfirmPopup
        isOpen={isUdkWraningPopupOpen}
        onClose={onUdkWarningClose}
        onConfirm={onUdkWarningClose}
        title={UDK_WARNING_TITLE}
        text={UDK_WARNING_TEXT}
        icon="warning"
        okButtonText="Ок"
      />
    </>
  );
}
