import React, { ReactElement, useMemo } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-line');

type LineSize = '' | 'padded' | 'doublePadded' | 'triplePadded' | 'oneThird' | 'half';
type IndentSize = '' | 'padded' | 'doublePadded' | 'middlePadded';

type Props = {
  children: ReactElement | false | (ReactElement | false)[];
  lineSize?: LineSize;
  leftIndent?: IndentSize;
  hasFreeFormat?: boolean;
  hasGap?: boolean;
  width?: string;
  growIndexes?: number[];
  hasTopAlign?: boolean;
  hasWrap?: boolean;
  default?: boolean;
};

export function Line(props: Props) {
  const {
    children,
    hasGap,
    lineSize,
    hasFreeFormat,
    width,
    growIndexes,
    hasTopAlign,
    leftIndent,
    hasWrap,
    default: empty,
  } = props;
  const fields = useMemo(() => (Array.isArray(children) ? children.filter(x => x) : [children]), [children]);
  return (
    <div
      className={b({
        gap: hasGap,
        wrap: hasWrap,
        right30: lineSize === 'padded',
        right60: lineSize === 'doublePadded',
        right90: lineSize === 'triplePadded',
        oneThird: lineSize === 'oneThird',
        half: lineSize === 'half',
        left30: leftIndent === 'padded',
        left40: leftIndent === 'middlePadded',
        left60: leftIndent === 'doublePadded',
        'top-aligned': hasTopAlign,
        empty,
      })}
      style={{ width }}
    >
      {fields.map((item, index) => (
        <div
          key={index}
          className={b('item', {
            free: hasFreeFormat,
          })}
          style={{ flexGrow: growIndexes ? growIndexes[index] : 0 }}
        >
          {item}
        </div>
      ))}
    </div>
  );
}
