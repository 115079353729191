import { useState, useLayoutEffect, useCallback } from 'react';

import { showNotification } from 'features/Notifications';
import { Form, Table as T } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { validate } from './validate';
import {
  KindConsumption,
  KindConsumptionOriginal,
  MobileKindConsumption,
  MobileKindConsumptionType,
} from 'types/models/KindConsumption';
import * as R from 'ramda';
import { Item } from 'types/models/common';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const [mobileKindConsumption, setMobileKindConsumption] = useState<MobileKindConsumption>();
  const [kindConsumptions, setKindConsumptions] = useState<KindConsumption[]>([]);
  const [mobileKindConsumptionTypes, setMobileKindConsumptionTypes] = useState<Item<MobileKindConsumptionType>[]>([]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Наименование',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    code: {
      value: '',
      isValid: true,
      required: true,
      title: 'Код',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: { ...prevState.code, value },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      required: false,
      title: 'Описание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
    },
    kindConsumption: {
      value: '',
      isValid: true,
      required: false,
      title: 'Вид расхода бухгалтерии',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          kindConsumption: { ...prevState.kindConsumption, value },
        }));
      },
    },
  });

  const modalTableRowKindConsumptionConverter = useCallback<(row: T.Entry) => KindConsumptionOriginal>(row => {
    return { id: row.id, name: row.name, code: row.code };
  }, []);

  const { methods: getMobileKindConsumption } = BackendAPI.useBackendAPI('GetMobileKindConsumption', {
    onSuccessfullCall: ({ data }) => {
      setMobileKindConsumption(data);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('name'), data.name),
          R.set(getValueLens('code'), data.code),
          R.set(getValueLens('description'), data.description),
        )(prevFields),
      );
      setKindConsumptions(data.kindConsumptions);
    },
  });

  const { methods: saveMobileKindConsumption } = BackendAPI.useBackendAPI('SaveMobileKindConsumption', {
    onSuccessfullCall: () => {
      tableStreams.reloadTable.push();
    },
  });

  const onSubmit = useCallback((): boolean => {
    const errors: { isValid: boolean; invalidMessage: string }[] = validate({
      formFields,
      mobileKindConsumption,
      kindConsumptions,
    });
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        showNotification({ message: error.invalidMessage, theme: 'danger' });
      });
      return false;
    }

    saveMobileKindConsumption.callAPI({
      id: id || mobileKindConsumption?.id || null,
      name: formFields.name.value,
      code: formFields.code.value,
      description: formFields.description.value,
      types: mobileKindConsumptionTypes,
      kindConsumptions,
    });
    return true;
  }, [saveMobileKindConsumption, id, formFields, kindConsumptions, mobileKindConsumption, mobileKindConsumptionTypes]);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const handleKindConsumptionsChange = useCallback((val: KindConsumption[]) => {
    setKindConsumptions(val);
  }, []);

  useLayoutEffect(() => {
    if (id) getMobileKindConsumption.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    id,
    viewMode,
    editMode,
    handleFormSubmit,
    formFields,
    mobileKindConsumption,
    kindConsumptions,
    handleKindConsumptionsChange,
    modalTableRowKindConsumptionConverter,
    setMobileKindConsumptionTypes,
  };
}
