import { Event } from 'types/models';
import { FormComponent, ReferenceItem, RefList } from 'components';
import React from 'react';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  eventData?: Event.Data | null;
  setEventDataField: (field: keyof Event.Data) => (value: ReferenceItem[]) => void;
  viewMode?: boolean;
  hint?: string;
};

export function Classifiers(props: Props) {
  const { hint } = props;
  const { settings } = useAppDataContext();
  const { eventData, setEventDataField, viewMode } = props;
  const { shortName: name = '' } = settings?.organization || {};
  return (
    <>
      <FormComponent.Description mode="warning">{hint}</FormComponent.Description>

      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <RefList
              title="Коды ГРНТИ"
              rows={eventData?.grntis || []}
              onChange={setEventDataField('grntis')}
              modalTitle="Коды ГРНТИ"
              refName="RefGrnti"
              disabled={!!viewMode}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="Направление минобрнауки"
              rows={eventData?.ministryEducations || []}
              onChange={setEventDataField('ministryEducations')}
              modalTitle='Справочник "Направления минобрнауки"'
              refName="RefMinistryEducation"
              disabled={!!viewMode}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="Критические технологии"
              rows={eventData?.criticalTechnologies || []}
              onChange={setEventDataField('criticalTechnologies')}
              modalTitle='Справочник "Критические технологии"'
              refName="RefPriorityTechnology"
              disabled={!!viewMode}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
        </FormComponent.Column>

        <FormComponent.Column>
          <FormComponent.Line>
            <RefList
              title={`ПНР ${name}`}
              rows={eventData?.pnrs || []}
              onChange={setEventDataField('pnrs')}
              modalTitle={`Справочник "ПНР ${name}"`}
              refName="RefPnr"
              disabled={!!viewMode}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="ПНР науки технологий и техники РФ"
              rows={eventData?.pnis || []}
              onChange={setEventDataField('pnis')}
              modalTitle='Справочник "Критические технологии"'
              refName="RefPni"
              disabled={!!viewMode}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="ПНР модернизации и технического развития экономики России"
              rows={eventData?.pnmitrs || []}
              onChange={setEventDataField('pnmitrs')}
              modalTitle='Справочник "ПНР модернизации РФ"'
              refName="RefPnmitr"
              disabled={!!viewMode}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}
