import React from 'react';

import { Toolbar, FormComponent, Modal } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({ tableState });

  return (
    <>
      {!isEditButtonDisabled && (
        <modalTemplates.KindConsumptionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Toolbar buttons={buttons} />
      <Modal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title='Помощь в работе с разделом "Виды расходов из бухгалтерии"'
        size="medium"
      >
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Виды расходов из бухгалтерии загружены из информационной системы бухгалтерского учёта университета. Используются в
              сметах шифров проектов и заявок
            </p>
            <p>Для автоматизации процессов формирования сметы рекомендуется добавить следующее:</p>
            <p>Актуализировать список, проставив атрибут «Действующий»</p>
            <p>
              <ul>
                <li>
                  Для вида расходов, подразумевающих выплату налога, (Заработная плата…) добавить атрибут «Рассчитывать налог»,
                  указать вида расхода с названием налога и процента для расчёта суммы налога.
                </li>
                <li>Для вида расхода, связанных с накладными, добавить атрибут «Передавать накладные»</li>
                <li>
                  Для вида расхода, связанных с расчётами с соисполнителями, добавить атрибут «Расчёты с соисполнителем» — по
                  этому признаку система будет отображать название организации-контрагента на форме проекта, на вкладка
                  «Организации-соисполнители» из документов бухгалтерского учёта и учитываться при формировании отчётных форм
                  (например, 2-наука)
                </li>
              </ul>
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
