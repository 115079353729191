import { ProjectCode } from 'types/models/Project';
import { SectionTitle } from 'components';
import React from 'react';
import { DataGrid } from 'features/Table';
import { GetEstimateList } from 'features/Table/specifications/GetEstimateList';

type Props = {
  projectCode: ProjectCode | null;
  isDisabled?: boolean;
};

export function Estimates({ projectCode, isDisabled }: Props) {
  return (
    <>
      <SectionTitle title="Затраты шифра проекта" />
      <DataGrid specification={GetEstimateList({ projectCode, hideTitle: true, isDisabled })} />
    </>
  );
}
