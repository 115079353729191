import { FormComponent } from 'components';
import { SelectPerson } from 'features/SelectPerson';
import React, { useCallback } from 'react';
import { JointEvent } from 'types/models';

type Props = {
  participant: JointEvent.Participant | null;
  onChange(e: JointEvent.Participant): void;
};

export function ParticipantFields({ participant, onChange }: Props) {
  const handleParticipantChange = useCallback(
    (e: JointEvent.Participant) => {
      onChange({ ...e, id: e.id || null, awards: e.awards || [], historyLabel: e.historyLabel || '' });
    },
    [onChange],
  );
  return (
    <FormComponent.Field label="ФИО" isRequired>
      <SelectPerson member={participant} onUpdateMember={handleParticipantChange} />
    </FormComponent.Field>
  );
}
