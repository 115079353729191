import React from 'react';
import * as R from 'ramda';
import { block } from 'bem-cn';

import { TextInput, TextInputMode } from 'components';

import { isNumberValid } from 'utils/Validators';
import { Program } from 'types/models';

const b = block('performance-indicators');

type Props = {
  indicator: Program.Indicator;
  indicators: Program.Indicator[];
  amountStages: number;
  setIndicators(indicators: Program.Indicator[]): void;
  disabled: boolean;
};

export function Line(props: Props) {
  const { indicator, indicators, amountStages, setIndicators, disabled } = props;

  const makeHandleChange = (key: keyof Program.Indicator, id: string) => (val: string) => {
    if (isNumberValid(val)) {
      const index = indicators.findIndex(x => x.refResultItem.id === id);
      const updatedInideces = R.over(R.lensIndex(index), (x: Program.Indicator) => ({ ...x, [key]: val }), indicators);
      setIndicators(updatedInideces);
    }
  };

  const renderTextField = (key: keyof Program.Indicator) => (
    <TextInput
      mode={TextInputMode.number}
      value={indicator[key] as string}
      onChange={makeHandleChange(key, indicator.refResultItem.id)}
      settings={{ decimal: false }}
      isDisabled={disabled}
    />
  );

  return (
    <React.Fragment key={indicator.id}>
      <div className={b('column')}>{indicator.refResultItem.label}</div>
      <div className={b('column')}>{renderTextField('firstYearPlan')}</div>
      {amountStages > 1 && <div className={b('column')}>{renderTextField('secondYearPlan')}</div>}
    </React.Fragment>
  );
}
