import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  isDisabledAdd?: boolean;
};

function LeftPanelForThirdLevel({ tableState, isDisabledAdd }: Props) {
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
    tableStreams.reloadTable.push();
  }, [tableStreams.reloadTable]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        permissionName: 'MagazineView',
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: isDisabledAdd || false,
        onClick: handleAddButtonClick,
        permissionName: 'MagazineAdd',
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: 'MagazineEdit',
      },
    ],
    [
      isViewButtonDisabled,
      handleViewButtonClick,
      isDisabledAdd,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
    ],
  );

  return (
    <>
      <modalTemplates.MagazineAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
      />
      {!isViewButtonDisabled && (
        <modalTemplates.MagazineViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.MagazineEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.name}
          relatedTableState={tableState}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
