import { SubmitTable } from 'features/Table/streams';
import { useCallback } from 'react';
import { MipEnterpriseCustomer } from './types';

type Props = {
  onSubmit: (enterprise: MipEnterpriseCustomer | null) => void;
};

const useController = ({ onSubmit }: Props) => {
  const onGridSubmit = useCallback(
    (state: SubmitTable) => {
      const [row] = state.selectedRows;
      if (row) {
        onSubmit({
          value: { id: row.id, partnerId: row.partnerId },
          label: row.fullName,
        });
      } else {
        onSubmit(row);
      }
    },
    [onSubmit],
  );

  return {
    onGridSubmit,
  };
};

export default useController;
