import React, { useCallback } from 'react';

import { InputSelect, FormComponent, Option, Reference, ReferenceItem, TextInput, TextDateTime } from 'components';

import { Person } from 'types/models';
import { EnumMap } from 'types/models/Table';
import { showNotification } from 'features/Notifications';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { JobStatus } from 'utils/Enums';
import { getEnum } from 'utils/Helpers';
import { getMockJob } from 'features/Form/looks/person/helpers';

type Props = {
  job?: Person.ScientistJob;
  enumMap: EnumMap;
  onChange(job: Person.ScientistJob): void;
};

export function Fields({ job = getMockJob(), onChange, enumMap }: Props) {
  const { userPermission } = useAppDataContext();
  const { settings } = useAppDataContext();

  const makeChangeHandler = useCallback(
    (fieldName: keyof Person.ScientistJob) => (value: Option | null | ReferenceItem | string) => {
      onChange({ ...job, [fieldName]: value });
    },
    [job, onChange],
  );

  const handleChangeRefAppointment = useCallback(
    (ref: ReferenceItem) => {
      onChange({ ...job, refAppointment: ref, appointment: ref.label || '' });
    },
    [job, onChange],
  );

  const handleChangeRefDepartment = useCallback(
    (ref: ReferenceItem) => {
      onChange({ ...job, refDepartment: ref, department: ref.label || '' });
    },
    [job, onChange],
  );

  const handleChangeStatus = useCallback(
    (option: Option | null) => {
      const isNextValueLocal = option?.value === JobStatus.LOCAL;
      if (isNextValueLocal) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.PERSON_LOCAL_JOB_CREATE);
        if (!isUserHasPermission) {
          showNotification({ message: `У вас нет права добавлять работу ${settings?.organization?.shortName}`, theme: 'danger' });
        } else {
          const nextJob = { ...job };
          nextJob.status = option;
          nextJob.enterpise = settings?.organization?.shortName ?? '';
          onChange(nextJob);
        }
      } else if (option) {
        const nextJob = { ...job };
        nextJob.status = option;
        nextJob.enterpise = '';
        nextJob.staffState = null;
        onChange(nextJob);
      }
    },
    [job, onChange, userPermission, settings],
  );

  const options = getEnum('JobStatus', enumMap);
  const isDisabled = job?.isReadOnly ?? false;

  const renderExternalEmployeeFields = () => {
    return (
      <>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Статус" isRequired>
            <InputSelect
              disabled={isDisabled}
              options={options}
              value={options.find(x => x.value === job?.status?.value) ?? null}
              onSelectChange={handleChangeStatus}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Организация" isRequired>
            <TextInput value={job?.enterpise} onChange={makeChangeHandler('enterpise')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Должность" isRequired>
            <Reference
              name="RefAppointment"
              value={job?.refAppointment ?? null}
              relationTableModalTitle='Справочник "Должности"'
              onChange={handleChangeRefAppointment}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {job?.status.value === JobStatus.LOCAL && (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Штатное состояние" isRequired>
              <Reference
                name="RefStaffState"
                value={job?.staffState ?? null}
                relationTableModalTitle='Справочник "Штатное состояние"'
                onChange={makeChangeHandler('staffState')}
                disabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Подразделение">
            <TextInput value={job?.department || undefined} onChange={makeChangeHandler('department')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
      </>
    );
  };

  const renderCivilContractFields = () => {
    return (
      <>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Статус" isRequired>
            <InputSelect
              options={options}
              value={options.find(x => x.value === job?.status.value) ?? null}
              onSelectChange={handleChangeStatus}
              disabled={isDisabled}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата приема" isRequired>
            <TextDateTime value={job?.dateReceipt} onChange={makeChangeHandler('dateReceipt')} isDisabled={isDisabled} />
          </FormComponent.Field>
          <FormComponent.Field label="Дата увольнения">
            <TextDateTime
              value={job?.dateDismissal}
              onChange={makeChangeHandler('dateDismissal')}
              isDisabled={!isHasPermission(userPermission, Permits.PERSON_LOCAL_JOB_CREATE)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Название договора">
            <TextInput
              value={job?.civilContractName || ''}
              onChange={makeChangeHandler('civilContractName')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Номер договора">
            <TextInput
              value={job?.civilContractNumber || ''}
              onChange={makeChangeHandler('civilContractNumber')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Дата договора">
            <TextDateTime
              value={job?.civilContractDate}
              onChange={makeChangeHandler('civilContractDate')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Подразделение" tooltip='Выберете "Университет", если подразделение не известно.'>
            <Reference
              name="RefDepartment"
              value={job?.refDepartment ?? null}
              relationTableModalTitle='Справочник "Подразделения"'
              onChange={handleChangeRefDepartment}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </>
    );
  };

  const renderEmployeeFields = () => {
    return (
      <>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Статус" isRequired>
            <InputSelect
              options={options}
              value={options.find(x => x.value === job?.status.value) ?? null}
              onSelectChange={handleChangeStatus}
              disabled={isDisabled}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата приема">
            <TextDateTime value={job?.dateReceipt} onChange={makeChangeHandler('dateReceipt')} isDisabled={isDisabled} />
          </FormComponent.Field>
          <FormComponent.Field label="Дата увольнения">
            <TextDateTime
              value={job?.dateDismissal}
              onChange={makeChangeHandler('dateDismissal')}
              isDisabled={!isHasPermission(userPermission, Permits.PERSON_LOCAL_JOB_CREATE)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Организация" isRequired>
            <TextInput value={job?.enterpise} onChange={makeChangeHandler('enterpise')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Должность" isRequired>
            <Reference
              name="RefAppointment"
              value={job?.refAppointment ?? null}
              relationTableModalTitle='Справочник "Должности"'
              onChange={handleChangeRefAppointment}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label="Группа персонала">
            <Reference
              name="RefStaffGroup"
              value={job?.staffGroup ?? null}
              relationTableModalTitle='Справочник "Группы персонала"'
              onChange={makeChangeHandler('staffGroup')}
              disabled={isDisabled}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Категория должности">
            <Reference
              name="RefAppointmentCategory"
              value={job?.appointmentCategory ?? null}
              relationTableModalTitle='Справочник "Категории должностей"'
              onChange={makeChangeHandler('appointmentCategory')}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {job?.status.value === JobStatus.LOCAL && (
          <FormComponent.Line>
            <FormComponent.Field label="Штатное состояние" isRequired>
              <Reference
                name="RefStaffState"
                value={job?.staffState ?? null}
                relationTableModalTitle='Справочник "Штатное состояние"'
                onChange={makeChangeHandler('staffState')}
                disabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line>
          <FormComponent.Field label="Подразделение">
            <Reference
              name="RefDepartment"
              value={job?.refDepartment ?? null}
              relationTableModalTitle='Справочник "Подразделения"'
              onChange={handleChangeRefDepartment}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </>
    );
  };

  function renderSwitch(status: string | undefined) {
    return (
      <div>
        {(() => {
          switch (status) {
            case JobStatus.LOCAL:
              return renderEmployeeFields();
            case JobStatus.CIVIL_CONTRACT:
              return renderCivilContractFields();
            default:
              return renderExternalEmployeeFields();
          }
        })()}
      </div>
    );
  }

  return (
    <>
      {renderSwitch(job?.status?.value)}
      <>
        {(job?.creationSource || job?.modificationSource) && (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Actions>
              <span>
                <strong>Создано: </strong>
                {job?.creationSource ? `Зарплата ${job?.creationSource} (${job?.creationDate})` : 'нет данных'}
              </span>
              <span>
                <strong> Изменено: </strong>
                {job?.modificationSource ? `Зарплата ${job?.modificationSource} (${job?.modificationDate})` : 'нет данных'}
              </span>
            </FormComponent.Actions>
          </FormComponent.Line>
        )}
      </>
    </>
  );
}
