import React from 'react';
import { setup } from 'bem-cn';

import { IconButton, IconButtonProps, Permission } from 'components';

import * as icons from 'icons';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});

const b = block('toolbar');

export const buttonIcons = {
  personMultipleAdd: { default: icons.PersonMultipleAdd, disabled: icons.PersonMultipleAddDisabled },
  saveAs: { default: icons.SaveAs, disabled: icons.SaveAsDisabled },
  loop: { default: icons.Loop, disabled: icons.LoopDisabled },
  calendar: { default: icons.Calendar, disabled: icons.CalendarDisabled },
  check: { default: icons.Check, disabled: icons.CheckDisabled },
  print: { default: icons.Print, disabled: icons.PrintDisabled },
  plus: { default: icons.Plus, disabled: icons.PlusDisabled },
  edit: { default: icons.Edit, disabled: icons.EditDisabled },
  delete: { default: icons.Delete, disabled: icons.DeleteDisabled },
  merge: { default: icons.Merge, disabled: icons.MergeDisabled },
  mergeHistory: { default: icons.MergeHistory, disabled: icons.MergeHistoryDisabled },
  citation: { default: icons.PublicationCitation, disabled: icons.PublicationCitationDisabled },
  bookOpen: { default: icons.BookOpen, disabled: icons.BookOpenDisabled },
  info: { default: icons.Info, disabled: icons.InfoDisabled },
  list: { default: icons.List, disabled: icons.ListDisabled },
  toolbarHelp: { default: icons.ToolbarHelp, disabled: icons.ToolbarHelp },
  toolbarQuestion: { default: icons.ToolbarQuestion, disabled: icons.ToolbarQuestion },
  save: { default: icons.Save, disabled: icons.SaveDisabled },
  saveAndContinue: { default: icons.SaveAndContinue, disabled: icons.SaveAndContinueDisabled },
  saveDraft: { default: icons.SaveDraft, disabled: icons.SaveDraftDisabled },
  cancel: { default: icons.Cancel, disabled: icons.CancelDisabled },
  narrow: { default: icons.Narrow, disabled: icons.Narrow },
  like: { default: icons.Like, disabled: icons.LikeDisabled },
  dislike: { default: icons.Dislike, disabled: icons.DislikeDisabled },
  arrowDown: { default: icons.ArrowDown, disabled: icons.ArrowDownDisabled },
  arrowUp: { default: icons.ArrowUp, disabled: icons.ArrowUpDisabled },
  arrowLeft: { default: icons.ArrowLeft, disabled: icons.ArrowLeftDisabled },
  link: { default: icons.Link, disabled: icons.LinkDisabled },
  download: { default: icons.Download, disabled: icons.DownloadDisabled },
  approve: { default: icons.Approve, disabled: icons.ApproveDisabled },
  label: { default: icons.Label, disabled: icons.LabelDisabled },
  editRelations: { default: icons.EditRelations, disabled: icons.EditRelationsDisabled },
  sendToLibrary: { default: icons.SendToLibrary, disabled: icons.SendToLibraryDisabled },
  arrowLoop: { default: icons.ArrowLoop, disabled: icons.ArrowLoopDisabled },
  sourceScore: { default: icons.SourceScore, disabled: icons.SourceScoreDisabled },
  viewSource: { default: icons.ViewSource, disabled: icons.ViewSourceDisabled },
  doi: { default: icons.DOI, disabled: icons.DOIDisabled },
  articleRelations: { default: icons.ArticleRelations, disabled: icons.ArticleRelationsDisabled },
  financialSupport: { default: icons.FinancialSupport, disabled: icons.FinancialSupportDisabled },
  financialMove: { default: icons.FinancialMove, disabled: icons.FinancialMoveDisabled },
  medal: { default: icons.Medal, disabled: icons.MedalDisabled },
  person: { default: icons.Person, disabled: icons.PersonDisabled },
  viewList: { default: icons.ViewList, disabled: icons.ViewListDisabled },
  book: { default: icons.Book, disabled: icons.BookDisabled },
  bookGreen: { default: icons.BookGreen, disabled: icons.BookDisabled },
  bookLight: { default: icons.BookLight, disabled: icons.BookDisabled },
  bookPurple: { default: icons.BookPurple, disabled: icons.BookDisabled },
  bookTurquoise: { default: icons.BookTurquoise, disabled: icons.BookDisabled },
  personEmail: { default: icons.PersonMail, disabled: icons.PersonMailDisabled },
  payment: { default: icons.Payment, disabled: icons.PaymentDisabled },
  paymentHandover: { default: icons.PaymentHandover, disabled: icons.PaymentHandoverDisabled },
  update: { default: icons.Update, disabled: icons.UpdateDisabled },
  conflictsNone: { default: icons.ConflictsNone, disabled: icons.ConflictsNoneDisabled },
  box: { default: icons.Box, disabled: icons.BoxDisabled },
  comment: { default: icons.Comment, disabled: icons.CommentDisabled },
  calc: { default: icons.Calc, disabled: icons.CalcDisabled },
  affilationAdd: { default: icons.AffilationAdd, disabled: icons.AffilationAddDisabled },
  affilationDelete: { default: icons.AffilationDelete, disabled: icons.AffilationDeleteDisabled },
  paymentCancel: { default: icons.PaymentCancel, disabled: icons.PaymentCancelDisabled },
  eye: { default: icons.Eye, disabled: icons.EyeDisabled },
  clock: { default: icons.Clock, disabled: icons.ClockDisabled },
  attach: { default: icons.Attach, disabled: icons.AttachDisabled },
  star: { default: icons.Star, disabled: icons.StarDisabled },
  yellowLike: { default: icons.YellowLike, disabled: icons.YellowLikeDisabled },
  XMLDocument: { default: icons.XMLDocument, disabled: icons.XMLDocumentDisabled },
  report: { default: icons.Report, disabled: icons.ReportDisabled },
  logout: { default: icons.Logout, disabled: icons.Logout },
  activation: { default: icons.Activation, disabled: icons.ActivationDisabled },
  deactivation: { default: icons.Deactivation, disabled: icons.DeactivationDisabled },
  clone: { default: icons.Clone, disabled: icons.CloneDisabled },
  addNew: { default: icons.AddNew, disabled: icons.AddNewDisabled },
  preprintRelation: { default: icons.PreprintRelation, disabled: icons.PreprintRelationDisabled },
  filter: { default: icons.Filter, disabled: icons.Filter },
  resetFilter: { default: icons.CloseFilter, disabled: icons.CloseFilter },
  wrench: { default: icons.Wrench, disabled: icons.WrenchDisabled },
  downloadTextLink: { default: icons.DownloadTextLink, disabled: icons.DownloadTextLink },
  downloadShareLink: { default: icons.DownloadShareLink, disabled: icons.DownloadShareLink },
  unblock: { default: icons.Unblock, disabled: icons.UnblockDisabled },
  balance: { default: icons.Balance, disabled: icons.BalanceDisabled },
  publication: { default: icons.Publication, disabled: icons.PublicationDisabled },
  participation: { default: icons.ParticipationActivities, disabled: icons.ParticipationActivitiesDisabled },
  event: { default: icons.Event, disabled: icons.EventDisabled },
  requisition: { default: icons.Requisition, disabled: icons.RequisitionDisabled },
  intellectualProperty: { default: icons.IntellectualProperty, disabled: icons.IntellectualPropertyDisabled },
  expand: { default: icons.Expand, disabled: icons.Expand },
  closeFilter: { default: icons.CloseFilter, disabled: icons.CloseFilter },
} as const;

type ToolbarMode = 'form' | 'list';

type Props = {
  buttons: IconButtonProps[];
  mode?: ToolbarMode;
};

/**
 * Toolbar panel
 *
 * @param buttons - List of buttons
 * @param mode - (optional) Mode of toolbar ('form' | 'list') paddings and colors
 * @returns JSX
 */
export const Toolbar = React.memo(({ buttons, mode }: Props) => {
  return (
    <div className={b({ mode: mode })}>
      {buttons
        .filter(x => !x.isHidden)
        .map((x, index) => (
          <Permission key={index.toString() + x.icons.default} name={x.permissionName} profileName={x.profilePermissionName}>
            <IconButton {...x} />
          </Permission>
        ))}
    </div>
  );
});
