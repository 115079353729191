import { makeStream } from 'StreamRx';
import { Table } from 'types/models';

export const streams = {
  toggleChangeForm: makeStream(),
  toggleSettingEditing: makeStream<boolean>(),
  deleteTableSetting: makeStream<{ settingName: string }>(),
  addNewGridSetting: makeStream<AddNewGridSettingData>(),
};

export type AddNewGridSettingData = {
  columns: Table.Column[];
  shared: boolean;
  purpose: string;
  settingName: string;
  editedSettingID?: string;
  editingSettingName?: string;
};
