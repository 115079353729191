import { Table as T } from 'types/models';

export type DetailProps = {
  colleagueScientistId: string;
  scientistId: string;
};
export function GetPersonalColleagueParticipationList({ colleagueScientistId, scientistId }: DetailProps): T.Specification<{}> {
  return {
    apiID: 'GetPersonalColleagueParticipationList',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData: { colleagueScientistId, scientistId },
    isFiltersHidden: true,
  };
}
