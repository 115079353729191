import { GetPrintPublicationListSpecification } from 'features/Table/specifications';
import { useCallback, useMemo } from 'react';
import { Form, Table } from 'types/models';

type Props = {
  relatedTableState?: Table.State;
  releaseId?: string;
  releaseYear?: string;
};

const useController = ({ relatedTableState, releaseYear, releaseId }: Props) => {
  const modalTableRowToPrintPublicationConventer = useCallback<(row: Table.Entry) => Form.PrintArticle>(
    row => ({
      id: row.id,
      name: row.BibliographicRecord,
      status: row.Status,
    }),
    [],
  );

  const printPublicationTableConfig = useMemo(
    () =>
      GetPrintPublicationListSpecification({
        templatesTableDependencies: relatedTableState && {
          relatedTableAPIID: 'GetMagazineList',
          relatedTableState,
          year: releaseYear,
        },
        releaseId: releaseId || '-1',
      }),
    [releaseYear, releaseId, relatedTableState],
  );

  return {
    modalTableRowToPrintPublicationConventer,
    printPublicationTableConfig,
  };
};

export default useController;
