import React, { useMemo } from 'react';

import { CustomSelect, FormComponent, TextArea, TextAreaMode, TextDateTime, UploadFile } from 'components';

import { FileInfo, Table } from 'types/models';
import { ChooseProjectScientists } from './ChooseProjectScientists/ChooseProjectScientists';
import { ProjectAssignment, Project } from 'types/models/Project';
import { Specification } from 'types/models/Table';
import { Original } from 'types/models/Form';

type Props = {
  workMode: Table.WorkMode;
  projectAssignment: ProjectAssignment;
  project: Project | null;
  handleProjectChange(value: Original | null): void;
  projectTableRowConverter(row: Table.Entry): Original;
  makeChangeHandler(key: keyof ProjectAssignment): any;
  projectSpecification: Specification<any>;
  onChangeFile(fileInfo: FileInfo): void;
  hasPersonalMode: boolean;
};

function AboutProjectAssignment({
  workMode,
  projectAssignment,
  project,
  handleProjectChange,
  projectTableRowConverter,
  makeChangeHandler,
  projectSpecification,
  onChangeFile,
  hasPersonalMode,
}: Props) {
  const disabled = useMemo(() => workMode === 'viewMode', [workMode]);

  return (
    <>
      {hasPersonalMode && (
        <FormComponent.Line>
          <FormComponent.Field label="Проект" isRequired>
            <CustomSelect
              disabled={disabled || workMode === 'editMode'}
              original={
                project?.id
                  ? {
                      id: project?.id || '',
                      name: `${project?.number} "${project?.name}", (${project.startDate} - ${project.endDate})` || '',
                    }
                  : null
              }
              relationTableModalTitle="Проекты"
              onChange={handleProjectChange}
              modalTableRowConverter={projectTableRowConverter}
              onDeleteFunction={() => handleProjectChange(null)}
              modalTableSpecification={projectSpecification}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Дата поручения" isRequired>
          <TextDateTime value={projectAssignment.date} onChange={val => makeChangeHandler('date')(val)} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Поручение" isRequired>
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Поручение' }}
            value={projectAssignment.assignment}
            onChange={makeChangeHandler('assignment')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Выполнить до">
          <TextDateTime
            value={projectAssignment.upToDate}
            onChange={val => makeChangeHandler('upToDate')(val)}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Ответственные лица" isRequired>
          <ChooseProjectScientists
            performers={project?.performers || []}
            chosenPerformers={projectAssignment.projectScientists || []}
            disabled={disabled}
            setChosenPerformers={makeChangeHandler('projectScientists')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Прикрепите файл">
          <UploadFile file={projectAssignment?.file} onChange={onChangeFile} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

export const Component = React.memo(AboutProjectAssignment);
