import React, { useCallback, useMemo } from 'react';
import { ListEdit } from 'components';
import { Table } from 'types/models';
import { PublicationMobileRequest } from 'types/models/Form';
import { GetAcceptableMobileRequestList } from 'features/Table/specifications/GetAcceptableMobileRequestList';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { ParticipationTypeCode } from 'types/models/Participation';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';

type Props = {
  participationTypeCode: ParticipationTypeCode;
  workMode: Table.WorkMode;
  requests: PublicationMobileRequest[];
  relatedTableState: Table.State | undefined;
  setLocalMobileRequest: (rows: PublicationMobileRequest[]) => void;
  setLocalMobileRequestAccept: (id: string) => void;
  setLocalMobileRequestCancelAcception: (id: string) => void;
  isDisabled?: boolean;
  isMaximized?: boolean;
};

function Requests({
  workMode,
  participationTypeCode,
  requests,
  relatedTableState,
  setLocalMobileRequest,
  setLocalMobileRequestAccept,
  setLocalMobileRequestCancelAcception,
  isDisabled,
  isMaximized,
}: Props) {
  const { isProfile } = usePrivatePageContext();
  const toolbarEnabled: boolean = useMemo(() => workMode !== 'viewMode', [workMode]);

  const specifications = GetAcceptableMobileRequestList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const getIsEditMobileRequestButtonDisabled = useCallback(
    (mobileRequest: PublicationMobileRequest | null) => !!mobileRequest?.accepted,
    [],
  );

  const getIsDeleteMobileRequestButtonDisabled = useCallback(
    (mobileRequest: PublicationMobileRequest | null) => !!mobileRequest?.accepted,
    [],
  );

  const modalTableRowConverter = useCallback<(row: Table.Entry) => PublicationMobileRequest>(
    row => ({
      id: null,
      mobileRequest: {
        id: row.id,
        label: row.Label,
      },
      accepted: '',
      acceptedBy: null,
    }),
    [],
  );

  return (
    <ListEdit
      specification={{
        mode: 'relationTableModal',
        modalTableRowConverter,
        relationTableModalTitle: 'Заявки по мобильности',
        modalTableSpecification: specifications,
      }}
      isModalHintHidden
      title="Заявки по программам академической мобильности"
      rows={requests}
      isToolbarDisabled={isDisabled !== undefined ? isDisabled : !toolbarEnabled}
      columns={[
        { label: 'Заявка', formatValue: row => row.mobileRequest?.label || '', styles: { width: '75%' } },
        { label: 'Принята к отчету', formatValue: getAcceptInfo, styles: { width: '25%' } },
      ]}
      onChange={rows => setLocalMobileRequest(rows)}
      withAcceptButtons={!isProfile}
      onAccept={({ mobileRequest }) => setLocalMobileRequestAccept(mobileRequest?.id || '')}
      onAcceptanceCancel={({ mobileRequest }) => setLocalMobileRequestCancelAcception(mobileRequest?.id || '')}
      addPermissionName={Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits]}
      editPermissionName={Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits]}
      acceptPermissionName={Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits]}
      getIsDeleteDisabled={getIsEditMobileRequestButtonDisabled}
      getIsEditDisabled={getIsDeleteMobileRequestButtonDisabled}
      maxHeight={isMaximized ? 'none' : undefined}
      isFullScreenedTable={isMaximized}
    />
  );
}

export { Requests };
