import { useCallback, useMemo, useState, useEffect } from 'react';
import { BuilderProps } from 'features/BuildReportPopup/models';
import { Parameter } from 'types/models';

type Props = {} & BuilderProps;

const CHECKED_VALUE = 'true';
const UNCHECKED_VALUE = 'false';

const useController = ({ parameterName, setParameterValueByName, parameters }: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setParameterValueByName({ name: parameterName, value: UNCHECKED_VALUE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );

  const onIsCheckedChange = useCallback(
    (nextValue: boolean) => {
      setIsChecked(nextValue);
      setParameterValueByName({ name: parameterName, value: nextValue ? CHECKED_VALUE : UNCHECKED_VALUE });
    },
    [parameterName, setParameterValueByName],
  );

  return {
    isChecked,
    currentParameter,
    onIsCheckedChange,
  };
};

export default useController;
