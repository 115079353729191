import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, ConfirmPopup, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import * as modalTemplates from './modalTemplates';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Program',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);
  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
    ],
    [
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      handleHelpButtonClick,
    ],
  );

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.ProgramViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.ProgramEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <modalTemplates.ProgramAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную программу? (${tableState.selectedRows[0].name})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ConfirmPopup
        title="Помощь в работе с разделом «Список программ поддержки научной деятельности»"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <h3>Список программ поддержки научной деятельности</h3>
          <p>
            Список содержит федеральные, международные, региональные, отраслевые программы поддержки научных исследований,
            академической мобильности, инновационной деятельности, в частности,
            <strong className="primary"> рекомендуется занести в этот список</strong> следующие программы:
          </p>
          <ul>
            <li>
              Гранты РНФ — для выделения группы проектов РНФ и контроля соблюдения требований Фонда к составу и численности
              коллектива, расходованию средств, показателям и индикаторов результативности, отчётности;
            </li>
            <li>
              Программу(ы) развития СФУ — для организации внутренних конкурсов на выполнение НИР и ОКР; выделения группы проектов
              с целью контроля выполнения показателей и индикаторов; отчётности; в том числе, загрузки данных в ЕГИСУ
            </li>
            <li>
              Госзадание — для выделения группы проектов с целью контроля выполнения показателей и индикаторов; отчётности; в том
              числе, загрузки данных в ЕГИСУ
            </li>
            <li>
              Программы, в рамках которых заключены соглашения о сотрудничестве с предприятиями и организациями и сроки действия
              соглашения ограничены сроками действия Программы;
            </li>
            <li>
              Программу(ы) академической мобильности, например, Университет «Сириус» — для выделения группы заявок и проектов и
              мониторинга отложенных результатов и эффективности участия в программе за период.
            </li>
          </ul>
          <p>
            <strong className="primary">Полное название</strong> программы обычно используется в отчётных формах.
          </p>
          <p>
            <strong className="primary">Краткое название</strong> программы — выводится в столбцах многочисленных таблиц со
            списками проектов, заявок, конкурсов и из основных признаков группировки в списках Дата начала и дата окончания
            программы — не обязательные поля; если заполнены, учитываются в фильтре Год; если не заполнены, то в алгоритме фильтра
            Год такие программы считаются актуальными всегда.
          </p>
          <p>
            <strong className="primary">Логотип программы</strong> используется для оформления объявлений о конкурсах.
          </p>
          <p>
            В Положении о программе (федеральные целевые программы, национальные программы технологического развития и пр.) могут
            быть разделения на крупные <strong className="primary">направления и мероприятия</strong> целевого финансирования за
            счёт средств программы. Кроме того, в положении прописаны{' '}
            <strong className="primary">показатели результативности и индикаторы эффективности </strong>
            действия программы. В случае, если в университете выполняются проекты, рекомендуется внести эти направления на вкладке
            «Направления», мероприятия программы на вкладке «Мероприятия», индикаторы и показатели — это позволит быстро
            формировать отчёты по запросам Министерства образования о результатах выполнения проектов по программе в разрезе
            направлений и мероприятий.
          </p>
          <h3>Особенности заполнения данных на вкладках «Индикаторы» и «Показатели»</h3>
          <p>
            <strong className="primary">Индикаторы эффективности и показатели результативности</strong> программы выбираются из
            одноимённых справочников (см. п.м. Справочники), в которых хранятся коллекции всех индикаторов и показателей из разных
            программ. Опыт показывает, что в разных программах используются очень похожие по названию индикаторы, пусть вас не
            смущает, что в справочнике со временем накопятся смысловые дубликаты, попытка привести к общему знаменателю
            (использовать «универсальные» индикаторы) пагубна, потому что в отчёте по запросу необходимо указывать точное название
            индикатора как в программе. Поскольку для длительных программ на разных этапах индикаторы и показатели могут
            неактуальными или появляться новые,{' '}
            <strong className="primary">
              при подготовке к объявлению внутреннего конкурса необходимо актуализировать коллекцию показателей и индикаторов в
              программе.
            </strong>
          </p>
          <p>
            <strong className="primary">Важно</strong>
          </p>
          <p>
            В заявках на внутренний конкурс вкладка «Показатели эффективности» формируется исключительно по наличию в программе
            актуальных показателей и индикаторов; располагаются на форме в порядке возрастания кодов.
          </p>
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
