import { Form } from 'types/models';

const isStringFieldValid = (stringField: Form.Field) => {
  const { value, required } = stringField;
  const isRequired = !!required;
  const isValueEmpty = value === '';
  const isValid = isValueEmpty ? !isRequired : true;

  return isValid;
};

export { isStringFieldValid };
