import React from 'react';

import { Tabs, Tab } from 'components';

import {
  CoPerformers,
  CoPerformersProps,
  Partners,
  PartnersProps,
  InternationalPartnership,
  InternationalPartnershipProps,
} from './Tabs';

type PerformerTab = {
  projectId: string | null;
  disabled: boolean;
};
type Props = PerformerTab & CoPerformersProps & PartnersProps & InternationalPartnershipProps;

export function AllPerformers(props: Props) {
  const {
    disabled,
    projectId,
    partners,
    setPartners,
    internationalProgram,
    setInternationalProgram,
    foreignEnterprise,
    setForeignEnterprise,
    partnerCountries,
    setPartnerCountries,
  } = props;

  return (
    <Tabs>
      <Tab title="Организации-соисполнители">
        <CoPerformers projectId={projectId} />
      </Tab>
      <Tab title="Организации-партнёры">
        <Partners disabled={disabled} partners={partners} setPartners={setPartners} />
      </Tab>
      <Tab title="Международное сотрудничество">
        <InternationalPartnership
          disabled={disabled}
          internationalProgram={internationalProgram}
          setInternationalProgram={setInternationalProgram}
          foreignEnterprise={foreignEnterprise}
          setForeignEnterprise={setForeignEnterprise}
          partnerCountries={partnerCountries}
          setPartnerCountries={setPartnerCountries}
        />
      </Tab>
    </Tabs>
  );
}
