import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { setup } from 'bem-cn';

import { buttonIcons, FormComponent, IconButtonProps, Modal, Toolbar } from 'components';

import { InputAttributes } from 'types/models/common';
import { showNotification } from 'features/Notifications';

import ReactQuill from 'react-quill';
import { notificationsToolbar } from 'utils/Common/WysiwygToolbar';
import 'react-quill/dist/quill.snow.css';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const w = block('text-area-wrapper');
const b = block('text-area');
const q = block('quill');

const maxSymbols = 21845;
const defaultSymbolsWysiwyg = 2 * 1000 * 1000;

type CommonProps = {
  /** @property {number} rows - (optional) Rows count (2 by default) */
  rows?: number;
};

type TextProps = CommonProps & {
  /** @property {number} type - (optional) Max length of string */
  maxLength?: number;
  /** @property {boolean} isFixed - (optional) Is fixed size of TextArea */
  isFixed?: boolean;
};

type ModalProps = TextProps & {
  /** @property {string} title - (optional) Modal title */
  title?: string;
};

export enum TextAreaMode {
  /** Any text */
  text = 'text',
  /** With modal */
  modal = 'modal',
  /** wysiwyg editor */
  wysiwyg = 'wysiwyg',
  /** Site URL */
  url = 'url',
}

/**
 * Settings for textarea component
 */
export type Props = InputAttributes & {
  /** @property {TextAreaMode} type - (optional) Type of Mask (TextAreaMode.text by default) */
  mode?: TextAreaMode;
  /** @property {CommonProps | TextProps | ModalProps} settings - (optional) Settings for type TextAreaMode */
  settings?: any;
  /** @property {string} value - (optional) Value for textarea */
  value?: string;
  /** @property {function} onChange - (optional) Callback on text changed */
  onChange?(val: string): void;
  /** @property {boolean} isDisabled - (optional) Set is disabled textarea (false by default) */
  isDisabled?: boolean;
  /** @property {boolean} isError - (optional) Set is error textarea (false by default) */
  isError?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
} & (
    | {
        mode?: TextAreaMode.text;
        /** @property {TextProps} settings - (optional) Settings for text */
        settings?: TextProps;
      }
    | {
        mode: TextAreaMode.modal | TextAreaMode.wysiwyg;
        /** @property {ModalProps} settings - (optional) Settings for text with modal */
        settings?: ModalProps;
      }
    | {
        mode: TextAreaMode.url;
      }
  );

/** Examples
<TextArea
  settings={{ maxLength: 40 }}
  value={formFields.requestNumber.value}
  onChange={formFields.requestNumber.onChange}
  isDisabled={disabled}
/>
<TextInput mode={TextAreaMode.modal} settings={{ title: 'Modal title...' }} value={refs.year} onChange={refs.setYear} />
 */

/**
 * Textarea component
 *
 * @param {TextAreaMode} mode - Typeof component (See TextAreaMode enum)
 * @param {TextProps | NumberProps | YearProps} settings - (optional) Settings for type TextAreaMode
 * @param {string} value - (optional) Value for textarea
 * @param {function} onChange - (optional) Callback on text changed
 * @param {boolean} isDisabled - (optional) Set is disabled textarea (false by default)
 * @param {boolean} isError - (optional) Set is error textarea (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const TextArea = React.memo((props: Props) => {
  const {
    mode = TextAreaMode.text,
    settings = {},
    onClick,
    onFocus,
    onChange,
    isDisabled = false,
    isError = false,
    classMixin,
    value,
    ...restProps
  } = props;

  const [valueState, setValueState] = useState<string>(value || '');
  const [isFocusedQuill, setIsFocusedQuill] = useState<boolean>(false);

  useEffect(() => {
    setValueState(value || '');
  }, [value]);

  const className = useMemo(
    () =>
      b({
        'is-disabled': isDisabled,
        'is-error': isError,
        'is-clickabled': !!onClick,
        'is-fixed': !!settings.isFixed,
      }),
    [isDisabled, isError, onClick, settings.isFixed],
  );

  const calcHeight = (rows?: number) => `${(rows || 2) * 20 + 10}px`;

  const calculatedProps = {
    readOnly: isDisabled,
    disabled: isDisabled && !onClick,
    onClick: onClick,
  };

  const getValue = (e: React.ChangeEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLTextAreaElement>) => e.target.value;

  // Any mode
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val: string = getValue(e);

    if (onChange) {
      let { maxLength } = settings as TextProps;
      maxLength = maxLength || (mode === TextAreaMode.wysiwyg ? defaultSymbolsWysiwyg : maxSymbols);

      if (val.length > maxLength) {
        showNotification({
          message: `Превышено максимально количество символов (${maxLength}), сократите строку`,
          theme: 'danger',
        });
        onChange(val.slice(0, maxLength));
        return;
      }
      onChange(val);
    }
    setValueState(val);
  };

  // Remove value if ['0', '0.0', '0.00', ao]
  const onFocusIfIsEmpty = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const val: string = getValue(e);

    if (Number(val) === parseFloat(val) && !parseFloat(val) && onChange) {
      setValueState('');
    }
    if (onFocus) {
      onFocus(e);
    }
  };

  const textarea =
    mode === TextAreaMode.wysiwyg ? (
      <ReactQuill
        theme="snow"
        className={q({ 'is-focused': isFocusedQuill })}
        value={value}
        onChange={val => handleChange(({ target: { value: val } } as unknown) as React.ChangeEvent<HTMLTextAreaElement>)}
        modules={{
          toolbar: notificationsToolbar,
        }}
        {...calculatedProps}
        {...{ style: { minHeight: calcHeight(settings.rows || 10) } }}
        onFocus={() => setIsFocusedQuill(true)}
        onBlur={() => setIsFocusedQuill(false)}
      />
    ) : (
      <textarea
        className={className}
        onChange={handleChange}
        onFocus={onFocusIfIsEmpty}
        value={valueState}
        {...calculatedProps}
        {...{ style: { height: calcHeight(settings.rows) } }}
        {...(restProps as InputAttributes)}
      />
    );

  // TextAreaMode.modal | TextAreaMode.wysiwyg
  if (mode === TextAreaMode.modal || mode === TextAreaMode.wysiwyg) {
    const [extendedValue, setExtendedValue] = useState<string>('');
    const [isExtendedPopupOpen, setIsExtendedPopupOpen] = useState<boolean>(false);

    const openExtenededPopup = useCallback(() => {
      setExtendedValue(valueState);
      setIsExtendedPopupOpen(true);
    }, [valueState]);

    if (mode === TextAreaMode.wysiwyg) {
      useLayoutEffect(() => {
        const titles: Record<string, string> = {
          'ql-bold': 'Жирный',
          'ql-italic': 'Наклонный',
          'ql-underline': 'Подчёркнутый',
          'ql-strike': 'Зачёркнутый',
          'ql-blockquote': 'Цитата',
          'ql-list_ordered': 'Нумерованный список',
          'ql-list_bullet': 'Список',
          'ql-indent_-1': 'Убрать отступ',
          'ql-indent_+1': 'Добавить отступ',
          'ql-link': 'Добавить ссылку',
          'ql-image': 'Добавить изображение',
          'ql-clean': 'Убрать форматирование',
        };

        [].slice.call(document.querySelectorAll('.ql-toolbar > .ql-formats > button[type="button"]')).forEach(el => {
          const button: HTMLButtonElement = el;
          const index: string = [button.className, button.value || ''].filter(x => x).join('_');
          button.title = titles[index] ?? '';
        });
      }, [mode]);
    }

    const toolbarButtons: IconButtonProps[] = useMemo(
      () => [
        {
          icons: buttonIcons.loop,
          title: 'Открыть в новом окне',
          isDisabled: !valueState.length,
          onClick: openExtenededPopup,
        },
      ],
      [openExtenededPopup, valueState.length],
    );

    const buttons: IconButtonProps[] = [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        isDisabled: isDisabled,
        onClick: () => {
          handleChange(({ target: { value: extendedValue } } as unknown) as React.ChangeEvent<HTMLTextAreaElement>);
          setIsExtendedPopupOpen(false);
        },
      },
      {
        icons: buttonIcons.update,
        title: 'Удалить символы "Переноса строк"',
        isDisabled: isDisabled,
        onClick: () => {
          const lineBreakRegExp = /\n/g;
          const nextExtendedValue = extendedValue.replace(lineBreakRegExp, ' ');

          setExtendedValue(nextExtendedValue);
        },
      },
    ];

    return (
      <div className={w({}).mix(classMixin)}>
        {textarea}
        <Toolbar buttons={toolbarButtons} />

        <Modal
          onClose={() => setIsExtendedPopupOpen(false)}
          isOpen={isExtendedPopupOpen}
          title={settings.title || 'Просмотр содержимого'}
          size="large"
        >
          <FormComponent.Template>
            {mode === TextAreaMode.modal && <Toolbar buttons={buttons} mode="form" />}
            <FormComponent.Wrapper>
              {mode === TextAreaMode.modal ? (
                <textarea
                  className={className}
                  onChange={e => setExtendedValue(getValue(e))}
                  onFocus={onFocusIfIsEmpty}
                  value={extendedValue}
                  {...calculatedProps}
                  {...{ style: { height: calcHeight(10), minHeight: '100%' } }}
                  {...(restProps as InputAttributes)}
                />
              ) : (
                <div className="quill-preview" dangerouslySetInnerHTML={{ __html: value || '' }}></div>
              )}
            </FormComponent.Wrapper>
          </FormComponent.Template>
        </Modal>
      </div>
    );
  }

  return <div className={w({}).mix(classMixin)}>{textarea}</div>;
});
