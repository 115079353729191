import React, { memo, useState } from 'react';
import { Modal } from 'components';
import { Table } from 'types/models';
import { Component } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  publicationId: string;
  relatedTableState?: Table.State;
};

export const Container = memo(({ isOpen, onClose, publicationId, relatedTableState }: Props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="medium">
      <Component onClose={onClose} publicationId={publicationId} relatedTableState={relatedTableState} setTitle={setTitle} />
    </Modal>
  );
});
