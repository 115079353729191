import { JointEvent } from 'types/models';
import { getMaybeField, convertReferenceItemToXML } from 'services/BackendAPI/configurations/commonConverters';
import { ReferenceItem } from 'components';
import { SaveJointEventUserFeedlineRequest, SaveJointEvent } from './types';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { convertDocumentsToRequest } from '../converters';

export function convertJointEventToRequest({ jointEvent, feedlineMessage, feedlineMessageType }: SaveJointEvent) {
  const {
    id,
    type,
    level,
    name,
    startDate,
    endDate,
    isStudent,
    orderNumber,
    orderDate,
    description,
    site,
    contact,
    memberCountPlan,
    memberCountFact,
    keyWords,
    format,
    departments,
    enterprises,
    committeMembers,
    memberCounts,
    projects,
    participants,
    grntis,
    ministryEducations,
    criticalTechnologies,
    pnrs,
    pnmitrs,
    pnis,
    documents,
  } = jointEvent;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Type', type?.value),
    ...(level ? { Level: convertReferenceItemToXML(level) } : {}),
    ...getMaybeField('Name', name),
    ...getMaybeField('StartDate', startDate),
    ...getMaybeField('EndDate', endDate),
    ...getMaybeField('IsStudent', isStudent),
    ...getMaybeField('OrderNumber', orderNumber),
    ...getMaybeField('OrderDate', orderDate),
    ...getMaybeField('Description', description),
    ...getMaybeField('Site', site),
    ...getMaybeField('Contact', contact),
    MemberCountPlan: memberCountPlan || '0',
    MemberCountFact: memberCountFact || '0',
    ...getMaybeField('KeyWords', keyWords),
    ...getMaybeField('Format', format?.value),
    Departments: departments.length ? { Department: departments.map(convertDepartmentToRequest) } : {},
    Enterprises: enterprises.length ? { Enterprise: enterprises.map(convertEnterpriseToRequest) } : {},
    CommitteMembers: committeMembers.length ? { CommitteMember: committeMembers.map(convertCommitteMemberToRequest) } : {},
    MemberCounts: memberCounts.length ? { MemberCount: memberCounts.map(convertMemberCountToRequest) } : {},
    Projects: projects.length ? { Project: projects.map(convertEventProjectToRequest) } : {},
    Participants: participants.length ? { Participant: participants.map(convertParticipantToRequest) } : {},
    Grntis: grntis.length ? { Grnti: grntis.map(convertReferenceItemToXML) } : {},
    MinistryEducations: ministryEducations.length ? { MinistryEducation: ministryEducations.map(convertReferenceItemToXML) } : {},
    CriticalTechnologies: criticalTechnologies.length
      ? { CriticalTechnology: criticalTechnologies.map(convertReferenceItemToXML) }
      : {},
    Pnrs: pnrs.length ? { Pnr: pnrs.map(convertReferenceItemToXML) } : {},
    Pnmitrs: pnmitrs.length ? { Pnmitr: pnmitrs.map(convertReferenceItemToXML) } : {},
    Pnis: pnis.length ? { Pnis: pnis.map(convertReferenceItemToXML) } : {},
    ...(documents?.length ? convertDocumentsToRequest(documents) : {}),
    ...(feedlineMessage && feedlineMessageType
      ? { JointEventFeedline: convertJointEventFeedlineMessageToRequest(feedlineMessage, feedlineMessageType) }
      : {}),
  };
}

function convertDepartmentToRequest(department: JointEvent.Department) {
  const { id, element, position, isFinancing } = department;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(element ? { Element: convertReferenceItemToXML(element) } : {}),
    ...getMaybeField('Position', position),
    ...getMaybeField('IsFinancing', isFinancing),
  };
}

function convertEnterpriseToRequest(enterprise: JointEvent.Enterprise) {
  const { id, element, position, role } = enterprise;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Position', position),
    ...getMaybeField('Role', role?.value),
    ...(element ? { Element: convertReferenceItemToXML(element) } : {}),
  };
}

function convertCommitteMemberToRequest(committeMember: JointEvent.CommitteMember) {
  const { id, person, role } = committeMember;
  return {
    ...getHistoricalSliceXML(committeMember),
    ...(id ? { _attr: { id } } : {}),
    ...(person?.id ? { Person: { _attr: { id: person.id } } } : {}),
    ...(role ? { Role: convertReferenceItemToXML(role) } : {}),
  };
}

function convertMemberCountToRequest(memberCount: JointEvent.MemberCount) {
  const { id, type, amount, amounts } = memberCount;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Type', type?.value),
    ...getMaybeField('Amount', amount),
    Amounts: amounts.length ? { Amount: amounts.map(convertAmountToRequest) } : {},
  };
}

function convertAmountToRequest(amountItem: JointEvent.Amount) {
  const { id, amount, city, country, enterprise } = amountItem;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Amount', amount),
    ...getMaybeField('City', city),
    ...(country ? { Country: convertReferenceItemToXML(country) } : {}),
    ...(enterprise ? { Enterprise: convertReferenceItemToXML(enterprise) } : {}),
  };
}

function convertEventProjectToRequest(eventProject: JointEvent.JointEventProject) {
  const { id, project, accepted, acceptedBy } = eventProject;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(project ? { Project: convertProjectToRequest(project) } : {}),
    ...getMaybeField('Accepted', accepted),
    ...(acceptedBy ? { AcceptedBy: convertAcceptedByToRequest(acceptedBy) } : {}),
  };
}

function convertProjectToRequest(project: JointEvent.JointEventProjectItem) {
  const { id, number, name, leader, governances, financings, program } = project;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Number', number),
    ...getMaybeField('Name', name),
    ...getMaybeField('Leader', leader),
    ...getMaybeField('Governances', governances),
    ...getMaybeField('Financings', financings),
    ...getMaybeField('Program', program),
  };
}

function convertAcceptedByToRequest(acceptedBy: JointEvent.AcceptedBy) {
  const { id, fullName } = acceptedBy;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('FullName', fullName),
  };
}

function convertParticipantToRequest(participant: JointEvent.Participant) {
  const { id, person, awards } = participant;
  return {
    ...getHistoricalSliceXML(participant),
    ...(id ? { _attr: { id } } : {}),
    ...(person?.id ? { Person: { _attr: { id: person.id } } } : {}),
    Awards: awards.length ? { Award: awards.map(convertAwardToRequest) } : {},
  };
}

function convertAwardToRequest(award: JointEvent.Award) {
  const { id, category, name } = award;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Category', category?.value),
    ...getMaybeField('Name', name),
  };
}

export const convertSaveJointEventUserFeedlineToRequest = (props: SaveJointEventUserFeedlineRequest) => {
  const jointEventFeedlineAttr = { _attr: props.messageId ? { id: props.messageId } : {} };

  return {
    JointEventFeedline: {
      ...jointEventFeedlineAttr,
      ...{
        Message: {
          _attr: {
            id: props.labelId,
          },
        },
        Detail: props.message,
        JointEvent: {
          _attr: {
            id: props.jointEventId,
          },
        },
      },
    },
  };
};

function convertJointEventFeedlineMessageToRequest(feedlineMessage: string, feedlineMessageType: ReferenceItem) {
  return {
    Message: { _attr: { id: feedlineMessageType.id } },
    Detail: feedlineMessage,
  };
}
