import React, { memo, useCallback } from 'react';

import { FormComponent, ReferenceItem, CustomSelect as CustomSelectComponent } from 'components';

import { CustomSelectSpecification } from 'features/BuildReportPopup/models';

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  fieldLabel: string;
  specification: CustomSelectSpecification<CustomState, RequestData>;
};

const CustomSelect = <CustomState extends {} = {}, RequestData extends {} = {}>({
  referenceItem,
  setReferenceItem,
  fieldLabel,
  specification,
}: Props<CustomState, RequestData>) => {
  const handleSetReferenceItem = useCallback(
    (nextReferenceItem: ReferenceItem | null) => {
      if (nextReferenceItem) {
        setReferenceItem(nextReferenceItem);
      }
    },
    [setReferenceItem],
  );

  return (
    <FormComponent.Line>
      <FormComponent.Field>
        <CustomSelectComponent
          isRequired
          title={fieldLabel}
          original={referenceItem ? { id: referenceItem.id, name: referenceItem.label } : null}
          relationTableModalTitle={specification.relationTableModalTitle}
          onChange={handleSetReferenceItem}
          modalTableRowConverter={specification.modalTableRowConverter}
          modalTableSpecification={specification.modalTableSpecification}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(CustomSelect);

export { Component as CustomSelect };
