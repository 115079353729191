import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { setup } from 'bem-cn';

import { Icon } from 'components';

import { ColorBase } from 'constants/colors';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('modal');

export type ModalSize =
  | 'height' // height: 100%;
  | 'width' // width: 100%;
  | 'small' // max-width: 500px;
  | 'medium' // max-width: 800px;
  | 'large' // max-width: 1000px;
  | 'extralarge' // max-width: 1400px;
  | 'small-height' // max-height: 500px;
  | 'medium-height' // max-height: 800px;
  | 'large-height' // max-height: 1000px;
  | 'extralarge-height' // max-height: 1400px;
  | 'full'; // height: 100%; max-height: 100%; max-width: 100%;

type Props = {
  size: ModalSize | ModalSize[];
  isOpen?: boolean;
  onClose(): void;
  title?: string | React.ReactElement;
  viewMode?: boolean;
  isFixed?: boolean;
  children: React.ReactElement[] | React.ReactElement | false;
};

const MIN_OFFSET = 8;

/**
 * Modal component with custom content
 *
 * @param size - (value or array) Sizes (common, or size, or sizes)
 * [common - 'full']
 * [width - 'width' | 'small' | 'medium' | 'large' | 'extralarge']
 * [height - 'height' | 'small-height' | 'medium-height' | 'large-height' | 'extralarge-height']
 *
 * @param isOpen - (optional) Modal is opened
 * @param onClose - Callback on close component
 * @param title - (optional) Title for modal
 * @param viewMode - (optional) Show content for view only (false by default)
 * @param isFixed - (optional) Is fixed position modal (false by default)
 * @returns JSX
 */
export const Modal = React.memo(({ size, isOpen = false, onClose, title, viewMode, isFixed = false, children }: Props) => {
  type EventMousePosition = {
    clientX: number;
    clientY: number;
  };
  type MousePosition = {
    x: number;
    y: number;
  };
  type OffsetPosition = {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  };

  const DEFAULT_POSITION: MousePosition = { x: 0, y: 0 };
  const DEFAULT_OFFSET: OffsetPosition = { minX: 0, minY: 0, maxX: 0, maxY: 0 };

  const windowRef = useRef<HTMLDivElement | null>(null);
  const headRef = useRef<HTMLDivElement | null>(null);

  const [isMouseDowned, setIsMouseDowneded] = useState<boolean>(false);
  const [mouseStartPosition, setMouseStartPosition] = useState<MousePosition>(DEFAULT_POSITION);
  const [windowStarPosition, setWindowStartPosition] = useState<MousePosition>(DEFAULT_POSITION);
  const [windowPosition, setWindowPosition] = useState<MousePosition>(DEFAULT_POSITION);

  const [offsetPosition, setOffsetPosition] = useState<OffsetPosition>(DEFAULT_OFFSET);

  const calcOffsets = useCallback(() => {
    const result = {
      minX: 0 + windowPosition.x,
      minY: MIN_OFFSET + windowPosition.y,
      maxX: 0 - windowPosition.x,
      maxY: -MIN_OFFSET + windowPosition.y,
    };

    if (!windowRef.current || !headRef.current) {
      setOffsetPosition(result);
    } else {
      const windowRect: DOMRect = windowRef.current.getBoundingClientRect();
      const headRect: DOMRect = headRef.current.getBoundingClientRect();

      result.minX = result.minX - headRect.left - headRect.width / 2;
      result.minY = result.minY - headRect.top;
      result.maxX = result.maxX + headRect.right - headRect.width / 2;
      result.maxY = result.maxY + windowRect.height - headRect.bottom;

      setOffsetPosition(result);
    }
  }, [windowPosition]);

  const handleMouseDown = (e: EventMousePosition) => {
    if (!isFixed) {
      calcOffsets();
      setIsMouseDowneded(true);
      setMouseStartPosition({ x: e.clientX, y: e.clientY });
      setWindowStartPosition(windowPosition);
    }
  };

  const handleMouseUp = () => {
    if (isMouseDowned) {
      setIsMouseDowneded(false);
    }
  };

  const handleMouseMove = (e: EventMousePosition) => {
    if (isMouseDowned) {
      const position: MousePosition = {
        x: windowStarPosition.x + (e.clientX - mouseStartPosition.x),
        y: windowStarPosition.y + (e.clientY - mouseStartPosition.y),
      };

      setWindowPosition({
        x: Math.max(Math.min(position.x, offsetPosition.maxX), offsetPosition.minX),
        y: Math.max(Math.min(position.y, offsetPosition.maxY), offsetPosition.minY),
      });
    }
  };

  const sizes = (() => {
    if (typeof size === 'string') {
      return size;
    }
    return size.join(' ');
  })();

  return ReactDOM.createPortal(
    isOpen && (
      <div className={b({ mooved: isMouseDowned })} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} ref={windowRef}>
        <div
          className={b('window', { 'view-mode': viewMode, 'no-moving': isFixed })}
          style={{ transform: `translate(${windowPosition.x}px, ${windowPosition.y}px)` }}
          data-size={sizes}
        >
          <div className={b('head')} ref={headRef}>
            <div className={b('head_title')} onMouseDown={handleMouseDown}>
              {typeof title === 'string' ? <h3>{title}</h3> : title}
            </div>
            <button
              className={b('close')}
              onClick={() => {
                setWindowPosition(DEFAULT_POSITION);
                onClose();
              }}
            >
              <Icon type="remove" color={ColorBase.clear} size={16} />
            </button>
          </div>
          <div className={b('body')}>{children && children}</div>
        </div>
      </div>
    ),
    document.body,
  );
});
