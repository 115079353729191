import React, { useMemo } from 'react';
import { block } from 'bem-cn';

import { ConfirmPopup, IconButton, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import EventParticipationTemplate from './modalTemplates/EventParticipationTemplate';
import eventTypes from './eventTypes';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  customState: State;
};

const b = block('toolbar');
function LeftPanelForThirdLevel({ tableState, customState: { eventType: gridEventType } }: Props) {
  const actualType = useMemo(
    () =>
      eventTypes.filter(event => event.code === gridEventType).length
        ? eventTypes.filter(event => event.code === gridEventType)[0]
        : eventTypes[0],
    [gridEventType],
  );
  const {
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    eventTypeCode,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    organizationName,
  } = useController({ tableState, actualType });

  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={eventTypeCode}
          />
        </div>
      )}
      {isViewFormOpen && (
        <modalTemplates.EventViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.EventEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.EventAddTemplate.Component
          eventTypeCode={eventTypeCode}
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранное мероприятие? (${tableState.selectedRows[0]?.EventName})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ConfirmPopup
        title="Помощь в работе с выставками"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">Раздел Выставки</strong> предназначен для поддержки отчетности по научно-техническим,
            образовательным, художественным выставкам и результатам участия сотрудников, студентов и аспирантов в выставках.
          </p>
          <p>
            <strong className="primary">В разделе Выставки вы можете:</strong>
          </p>
          <ul>
            <li>
              Просмотреть запись о выставке и детальную информацию на всех вкладках с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.loop} hasNoHover />
            </li>
            <li>
              Добавить новую выставку с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover />
            </li>
            <li>
              Редактировать выставку в статусе Добавлена с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.edit} hasNoHover />
              &nbsp;при наличии прав для редактирования
            </li>
            <li>
              Удалить запись о выставке, которую вы создавали, если она в статусе Добавлена с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.delete} hasNoHover />
            </li>
            <li>
              Выгрузить список выставок в файл Excel с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
            </li>
            <li>Внести показатели результативности выставки на вкладке «Организация мероприятия»</li>
            <li>
              Добавить информацию об участниках выставки, указать связь с подразделением и информацию о полученных наградах на
              вкладке «Участники»
            </li>
          </ul>
          <p>
            <strong className="primary">Важно</strong>
          </p>
          <p>
            При сохранении записи о мероприятии присваивается статус <strong>Добавлена</strong>.
          </p>
          <p>
            На вкладке «Документы» обязательно прикрепите подтверждающий документ. Ответственное лицо проверит и утвердит
            мероприятие.
          </p>
          <p>
            Если университет является организатором или соорганизатором мероприятия, то все вкладки на форме обязательны к
            заполнению.
          </p>
          <p>
            Для добавления участия в мероприятии, организованного другой организацией, необходимо заполнить обязательные поля на
            вкладке "О мероприятии" и прикрепить подтверждающие документы на вкладке "Документы".
          </p>
          <ul>
            <li>
              <strong>Фильтр «Мои выставки»</strong> содержит список выставок, в которых вы указаны в составе Оргкомитета или
              являетесь участником выставки. Если вы создавали запись о выставке, но не являетесь участником оргкомитета или
              участником выставки, то выставка будет отображаться в другом разделе.
            </li>
            <li>
              <strong>Фильтр «Выставки подразделения»</strong> содержит список выставок, связанных с вашим подразделением.
            </li>
            <li>
              <strong>Фильтр «Выставки {organizationName}»</strong> содержит список конференций, в которых организатором или
              соорганизатором является университет.
            </li>
            <li>
              <strong>Фильтр «Другие выставки»</strong> показывает список выставок других организаций, в которых принимают участие
              сотрудники, аспиранты и студенты университета.
            </li>
            <li>
              <strong>Фильтр «Показывать выставки за год»</strong> формирует список выставок, сроки проведения которых содержат
              указанный год. По умолчанию выбран текущий год.
            </li>
          </ul>
          <div>Добавленное вами мероприятие проверит и утвердит Администратор данных.</div>
          <div>
            Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору&nbsp;
            <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
          </div>
        </div>
      </ConfirmPopup>
      {gridEventType && <EventParticipationTemplate controllerIsForm={controllerIsForm} eventTypeName={gridEventType} />}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
