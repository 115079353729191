import React from 'react';

import { Performer } from 'types/models/ContestRequest';
import { InputSelect, FormComponent } from 'components';
import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';

type Props = {
  entry: Performer | null;
  onFieldChange(row: Performer | null): void;
  nameTooltip?: string;
  roleTooltip?: string;
};

function PerformerInputs({ entry, onFieldChange, nameTooltip, roleTooltip }: Props) {
  const { handlePersonChange, handleRoleChange, roleOptions } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired tooltip={nameTooltip}>
          <SelectPerson member={entry} onUpdateMember={val => handlePersonChange()(val)} />
        </FormComponent.Field>
        {entry?.role?.value !== 'LEADER' && (
          <FormComponent.Field label="Роль" isRequired tooltip={roleTooltip}>
            <InputSelect
              options={roleOptions}
              value={roleOptions.find(x => x.value === entry?.role?.value) ?? null}
              onSelectChange={val => handleRoleChange()(val)}
            />
          </FormComponent.Field>
        )}
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { PerformerInputs };
