import { useCallback, useMemo, useState } from 'react';
import { Permits } from 'utils/Permissions/Permits';
import { Table } from 'types/models';
import { buttonIcons, IconButtonProps, ReferenceItem } from 'components';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useBackendAPI } from 'services/BackendAPI';

export function useController(tableState: Table.State) {
  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({
    reports: [Reports.DepartmentConferenceReport],
  });
  const { userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const { methods: AcceptEventProject } = useBackendAPI('AcceptEventProject');
  const { methods: AcceptParticipationProject } = useBackendAPI('AcceptParticipationProject');
  const selectedRowsAccepted = tableState.selectedRows[0]?.IsAccepted === 'true';
  const isAcceptButtonDisabled = !(tableState.selectedRows.length === 1);

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const isParticipation = useMemo<boolean>(() => {
    return selectedRow?.Entity === 'PARTICIPATION';
  }, [selectedRow?.Entity]);

  const isViewPermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`${isParticipation ? `PARTICIPATION_` : ''}${selectedRow?.['id:EventType']}_VIEW` as keyof Permits],
    );
  }, [isParticipation, selectedRow, userPermission]);

  const event = useMemo<ReferenceItem>(() => {
    return {
      id: selectedRow?.Entity === 'EVENT' ? selectedRow?.id : '',
      label: selectedRow?.Value ?? '',
    };
  }, [selectedRow?.Entity, selectedRow?.Value, selectedRow?.id]);

  const onClickPrint = useCallback(() => {
    handleSetCurrentReport({ name: Reports.DepartmentConferenceReport.caption, value: Reports.DepartmentConferenceReport.name });
  }, [handleSetCurrentReport]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow || !isViewPermitted,
        onClick: handleViewButtonClick,
        permissionName: [
          Permits.CONCERT_VIEW,
          Permits.CONFERENCE_VIEW,
          Permits.CONCERT_VIEW,
          Permits.EXPOSITION_VIEW,
          Permits.PARTICIPATION_CONCERT_VIEW,
          Permits.PARTICIPATION_CONFERENCE_VIEW,
          Permits.PARTICIPATION_CONCERT_VIEW,
          Permits.PARTICIPATION_EXPOSITION_VIEW,
        ],
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        isDisabled: !event.id,
        onClick: onClickPrint,
      },
      {
        icons: buttonIcons.like,
        title: 'Принять к отчету',
        isDisabled: isAcceptButtonDisabled || selectedRowsAccepted,
        onClick: () => {
          if (isParticipation) {
            AcceptParticipationProject.callAPI(
              { id: tableState.selectedRows[0]?.ParticipationProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push();
                },
              },
            );
          } else {
            AcceptEventProject.callAPI(
              { id: tableState.selectedRows[0]?.EventProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push();
                },
              },
            );
          }
        },
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отменить принятие к отчету',
        isDisabled: isAcceptButtonDisabled || !selectedRowsAccepted,
        onClick: () => {
          if (isParticipation) {
            AcceptParticipationProject.callAPI(
              { id: tableState.selectedRows[0]?.ParticipationProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push();
                },
              },
            );
          } else {
            AcceptEventProject.callAPI(
              { id: tableState.selectedRows[0]?.EventProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push();
                },
              },
            );
          }
        },
      },
    ],
    [
      selectedRow,
      isViewPermitted,
      handleViewButtonClick,
      event.id,
      onClickPrint,
      isAcceptButtonDisabled,
      selectedRowsAccepted,
      isParticipation,
      AcceptParticipationProject,
      tableState.selectedRows,
      tableStreams.reloadTable,
      AcceptEventProject,
    ],
  );
  return {
    isReportOpen,
    isViewFormOpen,
    buttons,
    currentReport,
    event,
    isParticipation,
    onReportClose,
    handleTemplateCloseViewForm,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}
