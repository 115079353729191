import { useMemo, useState, useCallback } from 'react';

import { Program, Table as T } from 'types/models';
import { IconButtonProps, buttonIcons } from 'components';
import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';

import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: T.State;
  handleOpenConfirm?: (confirmText: string) => void;
} & State;

export const useController = ({ tableState, program, getProgram, handleOpenConfirm }: Props) => {
  const selectedRowsLength = tableState.selectedRows.length;
  const tableStreams = useLocalTableStreams();

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  const { methods: getReferenceElementsAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const { methods: saveProgramAPI } = BackendAPI.useBackendAPI('SaveProgram');

  const handleSubmitAddForm = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      if (program && row) {
        getReferenceElementsAPI.callAPI(
          { childIds: [row.id], filters: [], referenceName: 'RefResultIndex' },
          {
            onSuccessfullCall: ({ data }: any) => {
              const refItem = data[0];
              const newIndex: Program.Indicator = {
                id: null,
                firstYearPlan: '',
                secondYearPlan: '',
                isActive: true,
                refResultItem: { id: refItem.id, label: refItem.label },
              };
              const newIndices = program.indices;
              const isIndiceExists = (newIndices.filter(ind => ind.refResultItem.id === refItem.id) ?? []).length > 0;
              if (!isIndiceExists) {
                program.indices.push(newIndex);
              }
              const newProgram = { ...program, indices: newIndices };
              saveProgramAPI.callAPI(newProgram, {
                onSuccessfullCall: () => {
                  showNotification({ message: 'Показатель успешно добавлен', theme: 'success' });
                  getProgram.callAPI({ id: program.id });
                  tableStreams.reloadTable.push();
                  setIsAddFormOpen(false);
                },
              });
            },
          },
        );
      }
    },
    [getProgram, getReferenceElementsAPI, program, saveProgramAPI, tableStreams.reloadTable],
  );

  const handleAddButtonClick = useCallback(() => {
    if (handleOpenConfirm && !program) {
      handleOpenConfirm('Для добавления показателя результативности программы необходимо сохранить данные');
    } else {
      setIsAddFormOpen(true);
    }
  }, [handleOpenConfirm, program]);

  const handleCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    if (program && tableState.selectedRows[0]) {
      const selectedIndexId = tableState.selectedRows[0].id;
      const newIndices = program.indices.filter(record => record.refResultItem.id !== selectedIndexId);
      const newProgram = { ...program, indices: newIndices };
      saveProgramAPI.callAPI(newProgram, {
        onSuccessfullCall: () => {
          showNotification({ message: 'Показатель успешно удален', theme: 'success' });
          getProgram.callAPI({ id: program.id });
          tableStreams.reloadTable.push();
        },
      });
    }
  }, [getProgram, program, saveProgramAPI, tableState.selectedRows, tableStreams.reloadTable]);

  const handleActivateButtonClick = useCallback(() => {
    if (program && tableState.selectedRows[0]) {
      const selectedIndexId = tableState.selectedRows[0].id;
      const selectedRecordIndex = program.indices.findIndex(record => record.refResultItem.id === selectedIndexId);
      const newProgram = program;
      newProgram.indices[selectedRecordIndex].isActive = true;
      saveProgramAPI.callAPI(newProgram, {
        onSuccessfullCall: () => {
          showNotification({ message: 'Показатель успешно активирован', theme: 'success' });
          getProgram.callAPI({ id: program.id });
          tableStreams.reloadTable.push();
        },
      });
    }
  }, [getProgram, program, saveProgramAPI, tableState.selectedRows, tableStreams.reloadTable]);

  const handleDeactivateButtonClick = useCallback(() => {
    if (program && tableState.selectedRows[0]) {
      const selectedIndexId = tableState.selectedRows[0].id;
      const selectedRecordIndex = program.indices.findIndex(record => record.refResultItem.id === selectedIndexId);
      const newProgram = program;
      newProgram.indices[selectedRecordIndex].isActive = false;
      saveProgramAPI.callAPI(newProgram, {
        onSuccessfullCall: () => {
          showNotification({ message: 'Показатель успешно деактивирован', theme: 'success' });
          getProgram.callAPI({ id: program.id });
          tableStreams.reloadTable.push();
        },
      });
    }
  }, [getProgram, program, saveProgramAPI, tableState.selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'delete',
        isDisabled: !(selectedRowsLength === 1),
        onClick: handleDeleteButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
      {
        icons: buttonIcons.activation,
        title: 'Активировать',
        code: 'activation',
        isDisabled: !(selectedRowsLength === 1),
        onClick: handleActivateButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
      {
        icons: buttonIcons.deactivation,
        title: 'Деактировать',
        code: 'deactivation',
        isDisabled: !(selectedRowsLength === 1),
        onClick: handleDeactivateButtonClick,
        permissionName: Permits.PROGRAM_EDIT,
      },
    ],
    [handleAddButtonClick, selectedRowsLength, handleDeleteButtonClick, handleActivateButtonClick, handleDeactivateButtonClick],
  );

  return {
    buttons,
    handleCloseAddForm,
    isAddFormOpen,
    handleSubmitAddForm,
  };
};
