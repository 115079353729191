import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { StateRegistrationReport } from 'types/models';
import { convertStateRegistrationReport } from './converters';
import { convertStateRegistrationReportToXML } from './requestConverters';

const configurations = {
  GetProjectStateRegistrationReport: makeBackendAPIConfiguration({
    id: 'GetProjectStateRegistrationReport',
    endpoint: '/msa/service/commands/GetProjectStateRegistrationReport',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetProjectStateRegistrationReport' },
        StateRegistrationReport: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertStateRegistrationReport(response.Response.StateRegistrationReport),
  }),
  SaveProjectStateRegistrationReport: makeBackendAPIConfiguration({
    id: 'SaveProjectStateRegistrationReport',
    endpoint: '/msa/service/commands/SaveProjectStateRegistrationReport',
    convertInputToXMLElement: ({
      stateRegistrationReport,
      projectId,
    }: {
      stateRegistrationReport: StateRegistrationReport;
      projectId?: string;
    }) => ({
      Request: {
        _attr: {
          commandName: 'SaveProjectStateRegistrationReport',
        },
        StateRegistrationReport: { ...convertStateRegistrationReportToXML({ stateRegistrationReport, projectId }) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const projectStateRegistrationReport = { ...configurations };
