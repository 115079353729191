import { makeStream } from 'StreamRx';
import { PublicationCitationSystem } from './types';

export const streams = {
  toggleChangeForm: makeStream(),
  toggleSettingEditing: makeStream<boolean>(),
  toggleResetNeeded: makeStream<boolean>(),
  deletePublicationCitationSystem: makeStream<{ publicationCitationSystemID: string }>(),
  savePublicationCitationSystem: makeStream<PublicationCitationSystem>(),
  saveAndContinuePublicationCitationSystem: makeStream<PublicationCitationSystem>(),
  getPublicationCitationSystem: makeStream<{ publicationCitationSystemID: string; sourceID: string }>(),
};
