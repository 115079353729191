import React, { memo } from 'react';
import { Table } from 'types/models';
import { ConfirmPopup } from 'components';
import { PublicationTypeCode } from 'types/models/Form';
import { DataGrid } from 'features/Table';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import useController from './controller';

type Props = {
  onClose: () => void;
  publicationTypeCode: PublicationTypeCode;
  publicationId: string;
  tableState: Table.State;
};

export const Component = memo(({ onClose, publicationTypeCode, publicationId, tableState }: Props) => {
  const {
    currentSpecification,
    isDeleteWarningPopupOpen,
    handleCloseDeleteWarningPopup,
    handleSubmitDeleteWarningPopup,
  } = useController({ publicationTypeCode, publicationId, tableState, onClose });

  return (
    <>
      <ConfirmPopup
        isOpen={isDeleteWarningPopupOpen}
        title={`Удаление ${PublicationTypes[publicationTypeCode]?.genitiveCaseLabel || 'публикации'}`}
        onConfirm={handleSubmitDeleteWarningPopup}
        onClose={handleCloseDeleteWarningPopup}
        okButtonText="Да"
        icon="warning"
        resetButtonText="Отмена"
      >
        <>
          {`У ${PublicationTypes[publicationTypeCode]?.genitiveCaseLabel || 'публикации'} (ID: ${publicationId}) нет ${
            publicationTypeCode === PublicationTypes.MONOGRAPH.code ? 'глав' : 'статей'
          }.`}
          <br />
          Удалить?
        </>
      </ConfirmPopup>
      {currentSpecification && <DataGrid specification={currentSpecification} />}
    </>
  );
});
