import { parse, isAfter } from 'date-fns';

import { ScientificIndex as S } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { ReferenceItem } from 'components';
import { formatStr } from 'utils/Constants/FormatStr';
import { checkIsReferenceItem } from './helpers';
import { Field } from './types';

export function getValidateErrorMessage(fields: Record<string, Field> = {}) {
  const requiredValuesMsg = checkRequiredValues(fields);
  const dateInvalidMsg = getInvalidDateMessage(fields);
  const yearInvalidMsg = getInvalidYearMessage(fields);

  return [requiredValuesMsg, dateInvalidMsg, yearInvalidMsg].filter(Boolean) as string[];
}

const fieldCodes = ['THEMATIC_CATEGORY', 'QUARTILE', 'PROCENTILE', 'RATING'];
const fieldsToValidate = ['QUARTILE', 'PROCENTILE', 'RATING'];

export const getPostValidateResult = (fields: Record<string, Field> = {}) => {
  const childFieldFields = Object.values(fields);

  const isThematicCategoryRelative =
    childFieldFields.every(child => fieldCodes.some(fieldCode => child.code === fieldCode)) &&
    childFieldFields.length === fieldCodes.length;

  if (isThematicCategoryRelative) {
    const filteredChilds = childFieldFields.filter(field =>
      fieldsToValidate.some(fieldToValidateCode => fieldToValidateCode === field.code),
    );

    const isValid = !filteredChilds.some(field => {
      if (field.type === 'REFERENCE') {
        return Boolean((field.value as ReferenceItem).id);
      }
      return Boolean(field.value);
    });

    return isValid ? `Заполните хотя бы одно поле (${filteredChilds.map(({ name }) => name).join(', ')})` : '';
  }

  return '';
};

export function getInitialValidateErrorMessage({
  citationSystem,
  selectedScientificIndex,
}: {
  selectedScientificIndex: S.ScientificIndex | null;
  citationSystem: CitationSystem | null;
}) {
  const fields = [
    { isValid: Boolean(citationSystem?.id), name: 'Система цитирования' },
    { isValid: Boolean(selectedScientificIndex?.id), name: 'Показатель' },
  ];
  const invalidFields = fields.filter(({ isValid }) => !isValid);

  const message = invalidFields.length
    ? `Обязательные поля: (${invalidFields.map(({ name }) => name).join(', ')}) не заполнены`
    : '';

  return message;
}

function checkRequiredValues(fields: Record<string, Field>) {
  const emptyValuesFields = [...Object.values(fields)].filter(x => {
    if (x.isRequired && checkIsReferenceItem(x.value)) {
      return !x.value?.id && !x.value?.label;
    }
    return x.isRequired && !x.value;
  });

  if (emptyValuesFields.length) {
    const fieldsNames = emptyValuesFields.map(x => x.name);
    const msg = `Обязательные поля: (${fieldsNames.join(', ')}) не заполнены`;
    return msg;
  }
  return '';
}

function getInvalidYearMessage(fields: Record<string, Field>) {
  const yearField = Object.values(fields).find(x => x.code === 'YEAR' && x.value);

  if (!yearField) {
    return null;
  }

  // eslint-disable-next-line
  if (isNaN(Number(yearField.value))) {
    return 'Некорректный год';
  }

  const yearValue = yearField.value;
  const year = Number(yearValue);

  if (year < 2000) {
    return 'Год не должен быть ранее 2000';
  }

  const currentYear = new Date().getFullYear();
  if (year > currentYear) {
    return 'Год не может быть больше текущего';
  }

  return null;
}

function getInvalidDateMessage(fields: Record<string, Field>) {
  const dateField = Object.values(fields).find(x => x.type === 'DATE');
  if (!dateField) {
    return null;
  }

  const dateValue = dateField.value as string;
  const parsedDate = parse(dateValue, formatStr, new Date());
  const year = parsedDate.getFullYear();

  if (year < 2000) {
    return 'Дата не может быть раньше 01.01.2000';
  }

  if (isAfter(parsedDate, new Date())) {
    return 'Дата не должна быть позднее текущей даты';
  }

  return null;
}
