import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Role } from 'types/models';
import { convertRole, convertRoleToXML } from './converters';

const configurations = {
  GetRole: makeBackendAPIConfiguration({
    id: 'GetRole',
    endpoint: '/msa/service/commands/GetRole',
    convertInputToXMLElement: ({ roleId }: { roleId: string }) => ({
      Request: {
        _attr: { commandName: 'GetRole' },
        Role: {
          _attr: { id: roleId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertRole(response.Response.Role),
  }),
  SaveRole: makeBackendAPIConfiguration({
    id: 'SaveRole',
    endpoint: '/msa/service/commands/SaveRole',
    convertInputToXMLElement: (role: Role) => ({
      Request: {
        _attr: { commandName: 'SaveRole' },
        Role: convertRoleToXML(role),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ActivateRolePermissions: makeBackendAPIConfiguration({
    id: 'ActivateRolePermissions',
    endpoint: '/msa/service/commands/ActivateRolePermissions',
    convertInputToXMLElement: ({
      roleId,
      activate,
      permissions,
    }: {
      roleId: string;
      activate: boolean;
      permissions: string[];
    }) => {
      const Permission = permissions.length ? permissions.map(x => ({ _attr: { id: x } })) : {};
      return {
        Request: {
          _attr: { commandName: 'ActivateRolePermissions' },
          Role: {
            _attr: { id: roleId, activate },
            Permission,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const roleConfigurations = { ...configurations };
