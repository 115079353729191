import React, { useCallback, useEffect, useState } from 'react';

import { Button, ButtonMode, TextArea, FormComponent, Modal, UploadFile } from 'components';

import { FileInfo } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  isOpen: boolean;
  submitText: string;
  titleText: string;
  onClose(): void;
  onSubmit?(message: string, file?: FileInfo): void;
  hasFileAttachment?: boolean;
};

export function MessageModal(props: Props) {
  const { isOpen, submitText, titleText, onClose, onSubmit, hasFileAttachment } = props;

  const [message, setMessage] = useState('');

  const [file, setFile] = useState<FileInfo>(EMPTY_FILE);

  useEffect(() => {
    setMessage('');
    setFile(EMPTY_FILE);
  }, [isOpen]);

  const handleSubmit = useCallback(() => {
    onSubmit?.(message, file);
    onClose();
  }, [onClose, onSubmit, message, file]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={titleText} size="medium">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Сообщение">
            <TextArea settings={{ rows: 4, isFixed: true }} value={message} onChange={setMessage} />
          </FormComponent.Field>
        </FormComponent.Line>
        {!!hasFileAttachment && (
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Файл">
              <UploadFile file={file} onChange={setFile} />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text={submitText} onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
