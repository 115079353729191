import React, { memo } from 'react';
import { ConfirmPopup } from 'components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const WarningDraftStatusPopup = memo(({ isOpen, onClose, onConfirm }: Props) => (
  <ConfirmPopup
    title="Предупреждение"
    icon="info"
    text='Публикация будет сохранена в статусе "Черновик".'
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    okButtonText="Продолжить"
    resetButtonText="Отмена"
  />
));

export { WarningDraftStatusPopup };
