import { useState, useCallback, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { useFormContext } from 'features/Form/hooks';
import { ValueOf } from 'types/helpers';
import { OtherProject } from 'types/models';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showErrorsMessages } from 'utils/Common';
import { getMockOtherProject, getPrefilledPerformer } from './getMockModel';
import { validate } from './validate';

type Props = {
  onClose: () => void;
};

const useController = ({ onClose }: Props) => {
  const {
    look: { id, viewMode, editMode },
  } = useFormContext();
  const tableStreams = useLocalTableStreams();

  const { isProfile } = usePrivatePageContext();
  const { currentPerson } = useAppDataContext();

  const [otherProject, setOtherProject] = useState<OtherProject.OtherProject>({ ...getMockOtherProject(), id });
  const [otherProjectErrors, setOtherProjectErrors] = useState<Partial<Record<keyof OtherProject.OtherProject, boolean>>>({});
  const [isHistoryWarningOpen, setIsHistoryWarningOpen] = useState<boolean>(false);

  const { methods: GetOtherProjectAPI, state: GetOtherProjectAPIState } = BackendAPI.useBackendAPI('GetOtherProject');
  const { methods: SaveOtherProjectAPI, state: SaveOtherProjectAPIState } = BackendAPI.useBackendAPI('SaveOtherProject');
  const { methods: SavePersonalOtherProjectAPI, state: SavePersonalOtherProjectAPIState } = BackendAPI.useBackendAPI(
    'SavePersonalOtherProject',
  );

  const saveMethod = useMemo(() => {
    if (isProfile) {
      return SavePersonalOtherProjectAPI;
    }

    return SaveOtherProjectAPI;
  }, [SaveOtherProjectAPI, SavePersonalOtherProjectAPI, isProfile]);

  const { workMode } = useWorkModeHook({ viewMode, editMode });

  const isViewMode = workMode === 'viewMode';

  const isLoadingProject = GetOtherProjectAPIState.kind === 'pending';
  const isSavingProject = SaveOtherProjectAPIState.kind === 'pending';
  const isSavingPersonalProject = SavePersonalOtherProjectAPIState.kind === 'pending';

  const isLoading = useMemo(() => isLoadingProject || isSavingProject || isSavingPersonalProject, [
    isLoadingProject,
    isSavingPersonalProject,
    isSavingProject,
  ]);

  const isDisabled = useMemo(() => isViewMode || isLoading, [isViewMode, isLoading]);

  const makeChangeHandler = useCallback(
    (key: keyof OtherProject.OtherProject) => (value: ValueOf<OtherProject.OtherProject>) => {
      setOtherProject(prevOtherProject => ({ ...prevOtherProject!, [key]: value }));
    },
    [],
  );

  const makeResetErrorHandler = useCallback(
    (key: keyof OtherProject.OtherProject) => () => {
      setOtherProjectErrors(prevOtherProjectErrors => ({ ...prevOtherProjectErrors, [key]: false }));
    },
    [],
  );

  useEffect(() => {
    if (id) {
      GetOtherProjectAPI.callAPI(
        { id },
        {
          onSuccessfullCall: ({ data }) => {
            setOtherProject(data);
          },
        },
      );
    } else if (isProfile) {
      setOtherProject(prevOtherProject => ({ ...prevOtherProject, performer: getPrefilledPerformer(currentPerson) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPerformerHistoryEmpty = useMemo(() => {
    const isCitizenship = Boolean(otherProject.performer?.citizenship);
    const isRank = Boolean(otherProject.performer?.rank);
    const isAcademicRank = Boolean(otherProject.performer?.academicRank);
    const isJob = Boolean(otherProject.performer?.job);
    const isEducation = Boolean(otherProject.performer?.education);

    const isEmpty = !isJob && !isCitizenship && !isAcademicRank && !isRank && !isEducation;
    return isEmpty;
  }, [otherProject.performer]);

  const saveOtherProject = useCallback(() => {
    saveMethod.callAPI(
      {
        otherProject,
      },
      {
        onSuccessfullCall: () => {
          onClose();
          showNotification({ message: 'Проект успешно сохранен', theme: 'success' });
          tableStreams.reloadTable.push();
        },
      },
    );
  }, [onClose, otherProject, saveMethod, tableStreams.reloadTable]);

  const onSubmitHistoryWarning = useCallback(() => {
    setIsHistoryWarningOpen(false);
    saveOtherProject();
  }, [saveOtherProject]);

  const onCloseHistoryWarning = useCallback(() => {
    setIsHistoryWarningOpen(false);
  }, []);

  const onSave = useCallback(() => {
    const validationInfo = validate(otherProject);

    const isValidForm = validationInfo.every(({ isValid }) => isValid);

    if (isValidForm) {
      if (isPerformerHistoryEmpty) {
        setIsHistoryWarningOpen(true);
      } else {
        saveOtherProject();
      }
    } else {
      const invalidInfo = validationInfo.filter(({ isValid }) => !isValid);
      const nextOtherProjectErrors = invalidInfo.reduce<Partial<Record<keyof OtherProject.OtherProject, boolean>>>(
        (accum, current) => ({ ...accum, [current.key]: true }),
        {},
      );

      setOtherProjectErrors(nextOtherProjectErrors);
      showErrorsMessages(invalidInfo.map(({ invalidMessage }) => invalidMessage));
    }
  }, [otherProject, isPerformerHistoryEmpty, saveOtherProject]);

  return {
    otherProject,
    workMode,
    isDisabled,
    isHistoryWarningOpen,
    otherProjectErrors,
    onSubmitHistoryWarning,
    onCloseHistoryWarning,
    onSave,
    makeChangeHandler,
    makeResetErrorHandler,
  };
};

export default useController;
