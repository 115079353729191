import React from 'react';
import { setup } from 'bem-cn';

import { modes, types } from './helpers';
import { Color, ColorBase } from 'constants/colors';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});

const b = block('svg-icon');

type IconEvents = {
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onFocus?(): void;
  onBlur?(): void;
  onClick?(): void;
};

export type Props = IconEvents & {
  type: keyof typeof types;
  mode?: keyof typeof modes;
  size?: number;
  color?: Color | ColorBase;
  hint?: string;
  classMixin?: string | string[];
};

/**
 * Vector icon component (/icons-vector-demo) (old version /icons-demo)
 *
 * @param type - Name of icon (see name on first block of page /icons-vector-demo)
 * @param mode - (optional) Mode of icon (see mode on second block of page /icons-vector-demo)
 * @param size - (optional) Size of element (20 by default)
 * @param color - (optional) Set primary color of component (Color | ColorBase)
 * @param hint - (optional) Tooltip on icon hover
 * @param classMixin - (optional) Mixin class(-es) for external customization
 * @returns JSX
 */
export const Icon = React.memo(({ type, mode, size = 20, color, hint, classMixin, ...restProps }: Props) => {
  const content = <g className={b('content', { color: color })}>{types[type]}</g>;
  const action = mode ? <g className={b('action')}>{modes[mode]}</g> : false;

  return (
    <svg
      className={b({}).mix(classMixin)}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={-1}
      {...(restProps as IconEvents)}
    >
      {hint && <title>{hint}</title>}
      {action ? (
        <>
          <mask id="svg-action-mask" maskUnits="userSpaceOnUse" x="0" y="0" width="100%" height="100%">
            <path d="M0 0H20V11H11V20H0V0Z" />
          </mask>
          <g mask="url(#svg-action-mask)">{content}</g>
          <svg x="12" y="12" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            {action}
          </svg>
        </>
      ) : (
        content
      )}
    </svg>
  );
});
