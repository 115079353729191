import { ReferenceItem } from 'components';
import { Document } from 'types/models';
import { convertFileToXML, getMaybeField } from '.';

export function convertReferenceItemToXML(item: ReferenceItem) {
  return {
    ...(item.id ? { _attr: { id: item.id } } : {}),
  };
}

export function convertDocumentToRequest(document: Document) {
  return {
    _attr: {
      ...(document.id ? { id: document.id } : {}),
      name: document.name,
      date: document.date,
      isShared: document.isShared ? 'true' : 'false',
      isCommonAward: document.isCommonAward ? 'true' : 'false',
      ...getMaybeField('isInfo', document.isInfo),
      ...getMaybeField('partnerDocumentType', document.partnerDocumentType),
      ...getMaybeField('startDate', document.startDate),
      ...getMaybeField('endDate', document.endDate),
      ...getMaybeField('isUnexpirable', document.isUnexpirable),
      ...getMaybeField('isAutoProlongation', document.isAutoProlongation),
      ...getMaybeField('number', document.number),
      ...getMaybeField('responsible', document.responsible),
      ...getMaybeField('responsibleJob', document.responsibleJob),
      ...getMaybeField('amount', document.amount),
      ...getMaybeField('documentType', document.projectDocumentType),
      ...getMaybeField('programId', document.program?.id),
    },
    ...convertFileToXML('File', document.file),
  };
}
