import { useLayoutEffect, useCallback, useState } from 'react';

import { useStream } from 'StreamRx';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { streams as localFormStreams } from 'features/Form/streams';
import { Form, QnaQuestion } from 'types/models';
import { useQnaFormController } from '../qnaFormController';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  onClose: () => void;
};

export const useController = ({ onClose }: Props) => {
  const {
    look: { id, viewMode, hasNoTableInteraction },
  } = useFormContext<Form.QuestionFormLook>();

  const tableStreams = hasNoTableInteraction ? null : useLocalTableStreams();

  const [themeText, setThemeText] = useState<string>('');
  const [questionText, setQuestionText] = useState<string>('');

  const { methods: getQnaQuestion } = BackendAPI.useBackendAPI('GetQnaQuestion');
  const { methods: saveQnaQuestion } = BackendAPI.useBackendAPI('SaveQnaQuestion');

  const updateTable = useCallback(() => {
    if (tableStreams) {
      tableStreams.reloadTable.push();
    }
  }, [tableStreams]);

  const { formFields, fieldsFromModel } = useQnaFormController();

  useStream(
    () => localFormStreams.loadForm,
    ({ currentId }) => {
      loadQnaQuestion(currentId);
    },
    // eslint-disable-next-line
    [],
  );
  const loadQnaQuestion = useCallback(
    (currentId?: string) => {
      if (viewMode && id) {
        getQnaQuestion.callAPI(
          {
            id: currentId || id || null,
          },
          {
            onSuccessfullCall: (result: any) => {
              const qnaQuestion = result.data as QnaQuestion;
              fieldsFromModel(qnaQuestion);
              setQuestionText(qnaQuestion.message);
              setThemeText(qnaQuestion.theme.label);
            },
          },
        );
      }
    },
    [getQnaQuestion, id, viewMode, fieldsFromModel],
  );

  const saveQnaQuestionHandler = useCallback(() => {
    if (viewMode && formFields.answer.value) {
      showNotification({ message: 'Невозможно изменить вопрос на который ответили', theme: 'danger' });
      return false;
    }

    if (!formFields.questionTheme.value) {
      showNotification({ message: 'Укажите тему', theme: 'danger' });
      return false;
    }

    if (!formFields.question.value) {
      showNotification({ message: 'Напишите вопрос', theme: 'danger' });
      return false;
    }

    saveQnaQuestion.callAPI(
      {
        id: undefined,
        themeId: formFields.questionTheme?.value?.id ? (formFields.questionTheme.value?.id as string) : '',
        message: formFields.question?.value as string,
        answerText: undefined,
        questionFile: { ...formFields.questionFile?.value },
        answerFile: undefined,
      },
      {
        onSuccessfullCall: () => {
          showNotification({
            // eslint-disable-next-line max-len
            message: `Ваш запрос отправлен администратору по направлению "${formFields?.questionTheme?.value?.label}". Ответ на ваш вопрос отобразится в Вашем личном кабинете на вкладке "Задать вопрос администраторам системы" в колонке ОТВЕТ`,
            theme: 'success',
          });
          updateTable();
          onClose();
        },
      },
    );
    return true;
  }, [saveQnaQuestion, updateTable, formFields, viewMode, onClose]);

  useLayoutEffect(() => {
    loadQnaQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    questionText,
    themeText,
    id,
    formFields,
    viewMode,
    saveQnaQuestionHandler,
  };
};
