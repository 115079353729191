import React, { useCallback, useMemo, useState } from 'react';

import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, RelationTableModal, FormComponent, Modal } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { GetReferenceElementList, GetMergeReferenceElementList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;
  const { setIsOpenModal, isOpenModal } = customState;
  const { setIsOpenMergeModal, isOpenMergeModal } = customState;
  const [selectedRefName, setSelectedRefName] = useState<string>('');

  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  const row = tableState.selectedRows.length ? tableState.selectedRows[0] : null;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: !tableState.selectedRows.length,
        onClick: () => {
          setIsOpenModal(true);
        },
      },
      {
        icons: buttonIcons.merge,
        title: 'Объединение дубликатов',
        isDisabled: !tableState.selectedRows.length,
        onClick: () => {
          setSelectedRefName(tableState.selectedRows[0].name);
          setIsOpenMergeModal(true);
        },
      },
    ],
    [tableState.selectedRows, setIsOpenModal, setIsOpenMergeModal],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <RelationTableModal
        isOpen={Boolean(isOpenModal && row)}
        onClose={handleCloseModal}
        relationTableModalTitle={row?.caption ?? ''}
        specification={GetReferenceElementList({ requestData: { filters: [], name: row?.name ?? '' } })}
      />
      <Modal
        title="Объединение элементов справочника"
        isOpen={isOpenMergeModal}
        onClose={() => setIsOpenMergeModal(false)}
        size={['extralarge', 'extralarge-height']}
      >
        <FormComponent.Wrapper>
          <DataGrid
            specification={GetMergeReferenceElementList({
              requestData: {
                filters: [],
                name: selectedRefName,
              },
            })}
          />
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export { Toolbar };
