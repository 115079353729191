import { SecurityDocument } from 'types/models';

export function getMockSecurityDocument(documentType: string): SecurityDocument {
  return {
    balanceOrderNumber: 0,
    label: '',
    balancePrice: 0,
    contracts: [],
    documentEndDate: '',
    documentNumber: '',
    documentStartDate: '',
    documents: [],
    domainKnowledges: [],
    grntis: [],
    hiDate: '',
    id: null,
    isRus: false,
    isWorldwide: false,
    intellectualPropertyKind: null,
    intellectualPropertyName: '',
    referat: '',
    lksetss: [],
    loDate: '',
    members: [],
    countries: [],
    ntiEteTechnologies: [],
    ntiMarkets: [],
    ntrStrategies: [],
    oecds: [],
    okveds: [],
    payments: [],
    pnis: [],
    pnmitrs: [],
    pnrs: [],
    priorityTechnologies: [],
    project: null,
    requestDate: '',
    requestSendDate: '',
    documentDate: '',
    documentReceiveDate: '',
    requestNumber: '',
    requestStatus: null,
    scienceBrunches: [],
    scienceDomainInterrests: [],
    stateNumber: '',
    stateRegistrationDate: '',
    technologyPlatforms: [],
    udks: [],
    type: { label: '', value: documentType || '' },
    departments: [],
  };
}
