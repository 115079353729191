import React from 'react';

import { DataGrid } from 'features/Table';
import { GetScientistDissertationManagementList } from 'features/Table/specifications';

type Props = {
  personId: string | null;
};

export function DissertationsDeffence(props: Props) {
  const { personId } = props;

  if (!personId) {
    return null;
  }

  return <DataGrid specification={GetScientistDissertationManagementList({ personId })} hasPersonalMode />;
}
