import React from 'react';

import { FormComponent, Reference, TextArea, TextAreaMode, TextDateTime } from 'components';

import { MobileRequest } from 'types/models';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.UseMaterialObligation | null;
  onFieldChange(row: MobileRequest.UseMaterialObligation | null): void;
};

function UseMaterialObligationFields({ entry, onFieldChange }: Props) {
  const { handleResultChange, handleResultDateChange, handleResultDescriptionChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Результат" isRequired>
        <Reference
          value={entry?.result || null}
          name="RefMobileUseMaterialObligationResult"
          relationTableModalTitle='Справочник "Результаты обязательств использования материалов"'
          onChange={handleResultChange}
        />
      </FormComponent.Field>
      <FormComponent.Field label="Срок исполнения" isRequired>
        <TextDateTime value={entry?.resultDate || ''} onChange={handleResultDateChange} />
      </FormComponent.Field>
      <FormComponent.Field label="Описание результата" isRequired>
        <TextArea
          mode={TextAreaMode.modal}
          settings={{ rows: 3, title: 'Описание результата' }}
          value={entry?.resultDescription || ''}
          onChange={handleResultDescriptionChange}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}

export { UseMaterialObligationFields };
