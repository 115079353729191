import fileDownload from 'js-file-download';
import XLSX from 'xlsx';
import { Column, Entry, RequestColumnData, SortKind } from 'types/models/Table';
import { GetFileDataArguments } from './types';
import { parse } from 'date-fns';
import { formatStr, formatDateTimeStr, formatTimeStr } from 'utils/Constants/FormatStr';
import { Option } from 'components';

export const PAGINATION_OPTIONS: Option<number>[] = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '500', value: 500 },
  { label: '1000', value: 1000 },
  { label: 'Все', value: -1 },
];

export const DEFAULT_SETTING_NAME = 'Default Setting';
const MIN_WIDTH_BY_COLUMN_ROWNUMBER = 70;
const MIN_WIDTH_BY_COLUMN_DEFAULT = 100;

export const NOT_REORDERABLE_COLUMN_NAMES = ['rowNumber'];
export const NOT_RESIZABLE_COLUMN_NAMES = ['rowNumber'];
export const NOT_SORTABLE_COLUMN_NAMES = ['rowNumber'];
export const NOT_FILTERABLE_COLUMN_NAMES = ['rowNumber'];
export const INITIAL_PAGE_SIZE = PAGINATION_OPTIONS[1].value;
export const INITIAL_PAGE_INDEX = 0;
export const MAX_RESIZE_COLUMN_WIDTH = 500;
export const SCROLL_OFFSET = 300;
export const INITIAL_VISIBLE_ITEMS_COUNT = 100;
export const EMPTY_ROW_HEIGHT = 32;

export const BASE_DEBOUNCE_TIME = 500;
export const ADDED_DEBOUNCE_TIME = 1000;
export const BOOLEAN_DEBOUNCE_TIME = BASE_DEBOUNCE_TIME;
export const DATE_DEBOUNCE_TIME = BASE_DEBOUNCE_TIME;
export const ENUM_DEBOUNCE_TIME = BASE_DEBOUNCE_TIME;
export const NUMERIC_DEBOUNCE_TIME = BASE_DEBOUNCE_TIME + ADDED_DEBOUNCE_TIME;
export const TEXT_DEBOUNCE_TIME = BASE_DEBOUNCE_TIME + ADDED_DEBOUNCE_TIME;
export const PAGE_CHANGE_DEBOUNCE_TIME = BASE_DEBOUNCE_TIME;

export const MAX_PAGE_NUMBER = 9999999;
export const ENTER_KEY = 'Enter';
export const SHIFT_KEY = 'Shift';
export const CONTROL_KEY = 'Control';

export const FILTER_STRUCRURE = {
  range: 'RANGE',
  value: 'VALUE',
};

export const checkIsReorderableColumn = (columnName: string): boolean => {
  return !NOT_REORDERABLE_COLUMN_NAMES.some(name => name === columnName);
};

export const checkIsResizableColumn = (columnName: string): boolean => {
  return !NOT_RESIZABLE_COLUMN_NAMES.some(name => name === columnName);
};

export const checkIsSortableColumn = (columnName: string): boolean => {
  return !NOT_SORTABLE_COLUMN_NAMES.some(name => name === columnName);
};

export const checkIsFilterableColumn = (columnName: string): boolean => {
  return !NOT_FILTERABLE_COLUMN_NAMES.some(name => name === columnName);
};

export const getSortKind = (column: Column): SortKind => {
  return {
    true: 'ascending',
    false: 'descending',
    default: 'no-sort',
  }[column.sortAscending || 'default'] as SortKind;
};

export const getRequestColumnData = (columns: Column[]): RequestColumnData[] =>
  columns
    .filter(({ sortOrder, filter, sortAscending }) => Boolean((typeof sortOrder === 'number' && sortAscending) || filter))
    .map(column => ({
      name: column.name,
      type: column.type,
      filterStructure: column.filterStructure,
      sortOrder: column.sortOrder,
      sortKind: getSortKind(column),
      filter: column.filter,
    }));

export const getArrayFromRange = ({ from, to }: { from: number; to: number }) => {
  const result = [];
  const [preparedFrom, preparedTo] = [from, to].sort((a, b) => a - b);

  for (let i = preparedFrom; i <= preparedTo; i += 1) {
    result.push(i);
  }

  return result;
};

type GetNextSelectedRowsArguments = {
  rows: Entry[];
  selectedRows: Entry[];
  uniqKeys?: string[];
};

export const getNextSelectedRowsIndices = ({ rows, selectedRows, uniqKeys = ['id'] }: GetNextSelectedRowsArguments) => {
  const nextSelectedRowsIndices = rows
    .map<number | null>((row, index) => {
      const isSomePrevSelectedRow = selectedRows.some(selectedRow => {
        return uniqKeys.every(uniqKey => {
          if (row[uniqKey] === undefined) {
            return false;
          }
          return row[uniqKey] === selectedRow[uniqKey];
        });
      });

      if (isSomePrevSelectedRow) {
        return index;
      }

      return null;
    })
    .filter(row => row !== null) as number[];
  return nextSelectedRowsIndices;
};

export const isEmptyRow = (row: Entry) => Boolean(row.EMPTY_ROW);

export const getMinWidthByColumn = (column: Column) => {
  if (column.name === 'rowNumber') {
    return MIN_WIDTH_BY_COLUMN_ROWNUMBER;
  }
  return MIN_WIDTH_BY_COLUMN_DEFAULT;
};

const makeXLSXRow = (columns: Column[], entry: Entry) =>
  columns.map(({ name, type }) => {
    const referenceDate = new Date();
    referenceDate.setHours(0);
    referenceDate.setMinutes(0);
    referenceDate.setSeconds(0);
    switch (type) {
      case 'DATE':
        return entry[name] ? parse(entry[name], formatStr, referenceDate) : '';
      case 'TIME':
        return entry[name] ? parse(entry[name], formatTimeStr, referenceDate) : '';
      case 'DATETIME':
        return entry[name] ? parse(entry[name], formatDateTimeStr, referenceDate) : '';
      case 'BOOLEAN':
        return entry[name] === 'true';
      case 'INTEGER':
      case 'DOUBLE':
      case 'MONEY':
        return entry[name] ? parseFloat(entry[name]) : 0;
      default:
        return entry[name] || '';
    }
  });

const ADDITIONAL_WIDTH_SPACE = 2;
const MAX_WIDTH_SPACE = 50;

const getFileData = ({ columns, entries }: GetFileDataArguments) => {
  const XLSXRows = (() => {
    const getDataXLSXRow = (data: Entry) => makeXLSXRow(columns, data);

    return entries.map(getDataXLSXRow);
  })();

  const excelBook = XLSX.utils.book_new();
  excelBook.Props = {
    Title: 'excel sheet',
    CreatedDate: new Date(),
  };

  excelBook.SheetNames.push('initial sheet');
  const sheetData = [columns.map(c => c.caption), ...XLSXRows];
  const excelSheet = XLSX.utils.aoa_to_sheet(sheetData, { cellDates: true });

  const columnsMaxLength: number[] = [];

  sheetData.forEach(row => {
    row.forEach((cellData, index) => {
      columnsMaxLength[index] = Math.max(
        columnsMaxLength[index] || 0,
        Math.min(`${cellData}`.length, MAX_WIDTH_SPACE) + ADDITIONAL_WIDTH_SPACE,
      );
    });
  });

  excelSheet['!cols'] = columnsMaxLength.map(width => ({ width }));
  excelBook.Sheets['initial sheet'] = excelSheet;
  return XLSX.write(excelBook, { bookType: 'xlsx', type: 'binary' });
};

const convertSheetToArrayBuffer = (sheet: string) => {
  const buffer = new ArrayBuffer(sheet.length);
  const views = new Uint8Array(buffer);
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i < sheet.length; i += 1) views[i] = sheet.charCodeAt(i) & 0xff;

  return buffer;
};

export const exportDataAsXLSX = ({ columns, entries }: GetFileDataArguments) => {
  const filteredColumns = columns.filter(({ hidden }) => !hidden);
  const content = getFileData({ columns: filteredColumns, entries });
  fileDownload(new Blob([convertSheetToArrayBuffer(content)]), 'export.xlsx');
};
