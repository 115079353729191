import React, { useCallback, useState, useMemo, useEffect } from 'react';
import block from 'bem-cn';

import { Select, Button, ButtonMode, Checkbox, FormComponent } from 'components';

import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import publicationTypes, { PublicationType } from 'types/models/Publication/publicationTypes';

const b = block('toolbar-form-template');

type Props = {
  onClose: () => void;
  onSelect: (val: PublicationType) => void;
  isElectronic: boolean;
  setIsElectronic: (nextIsElectronic: boolean) => void;
  electronicPublicationType: PublicationType;
  setElectronicPublicationType: (nextElectronicPublicationType: PublicationType) => void;
};

const MagazineAddTemplate: React.FC<Props> = ({
  onClose,
  onSelect,
  isElectronic,
  setIsElectronic,
  electronicPublicationType,
  setElectronicPublicationType,
}) => {
  const [publicationType, setPublicationType] = useState(publicationTypes[0]);

  const onChangePublicationType = (val: string) => {
    setPublicationType(publicationTypes.filter(type => type.label === val)[0]);
  };

  const continueWithSelectedType = () => {
    onSelect(publicationType);
  };

  const renderEntry = (x: string) => (
    <div className={b('item-text')} title={x}>
      {x}
    </div>
  );

  const electronicPublicationTypes = useMemo<PublicationType[]>(
    () => [
      {
        id: '1',
        label: 'Сетевой ресурс',
        labelDeclined: 'Сетевой ресурс',
        text: 'Публикация размещена в сети Интернет',
        code: 'WEB',
      },
      {
        id: '2',
        label: 'Эл. ресурс локального доступа',
        labelDeclined: 'Эл. ресурс локального доступа',
        text: 'Публикация размещена на электронном носителе. Например: CD-диске',
        code: 'LOCAL',
      },
    ],
    [],
  );

  const onChangeElectronicPublicationType = useCallback(
    (val: string) => {
      const selectedItemIndex = electronicPublicationTypes.findIndex(({ label }) => val === label);
      if (selectedItemIndex !== -1) {
        setElectronicPublicationType({ ...electronicPublicationTypes[selectedItemIndex] });
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  useEffect(() => {
    if (publicationType.code === PublicationTypes.PREPRINT_ARTICLE.code) {
      setIsElectronic(true);
    } else {
      setIsElectronic(false);
    }
  }, [publicationType, setIsElectronic]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Тип публикации">
          <Select.Component
            value={publicationType.label}
            onChange={onChangePublicationType}
            entries={publicationTypes
              .filter(e => !e.isHidden)
              .map(e => e.label)
              .sort()}
            renderEntry={renderEntry}
            variant="outlined"
            defaultPlaceholder="Не задан"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <div className={b('wrapper')}>
        {publicationType.text && <p className="field-text">{publicationType.text}</p>}
        {!publicationType.isOnPaperOnly && (
          <div className={b('checkbox')}>
            <Checkbox
              label="Электронное издание, не имеющее печатного аналога"
              checked={!!isElectronic}
              onChange={setIsElectronic}
              isDisabled={publicationType.code === PublicationTypes.PREPRINT_ARTICLE.code}
            />
          </div>
        )}
        {!publicationType.isOnPaperOnly && isElectronic && (
          <div className={b('fields-wrapper')}>
            <div className={b('field')}>
              <div className={`${b('field-select')} ${b('field-select--full-width')}`}>
                <Select.Component
                  value={electronicPublicationType.label}
                  onChange={onChangeElectronicPublicationType}
                  entries={electronicPublicationTypes.map(electronicPulicationType => electronicPulicationType.label)}
                  renderEntry={renderEntry}
                  variant="outlined"
                  defaultPlaceholder="Не задан"
                />
              </div>
            </div>
            {electronicPublicationType.code && <p className="field-text">{electronicPublicationType.text}</p>}
          </div>
        )}
      </div>
      <span>Внимание! От типа публикации зависит состав полей на форме добавления.</span>
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.primary}
          text="Продолжить"
          onClick={continueWithSelectedType}
          isDisabled={isElectronic && !electronicPublicationType.code}
        />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(MagazineAddTemplate);
