import React from 'react';
import { ConfirmPopup, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <ConfirmPopup
        title="Договоры на использование ИС"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        <div className="modal-help">
          <p>
            В таблице отображается список ВСЕХ договоров о распоряжении интеллектуальным правом. Источник информации —
            функциональный модуль <strong>Коммерциализация, Договоры о распоряжении интеллектуальным правом</strong>: на форме
            договора должна быть указана организация в поле <strong>Заключён с</strong>.
          </p>
          <p>
            Если объекты интеллектуальной собственности переданы в качестве активов в уставной фонд предприятия (в случае с МИП
            университета), то в договоре присутствует атрибут <strong>Передано в уставной фонд предприятия</strong>.
          </p>
          <p>
            Детализация о переданных активах в уставной фонд предприятия - в Разделе Учредители при каждом изменении в уставном
            капитале.
          </p>
        </div>
      </ConfirmPopup>
    </>
  );
};
