import { useAppDataContext } from 'features/AppData/context';
import { EnumMap } from 'types/models/Table';
import { Item } from 'types/models/common';

export function getEnum(enumName: string, enumList?: EnumMap): Item[] {
  const val: Item[] = (enumList || useAppDataContext().enumMap)[enumName]?.values || [];
  return val;
}

export function getEnumItem<T>(enumName: string, value: T, enumList?: EnumMap): Item<T> {
  const val: Item = getEnum(enumName, enumList)?.find(x => x.value === value, enumList) || { value: '', label: '' };
  return val as Item<T>;
}

export function getEnumItemLabel(enumName: string, value: string, enumList?: EnumMap): string {
  const val: string = getEnumItem(enumName, value, enumList).label || '';
  return val;
}
