export const COLORS_BORDERS = [
  '#ff6384', // red
  '#36a2eb', // blue
  '#ffce56', // yellow
  '#4bc0c0', // green
  '#9966ff', // purple
  '#ff9f40', // orange
  '#253c78', // violet
  '#5f5449', // brown
  '#dfbe99', // tan
];
export const COLORS = COLORS_BORDERS.map(x => `${x}30`);
