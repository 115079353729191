import React, { useEffect, useState } from 'react';
import { setup } from 'bem-cn';
import { v4 as uuidv4 } from 'uuid';

import { Icon } from 'components';

import { InputAttributes } from 'types/models/common';
import { ColorBase } from 'constants/colors';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('checkbox');

export type Props = InputAttributes & {
  /** @property {string} value - Label for render */
  label?: string;
  /** @property {string} value - Value for checkbox (as key) */
  value?: string;
  /** @property {boolean} value - Value for checked (false by default) */
  checked?: boolean;
  /** @property {function} onChange - (optional) Callback on checkbox changed */
  onChange?(val: boolean): void;
  /** @property {boolean} isDisabled - (optional) Set is disabled checkbox component (false by default) */
  isDisabled?: boolean;
  /** @property {string} hint - (optional) Hint/Tooltip for checkbox component */
  hint?: string;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
};

/**
 * Checkbox component (external/internal control)
 *
 * @param {string} label - (optional) Label for render
 * @param {string} value - (optional) Value for checkbox (as key)
 * @param {boolean} checked - (optional) Value for checked (false by default)
 * @param {function} onChange - (optional) Callback on checkbox changed
 * @param {boolean} isDisabled - (optional) Set is disabled checkbox component (false by default)
 * @param {string} hint - (optional) Hint/Tooltip for checkbox component
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const Checkbox = React.memo(
  ({ label = '', value, checked = false, onChange, isDisabled = false, classMixin, hint, ...restProps }: Props) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val: boolean = !!e.target.checked;
      setIsChecked(val);
      if (onChange !== undefined) onChange(val);
    };

    const name = uuidv4();

    useEffect(() => {
      if (isChecked !== checked) {
        setIsChecked(checked);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    return (
      <div className={b({ 'is-disabled': isDisabled }).mix(classMixin)} data-checked={isChecked}>
        <label className={b('item')} title={hint ?? ''}>
          <input
            type="checkbox"
            name={`checkbox -> ${name}`}
            value={value}
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleChange}
            {...restProps}
          />
          <span className={b('label')}>
            <div className={b('icon')}>
              <Icon type="check" color={ColorBase.clear} />
            </div>
            {label && <span>{label}</span>}
          </span>
        </label>
      </div>
    );
  },
);
