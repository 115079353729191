import * as XML from 'xml-js';
import { Form, Event } from 'types/models';
import { EventParticipation } from 'types/models/Event';
import { makeBackendAPIConfiguration } from './makeBackendAPIConfiguration';
import { getId, getLabel, getText } from './configurations/commonConverters';

export type ReleaseResponseData = {
  success: boolean;
  message: string;
  body?: {
    releaseId: string;
    Year: string;
    Part: string;
    Month: string;
    Number: string;
    PublicationDate: string;
    articleCount: number;
    conferences: Form.Conference[];
    participations: Event.EventParticipation[];
  };
};

export type SaveReleaseResponseData = {
  success: boolean;
  message: string;
};

const convertSaveReleaseResponseXMLElementToOutput = (response: XML.ElementCompact): SaveReleaseResponseData => {
  const output: SaveReleaseResponseData = {
    success: response.Response._attributes.success === 'true',
    message: response.Response._attributes.errorMessage || response.Response._attributes.serverErrorMessage || '',
  };

  return output;
};
function convertParticipations(publication: any): Event.EventParticipation[] {
  const participationsXMLArray = publication.Participations?.Participation;
  const convertParticipation = (participation: any): EventParticipation => {
    return {
      id: getId(participation),
      participationStatus: { label: participation.Status?._attributes?.label || '', value: getText(participation.Status) },
      eventFullName: getText(participation.EventFullName),
      reportType: participation.ReportType?._attributes?.label || '',
      reportTheme: getText(participation.ReportTheme),
      reporter: getText(participation.Reporter),
      reportDate: getText(participation.reportDate),
    };
  };

  return participationsXMLArray
    ? Array.isArray(participationsXMLArray)
      ? participationsXMLArray.map(convertParticipation)
      : [convertParticipation(participationsXMLArray)]
    : [];
}

const convertEvents = (publication: any) => {
  if (!publication.Events || !publication.Events.Event) {
    return [];
  }

  const converter = (event: any): Form.Conference => {
    const startDate = getText(event?.StartDate);
    const endDate = getText(event?.EndDate);
    const countryLabel = getLabel(event?.Country);
    const city = getText(event?.City);

    const eventDateString = startDate && endDate ? ` ${startDate} - ${endDate}` : ` ${startDate}`;
    const eventCountryString = `${countryLabel ? ` ${countryLabel}${city ? '' : '.'}` : ''}`;
    const eventCityString = `${city ? `, ${city}.` : ''}`;

    const fullName = `${event.name}.${eventCountryString}${eventCityString}${eventDateString}`;
    return {
      id: event._attributes.id,
      name: event.Name?._text || '',
      status: event.Status?._attributes?.label || '',
      fullName,
    };
  };

  const system = publication.Events.Event;
  return Array.isArray(system) ? system.map(converter) : [converter(system)];
};

const converGetReleaseResponseXMLElementToOutput = (response: XML.ElementCompact): ReleaseResponseData => {
  const output: ReleaseResponseData = {
    success: response.Response._attributes.success === 'true',
    message: response.Response._attributes.errorMessage || response.Response._attributes.serverErrorMessage || '',
  };

  if (response.Response.MagazineRelease?.Magazine) {
    const { MagazineRelease } = response.Response;

    output.body = {
      releaseId: MagazineRelease._attributes.id || '',
      Year: MagazineRelease.Year._text || '',
      Part: MagazineRelease.Part?._text || '',
      Month: MagazineRelease.Month?._text || '',
      Number: MagazineRelease.Number?._text || '',
      PublicationDate: MagazineRelease.PublicationDate?._text || '',
      conferences: convertEvents(MagazineRelease),
      articleCount: parseInt(MagazineRelease.ArticleCount?._text || 0, 10),
      participations: convertParticipations(MagazineRelease),
    };
  }
  return output;
};

const converters = {
  GetMagazineRelease: converGetReleaseResponseXMLElementToOutput,
  SaveMagazineRelease: convertSaveReleaseResponseXMLElementToOutput,
};

export function makeBackendPublicationReleaseAPIConfiguration<T extends string, Input>(
  command: T,
  convertInputToXMLElement: (input: Input) => XML.ElementCompact,
) {
  return makeBackendAPIConfiguration({
    id: command,
    endpoint: `/msa/service/commands/${command}`,
    convertInputToXMLElement,
    convertResponseXMLElementToOutput: converters[command as keyof typeof converters],
  });
}
