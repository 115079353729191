import React, { useState, useCallback, useMemo } from 'react';
import { addDays, subDays, format, parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, Checkbox, ConfirmPopup, FormComponent, ListEdit, ListEditTable } from 'components';

import { Project } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Fields } from './Fields/Fields';
import { getMockContestRequest } from 'features/Form/looks/project/ProjectForm/helpers';
import { clonePersonHistory, getDate, isDateIntersect } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
import { formatStr } from 'utils/Constants/FormatStr';
import { renderToString } from 'react-dom/server';

type Props = {
  disabled: boolean;
  contestRequests: Project.ContestRequest[];
  setContestRequests(contestRequests: Project.ContestRequest[]): void;
  stages: Project.Stage[];
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
};

const Component: React.FC<Props> = (props: Props) => {
  const { disabled, contestRequests, setContestRequests, stages, setProject } = props;

  const { currentPerson } = useAppDataContext();

  const [isCopyOptionsModalOpen, setIsCopyOptionsModalOpen] = useState<boolean>(false);
  const [isCopyStagesModalOpen, setIsCopyStagesModalOpen] = useState<boolean>(false);
  const [copyOptions, setCopyOptions] = useState<{ isDescription: boolean; isIndex: boolean; isPerformers: boolean }>({
    isDescription: false,
    isIndex: false,
    isPerformers: false,
  });
  const [selectedContestRequest, setSelectedContestRequest] = useState<Project.ContestRequest | null>(null);
  const [selectedStageIndex, setSelectedStageIndex] = useState<number | null>(null);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);

  const { methods: getContestRequestAPI } = BackendAPI.useBackendAPI('GetContestRequest');

  const selectedStage = useMemo(() => {
    if (selectedStageIndex === null) return null;
    return stages[selectedStageIndex];
  }, [selectedStageIndex, stages]);

  const formatStage = useCallback(
    (contest: Project.ContestRequest) => {
      const item = stages.find(x => x.id === contest.projectStageId);
      if (!item) return '';
      const result = `Этап №${item.number} (${item.startDate} - ${item.endDate})`;
      return result;
    },
    [stages],
  );

  const copyButtonClick = useCallback(
    (item: Project.ContestRequest) => {
      setSelectedContestRequest(item);
      const stageIndex = stages.findIndex(
        x => x.startDate === item.requestStageStartDate && x.endDate === item.requestStageEndDate,
      );
      if (stageIndex !== -1) {
        setSelectedStageIndex(stageIndex);
        setIsCopyOptionsModalOpen(true);
      } else setIsCopyStagesModalOpen(true);
    },
    [stages],
  );

  const stopCopyProcedure = useCallback(() => {
    setIsCopyOptionsModalOpen(false);
    setIsCopyStagesModalOpen(false);
    setCopyOptions({ isDescription: false, isIndex: false, isPerformers: false });
    setSelectedContestRequest(null);
    setSelectedStageIndex(null);
  }, []);

  const selectStage = useCallback((stageIndex: number | null) => {
    if (stageIndex === null) return;
    setSelectedStageIndex(stageIndex);
    setIsCopyStagesModalOpen(false);
    setIsCopyOptionsModalOpen(true);
  }, []);

  const ckeckIsOldContainsNew = useCallback(
    (oldStart: string, oldEnd: string) => {
      if (!selectedStage) return false;
      return (
        (getDate(oldStart) <= getDate(selectedStage.startDate) && getDate(oldEnd) > getDate(selectedStage.endDate)) ||
        (getDate(oldStart) < getDate(selectedStage.startDate) && getDate(oldEnd) >= getDate(selectedStage.endDate))
      );
    },
    [selectedStage],
  );

  const copyFromRequestToStage = useCallback(() => {
    if (!copyOptions.isDescription && !copyOptions.isIndex && !copyOptions.isPerformers) {
      showNotification({ message: 'Выберите хотя бы один раздел для копирования', theme: 'danger' });
      return;
    }
    if (selectedStageIndex === null || !selectedStage) {
      showNotification({ message: 'Выберите этап для копирования', theme: 'danger' });
      return;
    }
    if (!selectedContestRequest) {
      showNotification({ message: 'Выберите заявку для копирования', theme: 'danger' });
      return;
    }

    getContestRequestAPI.callAPI(
      { requestId: selectedContestRequest.id ?? '' },
      {
        onSuccessfullCall: ({ data }) => {
          setProject(prevState => {
            const modifiedPerformers: Project.Performer[] = prevState.performers.map(projectPerformer => {
              // поиск исполнителя из заявки с той же персоной и ролью
              const relatedRequestPerformer = data.requestStagePerformers.find(
                requestPerformer =>
                  requestPerformer.person?.id === projectPerformer.person?.id &&
                  projectPerformer.jobPeriods.find(x => x.role?.value === requestPerformer.role?.value),
              );
              // если связанного исполнителя из заявки нету, то старый остается как есть
              if (!relatedRequestPerformer) return projectPerformer;
              // если связанный исполнитель есть, то в существующего исполнителя из проекта заносятся данные из исполнителя заявки
              return {
                ...projectPerformer,
                stages: [
                  ...projectPerformer.stages,
                  // если этапа с таким id не существует создает новый. Должен оставаться старый этап или вместо него новый?
                  !projectPerformer.stages.find(x => x.stage?.id === selectedStage?.id) && {
                    id: null,
                    stage: selectedStage,
                    approved: false,
                    approvements: [],
                    payments: [],
                    paymentMethods: '',
                  },
                ].filter(x => x) as Project.PerformerStage[],
                jobPeriods: [
                  ...projectPerformer.jobPeriods.map(jobPeriod => {
                    // если новый период полностью захватывает старый, то старый удаляется
                    if (
                      getDate(jobPeriod.startDate) >= getDate(selectedStage.startDate) &&
                      getDate(jobPeriod.endDate) <= getDate(selectedStage.endDate)
                    )
                      return null;
                    else if (
                      // если старый период полностью захватывает новый, то новый не добавляется. Нужно ли изменять старый?
                      ckeckIsOldContainsNew(jobPeriod.startDate, jobPeriod.endDate) ||
                      // периоды не пересекаются
                      !isDateIntersect(jobPeriod.startDate, jobPeriod.endDate, selectedStage.startDate, selectedStage.endDate)
                    )
                      return jobPeriod;
                    // если существующий период работы пересекается с новым, то даты старого сдвигаются
                    else if (
                      getDate(jobPeriod.startDate) <= getDate(selectedStage.startDate) &&
                      getDate(jobPeriod.endDate) <= getDate(selectedStage.endDate)
                    )
                      return {
                        ...jobPeriod,
                        endDate: String(format(subDays(getDate(selectedStage.startDate), 1), formatStr)),
                      };
                    // если существующий период работы пересекается с новым, то даты старого сдвигаются
                    else if (
                      getDate(jobPeriod.startDate) >= getDate(selectedStage.startDate) &&
                      getDate(jobPeriod.endDate) >= getDate(selectedStage.endDate)
                    )
                      return {
                        ...jobPeriod,
                        startDate: String(format(addDays(getDate(selectedStage.endDate), 1), formatStr)),
                      };
                  }),
                  // если старый период не захватывает новый, то создается новый период
                  !projectPerformer.jobPeriods.find(jobPeriod =>
                    ckeckIsOldContainsNew(jobPeriod.startDate, jobPeriod.endDate),
                  ) && {
                    id: null,
                    role: relatedRequestPerformer.role,
                    person: relatedRequestPerformer.person,
                    note: '',
                    startDate: selectedStage?.startDate,
                    endDate: selectedStage?.endDate,
                    ...clonePersonHistory(relatedRequestPerformer),
                  },
                ].filter(x => x) as Project.JobPeriod[],
              };
            });

            const newPerformers: Project.Performer[] = data.requestStagePerformers
              .filter(
                requestPerformer =>
                  !prevState.performers.find(
                    projectPerformer =>
                      requestPerformer.person?.id === projectPerformer.person?.id &&
                      projectPerformer.jobPeriods.find(x => x.role?.value === requestPerformer.role?.value),
                  ),
              )
              .map(x => ({
                ...x,
                fio: x.person?.fullName || '',
                jobPeriods: [
                  {
                    id: null,
                    role: x.role,
                    person: x.person,
                    note: '',
                    startDate: selectedStage?.startDate,
                    endDate: selectedStage?.endDate,
                    ...clonePersonHistory(x),
                  },
                ],
                stages: [
                  {
                    id: null,
                    stage: selectedStage,
                    approved: false,
                    approvements: [],
                    payments: [],
                    paymentMethods: '',
                  },
                ],
                createdBy: currentPerson?.shortName || '',
                createdDate: format(new Date(), formatStr),
                paymentMethods: '',
              }));

            const modifiedStages = (() => {
              const newStages = prevState.stages;
              newStages[selectedStageIndex] = {
                ...prevState.stages[selectedStageIndex],
                ...(copyOptions.isDescription && {
                  name: data.requestStageName,
                  annotation: data.requestStageAnnotation,
                  goal: data.requestStageGoal,
                  task: data.requestStageWorkPlan, // возможно неверное соответствие полей, сделано так в рамках 459 задачи
                  expectedResults: data.requestStageExpectedResults,
                  workDescription: data.requestStageAdditionalInformation,
                  isPatentResearchPlanned: data.isRequestStageHasPatentResearch,
                  universityEffect: data.requestStageUniversityEffect,
                  regionalEffect: data.requestStageRegionalEffect,
                  nationalEffect: data.requestStageNationalEffect,
                }),
                ...(copyOptions.isIndex && {
                  indicators: [
                    ...prevState.stages[selectedStageIndex].indicators.map(projectIndicator => {
                      const relatedIndicator = data.requestStageIndicators.find(
                        requestIndicator => projectIndicator.ref?.id === requestIndicator.resultItem.id,
                      );
                      if (!relatedIndicator) return projectIndicator;
                      return {
                        ...projectIndicator,
                        plan: String(relatedIndicator?.plan || '0.00'),
                        note: relatedIndicator?.note || '',
                      };
                    }),
                    ...data.requestStageIndicators
                      .filter(
                        requestIndicator =>
                          !prevState.stages[selectedStageIndex].indicators.find(
                            projectIndicator => projectIndicator.ref?.id === requestIndicator.resultItem.id,
                          ),
                      )
                      .map(requestIndicator => ({
                        id: null,
                        ref: requestIndicator.resultItem,
                        plan: String(requestIndicator?.plan || '0.00'),
                        fact: '0.00',
                        note: requestIndicator.note,
                        year: requestIndicator.year,
                      })),
                  ],
                  indices: [
                    ...prevState.stages[selectedStageIndex].indices.map(projectIndex => {
                      const relatedIndex = data.requestStageIndices.find(
                        requestIndex => projectIndex.ref?.id === requestIndex.resultItem.id,
                      );
                      if (!relatedIndex) return projectIndex;
                      return {
                        ...projectIndex,
                        plan: String(relatedIndex?.plan || '0.00'),
                        note: relatedIndex?.note || '',
                      };
                    }),
                    ...data.requestStageIndices
                      .filter(
                        requestIndex =>
                          !prevState.stages[selectedStageIndex].indices.find(
                            projectIndex => projectIndex.ref?.id === requestIndex.resultItem.id,
                          ),
                      )
                      .map(requestIndex => ({
                        id: null,
                        ref: requestIndex.resultItem,
                        plan: String(requestIndex?.plan || '0.00'),
                        fact: '0.00',
                        note: requestIndex.note,
                        year: requestIndex.year,
                      })),
                  ],
                }),
              };
              return newStages;
            })();

            const updatedExpectedResults: string = data.requestStageExpectedResults
              ? `${prevState.expectedResults ? `${prevState.expectedResults}\n` : ''}` +
                `На ${parse(data.requestStageStartDate, formatStr, new Date()).getFullYear()} год:\n${
                  data.requestStageExpectedResults
                }`
              : prevState.expectedResults;

            return {
              ...prevState,
              ...(copyOptions.isDescription && { expectedResults: updatedExpectedResults }),
              stages: [...modifiedStages],
              performers: copyOptions.isPerformers ? [...modifiedPerformers, ...newPerformers] : prevState.performers,
              contestRequests: (() => {
                const newContestRequests = prevState.contestRequests;
                const requestIndex = prevState.contestRequests.findIndex(x => x.id === selectedContestRequest.id);
                if (!newContestRequests[requestIndex].newDataCopyDetail) newContestRequests[requestIndex].newDataCopyDetail = '';
                newContestRequests[requestIndex].newDataCopyDetail += `${
                  newContestRequests[requestIndex].newDataCopyDetail?.length ? '\n' : ''
                }${format(new Date(), formatStr)}: ${[
                  !!copyOptions.isDescription && 'Описание этапа',
                  !!copyOptions.isIndex && 'Индикаторы и показатели',
                  !!copyOptions.isPerformers && 'Список исполнителей',
                ]
                  .filter(x => x)
                  .join(', ')} в этап №${selectedStage.number}`;
                newContestRequests[requestIndex].projectStageId = selectedStage.id;
                return newContestRequests;
              })(),
            };
          });
          showNotification({ message: 'Данные скопированы из заявки в проект', theme: 'success' });
          if (copyOptions.isDescription) setIsNotificationModalOpen(true);
          setCopyOptions({ isDescription: false, isIndex: false, isPerformers: false });
          setSelectedContestRequest(null);
          setSelectedStageIndex(-1);
        },
      },
    );
    setIsCopyOptionsModalOpen(false);
  }, [
    ckeckIsOldContainsNew,
    copyOptions.isDescription,
    copyOptions.isIndex,
    copyOptions.isPerformers,
    currentPerson?.shortName,
    getContestRequestAPI,
    selectedContestRequest,
    selectedStage,
    selectedStageIndex,
    setProject,
  ]);

  return (
    <>
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        rows={contestRequests}
        onChange={setContestRequests}
        defaultRowsCount={15}
        visibleToolbarButtons={['edit']}
        maxHeight="100%"
        extraToolbarButtons={[
          {
            icons: buttonIcons.clone,
            title: 'Скопировать из заявки на продление',
            checkIsDisabled: row => !row || disabled,
            onClick: item => copyButtonClick(item!),
          },
        ]}
        columns={[
          { label: 'Заявка (Статус)', formatValue: x => `${x.type?.label} (${x.status?.label})`, styles: { width: '15%' } },
          { label: 'Год заявки', formatValue: x => x.year, styles: { width: '3%' } },
          {
            label: 'Этап проекта',
            formatValue: x => formatStage(x),
            styles: { width: '7%' },
          },
          {
            label: 'Данные выгружены в проект',
            formatValue: x => renderToString(<pre>{[x.dataCopyDetail, x.newDataCopyDetail].filter(y => y).join('\n')}</pre>),
          },
          { label: 'Примечание', formatValue: x => x.projectNote, styles: { width: '15%' } },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (contest, setContest) => (
            <Fields contest={contest! || getMockContestRequest()} setContest={setContest} />
          ),
        }}
      />
      <ConfirmPopup
        title="Выберите разделы для копирования данных из заявки в соответствующий этап проекта"
        isOpen={isCopyOptionsModalOpen}
        onClose={stopCopyProcedure}
        okButtonText="Скопировать"
        onConfirm={copyFromRequestToStage}
        resetButtonText="Отмена"
        onReset={stopCopyProcedure}
        isDisabled={selectedStageIndex === null}
      >
        <div>
          Копирование из заявки ({selectedContestRequest?.requestStageStartDate} - {selectedContestRequest?.requestStageEndDate})
          в этап №{selectedStage?.number} {selectedStage?.name} ({selectedStage?.startDate} - {selectedStage?.endDate})
        </div>
        <FormComponent.Wrapper>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={!!copyOptions.isDescription}
                onChange={checked => setCopyOptions(prevState => ({ ...prevState, isDescription: checked }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Описание этапа</strong> (Данные будут скопированы на форму редактирования этапа на вкладке "Календарный
              план")
            </span>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={!!copyOptions.isIndex}
                onChange={checked => setCopyOptions(prevState => ({ ...prevState, isIndex: checked }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Индикаторы и показатели</strong> (Плановые значения показателей и индикаторов на этап будут скопированы в
              раздел на вкладке "Результаты")
            </span>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={!!copyOptions.isPerformers}
                onChange={checked => setCopyOptions(prevState => ({ ...prevState, isPerformers: checked }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Список исполнителей</strong> (Обновление списка исполнителей с периодом работы в этапе на вкладке
              "Коллектив")
            </span>
          </FormComponent.Line>
        </FormComponent.Wrapper>
        <span>
          <strong>Внимание: При копировании имеющиеся значения из списка копирования в проекте будут удалены!</strong>
        </span>
      </ConfirmPopup>
      <ConfirmPopup
        title="Этап со сроками идентичными сроку заявки отсутствует. Выберите этап вручную"
        isOpen={isCopyStagesModalOpen}
        onClose={stopCopyProcedure}
        okButtonText="Выбрать"
        onConfirm={() => selectStage(selectedStageIndex)}
        resetButtonText="Отмена"
        onReset={stopCopyProcedure}
      >
        <ListEditTable
          rows={stages}
          columns={[
            { label: 'Номер', formatValue: row => row?.number || '', styles: { width: '10%' } },
            { label: 'Название', formatValue: row => row?.name || '' },
            { label: 'Период', formatValue: row => `${row?.startDate} - ${row?.endDate}` },
          ]}
          onDoubleRowClick={selectStage}
          selectedRowIndex={selectedStageIndex}
          selectRow={setSelectedStageIndex}
        />
      </ConfirmPopup>
      <ConfirmPopup
        title="Предупреждение"
        isOpen={isNotificationModalOpen}
        onClose={() => setIsNotificationModalOpen(false)}
        okButtonText="Понятно"
        onConfirm={() => setIsNotificationModalOpen(false)}
        text="Отредактируйте ожидаемые результаты вручную и удалите ненужные пункты"
      />
    </>
  );
};

export const ProlongateRequests = React.memo(Component) as typeof Component;
