import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'planned',
      label: 'Планируемые',
      checked: false,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'filter',
      list: [
        { label: 'Все', value: '0' },
        { label: 'Организованные', value: '1' },
        { label: 'Принято участие', value: '2' },
      ],
      value: '0',
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});
