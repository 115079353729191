import React, { useEffect, useMemo, useState } from 'react';

import {
  FormComponent,
  ListEdit,
  Tabs,
  Tab,
  Reference,
  SectionTitle,
  InputSelect,
  RefList,
  Toolbar,
  ConfirmPopup,
  Modal,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';

import { Documents } from 'features/Documents';
import { GetMobileRequestFeedlineList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { SelectPerson } from 'features/SelectPerson';
import { showNotification } from 'features/Notifications';
import { Form } from 'features/Form';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { convertMobileRequestToMember, getFinancingSourceLabel } from './helpers';

import { ApplicantSupervisorFields } from './ApplicantSupervisorFields';
import { RouteFields } from './RouteFields';
import { TripPlanFields } from './TripPlanFIelds';
import { UseMaterialObligationFields } from './UseMaterialObligationFields';
import { EstimatePositionFields } from './EstimatePositionFields';
import { FinancingFields } from './FinancingFields';
import { validateFinancing } from './validators';
import { useFormContext } from 'features/Form/hooks';
import { MobileRequestFormLook } from 'types/models/Form';
import { AboutTender } from 'features/Form/looks/programRequest/ProgramRequestForm/Tabs/AboutTender';
import { formatEducation, formatJob, getAcademicRankOption, getOption } from 'features/SelectPerson/helpers';
import { getEnumItemLabel } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';
import { MobileRequestPurpose } from 'utils/Enums';

type Props = {
  onClose: () => void;
};

function MobileRequestViewForm({ onClose }: Props) {
  const {
    mobileRequest,
    editMode,
    viewMode,
    handleDocumentsChange,
    handleApplicantChange,
    handleApplicantDepartmentChange,
    handleApplicantContactChange,
    handleApplicantLanguageCompetenceChange,
    handleApplicantNoteChange,
    handleApplicantSupervisorsChange,
    handleBusinessTripRangeDateChange,
    handleTraineeRangeDateChange,
    handleRoutesChange,
    purposeOptions,
    handlePurposeChange,
    handleActivityKindChange,
    handleVisaChange,
    handleThemeChange,
    handleLanguageChange,
    handleTraineeNecessityDocumentChange,
    handlePnrsChange,
    organizationName,
    handleTripPlanesChange,
    handleUseMaterialObligationsPlanesChange,
    handleAdvertisingMaterialTypesChange,
    traineeDays,
    businesTripDays,
    handleEstimatePositionsChange,
    handleFinancingsChange,
    type,
    estimateMessage,
    toolbarButtons,
    isStatusChangePopupOpen,
    handleStatusChangePopupConfirm,
    handleStatusChangePopupClose,
    statusChangeMessage,
    handleStatusChangeMessageChange,
    statusChangePopupTitle,
    statusChangePopupConfirmLabel,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    isPersonViewFormOpen,
    handlePersonViewFormClose,
    isReportOpen,
    onReportClose,
    currentReport,
    isMobileProtocolProjectPopupOpen,
    handleMobileProtocolProjectPopupConfirm,
    handleMobileProtocolProjectPopupClose,
    handleEducationYearChange,
    handleEducationGroupChange,
    themeFieldLabel,
    handleDescriptionChange,
    handleCodeChange,
    handleSendDateChange,
    handleTenderParticipantChange,
    handleScienceDomainInterrestChange,
    status,
    makeChangeHandler,
  } = useController({
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext<MobileRequestFormLook>();

  const { enumMap, userPermission } = useAppDataContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          return [
            viewMode ? 'Просмотр' : editMode ? 'Редактирование' : 'Добавление',
            'заявки по академической мобильности',
            mobileRequest.id ? `(ID ${mobileRequest.id})` : '',
            mobileRequest.status?.value
              ? ` - ${getEnumItemLabel('MobileRequestStatus', mobileRequest.status.value, enumMap)}`
              : '',
          ]
            .filter(x => x)
            .join(' ');
        })(),
      );
    }
  }, [editMode, enumMap, mobileRequest.id, mobileRequest.status?.value, setTitle, viewMode]);

  const [titlePerson, setTitlePerson] = useState<string | JSX.Element>('');

  const applicantLanguageCompetenceTooltip =
    // eslint-disable-next-line max-len
    'Указывается уровень владения иностранными языками. Если имеется сертификат/диплом, подтверждающий знание иностранного языка, указывается его название, уровень владения/средний балл диплома, кем и когда выдан';

  const destinationSupervisorFieldsVisible = useMemo(() => {
    return mobileRequest.purpose?.value === MobileRequestPurpose.TRAINEE;
  }, [mobileRequest]);

  return (
    <>
      <FormComponent.Template>
        <Toolbar buttons={toolbarButtons} mode="form" />

        <FormComponent.Template>
          <Tabs>
            <Tab title="О конкурсе">
              <AboutTender tender={mobileRequest?.tender || null} lot={mobileRequest?.lot || null} />
            </Tab>
            <Tab
              title="Заявка"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`MOBILE_REQUEST_EDIT_${status}_REQUEST`])}
            >
              <FormComponent.ColumnWrapper>
                <FormComponent.Column>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field
                      label="Шифр заявки"
                      tooltip={
                        // eslint-disable-next-line max-len
                        'Шифр заявки в ИАС Организатора конкурса. Заполняется после регистрации заявки в ИАС Организатора конкурса'
                      }
                    >
                      <TextInput
                        settings={{ maxLength: 255 }}
                        value={mobileRequest.code}
                        onChange={handleCodeChange}
                        isDisabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="padded">
                    <FormComponent.Field label="Подана на конкурс" tooltip="Дата регистрации заявки в ИАС Организатора конкурса ">
                      <TextDateTime value={mobileRequest.sendDate} onChange={handleSendDateChange} isDisabled={viewMode} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Цель командировки" isRequired>
                      <InputSelect
                        options={purposeOptions}
                        value={mobileRequest.purpose}
                        onSelectChange={handlePurposeChange}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Вид деятельности" isRequired>
                      <Reference
                        value={mobileRequest.activityKind}
                        name="RefMobileActivityKind"
                        relationTableModalTitle='Справочник "Виды деятельности"'
                        onChange={handleActivityKindChange}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Направление деятельности" isRequired>
                      <Reference
                        value={mobileRequest.visa}
                        name="RefMobileRequestVisa"
                        // eslint-disable-next-line max-len
                        relationTableModalTitle='Справочник "Должностные лица, подписывающие документы в заявках по академической мобильности"'
                        onChange={handleVisaChange}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="padded">
                    <FormComponent.Field label={themeFieldLabel} isRequired>
                      <TextArea
                        mode={TextAreaMode.modal}
                        settings={{ rows: 3, maxLength: 512, title: themeFieldLabel }}
                        value={mobileRequest.theme}
                        onChange={handleThemeChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="padded">
                    <FormComponent.Field label="Краткое описание">
                      <TextArea
                        mode={TextAreaMode.modal}
                        settings={{ rows: 3, title: 'Краткое описание' }}
                        value={mobileRequest.description}
                        onChange={handleDescriptionChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field
                      label="Рабочий язык"
                      // eslint-disable-next-line max-len
                      tooltip="Указывается рабочий язык мероприятия(стажировки) в соответствии с информационным сообщением о мероприятии"
                    >
                      <Reference
                        value={mobileRequest.language}
                        name="RefLanguage"
                        relationTableModalTitle='Справочник "Языки"'
                        onChange={handleLanguageChange}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field
                      label="Документ-основание"
                      // eslint-disable-next-line max-len
                      tooltip="Документ, подтверждающий необходимость стажировки (приглашение принимающей стороны, договор о двойной магистратуре/ аспирантуре/ о программе включенного обучения)"
                    >
                      <TextInput
                        value={mobileRequest.traineeNecessityDocument}
                        onChange={handleTraineeNecessityDocumentChange}
                        isDisabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  {destinationSupervisorFieldsVisible && (
                    <>
                      <FormComponent.Line lineSize="doublePadded">
                        <FormComponent.Field label="Руководитель принимающей стороны">
                          <TextInput
                            value={mobileRequest.destinationSupervisor}
                            onChange={makeChangeHandler('destinationSupervisor')}
                            isDisabled={viewMode}
                          />
                        </FormComponent.Field>
                      </FormComponent.Line>
                      <FormComponent.Line lineSize="doublePadded">
                        <FormComponent.Field label="Хар-ка руководителя">
                          <TextInput
                            value={mobileRequest.destinationSupervisorSpecifics}
                            onChange={makeChangeHandler('destinationSupervisorSpecifics')}
                            isDisabled={viewMode}
                          />
                        </FormComponent.Field>
                      </FormComponent.Line>
                    </>
                  )}
                  <FormComponent.Line>
                    <FormComponent.Field label="Область наук">
                      <Reference
                        value={mobileRequest.scienceDomainInterrest}
                        name="RefScienceDomainInterest"
                        relationTableModalTitle="Область наук"
                        onChange={handleScienceDomainInterrestChange}
                        filters={[
                          {
                            key: 'id',
                            values: mobileRequest.tender?.scienceDomainInterrests?.map(x => x.id).filter(x => !!x) || [],
                          },
                        ]}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field>
                      <RefList
                        modalTitle="Приоритетные развития науки университета"
                        refName="RefPnr"
                        rows={mobileRequest.pnrs}
                        onChange={handlePnrsChange}
                        title={`Приоритетные направления развития ${organizationName}`}
                        // eslint-disable-next-line max-len
                        tooltip="Выберите приоритетное направление развития университета (ПНР) и стратегическую экономическую единицу (САЕ), к которым имеет отношение тема вашей поездки"
                        isRequired
                        disabled={viewMode}
                        visibleToolbarButtons={['add', 'delete']}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Field>
                    <ListEdit
                      rows={mobileRequest.routes}
                      onChange={handleRoutesChange}
                      isDeleteConfirmEnabled
                      title="Место проведения мероприятия"
                      withMessages
                      maxHeight="25vh"
                      defaultRowsCount={3}
                      isToolbarDisabled={viewMode}
                      isRequired
                      columns={[
                        {
                          label: 'Принимающая организация',
                          formatValue: row => row.enterprise?.label || '',
                          styles: { width: '65%' },
                        },
                        {
                          label: 'Страна/город',
                          formatValue: row => [row.country?.label, row.city].filter(x => x).join(', ') || '',
                          styles: { width: '35%' },
                        },
                      ]}
                      specification={{
                        mode: 'customComponent',
                        validation: {
                          checkIsValid: row => !!row?.city && !!row.country?.id,
                          onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                        },
                        renderComponent: (row, onChange) => <RouteFields entry={row} onFieldChange={onChange} />,
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Column>
                <FormComponent.Column>
                  <SectionTitle title="Сроки" />
                  <FormComponent.Line>
                    <FormComponent.Field
                      label="Период командировки"
                      tooltip="Указываются планируемые даты отъезда и возвращения"
                      isRequired
                    >
                      <TextDateTime
                        isRange
                        value={`${mobileRequest.businessTripStartDate}-${mobileRequest.businessTripEndDate}`}
                        onChange={(value: string) => {
                          const dates = value.split('-');
                          handleBusinessTripRangeDateChange(dates[0] || '', dates[1] || '');
                        }}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Сроки мероприятия" isRequired>
                      <TextDateTime
                        isRange
                        value={`${mobileRequest.traineeStartDate}-${mobileRequest.traineeEndDate}`}
                        onChange={(value: string) => {
                          const dates = value.split('-');
                          handleTraineeRangeDateChange(dates[0] || '', dates[1] || '');
                        }}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line hasFreeFormat>
                    <FormComponent.Field label="Количество дней" fieldSize="fit">
                      <span>{traineeDays}</span>
                    </FormComponent.Field>
                    <FormComponent.Field label="Количество часов" fieldSize="fit">
                      <span>{traineeDays ? traineeDays * 6 : null}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Field>
                    <ListEdit
                      rows={mobileRequest.tripPlanes}
                      onChange={handleTripPlanesChange}
                      isDeleteConfirmEnabled
                      title="План поездки"
                      withMessages
                      maxHeight="30vh"
                      defaultRowsCount={5}
                      isToolbarDisabled={viewMode}
                      isRequired
                      columns={[
                        { label: 'Перечень разрабатываемых (изучаемых) вопросов', formatValue: row => row.question || '' },
                        { label: 'Форма реализации', formatValue: row => row.implementationForm || '' },
                      ]}
                      specification={{
                        mode: 'customComponent',
                        validation: {
                          checkIsValid: row => !!row?.question && !!row?.implementationForm,
                          onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                        },
                        renderComponent: (row, onChange) => <TripPlanFields entry={row} onFieldChange={onChange} />,
                      }}
                    />
                  </FormComponent.Field>
                  <FormComponent.Field>
                    <ListEdit
                      rows={mobileRequest.useMaterialObligations}
                      onChange={handleUseMaterialObligationsPlanesChange}
                      isDeleteConfirmEnabled
                      title="Описание планируемых результатов по итогам поездки"
                      withMessages
                      maxHeight="30vh"
                      defaultRowsCount={5}
                      isToolbarDisabled={viewMode}
                      isRequired
                      columns={[
                        { label: 'Результат', formatValue: row => row.result?.label || '', styles: { width: '25%' } },
                        { label: 'Срок исполнения', formatValue: row => row.resultDate || '', styles: { width: '25%' } },
                        {
                          label: 'Описание результата',
                          formatValue: row => row.resultDescription || '',
                          styles: { width: '50%' },
                        },
                      ]}
                      specification={{
                        mode: 'customComponent',
                        validation: {
                          checkIsValid: row => !!row?.result && !!row.resultDate && !!row.resultDescription,
                          onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                        },
                        renderComponent: (row, onChange) => <UseMaterialObligationFields entry={row} onFieldChange={onChange} />,
                      }}
                    />
                  </FormComponent.Field>
                  <FormComponent.Field>
                    <RefList
                      modalTitle='Справочник "Виды информационно-рекламных материалов"'
                      refName="RefMobileAdvertisingMaterialType"
                      rows={mobileRequest.advertisingMaterialTypes}
                      onChange={handleAdvertisingMaterialTypesChange}
                      title="Необходимые виды информационно-рекламных материалов"
                      disabled={viewMode}
                      visibleToolbarButtons={['add', 'delete']}
                      maxHeight="30vh"
                      defaultRowsCount={5}
                    />
                  </FormComponent.Field>
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </Tab>
            <Tab
              title="Заявитель"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`MOBILE_REQUEST_EDIT_${status}_APPLICANT`])}
            >
              <FormComponent.ColumnWrapper fitContent>
                <FormComponent.Column>
                  <FormComponent.Line>
                    <FormComponent.Field label="ФИО" isRequired>
                      <SelectPerson
                        member={convertMobileRequestToMember(mobileRequest)}
                        onUpdateMember={handleApplicantChange}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Дата рождения">
                      <span>{mobileRequest?.applicant?.scientist?.dateBirth}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Гражданство">
                      <span>{mobileRequest.citizenship?.label || ''}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Должность">
                      <span>{mobileRequest.job ? formatJob(mobileRequest.job) : null}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Учёная степень, учёное звание, академическое звание">
                      <span>
                        {[
                          mobileRequest.degree ? getOption(mobileRequest.degree)?.label : null,
                          mobileRequest.rank ? getOption(mobileRequest.rank)?.label : null,
                          mobileRequest.academicRank ? getAcademicRankOption(mobileRequest.academicRank)?.label : null,
                        ]
                          .filter(x => x)
                          .join(', ')}
                      </span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Специальность">
                      <span>{mobileRequest.education ? formatEducation(mobileRequest.education) : null}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="triplePadded" hasFreeFormat hasGap growIndexes={[1, 10]}>
                    <FormComponent.Field label="Курс (год обучения)">
                      <TextInput
                        mode={TextInputMode.year}
                        value={mobileRequest?.applicantEducationYear?.toString() ?? ''}
                        onChange={handleEducationYearChange}
                        isDisabled={viewMode}
                      />
                    </FormComponent.Field>
                    <FormComponent.Field label="Группа" labelSize="fit">
                      <TextInput
                        settings={{ maxLength: 100 }}
                        value={mobileRequest?.applicantGroupNumber ?? ''}
                        onChange={handleEducationGroupChange}
                        isDisabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="padded">
                    <FormComponent.Field label="Категория участника">
                      <Reference
                        value={mobileRequest.tenderParticipant}
                        name="RefTenderParticipant"
                        relationTableModalTitle='Справочник "Категории участников"'
                        onChange={handleTenderParticipantChange}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </FormComponent.Column>
                <FormComponent.Column>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Контакты" tooltip="Укажите телефон(ы) и электронную почту">
                      <TextInput
                        value={mobileRequest.applicantContact}
                        onChange={handleApplicantContactChange}
                        isDisabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Факультет/Институт">
                      <Reference
                        value={mobileRequest.applicantDepartment}
                        name="RefDepartment"
                        relationTableModalTitle='Справочник "Подразделения"'
                        onChange={handleApplicantDepartmentChange}
                        disabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Языковые компетенции" tooltip={applicantLanguageCompetenceTooltip}>
                      <TextInput
                        value={mobileRequest.applicantLanguageCompetence}
                        onChange={handleApplicantLanguageCompetenceChange}
                        isDisabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="padded">
                    <FormComponent.Field label="Примечание">
                      <TextArea
                        mode={TextAreaMode.modal}
                        settings={{ rows: 3, title: 'Примечание' }}
                        value={mobileRequest.applicantNote}
                        onChange={handleApplicantNoteChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
              <FormComponent.Line>
                <FormComponent.Field>
                  <ListEdit
                    rows={mobileRequest.applicantSupervisors}
                    onChange={handleApplicantSupervisorsChange}
                    isDeleteConfirmEnabled
                    title="Руководители"
                    tooltipText="Укажите руководителя структурного подразделения - это декан факультета/директор института"
                    withMessages
                    maxHeight="none"
                    defaultRowsCount={10}
                    isToolbarDisabled={viewMode}
                    isRequired
                    columns={[
                      { label: 'ФИО', formatValue: row => row.supervisor?.fullName || '' },
                      { label: 'Роль', formatValue: row => row.role?.label || '' },
                      { label: 'Подразделение', formatValue: row => row.job?.refDepartment?.label || '' },
                      { label: 'Должность', formatValue: row => row.job?.refAppointment?.label || '' },
                      {
                        label: 'Контакты',
                        formatValue: row =>
                          [
                            row.supervisor?.scientist?.phone,
                            row.supervisor?.scientist?.mobilePhone,
                            row.supervisor?.scientist?.email,
                          ]
                            .filter(x => x)
                            .join('; '),
                      },
                    ]}
                    specification={{
                      mode: 'customComponent',
                      validation: {
                        checkIsValid: row => !!row?.supervisor && !!row?.role,
                        onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                      },
                      renderComponent: (row, onChange) => <ApplicantSupervisorFields entry={row} onFieldChange={onChange} />,
                    }}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </Tab>
            <Tab
              title="Смета"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`MOBILE_REQUEST_EDIT_${status}_ESTIMATE`])}
            >
              <FormComponent.Line hasFreeFormat>
                <FormComponent.Field label="Период командировки:" contentSize="fit">
                  <span>{`(${mobileRequest.businessTripStartDate} - ${mobileRequest.businessTripEndDate})`}</span>
                </FormComponent.Field>
                <FormComponent.Field label="Количество дней:" contentSize="fit">
                  <span>{businesTripDays}</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Field>
                <ListEdit
                  rows={mobileRequest.estimatePositions}
                  onChange={handleEstimatePositionsChange}
                  isDeleteConfirmEnabled
                  title="Смета расходов"
                  withMessages
                  maxHeight="30vh"
                  defaultRowsCount={5}
                  isToolbarDisabled={viewMode}
                  isRequired
                  columns={[
                    { label: 'Статья расходов', formatValue: row => row.kindConsumption?.name || '' },
                    { label: 'Сумма, руб.', formatValue: row => row.amount || '' },
                    { label: 'Примечание', formatValue: row => row.note || '' },
                  ]}
                  specification={{
                    mode: 'customComponent',
                    validation: {
                      checkIsValid: row => !!row?.kindConsumption?.id && !!row.amount,
                      onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                    },
                    renderComponent: (row, onChange) => (
                      <EstimatePositionFields entry={row} onFieldChange={onChange} type={type} />
                    ),
                  }}
                />
              </FormComponent.Field>
              <FormComponent.Field>
                <ListEdit
                  rows={mobileRequest.financings}
                  onChange={handleFinancingsChange}
                  isDeleteConfirmEnabled
                  title="Источники финансирования"
                  withMessages
                  maxHeight="30vh"
                  defaultRowsCount={5}
                  isToolbarDisabled={viewMode}
                  // eslint-disable-next-line max-len
                  tooltipText="Укажите сумму, которые вы запрашиваете из средств Программы поддержки академической мобильности; и суммы софинансирования из других источников: средств грантов/ госконтрактов/ госзадания на выполнение НИР и ОКР; средств подразделений (факультет/институт/САЕ/лаборатория) или внебюдждетные средства принимающей стороны или ваши личные средства. Общая сумма по всем источникам должна быть равна общей сумме сметы"
                  columns={[
                    { label: 'Тип', formatValue: row => row.type?.label || '' },
                    { label: 'Сумма, руб.', formatValue: row => row.amount || '' },
                    {
                      label: 'Источник',
                      formatValue: row => getFinancingSourceLabel(row),
                    },
                    { label: 'Примечание', formatValue: row => row.note || '' },
                  ]}
                  specification={{
                    mode: 'customComponent',
                    validation: {
                      checkIsValid: row => (row ? validateFinancing(row) : false),
                      onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                    },
                    renderComponent: (row, onChange) => <FinancingFields entry={row} onFieldChange={onChange} />,
                  }}
                />
              </FormComponent.Field>
              <h3>{estimateMessage}</h3>
            </Tab>
            <Tab title="Журнал сообщений" isVisible={isHasPermission(userPermission, Permits.MOBILE_REQUEST_FEEDLINE_AGREE)}>
              <DataGrid specification={GetMobileRequestFeedlineList(mobileRequest.id || '-1')} />
            </Tab>
            <Tab
              title="Документы"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`MOBILE_REQUEST_EDIT_${status}_DOCUMENTS`])}
            >
              <Documents
                documents={mobileRequest.documents}
                setDocuments={handleDocumentsChange}
                disabled={!!viewMode}
                sourceLabelForLocalDocs="Текущая заявка"
                isShowSource
              />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>

      <ConfirmPopup
        title={statusChangePopupTitle}
        isOpen={isStatusChangePopupOpen}
        onClose={handleStatusChangePopupClose}
        okButtonText={statusChangePopupConfirmLabel}
        onConfirm={handleStatusChangePopupConfirm}
      >
        <FormComponent.Field label="Комментарий" labelSize="fit">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Комментарий' }}
            value={statusChangeMessage}
            onChange={handleStatusChangeMessageChange}
          />
        </FormComponent.Field>
      </ConfirmPopup>
      <ConfirmPopup
        title="Включение заявки в черновик протокола"
        isOpen={isMobileProtocolProjectPopupOpen}
        onClose={handleMobileProtocolProjectPopupClose}
        onReset={handleMobileProtocolProjectPopupClose}
        onConfirm={handleMobileProtocolProjectPopupConfirm}
        text="Вы действительно хотите включить текущую заявку в черновик протокола?"
        okButtonText="Да"
        resetButtonText="Отмена"
      />
      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />

      {!!isPersonViewFormOpen && (
        <Modal isOpen={isPersonViewFormOpen} onClose={handlePersonViewFormClose} title={titlePerson} size="full" viewMode>
          <Form
            look={{
              apiID: 'GetScientistData',
              id: mobileRequest.applicant?.id || '',
              isOpen: isPersonViewFormOpen,
              viewMode: true,
              template: 'PersonForm',
              setTitle: setTitlePerson,
            }}
            onClose={handlePersonViewFormClose}
          />
        </Modal>
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        mobileRequestId={mobileRequest.id || undefined}
      />
    </>
  );
}

export const Component = React.memo(MobileRequestViewForm);
