import React, { memo } from 'react';

import { Document, Table as T } from 'types/models';
import { Documents } from 'features/Documents';

type Props = {
  documents: Document[];
  setDocuments: (docs: Document[]) => void;
  workMode: T.WorkMode;
};

const Docs = ({ documents, setDocuments, workMode }: Props) => (
  <Documents
    sourceLabelForLocalDocs="Текущее участие в выставке"
    documents={documents}
    setDocuments={setDocuments}
    disabled={workMode === 'viewMode'}
    isShowSource
    isCommonAward
    hint="Для утверждения участия в мероприятии необходимо добавить документ, подтверждающий участие"
  />
);

export const Component = memo(Docs);
