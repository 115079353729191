import { useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const [selectedRow] = tableState.selectedRows;

  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
        permissionName: Permits.FUND_CARD_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: !selectedRow,
        onClick: () => setIsEditFormOpen(true),
        permissionName: Permits.FUND_CARD_EDIT,
      },
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [selectedRow],
  );

  return {
    buttons,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    selectedRow,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
};

export default useController;
