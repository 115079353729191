import { useCallback, useLayoutEffect, useState, useMemo } from 'react';
import * as R from 'ramda';
import { format } from 'date-fns';

import { ReferenceItem } from 'components';
import { tabsStreams } from 'components/Tabs/streams';

import { showErrorsMessages } from 'utils/Common';
import { showNotification } from 'features/Notifications';
import * as BackendAPI from 'services/BackendAPI';
import { ValueOf } from 'types/helpers';
import { PatentResearch, NirRequest, ProgramRequest } from 'types/models';

import {
  computeFinancingsByYear,
  getIndicators,
  getMockEditableIndicator,
  getMockRefElement,
  getMockProject,
  updateStageIndicators,
  getPerformerIndexByLastJobPeriodRole,
  getMockJobPeriod,
  getMockPerformer,
} from './helpers';
import { validate, optionalValidate } from './helpers/validate';
import { EditableIndicator } from './model';
import { compareDesc, parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { NirRequestPerformerRole, ProjectScientistRole, ProjectType } from 'utils/Enums';
import { JobPeriod, Project, Act, Stage, Performer, RefElement } from 'types/models/Project';
import { Mode } from 'features/Table/specifications/GetAnalyticProjectList/makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';
import { clonePersonHistory, getCurrentTime, getEnumItem } from 'utils/Helpers';
import { Item } from 'types/models/common';
import { CopyFromNirRequest, CopyFromProgramRequest } from './types';

type Arguments = {
  id: string | null;
  onSuccessfullSave?(): void;
  projectType?: ProjectType;
  nirRequestId?: string;
  mode: Mode;
  securityDocumentContractId?: string;
  programRequestId?: string;
  copiedProject?: Project;
};

const DEFAULT_OVERHEAD_PERCENT: string = '12.00';

export function useController(args: Arguments) {
  const {
    id: initialId,
    onSuccessfullSave,
    projectType: initialProjectType,
    nirRequestId,
    mode,
    securityDocumentContractId,
    programRequestId,
    copiedProject,
  } = args;

  const tabsId = 'ProjectForm';

  const { enumMap, currentPerson } = useAppDataContext();

  const [project, setProject] = useState<Project>(getMockProject());
  const [oldEndDate, setOldEndDate] = useState<string | null>(null);
  const [isSaveAndContinue, setIsSaveAndContinue] = useState<boolean>(false);
  const [isEndDateReducedWarningOpen, setIsEndDateReducedWarningOpen] = useState<boolean>(false);
  const [isEndDateEnlargedWarningOpen, setIsEndDateEnlargedWarningOpen] = useState<boolean>(false);
  const [isUnsavedWarningOpen, setIsUnsavedWarningOpen] = useState<boolean>(false);
  const [acts, setActs] = useState<Act[]>([]);
  const [isOptionalValidateModalOpen, setIsOptionalValidateModalOpen] = useState<boolean>(false);
  const [optionalValidateMessages, setOptionalValidateMessages] = useState<string[]>([]);
  const [saveInfo, setSaveInfo] = useState<{ isSaveAndContinue: boolean; project: Project | null; acts?: Act[] }>({
    isSaveAndContinue: false,
    project: null,
    acts: [],
  });

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');
  const { methods: saveProjectAPI } = BackendAPI.useBackendAPI('SaveProject');
  const { methods: saveGovermentContractAPI } = BackendAPI.useBackendAPI('SaveGovermentContract');
  const { methods: saveEconomicAgreementProjectAPI } = BackendAPI.useBackendAPI('SaveEconomicAgreement');
  const { methods: saveGrantAPI } = BackendAPI.useBackendAPI('SaveGrant');
  const { methods: getNirRequestAPI } = BackendAPI.useBackendAPI('GetNirRequest');
  const { methods: getReferenceElementAPI } = BackendAPI.useBackendAPI('GetReferenceElement');
  const { methods: getProgramRequestAPI } = BackendAPI.useBackendAPI('GetRequest');

  const createdInfo = useMemo<Pick<RefElement, 'createdBy' | 'createdDate'>>(
    () => ({
      createdBy: currentPerson?.fullName ?? '',
      createdDate: getCurrentTime(),
    }),
    [currentPerson?.fullName],
  );

  const projectType: ProjectType = useMemo(() => project?.type?.value ?? ProjectType.NIR, [project?.type?.value]);

  const mapSaveProject = useMemo<
    Record<
      ProjectType,
      typeof saveProjectAPI | typeof saveGovermentContractAPI | typeof saveEconomicAgreementProjectAPI | typeof saveGrantAPI
    >
  >(
    () => ({
      NIR: saveProjectAPI,
      GRANT: saveGrantAPI,
      GOVERMENT_CONTRACT: saveGovermentContractAPI,
      ECONOMIC_AGREEMENT: saveEconomicAgreementProjectAPI,
    }),
    [saveEconomicAgreementProjectAPI, saveGovermentContractAPI, saveGrantAPI, saveProjectAPI],
  );

  const indicators: EditableIndicator[] = useMemo(() => getIndicators(project?.stages ?? [], 'indicators'), [project?.stages]);
  const indices: EditableIndicator[] = useMemo(() => getIndicators(project?.stages ?? [], 'indices'), [project?.stages]);

  const loadProject = useCallback(
    ({ id, type, nirId, sdcId, prId }: { id?: string; type?: ProjectType; nirId?: string; sdcId?: string; prId?: string }) => {
      getProjectAPI.callAPI(
        { id, type, nirRequestId: nirId, securityDocumentContractId: sdcId, programRequestId: prId },
        {
          onSuccessfullCall: ({ data }) => {
            setProject({
              ...data,
              stateRegistrationReports: R.sort(
                (a, b) => a.stageNumber.localeCompare(b.stageNumber),
                data.stateRegistrationReports,
              ),
              financingsByYear: computeFinancingsByYear(data.stages),
            });
            setActs(data.acts ?? []);
            setOldEndDate(data.endDate);
          },
        },
      );
    },
    [getProjectAPI],
  );

  useLayoutEffect(() => {
    if (copiedProject) setProject(copiedProject);
    else if (initialId && mode !== 'add') loadProject({ id: initialId });
    else if (initialProjectType && (nirRequestId || securityDocumentContractId || programRequestId))
      loadProject({ type: initialProjectType, nirId: nirRequestId, sdcId: securityDocumentContractId, prId: programRequestId });
    else if (initialProjectType)
      setProject({ ...getMockProject(), type: getEnumItem('ProjectType', initialProjectType, enumMap) });
    else console.error('Empty project type');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveProject = useCallback(
    (isReloadProject: boolean, p: Project | null, newActs?: Act[]) => {
      const selectedProject = p != null ? p : project;
      const selectedActs = newActs || acts;
      if (selectedProject) {
        mapSaveProject[selectedProject.type!.value!].callAPI(
          { project: { ...selectedProject, acts: selectedActs } },
          {
            onSuccessfullCall: ({ data: projectId }) => {
              showNotification({ message: 'Проект успешно сохранен', theme: 'success' });
              if (isReloadProject) {
                loadProject({ id: projectId });
              } else {
                onSuccessfullSave?.();
              }
            },
          },
        );
      }
    },
    [project, acts, mapSaveProject, loadProject, onSuccessfullSave],
  );

  const latestJob = useCallback(performer => {
    return performer?.jobPeriods?.sort((a: JobPeriod, b: JobPeriod) => {
      const aEndDate = parse(a.endDate, formatStr, new Date());
      const bEndDate = parse(b.endDate, formatStr, new Date());

      return compareDesc(aEndDate, bEndDate);
    })[0];
  }, []);

  const fixLatestJobDate = useCallback(
    (performer, newDate) => {
      const job = latestJob(performer);
      const fixedJobPeriods = performer.jobPeriods.map((period: JobPeriod) => {
        if (period.endDate === job.endDate) {
          if (period.endDate === oldEndDate || period?.role?.value === ProjectScientistRole.LEADER) {
            return { ...period, endDate: newDate };
          }
        }
        return period;
      });
      return { ...performer, jobPeriods: fixedJobPeriods };
    },
    [latestJob, oldEndDate],
  );

  const toChangePerformersNum = useMemo(() => {
    const leaderIndexes = getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.LEADER);
    const notSuitableNum =
      project?.performers?.filter((performer, index) => {
        const job = latestJob(performer);
        return job?.endDate !== oldEndDate || leaderIndexes.some(leaderIndex => leaderIndex === index);
      })?.length || 0;
    return (project?.performers?.length || 0) - notSuitableNum;
  }, [project, latestJob, oldEndDate]);

  const validateAndSave = useCallback(
    (p: Project | null, forceIsSaveAndContinue?: boolean, newActs?: Act[]) => {
      const invalidationInfo = validate(p).filter(x => !x.isValid);
      if (invalidationInfo.length) {
        showErrorsMessages(invalidationInfo.map(x => x.invalidMessage));
        return;
      }
      const optionalInvalidationInfo = optionalValidate(p).filter(x => !x.isValid);
      if (optionalInvalidationInfo.length) {
        setOptionalValidateMessages(optionalInvalidationInfo.map(x => x.invalidMessage));
        setSaveInfo({
          isSaveAndContinue: typeof forceIsSaveAndContinue === 'boolean' ? forceIsSaveAndContinue : isSaveAndContinue,
          project: p,
          acts: newActs,
        });
        setIsOptionalValidateModalOpen(true);
        return;
      }
      saveProject(typeof forceIsSaveAndContinue === 'boolean' ? forceIsSaveAndContinue : isSaveAndContinue, p, newActs);
    },
    [saveProject, isSaveAndContinue],
  );

  const confirmOptionalValidatePopup = useCallback(() => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 6, tabsId });
    setIsOptionalValidateModalOpen(false);
  }, []);

  const resetOptionalValidatePopup = useCallback(() => {
    saveProject(saveInfo.isSaveAndContinue, saveInfo.project, saveInfo.acts);
    setIsOptionalValidateModalOpen(false);
  }, [saveInfo.acts, saveInfo.isSaveAndContinue, saveInfo.project, saveProject]);

  const fixProjectJobDates = useCallback(
    (leaderOnly?: boolean) => {
      let fixedPerformers = project?.performers || [];
      if (leaderOnly) {
        const leaderIndexes = getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.LEADER);
        fixedPerformers =
          project?.performers?.map((performer, index) => {
            if (leaderIndexes.some(leaderIndex => leaderIndex === index)) {
              return fixLatestJobDate(performer, project?.endDate);
            } else {
              return performer;
            }
          }) || fixedPerformers;
      } else {
        fixedPerformers =
          project?.performers?.map(performer => {
            return fixLatestJobDate(performer, project?.endDate);
          }) || fixedPerformers;
      }
      if (project) {
        validateAndSave({ ...project, performers: fixedPerformers });
      }
    },
    [project, fixLatestJobDate, validateAndSave],
  );

  const isProjectWithLeadOnly = useMemo(() => {
    if (project?.performers.length === 1) {
      const job = latestJob(project);
      return job && job?.role?.value === ProjectScientistRole.LEADER;
    }
    return false;
  }, [project, latestJob]);

  const handleSave = useCallback(
    (type: 'save' | 'saveAndContinue') => () => {
      setIsSaveAndContinue(type === 'saveAndContinue');
      const newTime = parse(project?.endDate || '', formatStr, new Date()).getTime();
      const oldTime = parse(oldEndDate || '', formatStr, new Date()).getTime();
      if (newTime === oldTime || !project?.id) {
        validateAndSave(project, type === 'saveAndContinue');
      } else {
        if (newTime < oldTime || isProjectWithLeadOnly || toChangePerformersNum === 0) {
          setIsEndDateReducedWarningOpen(true);
        } else {
          setIsEndDateEnlargedWarningOpen(true);
        }
      }
    },
    [project, oldEndDate, validateAndSave, isProjectWithLeadOnly, toChangePerformersNum],
  );

  const handleIsEndDateReducedWarningConfirm = useCallback(() => {
    setIsEndDateReducedWarningOpen(false);
    fixProjectJobDates();
  }, [fixProjectJobDates]);

  const handleIsEndDateEnlargeWarningConfirm = useCallback(() => {
    setIsEndDateEnlargedWarningOpen(false);
    fixProjectJobDates();
  }, [fixProjectJobDates]);

  const handleIsEndDateEnlargeWarningCancel = useCallback(() => {
    setIsEndDateEnlargedWarningOpen(false);
    fixProjectJobDates(true);
  }, [fixProjectJobDates]);

  const copyFromProgramRequest = useCallback(
    ({ id, copyOptions }: CopyFromProgramRequest) => {
      getProgramRequestAPI.callAPI(
        { id },
        {
          onSuccessfullCall: ({ data }) => {
            const preparedData = data as ProgramRequest.ProgramRequest;
            const errorNotifications: string[] = [];
            if (project?.program?.id !== preparedData.tender?.program?.id)
              errorNotifications.push('Выбранная в проекте программа должна совпадать с программой заявки');
            if (!errorNotifications.length) {
              const updatedNtrStrategies = preparedData.ntrStrategies.map(x => ({
                ...getMockRefElement(),
                ...createdInfo,
                ref: x,
              }));
              const updatedCriticalTechnologies = preparedData.criticalTechnologies.map(x => ({
                ...getMockRefElement(),
                ...createdInfo,
                ref: x,
              }));
              const updatedGrntis = preparedData.grntis.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));
              const updatedPnis = preparedData.pnis.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));
              const updatedUdks = preparedData.udks.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));

              const updatedPnrs = preparedData.pnrs.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));
              const updatedScienceDomainInterrests = preparedData.scienceDomainInterrests.map(x => ({
                ...getMockRefElement(),
                ...createdInfo,
                ref: x,
              }));
              const updatedPnmitrs = preparedData.pnmitrs.map(x => ({
                ...getMockRefElement(),
                ...createdInfo,
                ref: x,
              }));
              const updatedTechnologyPlatforms = preparedData.technologyPlatforms.map(x => ({
                ...getMockRefElement(),
                ...createdInfo,
                ref: x,
              }));
              const updatedDomainKnowledges = preparedData.domainKnowledges.map(x => ({
                ...getMockRefElement(),
                ...createdInfo,
                ref: x,
              }));

              let requestPerformers = [...preparedData.performers];
              const updatedPerformers: Performer[] = (() => {
                const modifiedPerformers: Performer[] = project.performers.map(projectPerformer => {
                  const relatedPerformerIndex = requestPerformers.findIndex(
                    requestPerformer =>
                      requestPerformer.person?.id === projectPerformer.person?.id &&
                      projectPerformer.jobPeriods.find(x => requestPerformer.role?.value === x.role?.value),
                  );
                  const relatedRequestPerformer =
                    relatedPerformerIndex === -1 ? null : requestPerformers.splice(relatedPerformerIndex, 1)[0];

                  if (relatedRequestPerformer)
                    return {
                      ...projectPerformer,
                      ...clonePersonHistory(relatedRequestPerformer),
                      jobPeriods: [
                        {
                          ...getMockJobPeriod(),
                          ...clonePersonHistory(relatedRequestPerformer),
                          startDate: project.startDate,
                          endDate: project.endDate,
                          role: ((relatedRequestPerformer.role as unknown) as Item<ProjectScientistRole> | undefined) || null,
                        },
                      ],
                      stages: project.stages.map(x => ({
                        id: null,
                        stage: x,
                        approved: false,
                        approvements: [],
                        payments: [],
                        paymentMethods: '',
                      })),
                    };
                  return projectPerformer;
                });

                const newPerformers: Performer[] = requestPerformers.map(requestPerformer => ({
                  ...getMockPerformer(),
                  fio: requestPerformer.person?.fullName || '',
                  person: requestPerformer.person,
                  ...clonePersonHistory(requestPerformer),
                  jobPeriods: [
                    {
                      ...getMockJobPeriod(),
                      ...clonePersonHistory(requestPerformer),
                      startDate: project.startDate,
                      endDate: project.endDate,
                      role: ((requestPerformer.role as unknown) as Item<ProjectScientistRole> | undefined) || null,
                    },
                  ],
                  stages: project.stages.map(x => ({
                    id: null,
                    stage: x,
                    approved: false,
                    approvements: [],
                    payments: [],
                    paymentMethods: '',
                  })),
                }));
                return [...modifiedPerformers, ...newPerformers];
              })();

              setProject({
                ...(project || getMockProject({ projectTypeItem: getEnumItem('ProjectType', projectType, enumMap) })),
                ...(copyOptions.isProject && {
                  // Аннотация (Описание)
                  annotation: preparedData.annotation || project?.annotation || '',
                  // Ключевые слова (Описание)
                  keyWords: preparedData.keyWords || project?.keyWords || '',

                  // Стратегии научно-технического развития РФ (Описание)
                  ntrStrategies: [
                    ...(project?.ntrStrategies || []),
                    ...updatedNtrStrategies.filter(x => !project?.ntrStrategies.find(y => x.ref.id === y.ref?.id)),
                  ],
                  // Критические технологии (Описание)
                  criticalTechnologies: [
                    ...(project?.criticalTechnologies || []),
                    ...updatedCriticalTechnologies.filter(x => !project?.criticalTechnologies.find(y => x.ref.id === y.ref?.id)),
                  ],
                  // УДК (Описание)
                  udks: [...(project?.udks || []), ...updatedUdks.filter(x => !project?.udks.find(y => x.ref.id === y.ref?.id))],
                  // ГРНТИ (Описание)
                  grntis: [
                    ...(project?.grntis || []),
                    ...updatedGrntis.filter(x => !project?.grntis.find(y => x.ref.id === y.ref?.id)),
                  ],
                  // Приоритетные направления развития науки, технологий и техники (Описание)
                  pnis: [...(project?.pnis || []), ...updatedPnis.filter(x => !project?.pnis.find(y => x.ref.id === y.ref?.id))],
                  // Тема проекта (О проекте)
                  name: preparedData.theme || project.name || '',
                  // ОКВЭД
                  okved: preparedData.okveds[0] || project.okved,
                  // ЛКСЭЦ
                  lksetss: preparedData.lksetss.length
                    ? [{ ...getMockRefElement(), ...createdInfo, ref: preparedData.lksetss[0] }]
                    : project.lksetss,
                  // Отрасль науки
                  scienceBrunches: preparedData.scienceBrunches.length
                    ? [{ ...getMockRefElement(), ...createdInfo, ref: preparedData.scienceBrunches[0] }]
                    : project.scienceBrunches,
                }),
                ...(copyOptions.isClassifiers && {
                  // Области научных интересов (Классификаторы)
                  scienceDomainInterrests: [
                    ...(project?.scienceDomainInterrests || []),
                    ...updatedScienceDomainInterrests.filter(
                      x => !project?.scienceDomainInterrests.find(y => x.ref.id === y.ref?.id),
                    ),
                  ],
                  // Приоритетные направления развития (Классификаторы)
                  pnrs: [...(project?.pnrs || []), ...updatedPnrs.filter(x => !project?.pnrs.find(y => x.ref.id === y.ref?.id))],
                  // Приоритетные направления развития модернизации и технического развития экономики России
                  pnmitrs: [
                    ...(project?.pnmitrs || []),
                    ...updatedPnmitrs.filter(x => !project?.pnmitrs.find(y => x.ref.id === y.ref?.id)),
                  ],
                  // Области знаний
                  domainKnowledges: [
                    ...(project?.domainKnowledges || []),
                    ...updatedDomainKnowledges.filter(x => !project?.domainKnowledges.find(y => x.ref.id === y.ref?.id)),
                  ],
                  // Технологические платформы
                  techPlatforms: [
                    ...(project?.techPlatforms || []),
                    ...updatedTechnologyPlatforms.filter(x => !project?.techPlatforms.find(y => x.ref.id === y.ref?.id)),
                  ],
                }),
                ...(copyOptions.isPerformers && {
                  performers: updatedPerformers || project?.performers || [],
                }),

                // Данные о копировании
                newProgramRequestDataCopyDetail:
                  (project.newProgramRequestDataCopyDetail ? `${project.newProgramRequestDataCopyDetail}\n` : '') +
                  `${format(new Date(), formatStr)}: ${[
                    !!copyOptions.isProject && 'Описание проекта',
                    !!copyOptions.isClassifiers && 'Классификаторы',
                    !!copyOptions.isPerformers && 'Список исполнителей',
                  ]
                    .filter(x => x)
                    .join(', ')} - ${currentPerson?.shortName}`,
              });
              showNotification({ message: 'Данные заявки скопированы в проект', theme: 'success' });
            } else showNotification({ message: errorNotifications.join('. '), theme: 'danger' });
          },
        },
      );
    },
    [getProgramRequestAPI, project, projectType, enumMap, createdInfo, currentPerson?.shortName],
  );

  const copyFromNirRequest = useCallback(
    ({ id, nextOverheadPercent = DEFAULT_OVERHEAD_PERCENT, copyOptions }: CopyFromNirRequest) => {
      getNirRequestAPI.callAPI(
        { id },
        {
          onSuccessfullCall: ({ data }) => {
            const preparedData = data as NirRequest.NirRequest;
            const errorNotifications: string[] = [];
            if (project?.program?.id !== preparedData.tender?.program?.id)
              errorNotifications.push('Выбранная в проекте программа должна совпадать с программой заявки');
            if (!errorNotifications.length)
              getReferenceElementAPI.callAPI(
                { refName: 'RefProjectSpecific', code: preparedData.specification },
                {
                  onSuccessfullCall: specificationReferenceElementResponse => {
                    const specification: ReferenceItem = specificationReferenceElementResponse.data;
                    const stages: Stage[] = project?.stages || [];
                    const requestFirstStage: NirRequest.Stage =
                      !preparedData.stages[1] || preparedData.stages[0].year < preparedData.stages[1].year
                        ? preparedData.stages[0]
                        : preparedData.stages[1];
                    const requestSecondStage: NirRequest.Stage | null =
                      preparedData.stages.find(x => x !== requestFirstStage) || null;
                    const projectFirstStageIndex: number = stages.findIndex(
                      x => x.startDate.split('.')[2] === requestFirstStage.year,
                    );
                    const projectSecondStageIndex: number | null = requestSecondStage
                      ? stages.findIndex(x => x.startDate.split('.')[2] === requestSecondStage.year)
                      : null;
                    const projectFirstStage: Stage | null = projectFirstStageIndex !== -1 ? stages[projectFirstStageIndex] : null;
                    const projectSecondStage: Stage | null =
                      projectSecondStageIndex !== null && projectSecondStageIndex !== -1 ? stages[projectSecondStageIndex] : null;

                    const newStages: Stage[] = stages;
                    if (projectFirstStage && projectFirstStageIndex !== -1) {
                      newStages[projectFirstStageIndex] = {
                        ...projectFirstStage,
                        name: requestFirstStage.name || projectFirstStage.name,
                        task:
                          preparedData.stageTasks.find(x => x.year === requestFirstStage.year)?.task || projectFirstStage.task,
                        expectedResults:
                          preparedData.expectedResults.find(x => x.year === requestFirstStage.year)?.result ||
                          projectFirstStage.expectedResults,
                        universityEffect: requestFirstStage.universityEffect || projectFirstStage.universityEffect,
                        regionalEffect: requestFirstStage.regionalEffect || projectFirstStage.regionalEffect,
                        nationalEffect: requestFirstStage.nationalEffect || projectFirstStage.nationalEffect,
                      };
                    }
                    if (
                      projectSecondStageIndex !== null &&
                      projectSecondStageIndex !== -1 &&
                      requestSecondStage &&
                      projectSecondStage
                    ) {
                      newStages[projectSecondStageIndex] = {
                        ...projectSecondStage,
                        name: requestSecondStage.name || projectSecondStage.name,
                        task:
                          preparedData.stageTasks.find(x => x.year === requestSecondStage.year)?.task || projectSecondStage.task,
                        expectedResults:
                          preparedData.expectedResults.find(x => x.year === requestSecondStage.year)?.result ||
                          projectSecondStage.expectedResults,
                        universityEffect: requestSecondStage.universityEffect || projectSecondStage.universityEffect,
                        regionalEffect: requestSecondStage.regionalEffect || projectSecondStage.regionalEffect,
                        nationalEffect: requestSecondStage.nationalEffect || projectSecondStage.nationalEffect,
                      };
                    }

                    const updatedIndicators: EditableIndicator[] = indicators;
                    preparedData.indicators.forEach(x => {
                      const firstProjectIndicatorIndex = indicators.findIndex(
                        y => !!x.firstYearPlan && y.stageNumber === projectFirstStage?.number && y.ref?.id === x.refResultItem.id,
                      );
                      if (firstProjectIndicatorIndex !== -1)
                        updatedIndicators[firstProjectIndicatorIndex] = {
                          ...updatedIndicators[firstProjectIndicatorIndex],
                          plan: x.firstYearPlan || '0',
                          fact: updatedIndicators[firstProjectIndicatorIndex].fact || '0',
                        };
                      else if (projectFirstStage && x.firstYearPlan)
                        updatedIndicators.push({
                          ...getMockEditableIndicator(),
                          ref: x.refResultItem,
                          year: requestFirstStage.year || '',
                          plan: x.firstYearPlan || '0',
                          fact: '0',
                          stageNumber: projectFirstStage.number,
                        });

                      const secondProjectIndicatorIndex = indicators.findIndex(
                        y =>
                          !!x.secondYearPlan && y.stageNumber === projectSecondStage?.number && y.ref?.id === x.refResultItem.id,
                      );
                      if (secondProjectIndicatorIndex !== -1)
                        updatedIndicators[secondProjectIndicatorIndex] = {
                          ...updatedIndicators[secondProjectIndicatorIndex],
                          plan: x.secondYearPlan || '0',
                          fact: updatedIndicators[secondProjectIndicatorIndex].fact || '0',
                        };
                      else if (projectSecondStage && x.secondYearPlan)
                        updatedIndicators.push({
                          ...getMockEditableIndicator(),
                          ref: x.refResultItem,
                          year: requestSecondStage?.year || '',
                          plan: x.secondYearPlan || '0',
                          fact: '0',
                          stageNumber: projectSecondStage.number,
                        });
                    });

                    const updatedIndices: EditableIndicator[] = indices;
                    preparedData.indices.forEach(x => {
                      const firstProjectIndexIndex = indices.findIndex(
                        y => !!x.firstYearPlan && y.stageNumber === projectFirstStage?.number && y.ref?.id === x.refResultItem.id,
                      );
                      if (firstProjectIndexIndex !== -1)
                        updatedIndices[firstProjectIndexIndex] = {
                          ...updatedIndices[firstProjectIndexIndex],
                          plan: x.firstYearPlan || '0',
                          fact: updatedIndices[firstProjectIndexIndex].fact || '0',
                        };
                      else if (projectFirstStage && x.firstYearPlan)
                        updatedIndices.push({
                          ...getMockEditableIndicator(),
                          ref: x.refResultItem,
                          year: requestFirstStage.year || '',
                          plan: x.firstYearPlan || '0',
                          fact: '0',
                          stageNumber: projectFirstStage.number,
                        });

                      const secondProjectIndexIndex = indices.findIndex(
                        y =>
                          !!x.secondYearPlan && y.stageNumber === projectSecondStage?.number && y.ref?.id === x.refResultItem.id,
                      );
                      if (secondProjectIndexIndex !== -1)
                        updatedIndices[secondProjectIndexIndex] = {
                          ...updatedIndices[secondProjectIndexIndex],
                          plan: x.secondYearPlan || '0',
                          fact: updatedIndices[secondProjectIndexIndex].fact || '0',
                        };
                      else if (projectSecondStage && x.secondYearPlan)
                        updatedIndices.push({
                          ...getMockEditableIndicator(),
                          ref: x.refResultItem,
                          year: requestSecondStage?.year || '',
                          plan: x.secondYearPlan || '0',
                          fact: '0',
                          stageNumber: projectSecondStage.number,
                        });
                    });

                    const updatedStages: Stage[] = copyOptions.isIndicator
                      ? updateStageIndicators(
                          updatedIndices,
                          updateStageIndicators(
                            updatedIndicators,
                            copyOptions.isStage ? newStages : project.stages,
                            'indicators',
                          ),
                          'indices',
                        )
                      : newStages;
                    const updatedNtrStrategies = preparedData.ntrStrategies.map(x => ({
                      ...getMockRefElement(),
                      ...createdInfo,
                      ref: x,
                    }));
                    const updatedCriticalTechnologies = preparedData.criticalTechnologies.map(x => ({
                      ...getMockRefElement(),
                      ...createdInfo,
                      ref: x,
                    }));
                    const updatedGrntis = preparedData.grntis.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));
                    const updatedOecds = preparedData.oecds.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));
                    const updatedPnrs = preparedData.pnrs.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));
                    const updatedPnis = preparedData.pnis.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));
                    const updatedUdks = preparedData.udks.map(x => ({ ...getMockRefElement(), ...createdInfo, ref: x }));

                    const updatedExpectedResults: string = preparedData.expectedResults
                      .sort((a, b) => Number(a.year) - Number(b.year))
                      .map(x => (x.result ? `На ${x.year} год:\n${x.result}` : null))
                      .filter(x => x)
                      .join('\n');

                    let requestPerformers = [...preparedData.performers];

                    const updatedPerformers: Performer[] = (() => {
                      const modifiedPerformers: Performer[] = project.performers.map(projectPerformer => {
                        const relatedPerformerIndex = requestPerformers.findIndex(
                          requestPerformer =>
                            requestPerformer.person?.id === projectPerformer.person?.id &&
                            projectPerformer.jobPeriods.find(x => requestPerformer.role?.value === x.role?.value),
                        );
                        const relatedRequestPerformer =
                          relatedPerformerIndex === -1 ? null : requestPerformers.splice(relatedPerformerIndex, 1)[0];

                        if (relatedRequestPerformer)
                          return {
                            ...projectPerformer,
                            ...clonePersonHistory(relatedRequestPerformer),
                            jobPeriods: [
                              {
                                ...getMockJobPeriod(),
                                ...clonePersonHistory(relatedRequestPerformer),
                                startDate: project.startDate,
                                endDate: project.endDate,
                                role:
                                  (relatedRequestPerformer.role?.value === NirRequestPerformerRole.LEAD_SCIENTIST
                                    ? getEnumItem('ProjectScientistRole', ProjectScientistRole.PERFORMER, enumMap)
                                    : ((relatedRequestPerformer.role as unknown) as Item<ProjectScientistRole> | undefined)) ||
                                  null,
                              },
                            ],
                            stages: project.stages.map(x => ({
                              id: null,
                              stage: x,
                              approved: false,
                              approvements: [],
                              payments: [],
                              paymentMethods: '',
                            })),
                          };
                        return projectPerformer;
                      });

                      const newPerformers: Performer[] = requestPerformers.map(requestPerformer => ({
                        ...getMockPerformer(),
                        fio: requestPerformer.person?.fullName || '',
                        person: requestPerformer.person,
                        ...clonePersonHistory(requestPerformer),
                        jobPeriods: [
                          {
                            ...getMockJobPeriod(),
                            ...clonePersonHistory(requestPerformer),
                            startDate: project.startDate,
                            endDate: project.endDate,
                            role:
                              (requestPerformer.role?.value === NirRequestPerformerRole.LEAD_SCIENTIST
                                ? getEnumItem('ProjectScientistRole', ProjectScientistRole.PERFORMER, enumMap)
                                : ((requestPerformer.role as unknown) as Item<ProjectScientistRole> | undefined)) || null,
                          },
                        ],
                        stages: project.stages.map(x => ({
                          id: null,
                          stage: x,
                          approved: false,
                          approvements: [],
                          payments: [],
                          paymentMethods: '',
                        })),
                      }));
                      return [...modifiedPerformers, ...newPerformers];
                    })();

                    setProject({
                      ...(project || getMockProject({ projectTypeItem: getEnumItem('ProjectType', projectType, enumMap) })),
                      ...(copyOptions.isProject && {
                        // Цель (Описание)
                        goal: preparedData.goal || project?.goal || '',
                        // Характер проекта (Описание)
                        specification: specification || project?.specification,
                        // Аннотация (Описание)
                        annotation: preparedData.annotation || project?.annotation || '',
                        // Ожидаемые результаты (Описание)
                        expectedResults: updatedExpectedResults,
                        // Научный задел (Описание)
                        scientificGroundwork: preparedData.scientificGroundwork || project?.scientificGroundwork || '',
                        // Обоснование междисциплинарного подхода (Описание)
                        interdisciplinaryAproachJustification:
                          preparedData.interdisciplinaryAproachJustification ||
                          project?.interdisciplinaryAproachJustification ||
                          '',
                        // Ключевые слова (Описание)
                        keyWords: preparedData.keyWords || project?.keyWords || '',

                        // Стратегии научно-технического развития РФ (Описание)
                        ntrStrategies: [
                          ...(project?.ntrStrategies || []),
                          ...updatedNtrStrategies.filter(x => !project?.ntrStrategies.find(y => x.ref.id === y.ref?.id)),
                        ],
                        // Критические технологии (Описание)
                        criticalTechnologies: [
                          ...(project?.criticalTechnologies || []),
                          ...updatedCriticalTechnologies.filter(
                            x => !project?.criticalTechnologies.find(y => x.ref.id === y.ref?.id),
                          ),
                        ],
                        // УДК (Описание)
                        udks: [
                          ...(project?.udks || []),
                          ...updatedUdks.filter(x => !project?.udks.find(y => x.ref.id === y.ref?.id)),
                        ],
                        // ГРНТИ (Описание)
                        grntis: [
                          ...(project?.grntis || []),
                          ...updatedGrntis.filter(x => !project?.grntis.find(y => x.ref.id === y.ref?.id)),
                        ],
                        // OECD (Описание)
                        oecds: [
                          ...(project?.oecds || []),
                          ...updatedOecds.filter(x => !project?.oecds.find(y => x.ref.id === y.ref?.id)),
                        ],
                        // Приоритетные направления развития (Классификаторы)
                        pnrs: [
                          ...(project?.pnrs || []),
                          ...updatedPnrs.filter(x => !project?.pnrs.find(y => x.ref.id === y.ref?.id)),
                        ],
                        // Приоритетные направления развития науки, технологий и техники (Описание)
                        pnis: [
                          ...(project?.pnis || []),
                          ...updatedPnis.filter(x => !project?.pnis.find(y => x.ref.id === y.ref?.id)),
                        ],
                        // Тема проекта (О проекте)
                        name: preparedData.name || project.name || '',
                      }),
                      ...((copyOptions.isStage || copyOptions.isIndicator) && {
                        stages: updatedStages || project?.stages,
                      }),
                      ...(copyOptions.isPerformers && {
                        performers: updatedPerformers || project?.performers || [],
                      }),

                      // Нормативный процент накладных расходов (Особенности)
                      overheadPercent: nextOverheadPercent,
                      // Данные о копировании
                      newNirRequestDataCopyDetail:
                        (project.newNirRequestDataCopyDetail ? `${project.newNirRequestDataCopyDetail}\n` : '') +
                        `${format(new Date(), formatStr)}: ${[
                          !!copyOptions.isProject && 'Описание проекта',
                          !!copyOptions.isStage && 'Описание этапов',
                          !!copyOptions.isIndicator && 'Индикаторы и показатели',
                          !!copyOptions.isPerformers && 'Список исполнителей',
                        ]
                          .filter(x => x)
                          .join(', ')} - ${currentPerson?.shortName}`,
                    });
                    showNotification({ message: 'Данные заявки скопированы в проект', theme: 'success' });
                  },
                },
              );
            else showNotification({ message: errorNotifications.join('. '), theme: 'danger' });
          },
        },
      );
    },
    [
      getNirRequestAPI,
      project,
      getReferenceElementAPI,
      indicators,
      indices,
      projectType,
      enumMap,
      currentPerson?.shortName,
      createdInfo,
    ],
  );

  const getPatentResearchByStageId = useCallback(
    (stageId: string): PatentResearch | null => {
      const value = project?.patentResearches.length ? project.patentResearches.find(x => x.stage?.id === stageId) : null;
      return value || null;
    },
    [project?.patentResearches],
  );

  const makeChangeHandler = (key: keyof Project) => (val: ValueOf<Project>) => {
    setProject(prev => ({ ...prev!, [key]: val }));
  };

  const handleActsChange = useCallback(
    (value: Act[]) => {
      validateAndSave(project, true, value);
    },
    [validateAndSave, project],
  );

  const handleUnsavedWarningConfirm = useCallback(() => {
    setIsUnsavedWarningOpen(false);
    validateAndSave(project, true);
  }, [validateAndSave, project]);

  const endDateWarningTxt = useMemo(() => {
    return isProjectWithLeadOnly || toChangePerformersNum === 0
      ? 'У руководителя изменилась дата окончания работ'
      : 'Для всех участников изменена дата окончания периода работы';
  }, [isProjectWithLeadOnly, toChangePerformersNum]);

  const endDateEnlargedWarningTxt = useMemo(() => {
    return `У ${toChangePerformersNum} исполнителей дата окончания работ ${oldEndDate}, заменить на ${project?.endDate}?`;
  }, [toChangePerformersNum, oldEndDate, project]);

  return {
    enumMap,
    project,
    projectType,
    makeChangeHandler,
    setProject,
    handleSave,
    getPatentResearchByStageId,
    copyFromNirRequest,
    copyFromProgramRequest,
    indicators,
    indices,
    handleActsChange,
    acts,
    isEndDateReducedWarningOpen,
    setIsEndDateReducedWarningOpen,
    isEndDateEnlargedWarningOpen,
    setIsEndDateEnlargedWarningOpen,
    handleIsEndDateReducedWarningConfirm,
    handleIsEndDateEnlargeWarningConfirm,
    handleIsEndDateEnlargeWarningCancel,
    endDateEnlargedWarningTxt,
    endDateWarningTxt,
    isUnsavedWarningOpen,
    setIsUnsavedWarningOpen,
    handleUnsavedWarningConfirm,
    isOptionalValidateModalOpen,
    setIsOptionalValidateModalOpen,
    optionalValidateMessages,
    confirmOptionalValidatePopup,
    resetOptionalValidatePopup,
    tabsId,
  };
}
