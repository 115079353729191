import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { ProgramRequest } from 'types/models';
import { convertProgramRequestToXML } from 'services/BackendAPI/configurations/programRequest/requestConverters';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { Settings } from 'types/models/common';

import { convertProgramRequest, convertRequestFeedline } from './converters';

export const programRequestConfigurations = {
  SaveRequest: makeBackendAPIConfiguration({
    id: 'SaveRequest',
    endpoint: '/msa/service/commands/SaveRequest',
    convertInputToXMLElement: (inputData: ProgramRequest.ProgramRequest) => ({
      Request: {
        _attr: {
          commandName: 'SaveRequest',
        },
        RequestInfo: { ...convertProgramRequestToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetRequest: makeBackendAPIConfiguration({
    id: 'GetRequest',
    endpoint: '/msa/service/commands/GetRequest',
    convertInputToXMLElement: (inputData: { id?: string | null; tenderId?: string | null; lotId?: string | null }) => {
      const { id, tenderId, lotId } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetRequest',
          },
          RequestInfo: {
            _attr: { id },
            ...(tenderId ? { Tender: { _attr: { id: tenderId } } } : {}),
            ...(lotId ? { Lot: { _attr: { id: lotId } } } : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertProgramRequest(response.Response.RequestInfo, settings),
  }),
  ChangeRequestStatus: makeBackendAPIConfiguration({
    id: 'ChangeRequestStatus',
    endpoint: '/msa/service/commands/ChangeRequestStatus',
    convertInputToXMLElement: ({ message, ids, status }: { message: string; ids: string[]; status: string }) => ({
      Request: {
        _attr: { commandName: 'ChangeRequestStatus' },
        RequestInfo: ids.map(id => ({
          _attr: { id },
          Status: status,
          ...(message ? { Message: message } : {}),
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  AddRequestApprovement: makeBackendAPIConfiguration({
    id: 'AddRequestApprovement',
    endpoint: '/msa/service/commands/AddRequestApprovement',
    convertInputToXMLElement: ({ message, requestId, status }: { message: string; requestId: string; status: string }) => ({
      Request: {
        _attr: { commandName: 'AddRequestApprovement' },
        RequestInfo: {
          _attr: { id: requestId },
          Approvement: {
            Resolution: status,
            ...(message ? { ApprovementMessage: message } : {}),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  EditGroupRequestNumberPrefix: makeBackendAPIConfiguration({
    id: 'EditGroupRequestNumberPrefix',
    endpoint: '/msa/service/commands/EditGroupRequestNumberPrefix',
    convertInputToXMLElement: ({ numberPrefixId, requestIds }: { numberPrefixId?: string; requestIds: string[] }) => ({
      Request: {
        _attr: { commandName: 'EditGroupRequestNumberPrefix' },
        RequestsGroup: {
          Data: {
            ...(numberPrefixId ? { NumberPrefix: { _attr: { id: numberPrefixId } } } : {}),
          },
          Requests: { RequestInfo: requestIds.map(requestId => ({ _attr: { id: requestId } })) },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetRequestFeedline: makeBackendAPIConfiguration({
    id: 'GetRequestFeedline',
    endpoint: '/msa/service/commands/GetRequestFeedline',
    convertInputToXMLElement: ({ id }: { id: string }) => {
      return {
        Request: {
          _attr: { commandName: 'GetRequestFeedline' },
          RequestFeedline: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertRequestFeedline(response.Response.RequestFeedline),
  }),
  SaveRequestFeedline: makeBackendAPIConfiguration({
    id: 'SaveRequestFeedline',
    endpoint: '/msa/service/commands/SaveRequestFeedline',
    convertInputToXMLElement: ({
      requestId,
      feedlineId,
      message,
    }: {
      requestId?: string;
      feedlineId?: string;
      message: string;
    }) => {
      return {
        Request: {
          _attr: { commandName: 'SaveRequestFeedline' },
          RequestFeedline: {
            ...(feedlineId ? { _attr: { id: feedlineId } } : null),
            ...(requestId ? { RequestInfo: { _attr: { id: requestId } } } : null),
            Message: message,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
