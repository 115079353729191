import React, { useLayoutEffect, useState, useCallback, useRef } from 'react';
import block from 'bem-cn';

import { Notice } from 'types/models/Notice';

import './style.scss';

const b = block('notices');

type Props = {
  props: Notice;
};

function Block({ props }: Props) {
  const { createdDate, caption, data } = props;

  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);
  const [isBlockExpanded, setIsBlockExpanded] = useState<boolean>(false);

  const refData = useRef<any>(null);
  const refBlock = useRef<any>(null);

  useLayoutEffect(() => {
    if (refData.current) setIsButtonVisible(refData.current.offsetHeight < refData.current.scrollHeight);
    // eslint-disable-next-line
  }, []);

  const handleExpandButtonClick = useCallback(() => {
    setIsBlockExpanded(!isBlockExpanded);
    setTimeout(() => {
      if (isBlockExpanded) {
        refBlock.current.parentNode.parentNode.scrollTo({
          top: refBlock.current.offsetTop - refBlock.current.parentNode.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 0 * 1000);
  }, [isBlockExpanded]);

  return (
    <div className={b('block')} ref={refBlock}>
      <div className={b('date')}>{createdDate}</div>
      <h3 className={b('caption')}>{caption}</h3>

      <div className={b('data', { expanded: isBlockExpanded })} ref={refData} dangerouslySetInnerHTML={{ __html: data }}></div>
      {isButtonVisible && (
        <span className={b('expand-button', { not_expanded: !isBlockExpanded })} onClick={handleExpandButtonClick}>
          {isBlockExpanded ? 'Скрыть' : 'Подробнее'}
        </span>
      )}
    </div>
  );
}

export const Component = React.memo(Block);
