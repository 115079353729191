import { useState, useCallback, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { useStream } from 'StreamRx';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Form, Author, Document, FileInfo, RecordStatus as RS } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import useSavePublicationHook from 'features/Form/hooks/savePublicationHook';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { useAppDataContext } from 'features/AppData/context';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { validate } from './validate';
import { useFormContext } from 'features/Form/hooks';
import { mobileRequestsStream, projectsStreams } from './streams';
import { formatToRequest } from './setup/formatToRequest';
import { setByResponse } from './setup/setByResponse';
import { REQUIRED_CATEGORY_CODE } from './helpers';
import { ModePublicationHeader, PublicationHeader } from 'features/Form/views/ModalHeader/publication';
import { showNotification } from 'features/Notifications';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  onClose: () => void;
};
const DEBOUNCE_TIME = 700;

const tabsId = 'PublicationFormDissertation';

export function useController({ onClose }: Props) {
  const { currentPerson, initialLanguageCode, settings, userSystemDepartment } = useAppDataContext();

  const {
    look: { type, id, isElectronic, electronicType, relatedTableState, name, viewMode, editMode, arm, setTitle },
  } = useFormContext<Form.PublicationFormLook>();

  const { isProfile } = usePrivatePageContext();
  const preparedStartCheckDuplicateAction = useRef<(() => void) | null>(null);
  const tableStreams = useLocalTableStreams();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const [isRecordElectronic, setIsRecordElectronic] = useState<boolean>(!!isElectronic);
  const [recordElectronicType, setRecordElectronicType] = useState<string>(electronicType || '');
  const [publicationId, setPublicationId] = useState<string | undefined>(id);
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const [isPublisherWarningOpen, setIsPublisherWarningOpen] = useState<boolean>(false);
  const [isAddressLoading, setIsAddressLoading] = useState<boolean>(false);
  const [addressOptions, setAddressOptions] = useState<{ value: string; label: string }[]>([]);
  const [selectTimeout, setSelectTimeout] = useState<null | NodeJS.Timeout>(null);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [projects, setProjects] = useState<PublicationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<PublicationMobileRequest[]>([]);
  const [topMessage, setTopMessage] = useState<string>('');
  const [isAuthorsCategegoryPopupOpen, setIsAuthorsCategoryPopupOpen] = useState<boolean>(false);
  const [userDepartments, setUserDepartments] = useState<ReferenceItem[]>([]);
  const [publicationDepartment, setPublicationDepartment] = useState<ReferenceItem | null>(null);
  const [formFields, setFormFields] = useState<Form.Fields>({
    isLocal: {
      value: false,
      required: false,
      isValid: true,
      title: settings?.organization?.shortName || '',
      onChange: (val: boolean) => {
        setFormFields((prevState: Form.Fields) => {
          const nextIsLocal = val;

          return {
            ...prevState,
            isLocal: { ...prevState.isLocal, value: nextIsLocal },
          };
        });
      },
    },
    affiliation: {
      value: null,
      required: false,
      title: 'Другая организация',
      isValid: true,
      onChange: (nextAffiliation: Form.Affiliation | null) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            affiliation: { ...prevState.affiliation, value: nextAffiliation },
          };
        });
      },
    },
    name: {
      value: '',
      required: true,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    doi: {
      value: '',
      isValid: true,
      title: 'DOI',
      tooltipText: 'Цифровой идентификатор объекта, представленного в Интернете, пример заполнения: 10.1007/b136753',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, isValid: true },
        }));
      },
    },
    textLanguage: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Язык текста',
      required: true,
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          textLanguage: {
            ...prevState.textLanguage,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          textLanguage: { ...prevState.textLanguage, isValid: true },
        }));
      },
    },
    publicationDate: {
      value: '',
      isValid: true,
      title: 'Дата публикации',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationDate: { ...prevState.publicationDate, value: nextDate || '' },
        }));
      },
    },
    printPageCount: {
      value: '',
      isValid: true,
      title: 'Печатных листов',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, isValid: true },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      required: true,
      title: 'Файл',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    vakSpeciality: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Специальность ВАК',
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          vakSpeciality: {
            ...prevState.vakSpeciality,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          vakSpeciality: { ...prevState.vakSpeciality, isValid: true },
        }));
      },
    },
    degree: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Ученая степень',
      required: false,
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          degree: {
            ...prevState.degree,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          degree: { ...prevState.degree, isValid: true },
        }));
      },
    },
    council: {
      value: null,
      isValid: true,
      title: 'Диссертационный совет',
      onChange: (nextCouncil: Form.Council | null) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          council: { ...prevState.council, value: nextCouncil },
        }));
      },
    },
    address: {
      value: '',
      isValid: true,
      required: true,
      title: 'Место издания',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          address: { ...prevState.address, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          address: { ...prevState.address, isValid: true },
        }));
      },
    },
    defencePlace: {
      value: '',
      isValid: true,
      required: true,
      title: 'Место защиты',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          defencePlace: { ...prevState.defencePlace, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          defencePlace: { ...prevState.defencePlace, isValid: true },
        }));
      },
    },
    defenceDate: {
      value: '',
      isValid: true,
      title: 'Дата защиты',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          defenceDate: { ...prevState.defenceDate, value: nextDate || '' },
        }));
      },
    },
    year: {
      value: '',
      isValid: true,
      required: true,
      title: 'Год издания',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          year: { ...prevState.year, value, isValid: true },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          year: { ...prevState.year, isValid: true },
        }));
      },
    },
    pageCount: {
      value: '',
      isValid: true,
      title: 'Количество страниц',
      maxValue: 100000,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageCount: { ...prevState.pageCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageCount: { ...prevState.pageCount, isValid: true },
        }));
      },
    },
    libraryLink: {
      value: '',
      isValid: true,
      title: 'Ссылка на каталог НБ',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          libraryLink: { ...prevState.libraryLink, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          libraryLink: { ...prevState.libraryLink, isValid: true },
        }));
      },
    },
    bibliographicRecord: {
      value: '',
      isValid: true,
      title: 'Библиографическая запись',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          bibliographicRecord: { ...prevState.bibliographicRecord, value },
        }));
      },
    },
    foreignName: {
      value: '',
      required: false,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, isValid: true },
        }));
      },
    },
    foreignAnnotation: {
      value: '',
      required: false,
      isValid: true,
      title: 'Аннотация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, isValid: true },
        }));
      },
    },
    foreignAuthorsKeyWords: {
      value: '',
      required: false,
      isValid: true,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, isValid: true },
        }));
      },
    },
    foreignAuthors: {
      value: '',
      required: false,
      isValid: true,
      title: 'Авторы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, isValid: true },
        }));
      },
    },
  });

  const { methods: getPublication, state: getPublicationState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublicationAPI, state: savePublicationAPIState } = BackendAPI.useBackendAPI('SavePublication');
  const { methods: getFieldSuggestion, state: getFieldSuggestionState } = BackendAPI.useBackendAPI('GetFieldSuggestion');
  const { methods: getUserDepartments, state: getUserDepartmentsState } = BackendAPI.useBackendAPI('GetUserDepartments');

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PUBLICATION_FORM_DISSERTATION', []);

  useEffect(() => {
    if (selectTimeout) {
      clearTimeout(selectTimeout);
    }
    if (formFields.address.value) {
      setSelectTimeout(() =>
        setTimeout(() => {
          getFieldSuggestion.callAPI(
            {
              fieldName: 'Publication.address',
              filter: formFields.address.value,
              limit: 7,
            },
            {
              onSuccessfullCall: ({ data }: any) => {
                setIsAddressLoading(false);
                if (data.success) {
                  setAddressOptions(() =>
                    data.fieldSuggestions.map((fieldSuggestion: any) => ({ ...fieldSuggestion, value: '' })),
                  );
                }
              },
            },
          );
        }, DEBOUNCE_TIME),
      );
    } else {
      setIsAddressLoading(false);
    }
    return () => {
      if (selectTimeout !== null) {
        clearTimeout(selectTimeout);
      }
    };
  }, [formFields.address.value]); // eslint-disable-line

  const fetchPublication = useCallback(() => {
    if (type) {
      getPublication.callAPI(
        {
          simpleFields: {
            status: RecordStatus.DRAFT,
            typeEdition: 'LOCAL',
            type,
            isElectronic: isRecordElectronic,
            electronicType: recordElectronicType,
            presetAuthorCategory: arm === 'pc' ? 'author' : '',
          },
          attrIdFields: {},
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          mobileRequests: {},
          projects: [],
          file: undefined,
          id: publicationId,
          arm,
        },
        {
          onSuccessfullCall: (result: any) => {
            const preparedData = result.data as Form.Publication;

            setByResponse({ setFormFields, preparedData, name });
            setIsRecordElectronic(preparedData.fields.isElectronic === 'true');
            setRecordElectronicType(preparedData.fields.electronicType);
            setMobileRequests(preparedData.mobileRequests);
            setProjects(preparedData.projects);
            setDocuments(preparedData.documents);
            setAuthors(preparedData.members);
            ReactDOM.unstable_batchedUpdates(() => {
              setPublicationInfo(preparedData);
            });
          },
        },
      );
    }
  }, [type, getPublication, isRecordElectronic, recordElectronicType, arm, publicationId, name]);

  const prepareFormFields = useCallback(() => {
    const preparedFormFields = R.clone(formFields);

    const draftValidationResult = validate({
      formFields: preparedFormFields,
      authors,
      isDraft: true,
    });

    const validationResult = validate({
      formFields: preparedFormFields,
      authors,
      isDraft: false,
    });

    return {
      draftValidationResult,
      validationResult,
      preparedFormFields,
    };
  }, [authors, formFields]);

  const fetchBibliographicRecord = useCallback<Form.FetchBibliographicRecordAction>(
    () =>
      new Promise(resolve => {
        if (!formFields.name?.value) {
          showNotification({ theme: 'danger', message: 'Не заполнено название' });
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            name: { ...prevState.name, isValid: false },
          }));

          resolve(null);
          return;
        }

        const fieldsForRequest = formatToRequest({
          isElectronic: isRecordElectronic,
          electronicType: recordElectronicType,
          formFields,
          projects,
          mobileRequests,
          type,
          authors,
          status: 'ADDED',
        });

        getPublication.callAPI(
          {
            ...fieldsForRequest,
            isBibliographicFetch: true,
            arm,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                bibliographicRecord: { ...prevState.bibliographicRecord, value: data.fields.bibliographicRecord },
              }));
              resolve(data.fields.bibliographicRecord);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [arm, authors, formFields, getPublication, isRecordElectronic, mobileRequests, projects, recordElectronicType, type],
  );

  const savePublication = useCallback(
    ({ preparedFormFields, nextStatus, publicationMessage, publicationMessageType, isNeedClose }: Form.SavePublicationData) => {
      const isMessage = !!publicationMessageType?.id;
      const currentFormFields = preparedFormFields || formFields;

      const fieldsForRequest = formatToRequest({
        isElectronic: isRecordElectronic,
        electronicType: recordElectronicType,
        formFields: currentFormFields,
        projects,
        mobileRequests,
        type,
        authors,
        status: nextStatus,
      });
      savePublicationAPI.callAPI(
        {
          id: publicationId,
          ...fieldsForRequest,
          documents,

          feedLine: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
          arm,
          department: publicationDepartment,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setPublicationId(data.status.id);
            if (isNeedClose) {
              onClose();

              tableStreams.reloadTable.push();
            } else {
              updateWorkModeAfterSaveAndContinue();
              if (workMode === 'editMode') {
                fetchPublication();
              }
            }

            showNotification({
              theme: data.success ? 'success' : 'danger',
              message: publicationId
                ? data.success
                  ? nextStatus === RecordStatus.DRAFT
                    ? 'Черновик успешно сохранен!'
                    : 'Публикация успешно отредактирована!'
                  : data.message || 'Произошла ошибка при редактировании публикации!'
                : data.success
                ? nextStatus === RecordStatus.DRAFT
                  ? 'Черновик успешно сохранен!'
                  : 'Публикация успешно сохранена!'
                : data.message || 'Произошла ошибка при сохранении публикации!',
            });
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      arm,
      authors,
      documents,
      fetchPublication,
      formFields,
      isRecordElectronic,
      mobileRequests,
      onClose,
      projects,
      publicationDepartment,
      publicationId,
      recordElectronicType,
      savePublicationAPI,
      tableStreams.reloadTable,
      type,
      updateWorkModeAfterSaveAndContinue,
      workMode,
    ],
  );

  const startCheckDuplicates = useCallback(
    ({ preparedFormFields, nextStatus, isNeedClose }: Form.StartCheckDuplicatesData) => {
      const successfullCallback = ({
        publicationMessage,
        publicationMessageType,
      }: {
        publicationMessage: string;
        publicationMessageType: ReferenceItem;
      }) => {
        savePublication({
          preparedFormFields,
          publicationMessage,
          publicationMessageType,
          isNeedClose,
          nextStatus,
        });
      };

      const isAuthorsCategoryValid = authors.some(({ category }) => category?.customFields?.code === REQUIRED_CATEGORY_CODE);

      preparedStartCheckDuplicateAction.current = () => {
        suchLikePublicationStreams.getSuchLikePublications.push({
          category: 'PUBLICATION',
          searchValue: formFields.name?.value as string,
          successfullCallback,
          publicationType: publicationInfo?.type as Form.PublicationTypeCode,
          currentId: publicationId || null,
          isDefaultSave: !isNeedClose,
          componentId: SUCH_LIKE_PUBLICATION_ID,
        });
      };

      const isNextStatusDraft = nextStatus === RecordStatus.DRAFT;

      const { isFormValid } = validate({
        formFields: preparedFormFields ?? formFields,
        isDraft: isNextStatusDraft,
        authors,
      });

      if (!isAuthorsCategoryValid && isFormValid && !isNextStatusDraft) {
        setIsAuthorsCategoryPopupOpen(true);
      } else {
        preparedStartCheckDuplicateAction.current();
      }
    },
    [
      formFields,
      authors,
      savePublication,
      suchLikePublicationStreams.getSuchLikePublications,
      publicationInfo?.type,
      publicationId,
      SUCH_LIKE_PUBLICATION_ID,
    ],
  );

  const {
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    loadPersonAuthorState,
  } = useSavePublicationHook({
    currentStatus: (publicationInfo?.status?.status as RS.Code) || RecordStatus.DRAFT,
    currentStatusText: publicationInfo?.status.text || 'Черновик',
    setFormFields,
    prepareFormFields,
    startCheckDuplicates,
    savePublication,
    fetchBibliographicRecord,
    initialLanguageCode: initialLanguageCode?.id ?? '',
    authors,
    setAuthors,
    isProfile,
    currentPerson,
    type,
    tabsId,
    authorsTabIndex: 1,
  });

  const { isLoading } = useIsLoading({
    callStates: [
      getPublicationState,
      savePublicationAPIState,
      getFieldSuggestionState,
      loadPersonAuthorState,
      getUserDepartmentsState,
    ],
  });

  const onCreateBibliographicRecordClick = useCallback(() => {
    fetchBibliographicRecord();
  }, [fetchBibliographicRecord]);

  const closePublisherWarning = useCallback(() => {
    setIsPublisherWarningOpen(false);
  }, []);

  useEffect(() => {
    if (publicationId || workMode === 'addMode') fetchPublication();
    // eslint-disable-next-line
  }, [publicationId]);

  useStream(
    () => projectsStreams.setProjects,
    (projectsData: Array<PublicationProject>) => setProjects(projectsData),
    [projects, setProjects, projectsStreams.setProjects],
  );

  useStream(
    () => projectsStreams.accept,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy,
        accepted,
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.accept, currentPerson],
  );

  useStream(
    () => projectsStreams.cancelAcceptance,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy: null,
        accepted: '',
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.cancelAcceptance],
  );

  useStream(
    () => mobileRequestsStream.setMobileRequests,
    (requests: Array<PublicationMobileRequest>) => setMobileRequests(requests),
    [mobileRequests, setMobileRequests, mobileRequestsStream.setMobileRequests],
  );

  useStream(
    () => mobileRequestsStream.accept,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy,
        accepted,
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.accept, currentPerson],
  );

  useStream(
    () => mobileRequestsStream.cancelAcceptance,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy: null,
        accepted: '',
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.cancelAcceptance],
  );

  const addCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        const citationSystemIndex = publicationInfo.citationSystems.findIndex(
          citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
        );
        const isNewCitationSystem = citationSystemIndex === -1;

        if (isNewCitationSystem) {
          setPublicationInfo(prevState => ({
            ...prevState!,
            citationSystems: [...prevState!.citationSystems, citationSystem],
          }));
        } else {
          setPublicationInfo(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState!.citationSystems));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return {
              ...prevState!,
              citationSystems: nextCitationSystems,
            };
          });
        }
      }
    },
    [publicationInfo],
  );

  const editCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      const citationSystemToUpdateIndex = (publicationInfo?.citationSystems || []).findIndex(
        citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
      );

      if (citationSystemToUpdateIndex !== -1) {
        const clonedCitationSystems: Form.CitationSystem[] = publicationInfo?.citationSystems
          ? R.clone(publicationInfo.citationSystems)
          : [];
        clonedCitationSystems.splice(citationSystemToUpdateIndex, 1, citationSystem);

        setPublicationInfo(prevState => prevState && { ...prevState!, citationSystems: clonedCitationSystems });
      }
    },
    [publicationInfo?.citationSystems],
  );

  const removeCitationSystem = useCallback(
    (citationSystemId: string) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        setPublicationInfo(prevState => ({
          ...prevState!,
          citationSystems: [
            ...prevState!.citationSystems.filter(
              (system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId,
            ),
          ],
        }));
      }
    },
    [publicationInfo],
  );

  const onAuthorsCategegoryPopupSubmit = useCallback(() => {
    if (preparedStartCheckDuplicateAction.current) {
      preparedStartCheckDuplicateAction.current();
      setIsAuthorsCategoryPopupOpen(false);
    }
  }, []);

  const onAuthorsCategegoryPopupClose = useCallback(() => {
    setIsAuthorsCategoryPopupOpen(false);
  }, []);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        PublicationHeader({
          settings,
          mode: ModePublicationHeader.default,
          workMode,
          publicationId: publicationInfo?.status.id || '',
          publicationTypeCode: publicationInfo?.type || '',
          publicationElectronicTypeCode: isElectronic ? electronicType : '',
          departments: publicationInfo?.departments || [],
          status: publicationInfo?.status.text || '',
          publicationTypeEdition: {
            id: publicationInfo?.fields.typeEditionCode || '',
            label: publicationInfo?.fields.typeEditionText || '',
          },
          isPreprint: false,
        }),
      );
    }
  }, [
    electronicType,
    isElectronic,
    publicationInfo?.departments,
    publicationInfo?.fields.typeEditionCode,
    publicationInfo?.fields.typeEditionText,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
    workMode,
  ]);

  useLayoutEffect(() => {
    if (!id)
      getUserDepartments.callAPI(
        { isParent: false },
        {
          onSuccessfullCall: ({ data }) => {
            const d = data.map(x => x.ref);
            setUserDepartments(d);
            setPublicationDepartment(d.find(x => x.id === userSystemDepartment?.id) || null);
          },
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    workMode,
    formFields,
    isAuthorsCategegoryPopupOpen,
    onAuthorsCategegoryPopupSubmit,
    onAuthorsCategegoryPopupClose,
    authors,
    setAuthors,
    documents,
    setDocuments,
    isAddressLoading,
    addressOptions,
    setIsAddressLoading,
    onCreateBibliographicRecordClick,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    isElectronic: isRecordElectronic,
    electronicType: recordElectronicType,
    relatedTableState,
    isPublisherWarningOpen,
    closePublisherWarning,
    publicationId,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    projects,
    mobileRequests,
    topMessage,
    setTopMessage,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
  };
}
