import { useMemo, useCallback } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Report, Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { ProjectAssignmentStatus } from 'types/models/Project';
import { State } from '../makeUseCustomController';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import {
  approverKindTitleMap,
  isNextPurchaseRequestStatus,
  PurchaseRequestApprover,
  PurchaseRequestStatus,
} from 'types/models/PurchaseRequest';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
  customState: State;
  projectId: string;
};

const useController = ({ customState, tableState, projectId }: Props) => {
  const tableStreams = useLocalTableStreams();

  const {
    setIsOpenMessageModal,
    newStatus,
    setNewStatus,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isMessageHistoryModalOpen,
    setIsMessageHistoryModalOpen,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    approverKind,
    setApproverKind,
  } = customState;

  const reports = useMemo<Report[]>(() => [Reports.ServiceNotePurchase], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const { methods: changePurchaseRequestStatus } = BackendAPI.useBackendAPI('ChangePurchaseRequestStatus');

  const { methods: addPurchaseRequestApprovement } = BackendAPI.useBackendAPI('AddPurchaseRequestApprovement');

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState]);

  const onAddClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const onCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const onViewClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, [setIsViewFormOpen]);

  const onCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, [setIsViewFormOpen]);

  const onEditClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const onCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const onDeleteClick = useCallback(() => {
    setIsDeleteWarningOpen(true);
  }, [setIsDeleteWarningOpen]);

  const onChangeStatusClick = useCallback(
    (status: ProjectAssignmentStatus) => {
      setNewStatus(status);
      setIsOpenMessageModal(true);
    },
    [setNewStatus, setIsOpenMessageModal],
  );

  const onCloseDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
  }, [setIsDeleteWarningOpen]);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, [setIsHelpFormOpen]);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, [setIsHelpFormOpen]);

  const handleSaveStatusClick = useCallback(
    (message: string) => {
      changePurchaseRequestStatus.callAPI(
        { status: newStatus, message: message || '', ids: tableState.selectedRows.map(r => r.id) },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.success) {
              setNewStatus('');
              setIsOpenMessageModal(false);
              tableStreams.reloadTable.push();
            } else {
              showNotification({ message: data.message, theme: 'danger' });
            }
          },
        },
      );
    },
    [changePurchaseRequestStatus, tableStreams, newStatus, tableState, setNewStatus, setIsOpenMessageModal],
  );

  const onSubmitDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
    if (row?.id) {
      tableStreams.deleteRow.push({
        deleteRowId: row.id,
        command: 'DeletePurchaseRequest',
        deletedItemPropName: 'PurchaseRequest',
      });
    }
  }, [row?.id, tableStreams.deleteRow, setIsDeleteWarningOpen]);

  const selectedRowsLength = useMemo(() => tableState.selectedRows.length, [tableState]);
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(() => {
    return {
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    };
  }, [selectedRowsLength]);
  const isAddButtonDisabled = useMemo(() => !rowsAvailability.ALWAYS, [rowsAvailability]);
  const isViewButtonDisabled = useMemo(() => !rowsAvailability.SINGLE_SELECTED, [rowsAvailability]);
  const isEditButtonDisabled = useMemo(() => !rowsAvailability.SINGLE_SELECTED, [rowsAvailability]);
  const isDeleteButtonDisabled = useMemo(() => {
    if (row && row['id:Status'] === PurchaseRequestStatus.DRAFT) {
      return false;
    }
    return true;
  }, [row]);

  const isChangeStatusDisabled = useCallback(
    (nextStatus: PurchaseRequestStatus) => {
      if (row && isNextPurchaseRequestStatus(row['id:Status'], nextStatus)) {
        return false;
      }
      return true;
    },
    [row],
  );

  const isApprovementDisabled = useCallback(() => {
    if (row && row['id:Status'] == PurchaseRequestStatus.CONSIDERATION) {
      return false;
    }
    return true;
  }, [row]);

  const handleApprovementSubmit = useCallback(
    (message: string) => {
      addPurchaseRequestApprovement.callAPI(
        { purchaseRequestId: row?.id, message, approverKind },
        {
          onSuccessfullCall: ({ data }) => {
            setIsApprovementModalOpen(false);
            tableStreams.reloadTable.push();
            showNotification({ message: data.message || 'Согласование добавлено', theme: 'success' });
          },
        },
      );
    },
    [row, addPurchaseRequestApprovement, tableStreams, setIsApprovementModalOpen, approverKind],
  );

  const approvementButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.approve,
        title: approverKindTitleMap[PurchaseRequestApprover.MANAGEMENT_COMMITTEE],
        textOnly: 'Комитет',
        permissionName: Permits.PURCHASE_REQUEST_AGREE_MANAGEMENT_COMMITTEE,
        isHidden: isApprovementDisabled(),
        onClick: () => {
          setApproverKind(PurchaseRequestApprover.MANAGEMENT_COMMITTEE);
          setIsApprovementModalOpen(true);
        },
      },
      {
        icons: buttonIcons.approve,
        title: approverKindTitleMap[PurchaseRequestApprover.PRICE_COMMISSION],
        textOnly: 'Комиссия',
        permissionName: Permits.PURCHASE_REQUEST_AGREE_PRICE_COMMISSION,
        isHidden: isApprovementDisabled(),
        onClick: () => {
          setApproverKind(PurchaseRequestApprover.PRICE_COMMISSION);
          setIsApprovementModalOpen(true);
        },
      },
      {
        icons: buttonIcons.approve,
        title: approverKindTitleMap[PurchaseRequestApprover.LOGISTIC_MANAGEMENT],
        textOnly: 'Логистика',
        permissionName: Permits.PURCHASE_REQUEST_AGREE_LOGISTIC_MANAGEMENT,
        isHidden: isApprovementDisabled(),
        onClick: () => {
          setApproverKind(PurchaseRequestApprover.LOGISTIC_MANAGEMENT);
          setIsApprovementModalOpen(true);
        },
      },
    ],
    [isApprovementDisabled, setIsApprovementModalOpen, setApproverKind],
  );
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: isViewButtonDisabled,
        onClick: onViewClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        permissionName: Permits.PURCHASE_REQUEST_ADD,
        onClick: onAddClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        permissionName: Permits.PURCHASE_REQUEST_EDIT,
        isDisabled: isEditButtonDisabled,
        onClick: onEditClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        permissionName: Permits.PURCHASE_REQUEST_DELETE,
        isDisabled: isDeleteButtonDisabled,
        onClick: onDeleteClick,
      },
      ...(!projectId || projectId === '-1'
        ? [
            {
              icons: buttonIcons.saveDraft,
              title: 'В черновик',
              permissionName: Permits.PURCHASE_REQUEST_CHANGE_STATUS_DRAFT,
              isHidden: isChangeStatusDisabled(PurchaseRequestStatus.DRAFT),
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.DRAFT),
            },
          ]
        : []),
      {
        icons: buttonIcons.activation,
        title: 'Передать на рассмотрение',
        permissionName: Permits.PURCHASE_REQUEST_CHANGE_STATUS_CONSIDERATION,
        isHidden: isChangeStatusDisabled(PurchaseRequestStatus.CONSIDERATION),
        onClick: () => onChangeStatusClick(PurchaseRequestStatus.CONSIDERATION),
      },
      ...(!projectId || projectId === '-1'
        ? [
            {
              icons: buttonIcons.arrowLoop,
              title: 'Возврат на доработку',
              permissionName: Permits.PURCHASE_REQUEST_CHANGE_STATUS_REVISION,
              isHidden: isChangeStatusDisabled(PurchaseRequestStatus.REVISION),
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.REVISION),
            },
            {
              icons: buttonIcons.approve,
              title: 'В работе (поставщик)',
              permissionName: Permits.PURCHASE_REQUEST_CHANGE_STATUS_IN_ACTION_PROVIDER,
              isHidden: isChangeStatusDisabled(PurchaseRequestStatus.IN_ACTION_PROVIDER),
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.IN_ACTION_PROVIDER),
            },
            {
              icons: buttonIcons.like,
              title: 'Выполнена',
              permissionName: Permits.PURCHASE_REQUEST_CHANGE_STATUS_COMPLETE,
              isHidden: isChangeStatusDisabled(PurchaseRequestStatus.COMPLETE),
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.COMPLETE),
            },
            {
              icons: buttonIcons.deactivation,
              title: 'Отклонена',
              permissionName: Permits.PURCHASE_REQUEST_CHANGE_STATUS_REJECTED,
              isHidden: isChangeStatusDisabled(PurchaseRequestStatus.REJECTED),
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.REJECTED),
            },
            ...approvementButtons,
          ]
        : []),
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      projectId,
      handleHelpButtonClick,
      isViewButtonDisabled,
      onViewClick,
      onAddClick,
      isEditButtonDisabled,
      onEditClick,
      isDeleteButtonDisabled,
      onDeleteClick,
      isChangeStatusDisabled,
      approvementButtons,
      onChangeStatusClick,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    buttons,
    approvementButtons,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    isViewFormOpen,
    onViewClick,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    handleHelpButtonClick,
    handleSaveStatusClick,
    newStatus,
    isMessageHistoryModalOpen,
    setIsMessageHistoryModalOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsDeleteWarningOpen,
    setIsViewFormOpen,
    setIsHelpFormOpen,
    isAddButtonDisabled,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    handleApprovementSubmit,
    getReports,
    onReportClose,
    handleSetCurrentReport,
    isReportOpen,
    currentReport,
  };
};

export default useController;
