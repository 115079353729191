import React, { useMemo } from 'react';

import { Column, ListEditTable } from 'components';

import { Event, Table } from 'types/models';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import ControlPanelParticipations from '../ControlPanelEventParticipations/controlPanelParticipations';
// eslint-disable-next-line max-len
import { TypeControllerIsForm } from 'features/Table/specifications/GetExpositionEventList/LeftPanelForThirdLevel/modalTemplates/ExpositionParticipationTemplate/models/typeControllerIsForm';

type Props = {
  participations: Event.Participations[];
  setIsFormOpen: TypeControllerIsForm;
  selectedRowIndex: number | null;
  setSelectedRowIndex: React.Dispatch<number | null>;
  workMode: Table.WorkMode;
  fetch: () => void;
};

const EventParticipations = ({
  workMode,
  participations,
  setIsFormOpen,
  selectedRowIndex,
  setSelectedRowIndex,
  fetch,
}: Props) => {
  const columns = useMemo<Column<Event.Participations>[]>(
    () => [
      { label: 'Статус', formatValue: row => row.status.name || '', styles: { width: '100px' } },
      {
        label: 'Руководитель',
        formatValue: row => row.supervisor,
        styles: { width: '100px' },
      },
      {
        label: 'Участники',
        formatValue: row => row.participant,
        styles: { width: '140px' },
      },
      {
        label: 'Награды',
        formatValue: row => row.awards || '',
        styles: { width: '100px' },
      },
      { label: 'Формат участия', formatValue: row => row.formLabel ?? '', styles: { width: '100px' } },
      { label: 'Разработка', formatValue: row => row.development || '', styles: { width: '100px' } },
      { label: 'Форма представления', formatValue: row => row.presentationForm || '', styles: { width: '80px' } },
      {
        label: 'Стим. выплаты за подготовку экспоната',
        formatValue: row => row.incentiveRequestPayment || '',
        styles: { width: '140px' },
      },
      {
        label: 'Стим. выплаты за получение награды',
        formatValue: row => row.incentiveAwardPayment || '',
        styles: { width: '140px' },
      },
      {
        label: 'Подразделения',
        formatValue: row => (row.departments ? row.departments : ''),
        styles: { width: '160px' },
      },
      { label: 'Факультеты/Институты', formatValue: row => row.faculties || '', styles: { width: '100px' } },
      { label: 'Примечания', formatValue: row => row.note || '', styles: { width: '100px' } },
      { label: 'Добавлено', formatValue: row => row.createdBy || '', styles: { width: '100px' } },
      { label: 'Дата добавления', formatValue: row => row.createdDate || '', styles: { width: '110px' } },
      { label: 'Изменено', formatValue: row => row.modifiedBy || '', styles: { width: '120px' } },
      { label: 'Дата изменения', formatValue: row => row.modifiedDate || '', styles: { width: '110px' } },
    ],
    [],
  );
  return (
    <>
      {setIsFormOpen && (
        <ControlPanelParticipations
          participationType={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
          workMode={workMode}
          participations={participations}
          setIsFormOpen={setIsFormOpen}
          selectedRowIndex={selectedRowIndex}
          isApproved={
            !(selectedRowIndex === null) ? participations[selectedRowIndex!].status.status === RecordStatus.APPROVED : false
          }
          fetch={fetch}
        />
      )}

      <ListEditTable
        rows={participations}
        defaultRowsCount={participations.length > 10 ? participations.length : 10}
        columns={columns}
        selectRow={setSelectedRowIndex}
        selectedRowIndex={selectedRowIndex}
      />
    </>
  );
};

export const Component = React.memo(EventParticipations);
