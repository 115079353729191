import { useState, useCallback, useMemo } from 'react';
import { NirRequestStatus } from 'utils/Enums';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { IconButtonProps, buttonIcons } from 'components';
import { Permits } from 'utils/Permissions';

type StatusArgs = {
  rows: { id: string; status: NirRequestStatus | null }[];
  callback?(): void;
};

export function getNirRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<NirRequestStatus>(NirRequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeNirRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddNirRequestApprovement');

  const handleButtonClick = useCallback((newStatus: NirRequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push();
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, nirRequestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
          },
        },
      );
    },
    [addApprovementAPI, rows],
  );

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.book,
        title: 'В черновик',
        isDisabled: !rows.length || rows.some(x => !!x.status && [NirRequestStatus.DRAFT].includes(x.status)),
        onClick: () => handleButtonClick(NirRequestStatus.DRAFT, 'В черновик'),
        permissionName: Permits.NIR_REQUEST_CHANGE_STATUS_DRAFT,
        profilePermissionName: Permits.NIR_REQUEST_CHANGE_STATUS_DRAFT,
      },
      {
        icons: buttonIcons.medal,
        title: 'На согласование',
        isDisabled:
          !rows.length || !rows.every(x => !!x.status && [NirRequestStatus.DRAFT, NirRequestStatus.REVISION].includes(x.status)),
        onClick: () => handleButtonClick(NirRequestStatus.REQUEST, 'На согласование'),
        permissionName: Permits.NIR_REQUEST_CHANGE_STATUS_REQUEST,
        profilePermissionName: Permits.NIR_REQUEST_CHANGE_STATUS_REQUEST,
      },

      {
        icons: buttonIcons.like,
        title: 'Присвоить заявке статус "Согласована"',
        code: 'approve',
        isDisabled: !rows.length || !rows.every(x => !!x.status && [NirRequestStatus.REQUEST].includes(x.status)),
        onClick: () => handleButtonClick(NirRequestStatus.AGREED, 'Присвоить заявке статус "Согласована"'),
        permissionName: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED,
        profilePermissionName: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED,
      },
      {
        icons: buttonIcons.yellowLike,
        title: 'Присвоить заявке статус "Согласована с условием"',
        code: 'agreed_with_condition',
        isDisabled:
          !rows.length ||
          !rows.every(
            x => !!x.status && [NirRequestStatus.REQUEST, NirRequestStatus.AGREED, NirRequestStatus.SUPPORTED].includes(x.status),
          ),
        onClick: () =>
          handleButtonClick(NirRequestStatus.AGREED_WITH_CONDITION, 'Присвоить заявке статус "Согласована с условием"'),
        permissionName: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION,
        profilePermissionName: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION,
      },
      {
        icons: buttonIcons.star,
        title: 'Поддержать',
        code: 'support',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [NirRequestStatus.AGREED, NirRequestStatus.AGREED_WITH_CONDITION].includes(x.status)),
        onClick: () => handleButtonClick(NirRequestStatus.SUPPORTED, 'Поддержать'),
        permissionName: Permits.NIR_REQUEST_CHANGE_STATUS_SUPPORTED,
        profilePermissionName: Permits.NIR_REQUEST_CHANGE_STATUS_SUPPORTED,
      },
      {
        icons: buttonIcons.update,
        title: 'Отправить на доработку',
        code: 'revison',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [NirRequestStatus.REQUEST, NirRequestStatus.REJECTED].includes(x.status)),
        onClick: () => handleButtonClick(NirRequestStatus.REVISION, 'Отправить на доработку'),
        permissionName: Permits.NIR_REQUEST_CHANGE_STATUS_REVISION,
        profilePermissionName: Permits.NIR_REQUEST_CHANGE_STATUS_REVISION,
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отклонить',
        code: 'reject',
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [NirRequestStatus.REQUEST, NirRequestStatus.AGREED_WITH_CONDITION].includes(x.status)),
        onClick: () => handleButtonClick(NirRequestStatus.REJECTED, 'Отклонить'),
        permissionName: Permits.NIR_REQUEST_CHANGE_STATUS_REJECTED,
        profilePermissionName: Permits.NIR_REQUEST_CHANGE_STATUS_REJECTED,
      },
      {
        icons: buttonIcons.approve,
        title: 'Экспертная оценка',
        isDisabled: rows.length !== 1 || (!!rows[0]?.status && rows[0].status === NirRequestStatus.DRAFT),
        onClick: () => setIsApprovementModalOpen(true),
        permissionName: Permits.NIR_REQUEST_AGREE,
        profilePermissionName: Permits.NIR_REQUEST_AGREE,
      },
    ],
    [rows, handleButtonClick],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}
