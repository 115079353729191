import * as R from 'ramda';

import { Mip, Subject } from 'types/models';
import { ForeignSecurityDocument } from 'types/models/Subject';

import { convertMaybeArray, getId, getText, convertItem, convertReference, convertDocument } from '../commonConverters';

import { convertMember, convertRntd } from '../patentResearch/converters';

export function convertSubject(subject: any): Subject {
  const rntds = subject.Rntds?.Rntd ?? [];
  const mips = subject.Mips?.Mip ?? [];
  const udks = subject.Udks?.Udk ?? [];
  const okveds = subject.Okveds?.Okveds ?? [];
  const grntis = subject.Grntis?.Grnti ?? [];
  const lksetss = subject.Lksetss?.Lksets ?? [];
  const scienceBrunches = subject.ScienceBrunches?.ScienceBrunch ?? [];
  const scienceDomainInterrests = subject.ScienceDomainInterrests?.ScienceDomainInterrest ?? [];
  const pnrs = subject.Pnrs?.Pnr ?? [];
  const pnmitrs = subject.Pnmitrs?.Pnmitr ?? [];
  const pnis = subject.Pnis?.Pni ?? [];
  const ntrStrategies = subject.NtrStrategies?.NtrStrategy ?? [];
  const technologyPlatforms = subject.TechnologyPlatforms?.TechnologyPlatform ?? [];
  const priorityTechnologies = subject.PriorityTechnologies?.PriorityTechnology ?? [];
  const domainKnowledges = subject.DomainKnowledges?.DomainKnowledge ?? [];
  const serverDocuments = subject.Documents?.Document || [];
  const serverForeignDocuments = subject.ForeignDocuments?.Document || [];
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, R.partial(convertDocument, ['foreign']));
  const localDocuments = convertMaybeArray(serverDocuments, R.partial(convertDocument, ['local']));
  const documents = [...foreignDocuments, ...localDocuments];
  const foreignSecurityDocuments = subject.ForeignSecurityDocuments?.ForeignSecurityDocument || [];

  return {
    id: getId(subject),
    type: convertItem(subject.Type),
    code: getText(subject.Code),
    name: getText(subject.Name),
    description: getText(subject.Description),
    rntds: convertMaybeArray(rntds, convertRntd),
    mips: convertMaybeArray(mips, convertMip),
    udks: convertMaybeArray(udks, convertReference),
    okveds: convertMaybeArray(okveds, convertReference),
    grntis: convertMaybeArray(grntis, convertReference),
    lksetss: convertMaybeArray(lksetss, convertReference),
    scienceBrunches: convertMaybeArray(scienceBrunches, convertReference),
    scienceDomainInterrests: convertMaybeArray(scienceDomainInterrests, convertReference),
    pnrs: convertMaybeArray(pnrs, convertReference),
    pnmitrs: convertMaybeArray(pnmitrs, convertReference),
    pnis: convertMaybeArray(pnis, convertReference),
    ntrStrategies: convertMaybeArray(ntrStrategies, convertReference),
    technologyPlatforms: convertMaybeArray(technologyPlatforms, convertReference),
    priorityTechnologies: convertMaybeArray(priorityTechnologies, convertReference),
    domainKnowledges: convertMaybeArray(domainKnowledges, convertReference),
    documents,
    foreignSecurityDocuments: convertMaybeArray(foreignSecurityDocuments, convertForeignSecurityDocument),
  };
}

function convertMip(mip: any): Mip {
  return {
    id: getId(mip),
    enterpriseCustomer: convertReference(mip.EnterpriseCustomer),
  };
}

function convertForeignSecurityDocument(foreignSecurityDocument: any): ForeignSecurityDocument {
  const members = foreignSecurityDocument.Members?.Member || [];
  return {
    id: getId(foreignSecurityDocument),
    type: foreignSecurityDocument.Type ? convertItem(foreignSecurityDocument.Type) : null,
    kind: foreignSecurityDocument.Kind ? convertReference(foreignSecurityDocument.Kind) : null,
    name: getText(foreignSecurityDocument.Name),
    number: getText(foreignSecurityDocument.Number),
    startDate: getText(foreignSecurityDocument.StartDate),
    endDate: getText(foreignSecurityDocument.EndDate),
    members: convertMaybeArray(members, convertMember),
  };
}
