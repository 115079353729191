import { Program } from 'types/models';

import { convertFile, convertItem, convertMaybeArray, convertReference, getId, getText } from '../commonConverters';

export function convertProgram(program: any): Program.Program {
  const customers = program.Customers?.Customer ?? [];
  const indicators = program.Indicators?.ResultElement ?? [];
  const indices = program.Indices?.ResultElement ?? [];
  const directions = program.Directions?.Direction ?? [];
  const domainKnowledges = program.DomainKnowledges?.DomainKnowledge ?? [];

  return {
    id: program._attributes.id,
    ...(program.Type ? { type: convertItem(program.Type) } : {}),
    name: getText(program.Name),
    shortName: getText(program.ShortName),
    customers: convertMaybeArray(customers, convertCustomer),
    indicators: convertMaybeArray(indicators, convertIndicator),
    indices: convertMaybeArray(indices, convertIndicator),
    directions: convertMaybeArray(directions, convertDirection),
    domainKnowledges: convertMaybeArray(domainKnowledges, convertDomainKnowledge),
    startDate: getText(program.StartDate),
    endDate: getText(program.EndDate),
    url: getText(program.Url),
    file: convertFile(program.File),
    logo: convertFile(program.Logo),
  };
}

export function convertCustomer(customer: any): Program.Customer {
  return {
    customerId: customer._attributes.id,
    investmentSourceType: convertReference(customer.InvestmentSourceType),
    refCustomer: convertReference(customer.Customer),
  };
}

export function convertProgramEvent(event: any): Program.ProgramEvent {
  return {
    id: event._attributes.id,
    code: getText(event.Code),
    name: getText(event.Name),
  };
}

export function convertProgramRequest(request: any): Program.ProgramRequest {
  return {
    id: getId(request) || null,
  };
}

export function convertIndicator(indicator: any): Program.Indicator {
  return {
    id: indicator._attributes.id,
    refResultItem: convertReference(indicator.ResultItem ?? indicator.Element),
    firstYearPlan: indicator.FirstYearPlan ? Number(getText(indicator.FirstYearPlan)).toString() : '',
    secondYearPlan: indicator.SecondYearPlan ? Number(getText(indicator.SecondYearPlan)).toString() : '',
    isActive: indicator.IsActive ? getText(indicator.IsActive) === 'true' : null,
  };
}

export function convertDirection(direction: any): Program.Direction {
  return {
    id: direction._attributes.id,
    name: getText(direction.Name),
    code: getText(direction.Code),
  };
}

export function convertDomainKnowledge(domainKnowledge: any): Program.DomainKnowledge {
  return convertReference(domainKnowledge);
}

export function convertSaveProgramResponse(response: any) {
  return {
    id: getText(response.Id),
  };
}
