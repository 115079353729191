import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Modal } from 'components';

const MagazineEditTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  disabledEvent,
}: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook = {
    apiID: 'GetParticipation',
    template: ParticipationTypes.PARTICIPATION_EXPOSITION.template,
    type: ParticipationTypes.PARTICIPATION_EXPOSITION.eventCode,
    viewMode: true,
    editMode: false,
    id: String(id),
    relatedTableState,
    disabledEvent,
    arm: 'pc',
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineEditTemplate);
