import React, { memo } from 'react';

import { BuilderProps, RadioItem } from 'features/BuildReportPopup/models';
import { DefaultRadioList } from '../DefaultRadioList';

const DissertationCouncilReportType = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const initialRadioList: RadioItem[] = [
    { label: 'Для отчётности по диссоветам', value: 'COUNCIL' },
    { label: 'Дла аналитики по всем типам проектов', value: 'ALL' },
  ];
  return (
    <DefaultRadioList
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
      parameterName={parameterName}
      initialRadioList={initialRadioList}
      defaultValue={initialRadioList[0].value}
    />
  );
};

const Component = memo(DissertationCouncilReportType);

export { Component as DissertationCouncilReportType };
