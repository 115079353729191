import { useCallback, useMemo, useState } from 'react';
import * as icons from 'icons';

import { Form as F, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import publicationTypes from 'types/models/Publication/publicationTypes';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';

type Props = {
  tableState: Table.State;
  isSetupSourceRelationMode: boolean;
};

const useController = ({ tableState, isSetupSourceRelationMode }: Props) => {
  const token = getAuthToken();
  const { isProfile } = usePrivatePageContext();
  const { settings } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = useMemo<number>(() => tableState.selectedRows.length, [tableState.selectedRows]);

  const { deletePublication } = useDeletePublication();

  const rowsAvailability = useMemo<Table.ToolbarStateRowsAvailability>(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const [title, setTitle] = useState<string | JSX.Element>('');
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isApproveFormOpen, setIsApproveFormOpen] = useState<boolean>(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState<boolean>(false);
  const [isEditArticlesRelationPopupOpen, setIsEditArticlesRelationPopupOpen] = useState<boolean>(false);

  const removePublication = useCallback(() => {
    const id = tableState.selectedRows[0].id;
    if (id) {
      deletePublication({ publicationId: id });
    }
  }, [deletePublication, tableState.selectedRows]);

  const handleOpenEditArticlesRelationPopup = useCallback(() => {
    setIsEditArticlesRelationPopupOpen(true);
  }, []);

  const handleCloseEditArticlesRelationPopup = useCallback(() => {
    setIsEditArticlesRelationPopupOpen(false);
  }, []);

  const openViewForm = useCallback(() => {
    setIsViewFormOpen(true);
  }, [setIsViewFormOpen]);

  const closeViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, [setIsViewFormOpen]);

  const openEditForm = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const closeEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const openConfirmDeletePopup = useCallback(() => {
    setIsConfirmDeletePopupOpen(true);
  }, [setIsConfirmDeletePopupOpen]);

  const closeConfirmDeletePopup = useCallback(() => {
    setIsConfirmDeletePopupOpen(false);
  }, [setIsConfirmDeletePopupOpen]);

  const confirmDelete = useCallback(() => {
    removePublication();
    closeConfirmDeletePopup();
  }, [removePublication, closeConfirmDeletePopup]);

  const openApproveForm = useCallback(() => {
    setIsApproveFormOpen(true);
  }, [setIsApproveFormOpen]);

  const closeApproveForm = useCallback(() => {
    setIsApproveFormOpen(false);
  }, [setIsApproveFormOpen]);

  const onApprove = useCallback(
    (disapprove: boolean) => {
      const selectedRowId = tableState.selectedRows[0]?.id || '';
      if (selectedRowId) {
        tableStreams.approveRow.push({
          approveRowId: selectedRowId,
          approveItemName: 'Publication',
          command: 'ApprovePublication',
          disapprove,
        });
        closeApproveForm();
      }
    },
    [closeApproveForm, tableState.selectedRows, tableStreams.approveRow],
  );

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const openFile = useCallback(() => {
    if (!tableState.selectedRows[0]) {
      return;
    }
    const fileId = tableState.selectedRows[0]?.FileId;
    if (!fileId) {
      showNotification({ theme: 'danger', message: 'У выбранной публикации нет прикрепленного файла' });
      return;
    }
    downloadFile(fileId || '', token);
  }, [tableState.selectedRows, token]);

  const toolbarButtons = useMemo(
    () => [
      {
        icons: { default: icons.Loop, disabled: icons.LoopDisabled },
        title: 'Просмотр',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: openViewForm,
        permissionName: Permits.PUBLICATION_VIEW,
        isHidden: isSetupSourceRelationMode,
      },
      {
        icons: { default: icons.Edit, disabled: icons.EditDisabled },
        title: 'Редактировать',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: openEditForm,
        permissionName: Permits.MAGAZINE_ARTICLES_EDIT,
        isHidden: isSetupSourceRelationMode,
      },
      {
        icons: { default: icons.Delete, disabled: icons.DeleteDisabled },
        title: 'Удалить',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: openConfirmDeletePopup,
        permissionName: Permits.PUBLICATION_DELETE,
      },
      {
        icons: { default: icons.EditRelations, disabled: icons.EditRelationsDisabled },
        title: 'Редактировать связь статей с журналом',
        isDisabled: !rowsAvailability.MULTIPLE_SELECTED,
        onClick: handleOpenEditArticlesRelationPopup,
        isHidden: !isSetupSourceRelationMode,
        permissionName: Permits.MAGAZINE_ARTICLES_EDIT,
      },
      {
        icons: { default: icons.Approve, disabled: icons.ApproveDisabled },
        title: 'Утверждение статьи',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: openApproveForm,
        permissionName: Permits.PUBLICATION_APPROVE,
        isHidden: isSetupSourceRelationMode || isProfile,
      },
      {
        icons: { default: icons.Link, disabled: icons.LinkDisabled },
        title: 'Открыть статью во внешнем источнике',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: () => {},
        getExpandedList: getLinks,
        isHidden: isSetupSourceRelationMode,
      },
      {
        icons: { default: icons.Download, disabled: icons.DownloadDisabled },
        title: 'Скачать файл, прикрепленный к публикации',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: openFile,
        isHidden: isSetupSourceRelationMode,
      },
    ],
    [
      rowsAvailability.SINGLE_SELECTED,
      rowsAvailability.MULTIPLE_SELECTED,
      openViewForm,
      isSetupSourceRelationMode,
      openEditForm,
      openConfirmDeletePopup,
      handleOpenEditArticlesRelationPopup,
      openApproveForm,
      getLinks,
      openFile,
      isProfile,
    ],
  );
  const selectedType = tableState && tableState?.selectedRows.length ? tableState.selectedRows[0]['id:type'] : '';
  const isRowCanDeleted = tableState?.selectedRows.length && tableState.selectedRows[0]['id:status'] !== 'APPROVED';

  const look: F.Look = {
    apiID: 'GetPublication',
    template: publicationTypes.filter(type => type.code === selectedType)[0]?.template,
    type: selectedType,
    editMode: isEditFormOpen,
    viewMode: isViewFormOpen,
    id: tableState.selectedRows[0]?.id,
    title: tableState.selectedRows[0]?.name,
    relatedTableState: tableState,
    setTitle,
  };

  return {
    isEditArticlesRelationPopupOpen,
    toolbarButtons,
    isEditFormOpen,
    isViewFormOpen,
    handleCloseEditArticlesRelationPopup,
    closeViewForm,
    closeEditForm,
    isApproveFormOpen,
    closeApproveForm,
    onApprove,
    isConfirmDeletePopupOpen,
    closeConfirmDeletePopup,
    confirmDelete,
    isRowCanDeleted,
    look,
    title,
  };
};

export default useController;
