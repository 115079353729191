import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { RecordStatus } from 'utils/Enums';

const RecognitionViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetRecognition',
    template: 'RecognitionApproveConfirm',
    viewMode: true,
    id: String(id),
    relatedTableState,
  };

  const isApproved: boolean = relatedTableState?.selectedRows[0]?.['id:status'] === RecordStatus.APPROVED || false;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isApproved ? 'Перевод признания (награды) в статус "Добавлена"' : 'Утверждение признания (награды)'}
      size="medium"
      viewMode
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(RecognitionViewTemplate);
