import React, { useState, useEffect, useMemo } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import PublicationAddTemplateType from './PublicationAddTemplateType';
import { PublicationType } from 'types/models/Publication/publicationTypes';

type Props = F.TemplateProps & {
  eventId?: string;
};

const MagazineAddTemplate: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, relatedTableState, id, name, eventId } = props;
  const [type, setType] = useState<PublicationType | null>(null);
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [electronicPublicationType, setElectronicPublicationType] = useState<PublicationType>({
    id: '1',
    label: 'Сетевой ресурс',
    labelDeclined: 'Сетевой ресурс',
    text: 'Публикация размещена в сети Интернет',
    code: 'WEB',
  });
  const [title, setTitle] = useState<string | JSX.Element>('');

  const lookToForm = useMemo(
    () => ({
      id,
      apiID: 'GetPublication',
      type: type?.code || '',
      template: type?.template,
      name,
      isElectronic,
      electronicType: electronicPublicationType.code,
      relatedTableState,
      initialTypeEdition: 'LOCAL',
      eventId,
      disableAddParticipationAfterSave: true,
      setTitle,
    }),
    [type, name, isElectronic, electronicPublicationType, id, relatedTableState, eventId],
  );

  useEffect(() => {
    if (isOpen) {
      setType(null);
      setElectronicPublicationType({
        id: ElectronicType.WEB.id,
        label: ElectronicType.WEB.label,
        labelDeclined: ElectronicType.WEB.label,
        text: ElectronicType.WEB.text,
        code: ElectronicType.WEB.code,
      });
      setIsElectronic(false);
    }
  }, [isOpen]);

  const changeSelectedType = (val: PublicationType) => {
    setType(val);
    if (val.isOnPaperOnly) {
      setIsElectronic(false);
    }
  };

  return (
    <>
      {type ? (
        <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'medium-height']}>
          <Form look={lookToForm} onClose={onClose} />
        </Modal>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} title="Добавление публикации" size="medium">
          <PublicationAddTemplateType
            onClose={onClose}
            onSelect={changeSelectedType}
            isElectronic={isElectronic}
            setIsElectronic={setIsElectronic}
            electronicPublicationType={electronicPublicationType}
            setElectronicPublicationType={setElectronicPublicationType}
          />
        </Modal>
      )}
    </>
  );
};
export const Component = React.memo(MagazineAddTemplate);
