import { HelpfulStuff } from 'types/models/HelpfulStuff';
import { convertFile, convertMaybeArray, getText } from '../commonConverters';

export function convertServerHelpfulStuff(serverStuff: any): HelpfulStuff[] {
  const convertServerData = (val: any) => ({
    creator: getText(val.Creator),
    data: getText(val.Data),
    createdDate: getText(val.CreatedDate),
    file: convertFile(val.File),
    id: val?._attributes.id || '',
  });

  return convertMaybeArray(serverStuff.Response?.HelpfulStuff ?? [], convertServerData);
}
