import * as regexp from 'utils/Regexps';

import { Parameter, YearValue } from 'types/models';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const queryBuilder = (yearParameter: Parameter): ValidatorResult => {
  const value = yearParameter.value as YearValue;
  const { required } = yearParameter;

  const isYearValid = new RegExp(regexp.finalYearRegExp).test(value);

  if (value) {
    return { isValid: isYearValid, message: isYearValid ? '' : `Некорректно заполнено поле "${yearParameter.description}"` };
  }
  if (required) {
    return { isValid: false, message: `Заполните поле "${yearParameter.description}"` };
  }

  return { isValid: true, message: '' };
};

export default queryBuilder;
