import { NirRequest } from 'types/models';

export function validate(nir: NirRequest.Stage | null, list: NirRequest.Stage[], index: number | null) {
  if (!nir) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const nirs = list.filter((x, i) => i !== index);
  const currentYear = new Date().getFullYear();

  const isValidName = Boolean(nir.name);
  const isValidYear = Boolean(nir.year);
  const isValidYearValue = nir.year ? Number(nir.year) >= currentYear && Number(nir.year) <= currentYear + 1 : true;
  const yearValueMsg =
    Number(nir.year) < currentYear
      ? `Значение поля Год слишком маленькое, минимальное значение ${currentYear}`
      : `Значение поля Год слишком большое, максимальное значение ${currentYear + 1}`;
  const isValidAmount = Boolean(nir.amount);
  const isValidUniq = (nirs.find(x => x.year === nir.year) ?? null) === null;

  return [
    { isValid: isValidName, invalidMessage: 'Введите название этапа' },
    { isValid: isValidYear, invalidMessage: 'Введите год' },
    { isValid: isValidAmount, invalidMessage: 'Введите сумму финансирования' },
    { isValid: isValidYearValue, invalidMessage: yearValueMsg },
    { isValid: isValidUniq, invalidMessage: 'Данная запись уже существует в списке' },
  ];
}
