import { Table } from 'types/models';
import { GetFundRequestData } from 'services/BackendAPI/configurations/funds/types';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

type Arguments = {
  year: string;
  setYear(year: string): void;
  selectFund(fund: Table.Entry | null): void;
  selectedFund: Table.Entry | null;
  isOnlyByDepartmentParameter: boolean;
};

export function GetFunds({
  year,
  setYear,
  selectFund,
  selectedFund,
  isOnlyByDepartmentParameter,
}: Arguments): Table.Specification<State, GetFundRequestData> {
  return {
    apiID: 'GetFunds',
    header: {
      firstLevel: {
        title: 'Фонд подразделений по основаниям стимулирующих выплат',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    requestData: {
      onlyByDepartment: isOnlyByDepartmentParameter,
    },
    useCustomController: makeUseCustomController({ selectedFund, selectFund, year, setYear }),
  };
}
