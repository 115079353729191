import React from 'react';
import block from 'bem-cn';

import { Header, Toolbar, ListEditTable, ConfirmPopup, FormComponent, Modal, TextArea, UploadFile } from 'components';

import { Component as Block } from './block';
import { useController } from './controller';

import '../style.scss';

const b = block('helpful');

function HelpfulStuff() {
  const {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setIsEditModalOpen,
    clearFormInfo,
    addModalButtons,
    modalData,
    handleModalDataChange,
    selectedRowIndex,
    formFields,
    isEditModalOpen,
    editModalButtons,
    list,
    setSelectedRowIndex,
    buttons,
    isProfile,
  } = useController();

  return (
    <>
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isConfirmPopupOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsConfirmPopupOpen(false)}
        onConfirm={handleDeleteButtonClick}
        icon="warning"
      />

      <Modal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          clearFormInfo();
        }}
        title="Добавление справочных материалов"
        size="medium"
      >
        <Toolbar buttons={addModalButtons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Описание" isRequired>
              <TextArea settings={{ rows: 3 }} value={modalData} onChange={handleModalDataChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Прикрепите файл">
              <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={!!selectedRowIndex} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          clearFormInfo();
        }}
        title="Редактирование списка материалов"
        size="medium"
      >
        <Toolbar buttons={editModalButtons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Описание" isRequired>
              <TextArea settings={{ rows: 3 }} value={modalData} onChange={handleModalDataChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Прикрепите файл">
              <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Scroll maxHeight={200}>
              <ListEditTable
                rows={list}
                columns={[
                  {
                    label: '№',
                    formatValue: (_, index) => index + 1,
                    styles: { width: '5%', textAlign: 'center' },
                  },
                  { label: 'Описание', formatValue: row => row.data },
                  { label: 'Файл', formatValue: row => row.file.name },
                ]}
                selectedRowIndex={selectedRowIndex}
                selectRow={setSelectedRowIndex}
              />
            </FormComponent.Scroll>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </Modal>

      <div className={b()}>
        <Header>Справочные материалы</Header>
        {!isProfile && (
          <div className={b('toolbar')}>
            <Toolbar buttons={buttons} />
          </div>
        )}
        <div className={b('list')}>
          {list.map(e => (
            <Block props={e} key={e.id + e.createdDate} />
          ))}
        </div>
      </div>
    </>
  );
}

export const Component = React.memo(HelpfulStuff);
