import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions/Permits';
import { Report, Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useBackendAPI } from 'services/BackendAPI';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController(tableState: Table.State) {
  const token = getAuthToken();
  const { settings } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRowsAccepted = tableState.selectedRows[0]?.IsAccepted === 'true';
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const { methods: AcceptPublicationProject } = useBackendAPI('AcceptPublicationProject');

  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDownloadPublicationFileDisabled = !(
    rowsAvailability.SINGLE_SELECTED &&
    tableState.selectedRows.length &&
    tableState.selectedRows[0]?.FileId
  );
  const isLinkButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isAcceptButtonDisabled = !(rowsAvailability.SINGLE_SELECTED && tableState.selectedRows.length);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(tableState.selectedRows[0]?.FileId, token);
  }, [tableState.selectedRows, token]);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });
    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const reports = useMemo<Report[]>(
    () => [Reports.EGISUParticipations],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        permissionName: Permits.PUBLICATION_VIEW,
      },
      {
        icons: buttonIcons.download,
        title: 'Скачать файл прикрепленный к публикации',
        code: 'approvePublication',
        isDisabled: isDownloadPublicationFileDisabled,
        onClick: handleDownloadPublicationFile,
      },
      {
        icons: buttonIcons.link,
        title: 'Открыть публикацию во внешнем источнике',
        code: 'openPublicationOuterSource',
        isDisabled: isLinkButtonDisabled,
        onClick: () => {},
        getExpandedList: getLinks,
      },
      {
        icons: buttonIcons.like,
        title: 'Принять к отчету',
        isDisabled: isAcceptButtonDisabled || selectedRowsAccepted,
        onClick: () => {
          AcceptPublicationProject.callAPI(
            { id: tableState.selectedRows[0]?.PublicationProjectId },
            {
              onSuccessfullCall() {
                tableStreams.reloadTable.push();
              },
            },
          );
        },
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отменить принятие к отчету',
        isDisabled: isAcceptButtonDisabled || !selectedRowsAccepted,
        onClick: () => {
          AcceptPublicationProject.callAPI(
            { id: tableState.selectedRows[0]?.PublicationProjectId },
            {
              onSuccessfullCall() {
                tableStreams.reloadTable.push();
              },
            },
          );
        },
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      isViewButtonDisabled,
      handleViewButtonClick,
      isDownloadPublicationFileDisabled,
      handleDownloadPublicationFile,
      isLinkButtonDisabled,
      getLinks,
      isAcceptButtonDisabled,
      selectedRowsAccepted,
      AcceptPublicationProject,
      tableState.selectedRows,
      tableStreams.reloadTable,
      getReports,
      handleSetCurrentReport,
      setIsHelpFormOpen,
    ],
  );
  return {
    isViewFormOpen,
    isViewButtonDisabled,
    handleTemplateCloseViewForm,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}
