import { useMemo } from 'react';
import { useAppDataContext } from 'features/AppData/context';
import { Item } from 'types/models/common';

export function ParticipantList(): Item[] {
  const { settings } = useAppDataContext();
  const result = useMemo(
    () => [
      { label: settings?.organization?.shortName || 'Университет', value: 'TSU' },
      {
        label: `Сторонние организации (не ${settings?.organization?.shortName || 'Университет'})`,
        value: 'Other',
      },
      { label: 'Совместные мероприятия', value: 'SubTSU' },
    ],
    [settings?.organization?.shortName],
  );

  return result;
}
