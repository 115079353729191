import React, { memo } from 'react';

import { DefaultCheckboxList } from '../DefaultCheckboxList';
import { BuilderProps, CheckboxItem } from 'features/BuildReportPopup/models';

const initialIsAcceptedCheckboxes: CheckboxItem[] = [
  {
    value: 'true',
    label: '',
    isChecked: false,
  },
];

const IsAccepted = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  return (
    <DefaultCheckboxList
      initialCheckboxList={initialIsAcceptedCheckboxes}
      parameterName={parameterName}
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
    />
  );
};

const Component = memo(IsAccepted);

export { Component as IsAccepted };
