import { useCallback, useMemo, useState, useEffect, useLayoutEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Form, Table, Tender } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { Customer } from 'types/models/Tender';
import { GetProgramEventSelectList, GetProgramSelectList } from 'features/Table/specifications';
import { ProgramEvent } from 'types/models/Tender';
import { getOptionsFromEnum } from 'features/Form/looks/project/ProjectForm/helpers';
import { WorkMode } from 'types/models/Table';
import { Original } from 'types/models/Form';

type Props = {
  viewMode?: boolean;
  onProgramIdChange(value: string): void;
  onProgramEventIdChange(value: string): void;
  formFields: Form.Fields;
  tender: Tender.Tender;
  setTender: React.Dispatch<React.SetStateAction<Tender.Tender>>;
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  workMode: WorkMode;
};

export function useController(props: Props) {
  const { onProgramIdChange, onProgramEventIdChange, formFields, tender, setTender, setCustomers, workMode } = props;

  const { enumMap } = useAppDataContext();
  const collectiveTypeOptions = useMemo(() => getOptionsFromEnum(enumMap.TenderCollectiveType), [enumMap]);
  const typeOptions = useMemo(() => getOptionsFromEnum(enumMap.TenderType), [enumMap]);

  const programSpecification = GetProgramSelectList({ setProgramId: onProgramIdChange });
  const programEventSpecification = GetProgramEventSelectList({
    setProgramEventId: onProgramEventIdChange,
    programId: formFields.program?.value?.id,
  });

  const [programInfo, setProgramInfo] = useState({ logo: '' });

  const { methods: getProgram } = BackendAPI.useBackendAPI('GetProgram', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data.customers.map(item => {
        const customer: Customer = {
          id: item.customerId || '',
          customerId: item.refCustomer?.id || '',
          // @ts-ignore-next-line
          fullName: item.refCustomer.customFields?.fullName || '',
          address:
            // @ts-ignore-next-line
            [item.refCustomer.customFields?.country?.label, item.refCustomer.customFields?.street].filter(i => i).join(', ') ||
            '',
          // @ts-ignore-next-line
          contacts: item.refCustomer.customFields?.phone || '',
        };
        return customer;
      });
      if (workMode === 'addMode') setCustomers(preparedData);
      setProgramInfo({ logo: data.logo?.id || '' });
    },
  });
  const { methods: getScientistData } = BackendAPI.useBackendAPI('GetScientistData');

  const modalTableRowConverter = useCallback(row => {
    getProgram.callAPI({ id: row.id });

    return {
      id: row.id || '',
      name: row.name,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalProgramEventTableRowConverter = useCallback<(row: Table.Entry) => ProgramEvent>(row => {
    return {
      id: row.id || '',
      name: row.EventName,
      direction: row.ProgramDirectionName
        ? {
            id: '-1', // todo доработать в гриде вывод айдишника сущности direction
            code: '-1', // todo доработать в гриде вывод code сущности direction
            name: row.ProgramDirectionName,
          }
        : null,
    };
  }, []);

  useEffect(() => {
    const i = tender.contacts.findIndex(x => !x.fullName);
    if (i !== -1)
      getScientistData.callAPI(
        { personId: tender.contacts[i].id, modules: ['GENERAL'] },
        {
          onSuccessfullCall: ({ data }) => {
            setTender(prevState => ({
              ...prevState,
              contacts: [...prevState.contacts.map((y, j) => (i === j ? data : y))],
            }));
          },
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tender.contacts]);

  const [backgroundImageId, setBackgroundImageId] = useState('');

  useLayoutEffect(() => {
    setBackgroundImageId(programInfo?.logo || formFields?.program?.value?.logo?.id || '');
  }, [formFields?.program?.value?.logo?.id, programInfo?.logo]);

  const onProgramChange = useCallback(
    (value: Original | null) => {
      formFields.program.onChange(value);
    },
    [formFields.program],
  );

  return {
    typeOptions,
    collectiveTypeOptions,
    programSpecification,
    programEventSpecification,
    modalTableRowConverter,
    modalProgramEventTableRowConverter,
    programInfo,
    onProgramChange,
    backgroundImageId,
    setBackgroundImageId,
  };
}
