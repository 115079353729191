import React, { useMemo } from 'react';
import * as R from 'ramda';

import { buttonIcons, FormComponent, ListEdit, Modal } from 'components';
import { showErrors } from 'utils/Validators';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { Project } from 'types/models';
import { getMockStateRegistrationReport, getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';
import { AddModal } from './AddModal/AddModal';
import { validate } from './validate';
import { useController } from './controller';

type Props = {
  disabled: boolean;
  stages: Project.Stage[];
  reports: Project.StateRegistrationReport[];
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  udkList: Project.RefElement[];
  isFieldsHidden: boolean;
};

export function StateReports(props: Props) {
  const { disabled, reports, stages, udkList, isFieldsHidden, setProject } = props;

  const {
    handleAddReport,
    isOpenAddModal,
    setIsOpenAddModal,
    handleLoadFromStagesData,
    onPreSubmit,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    reports,
    stages,
    setProject,
  });

  const token = getAuthToken();

  const isStagesEqualToReports = useMemo(() => reports.length === stages.length, [reports, stages]);
  return (
    <>
      <ListEdit
        title={`Отчёты для регистрации в ЦИТИС${
          isFieldsHidden ? '. Зарегистрированные отчёты доступны только для просмотра' : ''
        }`}
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        rows={reports}
        onChange={e => setProject(prevState => ({ ...prevState, stateRegistrationReports: e }))}
        defaultRowsCount={10}
        visibleToolbarButtons={['view', 'edit', 'delete']}
        getIsEditDisabled={row => isFieldsHidden && !!row?.reportIkNumber}
        getIsDeleteDisabled={row => isFieldsHidden && !!row?.reportIkNumber}
        extraToolbarButtons={[
          {
            icons: buttonIcons.plus,
            title: 'Добавить',
            onClick: () => setIsOpenAddModal(true),
            checkIsDisabled: () => disabled || isStagesEqualToReports,
            disabledTooltip: 'Вы не можете добавить отчет ни для одного этапа',
          },
          {
            icons: buttonIcons.arrowLoop,
            title: 'Заполнить из Календарного плана',
            checkIsDisabled: () => disabled,
            onClick: report => handleLoadFromStagesData(report),
          },
          {
            icons: buttonIcons.download,
            title: 'Скачать файл отчёта',
            checkIsDisabled: report => !report?.file || disabled,
            onClick: report => downloadFile(report?.file?.id, token),
          },
          {
            icons: buttonIcons.toolbarHelp,
            title: 'Помощь',
            onClick: () => setIsHelpModalOpen(true),
            isHidden: !isFieldsHidden,
          },
        ]}
        columns={[
          {
            label: 'Этап',
            formatValue: x => {
              const foundStage = stages.find(stage => stage.number === x.stageNumber);
              return foundStage ? getStageTitle(foundStage) : '';
            },
            styles: { width: '20%' },
          },
          { label: 'Тип отчёта', formatValue: x => x.reportType?.label ?? '', styles: { width: '10%' } },
          { label: 'Название отчёта', formatValue: x => x.reportName, styles: { width: '15%' } },
          { label: 'Ключевые слова', formatValue: x => x.keyWords, styles: { width: '15%' } },
          { label: 'Инв. номер в архиве', formatValue: x => x.reportArchiveNumber, styles: { width: '10%' } },
          { label: 'Номер отчёта (ИК)', formatValue: x => x.reportIkNumber, styles: { width: '10%' } },
          { label: 'Дата сдачи в ЦИТиС', formatValue: x => x.citisDate, styles: { width: '5%' } },
          { label: 'Отправлен заказчику', formatValue: x => x.reportCustomerSendDate, styles: { width: '5%' } },
          {
            label: 'Файл',
            formatValue: x => x.file?.name || '',
            styles: { width: '10%' },
          },
        ]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Отчёт',
          renderComponent: (report, setReport, index, mode) => {
            return (
              <Fields
                report={report || getMockStateRegistrationReport()}
                setReport={setReport}
                stages={stages}
                udkList={udkList}
                isFieldsHidden={isFieldsHidden}
                disabled={mode === 'view'}
              />
            );
          },
          validation: {
            checkIsValid: report => validate(report).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
          onPreSubmit: (row, submit) => onPreSubmit(row, submit),
        }}
      />
      <AddModal
        isOpen={isOpenAddModal}
        stages={stages}
        onSubmit={handleAddReport}
        onClose={() => setIsOpenAddModal(false)}
        reports={reports}
      />
      <Modal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} title="Вкладка Отчёты" size="medium">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>Форма предназначена для планирования графика подготовки и сдачи отчётов по этапам проекта.</p>
            <p>В зависимости от условий договора на выполнение НИР отчёты подлежат:</p>
            <ul>
              <li>
                Регистрации в Единой государственной информационной системе учета научно-исследовательских, опытно-конструкторских
                и технологических работ гражданского назначения (ЕГИСУ ЦИТиС)
              </li>
              <li>Отправке Заказчику</li>
              <li>Сдачи в архив научно-технической информации университета</li>
            </ul>
            <h3>Особенности заполнения формы отчёта:</h3>
            <p>
              План- график подготовки отчётов проекта, которые подлежат обязательной регистрации в ЕГИСУ ЦИТиС, как правило,
              формируется на вкладке Госрегистрация в ЦИТиС ответственными за госрегистрацию проектов и отчётов НИР. Список
              отчётов отображается в разделе <strong>Отчёты для регистрации в ЦИТиС</strong>
            </p>
            <p>
              <strong className="primary">ВАЖНО!</strong> В этом разделе форма отчёта доступна для редактирования до тех пор, пока
              не будет добавлен номер госрегистрации (ИК). Зарегистрированные в ЕГИСУ ЦИТиС отчёты на этой вкладке доступны только
              для просмотра, а для редактирования — на вкладке Госрегистрация в ЦИТиС при наличии права.
            </p>
            <p>
              Отчёты, которые не подлежат обязательной регистрации в ЕГИСУ ЦИТиС, например, промежуточный отчёт для Заказчика, или
              отчёт о патентных исследованиях, желательно планировать и хранить в разделе&nbsp;
              <strong>Список других отчётов по этапам проекта</strong>.
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}
