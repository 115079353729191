import React, { memo, useMemo } from 'react';
import { setup } from 'bem-cn';

import { Checkbox, FormComponent } from 'components';

import useController from './controller';
import { BuilderProps, CheckboxItem } from 'features/BuildReportPopup/models';

import './style.scss';

const block = setup({ mod: '--' });
const checkboxListBlock = block('checkbox-list');

type Props = { initialCheckboxList: CheckboxItem[] } & BuilderProps;

const DefaultCheckboxList = ({ parameters, setParameterValueByName, parameterName, initialCheckboxList }: Props) => {
  const { checkboxListParameter, canBuild, setCheckboxIsCheckedByValue, checkboxes } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
    initialCheckboxList,
  });

  const checkboxList = useMemo(
    () =>
      checkboxes.map((checkbox, index) => (
        <li key={index} className={checkboxListBlock('item')}>
          <FormComponent.Field labelSize="fit">
            <Checkbox
              label={checkbox.label}
              checked={checkbox.isChecked}
              onChange={checked =>
                setCheckboxIsCheckedByValue({
                  value: checkbox.value,
                  nextIsChecked: checked,
                })
              }
            />
          </FormComponent.Field>
        </li>
      )),
    [checkboxes, setCheckboxIsCheckedByValue],
  );

  return (
    <>
      {canBuild && (
        <div className={checkboxListBlock()}>
          <FormComponent.Field label={checkboxListParameter?.description} isRequired={checkboxListParameter?.required}>
            <ul className={checkboxListBlock('list')}>{checkboxList}</ul>
          </FormComponent.Field>
        </div>
      )}
    </>
  );
};

const Component = memo(DefaultCheckboxList);

export { Component as DefaultCheckboxList };
