import { useCallback, useEffect, useMemo, useState } from 'react';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { Table } from 'types/models';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { getLinksMap } from 'utils/Helpers';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { buttonIcons, IconButtonProps } from 'components';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const {
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    deletePublication,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
  } = useDeletePublication();
  const { settings } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;
  const isCitationButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isArticleButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isReleaseButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isApproveButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isIdintifierButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isLinkButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  const [isArticleFormOpen, setIsArticleFormOpen] = useState(false);
  const [isReleaseFormOpen, setIsReleaseFormOpen] = useState(false);
  const [isIdentifierFormOpen, setIsIdentifierFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleCitationButtonClick = useCallback(() => {
    setIsCitationFormOpen(true);
  }, []);
  const handleTemplateCloseCitationForm = useCallback(() => {
    setIsCitationFormOpen(false);
  }, []);

  const handleArticleButtonClick = useCallback(() => {
    setIsArticleFormOpen(true);
  }, []);
  const handleTemplateCloseArticleForm = useCallback(() => {
    setIsArticleFormOpen(false);
  }, []);

  const handleReleaseButtonClick = useCallback(() => {
    setIsReleaseFormOpen(true);
  }, []);
  const handleTemplateCloseReleaseForm = useCallback(() => {
    setIsReleaseFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    const publicationId = tableState.selectedRows[0].id;
    if (publicationId) {
      deletePublication({ publicationId, publicationTypeCode: PublicationTypes.MAGAZINE.code });
    }
    handleCloseDeleteConfirmPopup();
  }, [deletePublication, handleCloseDeleteConfirmPopup, tableState.selectedRows]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);
  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);
  const handleApproveButtonClick = useCallback(() => {
    tableStreams.approveRow.push({
      approveRowId: tableState.selectedRows[0].id,
      command: 'ApprovePublication',
      approveItemName: 'Publication',
    });
  }, [tableState.selectedRows, tableStreams.approveRow]);

  const handleIdentifiersButtonClick = useCallback(() => {
    setIsIdentifierFormOpen(true);
  }, []);

  const handleTemplateCloseIdentifierForm = useCallback(() => {
    setIsIdentifierFormOpen(false);
  }, []);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }

    return links;
  }, [tableState.selectedRows, settings]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        permissionName: Permits.MAGAZINE_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.MAGAZINE_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: Permits.MAGAZINE_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
        permissionName: Permits.MAGAZINE_DELETE,
      },
      {
        icons: buttonIcons.citation,
        title: 'Показатели журнала',
        code: 'citationSystem',
        isDisabled: isCitationButtonDisabled,
        onClick: handleCitationButtonClick,
      },
      {
        icons: buttonIcons.info,
        title: 'Информация о статьях',
        code: 'article',
        isDisabled: isArticleButtonDisabled,
        onClick: handleArticleButtonClick,
      },
      {
        icons: buttonIcons.bookOpen,
        title: 'Выпуски журнала',
        code: 'release',
        isDisabled: isReleaseButtonDisabled,
        onClick: handleReleaseButtonClick,
      },
      {
        icons: buttonIcons.approve,
        title: 'Утвердить',
        code: 'approve',
        isDisabled: isApproveButtonDisabled,
        onClick: handleApproveButtonClick,
        permissionName: Permits.MAGAZINE_APPROVE,
      },
      {
        icons: buttonIcons.label,
        title: 'Идентификаторы журнала',
        code: 'identifiers',
        isDisabled: isIdintifierButtonDisabled,
        onClick: handleIdentifiersButtonClick,
        permissionName: Permits.MAGAZINE_LIBRARY_FIELD_EDIT,
      },
      {
        icons: buttonIcons.link,
        title: 'Перейти на страницу журнала по ссылке',
        code: 'links',
        isDisabled: isLinkButtonDisabled,
        onClick: () => {},
        getExpandedList: getLinks,
      },
    ],
    [
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      isViewButtonDisabled,
      isCitationButtonDisabled,
      isArticleButtonDisabled,
      isReleaseButtonDisabled,
      isApproveButtonDisabled,
      isIdintifierButtonDisabled,
      isLinkButtonDisabled,
      handleEditButtonClick,
      handleAddButtonClick,
      handleViewButtonClick,
      handleCitationButtonClick,
      handleArticleButtonClick,
      handleReleaseButtonClick,
      handleDeleteButtonClick,
      handleApproveButtonClick,
      handleIdentifiersButtonClick,
      getLinks,
    ],
  );

  return {
    isAddFormOpen,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    handleTemplateCloseAddForm,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    isEditButtonDisabled,
    handleCloseEditForm,
    isCitationButtonDisabled,
    isCitationFormOpen,
    handleTemplateCloseCitationForm,
    isArticleButtonDisabled,
    isArticleFormOpen,
    handleTemplateCloseArticleForm,
    isReleaseButtonDisabled,
    isReleaseFormOpen,
    handleTemplateCloseReleaseForm,
    isIdintifierButtonDisabled,
    handleTemplateCloseIdentifierForm,
    isIdentifierFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isViewButtonDisabled,
    buttons,
  };
};

export default useController;
