import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { SecurityDocumentTypes, Table } from 'types/models';
import { Permits } from 'utils/Permissions';

type Props = {
  tableState: Table.State;
  documentType?: string | null;
};

export function useController(props: Props) {
  const { tableState, documentType } = props;
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const securityDocumentId = tableState.selectedRows[0]?.id;

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const permissionByDocumentType = useCallback(
    action => {
      switch (documentType) {
        case SecurityDocumentTypes.KNOW_HOW:
          switch (action) {
            case 'add':
              return Permits.KNOW_HOW_ADD;
              break;
            case 'edit':
              return Permits.KNOW_HOW_EDIT;
              break;
            case 'delete':
              return Permits.KNOW_HOW_DELETE;
              break;
            case 'view':
            default:
              return Permits.KNOW_HOW_VIEW;
              break;
          }
          break;
        case SecurityDocumentTypes.EVIDENCE:
          switch (action) {
            case 'add':
              return Permits.EVIDENCE_ADD;
              break;
            case 'edit':
              return Permits.EVIDENCE_EDIT;
              break;
            case 'delete':
              return Permits.EVIDENCE_DELETE;
              break;
            case 'view':
            default:
              return Permits.EVIDENCE_VIEW;
              break;
          }
          break;
        case SecurityDocumentTypes.PATENT:
        default:
          switch (action) {
            case 'add':
              return Permits.PATENT_ADD;
              break;
            case 'edit':
              return Permits.PATENT_EDIT;
              break;
            case 'delete':
              return Permits.PATENT_DELETE;
              break;
            case 'view':
            default:
              return Permits.PATENT_VIEW;
              break;
          }
          break;
      }
    },
    [documentType],
  );

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: isViewButtonDisabled,
        permissionName: permissionByDocumentType('view'),
        onClick: handleViewButtonClick,
      },
    ],
    [isViewButtonDisabled, handleViewButtonClick, permissionByDocumentType],
  );

  return {
    isViewButtonDisabled,
    isViewFormOpen,
    setIsViewFormOpen,
    handleViewButtonClick,
    buttons,
    securityDocumentId,
  };
}
