import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'isAll',
      list: [
        {
          label: 'Все конкурсы',
          value: 'true',
        },
        {
          label: 'Актуальные сегодня',
          value: 'false',
        },
      ],
      value: 'false',
    },
  ]);
});
