import { ProgramRequest } from 'types/models';

import { Department, Finance, RequestPartner, Performer } from 'types/models/ProgramRequest';
import { ReferenceItem } from 'components';
import { convertDocumentToRequest, getMaybeField } from 'services/BackendAPI/configurations/commonConverters';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { convertEstimatePosition } from '../nirRequests/requestConverters';

export function convertProgramRequestToXML(rc: ProgramRequest.ProgramRequest) {
  return {
    _attr: rc.id ? { id: rc.id } : {},
    ...getMaybeField('Lot', rc.lot?.id && { _attr: { id: rc.lot.id } }),
    ...getMaybeField('Tender', rc.tender?.id && { _attr: { id: rc.tender.id } }),
    Code: rc.code,
    Theme: rc.theme,
    ...(rc.requestDate ? { RequestDate: rc.requestDate } : {}),
    ...(rc.startDate ? { StartDate: rc.startDate } : {}),
    ...(rc.endDate ? { EndDate: rc.endDate } : {}),
    RequestProvideAmount: rc.requestProvideAmount || 0.0,
    ...(rc.requestProvideKind
      ? {
          RequestProvideKind: rc.requestProvideKind,
        }
      : {}),
    ...(rc.requestProvideNote
      ? {
          RequestProvideNote: rc.requestProvideNote,
        }
      : {}),
    ContractProvideAmount: rc.contractProvideAmount || 0.0,
    ...(rc.contractProvideKind
      ? {
          ContractProvideKind: rc.contractProvideKind,
        }
      : {}),
    ...(rc.contractProvideNote
      ? {
          ContractProvideNote: rc.contractProvideNote,
        }
      : {}),
    WarrantyProvideAmount: rc.warrantyProvideAmount || 0.0,
    ...(rc.contractProvideKind
      ? {
          WarrantyProvideKind: rc.warrantyProvideKind,
        }
      : {}),
    ...(rc.warrantyProvideNote
      ? {
          WarrantyProvideNote: rc.warrantyProvideNote,
        }
      : {}),
    ...(rc.keyWords ? { KeyWords: rc.keyWords } : {}),
    ...(rc.annotation ? { Annotation: rc.annotation } : {}),
    ...(rc.note ? { Note: rc.note } : {}),
    ...(rc.periodNote ? { PeriodNote: rc.periodNote } : {}),
    Finances: rc.finances && rc.finances.length ? { Finance: rc.finances.map(convertFinanceToXML) } : {},
    Performers: rc.performers && rc.performers.length ? { Performer: rc.performers.map(convertPerformerToXML) } : {},
    Partners: rc.partners && rc.partners.length ? { Partner: rc.partners.map(convertPartnerToXML) } : {},
    ...(rc.performerCount ? { PerformerCount: rc.performerCount } : {}),
    Departments:
      rc.departments && rc.departments.length
        ? { Department: rc.departments.map((dep, index) => convertDepartmentToXML(dep, index)) }
        : {},
    Grntis: rc.grntis && rc.grntis.length ? { Grnti: rc.grntis.map(convertItemToXML) } : {},
    Udks: rc.udks && rc.udks.length ? { Udk: rc.udks.map(convertItemToXML) } : {},
    Okveds:
      rc.okveds && rc.okveds.length ? { Okved: rc.okveds.filter((o: ReferenceItem) => o.id !== '').map(convertItemToXML) } : {},
    Lksetss: rc.lksetss && rc.lksetss.length ? { Lksets: rc.lksetss.map(convertItemToXML) } : {},
    ScienceBrunches:
      rc.scienceBrunches && rc.scienceBrunches.length
        ? { ScienceBrunch: rc.scienceBrunches.filter((b: ReferenceItem) => b.id !== '').map(convertItemToXML) }
        : {},
    ScienceDomainInterrests:
      rc.scienceDomainInterrests && rc.scienceDomainInterrests.length
        ? { ScienceDomainInterrest: rc.scienceDomainInterrests.map(convertItemToXML) }
        : {},
    CriticalTechnologies:
      rc.criticalTechnologies && rc.criticalTechnologies.length
        ? { CriticalTechnology: rc.criticalTechnologies.map(convertItemToXML) }
        : {},
    DomainKnowledges:
      rc.domainKnowledges && rc.domainKnowledges.length ? { DomainKnowledge: rc.domainKnowledges.map(convertItemToXML) } : {},
    Pnrs: rc.pnrs && rc.pnrs.length ? { Pnr: rc.pnrs.map(convertItemToXML) } : {},
    Pnmitrs: rc.pnmitrs && rc.pnmitrs.length ? { Pnmitr: rc.pnmitrs.map(convertItemToXML) } : {},
    Pnis: rc.pnis && rc.pnis.length ? { Pni: rc.pnis.map(convertItemToXML) } : {},
    NtrStrategies: rc.ntrStrategies && rc.ntrStrategies.length ? { NtrStrategy: rc.ntrStrategies.map(convertItemToXML) } : {},
    TechPlatforms:
      rc.technologyPlatforms && rc.technologyPlatforms.length
        ? { TechPlatform: rc.technologyPlatforms.map(convertItemToXML) }
        : {},
    Documents:
      rc.documents && rc.documents.length
        ? { Document: rc.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)) }
        : {},
    EstimatePositions: rc.estimatePositions.length ? { Position: rc.estimatePositions.map(convertEstimatePosition) } : {},
    ...(!!rc.numberPrefix?.id && { NumberPrefix: { _attr: { id: rc.numberPrefix.id } } }),
  };
}
function convertFinanceToXML(fin: Finance) {
  return {
    ...(fin.id ? { _attr: { id: fin.id } } : {}),
    Year: fin.year,
    FBAmount: fin.fbAmount || 0,
    SFAmount: fin.sfAmount || 0,
    InvestmentSource: {
      _attr: { id: fin.investmentSource?.id },
    },
  };
}

function convertPerformerToXML(perf: Performer) {
  return {
    ...(perf.id ? { _attr: { id: perf.id } } : {}),
    Person: {
      _attr: { id: perf.person?.id },
    },
    Role: perf.role?.value,
    ...getHistoricalSliceXML(perf),
  };
}

function convertPartnerToXML(partner: RequestPartner) {
  return {
    Partner: {
      _attr: { id: partner?.partner?.id },
    },
    Note: partner.note,
  };
}

export function convertItemToXML(item: ReferenceItem) {
  return {
    ...(item.id ? { _attr: { id: item.id } } : {}),
  };
}
function convertDepartmentToXML(dep: Department, index: number) {
  return {
    _attr: { id: dep.id },
    Unit: {
      _attr: { id: dep.unit.id },
    },
    Governance: {
      _attr: { id: dep.governance.id },
    },
    Position: dep.position || index,
  };
}
