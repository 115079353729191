import React, { useCallback } from 'react';

import { Checkbox, ConfirmPopup, FormComponent, ListEdit, TextDateTime, TextInput, TextInputMode } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  viewMode: boolean;
};

const ReleaseForm = ({ onClose, viewMode }: Props) => {
  const {
    formFields,
    conferences,
    conferenceColumns,
    submitRelease,
    updateConferences,
    relationTableModalState,
    warningPopupText,
    closeWarningPopup,
    submitWarningPopup,
    isWarningPopupOpen,
    eventId,
    onSubmitReleaseClick,
    isLoading,
  } = useController({ onClose });

  const onReleaseSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submitRelease();
    },
    [submitRelease],
  );

  return (
    <FormComponent.Template>
      <ConfirmPopup
        title="Внимание"
        text={warningPopupText}
        isOpen={isWarningPopupOpen}
        resetButtonText="Отмена"
        okButtonText="Изменить"
        onClose={closeWarningPopup}
        onConfirm={submitWarningPopup}
        icon="info"
      />

      <ControlPanel handleSave={onSubmitReleaseClick} viewMode={viewMode} isDisabled={isLoading} />

      <form onSubmit={onReleaseSubmit}>
        <FormComponent.Wrapper>
          <FormComponent.Line lineSize="padded" growIndexes={[1, 1]}>
            <FormComponent.Field label={formFields.Year.title} isRequired>
              <TextInput mode={TextInputMode.year} value={formFields.Year.value} onChange={formFields.Year.onChange} />
            </FormComponent.Field>
            <FormComponent.Field label={formFields.Month.title}>
              <TextInput value={formFields.Month.value} onChange={formFields.Month.onChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.Part.title}>
              <TextInput value={formFields.Part.value} onChange={formFields.Part.onChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.Number.title}>
              <TextInput value={formFields.Number.value} onChange={formFields.Number.onChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.PublicationDate.title}>
              <TextDateTime value={formFields.PublicationDate.value} onChange={formFields.PublicationDate.onChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="">
              <Checkbox
                label={formFields.isConferenceMaterialsPublished.title}
                checked={!!formFields.isConferenceMaterialsPublished.value}
                onChange={formFields.isConferenceMaterialsPublished.onChange}
                isDisabled={Boolean(eventId)}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <ListEdit
            isRequired={formFields.isConferenceMaterialsPublished.value}
            title="Конференции"
            rows={conferences}
            specification={relationTableModalState}
            columns={conferenceColumns}
            onChange={updateConferences}
            withMessages
            isToolbarDisabled={!formFields.isConferenceMaterialsPublished.value || viewMode || Boolean(eventId)}
            isDeleteConfirmEnabled
          />
        </FormComponent.Wrapper>
      </form>
    </FormComponent.Template>
  );
};

export const Component = React.memo(ReleaseForm);
