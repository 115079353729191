import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, IconButtonProps } from 'components';

import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { showNotification } from 'features/Notifications';
import { helpers } from 'features/Form/views/PublicationOriginalTranslatesRelations';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { getIsSourceCitationDisabled } from 'utils/Helpers/getIsSourceCitationDisabled';
import { Permits } from 'utils/Permissions/Permits';
import { Event, Publication, Table, Report } from 'types/models';
import { ParticipationEventSource } from 'types/models/Participation';
import { useAppDataContext } from 'features/AppData/context';
import { PublicationTypeCode } from 'types/models/Form';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useLocalTableStreams } from 'features/Table/hooks';
import { isCompilation, isTSUEdition } from 'features/EditRelationsModal/helpers';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { Props as ButtonProps } from 'components/Button';

export function useController(tableState: Table.State) {
  const token = getAuthToken();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const tableStreams = useLocalTableStreams();
  const { settings } = useAppDataContext();

  const selectedRow = tableState.selectedRows[0] || null;

  const [publication, setPublication] = useState<Publication | null>(null);
  const [publicationConferenceParticipation, setPublicationConferenceParticipation] = useState<Event.Source | null>(null);
  const [publicationConferenceSource, setPublicationConferenceSource] = useState<ParticipationEventSource | null>(null);

  const [isAddFormOpenConferenceParticipation, setIsAddFormOpenConferenceParticipation] = useState(false);

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isDoiPopupOpened, setIsDoiPopupOpened] = useState(false);
  const [isOpenEditRelations, setIsOpenEditRelations] = useState(false);
  const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  const [isPublicationOriginalTranslatesRelationsOpen, setIsPublicationOriginalTranslatesRelationsOpen] = useState(false);
  const [isSourceCitationOpen, setIsSourceCitationOpen] = useState(false);
  const [isFinancialSupportOpen, setIsFinancialSupportOpen] = useState(false);
  const [isPrepreintRelationOpen, setIsPrepreintRelationOpen] = useState(false);
  const [isTransformOpen, setIsTransformOpen] = useState(false);
  const [isUsagesListOpen, setIsUsagesListOpen] = useState(false);

  const [nextPublicationName, setNextPublicationName] = useState('');

  const { methods: getPublicationAPI } = BackendAPI.useBackendAPI('GetPublicationById', {
    onSuccessfullCall: ({ data }) => {
      setPublication(data);
    },
  });
  const { methods: sendToLibraryAPI } = BackendAPI.useBackendAPI('ExportPublicationToVirtua', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Отправлено в библиотеку', theme: 'success' });
    },
  });

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PUBLICATION_EXTENDED_LIST', []);

  const isSelectedPublicationTransformable = useMemo(() => {
    return (
      selectedRow &&
      (selectedRow['id:PublicationType'] === PublicationTypes.MAGAZINE_ARTICLE.code ||
        selectedRow['id:PublicationType'] === PublicationTypes.PREPRINT_ARTICLE.code)
    );
  }, [selectedRow]);

  const isSourceCitationDisabled = useMemo(() => {
    if (selectedRow) return getIsSourceCitationDisabled({ row: selectedRow });
    return true;
  }, [selectedRow]);

  const isPublicationOriginalTranslatesRelationsDisabled = useMemo(() => {
    if (selectedRow) return !helpers.isMagazineArticle(selectedRow);
    return true;
  }, [selectedRow]);

  const isEditRelationsDisabled = useMemo(() => {
    if (selectedRow) return isCompilation(selectedRow) ? !isTSUEdition(selectedRow) : false;
    return true;
  }, [selectedRow]);

  const handleCloseFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(false);
  }, []);
  const handleOpenFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(true);
  }, []);

  const sendToLibrary = useCallback(
    (publicationId: string) => {
      sendToLibraryAPI.callAPI({ publicationId });
    },
    [sendToLibraryAPI],
  );

  const getPublication = useCallback(
    (publicationId: string) => {
      getPublicationAPI.callAPI({ publicationId });
    },
    [getPublicationAPI],
  );

  const openDuplicatesForm = useCallback(() => {
    suchLikePublicationStreams.openSuchLikePublication.push({ componentId: SUCH_LIKE_PUBLICATION_ID });
  }, [suchLikePublicationStreams.openSuchLikePublication, SUCH_LIKE_PUBLICATION_ID]);

  const {
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    deletePublication,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
  } = useDeletePublication();

  const isTransformButtonDisabled = !selectedRow || !isSelectedPublicationTransformable;

  const isDownloadPublicationFileDisabled = !(selectedRow && selectedRow?.FileId);
  const isPrepreintRelationDisabled = !(
    selectedRow && selectedRow['id:PublicationType'] === PublicationTypes.PREPRINT_ARTICLE.code
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.AvtodissDetail,
      Reports.Dissertations,
      Reports.MonographDetail,
      Reports.DepartmentsPublications,
      Reports.TotalDepartmentReportAll,
      Reports.PublicationList,
      Reports['PublicationForm2.3'],
      Reports.PublicationExtendedList,
      Reports.PublicationsByCondition,
      Reports.Dissertation,
      Reports.Monograph,
      Reports.Publications,
      Reports.PublicationsYoung,
      Reports.PersonPubplicationMembers,
      Reports.Personal,
      Reports.YearTotal,
      Reports.YearMonograph,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleOpenPreprintRelation = useCallback(() => {
    setIsPrepreintRelationOpen(true);
  }, []);

  const handleOpenUsagesList = useCallback(() => {
    setIsUsagesListOpen(true);
  }, []);

  const handleCloseUsagesList = useCallback(() => {
    setIsUsagesListOpen(false);
  }, []);

  const handleTransform = useCallback(() => {
    setIsTransformOpen(true);
  }, []);

  const handleClosePreprintRelation = useCallback(() => {
    setIsPrepreintRelationOpen(false);
  }, []);

  const handleOpenFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(true);
  }, []);

  const handleCloseFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(false);
  }, []);

  const handleOpenSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(true);
  }, []);

  const handleCloseSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(false);
  }, []);

  const handleOpenCitationForm = useCallback(() => {
    setIsCitationFormOpen(true);
  }, []);

  const handleClosePublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(false);
  }, []);

  const handleOpenPublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(true);
  }, []);

  const handleCloseCitationForm = useCallback(() => {
    setIsCitationFormOpen(false);
  }, []);

  const handleSendToLibrary = useCallback(() => {
    if (selectedRow) sendToLibrary(selectedRow.id);
  }, [selectedRow, sendToLibrary]);

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams.reloadTable]);

  const submitDoiPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const submitFinancialPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const submitPublicationOriginalTranslatesRelationsOpen = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleOpenDoiPopup = useCallback(() => {
    setIsDoiPopupOpened(true);
  }, []);

  const handleCloseDoiPopup = useCallback(() => {
    setIsDoiPopupOpened(false);
  }, []);

  const handleApprovePopupOpen = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleApprovePopupClose = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleCloseEditRelationsModal = useCallback(() => {
    setIsOpenEditRelations(false);
  }, []);

  const handleEditRelations = useCallback(() => {
    if (selectedRow) {
      getPublication(selectedRow.id);
      setIsOpenEditRelations(true);
    }
  }, [getPublication, selectedRow]);
  useEffect(() => {
    if (!selectedRow) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRow]);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    const publicationId = selectedRow.id;
    const publicationTypeCode = selectedRow['id:PublicationType'];

    if (publicationId && publicationTypeCode) {
      const preparedPublicationTypeCode = publicationTypeCode as PublicationTypeCode;
      deletePublication({ publicationId, publicationTypeCode: preparedPublicationTypeCode });
    }
    handleCloseDeleteConfirmPopup();
  }, [deletePublication, handleCloseDeleteConfirmPopup, selectedRow]);

  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(selectedRow?.FileId, token);
  }, [selectedRow?.FileId, token]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: selectedRow,
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [selectedRow, settings]);

  const onApprove = useCallback(
    (disapprove: boolean) => {
      const selectedRowId = selectedRow?.id || '';
      if (selectedRowId) {
        tableStreams.approveRow.push({
          approveRowId: selectedRowId,
          approveItemName: 'Publication',
          command: 'ApprovePublication',
          disapprove,
        });
        handleApprovePopupClose();
      }
    },
    [handleApprovePopupClose, selectedRow?.id, tableStreams.approveRow],
  );

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
        permissionName: Permits.PUBLICATION_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        onClick: handleAddButtonClick,
        permissionName: Permits.PUBLICATION_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: !selectedRow,
        onClick: handleEditButtonClick,
        permissionName: Permits.PUBLICATION_EDIT,
      },
      {
        icons: buttonIcons.editRelations,
        title: 'Редактировать связь публикации с подразделениями',
        code: 'editRelations',
        isDisabled: isEditRelationsDisabled,
        onClick: handleEditRelations,
        permissionName: Permits.PUBLICATION_DEPARTMENTS_EDIT,
      },
      {
        icons: buttonIcons.star,
        title: 'Связи публикации с другими объектами',
        code: 'usagesList',
        isDisabled: !selectedRow,
        onClick: handleOpenUsagesList,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: !selectedRow,
        onClick: handleDeleteButtonClick,
        permissionName: Permits.PUBLICATION_DELETE,
      },
      {
        icons: buttonIcons.citation,
        title: 'Показатели публикации',
        code: 'citationSystem',
        isDisabled: !selectedRow,
        onClick: handleOpenCitationForm,
      },
      {
        icons: buttonIcons.sourceScore,
        title: 'Показатели источника',
        code: 'sourceScore',
        isDisabled: isSourceCitationDisabled,
        onClick: handleOpenSourceCitation,
      },
      {
        icons: buttonIcons.approve,
        title: 'Утверждение публикации',
        code: 'sourceScore',
        isDisabled: !selectedRow,
        onClick: handleApprovePopupOpen,
        permissionName: Permits.PUBLICATION_APPROVE,
      },
      {
        icons: buttonIcons.download,
        title: 'Скачать файл прикрепленный к публикации',
        code: 'approvePublication',
        isDisabled: isDownloadPublicationFileDisabled,
        onClick: handleDownloadPublicationFile,
      },
      {
        icons: buttonIcons.link,
        title: 'Открыть публикацию во внешнем источнике',
        code: 'openPublicationOuterSource',
        isDisabled: !selectedRow,
        onClick: () => {},
        getExpandedList: getLinks,
      },
      {
        icons: buttonIcons.sendToLibrary,
        title: 'Отправить в библиотеку',
        code: 'sendPublicationToLibrary',
        isDisabled: !selectedRow,
        onClick: handleSendToLibrary,
        permissionName: Permits.PUBLICATION_EXPORT,
      },
      {
        icons: buttonIcons.doi,
        title: 'Указать DOI статьи',
        code: 'publicationDOI',
        isDisabled: !selectedRow,
        onClick: handleOpenDoiPopup,
        permissionName: Permits.PUBLICATION_DOI_EDIT,
      },
      {
        icons: buttonIcons.articleRelations,
        title: 'Указать статью-оригинал/переводную статью',
        code: 'articleRelations',
        isDisabled: isPublicationOriginalTranslatesRelationsDisabled,
        onClick: handleOpenPublicationOriginalTranslatesRelations,
        permissionName: Permits.PUBLICATION_ARTICLE_TRANSLATION_EDIT,
      },
      {
        icons: buttonIcons.financialSupport,
        title: 'Финансовая поддержка публикации',
        code: 'publicationFinancialSupport',
        isDisabled: !selectedRow,
        onClick: handleOpenFinancialSupport,
        permissionName: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT,
      },
      {
        icons: buttonIcons.preprintRelation,
        title: 'Указать связь между препринтом и публикацией',
        isDisabled: isPrepreintRelationDisabled,
        onClick: handleOpenPreprintRelation,
        permissionName: Permits.PUBLICATION_EDIT,
      },
      {
        icons: buttonIcons.update,
        title: 'Трансформация',
        isDisabled: isTransformButtonDisabled,
        onClick: handleTransform,
        permissionName: Permits.PUBLICATION_TRANSFORM,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      selectedRow,
      handleViewButtonClick,
      handleAddButtonClick,
      handleEditButtonClick,
      isEditRelationsDisabled,
      handleEditRelations,
      handleOpenUsagesList,
      handleDeleteButtonClick,
      handleOpenCitationForm,
      isSourceCitationDisabled,
      handleOpenSourceCitation,
      handleApprovePopupOpen,
      isDownloadPublicationFileDisabled,
      handleDownloadPublicationFile,
      getLinks,
      handleSendToLibrary,
      handleOpenDoiPopup,
      isPublicationOriginalTranslatesRelationsDisabled,
      handleOpenPublicationOriginalTranslatesRelations,
      handleOpenFinancialSupport,
      isPrepreintRelationDisabled,
      handleOpenPreprintRelation,
      isTransformButtonDisabled,
      handleTransform,
      getReports,
      handleSetCurrentReport,
    ],
  );

  const buttonsNew: ButtonProps[] = useMemo(
    () => [
      {
        icon: { type: 'view' },
        hint: 'Просмотр',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
        permission: { name: Permits.PUBLICATION_VIEW },
      },
      {
        icon: { type: 'add' },
        hint: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PUBLICATION_ADD },
      },
      {
        icon: { type: 'edit' },
        hint: 'Редактировать',
        isDisabled: !selectedRow,
        onClick: handleEditButtonClick,
        permission: { name: Permits.PUBLICATION_EDIT },
      },
      {
        icon: { type: 'connection' },
        hint: 'Редактировать связь публикации с подразделениями',
        isDisabled: isEditRelationsDisabled,
        onClick: handleEditRelations,
        permission: { name: Permits.PUBLICATION_DEPARTMENTS_EDIT },
      },
      {
        icon: { type: 'star' },
        hint: 'Связи публикации с другими объектами',
        isDisabled: !selectedRow,
        onClick: handleOpenUsagesList,
      },
      {
        icon: { type: 'remove' },
        hint: 'Удалить',
        isDisabled: !selectedRow,
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.PUBLICATION_DELETE },
      },
      {
        icon: { type: 'statistic' },
        hint: 'Показатели публикации',
        isDisabled: !selectedRow,
        onClick: handleOpenCitationForm,
      },
      {
        icon: { type: 'statistic', mode: 'view' },
        hint: 'Показатели источника',
        isDisabled: isSourceCitationDisabled,
        onClick: handleOpenSourceCitation,
      },
      {
        icon: { type: 'check' },
        hint: 'Утверждение публикации',
        isDisabled: !selectedRow,
        onClick: handleApprovePopupOpen,
        permission: { name: Permits.PUBLICATION_APPROVE },
      },
      {
        icon: { type: 'download' },
        hint: 'Скачать файл прикрепленный к публикации',
        isDisabled: isDownloadPublicationFileDisabled,
        onClick: handleDownloadPublicationFile,
      },
      {
        icon: { type: 'link' },
        hint: 'Открыть публикацию во внешнем источнике',
        isDisabled: !selectedRow,
        expandedList: { list: getLinks },
      },
      {
        icon: { type: 'document', mode: 'arrowRight' },
        hint: 'Отправить в библиотеку',
        isDisabled: !selectedRow,
        onClick: handleSendToLibrary,
        permission: { name: Permits.PUBLICATION_EXPORT },
      },
      {
        icon: { type: 'badge', mode: 'add' },
        hint: 'Указать DOI статьи',
        isDisabled: !selectedRow,
        onClick: handleOpenDoiPopup,
        permission: { name: Permits.PUBLICATION_DOI_EDIT },
      },
      {
        icon: { type: 'documents', mode: 'edit' },
        hint: 'Указать статью-оригинал/переводную статью',
        isDisabled: isPublicationOriginalTranslatesRelationsDisabled,
        onClick: handleOpenPublicationOriginalTranslatesRelations,
        permission: { name: Permits.PUBLICATION_ARTICLE_TRANSLATION_EDIT },
      },
      {
        icon: { type: 'currency', mode: 'edit' },
        hint: 'Финансовая поддержка публикации',
        isDisabled: !selectedRow,
        onClick: handleOpenFinancialSupport,
        permission: { name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT },
      },
      {
        icon: { type: 'connection', mode: 'edit' },
        hint: 'Указать связь между препринтом и публикацией',
        isDisabled: isPrepreintRelationDisabled,
        onClick: handleOpenPreprintRelation,
        permission: { name: Permits.PUBLICATION_EDIT },
      },
      {
        icon: { type: 'transform' },
        hint: 'Трансформация',
        isDisabled: isTransformButtonDisabled,
        onClick: handleTransform,
        permission: { name: Permits.PUBLICATION_TRANSFORM },
      },
      {
        icon: { type: 'print' },
        hint: 'Печать документов',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      selectedRow,
      handleViewButtonClick,
      handleAddButtonClick,
      handleEditButtonClick,
      isEditRelationsDisabled,
      handleEditRelations,
      handleOpenUsagesList,
      handleDeleteButtonClick,
      handleOpenCitationForm,
      isSourceCitationDisabled,
      handleOpenSourceCitation,
      handleApprovePopupOpen,
      isDownloadPublicationFileDisabled,
      handleDownloadPublicationFile,
      getLinks,
      handleSendToLibrary,
      handleOpenDoiPopup,
      isPublicationOriginalTranslatesRelationsDisabled,
      handleOpenPublicationOriginalTranslatesRelations,
      handleOpenFinancialSupport,
      isPrepreintRelationDisabled,
      handleOpenPreprintRelation,
      isTransformButtonDisabled,
      handleTransform,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    SUCH_LIKE_PUBLICATION_ID,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    publication,
    isAddFormOpenConferenceParticipation,
    handleCloseFormConferenceParticipation,
    handleOpenFormConferenceParticipation,
    publicationConferenceParticipation,
    setPublicationConferenceParticipation,
    publicationConferenceSource,
    setPublicationConferenceSource,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    handleContinueStep,
    handleTemplateCloseAddForm,
    nextPublicationName,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isEditRelationsDisabled,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isDoiPopupOpened,
    handleCloseDoiPopup,
    submitDoiPopupCallback,
    isApprovePopupOpen,
    onApprove,
    handleApprovePopupClose,
    isCitationFormOpen,
    handleCloseCitationForm,
    isPublicationOriginalTranslatesRelationsDisabled,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    submitPublicationOriginalTranslatesRelationsOpen,
    isPrepreintRelationOpen,
    isSourceCitationDisabled,
    isSourceCitationOpen,
    handleCloseSourceCitation,
    isFinancialSupportOpen,
    handleCloseFinancialSupport,
    submitFinancialPopupCallback,
    handleClosePreprintRelation,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isTransformOpen,
    handleTransform,
    setIsTransformOpen,
    isUsagesListOpen,
    handleCloseUsagesList,
    settings,
    selectedRow,
    buttonsNew,
  };
}
