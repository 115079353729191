import React from 'react';
import { FormComponent, TextGridSelect } from 'components';
import { Item } from 'types/models/common';
import { GetProgramEventList } from 'features/Table/specifications';
import { tableRowConverter } from './helpers';

type Props = {
  programId?: string;
  programEvent: Item<string> | null;
  setProgramEvent: (programEvent: Item<string> | null) => void;
  isDisabled: boolean;
};

export const SelectProgramEvent = ({ programEvent, setProgramEvent, isDisabled, programId }: Props) => (
  <FormComponent.Field label="Мероприятие\Вид конкурса">
    <TextGridSelect
      value={programEvent}
      onChange={setProgramEvent}
      disabled={isDisabled}
      specification={GetProgramEventList({ programId: programId || '-1' })}
      settings={{
        mode: 'selectTableRow',
        tableRowConverter,
      }}
      valueFormatter={x => x.label}
      title="Мероприятия"
    />
  </FormComponent.Field>
);
