import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { Author, Event, Table, Report } from 'types/models';
import { ParticipationEventSource } from 'types/models/Participation';
import { getIsSourceCitationDisabled } from 'utils/Helpers/getIsSourceCitationDisabled';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { PublicationTypeCode } from 'types/models/Form';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const { currentPerson } = useAppDataContext();
  const token = getAuthToken();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const tableStreams = useLocalTableStreams();

  const selectedRow = tableState.selectedRows[0] || null;

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isOpenEditRelations, setIsOpenEditRelations] = useState(false);
  const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  const [isPublicationOriginalTranslatesRelationsOpen, setIsPublicationOriginalTranslatesRelationsOpen] = useState(false);
  const [isSourceCitationOpen, setIsSourceCitationOpen] = useState(false);
  const [isFinancialSupportOpen, setIsFinancialSupportOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const [isAddFormOpenConferenceParticipation, setIsAddFormOpenConferenceParticipation] = useState(false);
  const handleCloseFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(false);
  }, []);
  const handleOpenFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(true);
  }, []);

  const [publicationConferenceParticipation, setPublicationConferenceParticipation] = useState<Event.Source | null>(null);
  const [publicationConferenceSource, setPublicationConferenceSource] = useState<ParticipationEventSource | null>(null);

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PERSONAL_PUBLICATION_LIST', []);
  const {
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    deletePublication,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
  } = useDeletePublication();
  const { settings } = useAppDataContext();
  const author = useMemo(
    (): Author => ({
      id: null,
      category: {
        code: '',
        customFields: { code: 'author' },
        deletable: false,
        editable: false,
        foreignName: null,
        id: '1',
        label: 'Автор',
        shortName: null,
      },
      affilateCount: 1,
      historyLabel: '',
      name: currentPerson ? currentPerson.fullName : '',
      job: currentPerson && currentPerson.scientistJobs.length ? currentPerson.scientistJobs[0] : null,
      memberAffilations: [],
      position: 0,
      person: currentPerson || null,
      education: null,
      enterprise: null,
      degree: !(currentPerson && currentPerson.degrees.length) ? null : currentPerson.degrees[0],
      citizenship: null,
      isAffilated: true,
      academicRank: !(currentPerson && currentPerson.academicRanks !== null) ? null : currentPerson.academicRanks[0],
      isReporter: null,
      role: null,
      rank: null,
    }),
    [currentPerson],
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.AvtodissDetail,
      Reports.Dissertations,
      Reports.MonographDetail,
      Reports.DepartmentsPublications,
      Reports.PublicationsByCondition,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: selectedRow,
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }

    return links;
  }, [selectedRow, settings]);

  const isSourceCitationDisabled = useMemo(() => {
    if (selectedRow) return getIsSourceCitationDisabled({ row: selectedRow });
    return true;
  }, [selectedRow]);

  /**
   * запрос публикаций
   */

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams.reloadTable]);

  /**
   * handles
   */

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const openDuplicatesForm = useCallback(() => {
    suchLikePublicationStreams.openSuchLikePublication.push({ componentId: SUCH_LIKE_PUBLICATION_ID });
  }, [suchLikePublicationStreams.openSuchLikePublication, SUCH_LIKE_PUBLICATION_ID]);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    const publicationId = selectedRow.id;
    const publicationTypeCode = selectedRow['id:PublicationType'];

    if (publicationId && publicationTypeCode) {
      const preparedPublicationTypeCode = publicationTypeCode as PublicationTypeCode;
      deletePublication({ publicationId, publicationTypeCode: preparedPublicationTypeCode });
    }
    handleCloseDeleteConfirmPopup();
  }, [deletePublication, handleCloseDeleteConfirmPopup, selectedRow]);

  const handleEditRelations = useCallback(() => {
    if (selectedRow) setIsOpenEditRelations(true);
  }, [selectedRow]);

  const handleCloseEditRelationsModal = useCallback(() => {
    setIsOpenEditRelations(false);
  }, []);

  const handleOpenCitationForm = useCallback(() => {
    setIsCitationFormOpen(true);
  }, []);

  const handleCloseCitationForm = useCallback(() => {
    setIsCitationFormOpen(false);
  }, []);

  const handleOpenSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(true);
  }, []);

  const handleCloseSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(false);
  }, []);

  const handleOpenPublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(true);
  }, []);

  const handleClosePublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(false);
  }, []);

  const submitPublicationOriginalTranslatesRelationsOpen = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const handleOpenFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(true);
  }, []);

  const handleCloseFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(false);
  }, []);

  const submitFinancialPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);
  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(selectedRow?.FileId, token);
  }, [selectedRow?.FileId, token]);
  /**
   * кнопки
   */

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
        profilePermissionName: Permits.PUBLICATION_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        onClick: handleAddButtonClick,
        profilePermissionName: Permits.PUBLICATION_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактирование',
        code: 'edit',
        isDisabled: !selectedRow,
        onClick: handleEditButtonClick,
        profilePermissionName: Permits.PUBLICATION_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'delete',
        isDisabled: !selectedRow,
        onClick: handleDeleteButtonClick,
        profilePermissionName: Permits.PUBLICATION_DELETE,
      },
      {
        icons: buttonIcons.editRelations,
        title: 'Редактировать связь публикации с подразделениями',
        code: 'editRelations',
        isDisabled: !selectedRow,
        onClick: handleEditRelations,
        profilePermissionName: Permits.PUBLICATION_DEPARTMENTS_EDIT,
      },
      {
        icons: buttonIcons.citation,
        title: 'Показатели публикации',
        code: 'citationSystem',
        isDisabled: !selectedRow,
        onClick: handleOpenCitationForm,
        profilePermissionName: Permits.PUBLICATION_CITATION_SYSTEM_EDIT,
      },
      {
        icons: buttonIcons.sourceScore,
        title: 'Показатели источника',
        code: 'sourceScore',
        isDisabled: isSourceCitationDisabled,
        onClick: handleOpenSourceCitation,
        profilePermissionName: Permits.PUBLICATION_CITATION_SYSTEM_INDEX_EDIT,
      },
      {
        icons: buttonIcons.download,
        title: 'Скачать скан-копию публикации',
        isDisabled: !selectedRow,
        onClick: handleDownloadPublicationFile,
      },
      {
        icons: buttonIcons.link,
        title: 'Открыть публикацию во внешнем источнике',
        code: 'openPublicationOuterSource',
        isDisabled: !selectedRow,
        getExpandedList: getLinks,
      },
      {
        icons: buttonIcons.articleRelations,
        title: 'Указать статью-оригинал/переводную статью',
        code: 'articleRelations',
        isDisabled: !selectedRow,
        onClick: handleOpenPublicationOriginalTranslatesRelations,
      },
      {
        icons: buttonIcons.financialSupport,
        title: 'Финансовая поддержка публикации',
        code: 'publicationFinancialSupport',
        isDisabled: !selectedRow,
        onClick: handleOpenFinancialSupport,
        profilePermissionName: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        isDisabled: !selectedRow,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      handleHelpButtonClick,
      handleViewButtonClick,
      handleAddButtonClick,
      handleEditButtonClick,
      handleDeleteButtonClick,
      handleEditRelations,
      handleOpenCitationForm,
      isSourceCitationDisabled,
      handleOpenSourceCitation,
      selectedRow,
      handleDownloadPublicationFile,
      getLinks,
      handleOpenPublicationOriginalTranslatesRelations,
      handleOpenFinancialSupport,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    SUCH_LIKE_PUBLICATION_ID,
    isAddFormOpen,
    isViewFormOpen,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isEditFormOpen,
    handleContinueStep,
    isAddFormOpenConferenceParticipation,
    handleCloseFormConferenceParticipation,
    handleOpenFormConferenceParticipation,
    publicationConferenceParticipation,
    setPublicationConferenceParticipation,
    publicationConferenceSource,
    setPublicationConferenceSource,
    handleTemplateCloseAddForm,
    nextPublicationName,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    buttons,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isCitationFormOpen,
    handleCloseCitationForm,
    isSourceCitationDisabled,
    isSourceCitationOpen,
    handleCloseSourceCitation,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    submitPublicationOriginalTranslatesRelationsOpen,
    isFinancialSupportOpen,
    handleCloseFinancialSupport,
    submitFinancialPopupCallback,
    author,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
