import { useMemo } from 'react';
import { useAppDataContext } from 'features/AppData/context';
import { EnumerationValue } from 'types/models/Merge';
import { getEnum } from 'utils/Helpers';

type Props = {
  enumerationName: string;
};

const useController = ({ enumerationName }: Props) => {
  const { enumMap } = useAppDataContext();

  const options = useMemo<EnumerationValue[]>(() => {
    const enumerationOptions = getEnum(enumerationName, enumMap);

    return enumerationOptions;
  }, [enumMap, enumerationName]);

  return { options };
};

export default useController;
