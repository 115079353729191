import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { block } from 'bem-cn';

import { FormComponent, Popover } from 'components';

import './IconButton.scss';

export type IconButtonProps = {
  icons: { default: string; disabled?: string };
  type?: 'submit';
  title?: string;
  disabledTooltip?: string;
  code?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  onClick?(e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined): void;
  getExpandedList?: () => Map<string, string> | Promise<Map<string, string>>;
  expandedItemCallback?: ({ value, name }: { value: string; name: string }) => void;
  permissionName?: string | string[];
  profilePermissionName?: string | string[];
  hasNoHover?: boolean;
  textOnly?: string;
};

const b = block('icon-button');

function IconButton({
  icons,
  title,
  isDisabled,
  onClick,
  type,
  getExpandedList,
  expandedItemCallback,
  disabledTooltip,
  hasNoHover = false,
  textOnly,
}: IconButtonProps) {
  const [isActive, setIsActive] = useState(false);
  const [items, setItems] = useState<ReactNode[]>([]);

  const wrappedExpandedItemCallback = useCallback(
    ({ value, name }: { value: string; name: string }) => {
      if (typeof expandedItemCallback === 'function') {
        expandedItemCallback({ value, name });
        setIsActive(false);
      }
    },
    [expandedItemCallback],
  );

  const handleButtonClick = async (e: any) => {
    if (getExpandedList) {
      if (isActive) {
        setIsActive(false);
      } else {
        const newExpandedList: Map<string, string> = await getExpandedList();

        if (newExpandedList.size) {
          const result: ReactNode[] = [];
          newExpandedList?.forEach((value, name) => {
            result.push(
              <li className={b('list-item')} key={value + name}>
                {expandedItemCallback && (
                  <button
                    className={b('button-link')}
                    type="button"
                    onClick={() => {
                      wrappedExpandedItemCallback({ value, name });
                    }}
                  >
                    {name}
                  </button>
                )}
                {!expandedItemCallback && <FormComponent.Link href={value} label={name} />}
              </li>,
            );
          });
          setItems(() => result);
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      }
    }

    if (onClick) {
      onClick(e);
    }
  };

  const buttonElement = (
    <button
      className={b('button', { no_hover: hasNoHover })}
      onClick={handleButtonClick}
      disabled={isDisabled}
      type={type ?? 'button'}
    >
      {!!textOnly ? (
        <div className={b('text')}>
          <span>{textOnly}</span>
        </div>
      ) : (
        <img className={b('image')} src={isDisabled ? icons.disabled : icons.default} alt="icon" />
      )}
    </button>
  );

  const handleTooltipClose = useCallback(() => {
    setIsActive(false);
  }, []);

  if (!getExpandedList) {
    return (
      <div className={b('wrapper')} title={isDisabled && disabledTooltip ? disabledTooltip : title}>
        {buttonElement}
      </div>
    );
  }

  const buttonRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div ref={buttonRef} className={b('wrapper')} title={isDisabled && disabledTooltip ? disabledTooltip : title}>
        {buttonElement}
      </div>
      <Popover
        isOpen={isActive}
        onClose={handleTooltipClose}
        anchorRef={buttonRef.current}
        position={{
          offset: 0,
          anchor: {
            vertical: 'top',
            horizontal: 'right',
          },
          content: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <div className={b('list')}>{items}</div>
      </Popover>
    </>
  );
}

const C = React.memo(IconButton);
export { C as IconButton };
