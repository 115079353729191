import React, { useCallback } from 'react';
import * as R from 'ramda';

import { Tabs, Tab, CommentPopup, FormComponent, Modal } from 'components';

import { Table } from 'types/models';
import { EventType } from 'types/models/Payment';
import { State } from '../model';
import { mapTitle } from '../helpers';
import { Toolbar } from './Toolbar/Toolbar';
import { ViewConference, Payment, MessageLog, ViewArticle, ViewMonograph, ViewTextBook, ViewParticipation } from './Tabs';
import { useController } from './useController';
import { Departments } from 'features/Table/views';
import { Documents } from 'features/Documents';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function Form({ customState, tableState }: Props) {
  const { isOpenForm, cancelPayment, mode } = customState;
  const {
    status,
    comment,
    authorPayment,
    isOpenCommentForm,
    typeEvent,
    setIsOpenCommentForm,
    setComment,
    changeField,
    savePayment,
    submitForm,
    prepareChangeStatusEvent,
    changeStatus,
  } = useController(customState);

  const closeCommentForm = useCallback(() => {
    setIsOpenCommentForm(false);
  }, [setIsOpenCommentForm]);

  if (!isOpenForm) {
    return null;
  }

  const isViewMode = mode === 'view';

  const renderElements: Record<
    EventType,
    {
      title: JSX.Element;
      firstTab: JSX.Element;
    }
  > = (() => {
    const generateTitle = (text: string) => {
      const details = [
        authorPayment?.id ? `ID ${authorPayment?.id}` : '',
        authorPayment?.status?.label ? `Статус заявки: ${authorPayment?.status?.label}` : '',
      ]
        .filter(x => x)
        .join('. ');

      const title = [text, details ? `(${details})` : ''].filter(x => x).join(' ');

      const hint = (() => {
        // eslint-disable-next-line max-len
        return 'Добавить или удалить связь с подразделением можно на вкладке "Расчет стимулирующих выплат" с помощью таблицы редактирования подразделений';
      })();

      return (
        <>
          <h3>{title}</h3>
          <h4>
            <Departments
              hint={hint}
              departments={authorPayment?.departments.map(({ topLevelDepartment }) => topLevelDepartment.name).join(', ') || ''}
            />
          </h4>
        </>
      );
    };

    return {
      ARTICLE: {
        title: generateTitle('Заявка на оплату статьи'),
        firstTab: (
          <Tab title="Просмотр статьи">
            <ViewArticle
              isViewMode={isViewMode}
              article={authorPayment?.article ?? null}
              tableState={tableState}
              status={status}
            />
          </Tab>
        ),
      },
      CONFERENCE: {
        title: generateTitle('Заявка на оплату конференции'),
        firstTab: (
          <Tab title="Просмотр конференции">
            <ViewConference eventId={authorPayment?.event?.id ?? null} tableState={tableState} />
          </Tab>
        ),
      },
      MONOGRAPH: {
        title: generateTitle('Заявка на оплату монографии'),
        firstTab: (
          <Tab title="Просмотр публикации">
            <ViewMonograph
              isViewMode={isViewMode}
              publiation={authorPayment?.publication ?? null}
              tableState={tableState}
              status={status}
            />
          </Tab>
        ),
      },
      TEXTBOOK: {
        title: generateTitle('Заявка на оплату учебника, учебного пособия'),
        firstTab: (
          <Tab title="Просмотр публикации">
            <ViewTextBook
              isViewMode={isViewMode}
              publiation={authorPayment?.publication ?? null}
              tableState={tableState}
              status={status}
            />
          </Tab>
        ),
      },
      MEDAL: {
        title: generateTitle('Заявки на оплату награды выставки'),
        firstTab: (
          <Tab title="Участие в выставке">
            <ViewParticipation
              participation={authorPayment?.participation ?? null}
              tableState={tableState}
              onClose={cancelPayment}
            />
          </Tab>
        ),
      },
    };
  })();

  return (
    <Modal isOpen={isOpenForm} onClose={cancelPayment} title={renderElements[typeEvent].title} size="full">
      <FormComponent.Template>
        <Toolbar
          authorPayment={authorPayment}
          onSave={submitForm}
          prepareChangeStatusEvent={prepareChangeStatusEvent}
          isViewMode={isViewMode}
        />

        <FormComponent.Template>
          <Tabs>
            {renderElements[typeEvent].firstTab}
            <Tab title="Расчет стимулирующих выплат">
              <Payment authorPayment={authorPayment} changeField={changeField} disabled={isViewMode} eventType={typeEvent} />
            </Tab>
            <Tab title="Документы">
              <Documents
                isShowSource
                disabled={isViewMode}
                documents={authorPayment?.documents ?? []}
                setDocuments={R.partial(changeField, ['documents'])}
                sourceLabelForLocalDocs="Текущая заявка"
              />
            </Tab>
            {!!authorPayment?.id && (
              <Tab title="Журнал сообщений">
                <MessageLog
                  isDisabledToolbar={isViewMode}
                  authorPaymentId={authorPayment.id}
                  paymentStatus={authorPayment.status?.value}
                />
              </Tab>
            )}
          </Tabs>
        </FormComponent.Template>

        <CommentPopup
          title={(mapTitle as any)[String(status)]}
          isOpen={isOpenCommentForm}
          onClose={closeCommentForm}
          comment={comment}
          setComment={setComment}
          onSubmit={() => {
            if (status !== null) {
              changeStatus();
              return;
            }
            savePayment();
          }}
        />
      </FormComponent.Template>
    </Modal>
  );
}
