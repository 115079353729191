import { useCallback, useMemo, useEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Project, JobPeriod } from 'types/models/Project';
import { ProjectScientistRole } from 'utils/Enums';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { showErrorsMessages } from 'utils/Common';
import { clonePersonHistory, getEnumItem } from 'utils/Helpers';
import { getMockProject, getPerformerIndexByLastJobPeriodRole } from 'features/Form/looks/project/ProjectForm/helpers';
import { Period, CheckboxSettings, ClonePerformersData, CheckboxSettingsKey } from './model';
import { getClonePerformersData, getDefaultCheckboxSettings, getDefaultPeriod } from './helpers';
import { validate } from './validate';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  projectId: string;
  onClose: () => void;
  submitCallback: (preparedProject: Project) => void;
};

const useController = ({ projectId, onClose, submitCallback }: Props) => {
  const [project, setProject] = useState<Project | null>(null);
  const [checkboxSettings, setCheckboxSettings] = useState<CheckboxSettings>(getDefaultCheckboxSettings());
  const [period, setPeriod] = useState<Period>(getDefaultPeriod());
  const [clonePerforemersData, setClonePerformersData] = useState<ClonePerformersData>(getClonePerformersData());
  const { methods: getProjectAPI, state: getProjectState } = BackendAPI.useBackendAPI('GetProject');

  const isProjectLoading = useMemo(() => getIsPending(getProjectState), [getProjectState]);

  const { enumMap } = useAppDataContext();

  const onCheckboxSettingValueChange = useCallback(
    (settingKey: CheckboxSettingsKey) => (nextValue: boolean) => {
      setCheckboxSettings(prevCheckboxSettings => ({
        ...prevCheckboxSettings,
        [settingKey]: { ...prevCheckboxSettings[settingKey], isChecked: nextValue },
      }));
    },
    [],
  );

  const onPeriodChange = useCallback((from: string, to: string) => {
    setPeriod(prevPeriod => ({
      ...prevPeriod,
      from,
      to,
    }));
  }, []);

  const onSubmit = useCallback(() => {
    if (!project) return;
    const result = validate(period);

    const isValidResult = result.every(({ isValid }) => isValid);
    if (!isValidResult) {
      showErrorsMessages(result.filter(({ isValid }) => !isValid).map(({ invalidMessage }) => invalidMessage));
      return;
    }
    const preparedClonePerformersData = { ...clonePerforemersData };

    const getPreparedJobPeriod = (jobPeriod: JobPeriod): JobPeriod => ({
      ...jobPeriod,
      ...clonePersonHistory(jobPeriod),
      id: null,
      startDate: period.from,
      endDate: period.to,
    });

    if (preparedClonePerformersData.leader) {
      preparedClonePerformersData.leader.jobPeriods = preparedClonePerformersData.leader.jobPeriods.map(getPreparedJobPeriod);
    }

    if (preparedClonePerformersData.responsiblePerformer) {
      // eslint-disable-next-line max-len
      preparedClonePerformersData.responsiblePerformer.jobPeriods = preparedClonePerformersData.responsiblePerformer.jobPeriods.map(
        getPreparedJobPeriod,
      );
    }

    preparedClonePerformersData.performers = preparedClonePerformersData.performers.map(performer => ({
      ...performer,
      jobPeriods: performer.jobPeriods.map(getPreparedJobPeriod),
    }));

    preparedClonePerformersData.assistances = preparedClonePerformersData.assistances.map(assistant => ({
      ...assistant,
      jobPeriods: assistant.jobPeriods.map(getPreparedJobPeriod),
    }));

    const nextProject: Project = getMockProject({
      projectTypeItem: getEnumItem('ProjectType', project.type!.value, enumMap),
    });

    nextProject.performers = [
      ...(checkboxSettings.leader.isChecked && preparedClonePerformersData.leader ? [preparedClonePerformersData.leader] : []),
      ...(checkboxSettings.responsiblePerformer.isChecked && preparedClonePerformersData.responsiblePerformer
        ? [preparedClonePerformersData.responsiblePerformer]
        : []),
      ...(checkboxSettings.performers.isChecked ? preparedClonePerformersData.performers : []),
      ...(checkboxSettings.assistances.isChecked ? preparedClonePerformersData.assistances : []),
    ];

    nextProject.program = project.program;
    nextProject.programEvent = project.programEvent;
    nextProject.name = project.name;

    nextProject.startDate = period.from;
    nextProject.endDate = period.to;

    nextProject.isFinancingStopped = project.isFinancingStopped;
    nextProject.stopMoneyReason = project.stopMoneyReason;
    nextProject.stopMoneyDate = project.stopMoneyDate;
    nextProject.isSpecTheme = project.isSpecTheme;

    nextProject.isYouth = project.isYouth;
    // nextProject.youthNote = project.youthNote; TODO
    // nextProject.ckp = project.ckp; TODO
    // nextProject.noc = project.noc; TODO
    // nextProject.cp = project.cp; TODO
    // nextProject.useOfResourceNote = project.useOfResourceNote; TODO

    nextProject.isBuyMaterial = project.isBuyMaterial;
    // nextProject.buyMaterialNote = project..buyMaterialNote TODO
    nextProject.internationalProgram = project.internationalProgram;
    nextProject.foreignEnterprise = project.foreignEnterprise;
    nextProject.overheadPercent = project.overheadPercent;
    nextProject.financialNote = project.financialNote;

    nextProject.activityKind = project.activityKind;
    nextProject.specification = project.specification;
    nextProject.okvedIncome = project.okvedIncome;

    nextProject.expendituresBudgets = project.expendituresBudgets;
    nextProject.goal = project.goal;
    nextProject.annotation = project.annotation;

    nextProject.expectedResults = project.expectedResults;
    nextProject.keyWords = project.keyWords;

    nextProject.result = project.result;

    nextProject.customers = project.customers.map(item => ({ ...item, id: null }));

    nextProject.departments = project.departments.map(department => ({ ...department, id: null }));
    nextProject.partners = project.partners.map(partner => ({ ...partner, id: null }));

    nextProject.partnerCountries = project.partnerCountries.map(partnerCountry => ({ ...partnerCountry, id: null }));
    nextProject.grntis = project.grntis.map(grnti => ({ ...grnti, id: null }));
    nextProject.udks = project.udks.map(udk => ({ ...udk, id: null }));
    nextProject.lksetss = project.lksetss.map(lksets => ({ ...lksets, id: null }));
    nextProject.scienceBrunches = project.scienceBrunches.map(scienceBrunch => ({ ...scienceBrunch, id: null }));
    nextProject.scienceDomainInterrests = project.scienceDomainInterrests.map(scienceDomainInterrest => ({
      ...scienceDomainInterrest,
      id: null,
    }));
    nextProject.criticalTechnologies = project.criticalTechnologies.map(criticalTechnology => ({
      ...criticalTechnology,
      id: null,
    }));

    nextProject.pnrs = project.pnrs.map(pnr => ({ ...pnr, id: null }));
    nextProject.pnmitrs = project.pnmitrs.map(pnmitr => ({ ...pnmitr, id: null }));
    nextProject.pnis = project.pnis.map(pni => ({ ...pni, id: null }));
    nextProject.ntrStrategies = project.ntrStrategies.map(ntrStrategy => ({ ...ntrStrategy, id: null }));
    nextProject.techPlatforms = project.techPlatforms.map(techPlatform => ({ ...techPlatform, id: null }));
    nextProject.domainKnowledges = project.domainKnowledges.map(domainKnowledge => ({ ...domainKnowledge, id: null }));
    nextProject.oecds = project.oecds.map(oecd => ({ ...oecd, id: null }));
    nextProject.ntiEteTechnologies = project.ntiEteTechnologies.map(ntiEteTechnology => ({ ...ntiEteTechnology, id: null }));
    nextProject.ntiMarkets = project.ntiMarkets.map(ntiMarket => ({ ...ntiMarket, id: null }));

    submitCallback(nextProject);
    onClose();
  }, [
    checkboxSettings.assistances.isChecked,
    checkboxSettings.leader.isChecked,
    checkboxSettings.performers.isChecked,
    checkboxSettings.responsiblePerformer.isChecked,
    clonePerforemersData,
    enumMap,
    onClose,
    period,
    project,
    submitCallback,
  ]);

  const initForm = useCallback((projectToInit: Project) => {
    const clonedProject = { ...projectToInit };
    setProject(clonedProject);
    const nextClonePerformersData = getClonePerformersData();
    const leaderIndex = getPerformerIndexByLastJobPeriodRole(clonedProject, ProjectScientistRole.LEADER)[0] ?? null;
    if (leaderIndex !== null) {
      nextClonePerformersData.leader = clonedProject.performers.splice(leaderIndex, 1)[0];
    }
    const responsiblePerformerIndex =
      getPerformerIndexByLastJobPeriodRole(clonedProject, ProjectScientistRole.RESPONSIBLE_PERFORMER)[0] ?? null;
    if (responsiblePerformerIndex !== null) {
      nextClonePerformersData.responsiblePerformer = clonedProject.performers.splice(responsiblePerformerIndex, 1)[0];
    }

    const performerIndices = getPerformerIndexByLastJobPeriodRole(clonedProject, ProjectScientistRole.PERFORMER);

    if (performerIndices.length) {
      clonedProject.performers = clonedProject.performers.filter((performer, index) => {
        const isNotPerformer = performerIndices.every(performerIndex => performerIndex !== index);
        if (!isNotPerformer) {
          nextClonePerformersData.performers.push(performer);
        }

        return isNotPerformer;
      });
    }

    const assistanceIndices = getPerformerIndexByLastJobPeriodRole(clonedProject, ProjectScientistRole.ASSISTANCE);

    if (assistanceIndices.length) {
      clonedProject.performers = clonedProject.performers.filter((assistace, index) => {
        const isNotAssistace = assistanceIndices.every(assistanceIndex => assistanceIndex !== index);
        if (!isNotAssistace) {
          nextClonePerformersData.assistances.push(assistace);
        }

        return isNotAssistace;
      });
    }

    if (nextClonePerformersData.leader) {
      nextClonePerformersData.leader.jobPeriods = nextClonePerformersData.leader?.jobPeriods.filter(
        jobPerdiod => jobPerdiod.role?.value === ProjectScientistRole.LEADER,
      );
      nextClonePerformersData.leader.id = null;
      nextClonePerformersData.leader.stages = [];
    }

    if (nextClonePerformersData.responsiblePerformer) {
      nextClonePerformersData.responsiblePerformer.id = null;
      nextClonePerformersData.responsiblePerformer.stages = [];
      nextClonePerformersData.responsiblePerformer.jobPeriods = nextClonePerformersData.responsiblePerformer.jobPeriods.filter(
        jobPerdiod => jobPerdiod.role?.value === ProjectScientistRole.RESPONSIBLE_PERFORMER,
      );
    }

    nextClonePerformersData.performers = nextClonePerformersData.performers.map(performer => ({
      ...performer,
      id: null,
      stages: [],
      jobPeriods: performer.jobPeriods.filter(jobPerdiod => jobPerdiod.role?.value === ProjectScientistRole.PERFORMER),
    }));

    nextClonePerformersData.assistances = nextClonePerformersData.assistances.map(assistant => ({
      ...assistant,
      id: null,
      stages: [],
      jobPeriods: assistant.jobPeriods.filter(jobPerdiod => jobPerdiod.role?.value === ProjectScientistRole.ASSISTANCE),
    }));

    setClonePerformersData(nextClonePerformersData);
  }, []);

  useEffect(() => {
    getProjectAPI.callAPI(
      {
        id: projectId,
      },
      {
        onSuccessfullCall: ({ data }) => {
          initForm(data);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isProjectLoading,
    project,
    checkboxSettings,
    period,
    clonePerforemersData,
    onSubmit,
    onPeriodChange,
    onCheckboxSettingValueChange,
  };
};

export default useController;
