import { useMemo } from 'react';

import { CheckboxItem } from 'features/BuildReportPopup/models';
import { useAppDataContext } from 'features/AppData/context';
import { EventTypeIncentivePayment } from 'utils/Enums';

const useController = () => {
  const { enumMap } = useAppDataContext();

  const checkboxList = useMemo<CheckboxItem[]>(() => {
    const sortedEnum = [...enumMap.PaymentRequestType.values];
    const index = sortedEnum.findIndex(i => i.value === EventTypeIncentivePayment.TEXTBOOK);
    const element = { ...sortedEnum[index] };
    sortedEnum.splice(index, 1);
    sortedEnum.splice(2, 0, element);

    return sortedEnum.map(({ ordinal, label }) => ({
      isChecked: false,
      label,
      value: ordinal.toString(),
    }));
  }, [enumMap.PaymentRequestType.values]);
  return {
    checkboxList,
  };
};

export default useController;
