import { Table as T } from 'types/models';
import { CitationSystem } from 'types/models/Form';

import { Toolbar } from './Toolbar/Toolbar';
import { Editor } from './Editor/Editor';
import { makeUseCustomController } from './makeUseCustomController';
import { State } from './types';

export type IndicatorsDeps = {
  citationSystems: CitationSystem[];
  publicationId: string | null;
  publicationType: string;
  workMode: T.WorkMode;
};

export function GetPublicationCitationSystemIndexList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  deps: IndicatorsDeps,
): T.Specification<State> {
  return {
    apiID: 'GetPublicationCitationSystemIndexList',
    isFiltersHidden: templatesTableDependencies.isFiltersHidden,
    templatesTableDependencies,
    useCustomController: makeUseCustomController(templatesTableDependencies, deps),
    header: {
      secondLevel: {
        Component: Editor,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
  };
}
