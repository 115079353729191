import { Event, Document, Form } from 'types/models';
import { Item } from 'types/models/common';
import { EventProject } from 'types/models/Event';

type Props = {
  formFields: Form.Fields;
  projects: EventProject[] | undefined;
  type: string | undefined;
  memberCounts: Event.MemberCount[] | undefined;
  documents: Document[] | undefined;
  eventId: string | undefined;
  departments: Event.Department[];
  enterprises: Event.Enterprise[];
  committeMembers: Event.Member[];
  format: Item | null;
};

const formatToRequest = ({
  projects,
  type,
  departments,
  enterprises,
  memberCounts,
  documents,
  eventId,
  formFields,
  committeMembers,
  format,
}: Props) => ({
  id: eventId,
  simpleFields: {
    type: type?.toUpperCase() || '',
    domain: 'EVENT',
    isStudent: formFields.isStudent.value,
    isInSummaryPlan: formFields.isInSummaryPlan.value,
    site: formFields.site.value,
    city: formFields.city.value,
    address: formFields.address.value,
    contact: formFields.contact.value,
    keyWords: formFields.keyWords.value,
    name: formFields.name.value,
    memberCountPlan: formFields.memberCountPlan.value,
    memberCountFact: formFields.memberCountFact.value,
    description: formFields.description.value,
    startDate: formFields.startDate.value,
    endDate: formFields.endDate.required ? formFields.endDate.value : '',
    form: formFields.form?.value?.id,
  },
  arrayFields: {
    Grntis: { translation: [] },
    MinistryEducations: { translation: [] },
    CriticalTechnologies: { translation: [] },
    Pnmitrs: { translation: [] },
    Pnrs: { translation: [] },
    Pnis: { translation: [] },
    Partners: { translation: [] },
  },
  attrIdFields: {
    eventSeries: formFields.eventSeries.value?.id as string,
    concertType: formFields.concertType.value?.id as string,
    eventStatus: formFields.eventStatus.value?.id as string,
    country: formFields.country.value?.id as string,
  },
  documents,
  departments,
  enterprises,
  memberCounts,
  committeMembers,
  projects,
  format,
});

export { formatToRequest };
