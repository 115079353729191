import React from 'react';

import { Tab, Tabs } from 'components';
import { EventConferences } from './EventConferences';
import { EventContests } from './EventContests';
import { EventConcerts } from './EventConcerts';
import { EventExpositions } from './EventExpositions';
import { useAppDataContext } from 'features/AppData/context';

export function EventList() {
  const { initialOrganizationCode } = useAppDataContext();
  return (
    <Tabs>
      <Tab title="Конференции">
        <EventConferences />
      </Tab>
      <Tab title="Олимпиады и конкурсы">
        <EventContests />
      </Tab>
      <Tab title="Выставки">
        <EventExpositions />
      </Tab>
      {initialOrganizationCode?.code === 'TSU' && (
        <Tab title="Творческие мероприятия">
          <EventConcerts />
        </Tab>
      )}
    </Tabs>
  );
}
