import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TypeEditionCode } from 'utils/Enums/TypeEditionCode';
import { useAppDataContext } from 'features/AppData/context';
import * as BackendAPI from 'services/BackendAPI';

type Item = {
  kind: string;
  code: TypeEditionCode;
  share: number;
};

export function useController() {
  const { settings } = useAppDataContext();
  const [rusAndOtherByPeriod, setRusAndOtherByPeriod] = useState<Item[]>([]);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activePeriodFilter, setActivePeriodFilter] = useState<string>('THREE_YEAR');
  const [isLoading, setIsLoading] = useState(false);
  const [actualType, setActualType] = useState<TypeEditionCode | null>(null);

  const periodOpts = useRef([
    {
      alias: 'CURRENT_YEAR',
      label: 'за текущий год',
    },
    {
      alias: 'THREE_YEAR',
      label: 'за последние три года',
    },
    {
      alias: 'FIVE_YEAR',
      label: 'за последние пять лет',
    },
  ]);

  const { methods: getDashboardChartByTypeEditionMagazineArticleListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartByTypeEditionMagazineArticleListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        setIsLoading(false);
        const chartAttrRes = data.Response.Grid.GroupingRows.TotalRow._attributes;
        const qtyForeignTypeEdition = Number(chartAttrRes.IsForeignTypeEdition);
        const qtyIsLocalTypeEdition = Number(chartAttrRes.IsLocalTypeEdition);
        const qtyIsOtherUniversityTypeEdition = Number(chartAttrRes.IsOtherUniversityTypeEdition);
        const qtyIsOtherTypeEdition = Number(chartAttrRes.IsOtherTypeEdition);
        const qtyIsRsaTypeEdition = Number(chartAttrRes.IsRsaTypeEdition);

        setRusAndOtherByPeriod([
          {
            kind: 'Доля публикаций в зарубежных журналах',
            code: TypeEditionCode.FOREIGN,
            share: qtyForeignTypeEdition,
          },
          {
            kind: ` Доля публикаций в журналах ${settings?.organization?.shortName}`,
            code: TypeEditionCode.LOCAL,
            share: qtyIsLocalTypeEdition,
          },
          {
            kind: 'Доля публикаций в изданиях РАН',
            code: TypeEditionCode.RSA,
            share: qtyIsRsaTypeEdition,
          },
          {
            kind: 'Доля публикаций в журналах других университетов',
            code: TypeEditionCode.OTHER_UNIVERSITY,
            share: qtyIsOtherUniversityTypeEdition,
          },
          {
            kind: 'Доля публикаций в других журналах',
            code: TypeEditionCode.OTHER,
            share: qtyIsOtherTypeEdition,
          },
        ]);
      },
      onFailedCall: () => {
        setIsLoading(false);
      },
    },
  );

  useEffect(() => {
    setIsLoading(true);
    getDashboardChartByTypeEditionMagazineArticleListStatic.callAPI({
      period: activePeriodFilter,
      rowQuantity: 1,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePeriodFilter]);

  const getNumberSumResult = useCallback(() => {
    let sum = 0;
    rusAndOtherByPeriod.forEach((item: any) => {
      sum += item.share;
    });
    return sum;
  }, [rusAndOtherByPeriod]);

  const getChartSumResult = useCallback(() => {
    return new Intl.NumberFormat().format(getNumberSumResult());
  }, [getNumberSumResult]);

  const renderEntry = useCallback(x => x, []);
  const handleChangeActiveFilter = useCallback(
    newLabel => {
      const data = periodOpts.current.filter((opt: any) => opt.label === newLabel)[0];
      setActivePeriodFilter(data?.alias);
    },
    [setActivePeriodFilter],
  );

  const activeFilterLabel = useMemo(() => periodOpts.current.filter(opt => opt.alias === activePeriodFilter)[0].label, [
    periodOpts,
    activePeriodFilter,
  ]);

  const seriesClickHandler = useCallback(
    (item: Item) => {
      setModalTitle(`${item.kind} ${activeFilterLabel}`);
      setActualType(item.code);
      setIsModalOpen(true);
    },
    [setModalTitle, setActualType, setIsModalOpen, activeFilterLabel],
  );

  return {
    activeFilterLabel,
    periodOpts,
    handleChangeActiveFilter,
    renderEntry,
    modalTitle,
    isModalOpen,
    setIsModalOpen,
    activePeriodFilter,
    actualType,
    isLoading,
    seriesClickHandler,
    rusAndOtherByPeriod,
    getChartSumResult,
    getNumberSumResult,
  };
}
