import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, IconButtonProps } from 'components';

import { usePrivatePageContext } from 'App/PrivatePage/context';
import { Permits } from 'utils/Permissions';
import { FileInfo, Form } from 'types/models';
import { HelpfulStuff } from 'types/models/HelpfulStuff';
import { showNotification } from 'features/Notifications';
import { EMPTY_FILE } from 'utils/Constants';

export function useController() {
  const { isProfile } = usePrivatePageContext();

  const [list, setList] = useState<HelpfulStuff[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<string>('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);

  const [formFields, setFormFields] = useState<Form.Fields>({
    file: {
      value: EMPTY_FILE,
      isValid: true,
      required: true,
      title: 'Файл',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
  });

  function clearFormInfo() {
    setModalData('');
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      file: { ...prevState.file, value: EMPTY_FILE },
    }));
    setSelectedRowIndex(null);
  }

  const { methods: getHelpfulStuffs } = BackendAPI.useBackendAPI('GetHelpfulStuffs', {
    onSuccessfullCall: ({ data }) => {
      setList(data.sort((a, b) => Number(b.id) - Number(a.id)));
    },
  });

  const { methods: saveHelpfulStuff } = BackendAPI.useBackendAPI('SaveHelpfulStuff', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Материал сохранен', theme: 'success' });
      getHelpfulStuffs.callAPI({});
      setIsAddModalOpen(false);
    },
  });

  const { methods: deleteModel } = BackendAPI.useBackendAPI('DeleteModel', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Материал удален', theme: 'success' });
      setSelectedRowIndex(null);
      getHelpfulStuffs.callAPI({});
    },
  });

  useEffect(() => {
    if (selectedRowIndex || selectedRowIndex === 0) {
      setModalData(list[selectedRowIndex].data);
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        file: {
          ...prevState.file,
          value: list[selectedRowIndex].file,
        },
      }));
    } else {
      clearFormInfo();
    }
  }, [list, selectedRowIndex]);

  useLayoutEffect(() => {
    getHelpfulStuffs.callAPI({});
    // eslint-disable-next-line
  }, []);

  const handleModalDataChange = useCallback((value: string) => {
    setModalData(value);
  }, []);

  const handleModalSaveButtonClick = useCallback(() => {
    if (modalData && formFields.file.value?.id) {
      const editingRecord = {
        id: selectedRowIndex || selectedRowIndex === 0 ? list[selectedRowIndex].id : null,
        data: modalData,
        file: formFields.file.value,
      };
      saveHelpfulStuff.callAPI(editingRecord);
    } else {
      showNotification({ theme: 'danger', message: 'Заполните все поля' });
    }
  }, [modalData, formFields, selectedRowIndex, list, saveHelpfulStuff]);

  const handleDeleteButtonClick = useCallback(() => {
    if (selectedRowIndex || selectedRowIndex === 0)
      deleteModel.callAPI({ rowId: list[selectedRowIndex].id, commandName: 'DeleteEntity', entityName: 'HelpfulStuff' });
    setIsConfirmPopupOpen(false);
  }, [deleteModel, list, selectedRowIndex]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        permissionName: Permits.HELPFUL_STUFF_ADD,
        isDisabled: false,
        onClick: () => {
          setIsAddModalOpen(true);
        },
      },
      {
        icons: buttonIcons.viewList,
        title: 'Редактировать список',
        permissionName: Permits.HELPFUL_STUFF_LIST_EDIT,
        isDisabled: false,
        onClick: () => {
          setIsEditModalOpen(true);
        },
      },
    ],
    [],
  );

  const addModalButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Cохранить',
        code: 'save',
        isDisabled: false,
        onClick: () => {
          handleModalSaveButtonClick();
        },
      },
    ],
    [handleModalSaveButtonClick],
  );

  const editModalButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Cохранить',
        code: 'save',
        isDisabled: false,
        onClick: () => {
          handleModalSaveButtonClick();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'delete',
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
        onClick: () => {
          setIsConfirmPopupOpen(true);
        },
      },
    ],
    [handleModalSaveButtonClick, selectedRowIndex],
  );
  return {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsEditModalOpen,
    setIsAddModalOpen,
    clearFormInfo,
    addModalButtons,
    modalData,
    handleModalDataChange,
    selectedRowIndex,
    formFields,
    isEditModalOpen,
    editModalButtons,
    list,
    setSelectedRowIndex,
    buttons,
    isProfile,
  };
}
