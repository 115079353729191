import React from 'react';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isViewDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isAddDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isEditDisabled,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    buttons,
  } = useController(tableState);
  return (
    <>
      {!isViewDisabled && (
        <modalTemplates.ProjectStateRegistrationReportViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.ProjectID ?? '-1'}
        />
      )}
      {!isEditDisabled && (
        <modalTemplates.ProjectStateRegistrationReportEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.ReportID ?? '-1'}
          relatedTableState={tableState}
        />
      )}
      {!isAddDisabled && (
        <modalTemplates.ProjectStateRegistrationReportAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          id={tableState.selectedRows[0]?.ProjectID ?? '-1'}
          relatedTableState={tableState}
          name={nextPublicationName}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
