import { ProgramEvent } from 'types/models';

import { getText } from '../commonConverters';
import { convertProgram, convertDirection } from '../program/converters';

export function convertProgramEvent(programEvent: any): ProgramEvent {
  return {
    id: programEvent._attributes.id,
    name: getText(programEvent.Name),
    code: getText(programEvent.Code),
    nationalProjectExpenseCode: getText(programEvent.NationalProjectExpenseCode),
    direction: programEvent.Direction ? convertDirection(programEvent.Direction) : null,
    program: convertProgram(programEvent.Program),
  };
}
