import React, { useCallback, useMemo, useState } from 'react';
import { parse } from 'date-fns';

import { buttonIcons, FormComponent, ListEdit, Column } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Document } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { formatStr } from 'utils/Constants/FormatStr';
import { Fields } from './Fields/Fields';
import { validate } from './validate';
import { getMockDocument } from './helpers';
import { downloadFile, formatNumber, getAuthToken } from 'utils/Helpers';

export type FieldsType = 'details' | 'projectDocument';

type Props = {
  documents: Document[];
  setDocuments(docs: Document[]): void;
  disabled?: boolean;
  title?: string;
  isSortEnabled?: boolean;
  isVisibleFilters?: boolean;
  isCanIsSharedEdit?: boolean;
  isCommonAward?: boolean;
  isCanIsInfoEdit?: boolean;
  isShowSource?: boolean;
  sharedLabel?: string;
  addCallback?: (document: Document, startEdit: (document: Document) => void) => void;
  editCallback?: (document: Document, startEdit: () => void) => void;
  deleteCallback?: (document: Document, startEdit: () => void) => void;
  fieldsType?: FieldsType;
  sourceLabelForLocalDocs?: string;
  defaultSharedValue?: boolean;
  editPermissionName?: string;
  maxHeight?: string;
  tooltip?: string;
  hint?: string;
  tooltipDocumentName?: string;
  isEditButtonsHidden?: boolean;
  additionalToolbarButtons?: ExtraToolbarButton<Document>[];
};

export function Documents(props: Props) {
  const {
    title,
    documents,
    setDocuments,
    sharedLabel,
    disabled = false,
    isShowSource = false,
    isCanIsInfoEdit = false,
    isCanIsSharedEdit = false,
    isCommonAward = false,
    addCallback,
    editCallback,
    deleteCallback,
    sourceLabelForLocalDocs,
    defaultSharedValue = false,
    fieldsType = null,
    editPermissionName,
    isVisibleFilters,
    isSortEnabled,
    maxHeight,
    tooltip,
    hint,
    tooltipDocumentName,
    isEditButtonsHidden,
    additionalToolbarButtons,
  } = props;

  const { userPermission, enumMap } = useAppDataContext();

  const [isInProgram, setIsInProgram] = useState<boolean>(false);
  const token = getAuthToken();

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Document>[]>(
    () => [
      {
        icons: buttonIcons.download,
        title: 'Скачать файл',
        code: 'downloadFile',
        checkIsDisabled: doc => !doc?.id,
        onClick: document => downloadFile(document?.file?.id, token),
      },
      ...(additionalToolbarButtons ? additionalToolbarButtons : []),
    ],
    [additionalToolbarButtons, token],
  );

  const makePreCallback = (type: 'edit' | 'delete') => (doc: Document | null, startEdit: () => void) => {
    if (doc?.docType === 'foreign') {
      const word = type === 'edit' ? 'Редактировать' : 'Удалить';
      showNotification({ message: `${word} документ можно только текущего источника`, theme: 'danger' });
    } else {
      const isEdit = type === 'edit';
      const isDelete = type === 'delete';

      const isEditCallback = typeof editCallback === 'function';
      const isDeleteCallback = typeof deleteCallback === 'function';
      if (isEdit && isEditCallback && doc) {
        editCallback(doc, startEdit);
      } else if (isDelete && isDeleteCallback && doc) {
        deleteCallback(doc, startEdit);
      } else {
        startEdit();
      }
    }
  };

  const preSubmitCallback = useCallback(
    (document: Document, submit: (document: Document) => void) => {
      if (typeof addCallback === 'function') {
        addCallback(document, submit);
      } else {
        submit(document);
      }
    },
    [addCallback],
  );

  const firstColumn: Column<Document>[] = isShowSource
    ? [{ label: 'Источник', formatValue: x => (x.docType === 'local' ? sourceLabelForLocalDocs ?? 'Источник' : x.type) }]
    : [];

  const detailsColums: Column<Document>[] = [
    {
      label: 'Действует',
      formatValue: row => parse(row.endDate ?? '', formatStr, new Date()) >= new Date(),
    },
    {
      label: 'Вид и номер',
      formatValue: row => `${row.type}${row.number ? ` №${row.number}` : ''} от ${row.date}`,
    },
    {
      label: 'Название документа',
      formatValue: row => row.name,
    },
    {
      label: 'Особые условия',
      formatValue: row =>
        [
          row.isUnexpirable ? 'Бессрочный' : null,
          row.isAutoProlongation ? 'Автоматическое продление' : null,
          row.program?.id ? `В рамках программы: ${row.program.label}` : null,
        ]
          .filter(x => x)
          .join('; '),
    },
    {
      label: 'Период действия',
      formatValue: row => `${row.startDate || ''} - ${row.endDate || ''}`,
    },
    {
      label: 'Ответственный',
      formatValue: row => [row.responsibleFio, row.responsibleJobAppointment].filter(x => x).join(', '),
    },
    {
      label: 'Добавлено',
      formatValue: row => `${row.createdBy}, ${row.createdDate}`,
    },
  ];

  return (
    <>
      <FormComponent.Description mode="warning">{hint}</FormComponent.Description>

      <ListEdit
        title={title}
        withMessages
        isDeleteConfirmEnabled
        rows={documents}
        onChange={setDocuments}
        defaultRowsCount={15}
        extraToolbarButtons={extraToolbarButtons}
        isToolbarDisabled={disabled}
        isFullScreenedTable
        isSortEnabled={isSortEnabled}
        isVisibleFilters={isVisibleFilters}
        editPermissionName={editPermissionName}
        {...(isEditButtonsHidden ? { visibleToolbarButtons: [] } : null)}
        columns={
          fieldsType === 'details'
            ? detailsColums
            : [
                ...firstColumn,
                {
                  label: 'Название документа',
                  tooltip: tooltipDocumentName,
                  formatValue: x => x.name,
                },
                { label: 'Дата документа', formatValue: x => x.date },
                ...(fieldsType === 'projectDocument'
                  ? [{ label: 'Сумма', formatValue: (x: Document) => formatNumber(String(x.amount || 0), 2) }]
                  : []),
                { label: 'Файл', formatValue: x => x.file?.name },
                { label: 'Добавлено', formatValue: x => x.createdBy },
                { label: 'Дата добавления', formatValue: x => x.createdDate },
              ]
        }
        {...(fieldsType === 'projectDocument' ? { columnIndexesForSumTotal: [3] } : {})}
        maxHeight={maxHeight}
        specification={{
          onPreEdit: makePreCallback('edit'),
          onPreDelete: makePreCallback('delete'),
          onPreSubmit: preSubmitCallback,
          mode: 'customComponent',
          renderComponent: (doc, setDoc) => (
            <Fields
              document={doc || getMockDocument({ user: userPermission, defaultSharedValue, projectDocumentInitType: 'OTHER' })}
              setDocument={setDoc}
              sharedLabel={sharedLabel}
              isCanIsInfoEdit={isCanIsInfoEdit}
              isCanIsSharedEdit={isCanIsSharedEdit}
              fieldsType={fieldsType}
              enumMap={enumMap}
              isCommonAward={isCommonAward}
              isShowSource={isShowSource}
              isInProgram={isInProgram}
              setIsInProgram={setIsInProgram}
              sourceLabelForLocalDocs={sourceLabelForLocalDocs}
              tooltip={tooltip}
            />
          ),
          validation: {
            checkIsValid: document => validate(document, fieldsType, isInProgram).every(x => x.isValid),
            onInvalidate: document => {
              const info = validate(document, fieldsType, isInProgram);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />
    </>
  );
}
