import React, { useCallback } from 'react';

import { EditMipPartner } from 'features/EditMipPartner';
import { State, Mode } from '../makeUseCustomController';

type Props = {
  customState: State;
};

export function Form({ customState }: Props) {
  const { mipPartner, isOpenForm, closeForm, mode, reloadTable } = customState;

  const onSuccessSave = useCallback(() => {
    reloadTable();
    closeForm();
  }, [reloadTable, closeForm]);

  const getTitle = () => {
    const partnerInfo = `${mipPartner?.shortName || mipPartner?.fullName} (ID: ${mipPartner?.id ?? ''}, Статус записи: ${
      mipPartner?.status?.label ?? ''
    }, Состояние: ${mipPartner?.state?.label})`;
    const map: Record<Mode, string> = {
      add: 'Добавление МИП',
      edit: `Редактирование МИП ${partnerInfo}`,
      view: `Просмотр ${partnerInfo}`,
    };
    return mode ? map[mode] : '';
  };

  return (
    <EditMipPartner
      isOpenForm={isOpenForm}
      title={getTitle()}
      mipPartner={mipPartner}
      closeForm={closeForm}
      mode={mode}
      onSuccessSavePartner={onSuccessSave}
    />
  );
}
