import React from 'react';
import { block } from 'bem-cn';
import Select from 'react-select';
import useController from './controller';
import { options } from './helpers';
import './style.scss';

export type Props = {
  setColumnFilter: (nextValue: string) => void;
  value: string;
};

const b = block('grid-boolean-filter');

export const BooleanFilter = ({ value, setColumnFilter }: Props) => {
  const { selectedValue, onFieldChange } = useController({ value, setColumnFilter });
  return (
    <div title={selectedValue?.label ?? 'Выберите значение'}>
      <Select
        options={options}
        value={selectedValue}
        onChange={onFieldChange}
        noOptionsMessage={() => 'Не найдено'}
        placeholder=""
        menuPortalTarget={document.body}
        maxMenuHeight={200}
        classNamePrefix={b()}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuShouldScrollIntoView={false}
        menuShouldBlockScroll
        menuPosition="fixed"
      />
    </div>
  );
};
