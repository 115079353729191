import { SecurityDocumentContract } from 'types/models';
import { convertDocumentsToRequest } from '../converters';
import { SecurityDocumentContractSaveInput } from './types';
import { convertFileToXML, getMaybeField } from '../commonConverters';

export function convertSecurityDocumentContractToXML(item: SecurityDocumentContractSaveInput) {
  const {
    documents,
    endDate,
    conclusionDate,
    enterpriseCustomer,
    id,
    isTransfered,
    kind,
    number,
    paymentCondition,
    paymentKind,
    payments,
    price,
    startDate,
    registrationDate,
    registrationNumber,
    note,
    securityDocuments,
  } = item;
  return {
    _attr: item.id ? { id } : {},
    Number: number,
    ...(kind?.id ? { Kind: { _attr: { id: kind.id } } } : {}),
    LoDate: startDate,
    HiDate: endDate,
    ConclusionDate: conclusionDate,
    ...(enterpriseCustomer?.id ? { Enterprise: { _attr: { id: enterpriseCustomer.id } } } : {}),
    Price: price || '0.00',
    ...(paymentKind?.id ? { PaymentKind: { _attr: { id: paymentKind.id } } } : {}),
    ...getMaybeField('PaymentCondition', paymentCondition),
    IsTransfered: isTransfered,
    Payments: payments.length ? { Payment: payments.map(convertPaymentsToXML) } : {},
    SecurityDocuments: securityDocuments?.length
      ? {
          SecurityDocument: securityDocuments.map(doc => {
            return { _attr: { id: doc.id } };
          }),
        }
      : {},
    ...(documents.length ? convertDocumentsToRequest(documents, 'local') : {}),
    ...getMaybeField('RegistrationDate', registrationDate),
    ...getMaybeField('RegistrationNumber', registrationNumber),
    RegistrationNumber: registrationNumber || '',
    Note: note || '',
  };
}

function convertPaymentsToXML(item: SecurityDocumentContract.Payment) {
  return {
    _attr: { ...getMaybeField('id', item.id) },
    ...(item.receivingDocument ? { ReceivingDocument: convertReceivingDocumentToXML(item.receivingDocument) } : {}),
    ...convertFileToXML('File', item.file),
  };
}

function convertReceivingDocumentToXML(item: SecurityDocumentContract.ReceivingDocument) {
  return {
    _attr: { id: item.id },
    ...(item.cashDocument ? { CashDocument: convertCashDocumentToXML(item.cashDocument) } : {}),
  };
}

function convertCashDocumentToXML(item: SecurityDocumentContract.CashDocument) {
  return {
    _attr: { id: item.id },
  };
}
