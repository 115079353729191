import React, { memo } from 'react';
import { setup } from 'bem-cn';

import { Button, ButtonMode } from 'components';

import { Documents } from 'features/Documents';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { EventTypes } from 'utils/Enums/EventTypes';
import { EventTypeCode } from 'types/models/Event';
import useController from './controller';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('approve-event');

type Props = {
  id: string;
  typeCode: EventTypeCode;
  onClose: () => void;
};

const ApproveEventComponent = ({ id, typeCode, onClose }: Props) => {
  const { eventData, handleApproveEvent, handleDocumentsChange, organizationName } = useController({
    onClose,
    id,
    typeCode,
  });
  return (
    <div className={b()}>
      <ul className={b('prop-list')}>
        <li className={b('prop')}>
          {typeCode === EventTypes.EVENT_CONFERENCE.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Конференция: ${eventData ? `${eventData.fullName} (ID: ${eventData.status.id}. Статус записи: ${eventData.status.text})` : ''}`}
              </span>
            </>
          )}
          {typeCode === EventTypes.EVENT_CONCERT.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Творческое мероприятие: ${eventData ? `${eventData.fullName} (ID: ${eventData.status.id}. Статус записи: ${eventData.status.text})` : ''}`}
              </span>
            </>
          )}
          {typeCode === EventTypes.EVENT_CONTEST.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Конкурс: ${eventData ? `${eventData.fullName} (ID: ${eventData.status.id}. Статус записи: ${eventData.status.text})` : ''}`}
              </span>
            </>
          )}
          {typeCode === EventTypes.EVENT_EXPOSITION.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Выставка: ${eventData ? `${eventData.fullName} (ID: ${eventData.status.id}. Статус записи: ${eventData.status.text})` : ''}`}
              </span>
            </>
          )}
        </li>
        <li className={b('prop')}>
          <span className={b('prop-key')}>Сайт:&nbsp;</span>
          <span className={b('prop-value')}>{`${eventData?.fields?.site || ''}`}</span>
        </li>
        <li className={b('prop')}>
          <span className={b('prop-key')}>Формат проведения и уровень:&nbsp;</span>
          <span className={b('prop-value')}>{`${[eventData?.format?.label, eventData?.fields?.eventStatusLabel].join(
            ', ',
          )}`}</span>
        </li>
        <li className={b('prop')}>
          <span className={b('prop-key')}>Организаторы:&nbsp;</span>
          <span className={b('prop-value')}>{`${[
            ...(eventData?.departments?.map(d => `${d.name} (${organizationName})`) || []),
            ...(eventData?.enterprises?.map(e => e.organization.label) || []),
          ].join(', ')}`}</span>
        </li>
      </ul>
      <div className={b('table-wrapper')}>
        <Documents
          title="Подтверждающие документы"
          documents={eventData?.documents || []}
          setDocuments={docs => handleDocumentsChange(docs)}
          disabled={false}
          maxHeight="490px"
        />
      </div>
      <ul className={b('action-list')}>
        <li className={b('action-item')}>
          <Button
            mode={ButtonMode.primary}
            text={eventData?.status.status === RecordStatus.APPROVED ? 'Перевести в статус Добавлена' : 'Утвердить'}
            onClick={handleApproveEvent}
          />
        </li>
        <li className={b('action-item')}>
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </li>
      </ul>
    </div>
  );
};

export const Component = memo(ApproveEventComponent);
