import { Component as Block } from 'features/Home/blocks/HelpfulStuff/block';
import React from 'react';
import block from 'bem-cn';
const b = block('helpful');

import { Document } from 'types/models';

type Props = {
  documents: Document[] | null;
};

export function DocumentList(props: Props) {
  const { documents } = props;
  return (
    <div className={b('list')}>
      {documents?.map(e => (
        <Block props={{ ...e, creator: '', data: e.name }} key={e.id + e.createdDate} />
      ))}
    </div>
  );
}
