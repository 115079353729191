import { GetPublicationOtherListSpecification } from 'features/Table/specifications';
import { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form, Table } from 'types/models';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  articlePublicationIds: string[];
  sourcePublicationId: string;
  onClose: () => void;
};

const controller = ({ onClose, articlePublicationIds, sourcePublicationId }: Props) => {
  const { methods: getPublicationAPI, state: getPublicationAPIState } = BackendAPI.useBackendAPI('GetPublication');
  const {
    methods: changePublicationOtherArticlePublicationOtherAPI,
    state: changePublicationOtherArticlePublicationOtherAPIState,
  } = BackendAPI.useBackendAPI('ChangePublicationOtherArticlePublicationOther');
  const localTableStreams = useLocalTableStreams();

  const isChangePublicationOtherArticlePublicationOtherLoading =
    changePublicationOtherArticlePublicationOtherAPIState.kind === 'pending';
  const isPublicationLoading = getPublicationAPIState.kind === 'pending';

  const isLoading = useMemo(() => isChangePublicationOtherArticlePublicationOtherLoading || isPublicationLoading, [
    isChangePublicationOtherArticlePublicationOtherLoading,
    isPublicationLoading,
  ]);

  // новый сборник, к которому прикрепляются статьи
  const [sourcePublicationOther, setSourcePublicationOther] = useState<Form.Compilation | null>(null);
  // сборник, к которому были прикреплены эти статьи
  const [sourcePublication, setSourcePublication] = useState<Form.Publication | null>(null);

  const getPublication = useCallback(
    ({ publicationId }: { publicationId: string }) =>
      new Promise<Form.Publication | null>(resolve => {
        getPublicationAPI.callAPI(
          {
            id: publicationId,
            simpleFields: {
              status: RecordStatus.ADDED,
            },
            attrIdFields: {},
            arrayFields: {
              translations: { translation: [] },
              events: { event: [] },
            },
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getPublicationAPI],
  );

  const changeOtherPublication = useCallback(
    async (otherPublication: Form.Compilation | null) => {
      if (otherPublication?.id !== sourcePublicationId) {
        setSourcePublicationOther(otherPublication);
      } else {
        // eslint-disable-next-line max-len
        showNotification({
          message: 'Нельзя выбрать ту же публикацию другого издания к которой выбранные статьи изначально прикреплены',
          theme: 'danger',
        });
      }
    },
    [sourcePublicationId],
  );

  const modalTableRowToSourcePublicationOtherConventer = useCallback<(row: Table.Entry) => Form.Compilation>(
    row => ({
      id: row.id,
      name: row.name,
      bibliographic: row.bibliographicRecord,
      status: row.status,
      typeEdition: row['id:typeEdition'],
    }),
    [],
  );

  const sourcePublicationOtherTableConfig = useMemo(
    () =>
      GetPublicationOtherListSpecification({
        isElectronic: sourcePublication?.fields?.isElectronic === 'true',
        articleIds: articlePublicationIds.join(','),
      }),
    [sourcePublication, articlePublicationIds],
  );

  const handleSubmit = useCallback(() => {
    if (!sourcePublicationOther) {
      return;
    }

    changePublicationOtherArticlePublicationOtherAPI.callAPI(
      {
        articlePublicationIds,
        sourcePublicationId: sourcePublicationOther?.id || '',
      },
      {
        onSuccessfullCall: () => {
          onClose();
          localTableStreams.reloadTable.push();
        },
      },
    );
  }, [
    articlePublicationIds,
    changePublicationOtherArticlePublicationOtherAPI,
    localTableStreams.reloadTable,
    onClose,
    sourcePublicationOther,
  ]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      const fetchedSourcePublication = await getPublication({ publicationId: sourcePublicationId });
      setSourcePublication(fetchedSourcePublication);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    sourcePublicationOther,
    handleSubmit,
    changeOtherPublication,
    modalTableRowToSourcePublicationOtherConventer,
    sourcePublicationOtherTableConfig,
  };
};

export default controller;
