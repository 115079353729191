import React, { useEffect } from 'react';

import { FormComponent, TextArea, Toolbar, UploadFile } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectAssignmentForm({ viewMode, editMode, onClose }: Props) {
  const { projectAssignmentMessage, projectAssignmentMessageId, buttons, workMode, makeChangeHandler } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (workMode === 'addMode') {
            return `Добавление сообщения`;
          }

          const details = [projectAssignmentMessageId ? `ID ${projectAssignmentMessageId}` : ''].filter(x => x).join(', ');

          const title = [
            workMode === 'viewMode' ? 'Просмотр сообщения' : 'Редактирование сообщения',
            details ? `(${details})` : '',
          ]
            .filter(x => x)
            .join(' ');

          return (
            <>
              <h3>{title}</h3>
            </>
          );
        })(),
      );
    }
  }, [projectAssignmentMessageId, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Сообщение" isRequired>
            <TextArea
              settings={{ rows: 4, isFixed: true }}
              value={projectAssignmentMessage?.message || ''}
              onChange={makeChangeHandler('message')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Файл">
            <UploadFile file={projectAssignmentMessage?.file} onChange={makeChangeHandler('file')} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectAssignmentForm);
