import { ValueOf } from 'types/helpers';
import { MipPartner } from 'types/models';

type Props = {
  charterCapital: MipPartner.CharterCapital;
  setCharterCapital: (charterCapital: MipPartner.CharterCapital) => void;
};
const useController = ({ charterCapital, setCharterCapital }: Props) => {
  const makeHandler = (key: keyof MipPartner.CharterCapital) => (value: ValueOf<MipPartner.CharterCapital>) => {
    setCharterCapital({
      ...charterCapital,
      [key]: value,
    });
  };

  return {
    makeHandler,
  };
};

export default useController;
