import React from 'react';

import { Person } from 'types/models';

type Props = {
  person?: Person.ScientistPerson | null;
};

/**
 * Render person contacts
 *
 * @param person - Person for contacts
 * @returns JSX
 */
export const PersonContacts = React.memo(({ person }: Props) => {
  return (
    <span>
      {[
        person?.scientist?.phone ? `тел: ${person?.scientist?.phone}` : '',
        person?.scientist?.mobilePhone ? `моб. тел.: ${person?.scientist?.mobilePhone}` : '',
        person?.scientist?.email ? `эл. почта: ${person?.scientist?.email}` : '',
      ]
        .filter(x => x)
        .join('; ')}
    </span>
  );
});
