import { useState, useLayoutEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Form } from 'types/models';
import type { Recognition } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';

import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  onClose: () => void;
};
export function useController({ onClose }: Props) {
  const [recognitionInfo, setRecognitionInfo] = useState<Recognition | null>(null);
  const [formFields, setFormFields] = useState<Form.Fields>({
    department: {
      value: { label: '', id: '' },
      isValid: true,
      required: true,
      title: 'Подразделение',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          department: { ...prevState.department, value },
        }));
      },
    },
  });
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { methods: getRecognition } = BackendAPI.useBackendAPI('GetRecognition', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Recognition;
      setRecognitionInfo(preparedData);
      // setDepartments([])
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        department: {
          ...prevState.department,
          value: {
            id: preparedData.department.id,
            label: preparedData.department.label,
          },
        },
      }));
    },
  });

  const { methods: saveRecognitionDepartment } = BackendAPI.useBackendAPI('SaveRecognitionDepartment', {
    onSuccessfullCall: () => {
      tableStreams.reloadTable.push();
    },
  });

  useLayoutEffect(() => {
    if (id) getRecognition.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  const saveFormSubmit = useCallback(
    () => {
      if (id && formFields.department.value?.id) {
        saveRecognitionDepartment.callAPI({ id, departmentId: formFields.department.value?.id });
        showNotification({ message: 'Связь признания с подразделением успешно сохранена', theme: 'success' });
      } else {
        showNotification({ message: 'Заполните поля', theme: 'danger' });
      }
      onClose();
    },
    // eslint-disable-next-line
    [formFields, id, saveRecognitionDepartment]);

  return {
    recognitionId: id ?? null,
    formFields,
    recognitionInfo,
    saveFormSubmit,
  };
}
