import React from 'react';

import { Tabs, Tab, ConfirmPopup, FormComponent } from 'components';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { CantSaveInPreviousStatusPopup } from 'features/Form/views/CantSaveInPreviousStatusPopup';
import { WarningDraftStatusPopup } from 'features/Form/views/WarningDraftStatusPopup';
import { Component as BibliographicRecordErrorPopup } from 'features/Form/views/BibliographicRecordErrorPopup';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const PublicationFormArticle = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    workMode,
    formFields,
    isAuthorsCategegoryPopupOpen,
    onAuthorsCategegoryPopupSubmit,
    onAuthorsCategegoryPopupClose,
    onCreateBibliographicRecordClick,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    relatedTableState,
    publicationId,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    projects,
    mobileRequests,
    authors,
    setAuthors,
    documents,
    setDocuments,
    topMessage,
    setTopMessage,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
  } = useController({
    onClose,
  });

  return (
    <FormComponent.Template>
      <ConfirmPopup
        title="Предупреждение"
        icon="info"
        okButtonText="Продолжить"
        resetButtonText="Отмена"
        isOpen={isAuthorsCategegoryPopupOpen}
        onConfirm={onAuthorsCategegoryPopupSubmit}
        onClose={onAuthorsCategegoryPopupClose}
        text="Не указан научный руководитель, продолжить сохранение?"
      />
      <CantSaveInPreviousStatusPopup
        isOpen={isCantSaveInPreviousStatusPopupOpen}
        onConfirm={handleConfirmCantSaveInPreviousStatusPopup}
        onClose={handleCloseCantSaveInPreviousStatusPopup}
        text={cantSaveInPreviousStatusText}
      />
      <WarningDraftStatusPopup
        isOpen={isDraftWarningPopupOpen}
        onClose={handleCloseDraftWarningPopup}
        onConfirm={handleConfirmDraftWarningPopup}
      />
      <BibliographicRecordErrorPopup
        errorText={bibliographicRecordErrorText}
        textLanguageId={formFields.textLanguage.value?.id || ''}
        onConfirm={handleConfirmBibliographicRecordError}
        onClose={handleCloseBibliographicErrorPopup}
        isOpen={isBibliographicErrorPopupOpen}
      />
      <ConfirmPopup
        title="Внимание!"
        icon="info"
        isOpen={isAuthorsPopupOpen}
        okButtonText="Нет"
        resetButtonText="Добавить"
        onConfirm={handleConfirmAuthorsPopup}
        onClose={handleCloseAuthorsPopup}
        onReset={handleResetAuthorsPopup}
      >
        <div>
          <div>
            <strong>
              В списке «Мои публикации», отображаются только те, в которых вы указаны в разделе Сведения об авторстве, иначе в
              списке «Все публикации»
            </strong>
          </div>
          <br />
          <span>Вашей персоны нет в списке авторов. Добавить?</span>
        </div>
      </ConfirmPopup>

      <ControlPanel
        handleSave={handlePublicationSave}
        handleSaveAsDraft={handlePublicationSaveAsDraft}
        handleSaveAndContinue={handlePublicationSaveAndContinue}
        viewMode={viewMode}
        isDisabled={isLoading}
      />

      <SuchLikePublication mode="edit" handleCloseOuterForm={onClose} componentId={SUCH_LIKE_PUBLICATION_ID} />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="О публикации">
            <TabsContent.About
              publicationInfo={publicationInfo}
              formFields={formFields}
              workMode={workMode}
              onCreateBibliographicRecordClick={onCreateBibliographicRecordClick}
              userDepartments={userDepartments}
              publicationId={publicationId}
              publicationDepartment={publicationDepartment}
              setPublicationDepartment={setPublicationDepartment}
              arm={arm}
            />
          </Tab>
          <Tab title="Сведения об авторстве">
            <TabsContent.AuthorsComponent workMode={workMode} formFields={formFields} authors={authors} setAuthors={setAuthors} />
          </Tab>
          <Tab title="О публикации (анг.)">
            <TabsContent.AboutEnglishComponent formFields={formFields} viewMode={viewMode} />
          </Tab>
          <Tab title="Сведения о защите">
            <TabsContent.Defence formFields={formFields} workMode={workMode} />
          </Tab>
          <Tab title="Индексация в базах">
            <TabsContent.DatabaseIndexing
              citationSystems={publicationInfo?.citationSystems ?? []}
              relatedTableState={relatedTableState}
              publicationId={publicationId || null}
              publicationType={publicationInfo?.type ?? ''}
              addCitationSystem={addCitationSystem}
              editCitationSystem={editCitationSystem}
              removeCitationSystem={removeCitationSystem}
              workMode={workMode}
              sourceId={publicationInfo?.magazine?.id}
              releaseId={publicationInfo?.magazineRelease?.id}
              topMessage={topMessage}
              setTopMessage={setTopMessage}
            />
          </Tab>
          <Tab title="Финансовая поддержка">
            <TabsContent.FinancialSupport
              projects={projects}
              mobileRequests={mobileRequests}
              incentivePaymentRequest={publicationInfo?.status?.incentivePaymentRequest || ''}
              relatedTableState={relatedTableState}
              workMode={workMode}
            />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.Messages relatedTableState={relatedTableState} workMode={workMode} publicationId={publicationId} />
          </Tab>
          <Tab title="Документы">
            <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
};

export const Component = React.memo(PublicationFormArticle);
