import * as R from 'ramda';
import { StreamRx, makeStream } from 'StreamRx';
import { Notification } from './index';

type NotificationsStreams<T> = {
  [K in keyof T]: T[K];
};

export function useLocalStreams<T>(localStreams: T): NotificationsStreams<T> {
  return R.map(
    (stream): StreamRx<any> => ({
      push: (data: any) => stream.push(data),
      observable: stream.observable,
    }),
    localStreams as any,
  ) as any;
}

type NotificationsStreamData<Data = object> = Data;

const streams = {
  addNotification: makeStream<NotificationsStreamData<Notification>>(),
};

export function useLocalNotificationsStreams() {
  return useLocalStreams(streams);
}
