import React, { memo } from 'react';

import { FormComponent, TextDateTime } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const DateRange = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { canBuild, onChange, valueFrom, valueTo, dateRangeParameter } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });

  return (
    <>
      {canBuild && (
        <FormComponent.Line hasGap hasFreeFormat>
          <FormComponent.Field label="Период" isRequired={dateRangeParameter?.required}>
            <TextDateTime
              isRange
              value={`${valueFrom}-${valueTo}`}
              onChange={(value: string) => {
                const dates = value.split('-');
                onChange(dates[0] || '', dates[1] || '');
              }}
              rangeButtons={['singeDate']}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
};

const Component = memo(DateRange);

export { Component as DateRange };
