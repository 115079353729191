import React from 'react';
import { Person } from 'types/models';

import { FormComponent, ReferenceItem, SectionTitle, TextInput } from 'components';

type Props = {
  scientist: Person.Scientist | null;
  onChange(fieldName: keyof Person.Scientist, value: string | ReferenceItem | null): void;
  disabled: boolean;
};

export function Contacts(props: Props) {
  const { scientist, onChange, disabled } = props;

  const handleChange = (fieldName: keyof Person.Scientist) => (value: string | ReferenceItem | null) => {
    onChange(fieldName, value);
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <SectionTitle
            title="Актуальные контакты"
            tooltip="Введите свои контактные данные. Они необходимы для связи с вами и формирования отчетных форм"
          />
          <FormComponent.Line>
            <FormComponent.Field label="Телефон">
              <TextInput
                value={scientist?.phoneOther ?? ''}
                onChange={handleChange('phoneOther')}
                placeholder={disabled ? 'Не указан' : ''}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Моб. телефон">
              <TextInput
                value={scientist?.mobilePhoneOther ?? ''}
                onChange={handleChange('mobilePhoneOther')}
                placeholder={disabled ? 'Не указан' : ''}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="E-mail">
              <TextInput
                value={scientist?.emailOther ?? ''}
                onChange={handleChange('emailOther')}
                placeholder={disabled ? 'Не указан' : ''}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <SectionTitle
            title="Контакты из других систем"
            // eslint-disable-next-line max-len
            tooltip="Сведения о контактах загружаются из информационных систем кадрового учёта сотрудников и обучающихся. Исправить данные можно только в системах-источниках"
          />
          <FormComponent.Line>
            <FormComponent.Field label="Телефон:">
              <span>{scientist?.phoneIntegration || ''}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Моб. Телефон:">
              <span>{scientist?.mobilePhoneIntegration || ''}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="E-mail:">
              <span>{scientist?.emailIntegration || ''}</span>
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
