import React, { memo } from 'react';
import { block } from 'bem-cn';

import './style.scss';

type Props = {
  label?: string;
  href?: string;
  classMixin?: string | string[];
  children?: JSX.Element | JSX.Element[];
};

const b = block('form-component-link');

export const Link = memo(({ href, label, classMixin, children }: Props) => (
  <div className={b({}).mix(classMixin)}>
    {href ? (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children || label || href}
      </a>
    ) : (
      <span>{label ? label : 'не указана'}</span>
    )}
  </div>
));
