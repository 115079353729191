import React from 'react';
import { Table } from 'types/models';
import { ConfirmPopup, Toolbar as SharedToolbar } from 'components';
import useController from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

export const Toolbar = ({ tableState }: Props) => {
  const {
    buttons,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    row,
    isViewFormOpen,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
  } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <modalTemplates.OtherProjectAddTemplate.Component isOpen={isAddFormOpen} onClose={onCloseAddForm} />
      <modalTemplates.OtherProjectEditTemplate.Component isOpen={isEditFormOpen} onClose={onCloseEditForm} id={row?.id} />
      <modalTemplates.OtherProjectViewTemplate.Component isOpen={isViewFormOpen} onClose={onCloseViewForm} id={row?.id} />
      <ConfirmPopup
        isOpen={isDeleteWarningOpen}
        icon="warning"
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={onCloseDeleteWarning}
        onConfirm={onSubmitDeleteWarning}
        // eslint-disable-next-line max-len
        text={`Вы точно хотите удалить выбранный проект? (Тема: ${tableState.selectedRows[0]?.Name}; ID: ${tableState.selectedRows[0]?.id})`}
        title="Предупреждение"
      />
    </>
  );
};
