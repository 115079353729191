import * as R from 'ramda';

import { CitationSystem, Partner, Contact } from 'types/models';
import { PeriodableReferenceItem, Change } from 'types/models/Partner';
import { convertFileToXML, getMaybeField } from '../commonConverters';
import { convertDocumentsToRequest } from '../converters';
import { SetPartnerHeadRequest } from './types';

export function convertPartnerToRequest(partner: Partner) {
  const body = {
    _attr: partner.id !== null ? { id: partner.id } : {},
    State: { _attr: { id: partner.state?.id ?? '' } },
    FullName: partner.fullName,
    Enterprise: partner.enterprise?.id ? { _attr: { id: partner.enterprise.id } } : null,
    EnterpriseCustomer:
      partner.enterpriseCustomer && partner.enterpriseCustomer.id ? { _attr: { id: partner.enterpriseCustomer.id } } : null,
    Manager: partner.manager ? { _attr: { id: partner.manager.id } } : null,
    Department: partner.department && partner.department.id ? { _attr: { id: partner.department.id } } : null,
    ActivitySector: partner.activitySector && partner.activitySector.id ? { _attr: { id: partner.activitySector.id } } : null,
    Lksd: partner.lksd && partner.lksd.id ? { _attr: { id: partner.lksd.id } } : null,
    Country: partner.country && partner.country.id ? { _attr: { id: partner.country.id } } : null,

    ShortName: partner.shortName || partner.fullName,
    BankRequisites: partner.bankRequisites,
    Inn: partner.inn,
    Kpp: partner.kpp,
    Ogrn: partner.ogrn,
    Okopf: partner.okopf,
    Email: partner.email,
    Phone: partner.phone,
    WebSite: partner.webSite,
    PostCode: partner.postCode,
    House: partner.house,
    Office: partner.office,
    Street: partner.street,
    City: partner.city,
    District: partner.district,
    CitationSystems: convertCitationSystemsToRequest(partner.citationSystems),
    Okveds: convertPeriodableReferenceItemsToRequest(partner.okveds),
    PartnershipTypes: convertPeriodableReferenceItemsToRequest(partner.partnershipTypes),
    Contacts: convertContactsToRequest(partner.contacts),
    Changes: convertChangesToRequest(partner.changes),
    Childs: convertChildPartnersToRequest(partner.childs),
    ...convertDocumentsToRequest(partner.documents),
  };

  return {
    Partner: {
      ...R.filter(Boolean, body as any),
    },
  };
}

export function convertChangesToRequest(changes: Change[]) {
  if (!changes.length) {
    return {};
  }

  return {
    Change: changes.map(x => ({
      _attr: x.id ? { id: x.id } : {},
      Category: { _attr: { id: x.category?.id ?? '' } },
      ChangeDate: x.changeDate,
      ...(x.description ? { Description: x.description } : {}),
      ...convertFileToXML('File', x.file),
    })),
  };
}

export function convertChildPartnersToRequest(childPartners: Partner[]) {
  if (!childPartners.length) {
    return {};
  }

  return {
    Child: childPartners.map(x => ({
      _attr: { id: x.id },
      ...getMaybeField('Enterprise', x.enterprise?.id && !x.id && { _attr: { id: x.enterprise?.id } }),
      ...getMaybeField('EnterpriseCustomer', x.enterpriseCustomer?.id && !x.id && { _attr: { id: x.enterpriseCustomer?.id } }),
      Relation: x.relation?.value ?? '',
    })),
  };
}

export function convertContactsToRequest(contacts: Contact[]) {
  if (!contacts.length) {
    return {};
  }

  return {
    Contact: contacts.map(x => ({
      _attr: x.id ? { id: x.id } : {},
      Person: { _attr: { id: x.person?.id ?? '' } },
      IsHead: x.isHead,
      IsActual: x.isActual,
      ...(x.appointment ? { Appointment: x.appointment } : {}),
      ...(x.department ? { Department: x.department } : {}),
      ...(x.communication ? { Communication: x.communication } : {}),
      ...(x.extraContacts ? { ExtraContacts: x.extraContacts } : {}),
      ...(x.note ? { Note: x.note } : {}),
    })),
  };
}

export function convertCitationSystemsToRequest(citationSystems: CitationSystem[]) {
  if (!citationSystems.length) {
    return {};
  }

  return {
    CitationSystem: citationSystems.map(x => ({
      _attr: x.id ? { id: x.id } : {},
      CitationSystem: { _attr: { id: x.refCitationSystem?.id } },
      Identifier: x.identifier,
      ...(x.name ? { Name: x.name } : {}),
      ...(x.url ? { Url: x.url } : {}),
    })),
  };
}

export function convertPeriodableReferenceItemsToRequest(refs: PeriodableReferenceItem[]) {
  if (!refs.length) {
    return {};
  }

  return {
    PeriodableReferenceElement: refs.map(x => ({
      _attr: x.id ? { id: x.id } : {},
      ReferenceElement: { _attr: { id: x.ref?.id ?? '' } },
      ...(x.startDate ? { StartDate: x.startDate } : {}),
      ...(x.endDate ? { EndDate: x.endDate } : {}),
      ...(x.note ? { Note: x.note } : {}),
    })),
  };
}

export function convertSetPartnerHeadToRequest(args: SetPartnerHeadRequest) {
  const { enterprisesIds, headId, partnerId, relation } = args;
  return {
    Partner: {
      _attr: { id: partnerId, headId, relationId: relation },
      Enterprises: {
        Enterprise: enterprisesIds.map(id => ({ _attr: { id } })),
      },
    },
  };
}
