import React from 'react';

import { Table } from 'types/models';
import { Toolbar as SharedToolbar, ConfirmPopup } from 'components';
import { BuildReportPopup } from 'features/BuildReportPopup';

import { useAppDataContext } from 'features/AppData/context';
import { State } from '../makeUseCustomController';

import { RelationsModal } from '../RelationsModal/RelationsModal';
import { useController } from './controller';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar({ customState, tableState }: Props) {
  const {
    buttons,
    isOpenConfirmDeletePartner,
    setIsOpenConfirmDeletePartner,
    deletePartner,
    row,
    isOpenApproveInfoModal,
    setMode,
    loadPartner,
    setIsOpenApproveInfoModal,
    isOpenRelationsForm,
    closeRelationsModal,
    partner,
    openForm,
    isReportOpen,
    onReportClose,
    currentReport,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  } = useController({ tableState, customState });
  const { settings } = useAppDataContext();
  return (
    <>
      <SharedToolbar buttons={buttons} />
      <ConfirmPopup
        title="Предупреждение"
        okButtonText="Да"
        resetButtonText="Отмена"
        icon="warning"
        isOpen={isOpenConfirmDeletePartner}
        onClose={() => setIsOpenConfirmDeletePartner(false)}
        text="Вы действительно хотите удалить выбранного партнёра?"
        onConfirm={() => {
          setIsOpenConfirmDeletePartner(false);
          deletePartner({ partnerId: row.id, type: row.Type, fullName: row.FullName, shortName: row.ShortName });
        }}
      />
      <ConfirmPopup
        title={`Подтверждение статуса организации - "Партнёр ${settings?.organization?.shortName}"`}
        okButtonText="Продолжить"
        resetButtonText="Отмена"
        isOpen={isOpenApproveInfoModal}
        onClose={() => setIsOpenApproveInfoModal(false)}
        text="Для подтверждения статуса необходимо актуализировать данные по организации"
        onConfirm={() => {
          if (row) {
            setMode('edit');
            loadPartner(row.id, openForm, row.Type);
            setIsOpenApproveInfoModal(false);
          }
        }}
      />
      <RelationsModal isOpen={isOpenRelationsForm} onClose={closeRelationsModal} partner={partner} />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name ?? ''}
        reportCaption={currentReport?.caption ?? ''}
      />
      <ConfirmPopup
        title={'Помощь в разделе "Cписок организаций"'}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Функциональный блок <strong>Партнёры</strong> предназначен для ведения учёта организаций-партнёров; соглашений о
            сотрудничестве и анализа данных о результатах совместной деятельности в научных исследованиях, совместных публикациях,
            организации и проведении научных мероприятий, в программах поддержки академической мобильности.
          </p>
          <p>
            Страница <strong>Список организаций</strong> содержит общий список организаций:
          </p>
          <ul>
            <li>
              из справочника <strong>«Заказчики»</strong> — список контрагентов университета; данные загружаются из информационной
              системы бухгалтерия и используются при описании проектов, заявок на конкурсы, договоров о распоряжении
              интеллектуальным правом, конкурсов и программ;
            </li>
            <li>
              из справочника <strong>«Организации»</strong> — список организаций, добавленных пользователями системы при описании
              мероприятий (конференций, выставок, олимпиад) и аффилиации авторов публикаций;
            </li>
            <li>
              малых инновационных предприятий университета (<strong>МИП</strong>);
            </li>
            <li>добавленные в этом пункте меню организации, с которыми заключены соглашения о сотрудничестве.</li>
          </ul>
          <p>
            В режиме анализа данных в таблице добавлены столбцы с количественными показателями результативности партнёрских
            отношений с этой организацией: количество совместных публикаций, участие в мероприятиях, стажировки сотрудников и
            студентов, научные проекты.
          </p>
          <p>
            Поскольку список содержит данные из разных источников, то в нём содержится много дубликатов — различных вариантов
            названий одной организации, поэтому предусмотрен функционал для объединения дубликатов организации, а именно:
          </p>
          <ul>
            <li>выбрать один из дубликатов, как правило, с наиболее правильным описанием (название и пр.);</li>
            <li>
              в режиме редактирования заполнить основные сведения <strong>Об организации</strong>;
            </li>
            <li>
              в разделе связи с другими организациями добавить организация дочерние филиалы из списка, указав форму отношения с
              этой организацией;
            </li>
            <li>в разделе варианты наименования добавить организации, которые являются дубликатами.</li>
          </ul>
          <p>
            Таким образом, данная организация, во-первых, приобретает статус Партнёра, во-вторых, к её статистическим данным о
            результатах сотрудничества добавляются данные объединённых организаций; в-третьих, относительно объединённых
            организаций, имеет форму отношений — головная организация.
          </p>
          <p>Объединённые организации при этом не удаляются из базы данных и интерпретируются как партнёр в том числе.</p>
          <p>
            Фильтр <strong>На подтверждение статуса «Партнёр»</strong> показывает список организаций, которые указаны в аффилиации
            у авторов публикации и не имеют статуса <strong>Партнёр</strong>.
          </p>
          <p>
            Фильтр <strong>Курируемые</strong> – показывает организации, которые закреплены за менеджером.
          </p>
        </div>
      </ConfirmPopup>
    </>
  );
}

export { Toolbar };
