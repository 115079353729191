import React, { memo } from 'react';

import { FormComponent, SectionTitle, TextInput, TextInputMode } from 'components';

import { Form as F, Author, Table } from 'types/models';
import { AboutAuthorship } from 'features/Form/views/AboutAuthorship';
import { useAppDataContext } from 'features/AppData/context';
import { DECIMAL_LIMIT, MAX_FRACTIONAL_COUNT } from 'utils/Constants/FractionalCount';
import useController from './controller';

type Props = {
  formFields: F.Fields;
  workMode: Table.WorkMode;
  authors: Author[];
  setAuthors(authors: Author[]): void;
};

const Authors = ({ workMode, formFields, authors, setAuthors }: Props) => {
  const { affilatedCounter, affilatedAuthors } = useController({
    authors,
  });

  const { settings } = useAppDataContext();

  return (
    <form>
      <FormComponent.Wrapper>
        <input type="submit" hidden />
        <SectionTitle
          // eslint-disable-next-line max-len
          title={`Сведения об авторстве - ${authors.length}; из них авторов, аффилированных с ${settings?.organization?.shortName} - ${affilatedAuthors} (всего аффилиаций - ${affilatedCounter})`}
          // eslint-disable-next-line max-len
          tooltip='"Сведения об авторстве" - это общее количество занесенных в список персон, а "из них авторов" - это количество персон с категорией ответственности: автор, автор-составитель и коллективный автор'
        />
        <FormComponent.Line hasGap>
          <FormComponent.Field isRequired={formFields.authorsCount.required} label={formFields.authorsCount.title}>
            <TextInput
              mode={TextInputMode.number}
              value={formFields.authorsCount.value ?? ''}
              onChange={formFields.authorsCount.onChange}
              settings={{ decimal: false }}
              isDisabled={workMode === 'viewMode'}
              isError={!formFields.authorsCount.isValid}
            />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.fractionalScore.title} isRequired={formFields.fractionalScore.required}>
            <TextInput
              mode={TextInputMode.number}
              value={formFields.fractionalScore.value ?? ''}
              onChange={formFields.fractionalScore.onChange}
              settings={{ max: MAX_FRACTIONAL_COUNT, decimal: { limit: DECIMAL_LIMIT } }}
              isDisabled={workMode === 'viewMode'}
              isError={!formFields.fractionalScore.isValid}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <AboutAuthorship
          language={formFields.textLanguage.value}
          disabled={workMode === 'viewMode'}
          authors={authors}
          setAuthors={setAuthors}
          isHistoryPersonDisabled={workMode !== 'editMode'}
        />
      </FormComponent.Wrapper>
    </form>
  );
};

export const AuthorsComponent = memo(Authors);
