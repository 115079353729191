import React, { useMemo } from 'react';

import { IconButtonProps, buttonIcons, Toolbar } from 'components';

export type Props = {
  onSave: (needClose: boolean) => void;
  onSaveAndContinue: () => void;
  onHelp: () => void;
  onClose: () => void;
};

function EditPartnerPanel({ onSave, onSaveAndContinue, onHelp, onClose }: Props) {
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      { icons: buttonIcons.save, title: 'Сохранить', onClick: () => onSave(true) },
      { icons: buttonIcons.saveAndContinue, title: 'Сохранить и продолжить', onClick: () => onSaveAndContinue() },
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: onHelp,
      },
      {
        icons: buttonIcons.cancel,
        title: 'Отмена',
        onClick: onClose,
      },
    ],
    [onSave, onSaveAndContinue, onHelp, onClose],
  );

  return <Toolbar buttons={buttons} mode="form" />;
}

const Component = React.memo(EditPartnerPanel);

export { Component as EditPartnerPanel };
