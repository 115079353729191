import React, { useMemo } from 'react';
import { block } from 'bem-cn';

import { ConfirmPopup, IconButton, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import EventParticipationTemplate from './modalTemplates/EventParticipationTemplate';
import eventTypes from './eventTypes';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  customState: State;
};

const b = block('toolbar');
function LeftPanelForThirdLevel({ tableState, customState: { eventType: gridEventType } }: Props) {
  const actualType = useMemo(
    () =>
      eventTypes.filter(event => event.code === gridEventType).length
        ? eventTypes.filter(event => event.code === gridEventType)[0]
        : eventTypes[0],
    [gridEventType],
  );
  const {
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    eventTypeCode,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    organizationName,
  } = useController({ tableState, actualType });

  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={eventTypeCode}
          />
        </div>
      )}
      {isViewFormOpen && (
        <modalTemplates.EventViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.EventEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.EventAddTemplate.Component
          eventTypeCode={eventTypeCode}
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранное мероприятие? (${tableState.selectedRows[0]?.EventName})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {gridEventType && <EventParticipationTemplate controllerIsForm={controllerIsForm} eventTypeName={gridEventType} />}
      <ConfirmPopup
        title="Помощь в работе творческими мероприятиями"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">Раздел Творческие мероприятия</strong> предназначен для поддержки процессов
            организационно-технического и финансового сопровождения творческих мероприятий университета: концертов, встреч,
            фестивалей и проч.
          </p>
          <p>
            <strong className="primary">В разделе Творческие мероприятия вы можете:</strong>
          </p>
          <ul>
            <li>
              Просмотреть информацию о мероприятии, участниках, место и время проведения с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.loop} hasNoHover />
            </li>
            <li>
              Добавить новое мероприятие с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover />
            </li>
            <li>
              Редактировать мероприятие в статусе Добавлена с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.edit} hasNoHover />
            </li>
            <li>
              Удалить запись о мероприятии, которую вы создавали, если мероприятие в статусе Добавлена с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.delete} hasNoHover />
            </li>
            <li>
              Выгрузить список мероприятий в файл Excel с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
            </li>
            <li>
              Добавить информацию об участниках мероприятия, указать связь с подразделением и информацию о полученных наградах на
              вкладке «Участники»
            </li>
          </ul>
          <p>
            <strong className="primary">Важно</strong>
          </p>
          <p>При сохранении записи о мероприятии присваивается статус Добавлена.</p>
          <div>
            На вкладке «Документы» обязательно прикрепите подтверждающий документ. Ответственное лицо проверит и утвердит
            мероприятие.
          </div>
          <div>
            Если университет является организатором или соорганизатором мероприятия, то все вкладки на форме обязательны к
            заполнению.
          </div>
          <div>
            Для добавления участия в мероприятии, организованного другой организацией, необходимо заполнить обязательные поля на
            вкладке "О мероприятии" и прикрепить Программу или информационное письмо на вкладке "Документы".
          </div>
          <ul>
            <li>
              <strong>Фильтр «Мои мероприятия»</strong> содержит список мероприятий, в которых вы указаны в составе участников.
            </li>
            <li>
              <strong>Фильтр «Мероприятия подразделения»</strong> содержит список мероприятий, связанных с вашим подразделением.
            </li>
            <li>
              <strong>Фильтр «Мероприятия {organizationName}»</strong> содержит список мероприятий, в которых организатором или
              соорганизатором является Университет.
            </li>
            <li>
              <strong>Фильтр «Другие мероприятия»</strong> показывает список мероприятий других организаций, в которых принимают
              участие сотрудники, аспиранты и студенты Университета.
            </li>
            <li>
              <strong>Фильтр «Показывать мероприятия за год»</strong> формирует список мероприятий, сроки проведения которых
              содержат указанный год. По умолчанию выбран текущий год.
            </li>
          </ul>
          <div>Добавленное вами мероприятие проверит и утвердит Администратор данных.</div>
          <div>
            Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору&nbsp;
            <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
          </div>
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
