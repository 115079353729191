import React from 'react';
import { Toolbar } from 'components';
import { QnaAddTemplate } from 'features/Table/modals';
import useController from './controller';
// eslint-disable-next-line max-len
export * as QnaAddTemplate from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates/QnaAddTemplate';

export type Props = {
  isExportButtonDisabled: boolean;
  exportTableData: () => Promise<void>;
  isWithQna?: boolean;
  isWithExport?: boolean;
};

export const ExportDataControl = ({ isExportButtonDisabled, exportTableData, isWithQna, isWithExport = true }: Props) => {
  const { buttons, isAddQuestionFormOpen, handleTemplateCloseAddQuestionForm } = useController({
    isExportButtonDisabled,
    exportTableData,
    isWithQna,
    isWithExport,
  });

  return (
    <>
      {isWithQna && <QnaAddTemplate.Component isOpen={isAddQuestionFormOpen} onClose={handleTemplateCloseAddQuestionForm} />}
      <Toolbar buttons={buttons} />
    </>
  );
};
