import { Payment } from 'types/models';

import { useController } from 'features/IncentivePayments/controller';
import { State as PaymentState } from 'features/IncentivePayments';

export function makeUseCustomController(event: Payment.EventType) {
  return function useCustomController(): PaymentState {
    return useController({ event });
  };
}
