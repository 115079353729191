import { useFormContext } from 'features/Form/hooks';
import { PublicationHeader } from 'features/Form/views/ModalHeader';
import { ModePublicationHeader } from 'features/Form/views/ModalHeader/publication';
import { useEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';

const useController = () => {
  const { settings } = useAppDataContext();
  const {
    look: { relatedTableState, id, setTitle },
  } = useFormContext();

  const { methods: GetPublication } = BackendAPI.useBackendAPI('GetPublication');

  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);

  useEffect(() => {
    GetPublication.callAPI(
      {
        simpleFields: {
          status: '',
        },
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
        attrIdFields: {},
        projects: [],
        mobileRequests: {},
        id,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setPublicationInfo(data);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        PublicationHeader({
          settings,
          mode: ModePublicationHeader.release,
          workMode: 'editMode',
          publicationId: publicationInfo?.status.id || '',
          publicationName: publicationInfo?.fields.name || '',
          publicationTypeCode: publicationInfo?.type || '',
          publicationElectronicTypeCode:
            publicationInfo?.fields.isElectronic === 'true' ? publicationInfo?.fields.electronicType || '' : '',
          departments: publicationInfo?.departments || [],
          status: publicationInfo?.status.text || '',
          publicationTypeEdition: {
            id: publicationInfo?.fields.typeEditionCode || '',
            label: publicationInfo?.fields.typeEditionText || '',
          },
          isPreprint: !!publicationInfo?.isPreprint,
        }),
      );
    }
  }, [
    publicationInfo,
    publicationInfo?.departments,
    publicationInfo?.fields.electronicType,
    publicationInfo?.fields.name,
    publicationInfo?.fields.typeEditionCode,
    publicationInfo?.fields.typeEditionText,
    publicationInfo?.isPreprint,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
  ]);

  return {
    relatedTableState,
    publicationInfo,
  };
};

export default useController;
