import React, { useCallback } from 'react';

import { block } from 'bem-cn';

import { Nominee, AuthorPayment, EventType } from 'types/models/Payment';
import { ValueOf } from 'types/helpers';
import { Info } from './Info/Info';
import { Nominees } from './Nominees/Nominees';
import { Departments } from './Departments/Departments';
import { LocalAuthorPayment } from 'features/IncentivePayments/model';

import './style.scss';

const b = block('request-payment-tab');

type Props = {
  authorPayment: LocalAuthorPayment | null;
  disabled: boolean;
  changeField(key: keyof AuthorPayment, value: ValueOf<AuthorPayment>): void;
  eventType: EventType;
};

export function Payment({ authorPayment, eventType, disabled, changeField }: Props) {
  const changeNominees = useCallback(
    (nominees: Nominee[]) => {
      changeField('nominees', nominees);
    },
    [changeField],
  );

  return (
    <div className={b()}>
      <Info disabled={disabled} changeField={changeField} authorPayment={authorPayment} />
      <Nominees eventType={eventType} disabled={disabled} nominees={authorPayment?.nominees ?? []} setNominees={changeNominees} />
      <Departments authorPayment={authorPayment} changeField={changeField} disabled={disabled} />
    </div>
  );
}
