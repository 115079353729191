import { GetLksdRequestData } from 'services/BackendAPI/configurations/partners/types';
import { Table } from 'types/models';

type Deps = {
  sectorActivityId: string;
};

export function GetLksdEnterpriseTypeBySectorActivity(deps: Deps): Table.Specification<object, GetLksdRequestData> {
  return {
    apiID: 'GetLksdEnterpriseTypeBySectorActivity',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
    requestData: deps,
  };
}
