import { Event } from 'types/models';

export function formatFromText(member: Event.MemberCountAnother) {
  const fromTextArray = [];
  if (member.country) fromTextArray.push(member.country.label);
  if (member.city) fromTextArray.push(member.city);
  if (member.enterprise) fromTextArray.push(member.enterprise.label);
  if (member.department) fromTextArray.push(member.department.label);
  return `
  <span>${fromTextArray.join(', ')}</span>
`;
}
