import React from 'react';

import { ConfirmPopup, Modal, Toolbar as SharedToolbar } from 'components';

import { FileInfo, Table } from 'types/models';
import useController from './controller';
import * as modalTemplates from './modalTemplates';
import { MessageModal } from 'features/Form/views/MessageModal/MessageModal';
import { DataGrid } from 'features/Table';
import { GetProjectAssignmentMessageList } from 'features/Table/specifications/GetProjectAssignmentMessageList';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export const titleTextMap: Record<string, string> = {
  MESSAGE: 'Добавить сообщение',
  IN_ACTION: 'Взять в работу',
  COMPLETE: 'Готово',
  CLOSED: 'Закрыть поручение',
  CANCELED: 'Отменить поручение',
};

export const submitTextMap: Record<string, string> = {
  MESSAGE: 'Отправить',
  IN_ACTION: 'В работе',
  COMPLETE: 'Готово',
  CLOSED: 'Закрыть',
  CANCELED: 'Отменить',
};

export const Toolbar = ({ customState, tableState }: Props) => {
  const {
    isOpenMessageModal,
    newStatus,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    isViewFormOpen,
    isHelpFormOpen,
    isMessageHistoryModalOpen,
    handleMessageHistoryModalClose,
    handleMessageModalClose,
  } = customState;

  const {
    buttons,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    handleTemplateCloseHelpForm,
    handleSaveStatusClick,
    handleSendMessageClick,
  } = useController({ customState, tableState });

  const submitsMap: Record<string, (message: string, file: FileInfo) => void> = {
    IN_ACTION: message => handleSaveStatusClick(message),
    COMPLETE: message => handleSaveStatusClick(message),
    CLOSED: message => handleSaveStatusClick(message),
    CANCELED: message => handleSaveStatusClick(message),
    MESSAGE: (message, file) => handleSendMessageClick(message, file),
  };

  return (
    <>
      <modalTemplates.ProjectAssignmentViewTemplate.Component
        isOpen={isViewFormOpen}
        onClose={onCloseViewForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />
      <modalTemplates.ProjectAssignmentEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={onCloseEditForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
        hasPersonalMode
      />
      <modalTemplates.ProjectAssignmentAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={onCloseAddForm}
        relatedTableState={tableState}
        hasPersonalMode
      />
      {tableState.selectedRows.length > 0 && (
        <>
          <MessageModal
            isOpen={isOpenMessageModal}
            onClose={handleMessageModalClose}
            onSubmit={submitsMap[newStatus.toString()]}
            titleText={titleTextMap[newStatus.toString()]}
            submitText={submitTextMap[newStatus.toString()]}
            hasFileAttachment={newStatus === 'MESSAGE'}
          />
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранное поручение? (${tableState.selectedRows[0].id})`}
            isOpen={isDeleteWarningOpen}
            onClose={onCloseDeleteWarning}
            onConfirm={onSubmitDeleteWarning}
            okButtonText="Да"
          />
          <Modal
            isOpen={isMessageHistoryModalOpen}
            onClose={handleMessageHistoryModalClose}
            title="Журнал сообщений"
            size={['large', 'small-height']}
          >
            <DataGrid
              specification={GetProjectAssignmentMessageList({
                projectAssignmentId: tableState.selectedRows[0].id,
              })}
            />
          </Modal>
        </>
      )}
      <ConfirmPopup
        title="Список поручений по проектам"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <strong className="primary">Поручения по научным проектам</strong>
          <p>
            Раздел предназначен для контроля исполнения поручений по проекту. Содержит список поручений и ленту сообщений ,
            связанных с поручением, из проектов, в которых пользователь в роли:
          </p>
          <ul>
            <li>Руководитель проекта</li>
            <li>Ответственный исполнитель</li>
            <li>Исполнитель, на которого назначено поручение</li>
          </ul>
          <p>Руководитель проекта и ответственный исполнитель имеют права на следующие действия:</p>
          <ul>
            <li>Добавить поручение</li>
            <li>Редактировать поручение - доступна в статусах "Добавлена", "В работе"</li>
            <li>Редактировать сообщение по поручению – доступна только для автора сообщения</li>
            <li>Удалить поручение в статусе «Добавлено»</li>
            <li>Отменить поручение – перевести в статус «Отменено»</li>
            <li>Закрыть поручение – перевести в статус «Закрыто»</li>
            <li>Изменить статус поручения из «Добавлено» в «В работе» и «Готово»</li>
            <li>Скачать файл - открывает файл, прикреплённый к поручению</li>
            <li>Открыть форму со списком сообщений, связанных с поручением (Журнал сообщений)</li>
            <li>Добавить сообщение по поручению</li>
          </ul>
          <p>Исполнитель, на которого назначено поручение, имеет права на следующие действия:</p>
          <ul>
            <li>Добавить сообщение по поручению</li>
            <li>Изменить статус поручения из «Добавлено» в «В работе» и «Готово»</li>
            <li>Скачать файл - открывает файл, прикреплённый к поручению</li>
            <li>Открыть форму со списком сообщений, связанных с поручением (Журнал сообщений)</li>
            <li>Редактировать сообщение по поручению – доступна только для автора сообщения</li>
          </ul>
          <p>Фильтр «Год» — отображает список поручений по периоду: Дата добавления - Выполнить до.</p>
          <p>Фильтр «Актуальные» — отображает список поручений в статусах «Добавлено» или «В работе»</p>
          <p>
            <strong className="primary">Важно:</strong>&nbsp;Поручение в статусах «Добавлено» или «В работе» выделяется в таблице:
          </p>
          <ul>
            <li>красным цветом, если текущая дата &ge; дата «Выполнить до» (просрочена дата)</li>
            <li>
              зелёным цветом, если текущая дата &ge; (дата «Выполнить до» -3) (т.е. за три дня до наступления критической даты)
            </li>
          </ul>
        </div>
      </ConfirmPopup>
      <SharedToolbar buttons={buttons} />
    </>
  );
};
