import React from 'react';

import { FormComponent, Reference, TextDateTime, TextInput } from 'components';

import { Person } from 'types/models';

type Props = {
  hirshIndex: Person.HirshIndex | null;
  setHirshIndex: React.Dispatch<React.SetStateAction<Person.HirshIndex | null>>;
};

export const Fields = React.memo(({ hirshIndex, setHirshIndex }: Props) => {
  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Значение">
          <TextInput value={hirshIndex?.value || ''} onChange={value => setHirshIndex(prevState => ({ ...prevState!, value }))} />
        </FormComponent.Field>
        <FormComponent.Field label="Система цитирования">
          <Reference
            name="RefCitationSystem"
            value={hirshIndex?.citationSystem || null}
            onChange={e => setHirshIndex(prevState => ({ ...prevState!, citationSystem: e }))}
            relationTableModalTitle='Справочник "Системы цитирования"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat growIndexes={[1, 9]}>
        <FormComponent.Field label="Дата расчета">
          <TextDateTime value={hirshIndex?.date} onChange={e => setHirshIndex(prevState => ({ ...prevState!, date: e }))} />
        </FormComponent.Field>
        <FormComponent.Field label="Примечание" labelSize="fit">
          <TextInput
            value={hirshIndex?.note || ''}
            onChange={val => setHirshIndex(prevState => ({ ...prevState!, note: val }))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
});
