import React from 'react';
import { block } from 'bem-cn';

import { ConfirmPopup, Modal, Toolbar /*, ToolbarNew*/ } from 'components';

import { EditRelationsModal } from 'features/EditRelationsModal';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import { Component as EditDoiPopup } from 'features/Form/views/EditDoiPopup';
import { Component as FinancicalSupportPopup } from 'features/Form/views/FinancicalSupportPopup';
import { EditArticleSource, PublicationArticlesWarningPopup, EditPreprintRelationPopup } from 'features/Form/views';
import { Component as PublicationOriginalTranslatesRelations } from 'features/Form/views/PublicationOriginalTranslatesRelations';
import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { Table } from 'types/models';
import { PublicationTypeCode } from 'types/models/Form';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { Component as PublicationTransformPopup } from 'features/Form/views/PublicationTransformPopup';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import { DataGrid } from 'features/Table';
import { GetPublicationUsagesList } from 'features/Table/specifications';

type Props = {
  tableState: Table.State;
};

const b = block('toolbar');

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    SUCH_LIKE_PUBLICATION_ID,
    publication,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    isAddFormOpenConferenceParticipation,
    isPrepreintRelationOpen,
    handleClosePreprintRelation,
    handleCloseFormConferenceParticipation,
    handleOpenFormConferenceParticipation,
    publicationConferenceParticipation,
    setPublicationConferenceParticipation,
    publicationConferenceSource,
    setPublicationConferenceSource,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    handleContinueStep,
    handleTemplateCloseAddForm,
    nextPublicationName,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isEditRelationsDisabled,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isDoiPopupOpened,
    handleCloseDoiPopup,
    submitDoiPopupCallback,
    isApprovePopupOpen,
    onApprove,
    handleApprovePopupClose,
    isCitationFormOpen,
    handleCloseCitationForm,
    isPublicationOriginalTranslatesRelationsDisabled,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    submitPublicationOriginalTranslatesRelationsOpen,
    isSourceCitationDisabled,
    isSourceCitationOpen,
    handleCloseSourceCitation,
    isFinancialSupportOpen,
    handleCloseFinancialSupport,
    submitFinancialPopupCallback,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isTransformOpen,
    setIsTransformOpen,
    isUsagesListOpen,
    handleCloseUsagesList,
    settings,
    selectedRow,
    // buttonsNew,
  } = useController(tableState);

  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikePublication
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            componentId={SUCH_LIKE_PUBLICATION_ID}
          />
        </div>
      )}

      <modalTemplates.PublicationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        name={nextPublicationName}
        relatedTableState={tableState}
        handleChangeParticipation={handleOpenFormConferenceParticipation}
        isHidden={isAddFormOpenConferenceParticipation}
        setPublication={setPublicationConferenceParticipation}
        setPublicationConferenceSource={setPublicationConferenceSource}
      />
      <EditPreprintRelationPopup
        isOpen={isPrepreintRelationOpen}
        onClose={handleClosePreprintRelation}
        publicationId={selectedRow?.id || '-1'}
      />
      {!!selectedRow && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}
      {!!selectedRow && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
          handleChangeParticipation={handleOpenFormConferenceParticipation}
          isHidden={isAddFormOpenConferenceParticipation}
          setPublication={setPublicationConferenceParticipation}
          setPublicationConferenceSource={setPublicationConferenceSource}
        />
      )}
      {!!selectedRow && !!tableState.selectedRows.length && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную публикацию? (${selectedRow.PublicationType})`}
          isOpen={isDeleteConfirmPopupOpen}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
        />
      )}

      {!isEditRelationsDisabled && !!tableState.selectedRows.length && (
        <EditRelationsModal
          title="Редактировать связь публикации с подразделениями"
          isOpen={isOpenEditRelations}
          onClose={handleCloseEditRelationsModal}
          publication={publication}
        />
      )}
      {!!selectedRow && !!tableState.selectedRows.length && (
        <EditDoiPopup
          isOpen={isDoiPopupOpened}
          onClose={handleCloseDoiPopup}
          onSuccess={submitDoiPopupCallback}
          publicationId={selectedRow.id as string}
        />
      )}

      {!!selectedRow && !!tableState.selectedRows.length && (
        <ApprovePopup
          isOpen={isApprovePopupOpen}
          selectedRowId={selectedRow.id}
          onApprove={onApprove}
          onClose={handleApprovePopupClose}
        />
      )}
      {!!selectedRow && !!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}
      {!isPublicationOriginalTranslatesRelationsDisabled && !!tableState.selectedRows.length && (
        <PublicationOriginalTranslatesRelations
          isOpen={isPublicationOriginalTranslatesRelationsOpen}
          onClose={handleClosePublicationOriginalTranslatesRelations}
          onSuccess={submitPublicationOriginalTranslatesRelationsOpen}
          publicationId={selectedRow!.id}
          relatedTableState={tableState}
        />
      )}
      {!isSourceCitationDisabled && (
        <modalTemplates.SourcePublicationCitationSystemTemplate.Component
          isOpen={isSourceCitationOpen}
          onClose={handleCloseSourceCitation}
          id={selectedRow.SourceId}
          relatedTableState={tableState}
        />
      )}
      {!!selectedRow && (
        <FinancicalSupportPopup
          isOpen={isFinancialSupportOpen}
          relatedTableState={tableState}
          onClose={handleCloseFinancialSupport}
          publicationId={selectedRow.id}
          workMode="editMode"
          onSuccess={submitFinancialPopupCallback}
        />
      )}
      {isAddFormOpenConferenceParticipation && (
        <modalTemplates.ConferenceParticipationAddTemplate.Component
          isOpen={isAddFormOpenConferenceParticipation}
          onClose={handleCloseFormConferenceParticipation}
          sourcePublication={publicationConferenceParticipation}
          sourceEvent={publicationConferenceSource}
        />
      )}
      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={selectedRow['id:PublicationType'] as PublicationTypeCode}
        />
      )}
      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={selectedRow.id}
          publicationTypeCode={selectedRow['id:PublicationType'] as PublicationTypeCode}
        />
      )}
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        department={settings?.userDepartment}
      />
      <PublicationTransformPopup
        isOpen={isTransformOpen}
        onClose={() => setIsTransformOpen(false)}
        publicationId={selectedRow?.id}
        onSuccess={() => {
          setIsEditFormOpen(true);
        }}
        relatedTableState={tableState}
      />

      <Modal
        isOpen={isUsagesListOpen}
        onClose={handleCloseUsagesList}
        title="Связи публикации с другими объектами"
        size={['extralarge', 'small-height']}
      >
        <DataGrid specification={GetPublicationUsagesList({ id: selectedRow?.id })} />
      </Modal>

      <Toolbar buttons={buttons} />
      {/* <ToolbarNew buttons={buttonsNew} /> */}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
