import { Table as T } from 'types/models';

import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export function GetForeignSecurityDocumentFeedlineList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
): T.Specification<State> {
  const toolbarEnabled: boolean = workMode !== 'addMode';
  return {
    apiID: 'GetForeignSecurityDocumentFeedlineList',
    useCustomController: makeUseCustomController(templatesTableDependencies),
    templatesTableDependencies,
    workMode,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: toolbarEnabled ? LeftPanelForThirdLevel({ workMode }) : undefined,
      },
    },
  };
}
