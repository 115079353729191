import React, { memo } from 'react';
import { Table } from 'types/models';
import { ConfirmPopup, Toolbar } from 'components';
import * as modalTemplates from './modalTemplates';
import useController from './controller';
import { Table as T } from 'types/models';

type Props = {
  tableState: Table.State;
};

type WrapperProps = {
  workMode: T.WorkMode;
};

const ToolbarTemplate = ({ workMode }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isAddFormOpen,
      isEditFormOpen,
      isViewFormOpen,
      closeViewForm,
      closeAddForm,
      closeEditForm,
      toolbarButtons,
      closeConfirmDeletePopup,
      isConfirmDeletePopupOpen,
      confirmDeleteRelease,
      isArticleFormOpen,
      closeArticleForm,
      relatedTableId,
    } = useController({
      tableState,
      workMode,
    });

    return (
      <>
        <Toolbar buttons={toolbarButtons} />

        <modalTemplates.MagazineReleaseAddTemplate
          isOpen={isAddFormOpen}
          onClose={closeAddForm}
          relatedTableId={relatedTableId}
        />
        <modalTemplates.MagazineReleaseViewTemplate
          isOpen={isViewFormOpen}
          onClose={closeViewForm}
          id={tableState.selectedRows[0]?.id}
        />
        <modalTemplates.MagazineReleaseEditTemplate
          isOpen={isEditFormOpen}
          onClose={closeEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableId={relatedTableId}
        />
        <modalTemplates.MagazineReleaseArticlesTemplate
          isOpen={isArticleFormOpen}
          onClose={closeArticleForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />

        {!!tableState.selectedRows.length && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранный выпуск? (${tableState.selectedRows[0].label})`}
            isOpen={isConfirmDeletePopupOpen}
            onClose={closeConfirmDeletePopup}
            onConfirm={confirmDeleteRelease}
            okButtonText="Да"
            resetButtonText="Отмена"
          />
        )}
      </>
    );
  });

export const Component = ToolbarTemplate;
