import { Table } from 'types/models';
import { Filters } from './filters';

type RequestData = {
  salaryJobPaymentIds: string;
  projectCodeId?: string;
};

export function GetSalaryJobPaymentList({
  salaryJobPaymentIds,
  projectCodeId,
}: RequestData): Table.Specification<{}, RequestData> {
  return {
    apiID: 'GetSalaryJobPaymentList',
    header: {
      firstLevel: {
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { projectCodeId, salaryJobPaymentIds },
  };
}
