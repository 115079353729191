import React from 'react';
// import { block } from 'bem-cn';

import { ConfirmPopup } from 'components';

import { Author } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { getMinAffilationsCount } from '../helpers';

type Props = {
  isOpen: boolean;
  author: Author | null;
  onClose(): void;
  onSubmit(): void;
};

function AmountAffilationModal(props: Props) {
  const { isOpen, author, onClose, onSubmit } = props;
  const { settings } = useAppDataContext();
  if (author === null) {
    return null;
  }

  const memberAffilationsCount = author.memberAffilations.length ?? 0;
  const minAffilationsCount = getMinAffilationsCount(author);
  const isAffilatedString = author.isAffilated
    ? `атрибут "Аффилирован с ${settings?.organization?.shortName}" включён - 1 аффилиация,`
    : '';
  return (
    <ConfirmPopup
      title="Количество аффиляций"
      text={`Указанное количество аффилиаций - "${
        author.affilateCount ?? 0
      }" меньше чем минимально возможное - "${minAffilationsCount}"
        (${isAffilatedString} количество аффилированных организаций - ${memberAffilationsCount})
        Установить количество аффилиаций равное ${minAffilationsCount}?`}
      isOpen={isOpen}
      okButtonText="Да"
      resetButtonText="Отмена"
      onClose={onClose}
      onConfirm={onSubmit}
      icon="warning"
    />
  );
}

export { AmountAffilationModal };
