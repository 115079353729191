export function yearsFromRange(startDate: Date | undefined, endDate: Date | undefined) {
  if (!startDate || !endDate || endDate.getTime() < startDate.getTime()) {
    return;
  }
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const years = [];
  let curYear = startYear;
  while (curYear <= endYear) {
    years.push(curYear);
    curYear += 1;
  }
  return years;
}
