import React from 'react';
import { block } from 'bem-cn';

import { Header, Tab, Tabs } from 'components';

import { PublicationShares } from 'features/Charts/PublicationShares';
import { PublicationWebOfScience } from 'features/Charts/PublicationWebOfScience';
import { PublicationSummary } from 'features/Charts/PublicationSummary';

import { EventParticipation } from 'features/Charts/EventParticipation';
import { ExpositionParticipation } from 'features/Charts/ExpositionParticipation';

import { PublicationFinancingNumbers } from 'features/Charts/PublicationFinancingNumbers';
import { PublicationExpenses } from 'features/Charts/PublicationExpenses';
import { PublicationFinancing } from 'features/Charts/PublicationFinancing';
import { PublicationFinancingByFaculties } from 'features/Charts/PublicationFinancingByFaculties';
import { SecurityDocumentsAmount } from 'features/Charts/SecurityDocumentsAmount';

import './style.scss';

const b = block('science-charts');

function ScienceCharts() {
  const formatNumbertoDate = (val: number) => (String(val).length === 2 ? String(val) : `0${val}`);
  const yearInterval = `01.01.${new Date().getFullYear() - 5} - ${formatNumbertoDate(new Date().getDate())}.${formatNumbertoDate(
    new Date().getMonth() + 1,
  )}.${new Date().getFullYear()} гг.`;

  return (
    <div className={b()}>
      <Header>Аналитические и статистические данные о научной и инновационной деятельности Университета за {yearInterval}</Header>
      <Tabs>
        <Tab title="Публикации">
          <div className={b('section')}>
            <div className={b('section-block')}>
              <PublicationShares />
            </div>
            <div className={b('section-block')}>
              <PublicationWebOfScience />
            </div>
            <div className={b('section-block')}>
              <PublicationSummary />
            </div>
          </div>
        </Tab>
        <Tab title="Мероприятия">
          <div className={b('section')}>
            <div className={b('section-block')}>
              <EventParticipation />
            </div>
            <div className={b('section-block')}>
              <ExpositionParticipation />
            </div>
          </div>
        </Tab>
        <Tab title="НИОКР">
          <div className={b('section')}>
            <div className={b('section-block')}>
              <PublicationFinancingNumbers />
            </div>
            <div className={b('section-block-wrapper')}>
              <div className={b('section-block')}>
                <PublicationExpenses />
              </div>
              <div className={b('section-block')}>
                <PublicationFinancing />
              </div>
            </div>
            <div className={b('section-block')}>
              <PublicationFinancingByFaculties />
            </div>
            <div className={b('section-block')}>
              <SecurityDocumentsAmount />
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export const Component = React.memo(ScienceCharts);
