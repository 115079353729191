import { Result } from '.';

type Props = {
  pageFrom: string;
  pageTo: string;
  pageCount?: string;
};

export const validatePages = ({ pageFrom, pageTo, pageCount }: Props) => {
  const result: Result = {
    isFormValid: true,
    invalidFieldKeys: [],
    nextNotification: { theme: 'danger', message: '' },
  };

  if (pageFrom && pageTo) {
    const pageFromValue = parseFloat(pageFrom);
    const pageToValue = parseFloat(pageTo);

    if (pageFromValue > pageToValue) {
      result.nextNotification.message = 'Страница "С" должна быть >= Страницы "ПО"';
      result.invalidFieldKeys = ['pageFrom', 'pageTo'];
      result.isFormValid = false;
    }

    if (result.isFormValid && pageCount) {
      const printPageCountValue = parseFloat(pageCount);

      if (pageToValue >= printPageCountValue) {
        result.nextNotification.message = 'Страница "ПО" должна быть меньше, чем чем количество страниц';
        result.invalidFieldKeys = ['pageTo'];
        result.isFormValid = false;
      }
    }
  }

  return result;
};
