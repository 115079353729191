import { useMemo, useState, useCallback, useLayoutEffect } from 'react';
import * as regexp from 'utils/Regexps';
import * as BackendAPI from 'services/BackendAPI';

import { Column, RelationTableModalSpecification } from 'components/ListEdit/model';

import { Form as F, Table as T } from 'types/models';
import { useLocalTableStreams, useTableContext } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { GetShortEventList } from 'features/Table/specifications';
import { getNumWord } from 'utils/Helpers';
import { State } from 'features/Table/specifications/GetShortEventList/makeUseCustomController';
import { EventFormat } from 'utils/Enums';
import { Props as ComponentProps } from './index';
import { showNotification } from 'features/Notifications';

type Props = {
  onClose: ComponentProps['onClose'];
};

const useController = ({ onClose }: Props) => {
  const tableContext = useTableContext();
  const { relatedTableState } = tableContext.specification.templatesTableDependencies!;
  const tableSelectedRows = useMemo(
    () => tableContext.specification.templatesTableDependencies?.relatedTableState.selectedRows || [],
    [tableContext],
  );
  const formContext = useFormContext();
  const releaseId = formContext.look.editMode ? formContext.look.id : '';
  const relatedTableId = formContext.look.relatedTableId ? formContext.look.relatedTableId : '';
  const magazineId = useMemo(() => relatedTableId || (tableSelectedRows.length ? tableSelectedRows[0].id : null), [
    relatedTableId,
    tableSelectedRows,
  ]);
  const { eventId } = formContext.look;
  const tableStreams = useLocalTableStreams();
  const [conferences, setConferences] = useState<F.Conference[]>([]);
  const [articleCount, setArticleCount] = useState<number>(0);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);

  const [formFields, setFormFields] = useState<F.Fields>({
    Year: {
      value: '',
      required: true,
      isValid: true,
      title: 'Год',
      onChange: (value: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          Year: { ...prevState.Year, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          Year: { ...prevState.Year, isValid: true },
        }));
      },
    },
    Month: {
      value: '',
      isValid: true,
      title: 'Месяц',
      onChange: (value: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          Month: { ...prevState.Month, value },
        }));
      },
    },
    Part: {
      value: '',
      isValid: true,
      title: 'Том',
      onChange: (value: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          Part: { ...prevState.Part, value },
        }));
      },
    },
    Number: {
      value: '',
      isValid: true,
      title: 'Выпуск',
      onChange: (value: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          Number: { ...prevState.Number, value },
        }));
      },
    },
    PublicationDate: {
      value: '',
      isValid: true,
      title: 'Дата публикации',
      onChange: (nextDate: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          PublicationDate: { ...prevState.PublicationDate, value: nextDate || '' },
        }));
      },
    },
    isConferenceMaterialsPublished: {
      value: false,
      isValid: true,
      title: 'В выпуске журнала опубликованы материалы конференции',
      onChange: () => {
        setFormFields((prevState: F.Fields) => {
          const nextValue = !prevState.isConferenceMaterialsPublished.value;
          if (!nextValue) {
            setConferences([]);
          }
          return {
            ...prevState,
            isConferenceMaterialsPublished: {
              ...prevState.isConferenceMaterialsPublished,
              value: nextValue,
            },
          };
        });
      },
    },
  });

  const { methods: getMagazineRelease, state: getMagazineReleaseAPIState } = BackendAPI.useBackendAPI('GetMagazineRelease');
  const { methods: saveMagazineRelease, state: saveMagazineReleaseAPIState } = BackendAPI.useBackendAPI('SaveMagazineRelease');
  const { methods: GetEventAPI, state: GetEventAPIState } = BackendAPI.useBackendAPI('GetEvent');

  const isMagazineReleaseLoading = getMagazineReleaseAPIState.kind === 'pending';
  const isMagazineReleaseSaving = saveMagazineReleaseAPIState.kind === 'pending';
  const isEventLoading = GetEventAPIState.kind === 'pending';

  const isLoading = useMemo(() => isMagazineReleaseLoading || isMagazineReleaseSaving || isEventLoading, [
    isMagazineReleaseLoading,
    isMagazineReleaseSaving,
    isEventLoading,
  ]);

  const submitRelease = useCallback(() => {
    const isValidYear = new RegExp(regexp.finalYearRegExp).test(formFields.Year.value);
    const isValidConferences =
      !formFields.isConferenceMaterialsPublished.value || (formFields.isConferenceMaterialsPublished.value && conferences.length);
    const isValidForm = isValidYear && isValidConferences;
    if (!isValidForm) {
      if (!isValidYear) {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          Year: { ...prevState.Year, isValid: false },
        }));
        showNotification({
          theme: 'danger',
          message: formFields.Year.value ? 'Год введен некорректно' : 'Введите год',
        });
      }

      if (!isValidConferences) {
        setTimeout(() => {
          showNotification({ theme: 'danger', message: 'Не указаны конференции' });
        });
      }
    } else {
      saveMagazineRelease.callAPI(
        {
          magazineId: magazineId!,
          releaseId,
          Year: formFields.Year.value as string,
          Part: formFields.Part.value as string,
          Month: formFields.Month.value as string,
          Number: formFields.Number.value as string,
          PublicationDate: formFields.PublicationDate.value as string,
          Events: conferences,
        },
        {
          onSuccessfullCall: () => {
            showNotification({
              message: !formContext.look.editMode ? 'Выпуск успешно сохранен' : 'Выпуск успешно отредактирован',
              theme: 'success',
            });

            tableStreams.reloadTable.push();
            onClose();
          },
        },
      );
    }
  }, [
    formFields.PublicationDate.value,
    formFields.Year.value,
    formFields.isConferenceMaterialsPublished.value,
    formFields.Part.value,
    formFields.Month.value,
    formFields.Number.value,
    conferences,
    saveMagazineRelease,
    magazineId,
    releaseId,
    formContext.look.editMode,
    tableStreams.reloadTable,
    onClose,
  ]);

  const onSubmitReleaseClick = useCallback(() => {
    const isSomeArticleWillChange = Boolean(articleCount);

    if (isSomeArticleWillChange) {
      setIsWarningPopupOpen(true);
    } else {
      submitRelease();
    }
  }, [submitRelease, articleCount]);

  const resetState = useCallback(() => {
    setConferences([]);
    setFormFields((prevState: F.Fields) => ({
      ...prevState,
      Year: { ...prevState.Year, value: '' },
      Month: { ...prevState.Month, value: '' },
      Part: { ...prevState.Part, value: '' },
      Number: { ...prevState.Number, value: '' },
      PublicationDate: { ...prevState.PublicationDate, value: '' },
      isConferenceMaterialsPublished: { ...prevState.isConferenceMaterialsPublished, value: false },
    }));
  }, []);

  const fetchEvent = useCallback(
    ({ currentEvents }: { currentEvents: F.Conference[] }) => {
      GetEventAPI.callAPI(
        {
          simpleFields: {
            type: 'CHECK OTHER',
            domain: 'EVENT',
            memberCountPlan: 0,
            memberCountFact: 0,
            isStudent: false,
          },
          attrIdFields: {},
          arrayFields: {
            Grntis: { translation: [] },
            MinistryEducations: { translation: [] },
            CriticalTechnologies: { translation: [] },
            Pnmitrs: { translation: [] },
            Pnrs: { translation: [] },
            Pnis: { translation: [] },
            Partners: { translation: [] },
          },
          id: eventId,
          format: EventFormat.OFFLINE,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setConferences([
              ...currentEvents,
              { id: eventId || '', name: data.fields.name, status: data.status.label, fullName: data.fullName },
            ]);
          },
        },
      );
    },
    [GetEventAPI, eventId],
  );

  const fetchPublication = useCallback(async () => {
    if (releaseId) {
      getMagazineRelease.callAPI(
        { releaseId },
        {
          onSuccessfullCall: ({ data }: any) => {
            if (data.success && data.body) {
              setFormFields((prevState: F.Fields) => ({
                ...prevState,
                Year: { ...prevState.Year, value: data.body!.Year },
                Month: { ...prevState.Month, value: data.body!.Month },
                Part: { ...prevState.Part, value: data.body!.Part },
                Number: { ...prevState.Number, value: data.body!.Number },
                PublicationDate: { ...prevState.PublicationDate, value: data.body!.PublicationDate },
                isConferenceMaterialsPublished: {
                  ...prevState.isConferenceMaterialsPublished,
                  value: !!data.body!.conferences.length || Boolean(eventId),
                },
              }));

              setArticleCount(data.body.articleCount);

              setConferences(data.body.conferences);
              if (eventId) {
                const isEventAlredyIn = (data.body.conferences || []).some(
                  (conference: F.Conference) => conference.id === eventId,
                );
                if (!isEventAlredyIn) {
                  fetchEvent({ currentEvents: data.body.conferences });
                }
              }
            }
          },
        },
      );
    } else if (eventId) {
      fetchEvent({ currentEvents: [] });
      setFormFields((prevState: F.Fields) => ({
        ...prevState,
        Year: { ...prevState.Year, value: new Date().getFullYear() },
        isConferenceMaterialsPublished: {
          ...prevState.isConferenceMaterialsPublished,
          value: Boolean(eventId),
        },
      }));
    }
  }, [releaseId, getMagazineRelease, eventId, fetchEvent]);

  useLayoutEffect(() => {
    resetState();
    fetchPublication();
  }, []); // eslint-disable-line

  const modalTableSpecification = useMemo(
    () =>
      GetShortEventList({
        templatesTableDependencies: relatedTableState && {
          relatedTableAPIID: 'GetMagazineList',
          relatedTableState,
          relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
        },
        requestData: {
          eventType: 'CONFERENCE',
          planned: true,
          allEvents: true,
        },
        isDisableSource: formContext.look.isDisableEventSource,
        isMagazineReleasesPlanned: formContext.look.isDisableEventSource,
        hasFilter: true,
      }),
    [formContext.look.isDisableEventSource, relatedTableState],
  );

  const modalTableRowConverter = useCallback<(row: T.Entry) => F.Conference>(
    row => ({
      id: row.id,
      name: row.name,
      fullName: '',
    }),
    [],
  );

  const relationTableModalState = useMemo<RelationTableModalSpecification<F.Conference, State>>(
    () => ({
      relationTableModalTitle: 'Конференции',
      modalTableSpecification,
      modalTableRowConverter,
      mode: 'relationTableModal',
    }),
    [modalTableSpecification, modalTableRowConverter],
  );

  const conferenceColumns = useMemo<Column<F.Conference>[]>(
    () => [{ label: 'Название', formatValue: row => row.name ?? '' }],
    [],
  );

  const updateConferences = useCallback(
    (nextConferences: F.Conference[]) => {
      setConferences(nextConferences);
    },
    [setConferences],
  );

  const closeWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(false);
  }, []);

  const submitWarningPopup = useCallback(() => {
    submitRelease();
  }, [submitRelease]);

  const warningPopupText = useMemo(
    () =>
      getNumWord({
        words: [
          // eslint-disable-next-line max-len
          `Внимание, в связи с изменением выпуска будет изменена библиографическая ссылка для ${articleCount} статьи этого выпуска`,
          // eslint-disable-next-line max-len
          `Внимание, в связи с изменением выпуска будут изменены библиографические ссылки для ${articleCount} статей этого выпуска`,
          // eslint-disable-next-line max-len
          `Внимание, в связи с изменением выпуска будут изменены библиографические ссылки для ${articleCount} статей этого выпуска`,
        ],
        count: articleCount,
      }),
    [articleCount],
  );

  return {
    eventId,
    conferences,
    formFields,
    conferenceColumns,
    submitRelease,
    updateConferences,
    closeWarningPopup,
    submitWarningPopup,
    warningPopupText,
    isWarningPopupOpen,
    relationTableModalState,
    onSubmitReleaseClick,
    isLoading,
  };
};

export { useController };
