import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { AuthorPayment } from 'types/models/Payment';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { isNextPaymentStatus } from 'utils/Helpers/authorPayments';
import { usePermissions } from '../usePermissions';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void, needConfirm?: boolean): void;
  onSaveDraft(): void;
  onChangeStatus(status: AuthorPaymentRequestStatus | null): void;
  onChangeConfirm(status: AuthorPaymentRequestStatus | null): void;
  paymentRequest?: AuthorPayment | null;
};

export function useController(props: Props) {
  const { onSave, onSaveDraft, onChangeStatus, onChangeConfirm, paymentRequest } = props;

  const status = useMemo(() => paymentRequest?.status?.value as AuthorPaymentRequestStatus, [paymentRequest]);

  const mode = useMemo(() => (paymentRequest?.id ? 'edit' : 'add'), [paymentRequest]);

  const [isFeedlineModalOpen, setIsFeedlineModalOpen] = useState<boolean>();

  const permissions = usePermissions(status);

  const changeStatusTo = useCallback(
    (x: AuthorPaymentRequestStatus | null) => {
      if (!checkPaymentByStatus(paymentRequest?.factNomineesMoney ?? null, x)) {
        onSave(false, () => {}, false);
        onChangeStatus(x);
      } else {
        onChangeConfirm(x);
      }
    },
    [onChangeStatus, onChangeConfirm, onSave, paymentRequest],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        isHidden: status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
        onClick: () => {
          onSave(true);
        },
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        isHidden: status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
        onClick: () => {
          onSaveDraft();
        },
      },
      {
        icons: buttonIcons.like,
        title: 'Подать на согласование',
        permissionName: permissions.mapStatusPermission[status],
        isHidden: !isNextPaymentStatus(status, AuthorPaymentRequestStatus.AGREEMENT),
        isDisabled: false,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.AGREEMENT as AuthorPaymentRequestStatus),
      },
      {
        icons: buttonIcons.paymentHandover,
        title: 'Передать на оплату',
        permissionName: permissions.mapStatusPermission[status],
        isHidden: !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.PAID) || mode === 'add',
        isDisabled: false,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID as AuthorPaymentRequestStatus),
      },
      {
        icons: buttonIcons.conflictsNone,
        title: 'Одобрить оплату',
        permissionName: permissions.mapStatusPermission[status],
        isHidden: !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.PAYMENT_APPROVED) || mode === 'add',
        isDisabled: false,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAYMENT_APPROVED as AuthorPaymentRequestStatus),
      },
      {
        icons: buttonIcons.clock,
        title: 'Отложить',
        permissionName: permissions.mapStatusPermission[status],
        isHidden: !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.POSTPONE) || mode === 'add',
        isDisabled: false,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE as AuthorPaymentRequestStatus),
      },
      {
        icons: buttonIcons.update,
        title: 'Отправить на доработку',
        code: 'update',
        permissionName: permissions.mapStatusPermission[status],
        isHidden: !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.REVISION) || mode === 'add',
        isDisabled: false,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REVISION as AuthorPaymentRequestStatus),
      },
      {
        icons: buttonIcons.paymentCancel,
        title: 'Отклонить',
        permissionName: permissions.mapStatusPermission[status],
        isHidden: !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.REJECTED) || mode === 'add',
        isDisabled: false,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED as AuthorPaymentRequestStatus),
      },
      {
        icons: buttonIcons.comment,
        title: 'Лента активности',
        isDisabled: false,
        onClick: () => {
          setIsFeedlineModalOpen(true);
        },
      },
    ],
    [onSave, onSaveDraft, changeStatusTo, mode, status, permissions],
  );
  return {
    buttons,
    isFeedlineModalOpen,
    setIsFeedlineModalOpen,
  };
}
