import { useLocalTableStreams } from 'features/Table/hooks';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { SecurityDocumentTypes, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
  documentType?: string | null;
};

export function useController(props: Props) {
  const { tableState, documentType } = props;
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDepartmentsButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;
  const isPrintButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const securityDocumentId = tableState.selectedRows[0]?.id;

  const onClickPrint = useCallback(() => {
    handleSetCurrentReport({ name: Reports.SecurityDocumentAuthors.caption, value: Reports.SecurityDocumentAuthors.name });
  }, [handleSetCurrentReport]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteSecurityDocument',
      deletedItemPropName: 'ProjectSecurityDocument',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const permissionByDocumentType = useCallback(
    action => {
      switch (documentType) {
        case SecurityDocumentTypes.KNOW_HOW:
          switch (action) {
            case 'add':
              return Permits.KNOW_HOW_ADD;
              break;
            case 'edit':
              return Permits.KNOW_HOW_EDIT;
              break;
            case 'delete':
              return Permits.KNOW_HOW_DELETE;
              break;
            case 'view':
            default:
              return Permits.KNOW_HOW_VIEW;
              break;
          }
          break;
        case SecurityDocumentTypes.EVIDENCE:
          switch (action) {
            case 'add':
              return Permits.EVIDENCE_ADD;
              break;
            case 'edit':
              return Permits.EVIDENCE_EDIT;
              break;
            case 'delete':
              return Permits.EVIDENCE_DELETE;
              break;
            case 'view':
            default:
              return Permits.EVIDENCE_VIEW;
              break;
          }
          break;
        case SecurityDocumentTypes.NON_REGISTERED:
          switch (action) {
            case 'add':
              return Permits.NON_REGISTERED_ADD;
              break;
            case 'edit':
              return Permits.NON_REGISTERED_EDIT;
              break;
            case 'delete':
              return Permits.NON_REGISTERED_DELETE;
              break;
            case 'view':
            default:
              return Permits.NON_REGISTERED_VIEW;
              break;
          }
          break;
        case SecurityDocumentTypes.PATENT:
        default:
          switch (action) {
            case 'add':
              return Permits.PATENT_ADD;
              break;
            case 'edit':
              return Permits.PATENT_EDIT;
              break;
            case 'delete':
              return Permits.PATENT_DELETE;
              break;
            case 'view':
            default:
              return Permits.PATENT_VIEW;
              break;
          }
          break;
      }
    },
    [documentType],
  );

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: isViewButtonDisabled,
        permissionName: permissionByDocumentType('view'),
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isDisabled: false,
        permissionName: permissionByDocumentType('add'),
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: isEditButtonDisabled,
        permissionName: permissionByDocumentType('edit'),
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        isDisabled: isDeleteButtonDisabled,
        permissionName: permissionByDocumentType('delete'),
        onClick: handleDeleteButtonClick,
      },
      {
        icons: buttonIcons.print,
        title: 'Авториский коллектив РИД',
        onClick: onClickPrint,
        isDisabled: isPrintButtonDisabled,
        permissionName: permissionByDocumentType('view'),
      },
    ],
    [
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      isPrintButtonDisabled,
      onClickPrint,
      permissionByDocumentType,
    ],
  );

  return {
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDepartmentsButtonDisabled,
    isDeleteButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleDeleteButtonClick,
    handleViewButtonClick,
    handleTemplateCloseViewForm,
    handleAddButtonClick,
    handleTemplateCloseAddForm,
    handleEditButtonClick,
    handleTemplateCloseEditForm,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    securityDocumentId,
  };
}
