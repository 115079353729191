import React from 'react';
import { DataGrid } from 'features/Table';
import { GetMagazineReleaseList } from 'features/Table/specifications';
import { FormComponent } from 'components';
import useController from './controller';

const MagazineRelease = () => {
  const { relatedTableState } = useController();

  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        {!!relatedTableState && (
          <DataGrid
            specification={GetMagazineReleaseList(
              {
                relatedTableAPIID: 'GetMagazineList',
                relatedTableState,
                relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
              },
              'editMode',
            )}
          />
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
};

export const Component = React.memo(MagazineRelease);
