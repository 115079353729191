import React from 'react';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { ConfirmPopup } from 'components';
import useController from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isEditButtonDisabled,
    isConfirmPopupDisabled,
    buttons,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isConfirmPopupOpen,
    resetDelete,
    confirmDelete,
  } = useController({ tableState });

  return (
    <>
      {!isEditButtonDisabled && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isConfirmPopupDisabled && (
        <ConfirmPopup
          title="Предупреждение"
          text="Вы точно хотите удалить выбранный элемент?"
          isOpen={isConfirmPopupOpen}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={resetDelete}
          onConfirm={confirmDelete}
          icon="warning"
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
