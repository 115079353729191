import React, { memo } from 'react';

import { ConfirmPopup, FormComponent, Modal, Toolbar } from 'components';
import { Table } from 'types/models';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';

import { ParticipationFinancicalSupportPopup } from 'features/Form/views/ParticipationFinancicalSupportPopup';
import useController from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({ tableState }: Props) => {
  const {
    buttons,
    isReportOpen,
    currentReport,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    isWarningPopupOpen,
    isApprovePopupOpen,
    isConfirmDeleteOpen,
    isEditDepartmentsOpen,
    participationInfoText,
    isFinancicalSupportPopupOpen,
    submitFinancialPopupCallback,
    handleCloseFinancicalSupportPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    handleCloseWarningPopup,
    handleAttachParticipation,
    handleCloseApprovePopup,
    handleCloseEditDepartments,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    afterSubmitEditDepartments,
    afterSubmitApproveParticipation,
    onReportClose,
    settings,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    tableState,
  });
  return (
    <>
      <EditParticipationDepartments
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
        isOpen={isEditDepartmentsOpen}
        onClose={handleCloseEditDepartments}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitEditDepartments}
      />
      <ApproveParticipation
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
        isOpen={isApprovePopupOpen}
        onClose={handleCloseApprovePopup}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitApproveParticipation}
      />
      <ConfirmPopup
        isOpen={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        title="Подтверждение удаления"
        text="Вы действительно хотите удалить выбранное участие?"
        icon="warning"
        okButtonText="Удалить"
        resetButtonText="Отмена"
        onConfirm={handleDeleteParticipation}
      />
      <ConfirmPopup
        isOpen={isWarningPopupOpen}
        onClose={handleCloseWarningPopup}
        title="Привязка к подразделению"
        text={participationInfoText}
        icon="warning"
        okButtonText="Ок"
        resetButtonText="Отмена"
        onConfirm={handleAttachParticipation}
      />
      {isEditFormOpen && (
        <modalTemplates.ExpositionParticipationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}
      {isViewFormOpen && (
        <modalTemplates.ExpositionParticipationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.ExpositionParticipationAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        department={settings?.userDepartment}
      />
      <ParticipationFinancicalSupportPopup
        participationId={tableState.selectedRows[0]?.id ?? '-1'}
        isOpen={isFinancicalSupportPopupOpen}
        onClose={handleCloseFinancicalSupportPopup}
        relatedTableState={tableState}
        onSuccess={submitFinancialPopupCallback}
        workMode="editMode"
        participationTypeCode={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
      />

      <Toolbar buttons={buttons} />
      <Modal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} title="Участия в выставках" size="medium">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <h2>Алгоритмы заполнения данных в «Участияx в выставках»</h2>
            <h3>Вкладка «Описание»</h3>
            <ul className="dashed">
              <li>
                поле <strong>«Выставка»</strong> - Вручную в поле нельзя ввести данные. Выставка, в которой было принято данное
                участие выбирается из справочника «Выставки» (открывается таблица раздела «Выставки»). Если в таблице нет нужной
                выставки, то можно по кнопке «Добавить» выставку.
              </li>
              <li>
                <strong>«Уровень мероприятия» и «Формат проведения»</strong> после выбора выставки данные автоматически
                подставляются из одноименных полей самой выставки
              </li>
              <li>
                поле <strong>«Формат участия»</strong> - Вручную в поле нельзя ввести данные. Необходимо выбрать из справочника
                «Форматы участия» один из четырех вариантов участия.{' '}
                <strong>Состав полей и данные различаются в зависимости от выбора «Формата участия»:</strong>
                <ul>
                  <li>Презентация разработки</li>
                  <li>Презентация проекта</li>
                  <li>Работа на экспозиции</li>
                  <li>Участие в деловой программе</li>
                </ul>
              </li>
              <li>
                таблица <strong>«Сведения об участниках выставки»</strong> необходимо выбрать ФИО из справочника «Персон» и
                указать роль персоны, выбрав из справочника «Роли участников мероприятия». Персону в роли Руководитель необходимо
                добавить на форму последним. Во втором столбике таблицы отображается награда участника, если есть награда и данные
                о ней занесены на вкладке «Награды».
                <ul>
                  <li>Презентация разработки (если выбран данный формат участия, то)</li>
                </ul>
              </li>
              <li>
                чек-бокс <strong>«Коллективная экспозиция»</strong>, если экспозиция является коллективной, то необходимо
                установить отметку в чек-бокс и следующее поле «Экспозиция» становится обязательным к заполнению
              </li>
              <li>
                поле <strong>«Экспозиция»</strong> - данные об экспозиции вносятся в поле вручную
              </li>
              <li>
                поле <strong>«Название разработки»</strong> - Необходимо выбрать из справочника «Список разработок, представленных
                на выставках». Если нет разработки в справочнике, то необходимо по кнопке «Добавить» разработку. Для этого на
                форме добавления новой разработки заполняются следующие поля:
                <ul className="dashed">
                  <li>поле «Тип» выбрать из справочника «Тип разработок»</li>
                  <li>поле «Название разработки»</li>
                  <li>
                    поле «Продукт для коммерциализации (ОИС)» заполняется, если разработка связана с объектом интеллектуальной
                    собственности из справочника «Продукт для коммерциализации (ОИС)»
                  </li>
                  <li>в таблицу «Контактные лица» добавляется данные выбранной персоны из справочника «Персон»</li>
                  <li>
                    в таблицу «Организации-партнеры» необходимо выбрать из справочника «Партнеры» организацию, если разработка
                    выполняется совместно с организацией
                  </li>
                </ul>
              </li>
              <li>
                чек-боксы <strong>«Форма представления»</strong> - обязательно необходимо выбрать один или более чек боксов в
                какой форме представлена разработка
                <ul>
                  <li>Презентация проекта (если выбран данный формат участия, то)</li>
                </ul>
              </li>
              <li>
                чек-бокс <strong>«Продвижение результатов проекта»</strong> обязательно необходимо выбрать проект из справочника
                «Проекты» (открывается таблица списков проектов научного управления: Гранты, Задания на НИР, Хоздоговоры,
                Контракты)
              </li>
              <li>
                чек-боксы <strong>«Форма представления»</strong> - обязательно необходимо выбрать один или более чек боксов в
                какой форме представлена разработка
                <ul>
                  <li>Работа на экспозиции (если выбран данный формат участия, то)</li>
                </ul>
              </li>
              <li>
                чек-бокс <strong>«Коллективная экспозиция»</strong>, если экспозиция является коллективной, то необходимо
                установить отметку в чек-бокс и следующее поле «Экспозиция» становится обязательным к заполнению
              </li>
              <li>
                поле <strong>«Экспозиция»</strong> - данные об экспозиции вносятся в поле вручную
              </li>
            </ul>
            <h3>Вкладка «Награды» (заполняется после проведения мероприятия)</h3>
            <p>По кнопке «Добавить» можно внести данные о награде. </p>
            <ul>
              <li>
                поле <strong>«Категория награды»</strong> - выбрать категорию награды из выпадающего меню
              </li>
              <li>
                поле <strong>«Название награды»</strong> обязательно указать название награды вручную
              </li>
              <li>
                поле <strong>«Номинации»</strong> вручную внести название номинации
              </li>
              <li>
                <strong>«Файл копия документа»</strong> прикрепить файл подтверждающий наличие награды
              </li>
              <li>
                в таблице <strong>«Номинанты. Выберите из списка участников мероприятия»</strong> выбрать участника или
                участников, удостоенных наград. Если не выбрать конкретного участника, то награда отобразится автоматически у всех
                участников, указанных на вкладке «Описание».
              </li>
            </ul>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};

export const Component = memo(LeftPanelForThirdLevel);
