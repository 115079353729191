import { Table } from 'types/models';
import { Filters } from './filters';

export function GetProjectScopusWosPublicationList(): Table.Specification {
  return {
    apiID: 'GetProjectScopusWosPublicationList',
    header: {
      firstLevel: {
        title: 'Публикации Scopus, WoS по проектам',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    isWithQna: true,
  };
}
