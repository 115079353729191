import { Parameter, RangeValue } from 'types/models';
import { compareDates } from './helpers';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateDateRangeParameters = (dateRangeParameter: Parameter): ValidatorResult => {
  const dateRange = dateRangeParameter.value as RangeValue;
  const { isFromMoreTo } = compareDates(dateRange.from, dateRange.to);
  const isValid = !isFromMoreTo;

  return {
    isValid,
    message: isValid
      ? ''
      : `Дата начала периода не должна быть больше даты конца периода в поле "${dateRangeParameter.description}"`,
  };
};

export default validateDateRangeParameters;
