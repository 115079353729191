import React, { memo, useCallback } from 'react';
import { ReferenceItem, Select, FormComponent } from 'components';

type Props = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  fieldLabel: string;
  userDepartments: ReferenceItem[];
};

const SelectUserDepartments = ({ referenceItem, setReferenceItem, fieldLabel, userDepartments }: Props) => {
  const handleSetReferenceItem = useCallback(
    (nextDepartmentLabel: string) => {
      const nextEnumValue = userDepartments.find(({ label }) => label === nextDepartmentLabel);
      if (nextEnumValue) {
        setReferenceItem(nextEnumValue);
      }
    },
    [setReferenceItem, userDepartments],
  );

  const renderEntry = useCallback(x => x, []);

  return (
    <FormComponent.Line>
      <FormComponent.Field label={fieldLabel} isRequired>
        <Select.Component
          value={referenceItem?.label || ''}
          variant="outlined"
          defaultPlaceholder="Не задан"
          entries={userDepartments.map(userDepartment => userDepartment.label)}
          onChange={handleSetReferenceItem}
          renderEntry={renderEntry}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(SelectUserDepartments);

export { Component as SelectUserDepartments };
