import { Form } from 'types/models';

const isISSNValid = (issnField: Form.Field) => {
  const { value } = issnField;

  if (!value) {
    return true;
  }

  const mask = [/\d/, /\d/, /\d/, /\d/, /(-)/, /\d/, /\d/, /\d/, /\d|X|x/];
  if (value.length !== mask.length) {
    return false;
  }

  return [...value].every((char: string, index: number) => mask[index].test(char));
};

export { isISSNValid };
