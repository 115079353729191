import React from 'react';
import { block } from 'bem-cn';

import { Button, ButtonMode, Checkbox, FormComponent, Modal } from 'components';

import { Project } from 'types/models';
import { useController } from './controller';
import { getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';

import './style.scss';

const b = block('edit-stages-modal');

type Props = {
  isOpen: boolean;
  onClose(): void;
  stages: Project.Stage[];
  performerStages: Project.PerformerStage[];
  setPerformerStages(performersStages: Project.PerformerStage[]): void;
};

export function EditStagesModal(props: Props) {
  const { isOpen, onClose, stages, setPerformerStages, performerStages } = props;

  const {
    handleSubmit,
    setIsApprovedInPerformerStage,
    setStageInPerformer,
    mapSelectedApproved,
    mapSelectedStages,
  } = useController({ isOpen, onClose, stages, setPerformerStages, performerStages });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавление исполнителя на этапы" size="small">
      <FormComponent.Wrapper specialStyles={{ padding: '15px' }}>
        <>
          {stages.map((stage, index) => {
            const isSelectedStage = Boolean(mapSelectedStages?.[stage.number]);
            const isApproved = Boolean(mapSelectedApproved?.[stage.number]);

            return (
              <div key={index} className={b('line')}>
                <div className={b('stage')}>
                  <Checkbox
                    label={getStageTitle(stage)}
                    checked={isSelectedStage}
                    onChange={checked => setStageInPerformer(stage, checked)}
                  />
                </div>
                <Checkbox
                  label="Подтвержден"
                  checked={isApproved}
                  onChange={checked => setIsApprovedInPerformerStage(stage, checked)}
                  isDisabled={!isSelectedStage}
                />
              </div>
            );
          })}
          <FormComponent.Actions>
            <Button mode={ButtonMode.primary} text="Добавить" onClick={handleSubmit} />
            <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
          </FormComponent.Actions>
        </>
      </FormComponent.Wrapper>
    </Modal>
  );
}
