import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, CustomSelect } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  articlePublicationIds: string[];
  onClose: () => void;
  sourcePublicationId: string;
  relatedTableState?: Table.State;
};

export const Component = memo(({ onClose, articlePublicationIds, sourcePublicationId, relatedTableState }: Props) => {
  const {
    sourceMonograph,
    handleSubmit,
    changeSourceMonograph,
    modalTableRowToSourceMonographConventer,
    sourceMonographTableConfig,
  } = useController({ articlePublicationIds, sourcePublicationId, onClose, relatedTableState });
  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field>
            <CustomSelect
              isRequired
              title="Коллективная монография"
              isStatusText
              original={sourceMonograph}
              relationTableModalTitle="Монографии"
              onChange={changeSourceMonograph}
              modalTableRowConverter={modalTableRowToSourceMonographConventer}
              modalTableSpecification={sourceMonographTableConfig}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Продолжить" onClick={handleSubmit} isDisabled={sourceMonograph === null} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
});
