import React, { memo, useCallback } from 'react';

import {
  CustomSelect,
  Reference,
  Button,
  ButtonMode,
  FormComponent,
  SectionTitle,
  TextArea,
  TextInput,
  TextInputMode,
} from 'components';

import { Table as T, Form as F } from 'types/models';
import { GetMonographListSpecification } from 'features/Table/specifications';

type Props = {
  formFields: F.Fields;
  onCreateBibliographicRecordClick: () => void;
  workMode: T.WorkMode;
  changeSourceCompilation: (original: F.Compilation | null) => void;
  onDeleteSourceCompilation: () => void;
  sourceMonograph: F.Compilation | null;
  relatedTableState: T.State | undefined;
  isDisableSource: boolean;
  sourcePublicationDate: string;
  isElectronic: boolean;
  publicationId: string;
};

const Source = ({
  isElectronic,
  publicationId,
  workMode,
  formFields,
  onCreateBibliographicRecordClick,
  changeSourceCompilation,
  onDeleteSourceCompilation,
  sourceMonograph,
  relatedTableState,
  isDisableSource,
  sourcePublicationDate,
}: Props) => {
  const modalTableRowToSourceCompilationConventer = useCallback<(row: T.Entry) => F.Compilation>(
    row => ({
      id: row.id,
      name: row.name,
      bibliographic: row.bibliographicRecord,
      status: `${row.status}. Год выпуска ${row.year ? row.year : 'не указан'}`,
      typeEdition: row['id:typeEdition'],
    }),
    [],
  );
  // GetSimpleMagazineListSpecification
  const sourceMonographTableConfig = GetMonographListSpecification({
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedTableState,
        relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
      },
    },
    isElectronic,
    monographChapterIds: publicationId,
  });

  return (
    <form>
      <input type="submit" hidden />
      <SectionTitle title="Издание" />
      <FormComponent.Line>
        <CustomSelect
          disabled={workMode === 'viewMode' || isDisableSource}
          isRequired
          isStatusText
          title="Коллективная монография"
          original={sourceMonograph}
          relationTableModalTitle="Монографии"
          onDeleteFunction={onDeleteSourceCompilation}
          onChange={changeSourceCompilation}
          modalTableRowConverter={modalTableRowToSourceCompilationConventer}
          modalTableSpecification={sourceMonographTableConfig}
          IsShowBibliographic
        />
      </FormComponent.Line>
      {!!sourcePublicationDate && (
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Дата публикации">
            <span>{sourcePublicationDate}</span>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <Reference
          // disabled
          value={formFields.textLanguage.value}
          isRequired
          theme="big"
          title="Язык текста"
          relationTableModalTitle="Язык"
          refButtonTitle="Язык"
          name="RefLanguage"
          onChange={formFields.textLanguage.onChange}
          isDisabledDelete
          disabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>
      <FormComponent.Line hasGap hasFreeFormat>
        <FormComponent.Field label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.number}
            value={formFields.printPageCount.value}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageFrom.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={formFields.pageFrom.value}
            onChange={formFields.pageFrom.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageTo.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={formFields.pageTo.value}
            onChange={formFields.pageTo.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.chapterNumber.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={formFields.chapterNumber.value}
            onChange={formFields.chapterNumber.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />
      <FormComponent.Line hasGap hasFreeFormat growIndexes={[2, 0]}>
        <FormComponent.Field>
          <TextArea
            settings={{ rows: 3 }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
        <Button
          mode={ButtonMode.secondary}
          text="Построить"
          onClick={onCreateBibliographicRecordClick}
          isDisabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>

      <SectionTitle title="Классификаторы" />
      <FormComponent.Line>
        <Reference
          disabled={workMode === 'viewMode'}
          value={formFields.grnti.value}
          title={formFields.grnti.title}
          relationTableModalTitle="ГРНТИ"
          tooltipText={formFields.grnti.tooltipText}
          refButtonTitle="ГРНТИ"
          theme="big"
          name="RefGrnti"
          onChange={formFields.grnti.onChange}
        />
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
          <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <Reference
          tooltipText={formFields.study.tooltipText}
          disabled={workMode === 'viewMode'}
          value={formFields.study.value}
          title={formFields.study.title}
          relationTableModalTitle={formFields.study.title}
          theme="big"
          refButtonTitle="Область науки"
          name="RefStudy"
          onChange={formFields.study.onChange}
        />
      </FormComponent.Line>
    </form>
  );
};

export const SourceComponent = memo(Source);
