import React from 'react';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal title="Стажировки" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Таблица содержит список сотрудников, аспирантов и студентов университета, прошедших стажировку в организации.
              Источник данных — функциональный модуль, <strong>Заявки, Заявки по академической мобильности</strong>.
            </p>
            <p>Условие для отображения данных в таблице является выбор организации:</p>
            <ul>
              <li>
                На форме редактирования Заявки <strong>по академической мобильности</strong>, на вкладке Заявка, в разделе&nbsp;
                <strong>Место проведения мероприятия</strong> в поле <strong>Принимающая сторона</strong>.<br />
                <br />
              </li>
            </ul>
            <p>
              <strong className="state-danger">ВАЖНО!</strong> В случае, если для организации указаны организации на
              вкладках&nbsp;
              <strong>Связи с другими организациями</strong> и <strong>Варианты наименования</strong>, то в списке будут
              отображаться записи связанных организаций. В столбце <strong>Организация</strong> отображается оригинальное название
              организации. В столбце <strong>Роль</strong> отображается роль организации в проекте.
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
