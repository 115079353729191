import React from 'react';

import { FormComponent, TextArea, TextAreaMode, TextDateTime, TextInput, UploadFile } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectStateRegistrationReportForm({ viewMode, editMode, onClose }: Props) {
  const {
    handleCitisDateChange,
    handleExpectedResultsChange,
    handleFileChange,
    handleInterdisciplinaryAproachJustificationChange,
    handleKeyWordsChange,
    handleReferatChange,
    handleReportArchiveNumberChange,
    handleReportCustomerSendDateChange,
    handleReportIkNumberChange,
    handleReportNameChange,
    handleStageNameChange,
    onSubmit,
    stateRegistrationReport,
  } = useController({ viewMode, editMode, onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <FormComponent.Field label="Этап">
              <div>{`Этап № ${stateRegistrationReport?.stage?.number ?? '-'} (${
                stateRegistrationReport?.stage?.startDate ?? '-'
              } - ${stateRegistrationReport?.stage?.endDate ?? '-'})`}</div>
            </FormComponent.Field>
            <FormComponent.Field
              label="Название этапа"
              tooltip="Название этапа должно соответствовать названию этапа в заявке на проект. Объем до 2000 знаков"
            >
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Название этапа' }}
                value={stateRegistrationReport?.stageName ?? ''}
                onChange={handleStageNameChange}
              />
            </FormComponent.Field>
            <FormComponent.Field
              label="Полученные результаты и их применимость"
              // eslint-disable-next-line max-len
              tooltip="В краткой свободной форме дается описание нового материала, устройства, продукта и других результатов проекта в рамках данного этапа, а также возможного метода использования результатов проекта. Объем до 5000 знаков"
            >
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Полученные результаты и их применимость' }}
                value={stateRegistrationReport?.expectedResults ?? ''}
                onChange={handleExpectedResultsChange}
              />
            </FormComponent.Field>
            <FormComponent.Field
              label="Обоснование междисциплинарного подхода"
              // eslint-disable-next-line max-len
              tooltip="Данное поле заполняется только при наличии указания разных тематических рубрик первого уровня (ГРНТИ и Кодов Международной Классификации). В краткой свободной форме обосновывается междисциплинарный характер работы, относящейся к разным тематическим уровням. Объем до 3000 заков"
            >
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Обоснование междисциплинарного подхода' }}
                value={stateRegistrationReport?.interdisciplinaryAproachJustification ?? ''}
                onChange={handleInterdisciplinaryAproachJustificationChange}
              />
            </FormComponent.Field>
          </FormComponent.Column>
          <FormComponent.Column>
            <FormComponent.Field label="Тип отчета">
              <div>{stateRegistrationReport?.reportType?.label ?? ''}</div>
            </FormComponent.Field>
            <FormComponent.Field label="УДК">
              <div>
                {stateRegistrationReport.udks
                  .map(x => x.label)
                  .filter(x => x)
                  .join(', ')}
              </div>
            </FormComponent.Field>
            <FormComponent.Field label="Название отчета">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 4, title: 'Название отчета' }}
                value={stateRegistrationReport?.reportName ?? ''}
                onChange={handleReportNameChange}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Реферат">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 4, title: 'Реферат' }}
                value={stateRegistrationReport?.referat ?? ''}
                onChange={handleReferatChange}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Ключевые слова">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 4, title: 'Ключевые слова' }}
                value={stateRegistrationReport?.keyWords ?? ''}
                onChange={handleKeyWordsChange}
              />
            </FormComponent.Field>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label="Номер отчета (ИК)">
                <TextInput value={stateRegistrationReport?.reportIkNumber ?? ''} onChange={handleReportIkNumberChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label="Инвентарный номер отчета в архиве">
                <TextInput
                  value={stateRegistrationReport?.reportArchiveNumber ?? ''}
                  onChange={handleReportArchiveNumberChange}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Field label="Дата сдачи отчета в ЦИТиС" isRequired>
              <TextDateTime value={stateRegistrationReport?.citisDate} onChange={handleCitisDateChange} />
            </FormComponent.Field>
            <FormComponent.Field label="Отправлен заказчику">
              <TextDateTime
                value={stateRegistrationReport?.reportCustomerSendDate}
                onChange={handleReportCustomerSendDateChange}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Файл отчета">
              <UploadFile file={stateRegistrationReport?.file} onChange={handleFileChange} />
            </FormComponent.Field>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectStateRegistrationReportForm);
