import React, { useCallback } from 'react';

import { Radio, Reference, SectionTitle, RefList, FormComponent, TextArea, TextAreaMode } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import {
  getHeadName,
  mergeStageTasks,
  mergeExpectedResult,
  getResponsiblePerformerName,
} from 'features/Form/looks/nirRequest/NirRequestForm/helpers';
import { Stages } from './Stages/Stages';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  setNirRequest(nirRequest: NirRequest.NirRequest): void;
  disabled: boolean;
};

export function Request(props: Props) {
  const { nirRequest, makeChangeHandler, setNirRequest, disabled } = props;

  const manager = getHeadName(nirRequest);
  const responsiblePerformer = getResponsiblePerformerName(nirRequest);

  const handleSetStages = useCallback(
    (stages: NirRequest.Stage[]) => {
      if (nirRequest) {
        const expectedResults = nirRequest?.expectedResults ?? [];
        const stageTasks = nirRequest?.stageTasks ?? [];
        const mergedExpectedResult = mergeExpectedResult(stages, expectedResults);
        const mergedStageTasks = mergeStageTasks(stages, stageTasks);
        setNirRequest({ ...nirRequest, stages, stageTasks: mergedStageTasks, expectedResults: mergedExpectedResult });
      }
    },
    [nirRequest, setNirRequest],
  );

  const { settings } = useAppDataContext();

  /* eslint-disable max-len */
  const mapPlaceholder = {
    pnrJustification: `Обоснование соответствия тематики проекта приоритетному направлению стратегического проекта Программы развития ${settings?.organization?.shortName}. Не более 1000 знаков с пробелами`,
    interdisciplinaryAproachJustification:
      'Заполняется в случае указания разных тематических рубрик первого уровня ГРНТИ/OECD. Не более 3000 знаков с пробелами',
  };
  /* eslint-enable max-len */

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Программа">
              <span>{nirRequest?.tender?.program?.name ?? ''}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Мероприятие">
              <span>{nirRequest?.tender?.programEvent?.name ?? ''}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Заказчики">
              <span>
                {nirRequest?.tender?.program?.customers.map(x => x.refCustomer?.customFields?.fullName ?? '').join(', ') ?? ''}
              </span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field isRequired label="Название проекта">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Название проекта' }}
                value={nirRequest?.name ?? ''}
                onChange={makeChangeHandler('name')}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Характер исследования">
              <Radio
                isDisabled={disabled}
                value={nirRequest?.specification ?? ''}
                list={[
                  { label: 'Фундаментальное', value: 'BASIC_RESEARCH' },
                  { label: 'Поисковое', value: 'EXPLORATORY_RESEARCH' },
                  { label: 'Прикладное', value: 'APPLIED_RESEARCH' },
                ]}
                onChange={makeChangeHandler('specification')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Руководитель" isRequired>
              <span>{manager ?? 'Данные о руководителе проекта заполните на вкладке "Коллектив"'}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Ответственный исполнитель">
              <span>{responsiblePerformer ?? 'Данные о ответственном исполнителе проекта заполните на вкладке "Коллектив"'}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field>
              <Reference
                name="RefDepartment"
                relationTableModalTitle='Справочник "Подразделения"'
                value={nirRequest?.department ?? null}
                onChange={makeChangeHandler('department')}
                disabled={disabled}
                title="Лаборатория, в которой выполняется НИР"
                theme="big"
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <Stages disabled={disabled} stages={nirRequest?.stages ?? []} setStages={handleSetStages} />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="Стратегии научно-технического развития РФ"
              modalTitle='Справочник "Стратегии научно-технического развития РФ"'
              rows={nirRequest?.ntrStrategies ?? []}
              onChange={makeChangeHandler('ntrStrategies')}
              refName="RefNtrStrategy"
              disabled={disabled}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="Критические технологии"
              modalTitle='Справочник "Критические технологии"'
              rows={nirRequest?.criticalTechnologies ?? []}
              onChange={makeChangeHandler('criticalTechnologies')}
              refName="RefPriorityTechnology"
              disabled={disabled}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <RefList
              title="ГРНТИ"
              modalTitle='Справочник "ГРНТИ"'
              rows={nirRequest?.grntis ?? []}
              onChange={makeChangeHandler('grntis')}
              refName="RefGrnti"
              disabled={disabled}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="УДК"
              modalTitle='Справочник "УДК"'
              rows={nirRequest?.udks ?? []}
              onChange={makeChangeHandler('udks')}
              refName="RefUdk"
              disabled={disabled}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="OECD (Коды международной классификации отраслей наук)"
              modalTitle='Справочник "Коды международной классификации отраслей наук"'
              rows={nirRequest?.oecds ?? []}
              onChange={makeChangeHandler('oecds')}
              refName="RefOecd"
              disabled={disabled}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <SectionTitle
            title="Обоснование междисциплинарного подхода"
            tooltip={mapPlaceholder.interdisciplinaryAproachJustification}
          />
          <FormComponent.Line>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Обоснование междисциплинарного подхода' }}
              value={nirRequest?.interdisciplinaryAproachJustification ?? ''}
              onChange={makeChangeHandler('interdisciplinaryAproachJustification')}
              isDisabled={!!disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title={`Приоритетные направления Программы развития ${settings?.organization?.shortName}`}
              modalTitle={`Справочник "ПНР ${settings?.organization?.shortName}"`}
              rows={nirRequest?.pnrs ?? []}
              onChange={makeChangeHandler('pnrs')}
              refName="RefPnr"
              disabled={disabled}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
          <SectionTitle
            // eslint-disable-next-line max-len
            title={`Обоснование соответствия тематики проекта приоритетному направлению Программы развития ${settings?.organization?.shortName}`}
            tooltip={mapPlaceholder.pnrJustification}
          />
          <FormComponent.Line>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: mapPlaceholder.pnrJustification }}
              value={nirRequest?.pnrJustification ?? ''}
              onChange={makeChangeHandler('pnrJustification')}
              isDisabled={!!disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              title="Приоритетные направления развития науки, технологий и техники"
              modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
              rows={nirRequest?.pnis ?? []}
              onChange={makeChangeHandler('pnis')}
              refName="RefPni"
              disabled={disabled}
              visibleToolbarButtons={['add', 'delete']}
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
