import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { EditRelationFields } from '../types';

type ValidateArguments = {
  type: LinkedHistoryType;
} & EditRelationFields;

export const validate = ({ entityToReplace, isNeedDeleteRelation, type }: ValidateArguments) => {
  let entityTypePostfix = '';

  switch (type) {
    case LinkedHistoryType.JOB:
      entityTypePostfix = 'работу на замену';
      break;
    case LinkedHistoryType.EDUCATION:
      entityTypePostfix = 'обучение на замену';
      break;
    case LinkedHistoryType.DEGREE:
      entityTypePostfix = 'ученая степень на замену';
      break;
    case LinkedHistoryType.RANK:
      entityTypePostfix = 'ученое звание на замену';
      break;
    case LinkedHistoryType.ACADEMIC_RANK:
      entityTypePostfix = 'академическое звание на замену';
      break;
    default:
      entityTypePostfix = 'сущность на замену';
      break;
  }

  return [{ isValid: isNeedDeleteRelation || Boolean(entityToReplace?.value), invalidMessage: `Заполните ${entityTypePostfix}` }];
};
