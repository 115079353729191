import { Table, Community } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  communityTypes: Community.CommunityType[];
  title?: string;
  enumFilterValues?: Table.EnumFilterValues;
  hasSelectButton?: boolean;
  isLocal?: boolean;
  isMembersTabHidden?: boolean;
};

export function GetDissertationCouncilList({
  communityTypes,
  title,
  enumFilterValues,
  hasSelectButton,
  isLocal,
  isMembersTabHidden,
}: Props): Table.Specification<State> {
  return {
    apiID: 'GetDissertationCouncilList',
    header: {
      firstLevel: {
        title,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton,
      },
    },
    requestData: { communityTypes, isLocal },
    useCustomController: makeUseCustomController(communityTypes, isMembersTabHidden),
    enumFilterValues,
    isWithQna: true,
  };
}
