import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { CreateContestRequestFormLook } from 'types/models/Form/look/createContestRequest';
import { RequestTypes } from 'utils/Enums/RequestTypes';
import { Modal } from 'components';
import { ContestRequestType } from 'utils/Enums';

type ContestRequestAddTemplateProps = {
  projectId: string;
  requestType: ContestRequestType;
} & F.TemplateProps;

const ContestRequestAddTemplate: React.FC<ContestRequestAddTemplateProps> = ({
  isOpen,
  onClose,
  projectId,
  relatedTableState,
  requestType,
}: ContestRequestAddTemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: CreateContestRequestFormLook = {
    apiID: 'GetContestRequest',
    template: RequestTypes.CONTEST_REQUEST.template,
    projectId: String(projectId),
    relatedTableState,
    setTitle,
    requestType,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ContestRequestAddTemplate);
