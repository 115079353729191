import React, { useCallback } from 'react';

import { Collapse } from 'components';

import { AddNewGridSettingData, streams as panelStreams } from '../streams';
import { State } from '../makeUseCustomController';
import { Form } from './Form';
import { SecondLevelProps } from 'features/Table/types';

import './styles.scss';

export const ComponentForSecondLevel = ({ customState }: SecondLevelProps<State>) => {
  const { isChangeFormOpen, selectedRow, isSettingEditing, relatedColumns } = customState;

  const resetComponentStateCallback = useCallback(() => {
    panelStreams.toggleSettingEditing.push(false);
  }, []);

  const submitCallback = useCallback((addNewGridSettingArguments: AddNewGridSettingData) => {
    panelStreams.addNewGridSetting.push(addNewGridSettingArguments);
    panelStreams.toggleChangeForm.push();
  }, []);

  return (
    <Collapse isCollapse={isChangeFormOpen}>
      <Form
        setting={
          selectedRow && {
            author: selectedRow.author ?? '',
            id: selectedRow.id ?? '',
            isShared: selectedRow.isShared === 'true',
            name: selectedRow.name ?? '',
            purpose: selectedRow.purpose ?? '',
          }
        }
        isSettingEditing={isSettingEditing}
        relatedColumns={relatedColumns}
        submitCallback={submitCallback}
        resetComponentStateCallback={resetComponentStateCallback}
      />
    </Collapse>
  );
};
