import React from 'react';

import { UploadImage, FormComponent, SectionTitle, PreviewHTML } from 'components';
import { formatLotPrices } from 'features/Form/looks/programRequest/ProgramRequestForm/helpers';
import { formatNumber } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
import { Tender } from 'types/models';

type Props = {
  tender: Tender.Tender | null;
  lot: Tender.Lot | null;
};

export function AboutTender(props: Props) {
  const { tender, lot } = props;
  const { settings } = useAppDataContext();

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column specialStyles={{ width: '60%' }}>
          <FormComponent.Line>
            <FormComponent.ColumnWrapper>
              <FormComponent.Column specialStyles={{ width: 'calc(100% - 250px)' }} hasNoWrap>
                <FormComponent.Line>
                  <FormComponent.Field label="Программа">
                    <>{tender?.program?.name}</>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Мероприятие программы">
                    <>{tender?.programEvent?.code}</>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Конкурс (тема лота)">
                    <>{lot?.theme || tender?.name ? `${tender?.name} (ID конкурса: ${tender?.id})` : '' || ''}</>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Описание конкурса">
                    <PreviewHTML value={tender?.description || ''} modalTitle="Полное описание" />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column specialStyles={{ width: '250px', height: '110px' }} hasNoWrap>
                <UploadImage text="Логотип программы" id={tender?.logo?.id || tender?.program?.logo?.id} viewMode />
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Участники">
              <>{tender?.participants?.map(p => p.label).join(', ')}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Категория конкурса">
              <>{tender?.categories?.map(c => c.label).join(', ')}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Области научных интересов">
              <>{tender?.scienceDomainInterrests?.map(s => s.label).join(', ')}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Шифр лота">
              <>{lot?.code}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Количество контрактов в лоте">
              <>{lot?.contractCount}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Цена контракта">
              <>{lot?.contractAmount}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Календарный план">
              <>{formatLotPrices(lot?.prices?.sort((a, b) => parseInt(a.year, 10) - parseInt(b.year, 10)) || [])}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Заказчики">
              <>
                {tender?.customers
                  ?.map(({ fullName }) => fullName || '')
                  .filter(x => x)
                  .join(', ')}
              </>
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: '40%' }}>
          <FormComponent.Line>
            <FormComponent.Field label="Подать заявку на согласование до">
              <>{tender?.dateUniversity}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата/время окончания приема заявок">
              <>
                {tender?.dateOpenEnvelope} {tender?.timeOpenEnvelope}
              </>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата рассмотрения заявок">
              <>{tender?.dateExamenation}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата подведения итогов">
              <>{tender?.dateSummingUp}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Обеспечение заявки">
              <>{formatNumber(lot?.requestSupportAmount || '0.0', 2)}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Обеспечение контракта">
              <>{formatNumber(lot?.contractSupportAmount || '0.0', 2)}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Обеспечение гарантийных обязательств">
              <>{formatNumber(lot?.warrantySupportAmount || '0.0', 2)}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Электронная торговая площадка">
              <>{tender?.marketplace?.label}</>
            </FormComponent.Field>
          </FormComponent.Line>
          <SectionTitle title={`Консультации и помощь в ${settings?.organization?.shortName}`} />
          <div>
            {(tender?.contacts || []).map(x => {
              return (
                <FormComponent.Field key={x.id}>
                  <FormComponent.ColumnWrapper>
                    <FormComponent.Column specialStyles={{ width: '70%' }} hasNoWrap>
                      <h3>{x.fullName}</h3>
                      <FormComponent.Field label="Электронная почта">{x.scientist?.email || ''}</FormComponent.Field>
                      <FormComponent.Field label="Телефон">
                        {[x.scientist?.phone, x.scientist?.mobilePhone].filter(y => y).join('; ')}
                      </FormComponent.Field>
                    </FormComponent.Column>
                    <FormComponent.Column specialStyles={{ width: '30%', height: '110px' }} hasNoWrap>
                      <UploadImage text="Фото пользователя" id={x.avatar?.id} viewMode />
                    </FormComponent.Column>
                  </FormComponent.ColumnWrapper>
                </FormComponent.Field>
              );
            })}
          </div>
          {tender?.contactFace ? (
            <div>
              <b>Очные консультации по адресу:</b> {tender?.contactFace || ''}
            </div>
          ) : (
            <></>
          )}
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
