import React, { memo } from 'react';
import { block } from 'bem-cn';
import { renderToString } from 'react-dom/server';

import { ConfirmPopup, ListEditTable, Toolbar } from 'components';

import { useController } from './controller';

const b = block('left-panel-for-third-level');

function LeftPanelForThirdLevel() {
  const { buttons, isHelpFormOpen, handleTemplateCloseHelpForm } = useController();

  return (
    <div className={b()}>
      <ConfirmPopup
        title='Помощь в работе с разделом "Список участников заявок на конкурсы"'
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Функциональный блок <strong>Список участников заявок на конкурсы</strong> содержит список участников заявок и
            предназначен для контроля количества участий одного физлица в нескольких заявках. Для этого выделять в составе
            коллектива тех людей, которые участвуют в нескольких заявках и указывать количество заявок.
          </p>
          <p>
            Пункт меню <strong>Заявки &rarr; Список участников заявок на конкурсы</strong> содержит в табличном виде информацию
            обо всех сотрудниках, которые принимали участие в заявках конкурсов. Информация в столбцах динамически меняется из
            регистрированных в системе заявок, а также при смене статуса заявки:
          </p>
          <p>
            <strong>Правила отображения данных в столбцах таблицы</strong>
          </p>
          <ListEditTable
            rows={[
              { column: 'ID', purpose: 'ID персоны' },
              { column: 'ФИО', purpose: 'Фамилия Имя Отчество участника' },
              {
                column: 'Всего заявок, шт.',
                purpose: 'Общее кол-во заявок = Заявка на конкурс + Заявка на внутренний конкурс + Заявка на мобильность',
              },
              {
                column: 'Поддержана, шт.',
                purpose: renderToString(
                  <>
                    Количество заявок в статусе <strong>Поддержана</strong>
                  </>,
                ),
              },
              {
                column: 'Отклонена, шт.',
                purpose: renderToString(
                  <>
                    Количество заявок в статусе <strong>Отклонена</strong>
                  </>,
                ),
              },
              {
                column: 'Оформляются, шт.',
                purpose: renderToString(
                  <>
                    Количество заявок в статусах, кроме <strong>Поддержана</strong> и <strong>Отклонена</strong>
                  </>,
                ),
              },
              {
                column: 'ID_Оформляются',
                purpose: renderToString(
                  <>
                    Заявки в статусах, кроме <strong>Поддержана</strong> и <strong>Отклонена</strong>, выводить в формате Тип
                    заявки ID:&lt;ID&gt;-&lt;роль в заявке&gt;
                  </>,
                ),
              },
              {
                column: 'ID_Поддержаные',
                purpose: 'Заявки в статусе Поддержана, выводить в формате Тип заявки ID:<ID>-<роль в заявке>',
              },
              { column: 'Проекты', purpose: 'Выводить в формате Тип проекта ID:<ID>-<роль в проекте>' },
            ]}
            columns={[
              {
                label: `Столбец`,
                formatValue: row => row.column || '',
                styles: { width: '20%' },
              },
              { label: 'Назначение', formatValue: row => row.purpose || '', styles: { width: '80%' } },
            ]}
            selectedRowIndex={null}
            selectRow={() => {}}
          />
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </div>
  );
}

export const Component = memo(LeftPanelForThirdLevel);
