import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, CustomSelect } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  articlePublicationIds: string[];
  onClose: () => void;
  sourcePublicationId: string;
  relatedTableState?: Table.State;
};

export const Component = memo(({ onClose, articlePublicationIds, sourcePublicationId, relatedTableState }: Props) => {
  const {
    sourceMagazine,
    sourceMagazineTableConfig,
    sourceMagazineRelease,
    sourceMagazineReleaseTableConfig,
    modalTableRowToSourceMagazineReleaseConventer,
    changeSourceMagazineRelease,
    handleSubmit,
    changeSourceMagazine,
    modalTableRowToSourceMagazineConventer,
  } = useController({ articlePublicationIds, sourcePublicationId, onClose, relatedTableState });
  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field>
            <CustomSelect
              isRequired
              title="Название журнала"
              isStatusText
              original={sourceMagazine}
              relationTableModalTitle="Журналы"
              onChange={changeSourceMagazine}
              modalTableRowConverter={modalTableRowToSourceMagazineConventer}
              modalTableSpecification={sourceMagazineTableConfig}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field>
            <CustomSelect
              disabled={!sourceMagazine}
              isStatusText
              title="Выпуск журнала"
              original={sourceMagazineRelease}
              relationTableModalTitle="Выпуск журнала"
              onChange={changeSourceMagazineRelease}
              modalTableRowConverter={modalTableRowToSourceMagazineReleaseConventer}
              modalTableSpecification={sourceMagazineReleaseTableConfig}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button
            mode={ButtonMode.primary}
            text="Продолжить"
            onClick={handleSubmit}
            isDisabled={sourceMagazine === null || sourceMagazineRelease === null}
          />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
});
