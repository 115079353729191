import React from 'react';

import { Modal } from 'components';
import { Props as ComponentProps, Component } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: () => void;
} & ComponentProps;

export const Container = ({ entityIds, isOpen, onClose, entity, onUpdate }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Объединение дубликатов" size="full">
    <Component entity={entity} entityIds={entityIds} onClose={onClose} onUpdate={onUpdate} />
  </Modal>
);
