import { Table as T, RefFeedlineCommand } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export function GetEventFeedlineList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
  command?: RefFeedlineCommand,
): T.Specification<State> {
  const toolbarEnabled: boolean = workMode !== 'addMode';
  return {
    apiID: 'GetEventFeedlineList',
    useCustomController: makeUseCustomController(templatesTableDependencies, command),
    templatesTableDependencies,
    workMode,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: toolbarEnabled ? LeftPanelForThirdLevel : undefined,
      },
    },
  };
}
