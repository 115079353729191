import { useCallback, useMemo } from 'react';

import { FileInfo, MipPartner } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getPercentOfAmountByPart } from 'features/EditMipPartner/helpers';
import { getMockScientist } from 'features/Form/looks/person/helpers';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  founder: MipPartner.Founder;
  setFounder: (founder: MipPartner.Founder) => void;
  amount: string;
};

const useController = ({ founder, setFounder, amount }: Props) => {
  const makeHandler = useCallback(
    (key: keyof MipPartner.Founder) => (value: ValueOf<MipPartner.Founder>) => {
      setFounder({
        ...founder,
        [key]: value,
      });
    },
    [founder, setFounder],
  );

  const onChangeFile = useCallback(
    (fileInfo: FileInfo) => {
      makeHandler('file')(fileInfo);
    },
    [makeHandler],
  );

  const amountPercent = useMemo(() => getPercentOfAmountByPart({ part: founder.amount, amount }), [amount, founder.amount]);

  const { initialCountryCode } = useAppDataContext();

  const onIsScientistChange = useCallback(
    (nextIsScientist: string) => {
      const preparedNextIsScientist = nextIsScientist === 'true';

      if (preparedNextIsScientist) {
        setFounder({
          ...founder,
          enterpriseCustomer: null,
          scientist: getMockScientist({ initialCountryCode }),
        });
      } else {
        setFounder({
          ...founder,
          passport: '',
          scientist: null,
        });
      }
    },
    [founder, setFounder, initialCountryCode],
  );

  const isScientist = useMemo(() => {
    return founder.scientist !== null && founder.enterpriseCustomer === null;
  }, [founder]);

  return {
    isScientist,
    amountPercent,
    onChangeFile,
    onIsScientistChange,
    makeHandler,
  };
};

export default useController;
