import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput, getId } from 'services/BackendAPI/configurations/commonConverters';
import {
  convertEstimate,
  convertEstimatePosition,
  convertKindConsumption,
} from 'services/BackendAPI/configurations/estimates/converters';
import {
  convertBalanceEstimatePositionToXML,
  convertEstimateInputToXML,
  convertEstimatePositionToXML,
  convertEstimateToXML,
} from 'services/BackendAPI/configurations/estimates/requestConverters';
import { EstimateCreate, EstimatePositionForBalance, EstimatePositionInput, EstimateUpdate } from 'types/models/Estimate';
import { Settings } from 'types/models/common';

export const estimateConfigurations = {
  CreateEstimate: makeBackendAPIConfiguration({
    id: 'CreateEstimate',
    endpoint: '/msa/service/commands/SaveEstimate',
    convertInputToXMLElement: (inputData: EstimateCreate) => ({
      Request: {
        _attr: {
          commandName: 'SaveEstimate',
        },
        Estimate: {
          ...convertEstimateInputToXML(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  SaveEstimate: makeBackendAPIConfiguration({
    id: 'SaveEstimate',
    endpoint: '/msa/service/commands/SaveEstimate',
    convertInputToXMLElement: (inputData: EstimateUpdate) => ({
      Request: {
        _attr: {
          commandName: 'SaveEstimate',
        },
        Estimate: {
          ...convertEstimateToXML(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ChangeEstimateStatus: makeBackendAPIConfiguration({
    id: 'ChangeEstimateStatus',
    endpoint: '/msa/service/commands/ChangeEstimateStatus',
    convertInputToXMLElement: (inputData: { estimateId: string; estimateStatus: string }) => ({
      Request: {
        _attr: {
          commandName: 'ChangeEstimateStatus',
        },
        Estimate: {
          _attr: { id: inputData.estimateId, status: inputData.estimateStatus },
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetEstimate: makeBackendAPIConfiguration({
    id: 'GetEstimate',
    endpoint: '/msa/service/commands/GetEstimate',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetEstimate',
          },
          Estimate: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings, isTSU?: boolean) =>
      convertEstimate(response.Response.Estimate, settings, isTSU),
  }),
  SaveEstimatePosition: makeBackendAPIConfiguration({
    id: 'SaveEstimatePosition',
    endpoint: '/msa/service/commands/SaveEstimatePosition',
    convertInputToXMLElement: (inputData: EstimatePositionInput) => ({
      Request: {
        _attr: {
          commandName: 'SaveEstimatePosition',
        },
        EstimatePosition: { ...convertEstimatePositionToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetEstimatePosition: makeBackendAPIConfiguration({
    id: 'GetEstimatePosition',
    endpoint: '/msa/service/commands/GetEstimatePosition',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetEstimatePosition',
          },
          EstimatePosition: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertEstimatePosition(response.Response.EstimatePosition),
  }),
  GetKindConsumption: makeBackendAPIConfiguration({
    id: 'GetKindConsumption',
    endpoint: '/msa/service/commands/GetKindConsumption',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetKindConsumption',
          },
          KindConsumption: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertKindConsumption(response.Response.KindConsumption),
  }),
  GetBalanceEstimatePosition: makeBackendAPIConfiguration({
    id: 'BalanceEstimatePosition',
    endpoint: '/msa/service/commands/BalanceEstimatePosition',
    convertInputToXMLElement: (inputData: { ids: EstimatePositionForBalance[] }) => {
      const { ids } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'BalanceEstimatePosition',
          },
          ...convertBalanceEstimatePositionToXML(ids),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  CreateEstimateByContestRequest: makeBackendAPIConfiguration({
    id: 'CreateEstimateByContestRequest',
    endpoint: '/msa/service/commands/CreateEstimateByContestRequest',
    convertInputToXMLElement: (inputData: { projectCodeId: string; contestRequestId: string }) => {
      const { projectCodeId, contestRequestId } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'CreateEstimateByContestRequest',
          },
          ContestRequest: { _attr: { id: contestRequestId } },
          ProjectCode: { _attr: { id: projectCodeId } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings, isTSU?: boolean) =>
      convertEstimate(response.Response.Estimate, settings, isTSU),
  }),

  CreateEstimateByProjectCode: makeBackendAPIConfiguration({
    id: 'CreateEstimateByProjectCode',
    endpoint: '/msa/service/commands/CreateEstimateByProjectCode',
    convertInputToXMLElement: ({
      year,
      fundCardId,
      kindConsumptionId,
      createdDate,
      projectCodeIds,
    }: {
      year: string;
      fundCardId: string;
      kindConsumptionId: string;
      createdDate: string;
      projectCodeIds: string[];
    }) => {
      return {
        Request: {
          _attr: {
            commandName: 'CreateEstimateByProjectCode',
          },
          Year: year,
          FundCard: { _attr: { id: fundCardId } },
          KindConsumption: { _attr: { id: kindConsumptionId } },
          CreatedDate: createdDate,
          ProjectCodes: { ProjectCode: projectCodeIds.map(x => ({ _attr: { id: x } })) },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getId(response.Response.Estimate),
  }),
};
