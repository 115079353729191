import React from 'react';

import { CustomSelect, FormComponent, InputSelect, ListEdit, SectionTitle, TextArea, TextAreaMode, TextInput } from 'components';

import { Project } from 'types/models';
import { useController } from './controller';
import { GetCommunityMemberList, GetReferenceElementList } from 'features/Table/specifications';
import { formatNumber } from 'utils/Helpers';

type Props = {
  project: Project.Project | null;
  act: Project.Act | null;
  onActChange(refs: Project.Act): void;
};

export const ActFields = ({ project, act, onActChange }: Props) => {
  const {
    makeChangeHandler,
    modalTableRowCouncilConverter,
    sourceCouncilTableConfig,
    projectStageOptions,
    defaultBaseProgram,
    projectLeader,
    stageTotal,
    handleMembersChange,
    handleDepartmentsChange,
    modalTableRowDepartmentsConverter,
    handleStageChange,
    сonsiderationDocumentsTooltipTxt,
    councilTooltipTxt,
    membersTooltipTxt,
    conclusionTooltipTxt,
    noteTooltipTxt,
  } = useController({
    project,
    act,
    onActChange,
  });
  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column specialStyles={{ width: '63%' }}>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field isRequired label="Этап проекта">
            <InputSelect
              onSelectChange={handleStageChange}
              options={projectStageOptions}
              value={{ value: act?.stage?.id || null, label: act?.stage?.label ?? '' }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field isRequired label="Номер акта">
            <TextInput
              value={act?.number !== '' ? act?.number : act?.stage?.number ?? ''}
              onChange={val => makeChangeHandler('number')(val)}
              settings={{ maxLength: 50 }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Подразделения">
            <ListEdit
              rows={act?.departments ?? []}
              onChange={val => handleDepartmentsChange(val)}
              columns={[{ label: 'Наименование', formatValue: x => x?.department?.unit?.label ?? '' }]}
              isDeleteConfirmEnabled
              withMessages
              visibleToolbarButtons={['delete']}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "Подразделения"',
                modalTableRowConverter: modalTableRowDepartmentsConverter,
                modalTableSpecification: GetReferenceElementList({
                  requestData: {
                    filters: [],
                    name: 'RefDepartment',
                  },
                }),
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field
            label="Комиссии были представлены на рассмотрение следующие документы"
            tooltip={сonsiderationDocumentsTooltipTxt}
          >
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Комиссии были представлены на рассмотрение следующие документы' }}
              value={act?.сonsiderationDocuments ?? ''}
              onChange={makeChangeHandler('сonsiderationDocuments')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Основание для НИР">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Основание для НИР' }}
              value={act?.defaultBaseProgram ?? defaultBaseProgram}
              onChange={makeChangeHandler('defaultBaseProgram')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field tooltip={councilTooltipTxt}>
            <CustomSelect
              isRequired
              title="Научно-технический совет"
              original={act?.council?.id ? { id: act?.council.id, name: `${act?.council?.code}` } : { id: '', name: '' }}
              relationTableModalTitle="Научно-технические советы"
              onChange={val => {
                makeChangeHandler('council')({
                  id: val?.id ?? '',
                  name: val?.name ?? '',
                  code: val?.name ?? '',
                });
              }}
              modalTableRowConverter={modalTableRowCouncilConverter}
              modalTableSpecification={sourceCouncilTableConfig}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Комиссия" tooltip={membersTooltipTxt}>
            <ListEdit
              rows={act?.members ?? []}
              columns={[
                { label: 'ФИО', formatValue: x => x?.councilMember?.label ?? '' },
                { label: 'Должность', formatValue: x => x?.appointmentLabel || x?.job?.refAppointment?.label || '' },
                { label: 'Ученая степень', formatValue: x => x?.degreeLabel || x?.degree?.refDegree?.label || '' },
                { label: 'Ученое звание', formatValue: x => x?.rankLabel || x?.rank?.refRank?.label || '' },
                { label: 'Роль', formatValue: x => x?.councilMember?.role?.label ?? '' },
              ]}
              onChange={handleMembersChange}
              defaultRowsCount={3}
              isCanMovingRows={true}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Выберите члена комиссии',
                modalTableRowConverter: row => {
                  return {
                    id: null,
                    label: row.Person,
                    councilMember: { id: row.id, label: row.Person, role: { label: row.Role || '', value: '' } },
                    academicRankLabel: row.AcademicRank,
                    citizenship: null,
                    degreeLabel: row.Degree,
                    educationLabel: row.Academy,
                    historyLabel: '',
                    appointmentLabel: row.Appointment,
                    rankLabel: row.Rank,
                    rank: null,
                    academicRank: null,
                    degree: null,
                    education: null,
                    job: null,
                  };
                },
                modalTableSpecification: GetCommunityMemberList({
                  communityId: act?.council?.id || '-1',
                  hasSelectButton: true,
                  isCanSelectOnlyOneRow: false,
                }),
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Заключение комиссии" tooltip={conclusionTooltipTxt}>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 8, title: 'Заключение комиссии' }}
              value={act?.conclusion ?? ''}
              onChange={makeChangeHandler('conclusion')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Дополнительные замечания и предложения" tooltip={noteTooltipTxt}>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Дополнительные замечания и предложения' }}
              value={act?.note ?? ''}
              onChange={makeChangeHandler('note')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column specialStyles={{ width: '40%' }}>
        <SectionTitle title="О проекте" />
        <FormComponent.Line>
          <FormComponent.Field label="Проект">
            {project?.number} {project?.name}
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тема этапа">
            <div>{act?.stage?.label ?? ''}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Характер проекта">
            <div>{project?.specification?.label}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Руководитель">
            <div>{projectLeader?.person?.fullName}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Объём средств на проведение НИР за отчётный период">
            <div>{formatNumber(stageTotal, 2)}</div>
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
};
