import React from 'react';
import { Project } from 'types/models';

import { InputSelect, FormComponent, TextArea, TextAreaMode, TextDateTime, CustomSelect } from 'components';

import { useController } from './controller';

type Props = {
  jobPeriod: Project.JobPeriod;
  setJobPeriod(jobPeriod: Project.JobPeriod): void;
  projectId: string | null;
};

export function Fields(props: Props) {
  const { jobPeriod, setJobPeriod, projectId } = props;

  const {
    makeChangeHandler,
    options,
    performerOrderModalTableRowConverter,
    performerOrderModalTableSpecification,
    handleStartOrderChange,
    handleEndOrderChange,
  } = useController({ jobPeriod, setJobPeriod, projectId });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field isRequired label="Роль">
          <InputSelect
            options={options}
            value={options.find(x => x.value === jobPeriod.role?.value) ?? null}
            onSelectChange={makeChangeHandler('role') as any}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap>
        <FormComponent.Field isRequired label="Дата начала работы">
          <TextDateTime
            value={jobPeriod?.startDate || ''}
            onChange={(value: string) => {
              setJobPeriod({ ...jobPeriod, startDate: value || '' } as Project.JobPeriod);
            }}
          />
        </FormComponent.Field>
        <FormComponent.Field label={'На основании приказа'} labelSize="fit">
          <CustomSelect
            original={
              jobPeriod.startDateOrder
                ? { id: jobPeriod.startDateOrder?.id || '', name: jobPeriod.startDateOrder.number || '' }
                : null
            }
            relationTableModalTitle={'На основании приказа'}
            onChange={value => handleStartOrderChange(value)}
            modalTableRowConverter={performerOrderModalTableRowConverter}
            modalTableSpecification={performerOrderModalTableSpecification}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap>
        <FormComponent.Field isRequired label="Дата окончания работы">
          <TextDateTime
            value={jobPeriod?.endDate || ''}
            onChange={(value: string) => {
              setJobPeriod({ ...jobPeriod, endDate: value || '' } as Project.JobPeriod);
            }}
          />
        </FormComponent.Field>
        <FormComponent.Field label={'На основании приказа'} labelSize="fit">
          <CustomSelect
            original={
              jobPeriod.endDateOrder ? { id: jobPeriod.endDateOrder?.id || '', name: jobPeriod.endDateOrder.number || '' } : null
            }
            relationTableModalTitle={'На основании приказа'}
            onChange={value => handleEndOrderChange(value)}
            modalTableRowConverter={performerOrderModalTableRowConverter}
            modalTableSpecification={performerOrderModalTableSpecification}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Примечание' }}
            value={jobPeriod.note}
            onChange={makeChangeHandler('note')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
