import { useCallback, useLayoutEffect, useState } from 'react';

import { showNotification } from 'features/Notifications';
import * as BackendAPI from 'services/BackendAPI';
import { ValueOf } from 'types/helpers';
import { Form as F } from 'types/models';

import { getMockPurchaseRequest } from './helpers/getMockModels';

import { Project } from 'types/models/Project';
import { PurchaseRequestProject } from 'types/models/PurchaseRequest';
import { useAppDataContext } from 'features/AppData/context';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './helpers/validate';
import { useLocalTableStreams } from 'features/Table/hooks';
import { ProjectScientistRole } from 'utils/Enums';
import { PurchaseRequest as PurchaseRequest1, PurchaseRequest } from 'types/models/PurchaseRequest';

type Arguments = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController(args: Arguments) {
  const { viewMode, editMode, onClose } = args;

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const {
    look: { id, projectId },
  } = useFormContext<F.Look & { projectId: string }>();

  const tableStreams = useLocalTableStreams();

  const { enumMap } = useAppDataContext();

  const [purchaseRequest, setPurchaseRequest] = useState<PurchaseRequest>(getMockPurchaseRequest());

  const [project, setProject] = useState<Project | null>(null);

  const { methods: getPurchaseRequestAPI } = BackendAPI.useBackendAPI('GetPurchaseRequest');
  const { methods: savePurchaseRequestAPI } = BackendAPI.useBackendAPI('SavePurchaseRequest');
  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');

  const makeChangeHandler = (key: keyof PurchaseRequest1) => (val: ValueOf<PurchaseRequest1>) => {
    setPurchaseRequest(prev => ({ ...prev!, [key]: val }));
    if (key === 'project') {
      const rpr = val as PurchaseRequestProject;
      loadProject(rpr.id || '-1');
    }
  };

  const loadProject = useCallback(
    (projId: string) => {
      if (projId) {
        getProjectAPI.callAPI(
          { id: projId },
          {
            onSuccessfullCall: ({ data }) => {
              setProject(data);
              if (!purchaseRequest.applicant) {
                const projectLeader =
                  data?.performers.find(x =>
                    x?.jobPeriods ? x?.jobPeriods?.find(jp => jp.role?.value === ProjectScientistRole.LEADER) : false,
                  ) ?? null;
                if (projectLeader != null) {
                  setPurchaseRequest(prev => ({
                    ...prev!,
                    ['applicant']: { id: projectLeader.id, person: projectLeader.person?.scientist },
                  }));
                }
              }
            },
          },
        );
      }
    },
    [getProjectAPI, purchaseRequest],
  );

  const loadPurchaseRequest = useCallback(
    (requestId: string) => {
      getPurchaseRequestAPI.callAPI(
        { id: requestId },
        {
          onSuccessfullCall: ({ data }) => {
            setPurchaseRequest({
              ...data,
            });
            if (data.project?.id) {
              loadProject(data.project?.id);
            }
          },
        },
      );
    },
    [getPurchaseRequestAPI, loadProject],
  );

  const handleSave = useCallback(
    (needClose?: boolean) => {
      return () => {
        const pr = { ...purchaseRequest, project: project };
        const errors = validate(pr).filter(m => !m.isValid);
        if (errors && errors.length > 0) {
          const message = `${errors.map(({ invalidMessage }) => invalidMessage).join(', ')}`;
          showNotification({ message, theme: 'danger' });
          return false;
        }
        savePurchaseRequestAPI.callAPI(
          { purchaseRequest: pr },
          {
            onSuccessfullCall: ({ data }) => {
              showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
              if (needClose) {
                tableStreams.reloadTable.push();
                onClose();
              } else if (data.id) {
                loadPurchaseRequest(data.id);
                updateWorkModeAfterSaveAndContinue();
              }
            },
          },
        );
      };
    },
    [
      purchaseRequest,
      project,
      tableStreams,
      onClose,
      loadPurchaseRequest,
      savePurchaseRequestAPI,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  useLayoutEffect(() => {
    if (id) {
      loadPurchaseRequest(id);
    }
    if (projectId && projectId !== '-1') {
      loadProject(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    enumMap,
    purchaseRequest,
    setPurchaseRequest,
    makeChangeHandler,
    handleSave,
    workMode,
    project,
  };
}
