import React from 'react';

import { FormComponent, TextInput, Checkbox, CustomSelect, Radio, TextInputMode } from 'components';

import { KindConsumptionRadioOptions, useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { formatNumber } from 'utils/Helpers';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function KindConsumptionForm({ viewMode, editMode, onClose }: Props) {
  const {
    kindConsumption,
    handleFormSubmit,
    makeChangeHandler,
    selectedRadio,
    handleRadioChange,
    kindConsumptionLabel,
    taxSpecification,
    taxTableRowConverter,
    handleTaxChange,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Checkbox
              label="Действующий"
              checked={!!kindConsumption?.isActive}
              onChange={makeChangeHandler('isActive')}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид расхода">{kindConsumption?.name}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Статья расходов">{kindConsumptionLabel}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КВР">{kindConsumption?.kvr?.label}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КОСГУ">{kindConsumption?.kosgu?.label}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="" labelWidth={5}>
            <Radio
              value={selectedRadio}
              list={[
                { label: 'Без дополнений', value: KindConsumptionRadioOptions.empty },
                { label: 'Рассчитать налог', value: KindConsumptionRadioOptions.isCalculateTax },
                {
                  label: 'Передавать накладные',
                  value: KindConsumptionRadioOptions.isSendOverhead,
                },
                {
                  label: 'Расчёты с соисполнителем',
                  value: KindConsumptionRadioOptions.isAccomplicePayment,
                },
              ]}
              onChange={e => handleRadioChange(e as KindConsumptionRadioOptions)}
              isInRow
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {kindConsumption?.isCalculateTax === true && (
          <>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="Процент налога" isRequired>
                <TextInput
                  onChange={makeChangeHandler('percentTax')}
                  value={formatNumber(kindConsumption?.percentTax || '0', 2)}
                  mode={TextInputMode.number}
                  isDisabled={viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Вид расхода, налог">
                <CustomSelect
                  original={
                    kindConsumption.tax
                      ? { id: kindConsumption?.tax?.id, name: `${kindConsumption.tax.code} ${kindConsumption.tax.name}` }
                      : null
                  }
                  relationTableModalTitle="Виды расходов"
                  onChange={handleTaxChange}
                  onDeleteFunction={() => handleTaxChange(null)}
                  modalTableRowConverter={taxTableRowConverter}
                  modalTableSpecification={taxSpecification}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            {kindConsumption?.tax?.id && (
              <>
                <FormComponent.Line>
                  <FormComponent.Field label="КВР">{kindConsumption?.tax?.kvr?.label}</FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="КОСГУ">{kindConsumption?.tax?.kosgu?.label}</FormComponent.Field>
                </FormComponent.Line>
              </>
            )}
          </>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(KindConsumptionForm);
