import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Specification } from 'types/models/Table';
import { Color } from 'constants/colors';

export const GetPublicationExtendedList: Specification = {
  apiID: 'GetPublicationExtendedList',
  header: {
    firstLevel: {
      title: 'Публикации',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
  enumFilterValues: {
    enumName: 'PublicationType',
    excludeValues: [PublicationTypes.MAGAZINE.code],
  },
  highlightEntryPredicate: entry => ({ rowTheme: !!entry.LibraryNote ? Color.danger : '' }),
  isWithQna: true,
};
