import React from 'react';
import * as R from 'ramda';

import { Column, ListEdit } from 'components';

import { showErrors } from 'utils/Validators';
import { useAppDataContext } from 'features/AppData/context';
import { Partner } from 'types/models/Partner';
import { getMockPartner } from 'features/EditPartner/helpers';
import { Fields } from './Fields/Fields';
import { validate } from './validate';

type Props = {
  childsPartners: Partner[];
  allChildsPartners: Partner[];
  setChildPartners(partners: Partner[]): void;
  disabled: boolean;
  isOnlyVariant?: boolean;
  mainPartner: Partner | null;
};

function formatINNKPP(partner: Partner) {
  if (partner.inn || partner.kpp) {
    return `${partner?.inn} / ${partner.kpp}`;
  }
  if (!partner.enterpriseCustomer?.customFields?.kpp || !partner.enterpriseCustomer?.customFields?.inn) {
    return '';
  }

  return `${partner.enterpriseCustomer?.customFields?.inn} / ${partner.enterpriseCustomer?.customFields?.kpp}`;
}

export function ChildPartners(props: Props) {
  const { childsPartners, setChildPartners, mainPartner, disabled, allChildsPartners, isOnlyVariant } = props;
  const { enumMap } = useAppDataContext();

  const relationValues = enumMap.PartnerEnterpriseRelation;

  const firstColumn: Column<Partner>[] = isOnlyVariant
    ? []
    : [
        {
          label: 'Форма отношений',
          formatValue: x => relationValues.values.find(val => val.value === x.relation?.value)?.label ?? '',
        },
      ];
  return (
    <ListEdit
      withMessages
      isDeleteConfirmEnabled
      rows={childsPartners}
      isToolbarDisabled={disabled}
      onChange={setChildPartners}
      maxHeight="300px"
      defaultRowsCount={5}
      columns={[
        { label: 'ID', formatValue: x => x.id || '', styles: { width: '5%' } },
        ...firstColumn,
        { label: 'Краткое наименование', formatValue: x => x.shortName },
        { label: 'Полное наименование', formatValue: x => x.fullName },
        { label: 'ИНН/КПП', formatValue: formatINNKPP },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (childPartner, setChildPartner) => (
          <Fields
            isOnlyVariant={isOnlyVariant}
            partner={childPartner || getMockPartner()}
            setPartner={setChildPartner}
            relationValues={relationValues}
            allChildsPartners={allChildsPartners}
            mainPartner={mainPartner}
          />
        ),
        validation: {
          checkIsValid: partner => validate(partner).every(x => x.isValid),
          onInvalidate: R.partial(showErrors, [validate]),
        },
      }}
    />
  );
}
