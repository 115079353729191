import { createContext, useContext } from 'react';

export type PrivatePageContextType = {
  isProfile: boolean;
};

export const PrivatePageContext = createContext<PrivatePageContextType>({
  isProfile: false,
});

export function usePrivatePageContext() {
  return useContext<PrivatePageContextType>(PrivatePageContext);
}
