import React, { memo } from 'react';
import { DataGrid } from 'features/Table';
import { GetParticipationFeedlineList } from 'features/Table/specifications';
import { Table as T } from 'types/models';

type Props = {
  relatedTableState: T.State | undefined;
  participationId?: string | null;
  workMode: T.WorkMode;
};

function Messages({ relatedTableState, participationId, workMode }: Props) {
  return (
    <>
      {relatedTableState && (
        <DataGrid
          specification={GetParticipationFeedlineList(
            {
              relatedTableAPIID: 'GetConferenceParticipationList',
              relatedTableState,
              relatedRecordId: participationId || undefined,
            },
            workMode,
          )}
        />
      )}
    </>
  );
}

export const Component = memo(Messages);
