import { useMemo } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Report } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController() {
  const { settings } = useAppDataContext();

  const reports = useMemo<Report[]>(() => [Reports.IncomingFundsNiokr, Reports.IncomingFundsTotalFact], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [getReports, handleSetCurrentReport],
  );

  return {
    buttons,
    isReportOpen,
    currentReport,
    onReportClose,
    settings,
  };
}
