import React from 'react';

import { ListEditTable } from 'components';
import { Project } from 'types/models';
import { formatNumber } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
import useController from './controller';

type Props = {
  financingsByYear: Project.FinancingByYear[];
  stages: Project.Stage[];
};

export function FinancingsByYear(props: Props) {
  const { financingsByYear, stages: projectStages } = props;

  const { settings } = useAppDataContext();

  const { selectedRowIndex, setSelectedRowIndex, ndsPercentFormatter, ndsAmountFormatter } = useController({
    stages: projectStages,
  });

  return (
    <ListEditTable
      rows={financingsByYear}
      defaultRowsCount={3}
      selectRow={setSelectedRowIndex}
      selectedRowIndex={selectedRowIndex}
      columnIndexesForSumTotal={[2, 3, 4, 5, 6, 8]}
      columns={[
        { label: 'Год', formatValue: x => x.year, styles: { width: '50px' } },
        { label: 'Этапы', formatValue: x => x.stages.join(', '), styles: { width: '18%', textAlign: 'center' } },
        { label: 'ОИФ', formatValue: x => formatNumber(x.amountMain), styles: { width: '12%', textAlign: 'right' } },
        { label: 'СФ1', formatValue: x => formatNumber(x.amountCofinancing1), styles: { width: '12%', textAlign: 'right' } },
        { label: 'СФ2', formatValue: x => formatNumber(x.amountCofinancing2), styles: { width: '10%', textAlign: 'right' } },
        {
          label: settings?.organization?.shortName || '',
          formatValue: x => formatNumber(x.amountLocal),
          styles: { width: '10%', textAlign: 'right' },
        },
        { label: 'Партнёр', formatValue: x => formatNumber(x.amountAccomplice), styles: { width: '10%', textAlign: 'right' } },
        { label: 'НДС, %', formatValue: ndsPercentFormatter, styles: { width: '10%', textAlign: 'right' } },
        { label: 'НДС, руб.', formatValue: ndsAmountFormatter, styles: { width: '10%', textAlign: 'right' } },
      ]}
    />
  );
}
