import React from 'react';

import { FormComponent, Reference, TextArea, TextAreaMode } from 'components';

import { RequestPartner } from 'types/models/ProgramRequest';
import { useController } from './controller';

type Props = {
  partner: RequestPartner | null;
  onPartnerChange(row: RequestPartner): void;
};

function PartnerInputs({ partner, onPartnerChange }: Props) {
  const { handleFieldChange } = useController({
    partner,
    onPartnerChange,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Организация-партнер" isRequired>
          <Reference
            name="RefEnterpriseCustomer"
            onChange={val => handleFieldChange('partner')(val)}
            value={partner?.partner ?? null}
            relationTableModalTitle="Список организаций-партнеров"
            isRequired
            isDisabledDelete
            isHiddenDelete
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Примечание' }}
            value={partner?.note || ''}
            onChange={handleFieldChange('note')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { PartnerInputs };
