import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { Table } from 'types/models';
import { EditPreprintRelation } from '../EditPreprintRelation';
import useController from './controller';

type Props = {
  onClose: () => void;
  publicationId: string;
  relatedTableState?: Table.State;
  setTitle?: React.Dispatch<string | React.ReactElement>;
};

export const Component = memo(({ onClose, publicationId, relatedTableState, setTitle }: Props) => {
  const { publicationInfo, handleSavePublication, isLoading, printArticle, handleSetPrintArticle } = useController({
    publicationId,
    onClose,
    setTitle,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <EditPreprintRelation
          handleChangePrintArticle={handleSetPrintArticle}
          printArticle={printArticle}
          isDisabled={isLoading}
          releaseId={publicationInfo?.magazineRelease?.id || ''}
          releaseYear={publicationInfo?.magazineRelease?.year || ''}
          relatedTableState={relatedTableState}
        />
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Сохранить" onClick={handleSavePublication} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
});
