import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Project } from 'types/models';

type CheckActiveProjectCodesForDepartmentProps = {
  department: Project.Department;
  continueCallback: () => void;
  mode: 'edit' | 'delete';
};

type Props = {
  projectId?: string;
};

const useController = ({ projectId }: Props) => {
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState<boolean>(false);
  const [codes, setCodes] = useState<string>('');

  const {
    methods: CheckActiveProjectCodesForDepartmentAPI,
    state: CheckActiveProjectCodesForDepartmentState,
  } = BackendAPI.useBackendAPI('CheckActiveProjectCodesForDepartment');

  const isLoading = useMemo(() => CheckActiveProjectCodesForDepartmentState.kind === 'pending', [
    CheckActiveProjectCodesForDepartmentState.kind,
  ]);

  const onCloseDeleteWarningPopup = useCallback(() => {
    setIsDeleteWarningOpen(false);
  }, []);

  const deleteWarningText = useMemo(
    () => `Выбранное подразделение не может быть удалено, т.к. на него уже заведен шифр <b>&ldquo;${codes}&rdquo;</b>`,
    [codes],
  );

  const checkActiveProjectCodesForDepartment = useCallback(
    ({ department, continueCallback, mode }: CheckActiveProjectCodesForDepartmentProps) => {
      const isEdit = mode === 'edit';
      const isDelete = mode === 'delete';

      CheckActiveProjectCodesForDepartmentAPI.callAPI(
        {
          projectId: projectId || '-1',
          projectDepartmentId: department.id || '-1',
        },
        {
          onSuccessfullCall: ({ data: { codes: responseCodes, value } }) => {
            if (value) {
              setCodes(responseCodes);
            }

            if (isEdit) {
              continueCallback();
            } else if (isDelete) {
              if (value) {
                setIsDeleteWarningOpen(true);
              } else {
                continueCallback();
              }
            }
          },
        },
      );
    },
    [CheckActiveProjectCodesForDepartmentAPI, projectId],
  );

  const onPreEdit = useCallback(
    (department: Project.Department | null, continueEdit: () => void) => {
      setCodes('');
      if (department?.id && projectId) {
        checkActiveProjectCodesForDepartment({
          department,
          continueCallback: continueEdit,
          mode: 'edit',
        });
      } else {
        continueEdit();
      }
    },
    [checkActiveProjectCodesForDepartment, projectId],
  );

  const onPreDelete = useCallback(
    (department: Project.Department | null, continueDelete: () => void) => {
      if (department?.id && projectId) {
        checkActiveProjectCodesForDepartment({ department, continueCallback: continueDelete, mode: 'delete' });
      } else {
        continueDelete();
      }
    },
    [checkActiveProjectCodesForDepartment, projectId],
  );

  return {
    isDeleteWarningOpen,
    onCloseDeleteWarningPopup,
    deleteWarningText,
    isLoading,
    onPreDelete,
    onPreEdit,
    codes,
  };
};

export default useController;
