import { Parameter, ParameterListValue } from 'types/models';
import { ValidatorResult } from 'features/BuildReportPopup/models';
import validate from '../validate';

const validateProjectAndCode = (parameter: Parameter): ValidatorResult => {
  const { required } = parameter;
  const value = parameter.value as ParameterListValue;

  if (required) {
    const isValid = Boolean(value.parentId);

    if (isValid) {
      return validate(parameter);
    }

    return {
      isValid,
      message: 'Выберите проект',
    };
  }

  return { isValid: true, message: '' };
};

export default validateProjectAndCode;
