import { TableSelect } from 'components';

import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { formatEducation } from 'features/SelectPerson/helpers';
import { prepareEducation } from 'features/SelectPerson/PersonHistoryModal/helpers';

type Props = {
  educations: Person.ScientistEducation[];
  selectedEducation: Person.ScientistEducation | null;
  onChangeEducation(job: Person.ScientistEducation | null): void;
  isDisabled?: boolean;
};

function SelectEducation(props: Props) {
  const { educations, onChangeEducation: onChangeEducationCallback, selectedEducation, isDisabled } = props;

  const onChangeEducation = (education: Person.ScientistEducation | null) => {
    const nextEducation = prepareEducation(education);
    const isActive = nextEducation ? nextEducation.isActive : true;

    if (!isActive) {
      showNotification({ message: 'Нельзя выбрать неактивное обучение', theme: 'danger' });
    }

    const preparedJob = isActive ? nextEducation : selectedEducation;

    onChangeEducationCallback(preparedJob);
  };

  return (
    <TableSelect
      rows={educations}
      onChange={onChangeEducation}
      tableModalTitle="Выберите место обучения двойным кликом"
      isDisabledListIcon={isDisabled}
      value={selectedEducation}
      formatValue={education => (education ? formatEducation(education) : '')}
      columns={[
        { label: 'Статус', formatValue: row => row.status.label },
        { label: 'Организация', formatValue: row => row.enterprise },
        {
          label: 'Специальность',
          formatValue: row => row.refSpeciality?.label || row.speciality || '',
          styles: { width: '18%', textAlign: 'center' },
        },
        { label: 'Подразделение', formatValue: row => row.refDepartment?.label || row.department || '' },
        { label: 'Дата начала', formatValue: row => row.dateReceipt ?? '' },
        { label: 'Дата окончания', formatValue: row => row.dateDismissal ?? '' },
        {
          label: 'Создано',
          formatValue: ({ creationDate, creationSource }) => `${creationSource} (${creationDate})`,
        },
        {
          label: 'Изменено',
          formatValue: ({ modificationDate, modificationSource }) => `${modificationSource} (${modificationDate})`,
        },
        {
          label: 'Удалено',
          formatValue: ({ deletionDate, deletedBy }) => (deletedBy && deletionDate ? `${deletedBy} (${deletionDate})` : ''),
        },
      ]}
    />
  );
}

export { SelectEducation };
