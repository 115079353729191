import React, { useMemo, useCallback } from 'react';

import { InputSelect, Option } from 'components';

import './style.scss';

type Props = {
  value: string;
  updateValue: (nextValue: string) => void;
};

const allOption: Option = { label: 'Все', value: 'all' };

export const BooleanFilter = ({ value, updateValue }: Props) => {
  const options = useMemo<Option[]>(() => [allOption, { label: 'Да', value: 'true' }, { label: 'Нет', value: 'false' }], []);

  const onValueUpdate = useCallback(
    (option: Option | null) => {
      const optionValue = option?.value === allOption.value ? '' : option?.value ?? '';
      updateValue(optionValue);
    },
    [updateValue],
  );

  const currentOption = useMemo<Option>(() => options.find(({ value: optionValue }) => optionValue === value) ?? allOption, [
    options,
    value,
  ]);

  return <InputSelect onSelectChange={onValueUpdate} options={options} value={currentOption} />;
};
