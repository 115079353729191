import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';

export const GetPersonalDissertationManagementList: Table.Specification = {
  apiID: 'GetPersonalDissertationManagementList',
  isFiltersHidden: true,
  header: {
    firstLevel: {
      title: 'Руководство защитой на основе данных публикаций типов "Автореферат" и "Диссертация"',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: false,
    },
  },
  isWithQna: true,
};
