import { useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceFilter, ReferenceItem } from 'components';

import { Project } from 'types/models';

type Arguments = {
  department: Project.Department;
  setDepartment(department: Project.Department): void;
};

export function useController({ department, setDepartment }: Arguments) {
  const { methods: getReferenceElementAPI } = BackendAPI.useBackendAPI('GetReferenceElement');

  const getRefElement = useCallback(
    (id: string) =>
      new Promise<ReferenceItem | null>(resolve => {
        getReferenceElementAPI.callAPI(
          {
            refName: 'RefDepartment',
            elementSettings: { key: 'id', value: id },
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getReferenceElementAPI],
  );
  const makeChangeHandler = (key: keyof Project.Department) => (value: ReferenceItem) => {
    if (value?.id) {
      getRefElement(value.id).then(item => {
        setDepartment({ ...department, [key]: item });
      });
    }
  };

  const unitFilters = useMemo<ReferenceFilter[]>(() => {
    const isCode = typeof department.unit?.customFields?.code === 'string' && department.unit?.customFields?.code !== '00000';

    if (isCode) {
      const code: string = department!.unit!.customFields!.code as string;
      return [{ key: 'code', values: [code] }];
    }

    return [];
  }, [department]);

  return {
    unitFilters,
    makeChangeHandler,
  };
}
