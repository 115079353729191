import React from 'react';

import {
  Button,
  ButtonMode,
  Reference,
  FormComponent,
  SectionTitle,
  InputSelect,
  ReferenceItem,
  TextDateTime,
  TextArea,
  TextInput,
  TextInputMode,
  UploadFile,
} from 'components';

import { Arm, Form, Table } from 'types/models';
import { LibraryField } from 'features/Form/views';

type Props = {
  formFields: Form.Fields;
  workMode: Table.WorkMode;
  publicationInfo: Form.Publication | null;
  onCreateBibliographicRecordClick: () => void;
  userDepartments: ReferenceItem[];
  publicationId?: string;
  publicationDepartment: ReferenceItem | null;
  setPublicationDepartment: React.Dispatch<ReferenceItem | null>;
  arm?: Arm;
};

function About({
  publicationInfo,
  formFields,
  workMode,
  onCreateBibliographicRecordClick,
  userDepartments,
  publicationId,
  publicationDepartment,
  setPublicationDepartment,
  arm,
}: Props) {
  return (
    <>
      <SectionTitle title="О публикации" />
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.name.title} isRequired>
          <TextArea
            settings={{ rows: 3, maxLength: 2049 }}
            value={formFields.name.value}
            onChange={formFields.name.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.textLanguage.title} isRequired={formFields.textLanguage.required}>
          <Reference
            value={formFields.textLanguage.value}
            theme="big"
            disabled={workMode === 'viewMode'}
            relationTableModalTitle="Язык"
            name="RefLanguage"
            onChange={formFields.textLanguage.onChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap lineSize="doublePadded">
        <FormComponent.Field isRequired={formFields.pageCount.required} label={formFields.pageCount.title}>
          <TextInput
            mode={TextInputMode.number}
            value={formFields.pageCount.value ?? ''}
            onChange={formFields.pageCount.onChange}
            settings={{ max: 100000, decimal: false }}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.pageCount.isValid}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.year.title} isRequired={formFields.year.required}>
          <TextInput
            mode={TextInputMode.year}
            value={formFields.year.value}
            onChange={formFields.year.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.publicationDate.title}>
          <TextDateTime
            value={formFields.publicationDate.value}
            onChange={formFields.publicationDate.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap lineSize="doublePadded">
        <FormComponent.Field isRequired={formFields.printPageCount.required} label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.number}
            value={formFields.printPageCount.value ?? ''}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.printPageCount.isValid}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.address.title} isRequired={formFields.address.required}>
          <TextInput
            value={formFields.address.value}
            onChange={formFields.address.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />
      <FormComponent.Line hasFreeFormat hasGap growIndexes={[1, 0]}>
        <FormComponent.Field>
          <TextArea
            settings={{ rows: 3 }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
        <Button
          mode={ButtonMode.secondary}
          text="Построить"
          onClick={onCreateBibliographicRecordClick}
          isDisabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>

      <SectionTitle title="Скан-копия выходных данных и идентификаторы публикации" />
      <FormComponent.Line hasGap lineSize="padded">
        <FormComponent.Field
          label="Прикрепите файл"
          // eslint-disable-next-line max-len
          tooltip="Файл с выходными данными обязательно должен содержать титул, оборот титула, содержание и последний лист с информацией о типе издания, тираже, издательстве"
          isRequired
        >
          <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.doi.title} tooltip={formFields.doi.tooltipText}>
          <TextInput
            mode={TextInputMode.doi}
            value={formFields.doi.value}
            onChange={formFields.doi.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <LibraryField libraryUrl={publicationInfo?.status?.libraryUrl || ''} />
        <FormComponent.Field label={formFields.libraryLink.title}>
          <TextInput
            mode={TextInputMode.url}
            value={formFields.libraryLink.value}
            onChange={formFields.libraryLink.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!publicationId && arm === 'pc' && (
        <>
          <SectionTitle title="Ответственное подразделение" />
          <FormComponent.Field
            label="Подразделение"
            tooltip="Публикация будет передана ответственному по подразделению для согласования и утверждения"
          >
            <InputSelect
              options={userDepartments.map(x => ({ value: x.id, label: x.label }))}
              value={{ value: publicationDepartment?.id, label: publicationDepartment?.label || '' }}
              onSelectChange={e => setPublicationDepartment(userDepartments.find(x => x.id === e?.value) || null)}
            />
          </FormComponent.Field>
        </>
      )}
    </>
  );
}

export const Component = React.memo(About);
