import { useMemo } from 'react';
import { PublicationTypeCode } from 'types/models/Form';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  publicationTypeCode: PublicationTypeCode;
};
const controller = ({ publicationTypeCode }: Props) => {
  const title = useMemo(() => `Удаление ${PublicationTypes[publicationTypeCode].genitiveCaseLabel}`, [publicationTypeCode]);

  const text = useMemo(() => {
    switch (publicationTypeCode) {
      case PublicationTypes.COMPILATION_ARTICLES.code:
      case PublicationTypes.COMPILATION_CONFERENCE.code:
      case PublicationTypes.COMPILATION_OTHER.code:
      case PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code:
        // eslint-disable-next-line max-len
        return 'С выбранным сборником связаны статьи.</br>Удалить сборник на данный момент невозможно. Вы можете изменить связь этих статей со сборником, указав другой источник.</br>Продолжить?';
      case PublicationTypes.MAGAZINE.code:
        // eslint-disable-next-line max-len
        return 'С выбранным журналом связаны статьи.</br>Удалить журнал на данный момент невозможно. Вы можете изменить связь этих статей с журналом, указав другой источник.</br>Продолжить?';
      case PublicationTypes.OTHER_BOOK.code:
        // eslint-disable-next-line max-len
        return 'С выбранным дургим изданием связаны статьи.</br>Удалить дургое издание на данный момент невозможно. Вы можете изменить связь этих статей с другим изданием, указав другой источник.</br>Продолжить?';
      case PublicationTypes.MONOGRAPH.code:
        // eslint-disable-next-line max-len
        return 'С выбранной монографией связаны главы.</br>Удалить монографию на данный момент невозможно. Вы можете изменить связь этих глав с другим изданием, указав другой источник.</br>Продолжить?';
      default:
        return 'Не удалось удалить публикацию';
    }
  }, [publicationTypeCode]);

  return {
    title,
    text,
  };
};

export default controller;
