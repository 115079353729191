import React, { useEffect } from 'react';

import { FormComponent, TextDateTime, TextInput, TextInputMode, UploadFile } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function SecurityDocumentPaymentForm({ viewMode, editMode, onClose }: Props) {
  const { payment, formFields, handleFormSubmit, workMode } = useController({
    viewMode,
    editMode,
    onClose,
  });
  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      const paymentTitle = () => {
        if (workMode === 'addMode') {
          return 'Добавить оплату';
        }
        if (workMode === 'viewMode') {
          return 'Просмотр оплаты';
        }
        return 'Редактировать оплату';
      };
      setTitle(
        [paymentTitle(), formFields.paymentPurpose.value?.label ? `${formFields.paymentPurpose?.value?.label}` : '']
          .filter(x => x)
          .join(' '),
      );
    }
  }, [payment, formFields, setTitle, workMode]);
  return (
    <FormComponent.Template>
      <ControlPanel handleSave={() => handleFormSubmit(true)} handleSaveAndContinue={() => handleFormSubmit(false)} />

      <FormComponent.Wrapper>
        <FormComponent.Description mode="info">{payment?.securityDocument?.fullName}</FormComponent.Description>
        <FormComponent.Line>
          <FormComponent.Field label="Оплатить до" isRequired={formFields.payBeforeDate.required}>
            <TextDateTime value={formFields?.payBeforeDate.value} onChange={formFields?.payBeforeDate.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Сумма" isRequired={formFields.amount.required}>
            <TextInput
              mode={TextInputMode.number}
              value={formFields?.amount.value ? formFields?.amount.value.toString() : '0'}
              onChange={formFields?.amount.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Оплачено" isRequired={formFields.payDate.required}>
            <TextDateTime value={formFields.payDate?.value} onChange={formFields?.payDate.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields?.file?.title} isRequired={formFields.file.required}>
            <UploadFile file={formFields?.file?.value} onChange={formFields.file.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(SecurityDocumentPaymentForm);
