import React from 'react';

import { Author } from 'types/models';
import { MemberAffilation } from 'types/models/Author';

import * as BackendAPI from 'services/BackendAPI';

import { GetPartnerList } from 'features/Table/specifications';
import { showNotification } from 'features/Notifications';
import { ListEdit } from 'components';
import { checkIsSelectedRowValid } from './helpers';

type Props = {
  author: Author | null;
  handleChangeMembersAffilations(memberAffilations: MemberAffilation[]): void;
};

export function MemberAffilations(props: Props) {
  const { author, handleChangeMembersAffilations } = props;

  const { methods: getPartnerAPI } = BackendAPI.useBackendAPI('GetPartner');

  return (
    <ListEdit
      maxHeight="200px"
      defaultRowsCount={3}
      rows={author?.memberAffilations ?? []}
      title="Аффилированные организации"
      columns={[
        { label: 'Название организации', formatValue: x => x.label },
        { label: 'Идентификатор', formatValue: x => x.id },
      ]}
      isModalHintHidden
      onChange={handleChangeMembersAffilations}
      isDeleteConfirmEnabled
      withMessages
      specification={{
        mode: 'loadInstanceTableModal',
        relationTableModalTitle: 'Список организаций',
        modalTableSpecification: GetPartnerList({
          withMenu: false,
          parameters: [{ name: 'withRaw', value: true }],
          hasSelectButton: true,
        }),
        validation: {
          checkIsValid: (selectedEntries, selectedIndex, affiliations, mode) =>
            checkIsSelectedRowValid({ selectedEntries, selectedIndex, affiliations, mode }),
          onInvalidate: () => {
            showNotification({ message: 'Нельзя добавлять дубли организаций', theme: 'danger' });
          },
        },
        onStartLoad: (tableRows, onSuccess) => {
          const [row] = tableRows;
          getPartnerAPI.callAPI(
            {
              partnerId: row.id,
              enterpriseCustomerId: row.Type === 'ENTERPRISE_CUSTOMER' ? row.id : null,
              enterpriseId: row.Type === 'ENTERPRISE' ? row.id : null,
              shortName: row.Type === 'RAW' ? row.ShortName ?? null : null,
              fullName: row.Type === 'RAW' ? row.FullName : null,
            },
            {
              onSuccessfullCall: ({ data }) => {
                onSuccess([
                  {
                    id: row.id,
                    affilationId: null,
                    memberAffilationId: null,
                    label: row.FullName,
                    type: row.Type as any,
                    shortLabel: row.ShortName,
                    partner: data,
                  },
                ]);
              },
            },
          );
        },
      }}
    />
  );
}
