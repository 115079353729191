import React, { useMemo } from 'react';

import {
  SectionTitle,
  ReferenceItem,
  TextInput,
  TextInputMode,
  FormComponent,
  IconButton,
  Reference,
  Radio,
  UploadImage,
  TextArea,
  TextAreaMode,
  TextDateTime,
} from 'components';

import { Person, UserPermission } from 'types/models';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { ValueOf } from 'types/helpers';
import { convertDegree, convertAcademicRank, convertRank } from './helpers';
import { useController } from './controller';
import UpperFormPublications from './UpperFormPublications';
import ProfileAchievements from 'features/Charts/ProfileAchievements';
import { LANGUAGE_COMMUNICATION_HINT } from 'utils/Constants/TextConstants';

type Props = {
  scientist: Person.Scientist | null;
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  viewMode: boolean;
  onChange(fieldName: keyof Person.Scientist, value: string | ReferenceItem | null): void;
  makeSetPersonField: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};
function Component(props: Props) {
  const { scientist, userPermission, viewMode, person, onChange, makeSetPersonField } = props;
  if (!person) {
    return null;
  }

  const {
    infoButton,
    isPublicationsOpened,
    isFiveYear,
    closePublicationWindow,
    openPublicationsWindow,
    getActualJob,
    jobsCombinationList,
    getActualEducation,
    yearInterval,
    hirshIndexes,
    onCustomChange,
  } = useController({ person, makeSetPersonField });

  const personalFields = useMemo(
    () => [
      {
        label: 'Ученая степень:',
        value: person.degrees.length > 0 ? convertDegree(person) : '',
      },
      {
        label: 'Ученое звание:',
        value: person.ranks.length > 0 ? convertRank(person) : '',
      },
      {
        label: 'Академическое звание:',
        value: person.academicRanks.length > 0 ? convertAcademicRank(person) : '',
      },
      {
        label: 'Обучение:',
        value: person.scientistEducations.length > 0 ? getActualEducation : '',
      },
    ],
    [person, getActualEducation],
  );

  type FieldSettings = {
    onValueChange: (val: keyof Person.Scientist) => void;
    value: string | undefined;
    mode?: TextInputMode;
  };

  const checkFieldTypeByMode = ({ onValueChange, value, mode }: FieldSettings) =>
    viewMode ? (
      <span>{value || <em>не указано</em>}</span>
    ) : (
      <TextInput mode={mode} value={value ?? ''} onChange={onValueChange} />
    );

  const handleChange = (fieldName: keyof Person.Scientist) => (value: string | ReferenceItem | null) => {
    onChange(fieldName, value);
  };

  const header = useMemo(() => {
    const engName: string = [
      scientist?.englishLastName || '',
      scientist?.englishFirstName || '',
      scientist?.englishPatronymic || '',
    ]
      .filter(x => x)
      .join(' ');

    return [
      scientist?.lastName || '',
      scientist?.firstName || '',
      scientist?.patronymic || '',
      ...(viewMode && engName ? [`(${engName})`] : []),
    ]
      .filter(x => x)
      .join(' ');
  }, [
    scientist?.englishFirstName,
    scientist?.englishLastName,
    scientist?.englishPatronymic,
    scientist?.firstName,
    scientist?.lastName,
    scientist?.patronymic,
    viewMode,
  ]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column specialStyles={{ width: 'calc(100% - 750px)', maxWidth: '950px' }}>
          <SectionTitle title={header} />

          <FormComponent.ColumnWrapper>
            <FormComponent.Column specialStyles={{ width: 'calc(100% - 250px)' }} hasNoWrap>
              {!viewMode && (
                <>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field isRequired label="Фамилия">
                      <TextInput
                        value={scientist?.lastName ?? ''}
                        onChange={handleChange('lastName')}
                        isDisabled={scientist?.fromBus}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Имя" isRequired>
                      <TextInput
                        value={scientist?.firstName ?? ''}
                        onChange={handleChange('firstName')}
                        isDisabled={scientist?.fromBus}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Отчество">
                      <TextInput
                        value={scientist?.patronymic ?? ''}
                        onChange={handleChange('patronymic')}
                        isDisabled={scientist?.fromBus}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>

                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Фамилия на англ:">
                      <TextInput value={scientist?.englishLastName ?? ''} onChange={handleChange('englishLastName')} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Имя на англ:">
                      <TextInput value={scientist?.englishFirstName ?? ''} onChange={handleChange('englishFirstName')} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Отчество на англ:">
                      <TextInput value={scientist?.englishPatronymic ?? ''} onChange={handleChange('englishPatronymic')} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
              )}

              {isHasPermission(userPermission, Permits.PERSON_PRIVATE_DATA_BIRTH_DATE_ACCESS) &&
                (viewMode ? (
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Дата рождения">
                      <span>{scientist?.dateBirth}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                ) : (
                  <FormComponent.Line lineSize="doublePadded" hasGap>
                    <FormComponent.Field label="Дата рождения" isRequired>
                      <TextDateTime
                        value={scientist?.dateBirth}
                        onChange={value => onChange('dateBirth', value)}
                        isDisabled={scientist?.fromBus}
                        customStyles={{ minWidth: '160px' }}
                      />
                    </FormComponent.Field>
                    <FormComponent.Field label="Пол" labelSize="fit">
                      <Radio
                        isDisabled={scientist?.fromBus}
                        value={scientist?.gender}
                        list={[
                          { label: 'М', value: 'MALE' },
                          { label: 'Ж', value: 'FEMALE' },
                        ]}
                        onChange={handleChange('gender')}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                ))}

              {viewMode ? (
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Гражданство">
                    <span>{scientist?.citizenship?.label || 'не указано'}</span>
                  </FormComponent.Field>
                </FormComponent.Line>
              ) : (
                <FormComponent.Line>
                  <FormComponent.Field label="Гражданство">
                    <Reference
                      name="RefCountry"
                      disabled={scientist?.fromBus}
                      relationTableModalTitle='Справочник "Страны"'
                      value={scientist?.citizenship || null}
                      onChange={handleChange('citizenship')}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
              <>
                {personalFields.map(
                  (field, index) =>
                    field.value && (
                      <FormComponent.Line key={index} lineSize="doublePadded">
                        <FormComponent.Field label={field.label}>
                          <span>{field.value}</span>
                        </FormComponent.Field>
                      </FormComponent.Line>
                    ),
                )}
              </>

              {isHasPermission(userPermission, Permits.PERSON_PRIVATE_DATA_SNILS_INN_ACCESS) && (
                <>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="ИНН:">
                      {checkFieldTypeByMode({ value: scientist?.inn, onValueChange: handleChange('inn') })}
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="СНИЛС:">
                      {checkFieldTypeByMode({
                        value: scientist?.snils,
                        onValueChange: handleChange('snils'),
                        mode: TextInputMode.snils,
                      })}
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
              )}

              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Языковые коммуникации" tooltip={LANGUAGE_COMMUNICATION_HINT}>
                  {checkFieldTypeByMode({
                    value: scientist?.languageCommunication || '',
                    onValueChange: handleChange('languageCommunication'),
                  })}
                </FormComponent.Field>
              </FormComponent.Line>

              <SectionTitle title="Актуальные контакты" />

              {isHasPermission(userPermission, Permits.PERSON_PRIVATE_DATA_CONTACTS_ACCESS) &&
                (viewMode ? (
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label="Телефон">
                      <span>
                        {[scientist?.mobilePhone ? `Моб.: ${scientist?.mobilePhone}` : null, scientist?.phone]
                          .filter(x => !!x)
                          .join('; ')}
                      </span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                ) : (
                  <>
                    <FormComponent.Line lineSize="doublePadded">
                      <FormComponent.Field label="Телефон">
                        {checkFieldTypeByMode({
                          onValueChange: handleChange('phoneOther'),
                          value: scientist?.phoneOther || '',
                        })}
                      </FormComponent.Field>
                    </FormComponent.Line>
                    <FormComponent.Line lineSize="doublePadded">
                      <FormComponent.Field label="Моб. телефон">
                        {checkFieldTypeByMode({
                          onValueChange: handleChange('mobilePhoneOther'),
                          value: scientist?.mobilePhoneOther || '',
                        })}
                      </FormComponent.Field>
                    </FormComponent.Line>
                    <FormComponent.Line lineSize="doublePadded">
                      <FormComponent.Field label="E-mail">
                        {checkFieldTypeByMode({
                          onValueChange: handleChange('emailOther'),
                          value: scientist?.emailOther || '',
                        })}
                      </FormComponent.Field>
                    </FormComponent.Line>
                  </>
                ))}
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label="Примечание">
                  <TextArea
                    mode={TextAreaMode.modal}
                    settings={{ rows: 3, title: 'Примечание' }}
                    value={scientist?.note || ''}
                    onChange={handleChange('note')}
                    isDisabled={!!viewMode}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column specialStyles={{ width: '250px', height: '270px' }} hasNoWrap>
              <UploadImage text="Фото профиля" id={person?.avatar?.id} viewMode={viewMode} onChange={onCustomChange} />
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
          <SectionTitle title="Место работы" tooltip="Эти данные о работе используются  Системой по умолчанию" />
          <>
            {person.scientistJobs.length > 0 && (
              <FormComponent.Line>
                <FormComponent.Field label="Основное место работы:">
                  <span>{getActualJob}</span>
                </FormComponent.Field>
              </FormComponent.Line>
            )}
          </>
          <>
            {jobsCombinationList.length > 0 && (
              <FormComponent.Line>
                <FormComponent.Field label="Совместительство: ">
                  {jobsCombinationList.map((job, index) => (
                    <FormComponent.Line key={index}>
                      <span>{job}</span>
                    </FormComponent.Line>
                  ))}
                </FormComponent.Field>
              </FormComponent.Line>
            )}
          </>

          <SectionTitle title="Идентификаторы" />
          <>
            {person.identifiers.map((filter, index) => (
              <FormComponent.Line key={index}>
                <FormComponent.Field label={`${filter.citationSystem?.label}: `}>
                  {filter.link ? (
                    <FormComponent.Link href={filter.link} label={filter.identifier} />
                  ) : (
                    <span>{filter.identifier}</span>
                  )}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
          </>
          <>
            {person.affiliations?.length > 0 && (
              <>
                <SectionTitle title="Аффилированные организации:" />
                {person.affiliations.map(affiliation => (
                  <FormComponent.Line key={affiliation.id}>
                    <span>{`${affiliation.partner?.fullName} ${
                      affiliation.partner?.shortName ? ` (${affiliation.partner?.shortName})` : ''
                    }`}</span>
                  </FormComponent.Line>
                ))}
              </>
            )}
          </>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: '750px' }}>
          <SectionTitle title={`Результаты за период: ${yearInterval}`} />
          <FormComponent.Line>
            <span>Индекс Хирша:</span>
          </FormComponent.Line>
          <>
            {hirshIndexes
              .filter((i, index) => hirshIndexes.map(h => h.label).indexOf(i.label) === index)
              .map((hirsh, index) => (
                <FormComponent.Line key={index}>
                  <FormComponent.Field label={`${hirsh.label}: `}>
                    {!!hirsh.value && (
                      <span>
                        <strong>{hirsh.value}</strong>
                        {hirsh.date ? ` (дата расчета: ${hirsh.date})` : ''}
                      </span>
                    )}
                  </FormComponent.Field>
                </FormComponent.Line>
              ))}
          </>
          <FormComponent.Line hasFreeFormat hasGap>
            <span>
              Совокупный импакт&#8209;фактор:&nbsp;&nbsp;
              {person.scientist?.calculateImpactFactor}
            </span>
            <IconButton
              {...infoButton}
              title="Публикации для расчета совокупного ИФ"
              onClick={() => openPublicationsWindow(false)}
            />
          </FormComponent.Line>
          <SectionTitle />
          <>{person.calculations && <ProfileAchievements calculations={person.calculations} />}</>
        </FormComponent.Column>
        <UpperFormPublications
          isOpen={isPublicationsOpened}
          onClose={closePublicationWindow}
          id={person.id}
          isFiveYear={isFiveYear}
        />
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

export const UpperForm = React.memo(Component);
