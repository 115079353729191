import * as XML from 'xml-js';

import { Field } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/types';
import { checkIsReferenceItem } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/helpers';
import { Event, FileInfo, ResponseData, ScientificIndex, Table, UserDepartment } from 'types/models';
import { PublicationCitationSystem } from 'features/Table/specifications/GetPublicationCitationSystemList/types';
import { PublicationLibraryLog, PublicationFieldEditData } from 'types/models/Form';

import { convertRequestInfoLot } from 'services/BackendAPI/configurations/programRequest/converters';
import { makeBackendAPIConfiguration } from '../makeBackendAPIConfiguration';

import { backendTableAPIConfigurations } from './tableData';
import { backendFormApiConfigurations } from './formData';
import { backendEventApiConfigurations } from './event';
import { backendCompilationApiConfigurations } from './compilationData';
import { backendPublicationReleaseApiConfigurations } from './publicationReleaseData';
import { backendSuchlikePublicationApiConfigurations } from './suchlikePublicationData';
import { backendRecognitionAPIConfigurations } from './recognitionData';
import { backendScientificIndexAPIConfigurations } from './scientificIndexData';
import { backendFormSettingsAPIConfigurations } from './formSettingsData';
import { authorConfigurations } from './author';
import { backendFieldSuggestionApiConfigurations } from './fieldSuggestion';
import { exponentConfigurations } from './exponents';
import { publicationConfigurations } from './publication';
import { referenceConfigurations } from './reference';
import { abstractModelConfigurations } from './abstractModelConfigurations';
import { participationConfigurations } from './participation';
import { paymentsConfigurations } from './payments';
import { fundsConfigurations } from './funds';
import { reportConfigurations } from './reports';
import { partnersConfigurations } from './partners';
import { nirRequestsConfigurations } from './nirRequests';
import { govermentContractsConfigurations } from './govermentContracts';
import { economicAgreementsConfigurations } from './economicAgreements';
import { grantsConfigurations } from './grants';
import { programsConfigurations } from './program';
import { tenderConfigurations } from './tenders';
import { programEventConfigurations } from './programEvent';
import { contestRequestConfigurations } from './contestRequests';
import { programRequestConfigurations } from './programRequest';
import { estimateConfigurations } from './estimates';
import { communitiesConfigurations } from './community';
import { patentResearchsConfigurations } from './patentResearch';
import { securityDocumentConfiguration } from './securityDocument';
import { userConfigurations } from './users';
import { securityDocumentContractsConfigurations } from './securityDocumentContract';
import { securityDocumentPaymentConfigurations } from './securityDocumentPayment';
import { securityDocumentPaymentRequestConfigurations } from './securityDocumentPaymentRequest';
import { statisticConfigurations } from './statistic';
import { tenderProtocolConfigurations } from './tenderProtocols';
import { projectAssignmentConfigurations } from './projectAssignments';
import { projectEditPartialConfigurations } from './project';
import { projectPerformerOrderConfigurations } from './projectPerformerOrders';
import { projectCodeConfigurations } from './projectCode';
import { projectStateRegistrationReport } from './projectStateRegistrationReport';
import { communityMemberConfigurations } from './communityMember';
import { availableLockableEntitiesConfigurations } from './availableLockableEntities';
import { rntdConfigurations } from './rntd';
import { foreignSecurityDocumentConfiguration } from './foreignSecurityDocument';
import { GetGridSettingRequestData, PublicationCitationSystemIndex, SaveCitationSystemIndexRequest, SavedChild } from './types';
import * as converters from './converters';
import { appDataConfigurations } from './appData';
import { convertMaybeArray, convertReference, getMaybeField, getText, makeParametersXMLElement } from './commonConverters';
import { convertServerQnaFrequents, convertServerQnaQuestion } from './qna/converters';
import { userRoleConfigurations } from './userRole';
import { roleConfigurations } from './role';
import { lockedEntityConfigurations } from './lockedEntity';
import { otherProjectConfigurations, otherProjectGridConfigurations } from './otherProject';
import { subjectConfigurations } from './subject';
import { partnersMipConfigurations } from './mips';
import { jointEventConfigurations } from './jointEvent';
import { mobileRequestConfigurations } from './mobileRequest';
import { protocolCommissionConfigurations } from './protocolCommission';
import { consumptionConfigurations } from './kindConsumption';
import { requestKindConsumptionConfigurations } from './kindConsumption';
import { userActivationConfigurations } from './userActivation';
import { mergeScientistConfigurations } from './MergeScientist';
import { mergeScientistCollectionConfigurations } from './MergeScientistCollection';
import { mergeConfigurations } from './merge';
import { purchaseRequestConfigurations } from './purchaseRequests';
import { convertServerNotice } from './notice/converters';
import { convertServerUsefulLink } from './usefulLink/converters';
import { convertServerHelpfulStuff } from './helpfulStuff/converters';
import { convertSettings } from '../makeBackendTableAPIConfiguration';
import { Settings } from 'types/models/common';
import { fundCardConfigurations } from './fundCard';

export const backendAPIConfigurations = {
  ...abstractModelConfigurations,
  ...appDataConfigurations,
  ...authorConfigurations,
  ...availableLockableEntitiesConfigurations,
  ...backendCompilationApiConfigurations,
  ...backendEventApiConfigurations,
  ...backendFieldSuggestionApiConfigurations,
  ...backendFormApiConfigurations,
  ...backendFormSettingsAPIConfigurations,
  ...backendPublicationReleaseApiConfigurations,
  ...backendRecognitionAPIConfigurations,
  ...backendScientificIndexAPIConfigurations,
  ...backendSuchlikePublicationApiConfigurations,
  ...backendTableAPIConfigurations,
  ...communitiesConfigurations,
  ...communityMemberConfigurations,
  ...contestRequestConfigurations,
  ...economicAgreementsConfigurations,
  ...estimateConfigurations,
  ...exponentConfigurations,
  ...fundsConfigurations,
  ...govermentContractsConfigurations,
  ...grantsConfigurations,
  ...jointEventConfigurations,
  ...mergeScientistConfigurations,
  ...mergeScientistCollectionConfigurations,
  ...lockedEntityConfigurations,
  ...nirRequestsConfigurations,
  ...otherProjectConfigurations,
  ...otherProjectGridConfigurations,
  ...participationConfigurations,
  ...partnersConfigurations,
  ...partnersMipConfigurations,
  ...patentResearchsConfigurations,
  ...paymentsConfigurations,
  ...programEventConfigurations,
  ...programsConfigurations,
  ...tenderConfigurations,
  ...projectAssignmentConfigurations,
  ...projectEditPartialConfigurations,
  ...projectPerformerOrderConfigurations,
  ...projectCodeConfigurations,
  ...projectStateRegistrationReport,
  ...publicationConfigurations,
  ...referenceConfigurations,
  ...reportConfigurations,
  ...programRequestConfigurations,
  ...rntdConfigurations,
  ...foreignSecurityDocumentConfiguration,
  ...roleConfigurations,
  ...securityDocumentConfiguration,
  ...securityDocumentContractsConfigurations,
  ...securityDocumentPaymentConfigurations,
  ...securityDocumentPaymentRequestConfigurations,
  ...statisticConfigurations,
  ...subjectConfigurations,
  ...tenderProtocolConfigurations,
  ...userConfigurations,
  ...userRoleConfigurations,
  ...mobileRequestConfigurations,
  ...consumptionConfigurations,
  ...requestKindConsumptionConfigurations,
  ...protocolCommissionConfigurations,
  ...userActivationConfigurations,
  ...mergeConfigurations,
  ...fundCardConfigurations,
  ...purchaseRequestConfigurations,

  GetAllGridSettings: makeBackendAPIConfiguration({
    id: 'GetAllGridSettings',
    endpoint: '/msa/service/commands/GetGridSettings',
    convertInputToXMLElement: ({ isCurrentUserSettingsOnly, relatedGridName }: GetGridSettingRequestData) => ({
      Request: {
        _attr: {
          commandName: 'GetGridSettings',
        },
        Grid: {
          ...makeParametersXMLElement([
            {
              name: 'gridName',
              value: relatedGridName,
            },
            { name: 'currentUserSettingsOnly', value: Boolean(isCurrentUserSettingsOnly) },
          ]),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSettings,
  }),
  ActivateGridSetting: makeBackendAPIConfiguration({
    id: 'ActivateGridSetting',
    endpoint: '/msa/service/commands/ActivateGridSetting',
    convertInputToXMLElement: (inputData: { gridName: string; settingName?: string; author?: string }) => {
      const { gridName, settingName, author } = inputData;
      const getTableGrid = () =>
        settingName
          ? {
              _attr: {
                name: gridName,
              },
              Setting: {
                _attr: {
                  name: settingName,
                  ...getMaybeField('author', author),
                },
              },
            }
          : {
              _attr: {
                name: gridName,
              },
            };

      return {
        Request: {
          _attr: {
            commandName: 'ActivateGridSetting',
          },
          Grid: getTableGrid(),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteGridSetting: makeBackendAPIConfiguration({
    id: 'DeleteGridSetting',
    endpoint: '/msa/service/commands/DeleteGridSetting',
    convertInputToXMLElement: (inputData: { gridName: string; settingName: string }) => {
      const { gridName, settingName } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'DeleteGridSetting',
          },
          Grid: {
            _attr: {
              name: gridName,
            },
            Setting: {
              _attr: { name: settingName },
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveGridSetting: makeBackendAPIConfiguration({
    id: 'SaveGridSetting',
    endpoint: '/msa/service/commands/SaveGridSetting',
    convertInputToXMLElement: (inputData: {
      gridName: string;
      columns: Table.Column[];
      purpose?: string;
      id?: string;
      shared?: boolean;
      settingName?: string;
      settingID?: string;
    }) => {
      const { gridName, columns, settingName, shared, purpose, settingID } = inputData;

      const constGridContent = {
        _attr: {
          name: gridName,
        },
        Columns: {
          Column: columns.map(({ name, width, type, filter, sortAscending, filterStructure, hidden, sortOrder }, index) => ({
            _attr: {
              name,
              order: index,
              width: isNaN(parseInt(width, 10)) ? 0 : parseInt(width, 10),
              sortAscending,
              hidden,
              type,
              filterStructure,
              ...(settingName && sortOrder !== undefined ? { sortOrder } : {}),
              ...(settingName && filter ? { filter } : {}),
            },
          })),
        },
      };

      const optionalGridContent = settingName
        ? {
            Setting: {
              _attr: {
                name: settingName,
                ...getMaybeField('shared', shared),
                ...getMaybeField('purpose', purpose),
                ...getMaybeField('id', settingID),
              },
            },
          }
        : {};

      return {
        Request: {
          _attr: {
            commandName: 'SaveGridSetting',
          },
          Grid: { ...constGridContent, ...optionalGridContent },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),

  SavePublicationCitationSystem: makeBackendAPIConfiguration({
    id: 'SavePublicationCitationSystem',
    endpoint: '/msa/service/commands/SavePublicationCitationSystem',
    convertInputToXMLElement: (inputData: PublicationCitationSystem) => {
      const {
        publicationID,
        publicationCitationSystemID,
        citationSystem,
        identifier,
        url,
        name,
        sourceType,
        periods,
        thematicCategories,
        keyWords,
        citationSystemType,
      } = inputData;
      const Url = url ? { Url: url } : {};
      const Name = name ? { Name: name } : {};
      const SourceTypeID = sourceType?.id
        ? citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID'
          ? { SourceType: { _attr: { id: sourceType.id } } }
          : { PublicationType: { _attr: { id: sourceType.id } } }
        : {};
      const Periods =
        periods && periods.filter(x => x.startYear).length
          ? {
              Period: periods.map(period => ({
                ...(period.id ? { _attr: { id: period.id } } : {}),
                StartYear: period.startYear,
                ...(period.endYear ? { EndYear: period.endYear } : {}),
              })),
            }
          : {};
      const ThematicCategories =
        thematicCategories && thematicCategories.length
          ? {
              ThematicCategory: thematicCategories.map(thematicCategory => ({
                ...(thematicCategory.id ? { _attr: { id: thematicCategory.id } } : {}),
                ThematicCategory: {
                  _attr: { id: thematicCategory.value?.id },
                },
              })),
            }
          : {};
      const KeyWords = keyWords
        ? {
            KeyWords: keyWords,
          }
        : {};
      return {
        Request: {
          _attr: {
            commandName: 'SavePublicationCitationSystem',
          },
          PublicationCitationSystem: {
            ...(publicationCitationSystemID ? { _attr: { id: publicationCitationSystemID } } : {}),
            Publication: { _attr: { id: publicationID } },
            CitationSystem: { _attr: { id: citationSystem.id } },
            Identifier: identifier,
            ...Url,
            ...Name,
            ...SourceTypeID,
            ...KeyWords,
            Periods: { ...Periods },
            ThematicCategories: { ...ThematicCategories },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeletePublicationCitationSystem: makeBackendAPIConfiguration({
    id: 'DeletePublicationCitationSystem',
    endpoint: '/msa/service/commands/DeletePublicationCitationSystem',
    convertInputToXMLElement: (inputData: { publicationCitationSystemID: string }) => {
      const { publicationCitationSystemID } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'DeletePublicationCitationSystem',
          },
          PublicationCitationSystem: {
            _attr: { id: publicationCitationSystemID },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetPublicationCitationSystem: makeBackendAPIConfiguration({
    id: 'GetPublicationCitationSystem',
    endpoint: '/msa/service/commands/GetPublicationCitationSystem',
    convertInputToXMLElement: (inputData: { publicationCitationSystemID: string; sourceID?: string }) => {
      const { publicationCitationSystemID, sourceID } = inputData;
      if (!publicationCitationSystemID && sourceID)
        return {
          Request: {
            _attr: {
              commandName: 'GetPublicationCitationSystem',
            },
            PublicationCitationSystem: { _attr: { sourceId: sourceID } },
          },
        };
      return {
        Request: {
          _attr: {
            commandName: 'GetPublicationCitationSystem',
          },
          PublicationCitationSystem: { _attr: { id: publicationCitationSystemID } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetScientifiIndices: makeBackendAPIConfiguration({
    id: 'GetScientifiIndices',
    endpoint: '/msa/service/commands/GetScientifiIndices',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: {
          commandName: 'GetScientifiIndices',
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ScientificIndex.ScientificIndex[] => {
      const indexes = response.Response.ScientificIndex;
      return Array.isArray(indexes)
        ? indexes.map(converters.convertScientificIndex)
        : [converters.convertScientificIndex(indexes)];
    },
  }),
  GetPublicationCitationSystemIndex: makeBackendAPIConfiguration({
    id: 'GetPublicationCitationSystemIndex',
    endpoint: '/msa/service/commands/GetPublicationCitationSystemIndex',
    convertInputToXMLElement: ({ citationSystemIndexId }: any) => ({
      Request: {
        _attr: {
          commandName: 'GetPublicationCitationSystemIndex',
        },
        PublicationCitationSystemIndex: {
          _attr: {
            id: citationSystemIndexId,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): PublicationCitationSystemIndex => {
      const serverScientificIndex = response.Response.PublicationCitationSystemIndex.ScientificIndex;
      const scientificIndex = converters.convertScientificIndex(serverScientificIndex);
      const publicationCitationSystemIndex = response.Response.PublicationCitationSystemIndex._attributes.id;
      const serverValues = response.Response.PublicationCitationSystemIndex.Values.Value;
      const serverChilds = response.Response.PublicationCitationSystemIndex.Childs.Child ?? [];

      const convertChilds = (item: any): SavedChild => {
        const serverChildValues = item.Values.Value;
        const values = Array.isArray(serverChildValues)
          ? serverChildValues.map(convertValues)
          : [convertValues(serverChildValues)];

        const index = converters.convertScientificIndex(item.ScientificIndex);
        return {
          savedChildId: item._attributes.id,
          scientificIndex: index,
          savedValues: values,
        };
      };

      const convertValues = (item: any) => ({
        savedValueId: item._attributes.id,
        valueId: item.ValueType._attributes.id,
        value: (() => {
          if (
            typeof item.Value === 'object' &&
            item.Value._attributes &&
            item.Value._attributes.label &&
            item.Value._attributes.id
          ) {
            return {
              id: item.Value._attributes.id,
              label: item.Value._attributes.label,
            };
          }
          return getText(item.Value) as any;
        })(),
      });

      const savedChilds = Array.isArray(serverChilds) ? serverChilds.map(convertChilds) : [convertChilds(serverChilds)];
      const savedValues = Array.isArray(serverValues) ? serverValues.map(convertValues) : [convertValues(serverValues)];
      return {
        scientificIndex,
        publicationCitationSystemIndex,
        savedValues,
        savedChilds,
      };
    },
  }),
  SavePublicationCitationSystemIndex: makeBackendAPIConfiguration({
    id: 'SavePublicationCitationSystemIndex',
    endpoint: '/msa/service/commands/SavePublicationCitationSystemIndex',
    convertInputToXMLElement: (input: SaveCitationSystemIndexRequest) => {
      const convertValue = ({ value, valueId, savedValueId }: Field) => {
        const obj = {
          ValueType: {
            _attr: {
              id: valueId,
            },
          },
          Value: checkIsReferenceItem(value) ? { _attr: { id: value.id } } : value,
        };
        const attr = {
          _attr: {
            id: savedValueId,
          },
        };
        return Object.assign(obj, savedValueId !== null ? attr : {});
      };

      return {
        Request: {
          _attr: {
            commandName: 'SavePublicationCitationSystemIndex',
          },
          Index: {
            _attr: input.publicationCitationSystemIndex ? { id: input.publicationCitationSystemIndex } : {},
            PublicationCitationSystem: {
              _attr: {
                id: input.publicationCitationSystemId,
              },
            },
            ScientificIndex: {
              _attr: {
                id: input.scientificIndexId,
              },
            },
            Values: {
              Value: input.values.filter(({ value }) => Boolean(value)).map(convertValue),
            },
            Childs: {
              Child: input.childs.map(child => ({
                _attr: child.savedChildId ? { id: child.savedChildId } : {},
                ScientificIndex: {
                  _attr: {
                    id: child.scientificIndexId,
                  },
                },
                Values: {
                  Value: Object.values(child.fields)
                    .filter(({ value }) => Boolean(value))
                    .map(convertValue),
                },
              })),
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SavePublicationUserFeedline: makeBackendAPIConfiguration({
    id: 'SavePublicationUserFeedline',
    endpoint: '/msa/service/commands/SavePublicationUserFeedline',
    convertInputToXMLElement: (props: { labelId: string; messageId: string; message: string; publicationId: string }) => {
      const publicationFeedlineAttr = props.messageId
        ? {
            _attr: {
              id: props.messageId,
            },
          }
        : {};

      return {
        Request: {
          _attr: {
            commandName: 'SavePublicationUserFeedline',
          },
          PublicationFeedline: {
            ...publicationFeedlineAttr,
            ...{
              Message: {
                _attr: {
                  id: props.labelId,
                },
              },
              Detail: props.message,
              Publication: {
                _attr: {
                  id: props.publicationId,
                },
              },
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ResponseData => {
      const success = response.Response._attributes.success === 'true';
      const message = success
        ? ''
        : response.Response._attributes.errorMessage ||
          response.Response._attributes.serverErrorMessage ||
          'Произошла ошибка при сохранении';

      return { success, message };
    },
  }),

  SaveParticipationUserFeedline: makeBackendAPIConfiguration({
    id: 'SaveParticipationUserFeedline',
    endpoint: '/msa/service/commands/SaveParticipationUserFeedline',
    convertInputToXMLElement: (props: { labelId: string; messageId: string; message: string; publicationId: string }) => {
      const participationFeedlineAttr = props.messageId
        ? {
            _attr: {
              id: props.messageId,
            },
          }
        : {};

      return {
        Request: {
          _attr: {
            commandName: 'SaveParticipationUserFeedline',
          },
          ParticipationFeedline: {
            ...participationFeedlineAttr,
            ...{
              Message: {
                _attr: {
                  id: props.labelId,
                },
              },
              Detail: props.message,
              Participation: {
                _attr: {
                  id: props.publicationId,
                },
              },
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ResponseData => {
      const success = response.Response._attributes.success === 'true';
      const message = success
        ? ''
        : response.Response._attributes.errorMessage ||
          response.Response._attributes.serverErrorMessage ||
          'Произошла ошибка при сохранении';

      return { success, message };
    },
  }),
  GetEventById: makeBackendAPIConfiguration({
    id: 'GetEventById',
    endpoint: '/msa/service/commands/GetEvent',
    convertInputToXMLElement: ({ eventId }: { eventId: string }) => ({
      Request: {
        _attr: {
          commandName: 'GetEvent',
        },
        Event: {
          _attr: {
            id: eventId,
            eventModules: 'MAIN,COMPILATIONS,MAGAZINE_RELEASES,PUBLICATIONS',
            participationsModules: 'ID',
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): Event.Event =>
      converters.convertServerEvent(response.Response.Event),
  }),
  EditPublicationField: makeBackendAPIConfiguration({
    id: 'EditPublicationField',
    endpoint: '/msa/service/commands/EditPublicationField',
    convertInputToXMLElement: ({ id, parameters }: PublicationFieldEditData) => ({
      Request: {
        _attr: {
          commandName: 'EditPublicationField',
        },
        Publication: {
          _attr: {
            id,
          },
          PublicationField: parameters.map(param => ({
            _attr: { ...{ name: param.name }, ...(param.value ? { value: param.value } : {}) },
          })),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ResponseData => {
      const success = response.Response._attributes.success === 'true';
      const message = success
        ? ''
        : response.Response._attributes.errorMessage ||
          response.Response._attributes.serverErrorMessage ||
          'Произошла ошибка при сохранении';

      return { success, message };
    },
  }),

  GetPublicationLibraryLog: makeBackendAPIConfiguration({
    id: 'GetPublicationLibraryLog',
    endpoint: '/msa/service/commands/GetPublicationLibraryLog',
    convertInputToXMLElement: ({ publicationLibraryLogId }: any) => ({
      Request: {
        _attr: {
          commandName: 'GetPublicationLibraryLog',
        },
        PublicationLibraryLog: {
          _attr: {
            id: publicationLibraryLogId,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => {
      const success = response.Response?._attributes?.success === 'true';
      const errorMessage = response.Response?._attributes?.serverErrorMessage
        ? response.Response?._attributes?.serverErrorMessage
        : 'При загрузке произошла ошибка';
      if (!success) {
        return {
          status: {
            success,
            errorMessage,
          },
          data: {},
        };
      }

      const id = response.Response?.PublicationLibraryLog?._attributes?.id || '';
      const libraryNote = response.Response?.PublicationLibraryLog?.LibraryNote?._text || '';
      const errorReasonDetail = response.Response?.PublicationLibraryLog?.ErrorReasonDetail?._text || '';
      const errorReasonElement = response.Response?.PublicationLibraryLog?.ErrorReason;
      const errorReason = errorReasonElement
        ? {
            label: errorReasonElement._attributes?.label || '',
            ordinal: errorReasonElement._attributes?.ordinal || '',
            key: errorReasonElement._text || '',
          }
        : null;
      return {
        status: {
          success,
        },
        data: {
          id,
          libraryNote,
          errorReasonDetail,
          errorReason,
        },
      };
    },
  }),
  ProcessPublicationLibraryLog: makeBackendAPIConfiguration({
    id: 'ProcessPublicationLibraryLog',
    endpoint: '/msa/service/commands/ProcessPublicationLibraryLog',
    convertInputToXMLElement: ({ id, libraryNote, errorReasonDetail, errorReason }: PublicationLibraryLog) => ({
      Request: {
        _attr: {
          commandName: 'ProcessPublicationLibraryLog',
        },
        PublicationLibraryLog: {
          _attr: {
            id,
          },
          Status: 'PROCESSED',
          LibraryNote: libraryNote,
          ErrorReason: errorReason ? errorReason.value : '',
          ErrorReasonDetail: errorReasonDetail,
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ResponseData => {
      const success = response.Response._attributes.success === 'true';
      const message = success
        ? ''
        : response.Response._attributes.errorMessage ||
          response.Response._attributes.serverErrorMessage ||
          'Произошла ошибка при отправке запроса';

      return { success, message };
    },
  }),
  GetNotices: makeBackendAPIConfiguration({
    id: 'GetNotices',
    endpoint: '/msa/service/commands/GetNotices',
    convertInputToXMLElement: (inputData: { startRecord: number; lastRecord: number }) => {
      const { startRecord, lastRecord } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetNotices',
          },
          GetNotices: {
            _attr: {
              startRecord,
              lastRecord,
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertServerNotice(response),
  }),
  SaveNotice: makeBackendAPIConfiguration({
    id: 'SaveNotice',
    endpoint: '/msa/service/commands/SaveNotice',
    convertInputToXMLElement: (inputData: { id: string | null; caption: string; data: string; kind: string }) => {
      const { id, caption, data, kind } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveNotice',
          },
          Notice: {
            _attr: { id },
            Caption: caption,
            Data: data,
            Kind: kind,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetHelpfulStuffs: makeBackendAPIConfiguration({
    id: 'GetHelpfulStuffs',
    endpoint: '/msa/service/commands/GetHelpfulStuffs',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: {
          commandName: 'GetHelpfulStuffs',
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertServerHelpfulStuff(response),
  }),
  SaveHelpfulStuff: makeBackendAPIConfiguration({
    id: 'SaveHelpfulStuff',
    endpoint: '/msa/service/commands/SaveHelpfulStuff',
    convertInputToXMLElement: (inputData: { id: string | null; data: string; file: FileInfo }) => {
      const { id, data, file } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveHelpfulStuff',
          },
          HelpfulStuff: {
            ...(id ? { _attr: { id } } : {}),
            Data: data,
            File: {
              _attr: { name: file.name, ...(file.id ? { id: file.id } : {}) },
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetUsefulLinks: makeBackendAPIConfiguration({
    id: 'GetUsefulLinks',
    endpoint: '/msa/service/commands/GetUsefulLinks',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: {
          commandName: 'GetUsefulLinks',
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertServerUsefulLink(response),
  }),
  SaveUsefulLinks: makeBackendAPIConfiguration({
    id: 'SaveUsefulLink',
    endpoint: '/msa/service/commands/SaveUsefulLink',
    convertInputToXMLElement: (inputData: { id: string | null; caption: string; url: string }) => {
      const { id, caption, url } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveUsefulLink',
          },
          UsefulLink: {
            ...(id ? { _attr: { id } } : {}),
            Caption: caption,
            Url: url,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetQnaFrequentQuestions: makeBackendAPIConfiguration({
    id: 'GetQnaFrequentQuestions',
    endpoint: '/msa/service/commands/GetQnaFrequentQuestions',
    convertInputToXMLElement: (inputData: { id: string | undefined }) => {
      const { id } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetQnaFrequentQuestions',
          },
          ...(id ? { QnaFrequentQuestion: { _attr: { id } } } : {}),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertServerQnaFrequents(response),
  }),

  SaveQnaQuestion: makeBackendAPIConfiguration({
    id: 'SaveQnaQuestion',
    endpoint: '/msa/service/commands/SaveQnaQuestion',
    convertInputToXMLElement: (inputData: {
      id: string | undefined;
      themeId: string;
      message: string;
      answerText: string | undefined;
      questionFile: FileInfo | undefined;
      answerFile: FileInfo | undefined;
    }) => {
      const { id, themeId, message, answerText, questionFile, answerFile } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveQnaQuestion',
          },
          QnaQuestion: {
            ...(id ? { _attr: { id } } : {}),
            ...(themeId
              ? {
                  Theme: {
                    _attr: { id: themeId },
                  },
                }
              : {}),
            Message: message,
            ...(answerText
              ? {
                  Answer: answerText,
                }
              : {}),
            ...(questionFile && questionFile.name
              ? {
                  QuestionFile: {
                    _attr: { name: questionFile.name, ...(questionFile.id ? { id: questionFile.id } : {}) },
                  },
                }
              : {}),
            ...(answerFile && answerFile.name
              ? {
                  AnswerFile: {
                    _attr: { name: answerFile.name, ...(answerFile.id ? { id: answerFile.id } : {}) },
                  },
                }
              : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveQnaQuestionAnswer: makeBackendAPIConfiguration({
    id: 'SaveQnaQuestionAnswer',
    endpoint: '/msa/service/commands/SaveQnaQuestion',
    convertInputToXMLElement: (inputData: {
      id: string | undefined;
      answerText: string | undefined;
      answerFile: FileInfo | undefined;
    }) => {
      const { id, answerText, answerFile } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveQnaQuestionAnswer',
          },
          QnaQuestion: {
            ...(id ? { _attr: { id } } : {}),
            ...(answerText
              ? {
                  Answer: answerText,
                }
              : {}),
            ...(answerFile && answerFile.name
              ? {
                  AnswerFile: {
                    _attr: { name: answerFile.name, ...(answerFile.id ? { id: answerFile.id } : {}) },
                  },
                }
              : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveQnaFrequentQuestion: makeBackendAPIConfiguration({
    id: 'SaveQnaFrequentQuestion',
    endpoint: '/msa/service/commands/SaveQnaFrequentQuestion',
    convertInputToXMLElement: (inputData: {
      id: string | undefined;
      themeId: string;
      message: string;
      answerText: string | undefined;
      hashtags: string[] | undefined;
      priority: string | undefined;
    }) => {
      const { id, themeId, message, answerText, hashtags, priority } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveQnaFrequentQuestion',
          },
          QnaQuestion: {
            ...(id && id !== 'undefined' ? { _attr: { id } } : {}),
            ...(themeId
              ? {
                  Theme: {
                    _attr: { id: themeId },
                  },
                }
              : {}),
            Message: message,
            ...(answerText
              ? {
                  Answer: answerText,
                }
              : {}),
            ...(hashtags && hashtags.length > 0
              ? {
                  Tags: {
                    Tag: hashtags.map((tag: string) => ({ _text: tag })),
                  },
                }
              : {}),
            ...(priority
              ? {
                  Priority: priority,
                }
              : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetQnaQuestion: makeBackendAPIConfiguration({
    id: 'GetQnaQuestion',
    endpoint: '/msa/service/commands/GetQnaQuestion',
    convertInputToXMLElement: (inputData: { id: string | null }) => {
      const { id } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetQnaQuestion',
          },
          QnaQuestion: {
            _attr: {
              id,
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertServerQnaQuestion(response.Response.QnaQuestion),
  }),
  GetLot: makeBackendAPIConfiguration({
    id: 'GetLot',
    endpoint: '/msa/service/commands/GetLot',
    convertInputToXMLElement: (inputData: { id: string | undefined }) => {
      const { id } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetLot',
          },
          ...(id ? { Lot: { _attr: { id } } } : {}),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertRequestInfoLot(response.Response.Lot, settings),
  }),
  GetDashboardChartTotalPublicationListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartTotalPublicationList',
    endpoint: '/msa/service/commands/GetDashboardChartTotalPublicationList',
    convertInputToXMLElement: ({ parameters }: { parameters: { name: string; value: string }[] }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartTotalPublicationList',
        },
        Grid: {
          _attr: {
            rowQuantity: 1,
            startRow: 0,
          },
          Parameters: {
            Parameter: parameters.map(x => ({ _attr: x })),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartExpositionParticipationListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartExpositionParticipationList',
    endpoint: '/msa/service/commands/GetDashboardChartExpositionParticipationList',
    convertInputToXMLElement: ({ parameters }: { parameters: { name: string; value: string | null }[] }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartExpositionParticipationList',
        },
        Grid: {
          _attr: {
            rowQuantity: 1,
            startRow: 0,
          },
          Parameters:
            parameters.filter(x => !!x.value).length > 0
              ? {
                  Parameter: parameters.map(x => ({ _attr: x })),
                }
              : {},
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartExpositionParticipationAwardListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartExpositionParticipationAwardList',
    endpoint: '/msa/service/commands/GetDashboardChartExpositionParticipationAwardList',
    convertInputToXMLElement: ({ parameters }: { parameters: { name: string; value: string | null }[] }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartExpositionParticipationAwardList',
        },
        Grid: {
          _attr: {
            rowQuantity: 1,
            startRow: 0,
          },
          Parameters:
            parameters.filter(x => !!x.value).length > 0
              ? {
                  Parameter: parameters.map(x => ({ _attr: x })),
                }
              : {},
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartConferenceParticipationListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartConferenceParticipationList',
    endpoint: '/msa/service/commands/GetDashboardChartConferenceParticipationList',
    convertInputToXMLElement: ({ parameters }: { parameters: { name: string; value: string }[] }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartConferenceParticipationList',
        },
        Grid: {
          _attr: {
            rowQuantity: 1,
            startRow: 0,
          },
          Parameters: {
            Parameter: parameters.map(x => ({ _attr: x })),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetPublicationCitationSystemListStatic: makeBackendAPIConfiguration({
    id: 'GetPublicationCitationSystemList',
    endpoint: '/msa/service/commands/GetPublicationCitationSystemList',
    convertInputToXMLElement: ({ parameters }: { parameters: { name: string; value: string }[] }) => ({
      Request: {
        _attr: {
          commandName: 'GetPublicationCitationSystemList',
        },
        Grid: {
          _attr: {
            rowQuantity: 50,
            startRow: 0,
          },
          Parameters: {
            Parameter: parameters.map(x => ({ _attr: x })),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartExpositionListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartExpositionList',
    endpoint: '/msa/service/commands/GetDashboardChartExpositionList',
    convertInputToXMLElement: ({ parameters }: { parameters: { name: string; value: string | null }[] }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartExpositionList',
        },
        Grid: {
          _attr: {
            rowQuantity: 1,
            startRow: 0,
          },
          Parameters:
            parameters.filter(x => !!x.value).length > 0
              ? {
                  Parameter: parameters.map(x => ({ _attr: x })),
                }
              : {},
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartByTypeSecurityDocumentListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartByTypeSecurityDocumentList',
    endpoint: '/msa/service/commands/GetDashboardChartByTypeSecurityDocumentList',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartByTypeSecurityDocumentList',
        },
        Grid: {
          _attr: {
            rowQuantity: 1,
            startRow: 0,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetUserDepartments: makeBackendAPIConfiguration({
    id: 'GetUserDepartments',
    endpoint: '/msa/service/commands/GetUserDepartments',
    convertInputToXMLElement: (input: { isParent?: boolean }) => ({
      Request: {
        _attr: {
          commandName: 'GetUserDepartments',
        },
        GetUserDepartments: {
          _attr: {
            isParent: input.isParent,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: any): UserDepartment[] => {
      const convert = (department: any): UserDepartment => ({
        id: '',
        ref: convertReference(department),
      });
      return convertMaybeArray(response.Response.Department ?? [], convert); // кривой конвертер, должен быть RefeferenceItem
    },
  }),
  GetDashboardChartByTypeEditionMagazineArticleListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartByTypeEditionMagazineArticleList',
    endpoint: '/msa/service/commands/GetDashboardChartByTypeEditionMagazineArticleList',
    convertInputToXMLElement: ({ period, rowQuantity, offset }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartByTypeEditionMagazineArticleList',
        },
        Grid: {
          _attr: {
            rowQuantity,
            startRow: offset,
          },
          Parameters: {
            Parameter: {
              _attr: {
                name: 'period',
                value: period,
              },
            },
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartByStudyProjectListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartByStudyProjectList ',
    endpoint: '/msa/service/commands/GetDashboardChartByStudyProjectList',
    convertInputToXMLElement: ({ year, rowQuantity, offset }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartByStudyProjectList ',
        },
        Grid: {
          _attr: {
            rowQuantity,
            startRow: offset,
          },
          Parameters: {
            Parameter: {
              _attr: {
                name: 'year',
                value: year,
              },
            },
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartByProgramIndicatorListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartByProgramIndicatorList ',
    endpoint: '/msa/service/commands/GetDashboardChartByProgramIndicatorList',
    convertInputToXMLElement: ({ rowQuantity, offset, byDepartment = false }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartByProgramIndicatorList ',
        },
        Grid: {
          _attr: {
            rowQuantity,
            startRow: offset,
          },

          Parameters: {
            Parameter: {
              _attr: {
                name: 'byDepartment',
                value: byDepartment,
              },
            },
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetDashboardChartScopusWosArticleListStatic: makeBackendAPIConfiguration({
    id: 'GetDashboardChartScopusWosArticleList',
    endpoint: '/msa/service/commands/GetDashboardChartScopusWosArticleList',
    convertInputToXMLElement: ({
      parameters,
      rowQuantity,
      offset,
    }: {
      parameters: { name: string; value: string }[];
      rowQuantity: number;
      offset: number;
    }) => ({
      Request: {
        _attr: {
          commandName: 'GetDashboardChartScopusWosArticleList',
        },
        Grid: {
          _attr: {
            rowQuantity,
            startRow: offset,
          },
          Parameters: {
            Parameter: parameters.map(x => ({ _attr: x })),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SavePaymentTermPreset: makeBackendAPIConfiguration({
    id: 'SaveEntity',
    endpoint: '/msa/service/commands/SaveEntity',
    convertInputToXMLElement: (inputData: {
      id: string | undefined;
      type: string;
      regulationParagraph: string;
      regulationName: string;
    }) => {
      const { id, type, regulationParagraph, regulationName } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveEntity',
          },
          paymentTermPreset: {
            ...(id ? { _attr: { id } } : {}),
            type,
            regulationParagraph,
            regulationName,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveScientistHirshIndex: makeBackendAPIConfiguration({
    id: 'SaveScientistHirshIndex',
    endpoint: '/msa/service/commands/SaveScientistHirshIndex',
    convertInputToXMLElement: (inputData: {
      hirshIndexID?: string;
      scientistID?: string;
      date: string;
      citationSystemID: string;
      value?: string;
      identifier?: string;
      note?: string;
    }) => {
      const { hirshIndexID, scientistID, date, citationSystemID, value, identifier, note } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveScientistHirshIndex',
          },
          ScientistHirshIndex: {
            ...(hirshIndexID ? { _attr: { id: hirshIndexID } } : {}),
            Scientist: { _attr: { id: scientistID } },
            CitationSystem: { _attr: { id: citationSystemID } },
            Date: date,
            ...(value ? { Value: value } : {}),
            ...(identifier ? { Identifier: identifier } : {}),
            Note: note,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteScientistHirshIndex: makeBackendAPIConfiguration({
    id: 'DeleteEntity',
    endpoint: '/msa/service/commands/DeleteEntity',
    convertInputToXMLElement: (inputData: { hirshIndexID: string }) => {
      const { hirshIndexID } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'DeleteEntity',
          },
          ScientistHirshIndex: {
            ...(hirshIndexID ? { _attr: { id: hirshIndexID } } : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
