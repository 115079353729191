import React from 'react';
import { block } from 'bem-cn';

import { Tab, Tabs, TextArea, TextAreaMode, FormComponent } from 'components';

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { sortStages } from 'features/Form/looks/project/ProjectForm/helpers';
import { IndicatorKey, EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';
import { EditableIndicators } from './EditIndicators/EditIndicators';
import { ResultsProjectPublications } from './ResultProjectPublications';
import { ResultsProjectEvents } from './ResultProjectEvents';
import { ResultsProjectPatents } from './ResultProjectPatents';
import { ResultsProjectDocuments } from './ResultProjectDocuments';
import { ResultProjectDissertations } from './ResultProjectDissertations';
import { ProjectType } from 'utils/Enums';

import './style.scss';

const b = block('research-job-results-project');

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  indicators: EditableIndicator[];
  indices: EditableIndicator[];
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
};

export function ResultsProject(props: Props) {
  const { disabled, project, indicators, indices, makeChangeHandler } = props;
  const projectType = project?.type?.value!;

  const { userPermission } = useAppDataContext();

  const stages = project?.stages ?? [];

  const getTitle = (type: IndicatorKey) =>
    sortStages(stages)
      .slice(0, 7)
      .map(x => `Этап ${x.number} - ${x[type].length} шт`)
      .join(', ');

  const intellectualPropertyViewMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectIntellectualPropertyView',
    GRANT: 'GrantProjectIntellectualPropertyView',
    GOVERMENT_CONTRACT: 'GovContractProjectIntellectualPropertyView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectIntellectualPropertyView',
  };

  const isVisibleIntellectualProperty = isHasPermission(userPermission, intellectualPropertyViewMap[projectType]);

  return (
    <Tabs>
      <Tab title="Индикаторы и показатели">
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <EditableIndicators
              title={`Индикаторы результативности. ${getTitle('indicators')}.`}
              type="indicators"
              disabled={disabled}
              stages={project?.stages ?? []}
              setStages={makeChangeHandler('stages')}
              program={project?.program ?? null}
              indices={indicators}
            />
          </FormComponent.Column>
          <FormComponent.Column>
            <EditableIndicators
              title={`Показатели результативности. ${getTitle('indices')}.`}
              type="indices"
              disabled={disabled}
              stages={project?.stages ?? []}
              setStages={makeChangeHandler('stages')}
              program={project?.program ?? null}
              indices={indices}
            />
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </Tab>
      <Tab title="Публикации">
        <ResultsProjectPublications projectId={project?.id ?? null} />
      </Tab>
      <Tab title="Диссертации и авторефераты">
        <ResultProjectDissertations projectId={project?.id ?? null} />
      </Tab>
      <Tab title="Мероприятия и участие в мероприятиях">
        <ResultsProjectEvents projectId={project?.id ?? null} />
      </Tab>
      {isVisibleIntellectualProperty && projectType !== ProjectType.ECONOMIC_AGREEMENT && (
        <Tab title="Интеллектуальная собственность">
          <div className={b('intellectual-property')}>
            <div>
              <ResultsProjectDocuments projectId={project?.id ?? null} />
            </div>
            <div>
              <ResultsProjectPatents projectId={project?.id ?? null} />
            </div>
          </div>
        </Tab>
      )}
      <Tab title="Описание результатов">
        <FormComponent.Line>
          <FormComponent.Field label="Результаты проекта">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 10, title: 'Результаты проекта' }}
              value={project?.result ?? ''}
              onChange={makeChangeHandler('result')}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </Tab>
    </Tabs>
  );
}
