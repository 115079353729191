import { useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Report, Table } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const reports = useMemo<Report[]>(() => [Reports.SecurityDocumentAuthors], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const row = useMemo(() => {
    return tableState?.selectedRows ? tableState.selectedRows[0] : undefined;
  }, [tableState]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        isDisabled: false,
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        isDisabled: row !== undefined && row.Type !== 'SECURITY_DOCUMENT',
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [row, getReports, handleSetCurrentReport],
  );

  return {
    buttons,
    currentReport,
    isReportOpen,
    onReportClose,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}
