import React, { useMemo } from 'react';

import { Component as Home } from './component';
import { GridContext } from 'features/Table/context';

function HomeSubContainer() {
  return <Home />;
}

export function HomeContainer(props: { specification: any }) {
  const { specification } = props;
  const contextValue = useMemo(() => ({ specification }), [specification]);

  return (
    <GridContext.Provider value={contextValue}>
      <HomeSubContainer />
    </GridContext.Provider>
  );
}
