import React from 'react';

import { FormComponent, ListEdit, TextArea, TextAreaMode, TextInput } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { GetKindConsumptionList } from 'features/Table/specifications/GetKindConsumptionSelectList';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function MobileKindConsumptionForm({ viewMode, editMode, onClose }: Props) {
  const {
    formFields,
    handleFormSubmit,
    kindConsumptions,
    handleKindConsumptionsChange,
    modalTableRowKindConsumptionConverter,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label={formFields.name.title} isRequired={formFields.name.required}>
            <TextInput value={formFields.name.value} onChange={formFields.name.onChange} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label={formFields.code.title} isRequired={formFields.code.required}>
            <TextInput value={formFields.code.value} onChange={formFields.code.onChange} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.description.title}>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3 }}
              value={formFields?.description.value || ''}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field>
            <ListEdit
              title="Виды расходов из бухгалтерии"
              isRequired
              rows={kindConsumptions ?? []}
              onChange={handleKindConsumptionsChange}
              isToolbarDisabled={viewMode}
              columns={[
                { label: 'Код', formatValue: x => x?.code ?? '', styles: { width: '25%' } },
                { label: 'Наименование', formatValue: x => x?.name ?? '', styles: { width: '75%' } },
              ]}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Виды расходов',
                modalTableRowConverter: modalTableRowKindConsumptionConverter,
                modalTableSpecification: GetKindConsumptionList({ hasSelectButton: true }),
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(MobileKindConsumptionForm);
