import { Form } from 'types/models';
import { KindConsumption, MobileKindConsumption } from 'types/models/KindConsumption';

type Props = {
  formFields: Form.Fields;
  mobileKindConsumption?: MobileKindConsumption;
  kindConsumptions: KindConsumption[];
};
export function validate(props: Props) {
  const { formFields, kindConsumptions } = props;
  const required = Object.keys(formFields)
    .filter(
      (fieldName: string) =>
        formFields[fieldName].required && formFields[fieldName]?.value?.length <= 0 && formFields[fieldName].title,
    )
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  const validations = [];

  if (kindConsumptions.length <= 0) {
    validations.push({
      isValid: false,
      invalidMessage: `Не выбран ни один вид расхода из бухгалтерии"`,
    });
  }

  return [...required, ...validations];
}
