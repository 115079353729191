import { useCallback, useMemo } from 'react';

import { ReferenceFilter, ReferenceItem } from 'components';

import { Table } from 'types/models';
import { ProgramRequest } from 'types/models/ProgramRequest';

type Props = {
  requestInfo?: ProgramRequest | null;
};

export function useController({ requestInfo }: Props) {
  const domainKnowledgeTableRowConverter = useCallback<(row: Table.Entry) => ReferenceItem>(
    row => ({
      id: row.id,
      label: `${row.Code} ${row.Name}`,
      code: row.Code,
    }),
    [],
  );

  const scienceDomainInterrestsFilters: ReferenceFilter[] = useMemo(() => {
    if (!requestInfo?.tender?.scienceDomainInterrests || requestInfo?.tender?.scienceDomainInterrests.length == 0) {
      return [
        ({
          key: 'id',
          values: ['-1'],
        } as unknown) as ReferenceFilter,
      ];
    }
    return [
      ({
        key: 'id',
        values: requestInfo?.tender?.scienceDomainInterrests?.map(x => x.id),
      } as unknown) as ReferenceFilter,
    ];
  }, [requestInfo]);

  return {
    domainKnowledgeTableRowConverter,
    scienceDomainInterrestsFilters,
  };
}
