import { useState, useLayoutEffect, useMemo, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Form } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { ValueOf } from 'types/helpers';
import workModeHook from 'features/Form/hooks/workModeHook';
import { ContestRequest, Performer } from 'types/models/ContestRequest';
import { getMockContestRequest } from 'features/Form/looks/contestRequest/ContestRequestForm/helpers';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useStreamsByApiID } from 'StreamRx';
import { streams as globalStreams } from 'features/Table/streams';
import { showErrorsMessages } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { validate } from './validate';
import { ContestRequestStatus } from 'utils/Enums/ContestRequestStatus';
import { ContestRequestType } from 'utils/Enums';
import { formatIndexHirsh } from './helpers';
import { clonePersonHistory } from 'utils/Helpers';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id: initialId, projectId, requestType: initialRequestType },
  } = useFormContext<Form.CreateContestRequestFormLook>();

  const [contestRequest, setContestRequest] = useState<ContestRequest | undefined>(undefined);
  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });
  const [requestStagePerformers, setRequestStagePerformers] = useState<Performer[]>([]);
  const [personId, setPersonId] = useState<string | null>(null);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState<boolean>(false);
  const [isStageModalOpen, setIsStageModalOpen] = useState<boolean>(false);

  const [formFields, setFormFields] = useState<Form.Fields>({
    requestStagePreviousYearAnnotation: {
      value: '',
      isValid: true,
      required: false,
      title: `Аннотация проведённых научных исследований в проекте на предыдущем этапе`,
      // eslint-disable-next-line max-len
      tooltipText: `Кратко опишите основные результаты проекта, полученные в предыдущем году, которые являются научным заделом для нового этапа проекта.`,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStagePreviousYearAnnotation: { ...prevState.requestStagePreviousYearAnnotation, value },
        }));
      },
    },
    requestStageKeyWords: {
      value: '',
      isValid: true,
      required: false,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageKeyWords: { ...prevState.requestStageKeyWords, value },
        }));
      },
    },
    requestStageName: {
      value: '',
      isValid: false,
      required: true,
      title: 'Название этапа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageName: { ...prevState.requestStageName, value },
        }));
      },
    },
    requestStageStartDate: {
      value: '',
      isValid: true,
      required: true,
      title: 'Дата начала этапа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageStartDate: { ...prevState.requestStageStartDate, value },
        }));
      },
    },
    requestStageEndDate: {
      value: '',
      isValid: true,
      required: true,
      title: 'Дата окончания этапа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageEndDate: { ...prevState.requestStageEndDate, value },
        }));
      },
    },
    requestStagePlanAmount: {
      value: '',
      isValid: true,
      required: false,
      title: 'Плановый объём средств на этап, руб',
      tooltipText: 'Сумма вычисляется автоматически на  основе данных сметы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStagePlanAmount: { ...prevState.requestStagePlanAmount, value },
        }));
      },
    },
    requestStageAnnotation: {
      value: '',
      isValid: true,
      required: false,
      title: 'Аннотация работ (по этапу)',
      tooltipText:
        // eslint-disable-next-line max-len
        'В краткой свободной форме отражаются планируемые результаты работы, основные ожидаемые характеристики и параметры объекта исследования или разработки. Текст должен быть кратким и точным, не должен содержать сложных формул, таблиц, рисунков и аббревиатур без их расшифровки (кроме общепринятых), а также сокращённых слов (кроме общепринятых). Объем – до 1300 знаков с пробелами',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageAnnotation: { ...prevState.requestStageAnnotation, value },
        }));
      },
    },
    requestStageGoal: {
      value: '',
      isValid: true,
      required: false,
      title: 'Цель этапа НИР',
      tooltipText: `Цель исследования должна быть измеряема и достижима исключительно в ходе исследования`,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageGoal: { ...prevState.requestStageGoal, value },
        }));
      },
    },
    requestStageWorkPlan: {
      value: '',
      isValid: true,
      required: false,
      title: 'План работ на этап (задачи)',
      tooltipText:
        // eslint-disable-next-line max-len
        'Формулируйте задачи с учётом того, что в дальнейшем они станут основными главами в содержании отчёта о научно-исследовательской работе по ГОСТ.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageWorkPlan: { ...prevState.requestStageWorkPlan, value },
        }));
      },
    },
    requestStageExpectedResults: {
      value: '',
      isValid: true,
      required: false,
      title: 'Ожидаемые результаты по этапу',
      tooltipText:
        // eslint-disable-next-line max-len
        'Формулируйте ожидаемые результаты с учётом поставленных задач. Результаты должны предусматривать их состав, содержание, предназначение (роль в общем результате)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageExpectedResults: { ...prevState.requestStageExpectedResults, value },
        }));
      },
    },
    requestStageExpectedResultsWorth: {
      value: '',
      isValid: true,
      required: false,
      title: 'Научная, научно-техническая и практическая ценность ожидаемых результатов этапа НИР',
      tooltipText:
        'Данное поле обязательно для  фундаментальных исследований\n' +
        // eslint-disable-next-line max-len
        'Научная и научно-техническая ценность ожидаемых результатов НИР: определяется вкладом в ту или иную область научного знания.\n',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageExpectedResultsWorth: { ...prevState.requestStageExpectedResultsWorth, value },
        }));
      },
    },
    requestStageExpectedResultsSuppose: {
      value: '',
      isValid: true,
      required: false,
      title: 'Предполагаемое использование результатов (продукции) (для прикладных работ)',
      tooltipText:
        // eslint-disable-next-line max-len
        'Поле обязательно для  прикладных работ. Кратко опишите,  каким образом на практике будут использоваться  результаты (продукция) этапа.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageExpectedResultsSuppose: { ...prevState.requestStageExpectedResultsSuppose, value },
        }));
      },
    },
    requestStageExpectedResultsEducationalSuppose: {
      value: '',
      isValid: true,
      required: false,
      title: 'Предполагаемое использование результатов работы в учебном процессе',
      tooltipText:
        // eslint-disable-next-line max-len
        'Опишите область применения научных результатов в учебном процессе (например: использование при написании методических материалов..., в учебный процесс..., в курсовых и дипломных работах, внедрение в систему дополнительного образования для повышения квалификации....',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageExpectedResultsEducationalSuppose: { ...prevState.requestStageExpectedResultsEducationalSuppose, value },
        }));
      },
    },
    isRequestStageHasPatentResearch: {
      value: false,
      isValid: true,
      required: false,
      title: 'Планируется охрана РИД',
      tooltipText:
        // eslint-disable-next-line max-len
        'Отметьте этот пункт если планируется  возникновение результатов интеллектуальной деятельности, подлежащих регистрации патента или свидетельства, или ноу-хау.',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isRequestStageHasPatentResearch: {
            ...prevState.isRequestStageHasPatentResearch,
            value: !prevState.isRequestStageHasPatentResearch.value,
          },
        }));
      },
    },
    requestStageCollaborations: {
      value: '',
      isValid: true,
      required: false,
      title: 'Участие в международных коллаборациях',
      tooltipText: 'Укажите наименование коллаборации; форму участия; наименование организации участника; страна; город',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageCollaborations: { ...prevState.requestStageCollaborations, value },
        }));
      },
    },
    interdisciplinaryAproachJustification: {
      value: '',
      isValid: true,
      required: false,
      title: 'Обоснование междисциплинарного подхода',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          interdisciplinaryAproachJustification: { ...prevState.interdisciplinaryAproachJustification, value },
        }));
      },
    },
    requestStageRAN: {
      value: '',
      isValid: true,
      required: false,
      title: 'Взаимодействие с РАН',
      tooltipText: 'Укажите полное наименование организации РАН с указанием города; ФИО исполнителя проекта из организации РАН',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageRAN: { ...prevState.requestStageRAN, value },
        }));
      },
    },
    requestStagePartnerEnterprises: {
      value: '',
      isValid: true,
      required: false,
      title: 'Взаимодействие с реальным сектором экономики',
      tooltipText:
        // eslint-disable-next-line max-len
        'Укажите организации-промпартнёры (полное наименование организации, страна, город), заинтересованные в результатах проекта и  форму взаимодействия.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStagePartnerEnterprises: { ...prevState.requestStagePartnerEnterprises, value },
        }));
      },
    },
    requestStageUniversityEffect: {
      value: '',
      isValid: true,
      required: false,
      title: 'Эффект на уровне университета',
      tooltipText: 'Достигнутый от реализации этапа эффект на уровне университета',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageUniversityEffect: { ...prevState.requestStageUniversityEffect, value },
        }));
      },
    },
    requestStageRegionalEffect: {
      value: '',
      isValid: true,
      required: false,
      title: 'Эффект на уровне региональном',
      tooltipText: 'Достигнутый от реализации этапа эффект на региональном и (или) отраслевом уровне',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageRegionalEffect: { ...prevState.requestStageRegionalEffect, value },
        }));
      },
    },
    requestStageNationalEffect: {
      value: '',
      isValid: true,
      required: false,
      title: 'Эффект на уровне национальном',
      tooltipText: 'Достигнутый от реализации этапа эффект на национальном уровне',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageNationalEffect: { ...prevState.requestStageNationalEffect, value },
        }));
      },
    },
    requestStageAvailableScientificGroundwork: {
      value: '',
      isValid: true,
      required: false,
      title: 'Имеющийся научный задел',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageAvailableScientificGroundwork: { ...prevState.requestStageAvailableScientificGroundwork, value },
        }));
      },
    },
    requestStageAdditionalInformation: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дополнительная информация (описание работ)',
      tooltipText:
        // eslint-disable-next-line max-len
        'Это поле необязательно для заполнения предназначено для дополнительной информации о проекте, в рамках поставленных задач',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestStageAdditionalInformation: { ...prevState.requestStageAdditionalInformation, value },
        }));
      },
    },
  });

  const { methods: getContestRequest } = BackendAPI.useBackendAPI('GetContestRequest', {
    onSuccessfullCall: ({ data }) => {
      setContestRequest(data);
      const fields: Array<keyof ContestRequest> = [
        'requestStageUniversityEffect',
        'requestStageRegionalEffect',
        'requestStageNationalEffect',
        'requestStageAvailableScientificGroundwork',
        'requestStageAdditionalInformation',
        'requestStagePreviousYearAnnotation',
        'requestStageKeyWords',
        'requestStageName',
        'requestStageStartDate',
        'requestStageEndDate',
        'requestStagePlanAmount',
        'requestStageAnnotation',
        'requestStageGoal',
        'requestStageWorkPlan',
        'requestStageExpectedResults',
        'requestStageExpectedResultsWorth',
        'requestStageExpectedResultsSuppose',
        'requestStageExpectedResultsEducationalSuppose',
        'requestStageCollaborations',
        'interdisciplinaryAproachJustification',
        'requestStageRAN',
        'requestStagePartnerEnterprises',
        'isRequestStageHasPatentResearch',
      ];
      fields.forEach(key => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            value: data[key],
          },
        }));
      });
      setRequestStagePerformers(data.requestStagePerformers);
      if (!data?.id && projectId && initialRequestType === ContestRequestType.ACTUALIZATION) {
        if (data.project?.stages?.length === 1) {
          formFields.requestStageName.onChange(data.project.stages[0].name || '');
          formFields.requestStageStartDate.onChange(data.project.stages[0].startDate || '');
          formFields.requestStageEndDate.onChange(data.project.stages[0].endDate || '');
        } else if ((data.project?.stages?.length || 0) > 1) setIsStageModalOpen(true);
      }
    },
  });

  const { methods: saveContestRequest } = BackendAPI.useBackendAPI('SaveContestRequest');

  useLayoutEffect(() => {
    if (initialId) {
      getContestRequest.callAPI({ requestId: initialId });
    } else if (projectId && initialRequestType) {
      getContestRequest.callAPI({ projectId, requestType: initialRequestType });
    }
    // eslint-disable-next-line
  }, []);

  const makeChangeHandler = (key: keyof ContestRequest) => (value: ValueOf<ContestRequest>) => {
    setContestRequest(prevNir => ({ ...(prevNir || getMockContestRequest()), [key]: value }));
  };

  const performersChangeHandler = (val: Performer[]) => {
    setContestRequest(prev => ({ ...prev!, requestStagePerformers: val }));
    setRequestStagePerformers(val);
  };

  const onSubmit = useCallback(
    (needClose: boolean, onSuccessCb?: (id: string | null) => void) => {
      const validationInfo = validate(contestRequest || null, formFields);
      const errorMessages = [...validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage)];

      if (errorMessages.length) {
        showErrorsMessages(errorMessages);
        return;
      }
      saveContestRequest.callAPI(
        {
          id: contestRequest?.id || initialId || null,
          approvements: contestRequest?.approvements || [],
          code: contestRequest?.code || '',
          indicators: contestRequest?.indicators || [],
          indices: contestRequest?.indices || [],
          isRequestStageHasPatentResearch: formFields.isRequestStageHasPatentResearch.value,
          requestStageUniversityEffect: formFields.requestStageUniversityEffect.value || '',
          requestStageRegionalEffect: formFields.requestStageRegionalEffect.value || '',
          requestStageNationalEffect: formFields.requestStageNationalEffect.value || '',
          requestStageAvailableScientificGroundwork: formFields.requestStageAvailableScientificGroundwork.value || '',
          requestStageAdditionalInformation: formFields.requestStageAdditionalInformation.value || '',
          okveds: contestRequest?.okveds || [],
          project: contestRequest?.project,
          requestStageAnnotation: formFields.requestStageAnnotation.value,
          requestStageCollaborations: formFields.requestStageCollaborations.value,
          requestStageCriticalTechnologies: contestRequest?.requestStageCriticalTechnologies || [],
          requestStageEndDate: formFields.requestStageEndDate.value,
          requestStageEstimatePositions: contestRequest?.requestStageEstimatePositions || [],
          requestStageExpectedResults: formFields.requestStageExpectedResults.value,
          requestStageExpectedResultsEducationalSuppose: formFields.requestStageExpectedResultsEducationalSuppose.value,
          requestStageExpectedResultsSuppose: formFields.requestStageExpectedResultsSuppose.value,
          requestStageExpectedResultsWorth: formFields.requestStageExpectedResultsWorth.value,
          requestStageGoal: formFields.requestStageGoal.value,
          requestStageIndicators: contestRequest?.requestStageIndicators || [],
          requestStageIndices: contestRequest?.requestStageIndices || [],
          requestStageKeyWords: formFields.requestStageKeyWords.value || '',
          requestStageKindWork: contestRequest?.requestStageKindWork,
          requestStageName: formFields.requestStageName.value,
          requestStageNtrStrategies: contestRequest?.requestStageNtrStrategies || [],
          requestStageNumber: contestRequest?.requestStageNumber || 0,
          requestStagePartnerEnterprises: formFields.requestStagePartnerEnterprises.value,
          requestStagePerformers,
          requestStageOecds: contestRequest?.requestStageOecds || [],
          requestStagePlanAmount: formFields.requestStagePlanAmount.value,
          requestStagePnis: contestRequest?.requestStagePnis || [],
          requestStagePnmitrs: contestRequest?.requestStagePnmitrs || [],
          requestStagePnrs: contestRequest?.requestStagePnrs || [],
          requestStagePreviousYearAnnotation: formFields.requestStagePreviousYearAnnotation.value,
          interdisciplinaryAproachJustification: formFields.interdisciplinaryAproachJustification.value,
          requestStageRAN: formFields.requestStageRAN.value,
          requestStageStartDate: formFields.requestStageStartDate.value,
          requestStageWorkPlan: formFields.requestStageWorkPlan.value,
          status: contestRequest?.status,
          documents: contestRequest?.documents || [],
          type: { value: initialRequestType || '', label: '' },
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
            if (onSuccessCb) onSuccessCb(data.id || null);
            else if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data?.id) {
              updateWorkModeAfterSaveAndContinue();
              getContestRequest.callAPI({ requestId: data.id });
              tableStreams.reloadTable.push();
            }
          },
        },
      );
    },
    [
      contestRequest,
      formFields,
      saveContestRequest,
      initialId,
      requestStagePerformers,
      initialRequestType,
      tableStreams.reloadTable,
      onClose,
      updateWorkModeAfterSaveAndContinue,
      getContestRequest,
    ],
  );

  const handlePersonInfoClick = useCallback(() => {
    setIsOpenPersonModal(true);
  }, [setIsOpenPersonModal]);

  const handleCopyPerformers = useCallback(() => {
    if (contestRequest?.project?.performers) {
      const preparedPerformers = contestRequest.project.performers
        .map<Performer | null>(performer => {
          const period = performer.jobPeriods.reduce((a, b) =>
            parse(a.startDate, formatStr, new Date()) > parse(b.startDate, formatStr, new Date()) ? a : b,
          );
          return {
            ...performer,
            id: null,
            role: period.role,
            ...clonePersonHistory(period),
          };
        })
        .filter(x => x) as Performer[];
      setRequestStagePerformers(preparedPerformers);
    }
  }, [contestRequest?.project?.performers]);

  const performersButtons = useMemo<ExtraToolbarButton<any>[]>(
    () => [
      {
        icons: buttonIcons.clone,
        title: 'Скопировать всех исполнителей из проекта',
        onClick: handleCopyPerformers,
      },
    ],
    [handleCopyPerformers],
  );

  const { methods: sendMessageAPI } = BackendAPI.useBackendAPI('PushContestRequestMessage');

  const feedlineListStreams = useStreamsByApiID(globalStreams, 'GetContestRequestFeedlineList');

  const reloadFeedlineList = useCallback(() => {
    feedlineListStreams.reloadTable.push();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatJobOrEducation = (performer: Performer) => {
    if (performer.job) {
      return formatJob(performer.job);
    }

    return performer.education ? formatEducation(performer.education) : '';
  };

  const sendMessage = useCallback(
    (message: string) => {
      if (!contestRequest || !contestRequest.id) {
        return;
      }
      sendMessageAPI.callAPI(
        { contestRequestId: contestRequest.id, message },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщние успешно добавлено', theme: 'success' });
            reloadFeedlineList();
          },
        },
      );
    },
    [contestRequest, reloadFeedlineList, sendMessageAPI],
  );

  const { methods: getContestRequestAPI } = BackendAPI.useBackendAPI('GetContestRequest');

  const reloadContestRequest = useCallback(
    (contest: ContestRequest) => {
      getContestRequestAPI.callAPI(
        { requestId: contest.id ?? '' },
        {
          onSuccessfullCall: ({ data }) => {
            setContestRequest(data);
          },
        },
      );
    },
    [getContestRequestAPI],
  );

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeContestRequestStatus');

  const changeStatus = useCallback(
    (message: string, status: ContestRequestStatus, id: string | null) => {
      if (id) {
        changeStatusAPI.callAPI(
          { message, ids: [id], status },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Статус успешно изменен', theme: 'success' });
              tableStreams.reloadTable.push();
              onClose();
            },
          },
        );
      }
    },
    [changeStatusAPI, onClose, tableStreams.reloadTable],
  );

  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddContestRequestApprovement');

  const addApprovement = useCallback(
    (message: string, status: string) => {
      if (contestRequest?.id) {
        addApprovementAPI.callAPI(
          { message, contestRequestId: contestRequest.id, status },
          {
            onSuccessfullCall: () => {
              reloadContestRequest(contestRequest);
              showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
              reloadFeedlineList();
            },
          },
        );
      }
    },
    [addApprovementAPI, contestRequest, reloadContestRequest, reloadFeedlineList],
  );

  const selectStage = useCallback(
    (index: number) => {
      const stage = contestRequest?.project?.stages[index];
      formFields.requestStageName.onChange(stage?.name || '');
      formFields.requestStageStartDate.onChange(stage?.startDate || '');
      formFields.requestStageEndDate.onChange(stage?.endDate || '');
      setIsStageModalOpen(false);
    },
    [
      contestRequest?.project?.stages,
      formFields.requestStageEndDate,
      formFields.requestStageName,
      formFields.requestStageStartDate,
    ],
  );

  const { enumMap } = useAppDataContext();

  const roles = enumMap.ProjectScientistRole.values;

  const performersListEditColumns = [
    { label: 'Роль', formatValue: (row: Performer) => roles.find(role => role.value === row.role?.value)?.label ?? '' },
    { label: 'ФИО', formatValue: (row: Performer) => row?.person?.fullName || '' },
    { label: 'Дата рождения', formatValue: (row: Performer) => row?.person?.scientist?.dateBirth || '' },
    {
      label: 'Степень, звание',
      formatValue: (row: Performer) =>
        [
          row?.degree?.refDegree?.label || '',
          row?.person?.ranks[0]?.refRank?.label || '',
          row?.person?.academicRanks[0]?.refAcademicRank?.label || '',
        ]
          .filter(x => x)
          .join(', '),
    },
    { label: 'Место работы/обучения', formatValue: formatJobOrEducation },
    { label: 'Гражданство', formatValue: (row: Performer) => row.citizenship?.label ?? '' },
    { label: 'Индексы Хирша', formatValue: formatIndexHirsh },
  ];

  return {
    requestId: contestRequest?.id || initialId || null,
    requestType: (contestRequest?.type?.value || initialRequestType) as ContestRequestType,
    workMode,
    contestRequest,
    formFields,
    makeChangeHandler,
    onSubmit,
    requestStagePerformers,
    performersChangeHandler,
    setPersonId,
    setIsOpenPersonModal,
    handlePersonInfoClick,
    performersButtons,
    sendMessage,
    addApprovement,
    changeStatus,
    personId,
    isOpenPersonModal,
    performersListEditColumns,
    isStageModalOpen,
    setIsStageModalOpen,
    selectStage,
  };
}
