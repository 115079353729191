import React from 'react';

import { DataGrid } from 'features/Table';
import { GetPersonalConferenceEventList } from 'features/Table/specifications';
import { EventTypes } from 'utils/Enums/EventTypes';

export function EventConferences() {
  return (
    <DataGrid specification={GetPersonalConferenceEventList({ eventType: EventTypes.EVENT_CONFERENCE.code })} hasPersonalMode />
  );
}
