import { FundCard } from 'types/models';
import { getMaybeField } from '../commonConverters';

export function convertFundCardToXML(fc: FundCard) {
  return {
    _attr: fc.id ? { id: fc.id } : {},
    ...getMaybeField('Name', fc.name),
    ...getMaybeField('Note', fc.note),
  };
}
