import * as R from 'ramda';

import { ResponseData } from 'types/models';

import { convertDocument, convertItem, convertMaybeArray, convertReference, getId, getText } from '../commonConverters';

import { convertProjectScientist } from 'services/BackendAPI/configurations/projectAssignments/converters';
import { convertServerPerson } from 'services/BackendAPI/configurations/author/converters';
import { Settings } from 'types/models/common';
import { Member } from 'features/SelectPerson';
import { PurchaseRequest, PurchaseRequestMessage, PurchaseRequestProject, Unit } from 'types/models/PurchaseRequest';
import { sortByPosition } from 'utils/Helpers/sortByPosition';

export function convertPurchaseRequestProject(prp: any): PurchaseRequestProject {
  return {
    id: getId(prp),
    name: getText(prp.Name),
    number: getText(prp.Number),
  };
}
export function convertPurchaseRequest(pr: any, settings: Settings): PurchaseRequest {
  const documents = pr.Documents?.Document || [];

  return {
    id: getId(pr),
    name: getText(pr.Name),
    sendDate: getText(pr.SendDate),
    status: convertItem(pr.Status),
    applicant: convertProjectScientist(pr.Applicant),
    contractKind: convertItem(pr.ContractKind),
    deliveryAddress: getText(pr.DeliveryAddress),
    deliveryDate: getText(pr.DeliveryDate),
    deliveryNote: getText(pr.DeliveryNote),
    department: convertReference(pr.Department),
    ...(pr.FinanceResponsible ? { financeResponsible: convertPurchaseMember(pr.FinanceResponsible, settings) } : {}),
    limitPrice: parseFloat(getText(pr.LimitPrice)),
    note: getText(pr.Note),
    number: getText(pr.Number),
    project: convertPurchaseRequestProject(pr.Project),
    ...(pr.RequestResponsible ? { requestResponsible: convertPurchaseMember(pr.RequestResponsible, settings) } : {}),
    units: sortByPosition(convertMaybeArray(pr.Units?.Unit || [], convertUnit)),
    documents: convertMaybeArray(documents, R.partial(convertDocument, ['local'])),
  };
}

function convertUnit(u: any): Unit {
  return {
    type: u.Type ? convertItem(u.Type) : null,
    name: getText(u.Name),
    quantity: Number(getText(u.Quantity)),
    unit: getText(u.Unit),
    perUnitPrice: parseFloat(getText(u.PerUnitPrice)),
    isNds: getText(u.IsNds) === 'true',
    position: u.Position ? parseInt(getText(u.Position), 10) : 0,
  };
}

export function convertPurchaseMember(m: any, settings: Settings): Member {
  const person = convertServerPerson(m, settings);
  return {
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    job: null,
    rank: null,
    person,
  };
}
export function convertPurchaseRequestMessage(pam: any): PurchaseRequestMessage {
  return {
    id: pam?._attributes?.id ?? null,
    message: getText(pam.Message),
  };
}
export function convertChangeStatusXMLElementToOutput(response: any): ResponseData {
  return {
    success: response.Response.PurchaseRequest._attributes.success === 'true',
    message:
      response.Response.PurchaseRequest._attributes.unavailableChangeStatusReason ||
      response.Response.PurchaseRequest._attributes.serverErrorMessage ||
      '',
    id: response.Response.PurchaseRequest?._attributes.id,
  };
}
