import React from 'react';

import { Tabs, Tab } from 'components';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { useController } from './controller';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function ScientistDataForm({ onClose }: Props) {
  const { relatedTableState, SUCH_LIKE_PUBLICATION_ID } = useController();

  const workMode = 'viewMode';

  return (
    <>
      <SuchLikePublication mode="edit" handleCloseOuterForm={onClose} componentId={SUCH_LIKE_PUBLICATION_ID} />
      <Tabs>
        <Tab title="О персоне">Контент в разработке</Tab>
        <Tab title="Публикации">
          Заглушка
          {false && <TabsContent.PublicationList relatedTableState={relatedTableState} workMode={workMode} />}
        </Tab>
        <Tab title="Индекс Хирша">
          <TabsContent.HirshIndexesList relatedTableState={relatedTableState} workMode={workMode} />
        </Tab>
        <Tab title="Аффилиации">Контент в разработке</Tab>
      </Tabs>
    </>
  );
}

export const Component = React.memo(ScientistDataForm);
