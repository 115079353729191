import React from 'react';
import block from 'bem-cn';

import { HOURS_ARRAY, MINUTES_ARRAY } from './helpers';
import useController from './controller';

import './style.scss';

type Props = {
  selectedHour?: string;
  canResetValue?: boolean;
  selectedMinute?: string;
  setHours: (nextHours?: string) => void;
  setMinutes: (nextMinutes?: string) => void;
};

const b = block('time');

export const Timepicker = ({ selectedHour, canResetValue, selectedMinute, setMinutes, setHours }: Props) => {
  const { selectedHourRef, selectedMinuteRef } = useController();

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <h3 className={b('list-title')}>Часы:</h3>
        <div className={`${b('list-wrapper')} scroll-shadows`}>
          <ul className={b('list', { hours: true })}>
            {HOURS_ARRAY.map(hour => {
              const isSelectedHour = hour === selectedHour;
              const nextValue = isSelectedHour && canResetValue ? undefined : hour;
              return (
                <li key={hour} className={b('item')}>
                  <button
                    ref={hour === selectedHour ? selectedHourRef : null}
                    onClick={() => setHours(nextValue)}
                    className={b('button', { selected: hour === selectedHour })}
                  >
                    {hour}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={b('separator')}>:</div>
      <div className={b('wrapper')}>
        <h3 className={b('list-title')}>Минуты:</h3>
        <div className={`${b('list-wrapper')} scroll-shadows`}>
          <ul className={b('list', { minutes: true })}>
            {MINUTES_ARRAY.map(minute => {
              const isSelectedMinute = minute === selectedMinute;
              const nextValue = isSelectedMinute && canResetValue ? undefined : minute;
              return (
                <li key={minute} className={b('item')}>
                  <button
                    ref={minute === selectedMinute ? selectedMinuteRef : null}
                    onClick={() => setMinutes(nextValue)}
                    className={b('button', { selected: minute === selectedMinute })}
                  >
                    {minute}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
