import React, { useMemo } from 'react';

import { SectionTitle, Tab, Tabs } from 'components';

import { MipPartner } from 'types/models';
import { ValueOf } from 'types/helpers';

import { CodesFields } from 'features/EditPartner/Tabs/AboutOrganization/CodesFields/CodesFields';
import { ChildPartners } from 'features/EditPartner/Tabs/AboutOrganization/ChildPartners/ChildPartners';
import { AddressAndDetails } from 'features/EditPartner/Tabs/AboutOrganization/AddressAndDetails/AddressAndDetails';
import { PartnershipTypes } from 'features/EditPartner/Tabs/AboutOrganization/PartnershipTypes/PartnershipTypes';
import { CitationSystems } from 'features/EditPartner/Tabs/AboutOrganization/CitationSystems/CitationSystems';
import { UpperFields } from './UpperFields/UpperFields';

type Props = {
  mipPartner: MipPartner.MipPartner | null;
  makeChangeHandler: (key: keyof MipPartner.MipPartner) => (value: ValueOf<MipPartner.MipPartner>) => void;
  setMipPartner: React.Dispatch<MipPartner.MipPartner | null>;
  disabled: boolean;
};

export function AboutOrganization(props: Props) {
  const { setMipPartner, makeChangeHandler, mipPartner, disabled } = props;

  const variantRelationChilds = useMemo(() => (mipPartner?.childs || []).filter(x => x.relation?.value === 'VARIANT') ?? [], [
    mipPartner?.childs,
  ]);

  const otherRelationChilds = useMemo(() => (mipPartner?.childs || []).filter(x => x.relation?.value !== 'VARIANT') ?? [], [
    mipPartner?.childs,
  ]);

  return (
    <>
      <UpperFields
        disabled={disabled}
        mipPartner={mipPartner}
        makeChangeHandler={makeChangeHandler}
        setMipPartner={setMipPartner}
      />

      <Tabs>
        <Tab title="Коды организации">
          <CodesFields disabled={disabled} partner={mipPartner} makeChangeHandler={makeChangeHandler} />
        </Tab>
        <Tab title="Адрес и контакты">
          <AddressAndDetails disabled={disabled} partner={mipPartner} makeChangeHandler={makeChangeHandler} />
        </Tab>
        <Tab title="Идентификаторы в базах цитирования">
          <CitationSystems
            citationSystems={mipPartner?.citationSystems ?? []}
            setCitationSystems={makeChangeHandler('citationSystems')}
            disabled={disabled}
          />
        </Tab>
        {!mipPartner?.parent && (
          <Tab title="Связи с другими организациями">
            <SectionTitle title="Список дочерних организаций и филиалов" />
            <ChildPartners
              disabled={disabled}
              childsPartners={otherRelationChilds}
              allChildsPartners={mipPartner?.childs ?? []}
              setChildPartners={otherChilds => makeChangeHandler('childs')([...otherChilds, ...variantRelationChilds])}
              mainPartner={mipPartner}
            />
          </Tab>
        )}
        {!mipPartner?.parent && (
          <Tab title="Варианты наименования">
            <SectionTitle title="Список вариантов наименования организации-партнёра в базе данных" />
            <ChildPartners
              isOnlyVariant
              disabled={disabled}
              childsPartners={variantRelationChilds}
              allChildsPartners={mipPartner?.childs ?? []}
              setChildPartners={variantChilds => makeChangeHandler('childs')([...otherRelationChilds, ...variantChilds])}
              mainPartner={mipPartner}
            />
          </Tab>
        )}
        <Tab title="Типы партнёрства" isRequired>
          <SectionTitle title="История сотрудничества организации и университета" />

          <PartnershipTypes
            disabled={disabled}
            partnerships={mipPartner?.partnershipTypes ?? []}
            setPartnerships={makeChangeHandler('partnershipTypes')}
          />
        </Tab>
      </Tabs>
    </>
  );
}
