import React from 'react';

import { Table as T } from 'types/models';

type RequestData = {
  nirRequestId: string;
};

export function GetNirRequestFeedlineList(requestData: RequestData): T.Specification<object, RequestData> {
  return {
    apiID: 'GetNirRequestFeedlineList',
    header: {
      thirdLevel: {
        LeftPanel: () => <div />,
        withTemplatesPanel: true,
      },
    },
    requestData,
  };
}
