import React, { memo } from 'react';

import { ConfirmPopup, Toolbar } from 'components';
import { Table } from 'types/models';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { BuildReportPopup } from 'features/BuildReportPopup';

import useController from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({ tableState }: Props) => {
  const {
    isEditFormOpen,
    isConfirmDeleteOpen,
    isViewFormOpen,
    isAddFormOpen,
    buttons,
    isWarningPopupOpen,
    participationInfoText,
    isApprovePopupOpen,
    isEditDepartmentsOpen,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    handleCloseWarningPopup,
    handleAttachParticipation,
    handleCloseApprovePopup,
    handleCloseEditDepartments,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    afterSubmitEditDepartments,
    afterSubmitApproveParticipation,
    isReportOpen,
    onReportClose,
    currentReport,
    settings,
  } = useController({
    tableState,
  });
  return (
    <>
      <EditParticipationDepartments
        type={ParticipationTypes.PARTICIPATION_CONCERT.code}
        isOpen={isEditDepartmentsOpen}
        onClose={handleCloseEditDepartments}
        afterSubmit={afterSubmitEditDepartments}
        id={tableState.selectedRows[0]?.id || ''}
      />
      <ApproveParticipation
        type={ParticipationTypes.PARTICIPATION_CONCERT.code}
        isOpen={isApprovePopupOpen}
        onClose={handleCloseApprovePopup}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitApproveParticipation}
      />
      <ConfirmPopup
        isOpen={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        title="Подтверждение удаления"
        text="Вы действительно хотите удалить выбранное участие?"
        icon="warning"
        okButtonText="Удалить"
        resetButtonText="Отмена"
        onConfirm={handleDeleteParticipation}
      />
      <ConfirmPopup
        isOpen={isWarningPopupOpen}
        onClose={handleCloseWarningPopup}
        title="Привязка к подразделению"
        text={participationInfoText}
        icon="warning"
        okButtonText="Ок"
        resetButtonText="Отмена"
        onConfirm={handleAttachParticipation}
      />
      {isEditFormOpen && (
        <modalTemplates.ConcertParticipationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}
      {isViewFormOpen && (
        <modalTemplates.ConcertParticipationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.ConcertParticipationAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        department={settings?.userDepartment}
      />
      <Toolbar buttons={buttons} />
    </>
  );
};

export const Component = memo(LeftPanelForThirdLevel);
