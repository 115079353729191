import React, { useState, useEffect, useCallback } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import publicationTypes, { PublicationType } from 'types/models/Publication/publicationTypes';
import CompilationAddTemplateType from './CompilationAddTemplateType';

export type SelectProp = {
  id: string;
  label: string;
  code: string;
  text: string;
};

type Props = { eventId?: string; compilationTypeCode: string } & F.TemplateProps;

const CompilationAddTemplate: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, relatedTableState, id, eventId, compilationTypeCode } = props;
  const [isCompilationAddTemplateTypePopupOpen, setIsCompilationAddTemplateTypePopupOpen] = useState<boolean>(true);
  const [publicationType, setPublicationType] = useState<PublicationType | null>(null);
  const [electronicPublicationType, setElectronicPublicationType] = useState<SelectProp>({
    id: '1',
    label: 'Сетевой ресурс',
    text: 'Публикация размещена в сети Интернет',
    code: 'WEB',
  });
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [title, setTitle] = useState<string | JSX.Element>('');

  const onConfirm = useCallback(() => {
    setIsCompilationAddTemplateTypePopupOpen(false);
  }, []);

  const look: F.CompilationFormLook = {
    id,
    apiID: 'GetPublication',
    template: 'CompilationForm',
    type: publicationType?.code,
    initialTypeEdition: 'OTHER_UNIVERSITY',
    isElectronic,
    electronicType: electronicPublicationType.code,
    relatedTableState,
    eventId,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) {
      const initialPublicationType = publicationTypes.find(
        publicationTypeItem => publicationTypeItem.code === compilationTypeCode,
      );
      if (initialPublicationType) {
        setPublicationType(initialPublicationType);
      }
      setElectronicPublicationType({
        id: '1',
        label: 'Сетевой ресурс',
        text: 'Публикация размещена в сети Интернет',
        code: 'WEB',
      });
      setIsElectronic(false);
      setIsCompilationAddTemplateTypePopupOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {isCompilationAddTemplateTypePopupOpen ? (
        <Modal isOpen={isOpen} onClose={onClose} title="Добавление сборника" size="medium">
          <CompilationAddTemplateType
            onClose={onClose}
            onConfirm={onConfirm}
            publicationType={publicationType}
            electronicPublicationType={electronicPublicationType}
            setPublicationType={setPublicationType}
            setElectronicPublicationType={setElectronicPublicationType}
            isElectronic={isElectronic}
            setIsElectronic={setIsElectronic}
            compilationTypeCode={compilationTypeCode}
          />
        </Modal>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
          <Form look={look} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};
export const Component = React.memo(CompilationAddTemplate);
