import { Payment } from 'types/models';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

export function usePermissions(event: Payment.EventType, status: AuthorPaymentRequestStatus) {
  const addRequestPermissionNameMap: Record<Payment.EventType, string> = {
    ARTICLE: 'IPArticleRequestsAdd',
    CONFERENCE: 'IPConferenceRequestsAdd',
    MEDAL: 'IPExhibitionAwardRequestsAdd',
    MONOGRAPH: 'IPMonographRequestsAdd',
    TEXTBOOK: 'IPTextBookRequestsAdd',
  };

  const editFundRequestPermissionNameMap: Record<Payment.EventType, string> = {
    ARTICLE: 'IPArticleRequestsFundManualEdit',
    CONFERENCE: 'IPConferenceRequestsFundManualEdit',
    MEDAL: 'IPExhibitionAwardRequestsFundManualEdit',
    MONOGRAPH: 'IPMonographRequestsFundManualEdit',
    TEXTBOOK: 'IPTextBookRequestsFundManualEdit',
  };

  const toAgreementPermissionNameMap: Record<Payment.EventType, string> = {
    MEDAL: 'IPExhibitionAwardRequestsStatusChangeToAgreement',
    CONFERENCE: 'IPConferenceRequestsStatusChangeToAgreement',
    MONOGRAPH: 'IPMonographRequestsStatusChangeToAgreement',
    ARTICLE: 'IPArticleRequestsStatusChangeToAgreement',
    TEXTBOOK: 'IPTextBookRequestsStatusChangeToAgreement',
  };

  const toRevisionPermissionNameMap: Record<Payment.EventType, string> = {
    MEDAL: 'IPExhibitionAwardRequestsStatusChangeToRevision',
    CONFERENCE: 'IPConferenceRequestsStatusChangeToRevision',
    MONOGRAPH: 'IPMonographRequestsStatusChangeToRevision',
    ARTICLE: 'IPArticleRequestsStatusChangeToRevision',
    TEXTBOOK: 'IPTextBookRequestsStatusChangeToRevision',
  };

  const toPaidPermissionNameMap: Record<Payment.EventType, string> = {
    MEDAL: 'IPExhibitionAwardRequestsStatusChangeToPaid',
    CONFERENCE: 'IPConferenceRequestsStatusChangeToPaid',
    MONOGRAPH: 'IPMonographRequestsStatusChangeToPaid',
    ARTICLE: 'IPArticleRequestsStatusChangeToPaid',
    TEXTBOOK: 'IPTextBookRequestsStatusChangeToPaid',
  };

  const toPostponePermissionNameMap: Record<Payment.EventType, string> = {
    MEDAL: 'IPExhibitionAwardRequestsStatusChangeToPostpone',
    CONFERENCE: 'IPConferenceRequestsStatusChangeToPostpone',
    MONOGRAPH: 'IPMonographRequestsStatusChangeToPostpone',
    ARTICLE: 'IPArticleRequestsStatusChangeToPostpone',
    TEXTBOOK: 'IPTextBookRequestsStatusChangeToPostpone',
  };

  const toRejectedPermissionNameMap: Record<Payment.EventType, string> = {
    MEDAL: 'IPExhibitionAwardRequestsStatusChangeToRejected',
    CONFERENCE: 'IPConferenceRequestsStatusChangeToRejected',
    MONOGRAPH: 'IPMonographRequestsStatusChangeToRejected',
    ARTICLE: 'IPArticleRequestsStatusChangeToRejected',
    TEXTBOOK: 'IPTextBookRequestsStatusChangeToRejected',
  };

  const toPaymentPermissionNameMap: Record<Payment.EventType, string> = {
    MEDAL: 'IPExhibitionAwardRequestsStatusChangeToPaymentApproved',
    CONFERENCE: 'IPConferenceRequestsStatusChangeToPaymentApproved',
    MONOGRAPH: 'IPMonographRequestsStatusChangeToPaymentApproved',
    ARTICLE: 'IPArticleRequestsStatusChangeToPaymentApproved',
    TEXTBOOK: 'IPTextBookRequestsStatusChangeToPaymentApproved',
  };

  return {
    addRequest: addRequestPermissionNameMap[event],
    editFundRequest: editFundRequestPermissionNameMap[event],
    toAgreement: toAgreementPermissionNameMap[event],
    toRevision: toRevisionPermissionNameMap[event],
    toPaid: toPaidPermissionNameMap[event],
    toPostpone: toPostponePermissionNameMap[event],
    toRejected: toRejectedPermissionNameMap[event],
    toPayment: toPaymentPermissionNameMap[event],
    deletablePermissionName: getDeletablePermissionName(event, status),
    editablePermissionName: getEditPermission(event, status),
  };
}

function getDeletablePermissionName(event: Payment.EventType, status: AuthorPaymentRequestStatus) {
  return `IncentivePayment:NomineeRequest:${event}:Delete:${status}`;
}

function getEditPermission(event: Payment.EventType, status: AuthorPaymentRequestStatus) {
  return `IncentivePayment:NomineeRequest:${event}:Edit:${status}`;
}
