import React, { useEffect } from 'react';
import { block } from 'bem-cn';

import { FormComponent, Radio, Reference, ReferenceItem, TextDateTime } from 'components';

import { Person } from 'types/models';
import { format } from 'date-fns';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { EditableHonor, HonorType } from 'features/Form/looks/person/model';

import './style.scss';

const b = block('main-tab-honors-fields');

type Props = {
  item: EditableHonor | null;
  onChange(item: EditableHonor): void;
};

export function Fields(props: Props) {
  const { item, onChange } = props;

  useEffect(() => {
    if (item === null) {
      onChange({ type: 'degree' } as EditableHonor);
    }
  }, [item, onChange]);

  const makeChangeHandler = (fieldName: keyof EditableHonor) => (value: null | ReferenceItem | string) => {
    onChange({
      ...item!,
      [fieldName]: value,
      modificationDate: format(new Date(), formatDateTimeStr),
      creationDate: item?.creationDate || format(new Date(), formatDateTimeStr),
    });
  };

  const isDisabled = item?.isReadOnly ?? false;
  const renderByTypeHonor = () => {
    const mapRender: Record<HonorType, (honor: any) => JSX.Element> = {
      degree: (honor: Person.ScientistDegree) => (
        <FormComponent.Field isRequired label="Ученая степень">
          <Reference
            name="RefDegree"
            value={honor.refDegree ?? null}
            relationTableModalTitle='Справочник "Ученые степени"'
            onChange={makeChangeHandler('refDegree' as any)}
            disabled={isDisabled}
          />
        </FormComponent.Field>
      ),
      academicRank: (honor: Person.ScientistAcademicRank) => (
        <FormComponent.Line lineSize="padded" hasGap>
          <FormComponent.Field isRequired label="Академическое звание">
            <Reference
              name="RefAcademicRank"
              value={honor.refAcademicRank ?? null}
              relationTableModalTitle='Справочник "Академические звания"'
              onChange={makeChangeHandler('refAcademicRank' as any)}
              disabled={isDisabled}
            />
          </FormComponent.Field>
          <FormComponent.Field isRequired label="Академия">
            <Reference
              name="RefAcademy"
              value={honor.academy ?? null}
              relationTableModalTitle='Справочник "Академии"'
              onChange={makeChangeHandler('academy' as any)}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      ),
      rank: (honor: Person.ScientistRank) => (
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field isRequired label="Ученое звание">
            <Reference
              name="RefScientificRank"
              value={honor.refRank ?? null}
              relationTableModalTitle='Справочник "Ученые звания"'
              onChange={makeChangeHandler('refRank' as any)}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      ),
    };

    const render = mapRender[item?.type!];
    if (!render) {
      return null;
    }

    return render(item);
  };

  return (
    <div className={b()}>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Вид">
          <Radio
            isDisabled={isDisabled}
            value={item?.type}
            list={[
              { label: 'Ученая степень', value: 'degree' },
              { label: 'Ученое звание', value: 'rank' },
              { label: 'Академическое звание', value: 'academicRank' },
            ]}
            onChange={makeChangeHandler('type')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {renderByTypeHonor()}
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Дата получения">
          <TextDateTime value={item?.dateAssign} onChange={makeChangeHandler('dateAssign')} isDisabled={isDisabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </div>
  );
}
