import { EventTypeCode } from 'types/models/Event';
import { EventTypes } from 'utils/Enums/EventTypes';

export type EventType = {
  label: string;
  labelDeclined: string;
  code: EventTypeCode;
  template: string;
};

const eventTypes: EventType[] = [
  {
    label: EventTypes.EVENT_CONFERENCE.label,
    labelDeclined: EventTypes.EVENT_CONFERENCE.labelDeclined,
    code: EventTypes.EVENT_CONFERENCE.code,
    template: 'EventFormConference',
  },
  {
    label: EventTypes.EVENT_CONCERT.label,
    labelDeclined: EventTypes.EVENT_CONCERT.labelDeclined,
    code: EventTypes.EVENT_CONCERT.code,
    template: 'EventFormConcert',
  },
  {
    label: EventTypes.EVENT_CONTEST.label,
    labelDeclined: EventTypes.EVENT_CONTEST.labelDeclined,
    code: EventTypes.EVENT_CONTEST.code,
    template: 'EventFormContest',
  },
  {
    label: EventTypes.EVENT_EXPOSITION.label,
    labelDeclined: EventTypes.EVENT_EXPOSITION.labelDeclined,
    code: EventTypes.EVENT_EXPOSITION.code,
    template: 'EventFormExposition',
  },
];

export default eventTypes;
