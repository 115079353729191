import React, { useCallback } from 'react';

import { Reference, ReferenceItem, TextArea, TextInput, TextGridSelect, SectionTitle, FormComponent } from 'components';

import { Partner } from 'types/models';
import { ValueOf } from 'types/helpers';

import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { GetLksdEnterpriseTypeBySectorActivity } from 'features/Table/specifications';

type Props = {
  partner: Partner | null;
  makeChangeHandler: (key: keyof Partner) => (value: ValueOf<Partner>) => void;
  setPartner: React.Dispatch<Partner | null>;
  disabled: boolean;
};

export function UpperFields(props: Props) {
  const { setPartner, makeChangeHandler, partner, disabled } = props;

  const onChangePerson = useCallback(
    (member: Member) => {
      makeChangeHandler('manager')(member.person);
    },
    [makeChangeHandler],
  );

  const changeActivitySector = useCallback(
    (nextActivitySector: ReferenceItem | null) => {
      setPartner({
        ...partner!,
        activitySector: nextActivitySector,
        lksd: null,
      });
    },
    [partner, setPartner],
  );

  return (
    <>
      <SectionTitle title="Ответственные за ведение карточки организации-партнёра" />
      <FormComponent.Line>
        <FormComponent.Field
          label="Отв. менеджер"
          isRequired
          // eslint-disable-next-line
          tooltip="К ответственному менеджеру можно обращаться за дополнительной информацией по организации, например, запросить Соглашение о сотрудничестве"
        >
          <SelectPerson
            member={getMemberWithoutHistory(partner?.manager ?? null)}
            onUpdateMember={onChangePerson}
            withHistory={false}
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          isRequired
          label="Отв. подразделение"
          tooltip="Подразделение несёт ответственность за актуальность ключевых данных об организации- партнёре"
        >
          <Reference
            name="RefDepartment"
            onChange={makeChangeHandler('department')}
            value={partner?.department ?? null}
            relationTableModalTitle="Статусы организаций"
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Об организации" />
      {partner?.parent?.id && (
        <FormComponent.Line>
          <FormComponent.Field label="Головная организация">
            <div>
              {partner.parent.fullName} (ID - {partner.parent.id}. {partner.relation?.label ?? ''})
            </div>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field
          // eslint-disable-next-line max-len
          tooltip='Для иностранных организаций Вводите в формате "Сокращённое название организации на оригинальном языке (Сокращённое название на русском языке)'
          label="Краткое название"
        >
          <TextInput value={partner?.shortName ?? ''} onChange={makeChangeHandler('shortName')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field
          isRequired
          // eslint-disable-next-line max-len
          tooltip='Для международных организаций Вводите в формате "Полное название организации на оригинальном языке (Полное название на русском языке)'
          label="Полное название"
        >
          <TextArea
            settings={{ isFixed: true }}
            value={partner?.fullName ?? ''}
            onChange={makeChangeHandler('fullName')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сектор деятельности">
          <Reference
            name="RefSectorActivity"
            onChange={changeActivitySector}
            value={partner?.activitySector ?? null}
            relationTableModalTitle="Сектор деятельности"
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Тип организации">
          <TextGridSelect<ReferenceItem>
            value={partner?.lksd ?? null}
            onChange={makeChangeHandler('lksd')}
            specification={GetLksdEnterpriseTypeBySectorActivity({ sectorActivityId: partner?.activitySector?.id ?? '' })}
            settings={{
              mode: 'selectTableRow',
              tableRowConverter: x => ({ id: x.id, label: x.EnterpriseType }),
            }}
            valueFormatter={x => x.label}
            disabled={disabled || !partner?.activitySector?.id}
            title="Типы организаций"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Состояние">
          <Reference
            name="RefEnterpriseStatus" // Старое имя справочника
            onChange={makeChangeHandler('state')}
            value={partner?.state ?? null}
            relationTableModalTitle="Состояния"
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
