import React from 'react';
import block from 'bem-cn';
import * as icons from 'icons';

import { Toolbar, ConfirmPopup, InputSelect, Modal, FormComponent, IconButton } from 'components';

import { CurrentSetting, Column, Setting } from 'types/models/Table';
import { ActivateGridSettingArguments, SaveAndChangeGridSettingArguments } from 'features/Table/types';
import { TemplatesConfigurationModal } from '../TemplatesConfigurationModal';
import { TemplateForm } from '../TemplateForm';
import { SettingOption } from './types';
import useController from './controller';

import './styles.scss';

const b = block('template-panel');

export type Props = {
  gridName: string;
  columns: Column[];
  isTableExtended: boolean;
  currentSetting: CurrentSetting | null;
  settingPresets: Setting[];
  saveAndChangeGridSetting: (saveAndChangeGridSettingArguments: SaveAndChangeGridSettingArguments) => Promise<void>;
  changeCurrentSetting: (activateGridSettingArguments: ActivateGridSettingArguments) => void;
  reloadSettingPresets: () => Promise<void>;
};

export const TemplatesPanel = ({
  columns,
  gridName,
  isTableExtended,
  currentSetting,
  settingPresets,
  changeCurrentSetting,
  saveAndChangeGridSetting,
  reloadSettingPresets,
}: Props) => {
  const {
    buttons,
    userLogin,
    isHelpModalOpen,
    isTemplateFormOpen,
    settingPresetOptions,
    isTemplatesModalOpen,
    isSaveWarningModalOpen,
    isNotificationModalOpen,
    crudCallback,
    closeHelpModal,
    submitCallback,
    handleSaveClick,
    closeTemplateForm,
    successfullCallback,
    onChangeCurrentSetting,
    onResetSaveWarningModal,
    handleSaveOtherTemplate,
    onCloseSaveWarningModal,
    onConfirmSaveWarningModal,
    handleTemplatesModalClose,
    handleNotificationModalClose,
  } = useController({
    settingPresets,
    currentSetting,
    changeCurrentSetting,
    saveAndChangeGridSetting,
    reloadSettingPresets,
  });
  return (
    <div className={b()}>
      <TemplatesConfigurationModal
        columns={columns}
        currentSetting={currentSetting}
        gridName={gridName}
        isTableExtended={isTableExtended}
        isOpen={isTemplatesModalOpen}
        onClose={handleTemplatesModalClose}
        crudCallback={crudCallback}
        onSubmitTable={submitCallback}
      />
      <div className={b('select')}>
        <InputSelect<SettingOption>
          compactLabel
          label="Шаблон настройки таблицы"
          placeholder="Не задан"
          options={settingPresetOptions.filter(item => {
            return item.value.author === userLogin;
          })}
          value={
            settingPresetOptions.find(presetOption => {
              const isEqualName = currentSetting?.name === presetOption.label;
              const isEqualAuthor = currentSetting?.author === presetOption.value?.author;
              return isEqualName && isEqualAuthor;
            }) ?? null
          }
          onSelectChange={onChangeCurrentSetting}
        />
      </div>
      <Toolbar buttons={buttons} />
      <ConfirmPopup
        title="Сохранение шаблона"
        text={`Сохранить настройку таблицы в качестве шаблона "${currentSetting?.name ?? ''}"?`}
        isOpen={isNotificationModalOpen}
        okButtonText="Сохранить"
        resetButtonText="Сохранить под другим именем"
        onConfirm={handleSaveClick}
        onReset={handleSaveOtherTemplate}
        onClose={handleNotificationModalClose}
        icon="warning"
      />
      <ConfirmPopup
        title="Сохранение шаблона"
        text="Этот шаблон создан другим пользователем, только автор имеет право его изменить"
        isOpen={isSaveWarningModalOpen}
        okButtonText="Сохранить шаблон под другим именем"
        resetButtonText="Отмена"
        onConfirm={onConfirmSaveWarningModal}
        onReset={onResetSaveWarningModal}
        onClose={onCloseSaveWarningModal}
        icon="warning"
      />
      <TemplateForm
        isOpen={isTemplateFormOpen}
        onClose={closeTemplateForm}
        relatedColumns={columns}
        successfullCallback={successfullCallback}
      />

      <Modal title="Настройка таблицы с помощью шаблона" isOpen={isHelpModalOpen} onClose={closeHelpModal} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              <strong>Шаблон</strong> — это персональная (авторская) настройка таблицы, сохранённая под конкретным названием. В
              шаблоне выборки сохраняется список, очередность и ширина столбцов таблицы; введённые фразы для поиска в конкретном
              столбце; параметры сортировки данных в столбце.
            </p>
            <p>Шаблон используется для перестройки таблицы в один клик.</p>
            <p>
              Шаблон создается <strong>для конкретного режима</strong> работы: <strong>Для ввода данных</strong> или{' '}
              <strong>Для анализа</strong>.
            </p>
            <p>
              Если шаблон выборки имеет дополнительный атрибут <strong>Поделиться</strong>, такой шаблон будет доступен для выбора
              другим пользователям.
            </p>
            <p>
              Только <strong>автор</strong> шаблона имеет право изменить его настройки.
            </p>

            <h3>Как создать шаблон</h3>
            <ol>
              <li>
                Шаг: Очистить поле <strong>Шаблон настройки таблицы</strong>
              </li>
              <li>
                Шаг: Оставьте видимыми нужные вам столбцы с помощью кнопки Выбрать столбцы{' '}
                <IconButton icons={{ default: icons.PickColumns }} hasNoHover />
              </li>
              <li>Шаг: Если необходимо, настройте внешний вид таблицы: порядок и ширину столбцов</li>
              <li>
                Шаг: Для установки фильтров в столбцах таблицы откройте в таблице <strong>Строку для фильтрации</strong>. В
                ячейках строки введите значение для фильтрации данных в соответствующих столбцах
              </li>
              <li>Шаг: Для сортировки — щелчок левой кнопкой мыши по названию столбца</li>
              <li>
                Шаг: Нажмите кнопку справа от поля Шаблон выборки <strong>Сохранить как</strong>{' '}
                <IconButton icons={{ default: icons.Save }} hasNoHover /> откроется окно Добавления шаблона
              </li>
              <li>
                Шаг: Введите <strong>название</strong> шаблона, в поле <strong>Описание</strong> — кратко опишите назначение и
                особенности, если считаете, что шаблон будет полезен вашим коллегам — <strong>Поделиться со всеми</strong>;
                нажмите кнопку <strong>Сохранить шаблон</strong>
              </li>
              <li>
                Результат: После сохранения шаблона, в поле <strong>Шаблон настройки таблицы</strong> отобразится Название
                сохранённого шаблона. Название шаблона будет присутствовать в списке доступных для формы шаблонов настройки
                таблицы
              </li>
            </ol>

            <h3>Как изменить шаблон</h3>
            <ol>
              <li>
                Шаг: Выберите из списка в поле <strong>Шаблон настройки таблицы</strong> авторский шаблон
              </li>
              <li>Шаг: Измените настройки таблицы</li>
              <li>
                Шаг: Нажмите кнопку справа от поля Шаблон выборки <strong>Сохранить как</strong>{' '}
                <IconButton icons={{ default: icons.Save }} /> откроется окно Сохранения шаблона
              </li>
            </ol>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </div>
  );
};
