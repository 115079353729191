import { MobileRequest } from 'types/models';
import { MobileRequestFinancingType } from 'utils/Enums';
import { getMaybeField, convertReferenceItemToXML } from 'services/BackendAPI/configurations/commonConverters';
import { convertScientistToRequest, getHistoricalSliceXML } from '../author/requestConverters';
import { convertDocumentsToRequest } from '../converters';

export function convertMobileRequestToRequest({
  mobileRequest,
  isPrepareSave,
}: {
  mobileRequest: MobileRequest.MobileRequest;
  isPrepareSave?: boolean;
}) {
  const {
    id,
    visa,
    activityKind,
    advertisingMaterialTypes,
    applicant,
    applicantContact,
    applicantDepartment,
    applicantLanguageCompetence,
    applicantNote,
    applicantSupervisors,
    businessTripEndDate,
    businessTripStartDate,
    code,
    description,
    documents,
    estimatePositions,
    eventMaterialLevel,
    eventReportName,
    financings,
    isEventReportAccepted,
    language,
    mobileProtocol,
    number,
    pnrs,
    purpose,
    routes,
    sendDate,
    status,
    theme,
    traineeEndDate,
    traineeNecessityDocument,
    traineeStartDate,
    tripPlanes,
    useMaterialObligations,
    specialNote,
    tender,
    lot,
    applicantEducationYear,
    applicantGroupNumber,
    scienceDomainInterrest,
    tenderParticipant,
  } = mobileRequest;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(visa ? { Visa: convertReferenceItemToXML(visa) } : {}),
    ...(activityKind ? { ActivityKind: convertReferenceItemToXML(activityKind) } : {}),
    AdvertisingMaterialTypes: advertisingMaterialTypes.length
      ? { AdvertisingMaterialType: advertisingMaterialTypes.map(convertReferenceItemToXML) }
      : {},
    ...(applicant ? { Applicant: convertApplicantToRequst(applicant) } : {}),
    ...getMaybeField('ApplicantContact', applicantContact),
    ...(applicantDepartment ? { ApplicantDepartment: convertReferenceItemToXML(applicantDepartment) } : {}),
    ...getMaybeField('ApplicantLanguageCompetence', applicantLanguageCompetence),
    ...getMaybeField('ApplicantNote', applicantNote),
    ApplicantSupervisors: applicantSupervisors.length
      ? { ApplicantSupervisor: applicantSupervisors.map(convertApplicantSupervisorToRequest) }
      : {},
    ...getMaybeField('BusinessTripEndDate', businessTripEndDate),
    ...getMaybeField('BusinessTripStartDate', businessTripStartDate),
    ...getMaybeField('Code', code),
    ...(documents?.length ? convertDocumentsToRequest(documents, 'local') : {}),
    EstimatePositions: estimatePositions.length
      ? { EstimatePosition: estimatePositions.map(convertEstimatePositionToRequest) }
      : {},
    ...getMaybeField('EventMaterialLevel', eventMaterialLevel),
    ...getMaybeField('EventReportName', eventReportName),
    Financings: financings.length ? { Financing: financings.map(convertFinancingToRequest) } : {},
    isEventReportAccepted: String(!!isEventReportAccepted),
    ...(language ? { Language: convertReferenceItemToXML(language) } : {}),
    ...(mobileProtocol ? { MobileProtocol: convertMobileProtocol(mobileProtocol) } : {}),
    ...getMaybeField('Number', number),
    ...getMaybeField('Description', description),
    Pnrs: pnrs.length ? { Pnr: pnrs.map(convertReferenceItemToXML) } : {},
    ...getMaybeField('Purpose', purpose?.value),
    Routes: routes.length ? { Route: routes.map(convertRouteToRequest) } : {},
    ...getMaybeField('SendDate', sendDate),
    ...getMaybeField('Status', status?.value),
    ...getMaybeField('Theme', theme),
    ...getMaybeField('TraineeEndDate', traineeEndDate),
    ...getMaybeField('TraineeNecessityDocument', traineeNecessityDocument),
    ...getMaybeField('TraineeStartDate', traineeStartDate),
    TripPlanes: tripPlanes.length ? { TripPlan: tripPlanes.map(convertTripPlanToRequest) } : {},
    UseMaterialObligations: useMaterialObligations.length
      ? { UseMaterialObligation: useMaterialObligations.map(convertUseMaterialObligationToRequest) }
      : {},
    ...getHistoricalSliceXML(mobileRequest),
    ...getMaybeField('SpecialNote', specialNote),
    isPrepareSave: String(!!isPrepareSave),
    ...getMaybeField('Tender', tender?.id && { _attr: { id: tender.id } }),
    ...getMaybeField('Lot', lot?.id && { _attr: { id: lot.id } }),
    ...getMaybeField('ApplicantEducationYear', applicantEducationYear),
    ...getMaybeField('ApplicantGroupNumber', applicantGroupNumber),
    ...(scienceDomainInterrest ? { ScienceDomainInterest: convertReferenceItemToXML(scienceDomainInterrest) } : {}),
    ...(tenderParticipant ? { TenderParticipant: convertReferenceItemToXML(tenderParticipant) } : {}),
    ...getMaybeField('DestinationSupervisor', mobileRequest.destinationSupervisor),
    ...getMaybeField('DestinationSupervisorSpecifics', mobileRequest.destinationSupervisorSpecifics),
    ...getMaybeField('ParticipationsReportsThemes', mobileRequest.participationsReportsThemes),
  };
}

function convertApplicantToRequst(applicant: MobileRequest.Applicant) {
  const { id, fullName, shortName, scientist } = applicant;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('FullName', fullName),
    ...getMaybeField('ShortName', shortName),
    ...(scientist ? { Scientist: convertScientistToRequest({ scientist }) } : {}),
  };
}

function convertApplicantSupervisorToRequest(applicantSupervisor: MobileRequest.ApplicantSupervisor) {
  const { id, role, supervisor } = applicantSupervisor;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Role', role?.value),
    ...(supervisor ? { Supervisor: convertSupervisorToRequest(supervisor) } : {}),
    ...getHistoricalSliceXML(applicantSupervisor),
  };
}

function convertSupervisorToRequest(supervisor: MobileRequest.Supervisor) {
  const { id, shortName, fullName, scientist } = supervisor;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('ShortName', shortName),
    ...getMaybeField('FullName', fullName),
    ...(scientist ? { Scientist: convertScientistToRequest({ scientist }) } : {}),
  };
}

function convertEstimatePositionToRequest(estimatePosition: MobileRequest.EstimatePosition) {
  const { id, kindConsumption, amount, note } = estimatePosition;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(kindConsumption ? { KindConsumption: convertKindConsumptionToRequest(kindConsumption) } : {}),
    ...getMaybeField('Amount', amount),
    ...getMaybeField('Note', note),
  };
}

function convertKindConsumptionToRequest(kindConsumption: MobileRequest.KindConsumption) {
  const { id, code, name } = kindConsumption;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Code', code),
    ...getMaybeField('Name', name),
  };
}

function convertFinancingToRequest(financing: MobileRequest.Financing) {
  const { id, amount, type, note, sf1Source, department, sf3Source } = financing;
  const isSf1Type = type?.value === MobileRequestFinancingType.SF1;
  const isSf2Type = type?.value === MobileRequestFinancingType.SF2;
  const isSf3Type = type?.value === MobileRequestFinancingType.SF3;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Amount', amount),
    ...getMaybeField('Type', type?.value),
    ...getMaybeField('Note', note),
    ...(isSf1Type && sf1Source ? { Sf1Source: convertSf1sourceToRequest(sf1Source) } : {}),
    ...(isSf2Type && department ? { Department: convertReferenceItemToXML(department) } : {}),
    ...(isSf3Type && sf3Source ? { Sf3Source: sf3Source.value } : {}),
  };
}

function convertSf1sourceToRequest(sf1Source: MobileRequest.Sf1Source) {
  const { projectCodeId, fundCardId } = sf1Source;
  return {
    ...(projectCodeId ? { ProjectCode: { _attr: { id: projectCodeId } } } : {}),
    ...(fundCardId ? { FundCard: { _attr: { id: fundCardId } } } : {}),
  };
}

function convertMobileProtocol(mobileProtocol: MobileRequest.MobileProtocol) {
  const { id, number, protocolDate, status } = mobileProtocol;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Number', number),
    ...getMaybeField('ProtocolDate', protocolDate),
    ...getMaybeField('Status', status?.value),
  };
}

function convertRouteToRequest(route: MobileRequest.Route) {
  const { id, city, country, enterprise } = route;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('City', city),
    ...(country ? { Country: convertReferenceItemToXML(country) } : {}),
    ...(enterprise ? { Enterprise: convertReferenceItemToXML(enterprise) } : {}),
  };
}

function convertTripPlanToRequest(tripPlan: MobileRequest.TripPlan) {
  const { id, question, implementationForm } = tripPlan;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Question', question),
    ...getMaybeField('ImplementationForm', implementationForm),
  };
}

function convertUseMaterialObligationToRequest(useMaterialObligation: MobileRequest.UseMaterialObligation) {
  const { id, result, resultDate, resultDescription } = useMaterialObligation;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(result ? { Result: convertReferenceItemToXML(result) } : {}),
    ...getMaybeField('ResultDate', resultDate),
    ...getMaybeField('ResultDescription', resultDescription),
  };
}
