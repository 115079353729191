import { Document } from 'types/models';
import { FieldsType } from './index';

export function validate(doc: Document | null, fieldsType: FieldsType | null, isInProgram: boolean) {
  if (!doc) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidName = Boolean(doc.name);
  const isValidDate = Boolean(doc.date);
  const isValidFile = Boolean(doc.file.id);
  const isValidPartnerDocumentType = fieldsType === 'details' ? doc.partnerDocumentType !== null : true;
  const isValidProjectDocumentType = fieldsType === 'projectDocument' ? doc.projectDocumentType !== null : true;
  const isValidProgram = isInProgram ? !!doc.program?.id : true;

  return [
    { isValid: isValidName, invalidMessage: 'Заполните название документа' },
    { isValid: isValidDate, invalidMessage: 'Выберите дату документа' },
    { isValid: isValidFile, invalidMessage: 'Выберите файл' },
    { isValid: isValidPartnerDocumentType, invalidMessage: 'Укажите вид документа' },
    { isValid: isValidProjectDocumentType, invalidMessage: 'Выберите тип документа' },
    { isValid: isValidProgram, invalidMessage: 'Выберите программу' },
  ];
}
