import React, { useCallback } from 'react';

import { Modal, ListEdit, FormComponent } from 'components';

import { Publication } from 'types/models';
import { formatAuthor } from 'features/Form/views/AboutAuthorship/helpers';
import { GetReferenceElementList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  isOpen: boolean;
  onClose(): void;
  publication: Publication | null;
  title: string;
};

function EditRelationsModal(props: Props) {
  const { isOpen, onClose, publication, title } = props;
  const { departments, saveDepartments, setDepartments, publicationType } = useController({ publication });
  const { settings } = useAppDataContext();
  const authors = publication?.members ?? [];
  const biblioRecord = publication?.bibliographicRecord ?? '';

  const handleSubmit = useCallback(() => {
    saveDepartments();
    onClose();
  }, [onClose, saveDepartments]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <ControlPanel handleSave={handleSubmit} handleCancel={onClose} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <strong>{`Библиографическая запись ${publicationType?.genitiveCaseLabel || ''}:`}</strong>
        </FormComponent.Line>
        <FormComponent.Line>
          <span>{biblioRecord}</span>
        </FormComponent.Line>

        <FormComponent.Line>
          <strong>Авторы:</strong>
        </FormComponent.Line>
        <>
          {authors.map((author, index) => (
            <FormComponent.Line key={index}>
              <div dangerouslySetInnerHTML={{ __html: formatAuthor(author, settings) }} />
            </FormComponent.Line>
          ))}
        </>

        <ListEdit
          rows={departments}
          onChange={setDepartments}
          columns={[{ label: 'Наименование', formatValue: x => x.ref.label }]}
          isDeleteConfirmEnabled
          withMessages
          specification={{
            mode: 'relationTableModal',
            relationTableModalTitle: 'Справочник "Подразделения"',
            modalTableRowConverter: row => ({ ref: { label: row.label, id: row.id }, id: null }),
            modalTableSpecification: GetReferenceElementList({
              requestData: {
                filters: [],
                name: 'RefDepartment',
              },
            }),
          }}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { EditRelationsModal };
