import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { SecurityDocumentTypes, Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { State as CustomState } from '../makeUseCustomController';
import * as modalTemplates from './modalTemplates';

type Props = {
  customState: CustomState;
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ customState, tableState }: Props) {
  const { documentType } = customState;
  const {
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    nextPublicationName,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    securityDocumentId,
  } = useController({ tableState, documentType });

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.SecurityDocumentViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          documentType={documentType || SecurityDocumentTypes.PATENT}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.SecurityDocumentEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          documentType={documentType || SecurityDocumentTypes.PATENT}
        />
      )}
      <modalTemplates.SecurityDocumentAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
        documentType={documentType || SecurityDocumentTypes.PATENT}
      />
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный документ? (${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {securityDocumentId && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          securityDocumentId={securityDocumentId || ''}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
