import { Form } from 'types/models';
import { Notification } from 'features/Notifications';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

export const validatePageAndPageArticle = (pageFrom: Form.Field, pageTo: Form.Field, articleNumber: Form.Field): Result => {
  const result: Result = {
    isFormValid: true,
    invalidFieldKeys: [],
    nextNotification: { theme: 'danger', message: '' },
  };

  if ((pageFrom.value || pageTo.value) && articleNumber.value) {
    result.nextNotification.message = 'Указать можно либо номера страниц, либо номер статьи';
    result.invalidFieldKeys = ['pageFrom', 'pageTo', 'articleNumber'];
    result.isFormValid = false;
  }

  return result;
};
