import React, { useCallback, useMemo } from 'react';
import block from 'bem-cn';

import { Select, Button, ButtonMode, Checkbox, FormComponent } from 'components';

import { ElectronicType } from 'utils/Enums/ElectronicType';
import { SelectProp } from '../index';

const b = block('toolbar-form-template');

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  publicationType: SelectProp;
  electronicPublicationType: SelectProp;
  setPublicationType: (nextPublicationType: SelectProp) => void;
  setElectronicPublicationType: (nextElectronicPublicationType: SelectProp) => void;
  isElectronic: boolean;
  setIsElectronic: (nextIsElectronic: boolean) => void;
};

const CompilationAddTemplate: React.FC<Props> = ({
  onClose,
  onConfirm,
  publicationType,
  electronicPublicationType,
  setPublicationType,
  setElectronicPublicationType,
  isElectronic,
  setIsElectronic,
}) => {
  const NumPublicationTypes = useMemo(
    () => [
      {
        id: '1',
        label: 'Сборник научных трудов',
        text: 'Сборник, содержащий исследовательские материалы научных учреждений, учебных заведений или обществ',
        code: 'COMPILATION_SCIENTIFIC_WORK',
      },
      {
        id: '2',
        label: 'Сборник материалов конференций, симпозиумов и др.',
        text: 'Непериодический сборник, содержащий итоги конференции в виде докладов, рекомендаций, решений',
        code: 'COMPILATION_CONFERENCE',
      },
      {
        id: '3',
        label: 'Справочное издание',
        text:
          // eslint-disable-next-line max-len
          'Издание, содержащее краткие сведения научного или прикладного характера, расположенные в порядке, удобном для их быстрого отыскания, не предназначенное для сплошного чтения',
        code: 'COMPILATION_OTHER',
      },
    ],
    [],
  );

  const electronicPublicationTypes = useMemo(
    () => [
      {
        id: ElectronicType.WEB.id,
        label: ElectronicType.WEB.label,
        text: ElectronicType.WEB.text,
        code: ElectronicType.WEB.code,
      },
      {
        id: ElectronicType.LOCAL.id,
        label: ElectronicType.LOCAL.label,
        text: ElectronicType.LOCAL.text,
        code: ElectronicType.LOCAL.code,
      },
    ],
    [],
  );

  const onChangePublicationType = useCallback(
    (val: string) => {
      const selectedItemIndex = NumPublicationTypes.findIndex(({ label }) => val === label);
      if (selectedItemIndex !== -1) {
        setPublicationType({ ...NumPublicationTypes[selectedItemIndex] });
      }
    },
    [NumPublicationTypes, setPublicationType],
  );

  const onChangeElectronicPublicationType = useCallback(
    (val: string) => {
      const selectedItemIndex = electronicPublicationTypes.findIndex(({ label }) => val === label);
      if (selectedItemIndex !== -1) {
        setElectronicPublicationType({ ...electronicPublicationTypes[selectedItemIndex] });
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  const continueWithSelectedType = () => {
    onConfirm();
  };

  const renderEntry = (x: string) => (
    <div className={b('item-text')} title={x}>
      {x}
    </div>
  );

  return (
    <FormComponent.Wrapper>
      <div className={b('wrapper')}>
        <div className={b('fields-wrapper')}>
          <div className={b('field')}>
            <div className={b('field-title')}>Тип публикации</div>
            <div className={b('field-select')}>
              <Select.Component
                value={publicationType.label}
                onChange={onChangePublicationType}
                entries={NumPublicationTypes.map(e => e.label)}
                renderEntry={renderEntry}
                variant="outlined"
                defaultPlaceholder="Не задан"
              />
            </div>
          </div>
          <span>Внимание! От типа публикации зависит состав полей на форме добавления.</span>
          {publicationType.code && <p className="field-text">{publicationType.text}</p>}
          <div className={b('checkbox')}>
            <Checkbox
              label="Электронное издание, не имеющее печатного аналога"
              checked={!!isElectronic}
              onChange={setIsElectronic}
            />
          </div>
        </div>

        {isElectronic && (
          <div className={b('fields-wrapper')}>
            <div className={b('field')}>
              <div className={`${b('field-select')} ${b('field-select--full-width')}`}>
                <Select.Component
                  value={electronicPublicationType.label}
                  onChange={onChangeElectronicPublicationType}
                  entries={electronicPublicationTypes.map(electronicPulicationType => electronicPulicationType.label)}
                  renderEntry={renderEntry}
                  variant="outlined"
                  defaultPlaceholder="Не задан"
                />
              </div>
            </div>
            {electronicPublicationType.code && <p className="field-text">{electronicPublicationType.text}</p>}
          </div>
        )}
      </div>
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.primary}
          text="Продолжить"
          onClick={continueWithSelectedType}
          isDisabled={isElectronic && !electronicPublicationType.code}
        />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(CompilationAddTemplate);
