import React, { useMemo } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { EntityLocker } from 'features/EntityLocker';
import { State } from 'features/Table/specifications/GetShortEventList/makeUseCustomController';
import { EventTypes } from 'utils/Enums/EventTypes';

type CustomType = {
  customState: State;
  withHeaderId: boolean;
};

const EventEditTemplate: React.FC<F.TemplateProps & CustomType> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  customState,
  withHeaderId,
}: F.TemplateProps & CustomType) => {
  const event = useMemo(() => {
    switch (customState.eventType) {
      case EventTypes.EVENT_CONFERENCE.code:
        return EventTypes.EVENT_CONFERENCE;
      case EventTypes.EVENT_EXPOSITION.code:
        return EventTypes.EVENT_EXPOSITION;
      case EventTypes.EVENT_CONTEST.code:
        return EventTypes.EVENT_CONTEST;
      case EventTypes.EVENT_CONCERT.code:
        return EventTypes.EVENT_CONCERT;
      default:
        return EventTypes.EVENT_CONFERENCE;
    }
  }, [customState.eventType]);

  const look: F.EventFormLook = {
    apiID: 'GetEvent',
    template: event.template,
    type: 'conference',
    editMode: true,
    id: String(id),
    relatedTableState,
    isDisableEventSource: customState.isDisableSource,
    isMagazineReleasesPlanned: customState.isMagazineReleasesPlanned,
    isCompilationsPlanned: customState.isCompilationsPlanned,
    withHeaderId,
  };

  return (
    <EntityLocker onClose={onClose} isOpen={isOpen} entity="Event" id={look.id}>
      <Modal isOpen={isOpen} onClose={onClose} title={`Редактирование "${event.label}" (ID ${id})`} size="full">
        <Form look={look} onClose={onClose} />
      </Modal>
    </EntityLocker>
  );
};

export const Component = React.memo(EventEditTemplate);
