import React from 'react';
import { Table } from 'types/models';
import { ConfirmPopup, Toolbar as SharedToolbar } from 'components';
import { AddMip } from 'features/Form/views';
import useController from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

export const Toolbar = ({ tableState, customState }: Props) => {
  const {
    buttons,
    isPreAddFormOpen,
    isInfoPopupOpen,
    isConfirmDeletePopupOpen,
    onDeleteClose,
    onDeleteConfirm,
    onCloseInfoPopup,
    onPreAddClose,
    onPreAddSubmit,
  } = useController({
    tableState,
    customState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />
      {isConfirmDeletePopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          okButtonText="Да"
          resetButtonText="Отмена"
          icon="warning"
          isOpen={isConfirmDeletePopupOpen}
          onClose={onDeleteClose}
          text="Вы действительно хотите удалить выбранный МИП?"
          onConfirm={onDeleteConfirm}
        />
      )}
      {isInfoPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text="Партнёр на освное этой организации уже создан. Вы перенаправлены на форму редактирования"
          isOpen={isInfoPopupOpen}
          onClose={onCloseInfoPopup}
          onConfirm={onCloseInfoPopup}
          okButtonText="Ок"
          icon="info"
        />
      )}
      {isPreAddFormOpen && <AddMip isOpen={isPreAddFormOpen} onClose={onPreAddClose} onSubmit={onPreAddSubmit} />}
    </>
  );
};
