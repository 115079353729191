import React from 'react';
import block from 'bem-cn';
import './styles.scss';
import { Header } from '../types';
import { Entry } from 'types/models/Table';

const b = block('second-level-header');
type Props<CustomState> = { customState: CustomState; selectedRows: Entry[] } & Header<CustomState>['secondLevel'];
export const SecondLevelHeader = <CustomState extends {}>({ Component, customState, selectedRows }: Props<CustomState>) => {
  return (
    <>
      {typeof Component === 'function' && (
        <div className={b()}>
          <Component customState={customState} state={{ selectedRows: selectedRows }} />
        </div>
      )}
    </>
  );
};
