import React from 'react';

import { Checkbox, FormComponent, TextGridSelect, Option, Button, ButtonMode, ConfirmPopup, Modal } from 'components';

import { GetPersonAvailableHistoryElements } from 'features/Table/specifications';
import { GetLinkedHistoryEntitiesRequestData, HistoryEntity } from 'services/BackendAPI/configurations/author/types';
import useController from './controller';

type Props = {
  isOpen: boolean;
  historyEntities: HistoryEntity[];
  personId?: string;
  onClose: () => void;
} & GetLinkedHistoryEntitiesRequestData;

export const EditRelations = ({ isOpen, personId, onClose, type, id, historyEntities }: Props) => {
  const {
    fields,
    preparedTitle,
    entityLabel,
    tableTitle,
    confirmTextEntity,
    confirmTitle,
    isConfirmPopupOpen,
    closeConfirmPopup,
    onSubmit,
    onContinueClick,
    onIsNeedDeleteRelationChange,
    tableRowConverter,
    makeFieldChangeHandler,
  } = useController({ type, historyEntities, onClose });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={preparedTitle} size="large">
      <FormComponent.Wrapper>
        <FormComponent.Template>
          <FormComponent.Line>
            <FormComponent.Field label="Удалить связь">
              <Checkbox checked={fields.isNeedDeleteRelation} onChange={onIsNeedDeleteRelationChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          {fields.isNeedDeleteRelation && (
            <FormComponent.Line>
              <FormComponent.Field label="Оставить исторический срез">
                <Checkbox
                  checked={fields.isNeedHistorySlice}
                  onChange={makeFieldChangeHandler('isNeedHistorySlice')}
                  isDisabled={!fields.isNeedDeleteRelation}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line>
            <FormComponent.Field label={entityLabel} isRequired={!fields.isNeedDeleteRelation}>
              <TextGridSelect<Option | null>
                value={fields.entityToReplace || null}
                onChange={makeFieldChangeHandler('entityToReplace')}
                specification={GetPersonAvailableHistoryElements({ id, personId, type })}
                settings={{
                  mode: 'selectTableRow',
                  tableRowConverter,
                }}
                valueFormatter={x => x?.label ?? ''}
                title={tableTitle}
                disabled={fields.isNeedDeleteRelation}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Actions>
            <Button mode={ButtonMode.primary} text="Продолжить" onClick={onContinueClick} />
            <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
          </FormComponent.Actions>
        </FormComponent.Template>
        <ConfirmPopup
          isOpen={isConfirmPopupOpen}
          onClose={closeConfirmPopup}
          onConfirm={onSubmit}
          onReset={closeConfirmPopup}
          okButtonText="Да"
          resetButtonText="Отмена"
          title={confirmTitle}
          icon="info"
        >
          <span>
            Произвести замену исторического среза {confirmTextEntity} в выбранных элементах?
            <br />
            Замена будет произведена незамедлительно
            <br />
            Продолжить?
          </span>
        </ConfirmPopup>
      </FormComponent.Wrapper>
    </Modal>
  );
};
