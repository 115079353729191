import React from 'react';
import { FormComponent, TextGridSelect } from 'components';
import { Item } from 'types/models/common';
import { GetRequestList } from 'features/Table/specifications';
import { RequestStatus } from 'utils/Enums/RequestStatus';
import { ExtraButton } from 'components/TextGridSelect/TextGridSelect';

import useController from './controller';

type Props = {
  programRequest: Item<string> | null;
  setProgramRequest: (contestRequest: Item<string> | null) => void;
  label: string;
  modalTitle: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  extraButtons?: ExtraButton<Item>[];
};
export const SelectProgramRequest = ({
  programRequest,
  setProgramRequest,
  label,
  modalTitle,
  isDisabled,
  isRequired,
  extraButtons,
}: Props) => {
  const { onSelectRow } = useController();
  return (
    <FormComponent.Field label={label} isRequired={isRequired}>
      <TextGridSelect
        specification={GetRequestList({
          isOnlyNotLinked: true,
          isClearToolbar: true,
          status: RequestStatus.APPROVED,
          enumFilterValues: {
            enumName: 'RequestStatus',
            excludeValues: [RequestStatus.REQUEST, RequestStatus.RECOMENDED, RequestStatus.SENT, RequestStatus.REJECTED],
          },
        })}
        disabled={isDisabled}
        title={modalTitle}
        value={programRequest}
        valueFormatter={x => x.label}
        onChange={val => setProgramRequest(val)}
        settings={{
          mode: 'loadTableRow',
          onSelectRow,
        }}
        extraButtons={extraButtons}
      />
    </FormComponent.Field>
  );
};
