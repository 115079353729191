import { useCallback, useState, useLayoutEffect, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form } from 'types/models';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { PublicationHeader } from '../ModalHeader';
import { ModePublicationHeader } from '../ModalHeader/publication';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  onClose: () => void;
  publicationId: string;
  setTitle?: React.Dispatch<string | React.ReactElement>;
};

const useController = ({ onClose, publicationId, setTitle }: Props) => {
  const { settings } = useAppDataContext();
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const [printArticle, setPrintArticle] = useState<Form.PrintArticle | null>(null);
  const localTableStreams = useLocalTableStreams();

  const { methods: getPublicationAPI, state: getPublicationAPIState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublicationAPI, state: savePublicationAPIState } = BackendAPI.useBackendAPI('SavePublication');

  const isGetPublicationLoading = getPublicationAPIState.kind === 'pending';
  const isSavePublicationLoading = savePublicationAPIState.kind === 'pending';
  const isLoading = useMemo(() => isGetPublicationLoading || isSavePublicationLoading, [
    isGetPublicationLoading,
    isSavePublicationLoading,
  ]);

  const getPublication = useCallback(() => {
    getPublicationAPI.callAPI(
      {
        id: publicationId,
        simpleFields: {
          status: RecordStatus.ADDED,
        },
        attrIdFields: {},
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
      },
      {
        onSuccessfullCall: ({ data }) => {
          setPublicationInfo(data);
          setPrintArticle(data.printArticle);
        },
        onFailedCall: () => {
          setPublicationInfo(null);
        },
      },
    );
  }, [getPublicationAPI, publicationId]);

  const handleSavePublication = useCallback(() => {
    if (publicationInfo && !isLoading) {
      savePublicationAPI.callAPI(
        {
          id: publicationId,
          simpleFields: {
            type: PublicationTypes.PREPRINT_ARTICLE.code,
            status: publicationInfo.status.status,
            isElectronic: publicationInfo.fields.isElectronic === 'true',
            electronicType: publicationInfo.fields.electronicType,
            name: publicationInfo.fields.name,
            udk: publicationInfo.fields.udk,
            annotation: publicationInfo.fields.annotation,
            authorsKeyWords: publicationInfo.fields.authorsKeyWords,
            authorsCount: publicationInfo.fields.authorsCount,
            fractionalCount: publicationInfo.fields.fractionalCount,
            electronicMediaSource: publicationInfo.fields.electronicMediaSource,
            electronicSystemRequirements: publicationInfo.fields.electronicSystemRequirements,
            url: publicationInfo.fields.url,
            doi: publicationInfo.fields.doi,
            articleNumber: publicationInfo.fields.articleNumber,
            foreignName: publicationInfo.fields.foreignName,
            foreignAnnotation: publicationInfo.fields.foreignAnnotation,
            foreignAuthorsKeyWords: publicationInfo.fields.foreignAuthorsKeyWords,
            foreignAuthors: publicationInfo.fields.foreignAuthors,
          },
          attrIdFields: {
            grnti: publicationInfo.fields.grntiId,
            study: publicationInfo.fields.studyId,
            textLanguage: publicationInfo.fields.textLanguageId,
            magazine: publicationInfo.magazine?.id,
            original: publicationInfo.original?.id,
            magazineRelease: publicationInfo.magazineRelease?.id,
            printArticle: printArticle?.id || '',
          },
          arrayFields: {
            translations: { translation: [] },
          },
          authors: publicationInfo.members,
          mobileRequests: { mobileRequest: publicationInfo.mobileRequests },
          projects: publicationInfo.projects,
          file: publicationInfo.file,
        },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Связь препринта успешно отредактирована', theme: 'success' });
            onClose();
            localTableStreams.reloadTable.push();
          },
        },
      );
    }
  }, [publicationInfo, isLoading, savePublicationAPI, publicationId, printArticle?.id, onClose, localTableStreams.reloadTable]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        PublicationHeader({
          settings,
          mode: ModePublicationHeader.preprint,
          workMode: 'editMode',
          publicationId: publicationInfo?.status.id || '',
          publicationTypeCode: publicationInfo?.type || '',
          publicationElectronicTypeCode:
            publicationInfo?.fields.isElectronic === 'true' ? publicationInfo?.fields.electronicType || '' : '',
          departments: publicationInfo?.departments || [],
          status: publicationInfo?.status.text || '',
          publicationTypeEdition: {
            id: publicationInfo?.fields.typeEditionCode || '',
            label: publicationInfo?.fields.typeEditionText || '',
          },
          isPreprint: Boolean(publicationInfo?.isPreprint),
        }),
      );
    }
  }, [
    publicationInfo?.departments,
    publicationInfo?.fields.electronicType,
    publicationInfo?.fields.isElectronic,
    publicationInfo?.fields.typeEditionCode,
    publicationInfo?.fields.typeEditionText,
    publicationInfo?.isPreprint,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
  ]);

  const handleSetPrintArticle = useCallback((nextPrintArticle: Form.PrintArticle | null) => {
    setPrintArticle(nextPrintArticle);
  }, []);

  useLayoutEffect(() => {
    getPublication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    publicationInfo,
    printArticle,
    handleSavePublication,
    handleSetPrintArticle,
    setTitle,
  };
};

export default useController;
