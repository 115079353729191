import React, { useMemo, useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import publicationTypes from '../publicationTypes';
import { PublicationType } from 'types/models/Publication/publicationTypes';

type Props = F.TemplateProps;
const MagazineViewTemplate: React.FC<Props> = ({ isOpen, onClose, id, relatedTableState }: Props) => {
  const selectedType =
    relatedTableState && relatedTableState?.selectedRows.length ? relatedTableState.selectedRows[0]['id:type'] : '';

  const preparedPublicationTypes = useMemo<PublicationType[]>(() => {
    const list: PublicationType[] = [
      publicationTypes.magazineArticle,
      publicationTypes.articleConference,
      publicationTypes.thesis,
    ];

    return list;
  }, []);

  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    apiID: 'GetPublication',
    template: preparedPublicationTypes.filter(type => type.code === selectedType)[0]?.template,
    type: selectedType,
    viewMode: true,
    id: String(id),
    relatedTableState,
    disableAddParticipationAfterSave: true,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'medium-height']} viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineViewTemplate);
