import React, { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';

import { buttonIcons, IconButtonProps, ListEditTable, SectionTitle, Toolbar, FormComponent } from 'components';
import { Performer, ProjectScientist } from 'types/models/Project';
import { ModalProjectScientistsTable } from './ModalProjectScientistsTable/ModalProjectScientistsTable';
import { formatContacts } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  title?: string;
  performers: Performer[];
  chosenPerformers: ProjectScientist[];
  setChosenPerformers(performers: ProjectScientist[]): void;
  disabled: boolean;
};

type Mode = 'add' | 'edit';

const getUniq = <T,>(arr: T[]) => R.uniqWith<T, T>(R.equals, arr);

export function ChooseProjectScientists(props: Props) {
  const { chosenPerformers, setChosenPerformers, performers, title, disabled } = props;
  const collectivePerformers = performers
    .filter(performer => chosenPerformers.find(x => x.id === performer.id || (x.person?.id === performer.person?.id ?? '')))
    .sort((x, y) => (x.person?.fullName ?? '').localeCompare(y.person?.fullName ?? ''));

  const setPerformers = useCallback(
    (сurrentPerformers: Performer[]) => {
      setChosenPerformers(
        сurrentPerformers.map<ProjectScientist>(x => ({
          id: x.id || '',
          person: x.person?.scientist || null,
        })),
      );
    },
    [setChosenPerformers],
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mode, setMode] = useState<Mode | null>(null);
  const [selectedCollectivePerformerIndex, setSelectedCollectivePerformerIndex] = useState<number | null>(null);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
    setMode(null);
  }, []);

  const submitModal = useCallback(
    (newPerformers: Performer[]) => {
      const updatedPerformers =
        mode === 'add'
          ? getUniq([...collectivePerformers, ...newPerformers])
          : R.pipe<Performer[], Performer[], Performer[], Performer[]>(
              R.remove(selectedCollectivePerformerIndex || collectivePerformers.length, 1),
              R.insertAll(selectedCollectivePerformerIndex || collectivePerformers.length, newPerformers),
              getUniq,
            )(collectivePerformers);
      setPerformers(updatedPerformers);
    },
    [collectivePerformers, setPerformers, mode, selectedCollectivePerformerIndex],
  );

  const removePerformer = useCallback(() => {
    if (selectedCollectivePerformerIndex !== null) {
      const updatedPerformers = R.remove(selectedCollectivePerformerIndex, 1, collectivePerformers);
      setPerformers(updatedPerformers);
      if (!updatedPerformers.length) {
        setSelectedCollectivePerformerIndex(null);
      }
    }
  }, [selectedCollectivePerformerIndex, collectivePerformers, setPerformers]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isDisabled: disabled,
        onClick: () => {
          setIsOpenModal(true);
          setMode('add');
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        isDisabled: disabled || selectedCollectivePerformerIndex === null,
        onClick: removePerformer,
      },
    ],
    [disabled, removePerformer, selectedCollectivePerformerIndex],
  );

  return (
    <FormComponent.Line>
      <FormComponent.Field>
        {!!title && <SectionTitle title={title} isTableHeader />}
        <Toolbar buttons={buttons} mode="list" />

        <ListEditTable
          rows={collectivePerformers}
          defaultRowsCount={6}
          columns={[
            { label: 'ФИО', formatValue: x => x.person?.fullName ?? '' },
            { label: 'Контакты', formatValue: formatContacts },
          ]}
          selectRow={setSelectedCollectivePerformerIndex}
          selectedRowIndex={selectedCollectivePerformerIndex}
        />

        <ModalProjectScientistsTable
          isOpen={isOpenModal}
          onClose={closeModal}
          onSubmit={submitModal}
          performers={R.without(collectivePerformers, performers)}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
}
