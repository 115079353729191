import React, { useMemo, useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { ConferenceTypeProps } from 'types/models/Event';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { State } from 'features/Table/specifications/GetPersonalExpositionParticipationList/makeUseCustomController';

const ConferenceParticipatiotAddTemplate: React.FC<F.TemplateProps & ConferenceTypeProps & Partial<State>> = ({
  isOpen,
  onClose,
  relatedTableState,
  disabledEvent,
  sourceEvent,
  author,
}: F.TemplateProps & ConferenceTypeProps & Partial<State>) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look = useMemo(
    () => ({
      apiID: 'GetParticipation',
      template: ParticipationTypes.PARTICIPATION_EXPOSITION.template,
      type: ParticipationTypes.PARTICIPATION_EXPOSITION.eventCode,
      relatedTableState,
      viewMode: false,
      editMode: false,
      disabledEvent,
      sourceEvent,
      author,
      arm: 'pc',
      setTitle,
    }),
    // eslint-disable-next-line
  [author, disabledEvent, sourceEvent, relatedTableState]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ConferenceParticipatiotAddTemplate);
