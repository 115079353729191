import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { Event, Table, Report } from 'types/models';
import { Department, Participations, EventProject } from 'types/models/Event';
import { ParticipationEventSource } from 'types/models/Participation';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

import { TypeControllerIsForm } from './modalTemplates/ConferenceParticipationTemplate/models/typeControllerIsForm';

import { useLocalTableStreams } from 'features/Table/hooks';
import { buttonIcons, IconButtonProps, ReferenceItem } from 'components';
import * as BackendAPI from 'services/BackendAPI';
import { Permits } from 'utils/Permissions';
import { isHasPermission, isHasSomePermission } from 'features/AppData';
import { EventFormat, RecordStatus } from 'utils/Enums';
import { ConferenceTab } from 'utils/Permissions/ConferenceTab';
import ReactDOM from 'react-dom';
import { showNotification } from 'features/Notifications';
import { mockToSave } from 'features/Form/looks/event/views/ApproveEvent/mockToSave';

type Props = {
  tableState: Table.State;
};
export function useController({ tableState }: Props) {
  const suchLikeEventStreams = useLocalSuchLikeEventStreams();

  const openDuplicatesForm = useCallback(() => {
    suchLikeEventStreams.openSuchLikeEvents.push();
  }, [suchLikeEventStreams.openSuchLikeEvents]);

  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;
  const status = (selectedRow?.['id:RecordStatus'] || null) as RecordStatus | null;
  const selectedRowsLength = tableState.selectedRows.length;

  const { settings, userPermission } = useAppDataContext();

  const reports = useMemo<Report[]>(
    () => [
      Reports.EventConsolidatedListRequest,
      Reports.DepartmentEventConsolidatedListRequest,
      Reports.DepartmentConferenceConsolidatedList,
      Reports.DepartmentConferenceReport,
      Reports.ConferenceMemberCounts,
      Reports.NruP2Anketa,
      Reports.DepartmentConference,
      Reports.DepartmentConferenceParticipation,
      Reports.TotalDepartmentReportAll,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isMaterialFormOpen, setIsMaterialFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);

  const [isAddParticipationFormOpen, setIsAddParticipationFormOpen] = useState<boolean>(false);
  const [isEditParticipationFormOpen, setIsEditParticipationFormOpen] = useState<boolean>(false);
  const [isViewParticipationFormOpen, setIsViewParticipationFormOpen] = useState<boolean>(false);
  const [isFinancingModalOpen, setIsFinancingModalOpen] = useState<boolean>(false);
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [sourceEvent, setSourceEvent] = useState<ParticipationEventSource | null>(null);
  const [participation, setParticipation] = useState<Participations | null>(null);
  const [isEditSubdivisionModal, setIsEditSubdivisionModal] = useState<boolean>(false);
  const [isApproveParticipationModal, setIsApproveParticipationModal] = useState<boolean>(false);
  const [isShowAwards, setIsShowAwards] = useState<boolean>(false);
  const [sourcePublication, setSourcePublication] = useState<Event.Source | null>(null);

  const [isCancelMessagePopupOpen, setIsCancelMessagePopupOpen] = useState<boolean>(false);
  const [publicationMessage, setPublicationMessage] = useState<string>('');
  const [publicationMessageType, setPublicationMessageType] = useState<ReferenceItem>({ id: '', label: '' });

  const [eventData, setEventData] = useState<Event.Data | null | undefined>(null);
  const [projects, setProjects] = useState<EventProject[]>([]);
  const [departments, setDepartments] = useState<Event.Department[]>([]);

  const { methods: getEvent } = BackendAPI.useBackendAPI('GetEvent');
  const { methods: saveEvent } = BackendAPI.useBackendAPI('SaveEvent');

  const fetchEvent = useCallback(() => {
    if (selectedRow?.id) {
      getEvent.callAPI(
        {
          simpleFields: {
            type: 'CONFERENCE',
            domain: 'EVENT',
            isInSummaryPlan: false,
            isCompilationPlanned: false,
            IsMagazineReleasePlanned: false,
            isStudent: false,
            memberCountPlan: 0,
            memberCountFact: 0,
          },
          attrIdFields: {},
          arrayFields: {
            MinistryEducations: { translation: [] },
            CriticalTechnologies: { translation: [] },
            Pnmitrs: { translation: [] },
            Pnrs: { translation: [] },
            Pnis: { translation: [] },
            Partners: { translation: [] },
          },
          id: selectedRow?.id,
          projects,
          grntis: eventData?.grntis || [],
          memberCounts: [],
          format: EventFormat.OFFLINE,
        },
        {
          onSuccessfullCall: (result: any) => {
            const data = result.data as Event.Data;
            ReactDOM.unstable_batchedUpdates(() => {
              setEventData(data);
              setDepartments(data.departments);
              setProjects(data.projects);
            });
          },
        },
      );
    }
  }, [getEvent, selectedRow, setEventData, setDepartments, eventData, projects]);

  const handleSaveEvent = useCallback(
    callback => {
      if (eventData) {
        const fieldsForRequest = mockToSave(selectedRow?.id, eventData, departments, projects);

        saveEvent.callAPI(
          {
            ...fieldsForRequest,
            id: selectedRow?.id,
          },
          {
            onSuccessfullCall: (result: any) => {
              const data = result.data as Event.Data;
              showNotification({
                theme: data.success ? 'success' : 'danger',
                message: data.success ? 'Конференция успешно сохранена!' : 'Ошибка сохранения конференции',
              });
              callback?.();
            },
            onFailedCall: () => {},
          },
        );
      }
    },
    [selectedRow, saveEvent, departments, eventData, projects],
  );
  const onCloseParticipationForm = () => {
    setSourcePublication(null);
  };
  const controllerIsForm: TypeControllerIsForm = {
    isShowAwards,
    setIsShowAwards,
    isApproveParticipationModal,
    isEditSubdivisionModal,
    sourceEvent,
    participation,
    onCloseParticipationForm,
    isView: isViewParticipationFormOpen,
    isEdit: isEditParticipationFormOpen,
    isAdd: isAddParticipationFormOpen,
    isDelete: isDeletePopup,
    sourcePublication,
    setSourcePublication,
    setParticipation,
    setAdd: setIsAddParticipationFormOpen,
    setEdit: setIsEditParticipationFormOpen,
    setView: setIsViewParticipationFormOpen,
    setDelete: setIsDeletePopup,
    setSourceEvent,
    setIsEditSubdivisionModal,
    setIsApproveParticipationModal,
  };

  const isEditPermitted = useMemo(() => {
    if (status === RecordStatus.APPROVED)
      return isHasSomePermission(
        userPermission,
        (Object.keys(ConferenceTab) as Array<ConferenceTab>).map(
          tabKey => Permits[`CONFERENCE_EDIT_APPROVED_${tabKey.toUpperCase()}` as keyof Permits],
        ),
      );
    return isHasPermission(userPermission, Permits.CONFERENCE_EDIT_ADDED);
  }, [status, userPermission]);

  const isDeletePermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits[`CONFERENCE_DELETE_${status}` as keyof Permits]);
  }, [status, userPermission]);

  const isFinancingPermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_FINANCING);
  }, [userPermission]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleCloseApprovePopup = useCallback(() => {
    setIsCancelMessagePopupOpen(true);
  }, []);

  const handleApproveButtonClick = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Event',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleMaterialButtonClick = useCallback(() => {
    setIsMaterialFormOpen(true);
  }, []);
  const handleTemplateCloseMaterialForm = useCallback(() => {
    setIsMaterialFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleOpenFinancialSupport = useCallback(() => {
    fetchEvent();
    setIsFinancingModalOpen(true);
  }, [fetchEvent]);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const updatePublicationMessage = useCallback(
    (nextPublicationMessage: string) => {
      setPublicationMessage(nextPublicationMessage);
    },
    [setPublicationMessage],
  );

  const handleAttractedDepartmentsChange = useCallback(
    (value: Department[]) => {
      const depExists = (depId?: string) => value.find(d => d.id === depId);
      const newDepartments = departments.map(d => {
        if (d) {
          return { ...d, isFinancing: !!depExists(d.id) };
        }
        return d;
      });
      setDepartments(newDepartments);
    },
    [departments, setDepartments],
  );

  const { methods: addNewMessage } = BackendAPI.useBackendAPI('SaveEventUserFeedline');

  const onCloseMessageConfirm = useCallback(() => {
    setIsCancelMessagePopupOpen(false);
    setIsApprovePopupOpen(false);
    tableStreams.reloadTable.push();
  }, [tableStreams]);

  const onCancelMessageConfirmClick = useCallback(() => {
    addNewMessage.callAPI(
      {
        message: publicationMessage,
        messageId: '',
        labelId: publicationMessageType?.id,
        eventId: tableState.selectedRows[0]?.id,
      },
      {
        onSuccessfullCall: () => {
          onCloseMessageConfirm();
        },
      },
    );
  }, [tableState, addNewMessage, publicationMessageType, onCloseMessageConfirm, publicationMessage]);

  const onCancelMessageConfirmWithoutMessageClick = useCallback(() => {
    onCloseMessageConfirm();
  }, [onCloseMessageConfirm]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        permissionName: Permits.CONFERENCE_VIEW,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.book,
        title: 'Просмотр материалов',
        code: 'viewmaterial',
        isDisabled: !selectedRow,
        onClick: handleMaterialButtonClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        permissionName: Permits.CONFERENCE_ADD,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        permissionName: [
          Permits.CONFERENCE_EDIT_ADDED,
          ...(Object.keys(ConferenceTab) as Array<ConferenceTab>).map(
            tabKey => Permits[`CONFERENCE_EDIT_APPROVED_${tabKey.toUpperCase() as keyof typeof ConferenceTab}`],
          ),
        ],
        isDisabled: !selectedRow || !isEditPermitted,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: [Permits.CONFERENCE_DELETE_ADDED, Permits.CONFERENCE_DELETE_APPROVED],
        isDisabled: !selectedRow || !isDeletePermitted,
        onClick: handleDeleteButtonClick,
      },
      {
        icons: buttonIcons.approve,
        title: 'Процедура утверждения',
        code: 'approve',
        permissionName: Permits.CONFERENCE_APPROVE,
        isDisabled: !selectedRow,
        onClick: handleApproveButtonClick,
      },
      {
        icons: buttonIcons.financialSupport,
        title: 'Финансирование',
        code: 'publicationFinancialSupport',
        isDisabled: !selectedRow || !isFinancingPermitted,
        onClick: handleOpenFinancialSupport,
        permissionName: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      selectedRow,
      handleViewButtonClick,
      handleMaterialButtonClick,
      handleAddButtonClick,
      isEditPermitted,
      handleEditButtonClick,
      isDeletePermitted,
      handleDeleteButtonClick,
      handleApproveButtonClick,
      getReports,
      handleSetCurrentReport,
      handleOpenFinancialSupport,
      isFinancingPermitted,
    ],
  );

  const isApproved = useMemo(() => {
    return !!selectedRow;
  }, [selectedRow]);

  const setEventDataField = useCallback(
    (fieldName: keyof Event.Data) => (value: ReferenceItem[] | string) => {
      if (eventData) {
        setEventData({ ...eventData, [fieldName]: value });
      }
    },
    [eventData, setEventData],
  );

  const departmentOptions = useMemo(() => departments.filter(d => !d.isFinancing), [departments]);

  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  return {
    event: tableState.selectedRows[0]
      ? { id: tableState.selectedRows[0]?.id, label: tableState.selectedRows[0]?.EventName }
      : undefined,
    eventData,
    isApprovePopupOpen,
    handleCloseApprovePopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    isMaterialFormOpen,
    handleTemplateCloseMaterialForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    sourceEvent,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    publicationMessageType,
    updatePublicationMessage,
    setPublicationMessageType,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    buttons,
    settings,
    isFinancingModalOpen,
    setIsFinancingModalOpen,
    userPermission,
    isApproved,
    setEventDataField,
    departmentOptions,
    departments,
    handleAttractedDepartmentsChange,
    projects,
    setProjects,
    handleSaveEvent,
  };
}
