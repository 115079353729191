import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { Table, PatentResearch, Rntd, Customer, Performer, Document, PersonHistory } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Project, Stage } from 'types/models/Project';
import { clonePersonHistory, findLastestPerformerWithRole } from 'utils/Helpers';
import { ScientistJob } from 'types/models/Person';
import { getMockProject, getMockStage } from 'features/Form/looks/project/ProjectForm/helpers';
import { ProjectScientistRole } from 'utils/Enums';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

type LabelProject = Project & { label?: string };
type LabelStage = Stage & { label?: string };

export function useController({ viewMode, editMode, onClose }: Props) {
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [target, setTarget] = useState<string>('');
  const [loDate, setLoDate] = useState<string>('');
  const [hiDate, setHiDate] = useState<string>('');
  const [rntd, setRntd] = useState<Rntd | null>(null);
  const [project, setProject] = useState<LabelProject | null>(null);
  const [stage, setStage] = useState<LabelStage | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [performers, setPerformers] = useState<Performer[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);

  const tableStreams = useLocalTableStreams();
  const {
    look: { id, relatedTableState },
  } = useFormContext();

  const { methods: getPatentResearch } = BackendAPI.useBackendAPI('GetPatentResearch', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as PatentResearch;
      setName(preparedData.name);
      setNumber(preparedData.number);
      setTarget(preparedData.target);
      setLoDate(preparedData.loDate);
      setHiDate(preparedData.hiDate);
      setRntd(preparedData.rntd);
      setCustomers(preparedData.customers);
      setPerformers(preparedData.performers);
      setDocuments(preparedData.documents);

      const projectLabel: string = `${preparedData.project?.type?.label ? `${preparedData.project.type.label}: ` : ''}${
        preparedData.project?.number ? `${preparedData.project.number} - ` : ''
      }${preparedData.project?.name || ''}`;
      setProject(preparedData.project ? { ...preparedData.project, label: projectLabel } : null);

      const stageLabel: string = `Этап № ${preparedData.stage?.number || ''} (${preparedData.stage?.startDate || ''} - ${
        preparedData.stage?.endDate || ''
      })`;
      setStage(preparedData.stage ? { ...preparedData.stage, label: stageLabel } : null);
    },
  });

  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Project;
      const projectLabel: string = `${preparedData.type?.label ? `${preparedData.type.label}: ` : ''}${
        preparedData.number ? `${preparedData.number} - ` : ''
      }${preparedData.name || ''}`;
      setProject(preparedData ? { ...preparedData, label: projectLabel } : null);

      const preparedStage: Stage | null = data.stages.find(x => x.id === relatedTableState?.selectedRows[0].StageId) || null;
      const stageLabel: string = `Этап № ${preparedStage?.number || ''} (${preparedStage?.startDate || ''} - ${
        preparedStage?.endDate || ''
      })`;
      setStage(preparedStage ? { ...preparedStage, label: stageLabel } : null);

      const leaderPerformer = findLastestPerformerWithRole(preparedData.performers, 'LEADER');
      const leaderPersonHistory: PersonHistory | null = leaderPerformer ? { ...leaderPerformer.jobPeriods[0] } : null;
      const preparedLeader: Customer | null =
        leaderPerformer?.person && leaderPersonHistory
          ? { ...clonePersonHistory(leaderPersonHistory), id: null, person: leaderPerformer.person }
          : null;

      const responsablePerformerPerformer = findLastestPerformerWithRole(preparedData.performers, 'RESPONSABLE_PERFORMER');
      const responsablePerformerPersonHistory: PersonHistory | null = responsablePerformerPerformer
        ? { ...responsablePerformerPerformer.jobPeriods[0] }
        : null;
      const preparedResponsablePerformer: Customer | null =
        responsablePerformerPerformer?.person && responsablePerformerPersonHistory
          ? { ...clonePersonHistory(responsablePerformerPersonHistory), id: null, person: responsablePerformerPerformer.person }
          : null;

      const preparedCustomers: Customer[] = [preparedLeader, preparedResponsablePerformer].filter(x => x) as Customer[];
      setCustomers(preparedCustomers);
    },
  });

  const { methods: savePatentResearch } = BackendAPI.useBackendAPI('SavePatentResearch', {
    onSuccessfullCall: ({ data }) => {
      if (data.Response._attributes.success)
        showNotification({ message: 'Патентное исследование успешно сохранено', theme: 'success' });
      tableStreams.reloadTable.push();
    },
  });

  const onSubmit = useCallback((): boolean => {
    if (!(name && number && loDate && ((project?.id && stage?.id) || (!project?.id && !stage?.id)))) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return false;
    }
    savePatentResearch.callAPI({
      id: id || null,
      name,
      number,
      target,
      loDate,
      hiDate,
      rntd,
      project,
      stage,
      customers,
      performers,
      documents,
    });
    return true;
  }, [customers, documents, hiDate, id, loDate, name, number, performers, project, rntd, savePatentResearch, stage, target]);

  function getJobLabel(job: ScientistJob): string {
    const value: string = [
      job.enterpise,
      job.refAppointment?.label || job.appointment,
      job.staffState?.label,
      job.refDepartment?.label || job.department,
    ]
      .filter(x => x)
      .join(', ');
    return value;
  }
  const handleNameFieldChange = useCallback((value: string) => {
    setName(value);
  }, []);

  const handleNumberFieldChange = useCallback((value: string) => {
    setNumber(value);
  }, []);

  const handleTargetFieldChange = useCallback((value: string) => {
    setTarget(value);
  }, []);

  const handleLoDateFieldChange = useCallback((value: string | null) => {
    setLoDate(value || '');
  }, []);

  const handleHiDateFieldChange = useCallback((value: string | null) => {
    setHiDate(value || '');
  }, []);

  const handleProjectFieldChange = useCallback((e: Project) => {
    setProject(e);
    setStage(null);
  }, []);

  const handleStageFieldChange = useCallback((e: Stage) => {
    setStage(e);
  }, []);

  const handleCustomersFieldChange = useCallback((e: Customer[]) => {
    setCustomers(e);
  }, []);

  const handlePerformersFieldChange = useCallback((e: Performer[]) => {
    setPerformers(e);
  }, []);

  const handleDocumentsFieldChange = useCallback((e: Document[]) => {
    setDocuments(e);
  }, []);

  const convertProjectRow = useCallback((e: Table.Entry): LabelProject => {
    const value: LabelProject = {
      ...getMockProject(),
      id: e.id,
      name: e.name,
      label: e.fullName,
    };
    return value;
  }, []);

  const convertStageRow = useCallback((e: Table.Entry): LabelStage => {
    const value: LabelStage = { ...getMockStage(), id: e.id, name: e.NameStage, label: e.Name };
    return value;
  }, []);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const handleFormClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const findLeader = useCallback(() => {
    return (
      project?.performers.find(x =>
        x?.jobPeriods ? x?.jobPeriods?.find(jp => jp.role?.value === ProjectScientistRole.LEADER) : false,
      ) ?? null
    );
  }, [project]);

  const projectLeader = useMemo(() => findLeader(), [findLeader]);

  useLayoutEffect(() => {
    const projectId = relatedTableState?.selectedRows[0]?.ProjectId;
    if (id) getPatentResearch.callAPI({ id });
    else if (projectId) getProject.callAPI({ id: projectId });
    // eslint-disable-next-line
  }, []);

  return {
    workMode,
    name,
    number,
    target,
    loDate,
    hiDate,
    rntd,
    project,
    stage,
    customers,
    performers,
    documents,
    handleNameFieldChange,
    handleNumberFieldChange,
    handleTargetFieldChange,
    handleLoDateFieldChange,
    handleHiDateFieldChange,
    handleProjectFieldChange,
    handleStageFieldChange,
    handleCustomersFieldChange,
    handlePerformersFieldChange,
    handleDocumentsFieldChange,
    convertProjectRow,
    convertStageRow,
    handleFormSubmit,
    handleFormClose,
    getJobLabel,
    projectLeader,
  };
}
