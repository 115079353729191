import React, { useState } from 'react';
import block from 'bem-cn';

import { Button, ButtonMode, FormComponent, Radio, Select } from 'components';

import { Table as T } from 'types/models';
import compilationConferenceTypes from '../compilationConferenceTypes';
import { DataGrid } from 'features/Table';
import { GetCompilationArticleList } from 'features/Table/specifications/GetCompilationArticleList';
import { FetchCompilationProcess } from 'services/BackendAPI/configurations/publication/types';

const b = block('toolbar-form-template');

type Props = {
  onClose: () => void;
  onSelect: FetchCompilationProcess;
  relatedTableState: T.State;
  action: string;
  id: string;
};

const ChangeArticleTypes = (props: Props) => {
  const radioItems = [
    {
      value: 'ALL',
      label: 'Для всех статей',
    },
    {
      value: 'SELECTED',
      label: 'Для выбранных статей',
    },
  ];

  const { onClose, relatedTableState, onSelect, action, id } = props;
  const [entryPages, setEntryPages] = useState<string[]>();
  const [amount, setAmount] = useState<string>(radioItems[0].value);

  const [compilationConferenceType, setCompilationConferenceType] = useState(compilationConferenceTypes[0]);

  const onChangeCompilationConferenceType = (val: string) => {
    setCompilationConferenceType(compilationConferenceTypes.filter(type => type.label === val)[0]);
  };

  const onParticipantChange = (val: string) => {
    setAmount(val);
  };

  const continueWithSelectedType = () => {
    onSelect(id || '', action, {
      newArticleType: compilationConferenceType.code,
      articleIds: amount === 'SELECTED' && entryPages?.length ? entryPages!.join(',') : undefined,
    });
    onClose();
  };

  const renderEntry = (x: string) => (
    <div className={b('item-text')} title={x}>
      {x}
    </div>
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line hasGap>
        <FormComponent.Field label="Изменить тип статей на">
          <Select.Component
            value={compilationConferenceType.label}
            onChange={onChangeCompilationConferenceType}
            entries={compilationConferenceTypes.map(e => e.label)}
            renderEntry={renderEntry}
            variant="outlined"
            defaultPlaceholder="Не задан"
            required
          />
        </FormComponent.Field>
        <FormComponent.Field>
          <Radio value={amount} list={radioItems} onChange={onParticipantChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field>
          <DataGrid
            specification={GetCompilationArticleList({
              templatesTableDependencies: {
                relatedTableAPIID: 'GetCompilationArticleList',
                relatedTableState,
                relatedRecordId: id || undefined,
              },
              workMode: 'viewMode',
              setEntryPages,
            })}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.primary}
          text={amount === 'SELECTED' && !entryPages?.length ? 'Выберите статью' : 'Выполнить операцию по изменению типа статей'}
          onClick={continueWithSelectedType}
        />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(ChangeArticleTypes);
