import React, { memo } from 'react';

import { Form } from 'features/Form';
import { Table } from 'types/models';
import { Modal, ConfirmPopup, Toolbar } from 'components';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import { EntityLocker } from 'features/EntityLocker';
import { EditMagazineArticleMagazine } from 'features/Form/views';
import useController from './controller';

type WrapperProps = {
  parentId?: string;
  isSetupSourceRelationMode: boolean;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ parentId, isSetupSourceRelationMode }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isEditArticlesRelationPopupOpen,
      handleCloseEditArticlesRelationPopup,
      toolbarButtons,
      isEditFormOpen,
      closeEditForm,
      isViewFormOpen,
      closeViewForm,
      isApproveFormOpen,
      onApprove,
      closeApproveForm,
      closeConfirmDeletePopup,
      confirmDelete,
      isConfirmDeletePopupOpen,
      isRowCanDeleted,
      look,
      title,
    } = useController({
      tableState,
      isSetupSourceRelationMode,
    });

    return (
      <>
        <Toolbar buttons={toolbarButtons} />

        <EntityLocker isOpen={isEditFormOpen} onClose={closeEditForm} entity="Publication" id={look.id}>
          <Modal isOpen={isEditFormOpen} onClose={closeEditForm} title={title} size={['extralarge', 'large-height']}>
            <Form look={look} onClose={closeEditForm} />
          </Modal>
        </EntityLocker>

        <Modal isOpen={isViewFormOpen} onClose={closeViewForm} title={title} size={['extralarge', 'large-height']}>
          <Form look={look} onClose={closeViewForm} />
        </Modal>

        <ConfirmPopup
          title={isRowCanDeleted ? 'Удаление статьи' : 'Предупреждение'}
          text={
            isRowCanDeleted
              ? 'Вы действительно хотите удалить выбранную статью?'
              : 'Удаление статьи в статусе "Утверждено" не доступно. Для удаления статьи ее нужно перевести в статус "Добавлено"'
          }
          isOpen={isConfirmDeletePopupOpen}
          onClose={closeConfirmDeletePopup}
          onConfirm={isRowCanDeleted ? confirmDelete : closeConfirmDeletePopup}
          okButtonText={isRowCanDeleted ? 'Да' : 'Закрыть'}
          resetButtonText={isRowCanDeleted ? 'Отмена' : undefined}
        />
        <ApprovePopup
          isOpen={isApproveFormOpen}
          onClose={closeApproveForm}
          onApprove={onApprove}
          selectedRowId={tableState.selectedRows.length ? tableState.selectedRows[0].id : null}
        />
        <EditMagazineArticleMagazine
          isOpen={isEditArticlesRelationPopupOpen}
          onClose={handleCloseEditArticlesRelationPopup}
          sourcePublicationId={parentId || ''}
          articlePublicationIds={tableState.selectedRows.map(({ id }) => id as string)}
          relatedTableState={tableState}
        />
      </>
    );
  });
