import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'filterVal',
      list: [
        { label: 'Все', value: 'false' },
        { label: 'Текущие', value: 'true' },
      ],
      value: 'false',
    },
  ]);
});
