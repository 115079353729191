import React from 'react';

import {
  Reference,
  SectionTitle,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  FormComponent,
  Radio,
  InputSelect,
} from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { DistributionFunds } from './DistributionFunds/DistributionFunds';
import { ResourceUsageDepartments } from './ResourceUsageDepartments/ResourceUsageDepartments';
import useController from './controller';
import { ProjectType } from 'utils/Enums';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
  projectType: ProjectType;
};

export function FeaturesProject(props: Props) {
  const { disabled, project, projectType, makeChangeHandler } = props;
  const { provisionTypeOptions, onChangeIsRequestProvision, onChangeIsContractProvision } = useController({ makeChangeHandler });

  return (
    <>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Вид деятельности">
              <Reference
                disabled={disabled}
                name="RefOkved"
                onChange={makeChangeHandler('activityKind')}
                value={project?.activityKind ?? null}
                relationTableModalTitle='Справочник "Виды деятельности"'
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="ОКВЭД по доходам">
              <Reference
                disabled={disabled}
                name="RefOkvedIncome"
                onChange={makeChangeHandler('okvedIncome')}
                value={project?.okvedIncome ?? null}
                relationTableModalTitle='Справочник "ОКВЭД по доходам"'
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Расход бюджета">
              <Reference
                disabled={disabled}
                name="RefClassificationExpendituresBudgets"
                onChange={makeChangeHandler('expendituresBudgets')}
                value={project?.expendituresBudgets ?? null}
                relationTableModalTitle='Справочник "Классификации расходов бюджета"'
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Код расходов Нацпроекта">
              <Reference
                disabled={disabled}
                name="RefNationalProjectExpenseCode"
                onChange={makeChangeHandler('nationalProjectExpenseCode')}
                value={project?.nationalProjectExpenseCode ?? null}
                relationTableModalTitle='Справочник "Коды расходов нацпроекта"'
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <SectionTitle title="Особенности финансирования" />
      <FormComponent.Line hasGap hasFreeFormat growIndexes={[0, 1]} lineSize="doublePadded">
        <FormComponent.Field label="Нормативный процент накладных расходов" labelSize="fit" contentSize="small">
          <TextInput
            mode={TextInputMode.number}
            value={project?.overheadPercent}
            onChange={makeChangeHandler('overheadPercent')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Примечание">
          <TextInput value={project?.financialNote} onChange={makeChangeHandler('financialNote')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      {projectType !== ProjectType.NIR && (
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <SectionTitle title="Обеспечение контракта" />
              <FormComponent.Line>
                <FormComponent.Field label="">
                  <Radio
                    isDisabled={disabled}
                    value={String(project?.isContractProvision)}
                    list={[
                      { label: 'Да', value: 'true' },
                      { label: 'Нет', value: 'false' },
                    ]}
                    onChange={(value: string) => onChangeIsContractProvision(value === 'true')}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label="Сумма">
                  <TextInput
                    mode={TextInputMode.number}
                    value={project?.contractProvisionAmount || '0'}
                    onChange={makeChangeHandler('contractProvisionAmount')}
                    isDisabled={disabled || !project?.isContractProvision}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Вид обеспечения">
                  <InputSelect
                    disabled={disabled || !project?.isContractProvision}
                    options={provisionTypeOptions}
                    value={project?.contractProvisionType || null}
                    onSelectChange={makeChangeHandler('contractProvisionType')}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Примечание">
                  <TextArea
                    mode={TextAreaMode.modal}
                    settings={{ rows: 3, title: 'Примечание' }}
                    value={project?.contractProvisionNote ?? ''}
                    onChange={makeChangeHandler('contractProvisionNote')}
                    isDisabled={disabled || !project?.isContractProvision}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column>
              <SectionTitle title="Обеспечение заявки" />
              <FormComponent.Line>
                <FormComponent.Field label="">
                  <Radio
                    isDisabled={disabled}
                    value={String(project?.isRequestProvision)}
                    list={[
                      { label: 'Да', value: 'true' },
                      { label: 'Нет', value: 'false' },
                    ]}
                    onChange={(value: string) => onChangeIsRequestProvision(value === 'true')}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label="Сумма">
                  <TextInput
                    mode={TextInputMode.number}
                    value={project?.requestProvisionAmount || '0'}
                    onChange={makeChangeHandler('requestProvisionAmount')}
                    isDisabled={disabled || !project?.isRequestProvision}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Вид обеспечения">
                  <InputSelect
                    disabled={disabled || !project?.isRequestProvision}
                    options={provisionTypeOptions}
                    value={project?.requestProvisionType || null}
                    onSelectChange={makeChangeHandler('requestProvisionType')}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Примечание">
                  <TextArea
                    mode={TextAreaMode.modal}
                    settings={{ rows: 3, title: 'Примечание' }}
                    value={project?.requestProvisionNote ?? ''}
                    onChange={makeChangeHandler('requestProvisionNote')}
                    isDisabled={disabled || !project?.isRequestProvision}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <DistributionFunds projectCodes={project?.projectCodes ?? []} />
      </FormComponent.Line>
      <FormComponent.Line>
        <ResourceUsageDepartments
          resourceUsageDepartments={project?.resourceUsageDepartments ?? []}
          onResourceUsageDepartmentsChange={makeChangeHandler('resourceUsageDepartments')}
          disabled={disabled}
        />
      </FormComponent.Line>
    </>
  );
}
