import { useCallback, useMemo, useState } from 'react';
import { Table } from 'types/models';
import { buttonIcons, IconButtonProps } from 'components';
import { DetailProps } from '..';

type Props = {
  tableState: Table.State;
};

export const useController = ({ tableState }: Props & DetailProps) => {
  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
      },
    ],
    [isViewButtonDisabled, handleViewButtonClick],
  );
  return {
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    buttons,
  };
};
