import React, { useMemo } from 'react';

import { FormComponent, Radio, Reference, SectionTitle, TextArea, TextAreaMode } from 'components';

import { Project, PatentResearch } from 'types/models';
import { ValueOf } from 'types/helpers';
import { ProjectFinancingType } from 'utils/Enums';
import { formatNumber, calcNdsSumm } from 'utils/Helpers';

type Props = {
  project: Project.Project | null;
  stage: Project.Stage;
  setStage(stage: Project.Stage): void;
  getPatentResearchByStageId(stageId: string): PatentResearch | null;
};

export function Fields(props: Props) {
  const { project, stage, setStage, getPatentResearchByStageId } = props;

  const report = stage.reports[0] || null;

  const makeChangeHandler = (key: keyof Project.Stage) => (value: ValueOf<Project.Stage>) => {
    setStage({ ...stage, [key]: value });
  };

  const patentResearch = stage.id ? getPatentResearchByStageId(stage.id) : null;
  const financingText = useMemo<string>(() => {
    let result = '';
    const numberMainAmount = Number(stage.amountMain || 0);
    const numberMainNdsPercent = Number(stage.mainNdsPercent || 0);
    const mainFinancingItem = project?.financings.find(({ type }) => type?.value === ProjectFinancingType.MAIN);

    if (numberMainAmount && mainFinancingItem) {
      const mainNds = calcNdsSumm(stage.amountMain || '0', stage.mainNdsPercent || '0');
      result += `${mainFinancingItem.source?.label} - ${formatNumber(numberMainAmount)} руб.${
        numberMainNdsPercent ? ` В т.ч. НДС ${formatNumber(mainNds)} руб.` : ''
      }</br>`;
    }

    const sfAmount = Number(stage.amountCofinancing1 || 0);
    const numberSfNdsPercent = Number(stage.cofinancing1NdsPercent || 0);
    const sfFinancingItem = project?.financings.find(({ type }) => type?.value === ProjectFinancingType.COFINANCING_1);

    if (sfAmount && sfFinancingItem) {
      const sf1Nds = Number(calcNdsSumm(stage.amountCofinancing1 || '0', stage.cofinancing1NdsPercent || '0'));
      result += `${sfFinancingItem.source?.label} - ${formatNumber(sfAmount)} руб.${
        numberSfNdsPercent ? ` В т.ч. НДС ${formatNumber(sf1Nds)} руб.` : ''
      }</br>`;
    }

    const sf2Amount = Number(stage.amountCofinancing2 || 0);
    const numberSf2NdsPercent = Number(stage.cofinancing2NdsPercent || 0);
    const sf2FinancingItem = project?.financings.find(({ type }) => type?.value === ProjectFinancingType.COFINANCING_2);

    if (sf2Amount && sf2FinancingItem) {
      const sf2Nds = Number(calcNdsSumm(stage.amountCofinancing2 || '0', stage.cofinancing2NdsPercent || '0'));
      result += `${sf2FinancingItem.source?.label} - ${formatNumber(sf2Amount)} руб.${
        numberSf2NdsPercent ? ` В т.ч. НДС ${formatNumber(sf2Nds)} руб.` : ''
      }</br>`;
    }

    const localAmount = Number(stage.amountLocal || 0);
    const numberLocalNdsPercent = Number(stage.localNdsPercent || 0);
    const localFinancingItem = project?.financings.find(({ type }) => type?.value === ProjectFinancingType.LOCAL);

    if (localAmount && localFinancingItem) {
      const localNds = Number(calcNdsSumm(stage.amountLocal || '0', stage.localNdsPercent || '0'));
      result += `${localFinancingItem.source?.label} - ${formatNumber(localAmount)} руб.${
        numberLocalNdsPercent ? ` В т.ч. НДС ${formatNumber(localNds)} руб.` : ''
      }</br>`;
    }
    const accompliceAmount = Number(stage.amountAccomplice || 0);
    const numberAccompliceNdsPercent = Number(stage.accompliceNdsPercent || 0);
    const accompliceFinancingItem = project?.financings.find(({ type }) => type?.value === ProjectFinancingType.ACCOMPLICE);

    if (accompliceAmount && accompliceFinancingItem) {
      const accompliceNds = Number(calcNdsSumm(stage.amountAccomplice || '0', stage.accompliceNdsPercent || '0'));
      result += `${accompliceFinancingItem.source?.label} - ${formatNumber(accompliceAmount)} руб.${
        numberAccompliceNdsPercent ? ` В т.ч. НДС ${formatNumber(accompliceNds)} руб.` : ''
      }</br>`;
    }
    return result;
  }, [project, stage]);

  return (
    <FormComponent.Wrapper specialStyles={{ maxHeight: '50vh', overflowY: 'auto' }}>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Финансирование">
              <span dangerouslySetInnerHTML={{ __html: financingText || '-' }} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Название этапа">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Название этапа' }}
                value={stage.name || ''}
                onChange={makeChangeHandler('name')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field
              label="Аннотация работ"
              // eslint-disable-next-line max-len
              tooltip="В краткой свободной форме опишите планируемые результаты работы, основные ожидаемые характеристики и параметры объекта исследования или разработки. Текст должен быть кратким и точным, не должен содержать сложных формул, таблиц, рисунков и аббревиатур без их расшифровки(кроме общепринятых), а также сокращенных слов (кроме общепринятых). Объем - до 21845 знаков"
            >
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Аннотация работ' }}
                value={stage.annotation || ''}
                onChange={makeChangeHandler('annotation')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Цели этапа">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Цели этапа' }}
                value={stage.goal || ''}
                onChange={makeChangeHandler('goal')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Задачи этапа">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Задачи этапа' }}
                value={stage.task || ''}
                onChange={makeChangeHandler('task')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field
              label="Ожидаемые результаты"
              // eslint-disable-next-line max-len
              tooltip="В краткой свободной форме дается описание нового материала, устройства, продукта и других результатов проекта, а также возможного метода использования результатов проекта. Объем - до 21845 знаков"
            >
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Ожидаемые результаты' }}
                value={stage.expectedResults || ''}
                onChange={makeChangeHandler('expectedResults')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Вид работ">
              <Reference
                name="RefKindProjectWork"
                onChange={makeChangeHandler('kindWork')}
                value={stage.kindWork || null}
                relationTableModalTitle='Справочник "Виды работ"'
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Описание работ этапа">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Описание работ этапа' }}
                value={stage.workDescription || ''}
                onChange={makeChangeHandler('workDescription')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Правовая охрана РИД">
              <Radio
                list={[
                  { label: 'Планируются', value: 'true' },
                  { label: 'Не планируются', value: 'false' },
                ]}
                value={String(stage.isPatentResearchPlanned)}
                onChange={makeChangeHandler('isPatentResearchPlanned')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="">
              <div>
                {patentResearch
                  ? `${patentResearch.number} "${patentResearch.name}". ${
                      patentResearch.rntd?.name ? `На охрану РИД: ${patentResearch.rntd.name} ` : ''
                    }${patentResearch.hiDate ? `(Дата сдачи отчета: ${patentResearch.hiDate})` : 'В работе'}`
                  : ''}
              </div>
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Получены результаты этапа">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Получены результаты этапа' }}
                value={stage.obtainedResults || ''}
                onChange={makeChangeHandler('obtainedResults')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Эффект на уровне университета">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Эффект на уровне университета' }}
                value={stage.universityEffect || ''}
                onChange={makeChangeHandler('universityEffect')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Эффект на уровне региональном">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Эффект на уровне региональном' }}
                value={stage.regionalEffect || ''}
                onChange={makeChangeHandler('regionalEffect')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Эффект на уровне национальном">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Эффект на уровне национальном' }}
                value={stage.nationalEffect || ''}
                onChange={makeChangeHandler('nationalEffect')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {!!report && (
            <>
              <SectionTitle title="Отчёт по итогам этапа" />
              <FormComponent.Line>
                <FormComponent.Field>
                  {/* eslint-disable-next-line max-len */}
                  {`Отчёт: ${report.name}: отчёт о НИР (${report.type?.label}); - ГР ${report.number}. - Инв. ${report.ontiNumber}`}
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Госрегистрация">{report.registrationDate}</FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Регистрация в архиве">{report.registrationOntiDate}</FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Отправлен Заказчику">{report.sendCustomerDate}</FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
