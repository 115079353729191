import React from 'react';

import { ListEdit, SectionTitle, TextArea, FormComponent } from 'components';

import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';
import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  person: Person.ScientistPerson | null;
  disabled: boolean;
  makeSetPersonField: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};

export function ScientificInterests(props: Props) {
  const { person, disabled, makeSetPersonField } = props;

  if (!person) {
    return null;
  }

  return (
    <>
      <FormComponent.Description mode="warning">Анкетные данные "Профиль учёного"</FormComponent.Description>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <SectionTitle title="Научные интересы" />
          <FormComponent.Line>
            <FormComponent.Field label="Направления деятельности">
              <TextArea
                settings={{ rows: 7, isFixed: true }}
                value={person.activities}
                onChange={makeSetPersonField('activities')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Основные научные результаты">
              <TextArea
                settings={{ rows: 7, isFixed: true }}
                value={person.scientificResults}
                onChange={makeSetPersonField('scientificResults')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ключевые слова на русском языке">
              <TextArea settings={{ isFixed: true }} value={person.keywordsRus} onChange={makeSetPersonField('keywordsRus')} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ключевые слова на английском языке">
              <TextArea
                settings={{ rows: 3, isFixed: true }}
                value={person.keywordsEnglish}
                onChange={makeSetPersonField('keywordsEnglish')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>

        <FormComponent.Column>
          <SectionTitle title="Области научных интересов" />
          <FormComponent.Line>
            <ListEdit
              title="Коды ГРНТИ"
              maxHeight="220px"
              rows={person.grntis}
              onChange={makeSetPersonField('grntis')}
              isToolbarDisabled={disabled}
              columns={[{ label: '', formatValue: x => x.label }]}
              isDeleteConfirmEnabled
              withMessages
              withoutHead
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "ГРНТИ"',
                modalTableRowConverter: row => ({ id: row.id, label: `${row.code}. ${row.name} (Группа: ${row.parent})` }),
                modalTableSpecification: GetReferenceElementList({
                  requestData: {
                    filters: [],
                    name: 'RefGrnti',
                  },
                }),
              }}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ListEdit
              title="Коды УДК"
              maxHeight="220px"
              rows={person.udks}
              onChange={makeSetPersonField('udks')}
              isToolbarDisabled={disabled}
              columns={[{ label: '', formatValue: x => x.label }]}
              isDeleteConfirmEnabled
              withMessages
              withoutHead
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "УДК"',
                modalTableRowConverter: row => ({ id: row.id, label: row.label }),
                modalTableSpecification: GetReferenceElementList({
                  requestData: {
                    filters: [],
                    name: 'RefUdk',
                  },
                }),
              }}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ListEdit
              title="Коды международной классификации отраслей наук (ОЕСД)"
              maxHeight="220px"
              rows={person.oecds}
              onChange={makeSetPersonField('oecds')}
              isToolbarDisabled={disabled}
              columns={[{ label: '', formatValue: x => x.label }]}
              isDeleteConfirmEnabled
              withMessages
              withoutHead
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "ОЕСД"',
                modalTableRowConverter: row => ({ id: row.id, label: row.label }),
                modalTableSpecification: GetReferenceElementList({
                  requestData: {
                    filters: [],
                    name: 'RefOecd',
                  },
                }),
              }}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ListEdit
              title="Области знаний"
              maxHeight="220px"
              rows={person.domainKnowledges}
              onChange={makeSetPersonField('domainKnowledges')}
              isToolbarDisabled={disabled}
              columns={[{ label: '', formatValue: x => x.label }]}
              isDeleteConfirmEnabled
              withMessages
              withoutHead
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "Области знаний"',
                modalTableRowConverter: row => ({ id: row.id, label: row.label }),
                modalTableSpecification: GetReferenceElementList({
                  requestData: {
                    filters: [],
                    name: 'RefDomainKnowledge',
                  },
                }),
              }}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ListEdit
              title="Специальности ВАК"
              maxHeight="220px"
              rows={person.vakSpecialities}
              onChange={makeSetPersonField('vakSpecialities')}
              isToolbarDisabled={disabled}
              columns={[{ label: '', formatValue: x => x.label }]}
              isDeleteConfirmEnabled
              withMessages
              withoutHead
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "ВАК"',
                modalTableRowConverter: row => ({ id: row.id, label: row.label }),
                modalTableSpecification: GetReferenceElementList({
                  requestData: {
                    filters: [],
                    name: 'RefVakSpeciality',
                  },
                }),
              }}
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}
