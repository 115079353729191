import { useCallback, useMemo, useState } from 'react';

import { Reference, ReferenceItem, Checkbox, FormComponent, ListEdit, ConfirmPopup, buttonIcons } from 'components';
import { ExtraToolbarButton, Mode } from 'components/ListEdit/model';

import { SelectPerson, Member } from 'features/SelectPerson';
import { showNotification } from 'features/Notifications';
import { Component as AddSupervisorToAuthor } from '../AddSupervisorToAuthor/AddSupervisorToAuthor';
import { EventTypeName } from '../eventTypes';
import { ExtendedMember } from '../model';
import { getSupervisorInfoText, getSupervisorWarningText, USER_ROLES } from '../helpers';
import { validate } from './validate';

export type ChangeProps = {
  key: keyof ExtendedMember;
  value: ExtendedMember[keyof ExtendedMember];
};

type Props = {
  extendedMember: ExtendedMember;
  extendedMembers: ExtendedMember[];
  index: number | null;
  mode: Mode;
  onChange(args: ChangeProps[]): void;
  eventType: EventTypeName;
};

function AuthorshipFields(props: Props) {
  const { extendedMember, onChange, eventType, index, mode, extendedMembers } = props;
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);

  const onWargningClose = useCallback(() => {
    setIsWarningOpen(false);
  }, []);

  const onInfoClose = useCallback(() => {
    setIsInfoOpen(false);
  }, []);

  const preparedExtendedMembers = useMemo(
    () => (mode === 'edit' ? extendedMembers.filter((_, memberIndex) => index !== memberIndex) : extendedMembers),
    [extendedMembers, index, mode],
  );

  const freeParticipants = useMemo(
    () =>
      preparedExtendedMembers.filter(
        preparedExtendedMember =>
          !preparedExtendedMember.supervisorId &&
          preparedExtendedMember.role?.customFields?.code !== USER_ROLES.SUPERVISOR.code &&
          !extendedMember.participantIds.some(
            ({ personId, roleId }) =>
              personId === preparedExtendedMember.person?.id && preparedExtendedMember.role?.id === roleId,
          ),
      ),
    [extendedMember.participantIds, preparedExtendedMembers],
  );

  const extendedMemberParticipants = useMemo(
    () =>
      preparedExtendedMembers.filter(preparedExtendedMember =>
        extendedMember.participantIds.some(
          ({ personId, roleId }) => personId === preparedExtendedMember.person?.id && roleId === preparedExtendedMember.role?.id,
        ),
      ),
    [extendedMember.participantIds, preparedExtendedMembers],
  );

  const rolePerson = extendedMember?.role ?? null;
  const isConferenceEvent = useMemo(() => eventType === 'CONFERENCE', [eventType]);
  const updateAuthor = useCallback(
    (updatedMember: Member | null) => {
      if (updatedMember) {
        const changeProps = Object.entries(updatedMember).map<ChangeProps>(([key, value]) => ({
          key: key as keyof Member,
          value,
        }));
        onChange(changeProps);
      }
    },
    [onChange],
  );

  const handleUpdateIsReporter = useCallback(() => {
    onChange([{ key: 'isReporter', value: !extendedMember.isReporter }]);
  }, [extendedMember, onChange]);

  const handleChangeRole = useCallback(
    (value: ReferenceItem) => {
      const isNextRoleSupervisor = value.customFields?.code === USER_ROLES.SUPERVISOR.code;
      const hasSomeParticipant = extendedMembers.some(
        extendedMemberItem => extendedMemberItem.role?.customFields?.code !== USER_ROLES.SUPERVISOR.code,
      );

      if (isNextRoleSupervisor) {
        if (!hasSomeParticipant) {
          setIsWarningOpen(true);
          return;
        }

        setIsInfoOpen(true);
      }
      const nextValue = value.id ? value : null;
      onChange([{ key: 'role', value: nextValue }]);
    },
    [extendedMembers, onChange],
  );

  const handleChangeParticipantsId = useCallback(
    (nextParticipants: ExtendedMember[]) => {
      const nextIds = nextParticipants.map(nextParticipant => ({
        personId: nextParticipant?.person?.id ?? '',
        roleId: nextParticipant?.role?.id ?? '',
      }));

      onChange([{ key: 'participantIds', value: nextIds }]);
    },
    [onChange],
  );

  const isSupervisor = useMemo<boolean>(() => extendedMember.role?.customFields?.code === USER_ROLES.SUPERVISOR.code, [
    extendedMember.role?.customFields?.code,
  ]);

  const addFreeParticipants = useCallback(() => {
    onChange([
      {
        key: 'participantIds',
        value: [
          ...extendedMember.participantIds,
          ...freeParticipants.map(({ person, role }) => ({ roleId: role?.id ?? '', personId: person?.id ?? '' })),
        ],
      },
    ]);
  }, [extendedMember.participantIds, freeParticipants, onChange]);

  const buttons = useMemo<ExtraToolbarButton<ExtendedMember>[]>(
    () => [
      {
        icons: buttonIcons.person,
        title: 'Добавить свободных участников',
        onClick: () => {
          addFreeParticipants();
        },
      },
    ],
    [addFreeParticipants],
  );

  const onConfirmInfoClick = useCallback(() => {
    setIsInfoOpen(false);
    addFreeParticipants();
  }, [addFreeParticipants]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="ФИО">
          <SelectPerson member={extendedMember} onUpdateMember={updateAuthor} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat growIndexes={[2, 0]} lineSize="padded">
        <FormComponent.Field isRequired label="Роль">
          <Reference
            value={rolePerson}
            leftAlignedTitle
            relationTableModalTitle='Справочник "Роли участников мероприятий"'
            theme="small"
            filters={[{ key: 'type', values: [eventType] }]}
            refButtonTitle='Справочник "Роли участников мероприятий"'
            name="RefParticipationMemberRole"
            onChange={handleChangeRole}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {isConferenceEvent && (
        <FormComponent.Line>
          <FormComponent.Field label="Докладчик">
            <Checkbox checked={!!extendedMember?.isReporter} onChange={handleUpdateIsReporter} isDisabled={!extendedMember} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {isSupervisor && (
        <FormComponent.Line>
          <ListEdit
            maxHeight="none"
            defaultRowsCount={10}
            rows={extendedMemberParticipants}
            title="Является руководителем участников"
            extraToolbarButtons={buttons}
            columns={[{ label: '', formatValue: x => `${x.role?.label}: <b>${x.person?.fullName ?? ''}</b>` }]}
            onChange={handleChangeParticipantsId}
            isDeleteConfirmEnabled
            withMessages
            specification={{
              mode: 'customComponent',
              onPreSubmit: (author, submit) => {
                submit(author);
              },
              validation: {
                checkIsValid: member => validate(member).every(x => x.isValid),
                onInvalidate: member => {
                  const info = validate(member);
                  info.forEach(({ isValid, invalidMessage }) => {
                    if (!isValid) {
                      setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                    }
                  });
                },
              },
              renderComponent: (participant, setParticipant) => (
                <AddSupervisorToAuthor
                  participant={participant}
                  setParticipant={setParticipant}
                  participants={freeParticipants}
                />
              ),
            }}
          />
        </FormComponent.Line>
      )}
      <ConfirmPopup
        isOpen={isWarningOpen}
        title="Предупреждение"
        onClose={onWargningClose}
        onConfirm={onWargningClose}
        okButtonText="Ок"
        icon="warning"
        text={getSupervisorWarningText(eventType)}
      />
      <ConfirmPopup
        isOpen={isInfoOpen}
        title="Предупреждение"
        onClose={onInfoClose}
        onConfirm={onConfirmInfoClick}
        onReset={onInfoClose}
        resetButtonText="Ок"
        okButtonText="Скопировать участников"
        icon="info"
        text={getSupervisorInfoText(eventType)}
      />
    </FormComponent.Wrapper>
  );
}

export { AuthorshipFields };
