export function getAcceptInfo({
  accepted,
  acceptedBy,
}: {
  accepted: string;
  acceptedBy: { id: string; fullName: string } | null;
}) {
  if (accepted && acceptedBy) return `${acceptedBy.fullName} ${accepted}`;
  return '';
}
