import { FILTER_STRUCRURE } from 'features/Table/helpers';
import { TEXT_DEBOUNCE_TIME } from 'features/Table/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { formatNumbericToFilter } from './helpers';
import { Props as ComponentProps } from './index';

type Props = object & ComponentProps;

const useController = ({ columnType, filterStructure, setColumnFilter, value }: Props) => {
  const [inputValueFrom, setInputValueFrom] = useState<string>('');
  const [inputValueTo, setInputValueTo] = useState<string>('');

  useEffect(() => {
    if (filterStructure === FILTER_STRUCRURE.range) {
      const [from, to] = value.split('#').map(n => (n === 'EMPTY' || !n ? '' : n));
      setInputValueFrom(from);
      setInputValueTo(to);
    } else {
      setInputValueFrom(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const inputDebounce = useDebouncedCallback((newValue: any) => {
    setColumnFilter(newValue);
  }, TEXT_DEBOUNCE_TIME);

  const onFromInputChange = useCallback(
    (nextValue: string) => {
      if (nextValue !== inputValueFrom) {
        setInputValueFrom(nextValue);
        inputDebounce(
          formatNumbericToFilter({
            from: nextValue,
            to: inputValueTo,
            filterStructure,
          }),
        );
      }
    },
    [filterStructure, inputDebounce, inputValueFrom, inputValueTo],
  );

  const onToInputChange = useCallback(
    (nextValue: string) => {
      if (nextValue !== inputValueTo) {
        setInputValueTo(nextValue);
        inputDebounce(
          formatNumbericToFilter({
            from: inputValueFrom,
            to: nextValue,
            filterStructure,
          }),
        );
      }
    },
    [filterStructure, inputDebounce, inputValueFrom, inputValueTo],
  );

  const isMoneyFilter = useMemo(() => columnType === 'MONEY', [columnType]);
  const isIntegerFilter = useMemo(() => columnType === 'INTEGER', [columnType]);

  const title = useMemo(() => {
    const isRange = filterStructure === FILTER_STRUCRURE.range;
    const isSomeValue = Boolean(inputValueFrom) || Boolean(inputValueTo);
    if (isRange && isSomeValue) {
      return `${inputValueFrom || ' '} - ${inputValueTo || ' '}`;
    } else {
      return inputValueFrom;
    }
  }, [filterStructure, inputValueFrom, inputValueTo]);

  return {
    title,
    inputValueTo,
    isMoneyFilter,
    inputValueFrom,
    isIntegerFilter,
    onToInputChange,
    onFromInputChange,
  };
};

export default useController;
