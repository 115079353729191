import React from 'react';
import * as R from 'ramda';

import { ReferenceItem, TextInput, FormComponent, Reference, Radio, UploadImage, TextDateTime, TextInputMode } from 'components';

import { Person, UserPermission } from 'types/models';

type Props = {
  scientist: Person.Scientist | null;
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  onChange(fieldName: keyof Person.Scientist, value: string | ReferenceItem | null): void;
};
function Component(props: Props) {
  const { scientist, person, onChange } = props;
  if (!person) {
    return null;
  }

  type FieldSettings = {
    onValueChange: (val: keyof Person.Scientist) => void;
    value: string | undefined;
    mode?: TextInputMode;
  };
  const getFieldHandler = (fieldName: keyof Person.Scientist) => R.partial(onChange, [fieldName]);

  const checkFieldTypeByMode = ({ onValueChange, value, mode }: FieldSettings) => (
    <TextInput mode={mode} value={value ?? ''} onChange={onValueChange} />
  );
  return (
    <FormComponent.Wrapper>
      <FormComponent.Description mode="warning">Обязательно добавьте сведения о работе или обучении</FormComponent.Description>

      <FormComponent.ColumnWrapper>
        <FormComponent.Column specialStyles={{ width: 'calc(100% - 250px)' }} hasNoWrap>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field isRequired label="Фамилия">
              <TextInput value={scientist?.lastName ?? ''} onChange={getFieldHandler('lastName')} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field isRequired label="Имя">
              <TextInput value={scientist?.firstName ?? ''} onChange={getFieldHandler('firstName')} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Отчество">
              <TextInput value={scientist?.patronymic ?? ''} onChange={getFieldHandler('patronymic')} />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Фамилия на англ:">
              <TextInput value={scientist?.englishLastName ?? ''} onChange={getFieldHandler('englishLastName')} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Имя на англ:">
              <TextInput value={scientist?.englishFirstName ?? ''} onChange={getFieldHandler('englishFirstName')} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Отчество на англ:">
              <TextInput value={scientist?.englishPatronymic ?? ''} onChange={getFieldHandler('englishPatronymic')} />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line lineSize="doublePadded" hasGap>
            <FormComponent.Field label="Дата рождения" isRequired>
              <TextDateTime value={scientist?.dateBirth} onChange={value => onChange('dateBirth', value)} />
            </FormComponent.Field>
            <FormComponent.Field label="Пол" labelSize="fit">
              <Radio
                value={scientist?.gender}
                list={[
                  { label: 'М', value: 'MALE' },
                  { label: 'Ж', value: 'FEMALE' },
                ]}
                onChange={getFieldHandler('gender')}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field isRequired label="Гражданство">
              <Reference
                name="RefCountry"
                relationTableModalTitle='Справочник "Страны"'
                value={scientist?.citizenship || null}
                onChange={getFieldHandler('citizenship')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="СНИЛС:">
              {checkFieldTypeByMode({
                value: scientist?.snils,
                onValueChange: getFieldHandler('snils'),
                mode: TextInputMode.snils,
              })}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="ИНН:">
              {checkFieldTypeByMode({ value: scientist?.inn, onValueChange: getFieldHandler('inn') })}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Телефон">
              {checkFieldTypeByMode({ onValueChange: getFieldHandler('phone'), value: scientist?.phone })}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Моб. телефон">
              {checkFieldTypeByMode({ onValueChange: getFieldHandler('mobilePhone'), value: scientist?.mobilePhone })}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="E-mail">
              {checkFieldTypeByMode({ onValueChange: getFieldHandler('email'), value: scientist?.email })}
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: '250px', height: '270px' }} hasNoWrap>
          <UploadImage text="Фото профиля" viewMode />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

export const UpperForm = React.memo(Component);
