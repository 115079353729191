import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { Parameter, CheckedListValue } from 'types/models';
import { BuilderProps, CheckboxItem } from 'features/BuildReportPopup/models';

type Props = { initialCheckboxList: CheckboxItem[] } & BuilderProps;

const useController = ({ parameters, setParameterValueByName, parameterName, initialCheckboxList }: Props) => {
  const checkboxListParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );

  const canBuild = useMemo<boolean>(() => !!checkboxListParameter, [checkboxListParameter]);

  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>(initialCheckboxList);

  const setCheckboxIsCheckedByValue = useCallback(
    ({ value, nextIsChecked }: { value: string; nextIsChecked: boolean }) => {
      const nextCheckboxes = checkboxes.map(checkbox => {
        const isCheckboxToUpdate = checkbox.value === value;
        if (isCheckboxToUpdate) {
          return { ...checkbox, isChecked: nextIsChecked };
        }

        return { ...checkbox };
      });

      const nextParameterValue: CheckedListValue = {
        list: nextCheckboxes.map(item => ({ value: item.value, isChecked: item.isChecked })),
      };

      setParameterValueByName({ name: parameterName, value: nextParameterValue });
      setCheckboxes(nextCheckboxes);
    },
    [parameterName, checkboxes, setParameterValueByName],
  );

  useLayoutEffect(() => {
    const nextParameterValue: CheckedListValue = {
      list: initialCheckboxList,
    };

    setParameterValueByName({ name: parameterName, value: nextParameterValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    checkboxListParameter,
    canBuild,
    setCheckboxIsCheckedByValue,
    checkboxes,
  };
};

export default useController;
