import React from 'react';

import { ListEdit } from 'components';

import { Project } from 'types/models';

import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  customers: Project.Customer[];
  setCustomers(customers: Project.Customer[]): void;
  disabled: boolean;
};

export function Customers(props: Props) {
  const { customers, setCustomers, disabled } = props;

  return (
    <ListEdit
      defaultRowsCount={3}
      rows={customers}
      withMessages
      isToolbarDisabled={disabled}
      isDeleteConfirmEnabled
      maxHeight="350px"
      columns={[{ label: 'Наименование заказчика', formatValue: x => `${x.ref.customFields?.fullName} (${x.ref.label})` }]}
      withoutHead
      onChange={setCustomers}
      specification={{
        mode: 'relationTableModal',
        relationTableModalTitle: 'Справочник "Заказчики"',
        modalTableRowConverter: x => ({ id: null, ref: { label: x.label, id: x.id, customFields: { fullName: x.fullName } } }),
        modalTableSpecification: GetReferenceElementList({
          requestData: { filters: [], name: 'RefEnterpriseCustomer' },
        }),
      }}
    />
  );
}
