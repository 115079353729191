import { useCallback, useMemo, useState } from 'react';

import * as R from 'ramda';

import * as BackendAPI from 'services/BackendAPI';

import { Project, Report } from 'types/models';

import { showNotification } from 'features/Notifications';
import { useReportsHook, Reports } from 'features/BuildReportPopup';
import { copyAcademicRank, copyDegree, copyEducation, copyJob, copyRank } from 'utils/Helpers/getHistoryCopy';
import { format, parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { getActualJobPeriod } from 'types/models/Project/helpers';

type Arguments = {
  performers: Project.Performer[];
  setPerformers(performers: Project.Performer[]): void;
  projectId: string | null;
  endProjectDate?: string;
};

export function useController(args: Arguments) {
  const { performers, setPerformers, projectId, endProjectDate } = args;

  const [isOpenEditStagesModal, setIsOpenEditStagesModal] = useState(false);
  const [selectedPerformerIndex, setSelectedPerformerIndex] = useState<number | null>(null);

  const [showOnlyYoungPerformers, setShowOnlyYoungPerformers] = useState<boolean>(false);
  const [showOnlyActualPerformers, setShowOnlyActualPerformers] = useState<boolean>(false);

  const handleCloseEditStagesModal = useCallback(() => {
    setIsOpenEditStagesModal(false);
    setSelectedPerformerIndex(null);
  }, []);

  const setPerformersStages = useCallback(
    (performerStages: Project.PerformerStage[]) => {
      if (selectedPerformerIndex !== null) {
        const updatedPerformers = R.over(
          R.lensIndex(selectedPerformerIndex),
          performer => ({ ...performer, stages: performerStages }),
          performers,
        );
        setPerformers(updatedPerformers);
      }
    },
    [setPerformers, selectedPerformerIndex, performers],
  );

  const selectedPerformer = selectedPerformerIndex !== null ? performers[selectedPerformerIndex] : null;
  const performerStages = selectedPerformer !== null ? selectedPerformer.stages : [];

  const { methods: getPerformersAPI } = BackendAPI.useBackendAPI('GetPerformersFromSecurityDocuments');

  const loadAuthors = useCallback(() => {
    if (projectId) {
      getPerformersAPI.callAPI(
        { projectId },
        {
          onSuccessfullCall: ({ data }) => {
            if (!data.length) {
              showNotification({ message: 'В проекте сведений об охранных документах нет', theme: 'danger' });
            }
            const performersForAdd = getPerformersForAdd(performers, data).map(performer => ({
              ...performer,
              jobPeriods: performer.jobPeriods.map(jobPeriod => ({
                ...jobPeriod,
                job: copyJob(jobPeriod.job),
                education: copyEducation(jobPeriod.education),
                academicRank: copyAcademicRank(jobPeriod.academicRank),
                degree: copyDegree(jobPeriod.degree),
                rank: copyRank(jobPeriod.rank),
              })),
            }));
            setPerformers([...performers, ...performersForAdd]);
            const msg = performersForAdd.length
              ? `Количество добавленных исполнителей - ${performersForAdd.length}`
              : 'Не добавлен ни один автор';
            showNotification({ message: msg, theme: 'success' });
          },
        },
      );
    } else {
      showNotification({ message: 'Для не созданных проектов данная функция недоступна', theme: 'danger' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, performers, setPerformers]);

  const reports = useMemo<Report[]>(() => [Reports.PerformersProject, Reports.СompositionScientificProjectPeriod], []);

  const { getReports, isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({
    reports,
  });

  const onClickPrint = useCallback(
    (_, selectedRowIndex: number | null) => {
      setSelectedPerformerIndex(selectedRowIndex);
      handleSetCurrentReport({ name: Reports.PerformersProject.caption, value: Reports.PerformersProject.name });
    },
    [handleSetCurrentReport],
  );

  const onClickPerformerComposition = useCallback(
    (_, selectedRowIndex: number | null) => {
      setSelectedPerformerIndex(selectedRowIndex);
      handleSetCurrentReport({
        name: Reports.ScienceProjectPerformerCompositionMemo.caption,
        value: Reports.ScienceProjectPerformerCompositionMemo.name,
      });
    },
    [handleSetCurrentReport],
  );

  const filterBabyPerformers = useCallback((pfrms: Project.Performer[]) => {
    return pfrms?.filter(p => {
      return new Date().getFullYear() - 39 < parse(p.person?.scientist?.dateBirth || '', formatStr, new Date()).getFullYear();
    });
  }, []);

  const todayIsWithinJob = useCallback((pfrm: Project.Performer) => {
    return getActualJobPeriod(pfrm) !== null;
  }, []);

  const filterTodayWithinJob = useCallback(
    (pfrms: Project.Performer[]) => {
      return pfrms.filter(p => todayIsWithinJob(p)) || [];
    },
    [todayIsWithinJob],
  );

  const performersForToday = useMemo(() => filterTodayWithinJob(performers) || [], [performers, filterTodayWithinJob]);

  const filteredPerformers = useMemo(() => {
    let fp = performers;
    if (showOnlyActualPerformers) {
      fp = filterTodayWithinJob(fp);
    }
    if (showOnlyYoungPerformers) {
      fp = filterBabyPerformers(fp);
    }
    return fp || [];
  }, [performers, filterTodayWithinJob, filterBabyPerformers, showOnlyActualPerformers, showOnlyYoungPerformers]);

  const projectIsNotOutdated = useMemo(() => {
    return (
      !endProjectDate ||
      endProjectDate === format(new Date(), formatStr) ||
      new Date().getTime() <= parse(endProjectDate || '', formatStr, new Date()).getTime()
    );
  }, [endProjectDate]);

  return {
    isReportOpen,
    currentReport,
    performerStages,
    isOpenEditStagesModal,
    onReportClose,
    onClickPrint,
    onClickPerformerComposition,
    loadAuthors,
    setPerformersStages,
    handleCloseEditStagesModal,
    setSelectedPerformerIndex,
    setIsOpenEditStagesModal,
    filterBabyPerformers,
    todayIsWithinJob,
    filterTodayWithinJob,
    performersForToday,
    filteredPerformers,
    showOnlyYoungPerformers,
    setShowOnlyYoungPerformers,
    showOnlyActualPerformers,
    setShowOnlyActualPerformers,
    projectIsNotOutdated,
    getReports,
    handleSetCurrentReport,
  };
}

function getPerformersForAdd(allPerformers: Project.Performer[], newPerformers: Project.Performer[]) {
  return R.reject(
    x => Boolean(allPerformers.find(performer => Boolean((x as any)?.person?.id === performer.person?.id))),
    newPerformers,
  );
}
