import React, { useMemo } from 'react';
import block from 'bem-cn';

import { Button, ButtonMode, FormComponent, Icon, Modal } from 'components';

import { Props as ButtonProps } from 'components/Button';
import { ModalSize } from 'components/Modal';

import './style.scss';

const b = block('confirm-popup');

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  okButtonText?: string;
  resetButtonText?: string;
  onConfirm?: () => void;
  onReset?: () => void;
  children?: JSX.Element | Array<JSX.Element>;
  text?: string;
  icon?: string;
  bodyStyles?: React.CSSProperties;
  size?: ModalSize;
  isDisabled?: boolean;
  additionalButtons?: Array<ButtonProps>;
};

export const ConfirmPopup = React.memo(
  ({
    title,
    text,
    isOpen,
    onClose,
    children,
    okButtonText = '',
    resetButtonText = '',
    onReset,
    onConfirm,
    bodyStyles,
    icon = '',
    size = 'small',
    isDisabled,
    additionalButtons = [],
  }: Props) => {
    const buttons = useMemo<JSX.Element[]>(() => {
      const preparedButtons: JSX.Element[] = [];
      if (okButtonText) {
        preparedButtons.push(
          <Button key="ok" mode={ButtonMode.primary} text={okButtonText} onClick={onConfirm} isDisabled={isDisabled} />,
        );
      }

      if (resetButtonText) {
        preparedButtons.push(
          <Button
            key="reset"
            mode={ButtonMode.secondary}
            text={resetButtonText}
            onClick={onReset || onClose}
            isDisabled={isDisabled}
          />,
        );
      }

      preparedButtons.push(...additionalButtons.map((item, index) => <Button key={index} {...item} />));
      return preparedButtons;
    }, [additionalButtons, isDisabled, okButtonText, onClose, onConfirm, onReset, resetButtonText]);

    return (
      <Modal isOpen={isOpen} onClose={onClose} title={title} size={size}>
        <FormComponent.Wrapper>
          <div className={b('body')} style={bodyStyles}>
            {icon === 'warning' && <Icon type="danger" size={40} classMixin="icon-danger" />}
            {icon === 'info' && <Icon type="info" size={36} classMixin="icon-info" />}

            {text && <p className={b('text')} dangerouslySetInnerHTML={{ __html: text }} />}
            <div>{children}</div>
          </div>
          {!!buttons.length && <FormComponent.Actions>{buttons}</FormComponent.Actions>}
        </FormComponent.Wrapper>
      </Modal>
    );
  },
);
