import { Time } from './types';

const HOURS = { min: 0, max: 24 };
const MINUTES = { min: 0, max: 60 };

export const HOURS_ARRAY: string[] = [];
export const MINUTES_ARRAY: string[] = [];

export const prepareValue = (value: number) => {
  return value > 9 ? value.toString() : `0${value}`;
};

for (let i = HOURS.min; i < HOURS.max; i += 1) {
  HOURS_ARRAY.push(prepareValue(i));
}

for (let i = MINUTES.min; i < MINUTES.max; i += 1) {
  MINUTES_ARRAY.push(prepareValue(i));
}

export const DEFAULT_HOURS = '00';
export const DEFAULT_MINUTES = '00';

export const defaultTime: Time = { hour: DEFAULT_HOURS, minute: DEFAULT_MINUTES };
