import { useState, useMemo, useCallback } from 'react';
import { IconButtonProps, buttonIcons } from 'components';

const useController = () => {
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  const openAddForm = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const closeAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const toolbarButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isDisabled: false,
        onClick: openAddForm,
      },
    ],
    [openAddForm],
  );

  return {
    isAddFormOpen,
    closeAddForm,
    toolbarButtons,
  };
};

export default useController;
