import { Participation, Table } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';
import { EventFormat } from 'utils/Enums';

export function getMockParticipation(row: Table.Entry): Participation {
  return {
    awards: [
      {
        id: '',
        name: row?.name ?? '',
        category: { label: '', value: row.category },
        contest: null,
        file: EMPTY_FILE,
        recipients: [],
        nomination: null,
      },
    ],
    collectiveExhibitName: '',
    createdBy: '',
    departments: [],
    development: row.development,
    documents: [],
    event: {
      name: row.eventName,
      id: '',
      eventStatus: { label: row.eventStatus, id: '' },
      status: '',
      startDate: '',
      endDate: '',
      entity: null,
      fullName: `${row.eventName} ${row.place} (${row.period})`,
      departments: [{ name: row.organizer, id: '' }],
      format: EventFormat.OFFLINE,
    },
    exhibitType: { id: '', label: row.developmentType, code: '' },
    id: row.id,
    incentivePaymentRequest: '',
    isAllowEdit: false,
    isCollectiveExhibit: false,
    members: [],
    mobileRequests: [],
    note: '',
    participationProcedure: '',
    projects: [],
    publication: null,
    reportDate: '',
    reportTheme: '',
    reportType: null,
    status: { label: '', value: '' },
    teamName: '',
    presentationForms: [],
    form: null,
    financingProjects: [],
    presentationProject: null,
  };
}
