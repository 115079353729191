import React, { useCallback, useEffect, useMemo } from 'react';
import { block } from 'bem-cn';

import { buttonIcons, IconButtonProps, InputSelect, Option, ReferenceItem, RelationTableModal, Toolbar } from 'components';
import { Person } from 'types/models';
import { GetReferenceScientistListSpecification } from 'features/Table/specifications';
import { useController } from './controller';
import { getMockMember } from './helpers';
import { PersonHistoryModal } from './PersonHistoryModal/PersonHistoryModal';
import { SubmitTable } from 'features/Table/streams';

import './style.scss';

const b = block('person-field');

export type SelectPersonButton = 'change' | 'delete' | 'history';

export type Member = {
  person: Person.ScientistPerson | null;
  job: Person.ScientistJob | null;
  education: Person.ScientistEducation | null;
  degree: Person.ScientistDegree | null;
  citizenship: ReferenceItem | null;
  rank: Person.ScientistRank | null;
  academicRank: Person.ScientistAcademicRank | null;
};

type Props = {
  member: Member | null;
  personId?: string | null;
  onUpdateMember(member: Member | null): void;
  onUpdateBibliographicNames?(bibliographicName: Person.BibliographicName[]): void;
  getPersonRef?(ref: PersonRef): void;
  disabled?: boolean;
  withHistory?: boolean;
  isRequired?: boolean;
  memberLabel?: string;
  hiddenButtons?: SelectPersonButton[];
  isSelectDisabled?: boolean;
};

export type PersonRef = {
  reloadPersonBiblographicNames(): void;
};

function SelectPerson(props: Props) {
  const {
    member,
    onUpdateMember,
    onUpdateBibliographicNames,
    personId,
    getPersonRef,
    withHistory = true,
    disabled = false,
    isRequired = false,
    memberLabel,
    hiddenButtons = [],
    isSelectDisabled = false,
  } = props;
  const {
    isLoadingSuggestions,
    suggestions,
    isOpenHistoryModal,
    closeHistoryModal,
    setSearchPersonValue,
    openHistoryModal,
    resetPerson,
    isOpenPersonListModal,
    isLoadPersonAfterHistoryOpen,
    isLoadingScientist,
    openPersonListModal,
    closePersonListModal,
    loadScientist,
    historyMode,
    reloadPersonBiblographicNames,
    setHistoryMode,
  } = useController({ member, onUpdateMember, onUpdateBibliographicNames });

  useEffect(() => {
    if (personId && !member?.person) {
      loadScientist(personId, withHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  useEffect(() => {
    if (getPersonRef) {
      getPersonRef({
        reloadPersonBiblographicNames: () => {
          if (member?.person?.id) {
            // loadScientist(member.person.id, false);
            reloadPersonBiblographicNames(member.person.id);
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPersonRef, member?.person]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.list,
        title: 'Справочник "Персоны"',
        code: 'list',
        isDisabled: disabled,
        isHidden: hiddenButtons.includes('change'),
        onClick: event => {
          event?.currentTarget.blur();
          openPersonListModal();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Очистить',
        code: 'remove',
        isHidden: hiddenButtons.includes('delete'),
        isDisabled: !member?.person || disabled,
        onClick: resetPerson,
      },
      {
        icons: buttonIcons.clock,
        title: 'История персоны',
        code: 'history',
        isHidden: hiddenButtons.includes('history') || !withHistory,
        isDisabled: !member?.person || disabled,
        onClick: () => {
          setHistoryMode('edit');
          openHistoryModal(true);
        },
      },
    ],
    [disabled, hiddenButtons, member?.person, resetPerson, withHistory, openPersonListModal, setHistoryMode, openHistoryModal],
  );

  const onSubmitTable = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      if (row) {
        loadScientist(row.id, withHistory);
      }
      closePersonListModal();
    },
    [loadScientist, closePersonListModal, withHistory],
  );

  const handleChangePersonSelect = useCallback(
    (option: Option | null) => {
      if (option) {
        loadScientist(option.value, withHistory);
      }
    },
    [loadScientist, withHistory],
  );

  const submitHitoryModal = useCallback(
    (x: Partial<Member>) => {
      onUpdateMember({ ...(member ?? getMockMember()), ...x });
    },
    [member, onUpdateMember],
  );

  return (
    <>
      <div className={b()}>
        <InputSelect
          onSelectChange={handleChangePersonSelect}
          onInputChange={setSearchPersonValue}
          options={suggestions.map<Option>(x => ({ label: x.label, value: x.id }))}
          value={member?.person ? { label: memberLabel || member.person.fullName, value: member.person.id } : null}
          isLoading={isLoadingSuggestions || isLoadingScientist}
          disabled={disabled || isSelectDisabled}
          isRequired={isRequired}
        />
        <Toolbar buttons={buttons} />
      </div>

      <RelationTableModal
        specification={{ ...GetReferenceScientistListSpecification({ hasSelectButton: true }), onSubmitTable }}
        relationTableModalTitle='Справочник "Персоны"'
        isOpen={isOpenPersonListModal}
        onClose={closePersonListModal}
      />
      <PersonHistoryModal
        member={member}
        isLoadPersonAfterOpen={isLoadPersonAfterHistoryOpen}
        isOpen={isOpenHistoryModal}
        onClose={closeHistoryModal}
        onSubmit={submitHitoryModal}
        mode={historyMode}
      />
    </>
  );
}

export { SelectPerson };
