import React from 'react';

import { SectionTitle, ConfirmPopup, FormComponent, Checkbox, buttonIcons, IconButton, Modal } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { ProlongateRequests } from './ProlongateRequests/ProlongateRequests';
import { useController } from './controller';
import { SelectProgramRequest } from 'features/Form/looks/project/ProjectForm/views/SelectProgramRequest';
import { SelectNir } from 'features/Form/looks/project/ProjectForm/views/SelectNir';
import { CopyFromNirRequest, CopyFromProgramRequest } from 'features/Form/looks/project/ProjectForm/types';
import { SelectSecurityDocumentContract } from 'features/Form/looks/project/ProjectForm/views';

export type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
  copyFromNirRequest({ id, nextOverheadPercent }: CopyFromNirRequest): void;
  copyFormProgramRequest({ id }: CopyFromProgramRequest): void;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
};

const Component: React.FC<Props> = (props: Props) => {
  const { disabled, project, makeChangeHandler, copyFromNirRequest, copyFormProgramRequest, setProject } = props;
  const {
    nirRequestExtraButtons,
    programRequestExtraButtons,
    isNirRequestOptionsPopupOpen,
    isResetOverheadPercentOpen,
    onResetResetOverheadPercent,
    onConfirmResetOverheadPercent,
    onResetOverheadPercentClose,
    setIsNirRequestOptionsPopupOpen,
    confirmNirRequestOptions,
    setProgramRequest,
    setSecurityDocumentContract,
    setNirRequest,
    nirRequestCopyOptions,
    setNirRequestCopyOptions,
    programRequestCopyOptions,
    setProgramRequestCopyOptions,
    isProgramRequestOptionsPopupOpen,
    setIsProgramRequestOptionsPopupOpen,
    confirmProgramRequestOptions,
    helpButton,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    disabled,
    copyFromNirRequest,
    copyFormProgramRequest,
    makeChangeHandler,
  });
  const helpModalTitle =
    'Помощь при копировании данных в проект из заявок на конкурс, на внутренний конкурс и заявок на актуализацию и продления.';
  return (
    <>
      <IconButton {...helpButton} />
      <FormComponent.Line lineSize="half">
        <SelectSecurityDocumentContract
          setSecurityDocumentContract={setSecurityDocumentContract}
          securityDocumentContract={project?.securityDocumentContract ?? null}
          label="Договор на ИС"
          modalTitle='Справочник "Договоры на ИС"'
          isDisabled={disabled}
        />
      </FormComponent.Line>
      {!!project?.securityDocumentContract?.securityDocuments?.length && (
        <FormComponent.Line lineSize="half">
          <FormComponent.Field label="">
            <FormComponent.Description mode="warning" classMixin="is-hint">
              <pre>{project?.securityDocumentContract?.securityDocuments?.map(doc => doc.label).join('\n')}</pre>
            </FormComponent.Description>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line lineSize="half">
        <SelectProgramRequest
          setProgramRequest={setProgramRequest}
          programRequest={project?.programRequest ?? null}
          label="Заявка на конкурс"
          modalTitle='Справочник "Заявки на конкурс программы"'
          isDisabled={disabled}
          extraButtons={programRequestExtraButtons}
        />
      </FormComponent.Line>
      {!!project?.programRequest && (
        <FormComponent.Line>
          <FormComponent.Field label="История копирования">
            <pre>
              {[project?.programRequestDataCopyDetail, project?.newProgramRequestDataCopyDetail].filter(x => x).join('\n')}
            </pre>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line lineSize="half">
        <SelectNir
          nir={project?.nirRequest ?? null}
          setNir={setNirRequest}
          label="Заявка на внутр. конкурс"
          modalTitle='Справочник "Заявки на внутренний конкурс"'
          extraButtons={nirRequestExtraButtons}
          isDisabled={disabled}
        />
      </FormComponent.Line>
      {!!project?.nirRequest && (
        <FormComponent.Line>
          <FormComponent.Field label="История копирования">
            <pre>{[project?.nirRequestDataCopyDetail, project?.newNirRequestDataCopyDetail].filter(x => x).join('\n')}</pre>
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <SectionTitle title="Заявки для актуализации данных этапа проекта и заявки на продление" />
      <ProlongateRequests
        contestRequests={project?.contestRequests ?? []}
        setContestRequests={makeChangeHandler('contestRequests')}
        disabled={disabled}
        stages={project?.stages ?? []}
        setProject={setProject}
      />

      <ConfirmPopup
        title="Копирование из заявки на внутренний конкурс: выберите разделы"
        isOpen={isNirRequestOptionsPopupOpen}
        okButtonText="Продолжить"
        resetButtonText="Отмена"
        onClose={() => setIsNirRequestOptionsPopupOpen(false)}
        onConfirm={confirmNirRequestOptions}
        size="medium"
      >
        <FormComponent.Wrapper>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={nirRequestCopyOptions.isProject}
                onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isProject: e }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Описание проекта</strong> - Данные будут скопированы на вкладки "Описание" и "Классификаторы":
              <br />
              OECD, Аннотация, ГРНТИ, Ключевые слова, Критические технологии, Научный задел, Обоснование междисциплинарного
              подхода, Ожидаемые результаты, Приоритетные направления развития науки, технологий и техники, Приоритетные
              направления развития, Стратегии научно-технического развития РФ, Тема проекта, УДК, Характер проекта, Цель
            </span>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={nirRequestCopyOptions.isStage}
                onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isStage: e }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Описание этапов</strong> - Данные будут скопированы на форму редактирования этапа на вкладке "Этапы"
            </span>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={nirRequestCopyOptions.isIndicator}
                onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isIndicator: e }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Индикаторы и показатели</strong> - Плановые значения показателей и индикаторов на этап будут скопированы в
              раздел на вкладке "Результаты"
            </span>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={nirRequestCopyOptions.isPerformers}
                onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isPerformers: e }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Список исполнителей</strong> - Обновление списка исполнителей на вкладке "Коллектив" с периодом работы,
              равным срокам проекта
            </span>
          </FormComponent.Line>
        </FormComponent.Wrapper>
        <span>
          <strong>Внимание: При копировании имеющиеся значения из списка копирования в проекте будут удалены!</strong>
        </span>
      </ConfirmPopup>

      <ConfirmPopup
        title="Копирование из заявки на конкурс: выберите разделы"
        isOpen={isProgramRequestOptionsPopupOpen}
        okButtonText="Продолжить"
        resetButtonText="Отмена"
        onClose={() => setIsProgramRequestOptionsPopupOpen(false)}
        onConfirm={confirmProgramRequestOptions}
        size="medium"
      >
        <FormComponent.Wrapper>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={programRequestCopyOptions.isProject}
                onChange={e => setProgramRequestCopyOptions(prevState => ({ ...prevState, isProject: e }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Описание проекта</strong> - следующие данные будут скопированы на вкладку <strong>Описание</strong>:
              <br />
              Аннотация, ГРНТИ, Ключевые слова, Критические технологии, 2-Наука (ОКВЭД, ЛКСЭЦ, Отрасль науки), Приоритетные
              направления развития науки, технологий и техники, Стратегии научно-технического развития РФ, Тема проекта, УДК
            </span>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={programRequestCopyOptions.isClassifiers}
                onChange={e => setProgramRequestCopyOptions(prevState => ({ ...prevState, isClassifiers: e }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Классификаторы</strong> - следующие данные будут скопированы на вкладку <strong>Классификаторы</strong>:
              <br />
              Области знаний, Области научных интересов, Приоритетные направления развития модернизации и технического развития
              экономики России, Приоритетные направления развития, Технологические платформы
            </span>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={programRequestCopyOptions.isPerformers}
                onChange={e => setProgramRequestCopyOptions(prevState => ({ ...prevState, isPerformers: e }))}
              />
            </FormComponent.Field>
            <span>
              <strong>Список исполнителей</strong> - обновление списка исполнителей на вкладке <strong>Коллектив</strong> с
              периодом работы, равным срокам проекта
            </span>
          </FormComponent.Line>
        </FormComponent.Wrapper>
        <span>
          <strong>Внимание: При копировании имеющиеся значения из списка копирования в проекте будут удалены!</strong>
        </span>
      </ConfirmPopup>

      <ConfirmPopup
        title="Нормативный процент накладных расходов"
        text='Установить значение в поле "Нормативный процент накладных расходов" равное 0?'
        isOpen={isResetOverheadPercentOpen}
        okButtonText="Да"
        resetButtonText="Нет"
        icon="info"
        onReset={onResetResetOverheadPercent}
        onClose={onResetOverheadPercentClose}
        onConfirm={onConfirmResetOverheadPercent}
      />
      <Modal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} title={helpModalTitle} size="medium">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Функционал предназначен для копирования одноименных полей и данных из&nbsp;
              <strong>Заявок на конкурс, Заявок на внутренний конкурс</strong> и&nbsp;
              <strong>Заявок на актуализацию и продления</strong> в проект. Для копирования необходимо выбрать заявку на конкурс
              или заявку на внутренний конкурс из справочника (примечание: программа заявки должна совпадать с программой
              проекта), далее нажать на кнопку <IconButton icons={buttonIcons.clone} />
              <strong>Скопировать данные из заявки в проект.</strong> В открывшемся окне выберете один или несколько пунктов для
              копирования.
            </p>
            <p>
              Для копирования данных из <strong>Заявки на актуализацию и продления</strong> необходимо выбрать нужную заявку из
              таблицы <strong>Заявки для актуализации данных этапа проекта и заявки на продление</strong> (для копирования
              доступны заявки на продление проекта в статусе – поддержана, для заявок на актуализацию данных в статусе -
              согласована) и нажать на кнопку <IconButton icons={buttonIcons.clone} />
              <strong>Скопировать из заявки на продление.</strong> В открывшемся окне выберете один или несколько пунктов для
              копирования.
            </p>
            <p>В зависимости от выбранной заявки, для копирования доступен свой набор полей.</p>
            <p>
              <strong className="primary">Внимание:</strong> При копировании имеющиеся значения из списка копирования в проекте
              будут удалены!
            </p>
            <h3>Для копирования в проект из Заявок на конкурс доступны следующие данные:</h3>
            <strong>1. Описание проекта</strong>
            <p>
              Из заявки (вкладка Описание) в проект на вкладку <strong>Описание</strong> будут скопированы:
            </p>
            <ul>
              <li>Аннотация</li>
              <li>ГРНТИ</li>
              <li>Ключевые слова</li>
              <li>Критические технологии</li>
              <li>2-Наука (ОКВЭД, ЛКСЭЦ, Отрасль науки)</li>
              <li>Приоритетные направления развития науки, технологий и техники</li>
              <li>Стратегии научно-технического развития РФ</li>
              <li>Тема проекта</li>
              <li>УДК</li>
            </ul>
            <strong>2. Классификаторы</strong>
            <p>
              Из заявки (вкладка Классификаторы) в проект на вкладку <strong>Классификаторы</strong> будут скопированы:
            </p>
            <ul>
              <li>Области научных интересов</li>
              <li>Области знаний</li>
              <li>Приоритетные направления развития модернизации и технического развития экономики России</li>
              <li>Приоритетные направления развития</li>
              <li>Технологические платформы</li>
            </ul>
            <strong>3. Список исполнителей</strong>
            <p>
              При запуске процедуры копирования в проект будут добавлены все участники коллектива, указанного в заявке. При
              добавлении участника копируются следующие данные из заявки:
            </p>
            <ul>
              <li>ФИО</li>
              <li>Сведения о работе/обучении, учёная степень, звание</li>
              <li>роль</li>
              <li>период работы (равный срокам проекта)</li>
              <li>отметка об участии во всех этапах проекта</li>
            </ul>
            <h3>Для копирования в проект из Заявок на внутренний конкурс доступны следующие данные:</h3>
            <strong>1. Описание проекта</strong>
            <p>
              Из заявки (вкладка Описание) на вкладки <strong>Описание</strong> и <strong>Классификаторы</strong> будут
              скопированы:
            </p>
            <strong>Копирование Описание из заявки в проект:</strong>
            <ul>
              <li>Название проекта</li>
              <li>Цель исследования &rarr; Цель</li>
              <li>Характер исследования &rarr; Характер проекта</li>
              <li>Аннотация исследования &rarr; Аннотация</li>
              <li>
                Планируемые научные результаты исследования за первый год + Планируемые научные результаты исследования за второй
                год &rarr; Ожидаемые результаты (с заголовками с новой строки «...год» «... год»)
              </li>
              <li>Имеющийся научный задел по теме &rarr; Научный задел</li>
              <li>Обоснование междисциплинарного подхода (на вкладке Заявка) &rarr; Обоснование междисциплинарного подхода</li>
              <li>Ключевые слова &rarr; Ключевые слова</li>
            </ul>
            <strong>Копирование Классификаторов из заявки в проект:</strong>
            <ul>
              <li>Стратегии научно-технического развития РФ</li>
              <li>Критические технологии</li>
              <li>УДК</li>
              <li>ГРНТИ</li>
              <li>OECD (Коды международной классификации отраслей наук)</li>
              <li>Приоритетные направления Программы развития</li>
              <li>Приоритетные направления развития науки, технологий и техники</li>
            </ul>
            <strong>2. Описание этапов</strong>
            <p>
              Данные будут скопированы из заявки (вкладка Описание проекта) на форму редактирования этапа на вкладку{' '}
              <strong>Этапы:</strong>
            </p>
            <ul>
              <li>Название этапа &rarr; Название этапа из раздела "Календарный план и финансирование проекта"</li>
              <li>Аннотация работ &rarr; Аннотация</li>
              <li>Цель этапа НИР &rarr; Цели</li>
              <li>Задачи этапа &rarr; Задачи исследования на первый год (вкладка Описание)</li>
              <li>Ожидаемые результаты &rarr; Планируемые научные результаты исследования за первый год</li>
              <li>Эффект на уровне университета &rarr; Эффект на уровне университета</li>
              <li>Эффект на уровне региональном &rarr; Эффект на уровне региональном</li>
              <li>Эффект на уровне национальном &rarr; Эффект на уровне национальном</li>
            </ul>
            <strong>3. Индикаторы и показатели</strong>
            <p>
              Плановые значения показателей и индикаторов на этап будут скопированы в раздел на вкладке{' '}
              <strong>Результаты.</strong>
            </p>
            <p>Формат копирования Индикатора:</p>
            <ul>
              <li>название индикатора</li>
              <li>этап</li>
              <li>плановое значение по индикатору из заявки</li>
            </ul>
            <p>Формат копирования Показателя:</p>
            <ul>
              <li>название показателя</li>
              <li>этап</li>
              <li>плановое значение по показателю из заявки</li>
            </ul>
            <strong>4. Список исполнителей</strong>
            <p>
              При запуске процедуры копирования в проект будут добавлены все участники коллектива, указанного в заявке. При
              добавлении участника копируются следующие данные из заявки:
            </p>
            <ul>
              <li>ФИО</li>
              <li>Сведения о работе/обучении, учёная степень, звание</li>
              <li>роль</li>
              <li>период работы (равный срокам проекта)</li>
              <li>отметка об участии во всех этапах проекта</li>
            </ul>
            <p>
              <strong className="primary">Внимание:</strong> В случае если в проекте уже присутствует часть коллектива, то при
              копировании данные будут перезаписаны (роль, сведения о работе/обучении, учёная степень, звание, период работы,
              отметка об участии во всех этапах проекта)
            </p>
            <h3>Для копирования в проект из Заявок на актуализацию и продления доступны следующие данные:</h3>
            <strong>1. Описание этапа</strong>
            <p>
              Данные будут скопированы на форму редактирования этапа на вкладке <strong>Календарный план.</strong>
            </p>
            <ul>
              <li>Название этапа</li>
              <li>Аннотация работ</li>
              <li>Цель этапа НИР &rarr; Цели этапа</li>
              <li>План работ на этап (задачи) &rarr; Задачи этапа</li>
              <li>Ожидаемые результаты по этапу &rarr; Ожидаемые результаты</li>
              <li>Дополнительная информация (описание работ) &rarr; Описание работ</li>
              <li>Планируется охрана РИД</li>
              <li>Эффект на уровне университета</li>
              <li>Эффект на уровне региональном</li>
              <li>Эффект на уровне национальном</li>
            </ul>
            <strong>2. Индикаторы и показатели</strong>
            <p>
              Плановые значения показателей и индикаторов на этап будут скопированы в раздел на вкладке&rarr;
              <strong>Результаты</strong> в формате:
            </p>
            <ul>
              <li>индикатор</li>
              <li>этап</li>
              <li>плановое значение из заявки</li>
            </ul>
            <strong>3. Список исполнителей</strong>
            <p>
              В проект будут добавлены все имеющиеся исполнители в заявке на вкладке <strong>Коллектив</strong>, которых нет среди
              исполнителей в проекте с указанной ролью.
            </p>
            <p>При копировании участника добавляются следующие данные из заявки:</p>
            <ul>
              <li>ФИО</li>
              <li>период работы (равный срокам этапа)</li>
              <li>Участие в этапе</li>
            </ul>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};

export const Requests = React.memo(Component) as typeof Component;
