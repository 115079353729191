import { parse } from 'date-fns';
import { Subject } from 'types/models';
import { SecurityDocument } from 'types/models/PatentResearch';
import { formatStr } from 'utils/Constants/FormatStr';

export function getMockSubject(): Subject {
  return {
    id: null,
    type: { label: '', value: '' },
    code: '',
    name: '',
    description: '',
    rntds: [],
    mips: [],
    grntis: [],
    udks: [],
    okveds: [],
    lksetss: [],
    scienceBrunches: [],
    scienceDomainInterrests: [],
    pnrs: [],
    pnmitrs: [],
    pnis: [],
    ntrStrategies: [],
    technologyPlatforms: [],
    priorityTechnologies: [],
    domainKnowledges: [],
    documents: [],
    foreignSecurityDocuments: [],
  };
}

export function getSecurityDocumentInfo(securityDocuments: SecurityDocument[] | string | undefined): string {
  if (typeof securityDocuments === 'string') return securityDocuments;
  if (securityDocuments?.length) {
    const {
      type,
      intellectualPropertyName,
      requestNumber,
      requestDate,
      documentNumber,
      documentStartDate,
      documentEndDate,
    } = securityDocuments[0];
    const info = documentNumber
      ? `${type?.label} № ${documentNumber} ${intellectualPropertyName} (${documentStartDate} - ${documentEndDate})`
      : `Заявка на ${type?.label} № ${requestNumber} от ${requestDate}`;
    return info;
  }
  return '';
}

export function getSecurityDocumentMembers(
  securityDocuments: SecurityDocument[] | string | undefined,
  memberType: string,
): string {
  if (typeof securityDocuments === 'string') return '';
  if (securityDocuments?.length) {
    const { members } = securityDocuments[0];
    const applicants: string[] = [];
    members.forEach(x => {
      if (x.type?.value === memberType) applicants.push(x.name);
    });
    const result = applicants.filter(x => x).join(', ');
    return result;
  }
  return '';
}

export function getIsCurrentSecurityDocument(securityDocuments: SecurityDocument[] | string | undefined): boolean {
  if (typeof securityDocuments === 'string') return false;
  if (securityDocuments?.length) {
    const { documentEndDate } = securityDocuments[0];
    return parse(documentEndDate || '', formatStr, new Date()) >= new Date();
  }
  return false;
}
