import React, { useCallback, useState } from 'react';
import { parse } from 'date-fns';

import { buttonIcons, FormComponent, ListEdit, Modal } from 'components';
import { PatentResearch, Project } from 'types/models';
import { useReportsHook, Reports, BuildReportPopup } from 'features/BuildReportPopup';

import { getMockStage, getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';

import { formatStr } from 'utils/Constants/FormatStr';

type Props = {
  project: Project.Project | null;
  disabled: boolean;
  setStages(stages: Project.Stage[]): void;
  getPatentResearchByStageId(stageId: string): PatentResearch | null;
};

export function CalendarPlan(props: Props) {
  const { project, disabled, setStages, getPatentResearchByStageId } = props;

  const [selectedStageIndex, setSelectedStageIndex] = useState<number | null>(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const onClickPrint = useCallback(
    (_, selectedRowIndex: number | null) => {
      setSelectedStageIndex(selectedRowIndex);
      handleSetCurrentReport({
        name: Reports.ProjectStagePerformersSimple.caption,
        value: Reports.ProjectStagePerformersSimple.name,
      });
    },
    [handleSetCurrentReport],
  );

  return (
    <>
      <ListEdit
        title="Список этапов проекта"
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        rows={project?.stages || []}
        onChange={setStages}
        defaultRowsCount={10}
        visibleToolbarButtons={['edit']}
        isFullScreenedTable
        extraToolbarButtons={[
          {
            icons: buttonIcons.print,
            title: 'Печать исполнителей проекта',
            checkIsDisabled: row => !row || disabled,
            onClick: onClickPrint,
          },
          {
            icons: buttonIcons.toolbarHelp,
            title: 'Помощь',
            onClick: () => setIsHelpModalOpen(true),
          },
        ]}
        columns={[
          { label: 'Этап', formatValue: getStageTitle },
          { label: 'Название этапа', formatValue: x => x.name ?? '' },
          { label: 'Получены результаты этапа', formatValue: x => x.obtainedResults ?? '' },
          {
            label: 'Отчёт',
            formatValue: x =>
              (() => {
                const report = x.reports[0];
                if (!report) return '';
                // eslint-disable-next-line max-len
                return `${report.name || ''}: отчёт о НИР (${report.type?.label || ''}); - ГР ${report.number || ''}. - Инв. ${
                  report.ontiNumber || ''
                } (Отправлен заказчику: ${report.sendCustomerDate || ''})`;
              })(),
          },
          {
            label: 'Охрана РИД',
            formatValue: x =>
              !!x.isPatentResearchPlanned ||
              (() => {
                const matchedSecurityDocument = project?.securityDocuments.find(securityDocument =>
                  securityDocument.documentDate
                    ? parse(securityDocument.documentDate, formatStr, new Date()) >= parse(x.startDate, formatStr, new Date()) &&
                      parse(securityDocument.documentDate, formatStr, new Date()) <= parse(x.endDate, formatStr, new Date())
                    : securityDocument.requestDate
                    ? parse(securityDocument.documentDate, formatStr, new Date()) >= parse(x.startDate, formatStr, new Date()) &&
                      parse(securityDocument.documentDate, formatStr, new Date()) <= parse(x.endDate, formatStr, new Date())
                    : false,
                );
                return matchedSecurityDocument?.label || '';
              })(),
          },
          {
            label: 'Закупки',
            formatValue: x =>
              (() => {
                const matchedPurchaseRequest = (project?.purchaseRequests || []).find(
                  purchaseRequest =>
                    parse(purchaseRequest.sendDate, formatStr, new Date()) >= parse(x.startDate, formatStr, new Date()) &&
                    parse(purchaseRequest.sendDate, formatStr, new Date()) <= parse(x.endDate, formatStr, new Date()),
                );
                return matchedPurchaseRequest ? `${matchedPurchaseRequest.name} (${matchedPurchaseRequest.status?.label})` : '';
              })(),
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (stage, setStage) => (
            <Fields
              stage={stage || getMockStage()}
              setStage={setStage}
              project={project}
              getPatentResearchByStageId={getPatentResearchByStageId}
            />
          ),
        }}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        stageId={selectedStageIndex !== null ? (project?.stages || [])[selectedStageIndex]?.id ?? '' : undefined}
      />
      <Modal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} title="Вкладка Этапы проекта" size="medium">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Список этапов проекта формируется автоматически на основе данных раздела <strong>Календарный план</strong> на
              вкладке&nbsp;
              <strong>О проекте</strong>
            </p>
            <p>
              Отчёт <strong>Состав научного коллектива проекта</strong> строится по данным об исполнителях проекта на вкладке
              Коллектив. В отчёте выводится список исполнителей, у которых указан период работы в проекте, пересекающийся со
              сроками выделенного этапа
            </p>
            <p>
              <strong className="primary">ВАЖНО!</strong> Если на вкладке Госрегистрация в ЦИТиС запланирован отчёт по итогам
              этапа. То такой этап невозможно удалить
            </p>
            <h3>Особенности заполнения формы этапа:</h3>
            <p>В системе предусмотрены процедуры для автозаполнения некоторых полей на форме этапа:</p>
            <ol>
              <li>
                При копировании данных из связанной заявки (заявка на конкурс, заявка на внутренний конкурс, заявка на продление
                проекта — смотри на вкладке <strong>Заявки</strong>) — на основе метаданных заявки
              </li>
              <li>При заполнении сведений об отчёте, который регистрируется в ЦИТиС</li>
            </ol>
            <p>Так, например, при копировании из заявки заполняются поля:</p>
            <ul>
              <li>Название этапа</li>
              <li>Аннотация работ</li>
              <li>Цель этапа НИР</li>
              <li>Задачи этапа</li>
              <li>Ожидаемые результаты</li>
              <li>Описание работ</li>
              <li>Планируется охрана РИД</li>
              <li>Эффект на уровне университета</li>
              <li>Эффект на уровне региональном</li>
              <li>Эффект на уровне национальном</li>
            </ul>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}
