import { Table } from 'types/models';

export const GetExhibitionParticipationForRntd: Table.Specification = {
  apiID: 'GetExhibitionParticipationForRntd',
  header: {
    firstLevel: {
      title: 'Участия в выставках',
    },
    thirdLevel: {
      withTemplatesPanel: true,
    },
  },
};
