import React, { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';

import { Table } from 'types/models';
import {
  IconButtonProps,
  Toolbar as SharedToolbar,
  buttonIcons,
  ConfirmPopup,
  RelationTableModal,
  CommentPopup,
} from 'components';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { State, Term } from '../model';
import { SelectInstanceModal } from './SelectInstanceModal/SelectInstanceModal';
import { TermPresetModal } from './TermPresetModal/TermPresetModal';

import { checkPaymentByStatus, mapTitle } from '../helpers';

import { usePermissions } from './usePermissions';
import { getEnum } from 'utils/Helpers';

type Props = {
  tableState: Table.State;
  customState: State;
};

function Component(props: Props) {
  const { tableState, customState } = props;
  const { enumMap } = useAppDataContext();
  const {
    openSelectInstanceModal,
    closeSelectInstanceModal,
    isOpenSelectInstanceModal,
    instanceValue,
    setInstanceValue,
    loadAuthorPayment,
    setMode,
    comment,
    setComment,
    isOpenCommentForm,
    closeCommentForm,
    changeStatus,
    openCommentForm,
    deletePayment,
    isOpenTermPresetModal,
    setIsOpenTermPresetModal,
    getTermPreset,
    changeTerm,
    termPreset,
    event,
    isReportOpen,
    onReportClose,
    currentReport,
    getReports,
    handleSetCurrentReport,
  } = customState;

  const [selectedStatus, setSelectedStatus] = useState<AuthorPaymentRequestStatus | null>(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenMessageLogModal, setIsOpenMessageLogModal] = useState(false);

  const [row] = tableState.selectedRows;
  const status = row ? (row['id:status'] as AuthorPaymentRequestStatus) : null;
  const changeStatusTo = useCallback(
    (newStatus: AuthorPaymentRequestStatus) => {
      setSelectedStatus(newStatus);
      if (checkPaymentByStatus(row?.factNomineesMoney ?? null, newStatus)) {
        setIsOpenConfirmModal(true);
      } else {
        openCommentForm();
      }
    },
    [row, openCommentForm, setIsOpenConfirmModal],
  );

  const { userPermission, settings, userSystemDepartment } = useAppDataContext();

  const defaultRequestType = useMemo(() => {
    const enumValue = getEnum('PaymentRequestType', enumMap).find(({ value }) => value === event);
    if (enumValue) {
      return enumValue;
    }
  }, [enumMap, event]);

  const defaultRequestTypeValue = useMemo(() => {
    if (defaultRequestType) {
      return (defaultRequestType.ordinal || 0).toString();
    }

    return '-1';
  }, [defaultRequestType]);

  const defaultRequestTypeLabel = useMemo(() => {
    if (defaultRequestType) {
      return defaultRequestType.label;
    }

    return '-1';
  }, [defaultRequestType]);

  const permissions = status && usePermissions(event, status);

  const isDisabledEditByStatus = useMemo<boolean>(() => {
    const editPermissionName = permissions?.editablePermissionName;
    if (editPermissionName) {
      return !isHasPermission(userPermission, editPermissionName);
    }

    return false;
  }, [permissions?.editablePermissionName, userPermission]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        permissionName: permissions?.addRequest,
        isDisabled: false,
        onClick: openSelectInstanceModal,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !row,
        onClick: () => {
          setMode('view');
          loadAuthorPayment({ id: row.id, label: row.name }, 'view');
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        permissionName: permissions?.editablePermissionName,
        isDisabled: !row || status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
        onClick: () => {
          setMode('edit');
          loadAuthorPayment({ id: row.id, label: row.name }, 'edit');
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: permissions?.deletablePermissionName,
        isDisabled: !row || status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
        onClick: () => setIsOpenConfirmDeleteModal(true),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать',
        code: 'print',
        isDisabled: false,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
      {
        icons: buttonIcons.payment,
        title: 'Изменить условия оплаты',
        code: 'payment',
        permissionName: permissions?.editFundRequest,
        isDisabled: !row || status === AuthorPaymentRequestStatus.PAYMENT_APPROVED || isDisabledEditByStatus,
        onClick: getTermPreset,
      },
      {
        icons: buttonIcons.like,
        title: 'Подать на согласование',
        code: 'like',
        permissionName: permissions?.toAgreement,
        isDisabled:
          !row ||
          status === AuthorPaymentRequestStatus.AGREEMENT ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.PAID ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.AGREEMENT),
      },
      {
        icons: buttonIcons.paymentHandover,
        title: 'Передать на оплату',
        code: 'paymentHandover',
        permissionName: permissions?.toPaid,
        isDisabled:
          !row ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAID ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID),
      },
      {
        icons: buttonIcons.update,
        title: 'Отправить на доработку',
        code: 'update',
        permissionName: permissions?.toRevision,
        isDisabled:
          !row ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAID ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REVISION),
      },
      {
        icons: buttonIcons.conflictsNone,
        title: 'Одобрить оплату',
        code: 'conflictsNone',
        permissionName: permissions?.toPayment,
        isDisabled:
          !row ||
          status === AuthorPaymentRequestStatus.AGREEMENT ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAYMENT_APPROVED),
      },
      {
        icons: buttonIcons.box,
        title: 'Отложить',
        code: 'box',
        permissionName: permissions?.toPostpone,
        isDisabled:
          !row ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE),
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отклонить',
        code: 'cancel',
        permissionName: permissions?.toRejected,
        isDisabled:
          !row ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
      },
      {
        icons: buttonIcons.comment,
        title: 'Журнал сообщений',
        code: 'feedline',
        isDisabled: !row,
        onClick: () => setIsOpenMessageLogModal(true),
      },
    ],
    [
      permissions?.addRequest,
      permissions?.editablePermissionName,
      permissions?.deletablePermissionName,
      permissions?.editFundRequest,
      permissions?.toAgreement,
      permissions?.toPaid,
      permissions?.toRevision,
      permissions?.toPayment,
      permissions?.toPostpone,
      permissions?.toRejected,
      openSelectInstanceModal,
      row,
      status,
      getReports,
      handleSetCurrentReport,
      isDisabledEditByStatus,
      getTermPreset,
      setMode,
      loadAuthorPayment,
      changeStatusTo,
    ],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <SelectInstanceModal
        event={event}
        isOpen={isOpenSelectInstanceModal}
        value={instanceValue}
        onClose={closeSelectInstanceModal}
        onChange={setInstanceValue}
        onSelect={R.partialRight(loadAuthorPayment, ['add'])}
      />
      <CommentPopup
        title={(mapTitle as any)[String(selectedStatus)]}
        isOpen={isOpenCommentForm}
        onClose={closeCommentForm}
        comment={comment}
        setComment={setComment}
        onSubmit={() => {
          if (row && selectedStatus) {
            changeStatus(row.id, selectedStatus);
            setSelectedStatus(null);
          }
        }}
      />
      <ConfirmPopup
        icon="warning"
        title="Изменение статуса заявки"
        text={checkPaymentByStatus(row?.factNomineesMoney ?? null, selectedStatus)}
        isOpen={isOpenConfirmModal}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmModal(false)}
        onConfirm={() => {
          setIsOpenConfirmModal(false);
          openCommentForm();
        }}
      />
      <ConfirmPopup
        icon="warning"
        title="Подтверждение удаления"
        text="Вы действительно хотите удалить выбранную заявку"
        isOpen={isOpenConfirmDeleteModal}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        onConfirm={() => {
          setIsOpenConfirmDeleteModal(false);
          if (row) {
            deletePayment(row.id);
          }
        }}
      />
      <RelationTableModal
        specification={GetAuthorPaymentRequestFeedlineList({ paymentId: row?.id ?? '', paymentStatus: status! })}
        relationTableModalTitle="Журнал сообщений"
        isOpen={isOpenMessageLogModal}
        onClose={() => setIsOpenMessageLogModal(false)}
      />
      <TermPresetModal
        isOpen={isOpenTermPresetModal}
        onClose={() => setIsOpenTermPresetModal(false)}
        onSubmit={(term: Term) => changeTerm(row.id, term)}
        termPreset={termPreset}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        requestType={defaultRequestTypeValue}
        requestTypeLabel={defaultRequestTypeLabel}
        userDepartment={settings?.userDepartment}
        systemDepartMentLabel={`${userSystemDepartment?.label}` || ''}
      />
    </>
  );
}

export const Toolbar = React.memo(Component);
