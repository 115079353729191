import React, { useMemo, useState } from 'react';

import { Table } from 'types/models';
import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, ConfirmPopup } from 'components';

import { getIsCanDelete } from 'utils/Helpers/feedline/getIsCanDelete';
import { getIsCanEdit } from 'utils/Helpers/feedline/getIsCanEdit';
import { MessageModal } from '../MessageModal/MessageModal';

import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;
  const {
    isOpenModal,
    getPaymentFeedline,
    paymentFeedline,
    savePaymentFeedline,
    changeFeedline,
    closeModal,
    openModal,
    deletePaymentFeedline,
    isDisabledToolbar,
  } = customState;

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const isDeleteButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanDelete(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);

  const [row] = tableState.selectedRows;
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: isDisabledToolbar,
        onClick: () => {
          openModal();
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled || isDisabledToolbar,
        onClick: () => {
          if (row) {
            getPaymentFeedline(row.id);
            openModal();
          }
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'delete',
        isDisabled: isDeleteButtonDisabled || isDisabledToolbar,
        onClick: () => {
          setIsOpenConfirmDeleteModal(true);
        },
      },
    ],
    [getPaymentFeedline, row, isDisabledToolbar, openModal, isDeleteButtonDisabled, isEditButtonDisabled],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <MessageModal
        isOpen={isOpenModal}
        onClose={closeModal}
        changeFeedline={changeFeedline}
        onSave={savePaymentFeedline}
        paymentFeedline={paymentFeedline}
      />
      <ConfirmPopup
        title="Удаление сообщения"
        text="Вы точно хотите удалить выбранное сообщение?"
        isOpen={isOpenConfirmDeleteModal}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        onConfirm={() => {
          deletePaymentFeedline(row.id);
          setIsOpenConfirmDeleteModal(false);
        }}
        icon="warning"
      />
    </>
  );
}

export { Toolbar };
