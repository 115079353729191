import React, { useCallback, useMemo, useState } from 'react';

import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, ConfirmPopup } from 'components';

import { EventType } from 'types/models/Payment';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { usePermissions } from 'features/IncentivePayments/Toolbar/usePermissions';
import { LocalAuthorPayment } from 'features/IncentivePayments/model';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';

type Props = {
  onSave(): void;
  prepareChangeStatusEvent(status: AuthorPaymentRequestStatus): void;
  authorPayment: LocalAuthorPayment | null;
  isViewMode?: boolean;
};

function Toolbar(props: Props) {
  const { onSave, prepareChangeStatusEvent, authorPayment, isViewMode } = props;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [status, setStatus] = useState<AuthorPaymentRequestStatus | null>(null);
  const permissions = usePermissions(authorPayment?.type?.value as EventType, status!);

  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false);
    setStatus(null);
  };

  const changeStatusTo = useCallback(
    (x: AuthorPaymentRequestStatus) => {
      setStatus(x);
      if (checkPaymentByStatus(authorPayment?.factNomineesMoney ?? null, x)) {
        setIsOpenConfirmModal(true);
      } else {
        prepareChangeStatusEvent(x);
      }
    },
    [prepareChangeStatusEvent, authorPayment],
  );

  const statusButtons = useMemo<IconButtonProps[]>(() => {
    if (isViewMode) {
      return [];
    }

    const likeButtons = [
      {
        icons: buttonIcons.like,
        title: 'Подать на согласование',
        code: 'like',
        permissionName: permissions.toAgreement,
        isDisabled: false,
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.AGREEMENT),
      },
    ];
    const mapButtons: Record<AuthorPaymentRequestStatus, IconButtonProps[]> = {
      REVISION: likeButtons,
      DRAFT: likeButtons,
      REJECTED: likeButtons,
      AGREEMENT: [
        {
          icons: buttonIcons.payment,
          title: 'Передать на оплату',
          permissionName: permissions.toPaid,
          code: 'payment',
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID),
        },
        {
          icons: buttonIcons.update,
          title: 'Отправить на доработку',
          code: 'revision',
          permissionName: permissions.toRevision,
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REVISION),
        },
        {
          icons: buttonIcons.box,
          title: 'Отложить',
          code: 'box',
          permissionName: permissions.toPostpone,
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE),
        },
        {
          icons: buttonIcons.dislike,
          title: 'Отклонить',
          permissionName: permissions.toRejected,
          code: 'cancel',
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
        },
      ],
      PAID: [
        {
          icons: buttonIcons.conflictsNone,
          title: 'Одобрить оплату',
          code: 'approve payment',
          isDisabled: false,
          permissionName: permissions.toPayment,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAYMENT_APPROVED),
        },
        {
          icons: buttonIcons.box,
          title: 'Отложить',
          code: 'box',
          permissionName: permissions.toPostpone,
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE),
        },
        {
          icons: buttonIcons.dislike,
          title: 'Отклонить',
          code: 'cancel',
          permissionName: permissions.toRejected,
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
        },
      ],
      POSTPONE: [
        {
          icons: buttonIcons.payment,
          title: 'Передать на оплату',
          permissionName: permissions.toPaid,
          code: 'payment',
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID),
        },
        {
          icons: buttonIcons.dislike,
          title: 'Отклонить',
          permissionName: permissions.toRejected,
          code: 'cancel',
          isDisabled: false,
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
        },
      ],
      PAYMENT_APPROVED: [],
    };

    return authorPayment ? mapButtons[authorPayment.status?.value] : [];
  }, [
    authorPayment,
    changeStatusTo,
    isViewMode,
    permissions.toAgreement,
    permissions.toPaid,
    permissions.toPayment,
    permissions.toPostpone,
    permissions.toRejected,
    permissions.toRevision,
  ]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'save',
        isDisabled: false,
        onClick: onSave,
      },
      ...statusButtons,
    ],
    [onSave, statusButtons],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />
      <ConfirmPopup
        icon="warning"
        title="Изменение статуса заявки"
        text={checkPaymentByStatus(authorPayment?.factNomineesMoney ?? null, status)}
        isOpen={isOpenConfirmModal}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={closeConfirmModal}
        onConfirm={() => {
          if (status !== null) {
            prepareChangeStatusEvent(status);
          }
          closeConfirmModal();
        }}
      />
    </>
  );
}

export { Toolbar };
