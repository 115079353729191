import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, ListEdit, ReferenceItem } from 'components';
import { Column } from 'components/ListEdit/model';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Project } from 'types/models';
import { GetReferenceElementList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { getCurrentTime } from 'utils/Helpers';
import { useController } from './controllers';

type Props = {
  title: string;
  rows: Project.RefElement[];
  onChange(refs: Project.RefElement[]): void;
  modalTitle: string;
  refName: string;
  disabled?: boolean;
  isCanMovingRows?: boolean;
  columns?: Column<Project.RefElement>[];
  extraToolbarButtons?: ExtraToolbarButton<any>[];
};

export function RefList(props: Props) {
  const { modalTitle, onChange, refName, rows, title, isCanMovingRows, columns, extraToolbarButtons, disabled = false } = props;

  const { listEditColumns } = useController({ columns });

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const { currentPerson } = useAppDataContext();

  return (
    <FormComponent.Line>
      <ListEdit
        title={title}
        rows={rows}
        columns={listEditColumns}
        onChange={onChange}
        isToolbarDisabled={disabled}
        defaultRowsCount={3}
        maxHeight="300px"
        isCanMovingRows={isCanMovingRows}
        extraToolbarButtons={[...(extraToolbarButtons || [])]}
        visibleToolbarButtons={['add', 'delete']}
        specification={{
          mode: 'loadInstanceTableModal',
          onStartLoad: (tableRows, onSuccess) => {
            loadReferenceAPI.callAPI(
              { filters: [], referenceName: refName, childIds: tableRows.map(x => x.id) },
              {
                onSuccessfullCall: ({ data }) => {
                  const refElements = (data as ReferenceItem[])
                    .filter(x => !rows.find(row => row.ref?.id === x.id))
                    .map<Project.RefElement>(x => ({
                      id: null,
                      ref: { id: x.id, label: x.label },
                      position: '0',
                      createdBy: currentPerson?.fullName ?? '',
                      createdDate: getCurrentTime(),
                      isActual: true,
                      isProject: true,
                    }));
                  onSuccess(refElements);
                },
              },
            );
          },
          relationTableModalTitle: modalTitle,
          modalTableSpecification: GetReferenceElementList({ requestData: { name: refName, filters: [] } }),
        }}
      />
    </FormComponent.Line>
  );
}
