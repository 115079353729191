import React from 'react';

import { Estimate } from 'types/models';

import { FormComponent, ListEditTable, SectionTitle } from 'components';
import { formatNumber } from 'utils/Helpers';
import { useController } from './controller';

type Props = {
  estimate: Estimate | null;
};

export function ProjectCodeInfo({ estimate }: Props) {
  const { projectLeader, calendarPlanRowIndex, setCalendarPlanRowIndex } = useController({ estimate });

  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Руководитель">
            <span>{projectLeader?.person?.fullName}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Шифр (Проект)">
            <span>
              {estimate?.projectCode?.code} ({estimate?.projectCode?.project?.number})
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тема">
            <span>{estimate?.projectCode?.project?.name})</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Подразделение">
            <span>{estimate?.projectCode?.department?.unit?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Источник">
            <span>{estimate?.projectCode?.financing?.source?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид фин. обеспечения">
            <span>{estimate?.projectCode?.financeProvide?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Заказчик">
            <span>{estimate?.projectCode?.project?.customers[0]?.ref?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид деятельности по уставу">
            <span>{estimate?.projectCode?.project?.activityKind?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="ОКВЭД по доходам">
            <span>{estimate?.projectCode?.project?.okvedIncome?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Характер проекта">
            <span>{estimate?.projectCode?.project?.specification?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Расход бюджета">
            <span>{estimate?.projectCode?.project?.expendituresBudgets?.label}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <SectionTitle title="Календарный план шифра" />
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEditTable
            rows={estimate?.projectCode?.calendarPlansByYears || []}
            columns={[
              { label: 'Год', formatValue: ref => ref.year },
              { label: 'Сумма, план', formatValue: ref => formatNumber(ref.amount) },
              { label: 'Сумма, факт', formatValue: ref => formatNumber(ref.factAmount) },
              { label: 'в т. ч. НДС', formatValue: ref => formatNumber(ref.ndsAmount) },
            ]}
            selectedRowIndex={calendarPlanRowIndex}
            selectRow={setCalendarPlanRowIndex}
            columnIndexesForSumTotal={[1, 2, 3]}
          />
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}
