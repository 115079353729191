import { buttonIcons, IconButtonProps } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useCallback, useMemo, useState } from 'react';
import { Report } from 'types/models';

type Props = {
  saveForm?(): void;
  closeForm?(): void;
};

export function useController({ saveForm, closeForm }: Props) {
  const { currentPerson } = useAppDataContext();

  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);

  const reports = useMemo<Report[]>(
    () => [Reports.Personal, Reports.PublicationList, Reports['PublicationForm2.3'], Reports.PublicationExtendedList],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить изменения',
        code: 'save',
        isDisabled: false,
        isHidden: saveForm === undefined,
        onClick: saveForm,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'print',
        isDisabled: false,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
      {
        icons: buttonIcons.toolbarQuestion,
        title: 'Задать вопрос',
        code: 'question',
        isDisabled: false,
        isHidden: false,
        onClick: handleQuestionButtonClick,
      },
      {
        icons: buttonIcons.cancel,
        title: 'Отмена',
        code: 'cancel',
        isHidden: closeForm === undefined,
        isDisabled: false,
        onClick: closeForm,
      },
    ],
    [saveForm, getReports, handleSetCurrentReport, handleQuestionButtonClick, closeForm],
  );
  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    currentPerson,
    isQuestionFormOpen,
    handleTemplateCloseQuestionForm,
  };
}
