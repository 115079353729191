import React from 'react';

import { FormComponent, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isViewFormOpen,
    isViewButtonDisabled,
    handleTemplateCloseViewForm,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController(tableState);

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <Modal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Вкладка Диссертации и авторефераты"
        size="medium"
      >
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>Для отображения на этой вкладке списка диссертаций/ авторефератов по теме проекта необходимо:</p>
            <ul>
              <li>
                В списке публикаций (пункт меню <strong>Публикации</strong>, или в&nbsp;
                <strong>личном кабинете вкладка Публикации);</strong> выделить диссертацию или автореферат, нажать кнопку&nbsp;
                <strong>Финансовая поддержка публикации;</strong>
              </li>
              <li>
                На форме, в разделе <strong>Проекты</strong> указать проект;
              </li>
              <li>Сохранить форму.</li>
            </ul>
            <p>
              <strong className="primary">ВАЖНО!</strong> Запись с отметкой <strong>Принят к отчёту</strong> подлежат регистрации
              в федеральной системе учёта результатов по научным проекта ЕГИСУ НИОКТР, в соответствии с Федеральным законом №
              77-ФЗ.
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
