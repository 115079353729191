import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ListEdit, ReferenceItem } from 'components';

import { DefaultToolbarButton } from 'components/ListEdit/model';
import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  title: string;
  rows: ReferenceItem[];
  onChange(refs: ReferenceItem[]): void;
  modalTitle: string;
  refName: string;
  disabled?: boolean;
  tooltip?: string;
  isRequired?: boolean;
  defaultRowsCount?: number;
  maxHeight?: string;
  isToolbarHidden?: boolean;
  visibleToolbarButtons?: DefaultToolbarButton[];
};

export function RefList(props: Props) {
  const {
    modalTitle,
    onChange,
    refName,
    rows,
    title,
    disabled = false,
    tooltip,
    isRequired,
    defaultRowsCount,
    maxHeight,
    isToolbarHidden,
    visibleToolbarButtons,
  } = props;

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  return (
    <ListEdit
      rows={rows}
      title={title}
      columns={[{ label: '', formatValue: x => x.label }]}
      onChange={onChange}
      isToolbarDisabled={disabled}
      withoutHead
      tooltipText={tooltip}
      isRequired={isRequired}
      defaultRowsCount={defaultRowsCount}
      maxHeight={maxHeight}
      hideToolbar={isToolbarHidden}
      visibleToolbarButtons={visibleToolbarButtons}
      specification={{
        mode: 'loadInstanceTableModal',
        onStartLoad: (tableRows, onSuccess) => {
          loadReferenceAPI.callAPI(
            { filters: [], referenceName: refName, childIds: tableRows.map(x => x.id) },
            { onSuccessfullCall: ({ data }) => onSuccess(data) },
          );
        },
        relationTableModalTitle: modalTitle,
        modalTableSpecification: GetReferenceElementList({ requestData: { name: refName, filters: [] } }),
      }}
    />
  );
}
