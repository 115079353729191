import React, { memo, useCallback } from 'react';
import { Modal, CustomSelect, FormComponent } from 'components';
import { Table as T } from 'types/models';
import { Original } from 'types/models/Form';
import { GetPatentForIncentivePayment } from 'features/Table/specifications/GetPatentForIncentivePayment';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit: (id: string) => void;
};

export const Container = memo(({ isOpen, onClose, onSubmit }: Props) => {
  const setSecurityDocumentId = useCallback(
    (securityDocumentId: string) => {
      if (securityDocumentId) onSubmit(securityDocumentId);
    },
    [onSubmit],
  );
  const modalTableRowToEventConventer = useCallback<(row: T.Entry) => Original>(
    row => ({
      id: row.id,
      name: row.name,
    }),
    [],
  );
  const modalTableConfig = GetPatentForIncentivePayment({ isCurrent: true });
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавление заявки на оплату объекта ИС" size="medium">
      <FormComponent.Wrapper>
        <CustomSelect
          isRequired
          title="Объект ИС"
          original={{ id: '', name: '' }}
          relationTableModalTitle="Объекты ИС"
          onDeleteFunction={() => setSecurityDocumentId('')}
          onChange={row => setSecurityDocumentId(row?.id || '')}
          modalTableRowConverter={modalTableRowToEventConventer}
          modalTableSpecification={modalTableConfig}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
});
