import React from 'react';

import { Button, ButtonMode, Reference, ReferenceItem, FormComponent, Modal, TextArea, TextAreaMode } from 'components';

import { Form, RefFeedlineCommand } from 'types/models';

type Props = {
  title: string;
  publicationMessage: Form.PublicationMessage;
  updatePublicationMessage: (publicationMessage: Form.PublicationMessage) => void;
  publicationMessageType: ReferenceItem;
  setPublicationMessageType: (publicationMessageType: ReferenceItem) => void;
  relationTableModalTitle?: string;
  isOpen: boolean;
  onConfirm: () => void;
  onConfirmWithoutMessage: () => void;
  submitButtonText?: string;
  cancelButtonText?: string;
  fieldVisibleMode?: string;
  command?: RefFeedlineCommand;
};

export const AdministratorMessagePopup = React.memo(
  ({
    title,
    onConfirm,
    onConfirmWithoutMessage,
    publicationMessageType,
    setPublicationMessageType,
    isOpen,
    publicationMessage,
    updatePublicationMessage,
    submitButtonText = 'Продолжить',
    cancelButtonText = 'Продолжить без сообщения',
    relationTableModalTitle = 'Сообщение администратору',
    command = 'RefPublicationFeedlineMessage',
    fieldVisibleMode,
  }: Props) => {
    return (
      <Modal isOpen={isOpen} onClose={onConfirmWithoutMessage} title={title} size="medium">
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="" labelSize="fit">
              <Reference
                relationTableModalTitle={relationTableModalTitle}
                isRequired
                name={command}
                fieldVisibleMode={fieldVisibleMode}
                value={publicationMessageType}
                onChange={setPublicationMessageType}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="" labelSize="fit">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3 }}
                value={publicationMessage}
                onChange={updatePublicationMessage}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Actions>
            <Button
              mode={ButtonMode.primary}
              text={submitButtonText}
              onClick={onConfirm}
              isDisabled={!publicationMessageType.id}
            />
            <Button mode={ButtonMode.secondary} text={cancelButtonText} onClick={onConfirmWithoutMessage} />
          </FormComponent.Actions>
        </FormComponent.Wrapper>
      </Modal>
    );
  },
);
