import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { Color } from 'constants/colors';
import { makeUseCustomController, State } from './makeUseCustomController';

export type Arguments = {
  hasToolbarButtonMode?: boolean;
  hasSelectButton?: boolean;
  defaultYearChecked?: boolean;
};

export function GetPersonalAllProjectList({
  hasToolbarButtonMode = false,
  hasSelectButton,
  defaultYearChecked = true,
}: Arguments): T.Specification<State> {
  return {
    apiID: 'GetPersonalAllProjectList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: hasToolbarButtonMode ? '' : 'Список научных проектов университета',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
        hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController({ hasToolbarButtonMode, defaultYearChecked }),
    highlightEntryPredicate: entry => ({ rowTheme: !!entry.LibraryNote ? Color.danger : '' }),
  };
}
