import { Form, Event } from 'types/models';
import { Notification } from 'features/Notifications';
import * as validator from 'utils/Validators';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

// key: value key shoul be equal formfield key,
// value is validator function (val) => boolean
const specialValidators = {
  file: validator.isFileValid,
};

const getError = (formFields: Form.Fields) => {
  const nextNotification: Pick<Notification, 'message' | 'theme'> = { message: '', theme: 'danger' };
  let isFormValid = true;

  const invalidFieldTitles: string[] = [];
  const incorrectFieldTitles: string[] = [];
  const outOfMaxRangeFieldTitles: string[] = [];
  const outOfSymbolsRangeFieldTitles: string[] = [];
  const invalidFieldKeys: string[] = [];

  const keysToValidate = Object.keys(formFields);

  keysToValidate.forEach((key: string) => {
    const isFieldRequired = formFields[key].required;

    let isFieldValid = true;
    let isFieldCorrect = true;
    let isFieldInValueRange = true;
    let isFieldInSymbolsRange = true;

    const isFieldObject = formFields[key].value instanceof Object;
    const isFieldString = typeof formFields[key].value === 'string';

    const isFieldKeyMatchSpecialValidator = Object.keys(specialValidators).some(
      specialValidatorKey => key === specialValidatorKey,
    );

    if (isFieldKeyMatchSpecialValidator) {
      isFieldValid = specialValidators[key as keyof typeof specialValidators](formFields[key]);
    } else if (isFieldObject && isFieldRequired) {
      isFieldValid = validator.isReferenceValid(formFields[key]);
    } else if (isFieldString) {
      if (formFields[key].value === '') {
        isFieldValid = validator.isStringFieldValid(formFields[key]);
      } else {
        isFieldCorrect = validator.isStringFieldWithValidationTypeValid(formFields[key]);
      }
      if (isFieldValid && isFieldCorrect) {
        const fieldHasMaxSymbols = !!formFields[key].maxSymbols;
        const fieldHasMaxValue = !!formFields[key].maxValue;

        if (fieldHasMaxSymbols) {
          isFieldInSymbolsRange = validator.isOutOfMaxSymbolsRange(formFields[key]);
        } else if (fieldHasMaxValue) {
          isFieldInValueRange = validator.isOutOfMaxValueRange(formFields[key]);
        }
      }
    }

    if (!isFieldValid || !isFieldCorrect || !isFieldInSymbolsRange || !isFieldInValueRange) {
      isFormValid = false;
      invalidFieldKeys.push(key);
      if (formFields[key].title) {
        if (!isFieldValid) {
          invalidFieldTitles.push(formFields[key].title);
        } else if (!isFieldCorrect) {
          incorrectFieldTitles.push(formFields[key].title);
        } else if (!isFieldInSymbolsRange) {
          outOfSymbolsRangeFieldTitles.push(formFields[key].title);
        } else if (!isFieldInValueRange) {
          outOfMaxRangeFieldTitles.push(formFields[key].title);
        }
      }
    }
  });

  const invalidMessage = invalidFieldTitles.length
    ? `Заполните ${invalidFieldTitles.length > 1 ? 'обязательные поля' : 'обязательное поле'}: ${invalidFieldTitles
        .map(title => `'${title.toLowerCase()}'`)
        .join(', ')}.`
    : '';

  const incorrectMessage = incorrectFieldTitles.length
    ? `${
        incorrectFieldTitles.length > 1 ? 'Поля заполнены некорректно' : 'Поле заполненно некорректно'
      }: ${incorrectFieldTitles.map(title => `'${title.toLowerCase()}'`).join(', ')}.`
    : '';

  const outOfSymbolsRangeMessage = outOfSymbolsRangeFieldTitles.length
    ? `${
        outOfSymbolsRangeFieldTitles.length > 1
          ? 'Превышено максимальное количество символов у полей'
          : 'Превышено максимальное количество символов у поля'
      }: ${outOfSymbolsRangeFieldTitles.map(title => `'${title.toLowerCase()}'`).join(', ')}.`
    : '';
  const outOfMaxRangeMessage = outOfMaxRangeFieldTitles.length
    ? `${
        outOfMaxRangeFieldTitles.length > 1 ? 'Превышено максимальное значение у полей' : 'Превышено максимальное значение у поля'
      }: ${outOfMaxRangeFieldTitles.map(title => `'${title.toLowerCase()}'`).join(', ')}.`
    : '';

  if (invalidMessage || incorrectMessage || outOfSymbolsRangeMessage || outOfMaxRangeMessage) {
    const messages = [invalidMessage, incorrectMessage, outOfSymbolsRangeMessage, outOfMaxRangeMessage];

    const preparedMessage = messages.filter(message => message !== '').join('<br/>');
    nextNotification.message = preparedMessage;
    nextNotification.theme = 'danger';
  }

  return { nextNotification, invalidFieldKeys, isFormValid };
};

export function validate(formFields: Form.Fields) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };
  const defaultResult = getError(formFields);

  result.nextNotification = defaultResult.nextNotification;
  result.isFormValid = defaultResult.isFormValid;
  result.invalidFieldKeys = defaultResult.invalidFieldKeys;

  return result;
}

export function compilationAndReleaseValidate(
  formFields: Form.Fields,
  magazineReleases: Event.Source[],
  compilations: Event.Source[],
) {
  const result = {
    isValid: true,
    helpText: '',
  };
  if (formFields.isMagazinePlanned.value && !magazineReleases.length) {
    result.helpText =
      // eslint-disable-next-line
      'Вы можете добавить выпуск журнала на вкладке "О мероприятии" в разделе "Опубликованные материалы конференции в специальных выпусках журнала"';
    result.isValid = false;
  }
  if (formFields.isCompilationPlanned.value && !compilations.length) {
    // eslint-disable-next-line
    result.helpText = 'Вы можете добавить сборник на вкладке "Опубликованные материалы"';
    result.isValid = false;
  }
  return result;
}
