import { ReferenceItem } from 'components';
import React from 'react';
import { ValueOf } from 'types/helpers';

import { Person, UserPermission } from 'types/models';
import { UpperForm } from './UpperForm/UpperForm';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  onChange(fieldName: keyof Person.Scientist, value: string | ReferenceItem | null): void;
  reloadPerson(): void;
  makeSetPersonField: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};

function Component(props: Props) {
  const { person, userPermission, reloadPerson, makeSetPersonField, onChange } = props;
  const scientist = person?.scientist ?? null;
  return (
    <UpperForm
      reloadPerson={reloadPerson}
      userPermission={userPermission}
      scientist={scientist}
      person={person}
      makeSetPersonField={makeSetPersonField}
      onChange={onChange}
    />
  );
}

export const Main = React.memo(Component);
