import { useCallback } from 'react';
import { Department } from 'types/models/Department';
import * as R from 'ramda';
import { ReferenceItem } from 'components';

type Props = {
  department: Department | null;
  onDepartmentChange(row: Department): void;
};

export function useController({ department, onDepartmentChange }: Props) {
  const makeChangeHandler = useCallback(
    (fieldName: keyof Department) => (value: ReferenceItem) => {
      onDepartmentChange(R.set(R.lensProp(fieldName), value, department!));
    },
    [onDepartmentChange, department],
  );

  return {
    makeChangeHandler,
  };
}
