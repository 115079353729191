import React, { useMemo } from 'react';

import { Form as F } from 'types/models';

import { Component as Form } from './component';
import { FormContext, Context } from './context';

type Props<SpecificLook> = {
  look: F.Look & SpecificLook;
  onClose?: () => void;
};

type SubContainerProps = {
  onClose?: () => void;
};

function TableSubContainer({ onClose }: SubContainerProps) {
  return <Form onClose={onClose} />;
}

export function FormContainer<SpecificLook>(props: Props<SpecificLook>) {
  const { look, onClose } = props;
  const contextValue = useMemo((): Context<SpecificLook> => ({ look }), [look]);

  return (
    <FormContext.Provider value={contextValue}>
      <TableSubContainer onClose={onClose} />
    </FormContext.Provider>
  );
}
