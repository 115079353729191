import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertMergeReference } from 'services/BackendAPI/configurations/merge/converters';
import { Merge } from 'types/models/Merge';
import {
  convertCommonToRequest,
  convertEntitiesToRequest,
} from 'services/BackendAPI/configurations/MergeScientist/requestConverters';
import { Row } from 'react-table';

export const mergeReferenceConfigurations = {
  RefMerge: makeBackendAPIConfiguration({
    id: 'Merge',
    endpoint: '/msa/service/commands/Merge',
    convertInputToXMLElement: ({
      selectedItems,
      refName,
      action,
    }: {
      selectedItems?: Row[];
      refName: string;
      action: string;
    }) => ({
      Request: {
        _attr: { commandName: 'Merge' },
        Merge: {
          _attr: { entity: refName, action },
          Entity: selectedItems?.map(item => {
            return {
              _attr: {
                id: item?.id || '',
              },
            };
          }),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertMergeReference(response.Response.Merge),
  }),
  SaveRefMerge: makeBackendAPIConfiguration({
    id: 'Merge',
    endpoint: '/msa/service/commands/Merge',
    convertInputToXMLElement: ({ merge, action, refName }: { merge: Merge; action: string; refName?: string }) => ({
      Request: {
        _attr: { commandName: 'Merge' },
        Merge: {
          _attr: { entity: refName, action },
          Entity: convertEntitiesToRequest(merge.entities),
          ...convertCommonToRequest({ fields: merge.common, commonId: merge.commonId }),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertMergeReference(response.Response.Merge),
  }),
};
export const mergeConfigurations = {
  ...mergeReferenceConfigurations,
};
