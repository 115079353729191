import React from 'react';
import { ListEdit, ReferenceItem, FormComponent } from 'components';

import * as BackendAPI from 'services/BackendAPI';

import { Project } from 'types/models';

import { GetReferenceElementList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';

import { getCurrentTime } from 'utils/Helpers';

type Props = {
  rows: Project.RefElement[];
  onChange(refs: Project.RefElement[]): void;
  programId?: string;
  disabled?: boolean;
  isCanMovingRows?: boolean;
};

const REF_NAME = 'RefDomainKnowledge';

export function DomainKnowledgeList(props: Props) {
  const { onChange, rows, isCanMovingRows, disabled = false } = props;

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const { currentPerson } = useAppDataContext();

  return (
    <FormComponent.Line>
      <ListEdit
        title="Области знаний"
        rows={rows}
        columns={[
          { label: 'Наименование', formatValue: x => x.ref?.label ?? '', styles: { width: '75%' } },
          { label: 'Создано', formatValue: x => `${x.createdBy} ${x.createdDate}`, styles: { width: '25%' } },
        ]}
        onChange={onChange}
        isToolbarDisabled={disabled}
        defaultRowsCount={3}
        maxHeight="300px"
        visibleToolbarButtons={['add', 'delete']}
        isCanMovingRows={isCanMovingRows}
        specification={{
          mode: 'loadInstanceTableModal',
          onStartLoad: (tableRows, onSuccess) => {
            loadReferenceAPI.callAPI(
              { filters: [], referenceName: REF_NAME, childIds: tableRows.map(x => x.id) },
              {
                onSuccessfullCall: ({ data }) => {
                  const refElements = (data as ReferenceItem[])
                    .filter(x => !rows.find(row => row.ref?.id === x.id))
                    .map<Project.RefElement>(x => ({
                      id: null,
                      ref: { id: x.id, label: x.label },
                      position: '0',
                      createdBy: currentPerson?.fullName ?? '',
                      createdDate: getCurrentTime(),
                      isActual: true,
                      isProject: true,
                    }));
                  onSuccess(refElements);
                },
              },
            );
          },
          relationTableModalTitle: 'Справочник "Области знаний"',
          //  modalTableSpecification: GetDomainKnowledgesByProgram({ programId }),
          modalTableSpecification: GetReferenceElementList({
            removeEdit: true,
            requestData: {
              filters: [],
              name: 'RefDomainKnowledge',
            },
          }),
        }}
      />
    </FormComponent.Line>
  );
}
