import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { Option } from 'components';

import { Project, Table } from 'types/models';
import type { StateRegistrationReport } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showErrorsMessages } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { getMockStateRegistrationReport, getStageLabel } from '../helpers';
import { useFormContext } from 'features/Form/hooks';
import { getMockStage } from 'features/Form/looks/project/ProjectForm/helpers/index';
import { validate } from './validate';
import { showNotification } from 'features/Notifications';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose: () => void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const [stateRegistrationReport, setStateRegistrationReport] = useState<StateRegistrationReport>(
    getMockStateRegistrationReport(),
  );
  const [projectStages, setProjectStages] = useState<Project.Stage[]>([]);
  const [selectedStageLabel, setSelectedStageLabel] = useState<string>('');

  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();
  const { enumMap } = useAppDataContext();

  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data.stages as Project.Stage[];
      setProjectStages(preparedData);
      setStateRegistrationReport({ ...stateRegistrationReport, stage: preparedData[0] ?? null });
      setSelectedStageLabel(preparedData[0] ? getStageLabel(preparedData[0]) : '');
    },
  });

  const { methods: saveProjectStateRegistrationReport } = BackendAPI.useBackendAPI('SaveProjectStateRegistrationReport', {
    onSuccessfullCall: () => {
      onClose();
      tableStreams.reloadTable.push();
      showNotification({ message: 'Отчет успешно создан', theme: 'success' });
    },
  });

  const stageOptions = useMemo(
    () => projectStages.map<Option>(x => ({ label: getStageLabel(x) ?? '', value: x.id ?? '' })),
    [projectStages],
  );

  const stageReportTypeOptions = useMemo(
    () => enumMap.StageReportType.values.map<Option>(x => ({ label: x.label ?? '', value: x.value ?? '' })),
    [enumMap.StageReportType.values],
  );

  const handleStageChange = useCallback(
    (newStage: Option) => {
      setSelectedStageLabel(newStage.label);
      setStateRegistrationReport({
        ...stateRegistrationReport,
        stage: { ...getMockStage(), id: newStage.value },
      });
    },
    [stateRegistrationReport],
  );

  const handleStageReportTypeChange = useCallback(
    (newReportType: Option) => {
      setStateRegistrationReport({
        ...stateRegistrationReport,
        reportType: newReportType,
      });
    },
    [stateRegistrationReport],
  );

  const handleCitisDateChange = useCallback(
    (citisDate: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, citisDate });
    },
    [stateRegistrationReport],
  );

  const onSubmit = useCallback(() => {
    const validateInfo = validate(stateRegistrationReport);
    const invalidFields = validateInfo.filter(({ isValid }) => !isValid);
    const isValid = !invalidFields.length;
    if (isValid) {
      saveProjectStateRegistrationReport.callAPI({ stateRegistrationReport, projectId: id });
    } else {
      showErrorsMessages(invalidFields.map(({ invalidMessage }) => invalidMessage));
    }
  }, [id, saveProjectStateRegistrationReport, stateRegistrationReport]);

  useLayoutEffect(() => {
    if (id) getProject.callAPI({ id, modules: ['STAGES'] });
    // eslint-disable-next-line
  }, []);

  return {
    handleCitisDateChange,
    handleStageChange,
    onSubmit,
    stageOptions,
    stateRegistrationReport,
    workMode,
    stageReportTypeOptions,
    handleStageReportTypeChange,
    selectedStageLabel,
  };
}
