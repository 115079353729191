import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { CommunityMember } from 'types/models';

import { Settings } from 'types/models/common';
import { convertCommunityMember } from './converters';
import { convertCommunityMemberToXML } from './requestConverters';

const configurations = {
  GetCommunityMember: makeBackendAPIConfiguration({
    id: 'GetCommunityMember',
    endpoint: '/msa/service/commands/GetCommunityMember',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetCommunityMember' },
        CommunityMember: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertCommunityMember(response.Response.CommunityMember, settings),
  }),
  SaveCommunityMember: makeBackendAPIConfiguration({
    id: 'SaveCommunityMember',
    endpoint: '/msa/service/commands/SaveCommunityMember',
    convertInputToXMLElement: (inputData: CommunityMember) => ({
      Request: {
        _attr: {
          commandName: 'SaveCommunityMember',
        },
        CommunityMember: { ...convertCommunityMemberToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const communityMemberConfigurations = { ...configurations };
