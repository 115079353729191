import React from 'react';

import { InputSelect, Reference, FormComponent, TextArea, TextAreaMode } from 'components';

import { Project } from 'types/models';
import useController from './controller';

type Props = {
  resourceUsageDepartment: Project.ResourceUsageDepartment;
  setResourceUsageDepartment(resourceUsageDepartment: Project.ResourceUsageDepartment): void;
};

export const Fields = (props: Props) => {
  const { resourceUsageDepartment, setResourceUsageDepartment } = props;

  const { makeChangeHandler, typeOptions } = useController({ resourceUsageDepartment, setResourceUsageDepartment });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Тип" isRequired>
          <InputSelect options={typeOptions} value={resourceUsageDepartment.type} onSelectChange={makeChangeHandler('type')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Подразделение" isRequired>
          <Reference
            value={resourceUsageDepartment.department}
            theme="big"
            relationTableModalTitle="Подразделение"
            name="RefDepartment"
            onChange={makeChangeHandler('department')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Примечание' }}
            value={resourceUsageDepartment.note ?? ''}
            onChange={makeChangeHandler('note')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
};
