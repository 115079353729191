import React, { useCallback, useMemo } from 'react';

import { InputSelect, TextArea, TextAreaMode, TextInput, FormComponent, TextDateTime, UploadFile } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';

import { ExtendedReport } from '.';
import { Item } from 'types/models/common';
import { getOptionsFromEnum } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  report: ExtendedReport;
  setReport: React.Dispatch<React.SetStateAction<ExtendedReport | null>>;
  disabled: boolean;
  stages: Project.Stage[];
};

export const Fields = React.memo((props: Props) => {
  const { report, setReport, disabled, stages } = props;
  const { enumMap } = useAppDataContext();

  const makeChangeHandler = useCallback(
    (key: keyof ExtendedReport) => (value: ValueOf<ExtendedReport>) => {
      setReport(prevState => ({ ...prevState!, [key]: value }));
    },
    [setReport],
  );

  const reportTypeOptions = getOptionsFromEnum(enumMap.StageReportType);

  const stageOptions = useMemo<Item[]>(() => {
    return stages.filter(x => x.reports.length === 0).map(x => ({ value: x.id || '', label: x.number || '' }));
  }, [stages]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Этап">
          <InputSelect
            options={stageOptions}
            value={report.stage}
            onSelectChange={val =>
              setReport(prevState => ({ ...prevState!, stage: { value: val?.value || '', label: val?.label || '' } }))
            }
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Название отчёта" isRequired>
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Название отчёта' }}
            value={report?.name ?? ''}
            onChange={makeChangeHandler('name')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Тип отчёта">
          <InputSelect
            disabled={disabled}
            options={reportTypeOptions}
            value={report?.type ?? null}
            onSelectChange={makeChangeHandler('type')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Реферат">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Реферат' }}
            value={report?.referat ?? ''}
            onChange={makeChangeHandler('referat')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ключевые слова">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Ключевые слова' }}
            value={report?.keyWords ?? ''}
            onChange={makeChangeHandler('keyWords')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Срок сдачи отчёта">
          <TextDateTime value={report?.reportDate} onChange={makeChangeHandler('reportDate')} isDisabled={disabled} />
        </FormComponent.Field>
        <FormComponent.Field label="Отправлен заказчику" labelWidth={300}>
          <TextDateTime value={report?.sendCustomerDate} onChange={makeChangeHandler('sendCustomerDate')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата сдачи отчёта в архив">
          <TextDateTime value={report?.reportOntiDate} onChange={makeChangeHandler('reportOntiDate')} isDisabled={disabled} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата регистрации отчёта в архиве" labelWidth={300}>
          <TextDateTime
            value={report?.registrationOntiDate}
            onChange={makeChangeHandler('registrationOntiDate')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Инвентарный номер отчёта в архиве">
          <TextInput value={report?.ontiNumber ?? ''} onChange={makeChangeHandler('ontiNumber')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Номер отчёта (ИК)">
          <TextInput value={report?.number ?? ''} onChange={makeChangeHandler('number')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата госрегистрации">
          <TextDateTime value={report?.registrationDate} onChange={makeChangeHandler('registrationDate')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Файл отчёта">
          <UploadFile file={report?.file} onChange={makeChangeHandler('file')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
});
