import React from 'react';

import { FormComponent, InputSelect, TextArea, TextDateTime } from 'components';

import { Community, Vak, Affiliation } from 'types/models';
import { Member, SelectPerson } from 'features/SelectPerson';
import { Item } from 'types/models/common';
import { Form } from 'types/models';
import { useController } from './controller';

type Props = {
  target: Community.Community | Form.Publication | null;
  entry: Community.Member | null;
  onFieldChange(row: Community.Member): void;
  vaks?: Vak[];
  selectedCommunityType?: Item | null;
  isLocal?: boolean;
  code?: string;
  affiliation?: Affiliation | null;
  roleOptions: Item[];
  cooperationTypeOptions?: Item[];
  isNotDissertationCouncil?: boolean;
  isJoinDateRequired?: boolean;
  isSpecialityHidden?: boolean;
};

function MemberFields({
  target,
  entry,
  onFieldChange,
  // vaks,
  selectedCommunityType,
  code,
  roleOptions,
  cooperationTypeOptions,
  isNotDissertationCouncil,
  isJoinDateRequired,
}: // isSpecialityHidden,
Props) {
  const {
    handlePersonFieldChange,
    handleJoinDateFieldChange,
    handleLeaveDateFieldChange,
    handleRoleFieldChange,
    // handleVakSpecialityFieldChange,
    handleCooperationTypeFieldChange,
    handleDescriptionFieldChange,
    memberHeading,
  } = useController({
    target,
    selectedCommunityType,
    code,
    entry,
    onFieldChange,
    roleOptions,
    cooperationTypeOptions,
  });

  const member: Member = {
    person: entry?.scientist || null,
    job: null,
    education: null,
    citizenship: null,
    degree: entry?.degree || null,
    rank: entry?.rank || null,
    academicRank: null,
  };

  return (
    <FormComponent.Template>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="">
          <strong>{memberHeading}</strong>
        </FormComponent.Field>
      </FormComponent.Line>
      {!!isNotDissertationCouncil && (
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Предмет сотрудничества" isRequired>
            <InputSelect
              options={cooperationTypeOptions || []}
              value={entry?.cooperationType || null}
              onSelectChange={handleCooperationTypeFieldChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Персона" isRequired>
          <SelectPerson
            member={member}
            onUpdateMember={handlePersonFieldChange}
            isRequired
            withHistory={true}
            memberLabel={[member.person?.fullName, member.degree?.refDegree?.label, member.rank?.refRank?.label]
              .filter(x => x)
              .join(', ')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Роль" isRequired>
          <InputSelect
            options={roleOptions}
            value={entry?.role || { label: '', value: '' }}
            onSelectChange={handleRoleFieldChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Дата ввода в состав" isRequired={isJoinDateRequired}>
          <TextDateTime value={entry?.joinDate} onChange={handleJoinDateFieldChange} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата вывода из состава">
          <TextDateTime value={entry?.leaveDate} onChange={handleLeaveDateFieldChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      {/* Скрыто в рамках 607 
      {!isNotDissertationCouncil && !isSpecialityHidden && (
        <FormComponent.Line>
          <FormComponent.Field label="Специальность">
            <Reference
              value={entry?.vakSpeciality || null}
              theme="big"
              disabled={false}
              relationTableModalTitle="Специальность ВАК"
              name="RefVakSpeciality"
              onChange={handleVakSpecialityFieldChange}
              filters={[{ key: 'id', values: vaks?.map(x => x.element.id).filter(x => x) || [] }]}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )} */}
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field
          label="Краткое описание"
          tooltip="Укажите специальность участника (по какому направлению специализируется участник)"
        >
          <TextArea settings={{ rows: 3 }} value={entry?.description ?? ''} onChange={handleDescriptionFieldChange} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { MemberFields };
