import React from 'react';

import { Checkbox, InputSelect, Reference, FormComponent } from 'components';

import { Project } from 'types/models';
import { Enum } from 'types/models/Table';
import { useController } from './controller';

type Props = {
  financing: Project.Financing;
  setFinancing(financing: Project.Financing): void;
  enumMap: Enum;
};

export function Fields(props: Props) {
  const { financing, setFinancing, enumMap } = props;

  const { makeChangeHandler, options } = useController({ financing, setFinancing, enumMap });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Вид финансирования" isRequired>
          <InputSelect
            options={options}
            value={options.find(x => x.value === financing.type?.value) ?? null}
            onSelectChange={makeChangeHandler('type') as any}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Источник финансирования" isRequired>
          <Reference
            name="RefInvestmentSource"
            onChange={makeChangeHandler('source')}
            value={financing.source}
            relationTableModalTitle='Справочник "Источники финансирования"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="В т.ч НДС">
          <Checkbox checked={financing.isNds} onChange={makeChangeHandler('isNds')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
