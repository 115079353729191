import { Table as T } from 'types/models';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetRequestFeedlineList = (id: string): T.Specification<State, { id: string }> => {
  return {
    apiID: 'GetRequestFeedlineList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    requestData: { id },
    useCustomController: makeUseCustomController({ requestId: id }),
  };
};
