import { useCallback, useMemo, useState } from 'react';
import { Form as F, Table } from 'types/models';
import * as icons from 'icons';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const selectedRowsLength = useMemo<number>(() => tableState.selectedRows.length, [tableState.selectedRows]);

  const rowsAvailability = useMemo<Table.ToolbarStateRowsAvailability>(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string | JSX.Element>('');

  const openViewForm = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const closeViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const toolbarButtons = useMemo(
    () => [
      {
        icons: { default: icons.Loop, disabled: icons.LoopDisabled },
        title: 'Просмотр',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: openViewForm,
        permissionName: 'PublicationView',
      },
    ],
    [openViewForm, rowsAvailability.SINGLE_SELECTED],
  );
  const look: F.Look = useMemo(
    () => ({
      apiID: 'GetPublication',
      template: 'PublicationFormArticle',
      type: PublicationTypes.MAGAZINE_ARTICLE.code,
      editMode: false,
      viewMode: true,
      id: tableState.selectedRows[0]?.id,
      title: tableState.selectedRows[0]?.name,
      relatedTableState: tableState,
      setTitle,
    }),
    [tableState],
  );
  return {
    toolbarButtons,
    isViewFormOpen,
    closeViewForm,
    look,
    title,
  };
};

export default useController;
