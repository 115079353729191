import React, { useCallback, useState, useEffect } from 'react';

import {
  InputSelect,
  FormComponent,
  Option,
  TextGridSelect,
  TextInput,
  TextInputMode,
  TextDateTime,
  Checkbox,
  UploadFile,
} from 'components';

import { Document, FileInfo, Person, Table as T } from 'types/models';
import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { GetProgramList, GetScientistJobs } from 'features/Table/specifications';
import { EnumMap } from 'types/models/Table';
import { DocumentProject } from 'types/models/Document';
import { FieldsType } from '../index';
import { getEnum } from 'utils/Helpers';

type Props = {
  document: Document;
  setDocument(doc: Document | null): void;
  isCanIsSharedEdit: boolean;
  isCommonAward: boolean;
  isCanIsInfoEdit: boolean;
  sourceLabelForLocalDocs?: string;
  sharedLabel?: string;
  fieldsType: FieldsType | null;
  enumMap: EnumMap;
  isShowSource: boolean;
  isInProgram: boolean;
  setIsInProgram(e: boolean): void;
  tooltip?: string;
};

export function Fields(props: Props) {
  const {
    document,
    enumMap,
    sourceLabelForLocalDocs,
    fieldsType,
    isCanIsInfoEdit,
    isCanIsSharedEdit,
    isCommonAward,
    sharedLabel,
    isShowSource,
    setDocument,
    isInProgram,
    setIsInProgram,
    tooltip,
  } = props;

  const [person, setPerson] = useState<Person.ScientistPerson | null>(null);

  const changeDocument = useCallback(
    (key: keyof Document, value: string | File | FileInfo | null | boolean) => {
      setDocument({ ...document, [key]: value });
    },
    [setDocument, document],
  );

  const changeRangeDocument = useCallback(
    (startDate: string, endDate: string) => {
      setDocument({ ...document, startDate, endDate });
    },
    [setDocument, document],
  );

  const handleChangeFile = useCallback(
    (fileInfo: FileInfo) => {
      setDocument({ ...document, file: fileInfo });
    },
    [setDocument, document],
  );

  const changeDocumentType = useCallback(
    (option: Option | null) => {
      changeDocument('partnerDocumentType', option?.value ?? null);
    },
    [changeDocument],
  );

  const changeProjectDocumentType = useCallback(
    (option: Option | null) => {
      changeDocument('projectDocumentType', option?.value ?? null);
    },
    [changeDocument],
  );

  const onChangePerson = useCallback(
    (member: Member) => {
      setDocument({
        ...document,
        responsible: member.person?.id ?? null,
        responsibleFio: member.person?.fullName ?? null,
        ...(person ? { responsibleJobAppointment: null, responsibleJob: null } : {}),
      });
      setPerson(member.person);
    },
    [document, person, setDocument],
  );

  const handleIsInProjectChange = useCallback(
    (e: boolean) => {
      setIsInProgram(e);
      if (e) setDocument({ ...document, startDate: null, endDate: null });
      if (!e) setDocument({ ...document, program: null });
    },
    [document, setDocument, setIsInProgram],
  );

  const handleProgramChange = useCallback(
    (program: DocumentProject) => {
      const value = program?.id ? program : null;
      setDocument({
        ...document,
        program: value,
        startDate: value?.startDate ? value.startDate : null,
        endDate: value?.endDate ? value.endDate : null,
      });
    },
    [document, setDocument],
  );

  const tableRowConverter = useCallback(
    (row: T.Entry): DocumentProject => ({
      id: row.id,
      label: row.name,
      startDate: row.StartDate,
      endDate: row.EndDate,
    }),
    [],
  );

  useEffect(() => {
    setIsInProgram(!!document.program?.id);
    // eslint-disable-next-line
  }, []);

  const documentTypeOptions = getEnum('PartnerDocumentType', enumMap);
  const projectDocumentTypeOptions = getEnum('ProjectDocumentType', enumMap);

  const renderDetailsFields = () => (
    <>
      <FormComponent.Line hasGap lineSize="padded">
        <FormComponent.Field isRequired label="Вид документа">
          <InputSelect
            options={documentTypeOptions}
            value={documentTypeOptions.find(x => x.value === document?.partnerDocumentType) ?? null}
            onSelectChange={changeDocumentType}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Номер документа">
          <TextInput value={document?.number ?? ''} onChange={val => changeDocument('number', val)} />
        </FormComponent.Field>
        {renderDataField()}
      </FormComponent.Line>
      <FormComponent.Line hasGap lineSize="doublePadded">
        {renderTitleField()}
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat growIndexes={[0, 10]}>
        <FormComponent.Field label="В рамках программы">
          <Checkbox checked={isInProgram} onChange={handleIsInProjectChange} />
        </FormComponent.Field>
        <FormComponent.Field label="Название программы" isRequired={isInProgram}>
          <TextGridSelect<DocumentProject>
            value={document.program || null}
            onChange={handleProgramChange}
            specification={GetProgramList({ withoutMenu: true })}
            settings={{
              mode: 'selectTableRow',
              tableRowConverter,
            }}
            valueFormatter={x => x.label}
            title="Партнёрские программы"
            disabled={!isInProgram}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap lineSize="padded">
        <FormComponent.Line hasGap>
          <FormComponent.Field label="Период действия">
            <TextDateTime
              isRange
              value={`${document?.startDate || ''}-${document?.endDate || ''}`}
              onChange={(value: string) => {
                const dates = value.split('-');
                changeRangeDocument(dates[0] || '', dates[1] || '');
              }}
              isDisabled={isInProgram}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Field label="">
          <FormComponent.Line hasGap hasFreeFormat>
            <Checkbox
              label="Бессрочный"
              checked={!!document?.isUnexpirable}
              onChange={val => changeDocument('isUnexpirable', val)}
            />
            <Checkbox
              label="Автоматическое продление"
              checked={!!document?.isAutoProlongation}
              onChange={val => changeDocument('isAutoProlongation', val)}
            />
          </FormComponent.Line>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">{renderFileField()}</FormComponent.Line>
      <FormComponent.Line hasGap>
        <FormComponent.Field label="Ответственное лицо">
          <SelectPerson
            member={getMemberWithoutHistory(person)}
            personId={document?.responsible ?? null}
            onUpdateMember={onChangePerson}
            withHistory={false}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Должность">
          <TextGridSelect
            specification={GetScientistJobs({ personId: person?.id ?? '', hasSelectButton: true })}
            title="Должности"
            value={{ label: document?.responsibleJobAppointment ?? null, id: document?.responsibleJob ?? null }}
            valueFormatter={x => x.label ?? ''}
            disabled={person === null}
            onChange={x => {
              setDocument({ ...document, responsibleJobAppointment: x?.label ?? null, responsibleJob: x?.id ?? null });
            }}
            settings={{ mode: 'selectTableRow', tableRowConverter: x => ({ id: x.id, label: x.appointment }) }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );

  const renderProjectDocumentsFields = () => (
    <>
      <FormComponent.Field label="">
        <Checkbox
          label={sharedLabel ?? 'Отображать на форме редактирования участия'}
          checked={!!document?.isShared}
          onChange={val => changeDocument('isShared', val)}
        />
      </FormComponent.Field>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field isRequired label="Тип документа">
          <InputSelect
            options={projectDocumentTypeOptions}
            value={projectDocumentTypeOptions.find(x => x.value === document?.projectDocumentType) ?? null}
            onSelectChange={changeProjectDocumentType}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Сумма документа">
          <TextInput mode={TextInputMode.number} value={document?.amount ?? ''} onChange={val => changeDocument('amount', val)} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );

  const renderTitleField = () => (
    <FormComponent.Field isRequired label="Название документа" tooltip={tooltip}>
      <TextInput value={document?.name ?? ''} onChange={val => changeDocument('name', val)} />
    </FormComponent.Field>
  );

  const renderDataField = () => (
    <FormComponent.Field isRequired label="Дата документа">
      <TextDateTime value={document?.date} onChange={date => changeDocument('date', date)} />
    </FormComponent.Field>
  );

  const renderFileField = () => (
    <FormComponent.Field isRequired label="Файл">
      <UploadFile file={document?.file} onChange={handleChangeFile} />
    </FormComponent.Field>
  );

  const renderDefaultFields = () => (
    <>
      <FormComponent.Line hasGap lineSize="padded">
        {renderTitleField()}
        {renderDataField()}
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">{renderFileField()}</FormComponent.Line>
    </>
  );

  return (
    <FormComponent.Wrapper>
      {fieldsType === null && renderDefaultFields()}
      {fieldsType === 'details' && renderDetailsFields()}
      {fieldsType === 'projectDocument' && (
        <>
          {renderDefaultFields()}
          {renderProjectDocumentsFields()}
        </>
      )}
      {isCanIsSharedEdit && (
        <FormComponent.Field label="">
          <Checkbox
            label={sharedLabel ?? 'Отображать на форме редактирования участия'}
            checked={!!document?.isShared}
            onChange={val => changeDocument('isShared', val)}
          />
        </FormComponent.Field>
      )}
      {isCommonAward && (
        <FormComponent.Field label="">
          <Checkbox
            label="Награда университета (отображать в том числе на персональных страницах участников мероприятия)"
            checked={!!document?.isCommonAward}
            onChange={val => changeDocument('isCommonAward', val)}
          />
        </FormComponent.Field>
      )}
      {isCanIsInfoEdit && (
        <FormComponent.Field label="">
          <Checkbox label="Разместить на сайте" checked={!!document?.isInfo} onChange={val => changeDocument('isInfo', val)} />
        </FormComponent.Field>
      )}
      {isShowSource && (
        <FormComponent.Field label="Источник">
          <span>{document.type || (document.docType === 'local' && sourceLabelForLocalDocs)}</span>
        </FormComponent.Field>
      )}
    </FormComponent.Wrapper>
  );
}
