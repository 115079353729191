import { ProjectAssignment } from 'types/models/Project';
import { formatStr } from 'utils/Constants/FormatStr';
import { format } from 'date-fns';
import { EMPTY_FILE } from 'utils/Constants';

export function getMockProjectAssignment(): ProjectAssignment {
  return {
    id: null,
    projectId: '',
    date: format(new Date(), formatStr),
    assignment: '',
    status: null,
    file: EMPTY_FILE,
  };
}
