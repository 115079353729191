import React from 'react';
import { Table as T } from 'types/models';
import { FormComponent, IconButton, Toolbar as SharedToolbar, buttonIcons } from 'components';
import { Modal } from 'components';
import useController from './controller';
import { DataGrid } from 'features/Table/container';
import { GetEstimateOverheadHistoryList } from 'features/Table/specifications/GetEstimateOverheadHistoryList';
import { GetFundCardPositionReceivingList } from 'features/Table/specifications/GetFundCardPositionReceivingList';

type Props = {
  tableState: T.State;
};
function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isDocumentListModalOpen,
    setIsDocumentListModalOpen,
    isHistoryModalOpen,
    setIsHistoryModalOpen,
    documentsModalTitle,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    tableState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal
        isOpen={isDocumentListModalOpen}
        onClose={() => setIsDocumentListModalOpen(false)}
        title={documentsModalTitle}
        size="full"
      >
        {tableState.selectedRows[0] && (
          <DataGrid specification={GetFundCardPositionReceivingList({ estimatePositionId: tableState.selectedRows[0].id })} />
        )}
      </Modal>
      <Modal
        isOpen={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        title="История изменения накладных"
        size="full"
      >
        {tableState.selectedRows[0] && (
          <DataGrid specification={GetEstimateOverheadHistoryList({ estimatePositionId: tableState.selectedRows[0]?.id })} />
        )}
      </Modal>
      <Modal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Источники средств  для шифра — статья затрат «Накладные расходы» в сметах шифров проектов"
        size="medium"
      >
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>Таблица содержит список шифров проектов, для которых</p>
            <ul>
              <li>Указан шифр накладных расходов</li>
              <li>Создана смета на год</li>
              <li>В смете указана статья расходов для накладных расходов</li>
            </ul>
            <h3>
              <strong>Особенности отображения информации в столбцах таблицы:</strong>
            </h3>
            <p>
              <strong>План сметы</strong> — плановая сумма в смете по статье затрат <strong>Накладные расходы</strong>
            </p>
            <p>
              <strong>Факт</strong> — сумма, вычисляемая автоматически при получении из бухгалтерии приходного документа по
              формуле: сумма документа * % НР, расчётный.{' '}
              <strong>Список документов бухгалтерского учёта о поступлении средств на шифр проекта</strong> можно посмотреть с
              помощью функциональной кнопки <IconButton icons={buttonIcons.viewList} hasNoHover />
            </p>
            <p>
              <strong>Остаток</strong> = План сметы - Факт
            </p>
            <p>
              <strong>% НР, нормативный</strong> — нормативный процент накладных расходов, <strong>указанный в проекте</strong> с
              проекте на вкладке <strong>Особенности</strong>
            </p>
            <p>
              <strong>Программа</strong> — краткое название программы, указанной в проекте. Может отсутствовать
            </p>
            <p>
              <strong>Уменьшает накладные</strong> — статьи затрат с атрибутом «Уменьшает накладные» в смете шифра проекта
            </p>
            <p>
              {/* eslint-disable-next-line max-len */}
              <strong>% НР, расчётный</strong> — расчётный процент накладных расходов <strong>вычисляется в смете шифра</strong>{' '}
              шифра с учётом сумм по статьям затрат, которые имеют атрибут <strong>Уменьшает накладные</strong>
            </p>
            <p>
              <strong>История изменения процента накладных расходов для шифра проекта</strong> — можно посмотреть с помощью
              функциональной кнопки <IconButton icons={buttonIcons.clock} hasNoHover />
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
