import React, { useEffect } from 'react';

import { FormComponent, Tab, Tabs, TextInput, Toolbar } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { DataGrid } from 'features/Table';
import { GetFundCardPositionList } from 'features/Table/specifications';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function FundCardForm({ viewMode, editMode, onClose }: Props) {
  const { buttons, fundCard, setFundCard } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (!fundCard.id) return 'Создание шифра ЦФ';
          return `Редактирование шифра ЦФ ${fundCard.name}`;
        })(),
      );
    }
  }, [fundCard.id, fundCard.name, setTitle]);

  const renderFundCardFields = (): JSX.Element => {
    return (
      <>
        <FormComponent.Line hasFreeFormat growIndexes={[1, 9]}>
          <FormComponent.Field label="Шифр" isRequired>
            <TextInput value={fundCard.name} onChange={e => setFundCard(prevState => ({ ...prevState, name: e }))} />
          </FormComponent.Field>
          <FormComponent.Field
            label="Примечание"
            labelSize="fit"
            tooltip="Перечислите для каких шифров проектов его использовать"
          >
            <TextInput value={fundCard.note} onChange={e => setFundCard(prevState => ({ ...prevState, note: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
      </>
    );
  };

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />
      <FormComponent.Template>
        {!!fundCard.id ? (
          <Tabs>
            <Tab title="О шифре">
              {renderFundCardFields()}
              <DataGrid specification={GetFundCardPositionList({ fundCardId: fundCard.id })} />
            </Tab>
            <Tab title="Затраты">
              <span>Не реализовано</span>
            </Tab>
            <Tab title="Затраты на коллектив">
              <span>Не реализовано</span>
            </Tab>
            <Tab title="Заявки на закупки">
              <span>Не реализовано</span>
            </Tab>
            <Tab title="Документы шифра">
              <span>Не реализовано</span>
            </Tab>
          </Tabs>
        ) : (
          <>{renderFundCardFields()}</>
        )}
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(FundCardForm);
