import { PatentResearch, Rntd, Customer, Performer } from 'types/models';
import { Project, Stage } from 'types/models/Project';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { getMaybeField } from '../commonConverters';
import { convertDocumentsToRequest } from '../converters';

export function convertPatentResearchToXML(patentResearch: PatentResearch) {
  const { id, name, number, target, loDate, hiDate, rntd, project, stage, customers, performers, documents } = patentResearch;
  return {
    _attr: patentResearch.id ? { id } : {},
    ...getMaybeField('Name', name),
    ...getMaybeField('Number', number),
    ...getMaybeField('Target', target),
    ...getMaybeField('LoDate', loDate),
    ...getMaybeField('HiDate', hiDate),
    ...(rntd ? { Rntd: convertRntdToXML(rntd) } : {}),
    ...(project ? { Project: convertProjectToXML(project) } : {}),
    ...(stage && project ? { Stage: convertStageToXML(stage) } : {}),
    Customers: customers.length ? { Customer: customers.map(convertCustomerToXML) } : {},
    Performers: performers.length ? { Performer: performers.map(convertPerformerToXML) } : {},
    ...(documents.length ? convertDocumentsToRequest(documents) : {}),
  };
}

function convertRntdToXML(rntd: Rntd) {
  return {
    _attr: { id: rntd.id },
  };
}

function convertProjectToXML(project: Project) {
  return {
    _attr: { id: project.id },
  };
}

function convertStageToXML(stage: Stage) {
  return {
    _attr: { id: stage.id },
  };
}

function convertCustomerToXML(customer: Customer) {
  return {
    _attr: { id: customer.id },
    Scientist: { _attr: { id: customer.person.id } },
    ...getHistoricalSliceXML(customer),
  };
}

function convertPerformerToXML(performer: Performer) {
  return {
    _attr: { id: performer.id },
    Scientist: { _attr: { id: performer.person.id } },
    ...getHistoricalSliceXML(performer),
  };
}
