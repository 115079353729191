import { EmployeePayment } from 'types/models/Payment';
import { convertDocumentsToRequest } from '../converters';

export function convertSaveEmployeePaymentToRequest(employeePayment: EmployeePayment, comment: string) {
  return {
    EmployeePaymentRequest: {
      _attr: {
        isPrechangeStatusSave: 'false',
        ...(employeePayment.id ? { id: employeePayment.id } : {}),
        ...(comment ? { saveComment: comment } : {}),
      },
      Fund: {
        department: employeePayment.fund.departmentId,
        type: employeePayment.fund.type.value,
        month: employeePayment.fund.month,
        quarter: employeePayment.fund.quarter.value,
        year: employeePayment.fund.year,
      },
      Nominee: employeePayment.nominees.map(x => ({
        _attr: { id: x.employee?.id ?? '' },
        Appointment: { _attr: { id: x.job?.id ?? '' } },
        AmountMoney: x.amountMoney,
      })),
      ...convertDocumentsToRequest(employeePayment.documents),
    },
  };
}
