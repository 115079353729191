import { useCallback, useMemo, useState } from 'react';
import { PublicationTypeCode } from 'types/models/Form';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { useStream, useStreamsByApiID } from 'StreamRx';
import { streams as globalStreams } from 'features/Table/streams';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';

import {
  GetCompilationArticleList,
  GetMagazineArticleList,
  GetMonographChapterList,
  GetPublicationOtherArticleList,
} from 'features/Table/specifications';
import { Table } from 'types/models';

type Props = {
  publicationTypeCode: PublicationTypeCode;
  publicationId: string;
  tableState: Table.State;
  onClose: () => void;
};

const ARTICLES_GRID_UNIQUE_ID = 'GRID_WITH_ARTICLES';
const controller = ({ publicationTypeCode, onClose, publicationId, tableState }: Props) => {
  const articlesGridStreams = useStreamsByApiID(globalStreams, ARTICLES_GRID_UNIQUE_ID);
  const { deletePublication } = useDeletePublication();

  const [isDeleteWarningPopupOpen, setIsDeleteWarningPopupOpen] = useState<boolean>(false);

  const handleSubmitDeleteWarningPopup = useCallback(() => {
    onClose();
    deletePublication({ publicationId, publicationTypeCode });
  }, [deletePublication, publicationId, onClose, publicationTypeCode]);

  const handleCloseDeleteWarningPopup = onClose;

  const currentSpecification = useMemo(() => {
    switch (publicationTypeCode) {
      case PublicationTypes.COMPILATION_ARTICLES.code:
      case PublicationTypes.COMPILATION_OTHER.code:
      case PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code:
      case PublicationTypes.COMPILATION_CONFERENCE.code:
        return GetCompilationArticleList({
          templatesTableDependencies: {
            relatedTableAPIID: 'GetCompilationArticleList',
            relatedTableState: tableState,
            relatedRecordId: publicationId,
          },
          workMode: 'editMode',
          parentTypeCode: publicationTypeCode,
          parentId: publicationId,
          isSetupSourceRelationMode: true,
          uniqID: ARTICLES_GRID_UNIQUE_ID,
        });
      case PublicationTypes.MAGAZINE.code:
        return GetMagazineArticleList({
          templatesTableDependencies: {
            relatedTableAPIID: 'GetMagazineList',
            relatedTableState: tableState,
            relatedRecordId: publicationId,
          },
          workMode: 'editMode',
          parentId: publicationId,
          uniqID: ARTICLES_GRID_UNIQUE_ID,
          isSetupSourceRelationMode: true,
        });
      case PublicationTypes.MONOGRAPH.code:
        return GetMonographChapterList({
          templatesTableDependencies: {
            relatedTableAPIID: 'GetCompilationArticleList',
            relatedTableState: tableState,
            relatedRecordId: publicationId,
          },
          workMode: 'editMode',
          parentId: publicationId,
          isSetupSourceRelationMode: true,
          uniqID: ARTICLES_GRID_UNIQUE_ID,
        });
      case PublicationTypes.OTHER_BOOK.code:
        return GetPublicationOtherArticleList({
          templatesTableDependencies: {
            relatedTableAPIID: 'GetPublicationOtherArticleList',
            relatedTableState: tableState,
            relatedRecordId: publicationId,
          },
          workMode: 'editMode',
          parentId: publicationId,
          isSetupSourceRelationMode: true,
          uniqID: ARTICLES_GRID_UNIQUE_ID,
        });
      default:
        return null;
    }
  }, [publicationId, publicationTypeCode, tableState]);

  useStream(
    () => articlesGridStreams.updateEntryPagesCount,
    ({ nextEntryCount }) => {
      const isArticlesGridEmpty = nextEntryCount === 0;

      if (isArticlesGridEmpty) {
        setIsDeleteWarningPopupOpen(true);
      }
    },
    [articlesGridStreams],
  );
  return {
    currentSpecification,
    isDeleteWarningPopupOpen,
    handleCloseDeleteWarningPopup,
    handleSubmitDeleteWarningPopup,
  };
};

export default controller;
