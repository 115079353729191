import { ReferenceItem } from 'components';
import { Author, Person } from 'types/models';
import { numberRegExp } from 'utils/Regexps';
import { Settings } from 'types/models/common';

type FormatSettings = {
  withoutStatus: boolean;
};

export function formatJob(job: Person.ScientistJob, settings?: FormatSettings) {
  const { enterpise, appointment, status, staffGroup, appointmentCategory, refAppointment } = job;
  const appointmentStr =
    staffGroup && appointmentCategory ? `(${staffGroup?.label ?? ''}, ${appointmentCategory?.label ?? ''}),` : '';
  if (settings?.withoutStatus) return `${enterpise}, ${refAppointment?.label || appointment}`;
  return `${enterpise}, ${refAppointment?.label || appointment}, ${appointmentStr} ${status.label}`;
}

export function formatEducation(education: Person.ScientistEducation) {
  const { status, enterprise, refSpeciality, speciality, refDepartment, department } = education;
  return [status.label, enterprise, refSpeciality?.label || speciality, refDepartment?.label || department]
    .filter(x => x)
    .join(', ');
}

export function getBiblioName(scientistPerson: Person.ScientistPerson, language: ReferenceItem) {
  return scientistPerson.bibliographicNames.find(x => x.isDefault && language.id === x.language?.id)?.name ?? '';
}

export function formatAffilation(author: Author, settings: Settings | null) {
  const { isAffilated = false, affilateCount = 0, memberAffilations = [] } = author;
  const affilations = memberAffilations.map(x => x.shortLabel || x.label).join(', ');
  // eslint-disable-next-line max-len
  return `Всего - ${affilateCount ?? 0}, ${
    isAffilated ? `<strong>${settings?.organization?.shortName}</strong>${affilations.length ? ', ' : ''}` : ''
  } ${affilations}`;
}

export function getMinAffilationsCount(author: Author) {
  const { memberAffilations, isAffilated } = author;
  return memberAffilations.length + (isAffilated ? 1 : 0);
}

const parseHistoryLabel = (author: Author, settings: Settings | null) => {
  const { category, job, education, degree, rank, academicRank, isAffilated, affilateCount, name, person } = author;

  const result = {
    name: '',
    degree: '',
    rank: '',
    academicRank: '',
    // job
    status: '',
    appointment: '',
    jobDepartment: '',
    // education
    educationStatus: '',
    department: '',
    error: '',
    affilated: '',
  };

  result.name = [
    category?.label ?? '',
    name ? `<span style="font-weight:bold">${name}</span>${person?.fullName ? `- ${person.fullName}` : ''}` : '',
  ]
    .filter(str => str)
    .join(': ');
  result.degree = degree?.refDegree?.shortName || '';
  result.rank = rank?.refRank?.label || '';
  result.academicRank = academicRank?.refAcademicRank?.label || '';

  if (job) {
    result.status = job.status.label || '';
    result.appointment = job.refAppointment?.label || job.appointment || '';
    result.jobDepartment = job.refDepartment?.label || job.department || (job.status.value === 'OTHER' ? job.enterpise : '');
  }

  if (education) {
    result.educationStatus = [education.status?.label || '', getDistinctSpeciality(education) || '']
      .filter(str => str)
      .join(': ');

    if (education.isTSU) {
      result.department = education.refDepartment?.label || '';
    }
    result.department = result.department || [education.enterprise, education.department].filter(str => str).join(', ') || '';
  }

  const isGroupAuthor = category?.customFields?.code === 'group';

  if (!job && !education && !isGroupAuthor) {
    result.error = '<span style="color:red">Не указаны сведения о работе или обучении.</span>';
  }

  result.affilated = [
    isAffilated ? `Аффилирован с ${settings?.organization?.shortName}` : '',
    affilateCount ? `(аффиляций всего: ${affilateCount})` : '',
  ]
    .filter(str => str)
    .join(' ');

  return result;
};

export const formatHistoryLabel = (author: Author, settings: Settings | null) => {
  const parsedFields = parseHistoryLabel(author, settings);
  return Object.values(parsedFields)
    .filter(str => str)
    .join(', ')
    .replace(', /, ', ' / ');
};

type SplittedHistoryLabel = {
  rank: string;
  job: string;
  error: string;
  affilated: string;
};

export const splittedHistoryLabel = (author: Author, settings: Settings | null): SplittedHistoryLabel => {
  const parsedFields = parseHistoryLabel(author, settings);

  const rank = [
    [
      parsedFields.degree,
      // parsedFields.rank,
      // parsedFields.academicRank,
      parsedFields.status,
      parsedFields.appointment,
    ]
      .filter(str => str)
      .join(', '),
    [parsedFields.educationStatus].filter(str => str).join(', '),
  ]
    .filter(str => str)
    .join(' / ');

  const job = [parsedFields.jobDepartment, parsedFields.department].filter(str => str).join(' / ');

  return {
    rank,
    job,
    error: parsedFields.error,
    affilated: parsedFields.affilated,
  } as SplittedHistoryLabel;
};

const getDistinctSpeciality = (education: Person.ScientistEducation): null | string => {
  if (education.refSpeciality) {
    return education.refSpeciality.label;
  }

  if (education.speciality) {
    return education.speciality;
  }

  return null;
};

export function formatAuthor(author: Author, settings: Settings | null) {
  return formatHistoryLabel(author, settings);
}

export function isCollectiveAuthor(category: Author['category']) {
  return category?.id === '2';
}

export function validate(author: Author | null) {
  if (!author) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }
  const isValid = Boolean(author.category?.id && author.name);
  const isValidConditionalFields = isCollectiveAuthor(author.category) ? Boolean(author.enterprise) : Boolean(author.person);
  const isValidAffilateFields = author.isAffilated ? Boolean(author.affilateCount) : true;
  const isValidRequiredFields = isValid && isValidConditionalFields && isValidAffilateFields;

  // eslint-disable-next-line no-extra-boolean-cast
  const isAffilateCountsValid = Boolean(author.isAffilated) ? isNumberValid(String(author.affilateCount)) : true;

  return [
    { isValid: isValidRequiredFields, invalidMessage: 'Заполните необходимые поля' },
    { isValid: isAffilateCountsValid, invalidMessage: 'Некорректное значение количества аффиляций' },
  ];
}

export function getMockAuthor(): Author {
  return {
    academicRank: null,
    affilateCount: 1,
    category: null,
    citizenship: null,
    degree: null,
    education: null,
    enterprise: null,
    historyLabel: '',
    id: null,
    isAffilated: true,
    job: null,
    memberAffilations: [],
    name: '',
    person: null,
    position: 0,
    rank: null,
  };
}

const isNumberValid = (value: string): boolean => new RegExp(numberRegExp).test(value);
