import { Enum } from 'types/models/Table';
import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';

type Arguments = {
  financing: Project.Financing;
  setFinancing(financing: Project.Financing): void;
  enumMap: Enum;
};

export function useController({ financing, setFinancing, enumMap }: Arguments) {
  const options = enumMap?.values ?? [];

  const makeChangeHandler = (key: keyof Project.Financing) => (value: ValueOf<Project.Financing>) => {
    setFinancing({ ...financing, [key]: value });
  };

  return {
    makeChangeHandler,
    options,
  };
}
