import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
      },
    ],
    [isEditButtonDisabled, handleEditButtonClick],
  );

  return {
    isEditButtonDisabled,
    isEditFormOpen,
    handleEditButtonClick,
    handleTemplateCloseEditForm,
    buttons,
  };
}
