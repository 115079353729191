import React, { useEffect, useMemo, useState } from 'react';
import { Event } from 'types/models';
import { Select, Reference, ReferenceItem, FormComponent } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { EnumValue } from 'types/models/Table';

type Props = {
  document: Event.Enterprise | null;
  onChange(row: Event.Enterprise): void;
};

function renderEntry(x: string) {
  return <span title={x}>{x}</span>;
}

const EnterprisesComponentForm = ({ document, onChange }: Props) => {
  const [organization, setOrganization] = useState<ReferenceItem>({ id: '', label: '' });
  const { enumMap } = useAppDataContext();
  const roles = useMemo<EnumValue[]>(() => enumMap.EventEnterpriseRole.values, [enumMap]);

  const emptyRow: Event.Enterprise = {
    role: { label: roles[0]?.label ?? '', code: roles[0]?.value ?? '' },
    organization: { id: '', label: '' },
  };

  const onChangeOrganization = (value: ReferenceItem) => {
    if (document) {
      onChange({ ...document, organization: value });
    } else {
      onChange({ ...emptyRow, organization: value });
    }
    setOrganization(value);
  };

  const initialRole = roles[0].label;
  const [role, setRole] = useState(initialRole);
  const changeRole = (value: string) => {
    const nextValue = roles.find(r => r.label === value);
    if (document) {
      onChange({ ...document, role: { label: nextValue?.label ?? '', code: nextValue?.value ?? '' } });
    } else {
      onChange({ ...emptyRow, role: { label: nextValue?.label ?? '', code: nextValue?.value ?? '' } });
    }
    setRole(nextValue?.label ?? '');
  };

  useEffect(() => {
    if (document) {
      if (document.role) {
        setRole(document.role.label);
      }

      if (document.organization) {
        setOrganization(document.organization);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line hasFreeFormat hasGap growIndexes={[0, 2]}>
        <FormComponent.Field label="Роль" labelSize="fit">
          <Select.Component
            value={role}
            variant="outlined"
            title="Роль"
            required
            defaultPlaceholder="Не задан"
            entries={roles.map(r => r.label)}
            onChange={changeRole}
            renderEntry={renderEntry}
          />
        </FormComponent.Field>
        <FormComponent.Field>
          <Reference
            disabled={false}
            value={organization}
            title="Организация"
            relationTableModalTitle='Справочник "Организации"'
            theme="small"
            refButtonTitle='Справочник "Организации"'
            name="RefEnterprise"
            onChange={onChangeOrganization}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
};

export { EnterprisesComponentForm };
