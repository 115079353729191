import { ExtraToolbarButton, Mode } from 'components/ListEdit/model';
import * as R from 'ramda';
import {
  calcSumFinancings,
  computeFinancingsByYear,
  getInitialProjectWithStages,
  getMockPerformerStage,
  getPerformerIndexByLastJobPeriodRole,
  getStageNdsPercent,
  getStageTotalNds,
} from 'features/Form/looks/project/ProjectForm/helpers';
import { useCallback, useState, useMemo } from 'react';
import { Project } from 'types/models';
import { formatNumber } from 'utils/Helpers';
import { ProjectFinancingType, ProjectScientistRole } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { buttonIcons } from 'components';
import { KeyName, PerformerCheckedRole, PerformersCopyInfo, Period } from './type';

type Props = {
  project: Project.Project;
  stages: Project.Stage[];
  financings: Project.Financing[];
  performers: Project.Performer[];
  setStages(stages: Project.Stage[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  setFinancings(financings: Project.Financing[]): void;
  setPerformers(performers: Project.Performer[]): void;
};

const useController = ({
  project,
  stages,
  financings,
  performers,
  setStages,
  setFinancingsByYear,
  setFinancings,
  setPerformers,
}: Props) => {
  const financingValuesEmpty = useMemo<Record<ProjectFinancingType, string>>(() => {
    return {
      [ProjectFinancingType.ACCOMPLICE]: '0',
      [ProjectFinancingType.COFINANCING_1]: '0',
      [ProjectFinancingType.COFINANCING_2]: '0',
      [ProjectFinancingType.LOCAL]: '0',
      [ProjectFinancingType.MAIN]: '0',
    };
  }, []);

  const [isWarningPopupOpened, setIsWarningPopupOpened] = useState<boolean>(false);
  const [updatedStage, setUpdatedStage] = useState<Project.Stage | null>(null);
  const [performersCopyInfo, setPerformersCopyInfo] = useState<PerformersCopyInfo>({
    leader: null,
    isLeaderChecked: false,
    responsiblePerformer: null,
    isResponsiblePerformerChecked: false,
    restPerformers: [],
    isRestPerformersChecked: false,
  });
  const [isGeneratePlanOpen, setIsGeneratePlanOpen] = useState<boolean>(false);
  const [isAddStageToPerformerOpen, setIsAddStageToPerformerOpen] = useState<boolean>(false);
  const [periodDuration, setPeriodDuration] = useState<Period>(Period.year);
  const [financingValues, setFinancingValues] = useState<Record<ProjectFinancingType, string>>(financingValuesEmpty);

  const leaderIndex = useMemo(() => {
    const indeces = project ? getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.LEADER) : [];
    const [index] = indeces;
    return index;
  }, [project]);

  const responsiblePerformerIndex = useMemo(() => {
    const indeces = project ? getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.RESPONSIBLE_PERFORMER) : [];
    const [index] = indeces;
    return index;
  }, [project]);

  const leader = useMemo(() => {
    return leaderIndex !== null ? performers[leaderIndex] : null;
  }, [leaderIndex, performers]);

  const responsiblePerformer = useMemo(() => {
    return responsiblePerformerIndex !== null ? performers[responsiblePerformerIndex] : null;
  }, [performers, responsiblePerformerIndex]);

  const editableFinancings = useMemo<ProjectFinancingType[]>(
    () => Object.keys(ProjectFinancingType).filter(x => financings.find(y => y.type?.value === x)) as ProjectFinancingType[],
    [financings],
  );

  const closeWarningPopup = useCallback(() => {
    setIsWarningPopupOpened(false);
  }, []);

  const openWarningPopup = useCallback(() => {
    setIsWarningPopupOpened(true);
  }, []);

  const onCloseAddStageToPerformer = useCallback(() => {
    setIsAddStageToPerformerOpen(false);
  }, []);

  const onConfirmAddStageToPerformer = useCallback(() => {
    setIsAddStageToPerformerOpen(false);
    const nextPerformers = performers.map((performer, index) => {
      const nextPerformer: Project.Performer = R.clone(performer);

      const isLeader = index === performersCopyInfo.leader;
      const isResponsiblePerformer = index === performersCopyInfo.responsiblePerformer;
      const isRestPerformer = !isLeader && !isResponsiblePerformer;

      const isNeedToAddLeader = isLeader && performersCopyInfo.isLeaderChecked;
      const isNeedToAddResponsiblePerformer = isResponsiblePerformer && performersCopyInfo.isResponsiblePerformerChecked;
      const isNeedToAddRestPerformer = isRestPerformer && performersCopyInfo.isRestPerformersChecked;
      const isPerformerAlredyContainsStage = nextPerformer.stages.some(({ stage }) => stage?.number === updatedStage?.number);
      const isNeedToAdd =
        (isNeedToAddLeader || isNeedToAddResponsiblePerformer || isNeedToAddRestPerformer) && !isPerformerAlredyContainsStage;

      if (isNeedToAdd) {
        nextPerformer.stages.push({ ...getMockPerformerStage(), stage: updatedStage });
      }

      return nextPerformer;
    });

    setPerformers(nextPerformers);
  }, [
    performers,
    setPerformers,
    performersCopyInfo.leader,
    performersCopyInfo.responsiblePerformer,
    performersCopyInfo.isLeaderChecked,
    performersCopyInfo.isResponsiblePerformerChecked,
    performersCopyInfo.isRestPerformersChecked,
    updatedStage,
  ]);

  const ndsPercentFormatter = useCallback((stage: Project.Stage) => formatNumber(getStageNdsPercent(stage)), []);

  const totalNdsFormatter = useCallback((row: Project.Stage) => formatNumber(getStageTotalNds(row)), []);

  const onStagesChange = useCallback(
    (nextStages: Project.Stage[]) => {
      const nextFinancingsByYear = computeFinancingsByYear(nextStages);
      const nextFinancings = calcSumFinancings(nextStages, financings);

      setFinancings(nextFinancings);
      setFinancingsByYear(nextFinancingsByYear);
      setStages(nextStages);
    },
    [financings, setFinancingsByYear, setStages, setFinancings],
  );

  const onStagePreSubmit = useCallback(
    (stage: Project.Stage, submit: (updatedStage: Project.Stage) => void, index: number | null, mode: Mode) => {
      const prevStageNumber = mode === 'add' ? stage.number : stages[index!].number;

      if (!stage.id) {
        const restPerformersIndexes = performers
          .filter((_, restPerformerIndex) =>
            [leaderIndex, responsiblePerformerIndex].every(busyIndex => busyIndex !== restPerformerIndex),
          )
          .map((_, restPerformerIndex) => restPerformerIndex);

        const getIsPerformerContainsStage = (performer: Project.Performer): boolean => {
          return performer.stages.some((performerStage: Project.PerformerStage) => {
            return performerStage?.stage?.number === prevStageNumber;
          });
        };

        const isLeaderNotContainsStage = !!leader && !getIsPerformerContainsStage(leader);
        const isResponsiblePerformerNotContainsStage =
          !!responsiblePerformer && !getIsPerformerContainsStage(responsiblePerformer);

        setPerformersCopyInfo({
          restPerformers: restPerformersIndexes,
          isRestPerformersChecked: false,
          leader: isLeaderNotContainsStage ? leaderIndex : null,
          isLeaderChecked: false,
          responsiblePerformer: isResponsiblePerformerNotContainsStage ? responsiblePerformerIndex : null,
          isResponsiblePerformerChecked: false,
        });

        const isNeedOpenAddStageToPerformer =
          isLeaderNotContainsStage || isResponsiblePerformerNotContainsStage || restPerformersIndexes.length;

        if (isNeedOpenAddStageToPerformer) {
          setUpdatedStage(stage);
          setIsAddStageToPerformerOpen(true);
        }
      }

      if (mode === 'edit') {
        const isSomePerforemerHasEditedStage = performers.some(performer =>
          performer.stages.some(performerStage => performerStage.stage?.number === prevStageNumber),
        );

        if (isSomePerforemerHasEditedStage) {
          const nextPerformers = performers.map(performer => {
            const nextPerformer = R.clone(performer);
            const stageIndex = nextPerformer.stages.findIndex(performerStage => performerStage.stage?.number === prevStageNumber);

            if (stageIndex !== -1) {
              const prevStage = R.clone(nextPerformer.stages[stageIndex]);
              nextPerformer.stages.splice(stageIndex, 1, { ...prevStage, stage });
            }

            return nextPerformer;
          });

          setPerformers(nextPerformers);
        }
      }
      submit(stage);
    },
    [stages, performers, leader, responsiblePerformer, leaderIndex, responsiblePerformerIndex, setPerformers],
  );

  const onStagePreDelete = useCallback(
    (stage: Project.Stage, continueDelete: () => void) => {
      const stageNumber = stage.number;

      const isSomePerforemerHasDeletedStage = performers.some(performer =>
        performer.stages.some(performerStage => performerStage.stage?.number === stageNumber),
      );

      if (isSomePerforemerHasDeletedStage) {
        const nextPerformers = performers.map(performer => {
          const nextPerformer = R.clone(performer);
          const stageIndex = nextPerformer.stages.findIndex(performerStage => performerStage.stage?.number === stageNumber);

          if (stageIndex !== -1) {
            nextPerformer.stages.splice(stageIndex, 1);
          }

          return nextPerformer;
        });

        setPerformers(nextPerformers);
      }

      continueDelete();
    },
    [performers, setPerformers],
  );

  const closeGeneratePlan = useCallback(() => {
    setIsGeneratePlanOpen(false);
  }, []);

  const openGeneratePlan = useCallback(() => {
    setIsGeneratePlanOpen(true);
    setFinancingValues(financingValuesEmpty);
    setPeriodDuration(Period.year);
  }, [financingValuesEmpty]);

  const submitCalendarPlan = useCallback(() => {
    closeGeneratePlan();
    const { stages: nextStages, performers: nextPerformers } = getInitialProjectWithStages({
      initialProject: project,
      isAddOtherToStages: false,
      periodDuration,
      financingValues,
    });

    const nextFinancingsByYear = computeFinancingsByYear(nextStages);
    const nextFinancings = calcSumFinancings(nextStages, financings);

    setPerformers(nextPerformers);
    setFinancings(nextFinancings);
    setFinancingsByYear(nextFinancingsByYear);
    setStages(nextStages);
  }, [
    closeGeneratePlan,
    financingValues,
    financings,
    periodDuration,
    project,
    setFinancings,
    setFinancingsByYear,
    setPerformers,
    setStages,
  ]);

  const makeChangeIsCheckedHandler = useCallback(
    (performerCheckedRole: PerformerCheckedRole) => (nextIsChecked: boolean) => {
      let keyName: KeyName | null = null;
      switch (performerCheckedRole) {
        case PerformerCheckedRole.LEADER:
          keyName = 'isLeaderChecked';
          break;
        case PerformerCheckedRole.RESPONSIBLE_PERFORMER:
          keyName = 'isResponsiblePerformerChecked';
          break;
        case PerformerCheckedRole.REST_PERFORMERS:
          keyName = 'isRestPerformersChecked';
          break;
      }

      if (keyName !== null) {
        setPerformersCopyInfo(prevPerformersCopyInfo => ({ ...prevPerformersCopyInfo, [keyName!]: nextIsChecked }));
      }
    },
    [],
  );

  const extraToolbarButtons: ExtraToolbarButton<Project.Stage>[] = useMemo(
    () => [
      {
        icons: buttonIcons.clone,
        title: 'Сформировать календарный план',
        checkIsDisabled: () => false,
        onClick: () => {
          if (!leader && !responsiblePerformer) {
            showNotification({
              message: 'Формирование календарного плана доступно только при наличии руководителя или ответственного исполнителя',
              theme: 'danger',
            });
            return;
          }

          if (stages.length) {
            showNotification({
              message: 'Формирование календарного плана доступно только при отсутствии этапов в списке',
              theme: 'danger',
            });
            return;
          }

          const isProjectHasStartAndEndDate = project?.startDate && project?.endDate;
          if (!isProjectHasStartAndEndDate) {
            showNotification({
              message: 'Для формирования календарного плана необходимо указать период проекта',
              theme: 'danger',
            });
            return;
          }

          openGeneratePlan();
        },
      },
    ],

    [leader, openGeneratePlan, project?.endDate, project?.startDate, responsiblePerformer, stages.length],
  );

  return {
    isWarningPopupOpened,
    isAddStageToPerformerOpen,
    isGeneratePlanOpen,
    extraToolbarButtons,
    performersCopyInfo,
    closeGeneratePlan,
    submitCalendarPlan,
    onStagesChange,
    ndsPercentFormatter,
    totalNdsFormatter,
    onStagePreSubmit,
    openWarningPopup,
    onStagePreDelete,
    closeWarningPopup,
    makeChangeIsCheckedHandler,
    onCloseAddStageToPerformer,
    onConfirmAddStageToPerformer,
    periodDuration,
    setPeriodDuration,
    financingValues,
    setFinancingValues,
    editableFinancings,
  };
};

export default useController;
