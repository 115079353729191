import React, { memo } from 'react';
import { Table } from 'types/models';
import { ConfirmPopup, Toolbar } from 'components';
import * as modalTemplates from '../modalTemplates';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

type WrapperProps = {
  eventId?: string;
  isDisableEventSource: boolean;
};

const ToolbarTemplate = ({ eventId, isDisableEventSource }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isAddFormOpen,
      isEditFormOpen,
      closeAddForm,
      closeEditForm,
      toolbarButtons,
      closeConfirmDeletePopup,
      isConfirmDeletePopupOpen,
      confirmDeleteRelease,
      relatedTableId,
    } = useController({
      tableState,
    });

    return (
      <>
        <Toolbar buttons={toolbarButtons} />

        <modalTemplates.MagazineReleaseAddTemplate
          isOpen={isAddFormOpen}
          onClose={closeAddForm}
          relatedTableId={relatedTableId}
          isDisableEventSource={isDisableEventSource}
          eventId={eventId}
        />
        <modalTemplates.MagazineReleaseEditTemplate
          isOpen={isEditFormOpen}
          onClose={closeEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableId={relatedTableId}
          isDisableEventSource={isDisableEventSource}
          eventId={eventId}
        />

        {!!tableState.selectedRows.length && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранный выпуск? (${tableState.selectedRows[0].label})`}
            isOpen={isConfirmDeletePopupOpen}
            onClose={closeConfirmDeletePopup}
            onConfirm={confirmDeleteRelease}
            okButtonText="Да"
            resetButtonText="Отмена"
          />
        )}
      </>
    );
  });

export default ToolbarTemplate;
