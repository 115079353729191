import React, { memo, useCallback } from 'react';

import { FormComponent, Reference, ReferenceFilter, ReferenceItem } from 'components';

type Props = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  fieldLabel: string;
  referenceName: string;
  relationTableModalTitle: string;
  referenceFilter: ReferenceFilter[];
};

const ReferenceSelect = ({
  referenceItem,
  setReferenceItem,
  fieldLabel,
  referenceName,
  relationTableModalTitle,
  referenceFilter,
}: Props) => {
  const handleSetReferenceItem = useCallback(
    (nextReferenceItem: ReferenceItem | null) => {
      if (nextReferenceItem) {
        setReferenceItem(nextReferenceItem);
      }
    },
    [setReferenceItem],
  );

  return (
    <FormComponent.Wrapper>
      <Reference
        title={fieldLabel}
        name={referenceName}
        onChange={handleSetReferenceItem}
        relationTableModalTitle={relationTableModalTitle}
        value={referenceItem}
        filters={referenceFilter}
        isRequired
        disabled={false}
      />
    </FormComponent.Wrapper>
  );
};

const Component = memo(ReferenceSelect);

export { Component as ReferenceSelect };
