import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';

export const GetThematicalSearchProjectStateRegistrationReportList: T.Specification = {
  apiID: 'GetThematicalSearchProjectStateRegistrationReportList',
  header: {
    firstLevel: {
      title: 'Отчёты по результатам научных исследований',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: true,
    },
  },
};
