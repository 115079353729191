import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const ProjectPerformerOrderAddTemplate: React.FC<F.TemplateProps & { projectId?: string; projectSelectable?: boolean }> = ({
  isOpen,
  onClose,
  projectId,
  relatedTableState,
  name,
  projectSelectable,
}: F.TemplateProps & { projectId?: string; projectSelectable?: boolean }) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look & { projectId?: string; projectSelectable?: boolean } = {
    apiID: 'GetPerformerOrder',
    template: 'ProjectPerformerOrderForm',
    projectId,
    relatedTableState,
    name,
    setTitle,
    projectSelectable,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ProjectPerformerOrderAddTemplate);
