import React from 'react';

import { FormComponent, ListEdit, TextInput } from 'components';

import { TenderProtocol } from 'types/models/TenderProtocol';
import { WorkMode } from 'types/models/Table';
import { ValueOf } from 'types/helpers';
import { formatNumber, getEnumItemLabel } from 'utils/Helpers';
import { GetProtocolCommissionMemberList } from 'features/Table/specifications/GetProtocolCommissionMemberList';
import {
  getMockCommissionMember,
  getMockCommissionMemberPerson,
} from 'features/Form/looks/protocolCommission/ProtocolCommissionForm/helpers';
import { ProtocolMainFields } from 'features/Form/views';
import { MobileRequestStatus, NirRequestStatus, RequestStatus } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  tenderProtocol: TenderProtocol;
  workMode: WorkMode;
  makeChangeHandler: (key: keyof TenderProtocol) => (val: ValueOf<TenderProtocol>) => void;
};
export function AboutProtocol(props: Props) {
  const { tenderProtocol, workMode, makeChangeHandler } = props;
  const { enumMap } = useAppDataContext();

  const programRequestsStatuses = [
    RequestStatus.REQUEST,
    RequestStatus.RECOMENDED,
    RequestStatus.REVISION,
    RequestStatus.REJECTED,
  ];
  const nirRequestStatuses = [
    NirRequestStatus.REQUEST,
    NirRequestStatus.REVISION,
    NirRequestStatus.REJECTED,
    NirRequestStatus.AGREED,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestStatus.SUPPORTED,
  ];
  const mobileRequestStatuses = [
    MobileRequestStatus.REQUEST,
    MobileRequestStatus.RECOMENDED,
    MobileRequestStatus.REVISION,
    MobileRequestStatus.REJECTED,
  ];

  const requests = [...(tenderProtocol.tender?.requests || []), ...(tenderProtocol.tender?.programRequests || [])];

  const programRequestCount = requests.filter(x => programRequestsStatuses.includes(x.status?.value as RequestStatus)).length;
  const nirRequestCount = (tenderProtocol.tender?.nirRequests || []).filter(x =>
    nirRequestStatuses.includes(x.status?.value as NirRequestStatus),
  ).length;
  const mobileRequestCount = (tenderProtocol.tender?.mobileRequests || []).filter(x =>
    mobileRequestStatuses.includes(x.status?.value as MobileRequestStatus),
  ).length;

  const getFilteredProgramRequest = (key: RequestStatus) => requests.filter(x => x.status?.value === key);
  const getFilteredNirRequest = (key: NirRequestStatus) =>
    (tenderProtocol.tender?.nirRequests || []).filter(x => x.status?.value === key);
  const getFilteredMobileRequest = (key: MobileRequestStatus) =>
    (tenderProtocol.tender?.mobileRequests || []).filter(x => x.status?.value === key);

  const calcRequestByStatus = (status: RequestStatus) =>
    getFilteredProgramRequest(status)
      .reduce((a, b) => a + b.finances.reduce((c, d) => c + Number(d.fbAmount), 0), 0)
      .toString() || 0;

  const calcNirRequestByStatus = (status: NirRequestStatus) =>
    getFilteredNirRequest(status)
      .reduce((a, b) => a + b.stages.reduce((c, d) => c + Number(d.amount), 0), 0)
      .toString() || 0;

  const amountSum = () => {
    if (!!programRequestCount) {
      return formatNumber(calcRequestByStatus(RequestStatus.RECOMENDED), 2);
    }

    if (!!nirRequestCount) {
      return formatNumber(
        Number(calcNirRequestByStatus(NirRequestStatus.AGREED)) +
          Number(calcNirRequestByStatus(NirRequestStatus.AGREED_WITH_CONDITION)) +
          Number(calcNirRequestByStatus(NirRequestStatus.SUPPORTED)),
        2,
      );
    }

    return formatNumber(tenderProtocol.tender?.marketplaceAmount || 0, 2);
  };

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column specialStyles={{ width: '54%' }}>
        <ProtocolMainFields
          workMode={workMode}
          tenderProtocol={tenderProtocol}
          changeTender={makeChangeHandler('tender')}
          changeNumber={makeChangeHandler('number')}
          changeProtocolDate={makeChangeHandler('protocolDate')}
          changeProtocolCommission={makeChangeHandler('protocolCommission')}
        />
        <FormComponent.Field>
          <ListEdit
            rows={tenderProtocol.commissionMembers || []}
            onChange={makeChangeHandler('commissionMembers')}
            title="Присутствовали на заседании"
            defaultRowsCount={10}
            isToolbarDisabled={workMode === 'viewMode'}
            columns={[
              { label: 'Роль', formatValue: row => row.role?.label || '' },
              { label: 'ФИО', formatValue: row => row?.person?.fullName || '' },
            ]}
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: `Члены комиссии ${tenderProtocol.protocolCommission?.name}`,
              modalTableRowConverter: row => {
                return {
                  ...getMockCommissionMember(),
                  person: {
                    ...getMockCommissionMemberPerson(),
                    fullName: row.Person,
                  },
                  id: row.id,
                  role: { label: row.Role, value: '' },
                  priority: parseInt(row.Priority),
                };
              },
              modalTableSpecification: GetProtocolCommissionMemberList({
                protocolCommissionId: tenderProtocol.protocolCommission?.id,
              }),
            }}
          />
        </FormComponent.Field>
      </FormComponent.Column>
      <FormComponent.Column specialStyles={{ width: '40%' }}>
        <FormComponent.Field label="Вид формы заявки">
          <div>{tenderProtocol.tender?.requestType?.label}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Всего заявок">
          <div>{programRequestCount + nirRequestCount + mobileRequestCount}</div>
        </FormComponent.Field>
        <>
          {!!programRequestCount &&
            programRequestsStatuses.map((key, index) => (
              <FormComponent.Line key={index} hasFreeFormat growIndexes={[0, 10]}>
                <FormComponent.Field label={getEnumItemLabel('RequestStatus', key, enumMap)}>
                  {getFilteredProgramRequest(key).length.toString()}
                </FormComponent.Field>
                <FormComponent.Field label="Сумма" isHidden={key !== RequestStatus.RECOMENDED}>
                  {formatNumber(calcRequestByStatus(key), 2)}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
          {!!nirRequestCount &&
            nirRequestStatuses.map((key, index) => (
              <FormComponent.Line key={index} hasFreeFormat growIndexes={[0, 10]}>
                <FormComponent.Field label={getEnumItemLabel('NirRequestStatus', key, enumMap)}>
                  {getFilteredNirRequest(key).length.toString()}
                </FormComponent.Field>
                <FormComponent.Field
                  label="Сумма"
                  isHidden={
                    ![NirRequestStatus.AGREED, NirRequestStatus.AGREED_WITH_CONDITION, NirRequestStatus.SUPPORTED].includes(key)
                  }
                >
                  {formatNumber(calcNirRequestByStatus(key), 2)}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
          {!!mobileRequestCount &&
            mobileRequestStatuses.map((key, index) => (
              <FormComponent.Line key={index} hasFreeFormat growIndexes={[0, 10]}>
                <FormComponent.Field label={getEnumItemLabel('MobileRequestStatus', key, enumMap)}>
                  {getFilteredMobileRequest(key).length.toString()}
                </FormComponent.Field>
                <FormComponent.Field label="Сумма" isHidden={key !== MobileRequestStatus.RECOMENDED}>
                  {formatNumber(
                    getFilteredMobileRequest(key).reduce(
                      (a, b) => a + b.estimatePositions.reduce((c, d) => c + Number(d.amount), 0),
                      0,
                    ) || 0,
                    2,
                  )}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
        </>
        <FormComponent.Field label="Объём запрашиваемых средств">
          <div>{amountSum()}</div>
        </FormComponent.Field>
        <FormComponent.Field
          label="О ходе проведения конкурса на заседании комиссии слушали"
          tooltip="Для оформления протокола введите в родительном падеже Фамилию и инициалы докладчика"
        >
          <TextInput
            value={tenderProtocol.reporter}
            onChange={makeChangeHandler('reporter')}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
