import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';

const configurations = {
  AddUserRole: makeBackendAPIConfiguration({
    id: 'AddUserRole',
    endpoint: '/msa/service/commands/AddUserRole',
    convertInputToXMLElement: ({ userId, roleId }: { userId: string; roleId: string }) => ({
      Request: {
        _attr: { commandName: 'AddUserRole' },
        UserRole: {
          _attr: { userId, roleId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteUserRole: makeBackendAPIConfiguration({
    id: 'DeleteUserRole',
    endpoint: '/msa/service/commands/DeleteUserRole',
    convertInputToXMLElement: ({ userId, roleId }: { userId: string; roleId: string }) => ({
      Request: {
        _attr: { commandName: 'DeleteUserRole' },
        UserRole: {
          _attr: { userId, roleId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const userRoleConfigurations = { ...configurations };
