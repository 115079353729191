import { PublicationTypes } from 'utils/Enums/PublicationTypes';

export default [
  {
    id: '1',
    label: PublicationTypes.COMPILATION_SCIENTIFIC_WORK.label,
    labelDeclined: 'COMPILATION_SCIENTIFIC_WORK',
    code: PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
  },
  {
    id: '2',
    label: PublicationTypes.COMPILATION_CONFERENCE.label,
    labelDeclined: 'COMPILATION_CONFERENCE',
    code: PublicationTypes.COMPILATION_CONFERENCE.code,
  },
  {
    id: '3',
    label: PublicationTypes.COMPILATION_OTHER.label,
    labelDeclined: 'COMPILATION_OTHER',
    code: PublicationTypes.COMPILATION_OTHER.code,
  },
];

export const electronicPublicationTypes = [
  {
    id: '1',
    label: 'Сетевой ресурс',
    text: 'Публикация размещена в сети Интернет',
    code: 'WEB',
  },
  {
    id: '2',
    label: 'Эл. ресурс локального доступа',
    text: 'Публикация размещена на электронном носителе. Например: CD-диске',
    code: 'LOCAL',
  },
];
export const articlePublicationTypes = [
  {
    id: '1',
    label: 'Сборник материалов конференций, симпозиумов и др.',
    code: 'ARTICLE_CONFERENCE',
  },
  {
    id: '2',
    label: 'Тезисы',
    code: 'THESIS',
  },
];
