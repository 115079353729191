import * as R from 'ramda';

import { UserPermission } from 'types/models/User';

export function isHasPermission(userPermission: UserPermission | null, permission: string | null | undefined) {
  return (
    userPermission !== null && !!permission && (userPermission.isSuperUser || userPermission.permissions.includes(permission))
  );
}

export function isHasSomePermission(userPermission: UserPermission | null, permissions: string[]) {
  if (userPermission === null || !permissions || !permissions.length) {
    return false;
  }

  const isHasSomeOnePermission = Boolean(R.intersection(permissions, userPermission.permissions).length);
  return userPermission.isSuperUser || isHasSomeOnePermission;
}

export function getIsUserHaveEveryPermission(userPermission: UserPermission | null, permissions: string[]) {
  if (userPermission === null) {
    return false;
  }
  const isUserHaveEveryPermission =
    userPermission.isSuperUser ||
    permissions.every(permission => userPermission.permissions.some(userPermissionName => userPermissionName === permission));

  return isUserHaveEveryPermission;
}
export function redirectWithPermissions(
  isAccountPage: boolean,
  isHasPermissionToAccount: boolean | null,
  isHasPermissionToMain: boolean | null,
  history: any,
) {
  if (location.pathname.indexOf('signin') === -1) {
    if (isAccountPage && isHasPermissionToAccount === false) {
      history.push('/');
    }
    if (!isAccountPage && isHasPermissionToMain === false) {
      if (isHasPermissionToAccount) {
        history.push('/account');
      }
    }
  }
}
