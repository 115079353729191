import { FormComponent, Modal, ConfirmPopup, ListEdit } from 'components';

import { formatAuthor } from 'features/Form/views/AboutAuthorship/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { useController } from './controller';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { TypeEditionRelationsCode } from 'utils/Enums/TypeEditionRelationsCode';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  isOpen: boolean;
  onClose(): void;
  id: string;
  title: string;
  errorText: string;
  type: string;
  participationType?: string;
};

function EditRelationsProfileModal(props: Props) {
  const { isOpen, onClose, id, title, errorText, type, participationType } = props;
  const { settings } = useAppDataContext();
  const {
    authors,
    departments,
    setDepartments,
    handleSubmit,
    biblioRecord,
    publicationType,
    isErrorModal,
    errorStatus,
    handleCloseErrorModal,
    participationData,
  } = useController({ id, onClose, errorText, type });

  const aboutEvent = (eventType: string) => {
    if (!participationData?.event) {
      return eventType;
    }
    const details = [
      participationData?.event?.id ? `ID: ${participationData?.event?.id}` : '',
      participationData?.event?.status ? `Статус записи: ${participationData?.event?.status}` : '',
    ]
      .filter(x => x)
      .join('. ');
    return [`${eventType}:`, participationData?.event?.fullName || '', details ? `(${details})` : ''].filter(x => x).join(' ');
  };

  return isErrorModal ? (
    <ConfirmPopup
      title={errorStatus.type}
      text={errorStatus.text}
      isOpen={isErrorModal}
      resetButtonText="Закрыть"
      onClose={handleCloseErrorModal}
      icon="warning"
    />
  ) : (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <ControlPanel handleSave={handleSubmit} handleCancel={onClose} />

      <FormComponent.Wrapper>
        {type === TypeEditionRelationsCode.PUBLICATION && (
          <>
            <FormComponent.Line>
              <strong>{`Библиографическая запись ${publicationType?.genitiveCaseLabel || ''}:`}</strong>
            </FormComponent.Line>
            <FormComponent.Line>
              <span>{biblioRecord}</span>
            </FormComponent.Line>

            <FormComponent.Line>
              <strong>Авторы:</strong>
            </FormComponent.Line>
            <>
              {authors.map((author, index) => (
                <FormComponent.Line>
                  <div key={index} dangerouslySetInnerHTML={{ __html: formatAuthor(author, settings) }} />
                </FormComponent.Line>
              ))}
            </>
          </>
        )}
        {type === TypeEditionRelationsCode.PARTICIPATION && (
          <>
            {participationType === ParticipationTypes.PARTICIPATION_CONFERENCE.code && (
              <FormComponent.Line>
                <span>
                  <strong>Мероприятие:&nbsp;</strong>
                  <span>{aboutEvent('Конференция')}</span>
                </span>
              </FormComponent.Line>
            )}
            {participationType === ParticipationTypes.PARTICIPATION_CONCERT.code && (
              <FormComponent.Line>
                <span>
                  <strong>Мероприятие:&nbsp;</strong>
                  <span>{aboutEvent('Творческое мероприятие')}</span>
                </span>
              </FormComponent.Line>
            )}
            {participationType === ParticipationTypes.PARTICIPATION_CONTEST.code && (
              <FormComponent.Line>
                <span>
                  <strong>Мероприятие:&nbsp;</strong>
                  <span>{aboutEvent('Конкурс')}</span>
                </span>
              </FormComponent.Line>
            )}
            {participationType === ParticipationTypes.PARTICIPATION_EXPOSITION.code && (
              <FormComponent.Line>
                <span>
                  <strong>Мероприятие:&nbsp;</strong>
                  <span>{aboutEvent('Выставка')}</span>
                </span>
              </FormComponent.Line>
            )}
            {participationType === ParticipationTypes.PARTICIPATION_CONFERENCE.code &&
              (participationData?.reportTheme || participationData?.publication?.fields.name) && (
                <FormComponent.Line>
                  <span>
                    <strong>Тема доклада:&nbsp;</strong>
                    <span>{participationData?.publication?.fields.name}</span>
                  </span>
                </FormComponent.Line>
              )}
            <FormComponent.Line>
              <strong>Участники:&nbsp;</strong>
            </FormComponent.Line>
            <FormComponent.Line>
              <>
                {participationData?.members.map((member, index) => (
                  <div key={index} dangerouslySetInnerHTML={{ __html: formatAuthor(member, settings) }} />
                ))}
              </>
            </FormComponent.Line>
          </>
        )}
        <ListEdit
          rows={departments}
          onChange={setDepartments}
          columns={[{ label: 'Наименование', formatValue: x => x.ref.label }]}
          isDeleteConfirmEnabled
          withMessages
          specification={{
            mode: 'relationTableModal',
            relationTableModalTitle: 'Справочник "Подразделения"',
            modalTableRowConverter: row => ({ ref: { label: row.label, id: row.id }, id: null }),
            modalTableSpecification: GetReferenceElementList({
              requestData: {
                filters: [],
                name: 'RefDepartment',
              },
            }),
          }}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { EditRelationsProfileModal };
