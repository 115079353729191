import React from 'react';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

export function LeftPanelForThirdLevel({ tableState }: Props) {
  const { isEditButtonDisabled, isEditFormOpen, handleTemplateCloseEditForm, buttons } = useController({ tableState });

  return (
    <>
      {!isEditButtonDisabled && (
        <modalTemplates.SecurityDocumentPaymentEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}
export const Component = LeftPanelForThirdLevel;
