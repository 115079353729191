import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';
import { AuthorPayment } from 'types/models/Payment';
import { DataGrid } from 'features/Table';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications';
import { useController } from './controller';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void, needConfirm?: boolean): void;
  onSaveDraft(): void;
  onChangeStatus(status: AuthorPaymentRequestStatus): void;
  onChangeConfirm(status: AuthorPaymentRequestStatus): void;
  paymentRequest?: AuthorPayment | null;
};

function Toolbar(props: Props) {
  const { onSave, onSaveDraft, paymentRequest, onChangeStatus, onChangeConfirm } = props;

  const { buttons, isFeedlineModalOpen, setIsFeedlineModalOpen } = useController({
    onSave,
    onSaveDraft,
    paymentRequest,
    onChangeStatus,
    onChangeConfirm,
  });
  return (
    <>
      <Modal
        isOpen={isFeedlineModalOpen || false}
        onClose={() => setIsFeedlineModalOpen(false)}
        title="Лента активности"
        size={['medium', 'small-height']}
      >
        <DataGrid specification={GetAuthorPaymentRequestFeedlineList({ paymentId: paymentRequest?.id || '' })} />
      </Modal>
      <SharedToolbar buttons={buttons} mode="form" />
    </>
  );
}

export { Toolbar };
