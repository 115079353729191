import { Table } from 'types/models';
import { makeUseCustomController } from 'features/Table/specifications/GetReferenceElementList/makeUseCustomController';
import { State } from 'features/Table/specifications/GetReferenceElementList/model';
import { Toolbar as LeftPanelForThirdLevel } from 'features/Table/specifications/GetReferenceElementList/Toolbar/Toolbar';
import { Editor as ComponentForSecondLevel } from 'features/Table/specifications/GetReferenceElementList/Editor/Editor';

export function GetEnterpriseCustomerSelectList(): Table.Specification<State> {
  return {
    apiID: 'GetEnterpriseCustomerSelectList',
    useCustomController: makeUseCustomController({ name: 'RefEnterpriseCustomer', isFacultyFilter: false }),
    header: {
      firstLevel: {
        title: 'Справочник "Заказчики"',
      },
      secondLevel: {
        Component: ComponentForSecondLevel,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    isNotInitialLoadTable: true,
    isCanSelectOnlyOneRow: true,
  };
}
