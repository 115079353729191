import { showNotification } from 'features/Notifications';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';

import { useDebounce } from 'shared/react/useDebounce';

import { AuthorPayment } from 'types/models/Payment';
import { ValueOf } from 'types/helpers';

import { Payment } from 'types/models';
import { LocalAuthorPayment, State } from '../model';
import { getFoundationId, getFoundationType, validateForm } from '../helpers';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

export function useController(customState: State) {
  const { termPreset, authorPayment: payment, event: typeEvent, toggleVisibleForm } = customState;
  const isPreventLoadDistribute = useRef(true);

  const { methods: saveArticlePayment } = BackendAPI.useBackendAPI('SaveArticlePaymentRequest');
  const { methods: saveConferencePayment } = BackendAPI.useBackendAPI('SaveConferencePaymentRequest');
  const { methods: saveMonographPayment } = BackendAPI.useBackendAPI('SaveMonographPaymentRequest');
  const { methods: saveTextBookPayment } = BackendAPI.useBackendAPI('SaveTextBookPaymentRequest');
  const { methods: saveParticipationPayment } = BackendAPI.useBackendAPI('SaveExhibitionAwardsPaymentRequest');

  type SaveApi =
    | typeof saveArticlePayment
    | typeof saveConferencePayment
    | typeof saveMonographPayment
    | typeof saveTextBookPayment
    | typeof saveParticipationPayment;

  const mapAPI = useMemo<Record<Payment.EventType, SaveApi>>(
    () => ({
      ARTICLE: saveArticlePayment,
      CONFERENCE: saveConferencePayment,
      MONOGRAPH: saveMonographPayment,
      TEXTBOOK: saveTextBookPayment,
      MEDAL: saveParticipationPayment,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [authorPayment, setAuthorPayment] = useState<LocalAuthorPayment | null>(null);
  const [isOpenCommentForm, setIsOpenCommentForm] = useState(false);
  const [savedInstanceId, setSavedInstanceId] = useState<string | null>(null);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState<AuthorPaymentRequestStatus | null>(null);

  const tableStreams = useLocalTableStreams();

  useEffect(() => {
    setAuthorPayment(payment);
  }, [payment]);

  useEffect(() => {
    if (termPreset) {
      setAuthorPayment(prevAuthor => ({
        ...prevAuthor!,
        regulationName: termPreset?.regulationName ?? '',
        regulationParagraph: termPreset?.regulationParagraph ?? '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termPreset]);

  const reloadTable = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams.reloadTable]);

  const { methods: distributeMoneyAPI } = BackendAPI.useBackendAPI('DistributeMoney', {
    onSuccessfullCall: ({ data }) => {
      if (data) {
        isPreventLoadDistribute.current = true;
        setAuthorPayment({
          ...data,
          id: authorPayment?.id ?? null,
          regulationName: authorPayment?.regulationName ?? '',
          regulationParagraph: authorPayment?.regulationParagraph ?? '',
          status: authorPayment?.status ?? data.status,
        });
      }
    },
    onFailedCall: () => {},
  });

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeAuthorPaymentRequestStatus');

  const submitForm = useCallback(() => {
    const validationInfo = validateForm(authorPayment);

    if (validationInfo.some(x => !x.isValid)) {
      validationInfo.forEach(({ isValid, invalidMessage }) => {
        if (!isValid) {
          setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
        }
      });
      return;
    }

    setIsOpenCommentForm(true);
  }, [authorPayment]);

  const changeStatus = useCallback(() => {
    if (savedInstanceId && status) {
      changeStatusAPI.callAPI(
        { status, paymentId: savedInstanceId, comment },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Статус заявки успешно изменен', theme: 'success' });
            setSavedInstanceId(null);
            toggleVisibleForm();
            reloadTable();
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedInstanceId, comment, status, toggleVisibleForm, reloadTable]);

  const savePayment = useCallback(() => {
    if (authorPayment) {
      const save = mapAPI[typeEvent];
      save.callAPI(
        { authorPayment, comment, eventType: typeEvent },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
            toggleVisibleForm();
            reloadTable();
          },
        },
      );
    }
  }, [authorPayment, comment, mapAPI, typeEvent, toggleVisibleForm, reloadTable]);

  const prepareChangeStatusEvent = useCallback(
    (x: AuthorPaymentRequestStatus) => {
      const validationInfo = validateForm(authorPayment);

      if (validationInfo.some(item => !item.isValid)) {
        validationInfo.forEach(({ isValid, invalidMessage }) => {
          if (!isValid) {
            setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
          }
        });
        return;
      }

      if (authorPayment) {
        const save = mapAPI[typeEvent];
        save.callAPI(
          { authorPayment, comment, eventType: typeEvent },
          {
            onSuccessfullCall: ({ data }) => {
              setSavedInstanceId(data.id);
              setIsOpenCommentForm(true);
              setStatus(x);
            },
          },
        );
      }
    },
    [authorPayment, comment, mapAPI, typeEvent],
  );

  const debouncedMaxFundMoney = useDebounce(authorPayment?.maxFundMoney, 500);
  const debouncedMaxNomineesMoney = useDebounce(authorPayment?.maxNomineesMoney, 500);

  useEffect(() => {
    if (!isPreventLoadDistribute.current) {
      distributeMoneyAPI.callAPI({
        type: typeEvent,
        foundationId: getFoundationId(authorPayment, typeEvent),
        foundationType: getFoundationType(authorPayment, typeEvent),
        maxFundMoney: debouncedMaxFundMoney || '0',
        maxNomineesMoney: debouncedMaxNomineesMoney || '0',
        isManualEdit: authorPayment?.isManualEdit ?? false,
        nominees: authorPayment?.nominees ?? [],
        departments: authorPayment?.departments ?? [],
        distributionMethod: authorPayment?.distributionMethodBetweenFunds ?? 'PAID',
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    debouncedMaxFundMoney,
    debouncedMaxNomineesMoney,
    authorPayment?.nominees,
    authorPayment?.distributionMethodBetweenFunds,
    authorPayment?.departments,
    authorPayment?.isManualEdit,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const changeField = useCallback((key: keyof AuthorPayment, value: ValueOf<AuthorPayment>) => {
    isPreventLoadDistribute.current = false;
    setAuthorPayment(prevAuthor => ({ ...prevAuthor!, [key]: value }));
  }, []);

  return {
    authorPayment,
    isOpenCommentForm,
    comment,
    status,
    setStatus,
    setComment,
    setIsOpenCommentForm,
    changeField,
    savePayment,
    submitForm,
    prepareChangeStatusEvent,
    changeStatus,
    typeEvent,
  };
}
