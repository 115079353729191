import React from 'react';

import { Checkbox, CustomSelect, FormComponent, Reference, SectionTitle, TextArea } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Table as T, Form as F, Author, Award } from 'types/models';
import { Enterprise, ParticipationEventSource, ParticipationProject } from 'types/models/Participation';
import { Projects } from 'features/Form/looks/participation/views/Projects';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { useController } from './controller';
import { MembersList } from 'features/Form/looks/participation/views/MembersList';
import { CheckboxItem } from 'features/Form/looks/participation/ParticipationFormExposition/controller';
import { getEnum } from 'utils/Helpers';

type Props = {
  formFields: F.Fields;
  workMode: T.WorkMode;
  event: ParticipationEventSource | null;
  setEvent: (event: ParticipationEventSource | null) => void;
  relatedTableState: T.State | undefined;
  members: Author[];
  changeMembers: (val: Author[]) => void;
  enterprise: Enterprise | null;
  disabledEvent: boolean | undefined;
  id: string;
  extraToolbarButtons?: ExtraToolbarButton<Author>[];
  changeCurrentExponent: (val: any) => void;
  awards: Award[];
  presentationProject: ParticipationProject | null;
  setLocalProjects: (rows: ParticipationProject[]) => void;
  setLocalProjectsAccept: (id: string) => void;
  setLocalProjectsCancelAcception: (id: string) => void;
  isVisibleProjectField: boolean;
  participationPresentationForm: CheckboxItem[];
  handlePresentationFormChange: (val: boolean, ordinal: number) => void;
  isVisiblePresentationForm: boolean;
  isVisibleExponentSection: boolean;
  isVisibleExponentField: boolean;
  exponentProduct: string;
  isVisibleExpositionFields: boolean;
};

function About({
  formFields,
  workMode,
  event,
  setEvent,
  relatedTableState,
  members,
  changeMembers,
  enterprise,
  disabledEvent,
  id,
  extraToolbarButtons,
  changeCurrentExponent,
  awards,
  presentationProject,
  setLocalProjects,
  setLocalProjectsAccept,
  setLocalProjectsCancelAcception,
  isVisibleProjectField,
  participationPresentationForm,
  handlePresentationFormChange,
  isVisiblePresentationForm,
  isVisibleExponentSection,
  isVisibleExponentField,
  exponentProduct,
  isVisibleExpositionFields,
}: Props) {
  const {
    enumMap,
    modalTableRowToEventConventer,
    modalTableRowToExponentConverter,
    exponentTableConfig,
    shortEventTableConfig,
  } = useController({ relatedTableState });

  const projects: string = getEnum('ProjectType', enumMap)
    .map(x => x.label)
    .join(', ');

  return (
    <form>
      <input type="submit" hidden />
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <SectionTitle title={`Участие в выставке ${id ? `(ID ${id ?? ''})` : ''}`} />

          <FormComponent.Line>
            <FormComponent.Field label="Выставка" isRequired>
              <CustomSelect
                disabled={workMode === 'viewMode' || disabledEvent}
                isRequired
                isStatusText
                original={event}
                relationTableModalTitle="Выставки"
                onDeleteFunction={() => setEvent(null)}
                onChange={setEvent}
                modalTableRowConverter={modalTableRowToEventConventer}
                modalTableSpecification={shortEventTableConfig}
                isShowFullName
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Уровень мероприятия">
              <div>{event?.eventStatus?.label}</div>
            </FormComponent.Field>
            <FormComponent.Field label="Формат проведения">
              <div>{event?.format?.label}</div>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label="Формат участия"
              isRequired={formFields.form.required}
              tooltip="Выбор формата участия влияет на состав полей формы. "
            >
              <Reference
                disabled={workMode === 'viewMode'}
                name="RefParticipationForm"
                value={formFields.form.value ?? null}
                onChange={formFields.form.onChange}
                filters={[{ key: 'type', values: ['EXPOSITION'] }]}
                relationTableModalTitle='Справочник "Форматы участия"'
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field>
              <MembersList
                eventType="EXPOSITION"
                disabled={workMode === 'viewMode'}
                authors={members}
                setAuthors={changeMembers}
                isRequired={false}
                extraToolbarButtons={extraToolbarButtons}
                awards={awards}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          {isVisibleExponentSection && (
            <>
              <SectionTitle title="О разработке (проекте/экспозиции)" />
              {isVisibleExpositionFields && (
                <>
                  <FormComponent.Line>
                    <FormComponent.Field label={formFields.isCollectiveExhibit.title}>
                      <Checkbox
                        checked={!!formFields.isCollectiveExhibit.value}
                        onChange={formFields.isCollectiveExhibit.onChange}
                        isDisabled={workMode === 'viewMode'}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line lineSize="doublePadded">
                    <FormComponent.Field label={formFields.collectiveExhibitName.title} isRequired>
                      <TextArea
                        settings={{ rows: 3 }}
                        value={formFields.collectiveExhibitName.value}
                        onChange={formFields.collectiveExhibitName.onChange}
                        placeholder="Укажите название экспозиции, совместно с кем была организована."
                        isDisabled={workMode === 'viewMode'}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
              )}

              {isVisibleExponentField && (
                <>
                  <FormComponent.Line>
                    <FormComponent.Field label="Название разработки" isRequired>
                      <CustomSelect
                        disabled={workMode === 'viewMode'}
                        original={formFields.exponent?.value ?? null}
                        relationTableModalTitle="Список разработок, представленных на выставках"
                        onDeleteFunction={() => formFields.exponent.onChange(null)}
                        onChange={changeCurrentExponent}
                        modalTableRowConverter={modalTableRowToExponentConverter}
                        modalTableSpecification={exponentTableConfig}
                        rowsNumber={4}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  {(exponentProduct || enterprise) && (
                    <FormComponent.Line lineSize="doublePadded">
                      <FormComponent.Field label="">
                        <FormComponent.Description mode="warning">
                          {exponentProduct && (
                            <>
                              <span>{`Продвижение научно-технической разработки: ${exponentProduct}`}</span>
                              <br />
                            </>
                          )}
                          {enterprise && (
                            <span>{`Совместно с: ${`${enterprise.name} ${
                              enterprise.address ? ` (${enterprise.address})` : ''
                            }`}`}</span>
                          )}
                        </FormComponent.Description>
                      </FormComponent.Field>
                    </FormComponent.Line>
                  )}
                </>
              )}

              {isVisibleProjectField && (
                <FormComponent.Line>
                  <Projects
                    participationTypeCode={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
                    setLocalProjects={setLocalProjects}
                    setLocalProjectsAccept={setLocalProjectsAccept}
                    setLocalProjectsCancelAcception={setLocalProjectsCancelAcception}
                    workMode={workMode}
                    isDisabled={workMode === 'viewMode'}
                    isSingle
                    projects={presentationProject ? [presentationProject] : []}
                    relatedTableState={relatedTableState}
                    isRequired
                    selectTitle="Продвижение результатов проекта"
                    // eslint-disable-next-line max-len
                    tooltipText={`Укажите проект из списка проектов научного управления (${projects}). Если в списке проект отсутствует, то выберите Формат участия = Презентация разработки и заполните поле "Название разработки"`}
                  />
                </FormComponent.Line>
              )}
              {isVisiblePresentationForm && (
                <FormComponent.Field label="Форма представления" isRequired>
                  <FormComponent.Line hasWrap hasFreeFormat hasGap>
                    {participationPresentationForm.map((val, index) => (
                      <Checkbox
                        key={index}
                        label={val.label}
                        checked={!!val.isChecked}
                        onChange={checked => handlePresentationFormChange(checked, val.ordinal)}
                        isDisabled={workMode === 'viewMode'}
                      />
                    ))}
                  </FormComponent.Line>
                </FormComponent.Field>
              )}
            </>
          )}
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </form>
  );
}

export const Component = React.memo(About);
