import React from 'react';
import { Table } from 'types/models';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';
import { FundCardAddTemplate, FundCardEditTemplate } from './modalTemplates';

type Props = {
  tableState: Table.State;
};

export const Toolbar = ({ tableState }: Props) => {
  const {
    buttons,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    selectedRow,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    tableState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />
      {isAddFormOpen && (
        <FundCardAddTemplate isOpen={isAddFormOpen} onClose={() => setIsAddFormOpen(false)} relatedTableState={tableState} />
      )}
      {isEditFormOpen && (
        <FundCardEditTemplate
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}
      <Modal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Шифры Централизованного фонда"
        size="medium"
      >
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              <strong>Централизованный фонд</strong> предназначен для планирования объёмов накладных расходов по проектам
            </p>
            <p>
              {/* eslint-disable-next-line max-len */}
              <strong>Шифр централизованного фонда</strong> предназначен для консолидации накладных расходов по группе проектов{' '}
              (шифров)
            </p>
            <p>Таблица содержит список шифров Централизованного фонда накладных расходов</p>
            <h3>
              <strong>Особенности отображения информации в столбцах таблицы:</strong>
            </h3>
            <p>
              <strong>Шифр</strong> — Название шифра централизованного фонда
            </p>
            <p>
              <strong>Поступления, план</strong> — общая сумма, запланированная в сметах по статье затрат{' '}
              <strong>Накладные расходы</strong> для шифров проектов. При этом, в смете указано, что передавать накладные на
              конкретный шифр централизованного фонда
            </p>
            <p>
              {/* eslint-disable-next-line max-len */}
              <strong>Поступления, факт</strong> — общая сумма, вычисляемая автоматически при получении из бухгалтерии приходного{' '}
              документа по формуле: сумма документа * % НР, расчётный
            </p>
            <p>
              <strong>% НР, расчётный</strong> — расчётный процент накладных расходов <strong>вычисляется в смете шифра</strong> с
              учётом сумм по статьям затрат, которые имеют атрибут <strong>Уменьшает накладные</strong>
            </p>
            <p>
              <strong>Примечание</strong> — Поясняющий текст для шифра Централизованного фонда
            </p>
            <p>
              <strong>Средний % накладных</strong> — средневзвешенный процент по шифрам проектов
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
