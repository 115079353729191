import React from 'react';

import { FormComponent } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { AffiliatedPublications, NotAffiliatedPublications } from 'features/Form/looks/nirRequest/NirRequestForm/views';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function PublicationsList(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;
  const { settings } = useAppDataContext();
  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <AffiliatedPublications
          title={`Публикации с аффилиацией ${settings?.organization?.shortName} по теме`}
          publications={nirRequest?.publications ?? []}
          setPublications={makeChangeHandler('publications')}
          scientistIDs={(nirRequest?.performers ?? []).map(x => x.person?.id ?? '').filter(Boolean)}
          disabled={disabled}
        />
      </FormComponent.Column>
      <FormComponent.Column>
        <NotAffiliatedPublications
          title={`Публикации без аффилиации с ${settings?.organization?.shortName} по теме`}
          publications={nirRequest?.notAffiliatedDescriptionPublications ?? []}
          setPublications={makeChangeHandler('notAffiliatedDescriptionPublications')}
          disabled={disabled}
        />
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
