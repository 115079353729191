import React, { memo, useCallback, useMemo } from 'react';
import { Table as T, Form as F } from 'types/models';

import {
  Checkbox,
  CustomSelect,
  Select,
  ListEdit,
  Reference,
  Column,
  SectionTitle,
  FormComponent,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { GetOriginalsListSpecification, GetTranslatesListSpecification } from 'features/Table/specifications';

type Props = {
  workMode: T.WorkMode;
  formFields: F.Fields;
  handleFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  publicationOriginal: F.Original | null;
  changeOriginal: (original: F.Original | null) => void;
  changeTranslations: (translations: F.Translation[] | null) => void;
  publicationTranslates: F.Translation[];
  EditionSettings: T.EnumValue[];
  relatedTableState: T.State | undefined;
};

const AboutMagazine = ({
  workMode,
  formFields,
  handleFormSubmit,
  publicationOriginal,
  changeOriginal,
  changeTranslations,
  publicationTranslates,
  EditionSettings,
  relatedTableState,
}: Props) => {
  const renderEntry = useCallback(x => x, []);
  const translatesListTableConfig = GetTranslatesListSpecification({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
    },
  });

  const columns = useMemo<Column<F.Translation>[]>(() => [{ label: '', formatValue: row => row.name }], []);
  const modalTableRowConverter = useCallback<(row: T.Entry) => F.Translation>(
    row => ({
      id: row.id,
      name: row.Name,
    }),
    [],
  );

  const modalTableRowToOriginalConventer = useCallback<(row: T.Entry) => F.Original>(
    row => ({
      id: row.id,
      name: row.Name,
    }),
    [],
  );

  const originalsListTableConfig = GetOriginalsListSpecification({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
    },
  });
  return (
    <form onSubmit={handleFormSubmit}>
      <input type="submit" hidden />
      <SectionTitle title="Сведения о журнале" />

      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.fullName.title} isRequired>
          <TextArea
            settings={{ rows: 3 }}
            value={formFields.fullName.value}
            onChange={formFields.fullName.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.foreignName.title}>
          <TextInput
            value={formFields.foreignName.value}
            onChange={formFields.foreignName.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="">
          <FormComponent.Line hasGap hasFreeFormat>
            <Checkbox
              label={formFields.isOriginal.title}
              checked={!!formFields.isOriginal.value}
              onChange={formFields.isOriginal.onChange}
              isDisabled={workMode === 'viewMode'}
            />
            <Checkbox
              label={formFields.isTranslate.title}
              checked={!!formFields.isTranslate.value}
              onChange={formFields.isTranslate.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Line>
        </FormComponent.Field>
      </FormComponent.Line>

      {Boolean(formFields.isTranslate.value) && (
        <FormComponent.Line>
          <CustomSelect
            isRequired
            title="Журнал-оригинал"
            original={publicationOriginal}
            relationTableModalTitle='Справочник "Журналы"'
            onChange={changeOriginal}
            modalTableRowConverter={modalTableRowToOriginalConventer}
            modalTableSpecification={originalsListTableConfig}
            disabled={workMode === 'viewMode'}
          />
        </FormComponent.Line>
      )}
      {Boolean(formFields.isOriginal.value) && (
        <FormComponent.Line>
          <FormComponent.Field label="">
            <ListEdit
              isRequired
              title="Журналы-переводы"
              rows={publicationTranslates}
              withoutHead
              columns={columns}
              onChange={changeTranslations}
              specification={{
                mode: 'relationTableModal',
                modalTableRowConverter,
                relationTableModalTitle: 'Справочник "Журналы"',
                modalTableSpecification: translatesListTableConfig,
              }}
              isToolbarDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line hasGap hasFreeFormat>
        <FormComponent.Field label={formFields.ISSN.title}>
          <TextInput
            mode={TextInputMode.issn}
            value={formFields.ISSN.value}
            onChange={formFields.ISSN.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.ISSNEl.title}>
          <TextInput
            mode={TextInputMode.issn}
            value={formFields.ISSNEl.value}
            onChange={formFields.ISSNEl.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap hasFreeFormat>
        <FormComponent.Field label={formFields.textLanguages.title}>
          <TextInput
            value={formFields.textLanguages.value}
            onChange={formFields.textLanguages.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.amount.title}>
          <TextInput
            mode={TextInputMode.number}
            value={formFields.amount.value}
            onChange={formFields.amount.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Идентификаторы журнала" />

      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label={formFields.site.title}>
          <TextInput
            mode={TextInputMode.url}
            value={formFields.site.value}
            onChange={formFields.site.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.DOI.title} tooltip={formFields.DOI.tooltipText}>
          <TextInput
            mode={TextInputMode.doi}
            value={formFields.DOI.value}
            onChange={formFields.DOI.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.connection.title}>
          <TextInput
            value={formFields.connection.value}
            onChange={formFields.connection.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <SectionTitle title="Сведения об издании" />
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Вид издания" isRequired={formFields.editionType.required}>
          <Select.Component
            value={formFields.editionType.label}
            onChange={formFields.editionType.onChange}
            entries={EditionSettings.map(e => e.label)}
            renderEntry={renderEntry}
            variant="outlined"
            defaultPlaceholder="Не задан"
            disabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <Reference
          relationTableModalTitle="Издательство"
          name="RefPublisher"
          title={formFields.publisher.title}
          value={formFields.publisher.value}
          theme="big"
          onChange={formFields.publisher.onChange}
          disabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.note.title}>
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: formFields.note.title }}
            value={formFields.note.value}
            onChange={formFields.note.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </form>
  );
};

export const AboutMagazineComponent = memo(AboutMagazine);
