import React, { memo } from 'react';
import { Modal } from 'components';
import { Component } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  articlePublicationIds: string[];
  sourcePublicationId: string;
};

export const Container = memo(({ isOpen, onClose, articlePublicationIds, sourcePublicationId }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Выбор другого издания" size="large">
    <Component onClose={onClose} articlePublicationIds={articlePublicationIds} sourcePublicationId={sourcePublicationId} />
  </Modal>
));
