import { Report } from 'types/models';

export class Reports {
  static Personal: Report = {
    name: 'Personal',
    caption: 'Персональный отчёт по результатам научно-исследовательской деятельности',
  };

  static YearTotal: Report = {
    name: 'YearTotal',
    caption: 'Общие показатели университета по итогам года',
  };

  static DepartmentRecognition: Report = {
    name: 'DepartmentRecognition',
    caption: 'Признание результатов научной деятельности',
  };

  static YearMonograph: Report = {
    name: 'YearMonograph',
    caption: 'Список монографий по отраслям наук',
  };

  static MemorandumAccounting: Report = {
    name: 'MemorandumAccounting',
    caption: 'Служебная записка для бухгалтерии',
  };

  static RequestListIncentivePaymentsPeriod: Report = {
    name: 'RequestListIncentivePaymentsPeriod',
    caption: 'Список заявок на стимулирующие выплаты за период',
  };

  static IncentivePaymentTransferedPatents: Report = {
    name: 'IncentivePaymentTransferedPatents',
    caption: 'Реестр переданных объектов ИС в качестве вклада в уставной капитал МИП',
  };

  static IPIssuePatentRegister: Report = {
    name: 'IPIssuePatentRegister',
    caption: 'Реестр поданных заявок на патенты для стимулирующих выплат',
  };

  static IPPatentRegister: Report = {
    name: 'IPPatentRegister',
    caption: 'Реестр полученных патентов для стимулирующих выплат',
  };

  static DepartmentNTRExpositionsAwards: Report = {
    name: 'DepartmentNTRExpositionsAwards',
    caption: 'Научно-технические разработки, удостоенные медалей, дипломов и специальных наград\n',
  };

  static MemorandumSummary: Report = {
    name: 'MemorandumSummary',
    caption: 'Служебная записка (сводная)',
  };

  static AppToMemorandum: Report = {
    name: 'AppToMemorandum',
    caption: 'Приложение к служебной записке',
  };

  static DepartmentsNominations: Report = {
    name: 'DepartmentsNominations',
    caption: 'Количество номинаций по подразделениям',
  };

  static ConsolidatedStatement: Report = {
    name: 'ConsolidatedStatement',
    caption: 'Суммы по подразделениям',
  };

  static YearExpositionParticipation: Report = {
    name: 'YearExpositionParticipation',
    caption: 'Участие организации в выставках',
  };

  static PublicationList: Report = { name: 'PublicationList', caption: 'Публикации (список)' };

  static ['PublicationForm2.3']: Report = { name: 'PublicationForm2.3', caption: 'Публикации (форма 2.3)' };

  static PublicationExtendedList: Report = { name: 'PublicationExtendedList', caption: 'Список публикаций (расширенный)' };

  static PublicationsByCondition: Report = {
    name: 'PublicationsByCondition',
    caption: 'Публикации авторов по условию (возраст, статус, подразделение)',
  };

  static IncentivePaymentsScientist: Report = {
    name: 'IncentivePaymentsScientist',
    caption: 'Стимулирующие выплаты сотруднику по результатам научной деятельности за период',
  };

  static NirRequestProject: Report = { name: 'NirRequestProject', caption: 'Данные о проекте' };

  static NirRequestLeader: Report = { name: 'NirRequestLeader', caption: 'Данные о руководителе проекта' };

  static NirRequestLeadScientist: Report = { name: 'NirRequestLeadScientist', caption: 'О ведущем учёном' };

  static NirRequestPerformers: Report = { name: 'NirRequestPerformers', caption: 'Данные об основных исполнителях проекта' };

  static NirRequestCollective: Report = {
    name: 'NirRequestCollective',
    caption: 'Основные показатели результативности за 5 лет',
  };

  static NirRequestEstimate: Report = { name: 'NirRequestEstimate', caption: 'Смета заявки' };

  static NirRequestPerformersList: Report = { name: 'NirRequestPerformersList', caption: 'Список участников проекта' };

  static NirRequestMemorandum: Report = {
    name: 'NirRequestMemorandum',
    caption: 'Служебная записка о составе научного коллектива',
  };

  static NirRequestProjectTechnicalTask: Report = {
    name: 'NirRequestProjectTechnicalTask',
    caption: 'Техническое задание на проект по заявке на внутренний конкурс',
  };

  static NirRequestProjectEstimate: Report = { name: 'NirRequestProjectEstimate', caption: 'Смета проекта' };

  static ContestRequest: Report = { name: 'ContestRequest', caption: 'Заявка на продление завершённого проекта' };

  static RequestTender: Report = { name: 'RequestTender', caption: 'Заявка на конкурс' };

  static EstimateRequest: Report = { name: 'EstimateRequest', caption: 'Смета заявки на конкурс' };

  static ProjectIndicators: Report = {
    name: 'ProjectIndicators',
    caption: 'Показатели и индикаторы результативности научного проекта (приложение №1 к заявке)',
  };

  static ContestRequestPerformerCompositionChangeMemo: Report = {
    name: 'ContestRequestPerformerCompositionChangeMemo',
    caption: 'Служебная записка об изменении состава научного коллектива',
  };

  static ContestRequestEstimate: Report = {
    name: 'ContestRequestEstimate',
    caption: 'Смета по этапу проекта для заявки на продление и заявки на актуализацию этапа проекта',
  };

  static ContestRequestPerformerCompositionMemo: Report = {
    name: 'ContestRequestPerformerCompositionMemo',
    caption: 'Служебная записка о составе научного коллектива',
  };

  static ContestRequestTechnicalTask: Report = {
    name: 'ContestRequestTechnicalTask',
    caption: 'Техническое задание на проведение научно-исследовательской работы',
  };

  static ContestRequestProjectIndicators: Report = {
    name: 'ProjectIndicators',
    caption: 'Показатели и индикаторы результативности науччного проекта (приложение №1 к заявке)',
  };

  static ContestRequestProjectResults: Report = {
    name: 'ProjectResults',
    caption: 'Результаты научного проекта (приложение №1 к заявке)',
  };

  static TotalNirRequestsIndicators: Report = {
    name: 'TotalNirRequestsIndicators',
    caption: 'Основные показатели и индикаторы по всем заявкам',
  };

  static PerformersProject: Report = {
    name: 'ProjectPerformersSimple',
    caption: 'Исполнители научного проекта',
  };

  static СompositionScientificProjectPeriod: Report = {
    name: 'СompositionScientificProjectPeriod',
    caption: 'Состав научного коллектива проекта за период',
  };

  static ProjectStagePerformersSimple: Report = {
    name: 'ProjectStagePerformersSimple',
    caption: 'Исполнители этапа научного проекта',
  };

  static ProjectResults: Report = {
    name: 'ProjectResults',
    caption: 'Результаты научного проекта',
  };

  static ProjectAboutChangesOrder: Report = {
    name: 'ProjectAboutChangesOrder',
    caption: 'О внесении изменений в приказ',
  };

  static ProjectIkrbs: Report = {
    name: 'ProjectIkrbs',
    caption: 'Информация для ИКРБС',
  };

  static ProjectResultsDetail: Report = {
    name: 'ProjectResultsDetail',
    caption: 'Подробный отчёт по результатам научного проекта',
  };

  static ProjectPerformersForCitis: Report = {
    name: 'ProjectPerformersForCitis',
    caption: 'Список исполнителей проекта для ЦИТиС',
  };

  static ScienceProjectPerformerCompositionMemo: Report = {
    name: 'ScienceProjectPerformerCompositionMemo',
    caption: 'Служебная записка о состава коллектива (в проекте)',
  };

  static ProjectTechnicalTask: Report = {
    name: 'ProjectTechnicalTask',
    caption: 'Техническое задание на проект',
  };

  static ProjectDocuments: Report = {
    name: 'ProjectDocuments',
    caption: 'Список документов проекта',
  };

  static PartnersContractsAndAgreements: Report = {
    name: 'PartnersContractsAndAgreements',
    caption: 'Реестр договоров и соглашений о сотрудничестве',
  };

  static SecurityDocumentAuthors: Report = { name: 'SecurityDocumentAuthors', caption: 'Авторский коллектив РИД' };

  static ServiceNotePurchase: Report = { name: 'ServiceNotePurchase', caption: 'Служебная записка на закупку' };

  static EventConsolidatedListRequest: Report = {
    name: 'EventConsolidatedListRequest',
    caption: 'Заявка в сводный план на проведение конференции',
  };

  static DepartmentEventConsolidatedListRequest: Report = {
    name: 'DepartmentEventConsolidatedListRequest',
    caption: 'Заявка подразделения в сводный план мероприятий университета',
  };

  static DepartmentConferenceConsolidatedList: Report = {
    name: 'DepartmentConferenceConsolidatedList',
    caption: 'Сводный перечень научных мероприятий',
  };

  static DepartmentConferenceReport: Report = {
    name: 'DepartmentConferenceReport',
    caption: 'Отчет по конференции',
  };

  static DepartmentConference: Report = {
    name: 'DepartmentConference',
    caption: 'Конференции, проведенные на базе организации',
  };

  static TenderReport: Report = {
    name: 'TenderReport',
    caption: 'Отчет по конкурсу (олимпиаде)',
  };

  static ExhibitionReport: Report = {
    name: 'ExhibitionReport',
    caption: 'Отчет по выставке',
  };

  static AvtodissDetail: Report = {
    name: 'AvtodissDetail',
    caption: 'Список авторефератов диссертаций подразделения за период',
  };

  static Dissertations: Report = {
    name: 'Dissertations',
    caption: 'Список диссертаций подразделения за период',
  };

  static Dissertation: Report = {
    name: 'Dissertation',
    caption: 'Справка о диссертациях',
  };

  static Monograph: Report = {
    name: 'Monograph',
    caption: 'Справка о научных монографиях',
  };

  static Publications: Report = {
    name: 'Publications',
    caption: 'Справка по статьям в журналах',
  };

  static PublicationsYoung: Report = {
    name: 'PublicationsYoung',
    caption: 'Публикации молодых ученых, студентов и аспирантов',
  };

  static PersonPubplicationMembers: Report = {
    name: 'PersonPubplicationMembers',
    caption: 'Отчёт для мониторинга публикационной активности',
  };

  static MonographDetail: Report = {
    name: 'MonographDetail',
    caption: 'Список монографий подразделения за период',
  };

  static DepartmentsPublications: Report = {
    name: 'DepartmentsPublications',
    caption: 'Список публикаций за год',
  };

  static WosScopusPublicationByDepartmentsMapping: Report = {
    name: 'WosScopusPublicationByDepartmentsMapping',
    caption: 'Публикации Scopus, WoS по факультетам/институтам',
  };

  static DepartmentContest: Report = {
    name: 'DepartmentContest',
    caption: 'Конкурсы и олимпиады, проведенные на базе организации',
  };

  static DepartmentContestConsolidatedList: Report = {
    name: 'DepartmentContestConsolidatedList',
    caption: 'Сводный перечень конкурсов и олимпиад',
  };

  static DepartmentContestParticipation: Report = {
    name: 'DepartmentContestParticipation',
    caption: 'Участие организации в конкурсах и олимпиадах',
  };

  static DepartmentExposition: Report = {
    name: 'DepartmentExposition',
    caption: 'Выставки, проведенные на базе организации',
  };

  static TotalDepartmentReportAll: Report = {
    name: 'TotalDepartmentReportAll',
    caption: 'Подробный отчет подразделения по итогам года',
  };

  static DepartmentExpositionParticipation: Report = {
    name: 'DepartmentExpositionParticipation',
    caption: 'Участие организации в выставках',
  };

  static DepartmentExpositionConsolidatedList: Report = {
    name: 'DepartmentExpositionConsolidatedList',
    caption: 'Сводный перечень выставок',
  };

  static AllExpositionsAwards: Report = {
    name: 'AllExpositionsAwards',
    caption: 'Художественные экспонаты и научно-технические разработки, удостоенные медалей, дипломов и специальных наград',
  };

  static DepartmentConcertParticipation: Report = {
    name: 'DepartmentConcertParticipation',
    caption: 'Участие организации в творческих мероприятиях',
  };

  static DepartmentConcertConsolidatedList: Report = {
    name: 'DepartmentConcertConsolidatedList',
    caption: 'Сводный перечень творческих мероприятий',
  };

  static DepartmentConferenceParticipation: Report = {
    name: 'DepartmentConferenceParticipation',
    caption: 'Участие организации в конференциях',
  };

  static ConferenceMemberCounts: Report = {
    name: 'ConferenceMemberCounts',
    caption: `Участники конференций сводного плана Университета за период`,
  };

  static NruP2Anketa: Report = {
    name: 'NruP2Anketa',
    caption: 'Анкета НРУ П2. Участие представителей Университета в международных и российских конференциях',
  };

  static EGISUParticipations: Report = {
    name: 'EGISUParticipations',
    caption: 'Список докладов по проекту',
  };

  static YearConference: Report = {
    name: 'YearConference',
    caption: 'Конференции, проведенные на базе организации',
  };

  static DepartmentContestAwards: Report = {
    name: 'DepartmentContestAwards',
    caption: 'Награды, полученные в конкурсных мероприятиях',
  };

  static DepartmentConcert: Report = {
    name: 'DepartmentConcert',
    caption: 'Творческие мероприятия, проведенные на базе организации',
  };

  static MobileRequest: Report = {
    name: 'MobileRequest',
    caption: 'Заявка по академической мобильности',
  };

  static MobileRequestAnalytic: Report = {
    name: 'MobileRequestAnalytic',
    caption: 'Информационно-аналитический отчёт по итогам командировки',
  };

  static MobileRequestMoneyOrder: Report = {
    name: 'MobileRequestMoneyOrder',
    caption: 'Приказ на командировку по данным заявки, допущенной на конкурс (заявление на деньги)',
  };

  static MobileRequestOrderOtherAppointment: Report = {
    name: 'MobileRequestOrderOtherAppointment',
    caption: 'Приказ на командировку по данным заявки, допущенной на конкурс (другая должность)',
  };

  static MobileRequestTask: Report = {
    name: 'MobileRequestTask',
    caption: 'Служебное задание по данным заявки, допущенной на конкурс (для сотрудников)',
  };

  static MobileRequestTaskOtherAppointment: Report = {
    name: 'MobileRequestTaskOtherAppointment',
    caption: 'Служебное задание на командировку',
  };

  static MobileRequestEstimate: Report = {
    name: 'MobileRequestEstimate',
    caption: 'Смета по данным заявки, допущенной на конкурс',
  };

  static MobileRequestOrderProject: Report = {
    name: 'MobileRequestOrderProject',
    caption: 'Проект приказа на стажировку/для участия в мероприятии по данным заявки, допущенной на конкурс',
  };

  static MobileRequestEducationOrderProject: Report = {
    name: 'MobileRequestEducationOrderProject',
    caption: 'Проект приказа по данным заявки, допущенной на конкурс (обучающиеся)',
  };

  static ProjectAct: Report = {
    name: 'ProjectAct',
    caption: 'АКТ приёмки НИР за период(этап)',
  };

  static MobileProtocol: Report = {
    name: 'MobileProtocol',
    caption: 'Протокол',
  };

  static TenderProtocol: Report = {
    name: 'TenderProtocol',
    caption: 'Протокол',
  };

  static NirRequestAll: Report = {
    name: 'NirRequestAll',
    caption: 'Заявка',
  };

  static NirRequestTotalEstimate: Report = {
    name: 'NirRequestTotalEstimate',
    caption: 'Консолидированная смета по заявкам на внутренний конкурс (Приоритет 2030)',
  };

  static IncomingFundsNiokr: Report = {
    name: 'IncomingFundsNiokr',
    caption: 'План-график поступления средств на НИР и ОКР на год (помесячный)',
  };

  static IncomingFundsTotalFact: Report = {
    name: 'IncomingFundsTotalFact',
    caption: 'Выполнение план-графика поступления средств на НИР и ОКР (суммарный факт за период)',
  };

  static ByDepartmentShort: Report = {
    name: 'ByDepartmentShort',
    caption: 'Отчет по подразделениям (доходы и расходы за год)',
  };

  static ByDepartmentFull: Report = {
    name: 'ByDepartmentFull',
    caption: 'Отчет по подразделениям (доходы и расходы в разрезе кодов КОСГУ за год)',
  };

  static ProjectEstimateByCodeForViu: Report = {
    name: 'ProjectEstimateByCodeForViu',
    caption: 'Смета расходов проекта',
  };

  static ProjectPerformers: Report = {
    name: 'ProjectPerformers',
    caption: 'Контроль списков исполнителей проектов',
  };

  static OrderAboutCreationResearchTeam: Report = {
    name: 'OrderAboutCreationResearchTeam',
    caption: 'Приказ о создании научного коллектива',
  };
}
