import { useState, useEffect, useMemo } from 'react';
import * as XML from 'xml-js';
import * as BackendAPI from 'services/BackendAPI';
import { useStream } from 'StreamRx';

import { Table as T, Form as F } from 'types/models';
import { TemplatesTableDependencies } from 'types/models/Table';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams as panelStreams } from '../streams';
import { CitationSystemType } from '../types';
import { showNotification } from 'features/Notifications';
import { getErrorMessage, isErrorResponse } from 'services/BackendAPI/helpers';

export type State = {
  isChangeFormOpen: boolean;
  isSettingEditing: boolean;
  selectedRow: Record<string, string> | undefined;
  publicationCitationSystem: XML.ElementCompact | undefined;
  workMode?: T.WorkMode;
  domain: string;
  publicationType: string;
  citationSystemType: CitationSystemType;
  relatedRecordId: string;
  relatedTableSelectedRow: T.Entry;
  relatedTableApiId: BackendAPI.TS.TableDataAPIID;
};

export function makeUseCustomController(
  templatesTableDependencies: TemplatesTableDependencies,
  domain: string,
  publicationType: string,
  citationSystemType: CitationSystemType,
  addCitationSystem: (citationSystem: F.CitationSystem) => void,
  removeCitationSystem: (citationSystemId: string) => void,
  editCitationSystem: (citationSystem: F.CitationSystem) => void,
  workMode?: T.WorkMode,
  setTopMessage?: (topMessage: string) => void,
) {
  return function useCustomController({ selectedRows, dataCallStateKind, entryPages }: T.UseCustomControllerProps): State {
    const tableStreams = useLocalTableStreams();
    const [isChangeFormOpen, setIsChangeFormOpen] = useState(false);
    const [isSettingEditing, setIsSettingEditing] = useState(false);
    const [publicationCitationSystem, setPublicationCitationSystem] = useState<XML.ElementCompact | undefined>(undefined);

    const selectedRow = useMemo(() => selectedRows[0], [selectedRows]);

    const { methods: savePublicationCitationSystemAPIMethods } = BackendAPI.useBackendAPI('SavePublicationCitationSystem', {
      onSuccessfullCall: data => {
        const { data: response } = data;
        if (isErrorResponse(response)) {
          showNotification({ message: getErrorMessage(response), theme: 'danger' });
          panelStreams.toggleResetNeeded.push(false);
          return;
        }

        showNotification({ message: 'Запись успешно сохранена', theme: 'success' });
        const isAddMode = !data.input.publicationCitationSystemID;
        if (isAddMode) {
          addCitationSystem({
            ...data.input.citationSystem,
            publicationCitationSystemId: data.data.Response?.Id?._text || '',
            thematicCategories: data.input.thematicCategories.map(({ value, name }) => ({ id: value.id, label: name })),
          });
        } else {
          editCitationSystem({
            ...data.input.citationSystem,
            publicationCitationSystemId: data.data.Response?.Id?._text || '',
            thematicCategories: data.input.thematicCategories.map(({ value, name }) => ({ id: value.id, label: name })),
          });
        }
        panelStreams.toggleResetNeeded.push(true);
        setPublicationCitationSystem(undefined);
        tableStreams.reloadTable.push();
      },
    });

    const { methods: saveAndContinuePublicationCitationSystemAPIMethods } = BackendAPI.useBackendAPI(
      'SavePublicationCitationSystem',
      {
        onSuccessfullCall: data => {
          const { data: response } = data;
          if (isErrorResponse(response)) {
            showNotification({ message: getErrorMessage(response), theme: 'danger' });
            panelStreams.toggleResetNeeded.push(false);
            return;
          }

          showNotification({ message: 'Запись успешно сохранена', theme: 'success' });
          const isAddMode = !data.input.publicationCitationSystemID;
          if (isAddMode) {
            addCitationSystem({
              ...data.input.citationSystem,
              publicationCitationSystemId: data.data.Response?.Id?._text || '',
              thematicCategories: data.input.thematicCategories.map(({ value, name }) => ({ id: value.id, label: name })),
            });
          } else {
            editCitationSystem({
              ...data.input.citationSystem,
              publicationCitationSystemId: data.data.Response?.Id?._text || '',
              thematicCategories: data.input.thematicCategories.map(({ value, name }) => ({ id: value.id, label: name })),
            });
          }
          getPublicationCitationSystemAPIMethods.callAPI({ publicationCitationSystemID: data.data.Response.Id._text });
          panelStreams.toggleSettingEditing.push(true);
          tableStreams.reloadTable.push();
        },
      },
    );

    const { methods: deletePublicationCitationSystemAPIMethods } = BackendAPI.useBackendAPI('DeletePublicationCitationSystem', {
      onSuccessfullCall: data => {
        showNotification({ message: 'Запись успешно удалена', theme: 'success' });
        removeCitationSystem(data.input.publicationCitationSystemID);
        tableStreams.reloadTable.push();
      },
    });

    const { methods: getPublicationCitationSystemAPIMethods } = BackendAPI.useBackendAPI('GetPublicationCitationSystem', {
      onSuccessfullCall: data => {
        setPublicationCitationSystem(data.data.Response.PublicationCitationSystem);
      },
    });

    useStream(
      () => panelStreams.savePublicationCitationSystem,
      ({
        publicationID,
        publicationCitationSystemID,
        citationSystem,
        identifier,
        url,
        name,
        sourceType,
        periods,
        thematicCategories,
        keyWords,
      }) => {
        savePublicationCitationSystemAPIMethods.callAPI({
          publicationID,
          publicationCitationSystemID,
          citationSystem,
          identifier,
          url,
          name,
          sourceType,
          periods,
          thematicCategories,
          keyWords,
          citationSystemType,
        });
      },
      [],
    );

    useStream(
      () => panelStreams.saveAndContinuePublicationCitationSystem,
      ({
        publicationID,
        publicationCitationSystemID,
        citationSystem,
        identifier,
        url,
        name,
        sourceType,
        periods,
        thematicCategories,
        keyWords,
      }) => {
        saveAndContinuePublicationCitationSystemAPIMethods.callAPI({
          publicationID,
          publicationCitationSystemID,
          citationSystem,
          identifier,
          url,
          name,
          sourceType,
          periods,
          thematicCategories,
          keyWords,
          citationSystemType,
        });
      },
      [],
    );

    useStream(
      () => panelStreams.deletePublicationCitationSystem,
      ({ publicationCitationSystemID }) => {
        deletePublicationCitationSystemAPIMethods.callAPI({
          publicationCitationSystemID,
        });
      },
      [],
    );

    useStream(
      () => panelStreams.getPublicationCitationSystem,
      ({ publicationCitationSystemID, sourceID }) => {
        getPublicationCitationSystemAPIMethods.callAPI({
          publicationCitationSystemID,
          sourceID,
        });
      },
      [],
    );

    useStream(
      () => panelStreams.toggleChangeForm,
      () => {
        setIsChangeFormOpen(prev => !prev);
        tableStreams.toggleSecondLevelPanel.push();
      },
      [setIsChangeFormOpen],
    );

    useStream(
      () => panelStreams.toggleSettingEditing,
      isEditing => setIsSettingEditing(isEditing),
      [setIsSettingEditing],
    );

    function isCitationSystemListContainsIds(list: any): boolean {
      let result: boolean = false;
      if (list)
        list.forEach((element: any) => {
          if (element?.id) result = true;
        });
      return result;
    }

    useEffect(() => {
      if (
        dataCallStateKind === 'successfull' &&
        setTopMessage &&
        (citationSystemType === 'CHILD_GRID' || citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code)
      )
        if (entryPages) {
          if (!entryPages.length) {
            if (citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code)
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у журнала нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация (журнал)',
              );
            else if (publicationType === 'MONOGRAPH_CHAPTER') {
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у монографии нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация',
              );
            } else if (publicationType === 'ARTICLE_OTHER') {
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у издания нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация',
              );
            } else
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у сборника нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация',
              );
          } else if (!isCitationSystemListContainsIds(entryPages))
            setTopMessage(
              // eslint-disable-next-line max-len
              'Невозможно добавить значение наукометрических показателей для публикации без Идентификатора БЦ. Добавьте сведения о публикации из базы цитирования: Идентификатор в БЦ, Тип публикации и Индексируемые ключевые слова в таблицу "Индексация в базах цитирования" с помощью кнопки "Редактировать"',
            );
          else setTopMessage('');
        } else setTopMessage('');
    }, [dataCallStateKind, entryPages]);

    return {
      isChangeFormOpen,
      isSettingEditing,
      selectedRow,
      publicationCitationSystem,
      workMode,
      domain,
      publicationType,
      citationSystemType,
      relatedRecordId:
        templatesTableDependencies.relatedRecordId || templatesTableDependencies.relatedTableState.selectedRows[0]?.id || '-1',
      relatedTableSelectedRow: templatesTableDependencies.relatedTableState.selectedRows[0],
      relatedTableApiId: templatesTableDependencies.relatedTableAPIID,
    };
  };
}
