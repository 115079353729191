export class PrintFormat {
  static XLSX = {
    id: 'xlsx',
    label: 'XLSX',
  };

  static DOCX = {
    id: 'docx',
    label: 'DOCX',
  };

  static RTF = {
    id: 'rtf',
    label: 'RTF',
  };

  static PDF = {
    id: 'pdf',
    label: 'PDF',
  };

  static ODT = {
    id: 'odt',
    label: 'ODT',
  };

  static ODS = {
    id: 'ods',
    label: 'ODS',
  };
}
