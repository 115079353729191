import { useCallback, useMemo, useState } from 'react';

import { useLocalTableStreams } from 'features/Table/hooks';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

export function useController({ tableState, onSubmitTable }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const handleSelectButtonClick = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable(tableState);
    } else {
      tableStreams.submitTable.push(tableState);
    }
  }, [tableStreams, onSubmitTable, tableState]);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.check,
        title: 'Выбрать',
        code: 'select',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: handleSelectButtonClick,
      },
    ],
    [rowsAvailability, handleSelectButtonClick],
  );

  return {
    buttons,
    isEditFormOpen,
    isEditButtonDisabled,
    handleTemplateCloseEditForm,
    nextPublicationName,
    setNextPublicationName,
  };
}
