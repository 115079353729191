import React, { memo, useMemo } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  id: string;
  relatedTableId?: string | null;
  eventId?: string;
  isDisableEventSource?: boolean;
};

const MagazineReleaseAddTemplate = ({ isOpen, onClose, id, relatedTableId = null, eventId, isDisableEventSource }: Props) => {
  const look: F.Look = useMemo(
    () => ({
      id,
      apiID: 'GetMagazineRelease',
      template: 'MagazineReleaseForm',
      type: 'Magazine',
      editMode: true,
      relatedTableId,
      eventId,
      isDisableEventSource,
    }),
    [id, relatedTableId, eventId, isDisableEventSource],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактирование выпуска" size="medium">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = memo(MagazineReleaseAddTemplate);
