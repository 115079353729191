import { DetailProps } from '.';

export type State = {
  isSearchMode: boolean;
};

export function makeUseCustomController({ isSearchMode = false }: DetailProps) {
  return function useCustomController(): State {
    return {
      isSearchMode,
    };
  };
}
