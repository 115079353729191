import React from 'react';
import { setup } from 'bem-cn';

import { RequiredBadge, Tooltip } from 'components';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('section-title');

type Props = {
  title?: string;
  tooltip?: string;
  isRequired?: boolean;
  isTableHeader?: boolean;
  children?: JSX.Element | JSX.Element[];
};

/**
 * Title component (styled by default)
 *
 * @param title - (optional) Text for render
 * @param tooltip - (optional) Add badge with tooltip text
 * @param isRequired - (optional) Add badge `isRequired` (red star)
 * @param isTableHeader - (optional) Set simple visible title
 * @returns JSX
 */
export const SectionTitle = React.memo(({ title, tooltip, isRequired, children, isTableHeader = false }: Props) => {
  return (
    <h3 className={b({ table: isTableHeader })}>
      <span className={b('inner')}>
        {children || title} {isRequired && <RequiredBadge />}
      </span>
      {tooltip && <Tooltip text={tooltip} />}
    </h3>
  );
});
