import { CompilationForm, CompilationArticle } from './compilation';
import { PublicationCitationSystem } from './citationSystem';
import {
  ParticipationFormConference,
  ParticipationFormContest,
  ParticipationFormExposition,
  ParticipationFormConcert,
} from './participation';
import { ScientistDataForm, ScientistHirshIndexForm } from './scientist';
import { LibraryIdForm } from './libraryId';
import { HandleErrorForm } from './common';
import { ProtocolCommissionForm } from './protocolCommission';
import { RecognitionApproveConfirm, RecognitionDepartments, RecognitionForm } from './recognition';
import { EventFormConcert, EventFormConference, EventFormContest, EventFormExposition, ConferenceDataForm } from './event';
import {
  MagazineArticle,
  MagazineIdentifierForm,
  MagazineRelease,
  MagazineReleaseArticles,
  MagazineForm,
  MagazineReleaseForm,
} from './magazine';
import {
  KindConsumptionForm,
  MobileKindConsumptionForm,
  NirRequestKindConsumptionForm,
  RequestKindConsumptionForm,
  ContestRequestKindConsumptionForm,
} from './kindConsumption';
import {
  PublicationFormArticle,
  PublicationFormOther,
  PublicationFormDissertation,
  PublicationFormMonography,
  PublicationFormMonographyArticle,
  PublicationFormOtherArticle,
  PublicationFormTextbook,
  PublicationThesis,
  CompilationFormArticle,
  PreprintFormArticle,
  ScientificIndexForm,
} from './publication';
import { ExponentForm } from './exponent';
import { UserEditForm, UserPermissionForm } from './user';
import { QnaAnswerForm, QnaQuestionForm, QnaQuestionEditForm, QnaFrequentForm } from './qna';
import {
  PatentForm,
  SecurityDocumentPaymentForm,
  SecurityDocumentPaymentRequestForm,
  ForeignSecurityDocumentForm,
} from './securityDocumentPatent';
import { PaymentTermPresetForm } from './payment';
import { ProgramForm, TenderForm, ProgramEventForm, TenderProtocolForm } from './tender';
import { PersonForm } from './person';
import { PersonAddForm } from './personAdd';
import { ProjectCodeForm } from './projectCode';
import { ProgramRequestForm } from './programRequest';
import { EstimateForm, EstimatePositionForm } from './estimate';
import { CommunityForm } from './community';
import { ContestRequestForm } from './contestRequest';
import { PatentResearchForm } from './patentResearch';
import { SecurityDocumentContractForm } from './securityDocumentContract';
import { ProjectStateRegistrationReportForm, ProjectStateRegistrationReportAddForm } from './projectStateRegistrationReport';
import { CommunityMemberForm } from './communityMember';
import { RoleForm } from './role';
import * as OtherProjectForm from './otherProject';
import { SubjectForm } from './subject';
import { RntdForm } from './rntd';
import { JointEventForm } from './jointEvent';
import { ProjectAssignmentForm, ProjectAssignmentMessageForm, PurchaseRequestForm, ProjectPerformerOrderForm } from './project';
import { MobileRequestForm } from './mobileRequest';
import { NirRequestForm } from './nirRequest';
import { FundCardForm } from './fundCard';
import { MipIndicatorForm } from './mip';

export default {
  OtherProjectForm,
  CommunityForm,
  CommunityMemberForm,
  CompilationForm,
  CompilationArticle,
  CompilationFormArticle,
  ConferenceDataForm,
  ContestRequestForm,
  EstimateForm,
  EstimatePositionForm,
  EventFormConcert,
  EventFormConference,
  EventFormContest,
  EventFormExposition,
  ExponentForm,
  HandleErrorForm,
  MagazineArticle,
  MagazineForm,
  MagazineIdentifierForm,
  MagazineRelease,
  MagazineReleaseArticles,
  MagazineReleaseForm,
  ProtocolCommissionForm,
  ParticipationFormConcert,
  ParticipationFormConference,
  ParticipationFormContest,
  ParticipationFormExposition,
  PatentForm,
  PatentResearchForm,
  SecurityDocumentPaymentForm,
  SecurityDocumentPaymentRequestForm,
  ForeignSecurityDocumentForm,
  PaymentTermPresetForm,
  PersonAddForm,
  PersonForm,
  PreprintFormArticle,
  ProgramEventForm,
  ProgramForm,
  TenderForm,
  TenderProtocolForm,
  ProjectAssignmentForm,
  ProjectAssignmentMessageForm,
  ProjectCodeForm,
  PurchaseRequestForm,
  ProjectPerformerOrderForm,
  ProjectStateRegistrationReportAddForm,
  ProjectStateRegistrationReportForm,
  PublicationCitationSystem,
  PublicationFormArticle,
  PublicationFormDissertation,
  PublicationFormMonography,
  PublicationFormMonographyArticle,
  PublicationFormOther,
  PublicationFormOtherArticle,
  PublicationFormTextbook,
  PublicationThesis,
  QnaAnswerForm,
  QnaFrequentForm,
  QnaQuestionEditForm,
  QnaQuestionForm,
  RecognitionApproveConfirm,
  RecognitionDepartments,
  RecognitionForm,
  ProgramRequestForm,
  RntdForm,
  ScientificIndexForm,
  ScientistDataForm,
  ScientistHirshIndexForm,
  SecurityDocumentContractForm,
  UserEditForm,
  UserPermissionForm,
  RoleForm,
  SubjectForm,
  JointEventForm,
  MobileRequestForm,
  KindConsumptionForm,
  MobileKindConsumptionForm,
  NirRequestKindConsumptionForm,
  RequestKindConsumptionForm,
  ContestRequestKindConsumptionForm,
  LibraryIdForm,
  NirRequestForm,
  FundCardForm,
  MipIndicatorForm,
};
