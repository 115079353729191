import { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Form } from 'types/models';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { useFormContext } from 'features/Form/hooks';
import { PublicationHeader } from 'features/Form/views/ModalHeader';
import { useAppDataContext } from 'features/AppData/context';
import { ModePublicationHeader } from 'features/Form/views/ModalHeader/publication';

export function useController() {
  const { settings } = useAppDataContext();
  const [publicationId, setPublicationId] = useState<string>('');
  const [publicationFields, setPublicationFields] = useState<Form.DataStatusInfo>({});
  const [publicationType, setPublicationType] = useState('');
  const [publicationCitationSystems, setPublicationCitationSystems] = useState<Form.CitationSystem[]>([]);
  const [publicationTranslates, setPublicationTranslates] = useState<Form.Translation[]>([]);
  const [publicationOriginal, setPublicationOriginal] = useState<Form.Original | null>(null);
  const [publicationLoaded, setPublicationLoaded] = useState(false);
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const {
    look: { id, relatedTableState, arm, setTitle },
  } = useFormContext();

  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication', {
    onSuccessfullCall: ({ data }) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setPublicationId(data.status.id || '');
        setPublicationFields(data.fields);
        setPublicationType(data.type || '');
        setPublicationTranslates(data.translations);
        setPublicationOriginal(data.original);
        setPublicationLoaded(true);
        setPublicationCitationSystems(data.citationSystems);
        setPublicationInfo(data);
      });
    },
  });

  useEffect(() => {
    getPublication.callAPI({
      simpleFields: {
        status: 'DRAFT',
        typeEdition: 'LOCAL',
      },
      arrayFields: {
        translations: { translation: [] },
        events: { event: [] },
      },
      attrIdFields: {},
      projects: [],
      mobileRequests: {},
      id,
      arm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const addCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        const citationSystemIndex = publicationInfo.citationSystems.findIndex(
          citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
        );
        const isNewCitationSystem = citationSystemIndex === -1;

        if (isNewCitationSystem) {
          setPublicationInfo(prevState => ({
            ...prevState!,
            citationSystems: [...prevState!.citationSystems, citationSystem],
          }));
          setPublicationCitationSystems(prevState => [...prevState, citationSystem]);
        } else {
          setPublicationInfo(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState!.citationSystems));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return {
              ...prevState!,
              citationSystems: nextCitationSystems,
            };
          });
          setPublicationCitationSystems(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return nextCitationSystems;
          });
        }
      }
    },
    [publicationInfo],
  );

  const editCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      const citationSystemToUpdateIndex = (publicationInfo?.citationSystems || []).findIndex(
        citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
      );

      if (citationSystemToUpdateIndex !== -1) {
        const clonedCitationSystems: Form.CitationSystem[] = publicationInfo?.citationSystems
          ? R.clone(publicationInfo.citationSystems)
          : [];
        clonedCitationSystems.splice(citationSystemToUpdateIndex, 1, citationSystem);

        setPublicationInfo(prevState => prevState && { ...prevState!, citationSystems: clonedCitationSystems });
        setPublicationCitationSystems(clonedCitationSystems);
      }
    },
    [publicationInfo?.citationSystems],
  );

  const removeCitationSystem = useCallback(
    (citationSystemId: string) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        setPublicationInfo(prevState => ({
          ...prevState!,
          citationSystems: [
            ...prevState!.citationSystems.filter(
              (system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId,
            ),
          ],
        }));
        setPublicationCitationSystems(prevState => [
          ...prevState!.filter((system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId),
        ]);
      }
    },
    [publicationInfo],
  );

  const sourceId = useMemo(() => {
    const isMonographChapter = [PublicationTypes.MONOGRAPH_CHAPTER.code].some(typeCode => publicationInfo?.type === typeCode);

    const isMagazineArticle = [PublicationTypes.MAGAZINE_ARTICLE.code, PublicationTypes.PREPRINT_ARTICLE.code].some(
      typeCode => publicationInfo?.type === typeCode,
    );

    const isCompilationArticle = [
      PublicationTypes.THESIS.code,
      PublicationTypes.ARTICLE_SCIENTIFIC_WORK.code,
      PublicationTypes.ARTICLE_CONFERENCE.code,
      PublicationTypes.ARTICLE_OTHER.code,
      PublicationTypes.ARTICLE_ARTICLES.code,
    ].some(typeCode => publicationInfo?.type === typeCode);

    const isOtherPart = [PublicationTypes.OTHER_PART.code].some(typeCode => publicationInfo?.type === typeCode);

    if (isMonographChapter) {
      return publicationInfo?.monograph?.id;
    }

    if (isMagazineArticle) {
      return publicationInfo?.magazine?.id;
    }

    if (isCompilationArticle) {
      return publicationInfo?.compilation?.id;
    }

    if (isOtherPart) {
      return publicationInfo?.publicationOther?.id;
    }
  }, [
    publicationInfo?.compilation?.id,
    publicationInfo?.magazine?.id,
    publicationInfo?.monograph?.id,
    publicationInfo?.publicationOther?.id,
    publicationInfo?.type,
  ]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        PublicationHeader({
          settings,
          mode: ModePublicationHeader.citation,
          workMode: 'editMode',
          publicationId: publicationInfo?.status.id || '',
          publicationName: publicationInfo?.fields.name || '',
          publicationTypeCode: publicationInfo?.type || '',
          publicationElectronicTypeCode:
            publicationInfo?.fields.isElectronic === 'true' ? publicationInfo?.fields.electronicType || '' : '',
          departments: publicationInfo?.departments || [],
          status: publicationInfo?.status.text || '',
          publicationTypeEdition: {
            id: publicationInfo?.fields.typeEditionCode || '',
            label: publicationInfo?.fields.typeEditionText || '',
          },
          isPreprint: !!publicationInfo?.isPreprint,
        }),
      );
    }
  }, [
    publicationInfo?.departments,
    publicationInfo?.fields.electronicType,
    publicationInfo?.fields.isElectronic,
    publicationInfo?.fields.name,
    publicationInfo?.fields.typeEditionCode,
    publicationInfo?.fields.typeEditionText,
    publicationInfo?.isPreprint,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
  ]);

  return {
    state: {
      publicationFields,
      publicationType,
      publicationLoaded,
      publicationTranslates,
      publicationOriginal,
      changeTranslations: setPublicationTranslates,
      changeOriginal: setPublicationOriginal,
      relatedTableState,
      publicationCitationSystems,
      setPublicationCitationSystems,
      publicationId: publicationId || null,
      addCitationSystem,
      removeCitationSystem,
      editCitationSystem,
      publicationInfo,
      sourceId,
    },
  };
}
