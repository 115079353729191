import React from 'react';

import { Item } from 'types/models/common';

import { TextGridSelect } from 'components';

import { GetProgramEventList } from 'features/Table/specifications';

type Props = {
  programEvent: Item | null;
  programId: string | null;
  onChange(programEvent: Item | null): void;
  disabled: boolean;
};

export function SelectProgramEvent(props: Props) {
  const { programEvent, onChange, disabled, programId } = props;

  return (
    <TextGridSelect<Item, any>
      isTextarea
      title="Мероприятия"
      value={programEvent}
      onChange={onChange}
      disabled={disabled}
      specification={GetProgramEventList({ programId: programId || '-1', hasSelectButton: true })}
      valueFormatter={x => x.label}
      settings={{ mode: 'selectTableRow', tableRowConverter: row => ({ label: row.CodeName, value: row.id }) }}
    />
  );
}
