import React, { useCallback, useMemo } from 'react';
import { buttonIcons, FormComponent, ListEdit, SectionTitle } from 'components';
import { MipPartner } from 'types/models';
import { getMockFounder, getPercentOfAmountByPart } from 'features/EditMipPartner/helpers';
import { downloadFile, formatNumber, getAuthToken } from 'utils/Helpers';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { Fields } from '../Fields/Fields';

type Props = {
  charterCapitals: MipPartner.CharterCapital[];
  selectedRowIndex: number | null;
};

export const Founders = ({ charterCapitals, selectedRowIndex }: Props) => {
  const token = getAuthToken();

  const currentCharterCapital = useMemo<MipPartner.CharterCapital | null>(
    () => (selectedRowIndex !== null ? charterCapitals[selectedRowIndex] : null),
    [charterCapitals, selectedRowIndex],
  );
  const founders = useMemo<MipPartner.Founder[]>(() => (currentCharterCapital ? currentCharterCapital.founders : []), [
    currentCharterCapital,
  ]);

  const amount = useMemo<string>(() => (currentCharterCapital ? currentCharterCapital.amount : '0.00'), [currentCharterCapital]);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<MipPartner.Founder>[]>(
    () => [
      {
        icons: buttonIcons.download,
        checkIsDisabled: founder => !founder?.file?.id,
        onClick: founder => downloadFile(founder?.file?.id, token),
      },
    ],
    [token],
  );

  const formatFounder = useCallback((p: MipPartner.Founder) => {
    return p.scientist
      ? `${p.scientist.fullName} (${p.passport})`
      : // eslint-disable-next-line max-len
        `${p.enterpriseCustomer?.customFields?.fullName} (${p.enterpriseCustomer?.label}), ИНН: ${p.enterpriseCustomer?.customFields?.inn}, КПП: ${p.enterpriseCustomer?.customFields?.kpp}`;
  }, []);

  return (
    <>
      <SectionTitle title="Учредители" />
      <ListEdit
        rows={founders}
        visibleToolbarButtons={['view']}
        extraToolbarButtons={extraToolbarButtons}
        onChange={() => {}}
        defaultRowsCount={5}
        maxHeight="none"
        columns={[
          {
            label: 'ФИО/Название организации',
            formatValue: formatFounder,
            styles: { width: '40%' },
          },
          {
            label: 'Доля в УК, %',
            formatValue: x => getPercentOfAmountByPart({ part: x.amount, amount }),
            styles: { width: '15%' },
          },
          {
            label: 'Сумма',
            formatValue: x => formatNumber(x.amount),
            styles: { width: '15%' },
          },
          {
            label: 'Активы',
            formatValue: x => x.insertionAsserts ?? '',
            styles: { width: '30%' },
          },
        ]}
        columnIndexesForSumTotal={[1, 2]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Учредитель',
          isToolbarHidden: true,
          renderComponent: (founder, setFounder) => (
            <FormComponent.Wrapper>
              <FormComponent.Template>
                <Fields founder={founder ?? getMockFounder()} setFounder={setFounder} amount={amount} isDisabled />
              </FormComponent.Template>
            </FormComponent.Wrapper>
          ),
        }}
      />
    </>
  );
};
