import React, { useMemo } from 'react';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { isViewButtonDisabled, isViewFormOpen, setIsViewFormOpen, buttons } = useController({
    tableState,
  });
  const row = useMemo(() => {
    return tableState?.selectedRows ? tableState.selectedRows[0] : null;
  }, [tableState]);
  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.SecurityDocumentViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={row?.id}
          relatedTableState={tableState}
          entityType={row?.Type || ''}
          documentType={row ? row['id:SecrityDocumentType'] : ''}
        />
      )}
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
