import React, { memo, useCallback } from 'react';

import { Content, Tabs, Tab, Select, FormComponent } from 'components';

import useController from './controller';
import * as ReportTabs from './Tabs';

const Reports = () => {
  const {
    documents,
    groups,
    currentGroup,
    isBuildReportPopupOpen,
    setupReportsColumns,
    setupReportsToolbarButtons,
    setupReportsSelectedRowIndex,
    closeBuildReportPopup,
    setupReportsSetSelectedRowIndex,
    setDocuments,
    saveReportDocument,
    editReportDocument,
    deleteReportDocument,
    updateCurrentGroupId,
    onDoubleRowClick,
  } = useController();

  const renderEntry = useCallback(x => x, []);

  return (
    <Content>
      <h2>Отчеты</h2>
      <FormComponent.Template>
        <FormComponent.Line>
          <FormComponent.Field>
            <FormComponent.ColumnWrapper>
              <FormComponent.Column specialStyles={{ width: '50%' }}>
                <FormComponent.Line>
                  <FormComponent.Field label="Группы отчетов">
                    <Select.Component
                      value={currentGroup?.caption || ''}
                      variant="outlined"
                      title="Группы"
                      defaultPlaceholder="Не задан"
                      entries={groups.map(group => group.caption)}
                      onChange={updateCurrentGroupId}
                      renderEntry={renderEntry}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Template>
          <Tabs id="reports">
            <Tab title="Формирование отчетов">
              <ReportTabs.SetupReport
                reports={currentGroup?.list || []}
                isBuildReportPopupOpen={isBuildReportPopupOpen}
                columns={setupReportsColumns}
                toolbarButtons={setupReportsToolbarButtons}
                selectedRowIndex={setupReportsSelectedRowIndex}
                setSelectedRowIndex={setupReportsSetSelectedRowIndex}
                closeBuildReportPopup={closeBuildReportPopup}
                onDoubleRowClick={onDoubleRowClick}
              />
            </Tab>
            <Tab title="Готовые отчеты">
              <ReportTabs.ReadyReports
                documents={documents}
                setDocuments={setDocuments}
                addCallback={saveReportDocument}
                editCallback={editReportDocument}
                deleteCallback={deleteReportDocument}
              />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>
    </Content>
  );
};

export const Component = memo(Reports);
