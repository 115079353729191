import { useCallback, useLayoutEffect, useState, useMemo } from 'react';
import * as R from 'ramda';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Document, Rid, Form } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { useAppDataContext } from 'features/AppData/context';
import { SecurityDocumentMemberType } from 'utils/Enums';

import { ValueOf } from 'types/helpers';
import { validate } from './validate';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();
  const { settings } = useAppDataContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [rntd, setRntd] = useState<Rid | null>();
  const [documents, setDocuments] = useState<Document[]>([]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    code: {
      value: '',
      isValid: true,
      required: true,
      title: 'Код',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: { ...prevState.code, value },
        }));
      },
    },
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    type: {
      value: '',
      isValid: true,
      required: true,
      title: 'Тип РИД',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          type: { ...prevState.type, value },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      required: false,
      title: 'Аннотация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
    },
    scope: {
      value: '',
      isValid: true,
      required: false,
      title: 'Область применения',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          scope: { ...prevState.scope, value },
        }));
      },
    },
    keyWords: {
      value: '',
      isValid: true,
      required: false,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          keyWords: { ...prevState.keyWords, value },
        }));
      },
    },
    project: {
      value: '',
      isValid: true,
      required: false,
      title: 'Получен в рамках научного проекта',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          project: { ...prevState.project, value },
        }));
      },
    },
    commercializationProtocol: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер протокола/заключения',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          commercializationProtocol: { ...prevState.commercializationProtocol, value },
        }));
      },
    },
    commercializationDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          commercializationDate: { ...prevState.commercializationDate, value },
        }));
      },
    },
    commercializationDecision: {
      value: '',
      isValid: true,
      required: false,
      title: 'Заключение',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          commercializationDecision: { ...prevState.commercializationDecision, value },
        }));
      },
    },
    selfProductionNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          selfProductionNumber: { ...prevState.selfProductionNumber, value },
        }));
      },
    },
    selfProductionDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата акта внедрения',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          selfProductionDate: { ...prevState.selfProductionDate, value },
        }));
      },
    },
    selfProductionNote: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          selfProductionNote: { ...prevState.selfProductionNote, value },
        }));
      },
    },
    fctekProtocol: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер протокола',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fctekProtocol: { ...prevState.fctekProtocol, value },
        }));
      },
    },
    fctekDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fctekDate: { ...prevState.fctekDate, value },
        }));
      },
    },
    fctekDecision: {
      value: '',
      isValid: true,
      required: false,
      title: 'Решение',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fctekDecision: { ...prevState.fctekDecision, value },
        }));
      },
    },
  });

  const { methods: getRntd } = BackendAPI.useBackendAPI('GetRntd', {
    onSuccessfullCall: ({ data }) => {
      setRntd(data);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('code'), data.code),
          R.set(getValueLens('name'), data.name),
          R.set(getValueLens('type'), data.type),
          R.set(getValueLens('description'), data.description),
          R.set(getValueLens('scope'), data.scope),
          R.set(getValueLens('keyWords'), data.keyWords),
          R.set(getValueLens('project'), data.project),
          R.set(getValueLens('commercializationProtocol'), data.commercializationProtocol),
          R.set(getValueLens('commercializationDate'), data.commercializationDate),
          R.set(getValueLens('commercializationDecision'), data.commercializationDecision),
          R.set(getValueLens('selfProductionNumber'), data.selfProductionNumber),
          R.set(getValueLens('selfProductionDate'), data.selfProductionDate),
          R.set(getValueLens('selfProductionNote'), data.selfProductionNote),
          R.set(getValueLens('fctekProtocol'), data.fctekProtocol),
          R.set(getValueLens('fctekDate'), data.fctekDate),
          R.set(getValueLens('fctekDecision'), data.fctekDecision),
        )(prevFields),
      );
      setDocuments(data.documents);
    },
  });
  const { methods: saveRntd } = BackendAPI.useBackendAPI('SaveRntd');

  const applicants: string = useMemo(() => {
    const applicantMembers = (rntd?.securityDocuments[0]?.members || [])
      .map(x => (x.type?.value === SecurityDocumentMemberType.APPLICANT ? x.name : null))
      .filter(x => x)
      .join(', ');
    return [settings?.organization?.shortName, applicantMembers].filter(x => x).join(', ');
  }, [rntd?.securityDocuments, settings?.organization?.shortName]);

  const onSubmit = useCallback(
    needClose => {
      const errors = validate(rntd, formFields);
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          showNotification({ message: error.invalidMessage, theme: 'danger' });
        });
        return false;
      }
      saveRntd.callAPI(
        {
          authors: rntd?.authors || [],
          commercializationDate: formFields.commercializationDate.value,
          commercializationDecision: formFields.commercializationDecision.value,
          commercializationProtocol: formFields.commercializationProtocol.value,
          departments: rntd?.departments || [],
          description: formFields.description.value,
          domainKnowledges: rntd?.domainKnowledges || [],
          fctekDate: formFields.fctekDate.value,
          fctekDecision: formFields.fctekDecision.value,
          fctekProtocol: formFields.fctekProtocol.value,
          grntis: rntd?.grntis || [],
          id: rntd?.id || null,
          keyWords: formFields.keyWords.value,
          lksetss: rntd?.lksetss || [],
          ntrStrategies: rntd?.ntrStrategies || [],
          okveds: rntd?.okveds || [],
          participations: rntd?.participations || [],
          pnis: rntd?.pnis || [],
          pnmitrs: rntd?.pnmitrs || [],
          pnrs: rntd?.pnrs || [],
          priorityTechnologies: [],
          project: formFields.project.value || null,
          scienceBrunches: rntd?.scienceBrunches || [],
          scienceDomainInterrests: rntd?.scienceDomainInterrests || [],
          scope: formFields.scope.value,
          securityDocuments: rntd?.securityDocuments || [],
          selfProductionDate: formFields.selfProductionDate.value,
          selfProductionNote: formFields.selfProductionNote.value,
          selfProductionNumber: formFields.selfProductionNumber.value,
          subjects: rntd?.subjects || [],
          technologyPlatforms: rntd?.technologyPlatforms || [],
          type: formFields.type.value,
          udks: rntd?.udks || [],
          code: formFields.code.value,
          name: formFields.name.value,
          documents,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'РИД успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              getRntd.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [rntd, tableStreams, updateWorkModeAfterSaveAndContinue, documents, onClose, formFields, getRntd, saveRntd],
  );

  const makeChangeHandler = (key: keyof Rid) => (val: ValueOf<Rid>) => {
    setRntd(prev => ({ ...prev!, [key]: val }));
  };

  useLayoutEffect(() => {
    if (id) {
      if (workMode !== 'editMode' && workMode !== 'viewMode') {
        getRntd.callAPI({ securityDocument: id });
      } else {
        getRntd.callAPI({ id });
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    needClose => {
      onSubmit(needClose);
    },
    [onSubmit],
  );
  return {
    rntdId: id ?? null,
    rntd,
    setRntd,
    workMode,
    formFields,
    handleFormSubmit,
    makeChangeHandler,
    documents,
    setDocuments,
    applicants,
  };
}
