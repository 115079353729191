import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';

const Department = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    tableModalProps={{
      modalTableRowSpecificationName: 'RefDepartment',
      listEditTitle: 'Список подразделений',
      relationTableModalTitle: 'Справочник "Подразделения"',
    }}
  />
);

const Component = memo(Department);

export { Component as Department };
