import React from 'react';
import { DataGrid } from 'features/Table';
import { GetMagazineReleaseArticleList } from 'features/Table/specifications';
import useController from './controller';

const MagazineReleaseArticles = () => {
  const { relatedTableState } = useController();
  return (
    <>
      {relatedTableState && (
        <DataGrid
          specification={GetMagazineReleaseArticleList({
            relatedTableAPIID: 'GetMagazineReleaseArticleList',
            relatedTableState,
            relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
          })}
        />
      )}
    </>
  );
};

export const Component = React.memo(MagazineReleaseArticles);
