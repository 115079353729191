import React from 'react';

import { FormComponent, Header, Tab, Tabs } from 'components';
import { DataGrid } from 'features/Table';

import { GetQnaFrequentQuestionList } from 'features/Table/specifications/GetQnaFrequentQuestionList';
import { GetQnaAllQuestionList } from 'features/Table/specifications';

function Questions() {
  return (
    <FormComponent.Template>
      <Header>Вопросы от пользователей администраторам</Header>
      <FormComponent.Template>
        <Tabs>
          <Tab title="Управление часто задаваемыми вопросами">
            <DataGrid specification={GetQnaFrequentQuestionList()} />
          </Tab>
          <Tab title="Вопросы от пользователей и ответы на них">
            <DataGrid specification={GetQnaAllQuestionList()} />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(Questions);
