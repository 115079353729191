import React, { useCallback, useMemo } from 'react';

import {
  CustomSelect,
  Reference,
  FormComponent,
  Radio,
  Column,
  ListEdit,
  TextArea,
  TextDateTime,
  SectionTitle,
} from 'components';

import { Table as T, Form as F, Event as E, Author } from 'types/models';
import { ParticipationEventSource } from 'types/models/Participation';
import {
  GetCompilationListByTypeSpecification,
  GetConferencePublicationList,
  GetShortEventList,
} from 'features/Table/specifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { MAIN_REPORTER_TOOLTIP_TEXT } from 'features/Form/looks/participation/views/MembersList/helpers';
import { EventFormat } from 'utils/Enums';
import { ParticipationForm } from 'utils/Enums/ParticipationForm';
import { MembersList } from 'features/Form/looks/participation/views/MembersList';
import { getAuthToken, getFileUrl } from 'utils/Helpers';

type Props = {
  mainReporterName: string;
  formFields: F.Fields;
  defaultIsReportPublished: string;
  workMode: T.WorkMode;
  sourcePublicationId: string | null;
  event: ParticipationEventSource | null;
  publication: F.Publication | null;
  isProject: boolean;
  eventPublications: { type: string; publication: E.Source }[];
  setEvent: (event: ParticipationEventSource | null) => void;
  relatedTableState: T.State | undefined;
  members: Author[];
  changeMembers: (val: Author[]) => void;
  onChangeIsReportPublished: (value: string) => void;
  disabledEvent?: boolean;
  isDisabledPublicationInfo?: boolean;
  onUpdatePublicationReportTheme: (publicationReportTheme: F.Original | null) => void;
};

function About({
  mainReporterName,
  formFields,
  workMode,
  publication,
  event,
  sourcePublicationId,
  isProject,
  setEvent,
  relatedTableState,
  onChangeIsReportPublished,
  members,
  changeMembers,
  eventPublications,
  disabledEvent,
  isDisabledPublicationInfo,
  onUpdatePublicationReportTheme,
}: Props) {
  const token = getAuthToken();

  const modalTableRowToPublicationReportThemeConverter = useCallback<(row: T.Entry) => F.Original>(
    row => ({ id: row.id, name: row.name }),
    [],
  );

  const modalTableRowToEventConventer = useCallback<(row: T.Entry) => ParticipationEventSource>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      fullName: row.fullName,
      startDate: row.startDate,
      endDate: row.endDate,
      entity: null,
      eventStatus: null,
      format: EventFormat.OFFLINE,
    }),
    [],
  );

  const sourceCompilationTableConfig = GetCompilationListByTypeSpecification({
    requestData: {
      type: PublicationTypes.COMPILATION_CONFERENCE.code,
      isElectronic: false,
    },
    hasSelectButton: true,
  });

  const eventPublicationColumns = useMemo<Column<{ type: string; publication: E.Source }>[]>(
    () => [
      {
        label: 'Тип',
        formatValue: row =>
          row.type === PublicationTypes.COMPILATION_CONFERENCE.code
            ? PublicationTypes.COMPILATION_CONFERENCE.label
            : 'Выпуск журнала',
        styles: { width: '20%' },
      },
      {
        label: 'Публикация',
        formatValue: row =>
          row.type === PublicationTypes.COMPILATION_CONFERENCE.code
            ? row.publication.bibliographicRecord || ''
            : `Журнал: ${event?.entity?.magazineSource?.name || ''} Том: ${row.publication.part} Выпуск: ${
                row.publication.number
              } Год: ${row.publication.year}`,
        styles: { width: '60%' },
      },
      {
        label: 'Статус',
        formatValue: row => `${row.publication.status || ''} (ID: ${row.publication.id})`,
        styles: { width: '20%' },
      },
    ],
    [event?.entity?.magazineSource?.name],
  );

  const eventPublicationRowConverter = useCallback<(row: T.Entry) => { type: string; publication: E.Source }>(
    row => ({
      type: row.type,
      publication: {
        id: row.id,
        name: row.label,
        bibliographicRecord: row.bibliographicRecord,
        status: row.status,
        number: row.number,
        part: row.part,
        year: row.year,
      },
    }),
    [],
  );

  const shortEventTableConfig = GetShortEventList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
    },
    requestData: {
      eventType: 'CONFERENCE',
      allEvents: true,
      participationReportPublicationId: sourcePublicationId || undefined,
    },
    hasSelectButton: true,
    hasFilter: true,
    buttonsToHide: ['approve'],
  });

  const conferencePublicationListTableConfig = GetConferencePublicationList({
    requestData: {
      eventId: event?.id,
    },
    eventId: event?.id,
  });

  return (
    <form>
      <input type="submit" hidden />
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <SectionTitle title="Конференция" />
          <FormComponent.Line>
            <FormComponent.Field>
              <CustomSelect
                disabled={workMode === 'viewMode' || disabledEvent}
                isRequired
                isStatusText
                title="Конференция"
                original={event}
                relationTableModalTitle="Конференции"
                onDeleteFunction={() => setEvent(null)}
                onChange={setEvent}
                modalTableRowConverter={modalTableRowToEventConventer}
                modalTableSpecification={shortEventTableConfig}
                isShowFullName
                isDisableClear={isDisabledPublicationInfo}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <>
            {(event?.eventStatus?.label || event?.format?.label || event?.site) && (
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="">
                  <FormComponent.Description mode="warning" classMixin="is-hint">
                    {event?.eventStatus?.label && (
                      <div>
                        <strong>Уровень мероприятия:</strong> {event?.eventStatus?.label}
                      </div>
                    )}
                    {event?.format?.label && (
                      <div>
                        <strong>Формат проведения:</strong> {event?.format?.label}
                      </div>
                    )}
                    {event?.site && (
                      <div>
                        <strong>Online: </strong>
                        <FormComponent.Link href={event?.site} />
                      </div>
                    )}
                  </FormComponent.Description>
                </FormComponent.Field>
              </FormComponent.Line>
            )}
          </>
          <FormComponent.Line>
            <FormComponent.Field
              label="Формат участия"
              isRequired={formFields.form.required}
              // eslint-disable-next-line max-len
              tooltip='Выбор формата участия влияет на состав полей формы. Для формата Участие в деловой программе мероприятия достаточно указать сведения об участниках и прикрепить подтверждающий документ на вкладке "Документы".'
            >
              <Reference
                disabled={workMode === 'viewMode' || isDisabledPublicationInfo}
                name="RefParticipationForm"
                value={formFields.form.value ?? null}
                onChange={formFields.form.onChange}
                filters={[{ key: 'type', values: ['CONFERENCE'] }]}
                relationTableModalTitle='Справочник "Форматы участия"'
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <MembersList eventType="CONFERENCE" disabled={workMode === 'viewMode'} authors={members} setAuthors={changeMembers} />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          {formFields.form.value?.customFields?.code !== ParticipationForm.CONFERENCE_BUSINESS_PROGRAM_PARTICIPATION && (
            <>
              <SectionTitle
                title="Доклад"
                tooltip="Доклад обязателен, если участие в конференции является одним из результатов выполнения проекта"
              />
              <FormComponent.Line>
                <FormComponent.Field
                  label={formFields.isReportPublished.title}
                  // eslint-disable-next-line max-len
                  tooltip={`Выбрать "Да" и подробно заполнить предоставленные формы, если участие в конференции с опубликованным докладом.`}
                >
                  <Radio
                    isDisabled={workMode === 'viewMode' || isDisabledPublicationInfo}
                    value={formFields.isReportPublished.value}
                    list={[
                      { label: 'Нет ', value: 'false' },
                      { label: 'Да', value: 'true' },
                    ]}
                    onChange={onChangeIsReportPublished}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <Reference
                  disabled={workMode === 'viewMode'}
                  isRequired={formFields.reportType.required}
                  onChange={formFields.reportType.onChange}
                  value={formFields.reportType.value || ''}
                  title={formFields.reportType.title}
                  relationTableModalTitle='Справочник "Типы доклада"'
                  theme="big"
                  refButtonTitle='Справочник "Типы доклада"'
                  name="RefTypeReport"
                />
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label={formFields.reportDate.title} isRequired={!!formFields.reportDate.required}>
                  <TextDateTime
                    value={formFields.reportDate.value}
                    onChange={formFields.reportDate.onChange}
                    isDisabled={workMode === 'viewMode' || (!!event && !!event.startDate && !event.endDate)}
                  />
                </FormComponent.Field>
              </FormComponent.Line>

              {formFields.isReportPublished.value === 'true' ? (
                <>
                  <FormComponent.Line>
                    <CustomSelect
                      disabled={workMode === 'viewMode' || isDisabledPublicationInfo}
                      isRequired={formFields.publicationReportTheme.required}
                      title={formFields.publicationReportTheme.title}
                      original={formFields.publicationReportTheme.value}
                      relationTableModalTitle="Список статей и тезисов в опубликованных материалах конференции"
                      onDeleteFunction={() => {
                        onUpdatePublicationReportTheme(null);
                      }}
                      onChange={onUpdatePublicationReportTheme}
                      modalTableRowConverter={modalTableRowToPublicationReportThemeConverter}
                      modalTableSpecification={conferencePublicationListTableConfig}
                      iconTitle='Справочник "Публикации конференции"'
                    />
                  </FormComponent.Line>
                  {publication && (
                    <>
                      <FormComponent.Line lineSize="doublePadded">
                        <FormComponent.Field label={publication?.status?.typeLabel || 'Тип публикации'}>
                          <span>
                            {publication?.fields?.bibliographicRecord +
                              (publication?.status
                                ? ` (Статус записи: ${publication?.status?.text.toLocaleLowerCase()}) (ID: ${
                                    publication?.status.id
                                  })`
                                : '') || ''}
                          </span>
                        </FormComponent.Field>
                      </FormComponent.Line>
                      <FormComponent.Line lineSize="doublePadded">
                        <FormComponent.Field label="Файл статьи">
                          {publication?.file ? (
                            <FormComponent.Link href={getFileUrl(publication.file.id, token)} label={publication.file?.name} />
                          ) : (
                            <FormComponent.Link label="У публикации нет прикрепленного файла" />
                          )}
                        </FormComponent.Field>
                      </FormComponent.Line>
                    </>
                  )}
                </>
              ) : (
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label={formFields.reportTheme.title} tooltip={formFields.reportTheme.tooltipText}>
                    <TextArea
                      settings={{ rows: 3 }}
                      value={formFields.reportTheme.value}
                      onChange={formFields.reportTheme.onChange}
                      isDisabled={workMode === 'viewMode'}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field
                  isRequired={Boolean(formFields.isReportPublished.value === 'true' || isProject)}
                  label="Основной докладчик"
                  tooltip={MAIN_REPORTER_TOOLTIP_TEXT}
                >
                  <span dangerouslySetInnerHTML={{ __html: mainReporterName }} />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
          <SectionTitle title="Издания, в которых опубликованы материалы конференции" />
          <FormComponent.Line lineSize="doublePadded">
            {eventPublications.length ? (
              <ListEdit
                defaultRowsCount={eventPublications.length}
                maxHeight="none"
                hideToolbar
                rows={eventPublications}
                columns={eventPublicationColumns}
                onChange={() => {}}
                specification={{
                  mode: 'relationTableModal',
                  modalTableRowConverter: eventPublicationRowConverter,
                  relationTableModalTitle: '',
                  modalTableSpecification: sourceCompilationTableConfig,
                }}
              />
            ) : (
              <FormComponent.Description mode="warning">
                {event?.entity
                  ? // eslint-disable-next-line max-len
                    'В выбранной конференции не указаны специальные выпуски журналов и сборники материалов конференции, в которых опубликованы ее материалы. Если доклад опубликован, обязательно необходимо выбрать "Да" в строке "Опубликован" и добавить издание и статью (тезисы) доклада. Сведения о докладе будут загружаться в ЕГИСУ.'
                  : 'Не выбрана конференция'}
              </FormComponent.Description>
            )}
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </form>
  );
}

export const Component = React.memo(About);
