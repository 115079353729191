import React from 'react';

import { DataGrid } from 'features/Table';
import { GetPersonalExpositionEventList } from 'features/Table/specifications';
import { EventTypes } from 'utils/Enums/EventTypes';

export function EventExpositions() {
  return (
    <DataGrid specification={GetPersonalExpositionEventList({ eventType: EventTypes.EVENT_EXPOSITION.code })} hasPersonalMode />
  );
}
