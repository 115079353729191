import { Tender } from 'types/models';

import { convertDirection, convertProgram } from 'services/BackendAPI/configurations/program/converters';
import { Option } from 'components';
import {
  convertDocument,
  convertFile,
  convertItem,
  convertMaybeArray,
  convertReference,
  convertTime,
  getAttribute,
  getText,
} from '../commonConverters';
import { TenderRequestType } from 'utils/Enums';
import { convertServerPerson } from '../author/converters';
import { Settings } from 'types/models/common';

export function convertTender(tender: any, settings: Settings): Tender.Tender {
  return {
    id: tender._attributes.id,
    name: getText(tender.Name),
    type: tender.Type ? convertType(tender.Type) : null,
    collectiveType: tender.CollectiveType ? convertCollectiveType(tender.CollectiveType) : null,
    ...(tender.Program ? { program: convertProgram(tender.Program) } : {}),
    ...(tender.ProgramEvent ? { programEvent: convertProgramEvent(tender.ProgramEvent) } : {}),
    kindTrade: convertReference(tender.KindTrade),
    dateUniversity: getText(tender.DateUniversity),
    contactFace: tender.ContactFace ? getText(tender.ContactFace) : null,
    dateOpenEnvelope: getText(tender.DateOpenEnvelope),
    timeOpenEnvelope: convertTime(tender.TimeOpenEnvelope),
    dateExamenation: getText(tender.DateExamenation),
    dateSummingUp: getText(tender.DateSummingUp),
    url: getText(tender.Url),
    ...(tender.Description ? { description: getText(tender.Description) } : {}),
    customers: convertMaybeArray(tender.Customers.Customer || [], convertCustomer),
    lots: tender.Lots ? convertMaybeArray(tender.Lots.Lot || [], convertLot) : [],
    documents: tender.Documents ? convertMaybeArray(tender.Documents.Document || [], doc => convertDocument('local', doc)) : [],
    categories: tender.Categories ? convertMaybeArray(tender.Categories.Category || [], convertReference) : [],
    participants: tender.Participants ? convertMaybeArray(tender.Participants.Participant || [], convertReference) : [],
    scienceDomainInterrests: convertMaybeArray(tender.ScienceDomainInterests.ScienceDomainInterest || [], convertReference),
    announcementDate: getText(tender.AnnouncementDate),
    isMarketplace: getText(tender.IsMarketplace) === 'true',
    marketplace: tender.Marketplace ? convertReference(tender.Marketplace) : null,
    // marketplaceSignatory: tender.MarketplaceSignatory ? convertMarketplaceSignatory(tender.MarketplaceSignatory) : null,
    marketplaceNote: getText(tender.MarketplaceNote),
    marketplaceAmount: getText(tender.MarketplaceAmount) || '0.00',
    requestType: convertItem<TenderRequestType>(tender.RequestType),
    contacts: tender.Contacts ? convertMaybeArray(tender.Contacts.Contact || [], x => convertServerPerson(x, settings)) : [],
    logo: convertFile(tender.Logo),
    requestsPlan: getText(tender.RequestsPlan),
  };
}

const convertType = (type: any): Option => {
  return {
    label: getAttribute({ attributeKey: 'label', item: type }) ?? '',
    value: getText(type),
  };
};

const convertCollectiveType = (collectiveType: any): Option => {
  return {
    label: getAttribute({ attributeKey: 'label', item: collectiveType }) ?? '',
    value: getText(collectiveType),
  };
};

// const convertMarketplaceSignatory = (marketplaceSignatory: any): Option => {
//   return {
//     label: getAttribute({ attributeKey: 'fullName', item: marketplaceSignatory }) ?? '',
//     value: getId(marketplaceSignatory),
//   };
// };

function convertCustomer(customer: any): Tender.Customer {
  return {
    id: customer._attributes.id,
    customerId: customer.Customer._attributes.id,
    fullName: getText(customer.Customer.fullName),
    address: getText(customer.Address),
    contacts: getText(customer.Contacts),
  };
}

function convertLot(lot: any): Tender.Lot {
  return {
    id: lot?._attributes?.id,
    number: getText(lot?.Number),
    code: getText(lot?.Code),
    periodNote: getText(lot?.PeriodNote),
    theme: getText(lot?.Theme),
    startDate: getText(lot?.StartDate),
    endDate: getText(lot?.EndDate),
    limitAmount: parseFloat(getText(lot?.LimitAmount)),
    contractCount: parseInt(getText(lot?.ContractCount), 10),
    contractAmount: parseFloat(getText(lot?.ContractAmount)) || 0,
    isSupportRequest: getText(lot?.IsSupportRequest) === 'true',
    requestSupportAmount: parseFloat(getText(lot?.RequestSupportAmount)),
    isSupportContract: getText(lot?.IsSupportContract) === 'true',
    contractSupportAmount: parseFloat(getText(lot?.ContractSupportAmount)),
    isSupportWarranty: getText(lot?.IsSupportWarranty) === 'true',
    warrantySupportAmount: parseFloat(getText(lot?.WarrantySupportAmount)),
    investmentSource: convertReference(lot?.InvestmentSource),
    investmentSourceNote: getText(lot?.InvestmentSourceNote),
    note: getText(lot?.Note),
    prices: convertMaybeArray((lot.Prices && lot.Prices.Price) || [], convertLotPrice),
  };
}

export function convertLotPrice(lotPrice: any): Tender.LotPrice {
  return {
    year: getText(lotPrice.Year),
    amount: getText(lotPrice.Amount),
  };
}

function convertProgramEvent(programEvent: any): Tender.ProgramEvent {
  return {
    id: programEvent._attributes.id,
    name: programEvent?.Name ? getText(programEvent?.Name) : null,
    direction: programEvent?.Direction ? convertDirection(programEvent?.Direction) : null,
    ...(programEvent?.Code ? { code: getText(programEvent?.Code) } : {}),
  };
}
