import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { Parameter, RadioValue } from 'types/models';
import { BuilderProps, RadioItem } from 'features/BuildReportPopup/models';

type Props = { initialRadioList: RadioItem[]; defaultValue?: string } & BuilderProps;

const useController = ({ parameters, setParameterValueByName, parameterName, initialRadioList, defaultValue }: Props) => {
  const radioListParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );

  const canBuild = useMemo<boolean>(() => !!radioListParameter, [radioListParameter]);

  const [radios] = useState<RadioItem[]>(initialRadioList);

  const [radioValue, setRadioValue] = useState<string>('');

  const updateRadioValue = useCallback(
    (nextRadioValue: string) => {
      const nextParameterValue: RadioValue = nextRadioValue;

      setParameterValueByName({ name: parameterName, value: nextParameterValue });
      setRadioValue(nextRadioValue);
    },
    [parameterName, setParameterValueByName],
  );

  useLayoutEffect(() => {
    const initialValue = radioListParameter?.default || defaultValue || '';
    if (initialValue) {
      updateRadioValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    radios,
    canBuild,
    radioValue,
    updateRadioValue,
    radioListParameter,
  };
};

export default useController;
