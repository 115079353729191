import React, { useCallback, useEffect } from 'react';

import { Tabs, Tab, FormComponent } from 'components';

import { Documents } from 'features/Documents';
import { NirRequestStatus, TenderType } from 'utils/Enums';
import { DataGrid } from 'features/Table';
import { GetNirRequestChangeList } from 'features/Table/specifications';
import { AboutTender } from 'features/Form/looks/programRequest/ProgramRequestForm/Tabs/AboutTender';
import { Estimate } from 'features/Form/views';

import {
  Request,
  Collective,
  ProjectDescription,
  CollectiveResults,
  FeedlineList,
  LeaderInfo,
  LeadingScientist,
  PerformanceIndicators,
} from './Tabs';
import { Toolbar } from './Toolbar/Toolbar';
import { useController } from './controller';

import { getHeadName, getLeadScientist } from './helpers';
import { useFormContext } from 'features/Form/hooks';
import { NirRequestFormLook } from 'types/models/Form';
import { useLocalTableStreams } from 'features/Table/hooks';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function NirRequestForm({ onClose, viewMode }: Props) {
  const { nirRequest, makeChangeHandler, saveNir, setNirRequest, sendMessage, changeStatus, addApprovement } = useController({
    onClose,
  });
  const {
    look: { setTitle },
  } = useFormContext<NirRequestFormLook>();
  const tableStreams = useLocalTableStreams();

  const status = nirRequest?.status.value ?? NirRequestStatus.DRAFT;

  const { userPermission } = useAppDataContext();

  const isHasLeader = getHeadName(nirRequest) !== null;
  const isHasLeadScientist = getLeadScientist(nirRequest) !== null;
  const isShowChanges = nirRequest?.status.value !== 'DRAFT';
  const tenderType = nirRequest?.tenderType.value ?? null;

  const handleSave = useCallback(
    (callback?: () => void) => {
      saveNir(true, () => {
        onClose();
        callback?.();
        setTimeout(() => tableStreams.reloadTable.push(), 100);
      });
    },
    [saveNir, onClose, tableStreams.reloadTable],
  );

  const handleSaveDraft = useCallback(() => {
    saveNir(false);
  }, [saveNir]);

  const handleClose = useCallback(() => {
    onClose();
    tableStreams.reloadTable.push();
  }, [onClose, tableStreams.reloadTable]);

  useEffect(() => {
    setTitle?.(
      (() => {
        if (!nirRequest?.id) {
          return 'Добавление Заявки на внутренний конкурс';
        }

        const details = [
          nirRequest?.id ? `ID ${nirRequest?.id}` : '',
          nirRequest?.status?.label ? `Статус: ${nirRequest?.status?.label}` : '',
        ]
          .filter(x => x)
          .join(', ');

        return [viewMode ? 'Просмотр' : 'Редактирование', 'заявки на внутренний конкурс', details ? `(${details})` : '']
          .filter(x => x)
          .join(' ');
      })(),
    );
  }, [nirRequest?.id, nirRequest?.status?.label, setTitle, viewMode]);

  return (
    <FormComponent.Template>
      <Toolbar
        nirRequest={nirRequest}
        disabledEdit={!!viewMode}
        onSave={handleSave}
        onSaveDraft={handleSaveDraft}
        onCancel={handleClose}
        onSendMessage={sendMessage}
        changeStatus={changeStatus}
        addApprovement={addApprovement}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О конкурсе">
            <AboutTender tender={nirRequest?.tender || null} lot={nirRequest?.lot || null} />
          </Tab>
          <Tab
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`NIR_REQUEST_EDIT_${status}_REQUEST`])}
            title="Заявка"
          >
            <Request
              disabled={!!viewMode}
              setNirRequest={setNirRequest}
              nirRequest={nirRequest}
              makeChangeHandler={makeChangeHandler}
            />
          </Tab>
          <Tab
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`NIR_REQUEST_EDIT_${status}_PERFORMERS`])}
            title="Коллектив"
          >
            <Collective disabled={!!viewMode} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
          </Tab>
          {isHasLeadScientist && tenderType !== TenderType.PromPartner && tenderType !== TenderType.InitiativeGroup && (
            <Tab title="Ведущий учёный">
              <LeadingScientist
                performers={nirRequest?.performers ?? []}
                setPerformers={makeChangeHandler('performers')}
                disabled={!!viewMode}
              />
            </Tab>
          )}
          {isHasLeader && (
            <Tab title="Данные о руководителе">
              <LeaderInfo disabled={!!viewMode} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
            </Tab>
          )}
          <Tab
            title="Описание проекта"
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`NIR_REQUEST_EDIT_${status}_DESCRIPTION`])}
          >
            <ProjectDescription disabled={!!viewMode} makeChangeHandler={makeChangeHandler} nirRequest={nirRequest} />
          </Tab>
          <Tab
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`NIR_REQUEST_EDIT_${status}_INDICATORS`])}
            title="Показатели эффективности"
          >
            <PerformanceIndicators disabled={!!viewMode} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
          </Tab>
          <Tab
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`NIR_REQUEST_EDIT_${status}_COLLECTIVE`])}
            title="Результаты коллектива (5 лет)"
          >
            <CollectiveResults
              disabled={!!viewMode}
              makeChangeHandler={makeChangeHandler}
              nirRequest={nirRequest}
              setNirRequest={setNirRequest}
            />
          </Tab>
          <Tab
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`NIR_REQUEST_EDIT_${status}_ESTIMATE`])}
            title="Смета"
          >
            <Estimate
              disabled={!!viewMode}
              handleEstimateChange={makeChangeHandler('estimatePositions')}
              estimatePositions={nirRequest?.estimatePositions || []}
              estimateExpencesJustification={nirRequest?.estimateExpencesJustification || ''}
              handleEstimateExpencesJustificationChange={makeChangeHandler('estimateExpencesJustification')}
            />
          </Tab>
          <Tab title="Журнал сообщений" isVisible={isHasPermission(userPermission, Permits.NIR_REQUEST_FEEDLINE_AGREE)}>
            <FeedlineList nirRequestId={nirRequest?.id ?? ''} />
          </Tab>
          <Tab
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`NIR_REQUEST_EDIT_${status}_DOCUMENTS`])}
            title="Документы"
          >
            <Documents
              documents={nirRequest?.documents ?? []}
              disabled={!!viewMode}
              setDocuments={makeChangeHandler('documents')}
              isCanIsSharedEdit
              sharedLabel="Отображать в проекте"
              defaultSharedValue
              sourceLabelForLocalDocs="Текущая заявка"
              isShowSource
            />
          </Tab>
          {isShowChanges && (
            <Tab title="Регистрация изменений">
              <DataGrid specification={GetNirRequestChangeList({ nirRequestId: nirRequest?.id ?? '' })} />
            </Tab>
          )}
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(NirRequestForm);
