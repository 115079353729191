import React from 'react';
import { DataGrid } from 'features/Table';
import { GetProjectPatentResearchList } from 'features/Table/specifications';

type Props = {
  projectId: string | null;
};
export function ResultsProjectPatents({ projectId }: Props) {
  return <DataGrid specification={GetProjectPatentResearchList({ projectId: projectId || '-1' })} />;
}
