import { useCallback, useMemo, useState } from 'react';
// import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, IconButtonProps } from 'components';

import { Table } from 'types/models';
// import { Publication, Report } from 'types/models';
// import useReportsHook from 'features/BuildReportPopup/hooks';
// import { Reports } from 'features/BuildReportPopup/Reports';
// import { getIsSourceCitationDisabled } from 'utils/Helpers/getIsSourceCitationDisabled';
import { Permits } from 'utils/Permissions';
// import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
// import { helpers } from 'features/Form/views/PublicationOriginalTranslatesRelations';
import { getLinksMap } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
// import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
// import { isCompilation, isTSUEdition } from 'features/EditRelationsModal/helpers';
// import { downloadFile, getAuthToken } from 'utils/Helpers';

export function useController(tableState: Table.State) {
  // const token = getAuthToken();
  // const { currentPerson } = useAppDataContext();
  const { settings } = useAppDataContext();
  // const tableStreams = useLocalTableStreams();
  const isSelectedRowsLength = !tableState.selectedRows.length;

  // const selectedRowsLength = tableState.selectedRows.length;

  // const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
  //   () => ({
  //     ALWAYS: true,
  //     SINGLE_SELECTED: selectedRowsLength === 1,
  //     MULTIPLE_SELECTED: selectedRowsLength >= 1,
  //   }),
  //   [selectedRowsLength],
  // );

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  // const [isOpenEditRelations, setIsOpenEditRelations] = useState(false);
  // const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  // const [isPublicationOriginalTranslatesRelationsOpen, setIsPublicationOriginalTranslatesRelationsOpen] = useState(false);
  // const [isSourceCitationOpen, setIsSourceCitationOpen] = useState(false);
  // const [isFinancialSupportOpen, setIsFinancialSupportOpen] = useState(false);
  // const [publication, setPublication] = useState<Publication | null>(null);

  // const reports = useMemo<Report[]>(
  //   () => [
  //     Reports.Personal,
  //     Reports.PublicationList,
  //     Reports['PublicationForm2.3'],
  //     Reports.PublicationExtendedList,
  //     Reports.IncentivePaymentsScientist,
  //   ],
  //   [],
  // );

  // const {
  //   isArticlesWarningPopupOpen,
  //   isEditArticleSourceOpen,
  //   handleCloseArticlesWarningPopup,
  //   handleCloseEditArticleSource,
  //   handleSubmitDeleteWarningPopup,
  // } = useDeletePublication();

  // const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });
    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  // const isEditRelationsDisabled = useMemo(() => {
  //   if (tableState.selectedRows.length) {
  //     const [row] = tableState.selectedRows;
  //     return isCompilation(row) ? !isTSUEdition(row) : false;
  //   }
  //   return true;
  // }, [tableState.selectedRows]);

  //  const isCitationDisabled = !rowsAvailability.SINGLE_SELECTED;

  // const isSourceCitationDisabled = useMemo(() => {
  //   if (tableState.selectedRows.length) {
  //     const [row] = tableState.selectedRows;
  //     return getIsSourceCitationDisabled({ row });
  //   }
  //   return true;
  // }, [tableState.selectedRows]);

  // const isPublicationOriginalTranslatesRelationsDisabled = useMemo(() => {
  //   if (tableState.selectedRows.length) {
  //     const [row] = tableState.selectedRows;
  //     return !helpers.isMagazineArticle(row);
  //   }
  //   return true;
  // }, [tableState.selectedRows]);

  /**
   * запрос публикаций
   */

  // const { methods: getPublicationAPI } = BackendAPI.useBackendAPI('GetPublicationById', {
  //   onSuccessfullCall: ({ data }) => {
  //     setPublication(data);
  //   },
  // });
  // const getPublication = useCallback((publicationId: string) => {
  //   getPublicationAPI.callAPI({ publicationId });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const getSelectedRow = useCallback(() => {
  //   if (tableState.selectedRows.length) {
  //     const [row] = tableState.selectedRows;
  //     return row;
  //   }
  //   return null;
  // }, [tableState.selectedRows]);

  // const updateTable = useCallback(() => {
  //   tableStreams.reloadTable.push();
  // }, [tableStreams.reloadTable]);

  /**
   * handles
   */

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  // const handleEditRelations = useCallback(() => {
  //   const row = getSelectedRow();
  //   if (row) {
  //     getPublication(row.id);
  //     setIsOpenEditRelations(true);
  //   }
  // }, [getSelectedRow, getPublication]);
  // const handleCloseEditRelationsModal = useCallback(() => {
  //   setIsOpenEditRelations(false);
  // }, []);

  // const handleOpenCitationForm = useCallback(() => {
  //   setIsCitationFormOpen(true);
  // }, []);

  // const handleCloseCitationForm = useCallback(() => {
  //   setIsCitationFormOpen(false);
  // }, []);

  // const handleOpenSourceCitation = useCallback(() => {
  //   setIsSourceCitationOpen(true);
  // }, []);

  // const handleCloseSourceCitation = useCallback(() => {
  //   setIsSourceCitationOpen(false);
  // }, []);

  // const handleOpenPublicationOriginalTranslatesRelations = useCallback(() => {
  //   setIsPublicationOriginalTranslatesRelationsOpen(true);
  // }, []);

  // const handleClosePublicationOriginalTranslatesRelations = useCallback(() => {
  //   setIsPublicationOriginalTranslatesRelationsOpen(false);
  // }, []);

  // const submitPublicationOriginalTranslatesRelationsOpen = useCallback(() => {
  //   updateTable();
  // }, [updateTable]);

  // const handleOpenFinancialSupport = useCallback(() => {
  //   setIsFinancialSupportOpen(true);
  // }, []);

  // const handleCloseFinancialSupport = useCallback(() => {
  //   setIsFinancialSupportOpen(false);
  // }, []);

  // const submitFinancialPopupCallback = useCallback(() => {
  //   updateTable();
  // }, [updateTable]);
  // const handleDownloadPublicationFile = useCallback(() => {
  //   downloadFile(tableState.selectedRows[0]?.FileId, token);
  // }, [tableState.selectedRows, token]);
  /**
   * кнопки
   */

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isSelectedRowsLength,
        onClick: handleViewButtonClick,
        permissionName: Permits.PUBLICATION_VIEW,
      },
      // {
      //   icons: buttonIcons.editRelations,
      //   title: 'Редактировать связь публикации с подразделениями',
      //   code: 'editRelations',
      //   isDisabled: isEditRelationsDisabled,
      //   onClick: handleEditRelations,
      //   permissionName: Permits.PUBLICATION_DEPARTMENTS_EDIT,
      // },
      // {
      //   icons: buttonIcons.citation,
      //   title: 'Показатели публикации',
      //   code: 'citationSystem',
      //   isDisabled: isCitationDisabled,
      //   onClick: handleOpenCitationForm,
      // },
      // {
      //   icons: buttonIcons.sourceScore,
      //   title: 'Показатели источника',
      //   code: 'sourceScore',
      //   isDisabled: isSourceCitationDisabled,
      //   onClick: handleOpenSourceCitation,
      // },
      // {
      //   icons: buttonIcons.download,
      //   title: 'Скачать скан-копию публикации',
      //   isDisabled: !tableState.selectedRows.length,
      //   onClick: handleDownloadPublicationFile,
      // },
      {
        icons: buttonIcons.link,
        title: 'Открыть публикацию во внешнем источнике',
        code: 'openPublicationOuterSource',
        isDisabled: !tableState.selectedRows.length,
        onClick: () => {},
        getExpandedList: getLinks,
      },
      // {
      //   icons: buttonIcons.articleRelations,
      //   title: 'Указать статью-оригинал/переводную статью',
      //   code: 'articleRelations',
      //   isDisabled: isPublicationOriginalTranslatesRelationsDisabled,
      //   onClick: handleOpenPublicationOriginalTranslatesRelations,
      //   permissionName: Permits.PUBLICATION_ARTICLE_TRANSLATION_EDIT,
      // },
      // {
      //   icons: buttonIcons.financialSupport,
      //   title: 'Финансовая поддержка публикации',
      //   code: 'publicationFinancialSupport',
      //   isDisabled: isSelectedRowsLength,
      //   onClick: handleOpenFinancialSupport,
      //   permissionName: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT,
      // },
      // {
      //   icons: buttonIcons.print,
      //   title: 'Печать документов',
      //   code: 'print',
      //   isDisabled: false,
      //   onClick: () => {},
      //   getExpandedList: getReports,
      //   expandedItemCallback: handleSetCurrentReport,
      // },
    ],
    [isSelectedRowsLength, handleViewButtonClick, tableState.selectedRows.length, getLinks],
  );
  return {
    isViewFormOpen,
    handleTemplateCloseViewForm,
    buttons,
    // publication,
    // isArticlesWarningPopupOpen,
    // isEditArticleSourceOpen,
    // handleCloseArticlesWarningPopup,
    // handleCloseEditArticleSource,
    // handleSubmitDeleteWarningPopup,
    // isOpenEditRelations,
    // handleCloseEditRelationsModal,
    // isCitationFormOpen,
    // handleCloseCitationForm,
    // isSourceCitationDisabled,
    // isSourceCitationOpen,
    // handleCloseSourceCitation,
    // isPublicationOriginalTranslatesRelationsOpen,
    // handleClosePublicationOriginalTranslatesRelations,
    // submitPublicationOriginalTranslatesRelationsOpen,
    // isFinancialSupportOpen,
    // handleCloseFinancialSupport,
    // submitFinancialPopupCallback,
    // isReportOpen,
    // onReportClose,
    // currentReport,
    // currentPerson,
  };
}
