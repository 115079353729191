import { useCallback, useEffect, useRef, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Option } from 'components';

import { InputActionMeta } from 'react-select';
import { useDebounce } from 'shared/react/useDebounce';

type Arguments = {
  fieldName: string;
  limit: number;
  onChange(value: string): void;
  value: string;
};

export function useController(args: Arguments) {
  const [options, setOptions] = useState<Option[]>([]);
  const isAllowedLoadingSuggestions = useRef(false);

  const { fieldName, limit, value, onChange } = args;

  const { methods: getFieldSuggestion, state } = BackendAPI.useBackendAPI('GetFieldSuggestion', {
    onSuccessfullCall: ({ data }) => {
      const updatedOptions = data.fieldSuggestions.map<Option>(x => ({ label: x.label, value: x.label }));
      setOptions(updatedOptions);
    },
  });

  useEffect(() => {
    if (value && !options.length) {
      setOptions([{ label: value, value }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedValue = useDebounce(value, 300);
  useEffect(() => {
    if (isAllowedLoadingSuggestions.current) {
      getFieldSuggestion.callAPI({ fieldName, limit, filter: debouncedValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onInputChange = useCallback(
    (newValue: string, meta: InputActionMeta) => {
      if (meta.action === 'input-change') {
        isAllowedLoadingSuggestions.current = true;
        onChange(newValue);
      }
    },
    [onChange],
  );

  const onSelectChange = useCallback(
    (option: Option | null) => {
      isAllowedLoadingSuggestions.current = false;
      onChange(option?.value ?? '');
    },
    [onChange],
  );

  return {
    onInputChange,
    onSelectChange,
    options,
    isLoading: state.kind === 'pending',
  };
}
