import React from 'react';

import { FormComponent, ListEdit, Tabs, Tab, Select } from 'components';

import { Documents } from 'features/Documents';
import { GetBaseDissertationPublicationList } from 'features/Table/specifications';
import { renderEntry } from 'components/Select/helpers';
import { DataGrid } from 'features/Table';
import { useController } from './controller';
import { MemberFields } from './MemberFields';
import { validateMember } from './validate';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { CommunityType } from 'utils/Enums';
import { MagazineAboutFields } from './AboutFields/MagazineAboutFields';
import { AboutFields } from 'features/Form/looks/community/CommunityForm/AboutFields/AboutFields';
import { getPersonHistory } from 'utils/Helpers';
import { Member } from 'types/models/Community';
import { useFormContext } from 'features/Form/hooks';
import { CommunityFormLook } from 'types/models/Form';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function CommunityForm({ viewMode, editMode, onClose }: Props) {
  const {
    community,
    setCommunity,
    communityId,
    communityTypes,
    communityTypesOptions,
    handleDocumentsFieldChange,
    handleFormSubmit,
    handleMembersFieldChange,
    workMode,
    handleCommunityTypeFieldChange,
    cooperationTypeOptions,
    roleOptions,
    isNotDissertationCouncil,
    settings,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { isMembersTabHidden },
  } = useFormContext<CommunityFormLook>();

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О сообществе">
            {isNotDissertationCouncil && (
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Тип сообщества" isRequired>
                  <Select.Component
                    value={community?.type?.label ?? ''}
                    onChange={handleCommunityTypeFieldChange}
                    entries={communityTypesOptions.map(x => x.label)}
                    renderEntry={renderEntry}
                    variant="outlined"
                    defaultPlaceholder="Не задан"
                    disabled={!(workMode === 'addMode')}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
            {community?.type?.value === CommunityType.MAGAZINE_REDACTION ? (
              <MagazineAboutFields workMode={workMode} community={community} setCommunity={setCommunity} />
            ) : (
              <AboutFields
                workMode={workMode}
                community={community}
                setCommunity={setCommunity}
                isNotDissertationCouncil={isNotDissertationCouncil}
                settings={settings}
              />
            )}
          </Tab>

          <Tab title="Члены сообщества" isVisible={!isMembersTabHidden}>
            <ListEdit
              withMessages
              defaultRowsCount={9}
              maxHeight="300px"
              isDeleteConfirmEnabled
              isToolbarDisabled={workMode === 'viewMode'}
              rows={community?.members || []}
              onChange={handleMembersFieldChange}
              columns={[
                { label: 'ФИО участника', formatValue: (m: Member) => m.scientist?.fullName || '', styles: { width: '20%' } },
                {
                  label: 'Должность',
                  formatValue: (m: Member) => {
                    return m.job?.refAppointment?.label || m.job?.appointment || '-';
                  },
                  styles: { width: '20%' },
                },
                {
                  label: 'Ученая степень, звание',
                  formatValue: (m: Member) => {
                    const personHistory = getPersonHistory({ person: m.scientist });
                    return (
                      [personHistory.rank?.refRank.label, personHistory.degree?.refDegree?.label].filter(e => e).join(', ') || '-'
                    );
                  },
                  styles: { width: '20%' },
                },
                { label: 'Роль в совете', formatValue: (m: Member) => m.role?.label || '', styles: { width: '10%' } },
                { label: 'Дата ввода в состав', formatValue: (m: Member) => m.joinDate || '', styles: { width: '10%' } },
                { label: 'Дата вывода из состава', formatValue: (m: Member) => m.leaveDate || '', styles: { width: '10%' } },
                {
                  label: 'Действующий член',
                  formatValue: (m: Member) =>
                    !m.leaveDate || parse(m.leaveDate, formatStr, new Date()).getTime() > new Date().getTime(),
                  styles: { width: '10%' },
                },
              ]}
              specification={
                !isNotDissertationCouncil
                  ? {
                      mode: 'customComponent',
                      renderComponent: (row, onFieldChange) => (
                        <MemberFields
                          target={community}
                          entry={row}
                          onFieldChange={onFieldChange}
                          vaks={community?.vaks}
                          selectedCommunityType={community?.type}
                          isLocal={community?.isLocal}
                          code={community?.code}
                          affiliation={community?.affiliation}
                          roleOptions={roleOptions}
                          cooperationTypeOptions={cooperationTypeOptions}
                          isNotDissertationCouncil={isNotDissertationCouncil}
                          isJoinDateRequired={true}
                        />
                      ),
                      validation: {
                        checkIsValid: member => validateMember(community, member, communityTypes),
                      },
                    }
                  : {
                      mode: 'customComponent',
                      renderComponent: (row, onFieldChange) => (
                        <MemberFields
                          target={community}
                          entry={row}
                          onFieldChange={onFieldChange}
                          vaks={community?.vaks}
                          selectedCommunityType={community?.type}
                          isLocal={community?.isLocal}
                          code={community?.code || ''}
                          affiliation={community?.affiliation || null}
                          roleOptions={roleOptions}
                          cooperationTypeOptions={cooperationTypeOptions}
                          isNotDissertationCouncil={isNotDissertationCouncil}
                          isJoinDateRequired={true}
                        />
                      ),
                      validation: {
                        checkIsValid: member => validateMember(community, member, communityTypes),
                      },
                    }
              }
            />
          </Tab>
          <Tab
            title="Авторефераты и диссертации"
            isVisible={community?.type?.value === CommunityType.DISSERTATION_COUNCIL && !isMembersTabHidden}
          >
            <FormComponent.Description mode="warning">
              На данной вкладке отображаются диссертации и авторефераты Диссертационного совета. Добавить диссертацию или
              автореферат диссертации вы можете в пункте меню Публикации.
            </FormComponent.Description>
            <DataGrid
              specification={GetBaseDissertationPublicationList({
                communityId: communityId || '-2',
                workMode,
                hideActionButtons: true,
                hideFilters: true,
              })}
            />
          </Tab>
          <Tab title="Документы" isVisible={community?.type?.value !== CommunityType.MAGAZINE_REDACTION}>
            <Documents
              documents={community?.documents || []}
              setDocuments={handleDocumentsFieldChange}
              disabled={workMode === 'viewMode'}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(CommunityForm);
