import { Table } from 'types/models';
import { Filters } from './filters';

export const GetExpenseLogList: Table.Specification = {
  apiID: 'GetExpenseLogList',
  header: {
    firstLevel: {
      title: 'Реестр расходных документов',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
    },
  },
};
