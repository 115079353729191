import { Table } from 'types/models';

export const GetSystemUpdatesList: Table.Specification = {
  apiID: 'GetSystemUpdatesList',
  isFiltersHidden: true,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
    },
  },
};
