import React, { useCallback, useRef } from 'react';
import { setup } from 'bem-cn';
import { uploadAPI } from 'services/BackendAPI';

import { buttonIcons, IconButton } from 'components';

import { downloadFile } from 'utils/Helpers';
import { isSizeError, isExtensionError } from './validate';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { getFileSize } from 'utils/Helpers/fileValidators';
import { FileInfo } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('upload-file');

export type UploadFileParams = {
  size?: number;
  extensions?: string[];
};

const defaultParams: UploadFileParams = {
  size: 0,
  extensions: ['*'],
};

type Props = {
  file?: FileInfo;
  params?: UploadFileParams;
  onChange?(fileInfo: FileInfo): void;
  isDisabled?: boolean;
};

/**
 * File uploader/viewer
 *
 * @param file - (optional) File of common type FileInfo
 * @param params - (optional) File upload params (See defaultParams)
 *   @param params.size - (optional) File size on Megabyte (Mb) (any by default)
 *   @param params.extensions - (optional) Extensions without * or . (example - see defaultParams)
 * @param onChange - (optional) Callback on file uploaded/changed
 * @param isDisabled - (optional) Set is disabled file select
 * @returns JSX
 */
export const UploadFile = React.memo((props: Props) => {
  const { file = EMPTY_FILE, onChange = () => {}, isDisabled = false } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { userToken: token } = useAppDataContext();

  const params = {
    ...defaultParams,
    ...(props?.params as UploadFileParams),
  };

  const { extensions = [], size: paramSize } = params;

  const formats = extensions.map(x => `*.${x}`).join(', ');

  const onAddClick = useCallback(
    (e: any) => {
      e.preventDefault();

      const uploadedFile = e.target.files[0]; // Only one file (can be to multiple)

      if (!uploadedFile) return;

      if (!!paramSize && isSizeError({ file: uploadedFile, size: paramSize || 0 })) {
        showNotification({ message: 'Вы превысили допустимый размер файла', theme: 'danger' });
        return;
      }

      if (!extensions.includes('*') && isExtensionError({ file: uploadedFile, extensions })) {
        showNotification({
          message: `Выбран некорректный тип файла. Загрузите файл в формате ${formats}`,
          theme: 'danger',
        });
        return;
      }

      uploadAPI({
        file: uploadedFile,
        token,
        onSuccess: dataFileId => {
          onChange({ id: dataFileId, name: uploadedFile.name || '', size: uploadedFile.size || 0 } as FileInfo);
        },
        onFail: error => {
          showNotification({ message: `Ошибка: ${error}`, theme: 'danger' });
        },
      });

      e.target.value = null;
    },
    [paramSize, extensions, token, formats, onChange],
  );

  const onDeleteClick = useCallback(() => {
    if (!onChange) {
      return;
    }
    onChange(EMPTY_FILE);
  }, [onChange]);

  const addButtonClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return (
    <div className={b({ disabled: !!isDisabled })}>
      {file?.id ? (
        <>
          <div className={b('link')} onClick={() => downloadFile(file.id, token)}>
            {file.name} ({getFileSize(Number(file.size) || 0)})
          </div>
          <IconButton
            icons={buttonIcons.delete}
            title="Удалить файл"
            code="delete"
            isDisabled={!!isDisabled}
            onClick={onDeleteClick}
          />
        </>
      ) : (
        <label>
          <span>Добавить файл</span>
          <IconButton
            icons={buttonIcons.plus}
            title="Добавить файл"
            code="add"
            isDisabled={!!isDisabled}
            onClick={addButtonClick}
          />
          <input
            ref={inputRef}
            type="file"
            name="file"
            accept={extensions.map(x => `.${x}`).join(',')}
            onChange={onAddClick}
            disabled={!!isDisabled}
          />
        </label>
      )}
    </div>
  );
});
