import React from 'react';
import { block } from 'bem-cn';

import { Tooltip } from 'components';

const b = block('departments-header');

type Props = {
  hint?: string;
  departments?: string;
};

const Departments = ({ departments, hint }: Props) => (
  <div className={b()}>
    {'Подразделения: '}
    {hint && <Tooltip text={hint} />}
    {` ${departments || 'не указаны'}`}
  </div>
);

export { Departments };
