import React, { useState, useCallback, useMemo } from 'react';
import { block } from 'bem-cn';

import { IconButtonProps, ConfirmPopup, Toolbar, buttonIcons } from 'components';

import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { Table, Button } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { useAppDataContext } from 'features/AppData/context';
import { ApproveEvent } from 'features/Form/looks/event/views/ApproveEvent';
import { EventTypeCode } from 'types/models/Event';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import * as modalTemplates from './modalTemplates';

type WrapperProps = {
  buttonsToHide?: Button.Button[];
  eventType: EventTypeCode;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

const b = block('toolbar');

export const LeftPanelForThirdLevelWrapper = ({ buttonsToHide, eventType }: WrapperProps) =>
  function LeftPanelForThirdLevel({ tableState, customState }: Props) {
    const { openDuplicatesForm } = useController();
    const { userPermission } = useAppDataContext();

    const tableStreams = useLocalTableStreams();
    const [selectedRow] = tableState.selectedRows;

    const isDeletePermitted = useMemo(() => {
      if (!selectedRow) return false;
      const status = selectedRow['id:status'] as 'ADDED' | 'APPROVED';
      return isHasPermission(userPermission, Permits[`${eventType}_DELETE_${status}`] as keyof Permits);
    }, [selectedRow, userPermission]);

    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
    const [nextPublicationName, setNextPublicationName] = useState('');
    const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);

    const handleOpenApprovePopup = useCallback(() => {
      setIsApprovePopupOpen(true);
    }, []);

    const handleCloseApprovePopup = useCallback(() => {
      setIsApprovePopupOpen(false);
    }, []);

    const handleCloseDeleteConfirmPopup = useCallback(() => {
      setIsDeleteConfirmPopupOpen(false);
    }, []);

    const handleConfirmDeleteConfirmPopup = useCallback(() => {
      tableStreams.deleteRow.push({
        deleteRowId: tableState.selectedRows[0].id,
        command: 'DeleteEntity',
        deletedItemPropName: 'Event',
      });
      handleCloseDeleteConfirmPopup();
    }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

    const handleDeleteButtonClick = useCallback(() => {
      setIsDeleteConfirmPopupOpen(true);
    }, []);

    const handleAddButtonClick = useCallback(() => {
      setNextPublicationName('');
      openDuplicatesForm();
    }, [openDuplicatesForm]);
    const handleTemplateCloseAddForm = useCallback(() => {
      setIsAddFormOpen(false);
    }, []);
    const handleEditButtonClick = useCallback(() => {
      setIsEditFormOpen(true);
    }, []);
    const handleTemplateCloseEditForm = useCallback(() => {
      setIsEditFormOpen(false);
    }, []);
    const handleContinueStep = useCallback((searchText: string) => {
      setNextPublicationName(searchText);
      setIsAddFormOpen(true);
    }, []);

    const buttons: IconButtonProps[] = useMemo(
      () => [
        {
          icons: buttonIcons.plus,
          title: 'Добавить',
          code: 'add',
          // isDisabled: !isAddPermitted,
          isHidden: (buttonsToHide || []).includes('add'),
          permissionName: Permits[`${eventType}_ADD`],
          profilePermissionName: Permits[`${eventType}_ADD`],
          onClick: handleAddButtonClick,
        },
        {
          icons: buttonIcons.edit,
          title: 'Редактировать',
          code: 'edit',
          isDisabled: !selectedRow,
          isHidden: (buttonsToHide || []).includes('edit'),
          permissionName: Permits[`${eventType}_EDIT_ADDED`],
          profilePermissionName: Permits[`${eventType}_EDIT_ADDED`],
          onClick: handleEditButtonClick,
        },
        {
          icons: buttonIcons.delete,
          title: 'Удалить',
          code: 'remove',
          isDisabled: !isDeletePermitted,
          isHidden: (buttonsToHide || []).includes('delete'),
          permissionName: [Permits[`${eventType}_DELETE_ADDED`], Permits[`${eventType}_DELETE_APPROVED`]],
          profilePermissionName: [Permits[`${eventType}_DELETE_ADDED`], Permits[`${eventType}_DELETE_APPROVED`]],
          onClick: handleDeleteButtonClick,
        },
        {
          icons: buttonIcons.approve,
          title: 'Утвердить',
          code: 'approve',
          isDisabled: !selectedRow,
          isHidden: (buttonsToHide || []).includes('approve'),
          permissionName: Permits[`${eventType}_APPROVE`],
          profilePermissionName: Permits[`${eventType}_APPROVE`],
          onClick: handleOpenApprovePopup,
        },
      ],
      [
        handleAddButtonClick,
        handleEditButtonClick,
        isDeletePermitted,
        handleDeleteButtonClick,
        handleOpenApprovePopup,
        selectedRow,
      ],
    );

    return (
      <>
        {!(isAddFormOpen || isEditFormOpen) && (
          <div className={b('hidden')}>
            <SuchLikeEvent
              mode="add"
              isSearchable
              handleContinueStep={handleContinueStep}
              command="RefEventFeedlineMessage"
              eventTypeCode={eventType}
            />
          </div>
        )}
        {isEditFormOpen && (
          <modalTemplates.EventEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            customState={customState}
            withHeaderId={false}
          />
        )}
        {isAddFormOpen && (
          <modalTemplates.EventAddTemplate.Component
            isOpen={isAddFormOpen}
            onClose={handleTemplateCloseAddForm}
            relatedTableState={tableState}
            name={nextPublicationName}
            customState={customState}
          />
        )}
        {selectedRow && isDeleteConfirmPopupOpen && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранное мероприятие? (${selectedRow.completeLabel})`}
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            onConfirm={handleConfirmDeleteConfirmPopup}
            okButtonText="Да"
          />
        )}
        {isApprovePopupOpen && (
          <ApproveEvent
            typeCode={EventTypes.EVENT_CONFERENCE.code}
            id={tableState.selectedRows[0]?.id || ''}
            isOpen={isApprovePopupOpen}
            onClose={handleCloseApprovePopup}
          />
        )}

        <Toolbar buttons={buttons} />
      </>
    );
  };
