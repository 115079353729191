import React, { memo, useCallback, useMemo, useState } from 'react';

import { buttonIcons, Permission, IconButtonProps, ConfirmPopup, Toolbar } from 'components';

import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Permits } from 'utils/Permissions';
import { streams as panelStreams } from '../streams';
import { State } from '../makeUseCustomController';
import { showNotification } from 'features/Notifications';

type Props = {
  customState: State;
};

function LeftPanelForThirdLevel({ customState }: Props) {
  const { isChangeFormOpen, selectedRow, publicationType, citationSystemType, workMode } = customState;
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleSaveButtonClick = useCallback(() => {
    panelStreams.toggleChangeForm.push();
  }, []);

  const handleEditButtonClick = useCallback(() => {
    panelStreams.toggleSettingEditing.push(true);
    panelStreams.toggleChangeForm.push();
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    if (selectedRow) {
      panelStreams.deletePublicationCitationSystem.push({ publicationCitationSystemID: selectedRow.id });
      handleCloseDeleteConfirmPopup();
    }
  }, [handleCloseDeleteConfirmPopup, selectedRow]);

  const handleOpenLinkButtonClick = useCallback(() => {
    if (!selectedRow) {
      showNotification({
        message: 'Выберите запись для открытия страницы во внешнем источнике',
        theme: 'danger',
      });
      return;
    }
    if (selectedRow.Url) {
      window?.open(selectedRow.Url, '_blank')?.focus();
    } else {
      showNotification({
        message: 'Для выбранной записи не указан URL страницы',
        theme: 'danger',
      });
    }
  }, [selectedRow]);

  const getPermission = useCallback((type: string) => {
    const mapPublicationTypes = {
      [PublicationTypes.COMPILATION_CONFERENCE.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.COMPILATION_OTHER.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.MAGAZINE.code]: Permits.MAGAZINE_CITATION_SYSTEM_EDIT,
    };
    return mapPublicationTypes[type as keyof typeof mapPublicationTypes] || Permits.PUBLICATION_CITATION_SYSTEM_EDIT;
  }, []);

  const buttonsPermissions = useMemo<IconButtonProps[]>(
    () => [
      ...(citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID'
        ? [
            {
              icons: buttonIcons.plus,
              title: 'Добавить',
              code: 'add',
              isDisabled: workMode === 'viewMode' || isChangeFormOpen,
              onClick: handleSaveButtonClick,
            },
          ]
        : []),
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: !selectedRow || workMode === 'viewMode' || isChangeFormOpen,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: !selectedRow || workMode === 'viewMode' || isChangeFormOpen,
        onClick: handleDeleteButtonClick,
      },
    ],
    [
      citationSystemType,
      handleDeleteButtonClick,
      handleEditButtonClick,
      handleSaveButtonClick,
      isChangeFormOpen,
      selectedRow,
      workMode,
    ],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.link,
        title: 'Открыть во внешнем источнике',
        code: 'link',
        isDisabled: selectedRow === undefined,
        onClick: handleOpenLinkButtonClick,
      },
    ],
    [selectedRow, handleOpenLinkButtonClick],
  );

  return (
    <>
      <Permission name={getPermission(publicationType)}>
        <Toolbar buttons={buttonsPermissions} />
      </Permission>
      <Toolbar buttons={buttons} />

      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранную запись?"
        isOpen={isDeleteConfirmPopupOpen}
        onClose={handleCloseDeleteConfirmPopup}
        onConfirm={handleConfirmDeleteConfirmPopup}
        okButtonText="Да"
      />
    </>
  );
}

export const Component = memo(LeftPanelForThirdLevel);
