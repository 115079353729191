import { formatToRequest } from 'features/Form/looks/event/EventFormConference/setup/formatToRequest';
import { Event } from 'types/models';
import { EventProject } from 'types/models/Event';

export function mockToSave(id: string, eventData: Event.Data, departments?: Event.Department[], projects?: EventProject[]) {
  return formatToRequest({
    eventId: id,
    attractedFinancing: eventData?.attractedFinancing || '',
    ...eventData,
    departments: departments || [],
    projects: projects || [],
    formFields: {
      isStudent: { value: eventData.fields.isStudent, isValid: true, title: '', onChange: () => {} },
      isInSummaryPlan: { value: eventData.fields.isInSummaryPlan, isValid: true, title: '', onChange: () => {} },
      isCompilationPlanned: { value: eventData.fields.isCompilationPlanned, isValid: true, title: '', onChange: () => {} },
      isMagazinePlanned: { value: eventData.fields.isMagazinePlanned, isValid: true, title: '', onChange: () => {} },
      site: { value: eventData.fields.site, isValid: true, title: '', onChange: () => {} },
      city: { value: eventData.fields.city, isValid: true, title: '', onChange: () => {} },
      address: { value: eventData.fields.address, isValid: true, title: '', onChange: () => {} },
      contact: { value: eventData.fields.contact, isValid: true, title: '', onChange: () => {} },
      keyWords: { value: eventData.fields.keyWords, isValid: true, title: '', onChange: () => {} },
      name: { value: eventData.fields.name, isValid: true, title: '', onChange: () => {} },
      memberCountPlan: { value: eventData.fields.memberCountPlan, isValid: true, title: '', onChange: () => {} },
      memberCountFact: { value: eventData.fields.memberCountFact, isValid: true, title: '', onChange: () => {} },
      description: { value: eventData.fields.description, isValid: true, title: '', onChange: () => {} },
      startDate: { value: eventData.fields.startDate, isValid: true, title: '', onChange: () => {} },
      orderNumber: { value: eventData.fields.orderNumber, isValid: true, title: '', onChange: () => {} },
      orderDate: { value: eventData.fields.orderDate, isValid: true, title: '', onChange: () => {} },
      endDate: { value: eventData.fields.endDate, isValid: true, title: '', onChange: () => {} },
      eventSeries: { value: { id: eventData.fields.eventSeriesId }, isValid: true, title: '', onChange: () => {} },
      eventStatus: { value: { id: eventData.fields.eventStatusId }, isValid: true, title: '', onChange: () => {} },
      conferenceType: { value: { id: eventData.fields.conferenceTypeId }, isValid: true, title: '', onChange: () => {} },
      country: { value: { id: eventData.fields.countryId }, isValid: true, title: '', onChange: () => {} },
      form: { value: eventData.fields.format, isValid: true, title: '', onChange: () => {} },
    },
  });
}
