import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { convertTenderProtocol } from './converters';
import { Settings } from 'types/models/common';
import { convertTenderProtocolToXML } from 'services/BackendAPI/configurations/tenderProtocols/requestConverters';
import { TenderProtocol } from 'types/models/TenderProtocol';

export const tenderProtocolConfigurations = {
  SaveTenderProtocol: makeBackendAPIConfiguration({
    id: 'SaveTenderProtocol',
    endpoint: '/msa/service/commands/SaveTenderProtocol',
    convertInputToXMLElement: (inputData: TenderProtocol) => ({
      Request: {
        _attr: {
          commandName: 'SaveTenderProtocol',
        },
        TenderProtocol: { ...convertTenderProtocolToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetTenderProtocol: makeBackendAPIConfiguration({
    id: 'GetTenderProtocol',
    endpoint: '/msa/service/commands/GetTenderProtocol',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetTenderProtocol',
          },
          TenderProtocol: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertTenderProtocol(response.Response.TenderProtocol, settings),
  }),
};
