import React, { memo, useMemo, useState } from 'react';

import { FormComponent, ReferenceItem, TextGridSelect } from 'components';

import { Parameter, Table } from 'types/models';
import { Item } from 'types/models/common';
import { GetProjectCodeListByProject, GetFullScienceProjectList } from 'features/Table/specifications';
import { Arguments } from 'features/Table/specifications/GetProjectCodeListByProject';
import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps, CustomSelectSpecification } from 'features/BuildReportPopup/models';

const ProjectAndCode = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const [project, setProject] = useState<Item<string> | null>(null);

  const specification: CustomSelectSpecification<object, Arguments> = {
    modalTableSpecification: GetProjectCodeListByProject({ projectId: project?.value || '' }),
    relationTableModalTitle: 'Справочник "Шифры проекта"',
    modalTableRowConverter: (row: Table.Entry): ReferenceItem => ({
      id: row.id,
      label: row.code,
    }),
  };

  const currentParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameterName, parameters],
  );

  return (
    <>
      <FormComponent.Field label="Проект" isRequired={currentParameter?.required}>
        <TextGridSelect<Item<string>>
          value={project}
          onChange={setProject}
          specification={GetFullScienceProjectList()}
          settings={{
            mode: 'selectTableRow',
            tableRowConverter: x => ({ value: x.id, label: x.name }),
          }}
          valueFormatter={x => x.label}
          title="Типы организаций"
        />
      </FormComponent.Field>
      <DefaultParameterList
        parameterName={parameterName}
        setParameterValueByName={setParameterValueByName}
        parameters={parameters}
        parentId={project?.value}
        disabled={!project?.value}
        isLabelHidden
        enumProps={{
          fieldLabel: 'Шифр',
          listEditTitle: 'Справочник "Шифры проекта"',
          mode: { component: 'CustomSelect', specification },
        }}
      />
    </>
  );
};

const Component = memo(ProjectAndCode);

export { Component as ProjectAndCode };
