import React, { ReactElement, useMemo } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-actions');

type Props = {
  children: ReactElement | false | null | (ReactElement | false | null)[];
};

export function Actions(props: Props) {
  const { children } = props;
  const fields = useMemo(() => (Array.isArray(children) ? children : [children]), [children]);

  return (
    <div className={b()}>
      {fields
        .filter(x => x)
        .map((item, index) => (
          <div key={index} className={b('item')}>
            {item}
          </div>
        ))}
    </div>
  );
}
