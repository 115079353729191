import { DataGrid } from 'features/Table';
import { GetPersonalAllSecurityDocumentList } from 'features/Table/specifications';
import React from 'react';

export function UniversityDocuments() {
  return (
    <DataGrid
      specification={GetPersonalAllSecurityDocumentList({ hasToolbarButtonMode: true, isPersonalMode: true })}
      hasPersonalMode
    />
  );
}
