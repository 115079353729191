import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController } from './makeUseCustomController';

type Props = {
  projectId?: string | null;
  isHideFirstLevel?: boolean;
  isWithTemplatesPanel?: boolean;
  isFiltersHidden?: boolean;
  hasSelectButton?: boolean;
  projectSelectable?: boolean;
};

type Arguments = {
  projectId?: string | null;
};

export function GetProjectPerformerOrderList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectPerformerOrderList',
    requestData: args,
    isFiltersHidden: args?.isFiltersHidden,
    header: {
      firstLevel: {
        title: 'Приказы по научным коллективам проектов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: args?.hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController({
      projectId: args?.projectId || undefined,
      projectSelectable: args?.projectSelectable,
    }),
  };
}
