import { useCallback, useEffect, useState } from 'react';
import { Option } from 'components';
import { BOOLEAN_DEBOUNCE_TIME } from 'features/Table/helpers';
import { useDebouncedCallback } from 'use-debounce';
import { SingleValue } from 'react-select';
import { Props as ComponentProps } from './index';
import { defaultOption, options } from './helpers';

type Props = object & ComponentProps;
const useController = ({ setColumnFilter, value }: Props) => {
  const [selectedValue, setSelectedValue] = useState<Option>(defaultOption);

  useEffect(() => {
    const selectedKey = value;
    const preparedSelectedValue = options.find(({ value: optionValue }) => selectedKey === optionValue);

    setSelectedValue(preparedSelectedValue || defaultOption);
  }, [value]);

  const booleanDebounce = useDebouncedCallback((selectedValueToUpdate: Option) => {
    setColumnFilter(selectedValueToUpdate?.value ?? '');
  }, BOOLEAN_DEBOUNCE_TIME);

  const onFieldChange = useCallback(
    (singleValue: SingleValue<Option<string>>) => {
      const isEqualValue = singleValue?.value === selectedValue?.value;
      const nextValue = singleValue ? { value: singleValue.value, label: singleValue.label } : defaultOption;
      setSelectedValue(nextValue);
      if (!isEqualValue) {
        booleanDebounce(nextValue);
      }
    },
    [booleanDebounce, selectedValue?.value],
  );

  return {
    selectedValue,
    onFieldChange,
  };
};

export default useController;
