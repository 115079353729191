import { RidAuthor } from 'types/models';
import { convertDocumentToRequest, getMaybeField } from 'services/BackendAPI/configurations/commonConverters';
import { Department } from 'types/models/Department';
import { RntdSaveInput } from './types';
import { getHistoricalSliceXML } from '../author/requestConverters';

export function convertRntdToXML(r: RntdSaveInput) {
  return {
    _attr: r.id ? { id: r.id } : {},
    Code: r.code,
    Name: r.name,
    Type: { _attr: { id: r.type?.id } },
    Project: { _attr: { id: r.project?.id } },
    ...getMaybeField('Description', r.description),
    ...getMaybeField('Scope', r.scope),
    ...getMaybeField('KeyWord', r.keyWords),
    ...getMaybeField('CommercializationProtocol', r.commercializationProtocol),
    ...getMaybeField('CommercializationDate', r.commercializationDate),
    ...(r.commercializationDecision?.id ? { CommercializationDecision: { _attr: { id: r.commercializationDecision.id } } } : {}),
    ...getMaybeField('SelfProductionNumber', r.selfProductionNumber),
    ...getMaybeField('SelfProductionDate', r.selfProductionDate),
    ...getMaybeField('SelfProductionNote', r.selfProductionNote),
    ...getMaybeField('FctekProtocol', r.fctekProtocol),
    ...getMaybeField('FctekDate', r.fctekDate),
    ...(r.fctekDecision?.id ? { FctekDecision: { _attr: { id: r.fctekDecision.id } } } : {}),
    Subjects:
      r.subjects && r.subjects.length
        ? {
            Subject: r.subjects.map(s => ({
              _attr: { id: s.id },
            })),
          }
        : {},
    Authors: r.authors && r.authors.length ? { Author: r.authors.map(convertAuthorToXML) } : {},
    Departments:
      r.departments && r.departments.length
        ? {
            Department: r.departments.map(convertDepartmentToXML),
          }
        : {},
    SecurityDocuments:
      r.securityDocuments && r.securityDocuments.length
        ? {
            SecurityDocument: r.securityDocuments.map(d => ({
              _attr: { id: d.id },
            })),
          }
        : {},
    Participations:
      r.participations && r.participations.length
        ? {
            Author: r.participations.map(a => ({
              _attr: { id: a.id },
            })),
          }
        : {},
    Documents:
      r.documents && r.documents.length
        ? { Document: r.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)) }
        : {},
  };
}

export function convertAuthorToXML(author: RidAuthor) {
  return {
    _attr: {
      ...(author.id ? { id: author.id } : {}),
    },
    ...getHistoricalSliceXML(author),
    ...(author.person?.id ? { Person: { _attr: { id: author.person.id } } } : {}),
  };
}
export function convertDepartmentToXML(a: Department) {
  return {
    _attr: {
      ...(a.id ? { id: a.id } : {}),
    },
    ...(a.governance?.id ? { Governance: { _attr: { id: a.governance?.id } } } : {}),
    ...(a.unit?.id ? { Unit: { _attr: { id: a.unit?.id } } } : {}),
  };
}
