import React from 'react';

import { FormComponent, Reference, TextInput, TextInputMode } from 'components';

import { Event } from 'types/models';
import useController from './controller';

type Props = {
  document: Event.MemberCountAnother | null;
  onChange(row: Event.MemberCountAnother): Promise<void>;
  actualRef: string;
  isOpenedCollapsible: boolean;
  hasShortForm?: boolean;
};
const MemberCountAnotherForm = ({ document, onChange, actualRef, isOpenedCollapsible, hasShortForm }: Props) => {
  const {
    handleAllField,
    handleProfessorsField,
    handleStudentsField,
    handleCityField,
    onChangeDepartment,
    onChangeOrganization,
    onChangeCountry,
  } = useController({
    onChange,
    document,
    actualRef,
    isOpenedCollapsible,
  });

  return (
    <FormComponent.Wrapper>
      <>
        {document && (
          <>
            <FormComponent.Line hasGap lineSize="doublePadded">
              <FormComponent.Field isRequired label="Всего участников">
                <TextInput
                  mode={TextInputMode.number}
                  value={document.amount}
                  onChange={handleAllField}
                  settings={{ decimal: false }}
                />
              </FormComponent.Field>
              <>
                {!hasShortForm && (
                  <FormComponent.Field isRequired labelSize="fit" label="В том числе, профессоров">
                    <TextInput
                      mode={TextInputMode.number}
                      value={document.professors}
                      onChange={handleProfessorsField}
                      settings={{ decimal: false }}
                    />
                  </FormComponent.Field>
                )}
              </>
              <>
                {!hasShortForm && (
                  <FormComponent.Field isRequired labelSize="fit" label="В том числе, студентов">
                    <TextInput
                      mode={TextInputMode.number}
                      value={document.students}
                      onChange={handleStudentsField}
                      settings={{ decimal: false }}
                    />
                  </FormComponent.Field>
                )}
              </>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Страна" isRequired>
                <Reference
                  disabled={actualRef !== 'FOREIGN'}
                  value={document.country}
                  relationTableModalTitle='Справочник "Страны"'
                  theme="big"
                  refButtonTitle='Справочник "Страны"'
                  name="RefCountry"
                  onChange={onChangeCountry}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="Город" isRequired>
                <TextInput
                  value={document.city}
                  onChange={handleCityField}
                  isDisabled={actualRef === 'DEPARTMENT' || actualRef === 'LOCAL'}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Организация" isRequired>
                <Reference
                  disabled={actualRef === 'DEPARTMENT'}
                  value={document.enterprise}
                  relationTableModalTitle="Организации"
                  theme="big"
                  refButtonTitle="Организации"
                  name="RefEnterprise"
                  onChange={onChangeOrganization}
                />
              </FormComponent.Field>
            </FormComponent.Line>

            {actualRef === 'DEPARTMENT' && (
              <FormComponent.Line>
                <FormComponent.Field label="Подразделение" isRequired>
                  <Reference
                    disabled={false}
                    value={document.department ?? null}
                    relationTableModalTitle='Справочник "Подразделения"'
                    theme="big"
                    refButtonTitle='Справочник "Подразделения"'
                    name="RefDepartment"
                    onChange={onChangeDepartment}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
          </>
        )}
      </>
    </FormComponent.Wrapper>
  );
};

export { MemberCountAnotherForm };
