import React from 'react';

import { TextInput, TextInputMode, FormComponent } from 'components';

import { Tender } from 'types/models';
import { useController } from './controller';

type Props = {
  lot: Tender.Lot | null;
  lotPrice: Tender.LotPrice | null;
  onLotPriceChange(row: Tender.LotPrice): void;
};

function LotPriceInputs({ lot, lotPrice, onLotPriceChange }: Props) {
  const { guessLotYear, handleChange } = useController({
    lot,
    lotPrice,
    onLotPriceChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Год" isRequired>
          <TextInput mode={TextInputMode.year} value={lotPrice?.year ?? guessLotYear() ?? ''} onChange={handleChange('year')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма">
          <TextInput
            mode={TextInputMode.number}
            value={lotPrice?.amount ? lotPrice.amount?.toString() : '0'}
            onChange={handleChange('amount')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { LotPriceInputs };
