import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';

export function useController() {
  const { isReportOpen, onReportClose, currentReport, handleSetCurrentReport } = useReportsHook({ reports: [] });

  const [projectActId, setProjectActId] = useState<string>('');

  const [isProjectNotSavedPopupOpen, setIsProjectNotSavedPopupOpen] = useState<boolean>(false);

  const onClickPrint = useCallback(
    row => {
      if (row.id) {
        setProjectActId(row.id);
        handleSetCurrentReport({ name: Reports.ProjectAct.caption, value: Reports.ProjectAct.name });
      } else {
        setIsProjectNotSavedPopupOpen(true);
      }
    },
    [handleSetCurrentReport],
  );

  const button = useMemo<IconButtonProps>(() => {
    return {
      icons: buttonIcons.saveAndContinue,
      title: 'Сохранить и продолжить',
      isDisabled: false,
      onClick: () => {},
    };
  }, []);

  return {
    onClickPrint,
    button,
    isProjectNotSavedPopupOpen,
    setIsProjectNotSavedPopupOpen,
    projectActId,
    setProjectActId,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
