import React, { useMemo } from 'react';

import { Modal } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { SuchLikeMode } from 'types/models/SuchLikePublication';
import { Component as SuchLikePublicationComponent } from './component';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants/TextConstants';
import { useController } from './controller';

type Props = {
  title?: string;
  isSearchable?: boolean;
  mode?: SuchLikeMode;
  componentId: string;
  handleContinueStep?: (searchText: string) => void;
  handleCloseOuterForm?: () => void;
};

export const SuchLikePublicationComponentContainer = ({
  title,
  isSearchable,
  componentId,
  handleContinueStep,
  mode,
  handleCloseOuterForm,
}: Props) => {
  const {
    isOpen,
    onClose,
    isMessagePopupOpen,
    suchLikePublicationList,
    onMessagePopupOpen,
    publicationMessage,
    updatePublicationMessage,
    isConfirmPopupOpen,
    updateIsConfirmPopupOpen,
    selectedRowIndex,
    setSelectedRowIndex,
    category,
    isWarningPopupOpen,
    setIsWarningPopupOpen,
    publicationMessageType,
    setPublicationMessageType,
    onConfirmClick,
    onConfirmWithoutMessageClick,
    isLoading,
    sendEmptyMessageWarning,
    handleCloseAttachPopup,
    isAttachPopupOpened,
    handleAttachPublication,
  } = useController({ handleCloseOuterForm, componentId });

  const modalTitle = useMemo(() => title || (mode === 'add' ? 'Поиск дубликатов публикаций' : 'Похожие публикации'), [
    title,
    mode,
  ]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title={modalTitle} size="large">
        <SuchLikePublicationComponent
          componentId={componentId}
          isSearchableComponent={isSearchable}
          suchLikePublicationList={suchLikePublicationList}
          onClose={onClose}
          handleAttachPublication={handleAttachPublication}
          onMessagePopupOpen={onMessagePopupOpen}
          isConfirmPopupOpen={isConfirmPopupOpen}
          updateIsConfirmPopupOpen={updateIsConfirmPopupOpen}
          selectedRowIndex={selectedRowIndex}
          setSelectedRowIndex={setSelectedRowIndex}
          category={category}
          handleCloseAttachPopup={handleCloseAttachPopup}
          isWarningPopupOpen={isWarningPopupOpen}
          setIsWarningPopupOpen={setIsWarningPopupOpen}
          customContinueHandle={handleContinueStep}
          isLoading={isLoading}
          isAttachPopupOpened={isAttachPopupOpened}
          sendEmptyMessageWarning={sendEmptyMessageWarning}
          mode={mode}
        />
      </Modal>
      <AdministratorMessagePopup
        title={ADD_PUB_FEEDBACK_LABEL}
        isOpen={isMessagePopupOpen}
        publicationMessage={publicationMessage}
        updatePublicationMessage={updatePublicationMessage}
        publicationMessageType={publicationMessageType}
        setPublicationMessageType={setPublicationMessageType}
        onConfirm={onConfirmClick}
        onConfirmWithoutMessage={onConfirmWithoutMessageClick}
      />
    </>
  );
};
