import { Table } from 'types/models';
import { Form } from './Form/Form';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Toolbar } from './Toolbar';

type Props = {
  hasSelectButton?: boolean;
};

export function GetPartnerMipList(props?: Props): Table.Specification<State, object> {
  const { hasSelectButton } = props || {};
  return {
    apiID: 'GetPartnerMipList',
    header: {
      firstLevel: {
        title: 'Малые инновационные предприятия, созданные с участием вуза',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
        hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController('GetPartnerMipList'),
    NeighborTableComponent: Form,
  };
}
