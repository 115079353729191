import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

const MagazineAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id } = props;
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    id,
    apiID: 'GetPublication',
    template: 'MagazineForm',
    type: 'Magazine',
    relatedTableState,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineAddTemplate);
