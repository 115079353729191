import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'actual',
        list: [
          {
            label: 'Актуальные поручения',
            value: 'actual',
            hint: 'Отображаются актуальные поручения',
          },
          {
            label: 'Все поручения',
            value: 'all',
            hint: 'Показаны все поручения',
          },
        ],
        value: 'actual',
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Показывать участия за год',
        checked: true,
      },
    ],
    'personal',
  );
});
