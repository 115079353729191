import React, { memo } from 'react';
import block from 'bem-cn';

import { FormComponent, SectionTitle } from 'components';

import { Table as T } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { GetPublicationCitationSystemIndexList, GetPublicationCitationSystemList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';

import 'features/Form/looks/publication/style.scss';

const b = block('database-indexing');

type Props = {
  relatedTableState: T.State | undefined;
  citationSystems: CitationSystem[];
  publicationId: string | null;
  publicationType: string;
  workMode: T.WorkMode;
  addCitationSystem: (citationSystem: CitationSystem) => void;
  editCitationSystem: (citationSystem: CitationSystem) => void;
  removeCitationSystem: (citationSystemId: string) => void;
  sourceId?: string;
  releaseId?: string;
  topMessage: string;
  setTopMessage: (topMessage: string) => void;
};

const DatabaseIndexing = (props: Props) => {
  const {
    relatedTableState,
    citationSystems,
    publicationId,
    publicationType,
    workMode,
    sourceId,
    releaseId,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    setTopMessage,
  } = props;

  return (
    <div className={b()}>
      <FormComponent.Line>
        {!!relatedTableState && !!publicationType && (
          <>
            <SectionTitle
              title="Индексация в базах цитирования и показатели издания"
              // eslint-disable-next-line max-len
              tooltip="Индексация в базах цитирования и показатели издания содержит сведения об индексации публикации и ее источниках в базах цитирования: Столбец Наименование БЦ содержит список баз цитирования в которых индексируется источник публикаций в год издания, в столбце Показатели издания - его наукометрические показатели."
            />

            <DataGrid
              specification={GetPublicationCitationSystemList({
                templatesTableDependencies: {
                  relatedTableAPIID: 'GetPublicationExtendedList',
                  relatedTableState,
                  relatedRecordId: publicationId || undefined,
                  isFiltersHidden: true,
                },
                publicationType,
                addCitationSystem,
                removeCitationSystem,
                editCitationSystem,
                workMode,
                sourceId,
                releaseId,
                setTopMessage,
              })}
            />
          </>
        )}
      </FormComponent.Line>
      <FormComponent.Line>
        {!!relatedTableState && !!publicationType && (
          <>
            <SectionTitle title="Наукометрические показатели" />

            <FormComponent.Description mode="warning">
              Невозможно добавить значение наукометрических показателей для публикации без Идентификатора БЦ. Добавьте сведения о
              публикации из базы цитирования: Идентификатор в БЦ, Тип публикации и Индексируемые ключевые слова в таблицу
              "Индексация в базах цитирования и показатели издания" с помощью кнопки "Редактировать"
            </FormComponent.Description>

            <DataGrid
              specification={GetPublicationCitationSystemIndexList(
                {
                  relatedTableAPIID: 'GetPublicationExtendedList',
                  relatedTableState,
                  relatedRecordId: publicationId || undefined,
                  isFiltersHidden: true,
                },
                {
                  citationSystems,
                  publicationId,
                  publicationType,
                  workMode,
                },
              )}
            />
          </>
        )}
      </FormComponent.Line>
    </div>
  );
};

export const Component = memo(DatabaseIndexing);
