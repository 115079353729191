import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import * as modalTemplates from './modalTemplates';
import { useController } from './controller';
import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isViewFormOpen,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsViewFormOpen,
  } = useController(tableState);
  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.ProtocolCommissionViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.ProtocolCommissionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.ProtocolCommissionAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную запись? (${tableState.selectedRows[0]?.id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
