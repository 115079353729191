import React, { memo } from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { useController } from './controller';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants/TextConstants';

type WrapperProps = {
  workMode: Table.WorkMode;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

export const ToolbarWrapper = ({ workMode }: WrapperProps) =>
  memo(({ tableState, customState }: Props) => {
    const {
      handleFormSubmit,
      handleTemplateCloseAddForm,
      isFormOpen,
      publicationMessage,
      handleMessageChange,
      messageLabel,
      handleMessageLabelChange,
      isDeleteButtonDisabled,
      isDeleteConfirmPopupOpen,
      handleCloseDeleteConfirmPopup,
      handleConfirmDeleteConfirmPopup,
      buttons,
    } = useController({ tableState, customState, workMode });
    return (
      <>
        <AdministratorMessagePopup
          title={ADD_PUB_FEEDBACK_LABEL}
          onConfirm={handleFormSubmit}
          onConfirmWithoutMessage={handleTemplateCloseAddForm}
          isOpen={isFormOpen}
          publicationMessage={publicationMessage.message}
          updatePublicationMessage={handleMessageChange}
          publicationMessageType={messageLabel}
          setPublicationMessageType={handleMessageLabelChange}
          relationTableModalTitle="Сообщения журнала"
          cancelButtonText="Отмена"
          submitButtonText="Сохранить"
          command="RefForeignSecurityDocumentFeedlineMessage"
        />
        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <ConfirmPopup
            title="Предупреждение"
            text="Вы уверены что хотите удалить выбранное сообщение?"
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            onConfirm={handleConfirmDeleteConfirmPopup}
            okButtonText="Да"
            resetButtonText="Нет"
          />
        )}

        <Toolbar buttons={buttons} />
      </>
    );
  });
