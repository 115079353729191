import { useCallback } from 'react';

import { MobileRequest } from 'types/models';
import * as R from 'ramda';

type Props = {
  entry: MobileRequest.EstimatePosition | null;
  onFieldChange(row: MobileRequest.EstimatePosition | null): void;
};

type Fields = keyof MobileRequest.EstimatePosition;

export function useController({ entry, onFieldChange }: Props) {
  const handleKindConsumptionChange = useCallback(
    (e: MobileRequest.KindConsumption) => {
      const fieldName: Fields = 'kindConsumption';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  const handleAmountChange = useCallback(
    (e: string) => {
      const fieldName: Fields = 'amount';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  const handleNoteChange = useCallback(
    (e: string) => {
      const fieldName: Fields = 'note';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  return {
    handleKindConsumptionChange,
    handleAmountChange,
    handleNoteChange,
  };
}
