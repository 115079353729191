import { Parameter, RadioValue } from 'types/models';

const queryBuilder = (projectTypesParameter: Parameter) => {
  const value = projectTypesParameter.value as RadioValue;
  if (value) {
    const query = `&${projectTypesParameter.name}=${value}`;
    return query;
  }

  return '';
};

export default queryBuilder;
