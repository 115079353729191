import React, { useCallback, useMemo } from 'react';

import { buttonIcons, CustomSelect, FormComponent, IconButtonProps, ListEdit } from 'components';

import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { ParticipationProject, ParticipationTypeCode } from 'types/models/Participation';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';

type Props = {
  workMode: Table.WorkMode;
  projects: ParticipationProject[];
  setLocalProjects: (rows: ParticipationProject[]) => void;
  setLocalProjectsAccept: (id: string) => void;
  setLocalProjectsCancelAcception: (id: string) => void;
  relatedTableState: Table.State | undefined;
  participationTypeCode: ParticipationTypeCode;
  isSingle?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  selectTitle?: string | null;
  tooltipText?: string | undefined;
  isMaximized?: boolean;
  tooltipProjectText?: string;
};

function Projects({
  participationTypeCode,
  workMode,
  projects,
  relatedTableState,
  setLocalProjects,
  setLocalProjectsAccept,
  setLocalProjectsCancelAcception,
  isSingle = false,
  isDisabled,
  isRequired = false,
  selectTitle,
  tooltipText,
  isMaximized,
  tooltipProjectText,
}: Props) {
  const { isProfile } = usePrivatePageContext();
  const toolbarEnabled: boolean = useMemo(() => workMode !== 'viewMode', [workMode]);

  const specifications = GetFullScienceProjectList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const project = useMemo<ParticipationProject | null>(() => projects[0] || null, [projects]);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => ParticipationProject>(row => {
    return {
      id: null,
      type: null,
      project: { id: row.id, value: row.completeLabel },
      accepted: '',
      acceptedBy: null,
    };
  }, []);

  const onChange = useCallback(
    (row: ParticipationProject | null) => {
      if (row) {
        setLocalProjects([row]);
      }
    },
    [setLocalProjects],
  );

  const acceptButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.like,
        title: 'Принять к отчету',
        code: 'accept',
        isDisabled: !(projects.length && !project?.accepted) || workMode === 'viewMode',
        permissionName: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits],
        profilePermissionName: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits],
        isHidden: isProfile,
        onClick: (event: any) => {
          event?.currentTarget.blur();
          if (projects.length && !project?.accepted) {
            setLocalProjectsAccept(project?.project?.id || '');
          }
        },
      },
      {
        icons: buttonIcons.dislike,
        title: 'Отменить принятие к отчету',
        code: 'cancel',
        isDisabled: !(projects.length && !!project?.accepted) || workMode === 'viewMode',
        permissionName: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits],
        profilePermissionName: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits],
        isHidden: isProfile,
        onClick: (event: any) => {
          event?.preventDefault();
          if (projects.length && !!project?.accepted) {
            setLocalProjectsCancelAcception(project?.project?.id || '');
          }
        },
      },
    ],
    [
      projects.length,
      project?.accepted,
      project?.project?.id,
      workMode,
      participationTypeCode,
      isProfile,
      setLocalProjectsAccept,
      setLocalProjectsCancelAcception,
    ],
  );

  const getIsEditProjectButtonDisabled = useCallback((row: ParticipationProject | null) => !!row?.accepted, []);

  const getIsDeleteProjectButtonDisabled = useCallback((row: ParticipationProject | null) => !!row?.accepted, []);

  return (
    <>
      {isSingle ? (
        <FormComponent.Field tooltip={tooltipText} label={selectTitle ?? 'Сведение о проекте'} isRequired={isRequired}>
          <CustomSelect
            disabled={(isDisabled !== undefined ? isDisabled : !toolbarEnabled) || !!project?.accepted}
            original={!!project?.project ? { id: project.project.id, name: project.project.value } : null}
            relationTableModalTitle="Проекты"
            onChange={onChange}
            modalTableRowConverter={modalTableRowConverter}
            onDeleteFunction={() => setLocalProjects([])}
            modalTableSpecification={specifications}
            textareaPlaceholder="Выберите элемент из справочника"
            editPermissionName={Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits]}
            customButtons={isProfile ? [] : acceptButtons}
            isStatusText={!!project?.accepted}
            customStatus={!!project?.accepted ? `Принят к отчету, ${getAcceptInfo(project)}` : ''}
          />
        </FormComponent.Field>
      ) : (
        <ListEdit<object, ParticipationProject>
          specification={{
            mode: 'relationTableModal',
            modalTableRowConverter,
            relationTableModalTitle: 'Проекты',
            modalTableSpecification: specifications,
          }}
          isModalHintHidden
          title="Проекты"
          tooltipText={tooltipText}
          rows={projects}
          columns={[
            {
              label: 'Название проекта',
              formatValue: row => row.project?.value || '',
              styles: { width: '75%' },
            },
            {
              label: 'Принят к отчету',
              tooltip: tooltipProjectText,
              formatValue: getAcceptInfo,
              styles: { width: '25%' },
            },
          ]}
          isToolbarDisabled={isDisabled !== undefined ? isDisabled : !toolbarEnabled}
          onChange={rows => setLocalProjects(rows)}
          withAcceptButtons={!isProfile}
          onAccept={row => setLocalProjectsAccept(row.project?.id || '')}
          onAcceptanceCancel={row => setLocalProjectsCancelAcception(row.project?.id || '')}
          addPermissionName={Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits]}
          editPermissionName={Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits]}
          acceptPermissionName={Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits]}
          getIsDeleteDisabled={getIsDeleteProjectButtonDisabled}
          getIsEditDisabled={getIsEditProjectButtonDisabled}
          maxHeight={isMaximized ? 'none' : undefined}
          isFullScreenedTable={isMaximized}
        />
      )}
    </>
  );
}

export { Projects };
