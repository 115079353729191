import { IdItem, Item } from 'types/models/common';
import { ReferenceItem } from 'components';
import { Member } from 'features/SelectPerson';
import { Document } from 'types/models/Document';
import { ProjectScientist } from 'types/models/Project';

export type PurchaseRequestContractKind = 'MORE' | 'LESS';
export type PurchaseRequestProject = {
  id: string | null;
  name: string | null;
  number?: string;
};
export type PurchaseRequest = {
  id: string | null;
  status: Item<string> | null;
  sendDate: string | null;
  project: PurchaseRequestProject | null;
  name: string | null;
  number?: string;
  applicant: ProjectScientist | null;
  limitPrice?: number;
  department?: ReferenceItem;
  financeResponsible?: Member | null;
  requestResponsible?: Member | null;
  deliveryAddress?: string;
  deliveryDate?: string;
  deliveryNote?: string;
  contractKind: Item<string> | null;
  note?: string;
  documents: Document[];
  units: Unit[];
};

export type Unit = {
  type: Item<string> | null;
  name: string;
  quantity: number;
  unit: string;
  perUnitPrice: number;
  isNds: boolean;
  position: number;
};

export type PurchaseRequestMessage = {
  id: string | null;
  purchaseRequest?: IdItem | null;
  message: string | null;
};
export type PurchaseRequestStatusCode = 'DRAFT' | 'CONSIDERATION' | 'REVISION' | 'REJECTED' | 'IN_ACTION_PROVIDER' | 'COMPLETE';

export class PurchaseRequestStatus {
  static DRAFT: PurchaseRequestStatusCode = 'DRAFT';

  static CONSIDERATION: PurchaseRequestStatusCode = 'CONSIDERATION';

  static REVISION: PurchaseRequestStatusCode = 'REVISION';

  static REJECTED: PurchaseRequestStatusCode = 'REJECTED';

  static IN_ACTION_PROVIDER: PurchaseRequestStatusCode = 'IN_ACTION_PROVIDER';

  static COMPLETE: PurchaseRequestStatusCode = 'COMPLETE';
}

export type PurchaseRequestApproverKind = 'MANAGEMENT_COMMITTEE' | 'PRICE_COMMISSION' | 'LOGISTIC_MANAGEMENT';

export class PurchaseRequestApprover {
  static MANAGEMENT_COMMITTEE: PurchaseRequestApproverKind = 'MANAGEMENT_COMMITTEE';

  static PRICE_COMMISSION: PurchaseRequestApproverKind = 'PRICE_COMMISSION';

  static LOGISTIC_MANAGEMENT: PurchaseRequestApproverKind = 'LOGISTIC_MANAGEMENT';
}

export const purchaseRequestTextMap: Record<string, string> = {
  DRAFT: 'Перевести заявку в статус: Черновик',
  CONSIDERATION: 'Передать заявку на рассмотрение',
  REVISION: 'Вернуть заявку на доработку',
  REJECTED: 'Перевести заявку в статус: Отклонена',
  IN_ACTION_PROVIDER: 'Перевести заявку в статус: В работе (поставщик)',
  COMPLETE: 'Перевести заявку в статус: Выполнена',
};
export const purchaseRequestSubmitTextMap: Record<string, string> = {
  DRAFT: 'В черновик',
  CONSIDERATION: 'На рассмотрение',
  REVISION: 'На доработку',
  REJECTED: 'Отклонить',
  IN_ACTION_PROVIDER: 'В работе',
  COMPLETE: 'Выполнена',
};

export const approverKindTitleMap: Record<string, string> = {
  MANAGEMENT_COMMITTEE: 'Виза управляющего комитета',
  PRICE_COMMISSION: 'Виза ценовой комиссии',
  LOGISTIC_MANAGEMENT: 'Виза управления логистики',
};

export function isNextPurchaseRequestStatus(current: PurchaseRequestStatus, next: PurchaseRequestStatus) {
  let canChangeStatus = false;
  switch (current) {
    case PurchaseRequestStatus.DRAFT:
      canChangeStatus = next === PurchaseRequestStatus.CONSIDERATION;
      break;
    case PurchaseRequestStatus.CONSIDERATION:
      canChangeStatus =
        next === PurchaseRequestStatus.REVISION ||
        next === PurchaseRequestStatus.REJECTED ||
        next === PurchaseRequestStatus.IN_ACTION_PROVIDER ||
        next === PurchaseRequestStatus.COMPLETE ||
        next === PurchaseRequestStatus.DRAFT;
      break;
    case PurchaseRequestStatus.REVISION:
      canChangeStatus = next === PurchaseRequestStatus.CONSIDERATION;
      break;
    case PurchaseRequestStatus.REJECTED:
      canChangeStatus = next === PurchaseRequestStatus.REVISION;
      break;
    case PurchaseRequestStatus.IN_ACTION_PROVIDER:
      canChangeStatus = next === PurchaseRequestStatus.COMPLETE || next === PurchaseRequestStatus.REJECTED;
      break;
    case PurchaseRequestStatus.COMPLETE:
      canChangeStatus = next === PurchaseRequestStatus.DRAFT;
      break;
    default:
      canChangeStatus = false;
  }
  return canChangeStatus;
}
