import { useMemo, useState } from 'react';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';

export function useController() {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, relatedTableState },
  } = useFormContext();

  const [isOpenPersonModal, setIsOpenPersonModal] = useState<boolean>(false);

  const userInfo = useMemo(() => {
    const selectedRow = relatedTableState?.selectedRows[0];
    return {
      login: selectedRow?.Login || '',
      fullName: selectedRow?.FullName || '',
      email: selectedRow?.UserEMail || '',
      parentDepartment: selectedRow?.ParentDepartment || '',
      department: selectedRow?.Department || '',
      person: selectedRow?.Person || '',
      contacts: selectedRow?.Contacts || '',
      personId: selectedRow?.ScientistId || '',
    };
  }, [relatedTableState?.selectedRows]);

  return {
    userId: id ?? null,
    tableStreams,
    userInfo,
    isOpenPersonModal,
    setIsOpenPersonModal,
  };
}
