import { useLocalLoaderStreams } from 'features/Loader/hooks';
import { showNotification } from 'features/Notifications';
import { BASE_FILE_URL } from 'utils/Constants';
import { uuidRegExpList } from 'utils/Regexps/uuid';

/**
 * Download file by URL or ID
 *
 * @param url - URL or file ID (java.util.uuid, see regex uuidRegExpList.java)
 * @param token - (optional) Auth token
 * @returns
 */
export function downloadFile(url?: string, token?: string | null) {
  const loaderStreams = useLocalLoaderStreams();

  if (!url) {
    return;
  }

  url = url.trim();
  if (uuidRegExpList.java.test(url)) {
    url = `${BASE_FILE_URL}${url}`;
  }

  loaderStreams.updateIsLoading.push({ isLoading: true });

  let fileName = '';

  fetch(url, {
    headers: {
      Auth: token || '',
      Credentials: 'same-origin',
      // 'Content-type': 'multipart/form-data',
    },
  })
    .then(response => {
      const contentDisposition = response.headers.get('content-disposition');

      if (!!contentDisposition) {
        const parts = contentDisposition.split('"').filter(x => x);
        if (parts.length > 1 && /filename=/.test(parts[0])) {
          parts.shift();
        }
        fileName = decodeURIComponent(parts.join('"').replaceAll('+', '%20'));
      }

      showNotification({ message: `Началaсь загрузка файла - <strong>${fileName}</strong>`, theme: 'success' });
      return response.blob();
    })
    .then(blob => {
      const a = document.createElement('a');

      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      a.target = '_blank';

      document.body.appendChild(a);

      setTimeout(() => {
        a.click();
        if (a.parentNode) {
          a.parentNode.removeChild(a);
        }
      }, 0);
      showNotification({ message: `Завершилась загрузка файла - <strong>${fileName}</strong>`, theme: 'success' });
    })
    .catch(error => {
      showNotification({ message: `Не удалось начать скачивание файла - <strong>${fileName}</strong>`, theme: 'danger' });
      console.warn('downloadFile -> Error ->', error);
    })
    .finally(() => {
      loaderStreams.updateIsLoading.push({ isLoading: false });
    });
}

/** As variant with custom params, but without headers (On if needed) */

// type OpenProps = {
//   url: string;
//   method?: 'GET' | 'POST';
//   params?: Record<string, string>;
//   isNewTab?: boolean;
// };

// /**
//  * Redirect to URL with params (include file download)
//  *
//  * @param url - URL for tab
//  * @param method - (optional) Request type ('POST' by default)
//  * @param params - (optional) Request params (key - param name, value - param value)
//  * @param isNewTab - (optional) Open in new tab (false by default)
//  * @returns
//  */
// export function openURL({ url, method = 'POST', params = {}, isNewTab = false }: OpenProps) {
//   const form = document.createElement('form');
//   const input = document.createElement('input');

//   form.innerHTML = '';

//   form.action = url;
//   form.method = method.toUpperCase();
//   // form.enctype = 'application/x-www-form-urlencoded';
//   form.target = isNewTab ? '_blank' : '_self';

//   for (var name in params) {
//     input.type = 'hidden';
//     input.name = name;
//     input.value = params[name];
//     form.appendChild(input.cloneNode(true));
//   }

//   document.getElementsByTagName('body')[0].appendChild(form);

//   setTimeout(() => {
//     form.submit();
//     if (form.parentNode) {
//       form.parentNode.removeChild(form);
//     }
//   }, 0);
// }
