import React, { useCallback, useMemo } from 'react';

import { IconButtonProps, buttonIcons, Toolbar } from 'components';

export type Props = {
  onClose: () => void;
  changeStatusCallback: () => void;
  statusHistoryCallback: () => void;
  projectCodeInfoCallback: () => void;
  id?: string | null;
};

function EditControlPanel({ projectCodeInfoCallback, onClose }: Props) {
  const handleProjectCodeInfoCallback = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      projectCodeInfoCallback();
    },
    [projectCodeInfoCallback],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      { icons: buttonIcons.save, title: 'Сохранить', onClick: onClose },
      {
        icons: buttonIcons.info,
        title: 'Информация о шифре',
        onClick: handleProjectCodeInfoCallback,
      },
      { icons: buttonIcons.print, title: 'Печать', onClick: () => {} },
    ],
    [handleProjectCodeInfoCallback, onClose],
  );

  return <Toolbar buttons={buttons} mode="form" />;
}

const Component = React.memo(EditControlPanel);

export { Component as EditControlPanel };
