import React, { useCallback, useMemo } from 'react';

import { ListEdit, buttonIcons } from 'components';

import { Project } from 'types/models';
import { Fields } from './Fields';
import { getMockStageReport, getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';
import { showNotification } from 'features/Notifications';
import { Item } from 'types/models/common';
import { StateReports } from '../StateRegistration/StateReports/StateReports';
import { getAuthToken, downloadFile } from 'utils/Helpers';

export type ExtendedReport = Project.StageReport & {
  stage: Item;
};

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
};

export const Reports = React.memo((props: Props) => {
  const { project, disabled, setProject } = props;

  const token = getAuthToken();

  const reports = useMemo<ExtendedReport[]>(() => {
    return (project?.stages || [])
      .map<ExtendedReport | null>(stage => {
        const report = stage.reports[0];
        if (!report) return null;
        return { ...report, stage: { value: stage.id || '', label: stage.number || '' } };
      })
      .filter(x => x) as ExtendedReport[];
  }, [project?.stages]);

  const isAddDisabled = useMemo<boolean>(() => {
    return !!project?.stages.length && project.stages.every(x => x.reports.length !== 0);
  }, [project?.stages]);

  const handleReportsChange = useCallback(
    (r: ExtendedReport[]) => {
      setProject(prevState => ({
        ...prevState!,
        stages: prevState.stages.map<Project.Stage>(stage => ({
          ...stage,
          reports: (() => {
            const matchedReport = r.find(report =>
              stage.id ? report.stage.value === stage.id : report.stage.label === stage.number,
            );
            return matchedReport ? [matchedReport] : [];
          })(),
        })),
      }));
    },
    [setProject],
  );

  return (
    <>
      <StateReports
        disabled={disabled}
        stages={project?.stages ?? []}
        reports={project?.stateRegistrationReports ?? []}
        setProject={setProject}
        udkList={project?.udks || []}
        isFieldsHidden
      />
      <ListEdit
        title="Список других отчётов по этапам проекта"
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        defaultRowsCount={10}
        visibleToolbarButtons={['view', 'add', 'edit', 'delete']}
        getIsAddDisabled={() => isAddDisabled}
        extraToolbarButtons={[
          {
            icons: buttonIcons.download,
            title: 'Скачать файл отчёта',
            checkIsDisabled: row => !row || disabled || !row?.file,
            onClick: row => downloadFile(row?.file.id, token),
          },
        ]}
        rows={reports}
        onChange={handleReportsChange}
        columns={[
          {
            label: 'Этап',
            formatValue: x => {
              const foundStage = (project?.stages || []).find(stage => stage.number === x.stage.label);
              return foundStage ? getStageTitle(foundStage) : '';
            },
            styles: { width: '20%' },
          },
          { label: 'Тип отчёта', formatValue: x => x.type?.label ?? '', styles: { width: '10%' } },
          { label: 'Название отчёта', formatValue: x => x.name ?? '', styles: { width: '15%' } },
          { label: 'Ключевые слова', formatValue: x => x.keyWords ?? '', styles: { width: '15%' } },
          { label: 'Инв. номер в архиве', formatValue: x => x.ontiNumber ?? '', styles: { width: '10%' } },
          { label: 'Срок сдачи', formatValue: x => x.reportDate ?? '', styles: { width: '5%' } }, //?
          { label: 'Отправлен заказчику', formatValue: x => x.sendCustomerDate ?? '', styles: { width: '5%' } }, //?
          { label: 'Сдан в архив', formatValue: x => x.reportOntiDate ?? '', styles: { width: '5%' } }, //?
          { label: 'Регистрация в архиве', formatValue: x => x.registrationDate ?? '', styles: { width: '5%' } }, //?
          { label: 'Файл', formatValue: x => x.file?.name ?? '', styles: { width: '10%' } },
        ]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Отчёт',
          renderComponent: (report, setReport, index, mode) => (
            <Fields
              report={report || { ...getMockStageReport(), stage: { value: '', label: '' } }}
              setReport={setReport}
              disabled={mode === 'view'}
              stages={project?.stages || []}
            />
          ),
          validation: {
            checkIsValid: row => !!row?.name,
            onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
          },
        }}
      />
    </>
  );
});
