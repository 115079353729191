import React from 'react';

import { Form as F } from 'types/models';
import { EditProject } from 'features/Form/looks/project/ProjectForm';

const ProjectStateRegistrationReportViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id }: F.TemplateProps) => (
  <>
    {isOpen && (
      <>
        <EditProject id={id || null} isOpen={isOpen} onClose={onClose} mode="view" />
      </>
    )}
  </>
);
export const Component = React.memo(ProjectStateRegistrationReportViewTemplate);
