import React from 'react';

import { FormComponent } from 'components';

import { RidAuthor } from 'types/models';
import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';

type Props = {
  author: RidAuthor | null;
  onAuthorChange(row: RidAuthor): void;
};

function AuthorInputs({ author, onAuthorChange }: Props) {
  const { handlePersonChange } = useController({
    author,
    onAuthorChange,
  });
  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Автор">
          <SelectPerson member={author || null} onUpdateMember={val => handlePersonChange(val)} isRequired />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { AuthorInputs };
