export class Months {
  static JANUARY = {
    id: '1',
    label: 'Январь',
  };

  static FEBRUARY = {
    id: '2',
    label: 'Февраль',
  };

  static MARCH = {
    id: '3',
    label: 'Март',
  };

  static APRIL = {
    id: '4',
    label: 'Апрель',
  };

  static MAY = {
    id: '5',
    label: 'Май',
  };

  static JUNE = {
    id: '6',
    label: 'Июнь',
  };

  static JULY = {
    id: '7',
    label: 'Июль',
  };

  static AUGUST = {
    id: '8',
    label: 'Август',
  };

  static SEPTEMBER = {
    id: '9',
    label: 'Сентябрь',
  };

  static OCTOBER = {
    id: '10',
    label: 'Октябрь',
  };

  static NOVEMBER = {
    id: '11',
    label: 'Ноябрь',
  };

  static DECEMBER = {
    id: '12',
    label: 'Декабрь',
  };
}
