import { Department } from 'types/models/Department';
import { RidAuthor } from 'types/models';

export function validateDepartment(department: Department | null) {
  if (!department || !department.unit?.id || !department.governance?.id) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }
  return [];
}
export function validateAuthor(author: RidAuthor | null) {
  if (!author || !author?.person?.id) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }
  return [];
}
