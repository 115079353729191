import { useMemo, useCallback, useLayoutEffect } from 'react';

import { Parameter } from 'types/models';
import { BuilderProps } from 'features/BuildReportPopup/models';

type Props = object & BuilderProps;

const useController = ({ parameters, setParameterValueByName, parameterName }: Props) => {
  const yearParameter = useMemo<Parameter | null>(() => parameters.find(parameter => parameter.name === parameterName) || null, [
    parameters,
    parameterName,
  ]);
  const canBuild = useMemo<boolean>(() => !!yearParameter, [yearParameter]);

  const onChange = useCallback(
    value => {
      setParameterValueByName({ name: parameterName, value });
    },
    [setParameterValueByName, parameterName],
  );

  useLayoutEffect(() => {
    const initialYear = new Date().getFullYear().toString();
    setParameterValueByName({ name: parameterName, value: initialYear });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    yearParameter,
    canBuild,
    onChange,
  };
};

export default useController;
