import React, { useMemo, useState } from 'react';
import { parse } from 'date-fns';
import { block } from 'bem-cn';

import {
  buttonIcons,
  CommentPopup,
  ConfirmPopup,
  IconButtonProps,
  RelationTableModal,
  Toolbar as SharedToolbar,
} from 'components';

import { Table } from 'types/models';
import { EmployeePaymentStatus } from 'types/models/Payment';
import { showNotification } from 'features/Notifications';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { isHasPermission, isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { buildPaymentRequestPeriodQuery } from 'features/BuildReportPopup/helpers';
import { Months } from 'utils/Enums/Months';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { RequestForm } from '../RequestForm/RequestForm';
import { State } from '../makeUseCustomController';
import { GetEmployeePaymentRequestFeedlineList } from '../..';

import './style.scss';

const b = block('request-fund-list-toolbar');

type Props = {
  tableState: Table.State;
  customState: State;
};

function getEditablePermission(status: EmployeePaymentStatus) {
  return `IncentivePayment:EmployeeRequest:Edit:${status}`;
}

function getDeletablePermission(status: EmployeePaymentStatus | null) {
  if (status) {
    return `IncentivePayment:EmployeeRequest:Delete:${status}`;
  }
}

const allEditablePermissions = [
  'IncentivePayment:EmployeeRequest:Edit:DRAFT',
  'IncentivePayment:EmployeeRequest:Edit:REJECTED',
  'IncentivePayment:EmployeeRequest:Edit:SENT',
  'IncentivePayment:EmployeeRequest:Edit:PAID',
  'IncentivePayment:EmployeeRequest:Edit:PAYMENT_APPROVED',
];

const allEditStatusPermissions = [
  'IncentivePayment:EmployeeRequest:Status:ToSent',
  'IncentivePayment:EmployeeRequest:Status:ToPaid',
  'IncentivePayment:EmployeeRequest:Status:ToPaymentApproved',
  'IncentivePayment:EmployeeRequest:Status:ToRejected',
];

const allDeletableStatusPermissions = [
  'IncentivePayment:EmployeeRequest:Delete:DRAFT',
  'IncentivePayment:EmployeeRequest:Delete:REJECTED',
  'IncentivePayment:EmployeeRequest:Delete:SENT',
  'IncentivePayment:EmployeeRequest:Delete:PAID',
  'IncentivePayment:EmployeeRequest:Delete:PAYMENT_APPROVED',
];

export function Toolbar({ tableState, customState }: Props) {
  const [row] = tableState.selectedRows;
  const {
    employeePayment,
    isOpenRequestForm,
    isReportOpen,
    currentReport,
    closeFormRequest,
    openFormRequest,
    setEmployeePayment,
    saveEmployeePayment,
    comment,
    setComment,
    toggleVisibleCommentForm,
    isOpenCommentForm,
    startSaveEmployeePayment,
    isOpenConfirmDelete,
    setIsOpenConfirmDelete,
    deleteEmployeePayment,
    changeEmployeePaymentStatus,
    onPrintClick,
    onReportClose,
    mode,
    selectedFund,
  } = customState;
  const status = row ? (row['id:status'] as EmployeePaymentStatus) : null;
  const { userPermission } = useAppDataContext();
  const [isOpenConfirmApprove, setIsOpenConfirmApprove] = useState(false);
  const [isOpenMessageLogModal, setIsOpenMessageLogModal] = useState(false);
  const [isOpenRevisionModal, setIsOpenRevisionModal] = useState(false);
  const [isOpenConfirmPaymentApprovedModal, setIsOpenConfirmPaymentApprovedModal] = useState(false);

  const isUserHasSomeEditablePermission = useMemo(() => isHasSomePermission(userPermission, allEditablePermissions), [
    userPermission,
  ]);

  const isUserHasSomeStatusEditPermission = useMemo(() => isHasSomePermission(userPermission, allEditStatusPermissions), [
    userPermission,
  ]);

  const isUserHasSomeStatusDeletePermission = useMemo(() => isHasSomePermission(userPermission, allDeletableStatusPermissions), [
    userPermission,
  ]);

  const isEditStatusHidden = useMemo(() => !isUserHasSomeStatusEditPermission || !isUserHasSomeEditablePermission, [
    isUserHasSomeEditablePermission,
    isUserHasSomeStatusEditPermission,
  ]);

  const isUserHasStatusEditPermission = useMemo(() => {
    if (status) {
      return isHasPermission(userPermission, getEditablePermission(status));
    }

    return false;
  }, [status, userPermission]);

  const isUserHasStatusDeletePermission = useMemo(() => {
    if (status) {
      return isHasPermission(userPermission, getDeletablePermission(status)!);
    }

    return false;
  }, [status, userPermission]);

  const selectedIds = useMemo(() => {
    if (tableState.selectedRows.length) {
      const selectedRowIds = tableState.selectedRows.map(({ id }) => id);
      return selectedRowIds.join(',');
    }
  }, [tableState.selectedRows]);

  const paymentRequestPeriod = useMemo(() => {
    if (tableState.selectedRows.length) {
      const { year } = row;
      return buildPaymentRequestPeriodQuery({
        periodKey: 'month',
        year,
        period: [
          Months.JANUARY.id,
          Months.FEBRUARY.id,
          Months.MARCH.id,
          Months.APRIL.id,
          Months.MAY.id,
          Months.JUNE.id,
          Months.JULY.id,
          Months.AUGUST.id,
          Months.SEPTEMBER.id,
          Months.OCTOBER.id,
          Months.NOVEMBER.id,
          Months.DECEMBER.id,
        ],
      });
    }
  }, [row, tableState.selectedRows.length]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        permissionName: 'IncentivePayment:EmployeeRequest:View',
        isDisabled: !row,
        onClick: () => openFormRequest('view'),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать',
        code: 'print',
        isDisabled: !row,
        onClick: onPrintClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        permissionName: 'IncentivePayment:EmployeeRequest:Add',
        isDisabled: !selectedFund,
        onClick: () => openFormRequest('add'),
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isHidden: !isUserHasSomeEditablePermission,
        isDisabled: !row || status === 'PAYMENT_APPROVED' || !isUserHasStatusEditPermission,
        onClick: () => openFormRequest('edit'),
      },
      {
        icons: buttonIcons.like,
        title: 'Подать на согласование',
        code: 'like',
        isHidden: isEditStatusHidden,
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToSent',
        isDisabled:
          !row || status === 'PAID' || status === 'SENT' || status === 'PAYMENT_APPROVED' || !isUserHasStatusEditPermission,
        onClick: () => changeEmployeePaymentStatus('SENT'),
      },
      {
        icons: buttonIcons.paymentHandover,
        title: 'Передать на оплату',
        isHidden: isEditStatusHidden,
        code: 'paymentHandover',
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToPaid',
        isDisabled:
          !row ||
          status === 'REJECTED' ||
          status === 'PAID' ||
          status === RecordStatus.DRAFT ||
          status === 'PAYMENT_APPROVED' ||
          !isUserHasStatusEditPermission,
        onClick: () => changeEmployeePaymentStatus('PAID'),
      },
      {
        icons: buttonIcons.conflictsNone,
        title: 'Одобрить оплату',
        isHidden: isEditStatusHidden,
        code: 'conflictsNone',
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToPaymentApproved',
        isDisabled:
          !row ||
          status === 'REJECTED' ||
          status === RecordStatus.DRAFT ||
          status === 'SENT' ||
          status === 'PAYMENT_APPROVED' ||
          !isUserHasStatusEditPermission,
        onClick: () => setIsOpenConfirmApprove(true),
      },
      {
        icons: buttonIcons.update,
        title: 'Отправить на доработку',
        isHidden: isEditStatusHidden,
        code: 'update',
        permissionName: 'IncentivePayment:EmployeeRequest:Status:ToRejected',
        isDisabled:
          !row ||
          status === 'REJECTED' ||
          status === RecordStatus.DRAFT ||
          status === 'PAYMENT_APPROVED' ||
          !isUserHasStatusEditPermission,
        onClick: () => setIsOpenRevisionModal(true),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isHidden: !isUserHasSomeStatusDeletePermission,
        isDisabled: !row || status === 'PAYMENT_APPROVED' || !isUserHasStatusDeletePermission,
        onClick: () => setIsOpenConfirmDelete(true),
      },
      {
        icons: buttonIcons.comment,
        title: 'Журнал сообщений',
        code: 'feedline',
        isDisabled: !row,
        onClick: () => setIsOpenMessageLogModal(true),
      },
    ],
    [
      row,
      onPrintClick,
      selectedFund,
      isUserHasSomeEditablePermission,
      status,
      isUserHasStatusEditPermission,
      isEditStatusHidden,
      isUserHasSomeStatusDeletePermission,
      isUserHasStatusDeletePermission,
      openFormRequest,
      changeEmployeePaymentStatus,
      setIsOpenConfirmDelete,
    ],
  );
  return (
    <>
      <SharedToolbar buttons={buttons} />
      <ConfirmPopup
        icon="warning"
        title="Удаление заявки"
        text="Вы действительно хотите удалить заявку?"
        isOpen={isOpenConfirmDelete}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmDelete(false)}
        onConfirm={() => {
          deleteEmployeePayment();
          setIsOpenConfirmDelete(false);
        }}
      />
      <ConfirmPopup
        icon="warning"
        title="Одобрение оплаты заявки"
        text={`Вы уверены, что хотите перевести выбранную заявку в статус "Оплата одобрена"? 
          Данный статус является конечным, и редактировать заявку в данном статусе невозможно.`}
        isOpen={isOpenConfirmApprove}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmApprove(false)}
        onConfirm={() => {
          setIsOpenConfirmApprove(false);
          if (Number(row.employeesAmount) > Number(selectedFund?.factIncome ?? 0)) {
            showNotification({ message: 'Сумма оплаты не должна превышать фактического прихода фонда', theme: 'danger' });
          } else {
            setIsOpenConfirmPaymentApprovedModal(true);
          }
        }}
      />
      <ConfirmPopup
        icon="none"
        title="Одобрение оплаты по заявке"
        isOpen={isOpenConfirmPaymentApprovedModal}
        okButtonText="Одобрить"
        resetButtonText="Отменить"
        onClose={() => setIsOpenConfirmPaymentApprovedModal(false)}
        onConfirm={() => {
          changeEmployeePaymentStatus('PAYMENT_APPROVED');
          setIsOpenConfirmPaymentApprovedModal(false);
        }}
      >
        <div className={b('approve-body')}>
          <div>Квартал {row?.quarter ?? ''}</div>
          <div>Год {parse(row?.createdDate ?? '', formatDateTimeStr, new Date()).getFullYear()}</div>
        </div>
      </ConfirmPopup>
      <RelationTableModal
        specification={GetEmployeePaymentRequestFeedlineList({ status, employeePaymentId: row?.id ?? '' })}
        relationTableModalTitle="Журнал сообщений"
        isOpen={isOpenMessageLogModal}
        onClose={() => setIsOpenMessageLogModal(false)}
      />
      <CommentPopup
        isRequired
        title="Отправка заявки на доработку"
        isOpen={isOpenRevisionModal}
        onClose={() => setIsOpenRevisionModal(false)}
        comment={comment}
        setComment={setComment}
        onSubmit={() => changeEmployeePaymentStatus('REJECTED')}
      />
      <RequestForm
        isOpen={isOpenRequestForm}
        employeePayment={employeePayment}
        setEmployeePayment={setEmployeePayment}
        onClose={closeFormRequest}
        saveEmployeePayment={saveEmployeePayment}
        comment={comment}
        setComment={setComment}
        selectedFund={selectedFund}
        toggleVisibleCommentForm={toggleVisibleCommentForm}
        isOpenCommentForm={isOpenCommentForm}
        startSaveEmployeePayment={startSaveEmployeePayment}
        mode={mode}
        status={status}
        changeEmployeePaymentStatus={changeEmployeePaymentStatus}
        openConfirmApproveStatusModal={() => setIsOpenConfirmApprove(true)}
        openConfirmRevisionStatusModal={() => setIsOpenRevisionModal(true)}
        openConfirmDeleteModal={() => setIsOpenConfirmDelete(true)}
        openMessageLogModal={() => setIsOpenMessageLogModal(true)}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        requestIds={selectedIds}
        onClose={onReportClose}
        reportName={currentReport?.name ?? ''}
        reportCaption={currentReport?.caption ?? ''}
        department="-1"
        requestStatus="-1"
        requestType="-1"
        regulationParagraph="-1"
        paymentRequestPeriod={paymentRequestPeriod}
      />
    </>
  );
}
