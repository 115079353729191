import React from 'react';
import * as R from 'ramda';
import { block } from 'bem-cn';

import { ListEdit } from 'components';

import { Author } from 'types/models';
import { Fields, ChangeProps } from './Fields/Fields';
import { formatRole, formatAuthor, getMockAuthor, validate, formatAuthorContacts, formatHistory } from './helpers';
import { showNotification } from 'features/Notifications';

const b = block('about-authorship');

type Props = {
  authors: Author[];
  setAuthors(authors: Author[]): void;
  disabled?: boolean;
};

function MembersList(props: Props) {
  const { authors, setAuthors, disabled = false } = props;

  const handleChangeField = (rowSetter: React.Dispatch<React.SetStateAction<Author | null>>, changeProps: ChangeProps[]) => {
    rowSetter(prevAuthor => {
      const updatedAuthor = changeProps.reduce<Author | null>((acc, { key, value }) => ({ ...acc, [key]: value } as any), {
        ...getMockAuthor(),
        ...prevAuthor,
      });
      return updatedAuthor;
    });
  };

  return (
    <div className={b()}>
      <ListEdit
        maxHeight="80vh"
        defaultRowsCount={13}
        rows={authors}
        columns={[
          { label: 'ФИО', formatValue: formatAuthor },
          { label: 'Сведения о работе/обучении', formatValue: formatHistory },
          { label: 'Роль', formatValue: formatRole },
          { label: 'Контакты', formatValue: formatAuthorContacts },
        ]}
        onChange={setAuthors}
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        withMessages
        specification={{
          mode: 'customComponent',
          validation: {
            checkIsValid: author => validate(author).every(x => x.isValid),
            onInvalidate: author => {
              const info = validate(author);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
          renderComponent: (author, setAuthor) => <Fields author={author} onChange={R.partial(handleChangeField, [setAuthor])} />,
        }}
      />
    </div>
  );
}

export { MembersList };
