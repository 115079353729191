import React, { useMemo } from 'react';

import { useAppDataContext } from 'features/AppData/context';
import { PersonCard } from 'features/PersonCard';
import { PrivatePageContextType, PrivatePageContext } from './context';

export function PrivatePage() {
  const { currentPerson } = useAppDataContext();

  const context = useMemo<PrivatePageContextType>(() => ({ isProfile: true }), []);
  return (
    <PrivatePageContext.Provider value={context}>
      <PersonCard personId={currentPerson?.id ?? null} isOpenForm />
    </PrivatePageContext.Provider>
  );
}
