import { Table } from 'types/models';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  row: Table.Entry;
};

const getIsSourceCitationDisabled = ({ row }: Props) => {
  const getIsValidRecordType = () => {
    const validRecordTypes = [
      PublicationTypes.MAGAZINE_ARTICLE.code,
      PublicationTypes.ARTICLE_ARTICLES.code,
      PublicationTypes.ARTICLE_CONFERENCE.code,
      PublicationTypes.ARTICLE_SCIENTIFIC_WORK.code,
      PublicationTypes.ARTICLE_OTHER.code,

      PublicationTypes.THESIS.code,
      PublicationTypes.MONOGRAPH_CHAPTER.code,
      PublicationTypes.OTHER_PART.code,
      PublicationTypes.PREPRINT_ARTICLE.code,
    ];

    return validRecordTypes.some(recordTypeCode => row['id:PublicationType'] === recordTypeCode);
  };
  const isValidRecordType = getIsValidRecordType();

  return !isValidRecordType;
};

export { getIsSourceCitationDisabled };
