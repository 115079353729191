import React from 'react';

import { Modal, FormComponent, Toolbar } from 'components';
import { CustomModalComponentSpecification, Mode } from '../model';

import { useController } from './controller';

type Props<Row> = {
  isOpen: boolean;
  modalTitle: string;
  modalEditTitle?: string;
  customEditRow: Row | null;
  selectedRowIndex: number | null;
  mode: Mode;
  onClose(): void;
  onChange: React.Dispatch<React.SetStateAction<Row | null>>;
  submitCustomComponent(val?: boolean): void;
  renderComponent: CustomModalComponentSpecification<Row>['renderComponent'];
  isSaveAndAddCustomComponent?: boolean;
  saveAndAddCustomComponent?: () => void;
  isCheck?: boolean;
  isToolbarHidden?: boolean;
  isFullScreenModal?: boolean;
};

function ModalComponent<Row>(props: Props<Row>) {
  const {
    isOpen,
    onClose,
    onChange,
    modalTitle,
    modalEditTitle,
    customEditRow,
    selectedRowIndex,
    submitCustomComponent,
    renderComponent,
    mode,
    isSaveAndAddCustomComponent,
    saveAndAddCustomComponent,
    isCheck,
    isToolbarHidden,
    isFullScreenModal,
  } = props;

  const { buttons, modalDialogTitle } = useController({
    mode,
    modalTitle,
    modalEditTitle,
    isCheck,
    customEditRow,
    submitCustomComponent,
    isSaveAndAddCustomComponent,
    saveAndAddCustomComponent,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalDialogTitle} size={isFullScreenModal ? 'full' : 'large'}>
      <FormComponent.Template>
        {!isToolbarHidden && mode !== 'view' && <Toolbar buttons={buttons} mode="form" />}
        <FormComponent.Wrapper>
          {renderComponent(customEditRow, onChange, selectedRowIndex, mode, submitCustomComponent)}
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
}

export { ModalComponent };
