import { ProgramRequest } from 'types/models';

import { convertLotPrice, convertTender } from 'services/BackendAPI/configurations/tenders/converters';
import { convertServerPerson } from 'services/BackendAPI/configurations/author/converters';
import { Lot } from 'types/models/Tender';

import * as R from 'ramda';
import { Settings } from 'types/models/common';
import { RequestPartner } from 'types/models/ProgramRequest';
import { convertDocument, convertItem, convertMaybeArray, convertReference, getText, getId } from '../commonConverters';
import { convertPersonHistory } from '../converters';
import { convertEstimatePosition } from '../nirRequests/converters';

export function convertProgramRequest(programRequest: any, settings: Settings): ProgramRequest.ProgramRequest {
  const estimatePositions = programRequest.EstimatePositions?.Position || [];

  return {
    id: programRequest?._attributes?.id ?? null,
    status: programRequest.Status ? convertItem(programRequest.Status) : null,
    code: getText(programRequest.Code),
    theme: getText(programRequest.Theme),
    tender: programRequest.Tender ? convertTender(programRequest.Tender, settings) : null,
    lot: programRequest.Lot ? convertRequestInfoLot(programRequest.Lot, settings) : null,
    requestDate: getText(programRequest.RequestDate),
    startDate: getText(programRequest.StartDate),
    endDate: getText(programRequest.EndDate),
    keyWords: getText(programRequest.KeyWords),
    annotation: getText(programRequest.Annotation),
    note: getText(programRequest.Note),
    periodNote: getText(programRequest.PeriodNote),
    performers: programRequest.Performers
      ? convertMaybeArray(programRequest.Performers.Performer || [], p => convertPerformer(p, settings))
      : [],
    performerCount: parseInt(getText(programRequest.PerformerCount), 10),
    partners: programRequest.Partners ? convertMaybeArray(programRequest.Partners.Partner || [], p => convertPartner(p)) : [],
    finances: programRequest.Finances ? convertMaybeArray(programRequest.Finances.Finance || [], convertFinance) : [],
    departments: programRequest.Departments
      ? convertMaybeArray(programRequest.Departments.Department || [], convertDepartment)
      : [],
    grntis: programRequest.Grntis ? convertMaybeArray(programRequest.Grntis.Grnti || [], convertReference) : [],
    udks: programRequest.Udks ? convertMaybeArray(programRequest.Udks.Udk || [], convertReference) : [],
    scienceDomainInterrests: programRequest.ScienceDomainInterrests
      ? convertMaybeArray(programRequest.ScienceDomainInterrests.ScienceDomainInterrest || [], convertReference)
      : [],
    criticalTechnologies: programRequest.CriticalTechnologies
      ? convertMaybeArray(programRequest.CriticalTechnologies.CriticalTechnology || [], convertReference)
      : [],
    pnrs: programRequest.Pnrs ? convertMaybeArray(programRequest.Pnrs.Pnr || [], convertReference) : [],
    pnmitrs: programRequest.Pnmitrs ? convertMaybeArray(programRequest.Pnmitrs.Pnmitr || [], convertReference) : [],
    pnis: programRequest.Pnis ? convertMaybeArray(programRequest.Pnis.Pni || [], convertReference) : [],
    ntrStrategies: programRequest.NtrStrategies
      ? convertMaybeArray(programRequest.NtrStrategies.NtrStrategy || [], convertReference)
      : [],
    technologyPlatforms: programRequest.TechPlatforms
      ? convertMaybeArray(programRequest.TechPlatforms.TechPlatform || [], convertReference)
      : [],
    okveds: programRequest.Okveds ? convertMaybeArray(programRequest.Okveds.Okved || [], convertReference) : [],
    lksetss: programRequest.Lksetss ? convertMaybeArray(programRequest.Lksetss.Lksets || [], convertReference) : [],
    scienceBrunches: programRequest.ScienceBrunches
      ? convertMaybeArray(programRequest.ScienceBrunches.ScienceBrunch || [], convertReference)
      : [],
    domainKnowledges: programRequest.DomainKnowledges
      ? convertMaybeArray(programRequest.DomainKnowledges.DomainKnowledge || [], convertReference)
      : [],
    requestProvideAmount: parseFloat(getText(programRequest.RequestProvideAmount)),
    requestProvideKind: programRequest.RequestProvideKind ? convertProvideKind(programRequest.RequestProvideKind) : null,
    requestProvideNote: getText(programRequest.RequestProvideNote),
    contractProvideAmount: parseFloat(getText(programRequest.ContractProvideAmount)),
    contractProvideKind: programRequest.ContractProvideKind ? convertProvideKind(programRequest.ContractProvideKind) : null,
    contractProvideNote: getText(programRequest.ContractProvideNote),
    warrantyProvideAmount: parseFloat(getText(programRequest.WarrantyProvideAmount)),
    warrantyProvideKind: programRequest.WarrantyProvideKind ? convertProvideKind(programRequest.WarrantyProvideKind) : null,
    warrantyProvideNote: getText(programRequest.WarrantyProvideNote),
    documents: [
      ...(programRequest.Documents && programRequest.Documents.Document
        ? convertMaybeArray(programRequest.Documents.Document, R.partial(convertDocument, ['local']))
        : []),
      ...(programRequest.ForeignDocuments && programRequest.ForeignDocuments.Document
        ? convertMaybeArray(programRequest.ForeignDocuments.Document, R.partial(convertDocument, ['foreign']))
        : []),
    ],
    estimatePositions: convertMaybeArray(estimatePositions, convertEstimatePosition),
    numberPrefix: programRequest.NumberPrefix ? convertReference(programRequest.NumberPrefix) : null,
  };
}

function convertProvideKind(kind: any): ProgramRequest.ProvideKind {
  return {
    id: kind?._attributes.id,
    label: kind?._attributes.label,
    ordinal: parseInt(kind?._attributes.ordinal, 10),
    position: parseInt(kind?._attributes.position, 10),
    name: getText(kind),
  };
}

function convertPerformer(performer: any, settings: Settings): ProgramRequest.Performer {
  return {
    id: performer?._attributes.id,
    person: convertServerPerson(performer?.Person, settings),
    role: convertItem(performer?.Role),
    ...convertPersonHistory(performer),
  };
}

function convertPartner(partner: any): RequestPartner {
  return {
    note: getText(partner.Note),
    partner: convertReference(partner.Partner),
  };
}

export function convertRequestInfoLot(lot: any, settings: Settings): Lot {
  return {
    id: lot?._attributes.id,
    tender: lot?.Tender ? convertTender(lot?.Tender, settings) : undefined,
    number: getText(lot?.Number),
    code: getText(lot?.Code),
    periodNote: getText(lot?.PeriodNote),
    theme: getText(lot?.Theme),
    startDate: getText(lot?.StartDate),
    endDate: getText(lot?.EndDate),
    limitAmount: getText(lot?.LimitAmount),
    contractCount: getText(lot?.ContractCount),
    contractAmount: getText(lot?.ContractAmount),
    isSupportRequest: getText(lot?.IsSupportRequest) === 'true',
    requestSupportAmount: getText(lot?.RequestSupportAmount),
    isSupportContract: getText(lot?.IsSupportContract) === 'true',
    contractSupportAmount: getText(lot?.ContractSupportAmount),
    isSupportWarranty: getText(lot?.IsSupportWarranty) === 'true',
    warrantySupportAmount: getText(lot?.WarrantySupportAmount),
    prices: convertMaybeArray((lot?.Prices && lot?.Prices.Price) || [], convertLotPrice),
  };
}

export function convertFinance(finance: any): ProgramRequest.Finance {
  return {
    id: finance?._attributes?.id ?? null,
    year: finance?.Year ? getText(finance?.Year) : '',
    fbAmount: finance?.FBAmount ? parseFloat(getText(finance?.FBAmount)) : 0,
    sfAmount: finance?.SFAmount ? parseFloat(getText(finance?.SFAmount)) : 0,
    ...(finance?.InvestmentSource ? { investmentSource: convertReference(finance?.InvestmentSource) } : {}),
  };
}

export function convertDepartment(department: any): ProgramRequest.Department {
  return {
    id: department?._attributes?.id || null,
    unit: convertReference(department?.Unit),
    governance: convertReference(department?.Governance),
    position: getText(department?.Position),
  };
}

export function convertRequestFeedline(f: any): ProgramRequest.Feedline {
  return {
    id: getId(f),
    type: f.Type ? convertItem(f.Type) : null,
    message: getText(f.Message),
    operator: { id: getId(f.Operator), fullname: getText(f.Operator) },
  };
}
