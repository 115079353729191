import { TableSelect } from 'components';

import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { formatJob } from 'features/SelectPerson/helpers';
import { prepareJob } from '../helpers';
import { JobStatus } from 'utils/Enums';

type Props = {
  jobs: Person.ScientistJob[];
  selectedJob: Person.ScientistJob | null;
  onChangeJob(job: Person.ScientistJob | null): void;
  isDisabled?: boolean;
};

function SelectJob(props: Props) {
  const { jobs, onChangeJob: onChangeJobCallback, selectedJob, isDisabled } = props;

  const onChangeJob = (job: Person.ScientistJob | null) => {
    const nextJob = prepareJob(job);
    const isActive = nextJob ? nextJob.isActive : true;
    if (!isActive) {
      showNotification({ message: 'Нельзя выбрать неактивную работу', theme: 'danger' });
    }
    const preparedJob = isActive ? nextJob : selectedJob;

    onChangeJobCallback(preparedJob);
  };

  return (
    <TableSelect
      rows={jobs}
      onChange={onChangeJob}
      tableModalTitle="Выберите место работы двойным кликом"
      isDisabledListIcon={isDisabled}
      value={selectedJob}
      formatValue={job => (job ? formatJob(job) : '')}
      columns={[
        { label: 'Организация', formatValue: row => row.enterpise },
        { label: 'Должность', formatValue: row => row.refAppointment?.label || row.appointment || '' },
        {
          label: 'Штатное состояние',
          formatValue: row =>
            `${row.status.label}${row.status.value === JobStatus.LOCAL ? `: ${row.staffState?.label || ''}` : ''}`,
        },
        {
          label: 'Подразделение',
          styles: { width: '18%', textAlign: 'center' },
          formatValue: row => row.refDepartment?.label || row.department || '',
        },
        { label: 'Дата приема', formatValue: row => row.dateReceipt ?? '' },
        { label: 'Дата увольнения', formatValue: row => row.dateDismissal ?? '' },
        {
          label: 'Создано',
          formatValue: ({ creationDate, creationSource }) => `${creationSource} (${creationDate})`,
        },
        {
          label: 'Изменено',
          formatValue: ({ modificationDate, modificationSource }) => `${modificationSource} (${modificationDate})`,
        },
        {
          label: 'Удалено',
          formatValue: ({ deletionDate, deletedBy }) => (deletedBy && deletionDate ? `${deletedBy} (${deletionDate})` : ''),
        },
      ]}
    />
  );
}

export { SelectJob };
