import React, { useCallback, useMemo, memo } from 'react';
import { FormComponent, InputSelect, Option } from 'components';
import { ExtendedMember } from '../model';

type Props = {
  participants: ExtendedMember[];
  participant: ExtendedMember | null;
  setParticipant: (nextAuthor: ExtendedMember | null) => void;
};

type ExtendedOption = Option<{
  personId: string;
  roleId: string;
}>;

const AddSupervisorToAuthor = ({ participants, participant, setParticipant }: Props) => {
  const onSelectChange = useCallback(
    (nextParticipant: ExtendedOption | null) => {
      const participantToSet = participants.find(participantItem => {
        const isEqualPersonId = participantItem?.person?.id === nextParticipant?.value?.personId;
        const isEqualRoles = participantItem.role?.id === nextParticipant?.value?.roleId;

        return isEqualRoles && isEqualPersonId;
      });

      setParticipant(participantToSet ?? null);
    },
    [participants, setParticipant],
  );

  const options = useMemo<ExtendedOption[]>(
    () =>
      participants.map(participantItem => ({
        label: participantItem.person?.fullName ?? '',
        value: {
          personId: participantItem.person?.id ?? '',
          roleId: participantItem.role?.id ?? '',
        },
      })),
    [participants],
  );

  const currentOption = useMemo<ExtendedOption | null>(() => {
    if (participant) {
      return {
        label: participant.person?.fullName ?? '',
        value: {
          personId: participant.person?.id ?? '',
          roleId: participant.role?.id ?? '',
        },
      };
    }

    return null;
  }, [participant]);

  return (
    <FormComponent.Line>
      <FormComponent.Field label="ФИО" isRequired>
        <InputSelect onSelectChange={onSelectChange} options={options} value={currentOption} />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

export const Component = memo(AddSupervisorToAuthor);
