import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export type Arguments = {
  hasToolbarButtonMode?: boolean;
};

export function GetPersonalForeignSecurityDocumentList({ hasToolbarButtonMode = false }: Arguments): T.Specification<State> {
  return {
    apiID: 'GetPersonalForeignSecurityDocumentList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Объекты интеллектуальной собственности не аффилированные с университетом',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController({ hasToolbarButtonMode }),
    isWithQna: true,
  };
}
