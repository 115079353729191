import { Form, ForeignSecurityDocument } from 'types/models';
import { SecurityDocumentMemberType } from 'utils/Enums';

export function validate(secDoc?: ForeignSecurityDocument | null, formFields?: Form.Fields) {
  if (!formFields) {
    return [];
  }
  const required = Object.keys(formFields)
    .filter((fieldName: string) => formFields[fieldName].required && !formFields[fieldName]?.value)
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  if (
    (secDoc?.members?.filter(m => m.type?.value === SecurityDocumentMemberType.AUTHOR) || []).reduce(
      (a, b) => a + b.contribution,
      0,
    ) > 100
  ) {
    required.push({ isValid: false, invalidMessage: 'Общий вклад не может превышать 100%' });
  }

  return [...required];
}
