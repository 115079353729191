import React from 'react';
import block from 'bem-cn';

import { buttonIcons, FormComponent, IconButton, Radio, SectionTitle } from 'components';

import { FieldType } from 'types/models/Merge';
import useController from './controller';
import { Field } from 'features/MergePerson/Field/Field';
import { MergeFieldRadioValue, ColumnType } from './types';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import * as helpers from 'features/MergePerson/helpers/helpers';
import { PortfolioCard } from 'features/Form/looks/person';

import './style.scss';

export type Props = {
  entityIds: string[];
  onClose: () => void;
  entity: string;
  onUpdate?: () => void;
};

const b = block('merge-form');

export const Component = ({ entityIds, onClose, entity, onUpdate }: Props) => {
  const {
    merge,
    personId,
    isPersonViewFormOpen,
    setIsPersonViewFormOpen,
    mergeFieldRadios,
    extendedCommonColumn,
    isLoading,
    setIsCollectionConflicResolved,
    onCommonRadioClick,
    getIsConflicted,
    updateEntityMergeFieldRadio,
    onPersonClick,
    setValue,
    saveMerge,
  } = useController({
    entityIds,
    onClose,
    entity,
    onUpdate,
  });

  return (
    <>
      <FormComponent.Template>
        <ControlPanel handleSave={saveMerge} isDisabled={isLoading} />
        <FormComponent.Wrapper specialStyles={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div className={b('column-sticky')}>
            <FormComponent.Column specialStyles={{ width: '100%' }}>
              <>
                <SectionTitle>
                  <div className={b('title')}>
                    <span>Результат объединения</span>
                  </div>
                </SectionTitle>
                {extendedCommonColumn.map(field => (
                  <div style={{ paddingRight: '15px' }}>
                    <Field
                      isCommon
                      isLabelShows
                      key={field.name}
                      isConflictedMarkerShows
                      isConflicted={getIsConflicted(field)}
                      field={field}
                      setValue={setValue({ type: ColumnType.COMMON, fieldName: field.name })}
                      isDisabled={!helpers.getIsCommonFieldEditable(field) || isLoading}
                    />
                  </div>
                ))}
              </>
            </FormComponent.Column>
          </div>
          <FormComponent.ColumnWrapper customStyles={{ justifyContent: 'start', paddingLeft: '55px' }}>
            <>
              {merge.entities.map(ent => (
                <FormComponent.Column specialStyles={{ width: '100%', paddingLeft: '0px' }}>
                  <>
                    <SectionTitle>
                      <div className={b('title')}>
                        <div className={b('title-radio')}>
                          <Radio
                            value={merge.commonId ?? undefined}
                            list={[{ label: '', value: ent.id }]}
                            onChange={onCommonRadioClick}
                          />
                        </div>
                        <span>Элемент c ID: {ent.id}</span>
                        <div className={b('icon')}>
                          <IconButton icons={buttonIcons.loop} onClick={() => onPersonClick(ent.id)} />
                        </div>
                      </div>
                    </SectionTitle>
                    {ent.fields.map(field => (
                      <div
                        key={field.name}
                        className={b('entity-field', { 'without-radio': field.type === FieldType.COLLECTION })}
                      >
                        {field.type !== FieldType.COLLECTION && (
                          <div className={b('entity-radio')}>
                            <Radio
                              isDisabled={!helpers.getIsEntityFieldCanSelected(field) || isLoading}
                              value={mergeFieldRadios?.[ent.id][field.name]}
                              list={[{ label: '', value: MergeFieldRadioValue.SELECTED }]}
                              onChange={updateEntityMergeFieldRadio({
                                fieldName: field.name,
                                entityId: ent.id,
                                value: field.value,
                              })}
                            />
                          </div>
                        )}
                        <Field
                          field={field}
                          setValue={setValue({ type: ColumnType.ENTITY, entityId: ent.id, fieldName: field.name })}
                          setIsCollectionConflictResolved={setIsCollectionConflicResolved({
                            fieldName: field.name,
                            entityId: ent.id,
                          })}
                          isDisabled
                        />
                      </div>
                    ))}
                  </>
                </FormComponent.Column>
              ))}
            </>
          </FormComponent.ColumnWrapper>
        </FormComponent.Wrapper>
      </FormComponent.Template>
      {isPersonViewFormOpen && (
        <PortfolioCard
          isOpen={isPersonViewFormOpen}
          id={personId}
          onClose={() => setIsPersonViewFormOpen(false)}
          shortMode={true}
        />
      )}
    </>
  );
};
