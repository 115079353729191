import { MAX_UPLOAD_SIZE_MB } from 'shared/constants';

type FileSizeArguments = {
  file: File;
  fileSizeInBytes?: number;
};
type ImageFileSizeArguments = {
  file: File;
  sizeMb: number;
};
type FileTypeArgumants = {
  file: File;
  acceptType: string;
  fileMIME: string[];
};

type ImageFilePermission = {
  uploadImageSizes: number[];
  permissions: number[];
};

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
export const getFileSize = (bytes: number, decimals: number = 2, only?: typeof sizes[keyof typeof sizes]) => {
  const K_UNIT = 1024;

  if (bytes == 0) return '0 Byte';
  if (only)
    return `${(
      bytes /
      Math.pow(
        K_UNIT,
        sizes.findIndex(i => i === only),
      )
    ).toFixed(decimals)} ${only}`;

  let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + ' ' + sizes[i];

  return resp;
};

export const isFileSizeError = ({ file, fileSizeInBytes = MAX_UPLOAD_SIZE_MB * 1024 * 1024 }: FileSizeArguments) => {
  return file && file.size >= fileSizeInBytes;
};

export const isImageSizeError = ({ file, sizeMb }: ImageFileSizeArguments) => {
  return file && file.size >= sizeMb * 1024 * 1024;
};

export const isTypeError = ({ file, acceptType, fileMIME }: FileTypeArgumants) => {
  if (acceptType === 'image') {
    let validFormat = false;
    if (fileMIME.includes(file.type.split('/').pop() as string)) {
      validFormat = true;
      return false;
    }

    if (!validFormat) return true;
  }

  return file?.type ? file?.type?.indexOf(acceptType) === -1 : true;
};

export const isImagePermission = ({ uploadImageSizes, permissions }: ImageFilePermission) => {
  return uploadImageSizes[0] > permissions[0] || uploadImageSizes[1] > permissions[1];
};
