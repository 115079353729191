import { useCallback, useMemo } from 'react';

import { buttonIcons } from 'components';
import { ExtraToolbarButton, ExtraToolbarClickHandler } from 'components/ListEdit/model';

import { showNotification } from 'features/Notifications';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { Award } from 'types/models';

const useController = () => {
  const awardColumns = useMemo(
    () => [
      { label: 'Категория награды', formatValue: (row: Award) => row?.category?.label || '' },
      { label: 'Наименование награды', formatValue: (row: Award) => row?.name || '' },
      { label: 'Номинация', formatValue: (row: Award) => row.nomination ?? '' },
      { label: 'Получатели', formatValue: (row: Award) => row.recipients?.map(x => x.person?.fullName).join(', ') || '' },
      { label: 'Документ', formatValue: (row: Award) => row.file?.name || '' },
    ],
    [],
  );

  const onClickDownloadFile = useCallback<ExtraToolbarClickHandler<Award>>(award => {
    if (award !== null) {
      const token = getAuthToken();
      if (award.file?.id && token) {
        downloadFile(award.file.id, token);
      } else {
        showNotification({ message: 'Загрузка файла невозможна. Для данной записи файл не был выбран. ', theme: 'danger' });
      }
    } else {
      showNotification({ message: 'Выберите награду, для скачивания файла', theme: 'danger' });
    }
  }, []);

  const awardsExtraToolbarButtons = useMemo<ExtraToolbarButton<Award>[]>(
    () => [
      {
        icons: buttonIcons.download,
        checkIsDisabled: row => !row,
        onClick: onClickDownloadFile,
      },
    ],
    [onClickDownloadFile],
  );
  return { awardColumns, awardsExtraToolbarButtons };
};

export default useController;
