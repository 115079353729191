import React from 'react';
import block from 'bem-cn';

import { Header, Toolbar, ListEditTable, ConfirmPopup, FormComponent, Modal, TextInput, TextInputMode } from 'components';

import { Component as Block } from './block';
import { useController } from './controller';

import '../style.scss';

const b = block('useful');

function UsefulLinks() {
  const {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setAddModalCaption,
    setAddModalUrl,
    addModalButtons,
    addModalCaption,
    handleAddModalCaptionChange,
    addModalUrl,
    handleAddModalUrlChange,
    isEditModalOpen,
    setIsEditModalOpen,
    editModalButtons,
    selectedRowIndex,
    editModalCaption,
    handleEditModalCaptionChange,
    editModalUrl,
    handleEditModalUrlChange,
    list,
    GetIndex,
    cutUrlValue,
    setSelectedRowIndex,
    isProfile,
    buttons,
  } = useController();
  return (
    <>
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isConfirmPopupOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsConfirmPopupOpen(false)}
        onConfirm={handleDeleteButtonClick}
        icon="warning"
      />

      <Modal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setAddModalCaption('');
          setAddModalUrl('');
        }}
        title="Добавление ссылки"
        size="medium"
      >
        <Toolbar buttons={addModalButtons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Название" isRequired>
              <TextInput value={addModalCaption} onChange={handleAddModalCaptionChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ссылка" isRequired>
              <TextInput mode={TextInputMode.url} value={addModalUrl} onChange={handleAddModalUrlChange} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        title="Редактирование списка ссылок"
        size="medium"
      >
        <Toolbar buttons={editModalButtons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Название" isRequired>
              <TextInput
                value={editModalCaption}
                onChange={handleEditModalCaptionChange}
                isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ссылка" isRequired>
              <TextInput
                mode={TextInputMode.url}
                value={editModalUrl}
                onChange={handleEditModalUrlChange}
                isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Scroll maxHeight={250}>
            <ListEditTable
              rows={list}
              columns={[
                { label: '№', formatValue: row => (GetIndex(row.id) + 1).toString(), styles: { width: '5%' } },
                { label: 'Название', formatValue: row => row.caption, styles: { width: '20%' } },
                { label: 'Ссылка', formatValue: row => cutUrlValue(row.url), styles: { width: '75%' } },
              ]}
              selectedRowIndex={selectedRowIndex}
              selectRow={setSelectedRowIndex}
            />
          </FormComponent.Scroll>
        </FormComponent.Wrapper>
      </Modal>

      <div className={b()}>
        <Header>Полезные ссылки</Header>
        {!isProfile && (
          <div className={b('toolbar')}>
            <Toolbar buttons={buttons} />
          </div>
        )}
        <div className={b('list')}>
          {list.map(e => (
            <Block props={e} key={e.id + e.createdDate} />
          ))}
        </div>
      </div>
    </>
  );
}

export const Component = React.memo(UsefulLinks);
