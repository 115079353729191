import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { Permits } from 'utils/Permissions';
import { Table } from 'types/models';
import { DetailProps } from '..';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  tableState: Table.State;
  // loadParticipation: State['loadParticipation'];
};

export const useController = ({ tableState }: Props & Partial<DetailProps>) => {
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const isViewPermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits[`PARTICIPATION_${selectedRow?.['id:typ']}_VIEW` as keyof Permits]);
  }, [selectedRow, userPermission]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      // {
      //   icons: buttonIcons.medal,
      //   title: selectedRow?.awards ? 'Награды' : 'У участия нет наград',
      //   code: 'awards',
      //   isDisabled: !selectedRow?.awards,
      //   onClick: () => {
      //     if (selectedRow) {
      //       loadParticipation(selectedRow.id);
      //     }
      //   },
      // },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow || !isViewPermitted,
        onClick: handleViewButtonClick,
        permissionName: [
          Permits.PARTICIPATION_CONCERT_VIEW,
          Permits.PARTICIPATION_CONFERENCE_VIEW,
          Permits.PARTICIPATION_CONTEST_VIEW,
          Permits.PARTICIPATION_EXPOSITION_VIEW,
        ],
      },
    ],
    [selectedRow, isViewPermitted, handleViewButtonClick],
  );

  return {
    buttons,
    isViewFormOpen,
    handleTemplateCloseViewForm,
  };
};
