import React from 'react';

import { FormComponent, TextInput } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose(): void;
};

const LibraryIdForm = ({ onClose }: Props) => {
  const { formFields, magazineName, saveForm, isHasEditFieldPermission } = useController({ onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveForm} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Журнал">
            <span>{magazineName}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.libraryId.title}>
            <TextInput
              value={formFields.libraryId.value}
              onChange={formFields.libraryId.onChange}
              isDisabled={!isHasEditFieldPermission}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
};

export const Component = React.memo(LibraryIdForm);
