import React, { memo } from 'react';
import { setup } from 'bem-cn';

import { Document } from 'types/models';
import { Documents } from 'features/Documents';

const block = setup({ mod: '--' });
const b = block('ready-reports');

type Props = {
  documents: Document[];
  setDocuments: React.Dispatch<Document[]>;
  addCallback: (document: Document, startEdit: (document: Document) => void) => void;
  editCallback: (document: Document, startEdit: () => void) => void;
  deleteCallback: (document: Document, startEdit: () => void) => void;
};

const ReadyReports = ({ documents, setDocuments, addCallback, editCallback, deleteCallback }: Props) => (
  <div className={b()}>
    <Documents
      documents={documents}
      setDocuments={setDocuments}
      addCallback={addCallback}
      editCallback={editCallback}
      deleteCallback={deleteCallback}
    />
  </div>
);

export const Component = memo(ReadyReports);
