import { Table as T, ScientificIndex as S } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { ChildField } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/types';
import { ThematicCategory } from './ThematicCategory/ThematicCategory';

type Props = {
  childFields: ChildField[];
  enumMap: T.EnumMap;
  citationSystems: CitationSystem[];
  citationSystem: CitationSystem | null;
  selectedScientificIndex: S.ScientificIndex | null;
  setChildFields(fields: ChildField[]): void;
};

function Childs(props: Props) {
  const { childFields, enumMap, citationSystems, citationSystem, setChildFields, selectedScientificIndex } = props;

  return (
    // THEMATIC_CATEGORY_RELATIVE
    <ThematicCategory
      childFields={childFields}
      enumMap={enumMap}
      citationSystems={citationSystems}
      citationSystem={citationSystem}
      setRows={setChildFields}
      selectedScientificIndex={selectedScientificIndex}
    />
  );
}

export { Childs };
