import { Partner } from 'types/models/Partner';

export function validate(childPartner: Partner | null) {
  const isValidPartner = childPartner !== null;
  const isValidRelation = Boolean(childPartner?.relation);

  return [
    { isValid: isValidPartner, invalidMessage: 'Выберите организацию' },
    { isValid: isValidRelation, invalidMessage: 'Выберите форму отношений' },
  ];
}
