import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

const CommunityAddTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  templateName,
}: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetCommunity',
    template: templateName,
    relatedTableState,
    name,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавление члена сообщества" size={['large', 'small-height']}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityAddTemplate);
