import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Color } from 'constants/colors';

export const GetExpositionParticipationList: Table.Specification = {
  apiID: 'GetExpositionParticipationList',
  header: {
    firstLevel: {
      title: 'Участия и награды в выставках',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
  highlightEntryPredicate: entry => ({ rowTheme: !!entry.LibraryNote ? Color.danger : '' }),
  isWithQna: true,
};
