import * as R from 'ramda';

import { NirRequest, Publication } from 'types/models';
import { ScientistPerson } from 'types/models/Person';

import {
  excludeIndicesIDsCodesForIG,
  excludeIndicesIDsCodesForML,
  excludeIndicesIDsCodesForONG,
  excludeIndicesIDsCodesForPD,
  excludeIndicesIDsCodesForPP,
} from './constants';
import { NirRequestStatus } from 'utils/Enums';

export function getMockNirRequest(): NirRequest.NirRequest {
  return {
    annotation: '',
    approvements: [],
    collaborationParticipation: '',
    collectiveBaseDissertations: [],
    collectiveConferenceParticipations: [],
    collectiveEducationalPerformers: [],
    collectiveExpositionParticipations: [],
    collectiveForeignPerformers: [],
    collectiveForeignProjects: [],
    collectiveForeignSecurityDocuments: [],
    collectiveProjects: [],
    collectivePublications: [],
    collectiveRanPerformers: [],
    collectiveSecurityDocuments: [],
    collectiveYoungPerformers: [],
    criticalTechnologies: [],
    department: null,
    departmentText: '',
    departmentTxt: '',
    documents: [],
    economicSectorInterraction: '',
    englishAnnotation: '',
    englishDepartmentTxt: '',
    englishGoal: '',
    englishKeyWords: '',
    englishLeader: '',
    englishName: '',
    englishRelevance: '',
    estimateExpencesJustification: '',
    estimatePositions: [],
    expectedResults: [],
    goal: '',
    grntis: [],
    id: null,
    indicators: [],
    indices: [],
    interdisciplinaryAproachJustification: '',
    keyWords: '',
    lot: null,
    name: '',
    notAffiliatedCollectivePublications: [],
    notAffiliatedDescriptionPublications: [],
    ntrStrategies: [],
    oecds: [],
    performers: [],
    pnis: [],
    pnrJustification: '',
    pnrs: [],
    problemResearchState: '',
    publications: [],
    registered: '',
    relevance: '',
    requestNumber: '',
    researchMethods: '',
    researchPracticalSignificance: '',
    scientificGroundwork: '',
    scientificProblem: '',
    specification: 'BASIC_RESEARCH',
    stages: [],
    stageTasks: [],
    status: { label: 'Черновик', value: NirRequestStatus.DRAFT },
    tender: null,
    tenderType: { label: 'Лаборатории мирового уровня с привлечением ведущих ученых', value: 'LMU' },
    udks: [],
  };
}

export function getMockStage(): NirRequest.Stage {
  return {
    amount: '',
    id: null,
    name: '',
    year: '',
    universityEffect: '',
    regionalEffect: '',
    nationalEffect: '',
  };
}

export function getMockPerformer(): NirRequest.Performer {
  return {
    id: null,
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    historyLabel: '',
    job: null,
    person: null,
    rank: null,
    role: null,
    collaborationParticipation: '',
    foreignPerformerQ1Q2PublicationQty: '',
    personQ1Q2PublicationQty: '',
    economicContractParticipationExperience: '',

    awards: null,
    keyWords: null,
    note: null,
    primePublications: null,
    projects: null,
    q1PublicationQty: null,
    rids: null,
    scientificGroundwork: null,
    totalPublicationQty: null,
  };
}

const getPersonContactsString = (person: ScientistPerson) => {
  const contacts = [person?.scientist?.phone, person?.scientist?.mobilePhone, person?.scientist?.email]
    .filter(i => !!i)
    .join(', ');
  return contacts ? ` (${contacts})` : '';
};

export function getResponsiblePerformerName(request: NirRequest.NirRequest | null | undefined) {
  const currentPersonData = request?.performers.find(x => x.role?.value === 'RESPONSIBLE_PERFORMER')?.person;
  return currentPersonData ? currentPersonData.fullName + getPersonContactsString(currentPersonData) : null;
}

export function getHeadName(request: NirRequest.NirRequest | null | undefined) {
  const currentPersonData = request?.performers.find(x => x.role?.value === 'LEADER')?.person;
  return currentPersonData ? currentPersonData.fullName + getPersonContactsString(currentPersonData) : null;
}

export function getHeadNirRequest(nirRequest: NirRequest.NirRequest | null) {
  return nirRequest?.performers.find(x => x.role?.value === 'LEADER') ?? null;
}

export function getLeadScientist(nirRequest: NirRequest.NirRequest | null) {
  return nirRequest?.performers.find(x => x.role?.value === 'LEAD_SCIENTIST') ?? null;
}

export function getMockPublication(): Publication {
  return {
    id: '',
    bibliographicRecord: '',
    departments: [],
    members: [],
    type: { label: 'Статья', value: 'ARTICLE' },
    doi: '',
    scopusCitations: '',
    scopusQuartile: '',
    wosCitations: '',
    wosQuartile: '',
  };
}

export function formatDegreeRank(performer: NirRequest.Performer) {
  return [
    // degreeStr
    performer.degree?.refDegree?.label || '',
    performer.degree?.dateAssign || '',
    // rankStr
    performer.rank?.refRank?.label || '',
    performer.rank?.dateAssign || '',
    // academicRank
    performer.academicRank?.refAcademicRank?.label || '',
    performer.academicRank?.academy?.label || '',
  ]
    .filter(x => x)
    .join(' ');
}

export function formatIdentifiers(performer: NirRequest.Performer) {
  const sorted = R.sort(
    (x, y) => (x.citationSystem?.label || '').localeCompare(y.citationSystem?.label || ''),
    performer.person?.identifiers ?? [],
  );
  return sorted.map(id => `${id.citationSystem?.label}: ${id.identifier}`).join(', ') ?? '';
}

export function formatIndexHirsh(performer: NirRequest.Performer) {
  const sorted = R.sort(
    (x, y) => (x.citationSystem?.label || '').localeCompare(y.citationSystem?.label || ''),
    performer.person?.hirshIndexes ?? [],
  );
  return sorted.map(x => `${x.citationSystem?.label} - ${x.value} (${x.date})`).join(', ') ?? '';
}

export function mergeEstimatePositions(
  estimatePositions: NirRequest.EstimatePosition[],
  kindConsumptions: NirRequest.KindConsumption[],
) {
  const activeKindConsumptions = kindConsumptions.filter(x => x.isActive);
  return activeKindConsumptions.reduce<NirRequest.EstimatePosition[]>((positions, kindConsumption) => {
    const foundConsumption = positions.find(x => x.kindConsumption.id === kindConsumption.id);
    return foundConsumption ? positions : [...positions, { id: null, amount: '0.00', kindConsumption, note: '' }];
  }, estimatePositions);
}

export function mergeExpectedResult(stages: NirRequest.Stage[], expectedResults: NirRequest.ExpectedResult[]) {
  return stages.map<NirRequest.ExpectedResult>(stage => {
    const foundExpectedResult = expectedResults.find(expectedResult => expectedResult.year === stage.year);
    return foundExpectedResult || { id: null, year: stage.year, result: '' };
  });
}

export function mergeStageTasks(stages: NirRequest.Stage[], stageTasks: NirRequest.StageTask[]) {
  return stages.map<NirRequest.StageTask>(stage => {
    const foundStageTask = stageTasks.find(stageTask => stageTask.year === stage.year);
    return foundStageTask || { id: null, year: stage.year, task: '', englishTask: '' };
  });
}

export function getMaxSymbolsInvalidMessage(fieldName: string) {
  return `Поле - "${fieldName}" превышен лимит по количеству знаков`;
}

export function getPreparedNir(nirRequest: NirRequest.NirRequest) {
  if (!nirRequest) {
    return nirRequest;
  }

  function defaultPerform(request: NirRequest.NirRequest): NirRequest.NirRequest {
    return { ...request, performers: sortPerformers(request.performers) };
  }

  const updatersMap: Record<NirRequest.TenderType, (nir: NirRequest.NirRequest) => NirRequest.NirRequest> = {
    IG: R.compose(updateIndicatorsByIG, defaultPerform),
    LMU: defaultPerform,
    ML: R.compose(updateIndicatorsByML, defaultPerform),
    ONG: R.compose(updateIndicatorsByONG, defaultPerform),
    PD: R.compose(updateIndicatorsByPD, defaultPerform),
    PP: R.compose(updateIndicatorsByPP, defaultPerform),
  };
  const updater = updatersMap[nirRequest.tenderType.value];

  return updater(nirRequest);
}

export const updateIndicatorsByPP = R.partial(getFilteredIndicators, [excludeIndicesIDsCodesForPP]);
export const updateIndicatorsByONG = R.partial(getFilteredIndicators, [excludeIndicesIDsCodesForONG]);
export const updateIndicatorsByPD = R.partial(getFilteredIndicators, [excludeIndicesIDsCodesForPD]);
export const updateIndicatorsByIG = R.partial(getFilteredIndicators, [excludeIndicesIDsCodesForIG]);
export const updateIndicatorsByML = R.partial(getFilteredIndicators, [excludeIndicesIDsCodesForML]);

function getFilteredIndicators(indicesCodes: string[], nir: NirRequest.NirRequest) {
  const indices = nir.indices.filter(x => {
    const code = (x.refResultItem.customFields?.code ?? null) as string | null;
    return !code ? x : !indicesCodes.includes(code);
  });

  return { ...nir, indices };
}

export function sortPerformers(performers: NirRequest.Performer[]) {
  return R.sort((x, y) => (x.person?.fullName ?? '').localeCompare(y.person?.fullName ?? ''), performers);
}
