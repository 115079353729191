import { useMemo } from 'react';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { SecurityDocumentPayment } from 'types/models';
import { downloadFile, getAuthToken } from 'utils/Helpers';

const useDownloadButton = () => {
  const token = getAuthToken();
  const extraToolbarButtons = useMemo<ExtraToolbarButton<SecurityDocumentPayment>[]>(
    () => [
      {
        icons: buttonIcons.download,
        title: 'Скачать файл',
        code: 'downloadFile',
        checkIsDisabled: payment => !payment?.file?.id,
        onClick: payment => downloadFile(payment?.file?.id, token),
      },
    ],
    [token],
  );

  return {
    extraToolbarButtons,
  };
};

export default useDownloadButton;
