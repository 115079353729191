import React, { memo } from 'react';

import { Event, Table } from 'types/models';
import { Component as EventParticipations } from 'features/Form/looks/event/views/EventContestParticipations';

type Props = {
  participations: Event.Participations[];
  selectedRowIndex: number | null;
  setIsFormOpen?: any;
  setSelectedRowIndex: React.Dispatch<number | null>;
  workMode: Table.WorkMode;
  fetch: () => void;
};

const Participations = ({ workMode, participations, setIsFormOpen, selectedRowIndex, setSelectedRowIndex, fetch }: Props) => (
  <EventParticipations
    participations={participations}
    selectedRowIndex={selectedRowIndex}
    setIsFormOpen={setIsFormOpen}
    setSelectedRowIndex={setSelectedRowIndex}
    workMode={workMode}
    fetch={fetch}
  />
);

export const Component = memo(Participations);
