import React from 'react';

import { Project } from 'types/models';

import { FormComponent } from 'components';

import { SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';

import { JobPeriods } from './JobPeriods/JobPeriods';
import { Stages } from './Stages/Stages';

import { useController } from './controller';

type Props = {
  disabled: boolean;
  performer: Project.Performer;
  setPerformer(performer: Project.Performer): void;
  stages: Project.Stage[];
  startProjectDate?: string;
  endProjectDate?: string;
  projectId: string | null;
};

export function Fields(props: Props) {
  const { performer, disabled, stages, startProjectDate, endProjectDate, setPerformer, projectId } = props;

  const { handleUpdateMember, setJobPeriods, setStages } = useController({
    performer,
    setPerformer,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired>
          <SelectPerson
            member={getMemberWithoutHistory(performer.person)}
            onUpdateMember={handleUpdateMember}
            withHistory={false}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <JobPeriods
            disabled={disabled}
            jobPeriods={performer.jobPeriods}
            person={performer.person}
            setJobPeriods={setJobPeriods}
            performerStages={performer.stages}
            projectStages={stages}
            startProjectDate={startProjectDate}
            endProjectDate={endProjectDate}
            setPerformerStages={setStages}
            projectId={projectId}
          />
        </FormComponent.Column>
        <FormComponent.Column>
          <Stages disabled={disabled} performer={performer} setStages={setStages} stages={stages} />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
