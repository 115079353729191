import { InputSelect, SectionTitle, FormComponent, Option, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { Form } from 'types/models';

type Props = {
  viewMode?: boolean;
  formFields: Form.Fields;
  provideKindOptions: Option[];
};

export function RequestProvides(props: Props) {
  const { viewMode, formFields, provideKindOptions } = props;

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Wrapper>
          <SectionTitle title="Обеспечение заявки (по условию лота в конкурсе)" />
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Сумма">
              <TextInput
                mode={TextInputMode.number}
                value={formFields.requestProvideAmount.value ? formFields.requestProvideAmount.value.toString() : '0.0'}
                onChange={formFields.requestProvideAmount.onChange}
                isDisabled
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Вид обеспечения">
              <InputSelect
                options={provideKindOptions}
                value={provideKindOptions.find(x => x.value === formFields.requestProvideKind.value) ?? null}
                onSelectChange={formFields.requestProvideKind.onChange}
                disabled={!formFields.lot?.value?.isSupportRequest || viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Примечание' }}
                value={formFields.requestProvideNote.value}
                onChange={formFields.requestProvideNote.onChange}
                isDisabled={!formFields.lot?.value?.isSupportRequest || viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <SectionTitle title="Обеспечение контракта (по условию лота в конкурсе)" />
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Сумма">
              <TextInput
                mode={TextInputMode.number}
                value={formFields.contractProvideAmount.value ? formFields.contractProvideAmount.value.toString() : '0.0'}
                onChange={formFields.contractProvideAmount.onChange}
                isDisabled
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Вид обеспечения">
              <InputSelect
                options={provideKindOptions}
                value={provideKindOptions.find(x => x.value === formFields.contractProvideKind.value) ?? null}
                onSelectChange={formFields.contractProvideKind.onChange}
                disabled={!formFields.lot?.value?.isSupportContract || viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Примечание' }}
                value={formFields.contractProvideNote.value}
                onChange={formFields.contractProvideNote.onChange}
                isDisabled={!formFields.lot?.value?.isSupportContract || viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Wrapper>
          <SectionTitle title="Обеспечение гарантийных обязательств (по условию лота в конкурсе)" />
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Сумма">
              <TextInput
                mode={TextInputMode.number}
                value={formFields.warrantyProvideAmount.value ? formFields.warrantyProvideAmount.value.toString() : '0.0'}
                onChange={formFields.warrantyProvideAmount.onChange}
                isDisabled
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Вид обеспечения">
              <InputSelect
                options={provideKindOptions}
                value={provideKindOptions.find(x => x.value === formFields.warrantyProvideKind.value) ?? null}
                onSelectChange={formFields.warrantyProvideKind.onChange}
                disabled={!formFields.lot?.value?.isSupportWarranty || viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Примечание' }}
                value={formFields.warrantyProvideNote.value}
                onChange={formFields.warrantyProvideNote.onChange}
                isDisabled={!formFields.lot?.value?.isSupportWarranty || viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
