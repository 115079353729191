import { ForeignSecurityDocument } from 'types/models';

import * as R from 'ramda';
import { Settings } from 'types/models/common';
import { convertSecurityDocumentMember } from 'services/BackendAPI/configurations/securityDocument/converters';
import { convertMaybeArray, convertReference, getId, getText, convertItem, convertDocument } from '../commonConverters';

export function convertForeignSecurityDocument(secDoc: any, settings: Settings): ForeignSecurityDocument {
  return {
    id: getId(secDoc),
    name: getText(secDoc.Name),
    number: getText(secDoc.Number),
    startDate: getText(secDoc.StartDate),
    endDate: getText(secDoc.EndDate),
    status: secDoc.Status ? convertItem<string>(secDoc.Status) : null,
    documentType: secDoc.Type ? convertItem<string>(secDoc.Type) : null,
    kind: secDoc.Kind ? convertReference(secDoc.Kind) : null,
    members: convertMaybeArray(secDoc.Members.Member, m => convertSecurityDocumentMember(m, settings)).sort(
      (a, b) => parseFloat(a?.id || '0') - parseFloat(b?.id || '0'),
    ),
    documents: [
      ...(secDoc.Documents && secDoc.Documents.Document
        ? convertMaybeArray(secDoc.Documents.Document, R.partial(convertDocument, ['local']))
        : []),
    ],
  };
}
