import { Parameter, RadioValue } from 'types/models';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateRadioParameter = (radioParameter: Parameter): ValidatorResult => {
  const { required } = radioParameter;
  const value = radioParameter.value as RadioValue;

  if (required) {
    const isValid = !!value;
    return { isValid, message: isValid ? '' : `Выберите хотя бы одно значение в поле "${radioParameter.description}"` };
  }

  return { isValid: true, message: '' };
};

export default validateRadioParameter;
