import { useState, useLayoutEffect, useCallback, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, IconButtonProps } from 'components';

import { Form as F, Form, Table as T } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { ValueOf } from 'types/helpers';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './validate';
import { ProjectPerformerOrder, Project } from 'types/models/Project';
import { Original } from 'types/models/Form';
import { getMockPerformerOrder } from 'features/Form/looks/project/ProjectPerformerOrderForm/helpers';
import { getEnum } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { GetFullScienceProjectList } from 'features/Table/specifications';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode: initialViewMode, editMode: initialEditMode, onClose }: Props) {
  const {
    look: { id, projectId },
  } = useFormContext<Form.Look & { projectId?: string }>();

  const { enumMap } = useAppDataContext();

  const [performerOrder, setPerformerOrder] = useState<ProjectPerformerOrder>(getMockPerformerOrder());
  const [project, setProject] = useState<Project | null>(null);

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode: initialViewMode, editMode: initialEditMode });

  const disabled = useMemo(() => {
    return workMode === 'viewMode';
  }, [workMode]);

  const tableStreams = useLocalTableStreams();
  const { methods: savePerformerOrder } = BackendAPI.useBackendAPI('SaveProjectPerformerOrder');
  const { methods: getPerformerOrder } = BackendAPI.useBackendAPI('GetProjectPerformerOrder');
  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject');

  const makeChangeHandler = useCallback(
    (key: keyof ProjectPerformerOrder) => (value: ValueOf<ProjectPerformerOrder> | Original) => {
      setPerformerOrder((prevPerformerOrder: ProjectPerformerOrder) => ({ ...prevPerformerOrder!, [key]: value }));
    },
    [],
  );

  const loadProject = useCallback(
    (projId: string) => {
      getProject.callAPI(
        { id: projId },
        {
          onSuccessfullCall: ({ data }) => {
            setProject(data);
          },
        },
      );
    },
    [getProject, setProject],
  );

  const handleProjectChange = useCallback(
    (value: Original | null) => {
      if (value?.id) {
        makeChangeHandler('project')(value);
        loadProject(value.id);
      }
    },
    [makeChangeHandler, loadProject],
  );

  const loadPerformerOrder = useCallback(
    (performerOrderId: string) => {
      getPerformerOrder.callAPI(
        { id: performerOrderId },
        {
          onSuccessfullCall: ({ data }) => {
            setPerformerOrder(data);
            if (data.project?.id) {
              loadProject(data.project.id);
            }
          },
        },
      );
    },
    [getPerformerOrder, loadProject],
  );

  const onSubmit = useCallback(
    (needClose: boolean) => {
      const errors = validate(performerOrder).filter(m => !m.isValid);
      if (errors && errors.length > 0) {
        const message = `${errors.map(({ invalidMessage }) => invalidMessage).join(', ')}`;
        showNotification({ message, theme: 'danger' });
        return false;
      }
      savePerformerOrder.callAPI(
        {
          ...performerOrder,
          id: id || performerOrder.id || null,
          project: { id: performerOrder.project?.id || projectId || '', label: '' },
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Приказ успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              loadPerformerOrder(data.id);
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      performerOrder,
      id,
      onClose,
      savePerformerOrder,
      tableStreams,
      updateWorkModeAfterSaveAndContinue,
      loadPerformerOrder,
      projectId,
    ],
  );
  const performerOrderTypeOptions = getEnum('ProjectPerformerOrderType', enumMap);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        code: 'add',
        onClick: () => onSubmit(true),
        isHidden: disabled,
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        code: 'addAndSave',
        onClick: () => onSubmit(false),
        isHidden: disabled,
      },
    ],
    [disabled, onSubmit],
  );

  const orderYear = useMemo(() => {
    return performerOrder.date ? parse(performerOrder.date || '', formatStr, new Date()).getFullYear() : new Date().getFullYear();
  }, [performerOrder]);

  const orderDefaultText = useMemo(() => {
    const txt = `в соответствии с техническим заданием на выполнение НИР ${project?.number || ''} «${project?.name || ''}»<br />
      ПРИКАЗЫВАЮ:<br />
      Утвердить состав научного коллектива для выполнения проекта:<br />
      Вывести из состава научного коллектива:<br />
        Научному коллективу приступить к выполнению этапа ${orderYear}г. 
      НИР ${project?.number || ''} «${project?.name || ''}» в соответствии с техническим заданием.<br />
        Контроль за исполнением приказа оставляю за собой.`;
    return txt;
  }, [project, orderYear]);

  const startDatePerformers = useMemo(() => {
    return (
      project?.performers
        ?.filter(perf => perf.jobPeriods.some(p => p.startDateOrder?.id === performerOrder?.id))
        .sort((x1, x2) => {
          const x2StartDate = x2.jobPeriods.find(p => p.startDateOrder?.id === performerOrder?.id)?.startDate;
          const x1StartDate = x1.jobPeriods.find(p => p.startDateOrder?.id === performerOrder?.id)?.startDate;
          return x2StartDate !== x1StartDate
            ? parse(x2StartDate || '', formatStr, new Date()).getTime() -
                parse(x1StartDate || '', formatStr, new Date()).getTime()
            : String(x1.person?.fullName).localeCompare(x2.person?.fullName || '');
        }) || []
    );
  }, [project, performerOrder]);

  const endDatePerformers = useMemo(() => {
    return (
      project?.performers
        ?.filter(perf => perf.jobPeriods.some(p => p.endDateOrder?.id === performerOrder?.id))
        .sort((x1, x2) => {
          const x2EndDate = x2.jobPeriods.find(p => p.endDateOrder?.id === performerOrder?.id)?.endDate;
          const x1EndDate = x1.jobPeriods.find(p => p.endDateOrder?.id === performerOrder?.id)?.endDate;
          return x2EndDate !== x1EndDate
            ? parse(x2EndDate || '', formatStr, new Date()).getTime() - parse(x1EndDate || '', formatStr, new Date()).getTime()
            : String(x1.person?.fullName).localeCompare(x2.person?.fullName || '');
        }) || []
    );
  }, [project, performerOrder]);

  const projectRowConverter = useCallback<(row: T.Entry) => F.Translation>(row => {
    const dates = row.period ? row.period.split(' - ') : ['', ''];
    return {
      id: row.id,
      name: row.name || row.Name,
      type: { label: row.projectType },
      number: row.projectNumber || row.ProjectNumber,
      startDate: dates[0],
      endDate: dates[1],
    };
  }, []);

  const projectSpecification = GetFullScienceProjectList({});

  const projectOriginal = useMemo((): Original => {
    return {
      id: project?.id || '',
      name: project?.id ? `${project?.number}, ${project.name}` : '',
    };
  }, [project]);

  useEffect(() => {
    if (!id) {
      makeChangeHandler('order')(orderDefaultText);
    }
  }, [orderDefaultText, makeChangeHandler, id]);

  useLayoutEffect(() => {
    if (id) {
      loadPerformerOrder(id);
    }
    if (projectId && projectId !== '-1') {
      loadProject(projectId);
    }
    // eslint-disable-next-line
  }, []);

  return {
    performerOrderId: id ?? null,
    performerOrder,
    buttons,
    workMode,
    makeChangeHandler,
    disabled,
    performerOrderTypeOptions,
    project,
    startDatePerformers,
    endDatePerformers,
    projectSpecification,
    projectRowConverter,
    projectOriginal,
    projectId,
    handleProjectChange,
  };
}
