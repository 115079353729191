import React from 'react';

import { Button, ButtonMode } from 'components';

import { showNotification } from 'features/Notifications';
import { renderToString } from 'react-dom/server';

export const Version = React.memo(() => {
  const time = 30;
  let date: string | null = '';

  const timer = setInterval(() => {
    try {
      fetch(window.location.origin).then(response => {
        const headerDate = response.headers.get('last-modified');

        if (!headerDate) {
          clearInterval(timer);
          return;
        }
        if (!date) {
          date = headerDate;
          return;
        }

        if (date !== headerDate) {
          showNotification({
            message: `Ваша версия системы устарела.<br>
              Необходимо обновить систему до актуальной.<br><br>
              Обновить сейчас?<br>
              <div style="display: flex; margin-top: 4px">
              <span onclick="window.location.reload(true)">${renderToString(
                <Button mode={ButtonMode.primary} text="Да" />,
              )}</span>
              <span style="margin-left: 8px">${renderToString(<Button mode={ButtonMode.secondary} text="Нет" />)}</span>
              </div>`,
            theme: 'warning',
            lifetime: time - 2,
          });
        }
        return;
      });
    } catch (e) {
      console.warn('check version error ->', e);
    }
  }, time * 1000);

  return <></>;
});
