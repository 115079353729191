import { useCallback } from 'react';
import { Props as ComponentProps } from './component';
import { AddNewGridSettingData } from 'features/Table/specifications/GetGridSettings/streams';
import { useAppDataContext } from 'features/AppData/context';

type Props = Pick<ComponentProps, 'onClose' | 'successfullCallback'>;

const useController = ({ onClose, successfullCallback }: Props) => {
  const { userPermission } = useAppDataContext();

  const onSubmitCallback = useCallback(
    (addNewGridSetting: AddNewGridSettingData) => {
      successfullCallback({
        isShared: addNewGridSetting.shared,
        purpose: addNewGridSetting.purpose,
        name: addNewGridSetting.settingName,
        id: addNewGridSetting.editedSettingID ?? '',
        author: userPermission?.userLogin ?? '',
      });
      onClose();
    },
    [onClose, successfullCallback, userPermission?.userLogin],
  );

  return {
    onSubmitCallback,
  };
};

export default useController;
