import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, IconButtonProps } from 'components';

import { Author, Table } from 'types/models';
import { isHasPermission } from 'features/AppData';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  loadParticipation: State['loadParticipation'];
};

export const useController = ({ tableState, loadParticipation }: Props) => {
  const tableStreams = useLocalTableStreams();
  const { currentPerson, userPermission, userSystemDepartment } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isEditDepartmentsOpen, setIsEditDepartmentsOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);

  const { methods: deleteParticipation } = BackendAPI.useBackendAPI('DeleteParticipation');

  const { methods: BindParticipationToCurrentUserDepartment } = BackendAPI.useBackendAPI(
    'BindParticipationToCurrentUserDepartment',
  );

  const author = useMemo(
    (): Author => ({
      id: null,
      category: null,
      affilateCount: 1,
      historyLabel: '',
      name: currentPerson ? currentPerson.fullName : '',
      job: currentPerson && currentPerson.scientistJobs.length ? currentPerson.scientistJobs[0] : null,
      memberAffilations: [],
      position: 0,
      person: currentPerson || null,
      education: null,
      enterprise: null,
      degree: !(currentPerson && currentPerson.degrees.length) ? null : currentPerson.degrees[0],
      citizenship: null,
      isAffilated: true,
      academicRank: !(currentPerson && currentPerson.academicRanks !== null) ? null : currentPerson.academicRanks[0],
      isReporter: null,
      role: {
        id: '3',
        label: 'Участник',
        deletable: false,
        editable: false,
        foreignName: null,
        shortName: null,
        code: '',
        customFields: {
          type: {
            value: 'CONCERT',
            label: 'Концерт',
          },
          name: 'Участник',
          code: 'PARTICIPANT',
        },
      },
      rank: null,
    }),
    [currentPerson],
  );

  const isEditPermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`PARTICIPATION_CONCERT_EDIT_${selectedRow?.['id:ParticipationStatus']}` as keyof Permits],
    );
  }, [selectedRow, userPermission]);

  const isDeletePermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`PARTICIPATION_CONCERT_DELETE_${selectedRow?.['id:ParticipationStatus']}` as keyof Permits],
    );
  }, [selectedRow, userPermission]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleOpenConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(true);
  }, []);

  const handleOpenEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCloseEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(false);
  }, []);

  const handleCloseConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleOpenWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(true);
  }, []);

  const handleCloseWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(false);
  }, []);

  const handleAttachParticipation = useCallback(() => {
    if (selectedRow) {
      BindParticipationToCurrentUserDepartment.callAPI(
        { participationId: selectedRow.id },
        {
          onSuccessfullCall: data => {
            if (data.data.success) {
              showNotification({ message: 'Участие успешно привязано', theme: 'success' });
              handleCloseWarningPopup();
            }
          },
          onFailedCall: () => {
            handleCloseWarningPopup();
          },
        },
      );
    }
  }, [selectedRow, BindParticipationToCurrentUserDepartment, handleCloseWarningPopup]);

  const handleDeleteParticipation = useCallback(() => {
    deleteParticipation.callAPI(
      { participationId: selectedRow?.id || '', workbench: 'pc' },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Участие успешно удалено', theme: 'success' });
          tableStreams.reloadTable.push();
          setIsConfirmDeleteOpen(false);
        },
        onFailedCall: () => {},
      },
    );
  }, [deleteParticipation, selectedRow?.id, tableStreams.reloadTable]);

  /**
   * buttons
   */

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.medal,
        title: selectedRow?.ParticipationAwards ? 'Награды' : 'У участия нет наград',
        code: 'awards',
        isDisabled: !selectedRow?.ParticipationAwards,
        onClick: () => {
          if (selectedRow) {
            loadParticipation(selectedRow.id);
          }
        },
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
        profilePermissionName: Permits.PARTICIPATION_CONCERT_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавление',
        code: 'add',
        onClick: handleAddButtonClick,
        profilePermissionName: Permits.PARTICIPATION_CONCERT_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактирование',
        code: 'edit',
        isDisabled: !selectedRow || !isEditPermitted,
        onClick: handleEditButtonClick,
        profilePermissionName: [Permits.PARTICIPATION_CONCERT_EDIT_ADDED, Permits.PARTICIPATION_CONCERT_EDIT_APPROVED],
      },
      {
        icons: buttonIcons.delete,
        title: 'Удаление',
        code: 'delete',
        isDisabled: !selectedRow || !isDeletePermitted,
        onClick: handleOpenConfirmDelete,
        profilePermissionName: [Permits.PARTICIPATION_CONCERT_DELETE_ADDED, Permits.PARTICIPATION_CONCERT_DELETE_APPROVED],
      },
      {
        icons: buttonIcons.attach,
        title: 'Привязать к подразделению',
        isDisabled: !selectedRow,
        onClick: handleOpenWarningPopup,
        profilePermissionName: Permits.PARTICIPATION_CONCERT_BIND_TO_DEPARTMENT,
      },
      {
        icons: buttonIcons.editRelations,
        title: 'Редактировать связь участия с подразделениями',
        code: 'editDepartments',
        isDisabled: !selectedRow,
        onClick: handleOpenEditDepartments,
        profilePermissionName: Permits.PARTICIPATION_CONCERT_DEPARTMENT_EDIT,
      },
    ],
    [
      handleHelpButtonClick,
      selectedRow,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditPermitted,
      handleEditButtonClick,
      isDeletePermitted,
      handleOpenConfirmDelete,
      handleOpenWarningPopup,
      handleOpenEditDepartments,
      loadParticipation,
    ],
  );

  return {
    buttons,
    isEditDepartmentsOpen,
    handleCloseEditDepartments,
    isConfirmDeleteOpen,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    author,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
    isWarningPopupOpen,
    handleCloseWarningPopup,
    handleAttachParticipation,
    userSystemDepartment,
    selectedRow,
  };
};
