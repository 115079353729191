import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertAvailableLockableEntities } from './converters';

const configurations = {
  GetAvailableLockableEntities: makeBackendAPIConfiguration({
    id: 'GetAvailableLockableEntities',
    endpoint: '/msa/service/commands/GetAvailableLockableEntities',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: { commandName: 'GetAvailableLockableEntities' },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertAvailableLockableEntities(response.Response.LockableEntity),
  }),
};

export const availableLockableEntitiesConfigurations = { ...configurations };
