import React from 'react';

import { ConfirmPopup, Toolbar as SharedToolbar } from 'components';
import { Table } from 'types/models';
import useController from './controller';
import * as modalTemplates from './modalTemplates';
import { MessageModal } from 'features/Form/views/MessageModal/MessageModal';
import { State } from '../makeUseCustomController';
import { purchaseRequestSubmitTextMap, purchaseRequestTextMap } from 'types/models/PurchaseRequest';
// eslint-disable-next-line max-len
import { PurchaseRequestApprovementModal } from 'features/Form/views/PurchaseRequestApprovementModal/PurchaseRequestApprovementModal';
import { PersonalHelpContents } from 'features/Table/specifications/GetPurchaseRequestList/PersonalHelpContents';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForSpecification({ customState, tableState }: Props) {
  const {
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    isViewFormOpen,
    isHelpFormOpen,
    isOpenMessageModal,
    handleMessageModalClose,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    approverKind,
  } = customState;

  const {
    buttons,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    handleTemplateCloseHelpForm,
    handleSaveStatusClick,
    newStatus,
    isAddButtonDisabled,
    isViewButtonDisabled,
    isEditButtonDisabled,
    handleApprovementSubmit,
  } = useController({ customState, tableState });

  const submitsMap: Record<string, (message: string) => void> = {
    DRAFT: message => handleSaveStatusClick(message),
    CONSIDERATION: message => handleSaveStatusClick(message),
    REVISION: message => handleSaveStatusClick(message),
    REJECTED: message => handleSaveStatusClick(message),
    IN_ACTION_COMMITTEE: message => handleSaveStatusClick(message),
    IN_ACTION_PRICE_COMMISSION: message => handleSaveStatusClick(message),
    IN_ACTION_LOGISTIC_DEPARTMENT: message => handleSaveStatusClick(message),
    IN_ACTION_PROVIDER: message => handleSaveStatusClick(message),
    COMPLETE: message => handleSaveStatusClick(message),
  };

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.PurchaseRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={onCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.PurchaseRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={onCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isAddButtonDisabled && (
        <modalTemplates.PurchaseRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={onCloseAddForm}
          relatedTableState={tableState}
          hasPersonalMode={true}
        />
      )}
      {newStatus && (
        <MessageModal
          isOpen={isOpenMessageModal}
          onClose={handleMessageModalClose}
          onSubmit={submitsMap[newStatus.toString()]}
          titleText={purchaseRequestTextMap[newStatus.toString()]}
          submitText={purchaseRequestSubmitTextMap[newStatus.toString()]}
          hasFileAttachment={newStatus === 'MESSAGE'}
        />
      )}
      <PurchaseRequestApprovementModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={handleApprovementSubmit}
        approverKind={approverKind}
      />
      {tableState.selectedRows.length > 0 && (
        <>
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранную заявку? (${tableState.selectedRows[0].id})`}
            isOpen={isDeleteWarningOpen}
            onClose={onCloseDeleteWarning}
            onConfirm={onSubmitDeleteWarning}
            okButtonText="Да"
          />
        </>
      )}
      <ConfirmPopup
        title="Заявки на закупки для научных проектов"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <PersonalHelpContents />
      </ConfirmPopup>
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForSpecification);
