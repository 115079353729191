import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ListEdit } from 'components';
import { DefaultToolbarButton } from 'components/ListEdit/model';

import { GetNirRequestPublicationList } from 'features/Table/specifications';
import { Publication } from 'types/models';

type Props = {
  title: string;
  publications: Publication[];
  setPublications(publications: Publication[]): void;
  scientistIDs: string[];
  disabled?: boolean;
  visibleToolbarButtons?: DefaultToolbarButton[];
};

export function AffiliatedPublications({
  publications,
  setPublications,
  scientistIDs,
  title,
  disabled,
  visibleToolbarButtons,
}: Props) {
  const { methods: loadRequestPublicationAPI } = BackendAPI.useBackendAPI('GetNirRequestPublication');

  return (
    <ListEdit
      rows={publications ?? []}
      onChange={setPublications}
      title={title}
      isFullScreenedTable
      isDeleteConfirmEnabled
      isToolbarDisabled={disabled}
      withMessages
      visibleToolbarButtons={visibleToolbarButtons}
      columns={[
        { label: 'Тип публикации', formatValue: x => x.type.label, styles: { width: '20%' } },
        { label: 'Библиографическая ссылка', formatValue: x => x.bibliographicRecord, styles: { width: '50%' } },
        { label: 'Показатели публикации', formatValue: x => x.indices ?? '', styles: { width: '15%' } },
        { label: 'Показатели источника', formatValue: x => x.sourceIndices ?? '', styles: { width: '15%' } },
      ]}
      specification={{
        mode: 'loadInstanceTableModal',
        relationTableModalTitle: 'Публикации',
        onStartLoad: (tableRows, onSuccess) => {
          loadRequestPublicationAPI.callAPI(
            { publicationIds: tableRows.map(x => x.id) },
            { onSuccessfullCall: ({ data }) => onSuccess(data) },
          );
        },
        modalTableSpecification: GetNirRequestPublicationList({ scientistIDs }),
      }}
    />
  );
}
