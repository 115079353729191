import { ConfirmPopup, ReferenceItem } from 'components';
import React from 'react';
import { Table, Event } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';

type Props = {
  isWarningPopupOpen: boolean;
  handleCloseWarningPopup(): void;
  handleAttachParticipation(): void;
  selectedRow: Table.Entry;
  userSystemDepartment: ReferenceItem | null;
  eventType: keyof typeof EventTypes;
};

export function BindParticipation({
  isWarningPopupOpen,
  handleCloseWarningPopup,
  handleAttachParticipation,
  selectedRow,
  userSystemDepartment,
  eventType,
}: Props) {
  if (!selectedRow || !userSystemDepartment) return null;
  return (
    <ConfirmPopup
      isOpen={isWarningPopupOpen}
      onClose={handleCloseWarningPopup}
      title="Привязка к подразделению"
      icon="warning"
      okButtonText="Ок"
      resetButtonText="Отмена"
      onConfirm={handleAttachParticipation}
    >
      <div>
        <div>Вы действительно хотите привязать данное участие к своему подразделению ({userSystemDepartment?.label})?</div>
        <br />
        <div>
          <b>Информация об участии:</b>
        </div>
        <br />
        <div>
          <b>{(EventTypes[eventType] as Event.EventType)?.label}</b>
        </div>
        <span>{selectedRow.EventFullName || selectedRow.EventName || ''}</span>
        {selectedRow.ReportTheme && (
          <>
            <br />
            <div>
              <b>Тема доклада</b>
            </div>
            <span>{selectedRow.ReportTheme}</span>
          </>
        )}
        {selectedRow.Participants && (
          <>
            <br />
            <div>
              <b>Участники</b>
            </div>
            <span>{selectedRow.Participants}</span>
          </>
        )}
      </div>
    </ConfirmPopup>
  );
}
