import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { block } from 'bem-cn';

import { FormComponent, Select } from 'components';
import { Bar } from 'react-chartjs-2';

import { COLORS, COLORS_BORDERS } from '../сolors';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function PublicationFinancingByFaculties() {
  const {
    financingByPeriod,
    categories,
    periodYearOpts,
    handleChangeActiveYearFilter,
    renderEntry,
    activeYearFilterLabel,
  } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { raw: value } = context;
      return ` ${new Intl.NumberFormat().format(value)} тыс. руб.`;
    };

    const data = {
      labels: categories,
      datasets: financingByPeriod.map(x => ({
        label: x.name,
        data: x.data,
        backgroundColor: COLORS,
        borderColor: COLORS_BORDERS,
        borderWidth: 2,
      })),
    };

    return (
      <Bar
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y',
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Научные разработки на факультетах/институтах</h3>
        <div className={b('params')}>
          <FormComponent.Line>
            <FormComponent.Field label="Период" hasLeftAlign>
              <Select.Component
                value={activeYearFilterLabel}
                variant="outlined"
                defaultPlaceholder="Не задан"
                entries={periodYearOpts.current.map(({ label }) => label)}
                onChange={handleChangeActiveYearFilter}
                renderEntry={renderEntry}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
        <div className={b('chart')} style={{ height: '260px' }}>
          {Chart()}
        </div>
      </div>
    </>
  );
}
