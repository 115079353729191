import { Form } from 'types/models';

export function validate(formFields: Form.Fields) {
  const required = Object.keys(formFields)
    .filter((fieldName: string) => formFields[fieldName].required && !formFields[fieldName]?.value)
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  return [...required];
}
