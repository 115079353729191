import { Person } from 'types/models';

import { Member } from '.';

export function getMockMember(): Member {
  return {
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    job: null,
    person: null,
    rank: null,
  };
}

export function formatJob(job: Person.ScientistJob, isShowAppointment: boolean = true) {
  const { enterpise, appointment, refAppointment, department, refDepartment, status } = job;

  const result = {
    enterpise: enterpise || '',
    status: status?.label || '',
    appointment: isShowAppointment ? appointment || refAppointment?.label || '' : '',
    department: department || refDepartment?.label || '',
  };

  const place = [result.enterpise, result.status].filter(x => x).join(', ');

  return [[result.appointment, result.department].filter(x => x).join(', '), place ? `(${place})` : ''].filter(x => x).join(' ');
}

export function formatEducation(education: Person.ScientistEducation) {
  const { status, enterprise, refSpeciality, speciality, refDepartment, department } = education;

  const result = {
    enterprise: enterprise || '',
    status: status?.label || '',
    department: refDepartment?.label || department || '',
    speciality: refSpeciality?.label || speciality || '',
  };

  return [
    [result.status, result.department, result.speciality].filter(x => x).join(', '),
    result.enterprise ? `(${result.enterprise})` : '',
  ]
    .filter(x => x)
    .join(' ');
}

export function getMemberWithoutHistory(person: Person.ScientistPerson | null): Member {
  return {
    person,
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    job: null,
    rank: null,
  };
}

export const getAcademicRankOption = ({ refAcademicRank: academyRank, academy, id }: Person.ScientistAcademicRank) => ({
  label: `${academyRank.label} ${academy.label}`,
  value: id,
});

export const getOption = (item: Person.ScientistRank | Person.ScientistDegree) => {
  const { dateAssign, id } = item;
  return {
    label: `${Person.isScientistDegree(item) ? item.refDegree?.label : item.refRank.label} (${dateAssign})`,
    value: id,
  };
};
