import { useMemo } from 'react';
import { useAppDataContext } from 'features/AppData/context';
import { Option } from 'components';
import { getEnum } from 'utils/Helpers';

const useController = () => {
  const { enumMap } = useAppDataContext();

  const roleOptions = useMemo<Option<string>[]>(() => getEnum('ForeignProjectPerformerRole', enumMap), [enumMap]);

  return {
    roleOptions,
  };
};

export default useController;
