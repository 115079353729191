import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const SecurityDocumentViewTemplate: React.FC<F.TemplateProps & { documentType: string; entityType: string }> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  entityType,
  documentType,
}: F.TemplateProps & { documentType: string; entityType: string }) => {
  const [title, setTitle] = useState<string | JSX.Element>('');
  const look: F.Look & { documentType: string } = {
    apiID: 'GetSecurityDocument',
    template: entityType === 'SECURITY_DOCUMENT' ? 'PatentForm' : 'ForeignSecurityDocumentForm',
    viewMode: true,
    id,
    relatedTableState,
    setTitle,
    documentType,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(SecurityDocumentViewTemplate);
