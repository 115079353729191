import { Dispatch, SetStateAction } from 'react';
import { Form } from 'types/models';
import { Participation } from 'types/models/Participation';
import { ParticipationFormatType } from 'utils/Enums/ParticipationFormatType';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  data: Participation;
};

const setByResponse = ({ setFormFields, data }: Props) => {
  const isExpositionExponentPresentation =
    data.form?.customFields?.code === ParticipationFormatType.EXPOSITION_EXPONENT_PRESENTATION;
  const isExpositionExposureWork = data.form?.customFields?.code === ParticipationFormatType.EXPOSITION_EXPOSURE_WORK;

  setFormFields((prevState: Form.Fields) => ({
    ...prevState,
    note: { ...prevState.note, value: data.note },
    development: { ...prevState.development, value: data.development },
    collectiveExhibitName: {
      ...prevState.collectiveExhibitName,
      value: data.collectiveExhibitName,
      required: (isExpositionExponentPresentation || isExpositionExposureWork) && data.isCollectiveExhibit,
    },
    isCollectiveExhibit: { ...prevState.isCollectiveExhibit, value: data.isCollectiveExhibit },
    exhibitType: { ...prevState.exhibitType, value: data.exhibitType, required: !!data.publication },
    exponent: { ...prevState.exponent, value: data.exponent },
    form: { ...prevState.form, value: data.form },
  }));
};

export { setByResponse };
