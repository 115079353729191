import React from 'react';
import { setup } from 'bem-cn';

import { Button, ButtonMode, Checkbox, FormComponent, Icon, TextInput, TextInputMode } from 'components';

import { useController } from './controller';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('auth__form__content');

function Login() {
  const {
    handleLoginSubmit,
    textError,
    error,
    login,
    setLogin,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    passwordType,
    setPasswordType,
  } = useController();

  return (
    <div className={b()}>
      <FormComponent.Line>
        <FormComponent.Field label="Логин">
          <TextInput id="login" value={login} onChange={setLogin} isError={!!error} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Пароль">
          <TextInput
            id="password"
            mode={passwordType as TextInputMode}
            value={password}
            onChange={setPassword}
            isError={!!error}
          />
          <Icon
            type="eye"
            size={26}
            onMouseEnter={() => setPasswordType(TextInputMode.text)}
            onMouseLeave={() => setPasswordType(TextInputMode.password)}
            onFocus={() => setPasswordType(TextInputMode.text)}
            onBlur={() => setPasswordType(TextInputMode.password)}
            classMixin="icon-eye"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <div className={b('offset').toString()}>
          <Checkbox id="rememberMe" label="Оставаться в системе" checked={rememberMe} onChange={setRememberMe} />
        </div>
      </FormComponent.Line>
      {!!textError && (
        <FormComponent.Line>
          <div className={b('error')}>{textError}</div>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <Button mode={ButtonMode.primary} text="Войти в систему" onClick={handleLoginSubmit} classMixin="button-auth" />
      </FormComponent.Line>
    </div>
  );
}

export const Component = React.memo(Login);
