import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { EditPublicationOriginalTranslatesRelations } from 'features/Form/views';
import { Form, Table } from 'types/models';

type Props = {
  disabled: boolean;
  isMagazineOriginal: boolean;
  isMagazineTranslate: boolean;
  magazinePublicationOriginal: Form.Original | null;
  magazinePublicationTranslates: Form.Translation[];
  magazineArticlePublicationOriginal: Form.Original | null;
  magazineArticlePublicationTranslates: Form.Translation[];
  magazinePublicationName: string;
  magazineArticlePublication: Form.Publication | null;
  relatedTableState?: Table.State;
  setMagazineArticlePublicationTranslates: (translations: Form.Translation[]) => void;
  setMagazineArticlePublicationOriginal: (translations: Form.Original | null) => void;
  onClose: () => void;
};

export const Component = memo(
  ({
    disabled,
    isMagazineOriginal,
    isMagazineTranslate,
    magazinePublicationOriginal,
    magazinePublicationTranslates,
    magazineArticlePublicationOriginal,
    magazineArticlePublicationTranslates,
    magazinePublicationName,
    magazineArticlePublication,
    relatedTableState,
    setMagazineArticlePublicationTranslates,
    setMagazineArticlePublicationOriginal,
    onClose,
  }: Props) => (
    <FormComponent.Wrapper>
      <EditPublicationOriginalTranslatesRelations
        disabled={disabled}
        isMagazineOriginal={isMagazineOriginal}
        isMagazineTranslate={isMagazineTranslate}
        magazinePublicationOriginal={magazinePublicationOriginal}
        magazinePublicationTranslates={magazinePublicationTranslates}
        magazineArticlePublicationOriginal={magazineArticlePublicationOriginal}
        magazineArticlePublicationTranslates={magazineArticlePublicationTranslates}
        isRelationsErrorPopupOpened={!isMagazineOriginal && !isMagazineTranslate}
        magazinePublicationName={magazinePublicationName}
        magazineArticlePublication={magazineArticlePublication}
        relatedTableState={relatedTableState}
        setMagazineArticlePublicationTranslates={setMagazineArticlePublicationTranslates}
        setMagazineArticlePublicationOriginal={setMagazineArticlePublicationOriginal}
        onRelationsErrorPopupClose={onClose}
        onRelationsErrorPopupSubmit={onClose}
      />
      <FormComponent.Actions>
        <Button mode={ButtonMode.primary} text="Продолжить" onClick={onClose} isDisabled={disabled} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  ),
);
