import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { Quarters } from 'utils/Enums/Quarters';
import { Months } from 'utils/Enums/Months';
import { Parameter, PaymentRequestPeriodValue } from 'types/models';
import { BuilderProps } from 'features/BuildReportPopup/models';
import { MONTH_LIST, MONTH_PERIOD_RADIO, QUARTER_LIST, QUARTER_PERIOD_RADIO } from './helpers';

const initialYear = new Date().getFullYear().toString();

type Props = object & BuilderProps;
const useController = ({ parameters, setParameterValueByName, parameterName }: Props) => {
  const [year, setYear] = useState<string>(initialYear);
  const [periodRadioValue, setPeriodRadioValue] = useState<'quarter' | 'month'>(QUARTER_PERIOD_RADIO);

  const [quarterId, setQuarterId] = useState<string>(Quarters.FIRST_QUARTER.id);

  const [monthFromId, setMonthFromId] = useState<string>(Months.JANUARY.id);
  const [monthToId, setMonthToId] = useState<string>(Months.JANUARY.id);

  const periodParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );

  const canBuild = useMemo<boolean>(() => !!periodParameter, [periodParameter]);

  const currentQuarter = useMemo(() => {
    const q = QUARTER_LIST.find(quarter => quarter.id === quarterId)!;
    return q;
  }, [quarterId]);

  const monthFrom = useMemo(() => {
    const currentMonthFrom = MONTH_LIST.find(month => month.id === monthFromId)!;
    return currentMonthFrom;
  }, [monthFromId]);

  const monthTo = useMemo(() => {
    const currentMonthTo = MONTH_LIST.find(month => month.id === monthToId)!;
    return currentMonthTo;
  }, [monthToId]);

  const setParameterValue = useCallback(
    (paymentRequestPeriodValue: Partial<PaymentRequestPeriodValue>) => {
      const currentYear = paymentRequestPeriodValue.year || year;
      const currentMonthRange = {
        fromId: paymentRequestPeriodValue.monthRange?.fromId || monthFromId,
        toId: paymentRequestPeriodValue.monthRange?.toId || monthToId,
      };

      const currentRadioValue = periodRadioValue;

      setParameterValueByName({
        name: parameterName,
        value: {
          year: currentYear,
          quarter: currentQuarter.id,
          monthRange: currentMonthRange,
          radioValue: currentRadioValue,
          ...paymentRequestPeriodValue,
        },
      });
    },
    [monthFromId, monthToId, parameterName, periodRadioValue, currentQuarter, setParameterValueByName, year],
  );

  const handleUpdatePeriodRadioValue = useCallback(
    (nextPeriodRadioValue: 'quarter' | 'month') => {
      setPeriodRadioValue(nextPeriodRadioValue);
      setParameterValue({});
    },
    [setParameterValue],
  );

  const periodRadioList = useMemo(
    () => [
      { label: '', value: QUARTER_PERIOD_RADIO },
      { label: '', value: MONTH_PERIOD_RADIO },
    ],
    [],
  );

  const handleUpdateMonth = useCallback(
    ({ isFrom }: { isFrom: boolean }) => (nextMonthName: string) => {
      const nextMonthIndex = MONTH_LIST.findIndex(month => month.label === nextMonthName);

      if (nextMonthIndex !== -1) {
        const nextMonth = MONTH_LIST[nextMonthIndex];

        const nextMonthRange: PaymentRequestPeriodValue['monthRange'] = {
          fromId: isFrom ? nextMonth.id : monthFrom.id,
          toId: isFrom ? monthTo.id : nextMonth.id,
        };
        setParameterValue({ monthRange: nextMonthRange });

        const setAction = isFrom ? setMonthFromId : setMonthToId;
        setAction(nextMonth.id);
      }
    },
    [monthFrom, monthTo, setParameterValue],
  );

  const handleUpdateQuarter = useCallback(
    (nextQuarterName: string) => {
      const nextQuarterIndex = QUARTER_LIST.findIndex(month => month.id === nextQuarterName);

      if (nextQuarterIndex !== -1) {
        const nextQuarter = QUARTER_LIST[nextQuarterIndex];

        setQuarterId(nextQuarter.id);
        setParameterValue({ quarter: nextQuarter.id });
      }
    },
    [setParameterValue],
  );

  const handleUpdateYear = useCallback(
    value => {
      setYear(value);
      setParameterValue({ year: value });
    },
    [setParameterValue],
  );

  useLayoutEffect(() => {
    const nextDefaultValue =
      JSON.stringify(QUARTER_PERIOD_RADIO) === periodParameter?.default ? QUARTER_PERIOD_RADIO : MONTH_PERIOD_RADIO;
    setPeriodRadioValue(nextDefaultValue);

    setParameterValue({ radioValue: nextDefaultValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isQuarterPeriod = useMemo(() => periodRadioValue === QUARTER_PERIOD_RADIO, [periodRadioValue]);
  const isMonthPeriod = useMemo(() => periodRadioValue === MONTH_PERIOD_RADIO, [periodRadioValue]);

  return {
    year,
    periodRadioValue,
    quarterId,
    currentQuarter,
    monthFromId,
    monthToId,
    monthFrom,
    monthTo,
    periodParameter,
    canBuild,
    periodRadioList,
    isQuarterPeriod,
    isMonthPeriod,
    handleUpdatePeriodRadioValue,
    handleUpdateYear,
    handleUpdateMonth,
    handleUpdateQuarter,
  };
};

export default useController;
