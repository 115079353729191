import React from 'react';

import { ConfirmPopup } from 'components';

import { AwardsModal } from 'features/AwardsModal';
import { useLocalTableStreams } from 'features/Table/hooks';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import * as modalTemplates from 'features/Table/specifications/GetConcertParticipationList/LeftPanelForThirdLevel/modalTemplates';
import { TypeControllerIsForm } from './models/typeControllerIsForm';

type Props = {
  controllerIsForm: TypeControllerIsForm;
  sourceEvent?: any;
};

const ConcertParticipationTemplate = ({ controllerIsForm, sourceEvent }: Props) => {
  const {
    isAdd,
    isApproveParticipationModal,
    isDelete,
    isEdit,
    isEditSubdivisionModal,
    isShowAwards,
    isView,
    participation,
    setAdd,
    setDelete,
    setEdit,
    setIsApproveParticipationModal,
    setIsEditSubdivisionModal,
    setIsShowAwards,
    setView,
  } = controllerIsForm;
  const tableStreams = useLocalTableStreams();
  const handleConfirmDeleteConfirmPopup = () => {
    tableStreams.deleteRow.push({
      deleteRowId: participation?.id || '',
      command: 'DeleteParticipation',
      deletedItemPropName: 'Participation',
    });
    setDelete(false);
  };
  return (
    <>
      {isAdd && (
        <modalTemplates.ConcertParticipationAddTemplate.Component
          disabledEvent
          sourceEvent={sourceEvent}
          isOpen={isAdd}
          onClose={() => setAdd(false)}
          disableUpdateAfterSubmit
        />
      )}

      {isView && (
        <modalTemplates.ConcertParticipationViewTemplate.Component
          disabledEvent
          id={participation?.id || ''}
          isOpen={isView}
          onClose={() => setView(false)}
          disableUpdateAfterSubmit
        />
      )}
      {isEdit && (
        <modalTemplates.ConcertParticipationEditTemplate.Component
          disabledEvent
          id={participation?.id || ''}
          isOpen={isEdit}
          onClose={() => setEdit(false)}
          disableUpdateAfterSubmit
        />
      )}
      {isDelete && (
        <ConfirmPopup
          isOpen={isDelete}
          okButtonText="Да"
          onClose={() => setDelete(false)}
          onConfirm={handleConfirmDeleteConfirmPopup}
          text="Вы действительно хотите удалить выбранное участие?"
          title="Предупреждение"
        />
      )}
      {isEditSubdivisionModal && (
        <EditParticipationDepartments
          onClose={() => {
            setIsEditSubdivisionModal(false);
          }}
          afterSubmit={() => {}}
          id={participation?.id || ''}
          isOpen={isEditSubdivisionModal}
          type={ParticipationTypes.PARTICIPATION_CONCERT.code}
        />
      )}
      {isApproveParticipationModal && (
        <ApproveParticipation
          onClose={() => {
            setIsApproveParticipationModal(false);
          }}
          id={participation?.id || ''}
          isOpen={isApproveParticipationModal}
          type={ParticipationTypes.PARTICIPATION_CONCERT.code}
        />
      )}
      {participation?.awards && (
        <AwardsModal isOpen={isShowAwards} onClose={() => setIsShowAwards(false)} id={participation?.id} />
      )}
    </>
  );
};

export default React.memo(ConcertParticipationTemplate);
