import { useCallback, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Project } from 'types/models/Project';
import { SubmitProps } from 'features/Table/views';

export type State = {
  isOpenForm: boolean;
  reloadTable(): void;
  isOpenSelectRequestModal: boolean;
  isOpenConfirmDeleteModal: boolean;
  isOpenCodeChangesModal: boolean;
  isOpenEditPartial: boolean;
  isCopyOpen: boolean;
  paramType: string;
  submitCopyForm: (preparedProject: Project) => void;
  mode: Mode | null;
  deleteProject(id: string): void;
  setMode(mode: Mode | null): void;
  setIsOpenSelectRequestModal(isOpen: boolean): void;
  setIsOpenConfirmDeleteModal(isOpen: boolean): void;
  setIsOpenCodeChangesModal(isOpen: boolean): void;
  setIsOpenEditPartial(isOpen: boolean): void;
  setParamType(value: string): void;
  handleSubmitSelectRequestModal(props: SubmitProps): void;
  programRequestId: string;
  nirRequestId: string;
  securityDocumentContractId: string;
  setIsOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  closeForm(): void;
  copiedProject?: Project;
  setIsCopyOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type Mode = 'edit' | 'add' | 'view';

export function makeUseCustomController() {
  return function useCustomController(): State {
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenSelectRequestModal, setIsOpenSelectRequestModal] = useState(false);
    const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
    const [isCopyOpen, setIsCopyOpen] = useState<boolean>(false);
    const [isOpenCodeChangesModal, setIsOpenCodeChangesModal] = useState(false);
    const [isOpenEditPartial, setIsOpenEditPartial] = useState(false);
    const [paramType, setParamType] = useState<string>('');
    const [mode, setMode] = useState<Mode | null>(null);
    const [programRequestId, setProgramRequestId] = useState<string>('');
    const [nirRequestId, setNirRequestId] = useState<string>('');
    const [securityDocumentContractId, setSecurityDocumentContractId] = useState<string>('');
    const [copiedProject, setCopiedProject] = useState<Project>();

    const { methods: deleteProjectAPI } = BackendAPI.useBackendAPI('DeleteProject');

    const tableStreams = useLocalTableStreams();

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push();
    }, [tableStreams.reloadTable]);

    const closeForm = useCallback(() => {
      setIsOpenForm(false);
      setIsCopyOpen(false);
      setMode(null);
      setProgramRequestId('');
      setNirRequestId('');
      setSecurityDocumentContractId('');
      setCopiedProject(undefined);
    }, []);

    const deleteProject = useCallback(
      (id: string) => {
        deleteProjectAPI.callAPI(
          { id },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Проект успешно удален', theme: 'success' });
              reloadTable();
            },
          },
        );
      },
      [deleteProjectAPI, reloadTable],
    );

    const handleSubmitSelectRequestModal = useCallback(
      ({ programRequestId: programId, nirRequestId: nirId, securityDocumentContractId: sdcId }: SubmitProps) => {
        if (programId) setProgramRequestId(programId);
        if (nirId) setNirRequestId(nirId);
        if (sdcId) setSecurityDocumentContractId(sdcId);
        setIsOpenSelectRequestModal(false);
        setIsOpenForm(true);
      },
      [],
    );
    const submitCopyForm = useCallback((project: Project) => {
      setCopiedProject(project);
      setMode('add');
      setIsOpenForm(true);
    }, []);

    return {
      isOpenForm,
      isOpenSelectRequestModal,
      isCopyOpen,
      submitCopyForm,
      setIsOpenSelectRequestModal,
      handleSubmitSelectRequestModal,
      reloadTable,
      mode,
      setMode,
      deleteProject,
      isOpenConfirmDeleteModal,
      setIsOpenConfirmDeleteModal,
      isOpenCodeChangesModal,
      setIsOpenCodeChangesModal,
      isOpenEditPartial,
      setIsOpenEditPartial,
      paramType,
      setParamType,
      programRequestId,
      nirRequestId,
      securityDocumentContractId,
      setIsOpenForm,
      closeForm,
      copiedProject,
      setIsCopyOpen,
    };
  };
}
