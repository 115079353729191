import { EditableNominee } from 'features/IncentivePayments';
import { Department, EventType } from 'types/models/Payment';
import { convertDocumentToRequest } from '../commonConverters';
import { SavePaymentRequest } from './types';
import { isEditableNomineeJob } from 'features/IncentivePayments/helpers';

export function convertNomineesToRequest(nominees: EditableNominee[], type: EventType | string) {
  const getJobId = (nominee: EditableNominee) =>
    isEditableNomineeJob(nominee.job) ? nominee.job.id : nominee.job?.originalId ?? '';

  return nominees.map(x => ({
    _attr: { id: x.person.id },
    Affiliated: String(x.isAffiliated),
    ...(x.topLevelDepartment?.id ? { Department: { _attr: { id: x.topLevelDepartment.id } } } : {}),
    ...(x.job ? { Job: { _attr: { id: getJobId(x) } } } : {}),
    ...(x.isPaid && x.isAffiliated ? { Payment: { _attr: { money: x.factMoney } } } : {}),
    ...(type === 'MEDAL' && x.planMoney ? { PlanMoney: x.planMoney } : {}),
  }));
}

export function convertDepartmentsToRequest(departments: Department[]) {
  return departments.map(x => ({
    _attr: { id: x.topLevelDepartment.id },
    AmountMoney: x.amountMoney,
  }));
}

export function convertPaymentToRequest({ authorPayment, comment, eventType }: SavePaymentRequest) {
  return {
    PaymentRequest: {
      _attr: { id: authorPayment.id, isPrechangeStatusSave: 'false', ...(comment ? { saveComment: comment } : {}) },
      Status: authorPayment.status?.value,
      RegulationPayment: {
        Paragraph: authorPayment.regulationParagraph,
        Name: authorPayment.regulationName,
      },
      MaxNomineesMoney: authorPayment.maxNomineesMoney,
      MaxFundMoney: authorPayment.maxFundMoney,
      Nominee: convertNomineesToRequest(authorPayment.nominees, eventType),
      Department: convertDepartmentsToRequest(authorPayment.departments),
      year: authorPayment.year,
      quarter: authorPayment.quarter?.value,
      month: authorPayment.month,
      DistributionMethodBetweenFunds: {
        _attr: { manual: String(authorPayment.isManualEdit) },
        _text: authorPayment.distributionMethodBetweenFunds,
      },
      Documents: {
        Document: authorPayment.documents.filter(x => x.docType === 'local').map(convertDocumentToRequest),
      },
    },
  };
}
