import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { MipPartner, Table } from 'types/models';
import { DeleteProps } from '../GetPartnerList/makeUseCustomController';

export type State = {
  loadMipPartner(mipPartnerId: string | null, onLoad: (mipPartner: MipPartner.MipPartner) => void, type?: string): void;
  deleteMipPartner(deleteProps: DeleteProps): void;
  mipPartner: MipPartner.MipPartner | null;
  isOpenForm: boolean;
  closeForm(): void;
  openForm(): void;
  mode: Mode | null;
  setMode(mode: Mode | null): void;
  reloadTable(): void;
  uniqID: string;
};

export type Mode = 'edit' | 'add' | 'view';

export function makeUseCustomController(uniqID: string) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    const [mipPartner, setMipPartner] = useState<MipPartner.MipPartner | null>(null);
    const [isOpenForm, setIsOpenForm] = useState(false);

    const [mode, setMode] = useState<Mode | null>(null);

    const tableStreams = useLocalTableStreams();

    const { methods: getMipPartnerAPI } = BackendAPI.useBackendAPI('GetPartnerMip');
    const { methods: deletePartnerAPI } = BackendAPI.useBackendAPI('DeletePartner');

    const [row] = selectedRows;

    const loadMipPartner = useCallback(
      (id: string | null, onLoad: (mipPartner: MipPartner.MipPartner) => void, type?: string) => {
        getMipPartnerAPI.callAPI(
          {
            partnerId: id,
            enterpriseCustomerId: type === 'ENTERPRISE_CUSTOMER' ? id : null,
            enterpriseId: type === 'ENTERPRISE' ? id : null,
            shortName: type === 'RAW' ? row.ShortName ?? null : null,
            fullName: type === 'RAW' ? row.FullName : null,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setMipPartner(data);
              onLoad(data);
            },
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [row],
    );

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push();
    }, [tableStreams.reloadTable]);

    const closeForm = useCallback(() => {
      setIsOpenForm(false);
      setMipPartner(null);
      setMode(null);
    }, []);

    const openForm = useCallback(() => {
      setIsOpenForm(true);
    }, []);

    const deleteMipPartner = useCallback(
      (deleteProps: DeleteProps) => {
        deletePartnerAPI.callAPI(deleteProps, {
          onSuccessfullCall: () => {
            showNotification({ message: 'МИП успешно удалён', theme: 'success' });
            reloadTable();
          },
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reloadTable],
    );

    return {
      loadMipPartner,
      closeForm,
      mipPartner,
      isOpenForm,
      openForm,
      mode,
      setMode,
      deleteMipPartner,
      reloadTable,
      uniqID,
    };
  };
}
