import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';

const UserDepartment = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    allRadioLabel="Все подразделения пользователя"
    enumProps={{
      fieldLabel: 'Факультет/Институт',
      listEditTitle: 'Подразделения пользователя',
      mode: { component: 'SelectUserDepartments' },
    }}
  />
);

const Component = memo(UserDepartment);

export { Component as UserDepartment };
