import { Parameter, PaymentRequestPeriodValue } from 'types/models';
import { MONTH_LIST, MONTH_PERIOD_RADIO, QUARTER_LIST, QUARTER_PERIOD_RADIO } from './helpers';

const queryBuilder = (paymentRequestPeriodParameter: Parameter) => {
  const value = paymentRequestPeriodParameter.value as PaymentRequestPeriodValue;
  let paymentRequestPeriodQuery = `&paymentRequestPeriod=${value.radioValue}&Year=${value.year}`;

  if (value.radioValue === MONTH_PERIOD_RADIO) {
    const fromIndex = MONTH_LIST.findIndex(month => month.id === value.monthRange.fromId);
    const toIndex = MONTH_LIST.findIndex(month => month.id === value.monthRange.toId);

    const result = [];

    for (let i = fromIndex; i <= toIndex; i += 1) {
      result.push(MONTH_LIST[i].id);
    }

    paymentRequestPeriodQuery += `&months=${result.join(',')}`;
  } else if (value.radioValue === QUARTER_PERIOD_RADIO) {
    const index = QUARTER_LIST.findIndex(quarter => quarter.id === value.quarter);

    const result = [];

    result.push(QUARTER_LIST[index].id);

    paymentRequestPeriodQuery += `&quarters=${result.join(',')}`;
  }

  return paymentRequestPeriodQuery;
};

export default queryBuilder;
