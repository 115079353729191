export type State = {
  type: string;
};

export function makeUseCustomController({ type }: State) {
  return function useCustomController(): State {
    return {
      type,
    };
  };
}
