import { Contact } from 'types/models/Partner';

export function validate(contact: Contact | null) {
  if (!contact) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidPerson = Boolean(contact.person);

  return [{ isValid: isValidPerson, invalidMessage: 'Выберите персону' }];
}
