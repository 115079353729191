import { Table } from 'types/models';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { Color } from 'constants/colors';

export function detPeriodHighlights(entry: Table.Entry, days: number) {
  const daysOffset = days * 86400 * 1000; // n days in milliseconds
  const upToTime = parse(entry.UpToDate, formatStr, new Date()).getTime();
  const deadLine = upToTime - daysOffset;
  const softDeeadline = deadLine - daysOffset;
  const now = new Date().getTime();

  if (now < upToTime) {
    if (now > softDeeadline && now < deadLine) {
      // in soft deadline period
      return {
        rowTheme: Color.warning,
      };
    } else {
      if (now > deadLine) {
        // in hard deadline
        return {
          rowTheme: Color.danger,
        };
      } else {
        return {
          rowTheme: Color.primary,
        };
      }
    }
  } else {
    // totally expired
    return {
      rowTheme: Color.danger,
    };
  }
}
