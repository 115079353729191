import { useState, useLayoutEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FileInfo, Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import type { StateRegistrationReport } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { validate } from './validate';
import { getMockStateRegistrationReport } from '../helpers';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose: () => void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const [stateRegistrationReport, setStateRegistrationReport] = useState<StateRegistrationReport>(
    getMockStateRegistrationReport(),
  );

  const tableStreams = useLocalTableStreams();

  const {
    look: { id },
  } = useFormContext();

  const { methods: getProjectStateRegistrationReport } = BackendAPI.useBackendAPI('GetProjectStateRegistrationReport', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as StateRegistrationReport;
      setStateRegistrationReport(preparedData);
    },
  });

  const { methods: saveProjectStateRegistrationReport } = BackendAPI.useBackendAPI('SaveProjectStateRegistrationReport', {
    onSuccessfullCall: () => {
      onClose();
      tableStreams.reloadTable.push();
      showNotification({ message: 'Отчет успешно сохранен', theme: 'success' });
    },
  });

  const handleStageNameChange = useCallback(
    (stageName: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, stageName });
    },
    [stateRegistrationReport],
  );
  const handleExpectedResultsChange = useCallback(
    (expectedResults: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, expectedResults });
    },
    [stateRegistrationReport],
  );
  const handleInterdisciplinaryAproachJustificationChange = useCallback(
    (interdisciplinaryAproachJustification: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, interdisciplinaryAproachJustification });
    },
    [stateRegistrationReport],
  );
  const handleReportNameChange = useCallback(
    (reportName: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, reportName });
    },
    [stateRegistrationReport],
  );
  const handleReferatChange = useCallback(
    (referat: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, referat });
    },
    [stateRegistrationReport],
  );
  const handleKeyWordsChange = useCallback(
    (keyWords: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, keyWords });
    },
    [stateRegistrationReport],
  );
  const handleCitisDateChange = useCallback(
    (citisDate: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, citisDate });
    },
    [stateRegistrationReport],
  );
  const handleReportIkNumberChange = useCallback(
    (reportIkNumber: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, reportIkNumber });
    },
    [stateRegistrationReport],
  );
  const handleReportArchiveNumberChange = useCallback(
    (reportArchiveNumber: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, reportArchiveNumber });
    },
    [stateRegistrationReport],
  );
  const handleReportCustomerSendDateChange = useCallback(
    (reportCustomerSendDate: string) => {
      setStateRegistrationReport({ ...stateRegistrationReport, reportCustomerSendDate });
    },
    [stateRegistrationReport],
  );

  const handleFileChange = useCallback(
    (fileInfo: FileInfo) => {
      setStateRegistrationReport({ ...stateRegistrationReport, file: fileInfo });
    },
    [stateRegistrationReport],
  );

  const onSubmit = useCallback(() => {
    const validateInfo = validate(stateRegistrationReport);
    const invalidFields = validateInfo.filter(({ isValid }) => !isValid);
    const isValid = !invalidFields.length;
    if (isValid) {
      saveProjectStateRegistrationReport.callAPI({ stateRegistrationReport });
    } else {
      showErrorsMessages(invalidFields.map(({ invalidMessage }) => invalidMessage));
    }
  }, [saveProjectStateRegistrationReport, stateRegistrationReport]);

  useLayoutEffect(() => {
    if (id) getProjectStateRegistrationReport.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    handleCitisDateChange,
    handleExpectedResultsChange,
    handleFileChange,
    handleInterdisciplinaryAproachJustificationChange,
    handleKeyWordsChange,
    handleReferatChange,
    handleReportArchiveNumberChange,
    handleReportCustomerSendDateChange,
    handleReportIkNumberChange,
    handleReportNameChange,
    handleStageNameChange,
    onSubmit,
    stateRegistrationReport,
    workMode,
  };
}
