import { convertItem, getText } from '../commonConverters';
import { ProjectPerformerOrder } from 'types/models/Project';

export function convertProjectPerformerOrder(pa: any): ProjectPerformerOrder {
  return {
    id: pa?._attributes?.id ?? null,
    project: pa.Project ? { id: pa.Project._attributes.id, label: '' } : null,
    type: convertItem(pa.Type),
    number: getText(pa.Number),
    date: getText(pa.Date),
    order: getText(pa.Order),
  };
}
