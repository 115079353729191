import React from 'react';

import { Table as T } from 'types/models';

type RequestData = {
  contestRequestId: string;
};

export function GetContestRequestChangeList(requestData: RequestData): T.Specification<object, RequestData> {
  return {
    apiID: 'GetContestRequestChangeList',
    header: {
      thirdLevel: {
        LeftPanel: () => <div />,
        withTemplatesPanel: true,
      },
    },
    requestData,
  };
}
