import React, { memo } from 'react';

import { Toolbar } from 'components';
import { Table as T } from 'types/models';
import { useController } from './controller';
import { ViewModal } from './viewModal';

type Props = {
  tableState: T.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { isViewModalOpen, handleViewModalClose, buttons, selectedRow } = useController({
    tableState,
  });

  return (
    <>
      {isViewModalOpen && selectedRow && (
        <ViewModal isViewModalOpen={isViewModalOpen} onViewModalClose={handleViewModalClose} selectedRow={selectedRow} />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = memo(LeftPanelForThirdLevel);
