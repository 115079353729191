import { useCallback, useEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { QnaFrequentQuestion } from 'types/models/Qna';
import { parse } from 'date-fns';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';

export type QnaItem = {
  id: string;
  message: string;
  answer: string;
  hashtags: string[];
};
export function useController() {
  const { methods: getQnaFrequentQuestions } = BackendAPI.useBackendAPI('GetQnaFrequentQuestions');

  const [frequentQuestions, setFrequentQuestions] = useState<QnaItem[]>([]);

  const loadFrequentQnaQuestions = useCallback(() => {
    getQnaFrequentQuestions.callAPI(
      { id: undefined },
      {
        onSuccessfullCall: (result: any) => {
          setFrequentQuestions(
            !(result?.data && result?.data?.length > 0)
              ? []
              : result.data.sort((q1: QnaFrequentQuestion, q2: QnaFrequentQuestion) => {
                  if (q1.priority !== q2.priority) {
                    return q2.priority - q1.priority;
                  }
                  return (
                    parse(q2.createdDate, formatDateTimeStr, new Date()).getTime() -
                    parse(q1.createdDate, formatDateTimeStr, new Date()).getTime()
                  );
                }),
          );
        },
      },
    );
  }, [getQnaFrequentQuestions]);

  useEffect(() => {
    loadFrequentQnaQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    frequentQuestions,
  };
}
