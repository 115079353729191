import { Parameter } from 'types/models';

const queryBuilder = (abstractBooleanParameter: Parameter) => {
  if (abstractBooleanParameter.value) {
    return `&${abstractBooleanParameter.name}=${abstractBooleanParameter.value}`;
  }

  return '';
};

export default queryBuilder;
