import React, { useCallback } from 'react';
import { block } from 'bem-cn';
import * as M from 'features/Table/specifications/GetReferenceElementList/model';

import { Checkbox, InputSelect, TextArea, FormComponent, TextDateTime, TextInput } from 'components';

import { EnumMap } from 'types/models/Table';
import { PasswordField } from './PasswordField/PasswordField';
import { ReferenceField } from './ReferenceField/ReferenceField';
import { getEnum } from 'utils/Helpers';

import './style.scss';

const b = block('referece-metadata-fields');

type Props = {
  fields: M.EditableMetadataField[];
  onChange(name: string, value: M.FieldValue): void;
  enumMap: EnumMap;
  mode: M.Mode | null;
  isRecordEditable: boolean;
};

function Fields(props: Props) {
  const { fields, onChange, enumMap, mode, isRecordEditable } = props;
  const isAddMode = mode === 'add';
  const isViewMode = mode === 'view';
  const isDisabled = useCallback(
    (isFieldEditable: boolean) => {
      return !isAddMode && (isViewMode || !(isRecordEditable || isFieldEditable));
    },
    [isAddMode, isRecordEditable, isViewMode],
  );

  const mapFields: Record<M.EditableMetadataField['type'], (field: any) => JSX.Element> = {
    STRING: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      if (name === 'password') {
        return <PasswordField value={value ?? ''} onChange={val => onChange(name, val)} isDisabled={isDisabled(editable)} />;
      }
      return <TextInput value={value ?? ''} onChange={val => onChange(name, val)} isDisabled={isDisabled(editable)} />;
    },
    BOOLEAN: (field: M.EditableBooleanMetadataField) => {
      const { name, value, editable } = field;
      return <Checkbox isDisabled={isDisabled(editable)} checked={!!value} onChange={val => onChange(name, val)} />;
    },
    DATE: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return (
        <TextDateTime value={value} onChange={(date: string) => onChange(name, date ?? '')} isDisabled={isDisabled(editable)} />
      );
    },
    DATETIME: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return (
        <TextDateTime
          type={['date', 'time']}
          rangeButtons={['singeDate', 'toInfinite', 'fromInfinite']}
          value={value}
          onChange={(date: string) => onChange(name, date ?? '')}
          isDisabled={isDisabled(editable)}
          customStyles={{ maxWidth: '100%' }}
        />
      );
    },
    TEXT: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return (
        <TextArea
          settings={{ isFixed: true }}
          value={value ?? ''}
          onChange={val => onChange(name, val)}
          isDisabled={isDisabled(editable)}
        />
      );
    },
    ENUMERATION: (field: M.EditableEnumerationMetadataField) => {
      const { enumeration, name, value, editable } = field;
      const foundEnumVal = getEnum(enumeration, enumMap).find(x => x.value === value?.value);
      return (
        <InputSelect
          value={foundEnumVal ? { label: foundEnumVal.label, value: foundEnumVal.value } : null}
          options={enumMap[enumeration].values.map(x => ({ label: x.label, value: x.value }))}
          onSelectChange={option => onChange(name, option)}
          disabled={isDisabled(editable)}
        />
      );
    },
    INTEGER: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return <TextInput value={value ?? ''} onChange={val => onChange(name, val)} isDisabled={isDisabled(editable)} />;
    },
    REFERENCE: (field: M.EditableReferenceMetadataField) => (
      <ReferenceField field={field} isDisabled={isDisabled(field.editable)} onChange={onChange} />
    ),
    DOUBLE: () => <div>a</div>,
  };

  return (
    <div className={b()}>
      {fields.map((field, index) => {
        const { type, caption, required } = field;
        const render = mapFields[type];
        return (
          <FormComponent.Line
            key={index}
            lineSize={type !== 'REFERENCE' ? (type === 'DATETIME' ? 'padded' : 'doublePadded') : ''}
          >
            <FormComponent.Field label={caption} isRequired={required}>
              {render(field)}
            </FormComponent.Field>
          </FormComponent.Line>
        );
      })}
    </div>
  );
}

export { Fields };
