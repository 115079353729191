import React from 'react';
import { ListEdit, SectionTitle } from 'components';
import { formatNumber } from 'utils/Helpers';
import { OtherProject } from 'types/models';
import { getMockFinancing } from 'features/Form/looks/otherProject/getMockModel';
import { showNotification } from 'features/Notifications';
import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  financings: OtherProject.Financing[];
  setFinancings: (financings: any[]) => void;
  isDisabled: boolean;
};

export const FinancingListEdit = ({ financings, setFinancings, isDisabled }: Props) => (
  <>
    <SectionTitle title="Финансирование" />
    <ListEdit
      withMessages
      isDeleteConfirmEnabled
      rows={financings}
      isToolbarDisabled={isDisabled}
      onChange={setFinancings}
      defaultRowsCount={3}
      columnIndexesForSumTotal={[0]}
      maxHeight="350px"
      columns={[
        { label: 'Сумма', formatValue: x => formatNumber(x.amount), styles: { width: '50%' } },
        { label: 'Год', formatValue: x => x.year, styles: { width: '50%' } },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (financing, setFinancing) => (
          <Fields financing={financing ?? getMockFinancing()} setFinancing={setFinancing} />
        ),
        validation: {
          checkIsValid: (financing, index, financingList, mode) =>
            validate(financing, index, financingList, mode).every(x => x.isValid),
          onInvalidate: (financing, mode, index, financingList) => {
            validate(financing, index, financingList, mode).forEach(({ invalidMessage }) => {
              setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
            });
          },
        },
      }}
    />
  </>
);
