import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

type RequestData = {
  partnerId: string;
};

export const GetPartnerMipPaymentList = ({ partnerId }: RequestData): Table.Specification<object, RequestData> => ({
  apiID: 'GetPartnerMipPaymentList',
  header: {
    firstLevel: {
      title: 'Лицензионные платежи',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: Toolbar,
    },
  },
  isFiltersHidden: true,
  requestData: { partnerId },
});
