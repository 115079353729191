import { PublicationTypeCode } from 'types/models/Form';
import React, { memo } from 'react';
import { ConfirmPopup } from 'components';
import useController from './controller';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  publicationTypeCode: PublicationTypeCode;
};

export const PublicationArticlesWarningPopup = memo(({ isOpen, onClose, onConfirm, publicationTypeCode }: Props) => {
  const { title, text } = useController({ publicationTypeCode });

  return (
    <ConfirmPopup
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title={title}
      text={text}
      okButtonText="Ок"
      resetButtonText="Отмена"
    />
  );
});
