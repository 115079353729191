import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';

import { Settings } from 'types/models/common';
import {
  GetOtherProjectRequest,
  SaveOtherProjectRequest,
  ApproveOtherProjectRequest,
  SavePersonalOtherProjectRequest,
} from './types';

import { makeTableRequestXMLConverter, makeParametersXMLElement } from '../commonConverters';
import { convertOtherProject } from './converters';
import { convertOtherProjectToXML } from './requestConverters';
import { GetOtherProjectListRequest } from '../types';

export const otherProjectGridConfigurations = {
  GetPersonalOtherProjectList: makeBackendTableAPIConfiguration(
    'GetPersonalForeignProjectList',
    makeTableRequestXMLConverter('GetPersonalForeignProjectList', () => makeParametersXMLElement([])),
  ),
  GetOtherProjectList: makeBackendTableAPIConfiguration(
    'GetForeignProjectList',
    makeTableRequestXMLConverter('GetForeignProjectList', ({ panelState }: GetOtherProjectListRequest) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
};

export const otherProjectConfigurations = {
  GetOtherProject: makeBackendAPIConfiguration({
    id: 'GetOtherProject',
    endpoint: '/msa/service/commands/GetForeignProject',
    convertInputToXMLElement: ({ id }: GetOtherProjectRequest) => ({
      Request: {
        _attr: { commandName: 'GetForeignProject' },
        ForeignProject: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertOtherProject(response?.Response?.ForeignProject, settings),
  }),
  SaveOtherProject: makeBackendAPIConfiguration({
    id: 'SaveForeignProject',
    endpoint: '/msa/service/commands/SaveForeignProject',
    convertInputToXMLElement: ({ otherProject }: SaveOtherProjectRequest) => ({
      Request: {
        _attr: { commandName: 'SaveForeignProject' },
        ForeignProject: convertOtherProjectToXML(otherProject),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SavePersonalOtherProject: makeBackendAPIConfiguration({
    id: 'SavePersonalForeignProject',
    endpoint: '/msa/service/commands/SavePersonalForeignProject',
    convertInputToXMLElement: ({ otherProject }: SavePersonalOtherProjectRequest) => ({
      Request: {
        _attr: { commandName: 'SavePersonalForeignProject' },
        ForeignProject: convertOtherProjectToXML(otherProject),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ApproveOtherProject: makeBackendAPIConfiguration({
    id: 'ApproveForeignProject',
    endpoint: '/msa/service/commands/ApproveForeignProject',
    convertInputToXMLElement: ({ id, isDisapprove }: ApproveOtherProjectRequest) => ({
      Request: {
        _attr: { commandName: 'ApproveForeignProject' },
        ForeignProject: { _attr: { id, disapprove: isDisapprove } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
