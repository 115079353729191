import { SecurityDocumentRefElement } from 'types/models';
import React from 'react';
import { RefList } from 'features/Form/looks/project/ProjectForm/views';
import { FormComponent } from 'components';
import { useController } from './controller';

type Props = {
  title: string;
  rows: SecurityDocumentRefElement[];
  onChange(refs: SecurityDocumentRefElement[]): void;
  modalTitle: string;
  refName: string;
  disabled?: boolean;
};

export function ClassifierEdit({ title, rows, onChange, modalTitle, refName, disabled }: Props) {
  const { extraToolbarButtons, handleClassifierChange } = useController({
    disabled,
    onChange,
  });
  return (
    <FormComponent.Line>
      <RefList
        modalTitle={modalTitle}
        refName={refName}
        rows={rows}
        onChange={handleClassifierChange}
        title={title}
        columns={[
          // eslint-disable-next-line max-len
          { label: 'Наименование', styles: { width: '70%' }, formatValue: (x: SecurityDocumentRefElement) => x.ref?.label ?? '' },
          {
            label: 'Источник',
            styles: { width: '15%' },
            formatValue: (x: SecurityDocumentRefElement) => (x?.isProject ? 'Проект' : 'Охранный документ'),
          },
          {
            label: 'Актуальный',
            styles: { width: '15%' },
            formatValue: x => x?.isActual,
          },
        ]}
        extraToolbarButtons={extraToolbarButtons}
        disabled={disabled}
      />
    </FormComponent.Line>
  );
}
