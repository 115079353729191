import React from 'react';
import * as R from 'ramda';

import { CustomSelect, FormComponent, ListEdit, SectionTitle, TextArea, TextAreaMode, TextInput } from 'components';

import { Form, Rid } from 'types/models';
import { ValueOf } from 'types/helpers';
import { ReferenceSelect } from 'features/BuildReportPopup/builders/ExtendedParameterList/DefaultParameterList/ReferenceSelect';
import { showErrors } from 'utils/Validators';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { validateAuthor, validateDepartment } from './validate';
import { RntdSubjects } from './RntdSubjects/RntdSubjects';
import { AuthorInputs } from './AuthorInputs/AuthorInputs';
import { useController } from './controller';
import { DepartmentInputs } from './DepartmentInputs/DepartmentInputs';

type Props = {
  rntd?: Rid | null;
  formFields: Form.Fields;
  makeChangeHandler(key: keyof Rid): (value: ValueOf<Rid>) => void;
  disabled?: boolean;
  applicants: string;
};

export function AboutRntd({ rntd, formFields, makeChangeHandler, disabled, applicants }: Props) {
  const {
    projectOriginal,
    projectSpecification,
    extraToolbarButtons,
    authorsExtraToolbarButtons,
    projectRowConverter,
    handleAuthorsChange,
    handleDepartmentsChange,
  } = useController({
    rntd,
    formFields,
    makeChangeHandler,
  });

  const { settings } = useAppDataContext();

  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        historyLabel: '',
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      settings,
    );

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column specialStyles={{ width: '65%' }}>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.code.title} isRequired={formFields.code.required}>
              <TextInput value={formFields.code.value} onChange={formFields.code.onChange} isDisabled={disabled} />
            </FormComponent.Field>
            <FormComponent.Field label={formFields.type.title} isRequired>
              <ReferenceSelect
                referenceItem={formFields.type.value}
                setReferenceItem={formFields.type.onChange}
                fieldLabel=""
                referenceName="RefTypeRntd"
                relationTableModalTitle='Справочник "Типы РИД"'
                referenceFilter={[]}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.name.title} isRequired={formFields.name.required}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.name.title }}
                value={formFields.name.value}
                onChange={formFields.name.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.description.title} isRequired={formFields.description.required}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.description.title }}
                value={formFields.description.value}
                onChange={formFields.description.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.scope.title} isRequired={formFields.scope.required}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.scope.title }}
                value={formFields.scope.value}
                onChange={formFields.scope.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.keyWords.title} isRequired={formFields.keyWords.required}>
              <div>{formFields.keyWords.value}</div>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Охрана РИД">
              <div>{rntd?.securityDocuments ? rntd?.securityDocuments[0]?.label : ''}</div>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.project.title} isRequired={formFields.project.required}>
              <CustomSelect
                disabled={disabled}
                isRequired
                title=""
                original={projectOriginal}
                relationTableModalTitle="Научные проекты"
                onDeleteFunction={() => formFields.project.onChange(null)}
                onChange={formFields.project.onChange}
                modalTableRowConverter={projectRowConverter}
                modalTableSpecification={projectSpecification}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <RntdSubjects rntd={rntd} makeChangeHandler={makeChangeHandler} />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: '690px' }}>
          <SectionTitle title="Авторы" isRequired />

          <FormComponent.Field>
            <ListEdit
              withMessages
              rows={rntd?.authors || []}
              isToolbarDisabled={disabled}
              maxHeight="80vh"
              onChange={handleAuthorsChange}
              extraToolbarButtons={authorsExtraToolbarButtons}
              columns={[
                { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '30%' } },
                {
                  label: 'Должность',
                  formatValue: x => {
                    const result = getSplittedHistoryLabel(x);
                    return result.rank || result.error || '';
                  },
                  styles: { width: '25%' },
                },
                {
                  label: 'Место работы',
                  formatValue: x => {
                    const result = getSplittedHistoryLabel(x);
                    return result.job || result.error || '';
                  },
                  styles: { width: '45%' },
                },
              ]}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <AuthorInputs author={row} onAuthorChange={onChange as any} />,
                validation: {
                  checkIsValid: a => validateAuthor(a).every(x => x.isValid),
                  onInvalidate: R.partial(showErrors, [validateAuthor]),
                },
              }}
            />
          </FormComponent.Field>
          <SectionTitle title="Правообладатели" />
          <FormComponent.Field>
            <div>{applicants}</div>
          </FormComponent.Field>

          <SectionTitle title="Результат получен на базе" />
          <ListEdit
            withMessages
            rows={rntd?.departments || []}
            extraToolbarButtons={extraToolbarButtons}
            isToolbarDisabled={disabled}
            maxHeight="80vh"
            onChange={handleDepartmentsChange}
            columns={[
              { label: 'Факультет/Институт', formatValue: x => x?.governance?.label || '' },
              { label: 'Подразделение', formatValue: x => x?.unit?.label || '' },
            ]}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => <DepartmentInputs department={row} onDepartmentChange={onChange} />,
              validation: {
                checkIsValid: dep => validateDepartment(dep).every(x => x.isValid),
                onInvalidate: R.partial(showErrors, [validateDepartment]),
              },
            }}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
