import React from 'react';
import * as R from 'ramda';

import { Form as F, Publication, PublicationType, Table } from 'types/models';

import { Form } from 'features/Form';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { Permits } from 'utils/Permissions';
import { Template } from 'types/models/Form';

type Props = {
  tableState: Table.State;
  publiation: Publication | null;
  isViewMode: boolean;
  status: AuthorPaymentRequestStatus | null;
};

export function ViewMonograph({ tableState, publiation, isViewMode, status }: Props) {
  const { userPermission } = useAppDataContext();

  if (publiation === null) {
    return null;
  }

  const mapTemplate: Record<PublicationType, Template> = {
    MONOGRAPH_CHAPTER: 'PublicationFormMonographyArticle',
    ARTICLE: 'PublicationFormMonographyArticle',
    MONOGRAPH: 'PublicationFormMonography',
    TUTORIAL: 'PublicationFormTextbook',
  };

  const viewMode =
    isViewMode ||
    !isHasPermission(
      userPermission,
      status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ? Permits.PUBLICATION_STRICTED_EDIT : Permits.PUBLICATION_EDIT,
    );

  const look: F.PublicationFormLook = {
    apiID: publiation.type.value === 'MONOGRAPH' ? 'GetMonograph' : 'GetPublication',
    id: publiation.id,
    template: mapTemplate[publiation.type.value],
    type: publiation.type.value,
    viewMode: viewMode,
    editMode: !viewMode,
    relatedTableState: R.over(R.lensPath(['selectedRows', 0]), x => ({ ...x, id: publiation.id }), tableState),
    isRequestPaymentView: true,
    isShowDetailedTitle: true,
  };

  return <Form look={look} onClose={() => {}} />;
}
