import { Template } from 'types/models/Form';

type Project = {
  label: string;
  nominativeCaseLabel: string;
  genitiveCaseLabel: string;
  template?: Template;
};
export class OtherProject {
  static OTHER_PROJECT: Project = {
    label: 'Проект в другом вузе',
    nominativeCaseLabel: 'проект',
    genitiveCaseLabel: 'проекта',
    template: 'OtherProjectForm',
  };
}
