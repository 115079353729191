import { Table } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { Event } from 'types/models';
import { Departments } from 'features/Table/views';

type Props = {
  isPublicaton?: boolean;
  workMode?: Table.WorkMode;
  departments?: Event.Department[];
  status: string;
  eventId?: string;
  eventTypeCode: string;
};

export const EventHeader = ({
  isPublicaton,
  workMode,
  departments,
  status,
  eventId,
  eventTypeCode,
}: Props): string | JSX.Element => {
  const eventType = ((): Event.EventType | null => {
    const eventTypesArray = Object.entries(EventTypes);
    const currentEventTypeEntire = eventTypesArray.find(([, { code }]) => code === eventTypeCode);

    if (currentEventTypeEntire) {
      const currentEventType: Event.EventType = currentEventTypeEntire[1];
      return currentEventType;
    }

    return null;
  })();

  const hint = (() => {
    return 'Добавить связь с подразделением - в разделе "Организаторы мероприятия"';
  })();

  const baseTitle = (() => {
    const base = [eventType?.label ? `"${eventType?.label}"` : '', eventId ? `(ID ${eventId})` : ''].filter(x => x).join(' ');

    return [base, workMode !== 'addMode' && status ? `Статус записи: ${status}` : ''].filter(x => x).join('. ');
  })();

  const title = (() => {
    const action = (() => {
      if (isPublicaton) {
        return 'Публикации';
      }

      if (workMode === 'addMode') {
        return 'Добавление';
      }
      if (workMode === 'viewMode') {
        return 'Просмотр';
      }
      return 'Редактирование';
    })();

    return [action, baseTitle].filter(x => x).join(' ');
  })();

  if (departments) {
    return (
      <>
        <h3>{title}</h3>
        <h4>
          <Departments hint={hint} departments={departments.map(department => department.name).join(', ')} />
        </h4>
      </>
    );
  }

  return title;
};
