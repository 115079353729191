import { Community } from 'types/models';

export class CommunityMemberRole {
  static CHAIRMAN: Community.CommunityMemberRole = 'CHAIRMAN';

  static CHAIRMAN_DEPUTY: Community.CommunityMemberRole = 'CHAIRMAN_DEPUTY';

  static SCIENTIFIC_SECRETARY: Community.CommunityMemberRole = 'SCIENTIFIC_SECRETARY';

  static MEMBER: Community.CommunityMemberRole = 'MEMBER';

  static PARTICIPANT: Community.CommunityMemberRole = 'PARTICIPANT';

  static EXPERT: Community.CommunityMemberRole = 'EXPERT';

  static CHIEF_EDITOR: Community.CommunityMemberRole = 'CHIEF_EDITOR';

  static DEPUTY_CHIEF_EDITOR: Community.CommunityMemberRole = 'DEPUTY_CHIEF_EDITOR';

  static EXECUTIVE_SECRETARY: Community.CommunityMemberRole = 'EXECUTIVE_SECRETARY';

  static EDITORIAL_COUNCIL: Community.CommunityMemberRole = 'EDITORIAL_COUNCIL';

  static MEMBER_EDITORIAL_BOARD: Community.CommunityMemberRole = 'MEMBER_EDITORIAL_BOARD';
}
