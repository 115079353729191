import React from 'react';
import block from 'bem-cn';

import { FormComponent, InputSelect } from 'components';
import { LoaderLocal } from 'features/LoaderLocal';
import * as icons from 'icons';
import {
  checkIsFilterableColumn,
  checkIsReorderableColumn,
  checkIsResizableColumn,
  checkIsSortableColumn,
  INITIAL_PAGE_SIZE,
} from './helpers';
import { SelectDataControl } from './ThirdLevelHeader/SelectDataControl';
import { ExportDataControl } from './ThirdLevelHeader/ExportDataControl';
import { ControlPanel } from './ThirdLevelHeader/ControlPanel';
import { Filter } from './filters';
import { Cell } from './cells';
import useController from './controller';
import { FirstLevelHeader } from './FirstLevelHeader';
import { SecondLevelHeader } from './SecondLevelHeader';
import { TemplatesPanel } from './ThirdLevelHeader/TemplatesPanel';
import { isEmptyRow, PAGINATION_OPTIONS } from './helpers';

import './style.scss';

const b = block('grid');

export const Component = <CustomState extends {} = {}, RequestData extends {} = {}>() => {
  const {
    columns,
    tableRef,
    gridName,
    totalRow,
    pageIndex,
    isDragged,
    pageCount,
    isLoading,
    isWithQna,
    isExtended,
    resizerRef,
    isResizing,
    tableState,
    visibleRows,
    canNextPage,
    pageOptions,
    customState,
    selectedRows,
    isExtendable,
    headerGroups,
    footerGroups,
    isWithExport,
    specification,
    tableContainer,
    currentSetting,
    settingPresets,
    isFiltersShown,
    allEntriesCount,
    hasPersonalMode,
    canPreviousPage,
    selectedRowIndices,
    reorderedColumnName,
    firstSelectedRowRef,
    isTextSelectDisabled,
    isSecondLevelPanelOpen,
    selectedPaginationOption,
    onDragEnd,
    prepareRow,
    onTableBlur,
    getXlsxFile,
    onDragLeave,
    reloadTable,
    onPageChange,
    onTableKeyUp,
    resetFilters,
    getTableProps,
    onTableKeyDown,
    onTableKeyPress,
    onSettingChange,
    setColumnFilter,
    toggleIsExtended,
    onPageSizeChange,
    createOnRowClick,
    getTableBodyProps,
    getColumnSortType,
    onContainerScroll,
    getIsDragOverColumn,
    toggleIsFiltersShown,
    createOnDropCallback,
    reloadSettingPresets,
    onHiddenColumnsChange,
    createOnRowDoubleClick,
    getIsLeftDropIndicator,
    createDragOverCallback,
    createDragStartCallback,
    createSortColumnCallback,
    saveAndChangeGridSetting,
    getColumnIndexByAccessor,
    createResizeColumnCallback,
  } = useController<CustomState, RequestData>();
  return (
    <div
      className={b({
        'text-select-disabled': isTextSelectDisabled,
        personal: hasPersonalMode,
      })}
      onKeyDown={onTableKeyDown}
      onKeyUp={onTableKeyUp}
      onBlur={onTableBlur}
      onKeyPress={onTableKeyPress}
      tabIndex={-1}
    >
      <header className={b('header')}>
        <div className={b('header-first')}>
          <FirstLevelHeader<CustomState>
            customState={customState}
            isTableExtendable={isExtendable}
            isTableExtended={isExtended}
            title={specification.header.firstLevel?.title}
            Panel={specification.header.firstLevel?.Panel}
          />
        </div>
        <div className={b('header-second')}>
          <SecondLevelHeader<CustomState>
            customState={customState}
            Component={specification.header.secondLevel?.Component}
            selectedRows={selectedRows}
          />
        </div>
        <div className={b('header-third')}>
          {(isLoading || isSecondLevelPanelOpen) && <div className={b('disabled-overlay')} />}
          {specification.header.thirdLevel?.hasSelectButton && (
            <SelectDataControl onSubmitTable={specification.onSubmitTable} selectedRows={selectedRows} />
          )}
          {specification.header.thirdLevel?.LeftPanel && (
            <specification.header.thirdLevel.LeftPanel
              customState={customState}
              tableState={tableState}
              onSubmitTable={specification.onSubmitTable}
            />
          )}
          <ExportDataControl
            exportTableData={getXlsxFile}
            isExportButtonDisabled={isLoading}
            isWithQna={isWithQna}
            isWithExport={isWithExport}
          />
          <div className={b('header-third__right')}>
            {specification.header.thirdLevel?.withTemplatesPanel && (
              <div className={b('header-third__right__template-panel')}>
                <TemplatesPanel
                  gridName={gridName}
                  isTableExtended={isExtended}
                  columns={columns}
                  changeCurrentSetting={onSettingChange}
                  currentSetting={currentSetting}
                  settingPresets={settingPresets}
                  saveAndChangeGridSetting={saveAndChangeGridSetting}
                  reloadSettingPresets={reloadSettingPresets}
                />
              </div>
            )}
            <div className={b('header-third__right__control-panel')}>
              <ControlPanel
                columns={columns}
                isTableExtendable={isExtendable}
                isTableExtended={isExtended}
                onHiddenColumnsChange={onHiddenColumnsChange}
                reloadTable={reloadTable}
                resetFilters={resetFilters}
                toggleIsFiltersShown={toggleIsFiltersShown}
                toggleIsExtended={toggleIsExtended}
              />
            </div>
          </div>
        </div>
      </header>
      <div className={b('table-container-wrapper')}>
        <LoaderLocal isShow={isLoading} />

        {isSecondLevelPanelOpen && <div className={b('disabled-overlay', { dark: true })} />}

        <div ref={resizerRef} className={b('table-head-data-resizer', { active: isResizing })} />
        <div ref={tableContainer} className={b('table-container')} onScroll={onContainerScroll}>
          <table ref={tableRef} {...getTableProps()} className={b('table', { 'with-total-row': Boolean(totalRow) })}>
            <thead className={b('table-head', { resizing: isResizing, ['hidden-filters']: !isFiltersShown })}>
              {headerGroups.map(headerGroup => (
                <tr className={b('table-row', { head: true })} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    const sortType = getColumnSortType(column.id);
                    const originalColumnIndex = getColumnIndexByAccessor(column.id);
                    const originalColumn = columns[originalColumnIndex];
                    const isColumnReorderable = checkIsReorderableColumn(column.id);
                    const isReorderedColumn = column.id === reorderedColumnName;
                    return (
                      <th
                        className={b('table-head-data', {
                          'with-drop-indicator': getIsDragOverColumn(column.id),
                          'left-drop-indicator': getIsLeftDropIndicator(column.id),
                          dragged: isDragged && !isReorderedColumn,
                        })}
                        {...column.getHeaderProps()}
                        onDrop={isColumnReorderable ? createOnDropCallback(column.id) : () => false}
                        onDragOver={isColumnReorderable && !isReorderedColumn ? createDragOverCallback(column.id) : () => false}
                        onDragLeave={isColumnReorderable ? onDragLeave : () => false}
                        onDragEnd={onDragEnd}
                        style={{
                          width: `${column.width}px`,
                          minWidth: `${parseFloat((column.width ?? 0).toString()) - 20}px`,
                          maxWidth: `${column.width}px`,
                        }}
                      >
                        <div
                          draggable={isColumnReorderable}
                          onDragStart={isColumnReorderable ? createDragStartCallback(column.id) : () => false}
                          className={b('table-head-data-container')}
                          title={`${column.render('Header')}`}
                        >
                          <div className={b('table-head-data-label')}>
                            <button
                              type="button"
                              onClick={checkIsSortableColumn(column.id) ? createSortColumnCallback(column.id) : () => false}
                              className={b('table-head-data-sort-button')}
                            >
                              <span className={b('table-head-data-label-text', { [sortType ?? '']: Boolean(sortType) })}>
                                {column.render('Header')}
                              </span>
                            </button>
                          </div>
                          {originalColumn.filter ? (
                            <div className={b('table-head-data-filter-icon')}>
                              <img
                                src={icons.ColumnFilter}
                                alt="Колонка фильтруется"
                                className={b('table-head-data-filter-icon-image')}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        {checkIsFilterableColumn(column.id) && isFiltersShown && (
                          <div className={b('table-head-data-filter')}>
                            <Filter column={originalColumn} setColumnFilter={setColumnFilter} />
                          </div>
                        )}
                        {checkIsResizableColumn(column.id) && (
                          <button
                            onMouseDown={createResizeColumnCallback(column.id)}
                            type="button"
                            className={b('table-head-resize-button')}
                          >
                            move
                          </button>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className={b('table-body')} {...getTableBodyProps()}>
              {visibleRows.map((row, index) => {
                prepareRow(row);
                const hightlight = specification.highlightEntryPredicate && specification.highlightEntryPredicate(row.original);
                return (
                  <tr
                    className={b('table-row', {
                      selected: selectedRowIndices.some(selectedRowIndex => selectedRowIndex === index),
                      ['empty']: isEmptyRow(row.original),
                      hightlight: hightlight?.rowTheme,
                    })}
                    onClick={createOnRowClick(index)}
                    onDoubleClick={createOnRowDoubleClick(index)}
                    {...row.getRowProps()}
                    ref={selectedRowIndices[0] === index ? firstSelectedRowRef : null}
                  >
                    {row.cells.map(cell => {
                      const columnIndex = getColumnIndexByAccessor(cell.column.id);
                      return (
                        <td className={b('table-data')} {...cell.getCellProps()}>
                          <Cell columnType={columns[columnIndex].type} cellValue={cell.value} />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {totalRow && (
              <tfoot className={b('table-footer')}>
                {footerGroups.map(group => (
                  <tr className={b('table-row')} {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td className={b('table-data')} {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </div>
      </div>
      <footer className={b('footer')}>
        <div className={b('footer-pagination')}>
          <button
            className={b('footer-navigation-button', { ['go-to-start']: true, ['go-to-left']: true })}
            onClick={() => onPageChange(0)}
            disabled={!canPreviousPage}
          />
          <button
            className={b('footer-navigation-button', { ['go-to-left']: true })}
            onClick={() => onPageChange(pageIndex - 1)}
            disabled={!canPreviousPage}
          />
          <button
            className={b('footer-navigation-button', { ['go-to-right']: true })}
            onClick={() => onPageChange(pageIndex + 1)}
            disabled={!canNextPage}
          />
          <button
            className={b('footer-navigation-button', { ['go-to-end']: true, ['go-to-right']: true })}
            onClick={() => onPageChange(pageCount - 1)}
            disabled={!canNextPage}
          />
        </div>
        <div className={b('footer-page-counter')}>
          <span className={b('footer-page-counter-text')}>
            Страница{' '}
            <strong>
              {pageIndex + 1} из {pageOptions.length || 1}
            </strong>
          </span>
        </div>
        {/* Пока решили не удалять, вдруг понадобится */}
        {/*
        <div className={b('footer-page-input')}>
          <FormComponent.Field label="Перейти к странице" labelStyle="thin" labelSize="fit">
            <TextInput
              mode={TextInputMode.number}
              value={nextCurrentPage}
              onChange={onNextCurrentPageChange}
              settings={{ max: MAX_PAGE_NUMBER, isThousands: false }}
            />
          </FormComponent.Field>
        </div>
        */}
        <div className={b('footer-page-size-select')}>
          <FormComponent.Field label="На странице" labelStyle="thin" labelSize="fit">
            <InputSelect<number>
              isMaxMenuHeightDisabled
              value={selectedPaginationOption}
              options={PAGINATION_OPTIONS}
              onSelectChange={nextOption => onPageSizeChange(nextOption?.value ?? INITIAL_PAGE_SIZE)}
            />
          </FormComponent.Field>
        </div>
        <div className={b('footer-all-count')}>
          <span className={b('footer-all-count-text')}>
            Всего: <strong>{allEntriesCount || 0}</strong>
          </span>
        </div>
      </footer>
      {specification.NeighborTableComponent && (
        <specification.NeighborTableComponent customState={customState} tableState={tableState} />
      )}
    </div>
  );
};
