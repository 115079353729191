import React, { memo } from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

import './style.scss';

const Year = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { yearParameter, onChange, canBuild } = useController({ parameters, setParameterValueByName, parameterName });

  return (
    <>
      {canBuild && (
        <FormComponent.Field label={yearParameter!.description} isRequired={yearParameter!.required}>
          <TextInput mode={TextInputMode.year} value={(yearParameter!.value || '').toString()} onChange={onChange} />
        </FormComponent.Field>
      )}
    </>
  );
};

const Component = memo(Year);

export { Component as Year };
