import React from 'react';

import { FormComponent, Reference, TextArea, TextDateTime } from 'components';

import { PeriodableReferenceItem } from 'types/models/Partner';
import { ValueOf } from 'types/helpers';

type Props = {
  partnership: PeriodableReferenceItem;
  setPartnership(partnership: PeriodableReferenceItem): void;
};

export function Fields(props: Props) {
  const { partnership, setPartnership } = props;

  const makeHandler = (key: keyof PeriodableReferenceItem) => (value: ValueOf<PeriodableReferenceItem>) => {
    setPartnership({ ...partnership, [key]: value });
  };

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Тип партнёрства">
          <Reference
            name="RefPartnershipType"
            onChange={makeHandler('ref')}
            value={partnership.ref}
            relationTableModalTitle='Справочник "Типы партнёрств"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Срок действия"
          tooltip="Скан-копии договоров о сотрудничестве размещайте на вкладке Документы"
        >
          <TextDateTime
            isRange
            value={`${partnership.startDate || ''}-${partnership.endDate || ''}`}
            onChange={(value: string) => {
              const dates = value.split('-');
              setPartnership({ ...partnership, startDate: dates[0] || '', endDate: dates[1] || '' } as PeriodableReferenceItem);
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Примечание">
          <TextArea settings={{ isFixed: true }} value={partnership.note ?? ''} onChange={makeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
