import { makeBackendTableAPIConfiguration } from '../makeBackendTableAPIConfiguration';

import * as T from './types';
import { makeTableRequestXMLConverter, makeParametersXMLElement } from './commonConverters';
import { GetLinkedHistoryEntitiesRequestData, GetPersonAvailableHistoryElementsRequestData } from './author/types';
import { GetGetShortEventListInput } from './types';
import { CommunityType } from 'types/models/Community';

export const backendTableAPIConfigurations = {
  GetGridSettings: makeBackendTableAPIConfiguration(
    'GetGridSettings',
    makeTableRequestXMLConverter('GetGridSettings', ({ requestData }: T.GetGridSettingsInput) =>
      makeParametersXMLElement([
        {
          name: 'gridName',
          value: requestData?.relatedGridName,
        },
        { name: 'currentUserSettingsOnly', value: Boolean(requestData?.isCurrentUserSettingsOnly) },
      ]),
    ),
  ),
  GetShortEventList: makeBackendTableAPIConfiguration(
    'GetShortEventList',
    makeTableRequestXMLConverter('GetShortEventList', ({ requestData, panelState }: GetGetShortEventListInput) => {
      return makeParametersXMLElement([
        { name: 'eventType', value: requestData!.eventType },
        ...(!!panelState?.year ? [{ name: 'year', value: panelState.year }] : []),
        ...(requestData?.planned !== undefined ? [{ name: 'planned', value: requestData.planned }] : []),
        ...(requestData?.allEvents !== undefined ? [{ name: 'allEvents', value: requestData.allEvents }] : []),
        ...(requestData?.participationReportPublicationId !== undefined
          ? [{ name: 'participationReportPublicationId', value: requestData.participationReportPublicationId }]
          : []),
      ]);
    }),
  ),
  GetConferencePublicationList: makeBackendTableAPIConfiguration(
    'GetConferencePublicationList',
    makeTableRequestXMLConverter(
      'GetConferencePublicationList',
      ({ requestData }: T.GetRequestData<T.GetConferencePublicationListRequest>) =>
        makeParametersXMLElement([
          ...(requestData?.eventId ? [{ name: 'eventId', value: requestData.eventId }] : []),
          // ...(requestData?.compilationId ? [{ name: 'compilationId', value: requestData.compilationId }] : []),
          // ...(requestData?.magazineReleaseId ? [{ name: 'magazineReleaseId', value: requestData.magazineReleaseId }] : []),
        ]),
    ),
  ),
  GetAcceptableMobileRequestList: makeBackendTableAPIConfiguration(
    'GetAcceptableMobileRequestList',
    makeTableRequestXMLConverter('GetAcceptableMobileRequestList', () => makeParametersXMLElement([])),
  ),
  GetFullScienceProjectList: makeBackendTableAPIConfiguration(
    'GetFullScienceProjectList',
    makeTableRequestXMLConverter('GetFullScienceProjectList', ({ panelState }: T.GetFullScienceProjectListInput) =>
      makeParametersXMLElement([{ name: 'isCurrent', value: panelState.filterVal === 'true' }]),
    ),
  ),
  GetMipList: makeBackendTableAPIConfiguration(
    'GetMipList',
    makeTableRequestXMLConverter('GetMipList', () => makeParametersXMLElement([])),
  ),
  GetMobileRequestList: makeBackendTableAPIConfiguration(
    'GetMobileRequestList',
    makeTableRequestXMLConverter('GetMobileRequestList', ({ requestData, panelState }: T.GetMobileRequestListInput) => {
      return makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ...(requestData?.type ? [{ name: 'type', value: requestData?.type }] : []),
      ]);
    }),
  ),
  GetMobileProjectList: makeBackendTableAPIConfiguration(
    'GetMobileProjectList',
    makeTableRequestXMLConverter('GetMobileProjectList', () => makeParametersXMLElement([])),
  ),
  GetTenderProtocolList: makeBackendTableAPIConfiguration(
    'GetTenderProtocolList',
    makeTableRequestXMLConverter('GetTenderProtocolList', ({ panelState }: T.GetTenderProtocolListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetProtocolCommissionMemberList: makeBackendTableAPIConfiguration(
    'GetProtocolCommissionMemberList',
    makeTableRequestXMLConverter('GetProtocolCommissionMemberList', ({ requestData }: T.GetProtocolCommissionMemberListInput) => {
      return makeParametersXMLElement([
        ...(requestData?.protocolCommissionId
          ? [{ name: 'protocolCommissionId', value: requestData?.protocolCommissionId }]
          : []),
      ]);
    }),
  ),
  GetMagazineReleaseArticleList: makeBackendTableAPIConfiguration(
    'GetMagazineReleaseArticleList',
    makeTableRequestXMLConverter(
      'GetMagazineReleaseArticleList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput) =>
        makeParametersXMLElement([{ name: 'magazineReleaseId', value: relatedRecordId || '-1' }]),
    ),
  ),
  GetContestRequestList: makeBackendTableAPIConfiguration(
    'GetContestRequestList',
    makeTableRequestXMLConverter('GetContestRequestList', ({ panelState, isTableExtended }: T.GetContestRequestListRequest) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ...(isTableExtended ? [{ name: 'extended', value: isTableExtended }] : []),
      ]),
    ),
  ),
  GetDetailedScientistList: makeBackendTableAPIConfiguration(
    'GetDetailedScientistList',
    makeTableRequestXMLConverter('GetDetailedScientistList', ({ panelState, isTableExtended }: T.GetDetailedScientistListInput) =>
      makeParametersXMLElement([
        { name: 'Actual', value: panelState.isActual },
        {
          name: 'TSUPersons',
          value: panelState.filterVal === 'TSUPersons' || panelState.filterVal === 'All' ? 'true' : 'false',
        },
        {
          name: 'TSUAspDocs',
          value: panelState.filterVal === 'TSUAspDocs' || panelState.filterVal === 'All' ? 'true' : 'false',
        },
        {
          name: 'TSUStudents',
          value: panelState.filterVal === 'TSUStudents' || panelState.filterVal === 'All' ? 'true' : 'false',
        },
        {
          name: 'OtherPersons',
          value: panelState.filterVal === 'OtherPersons' || panelState.filterVal === 'All' ? 'true' : 'false',
        },
        { name: 'extended', value: isTableExtended },
      ]),
    ),
  ),
  GetReferenceScientistList: makeBackendTableAPIConfiguration(
    'GetReferenceScientistList',
    makeTableRequestXMLConverter(
      'GetReferenceScientistList',
      ({ panelState, isTableExtended }: T.GetReferenceScientistListInput) =>
        makeParametersXMLElement([
          { name: 'Actual', value: panelState.isActual },
          {
            name: 'TSUPersons',
            value: panelState.filterVal === 'TSUPersons' || panelState.filterVal === 'All' ? 'true' : 'false',
          },
          {
            name: 'TSUAspDocs',
            value: panelState.filterVal === 'TSUAspDocs' || panelState.filterVal === 'All' ? 'true' : 'false',
          },
          {
            name: 'TSUStudents',
            value: panelState.filterVal === 'TSUStudents' || panelState.filterVal === 'All' ? 'true' : 'false',
          },
          {
            name: 'OtherPersons',
            value: panelState.filterVal === 'OtherPersons' || panelState.filterVal === 'All' ? 'true' : 'false',
          },
          { name: 'extended', value: isTableExtended },
        ]),
    ),
  ),
  GetScientistDuplicateList: makeBackendTableAPIConfiguration(
    'GetScientistDuplicateList',
    makeTableRequestXMLConverter('GetScientistDuplicateList', () => makeParametersXMLElement([])),
  ),
  GetCompilationArticleList: makeBackendTableAPIConfiguration(
    'GetCompilationArticleList',
    makeTableRequestXMLConverter(
      'GetCompilationArticleList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([{ name: 'compilationId', value: relatedRecordId || '-1' }]),
    ),
  ),
  GetPublicationOtherArticleList: makeBackendTableAPIConfiguration(
    'GetPublicationOtherArticleList',
    makeTableRequestXMLConverter(
      'GetPublicationOtherArticleList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([{ name: 'otherPublicationId', value: relatedRecordId || '-1' }]),
    ),
  ),
  GetMonographChapterList: makeBackendTableAPIConfiguration(
    'GetMonographChapterList',
    makeTableRequestXMLConverter(
      'GetMonographChapterList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([{ name: 'monographId', value: relatedRecordId || '-1' }]),
    ),
  ),
  GetExponentList: makeBackendTableAPIConfiguration(
    'GetExponentList',
    makeTableRequestXMLConverter('GetExponentList', () => makeParametersXMLElement([])),
  ),
  GetPublicationExtendedList: makeBackendTableAPIConfiguration(
    'GetPublicationExtendedList',
    makeTableRequestXMLConverter(
      'GetPublicationExtendedList',
      // byDepartment
      // byDraft
      // byAffiliation
      // byMine
      ({ panelState, isTableExtended }: T.GetPublicationExtendedListInput) => {
        return makeParametersXMLElement([
          { name: 'extended', value: isTableExtended },
          { name: 'byDepartment', value: panelState.byDepartment },
          { name: 'drafts', value: panelState.byDraft },
          { name: 'affilated', value: panelState.byAffiliation },
          { name: 'mine', value: panelState.byMine },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]);
      },
    ),
  ),
  GetDomainKnowledgesByProgram: makeBackendTableAPIConfiguration(
    'GetDomainKnowledgesByProgram',
    makeTableRequestXMLConverter('GetDomainKnowledgesByProgram', ({ requestData }: T.GetDomainKnowledgesByProgramRequest) =>
      makeParametersXMLElement([{ name: 'programId', value: requestData?.programId || '-1' }]),
    ),
  ),
  GetBaseDissertationPublicationList: makeBackendTableAPIConfiguration(
    'GetBaseDissertationPublicationList',
    makeTableRequestXMLConverter(
      'GetBaseDissertationPublicationList',
      ({ panelState, requestData }: T.GetBaseDissertationPublicationListInput) => {
        return makeParametersXMLElement([
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
          ...(requestData?.communityId ? [{ name: 'communityId', value: requestData.communityId }] : []),
        ]);
      },
    ),
  ),
  GetPreprintPublicationList: makeBackendTableAPIConfiguration(
    'GetPreprintPublicationList',
    makeTableRequestXMLConverter('GetPreprintPublicationList', ({ panelState }: T.GetPreprintPublicationListInput) => {
      return makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]);
    }),
  ),
  GetProtocolCommissionList: makeBackendTableAPIConfiguration(
    'GetProtocolCommissionList',
    makeTableRequestXMLConverter('GetProtocolCommissionList', ({ panelState }: T.GetProtocolCommissionListInput) => {
      return makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]);
    }),
  ),
  GetTenderProtocolApproverRequestList: makeBackendTableAPIConfiguration(
    'GetTenderProtocolApproverRequestList',
    makeTableRequestXMLConverter(
      'GetTenderProtocolApproverRequestList',
      ({ panelState, requestData }: T.GetTenderProtocolApproverRequestListInput) => {
        return makeParametersXMLElement([
          ...(requestData?.protocolId ? [{ name: 'protocolId', value: requestData.protocolId }] : []),
          { name: 'withApprovement', value: Boolean(panelState?.withApprovement) },
          { name: 'withoutApprovement', value: Boolean(panelState?.withoutApprovement) },
        ]);
      },
    ),
  ),
  GetTenderProtocolRequestList: makeBackendTableAPIConfiguration(
    'GetTenderProtocolRequestList',
    makeTableRequestXMLConverter('GetTenderProtocolRequestList', ({ requestData }: T.GetTenderProtocolRequestListInput) => {
      return makeParametersXMLElement([
        ...(requestData?.protocolId ? [{ name: 'protocolId', value: requestData.protocolId }] : []),
      ]);
    }),
  ),
  GetMonographList: makeBackendTableAPIConfiguration(
    'GetMonographList',
    makeTableRequestXMLConverter('GetMonographList', ({ requestData }: T.GetRequestData<T.GetMonographListRequest>) =>
      makeParametersXMLElement([
        { name: 'isElectronic', value: Boolean(requestData?.isElectronic) },
        { name: 'monographChapterIds', value: requestData?.monographChapterIds || '-1' },
      ]),
    ),
  ),
  GetSimpleRntdList: makeBackendTableAPIConfiguration(
    'GetSimpleRntdList',
    makeTableRequestXMLConverter('GetSimpleRntdList', () =>
      makeParametersXMLElement([
        { name: 'isElectronic', value: false },
        { name: 'articleId', value: -1 },
        { name: 'isPreprint', value: false },
      ]),
    ),
  ),
  GetRntdList: makeBackendTableAPIConfiguration(
    'GetRntdList',
    makeTableRequestXMLConverter('GetRntdList', ({ panelState }: T.GetRntdListInput) =>
      makeParametersXMLElement([
        { name: 'acceptedForCommercialization', value: panelState.isAcceptedForCommercialization },
        { name: 'notExisted', value: panelState.isNotExisted },
        { name: 'existed', value: panelState.isExisted },
      ]),
    ),
  ),
  GetForeignSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetForeignSecurityDocumentList',
    makeTableRequestXMLConverter('GetForeignSecurityDocumentList', ({ panelState }: T.GetForeignSecurityDocumentListInput) => {
      return makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]);
    }),
  ),
  GetPrintPublicationList: makeBackendTableAPIConfiguration(
    'GetPrintPublicationList',
    makeTableRequestXMLConverter('GetPrintPublicationList', ({ requestData }: any) =>
      makeParametersXMLElement([
        { name: 'releaseId', value: requestData?.releaseId || '-1' },
        { name: 'year', value: requestData?.year || '-1' },
      ]),
    ),
  ),
  GetSimpleMagazineList: makeBackendTableAPIConfiguration(
    'GetSimpleMagazineList',
    makeTableRequestXMLConverter('GetSimpleMagazineList', ({ requestData }: T.GetRequestData<T.GetSimpleMagazineListRequest>) =>
      makeParametersXMLElement([
        ...(typeof requestData?.isElectronic === 'boolean' ? [{ name: 'isElectronic', value: requestData.isElectronic }] : []),
        { name: 'articleIds', value: requestData?.articleIds || -1 },
        { name: 'isPreprint', value: requestData?.isPreprint || 'false' },
        ...(requestData?.filterMagazineReleaseId
          ? [{ name: 'magazineReleaseIds', value: requestData.filterMagazineReleaseId }]
          : []),
      ]),
    ),
  ),
  GetMagazineList: makeBackendTableAPIConfiguration(
    'GetMagazineList',
    makeTableRequestXMLConverter('GetMagazineList', ({ panelState }: T.GetMagazineListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetCompilationList: makeBackendTableAPIConfiguration(
    'GetCompilationList',
    makeTableRequestXMLConverter('GetCompilationList', ({ panelState, isTableExtended }: T.GetCompilationListInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        { name: 'extended', value: isTableExtended },
      ]),
    ),
  ),
  GetPublicationOtherList: makeBackendTableAPIConfiguration(
    'GetPublicationOtherList',
    makeTableRequestXMLConverter(
      'GetPublicationOtherList',
      ({ requestData }: T.GetRequestData<T.GetPublicationOtherListRequest>) =>
        makeParametersXMLElement([
          { name: 'isElectronic', value: requestData!.isElectronic },
          { name: 'articleIds', value: requestData?.articleIds || '-1' },
        ]),
    ),
  ),
  GetCompilationListByType: makeBackendTableAPIConfiguration(
    'GetCompilationListByType',
    makeTableRequestXMLConverter(
      'GetCompilationListByType',
      ({ requestData }: T.GetRequestData<T.GetCompilationListByTypeRequest>) =>
        makeParametersXMLElement([
          ...(typeof requestData?.isElectronic === 'boolean' ? [{ name: 'isElectronic', value: requestData.isElectronic }] : []),
          { name: 'type', value: requestData!.type },
          { name: 'articleIds', value: requestData?.articleIds || '-1' },
          ...(requestData?.filterCompilationId ? [{ name: 'compilationIds', value: requestData.filterCompilationId }] : []),
        ]),
    ),
  ),
  GetMagazineArticleTranslationList: makeBackendTableAPIConfiguration(
    'GetMagazineArticleTranslationList',
    makeTableRequestXMLConverter(
      'GetMagazineArticleTranslationList',
      ({ requestData }: T.GetRequestData<T.GetMagazineArticleTranslationListRequest>) =>
        makeParametersXMLElement([{ name: 'sourceId', value: requestData!.sourceId }]),
    ),
  ),
  GetLibraryPublicationLogList: makeBackendTableAPIConfiguration(
    'GetLibraryPublicationLogList',
    makeTableRequestXMLConverter('GetLibraryPublicationLogList', () => makeParametersXMLElement([])),
  ),
  GetPublicationLibraryList: makeBackendTableAPIConfiguration(
    'GetPublicationLibraryList',
    makeTableRequestXMLConverter('GetPublicationLibraryList', () => makeParametersXMLElement([])),
  ),
  GetScientificIndicesList: makeBackendTableAPIConfiguration(
    'GetScientificIndicesList',
    makeTableRequestXMLConverter('GetScientificIndicesList', () => makeParametersXMLElement([])),
  ),
  GetFundCardPositionList: makeBackendTableAPIConfiguration(
    'GetFundCardPositionList',
    makeTableRequestXMLConverter('GetFundCardPositionList', ({ requestData, panelState }: T.GetFundCardPositionListRequest) =>
      makeParametersXMLElement([
        ...(!!requestData?.fundCardId ? [{ name: 'fundCardId', value: requestData.fundCardId }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetFundCardPositionReceivingList: makeBackendTableAPIConfiguration(
    'GetFundCardPositionReceivingList',
    makeTableRequestXMLConverter(
      'GetFundCardPositionReceivingList',
      ({ requestData }: T.GetFundCardPositionReceivingListRequest) =>
        makeParametersXMLElement([
          ...(!!requestData?.estimatePositionId ? [{ name: 'estimatePositionId', value: requestData.estimatePositionId }] : []),
        ]),
    ),
  ),
  GetFundCardList: makeBackendTableAPIConfiguration(
    'GetFundCardList',
    makeTableRequestXMLConverter('GetFundCardList', ({ panelState }: T.GetFundCardListRequest) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetSystemUpdatesList: makeBackendTableAPIConfiguration(
    'GetSystemUpdatesList',
    makeTableRequestXMLConverter('GetSystemUpdatesList', () => makeParametersXMLElement([])),
  ),
  GetDashboardChartByTypeEditionMagazineArticleList: makeBackendTableAPIConfiguration(
    'GetDashboardChartByTypeEditionMagazineArticleList',
    makeTableRequestXMLConverter(
      'GetDashboardChartByTypeEditionMagazineArticleList',
      ({ requestData }: T.GetRequestData<T.GetDashboardChartByTypeEditionMagazineArticleListRequest>) =>
        makeParametersXMLElement([
          { name: 'period', value: requestData?.period || '-1' },
          { name: 'typeEdition', value: requestData?.typeEdition || '-1' },
        ]),
    ),
  ),
  GetDashboardChartScopusWosArticleList: makeBackendTableAPIConfiguration(
    'GetDashboardChartScopusWosArticleList',
    makeTableRequestXMLConverter(
      'GetDashboardChartScopusWosArticleList',
      ({ requestData }: T.GetRequestData<T.GetDashboardChartScopusWosArticleListRequest>) =>
        makeParametersXMLElement([
          { name: 'scientificIndexCode', value: requestData?.scientificIndexCode || '-1' },
          ...(requestData?.department ? [{ name: 'department', value: requestData?.department || '-1' }] : []),
          ...(requestData?.year ? [{ name: 'year', value: requestData?.year || '-1' }] : []),
          ...(requestData?.quartile ? [{ name: 'quartile', value: requestData?.quartile || '-1' }] : []),
        ]),
    ),
  ),
  GetDashboardChartTotalPublicationList: makeBackendTableAPIConfiguration(
    'GetDashboardChartTotalPublicationList',
    makeTableRequestXMLConverter(
      'GetDashboardChartTotalPublicationList',
      ({ requestData }: T.GetRequestData<T.GetDashboardChartScopusWosArticleListRequest>) =>
        makeParametersXMLElement([
          ...(requestData?.department ? [{ name: 'department', value: requestData?.department || '-1' }] : []),
          ...(requestData?.year ? [{ name: 'year', value: requestData?.year || '-1' }] : []),
        ]),
    ),
  ),
  GetDashboardChartConferenceParticipationList: makeBackendTableAPIConfiguration(
    'GetDashboardChartConferenceParticipationList',
    makeTableRequestXMLConverter(
      'GetDashboardChartConferenceParticipationList',
      ({ requestData }: T.GetRequestData<T.GetDashboardChartScopusWosArticleListRequest>) =>
        makeParametersXMLElement([
          ...(requestData?.department ? [{ name: 'department', value: requestData.department }] : []),
          ...(requestData?.year ? [{ name: 'year', value: requestData.year }] : []),
        ]),
    ),
  ),
  GetDashboardChartExpositionParticipationList: makeBackendTableAPIConfiguration(
    'GetDashboardChartExpositionParticipationList',
    makeTableRequestXMLConverter(
      'GetDashboardChartExpositionParticipationList',
      ({ requestData }: T.GetRequestData<T.GetDashboardChartExpositionListRequest>) =>
        makeParametersXMLElement([
          ...(requestData?.expositionType ? [{ name: 'expositionType', value: requestData.expositionType }] : []),
          ...(requestData?.year ? [{ name: 'year', value: requestData.year }] : []),
        ]),
    ),
  ),
  GetDashboardChartExpositionParticipationAwardList: makeBackendTableAPIConfiguration(
    'GetDashboardChartExpositionParticipationAwardList',
    makeTableRequestXMLConverter(
      'GetDashboardChartExpositionParticipationAwardList',
      ({ requestData }: T.GetRequestData<T.GetDashboardChartExpositionListRequest>) =>
        makeParametersXMLElement([
          ...(requestData?.expositionType ? [{ name: 'expositionType', value: requestData.expositionType }] : []),
          ...(requestData?.year ? [{ name: 'year', value: requestData.year }] : []),
        ]),
    ),
  ),
  GetDashboardChartExpositionList: makeBackendTableAPIConfiguration(
    'GetDashboardChartExpositionList',
    makeTableRequestXMLConverter(
      'GetDashboardChartExpositionList',
      ({ requestData }: T.GetRequestData<T.GetDashboardChartExpositionListRequest>) =>
        makeParametersXMLElement([
          ...(requestData?.expositionType ? [{ name: 'expositionType', value: requestData.expositionType }] : []),
          ...(requestData?.year ? [{ name: 'year', value: requestData.year }] : []),
        ]),
    ),
  ),
  GetScientistStatistics: makeBackendTableAPIConfiguration(
    'GetScientistStatistics',
    makeTableRequestXMLConverter('GetScientistStatistics', ({ panelState }: T.GetScientistStatisticsInput) =>
      makeParametersXMLElement([
        { name: panelState.filterVal, value: true },
        ...(!!panelState.year ? [{ name: 'Year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetMonographPaymentRequests: makeBackendTableAPIConfiguration(
    'GetMonographPaymentRequests',
    makeTableRequestXMLConverter('GetMonographPaymentRequests', ({ panelState }: T.GetMonographPaymentRequestsInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        {
          name: 'isAllRequests',
          value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserDepartmentRequests',
          value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserRequests',
          value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
        },
      ]),
    ),
  ),
  GetTextBookPaymentRequests: makeBackendTableAPIConfiguration(
    'GetTextBookPaymentRequests',
    makeTableRequestXMLConverter('GetTextBookPaymentRequests', ({ panelState }: T.GetTextBookPaymentRequestsInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        {
          name: 'isAllRequests',
          value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserDepartmentRequests',
          value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserRequests',
          value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
        },
      ]),
    ),
  ),
  GetPatentIssuePaymentRequests: makeBackendTableAPIConfiguration(
    'GetPatentIssuePaymentRequests',
    makeTableRequestXMLConverter('GetPatentIssuePaymentRequests', ({ panelState }: T.GetPatentIssuePaymentRequestsInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        {
          name: 'isAllRequests',
          value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserDepartmentRequests',
          value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserRequests',
          value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
        },
      ]),
    ),
  ),
  GetPatentPaymentRequests: makeBackendTableAPIConfiguration(
    'GetPatentPaymentRequests',
    makeTableRequestXMLConverter('GetPatentPaymentRequests', ({ panelState }: T.GetPatentPaymentRequestsInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        {
          name: 'isAllRequests',
          value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserDepartmentRequests',
          value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserRequests',
          value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
        },
      ]),
    ),
  ),
  GetExhibitPaymentRequests: makeBackendTableAPIConfiguration(
    'GetExhibitPaymentRequests',
    makeTableRequestXMLConverter('GetExhibitPaymentRequests', ({ panelState }: T.GetExhibitPaymentRequestsInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        {
          name: 'isAllRequests',
          value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserDepartmentRequests',
          value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
        },
        {
          name: 'isUserRequests',
          value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
        },
      ]),
    ),
  ),
  GetExhibitionAwardsPaymentRequests: makeBackendTableAPIConfiguration(
    'GetExhibitionAwardsPaymentRequests',
    makeTableRequestXMLConverter(
      'GetExhibitionAwardsPaymentRequests',
      ({ panelState }: T.GetExhibitionAwardsPaymentRequestsInput) =>
        makeParametersXMLElement([
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
          {
            name: 'isAllRequests',
            value: panelState.filter === 'isAllRequests' ? 'true' : 'false',
          },
          {
            name: 'isUserDepartmentRequests',
            value: panelState.filter === 'isUserDepartmentRequests' ? 'true' : 'false',
          },
          {
            name: 'isUserRequests',
            value: panelState.filter === 'isUserRequests' ? 'true' : 'false',
          },
        ]),
    ),
  ),
  GetPaymentTermPresets: makeBackendTableAPIConfiguration(
    'GetPaymentTermPresets',
    makeTableRequestXMLConverter('GetPaymentTermPresets', () => makeParametersXMLElement([])),
  ),
  GetConferenceEventList: makeBackendTableAPIConfiguration(
    'GetConferenceEventList',
    makeTableRequestXMLConverter('GetConferenceEventList', ({ panelState, isTableExtended }: T.GetConferenceEventListInput) =>
      makeParametersXMLElement([
        { name: 'extended', value: isTableExtended },
        { name: 'eventDomain', value: 'EVENT' },
        { name: 'planned', value: panelState.planned },
        { name: 'filter', value: panelState.filter },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetContestEventList: makeBackendTableAPIConfiguration(
    'GetContestEventList',
    makeTableRequestXMLConverter('GetContestEventList', ({ panelState, isTableExtended }: T.GetContestEventListInput) =>
      makeParametersXMLElement([
        { name: 'extended', value: isTableExtended },
        { name: 'eventDomain', value: 'EVENT' },
        { name: 'planned', value: panelState.planned },
        { name: 'filter', value: panelState.filter },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetExpositionEventList: makeBackendTableAPIConfiguration(
    'GetExpositionEventList',
    makeTableRequestXMLConverter('GetExpositionEventList', ({ panelState, isTableExtended }: T.GetExpositionEventListInput) =>
      makeParametersXMLElement([
        { name: 'extended', value: isTableExtended },
        { name: 'eventDomain', value: 'EVENT' },
        { name: 'planned', value: panelState.planned },
        { name: 'filter', value: panelState.filter },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetConcertEventList: makeBackendTableAPIConfiguration(
    'GetConcertEventList',
    makeTableRequestXMLConverter('GetConcertEventList', ({ panelState, isTableExtended }: T.GetConcertEventListInput) =>
      makeParametersXMLElement([
        { name: 'extended', value: isTableExtended },
        { name: 'eventDomain', value: 'EVENT' },
        { name: 'planned', value: panelState.planned },
        { name: 'filter', value: panelState.filter },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetConferenceParticipationList: makeBackendTableAPIConfiguration(
    'GetConferenceParticipationList',
    makeTableRequestXMLConverter(
      'GetConferenceParticipationList',
      ({ panelState, isTableExtended }: T.GetConferenceParticipationListInput) =>
        makeParametersXMLElement([
          { name: 'extended', value: isTableExtended },
          { name: 'eventType', value: 'CONFERENCE' },
          { name: 'isAll', value: panelState.isAll },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetContestParticipationList: makeBackendTableAPIConfiguration(
    'GetContestParticipationList',
    makeTableRequestXMLConverter(
      'GetContestParticipationList',
      ({ panelState, isTableExtended }: T.GetContestParticipationListInput) =>
        makeParametersXMLElement([
          { name: 'extended', value: isTableExtended },
          { name: 'eventType', value: 'CONTEST' },
          { name: 'isAll', value: panelState.isAll },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetExpositionParticipationList: makeBackendTableAPIConfiguration(
    'GetExpositionParticipationList',
    makeTableRequestXMLConverter(
      'GetExpositionParticipationList', // eslint-disable-line
      ({ panelState, isTableExtended }: T.GetExpositionParticipationListInput) =>
        makeParametersXMLElement([
          { name: 'extended', value: isTableExtended },
          { name: 'eventType', value: 'EXPOSITION' },
          { name: 'isAll', value: panelState.isAll },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetConcertParticipationList: makeBackendTableAPIConfiguration(
    'GetConcertParticipationList',
    makeTableRequestXMLConverter(
      'GetConcertParticipationList', // eslint-disable-line
      ({ panelState, isTableExtended }: T.GetConcertParticipationListInput) =>
        makeParametersXMLElement([
          { name: 'extended', value: isTableExtended },
          { name: 'eventType', value: 'CONCERT' },
          { name: 'isAll', value: panelState.isAll },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetRecognitionList: makeBackendTableAPIConfiguration(
    'GetRecognitionList',
    makeTableRequestXMLConverter(
      'GetRecognitionList', // eslint-disable-line
      ({ panelState }: T.GetRecognitionListInput) =>
        makeParametersXMLElement([
          { name: 'byPortfolio', value: '0' },
          { name: 'allRecognition', value: panelState.allRecognition },
        ]),
    ),
  ),
  GetMagazineReleaseList: makeBackendTableAPIConfiguration(
    'GetMagazineReleaseList',
    makeTableRequestXMLConverter(
      'GetMagazineReleaseList',
      ({
        relatedRecordId,
        gridWorkMode,
        requestData,
      }: T.GetRelatedRecordIdInput &
        T.GetGridInput &
        T.GetWorkModeInput &
        T.GetRequestData<{ filterMagazineReleaseId?: string; isPreprint?: boolean }>) =>
        makeParametersXMLElement([
          ...(relatedRecordId
            ? [
                {
                  name: 'magazineId',
                  value: gridWorkMode === 'addMode' ? '-1' : relatedRecordId || '-1',
                },
              ]
            : []),
          ...(requestData?.filterMagazineReleaseId
            ? [{ name: 'magazineReleaseIds', value: requestData.filterMagazineReleaseId }]
            : []),
          ...(requestData?.isPreprint ? [{ name: 'isPreprint', value: requestData?.isPreprint ? 'true' : 'false' }] : []),
        ]),
    ),
  ),
  GetDashboardDetailMagazineArticleList: makeBackendTableAPIConfiguration(
    'GetDashboardDetailMagazineArticleList',
    makeTableRequestXMLConverter(
      'GetDashboardDetailMagazineArticleList',
      ({ panelState }: T.GetDashboardDetailMagazineArticle) => {
        return makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]);
      },
    ),
  ),
  GetDashboardDetailMagazineList: makeBackendTableAPIConfiguration(
    'GetDashboardDetailMagazineList',
    makeTableRequestXMLConverter('GetDashboardDetailMagazineList', ({ panelState }: T.GetDashboardDetailMagazine) => {
      return makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]);
    }),
  ),
  GetPublicationCitationSystemList: makeBackendTableAPIConfiguration(
    'GetPublicationCitationSystemList',
    makeTableRequestXMLConverter(
      'GetPublicationCitationSystemList',
      ({
        relatedRecordId,
        requestData,
      }: T.GetRelatedRecordIdInput & T.GetRequestData<{ domain: string; sourceId?: string; releaseId?: string }>) =>
        makeParametersXMLElement([
          {
            name: 'publicationId',
            value: relatedRecordId || '-1',
          },
          ...(requestData?.domain ? [{ name: 'domain', value: requestData.domain }] : []),
          ...(requestData?.sourceId ? [{ name: 'sourceId', value: requestData.sourceId }] : []),
          ...(requestData?.releaseId ? [{ name: 'releaseId', value: requestData.releaseId }] : []),
        ]),
    ),
  ),
  GetPublicationCitationSystemIndexList: makeBackendTableAPIConfiguration(
    'GetPublicationCitationSystemIndexList',
    makeTableRequestXMLConverter(
      'GetPublicationCitationSystemIndexList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput) =>
        makeParametersXMLElement([
          {
            name: 'publicationId',
            value: relatedRecordId || '-1',
          },
        ]),
    ),
  ),
  GetMagazineArticleList: makeBackendTableAPIConfiguration(
    'GetMagazineArticleList',
    makeTableRequestXMLConverter(
      'GetMagazineArticleList',
      ({ relatedRecordId, gridWorkMode }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([
          {
            name: 'magazineIds',
            value: gridWorkMode === 'addMode' ? '-1' : relatedRecordId || '-1',
          },
        ]),
    ),
  ),
  GetMagazineTranslationList: makeBackendTableAPIConfiguration(
    'GetMagazineTranslationList',
    makeTableRequestXMLConverter(
      'GetMagazineTranslationList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput) =>
        makeParametersXMLElement([{ name: 'sourceId', value: relatedRecordId || '-1' }]),
    ),
  ),
  GetMagazineOriginalList: makeBackendTableAPIConfiguration(
    'GetMagazineOriginalList',
    makeTableRequestXMLConverter('GetMagazineOriginalList', ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput) =>
      makeParametersXMLElement([{ name: 'sourceId', value: relatedRecordId || '-1' }]),
    ),
  ),
  GetPublicationFeedlineList: makeBackendTableAPIConfiguration(
    'GetPublicationFeedlineList',
    makeTableRequestXMLConverter(
      'GetPublicationFeedlineList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([
          {
            name: 'publicationId',
            value: relatedRecordId || '-1',
          },
        ]),
    ),
  ),
  GetEventFeedlineList: makeBackendTableAPIConfiguration(
    'GetEventFeedlineList',
    makeTableRequestXMLConverter(
      'GetEventFeedlineList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([
          {
            name: 'eventId',
            value: relatedRecordId || '-1',
          },
        ]),
    ),
  ),
  GetParticipationFeedlineList: makeBackendTableAPIConfiguration(
    'GetParticipationFeedlineList',
    makeTableRequestXMLConverter(
      'GetParticipationFeedlineList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput) =>
        makeParametersXMLElement([{ name: 'participationId', value: relatedRecordId || '-1' }]),
    ),
  ),
  GetForeignSecurityDocumentFeedlineList: makeBackendTableAPIConfiguration(
    'GetForeignSecurityDocumentFeedlineList',
    makeTableRequestXMLConverter(
      'GetForeignSecurityDocumentFeedlineList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([
          {
            name: 'foreignSecurityDocumentId',
            value: relatedRecordId || '-1',
          },
        ]),
    ),
  ),
  GetPurchaseRequestFeedlineList: makeBackendTableAPIConfiguration(
    'GetPurchaseRequestFeedlineList',
    makeTableRequestXMLConverter('GetPurchaseRequestFeedlineList', ({ requestData }: T.GetPurchaseRequestById) =>
      makeParametersXMLElement([{ name: 'purchaseRequestId', value: requestData?.purchaseRequestId }]),
    ),
  ),
  GetScientistBibliographicNameList: makeBackendTableAPIConfiguration(
    'GetScientistBibliographicNameList',
    makeTableRequestXMLConverter(
      'GetScientistBibliographicNameList',
      ({ requestData }: T.GetRequestData<T.GetBibliographicNameListRequest>) =>
        makeParametersXMLElement([{ name: 'scientistId', value: requestData?.scientistId ?? '-1' }]),
    ),
  ),
  GetPartnerSelectList: makeBackendTableAPIConfiguration(
    'GetPartnerSelectList',
    makeTableRequestXMLConverter('GetPartnerSelectList', () => makeParametersXMLElement([{ name: 'withRaw', value: 'true' }])),
  ),
  GetPersonalPublicationList: makeBackendTableAPIConfiguration(
    'GetPersonalPublicationList',
    makeTableRequestXMLConverter('GetPersonalPublicationList', ({ panelState }: T.GetPublicationListByScientistRequest) =>
      makeParametersXMLElement([
        ...(panelState.publicationsFilter ? [{ name: panelState.publicationsFilter, value: true }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalForeignSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetPersonalForeignSecurityDocumentList',
    makeTableRequestXMLConverter(
      'GetPersonalForeignSecurityDocumentList',
      ({ panelState }: T.GetPersonalForeignDocumentListInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetPersonalColleagueList: makeBackendTableAPIConfiguration(
    'GetPersonalColleagueList',
    makeTableRequestXMLConverter('GetPersonalColleagueList', () => makeParametersXMLElement([])),
  ),
  GetScientistColleagueList: makeBackendTableAPIConfiguration(
    'GetScientistColleagueList',
    makeTableRequestXMLConverter('GetScientistColleagueList', ({ requestData }: T.GetPublicationListByScientistRequest) =>
      makeParametersXMLElement([{ name: 'scientistId', value: requestData?.scientistId || '-1' }]),
    ),
  ),
  GetScientistColleaguePublicationList: makeBackendTableAPIConfiguration(
    'GetScientistColleaguePublicationList',
    makeTableRequestXMLConverter(
      'GetScientistColleaguePublicationList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetPersonalColleaguePublicationList: makeBackendTableAPIConfiguration(
    'GetPersonalColleaguePublicationList',
    makeTableRequestXMLConverter(
      'GetPersonalColleaguePublicationList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetScientistColleagueParticipationList: makeBackendTableAPIConfiguration(
    'GetScientistColleagueParticipationList',
    makeTableRequestXMLConverter(
      'GetScientistColleagueParticipationList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetPersonalColleagueParticipationList: makeBackendTableAPIConfiguration(
    'GetPersonalColleagueParticipationList',
    makeTableRequestXMLConverter(
      'GetPersonalColleagueParticipationList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetScientistColleagueEventList: makeBackendTableAPIConfiguration(
    'GetScientistColleagueEventList',
    makeTableRequestXMLConverter('GetScientistColleagueEventList', ({ requestData }: T.GetPublicationListByScientistRequest) =>
      makeParametersXMLElement([
        { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
        { name: 'scientistId', value: requestData?.scientistId || '-1' },
      ]),
    ),
  ),
  GetPersonalColleagueEventList: makeBackendTableAPIConfiguration(
    'GetPersonalColleagueEventList',
    makeTableRequestXMLConverter('GetPersonalColleagueEventList', ({ requestData }: T.GetPublicationListByScientistRequest) =>
      makeParametersXMLElement([
        { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
        { name: 'scientistId', value: requestData?.scientistId || '-1' },
      ]),
    ),
  ),
  GetScientistColleagueRequestAndProjectList: makeBackendTableAPIConfiguration(
    'GetScientistColleagueRequestAndProjectList',
    makeTableRequestXMLConverter(
      'GetScientistColleagueRequestAndProjectList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetPersonalColleagueRequestAndProjectList: makeBackendTableAPIConfiguration(
    'GetPersonalColleagueRequestAndProjectList',
    makeTableRequestXMLConverter(
      'GetPersonalColleagueRequestAndProjectList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetScientistColleagueSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetScientistColleagueSecurityDocumentList',
    makeTableRequestXMLConverter(
      'GetScientistColleagueSecurityDocumentList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetPersonalColleagueSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetPersonalColleagueSecurityDocumentList',
    makeTableRequestXMLConverter(
      'GetPersonalColleagueSecurityDocumentList',
      ({ requestData }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'colleagueScientistId', value: requestData?.colleagueScientistId || '-1' },
          { name: 'scientistId', value: requestData?.scientistId || '-1' },
        ]),
    ),
  ),
  GetPublicationListByScientist: makeBackendTableAPIConfiguration(
    'GetPublicationListByScientist',
    makeTableRequestXMLConverter(
      'GetPublicationListByScientist',
      ({ requestData, panelState }: T.GetPublicationListByScientistRequest) =>
        makeParametersXMLElement([
          { name: 'scientistId', value: requestData!.scientistId },
          ...(panelState.publicationsFilter ? [{ name: panelState.publicationsFilter, value: true }] : []),
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetImpactFactorList: makeBackendTableAPIConfiguration(
    'GetImpactFactorList',
    makeTableRequestXMLConverter('GetImpactFactorList', ({ requestData, panelState }: T.GetImpactFactorListRequest) =>
      makeParametersXMLElement(
        [
          { name: 'scientistId', value: requestData!.scientistId },
          { name: 'isFiveYear', value: requestData!.isFiveYear },
        ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ),
    ),
  ),
  GetScientistParticipationList: makeBackendTableAPIConfiguration(
    'GetScientistParticipationList',
    makeTableRequestXMLConverter(
      'GetScientistParticipationList',
      ({ requestData, panelState }: T.GetScientistParticipationListRequest) =>
        makeParametersXMLElement(
          [
            { name: 'scientistId', value: requestData!.scientistId },
            { name: 'allRole', value: panelState.role === 'ALL' ? 'true' : 'false' },
            { name: 'role', value: panelState.role === 'ALL' ? 'PARTICIPANT' : panelState.role },
          ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ),
    ),
  ),
  GetPersonalConferenceParticipationList: makeBackendTableAPIConfiguration(
    'GetPersonalConferenceParticipationList',
    makeTableRequestXMLConverter('GetPersonalConferenceParticipationList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.participationCategory ? [{ name: panelState.participationCategory, value: true }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalContestParticipationList: makeBackendTableAPIConfiguration(
    'GetPersonalContestParticipationList',
    makeTableRequestXMLConverter('GetPersonalContestParticipationList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.participationCategory ? [{ name: panelState.participationCategory, value: true }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalExpositionParticipationList: makeBackendTableAPIConfiguration(
    'GetPersonalExpositionParticipationList',
    makeTableRequestXMLConverter('GetPersonalExpositionParticipationList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.participationCategory ? [{ name: panelState.participationCategory, value: true }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalConcertParticipationList: makeBackendTableAPIConfiguration(
    'GetPersonalConcertParticipationList',
    makeTableRequestXMLConverter('GetPersonalConcertParticipationList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.participationCategory ? [{ name: panelState.participationCategory, value: true }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalConferenceEventList: makeBackendTableAPIConfiguration(
    'GetPersonalConferenceEventList',
    makeTableRequestXMLConverter('GetPersonalConferenceEventList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.eventCategory && panelState.eventCategory !== 'other'
          ? [{ name: panelState.eventCategory, value: true }]
          : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalContestEventList: makeBackendTableAPIConfiguration(
    'GetPersonalContestEventList',
    makeTableRequestXMLConverter('GetPersonalContestEventList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.eventCategory && panelState.eventCategory !== 'other'
          ? [{ name: panelState.eventCategory, value: true }]
          : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalExpositionEventList: makeBackendTableAPIConfiguration(
    'GetPersonalExpositionEventList',
    makeTableRequestXMLConverter('GetPersonalExpositionEventList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.eventCategory && panelState.eventCategory !== 'other'
          ? [{ name: panelState.eventCategory, value: true }]
          : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPersonalConcertEventList: makeBackendTableAPIConfiguration(
    'GetPersonalConcertEventList',
    makeTableRequestXMLConverter('GetPersonalConcertEventList', ({ panelState }: T.GetPersonalParticipationList) =>
      makeParametersXMLElement([
        ...(panelState.eventCategory && panelState.eventCategory !== 'other'
          ? [{ name: panelState.eventCategory, value: true }]
          : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetScientistEventList: makeBackendTableAPIConfiguration(
    'GetScientistEventList',
    makeTableRequestXMLConverter('GetScientistEventList', ({ requestData, panelState }: T.GetScientistEventListRequest) =>
      makeParametersXMLElement(
        [{ name: 'scientistId', value: requestData!.scientistId }].concat(
          !!panelState.year ? [{ name: 'year', value: panelState.year }] : [],
        ),
      ),
    ),
  ),
  GetScientistRequestAndProjectList: makeBackendTableAPIConfiguration(
    'GetScientistRequestAndProjectList',
    makeTableRequestXMLConverter(
      'GetScientistRequestAndProjectList',
      ({ requestData, panelState }: T.GetRequestAndProjectListRequest) =>
        makeParametersXMLElement(
          [
            { name: 'scientistId', value: requestData!.scientistId },
            { name: 'isRequest', value: panelState?.requestTypeFilter || false },
          ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ),
    ),
  ),
  GetPersonalRequestList: makeBackendTableAPIConfiguration(
    'GetPersonalRequestList',
    makeTableRequestXMLConverter('GetPersonalRequestList', ({ panelState }: T.GetRequestAndProjectListRequest) =>
      makeParametersXMLElement(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
    ),
  ),
  GetPersonalSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetPersonalSecurityDocumentList',
    makeTableRequestXMLConverter('GetPersonalSecurityDocumentList', ({ panelState }: T.GetPersonalSecurityDocumentsList) =>
      makeParametersXMLElement([
        { name: 'isRequests', value: panelState.isRequests || 'false' },
        ...(panelState.isConsiderationRequests !== undefined
          ? [{ name: 'isConsiderationRequests', value: panelState.isConsiderationRequests || 'false' }]
          : []),
        ...(panelState.isActive !== undefined ? [{ name: 'isActive', value: panelState.isActive || 'false' }] : []),
        ...(panelState.documentsFilter ? [{ name: 'securityDocumentType', value: panelState.documentsFilter }] : []),
      ]),
    ),
  ),
  GetPersonalAllSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetPersonalAllSecurityDocumentList',
    makeTableRequestXMLConverter('GetPersonalAllSecurityDocumentList', ({ panelState }: T.GetPersonalSecurityDocumentsList) =>
      makeParametersXMLElement([
        { name: 'securityDocumentTypes', value: panelState.documentsFilter },
        { name: 'isConsiderationRequests', value: panelState.isConsiderationRequests || 'false' },
        { name: 'isRequests', value: panelState.isRequests || 'false' },
        { name: 'isActive', value: panelState.isActive || 'false' },
      ]),
    ),
  ),
  GetPersonalProjectList: makeBackendTableAPIConfiguration(
    'GetPersonalProjectList',
    makeTableRequestXMLConverter('GetPersonalProjectList', ({ requestData, panelState }: T.GetPersonalProjectListRequest) => {
      return makeParametersXMLElement([
        ...(!!requestData?.onlyLeaderOrResponsiblePerformer ? [{ name: 'onlyLeaderOrResponsiblePerformer', value: 'true' }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]);
    }),
  ),
  GetPersonalAllProjectList: makeBackendTableAPIConfiguration(
    'GetPersonalAllProjectList',
    makeTableRequestXMLConverter('GetPersonalAllProjectList', ({ panelState }: T.GetRequestAndProjectListRequest) =>
      makeParametersXMLElement(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
    ),
  ),
  GetPersonalProjectAssignmentList: makeBackendTableAPIConfiguration(
    'GetPersonalProjectAssignmentList',
    makeTableRequestXMLConverter(
      'GetPersonalProjectAssignmentList',
      ({ panelState }: T.GetPersonalProjectAssignmentListRequest) =>
        makeParametersXMLElement([
          ...(panelState.actual && panelState.actual === 'actual' ? [{ name: 'isActual', value: true }] : []),
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetProjectAssignmentList: makeBackendTableAPIConfiguration(
    'GetProjectAssignmentList',
    makeTableRequestXMLConverter('GetProjectAssignmentList', ({ panelState, requestData }: T.GetProjectAssignmentListRequest) =>
      makeParametersXMLElement([
        ...(panelState.actual && panelState.actual === 'actual' ? [{ name: 'isActual', value: true }] : []),
        ...(requestData?.projectId ? [{ name: 'projectId', value: requestData.projectId }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPurchaseRequestList: makeBackendTableAPIConfiguration(
    'GetPurchaseRequestList',
    makeTableRequestXMLConverter('GetPurchaseRequestList', ({ panelState, requestData }: T.GetPurchaseRequestListRequest) =>
      makeParametersXMLElement([
        ...(requestData?.projectId ? [{ name: 'projectId', value: requestData.projectId }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetPurchaseRequestUnitList: makeBackendTableAPIConfiguration(
    'GetPurchaseRequestUnitList',
    makeTableRequestXMLConverter(
      'GetPurchaseRequestUnitList',
      ({ panelState, requestData }: T.GetPurchaseRequestUnitListRequest) =>
        makeParametersXMLElement([
          ...(requestData?.projectId ? [{ name: 'projectId', value: requestData.projectId }] : []),
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetPersonalPurchaseRequestList: makeBackendTableAPIConfiguration(
    'GetPersonalPurchaseRequestList',
    makeTableRequestXMLConverter('GetPersonalPurchaseRequestList', ({ panelState }: T.GetPurchaseRequestListRequest) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetProjectAssignmentMessageList: makeBackendTableAPIConfiguration(
    'GetProjectAssignmentMessageList',
    makeTableRequestXMLConverter(
      'GetProjectAssignmentMessageList',
      ({ panelState, requestData }: T.GetProjectAssignmentMessageListRequest) =>
        makeParametersXMLElement([
          { name: 'projectAssignmentId', value: requestData!.projectAssignmentId || '-1' },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetThematicalSearchProjectStateRegistrationReportList: makeBackendTableAPIConfiguration(
    'GetThematicalSearchProjectStateRegistrationReportList',
    makeTableRequestXMLConverter(
      'GetThematicalSearchProjectStateRegistrationReportList',
      ({ panelState }: T.GetRequestAndProjectListRequest) =>
        makeParametersXMLElement(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
    ),
  ),

  GetScientistSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetScientistSecurityDocumentList',
    makeTableRequestXMLConverter(
      'GetScientistSecurityDocumentList',
      ({ requestData, panelState }: T.GetRequestAndProjectListRequest) =>
        makeParametersXMLElement(
          [
            { name: 'scientistId', value: requestData!.scientistId },
            { name: 'isActive', value: panelState?.isActive },
          ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ),
    ),
  ),
  GetScientistDissertationManagementList: makeBackendTableAPIConfiguration(
    'GetScientistDissertationManagementList',
    makeTableRequestXMLConverter(
      'GetScientistDissertationManagementList',
      ({ requestData, panelState }: T.GetScientistDissertationManagementListInput) =>
        makeParametersXMLElement([
          { name: 'scientistId', value: requestData!.scientistId },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
          { name: 'isSynopsisManagement', value: panelState.isSynopsisManagement },
          { name: 'isDissertationManagement', value: panelState.isDissertationManagement },
        ]),
    ),
  ),
  GetScientistRecognitionList: makeBackendTableAPIConfiguration(
    'GetScientistRecognitionList',
    makeTableRequestXMLConverter(
      'GetScientistRecognitionList',
      ({ requestData, panelState }: T.GetRequestAndProjectListRequest) =>
        makeParametersXMLElement(
          [{ name: 'scientistId', value: requestData!.scientistId }].concat(
            !!panelState.year ? [{ name: 'year', value: panelState.year }] : [],
          ),
        ),
      () => 'pc',
    ),
    'GetScientistRecognitionList',
  ),
  GetPersonalRecognitionList: makeBackendTableAPIConfiguration(
    'GetPersonalRecognitionList',
    makeTableRequestXMLConverter(
      'GetPersonalRecognitionList',
      ({ panelState }: T.GetPersonalRecognitionListRequest) => {
        return makeParametersXMLElement([
          { name: 'mine', value: panelState!.recognitionsFilter === 'mine' ? 'true' : 'false' },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]);
      },
      () => 'pc',
    ),
    'GetPersonalRecognitionList',
  ),
  GetPersonalDissertationCouncilMemberList: makeBackendTableAPIConfiguration(
    'GetPersonalDissertationCouncilMemberList',
    makeTableRequestXMLConverter(
      'GetPersonalDissertationCouncilMemberList',
      ({ panelState }: T.GetPersonalDissertationCouncilMemberListRequest) => {
        return makeParametersXMLElement([
          { name: 'mine', value: panelState!.communitiesFilter === 'mine' ? 'true' : 'false' },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]);
      },
      () => 'pc',
    ),
    'GetPersonalDissertationCouncilMemberList',
  ),
  GetScientistDissertationCouncilMemberList: makeBackendTableAPIConfiguration(
    'GetScientistDissertationCouncilMemberList',
    makeTableRequestXMLConverter(
      'GetScientistDissertationCouncilMemberList',
      ({ requestData }: T.GetRequestData<{ scientistId: string }>) => {
        return makeParametersXMLElement([{ name: 'scientistId', value: requestData?.scientistId }]);
      },
      () => 'pc',
    ),
    'GetScientistDissertationCouncilMemberList',
  ),
  GetFullUserList: makeBackendTableAPIConfiguration(
    'GetFullUserList',
    makeTableRequestXMLConverter('GetFullUserList', ({ requestData }: T.GetRequestData<{ id: string }>) =>
      makeParametersXMLElement([...(requestData?.id ? [{ name: 'id', value: requestData?.id }] : [])]),
    ),
    'GetFullUserList',
  ),
  GetPublicationUsagesList: makeBackendTableAPIConfiguration(
    'GetPublicationUsagesList',
    makeTableRequestXMLConverter('GetPublicationUsagesList', ({ requestData }: T.GetRequestData<{ id: string }>) =>
      makeParametersXMLElement([...(requestData?.id ? [{ name: 'id', value: requestData?.id }] : [])]),
    ),
    'GetPublicationUsagesList',
  ),
  GetQnaQuestionList: makeBackendTableAPIConfiguration(
    'GetQnaQuestionList',
    makeTableRequestXMLConverter('GetQnaQuestionList', () => makeParametersXMLElement([])),
    'GetQnaQuestionList',
  ),
  GetQnaAllQuestionList: makeBackendTableAPIConfiguration(
    'GetQnaAllQuestionList',
    makeTableRequestXMLConverter('GetQnaAllQuestionList', ({ panelState }: T.GetAllQnaQuestionListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
    'GetQnaAllQuestionList',
  ),
  GetQnaFrequentQuestionList: makeBackendTableAPIConfiguration(
    'GetQnaFrequentQuestionList',
    makeTableRequestXMLConverter('GetQnaFrequentQuestionList', () => makeParametersXMLElement([])),
    'GetQnaFrequentQuestionList',
  ),
  GetExhibitionParticipationForRntd: makeBackendTableAPIConfiguration(
    'GetExhibitionParticipationForRntd',
    makeTableRequestXMLConverter('GetExhibitionParticipationForRntd', () => makeParametersXMLElement([])),
  ),
  GetLotList: makeBackendTableAPIConfiguration(
    'GetLotList',
    makeTableRequestXMLConverter('GetLotList', () => makeParametersXMLElement([])),
  ),
  GetDissertationCouncilList: makeBackendTableAPIConfiguration(
    'GetDissertationCouncilList',
    makeTableRequestXMLConverter('GetDissertationCouncilList', ({ requestData }: T.GetDissertationCouncilListInpit) =>
      makeParametersXMLElement([
        ...(requestData?.communityTypes.length ? [{ name: 'type', value: requestData?.communityTypes.join(',') }] : []),
        ...(typeof requestData?.isLocal === 'boolean' ? [{ name: 'isLocal', value: requestData?.isLocal }] : []),
      ]),
    ),
  ),
  GetCommunityList: makeBackendTableAPIConfiguration(
    'GetCommunityList',
    makeTableRequestXMLConverter('GetCommunityList', ({ requestData }: T.GetDissertationCouncilListInpit) =>
      makeParametersXMLElement([
        ...(requestData?.communityTypes.length ? [{ name: 'type', value: requestData?.communityTypes.join(',') }] : []),
        ...(typeof requestData?.isLocal === 'boolean' ? [{ name: 'isLocal', value: requestData?.isLocal }] : []),
      ]),
    ),
  ),
  GetScientistJobs: makeBackendTableAPIConfiguration(
    'GetScientistJobs',
    makeTableRequestXMLConverter('GetScientistJobs', ({ requestData }: T.GetRequestAndProjectListRequest) =>
      makeParametersXMLElement([{ name: 'scientistId', value: requestData?.scientistId }]),
    ),
  ),
  GetScientistAffiliationPublicationList: makeBackendTableAPIConfiguration(
    'GetScientistAffiliationPublicationList',
    makeTableRequestXMLConverter(
      'GetScientistAffiliationPublicationList',
      ({ requestData }: T.GetRequestData<{ scientistId: string; affiliationId: string }>) =>
        makeParametersXMLElement([
          {
            name: 'scientistId',
            value: requestData?.scientistId || '-1',
          },
          {
            name: 'affiliationId',
            value: requestData?.affiliationId || '-1',
          },
        ]),
    ),
  ),
  GetScientistHirshIndexesList: makeBackendTableAPIConfiguration(
    'GetScientistHirshIndexesList',
    makeTableRequestXMLConverter(
      'GetScientistHirshIndexesList',
      ({ panelState, relatedRecordId }: T.GetScientistHirshIndexesListInput) =>
        makeParametersXMLElement([
          { name: 'scientistId', value: relatedRecordId },
          { name: 'isLast', value: panelState.isLast },
        ]),
    ),
  ),
  GetEstimatePositionList: makeBackendTableAPIConfiguration(
    'GetEstimatePositionList',
    makeTableRequestXMLConverter('GetEstimatePositionList', ({ requestData }: T.GetRequestData<{ estimateId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'estimate',
          value: requestData?.estimateId || '-1',
        },
      ]),
    ),
  ),
  GetEstimatePositionItemList: makeBackendTableAPIConfiguration(
    'GetEstimatePositionItemList',
    makeTableRequestXMLConverter('GetEstimatePositionItemList', ({ requestData }: T.GetRequestData<{ estimateId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'estimate',
          value: requestData?.estimateId || '-1',
        },
      ]),
    ),
  ),
  GetEstimateOverheadHistoryList: makeBackendTableAPIConfiguration(
    'GetEstimateOverheadHistoryList',
    makeTableRequestXMLConverter(
      'GetEstimateOverheadHistoryList',
      ({ requestData }: T.GetRequestData<{ estimatePositionId: string }>) =>
        makeParametersXMLElement([
          {
            name: 'estimatePositionId',
            value: requestData?.estimatePositionId || '-1',
          },
        ]),
    ),
  ),
  GetEstimateStatusHistoryList: makeBackendTableAPIConfiguration(
    'GetEstimateStatusHistoryList',
    makeTableRequestXMLConverter('GetEstimateStatusHistoryList', ({ requestData }: T.GetRequestData<{ estimateId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'estimate',
          value: requestData?.estimateId || '-1',
        },
      ]),
    ),
  ),
  GetEstimateAvailableFundCardList: makeBackendTableAPIConfiguration(
    'GetEstimateAvailableFundCardList',
    makeTableRequestXMLConverter(
      'GetEstimateAvailableFundCardList',
      ({ requestData }: T.GetRequestData<{ estimateId: string }>) =>
        makeParametersXMLElement([
          {
            name: 'estimate',
            value: requestData?.estimateId || '-1',
          },
        ]),
    ),
  ),
  GetExpenseReportList: makeBackendTableAPIConfiguration(
    'GetExpenseReportList',
    makeTableRequestXMLConverter(
      'GetExpenseReportList',
      ({ requestData }: T.GetRequestData<{ estimateId: string; kindConsumptionId: string }>) =>
        makeParametersXMLElement([
          {
            name: 'estimate',
            value: requestData?.estimateId || '-1',
          },
          {
            name: 'kindConsumption',
            value: requestData?.kindConsumptionId || '-1',
          },
        ]),
    ),
  ),
  GetKindConsumptionList: makeBackendTableAPIConfiguration(
    'GetKindConsumptionList',
    makeTableRequestXMLConverter('GetKindConsumptionList', () => makeParametersXMLElement([])),
  ),
  GetProjectCodeSimpleList: makeBackendTableAPIConfiguration(
    'GetProjectCodeSimpleList',
    makeTableRequestXMLConverter('GetProjectCodeSimpleList', () => makeParametersXMLElement([])),
  ),
  GetProgramEventList: makeBackendTableAPIConfiguration(
    'GetProgramEventList',
    makeTableRequestXMLConverter('GetProgramEventList', ({ requestData }: T.GetRequestData<{ programId?: string }>) =>
      makeParametersXMLElement([
        ...(requestData?.programId
          ? [
              {
                name: 'programId',
                value: requestData.programId,
              },
            ]
          : []),
      ]),
    ),
  ),
  GetProgramEventSelectList: makeBackendTableAPIConfiguration(
    'GetProgramEventList',
    makeTableRequestXMLConverter(
      'GetProgramEventList',
      ({ requestData }: T.GetRequestData<{ programEventId: string; programId: string }>) => {
        return makeParametersXMLElement([
          {
            name: 'programEventId',
            value: requestData?.programEventId || '-1',
          },
          {
            name: 'programId',
            value: requestData?.programId || '-1',
          },
        ]);
      },
    ),
  ),
  GetProjectCodeListByProject: makeBackendTableAPIConfiguration(
    'GetProjectCodeListByProject',
    makeTableRequestXMLConverter('GetProjectCodeListByProject', ({ requestData }: T.GetRequestData<{ projectId?: string }>) =>
      makeParametersXMLElement([
        ...(requestData?.projectId
          ? [
              {
                name: 'projectId',
                value: requestData.projectId,
              },
            ]
          : []),
      ]),
    ),
  ),
  GetTenderListForReport: makeBackendTableAPIConfiguration(
    'GetTenderListForReport',
    makeTableRequestXMLConverter('GetTenderListForReport', () => makeParametersXMLElement([])),
  ),
  GetProgramIndicatorsList: makeBackendTableAPIConfiguration(
    'GetProgramIndicatorsList',
    makeTableRequestXMLConverter('GetProgramIndicatorsList', ({ requestData }: T.GetRequestData<{ programId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'programId',
          value: requestData?.programId || '-1',
        },
      ]),
    ),
  ),
  GetTenderListForRequest: makeBackendTableAPIConfiguration(
    'GetTenderListForRequest',
    makeTableRequestXMLConverter(
      'GetTenderListForRequest',
      ({ panelState, requestData }: T.GetTenderListForRequestInput) => {
        return makeParametersXMLElement([
          ...(requestData?.isPc ? [] : [{ name: 'isAll', value: (panelState.isAll || 'false').toString() === 'true' }]),
          ...(requestData?.tenderRequestType ? [{ name: 'tenderRequestType', value: requestData.tenderRequestType }] : []),
        ]);
      },
      ({ requestData }: T.GetTenderListForRequestInput) => (requestData?.isPc ? 'pc' : null),
    ),
  ),
  GetTenderList: makeBackendTableAPIConfiguration(
    'GetTenderList',
    makeTableRequestXMLConverter('GetTenderList', ({ requestData, panelState }: T.GetTenderListInput) => {
      return makeParametersXMLElement([
        {
          name: 'tenderId',
          value: requestData?.tenderId || '-1',
        },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]);
    }),
  ),
  GetProgramDirectionList: makeBackendTableAPIConfiguration(
    'GetProgramDirectionList',
    makeTableRequestXMLConverter('GetProgramDirectionList', ({ requestData }: T.GetRequestData<{ programId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'programId',
          value: requestData?.programId || '-1',
        },
      ]),
    ),
  ),
  GetProgramIndicesList: makeBackendTableAPIConfiguration(
    'GetProgramIndicesList',
    makeTableRequestXMLConverter('GetProgramIndicesList', ({ requestData }: T.GetRequestData<{ programId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'programId',
          value: requestData?.programId || '-1',
        },
      ]),
    ),
  ),
  GetRequestList: makeBackendTableAPIConfiguration(
    'GetRequestList',
    makeTableRequestXMLConverter(
      'GetRequestList',
      ({ panelState, requestId, isTableExtended, requestData }: T.GetRequestListInput) => {
        return makeParametersXMLElement([
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
          {
            name: 'requestId',
            value: requestId || '-1',
          },
          ...(isTableExtended ? [{ name: 'extended', value: isTableExtended }] : []),
          ...(typeof requestData?.isOnlyNotLinked === 'boolean'
            ? [{ name: 'isOnlyNotLinked', value: requestData?.isOnlyNotLinked }]
            : []),
          ...(requestData?.status ? [{ name: 'status', value: requestData?.status }] : []),
        ]);
      },
    ),
  ),
  GetRequestPerformerList: makeBackendTableAPIConfiguration(
    'GetRequestPerformerList',
    makeTableRequestXMLConverter('GetRequestPerformerList', ({ panelState }: T.GetRequestPerformerListRequest) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetProgramDomainKnowledgeList: makeBackendTableAPIConfiguration(
    'GetProgramDomainKnowledgeList',
    makeTableRequestXMLConverter('GetProgramDomainKnowledgeList', ({ requestData }: T.GetRequestData<{ programId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'programId',
          value: requestData?.programId || '-1',
        },
      ]),
    ),
  ),
  GetProjectCodeList: makeBackendTableAPIConfiguration(
    'GetProjectCodeList',
    makeTableRequestXMLConverter('GetProjectCodeList', ({ panelState, isTableExtended }: T.GetProjectCodeListInput) =>
      makeParametersXMLElement([
        ...(isTableExtended ? [{ name: 'extended', value: isTableExtended }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        { name: 'projectChanged', value: +panelState.isProjectChanged },
        { name: 'deletedCodes', value: +panelState.isDeletedCodes },
        { name: 'nonSpread', value: +panelState.isNonSpread },
        { name: 'withoutCodes', value: +panelState.isWithoutCodes },
      ]),
    ),
  ),
  GetProjectCodeListByYear: makeBackendTableAPIConfiguration(
    'GetProjectCodeListByYear',
    makeTableRequestXMLConverter('GetProjectCodeListByYear', ({ panelState }: T.GetProjectCodeListByYearInput) =>
      makeParametersXMLElement([
        { name: 'activeCodes', value: panelState.isActiveCodes },
        { name: 'withoutCalendarPlan', value: panelState.isWithoutCalendarPlan },
        { name: 'planMoreFact', value: panelState.isPlanMoreFact },
        { name: 'planLessFact', value: panelState.isPlanLessFact },
      ]),
    ),
  ),
  GetReceivingDocumentList: makeBackendTableAPIConfiguration(
    'GetReceivingDocumentList',
    makeTableRequestXMLConverter(
      'GetReceivingDocumentList',
      ({ requestData }: T.GetRequestData<{ projectCodeId: string; year: string }>) =>
        makeParametersXMLElement([
          ...(requestData?.year ? [{ name: 'year', value: requestData?.year }] : []),
          { name: 'projectCodeId', value: requestData?.projectCodeId || '-1' },
        ]),
    ),
  ),
  GetEstimateList: makeBackendTableAPIConfiguration(
    'GetEstimateList',
    makeTableRequestXMLConverter('GetEstimateList', ({ panelState, requestData, isTableExtended }: T.GetEstimateListRequest) =>
      makeParametersXMLElement([
        { name: 'extended', value: isTableExtended ? 'true' : 'false' },
        ...(requestData?.projectCodeId !== '0' ? [{ name: 'projectCodeId', value: requestData?.projectCodeId ?? '-1' }] : []),
        ...(panelState?.year ? [{ name: 'year', value: panelState?.year ?? '-1' }] : []),
      ]),
    ),
  ),
  GetUnaccountedExpensesList: makeBackendTableAPIConfiguration(
    'GetUnaccountedExpensesList',
    makeTableRequestXMLConverter('GetUnaccountedExpensesList', ({ requestData }: T.GetEstimateListRequest) =>
      makeParametersXMLElement([{ name: 'projectCodeId', value: requestData?.projectCodeId ?? '-1' }]),
    ),
  ),
  GetProjectCodeAvailableFundCardList: makeBackendTableAPIConfiguration(
    'GetProjectCodeAvailableFundCardList',
    makeTableRequestXMLConverter('GetProjectCodeAvailableFundCardList', ({ requestData }: T.GetEstimateListRequest) =>
      makeParametersXMLElement([{ name: 'projectCodeId', value: requestData?.projectCodeId ?? '-1' }]),
    ),
  ),
  GetProgramDomainKnowledgeSelectList: makeBackendTableAPIConfiguration(
    'GetProgramDomainKnowledgeSelectList',
    makeTableRequestXMLConverter('GetProgramDomainKnowledgeList', ({ requestData }: T.GetRequestData<{ programId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'programId',
          value: requestData?.programId || '-1',
        },
      ]),
    ),
  ),
  GetProjectListForContestRequest: makeBackendTableAPIConfiguration(
    'GetProjectListForContestRequest',
    makeTableRequestXMLConverter('GetProjectListForContestRequest', ({ panelState }: T.GetProjectForContestListInput) =>
      makeParametersXMLElement([...[{ name: 'isAll', value: panelState.isAll }]]),
    ),
  ),
  GetProjectListForContestRequestPc: makeBackendTableAPIConfiguration(
    'GetProjectListForContestRequest',
    makeTableRequestXMLConverter(
      'GetProjectListForContestRequest',
      () => makeParametersXMLElement([...[{ name: 'isAll', value: false }]]),
      () => 'pc',
    ),
  ),
  GetReceivingDocumentsLog: makeBackendTableAPIConfiguration(
    'GetReceivingDocumentsLog',
    makeTableRequestXMLConverter('GetReceivingDocumentsLog', ({ panelState }: T.GetReceivingDocumentsLogInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        { name: 'withoutCalendarPlan', value: panelState.isWithoutCalendarPlan },
        { name: 'withoutOverheads', value: panelState.isWithoutOverheads },
        { name: 'auditDocuments', value: panelState.isAuditDocuments },
      ]),
    ),
  ),
  GetProjectPerformerOrderList: makeBackendTableAPIConfiguration(
    'GetProjectPerformerOrderList',
    makeTableRequestXMLConverter(
      'GetProjectPerformerOrderList',
      ({ panelState, requestData }: T.GetProjectPerformerOrderListInput) =>
        makeParametersXMLElement([
          ...(requestData?.projectId
            ? [
                {
                  name: 'projectId',
                  value: requestData?.projectId,
                },
              ]
            : []),
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetProjectPerformerJobPeriodList: makeBackendTableAPIConfiguration(
    'GetProjectPerformerJobPeriodList',
    makeTableRequestXMLConverter(
      'GetProjectPerformerJobPeriodList',
      ({ panelState, requestData }: T.GetProjectPerformerJobPeriodListInput) =>
        makeParametersXMLElement([
          ...(requestData?.projectId
            ? [
                {
                  name: 'projectId',
                  value: requestData?.projectId,
                },
              ]
            : []),
          ...(panelState.actual && panelState.actual === 'actual' ? [{ name: 'isActual', value: true }] : []),
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetExpenseLogList: makeBackendTableAPIConfiguration(
    'GetExpenseLogList',
    makeTableRequestXMLConverter('GetExpenseLogList', ({ panelState }: T.GetExpenseLogListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetContractPaymentLogList: makeBackendTableAPIConfiguration(
    'GetContractPaymentLogList',
    makeTableRequestXMLConverter('GetContractPaymentLogList', ({ panelState, requestData }: T.GetContractPaymentLogListInput) =>
      makeParametersXMLElement([
        {
          name: 'securityDocument',
          value: requestData?.securityDocument || '-1',
        },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetLicenceAgreementContractList: makeBackendTableAPIConfiguration(
    'GetLicenceAgreementContractList',
    makeTableRequestXMLConverter('GetLicenceAgreementContractList', ({ panelState }: T.GetLicenceAgreementContractListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetPatentResearchList: makeBackendTableAPIConfiguration(
    'GetPatentResearchList',
    makeTableRequestXMLConverter('GetPatentResearchList', ({ panelState }: T.GetPatentResearchListInput) =>
      makeParametersXMLElement([
        { name: 'isPlanned', value: panelState.isPlanned },
        { name: 'isInWork', value: panelState.isInWork },
        { name: 'isFinished', value: panelState.isFinished },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetScienceProjectList: makeBackendTableAPIConfiguration(
    'GetScienceProjectList',
    makeTableRequestXMLConverter('GetScienceProjectList', () => makeParametersXMLElement([])),
  ),
  GetStageProjectList: makeBackendTableAPIConfiguration(
    'GetStageProjectList',
    makeTableRequestXMLConverter('GetStageProjectList', ({ requestData }: T.GetRequestData<{ projectId: string }>) =>
      makeParametersXMLElement([
        {
          name: 'projectId',
          value: requestData?.projectId || '-1',
        },
      ]),
    ),
  ),
  GetSecurityDocumentContractList: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentContractList',
    makeTableRequestXMLConverter('GetSecurityDocumentContractList', ({ panelState }: T.GetSecurityDocumentContractListInput) =>
      makeParametersXMLElement([
        { name: 'hasNotPerformerOrDescription', value: panelState.isHasNotPerformerOrDescription },
        { name: 'concludedContracts', value: panelState.isConcluded ?? 'false' },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentList',
    makeTableRequestXMLConverter('GetSecurityDocumentList', ({ requestData, panelState }: T.GetSecurityDocumentListRequest) => {
      return makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ...(requestData?.documentType ? [{ name: 'documentType', value: requestData?.documentType }] : []),
        ...(panelState?.isActiveRequests ? [{ name: 'activeRequests', value: !!panelState?.isActiveRequests }] : []),
        ...(panelState?.isActiveSecurityDocuments
          ? [
              {
                name: 'activeSecurityDocuments',
                value: !!panelState?.isActiveSecurityDocuments,
              },
            ]
          : []),
      ]);
    }),
  ),
  GetSecurityDocumentPaymentList: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentPaymentList',
    makeTableRequestXMLConverter('GetSecurityDocumentPaymentList', ({ panelState }: T.GetSecurityDocumentPaymentListRequest) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ...(!panelState.showAll ? [{ name: 'notPaid', value: panelState.notPaid ? 'true' : 'false' }] : []),
        ...(!panelState.showAll ? [{ name: 'needPayment', value: panelState.needPayment ? 'true' : 'false' }] : []),
      ]),
    ),
  ),
  GetSecurityDocumentIncentivePaymentList: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentIncentivePaymentList',
    makeTableRequestXMLConverter(
      'GetSecurityDocumentIncentivePaymentList',
      ({ requestData }: T.GetRequestData<{ securityDocumentId?: string }>) =>
        makeParametersXMLElement([
          {
            name: 'securityDocumentId',
            value: requestData?.securityDocumentId || '-1',
          },
        ]),
    ),
  ),
  GetPatentForIncentivePayment: makeBackendTableAPIConfiguration(
    'GetPatentForIncentivePayment',
    makeTableRequestXMLConverter('GetPatentForIncentivePayment', ({ requestData }: T.GetRequestData<{ isCurrent?: boolean }>) =>
      makeParametersXMLElement([
        {
          name: 'isCurrent',
          value: requestData?.isCurrent || 'false',
        },
      ]),
    ),
  ),
  GetPersonalDissertationManagementList: makeBackendTableAPIConfiguration(
    'GetPersonalDissertationManagementList',
    makeTableRequestXMLConverter(
      'GetPersonalDissertationManagementList',
      ({ panelState }: T.GetPersonalDissertationManagementListInput) =>
        makeParametersXMLElement([
          { name: 'isSynopsisManagement', value: panelState.isSynopsisManagement },
          { name: 'isDissertationManagement', value: panelState.isDissertationManagement },
        ]),
    ),
  ),
  GetProjectStateRegistrationList: makeBackendTableAPIConfiguration(
    'GetProjectStateRegistrationList',
    makeTableRequestXMLConverter('GetProjectStateRegistrationList', ({ panelState }: T.GetProjectStateRegistrationListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGosContractProjectList: makeBackendTableAPIConfiguration(
    'GetGosContractProjectList',
    makeTableRequestXMLConverter('GetGosContractProjectList', ({ panelState }: T.GetGosContractProjectListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetProjectStateRegistrationReportList: makeBackendTableAPIConfiguration(
    'GetProjectStateRegistrationReportList',
    makeTableRequestXMLConverter(
      'GetProjectStateRegistrationReportList',
      ({ panelState }: T.GetProjectStateRegistrationReportListInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGosContractReportList: makeBackendTableAPIConfiguration(
    'GetGosContractReportList',
    makeTableRequestXMLConverter('GetGosContractReportList', ({ panelState }: T.GetGosContractReportListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGosContactCharacteristicList: makeBackendTableAPIConfiguration(
    'GetGosContactCharacteristicList',
    makeTableRequestXMLConverter('GetGosContactCharacteristicList', ({ panelState }: T.GetGosContactCharacteristicListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGosContactThematicalSearchList: makeBackendTableAPIConfiguration(
    'GetGosContactThematicalSearchList',
    makeTableRequestXMLConverter(
      'GetGosContactThematicalSearchList',
      ({ panelState }: T.GetGosContactThematicalSearchListInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGosContractRequestList: makeBackendTableAPIConfiguration(
    'GetGosContractRequestList',
    makeTableRequestXMLConverter('GetGosContractRequestList', ({ panelState }: T.GetGosContractRequestListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetHozDogovorProjectList: makeBackendTableAPIConfiguration(
    'GetHozDogovorProjectList',
    makeTableRequestXMLConverter('GetHozDogovorProjectList', ({ panelState }: T.GetHozDogovorProjectListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetHozDogovorReportList: makeBackendTableAPIConfiguration(
    'GetHozDogovorReportList',
    makeTableRequestXMLConverter('GetHozDogovorReportList', ({ panelState }: T.GetHozDogovorReportListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetHozDogovorCharacteristicList: makeBackendTableAPIConfiguration(
    'GetHozDogovorCharacteristicList',
    makeTableRequestXMLConverter('GetHozDogovorCharacteristicList', ({ panelState }: T.GetHozDogovorCharacteristicListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetHozDogovorThematicalSearchList: makeBackendTableAPIConfiguration(
    'GetHozDogovorThematicalSearchList',
    makeTableRequestXMLConverter(
      'GetHozDogovorThematicalSearchList',
      ({ panelState }: T.GetHozDogovorThematicalSearchListInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetNirProjectList: makeBackendTableAPIConfiguration(
    'GetNirProjectList',
    makeTableRequestXMLConverter('GetNirProjectList', ({ panelState }: T.GetNirProjectListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetNirReportList: makeBackendTableAPIConfiguration(
    'GetNirReportList',
    makeTableRequestXMLConverter('GetNirReportList', ({ panelState }: T.GetNirReportListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetNirCharacteristicList: makeBackendTableAPIConfiguration(
    'GetNirCharacteristicList',
    makeTableRequestXMLConverter('GetNirCharacteristicList', ({ panelState }: T.GetNirCharacteristicListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetNirThematicalSearchList: makeBackendTableAPIConfiguration(
    'GetNirThematicalSearchList',
    makeTableRequestXMLConverter('GetNirThematicalSearchList', ({ panelState }: T.GetNirThematicalSearchListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGrantProjectList: makeBackendTableAPIConfiguration(
    'GetGrantProjectList',
    makeTableRequestXMLConverter('GetGrantProjectList', ({ panelState }: T.GetGrantProjectListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGrantReportList: makeBackendTableAPIConfiguration(
    'GetGrantReportList',
    makeTableRequestXMLConverter('GetGrantReportList', ({ panelState }: T.GetGrantReportListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGrantCharacteristicList: makeBackendTableAPIConfiguration(
    'GetGrantCharacteristicList',
    makeTableRequestXMLConverter('GetGrantCharacteristicList', ({ panelState }: T.GetGrantCharacteristicListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGrantThematicalSearchList: makeBackendTableAPIConfiguration(
    'GetGrantThematicalSearchList',
    makeTableRequestXMLConverter('GetGrantThematicalSearchList', ({ panelState }: T.GetGrantThematicalSearchListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetGrantAnalyticRequestList: makeBackendTableAPIConfiguration(
    'GetGrantAnalyticRequestList',
    makeTableRequestXMLConverter('GetGrantAnalyticRequestList', ({ panelState }: T.GetGrantAnalyticRequestListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetAnalyticProjectList: makeBackendTableAPIConfiguration(
    'GetAnalyticProjectList',
    makeTableRequestXMLConverter('GetAnalyticProjectList', ({ panelState }: T.GetAnalyticProjectListInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        { name: 'nonSpread', value: panelState.isNonSpread },
      ]),
    ),
  ),
  GetAnalyticRequestList: makeBackendTableAPIConfiguration(
    'GetAnalyticRequestList',
    makeTableRequestXMLConverter('GetAnalyticRequestList', ({ panelState }: T.GetAnalyticRequestListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetPatentResearchAnalyticList: makeBackendTableAPIConfiguration(
    'GetPatentResearchAnalyticList',
    makeTableRequestXMLConverter('GetPatentResearchAnalyticList', ({ panelState }: T.GetPatentResearchAnalyticListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetSecurityDocumentAnalyticList: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentAnalyticList',
    makeTableRequestXMLConverter('GetSecurityDocumentAnalyticList', ({ panelState }: T.GetSecurityDocumentAnalyticListInput) =>
      makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        { name: 'isConsideredRequest', value: panelState.isConsideredRequest ? '1' : '0' },
        { name: 'isSupportedDocumentRequest', value: panelState.isSupportedDocumentRequest ? '1' : '0' },
      ]),
    ),
  ),
  GetSecurityDocumentMemberAnalyticList: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentMemberAnalyticList',
    makeTableRequestXMLConverter(
      'GetSecurityDocumentMemberAnalyticList',
      ({ panelState }: T.GetSecurityDocumentMemberAnalyticListInput) =>
        makeParametersXMLElement([
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
          { name: 'isRequest', value: panelState.isRequest ? '1' : '0' },
        ]),
    ),
  ),
  GetSecurityDocumentContractsAnalyticList: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentContractsAnalyticList',
    makeTableRequestXMLConverter(
      'GetSecurityDocumentContractsAnalyticList',
      ({ panelState }: T.GetSecurityDocumentContractsAnalyticListInput) =>
        makeParametersXMLElement([
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
          { name: 'isActiveContracts', value: panelState.isActiveContracts ? '1' : '0' },
        ]),
    ),
  ),
  GetCommunityMemberList: makeBackendTableAPIConfiguration(
    'GetCommunityMemberList',
    makeTableRequestXMLConverter('GetCommunityMemberList', ({ requestData }: T.GetRequestData<T.GetCommunityMemberListRequest>) =>
      makeParametersXMLElement([
        ...(requestData?.scientistId ? [{ name: 'scientistId', value: requestData.scientistId }] : []),
        ...(requestData?.communityId ? [{ name: 'communityId', value: requestData.communityId }] : []),
      ]),
    ),
  ),
  GetLinkedHistoryEntities: makeBackendTableAPIConfiguration(
    'GetLinkedHistoryEntities',
    makeTableRequestXMLConverter(
      'GetLinkedHistoryEntities',
      ({ requestData }: T.GetRequestData<GetLinkedHistoryEntitiesRequestData>) =>
        makeParametersXMLElement([
          { name: 'type', value: requestData?.type },
          { name: 'id', value: requestData?.id },
        ]),
    ),
  ),
  GetPersonAvailableHistoryElements: makeBackendTableAPIConfiguration(
    'GetPersonAvailableHistoryElements',
    makeTableRequestXMLConverter(
      'GetPersonAvailableHistoryElements',
      ({ requestData }: T.GetRequestData<GetPersonAvailableHistoryElementsRequestData>) =>
        makeParametersXMLElement([
          { name: 'personId', value: requestData?.personId || '-1' },
          { name: 'type', value: requestData?.type },
          { name: 'itemId', value: requestData?.id },
        ]),
    ),
  ),
  GetPersonalCommunityMemberList: makeBackendTableAPIConfiguration(
    'GetPersonalCommunityMemberList',
    makeTableRequestXMLConverter(
      'GetPersonalCommunityMemberList',
      ({ requestData, panelState }: T.GetPersonalCommunityMemberListRequest) =>
        makeParametersXMLElement([
          { name: 'mine', value: panelState!.communitiesFilter === 'mine' ? 'true' : 'false' },
          { name: 'type', value: requestData?.types.join(',') },
          ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        ]),
    ),
  ),
  GetScientistCommunityMemberList: makeBackendTableAPIConfiguration(
    'GetScientistCommunityMemberList',
    makeTableRequestXMLConverter(
      'GetScientistCommunityMemberList',
      ({ requestData }: T.GetRequestData<{ scientistId: string; types: CommunityType[] }>) =>
        makeParametersXMLElement([
          ...(requestData?.scientistId ? [{ name: 'scientistId', value: requestData.scientistId }] : []),
          { name: 'type', value: requestData?.types.join(',') },
        ]),
    ),
  ),
  GetRoleList: makeBackendTableAPIConfiguration(
    'GetRoleList',
    makeTableRequestXMLConverter('GetRoleList', () => makeParametersXMLElement([])),
  ),
  GetUserRoleList: makeBackendTableAPIConfiguration(
    'GetRoleList',
    makeTableRequestXMLConverter('GetRoleList', ({ requestData }: T.GetRoleListInput) =>
      makeParametersXMLElement([...(requestData?.userId ? [{ name: 'userId', value: requestData.userId }] : [])]),
    ),
  ),
  GetUserSessions: makeBackendTableAPIConfiguration(
    'GetUserSessions',
    makeTableRequestXMLConverter('GetUserSessions', ({ panelState }: T.GetUserSessionsInput) =>
      makeParametersXMLElement([
        { name: 'active', value: panelState.isActive ? '1' : '0' },
        { name: 'interval', value: panelState.intervalValue },
      ]),
    ),
  ),
  GetLockedEntityList: makeBackendTableAPIConfiguration(
    'GetLockedEntityList',
    makeTableRequestXMLConverter('GetLockedEntityList', ({ panelState }: T.GetLockedEntityListInput) =>
      makeParametersXMLElement([{ name: 'type', value: panelState.entityType || 'All' }]),
    ),
  ),
  GetUserPermissionList: makeBackendTableAPIConfiguration(
    'GetUserPermissionList',
    makeTableRequestXMLConverter('GetUserPermissionList', ({ requestData }: T.GetUserPermissionListInput) =>
      makeParametersXMLElement([...(requestData?.userId ? [{ name: 'userId', value: requestData.userId }] : [])]),
    ),
  ),
  GetRolePermissionList: makeBackendTableAPIConfiguration(
    'GetRolePermissionList',
    makeTableRequestXMLConverter('GetRolePermissionList', ({ requestData }: T.GetRolePermissionListInput) =>
      makeParametersXMLElement([...(requestData?.roleId ? [{ name: 'roleId', value: requestData.roleId }] : [])]),
    ),
  ),
  GetRoleUserList: makeBackendTableAPIConfiguration(
    'GetRoleUserList',
    makeTableRequestXMLConverter('GetRoleUserList', ({ requestData }: T.GetRoleUserListInput) =>
      makeParametersXMLElement([...(requestData?.roleId ? [{ name: 'roleId', value: requestData.roleId }] : [])]),
    ),
  ),
  GetSubjectList: makeBackendTableAPIConfiguration(
    'GetSubjectList',
    makeTableRequestXMLConverter('GetSubjectList', ({ requestData }: T.GetSubjectListInput) =>
      makeParametersXMLElement([...(requestData?.subjectType ? [{ name: 'type', value: requestData.subjectType }] : [])]),
    ),
  ),
  GetSubjectRntdList: makeBackendTableAPIConfiguration(
    'GetSubjectRntdList',
    makeTableRequestXMLConverter('GetSubjectRntdList', ({ requestData }: T.GetSubjectRntdListInput) =>
      makeParametersXMLElement([{ name: 'subjectId', value: requestData?.subjectId || '-1' }]),
    ),
  ),
  GetSubjectFinancingList: makeBackendTableAPIConfiguration(
    'GetSubjectFinancingList',
    makeTableRequestXMLConverter('GetSubjectFinancingList', ({ requestData }: T.GetSubjectFinancingListInput) =>
      makeParametersXMLElement([{ name: 'subjectId', value: requestData?.subjectId || '-1' }]),
    ),
  ),
  GetSubjectMipList: makeBackendTableAPIConfiguration(
    'GetSubjectMipList',
    makeTableRequestXMLConverter('GetSubjectMipList', ({ requestData }: T.GetSubjectMipListInput) =>
      makeParametersXMLElement([{ name: 'subjectId', value: requestData?.subjectId || '-1' }]),
    ),
  ),
  GetPartnerMipIndicatorList: makeBackendTableAPIConfiguration(
    'GetPartnerMipIndicatorList',
    makeTableRequestXMLConverter('GetPartnerMipIndicatorList', ({ panelState }: T.GetPartnerMipIndicatorListInput) =>
      makeParametersXMLElement([{ name: 'year', value: panelState.year || '-1' }]),
    ),
  ),
  GetSubjectContractsList: makeBackendTableAPIConfiguration(
    'GetSubjectContractsList',
    makeTableRequestXMLConverter('GetSubjectContractsList', ({ requestData, panelState }: T.GetSubjectContractsListInput) =>
      makeParametersXMLElement([
        { name: 'subjectId', value: requestData?.subjectId || '-1' },
        { name: 'year', value: panelState.year || '-1' },
      ]),
    ),
  ),
  GetSubjectPublicationList: makeBackendTableAPIConfiguration(
    'GetSubjectPublicationList',
    makeTableRequestXMLConverter('GetSubjectPublicationList', ({ requestData, panelState }: T.GetSubjectPublicationListInput) =>
      makeParametersXMLElement([
        { name: 'subjectId', value: requestData?.subjectId || '-1' },
        { name: 'year', value: panelState.year || '-1' },
      ]),
    ),
  ),
  GetSubjectConferenceList: makeBackendTableAPIConfiguration(
    'GetSubjectConferenceList',
    makeTableRequestXMLConverter('GetSubjectConferenceList', ({ requestData, panelState }: T.GetSubjectConferenceListInput) =>
      makeParametersXMLElement([
        { name: 'subjectId', value: requestData?.subjectId || '-1' },
        { name: 'year', value: panelState.year || '-1' },
      ]),
    ),
  ),
  GetSubjectExpositionList: makeBackendTableAPIConfiguration(
    'GetSubjectExpositionList',
    makeTableRequestXMLConverter('GetSubjectExpositionList', ({ requestData, panelState }: T.GetSubjectExpositionListInput) =>
      makeParametersXMLElement([
        { name: 'subjectId', value: requestData?.subjectId || '-1' },
        { name: 'year', value: panelState.year || '-1' },
      ]),
    ),
  ),
  GetSubjectPersonList: makeBackendTableAPIConfiguration(
    'GetSubjectPersonList',
    makeTableRequestXMLConverter('GetSubjectPersonList', ({ requestData }: T.GetSubjectPersonListInput) =>
      makeParametersXMLElement([{ name: 'subjectId', value: requestData?.subjectId || '-1' }]),
    ),
  ),
  GetRntdForSubjectList: makeBackendTableAPIConfiguration(
    'GetRntdForSubjectList',
    makeTableRequestXMLConverter('GetRntdForSubjectList', () => makeParametersXMLElement([])),
  ),
  GetSubjectSecurityDocumentContractList: makeBackendTableAPIConfiguration(
    'GetSubjectSecurityDocumentContractList',
    makeTableRequestXMLConverter(
      'GetSubjectSecurityDocumentContractList',
      ({ requestData }: T.GetSubjectSecurityDocumentContractListInput) =>
        makeParametersXMLElement([{ name: 'subjectId', value: requestData?.subjectId || '-1' }]),
    ),
  ),
  GetJointEventList: makeBackendTableAPIConfiguration(
    'GetJointEventList',
    makeTableRequestXMLConverter('GetJointEventList', ({ panelState }: T.GetJointEventListInput) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetJointEventFeedlineList: makeBackendTableAPIConfiguration(
    'GetJointEventFeedlineList',
    makeTableRequestXMLConverter(
      'GetJointEventFeedlineList',
      ({ relatedRecordId }: T.GetRelatedRecordIdInput & T.GetGridInput & T.GetWorkModeInput) =>
        makeParametersXMLElement([
          {
            name: 'jointEventId',
            value: relatedRecordId || '-1',
          },
        ]),
    ),
  ),
  GetSubjectForExponentList: makeBackendTableAPIConfiguration(
    'GetSubjectForExponentList',
    makeTableRequestXMLConverter('GetSubjectForExponentList', () => makeParametersXMLElement([])),
  ),
  GetReportByDepartmentMappingPublicationList: makeBackendTableAPIConfiguration(
    'GetReportByDepartmentMappingPublicationList',
    makeTableRequestXMLConverter(
      'GetReportByDepartmentMappingPublicationList',
      ({ panelState }: T.GetReportByDepartmentMappingPublicationListInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetNirRequestKindConsumptionList: makeBackendTableAPIConfiguration(
    'GetNirRequestKindConsumptionList',
    makeTableRequestXMLConverter('GetNirRequestKindConsumptionList', () => {
      return makeParametersXMLElement([]);
    }),
  ),
  GetRequestKindConsumptionList: makeBackendTableAPIConfiguration(
    'GetRequestKindConsumptionList',
    makeTableRequestXMLConverter('GetRequestKindConsumptionList', () => {
      return makeParametersXMLElement([]);
    }),
  ),
  GetContestRequestKindConsumptionList: makeBackendTableAPIConfiguration(
    'GetContestRequestKindConsumptionList',
    makeTableRequestXMLConverter('GetContestRequestKindConsumptionList', () => {
      return makeParametersXMLElement([]);
    }),
  ),
  GetMobileKindConsumptionList: makeBackendTableAPIConfiguration(
    'GetMobileKindConsumptionList',
    makeTableRequestXMLConverter('GetMobileKindConsumptionList', ({ requestData }: T.GetRequestData<{ type: string }>) => {
      return makeParametersXMLElement([...(requestData?.type ? [{ name: 'type', value: requestData.type }] : [])]);
    }),
  ),
  GetNUFinancingSourceList: makeBackendTableAPIConfiguration(
    'GetNUFinancingSourceList',
    makeTableRequestXMLConverter('GetNUFinancingSourceList', () => makeParametersXMLElement([])),
  ),
  GetMobileRequestFeedlineList: makeBackendTableAPIConfiguration(
    'GetMobileRequestFeedlineList',
    makeTableRequestXMLConverter(
      'GetMobileRequestFeedlineList',
      ({ requestData }: T.GetGridInput & T.GetRequestData<{ id: string }>) =>
        makeParametersXMLElement([{ name: 'mobileRequestId', value: requestData?.id || '-1' }]),
    ),
  ),
  GetProjectScopusWosPublicationList: makeBackendTableAPIConfiguration(
    'GetProjectScopusWosPublicationList',
    makeTableRequestXMLConverter(
      'GetProjectScopusWosPublicationList',
      ({ panelState }: T.GetProjectScopusWosPublicationListInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetSecurityDocumentListForSecurityDocumentContract: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentListForSecurityDocumentContract',
    makeTableRequestXMLConverter(
      'GetSecurityDocumentListForSecurityDocumentContract',
      ({ panelState }: T.GetSecurityDocumentListForSecurityDocumentContractInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetPersonalContestRequestList: makeBackendTableAPIConfiguration(
    'GetPersonalContestRequestList',
    makeTableRequestXMLConverter(
      'GetPersonalContestRequestList',
      ({ panelState }: T.GetSecurityDocumentListForSecurityDocumentContractInput) =>
        makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
  GetRequestFeedlineList: makeBackendTableAPIConfiguration(
    'GetRequestFeedlineList',
    makeTableRequestXMLConverter('GetRequestFeedlineList', ({ requestData }: T.GetGridInput & T.GetRequestData<{ id: string }>) =>
      makeParametersXMLElement([{ name: 'requestId', value: requestData?.id || '-1' }]),
    ),
  ),
  GetNirRequestListForProject: makeBackendTableAPIConfiguration(
    'GetNirRequestListForProject',
    makeTableRequestXMLConverter('GetNirRequestListForProject', () => makeParametersXMLElement([])),
  ),
  GetProgramRequestListForProject: makeBackendTableAPIConfiguration(
    'GetProgramRequestListForProject',
    makeTableRequestXMLConverter('GetProgramRequestListForProject', () => makeParametersXMLElement([])),
  ),
  GetSecurityDocumentContractListForProject: makeBackendTableAPIConfiguration(
    'GetSecurityDocumentContractListForProject',
    makeTableRequestXMLConverter('GetSecurityDocumentContractListForProject', () => makeParametersXMLElement([])),
  ),
  GetSalaryList: makeBackendTableAPIConfiguration(
    'GetSalaryList',
    makeTableRequestXMLConverter('GetSalaryList', ({ panelState, requestData }: T.GetSalaryListInput) =>
      makeParametersXMLElement([
        ...(!!requestData?.projectCodeId ? [{ name: 'projectCodeId', value: requestData?.projectCodeId || '-1' }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetSalaryJobPaymentList: makeBackendTableAPIConfiguration(
    'GetSalaryJobPaymentList',
    makeTableRequestXMLConverter('GetSalaryJobPaymentList', ({ panelState, requestData }: T.GetSalaryJobPaymentListInput) =>
      makeParametersXMLElement([
        { name: 'salaryJobPaymentIds', value: requestData?.salaryJobPaymentIds },
        ...(!!requestData?.projectCodeId ? [{ name: 'projectCodeId', value: requestData?.projectCodeId || '-1' }] : []),
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
  GetFundCardSelectList: makeBackendTableAPIConfiguration(
    'GetFundCardSelectList',
    makeTableRequestXMLConverter('GetFundCardSelectList', () => makeParametersXMLElement([])),
  ),
  GetProjectCodeOverheadList: makeBackendTableAPIConfiguration(
    'GetProjectCodeOverheadList',
    makeTableRequestXMLConverter('GetProjectCodeOverheadList', ({ panelState }: T.GetProjectCodeOverheadList) => {
      return makeParametersXMLElement([
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
        { name: 'withoutCodes', value: !!panelState.withoutCodes },
        { name: 'deletedCodes', value: !!panelState.deletedCodes },
      ]);
    }),
  ),
};
