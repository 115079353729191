import React, { useEffect } from 'react';

import { FormComponent, Tabs, Tab, ConfirmPopup } from 'components';

import { Documents } from 'features/Documents';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { TenderTab as Tender } from './Tabs/Tender/Tender';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function TenderForm({ viewMode, editMode, onClose }: Props) {
  const {
    customers,
    documents,
    categories,
    participants,
    scienceDomainInterrests,
    lots,
    formFields,
    handleCustomersFieldChange,
    handleDocumentsFieldChange,
    handleLotsChange,
    handleCategoriesChange,
    handleParticipantsChange,
    handleScienceDomainInterrestChange,
    handleFormSubmit,
    tenderId,
    workMode,
    handleProgramIdChange,
    handleProgramEventIdChange,
    confirmSavePopupText,
    isConfirmSavePopupOpen,
    handleConfirmSaveConfirm,
    handleConfirmSaveClose,
    needsClose,
    requestType,
    tender,
    setTender,
    setCustomers,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (workMode === 'addMode') {
            return 'Добавление конкурса';
          }
          return `${workMode === 'viewMode' ? 'Просмотр' : 'Редактирование'} конкурса (ID  ${tenderId})`;
        })(),
      );
    }
  }, [tenderId, setTitle, workMode, requestType]);

  return (
    <>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Конкурс">
            <Tender
              viewMode={viewMode}
              workMode={workMode}
              formFields={formFields}
              onProgramIdChange={handleProgramIdChange}
              onProgramEventIdChange={handleProgramEventIdChange}
              customers={customers}
              setCustomers={setCustomers}
              categories={categories}
              participants={participants}
              scienceDomainInterrests={scienceDomainInterrests}
              handleCustomersFieldChange={handleCustomersFieldChange}
              handleCategoriesChange={handleCategoriesChange}
              handleParticipantsChange={handleParticipantsChange}
              handleScienceDomainInterrestChange={handleScienceDomainInterrestChange}
              requestType={requestType}
              tender={tender}
              setTender={setTender}
              lots={lots}
              handleLotsChange={handleLotsChange}
            />
          </Tab>
          <Tab title="Документы">
            <Documents
              documents={documents ?? []}
              setDocuments={handleDocumentsFieldChange}
              disabled={!!viewMode}
              sharedLabel="Отображать на формах Заявки и Проекта"
              isCanIsSharedEdit
              defaultSharedValue
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <ConfirmPopup
        title="Предупреждение"
        okButtonText="Да"
        resetButtonText="Нет"
        icon="warning"
        isOpen={isConfirmSavePopupOpen}
        onClose={handleConfirmSaveClose}
        text={confirmSavePopupText}
        onConfirm={() => handleConfirmSaveConfirm(needsClose)}
      />
    </>
  );
}

export const Component = React.memo(TenderForm);
