import React from 'react';
import { ListEdit, ConfirmPopup } from 'components';

import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';

import { Project } from 'types/models';
import { getMockFinancing } from 'features/Form/looks/project/ProjectForm/helpers';
import useController from './controller';

import { Fields } from './Fields/Fields';

import { validate } from './validate';

type Props = {
  disabled: boolean;
  financings: Project.Financing[];
  projectId?: string;
  setFinancings(financings: Project.Financing[]): void;
  setStages(stages: Project.Stage[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  stages: Project.Stage[];
};

export function Financings(props: Props) {
  const { disabled, financings, projectId, stages, setFinancings, setStages, setFinancingsByYear } = props;

  const {
    isLoading,
    enumMap,
    isDeleteWarningPopupOpen,
    ndsPercentFormatter,
    ndsFormatter,
    deleteWarningText,
    onCloseDeleteWarningPopup,
    onPreDelete,
    onPreSubmit,
  } = useController({
    projectId,
    setStages,
    setFinancingsByYear,
    stages,
  });

  return (
    <>
      <ConfirmPopup
        isOpen={isDeleteWarningPopupOpen}
        text={deleteWarningText}
        title="Невозможно удалить элемент"
        onClose={onCloseDeleteWarningPopup}
        okButtonText="Да"
        onConfirm={onCloseDeleteWarningPopup}
        icon="warning"
      />
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled || isLoading}
        rows={financings}
        onChange={setFinancings}
        defaultRowsCount={3}
        maxHeight="350px"
        columnIndexesForSumTotal={[2, 4]}
        columns={[
          { label: 'Вид финансирования', formatValue: x => x.type?.label ?? '', styles: { width: '30%' } },
          { label: 'Источник', formatValue: x => x.source?.label ?? '', styles: { width: '30%' } },
          { label: 'Сумма', formatValue: x => formatNumber(x.amount), styles: { textAlign: 'right', width: '13%' } },
          { label: 'НДС %', formatValue: ndsPercentFormatter, styles: { textAlign: 'right', width: '13%' } },
          { label: 'НДС, руб', formatValue: ndsFormatter, styles: { textAlign: 'right', width: '13%' } },
        ]}
        specification={{
          onPreSubmit,
          onPreDelete,
          mode: 'customComponent',
          renderComponent: (financing, setFinancing) => (
            <Fields
              financing={financing || getMockFinancing()}
              setFinancing={setFinancing}
              enumMap={enumMap.ProjectFinancingType}
            />
          ),
          validation: {
            checkIsValid: (department, index, list, mode) => validate(department, list, mode).every(x => x.isValid),
            onInvalidate: (department, mode, index, list) => {
              const validationInfo = validate(department, list, mode);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
    </>
  );
}
