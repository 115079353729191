import * as R from 'ramda';

import { Project } from 'types/models';
import { Item, Settings } from 'types/models/common';

import { Payment, PaymentItem } from 'types/models/Estimate';
import { Option } from 'components';
import { convertServerPerson } from '../author/converters';

import * as authorConverters from '../author/converters';

import {
  convertDocument,
  convertFile,
  convertItem,
  convertMaybeArray,
  convertReference,
  getBoolean,
  getId,
  getLabel,
  getText,
} from '../commonConverters';
import { convertProgram } from '../program/converters';
import { convertServerPartner } from '../partners/converters';
import { convertPatentResearch } from '../patentResearch/converters';
import { convertSecurityDocumentContract } from '../securityDocumentContract/converters';
import { convertApprovement } from '../estimates/converters';
import { convertPersonHistory } from 'services/BackendAPI/configurations/converters';
import { convertFundCard } from '../fundCard/converters';
import { JobPeriodOrder } from 'types/models/Project';

export function convertProject(job: any, settings: Settings): Project.Project {
  const customers = job.Customers?.Customer ?? [];
  const departments = job.Departments?.Department ?? [];
  const financings = job.Financings?.Financing ?? [];
  const financingsByYear = job.FinancingByYears?.FinancingByYear ?? [];
  const stages = job.Stages?.Stage ?? [];
  const scienceInterests = job.ScienceDomainInterrests?.ScienceDomainInterrest ?? [];
  const pnrs = job.Pnrs?.Pnr ?? [];
  const pnmitrs = job.Pnmitrs?.Pnmitr ?? [];
  const pnis = job.Pnis?.Pni ?? [];
  const ntrStrategies = job.NtrStrategies?.NtrStrategy ?? [];
  const criticalTechnologies = job.CriticalTechnologies?.CriticalTechnology ?? [];
  const ntiEteTechnologies = job.NtiEteTechnologies?.NtiEteTechnology ?? [];
  const domainKnowledges = job.DomainKnowledges?.DomainKnowledge ?? [];

  const grntis = job.Grntis?.Grnti ?? [];
  const udks = job.Udks?.Udk ?? [];
  const oecds = job.Oecds?.Oecd ?? [];
  const ntiMarkets = job.NtiMarkets?.NtiMarket ?? [];
  const techPlatforms = job.TechPlatforms?.TechPlatform ?? [];
  const workSpaces = job.WorkSpaces?.WorkSpace ?? [];
  const performers = job.Performers?.Performer ?? [];
  const serverForeignDocuments = job.ForeignDocuments?.Document || [];
  const serverDocuments = job.Documents?.Document || [];
  const stageRegistrationReports = job.StateRegistrationReports?.StateRegistrationReport || [];
  const contestRequests = job.ContestRequests?.ContestRequest ?? [];
  const projectCodes = job.ProjectCodes?.ProjectCode ?? [];
  const partners = job.Partners?.Partner ?? [];
  const acts = job.Acts?.Act ?? [];
  const patentResearches = job.PatentResearches?.PatentResearch ?? [];
  const resourceUsageDepartments = job.ResourceUsageDepartments?.ResourceUsageDepartment ?? [];
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, R.partial(convertDocument, ['foreign']));
  const localDocuments = convertMaybeArray(serverDocuments, R.partial(convertDocument, ['local']));

  const lksetss = job.Lksetss?.Lksets ?? [];
  const scienceBrunches = job.ScienceBrunches?.ScienceBrunch ?? [];
  const partnerCountries = job.PartnerCountries?.PartnerCountry ?? [];
  const vaks = job.Vaks?.Vak ?? [];

  return {
    activityKind: job.ActivityKind ? convertReference(job.ActivityKind) : null,
    acts: convertMaybeArray(acts || [], convertAct).sort((a, b) => parseFloat(b?.id || '0') - parseFloat(a?.id || '0')),
    annotation: getText(job.Annotation),
    contestRequests: convertMaybeArray(contestRequests, convertContestRequest),
    contractDate: getText(job.ContractDate),
    contractKind: job.ContractKind ? convertReference(job.ContractKind) : null,
    contractNumber: getText(job.ContractNumber),
    criticalTechnologies: convertRefElements(criticalTechnologies),
    customers: convertMaybeArray(customers, convertCustomer),
    departments: convertMaybeArray(departments, convertDepartment),
    documents: [...foreignDocuments, ...localDocuments],
    domainKnowledges: convertRefElements(domainKnowledges),
    endDate: getText(job.EndDate),
    expectedResults: getText(job.ExpectedResults),
    expendituresBudgets: job.ExpendituresBudgets ? convertReference(job.ExpendituresBudgets) : null,
    financialNote: getText(job.FinancialNote),
    financings: convertMaybeArray(financings, convertFincancing),
    financingsByYear: convertMaybeArray(financingsByYear, convertFinancingByYear),
    foreignEnterprise: convertReference(job.ForeignEnterprise),
    goal: getText(job.Goal),
    grntis: convertRefElements(grntis),
    id: getId(job),
    interdisciplinaryAproachJustification: getText(job.InterdisciplinaryAproachJustification),
    internationalProgram: convertReference(job.InternationalProgram),
    isBuyMaterial: getBoolean(job.IsBuyMaterial),
    isFinancingStopped: getBoolean(job.IsFinancingStopped),
    isNirRequestDataConsumed: getBoolean(job.IsNirRequestDataConsumed),
    isSpecTheme: getBoolean(job.IsSpecTheme),
    isYouth: getBoolean(job.IsYouth),
    keyWords: getText(job.KeyWords),
    kindResearch: job.KindResearch ? convertReference(job.KindResearch) : null,
    lksetss: convertRefElements(lksetss),
    name: getText(job.Name),
    nationalProjectExpenseCode: job.NationalProjectExpenseCode ? convertReference(job.NationalProjectExpenseCode) : null,
    nirRequest: job.NirRequest ? convertNirRequest(job) : null,
    ntiEteTechnologies: convertRefElements(ntiEteTechnologies),
    ntiMarkets: convertRefElements(ntiMarkets),
    ntrStrategies: convertRefElements(ntrStrategies),
    number: getText(job.Number),
    numberPrefix: job.NumberPrefix ? convertReference(job.NumberPrefix) : null,
    oecds: convertRefElements(oecds),
    okved: job.Okved ? convertReference(job.Okved) : null,
    okvedIncome: job.OkvedIncome ? convertReference(job.OkvedIncome) : null,
    overheadPercent: getText(job.OverheadPercent),
    partnerCountries: convertMaybeArray(partnerCountries, convertRefElement),
    partners: convertMaybeArray(partners, p => convertPartner(p, settings)),
    patentResearches: convertMaybeArray(patentResearches, r => convertPatentResearch(r, settings)),
    performers: convertMaybeArray(performers, p => convertPerformer(p, settings)),
    periodNote: getText(job.PeriodNote),
    pnis: convertRefElements(pnis),
    pnmitrs: convertRefElements(pnmitrs),
    pnrs: convertRefElements(pnrs),
    program: job.Program ? convertProgram(job.Program) : null,
    programEvent: job.ProgramEvent ? convertItemProgramEvent(job.ProgramEvent) : null,
    programEventCode: job.ProgramEvent ? getText(job?.ProgramEvent?.Code) : undefined,
    programEventName: job.ProgramEvent ? getText(job?.ProgramEvent?.Name) : undefined,
    programRequest: job.ProgramRequest ? convertProgramRequest(job) : null,
    projectCodes: convertMaybeArray(projectCodes, convertProjectCode),
    projectCodesLabel: getText(job.ProjectCodesLabel),
    projectContractStatus: job.ContractStatus ? convertItem(job.ContractStatus) : null,
    resourceUsageDepartments: convertMaybeArray(resourceUsageDepartments, convertResourceUsageDepartment),
    result: getText(job.Result),
    scienceBrunches: convertRefElements(scienceBrunches),
    scienceDomainInterrests: convertRefElements(scienceInterests),
    scientificGroundwork: getText(job.ScientificGroundwork),
    specification: job.Specification ? convertReference(job.Specification) : null,
    stages: convertMaybeArray(stages, convertStage).sort((a, b) => a.number.localeCompare(b.number)),
    startDate: getText(job.StartDate),
    stateNumber: getText(job.StateNumber),
    stateRegistrationAnnotation: getText(job.StateRegistrationAnnotation),
    stateRegistrationDate: getText(job.StateRegistrationDate),
    stateRegistrationInterdisciplinaryAproachJustification: getText(job.StateRegistrationInterdisciplinaryAproachJustification),
    stateRegistrationReports: convertMaybeArray(stageRegistrationReports, convertStateRegistrationReport),
    stateRegistrationScientificGroundwork: getText(job.StateRegistrationScientificGroundwork),
    status: getText(job.Status),
    stopMoneyDate: getText(job.StopMoneyDate),
    stopMoneyReason: job.StopMoneyReason ? convertStopMoneyReason(job) : null,
    techPlatforms: convertRefElements(techPlatforms),
    workSpaces: convertRefElements(workSpaces),
    type: job.Type ? convertItem(job.Type) : null,
    udks: convertRefElements(udks),

    securityDocumentContract: job.SecurityDocumentContract
      ? convertSecurityDocumentContract(job.SecurityDocumentContract, settings)
      : null,

    isRequestProvision: getText(job.IsRequestProvision) === 'true',
    requestProvisionAmount: getText(job.RequestProvisionAmount) || '0.00',
    requestProvisionType: convertProvisionType(job.RequestProvisionType),
    requestProvisionNote: getText(job.RequestProvisionNote),

    isContractProvision: getText(job.IsContractProvision) === 'true',
    contractProvisionAmount: getText(job.ContractProvisionAmount) || '0.00',
    contractProvisionType: convertProvisionType(job.ContractProvisionType),
    contractProvisionNote: getText(job.ContractProvisionNote),
    vaks: convertRefElements(vaks),
    nirRequestDataCopyDetail: getText(job.NirRequestDataCopyDetail),
    programRequestDataCopyDetail: getText(job.ProgramRequestDataCopyDetail),
    stateRegistrationEgisuUrl: getText(job.StateRegistrationEgisuUrl),
    purchaseRequests: convertMaybeArray(job.PurchaseRequests?.PurchaseRequest || [], convertPurchaseRequests),
    securityDocuments: convertMaybeArray(job.SecurityDocuments?.SecurityDocument || [], convertSecurityDocument),
  };
}

function convertSecurityDocument(securityDocument: any): Project.SecurityDocument {
  return {
    id: getId(securityDocument),
    label: getText(securityDocument.Label),
    documentDate: getText(securityDocument.DocumentDate),
    requestDate: getText(securityDocument.RequestDate),
  };
}

function convertPurchaseRequests(purchaseRequest: any): Project.PurchaseRequest {
  return {
    id: getId(purchaseRequest),
    type: convertItem(purchaseRequest.Type),
    status: convertItem(purchaseRequest.Status),
    name: getText(purchaseRequest.Name),
    sendDate: getText(purchaseRequest.SendDate),
  };
}

const convertProvisionType = (provisionType: any): Option | null => {
  const value = getText(provisionType);
  const label = getLabel(provisionType);
  if (value) {
    return {
      value,
      label,
    };
  }

  return null;
};

const convertResourceUsageDepartment = (resourceUsageDepartment: any): Project.ResourceUsageDepartment => {
  const typeValue = getText(resourceUsageDepartment?.Type);
  const typeLabel = getLabel(resourceUsageDepartment?.Type);

  return {
    id: getId(resourceUsageDepartment),
    department: convertReference(resourceUsageDepartment?.Department),
    type: typeValue ? { value: typeValue, label: typeLabel } : null,
    note: getText(resourceUsageDepartment?.Note),
  };
};

const convertProgramRequest = (project: any): Item<string> => {
  const programShortName = getText(project?.ProgramRequest?.Tender?.Program?.ShortName);
  const kindTradeLabel = getLabel(project?.ProgramRequest?.Tender?.KindTrade);
  const requestCode = getText(project?.ProgramRequest?.Code);
  const preparedLabel = `Заявка № ${requestCode} на конкурс ${programShortName} (${kindTradeLabel})`;

  return { label: preparedLabel, value: project?.ProgramRequest?._attributes?.id };
};

const convertStopMoneyReason = (project: any): Item<string> => ({
  label: getLabel(project.StopMoneyReason),
  value: getText(project.StopMoneyReason),
});

const convertNirRequest = (project: any): Item<string> => {
  const programShortName = getText(project?.NirRequest?.Program?.ShortName);
  const eventCode = getText(project?.NirRequest?.ProgramEvent?.Code);
  const tenderType = getLabel(project?.NirRequest?.TenderType);
  const nirNumber = getText(project?.NirRequest?.RequestNumber);

  const preparedLabel = `Заявка № ${nirNumber} на конкурс ${programShortName} (${eventCode}/${tenderType})`;

  return { label: preparedLabel, value: project?.NirRequest?._attributes?.id };
};

function convertContestRequest(request: any): Project.ContestRequest {
  return {
    id: getId(request),
    createdBy: getText(request.CreatedBy),
    year: getText(request.Year),
    type: request.Type ? convertItem(request.Type) : null,
    requestStageEndDate: getText(request.RequestStageEndDate),
    requestStageStartDate: getText(request.RequestStageStartDate),
    status: convertItem(request.Status),
    projectNote: getText(request.ProjectNote),
    projectStageId: request.ProjectStage ? getId(request.ProjectStage) : null,
    dataCopyDetail: getText(request.DataCopyDetail),
  };
}

function convertItemProgramEvent(programEvent: any): Item {
  return {
    label: `${getText(programEvent.Code)} ${getText(programEvent.Name)}`,
    value: getId(programEvent),
  };
}

const convertRefElements = R.pipe<Project.RefElement[], Project.RefElement[], Project.RefElement[]>(
  (R.partialRight(convertMaybeArray, [convertRefElement]) as unknown) as (x0: Project.RefElement[]) => Project.RefElement[],
  R.sort((x, y) => Math.abs(Number(x.position)) - Math.abs(Number(y.position))),
);

export function convertStage(stage: any): Project.Stage {
  const indicators = stage.Indicators?.Result ?? [];
  const indices = stage.Indices?.Result ?? [];
  const reports = stage.Reports?.Report ?? [];

  return {
    id: getId(stage) || null,
    accompliceNdsPercent: getText(stage.AccompliceNdsPercent),
    amountAccomplice: getText(stage.AmountAccomplice),
    amountAccompliceUndistributed: getText(stage.AmountAccomplice._attributes.undistributed),
    amountCofinancing1: getText(stage.AmountCofinancing1),
    amountCofinancing1Undistributed: getText(stage.AmountCofinancing1._attributes.undistributed),
    amountCofinancing2: getText(stage.AmountCofinancing2),
    amountCofinancing2Undistributed: getText(stage.AmountCofinancing2._attributes.undistributed),
    amountExternalAccomplices: getText(stage.AmountExternalAccomplices),
    amountLocal: getText(stage.AmountLocal),
    amountLocalUndistributed: stage.AmountLocal._attributes.undistributed,
    amountMain: getText(stage.AmountMain),
    amountFact: getText(stage.AmountFact),
    amountMainUndistributed: stage.AmountMain._attributes.undistributed,
    cofinancing1NdsPercent: getText(stage.Cofinancing1NdsPercent),
    cofinancing2NdsPercent: getText(stage.Cofinancing2NdsPercent),
    endDate: getText(stage.EndDate),
    isLocked: getBoolean(stage.IsLocked),
    isPatentResearchPlanned: getBoolean(stage.IsPatentResearchPlanned),
    localNdsPercent: getText(stage.LocalNdsPercent),
    mainNdsPercent: getText(stage.MainNdsPercent),
    number: getText(stage.Number),
    startDate: getText(stage.StartDate),
    indicators: convertMaybeArray(indicators, convertIndicator),
    indices: convertMaybeArray(indices, convertIndicator),
    annotation: getText(stage.Annotation),
    expectedResults: getText(stage.ExpectedResults),
    goal: getText(stage.Goal),
    kindWork: stage.KindWork ? convertReference(stage.KindWork) : null,
    name: getText(stage.Name),
    task: getText(stage.Task),
    workDescription: getText(stage.WorkDescription),
    obtainedResults: getText(stage.ObtainedResults),
    universityEffect: getText(stage.UniversityEffect),
    regionalEffect: getText(stage.RegionalEffect),
    nationalEffect: getText(stage.NationalEffect),
    reports: convertMaybeArray(reports, convertStageReport),
  };
}

function convertPartner(partner: any, settings: Settings): Project.Partner {
  const financings = partner.Financings?.Financing ?? [];

  return {
    id: getId(partner) || null,
    contractNumber: getText(partner.ContractNumber),
    contractType: partner.ContractType ? convertItem(partner.ContractType) : null,
    endDate: getText(partner.EndDate),
    startDate: getText(partner.StartDate),
    kindWork: getText(partner.KindWork),
    partner: convertServerPartner(partner.Partner, settings),
    role: convertItem(partner.Role),
    financings: convertMaybeArray(financings, convertFinancing),
    note: getText(partner.Note),
    contractName: getText(partner.ContractName),
    leaderAppointment: getText(partner.LeaderAppointment),
    leaderContacts: getText(partner.LeaderContacts),
    leaderDegree: getText(partner.LeaderDegree),
    leaderFullName: getText(partner.LeaderFullName),
    leaderRank: getText(partner.LeaderRank),
    leaderJobEnterprise: partner.LeaderJobEnterprise ? convertReference(partner.LeaderJobEnterprise) : null,
  };
}

function convertFinancing(financing: any): Project.PartnerFinancing {
  return {
    id: getId(financing) || null,
    amount: getText(financing.Amount),
    year: getText(financing.Year),
  };
}

function convertIndicator(indicator: any): Project.Indicator {
  return {
    id: getId(indicator) || null,
    fact: getText(indicator.Fact) || '0',
    plan: getText(indicator.Plan) || '0',
    note: getText(indicator.Note),
    year: getText(indicator.Year),
    ref: convertReference(indicator.ResultItem),
  };
}

export function convertCustomer(customer: any): Project.Customer {
  return {
    id: getId(customer) || null,
    ref: convertReference(customer.Element || customer.Customer),
  };
}

function convertDepartment(department: any): Project.Department {
  return {
    id: getId(department) || null,
    governance: convertReference(department.Governance),
    unit: convertReference(department.Unit),
  };
}

export function convertFincancing(financing: any): Project.Financing {
  return {
    id: getId(financing) || null,
    amount: getText(financing.Amount),
    factAmount: getText(financing.FactAmount),
    isNds: getBoolean(financing.IsNds),
    source: convertReference(financing.Source),
    type: convertItem(financing.Type),
  };
}

export function convertFinancingByYear(financing: any): Project.FinancingByYear {
  return {
    amountAccomplice: getText(financing.AmountAccomplice),
    amountExternalAccomplices: getText(financing.AmountExternalAccomplices),
    amountCofinancing1: getText(financing.AmountCofinancing1),
    amountCofinancing2: getText(financing.AmountCofinancing2),
    amountLocal: getText(financing.AmountLocal),
    amountMain: getText(financing.AmountMain),
    undistributedAmount: getText(financing.UndistributedAmount),
    year: getText(financing.Year),
    stages: [],
  };
}

export function convertRefElement(ref: any): Project.RefElement {
  return {
    id: getId(ref) || null,
    createdBy: getText(ref.CreatedBy?.FullName),
    createdDate: getText(ref.CreatedDate),
    ref: convertReference(ref.Element),
    position: getText(ref.Position),
    isActual: getText(ref.IsActual) === 'true',
    isProject: getText(ref.isProject) === 'true',
  };
}

export function convertPerformer(performer: any, settings: Settings): Project.Performer {
  const jobsPeriods = performer.JobPeriods?.JobPeriod ?? [];
  const stages = performer.Stages?.Stage ?? [];
  return {
    id: getId(performer) || null,
    fio: getText(performer.Fio),
    person: performer.Person ? convertServerPerson(performer.Person, settings) : null,
    jobPeriods: convertMaybeArray(jobsPeriods, j => convertJobPeriod(j, settings)),
    stages: convertMaybeArray(stages, convertPerformerStage),
    createdBy: getText(performer.CreatedBy?.FullName),
    createdDate: getText(performer.CreatedDate),
    paymentMethods: getText(performer.PaymentMethods),
  };
}

function convertPerformerStage(stage: any): Project.PerformerStage {
  return {
    id: getId(stage) || null,
    approved: getBoolean(stage.Approved),
    stage: convertStage(stage.Stage),
    jobDescription: getText(stage.JobDescription),
    approvements: convertMaybeArray(stage.Approvements.Approvement || [], convertApprovement),
    payments: convertMaybeArray(stage.Payments?.Payment || [], convertPayment),
    paymentMethods: getText(stage.PaymentMethods),
  };
}

const convertPayment = (payment: any): Payment => ({
  id: getId(payment),
  projectCode: getText(payment.ProjectCode),
  items: convertMaybeArray(payment.Items.Item || [], convertPaymentItem),
});

const convertPaymentItem = (paymentItem: any): PaymentItem => ({
  id: getId(paymentItem),
  amount: getText(paymentItem.Amount),
  year: getText(paymentItem.Year),
  month: getText(paymentItem.Month),
  method: getText(paymentItem.Method),
});

function convertJobPeriod(jobPeriod: any, settings: Settings): Project.JobPeriod {
  return {
    id: getId(jobPeriod) || null,
    academicRank: jobPeriod.AcademicRank ? authorConverters.convertServerScientistAcademicRank(jobPeriod.AcademicRank) : null,
    citizenship: jobPeriod.Citizenship ? convertReference(jobPeriod.Citizenship) : null,
    degree: jobPeriod.Degree ? authorConverters.convertServerScientistDegree(jobPeriod.Degree) : null,
    education: jobPeriod.Education ? authorConverters.convertServerScientistEducation(jobPeriod.Education) : null,
    job: jobPeriod.Job ? authorConverters.convertServerScientistJob(jobPeriod.Job) : null,
    rank: jobPeriod.Rank ? authorConverters.convertServerScientistRank(jobPeriod.Rank) : null,
    role: convertItem(jobPeriod.Role),
    person: jobPeriod.Person ? convertServerPerson(jobPeriod.Person, settings) : null,
    historyLabel: getText(jobPeriod.HistoryLabel),
    endDate: getText(jobPeriod.EndDate),
    endDateOrder: jobPeriod.EndDateOrder ? convertJobPeriodOrder(jobPeriod.EndDateOrder) : null,
    startDate: getText(jobPeriod.StartDate),
    startDateOrder: jobPeriod.StartDateOrder ? convertJobPeriodOrder(jobPeriod.StartDateOrder) : null,
    note: getText(jobPeriod.Note),
  };
}

export function convertJobPeriodOrder(o: any): JobPeriodOrder {
  return {
    id: getId(o),
    number: getText(o.Number),
    date: getText(o.Date),
    type: convertItem<string>(o.Type),
  };
}

export function convertProjectFeedline(feedline: any): Project.Feedline {
  return {
    id: getId(feedline) || null,
    detail: getText(feedline.Detail),
    message: convertReference(feedline.Message),
    operator: convertItem(feedline.Operator),
    type: convertItem(feedline.Type),
  };
}

export function convertStateRegistrationReport(report: any): Project.StateRegistrationReport {
  return {
    id: getId(report) || null,
    citisDate: getText(report.CitisDate),
    expectedResults: getText(report.ExpectedResults),
    interdisciplinaryAproachJustification: getText(report.InterdisciplinaryAproachJustification),
    keyWords: getText(report.KeyWords),
    referat: getText(report.Referat),
    reportArchiveNumber: getText(report.ReportArchiveNumber),
    reportCustomerSendDate: getText(report.ReportCustomerSendDate),
    reportIkNumber: getText(report.ReportIkNumber),
    reportName: getText(report.ReportName),
    reportType: convertItem(report.ReportType),
    stageName: getText(report.StageName),
    stageNumber: getText(report.Stage.Number),
    stageId: report.Stage._attributes.id,
    file: convertFile(report.File),
  };
}

export function convertProjectCode(projectCode: any): Project.ProjectCode {
  return {
    id: projectCode._attributes.id,
    accompliceAmount: getText(projectCode.AccompliceAmount),
    code: getText(projectCode.Code),
    department: convertDepartment(projectCode.Department),
    ...(projectCode.FundCard ? { fundCard: convertFundCard(projectCode.FundCard) } : {}),
    expenseFactAmount: getText(projectCode.ExpenseFactAmount),
    expensePlanAmount: getText(projectCode.ExpensePlanAmount),
    financing: convertFincancing(projectCode.Financing),
    incomeFactAmount: getText(projectCode.IncomeFactAmount),
    incomePlanAmount: getText(projectCode.IncomePlanAmount),
    letter: convertReference(projectCode.Letter),
    overheadFactAmount: getText(projectCode.OverheadFactAmount),
    overheadPercent: getText(projectCode.OverheadPercent),
    year: getText(projectCode.Year),
  };
}

export function convertStageReport(report: any): Project.StageReport {
  return {
    id: getId(report) || null,
    keyWords: getText(report.KeyWords),
    name: getText(report.Name),
    number: getText(report.Number),
    ontiNumber: getText(report.OntiNumber),
    referat: getText(report.Referat),
    registrationDate: getText(report.RegistrationDate),
    registrationOntiDate: getText(report.RegistrationOntiDate),
    reportDate: getText(report.ReportDate),
    reportOntiDate: getText(report.ReportOntiDate),
    sendCustomerDate: getText(report.SendCustomerDate),
    udk: report.Udk ? convertReference(report.Udk) : null,
    status: report.Status ? convertItem(report.Status) : null,
    type: report.Type ? convertItem(report.Type) : null,
    file: convertFile(report.File),
  };
}

export function getPerformersFromSecurityDocuments(securityDocuments: any[], settings: Settings): Project.Performer[] {
  const docs = Array.isArray(securityDocuments) ? securityDocuments : [securityDocuments];
  return docs.flatMap((doc: any) => {
    const members: any[] = doc.Members?.Member ?? [];
    return convertMaybeArray(members, m => convertSecurityAuthor(m, settings));
  });
}

function convertSecurityAuthor(author: any, settings: Settings) {
  const person = convertServerPerson(author.Person, settings);
  return {
    id: null,
    fio: person.fullName,
    person,
    createdBy: '',
    createdDate: '',
    jobPeriods: [],
    stages: [],
    paymentMethods: '',
  };
}

export const convertCheckResult = (response: any): Project.CheckResult => {
  const checkResult = response.Response?.CheckResult;

  return {
    value: checkResult?._attributes?.value === 'true',
    codes: checkResult?._attributes?.codes ?? '',
  };
};

function convertAct(act: any): Project.Act {
  return {
    id: getId(act),
    number: getText(act.Number),
    stage: convertActStage(act.Stage),
    departments:
      act.Departments && act.Departments.Department
        ? convertMaybeArray(act.Departments.Department || [], convertActDepartment)
        : [],
    сonsiderationDocuments: getText(act.ConsiderationDocuments),
    defaultBaseProgram: getText(act.DefaultBaseProgram),
    council: act.Council ? convertCouncil(act.Council) : null,
    conclusion: getText(act.Conclusion),
    note: getText(act.Note),
    members: act.Members && act.Members.Member ? convertMaybeArray(act.Members.Member || [], convertMember) : [],
  };
}

function convertCouncil(c: any): Project.Council {
  return {
    id: getId(c),
    isLocal: getText(c.IsLocal) === 'true',
    type: convertItem(c.Type),
    status: convertItem(c.Status),
    code: getText(c.Code),
  };
}

function convertActDepartment(actDepartment: any): Project.ActDepartment {
  return {
    id: getId(actDepartment),
    department: convertDepartment(actDepartment.Department),
  };
}

function convertMember(member: any): Project.ActMember {
  return {
    id: getId(member),
    councilMember: convertCouncilMember(member.CouncilMember),
    ...convertPersonHistory(member),
  };
}

function convertCouncilMember(member: any): Project.CouncilMember {
  return {
    id: getId(member),
    label: member?.Person?._attributes?.fullName || '',
    role: convertItem(member.Role),
  };
}

function convertActStage(stage: any): Project.ActStage {
  return {
    id: stage._attributes.id,
    number: getText(stage.Number),
    label: `${getText(stage.Number)} (${getText(stage.StartDate)} - ${getText(stage.EndDate)})`,
  };
}
