import { useMemo } from 'react';

import { IconButtonProps, buttonIcons } from 'components';
import { Mode } from 'components/ListEdit/model';

type Props = {
  mode: Mode;
  modalTitle: string;
  modalEditTitle?: string;
  isCheck?: boolean;
  customEditRow: any | null;
  submitCustomComponent(val?: boolean): void;
  isSaveAndAddCustomComponent?: boolean;
  saveAndAddCustomComponent?: () => void;
};

export function useController(props: Props) {
  const {
    mode,
    modalTitle,
    modalEditTitle,
    isCheck,
    customEditRow,
    submitCustomComponent,
    isSaveAndAddCustomComponent,
    saveAndAddCustomComponent,
  } = props;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      ...(isCheck
        ? [
            {
              icons: buttonIcons.check,
              title: 'Выбрать',
              code: 'save',
              isDisabled: !customEditRow,
              onClick: () => {
                submitCustomComponent();
              },
            },
          ]
        : [
            {
              icons: buttonIcons.save,
              title: 'Сохранить',
              code: 'save',
              isDisabled: false,
              onClick: () => {
                submitCustomComponent();
              },
            },
          ]),
      ...(isSaveAndAddCustomComponent
        ? [
            {
              icons: buttonIcons.saveAndContinue,
              title: 'Сохранить и продолжить',
              code: 'saveAndAdd',
              onClick: saveAndAddCustomComponent,
            },
          ]
        : []),
    ],
    [submitCustomComponent, isCheck, isSaveAndAddCustomComponent, saveAndAddCustomComponent, customEditRow],
  );

  const modalDialogTitle = useMemo(() => {
    return mode == 'edit' && modalEditTitle ? modalEditTitle : modalTitle;
  }, [mode, modalTitle, modalEditTitle]);

  return {
    buttons,
    modalDialogTitle,
  };
}
