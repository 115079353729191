import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Estimate, Form as F } from 'types/models';
import { EntityLocker } from 'features/EntityLocker';
import { Modal } from 'components';

type EstimateEditTemplateProps = F.TemplateProps & {
  estimate?: Estimate;
  amountWarningMessage?: string;
};

const EstimateEditTemplate: React.FC<EstimateEditTemplateProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  estimate,
  amountWarningMessage,
}: EstimateEditTemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.EstimateLook = {
    apiID: 'GetEstimate',
    template: 'EstimateForm',
    editMode: true,
    id: String(id),
    relatedTableState,
    setTitle,
    estimate,
    amountWarningMessage,
  };

  return (
    <EntityLocker onClose={onClose} isOpen={isOpen} entity="Estimate" id={look.id}>
      <Modal isOpen={isOpen} title={title} onClose={onClose} size="full">
        <Form look={look} onClose={onClose} />
      </Modal>
    </EntityLocker>
  );
};

export const Component = React.memo(EstimateEditTemplate);
