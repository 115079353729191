import React from 'react';
import { MobileRequest } from 'types/models';

import { TextArea, TextAreaMode, FormComponent, TextGridSelect, TextInput, TextInputMode } from 'components';

import { useController } from './controller';
import { GetMobileKindConsumptionForSelect } from 'features/Table/specifications/GetMobileKindConsumptionList';

type Props = {
  entry: MobileRequest.EstimatePosition | null;
  onFieldChange(row: MobileRequest.EstimatePosition | null): void;
  type: string;
};

function EstimatePositionFields({ entry, onFieldChange, type }: Props) {
  const { handleKindConsumptionChange, handleAmountChange, handleNoteChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Статья расходов" isRequired>
          <TextGridSelect<MobileRequest.KindConsumption>
            value={entry?.kindConsumption || null}
            onChange={handleKindConsumptionChange}
            specification={GetMobileKindConsumptionForSelect({ type })}
            settings={{
              mode: 'selectTableRow',
              tableRowConverter: x => ({ id: x.id, code: x.Code, name: x.Name, types: [], kindConsumptions: [] }),
            }}
            valueFormatter={x => x?.name || ''}
            title="Виды расхода"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Сумма, руб." isRequired>
          <TextInput mode={TextInputMode.number} value={entry?.amount} onChange={handleAmountChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Примечание' }}
            value={entry?.note || ''}
            onChange={handleNoteChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { EstimatePositionFields };
