import React from 'react';

import { BuildReportPopup } from 'features/BuildReportPopup';

import { Toolbar as SharedToolbar, ConfirmPopup } from 'components';

import { Form, SecurityDocument } from 'types/models';
import { useController } from './controller';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  onViewProjectInfo(): void;
  patent?: SecurityDocument | null;
  formFields: Form.Fields;
  disabled?: boolean;
};

function Toolbar(props: Props) {
  const { onSave, onSaveDraft, onViewProjectInfo, patent, formFields, disabled } = props;
  const {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
  } = useController({ onSave, onSaveDraft, onViewProjectInfo, patent, formFields, disabled });
  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />
      {patent?.id && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          securityDocumentId={patent?.id || ''}
        />
      )}
      <ConfirmPopup
        onClose={() => setIsSaveConfirmPopupOpen(false)}
        isOpen={isSaveConfirmPopupOpen}
        icon="warning"
        title="Предупреждение"
        // eslint-disable-next-line max-len
        text="Дата снятия патента с баланса равна дате подачи заявки. Продолжить сохранение?"
        onConfirm={onSaveConfirmPopupConfirm}
        okButtonText="Да"
        resetButtonText="Отмена"
      />
    </>
  );
}

export { Toolbar };
