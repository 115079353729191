import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Color } from 'constants/colors';

export function GetPersonalExpositionParticipationList(): T.Specification<State> {
  return {
    apiID: 'GetPersonalExpositionParticipationList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: '',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController(),
    highlightEntryPredicate: entry => ({ rowTheme: !!entry.LibraryNote ? Color.danger : '' }),
    isWithQna: true,
  };
}
