import { UsefulLink } from 'types/models/UsefulLink';
import { convertMaybeArray, getId, getText } from '../commonConverters';

export function convertServerUsefulLink(severLink: any): UsefulLink[] {
  const convertServerData = (val: any) => ({
    id: getId(val),
    caption: getText(val.Caption),
    createdDate: getText(val.CreatedDate),
    url: getText(val.Url),
  });
  return convertMaybeArray(severLink.Response?.UsefulLink ?? [], convertServerData);
}
