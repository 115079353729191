import { MB, MAX_UPLOAD_SIZE_MB } from 'shared/constants';

type FileSizeProps = {
  file: File;
  size: number;
};

type FileExtensionsArguments = {
  file: File;
  extensions: string[];
};

export const isSizeError = ({ file, size = MAX_UPLOAD_SIZE_MB }: FileSizeProps) => {
  return file.size >= size * MB;
};

export const isExtensionError = ({ file, extensions }: FileExtensionsArguments) => {
  return !extensions.includes(file.type.split('/').pop() as string);
};
