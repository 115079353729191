export const formatSumToLocaleString = (val: number) => {
  const ranks = ['', 'тыс', 'млн', 'млрд', 'трлн'];

  const rankIndex: number = (() => {
    for (let i = ranks.length - 1; i >= 0; i--) {
      if (val / Math.pow(1000, i) > 1) {
        return i;
      }
    }
    return 0;
  })();

  const value = val / Math.pow(1000, rankIndex);
  const measure = [ranks[rankIndex], 'руб.'].filter(x => x).join('. ');

  return {
    value: value.toLocaleString('RU-ru'),
    measure,
    summary: `${value.toLocaleString('RU-ru')} ${measure}`,
  };
};

export const getIndicatorDataByYear = (indicator: any[], year: number) =>
  indicator.find(ind => ind?._attributes?.Year === year.toString())?._attributes;

export const calcReduceByParameter = (indicator: any[], year: number, param: string): number =>
  indicator.reduce(
    (acc, elem) => acc + parseInt(getIndicatorDataByYear(elem, year) ? getIndicatorDataByYear(elem, year)[param] : '0', 10),
    0,
  );

export const groupByParameter = (arr: any[], parameterName: string) => {
  const temp = arr.reduce((acc, elem) => {
    const param = elem._attributes[parameterName];
    if (!acc[param]) {
      acc[param] = [];
    }
    acc[param].push(elem);
    return acc;
  }, {});
  return Object.getOwnPropertyNames(temp).map(k => temp[k]);
};

export const sortByHardValues = (arr: any[]) => {
  const minObrName = 'Минобрнауки России';
  const grantsName = 'Гранты';

  const outOfFinName = 'Внебюджетные источники финансирования';
  const internationalName = 'Научнотехнические услуги';
  const minObr = arr.find(i => i.name.indexOf(minObrName) > -1);
  const grants = arr.find(i => i.name.indexOf(grantsName) > -1);
  const international = arr.find(i => i.name.indexOf(internationalName) > -1);
  const outOfFin = arr.find(i => i.name.indexOf(outOfFinName) > -1);
  const formatedArray = arr.filter(i => [minObrName, grantsName, internationalName, outOfFinName].indexOf(i.name) === -1);
  formatedArray.unshift(minObr, grants, outOfFin, international);
  return formatedArray.filter(value => !!value);
};

export const getActualAmountValue = (programm: any, isPlanSelected: boolean) =>
  programm[isPlanSelected ? 'planAmount' : 'factAmount'];

export const sortProgramsByAmount = (arr: any[], activeYearIndex: number, isPlanSelected: boolean) => {
  arr.sort(
    (a, b) =>
      getActualAmountValue(b.data[activeYearIndex], isPlanSelected) -
      getActualAmountValue(a.data[activeYearIndex], isPlanSelected),
  );
  return arr;
};
