import { ListEdit } from 'components';
import React, { memo } from 'react';
import { Author, Award } from 'types/models';
import { ParticipationTypeCode } from 'types/models/Participation';

import { showErrorsMessages } from 'utils/Common';
import { Component as AwardForm } from './AwardForm';
import { validate } from './validate';
import useController from './controller';

type Props = {
  awards: Award[];
  setAwards: (awards: Award[]) => void;
  recipients: Author[];
  disabled: boolean;
  participationTypeCode: ParticipationTypeCode;
  tooltip?: string;
};

const Awards = ({ awards, recipients, disabled, participationTypeCode, setAwards, tooltip }: Props) => {
  const { awardColumns, awardsExtraToolbarButtons } = useController();
  return (
    <ListEdit
      tooltipText={tooltip}
      isToolbarDisabled={disabled}
      isSaveAndAddCustomComponent
      isUniq={false}
      extraToolbarButtons={awardsExtraToolbarButtons}
      title="Награды"
      maxHeight="none"
      isFullScreenedTable
      rows={awards}
      onChange={(rows: Award[]) => {
        setAwards(rows);
      }}
      columns={awardColumns}
      specification={{
        mode: 'customComponent',
        onPreSubmit: (award, submit) => {
          const preparedAward = { ...award };
          if (!preparedAward.recipients || !preparedAward.recipients?.length) {
            preparedAward.recipients = JSON.parse(JSON.stringify(recipients));
          }
          submit(preparedAward);
        },
        renderComponent: (row, onChange) => (
          <AwardForm row={row} onChange={onChange} recipients={recipients} participationTypeCode={participationTypeCode} />
        ),
        validation: {
          checkIsValid: row => !validate(row).filter(({ isValid }) => !isValid).length,
          onInvalidate: row =>
            showErrorsMessages(
              validate(row)
                .filter(({ isValid }) => !isValid)
                .map(({ invalidMessage }) => invalidMessage),
            ),
        },
      }}
    />
  );
};

export const AwardsComponent = memo(Awards);
