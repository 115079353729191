import React, { memo } from 'react';
import { Table as T } from 'types/models';

import { GetCompilationArticleList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';

type Props = {
  relatedTableState?: T.State;
  workMode: T.WorkMode;
  publicationType?: string;
  publicationId?: string;
};

const Articles = ({ relatedTableState, workMode, publicationId, publicationType }: Props) => (
  <>
    {relatedTableState && (
      <DataGrid
        specification={GetCompilationArticleList({
          templatesTableDependencies: {
            relatedTableAPIID: 'GetCompilationArticleList',
            relatedTableState,
            relatedRecordId: publicationId,
            isFiltersHidden: true,
          },
          workMode,
          parentTypeCode: publicationType,
          parentId: publicationId,
        })}
      />
    )}
  </>
);

export const ArticlesComponent = memo(Articles);
