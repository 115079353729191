import React, { ReactElement } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-wrapper');

type Props = {
  children: ReactElement | false | (ReactElement | false)[];
  specialStyles?: React.CSSProperties;
};

export function Wrapper(props: Props) {
  const { children, specialStyles } = props;

  return (
    <div className={b()} style={specialStyles}>
      {children}
    </div>
  );
}
