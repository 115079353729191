import React from 'react';

import { FormComponent, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetSystemUpdatesList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { QuestionAndAnswersList } from 'features/PersonCard/Tabs/QuestionsAndAnswers/Tabs';
import * as Blocks from './blocks';

function Home() {
  const { userPermission } = useAppDataContext();

  return (
    <Tabs id="home" isStrip>
      <Tab title="Новости">
        <Blocks.Notices isHome />
      </Tab>
      <Tab title="О конкурсах">
        <Blocks.Notices isHome isTenders />
      </Tab>
      <Tab title="Открытые данные">
        <Blocks.ScienceCharts />
      </Tab>
      <Tab title="Часто задаваемые вопросы">
        <QuestionAndAnswersList />
      </Tab>
      <Tab title="Справочные материалы и ссылки">
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <Blocks.HelpfulStuff />
          </FormComponent.Column>
          <FormComponent.Column>
            <Blocks.UsefulLinks />
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </Tab>
      <Tab title="Обновления в системе">
        <DataGrid specification={GetSystemUpdatesList} />
      </Tab>
      {isHasPermission(userPermission, Permits.QNA_ADMINISTRATE) && (
        <Tab title="Вопросы от пользователей администраторам">
          <Blocks.Questions />
        </Tab>
      )}
    </Tabs>
  );
}

export const Component = React.memo(Home);
