import { GetLotSelectList } from 'features/Table/specifications/GetLotSelectList';
import { useCallback, useMemo } from 'react';
import { Original } from 'types/models/Form';
import { Form, Table } from 'types/models';
import { Finance } from 'types/models/ProgramRequest';

type Props = {
  formFields: Form.Fields;
};

export function useController({ formFields }: Props) {
  const lotSpecification = GetLotSelectList({ setLotId: () => {} });

  const lotModalTableRowConverter = useCallback<(row: Table.Entry) => Original>(
    row => ({
      id: row.id || '',
      name: row.Theme,
    }),
    [],
  );

  const calcFinTotal = useCallback(
    (fins: Finance[]) =>
      fins.length > 0
        ? fins
            .map(
              fin =>
                (typeof fin.fbAmount === 'string' ? parseFloat(fin.fbAmount) : fin.fbAmount || 0) +
                (typeof fin.sfAmount === 'string' ? parseFloat(fin.sfAmount) : fin.sfAmount || 0),
            )
            .reduce((prev: number, cur: number) => prev + cur)
        : 0,
    [],
  );

  type GetIsSomeFinanceHasEqualDate = {
    row: Finance | null;
    list: Finance[];
    index: number | null;
  };

  const getIsSomeFinanceHasEqualDate = ({ row, list, index }: GetIsSomeFinanceHasEqualDate) => {
    const isSomeFinanceHasEqualDate = !list.some((finance, financeIndex) => financeIndex !== index && finance.year === row?.year);
    return isSomeFinanceHasEqualDate;
  };

  const programEventName = useMemo(() => formFields.tender.value?.programEvent?.name, [
    formFields.tender.value?.programEvent?.name,
  ]);
  const programEventDirectionName = useMemo(() => formFields.tender.value?.programEvent?.direction?.name, [
    formFields.tender.value?.programEvent?.direction?.name,
  ]);

  return {
    programEventName,
    programEventDirectionName,
    lotSpecification,
    lotModalTableRowConverter,
    calcFinTotal,
    getIsSomeFinanceHasEqualDate,
  };
}
