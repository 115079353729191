import React, { useCallback, useMemo, useState } from 'react';
import { setup } from 'bem-cn';
import * as icons from 'icons';

import { IconButton, IconButtonProps, RelationTableModal, RequiredBadge, TextArea, Tooltip } from 'components';

import { Form } from 'types/models';
import { Entry, Specification } from 'types/models/Table';
import { SubmitTable } from 'features/Table/streams';

import './style.scss';

type DefaultRow = {
  id: string | null;
};

type Props<TableState extends {}, Row extends DefaultRow> = {
  title?: string;
  disabled?: boolean;
  original?: Form.Original | null;
  modalTableSpecification: Specification<TableState>;
  onChange(rows: Row | null): void;
  modalTableRowConverter(modalTableRow: Entry): Row;
  onDeleteFunction?: () => void;
  relationTableModalTitle: string;
  textareaPlaceholder?: string;
  isRequired?: boolean;
  isStatusText?: boolean;
  isDependent?: boolean;
  dependentId?: string;
  customButtons?: IconButtonProps[];
  editPermissionName?: string;
  customStatus?: string;
  IsShowYear?: boolean;
  isShowFullName?: boolean;
  IsShowBibliographic?: boolean;
  rowsNumber?: number;
  isDisableClear?: boolean;
  modalHelpText?: string;
  modalTooltipText?: string;
  iconTitle?: string;
  titleTooltip?: string;
};

const buttonIcons = {
  list: { default: icons.List, disabled: icons.ListDisabled },
  delete: { default: icons.Delete, disabled: icons.DeleteDisabled },
};

const block = setup({ mod: '--' });
const b = block('custom-select');

function CustomSelect<Row extends DefaultRow, CustomTableState extends {} = {}>({
  title,
  original,
  modalTableSpecification,
  onChange,
  modalTableRowConverter,
  relationTableModalTitle,
  onDeleteFunction,
  isStatusText = false,
  isRequired = false,
  isDisableClear = false,
  disabled = false,
  textareaPlaceholder = 'Выберите элемент из справочника',
  customButtons = [],
  customStatus = '',
  editPermissionName,
  IsShowYear,
  isShowFullName,
  IsShowBibliographic,
  rowsNumber,
  modalHelpText,
  modalTooltipText,
  iconTitle,
  titleTooltip,
}: Props<CustomTableState, Row>) {
  const [isOpenTableModal, setIsOpenTableModal] = useState(false);

  const onSubmitTable = useCallback(
    ({ selectedRows }: SubmitTable) => {
      onChange(modalTableRowConverter(selectedRows[0]));
      setIsOpenTableModal(false);
    },
    [modalTableRowConverter, onChange],
  );

  const onClear = useCallback(() => {
    if (onDeleteFunction) {
      onDeleteFunction();
    } else {
      onChange(null);
    }
  }, [onChange, onDeleteFunction]);
  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.list,
        title: iconTitle || 'Добавить',
        code: 'list',
        isDisabled: disabled || false,
        permissionName: editPermissionName,
        onClick: (event: any) => {
          event?.currentTarget.blur();
          setIsOpenTableModal(true);
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: editPermissionName,
        isDisabled: disabled || original === null || isDisableClear,
        onClick: onClear,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClear, original, disabled, editPermissionName, isDisableClear],
  );
  const value = useMemo(
    () => {
      if (isShowFullName) return original?.fullName || '';
      if (IsShowYear) return original?.year || '';
      if (IsShowBibliographic) return original?.bibliographic || '';
      if (original?.foreignName) return `${original?.name} (${original?.foreignName})`;
      return original?.name || '';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [IsShowYear, isShowFullName, original],
  );

  return (
    <div className={b()}>
      {title && (
        <span className={b('title')}>
          {title} {isRequired && <RequiredBadge />}
          {titleTooltip && <Tooltip text={titleTooltip} />}
        </span>
      )}

      <div className={b('original-wrapper')}>
        <TextArea
          settings={{ rows: rowsNumber || 2 }}
          value={value}
          {...(disabled ? {} : { onClick: () => setIsOpenTableModal(true) })}
          isDisabled
          placeholder={textareaPlaceholder}
        />

        {isStatusText && customStatus && <div className={b('hint')}>{customStatus}</div>}
        {isStatusText && !customStatus && (
          <div className={b('hint', { status: true })}>{`${original ? `ID: ${original.id || ''}. ` : ''}Статус записи: ${
            original ? original.status : 'Нет'
          }`}</div>
        )}
      </div>

      <div className={`${b('buttons-wrapper')} ${customButtons.length ? b('buttons-wrapper--custom-buttons') : ''}`}>
        <div className={b('buttons')}>
          {[...buttons, ...customButtons].map(x => (
            <IconButton {...x} key={x.title} />
          ))}
        </div>
      </div>

      <RelationTableModal
        specification={{
          ...modalTableSpecification,
          onSubmitTable,
        }}
        relationTableModalTitle={`${relationTableModalTitle}`}
        isOpen={isOpenTableModal}
        onClose={() => setIsOpenTableModal(false)}
        helpText={modalHelpText}
        tooltipText={modalTooltipText}
      />
    </div>
  );
}

export { CustomSelect };
