import { Form } from 'types/models';

const isISBNValid = (isbnField: Form.Field) => {
  const { value } = isbnField;

  let isValid = true;

  if (!value) {
    return isValid;
  }

  const MAX_SEPARATOR_SYMBOLS_LENGTH = 4;
  const MAX_SYMBOLS_LENGTH = 13;
  const MIN_SYMBOLS_LENGTH = 10;

  const separator = '-';

  const isbnSymbolsArray = value.split('');
  const separatorCount = isbnSymbolsArray.reduce(
    (accum: number, nextValue: string) => accum + (nextValue === separator ? 1 : 0),
    0,
  );
  const isbnSymbolsExpectSeparator = isbnSymbolsArray.filter((symbol: string) => symbol !== separator);

  if (separatorCount > MAX_SEPARATOR_SYMBOLS_LENGTH) {
    isValid = false;
  }

  const isMinLength = isbnSymbolsExpectSeparator.length === MIN_SYMBOLS_LENGTH;
  const isMaxLength = isbnSymbolsExpectSeparator.length === MAX_SYMBOLS_LENGTH;
  if (!isMinLength && !isMaxLength) {
    isValid = false;
  }

  return isValid;
};

export { isISBNValid };
