import React, { useCallback, useEffect, useRef, useState } from 'react';
import { intervalToDuration } from 'date-fns';

import { LockedInfo } from 'types/models';

import * as BackendAPI from 'services/BackendAPI';
import { usePrevious } from 'shared/react/usePrevious';
import { ConfirmPopup } from 'components';

type Props = {
  id: string | undefined;
  entity: string;
  isOpen: boolean;
  children: JSX.Element;
  onClose(): void;
  isCallableWithoutId?: boolean;
};

function EntityLocker(props: Props) {
  const { id, entity, children, isOpen, onClose, isCallableWithoutId = false } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [lockedInfo, setLockedInfo] = useState<LockedInfo | null>(null);
  const [isShowChild, setIsShowChild] = useState(false);
  const isLocked = useRef(false);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
    onClose();
  }, [onClose]);

  const resetState = useCallback(() => {
    setIsOpenModal(false);
    setLockedInfo(null);
    setIsShowChild(false);
  }, []);

  const { methods: LockEntityAPI } = BackendAPI.useBackendAPI('LockEntity');
  const { methods: UnlockEntityAPI } = BackendAPI.useBackendAPI('UnlockEntity');

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (!prevIsOpen && isOpen && id) {
      LockEntityAPI.callAPI(
        { nameEntity: entity, id },
        {
          onSuccessfullCall: () => {
            setIsShowChild(true);
            isLocked.current = true;
          },
          onFailedCall: ({ data }) => {
            if (data) {
              setLockedInfo(data);
              setIsOpenModal(true);
            }
          },
        },
      );
    }

    if (prevIsOpen && !isOpen && id && isLocked.current) {
      resetState();
      isLocked.current = false;
      UnlockEntityAPI.callAPI({ nameEntity: entity, id });
    }

    return () => {
      if (id && isLocked.current) {
        resetState();
        isLocked.current = false;
        UnlockEntityAPI.callAPI({ nameEntity: entity, id });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevIsOpen, isOpen, id]);

  const formatTime = () => {
    const time = intervalToDuration({ start: 0, end: lockedInfo?.duration ?? 0 });
    return time.minutes ? `${time.minutes} мин. ${time.seconds} сек.` : `${time.seconds} сек.`;
  };

  return (
    <>
      <ConfirmPopup
        title="Параллельный доступ"
        isOpen={isOpenModal}
        okButtonText="ОК"
        onClose={closeModal}
        onConfirm={closeModal}
      >
        <div>
          Данная запись была открыта на редактирование другим пользователем. Дождитесь, пока пользователь закончит работу с данной
          записью или обратитесь к администратору
          <br />
          Блокировка записей автоматически снимается через 1 часов после открытия
          <br />
          <br />
          Прошло времени с момента открытия - <strong>{formatTime()}</strong>
          <br />
          Открыто пользователем - <strong>{lockedInfo?.lockedBy}</strong>
        </div>
      </ConfirmPopup>
      {(isShowChild || (!id && isCallableWithoutId)) && children}
    </>
  );
}

export { EntityLocker };
