export enum EventModule {
  MAIN = 'MAIN',
  DEPARTMENTS = 'DEPARTMENTS',
  ENTERPRISES = 'ENTERPRISES',
  COMITTE_MEMBERS = 'COMITTE_MEMBERS',
  PROJECTS = 'PROJECTS',
  MEMBER_COUNTS = 'MEMBER_COUNTS',
  CLASSIFICATORS = 'CLASSIFICATORS',
  DOCUMENTS = 'DOCUMENTS',
  COMPILATIONS = 'COMPILATIONS',
  MAGAZINE_RELEASES = 'MAGAZINE_RELEASES',
  PUBLICATIONS = 'PUBLICATIONS',
  PARTICIPATIONS = 'PARTICIPATIONS',
  PARTNERS = 'PARTNERS',
  ESTIMATE = 'ESTIMATE',
  INCENTIVE_PAYMENTS = 'INCENTIVE_PAYMENTS',
}
