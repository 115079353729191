import { Table } from 'types/models';
import { Toolbar } from './Toolbar/Toolbar';

export const GetScientistDuplicateList: Table.Specification = {
  apiID: 'GetScientistDuplicateList',
  header: {
    firstLevel: {
      title: 'Возможные дубликаты персон',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: Toolbar,
    },
  },
};
