import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export type DetailProps = {
  isSearchMode?: boolean;
};
export function GetScientistStatistics({ isSearchMode = false }: DetailProps): Table.Specification<State> {
  return {
    apiID: 'GetScientistStatistics',
    header: {
      firstLevel: {
        title: isSearchMode ? 'Поиск персон по научным интересам' : 'Наукометрические показатели персон',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ isSearchMode }),
    isWithQna: true,
  };
}
