import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, Modal } from 'components';

import { Table, Form } from 'types/models';
import { EditPreprintRelation } from 'features/Form/views';

type Props = {
  onClose: () => void;
  printArticle: Form.PrintArticle | null;
  changePrintArticle: (val: Form.PrintArticle | null) => void;
  viewMode: boolean;
  relatedTableState: Table.State | undefined;
  isOpen: boolean;
  sourceRelease: Form.Original | null;
};

const PreprintPublicationsRelations = ({
  isOpen,
  onClose,
  printArticle,
  changePrintArticle,
  relatedTableState,
  sourceRelease,
  viewMode,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Редактирование связи препринта с публикацией" size="medium">
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <EditPreprintRelation
          handleChangePrintArticle={changePrintArticle}
          printArticle={printArticle}
          isDisabled={viewMode}
          releaseId={sourceRelease?.id}
          releaseYear={sourceRelease?.year}
          relatedTableState={relatedTableState}
        />
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Продолжить" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  </Modal>
);

export const Container = memo(PreprintPublicationsRelations);
