import * as regexp from 'utils/Regexps';

import { Mode } from 'components/ListEdit/model';

import { OtherProject } from 'types/models';

export const validate = (
  financing: OtherProject.Financing | null,
  index: number | null,
  financings: OtherProject.Financing[],
  mode: Mode,
) => {
  const financingList = financings.filter((_, financingIndex) => (mode === 'edit' ? financingIndex !== index : true));
  const isYearRepeated = financingList.some(({ year }) => year === financing?.year);

  const isYearValid = new RegExp(regexp.finalYearRegExp).test(financing?.year ?? '');
  const isAmountValid = Boolean(financing?.amount);

  return [
    { isValid: isYearValid, invalidMessage: financing?.year ? 'Поле год заполнено не корректно' : 'Поле год не заполнено' },
    { isValid: isAmountValid, invalidMessage: 'Поле сумма не заполнено' },
    { isValid: !isYearRepeated, invalidMessage: 'Год не должен повторяться' },
  ];
};
