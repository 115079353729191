import * as R from 'ramda';
import { OtherProject, RecordStatus } from 'types/models';
import { Item, Settings } from 'types/models/common';
import { convertServerAffiliation, convertServerPerson } from '../author/converters';
import { convertDocument, convertMaybeArray, convertReference, getId, getLabel, getText } from '../commonConverters';
import { convertPersonHistory } from '../converters';

export const convertOtherProject = (otherProject: any, settings: Settings): OtherProject.OtherProject => {
  const documents = otherProject?.Documents?.Document || [];
  const grntis = otherProject?.Grntis?.Grnti || [];
  const financings = otherProject?.Financings?.Financing || [];

  const customerAffiliation = otherProject?.Customer ? { Affiliation: otherProject.Customer } : null;

  const enterpriseAffiliation = otherProject?.Enterprise ? { Affiliation: otherProject.Enterprise } : null;

  return {
    id: getId(otherProject),
    status: convertStatus(otherProject),
    name: getText(otherProject?.Name),
    startDate: getText(otherProject?.StartDate),
    endDate: getText(otherProject?.EndDate),
    stateRegistrationNumber: getText(otherProject?.StateRegistrationNumber),
    stateRegistrationDate: getText(otherProject?.StateRegistrationDate),
    number: getText(otherProject?.Number),
    program: convertProgram(otherProject),
    programEvent: convertProgramEvent(otherProject),
    leader: getText(otherProject?.Leader),
    role: convertRole(otherProject),
    customer: customerAffiliation ? convertServerAffiliation(customerAffiliation, settings) : null,
    enterprise: enterpriseAffiliation ? convertServerAffiliation(enterpriseAffiliation, settings) : null,
    amount: getText(otherProject?.Amount),
    note: getText(otherProject.Note),
    performer: convertPerformer(otherProject, settings),
    documents: convertMaybeArray(documents, R.partial(convertDocument, ['local'])),
    grntis: convertMaybeArray(grntis, convertReference),
    financings: convertMaybeArray(financings, convertFinancing),
  };
};

const convertProgram = (otherProject: any): Item<string> | null => {
  if (otherProject?.Program) {
    return {
      value: getId(otherProject?.Program),
      label: getText(otherProject?.Program.ShortName),
    };
  }

  return null;
};

const convertProgramEvent = (otherProject: any): Item<string> | null => {
  if (otherProject?.ProgramEvent) {
    return {
      value: getId(otherProject?.ProgramEvent),
      label: getText(otherProject?.ProgramEvent.Name),
    };
  }

  return null;
};

const convertPerformer = (otherProject: any, settings: Settings): OtherProject.Performer => ({
  id: getId(otherProject?.Performer),
  person: convertServerPerson(otherProject?.Performer, settings),
  ...convertPersonHistory(otherProject),
});

const convertStatus = (otherProject: any): Item<RecordStatus.Code> => ({
  value: getText(otherProject?.Status) as RecordStatus.Code,
  label: getLabel(otherProject?.Status),
});

const convertRole = (otherProject: any): Item<string> => ({
  value: getText(otherProject?.Role),
  label: getLabel(otherProject?.Role),
});

const convertFinancing = (financing: any): OtherProject.Financing => ({
  id: getId(financing),
  year: getText(financing?.Year),
  amount: getText(financing?.Amount),
});
