import React from 'react';

import { TextGridSelect } from 'components';

import { Program } from 'types/models';

import * as BackendAPI from 'services/BackendAPI';

import { GetProgramList } from 'features/Table/specifications';

type Props = {
  program: Program.Program | null;
  onChange(program: Program.Program | null): void;
  disabled: boolean;
};

export function SelectProgram(props: Props) {
  const { program, onChange, disabled } = props;

  const { methods: getProgramAPI } = BackendAPI.useBackendAPI('GetProgram');

  return (
    <TextGridSelect<Program.Program>
      isTextarea
      value={program}
      onChange={onChange}
      disabled={disabled}
      specification={GetProgramList({ withoutMenu: true })}
      settings={{
        mode: 'loadTableRow',
        onSelectRow: (row, submit) => {
          getProgramAPI.callAPI({ id: row.id }, { onSuccessfullCall: ({ data }) => submit(data) });
        },
      }}
      valueFormatter={x => x.name}
      title="Программа"
    />
  );
}
