import React, { useCallback, useEffect, useMemo } from 'react';

import { FormComponent, InputSelect, Option, Radio, Reference, ReferenceItem, TextInput, TextDateTime } from 'components';
import { Mode } from 'components/ListEdit/model';

import { Person, UserPermission } from 'types/models';
import { EnumMap } from 'types/models/Table';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { EducationStatus } from 'utils/Enums/EducationStatus';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { getEnum } from 'utils/Helpers';
import { getMockEducation } from 'features/Form/looks/person/helpers';

type Props = {
  education?: Person.ScientistEducation;
  enumMap: EnumMap;
  onChange(education: Person.ScientistEducation): void;
  isProfile: boolean;
  mode: Mode;
  userPermission: UserPermission | null;
};

export function Fields({ education = getMockEducation(), onChange, enumMap, isProfile, userPermission, mode }: Props) {
  const { settings } = useAppDataContext();

  const makeChangeHandler = useCallback(
    (fieldName: keyof Person.ScientistEducation) => (value: Option | null | ReferenceItem | string) => {
      onChange({ ...education, [fieldName]: value });
    },
    [education, onChange],
  );

  const handleChangeRefDepartment = useCallback(
    (ref: ReferenceItem) => {
      onChange({ ...education, refDepartment: ref, department: ref.label });
    },
    [education, onChange],
  );

  const handleChangeRefSpeciality = useCallback(
    (ref: ReferenceItem) => {
      onChange({ ...education, refSpeciality: ref, speciality: ref.label });
    },
    [education, onChange],
  );

  const handleChangeDepartment = useCallback(
    (val: string) => {
      onChange({ ...education, refDepartment: null, department: val });
    },
    [education, onChange],
  );

  const handleChangeSpeciality = useCallback(
    (val: string) => {
      onChange({ ...education, refSpeciality: null, speciality: val });
    },
    [education, onChange],
  );

  const handleChangeIsTSU = useCallback(
    (value: string) => {
      // :TODO change to org from settings
      const isTSU = value === 'isTSU';

      if (isTSU) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.PERSON_LOCAL_EDUCATION_CREATE);
        if (isUserHasPermission) {
          onChange({ ...education, isTSU: isTSU, enterprise: settings?.organization?.shortName || '' });
        } else {
          showNotification({
            message: `У вас нет права добавлять обучение в ${settings?.organization?.shortName}`,
            theme: 'danger',
          });
        }
      } else {
        onChange({ ...education, isTSU: isTSU, enterprise: settings?.organization?.shortName || '' });
      }
    },
    [userPermission, onChange, education, settings],
  );

  const changeStatus = useCallback(
    (option: Option) => {
      const prevStatus = education?.status;
      const isPrevStatusStudent = prevStatus?.value === EducationStatus.STUDENT;
      const isNextStatusStudent = option.value === EducationStatus.STUDENT;
      if (isPrevStatusStudent || isNextStatusStudent) {
        makeChangeHandler('enterprise')(null);
      }

      onChange({ ...education, status: option });
    },
    [education, makeChangeHandler, onChange],
  );

  const isDisabled = education?.isReadOnly ?? false;

  const options = useMemo(() => getEnum('EducationStatus', enumMap), [enumMap]);

  useEffect(() => {
    const isAddMode = mode === 'add';
    if (isAddMode) {
      const defaultEducationStatus = options.find(({ value }) => value === EducationStatus.STUDENT);
      if (defaultEducationStatus) {
        changeStatus(defaultEducationStatus);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line hasGap>
        <FormComponent.Field label="Статус" isRequired>
          <InputSelect
            options={options}
            value={options.find(x => x.value === education?.status?.value) ?? null}
            onSelectChange={changeStatus}
            disabled={isDisabled}
          />
        </FormComponent.Field>

        <Radio
          isDisabled={isProfile || isDisabled}
          value={education?.isTSU ? 'isTSU' : 'isNotTSU'}
          list={[
            { label: settings?.organization?.shortName, value: 'isTSU' },
            { label: 'Другой организации', value: 'isNotTSU' },
          ]}
          onChange={handleChangeIsTSU}
        />
        <FormComponent.Field labelSize="fit" label="Дата начала">
          <TextDateTime value={education?.dateReceipt} onChange={makeChangeHandler('dateReceipt')} isDisabled={isDisabled} />
        </FormComponent.Field>
        <FormComponent.Field labelSize="fit" label="Дата окончания">
          <TextDateTime
            value={education?.dateDismissal}
            onChange={makeChangeHandler('dateDismissal')}
            isDisabled={!isHasPermission(userPermission, Permits.PERSON_LOCAL_EDUCATION_CREATE)}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Организация" isRequired tooltip="Введите полное имя организации не используя аббревиатуру">
          <TextInput
            value={education?.enterprise || ''}
            onChange={makeChangeHandler('enterprise')}
            isDisabled={education?.isTSU || isDisabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {education?.isTSU ? (
        <>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Специальность">
              <Reference
                name={education?.status?.value === EducationStatus.STUDENT ? 'RefEducationSpeciality' : 'RefVakSpeciality'}
                value={education?.refSpeciality ?? null}
                relationTableModalTitle='Справочник "Специальности"'
                onChange={handleChangeRefSpeciality}
                disabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Подразделение">
              <Reference
                name="RefDepartment"
                value={education?.refDepartment ?? null}
                relationTableModalTitle='Справочник "Подразделения"'
                onChange={handleChangeRefDepartment}
                disabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      ) : (
        <>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Специальность">
              <TextInput value={education?.speciality ?? ''} onChange={handleChangeSpeciality} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Подразделение">
              <TextInput value={education?.department ?? ''} onChange={handleChangeDepartment} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
    </FormComponent.Wrapper>
  );
}
