import { ReferenceItem } from 'components';
import { Mode } from 'components/ListEdit/model';

import { Author, Award, Person } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { ExtendedMember } from './model';
import { EventTypeName } from './eventTypes';

export const USER_ROLES = {
  SUPERVISOR: {
    code: 'SUPERVISOR',
  },
  PARTICIPANT: {
    code: 'PARTICIPANT',
  },
};

export const MAIN_REPORTER_TOOLTIP_TEXT: string =
  // eslint-disable-next-line max-len
  'При заполнении формы ФИО и Роли не забудьте поставить отметку в чек-бокс "Докладчик". Основной докладчик должен быть первым в списке раздела "Сведения об участниках конференции".  В опубликованном докладе это отобразится в библиографической записи и будет загружено в ЕГИСУ.';

export const ALT_PARTICIPANTS_TOOLTIP_TEXT: string =
  'Добавьте участников и сведения о них (История персоны). Руководителя участника или группы участников добавляйте последним.';

export function formatEducation(education: Person.ScientistEducation) {
  const { status, enterprise, refSpeciality, speciality, refDepartment, department } = education;
  return `${status.label}, ${enterprise}, ${refSpeciality?.label ?? speciality}, ${refDepartment?.label ?? department}`;
}

export function getBiblioName(scientistPerson: Person.ScientistPerson, language: ReferenceItem) {
  return scientistPerson.bibliographicNames.find(x => x.isDefault && language.id === x.language?.id)?.name ?? '';
}
export function formatRole(author: Author) {
  const { role } = author;

  return `
    <span>${role?.label || ''}</span>
  `;
}

export function formatJob(job: Person.ScientistJob) {
  const { enterpise, appointment, status, staffGroup, appointmentCategory, refAppointment } = job;
  const appointmentStr =
    staffGroup && appointmentCategory ? `(${staffGroup?.label ?? ''}, ${appointmentCategory?.label ?? ''})` : '';

  return `${enterpise}, ${refAppointment?.label || appointment}, ${appointmentStr} ${status.label}`;
}

export function isCollectiveAuthor(category: Author['category']) {
  return category?.id === '2';
}

export function formatMainAuthor(author: Author) {
  const { job, education, person, historyLabel } = author;
  return `
     <b>${person?.fullName ?? ''}.</b>
    ${historyLabel || ''}
    ${!historyLabel && job ? `${formatJob(job)};` : ''}
    ${!historyLabel && education ? `${formatEducation(education)}.` : ''}
    ${
      !job && !education && !isCollectiveAuthor(author.category)
        ? '<span style="color:red">Не указаны сведения о работе или обучении</span>.'
        : ''
    }
  `;
}

export function formatAuthorAward({ author, awards }: { author: Author; awards: Award[] }) {
  return (
    awards
      .filter(award => award.recipients.some(recipient => recipient.id === author.id))
      .map(award => award?.category?.label)
      .join(', ') || ''
  );
}

export function formatAuthor(author: Author) {
  const { job, education, person, historyLabel, role, isReporter } = author;
  const roleString = role?.label ? `${role?.label}: ` : '';
  return `
    ${roleString}
     <b>${person?.fullName ?? ''}.</b>
    ${historyLabel || ''}
    ${!historyLabel && job ? `${formatJob(job)};` : ''}
    ${!historyLabel && education ? `${formatEducation(education)}.` : ''}
    ${
      !job && !education && !isCollectiveAuthor(author.category)
        ? '<span style="color:red">Не указаны сведения о работе или обучении</span>.'
        : ''
    }
    ${isReporter ? '<b>(Докладчик)</b>' : ''}
  `;
}

export function formatAuthorContacts(author: Author) {
  const { person } = author;

  return person && person.scientist
    ? `
    <span>${person.scientist.phone || '*'}, ${person.scientist.mobilePhone || '*'}, ${person.scientist.email || '*'}</span>
  `
    : '';
}

export function validate(member: ExtendedMember | null, list: ExtendedMember[], mode: Mode, index: number | null) {
  const members = mode === 'edit' ? list.filter((_, memberIndex) => index !== memberIndex) : list;

  const isValidaMember = Boolean(member?.person);
  const isValidRole = Boolean(member?.role);

  const hasDublicates = members.some(existMember => {
    const isEqualPerson = existMember?.person?.id === member?.person?.id;
    const isEqualRole = existMember?.role?.id === member?.role?.id;
    return isEqualPerson && isEqualRole;
  });
  return [
    { isValid: isValidaMember, invalidMessage: 'Выберите участника' },
    { isValid: isValidRole, invalidMessage: 'Выберите роль' },
    { isValid: !hasDublicates, invalidMessage: 'Участник с такой ролью уже добавлен' },
  ];
}

export const getSupervisorWarningText = (eventType: EventTypeName) => {
  switch (eventType) {
    case EventTypes.EVENT_EXPOSITION.code:
      return 'Сначала необходимо добавить авторов экспоната, затем руководителя авторского коллектива';
    case EventTypes.EVENT_CONCERT.code:
      // eslint-disable-next-line max-len
      return 'Сначала необходимо добавить участников коллектива, или участника творческого мероприятия, затем руководителя коллектива/участника';
    case EventTypes.EVENT_CONTEST.code:
      return 'Сначала необходимо добавить участника(ов) конкурса, затем руководителя для участника';
    default:
      return 'Сначала необходимо добавить участника(ов), затем руководителя';
  }
};

export const getSupervisorInfoText = (eventType: EventTypeName) => {
  switch (eventType) {
    case EventTypes.EVENT_EXPOSITION.code:
      return 'Укажите участников авторского коллектива';
    case EventTypes.EVENT_CONCERT.code:
      // eslint-disable-next-line max-len
      return 'Укажите участника творческого мероприятия (участника коллектива)';
    case EventTypes.EVENT_CONTEST.code:
      return 'Укажите участника(ов) конкурса для связи с руководителем';
    default:
      return 'Укажите участников';
  }
};

export function getMockExtendedMember(): ExtendedMember {
  return {
    academicRank: null,
    affilateCount: null,
    category: null,
    citizenship: null,
    degree: null,
    education: null,
    enterprise: null,
    historyLabel: '',
    id: null,
    isAffilated: false,
    job: null,
    memberAffilations: [],
    name: '',
    person: null,
    position: 0,
    rank: null,
    isReporter: false,
    role: null,
    participantIds: [],
  };
}
