import { SecurityDocumentInfo, SecurityDocumentPayment, SecurityDocumentType } from 'types/models';

import { convertReference, getId, getText, convertFile, convertItem } from '../commonConverters';

export function convertSecurityDocumentPayment(p: any): SecurityDocumentPayment {
  return {
    id: getId(p),
    amount: parseFloat(getText(p.Amount)),
    file: convertFile(p.File),
    payBeforeDate: getText(p.PayBeforeDate),
    payDate: getText(p.PayDate),
    paymentPurpose: convertReference(p.PaymentPurpose),
    paymentPurposeRequest: convertReference(p.PaymentPurposeRequest),
    securityDocument: convertSecurityDocumentInfo(p.SecurityDocument),
  };
}
export function convertSecurityDocumentInfo(s: any): SecurityDocumentInfo {
  return {
    id: getId(s),
    fullName: getText(s.FullName),
    type: convertItem<SecurityDocumentType>(s.Type),
    documentNumber: getText(s.DocumentNumber),
  };
}
