import React, { useMemo } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { State } from 'features/Table/specifications/GetShortEventList/makeUseCustomController';

type CustomType = {
  customState: State;
};
const EventAddTemplate: React.FC<F.TemplateProps & CustomType> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  customState,
}: F.TemplateProps & CustomType) => {
  const event = useMemo(() => {
    switch (customState.eventType) {
      case EventTypes.EVENT_CONFERENCE.code:
        return EventTypes.EVENT_CONFERENCE;
      case EventTypes.EVENT_EXPOSITION.code:
        return EventTypes.EVENT_EXPOSITION;
      case EventTypes.EVENT_CONTEST.code:
        return EventTypes.EVENT_CONTEST;
      case EventTypes.EVENT_CONCERT.code:
        return EventTypes.EVENT_CONCERT;
      default:
        return EventTypes.EVENT_CONFERENCE;
    }
  }, [customState.eventType]);

  const look: F.EventFormLook = {
    apiID: 'GetEvent',
    template: event.template,
    type: customState.eventType,
    relatedTableState,
    name,
    isDisableEventSource: customState.isDisableSource,
    isMagazineReleasesPlanned: customState.isMagazineReleasesPlanned,
    isCompilationsPlanned: customState.isCompilationsPlanned,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Добавление "${event.label}"`} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(EventAddTemplate);
