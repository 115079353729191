import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export const GetParticipationFeedlineList = (
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
): T.Specification<State> => {
  const toolbarEnabled: boolean = workMode !== 'addMode';
  return {
    apiID: 'GetParticipationFeedlineList',
    templatesTableDependencies,
    useCustomController: makeUseCustomController(templatesTableDependencies),
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: toolbarEnabled ? LeftPanelForThirdLevel : undefined,
      },
    },
  };
};
