import React, { memo } from 'react';

import { FormComponent, Reference, ReferenceFilter } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

type Props = {
  referenceName: string;
  referenceRelationTableModalTitle: string;
  referenceFilter: ReferenceFilter[];
} & BuilderProps;

const DefaultReference = ({
  parameters,
  setParameterValueByName,
  parameterName,
  referenceName,
  referenceRelationTableModalTitle,
  referenceFilter = [],
}: Props) => {
  const { referenceParameter, canBuild, referenceItem, handleSetReferenceItem } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });
  return (
    <>
      {canBuild && (
        <FormComponent.Line>
          <FormComponent.Field label={referenceParameter?.description}>
            <Reference
              name={referenceName}
              onChange={handleSetReferenceItem}
              relationTableModalTitle={referenceRelationTableModalTitle}
              value={referenceItem}
              filters={referenceFilter}
              isRequired
              disabled={false}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
};

const Component = memo(DefaultReference);

export { Component as DefaultReference };
