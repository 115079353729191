import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Settings } from 'types/models/common';
import { MobileRequest } from 'types/models';
import { MobileRequestStatus } from 'utils/Enums';
import { convertMobileRequest } from './converters';
import { convertMobileRequestToRequest } from './requestConverters';
import { getText } from '../commonConverters';

const configurations = {
  GetMobileRequest: makeBackendAPIConfiguration({
    id: 'GetMobileRequest',
    endpoint: '/msa/service/commands/GetMobileRequest',
    convertInputToXMLElement: (mobileRequest: MobileRequest.MobileRequest) => ({
      Request: {
        _attr: { commandName: 'GetMobileRequest' },
        MobileRequest: { ...convertMobileRequestToRequest({ mobileRequest }) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) => {
      return convertMobileRequest(response.Response.MobileRequest, settings);
    },
  }),
  SaveMobileRequest: makeBackendAPIConfiguration({
    id: 'SaveMobileRequest',
    endpoint: '/msa/service/commands/SaveMobileRequest',
    convertInputToXMLElement: ({
      mobileRequest,
      isPrepareSave,
    }: {
      mobileRequest: MobileRequest.MobileRequest;
      isPrepareSave?: boolean;
    }) => {
      return {
        Request: {
          _attr: {
            commandName: 'SaveMobileRequest',
          },
          MobileRequest: { ...convertMobileRequestToRequest({ mobileRequest, isPrepareSave }) },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response?.Response?.Id),
  }),
  ChangeMobileRequestStatus: makeBackendAPIConfiguration({
    id: 'ChangeMobileRequestStatus',
    endpoint: '/msa/service/commands/ChangeMobileRequestStatus',
    convertInputToXMLElement: ({ ids, status, message }: { ids: string[]; status: MobileRequestStatus; message: string }) => {
      return {
        Request: {
          _attr: { commandName: 'ChangeMobileRequestStatus' },
          MobileRequest: ids.map(id => ({
            _attr: { id },
            Status: status,
            ...(message ? { Message: message } : {}),
          })),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SetMobileProtocolProjectForMobileRequest: makeBackendAPIConfiguration({
    id: 'SetMobileProtocolProjectForMobileRequest',
    endpoint: '/msa/service/commands/SetMobileProtocolProjectForMobileRequest',
    convertInputToXMLElement: ({ id, type, isInclude }: { id: string; type: string; isInclude: boolean }) => {
      return {
        Request: {
          _attr: {
            commandName: 'SetMobileProtocolProjectForMobileRequest',
          },
          MobileRequests: {
            _attr: { isInclude, type },
            MobileRequest: {
              _attr: { id },
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  AddMobileRequestApprovement: makeBackendAPIConfiguration({
    id: 'AddMobileRequestApprovement',
    endpoint: '/msa/service/commands/AddMobileRequestApprovement',
    convertInputToXMLElement: ({
      message,
      mobileRequestId,
      status,
    }: {
      message: string;
      mobileRequestId: string;
      status: string;
    }) => ({
      Request: {
        _attr: { commandName: 'AddMobileRequestApprovement' },
        MobileRequest: {
          _attr: { id: mobileRequestId },
          Approvement: {
            Resolution: status,
            ...(message ? { ApprovementMessage: message } : {}),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const mobileRequestConfigurations = { ...configurations };
