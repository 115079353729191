import React from 'react';
import { block } from 'bem-cn';

import { Estimate } from 'types/models';
import { buttonIcons, Collapse, FormComponent, IconButton } from 'components';
import { formatNumber } from 'utils/Helpers';

import './style.scss';

type Props = {
  estimate: Estimate | null;
  receivingDocumentsCallback(): void;
  estimateOverheadCallback(): void;
};

const b = block('estimate-summary');

export function EstimateSummary({ estimate, receivingDocumentsCallback, estimateOverheadCallback }: Props) {
  const { calculation } = estimate || {};

  return (
    <div className={b()}>
      <Collapse title="Суммарная информация по смете">
        <FormComponent.Wrapper>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column specialStyles={{ width: '40%' }}>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Финансирование:">
                  <span>{estimate?.projectCode?.financing?.source?.label || '-'}</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Сумма, план:">
                  <span>
                    <strong>{formatNumber(calculation?.income.plan || 0)} руб.</strong>, в т.ч. НДС{' '}
                    {formatNumber(calculation?.nds.plan || 0)} руб.
                  </span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Сумма, факт:">
                  <span>
                    <strong>{formatNumber(calculation?.income.fact || 0)} руб.</strong>, в т.ч. НДС{' '}
                    {formatNumber(calculation?.nds.fact || 0)} руб.
                    <IconButton title="Список документов" onClick={receivingDocumentsCallback} icons={buttonIcons.info} />
                  </span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Текущий остаток:">
                  <span>{formatNumber(calculation?.remain || 0)} руб.</span>
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column specialStyles={{ width: '60%' }}>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field
                  label="Накладные расходы:"
                  // eslint-disable-next-line max-len
                  tooltip='Для шифра можно установить карточку на вкладке "О шифре". Для конкретной сметы карточку можно указать на форме добавления накладных расходов (Сумма, план). При поступлении средств на шифр автоматически будет рассчитана Сумма, факт = Сумма документа - прихода * Расчётный процент накладных расходов.'
                >
                  <span>{`перечислить на шифр "${
                    (estimate?.positions || []).find(i => i.fundCard)?.fundCard?.name ||
                    estimate?.projectCode?.fundCard?.name ||
                    'Не указан'
                  }"`}</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Нормативный процент:">
                  <span>{formatNumber(calculation?.overhead.normativePercent || 0)} % (нормативный)</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Расчётный процент:">
                  <span>{formatNumber(calculation?.overhead.correctedPercent || 0)} % (расчетный)</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Сумма, план:">
                  <span className={b('overhead')}>
                    {estimate?.correctedOverheadPercent || calculation?.overhead.correctedAmount ? (
                      <span>
                        <strong>{formatNumber(calculation?.overhead.correctedAmount || 0)} руб. </strong> (
                        {formatNumber(calculation?.overhead.correctedPercent || 0)} %)
                      </span>
                    ) : (
                      <span className="critical">не заданы</span>
                    )}
                  </span>
                  <IconButton title="История изменения накладных" onClick={estimateOverheadCallback} icons={buttonIcons.info} />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Сумма, факт:">
                  <span>
                    <strong>{formatNumber(calculation?.overhead.factAmount || 0)} руб. </strong> (
                    {formatNumber(calculation?.overhead.correctedPercent || 0)} %)
                  </span>
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Wrapper>
      </Collapse>
    </div>
  );
}
