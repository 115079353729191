import React, { useEffect } from 'react';

import {
  CustomSelect,
  FormComponent,
  InputSelect,
  SectionTitle,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  Toolbar,
} from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';

import { Form } from 'types/models';
import { PROJECT_PERFORMER_ORDER_TOOLTIP_TXT } from 'types/models/Project';
import { formatOrderPerformer } from './helpers';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectPerformerOrderForm({ viewMode, editMode, onClose }: Props) {
  const {
    look: { setTitle, projectSelectable },
  } = useFormContext<Form.Look & { projectSelectable?: boolean }>();

  const {
    performerOrder,
    buttons,
    workMode,
    makeChangeHandler,
    performerOrderTypeOptions,
    disabled,
    startDatePerformers,
    endDatePerformers,
    projectSpecification,
    projectRowConverter,
    projectOriginal,
    handleProjectChange,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (workMode === 'addMode') {
            return `Приказ о научном коллективе`;
          }

          const details = [performerOrder?.project ? `проекта (ID ${performerOrder?.project?.id})` : '']
            .filter(x => x)
            .join(', ');

          const title = [
            workMode === 'viewMode' ? 'Просмотр приказа о научном коллективе' : 'Редактирование приказа о научном коллективе',
            details ? details : '',
          ]
            .filter(x => x)
            .join(' ');

          return (
            <>
              <h3>{title}</h3>
            </>
          );
        })(),
      );
    }
  }, [performerOrder, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />
      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column specialStyles={{ width: '50%' }}>
            {!!projectSelectable && (
              <FormComponent.Line>
                <FormComponent.Field label="Проект" isRequired>
                  <CustomSelect
                    title=""
                    original={projectOriginal}
                    relationTableModalTitle="Научные проекты"
                    onDeleteFunction={() => handleProjectChange(null)}
                    onChange={val => handleProjectChange(val)}
                    modalTableRowConverter={projectRowConverter}
                    modalTableSpecification={projectSpecification}
                    disabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="Номер приказа" isRequired>
                <TextInput value={performerOrder.number || ''} onChange={makeChangeHandler('number')} isDisabled={disabled} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="Тема приказа" isRequired>
                <InputSelect
                  options={performerOrderTypeOptions}
                  value={performerOrderTypeOptions.find(x => x.value === performerOrder.type?.value) ?? null}
                  onSelectChange={makeChangeHandler('type')}
                  disabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Дата приказа" isRequired>
                <TextDateTime
                  value={performerOrder.date}
                  onChange={val => makeChangeHandler('date')(val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label="Текст приказа" tooltip={PROJECT_PERFORMER_ORDER_TOOLTIP_TXT}>
                <TextArea
                  mode={TextAreaMode.wysiwyg}
                  settings={{ rows: 10, title: 'Текст приказа' }}
                  value={performerOrder.order || ''}
                  onChange={makeChangeHandler('order')}
                  isDisabled={!!disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column specialStyles={{ width: '50%' }}>
            {performerOrder?.id ? (
              <>
                <SectionTitle
                  title={
                    'Приказ указан для даты начала работы в проекте у следующих членов коллектива (' +
                    startDatePerformers.length +
                    ')'
                  }
                />
                <FormComponent.ColumnWrapper>
                  <FormComponent.Column specialStyles={{ width: '50%' }}>
                    {startDatePerformers.slice(0, Math.floor(startDatePerformers.length / 2)).map(x => (
                      <FormComponent.Line>
                        <>{formatOrderPerformer(x, performerOrder, true)}</>
                      </FormComponent.Line>
                    ))}
                  </FormComponent.Column>
                  <FormComponent.Column specialStyles={{ width: '50%' }}>
                    {startDatePerformers.slice(Math.floor(startDatePerformers.length / 2), startDatePerformers.length).map(x => (
                      <FormComponent.Line>
                        <>{formatOrderPerformer(x, performerOrder, true)}</>
                      </FormComponent.Line>
                    ))}
                  </FormComponent.Column>
                </FormComponent.ColumnWrapper>
                <SectionTitle
                  title={
                    'Приказ указан для даты окончания работы в проекте у следующих членов коллектива (' +
                    endDatePerformers.length +
                    ')'
                  }
                />
                <FormComponent.ColumnWrapper>
                  <FormComponent.Column specialStyles={{ width: '50%' }}>
                    {endDatePerformers.slice(0, Math.floor(endDatePerformers.length / 2)).map(x => (
                      <FormComponent.Line>
                        <>{formatOrderPerformer(x, performerOrder, false)}</>
                      </FormComponent.Line>
                    ))}
                  </FormComponent.Column>
                  <FormComponent.Column specialStyles={{ width: '50%' }}>
                    {endDatePerformers.slice(Math.floor(endDatePerformers.length / 2), endDatePerformers.length).map(x => (
                      <FormComponent.Line>
                        <>{formatOrderPerformer(x, performerOrder, false)}</>
                      </FormComponent.Line>
                    ))}
                  </FormComponent.Column>
                </FormComponent.ColumnWrapper>
              </>
            ) : (
              <></>
            )}
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectPerformerOrderForm);
