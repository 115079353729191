import React from 'react';

import { FormComponent, RefList, SectionTitle, TextDateTime, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { OtherProject } from 'types/models';
import { ValueOf } from 'types/helpers';
import { SelectPerson } from 'features/SelectPerson';
import { SelectProgram } from './views/SelectProgram';
import { SelectProgramEvent } from './views/SelectProgramEvent';
import { SelectRole } from './views/SelectRole';
import { FinancingListEdit } from './views/FinancingListEdit';
import { AffiliationSelect } from './views/AffiliationSelect';
import useController from './controller';

type Props = {
  isDisabled: boolean;
  otherProject: OtherProject.OtherProject;
  otherProjectErrors: Partial<Record<keyof OtherProject.OtherProject, boolean>>;
  makeChangeHandler: (key: keyof OtherProject.OtherProject) => (value: ValueOf<OtherProject.OtherProject>) => void;
  makeResetErrorHandler: (key: keyof OtherProject.OtherProject) => () => void;
};

export const AboutProject = ({
  makeChangeHandler,
  makeResetErrorHandler,
  otherProject,
  otherProjectErrors,
  isDisabled,
}: Props) => {
  const { onUpdatePerformer, onProgramSelect, onUpdateRangeDate } = useController({
    makeChangeHandler,
    otherProject,
    makeResetErrorHandler,
  });
  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title="О проекте" />
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field isRequired label="Участник проекта">
            <SelectPerson
              member={otherProject.performer}
              onUpdateMember={onUpdatePerformer}
              isSelectDisabled
              hiddenButtons={['change', 'delete']}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <SelectRole
            role={otherProject.role}
            setRole={makeChangeHandler('role')}
            isDisabled={isDisabled}
            onFocus={makeResetErrorHandler('role')}
            isError={otherProjectErrors.role}
          />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field isRequired label="Тема проекта">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Тема проекта' }}
              value={otherProject.name ?? ''}
              onChange={makeChangeHandler('name')}
              onFocus={makeResetErrorHandler('name')}
              isDisabled={isDisabled}
              isError={otherProjectErrors.name}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field isRequired label="Номер проекта">
            <TextInput
              value={otherProject.number}
              onFocus={makeResetErrorHandler('number')}
              onChange={makeChangeHandler('number')}
              isError={otherProjectErrors.number}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Номер госрегистрации">
            <TextInput
              value={otherProject.stateRegistrationNumber}
              onChange={makeChangeHandler('stateRegistrationNumber')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Дата госрегистрации">
            <TextDateTime
              value={otherProject.stateRegistrationDate}
              onChange={makeChangeHandler('stateRegistrationDate')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field isRequired label="Руководитель проекта">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Руководитель проекта' }}
              value={otherProject.leader}
              onChange={makeChangeHandler('leader')}
              onFocus={makeResetErrorHandler('leader')}
              isDisabled={isDisabled}
              isError={otherProjectErrors.leader}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата начала/окончания" isRequired>
            <TextDateTime
              isRange
              value={`${otherProject.startDate}-${otherProject.endDate}`}
              onChange={(value: string) => {
                const dates = value.split('-');
                onUpdateRangeDate(dates[0] || '', dates[1] || '');
              }}
              isError={otherProjectErrors.startDate || otherProjectErrors.endDate}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <AffiliationSelect
            affiliation={otherProject.enterprise}
            setAffiliation={makeChangeHandler('enterprise')}
            isDisabled={isDisabled}
            modalTitle="Организации"
            label="Организация - исполнитель проекта"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <AffiliationSelect
            affiliation={otherProject.customer}
            setAffiliation={makeChangeHandler('customer')}
            isDisabled={isDisabled}
            modalTitle="Организации"
            label="Заказчик"
            tooltip="Организация, которая профинансировала проект"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <SelectProgram program={otherProject.program} setProgram={onProgramSelect} isDisabled={isDisabled} />
        </FormComponent.Line>
        <FormComponent.Line>
          <SelectProgramEvent
            programEvent={otherProject.programEvent}
            setProgramEvent={makeChangeHandler('programEvent')}
            isDisabled={isDisabled}
            programId={otherProject.program?.value}
          />
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Общий объём финансирования">
            <TextInput
              mode={TextInputMode.number}
              value={otherProject.amount ?? ''}
              onChange={makeChangeHandler('amount')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Примечание">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Примечание' }}
              value={otherProject.note ?? ''}
              onChange={makeChangeHandler('note')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Line>
          <RefList
            disabled={isDisabled}
            modalTitle="ГРНТИ"
            title="ГРНТИ"
            rows={otherProject.grntis}
            onChange={makeChangeHandler('grntis')}
            refName="RefGrnti"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <FinancingListEdit
            isDisabled={isDisabled}
            financings={otherProject.financings}
            setFinancings={makeChangeHandler('financings')}
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
};
