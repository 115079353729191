import { Tender } from 'types/models';
import * as R from 'ramda';
import { useCallback } from 'react';
import { ReferenceItem } from 'components';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  customer: Tender.Customer | null;
  onCustomerChange(row: Tender.Customer | null): void;
};

export function useController({ customer, onCustomerChange }: Props) {
  const handleChange = useCallback(
    (fieldName: keyof Tender.Customer) => (value: string) => {
      onCustomerChange(R.set(R.lensProp(fieldName), value, customer!));
    },
    [customer, onCustomerChange],
  );

  const { methods: getReferenceElementAPI } = BackendAPI.useBackendAPI('GetReferenceElement');

  const getRefElement = useCallback(
    (id: string) =>
      new Promise<ReferenceItem | null>(resolve => {
        getReferenceElementAPI.callAPI(
          {
            refName: 'RefEnterpriseCustomer',
            elementSettings: { key: 'id', value: id },
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getReferenceElementAPI],
  );

  const handleCustomerIdChange = useCallback(
    () => async (value: ReferenceItem | null) => {
      const id = value?.customFields?.id ? value.customFields.id.toString() : value?.id ?? '';
      if (id) {
        const item = await getRefElement(id);
        // @ts-ignore country.label is not interfering from ReferenceItem
        const address = [item.customFields?.street, item.customFields?.country?.label].filter(x => x).join(', ');
        const fullName = item?.customFields?.fullName ? item.customFields.fullName.toString() : '';
        const contacts = item?.customFields?.contacts ? item.customFields.contacts.toString() : '';
        onCustomerChange({
          ...customer,
          ...{
            customerId: item?.id ? item.id : '',
            address,
            id: customer?.id || '',
            fullName,
            contacts,
          },
        });
      } else {
        onCustomerChange(null);
      }
    },
    [customer, onCustomerChange, getRefElement],
  );

  return {
    handleChange,
    handleCustomerIdChange,
  };
}
