import { makeStream } from 'StreamRx';

export const streams = {
  toggleChangeForm: makeStream(),
  toggleSettingEditing: makeStream<boolean>(),
  deleteScientistHirshIndex: makeStream<{ hirshIndexID: string }>(),
  addNewScientistHirshIndex: makeStream<AddNewScientistHirshIndexData>(),
};

type AddNewScientistHirshIndexData = {
  hirshIndexID?: string;
  scientistID?: string;
  date: string;
  citationSystemID: string;
  value?: string;
  identifier?: string;
  note?: string;
};
