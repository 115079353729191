import React from 'react';

import { FormComponent, Reference, TextDateTime, TextInput, TextInputMode, UploadFile } from 'components';

import { SecurityDocumentPayment } from 'types/models';
import { useController } from './controller';

type Props = {
  payment: SecurityDocumentPayment | null;
  onPaymentChange(row: SecurityDocumentPayment): void;
  isRequestPayments?: boolean;
};

function PaymentInputs({ payment, onPaymentChange, isRequestPayments }: Props) {
  const { handleFieldChange, handleFileChange } = useController({
    payment,
    onPaymentChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <FormComponent.Field label="Назначение платежа" isRequired>
              <Reference
                relationTableModalTitle='Справочник "Назначения платежа"'
                name={isRequestPayments ? 'RefPaymentPurposeRequest' : 'RefPaymentPurpose'}
                value={isRequestPayments ? payment?.paymentPurposeRequest ?? null : payment?.paymentPurpose ?? null}
                onChange={value => handleFieldChange(isRequestPayments ? 'paymentPurposeRequest' : 'paymentPurpose')(value)}
                theme="big"
              />
            </FormComponent.Field>
          </FormComponent.Column>
          <FormComponent.Column>
            {!isRequestPayments && (
              <FormComponent.Field label="Оплатить до">
                <TextDateTime value={payment?.payBeforeDate} onChange={handleFieldChange('payBeforeDate')} />
              </FormComponent.Field>
            )}
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Line>

      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Сумма" isRequired>
              <TextInput
                mode={TextInputMode.number}
                value={payment?.amount ? payment?.amount.toString() : '0'}
                onChange={handleFieldChange('amount')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Оплачено">
              <TextDateTime value={payment?.payDate} onChange={handleFieldChange('payDate')} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <FormComponent.Line>
        <FormComponent.Field label="Файл документа">
          <UploadFile file={payment?.file} onChange={handleFileChange} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { PaymentInputs };
