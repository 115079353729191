import React, { memo, useCallback } from 'react';

import {
  CustomSelect,
  Reference,
  Button,
  ButtonMode,
  SectionTitle,
  FormComponent,
  TextArea,
  TextInput,
  TextInputMode,
} from 'components';

import { Table as T, Form as F } from 'types/models';
import { GetCompilationListByTypeSpecification } from 'features/Table/specifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  publicationId: string;
  formFields: F.Fields;
  onCreateBibliographicRecordClick: () => void;
  workMode: T.WorkMode;
  changeSourceCompilation: (original: F.Compilation | null) => void;
  onDeleteSourceCompilation: () => void;
  sourceCompilation: F.Compilation | null;
  compilationType: string;
  isElectronic: boolean;
  sourceEvents: string[];
  publicationType: string;
  sourceParticipationReport: string[];
  isDisableSourceCompilation: boolean;
  filterCompilationId: string;
  sourcePublicationDate: string;
  eventId?: string;
};

const Source = ({
  publicationId,
  formFields,
  eventId,
  onCreateBibliographicRecordClick,
  changeSourceCompilation,
  onDeleteSourceCompilation,
  sourceCompilation,
  compilationType,
  isElectronic,
  sourceEvents,
  publicationType,
  sourceParticipationReport,
  isDisableSourceCompilation,
  filterCompilationId,
  sourcePublicationDate,
  workMode,
}: Props) => {
  const modalTableRowToSourceCompilationConventer = useCallback<(row: T.Entry) => F.Compilation>(
    row => ({
      id: row.id,
      type: row['id:type'],
      name: row.name,
      bibliographic: row.bibliographicRecord,
      status: row.status,
      typeEdition: row['id:typeEdition'],
    }),
    [],
  );

  const sourceCompilationTableConfig = GetCompilationListByTypeSpecification({
    requestData: {
      type: compilationType,
      isElectronic,
      filterCompilationId,
      articleIds: publicationId,
    },
    hasSelectButton: true,
    eventId,
  });

  return (
    <form>
      <input type="submit" hidden />
      <SectionTitle title="Издание" />
      <FormComponent.Line>
        <FormComponent.Field>
          <CustomSelect
            disabled={workMode === 'viewMode' || isDisableSourceCompilation}
            isRequired
            isStatusText
            title="Сборник"
            original={sourceCompilation}
            relationTableModalTitle="Сборники"
            onDeleteFunction={onDeleteSourceCompilation}
            onChange={changeSourceCompilation}
            modalTableRowConverter={modalTableRowToSourceCompilationConventer}
            modalTableSpecification={sourceCompilationTableConfig}
            IsShowBibliographic
          />
          {publicationType === PublicationTypes.ARTICLE_CONFERENCE.code && (
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="">
                <FormComponent.Description
                  mode="warning"
                  tooltip="Конференцию можно добавить на форме редактирования Выпуска журнала"
                  classMixin="is-hint"
                >
                  <div>
                    <strong>Опубликованы материалы конференции:</strong> {sourceEvents.join('; ') || 'Нет'}
                  </div>
                  <div>
                    <strong>Доклады:</strong> {sourceParticipationReport.join('; ') || 'Нет'}
                  </div>
                </FormComponent.Description>
              </FormComponent.Field>
            </FormComponent.Line>
          )}
        </FormComponent.Field>
      </FormComponent.Line>
      {!!sourcePublicationDate && (
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Дата публикации">
            <span>{sourcePublicationDate}</span>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <Reference
          // disabled
          value={formFields.textLanguage.value}
          isRequired
          theme="big"
          title="Язык текста"
          relationTableModalTitle="Язык"
          refButtonTitle="Язык"
          name="RefLanguage"
          onChange={formFields.textLanguage.onChange}
          isDisabledDelete
          disabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>
      <FormComponent.Line hasGap hasFreeFormat>
        <FormComponent.Field label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.number}
            value={formFields.printPageCount.value}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageFrom.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={formFields.pageFrom.value}
            onChange={formFields.pageFrom.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageTo.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={formFields.pageTo.value}
            onChange={formFields.pageTo.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.articleNumber.title} labelSize="fit">
          <TextInput
            value={formFields.articleNumber.value}
            onChange={formFields.articleNumber.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />
      <FormComponent.Line hasGap hasFreeFormat growIndexes={[2, 0]}>
        <FormComponent.Field>
          <TextArea
            settings={{ rows: 3 }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
        <Button
          mode={ButtonMode.secondary}
          text="Построить"
          onClick={onCreateBibliographicRecordClick}
          isDisabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>

      <SectionTitle title="Классификаторы" />
      <FormComponent.Line>
        <Reference
          disabled={workMode === 'viewMode'}
          value={formFields.grnti.value}
          title={formFields.grnti.title}
          relationTableModalTitle="ГРНТИ"
          tooltipText={formFields.grnti.tooltipText}
          refButtonTitle="ГРНТИ"
          theme="big"
          name="RefGrnti"
          onChange={formFields.grnti.onChange}
        />
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
          <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <Reference
          tooltipText={formFields.study.tooltipText}
          disabled={workMode === 'viewMode'}
          value={formFields.study.value}
          title={formFields.study.title}
          relationTableModalTitle={formFields.study.title}
          theme="big"
          refButtonTitle="Область науки"
          name="RefStudy"
          onChange={formFields.study.onChange}
        />
      </FormComponent.Line>
    </form>
  );
};

export const SourceComponent = memo(Source);
