import React, { useCallback } from 'react';

import { Project, Table } from 'types/models';
import { ValueOf } from 'types/helpers';

import { FormComponent, InputSelect, Option, TextDateTime, TextInput } from 'components';

import { getOptionsFromEnum } from 'features/Form/looks/project/ProjectForm/helpers';
import { SelectPartner } from '../SelectPartner/SelectPartner';

type Props = {
  partner: Project.Partner;
  makeChangeHandler: (key: keyof Project.Partner) => (value: ValueOf<Project.Partner>) => void;
  setPartner(partner: Project.Partner): void;
  enumMap: Table.EnumMap;
  mode: 'view' | 'add' | 'edit' | null;
};

export function BaseFields({ partner, makeChangeHandler, enumMap, mode, setPartner }: Props) {
  const roleOptions = getOptionsFromEnum(enumMap.PartnerRole).filter(x => x.value === 'PARTNER' || x.value === 'HEAD_PERFORMER');
  const contractTypeOptions = getOptionsFromEnum(enumMap.ContractType);

  const handleChangeRole = useCallback(
    (option: Option) => {
      if (option.value === 'HEAD_PERFORMER') {
        setPartner({
          ...partner,
          role: option,
          contractName: '',
          leaderAppointment: '',
          leaderContacts: '',
          leaderDegree: '',
          leaderFullName: '',
          leaderJobEnterprise: null,
          leaderRank: '',
        });
        return;
      }
      if (option.value === 'PARTNER') {
        setPartner({
          ...partner,
          role: option,
          financings: [],
          kindWork: '',
        });
        return;
      }
      setPartner({ ...partner, role: option });
    },
    [partner, setPartner],
  );

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Партнёр" isRequired>
          <SelectPartner partner={partner.partner} setPartner={makeChangeHandler('partner')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Роль" isRequired>
          <InputSelect options={roleOptions} value={partner.role} onSelectChange={handleChangeRole} disabled={mode === 'edit'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Вид договора" isRequired>
          <InputSelect
            options={contractTypeOptions}
            value={partner.contractType}
            onSelectChange={makeChangeHandler('contractType')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Номер договора" isRequired>
          <TextInput value={partner.contractNumber} onChange={makeChangeHandler('contractNumber')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap lineSize="padded">
        <FormComponent.Field isRequired label="Сроки действия">
          <TextDateTime
            isRange
            value={`${partner?.startDate || ''}-${partner?.endDate || ''}`}
            onChange={(value: string) => {
              const dates = value.split('-');
              setPartner({ ...partner, startDate: dates[0] || '', endDate: dates[1] || '' } as Project.Partner);
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Примечание">
          <TextInput value={partner.note} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
