import React from 'react';

import { Reference, FormComponent, TextArea, TextDateTime, UploadFile } from 'components';

import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function RecognitionForm({ viewMode, editMode, onClose }: Props) {
  const { formFields, author, updateAuthor, onSubmit, memberEditLocked } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="ФИО">
            <SelectPerson member={author} onUpdateMember={updateAuthor} disabled={!!viewMode || memberEditLocked} />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line hasGap lineSize="padded">
          <FormComponent.Field>
            <Reference
              disabled={!!viewMode}
              value={formFields.award.value}
              title={formFields.award.title}
              relationTableModalTitle='Справочник "Награды"'
              theme="big"
              isRequired={formFields.award.required}
              refButtonTitle='Справочник "Награды"'
              name="RefRecognitionAward"
              onChange={formFields.award.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Категория награды">
            <span>{formFields.awardCategory.value}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.dateGet.title} isRequired={formFields.dateGet.required}>
            <TextDateTime value={formFields.dateGet.value} onChange={formFields.dateGet.onChange} isDisabled={!!viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.file.title}>
            <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={!!viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="triplePadded">
          <FormComponent.Field label={formFields.description.title} tooltip={formFields.description.tooltipText}>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.description.value}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line lineSize="triplePadded">
          <FormComponent.Field label={formFields.note.title}>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.note.value}
              onChange={formFields.note.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(RecognitionForm);
