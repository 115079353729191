import { GetRequestListInputRequestData } from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = GetRequestListInputRequestData & {
  isClearToolbar?: boolean;
  enumFilterValues?: Table.EnumFilterValues;
  hasSelectButton?: boolean;
};

export function GetRequestList({
  isOnlyNotLinked,
  isClearToolbar,
  status,
  enumFilterValues,
  hasSelectButton = true,
}: Props = {}): Table.Specification {
  return {
    apiID: 'GetRequestList',
    requestData: {
      isOnlyNotLinked,
      status,
    },
    header: {
      firstLevel: {
        title: 'Заявки на конкурсы программ',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: isClearToolbar ? undefined : LeftPanelForThirdLevel,
        hasSelectButton: hasSelectButton,
      },
    },
    enumFilterValues,
  };
}
