import React, { useState } from 'react';

import { ListEditTable, SectionTitle } from 'components';

import { Project } from 'types/models';

import { formatNumber } from 'utils/Helpers/formatters';

type Props = {
  projectCodes: Project.ProjectCode[];
};

export function DistributionFunds({ projectCodes }: Props) {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  return (
    <>
      <SectionTitle title="Распределение средств проекта" />
      <ListEditTable
        rows={projectCodes}
        defaultRowsCount={10}
        columns={[
          { label: 'Шифр', formatValue: x => x.code ?? '' },
          { label: 'Факультет/Институт', formatValue: x => x.department?.governance?.label ?? '' },
          { label: 'Подразделение', formatValue: x => x.department?.unit?.label ?? '' },
          { label: 'Источник финансирования', formatValue: x => x.financing?.source?.label ?? '' },
          { label: 'Год', formatValue: x => x.year ?? '' },
          {
            label: 'Сумма план',
            formatValue: x => formatNumber(x.incomePlanAmount ?? '0', 2),
            styles: { textAlign: 'right' },
          },
          {
            label: 'Поступления факт',
            formatValue: x => formatNumber(x.incomeFactAmount ?? '0', 2),
            styles: { textAlign: 'right' },
          },
          {
            label: 'Расходы, план',
            formatValue: x => formatNumber(x.expensePlanAmount ?? '0', 2),
            styles: { textAlign: 'right' },
          },
          {
            label: 'Расходы, факт',
            formatValue: x => formatNumber(x.expenseFactAmount ?? '0', 2),
            styles: { textAlign: 'right' },
          },
          {
            label: 'В т.ч. ЦФ',
            formatValue: x => formatNumber(x.accompliceAmount ?? '0', 2),
            styles: { textAlign: 'right' },
          },
          { label: 'ЦФ %', formatValue: x => formatNumber(x.overheadPercent ?? '0', 2), styles: { textAlign: 'right' } },
        ]}
        selectedRowIndex={selectedRowIndex}
        selectRow={setSelectedRowIndex}
        columnIndexesForSumTotal={[5, 6, 7, 8, 9, 10]}
      />
    </>
  );
}
