import { useEffect, useMemo } from 'react';
import * as R from 'ramda';

import { Mode } from 'components/ListEdit/model';

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { BASE_NDS } from 'utils/Constants/BaseNds';
import { formatNumber } from 'utils/Helpers';
import { ProjectFinancingType } from 'utils/Enums';

type Arguments = {
  stage: Project.Stage;
  setStage(stage: Project.Stage): void;
  mode: Mode;
  financings: Project.Financing[];
};

export type InfoDescription = {
  sumKey: keyof Project.Stage;
  percentKey: keyof Project.Stage;
  financingType: ProjectFinancingType;
  label: string;
};

export function useController({ setStage, stage, mode, financings }: Arguments) {
  const makeChangeHandler = (key: keyof Project.Stage) => (value: ValueOf<Project.Stage>) => {
    setStage({ ...stage, [key]: value });
  };

  const { settings } = useAppDataContext();
  const sumFields = useMemo<InfoDescription[]>(
    () => [
      { sumKey: 'amountMain', percentKey: 'mainNdsPercent', label: 'Сумма ОИФ, руб.', financingType: ProjectFinancingType.MAIN },
      {
        sumKey: 'amountCofinancing1',
        percentKey: 'cofinancing1NdsPercent',
        label: 'Сумма СФ1, руб.',
        financingType: ProjectFinancingType.COFINANCING_1,
      },
      {
        sumKey: 'amountCofinancing2',
        percentKey: 'cofinancing2NdsPercent',
        label: 'Сумма СФ2, руб.',
        financingType: ProjectFinancingType.COFINANCING_2,
      },
      {
        sumKey: 'amountLocal',
        percentKey: 'localNdsPercent',
        label: `Сумма (${settings?.organization?.shortName}), руб.`,
        financingType: ProjectFinancingType.LOCAL,
      },
      {
        sumKey: 'amountAccomplice',
        percentKey: 'accompliceNdsPercent',
        label: 'Партнёр, руб.',
        financingType: ProjectFinancingType.ACCOMPLICE,
      },
    ],
    [settings],
  );

  useEffect(() => {
    const stageCopy: Project.Stage = R.clone(stage);

    const isAddMode = mode === 'add';
    if (isAddMode) {
      sumFields.forEach(({ financingType, percentKey }) => {
        const financing = financings.find(x => x.type?.value === financingType);
        const isFinancingWithNds = Boolean(financing?.isNds);
        const isNeedFillNdsPercentage = isAddMode && isFinancingWithNds;

        if (isNeedFillNdsPercentage) {
          const nextPercentValue: ValueOf<Project.Stage> = formatNumber(BASE_NDS);
          (stageCopy[percentKey] as any) = nextPercentValue;
        }
      });

      setStage(stageCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    makeChangeHandler,
    sumFields,
  };
}
