import { useState, useCallback, useMemo } from 'react';
import { RequestStatus } from 'utils/Enums';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { IconButtonProps, buttonIcons } from 'components';
import { Permits } from 'utils/Permissions';

type StatusArgs = {
  rows: { id: string; status: RequestStatus | null }[];
  callback?(): void;
};

export function getRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<RequestStatus>(RequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddRequestApprovement');

  const handleButtonClick = useCallback((newStatus: RequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push();
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, requestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
          },
        },
      );
    },
    [addApprovementAPI, rows],
  );

  const isAllRowsSent = useMemo(() => rows.length && rows.every(x => !!x.status && [RequestStatus.SENT].includes(x.status)), [
    rows,
  ]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.book,
        title: 'В черновик',
        isDisabled: !rows.length || rows.some(x => !!x.status && [RequestStatus.DRAFT].includes(x.status)),
        onClick: () => handleButtonClick(RequestStatus.DRAFT, 'В черновик'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_DRAFT,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_DRAFT,
      },
      {
        icons: buttonIcons.medal,
        title: 'На согласование',
        isDisabled:
          !rows.length || !rows.every(x => !!x.status && [RequestStatus.DRAFT, RequestStatus.REVISION].includes(x.status)),
        onClick: () => handleButtonClick(RequestStatus.REQUEST, 'На согласование'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REQUEST,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REQUEST,
      },
      {
        icons: buttonIcons.like,
        title: 'Рекомендовать на конкурс',
        isDisabled: !rows.length || !rows.every(x => x.status && [RequestStatus.REQUEST].includes(x.status)),
        onClick: () => handleButtonClick(RequestStatus.RECOMENDED, 'Рекомендовать на конкурс'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_RECOMENDED,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_RECOMENDED,
      },
      {
        icons: buttonIcons.activation,
        title: 'Подать на конкурс',
        isDisabled: !rows.length || !rows.every(x => !!x.status && [RequestStatus.RECOMENDED].includes(x.status)),
        onClick: () => handleButtonClick(RequestStatus.SENT, 'Подать на конкурс'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_SENT,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_SENT,
      },
      {
        icons: buttonIcons.star,
        title: 'Поддержать в конкурсе',
        isDisabled: !rows.length || !rows.every(x => !!x.status && [RequestStatus.SENT].includes(x.status)),
        onClick: () => handleButtonClick(RequestStatus.APPROVED, 'Поддержать'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_APPROVED,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_APPROVED,
      },
      {
        icons: buttonIcons.update,
        title: 'На доработку',
        isDisabled:
          !rows.length || !rows.every(x => !!x.status && [RequestStatus.REQUEST, RequestStatus.REJECTED].includes(x.status)),
        onClick: () => handleButtonClick(RequestStatus.REVISION, 'На доработку'),
        permissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REVISION,
        profilePermissionName: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REVISION,
      },
      {
        icons: buttonIcons.dislike,
        title: isAllRowsSent ? 'Отклонить в конкурсе' : 'Отклонить',
        isDisabled: !rows.length || !(rows.every(x => !!x.status && [RequestStatus.REQUEST].includes(x.status)) || isAllRowsSent),
        onClick: () =>
          isAllRowsSent
            ? handleButtonClick(RequestStatus.TENDER_CANCELED, 'Отклонить в конкурсе')
            : handleButtonClick(RequestStatus.REJECTED, 'Отклонить'),
        permissionName: isAllRowsSent
          ? Permits.PROGRAM_REQUEST_CHANGE_STATUS_TENDER_CANCELED
          : Permits.PROGRAM_REQUEST_CHANGE_STATUS_REJECTED,
        profilePermissionName: isAllRowsSent
          ? Permits.PROGRAM_REQUEST_CHANGE_STATUS_TENDER_CANCELED
          : Permits.PROGRAM_REQUEST_CHANGE_STATUS_REJECTED,
      },
      {
        icons: buttonIcons.approve,
        title: 'Экспертная оценка',
        isDisabled: rows.length !== 1 || (!!rows[0]?.status && rows[0].status === RequestStatus.DRAFT),
        onClick: () => setIsApprovementModalOpen(true),
        permissionName: Permits.PROGRAM_REQUEST_AGREE,
        profilePermissionName: Permits.PROGRAM_REQUEST_AGREE,
      },
    ],
    [handleButtonClick, isAllRowsSent, rows],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}
