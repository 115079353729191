import React, { ReactElement } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-template');

type Props = {
  children: ReactElement | false | (ReactElement | false)[];
};

export function Template(props: Props) {
  const { children } = props;

  return <div className={b()}>{children}</div>;
}
