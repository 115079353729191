import React from 'react';

import { block } from 'bem-cn';

import { DataGrid } from 'features/Table';
import { GetQnaQuestionList } from 'features/Table/specifications';

const b = block('form-tab');

export function GetQuestion() {
  return (
    <>
      <div className={b('subtitle')}>
        Если Вы не нашли ответ в рубрике &quot;Часто задаваемые вопросы&quot;,Вы можете задать свой вопрос администратору системы
      </div>
      <DataGrid specification={GetQnaQuestionList()} />
    </>
  );
}
