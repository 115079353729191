import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './model';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { referenceMetadata } = customState;

  if (!referenceMetadata?.restrictable) {
    return null;
  }

  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'restricted',
      list: [
        { label: 'Все', value: 'All' },
        { label: 'Актуальные', value: 'Actual' },
      ],
      value: 'Actual',
    },
  ]);
});
