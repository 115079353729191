import React from 'react';

import {
  Checkbox,
  Reference,
  SectionTitle,
  FormComponent,
  ConfirmPopup,
  TextArea,
  TextAreaMode,
  TextDateTime,
  InputSelect,
  TextInput,
} from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

import { EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';

import { SelectProgram } from '../SelectProgram/SelectProgram';
import { SelectProgramEvent } from '../SelectProgramEvent/SelectProgramEvent';

import { useController } from './controller';
import { getEnumItemLabel } from 'utils/Helpers';

type Props = {
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
  setProject(job: Project.Project): void;
  indicators: EditableIndicator[];
  indices: EditableIndicator[];
  disabled: boolean;
};

export function TaskNirFields(props: Props) {
  const { project, makeChangeHandler, setProject, disabled, indicators, indices } = props;

  const {
    enumMap,
    stopMoneyReasonOptions,
    leader,
    responsiblePerformer,
    isChangeProgramWarningOpen,
    changeProgramWarningText,
    changeProgramWarningTitle,
    handleChangeProgram,
    getContacts,
    onCloseChangeProgramWarning,
    onFinancingStoppedChange,
  } = useController({
    project,
    setProject,
    indicators,
    indices,
    makeChangeHandler,
  });

  return (
    <>
      <ConfirmPopup
        isOpen={isChangeProgramWarningOpen}
        text={changeProgramWarningText}
        title={changeProgramWarningTitle}
        onClose={onCloseChangeProgramWarning}
        onConfirm={onCloseChangeProgramWarning}
        icon="info"
        okButtonText="Да"
      />
      <SectionTitle title={`Проект типа: ${getEnumItemLabel('ProjectType', project?.type?.value || 'NIR', enumMap)}`} />
      <FormComponent.Line>
        <FormComponent.Field label="Программа">
          <SelectProgram program={project?.program ?? null} onChange={handleChangeProgram} disabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Мероприятие/Вид конкурса">
          <SelectProgramEvent
            disabled={disabled || !project?.program}
            programId={project?.program?.id ?? null}
            programEvent={project?.programEvent ?? null}
            onChange={makeChangeHandler('programEvent')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field isRequired label="Тема проекта">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Тема проекта' }}
            value={project?.name ?? ''}
            onChange={makeChangeHandler('name')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Номер проекта">
          <TextInput value={project?.number ?? ''} onChange={makeChangeHandler('number')} isDisabled={disabled} />
        </FormComponent.Field>
        <FormComponent.Field label="Код">
          <Reference
            disabled={disabled}
            value={project?.numberPrefix ?? null}
            relationTableModalTitle='Справочник "Префикс для шифра заявок и номера проектов"'
            name="RefProjectRequestNumberPrefix"
            onChange={makeChangeHandler('numberPrefix')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Спецтема">
          <Checkbox checked={!!project?.isSpecTheme} onChange={makeChangeHandler('isSpecTheme')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Сроки проекта">
          <TextDateTime
            isRange
            value={`${project?.startDate || ''}-${project?.endDate || ''}`}
            onChange={(value: string) => {
              const dates = value.split('-');
              setProject({ ...project, startDate: dates[0] || '', endDate: dates[1] || '' } as Project.Project);
            }}
            rangeButtons={['toInfinite', 'fromInfinite']}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Прекращено фин.">
          <Checkbox checked={!!project?.isFinancingStopped} onChange={onFinancingStoppedChange} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>

      {!!project?.isFinancingStopped && (
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Прекращено фин. от" isRequired>
            <TextDateTime value={project?.stopMoneyDate} onChange={makeChangeHandler('stopMoneyDate')} isDisabled={disabled} />
          </FormComponent.Field>
          <FormComponent.Field isRequired label="Причина">
            <InputSelect
              disabled={disabled}
              options={stopMoneyReasonOptions}
              value={stopMoneyReasonOptions.find(x => x.value === project?.stopMoneyReason?.value) ?? null}
              onSelectChange={makeChangeHandler('stopMoneyReason')}
              isClearable
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Примечание' }}
            value={project?.periodNote ?? ''}
            onChange={makeChangeHandler('periodNote')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Руководитель" isRequired={true}>
          {leader?.person?.fullName ? (
            <div>{`${`${leader.person.fullName}/`} ${getContacts(leader)}`}</div>
          ) : (
            <div style={{ color: 'gray' }}>Заполняется на вкладке Коллектив</div>
          )}
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Отв. исполнитель">
          {responsiblePerformer?.person?.fullName ? (
            <div>{`${`${responsiblePerformer.person.fullName}/`}${getContacts(responsiblePerformer)}`}</div>
          ) : (
            <div style={{ color: 'gray' }}>Заполняется на вкладке Коллектив</div>
          )}
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
