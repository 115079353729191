import * as R from 'ramda';

import { Grnti, Community, Vak } from 'types/models';

import { Settings } from 'types/models/common';
import { convertServerAffiliation, convertServerPerson } from '../author/converters';
import {
  convertMaybeArray,
  convertReference,
  getId,
  getText,
  getBoolean,
  convertItem,
  convertDocument,
} from '../commonConverters';
import { convertPersonHistory } from 'services/BackendAPI/configurations/converters';
import { Magazine } from 'types/models/Article';

export function convertCommunity(community: any, settings: Settings): Community.Community {
  const grntis = community.Grntis?.Grnti ?? [];
  const vaks = community.Vaks?.Vak ?? [];
  const members = community.Members?.Member || [];
  const documents = community.Documents?.Document || [];

  return {
    id: getId(community),
    type: convertItem(community.Type),
    code: getText(community.Code),
    foundationDate: getText(community.FoundationDate),
    isLocal: getBoolean(community.IsLocal),
    grntis: convertMaybeArray(grntis, convertGrnti),
    vaks: convertMaybeArray(vaks, convertVak),
    affiliation: community.Affiliation ? convertServerAffiliation(community, settings) : null,
    members: convertMaybeArray(members, m => convertMember(m, settings)).sort(
      (a: Community.Member, b: Community.Member) => (a.role?.position || 0) - (b.role?.position || 0),
    ),
    documents: convertMaybeArray(documents, R.partial(convertDocument, ['local'])),
    isApproved: community.Status === 'APPROVED',
    orderNumber: getText(community.OrderNumber),
    orderDate: getText(community.OrderDate),
    note: getText(community.Note),
    ...(community.Magazine ? { magazine: convertCommunityMagazine(community.Magazine) } : {}),
  };
}

function convertCommunityMagazine(mag: any): Magazine {
  return {
    id: getId(mag),
    name: getText(mag.Name),
    type: { label: '', value: '' },
  };
}
function convertGrnti(grnti: any): Grnti {
  return {
    id: getId(grnti),
    element: convertReference(grnti.Element),
  };
}

function convertVak(vak: any): Vak {
  return {
    id: getId(vak),
    element: convertReference(vak.Element),
  };
}

export function convertMember(member: any, settings: Settings): Community.Member {
  const documents = member.Documents?.Document || [];

  return {
    id: getId(member),
    joinDate: getText(member.JoinDate),
    leaveDate: getText(member.LeaveDate),
    role: convertItem(member.Role),
    scientist: convertServerPerson(member.Scientist, settings),
    vakSpeciality: convertReference(member.VakSpeciality),
    cooperationType: convertItem(member.CooperationType),
    description: getText(member.Description),
    ...convertPersonHistory(member),
    documents: convertMaybeArray(documents, R.partial(convertDocument, ['local'])),
  };
}
