import { useCallback } from 'react';

import { Option } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

import { getStagesOptions } from 'features/Form/looks/project/ProjectForm/helpers';

type Arguments = {
  stages: Project.Stage[];
  performerStage: Project.PerformerStage;
  setPerformerStage(performerStage: Project.PerformerStage): void;
};

export function useController(args: Arguments) {
  const { performerStage, setPerformerStage, stages } = args;

  const stagesOptions = getStagesOptions(stages);

  const handleChangeStage = useCallback(
    (option: Option | null) => {
      const stage = stages.find(x => x.number === option?.value ?? '');
      if (stage) {
        setPerformerStage({ ...performerStage, stage });
      }
    },
    [setPerformerStage, performerStage, stages],
  );

  const makeChangeHandler = (key: keyof Project.PerformerStage) => (value: ValueOf<Project.PerformerStage>) => {
    setPerformerStage({ ...performerStage, [key]: value });
  };

  return {
    stagesOptions,
    handleChangeStage,
    makeChangeHandler,
  };
}
