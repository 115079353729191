import { useCallback } from 'react';
import * as R from 'ramda';
import { Department } from 'types/models/ProgramRequest';

type Props = {
  department?: Department | null;
  onDepartmentChange(row: Department): void;
};

export function useController({ department, onDepartmentChange }: Props) {
  const handleFieldChange = useCallback(
    (fieldName: keyof Department) => (value: any) => {
      onDepartmentChange(R.set(R.lensProp(fieldName), value, department!));
    },
    [department, onDepartmentChange],
  );

  return {
    handleFieldChange,
  };
}
