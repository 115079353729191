import React from 'react';

import { Modal, Reference, TextArea, FormComponent } from 'components';

import { PaymentFeedline } from 'types/models/Payment';
import { ValueOf } from 'types/helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  isOpen: boolean;
  paymentFeedline: PaymentFeedline | null;
  changeFeedline(key: keyof PaymentFeedline, value: ValueOf<PaymentFeedline>): void;
  onClose(): void;
  onSave(): void;
};

export function MessageModal(props: Props) {
  const { isOpen, onClose, paymentFeedline, onSave, changeFeedline } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Сохранение заявки" size="large">
      <FormComponent.Template>
        <ControlPanel handleSave={onSave} />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Тема">
              <Reference
                name="RefPaymentRequestMessageCategory"
                onChange={val => changeFeedline('messageCategory', val)}
                relationTableModalTitle='Справочник "Категории сообщений по стимулирующим выплатам"'
                value={paymentFeedline?.messageCategory ?? null}
                disabled={paymentFeedline?.isSystem}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Сообщение">
              <TextArea
                settings={{ isFixed: true }}
                value={paymentFeedline?.message ?? ''}
                onChange={val => changeFeedline('message', val)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
}
