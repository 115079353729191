import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

const MagazineViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    apiID: 'GetPublication',
    template: PublicationTypes.PREPRINT_ARTICLE.template,
    type: PublicationTypes.PREPRINT_ARTICLE.code,
    viewMode: true,
    id: String(id),
    relatedTableState,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineViewTemplate);
