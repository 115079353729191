import React from 'react';

import { Modal, Tab, Tabs } from 'components';
import { Partner } from 'types/models';

import { DataGrid } from 'features/Table';
import { getEnterpriseCustomersIds, getEnterpriseIds } from 'features/EditPartner/helpers';

import {
  GetPartnerAffilatedPersonList,
  GetPartnerEventList,
  GetPartnerExpositionParticipationList,
  GetPartnerMobileProjectList,
  GetPartnerProjectList,
  GetPartnerPublicationList,
  GetPartnerSecurityDocumentContractList,
} from '../..';

type Props = {
  isOpen: boolean;
  onClose(): void;
  partner: Partner | null;
};

export function RelationsModal(props: Props) {
  const { isOpen, onClose, partner } = props;

  const enterpriseCustomerIds = getEnterpriseCustomersIds(partner);
  const enterpriseIds = getEnterpriseIds(partner);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Связи" size="full">
      <Tabs>
        <Tab title="НИОКР">
          <DataGrid specification={GetPartnerProjectList({ enterpriseCustomerIds, enterpriseIds })} />
        </Tab>
        <Tab title="Договоры на ИС">
          <DataGrid specification={GetPartnerSecurityDocumentContractList({ enterpriseCustomerIds, enterpriseIds })} />
        </Tab>
        <Tab title="Публикации">
          <DataGrid specification={GetPartnerPublicationList({ enterpriseCustomerIds, enterpriseIds })} />
        </Tab>
        <Tab title="Мероприятия">
          <DataGrid specification={GetPartnerEventList({ enterpriseCustomerIds, enterpriseIds })} />
        </Tab>
        <Tab title="Разработки (выставочные экспонаты)">
          <DataGrid specification={GetPartnerExpositionParticipationList({ enterpriseCustomerIds, enterpriseIds })} />
        </Tab>
        <Tab title="Стажировки">
          <DataGrid specification={GetPartnerMobileProjectList({ enterpriseCustomerIds, enterpriseIds })} />
        </Tab>
        <Tab title="Аффилированные лица в публикациях">
          <DataGrid specification={GetPartnerAffilatedPersonList({ enterpriseCustomerIds, enterpriseIds })} />
        </Tab>
      </Tabs>
    </Modal>
  );
}
