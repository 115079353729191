import React, { useEffect } from 'react';

import {
  TextDateTime,
  InputSelect,
  ListEditTable,
  FormComponent,
  Modal,
  Tabs,
  Tab,
  CustomSelect,
  ConfirmPopup,
} from 'components';

import {
  GetEstimatePositionList,
  GetReceivingDocumentList,
  GetEstimateOverheadHistoryList,
  GetEstimateStatusHistoryList,
  GetEstimatePositionItemList,
} from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { ProjectCodeInfo } from 'features/Form/looks/estimate/EstimateForm/ProjectCodeInfo/ProjectCodeInfo';
import { formatNumber } from 'utils/Helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { EditControlPanel } from './EditControlPanel';
import { useController } from './controller';
import { EstimateSummary } from './EstimateSummary/EstimateSummary';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function EstimateForm({ viewMode, editMode, onClose }: Props) {
  const {
    handleBalanceClick,
    refreshEstimate,
    estimateId,
    estimate,
    projectCodeId,
    workMode,
    handleAddFormSubmit,
    projectCodeOriginal,
    projectCodeSpecification,
    projectCodeRowConverter,
    createdDate,
    handleCreateDateChange,
    handleFieldChange,
    handleProjectCodeChange,
    yearOptions,
    handleYearChange,
    calendarPlans,
    selectedRowIndex,
    setSelectedRowIndex,
    isReceivingDocumentModalOpen,
    setIsReceivingDocumentModalOpen,
    isEstimateOverheadModalOpen,
    setIsEstimateOverheadModalOpen,
    handleReceivingDocumentsClick,
    handleEstimateOverheadClick,
    isChangeStatusModalOpen,
    setIsChangeStatusModalOpen,
    handleChangeStatusClick,
    handleSaveStatusClick,
    estimateStatusOptions,
    estimateStatus,
    handleChangeStatus,
    isStatusHistoryModalOpen,
    setIsStatusHistoryModalOpen,
    handleStatusHistoryClick,
    isProjectCodeInfoModalOpen,
    setIsProjectCodeInfoModalOpen,
    handleProjectCodeInfoClick,
    selectEstimateYear,
    projectCodeEditShown,
    isAmountWarningOpen,
    setIsAmountWarningOpen,
    amountWarningMessage,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });
  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (workMode === 'addMode') {
            return 'Создание сметы шифра';
          }
          return (
            <h3>
              {`${workMode === 'viewMode' ? 'Просмотр' : 'Редактирование'} Смета`} <b>{estimate?.year}</b>{' '}
              {`шифр: ${estimate?.projectCode?.code}`}
            </h3>
          );
        })(),
      );
    }
  }, [estimate?.projectCode?.code, estimate?.year, setTitle, workMode]);

  return (
    <>
      {workMode === 'addMode' && (
        <FormComponent.Template>
          <ControlPanel handleSave={handleAddFormSubmit} />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Дата составления" isRequired>
                <TextDateTime value={createdDate} onChange={handleCreateDateChange} isDisabled={!!viewMode} />
              </FormComponent.Field>
            </FormComponent.Line>
            {projectCodeEditShown && (
              <FormComponent.Line>
                <FormComponent.Field>
                  <CustomSelect
                    disabled={!!viewMode}
                    isRequired
                    title="Шифр проекта"
                    original={projectCodeOriginal}
                    relationTableModalTitle="Шифры проектов"
                    onDeleteFunction={() => handleFieldChange('projectCode')(null)}
                    onChange={value => handleProjectCodeChange('projectCode')(value)}
                    modalTableRowConverter={projectCodeRowConverter}
                    modalTableSpecification={projectCodeSpecification}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
            <FormComponent.Line lineSize="padded">
              <FormComponent.Field label="Год сметы">
                <InputSelect
                  options={yearOptions || []}
                  value={selectEstimateYear()}
                  onSelectChange={value => handleYearChange('year')(value)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field>
                <ListEditTable
                  rows={calendarPlans}
                  columns={[
                    { label: 'Этап', formatValue: row => row.stage.number || '' },
                    { label: 'Сроки', formatValue: row => `${row.stage.startDate} - ${row.stage.endDate}` || '' },
                    { label: 'Сумма, план', formatValue: row => formatNumber(row.stage.amountMain || '0', 2) },
                    { label: 'в т.ч. НДС', formatValue: row => formatNumber(row.ndsAmount || '0', 2) },
                  ]}
                  selectedRowIndex={selectedRowIndex}
                  selectRow={setSelectedRowIndex}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </FormComponent.Template>
      )}
      {workMode !== 'addMode' && (
        <FormComponent.Template>
          <EditControlPanel
            changeStatusCallback={handleChangeStatusClick}
            statusHistoryCallback={handleStatusHistoryClick}
            projectCodeInfoCallback={handleProjectCodeInfoClick}
            onClose={onClose}
          />
          <EstimateSummary
            estimate={estimate}
            receivingDocumentsCallback={handleReceivingDocumentsClick}
            estimateOverheadCallback={handleEstimateOverheadClick}
          />
          {estimateId !== null && (
            <FormComponent.Template>
              <Tabs>
                <Tab title="Расходы по статьям затрат">
                  <DataGrid
                    specification={GetEstimatePositionList({
                      estimateId,
                      viewMode,
                      handleRefreshEstimate: refreshEstimate,
                      handleBalanceClick,
                      isFiltersHidden: true,
                    })}
                  />
                </Tab>
                <Tab title="План-график расходования средств по проекту">
                  <DataGrid
                    specification={GetEstimatePositionItemList({
                      estimateId,
                      viewMode,
                      handleRefreshEstimate: refreshEstimate,
                      handleBalanceClick,
                      isFiltersHidden: true,
                    })}
                  />
                </Tab>
                {/* <Tab title="План-график =>">
                  <ListEditTable
                    rows={estimatePositionItems}
                    defaultRowsCount={6}
                    columnIndexesForSumTotal={[1]}
                    columns={[
                      { label: 'Дата', formatValue: x => x.date ?? '', styles: { width: '10%' } },
                      { label: 'Сумма, руб.', formatValue: x => x.amount ?? '0', styles: { width: '10%' } },
                      {
                        label: 'КВР',
                        formatValue: x => x.kindConsumption.kvr?.label || x.kindConsumption.code || '',
                        styles: { width: '55%' },
                      },
                      { label: 'Примечание', formatValue: x => x.note ?? '', styles: { width: '15%' } },
                      { label: 'Квартал', formatValue: x => x.quarter ?? '', styles: { width: '10%' } },
                    ]}
                    selectRow={setSelectedEstimatePositionItemIndex}
                    selectedRowIndex={selectedEstimatePositionItemIndex}
                  />
                </Tab> */}
              </Tabs>
            </FormComponent.Template>
          )}
        </FormComponent.Template>
      )}
      {estimateId !== null && projectCodeId !== null && (
        <Modal
          isOpen={isReceivingDocumentModalOpen}
          title={`Поступления на шифр ${estimate?.projectCode?.code}`}
          onClose={() => setIsReceivingDocumentModalOpen(false)}
          size={['extralarge', 'small-height']}
        >
          <DataGrid specification={GetReceivingDocumentList({ projectCodeId, year: estimate?.year, isFiltersHidden: true })} />
        </Modal>
      )}

      {estimateId !== null && (
        <Modal
          isOpen={isEstimateOverheadModalOpen}
          title="Журнал изменения ЦФ"
          onClose={() => setIsEstimateOverheadModalOpen(false)}
          size={['extralarge', 'small-height']}
        >
          <DataGrid specification={GetEstimateOverheadHistoryList({ estimatePositionId: estimateId, isFiltersHidden: true })} />
        </Modal>
      )}

      {estimateId !== null && (
        <Modal
          isOpen={isChangeStatusModalOpen}
          title="Смена статуса сметы"
          onClose={() => setIsChangeStatusModalOpen(false)}
          size="large"
        >
          <FormComponent.Template>
            <ControlPanel handleSave={handleSaveStatusClick} />

            <FormComponent.Wrapper>
              <FormComponent.Line>
                <InputSelect
                  options={estimateStatusOptions}
                  value={estimateStatusOptions.find(x => x.value === estimateStatus) ?? null}
                  onSelectChange={handleChangeStatus}
                />
              </FormComponent.Line>
            </FormComponent.Wrapper>
          </FormComponent.Template>
        </Modal>
      )}

      {estimateId !== null && (
        <Modal
          isOpen={isStatusHistoryModalOpen}
          title="История изменения статуса"
          onClose={() => setIsStatusHistoryModalOpen(false)}
          size="large"
        >
          <DataGrid specification={GetEstimateStatusHistoryList({ estimateId })} />
        </Modal>
      )}

      {estimateId !== null && (
        <Modal
          isOpen={isProjectCodeInfoModalOpen}
          title="Информация о шифре"
          onClose={() => setIsProjectCodeInfoModalOpen(false)}
          size="large"
        >
          <ProjectCodeInfo estimate={estimate} />
        </Modal>
      )}
      <ConfirmPopup
        isOpen={isAmountWarningOpen}
        onClose={() => setIsAmountWarningOpen(false)}
        title="Несоответствие сумм"
        okButtonText="Продолжить"
        onConfirm={() => setIsAmountWarningOpen(false)}
        icon="warning"
      >
        <div>
          <strong>Внимание!</strong>
        </div>
        <span dangerouslySetInnerHTML={{ __html: amountWarningMessage || '' }} />
      </ConfirmPopup>
    </>
  );
}

export const Component = React.memo(EstimateForm);
