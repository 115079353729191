import React from 'react';

import { FormComponent, Reference, TextInput } from 'components';

import { MobileRequest } from 'types/models';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.Route | null;
  onFieldChange(row: MobileRequest.Route | null): void;
};

function RouteFields({ entry, onFieldChange }: Props) {
  const { handleCountryChange, handleCityChange, handleEnterpriseChange } = useController({
    entry,
    onFieldChange,
  });
  const enterpriseTooltip =
    // eslint-disable-next-line max-len
    'Если вам не удалось добавить организацию, обратитесь к организаторам конкурса, которые указаны на вкладке "О конкурсе" в разделе "Консультация и помощь"';
  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Страна" isRequired>
        <Reference
          value={entry?.country || null}
          name="RefCountry"
          relationTableModalTitle='Справочник "Страны"'
          onChange={handleCountryChange}
        />
      </FormComponent.Field>
      <FormComponent.Field label="Город" isRequired>
        <TextInput value={entry?.city} onChange={handleCityChange} />
      </FormComponent.Field>
      <FormComponent.Field label="Принимающая организация" tooltip={enterpriseTooltip}>
        <Reference
          value={entry?.enterprise || null}
          name="RefEnterprise"
          relationTableModalTitle='Справочник "Организации"'
          onChange={handleEnterpriseChange}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}

export { RouteFields };
