import { Person } from 'types/models';

export function validate(hirshIndex: Person.HirshIndex | null) {
  if (!hirshIndex) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidDate = Boolean(hirshIndex.date);
  const isValidCitationSystem = Boolean(hirshIndex.citationSystem);

  return [
    { isValid: isValidDate, invalidMessage: 'Заполните дату' },
    { isValid: isValidCitationSystem, invalidMessage: 'Выберите систему цитирования' },
  ];
}
