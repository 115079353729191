import React, { useMemo } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { hasToolbarButtonMode } = customState;

  const { enumMap } = useAppDataContext();
  const securityDocumentsList = useMemo(
    () =>
      enumMap.SecurityDocumentType
        ? [
            ...enumMap.SecurityDocumentType.values.map(doc => ({ label: doc.label, value: doc.value })),
            ...[{ label: 'Все ОИС', value: '' }],
          ]
        : [],
    [enumMap],
  );

  return FiltersComponent(
    [
      {
        type: FilterComponentType.checkbox,
        streamKey: 'isActive',
        label: 'Действующие',
        checked: false,
      },
      {
        type: FilterComponentType.divider,
      },
      {
        type: FilterComponentType.radio,
        streamKey: 'documentsFilter',
        list: securityDocumentsList,
        value: securityDocumentsList[0]?.value || 'PATENT',
      },
    ],
    hasToolbarButtonMode ? 'personal' : 'default',
  );
});
