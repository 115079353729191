import { Report, Table } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Permits } from 'utils/Permissions';

import { useLocalTableStreams } from 'features/Table/hooks';

import { DetailProps } from '..';
import { Template } from 'types/models/Form';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController(tableState: Table.State, customState: DetailProps) {
  const tableStreams = useLocalTableStreams();
  const { scientistId } = customState;
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;
  const isApproveButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const templateName: Template = 'CommunityMemberForm';

  const isApproved: boolean = tableState.selectedRows[0]?.['id:Status'] === 'APPROVED';

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isApproveConfirmPopupOpen, setIsApproveConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const reports = useMemo<Report[]>(() => [Reports.Personal], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'CommunityMember',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleCloseApproveConfirmPopup = useCallback(() => {
    setIsApproveConfirmPopupOpen(false);
  }, []);

  const handleConfirmApproveConfirmPopup = useCallback(() => {
    tableStreams.approveRow.push({
      approveRowId: tableState.selectedRows[0].id,
      command: 'ApproveCommunityMember',
      approveItemName: 'CommunityMember',
      disapprove: isApproved,
    });
    handleCloseApproveConfirmPopup();
  }, [handleCloseApproveConfirmPopup, isApproved, tableState.selectedRows, tableStreams.approveRow]);

  const handleApproveButtonClick = useCallback(() => {
    setIsApproveConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        permissionName: Permits.COMMUNITY_INFO_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.COMMUNITY_INFO_VIEW,
        isHidden: !!scientistId,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: Permits.COMMUNITY_INFO_VIEW,
        isHidden: !!scientistId,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
        permissionName: Permits.COMMUNITY_INFO_VIEW,
        isHidden: !!scientistId,
      },
      {
        icons: buttonIcons.approve,
        title: isApproved ? 'Перевести в статус "Добавлено"' : 'Утвердить',
        code: 'approve',
        isDisabled: isApproveButtonDisabled,
        onClick: handleApproveButtonClick,
        permissionName: Permits.COMMUNITY_APPROVE,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'print',
        isDisabled: false,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      handleHelpButtonClick,
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      scientistId,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      isApproved,
      isApproveButtonDisabled,
      handleApproveButtonClick,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    isAddFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    isViewButtonDisabled,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    handleCloseApproveConfirmPopup,
    handleConfirmApproveConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    isApproved,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
