import React, { useCallback, useEffect, useState } from 'react';

import { ListEditTable } from 'components';

import { Program } from 'types/models';
import { IndicatorKey } from 'features/Form/looks/project/ProjectForm/model';

type Props = {
  indicators: Program.Indicator[];
  onSelectIndicators(indicators: Program.Indicator[]): void;
  disabled: boolean;
  type: IndicatorKey;
};

export function SelectIndicators(props: Props) {
  const { indicators, disabled, onSelectIndicators, type } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);

  const clearSelectedIndexes = useCallback(() => {
    setSelectedRowIndex(null);
    setSelectedRowsIndexes([]);
  }, []);

  useEffect(() => {
    clearSelectedIndexes();
  }, [indicators, clearSelectedIndexes]);

  useEffect(() => {
    if (disabled) {
      clearSelectedIndexes();
    }
  }, [disabled, clearSelectedIndexes]);

  const handleSelectIndicators = useCallback(
    (indexes: number[]) => {
      setSelectedRowsIndexes(indexes);
      const selectedStages = indexes.map(index => indicators[index]);
      onSelectIndicators(selectedStages);
    },
    [indicators, onSelectIndicators],
  );

  return (
    <ListEditTable
      rows={indicators}
      defaultRowsCount={4}
      columns={[
        {
          label:
            type === 'indicators'
              ? 'Актуальные индикаторы эффективности программы'
              : 'Актуальные показатели результативности программы',
          formatValue: x => x.refResultItem?.label ?? '',
        },
      ]}
      selectedRowIndex={selectedRowIndex}
      selectRow={disabled ? () => {} : setSelectedRowIndex}
      selectedRowsIndexes={selectedRowsIndexes}
      onSelectRows={disabled ? undefined : handleSelectIndicators}
    />
  );
}
