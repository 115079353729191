import { Person } from 'types/models';

export function formatName(item: Person.ScientistDegree | Person.ScientistAcademicRank | Person.ScientistRank) {
  if (Person.isScientistDegree(item)) {
    return `Уч. степень ${item.refDegree?.label}`;
  }
  if (Person.isScientistRank(item)) {
    return `Уч. звание ${item.refRank.label}`;
  }
  return `Академ. звание ${item.refAcademicRank.label}`;
}
