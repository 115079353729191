import { useLayoutEffect, useMemo, useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, IconButtonProps } from 'components';

import { usePrivatePageContext } from 'App/PrivatePage/context';
import { parse } from 'date-fns';
import { Permits } from 'utils/Permissions';
import { Notice, NoticeTender } from 'types/models/Notice';
import { getEnum } from 'utils/Helpers';
import { NoticeKind } from 'utils/Enums';
import { formatStr } from 'utils/Constants/FormatStr';
import { showNotification } from 'features/Notifications';
import { ProtocolTender } from 'types/models/TenderProtocol';

export const NOTICE_PAGE_SIZE = 10;

export function useController(isHome: boolean, isTenders: boolean) {
  const { isProfile } = usePrivatePageContext();
  const kindOptions = getEnum('NoticeKind');
  const [list, setList] = useState<Notice[]>([]);
  const [listTenders, setListTenders] = useState<NoticeTender[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [addModalKind, setAddModalKind] = useState<string>(NoticeKind.OTHER);
  const [addModalCaption, setAddModalCaption] = useState<string>('');
  const [addModalData, setAddModalData] = useState<string>('');
  const [editModalKind, setEditModalKind] = useState<string>(NoticeKind.OTHER);
  const [editModalCaption, setEditModalCaption] = useState<string>('');
  const [editModalData, setEditModalData] = useState<string>('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);

  /* Tenders */
  const { methods: getNoticesTenders } = BackendAPI.useBackendAPI('GetNoticeTenders', {
    onSuccessfullCall: ({ data }) => {
      const noticesTenders = data.map(
        (tender: ProtocolTender) =>
          ({
            id: tender.id,
            announcementDate: tender.announcementDate,
            name: tender.name,
            participants: (tender.participants || []).map(i => i.label),
            categories: (tender.categories || []).map(i => i.label),
            interests: (tender.scienceDomainInterrests || []).map(i => i.label),
            site: tender.url,
            dateUniversity: tender.dateUniversity,
            description: tender.description,
          } as NoticeTender),
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setListTenders(noticesTenders);
      });
    },
  });

  /* Notices */
  const { methods: getNotices } = BackendAPI.useBackendAPI('GetNotices', {
    onSuccessfullCall: ({ data }) => {
      data.sort((x1, x2) => {
        const datesDiff: number =
          parse(x2.createdDate || '', formatStr, new Date()).getTime() -
          parse(x1.createdDate || '', formatStr, new Date()).getTime();
        if (datesDiff === 0) {
          return parseInt(x2.id, 10) - parseInt(x1.id, 10);
        } else {
          return datesDiff;
        }
      });
      ReactDOM.unstable_batchedUpdates(() => {
        setList(data);
        setIsAddModalOpen(false);
      });
    },
  });

  const { methods: saveNotice } = BackendAPI.useBackendAPI('SaveNotice', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Объявление сохранено', theme: 'success' });
      getNotices.callAPI({ startRecord: 0, lastRecord: NOTICE_PAGE_SIZE });
    },
  });

  const { methods: deleteModel } = BackendAPI.useBackendAPI('DeleteModel', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Объявление удалено', theme: 'success' });
      getNotices.callAPI({ startRecord: 0, lastRecord: NOTICE_PAGE_SIZE });
    },
  });

  useEffect(() => {
    if (selectedRowIndex || selectedRowIndex === 0) {
      setEditModalKind(list[selectedRowIndex].kind.value);
      setEditModalCaption(list[selectedRowIndex].caption);
      setEditModalData(list[selectedRowIndex].data);
    } else {
      setEditModalKind(NoticeKind.OTHER);
      setEditModalCaption('');
      setEditModalData('');
    }
  }, [list, selectedRowIndex]);

  useLayoutEffect(() => {
    if (isTenders) {
      getNoticesTenders.callAPI({});
    } else {
      getNotices.callAPI({ startRecord: 0, lastRecord: NOTICE_PAGE_SIZE });
    }
    // eslint-disable-next-line
  }, []);

  function GetIndex(recordId: string) {
    const index = list.map((e: any) => e.id).indexOf(recordId);
    return index;
  }

  const handleAddModalKindChange = useCallback((value: string) => {
    setAddModalKind(value);
  }, []);

  const handleAddModalCaptionChange = useCallback((value: string) => {
    setAddModalCaption(value);
  }, []);

  const handleAddModalDataChange = useCallback((value: string) => {
    setAddModalData(value);
  }, []);

  const handleEditModalKindChange = useCallback((value: string) => {
    setEditModalKind(value);
  }, []);

  const handleEditModalCaptionChange = useCallback((value: string) => {
    setEditModalCaption(value);
  }, []);

  const handleEditModalDataChange = useCallback((value: string) => {
    setEditModalData(value);
  }, []);

  const handleAddModalSaveButtonClick = useCallback(() => {
    if (addModalData && addModalCaption && addModalKind) {
      const editingRecord = {
        id: null,
        data: addModalData,
        caption: addModalCaption,
        kind: addModalKind,
      };
      saveNotice.callAPI(editingRecord);
    } else {
      showNotification({ theme: 'danger', message: 'Заполните все поля' });
    }
  }, [addModalCaption, addModalData, addModalKind, saveNotice]);

  const handleEditModalSaveButtonClick = useCallback(() => {
    if (editModalData && editModalCaption && editModalKind) {
      const editingRecord = {
        id: selectedRowIndex || selectedRowIndex === 0 ? list[selectedRowIndex].id : null,
        data: editModalData,
        caption: editModalCaption,
        kind: editModalKind,
      };
      saveNotice.callAPI(editingRecord);
    } else {
      showNotification({ theme: 'danger', message: 'Заполните все поля' });
    }
  }, [editModalCaption, editModalData, editModalKind, list, saveNotice, selectedRowIndex]);

  const handleDeleteButtonClick = useCallback(() => {
    if (selectedRowIndex || selectedRowIndex === 0)
      deleteModel.callAPI({ rowId: list[selectedRowIndex].id, commandName: 'DeleteEntity', entityName: 'Notice' });
    setIsConfirmPopupOpen(false);
  }, [deleteModel, list, selectedRowIndex]);

  const handleLoadButtonClick = useCallback(() => {
    getNotices.callAPI({ startRecord: 0, lastRecord: list.length + NOTICE_PAGE_SIZE });
  }, [getNotices, list.length]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isHidden: isProfile,
        permissionName: Permits.NOTICE_SYSTEM_ADD,
        isDisabled: false,
        onClick: () => {
          setIsAddModalOpen(true);
          setAddModalKind(NoticeKind.OTHER);
          setAddModalCaption('');
          setAddModalData('');
        },
      },
      {
        icons: buttonIcons.viewList,
        title: 'Редактировать список',
        isHidden: isProfile,
        permissionName: Permits.NOTICE_SYSTEM_EDIT,
        isDisabled: false,
        onClick: () => {
          setIsEditModalOpen(true);
        },
      },
      {
        icons: buttonIcons.arrowDown,
        title: `Подгрузить предыдущие ${NOTICE_PAGE_SIZE} записей`,
        isDisabled: false,
        onClick: () => {
          handleLoadButtonClick();
        },
      },
    ],
    [handleLoadButtonClick, isProfile],
  );

  const addModalButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Cохранить',
        code: 'save',
        isDisabled: false,
        onClick: () => {
          handleAddModalSaveButtonClick();
        },
      },
    ],
    [handleAddModalSaveButtonClick],
  );

  const editModalButtons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Cохранить',
        code: 'save',
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
        onClick: () => {
          handleEditModalSaveButtonClick();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'delete',
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
        onClick: () => {
          setIsConfirmPopupOpen(true);
        },
      },
    ],
    [handleEditModalSaveButtonClick, selectedRowIndex],
  );

  return {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setAddModalCaption,
    setAddModalData,
    setAddModalKind,
    addModalButtons,
    kindOptions,
    addModalKind,
    handleAddModalKindChange,
    addModalCaption,
    handleAddModalCaptionChange,
    addModalData,
    handleAddModalDataChange,
    isEditModalOpen,
    setIsEditModalOpen,
    editModalButtons,
    editModalKind,
    handleEditModalKindChange,
    selectedRowIndex,
    editModalCaption,
    handleEditModalCaptionChange,
    editModalData,
    handleEditModalDataChange,
    list,
    GetIndex,
    setSelectedRowIndex,
    buttons,
    listTenders,
  };
}
