import { Table as T } from 'types/models';
import { Filters, FiltersForRequest } from './filters';
import { Toolbar } from './Toolbar/Toolbar';

export type DetailProps = {
  isRequestMode?: boolean;
};
export function GetPersonalSecurityDocumentList({ isRequestMode }: DetailProps): T.Specification {
  return {
    apiID: 'GetPersonalSecurityDocumentList',
    isFiltersHidden: true,
    header: {
      thirdLevel: {
        LeftPanel: Toolbar({ isRequestMode }),
        withTemplatesPanel: false,
      },
      firstLevel: {
        title: isRequestMode
          ? 'Заявки на регистрацию объектов интеллектуальной собственности'
          : 'Список моих патентов, свидетельств',
        Panel: isRequestMode ? FiltersForRequest : Filters,
      },
    },
    isWithQna: true,
  };
}
