import { Table as T } from 'types/models';

import { Filters } from './filters';
import { ComponentForSecondLevel } from './ComponentForSecondLevel';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export function GetScientistHirshIndexesList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
  scientistId: string,
): T.Specification<State> {
  return {
    apiID: 'GetScientistHirshIndexesList',
    useCustomController: makeUseCustomController(templatesTableDependencies, scientistId),
    templatesTableDependencies,
    workMode,
    header: {
      firstLevel: {
        Panel: Filters,
      },
      secondLevel: {
        Component: ComponentForSecondLevel,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: workMode === 'viewMode' ? undefined : LeftPanelForThirdLevel,
      },
    },
  };
}
