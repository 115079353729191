import React, { ReactElement, ReactNode } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('component-content');

type Props = {
  children: ReactElement | ReactNode | false | (ReactElement | ReactNode | false)[];
};

/**
 * Content component (div)
 *
 * @returns JSX
 */
export const Content = React.memo(({ children }: Props) => {
  return <div className={b()}>{children}</div>;
});
