import { useLayoutEffect, useCallback, useState } from 'react';

import { useStream } from 'StreamRx';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { streams as localFormStreams } from 'features/Form/streams';
import { QnaQuestion } from 'types/models';
import { useQnaFormController } from '../qnaFormController';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  onClose: () => void;
};

export const useController = ({ onClose }: Props) => {
  const {
    look: { id },
  } = useFormContext();

  const tableStreams = useLocalTableStreams();

  const { methods: getQnaQuestion } = BackendAPI.useBackendAPI('GetQnaQuestion');
  const { methods: saveQnaQuestionAnswer } = BackendAPI.useBackendAPI('SaveQnaQuestionAnswer');

  const [themeText, setThemeText] = useState<string>('');
  const [questionText, setQuestionText] = useState<string>('');

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams]);

  const { formFields, fieldsFromModel } = useQnaFormController();

  useStream(
    () => localFormStreams.loadForm,
    ({ currentId }) => {
      loadQnaQuestion(currentId);
    },
    // eslint-disable-next-line
    [],
  );
  const loadQnaQuestion = useCallback(
    (currentId?: string) => {
      if (id !== 'undefined') {
        getQnaQuestion.callAPI(
          {
            id: currentId || id || null,
          },
          {
            onSuccessfullCall: (result: any) => {
              const qnaQuestion = result.data as QnaQuestion;
              fieldsFromModel(qnaQuestion);
              setQuestionText(qnaQuestion.message);
              setThemeText(qnaQuestion.theme.label);
            },
          },
        );
      }
    },
    [getQnaQuestion, fieldsFromModel, id],
  );

  const saveQnaAnswerHandler = useCallback(() => {
    if (!formFields.answer.value) {
      showNotification({ message: 'Напишите ответ', theme: 'danger' });
      return false;
    }
    saveQnaQuestionAnswer.callAPI(
      {
        id,
        answerText: formFields.answer.value,
        answerFile: { ...formFields.answerFile?.value },
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Ответ сохранен', theme: 'success' });
          updateTable();
          onClose();
        },
      },
    );
    return true;
  }, [saveQnaQuestionAnswer, id, formFields, updateTable, onClose]);

  useLayoutEffect(() => {
    loadQnaQuestion();
    // eslint-disable-next-line
  }, []);

  return {
    questionText,
    themeText,
    id,
    formFields,
    saveQnaAnswerHandler,
  };
};
