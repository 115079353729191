import React, { useCallback, useState } from 'react';

import { buttonIcons, ListEditTable, Toolbar, FormComponent, Modal } from 'components';

import { NirRequest } from 'types/models';
import { EnumValue } from 'types/models/Table';

type Props = {
  performers: NirRequest.Performer[];
  roles: EnumValue[];
  isOpen: boolean;
  onClose(): void;
  onSubmit(performers: NirRequest.Performer[]): void;
};

export function ModalPerformersTable(props: Props) {
  const { performers, roles, isOpen, onClose, onSubmit } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedRowsIndexes([]);
    setSelectedRowIndex(null);
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(selectedRowsIndexes.map(index => performers[index]));
    handleClose();
  }, [handleClose, onSubmit, selectedRowsIndexes, performers]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="Выберите исполнителя" size="large">
      <FormComponent.Wrapper>
        <Toolbar
          buttons={[
            {
              icons: buttonIcons.check,
              title: 'Выбрать',
              isDisabled: !selectedRowsIndexes.length,
              onClick: handleSubmit,
            },
          ]}
          mode="list"
        />

        <ListEditTable
          rows={performers}
          columns={[
            { label: 'Роль', formatValue: x => roles.find(role => role.value === x.role?.value)?.label ?? '' },
            { label: 'ФИО исполнителя', formatValue: x => x.person?.fullName ?? '' },
            { label: 'Дата рождения', formatValue: x => x.person?.scientist?.dateBirth ?? '' },
          ]}
          selectedRowIndex={selectedRowIndex}
          selectRow={setSelectedRowIndex}
          selectedRowsIndexes={selectedRowsIndexes}
          onSelectRows={setSelectedRowsIndexes}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
}
