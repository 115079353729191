import * as T from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';

import { Toolbar } from './Toolbar/Toolbar';

export const GetProgramDomainKnowledgeSelectList = (
  requestData: T.GetProgramDomainKnowledgeListRequest,
): Table.Specification => ({
  apiID: 'GetProgramDomainKnowledgeSelectList',
  requestData,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: Toolbar,
    },
  },
});
