import React, { useCallback } from 'react';
import * as R from 'ramda';

import { FormComponent, ListEdit, SectionTitle } from 'components';

import { showErrors } from 'utils/Validators';
import { formatNumber } from 'utils/Helpers';
import { EmployeeNominee, EmployeePayment } from 'types/models/Payment';
import { calcShare, getMockEmployeeNominee } from 'features/Table/specifications/GetRequestFundList/helpers';
import { Fields } from './Fields/Fields';
import { BaseInfo } from './BaseInfo/BaseInfo';
import { validate } from './validate';

type Props = {
  employeePayment: EmployeePayment | null;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  disabled: boolean;
};

export function General(props: Props) {
  const { employeePayment, setEmployeePayment, disabled } = props;

  const onChangeNominees = useCallback(
    (nominees: EmployeeNominee[]) => {
      setEmployeePayment({ ...employeePayment!, nominees });
    },
    [employeePayment, setEmployeePayment],
  );

  if (!employeePayment) {
    return <FormComponent.Description mode="warning">Не выбран "Фонд подразделений"</FormComponent.Description>;
  }

  return (
    <>
      <BaseInfo employeePayment={employeePayment} disabled={disabled} setEmployeePayment={setEmployeePayment} />
      <SectionTitle title="Список сотрудников подразделения" />
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        rows={employeePayment.nominees}
        onChange={onChangeNominees}
        isToolbarDisabled={disabled}
        defaultRowsCount={10}
        maxHeight="80vh"
        columns={[
          { label: '№', styles: { width: '5%' }, formatValue: (x, index) => index + 1 },
          { label: 'ФИО сотрудника', formatValue: x => x.employee?.fullName ?? '' },
          { label: 'Должность', formatValue: x => x.job?.refAppointment.label ?? '' },
          { label: 'Доля', formatValue: x => formatNumber(calcShare(x.amountMoney, employeePayment?.amountMoney ?? 0), 2) },
          { label: 'Сумма', formatValue: x => formatNumber(x.amountMoney) },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (nominee, setNominee) => (
            <Fields
              nominee={nominee || getMockEmployeeNominee()}
              commonAmount={employeePayment?.amountMoney ?? 0}
              setNominee={setNominee}
            />
          ),
          validation: {
            checkIsValid: nominee => validate(nominee, employeePayment?.amountMoney ?? 0).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      />
    </>
  );
}
