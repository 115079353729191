import React from 'react';

import { block } from 'bem-cn';

import { SectionTitle, FormComponent } from 'components';

import { ValueOf } from 'types/helpers';

import { ContestRequest } from 'types/models/ContestRequest';

import { Line } from './Line';
import './style.scss';

const performance = block('cr-performance-indicators');

type Props = {
  contestRequest: ContestRequest | undefined;
  makeChangeHandler(key: keyof ContestRequest): (value: ValueOf<ContestRequest>) => void;
  disabled: boolean;
};

export function PerformanceIndicators(props: Props) {
  const { contestRequest, makeChangeHandler, disabled } = props;
  const amountStages = contestRequest?.project?.stages.length ?? 0;

  const indices = contestRequest?.requestStageIndices ?? [];
  const indicators = contestRequest?.requestStageIndicators ?? [];

  const notePlaceholders: Record<string, string> = {
    'Объемы привлеченных средств (в млн. руб.)': 'С учетом статей входящих в журналы Q1-Q4, Q0',
    'Количество экспертов для рейтинга QS (академических)': 'Число экспетов, привлеченных впервые в предыдущем году',
    'Количество экспертов для рейтинга QS (работодателей)': 'Число экспетов, привлеченных впервые в предыдущем году',
  };

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title="Показатели результативности" />
        <div className={performance('grid', { 'two-level': amountStages > 1 })}>
          {indices.map((x, index) => (
            <Line
              key={index}
              disabled={disabled}
              indicator={x}
              indicators={indices}
              setIndicators={makeChangeHandler('requestStageIndices')}
              notePlaceholders={notePlaceholders}
            />
          ))}
        </div>
      </FormComponent.Column>
      <FormComponent.Column>
        <SectionTitle title="Индикаторы результативности" />
        <div className={performance('grid', { 'two-level': amountStages > 1 })}>
          {indicators.map((x, index) => (
            <Line
              key={index}
              indicator={x}
              indicators={indicators}
              setIndicators={makeChangeHandler('requestStageIndicators')}
              disabled={disabled}
              notePlaceholders={notePlaceholders}
            />
          ))}
        </div>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
