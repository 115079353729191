import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons, ReferenceItem } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { SecurityDocument, SecurityDocumentPayment, SecurityDocumentTypes } from 'types/models';
import { showNotification } from 'features/Notifications';
import { format, parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { yearsFromRange } from 'utils/Helpers/yearsFromRange';

type Props = {
  patent?: SecurityDocument | null;
  setPatent?: React.Dispatch<React.SetStateAction<SecurityDocument | null | undefined>>;
  disabled?: boolean;
};

export function useController(props: Props) {
  const { patent, setPatent, disabled } = props;

  const [payments, setPayments] = useState<SecurityDocumentPayment[]>(
    patent?.payments.filter(p => p.paymentPurpose?.id !== undefined) || [],
  );

  const [requestPayments, setRequestPayments] = useState<SecurityDocumentPayment[]>(
    patent?.payments.filter(p => p.paymentPurposeRequest?.id !== undefined && p.paymentPurpose === undefined) || [],
  );

  const [purposesByYearNum, setPurposesByYearNum] = useState<Record<number, ReferenceItem>>();

  const handleRequestPaymentsChange = useCallback(
    (value: SecurityDocumentPayment[]) => {
      setRequestPayments(value);
      if (setPatent) {
        setPatent(prevPatent => ({ ...prevPatent!, payments: [...payments, ...value] }));
      }
    },
    [payments, setPatent],
  );

  const paymentTitleByType = useMemo(
    () =>
      patent?.type?.value === SecurityDocumentTypes.PATENT
        ? 'Пошлины за поддержание патента'
        : 'Пошлины за поддержание свидетельства',
    [patent],
  );

  const handlePaymentsChange = useCallback(
    (value: SecurityDocumentPayment[]) => {
      setPayments(value);
      if (setPatent) {
        setPatent(prevPatent => ({ ...prevPatent!, payments: [...requestPayments, ...value] }));
      }
    },
    [requestPayments, setPatent],
  );

  const yearNumFromLabel = useCallback((label: string) => {
    let yearNum = null;
    const yearMatch = label.match('за (.*)-й год действия');
    if (yearMatch) {
      yearNum = parseInt(yearMatch[1], 10);
    }
    return yearNum;
  }, []);

  const formatPurposesByYearNum = useCallback(
    (values: ReferenceItem[]) => {
      const purposes: Record<number, ReferenceItem> = {};
      values.forEach(item => {
        const yearNum = yearNumFromLabel(item?.label || '');
        if (yearNum) {
          purposes[yearNum] = item;
        }
      });
      return purposes;
    },
    [yearNumFromLabel],
  );

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const loadPurposes = useCallback(() => {
    loadReferenceAPI.callAPI(
      { filters: [], referenceName: 'RefPaymentPurpose' },
      {
        onSuccessfullCall: ({ data }) => {
          setPurposesByYearNum(formatPurposesByYearNum(data));
        },
      },
    );
  }, [loadReferenceAPI, formatPurposesByYearNum]);

  const handleFillCalendarClick = useCallback(() => {
    loadReferenceAPI.callAPI(
      { filters: [], referenceName: 'RefPaymentPurpose' },
      {
        onSuccessfullCall: () => {
          if (!patent?.requestDate) {
            showNotification({ message: 'Не введена дата подачи заявки', theme: 'danger' });
            return;
          }
          if (!patent?.documentEndDate) {
            showNotification({ message: 'Не введено окончание срока действия патента', theme: 'danger' });
          }
          let startDate = parse(patent?.requestDate || '', formatStr, new Date());
          const endDate = parse(patent?.documentEndDate || '', formatStr, new Date());
          const nextPayments = [...payments];
          let yearNum = payments.length || 1;
          if (payments.length) {
            const lastPayment = payments[payments.length - 1];
            if (lastPayment && lastPayment.paymentPurpose?.label) {
              const labelYearNum = yearNumFromLabel(lastPayment.paymentPurpose.label);
              if (labelYearNum) {
                yearNum = labelYearNum;
              }
              startDate = parse(lastPayment.payBeforeDate || '', formatStr, new Date());
            }
          }
          yearsFromRange(startDate, endDate)?.forEach(year => {
            const isExists: SecurityDocumentPayment[] = payments?.filter(
              p => parse(p.payBeforeDate || '', formatStr, new Date()).getFullYear() === year,
            );
            const purpose =
              purposesByYearNum && purposesByYearNum[yearNum] ? purposesByYearNum[yearNum] : { id: '1', label: 'НЕ УКАЗАНО' };
            if (!isExists.length) {
              nextPayments.push({
                id: null,
                payDate: '',
                amount: 0,
                payBeforeDate: format(new Date(startDate.setFullYear(year)), formatStr),
                securityDocument: { id: patent?.id || '', value: patent?.id || '' },
                paymentPurpose: purpose,
              });
            }
            yearNum += 1;
          });
          handlePaymentsChange(nextPayments);
        },
      },
    );
  }, [patent, payments, handlePaymentsChange, purposesByYearNum, loadReferenceAPI, yearNumFromLabel]);

  const paymentExtraToolbarButtons = useMemo<ExtraToolbarButton<SecurityDocumentPayment>[]>(
    () => [
      {
        title: 'Заполнить календарь',
        icons: buttonIcons.calc,
        onClick: handleFillCalendarClick,
        disabled,
      },
    ],
    [handleFillCalendarClick, disabled],
  );

  useLayoutEffect(() => {
    loadPurposes();
    // eslint-disable-next-line
  }, []);
  return {
    payments,
    requestPayments,
    handleRequestPaymentsChange,
    handlePaymentsChange,
    paymentExtraToolbarButtons,
    paymentTitleByType,
  };
}
