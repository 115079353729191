import * as R from 'ramda';
import * as o from 'rxjs/operators';
import { useContext } from 'react';

import { StreamRx } from 'StreamRx';

import { streams } from './streams';
import { FormContext, Context } from './context';
import { APIIDHolder } from './types';

type FormStreams<T> = {
  [K in keyof T]: T[K] extends StreamRx<infer Data> ? StreamRx<{ [DK in keyof Omit<Data, 'apiID'>]: Data[DK] }> : never;
};

export function useFormContext<SpecificLook = object>() {
  const context = useContext<Context<SpecificLook> | null>(FormContext);

  if (context === null) {
    throw Error('no context provided');
  }

  return context;
}

export function useLocalStreams<T>(localStreams: T): FormStreams<T> {
  const {
    look: { apiID },
  } = useFormContext();

  return R.map(
    (stream: StreamRx<APIIDHolder>): StreamRx<any> => ({
      push: (data: any) => stream.push({ ...data, apiID }),
      observable: stream.observable.pipe(o.filter(data => data.apiID === apiID)),
    }),
    localStreams as any,
  ) as any;
}

export function useLocalFormStreams() {
  return useLocalStreams(streams);
}
