import { CommunityMember } from 'types/models';
import { showNotification } from 'features/Notifications';

export function validateCommunityMember(communityMember: CommunityMember): boolean {
  const errorMessages: string[] = [];
  if (!communityMember?.scientist?.id) errorMessages.push('Заполните поле "Персона"');
  if (!communityMember?.role?.value) errorMessages.push('Заполните поле "Роль"');
  if (!communityMember?.joinDate) errorMessages.push('Заполните поле "Дата ввода в состав"');
  if (
    ['SCIENTIFIC_COUNCIL', 'COLLABORATION', 'FUND'].includes(communityMember.community?.type?.value || '') &&
    !communityMember?.cooperationType?.value
  ) {
    errorMessages.push('Заполните поле "Предмет сотрудничества"');
  }
  if (errorMessages.length) {
    errorMessages.forEach(message => showNotification({ message, theme: 'danger' }));
    return false;
  }
  return true;
}
