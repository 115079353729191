import React, { memo } from 'react';
import { ConfirmPopup } from 'components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DocumentsWarningPopup = ({ isOpen, onClose, onConfirm }: Props) => (
  <ConfirmPopup
    title="Внимание"
    text="Без подтверждающего документа невозможно утвердить мероприятие."
    isOpen={isOpen}
    okButtonText="Сохранить в статусе Добавлена"
    resetButtonText="Добавить документ"
    onClose={onClose}
    onConfirm={onConfirm}
    icon="info"
  />
);

const Component = memo(DocumentsWarningPopup);

export { Component as DocumentsWarningPopup };
