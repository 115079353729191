import { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { format } from 'date-fns';
import { useAppDataContext } from 'features/AppData/context';
import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { ScientistEducation } from 'types/models/Person';

type Props = {
  person: Person.ScientistPerson | null;
  onChangeEducations(education: Person.ScientistEducation[]): void;
};

const useController = ({ person, onChangeEducations }: Props) => {
  const { currentPerson, userPermission } = useAppDataContext();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isDeleteHistoryWarningOpen, setIsDeleteHistoryWarningOpen] = useState<boolean>(false);
  const [isEntityRelationOpen, setIsEntityRelationOpen] = useState<boolean>(false);
  const [educationIds, setEducationIds] = useState<string[]>([]);
  const [selectedEducations, setSelectedEducations] = useState<ScientistEducation[]>([]);
  const [isMergeDuplicatesOpen, setIsMergeDuplicatesOpen] = useState<boolean>(false);

  const handleSelectedRows = useCallback((educations: ScientistEducation[]) => {
    setSelectedEducations(educations);
  }, []);

  const handleCloseMergeDuplicatesForm = useCallback(() => {
    setIsMergeDuplicatesOpen(false);
  }, []);

  const isMergeDisabled = useCallback(() => {
    return selectedEducations.length !== 2;
  }, [selectedEducations]);

  const { methods: GetIsLinkedHistoryAPI, state: GetIsLinkedHistoryState } = BackendAPI.useBackendAPI('IsLinkedHistory');

  const isLinkedHistoryPending = useMemo(() => getIsPending(GetIsLinkedHistoryState), [GetIsLinkedHistoryState]);

  const onDeleteButtonClick = useCallback((nextDeletedIndex: number) => {
    setSelectedIndex(nextDeletedIndex);
    setIsDeleteConfirmOpen(true);
  }, []);

  const onDeleteReset = useCallback(() => {
    setSelectedIndex(null);
    setIsDeleteConfirmOpen(false);
  }, []);

  const selectedEducation = useMemo(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      return person.scientistEducations[selectedIndex!];
    }

    return null;
  }, [selectedIndex, person]);

  const removeEducation = useCallback(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      const scientistEducationsCopy = R.clone(person?.scientistEducations || []);
      const isReadOnlyEducation = Boolean(selectedEducation?.isReadOnly);

      if (isReadOnlyEducation) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.SCIENTIST_JOB_EDUCATION_DEGREE_RANK_DELETE);
        if (!isUserHasPermission) {
          showNotification({ message: 'Нет прав на удаление обучений пришедших по интеграции', theme: 'danger' });
          return;
        }
        const deletionDate = `${format(new Date(), formatDateTimeStr)}`;
        const deletedBy = currentPerson?.fullName || '';
        const nextScientistEducation: Person.ScientistEducation = {
          ...selectedEducation!,
          isActive: false,
          deletionDate,
          deletedBy,
        };
        const nextScientistEducations = R.pipe<
          Person.ScientistEducation[],
          Person.ScientistEducation[],
          Person.ScientistEducation[]
        >(
          R.remove(selectedIndex, 1),
          R.insert(selectedIndex, nextScientistEducation),
        )(scientistEducationsCopy);
        onChangeEducations(nextScientistEducations);

        showNotification({ message: 'Обучение помечено удаленным', theme: 'success' });
      } else {
        const nextScientistEducations = R.remove<Person.ScientistEducation>(selectedIndex, 1)(scientistEducationsCopy);
        onChangeEducations(nextScientistEducations);
        showNotification({ message: 'Обучение удалено', theme: 'success' });
      }
    }
  }, [currentPerson?.fullName, onChangeEducations, person, selectedEducation, selectedIndex, userPermission]);

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmOpen(false);

    if (selectedIndex === null) {
      return;
    }

    if (selectedEducation?.id) {
      GetIsLinkedHistoryAPI.callAPI(
        { type: LinkedHistoryType.EDUCATION, id: selectedEducation.id },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.isSuccess) {
              setIsDeleteHistoryWarningOpen(true);
            } else {
              removeEducation();
            }
          },
        },
      );
    } else {
      removeEducation();
    }
  }, [selectedIndex, selectedEducation?.id, GetIsLinkedHistoryAPI, removeEducation]);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.ScientistEducation>[]>(
    () => [
      {
        title: 'Удалить',
        icons: buttonIcons.delete,
        isDisabled: false,
        checkIsDisabled: (education: Person.ScientistEducation | null) => {
          if (education === null || isLinkedHistoryPending || (selectedEducations && selectedEducations?.length > 1)) {
            return true;
          }

          const isEducationReadonly = education?.isReadOnly;
          const isEducationActive = education?.isActive;
          if (isEducationReadonly) {
            if (isEducationActive) {
              return false;
            }

            return true;
          }

          return false;
        },
        onClick: (_, educationIndex: number | null) => {
          if (educationIndex !== null) {
            onDeleteButtonClick(educationIndex);
          }
        },
      },
      {
        title: 'Связь',
        icons: buttonIcons.editRelations,
        checkIsDisabled: (education: Person.ScientistEducation | null) =>
          !education?.id || (selectedEducations && selectedEducations?.length > 1),
        onClick: (_, educationIndex: number | null) => {
          if (educationIndex !== null) {
            setSelectedIndex(educationIndex);
            setIsEntityRelationOpen(true);
          }
        },
      },
      {
        icons: buttonIcons.merge,
        title: 'Объединение дубликатов',
        checkIsDisabled: isMergeDisabled,
        isHidden: !isHasPermission(userPermission, Permits.SCIENTIST_MERGE),
        onClick: () => {
          const ids: string[] = selectedEducations
            .map((education: ScientistEducation) => {
              return education?.id || '';
            })
            .filter(x => !!x);
          setEducationIds(ids);
          setIsMergeDuplicatesOpen(true);
        },
      },
    ],
    [isLinkedHistoryPending, onDeleteButtonClick, isMergeDisabled, selectedEducations, userPermission],
  );

  const closeDeleteHistoryWarning = useCallback(() => {
    setIsDeleteHistoryWarningOpen(false);
  }, []);

  const confirmDeleteHistoryWarning = useCallback(() => {
    closeDeleteHistoryWarning();
    setIsEntityRelationOpen(true);
  }, [closeDeleteHistoryWarning]);

  const closeEntityRelation = useCallback(() => {
    setIsEntityRelationOpen(false);
  }, []);

  return {
    userPermission,
    extraToolbarButtons,
    isDeleteConfirmOpen,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedEducation,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    educationIds,
  };
};

export default useController;
