import React from 'react';
import block from 'bem-cn';

import { Header } from '../types';

import './styles.scss';

const b = block('first-level-header');

type Props<CustomState> = {
  isTableExtendable: boolean;
  isTableExtended: boolean;
  customState: CustomState;
} & Header<CustomState>['firstLevel'];

export const FirstLevelHeader = <CustomState extends {}>({
  title,
  isTableExtendable,
  isTableExtended,
  Panel,
  customState,
}: Props<CustomState>) => {
  return (
    <>
      {(title || Panel) && (
        <div className={b()}>
          {title && (
            <h2 className={b('title')}>
              {title}
              {isTableExtendable ? `${isTableExtended ? ' (режим для анализа)' : ' (режим ввода)'}` : ''}
            </h2>
          )}
          {!!Panel && (
            <div className={b('panel')}>
              <Panel customState={customState} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
