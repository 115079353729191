import { Table } from 'types/models';

export function GetTenderListForReport(): Table.Specification {
  return {
    apiID: 'GetTenderListForReport',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
