import { Table } from 'types/models';
import { Filters } from './filters';

type Args = {
  subjectId: string;
};

type RequestData = {
  subjectId: string;
};

export function GetSubjectExpositionList({ subjectId }: Args): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetSubjectExpositionList',
    header: {
      firstLevel: {
        title: 'Участие и награды в выставках',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { subjectId },
  };
}
