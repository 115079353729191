import React from 'react';

import { ConfirmPopup, FormComponent, Checkbox, ListEdit, Radio, TextInput, TextInputMode } from 'components';

import { Project } from 'types/models';
import { formatNumber, getEnumItemLabel } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import useController from './controller';
import { Fields } from './Fields/Fields';
import { getMockStage } from 'features/Form/looks/project/ProjectForm/helpers';

import { validate } from './validate';
import { getPerformerNameByIndex } from './helpers';
import { PerformerCheckedRole, Period } from './type';
import { ProjectFinancingType } from 'utils/Enums';

type Props = {
  disabled: boolean;
  project: Project.Project;
  stages: Project.Stage[];
  financings: Project.Financing[];
  performers: Project.Performer[];
  setStages(stages: Project.Stage[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  setFinancings(financings: Project.Financing[]): void;
  setPerformers(performers: Project.Performer[]): void;
  isEditableLocked: boolean;
};

export function Stages(props: Props) {
  const {
    disabled,
    stages,
    setStages,
    financings,
    isEditableLocked,
    setFinancingsByYear,
    setFinancings,
    setPerformers,
    project,
    performers,
  } = props;
  const { settings } = useAppDataContext();

  const {
    isWarningPopupOpened,
    performersCopyInfo,
    isAddStageToPerformerOpen,
    isGeneratePlanOpen,
    extraToolbarButtons,
    closeGeneratePlan,
    submitCalendarPlan,
    onStagesChange,
    ndsPercentFormatter,
    totalNdsFormatter,
    onStagePreSubmit,
    openWarningPopup,
    onStagePreDelete,
    closeWarningPopup,
    makeChangeIsCheckedHandler,
    onCloseAddStageToPerformer,
    onConfirmAddStageToPerformer,
    periodDuration,
    setPeriodDuration,
    financingValues,
    setFinancingValues,
    editableFinancings,
  } = useController({
    project,
    stages,
    financings,
    performers,
    setStages,
    setFinancingsByYear,
    setFinancings,
    setPerformers,
  });
  const { enumMap } = useAppDataContext();
  return (
    <>
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        rows={stages}
        extraToolbarButtons={extraToolbarButtons}
        getIsDeleteDisabled={row =>
          project.stateRegistrationReports.some(report =>
            row?.id ? report.stageId === row?.id : report.stageNumber === row?.number,
          )
        }
        onChange={onStagesChange}
        defaultRowsCount={3}
        maxHeight="350px"
        columnIndexesForSumTotal={[2, 3, 4, 5, 6, 8]}
        columns={[
          { label: 'Этап', formatValue: x => x.number, styles: { width: '50px', textAlign: 'center' } },
          { label: 'Период', formatValue: x => `${x.startDate} - ${x.endDate}`, styles: { width: '18%' } },
          { label: 'ОИФ', formatValue: x => formatNumber(x.amountMain), styles: { width: '12%', textAlign: 'right' } },
          { label: 'СФ1', formatValue: x => formatNumber(x.amountCofinancing1), styles: { width: '12%', textAlign: 'right' } },
          { label: 'СФ2', formatValue: x => formatNumber(x.amountCofinancing2), styles: { width: '10%', textAlign: 'right' } },
          {
            label: settings?.organization?.shortName || '',
            formatValue: x => formatNumber(x.amountLocal),
            styles: { width: '10%', textAlign: 'right' },
          },
          { label: 'Партнёр', formatValue: x => formatNumber(x.amountAccomplice), styles: { width: '10%', textAlign: 'right' } },
          { label: 'НДС, %', formatValue: ndsPercentFormatter, styles: { width: '10%', textAlign: 'right' } },
          { label: 'НДС, руб', formatValue: totalNdsFormatter, styles: { width: '10%', textAlign: 'right' } },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (stage, setStage, _, mode) => (
            <Fields stage={stage || getMockStage()} setStage={setStage} financings={financings} mode={mode} />
          ),
          onPreSubmit: onStagePreSubmit,
          onPreEdit: (row, continueEdit) => {
            const isRowLocked = row?.isLocked;
            if (isRowLocked && !isEditableLocked) {
              openWarningPopup();
            } else {
              continueEdit();
            }
          },
          onPreDelete: onStagePreDelete,
          validation: {
            checkIsValid: (stage, index, list, mode) =>
              validate(stage, list, index, financings, mode, settings).every(x => x.isValid),
            onInvalidate: (stage, mode, index, list) => {
              const validationInfo = validate(stage, list, index, financings, mode, settings);
              if (validationInfo.some(x => !x.isValid)) {
                showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
              }
            },
          },
        }}
      />
      <ConfirmPopup
        title="Предупреждение"
        text="Период закрыт, для внесения изменений в данном периоде обратитесь к начальнику отдела сопровождения НИОКР"
        isOpen={isWarningPopupOpened}
        resetButtonText="Закрыть"
        onClose={closeWarningPopup}
        onConfirm={() => {}}
        icon="warning"
      />
      <ConfirmPopup
        title="Обновление этапов"
        isOpen={isAddStageToPerformerOpen}
        okButtonText="Да"
        resetButtonText="Нет"
        icon="info"
        onClose={onCloseAddStageToPerformer}
        onConfirm={onConfirmAddStageToPerformer}
      >
        <div>
          <FormComponent.Line>
            <FormComponent.Field>
              <span>Добавить в новый этап участников коллектива?</span>
            </FormComponent.Field>
          </FormComponent.Line>
          {performersCopyInfo.leader !== null && (
            <FormComponent.Line>
              <FormComponent.Field>
                <Checkbox
                  label={`Руководитель (${getPerformerNameByIndex({ performers, index: performersCopyInfo.leader })})`}
                  checked={performersCopyInfo.isLeaderChecked}
                  onChange={makeChangeIsCheckedHandler(PerformerCheckedRole.LEADER)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {performersCopyInfo.responsiblePerformer !== null && (
            <FormComponent.Line>
              <FormComponent.Field>
                <Checkbox
                  label={`Ответственный исполнитель ${getPerformerNameByIndex({
                    performers,
                    index: performersCopyInfo.responsiblePerformer,
                  })}}`}
                  checked={performersCopyInfo.isResponsiblePerformerChecked}
                  onChange={makeChangeIsCheckedHandler(PerformerCheckedRole.RESPONSIBLE_PERFORMER)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {Boolean(performersCopyInfo.restPerformers.length) && (
            <FormComponent.Line>
              <FormComponent.Field>
                <Checkbox
                  label="Остальные участники"
                  checked={performersCopyInfo.isRestPerformersChecked}
                  onChange={makeChangeIsCheckedHandler(PerformerCheckedRole.REST_PERFORMERS)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
        </div>
      </ConfirmPopup>
      <ConfirmPopup
        isOpen={isGeneratePlanOpen}
        onClose={closeGeneratePlan}
        title="Процедура формирования календарного плана"
        okButtonText="Продолжить"
        onConfirm={submitCalendarPlan}
        resetButtonText="Отмена"
        onReset={closeGeneratePlan}
      >
        <>
          <FormComponent.Line>
            <FormComponent.Field>
              <strong>Выберите длительность этапа и укажите сумму по умолчанию</strong>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Длительность этапа" hasLeftAlign>
              <Radio
                value={periodDuration}
                list={[
                  { value: Period.year, label: 'Год' },
                  { value: Period.halfYear, label: 'Полгода' },
                  { value: Period.quarter, label: 'Квартал' },
                ]}
                onChange={x => setPeriodDuration(x as Period)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {!!editableFinancings.length && (
            <>
              <FormComponent.Line>
                <FormComponent.Field>
                  {editableFinancings.map(x => (
                    <FormComponent.Field
                      label={getEnumItemLabel('ProjectFinancingType', x, enumMap)}
                      key={x}
                      labelWidth={330}
                      hasLeftAlign
                    >
                      <TextInput
                        mode={TextInputMode.number}
                        value={String(financingValues[x as ProjectFinancingType])}
                        onChange={e => setFinancingValues(prevState => ({ ...prevState, [x]: e || '0' }))}
                      />
                    </FormComponent.Field>
                  ))}
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}

          <FormComponent.Line>
            <FormComponent.Field>
              <span>
                <strong>Внимание:</strong> этапы будут добавлены для руководителя проекта
              </span>
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      </ConfirmPopup>
    </>
  );
}
