import { PurchaseRequest } from 'types/models/PurchaseRequest';

export function getMockPurchaseRequest(): PurchaseRequest {
  return {
    id: null,
    sendDate: null,
    status: null,
    applicant: null,
    contractKind: null,
    documents: [],
    name: '',
    project: null,
    units: [],
  };
}
