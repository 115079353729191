import React from 'react';

import { DataGrid } from 'features/Table';
import { GetQnaQuestionList } from 'features/Table/specifications';
import { FormComponent } from 'components';

export function GetQuestion() {
  return (
    <FormComponent.Template>
      <FormComponent.Description mode="warning">
        Если Вы не нашли ответ в рубрике &quot;Часто задаваемые вопросы&quot;, Вы можете задать свой вопрос администратору
        системы.
      </FormComponent.Description>

      <DataGrid specification={GetQnaQuestionList()} />
    </FormComponent.Template>
  );
}
