export enum Color {
  primary = 'primary', // primary system
  success = 'success', // state
  warning = 'warning', // state
  danger = 'danger', // state
  info = 'info', // state
  accent = 'accent', // state
}

export enum ColorBase {
  dark = 'dark', // black
  default = 'default', // gray
  clear = 'clear', // white
}
