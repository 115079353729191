import { useState } from 'react';
import { useStream } from 'StreamRx';

import { Notification } from './index';
import { v4 as uuidv4 } from 'uuid';
import { useLocalNotificationsStreams } from './hooks';

type ExtendedNotification = Notification & {
  id: string;
  isHide: boolean;
};

export function useController() {
  const [notifications, setNotifications] = useState<ExtendedNotification[]>([]);
  const hideTime: number = 0.5;

  const streams = useLocalNotificationsStreams();

  const removeNotification = (id: string, delay: number) => {
    const action = (idAction: string, type: 'hide' | 'remove', delayAction: number) => {
      setTimeout(() => {
        setNotifications(prevState => {
          const finded = prevState.findIndex(i => i.id === idAction);
          if (finded > -1) {
            const nextState = [...prevState];
            if (type === 'hide') {
              nextState[finded].isHide = true;
            }
            if (type === 'remove') {
              nextState.splice(finded, 1);
            }
            return nextState;
          }
          return prevState;
        });
      }, delayAction * 1000);
    };

    action(id, 'hide', delay - hideTime);
    action(id, 'remove', delay);
  };

  useStream(
    () => streams.addNotification,
    (notification: Notification) => {
      const id = uuidv4();

      const extendedNotification: ExtendedNotification = {
        ...notification,
        id,
        isHide: false,
      };

      setNotifications(prevState => [...prevState, extendedNotification]);

      removeNotification(id, extendedNotification.lifetime || 0);
    },
    [notifications],
  );

  const eventClick = (id: string) => {
    removeNotification(id, hideTime);
  };

  return {
    notifications,
    eventClick,
  };
}
