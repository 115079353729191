import React, { useState } from 'react';
import { setup } from 'bem-cn';

import { Icon } from 'components';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('form-collapse');

type Props = {
  title?: string;
  isCollapse?: boolean;
  children: any;
};

/**
 * Collapse component (external/internal control)
 *
 * @param title - (optional) Title for internal control
 * @param isCollapse - (optional) External control (false by default)
 * @returns JSX
 */
export const Collapse = React.memo(({ title, isCollapse = false, children }: Props) => {
  const [isActive, setIsActive] = useState(isCollapse);

  return (
    <div className={b({ expanded: !!title ? !isActive : !isCollapse })}>
      {title && (
        <div className={b('title')} onClick={() => setIsActive(!isActive)}>
          <span>{title}</span>
          <div className={b('arrow')}>
            <Icon type="chevron" size={14} />
          </div>
        </div>
      )}
      <div className={b('content')}>{children}</div>
    </div>
  );
});
