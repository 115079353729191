import React from 'react';

import { FormComponent, InputSelect, Reference } from 'components';
import { JointEvent } from 'types/models';

import { useController } from './controller';

type Props = {
  document: JointEvent.Enterprise | null;
  onChange(row: JointEvent.Enterprise): void;
};

export function EnterpriseForm({ document, onChange }: Props) {
  const { role, roles, handleChangeRole, organization, handleChangeOrganization } = useController({ document, onChange });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Роль">
        <InputSelect value={role} options={roles} onSelectChange={handleChangeRole} />
      </FormComponent.Field>
      <FormComponent.Field label="Организация">
        <Reference
          disabled={false}
          value={organization}
          relationTableModalTitle='Справочник "Организации"'
          theme="small"
          refButtonTitle='Справочник "Организации"'
          name="RefEnterprise"
          onChange={handleChangeOrganization}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}
