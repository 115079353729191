import { Community } from 'types/models';

export class CommunityType {
  static DISSERTATION_COUNCIL: Community.CommunityType = 'DISSERTATION_COUNCIL';

  static SCIENTIFIC_COUNCIL: Community.CommunityType = 'SCIENTIFIC_COUNCIL';

  static COLLABORATION: Community.CommunityType = 'COLLABORATION';

  static FUND: Community.CommunityType = 'FUND';

  static MAGAZINE_REDACTION: Community.CommunityType = 'MAGAZINE_REDACTION';
}
