import React, { useCallback, useState } from 'react';
import * as R from 'ramda';

import { buttonIcons, ListEdit } from 'components';

import { NirRequest } from 'types/models';
import { showErrors } from 'utils/Validators';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { BuildReportPopup, useReportsHook, Reports } from 'features/BuildReportPopup';
import { PortfolioCard } from 'features/Form/looks/person';

import { EditPerformerFields } from 'features/Form/looks/nirRequest/NirRequestForm/views';
import {
  formatDegreeRank,
  formatIdentifiers,
  formatIndexHirsh,
  getMockPerformer,
} from 'features/Form/looks/nirRequest/NirRequestForm/helpers';

import { validate } from './validate';

type Props = {
  performers: NirRequest.Performer[];
  setPerformers(performers: NirRequest.Performer[]): void;
  disabled: boolean;
};

export function LeadingScientist(props: Props) {
  const { performers, setPerformers, disabled } = props;

  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);
  const [selectedPerformer, setSelectedPerformer] = useState<NirRequest.Performer | null>(null);

  const { enumMap } = useAppDataContext();
  const roles = enumMap.NirRequestPerformerRole.values;

  const formatJobOrEducation = (performer: NirRequest.Performer) => {
    if (performer.job) {
      return formatJob(performer.job);
    }

    return performer.education ? formatEducation(performer.education) : '';
  };

  const onChangePerformers = useCallback(
    (updatedPerformers: NirRequest.Performer[]) => {
      const changedPerformers = performers.map(performer => {
        const updatedPerformer = updatedPerformers.find(x => x.person?.id === performer.person?.id);
        return updatedPerformer || performer;
      });
      setPerformers(changedPerformers);
    },
    [performers, setPerformers],
  );

  const closePersonModal = useCallback(() => {
    setIsOpenPersonModal(false);
    setSelectedPerformer(null);
  }, []);

  const openPersonModal = useCallback(() => {
    setIsOpenPersonModal(true);
  }, []);

  const onClickInfoIcon = useCallback(
    (performer: NirRequest.Performer | null) => {
      if (performer !== null) {
        setSelectedPerformer(performer);
        openPersonModal();
      }
    },
    [openPersonModal],
  );

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const onClickPrint = useCallback(
    (performer: NirRequest.Performer | null) => {
      setSelectedPerformer(performer);
      handleSetCurrentReport({ name: Reports.Personal.caption, value: Reports.Personal.name });
    },
    [handleSetCurrentReport],
  );

  return (
    <>
      <ListEdit
        rows={performers
          .filter(x => x.role?.value === 'LEAD_SCIENTIST')
          .sort((x, y) => (x.person?.fullName ?? '').localeCompare(y.person?.fullName ?? ''))}
        defaultRowsCount={10}
        maxHeight="60vh"
        visibleToolbarButtons={['edit']}
        isToolbarDisabled={disabled}
        extraToolbarButtons={[
          {
            icons: buttonIcons.info,
            title: 'Просмотр сведений о персоне',
            code: 'personInfo',
            checkIsDisabled: row => row === null || disabled,
            onClick: onClickInfoIcon,
          },
          {
            icons: buttonIcons.print,
            title: 'Персональный отчет',
            code: 'personReport',
            onClick: onClickPrint,
            checkIsDisabled: row => row === null || disabled,
          },
        ]}
        columns={[
          { label: 'Роль', formatValue: x => roles.find(role => role.value === x.role?.value)?.label ?? '' },
          { label: 'ФИО', formatValue: x => x.person?.fullName ?? '' },
          { label: 'Дата рождения', formatValue: x => x.person?.scientist?.dateBirth ?? '' },
          { label: 'Степень, звание', formatValue: formatDegreeRank },
          { label: 'Место работы / обучения', formatValue: formatJobOrEducation, styles: { width: '20%' } },
          { label: 'Гражданство', formatValue: x => x.citizenship?.label ?? '' },
          {
            label: 'Участие в международных научных сообществах',
            formatValue: x => x.collaborationParticipation,
            styles: { width: '20%' },
          },
          { label: 'Индексы Хирша', formatValue: formatIndexHirsh },
          { label: 'Идентификаторы', formatValue: formatIdentifiers },
          {
            label: 'Публикаций Scopus/Wos Q1, Q2',
            formatValue: x =>
              [
                x.personQ1Q2PublicationQty || '0',
                x.foreignPerformerQ1Q2PublicationQty ? `Примечание ${x.foreignPerformerQ1Q2PublicationQty}` : '',
              ]
                .filter(i => i)
                .join(', '),
          },
        ]}
        onChange={onChangePerformers}
        specification={{
          mode: 'customComponent',
          renderComponent: (performer, setPerformer) => (
            <EditPerformerFields
              disabled={disabled}
              hasTitle
              performer={performer || getMockPerformer()}
              onChangePerformer={setPerformer}
            />
          ),
          validation: {
            checkIsValid: performer => validate(performer!).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      />

      <PortfolioCard isOpen={isOpenPersonModal} id={selectedPerformer?.person?.id ?? null} onClose={closePersonModal} />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        scientistId={selectedPerformer?.person?.scientist?.id ?? ''}
      />
    </>
  );
}
