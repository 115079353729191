import React from 'react';

import { BuildReportPopup } from 'features/BuildReportPopup';

import { Toolbar as SharedToolbar } from 'components';

import { useController } from './controller';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  viewMode?: boolean;
  eventId: string;
  isDisabled: boolean;
};

function Toolbar(props: Props) {
  const { onSave, onSaveDraft, viewMode, eventId, isDisabled } = props;

  const { buttons, isReportOpen, onReportClose, currentReport } = useController({
    onSave,
    onSaveDraft,
    viewMode,
    isDisabled,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        event={{ id: eventId || '', label: `Конференция (ID ${eventId})` }}
        isEventParameterHidden
        conferenceEventId={eventId}
      />
    </>
  );
}

export { Toolbar };
