import { useCallback, useMemo } from 'react';
import * as R from 'ramda';

import { MobileRequest } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { Item } from 'types/models/common';
import { ReferenceItem } from 'components';
import { Entry } from 'types/models/Table';
import { MobileRequestFinancingType, NUFinancingSourceType } from 'utils/Enums';
import { getEnum } from 'utils/Helpers';

type Props = {
  entry: MobileRequest.Financing | null;
  onFieldChange(row: MobileRequest.Financing | null): void;
};

type Fields = keyof MobileRequest.Financing;

export function useController({ entry, onFieldChange }: Props) {
  const { enumMap } = useAppDataContext();

  const typeOptions = getEnum('MobileRequestFinancingType', enumMap);
  const sf3SourceOptions = getEnum('MobileRequestFinancingSf3Source', enumMap);

  const isSf1Type = useMemo(() => entry?.type?.value === MobileRequestFinancingType.SF1, [entry?.type?.value]);
  const isSf2Type = useMemo(() => entry?.type?.value === MobileRequestFinancingType.SF2, [entry?.type?.value]);
  const isSf3Type = useMemo(() => entry?.type?.value === MobileRequestFinancingType.SF3, [entry?.type?.value]);

  const handleTypeChange = useCallback(
    (e: Item) => {
      const fieldName: Fields = 'type';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  const handleSf1SourceChange = useCallback(
    (e: MobileRequest.Sf1Source) => {
      const fieldName: Fields = 'sf1Source';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  const handleDepartmentChange = useCallback(
    (e: ReferenceItem) => {
      const fieldName: Fields = 'department';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  const handleSf3SourceChange = useCallback(
    (e: Item) => {
      const fieldName: Fields = 'sf3Source';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  const handleAmountChange = useCallback(
    (e: string) => {
      const fieldName: Fields = 'amount';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  const handleNoteChange = useCallback(
    (e: string) => {
      const fieldName: Fields = 'note';
      onFieldChange(R.set(R.lensProp(fieldName), e, entry!));
    },
    [entry, onFieldChange],
  );

  function sf1SourceRowConverter(e: Entry): MobileRequest.Sf1Source {
    const isProjectCode = e['id:Type'] === NUFinancingSourceType.PROJECT_CODE;
    const isFundCard = e['id:Type'] === NUFinancingSourceType.FUND_CARD;
    return {
      projectCodeId: isProjectCode ? e.id : '',
      projectCodeValue: isProjectCode ? e.Label : '',
      fundCardId: isFundCard ? e.id : '',
      fundCardValue: isFundCard ? e.Label : '',
    };
  }

  return {
    typeOptions,
    sf3SourceOptions,
    handleTypeChange,
    handleSf1SourceChange,
    handleDepartmentChange,
    handleSf3SourceChange,
    handleAmountChange,
    handleNoteChange,
    isSf1Type,
    isSf2Type,
    isSf3Type,
    sf1SourceRowConverter,
  };
}
