import React, { useMemo } from 'react';

import { IconButtonProps, ConfirmPopup, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { isTSUEdition, EditRelationsModal } from 'features/EditRelationsModal';

import { Permits } from 'utils/Permissions';

import { PublicationTypeCode } from 'types/models/Form';
import * as modalTemplates from './modalTemplates';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    publication,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    fetchCompilationProcess,
    getLinks,
    handleSetCurrentReport,
    isChangeModal,
    handleCloseModal,
    currentType,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewButtonDisabled,
    handleEditButtonClick,
    handleAddButtonClick,
    handleViewButtonClick,
    handleDeleteButtonClick,
    handleApprove,
    handleEditRelations,
    handleSendToLibrary,
    getSelectedRow,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleCloseEditForm,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isDownloadPublicationFileDisabled,
    handleDownloadPublicationFile,
    isLinkButtonDisabled,
    getLinksToOpen,
    handleOpenCitationForm,
    handleCloseCitationForm,
    isCitationDisabled,
    isCitationFormOpen,
    handleArticleButtonClick,
    handleTemplateCloseArticleForm,
    isArticleButtonDisabled,
    isArticleFormOpen,
  } = useController(tableState);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
        permissionName: Permits.COMPILATION_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        permissionName: Permits.COMPILATION_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: Permits.COMPILATION_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleDeleteButtonClick,
        permissionName: Permits.COMPILATION_DELETE,
      },

      {
        icons: buttonIcons.approve,
        title: 'Утвердить',
        code: 'approve',
        isDisabled: isEditButtonDisabled,
        onClick: handleApprove,
        permissionName: Permits.COMPILATION_APPROVE,
      },
      {
        icons: buttonIcons.citation,
        title: 'Показатели сборника',
        code: 'citationSystem',
        isDisabled: isCitationDisabled,
        onClick: handleOpenCitationForm,
      },
      {
        icons: buttonIcons.info,
        title: 'Информация о статьях',
        code: 'article',
        isDisabled: isArticleButtonDisabled,
        onClick: handleArticleButtonClick,
      },
      {
        icons: buttonIcons.download,
        title: 'Скачать файл прикрепленный к сборнику',
        code: 'approvePublication',
        isDisabled: isDownloadPublicationFileDisabled,
        onClick: handleDownloadPublicationFile,
      },
      {
        icons: buttonIcons.link,
        title: 'Открыть сборник во внешнем источнике',
        code: 'openPublicationOuterSource',
        isDisabled: isLinkButtonDisabled,
        onClick: () => {},
        getExpandedList: getLinksToOpen,
      },
      {
        icons: buttonIcons.editRelations,
        title: 'Редактировать связь сборника с подразделениями',
        code: 'editRelations',
        isDisabled: (() => {
          const row = getSelectedRow();
          if (row) {
            return !isTSUEdition(row);
          }
          return true;
        })(),
        onClick: handleEditRelations,
        permissionName: Permits.COMPILATION_DEPARTMENTS_EDIT,
      },
      {
        icons: buttonIcons.sendToLibrary,
        title: 'Отправить в библиотеку',
        code: 'sendToLibrary',
        isDisabled: isEditButtonDisabled,
        onClick: handleSendToLibrary,
        permissionName: Permits.COMPILATION_EXPORT,
      },
      {
        icons: buttonIcons.mergeHistory,
        title: 'Трансформация',
        code: 'transform',
        isDisabled: !tableState.selectedRows.length,
        onClick: () => {},
        expandedItemCallback: handleSetCurrentReport,
        getExpandedList: getLinks,
        permissionName: Permits.PUBLICATION_TRANSFORM,
      },
    ],
    [
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      handleApprove,
      isDownloadPublicationFileDisabled,
      handleDownloadPublicationFile,
      handleEditRelations,
      handleSendToLibrary,
      tableState.selectedRows.length,
      handleSetCurrentReport,
      getLinks,
      getSelectedRow,
      isLinkButtonDisabled,
      getLinksToOpen,
      handleOpenCitationForm,
      isCitationDisabled,
      handleArticleButtonClick,
      isArticleButtonDisabled,
    ],
  );

  return (
    <>
      <modalTemplates.CompilationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
      />
      {!isViewButtonDisabled && (
        <modalTemplates.CompilationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isArticleButtonDisabled && (
        <modalTemplates.MagazineArticleTemplate.Component
          isOpen={isArticleFormOpen}
          onClose={handleTemplateCloseArticleForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isCitationDisabled && !!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.CompilationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <EditRelationsModal
        title="Редактирование связи сборника с подразделениями"
        isOpen={isOpenEditRelations}
        onClose={handleCloseEditRelationsModal}
        publication={publication}
      />
      {!isDeleteButtonDisabled && !!tableState.selectedRows.length && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный сборник? (${tableState.selectedRows[0].name})`}
          isOpen={isDeleteConfirmPopupOpen}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
        />
      )}
      {isChangeModal && (
        <modalTemplates.CompilationTransformationTemplate.Component
          isOpen={isChangeModal}
          onClose={handleCloseModal}
          relatedTableState={tableState}
          onSelect={fetchCompilationProcess}
          type={currentType?.name || ''}
          id={tableState.selectedRows[0]?.id || ''}
        />
      )}
      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={tableState.selectedRows[0]['id:type'] as PublicationTypeCode}
        />
      )}
      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0].id}
          publicationTypeCode={tableState.selectedRows[0]['id:type'] as PublicationTypeCode}
        />
      )}
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
