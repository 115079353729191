import React from 'react';
import { InputSelect } from 'components';
import { EnumerationValue } from 'types/models/Merge';
import useController from './controller';

type Props = {
  value: EnumerationValue | null;
  setValue: (nextValue: EnumerationValue | null) => void;
  enumerationName: string;
  isDisabled: boolean;
};

export const EnumerationSelect = ({ value, setValue, enumerationName, isDisabled }: Props) => {
  const { options } = useController({ enumerationName });

  return <InputSelect options={options} value={value} onSelectChange={setValue} disabled={isDisabled} />;
};
