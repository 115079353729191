import React, { useCallback, useEffect, useState } from 'react';

import { Button, ButtonMode, TextArea, FormComponent, Modal } from 'components';

import { showNotification } from 'features/Notifications';
import { approverKindTitleMap, PurchaseRequestApproverKind } from 'types/models/PurchaseRequest';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(message?: string, approverKind?: PurchaseRequestApproverKind | null): void;
  approverKind: PurchaseRequestApproverKind | null;
};

export function PurchaseRequestApprovementModal(props: Props) {
  const { isOpen, onClose, onSubmit, approverKind } = props;

  const [message, setMessage] = useState('');

  const handleClose = useCallback(() => {
    setMessage('');
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    const submit = () => {
      onSubmit?.(message, approverKind);
      handleClose();
    };
    if (message) {
      submit();
    } else {
      showNotification({ message: 'Для выбранной резолюции необходимо ввести комментарий', theme: 'danger' });
    }
  }, [handleClose, onSubmit, message, approverKind]);

  useEffect(() => {
    setMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={approverKind ? approverKindTitleMap[approverKind] : ''} size="large">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Замечания (не более 1000 знаков с пробелами)">
            <TextArea settings={{ rows: 4, isFixed: true }} value={message} onChange={setMessage} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Сохранить" onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
