import React from 'react';

import { Button, ButtonMode, FormComponent, Modal, TextArea } from 'components';

import { showNotification } from 'features/Notifications';

type Props = {
  isOpen: boolean;
  onClose(): void;
  comment: string;
  setComment(comment: string): void;
  onSubmit(): void;
  title: string;
  isRequired?: boolean;
};

export const CommentPopup = React.memo((props: Props) => {
  const { isRequired, isOpen, onClose, comment, title, setComment, onSubmit } = props;

  const submit = () => {
    if (!comment && isRequired) {
      showNotification({ message: 'Введите комментарий', theme: 'danger' });
      return;
    }
    onSubmit();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} size="large">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Комментарий" isRequired={isRequired}>
            <TextArea settings={{ rows: 3, isFixed: true }} value={comment} onChange={setComment} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Продолжить" onClick={submit} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
});
