import { TenderProtocol } from 'types/models/TenderProtocol';

export function validateTenderProtocol(tenderProtocol: TenderProtocol) {
  const errorMessages: string[] = [
    !tenderProtocol.tender && 'Выберите конкурс',
    !tenderProtocol.protocolCommission && 'Выберите конкурсную комиссию',
  ].filter(x => x) as string[];

  return [
    {
      isValid: !errorMessages.length,
      invalidMessage: errorMessages.join('. '),
    },
  ];
}
