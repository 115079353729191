import React, { useCallback, useState } from 'react';

import { Button, ButtonMode, buttonIcons, Checkbox, FormComponent, IconButton, Modal } from 'components';

import { Person } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  isOpen: boolean;
  onClose(val: boolean): void;
  person: Person.ScientistPerson | null;
};

function WelcomeModal(props: Props) {
  const { isOpen, onClose, person } = props;
  const [isNoMoreShowChecked, setIsNoMoreShowChecked] = useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    onClose(isNoMoreShowChecked);
  }, [isNoMoreShowChecked, onClose]);

  const { settings } = useAppDataContext();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      title={`${person?.scientist?.firstName} ${person?.scientist?.patronymic}, добро пожаловать в Личный кабинет `}
      size="medium"
    >
      <FormComponent.Wrapper>
        <>
          <ul>
            <li>Инструкции для пользователя доступны в разделе «Новости, Справочные материалы»</li>
            <li>
              Задать вопрос Администратору вы можете с помощью с помощью кнопки:&nbsp;
              <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
            </li>
            <li>Ответ на ваш запрос вы можете увидеть в разделе «Вопрос- ответ» и «Часто задаваемые вопросы»</li>
            <li>
              Краткая инструкция по каждому разделу открывается с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.toolbarHelp} hasNoHover /> на панели инструментов
            </li>
            <li>
              Добавляйте СВОИ публикации, участия в конференциях, выставках, конкурсах и творческого мероприятиях, полученные
              награды и благодарности
            </li>
            <li>
              Обращайте внимание на изменение статуса записи, так как добавленные вами записи будут рассмотрены, согласованы и
              дополнены, утверждены и рассмотрены комиссией по Стимулирующим выплатам
            </li>
            <li>Распечатывайте отчеты о результатах своей деятельности</li>
            <li>
              Подавайте свои заявки на конкурс на индивидуальную финансовую поддержку участия в программах академической
              мобильности
            </li>
            <li>Отслеживайте по статусам результаты выполнения процедур конкурсного отбора заявок</li>
          </ul>
          <p>
            &laquo;УНИД&raquo; функционирует в университете с 2013 года. Ежедневно ответственные за организацию и отчетность по
            научной и инновационной деятельности в различных подразделениях университета работают в Системе и заносят информацию о
            научных достижениях сотрудников, студентов и аспирантов {settings?.organization?.shortName}, таких как: публикации,
            результаты проведения и конференциях, выставках, конкурсах и даже творческого мероприятиях (!), полученных наградах,
            поданных заявках на выполнение НИР и ОКР, выполненных проектах, полученных патентах и свидетельствах на результаты
            научно-технической деятельности (РИД) и др
          </p>
        </>
        <FormComponent.Actions>
          <Checkbox label="Больше не показывать это окно" checked={!!isNoMoreShowChecked} onChange={setIsNoMoreShowChecked} />
          <Button mode={ButtonMode.primary} text="Начать работу" onClick={onCloseModal} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { WelcomeModal };
