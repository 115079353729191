import { useState, useCallback, useEffect, useMemo, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { useStream } from 'StreamRx';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Form, Author, Event, FileInfo, Document, RecordStatus as RS } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { ParticipationEventSource } from 'types/models/Participation';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import useSavePublicationHook from 'features/Form/hooks/savePublicationHook';
import { EventFormat } from 'utils/Enums';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { projectsStreams, mobileRequestsStream } from './streams';
import { validate } from './validate';
import { useFormContext } from 'features/Form/hooks';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { formatPaymentRequestData } from '../helpers';
import { formatToRequest } from './setup/formatToRequest';
import { setByResponse } from './setup/setByResponse';
import { ModePublicationHeader, PublicationHeader } from 'features/Form/views/ModalHeader/publication';
import { showNotification } from 'features/Notifications';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  onClose: () => void;
};

const tabsId = 'PublicationFormArticle';

export function useController({ onClose }: Props) {
  const { currentPerson, initialLanguageCode, settings, userSystemDepartment } = useAppDataContext();

  const {
    look: {
      type,
      id,
      isElectronic,
      electronicType,
      relatedTableState,
      name,
      viewMode,
      editMode,
      isRequestPaymentView = false,
      isShowDetailedTitle = false,
      filterMagazineReleaseId = '',
      handleChangeParticipation,
      setPublication,
      setPublicationConferenceSource,
      arm,
      eventId,
      disableAddParticipationAfterSave,
      setTitle,
    },
  } = useFormContext<Form.PublicationFormLook>();

  const { isProfile } = usePrivatePageContext();

  const tableStreams = useLocalTableStreams();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const [isConferenceConnection, setIsConferenceConnection] = useState(false);
  const [publicationId, setPublicationId] = useState<string | undefined>(id);
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const [sourceMagazine, setSourceMagazine] = useState<Form.Original | null>(null);
  const [sourceMagazineRelease, setSourceMagazineRelease] = useState<Form.Original | null>(null);
  const [sourceMagazineOriginal, setSourceMagazineOriginal] = useState<Form.Original | null>(null);
  const [sourceMagazineTranslates, setSourceMagazineTranslates] = useState<Form.Translation[]>([]);
  const [originalArticle, setOriginalArticle] = useState<Form.Original | null>(null);
  const [translateArticles, setTranslateArticles] = useState<Form.Translation[]>([]);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [projects, setProjects] = useState<PublicationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<Form.PublicationMobileRequest[]>([]);
  const [topMessage, setTopMessage] = useState<string>('');
  const [isSourceWarningPopupOpen, setIsSourceWarningPopupOpen] = useState<boolean>(false);
  const [isRecordElectronic, setIsRecordElectronic] = useState<boolean>(!!isElectronic);
  const [recordElectronicType, setRecordElectronicType] = useState<string>(electronicType || '');
  const [paymentString, setPaymentString] = useState('');
  const [foreignSource, setForeignSource] = useState('');
  const [sourcePublicationDate, setSourcePublicationDate] = useState('');
  const [userDepartments, setUserDepartments] = useState<ReferenceItem[]>([]);
  const [publicationDepartment, setPublicationDepartment] = useState<ReferenceItem | null>(null);
  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: name || '',
      required: true,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    annotation: {
      value: '',
      isValid: true,
      required: true,
      title: 'Аннотация',
      tooltipText: 'Если у публикации отсутствует аннотация, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, value },
        }));
      },
    },
    authorsKeyWords: {
      value: '',
      isValid: true,
      required: true,
      title: 'Авторские ключевые слова',
      tooltipText: 'Если у публикации отсутствуют ключевые слова, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsKeyWords: { ...prevState.authorsKeyWords, value },
        }));
      },
    },
    typeEdition: {
      value: '',
      isValid: true,
      title: 'SEND_DATA',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          typeEdition: { ...prevState.typeEdition, value },
        }));
      },
    },
    year: {
      value: '',
      isValid: true,
      title: 'SEND_DATA',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          year: { ...prevState.year, value },
        }));
      },
    },
    doi: {
      value: '',
      isValid: true,
      title: 'DOI',
      tooltipText: 'Цифровой идентификатор объекта, представленного в Интернете, пример заполнения: 10.1007/b136753',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, isValid: true },
        }));
      },
    },
    authorsCount: {
      value: '',
      isValid: true,
      required: true,
      title: 'Всего авторов в публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, isValid: true },
        }));
      },
    },
    fractionalScore: {
      value: '',
      isValid: true,
      required: false,
      title: 'Фракционный счёт университета',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, isValid: true },
        }));
      },
    },
    publicationLink: {
      value: '',
      isValid: true,
      title: 'Ссылка на публикацию',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationLink: { ...prevState.publicationLink, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationLink: { ...prevState.publicationLink, isValid: true },
        }));
      },
    },
    textLanguage: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Язык текста',
      required: true,
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          textLanguage: {
            ...prevState.textLanguage,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          textLanguage: { ...prevState.textLanguage, isValid: true },
        }));
      },
    },
    pageFrom: {
      value: '',
      isValid: true,
      title: 'Страницы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageFrom: { ...prevState.pageFrom, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageFrom: { ...prevState.pageFrom, isValid: true },
        }));
      },
    },
    pageTo: {
      value: '',
      isValid: true,
      title: '-',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageTo: { ...prevState.pageTo, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageTo: { ...prevState.pageTo, isValid: true },
        }));
      },
    },
    printPageCount: {
      value: '',
      isValid: true,
      title: 'Печатных листов',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, isValid: true },
        }));
      },
    },
    articleNumber: {
      value: '',
      isValid: true,
      title: 'Номер статьи',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          articleNumber: { ...prevState.articleNumber, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          articleNumber: { ...prevState.articleNumber, isValid: true },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      required: true,
      title: 'Файл',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    grnti: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'ГРНТИ',
      tooltipText:
        // eslint-disable-next-line max-len
        'Выберите коды ГРНТИ областей знаний из справочника ГРНТИ. Если код отсутствует в справочнике, добавьте его',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          grnti: { ...prevState.grnti, value },
        }));
      },
    },
    udk: {
      value: '',
      isValid: true,
      title: 'УДК',
      tooltipText: 'Универсальная десятичная классификация. Введите код УДК, который присвоен публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          udk: { ...prevState.udk, value },
        }));
      },
    },
    study: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Область науки',
      tooltipText:
        // eslint-disable-next-line max-len
        'Область науки выбрать из справочника. Если область науки отсутствует в справочнике, напишите запрос администратору на добавление области в справочник',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          study: { ...prevState.study, value },
        }));
      },
    },
    electronicMediaSource: {
      value: '',
      isValid: true,
      required: false,
      title: 'Электронный носитель',
      tooltipText: 'Например: 1 электрон. опт диск (CD-R)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicMediaSource: {
            ...prevState.electronicMediaSource,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicMediaSource: {
            ...prevState.electronicMediaSource,
            isValid: true,
          },
        }));
      },
    },
    electronicSystemRequirements: {
      value: '',
      isValid: true,
      title: 'Системные требования',
      tooltipText: 'Например: Windows XP; CD-ROM; дисковод; мышь;',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicSystemRequirements: {
            ...prevState.electronicSystemRequirements,
            value,
            isValid: true,
          },
        }));
      },
    },
    foreignName: {
      value: '',
      required: false,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, isValid: true },
        }));
      },
    },
    foreignAnnotation: {
      value: '',
      required: false,
      isValid: true,
      title: 'Аннотация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, isValid: true },
        }));
      },
    },
    foreignAuthorsKeyWords: {
      value: '',
      required: false,
      isValid: true,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, isValid: true },
        }));
      },
    },
    foreignAuthors: {
      value: '',
      required: false,
      isValid: true,
      title: 'Авторы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, isValid: true },
        }));
      },
    },
  });

  const { methods: getMagazineRelease, state: getMagazineReleaseState } = BackendAPI.useBackendAPI('GetMagazineRelease');
  const { methods: getPublication, state: getPublicationState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublicationAPI, state: savePublicationAPIState } = BackendAPI.useBackendAPI('SavePublication');
  const { methods: getUserDepartments, state: getUserDepartmentsState } = BackendAPI.useBackendAPI('GetUserDepartments');

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PUBLICATION_FORM_ARTICLE', []);

  const loadSourceMagazine = useCallback(
    (original: any) => {
      if (original) {
        getPublication.callAPI(
          {
            simpleFields: {
              status: RecordStatus.DRAFT,
              typeEdition: 'LOCAL',
              type: 'MAGAZINE',
              isElectronic: false,
              electronicType: '',
            },
            attrIdFields: {},
            arrayFields: {
              translations: { translation: [] },
              events: { event: [] },
            },
            mobileRequests: {},
            projects: [],
            file: undefined,
            id: original.id,
            arm,
          },
          {
            onSuccessfullCall: (result: any) => {
              const data = result.data as Form.Publication;
              setForeignSource(data.fields.foreignName || data.fields.name || '');
              setSourceMagazineOriginal(data.original);
              setSourceMagazineTranslates(data.translations);
              formFields.typeEdition.onChange({
                id: data.fields.typeEditionCode,
                label: data.fields.typeEditionText,
              });
            },
          },
        );
      } else {
        setSourceMagazineOriginal(null);
        setSourceMagazineTranslates([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceMagazineRelease],
  );
  const changeSourceMagazine = useCallback(
    (val: Form.Original | null) => {
      setSourceMagazine(val);
      setSourceMagazineRelease(null);
      setMagazineReleaseConference([]);
      setMagazineReleaseConferenceSource(null);
      setSourcePublicationDate('');
      setMagazineReleaseReport([]);
      loadSourceMagazine(val);
      setOriginalArticle(null);
      setTranslateArticles([]);
    },
    [loadSourceMagazine],
  );
  const [magazineReleaseReport, setMagazineReleaseReport] = useState<string[]>([]);
  const [magazineReleaseConference, setMagazineReleaseConference] = useState<string[]>([]);
  const [magazineReleaseConferenceSource, setMagazineReleaseConferenceSource] = useState<ParticipationEventSource | null>(null);
  const fetchMagazineRelease = useCallback(
    async (originaLId: string) => {
      getMagazineRelease.callAPI(
        { releaseId: originaLId },
        {
          onSuccessfullCall: ({ data }: any) => {
            if (data.success) {
              const conferences: string[] = data.body?.conferences.map(
                (i: Form.Conference) => `${i.name} (ID ${i.id}. Статус: ${i.status || 'нет'})`,
              );
              formFields.year.onChange(data.body.Year);

              if (data.body?.conferences?.[0]) {
                setMagazineReleaseConferenceSource({
                  id: data.body.conferences[0].id,
                  name: data.body.conferences[0].name,
                  fullName: data.body.conferences[0].name,
                  status: data.body.conferences[0].status,
                  entity: null,
                  startDate: '',
                  endDate: '',
                  eventStatus: null,
                  format: EventFormat.OFFLINE,
                });
              }
              setMagazineReleaseConference(conferences);
              setSourcePublicationDate(data.body.PublicationDate ?? '');
            }
          },
        },
      );
    },
    [formFields.year, getMagazineRelease],
  );
  const changeSourceMagazineRelease = useCallback(
    (original: Form.Original | null) => {
      setSourceMagazineRelease(original);
      if (original) {
        fetchMagazineRelease(original?.id || '');
      } else {
        setMagazineReleaseConference([]);
        setMagazineReleaseConferenceSource(null);
        setSourcePublicationDate('');
        setMagazineReleaseReport([]);
      }
    },
    [fetchMagazineRelease, setSourceMagazineRelease],
  );

  const fetchPublication = useCallback(() => {
    if (type) {
      getPublication.callAPI(
        {
          simpleFields: {
            status: RecordStatus.DRAFT,
            typeEdition: 'LOCAL',
            type,
            isElectronic: isRecordElectronic,
            electronicType: (!!isRecordElectronic && recordElectronicType) || '',
            presetAuthorCategory: arm === 'pc' ? 'author' : '',
          },
          attrIdFields: {},
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          mobileRequests: { mobileRequest: [] },
          projects: [],
          file: undefined,
          id: publicationId,
          arm,
        },
        {
          onSuccessfullCall: (result: any) => {
            const preparedData = result.data as Form.Publication;

            setIsRecordElectronic(preparedData.fields.isElectronic === 'true');
            setRecordElectronicType(preparedData.fields.electronicType);
            const reporter = preparedData.participations?.map(
              (i: Event.EventParticipation) =>
                ` ${i.reportTheme ? `${i.reportTheme}.` : ''}${i.reportType ? ` Тип доклада: ${i.reportType}.` : ''}${
                  i.reportDate ? ` ${i.reportDate}.` : ''
                }${i.reporter ? ` Основной докладчик: ${i.reporter}.` : ''}`,
            );
            changeSourceMagazine(preparedData.magazine || null);
            changeSourceMagazineRelease(preparedData.magazineRelease || null);
            setMagazineReleaseReport(reporter);
            setByResponse({ setFormFields, preparedData, name });
            setPaymentString(
              preparedData.paymentRequest ? preparedData.paymentRequest.map(i => formatPaymentRequestData(i)).join(', ') : '',
            );

            setMobileRequests(preparedData.mobileRequests);
            setProjects(preparedData.projects);
            setAuthors(preparedData.members);
            setDocuments(preparedData.documents);
            setOriginalArticle(preparedData.original);
            setTranslateArticles(preparedData.translations);
            ReactDOM.unstable_batchedUpdates(() => {
              setPublicationInfo(preparedData);
            });
          },
        },
      );
    }
  }, [
    type,
    getPublication,
    isRecordElectronic,
    recordElectronicType,
    arm,
    publicationId,
    changeSourceMagazine,
    changeSourceMagazineRelease,
    name,
  ]);

  const prepareFormFields = useCallback(() => {
    // const isAuthorsCountEmpty = !formFields.authorsCount.value;
    const preparedFormFields = R.clone(formFields);
    /* Удалено в рамках 543, чтобы отключить автоматический подсчет авторов
    if (isAuthorsCountEmpty && authors.length) {
      const nextValue = authors.length;
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        authorsCount: { ...prevState.authorsCount, value: nextValue },
      }));

      preparedFormFields.authorsCount.value = nextValue;
    }
    */
    const draftValidationResult = validate({
      formFields: preparedFormFields,
      isDraft: true,
      authors,
      sourceMagazine,
      sourceMagazineRelease,
    });

    const validationResult = validate({
      formFields: preparedFormFields,
      isDraft: false,
      authors,
      sourceMagazine,
      sourceMagazineRelease,
    });

    return {
      draftValidationResult,
      validationResult,
      preparedFormFields,
    };
  }, [authors, formFields, sourceMagazine, sourceMagazineRelease]);

  const fetchBibliographicRecord = useCallback<Form.FetchBibliographicRecordAction>(
    () =>
      new Promise(resolve => {
        if (!formFields.name.value) {
          showNotification({ theme: 'danger', message: 'Не заполнено название' });
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            name: { ...prevState.name, isValid: false },
          }));
          resolve(null);

          return;
        }
        const fieldsForRequest = formatToRequest({
          isElectronic: isRecordElectronic,
          electronicType: recordElectronicType,
          formFields,
          projects,
          mobileRequests,
          sourceMagazine,
          sourceMagazineRelease,
          type,
          authors,
          status: 'ADDED',
          original: originalArticle,
          translates: translateArticles,
        });
        getPublication.callAPI(
          {
            ...fieldsForRequest,
            isBibliographicFetch: true,
            arm,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                bibliographicRecord: { ...prevState.bibliographicRecord, value: data.fields.bibliographicRecord },
              }));
              resolve(data.fields.bibliographicRecord);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [
      formFields,
      isRecordElectronic,
      recordElectronicType,
      projects,
      mobileRequests,
      sourceMagazine,
      sourceMagazineRelease,
      type,
      authors,
      originalArticle,
      translateArticles,
      getPublication,
      arm,
    ],
  );

  const savePublication = useCallback(
    ({ preparedFormFields, nextStatus, publicationMessage, publicationMessageType, isNeedClose }: Form.SavePublicationData) => {
      const isMessage = !!publicationMessageType?.id;
      const currentFormFields = preparedFormFields || formFields;
      const fieldsForRequest = formatToRequest({
        isElectronic: isRecordElectronic,
        electronicType: recordElectronicType,
        formFields: currentFormFields,
        projects,
        mobileRequests,
        sourceMagazine,
        sourceMagazineRelease,
        type,
        authors,
        status: nextStatus,
        original: originalArticle,
        translates: translateArticles,
      });
      savePublicationAPI.callAPI(
        {
          id: publicationId,
          ...fieldsForRequest,
          documents,

          feedLine: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
          arm,
          department: publicationDepartment,
        },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.success) {
              setPublicationId(data.status.id);
              if (isNeedClose) {
                if (!isRequestPaymentView) {
                  const isCanCreateParticipation =
                    magazineReleaseConferenceSource &&
                    !magazineReleaseReport.length &&
                    !publicationInfo?.participations.length &&
                    !disableAddParticipationAfterSave;
                  if (isCanCreateParticipation) {
                    if (setPublication && setPublicationConferenceSource) {
                      setPublication({
                        id: data.status.id,
                        name: formFields.name.value,
                      });
                      setPublicationConferenceSource(magazineReleaseConferenceSource);
                    }
                    setIsConferenceConnection(true);
                  } else {
                    onClose();
                    tableStreams.reloadTable.push();
                  }
                }
              } else {
                updateWorkModeAfterSaveAndContinue();
                if (workMode === 'editMode') {
                  fetchPublication();
                }
              }
            }

            showNotification({
              theme: data.success ? 'success' : 'danger',
              message: publicationId
                ? data.success
                  ? nextStatus === RecordStatus.DRAFT
                    ? 'Черновик успешно сохранен!'
                    : 'Публикация успешно отредактирована!'
                  : data.message || 'Произошла ошибка при редактировании публикации!'
                : data.success
                ? nextStatus === RecordStatus.DRAFT
                  ? 'Черновик успешно сохранен!'
                  : 'Публикация успешно сохранена!'
                : data.message || 'Произошла ошибка при сохранении публикации!',
            });
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      formFields,
      isRecordElectronic,
      recordElectronicType,
      projects,
      mobileRequests,
      sourceMagazine,
      sourceMagazineRelease,
      type,
      authors,
      originalArticle,
      translateArticles,
      savePublicationAPI,
      publicationId,
      documents,
      arm,
      publicationDepartment,
      isRequestPaymentView,
      magazineReleaseConferenceSource,
      magazineReleaseReport,
      publicationInfo?.participations.length,
      disableAddParticipationAfterSave,
      setPublication,
      setPublicationConferenceSource,
      onClose,
      tableStreams.reloadTable,
      updateWorkModeAfterSaveAndContinue,
      workMode,
      fetchPublication,
    ],
  );

  const startCheckDuplicates = useCallback(
    ({ preparedFormFields, nextStatus, isNeedClose }: Form.StartCheckDuplicatesData) => {
      const successfullCallback = ({
        publicationMessage,
        publicationMessageType,
      }: {
        publicationMessage: string;
        publicationMessageType: ReferenceItem;
      }) => {
        savePublication({
          preparedFormFields,
          publicationMessage,
          publicationMessageType,
          isNeedClose,
          nextStatus,
        });
      };

      suchLikePublicationStreams.getSuchLikePublications.push({
        category: 'PUBLICATION',
        searchValue: formFields.name.value as string,
        successfullCallback,
        publicationType: publicationInfo?.type as Form.PublicationTypeCode,
        currentId: publicationId || null,
        isDefaultSave: !isNeedClose,
        componentId: SUCH_LIKE_PUBLICATION_ID,
      });
    },
    [
      publicationInfo?.type,
      SUCH_LIKE_PUBLICATION_ID,
      formFields.name?.value,
      publicationId,
      savePublication,
      suchLikePublicationStreams.getSuchLikePublications,
    ],
  );

  const {
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    loadPersonAuthorState,
  } = useSavePublicationHook({
    currentStatus: (publicationInfo?.status?.status as RS.Code) || RecordStatus.DRAFT,
    currentStatusText: publicationInfo?.status.text || 'Черновик',
    setFormFields,
    prepareFormFields,
    startCheckDuplicates,
    savePublication,
    fetchBibliographicRecord,
    initialLanguageCode: initialLanguageCode?.id ?? '',
    authors,
    setAuthors,
    isProfile,
    currentPerson,
    type,
    tabsId,
    authorsTabIndex: 1,
  });

  const { isLoading } = useIsLoading({
    callStates: [
      getPublicationState,
      savePublicationAPIState,
      getMagazineReleaseState,
      loadPersonAuthorState,
      getUserDepartmentsState,
    ],
  });

  const onCreateBibliographicRecordClick = useCallback(() => {
    fetchBibliographicRecord();
  }, [fetchBibliographicRecord]);

  useEffect(() => {
    if (publicationId || workMode === 'addMode') fetchPublication();
    // eslint-disable-next-line
  }, [publicationId]);

  const onDeleteSourceMagazine = () => {
    setSourceMagazine(null);
  };

  const onDeleteSourceMagazineRelease = () => {
    setSourceMagazineRelease(null);
    setSourcePublicationDate('');
    setMagazineReleaseConference([]);
    setMagazineReleaseConferenceSource(null);
    setSourcePublicationDate('');
    setMagazineReleaseReport([]);
  };

  const addCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        const citationSystemIndex = publicationInfo.citationSystems.findIndex(
          citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
        );
        const isNewCitationSystem = citationSystemIndex === -1;

        if (isNewCitationSystem) {
          setPublicationInfo(prevState => ({
            ...prevState!,
            citationSystems: [...prevState!.citationSystems, citationSystem],
          }));
        } else {
          setPublicationInfo(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState!.citationSystems));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return {
              ...prevState!,
              citationSystems: nextCitationSystems,
            };
          });
        }
      }
    },
    [publicationInfo],
  );

  const editCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      const citationSystemToUpdateIndex = (publicationInfo?.citationSystems || []).findIndex(
        citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
      );

      if (citationSystemToUpdateIndex !== -1) {
        const clonedCitationSystems: Form.CitationSystem[] = publicationInfo?.citationSystems
          ? R.clone(publicationInfo.citationSystems)
          : [];
        clonedCitationSystems.splice(citationSystemToUpdateIndex, 1, citationSystem);

        setPublicationInfo(prevState => prevState && { ...prevState!, citationSystems: clonedCitationSystems });
      }
    },
    [publicationInfo?.citationSystems],
  );

  const removeCitationSystem = useCallback(
    (citationSystemId: string) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        setPublicationInfo(prevState => ({
          ...prevState!,
          citationSystems: [
            ...prevState!.citationSystems.filter(
              (system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId,
            ),
          ],
        }));
      }
    },
    [publicationInfo],
  );

  useStream(
    () => projectsStreams.setProjects,
    (projectsData: Array<PublicationProject>) => setProjects(projectsData),
    [projects, setProjects, projectsStreams.setProjects],
  );

  useStream(
    () => projectsStreams.accept,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy,
        accepted,
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.accept, currentPerson],
  );

  useStream(
    () => projectsStreams.cancelAcceptance,
    (projectId: string) => {
      const projectIndex = projects.findIndex(project => project.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        acceptedBy: null,
        accepted: '',
      };
      setProjects([...projects]);
    },
    [projects, setProjects, projectsStreams.cancelAcceptance],
  );

  useStream(
    () => mobileRequestsStream.setMobileRequests,
    (requests: Array<PublicationMobileRequest>) => setMobileRequests(requests),
    [mobileRequests, setMobileRequests, mobileRequestsStream.setMobileRequests],
  );

  useStream(
    () => mobileRequestsStream.accept,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      const accepted = new Date().toLocaleString().replace(',', '');
      const acceptedBy = currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null;
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy,
        accepted,
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.accept, currentPerson],
  );

  useStream(
    () => mobileRequestsStream.cancelAcceptance,
    (requestId: string) => {
      const requestIndex = mobileRequests.findIndex(req => req.mobileRequest?.id === requestId);
      mobileRequests[requestIndex] = {
        ...mobileRequests[requestIndex],
        acceptedBy: null,
        accepted: '',
      };
      setMobileRequests([...mobileRequests]);
    },
    [mobileRequests, setMobileRequests, mobileRequestsStream.cancelAcceptance],
  );
  const handleCloseConferenceConnectionModal = () => {
    setIsConferenceConnection(false);
    onClose();
    tableStreams.reloadTable.push();
  };
  const handleOpenAddConferenceParticipation = () => {
    onClose();
    setIsConferenceConnection(false);
    handleChangeParticipation!();
  };

  const [isPublicationOriginalTranslatesRelationsOpen, setIsPublicationOriginalTranslatesRelationsOpen] = useState(false);
  const handleClosePublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(false);
  }, []);

  const handleCloseSourceWarningPopup = useCallback(() => {
    setIsSourceWarningPopupOpen(false);
  }, []);

  const handleOpenPublicationOriginalTranslatesRelations = useCallback(() => {
    if (sourceMagazine) {
      setIsPublicationOriginalTranslatesRelationsOpen(true);
    } else {
      setIsSourceWarningPopupOpen(true);
    }
  }, [sourceMagazine]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        PublicationHeader({
          settings,
          mode: ModePublicationHeader.default,
          workMode,
          publicationId: publicationInfo?.status.id || '',
          publicationTypeCode: publicationInfo?.type || '',
          publicationElectronicTypeCode: isElectronic ? electronicType : '',
          departments: publicationInfo?.departments || [],
          status: publicationInfo?.status.text || '',
          publicationTypeEdition: {
            id: publicationInfo?.fields.typeEditionCode || '',
            label: publicationInfo?.fields.typeEditionText || '',
          },
          isPreprint: false,
        }),
      );
    }
  }, [
    electronicType,
    isElectronic,
    publicationInfo?.departments,
    publicationInfo?.fields.typeEditionCode,
    publicationInfo?.fields.typeEditionText,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
    workMode,
  ]);

  useLayoutEffect(() => {
    if (!id)
      getUserDepartments.callAPI(
        { isParent: false },
        {
          onSuccessfullCall: ({ data }) => {
            const d = data.map(x => x.ref);
            setUserDepartments(d);
            setPublicationDepartment(d.find(x => x.id === userSystemDepartment?.id) || null);
          },
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    eventId,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    workMode,
    formFields,
    authors,
    setAuthors,
    documents,
    setDocuments,
    onCreateBibliographicRecordClick,
    isElectronic: isRecordElectronic,
    electronicType: recordElectronicType,
    sourceMagazine,
    sourceMagazineRelease,
    relatedTableState,
    changeSourceMagazine,
    changeSourceMagazineRelease,
    onDeleteSourceMagazine,
    onDeleteSourceMagazineRelease,
    publicationId,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    projects,
    mobileRequests,
    topMessage,
    setTopMessage,
    sourceMagazineOriginal,
    sourceMagazineTranslates,
    originalArticle,
    translateArticles,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    handleOpenPublicationOriginalTranslatesRelations,
    isRequestPaymentView,
    isShowDetailedTitle,
    magazineReleaseReport,
    magazineReleaseConference,
    paymentString,
    foreignSource,
    filterMagazineReleaseId,
    sourcePublicationDate,
    isConferenceConnection,
    handleCloseConferenceConnectionModal,
    handleOpenAddConferenceParticipation,
    isSourceWarningPopupOpen,
    setOriginalArticle,
    setTranslateArticles,
    handleCloseSourceWarningPopup,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
  };
}
