import React from 'react';
import { ListEdit } from 'components';
import { MipPartner } from 'types/models';
import { getMockIndicator } from 'features/EditMipPartner/helpers';
import { showErrorsMessages } from 'utils/Common';
import { Fields } from './Fields';
import { validateMipIndicator } from './validate';

type Props = {
  indicators: MipPartner.Indicator[];
  onIndicatorsChange: (indicators: MipPartner.Indicator[]) => void;
  disabled: boolean;
};

export const Indicators = ({ indicators, onIndicatorsChange, disabled }: Props) => (
  <ListEdit
    title="Годовые показатели результативности МИП"
    withMessages
    isDeleteConfirmEnabled
    rows={indicators}
    onChange={onIndicatorsChange}
    isToolbarDisabled={disabled}
    defaultRowsCount={15}
    maxHeight="none"
    columnIndexesForSumTotal={[1, 2, 3]}
    columnIndexesForIntegerTotal={[1]}
    columns={[
      { label: 'Год', formatValue: x => x.year },
      { label: 'Среднесписочная численность, чел', formatValue: x => x.averageHeadcount },
      { label: 'Чистая прибыль', formatValue: x => x.revenue },
      { label: 'Выручка', formatValue: x => x.profit },
      { label: 'Дата сдачи отчёта', formatValue: x => x.reportDate || '' },
    ]}
    specification={{
      mode: 'customComponent',
      renderComponent: (indicator, setIndicator) => (
        <Fields indicator={indicator ?? getMockIndicator()} setIndicator={setIndicator} />
      ),
      validation: {
        checkIsValid: (indicator, index, list, mode) => validateMipIndicator(indicator, index, list, mode).every(x => x.isValid),
        onInvalidate: (indicator, mode, index, list) => {
          const validationInfo = validateMipIndicator(indicator, index, list, mode);
          showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
        },
      },
    }}
  />
);
