import React, { useRef, useState } from 'react';

import { Divider, TextInputMode } from 'components';

import { Props as CheckboxProps, Checkbox } from 'components/Checkbox';
import { Props as RadioProps, Radio, RadioItem } from 'components/Radio';
import { Props as TextProps, TextInput } from 'components/TextInput';
import { Props as SelectProps, Option as SelectOption, InputSelect } from 'components/InputSelect/InputSelect';
import { Props as SliderProps, Slider } from 'components/Slider';
import { Props as TooltipProps, Tooltip } from 'components/Tooltip';

import { useStream } from 'StreamRx';
import { useLocalStreams, useLocalTableStreams } from 'features/Table/hooks';
import { streams as globalStreams } from 'features/Table/streams';

import './styles.scss';

/**
 * Enum of FilterComponentType
 *
 * @readonly
 * @param {string} constant - Constant param
 * @param {string} checkbox - Checkbox only
 * @param {string} radio - Radio group
 * @param {string} divider - Divider isVertical isFlex (hidden for FilterMode === 'personal')
 * @param {string} text - Raw text for span
 * @param {string} input - Simple text field
 * @param {string} year - Year text field (with checkbox)
 * @param {string} select - Select -> Options field
 * @param {string} slider - Slider field
 * @param {string} tooltip - Tooltip icon with bubble
 */
export enum FilterComponentType {
  /** Constant param () */
  constant = 'constant',
  /** Checkbox only */
  checkbox = 'checkbox',
  /** Radio group */
  radio = 'radio',
  /** Divider isVertical isFlex (hidden for FilterMode === 'personal') */
  divider = 'divider',
  /** Raw text for span */
  text = 'text',
  /** Simple text field */
  input = 'input',
  /** Year text field (with checkbox) */
  year = 'year',
  /** Select -> Options field */
  select = 'select',
  /** Slider field */
  slider = 'slider',
  /** Tooltip icon with bubble */
  tooltip = 'tooltip',
}

/**
 * Type of tableStreams.___.push() param
 */
type FilterComponentValue = string | number | boolean;

/**
 * Settings for filter component
 */
export type FilterComponent = {
  /** @property {FilterComponentType} type - (required) Type of filter */
  type: FilterComponentType;
  /** @property {string} streamKey - Key for stream RxJs (returned to panelState) */
  streamKey?: string;
} & (
  | {
      type: FilterComponentType.constant;
      streamKey: string;
      /** @property {FilterComponentValue} value - Value for constant */
      value: FilterComponentValue;
    }
  | ({
      type: FilterComponentType.checkbox;
      streamKey: string;
      /** Properties - Nested from Checkbox */
    } & Pick<CheckboxProps, 'label' | 'checked' | 'hint'>)
  | ({
      type: FilterComponentType.radio;
      streamKey: string;
      /** Properties - Nested from Radio */
    } & Pick<RadioProps, 'list' | 'value'>)
  | {
      type: FilterComponentType.divider;
    }
  | {
      type: FilterComponentType.text;
      /** @property {string} value - Default value for text field */
      value: string;
    }
  | ({
      type: FilterComponentType.input;
      streamKey: string;
      /** @property {boolean} value - (optional) Mask by year is required */
      isYearMask?: boolean;
      /** Properties - Nested from TextInput */
    } & Pick<TextProps, 'value'>)
  | ({
      type: FilterComponentType.year;
      streamKey: string;
      /** Properties - Nested from Checkbox */
    } & Pick<CheckboxProps, 'label' | 'checked'>)
  | ({
      type: FilterComponentType.select;
      streamKey: string;
      /** Properties - Nested from InputSelect */
    } & Pick<SelectProps<string>, 'options' | 'value' | 'classMixin'>)
  | ({
      type: FilterComponentType.slider;
      streamKey: string;
      /** Properties - Nested from Slider */
    } & Pick<SliderProps, 'value' | 'label' | 'params'>)
  | ({
      type: FilterComponentType.tooltip;
      /** Properties - Nested from Tooltip */
    } & Pick<TooltipProps, 'text'>)
);

/**
 * Mode of visual filters
 */
type FilterMode = 'default' | 'personal';

/**
 * Generate FirstLevelPanel for Table
 *
 * @param {FilterComponent[]} components - Array of filters
 * @param {FilterMode} mode - (optional) Mode for panel ('default' by default)
 * @returns {JSX} JSX
 */
export const FiltersComponent = (components: FilterComponent[], mode?: FilterMode) => {
  mode = mode || 'default';

  const yearLimits = { min: 1950, max: 2050 };

  type State = Record<string, FilterComponentValue>;

  // Calc default values from components
  const defaultState: State = components.reduce((acc, item) => {
    if (typeof item.streamKey === 'undefined') {
      return acc;
    }

    const value = (() => {
      const { type } = item;
      if (type === FilterComponentType.constant) {
        return item.value;
      }
      if (type === FilterComponentType.checkbox) {
        return item.checked;
      }
      if (type === FilterComponentType.radio) {
        return item.value;
      }
      if (type === FilterComponentType.input) {
        if (!!item.isYearMask) {
          return new Date().getFullYear().toString();
        }
        return item.value;
      }
      if (type === FilterComponentType.year) {
        return !!item.checked ? new Date().getFullYear().toString() : '';
      }
      if (type === FilterComponentType.select) {
        return (item.value as SelectOption).value;
      }
      if (type === FilterComponentType.slider) {
        return item.value;
      }
    })();

    return { ...acc, [item.streamKey]: value as FilterComponentValue };
  }, {});

  type ItemState = {
    value: string;
    checked: boolean;
  };

  // Streams for table and global for RxJs
  const streams = useLocalStreams(globalStreams);
  const tableStreams = useLocalTableStreams();

  // State for sending to backendAPI as panelState
  const [state, setState] = useState<State>(defaultState);
  // Inner state (not for sending)
  const itemsState = useRef<Record<string, ItemState>>({});
  // Description hint for personal mode only
  const hintDescription = useRef<string>('');

  useStream(
    () => streams.getPanelState,
    data => {
      data.onStateReceive(state);
    },
    [state],
  );

  const updateState = (updatedState: State) => {
    tableStreams.updateFirstLevelPanelState.push({ state: updatedState });
    setState(updatedState);
  };

  // Set inner state (not for sending)
  const setItemsState = (streamKey: string, itemState: ItemState) => {
    itemsState.current = {
      ...itemsState.current,
      [streamKey]: { ...itemState },
    };
  };

  const changeYearWithCheckbox = (streamKey: string, itemState: ItemState) => {
    updateState({ ...state, [streamKey]: itemState.checked ? itemState.value : '' });
    setItemsState(streamKey, itemState);
  };

  const setDescription = (items: RadioItem[], value: string) => {
    hintDescription.current = items.find(i => i.value === value)?.hint ?? '';
  };

  // Components for render
  const Components = () =>
    components.map((item: FilterComponent, index: number) => {
      const { type } = item;
      const key = `${type}_${index}`;

      if (type === FilterComponentType.checkbox) {
        return (
          <Checkbox
            key={key}
            label={item.label}
            checked={state[item.streamKey] as boolean}
            onChange={(value: boolean) => updateState({ ...state, [item.streamKey]: value })}
          />
        );
      }

      if (type === FilterComponentType.radio) {
        setDescription(item.list, state[item.streamKey] as string);
        return (
          <Radio
            key={key}
            value={state[item.streamKey] as string}
            list={item.list}
            onChange={(value: string) => {
              updateState({ ...state, [item.streamKey]: value });
              setDescription(item.list, value);
            }}
          />
        );
      }

      if (type === FilterComponentType.divider) {
        return <Divider key={key} isVertical isFlex />;
      }

      if (type === FilterComponentType.text) {
        return <span key={key}>{item.value}</span>;
      }

      if (type === FilterComponentType.input) {
        return (
          <TextInput
            {...(!!item.isYearMask
              ? {
                  type: TextInputMode.year,
                  settings: yearLimits,
                }
              : {})}
            value={state[item.streamKey] as string}
            onChange={(value: string) => updateState({ ...state, [item.streamKey]: value })}
          />
        );
      }

      if (type === FilterComponentType.year) {
        if (!itemsState.current[item.streamKey]) {
          setItemsState(item.streamKey, {
            value: new Date().getFullYear().toString(),
            checked: !!item.checked,
          });
        }

        return (
          <div key={key} className="is-year" data-checked={itemsState.current[item.streamKey].checked}>
            <Checkbox
              label={item.label}
              checked={itemsState.current[item.streamKey].checked}
              onChange={(checked: boolean) =>
                changeYearWithCheckbox(item.streamKey, { checked, value: itemsState.current[item.streamKey].value })
              }
            />
            <TextInput
              mode={TextInputMode.year}
              settings={yearLimits}
              value={itemsState.current[item.streamKey].value}
              onChange={(value: string) =>
                changeYearWithCheckbox(item.streamKey, { checked: itemsState.current[item.streamKey].checked, value })
              }
              isDisabled={!itemsState.current[item.streamKey].checked}
            />
          </div>
        );
      }

      if (type === FilterComponentType.select) {
        return (
          <InputSelect
            key={key}
            isSearchable={false}
            options={item.options}
            value={item.options.find(i => i.value === state[item.streamKey]) as SelectOption<string>}
            onSelectChange={(option: SelectOption) => updateState({ ...state, [item.streamKey]: option.value })}
            classMixin={item.classMixin}
          />
        );
      }

      if (type === FilterComponentType.slider) {
        return (
          <Slider
            key={key}
            value={state[item.streamKey] as number}
            label={item.label}
            params={item.params}
            onChange={(value: number) => updateState({ ...state, [item.streamKey]: value })}
          />
        );
      }

      if (type === FilterComponentType.tooltip) {
        return <Tooltip key={key} text={item.text} />;
      }
    });

  if (mode === 'personal') {
    return (
      <div key={mode} className={mode}>
        {!!hintDescription.current && <div className="hint">{hintDescription.current}</div>}
        {Components()}
      </div>
    );
  }

  return <>{Components()}</>;
};
