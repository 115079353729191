import { NirRequest, Report, Table } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { NirRequestStatus, NirRequestTenderType } from 'utils/Enums';
import { getNirRequestStatusButtons } from '../helpers';
import { useLocalTableStreams } from 'features/Table/hooks';
import { TenderInfo } from 'types/models/Form/look/programRequest';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { NirRequestLocalType } from '.';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;
  const status = (selectedRow?.['id:Status'] as NirRequestStatus) || null;

  const [nirRequest, setNirRequest] = useState<NirRequestLocalType | null>(null);
  const [tenderInfo, setTenderInfo] = useState<TenderInfo | null>(null);
  const [isConfirmDeleteModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isSelectTenderTypeModalOpen, setIsSelectTenderTypeModalOpen] = useState(false);
  const [isTenderModalOpen, setIsTenderModalOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  const { methods: deleteRowAPI } = BackendAPI.useBackendAPI('DeleteModel');
  const { methods: sendMessageAPI } = BackendAPI.useBackendAPI('PushNirRequestMessage');

  const reports = useMemo<Report[]>(
    () => [
      Reports.NirRequestProject,
      Reports.NirRequestLeader,
      Reports.NirRequestLeadScientist,
      Reports.NirRequestPerformers,
      Reports.NirRequestCollective,
      Reports.NirRequestEstimate,
      Reports.NirRequestPerformersList,
      Reports.NirRequestMemorandum,
      Reports.NirRequestProjectTechnicalTask,
      Reports.NirRequestProjectEstimate,
      Reports.TotalNirRequestsIndicators,
      Reports.NirRequestAll,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const loadNirRequest = useCallback(
    (row: Table.Entry | null, tenderType: NirRequest.TenderType | null = null) => {
      setNirRequest({
        specification: row?.['id:Specification'] ?? 'BASIC_RESEARCH',
        tenderType,
        ...tenderInfo,
      });
      setIsAddFormOpen(true);
    },
    [tenderInfo],
  );

  const deleteNir = useCallback(
    (rowId: string) => {
      deleteRowAPI.callAPI(
        { commandName: 'DeleteNirRequest', entityName: 'NirRequest', rowId },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Заявка успешно удалена', theme: 'success' });
            tableStreams.reloadTable.push();
          },
        },
      );
    },
    [deleteRowAPI, tableStreams.reloadTable],
  );

  const sendMessage = useCallback(
    (message: string) => {
      if (!selectedRow) {
        return;
      }
      sendMessageAPI.callAPI(
        { nirRequestId: selectedRow.id, message },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщение успешно добавлено', theme: 'success' });
            tableStreams.reloadTable.push();
          },
        },
      );
    },
    [selectedRow, sendMessageAPI, tableStreams.reloadTable],
  );

  const handleCloseTenderTypeModal = useCallback(() => {
    setIsSelectTenderTypeModalOpen(false);
  }, []);

  const handleSubmitTenderTypeModal = useCallback(
    (tenderType: NirRequestTenderType) => {
      loadNirRequest(null, tenderType);
      setIsSelectTenderTypeModalOpen(false);
    },
    [loadNirRequest],
  );

  const handleTenderSelect = useCallback(
    (row: Table.Entry) => {
      setTenderInfo({
        tenderId: row.id,
        lotId: row.lotId,
      });
      setIsTenderModalOpen(false);
      setIsSelectTenderTypeModalOpen(true);
    },
    [setTenderInfo],
  );

  const {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons: statusChangeButtons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = getNirRequestStatusButtons({
    rows: tableState.selectedRows.map(x => ({ id: x.id, status: (x?.['id:Status'] as NirRequestStatus) || null })),
  });

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        onClick: () => setIsViewFormOpen(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        permissionName: 'NirRequest:Add',
        onClick: () => {
          setTenderInfo(null);
          setIsTenderModalOpen(true);
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: !selectedRow,
        onClick: () => setIsEditFormOpen(true),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: 'NirRequest:Delete:DRAFT',
        isDisabled: !selectedRow || status !== 'DRAFT',
        // eslint-disable-next-line
        disabledTooltip: 'Удалить. Кнопка недоступна по причине - Должна быть выбрана одна заявка и она должна быть в статусе "Черновик"',
        onClick: () => {
          setIsConfirmModalOpen(true);
        },
      },
      ...statusChangeButtons,
      {
        icons: buttonIcons.comment,
        title: 'Добавить сообщение в журнал',
        code: 'message',
        isDisabled: !selectedRow,
        permissionName: 'NirRequest:PushFeedlineMessage',
        onClick: () => setIsMessageModalOpen(true),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        isDisabled: !selectedRow,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [selectedRow, status, statusChangeButtons, getReports, handleSetCurrentReport, setTenderInfo],
  );

  const handleCloseMessageModal = useCallback(() => {
    setIsMessageModalOpen(false);
  }, []);

  const submitsMap: Record<string, (message: string) => void> = {
    message: sendMessage,
  };

  return {
    isMessageModalOpen,
    handleCloseMessageModal,
    submitsMap,
    buttons,
    isConfirmDeleteModalOpen,
    setIsConfirmModalOpen,
    deleteNir,
    selectedRow,
    isReportOpen,
    onReportClose,
    currentReport,
    isSelectTenderTypeModalOpen,
    handleCloseTenderTypeModal,
    handleSubmitTenderTypeModal,
    handleTenderSelect,
    isTenderModalOpen,
    setIsTenderModalOpen,
    isViewFormOpen,
    isEditFormOpen,
    isAddFormOpen,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    sendMessage,
    nirRequest,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
};

export default useController;
