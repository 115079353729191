import { useState, useLayoutEffect, useCallback } from 'react';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { Role } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';

import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { getMockRole } from './helpers';

type Props = {
  onClose(): void;
  viewMode?: boolean;
  editMode?: boolean;
};

export function useController({ onClose, viewMode, editMode }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });
  const [role, setRole] = useState<Role>(getMockRole());

  const { methods: getRole } = BackendAPI.useBackendAPI('GetRole', {
    onSuccessfullCall: ({ data }: { data: any }) => {
      const preparedData = data as Role;
      setRole(preparedData);
    },
  });

  const { methods: saveRole } = BackendAPI.useBackendAPI('SaveRole', {
    onSuccessfullCall: ({ data }) => {
      showNotification({ message: 'Роль успешно сохранена', theme: 'success' });
      tableStreams.reloadTable.push();
      if (workMode === 'editMode') {
        onClose();
      } else {
        updateWorkModeAfterSaveAndContinue();
        getRole.callAPI({ roleId: data.Response._text });
      }
    },
  });

  const handleNameFieldChange = useCallback(
    (value: string) => {
      setRole({ ...role, name: value });
    },
    [role],
  );

  const handleDescriptionFieldChange = useCallback(
    (value: string) => {
      setRole({ ...role, description: value });
    },
    [role],
  );

  const onSubmit = useCallback(() => {
    if (role.name) saveRole.callAPI(role);
    else showNotification({ message: 'Введите название для роли', theme: 'danger' });
  }, [role, saveRole]);

  const handleFormSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleFormClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useLayoutEffect(() => {
    if (id) getRole.callAPI({ roleId: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleFormClose,
    handleFormSubmit,
    role,
    roleId: id ?? role.id ?? null,
    handleNameFieldChange,
    handleDescriptionFieldChange,
    workMode,
    updateWorkModeAfterSaveAndContinue,
  };
}
