import React from 'react';
import * as R from 'ramda';

import { Reference, FormComponent } from 'components';
import { Program } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  customer: Program.Customer | null;
  onCustomerChange(row: Program.Customer): void;
};

function CustomerInputs({ customer, onCustomerChange }: Props) {
  const handleChange = (fieldName: keyof Program.Customer) => (value: ValueOf<Program.Customer>) => {
    onCustomerChange(R.set(R.lensProp(fieldName), value, customer!));
  };

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Организация" isRequired>
          <Reference
            name="RefEnterpriseCustomer"
            onChange={handleChange('refCustomer')}
            value={customer?.refCustomer ?? null}
            relationTableModalTitle="Организация"
            isRequired
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Тип источника финансирования">
          <Reference
            name="RefTypeInvestmentSource"
            onChange={handleChange('investmentSourceType')}
            value={customer?.investmentSourceType ?? null}
            relationTableModalTitle="Тип источника финансирования"
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { CustomerInputs };
