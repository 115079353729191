import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Settings } from 'types/models/common';
import { convertRid } from './converters';
import { convertRntdToXML } from './requestConverters';
import { RntdSaveInput } from './types';

const configurations = {
  GetRntd: makeBackendAPIConfiguration({
    id: 'GetRntd',
    endpoint: '/msa/service/commands/GetRntd',
    convertInputToXMLElement: ({ id, securityDocument }: { id?: string; securityDocument?: string }) => ({
      Request: {
        _attr: { commandName: 'GetRntd' },
        Rntd: {
          _attr: securityDocument ? { securityDocument } : { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertRid(response.Response.Rntd, settings),
  }),
  SaveRntd: makeBackendAPIConfiguration({
    id: 'SaveRntd',
    endpoint: '/msa/service/commands/SaveRntd',
    convertInputToXMLElement: (inputData: RntdSaveInput) => ({
      Request: {
        _attr: {
          commandName: 'SaveRntd',
        },
        Rntd: { ...convertRntdToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const rntdConfigurations = { ...configurations };
