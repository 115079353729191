import React, { useCallback, useState } from 'react';

import { Button, ButtonMode, Modal, FormComponent, TextGridSelect, Radio } from 'components';

import { showNotification } from 'features/Notifications';
import {
  GetNirRequestListForProject,
  GetProgramRequestListForProject,
  GetSecurityDocumentContractListForProject,
} from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { getEnumItemLabel } from 'utils/Helpers';
import { ProjectType } from 'utils/Enums';

enum RadioOptions {
  empty = 'empty',
  program = 'program',
  nir = 'nir',
  sdc = 'sdc',
}

export type SubmitProps = {
  programRequestId?: string;
  nirRequestId?: string;
  securityDocumentContractId?: string;
};

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(props: SubmitProps): void;
  projectType: ProjectType;
};

export function SelectRequestModal({ isOpen, onClose, onSubmit, projectType }: Props) {
  const [programRequest, setProgramRequest] = useState<{ id: string; label: string } | null>(null);
  const [nirRequest, setNirRequest] = useState<{ id: string; label: string } | null>(null);
  const [securityDocumentContract, setSecurityDocumentContract] = useState<{ id: string; label: string } | null>(null);
  const [selectedRadio, setSelectedRadio] = useState<RadioOptions>(RadioOptions.empty);

  const { enumMap } = useAppDataContext();

  const handleSubmit = useCallback(() => {
    if (
      (selectedRadio === RadioOptions.program && !programRequest) ||
      (selectedRadio === RadioOptions.nir && !nirRequest) ||
      (selectedRadio === RadioOptions.sdc && !securityDocumentContract)
    ) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }
    if (selectedRadio === RadioOptions.program && programRequest) onSubmit({ programRequestId: programRequest.id });
    else if (selectedRadio === RadioOptions.nir && nirRequest) onSubmit({ nirRequestId: nirRequest.id });
    else if (selectedRadio === RadioOptions.sdc && securityDocumentContract)
      onSubmit({ securityDocumentContractId: securityDocumentContract.id });
    else onSubmit({});
    onClose();
  }, [selectedRadio, programRequest, nirRequest, securityDocumentContract, onSubmit, onClose]);

  const handleRadioChange = useCallback((e: RadioOptions) => {
    if (e !== RadioOptions.program) setProgramRequest(null);
    if (e !== RadioOptions.nir) setNirRequest(null);
    if (e !== RadioOptions.sdc) setSecurityDocumentContract(null);
    setSelectedRadio(e);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Создать проект (${getEnumItemLabel('ProjectType', projectType, enumMap)})`}
      size="medium"
    >
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Radio
              value={selectedRadio}
              list={[
                { label: 'Создать проект без заявки', value: RadioOptions.empty },
                { label: 'Создать на основе заявки на конкурс', value: RadioOptions.program },
                { label: 'Создать на основе заявки на внутренний конкурс', value: RadioOptions.nir },
                { label: 'Создать на основе Договора о распоряжении интеллектуальным правом', value: RadioOptions.sdc },
              ]}
              onChange={e => handleRadioChange(e as RadioOptions)}
              isInRow={false}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {selectedRadio === RadioOptions.program && (
          <FormComponent.Line>
            <FormComponent.Field label="Заявка на конкурс" isRequired={true}>
              <TextGridSelect
                specification={GetProgramRequestListForProject}
                title="Заявки на конкурс"
                value={programRequest}
                valueFormatter={x => x.label}
                onChange={x => setProgramRequest(x)}
                settings={{
                  mode: 'selectTableRow',
                  tableRowConverter: x => ({
                    id: x.id,
                    label: `${x.RequestCode || ''} (${x.Leader || ''}) ${x.RequestTheme || ''}`,
                  }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        {selectedRadio === RadioOptions.nir && (
          <FormComponent.Line>
            <FormComponent.Field label="Заявка на внутренний конкурс" isRequired={true}>
              <TextGridSelect
                specification={GetNirRequestListForProject}
                title="Заявки на внутренний конкурс"
                value={nirRequest}
                valueFormatter={x => x.label}
                onChange={x => setNirRequest(x)}
                settings={{
                  mode: 'selectTableRow',
                  tableRowConverter: x => ({
                    id: x.id,
                    label: `${x.RequestNumber || ''} (${x.Leader || ''}) ${x.RequestName || ''}`,
                  }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        {selectedRadio === RadioOptions.sdc && (
          <FormComponent.Line>
            <FormComponent.Field label="Договор на ИС" isRequired>
              <TextGridSelect
                specification={GetSecurityDocumentContractListForProject}
                title="Договоры на ИС"
                value={securityDocumentContract}
                valueFormatter={x => x.label}
                onChange={x => setSecurityDocumentContract(x)}
                settings={{
                  mode: 'selectTableRow',
                  tableRowConverter: x => ({
                    id: x.id,
                    label: `${x.ContractKind || ''} №${x.ContractNumber || ''} (${x.EnterpriseName || ''})`,
                  }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Продолжить" onClick={handleSubmit} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
