import React, { memo, useCallback } from 'react';

import { FormComponent, ReferenceItem, Select } from 'components';

import { Table } from 'types/models';

type Props = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  enumValues: Table.EnumValue[];
  fieldLabel: string;
};

const DefaultSelect = ({ referenceItem, setReferenceItem, enumValues, fieldLabel }: Props) => {
  const handleSetReferenceItem = useCallback(
    (nextEnumLabel: string) => {
      const nextEnumValue = enumValues.find(enumValue => enumValue.label === nextEnumLabel);
      if (nextEnumValue) {
        setReferenceItem({
          id: nextEnumValue.ordinal.toString(),
          label: nextEnumValue.label,
          code: nextEnumValue.value,
        });
      }
    },
    [enumValues, setReferenceItem],
  );

  const renderEntry = useCallback(x => x, []);

  return (
    <FormComponent.Line>
      <FormComponent.Field isRequired label={fieldLabel}>
        <Select.Component
          value={referenceItem?.label || ''}
          variant="outlined"
          defaultPlaceholder="Не задан"
          entries={enumValues.map(enumValue => enumValue.label)}
          onChange={handleSetReferenceItem}
          renderEntry={renderEntry}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(DefaultSelect);

export { Component as DefaultSelect };
