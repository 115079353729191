import React from 'react';
import { Form } from 'features/Form';
import { Form as F } from 'types/models';

const RoleAddTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, relatedTableState, name }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetRole',
    template: 'RoleForm',
    relatedTableState,
    name,
  };
  return isOpen ? <Form look={look} onClose={onClose} /> : <></>;
};
export const Component = React.memo(RoleAddTemplate);
