import React from 'react';

import { Tabs, Tab } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import { useAppDataContext } from 'features/AppData/context';

import { TeamComposition } from './TeamComposition/TeamComposition';
import { CharacteristicComposition } from './CharacteristicComposition/CharacteristicComposition';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function Collective(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;
  const { enumMap } = useAppDataContext();

  const roles = enumMap.NirRequestPerformerRole.values;

  return (
    <Tabs>
      <Tab title="Состав коллектива">
        <TeamComposition
          performers={nirRequest?.performers ?? []}
          setPerformers={makeChangeHandler('performers')}
          roles={roles}
          disabled={disabled}
          tenderType={nirRequest?.tenderType.value ?? null}
        />
      </Tab>
      <Tab title="Характеристика состава">
        <CharacteristicComposition
          makeChangeHandler={makeChangeHandler}
          nirRequest={nirRequest}
          roles={roles}
          disabled={disabled}
        />
      </Tab>
    </Tabs>
  );
}
