import React, { useCallback } from 'react';

import { ConfirmPopup } from 'components';

import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { AwardsModal } from 'features/AwardsModal';
// eslint-disable-next-line max-len
import * as conferenceParticipationModalTemplate from 'features/Table/specifications/GetConferenceParticipationList/LeftPanelForThirdLevel/modalTemplates';
// eslint-disable-next-line max-len
import * as contestParticipationModalTemplate from 'features/Table/specifications/GetContestParticipationList/LeftPanelForThirdLevel/modalTemplates';
// eslint-disable-next-line max-len
import * as expositionParticipationModalTemplate from 'features/Table/specifications/GetExpositionParticipationList/LeftPanelForThirdLevel/modalTemplates';
// eslint-disable-next-line max-len
import * as concertParticipationModalTemplate from 'features/Table/specifications/GetConcertParticipationList/LeftPanelForThirdLevel/modalTemplates';
import { EventTypeCode } from 'types/models/Event';
import { TypeControllerIsForm } from './models/typeControllerIsForm';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  controllerIsForm: TypeControllerIsForm;
  eventTypeName: EventTypeCode;
};

const EventParticipationTemplate = ({ controllerIsForm, eventTypeName }: Props) => {
  const {
    sourceEvent,
    isAdd,
    isEdit,
    isView,
    isDelete,
    isEditSubdivisionModal,
    isApproveParticipationModal,
    setDelete,
    setEdit,
    setView,
    setAdd,
    setIsEditSubdivisionModal,
    setIsApproveParticipationModal,
    isShowAwards,
    setIsShowAwards,
    participation,
    sourcePublication,
    onCloseParticipationForm,
  } = controllerIsForm;
  const tableStreams = useLocalTableStreams();
  const handleConfirmDeleteConfirmPopup = () => {
    tableStreams.deleteRow.push({
      deleteRowId: participation?.id || '',
      command: 'DeleteParticipation',
      deletedItemPropName: 'Participation',
    });
    setDelete(false);
  };

  const onCloseAddForm = useCallback(() => {
    if (onCloseParticipationForm) onCloseParticipationForm();
    setAdd(false);
  }, [setAdd, onCloseParticipationForm]);

  const getAddTemplate = useCallback(() => {
    switch (eventTypeName) {
      case 'CONCERT':
        return (
          <concertParticipationModalTemplate.ConcertParticipationAddTemplate.Component
            isOpen={isAdd}
            onClose={onCloseAddForm}
            disabledEvent
            sourceEvent={sourceEvent}
            disableUpdateAfterSubmit
            sourcePublication={sourcePublication}
          />
        );
      case 'CONFERENCE':
        return (
          <conferenceParticipationModalTemplate.ConferenceParticipationAddTemplate.Component
            isOpen={isAdd}
            onClose={onCloseAddForm}
            disabledEvent
            sourceEvent={sourceEvent}
            disableUpdateAfterSubmit
            sourcePublication={sourcePublication}
          />
        );
      case 'EXPOSITION':
        return (
          <expositionParticipationModalTemplate.ExpositionParticipationAddTemplate.Component
            isOpen={isAdd}
            onClose={onCloseAddForm}
            disabledEvent
            sourceEvent={sourceEvent}
            disableUpdateAfterSubmit
            sourcePublication={sourcePublication}
          />
        );
      case 'CONTEST':
        return (
          <contestParticipationModalTemplate.ContestParticipationAddTemplate.Component
            isOpen={isAdd}
            onClose={onCloseAddForm}
            disabledEvent
            sourceEvent={sourceEvent}
            disableUpdateAfterSubmit
            sourcePublication={sourcePublication}
          />
        );
      default:
        return null;
    }
  }, [eventTypeName, isAdd, onCloseAddForm, sourceEvent, sourcePublication]);

  const getEditTemplate = useCallback(() => {
    switch (eventTypeName) {
      case 'CONTEST':
        return (
          <contestParticipationModalTemplate.ContestParticipationEditTemplate.Component
            isOpen={isEdit}
            onClose={() => setEdit(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      case 'CONFERENCE':
        return (
          <conferenceParticipationModalTemplate.ConferenceParticipationEditTemplate.Component
            isOpen={isEdit}
            onClose={() => setEdit(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      case 'EXPOSITION':
        return (
          <expositionParticipationModalTemplate.ExpositionParticipationEditTemplate.Component
            isOpen={isEdit}
            onClose={() => setEdit(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      case 'CONCERT':
        return (
          <concertParticipationModalTemplate.ConcertParticipationEditTemplate.Component
            isOpen={isEdit}
            onClose={() => setEdit(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      default:
        return null;
    }
  }, [eventTypeName, isEdit, participation?.id, setEdit]);

  const getViewTemplate = useCallback(() => {
    switch (eventTypeName) {
      case 'CONTEST':
        return (
          <contestParticipationModalTemplate.ContestParticipationViewTemplate.Component
            isOpen={isView}
            onClose={() => setView(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      case 'CONFERENCE':
        return (
          <conferenceParticipationModalTemplate.ConferenceParticipationViewTemplate.Component
            isOpen={isView}
            onClose={() => setView(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      case 'EXPOSITION':
        return (
          <expositionParticipationModalTemplate.ExpositionParticipationViewTemplate.Component
            isOpen={isView}
            onClose={() => setView(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      case 'CONCERT':
        return (
          <concertParticipationModalTemplate.ConcertParticipationViewTemplate.Component
            isOpen={isView}
            onClose={() => setView(false)}
            id={participation?.id || ''}
            disabledEvent
            disableUpdateAfterSubmit
          />
        );
      default:
        return null;
    }
  }, [eventTypeName, isView, participation?.id, setView]);

  return (
    <>
      {isAdd && getAddTemplate()}
      {isView && getViewTemplate()}
      {isEdit && getEditTemplate()}
      {isDelete && (
        <ConfirmPopup
          title="Предупреждение"
          text="Вы действительно хотите удалить выбранное участие?"
          isOpen={isDelete}
          onClose={() => setDelete(false)}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isEditSubdivisionModal && (
        <EditParticipationDepartments
          onClose={() => {
            setIsEditSubdivisionModal(false);
          }}
          id={participation?.id || ''}
          isOpen={isEditSubdivisionModal}
          afterSubmit={() => {}}
          type={eventTypeName}
        />
      )}
      {isApproveParticipationModal && (
        <ApproveParticipation
          onClose={() => {
            setIsApproveParticipationModal(false);
          }}
          id={participation?.id || ''}
          isOpen={isApproveParticipationModal}
          type={eventTypeName}
        />
      )}
      {participation?.awards && (
        <AwardsModal isOpen={isShowAwards} onClose={() => setIsShowAwards(false)} id={participation.id} />
      )}
    </>
  );
};

export default React.memo(EventParticipationTemplate);
