import React from 'react';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal
        title="План - график платежей по договорам"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Источник данных для таблицы — сведения из Календарного плана проекта, связанного с договором (модуль&nbsp;
              <strong>Проекты, на вкладке Заявки</strong> указан договор на использование интеллектуального права на объекты).
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
