import React, { useMemo } from 'react';

import {
  CustomSelect,
  FormComponent,
  InputSelect,
  ListEdit,
  Reference,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';

import { Project, Table } from 'types/models';
import { ValueOf } from 'types/helpers';

import { useController } from './controller';
import { formatNumber } from 'utils/Helpers';
import { SelectPerson } from 'features/SelectPerson';
import { PurchaseRequest } from 'types/models/PurchaseRequest';
import { GetFullScienceProjectList, GetPersonalProjectList } from 'features/Table/specifications';
import { showNotification } from 'features/Notifications';
import { UnitFields } from './UnitFields/UnitFields';

type Props = {
  workMode: Table.WorkMode;
  purchaseRequest: PurchaseRequest;
  project: Project.Project | null;
  projectSelectable: boolean;
  hasPersonalMode: boolean;
  makeChangeHandler: (key: keyof PurchaseRequest) => (val: ValueOf<PurchaseRequest>) => void;
  setPurchaseRequest: React.Dispatch<React.SetStateAction<PurchaseRequest>>;
};

export function AboutPurchaseRequest(props: Props) {
  const { workMode, purchaseRequest, hasPersonalMode, makeChangeHandler, project, projectSelectable, setPurchaseRequest } = props;

  const disabled = useMemo(() => {
    return workMode === 'viewMode';
  }, [workMode]);

  const {
    contractKindOptions,
    projectPerformerOptions,
    handleApplicantChange,
    handleFinResponsibleChange,
    handleReqResponsibleChange,
    projectRowConverter,
    formatScientist,
    projectOriginal,
    displayRevFields,
    projectTooltipTxt,
    purchaseRequestNumberTooltipTxt,
    purchaseRequestSendDateTooltipTxt,
    purchaseRequestApplicantTxt,
    purchaseRequestDepartmentTxt,
  } = useController({ makeChangeHandler, project, purchaseRequest });
  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Наименование закупки" isRequired>
              <TextInput value={purchaseRequest?.name || ''} onChange={makeChangeHandler('name')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          {projectSelectable && (
            <FormComponent.Line>
              <FormComponent.Field label="Проект" isRequired tooltip={projectTooltipTxt}>
                {hasPersonalMode ? (
                  <CustomSelect
                    title=""
                    original={projectOriginal}
                    relationTableModalTitle="Научные проекты"
                    onDeleteFunction={() => makeChangeHandler('project')(null)}
                    onChange={val => makeChangeHandler('project')(val)}
                    modalTableRowConverter={projectRowConverter}
                    modalTableSpecification={GetPersonalProjectList({
                      hasSelectButton: true,
                      hasOnlyLeaders: true,
                    })}
                    disabled={disabled}
                  />
                ) : (
                  <CustomSelect
                    title=""
                    original={projectOriginal}
                    relationTableModalTitle="Научные проекты"
                    onDeleteFunction={() => makeChangeHandler('project')(null)}
                    onChange={val => makeChangeHandler('project')(val)}
                    modalTableRowConverter={projectRowConverter}
                    modalTableSpecification={GetFullScienceProjectList({})}
                    disabled={disabled}
                  />
                )}
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {displayRevFields && (
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Номер заявки на закупку" tooltip={purchaseRequestNumberTooltipTxt}>
                  <TextInput value={purchaseRequest?.number || ''} onChange={makeChangeHandler('number')} isDisabled={disabled} />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата регистрации" tooltip={purchaseRequestSendDateTooltipTxt}>
                  <>{purchaseRequest?.sendDate}</>
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
          <FormComponent.Line>
            <ListEdit
              title="Список товаров (работ, услуг)"
              rows={purchaseRequest.units}
              onChange={e => setPurchaseRequest(prev => ({ ...prev, units: e }))}
              defaultRowsCount={5}
              maxHeight="50vh"
              isCanMovingRows={true}
              columns={[
                { label: 'Тип', formatValue: x => x.type?.label ?? '' },
                { label: 'Наименование', formatValue: x => x.name ?? '' },
                { label: 'Количество', formatValue: x => x.quantity ?? 0 },
                { label: 'Единица измерения', formatValue: x => x.unit ?? '' },
                {
                  label: 'Ориентировочная цена за ед.',
                  formatValue: x => formatNumber(String(x.perUnitPrice || 0), 2),
                },
                { label: 'Включая НДС', formatValue: x => !!x.isNds },
                {
                  label: 'Итого',
                  formatValue: x => formatNumber(String((x.perUnitPrice || 0) * (x.quantity || 0)), 2),
                },
              ]}
              columnIndexesForSumTotal={[6]}
              specification={{
                mode: 'customModalComponent',
                modalTitle: 'Список товаров (работ, услуг)',
                renderComponent: (unit, setUnit) => <UnitFields unit={unit} setUnit={setUnit} />,
                validation: {
                  checkIsValid: unit => !!unit?.type?.value && !!unit.name,
                  onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                },
              }}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Предельная сумма закупки">
              <TextInput
                mode={TextInputMode.number}
                value={purchaseRequest?.limitPrice ? formatNumber(purchaseRequest?.limitPrice?.toString(), 2) : undefined}
                onChange={makeChangeHandler('limitPrice')}
                isDisabled={disabled}
                placeholder="0.00"
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Вид договора" isRequired>
              <InputSelect
                options={contractKindOptions}
                value={contractKindOptions.find(x => x.value === purchaseRequest?.contractKind?.value) ?? null}
                onSelectChange={val => makeChangeHandler('contractKind')(val)}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Срок поставки">
              <TextDateTime
                value={purchaseRequest?.deliveryDate || null}
                onChange={makeChangeHandler('deliveryDate')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Заявитель" isRequired tooltip={purchaseRequestApplicantTxt}>
              <InputSelect
                options={projectPerformerOptions}
                value={projectPerformerOptions.find(x => x.value === purchaseRequest?.applicant?.id) ?? null}
                onSelectChange={handleApplicantChange}
                disabled={disabled}
              />
              {formatScientist(purchaseRequest?.applicant?.person || null)}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ответственное лицо по заявке">
              <SelectPerson
                member={purchaseRequest?.requestResponsible || null}
                memberLabel={purchaseRequest?.requestResponsible?.person?.fullName}
                onUpdateMember={handleReqResponsibleChange}
                withHistory={false}
                disabled={disabled}
              />
              {formatScientist(purchaseRequest?.requestResponsible?.person?.scientist || null)}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Материально-ответственное лицо">
              <SelectPerson
                member={purchaseRequest?.financeResponsible || null}
                personId={purchaseRequest?.financeResponsible?.person?.id ?? null}
                onUpdateMember={handleFinResponsibleChange}
                withHistory={false}
                disabled={disabled}
              />
              {formatScientist(purchaseRequest?.financeResponsible?.person?.scientist || null)}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Подразделение" tooltip={purchaseRequestDepartmentTxt}>
              <Reference
                value={purchaseRequest?.department || null}
                relationTableModalTitle="Справочник подразделений"
                name="RefDepartment"
                onChange={makeChangeHandler('department')}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Адрес поставки">
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3 }}
                value={purchaseRequest?.deliveryAddress || ''}
                onChange={makeChangeHandler('deliveryAddress')}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea
                mode={TextAreaMode.wysiwyg}
                settings={{ rows: 5 }}
                value={purchaseRequest?.note || ''}
                onChange={makeChangeHandler('note')}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}
