import { Role } from 'types/models';
import { getMaybeField, getText } from '../commonConverters';

export function convertRole(role: any): Role {
  return {
    id: role._attributes.id,
    name: getText(role.Name),
    description: getText(role.Description),
  };
}

export function convertRoleToXML(role: Role) {
  return {
    _attr: getMaybeField('id', role.id),
    Name: role.name,
    Description: role.description,
  };
}
