import { useCallback, useState } from 'react';

import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { getMockStateRegistrationReport } from 'features/Form/looks/project/ProjectForm/helpers';
import { formatStr } from 'utils/Constants/FormatStr';
import { format, parse } from 'date-fns';
import { EMPTY_FILE } from 'utils/Constants';

type Arguments = {
  stages: Project.Stage[];
  reports: Project.StateRegistrationReport[];
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
};

export function useController(args: Arguments) {
  const { reports, stages, setProject } = args;

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const handleAddReport = useCallback(
    (stageNumber: string, reportType: Item, date: string) => {
      const foundStage = stages.find(x => x.number === stageNumber);
      const newReport: Project.StateRegistrationReport = {
        ...getMockStateRegistrationReport(),
        stageNumber,
        stageId: foundStage?.id ?? null,
        reportType,
        citisDate: date,
      };
      setProject(prevState => ({ ...prevState, stateRegistrationReports: [...reports, newReport] }));
    },
    [stages, setProject, reports],
  );

  const handleLoadFromStagesData = useCallback(
    (report: Project.StateRegistrationReport | null) => {
      if (report) {
        const actualStage = stages.find(stage => stage.id === report?.stageId);
        const actualStageReport = actualStage?.reports[0];
        if (report && actualStage) {
          const reportToUpdate = {
            ...report,
            stageName: report.stageName || actualStage.name || '',
            expectedResults: report.expectedResults || actualStage.expectedResults || '',
            reportType: report.reportType || actualStageReport?.type || null,
            reportName: report.reportName || actualStageReport?.name || '',
            reportIkNumber: report.reportIkNumber || actualStageReport?.number || '',
            referat: report.referat || actualStageReport?.referat || '',
            keyWords: report.keyWords || actualStageReport?.keyWords || '',
            reportCustomerSendDate: report.reportCustomerSendDate || actualStageReport?.sendCustomerDate || '',
            reportArchiveNumber: report?.reportArchiveNumber || actualStageReport?.ontiNumber || '',
            file: report?.file || actualStageReport?.file || null,
          };
          const updatedReportsList = reports.map(r => (r.id === report?.id ? reportToUpdate : r));
          setProject(prevState => ({ ...prevState, stateRegistrationReports: updatedReportsList }));
        }
      } else {
        const daysInMs = 1000 * 60 * 60 * 24 * 30;
        const newReports =
          stages
            .map(stage => {
              const rep = reports.find(r => r.stageId === stage.id);
              if (!rep) {
                return {
                  id: null,
                  stageId: stage.id,
                  stageName: stage.name || '',
                  stageNumber: stage.number,
                  reportType: { label: 'Промежуточный', value: 'INTERMEDIATE' },
                  expectedResults: stage.expectedResults || '',
                  reportName: stage?.name || '',
                  reportIkNumber: stage?.number || '',
                  citisDate: format(parse(stage.endDate, formatStr, new Date()).getTime() + daysInMs, formatStr),
                  interdisciplinaryAproachJustification: '',
                  reportArchiveNumber: '',
                  reportCustomerSendDate: '',
                  keyWords: '',
                  referat: '',
                  file: EMPTY_FILE,
                } as Project.StateRegistrationReport;
              } else {
                return {
                  id: 'skip',
                  stageId: '',
                  stageName: '',
                  stageNumber: '',
                  reportType: null,
                  expectedResults: '',
                  reportName: '',
                  reportIkNumber: '',
                  citisDate: '',
                  interdisciplinaryAproachJustification: '',
                  reportArchiveNumber: '',
                  reportCustomerSendDate: '',
                  keyWords: '',
                  referat: '',
                  file: EMPTY_FILE,
                };
              }
            })
            .filter(r => r.id !== 'skip') || [];
        const size = reports.length + newReports.length;
        const fixedReports = [...reports, ...newReports].map((r, index) => {
          if (index === size - 1) {
            return { ...r, reportType: { label: 'Итоговый', value: 'TOTAL' } };
          } else {
            return { ...r, reportType: { label: 'Промежуточный', value: 'INTERMEDIATE' } };
          }
        });
        setProject(prevState => ({ ...prevState, stateRegistrationReports: [...fixedReports] }));
      }
    },
    [reports, setProject, stages],
  );

  const onPreSubmit = useCallback(
    (row: Project.StateRegistrationReport, submit: (submit: Project.StateRegistrationReport) => void) => {
      const matchedStageIndex = stages.findIndex(stage => stage.number === row?.stageNumber);
      const matchedStage = matchedStageIndex !== -1 ? stages[matchedStageIndex] : null;
      if (matchedStage && (!matchedStage.name || !matchedStage.obtainedResults)) {
        setProject(prevState => ({
          ...prevState,
          stages: (() => {
            const newStages = [...prevState.stages];
            if (!newStages[matchedStageIndex].name) newStages[matchedStageIndex].name = row?.stageName || '';
            if (!newStages[matchedStageIndex].obtainedResults)
              newStages[matchedStageIndex].obtainedResults = row?.expectedResults || '';
            return newStages;
          })(),
        }));
      }
      submit(row);
    },
    [setProject, stages],
  );

  return {
    isOpenAddModal,
    setIsOpenAddModal,
    handleAddReport,
    handleLoadFromStagesData,
    onPreSubmit,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
}
