import { ListEdit } from 'components';
import React from 'react';

import { Lot } from 'types/models/Tender';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { validateLot } from './validate';
import { LotInputs } from './LotInputs/LotInputs';

type Props = {
  viewMode?: boolean;
  lots: Lot[];
  handleLotsChange(value: Lot[]): void;
  tenderName?: string;
  tenderStartDate?: string;
};

export function Lots(props: Props) {
  const { viewMode, lots, handleLotsChange, tenderName, tenderStartDate } = props;

  return (
    <ListEdit
      rows={lots}
      onChange={handleLotsChange}
      withMessages
      isDeleteConfirmEnabled
      isToolbarDisabled={!!viewMode}
      defaultRowsCount={5}
      columns={[
        { label: 'Номер', formatValue: row => row.number ?? '', styles: { width: '10%' } },
        { label: 'Шифр', formatValue: row => row.code ?? '', styles: { width: '15%' } },
        { label: 'Тема', formatValue: row => row.theme ?? '', styles: { width: '25%' } },
        {
          label: 'Сроки выполнения',
          formatValue: row =>
            [row.startDate ? `${row.startDate} - ${row.endDate}` : '', row.periodNote || ''].filter(x => x).join(', '),
          styles: { width: '15%' },
        },
        {
          label: 'Предельная цена',
          formatValue: row => {
            return `${formatNumber(row.limitAmount || '0', 2)} (контрактов - ${row.contractCount ?? '0'})`;
          },
          styles: { width: '15%' },
        },
        {
          label: 'Календарный план',
          formatValue: row =>
            row?.prices ? row.prices.map(p => `${formatNumber((p.amount || 0).toString(), 2)} (${p.year}г.)`).join('<br>') : '',
          styles: { width: '10%' },
        },
        {
          label: 'Обеспечение',
          formatValue: (row: Lot) => {
            return [
              row.isSupportRequest
                ? `<div>Заявка: ${formatNumber(row.requestSupportAmount, 2)}${row.note ? ' (' + row.note + ')' : ''}</div>`
                : '',
              row.isSupportContract
                ? `<div>Контракт: ${formatNumber(row.contractSupportAmount, 2)}${row.note ? ' (' + row.note + ')' : ''}</div>`
                : '',
              row.isSupportWarranty
                ? `<div>Гарант. обяз.: ${formatNumber(row.warrantySupportAmount, 2)}${
                    row.note ? ' (' + row.note + ')' : ''
                  }</div>`
                : '',
            ]
              .filter(item => item)
              .join('');
          },
          styles: { width: '10%' },
        },
      ]}
      specification={{
        mode: 'customModalComponent',
        modalTitle: 'Лот конкурса',
        isFullScreenModal: true,
        renderComponent: (row, onChange) => (
          <LotInputs
            lot={row}
            onLotChange={onChange}
            viewMode={!!viewMode}
            lotsAmount={lots.length}
            tenderName={tenderName || ''}
            tenderStartDate={tenderStartDate || ''}
          />
        ),
        validation: {
          checkIsValid: lot => validateLot(lot).every(x => x.isValid),
          onInvalidate: lot => {
            const validationInfo = validateLot(lot);
            showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );
}
