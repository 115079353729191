import React from 'react';

import {
  CustomSelect,
  FormComponent,
  ListEdit,
  Reference,
  SectionTitle,
  ReferenceItem,
  TextDateTime,
  TextArea,
  TextAreaMode,
  TextInput,
} from 'components';

import {
  Author,
  Form,
  SecurityDocument,
  SecurityDocumentMember,
  SecurityDocumentMemberTypes,
  SecurityDocumentTypes,
} from 'types/models';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { validatePatentMember } from '../Document/validate';
import { useController } from './controller';
import { MemberInputs } from '../Document/MemberInputs/MemberInputs';
import { Department } from 'types/models/Project';

type Props = {
  patent?: SecurityDocument | null;
  setPatent?: React.Dispatch<React.SetStateAction<SecurityDocument | null | undefined>>;
  departments?: Department[] | null;
  setDepartments?: React.Dispatch<React.SetStateAction<Department[]>>;
  mpks?: ReferenceItem[] | null;
  setMpks?: React.Dispatch<React.SetStateAction<ReferenceItem[]>>;
  formFields: Form.Fields;
  disabled?: boolean;
};

export function NotRegistredDocument({ patent, formFields, disabled, setPatent }: Props) {
  const {
    projectRowConverter,
    projectSpecification,
    projectOriginal,
    handleAuthorsChange,
    handleApplicantChange,
    documentTitles,
    isKnowHowDocument,
    leftColumnWidth,
    rightColumnWidth,
    authorsList,
    applicantPersonList,
  } = useController({
    patent,
    setPatent,
  });
  const { settings } = useAppDataContext();
  const applicantsComponent = () => (
    <ListEdit
      withMessages
      title={`Правообладатели, кроме ${settings?.organization?.shortName}`}
      isDeleteConfirmEnabled
      rows={applicantPersonList}
      isToolbarDisabled={disabled}
      onChange={handleApplicantChange}
      maxHeight="300px"
      defaultRowsCount={5}
      columns={[
        { label: 'ФИО персоны / Название организации', formatValue: x => x?.name || '', styles: { width: '42%' } },
        {
          label: 'Договор',
          formatValue: x =>
            x?.contractDate ? `№ ${x?.contractNumber} от ${x?.contractDate}` : x?.contractNumber ? `№ ${x?.contractNumber}` : '',
          styles: { width: '42%' },
        },
        { label: 'Доля', formatValue: x => x?.contractPortion ?? '', styles: { width: '16%' } },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs
            member={row}
            onMemberChange={onChange}
            memberType={SecurityDocumentMemberTypes.APPLICANT}
            enterpriseRef="RefEnterpriseCustomer"
          />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );
  const convertSecurityDocumentToAuthor = (member: SecurityDocumentMember): Author => ({
    ...member,
    category: null,
    historyLabel: '',
    affilateCount: null,
    isAffilated: false,
    memberAffilations: [],
    position: 0,
    enterprise: null,
  });
  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        historyLabel: '',
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      settings,
    );

  const authorsComponent = () => (
    <ListEdit
      isRequired
      title={`Авторы${authorsList.length ? `, всего: ${authorsList.length}` : ''}`}
      withMessages
      isDeleteConfirmEnabled
      rows={authorsList}
      isToolbarDisabled={disabled}
      onChange={handleAuthorsChange}
      maxHeight="none"
      columnIndexesForSumTotal={[3]}
      defaultRowsCount={8}
      columns={[
        { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '25%' } },
        {
          label: 'Должность',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.rank || result.error || '';
          },
          styles: { width: '25%' },
        },
        {
          label: 'Место работы',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.job || result.error || '';
          },
          styles: { width: '35%' },
        },
        { label: 'Вклад, %', formatValue: x => formatNumber(x?.contribution || '0', 2), styles: { width: '15%' } },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs member={row} onMemberChange={onChange as any} memberType={SecurityDocumentMemberTypes.AUTHOR} />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );
  return (
    <>
      <SectionTitle title={documentTitles[SecurityDocumentTypes.NON_REGISTERED]} />
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column specialStyles={{ width: leftColumnWidth }}>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.intellectualPropertyKindNonRegistered.title} isRequired>
              <Reference
                name={'RefIntellectualPropertyKindNonRegistered'}
                value={formFields.intellectualPropertyKindNonRegistered.value ?? null}
                relationTableModalTitle='Справочник "Объекты авторского права"'
                onChange={formFields.intellectualPropertyKindNonRegistered.onChange}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field
              label={formFields.intellectualPropertyName.title}
              isRequired={formFields.intellectualPropertyName.required}
            >
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.intellectualPropertyName.title }}
                value={formFields.intellectualPropertyName.value}
                onChange={formFields.intellectualPropertyName.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label={formFields.referat.title} isRequired={formFields.referat.required}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: formFields.referat.title }}
                value={formFields.referat.value}
                onChange={formFields.referat.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field>
              <CustomSelect
                rowsNumber={3}
                disabled={patent?.project?.id !== undefined}
                title={formFields.project.title}
                relationTableModalTitle="Проекты"
                original={formFields.project.value ? projectOriginal(formFields.project.value) : null}
                onChange={formFields.project.onChange}
                onDeleteFunction={() => {}}
                modalTableSpecification={projectSpecification}
                modalTableRowConverter={projectRowConverter}
                isRequired={formFields.project.required}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: rightColumnWidth }}>
          <FormComponent.Line>
            <FormComponent.Field label="Дата создания" isRequired>
              <TextDateTime
                value={formFields.documentDate.value}
                onChange={formFields.documentDate.onChange}
                isDisabled={disabled || formFields.documentDate.disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={`Предоставлено в распоряжение ${settings?.organization?.shortName}`}>
              <TextDateTime
                value={formFields.documentStartDate.value}
                onChange={formFields.documentStartDate.onChange}
                isDisabled={disabled || formFields.documentStartDate.disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Основание" isRequired={formFields.documentNumber.required}>
              <TextArea
                mode={TextAreaMode.modal}
                settings={{ rows: 3, title: 'Основание' }}
                value={formFields.documentNumber.value}
                onChange={formFields.documentNumber.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={formFields.internalNumber.title} isRequired>
              <TextInput
                value={formFields.internalNumber.value}
                onChange={formFields.internalNumber.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата начала" isRequired>
              <TextDateTime
                value={formFields.loDate.value}
                onChange={formFields.loDate.onChange}
                isDisabled={disabled || formFields.loDate.disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата окончания">
              <TextDateTime
                value={formFields.hiDate.value}
                onChange={formFields.hiDate.onChange}
                isDisabled={disabled || formFields.hiDate.disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column specialStyles={{ width: leftColumnWidth }}>
          <FormComponent.Line>{authorsComponent()}</FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: rightColumnWidth }}>
          <FormComponent.Line>{!isKnowHowDocument && applicantsComponent()}</FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}
