import { useState, useLayoutEffect, useCallback, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Form, FileInfo, Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { getPersonHistory } from 'utils/Helpers';
import type { Recognition } from 'types/models';
import type { Member } from 'features/SelectPerson';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { RecognitionHeader } from 'features/Form/views/ModalHeader';
import { TemplateProps } from 'types/models/Form';
import { showNotification } from 'features/Notifications';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose: () => void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const { isProfile } = usePrivatePageContext();
  const {
    look: { id, relatedTableState, arm, setTitle, memberEditLocked },
  } = useFormContext<TemplateProps & { memberEditLocked: boolean }>();
  const { currentPerson, initialOrganizationCode } = useAppDataContext();

  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const [status, setStatus] = useState<Recognition['status'] | null>(null);
  const [author, setAuthor] = useState<Member | null>(null);
  const [department, setDepartment] = useState<Recognition['department'] | null>(null);

  const [formFields, setFormFields] = useState<Form.Fields>({
    dateGet: {
      value: '',
      isValid: true,
      required: true,
      title: 'Дата получения',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? undefined;
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          dateGet: { ...prevState.dateGet, value },
        }));
      },
    },
    award: {
      value: { label: '', id: '' },
      isValid: true,
      required: true,
      title: 'Награда',
      onChange: (value: ReferenceItem) => {
        const awardCategory = value.customFields?.category as any;
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          award: { ...prevState.award, value },
          awardCategory: { ...prevState.awardCategory, value: awardCategory?.label || '' },
        }));
      },
    },
    awardCategory: {
      value: '',
      isValid: true,
      title: 'Категория награды',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          awardCategory: { ...prevState.awardCategory, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          awardCategory: { ...prevState.awardCategory, isValid: true },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      title: 'Файл копии документа',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      title: 'Описание награды',
      tooltipText: `Пример заполнения поля: 
      ${
        initialOrganizationCode?.code === 'TSU'
          ? `
      Объявлена Благодарность Томского
      государственного университета за добросовестный
      труд и в связи с 30-летием философского факультета
      Томского государственного университета
      
      или
      `
          : ''
      }
      Награждена Благодарственным письмом
      полномочного представителя Президента Российской
      Федерации в Сибирском федеральном округе за
      многолетнюю плодотворную педагогическую, научную
      и исследовательскую деятельность, большой личный
      вклад в совершенствование подготовки
      высококвалифицированных специалистов`,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, isValid: true },
        }));
      },
    },
    note: {
      value: '',
      isValid: true,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, isValid: true },
        }));
      },
    },
  });

  const tableStreams = useLocalTableStreams();

  const { methods: getRecognition } = BackendAPI.useBackendAPI('GetRecognition', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Recognition;

      setStatus(preparedData.status);
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        dateGet: {
          ...prevState.dateGet,
          value: preparedData.dateGet,
        },
        award: {
          ...prevState.award,
          value: {
            id: preparedData.award.id,
            label: preparedData.award.label,
          },
        },
        awardCategory: {
          ...prevState.awardCategory,
          value: preparedData.awardCategory.label,
        },
        file: {
          ...prevState.file,
          value: preparedData.file,
        },
        description: {
          ...prevState.description,
          value: preparedData.description,
        },
        note: {
          ...prevState.note,
          value: preparedData.note,
        },
      }));
      setAuthor({
        person: preparedData.person,
        job: preparedData.job,
        education: preparedData.education,
        degree: preparedData.degree,
        citizenship: preparedData.citizenship,
        rank: preparedData.rank,
        academicRank: preparedData.academicRank,
      });

      setDepartment(preparedData.department);
    },
  });

  const { methods: saveRecognition } = BackendAPI.useBackendAPI('SaveRecognition', {
    onSuccessfullCall: ({ data }) => {
      if (data.Response._attributes.success) onClose();
      tableStreams.reloadTable.push();
      showNotification({ message: 'Награда успешно сохранена', theme: 'success' });
    },
  });

  const updateAuthor = useCallback((updatedMember: Member | null) => {
    if (updatedMember) {
      setAuthor(updatedMember);
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (author?.person?.id && formFields.dateGet.value && formFields.award.value?.id) {
      saveRecognition.callAPI({
        id,
        personId: author?.person?.id,
        jobId: author?.job?.id,
        degreeId: author?.degree?.id,
        rankId: author?.rank?.id,
        citizenshipId: author?.citizenship?.id,
        dateGet: formFields.dateGet.value,
        awardId: formFields.award.value?.id,
        file: formFields.file.value,
        description: formFields.description.value,
        note: formFields.note.value,
        arm,
      });
    } else showNotification({ theme: 'danger', message: 'Заполните обязательные поля' });
  }, [
    arm,
    author?.citizenship?.id,
    author?.degree?.id,
    author?.job?.id,
    author?.person?.id,
    author?.rank?.id,
    formFields.award.value?.id,
    formFields.dateGet.value,
    formFields.description.value,
    formFields.file.value,
    formFields.note.value,
    id,
    saveRecognition,
  ]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        RecognitionHeader({
          workMode,
          recognitionId: id || '',
          department,
          status: status?.label || '',
        }),
      );
    }
  }, [department, id, setTitle, status?.label, workMode]);

  useLayoutEffect(() => {
    if (id) {
      getRecognition.callAPI({ id });
    } else if (isProfile) {
      const currentPersonHistory = getPersonHistory({ person: currentPerson });
      setAuthor({ person: currentPerson, ...currentPersonHistory });
    }
    // eslint-disable-next-line
  }, []);

  return {
    relatedTableState,
    formFields,
    author,
    updateAuthor,
    department,
    onSubmit,
    status,
    workMode,
    memberEditLocked,
  };
}
