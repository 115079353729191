import { useLayoutEffect, useMemo, useState, useRef, useCallback } from 'react';

import { getNumWord } from 'utils/Helpers';
import { calcReduceByParameter, getIndicatorDataByYear, groupByParameter, sortByHardValues } from '../helpers';
import { FormatedDataArray, ResponseData } from '../types';
import * as BackendAPI from 'services/BackendAPI';

type ProgramParams = {
  factAmount: number;
  planAmount: number;
  projectsCount: number;
};

export type Program = {
  programName: string;
  data: ProgramParams[];
};

export type Indicator = {
  name: string;
  summary: ProgramParams[];
  programs: Program[];
};

export function useController() {
  const PLAN_VALUE = 'PLAN';
  const FACT_VALUE = 'VALUE';

  const [financingByPeriod, setFinancingByPeriod] = useState<Indicator[]>([]);
  const [activePeriodFilter, setActivePeriodFilter] = useState<string>(PLAN_VALUE);
  const [activeYearPeriodFilter, setActiveYearPeriodFilter] = useState<string>(new Date().getFullYear().toString());

  const periodOpts = useRef([
    {
      alias: PLAN_VALUE,
      label: 'Планирование',
    },
    {
      alias: FACT_VALUE,
      label: 'Исполнение плана',
    },
  ]);

  const yearslist = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const periodYearOpts = useRef(
    yearslist.map(yearItem => ({
      alias: yearItem.toString(),
      label: `${yearItem} год`,
    })),
  );

  const getProjectCountText = (val: number) => getNumWord({ words: ['проект', 'проекта', 'проектов'], count: val });

  const [isLoading, setIsLoading] = useState(false);

  const { methods: GetDashboardChartByProgramIndicatorListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartByProgramIndicatorListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        setIsLoading(false);
        const receivedData = data as ResponseData;
        const groupedValueArray: Array<FormatedDataArray[]> = groupByParameter(
          receivedData.Response.Grid.Rows.Row,
          'Indicator',
        ).map(ind => groupByParameter(ind, 'Program'));

        const constructIndicatorParameters = (indicator: FormatedDataArray, year: number): ProgramParams => ({
          factAmount: getIndicatorDataByYear(indicator, year)?.FactAmount || 0,
          planAmount: getIndicatorDataByYear(indicator, year)?.PlanAmount || 0,
          projectsCount: getIndicatorDataByYear(indicator, year)?.ProjectsQty || 0,
        });

        const constructSummaryParameters = (indicator: FormatedDataArray[], year: number): ProgramParams => ({
          factAmount: calcReduceByParameter(indicator, year, 'FactAmount'),
          planAmount: calcReduceByParameter(indicator, year, 'PlanAmount'),
          projectsCount: calcReduceByParameter(indicator, year, 'ProjectsQty'),
        });

        setFinancingByPeriod(
          sortByHardValues(
            groupedValueArray.map((indicator: FormatedDataArray[]) => ({
              name: indicator[0][0]._attributes.Indicator,
              summary: yearslist.map(yearItem => constructSummaryParameters(indicator, yearItem)),
              programs: indicator.map((pogramInd: FormatedDataArray) => ({
                programName: pogramInd[0]._attributes.Program,
                data: yearslist.map(yearItem => constructIndicatorParameters(pogramInd, yearItem)),
              })),
            })),
          ),
        );
      },
      onFailedCall: () => {
        setIsLoading(false);
      },
    },
  );

  useLayoutEffect(() => {
    setIsLoading(true);
    GetDashboardChartByProgramIndicatorListStatic.callAPI({
      rowQuantity: -1,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEntry = useCallback(x => x, []);
  const handleChangeActiveFilter = useCallback(
    newLabel => {
      const data = periodOpts.current.filter(opt => opt.label === newLabel)[0];
      setActivePeriodFilter(data?.alias);
    },
    [setActivePeriodFilter],
  );

  const activeFilterLabel = useMemo(() => periodOpts.current.filter(opt => opt.alias === activePeriodFilter)[0]?.label, [
    periodOpts,
    activePeriodFilter,
  ]);

  const handleChangeActiveYearFilter = useCallback(
    newLabel => {
      const data = periodYearOpts.current.filter(opt => opt.label === newLabel)[0];
      setActiveYearPeriodFilter(data?.alias);
    },
    [setActiveYearPeriodFilter],
  );

  const activeYearFilterLabel = useMemo(
    () => periodYearOpts.current.filter(opt => opt.alias === activeYearPeriodFilter)[0]?.label,
    [periodYearOpts, activeYearPeriodFilter],
  );

  const actualYearIndex = useMemo(() => periodYearOpts.current.findIndex(opt => opt.alias === activeYearPeriodFilter) || 0, [
    periodYearOpts,
    activeYearPeriodFilter,
  ]);

  return {
    financingByPeriod,
    isLoading,
    periodOpts,
    renderEntry,
    activeFilterLabel,
    handleChangeActiveFilter,
    handleChangeActiveYearFilter,
    activeYearFilterLabel,
    periodYearOpts,
    actualYearIndex,
    getProjectCountText,
  };
}
