import React from 'react';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal title="Публикации" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Таблица содержит список публикаций, в которых авторы аффилированы с организацией Источник информации — раздел&nbsp;
              <strong>Сведения об авторах</strong> на форме публикации (модуль <strong>Публикации</strong>).
            </p>
            <p>
              <strong className="state-danger">ВАЖНО!</strong> В случае, если для организации указаны организации на
              вкладках&nbsp;
              <strong>Связи с другими организациями</strong> и <strong>Варианты наименования</strong>, то в списке будут
              отображаться записи связанных организаций. В столбце <strong>Организация</strong> отображается оригинальное название
              организации. В столбце <strong>Роль</strong> отображается роль организации в проекте.
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
