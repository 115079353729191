import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Color } from 'constants/colors';

export const GetProjectStateRegistrationReportList: Table.Specification = {
  apiID: 'GetProjectStateRegistrationReportList',
  header: {
    firstLevel: {
      title: 'Госрегистрация, отчеты',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: LeftPanelForThirdLevel,
      withTemplatesPanel: true,
    },
  },
  highlightEntryPredicate: (entry: Table.Entry) => {
    return {
      rowTheme: (() => {
        if (entry.IsExpired === 'true') return Color.danger;
        if (entry.IsExpiring === 'true') return Color.success;
        return '';
      })(),
    };
  },
};
