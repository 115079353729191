import React, { useCallback } from 'react';

import {
  Checkbox,
  CustomSelect,
  FormComponent,
  IconButton,
  InputSelect,
  Option,
  Reference,
  SectionTitle,
  TextInput,
  buttonIcons,
} from 'components';

import { Performer, ProjectCode, Project } from 'types/models/Project';
import { Form, Person } from 'types/models';
import { OverheadPercentDefault } from 'utils/Constants/OverheadPercentDefault';
import { useController } from './controller';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  projectCode: ProjectCode | null;
  formFields: Form.Fields;
  findPerformerByJobPeriodRole(role: string, targetProject?: Project | null): Performer;
  disabled?: boolean;
  handleFinancingSourceChange(value: Option<string> | null): void;
  generateProjectCode(): void;
  replaceMode?: boolean;
};

export function AboutProjectCode({
  projectCode,
  formFields,
  disabled,
  findPerformerByJobPeriodRole,
  handleFinancingSourceChange,
  generateProjectCode,
  replaceMode,
}: Props) {
  const {
    departmentOptions,
    performerOptions,
    financingOptions,
    modalTableAvailableFundCardConverter,
    availableFundCardSpecification,
    projectCodesLabel,
    getNameWithContacts,
    projectCodeFinAmountSum,
    projectCodeNdsAmountSum,
    finSourceEnabled,
    projectCodeTooltipTxt,
  } = useController({
    projectCode,
    formFields,
  });

  const { initialOrganizationCode } = useAppDataContext();

  const getPersonDataByType = useCallback(
    (type: string) => {
      const project: Project | null | undefined = projectCode?.project;
      const person: Person.ScientistPerson | null = findPerformerByJobPeriodRole(type, project)?.person;
      return getNameWithContacts(person);
    },
    [findPerformerByJobPeriodRole, getNameWithContacts, projectCode?.project],
  );

  const getFinancing = useCallback(() => {
    return (projectCode?.project?.financings || [])
      .map(item => {
        return [
          item.source?.label || '',
          (() => {
            const splittedLabel = item.type?.label.split('(');
            if (splittedLabel && splittedLabel[1]) {
              return `(${splittedLabel[1]}`;
            }
            return '';
          })(),
        ]
          .filter(x => x)
          .join(' ');
      })
      .join(', ');
  }, [projectCode?.project?.financings]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column specialStyles={{ width: '50%' }}>
          <SectionTitle title="Справочная информация" />
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={formFields.department.title} isRequired={formFields.department.required}>
              <InputSelect
                options={departmentOptions}
                value={departmentOptions.find(x => x.value === formFields.department?.value?.id) ?? null}
                onSelectChange={formFields.department.onChange}
                disabled={disabled || !!projectCode?.id}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Факультет/Институт">
              <div>{formFields?.department?.value?.governance?.label || ''}</div>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field
              label={formFields.responsiblePerformer.title}
              isRequired={formFields.responsiblePerformer.required}
            >
              <InputSelect
                options={performerOptions}
                value={formFields.responsiblePerformer.value ?? null}
                onSelectChange={formFields.responsiblePerformer.onChange}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {initialOrganizationCode?.code === 'TSU' ? (
            <FormComponent.Line hasFreeFormat hasGap>
              <FormComponent.Field
                label={formFields.letter?.title}
                isRequired={formFields.letter?.required}
                tooltip={projectCodeTooltipTxt}
              >
                <Reference
                  name="RefProjectCodeLetter"
                  value={formFields.letter?.value ?? null}
                  relationTableModalTitle='Справочник "Буквы шифров"'
                  onChange={formFields.letter?.onChange}
                  disabled={disabled || (Boolean(projectCode?.id) && !replaceMode)}
                />
              </FormComponent.Field>
              <FormComponent.Field>
                <TextInput
                  value={formFields.codeDepartmentPart?.value}
                  onChange={formFields.codeDepartmentPart?.onChange}
                  isDisabled={disabled || Boolean(projectCode?.id)}
                />
              </FormComponent.Field>
              <FormComponent.Field>
                <TextInput
                  value={formFields.codeManualPart?.value}
                  onChange={formFields.codeManualPart?.onChange}
                  isDisabled={disabled || Boolean(projectCode?.id)}
                />
              </FormComponent.Field>
              <IconButton
                icons={buttonIcons.calc}
                onClick={generateProjectCode}
                isDisabled={
                  disabled ||
                  (Boolean(projectCode?.id) && !replaceMode) ||
                  !formFields.letter?.value?.id ||
                  !formFields.codeYearPart?.value ||
                  !formFields.codeDepartmentPart?.value
                }
              />
              <FormComponent.Field>
                <span>{formFields.codeYearPart?.value}</span>
              </FormComponent.Field>
            </FormComponent.Line>
          ) : (
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="Шифр" isRequired={formFields.code.required} tooltip={projectCodeTooltipTxt}>
                <TextInput
                  value={formFields.code.value}
                  onChange={formFields.code.onChange}
                  isDisabled={disabled || (Boolean(projectCode?.id) && !replaceMode)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={formFields.financing.title} isRequired={formFields.financing.required}>
              <InputSelect
                options={financingOptions}
                value={financingOptions.find(x => x.label === formFields.financing?.value?.label) ?? null}
                onSelectChange={handleFinancingSourceChange}
                disabled={disabled || Boolean(projectCode?.id)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line hasGap lineSize="doublePadded" hasFreeFormat>
            <FormComponent.Field
              label="Объем финансирования"
              tooltip="Данные отображаются из итоговой строки в таблице Финансирование шифра по годам на вкладке Финансовый план."
            >
              {projectCodeFinAmountSum}
            </FormComponent.Field>
            {finSourceEnabled ? <FormComponent.Field label="НДС">{projectCodeNdsAmountSum}</FormComponent.Field> : <></>}
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.financeProvide.title} isRequired={formFields.financeProvide.required}>
              <Reference
                name="RefFinanceProvide"
                value={formFields.financeProvide.value ?? null}
                relationTableModalTitle='Справочник "Виды финансового обеспечения"'
                onChange={formFields.financeProvide.onChange}
                disabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="">
              <Checkbox
                label={formFields.isOutsideBudget.title}
                checked={!!formFields.isOutsideBudget.value}
                onChange={formFields.isOutsideBudget.onChange}
              />
              <Checkbox
                label={formFields.isRefersToEconomicAgreement.title}
                checked={!!formFields.isRefersToEconomicAgreement.value}
                onChange={formFields.isRefersToEconomicAgreement.onChange}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {!!projectCode?.project?.contractKind && (
            <FormComponent.Line>
              <FormComponent.Field label="Вид договора">
                <span>{projectCode?.project?.contractKind.label}</span>
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {!!projectCode?.project?.nationalProjectExpenseCode && (
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="Код расходов Нацпроекта">
                <span>{projectCode?.project?.nationalProjectExpenseCode.label}</span>
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line>
            <FormComponent.Field label="Передавать накладные расходы на карточку ЦФ">
              <CustomSelect
                disabled={disabled}
                isRequired={formFields.fundCard.required}
                title={formFields.fundCard.title}
                original={{ id: formFields.fundCard?.value?.id, name: formFields.fundCard?.value?.code }}
                relationTableModalTitle="Список шифров Централизованного фонда (фонд накладных расходов)"
                onDeleteFunction={() => {
                  formFields.fundCard.onChange(null);
                }}
                onChange={formFields.fundCard.onChange}
                modalTableRowConverter={modalTableAvailableFundCardConverter}
                modalTableSpecification={availableFundCardSpecification}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column specialStyles={{ width: '45%' }}>
          <SectionTitle title="Сведения о проекте" />
          <FormComponent.Line>
            <FormComponent.Field label="Код номера проекта">
              <span>{projectCode?.project?.numberPrefix?.label}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Номер проекта">
              <span>{projectCode?.project?.number}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Сроки выполнения">
              <span>
                {projectCode?.project?.startDate} - {projectCode?.project?.endDate}
              </span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Руководитель">
              <span>{getPersonDataByType('LEADER')}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Отв. исполнитель">
              <span>{getPersonDataByType('RESPONSIBLE_PERFORMER')}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Заказчик">
              <span>{projectCode?.project?.customers[0]?.ref?.label}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Источники финансирования">
              <span>{getFinancing()}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Нормативный процент накладных">
              <span>{projectCode?.project?.overheadPercent || OverheadPercentDefault}%</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line lineSize="padded">
            <FormComponent.Field label="Сведения о шифрах проекта:">
              <span>{projectCodesLabel}</span>
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
