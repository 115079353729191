import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  hasSelectButton?: boolean;
};
export function GetExponentList(props: Props): Table.Specification {
  return {
    apiID: 'GetExponentList',
    header: {
      firstLevel: {
        title: 'Список разработок, представленных на выставках',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: props.hasSelectButton,
      },
    },
  };
}
