import { AuthorPaymentRequestStatus } from 'utils/Enums';

import { RecordStatus } from 'utils/Enums';

export function isNextPaymentStatus(current: AuthorPaymentRequestStatus, next: AuthorPaymentRequestStatus) {
  switch (next) {
    case AuthorPaymentRequestStatus.AGREEMENT:
      return (
        current !== AuthorPaymentRequestStatus.AGREEMENT &&
        current !== AuthorPaymentRequestStatus.POSTPONE &&
        current !== AuthorPaymentRequestStatus.PAYMENT_APPROVED &&
        current !== AuthorPaymentRequestStatus.PAID
      );
      break;
    case AuthorPaymentRequestStatus.PAID:
      return (
        current !== AuthorPaymentRequestStatus.REJECTED &&
        current !== AuthorPaymentRequestStatus.PAID &&
        current !== AuthorPaymentRequestStatus.PAYMENT_APPROVED &&
        current !== AuthorPaymentRequestStatus.REVISION &&
        current !== RecordStatus.DRAFT
      );
      break;
    case AuthorPaymentRequestStatus.PAYMENT_APPROVED:
      return (
        current !== AuthorPaymentRequestStatus.REJECTED &&
        current !== AuthorPaymentRequestStatus.AGREEMENT &&
        current !== AuthorPaymentRequestStatus.POSTPONE &&
        current !== AuthorPaymentRequestStatus.PAYMENT_APPROVED &&
        current !== AuthorPaymentRequestStatus.REVISION &&
        current !== RecordStatus.DRAFT
      );
      break;
    case AuthorPaymentRequestStatus.POSTPONE:
      return (
        current !== AuthorPaymentRequestStatus.REJECTED &&
        current !== AuthorPaymentRequestStatus.POSTPONE &&
        current !== AuthorPaymentRequestStatus.PAYMENT_APPROVED &&
        current !== AuthorPaymentRequestStatus.REVISION &&
        current !== RecordStatus.DRAFT
      );
      break;
    case AuthorPaymentRequestStatus.REJECTED:
      return (
        current !== AuthorPaymentRequestStatus.REJECTED &&
        current !== AuthorPaymentRequestStatus.POSTPONE &&
        current !== AuthorPaymentRequestStatus.PAYMENT_APPROVED &&
        current !== AuthorPaymentRequestStatus.REVISION &&
        current !== RecordStatus.DRAFT
      );
      break;
    case AuthorPaymentRequestStatus.REVISION:
      return (
        current !== AuthorPaymentRequestStatus.REJECTED &&
        current !== AuthorPaymentRequestStatus.POSTPONE &&
        current !== AuthorPaymentRequestStatus.PAYMENT_APPROVED &&
        current !== AuthorPaymentRequestStatus.PAID &&
        current !== AuthorPaymentRequestStatus.REVISION &&
        current !== RecordStatus.DRAFT
      );
      break;
    default:
      return false;
      break;
  }
}
