import { Option, ReferenceItem } from 'components';

import { ReceivingDocument } from 'types/models/SecurityDocumentContract';
import { FundCard } from 'types/models/FundCard';
import { Approvement, Payment } from 'types/models/Estimate';
import { Document, Partner as BasePartner, PatentResearch, Person, PersonHistory, Program, SecurityDocumentContract } from '..';
import { IdItem, Item } from '../common';
import { ProjectFinancingType, ProjectScientistRole, ProjectType } from 'utils/Enums';
import { Scientist } from 'types/models/Person';
import { FileInfo } from 'types/models';

export type ProjectValue = '0' | '1' | '2' | '3';

export type ProjectTypeItem = {
  code: ProjectType;
  value: ProjectValue;
};

export type Project = {
  activityKind: ReferenceItem | null;
  acts?: Act[];
  annotation: string;
  contestRequests: ContestRequest[];
  contractDate?: string;
  contractKind: ReferenceItem | null;
  contractNumber?: string;
  contractProvisionAmount: string;
  contractProvisionNote: string;
  contractProvisionType: Option | null;
  criticalTechnologies: RefElement[];
  customers: Customer[];
  departments: Department[];
  documents: Document[];
  domainKnowledges: RefElement[];
  endDate: string;
  expectedResults: string;
  expendituresBudgets: ReferenceItem | null;
  financialNote: string;
  financings: Financing[];
  financingsByYear: FinancingByYear[];
  foreignEnterprise: ReferenceItem | null;
  goal: string;
  grntis: RefElement[];
  id: string | null;
  interdisciplinaryAproachJustification: string;
  internationalProgram: ReferenceItem | null;
  isBuyMaterial: boolean;
  isContractProvision: boolean;
  isFinancingStopped: boolean;
  isNirRequestDataConsumed: boolean;
  isRequestProvision: boolean;
  isSpecTheme: boolean;
  isYouth: boolean;
  keyWords: string;
  kindResearch: ReferenceItem | null;
  lksetss: RefElement[];
  name: string;
  nationalProjectExpenseCode: ReferenceItem | null;
  nirRequest: Item | null;
  ntiEteTechnologies: RefElement[];
  ntiMarkets: RefElement[];
  ntrStrategies: RefElement[];
  number: string;
  numberPrefix: ReferenceItem | null;
  oecds: RefElement[];
  okved: ReferenceItem | null;
  okvedIncome: ReferenceItem | null;
  overheadPercent: string;
  partnerCountries: RefElement[];
  partners: Partner[];
  patentResearches: PatentResearch[];
  periodNote?: string;
  performers: Performer[];
  pnis: RefElement[];
  pnmitrs: RefElement[];
  pnrs: RefElement[];
  program: Program.Program | null;
  programEvent: Item | null;
  programEventCode?: string;
  programEventName?: string;
  programRequest: Item | null;
  projectCodes: ProjectCode[];
  projectCodesLabel?: string;
  projectContractStatus: Option | null;
  requestProvisionAmount: string;
  requestProvisionNote: string;
  requestProvisionType: Option | null;
  resourceUsageDepartments: ResourceUsageDepartment[];
  result: string;
  scienceBrunches: RefElement[];
  scienceDomainInterrests: RefElement[];
  scientificGroundwork: string;
  securityDocumentContract: SecurityDocumentContract.SecurityDocumentContract | null;
  specification: ReferenceItem | null;
  stages: Stage[];
  startDate: string;
  stateNumber: string;
  stateRegistrationAnnotation: string;
  stateRegistrationDate: string;
  stateRegistrationInterdisciplinaryAproachJustification: string;
  stateRegistrationReports: StateRegistrationReport[];
  stateRegistrationScientificGroundwork: string;
  status: string;
  stopMoneyDate?: string;
  stopMoneyReason: Item<string> | null;
  techPlatforms: RefElement[];
  workSpaces: RefElement[];
  type: Item<ProjectType> | null;
  udks: RefElement[];
  vaks: RefElement[];
  nirRequestDataCopyDetail: string;
  newNirRequestDataCopyDetail?: string; // Вспомогательное поле, в модели на бэке его нет
  programRequestDataCopyDetail: string;
  newProgramRequestDataCopyDetail?: string; // Вспомогательное поле, в модели на бэке его нет
  stateRegistrationEgisuUrl?: string;
  purchaseRequests: PurchaseRequest[];
  securityDocuments: SecurityDocument[];
};

// Сокращенная модель
export type SecurityDocument = {
  id: string | null;
  label: string;
  documentDate: string;
  requestDate: string;
};

export type PurchaseRequest = {
  id: string | null;
  type: Item | null;
  status: Item | null;
  name: string;
  sendDate: string;
};

export type ResourceUsageDepartment = {
  id: null | string;
  type: Option | null;
  department: ReferenceItem | null;
  note?: string;
};

export type ProgramRequest = {
  id: string | null;
};

export type Customer = {
  id: string | null;
  ref: ReferenceItem;
};

export type Department = {
  id: string | null;
  governance: ReferenceItem | null;
  unit: ReferenceItem | null;
};

export type Financing = {
  id: string | null;
  type?: Item<ProjectFinancingType> | null;
  source: ReferenceItem | null;
  amount: string;
  factAmount: string;
  isNds: boolean;
};

export type FinancingByYear = {
  year: string;
  amountMain: string;
  amountCofinancing1: string;
  amountCofinancing2: string;
  amountLocal: string;
  amountAccomplice: string;
  amountExternalAccomplices?: string;
  stages: string[];
  undistributedAmount?: string;
};

export type Stage = {
  id: string | null;
  isLocked: boolean;
  number: string;
  startDate: string;
  endDate: string;
  amountMain: string;
  amountCofinancing1: string;
  amountCofinancing2: string;
  amountLocal: string;
  amountFact: string;
  amountAccomplice: string;
  amountExternalAccomplices: string;
  mainNdsPercent: string;
  cofinancing1NdsPercent: string;
  cofinancing2NdsPercent: string;
  localNdsPercent: string;
  accompliceNdsPercent: string;
  reports: StageReport[];
  indicators: Indicator[];
  indices: Indicator[];
  amountAccompliceUndistributed?: string;
  amountCofinancing1Undistributed?: string;
  amountCofinancing2Undistributed?: string;
  amountLocalUndistributed?: string;
  amountMainUndistributed?: string;
  amountTotal?: string;
  goal?: string;
  annotation?: string;
  name?: string;
  task?: string;
  expectedResults?: string;
  kindWork?: ReferenceItem | null;
  isPatentResearchPlanned?: boolean;
  workDescription?: string;
  obtainedResults?: string;
  universityEffect?: string;
  regionalEffect?: string;
  nationalEffect?: string;
};

export type Act = {
  id: string;
  number: string;
  stage: ActStage | null;
  departments: ActDepartment[];
  сonsiderationDocuments: string;
  defaultBaseProgram: string;

  council: Council | null;

  conclusion: string;
  note: string;
  members: ActMember[];
};

export type ActDepartment = {
  id: string;
  department: Department;
};

export type Council = {
  id: string;
  isLocal: boolean;
  type: Item<string>;
  status: Item<string>;
  name?: string;
  code: string;
};

export type CouncilMember = {
  id: string;
  label?: string;
  role: Item;
};

export type ActStage = {
  id: string | null;
  number?: string | null;
  label?: string;
};

export type ActMember = {
  id: string | null;
  councilMember: CouncilMember;
  appointmentLabel?: string;
  academicRankLabel?: string;
  degreeLabel?: string;
  educationLabel?: string;
  rankLabel?: string;
  councilMemberId?: string;
} & PersonHistory;

export type StageReport = {
  id: string | null;
  type: Item | null;
  status: Item | null;
  file: FileInfo;
  udk: ReferenceItem | null;
  name: string;
  reportDate: string;
  sendCustomerDate: string;
  reportOntiDate: string;
  registrationOntiDate: string;
  ontiNumber: string;
  registrationDate: string;
  number: string;
  referat: string;
  keyWords: string;
};

export type RefElement = {
  id: string | null;
  isActual: boolean;
  isProject: boolean;
  ref: ReferenceItem | null;
  createdBy: string;
  createdDate: string;
  position: string;
};

export type Performer = {
  id: string | null;
  person: Person.ScientistPerson | null;
  fio: string;
  jobPeriods: JobPeriod[];
  stages: PerformerStage[];
  createdBy: string;
  createdDate: string;
  paymentMethods: string;
};

export type JobPeriodOrder = {
  id: string;
  number?: string;
  date?: string;
  type?: Item<string>;
};

export type JobPeriod = {
  id: string | null;
  startDate: string;
  startDateOrder?: JobPeriodOrder | null;
  endDate: string;
  endDateOrder?: JobPeriodOrder | null;
  role: Item<ProjectScientistRole> | null;
  person: Person.ScientistPerson | null;
  historyLabel: string;
  job: Person.ScientistJob | null;
  education: Person.ScientistEducation | null;
  degree: Person.ScientistDegree | null;
  rank: Person.ScientistRank | null;
  academicRank: Person.ScientistAcademicRank | null;
  citizenship: ReferenceItem | null;
  note: string;
};

export type PerformerStage = {
  id: string | null;
  stage: Stage | null;
  approved: boolean;
  jobDescription?: string;
  approvements: Approvement[];
  payments: Payment[];
  paymentMethods: string;
};

export type Indicator = {
  id: string | null;
  ref: ReferenceItem | null;
  plan: string;
  fact: string;
  note: string;
  year: string;
};

export type Feedline = {
  id: string | null;
  type: Item | null;
  message: ReferenceItem | null;
  detail: string;
  operator: Item | null;
};

export type StateRegistrationReport = {
  id: string | null;
  stageNumber: string;
  stageId: string | null;
  reportType: Item | null;
  citisDate: string;
  stageName: string;
  expectedResults: string;
  interdisciplinaryAproachJustification: string;
  reportName: string;
  reportIkNumber: string;
  reportArchiveNumber: string;
  reportCustomerSendDate: string;
  keyWords: string;
  referat: string;
  file: FileInfo;
};

export type ContestRequest = {
  id: string | null;
  status: Item | null;
  requestStageStartDate: string;
  requestStageEndDate: string;
  projectNote: string;
  projectStageId: string | null; // сокращенная модель
  year: string;
  createdBy: string;
  type: Item | null;
  dataCopyDetail: string;
  newDataCopyDetail?: string; // Вспомогательное поле, в модели на бэке его нет
};

export type ProjectCode = {
  id?: string | null;
  prototypeId?: string;
  code?: string;
  year?: string;
  letter?: ReferenceItem;
  financing?: Financing;
  department?: Department;
  departments?: ReferenceItem[];
  project?: Project | null;
  fundCard?: FundCard;
  incomePlanAmount?: string;
  incomeFactAmount?: string;
  expensePlanAmount?: string;
  expenseFactAmount?: string;
  overheadFactAmount?: string;
  overheadPercent?: string;
  accompliceAmount?: string;
  responsiblePerformer?: Performer;
  codeDepartmentPart?: string;
  codeYearPart?: string;
  codeManualPart?: string;
  financeProvide?: ReferenceItem;
  isOutsideBudget?: boolean;
  isRefersToEconomicAgreement?: boolean;
  calendarPlansByYears?: CalendarPlanByYear[];
  calendarPlansByStages?: CalendarPlanByStage[];
  receivingDocuments?: ReceivingDocument[];
  documents?: Document[];
  isActive?: boolean;
  isFundCardUsed?: boolean;
  calendarPlansTotalAmount?: number;
};

export type CalendarPlanByYear = {
  year: string;
  amount: number;
  factAmount: number;
  ndsPercent: number;
  ndsAmount: number;
  accompliceAmount: number;
  hasEstimate: boolean;
  isInProjectStages: boolean;
};

export type CalendarPlanByStage = {
  stage: Stage;
  amount: number;
  ndsPercent: number;
  ndsAmount: number;
};

export type Partner = {
  id: string | null;
  role: Item | null;
  partner: BasePartner | null;
  contractType: Item | null;
  contractNumber: string;
  startDate: string;
  endDate: string;
  kindWork: string;
  note: string;
  financings: PartnerFinancing[];

  contractName: string;
  leaderFullName: string;
  leaderDegree: string;
  leaderRank: string;
  leaderJobEnterprise: ReferenceItem | null;
  leaderAppointment: string;
  leaderContacts: string;
};

export type PartnerFinancing = {
  id: string | null;
  year: string;
  amount: string;
};

export type FullContestRequest = {
  performers: Performer[];
  indices: Indicator[];
  indicators: Indicator[];
  startDate: string;
  endDate: string;
};

export type CheckResult = {
  value: boolean;
  codes: string;
};

export type ProjectScientist = {
  id: string | null;
  person?: Scientist | null;
};

export type ProjectAssignment = {
  id: string | null;
  projectId: string;
  status: Item<string> | null;
  date: string;
  assignment: string;
  file: FileInfo;
  upToDate?: string;
  projectScientists?: ProjectScientist[];
};

export type ProjectAssignmentStatusCode = 'ADDED' | 'IN_ACTION' | 'COMPLETE' | 'CANCELED' | 'CLOSED';

export type ProjectAssignmentMessage = {
  id: string | null;
  projectAssignment?: IdItem | null;
  message: string | null;
  file: FileInfo;
};
export class ProjectAssignmentStatus {
  static ADDED: ProjectAssignmentStatusCode = 'ADDED';

  static IN_ACTION: ProjectAssignmentStatusCode = 'IN_ACTION';

  static COMPLETE: ProjectAssignmentStatusCode = 'COMPLETE';

  static CANCELED: ProjectAssignmentStatusCode = 'CANCELED';

  static CLOSED: ProjectAssignmentStatusCode = 'CLOSED';
}

export type ProjectPerformerOrder = {
  id: string | null;
  project: ReferenceItem | null;
  type: Item<string> | null;
  number: string | null;
  date: string | null;
  order: string | null;
};

export const PROJECT_PERFORMER_ORDER_TOOLTIP_TXT = 'Отредактируйте текст приказа в соответствии с оригиналом';
