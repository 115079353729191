import { OtherProject } from 'types/models';

type ValidationItem = { isValid: boolean; invalidMessage: string; key: keyof OtherProject.OtherProject };

export function validate(otherProject: OtherProject.OtherProject): ValidationItem[] {
  const isValidTheme = Boolean(otherProject.name);
  const isValidNumber = Boolean(otherProject.number);
  const isValidLeader = Boolean(otherProject.leader);
  const isValidStartDate = Boolean(otherProject.startDate);
  const isValidEndDate = Boolean(otherProject.endDate);
  const isValidRole = Boolean(otherProject.role);

  return [
    { isValid: isValidNumber, invalidMessage: 'Не введен номер проекта', key: 'number' },
    { isValid: isValidTheme, invalidMessage: 'Не указана тема проекта', key: 'name' },
    { isValid: isValidLeader, invalidMessage: 'Не указан руководитель проекта', key: 'leader' },
    { isValid: isValidStartDate, invalidMessage: 'Не указана дата начала', key: 'startDate' },
    { isValid: isValidEndDate, invalidMessage: 'Не указана дата окончания', key: 'endDate' },
    { isValid: isValidRole, invalidMessage: 'Не указано роль', key: 'role' },
  ];
}
