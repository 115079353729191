import { useCallback } from 'react';

import { Customer } from 'types/models';
import { Member } from 'features/SelectPerson';

type Props = {
  entry: Customer | null;
  onFieldChange(row: Customer | null): void;
};

export function useController({ onFieldChange }: Props) {
  const handlePersonChange = useCallback(
    (value: Customer | null) => {
      onFieldChange(value);
    },
    [onFieldChange],
  );

  function convertMemberToCustomer(e: Member | null): Customer | null {
    if (!e?.person) return null;
    return {
      ...e,
      id: null,
      person: e.person,
      historyLabel: '',
    };
  }

  return {
    handlePersonChange,
    convertMemberToCustomer,
  };
}
