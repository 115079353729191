import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const QnaAnswerViewTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, id } = props;
  const look: F.QuestionFormLook = {
    id: String(id),
    apiID: 'GetQnaQuestion',
    template: 'QnaQuestionForm',
    viewMode: true,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Просмотр вопроса" size="large" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(QnaAnswerViewTemplate);
