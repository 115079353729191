import * as R from 'ramda';

import { Rid, RidAuthor, RidSubject } from 'types/models';

import { convertSecurityDocument } from 'services/BackendAPI/configurations/securityDocument/converters';
import { convertRefElement, convertProject } from 'services/BackendAPI/configurations/project/converters';
import { convertServerPerson } from 'services/BackendAPI/configurations/author/converters';
import { convertDepartment, convertPersonHistory } from 'services/BackendAPI/configurations/converters';
import { convertServerParticipation } from 'services/BackendAPI/configurations/participation/converters';
import { Settings } from 'types/models/common';
import { convertMaybeArray, getId, getText, convertDocument, convertReference, convertItem } from '../commonConverters';

export function convertRid(r: any, settings: Settings): Rid {
  const serverDocuments = r.Documents?.Document || [];
  const serverForeignDocuments = r.ForeignDocuments?.Document || [];

  const localDocuments = convertMaybeArray(serverDocuments, R.partial(convertDocument, ['local']));
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, R.partial(convertDocument, ['foreign']));

  const documents = [...localDocuments, ...foreignDocuments];
  return {
    authors: convertMaybeArray(r.Authors.Author, a => convertRidAuthor(a, settings)),
    code: getText(r.Code),
    commercializationDate: getText(r.CommercializationDate),
    commercializationDecision: convertReference(r.CommercializationDecision),
    commercializationProtocol: getText(r.CommercializationProtocol),
    departments: convertMaybeArray(r.Departments.Department, convertDepartment),
    description: getText(r.Description),
    domainKnowledges: convertMaybeArray(r.DomainKnowledges.DomainKnowledge, convertReference),
    fctekDate: getText(r.FctekDate),
    fctekDecision: convertReference(r.FctekDecision),
    fctekProtocol: getText(r.FctekProtocol),
    grntis: convertMaybeArray(r.Grntis.Grnti, convertReference),
    keyWords: getText(r.KeyWords),
    lksetss: convertMaybeArray(r.Lksetss.Lksets, convertReference),
    ntrStrategies: convertMaybeArray(r.NtrStrategies.NtrStrategy, convertReference),
    okveds: convertMaybeArray(r.Okveds.Okved, convertReference),
    participations: convertMaybeArray(r.Participations.Participation, p => convertServerParticipation(p, settings)),
    pnis: convertMaybeArray(r.Pnis.Pni, convertReference),
    pnmitrs: convertMaybeArray(r.Pnmitrs.Pnmitr, convertReference),
    pnrs: convertMaybeArray(r.Pnrs.Pnr, convertReference),
    priorityTechnologies: convertMaybeArray(r.PriorityTechnologies.PriorityTechnology, convertReference),
    project: r.Project ? convertProject(r.Project, settings) : null,
    scienceBrunches: convertMaybeArray(r.ScienceBrunches.ScienceBrunch, convertReference),
    scienceDomainInterrests: convertMaybeArray(r.ScienceDomainInterrests.ScienceDomainInterrest, convertReference),
    scope: getText(r.Scope),
    securityDocuments: convertMaybeArray(r.SecurityDocuments.SecurityDocument, d => convertSecurityDocument(d, settings)),
    selfProductionDate: getText(r.SelfProductionDate),
    selfProductionNote: getText(r.SelfProductionNote),
    selfProductionNumber: getText(r.SelfProductionNumber),
    subjects: convertMaybeArray(r.Subjects.Subject, convertRidSubject),
    technologyPlatforms: convertMaybeArray(r.TechnologyPlatforms.TechnologyPlatform, convertReference),
    type: convertReference(r.Type),
    udks: convertMaybeArray(r.Udks.Udk, convertReference),
    id: getId(r),
    name: getText(r.Name),
    documents,
  };
}
export function convertRidSubject(s: any): RidSubject {
  return {
    id: getId(s),
    code: getText(s.Code),
    name: getText(s.Name),
    rntds: convertMaybeArray(s.Rntds.Rntd, convertRefElement),
    type: convertItem(s.Type),
  };
}

export function convertRidAuthor(r: any, settings: Settings): RidAuthor {
  return {
    ...convertPersonHistory(r),
    id: getId(r),
    name: getText(r.Name),
    person: convertServerPerson(r.Person, settings),
    ...convertPersonHistory(r),
  };
}
