import { GetRequestFundListRequestData } from 'services/BackendAPI/configurations/funds/types';
import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { Toolbar } from './Toolbar/Toolbar';

type Arguments = {
  selectedFund: Table.Entry | null;
  year: string;
  isOnlyByDepartmentParameter: boolean;
};

export function GetRequestFundList({
  selectedFund,
  year,
  isOnlyByDepartmentParameter,
}: Arguments): Table.Specification<State, GetRequestFundListRequestData> {
  return {
    apiID: 'GetRequestFundList',
    header: {
      firstLevel: {
        title: `Заявки на оплату сотрудникам из Фонда ${selectedFund?.faculty || 'подразделения'}`,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    requestData: {
      onlyByDepartment: isOnlyByDepartmentParameter,
    },
    useCustomController: makeUseCustomController({ selectedFund, year }),
  };
}
