import { Tender } from 'types/models';

export function getMockTender(): Tender.Tender {
  const tender: Tender.Tender = {
    id: null,
    name: '',
    type: null,
    collectiveType: null,
    customers: [],
    kindTrade: { id: '', label: '' },
    dateUniversity: '',
    contactFace: null,
    dateOpenEnvelope: '',
    timeOpenEnvelope: '',
    dateExamenation: '',
    announcementDate: '',
    isMarketplace: false,
    marketplace: null,
    // marketplaceSignatory: null,
    marketplaceAmount: '',
    marketplaceNote: '',
    dateSummingUp: '',
    lots: [],
    url: '',
    categories: [],
    participants: [],
    scienceDomainInterrests: [],
    documents: [],
    requestType: null,
    contacts: [],
  };
  return tender;
}

export function getMockLot(): Tender.Lot {
  const lot: Tender.Lot = {
    id: '',
    number: '',
    code: '',
    periodNote: '',
    theme: '',
    startDate: '',
    endDate: '',
    limitAmount: 0,
    contractCount: 0,
    contractAmount: 0,
    isSupportRequest: false,
    requestSupportAmount: 0,
    isSupportContract: false,
    contractSupportAmount: 0,
    isSupportWarranty: false,
    warrantySupportAmount: 0,
    prices: [],
  };
  return lot;
}
