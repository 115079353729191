import { MipIndicator } from 'types/models/MipIndicator';

export function getMockMipIndicator(): MipIndicator {
  return {
    id: null,
    year: '',
    averageHeadcount: '',
    profit: '',
    revenue: '',
  };
}
