import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const ProjectAssignmentEditTemplate: React.FC<F.TemplateProps> = ({ id, isOpen, onClose }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look = {
    apiID: 'GetProjectAssignment',
    id,
    viewMode: true,
    template: 'ProjectAssignmentForm',
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ProjectAssignmentEditTemplate);
