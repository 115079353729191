export class Quarters {
  static FIRST_QUARTER = {
    id: '1',
    name: '1',
  };

  static SECOND_QUARTER = {
    id: '2',
    name: '2',
  };

  static THIRD_QUARTER = {
    id: '3',
    name: '3',
  };

  static FOURTH_QUARTER = {
    id: '4',
    name: '4',
  };
}
