import React, { memo } from 'react';

import { FormComponent, Button, ButtonMode, CustomSelect } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  onClose: () => void;
  publicationId: string;
  relatedTableState: Table.State;
  onSuccess: () => void;
};

const PublicationTransformComponent = ({ onClose, publicationId, relatedTableState, onSuccess }: Props) => {
  const {
    submitForm,
    magazineArticlePublication,
    modalTableMagazineConverter,
    magazineTableConfig,
    handlePreprintChange,
    preprintOriginal,
    preprintDialogTitle,
    preprintButtonLabel,
  } = useController({
    onClose,
    publicationId,
    onSuccess,
    relatedTableState,
  });
  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Библиографическая ссылка">
          <div>{magazineArticlePublication?.fields?.bibliographicRecord}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field>
          <CustomSelect
            title={preprintDialogTitle}
            original={preprintOriginal}
            relationTableModalTitle={preprintDialogTitle}
            onChange={handlePreprintChange}
            modalTableRowConverter={modalTableMagazineConverter}
            modalTableSpecification={magazineTableConfig}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Actions>
        <Button mode={ButtonMode.primary} text={preprintButtonLabel} onClick={submitForm} />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = memo(PublicationTransformComponent);
