import { ScientistJob } from 'types/models/Person';
import { Person } from 'types/models';
import { getTimestamp, getCurrentDateTimestamp } from 'utils/Helpers';

export function findActualJob(person: Person.ScientistPerson | null): ScientistJob | null {
  const jobs = person?.scientistJobs || [];
  const actualDate = getCurrentDateTimestamp();
  let actualJob: ScientistJob | null = null;
  const sortedJobs = jobs.sort((a, b) => getTimestamp(b.dateReceipt || '') - getTimestamp(a.dateReceipt || ''));
  let actualJobPriority = 5;
  sortedJobs.forEach(job => {
    if (job.isActive) {
      const startDate = job.dateReceipt ? getTimestamp(job.dateReceipt) || null : null;
      const endDate = job.dateDismissal ? getTimestamp(job.dateDismissal) || null : null;
      if ((startDate === null || startDate <= actualDate) && (endDate === null || endDate >= actualDate)) {
        if (job.status.value === 'LOCAL') {
          if (job.staffState?.customFields?.code === 'STAFF') {
            if (actualJobPriority > 1) {
              actualJob = job;
              actualJobPriority = 1;
            }
          } else if (actualJobPriority > 2) {
            actualJob = job;
            actualJobPriority = 2;
          }
        } else if (job.staffState?.customFields?.code === 'STAFF') {
          if (actualJobPriority > 3) {
            actualJob = job;
            actualJobPriority = 3;
          }
        } else if (actualJobPriority > 4) {
          actualJob = job;
          actualJobPriority = 4;
        }
      }
    }
  });
  return actualJob || null;
}
