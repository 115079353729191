import { useMemo } from 'react';
import { CommunityType } from 'types/models/Community';

export type UsedNames = {
  upperNominative: string;
  upperGenitive: string;
  upperPrepositional: string;
  upperNominativePlural: string;
  upperGenitivePlural: string;
  upperPrepositionalPlural: string;
  lowerNominative: string;
  lowerGenitive: string;
  lowerPrepositional: string;
  lowerNominativePlural: string;
  lowerGenitivePlural: string;
  lowerPrepositionalPlural: string;
};

export type State = {
  communityTypes: CommunityType[];
  usedNames: UsedNames;
  isMembersTabHidden?: boolean;
};

export function makeUseCustomController(communityTypes: CommunityType[], isMembersTabHidden?: boolean) {
  return function useCustomController(): State {
    const usedNames: UsedNames = useMemo(() => {
      if (!communityTypes.includes('SCIENTIFIC_COUNCIL'))
        return {
          upperNominative: 'Диссертационный совет',
          upperGenitive: 'Диссертационного совета',
          upperPrepositional: 'Диссертационном совете',
          upperNominativePlural: 'Диссертационные советы',
          upperGenitivePlural: 'Диссертационных советов',
          upperPrepositionalPlural: 'Диссертационных советах',
          lowerNominative: 'диссертационный совет',
          lowerGenitive: 'диссертационного совета',
          lowerPrepositional: 'диссертационном совете',
          lowerNominativePlural: 'диссертационные советы',
          lowerGenitivePlural: 'диссертационных советов',
          lowerPrepositionalPlural: 'диссертационных советах',
        };
      return {
        upperNominative: 'Научное сообщество',
        upperGenitive: 'Научного сообщества',
        upperPrepositional: 'Научном сообществе',
        upperNominativePlural: 'Наунчые сообщества',
        upperGenitivePlural: 'Научных сообществ',
        upperPrepositionalPlural: 'Научных сообществах',
        lowerNominative: 'научное сообщество',
        lowerGenitive: 'научного сообщества',
        lowerPrepositional: 'научном сообществе',
        lowerNominativePlural: 'научные сообщества',
        lowerGenitivePlural: 'научных сообществ',
        lowerPrepositionalPlural: 'научных сообществах',
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityTypes]);

    return { communityTypes, usedNames, isMembersTabHidden };
  };
}
