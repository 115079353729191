import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { ReferenceElementSetting, ReferenceFilter, ReferenceItem, ReferenceRequestData } from 'components/Reference';
import { HistoryEntry } from 'types/models';
import {
  convertMaybeArray,
  convertReference,
  makeParametersXMLElement,
  makePresetFiltersXML,
  makeTableRequestXMLConverter,
} from '../commonConverters';

import { GetRequestData, FirstLevelHeaderCustomPanelStateHolder } from '../types';

import { convertServerHistoryEntry, convertServerReferenceMetadata } from './converters';
import { DeleteReferenceRequest, GetReferenceElementsRequest, ReferenceRequest, SaveReferenceRequest } from './types';

const configurations = {
  DeleteReferenceElement: makeBackendAPIConfiguration({
    id: 'DeleteReferenceElement',
    endpoint: '/msa/service/commands/DeleteReferenceElement',
    convertInputToXMLElement: ({ name, rowId }: DeleteReferenceRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteReferenceElement' },
        Reference: {
          _attr: { name, id: rowId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetReferenceModificationHistory: makeBackendAPIConfiguration({
    id: 'GetReferenceModificationHistory',
    endpoint: '/msa/service/commands/GetReferenceModificationHistory',
    convertInputToXMLElement: ({ name, rowId }: DeleteReferenceRequest) => ({
      Request: {
        _attr: { commandName: 'GetReferenceModificationHistory' },
        History: {
          _attr: { name, id: rowId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): HistoryEntry[] =>
      convertMaybeArray(response.Response.HistoryEntry, convertServerHistoryEntry),
  }),
  GetReferenceMetadata: makeBackendAPIConfiguration({
    id: 'GetReferenceMetadata',
    endpoint: '/msa/service/commands/GetReferenceMetadata',
    convertInputToXMLElement: ({ name }: ReferenceRequest) => ({
      Request: {
        _attr: { commandName: 'GetReferenceMetadata' },
        Reference: {
          _attr: { name },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerReferenceMetadata(response.Response.ReferenceMetadata),
  }),
  SaveReferenceElement: makeBackendAPIConfiguration({
    id: 'SaveReferenceElement',
    endpoint: '/msa/service/commands/SaveReferenceElement',
    convertInputToXMLElement: ({ name, fields, rowId }: SaveReferenceRequest) => {
      const requestFields = fields.reduce((acc, field) => {
        if ((field.type === 'REFERENCE' && (!field.value || !field.value?.id)) || field.value === null || field?.value === '') {
          return acc;
        }

        const getText = () => {
          if (field.type === 'REFERENCE') {
            return field.value?.id;
          }

          if (field.type === 'ENUMERATION') {
            return field.value?.value;
          }

          return String(field.value);
        };

        return {
          ...acc,
          [field.name]: {
            _attr: { type: field.type },
            _text: getText(),
          },
        };
      }, {});
      return {
        Request: {
          _attr: { commandName: 'SaveReferenceElement' },
          Reference: {
            _attr: { name, ...(rowId !== null ? { id: rowId } : {}) },
            ...requestFields,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetReferenceElements: makeBackendAPIConfiguration({
    id: 'GetReferenceElements',
    endpoint: '/msa/service/commands/GetReferenceElements',
    convertInputToXMLElement: ({ referenceName, filters, fieldVisibleMode, childIds }: GetReferenceElementsRequest) => ({
      Request: {
        _attr: {
          commandName: 'GetReferenceElements',
        },
        Reference: {
          _attr: {
            name: referenceName,
            fieldVisibleMode: fieldVisibleMode || 'ALL',
            subReferenceFieldVisibleMode: 'NONE',
          },
          Filter: filters.map(({ key, values }: ReferenceFilter) => ({
            _attr: { name: key },
            Value: values,
          })),
          ...(childIds ? { Element: childIds.map(x => ({ _attr: { id: x } })) } : {}),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ReferenceItem[] =>
      convertMaybeArray(response.Response.Reference, convertReference),
  }),
};

export const referenceTableConfigurations = {
  GetReferenceElementList: makeBackendTableAPIConfiguration(
    'GetReferenceElementList',
    makeTableRequestXMLConverter(
      'GetReferenceElementList',
      ({
        requestData,
        panelState,
      }: GetRequestData<ReferenceRequestData> &
        FirstLevelHeaderCustomPanelStateHolder<{
          restricted?: 'All' | 'Actual';
          level?: boolean;
          columnFilters: ReferenceFilter[];
        }>) => {
        const filters: ReferenceFilter[] = [
          ...(typeof panelState?.level !== 'undefined'
            ? [
                {
                  key: 'restricted',
                  values: [...(panelState?.restricted === 'All' ? ['true'] : []), 'false'],
                },
              ]
            : []),
          ...(!!panelState?.level
            ? [
                {
                  key: 'level',
                  values: ['1'],
                },
              ]
            : []),
        ];
        return {
          ...makePresetFiltersXML([...(requestData?.filters ?? []), ...filters]),
          ...makeParametersXMLElement([{ name: 'reference', value: requestData?.name ?? '' }]),
        };
      },
    ),
  ),
  GetReferences: makeBackendTableAPIConfiguration(
    'GetReferences',
    makeTableRequestXMLConverter('GetReferences', input => input),
  ),
  GetReferenceElement: makeBackendAPIConfiguration({
    id: 'GetReferenceElement',
    endpoint: '/msa/service/commands/GetReferenceElement',
    convertInputToXMLElement: ({
      refName,
      code,
      elementSettings,
    }: {
      refName: string;
      code?: string;
      elementSettings?: ReferenceElementSetting;
    }) => ({
      Request: {
        _attr: { commandName: 'GetReferenceElement' },
        Reference: {
          _attr: { name: refName },
          ...(code ? { Element: { _attr: { code } } } : {}),
          ...(elementSettings
            ? {
                Element: {
                  _attr: { [`${elementSettings.key}`]: elementSettings.value },
                },
              }
            : {}),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertReference(response.Response.Reference),
  }),
};

export const referenceConfigurations = { ...referenceTableConfigurations, ...configurations };
