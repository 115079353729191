import { Parameter } from 'types/models';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateCustomSelect = (customSelectParameter: Parameter): ValidatorResult => {
  const { required, value } = customSelectParameter;

  const isValid = required ? !!value : true;

  return { isValid, message: isValid ? '' : `Выберите значение для поля "${customSelectParameter.description}"` };
};

export default validateCustomSelect;
