import { Event } from 'types/models';
import { useCallback, useLayoutEffect, useState } from 'react';

type Props = {
  departmentOptions: Event.Department[];
  onDepartmentChange(row: Event.Department): void;
  submitCustomComponent?: () => void;
};

export function useController(props: Props) {
  const { departmentOptions, onDepartmentChange, submitCustomComponent } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>();

  const handleDepartmentChange = useCallback(
    (depIdx: number, noSubmit?: boolean) => {
      const i = depIdx || selectedRowIndex || 0;
      const department = departmentOptions[i];
      onDepartmentChange({ ...department, isFinancing: true });
      if (!noSubmit && submitCustomComponent) {
        submitCustomComponent();
      }
    },
    [onDepartmentChange, departmentOptions, selectedRowIndex, submitCustomComponent],
  );

  useLayoutEffect(() => {
    if (departmentOptions.length > 0) {
      handleDepartmentChange(0, true);
    }
  }, []); // eslint-disable-line

  return {
    handleDepartmentChange,
    selectedRowIndex,
    setSelectedRowIndex,
  };
}
