import React from 'react';

import { FormComponent, TextArea, TextAreaMode, UploadFile } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose: () => void;
};

function QnaAnswerForm({ onClose }: Props) {
  const { questionText, themeText, formFields, saveQnaAnswerHandler } = useController({
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveQnaAnswerHandler} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Тема">
            <div>{themeText ?? ''}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вопрос">
            <div>{questionText ?? ''}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Файл вопроса">
            <UploadFile file={formFields.questionFile.value} onChange={formFields.questionFile.onChange} isDisabled />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ответ" isRequired={formFields.answer.required}>
            <TextArea
              mode={TextAreaMode.wysiwyg}
              settings={{ rows: 7 }}
              value={formFields.answer.value}
              onChange={formFields.answer.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Файл ответа">
            <UploadFile file={formFields.answerFile.value} onChange={formFields.answerFile.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(QnaAnswerForm);
