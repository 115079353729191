import { showNotification } from 'features/Notifications';
import { useCallback, useState } from 'react';
import { Form } from 'types/models';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { PublicationUnavailableDeleteResultCode } from 'utils/Enums/PublicationDeleteResult';

type DeletePublicationArgs = { publicationTypeCode?: Form.PublicationTypeCode; publicationId: string };

type Props = {
  isNeedUpdateGridOnSuccess?: boolean;
};

const useDeletePublication = ({ isNeedUpdateGridOnSuccess = true }: Props = { isNeedUpdateGridOnSuccess: true }) => {
  const tableStreams = useLocalTableStreams();
  const [isArticlesWarningPopupOpen, setIsArticlesWarningPopupOpen] = useState<boolean>(false);
  const [isEditArticleSourceOpen, setIsEditArticleSourceOpen] = useState<boolean>(false);

  const { methods: deletePublicationAPI } = BackendAPI.useBackendAPI('DeletePublication');

  const deletePublication = useCallback(
    ({ publicationTypeCode, publicationId }: DeletePublicationArgs) => {
      deletePublicationAPI.callAPI(
        {
          publicationId,
        },
        {
          onSuccessfullCall: ({ data }) => {
            const { isSuccess, unavailableDeleteReasonCode } = data;
            if (isSuccess) {
              showNotification({ message: 'Запись удалена', theme: 'success' });
              if (isNeedUpdateGridOnSuccess) {
                tableStreams.reloadTable.push();
              }
            } else {
              switch (unavailableDeleteReasonCode) {
                case PublicationUnavailableDeleteResultCode.HAS_LINKED_CHILDS:
                  const publicationWithArticleCodes = [
                    // все виды сборников
                    PublicationTypes.COMPILATION_ARTICLES.code,
                    PublicationTypes.COMPILATION_CONFERENCE.code,
                    PublicationTypes.COMPILATION_OTHER.code,
                    PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
                    // журналы
                    PublicationTypes.MAGAZINE.code,
                    // Другое издание
                    PublicationTypes.OTHER_BOOK.code,
                    // монография
                    PublicationTypes.MONOGRAPH.code,
                  ];

                  const isPublicationWithArticle = publicationWithArticleCodes.some(code => code === publicationTypeCode);

                  if (isPublicationWithArticle) {
                    setIsArticlesWarningPopupOpen(true);
                  } else {
                    showNotification({ message: 'Ошибка при удалении записи', theme: 'danger' });
                  }
                  break;
                case PublicationUnavailableDeleteResultCode.SENDED_TO_LIBRARY:
                  showNotification({
                    message: 'Публикацию нельзя удалить, она была отправлена в библиотеку и ожидает обработки',
                    theme: 'danger',
                  });
                  break;
                default:
                  showNotification({ message: 'Ошибка при удалении записи', theme: 'danger' });
                  break;
              }
            }
          },
          onFailedCall: () => {},
        },
      );
    },
    [deletePublicationAPI, isNeedUpdateGridOnSuccess, tableStreams.reloadTable],
  );

  const handleCloseArticlesWarningPopup = useCallback(() => {
    setIsArticlesWarningPopupOpen(false);
  }, []);

  const handleOpenEditArticleSource = useCallback(() => {
    setIsArticlesWarningPopupOpen(false);
    setIsEditArticleSourceOpen(true);
  }, []);

  const handleCloseEditArticleSource = useCallback(() => {
    setIsEditArticleSourceOpen(false);
  }, []);

  const handleSubmitDeleteWarningPopup = handleOpenEditArticleSource;

  return {
    isEditArticleSourceOpen,
    isArticlesWarningPopupOpen,
    deletePublication,
    handleSubmitDeleteWarningPopup,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
  };
};

export default useDeletePublication;
