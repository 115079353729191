import { useCallback, useLayoutEffect, useState } from 'react';

import { RecordStatus as RS, Event } from 'types/models';
import { showNotification } from 'features/Notifications';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { EventFormat } from 'utils/Enums';
import { Document } from 'types/models';
import { mockToSave } from 'features/Form/looks/event/views/ApproveEvent/mockToSave';
import { useAppDataContext } from 'features/AppData/context';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  id: string;
  typeCode: Event.EventTypeCode;
  onClose: () => void;
};

const useController = ({ id, onClose, typeCode }: Props) => {
  const { settings } = useAppDataContext();
  const organizationName = settings?.organization?.shortName || null;

  const { methods: approveEvent } = BackendAPI.useBackendAPI('ApproveEvent');
  const { methods: getEvent } = BackendAPI.useBackendAPI('GetEvent');
  const { methods: saveEvent } = BackendAPI.useBackendAPI('SaveEvent');
  const [eventData, setEventData] = useState<null | Event.Data>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleApproveEvent = useCallback(() => {
    setIsLoading(true);
    if (eventData !== null) {
      const nextRecordStatus: RS.Status = {
        label: 'Утверждена',
        value: RecordStatus.APPROVED,
      };

      if (eventData.status.status === RecordStatus.APPROVED) {
        nextRecordStatus.label = 'Добавлена';
        nextRecordStatus.value = RecordStatus.ADDED;
      }

      approveEvent.callAPI(
        {
          eventId: id,
          disapprove: nextRecordStatus.value === RecordStatus.ADDED,
        },
        {
          onSuccessfullCall: () => {
            setIsLoading(false);

            if (eventData.status.status === RecordStatus.APPROVED) {
              showNotification({ message: 'Мероприятие переведено в статус "добавлена"', theme: 'success' });
            } else if (eventData.status.status === RecordStatus.ADDED) {
              showNotification({ message: 'Мероприятие утверждено', theme: 'success' });
            }
            onClose();
          },
          onFailedCall: () => {
            setIsLoading(false);
          },
        },
      );
    }
  }, [eventData, onClose, approveEvent, id]);

  const loadEventById = useCallback(
    (eventId: string) => {
      getEvent.callAPI(
        {
          simpleFields: {
            type: typeCode,
            domain: 'EVENT',
            isInSummaryPlan: false,
            isCompilationPlanned: false,
            IsMagazineReleasePlanned: false,
            isStudent: false,
            memberCountPlan: 0,
            memberCountFact: 0,
          },
          attrIdFields: {},
          arrayFields: {
            Grntis: { translation: [] },
            MinistryEducations: { translation: [] },
            CriticalTechnologies: { translation: [] },
            Pnmitrs: { translation: [] },
            Pnrs: { translation: [] },
            Pnis: { translation: [] },
            Partners: { translation: [] },
          },
          id: eventId,
          format: EventFormat.OFFLINE,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setIsLoading(false);
            setEventData(data);
          },
          onFailedCall: () => {
            setIsLoading(false);
          },
        },
      );
    },
    [getEvent, typeCode],
  );

  const handleDocumentsChange = useCallback(
    (docs: Document[]) => {
      //const isMessage = !!publicationMessageType?.id;
      if (!eventData) {
        return false;
      }
      const fieldsForRequest = mockToSave(id, eventData);
      saveEvent.callAPI(
        {
          ...fieldsForRequest,
          feedline: undefined,
          ...eventData,
          documents: docs,
        },
        {
          onSuccessfullCall: (result: any) => {
            const data = result.data as Event.Data;
            showNotification({
              theme: data.success ? 'success' : 'danger',
              message: data.success
                ? 'Конференция успешно сохранена!'
                : data.message || 'Произошла ошибка при сохранении конференции!',
            });
            setIsLoading(true);
            loadEventById(id);
          },
          onFailedCall: () => {},
        },
      );
    },
    [eventData, id, loadEventById, saveEvent],
  );

  useLayoutEffect(() => {
    setIsLoading(true);
    loadEventById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleApproveEvent,
    eventData,
    isLoading,
    handleDocumentsChange,
    organizationName,
  };
};

export default useController;
