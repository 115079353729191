import React from 'react';

import { DataGrid } from 'features/Table';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

type Props = {
  authorPaymentId: string;
  paymentStatus: AuthorPaymentRequestStatus;
  isDisabledToolbar: boolean;
};

export function MessageLog({ authorPaymentId, isDisabledToolbar, paymentStatus }: Props) {
  return (
    <DataGrid
      specification={GetAuthorPaymentRequestFeedlineList({ paymentId: authorPaymentId, isDisabledToolbar, paymentStatus })}
    />
  );
}
