import React, { memo } from 'react';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { Form } from 'features/Form';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

const ToolbarTemplate = ({ tableState }: Props) => {
  const { toolbarButtons, isViewFormOpen, closeViewForm, look, title } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={toolbarButtons} />
      <Modal isOpen={isViewFormOpen} onClose={closeViewForm} title={title} size="full" viewMode>
        <Form look={look} onClose={closeViewForm} />
      </Modal>
    </>
  );
};

export default memo(ToolbarTemplate);
