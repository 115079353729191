import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButtonProps, buttonIcons } from 'components';

import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { Event, Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { EventTypes } from 'utils/Enums/EventTypes';
import { ParticipationEventSource } from 'types/models/Participation';
import { EventTypeCode, Participations } from 'types/models/Event';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

// eslint-disable-next-line max-len
import { TypeControllerIsForm } from 'features/Table/specifications/GetConferenceEventList/LeftPanelForThirdLevel/modalTemplates/ConferenceParticipationTemplate/models/typeControllerIsForm';
import { DetailProps } from '..';
import { EventType } from './eventTypes';

type Props = {
  tableState: Table.State;
  actualType: EventType;
};

export function useController({ tableState, actualType }: Props & DetailProps) {
  const suchLikeEventStreams = useLocalSuchLikeEventStreams();
  const { userPermission, settings } = useAppDataContext();

  const organizationName = settings?.organization?.shortName;

  const openDuplicatesForm = useCallback(() => {
    suchLikeEventStreams.openSuchLikeEvents.push();
  }, [suchLikeEventStreams.openSuchLikeEvents]);
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRow = tableState.selectedRows.length ? tableState.selectedRows[0] : null;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [eventTypeCode, setEventTypeCode] = useState<EventTypeCode>(EventTypes.EVENT_CONCERT.code);

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const [isAddParticipationFormOpen, setIsAddParticipationFormOpen] = useState<boolean>(false);
  const [isEditParticipationFormOpen, setIsEditParticipationFormOpen] = useState<boolean>(false);
  const [isViewParticipationFormOpen, setIsViewParticipationFormOpen] = useState<boolean>(false);
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [sourceEvent, setSourceEvent] = useState<ParticipationEventSource | null>(null);
  const [participation, setParticipation] = useState<Participations | null>(null);
  const [isEditSubdivisionModal, setIsEditSubdivisionModal] = useState<boolean>(false);
  const [isApproveParticipationModal, setIsApproveParticipationModal] = useState<boolean>(false);
  const [isShowAwards, setIsShowAwards] = useState<boolean>(false);
  const [sourcePublication, setSourcePublication] = useState<Event.Source | null>(null);
  const onCloseParticipationForm = () => {
    setSourcePublication(null);
  };
  const controllerIsForm: TypeControllerIsForm = {
    isShowAwards,
    setIsShowAwards,
    isApproveParticipationModal,
    isEditSubdivisionModal,
    sourceEvent,
    participation,
    isView: isViewParticipationFormOpen,
    isEdit: isEditParticipationFormOpen,
    isAdd: isAddParticipationFormOpen,
    isDelete: isDeletePopup,
    setParticipation,
    sourcePublication,
    setSourcePublication,
    onCloseParticipationForm,
    setAdd: setIsAddParticipationFormOpen,
    setEdit: setIsEditParticipationFormOpen,
    setView: setIsViewParticipationFormOpen,
    setDelete: setIsDeletePopup,
    setSourceEvent,
    setIsEditSubdivisionModal,
    setIsApproveParticipationModal,
  };

  const isDeletePermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits[`CONCERT_DELETE_${selectedRow?.['id:EventStatus']}` as keyof Permits]);
  }, [selectedRow, userPermission]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Event',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setEventTypeCode(actualType.code);
    setNextPublicationName('');
    openDuplicatesForm();
  }, [setEventTypeCode, actualType, setNextPublicationName, openDuplicatesForm]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },

      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
        profilePermissionName: Permits.CONCERT_VIEW,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: false,
        onClick: handleAddButtonClick,
        profilePermissionName: Permits.CONCERT_ADD,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        isDisabled: !selectedRow,
        onClick: handleEditButtonClick,
        profilePermissionName: Permits.CONCERT_EDIT_ADDED,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: !selectedRow || !isDeletePermitted,
        onClick: handleDeleteButtonClick,
        profilePermissionName: [Permits.CONCERT_DELETE_ADDED, Permits.CONCERT_DELETE_APPROVED],
      },
    ],
    [
      selectedRow,
      handleViewButtonClick,
      handleAddButtonClick,
      handleEditButtonClick,
      isDeletePermitted,
      handleDeleteButtonClick,
      handleHelpButtonClick,
    ],
  );

  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  return {
    eventTypeCode,
    buttons,
    handleViewButtonClick,
    handleAddButtonClick,
    handleEditButtonClick,
    handleDeleteButtonClick,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    organizationName,
  };
}
