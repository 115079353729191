import React from 'react';
import { SecurityDocumentPayment } from 'types/models';
import { ListEdit } from 'components';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { PaymentInputs } from './PaymentInputs/PaymentInputs';
import { validatePaymentByRequest } from './validatePaymentByRequest';
import useDownloadButton from './useDownloadButton';

type Props = {
  disabled?: boolean;
  requestPayments: SecurityDocumentPayment[];
  handleRequestPaymentsChange: (value: SecurityDocumentPayment[]) => void;
};

export function PaymentByRequest({ requestPayments, handleRequestPaymentsChange, disabled }: Props) {
  const { extraToolbarButtons } = useDownloadButton();
  return (
    <ListEdit
      withMessages
      isDeleteConfirmEnabled
      rows={requestPayments}
      isToolbarDisabled={disabled}
      extraToolbarButtons={extraToolbarButtons}
      isFullScreenedTable
      onChange={handleRequestPaymentsChange}
      columns={[
        {
          label: '№',
          formatValue: (_, rowIndex) => rowIndex + 1,
          styles: { width: '5%' },
        },
        {
          label: 'Назначение',
          formatValue: x => x?.paymentPurposeRequest?.label || '',
        },
        {
          label: 'Сумма',
          formatValue: x => formatNumber(x?.amount || '0'),
        },
        {
          label: 'Оплачено',
          formatValue: x => x?.payDate || '',
        },
        {
          label: 'Файл документа',
          formatValue: x => x?.file?.name || '',
        },
      ]}
      columnIndexesForSumTotal={[2, 3]}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => <PaymentInputs payment={row} onPaymentChange={onChange as any} isRequestPayments />,
        validation: {
          checkIsValid: (row, index, list, mode) =>
            validatePaymentByRequest(row, mode, list, index).every(({ isValid }) => isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePaymentByRequest(row, mode, list, index);
            showErrorsMessages(validationInfo?.filter(({ isValid }) => !isValid).map(({ invalidMessage }) => invalidMessage));
          },
        },
      }}
    />
  );
}
