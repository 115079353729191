import React from 'react';

import { Person } from 'types/models';
import { ListEdit, SectionTitle, FormComponent } from 'components';
import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  person: Person.ScientistPerson | null;
};

export function ScientificInterests(props: Props) {
  const { person } = props;

  if (!person) {
    return null;
  }

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title="Научные интересы" />
        <FormComponent.Line>
          <FormComponent.Field label="Направления деятельности">
            <span>{person.activities}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Основные научные результаты">
            <span>{person.scientificResults}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ключевые слова на русском языке">
            <span>{person.keywordsRus}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ключевые слова на английском языке">
            <span>{person.keywordsEnglish}</span>
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>

      <FormComponent.Column>
        <SectionTitle title="Области научных интересов" />
        <FormComponent.Line>
          <ListEdit
            title="Коды ГРНТИ"
            maxHeight="220px"
            rows={person.grntis}
            onChange={() => {}}
            hideToolbar
            columns={[{ label: '', formatValue: x => x.label }]}
            isDeleteConfirmEnabled
            withMessages
            withoutHead
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "ГРНТИ"',
              modalTableRowConverter: row => ({ id: row.id, label: `${row.code}. ${row.name} (Группа: ${row.parent})` }),
              modalTableSpecification: GetReferenceElementList({
                requestData: {
                  filters: [],
                  name: 'RefGrnti',
                },
              }),
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEdit
            title="Коды УДК"
            maxHeight="220px"
            rows={person.udks}
            onChange={() => {}}
            hideToolbar
            columns={[{ label: '', formatValue: x => x.label }]}
            isDeleteConfirmEnabled
            withMessages
            withoutHead
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "УДК"',
              modalTableRowConverter: row => ({ id: row.id, label: row.label }),
              modalTableSpecification: GetReferenceElementList({
                requestData: {
                  filters: [],
                  name: 'RefUdk',
                },
              }),
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEdit
            title="Коды международной классификации отраслей наук (ОЕСД)"
            maxHeight="220px"
            rows={person.oecds}
            onChange={() => {}}
            hideToolbar
            columns={[{ label: '', formatValue: x => x.label }]}
            isDeleteConfirmEnabled
            withMessages
            withoutHead
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "ОЕСД"',
              modalTableRowConverter: row => ({ id: row.id, label: row.label }),
              modalTableSpecification: GetReferenceElementList({
                requestData: {
                  filters: [],
                  name: 'RefOecd',
                },
              }),
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEdit
            title="Области знаний"
            maxHeight="220px"
            rows={person.domainKnowledges}
            onChange={() => {}}
            hideToolbar
            columns={[{ label: '', formatValue: x => x.label }]}
            isDeleteConfirmEnabled
            withMessages
            withoutHead
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "Области знаний"',
              modalTableRowConverter: row => ({ id: row.id, label: row.label }),
              modalTableSpecification: GetReferenceElementList({
                requestData: {
                  filters: [],
                  name: 'RefDomainKnowledge',
                },
              }),
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEdit
            title="Специальности ВАК"
            maxHeight="220px"
            rows={person.vakSpecialities}
            onChange={() => {}}
            hideToolbar
            columns={[{ label: '', formatValue: x => x.label }]}
            isDeleteConfirmEnabled
            withMessages
            withoutHead
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "ВАК"',
              modalTableRowConverter: row => ({ id: row.id, label: row.label }),
              modalTableSpecification: GetReferenceElementList({
                requestData: {
                  filters: [],
                  name: 'RefVakSpeciality',
                },
              }),
            }}
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
