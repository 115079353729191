import { useCallback, useMemo, useState } from 'react';
import { Form as F, Table } from 'types/models';
import * as icons from 'icons';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import publicationTypes from 'types/models/Publication/publicationTypes';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const { isProfile } = usePrivatePageContext();
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = useMemo<number>(() => tableState.selectedRows.length, [tableState.selectedRows]);

  const rowsAvailability = useMemo<Table.ToolbarStateRowsAvailability>(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const [title, setTitle] = useState<string | JSX.Element>('');
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isApproveFormOpen, setIsApproveFormOpen] = useState<boolean>(false);

  const openViewForm = useCallback(() => {
    setIsViewFormOpen(true);
  }, [setIsViewFormOpen]);

  const closeViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, [setIsViewFormOpen]);

  const openApproveForm = useCallback(() => {
    setIsApproveFormOpen(true);
  }, [setIsApproveFormOpen]);

  const closeApproveForm = useCallback(() => {
    setIsApproveFormOpen(false);
  }, [setIsApproveFormOpen]);

  const onApprove = useCallback(
    (disapprove: boolean) => {
      const selectedRowId = tableState.selectedRows[0]?.id || '';
      if (selectedRowId) {
        tableStreams.approveRow.push({
          approveRowId: selectedRowId,
          approveItemName: 'Publication',
          command: 'ApprovePublication',
          disapprove,
        });
        closeApproveForm();
      }
    },
    [closeApproveForm, tableState.selectedRows, tableStreams.approveRow],
  );

  const toolbarButtons = useMemo(
    () => [
      {
        icons: { default: icons.Loop, disabled: icons.LoopDisabled },
        title: 'Просмотр',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        isHidden: isProfile,
        onClick: openViewForm,
      },
      {
        icons: { default: icons.Approve, disabled: icons.ApproveDisabled },
        title: 'Утверждение статьи',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: openApproveForm,
      },
    ],
    [openApproveForm, openViewForm, rowsAvailability.SINGLE_SELECTED, isProfile],
  );

  const selectedType = tableState && tableState?.selectedRows.length ? tableState.selectedRows[0]['id:Type'] : '';

  const look: F.Look = {
    apiID: 'GetPublication',
    template: publicationTypes.filter(type => type.code === selectedType)[0]?.template,
    type: selectedType,
    viewMode: true,
    id: tableState.selectedRows[0]?.id,
    relatedTableState: tableState,
    setTitle,
  };
  return {
    toolbarButtons,
    isViewFormOpen,
    closeViewForm,
    isApproveFormOpen,
    closeApproveForm,
    onApprove,
    look,
    title,
  };
};

export default useController;
