import React from 'react';

import { Tabs, Tab } from 'components';
import { DataGrid } from 'features/Table';
import { GetPublicationCitationSystemList, GetPublicationCitationSystemIndexList } from 'features/Table/specifications';
import { useController } from './controller';

const PublicationCitationSystem = () => {
  const { state } = useController();

  return (
    <Tabs>
      <Tab title="Индексация в базах">
        {state.relatedTableState && state.publicationType && (
          <DataGrid
            specification={GetPublicationCitationSystemList({
              templatesTableDependencies: {
                relatedTableAPIID: 'GetMagazineList',
                relatedTableState: state.relatedTableState,
                relatedRecordId: state.publicationId || '',
              },
              publicationType: state.publicationType,
              addCitationSystem: state.addCitationSystem,
              removeCitationSystem: state.removeCitationSystem,
              editCitationSystem: state.editCitationSystem,
              workMode: 'editMode',
              sourceId: state.sourceId,
              releaseId: state.publicationInfo?.magazineRelease?.id,
            })}
          />
        )}
      </Tab>
      <Tab title="Показатели">
        {state.relatedTableState && state.publicationType && (
          <DataGrid
            specification={GetPublicationCitationSystemIndexList(
              {
                relatedTableAPIID: 'GetMagazineList',
                relatedTableState: state.relatedTableState,
                relatedRecordId: state.publicationId || '',
              },
              {
                citationSystems: state.publicationCitationSystems,
                publicationId: state.publicationId,
                publicationType: state.publicationType,
                workMode: 'editMode',
              },
            )}
          />
        )}
      </Tab>
    </Tabs>
  );
};

export const Component = React.memo(PublicationCitationSystem);
