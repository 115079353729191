import * as R from 'ramda';

import { Mode } from 'components/ListEdit/model';

import { Entry } from 'types/models/Table';
import { MemberAffilation } from 'types/models/Author';

type CheckIsSelectedRowValidProps = {
  selectedEntries: Array<Entry> | null;
  selectedIndex: number | null;
  affiliations: Array<MemberAffilation>;
  mode: Mode;
};

export const checkIsSelectedRowValid = ({ selectedEntries, selectedIndex, affiliations, mode }: CheckIsSelectedRowValidProps) => {
  if (!selectedEntries) {
    return false;
  }

  const selectedEntryIds = selectedEntries.map(({ id }) => id);
  const affiliationIds = affiliations.map(({ id }) => id);

  const isEditMode = mode === 'edit';

  const isAddMode = mode === 'add';

  if (isEditMode) {
    const filteredAffiliationIds = affiliationIds.filter(id =>
      selectedIndex !== null ? id !== affiliations[selectedIndex].id : true,
    );
    const isSomeAffiliationAlredyIn = Boolean(R.intersection(selectedEntryIds, filteredAffiliationIds).length);
    if (isSomeAffiliationAlredyIn) {
      return false;
    }
  }

  if (isAddMode) {
    const isSomeAffiliationAlredyIn = Boolean(R.intersection(selectedEntryIds, affiliationIds).length);
    if (isSomeAffiliationAlredyIn) {
      return false;
    }
  }

  return true;
};
