import React from 'react';

import { Tabs, Tab } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import { TenderType } from 'utils/Enums';

import { Projects } from './Projects/Projects';
import { PublicationsScopusWoS } from './PublicationsScopusWoS/PublicationsScopusWoS';
import { Participations } from './Participations/Participations';
import { Dissertations } from './Dissertations/Dissertations';
import { SecurityDocuments } from './SecurityDocuments/SecurityDocuments';
import { ExpositionParticipations } from './ExpositionParticipations/ExpositionParticipations';

type Props = {
  nirRequest: NirRequest.NirRequest;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  setNirRequest: React.Dispatch<React.SetStateAction<NirRequest.NirRequest>>;
  disabled: boolean;
};

export function CollectiveResults(props: Props) {
  const { nirRequest, makeChangeHandler, setNirRequest, disabled } = props;
  const tenderType = nirRequest?.tenderType.value ?? null;

  return (
    <Tabs>
      <Tab title="Проекты">
        <Projects disabled={disabled} nirRequest={nirRequest} setNirRequest={setNirRequest} />
      </Tab>
      <Tab title="Публикации WoS и Scopus">
        <PublicationsScopusWoS disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab title="РИД">
        <SecurityDocuments disabled={disabled} nirRequest={nirRequest} setNirRequest={setNirRequest} />
      </Tab>
      <Tab title="Доклады на мероприятиях">
        <Participations disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab title="Представление продукции">
        <ExpositionParticipations disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab title="Защищённые диссертации" isVisible={tenderType !== TenderType.PromPartner}>
        <Dissertations disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
    </Tabs>
  );
}
