import { Parameter, RangeValue } from 'types/models';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';

const queryBuilder = (dateRangeParameter: Parameter) => {
  const dateRange = dateRangeParameter.value as RangeValue;
  const startDate = parse(dateRange.from, formatStr, new Date());
  const endDate = parse(dateRange.to, formatStr, new Date());

  const startDateTime = startDate.getTime();
  const endDateTime = endDate.getTime();

  const query = `&DateRange=${true}&StartDate=${startDateTime}&EndDate=${endDateTime}`;

  return query;
};

export default queryBuilder;
