import React, { useCallback, useState, useRef } from 'react';
import { block } from 'bem-cn';

import { Column } from 'types/models/Table';
import { IconButton, Popover } from 'components';
import { PopoverContent } from './PopoverContent';
import * as icons from 'icons';

import './styles.scss';

const b = block('column-picker');

export type Props = {
  columns: Column[];
  onHiddenColumnsChange: (nextHiddenColumnNames: string[]) => void;
};

export const ColumnPicker = ({ columns, onHiddenColumnsChange }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const onButtonClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <div className={b()} ref={anchorRef}>
        <IconButton title="Выбрать столбцы" icons={{ default: icons.PickColumns }} onClick={onButtonClick} />
      </div>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        anchorRef={anchorRef.current}
        position={{
          offset: 24,
          anchor: {
            vertical: 'top',
            horizontal: 'left',
          },
          content: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <PopoverContent onClose={onClose} columns={columns} onHiddenColumnsChange={onHiddenColumnsChange} />
      </Popover>
    </>
  );
};
