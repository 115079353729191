import React from 'react';
import { Form } from 'features/Table/specifications/GetGridSettings/ComponentForSecondLevel/Form';
import useController from './controller';
import { getMockModel } from './getMockModel';
import { Column, Setting } from 'types/models/Table';

export type Props = {
  relatedColumns: Column[];
  successfullCallback: (setting: Setting) => void;
  onClose: () => void;
};

export const TemplateFormComponent = ({ relatedColumns, onClose, successfullCallback }: Props) => {
  const { onSubmitCallback } = useController({ onClose, successfullCallback });
  return (
    <Form
      isSettingEditing={false}
      isDescriptionShowed
      relatedColumns={relatedColumns}
      resetComponentStateCallback={() => {}}
      submitCallback={onSubmitCallback}
      setting={getMockModel()}
    />
  );
};
