import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetContestRequestKindConsumptionList(): T.Specification {
  return {
    apiID: 'GetContestRequestKindConsumptionList',
    header: {
      firstLevel: {
        title: 'Виды расходов для заявок на актуализацию проекта',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: false,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}
