import React, { useCallback, useState } from 'react';

import { ConfirmPopup, ReferenceItem } from 'components';

import { BiblioFields } from 'features/Table/specifications/GetScientistBibliographicNameList/Editor/BiblioFields/BiblioFields';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmitModal(fio: string, language: ReferenceItem | null): void;
};

function BiblioFieldsModal(props: Props) {
  const { isOpen, onClose, onSubmitModal } = props;

  const [fio, setFIO] = useState<string>('');
  const [language, setLanguage] = useState<ReferenceItem | null>(null);

  const onConfirm = useCallback(() => {
    onSubmitModal(fio, language);
    onClose();
  }, [fio, language, onSubmitModal, onClose]);

  return (
    <ConfirmPopup
      title="Добавление ФИО для библиографической записи"
      okButtonText="Сохранить"
      resetButtonText="Отменить"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      bodyStyles={{ display: 'block' }}
    >
      <BiblioFields fio={fio} language={language} setFIO={setFIO} setLanguage={setLanguage} />
    </ConfirmPopup>
  );
}

export { BiblioFieldsModal };
