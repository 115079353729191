import { Tender } from 'types/models';

export const defaultLotValue: Tender.Lot = {
  id: '',
  number: '',
  code: '',
  periodNote: '',
  theme: '',
  startDate: '',
  endDate: '',
  limitAmount: 0,
  contractCount: 0,
  contractAmount: 0,
  isSupportRequest: false,
  requestSupportAmount: 0,
  isSupportContract: false,
  contractSupportAmount: 0,
  isSupportWarranty: false,
  warrantySupportAmount: 0,
  note: '',
  prices: [],
};
