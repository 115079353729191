import { useEffect, useRef, useMemo } from 'react';
import { useStream } from 'StreamRx';
import { Report, Table } from 'types/models';

import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { streams } from 'App/pages/FundsDepartments/streams';
import { useLocalTableStreams } from 'features/Table/hooks';

export type State = {
  year: string;
  isReportOpen: boolean;
  currentReport: Report | null;
  getReports: () => Map<string, string>;
  handleSetCurrentReport: ({ name, value }: { name: string; value: string }) => void;
  setYear(year: string): void;
  onReportClose: () => void;
  selectFund(fund: Table.Entry | null): void;
};

type Arguments = {
  year: string;
  setYear(year: string): void;
  selectFund(fund: Table.Entry | null): void;
  selectedFund: Table.Entry | null;
};

export function makeUseCustomController({ year, selectedFund, selectFund, setYear }: Arguments) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    const [row] = selectedRows;

    const isReloading = useRef(false);
    const tableStreams = useLocalTableStreams();

    useEffect(() => {
      if (!isReloading.current) {
        selectFund(row ?? null);
      } else {
        isReloading.current = false;
        // if (selectedFund) {
        // tableStreams.selectRowExclusive.push({ page: 0, rowIndex: Number(selectedFund.index) ?? 0 });
        // setTimeout(() => tableStreams.selectRowExclusive.push({ page: 0, rowIndex: Number(selectedFund.index) ?? 0 }), 0);
        // }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row, selectFund, selectedFund]);

    useStream(
      () => streams.reloadFundsTable,
      () => {
        isReloading.current = true;
        tableStreams.reloadTable.push();
      },
      [],
    );
    const reports = useMemo<Report[]>(
      () => [Reports.MemorandumSummary, Reports.DepartmentsNominations, Reports.ConsolidatedStatement],
      [],
    );

    const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

    return {
      year,
      isReportOpen,
      currentReport,
      getReports,
      handleSetCurrentReport,
      onReportClose,
      selectFund,
      setYear,
    };
  };
}
