import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type RequestData = {
  fundCardId: string;
};

export function GetFundCardPositionList({ fundCardId }: RequestData): T.Specification {
  return {
    apiID: 'GetFundCardPositionList',
    requestData: { fundCardId },
    header: {
      firstLevel: {
        title: 'Источники средств для шифра — статья затрат «Накладные расходы» в сметах шифров проектов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}
