import { useCallback, useMemo, useState } from 'react';

import { Project, Report } from 'types/models';
import { buttonIcons, IconButtonProps } from 'components';

import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { streams as globalStreams } from 'features/Table/streams';

import { useReportsHook, Reports } from 'features/BuildReportPopup';

import { useStreamsByApiID } from 'StreamRx';

type Arguments = {
  onSave(): void;
  onSaveAndContinue(): void;
  projectId: string | null;
  hidden: boolean;
};

export function useController({ onSave, onSaveAndContinue, projectId, hidden }: Arguments) {
  const [isOpenCodeChangesModal, setIsOpenCodeChangesModal] = useState(false);
  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [feedline, setFeedline] = useState<Project.Feedline | null>(null);

  const feedlineListStreams = useStreamsByApiID(globalStreams, 'GetProjectFeedlineList');

  const reloadFeedlineList = useCallback(() => {
    feedlineListStreams.reloadTable.push();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVisibleCodeChangesModal = useCallback(() => {
    setIsOpenCodeChangesModal(prev => !prev);
  }, []);

  const toggleVisibleMessageModal = useCallback(() => {
    setFeedline(null);
    setIsOpenMessageModal(prev => !prev);
  }, []);

  const { methods: saveFeedlineAPI } = BackendAPI.useBackendAPI('SaveProjectUserFeedline');

  const handleSubmitMessageModal = useCallback(
    (feed: Project.Feedline) => {
      saveFeedlineAPI.callAPI(
        { feedline: feed, projectId: projectId ?? '-1' },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
            reloadFeedlineList();
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, reloadFeedlineList],
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.ProjectResults,
      // Reports.ProjectAboutChangesOrder,
      // TODO Удалено в рамках 524 для всех типов проектов
      Reports.ProjectIkrbs,
      Reports.ProjectResultsDetail,
      Reports.ProjectPerformersForCitis,
      Reports.ScienceProjectPerformerCompositionMemo,
      Reports.ProjectTechnicalTask,
      Reports.ProjectDocuments,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        isDisabled: false,
        onClick: onSave,
        isHidden: hidden,
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        isDisabled: false,
        onClick: onSaveAndContinue,
        isHidden: hidden,
      },
      {
        icons: buttonIcons.clock,
        title: 'Журнал изменений в проекте',
        isHidden: projectId === null || hidden,
        onClick: toggleVisibleCodeChangesModal,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        isHidden: projectId === null,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
      {
        icons: buttonIcons.comment,
        title: 'Добавить сообщение в журнал',
        isHidden: projectId === null || hidden,
        onClick: toggleVisibleMessageModal,
      },
    ],
    [
      hidden,
      onSave,
      onSaveAndContinue,
      toggleVisibleCodeChangesModal,
      toggleVisibleMessageModal,
      projectId,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    buttons,
    toggleVisibleCodeChangesModal,
    isOpenCodeChangesModal,
    isOpenMessageModal,
    feedline,
    setFeedline,
    toggleVisibleMessageModal,
    handleSubmitMessageModal,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}
