import React, { useEffect } from 'react';

import { Tabs, Tab, FormComponent } from 'components';

import { Documents } from 'features/Documents';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { isHasPermission, isHasSomePermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { FinPlan } from './Tabs/FinPlan/FinPlan';
import { ReceivingDocuments } from './Tabs/ReceivingDocuments/ReceivingDocuments';
import { Estimates } from './Tabs/Estimates/Estimates';
import { ChangesList } from './Tabs/ChangesList/ChangesList';
import { useController } from './controller';
import { AboutProjectCode } from './Tabs/AboutProjectCode/AboutProjectCode';
import { Project } from 'types/models/Project';
import { useFormContext } from 'features/Form/hooks';
import { DataGrid } from 'features/Table';
import { GetProjectPerformerJobPeriodList, GetSalaryList } from 'features/Table/specifications';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectCodeForm({ viewMode, editMode, onClose }: Props) {
  const {
    formFields,
    workMode,
    handleFormSubmit,
    projectCode,
    replaceMode,
    handleFinPlanChange,
    handleDocumentsChange,
    documents,
    userPermission,
    findPerformerByJobPeriodRole,
    calendarPlansByYear,
    financingsByYear,
    calendarPlansByStages,
    handleFinancingSourceChange,
    financingTagnameByProjectType,
    generateProjectCode,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          const baseTitle: string = (() => {
            if (workMode === 'addMode') {
              return 'Добавление шифра проекта';
            }

            const leader = findPerformerByJobPeriodRole('LEADER', projectCode?.project)?.person?.fullName || '';
            const performer = findPerformerByJobPeriodRole('RESPONSIBLE_PERFORMER', projectCode?.project)?.person?.fullName || '';

            return [
              [
                replaceMode ? 'Замена' : workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
                'шифра проекта',
                projectCode?.code || '',
              ]
                .filter(x => x)
                .join(' '),
              leader ? `Руководитель: ${leader}` : '',
              performer ? `Отв. исполнитель: ${performer}` : '',
            ]
              .filter(x => x)
              .join(', ');
          })();

          if (workMode === 'addMode') {
            return baseTitle;
          }

          const project: Project | null = projectCode?.project ?? null;
          const date = [project?.startDate, project?.endDate].filter(x => x).join(' - ');
          const info = [
            project?.program?.shortName ? `${project?.program?.shortName}:` : '',
            project?.name,
            date ? `(${date})` : '',
          ]
            .filter(x => x)
            .join(' ');

          if (info) {
            return (
              <>
                <h3>{baseTitle}</h3>
                <h4>{info}</h4>
              </>
            );
          }

          return baseTitle;
        })(),
      );
    }
  }, [findPerformerByJobPeriodRole, projectCode?.code, projectCode?.project, replaceMode, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О шифре">
            <AboutProjectCode
              projectCode={projectCode}
              formFields={formFields}
              disabled={viewMode}
              findPerformerByJobPeriodRole={findPerformerByJobPeriodRole}
              handleFinancingSourceChange={handleFinancingSourceChange}
              generateProjectCode={generateProjectCode}
              replaceMode={replaceMode}
            />
          </Tab>
          <Tab title="Финансовый план" isVisible={isHasPermission(userPermission, Permits.PROJECT_CODE_FINANCING)}>
            <FinPlan
              projectCode={projectCode}
              disabled={viewMode}
              handleFinPlanChange={handleFinPlanChange}
              calendarPlansByYear={calendarPlansByYear}
              financingsByYear={financingsByYear}
              calendarPlansByStages={calendarPlansByStages}
              financingTagnameByProjectType={financingTagnameByProjectType}
              formFields={formFields}
              handleSave={handleFormSubmit}
            />
          </Tab>
          <Tab title="Поступление средств">
            <ReceivingDocuments projectCode={projectCode} />
          </Tab>
          <Tab title="Затраты">
            <Estimates projectCode={projectCode} isDisabled={workMode === 'viewMode' || !projectCode?.id} />
          </Tab>
          <Tab
            title="Зарплата коллективу"
            isVisible={isHasSomePermission(userPermission, [Permits.SALARY_FULL_ACCESS, Permits.ENCODING_SALARY_ACCESS])}
          >
            <Tabs>
              <Tab title="Зарплата коллективу">
                <DataGrid specification={GetSalaryList(projectCode?.id || '-1')} />
              </Tab>
              <Tab title="Контроль состава">
                <DataGrid
                  specification={GetProjectPerformerJobPeriodList({ projectId: projectCode?.project?.id || undefined })}
                />
              </Tab>
            </Tabs>
          </Tab>
          <Tab title="История изменения данных">
            <ChangesList
              projectCodeId={projectCode?.id}
              projectId={projectCode?.project?.id || ''}
              isDisabled={workMode === 'viewMode'}
            />
          </Tab>
          <Tab title="Документы шифра">
            <Documents
              isCanIsSharedEdit
              sharedLabel="Отображать в проекте"
              documents={documents}
              sourceLabelForLocalDocs="Текущий шифр"
              setDocuments={handleDocumentsChange}
              disabled={viewMode ?? false}
              isShowSource
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectCodeForm);
