import React from 'react';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal title="НИОКР" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Вкладка <strong>НИОКР:</strong> список научных проектов, в которых организация является заказчиком или
              соисполнителем, или технологическим партнёром. Источник информации — разделах <strong>Заказчики и Партнёры</strong>
              &nbsp;на форме редактирования научного проекта (модуль <strong>Проекты</strong>). Условием отображения данных в
              таблице является выбор организации на форме редактирования научного проекта:
            </p>
            <ul>
              <li>На вкладке «О проекте», раздел Заказчики»</li>
              <li>На вкладке Партнёры, Организации-партнёры, Партнёры, Международное сотрудничество</li>
              <li>На вкладке Организации- соисполнители</li>
            </ul>
            <p>
              <strong className="state-danger">ВАЖНО!</strong> В случае, если для организации указаны организации на
              вкладках&nbsp;
              <strong>Связи с другими организациями</strong> и <strong>Варианты наименования</strong>, то в списке будут
              отображаться записи связанных организаций. В столбце&nbsp;
              <strong>Организация</strong> отображается оригинальное название организации. В столбце <strong>Роль</strong>&nbsp;
              отображается роль организации в проекте.
            </p>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
