import React, { useCallback } from 'react';
import * as R from 'ramda';

import { Tabs, Tab, ReferenceItem, FormComponent, Modal } from 'components';
import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import * as PersonTabs from 'features/Form/looks/person/Tabs/AdditionalInfo';
import { EditableHonor, HonorType } from './model';
import { ControlPanel } from 'features/Form/views/ControlPanel';

import { Main } from './Main/Main';

import { useController } from './controller';

type Props = {
  onClose?(): void;
};

function PersonForm(props: Props) {
  const { onClose } = props;
  const { localPerson, setLocalPerson, isOpen, submitForm } = useController({
    onClose,
  });

  const { enumMap, userPermission } = useAppDataContext();

  const changeScientificField = useCallback(
    (fieldName: string, value: string | ReferenceItem | null) => {
      setLocalPerson(R.set(R.lensPath(['scientist', fieldName]), value));
    },
    [setLocalPerson],
  );

  const makeChangeHandler = (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => {
    setLocalPerson(R.set(R.lensProp(fieldName), value as EditableHonor[]));
  };

  const changeHonors = useCallback(
    (honors: EditableHonor[]) => {
      const map = R.groupBy(honor => honor.type!, honors) as Record<HonorType, EditableHonor[]>;
      const updatePerson = R.pipe(
        R.set(R.lensProp('academicRanks'), map.academicRank),
        R.set(R.lensProp('ranks'), map.rank),
        R.set(R.lensProp('degrees'), map.degree),
      );
      setLocalPerson(updatePerson);
    },
    [setLocalPerson],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose!} title="Добавление персоны" size={['extralarge', 'large-height']}>
      <FormComponent.Template>
        <ControlPanel handleSave={submitForm} />

        <FormComponent.Template>
          <Tabs>
            <Tab title="Профиль">
              <Main onChange={changeScientificField} person={localPerson} userPermission={userPermission} />
            </Tab>
            <Tab title="Сведения о работе">
              <PersonTabs.JobDetails
                disabled={false}
                isAddMode
                enumMap={enumMap}
                person={localPerson}
                onChangeJobs={makeChangeHandler('scientistJobs')}
              />
            </Tab>
            <Tab title="Сведения об обучении">
              <PersonTabs.EducationDetails
                disabled={false}
                isAddMode
                enumMap={enumMap}
                person={localPerson}
                onChangeEducations={makeChangeHandler('scientistEducations')}
              />
            </Tab>

            <Tab title="Ученые степени и звания">
              <PersonTabs.ScientistHonors
                disabled={false}
                isAddMode
                userPermission={userPermission}
                person={localPerson}
                onChangeHonors={changeHonors}
              />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>
    </Modal>
  );
}

export const Component = React.memo(PersonForm);
