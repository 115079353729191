import { NirRequest } from 'types/models';
import { getMaxSymbolsInvalidMessage } from 'features/Form/looks/nirRequest/NirRequestForm/helpers';

export function validate(performer: NirRequest.Performer) {
  const isValidLength = (maxLength: number, key: keyof NirRequest.Performer) =>
    performer[key] ? (performer[key] as string).length <= maxLength : true;

  const isValidKeyWords = isValidLength(15, 'keyWords');
  const isValidAwards = isValidLength(2000, 'awards');
  const isValidScientificGroundwork = isValidLength(3000, 'scientificGroundwork');
  const isValidPrimePublications = isValidLength(3000, 'primePublications');
  const isValidRids = isValidLength(3000, 'rids');
  const isValidProjects = isValidLength(3000, 'projects');
  const isValidNote = isValidLength(500, 'note');

  return [
    { isValid: isValidKeyWords, invalidMessage: getMaxSymbolsInvalidMessage('Ключевые слова') },
    { isValid: isValidAwards, invalidMessage: getMaxSymbolsInvalidMessage('Награды и премии за научную деятельность') },
    { isValid: isValidScientificGroundwork, invalidMessage: getMaxSymbolsInvalidMessage('Научный задел по теме') },
    { isValid: isValidPrimePublications, invalidMessage: getMaxSymbolsInvalidMessage('Список основных публикаций') },
    { isValid: isValidRids, invalidMessage: getMaxSymbolsInvalidMessage('Список РИД') },
    { isValid: isValidProjects, invalidMessage: getMaxSymbolsInvalidMessage('Выполненные научные проекты') },
    { isValid: isValidNote, invalidMessage: getMaxSymbolsInvalidMessage('Дополнительная информация') },
  ];
}
