import React, { memo, useCallback } from 'react';
import { setup } from 'bem-cn';

import { Select, FormComponent } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const block = setup({ mod: '--' });

const enumerations = block('enumeration');

const Enumeration = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { canBuild, currentValue, setNextValueKey, selectTitle, enumParameter, values } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });
  const renderEntry = useCallback(x => x, []);
  return (
    <>
      {canBuild && (
        <div className={enumerations()}>
          <FormComponent.Line>
            <FormComponent.Field label={selectTitle} isRequired={!!enumParameter?.required}>
              <Select.Component
                disabled={false}
                value={currentValue?.label || ''}
                variant="outlined"
                title={selectTitle}
                defaultPlaceholder="Не задан"
                entries={values.map(({ label }) => label)}
                onChange={setNextValueKey}
                renderEntry={renderEntry}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
      )}
    </>
  );
};

const Component = memo(Enumeration);

export { Component as Enumeration };
