import React from 'react';

import { CommentPopup, ConfirmPopup, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { SelectSecurityDocumentPopup } from 'features/Form/views/SelectSecurityDocumentPopup';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { TermPresetModal } from 'features/IncentivePayments/Toolbar/TermPresetModal/TermPresetModal';
import { Term } from 'features/IncentivePayments';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { DataGrid } from 'features/Table';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications/GetAuthorPaymentRequestFeedlineList';
import { useController } from './controller';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import * as modalTemplates from './modalTemplates';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isAddPaymentRequestFormOpen,
    handleTemplateCloseAddForm,
    isViewPaymentRequestFormOpen,
    handleTemplateCloseViewForm,
    isAddButtonDisabled,
    securityDocumentId,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isSelectFormOpen,
    onSelectProjectFormClose,
    onSubmitSelectDocument,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isChangePaymentPopupOpen,
    setIsChangePaymentPopupOpen,
    changeTerm,
    termPreset,
    statusButtons,
    comment,
    setComment,
    newStatus,
    isOpenStatusForm,
    setIsOpenStatusForm,
    isOpenConfirmStatusForm,
    setIsOpenConfirmStatusForm,
    handleStatusChange,
    isFeedlineModalOpen,
    setIsFeedlineModalOpen,
  } = useController({ tableState });

  const { settings, userSystemDepartment } = useAppDataContext();

  return (
    <>
      <modalTemplates.PatentPaymentRequestViewTemplate.Component
        isOpen={isViewPaymentRequestFormOpen}
        onClose={handleTemplateCloseViewForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />
      {!isAddButtonDisabled && (
        <modalTemplates.PatentPaymentRequestAddTemplate.Component
          isOpen={isAddPaymentRequestFormOpen}
          onClose={handleTemplateCloseAddForm}
          securityDocumentId={securityDocumentId || ''}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.PatentPaymentRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную заявку? (ID ${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <SelectSecurityDocumentPopup
        isOpen={isSelectFormOpen}
        onClose={onSelectProjectFormClose}
        onSubmit={onSubmitSelectDocument}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        userDepartment={settings?.userDepartment}
        systemDepartMentLabel={`${userSystemDepartment?.label}` || ''}
      />
      <TermPresetModal
        isOpen={isChangePaymentPopupOpen}
        onClose={() => {
          setIsChangePaymentPopupOpen(false);
        }}
        onSubmit={(term: Term) => changeTerm(tableState.selectedRows[0].id, term)}
        termPreset={termPreset ?? null}
      />
      <CommentPopup
        title="Извменение статуса заявки"
        isOpen={isOpenStatusForm || false}
        onClose={() => setIsOpenStatusForm(false)}
        comment={comment || ''}
        setComment={setComment}
        onSubmit={() => handleStatusChange(newStatus || '')}
      />
      <ConfirmPopup
        icon="warning"
        title="Изменение статуса заявки"
        text={checkPaymentByStatus(
          tableState.selectedRows[0]?.factNomineesMoney ?? null,
          (newStatus as AuthorPaymentRequestStatus) ?? null,
        )}
        isOpen={isOpenConfirmStatusForm || false}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmStatusForm(false)}
        onConfirm={() => {
          setIsOpenConfirmStatusForm(false);
          setIsOpenStatusForm(true);
        }}
      />

      <Toolbar buttons={[...buttons, ...statusButtons]} />

      <Modal
        isOpen={isFeedlineModalOpen || false}
        onClose={() => setIsFeedlineModalOpen(false)}
        title="Лента активности"
        size={['medium', 'small-height']}
      >
        <DataGrid specification={GetAuthorPaymentRequestFeedlineList({ paymentId: tableState.selectedRows[0]?.id || '' })} />
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
