import React from 'react';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';
import { EnumValue } from 'types/models/Table';

import { TenderType } from 'utils/Enums';
import { FormComponent } from 'components';

import { ChoosePerformers } from './ChoosePerformers/ChoosePerformers';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  roles: EnumValue[];
  disabled: boolean;
};

export function CharacteristicComposition(props: Props) {
  const { nirRequest, makeChangeHandler, roles, disabled } = props;
  const performers = nirRequest?.performers ?? [];

  const tenderType = nirRequest?.tenderType.value ?? null;

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <>
            {tenderType === TenderType.PromPartner ||
            tenderType === TenderType.InitiativeGroup ||
            tenderType === TenderType.YouthLab ||
            tenderType === TenderType.Postdocs ? null : (
              <ChoosePerformers
                title="Список иностранных ученых в составе научного коллектива"
                performers={performers}
                chosenPerformers={nirRequest?.collectiveForeignPerformers ?? []}
                roles={roles}
                disabled={disabled}
                setChosenPerformers={makeChangeHandler('collectiveForeignPerformers')}
              />
            )}
          </>
          <ChoosePerformers
            title="Список НПР в возрасте до 39 лет"
            performers={performers}
            chosenPerformers={nirRequest?.collectiveYoungPerformers ?? []}
            roles={roles}
            disabled={disabled}
            setChosenPerformers={makeChangeHandler('collectiveYoungPerformers')}
          />
        </FormComponent.Column>

        <FormComponent.Column>
          <>
            {tenderType === TenderType.PromPartner ||
            tenderType === TenderType.InitiativeGroup ||
            tenderType === TenderType.YouthLab ||
            tenderType === TenderType.Postdocs ? null : (
              <ChoosePerformers
                roles={roles}
                performers={performers}
                chosenPerformers={nirRequest?.collectiveRanPerformers ?? []}
                title="Список сотрудников РАН в составе научного коллектива"
                setChosenPerformers={makeChangeHandler('collectiveRanPerformers')}
                disabled={disabled}
              />
            )}
          </>
          <ChoosePerformers
            title="Список студентов, аспирантов в составе научного коллектива"
            chosenPerformers={nirRequest?.collectiveEducationalPerformers ?? []}
            performers={performers}
            roles={roles}
            setChosenPerformers={makeChangeHandler('collectiveEducationalPerformers')}
            disabled={disabled}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}
