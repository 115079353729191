import React from 'react';
import * as R from 'ramda';

import { FormComponent, ListEdit, ReferenceItem } from 'components';

import { Table as T, ScientificIndex as S } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { getFieldsFromScientificIndex } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/helpers';
import { ChildField, Field, FieldValue } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/types';
import { Fields } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/Editor/Fields/Fields';
import {
  getValidateErrorMessage,
  getPostValidateResult,
} from 'features/Table/specifications/GetPublicationCitationSystemIndexList/validate';
import { showNotification } from 'features/Notifications';

type Props = {
  childFields: ChildField[];
  enumMap: T.EnumMap;
  citationSystems: CitationSystem[];
  citationSystem: ReferenceItem | null;
  selectedScientificIndex: S.ScientificIndex | null;
  setRows(childFields: ChildField[]): void;
};

function ThematicCategory(props: Props) {
  const { childFields, enumMap, citationSystem, citationSystems, setRows, selectedScientificIndex } = props;
  const fields = selectedScientificIndex !== null ? getFieldsFromScientificIndex(selectedScientificIndex) : null;

  if (!fields || !fields.childFields.length) {
    return null;
  }

  const { childFields: mockFields } = fields;
  const mockChildField = mockFields[0];

  const handleChangeField = (
    setChildField: React.Dispatch<React.SetStateAction<ChildField | null>>,
    fieldId: string,
    value: FieldValue,
  ) => {
    setChildField(prevChildField => {
      const prevField = prevChildField || mockChildField;
      return R.over(R.lensPath(['fields', fieldId]), (field: Field) => ({ ...field, value }), prevField);
    });
  };

  return (
    <FormComponent.Field>
      <ListEdit
        title={mockChildField.label}
        rows={childFields}
        onChange={setRows}
        columns={Object.values(mockChildField.fields).map(x => ({
          label: x.name,
          formatValue: (row: ChildField) => {
            const field = row.fields[x.valueId];
            if (field.type === 'REFERENCE') {
              return (field.value as ReferenceItem).label;
            }
            return field.value as string;
          },
        }))}
        specification={{
          mode: 'customComponent',
          validation: {
            checkIsValid: childField => {
              const isBaseValidateionPassed = !getValidateErrorMessage(childField?.fields ?? {}).length;
              const isPostValidatePassed = !getPostValidateResult(childField?.fields ?? {}).length;
              return isBaseValidateionPassed && isPostValidatePassed;
            },
            onInvalidate: childField => {
              const messages = [
                ...getValidateErrorMessage(childField ? childField.fields : {}),
                getPostValidateResult(childField?.fields ?? {}),
              ].filter(message => Boolean(message));
              setTimeout(() => {
                showNotification({ theme: 'danger', message: messages.join('<br>') });
              }, 0);
            },
          },
          renderComponent: (childField, setChildField) => (
            <Fields
              enumMap={enumMap}
              citationSystems={citationSystems}
              citationSystem={citationSystem}
              fields={childField ? childField.fields : mockChildField.fields}
              onChangeField={R.partial(handleChangeField, [setChildField])}
            />
          ),
        }}
      />
    </FormComponent.Field>
  );
}

export { ThematicCategory };
