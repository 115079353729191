import React, { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { block } from 'bem-cn';

import { buttonIcons, IconButtonProps, InputSelect, ReferenceItem, RelationTableModal, Toolbar } from 'components';

import { Person } from 'types/models';
import { GetScientistBibliographicNameList } from 'features/Table/specifications';
import { BiblioFieldsModal } from './BiblioFieldsModal/BiblioFieldsModal';
import { SubmitTable } from 'features/Table/streams';
import { showNotification } from 'features/Notifications';

import './style.scss';

const b = block('select-biblio-name');

type Props = {
  scientistPerson: Person.ScientistPerson | null;
  selectedBiblioName: string;
  onChangeBiblioName(biblioName: string): void;
  onAfterAddBiblioName(): void;
};

function SelectBiblioName(props: Props) {
  const { scientistPerson, selectedBiblioName, onAfterAddBiblioName, onChangeBiblioName } = props;
  const [isOpenTableModal, setIsOpenTableModal] = useState(false);
  const [isOpenFieldsModal, setIsOpenFieldsModal] = useState(false);

  const closeTableModal = useCallback(() => {
    setIsOpenTableModal(false);
    onAfterAddBiblioName();
  }, [setIsOpenTableModal, onAfterAddBiblioName]);

  const openTableModal = useCallback(() => {
    setIsOpenTableModal(true);
  }, [setIsOpenTableModal]);

  const isDisabled = scientistPerson === null;
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.list,
        title: 'Варианты ФИО для библиографической ссылки',
        code: 'add',
        isDisabled,
        onClick: event => {
          event?.currentTarget.blur();
          openTableModal();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Очистить',
        code: 'add',
        isDisabled,
        onClick: () => {
          onChangeBiblioName('');
        },
      },
    ],
    [isDisabled, openTableModal, onChangeBiblioName],
  );

  const handleCloseFieldsModal = useCallback(() => {
    setIsOpenFieldsModal(false);
  }, [setIsOpenFieldsModal]);

  const { methods: saveBibliographicNameAPI } = BackendAPI.useBackendAPI('SaveScientistBibliographicName', {
    onSuccessfullCall: () => {
      onAfterAddBiblioName();
      showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });
    },
  });

  const onAddBiblioName = useCallback(
    (fio: string, language: ReferenceItem | null) => {
      if (language && scientistPerson) {
        saveBibliographicNameAPI.callAPI({
          bibliographicName: fio,
          isDefault: 'false',
          languageId: language.id,
          scientistId: scientistPerson.scientist?.id ?? '',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scientistPerson],
  );

  const onSubmitTable = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      if (row) {
        onChangeBiblioName(row.bibliographicName);
        closeTableModal();
      }
    },
    [onChangeBiblioName, closeTableModal],
  );

  const specification = GetScientistBibliographicNameList({
    scientistId: scientistPerson?.scientist?.id ?? '',
    onSubmitTable,
  });

  return (
    <div className={b()}>
      <div className={b('select')}>
        <InputSelect
          value={{ label: selectedBiblioName, value: selectedBiblioName }}
          options={scientistPerson?.bibliographicNames.map(x => ({ value: x.name, label: x.name })) ?? []}
          onSelectChange={option => onChangeBiblioName(option!.value)}
          disabled={isDisabled}
          placeholder="Не задан"
        />
      </div>
      <Toolbar buttons={buttons} />
      <RelationTableModal
        specification={specification}
        relationTableModalTitle="Варианты ФИО для библиографической ссылки"
        isOpen={isOpenTableModal}
        onClose={closeTableModal}
      />
      <BiblioFieldsModal isOpen={isOpenFieldsModal} onClose={handleCloseFieldsModal} onSubmitModal={onAddBiblioName} />
    </div>
  );
}

export { SelectBiblioName };
