import React from 'react';

import { RelationTableModal, Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { GetProjectCodeChangesInProject } from 'features/Table/specifications';
import { MessageModal } from 'features/Table/specifications/GetProjectFeedlineList/Toolbar/MessageModal/MessageModal';
import { getMockFeedline } from 'features/Table/specifications/GetProjectFeedlineList/helpers';

import { useController } from './controller';

type Props = {
  onSave(): void;
  onSaveAndContinue(): void;
  projectId: string | null;
  hidden: boolean;
};

function Toolbar(props: Props) {
  const { onSave, projectId, onSaveAndContinue, hidden } = props;

  const {
    buttons,
    isOpenCodeChangesModal,
    toggleVisibleCodeChangesModal,
    toggleVisibleMessageModal,
    feedline,
    isOpenMessageModal,
    setFeedline,
    handleSubmitMessageModal,
    currentReport,
    isReportOpen,
    onReportClose,
  } = useController({ onSave, projectId, onSaveAndContinue, hidden });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />
      <RelationTableModal
        isOpen={isOpenCodeChangesModal}
        onClose={toggleVisibleCodeChangesModal}
        relationTableModalTitle="Журнал изменений в проекте"
        specification={GetProjectCodeChangesInProject({ projectId })}
      />
      <MessageModal
        isOpen={isOpenMessageModal}
        onClose={toggleVisibleMessageModal}
        feedline={feedline || getMockFeedline()}
        changeFeedline={setFeedline}
        onSubmit={handleSubmitMessageModal}
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        projectId={projectId || ''}
      />
    </>
  );
}

export { Toolbar };
