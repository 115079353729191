import { useEffect } from 'react';

import { Table } from 'types/models';

export type State = {
  setProjectCodeId(projectCodeId: string): void;
};

type Props = {
  setProjectCodeId(projectCodeId: string): void;
};

export function makeUseCustomController({ setProjectCodeId }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    useEffect(() => {
      if (selectedRows.length) setProjectCodeId(selectedRows[0].id);
    }, [selectedRows]);
    return {
      setProjectCodeId,
    };
  };
}
