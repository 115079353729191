import { SubjectType } from 'types/models/Subject';

export type State = {
  subjectType: SubjectType;
  isSubject: boolean;
};

export function makeUseCustomController({ subjectType, isSubject }: State) {
  return function useCustomController(): State {
    return {
      subjectType,
      isSubject,
    };
  };
}
