import { Table as T } from 'types/models';

type RequestData = {
  scientistId: string;
  affiliationId: string;
};

export function GetScientistAffiliationPublicationList(scientistId: string, affiliationId: string): T.Specification {
  const requestData: RequestData = {
    scientistId,
    affiliationId,
  };

  return {
    apiID: 'GetScientistAffiliationPublicationList',
    requestData,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
