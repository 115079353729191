import { convertDocument, convertItem, convertMaybeArray, getId, getText } from '../commonConverters';
import { Settings } from 'types/models/common';
import { TenderProtocol, ProtocolTender, ProgramRequest, NirRequest, MobileRequest } from 'types/models/TenderProtocol';
import {
  convertCommissionMember,
  convertProtocolCommission,
} from 'services/BackendAPI/configurations/protocolCommission/converters';
import { convertTender } from 'services/BackendAPI/configurations/tenders/converters';
import { MobileRequestStatus, NirRequestStatus, RequestStatus } from 'utils/Enums';

export function convertTenderProtocol(tp: any, settings: Settings): TenderProtocol {
  const members = tp.CommissionMembers?.CommissionMember || [];
  return {
    id: getId(tp),
    tender: tp.Tender ? convertProtocolTender(tp.Tender, settings) : null,
    number: getText(tp.Number),
    protocolDate: getText(tp.ProtocolDate),
    protocolCommission: convertProtocolCommission(tp.ProtocolCommission, settings),
    commissionMembers:
      convertMaybeArray(members, m => convertCommissionMember(m, settings)).sort((x1, x2) => {
        return (x1?.priority || 0) - (x2?.priority || 0);
      }) || [],
    documents: [
      ...convertMaybeArray(tp?.Documents?.Document || [], doc => convertDocument('local', doc)),
      ...convertMaybeArray(tp?.ForeignDocuments?.Document || [], doc => convertDocument('foreign', doc)),
    ],
    reporter: getText(tp.Reporter),
  };
}

function convertProtocolTender(pt: any, settings: Settings): ProtocolTender {
  return {
    ...convertTender(pt, settings),
    requests: convertMaybeArray(pt.Requests?.Request || [], convertRequest),
    nirRequests: convertMaybeArray(pt.NirRequests?.NirRequest || [], convertNirRequest),
    mobileRequests: convertMaybeArray(pt.MobileRequests?.MobileRequest || [], convertMobileRequest),
    programRequests: convertMaybeArray(pt.ProgramRequests?.ProgramRequest || [], convertRequest),
  };
}

function convertRequest(r: any): ProgramRequest {
  return {
    status: r.Status ? convertItem<RequestStatus>(r.Status) : null,
    finances: convertMaybeArray(r.Finances?.Finance || [], (x: any) => ({
      year: getText(x.Year),
      fbAmount: getText(x.FBAmount),
      sfAmount: getText(x.SFAmount),
    })),
  };
}

function convertNirRequest(r: any): NirRequest {
  return {
    status: r.Status ? convertItem<NirRequestStatus>(r.Status) : null,
    stages: convertMaybeArray(r.Stages?.Stage || [], (x: any) => ({
      year: getText(x.Year),
      amount: getText(x.Amount),
    })),
  };
}

function convertMobileRequest(r: any): MobileRequest {
  return {
    status: r.Status ? convertItem<MobileRequestStatus>(r.Status) : null,
    estimatePositions: convertMaybeArray(r.EstimatePositions?.EstimatePosition || [], (x: any) => ({
      amount: getText(x.Amount),
    })),
  };
}
