import { Parameter } from 'types/models';

const queryBuilder = (yearParameter: Parameter) => {
  if (yearParameter.value) {
    return `&${yearParameter.name}=${yearParameter.value}`;
  }

  return '';
};

export default queryBuilder;
