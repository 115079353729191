import React, { Dispatch, useCallback, useEffect, useState } from 'react';

import { showNotification } from 'features/Notifications';

import { Person } from 'types/models';

import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { validateMainFields } from './helpers';
import { useFormContext } from 'features/Form/hooks';

export type State = {
  localPerson: Person.ScientistPerson | null;
  setLocalPerson: Dispatch<React.SetStateAction<Person.ScientistPerson | null>>;
  closeForm(): void;
  submitForm(): void;
  loadPerson(id: string): void;
  isOpen: boolean;
  id: string | null;
};

export type HonorType = 'degree' | 'rank' | 'academicRank';

export type EditableHonor = {
  type?: HonorType;
} & Honor;

export type Honor = Person.ScientistDegree | Person.ScientistRank | Person.ScientistAcademicRank;

type Arguments = {
  onClose?(): void;
};

export function useController({ onClose }: Arguments): State {
  const [localPerson, setLocalPerson] = useState<Person.ScientistPerson | null>(null);
  const tableStreams = useLocalTableStreams();
  const {
    look: { isOpen, id },
  } = useFormContext<any>();
  const { methods: SaveScientistAPI } = BackendAPI.useBackendAPI('SaveScientist');
  const { methods: loadScientistAPI } = BackendAPI.useBackendAPI('GetScientistData');

  const loadPerson = useCallback(
    (personId: string) => {
      loadScientistAPI.callAPI(
        { personId },
        {
          onSuccessfullCall: ({ data }) => {
            setLocalPerson(data);
          },
        },
      );
    },
    [loadScientistAPI],
  );

  useEffect(() => {
    if (isOpen && id !== null) {
      loadPerson(id);
    }

    if (!isOpen && id === null) {
      setLocalPerson(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, setLocalPerson, id]);

  const closeForm = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const saveScientist = useCallback(() => {
    if (localPerson) {
      SaveScientistAPI.callAPI(
        { person: localPerson },
        {
          onSuccessfullCall: () => {
            tableStreams.reloadTable.push();
            showNotification({ message: 'Персона успешно сохранена', theme: 'success' });
            closeForm();
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPerson, closeForm]);

  const submitForm = useCallback(() => {
    if (!localPerson) {
      return;
    }

    const validateInfo = validateMainFields(localPerson);
    const isValidForm = validateInfo.every(x => x.isValid);

    if (!isValidForm) {
      validateInfo.forEach(({ isValid, invalidMessage }) => {
        if (!isValid) {
          setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
        }
      });
      return;
    }

    saveScientist();
  }, [localPerson, saveScientist]);

  return {
    localPerson,
    closeForm,
    setLocalPerson,
    submitForm,
    loadPerson,
    isOpen,
    id,
  };
}
