import { useMemo } from 'react';

import { ReferenceFilter, ReferenceItem } from 'components';

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';

type Arguments = {
  department: Project.Department;
  setDepartment(department: Project.Department): void;
  filterGovernances?: boolean;
};

export function useController({ department, setDepartment, filterGovernances }: Arguments) {
  const makeChangeHandler = (key: keyof Project.Department) => (value: ValueOf<Project.Department>) => {
    let newDepartment = { ...department, [key]: value };
    if (key === 'unit') {
      const refItem = value as ReferenceItem;
      const level = refItem?.customFields?.level;
      if (level === '1') {
        newDepartment = { ...newDepartment, governance: refItem };
      } else if (refItem.customFields?.parent) {
        newDepartment = { ...newDepartment, governance: refItem.customFields?.parent as ReferenceItem };
      }
    }
    setDepartment(newDepartment);
  };

  const unitFilters = useMemo<ReferenceFilter[]>(() => {
    const filters = [];

    if (filterGovernances) {
      filters.push({ key: 'level', values: ['1'] });
    }
    return filters as ReferenceFilter[];
  }, [filterGovernances]);

  return {
    unitFilters,
    makeChangeHandler,
  };
}
