import React from 'react';
import { block } from 'bem-cn';
import { Table } from 'types/models';
import { Toolbar, ConfirmPopup } from 'components';
import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { EditRelationsModal } from 'features/EditRelationsModal';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import { Component as EditDoiPopup } from 'features/Form/views/EditDoiPopup';
import { Component as FinancicalSupportPopup } from 'features/Form/views/FinancicalSupportPopup';
import * as modalTemplates from './modalTemplates';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

const b = block('toolbar');

const LeftPanelForThirdLevel = ({ tableState }: Props) => {
  const {
    SUCH_LIKE_PUBLICATION_ID,
    isSourceCitationDisabled,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isFinancialSupportDisabled,
    isDeleteButtonDisabled,
    isEditRelationsDisabled,
    isDOIDisabled,
    isApproveButtonDisabled,
    isCitationDisabled,
    nextPublicationName,
    publication,
    buttons,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isOpenEditRelations,
    isDeleteConfirmPopupOpen,
    isCitationFormOpen,
    isSourceCitationOpen,
    isApprovePopupOpen,
    isDoiPopupOpened,
    isFinancialSupportOpen,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    handleCloseEditRelationsModal,
    handleConfirmDeleteConfirmPopup,
    handleCloseCitationForm,
    handleCloseSourceCitation,
    handleApprovePopupClose,
    handleCloseDoiPopup,
    handleCloseFinancialSupport,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    handleCloseDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    onApprove,
    handleContinueStep,
    submitDoiPopupCallback,
    submitFinancialPopupCallback,
  } = useController({ tableState });
  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikePublication
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            componentId={SUCH_LIKE_PUBLICATION_ID}
          />
        </div>
      )}
      <modalTemplates.PublicationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        name={nextPublicationName}
        relatedTableState={tableState}
      />
      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          // handleChangeParticipation={handleOpenFormConferenceParticipation}
          // isHidden={isAddFormOpenConferenceParticipation}
          // setPublication={setPublicationConferenceParticipation}
          // setPublicationConferenceSource={setPublicationConferenceSource}
        />
      )}
      {!isDeleteButtonDisabled && !!tableState.selectedRows.length && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную публикацию? (ID: ${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          okButtonText="Да"
          resetButtonText="Отмена"
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
        />
      )}
      {!isEditRelationsDisabled && !!tableState.selectedRows.length && (
        <EditRelationsModal
          title="Редактировать связь публикации с подразделениями"
          isOpen={isOpenEditRelations}
          onClose={handleCloseEditRelationsModal}
          publication={publication}
        />
      )}
      {!isDOIDisabled && !!tableState.selectedRows.length && (
        <EditDoiPopup
          isOpen={isDoiPopupOpened}
          onClose={handleCloseDoiPopup}
          onSuccess={submitDoiPopupCallback}
          publicationId={tableState.selectedRows[0].id as string}
        />
      )}

      {!isApproveButtonDisabled && !!tableState.selectedRows.length && (
        <ApprovePopup
          isOpen={isApprovePopupOpen}
          selectedRowId={tableState.selectedRows[0].id}
          onApprove={onApprove}
          onClose={handleApprovePopupClose}
        />
      )}
      {!isCitationDisabled && !!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isSourceCitationDisabled && (
        <modalTemplates.SourcePublicationCitationSystemTemplate.Component
          isOpen={isSourceCitationOpen}
          onClose={handleCloseSourceCitation}
          id={tableState.selectedRows[0].SourceId}
          relatedTableState={tableState}
        />
      )}
      {!isFinancialSupportDisabled && (
        <FinancicalSupportPopup
          isOpen={isFinancialSupportOpen}
          relatedTableState={tableState}
          onClose={handleCloseFinancialSupport}
          publicationId={tableState.selectedRows[0].id}
          workMode="editMode"
          onSuccess={submitFinancialPopupCallback}
        />
      )}
      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={PublicationTypes.PREPRINT_ARTICLE.code}
        />
      )}
      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0].id}
          publicationTypeCode={PublicationTypes.PREPRINT_ARTICLE.code}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
};

export const Component = React.memo(LeftPanelForThirdLevel);
