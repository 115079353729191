import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

import { CustomProps } from '../..';

type Props = {
  isApproved: boolean;
  isMembersTabHidden?: boolean;
};

const CommunityViewTemplate: React.FC<F.TemplateProps & CustomProps & Props> = ({
  isOpen,
  onClose,
  id,
  name,
  relatedTableState,
  templateName,
  communityTypes,
  usedNames,
  isApproved,
  isMembersTabHidden,
}: F.TemplateProps & CustomProps & Props) => {
  const look: F.CommunityFormLook = {
    apiID: 'GetCommunity',
    template: templateName,
    viewMode: true,
    id,
    relatedTableState,
    communityTypes,
    usedNames,
    isMembersTabHidden,
  };

  const title = (() => {
    const details = [id ? `ID: ${id}` : '', `Статус: ${isApproved ? 'Утверждено' : 'Добавлено'}`].filter(x => x).join(', ');

    return [
      'Просмотр',
      usedNames.upperNominative ? `"${usedNames.upperNominative}"` : '',
      name ? `"${name}"` : '',
      details ? `(${details})` : '',
    ]
      .filter(x => x)
      .join(' ');
  })();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'medium-height']} viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityViewTemplate);
