import { MipPartner } from 'types/models';

export function validate(charterCapital: MipPartner.CharterCapital | null) {
  const isEditDateValid = Boolean(charterCapital?.editDate);
  const isAmountValid = Boolean(charterCapital?.amount);
  const isValidFounders = Boolean(charterCapital?.founders.length);
  const foundersSumm = (charterCapital?.founders || []).reduce<number>((accum, current) => accum + Number(current.amount), 0);

  const isFoundersSummMoreThanAmount = foundersSumm > Number(charterCapital?.amount ?? 0);

  return [
    { isValid: isEditDateValid, invalidMessage: 'Заполните поле "Дата изменения"' },
    { isValid: isAmountValid, invalidMessage: 'Заполните поле "Уставной капитал"' },
    { isValid: isValidFounders, invalidMessage: 'Укажите хотя бы одного Учредителя' },
    { isValid: !isFoundersSummMoreThanAmount, invalidMessage: 'Сумма по учредителям превышает уставный капитал' },
  ];
}
