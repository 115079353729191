import React, { useCallback, useMemo, useState } from 'react';

import { buttonIcons, ConfirmPopup, IconButton, IconButtonProps, Toolbar as SharedToolbar } from 'components';

function Toolbar() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
    ],
    [handleHelpButtonClick],
  );
  return (
    <>
      <ConfirmPopup
        title="Архив отчётов по результатам научных исследований"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">
              Раздел Архив отчётов по результатам научных исследований содержит информацию о научных отчётах выполненных НИОКР с
              2006 года.
            </strong>
          </p>
          <p>
            Используйте строку поиска по словам в столбцах таблицы для поиска отчётов по тематике проекта, названию отчёта,
            ключевым словам, классификаторам (ГРНТИ,УДК и пр.)
          </p>
          <p>
            Список выбранных отчётов вы можете выгрузить в файл с помощью кнопки:
            <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
          </p>
          <p>С материалами отчётов вы можете ознакомиться в ОНТИ (отдел научно-технической информации)</p>
        </div>
      </ConfirmPopup>
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
