import { useLayoutEffect, useState, useCallback, useMemo } from 'react';
import { Document, Group, Report } from 'types/models';
import { buttonIcons, Column, IconButtonProps } from 'components';
import * as BackendAPI from 'services/BackendAPI';

const useController = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [groups, setGroups] = useState<Group<Report>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBuildReportPopupOpen, setIsBuildReportPopupOpened] = useState<boolean>(false);
  const [setupReportsSelectedRowIndex, setupReportsSetSelectedRowIndex] = useState<number | null>(null);
  const [currentGroupId, setCurrentGroupId] = useState<string | null>(null);

  const currentGroup = useMemo(() => groups.find(group => group.id === currentGroupId) || null, [currentGroupId, groups]);

  const { methods: GetReportTree } = BackendAPI.useBackendAPI('GetReportTree');
  const { methods: GetReportDocuments } = BackendAPI.useBackendAPI('GetReportDocuments');
  const { methods: SaveReportDocument } = BackendAPI.useBackendAPI('SaveReportDocument');
  const { methods: DeleteReportDocument } = BackendAPI.useBackendAPI('DeleteReportDocument');

  const getReportDocuments = useCallback(
    ({ groupId }: { groupId: string }) => {
      setIsLoading(true);
      GetReportDocuments.callAPI(
        { group: groupId },
        {
          onSuccessfullCall: ({ data }) => {
            setIsLoading(false);
            setDocuments(data.documents);
          },
          onFailedCall: () => {
            setIsLoading(false);
          },
        },
      );
    },
    [GetReportDocuments],
  );

  const updateCurrentGroupId = useCallback(
    (nextGroupName: string) => {
      const nextGroup = groups.find(group => group.caption === nextGroupName);
      if (nextGroup) {
        setupReportsSetSelectedRowIndex(null);
        getReportDocuments({ groupId: nextGroup.id });
        setCurrentGroupId(nextGroup.id);
      } else {
        setCurrentGroupId(null);
      }
    },
    [groups, getReportDocuments],
  );

  const onBuildReportClick = useCallback(() => {
    setIsBuildReportPopupOpened(true);
  }, []);
  const closeBuildReportPopup = useCallback(() => {
    setIsBuildReportPopupOpened(false);
  }, []);

  const onDoubleRowClick = useCallback(
    (rowNumber: number) => {
      setupReportsSetSelectedRowIndex(rowNumber);
      onBuildReportClick();
    },
    [onBuildReportClick],
  );

  const saveReportDocument = useCallback(
    (document: Document, callback: (document: Document) => void) => {
      if (currentGroupId) {
        setIsLoading(true);
        SaveReportDocument.callAPI(
          {
            document,
            groupId: currentGroupId,
          },
          {
            onSuccessfullCall: () => {
              setIsLoading(false);
              callback(document);
            },
            onFailedCall: () => {
              setIsLoading(false);
            },
          },
        );
      }
    },
    [SaveReportDocument, currentGroupId],
  );

  const editReportDocument = useCallback(
    (document: Document, callback: () => void) => {
      if (currentGroupId) {
        setIsLoading(true);
        SaveReportDocument.callAPI(
          {
            document,
            groupId: currentGroupId,
          },
          {
            onSuccessfullCall: () => {
              setIsLoading(false);
              callback();
            },
            onFailedCall: () => {
              setIsLoading(false);
            },
          },
        );
      }
    },
    [SaveReportDocument, currentGroupId],
  );

  const deleteReportDocument = useCallback(
    (document: Document, callback: () => void) => {
      setIsLoading(true);
      DeleteReportDocument.callAPI(
        {
          documentId: document.id,
        },
        {
          onSuccessfullCall: () => {
            setIsLoading(false);
            callback();
          },
          onFailedCall: () => {
            setIsLoading(false);
          },
        },
      );
    },
    [DeleteReportDocument],
  );

  useLayoutEffect(() => {
    setIsLoading(true);
    GetReportTree.callAPI(
      {},
      {
        onSuccessfullCall: ({ data }) => {
          if (data.groups.length) {
            setGroups(data.groups);
            const [group] = data.groups;
            setCurrentGroupId(group.id);
            GetReportDocuments.callAPI(
              { group: group.id },
              {
                onSuccessfullCall: getReportDocumensResult => {
                  setIsLoading(false);
                  setDocuments(getReportDocumensResult.data.documents);
                },
                onFailedCall: () => {
                  setIsLoading(false);
                },
              },
            );
          }
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupReportsColumns = useMemo<Column<Report>[]>(
    () => [{ label: 'Название отчета', formatValue: row => row.caption }],
    [],
  );

  const setupReportsToolbarButtons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.print,
        title: 'Построить отчет',
        onClick: onBuildReportClick,
        isDisabled: isLoading || setupReportsSelectedRowIndex === null,
      },
    ],
    [isLoading, onBuildReportClick, setupReportsSelectedRowIndex],
  );

  return {
    documents,
    groups,
    isLoading,
    currentGroupId,
    currentGroup,
    isBuildReportPopupOpen,
    setupReportsColumns,
    setupReportsToolbarButtons,
    setupReportsSelectedRowIndex,
    closeBuildReportPopup,
    setupReportsSetSelectedRowIndex,
    setDocuments,
    saveReportDocument,
    editReportDocument,
    deleteReportDocument,
    updateCurrentGroupId,
    onDoubleRowClick,
  };
};

export default useController;
