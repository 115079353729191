import React from 'react';
import { setup } from 'bem-cn';

import { Tooltip } from 'components';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});

const b = block('form-component-description');

type DescriptionMode = 'default' | 'danger' | 'warning' | 'info';
type DescriptionAlign = 'left' | 'center' | 'right';

type Props = {
  mode?: DescriptionMode;
  align?: DescriptionAlign;
  children?: any;
  tooltip?: string;
  classMixin?: string | string[];
};

export function Description({ children, mode = 'default', align, tooltip, classMixin }: Props) {
  if (!children) {
    return null;
  }

  return (
    <div className={b({ mode, align }).is({ tooltip: !!tooltip }).mix(classMixin)}>
      {!!tooltip && <Tooltip text={tooltip} />}
      {children}
    </div>
  );
}
