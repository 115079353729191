import * as XML from 'xml-js';
import type { ScientificIndex } from 'types/models';
import { makeBackendAPIConfiguration } from '../makeBackendAPIConfiguration';
import { convertScientificIndex } from './converters';

export const backendScientificIndexAPIConfigurations = {
  GetScientificIndex: makeBackendAPIConfiguration({
    id: 'GetScientificIndex',
    endpoint: '/msa/service/commands/GetScientificIndex',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetScientificIndex',
          },
          ScientificIndex: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ScientificIndex.ScientificIndex => {
      const scientificIndex = response?.Response?.ScientificIndex;
      return convertScientificIndex(scientificIndex);
    },
  }),

  SaveScientificIndex: makeBackendAPIConfiguration({
    id: 'SaveScientificIndex',
    endpoint: '/msa/service/commands/SaveScientificIndex',
    convertInputToXMLElement: (inputData: { id: string; name: string; label: string; note: string | undefined | null }) => {
      const { id, name, label, note } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveScientificIndex',
          },
          ScientificIndex: {
            ...(id ? { _attr: { id } } : {}),
            ...(name ? { Name: name } : {}),
            ...(label ? { Label: label } : {}),
            ...(note ? { Note: note } : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
