import { Dispatch, SetStateAction } from 'react';
import { Form } from 'types/models';
import * as R from 'ramda';
import { ElectronicType } from 'utils/Enums/ElectronicType';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  preparedData: Form.Publication;
  name: string | undefined;
};

const setByResponse = ({ setFormFields, preparedData, name }: Props) => {
  const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
  const getRequiredLens = (fieldName: string) => R.lensPath([fieldName, 'required']);

  setFormFields(prevFields =>
    (R.pipe as any)(
      R.set(getValueLens('name'), preparedData.fields.name || name),
      R.set(getValueLens('annotation'), preparedData.fields.annotation),
      R.set(getValueLens('authorsKeyWords'), preparedData.fields.authorsKeyWords),
      R.set(getValueLens('authorsCount'), preparedData.fields.authorsCount),
      R.set(getValueLens('publicationDate'), preparedData.fields.publicationDate),
      R.set(getValueLens('fractionalScore'), preparedData.fields.fractionalCount),
      R.set(getValueLens('doi'), preparedData.fields.doi),
      R.set(getValueLens('textLanguage'), {
        id: preparedData.fields.textLanguageId || '',
        label: preparedData.fields.textLanguageLabel || '',
      }),
      R.set(getValueLens('publisher'), {
        id: preparedData.fields.publisherId || '',
        label: preparedData.fields.publisherLabel || '',
        foreignName: preparedData.fields.publisherForeignName || '',
      }),
      R.set(getValueLens('address'), preparedData.fields.address),
      R.set(getValueLens('year'), preparedData.fields.year || new Date().getFullYear()),
      R.set(getValueLens('pageCount'), preparedData.fields.pageCount),
      R.set(getRequiredLens('urlDate'), preparedData.fields.isElectronic === 'false'),

      R.set(getValueLens('printPageCount'), preparedData.fields.printPageCount || undefined),
      R.set(getValueLens('pageFrom'), preparedData.fields.pageFrom),
      R.set(getValueLens('pageTo'), preparedData.fields.pageTo),
      R.set(getValueLens('tirage'), preparedData.fields.tirage),
      R.set(getValueLens('categoryEdition'), {
        label: preparedData.fields.categoryEditionLabel || '',
        id: preparedData.fields.categoryEditionId || '',
      }),

      R.set(getValueLens('grnti'), {
        id: preparedData.fields.grntiId || '',
        label: preparedData.fields.grntiLabel || '',
      }),
      R.set(getValueLens('udk'), preparedData.fields.udk),
      R.set(getValueLens('isbn'), preparedData.fields.ISBN),
      R.set(getValueLens('eIsbn'), preparedData.fields.ISBN),
      R.set(getValueLens('originalLanguage'), {
        id: preparedData.fields.originalLanguageId || '',
        label: preparedData.fields.originalLanguageLabel || '',
      }),
      R.set(getValueLens('grif'), {
        id: preparedData.fields.grifId || '',
        label: preparedData.fields.grifLabel || '',
      }),
      R.set(getValueLens('republishData'), preparedData.fields.republishData),
      R.set(getValueLens('volumeNumber'), preparedData.fields.volumeNumber),
      R.set(getValueLens('url'), preparedData.fields.url),
      R.set(
        getRequiredLens('url'),
        preparedData.fields.isElectronic === 'true' && preparedData.fields.electronicType === ElectronicType.WEB.code,
      ),
      R.set(getValueLens('urlDate'), preparedData.fields.urlDate),
      R.set(getRequiredLens('urlDate'), preparedData.fields.isElectronic === 'true'),

      R.set(getValueLens('libraryLink'), preparedData.fields.libraryLink),
      R.set(getValueLens('seriesTitle'), preparedData.fields.seriesTitle),

      R.set(getValueLens('study'), {
        id: preparedData.fields.studyId || '',
        label: preparedData.fields.studyLabel || '',
      }),
      R.set(getValueLens('articleNumber'), preparedData.fields.articleNumber),
      R.set(getValueLens('electronicMediaSource'), preparedData.fields.electronicMediaSource),
      R.set(getValueLens('electronicSystemRequirements'), preparedData.fields.electronicSystemRequirements),
      R.set(getValueLens('file'), preparedData.file),
      R.set(getValueLens('bibliographicRecord'), preparedData.fields.bibliographicRecord),

      R.set(getValueLens('foreignName'), preparedData.fields.foreignName),
      R.set(getValueLens('foreignAnnotation'), preparedData.fields.foreignAnnotation),
      R.set(getValueLens('foreignAuthorsKeyWords'), preparedData.fields.foreignAuthorsKeyWords),
      R.set(getValueLens('foreignAuthors'), preparedData.fields.foreignAuthors),
    )(prevFields),
  );
};

export { setByResponse };
