import React, { memo } from 'react';
import { FormComponent } from 'components';

type Props = {
  libraryUrl: string;
};

export const LibraryField = memo(({ libraryUrl }: Props) => {
  if (!libraryUrl) {
    return null;
  }

  return (
    <FormComponent.Field label="В электронном каталоге НБ" align="center">
      <FormComponent.Link href={libraryUrl} label={libraryUrl} />
    </FormComponent.Field>
  );
});
