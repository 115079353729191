import React from 'react';

import { FormComponent, SectionTitle, TextInput, TextInputMode, TextDateTime } from 'components';

import { Form } from 'types/models';

type Props = {
  formFields: Form.Fields;
  disabled?: boolean;
};

export function Nma({ formFields, disabled }: Props) {
  // eslint-disable-next-line max-len
  const balanceOrderNumberTooltip = `Номер акта/приказа/протокола. Если процессуально используются разные типы распорядительных документов, то рекомендуется указывать какой именно документ, например так: Акт № 1/23`;

  return (
    <FormComponent.Wrapper>
      <SectionTitle title="Балансовый учет" />
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Номер документа" tooltip={balanceOrderNumberTooltip}>
              <TextInput
                value={formFields.balanceOrderNumber.value || ''}
                onChange={formFields.balanceOrderNumber.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Стоимость, руб">
              <TextInput
                mode={TextInputMode.number}
                value={formFields.balancePrice.value ? formFields.balancePrice.value.toString() : '0'}
                onChange={formFields.balancePrice.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Field label={formFields.loDate.title}>
            <TextDateTime value={formFields.loDate.value} onChange={formFields.loDate.onChange} />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.hiDate.title}>
            <TextDateTime value={formFields.hiDate.value} onChange={formFields.hiDate.onChange} />
          </FormComponent.Field>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
