import { ProjectAssignmentMessage } from 'types/models/Project';

export function validate(projectAssignmentMessage: ProjectAssignmentMessage) {
  if (!projectAssignmentMessage) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidMessage = Boolean(projectAssignmentMessage.message);

  return [{ isValid: isValidMessage, invalidMessage: 'Задайте текст сообщения' }];
}
