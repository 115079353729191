import { Table } from 'types/models';

type Props = {
  securityDocumentId?: string | null;
  isFiltersHidden?: boolean;
};

type Arguments = {
  securityDocumentId?: string | null;
};

export function GetSecurityDocumentIncentivePaymentList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetSecurityDocumentIncentivePaymentList',
    requestData: args,
    isFiltersHidden: args?.isFiltersHidden,
    header: {
      firstLevel: {
        title: 'Стимулирующие выплаты за подачу заявки и использование ОИС',
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
