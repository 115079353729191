import { NirRequest } from 'types/models';

export class TenderType {
  static readonly PromPartner: NirRequest.TenderType = 'PP';

  static readonly InitiativeGroup: NirRequest.TenderType = 'IG';

  static readonly WorldLabs: NirRequest.TenderType = 'LMU';

  static readonly YouthLab: NirRequest.TenderType = 'ML';

  static readonly ScientistGroups: NirRequest.TenderType = 'ONG';

  static readonly Postdocs: NirRequest.TenderType = 'PD';
}
