import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, TextInput, TextInputMode } from 'components';

import useController from './controller';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  publicationId: string;
};

const EditDoiPopupComponent = ({ onClose, onSuccess, publicationId }: Props) => {
  const { formFields, submitForm, publication, isSubmitButtonDisabled, isLoading, hasAccess } = useController({
    onClose,
    onSuccess,
    publicationId,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <>
          {publication?.status.typeLabel && (
            <span>
              <strong>{publication?.status.typeLabel}:</strong>
            </span>
          )}
        </>
      </FormComponent.Line>
      <FormComponent.Line>
        <span>{publication?.fields.bibliographicRecord}</span>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.doi.title} tooltip={formFields.doi.tooltipText} labelSize="fit">
          <TextInput
            mode={TextInputMode.doi}
            value={formFields.doi.value}
            onChange={formFields.doi.onChange}
            isDisabled={!hasAccess || isLoading}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Actions>
        <Button mode={ButtonMode.primary} text="Сохранить" onClick={submitForm} isDisabled={isSubmitButtonDisabled} />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = memo(EditDoiPopupComponent);
