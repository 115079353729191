import { ReferenceItem } from 'components';
import { Article, Document, Participation, Person, Publication, SecurityDocument } from '..';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

export type DistributionMethodBetweenFunds = 'AFFILIATED' | 'PAID' | 'ALL';

export type AuthorPayment = {
  id: string | null;
  type: Item<EventType> | null;
  year: string;
  quarter: Item | null;
  month: string;
  status: Item<AuthorPaymentRequestStatus>;
  statusChanger: IdItem | null;
  creator: IdItem | null;
  distributionMethodBetweenNominees: Item | null;
  distributionMethodBetweenFunds: DistributionMethodBetweenFunds | null;
  maxFundMoney: string;
  factFundMoney: string;
  remainderFundMoney: string;
  factNomineesMoney: string;
  maxNomineesMoney: string;
  remainderNomineesMoney: string;
  documents: Document[];
  nominees: Nominee[];
  departments: Department[];
  regulationParagraph: string | null;
  regulationName: string | null;
  statusChanged: {
    time: string;
    date: string;
  } | null;
  article: Article | null;
  event: Event | null;
  publication: Publication | null;
  participation: Participation | null;
  isManualEdit: boolean;
  patent?: SecurityDocument | null;
};

export type Nominee = {
  id?: string | null;
  person: Person.ScientistPerson;
  isAffiliated: boolean;
  isPaid: boolean;
  planMoney: string;
  factMoney: string;
  weight: string;
  name: string;
  job: Person.ScientistJob | null;
  education: Person.ScientistEducation | null;
  foundationJob: Person.ScientistJob | null;
  foundationEducation: Person.ScientistEducation | null;
  degree: Person.ScientistDegree | null;
  rank: Person.ScientistRank | null;
  citizenship: ReferenceItem | null;
  topLevelDepartment: TopLevelDepartment | null;
};

export type TopLevelDepartment = {
  id: string;
  name: string;
};

export type Department = {
  amountMoney: string;
  fraction: string;
  nomineeQuantity: string;
  topLevelDepartment: TopLevelDepartment;
};

export type Event = {
  id: string;
  level: string;
  isStudent: boolean;
  organizers: string;
  name: string;
  fullName: string;
  projects: string;
};

export type IdItem = {
  id: string;
  value: string;
};

export type Item<T = string> = {
  label: string;
  value: T;
};

export type EventType = 'CONFERENCE' | 'ARTICLE' | 'MONOGRAPH' | 'TEXTBOOK' | 'MEDAL';

export class IncentivePaymentRequestType {
  public static ARTICLE: string = 'ARTICLE';

  public static MONOGRAPH: string = 'MONOGRAPH';

  public static SECURITY_DOCUMENT_ISSUE: string = 'SECURITY_DOCUMENT_ISSUE';

  public static SECURITY_DOCUMENT: string = 'SECURITY_DOCUMENT';

  public static EXHIBIT: string = 'EXHIBIT';

  public static CONFERENCE: string = 'CONFERENCE';

  public static MEDAL: string = 'MEDAL';

  public static TEXTBOOK: string = 'TEXTBOOK';
}

export type TermPreset = {
  id: string;
  type: Item | null;
  regulationParagraph: string;
  regulationName: string;
};

export type PaymentFeedline = {
  id: string | null;
  messageCategory: ReferenceItem;
  message: string;
  status: Item;
  isSystem: boolean;
  createdBy: string;
  modifiedBy: string;
  createdDate: string;
  modifiedDate: string;
};

export type EmployeePayment = {
  id: string | null;
  amountMoney: number;
  status: Item<EmployeePaymentStatus>;
  fund: EmployeeFund;
  nominees: EmployeeNominee[];
  documents: Document[];
};

export type EmployeeNominee = {
  id: string | null;
  employee: Person.ScientistPerson | null;
  amountMoney: number;
  job: EmployeeJob | null;
};

export type EmployeeJob = {
  id: string;
  refAppointment: {
    id: string;
    label: string;
  };
};

export type EmployeeFund = {
  month: string;
  quarter: Item;
  type: Item;
  year: string;
  commonSumm: number;
  departmentId: string;
  department?: {
    label: string;
  }[];
};

export type EmployeePaymentStatus = 'SENT' | 'DRAFT' | 'PAID' | 'REJECTED' | 'PAYMENT_APPROVED';
