import { GetPersonAvailableHistoryElementsRequestData } from 'services/BackendAPI/configurations/author/types';
import { Table } from 'types/models';

type Props = object & GetPersonAvailableHistoryElementsRequestData;

export const GetPersonAvailableHistoryElements = ({
  id,
  type,
  personId,
}: Props): Table.Specification<object, GetPersonAvailableHistoryElementsRequestData> => ({
  apiID: 'GetPersonAvailableHistoryElements',
  header: {
    thirdLevel: {
      withTemplatesPanel: false,
    },
  },
  requestData: { id, type, personId },
});
