import { Table, Form } from 'types/models';

export const convertAffiliation = (row: Table.Entry): Form.Affiliation => {
  return {
    organization: { kind: row.Type, id: row.id, name: row.FullName },
  };
};

export const convertCouncil = (row: Table.Entry): Form.Council => {
  return {
    id: row.id,
    name: row.label,
  };
};

export const REQUIRED_CATEGORY_CODE = 'supervisor';
