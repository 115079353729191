import { useCallback, useLayoutEffect, useState } from 'react';
import * as R from 'ramda';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Document, Form, ForeignSecurityDocument } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './validate';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, relatedTableState, arm },
  } = useFormContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [foreignSecurityDocument, setForeignSecurityDocument] = useState<ForeignSecurityDocument | null>();
  const [documents, setDocuments] = useState<Document[]>([]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название ОИС',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    documentType: {
      value: '',
      isValid: true,
      required: true,
      title: 'Тип Документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          documentType: { ...prevState.documentType, value },
          kind: { ...prevState.kind, value: null },
        }));
      },
    },
    number: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          number: { ...prevState.number, value },
        }));
      },
    },
    startDate: {
      value: '',
      isValid: true,
      required: false,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          startDate: { ...prevState.startDate, value },
        }));
      },
    },
    endDate: {
      value: '',
      isValid: true,
      required: false,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          endDate: { ...prevState.endDate, value },
        }));
      },
    },
    kind: {
      value: '',
      isValid: true,
      required: false,
      title: 'Вид ОИС',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          kind: { ...prevState.kind, value },
        }));
      },
    },
  });

  const { methods: getForeignSecurityDocument } = BackendAPI.useBackendAPI('GetForeignSecurityDocument', {
    onSuccessfullCall: ({ data }) => {
      setForeignSecurityDocument(data);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('name'), data.name),
          R.set(getValueLens('number'), data.number),
          R.set(getValueLens('documentType'), data.documentType),
          R.set(getValueLens('startDate'), data.startDate),
          R.set(getValueLens('endDate'), data.endDate),
          R.set(getValueLens('kind'), data.kind),
        )(prevFields),
      );
      setDocuments(data.documents);
    },
  });
  const { methods: saveForeignSecurityDocument } = BackendAPI.useBackendAPI(
    arm === 'pc' ? 'SavePersonalForeignSecurityDocument' : 'SaveForeignSecurityDocument',
  );
  const onSubmit = useCallback(
    needClose => {
      const errors = validate(foreignSecurityDocument, formFields);
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          showNotification({ message: error.invalidMessage, theme: 'danger' });
        });
        return false;
      }
      saveForeignSecurityDocument.callAPI(
        {
          id,
          name: formFields.name.value,
          startDate: formFields.startDate.value,
          endDate: formFields.endDate.value,
          kind: formFields.kind.value,
          number: formFields.number.value,
          documentType: formFields.documentType.value,
          members: foreignSecurityDocument?.members || [],
          documents,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Документ успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data.id) {
              getForeignSecurityDocument.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      id,
      foreignSecurityDocument,
      tableStreams,
      updateWorkModeAfterSaveAndContinue,
      documents,
      onClose,
      formFields,
      getForeignSecurityDocument,
      saveForeignSecurityDocument,
    ],
  );

  useLayoutEffect(() => {
    getForeignSecurityDocument.callAPI({ id, arm });
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    needClose => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  return {
    foreignSecurityDocumentId: id ?? null,
    foreignSecurityDocument,
    setForeignSecurityDocument,
    workMode,
    formFields,
    handleFormSubmit,
    documents,
    setDocuments,
    relatedTableState,
  };
}
