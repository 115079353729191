import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';

import {
  GetKindConsumptionRequest,
  SaveContestRequestKindConsumptionRequest,
  SaveKindConsumptionRequest,
  SaveMobileKindConsumptionRequest,
  SaveNirRequestKindConsumptionRequest,
  SaveRequestKindConsumptionRequest,
} from './types';

import {
  convertServerContestRequestKindConsumption,
  convertServerKindConsumption,
  convertServerMobileKindConsumption,
  convertServerNirRequestKindConsumption,
} from 'services/BackendAPI/configurations/kindConsumption/converters';
import {
  convertContestRequestKindConsumptionRequest,
  convertKindConsumptionRequest,
  convertMobileKindConsumptionRequest,
  convertNirRequestKindConsumptionRequest,
} from './requestConverters';

export const kindConsumptionConfigurations = {
  GetKindConsumption: makeBackendAPIConfiguration({
    id: 'GetKindConsumption',
    endpoint: '/msa/service/commands/GetKindConsumption',
    convertInputToXMLElement: ({ id }: GetKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'GetKindConsumption' },
        KindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerKindConsumption(response.Response.KindConsumption),
  }),
  SaveKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveKindConsumption',
    endpoint: '/msa/service/commands/SaveKindConsumption',
    convertInputToXMLElement: (inputData: SaveKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'SaveKindConsumption' },
        KindConsumption: {
          ...convertKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
export const mobileKindConsumptionConfigurations = {
  GetMobileKindConsumption: makeBackendAPIConfiguration({
    id: 'GetMobileKindConsumption',
    endpoint: '/msa/service/commands/GetMobileKindConsumption',
    convertInputToXMLElement: ({ id }: GetKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'GetMobileKindConsumption' },
        MobileKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerMobileKindConsumption(response.Response.MobileKindConsumption),
  }),
  SaveMobileKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveMobileKindConsumption',
    endpoint: '/msa/service/commands/SaveMobileKindConsumption',
    convertInputToXMLElement: (inputData: SaveMobileKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'SaveMobileKindConsumption' },
        MobileKindConsumption: {
          ...convertMobileKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
export const requestKindConsumptionConfigurations = {
  GetRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'GetRequestKindConsumption',
    endpoint: '/msa/service/commands/GetRequestKindConsumption',
    convertInputToXMLElement: ({ id }: GetKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'GetRequestKindConsumption' },
        RequestKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerNirRequestKindConsumption(response.Response.RequestKindConsumption),
  }),
  SaveRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveRequestKindConsumption',
    endpoint: '/msa/service/commands/SaveNirRequestKindConsumption',
    convertInputToXMLElement: (inputData: SaveRequestKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'SaveRequestKindConsumption' },
        RequestKindConsumption: {
          ...convertNirRequestKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
export const nirRequestKindConsumptionConfigurations = {
  GetNirRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'GetNirRequestKindConsumption',
    endpoint: '/msa/service/commands/GetNirRequestKindConsumption',
    convertInputToXMLElement: ({ id }: GetKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestKindConsumption' },
        NirRequestKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerNirRequestKindConsumption(response.Response.NirRequestKindConsumption),
  }),
  SaveNirRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveNirRequestKindConsumption',
    endpoint: '/msa/service/commands/SaveNirRequestKindConsumption',
    convertInputToXMLElement: (inputData: SaveNirRequestKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'SaveNirRequestKindConsumption' },
        NirRequestKindConsumption: {
          ...convertNirRequestKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
export const contestRequestKindConsumptionConfigurations = {
  GetContestRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'GetContestRequestKindConsumption',
    endpoint: '/msa/service/commands/GetContestRequestKindConsumption',
    convertInputToXMLElement: ({ id }: GetKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'GetContestRequestKindConsumption' },
        ContestRequestKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerContestRequestKindConsumption(response.Response.ContestRequestKindConsumption),
  }),
  SaveContestRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveContestRequestKindConsumption',
    endpoint: '/msa/service/commands/SaveContestRequestKindConsumption',
    convertInputToXMLElement: (inputData: SaveContestRequestKindConsumptionRequest) => ({
      Request: {
        _attr: { commandName: 'SaveContestRequestKindConsumption' },
        ContestRequestKindConsumption: {
          ...convertContestRequestKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};
export const consumptionConfigurations = {
  ...kindConsumptionConfigurations,
  ...mobileKindConsumptionConfigurations,
  ...nirRequestKindConsumptionConfigurations,
  ...contestRequestKindConsumptionConfigurations,
  ...requestKindConsumptionConfigurations,
};
