import React from 'react';

import { FormComponent, InputSelect, TextDateTime } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectStateRegistrationReportAddForm({ viewMode, editMode, onClose }: Props) {
  const {
    handleCitisDateChange,
    handleStageChange,
    onSubmit,
    stageOptions,
    stateRegistrationReport,
    stageReportTypeOptions,
    handleStageReportTypeChange,
    selectedStageLabel,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Выберите этап проекта" isRequired>
            <InputSelect
              options={stageOptions}
              value={{
                label: selectedStageLabel,
                value: stateRegistrationReport.stage?.id ?? '',
              }}
              onSelectChange={handleStageChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Тип отчета" isRequired>
            <InputSelect
              options={stageReportTypeOptions}
              value={stateRegistrationReport.reportType}
              onSelectChange={handleStageReportTypeChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Зарегистрировать отчет в ЕГИСУ до" isRequired>
            <TextDateTime value={stateRegistrationReport?.citisDate} onChange={handleCitisDateChange} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectStateRegistrationReportAddForm);
