import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

const CommunityViewTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  templateName,
}: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetCommunity',
    template: templateName,
    viewMode: true,
    id,
    relatedTableState,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Просмотр члена сообщества (ID: ${id})`}
      size={['large', 'small-height']}
      viewMode
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityViewTemplate);
