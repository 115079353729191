import { Table } from 'types/models';

type Props = {
  estimateId: string;
  viewMode?: boolean;
  handleRefreshEstimate?: Function;
  handleBalanceClick?: Function;
  isFiltersHidden?: boolean;
};

export const GetEstimatePositionItemList = (requestData: Props): Table.Specification => ({
  apiID: 'GetEstimatePositionItemList',
  requestData,
  isFiltersHidden: requestData.isFiltersHidden,
  header: {
    // firstLevel: {
    //   title: 'Расходы по статьям затрат',
    // },
    thirdLevel: {
      withTemplatesPanel: true,
      // LeftPanel: LeftPanelForThirdLevel(),
    },
  },
});
