import { Mode } from 'components/ListEdit/model';
import { MipPartner } from 'types/models';

export const validate = (founder: MipPartner.Founder | null, index: number | null, list: MipPartner.Founder[], mode: Mode) => {
  const founders = mode === 'edit' ? list.filter((_, itemIndex) => index !== itemIndex) : list;

  const isHasSameFounder =
    founder?.scientist?.id &&
    founder?.enterpriseCustomer?.id &&
    founders.some(
      x => x.scientist?.id === founder?.scientist?.id || x.enterpriseCustomer?.id === founder?.enterpriseCustomer?.id,
    );

  const isValidFounder = Boolean(founder?.scientist) || Boolean(founder?.enterpriseCustomer);
  const isValidAmount = Boolean(founder?.amount);

  return [
    { isValid: !isHasSameFounder, invalidMessage: 'Учредитель уже выбран' },
    { isValid: isValidFounder, invalidMessage: 'Не выбран учредитель' },
    { isValid: isValidAmount, invalidMessage: 'Не выбран сумма' },
  ];
};
