import { Table } from 'types/models';
import { EmployeePaymentStatus } from 'types/models/Payment';

import { Toolbar } from './Toolbar/Toolbar';

import { makeUseCustomController, State } from './makeUseCustomController';

export type PaymentFeedlineRequestData = {
  employeePaymentId: string;
  isDisabledToolbar?: boolean;
  status: EmployeePaymentStatus | null;
};

export function GetEmployeePaymentRequestFeedlineList(
  deps: PaymentFeedlineRequestData,
): Table.Specification<State, PaymentFeedlineRequestData> {
  return {
    apiID: 'GetEmployeePaymentRequestFeedlineList',
    header: {
      firstLevel: {
        title: 'Журнал сообщений',
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    requestData: deps,
    useCustomController: makeUseCustomController(deps),
  };
}
