import { Person } from 'types/models';

export const validate = (identifier: Person.Identifier | null) => {
  if (!identifier) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidCitationSystem = Boolean(identifier.citationSystem?.id);
  const isValidIdentifier = Boolean(identifier.identifier);

  return [
    { isValid: isValidCitationSystem, invalidMessage: 'Заполните Систему цитирования' },
    { isValid: isValidIdentifier, invalidMessage: 'Заполните Идентификатор' },
  ];
};

export const checkIsIdentifierLinkCorrect = (
  identifier: Person.Identifier | null,
  callback: (updatedRow: Person.Identifier) => void,
) => {
  if (identifier) {
    const identifierLink = identifier.link && !identifier.link.includes('http') ? `https://${identifier.link}` : identifier.link;
    callback({
      ...identifier,
      link: identifierLink,
    });
  }
};
