import { Dispatch, SetStateAction } from 'react';
import { Form } from 'types/models';
import { Participation, ParticipationEventSource } from 'types/models/Participation';
import { WorkMode } from 'types/models/Table';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  data: Participation;
  workMode: WorkMode;
  sourceEvent?: ParticipationEventSource | null;
};

const setByResponse = ({ setFormFields, data, sourceEvent, workMode }: Props) => {
  setFormFields((prevState: Form.Fields) => ({
    ...prevState,
    note: { ...prevState.note, value: data.note },
    publicationReportTheme: {
      ...prevState.publicationReportTheme,
      value: {
        name: data.publication?.fields.name || '',
        id: data.publication?.status.id || '',
      },
      required: !!data.publication,
    },
    reportTheme: { ...prevState.reportTheme, value: data.reportTheme },
    reportDate: {
      ...prevState.reportDate,
      value: sourceEvent?.startDate && workMode === 'addMode' ? sourceEvent?.startDate : data.reportDate,
      required: !!data.publication || !!data.projects[0],
    },
    reportType: { ...prevState.reportType, value: data.reportType, required: !!data.publication || !!data.projects[0] },
    isReportPublished: { ...prevState.isReportPublished, value: data.publication ? 'true' : 'false' },
    form: { ...prevState.form, value: data.form },
  }));
};

export { setByResponse };
