import { Table as T } from 'types/models';

type RequestData = {
  estimatePositionId: string;
};

export function GetFundCardPositionReceivingList({ estimatePositionId }: RequestData): T.Specification {
  return {
    apiID: 'GetFundCardPositionReceivingList',
    requestData: { estimatePositionId },
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
