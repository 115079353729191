import React, { useEffect, useState } from 'react';

import { Tabs, Tab, FormComponent, ListEditTable, Modal } from 'components';

import { Documents } from 'features/Documents';
import { GetContestRequestChangeList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { Estimate } from 'features/Form/views';
import { StageInfo } from './Tabs/StageInfo/StageInfo';
import { ProjectInfo } from './Tabs/ProjectInfo/ProjectInfo';
import { Toolbar } from './Toolbar/Toolbar';
import { useController } from './controller';
import { PerformanceIndicators } from './Tabs/PerformanceIndicators/PerformanceIndicators';
import { FeedlineList } from './Tabs/FeedlineList/FeedlineList';
import { useFormContext } from 'features/Form/hooks';
import { ContestRequestStatus } from 'utils/Enums/ContestRequestStatus';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { ContestRequestType } from 'utils/Enums';
import { PerformersListEdit } from './PerformersListEdit';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ContestRequestForm({ viewMode, editMode, onClose }: Props) {
  const {
    requestId,
    workMode,
    contestRequest,
    formFields,
    makeChangeHandler,
    onSubmit,
    requestStagePerformers,
    performersChangeHandler,
    performersButtons,
    sendMessage,
    addApprovement,
    changeStatus,
    performersListEditColumns,
    requestType,
    isStageModalOpen,
    setIsStageModalOpen,
    selectStage,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  const { userPermission } = useAppDataContext();
  const status = contestRequest?.status?.value as ContestRequestStatus;

  const [stageIndex, setStageIndex] = useState<number | null>(null);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          return [
            workMode === 'viewMode' ? 'Просмотр' : !requestId ? 'Добавление' : 'Редактирование',
            `заявки на ${requestType === ContestRequestType.ACTUALIZATION ? 'актуализацию' : 'продление'} проекта:`,
            contestRequest?.project?.number || '',
            contestRequest?.status?.label ? `- ${contestRequest?.status?.label}` : '',
          ]
            .filter(x => x)
            .join(' ');
        })(),
      );
    }
  }, [contestRequest?.project?.number, contestRequest?.status?.label, requestId, requestType, setTitle, workMode]);

  return (
    <>
      <FormComponent.Template>
        <Toolbar
          isRequestPayments={contestRequest}
          disabledEdit={workMode === 'viewMode'}
          onSave={(needClose, cb) => onSubmit(needClose, cb)}
          onSendMessage={sendMessage}
          changeStatus={changeStatus}
          addApprovement={addApprovement}
          requestType={requestType}
        />

        <FormComponent.Template>
          <Tabs>
            <Tab title="О проекте">
              <ProjectInfo
                contestRequest={contestRequest}
                project={contestRequest?.project}
                makeChangeHandler={makeChangeHandler}
                disabled={workMode === 'viewMode'}
                formFields={formFields}
              />
            </Tab>
            <Tab
              title="Описание этапа"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`CONTEST_REQUEST_EDIT_${status}_REQUEST`])}
            >
              <StageInfo
                contestRequest={contestRequest}
                makeChangeHandler={makeChangeHandler}
                disabled={workMode === 'viewMode'}
                formFields={formFields}
              />
            </Tab>
            <Tab
              title="Показатели эффективности"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`CONTEST_REQUEST_EDIT_${status}_INDICATORS`])}
            >
              <PerformanceIndicators
                disabled={workMode === 'viewMode'}
                contestRequest={contestRequest}
                makeChangeHandler={makeChangeHandler}
              />
            </Tab>
            <Tab
              title="Исполнители работ"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`CONTEST_REQUEST_EDIT_${status}_PERFORMERS`])}
            >
              <PerformersListEdit
                rows={requestStagePerformers || []}
                onChange={value => performersChangeHandler(value)}
                disabled={!!viewMode}
                isShowPersonalInfo
                isShowPersonalReport
                isShowHistory
                extraToolbarButtons={performersButtons}
                columns={performersListEditColumns}
              />
            </Tab>
            <Tab
              title="Смета"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`CONTEST_REQUEST_EDIT_${status}_ESTIMATE`])}
            >
              <Estimate
                disabled={workMode === 'viewMode'}
                estimatePositions={contestRequest?.requestStageEstimatePositions || []}
                handleEstimateChange={makeChangeHandler('requestStageEstimatePositions')}
                handleTotalChange={formFields.requestStagePlanAmount.onChange}
              />
            </Tab>
            <Tab title="Журнал сообщений">
              <FeedlineList contestRequestId={requestId || '-1'} />
            </Tab>
            <Tab
              title="Документы"
              isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`CONTEST_REQUEST_EDIT_${status}_DOCUMENTS`])}
            >
              <Documents
                documents={contestRequest?.documents ?? []}
                disabled={workMode === 'viewMode'}
                setDocuments={makeChangeHandler('documents')}
                isCanIsSharedEdit
                sharedLabel="Отображать в проекте"
                sourceLabelForLocalDocs="Текущая заявка"
                defaultSharedValue
                isShowSource
              />
            </Tab>
            <Tab title="Регистрация изменений в заявке">
              <DataGrid specification={GetContestRequestChangeList({ contestRequestId: requestId || '-1' })} />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>

      <Modal
        isOpen={isStageModalOpen}
        onClose={() => setIsStageModalOpen(false)}
        title="Выберите этап проекта двойным кликом"
        size={['medium', 'small-height']}
      >
        <FormComponent.Wrapper>
          <ListEditTable
            rows={contestRequest?.project?.stages || []}
            columns={[
              { label: 'Номер', formatValue: row => row.number || '', styles: { width: '10%' } },
              { label: 'Название', formatValue: row => row.name || '', styles: { width: '60%' } },
              { label: 'Дата начала', formatValue: row => row.startDate || '', styles: { width: '15%' } },
              { label: 'Дата окончания', formatValue: row => row.endDate || '', styles: { width: '15%' } },
            ]}
            selectedRowIndex={stageIndex}
            selectRow={setStageIndex}
            onDoubleRowClick={selectStage}
          />
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export const Component = React.memo(ContestRequestForm);
