import { SecurityDocumentTypes } from 'types/models';

export type State = {
  documentType: SecurityDocumentTypes;
};

export function makeUseCustomController(documentType: SecurityDocumentTypes) {
  return function useCustomController(): State {
    return {
      documentType,
    };
  };
}
