import React from 'react';
import { FormComponent, Modal, Toolbar as SharedToolbar } from 'components';
import useController from './controller';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <Modal title="Лицензионные платежи" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        <FormComponent.Wrapper>
          <div className="modal-help">
            <p>
              Источник данных для таблицы — сведения из документов бухгалтерского учёта. Для отображения данных в таблице
              необходимо выполнение трёх условий:
            </p>
            <ul>
              <li>
                <strong>Есть информационный обмен</strong> с информационной системой Бухгалтерского учёта университета;
              </li>
              <li>
                В <strong>научном проекте</strong> (модуль <strong>Проекты</strong>) <strong>на вкладке Заявки</strong> указан
                договор на использование интеллектуального права на объекты;
              </li>
              <li>
                В системе бухгалтерского учёта <strong>Документ о приходе средств по договору</strong> на использование
                интеллектуального права на объекты интеллектуальной собственности университета, в котором МИП — Лицензиат,{' '}
                <strong>связан с Шифром проекта</strong>.
              </li>
            </ul>
          </div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
};
