import { makeStream } from 'StreamRx';
import { Form } from 'types/models';

export type AddNewMessageData = {
  message: string;
  messageId: string;
  labelId: string;
  jointEventId: string;
};

export const streams = {
  toggleMessageForm: makeStream(),
  addNewGridSetting: makeStream<AddNewMessageData>(),
  changeMessageState: makeStream<Partial<Form.MagazineMessage>>(),
  changeMessageLabelState: makeStream<Form.MessageLabel>(),
  clearMessageState: makeStream(),
  clearMessageLabelState: makeStream(),
};
