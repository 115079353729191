import React, { useCallback } from 'react';

import { EditPartner } from 'features/EditPartner/EditPartner';
import { State, Mode } from '../makeUseCustomController';

type Props = {
  customState: State;
};

export function Form({ customState }: Props) {
  const { partner, isOpenForm, closeForm, mode, reloadTable } = customState;

  const onSuccessSave = useCallback(() => {
    reloadTable();
    closeForm();
  }, [reloadTable, closeForm]);

  const getTitle = () => {
    const partnerInfo = `"${partner?.shortName ?? ''}" (ID ${partner?.id ?? ''}, Статус записи: ${partner?.status?.label ?? ''})`;
    const map: Record<Mode, string> = {
      add: 'Добавление партнёра',
      edit: `Редактирование ${partnerInfo}`,
      view: `Просмотр ${partnerInfo}`,
    };
    return mode ? map[mode] : '';
  };

  return (
    <EditPartner
      isOpenForm={isOpenForm}
      title={getTitle()}
      partner={partner}
      closeForm={closeForm}
      disabled={mode === 'view'}
      mode={mode || ''}
      onSuccessSavePartner={onSuccessSave}
    />
  );
}
