import { useMemo, useCallback } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { ProjectAssignmentStatus } from 'types/models/Project';
import { State } from '../makeUseCustomController';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { isNextPurchaseRequestStatus, PurchaseRequestStatus } from 'types/models/PurchaseRequest';

type Props = {
  tableState: Table.State;
  customState: State;
};

const useController = ({ customState, tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const {
    setIsOpenMessageModal,
    newStatus,
    setNewStatus,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isMessageHistoryModalOpen,
    setIsMessageHistoryModalOpen,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    approverKind,
  } = customState;

  const { methods: changePurchaseRequestStatus } = BackendAPI.useBackendAPI('ChangePurchaseRequestStatus');

  const { methods: addPurchaseRequestApprovement } = BackendAPI.useBackendAPI('AddPurchaseRequestApprovement');

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState]);

  const isAuthor = useMemo(() => {
    return !!(row !== null && row.IsApplicant === 'true');
  }, [row]);

  const onAddClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const onCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const onViewClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, [setIsViewFormOpen]);

  const onCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, [setIsViewFormOpen]);

  const onEditClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const onCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const onDeleteClick = useCallback(() => {
    setIsDeleteWarningOpen(true);
  }, [setIsDeleteWarningOpen]);

  const onChangeStatusClick = useCallback(
    (status: ProjectAssignmentStatus) => {
      setNewStatus(status);
      setIsOpenMessageModal(true);
    },
    [setNewStatus, setIsOpenMessageModal],
  );

  const onCloseDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
  }, [setIsDeleteWarningOpen]);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, [setIsHelpFormOpen]);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, [setIsHelpFormOpen]);

  const handleSaveStatusClick = useCallback(
    (message: string) => {
      changePurchaseRequestStatus.callAPI(
        { status: newStatus, message: message || '', ids: tableState.selectedRows.map(r => r.id) },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.success) {
              setNewStatus('');
              setIsOpenMessageModal(false);
              tableStreams.reloadTable.push();
            } else {
              showNotification({ message: data.message, theme: 'danger' });
            }
          },
        },
      );
    },
    [changePurchaseRequestStatus, tableStreams, newStatus, tableState, setNewStatus, setIsOpenMessageModal],
  );

  const onSubmitDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
    if (row?.id) {
      tableStreams.deleteRow.push({
        deleteRowId: row.id,
        command: 'DeletePurchaseRequest',
        deletedItemPropName: 'PurchaseRequest',
      });
    }
  }, [row?.id, tableStreams.deleteRow, setIsDeleteWarningOpen]);

  const selectedRowsLength = useMemo(() => tableState.selectedRows.length, [tableState]);
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(() => {
    return {
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    };
  }, [selectedRowsLength]);
  const isAddButtonDisabled = useMemo(() => !rowsAvailability.ALWAYS, [rowsAvailability]);
  const isViewButtonDisabled = useMemo(() => !rowsAvailability.SINGLE_SELECTED, [rowsAvailability]);
  const isEditButtonDisabled = useMemo(() => {
    if (row && isAuthor && row['id:Status'] === PurchaseRequestStatus.DRAFT) {
      return false;
    }
    return true;
  }, [isAuthor, row]);
  const isDeleteButtonDisabled = useMemo(() => {
    if (row && isAuthor && row['id:Status'] === PurchaseRequestStatus.DRAFT) {
      return false;
    }
    return true;
  }, [row, isAuthor]);

  const isChangeStatusDisabled = useCallback(
    (nextStatus: PurchaseRequestStatus) => {
      if (row && isNextPurchaseRequestStatus(row['id:Status'], nextStatus)) {
        return false;
      }
      return true;
    },
    [row],
  );

  const handleApprovementSubmit = useCallback(
    (message: string) => {
      addPurchaseRequestApprovement.callAPI(
        { purchaseRequestId: row?.id, message, approverKind },
        {
          onSuccessfullCall: ({ data }) => {
            setIsApprovementModalOpen(false);
            tableStreams.reloadTable.push();
            showNotification({ message: data.message || 'Согласование добавлено', theme: 'success' });
          },
        },
      );
    },
    [row, addPurchaseRequestApprovement, tableStreams, setIsApprovementModalOpen, approverKind],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: isViewButtonDisabled,
        onClick: onViewClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        profilePermissionName: Permits.PURCHASE_REQUEST_ADD,
        onClick: onAddClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        profilePermissionName: Permits.PURCHASE_REQUEST_EDIT,
        isDisabled: isEditButtonDisabled,
        onClick: onEditClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        profilePermissionName: Permits.PURCHASE_REQUEST_DELETE,
        isDisabled: isDeleteButtonDisabled,
        onClick: onDeleteClick,
      },
      {
        icons: buttonIcons.activation,
        title: 'Передать на рассмотрение',
        profilePermissionName: Permits.PURCHASE_REQUEST_CHANGE_STATUS_CONSIDERATION,
        isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.CONSIDERATION),
        onClick: () => onChangeStatusClick(PurchaseRequestStatus.CONSIDERATION),
      },
    ],
    [
      isViewButtonDisabled,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      isChangeStatusDisabled,
      onViewClick,
      onAddClick,
      onEditClick,
      onDeleteClick,
      handleHelpButtonClick,
      onChangeStatusClick,
    ],
  );

  return {
    buttons,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    isViewFormOpen,
    onViewClick,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    handleHelpButtonClick,
    handleSaveStatusClick,
    newStatus,
    isMessageHistoryModalOpen,
    setIsMessageHistoryModalOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsDeleteWarningOpen,
    setIsViewFormOpen,
    setIsHelpFormOpen,
    isAddButtonDisabled,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    handleApprovementSubmit,
  };
};

export default useController;
