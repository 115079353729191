import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { ProjectCodeFormLook } from 'types/models/Form/look';
import { EntityLocker } from 'features/EntityLocker';
import { Modal } from 'components';

type ProjectCodeEditTemplateProps = {
  replaceMode: boolean;
} & F.TemplateProps;

const ProjectCodeEditTemplate: React.FC<ProjectCodeEditTemplateProps> = ({
  isOpen,
  onClose,
  id,
  replaceMode,
  relatedTableState,
}: ProjectCodeEditTemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: ProjectCodeFormLook = {
    apiID: 'GetProjectCode',
    template: 'ProjectCodeForm',
    editMode: true,
    id: String(id),
    replaceMode,
    relatedTableState,
    setTitle,
  };

  return (
    <EntityLocker onClose={onClose} isOpen={isOpen} entity="ProjectCode" id={look.id}>
      <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
        <Form look={look} onClose={onClose} />
      </Modal>
    </EntityLocker>
  );
};

export const Component = React.memo(ProjectCodeEditTemplate);
