import React, { useCallback } from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { ValueOf } from 'types/helpers';
import { OtherProject } from 'types/models';

type Props = {
  financing: OtherProject.Financing;
  setFinancing: (financing: OtherProject.Financing | null) => void;
};

export const Fields = ({ financing, setFinancing }: Props) => {
  const makeChangeHandler = useCallback(
    (key: keyof OtherProject.Financing) => (value: ValueOf<OtherProject.Financing>) => {
      setFinancing({ ...financing!, [key]: value });
    },
    [financing, setFinancing],
  );
  return (
    <>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Сумма" isRequired>
            <TextInput mode={TextInputMode.number} value={financing.amount} onChange={makeChangeHandler('amount')} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Год" isRequired>
            <TextInput mode={TextInputMode.year} value={financing.year} onChange={makeChangeHandler('year')} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </>
  );
};
