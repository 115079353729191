import * as R from 'ramda';

import { EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';

export function validate(indicator: EditableIndicator | null, list: EditableIndicator[], mode: 'view' | 'add' | 'edit' | null) {
  if (!indicator) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const indicators = mode === 'edit' ? R.reject(x => Boolean((x as any)?.stageNumber === indicator.stageNumber), list) : list;

  const isValidStage = Boolean(indicator.stageNumber);
  const isValidYear = Boolean(indicator.year);
  const isValidIndicator = Boolean(indicator.ref);
  const isValidPlan = Boolean(indicator.plan);
  const isValidFact = Boolean(indicator.fact);

  const isHasDublicateIndicator = indicators.find(
    x => x.ref?.id === indicator.ref?.id && x.stageNumber === indicator.stageNumber && x.year === indicator.year,
  );
  const isValidDublicateIndicator = !isHasDublicateIndicator;

  return [
    { isValid: isValidStage, invalidMessage: 'Выберите этап' },
    { isValid: isValidYear, invalidMessage: 'Введите год' },
    { isValid: isValidIndicator, invalidMessage: 'Выберите индикатор' },
    { isValid: isValidPlan, invalidMessage: 'Введите плановое значение' },
    { isValid: isValidFact, invalidMessage: 'Выберите фактическое значение' },
    { isValid: isValidDublicateIndicator, invalidMessage: 'Выбранный индикатор уже добавлен для данного этапа' },
  ];
}
