import { Table } from 'types/models';

type Args = {
  subjectId: string;
  isSubject: boolean;
};

type RequestData = {
  subjectId: string;
};

export function GetSubjectFinancingList({ subjectId, isSubject }: Args): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetSubjectFinancingList',
    header: {
      firstLevel: {
        title: isSubject
          ? 'Список проектов, в которых созданы РИД из состава тематики'
          : 'Научные проекты, в которых созданы РИД из состава продукта',
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { subjectId },
    isFiltersHidden: true,
  };
}
