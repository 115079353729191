import { DetailProps } from '.';

export type State = {
  isViewMode: boolean;
};

export function makeUseCustomController({ isViewMode = false }: DetailProps) {
  return function useCustomController(): State {
    return {
      isViewMode,
    };
  };
}
