/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useMemo } from 'react';

import { FormComponent, CommentPopup, Modal, Tab, Tabs } from 'components';

import { Document, Table } from 'types/models';
import { EmployeePayment, EmployeePaymentStatus } from 'types/models/Payment';
import { General } from './General/General';
import { Toolbar } from './Toolbar/Toolbar';
import { RequestHeader } from 'features/Form/views/ModalHeader';
import { Mode } from '../makeUseCustomController';
import { Documents } from 'features/Documents';

type Props = {
  isOpen: boolean;
  employeePayment: EmployeePayment | null;
  comment: string;
  onClose(): void;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  saveEmployeePayment(): void;
  setComment(comment: string): void;
  toggleVisibleCommentForm(): void;
  startSaveEmployeePayment(): void;
  isOpenCommentForm: boolean;
  mode: Mode | null;
  status: EmployeePaymentStatus | null;
  selectedFund: Table.Entry | null;
  changeEmployeePaymentStatus(status: EmployeePaymentStatus): void;
  openConfirmApproveStatusModal(): void;
  openConfirmRevisionStatusModal(): void;
  openConfirmDeleteModal(): void;
  openMessageLogModal(): void;
};

export function RequestForm(props: Props) {
  const {
    isOpen,
    employeePayment,
    setEmployeePayment,
    onClose,
    saveEmployeePayment,
    mode,
    status,
    comment,
    setComment,
    toggleVisibleCommentForm,
    isOpenCommentForm,
    startSaveEmployeePayment,
    changeEmployeePaymentStatus,
    openConfirmApproveStatusModal,
    openConfirmRevisionStatusModal,
    openConfirmDeleteModal,
    openMessageLogModal,
    selectedFund,
  } = props;

  const setDocuments = useCallback(
    (documents: Document[]) => {
      // TODO: mock emplyee payment
      setEmployeePayment({ ...employeePayment!, documents });
    },
    [employeePayment, setEmployeePayment],
  );

  const isViewMode = useMemo(() => mode === 'view', [mode]);

  const documents = <Documents disabled={isViewMode} documents={employeePayment?.documents ?? []} setDocuments={setDocuments} />;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={RequestHeader({
        employeePayment,
        subText:
          mode === 'add'
            ? selectedFund?.faculty ?? ''
            : employeePayment?.fund.department?.map(d => d.label).join(', ') || 'не указаны',
      })}
      size={['extralarge', 'medium-height']}
    >
      <FormComponent.Template>
        <Toolbar
          status={status}
          mode={mode}
          onSave={startSaveEmployeePayment}
          changeEmployeePaymentStatus={changeEmployeePaymentStatus}
          openConfirmApproveStatusModal={openConfirmApproveStatusModal}
          openConfirmRevisionStatusModal={openConfirmRevisionStatusModal}
          openConfirmDeleteModal={openConfirmDeleteModal}
          openMessageLogModal={openMessageLogModal}
        />

        <FormComponent.Template>
          <Tabs>
            <Tab title="Основное">
              <General disabled={isViewMode} employeePayment={employeePayment} setEmployeePayment={setEmployeePayment} />
            </Tab>
            <Tab title="Документы">{documents}</Tab>
          </Tabs>
        </FormComponent.Template>

        <CommentPopup
          title="Сохранение заявки"
          isOpen={isOpenCommentForm}
          onClose={toggleVisibleCommentForm}
          comment={comment}
          setComment={setComment}
          onSubmit={saveEmployeePayment}
        />
      </FormComponent.Template>
    </Modal>
  );
}
