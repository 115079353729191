import { ReferenceItem } from 'components';

import { FileInfo, Affiliation } from 'types/models';
import { Item } from 'types/models/common';

export type ScientitstTextFields = {
  activities: string;
  scientificResults: string;
  keywordsRus: string;
  keywordsEnglish: string;
};

export type ScientistPerson = ScientitstTextFields & {
  fullName: string;
  shortName: string;
  id: string;
  bibliographicNames: BibliographicName[];
  scientist: Scientist | null;
  scientistJobs: ScientistJob[];
  scientistEducations: ScientistEducation[];
  degrees: ScientistDegree[];
  ranks: ScientistRank[];
  academicRanks: ScientistAcademicRank[];
  identifiers: Identifier[];
  hirshIndexes: HirshIndex[];
  grntis: ReferenceItem[];
  udks: ReferenceItem[];
  oecds: ReferenceItem[];
  domainKnowledges: ReferenceItem[];
  vakSpecialities: ReferenceItem[];
  communityMembers: CommunityMember[];
  changes: Change[];
  affiliations: Affiliation[];
  calculations: Calculation | null;
  avatar: FileInfo;
};
export type CalculationPublications = {
  all: number;
  allByYear0: number;
  allByYear1: number;
  allByYear2: number;
  allByYear3: number;
  allByYear4: number;
  allByYear5: number;
  scopusWos: number;
  scopusWosByYear0: number;
  scopusWosByYear1: number;
  scopusWosByYear2: number;
  scopusWosByYear3: number;
  scopusWosByYear4: number;
  scopusWosByYear5: number;
  q1q2ScopusWos: number;
  q1q2ScopusWosByYear0: number;
  q1q2ScopusWosByYear1: number;
  q1q2ScopusWosByYear2: number;
  q1q2ScopusWosByYear3: number;
  q1q2ScopusWosByYear4: number;
  q1q2ScopusWosByYear5: number;
  allMonographs: number;
  monographsByYear0: number;
  monographsByYear1: number;
  monographsByYear2: number;
  monographsByYear3: number;
  monographsByYear4: number;
  monographsByYear5: number;
};

export type CalculationProjects = {
  all: number;
  leader: number;
  leaderByYear0: number;
  leaderByYear1: number;
  leaderByYear2: number;
  leaderByYear3: number;
  leaderByYear4: number;
  leaderByYear5: number;
  notLeader: number;
  notLeaderByYear0: number;
  notLeaderByYear1: number;
  notLeaderByYear2: number;
  notLeaderByYear3: number;
  notLeaderByYear4: number;
  notLeaderByYear5: number;
};
export type CalculationParticipations = CalculationBaseProps & {
  reporterByYear0: number;
  reporterByYear1: number;
  reporterByYear2: number;
  reporterByYear3: number;
  reporterByYear4: number;
  reporterByYear5: number;
};
export type CalculationBaseProps = {
  all: number;
  byYear0: number;
  byYear1: number;
  byYear2: number;
  byYear3: number;
  byYear4: number;
  byYear5: number;
};
export type CalculationSecurityDocuments = {
  all: number;
  allEvidenceRequests: number;
  allEvidences: number;
  allKnowHows: number;
  allPatentRequests: number;
  allPatents: number;
  evidenceRequestsByYear0: number;
  evidenceRequestsByYear1: number;
  evidenceRequestsByYear2: number;
  evidenceRequestsByYear3: number;
  evidenceRequestsByYear4: number;
  evidenceRequestsByYear5: number;
  evidencesByYear0: number;
  evidencesByYear1: number;
  evidencesByYear2: number;
  evidencesByYear3: number;
  evidencesByYear4: number;
  evidencesByYear5: number;
  knowHowsByYear0: number;
  knowHowsByYear1: number;
  knowHowsByYear2: number;
  knowHowsByYear3: number;
  knowHowsByYear4: number;
  knowHowsByYear5: number;
  patentRequestsByYear0: number;
  patentRequestsByYear1: number;
  patentRequestsByYear2: number;
  patentRequestsByYear3: number;
  patentRequestsByYear4: number;
  patentRequestsByYear5: number;
  patentsByYear0: number;
  patentsByYear1: number;
  patentsByYear2: number;
  patentsByYear3: number;
  patentsByYear4: number;
  patentsByYear5: number;
};

export type Calculation = {
  publications: CalculationPublications;
  participations: CalculationParticipations;
  event: CalculationBaseProps;
  projects: CalculationProjects;
  securityDocuments: CalculationSecurityDocuments;
};

export type HirshIndex = {
  id: string | null;
  citationSystem: ReferenceItem | null;
  value: string | null;
  note: string | null;
  date: string;
};

export type Change = {
  id: string | null;
  lastName: string;
  firstName: string;
  patronymic: string;
  birthDate: string;
  citizenship: ReferenceItem;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
};

export type CommunityMember = {
  id: string;
  startDate: string;
  endDate: string;
  joinDate: string;
  leaveDate: string;
  organization?: string;
  cooperationType: Item | null;
  role: {
    label: string;
    value: string;
  };
  community: ReferenceItem;
  vakSpecialty?: ReferenceItem;
};

export type BibliographicName = {
  id: string;
  name: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  language: BiblographicLanguage | null;
  isDefault: boolean;
};

export type BiblographicLanguage = {
  id: string;
  label: string;
  isoCode?: string;
};

export type Scientist = {
  id: string;
  fullName: string;
  averageFiveYearImpactFactor: string;
  averageImpactFactor: string;
  calculateFiveYearImpactFactor: string;
  calculateImpactFactor: string;
  citations: string;
  dateBirth: string;
  citizenship: ReferenceItem | null;
  englishFirstName: string;
  englishLastName: string;
  englishPatronymic: string;
  firstName: string;
  fromBus: boolean;
  gender: string;
  guid: string;
  inn: string;
  lastName: string;
  patronymic: string;
  status: string;
  statusFull: string;
  snils: string;
  mobilePhone: string;
  email: string;
  phone: string;
  mobilePhoneIntegration: string;
  phoneIntegration: string;
  emailIntegration: string;
  mobilePhoneOther?: string;
  emailOther?: string;
  phoneOther?: string;
  note: string;
  languageCommunication?: string;
};

export type ScientistRank = {
  id: string | null;
  originalId: string;
  refRank: ReferenceItem;
} & BaseHonor;

export type ScientistAcademicRank = {
  id: string | null;
  originalId: string;
  refAcademicRank: ReferenceItem;
  academy: ReferenceItem;
} & BaseHonor;

export type ScientistJob = {
  originalId: string;
  id: string | null;
  employeeNumber?: string;
  enterpise: string;
  creationDate: string;
  creationSource: string;
  modificationDate: string;
  modificationSource: string;
  deletionDate: string;
  deletedBy: string;
  dateReceipt: string;
  dateDismissal: string | null;
  department: string | null;
  isActive: boolean;
  isReadOnly: boolean;
  status: Item;
  appointmentCategory: ReferenceItem | null;
  staffGroup: ReferenceItem | null;
  appointment: string | null;
  refAppointment: ReferenceItem | null;
  refDepartment: ReferenceItem | null;
  civilContractName: string | null;
  civilContractNumber: string | null;
  civilContractDate: string | null;
  staffState: ReferenceItem | null;
};

export type ScientistEducation = {
  originalId: string;
  id: string | null;
  status: Item;
  enterprise: string;
  deletionDate: string;
  deletedBy: string;
  creationDate: string;
  creationSource: string;
  modificationDate: string;
  modificationSource: string;
  dateReceipt: string;
  dateDismissal: string | null;
  refSpeciality: ReferenceItem | null;
  refDepartment: ReferenceItem | null;
  department: string | null;
  speciality: string | null;
  isTSU: boolean;
  isReadOnly: boolean;
  isActive: boolean;
};

export type Identifier = {
  citationSystem: ReferenceItem | null;
  identifier: string;
  authorName: string | null;
  id: string | null;
  link: string | null;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
};

export type ScientistDegree = {
  originalId: string;
  id: string | null;
  refDegree: ReferenceItem | null;
} & BaseHonor;

export type BaseHonor = {
  dateAssign: string;
  isReadOnly: boolean;
  createdBy: string;
  creationSource: string;
  modifiedBy: string;
  modificationSource: string;
  creationDate: string;
  modificationDate: string;
  isActive: boolean;
  deletionDate: string;
  deletedBy: string;
};

export type OriginalIdField = ScientistDegree | ScientistEducation | ScientistJob | ScientistRank | ScientistAcademicRank;

export function isWithOriginalIdField(arg: any): arg is OriginalIdField {
  return typeof arg === 'object' && arg.originalId !== undefined && arg.id !== undefined;
}

export function isScientistDegree(arg: any): arg is ScientistDegree {
  return arg.refDegree !== undefined;
}

export function isScientistAcademicRank(arg: any): arg is ScientistAcademicRank {
  return arg.refAcademicRank !== undefined;
}

export function isScientistRank(arg: any): arg is ScientistRank {
  return arg.refRank !== undefined;
}
