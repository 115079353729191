import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import { Author, Form, Partner, Subject, Table as T } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';

import { useFormContext } from 'features/Form/hooks';

import workModeHook from 'features/Form/hooks/workModeHook';

import * as R from 'ramda';
import { showNotification } from 'features/Notifications';
import { showErrorsMessages } from 'utils/Common';
import { Exponent, PartnerRef } from 'types/models/Exponent';
import { ValueOf } from 'types/helpers';
import { Item } from 'types/models/common';
import { validate } from './validate';
import { useAppDataContext } from 'features/AppData/context';
import { ReferenceItem } from 'components';

type GetRefProps = {
  refName: string;
  code: string;
};

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { settings } = useAppDataContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);

  const [isConfirmPopupRunnable, setIsConfirmPopupRunnable] = useState<{ run: () => void }>();

  const [exponent, setExponent] = useState<Exponent>({
    id: null,
    name: '',
    type: null,
    subject: null,
    authors: [],
    partners: [],
    note: null,
    participations: [],
  });

  const [subjectSecurityDocuments, setSubjectSecurityDocuments] = useState<string>('');
  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название разработки',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value,
          },
        }));
      },
    },
    type: {
      value: '',
      isValid: true,
      required: true,
      title: 'Тип',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          type: {
            ...prevState.type,
            value,
          },
        }));
      },
    },
    note: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: {
            ...prevState.note,
            value,
          },
        }));
      },
    },
  });

  const { methods: getExponent } = BackendAPI.useBackendAPI('GetExponent', {
    onSuccessfullCall: ({ data }) => {
      setExponent(data);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('name'), data.name),
          R.set(getValueLens('type'), data.type),
          R.set(getValueLens('note'), data.note),
        )(prevFields),
      );
    },
  });

  const securityDocumentsRow = useMemo(
    () =>
      subjectSecurityDocuments ||
      [
        ...(exponent?.subject?.rntds.length
          ? exponent.subject.rntds.map(x =>
              typeof x.securityDocuments === 'string'
                ? x.securityDocuments
                : x.securityDocuments?.length
                ? x.securityDocuments
                    .filter(y => y)
                    .map(y => `${y.type?.label} № ${y.documentNumber} ${y.intellectualPropertyName}`)
                    .join('; ')
                : '',
            )
          : []),
      ]
        .filter(x => x)
        .join('; ') ||
      '',
    [exponent?.subject?.rntds, subjectSecurityDocuments],
  );

  const presentationFormsRow = useMemo(() => {
    const result: Item[] = [];
    if (exponent?.participations.length)
      exponent.participations.forEach(x =>
        x.presentationForms.forEach(y => {
          if (!result.find(z => z.value === y.value)) result.push(y);
        }),
      );
    return result.map(x => x.label).join(', ');
  }, [exponent?.participations]);

  const makeChangeHandler = useCallback(
    (key: keyof Exponent) => (val: ValueOf<Exponent>) => {
      setExponent(prev => ({ ...prev!, [key]: val }));
    },
    [],
  );

  const handleSubjectChange = useCallback(
    (e: Subject) => {
      setExponent((prevState: Exponent) => ({ ...prevState, subject: e }));
      if (e?.description) formFields.note.onChange(e.description);
      if (!e) setSubjectSecurityDocuments('');
    },
    [formFields.note],
  );

  const handleAuthorsChange = useCallback(
    (value: Author[]) => {
      makeChangeHandler('authors')(value);
    },
    [makeChangeHandler],
  );

  const { methods: getPartnerAPI } = BackendAPI.useBackendAPI('GetPartner');
  const { methods: savePartnerAPI } = BackendAPI.useBackendAPI('SavePartner');
  const { methods: getReferenceElementAPI } = BackendAPI.useBackendAPI('GetReferenceElement');

  const loadPartner = useCallback(
    (partner: PartnerRef, onLoad: (partner: Partner) => void) => {
      getPartnerAPI.callAPI(
        {
          partnerId: partner.id,
          enterpriseCustomerId: partner.type === 'ENTERPRISE_CUSTOMER' ? partner.id : null,
          enterpriseId: partner.type === 'ENTERPRISE' ? partner.id : null,
          shortName: null,
          fullName: null,
        },
        {
          onSuccessfullCall: ({ data }) => {
            onLoad(data);
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handlePartnersChange = useCallback(
    (value: PartnerRef[]) => {
      makeChangeHandler('partners')(value);
    },
    [makeChangeHandler],
  );

  const getReference = useCallback<({ refName, code }: GetRefProps) => Promise<ReferenceItem | null>>(
    ({ refName, code }: GetRefProps) =>
      new Promise(resolve => {
        getReferenceElementAPI.callAPI(
          {
            refName,
            code,
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getReferenceElementAPI],
  );

  const checkPartnerStatus = useCallback(
    (row: PartnerRef[], submit: (updatedRows: PartnerRef[]) => void) => {
      loadPartner(row[0], (partner: Partner) => {
        if (partner?.state?.customFields?.code !== 'ACTIVE') {
          setIsConfirmPopupRunnable({
            run: async () => {
              const [entepriseStatus] = await Promise.all([getReference({ refName: 'RefEnterpriseStatus', code: 'ACTIVE' })]);

              if (partner && entepriseStatus) {
                const nextPartner: Partner = { ...partner, state: entepriseStatus };
                savePartnerAPI.callAPI(
                  { partner: nextPartner },
                  {
                    onSuccessfullCall: ({ data }) => {
                      loadPartner({ id: data.id || '', name: '', address: '' }, (newPartner: Partner) => {
                        submit([
                          {
                            id: newPartner.id || '',
                            name: newPartner.fullName,
                            address: newPartner.fullName,
                          },
                        ]);
                        setIsConfirmPopupOpen(false);
                      });
                    },
                  },
                );
              }
            },
          });
          setIsConfirmPopupOpen(true);
        } else {
          submit(row);
        }
      });
    },
    [loadPartner, setIsConfirmPopupRunnable, getReference, savePartnerAPI],
  );

  const loadExponent = useCallback(
    ({ exponentId }) => {
      getExponent.callAPI(
        { id: exponentId },
        {
          onSuccessfullCall: ({ data }) => {
            setExponent(data);
          },
        },
      );
    },
    [getExponent, setExponent],
  );

  const { methods: saveExponent } = BackendAPI.useBackendAPI('SaveExponent');

  const onSubmit = useCallback(
    needClose => {
      const validationInfo = validate(exponent, formFields);
      const errorMessages = [...validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage)];

      if (errorMessages.length) {
        showErrorsMessages(errorMessages);
        return;
      }
      saveExponent.callAPI(
        {
          id: id || null,
          name: formFields.name.value,
          type: formFields.type.value,
          subject: exponent?.subject || null,
          authors: exponent?.authors || [],
          partners: exponent?.partners || [],
          note: formFields.note.value,
          participations: [],
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Экспонат успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push();
              onClose();
            } else if (data?.id) {
              getExponent.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [exponent, formFields, saveExponent, id, tableStreams.reloadTable, onClose, getExponent, updateWorkModeAfterSaveAndContinue],
  );

  useLayoutEffect(() => {
    if (id) {
      loadExponent({ exponentId: id });
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    needClose => {
      onSubmit(needClose);
    },
    [onSubmit],
  );
  const modalTableSubjectConverter = useCallback<(row: T.Entry) => Subject>(row => {
    setSubjectSecurityDocuments(row.SecurityDocuments || '');
    return ({
      id: row.id,
      name: row.Name || '',
      type: { value: row['id:type'], label: row.type },
      code: row.code,
      description: row.Description || '',
      rntds: [],
      mips: [],
      documents: [row.SecurityDocuments || ''].filter(x => x),
      foreignSecurityDocuments: [],
    } as unknown) as Subject;
  }, []);

  const partnerTableRowConverter = useCallback<(row: T.Entry) => PartnerRef>(
    row => ({
      id: row.id,
      name: row.FullName,
      address: row.FullName,
      type: row.Type,
    }),
    [],
  );

  const handleConfirmPartnerButtonClick = useCallback(() => {
    isConfirmPopupRunnable?.run();
  }, [isConfirmPopupRunnable]);

  return {
    exponentId: id ?? null,
    exponent,
    formFields,
    workMode,
    handleFormSubmit,
    modalTableSubjectConverter,
    handleAuthorsChange,
    partnerTableRowConverter,
    handlePartnersChange,
    handleSubjectChange,
    securityDocumentsRow,
    presentationFormsRow,
    settings,
    handleConfirmPartnerButtonClick,
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    checkPartnerStatus,
  };
}
