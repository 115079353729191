import React, { useCallback, useMemo } from 'react';

import { IconButtonProps, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import * as BackendAPI from 'services/BackendAPI';

type WrapperProps = {
  isDisabled?: boolean;
};

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({ isDisabled }: WrapperProps) =>
  React.memo(({ tableState }: Props) => {
    const selectedRowsLength = tableState.selectedRows.length;
    const rowsAvailability: Table.ToolbarStateRowsAvailability = {
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    };

    const isProcessButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

    const { methods: processProjectChanges } = BackendAPI.useBackendAPI('ProcessProjectChanges');

    const tableStreams = useLocalTableStreams();

    const handleProcessButtonClick = useCallback(() => {
      processProjectChanges.callAPI(
        { id: tableState.selectedRows[0].ChangeInProjectId },
        {
          onSuccessfullCall: () => {
            tableStreams.reloadTable.push();
            showNotification({ message: 'Изменения успешной обработаны', theme: 'success' });
          },
        },
      );
    }, [tableState, processProjectChanges, tableStreams]);

    const buttons: IconButtonProps[] = useMemo(
      () => [
        {
          icons: buttonIcons.check,
          title: 'Отметить обработку изменений',
          isDisabled: isDisabled || isProcessButtonDisabled,
          permissionName: Permits.PROJECT_CODE_PROCESSING_EDIT,
          onClick: handleProcessButtonClick,
        },
      ],
      [isProcessButtonDisabled, handleProcessButtonClick],
    );

    return <Toolbar buttons={buttons} />;
  });

export const Component = LeftPanelForThirdLevel;
