import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertFileToXML, convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { ProjectAssignment, ProjectAssignmentStatus } from 'types/models/Project';
import { convertProjectAssignmentToXML } from 'services/BackendAPI/configurations/projectAssignments/requestConverters';
import {
  convertProjectAssignment,
  convertProjectAssignmentMessage,
} from 'services/BackendAPI/configurations/projectAssignments/converters';
import { FileInfo } from 'types/models';

export const projectAssignmentConfigurations = {
  SaveProjectAssignment: makeBackendAPIConfiguration({
    id: 'SaveProjectAssignment',
    endpoint: '/msa/service/commands/SaveProjectAssignment',
    convertInputToXMLElement: (inputData: ProjectAssignment) => ({
      Request: {
        _attr: {
          commandName: 'SaveProjectAssignment',
        },
        ProjectAssignment: { ...convertProjectAssignmentToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetProjectAssignment: makeBackendAPIConfiguration({
    id: 'GetProjectAssignment',
    endpoint: '/msa/service/commands/GetProjectAssignment',
    convertInputToXMLElement: (inputData: { id?: string | null }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetProjectAssignment',
          },
          ProjectAssignment: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertProjectAssignment(response.Response.ProjectAssignment),
  }),
  ChangeProjectAssignmentStatus: makeBackendAPIConfiguration({
    id: 'ChangeProjectAssignmentStatus',
    endpoint: '/msa/service/commands/ChangeProjectAssignmentStatus',
    convertInputToXMLElement: ({
      message,
      ids,
      status,
    }: {
      message: string;
      ids: string[];
      status: ProjectAssignmentStatus;
    }) => ({
      Request: {
        _attr: { commandName: 'ChangeProjectAssignmentStatus' },
        ProjectAssignment: ids.map(id => ({
          _attr: { id },
          Status: status,
          ...(message ? { Message: message } : {}),
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveProjectAssignmentMessage: makeBackendAPIConfiguration({
    id: 'SaveProjectAssignmentMessage',
    endpoint: '/msa/service/commands/SaveProjectAssignmentMessage',
    convertInputToXMLElement: ({
      id,
      message,
      assignmentId,
      file,
    }: {
      id?: string | null;
      message: string;
      assignmentId: string;
      file: FileInfo;
    }) => ({
      Request: {
        _attr: { commandName: 'SaveProjectAssignmentMessage' },
        ProjectAssignmentMessage: {
          ...(id ? { _attr: { id } } : {}),
          ProjectAssignment: {
            _attr: { id: assignmentId },
          },
          ...(message ? { Message: message } : {}),
          ...convertFileToXML('File', file),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetProjectAssignmentMessage: makeBackendAPIConfiguration({
    id: 'GetProjectAssignmentMessage',
    endpoint: '/msa/service/commands/GetProjectAssignmentMessage',
    convertInputToXMLElement: (inputData: { id?: string | null }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetProjectAssignmentMessage',
          },
          ProjectAssignmentMessage: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertProjectAssignmentMessage(response.Response.ProjectAssignmentMessage),
  }),
};
