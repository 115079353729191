import { CitationSystem } from 'types/models';

export function validate(citationSystem: CitationSystem | null) {
  if (!citationSystem) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidRef = Boolean(citationSystem.refCitationSystem && citationSystem.refCitationSystem.id);
  const isValidIdentifier = Boolean(citationSystem.identifier);

  return [
    { isValid: isValidRef, invalidMessage: 'Выберите базу цитирование' },
    { isValid: isValidIdentifier, invalidMessage: 'Укажите идентификатор' },
  ];
}
