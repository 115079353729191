import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const RecognitionAddTemplate: React.FC<F.TemplateProps & { memberEditLocked: boolean }> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  memberEditLocked,
}: F.TemplateProps & { memberEditLocked: boolean }) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look & { memberEditLocked: boolean } = {
    apiID: 'GetRecognition',
    template: 'RecognitionForm',
    relatedTableState,
    name,
    arm: 'pc',
    setTitle,
    memberEditLocked,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(RecognitionAddTemplate);
