import React from 'react';
import { Tabs, Tab } from 'components';
import { DataGrid } from 'features/Table';

import { GetContractPaymentLogList } from 'features/Table/specifications';
import { GetLicenceAgreementContractList } from 'features/Table/specifications/GetLicenceAgreementContractList';

export function ContractPayments() {
  return (
    <Tabs id="contractPayments">
      <Tab title="Журнал поступления лицензионных платежей">
        <DataGrid specification={GetContractPaymentLogList()} />
      </Tab>
      <Tab title="План - график платежей по договорам">
        <DataGrid specification={GetLicenceAgreementContractList()} />
      </Tab>
    </Tabs>
  );
}
