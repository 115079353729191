import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import { State } from '../makeUseCustomController';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  customState: State;
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ customState, tableState }: Props) {
  const {
    projectId,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({ customState, tableState });
  const { projectSelectable } = customState;
  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.ProjectPerformerOrderViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          projectSelectable={projectSelectable}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.ProjectPerformerOrderEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          projectSelectable={projectSelectable}
          relatedTableState={tableState}
        />
      )}
      <modalTemplates.ProjectPerformerOrderAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        projectId={projectId || ''}
        projectSelectable={projectSelectable}
        relatedTableState={tableState}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный приказ? (${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name ?? ''}
        reportCaption={currentReport?.caption ?? ''}
        projectPerformerOrderId={tableState.selectedRows[0]?.id}
      />
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
