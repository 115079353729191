import React, { memo } from 'react';
import { setup } from 'bem-cn';

import { ListEdit, Button, ButtonMode, FormComponent } from 'components';

import { GetReferenceElementList } from 'features/Table/specifications';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Department } from 'types/models/Participation';
import { formatAuthor } from '../MembersList/helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import useController from './controller';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('edit-participation-departments');

type Props = {
  id: string;
  type: string;
  afterSubmit: (departments: Department[]) => void;
  onClose: () => void;
  participantsTitle: string;
  hideToolbarButtons: boolean;
  hideActionButtons: boolean;
};

const EditParticipationDepartmentsComponent = ({
  type,
  id,
  onClose,
  afterSubmit,
  participantsTitle,
  hideToolbarButtons,
  hideActionButtons,
}: Props) => {
  const {
    isLoading,
    departmentColumns,
    participationData,
    participationDepartments,
    setParticipationDepartments,
    handleSaveParticipation,
    extraToolbarButtons,
  } = useController({
    id,
    onClose,
    afterSubmit,
  });

  const eventData = `${
    participationData?.event
      ? // eslint-disable-next-line max-len
        `${participationData?.event?.fullName} (ID: ${participationData?.event?.id}. Статус записи: ${participationData?.event?.status})`
      : ''
  }`;

  return (
    <FormComponent.Template>
      {!hideToolbarButtons && <ControlPanel handleSave={handleSaveParticipation} />}

      <FormComponent.Wrapper>
        <ul className={b('prop-list')}>
          <li className={b('prop')}>
            {type === ParticipationTypes.PARTICIPATION_CONFERENCE.code && (
              <>
                <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
                <span className={b('prop-value')}>
                  {/* eslint-disable-next-line */}
                  {`Конференция: ${eventData}`}
                </span>
              </>
            )}
            {type === ParticipationTypes.PARTICIPATION_CONCERT.code && (
              <>
                <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
                <span className={b('prop-value')}>
                  {/* eslint-disable-next-line */}
                  {`Творческое мероприятие: ${eventData}`}
                </span>
              </>
            )}
            {type === ParticipationTypes.PARTICIPATION_CONTEST.code && (
              <>
                <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
                <span className={b('prop-value')}>
                  {/* eslint-disable-next-line */}
                  {`Конкурс: ${eventData}`}
                </span>
              </>
            )}
            {type === ParticipationTypes.PARTICIPATION_EXPOSITION.code && (
              <>
                <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
                <span className={b('prop-value')}>
                  {/* eslint-disable-next-line */}
                  {`Выставка: ${eventData}`}
                </span>
              </>
            )}
          </li>
          {type === ParticipationTypes.PARTICIPATION_CONFERENCE.code &&
            (participationData?.reportTheme || participationData?.publication?.fields.name) && (
              <li className={b('prop')}>
                <span className={b('prop-key')}>{'Тема доклада: '}</span>
                <span className={b('prop-value')}>{participationData?.publication?.fields.name}</span>
              </li>
            )}
          <li className={b('prop')}>
            <span className={b('prop-key')}>{`${participantsTitle} `}</span>
            <span className={b('prop-value')}>
              {participationData?.members.map((member, index) => (
                <div key={index} className={b('participant')} dangerouslySetInnerHTML={{ __html: formatAuthor(member) }} />
              ))}
            </span>
          </li>
        </ul>
        <div className={b('table-wrapper')}>
          <ListEdit
            title="Подразделения к которым относится участие"
            isRequired
            isToolbarDisabled={isLoading}
            rows={participationDepartments}
            columns={departmentColumns}
            onChange={setParticipationDepartments}
            extraToolbarButtons={extraToolbarButtons}
            withMessages
            isDeleteConfirmEnabled
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "Подразделения"',
              modalTableRowConverter: row => ({ name: row.name, id: row.id, code: row.code }),
              modalTableSpecification: GetReferenceElementList({
                requestData: {
                  filters: [{ key: 'restricted', values: ['false'] }],
                  name: 'RefDepartment',
                },
              }),
            }}
          />
        </div>

        {!hideActionButtons && (
          <ul className={b('action-list')}>
            <li className={b('action-item')}>
              <Button mode={ButtonMode.primary} text="Сохранить связь с подразделениями" onClick={handleSaveParticipation} />
            </li>
            <li className={b('action-item')}>
              <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
            </li>
          </ul>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
};

export const Component = memo(EditParticipationDepartmentsComponent);
