import React, { useCallback } from 'react';
import { block } from 'bem-cn';

import { TextInput, TextInputMode } from 'components';

import { isNumberValid } from 'utils/Validators';
import { formatNumber } from 'utils/Helpers';
import { EmployeePayment } from 'types/models/Payment';
import { calcRemainderAmount } from 'features/Table/specifications/GetRequestFundList/helpers';

import './style.scss';

const b = block('request-fund-base-info');

type Props = {
  employeePayment: EmployeePayment;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  disabled: boolean;
};

type InfoProps = {
  label: string;
  value?: string | number;
  children?: JSX.Element;
};

function Info({ label, value, children }: InfoProps) {
  return (
    <div className={b('info')}>
      <div className={b('label')}>{label}</div>
      <div className={b('value')}>{children || value}</div>
    </div>
  );
}

const mapMonths: Record<string, string> = {
  '1': 'Январь',
  '2': 'Февраль',
  '3': 'Март',
  '4': 'Апрель',
  '5': 'Май',
  '6': 'Июнь',
  '7': 'Июль',
  '8': 'Август',
  '9': 'Сентябрь',
  '10': 'Октябрь',
  '11': 'Ноябрь',
  '12': 'Декабрь',
};

export function BaseInfo(props: Props) {
  const { employeePayment, setEmployeePayment, disabled } = props;

  const onChangeAmount = useCallback(
    (amount: string) => {
      if (isNumberValid(amount)) {
        setEmployeePayment({ ...employeePayment!, amountMoney: Number(amount) });
      }
    },
    [employeePayment, setEmployeePayment],
  );

  return (
    <div className={b()}>
      <div className={b('line')}>
        <Info label="Основание:" value={employeePayment?.fund.type.label ?? ''} />
      </div>
      <div className={b('line')}>
        <Info label="Месяц:" value={mapMonths[employeePayment.fund.month]} />
        <Info label="Квартал:" value={employeePayment.fund.quarter.label} />
        <Info label="Год:" value={employeePayment.fund.year} />
      </div>
      <div className={b('line')}>
        <Info label="Сумма, руб:">
          <TextInput
            mode={TextInputMode.number}
            value={String(employeePayment.amountMoney)}
            onChange={onChangeAmount}
            settings={{ decimal: false }}
            isDisabled={disabled}
          />
        </Info>
        <Info label="Из них не распределено, руб:" value={formatNumber(calcRemainderAmount(employeePayment), 0)} />
      </div>
    </div>
  );
}
