import { createContext, useContext, Context } from 'react';

import { Specification } from 'types/models/Table';

export type GridContextType<CustomState extends {} = {}, RequestData extends {} = {}> = {
  specification: Specification<CustomState, RequestData>;
  hasPersonalMode?: boolean;
};

export const GridContext = createContext<any>({});

export const useGridContext = <CustomState extends {} = {}, RequestData extends {} = {}>() => {
  return useContext(GridContext as Context<GridContextType<CustomState, RequestData>>);
};
