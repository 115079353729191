import React from 'react';

import { FormComponent, Select, TextGridSelect, Tabs, Tab, TextArea, TextDateTime } from 'components';

import { Documents } from 'features/Documents';
import { GetDissertationCouncilList } from 'features/Table/specifications';
import { renderEntry } from 'components/Select/helpers';
import { Item } from 'types/models/common';
import { SelectPerson, Member } from 'features/SelectPerson';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { CommunityType } from 'utils/Enums';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

// eslint-disable-next-line
function CommunityForm({ viewMode, editMode, onClose }: Props) {
  const {
    communityMember,
    handleFormSubmit,
    roleOptions,
    cooperationTypeOptions,
    handleCooperationTypeFieldChange,
    handleDescriptionFieldChange,
    handleJoinDateFieldChange,
    handleLeaveDateFieldChange,
    handlePersonFieldChange,
    handleRoleFieldChange,
    // handleVakSpecialityFieldChange,
    communityRowConverter,
    handleCommunityFieldChange,
    handleDocumentsFieldChange,
    isScientificCommunity,
    workMode,
    settings,
    allowedCommunityTypes,
    memberEditLocked,
    communityTypeLabel,
    preferredCommunityType,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const member: Member = {
    person: communityMember.scientist || null,
    job: null,
    education: null,
    citizenship: null,
    degree: communityMember.degree || null,
    rank: communityMember.rank || null,
    academicRank: communityMember.academicRank || null,
  };

  const isDissertationCouncil = preferredCommunityType === CommunityType.DISSERTATION_COUNCIL;

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            <FormComponent.Wrapper>
              <FormComponent.Line>
                <FormComponent.Field label={communityTypeLabel} isRequired>
                  <TextGridSelect
                    specification={GetDissertationCouncilList({
                      communityTypes: allowedCommunityTypes,
                      hasSelectButton: true,
                      isMembersTabHidden: true,
                      enumFilterValues: {
                        enumName: 'CommunityType',
                        excludeValues: [],
                      },
                    })}
                    title={communityTypeLabel}
                    value={communityMember.community || null}
                    valueFormatter={x => [x?.type?.label ?? '-', x?.code ?? '-', x?.magazine?.name ?? ''].join(' ')}
                    onChange={handleCommunityFieldChange}
                    settings={{
                      mode: 'selectTableRow',
                      tableRowConverter: x => communityRowConverter(x),
                    }}
                    disabled={workMode === 'viewMode'}
                    isTextarea={true}
                    rowsMin={2}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Организация">
                  <div>
                    {communityMember.community?.isLocal
                      ? settings?.organization?.shortName
                      : communityMember.community?.affiliation?.partner?.id
                      ? [
                          communityMember.community.affiliation.partner.fullName,
                          communityMember.community.affiliation.partner.country?.label,
                          communityMember.community.affiliation.partner.city,
                        ]
                          .filter(x => x)
                          .join(', ')
                      : communityMember.community?.affiliation?.enterprise?.id
                      ? [
                          communityMember.community.affiliation.enterprise.label,
                          communityMember.community.affiliation.enterprise.customFields?.country,
                          communityMember.community.affiliation.enterprise.customFields?.city,
                        ]
                          .filter(x => x)
                          .join(', ')
                      : communityMember.community?.affiliation?.enterpriseCustomer?.id
                      ? [
                          communityMember.community.affiliation.enterpriseCustomer.label,
                          communityMember.community.affiliation.enterpriseCustomer.customFields?.country,
                          communityMember.community.affiliation.enterpriseCustomer.customFields?.city,
                        ]
                          .filter(x => x)
                          .join(', ')
                      : '-'}
                  </div>
                </FormComponent.Field>
              </FormComponent.Line>
              {isScientificCommunity && (
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Предмет сотрудничества" isRequired>
                    <Select.Component<Item>
                      value={communityMember.cooperationType || { label: '', value: '' }}
                      title={communityMember.cooperationType?.label || ''}
                      variant="outlined"
                      defaultPlaceholder="Не задан"
                      entries={cooperationTypeOptions}
                      onChange={handleCooperationTypeFieldChange}
                      renderEntry={x => renderEntry(x.label || '')}
                      renderValue={x => x.label || ''}
                      disabled={workMode === 'viewMode'}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
              <FormComponent.Line>
                <FormComponent.Field label="Персона" isRequired>
                  <SelectPerson
                    member={member}
                    onUpdateMember={handlePersonFieldChange}
                    isRequired
                    withHistory={true}
                    memberLabel={[member.person?.fullName, member.degree?.refDegree?.label, member.rank?.refRank?.label]
                      .filter(x => x)
                      .join(', ')}
                    disabled={workMode === 'viewMode' || memberEditLocked}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="Роль" isRequired>
                  <Select.Component<Item>
                    value={communityMember.role || { label: '', value: '' }}
                    title={communityMember.role?.label || ''}
                    variant="outlined"
                    defaultPlaceholder="Не задан"
                    entries={roleOptions}
                    onChange={handleRoleFieldChange}
                    renderEntry={x => renderEntry(x.label || '')}
                    renderValue={x => x.label || ''}
                    disabled={!communityMember.community || workMode === 'viewMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label="Дата ввода в состав" isRequired>
                  <TextDateTime
                    value={communityMember.joinDate}
                    onChange={handleJoinDateFieldChange}
                    isDisabled={workMode === 'viewMode'}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Дата вывода из состава">
                  <TextDateTime
                    value={communityMember.leaveDate}
                    onChange={handleLeaveDateFieldChange}
                    isDisabled={workMode === 'viewMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              {/* Скрыто в рамках 607
              {!isScientificCommunity && (
                  <FormComponent.Line>
                    <FormComponent.Field label="Специальность">
                      <Reference
                        value={communityMember.vakSpeciality || null}
                        theme="big"
                        relationTableModalTitle="Специальность ВАК"
                        name="RefVakSpeciality"
                        onChange={handleVakSpecialityFieldChange}
                        filters={[
                          { key: 'id', values: communityMember.community?.vaks.map(x => x.element.id).filter(x => x) || [] },
                        ]}
                        disabled={workMode === 'viewMode'}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                )} */}
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field
                  label="Краткое описание"
                  tooltip="Укажите специальность участника (по какому направлению специализируется участник)"
                >
                  <TextArea
                    settings={{ rows: 3 }}
                    value={communityMember.description ?? ''}
                    onChange={handleDescriptionFieldChange}
                    isDisabled={workMode === 'viewMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Wrapper>
          </Tab>
          <Tab title="Документы">
            <FormComponent.Description mode="warning">{`Прикрепите документ, подтверждающий участие в ${
              isDissertationCouncil ? 'диссертационном совете' : 'работе сообщества'
            }: скан-копию приказа об основании ${
              isDissertationCouncil ? 'совета' : 'сообщества'
            } или скриншот сайта`}</FormComponent.Description>

            <Documents
              documents={communityMember.documents || []}
              setDocuments={handleDocumentsFieldChange}
              disabled={workMode === 'viewMode'}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(CommunityForm);
