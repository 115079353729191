import { SecurityDocument } from 'types/models';
import { FormComponent } from 'components';
import React from 'react';

import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { ClassifierEdit } from './ClassifierEdit/ClassifierEdit';

type Props = {
  patent?: SecurityDocument | null;
  makeChangeHandler: (key: keyof SecurityDocument) => (val: ValueOf<SecurityDocument>) => void;
  disabled?: boolean;
};

export function Classifiers({ patent, makeChangeHandler, disabled }: Props) {
  const { settings } = useAppDataContext();
  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <ClassifierEdit
            title="Коды международной классификации отраслей наук (OECD)"
            refName="RefOecd"
            rows={patent?.oecds ?? []}
            onChange={makeChangeHandler('oecds')}
            modalTitle='Справочник "Коды международной классификации отраслей наук (OECD)"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Сквозные технологии Национальной технической инициативы"
            refName="RefNtiEteTechnology"
            rows={patent?.ntiEteTechnologies ?? []}
            onChange={makeChangeHandler('ntiEteTechnologies')}
            modalTitle='Справочник "Сквозные технологий Национальной технологической инициативы"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="ГРНТИ"
            refName="RefGrnti"
            rows={patent?.grntis ?? []}
            onChange={makeChangeHandler('grntis')}
            modalTitle='Справочник "ГРНТИ"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="УДК"
            refName="RefUdk"
            rows={patent?.udks ?? []}
            onChange={makeChangeHandler('udks')}
            modalTitle='Справочник "УДК"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="ОКВЭД"
            refName="RefOkved"
            rows={patent?.okveds ?? []}
            onChange={makeChangeHandler('okveds')}
            modalTitle='Справочник "ОКВЭД"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="ЛКСЭЦ"
            refName="RefLksets"
            rows={patent?.lksetss ?? []}
            onChange={makeChangeHandler('lksetss')}
            modalTitle='Справочник "ЛКСЭЦ"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Отрасли наук"
            refName="RefScienceBrunch"
            rows={patent?.scienceBrunches ?? []}
            onChange={makeChangeHandler('scienceBrunches')}
            modalTitle='Справочник "Отрасли науки"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Области научных интересов"
            refName="RefScienceDomainInterest"
            rows={patent?.scienceDomainInterrests ?? []}
            onChange={makeChangeHandler('scienceDomainInterrests')}
            modalTitle='Справочник "Области научных интересов"'
            disabled={disabled}
          />
        </FormComponent.Column>
        <FormComponent.Column>
          <ClassifierEdit
            title="Рынки национальной технологической инициативы"
            refName="RefNtiMarket"
            rows={patent?.ntiMarkets ?? []}
            onChange={makeChangeHandler('ntiMarkets')}
            modalTitle='Справочник "Рынки Национальной технологической инициативы"'
            disabled={disabled}
          />
          <ClassifierEdit
            title={`Приоритетные направления ${settings?.organization?.shortName}`}
            refName="RefPnr"
            rows={patent?.pnrs ?? []}
            onChange={makeChangeHandler('pnrs')}
            modalTitle={`Справочник "ПНР ${settings?.organization?.shortName}"`}
            disabled={disabled}
          />
          <ClassifierEdit
            title="Приоритетные направления развития модернизации и технического развития экономики России"
            refName="RefPnmitr"
            rows={patent?.pnmitrs ?? []}
            onChange={makeChangeHandler('pnmitrs')}
            modalTitle='Справочник "Приоритетные направления развития модернизации и технического развития экономики России"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Приоритетные направления развития науки, технологий и техники"
            refName="RefPni"
            rows={patent?.pnis ?? []}
            onChange={makeChangeHandler('pnis')}
            modalTitle='Справочник "Приоритетные нпправления науки, технологий и техники"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Cтратегии научно-технического развития РФ"
            refName="RefNtrStrategy"
            rows={patent?.ntrStrategies ?? []}
            onChange={makeChangeHandler('ntrStrategies')}
            modalTitle='Справочник "Стратегии научно-технического развития РФ"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Технологические платформы"
            refName="RefTechnologyPlatform"
            rows={patent?.technologyPlatforms ?? []}
            onChange={makeChangeHandler('technologyPlatforms')}
            modalTitle='Справочник "Технологические платформы"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Критические технологии"
            refName="RefPriorityTechnology"
            rows={patent?.priorityTechnologies ?? []}
            onChange={makeChangeHandler('priorityTechnologies')}
            modalTitle='Справочник "Критические технологии"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Области знаний"
            refName="RefDomainKnowledge"
            rows={patent?.domainKnowledges ?? []}
            onChange={makeChangeHandler('domainKnowledges')}
            modalTitle='Справочник "Области знаний"'
            disabled={disabled}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}
