import React from 'react';

import { FormComponent, ReferenceItem, TextDateTime, TextInput, TextInputMode } from 'components';

import { Table as T } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { Field, FieldValue } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/types';
import { ReferenceField } from './ReferenceField/ReferenceField';
import { SelectField } from './SelectField/SelectField';

type Props = {
  fields: Record<string, Field>;
  enumMap: T.EnumMap;
  citationSystems: CitationSystem[];
  citationSystem: ReferenceItem | null;
  onChangeField(fieldId: string, value: FieldValue): void;
};

function Fields(props: Props) {
  const { fields, citationSystem, citationSystems, onChangeField, enumMap } = props;

  return (
    <FormComponent.Wrapper>
      {Object.values(fields).map((field, index) => {
        // BY CODES
        if (field.code === 'PROCENTILE') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.number}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
                settings={{ max: 100 }}
              />
            </FormComponent.Field>
          );
        }

        if (field.code === 'YEAR') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.year}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
              />
            </FormComponent.Field>
          );
        }

        // BY TYPES
        if (field.type === 'DATE') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextDateTime
                key={field.valueId}
                value={field.value as string}
                onChange={date => onChangeField(field.valueId, (date as string) ?? '')}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'REFERENCE' && field.metaType) {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <ReferenceField
                key={field.valueId}
                field={field}
                onChangeField={onChangeField}
                citationSystems={citationSystems}
                citationSystem={citationSystem}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'ENUMERATION' && field.metaType) {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <SelectField key={field.valueId} field={field} onChangeField={onChangeField} enumMap={enumMap} />
            </FormComponent.Field>
          );
        }

        if (field.code === 'URL') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.url}
                value={field.value.toString()}
                onChange={nextValue => onChangeField(field.valueId, nextValue ?? '')}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'FLOAT') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.number}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
                settings={{
                  isThousands: false,
                  decimal: { limit: 3 },
                }}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'INTEGER') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.number}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
                settings={{
                  isThousands: false,
                  decimal: false,
                }}
              />
            </FormComponent.Field>
          );
        }

        return (
          <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
            <TextInput
              key={field.valueId}
              value={field.value as string}
              onChange={nextValue => onChangeField(field.valueId, nextValue)}
            />
          </FormComponent.Field>
        );
      })}
    </FormComponent.Wrapper>
  );
}

export { Fields };
