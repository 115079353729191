import React from 'react';
import * as R from 'ramda';

import { ListEdit } from 'components';

import { Project } from 'types/models';
import { showErrors } from 'utils/Validators';
import { formatNumber } from 'utils/Helpers';
import { getMockProjectPartner } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields/Fields';
import { validate } from './validate';

type Props = {
  disabled: boolean;
  partners: Project.Partner[];
  setPartners(partners: Project.Partner[]): void;
};

function formatAddress(x: Project.Partner) {
  const { partner } = x;

  const arrStrings = [partner?.country?.label ?? '', partner?.district ?? '', partner?.city ?? '', partner?.street ?? ''];

  return arrStrings.filter(Boolean).join(', ');
}

export function Partners(props: Props) {
  const { disabled, partners, setPartners } = props;

  return (
    <ListEdit
      title="Партнёры по проекту"
      withMessages
      isDeleteConfirmEnabled
      isToolbarDisabled={disabled}
      rows={partners}
      onChange={setPartners}
      isFullScreenedTable
      maxHeight="none"
      columns={[
        { label: 'Роль', formatValue: x => x.role?.label ?? '' },
        { label: 'Название', formatValue: x => x.partner?.fullName ?? '' },
        {
          label: 'Договор',
          formatValue: x => `${x.contractType?.label ?? ''} №${x.contractNumber} c (${x.startDate} по ${x.endDate})`,
        },
        {
          label: 'Общий объем',
          formatValue: x => formatNumber(x.financings.reduce((sum, { amount }) => sum + Number(amount), 0)),
        },
        { label: 'Адрес', formatValue: formatAddress },
        { label: 'Тип организации', formatValue: x => x.role?.label ?? '' },
      ]}
      specification={{
        mode: 'customModalComponent',
        modalTitle: 'Партнёры по проекту',
        isCheckSubmitIcon: false,
        renderComponent: (partner, setPartner, index, mode) => (
          <Fields mode={mode} partner={partner || getMockProjectPartner()} setPartner={setPartner} />
        ),
        validation: {
          checkIsValid: partner => validate(partner).every(x => x.isValid),
          onInvalidate: R.partial(showErrors, [validate]),
        },
      }}
    />
  );
}
