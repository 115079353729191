import { IconButtonProps } from 'components';

import { Entry, Specification } from 'types/models/Table';
import { SortType } from './ListEditTable/ListEditTable';

export enum DataKind {
  STRING = 'string',
  DATE = 'date',
  FLOAT = 'float',
  INT = 'int',
  BOOLEAN = 'boolean',
  RANGE = 'range',
}

export type Column<Row> = {
  styles?: React.CSSProperties;
  label: string;
  formatValue(row: Row, rowIndex: number): string | number | boolean;
  dataKind?: DataKind;
  defaultSort?: SortType;
  tooltip?: string;
};

export type Mode = 'view' | 'add' | 'edit' | null;

export type ValidateProps<Row> = {
  checkIsValid(row: Row | null, index: number | null, list: Row[], mode: Mode): boolean;
  onInvalidate?: (row: Row | null, mode: Mode, index: number | null, list: Row[]) => void;
};

export type ValidateLoadInstanceTableModalSpecificationProps<Row> = {
  checkIsValid(selectedRows: Array<Entry> | null, selectedRowIndex: number | null, list: Row[], mode: Mode): boolean;
  onInvalidate?: (selectedRows: Array<Entry> | null, selectedRowIndex: number | null, list: Row[], mode: Mode) => void;
};

export type CustomComponentSpecification<Row> = {
  mode: 'customComponent';
  validation?: ValidateProps<Row>;
  renderComponent: RenderComponent<Row>;
  onPreSubmit?(row: Row, submit: (updatedRow: Row) => void, selectedRowIndex: number | null, mode: Mode): void;
  onPreEdit?(row: Row | null, continueEdit: () => void): void;
  onPreDelete?(row: Row | null, continueEdit: () => void, selectedRowIndex: number | null): void;
};

export type CustomModalComponentSpecification<Row> = {
  modalTitle: string;
  modalEditTitle?: string;
  isToolbarHidden?: boolean;
  isCheckSubmitIcon?: boolean;
  mode: 'customModalComponent';
  validation?: ValidateProps<Row>;
  isFullScreenModal?: boolean;
  renderComponent: RenderComponent<Row>;
  onPreEdit?(row: Row | null, continueEdit: () => void): void;
  onPreDelete?(row: Row | null, continueEdit: () => void): void;
  onPreSubmit?(row: Row, submit: (updatedRow: Row) => void, selectedRowIndex: number | null, mode: Mode): void;
};

export type RenderComponent<Row> = (
  row: Row | null,
  onChange: React.Dispatch<React.SetStateAction<Row | null>>,
  index: number | null,
  mode: Mode,
  submitCustomComponent?: () => void,
) => JSX.Element;

export type RelationTableModalSpecification<Row, TableState extends {} = {}> = {
  relationTableModalTitle: string;
  modalTableSpecification: Specification<TableState>;
  modalTableRowConverter(modalTableRow: Entry): Row;
  onPreEdit?(row: Row[], submit: (updatedRows: Row[]) => void): void;
  onPreSubmit?(row: Row[], submit: (updatedRows: Row[]) => void): void;
  mode: 'relationTableModal';
};

export type LoadInstanceTableModalSpecification<Row, TableState extends {} = {}> = {
  relationTableModalTitle: string;
  modalTableSpecification: Specification<TableState>;
  validation?: ValidateLoadInstanceTableModalSpecificationProps<Row>;
  onStartLoad(tableRows: Entry[], onSuccessLoad: (loadedRows: Row[]) => void): void;
  mode: 'loadInstanceTableModal';
};

export type ModeSpecification<Row, State extends {} = {}> =
  | RelationTableModalSpecification<Row, State>
  | CustomComponentSpecification<Row>
  | CustomModalComponentSpecification<Row>
  | LoadInstanceTableModalSpecification<Row, State>;

export type ExtraToolbarButton<Row> = Omit<IconButtonProps, 'onClick' | 'isDisabled'> & {
  isBeforeDefaultButtons?: boolean;
  checkIsDisabled?(row: Row | null): boolean;
  onClick: ExtraToolbarClickHandler<Row>;
};
export type ExtraToolbarClickHandler<Row> = (row: Row | null, selectedRowIndex: number | null, rows: Row[]) => void;

export type DefaultToolbarButton = 'add' | 'edit' | 'delete' | 'dublicate' | 'view';
