import React, { useCallback } from 'react';

import { Reference, ReferenceItem, FormComponent } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getMockRefElement } from 'features/Form/looks/project/ProjectForm/helpers';

import './style.scss';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};

export function ScienceSection(props: Props) {
  const { disabled, project, makeChangeHandler } = props;

  const renderRefHint = (ref: ReferenceItem | null | undefined, fieldName: string) =>
    ref?.id ? (
      <div className="description-science-section-hint">{(ref?.customFields?.[fieldName] as ReferenceItem)?.label}</div>
    ) : null;

  const handleChangeLkset = useCallback(
    (lkset: ReferenceItem | null) => {
      let updatedLksets: Array<Project.RefElement> = [];
      if (lkset) {
        updatedLksets = [{ ...getMockRefElement(), ref: lkset }];
      }
      makeChangeHandler('lksetss')(updatedLksets);
    },
    [makeChangeHandler],
  );

  const handleChangeScienceBrunch = useCallback(
    (scienceBrunch: ReferenceItem | null) => {
      let updatedScienceBrunches: Array<Project.RefElement> = [];
      if (scienceBrunch) {
        updatedScienceBrunches = [{ ...getMockRefElement(), ref: scienceBrunch }];
      }
      makeChangeHandler('scienceBrunches')(updatedScienceBrunches);
    },
    [makeChangeHandler],
  );

  const lksets = getFirstRefElement(project?.lksetss ?? []);
  const scienceBrunch = getFirstRefElement(project?.scienceBrunches ?? []);

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="ОКВЭД">
          <Reference
            disabled={disabled}
            name="RefOkved2"
            onChange={makeChangeHandler('okved')}
            value={project?.okved ?? null}
            relationTableModalTitle="ОКВЭД"
            fieldVisibleMode="SYSTEM"
          />
          {renderRefHint(project?.okved, 'parent')}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="ЛКСЭЦ">
          <Reference
            disabled={disabled}
            name="RefLksets"
            onChange={handleChangeLkset}
            value={lksets}
            relationTableModalTitle="Классификатор социально-экономических целей"
            fieldVisibleMode="SYSTEM"
          />
          {renderRefHint(lksets, 'parent')}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Отрасль науки">
          <Reference
            disabled={disabled}
            name="RefScienceBrunch"
            onChange={handleChangeScienceBrunch}
            value={scienceBrunch}
            relationTableModalTitle='Справочник "Отрасли науки"'
            fieldVisibleMode="SYSTEM"
          />
          {renderRefHint(scienceBrunch, 'study')}
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

function getFirstRefElement(refs: Project.RefElement[]) {
  const [element] = refs;
  return element?.ref ?? null;
}
