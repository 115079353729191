export enum ContestRequestStatus {
  DRAFT = 'DRAFT',
  REQUEST = 'REQUEST',
  ACTUALIZATION = 'ACTUALIZATION',
  REVISION = 'REVISION',
  REJECTED = 'REJECTED',
  AGREED = 'AGREED',
  AGREED_WITH_CONDITION = 'AGREED_WITH_CONDITION',
  SUPPORTED = 'SUPPORTED',
}
