import React from 'react';
import * as modalTemplates from './modalTemplates';

import { buttonIcons, ConfirmPopup, IconButton, Modal, TextArea, Toolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { DataGrid } from 'features/Table';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { TenderRequestType } from 'utils/Enums';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    buttons,
    currentReport,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isReportOpen,
    isViewFormOpen,
    mobileRequestId,
    onReportClose,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsViewFormOpen,
    handleTenderSelect,
    isTenderModalOpen,
    setIsTenderModalOpen,
    tenderInfo,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
  } = useController(tableState);
  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.MobileRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          type={customState.type}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.MobileRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          type={customState.type}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.MobileRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          type={customState.type}
          tenderInfo={tenderInfo}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную заявку? (${mobileRequestId})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          mobileRequestId={tableState.selectedRows[0]?.id}
        />
      )}

      <Modal
        isOpen={isTenderModalOpen}
        onClose={() => setIsTenderModalOpen(false)}
        title="Список конкурсов"
        size={['extralarge', 'large-height']}
      >
        <DataGrid
          specification={GetTenderListForRequest({
            tenderRequestType: TenderRequestType.MOBILE_REQUEST,
            selectRow: handleTenderSelect,
          })}
        />
      </Modal>

      {isStatusModalOpen && (
        <ConfirmPopup
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          onConfirm={changeStatus}
          okButtonText={statusLabel}
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </ConfirmPopup>
      )}
      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />

      <ConfirmPopup
        title="Помощь в разделе Заявки по академической мобильности"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Раздел содержит заявки на конкурсы из категории: Стажировки, повышение квалификации, Участие в научных мероприятиях.
          </p>
          <p>
            <strong className="primary">ВАЖНО:</strong> Для настройки формы заявки необходимо актуализировать справочники:
          </p>
          <ul>
            <li>Должностные лица, подписывающие документы в заявках по академической мобильности (п.м. Справочники)</li>
            <li>Виды расходов по академической мобильности (п.м. Финансы)</li>
            <li>Виды деятельности (п.м. Справочники)</li>
            <li>Результаты обязательств использования материалов (мобильность) (п.м. Справочники)</li>
          </ul>
          <p>
            <strong className="primary">Статусы заявки:</strong>
          </p>
          <ol>
            <li>
              <strong>«Черновик»:</strong> Автоматически присвоенный статус при создании новой заявки заявителем или ответственным
              за заявки по академической мобильности
            </li>
            <li>
              <strong>«Заявка»:</strong> Статус присваивается при отправке заявки на проверку с помощью кнопки&nbsp;
              <strong>На согласование</strong> <IconButton icons={buttonIcons.like} hasNoHover />. В этом статусе консультанты и
              члены конкурсной комиссии проверяют заявку на соответствие условиям конкурса. Члены комиссии имеют возможность
              добавить резолюцию (Согласовано/Отклонить/На доработку) с помощью кнопки <strong>Экспертная оценка</strong>&nbsp;
              <IconButton icons={buttonIcons.approve} hasNoHover />. Если резолюция – На доработку, то обязательно необходимо
              описать что и в каком разделе рекомендуется доработать.
            </li>
            <li>
              <ol>
                <li>
                  <strong>«Рекомендована на конкурс»:</strong> Если по итогам проверки резолюция «Согласовано», то ответственный
                  за заявки меняет статус с помощью кнопки&nbsp;
                  <strong>
                    Рекомендовать на конкурс <IconButton icons={buttonIcons.star} hasNoHover />
                  </strong>
                </li>
                <li>
                  <strong>«На доработку»:</strong> Если по итогам проверки есть хотя бы одна резолюция «На доработку», то
                  Ответственный за заявки меняет статус с помощью кнопки <strong>На доработку</strong>
                  <IconButton icons={buttonIcons.update} hasNoHover />. Из статуса&nbsp;
                  <strong>«На доработку»</strong>&nbsp;заявка может получить статус «Заявка» с помощью кнопки&nbsp;
                  <strong>На согласование</strong>&nbsp;
                  <IconButton icons={buttonIcons.like} hasNoHover /> и далее продолжить цикл смены статусов.
                </li>
                <li>
                  <strong>«Отклонена»:</strong> Если по итогам проверки резолюция «Отклонить», то ответственный за заявки меняет
                  на статус «Отклонена» с помощью кнопки <strong>Отклонить</strong>&nbsp;
                  <IconButton icons={buttonIcons.dislike} hasNoHover />. Заявка может остаться в статусе&nbsp;
                  <strong>«Отклонена»</strong>&nbsp;окончательно или получить статус «На доработку» с помощью кнопки{' '}
                  <strong>На доработку</strong>&nbsp;
                  <IconButton icons={buttonIcons.update} hasNoHover /> и продолжить смену следующих статусов.
                </li>
              </ol>
              <li>
                <strong>«Подана на конкурс»:</strong> В этот статус заявку переводят с помощью кнопки&nbsp;
                <strong>Подать на конкурс</strong> <IconButton icons={buttonIcons.medal} hasNoHover /> после регистрации заявки на
                сайте конкурса. Для заявки в статусе «Подана на конкурс» необходимо добавить регистрационный номер заявки в поле
                «Шифр заявки».
              </li>
              <li>
                <strong>«Поддержана»:</strong> Статус, который присваивают выигравшей заявке по итогам конкурса, с помощью
                кнопки&nbsp;
                <strong>Поддержать</strong>&nbsp;
                <IconButton icons={buttonIcons.activation} hasNoHover /> (итоговый статус заявки).
              </li>
              <li>
                <strong>«Отклонена в конкурсе»:</strong> Статус, который присваивают «проигравшей» заявке по итогам конкурса, с
                помощью кнопки <strong>Отклонить</strong> <IconButton icons={buttonIcons.dislike} hasNoHover /> (итоговый статус
                заявки).
              </li>
            </li>
          </ol>
          <p>
            <strong className="primary">ВАЖНО:</strong> Форма заявки доступна для редактирования в статусах: «Черновик» или «На
            доработку». В других статусах — форма заявки доступна для просмотра. Удалить заявку можно только в статусе «Черновик».
            Заявки в статусах «Поддержана» и «Отклонена» редактировать нельзя.
          </p>
          <p>
            <strong className="primary">Источники данных в столбцах таблицы «Заявки на мобильность» </strong>
          </p>
          <p>
            <strong>Столбцы о персональных данных заявителя: </strong>
          </p>
          <ul>
            <li>Заявитель – полные ФИО заявителя на вкладке "Заявитель" в поле "ФИО"</li>
            <li>Уч.степень заявителя – уч. Степень из карточки/исторического среза заявителя</li>
            <li>Уч.звание заявителя – уч. Звание из карточки/исторического среза заявителя</li>
            <li>Статус обучающегося – текущий статус из карточки/исторического среза заявителя</li>
            <li>Специальность – код специальности заявителя</li>
            <li>
              Категория участников – данные из поля «Категория участника» на вкладке "Заявитель", заполняется из справочника
              "Категории участников"
            </li>
            <li>Возраст заявителя – система автоматически подставляет возраст от даты рождения заявителя</li>
            <li>Контакты заявителя – данные из поля "Контакты" на вкладке "Заявитель"</li>
            <li>Подразделение – подразделение, которое является основным для учетной записи заявителя в системе</li>
            <li>
              Факультет/Институт – данные из поля «Факультет/Институт» на вкладке "Заявитель", заполняется из справочника
              "Подразделения"
            </li>
            <li>Руководители – ФИО научного руководителя и руководителя структурного подразделения на вкладке «Заявитель»</li>
          </ul>
          <p>
            <strong>Столбцы о целях командировки, сроках, месте назначения и принимающей стороне:</strong>
          </p>
          <ul>
            <li>
              Цель командировки – в поле «Цель командировки» выбранная цель: Стажировка или Участие в мероприятии на вкладке
              «Заявка». От выбора цели командировки зависят поля формы заполнения данных.
            </li>
            <li>Тема – тема стажировки или мероприятия из поля "Тема" вкладки "Заявка"</li>
            <li>
              Вид деятельности – данные поля "Вид деятельности" вкладки "Заявка", выбранные из справочника "Вид деятельности"
            </li>
            <li>
              Направление деятельности – данные поля "Направление деятельности" вкладки "Заявка", выбранные из справочника
              "Должностные лица, подписывающие документы в заявках по академической мобильности". Направление и в скобках ФИО
              куратора.
            </li>
            <li>ПНР – приоритетные направления развития университета</li>
            <li>
              Описание результатов – Таблица "Описание планируемых результатов по итогам поездки" вкладки "Заявка", выбор из
              справочника "Результаты обязательств использования материалов", в скобках сроки исполнения
            </li>
            <li>
              Описание результатов (примечание) – заполненные вручную данные поля «Описания результатов» таблицы Таблица "Описание
              планируемых результатов по итогам поездки" на вкладке "Заявка"
            </li>
          </ul>
          <p>
            <strong>Столбцы о сроках, месте назначения и принимающей стороне:</strong>
          </p>
          <ul>
            <li>Дата вылета – дата поля "Период командировки" на вкладке «Заявка»</li>
            <li>Дата прилета – дата поля "Период командировки" на вкладке «Заявка»</li>
            <li>Сроки стажировки/мероприятия – даты поля "Сроки мероприятия" на вкладке «Заявка»</li>
            <li>
              Дней – Автоматический подсчет кол-ва дней из данных поля "Период командировки" и в скобках из поля "Сроки
              мероприятия"
            </li>
            <li>Место назначения (Город) – данные таблицы "Место проведения мероприятия" на вкладке "Заявка" </li>
            <li>
              Место назначения (Страна) – данные таблицы "Место проведения мероприятия" на вкладке "Заявка" из справочника
              «Страны»
            </li>
            <li>
              Место назначения (Принимающая организация) – данные таблицы "Место проведения мероприятия" на вкладке "Заявка" из
              справочника «Организации»
            </li>
            <li>
              Руководитель принимающей стороны - ФИО руководителя и его характеристика из соответствующих полей вкладки «Заявка»
              (поля есть на форме заполнения только при выборе в «Цель командировки»: стажировка)
            </li>
          </ul>
          <p>
            <strong>Столбцы для мониторинга результатов научной деятельности по итогам командировки:</strong>
          </p>
          <p>О публикациях</p>
          <ul>
            <li>
              Публикации – библиографическая ссылка публикации, у которой на вкладке «Финансовая поддержка» указана связь с
              заявкой
            </li>
            <li>
              Аффилированы – аффилированные публикации, у которой хотя бы для одного автора указана аффилиация с университетом
            </li>
            <li>Импакт-фактор – показатель издания публикации</li>
          </ul>
          <div>Об участии в мероприятии:</div>
          <ul>
            <li>
              Участие в конференции – запись об участии в конференции (п.м. Мероприятия), у которой на вкладке «Связи с проектами»
              указана заявка
            </li>
            <li>Формат участия – Формат участия в конференции</li>
            <li>Доклад – Название доклада на форме Участие в конференции</li>
            <li>
              Награды за участие – Награды, которые были получены по итогам командировки и указаны на вкладке «Награды» в форме
              Участие в конференции
            </li>
          </ul>
          <p>
            <strong>Столбцы для анализа финансирования в разрезе источников:</strong>
          </p>
          <ul>
            <li>Всего – Общая сумма в смете заявки, руб.</li>
            <li>
              Средства Программы поддержки академической мобильности - Сумма из типа источника "Средства академической
              мобильности" вкладки "Смета", руб.
            </li>
            <li>
              Средства проекта – Сумма из типа источника "Софинансирование из средств научного проекта" вкладки "Смета", руб.
            </li>
            <li>
              Средства подразделения – Сумма из типа источника "Софинансирование из средств подразделения" вкладки "Смета", руб.
            </li>
            <li>
              Др.источники – Сумма из типа источника "Софинансирование принимающей стороны или личные средства" вкладки "Смета",
              руб.
            </li>
            <li>
              Проект – краткая информация о проекте, указанного на вкладке "Смета" в типе источника "Софинансирование из средств
              научного проекта"
            </li>
          </ul>
          <p>
            <strong>
              Столбцы для отображения данных конкурса – информация на вкладке «О конкурсе», автоматически добавленная из Конкурса,
              на основании которого была создана заявка:
            </strong>
          </p>
          <ul>
            <li>Конкурс – название конкурса на основании которого создана заявка</li>
            <li>Программа – название программы у которой указана связь с конкурсом</li>
            <li>Мероприятие – данные поля "Мероприятие программы" из Программы, которая связана с конкурсом заявки</li>
            <li>Дата публикации – дата из поля "Дата объявления" конкурса на мобильность</li>
            <li>Подать до – дата из поля "Подать заявку на согласование консультанту до" в конкурсе</li>
            <li>Консультанты – консультанты по заявке, указанные в Конкурсе на мобильность в таблице "Консультанты по заявке"</li>
            <li>Дата окончания приема – дата из поля "Дата окончания приема заявок" конкурса</li>
            <li>Дата рассмотрения – дата из поля "Дата рассмотрения заявок" конкурса</li>
            <li>Дата подведения итогов – дата из поля "Дата подведения итогов" конкурса</li>
            <li>Категория конкурса – данные из справочника "Категории конкурса", добавленные в одноименную таблицу конкурса</li>
            <li>Участники конкурса – данные из справочника "Категории конкурса", добавленные в одноименную таблицу конкурса</li>
          </ul>
          <p>
            <strong>Столбцы для аналитических данных заявки:</strong>
          </p>
          <ul>
            <li>ID Заявки – Индивидуальный номер, который система присваивает заявке</li>
            <li>Статус – текущий статус заявки </li>
            <li>Подана на конкурс – дата подачи на конкурс из поля "Подана на конкурс" на вкладке "Заявитель"</li>
            <li>
              Шифр заявки – данные поля «Шифр заявки» на вкладке «Заявка», которые присваивает заявке ответственное лицо для учета
              заявки в системе внутренних шифров университета
            </li>
            <li>Дата создания – дата и время создания заявки</li>
            <li>Замечания эксперта – сообщение при добавлении заявке статусов "На доработку" или "Отклонить" </li>
            <li>Сообщение – сообщение при добавлении заявке статуса "Согласование"</li>
            <li>Согласование – ФИО ответственного, который присвоил заявке статус: согласование</li>
            <li>Создано – ФИО персоны создавшей заявку</li>
            <li>Изменено – ФИО персоны вносившей изменения в заявке</li>
            <li>Дата изменения – дата и время последнего изменения/редактирования</li>
          </ul>
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
