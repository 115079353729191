import React from 'react';

import { buttonIcons, ConfirmPopup, ListEdit } from 'components';

import { Program, Project } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { Fields } from './Fields/Fields';
import { validate } from './validate';
import { AddIndicatorsModal } from './AddIndicatorsModal/AddIndicatorsModal';
import { SelectStageModal } from './SelectStageModal/SelectStageModal';
import { useController } from './controller';
import { getMockEditableIndicator } from 'features/Form/looks/project/ProjectForm/helpers';
import { IndicatorKey, EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';

type Props = {
  title: string;
  stages: Project.Stage[];
  disabled: boolean;
  setStages(stages: Project.Stage[]): void;
  program: Program.Program | null;
  type: IndicatorKey;
  indices: EditableIndicator[];
};

const Component: React.FC<Props> = (props: Props) => {
  const { title, stages, disabled, type, program, indices, setStages } = props;

  const {
    handleChangeIndicators,
    handleCopyIndicatorsFromProgram,
    handleSubmitAddIndicatorsModal,
    isOpenAddIndicatorsModal,
    isOpenSelectStageModal,
    toggleAddIndicatorsModal,
    contestRequest,
    setIsOpenSelectStageModal,
    isHelpFormOpen,
    setIsHelpFormOpen,
  } = useController({ setStages, stages, type, program });

  return (
    <>
      <ListEdit
        title={title}
        withMessages
        isDeleteConfirmEnabled
        isToolbarDisabled={disabled}
        rows={indices}
        onChange={handleChangeIndicators}
        maxHeight="500px"
        isVisibleFilters
        visibleToolbarButtons={['add', 'edit', 'delete', 'dublicate']}
        extraToolbarButtons={[
          {
            icons: buttonIcons.attach,
            title: 'Добавить из программы, указанной в проекте',
            checkIsDisabled: () => disabled,
            onClick: toggleAddIndicatorsModal,
          },
          {
            icons: buttonIcons.toolbarHelp,
            title: 'Помощь',
            code: 'help',
            onClick: () => setIsHelpFormOpen(true),
          },
        ]}
        defaultRowsCount={10}
        columns={[
          { label: 'Год (этап)', formatValue: x => `${x.year} (этап ${x.stageNumber})`, styles: { minWidth: '110px' } },
          {
            label: type === 'indicators' ? 'Индикатор' : 'Показатель',
            formatValue: x => x.ref?.label ?? '',
            styles: { width: '50%' },
          },
          { label: 'Код', formatValue: x => (x.ref?.customFields?.code as string) ?? '', styles: { width: '5%' } },
          {
            label: 'План',
            formatValue: x =>
              x.plan
                ? `${(x.ref?.customFields?.units ?? '').toString().indexOf('чел') > -1 ? parseInt(x.plan) : x.plan} ${
                    x.ref?.customFields?.units ?? ''
                  }`
                : '',
            styles: { width: '10%' },
          },
          {
            label: 'Факт',
            formatValue: x =>
              x.fact
                ? `${(x.ref?.customFields?.units ?? '').toString().indexOf('чел') > -1 ? parseInt(x.fact) : x.fact} ${
                    x.ref?.customFields?.units ?? ''
                  }`
                : '',
            styles: { width: '10%' },
          },
          { label: 'Примечание', formatValue: x => x.note, styles: { width: '5%' } },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (indicator, setIndicator) => (
            <Fields
              indicator={indicator || getMockEditableIndicator()}
              setIndicator={setIndicator}
              stages={stages}
              type={type}
              programId={program?.id ?? ''}
            />
          ),
          validation: {
            checkIsValid: (indicator, index, list, mode) => validate(indicator, list, mode).every(x => x.isValid),
            onInvalidate: (indicator, mode, index, list) => {
              const validationInfo = validate(indicator, list, mode);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
      <AddIndicatorsModal
        indicators={program?.[type] ?? []}
        isOpen={isOpenAddIndicatorsModal}
        onClose={toggleAddIndicatorsModal}
        stages={stages}
        onSubmit={handleSubmitAddIndicatorsModal}
        program={program}
        type={type}
      />
      <SelectStageModal
        isOpen={isOpenSelectStageModal}
        onClose={() => setIsOpenSelectStageModal(false)}
        stages={stages}
        onSubmit={handleCopyIndicatorsFromProgram}
        contestRequest={contestRequest}
      />
      <ConfirmPopup
        title="Индикаторы эффективности и показатели результативности"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Вкладка предназначена для мониторинга качества и результативности выполнения проекта по количественным
            характеристикам: показателям результативности и индикаторам эффективности.
          </p>
          <p>
            Для этого по каждому этапу проекта необходимо указать список индикаторов и показателей ввести плановые значения, после
            и фактические значения. Иконка <strong>Добавить индикатор (показатель)</strong> открывает форму для добавления
            индикатора (показателя); необходимо указать плановое значение индикатора по этапу. Показатели результативности и
            индикаторы эффективности выполнения проекта выбираются из одноимённых справочников, в которых хранятся коллекции всех
            индикаторов и показателей из разных программ.
          </p>
          <p>
            <strong className="primary">Примечание!</strong> Если в справочнике отсутствует элемент, его необходимо добавить. Опыт
            показывает, что в разных проектах используются очень похожие по названию индикаторы (показатели), пусть вас не
            смущает, что в справочнике со временем накопятся смысловые дубликаты. Попытка привести к общему знаменателю, т.е.
            использовать «универсальные» индикаторы, пагубна, потому что в отчёте по запросу необходимо указывать точное название
            как было заявлено в Техническом задании.
          </p>
          <p>
            Как правило, Министерство образования РФ, периодически запрашивает отчётность по выполненным НИОКР в рамках Программы,
            её мероприятий и направлений. Предметом такой отчётности является перечень показателей и индикаторов Программы.
          </p>
          <p>
            Поэтому, для проектов, которые выполняются в рамках государственной программы, обычно используется набор индикаторов и
            показателей, связанных с Программой. По кнопке <strong>Добавить из программы, связанной с проектом</strong> система
            выполняет процедуру автоматизированного добавления выбранных индикаторов из списка указанного на форме&nbsp;
            <strong>Программы</strong> по всем этапам проекта, плановое значение = 0.
          </p>
          <p>
            <strong className="primary">Важно!</strong> В идеале, фактические значения индикаторов и показателей должны
            подтверждаться списками полученных результатов, указанных на вкладках&nbsp;
            <strong>
              Публикации, Диссертации и авторефераты, Мероприятия и участия в мероприятиях, Интеллектуальная собственность,
              Описание результатов.
            </strong>
          </p>
        </div>
      </ConfirmPopup>
    </>
  );
};

export const EditableIndicators = React.memo(Component) as typeof Component;
