import React from 'react';
import { DataGrid } from 'features/Table';
import { GetProjectDissertationList } from 'features/Table/specifications';

type Props = {
  projectId: string | null;
};
export function ResultProjectDissertations({ projectId }: Props) {
  return (
    <>
      <DataGrid specification={GetProjectDissertationList({ projectId: projectId || '-1' })} />
    </>
  );
}
