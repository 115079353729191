import { Document, DocType } from 'types/models';
import { convertFile } from '.';

export function convertDocument(docType: DocType, document: any): Document {
  const attrs = document._attributes;

  return {
    docType,
    id: attrs.id,
    createdBy: attrs.createdBy,
    createdDate: attrs.createdDate,
    date: attrs.date,
    isShared: attrs.isShared === 'true',
    isCommonAward: attrs.isCommonAward === 'true',
    isInfo: attrs.isInfo !== null ? attrs.isInfo === 'true' : false,
    type: attrs.type,
    name: attrs.name,
    file: convertFile(document.File),
    endDate: document._attributes.endDate || null,
    startDate: document._attributes.startDate || null,
    isAutoProlongation: document._attributes.isAutoProlongation ? document._attributes.isAutoProlongation === 'true' : null,
    isUnexpirable: document._attributes.isUnexpirable ? document._attributes.isUnexpirable === 'true' : null,
    number: document._attributes.number || null,
    partnerDocumentType: document._attributes.partnerDocumentType || null,
    responsible: document._attributes.responsible || null,
    responsibleJob: document._attributes.responsibleJob || null,
    responsibleFio: document._attributes.responsibleFio || null,
    responsibleJobAppointment: document._attributes.responsibleJobAppointment || null,
    projectDocumentType: document._attributes.documentType || null,
    amount: document._attributes.amount || null,
    program: { id: document._attributes.programId, label: document._attributes.programLabel },
  };
}
