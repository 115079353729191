import { useCallback, useState, useLayoutEffect, useMemo } from 'react';
import { Form } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  publicationId: string;
};

const useController = ({ onClose, onSuccess, publicationId }: Props) => {
  const { isProfile } = usePrivatePageContext();
  const { userPermission } = useAppDataContext();
  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublication } = BackendAPI.useBackendAPI('SavePublication');

  const [isLoading, setIsLoading] = useState(false);
  const [publication, setPublication] = useState<Form.Publication | null>(null);
  const [formFields, setFormFields] = useState<Form.Fields>({
    doi: {
      value: '',
      isValid: true,
      title: 'DOI',
      tooltipText: 'Цифровой идентификатор объекта, представленного в Интернете, пример заполнения: 10.1007/b136753',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, isValid: true },
        }));
      },
    },
  });

  const submitForm = useCallback(() => {
    if (publication === null) {
      return;
    }
    setIsLoading(true);
    savePublication.callAPI(
      {
        id: publicationId,
        simpleFields: {
          type: publication.type,
          status: publication.status.status,
          isElectronic: publication.fields.isElectronic === 'true',
          electronicType: publication.fields.electronicType,
          doi: formFields.doi.value,
          name: publication.fields.name,
          year: publication.fields.year,
          month: publication.fields.month,
          day: publication.fields.day,
          tirage: publication.fields.tirage,
          seriesTitle: publication.fields.seriesTitle,
          issn: publication.fields.ISSN,
          isbn: publication.fields.ISBN,
          volume: publication.fields.volume,
          issue: publication.fields.issue,
          printPageCount: publication.fields.printPageCount,
          url: publication.fields.url,
          libraryLink: publication.fields.libraryLink,
          typeEdition: publication.fields.typeEditionCode,
          pageCount: publication.fields.pageCount,
          address: publication.fields.address,
          bibliographicRecord: publication.fields.bibliographicRecord,
          electronicMediaSource: publication.fields.electronicMediaSource,
          electronicSystemRequirements: publication.fields.electronicSystemRequirements,
          udk: publication.fields.udk,
          urlDate: publication.fields.urlDate,
          annotation: publication.fields.annotation,
          authorsKeyWords: publication.fields.authorsKeyWords,
          authorsCount: publication.fields.authorsCount,
          fractionalCount: publication.fields.fractionalCount,
          shortName: publication.fields.shortName,
          eIssn: publication.fields.EISSN,
          startPage: publication.fields.startPage,
          endPage: publication.fields.endPage,
          articleNumber: publication.fields.articleNumber,
          defencePlace: publication.fields.defencePlace,
          eIsbn: publication.fields.EISBN,
          volumeNumber: publication.fields.volumeNumber,
          republishData: publication.fields.republishData,
          defenceDate: publication.fields.defenceDate,
          councilCipher: publication.fields.councilCipher,
          foreignName: publication.fields.foreignName,
          foreignAnnotation: publication.fields.foreignAnnotation,
          foreignAuthorsKeyWords: publication.fields.foreignAuthorsKeyWords,
          foreignAuthors: publication.fields.foreignAuthors,
          isLocal: publication.isLocal,
        },
        attrIdFields: {
          sourceType: publication.fields.sourceTypeId,
          publisher: publication.fields.publisherId,
          categoryEdition: publication.fields.categoryEditionId,
          textLanguage: publication.fields.textLanguageId,
          grnti: publication.fields.grntiId,
          study: publication.fields.studyId,
          original: publication.original?.id,
          compilation: publication.compilation?.id,
          magazine: publication.magazine?.id,
          grif: publication.fields.grifId,
          vakSpeciality: publication.fields.vakSpecialityId,
          degree: publication.fields.degreeId,
          council: publication.council?.id,
          originalLanguage: publication.fields.originalLanguageId,
          monograph: publication.monograph?.id,
          magazineRelease: publication.magazineRelease?.id,
          printArticle: publication.printArticle?.id,
          publicationOther: publication.publicationOther?.id,
        },
        affiliation: publication.affiliation,
        arrayFields: {
          translations: { translation: publication.translations },
          events: { event: publication.events as any },
        },
        mobileRequests: { mobileRequest: publication.mobileRequests },
        projects: publication.projects,
        file: publication.file,
        authors: publication.members,
      },
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
          showNotification({ message: 'Запись DOI успешно обновлена', theme: 'success' });
          onSuccess();
          onClose();
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
  }, [formFields.doi, publication, savePublication, publicationId, onSuccess, onClose]);

  useLayoutEffect(() => {
    setIsLoading(true);
    getPublication.callAPI(
      {
        id: publicationId,
        simpleFields: {
          status: 'ADDED',
        },
        attrIdFields: {},
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
        projects: [],
      },
      {
        onSuccessfullCall: (result: any) => {
          const data = result.data as Form.Publication;
          setIsLoading(false);
          setPublication(data);

          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            doi: {
              ...prevState.doi,
              value: data.fields.doi,
            },
          }));
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
  }, []); // eslint-disable-line

  const hasAccess = useMemo(
    () =>
      isProfile
        ? isHasPermission(userPermission, Permits.PERSONAL_CABINET)
        : isHasPermission(userPermission, Permits.PUBLICATION_DOI_EDIT),
    [isProfile, userPermission],
  );

  const isSubmitButtonDisabled = useMemo(() => isLoading || !hasAccess, [isLoading, hasAccess]);

  return {
    publication,
    submitForm,
    formFields,
    isLoading,
    isSubmitButtonDisabled,
    hasAccess,
  };
};

export default useController;
