import React, { memo } from 'react';

import { buttonIcons, IconButton, ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type WrapperProps = {
  recognitionsFilter: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ recognitionsFilter }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isViewButtonDisabled,
      isViewFormOpen,
      handleTemplateCloseViewForm,
      isEditButtonDisabled,
      isEditFormOpen,
      handleTemplateCloseEditForm,
      isAddFormOpen,
      handleTemplateCloseAddForm,
      nextPublicationName,
      isDeleteConfirmPopupOpen,
      isDeleteButtonDisabled,
      handleCloseDeleteConfirmPopup,
      handleConfirmDeleteConfirmPopup,
      buttons,
      member,
      handleTemplateCloseHelpForm,
      isHelpFormOpen,
    } = useController({ tableState, recognitionsFilter });
    return (
      <>
        {!isViewButtonDisabled && (
          <modalTemplates.RecognitionViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
          />
        )}
        {!isEditButtonDisabled && (
          <modalTemplates.RecognitionEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            memberEditLocked={true}
          />
        )}
        <modalTemplates.RecognitionAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          member={member}
          memberEditLocked={true}
        />

        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранную запись?`}
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            onConfirm={handleConfirmDeleteConfirmPopup}
            okButtonText="Да"
          />
        )}
        <ConfirmPopup
          title="Помощь в разделе Признания и награды"
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          <div className="modal-help">
            <p>
              <strong className="primary">Раздел Признания и награды </strong>ппредназначен для ввода и хранения информации о
              персональных наградах или признаниях (награды министерств и ведомств, правительственные награды, областной и
              городской администрации, университета и пр.)
            </p>
            <ul>
              <li>
                <strong>Фильтр «Мои награды»</strong> содержит список ваших наград
              </li>
              <li>
                <strong>Фильтр «Все награды»</strong> содержит список персон, имеющих награды
              </li>
            </ul>
            <p>
              Информацию о своих наградах и достижениях пользователь заносит в данном разделе Личного кабинета или ответственное
              лицо от подразделения заносит в общем меню сайта. Связь с подразделением должна быть указана для отображения вашей
              награды в отчете подразделения. Привязка к подразделению отображается в столбце «Подразделение».
            </p>
            <h3>
              <strong className="primary">В разделе Признания и награды вы можете:</strong>
            </h3>
            <ul>
              <li>
                Посмотреть запись о награде с помощью кнопки <IconButton icons={buttonIcons.loop} hasNoHover />
              </li>
              <li>
                Добавить новую награду с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover />
              </li>
              <li>
                Редактировать запись о награде в статусе Добавлена с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.edit} hasNoHover />
              </li>
              <li>
                Удалить запись о награде, которую вы создавали, если она в статусе Добавлена с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.delete} hasNoHover />
              </li>
              <li>
                Скачать прикрепленный файл награды с помощью кнопки <IconButton icons={buttonIcons.download} hasNoHover />
              </li>
              <li>
                Выгрузить список наград в файл Excel с помощью кнопки <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
              </li>
              <li>
                Задать вопрос или написать сообщение администратору в отдельном окне с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
              </li>
            </ul>
            <p>
              При сохранении награде присваивается статус <strong>Добавлена</strong>. Добавленную запись о награде можно
              редактировать, добавить информацию или удалить.
            </p>
            <p>
              Добавленную вами награду проверит и переведет в статус <strong>Утверждена</strong> Администратор данных.
              Утвержденная запись недоступна для внесения изменений. Если вы нашли ошибку в утвержденной записи - напишите письмо
              Администратору.
            </p>
          </div>
        </ConfirmPopup>

        <Toolbar buttons={buttons} />
      </>
    );
  });
