import { useCallback, useMemo, useState } from 'react';

import { buttonIcons } from 'components';

import { ExtraToolbarButton } from 'components/ListEdit/model';
import { MipPartner } from 'types/models';

export function useController() {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const extraToolbarButtons = useMemo<ExtraToolbarButton<MipPartner.CharterCapital>[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
    ],
    [],
  );

  const formatFounders = useCallback((capital: MipPartner.CharterCapital) => {
    return capital.founders.map(f => (f.enterpriseCustomer?.id ? f.enterpriseCustomer?.label : f.scientist?.fullName)).join(', ');
  }, []);

  const formatAsserts = useCallback((capital: MipPartner.CharterCapital) => {
    return capital.founders
      .map(f => f.insertionAsserts)
      .filter(a => a)
      .join(', ');
  }, []);

  return {
    selectedRowIndex,
    setSelectedRowIndex,
    isHelpFormOpen,
    setIsHelpFormOpen,
    extraToolbarButtons,
    formatFounders,
    formatAsserts,
  };
}
