import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { State, makeUseCustomController } from './makeUseCustomController';

/* This specification - is full clone of GetDetailedScientistList and renamed */

export type Arguments = {
  hasSelectButton?: boolean;
};

export const GetReferenceScientistList = ({ hasSelectButton }: Arguments): Table.Specification<State> => ({
  apiID: 'GetReferenceScientistList',
  header: {
    firstLevel: {
      title: 'Персоны',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: false,
      hasSelectButton,
    },
  },
  useCustomController: makeUseCustomController(),
  isCanSelectOnlyOneRow: true,
  isWithExport: false,
});

export const GetReferenceScientistListSpecification = ({ hasSelectButton }: Arguments): Table.Specification<State> => ({
  apiID: 'GetReferenceScientistList',
  header: {
    firstLevel: {
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: false,
      hasSelectButton,
    },
  },
  useCustomController: makeUseCustomController(),
  isCanSelectOnlyOneRow: true,
  isWithExport: false,
});
