import * as R from 'ramda';

import { ReferenceItem } from 'components';
import { Form, ScientificIndex, UserPermission } from 'types/models';

import { SavedChild, SavedValue } from 'services/BackendAPI/configurations/types';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { ChildField, Field } from './types';

export function checkIsReferenceItem(arg: any): arg is ReferenceItem {
  return typeof arg === 'object' && arg.label !== undefined && arg.id !== undefined;
}

export function provideSavedValuesInFields(savedValues: SavedValue[], fields: Record<string, Field>) {
  return R.pipe<Record<string, Field>, [string, Field][], [string, Field][], Record<string, Field>>(
    R.toPairs,
    R.map(([id, field]) => {
      const savedValue = savedValues.find(val => val.valueId === field.valueId);
      const updatedField: Field = {
        ...field,
        savedValueId: savedValue?.savedValueId ?? null,
        value: savedValue?.value ?? '',
      };
      return [id, updatedField];
    }),
    R.fromPairs,
  )((fields as unknown) as Record<string, Field>);
}

export function getFieldsFromServerChilds(serverChilds: SavedChild[]) {
  return serverChilds.map<ChildField>(x => {
    const { parentFields: fields } = getFieldsFromScientificIndex(x.scientificIndex);
    const updatedFields = provideSavedValuesInFields(x.savedValues, fields);
    return {
      code: x.scientificIndex.code,
      label: x.scientificIndex.label,
      savedChildId: x.savedChildId,
      scientificIndexId: x.scientificIndex.id,
      fields: updatedFields,
    };
  });
}

export function getFieldsFromScientificIndex(scientificIndex: ScientificIndex.ScientificIndex) {
  const convert = (values: ScientificIndex.ScientificField[]) =>
    values.reduce<Record<string, Field>>((acc, { name, id, required, type, metaType, code }) => {
      const field: Field = { valueId: id, name, value: '', isRequired: required, type, metaType, savedValueId: null, code };
      return { ...acc, [field.valueId]: field };
    }, {});

  const parentFields = convert(scientificIndex.values);
  const childFields = scientificIndex.childs.map<ChildField>(({ id: childId, code: childCode, values, label }) => {
    const fields = convert(values);
    return { scientificIndexId: childId, code: childCode, fields, label, savedChildId: null };
  });

  return { parentFields, childFields };
}

type GetCitationSystemIndexEditPermissionAccessProps = {
  publicationType: Form.PublicationTypeCode;
  userPermission: UserPermission | null;
};

export const getCitationSystemIndexEditPermissionAccess = ({
  publicationType,
  userPermission,
}: GetCitationSystemIndexEditPermissionAccessProps) => {
  const isCompilation = [
    PublicationTypes.COMPILATION_CONFERENCE.code,
    PublicationTypes.COMPILATION_OTHER.code,
    PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
  ].some(compilationType => compilationType === publicationType);
  const isMagazine = PublicationTypes.MAGAZINE.code === publicationType;

  if (isCompilation) {
    return isHasPermission(userPermission, Permits.COMPILATION_CITATION_SYSTEM_INDEX_EDIT);
  }
  if (isMagazine) {
    return isHasPermission(userPermission, Permits.MAGAZINE_CITATION_SYSTEM_INDEX_EDIT);
  }

  return isHasPermission(userPermission, Permits.PUBLICATION_CITATION_SYSTEM_INDEX_EDIT);
};
