import React from 'react';

import { ConfirmPopup, Toolbar as SharedToolbar } from 'components';
import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from '../modalTemplates';

type Props = {
  tableState: Table.State;
};

function Toolbar({ tableState }: Props) {
  const { buttons, isViewFormOpen, handleTemplateCloseViewForm, isHelpFormOpen, handleTemplateCloseHelpForm } = useController({
    tableState,
  });
  return (
    <>
      <ConfirmPopup
        title="Помощь  в работе с разделом Руководство защитой"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">В разделе «Руководство защитой» </strong> отображаются сведения из добавленных публикаций
            типов &quot;Автореферат&quot; и &quot;Диссертация&quot;, где Вы указаны в сведениях об авторстве с ролью «научный
            руководитель».
          </p>
          <p>Диссертации, защищённые под вашим руководством, вы можете добавить в разделе Публикации.</p>
          <p>
            Сведения об участие в составе диссертационного совета вы можете добавить в разделе Персональные достижения , Работа в
            составе диссертационных советов.
          </p>
        </div>
      </ConfirmPopup>
      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
