import React from 'react';

import { Table } from 'types/models';

import { Toolbar as SharedToolbar } from 'components';
import * as modalTemplates from './modalTemplates';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { tableState } = props;
  const {
    isViewFormOpen,
    handleTemplateCloseViewForm,
    buttons,
    // publication,
    // isArticlesWarningPopupOpen,
    // isEditArticleSourceOpen,
    // handleCloseArticlesWarningPopup,
    // handleCloseEditArticleSource,
    // handleSubmitDeleteWarningPopup,
    // isOpenEditRelations,
    // handleCloseEditRelationsModal,
    // isCitationFormOpen,
    // handleCloseCitationForm,
    // isSourceCitationDisabled,
    // isSourceCitationOpen,
    // handleCloseSourceCitation,
    // isPublicationOriginalTranslatesRelationsOpen,
    // handleClosePublicationOriginalTranslatesRelations,
    // submitPublicationOriginalTranslatesRelationsOpen,
    // isFinancialSupportOpen,
    // handleCloseFinancialSupport,
    // submitFinancialPopupCallback,
    // isReportOpen,
    // onReportClose,
    // currentReport,
    // currentPerson,
  } = useController(tableState);
  return (
    <>
      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {/* {!!tableState.selectedRows.length && (
        <EditRelationsModal
          title="Редактировать связь публикации с подразделениями"
          isOpen={isOpenEditRelations}
          onClose={handleCloseEditRelationsModal}
          publication={publication}
        />
      )}
      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isSourceCitationDisabled && (
        <modalTemplates.SourcePublicationCitationSystemTemplate.Component
          isOpen={isSourceCitationOpen}
          onClose={handleCloseSourceCitation}
          id={tableState.selectedRows[0].SourceId}
          relatedTableState={tableState}
        />
      )}
      {!!tableState.selectedRows.length && (
        <PublicationOriginalTranslatesRelations
          isOpen={isPublicationOriginalTranslatesRelationsOpen}
          onClose={handleClosePublicationOriginalTranslatesRelations}
          onSuccess={submitPublicationOriginalTranslatesRelationsOpen}
          publicationId={tableState.selectedRows[0]!.id}
          relatedTableState={tableState}
        />
      )}
      {!!tableState.selectedRows.length && (
        <FinancicalSupportPopup
          isOpen={isFinancialSupportOpen}
          relatedTableState={tableState}
          onClose={handleCloseFinancialSupport}
          publicationId={tableState.selectedRows.length ? tableState.selectedRows[0].id : ''}
          workMode="editMode"
          onSuccess={submitFinancialPopupCallback}
        />
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        scientistId={currentPerson?.scientist?.id || ''}
      />
      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={tableState.selectedRows[0]['id:publicationType'] as PublicationTypeCode}
        />
      )}
      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0].id}
          publicationTypeCode={tableState.selectedRows[0]['id:publicationType'] as PublicationTypeCode}
        />
      )} */}
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
