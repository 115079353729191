import React, { useMemo } from 'react';
import { setup } from 'bem-cn';

import { ListEditTable, Button, ButtonMode, ConfirmPopup, Modal, FormComponent } from 'components';

import { Event, Table } from 'types/models';
import useController from './controller';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ParticipationTypeCode } from 'types/models/Participation';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('similar-participations');

type Props = {
  isOpen: boolean;
  eventId: string;
  eventStatus: string;
  onClose: () => void;
  sourceEvent: Event.Participations[];
  workMode: Table.WorkMode;
  acceptSimilarParticipation: () => void;
  eventName: string;
  type: ParticipationTypeCode;
};

const SimilarParticipations = ({
  eventName,
  isOpen,
  onClose,
  sourceEvent,
  workMode,
  acceptSimilarParticipation,
  eventId,
  eventStatus,
  type,
}: Props) => {
  const {
    columns,
    selectedRowIndex,
    setSelectedRowIndex,
    isWarningPopupOpen,
    attachParticipation,
    closeWarningPopup,
    openWarningPopup,
    participationInfoText,
    attachParticipationPermission,
  } = useController({ sourceEvent, acceptSimilarParticipation, eventName, type });

  const title = useMemo(() => {
    const baseTitle = (() => {
      switch (type) {
        case ParticipationTypes.PARTICIPATION_CONFERENCE.code:
          return 'Участия, которые связаны с конференцией';
        case ParticipationTypes.PARTICIPATION_EXPOSITION.code:
          return 'Участия, которые связаны с выставкой';
        case ParticipationTypes.PARTICIPATION_CONTEST.code:
          return 'Участия, которые связаны с конкурсом';
        case ParticipationTypes.PARTICIPATION_CONCERT.code:
          return 'Участия, которые связаны с концертом';
        default:
          return '';
      }
    })();

    const eventTitle = (() => {
      const typeName = (() => {
        switch (type) {
          case ParticipationTypes.PARTICIPATION_CONFERENCE.code:
            return 'Конференция';
          case ParticipationTypes.PARTICIPATION_EXPOSITION.code:
            return 'Выставка';
          case ParticipationTypes.PARTICIPATION_CONTEST.code:
            return 'Конкурс';
          case ParticipationTypes.PARTICIPATION_CONCERT.code:
            return 'Творческое мероприятие';
          default:
            return '';
        }
      })();

      const details = [eventId ? `ID: ${eventId}` : '', eventStatus ? `Статус: ${eventStatus}` : ''].filter(x => x).join(', ');

      return ['Мероприятие:', typeName ? `${typeName}:` : '', eventName, details ? `(${details})` : ''].filter(x => x).join(' ');
    })();

    return (
      <>
        <h3>{baseTitle}</h3>
        <h4>{eventTitle}</h4>
      </>
    );
  }, [eventId, eventName, eventStatus, type]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <FormComponent.Wrapper>
        <div className={b('table-wrapper')}>
          <div className={b('table')}>
            <ListEditTable
              rows={sourceEvent}
              defaultRowsCount={10}
              columns={columns}
              selectRow={setSelectedRowIndex}
              selectedRowIndex={selectedRowIndex}
            />
          </div>
        </div>
        <ConfirmPopup
          isOpen={isWarningPopupOpen}
          onClose={closeWarningPopup}
          title="Привязка к подразделению"
          text={participationInfoText}
          icon="warning"
          okButtonText="Ок"
          resetButtonText="Отмена"
          onConfirm={attachParticipation}
        />
        <div className={b('actions')}>
          <ul className={b('actions-list')}>
            <li className={b('actions-item')}>
              <Button
                mode={ButtonMode.primary}
                text="Привязать к подраздерелению и закрыть форму"
                onClick={openWarningPopup}
                permission={{ name: attachParticipationPermission }}
                isDisabled={selectedRowIndex === null}
              />
            </li>
            <li className={b('actions-item')}>
              <Button
                mode={ButtonMode.secondary}
                text={`Продолжить ${workMode === 'addMode' ? 'создание' : 'редактирование'} участия`}
                onClick={onClose}
              />
            </li>
          </ul>
        </div>
      </FormComponent.Wrapper>
    </Modal>
  );
};

export const Component = React.memo(SimilarParticipations);
