import { useCallback, useMemo, useState, useLayoutEffect, useEffect } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Parameter, Table, ParameterListValue } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { usePrevious } from 'shared/react/usePrevious';
import { Parameters } from 'features/BuildReportPopup/Parameters';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { AdditionalField, BuilderProps, EnumProps } from 'features/BuildReportPopup/models';
import { ALL_RADIO_VALUE, PARTIAL_RADIO_VALUE } from './helpers';

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = {
  enumProps?: EnumProps<CustomState, RequestData>;
  additionalFields?: AdditionalField[];
  parentId?: string;
  isWithoutTopFields?: boolean;
  disabled?: boolean;
} & BuilderProps;

const useController = <CustomState extends {} = {}, RequestData extends {} = {}>({
  parameters,
  parentId,
  parameterName,
  enumProps,
  additionalFields = [],
  isWithoutTopFields,
  disabled,
  setParameterValueByName,
}: Props<CustomState, RequestData>) => {
  const { methods: GetUserDepartments, state: GetUserDepartmentsState } = BackendAPI.useBackendAPI('GetUserDepartments');
  const { enumMap } = useAppDataContext();
  const [refs, setRefs] = useState<ReferenceItem[]>([]);
  const [refsRadioValue, setRefsRadioValue] = useState<ParameterListValue['radioValue']>(ALL_RADIO_VALUE);
  const [fields, setFields] = useState<AdditionalField[]>(R.clone(additionalFields));
  const [userDepartments, setUserDepartments] = useState<ReferenceItem[]>([]);

  const prevParentId = usePrevious(parentId);

  const onChangeFields = useCallback(
    ({ index, nextValue }: { index: number; nextValue: AdditionalField['value'] }) => {
      const nextFields = fields.map((field, fieldIndex) => ({ ...field, ...(index === fieldIndex ? { value: nextValue } : {}) }));
      setFields(nextFields);
      setParameterValueByName({
        name: parameterName,
        value: {
          radioValue: isWithoutTopFields ? undefined : refsRadioValue,
          list: refs,
          additionalValues: isWithoutTopFields ? [] : nextFields,
          parentId,
        },
      });
    },
    [fields, isWithoutTopFields, parameterName, parentId, refs, refsRadioValue, setParameterValueByName],
  );

  const isUserDepartmentsLoading = getIsPending(GetUserDepartmentsState);

  const currentParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameterName, parameters],
  );

  const setNextParameterValue = useCallback(
    ({ nextRefs, nextRefsRadioValue }: { nextRefs?: ReferenceItem[]; nextRefsRadioValue?: ParameterListValue['radioValue'] }) => {
      const usedRefs = nextRefs || refs;
      const usedRadioValue = nextRefsRadioValue || refsRadioValue;

      const nextParameterValue: ParameterListValue = {
        parentId,
        radioValue: isWithoutTopFields ? undefined : usedRadioValue,
        list: usedRefs,
        additionalValues: isWithoutTopFields ? [] : fields,
      };

      setParameterValueByName({ name: parameterName, value: nextParameterValue });
    },
    [fields, isWithoutTopFields, parameterName, parentId, refs, refsRadioValue, setParameterValueByName],
  );

  useEffect(() => {
    if (parentId !== prevParentId) {
      setParameterValueByName({
        name: parameterName,
        value: {
          parentId,
          radioValue: isWithoutTopFields ? undefined : refsRadioValue,
          list: [],
          additionalValues: [],
        },
      });

      setRefs([]);
      setFields([]);
    }
  }, [isWithoutTopFields, parameterName, parentId, prevParentId, refsRadioValue, setParameterValueByName]);

  const canBuild = useMemo<boolean>(() => !!currentParameter, [currentParameter]);

  const handleChangeRefs = useCallback(
    (nextRefs: ReferenceItem[]) => {
      setRefs(nextRefs);
      setNextParameterValue({ nextRefs });
    },
    [setNextParameterValue],
  );

  const handleChangeRefsRadioValue = useCallback(
    (nextValue: string) => {
      if (nextValue === ALL_RADIO_VALUE || nextValue === PARTIAL_RADIO_VALUE) {
        if (nextValue === ALL_RADIO_VALUE) {
          setNextParameterValue({ nextRefs: userDepartments, nextRefsRadioValue: nextValue });
        } else {
          setNextParameterValue({ nextRefsRadioValue: nextValue });
        }
        setRefsRadioValue(nextValue);
      }
    },
    [setNextParameterValue, userDepartments],
  );

  const defaultRadioValue = useMemo<ParameterListValue['radioValue']>(
    () => (currentParameter?.default !== '-1' ? ALL_RADIO_VALUE : PARTIAL_RADIO_VALUE),
    [currentParameter],
  );

  const enumValues = useMemo(() => {
    const currentEnumValues: Table.EnumValue[] = [];

    if (enumProps?.mode?.component === 'DefaultSelect') {
      currentEnumValues.push(...(enumMap[enumProps.mode.enumName]?.values || []));
    }

    return currentEnumValues;
  }, [enumMap, enumProps]);

  useLayoutEffect(() => {
    if (currentParameter?.name === Parameters.NotRequiredUserDepartment) {
      GetUserDepartments.callAPI(
        { isParent: true },
        {
          onSuccessfullCall: ({ data }) => {
            const preparedDepartments = data.map(({ ref }) => ref);
            setUserDepartments(preparedDepartments);
            setNextParameterValue({ nextRefs: preparedDepartments, nextRefsRadioValue: 'all' });
          },
        },
      );
    } else {
      setRefsRadioValue(isWithoutTopFields ? undefined : defaultRadioValue);
      setNextParameterValue({ nextRefsRadioValue: isWithoutTopFields ? undefined : defaultRadioValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isListEditDisabled = useMemo(
    () => (isWithoutTopFields ? false : refsRadioValue === ALL_RADIO_VALUE || disabled || isUserDepartmentsLoading),
    [disabled, isUserDepartmentsLoading, isWithoutTopFields, refsRadioValue],
  );

  return {
    refs,
    refsRadioValue,
    currentParameter,
    fields,
    userDepartments,
    canBuild,
    enumMap,
    enumValues,
    isListEditDisabled,
    onChangeFields,
    handleChangeRefs,
    handleChangeRefsRadioValue,
  };
};

export default useController;
