import { getCurrentTime, getMockPartner } from 'features/EditPartner/helpers';

import { MipPartner } from 'types/models';
import { formatNumber } from 'utils/Helpers';
import { Change } from 'types/models/Partner';
import { EMPTY_FILE } from 'utils/Constants';

export const getMockMipPartner = (): MipPartner.MipPartner => ({
  ...getMockPartner(),
  mipDocuments: [],
  isFl217: false,
  isFl273: false,
  indicators: [],
  charterCapitals: [],
});

export const getMockFounder = (): MipPartner.Founder => ({
  id: null,
  amount: '0.00',
  enterpriseCustomer: null,
  file: EMPTY_FILE,
  scientist: null,
  info: '',
  insertionAsserts: '',
  passport: '',
});

export const getMockCharterCapital = (): MipPartner.CharterCapital => ({
  id: null,
  amount: '0.00',
  editDate: '',
  founders: [],
});

export const getMockIndicator = (): MipPartner.Indicator => ({
  id: null,
  year: '',
  profit: '0.00',
  revenue: '0.00',
  reportDate: '',
  averageHeadcount: '',
});

type GetPercentOfAmountByPartArguments = {
  part: string;
  amount: string;
};

export const getPercentOfAmountByPart = ({ part, amount }: GetPercentOfAmountByPartArguments): string => {
  const partNumber = Number(part);
  const amountNumber = Number(amount);

  if (partNumber && amountNumber) {
    const percentage = (partNumber / amountNumber) * 100;
    return formatNumber(percentage);
  }

  return '0.00';
};

export function getMockChange(userName: string): Change {
  return {
    id: null,
    category: null,
    changeDate: '',
    description: '',
    createdBy: userName,
    createdDate: getCurrentTime(),
    modifiedBy: '',
    modifiedDate: '',
    file: EMPTY_FILE,
  };
}
