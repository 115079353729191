import * as R from 'ramda';

import { ContestRequest, Performer } from 'types/models/ContestRequest';

export function getMockContestRequest(): ContestRequest {
  return {
    approvements: [],
    code: '',
    id: null,
    indicators: [],
    indices: [],
    isRequestStageHasPatentResearch: false,
    okveds: [],
    project: undefined,
    requestStageAnnotation: '',
    requestStageCollaborations: '',
    requestStageCriticalTechnologies: [],
    requestStageEndDate: '',
    requestStageEstimatePositions: [],
    requestStageGoal: '',
    requestStageIndicators: [],
    requestStageIndices: [],
    requestStageKeyWords: '',
    requestStageKindWork: undefined,
    requestStageName: '',
    requestStageNtrStrategies: [],
    requestStageNumber: 0,
    requestStagePartnerEnterprises: '',
    requestStagePerformers: [],
    requestStagePlanAmount: '',
    requestStagePnis: [],
    requestStagePnmitrs: [],
    requestStagePnrs: [],
    requestStageOecds: [],
    requestStagePreviousYearAnnotation: '',
    requestStageRAN: '',
    requestStageStartDate: '',
    requestStageWorkPlan: '',
    status: undefined,
    documents: [],
    type: null,
  };
}

export function getHead(request: ContestRequest | null | undefined) {
  return request?.requestStagePerformers?.find(x => x.role?.value === 'LEADER') ?? null;
}

export function formatIndexHirsh(performer: Performer) {
  const sorted = R.sort(
    (x, y) => (x.citationSystem?.label || '').localeCompare(y.citationSystem?.label || ''),
    performer.person?.hirshIndexes ?? [],
  );
  return sorted.map(x => `${x.citationSystem?.label} - ${x.value} (${x.date})`).join(', ') ?? '';
}
