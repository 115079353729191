import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  // This removed. Why? I replaced to constant
  // import { USER_ROLES } from 'features/Form/looks/participation/views/MembersList/helpers';
  // const initialRoleValue: string = 'ALL';
  // const initialRoleList: RoleList = [
  //   { label: 'Все', value: 'ALL' },
  //   { label: 'Руководитель', value: USER_ROLES.SUPERVISOR.code },
  //   { label: 'Участник', value: USER_ROLES.PARTICIPANT.code },
  // ];

  return FiltersComponent([
    {
      type: FilterComponentType.constant,
      streamKey: 'role',
      value: 'ALL',
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});
