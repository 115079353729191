import { useCallback, useLayoutEffect, useState, useMemo } from 'react';
import { Participation, Department } from 'types/models/Participation';
import { RecordStatus as RS } from 'types/models';
import { Column } from 'components';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { RecordStatus } from 'utils/Enums/RecordStatus';

type Props = {
  id: string;
  onClose: () => void;
  afterSubmit?: ({ nextRecordStatus }: { nextRecordStatus: RS.Status }) => void;
};

const useController = ({ id, onClose, afterSubmit }: Props) => {
  const { methods: approveParticipation } = BackendAPI.useBackendAPI('ApproveParticipation');
  const { methods: getParticipation } = BackendAPI.useBackendAPI('GetParticipation');
  const [participationData, setParticipationData] = useState<null | Participation>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleApproveParticipation = useCallback(() => {
    setIsLoading(true);
    if (participationData !== null) {
      const nextRecordStatus: RS.Status = {
        label: 'Утверждена',
        value: RecordStatus.APPROVED,
      };

      if (participationData.status.value === RecordStatus.APPROVED) {
        nextRecordStatus.label = 'Добавлена';
        nextRecordStatus.value = RecordStatus.ADDED;
      }

      approveParticipation.callAPI(
        {
          participationId: participationData.id,
          disapprove: nextRecordStatus.value === RecordStatus.ADDED,
        },
        {
          onSuccessfullCall: () => {
            setIsLoading(false);

            if (participationData.status.value === RecordStatus.APPROVED) {
              showNotification({ message: 'Участие переведено в статус "добавлена"', theme: 'success' });
            } else if (participationData.status.value === RecordStatus.ADDED) {
              showNotification({ message: 'Участие утверждено', theme: 'success' });
            }
            if (afterSubmit) {
              afterSubmit({ nextRecordStatus });
            }
            onClose();
          },
          onFailedCall: () => {
            setIsLoading(false);
          },
        },
      );
    }
  }, [afterSubmit, participationData, onClose, approveParticipation]);

  useLayoutEffect(() => {
    setIsLoading(true);
    getParticipation.callAPI(
      {
        participationId: id,
        modules: [
          'ID',
          'ACCESS_MODE',
          'MAIN',
          'EVENT',
          'DEPARTMENTS',
          'PUBLICATION',
          'PROJECTS',
          'MEMBERS',
          'AWARDS',
          'MOBILE_REQUESTS',
          'DOCUMENTS',
        ],
        eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
        IsCollectiveExhibit: false,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setIsLoading(false);
          setParticipationData(data);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const departmentColumns = useMemo<Column<Department>[]>(
    () => [{ label: 'Подразделения к которым относится участие', formatValue: row => row.name }],
    [],
  );

  return {
    handleApproveParticipation,
    departmentColumns,
    participationData,
    isLoading,
  };
};

export default useController;
