import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';

function LeftPanelForThirdLevel() {
  const { isHelpFormOpen, handleTemplateCloseHelpForm, buttons, isReportOpen, onReportClose, currentReport } = useController();

  return (
    <>
      <ConfirmPopup
        title="Список публикаций Scopus, WoS по факультетам/институтам"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Таблица &quot;Список публикаций Scopus, WoS по факультетам/институтам&quot; содержит список публикаций, у которых
            источник индексируется в Scopus(и/или) WoS. Связь с факультетом/институтом для публикации определяется по алгоритму:
          </p>
          <ul>
            <li>если публикация связана с факультетом/институтом;</li>
            <li>
              если публикация связана с подразделением; а для подразделения указана связь с факультетом/институтом в специальном
              справочнике &quot;Структура по факультетам/институтам для отчётности&quot;.
            </li>
          </ul>
          <p>
            Таблица содержит исходные данные для построения отчёта &quot;Публикации Scopus, WoS по факультетам/институтам&quot;
          </p>
        </div>
      </ConfirmPopup>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
