import { useEffect } from 'react';

import { Table } from 'types/models';

export type State = {
  setLotId(programId: string): void;
};

type Props = {
  setLotId(programId: string): void;
};

export function makeUseCustomController({ setLotId }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    useEffect(() => {
      if (selectedRows.length) setLotId(selectedRows[0].id);
    }, [selectedRows]);
    return {
      setLotId,
    };
  };
}
