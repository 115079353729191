import { useMemo, useState } from 'react';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { buttonIcons } from 'components';
import { Document } from 'types/models';

export function useController() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const extraToolbarButtons = useMemo<ExtraToolbarButton<Document>[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
        checkIsDisabled: () => false,
      },
    ],
    [],
  );
  return {
    isHelpFormOpen,
    setIsHelpFormOpen,
    extraToolbarButtons,
  };
}
