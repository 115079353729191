import React, { SetStateAction, useCallback, useEffect } from 'react';

import { Tabs, Tab, ReferenceItem, FormComponent } from 'components';
import { Person, Form } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';

import { EditableHonor } from './model';
import { Toolbar } from './Toolbar/Toolbar';

import {
  Main,
  AdditionalInfo,
  Publications,
  ParticipationsEvents,
  RequestAndProjects,
  SecurityDocuments,
  DissertationsDeffence,
  Recognitions,
  ScientificInterests,
} from './Tabs';

import { useController } from './controller';
import { Colleagues } from './Tabs/Colleagues/Colleagues';
import { useFormContext } from 'features/Form/hooks';
import { ScientistEvents } from 'features/Form/looks/person/Tabs/ScientistEvents/ScientistEvents';

type Props = {
  onClose?(): void;
  viewMode?: boolean;
};

function PersonForm(props: Props) {
  const { onClose, viewMode = false } = props;
  const { localPerson, setLocalPerson, id, submitForm, loadPerson } = useController({
    onClose,
  });
  const {
    look: { setTitle, shortMode },
  } = useFormContext<Form.Look & { shortMode: boolean }>();

  const { enumMap, userPermission } = useAppDataContext();

  const changeScientificField = useCallback(
    (fieldName: string, value: string | ReferenceItem | null | boolean | undefined) => {
      setLocalPerson({ ...localPerson!, scientist: { ...localPerson?.scientist!, [fieldName]: value } });
    },
    [localPerson, setLocalPerson],
  );

  const makeChangeHandler = (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => {
    setLocalPerson({ ...localPerson!, [fieldName]: value });
  };

  const changeHonors = useCallback(
    (honors: EditableHonor[]) => {
      const academicRanks = honors.filter(i => i.type === 'academicRank');
      const ranks = honors.filter(i => i.type === 'rank');
      const degrees = honors.filter(i => i.type === 'degree');

      const updatePerson = { ...localPerson, academicRanks, ranks, degrees };

      setLocalPerson(updatePerson as SetStateAction<Person.ScientistPerson | null>);
    },
    [localPerson, setLocalPerson],
  );

  const renderMain = () => (
    <Main
      onChange={changeScientificField}
      person={localPerson}
      userPermission={userPermission}
      viewMode={viewMode}
      makeSetPersonField={makeChangeHandler}
    />
  );

  useEffect(() => {
    if (!localPerson?.scientist?.phoneOther && localPerson) {
      changeScientificField('phoneOther', localPerson?.scientist?.phoneIntegration || '');
    }
    if (!localPerson?.scientist?.mobilePhoneOther && localPerson) {
      changeScientificField('mobilePhoneOther', localPerson?.scientist?.mobilePhoneIntegration || '');
    }
    if (!localPerson?.scientist?.emailOther && localPerson) {
      changeScientificField('emailOther', localPerson?.scientist?.emailIntegration || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localPerson?.scientist?.phoneIntegration,
    localPerson?.scientist?.mobilePhoneIntegration,
    localPerson?.scientist?.emailIntegration,
  ]);

  useEffect(() => {
    if (setTitle) {
      setTitle(
        [
          viewMode ? 'Просмотр' : 'Редактирование',
          'портфолио',
          localPerson?.fullName || '',
          localPerson?.id ? `(ID ${localPerson?.id})` : '',
        ]
          .filter(x => x)
          .join(' '),
      );
    }
  }, [localPerson?.fullName, localPerson?.id, setTitle, viewMode]);

  return (
    <FormComponent.Template>
      {!shortMode && <Toolbar saveForm={viewMode ? undefined : submitForm} />}

      <FormComponent.Template>
        {shortMode ? (
          <AdditionalInfo
            enumMap={enumMap}
            person={localPerson}
            disabled={viewMode}
            makeSetPersonField={makeChangeHandler}
            onChangeHonors={changeHonors}
            userPermission={userPermission}
            onChange={changeScientificField}
            onChangeHirshIndexes={makeChangeHandler('hirshIndexes')}
            onUpdate={() => loadPerson(localPerson?.id || '')}
          />
        ) : (
          <Tabs>
            <Tab title="Профиль">{renderMain()}</Tab>
            <Tab title="Научные интересы">
              <ScientificInterests person={localPerson} />
            </Tab>
            <Tab title="Коллеги по научной работе">
              <Colleagues personId={localPerson?.id} />
            </Tab>
            <Tab title="Публикации">
              <Publications personId={localPerson?.id} isViewMode={viewMode} />
            </Tab>
            <Tab title="Участия в мероприятиях">
              <ParticipationsEvents personId={localPerson?.id} isViewMode={viewMode} />
            </Tab>
            <Tab title="Организованные мероприятия">
              <ScientistEvents person={localPerson} />
            </Tab>
            <Tab title="Персональные достижения">
              <Recognitions person={localPerson} disabled={viewMode} />
            </Tab>
            <Tab title="НИОКР">
              <RequestAndProjects personId={id} />
            </Tab>
            <Tab title="ОИС">
              <SecurityDocuments personId={id} />
            </Tab>
            <Tab title="Руководство защитой">
              <DissertationsDeffence personId={id} />
            </Tab>
            <Tab title="Дополнительные сведения">
              <AdditionalInfo
                enumMap={enumMap}
                person={localPerson}
                disabled={viewMode}
                makeSetPersonField={makeChangeHandler}
                onChangeHonors={changeHonors}
                userPermission={userPermission}
                onChange={changeScientificField}
                onChangeHirshIndexes={makeChangeHandler('hirshIndexes')}
                onUpdate={() => loadPerson(localPerson?.id || '')}
              />
            </Tab>
          </Tabs>
        )}
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(PersonForm);
