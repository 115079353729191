import React, { useState, useCallback, useMemo, ReactElement } from 'react';

import { IconButtonProps, ConfirmPopup, ListEditTable, FormComponent, Toolbar, buttonIcons } from 'components';

import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as modalTemplates from './modalTemplates';
import { TenderProtocol } from 'types/models/TenderProtocol';
import { getMockTenderProtocol } from 'features/Form/looks/tender/TenderProtocolForm/helpers';
import { ProtocolMainFields } from 'features/Form/views';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { renderToString } from 'react-dom/server';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const [selectedRow] = tableState.selectedRows;
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [tenderProtocol, setTenderProtocol] = useState<TenderProtocol>(getMockTenderProtocol());

  const { methods: saveTenderProtocol } = BackendAPI.useBackendAPI('SaveTenderProtocol');

  const reports = useMemo<Report[]>(() => [Reports.TenderProtocol], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleSave = useCallback(() => {
    if (!tenderProtocol.tender || !tenderProtocol.protocolCommission) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }
    saveTenderProtocol.callAPI(tenderProtocol, {
      onSuccessfullCall: ({ data }) => {
        setTenderProtocol(prevState => ({ ...prevState, id: data.id || null }));
        showNotification({ message: 'Протокол успешно сохранен', theme: 'success' });
        setIsAddFormOpen(false);
        setTimeout(() => setIsEditFormOpen(true), 0);
      },
    });
  }, [saveTenderProtocol, tenderProtocol]);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'TenderProtocol',
    });
    setIsDeleteConfirmPopupOpen(false);
  }, [tableState, tableStreams.deleteRow]);

  const handleCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
    setTenderProtocol(getMockTenderProtocol());
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
    setTenderProtocol(getMockTenderProtocol());
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setTenderProtocol(getMockTenderProtocol());
    setIsAddFormOpen(true);
  }, []);

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        permissionName: [
          Permits.TENDER_PROTOCOL_VIEW_PROTOCOL,
          Permits.TENDER_PROTOCOL_VIEW_PROTOCOL_REQUESTS,
          Permits.TENDER_PROTOCOL_VIEW_APPROVER_REQUESTS,
          Permits.TENDER_PROTOCOL_VIEW_TENDER,
          Permits.TENDER_PROTOCOL_VIEW_DOCUMENTS,
        ],
        isDisabled: !selectedRow,
        onClick: () => setIsViewFormOpen(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        permissionName: Permits.TENDER_PROTOCOL_ADD,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        permissionName: [Permits.TENDER_PROTOCOL_EDIT_PROTOCOL, Permits.TENDER_PROTOCOL_EDIT_DOCUMENTS],
        isDisabled: !selectedRow,
        onClick: () => setIsEditFormOpen(true),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: 'SUPERUSER',
        isDisabled: !selectedRow,
        onClick: () => setIsDeleteConfirmPopupOpen(true),
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'reports',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [handleAddButtonClick, handleHelpButtonClick, selectedRow, getReports, handleSetCurrentReport],
  );

  type Custom = {
    value: ReactElement;
    status: string;
  };

  const renderFields = (args: Custom[] | Custom) => {
    const fields = Array.isArray(args) ? args : [args];

    return renderToString(
      <>
        {fields.map((item, index) => {
          return (
            <div key={index}>
              <div style={{ display: 'flex' }}>
                <FormComponent.Column specialStyles={{ width: '80%' }}>
                  <>{item.value}</>
                </FormComponent.Column>
                <FormComponent.Column specialStyles={{ width: '20%' }}>
                  <>{item.status}</>
                </FormComponent.Column>
              </div>
              {fields.length - 1 !== index && <hr />}
            </div>
          );
        })}
      </>,
    );
  };

  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.TenderProtocolViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.TenderProtocolEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tenderProtocol.id || tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isAddFormOpen && (
        <ConfirmPopup
          title="Создание проекта протокола"
          isOpen={isAddFormOpen}
          onClose={handleCloseAddForm}
          okButtonText="Сохранить и продолжить"
          onConfirm={handleSave}
          resetButtonText="Отмена"
          onReset={handleCloseAddForm}
          size="large"
        >
          <ProtocolMainFields
            workMode="addMode"
            tenderProtocol={tenderProtocol}
            changeTender={e => setTenderProtocol(prevState => ({ ...prevState, tender: e }))}
            changeNumber={e => setTenderProtocol(prevState => ({ ...prevState, number: e }))}
            changeProtocolDate={e => setTenderProtocol(prevState => ({ ...prevState, protocolDate: e }))}
            changeProtocolCommission={e => setTenderProtocol(prevState => ({ ...prevState, protocolCommission: e }))}
          />
        </ConfirmPopup>
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный протокол? (ID: ${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ConfirmPopup
        title='Помощь в работе с разделом "Список участников заявок на конкурсы"'
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Функциональный блок <strong>Протоколы</strong> предназначен для организации и проведения процедуры проведения
            конкурсной процедуры экспертизы и согласования и \или экспертизы заявок, оформляемых на участие в конкурсе.
          </p>
          <p>
            Члены конкурсной комиссии имеют право на редактирование и просмотр протоколов, в которых они указаны в разделе
            Присутствовали.
          </p>
          <p>
            В Протоколе на вкладке "Список заявок" отображаются заявки, связанные с конкурсом в статусах: Заявка, На доработку,
            Отклонена, Рекомендована на конкурс - для Заявок на конкурс и Заявок на мобильность; в статусах: Подана на конкурс, На
            доработку, Отклонена, Согласована, Согласована с условием, Поддержана - для Заявок на внутренний конкурс. Форма заявки
            зависит от типа конкурса.
          </p>
          <p>
            <strong>Важно!</strong> Для заявок на конкурс и заявок на мобильность в протоколе отображаются последний статус,
            который был присвоен заявке до даты протокола.
          </p>
          <h3>
            Пользователь в роли <strong>Ответственный по заявкам</strong>:
          </h3>
          <ul>
            <li>
              создаёт проект протокола, в протоколе в разделе <strong>Присутствовали</strong> список членов из состава&nbsp;
              <strong>Конкурсной комиссии</strong>, участвующие в проверке заявок на конкурс;
            </li>
            <li>учитывая экспертную оценку изменяет статусы у заявок;</li>
            <li>оформляет протокол (номер и дата);</li>
            <li>распечатывает протокол, подписывает у членов комиссии;</li>
            <li>
              скан-копию протокола прикрепляет на вкладке <strong>Документы</strong>.
            </li>
          </ul>
          <h3>Порядок действий члена конкурсной комиссии:</h3>
          <ul>
            <li>Открыть форму протокола, перейти на вкладку Список заявок.</li>
            <li>Выбрать в списке Заявку, просмотреть разделы заявки.</li>
            <li>Оформить экспертную оценку в формате стандартизованного сообщения (виза и замечания).</li>
          </ul>
          <h3>Управление статусами заявок и краткое описание бизнес процесса</h3>
          <strong className="primary">Таблица 1. Статусы заявки на конкурс и краткое описание бизнес-процесса</strong>
          <ListEditTable
            rows={[
              {
                role: 'Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета',
                action: renderFields([
                  {
                    value: <>Добавить заявку на конкурс</>,
                    status: 'Черновик',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку с помощью кнопки <strong>На согласование</strong>
                      </>
                    ),
                    status: 'Заявка',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки на конкурс',
                action: renderFields([
                  {
                    value: <>Просмотр заявки; Добавить сообщение для заявителя</>,
                    status: 'Заявка',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отправить на доработку</strong>
                      </>
                    ),
                    status: 'На доработку',
                  },
                ]),
              },
              {
                role: 'Конкурская комиссия',
                action: renderFields({
                  value: <>Просмотр заявки; Добавить визу</>,
                  status: 'Заявка',
                }),
              },
              {
                role: 'Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета',
                action: renderFields([
                  {
                    value: (
                      <>
                        Редактирование заявки в статусе <strong>На Доработку</strong>
                      </>
                    ),
                    status: '',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку в с помощью кнопки <strong>На согласование</strong>
                      </>
                    ),
                    status: '',
                  },
                ]),
              },
              {
                role: 'Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета',
                action: renderFields([
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отклонить</strong>; написать сообщение — обоснование
                      </>
                    ),
                    status: 'Отклонена',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Рекомендовать на конкурс</strong>; написать сообщение для
                        заявителя
                      </>
                    ),
                    status: 'Рекомендована на конкурс',
                  },
                  {
                    value: (
                      <>
                        После регистрации заявки в ИАС Организатора конкурса. На форме редактирования Заявки на конкурс заполнить
                        поля:
                        <strong>Подана на конкурс</strong>; и <strong>Шифр заявки</strong>
                      </>
                    ),
                    status: 'Подана',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки{' '}
                        <strong>Поддержать</strong>
                      </>
                    ),
                    status: 'Поддержана',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки{' '}
                        <strong>Отклонить</strong>
                      </>
                    ),
                    status: 'Отклонена',
                  },
                ]),
              },
            ]}
            columns={[
              { label: 'Роль', formatValue: row => row.role || '', styles: { width: '20%' } },
              { label: 'Действие и статус', formatValue: row => row.action || '', styles: { width: '80%' } },
            ]}
            selectedRowIndex={null}
            selectRow={() => {}}
          />
          <strong className="primary">Таблица 2. Статусы заявки на внутренние конкурс и краткое описание бизнес-процесса</strong>

          <ListEditTable
            rows={[
              {
                role: 'Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета',
                action: renderFields([
                  {
                    value: <>Добавить заявку на конкурс</>,
                    status: 'Черновик',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку с помощью кнопки <strong>На согласование</strong>
                      </>
                    ),
                    status: 'Заявка',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки на внутренний конкурс',
                action: renderFields([
                  {
                    value: <>Просмотр заявки; Добавить сообщение для заявителя</>,
                    status: 'Заявка',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отправить на доработку</strong>
                      </>
                    ),
                    status: 'На доработку',
                  },
                ]),
              },
              {
                role: 'Конкурская комиссия',
                action: renderFields({
                  value: <>Просмотр заявки; Добавить визу</>,
                  status: 'Заявка',
                }),
              },
              {
                role: 'Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета',
                action: renderFields([
                  {
                    value: (
                      <>
                        Редактирование заявки в статусе <strong>На Доработку</strong>
                      </>
                    ),
                    status: '',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку в с помощью кнопки <strong>На согласование</strong>
                      </>
                    ),
                    status: '',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки на внутренний конкурс',
                action: renderFields([
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отклонить</strong>; написать сообщение — обоснование
                      </>
                    ),
                    status: 'Отклонена',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Рекомендовать на конкурс</strong>; написать сообщение для
                        заявителя
                      </>
                    ),
                    status: 'Рекомендована на конкурс',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки на внутренний конкурс',
                action: renderFields([
                  {
                    value: (
                      <>
                        После регистрации заявки в ИАС Организатора конкурса. На форме редактирования Заявки на конкурс заполнить
                        поля:
                        <strong>«Подана на конкурс»</strong>; и <strong>«Шифр заявки»</strong>
                      </>
                    ),
                    status: 'Подана',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки&nbsp;
                        <strong>«Поддержать»</strong>
                      </>
                    ),
                    status: 'Поддержана',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки&nbsp;
                        <strong>«Отклонить»</strong>
                      </>
                    ),
                    status: 'Отклонена',
                  },
                ]),
              },
            ]}
            columns={[
              { label: 'Роль', formatValue: row => row.role || '', styles: { width: '20%' } },
              { label: 'Действие и статус', formatValue: row => row.action || '', styles: { width: '80%' } },
            ]}
            selectedRowIndex={null}
            selectRow={() => {}}
          />

          <strong className="primary">Таблица 3. Статусы заявки по академической мобильности и описание бизнес-процесса</strong>

          <ListEditTable
            rows={[
              {
                role: 'Заявитель —пользователь личного кабинета',
                action: renderFields([
                  {
                    value: <>Добавить заявку на конкурс</>,
                    status: 'Черновик',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку с помощью кнопки <strong>На согласование</strong>
                      </>
                    ),
                    status: 'Заявка',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки по академической мобильности',
                action: renderFields([
                  {
                    value: <>Просмотр заявки; Добавить сообщение для заявителя</>,
                    status: 'Заявка',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отправить на доработку</strong>
                      </>
                    ),
                    status: 'На доработку',
                  },
                ]),
              },
              {
                role: 'Конкурская комиссия',
                action: renderFields({
                  value: <>Просмотр заявки; Добавить визу</>,
                  status: 'Заявка',
                }),
              },
              {
                role: 'Заявитель —пользователь личного кабинета',
                action: renderFields([
                  {
                    value: (
                      <>
                        Редактирование заявки в статусе <strong>На Доработку</strong>;
                      </>
                    ),
                    status: '',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку в с помощью кнопки <strong>На конкурс</strong>
                      </>
                    ),
                    status: '',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отклонить</strong>; написать сообщение — обоснование
                      </>
                    ),
                    status: 'Отклонена',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки по академической мобильности',
                action: renderFields([
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Рекомендовать на конкурс</strong>; написать сообщение для
                        заявителя
                      </>
                    ),
                    status: 'Рекомендована на конкурс',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки{' '}
                        <strong>Поддержать</strong>
                      </>
                    ),
                    status: 'Поддержана',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки{' '}
                        <strong>Отклонить</strong>
                      </>
                    ),
                    status: 'Отклонена',
                  },
                ]),
              },
            ]}
            columns={[
              { label: 'Роль', formatValue: row => row.role || '', styles: { width: '20%' } },
              { label: 'Действие и Статус', formatValue: row => row.action || '', styles: { width: '80%' } },
            ]}
            selectedRowIndex={null}
            selectRow={() => {}}
          />
        </div>
      </ConfirmPopup>
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        tenderProtocolId={selectedRow?.id ?? ''}
      />
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
