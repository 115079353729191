import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { useAppDataContext } from 'features/AppData/context';

export const Filters = React.memo(() => {
  const { settings, userSystemDepartment } = useAppDataContext();
  const organizationName = settings?.organization?.shortName;

  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'eventCategory',
        list: [
          {
            label: 'Мои мероприятия',
            value: 'mine',
            hint: 'Отображаются только те мероприятия, в которых вы в составе оргкомитета',
          },
          {
            label: 'Мероприятия подразделения',
            value: 'byDepartment',
            hint: `Список творческих мероприятий, организованных подразделением: ${userSystemDepartment?.label}`,
          },
          {
            label: organizationName ? `Мероприятия ${organizationName}` : 'Все мероприятия',
            value: 'local',
            hint: `Список творческих мероприятий, организованных ${organizationName || 'университетом'}`,
          },
          {
            label: 'Другие мероприятия',
            value: 'other',
            // eslint-disable-next-line max-len
            hint: `Список творческих мероприятий других организаций, в которых принимают участие сотрудники, аспиранты и студенты ${
              organizationName || 'университета'
            }`,
          },
        ],
        value: 'mine',
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Показывать за год',
        checked: false,
      },
    ],
    'personal',
  );
});
