export class Parameters {
  static PrintFormat = 'allowedFormats'; // Дефолтное поле с возможными форматами печати

  static SingleFaculty = 'SingleFaculty'; // подразделение

  static PaymentRequestPeriod = 'paymentRequestPeriod'; // периоды

  static DateRange = 'DateRange'; // период даты

  static StartDate = 'StartDate'; // Начало периода даты

  static EndDate = 'EndDate'; // Конец периода даты

  static Date = 'Date'; // Дата

  static year = 'year'; // Год

  static Year = 'Year'; // Год

  static ProjectID = 'ProjectID'; // id проекта

  static mipId = 'mipId'; // id мипа

  static MobileRequestId = 'MobileRequestId'; // id заявки

  static ProjectActId = 'ProjectActId'; // id акта

  static MobileProjectId = 'MobileProjectId'; // id проекта

  static TenderProtocolId = 'TenderProtocolId'; // id протокола

  static ProjectCode = 'ProjectCode'; // Шифр проекта

  static DepartmentsList = 'DepartmentsList'; // id подразделение

  static ContestRequestId = 'ContestRequestId'; // id заявки

  static RequestIds = 'RequestIds'; // id заявок

  static tender = 'tender'; // tender id

  static RequestStatusList = 'RequestStatusList'; // RequestStatusList

  static ProgramsList = 'ProgramsList'; // Список программ

  static GrantTypeList = 'GrantTypeList'; // Список грантовых программ

  static request = 'request'; // заявка

  static LowYear = 'loyear'; // Год

  static HightYear = 'hiyear'; // Год

  static StartYear = 'StartYear'; // Начальный Год

  static EndYear = 'EndYear'; // Конечный Год

  static NotRequeredProgramList = 'NotRequeredProgramList'; // Список программ

  static ProjectCodeLetter = 'ProjectCodeLetter'; // Буква шифра проекта

  static NotRequeredProgramEventList = 'NotRequeredProgramEventList'; // Список мероприятий

  static ProjectAndCode = 'projectAndCode'; // список шифров

  static IncludeTransitoryBalance = 'IncludeTransitoryBalance'; // включать входящее сальдо

  static reportYear = 'reportYear'; // год

  static ReportYear = 'ReportYear'; // год

  static YoungScientists = 'YoungScientists'; // молодые ученые(чекбокс)

  static ProjectTypes = 'ProjectTypes'; // типы проекта

  static NotRequiredNirRequestStatus = 'notRequiredNirRequestStatus'; // статусы заявок на нир

  static NotRequiredDepartment = 'notRequiredDepartment'; // подразделения

  static NotRequiredExpositionType = 'notRequiredExpositionType'; // выставки

  static FinTypeList = 'FinTypeList'; // Список источников финансирования

  static NotRequiredAuthorRequestStatus = 'notRequiredAuthorRequestStatus'; // Статус заявок

  static NotRequiredRequestType = 'notRequiredRequestType'; // Типы заявок

  static NotRequiredRequestStatus = 'notRequiredRequestStatus'; // Статусы заявок

  static NotRequiredRegulationParagraph = 'notRequiredRegulationParagraph'; // Показатели

  static NotRequiredDepartmentWithMode = 'notRequiredDepartmentWithMode'; // Расширенные подразделения

  static NotRequiredUserDepartment = 'notRequiredUserDepartment'; // Подразделения юзера

  static NotRequiredDocumentTypes = 'NotRequiredDocumentTypes'; // типы документа

  static EventLevelStatus = 'eventLevelStatus'; // Уровень мероприятия

  static PublicationRecordStatus = 'publicationRecordStatus'; // перечисления статусов публикации

  static Scientist = 'scientist'; // ученый

  static ScientistId = 'scientistId'; // сотрудник

  static ReportRecordStatus = 'ReportRecordStatus';

  static EventRecordStatus = 'eventRecordStatus';

  static PatentRecordStatus = 'patentRecordStatus';

  static AuthorRequestStatus = 'authorRequestStatus';

  static EmployeeRequestStatus = 'employeeRequestStatus';

  static DepartmentConsider = 'DepartmentConsider';

  static PersonStatus = 'personStatus';

  static ByDissertationCouncilReportType = 'ByDissertationCouncilReportType'; // Тип отчета

  static program = 'program'; // id программы

  static NirRequestId = 'NirRequestId';

  static RequestId = 'RequestId';

  static StageId = 'StageID';

  static SecurityDocumentId = 'SecurityDocumentId';

  static DropdownPresetYear = 'DropdownPresetYear';

  static ConferenceEventId = 'ConferenceEventId';

  static EventId = 'EventId';

  static ContestEventId = 'ContestEventId';

  static ExhibitionEventId = 'ExhibitionEventId';

  static IsAccepted = 'IsAccepted';

  static MobileProtocolId = 'MobileProtocolId';

  static PurchaseRequestId = 'PurchaseRequestId';

  static ProjectPerformerOrderId = 'ProjectPerformerOrderId';
}
