import React, { useCallback, useMemo } from 'react';

import { buttonIcons, IconButtonProps, Toolbar } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

const ToolbarTemplate = (props: Props) => {
  const { tableState, onSubmitTable } = props;
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const handleSelectButtonClick = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable(tableState);
    } else {
      tableStreams.submitTable.push(tableState);
    }
  }, [tableStreams, onSubmitTable, tableState]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.check,
        title: 'Выбрать',
        code: 'select',
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
        onClick: handleSelectButtonClick,
      },
    ],
    [rowsAvailability, handleSelectButtonClick],
  );

  return <Toolbar buttons={buttons} />;
};

export const Component = React.memo(ToolbarTemplate);
