import React from 'react';

import { ListEdit, ReferenceFilter, ReferenceItem } from 'components';
import { Column } from 'components/ListEdit/model';

import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  title: string;
  rows: ReferenceItem[];
  onChange(items: ReferenceItem[]): void;
  modalTitle: string;
  refName: string;
  disabled?: boolean;
  columns?: Column<ReferenceItem>[];
  isToolbarHidden?: boolean;
  filters?: ReferenceFilter[];
  isLeftPanelHidden?: boolean;
  tooltipTitle?: string;
};

export function ItemListEdit(props: Props) {
  const {
    modalTitle,
    onChange,
    refName,
    rows,
    title,
    disabled,
    columns,
    isToolbarHidden,
    filters = [],
    isLeftPanelHidden,
    tooltipTitle,
  } = props;
  const listEditColumns =
    columns && columns.length > 0 ? columns : [{ label: '', formatValue: (row: ReferenceItem) => row.label }];

  return (
    <ListEdit
      title={title}
      visibleToolbarButtons={['add', 'delete']}
      rows={rows}
      onChange={onChange}
      withMessages
      isDeleteConfirmEnabled
      withoutHead
      columns={listEditColumns}
      specification={{
        mode: 'relationTableModal',
        relationTableModalTitle: modalTitle,
        modalTableRowConverter: row => ({
          id: row.id,
          label: row.label,
          element: {
            label: row.label,
            id: row.id,
            customFields: { parent: { id: row['id:parent'], label: row.parent } },
          },
        }),
        modalTableSpecification: GetReferenceElementList({
          requestData: {
            filters,
            name: refName,
          },
          isLeftPanelHidden,
        }),
      }}
      isToolbarDisabled={disabled}
      hideToolbar={isToolbarHidden}
      tooltipText={tooltipTitle}
    />
  );
}
