import { Author, FileInfo, Form } from 'types/models';
import { PublicationProject } from 'types/models/Form';

type Props = {
  isElectronic: boolean | undefined;
  electronicType: string | undefined;
  formFields: Form.Fields;
  projects: PublicationProject[];
  mobileRequests: Form.PublicationMobileRequest[];
  sourceMagazine: Form.Original | null;
  sourceMagazineRelease: Form.Original | null;
  type: string | undefined;
  authors: Author[];
  status: string;
  publicationInfo: Form.Publication | null;
  printArticle: Form.PrintArticle | null;
};

const formatToRequest = ({
  isElectronic,
  electronicType,
  formFields,
  projects,
  mobileRequests,
  sourceMagazine,
  sourceMagazineRelease,
  type,
  authors,
  status,
  publicationInfo,
  printArticle,
}: Props) => ({
  simpleFields: {
    type: type?.toUpperCase() || '',
    status,
    isElectronic: !!isElectronic,
    electronicType: (!!isElectronic && electronicType) || '',
    name: formFields.name.value || '',
    udk: formFields.udk.value as string,
    annotation: formFields.annotation.value || '',
    authorsKeyWords: formFields.authorsKeyWords.value || '',
    authorsCount: formFields.authorsCount.value || '',
    fractionalCount: formFields.fractionalScore.value || '',
    electronicMediaSource: formFields.electronicMediaSource.value as string,
    electronicSystemRequirements: formFields.electronicSystemRequirements.value as string,
    url: formFields.publicationLink.value as string,
    doi: formFields.doi.value as string,
    articleNumber: formFields.articleNumber.value as string,
    foreignName: formFields.foreignName.value as string,
    foreignAnnotation: formFields.foreignAnnotation.value as string,
    foreignAuthorsKeyWords: formFields.foreignAuthorsKeyWords.value as string,
    foreignAuthors: formFields.foreignAuthors.value as string,
    pageCount: formFields.pageCount.value || '',
  },
  attrIdFields: {
    grnti: formFields.grnti.value?.id,
    study: formFields.study.value?.id,
    textLanguage: formFields.textLanguage.value?.id,
    magazine: sourceMagazine ? sourceMagazine.id : '',
    original: publicationInfo?.original?.id,
    magazineRelease: sourceMagazineRelease ? sourceMagazineRelease.id : '',
    printArticle: printArticle?.id || '',
  },
  arrayFields: {
    translations: { translation: [] },
  },
  authors,
  mobileRequests: { mobileRequest: mobileRequests },
  projects,
  file: formFields.file.value as FileInfo,
});

export { formatToRequest };
