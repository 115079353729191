import React from 'react';
import { AwardsComponent } from 'features/Form/views';
import { Table as T, Author, Award } from 'types/models';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';

type Props = {
  members: Author[];
  awards: Award[];
  workMode: T.WorkMode;
  setAwards: (awards: Award[]) => void;
};

function FinancialSupport({ members, awards, setAwards, workMode }: Props) {
  return (
    <AwardsComponent
      disabled={workMode === 'viewMode'}
      recipients={members}
      awards={awards}
      setAwards={setAwards}
      participationTypeCode={ParticipationTypes.PARTICIPATION_CONTEST.code}
    />
  );
}

export const Component = React.memo(FinancialSupport);
