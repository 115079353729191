import { useCallback, useLayoutEffect, useMemo } from 'react';
import * as R from 'ramda';

import { Community, Form } from 'types/models';
import { Member } from 'features/SelectPerson';
import { Item } from 'types/models/common';
import { ReferenceItem } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { CommunityType } from 'utils/Enums';

type Props = {
  target: Community.Community | Form.Publication | null;
  selectedCommunityType?: Item | null;
  code?: string;
  entry: Community.Member | null;
  onFieldChange(row: Community.Member): void;
  roleOptions: Item[];
  cooperationTypeOptions?: Item[];
};

type MemberFields = keyof Community.Member;

export function useController({
  target,
  selectedCommunityType,
  code,
  entry,
  onFieldChange,
  roleOptions,
  cooperationTypeOptions,
}: Props) {
  const { settings } = useAppDataContext();

  const handlePersonFieldChange = useCallback(
    (value: Member) => {
      if (entry) {
        onFieldChange({ ...entry, ...value, scientist: value.person });
      }
    },
    [onFieldChange, entry],
  );

  const handleJoinDateFieldChange = useCallback(
    (value: string) => {
      const fieldName: MemberFields = 'joinDate';
      onFieldChange(R.set(R.lensProp(fieldName), value || '', entry!));
    },
    [onFieldChange, entry],
  );

  const handleLeaveDateFieldChange = useCallback(
    (value: string) => {
      const fieldName: MemberFields = 'leaveDate';
      onFieldChange(R.set(R.lensProp(fieldName), value || '', entry!));
    },
    [onFieldChange, entry],
  );

  const handleRoleFieldChange = useCallback(
    (value: Item) => {
      const fieldName: MemberFields = 'role';
      onFieldChange(R.set(R.lensProp(fieldName), value, entry!));
    },
    [onFieldChange, entry],
  );

  const handleCooperationTypeFieldChange = useCallback(
    (value: Item) => {
      const fieldName: MemberFields = 'cooperationType';
      onFieldChange(R.set(R.lensProp(fieldName), value, entry!));
    },
    [onFieldChange, entry],
  );

  const handleVakSpecialityFieldChange = useCallback(
    (value: ReferenceItem) => {
      const fieldName: MemberFields = 'vakSpeciality';
      onFieldChange(R.set(R.lensProp(fieldName), value, entry!));
    },
    [onFieldChange, entry],
  );

  const handleDescriptionFieldChange = useCallback(
    (value: string) => {
      const fieldName: MemberFields = 'description';
      onFieldChange(R.set(R.lensProp(fieldName), value, entry!));
    },
    [onFieldChange, entry],
  );

  const memberHeading = useMemo(() => {
    return [
      selectedCommunityType?.label,
      selectedCommunityType?.value === CommunityType.MAGAZINE_REDACTION ? target?.magazine?.name : code,
    ]
      .filter(x => x)
      .join(' ');
  }, [target, selectedCommunityType, code]);

  useLayoutEffect(() => {
    const roleFieldName: MemberFields = 'role';
    const cooperationTypeFieldName: MemberFields = 'cooperationType';
    if (roleOptions && !roleOptions.find(x => x.value === entry?.role?.value))
      onFieldChange(R.set(R.lensProp(roleFieldName), roleOptions[0], entry!));
    if (cooperationTypeOptions?.length && !cooperationTypeOptions.find(x => x.value === entry?.cooperationType?.value)) {
      onFieldChange(R.set(R.lensProp(cooperationTypeFieldName), cooperationTypeOptions[0], entry!));
    }
  }, [cooperationTypeOptions, entry, onFieldChange, roleOptions]);

  return {
    cooperationTypeOptions,
    handleCooperationTypeFieldChange,
    handleDescriptionFieldChange,
    handleJoinDateFieldChange,
    handleLeaveDateFieldChange,
    handlePersonFieldChange,
    handleRoleFieldChange,
    handleVakSpecialityFieldChange,
    roleOptions,
    settings,
    memberHeading,
  };
}
