import { Table } from 'types/models';
import { Color } from 'constants/colors';
import { parse } from 'date-fns';

import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Component as GridLeftPanelForThirdLevel } from './GridLeftPanelForThirdLevel';
import { formatStr } from 'utils/Constants/FormatStr';

export function GetSimlpleRntdListSpecification(deps: Table.Dependencies): Table.Specification {
  return {
    apiID: 'GetSimpleRntdList',
    templatesTableDependencies: deps.templatesTableDependencies,

    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}

function checkRowColor(entry: Table.Entry) {
  if (entry.Source === 'SecurityDocument') {
    return Color.success;
  } else if (parse(entry.DocumentEndDate, formatStr, new Date()).getTime() > Date.now()) {
    return Color.danger;
  } else {
    return '';
  }
}

export const GetRntdList: Table.Specification = {
  apiID: 'GetRntdList',
  header: {
    firstLevel: {
      title: 'Результаты научно-технической деятельности',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: GridLeftPanelForThirdLevel,
    },
  },
  highlightEntryPredicate: entry => ({ rowTheme: checkRowColor(entry) }),
};
