import { Parameter, StringValue } from 'types/models';

const queryBuilder = (requestIdsParameter: Parameter) => {
  const value = requestIdsParameter.value as StringValue;
  if (value) {
    const query = `&${requestIdsParameter.name}=${value}`;
    return query;
  }

  return '';
};

export default queryBuilder;
