import React, { useState } from 'react';

import { ListEditTable, Modal, FormComponent } from 'components';
import { HistoryEntry } from 'types/models';

type Props = {
  isOpen: boolean;
  onClose(): void;
  historyEntries: HistoryEntry[];
};

export function HistoryModal(props: Props) {
  const { isOpen, onClose, historyEntries } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="История изменений" size="large">
      <FormComponent.Wrapper>
        <ListEditTable
          columns={[
            { label: 'Действие', formatValue: x => x.action },
            { label: 'Изменен', formatValue: x => x.changed },
            { label: 'Изменил', formatValue: x => x.changer },
          ]}
          rows={historyEntries}
          selectedRowIndex={selectedRowIndex}
          selectRow={setSelectedRowIndex}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
}
