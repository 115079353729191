import React from 'react';

import { buttonIcons, ConfirmPopup, IconButton, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { TenderRequestType } from 'utils/Enums';

// eslint-disable-next-line max-len
import { Component as RequestViewTemplate } from 'features/Table/specifications/GetRequestList/LeftPanelForThirdLevel/modalTemplates/RequestViewTemplate';
// eslint-disable-next-line max-len
import { Component as NirRequestViewTemplate } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel/modalTemplates/NirRequestViewTemplate';
// eslint-disable-next-line max-len
import { Component as MobileRequestViewTemplate } from 'features/Table/specifications/GetMobileRequestList/LeftPanelForThirdLevel/modalTemplates/MobileRequestViewTemplate';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen, isViewFormOpen, setIsViewFormOpen, statusChangeInfo } = useController({
    tableState,
    customState,
  });

  const type = customState.type;

  return (
    <>
      {isViewFormOpen && !!type && (
        <>
          {type === TenderRequestType.PROGRAM_REQUEST && (
            <RequestViewTemplate
              isOpen={isViewFormOpen}
              onClose={() => setIsViewFormOpen(false)}
              id={tableState.selectedRows[0]?.id}
              relatedTableState={tableState}
            />
          )}
          {type === TenderRequestType.NIR_REQUEST && (
            <NirRequestViewTemplate
              isOpen
              onClose={() => setIsViewFormOpen(false)}
              relatedTableState={tableState}
              nirRequest={null}
              id={tableState.selectedRows[0]?.id}
            />
          )}
          {type === TenderRequestType.MOBILE_REQUEST && (
            <MobileRequestViewTemplate
              isOpen
              onClose={() => setIsViewFormOpen(false)}
              relatedTableState={tableState}
              id={tableState.selectedRows[0]?.id}
              type="NPR"
            />
          )}
        </>
      )}

      <Toolbar buttons={buttons} />

      {statusChangeInfo?.isApprovementModalOpen && (
        <ExpertEstimateModal
          isOpen={statusChangeInfo.isApprovementModalOpen}
          onClose={() => statusChangeInfo.setIsApprovementModalOpen(false)}
          onSubmit={statusChangeInfo.saveApprovement}
        />
      )}

      <ConfirmPopup
        title='Помощь в работе с разделом "Список заявок для эксперта"'
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        <div className="modal-help">
          <p>
            В таблице содержится список всех заявок поданных на конкурс для экспертизы. Фильтр <strong>С визой</strong> отображает
            список рассмотренных заявок. В столбце <strong>Согласование</strong> выводится дата, виза и замечание по заявке.
          </p>
          <h3>Что делать эксперту?</h3>
          <p>
            Для просмотра заявки нажмите на кнопку <strong>Просмотр</strong> <IconButton icons={buttonIcons.loop} hasNoHover />
          </p>
          <p>
            С помощью кнопки <strong>Экспертная оценка</strong> <IconButton icons={buttonIcons.approve} hasNoHover /> добавьте
            визу: Согласовано, Отклонить или На доработку.
          </p>
          <p>
            В поле <strong>Замечания</strong> добавьте текст в соответствии с регламентом проведения конкурса.
          </p>
          <p>
            <strong className="primary">Важно!</strong> Исправить экспертную оценку нельзя, но можно добавить новую.
            Завизированные экспертом заявки передаются на рассмотрение конкурсной комиссии. Конкурсная комиссия учитывает
            последнюю добавленную экспертом визу. По итогам работы конкурсной комиссии заявке присваивается следующий статус и
            заявка исключается из списка заявок для экспертизы.
          </p>
        </div>
      </ConfirmPopup>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
