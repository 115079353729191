import React from 'react';
import { DataGrid } from 'features/Table';
import { GetProjectParticipationEventList } from 'features/Table/specifications';

type Props = {
  projectId: string | null;
};
export function ResultsProjectEvents({ projectId }: Props) {
  return (
    <>
      <DataGrid specification={GetProjectParticipationEventList({ projectId: projectId || '-1' })} />
    </>
  );
}
