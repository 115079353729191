import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Color } from 'constants/colors';

type Props = {
  estimateId: string;
  viewMode?: boolean;
  handleRefreshEstimate?: Function;
  handleBalanceClick?: Function;
  isFiltersHidden?: boolean;
};

export const GetEstimatePositionList = (requestData: Props): Table.Specification => ({
  apiID: 'GetEstimatePositionList',
  requestData,
  isFiltersHidden: requestData.isFiltersHidden,
  header: {
    // firstLevel: {
    //   title: 'Расходы по статьям затрат',
    // },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel(requestData),
    },
  },
  highlightEntryPredicate: entry => ({ rowTheme: !entry.EMPTY_ROW && !entry.id ? Color.danger : '' }),
});
