import React, { memo } from 'react';

import { FormComponent, TextDateTime } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const DateBuilder = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { date, dateParameter, onChange, canBuild } = useController({ parameters, setParameterValueByName, parameterName });
  return (
    <>
      {canBuild && (
        <FormComponent.Line>
          <FormComponent.Field label={dateParameter?.description || 'Дата'} isRequired={dateParameter?.required}>
            <TextDateTime value={date} onChange={onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
};

const Component = memo(DateBuilder);

export { Component as DateBuilder };
