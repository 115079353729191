import { MipPartner } from 'types/models';
import { validate as validatePartner } from 'features/EditPartner/validate';
import { PartnerStateCode } from 'utils/Enums/PartnerStateCode';

export function validate(mipPartner: MipPartner.MipPartner) {
  const isTerminated = mipPartner.state?.customFields?.code === PartnerStateCode.TERMINATED;

  const isValidTerminatedState = isTerminated ? Boolean(mipPartner.terminatedDate) : true;
  return [...validatePartner(mipPartner), { isValid: isValidTerminatedState, invalidMessage: '"Дата ликвидации" не заполнена' }];
}
