import React from 'react';
import { block } from 'bem-cn';

import Select from 'react-select';
import useController from './controller';

import './style.scss';

export type Props = {
  enumeration: string;
  setColumnFilter: (nextValue: string) => void;
  value: string;
};

const b = block('grid-enum-filter');

export const EnumFilter = ({ enumeration, value, setColumnFilter }: Props) => {
  const { options, selectedValues, selectedValueLabels, onFieldChange } = useController({ enumeration, value, setColumnFilter });

  return (
    <div title={selectedValueLabels || 'Выберите значение'}>
      <Select
        isMulti
        options={options}
        controlShouldRenderValue
        value={selectedValues}
        onChange={onFieldChange}
        noOptionsMessage={() => 'Не найдено'}
        placeholder=""
        menuPortalTarget={document.body}
        maxMenuHeight={200}
        classNamePrefix={b()}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuShouldScrollIntoView={false}
        menuShouldBlockScroll
        menuPosition="fixed"
      />
    </div>
  );
};
