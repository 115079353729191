import React from 'react';

import { ConfirmPopup, ListEdit, FormComponent, Modal, Toolbar, Button, ButtonMode } from 'components';

import { Table } from 'types/models';
import { GetReferenceElementList } from 'features/Table/specifications/GetReferenceElementList';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    projectId,
    departments,
    setIsDepartmentsModalOpen,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isReplaceButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isDepartmentsModalOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    nextPublicationName,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleDepartmentsChange,
    buttons,
    departmentModalDisclaimerText,
    isReplaceConfirmPopupOpen,
    handleCloseReplaceConfirmPopup,
    handleConfirmReplaceConfirmPopup,
    isInReplaceMode,
    projectValidationModalTxt,
    isProjectValidationModalOpen,
    setIsProjectValidationModalModalOpen,
    handleSaveDepartmentsClick,
    isReportOpen,
    currentReport,
    onReportClose,
    settings,
  } = useController({ tableState });

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.ProjectCodeViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.ProjectCodeEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          replaceMode={isInReplaceMode}
          relatedTableState={tableState}
        />
      )}
      <modalTemplates.ProjectCodeAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        projectId={projectId || ''}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      <Modal
        isOpen={isDepartmentsModalOpen}
        onClose={() => setIsDepartmentsModalOpen(false)}
        title="Определение доступа к шифру по подразеделениям"
        size="medium"
      >
        <FormComponent.Template>
          <ControlPanel handleSave={handleSaveDepartmentsClick} />

          <FormComponent.Wrapper>
            <FormComponent.Description mode="info">{departmentModalDisclaimerText}</FormComponent.Description>

            <ListEdit
              title=""
              rows={departments}
              onChange={handleDepartmentsChange}
              withMessages
              isDeleteConfirmEnabled
              columns={[{ label: '', formatValue: row => row?.label || '' }]}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Список подразделений',
                modalTableRowConverter: row => ({
                  id: row.id,
                  label: row.label || '',
                }),
                modalTableSpecification: GetReferenceElementList({
                  requestData: {
                    filters: [],
                    name: 'RefDepartment',
                  },
                }),
              }}
            />
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный шифр? (${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {!isReplaceButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal isOpen={isReplaceConfirmPopupOpen} onClose={handleCloseReplaceConfirmPopup} title="Предупреждение" size="small">
          <FormComponent.Wrapper>
            <FormComponent.Template>
              <p>
                Выбранный шифр будет удален. Вместо него будет создан новый с сохранением смет и документов, связанных ранее с
                удаленным шифром. Создать шифр с тем же номером, как и удаленный в дальнейшем будет невозможно. <br />
                <br />
                Вы действительно хотите заменить выбранный шифр на новый?
              </p>
              <FormComponent.Actions>
                <Button mode={ButtonMode.primary} text="Продолжить" onClick={handleConfirmReplaceConfirmPopup} />
                <Button mode={ButtonMode.secondary} text="Отмена" onClick={handleCloseReplaceConfirmPopup} />
              </FormComponent.Actions>
            </FormComponent.Template>
          </FormComponent.Wrapper>
        </Modal>
      )}
      <ConfirmPopup
        title="Внимание"
        text={projectValidationModalTxt}
        isOpen={isProjectValidationModalOpen}
        onClose={() => setIsProjectValidationModalModalOpen(false)}
        onConfirm={() => setIsProjectValidationModalModalOpen(false)}
        okButtonText="Закрыть"
      />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        department={settings?.userDepartment}
      />
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
