import { NirRequest, Program, Publication } from 'types/models';
import { BasePerformerInfo } from 'types/models/NirRequest';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { getMaybeField } from '../commonConverters';

import { convertDocumentsToRequest } from '../converters';

export function convertNirRequestToXML(nir: NirRequest.NirRequest) {
  return {
    _attr: nir.id ? { id: nir.id } : {},
    ...convertDocumentsToRequest(nir.documents.filter(x => x.docType === 'local')),
    ...convertRequestDescriptionFields(nir),
    ...getMaybeField('EstimateExpencesJustification', nir.estimateExpencesJustification),
    ...getMaybeField('Lot', nir.lot?.id && { _attr: { id: nir.lot.id } }),
    ...getMaybeField('Tender', nir.tender?.id && { _attr: { id: nir.tender.id } }),
    CollectiveBaseDissertations: nir.collectiveBaseDissertations.length
      ? { BaseDissertation: getIdsArray(nir.collectiveBaseDissertations) }
      : {},
    CollectiveConferenceParticipations: nir.collectiveConferenceParticipations.length
      ? { Participation: getIdsArray(nir.collectiveConferenceParticipations) }
      : {},
    CollectiveEducationalPerformers: nir.collectiveEducationalPerformers.length
      ? { Performer: nir.collectiveEducationalPerformers.map(convertCollectivePerformer) }
      : {},
    CollectiveExpositionParticipations: nir.collectiveExpositionParticipations.length
      ? { Participation: getIdsArray(nir.collectiveExpositionParticipations) }
      : {},
    CollectiveForeignPerformers: nir.collectiveForeignPerformers.length
      ? { Performer: nir.collectiveForeignPerformers.map(convertCollectivePerformer) }
      : {},
    CollectiveProjects: nir.collectiveProjects.length ? { Project: getIdsArray(nir.collectiveProjects) } : {},
    CollectivePublications: nir.collectivePublications.length ? { Publication: getIdsArray(nir.collectivePublications) } : {},
    CollectiveRanPerformers: nir.collectiveRanPerformers.length
      ? { Performer: nir.collectiveRanPerformers.map(convertCollectivePerformer) }
      : {},
    CollectiveSecurityDocuments: nir.collectiveSecurityDocuments.length
      ? { SecurityDocument: getIdsArray(nir.collectiveSecurityDocuments) }
      : {},
    CollectiveYoungPerformers: nir.collectiveYoungPerformers.length
      ? { Performer: nir.collectiveYoungPerformers.map(convertCollectivePerformer) }
      : {},
    CriticalTechnologies: nir.criticalTechnologies.length ? { CriticalTechnology: getIdsArray(nir.criticalTechnologies) } : {},
    EstimatePositions: nir.estimatePositions.length ? { Position: nir.estimatePositions.map(convertEstimatePosition) } : {},
    ExpectedResults: nir.expectedResults.length ? { ExpectedResult: nir.expectedResults.map(convertExpectedResult) } : {},
    Grntis: nir.grntis.length ? { Grnti: getIdsArray(nir.grntis) } : {},
    Indicators: nir.indicators.length ? { Result: nir.indicators.map(x => convertIndicator(x, nir.stages.length)) } : {},
    Indices: nir.indices.length ? { Result: nir.indices.map(x => convertIndicator(x, nir.stages.length)) } : {},
    IsPrepareSave: 'false',
    Name: nir.name,
    NotAffiliatedCollectivePublications: nir.notAffiliatedCollectivePublications.length
      ? { Publication: nir.notAffiliatedCollectivePublications.map(convertNotAffiliatedPublicaton) }
      : {},
    NotAffiliatedDescriptionPublications: nir.notAffiliatedDescriptionPublications.length
      ? { Publication: nir.notAffiliatedDescriptionPublications.map(convertNotAffiliatedPublicaton) }
      : {},
    NtrStrategies: nir.ntrStrategies.length ? { NtrStrategy: getIdsArray(nir.ntrStrategies) } : {},
    Oecds: nir.oecds.length ? { Oecd: getIdsArray(nir.oecds) } : {},
    Performers: nir.performers.length ? { Performer: nir.performers.map(convertPerformer) } : {},
    Pnis: nir.pnis.length ? { Pni: getIdsArray(nir.pnis) } : {},
    Pnrs: nir.pnrs.length ? { Pnr: getIdsArray(nir.pnrs) } : {},
    Publications: nir.publications.length ? { Publication: getIdsArray(nir.publications) } : {},
    Specification: nir.specification,
    Stages: nir.stages.length ? { Stage: nir.stages.map(convertStageToXML) } : {},
    StageTasks: nir.stageTasks.length ? { StageTask: nir.stageTasks.map(convertStageTask) } : {},
    TenderType: nir.tenderType.value,
    Udks: nir.udks.length ? { Udk: getIdsArray(nir.udks) } : {},
    CollectiveForeignProjects: { ForeignProject: getIdsArray(nir.collectiveForeignProjects || []) },
    CollectiveForeignSecurityDocuments: { ForeignSecurityDocument: getIdsArray(nir.collectiveForeignSecurityDocuments || []) },
  };
}

export function convertCollectivePerformer(performer: BasePerformerInfo) {
  return {
    _attr:
      performer.performerId !== null
        ? { id: performer.performerId }
        : { personId: performer.personId, roleId: performer.role?.value },
  };
}

function convertNotAffiliatedPublicaton(publication: Publication) {
  return {
    _attr: publication.id ? { id: publication.id } : {},
    Type: publication.type.value,
    BibliographicRecord: publication.bibliographicRecord,
    ...getMaybeField('Doi', publication.doi),
    ...getMaybeField('ScopusCitations', publication.scopusCitations),
    ...getMaybeField('WosCitations', publication.wosCitations),
    ...getMaybeField('ScopusQuartile', publication.scopusQuartile),
    ...getMaybeField('WosQuartile', publication.wosQuartile),
  };
}

function convertRequestDescriptionFields(nir: NirRequest.NirRequest) {
  return {
    ...(nir.department?.id ? { Department: { _attr: { id: nir.department.id } } } : {}),
    ...getMaybeField('DepartmentTxt', nir.departmentTxt ? nir.departmentTxt : nir.department?.label ?? ''),
    ...getMaybeField('EName', nir.englishName),
    ...getMaybeField('ELeader', nir.englishLeader),
    ...getMaybeField('EDepartmentTxt', nir.englishDepartmentTxt),
    ...getMaybeField('KeyWords', nir.keyWords),
    ...getMaybeField('EKeyWords', nir.englishKeyWords),
    ...getMaybeField('Annotation', nir.annotation),
    ...getMaybeField('EAnnotation', nir.englishAnnotation),
    ...getMaybeField('Relevance', nir.relevance),
    ...getMaybeField('ERelevance', nir.englishRelevance),
    ...getMaybeField('Goal', nir.goal),
    ...getMaybeField('EGoal', nir.englishGoal),
    ...getMaybeField('ResearchMethods', nir.researchMethods),
    ...getMaybeField('ScientificProblem', nir.scientificProblem),
    ...getMaybeField('ProblemResearchState', nir.problemResearchState),
    ...getMaybeField('ResearchPracticalSignificance', nir.researchPracticalSignificance),
    ...getMaybeField('EconomicSectorInterraction', nir.economicSectorInterraction),
    ...getMaybeField('InterdisciplinaryAproachJustification', nir.interdisciplinaryAproachJustification),
    ...getMaybeField('CollaborationParticipation', nir.collaborationParticipation),
    ...getMaybeField('PnrJustification', nir.pnrJustification),
    ...getMaybeField('ScientificGroundwork', nir.scientificGroundwork),
  };
}

function convertStageTask(result: NirRequest.StageTask) {
  return {
    _attr: result.id ? { id: result.id } : {},
    Year: result.year,
    ...(result.task ? { Task: result.task } : {}),
    ...(result.englishTask ? { ETask: result.englishTask } : {}),
  };
}

function convertExpectedResult(result: NirRequest.ExpectedResult) {
  return {
    _attr: result.id ? { id: result.id } : {},
    Year: result.year,
    ...(result.result ? { Result: result.result } : {}),
  };
}

export function convertEstimatePosition(position: NirRequest.EstimatePosition) {
  return {
    _attr: position.id ? { id: position.id } : {},
    KindConsumption: { _attr: { id: position.kindConsumption.id } },
    Amount: position.amount || '0.00',
    ...(position.note ? { Note: position.note } : {}),
  };
}

export function convertIndicator(indicator: Program.Indicator, amountStages: number) {
  return {
    _attr: indicator.id ? { id: indicator.id } : {},
    ResultItem: { _attr: { id: indicator.refResultItem.id } },
    ...(indicator.firstYearPlan && amountStages > 0 ? { FirstYearPlan: indicator.firstYearPlan } : {}),
    ...(indicator.secondYearPlan && amountStages > 1 ? { SecondYearPlan: indicator.secondYearPlan } : {}),
  };
}

function convertStageToXML(stage: NirRequest.Stage) {
  return {
    _attr: stage.id ? { id: stage.id } : {},
    Name: stage.name,
    Year: stage.year,
    Amount: stage.amount,
    UniversityEffect: stage.universityEffect,
    RegionalEffect: stage.regionalEffect,
    NationalEffect: stage.nationalEffect,
  };
}

function getIdsArray(items: { id: string | null }[]) {
  return items.filter(x => !!x.id).map(x => ({ _attr: { id: x.id } }));
}

function convertPerformer(performer: NirRequest.Performer) {
  const historicalSlice = getHistoricalSliceXML(performer);

  return {
    _attr: { id: performer.id },
    ...historicalSlice,
    ...(performer.person ? { Person: { _attr: { id: performer.person.id } } } : {}),
    Role: performer.role?.value,
    ...getMaybeField('Awards', performer.awards),
    ...getMaybeField('KeyWords', performer.keyWords),
    ...getMaybeField('ScientificGroundwork', performer.scientificGroundwork),
    ...getMaybeField('TotalPublicationsQty', performer.totalPublicationQty),
    ...getMaybeField('Q1PublicationsQty', performer.q1PublicationQty),
    ...getMaybeField('PrimePublications', performer.primePublications),
    ...getMaybeField('CollaborationParticipation', performer.collaborationParticipation),
    ...getMaybeField('ForeignPerformerQ1Q2PublicationQty', performer.foreignPerformerQ1Q2PublicationQty),
    ...getMaybeField('Rids', performer.rids),
    ...getMaybeField('Projects', performer.projects),
    ...getMaybeField('Note', performer.note),
    ...getMaybeField('EconomicContractParticipationExperience', performer.economicContractParticipationExperience),
  };
}
