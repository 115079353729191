import { Table } from 'types/models';
import { Filters } from './filters';
import { TenderRequestType } from 'utils/Enums';

type Props = {
  isPc?: boolean;
  tenderRequestType?: TenderRequestType;
  selectRow?(r: Table.Entry | null): void;
};

type RequestData = {
  isPc?: boolean;
  tenderRequestType?: TenderRequestType;
};

export function GetTenderListForRequest({ isPc, tenderRequestType, selectRow }: Props): Table.Specification<{}, RequestData> {
  return {
    apiID: 'GetTenderListForRequest',
    header: {
      firstLevel: {
        ...(isPc ? {} : { Panel: Filters }),
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
    requestData: { isPc, tenderRequestType },
    onSubmitTable: e => selectRow?.(e.selectedRows[0] || null),
  };
}
