import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { ProjectInfoModal } from 'features/ProjectInfoModal';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isAddButtonDisabled,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    project,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  } = useController({ tableState });
  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.RntdViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.RntdEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isAddButtonDisabled && (
        <modalTemplates.RntdAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
          name={nextPublicationName}
        />
      )}
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный результат? (${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ProjectInfoModal
        isOpen={isProjectInfoModalOpen}
        onClose={() => setIsProjectInfoModalOpen(false)}
        project={project ?? null}
      />
      <ConfirmPopup title="Помощь в разделе РИД" isOpen={isHelpFormOpen} onClose={handleTemplateCloseHelpForm} size="medium">
        <div className="modal-help">
          <p>
            <strong className="primary">
              Раздел предназначен для решения задач по коммерциализации и трансфера технологий.{' '}
            </strong>
          </p>
          <p>
            <strong>РИД</strong> — результат интеллектуальной деятельности в научно-технической сфере: изобретение, промышленные
            образцы, базы данных, программные продукты, способ осуществления профессиональной деятельности и прочие объекты,
            имеющие действительную или потенциальную коммерческую ценность.
          </p>
          <h3>Работа фильтров</h3>
          <ul>
            <li>
              <strong>РИД</strong> – список РИД
            </li>
            <li>
              <strong>Приняты к коммерциализации</strong> — показывает РИД, в которых есть заключение о возможности
              коммерциализации РИД (на вкладке Коммерциализация)
            </li>
            <li>
              <strong>Новые ОИС</strong> — записи о новых зарегистрированных объектах интеллектуальной собственности (в таблице
              записи выделены зелёным цветом)
            </li>
          </ul>
          <strong>Подсветка записей в таблице:</strong>
          <ul>
            <li>Зелёный цвет — для новых зарегистрированных объектах интеллектуальной собственности</li>
            <li>Красный цвет — для РИД, у которых закончился срок действия охранного документа</li>
          </ul>
          <br />
          <strong>Особенности заполнения информации о РИД</strong>
          <ul>
            <li>
              Для добавления РИД используется процедура создания записи на основе метаданных охранного документа (патента,
              свидетельства, ноу-хау), а так же ОИС, не требующих регистрации. Именно поэтому в таблице отображаются
              зарегистрированные сотрудниками отдела интеллектуальной собственности новые ОИС.
            </li>
            <li>
              Чтобы добавить РИД необходимо выделить в таблице новый ОИС и нажать кнопку <strong>Добавить</strong> — откроется
              форма добавления <strong>РИД</strong> и на вкладки <strong>О РИД и Классификаторы</strong> скопируются основные
              данные из описания ОИС.
            </li>
            <li>Поле Код заполняется в соответствии с принятыми внутренними правилами учётной политики.</li>
            <li>
              В случае, если для ОИС указана связь с научным проектом, то с помощью кнопки&nbsp;
              <strong>Посмотреть описание проекта</strong> — откроется окно с информацией о проекте (аннотация, цели, ожидаемые
              результаты)
            </li>
            <li>
              Вкладка Коммерциализация предназначена для заключения о возможности коммерциализации. Формулировки Заключения- в
              пополняемом справочнике.
            </li>
            <li>
              Вкладка Участие в выставках – содержит информацию о выставках и полученных наградах за научно- техническую
              разработку. Источник данных — участие в выставке в пункте меню Мероприятия, Участие в выставках.
            </li>
            <li>
              Общий список разработок, представленных на выставках, — в пункте меню Коммерциализация, Разработки (выставочные
              экспонаты)
            </li>
          </ul>
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
