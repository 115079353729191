import { convertFileToXML, getMaybeField } from 'services/BackendAPI/configurations/commonConverters';
import { SecurityDocumentPaymentSaveInput } from './types';

export function convertSecurityDocumentPaymentToXML(p: SecurityDocumentPaymentSaveInput) {
  return {
    _attr: p.id ? { id: p.id } : {},
    SecurityDocument: p.securityDocument?.id ? { _attr: { id: p.securityDocument.id } } : {},
    PaymentPurpose: p.paymentPurpose?.id ? { _attr: { id: p.paymentPurpose.id } } : {},
    PayBeforeDate: p.payBeforeDate,
    ...getMaybeField('PayDate', p.payDate),
    Amount: p.amount,
    ...convertFileToXML('File', p.file),
  };
}
