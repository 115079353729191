import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';
import { Table } from 'types/models';
import { DataGrid } from 'features/Table';
import {
  GetPersonalColleaguePublicationList,
  GetPersonalColleagueParticipationList,
  GetPersonalColleagueEventList,
  GetPersonalColleagueRequestAndProjectList,
  GetPersonalColleagueSecurityDocumentList,
} from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function Toolbar({ tableState }: Props) {
  const { currentPerson } = useAppDataContext();
  const {
    activePerson,
    buttons,
    isPublicationListOpen,
    handleClosePublicationsForm,
    isParticipationListOpen,
    handleCloseParticipationsForm,
    isEventListOpen,
    handleCloseEventsForm,
    isRequestListOpen,
    handleCloseRequestsForm,
    isDocumentListOpen,
    handleCloseDocumentsForm,
  } = useController({ tableState });
  return (
    <>
      <Modal
        isOpen={isPublicationListOpen}
        onClose={handleClosePublicationsForm}
        title="Список совместных публикаций"
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetPersonalColleaguePublicationList({
            colleagueScientistId: activePerson,
            scientistId: currentPerson?.id || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isParticipationListOpen}
        onClose={handleCloseParticipationsForm}
        title="Список соучастий в мероприятиях"
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetPersonalColleagueParticipationList({
            colleagueScientistId: activePerson,
            scientistId: currentPerson?.id || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isEventListOpen}
        onClose={handleCloseEventsForm}
        title="Список совместно организованных мероприятий"
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetPersonalColleagueEventList({
            colleagueScientistId: activePerson,
            scientistId: currentPerson?.id || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isRequestListOpen}
        onClose={handleCloseRequestsForm}
        title="Список заявок и проектов НИР и ОКР"
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetPersonalColleagueRequestAndProjectList({
            colleagueScientistId: activePerson,
            scientistId: currentPerson?.id || '',
          })}
        />
      </Modal>

      <Modal
        isOpen={isDocumentListOpen}
        onClose={handleCloseDocumentsForm}
        title="Список объектов интеллектуальной собственности"
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetPersonalColleagueSecurityDocumentList({
            colleagueScientistId: activePerson,
            scientistId: currentPerson?.id || '',
          })}
        />
      </Modal>

      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
