import { Table } from 'types/models';

import { EmployeeNominee, EmployeePayment } from 'types/models/Payment';

export function getMockEmployeeNominee(): EmployeeNominee {
  return {
    id: null,
    job: null,
    employee: null,
    amountMoney: 0,
  };
}

export function getInitialAmount(fund: Table.Entry) {
  const factIncome = Number(fund.factIncome);
  const planIncome = Number(fund.planIncome);
  const factExpense = Number(fund.factExpense);
  const planExpense = Number(fund.planExpense);
  return factIncome + planIncome - factExpense - planExpense;
}

export function getInitialEmployeePayment(fund: Table.Entry): EmployeePayment {
  return {
    amountMoney: getInitialAmount(fund),
    documents: [],
    nominees: [],
    status: { value: 'DRAFT', label: 'Черновик' },
    id: null,
    fund: {
      commonSumm: getInitialAmount(fund),
      month: fund.month,
      quarter: { value: fund['id:quarter'], label: fund.quarter },
      type: { value: fund['id:type'], label: fund.type },
      departmentId: fund['id:faculty'],
      year: String(new Date().getFullYear()),
    },
  };
}

export function calcRemainderAmount(employeePayment: EmployeePayment | null) {
  if (!employeePayment) {
    return 0;
  }

  const commonAmount = employeePayment.amountMoney;
  const sumAmounts = employeePayment.nominees.reduce((acc, nominee) => nominee.amountMoney + acc, 0);
  return commonAmount - sumAmounts;
}

export function calcShare(amountMoney: number, commonAmountMoney: number) {
  return (amountMoney / commonAmountMoney).toFixed(4);
}

export function checkIsBudgetOver(fund: Table.Entry | null, employeePayments: Table.Entry[]) {
  if (!fund) {
    return false;
  }
  const paidPayments = employeePayments.filter(x => x['id:status'] !== 'DRAFT');
  const paymentsSum = paidPayments.reduce((acc, cur) => acc + Number(cur.employeesAmount), 0);
  const factIncome = Number(fund?.factIncome ?? 0);

  if (factIncome > 0) {
    return Number(fund.factIncome) - paymentsSum === 0;
  }

  const planSumExpense = Number(fund.planExpensePaid) + Number(fund.planExpenseRejected) + Number(fund.planExpenseSent);
  return Number(fund.planIncome) - planSumExpense <= 0;
}
