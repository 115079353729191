import React from 'react';
import block from 'bem-cn';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const b = block('toolbar-form-template');

const ScientistViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetPublication',
    template: 'ScientistDataForm',
    type: 'magazine',
    viewMode: true,
    id: String(id),
    relatedTableState,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Список публикаций автора для расчёта совокупного импакт-фактора"
      size="full"
      viewMode
    >
      <div className={b()}>
        <div style={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Контент в разработке
        </div>
        {false && <Form look={look} onClose={onClose} />}
      </div>
    </Modal>
  );
};

export const Component = React.memo(ScientistViewTemplate);
