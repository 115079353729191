import { Parameter, ReferenceItemValue } from 'types/models';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateReferenceParameter = (referenceParameter: Parameter): ValidatorResult => {
  const { required } = referenceParameter;
  const value = referenceParameter.value as ReferenceItemValue;

  if (required) {
    const isValid = Boolean(value?.id);
    return { isValid, message: isValid ? '' : `Поле не заполненно "${referenceParameter.description}"` };
  }

  return { isValid: true, message: '' };
};

export default validateReferenceParameter;
