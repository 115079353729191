import React from 'react';

import { Button, ButtonMode, Checkbox, FormComponent, TextDateTime } from 'components';

import { Project } from 'types/models/Project';
import useController from './useController';

type Props = {
  onClose: () => void;
  projectId: string;
  submitCallback: (preparedProject: Project) => void;
};

export const Component = ({ onClose, submitCallback, projectId }: Props) => {
  const {
    isProjectLoading,
    checkboxSettings,
    clonePerforemersData,
    period,
    onPeriodChange,
    onCheckboxSettingValueChange,
    onSubmit,
  } = useController({
    onClose,
    submitCallback,
    projectId,
  });
  return (
    <>
      <FormComponent.Template>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Период проекта" isRequired>
              <TextDateTime
                isRange
                value={`${period.from || ''}-${period.to || ''}`}
                onChange={(value: string) => {
                  const dates = value.split('-');
                  onPeriodChange(dates[0] || '', dates[1] || '');
                }}
                isDisabled={isProjectLoading}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="">
              <strong>Укажите, что необходимо копировать в новый проект</strong>
            </FormComponent.Field>
          </FormComponent.Line>

          {!!clonePerforemersData.leader && (
            <FormComponent.Line>
              <FormComponent.Field label="">
                <Checkbox
                  label={`Ответственного исполнителя: "${clonePerforemersData.leader.fio}"`}
                  checked={checkboxSettings.leader.isChecked}
                  onChange={onCheckboxSettingValueChange('leader')}
                  isDisabled={isProjectLoading}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}

          {!!clonePerforemersData.responsiblePerformer && (
            <FormComponent.Line>
              <FormComponent.Field label="">
                <Checkbox
                  label={`Ответственного исполнителя: "${clonePerforemersData.responsiblePerformer.fio}"`}
                  checked={checkboxSettings.responsiblePerformer.isChecked}
                  onChange={onCheckboxSettingValueChange('responsiblePerformer')}
                  isDisabled={isProjectLoading}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {!!clonePerforemersData.performers.length && (
            <FormComponent.Line>
              <FormComponent.Field label="">
                <Checkbox
                  label={`Исполнителей, всего ${clonePerforemersData.performers.length} чел.`}
                  checked={checkboxSettings.performers.isChecked}
                  onChange={onCheckboxSettingValueChange('performers')}
                  isDisabled={isProjectLoading}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {!!clonePerforemersData.assistances.length && (
            <FormComponent.Line>
              <FormComponent.Field label="">
                <Checkbox
                  label={`Вспомогательный персонал, всего ${clonePerforemersData.assistances.length} чел.`}
                  checked={checkboxSettings.assistances.isChecked}
                  onChange={onCheckboxSettingValueChange('assistances')}
                  isDisabled={isProjectLoading}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}

          <FormComponent.Actions>
            <Button mode={ButtonMode.primary} text="Продолжить" onClick={onSubmit} isDisabled={isProjectLoading} />
            <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} isDisabled={isProjectLoading} />
          </FormComponent.Actions>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </>
  );
};
