import { ReferenceItem } from 'components';
import { Author, Form, Document, Award } from 'types/models';
import { Department, ParticipationEventSource, Participation } from 'types/models/Participation';
import { ParticipationInput } from 'services/BackendAPI/configurations/participation/types';

type Props = {
  formFields: Form.Fields;
  members: Author[];
  event: ParticipationEventSource | null;
  documents: Document[];
  participationData: Participation | null;
  participationMessageType: ReferenceItem;
  participationMessage: string;
  currentParticipationId: string | null;
  withMessage?: boolean;
  awards: Award[];
  departments?: Department[];
};

const formatToRequest = ({
  formFields,
  members,
  event,
  documents,
  participationData,
  participationMessageType,
  participationMessage,
  currentParticipationId,
  withMessage,
  awards,
  departments,
}: Props): ParticipationInput => ({
  participationId: currentParticipationId || undefined,
  simpleFields: {
    participationProcedure: 'WITHOUT_REQUEST',
    teamName: formFields.teamName.value as string,
    isInTeam: false,
    IsCollectiveExhibit: false,
    note: formFields.note.value as string,
  },
  attrIdFields: {
    event: event?.id as string,
    form: formFields.form?.value?.id,
  },
  otherFields: {
    documents,
    departments: departments || participationData?.departments || undefined,
    awards,
    members,
    feedLine: withMessage
      ? {
          messageId: participationMessageType.id as string,
          detail: participationMessage as string,
        }
      : undefined,
    financingProjects: [],
    presentationProject: null,
  },
});

export { formatToRequest };
