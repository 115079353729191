import { Table } from 'types/models';

import { Toolbar } from './Toolbar/Toolbar';

import { makeUseCustomController, State } from './makeUseCustomController';

type RequestData = {
  enterpriseCustomerIds: string[];
  enterpriseIds: string[];
};

export function GetPartnerAffilatedPersonList({
  enterpriseCustomerIds,
  enterpriseIds,
}: RequestData): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetPartnerAffilatedPersonList',
    header: {
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    requestData: { enterpriseCustomerIds, enterpriseIds },
    useCustomController: makeUseCustomController(enterpriseCustomerIds, enterpriseIds),
  };
}
