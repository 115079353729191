import React from 'react';
import { Column } from 'types/models/Table';
import { FILTER_STRUCRURE } from 'features/Table/helpers';
import { SetColumnFilterArguments } from '../types';
import { BooleanFilter } from './BooleanFilter';
import { NumericFilter } from './NumericFilter';
import { EnumFilter } from './EnumFilter';
import { TextFilter } from './TextFilter';
import { ReferenceFilter } from './ReferenceFilter';
import { TextDateTime } from 'components';

type Props = {
  column: Column;
  setColumnFilter: (setColumnFilterArguments: SetColumnFilterArguments) => void;
};

export const Filter = ({ column, setColumnFilter }: Props) => {
  const isRange = column.filterStructure === FILTER_STRUCRURE.range;

  const dateDelimiter = '#';
  const emptyDate = 'EMPTY';

  const prepareFromFilter = (value: string) => {
    if (isRange) {
      return value
        .split(dateDelimiter)
        .map(x => (x === emptyDate ? '' : x))
        .join('-');
    }
    return value ?? '';
  };

  const prepareToFilter = (value: string) => {
    if (!value) {
      return '';
    }
    if (isRange) {
      const dates = value.split('-').map(x => x || emptyDate);

      if (dates.every(i => i === emptyDate)) {
        return '';
      }
      value = dates.join(dateDelimiter);
    }
    return value;
  };

  switch (column.type) {
    case 'STRING':
    case 'HTML':
      return (
        <TextFilter
          value={column.filter ?? ''}
          setColumnFilter={nextValue => setColumnFilter({ columnName: column.name, nextValue: nextValue.trim() })}
        />
      );
    case 'INTEGER':
    case 'MONEY':
      return (
        <NumericFilter
          columnType={column.type}
          value={column.filter ?? ''}
          filterStructure={column.filterStructure}
          setColumnFilter={nextValue => setColumnFilter({ columnName: column.name, nextValue })}
        />
      );
    case 'DOUBLE':
      return (
        <NumericFilter
          columnType={column.type}
          value={column.filter ?? ''}
          filterStructure={column.filterStructure}
          setColumnFilter={nextValue => setColumnFilter({ columnName: column.name, nextValue })}
          columnPrecision={column.precision}
        />
      );
    case 'BOOLEAN':
      return (
        <BooleanFilter
          value={column.filter ?? ''}
          setColumnFilter={nextValue => setColumnFilter({ columnName: column.name, nextValue })}
        />
      );
    case 'ENUMERATION':
      return (
        <EnumFilter
          value={column.filter ?? ''}
          enumeration={column.enumeration}
          setColumnFilter={nextValue => setColumnFilter({ columnName: column.name, nextValue })}
        />
      );
    case 'DATE':
      return (
        <TextDateTime
          isRange={isRange}
          rangeButtons={['singeDate', 'toInfinite', 'fromInfinite']}
          value={prepareFromFilter(column.filter ?? '')}
          onChange={value => setColumnFilter({ columnName: column.name, nextValue: prepareToFilter(value) })}
          customStyles={{ maxWidth: '100%' }}
        />
      );
    case 'TIME':
      return (
        <TextDateTime
          type={['time']}
          isRange={isRange}
          value={prepareFromFilter(column.filter ?? '')}
          onChange={value => setColumnFilter({ columnName: column.name, nextValue: prepareToFilter(value) })}
          customStyles={{ maxWidth: '100%' }}
        />
      );
    case 'DATETIME':
      return (
        <TextDateTime
          type={['date', 'time']}
          isRange={isRange}
          rangeButtons={['singeDate', 'toInfinite', 'fromInfinite']}
          value={prepareFromFilter(column.filter ?? '')}
          onChange={value => setColumnFilter({ columnName: column.name, nextValue: prepareToFilter(value) })}
          customStyles={{ maxWidth: '100%' }}
        />
      );
    case 'REFERENCE': {
      if (column.filterStructure === 'LIST') {
        return (
          <ReferenceFilter
            caption={column.caption}
            value={column.filter ?? ''}
            reference={column.reference}
            setColumnFilter={nextValue => setColumnFilter({ columnName: column.name, nextValue })}
          />
        );
      }

      return (
        <TextFilter
          value={column.filter ?? ''}
          setColumnFilter={nextValue => setColumnFilter({ columnName: column.name, nextValue: nextValue.trim() })}
        />
      );
    }
    default:
      return <span>Фронтенд не поддерживает тип колонки</span>;
  }
};
