import React, { useMemo } from 'react';
import { buttonIcons, IconButton, IconButtonProps } from 'components';

export function AdminHelpContents() {
  const button = useMemo<IconButtonProps>(() => {
    return {
      icons: buttonIcons.activation,
      title: 'Передать на рассмотрение',
      isDisabled: false,
      onClick: () => {},
    };
  }, []);

  return (
    <span>
      <h2>Заявки на закупки для научных проектов</h2>
      Раздел содержит список заявок на закупки для научных проектов. Пользователь, который имеет возможность оформить заявку:
      <ul>
        <li>
          <strong>руководитель</strong> или <strong>ответственный исполнитель</strong> проекта — через свой личный кабинет на
          вкладке НИОКР, Закупки;
        </li>
        <li>
          сотрудники университета — <strong>ответственные лица по работе с заявками на закупки для научных проектов</strong>;
          имеют право доступа к функциональному модулю Закупки.
        </li>
      </ul>
      <h2>Жизненный цикл Заявки на закупку</h2>
      <h3>Этап 1. Добавление заявки на закупку</h3>
      Заполнение формы Заявки, добавление файла к заявке (например, требования к поставщику, техническое задание на выполнение
      работ и пр.)
      <ul>
        <li>
          Заявка, не зависимо от статуса, будет отображаться в Личном кабинете у руководителя проекта, ответственного исполнителя
          проекта и материально-ответственного лица.
        </li>
      </ul>
      <h3>
        Этап 2. Отправка заявки на рассмотрение с помощью КНОПКИ <IconButton {...button} />
      </h3>
      <ul>
        <li>
          Заявка меняет статус <strong>Черновик → На рассмотрении</strong> и система фиксирует дату подачи заявки на рассмотрение.
        </li>
        <li>
          Ответственное лицо за сбор заявок на закупки зарегистрирует заявку, присвоив ей номер в соответствии с внутренним
          регламентом.
        </li>
        <li>Заявку просматривают эксперты и добавляют экспертное замечание (визу).</li>
        <li>
          Заявка в статусе <strong>На рассмотрении</strong> не доступна для редактирования.
        </li>
      </ul>
      <h3>Этап 3. При необходимости, заявка может быть переведена в статус На доработку</h3>
      Внесение исправлений по замечаниям, и повторная отправка заявителем заявки на рассмотрение.
      <br />
      <br />
      <h3>Этап 4.Перевод заявки в статус Отклонена или В работе (поставщик).</h3>В соответствии с регламентом на основании
      имеющихся виз и экспертных замечаний Ответственное лицо по заявкам переводит заявку из статуса&nbsp;
      <strong>На рассмотрении → Отклонена или В работе (поставщик).</strong>
      <br />
      <br />
      <h3>Этап 5. Перевод заявки в статус Выполнена</h3>В статус <strong>Выполнена</strong> заявка переводится после приемки
      выполненных работ/услуги или поставки товара.
      <br />
      <br />
      <strong className="primary">Важно!</strong>
      <br />
      Сообщения, добавленные при переводе в любой статус, отображаются в столбце <strong>Сообщение</strong> в&nbsp;
      <strong>Журнале сообщений</strong> закупки. Сообщения, добавленные по кнопкам:&nbsp;
      <strong>Виза управляющего комитета, Виза ценовой комиссии</strong> и <strong>Виза управления логистики</strong>&nbsp;
      отображаются в столбце <strong>Замечание эксперта</strong> в <strong>Журнале сообщений</strong> закупки. В таблице&nbsp;
      <strong>Список закупок</strong> в столбце <strong>Сообщение</strong> отображается последнее добавленное сообщение, в
      столбце&nbsp;
      <strong>Замечание эксперта</strong> — все визы: Управляющего комитета, Ценовой комиссии и Управления логистики.&nbsp;
      <br />
      <br />
      <h2>Роли и права пользователей функционального модуля:</h2>
      <table className="list-edit-table">
        <thead>
          <tr className="list-edit-table__row list-edit-table__row_head">
            <th className="list-edit-table__column" style={{ width: '30%' }}>
              Роль
            </th>
            <th className="list-edit-table__column" style={{ width: '70%' }}>
              Право
            </th>
          </tr>
        </thead>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">Заявитель (руководитель или ответственный исполнитель проекта)</td>
          <td className="list-edit-table__column">
            <ol>
              <li>Черновик: Создание, Редактирование, удаление</li>
              <li>На доработку: Редактирование</li>
              <li>Перевод в статус "На рассмотрение"</li>
            </ol>
          </td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">Ответственное лицо по заявке</td>
          <td className="list-edit-table__column">
            <ol>
              <li>Черновик: Создание, Редактирование, удаление</li>
              <li>На доработку: Редактирование</li>
              <li>Перевод в статус "На рассмотрение"</li>
              <li>Перевод в статус "На доработку"</li>
              <li>Перевод в статус "Отклонена"</li>
              <li>Перевод в статус "Выполнена"</li>
              <li>Перевод в статус "В работе (поставщик)"</li>
            </ol>
          </td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">Управляющий комитет</td>
          <td className="list-edit-table__column">На рассмотрение: Виза управляющего комитета</td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">Ценовая комиссия</td>
          <td className="list-edit-table__column">На рассмотрение: Виза ценовой комиссии</td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">Управление логистики</td>
          <td className="list-edit-table__column">На рассмотрение: Виза управления логистики</td>
        </tr>
      </table>
      <br />
      <h2>Процедуры по заявкам на закупку:</h2>
      <table className="list-edit-table">
        <thead>
          <tr className="list-edit-table__row list-edit-table__row_head">
            <th className="list-edit-table__column">Статус закупки</th>
            <th className="list-edit-table__column">Доступные действия с заявкой в текущем статусе</th>
            <th className="list-edit-table__column">Доступный статус для перевода</th>
          </tr>
        </thead>
        <tbody>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">Черновик</td>
            <td className="list-edit-table__column">
              <strong>Создание новой заявки</strong>
              <br /> Кто: Заявитель (Руководитель, Ответственный исполнитель)
            </td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> На рассмотрение
              <br />
              <strong>Кто переводит:</strong> Заявитель
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">На рассмотрении</td>
            <td className="list-edit-table__column">
              <strong>Просмотр заявки и возможность оставить визу:</strong>
              <ul>
                <li>Управляющий комитет</li>
                <li>Ценовая комиссия</li>
                <li>Управление логистики</li>
              </ul>
              Функциональные кнопки <strong>«Комитет»</strong>, <strong>«Комиссия»</strong> и <strong>«Логистика»</strong>,
              доступны, если статус закупки — <strong>"На рассмотрении"</strong>.<br />
              <strong>Примечание:</strong> Порядок рассмотрения управляющим комитетом, ценовой комиссией и управлением логистики,
              определяется внутренним регламентом университета.
            </td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong>
              <ul>
                <li>На доработку</li>
                <li>Отклонена</li>
                <li>В работе (поставщик)</li>
                <li>Выполнена</li>
                <li>Черновик</li>
              </ul>
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">На доработку</td>
            <td className="list-edit-table__column">
              Доступно <strong>редактирование</strong> заявки
              <br />
              <strong>Кто может редактировать:</strong> Заявитель, Ответственное лицо по заявке
            </td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> На рассмотрение
              <br />
              <strong>Кто переводит:</strong> Заявитель, Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">Отклонена</td>
            <td className="list-edit-table__column">Заявка закрыта для редактирования, доступен только просмотр.</td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> На доработку
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">В работе (Поставщик)</td>
            <td className="list-edit-table__column">Заявка закрыта для редактирования, доступен только просмотр.</td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> Выполнена, Отклонена
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">Выполнена</td>
            <td className="list-edit-table__column">Заявка закрыта для редактирования, доступен только просмотр.</td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> Черновик
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h2>Удаление заявки на закупку</h2>
      Удалить заявку на закупку можно только в статусе <strong>Черновик</strong> и при наличии соответствующего права: "Удаление
      заявок в статусе "Черновик".
      <br />
      <h3>Перевод заявки на закупку в статус Черновик из разных статусов:</h3>
      <table className="list-edit-table">
        <thead>
          <tr className="list-edit-table__row list-edit-table__row_head">
            <th className="list-edit-table__column" style={{ width: '30%' }}>
              Статус заявки на закупку
            </th>
            <th className="list-edit-table__column" style={{ width: '70%' }}>
              Перевод заявки в статус Черновик
            </th>
          </tr>
        </thead>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">На доработку</td>
          <td className="list-edit-table__column">
            Передать на рассмотрение <IconButton icons={buttonIcons.activation} /> &rarr; в Черновик&nbsp;
            <IconButton icons={buttonIcons.saveDraft} />
          </td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">Отклонена</td>
          <td className="list-edit-table__column">
            На доработку <IconButton icons={buttonIcons.arrowLoop} /> &rarr; Передать на рассмотрение&nbsp;
            <IconButton icons={buttonIcons.activation} /> &rarr; в Черновик <IconButton icons={buttonIcons.saveDraft} />
          </td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">В работе (поставщик)</td>
          <td className="list-edit-table__column">
            Отклонить <IconButton icons={buttonIcons.deactivation} /> &rarr; На доработку&nbsp;
            <IconButton icons={buttonIcons.arrowLoop} /> &rarr; Передать на рассмотрение&nbsp;
            <IconButton icons={buttonIcons.activation} /> <br />
            &rarr; в Черновик <IconButton icons={buttonIcons.saveDraft} />
          </td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">На рассмотрение</td>
          <td className="list-edit-table__column">
            в Черновик <IconButton icons={buttonIcons.saveDraft} />
          </td>
        </tr>
        <tr className="list-edit-table__row">
          <td className="list-edit-table__column">Выполнена</td>
          <td className="list-edit-table__column">
            в Черновик <IconButton icons={buttonIcons.saveDraft} />
          </td>
        </tr>
      </table>
    </span>
  );
}
