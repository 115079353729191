import React, { useCallback } from 'react';

import { FormComponent, Reference, TextInput } from 'components';

import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  identifier: Person.Identifier | null;
  onChange(identifier: Person.Identifier): void;
};

export function Fields(props: Props) {
  const { identifier, onChange } = props;

  const makeChangeHandler = useCallback(
    (fieldName: keyof Person.Identifier) => (value: ValueOf<Person.Identifier>) => {
      onChange({ ...identifier!, [fieldName]: value });
    },
    [onChange, identifier],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Система цитирования" isRequired>
          <Reference
            name="RefCitationSystem"
            value={identifier?.citationSystem || null}
            relationTableModalTitle='Справочник "Базы цитирования и электронных ресурсов"'
            onChange={makeChangeHandler('citationSystem')}
            disabled={false}
          />
        </FormComponent.Field>
        <FormComponent.Field isRequired label="Идентификатор">
          <TextInput value={identifier?.identifier || ''} onChange={makeChangeHandler('identifier')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Имя автора">
            <TextInput value={identifier?.authorName || ''} onChange={makeChangeHandler('authorName')} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Field label="Ссылка на профиль">
          <TextInput value={identifier?.link || ''} onChange={makeChangeHandler('link')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
