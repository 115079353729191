import React from 'react';
import { block } from 'bem-cn';

import { Button, ButtonMode, ConfirmPopup, FormComponent, Modal, Toolbar } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { ApproveEvent } from 'features/Form/looks/event/views/ApproveEvent';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { EventTypes } from 'utils/Enums/EventTypes';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { Table } from 'types/models';

import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import ConferenceParticipationTemplate from './modalTemplates/ConferenceParticipationTemplate';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants/TextConstants';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { FinancialSupport } from 'features/Form/looks/event/EventFormConference/Tabs';

type Props = {
  tableState: Table.State;
};

const b = block('toolbar');

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isApprovePopupOpen,
    handleCloseApprovePopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    isMaterialFormOpen,
    handleTemplateCloseMaterialForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    sourceEvent,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    publicationMessageType,
    updatePublicationMessage,
    setPublicationMessageType,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    isFinancingModalOpen,
    setIsFinancingModalOpen,
    buttons,
    settings,
    userPermission,
    isApproved,
    eventData,
    setEventDataField,
    departmentOptions,
    handleAttractedDepartmentsChange,
    departments,
    projects,
    setProjects,
    handleSaveEvent,
  } = useController({ tableState });
  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={EventTypes.EVENT_CONFERENCE.code}
          />
        </div>
      )}
      {isViewFormOpen && (
        <modalTemplates.ConferenceViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}
      {isMaterialFormOpen && (
        <modalTemplates.ConferenceMaterialTemplate.Component
          isOpen={isMaterialFormOpen}
          onClose={handleTemplateCloseMaterialForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          withHeaderId={false}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.ConferenceEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.ConferenceAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранное мероприятие? (${tableState.selectedRows[0]?.EventName})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isApprovePopupOpen && (
        <ApproveEvent
          typeCode={EventTypes.EVENT_CONFERENCE.code}
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isApprovePopupOpen}
          onClose={handleCloseApprovePopup}
        />
      )}
      {isCancelMessagePopupOpen && (
        <AdministratorMessagePopup
          title={ADD_PUB_FEEDBACK_LABEL}
          isOpen={isCancelMessagePopupOpen}
          publicationMessage={publicationMessage}
          updatePublicationMessage={updatePublicationMessage}
          publicationMessageType={publicationMessageType}
          setPublicationMessageType={setPublicationMessageType}
          onConfirm={onCancelMessageConfirmClick}
          onConfirmWithoutMessage={onCancelMessageConfirmWithoutMessageClick}
        />
      )}
      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          conferenceEventId={tableState.selectedRows[0]?.id || ''}
          department={settings?.userDepartment}
          event={{ id: tableState.selectedRows[0]?.id || '', label: tableState.selectedRows[0]?.EventName }}
        />
      )}
      <ConferenceParticipationTemplate controllerIsForm={controllerIsForm} sourceEvent={sourceEvent} />
      <Modal
        isOpen={isFinancingModalOpen}
        onClose={() => setIsFinancingModalOpen(false)}
        title="Редактирование связей конференции с проектами и программами финансирования"
        size={['extralarge', 'medium-height']}
      >
        <FormComponent.Template>
          <FormComponent.Wrapper>
            <FinancialSupport
              eventData={eventData}
              projects={projects}
              setProjects={setProjects}
              workMode={
                isApproved && !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_FINANCING)
                  ? 'viewMode'
                  : 'editMode'
              }
              relatedTableState={undefined}
              incentivePaymentRequest={''}
              setEventDataField={setEventDataField}
              departments={departments}
              departmentOptions={departmentOptions}
              handleAttractedDepartmentsChange={handleAttractedDepartmentsChange}
              standalone
            />
            <FormComponent.Actions>
              <Button
                mode={ButtonMode.primary}
                text="Сохранить"
                onClick={() => handleSaveEvent(() => setIsFinancingModalOpen(false))}
              />
              <Button mode={ButtonMode.secondary} text="Отмена" onClick={() => setIsFinancingModalOpen(false)} />
            </FormComponent.Actions>
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
