import * as R from 'ramda';
import { HistoryEntry, MetadataField, ReferenceMetadata } from 'types/models';

import { convertMaybeArray, getText } from '../commonConverters';

export function convertServerReferenceMetadata(metadata: any): ReferenceMetadata {
  return {
    addable: metadata._attributes.addable === 'true',
    deletable: metadata._attributes.deletable === 'true',
    editable: metadata._attributes.editable === 'true',
    restrictable: metadata._attributes.restrictable === 'true',
    name: metadata._attributes.name,
    caption: metadata._attributes.caption,
    fields: R.filter(x => x !== undefined, convertMaybeArray(metadata.Field, convertServerMetadataField)),
  };
}

export function convertServerMetadataField(field: any): MetadataField {
  const type = field._attributes.type;
  return {
    type,
    caption: field._attributes.caption,
    editable: field._attributes.editable === 'true',
    name: field._attributes.name,
    reference: type === 'REFERENCE' ? field._attributes.reference : undefined,
    enumeration: type === 'ENUMERATION' ? field._attributes.enumeration : undefined,
    required: field._attributes.required === 'true',
  };
}

export function convertServerHistoryEntry(entry: any): HistoryEntry {
  return {
    action: getText(entry.Action),
    changed: getText(entry.Changer),
    changer: getText(entry.Changed),
  };
}
