import { parse } from 'date-fns';
import { getDateYearMonthDayTime } from 'utils/Helpers/getDateYearMonthDayTime';
import { formatStr as defaultFormatStr } from 'utils/Constants/FormatStr';

type Props = {
  dateString: string;
  dateStringToCompare: string;
  formatStr?: string;
};

const getIsEqualDates = ({ dateString, dateStringToCompare, formatStr = defaultFormatStr }: Props) => {
  const date = parse(dateString, formatStr, new Date());
  const dateToCompare = parse(dateStringToCompare, formatStr, new Date());

  const dateTime = getDateYearMonthDayTime(date);

  const dateToCompareTime = getDateYearMonthDayTime(dateToCompare);

  const isEqualDates = dateTime === dateToCompareTime;

  return isEqualDates;
};

export { getIsEqualDates };
