import { Table as T } from 'types/models';

export const GetMobileRequestFeedlineList = (id: string): T.Specification<{}, { id: string }> => {
  return {
    apiID: 'GetMobileRequestFeedlineList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { id },
  };
};
