import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { block } from 'bem-cn';
import { v4 as uuidv4 } from 'uuid';
import { useStream, useStreamsByApiID } from 'StreamRx';

import {
  IconButtonProps,
  Toolbar,
  buttonIcons,
  RequiredBadge,
  InputSelect,
  Option,
  RelationTableModal,
  Tooltip,
} from 'components';

import { streams as globalStreams } from 'features/Table/streams';
import { GetReferenceElementList } from 'features/Table/specifications';
import { DefaultFieldValue } from 'features/Table/specifications/GetReferenceElementList/model';
import { useController } from './controller';
import { ReferenceItem, ReferenceFilter } from '.';

import './style.scss';

const b = block('reference');

type Props = {
  value: ReferenceItem | null;
  name: string;
  tooltipText?: string;
  relationTableModalTitle: string;
  title?: string;
  refButtonTitle?: string;
  filters?: ReferenceFilter[];
  isStartLoadListWhenInitial?: boolean;
  isRequired?: boolean;
  theme?: 'small' | 'big' | 'default';
  fieldVisibleMode?: string;
  leftAlignedTitle?: boolean;
  disabled?: boolean;
  withoutDropdown?: boolean;
  onChange(reference: ReferenceItem | null): void;
  isDisabledDelete?: boolean;
  withoutControlPanel?: boolean;
  defaultValue?: DefaultFieldValue;
  isHiddenDelete?: boolean;
};

function Component(props: Props) {
  const {
    onChange,
    value,
    name,
    relationTableModalTitle,
    disabled,
    isRequired,
    isStartLoadListWhenInitial = false,
    filters: rowFilters = [],
    fieldVisibleMode,
    title,
    theme,
    withoutDropdown,
    tooltipText = '',
    refButtonTitle,
    leftAlignedTitle = false,
    isDisabledDelete,
    withoutControlPanel,
    defaultValue,
    isHiddenDelete,
  } = props;
  const generatedKey = useRef<string>(uuidv4());
  const filters = rowFilters.map(item => ({ ...item, values: item.values.filter(Boolean) })).filter(item => item.values.length);
  const streams = useStreamsByApiID(globalStreams, generatedKey.current);
  const {
    referencesList,
    isStartedLoadReferences,
    isOpenTableModal,
    isLoadingReferences,
    setIsStartedLoadReferences,
    setIsOpenTableModal,
  } = useController({ referenceName: name, filters, fieldVisibleMode, value });

  useEffect(() => {
    if (isStartLoadListWhenInitial) {
      setIsStartedLoadReferences(true);
    }
  }, [setIsStartedLoadReferences, isStartLoadListWhenInitial]);

  const onOpenMenu = useCallback(() => {
    if (!isStartedLoadReferences) {
      setIsStartedLoadReferences(true);
    }
  }, [isStartedLoadReferences, setIsStartedLoadReferences]);

  const handleChange = useCallback(
    (option: Option | null) => {
      const reference = referencesList.find(ref => ref.id === option?.value);
      onChange(reference || (option ? { id: option?.value || '', label: option?.label || '' } : null));
    },
    [referencesList, onChange],
  );

  const closeTableModal = useCallback(() => {
    setIsOpenTableModal(false);
  }, [setIsOpenTableModal]);

  const openTableModal = useCallback(() => {
    setIsOpenTableModal(true);
    setIsStartedLoadReferences(true);
  }, [setIsOpenTableModal, setIsStartedLoadReferences]);

  useStream(
    () => streams.submitTable,
    ({ selectedRows: [row] }) => {
      if (row) {
        handleChange({ label: row.label, value: row.id });
      }
      closeTableModal();
    },
    [handleChange, closeTableModal, streams.submitTable],
  );

  const options = useMemo(() => referencesList.map(({ label, id }) => ({ label, value: id })), [referencesList]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.list,
        title: refButtonTitle || relationTableModalTitle,
        code: 'add',
        isDisabled: disabled,
        onClick: event => {
          event?.currentTarget.blur();
          openTableModal();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Очистить',
        code: 'remove',
        isDisabled: isDisabledDelete || disabled || !value || !value.label,
        isHidden: isHiddenDelete,
        onClick: () => handleChange(null),
      },
    ], // eslint-disable-next-line
    [value, disabled, handleChange, openTableModal, relationTableModalTitle],
  );

  const onClickInputSelect = useCallback(() => {
    openTableModal();
    onOpenMenu();
  }, [openTableModal, onOpenMenu]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const specification = GetReferenceElementList({ requestData: { filters, name }, defaultValue }, generatedKey.current);

  return (
    <div className={b()}>
      {!!title && (
        <div
          className={`${b('title')} ${b(`title--${theme!}`)} ${tooltipText ? b('title--tooltip') : ''} ${
            leftAlignedTitle ? b('title--left') : ''
          }`}
        >
          {title}
          {tooltipText && (
            <div className={b('tooltip')}>
              <Tooltip text={tooltipText} />
            </div>
          )}
        </div>
      )}
      {!!isRequired && (
        <div className={b('badge')}>
          <RequiredBadge />
        </div>
      )}
      <div className={b('select', { 'without-dropdown': Boolean(withoutDropdown) })}>
        <InputSelect
          options={options}
          value={value ? { label: value.label, value: value.id } : null}
          disabled={disabled || withoutDropdown}
          onSelectChange={handleChange}
          onMenuOpen={onOpenMenu}
          onClick={withoutDropdown ? onClickInputSelect : undefined}
          isLoading={isLoadingReferences}
          onKeyDown={onKeyDown}
        />
      </div>
      {!withoutControlPanel && (
        <div className={b('icons')}>
          <Toolbar buttons={buttons} />
        </div>
      )}
      <RelationTableModal
        specification={specification}
        relationTableModalTitle={relationTableModalTitle}
        isOpen={isOpenTableModal}
        onClose={closeTableModal}
      />
    </div>
  );
}

export const Reference = React.memo(Component);
