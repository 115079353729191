import { useCallback } from 'react';
import { Table, Person } from 'types/models';
import { getMockScientist } from 'features/Form/looks/person/helpers';
import { useAppDataContext } from 'features/AppData/context';

const useController = () => {
  const { initialCountryCode } = useAppDataContext();

  const tableRowConverter = useCallback(
    (row: Table.Entry): Person.Scientist => {
      const scientist = { ...getMockScientist({ initialCountryCode }), fullName: row.FullFIO, id: row.id };
      return scientist;
    },
    [initialCountryCode],
  );

  return {
    tableRowConverter,
  };
};

export default useController;
