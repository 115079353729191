import { Event, Award } from 'types/models';
import { fillPersonToRequest } from '../author/requestConverters';
import { SaveEventUserFeedlineRequest, EventFeedline } from './types';
import { EventProject } from 'types/models/Event';

export function convertEventFeedlineToRequest(eventFeedline?: EventFeedline) {
  if (!eventFeedline) {
    return {};
  }

  return {
    EventFeedline: {
      Message: { _attr: { id: eventFeedline.messageId } },
      Detail: eventFeedline.detail,
    },
  };
}

export function convertAmountsToRequest(amounts: Event.MemberCountAnother[] | undefined) {
  if (!amounts || !amounts.length) {
    return { Amounts: {} };
  }
  return {
    Amounts: {
      Amount: amounts.map(doc => ({
        _attr: doc.id ? { id: doc.id } : {},
        Amount: { _text: doc.amount },
        Professors: { _text: doc.professors },
        Students: { _text: doc.students },
        City: { _text: doc.city },
        Country: { _attr: { id: doc.country?.id } },
        Enterprise: { _attr: { id: doc.enterprise?.id } },
        ...(doc?.department?.id ? { Department: { _attr: { id: doc.department?.id } } } : {}),
      })),
    },
  };
}
export function convertMagazineReleaseToRequest(sources: Event.Source[] | undefined) {
  if (!sources || !sources.length) {
    return { MagazineReleases: {} };
  }
  return {
    MagazineReleases: {
      MagazineRelease: sources.map(source => ({
        _attr: source.id ? { id: source.id } : {},
      })),
    },
  };
}
export function convertCompilationsToRequest(sources: Event.Source[] | undefined) {
  if (!sources || !sources.length) {
    return { Compilations: {} };
  }
  return {
    Compilations: {
      Compilation: sources.map(source => ({
        _attr: source.id ? { id: source.id } : {},
      })),
    },
  };
}

export function convertCommitteMembersToRequest(members: Event.Member[] | undefined) {
  if (!members || !members.length) {
    return { CommitteMembers: {} };
  }
  return {
    CommitteMembers: {
      CommitteMember: members.map((author, position) => fillPersonToRequest(author, position)),
    },
  };
}
export function convertMemberCountsToRequest(members: Event.MemberCount[] | undefined) {
  if (!members || !members.length) {
    return { MemberCounts: {} };
  }
  return {
    MemberCounts: {
      MemberCount: members.map(doc => ({
        _attr: doc.id ? { id: doc.id } : {},
        Type: {
          _text: doc.type,
        },
        Amount: {
          _text: doc.amount,
        },
        ...convertAmountsToRequest(doc.amounts),
      })),
    },
  };
}

export const convertSaveEventUserFeedlineToRequest = (props: SaveEventUserFeedlineRequest) => {
  const eventFeedlineAttr = { _attr: props.messageId ? { id: props.messageId } : {} };

  return {
    EventFeedline: {
      ...eventFeedlineAttr,
      ...{
        Message: {
          _attr: {
            id: props.labelId,
          },
        },
        Detail: props.message,
        Event: {
          _attr: {
            id: props.eventId,
          },
        },
      },
    },
  };
};

export function convertEventEnterprisesToRequest(enterprises: Event.Enterprise[] | undefined) {
  if (!enterprises || !enterprises.length) {
    return { Enterprises: {} };
  }
  return {
    Enterprises: {
      Enterprise: enterprises.map((doc, index) => ({
        _attr: doc.mainId ? { id: doc.mainId } : {},
        Element: {
          _attr: { id: doc.organization.id },
        },
        Position: {
          _text: index,
        },
        Role: {
          _text: doc.role.code,
        },
      })),
    },
  };
}

export function convertAwardsToRequest(awards: Award[] | undefined) {
  if (!awards || !awards.length) {
    return { Awards: {} };
  }
  return {
    Awards: {
      Award: awards.map(doc => ({
        _attr: doc.id ? { id: doc.id } : {},
      })),
    },
  };
}
export function convertEventDepartmentsToRequest(departments: Event.Department[] | undefined) {
  if (!departments || !departments.length) {
    return { Departments: {} };
  }
  return {
    Departments: {
      Department: departments.map((doc, index) => ({
        _attr: doc.mainId ? { id: doc.mainId } : {},
        Element: {
          _attr: { id: doc.id },
        },
        Position: {
          _text: index,
        },
        IsFinancing: doc.isFinancing,
      })),
    },
  };
}

export function convertProjectsToRequest(projects?: EventProject[]) {
  if (!projects) {
    return {};
  }

  return {
    Project: projects.map(convertProjectToRequest),
  };
}

export const convertProjectToRequest = (project?: EventProject) => ({
  _attr: project?.id ? { id: project.id } : null,
  Project: {
    _attr: { id: project?.project?.id },
  },
  IsAccepted: !!project?.accepted,
});
