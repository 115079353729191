import * as T from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';

import { Component as PanelForThirdLevelHeader } from './LeftPanelForThirdLevel';

type Props = {
  requestData: T.GetConferencePublicationListRequest;
  eventId?: string;
};

export const GetConferencePublicationList = ({ requestData, eventId }: Props): Table.Specification => ({
  apiID: 'GetConferencePublicationList',
  requestData,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: PanelForThirdLevelHeader({ eventId }),
    },
  },
});
