import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { showNotification } from 'features/Notifications';
import { Table, CommunityMember, Community, Document } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Item } from 'types/models/common';
import { Member } from 'features/SelectPerson';
import { useAppDataContext } from 'features/AppData/context';
import { getMockCommunityMember } from './helpers';
import { validateCommunityMember } from './validate';
import {
  getMockCommunity,
  getRoleOptionsByCommunityType,
  getCooperationTypeOptionsByCommunityType,
} from 'features/Form/looks/community/CommunityForm/helpers';
import { TemplateProps } from 'types/models/Form';
import { CommunityType } from 'utils/Enums';
import { getEnum } from 'utils/Helpers';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const { settings, enumMap } = useAppDataContext();

  const tableStreams = useLocalTableStreams();
  const {
    look: { id, arm, memberEditLocked, communityTypes },
  } = useFormContext<TemplateProps & { memberEditLocked: boolean; communityTypes?: Community.CommunityType[] }>();

  const preferredCommunityType = useMemo(() => {
    return communityTypes?.length === 1 ? communityTypes[0] : null;
  }, [communityTypes]);

  const communityTypeLabel = useMemo(() => {
    switch (preferredCommunityType) {
      case CommunityType.DISSERTATION_COUNCIL:
        return 'Диссертационный совет';
        break;
      default:
        return 'Научное сообщество';
        break;
    }
  }, [preferredCommunityType]);

  const allowedCommunityTypes = communityTypes || [
    CommunityType.SCIENTIFIC_COUNCIL,
    CommunityType.COLLABORATION,
    CommunityType.FUND,
    CommunityType.DISSERTATION_COUNCIL,
    CommunityType.MAGAZINE_REDACTION,
  ];

  const [communityMember, setCommunityMember] = useState<CommunityMember>(getMockCommunityMember());

  const isScientificCommunity = [CommunityType.SCIENTIFIC_COUNCIL, CommunityType.COLLABORATION, CommunityType.FUND].includes(
    (communityMember.community?.type?.value as Community.CommunityType) || '',
  );

  const allRoleOptions = useMemo(() => {
    return getEnum('CommunityMemberRole', enumMap);
  }, [enumMap]);

  const allCooperationTypeOptions = useMemo(() => {
    return getEnum('CommunityMemberCooperationType', enumMap);
  }, [enumMap]);

  const roleOptions = useMemo<Item[]>(() => {
    return communityMember.community?.type?.value
      ? getRoleOptionsByCommunityType(allRoleOptions, communityMember.community.type.value as Community.CommunityType)
      : [];
  }, [allRoleOptions, communityMember?.community?.type?.value]);

  const cooperationTypeOptions = useMemo<Item[]>(() => {
    return communityMember.community?.type?.value
      ? getCooperationTypeOptionsByCommunityType(
          allCooperationTypeOptions,
          communityMember.community.type.value as Community.CommunityType,
        )
      : [];
  }, [allCooperationTypeOptions, communityMember?.community?.type?.value]);

  const { methods: getCommunityMember } = BackendAPI.useBackendAPI('GetCommunityMember', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as CommunityMember;
      setCommunityMember(preparedData);
    },
  });

  const { methods: saveCommunityMember } = BackendAPI.useBackendAPI('SaveCommunityMember', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Член сообщества успешно сохранен', theme: 'success' });
      tableStreams.reloadTable.push();
    },
  });

  const { methods: getCommunity } = BackendAPI.useBackendAPI('GetCommunity');

  const handlePersonFieldChange = useCallback(
    (value: Member) => {
      setCommunityMember({ ...communityMember, ...value, scientist: value.person });
    },
    [communityMember],
  );

  const handleJoinDateFieldChange = useCallback(
    (value: string) => {
      setCommunityMember({ ...communityMember, joinDate: value || '' });
    },
    [communityMember],
  );

  const handleLeaveDateFieldChange = useCallback(
    (value: string) => {
      setCommunityMember({ ...communityMember, leaveDate: value || '' });
    },
    [communityMember],
  );

  const handleRoleFieldChange = useCallback(
    (value: Item) => {
      setCommunityMember({ ...communityMember, role: value });
    },
    [communityMember],
  );

  const handleCooperationTypeFieldChange = useCallback(
    (value: Item) => {
      setCommunityMember({ ...communityMember, cooperationType: value });
    },
    [communityMember],
  );

  const handleVakSpecialityFieldChange = useCallback(
    (value: ReferenceItem) => {
      setCommunityMember({ ...communityMember, vakSpeciality: value });
    },
    [communityMember],
  );

  const handleDescriptionFieldChange = useCallback(
    (value: string) => {
      setCommunityMember({ ...communityMember, description: value });
    },
    [communityMember],
  );

  const handleDocumentsFieldChange = useCallback(
    (value: Document[]) => {
      setCommunityMember({ ...communityMember, documents: value });
    },
    [communityMember],
  );

  const handleCommunityFieldChange = useCallback(
    (value: Community.Community) => {
      setCommunityMember({ ...communityMember, community: value });
    },
    [communityMember],
  );

  const communityRowConverter = useCallback(
    async (e: Table.Entry): Promise<Community.Community> => {
      const community: Community.Community = await new Promise(resolve => {
        getCommunity.callAPI(
          { id: e.id },
          {
            onSuccessfullCall: ({ data }) => {
              const preparedData = data as Community.Community;
              resolve(preparedData);
            },
            onFailedCall: () => {
              resolve(getMockCommunity());
            },
          },
        );
      });
      return community;
    },
    [getCommunity],
  );

  const onSubmit = useCallback(() => {
    if (validateCommunityMember(communityMember)) {
      saveCommunityMember.callAPI(communityMember);
    }
  }, [communityMember, saveCommunityMember]);

  const handleFormSubmit = useCallback(() => {
    onSubmit();
    if (validateCommunityMember(communityMember)) onClose();
  }, [communityMember, onClose, onSubmit]);

  const handleFormClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const { currentPerson } = useAppDataContext();

  useLayoutEffect(() => {
    if (id) getCommunityMember.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    const isNeedToSetScientist = arm === 'pc' && !!currentPerson;
    const isNeedToSetRole = roleOptions.length && !roleOptions.find(x => x.value === communityMember.role?.value);
    const isNeedToSetCoop =
      cooperationTypeOptions.length && !cooperationTypeOptions.find(x => x.value === communityMember?.cooperationType?.value);

    setCommunityMember({
      ...communityMember,
      role: isNeedToSetRole ? roleOptions[0] : communityMember.role,
      cooperationType: isNeedToSetCoop ? cooperationTypeOptions[0] : communityMember.cooperationType,
      scientist: isNeedToSetScientist ? currentPerson : communityMember.scientist,
    });
    // eslint-disable-next-line
  }, [communityMember.community?.type, cooperationTypeOptions, roleOptions]);

  return {
    communityMember,
    handleFormClose,
    handleFormSubmit,
    workMode,
    roleOptions,
    cooperationTypeOptions,
    handleCooperationTypeFieldChange,
    handleDescriptionFieldChange,
    handleJoinDateFieldChange,
    handleLeaveDateFieldChange,
    handlePersonFieldChange,
    handleRoleFieldChange,
    handleVakSpecialityFieldChange,
    communityRowConverter,
    handleCommunityFieldChange,
    handleDocumentsFieldChange,
    isScientificCommunity,
    settings,
    memberEditLocked,
    allowedCommunityTypes,
    preferredCommunityType,
    communityTypeLabel,
  };
}
