import React from 'react';

import { FormComponent, InputSelect, Option, Select, TextInput, TextInputMode } from 'components';
import { renderEntry } from 'components/Select/helpers';

import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';
import { CommissionMember } from 'types/models/ProtocolCommission';

type Props = {
  entry: CommissionMember | null;
  onFieldChange: React.Dispatch<React.SetStateAction<CommissionMember | null>>;
  disabled?: boolean;
};

function CommissionMemberFields({ disabled, entry, onFieldChange }: Props) {
  const {
    handlePersonChange,
    handleRoleChange,
    roleOptions,
    convertCommissionMemberToMember,
    makeChangeHandler,
    priorityTooltipTxt,
  } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Персона" isRequired={true}>
          <SelectPerson member={convertCommissionMemberToMember(entry)} onUpdateMember={handlePersonChange} disabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="triplePadded">
        <FormComponent.Field label="ФИО для протокола" isRequired={true}>
          <Select.Component
            variant="outlined"
            defaultPlaceholder="Не задан"
            value={entry?.protocolName}
            entries={entry?.person?.bibliographicNames.map(x => x.name) ?? []}
            onChange={makeChangeHandler('protocolName')}
            renderEntry={renderEntry}
            disabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="triplePadded" hasFreeFormat growIndexes={[100, 1]} hasGap>
        <FormComponent.Field label="Роль" isRequired={true}>
          <InputSelect
            options={roleOptions}
            value={entry?.role as Option<string>}
            onSelectChange={handleRoleChange}
            disabled={!!disabled}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Категория роли" tooltip={priorityTooltipTxt} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={entry?.priority?.toString() || '0'}
            onChange={makeChangeHandler('priority')}
            settings={{ max: 9, decimal: false }}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { CommissionMemberFields };
