import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import * as BackendAPI from 'services/BackendAPI';

import { Reference } from 'components';

import { getIsPending } from 'utils/Helpers/getIsPending';
import * as M from 'features/Table/specifications/GetReferenceElementList/model';

type Props = {
  field: M.EditableReferenceMetadataField;
  isDisabled: boolean;
  onChange(name: string, value: M.FieldValue): void;
};

export const ReferenceField = ({ field, isDisabled, onChange }: Props) => {
  const { name, value, reference, caption, defaultReferenceFieldValue } = field;
  const { methods: getReferenceElements, state: getReferenceElementsState } = BackendAPI.useBackendAPI('GetReferenceElements');

  const isLoading = useMemo(() => getIsPending(getReferenceElementsState), [getReferenceElementsState]);

  useEffect(() => {
    const hasDefaultValue = defaultReferenceFieldValue && field.name === defaultReferenceFieldValue.fieldName;
    if (hasDefaultValue) {
      getReferenceElements.callAPI(
        {
          filters: [{ key: 'id', values: [defaultReferenceFieldValue.id] }],
          referenceName: defaultReferenceFieldValue.referenceName,
        },
        {
          onSuccessfullCall: ({ data }) => {
            const defaultRefValue = data[0] ?? null;
            R.partial(onChange, [name])(defaultRefValue);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Reference
      name={reference}
      value={value}
      relationTableModalTitle={caption}
      disabled={isDisabled || isLoading || defaultReferenceFieldValue?.isDisabled}
      onChange={R.partial(onChange, [name])}
    />
  );
};
