import React from 'react';

import './style.scss';

/**
 * Badge of required (without params)
 *
 * @returns JSX
 */
export const RequiredBadge = React.memo(() => {
  return <span className="required-badge">*</span>;
});
