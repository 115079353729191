import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';

const FinTypeList = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    enumProps={{
      mode: {
        component: 'Reference',
        referenceName: 'RefTypeInvestmentSource',
        referenceFilter: [],
        relationTableModalTitle: 'Справочник "Типы источника финансирования"',
      },
      listEditTitle: 'Список типов источника финансирования',
      fieldLabel: 'Тип источника',
    }}
  />
);

const Component = memo(FinTypeList);

export { Component as FinTypeList };
