import React from 'react';
import * as R from 'ramda';

import { ReferenceItem, TextInput, TextArea, FormComponent } from 'components';

import { Program } from 'types/models';

type Props = {
  direction: Program.Direction | null;
  onDirectionChange(row: Program.Direction): void;
};

function DirectionInputs({ direction, onDirectionChange }: Props) {
  const handleChange = (fieldName: keyof Program.Direction) => (value: ReferenceItem | string) => {
    onDirectionChange(R.set(R.lensProp(fieldName), value, direction!));
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Название направления" isRequired>
          <TextInput value={direction?.name ?? ''} onChange={handleChange('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Краткое название" isRequired>
          <TextArea value={direction?.code ?? ''} onChange={handleChange('code')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { DirectionInputs };
