import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Mode } from '../makeUseCustomController';
import { Modal } from 'components';

type DetailProps = {
  mode: Mode | null;
};

const NeighborTableComponent: React.FC<F.TemplateProps & DetailProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  id,
  mode,
}: F.TemplateProps & DetailProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook & { isOpen: boolean } = {
    apiID: 'GetScientistData',
    template: mode === 'add' ? 'PersonAddForm' : 'PersonForm',
    relatedTableState,
    id: String(id),
    isOpen,
    viewMode: mode === 'view',
    editMode: mode === 'edit',
    setTitle,
  };

  return mode === 'add' ? (
    <Form look={look} onClose={onClose} />
  ) : (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode={mode === 'view'}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(NeighborTableComponent);
