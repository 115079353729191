import { Rid, RidSubject } from 'types/models';
import { useCallback } from 'react';
import { ValueOf } from 'types/helpers';

type Props = {
  rntd?: Rid | null;
  makeChangeHandler(key: keyof Rid): (value: ValueOf<Rid>) => void;
};

export function useController(props: Props) {
  const { makeChangeHandler } = props;

  const handleSubjectsChange = useCallback(
    (subjects: RidSubject[]) => {
      makeChangeHandler('subjects')(subjects);
    },
    [makeChangeHandler],
  );

  return {
    handleSubjectsChange,
  };
}
