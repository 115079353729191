import React from 'react';

import {
  Checkbox,
  FormComponent,
  SectionTitle,
  ListEdit,
  CustomSelect,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { formatNumber } from 'utils/Helpers';
import { TAX, useController } from './controller';
import { Fields } from './Fields';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { validate } from './validate';
import { showErrorsMessages } from 'utils/Common';
import { Estimate } from 'types/models/Estimate';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function EstimatePositionForm({ viewMode, editMode, onClose }: Props) {
  const {
    estimate,
    kindConsumptionEditMode,
    handleFormSubmit,
    kindConsumptionSpecification,
    kindConsumptionTableRowConverter,
    kindConsumptionOriginal,
    kvrLabel,
    kosguLabel,
    formFields,
    overheadPercent,
    availableFundCardSpecification,
    availableFundCardTableRowConverter,
    availableFundCardOriginal,
    taxKindConsumptionOriginal,
    taxKvrLabel,
    taxKosguLabel,
    estimatePosition,
    handlePositionItemChange,
  } = useController({ viewMode, editMode, onClose });

  const itemsComponent = () => (
    <ListEdit
      title="План расходования средств"
      withMessages
      isDeleteConfirmEnabled
      rows={estimatePosition?.items || []}
      isToolbarDisabled={false}
      onChange={handlePositionItemChange}
      maxHeight="none"
      columnIndexesForSumTotal={[1]}
      defaultRowsCount={4}
      columns={[
        { label: 'Дата', formatValue: x => x?.date || '', styles: { width: '20%' } },
        {
          label: 'Сумма, план',
          formatValue: x => x?.amount || '',
          styles: { width: '20%' },
        },
        {
          label: 'Примечание',
          formatValue: x => x?.note || '',
          styles: { width: '60%' },
        },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (item, setItem) => <Fields item={item} setItem={setItem} />,
        validation: {
          checkIsValid: (indicator, index, list, mode) =>
            validate(indicator, index, list, mode, estimate as Estimate).every(x => x.isValid),
          onInvalidate: (indicator, mode, index, list) => {
            const validationInfo = validate(indicator, index, list, mode, estimate as Estimate);
            showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );

  const tooltips = {
    amount:
      // eslint-disable-next-line prettier/prettier, max-len
      'Кнопка Рассчитать накладные автоматически рассчитывает сумму и фактический процент централизованного фонда с учётом плановой суммы сметы- сумма сметы по статьям с атрибутом "Уменьшает накладные" и нормативного процента накладных расходов. Вы можете ввести произвольную сумму накладных расходов, в этом случае, автоматически рассчитывается процент. Информация о накладных расходах будет сохранена в карточке Централизованного фонда как Сумма, план. При поступлении средств на шифр автоматически для этой статьи расходов будет проставлена Сумма, факт = Сумма документа прихода * Расчётный процент накладных расходов',

    funcCard:
      // eslint-disable-next-line prettier/prettier, max-len
      'Карточка ЦФ может быть установлена для Шифра проекта (значение по умолчанию). Вы можете выбрать другую карточку из списка. Информация о накладных расходах будет сохранена в карточке Централизованного фонда как Сумма, план. При поступлении средств на шифр автоматически будет рассчитана Сумма, факт = Сумма документа прихода * Расчётный процент накладных расходов.',
  };

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field>
            <CustomSelect
              disabled={!!viewMode}
              isRequired
              title={formFields.kindConsumption.title}
              original={kindConsumptionOriginal}
              relationTableModalTitle={formFields.kindConsumption.title}
              onDeleteFunction={() => formFields.kindConsumption.onChange(null)}
              onChange={formFields.kindConsumption.onChange}
              modalTableRowConverter={kindConsumptionTableRowConverter}
              modalTableSpecification={kindConsumptionSpecification}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КВР">
            <div>{kvrLabel}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КОСГУ">
            <div>{kosguLabel}</div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column specialStyles={{ width: '30%', minWidth: '200px' }}>
              <FormComponent.Field
                label={formFields.amount.title}
                isRequired
                tooltip={kindConsumptionEditMode === TAX.OVERHEAD ? tooltips.amount : ''}
              >
                <TextInput
                  mode={TextInputMode.number}
                  value={formFields?.amount.value || ''}
                  onChange={formFields.amount.onChange}
                />
              </FormComponent.Field>
            </FormComponent.Column>
            <FormComponent.Column specialStyles={{ width: '70%' }}>
              {kindConsumptionEditMode === TAX.OVERHEAD && (
                <>
                  <FormComponent.Line>
                    <FormComponent.Field>
                      <>Расчетный процент накладных {formatNumber(overheadPercent)} %</>
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
              )}
              {(kindConsumptionEditMode === TAX.DEFAULT || kindConsumptionEditMode === TAX.TAX) && (
                <FormComponent.Field label={formFields.isReduceOverhead.title}>
                  <Checkbox
                    checked={!!formFields?.isReduceOverhead.value}
                    onChange={formFields.isReduceOverhead.onChange}
                    isDisabled={viewMode}
                  />
                </FormComponent.Field>
              )}
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
        {kindConsumptionEditMode === TAX.OVERHEAD && (
          <FormComponent.Line>
            <FormComponent.Field label="Карточки ЦФ" tooltip={tooltips.funcCard}>
              <CustomSelect
                disabled={!!viewMode}
                original={availableFundCardOriginal}
                relationTableModalTitle="Карточки ЦФ"
                onDeleteFunction={() => formFields.funcCard.onChange(null)}
                onChange={formFields.fundCard.onChange}
                modalTableRowConverter={availableFundCardTableRowConverter}
                modalTableSpecification={availableFundCardSpecification}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line>
          <FormComponent.Field label={formFields.note.title}>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: formFields.note.title }}
              value={formFields.note.value}
              onChange={formFields.note.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field>{itemsComponent()}</FormComponent.Field>
        </FormComponent.Line>
        {kindConsumptionEditMode === TAX.TAX && (
          <>
            <SectionTitle title="Налог" />
            <FormComponent.Line>
              <CustomSelect
                disabled={!!viewMode || kindConsumptionEditMode === TAX.TAX}
                isRequired
                title="Вид расхода, налог"
                original={taxKindConsumptionOriginal}
                relationTableModalTitle="Виды расхода"
                onDeleteFunction={() => {}}
                onChange={() => {}}
                modalTableRowConverter={kindConsumptionTableRowConverter}
                modalTableSpecification={kindConsumptionSpecification}
              />
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="КВР">
                <div>{taxKvrLabel}</div>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="КОСГУ">
                <div>{taxKosguLabel}</div>
              </FormComponent.Field>
            </FormComponent.Line>

            <FormComponent.Line>
              <FormComponent.Field label={formFields.taxPercent.title} isRequired>
                <TextInput
                  mode={TextInputMode.number}
                  value={formFields?.taxPercent.value || ''}
                  onChange={formFields.taxPercent.onChange}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.ColumnWrapper>
                <FormComponent.Column specialStyles={{ width: '30%', minWidth: '200px' }}>
                  <FormComponent.Field label={formFields.taxAmount.title} isRequired>
                    <TextInput
                      mode={TextInputMode.number}
                      value={formFields?.taxAmount.value || ''}
                      onChange={formFields.taxAmount.onChange}
                    />
                  </FormComponent.Field>
                </FormComponent.Column>
                <FormComponent.Column specialStyles={{ width: '70%' }}>
                  <FormComponent.Field label={formFields.taxIsReduceOverhead.title}>
                    <Checkbox
                      checked={!!formFields?.taxIsReduceOverhead.value}
                      onChange={formFields.taxIsReduceOverhead.onChange}
                      isDisabled={viewMode}
                    />
                  </FormComponent.Field>
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </FormComponent.Line>
          </>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(EstimatePositionForm);
