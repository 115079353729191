import { useMemo, useState, useCallback } from 'react';
import { format } from 'date-fns';

import { buttonIcons, IconButtonProps } from 'components';
import { IdItem } from 'types/models/common';
import { Table } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { formatStr } from 'utils/Constants/FormatStr';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

type CreateInfo = {
  year: string;
  fundCard: IdItem | null;
  kindConsumption: IdItem | null;
  createdDate: string;
};

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const defaultCreateInfo = useMemo<CreateInfo>(
    () => ({ year: '', fundCard: null, kindConsumption: null, createdDate: format(new Date(), formatStr) }),
    [],
  );
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [createInfo, setCreateInfo] = useState<CreateInfo>(defaultCreateInfo);

  const { methods: createEstimateByProjectCode } = BackendAPI.useBackendAPI('CreateEstimateByProjectCode');

  const closeCreateModal = useCallback(() => {
    setIsCreateModalOpen(false);
    setCreateInfo(defaultCreateInfo);
  }, [defaultCreateInfo]);

  const confirmCreateModal = useCallback(() => {
    const { year, fundCard, kindConsumption, createdDate } = createInfo;
    if (!year || !fundCard?.id || !kindConsumption?.id || !createdDate) {
      showNotification({ message: 'Заполните все поля', theme: 'danger' });
      return;
    }
    createEstimateByProjectCode.callAPI(
      {
        year: year,
        fundCardId: fundCard.id,
        kindConsumptionId: kindConsumption.id,
        createdDate: createdDate,
        projectCodeIds: tableState.selectedRows.map(x => x.id),
      },
      {
        onSuccessfullCall: () => {
          tableStreams.reloadTable.push();
          closeCreateModal();
        },
      },
    );
  }, [closeCreateModal, createEstimateByProjectCode, createInfo, tableState.selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Создать смету для группы проектов',
        onClick: () => setIsCreateModalOpen(true),
        isDisabled: !tableState.selectedRows.length,
      },
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [tableState.selectedRows.length],
  );

  return {
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
    isCreateModalOpen,
    closeCreateModal,
    createInfo,
    setCreateInfo,
    confirmCreateModal,
  };
};

export default useController;
