import { useAppDataContext } from 'features/AppData/context';
import { useCallback } from 'react';
import { ParticipationEventSource } from 'types/models/Participation';
import { GetExponentList, GetShortEventList } from 'features/Table/specifications';
import { Table as T, Exponent } from 'types/models';
import { EventFormat } from 'utils/Enums';

type Props = {
  relatedTableState: T.State | undefined;
};

export function useController(props: Props) {
  const { relatedTableState } = props;

  const { settings, enumMap } = useAppDataContext();
  const modalTableRowToEventConventer = useCallback<(row: T.Entry) => ParticipationEventSource>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      fullName: row.fullName,
      startDate: row.startDate,
      endDate: row.endDate,
      entity: null,
      eventStatus: null,
      format: EventFormat.OFFLINE,
    }),
    [],
  );
  const modalTableRowToExponentConverter = useCallback<(row: T.Entry) => Exponent>(
    row => ({
      id: row.id,
      name: `${row.Name} ${row.Type ? ` (${row.Type})` : ''}`,
      type: null,
      subject: null,
      authors: [],
      partners: [],
      note: null,
      participations: [],
    }),
    [],
  );

  const exponentTableConfig = GetExponentList({
    hasSelectButton: true,
  });

  const shortEventTableConfig = GetShortEventList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
    },
    requestData: {
      eventType: 'EXPOSITION',
      allEvents: true,
    },
    hasSelectButton: true,
    hasFilter: true,
    buttonsToHide: ['approve'],
  });

  return {
    enumMap,
    settings,
    modalTableRowToEventConventer,
    modalTableRowToExponentConverter,
    exponentTableConfig,
    shortEventTableConfig,
  };
}
