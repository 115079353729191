import { Table } from 'types/models';

import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Filters } from './filters';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Table as T } from 'types/models';

type Props = {
  workMode?: T.WorkMode;
  communityId?: string;
  hideActionButtons?: boolean;
  hideFilters?: boolean;
};

export function GetBaseDissertationPublicationList(props: Props): Table.Specification {
  const { workMode, communityId, hideActionButtons, hideFilters } = props;
  return {
    apiID: 'GetBaseDissertationPublicationList',
    header: {
      firstLevel: {
        title: 'Авторефераты и диссертации',
        Panel: !hideFilters ? Filters : undefined,
      },
      thirdLevel: {
        withTemplatesPanel: !hideFilters,
        LeftPanel: LeftPanelForThirdLevel({ workMode, hideActionButtons }),
      },
    },
    enumFilterValues: {
      enumName: 'PublicationType',
      excludeValues: [
        PublicationTypes.MAGAZINE.code,
        PublicationTypes.COMPILATION_CONFERENCE.code,
        PublicationTypes.COMPILATION_OTHER.code,
        PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
        PublicationTypes.MAGAZINE_ARTICLE.code,
        PublicationTypes.MONOGRAPH.code,
        PublicationTypes.MONOGRAPH_CHAPTER.code,
        PublicationTypes.TEXTBOOK.code,
        PublicationTypes.TUTORIAL.code,
        PublicationTypes.METHODICAL_EDITION.code,
        PublicationTypes.METHODICAL_EDITION_OTHER.code,
        PublicationTypes.THESIS.code,
        PublicationTypes.ARTICLE_SCIENTIFIC_WORK.code,
        PublicationTypes.ARTICLE_CONFERENCE.code,
        PublicationTypes.ARTICLE_OTHER.code,
        PublicationTypes.OTHER_BOOK.code,
        PublicationTypes.OTHER_PART.code,
        PublicationTypes.ARTICLE_ARTICLES.code,
        PublicationTypes.PREPRINT_ARTICLE.code,
      ],
    },
    requestData: { communityId },
    isWithQna: true,
  };
}
