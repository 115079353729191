import { PeriodableReferenceItem } from 'types/models/Partner';

export function validate(okved: PeriodableReferenceItem | null) {
  if (!okved) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  if (okved.startDate && okved.endDate && okved.startDate > okved.endDate)
    return [{ isValid: false, invalidMessage: 'Дата окончания не может быть меньше даты начала' }];

  const isValidRef = Boolean(okved.ref && okved.ref.id);

  return [{ isValid: isValidRef, invalidMessage: 'Выберите ОКВЭД' }];
}
