import { useState, useCallback } from 'react';
import { Table } from 'types/models';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
};

const workModeHook = ({ viewMode, editMode }: Props) => {
  const [workMode, setWorkMode] = useState<Table.WorkMode>(editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode');

  const updateWorkModeAfterSaveAndContinue = useCallback(() => {
    setWorkMode('editMode');
  }, []);

  return {
    workMode,
    updateWorkModeAfterSaveAndContinue,
  };
};

export default workModeHook;
