import { Table } from 'types/models';
import { Filters } from './filters';
import LeftPanelForThirdLevel from './LeftPanelForThirdLevel';

export const GetDashboardDetailMagazineArticleList = (): Table.Specification => ({
  apiID: 'GetDashboardDetailMagazineArticleList',
  header: {
    firstLevel: {
      title: 'Статьи в журналах',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
  isWithQna: true,
});
