import { Customer } from 'types/models';

export function getContacts(customer: Customer): string {
  const scientist = customer.person.scientist;
  const contacts = [
    scientist?.phone,
    scientist?.phoneIntegration,
    scientist?.mobilePhone,
    scientist?.mobilePhoneIntegration,
    scientist?.email,
    scientist?.emailIntegration,
  ]
    .filter(x => !!x && x !== '*')
    .join(', ');
  return contacts;
}
