import React, { useCallback } from 'react';

import {
  buttonIcons,
  InputSelect,
  Option,
  TextInput,
  TextInputMode,
  Toolbar,
  SectionTitle,
  FormComponent,
  ListEditTable,
} from 'components';

import { formatNumber, getEnum } from 'utils/Helpers';
import { AuthorPayment } from 'types/models/Payment';
import { useAppDataContext } from 'features/AppData/context';
import { SecurityDocumentPaymentRequest } from 'types/models';
import { LocalAuthorPayment } from 'features/IncentivePayments/model';

type Props = {
  authorPayment: LocalAuthorPayment | SecurityDocumentPaymentRequest | null;
  disabled: boolean;
  changeField(key: keyof AuthorPayment, value: string | Option): void;
};

const monthOptionsMap: Record<number | string, { label: string; value: string }[]> = {
  FIRST: [
    { label: 'Январь', value: '1' },
    { label: 'Февраль', value: '2' },
    { label: 'Март', value: '3' },
  ],
  SECOND: [
    { label: 'Апрель', value: '4' },
    { label: 'Май', value: '5' },
    { label: 'Июнь', value: '6' },
  ],
  THIRD: [
    { label: 'Июль', value: '7' },
    { label: 'Август', value: '8' },
    { label: 'Сентябрь', value: '9' },
  ],
  FOURTH: [
    { label: 'Октябрь', value: '10' },
    { label: 'Ноябрь', value: '11' },
    { label: 'Декабрь', value: '12' },
  ],
};

export function Info({ authorPayment, disabled, changeField }: Props) {
  const { enumMap } = useAppDataContext();

  if (authorPayment === null) {
    return null;
  }

  const quartersOptions = getEnum('Quarter', enumMap);
  const monthOptions = monthOptionsMap[authorPayment?.quarter?.value || ''];

  const changeQuarter = useCallback(
    (quarter: Option | null) => {
      const [monthOption] = monthOptionsMap[quarter?.value ?? 'FIRST'];
      changeField('quarter', quarter ?? { label: '1', value: 'FIRST' });
      changeField('month', monthOption.value);
    },
    [changeField],
  );

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column specialStyles={{ width: '25%' }}>
        <SectionTitle title="Период оплаты" />
        <FormComponent.Line>
          <FormComponent.Field label="Год" isRequired>
            <TextInput
              mode={TextInputMode.year}
              value={authorPayment.year}
              onChange={val => changeField('year', val)}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Квартал" isRequired>
            <InputSelect
              options={quartersOptions}
              value={quartersOptions.find(x => x.value === authorPayment!.quarter?.value) ?? null}
              onSelectChange={changeQuarter}
              disabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Месяц" isRequired>
            <FormComponent.Line>
              <InputSelect
                options={monthOptions}
                value={monthOptions.find(x => x.value === authorPayment.month) ?? null}
                onSelectChange={option => changeField('month', option?.value ?? '')}
                disabled={disabled}
              />
              <Toolbar
                buttons={[
                  {
                    icons: buttonIcons.delete,
                    title: 'Очистить',
                    isDisabled: !authorPayment.month,
                    onClick: () => changeField('month', ''),
                  },
                ]}
              />
            </FormComponent.Line>
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column specialStyles={{ width: '75%' }}>
        <SectionTitle title="Условия оплаты" />
        <FormComponent.ColumnWrapper>
          <FormComponent.Column specialStyles={{ width: '60%' }}>
            <FormComponent.Line>
              <FormComponent.Field isRequired label="Пункт положения">
                <TextInput
                  value={authorPayment?.regulationParagraph ?? ''}
                  onChange={val => changeField('regulationParagraph', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field isRequired label="Название">
                <TextInput
                  value={authorPayment?.regulationName ?? ''}
                  onChange={val => changeField('regulationName', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field isRequired label="Сумма номинантам, руб:">
                <TextInput
                  mode={TextInputMode.number}
                  value={authorPayment?.maxNomineesMoney ?? ''}
                  onChange={val => changeField('maxNomineesMoney', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
              <FormComponent.Field isRequired label="Сумма фонда, руб:">
                <TextInput
                  mode={TextInputMode.number}
                  value={authorPayment?.maxFundMoney ?? ''}
                  onChange={val => changeField('maxFundMoney', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column specialStyles={{ width: '40%' }}>
            <FormComponent.Line>
              <ListEditTable
                rows={[
                  {
                    type: 'Максимальная',
                    nominie: formatNumber(authorPayment.maxNomineesMoney, 0),
                    fund: formatNumber(authorPayment.maxFundMoney, 0),
                  },
                  {
                    type: 'Фактическая',
                    nominie: formatNumber(authorPayment.factNomineesMoney, 0),
                    fund: formatNumber(authorPayment.factFundMoney, 0),
                  },
                  {
                    type: 'Остаток',
                    nominie: formatNumber(authorPayment.remainderNomineesMoney, 0),
                    fund: formatNumber(authorPayment.remainderFundMoney, 0),
                  },
                ]}
                columns={[
                  { label: 'Сумма, руб.', formatValue: row => row.type || '', styles: { width: '40%' } },
                  { label: 'Номинанты', formatValue: row => row.nominie || '', styles: { width: '30%' } },
                  { label: 'Фонд', formatValue: row => row.fund || '', styles: { width: '30%' } },
                ]}
                selectedRowIndex={null}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                selectRow={function (index: number | null): void {}}
              />
            </FormComponent.Line>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
