import { useCallback, useMemo } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Props } from './';
import { useLocalTableStreams } from 'features/Table/hooks';

const useController = ({ selectedRows, onSubmitTable }: Props) => {
  const tableStreams = useLocalTableStreams();
  const isSelectButtonDisabled = useMemo(() => !selectedRows.length, [selectedRows.length]);
  const preparedOnSubmitTable = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable({ selectedRows });
    } else {
      tableStreams.submitTable.push({ selectedRows });
    }
  }, [onSubmitTable, selectedRows, tableStreams.submitTable]);
  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.check,
        title: 'Выбрать',
        code: 'select',
        isDisabled: isSelectButtonDisabled,
        onClick: preparedOnSubmitTable,
      },
    ],
    [preparedOnSubmitTable, isSelectButtonDisabled],
  );
  return {
    buttons,
  };
};

export default useController;
