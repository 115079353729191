import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  setProgramId(programId: string): void;
};

export function GetProgramSelectList({ setProgramId }: Props): Table.Specification<State> {
  return {
    apiID: 'GetProgramList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ setProgramId }),
  };
}
