import { EmptyToolbar } from 'features/Table/views';
import { Table } from 'types/models';

type Arguments = {
  projectId: string | null;
};

export function GetProjectPatentResearchList(args: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectPatentResearchList',
    isFiltersHidden: true,
    requestData: args,
    header: {
      firstLevel: {
        title: 'Патентные исследования',
      },
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: EmptyToolbar,
      },
    },
  };
}
