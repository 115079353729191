import { useCallback, useEffect, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { Award } from 'types/models';

type Props = {
  isOpen: boolean;
  awardsArray?: Award[];
  id?: string;
};
export function useController({ isOpen, id, awardsArray }: Props) {
  if (awardsArray) {
    return { awards: awardsArray };
  }

  const [awards, setAwards] = useState<Award[]>([]);

  const { methods: getParticipation } = BackendAPI.useBackendAPI('GetParticipation');
  const GetParticipation = useCallback(
    () => {
      if (id) {
        getParticipation.callAPI(
          {
            participationId: id,
            modules: [
              'ID',
              'ACCESS_MODE',
              'MAIN',
              'EVENT',
              'DEPARTMENTS',
              'PUBLICATION',
              'PROJECTS',
              'MEMBERS',
              'AWARDS',
              'MOBILE_REQUESTS',
              'DOCUMENTS',
            ],
            eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
            IsCollectiveExhibit: false,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setAwards(data.awards);
            },
          },
        );
      }
    }, // eslint-disable-next-line
    [getParticipation],
  );

  useEffect(
    () => {
      if (isOpen) {
        GetParticipation();
      }
    },
    // eslint-disable-next-line
    [isOpen],
  );

  return {
    awards,
  };
}
