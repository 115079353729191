import React, { useMemo } from 'react';

import { Tabs, Tab, ReferenceItem } from 'components';

import { Person, UserPermission } from 'types/models';
import { EnumMap } from 'types/models/Table';
import { ValueOf } from 'types/helpers';
import { Honor } from 'features/PersonCard/model';
import { Contacts } from './Contacts/Contacts';
import { JobDetails } from './JobDetails/JobDetails';
import { HirshIndexes } from './HirshIndexes/HirshIndexes';
import { EducationDetails } from './EducationDetails/EducationDetails';
import { ScientistHonors } from './ScientistHonors/ScientistHonors';
import { Identifiers } from './Identifiers/Identifiers';
import { FIO } from './FIO/FIO';
import { Changes } from './Changes/Changes';
import { Affiliations } from './Affiliations/Affiliations';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  enumMap: EnumMap;
  disabled: boolean;
  makeSetPersonField: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
  onChangeHonors(item: Honor[]): void;
  onChange(fieldName: keyof Person.Scientist, value: string | ReferenceItem | null): void;
  onChangeHirshIndexes(indexes: Person.HirshIndex[]): void;
};

function Component(props: Props) {
  const { person, enumMap, userPermission, disabled, onChangeHonors, makeSetPersonField, onChange, onChangeHirshIndexes } = props;
  const scientist = useMemo(() => person?.scientist ?? null, [person]);

  return (
    <Tabs>
      <Tab title="Контакты">
        <Contacts scientist={scientist} onChange={onChange} />
      </Tab>
      <Tab title="Сведения о работе">
        <JobDetails disabled={disabled} enumMap={enumMap} person={person} onChangeJobs={makeSetPersonField('scientistJobs')} />
      </Tab>
      <Tab title="Сведения об обучении">
        <EducationDetails
          disabled={disabled}
          enumMap={enumMap}
          person={person}
          onChangeEducations={makeSetPersonField('scientistEducations')}
        />
      </Tab>
      <Tab title="Ученые степени и звания">
        <ScientistHonors userPermission={userPermission} person={person} onChangeHonors={onChangeHonors} />
      </Tab>
      <Tab title="Индекс Хирша">
        <HirshIndexes person={person} onChangeHirshIndexes={onChangeHirshIndexes} />
      </Tab>
      <Tab title="Идентификаторы в базах цитирования">
        <Identifiers disabled={disabled} person={person} onChangeIdentifiers={makeSetPersonField('identifiers')} />
      </Tab>
      <Tab title="Библиографические ФИО">
        <FIO
          disabled={disabled}
          person={person}
          userPermission={userPermission}
          onChangeFIO={makeSetPersonField('bibliographicNames')}
        />
      </Tab>
      <Tab title="Аффилиации">
        <Affiliations person={person} />
      </Tab>
      <Tab title="Редакции" isVisible={!!person?.changes.length}>
        <Changes person={person} />
      </Tab>
    </Tabs>
  );
}

export const AdditionalInfo = React.memo(Component);
