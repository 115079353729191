import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseEditForm,
    handleTemplateClosePermissionForm,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isPermissionFormOpen,
  } = useController({ tableState });
  return (
    <>
      <modalTemplates.UserEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={handleTemplateCloseEditForm}
        id={tableState.selectedRows[0]?.id}
        login={tableState.selectedRows[0]?.Login}
        connectedPerson={tableState.selectedRows[0]?.Person}
        relatedTableState={tableState}
      />
      <modalTemplates.UserPermissionTemplate.Component
        isOpen={isPermissionFormOpen}
        onClose={handleTemplateClosePermissionForm}
        id={tableState.selectedRows[0]?.id}
        login={tableState.selectedRows[0]?.Login}
        connectedPerson={tableState.selectedRows[0]?.Person}
        relatedTableState={tableState}
      />
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить пользователя? (${tableState.selectedRows[0].Login})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
