import { Table } from 'types/models';
import { Toolbar } from './Toolbar';

type RequestData = {
  enterpriseCustomerIds: string[];
  enterpriseIds: string[];
};

export function GetPartnerExpositionParticipationList({
  enterpriseCustomerIds,
  enterpriseIds,
}: RequestData): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetPartnerExpositionParticipationList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    requestData: { enterpriseCustomerIds, enterpriseIds },
  };
}
