import { Table } from 'types/models';
import * as R from 'ramda';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  enumMap: Table.EnumMap;
};

export const filterSystemEnums = ({ enumMap }: Props): Table.EnumMap => {
  const PUBLICATION_TYPES_TO_EXCLUDE = [
    PublicationTypes.COMPILATION_ARTICLES.code,
    PublicationTypes.ARTICLE_ARTICLES.code,
    PublicationTypes.DEPOSITED.code,
  ];

  const enumMapCopy = R.clone(enumMap);
  if ('PublicationType' in enumMapCopy) {
    enumMapCopy.PublicationType.values = enumMapCopy.PublicationType.values.filter(({ value }) =>
      PUBLICATION_TYPES_TO_EXCLUDE.every(publicationTypeCode => publicationTypeCode !== value),
    );
  }

  return enumMapCopy;
};
