import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, ConfirmPopup, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as modalTemplates from './modalTemplates';

type WrapperProps = {
  estimateId: string;
  viewMode?: boolean;
  handleRefreshEstimate?: Function;
  handleBalanceClick?: Function;
};

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({
  estimateId,
  viewMode,
  handleRefreshEstimate = () => {},
  handleBalanceClick = () => {},
}: WrapperProps) =>
  React.memo(({ tableState }: Props) => {
    const tableStreams = useLocalTableStreams();
    const selectedRowsLength = tableState.selectedRows.length;
    const rowsAvailability: Table.ToolbarStateRowsAvailability = {
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    };

    const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
    const isBalanceButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;
    const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;

    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
    const [nextPublicationName, setNextPublicationName] = useState('');

    const handleCloseDeleteConfirmPopup = useCallback(() => {
      setIsDeleteConfirmPopupOpen(false);
    }, []);

    const handleConfirmDeleteConfirmPopup = useCallback(() => {
      tableStreams.deleteRow.push({
        deleteRowId: tableState.selectedRows[0].id,
        command: 'DeleteEstimatePosition',
        deletedItemPropName: 'EstimatePosition',
      });
      handleCloseDeleteConfirmPopup();
      handleRefreshEstimate();
    }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

    const handleDeleteButtonClick = useCallback(() => {
      setIsDeleteConfirmPopupOpen(true);
    }, []);
    const handleAddButtonClick = useCallback(() => {
      setNextPublicationName('');
      setIsAddFormOpen(true);
    }, []);
    const handleTemplateCloseAddForm = useCallback(() => {
      setIsAddFormOpen(false);
      handleRefreshEstimate();
    }, []);
    const handleEditButtonClick = useCallback(() => {
      setIsEditFormOpen(true);
    }, []);
    const handleTemplateCloseEditForm = useCallback(() => {
      setIsEditFormOpen(false);
      handleRefreshEstimate();
    }, []);
    const handleBalanceButtonClick = useCallback(() => {
      handleBalanceClick(
        tableState.selectedRows.map(x => ({ id: x.id || '', estimateId: '', kindConsumptionId: x.kindConsumptionId || '' })),
      );
    }, [tableState.selectedRows]);

    const buttons: IconButtonProps[] = useMemo(
      () => [
        ...(viewMode
          ? []
          : [
              {
                icons: buttonIcons.plus,
                title: 'Добавить',
                code: 'add',
                isDisabled: false,
                onClick: handleAddButtonClick,
              },
              {
                icons: buttonIcons.edit,
                title: 'Редактировать',
                code: 'edit',
                isDisabled: isEditButtonDisabled,
                onClick: handleEditButtonClick,
              },
              {
                icons: buttonIcons.delete,
                title: 'Удалить',
                code: 'remove',
                isDisabled: isDeleteButtonDisabled,
                onClick: handleDeleteButtonClick,
              },
              {
                icons: buttonIcons.balance,
                title: 'Установить Сумма, план = Сумма, факт',
                code: 'align',
                isDisabled: isBalanceButtonDisabled,
                onClick: handleBalanceButtonClick,
              },
            ]),
        // {
        //   icons: buttonIcons.info,
        //   title: 'Планируемые расходы по статьям затрат',
        //   isDisabled: false,
        //   onClick: handleExpenseButtonClick,
        // },
        // {
        //   icons: buttonIcons.viewSource,
        //   title: 'Планируемые расходы по выбранным статьям затрат',
        //   isDisabled: isDetailsButtonDisabled,
        //   onClick: handleDetailsButtonClick,
        // },
      ],
      [
        handleAddButtonClick,
        isEditButtonDisabled,
        handleEditButtonClick,
        isDeleteButtonDisabled,
        handleDeleteButtonClick,
        isBalanceButtonDisabled,
        handleBalanceButtonClick,
      ],
    );

    return (
      <>
        {!isEditButtonDisabled && (
          <modalTemplates.EstimatePositionEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            estimateId={estimateId}
          />
        )}
        <modalTemplates.EstimatePositionAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          estimateId={estimateId}
        />
        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранную позицию? (${tableState.selectedRows[0].id})`}
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            onConfirm={handleConfirmDeleteConfirmPopup}
            okButtonText="Да"
          />
        )}

        <Toolbar buttons={buttons} />
      </>
    );
  });

export const Component = LeftPanelForThirdLevel;
