import { useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const [isDocumentListModalOpen, setIsDocumentListModalOpen] = useState<boolean>(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const [row] = useMemo(() => tableState.selectedRows, [tableState]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.viewList,
        title: 'Список документов бухгалтерского учёта о поступлении средств на шифр проекта',
        isDisabled: !row,
        onClick: () => setIsDocumentListModalOpen(true),
      },
      {
        icons: buttonIcons.clock,
        title: 'История изменений планового значения процента накладных расходов для проекта',
        isDisabled: !row,
        onClick: () => setIsHistoryModalOpen(true),
      },
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [row],
  );

  const documentsModalTitle = useMemo(() => {
    return `Поступления на шифр ${row?.ProjectCode} за ${row?.EstimateYear} год`;
  }, [row]);

  return {
    buttons,
    isDocumentListModalOpen,
    setIsDocumentListModalOpen,
    isHistoryModalOpen,
    setIsHistoryModalOpen,
    documentsModalTitle,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
};

export default useController;
