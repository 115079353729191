import React from 'react';

import { ReferenceItem, FormComponent, Reference } from 'components';

type Props = {
  country: ReferenceItem | null;
  onCountryChange(row: ReferenceItem): void;
};

function CountryInput({ country, onCountryChange }: Props) {
  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Страна" isRequired>
          <Reference
            name="RefCountry"
            value={country ?? null}
            relationTableModalTitle='Справочник "Страны"'
            onChange={onCountryChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { CountryInput };
