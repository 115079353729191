import React, { useState, useEffect, useCallback } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import CompilationAddTemplateType from './CompilationAddTemplateType';

export type SelectProp = {
  id: string;
  label: string;
  code: string;
  text: string;
};

const CompilationAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id } = props;
  const [title, setTitle] = useState<string | JSX.Element>('');
  const [isCompilationAddTemplateTypePopupOpen, setIsCompilationAddTemplateTypePopupOpen] = useState<boolean>(true);
  const [publicationType, setPublicationType] = useState<SelectProp>({
    id: '1',
    label: 'Сборник научных трудов',
    text: 'Сборник, содержащий исследовательские материалы научных учреждений, учебных заведений или обществ',
    code: 'COMPILATION_SCIENTIFIC_WORK',
  });
  const [electronicPublicationType, setElectronicPublicationType] = useState<SelectProp>({
    id: ElectronicType.WEB.id,
    label: ElectronicType.WEB.label,
    text: ElectronicType.WEB.text,
    code: ElectronicType.WEB.code,
  });
  const [isElectronic, setIsElectronic] = useState<boolean>(false);

  const onConfirm = useCallback(() => {
    setIsCompilationAddTemplateTypePopupOpen(false);
  }, []);

  const look: F.CompilationFormLook = {
    id,
    apiID: 'GetPublication',
    template: 'CompilationForm',
    type: publicationType.code,
    initialTypeEdition: 'OTHER_UNIVERSITY',
    isElectronic,
    electronicType: electronicPublicationType.code,
    relatedTableState,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) {
      setPublicationType({
        id: '1',
        label: 'Сборник научных трудов',
        text: 'Сборник, содержащий исследовательские материалы научных учреждений, учебных заведений или обществ',
        code: 'COMPILATION_SCIENTIFIC_WORK',
      });
      setElectronicPublicationType({
        id: ElectronicType.WEB.id,
        label: ElectronicType.WEB.label,
        text: ElectronicType.WEB.text,
        code: ElectronicType.WEB.code,
      });
      setIsElectronic(false);
      setIsCompilationAddTemplateTypePopupOpen(true);
    }
  }, [isOpen]);

  return (
    <>
      {isCompilationAddTemplateTypePopupOpen ? (
        <Modal isOpen={isOpen} onClose={onClose} title="Выберите тип публикации" size="medium">
          <CompilationAddTemplateType
            onClose={onClose}
            onConfirm={onConfirm}
            publicationType={publicationType}
            electronicPublicationType={electronicPublicationType}
            setPublicationType={setPublicationType}
            setElectronicPublicationType={setElectronicPublicationType}
            isElectronic={isElectronic}
            setIsElectronic={setIsElectronic}
          />
        </Modal>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
          <Form look={look} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};

export const Component = React.memo(CompilationAddTemplate);
