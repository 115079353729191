import { Departments } from 'features/Table/views';
import { EmployeePayment } from 'types/models/Payment';

type Props = {
  employeePayment: EmployeePayment | null;
  subText: string;
};

export const RequestHeader = ({ employeePayment, subText }: Props): string | JSX.Element => {
  const title = (() => {
    const details = [
      employeePayment?.id ? `ID ${employeePayment.id}` : '',
      employeePayment?.status?.label ? `Статус заявки: ${employeePayment.status.label}` : '',
    ]
      .filter(x => x)
      .join(', ');

    return employeePayment?.id
      ? `Заявка на оплату стимулирующих выплат сотрудникам из Фонда подразделения. ${details ? `(${details})` : ''}`
      : 'Заявка по сотрудникам подразделения';
  })();

  return (
    <>
      <h3>{title}</h3>
      <h4>
        <Departments departments={subText} />
      </h4>
    </>
  );
};
