import { Dispatch, SetStateAction } from 'react';
import { Form } from 'types/models';
import * as R from 'ramda';
import { ElectronicType } from 'utils/Enums/ElectronicType';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  preparedData: Form.Publication;
  name: string | undefined;
};

const setByResponse = ({ setFormFields, preparedData, name }: Props) => {
  const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
  const getRequiredLens = (fieldName: string) => R.lensPath([fieldName, 'required']);

  setFormFields(prevFields =>
    (R.pipe as any)(
      R.set(getValueLens('name'), preparedData.fields.name || name),
      R.set(getValueLens('annotation'), preparedData.fields.annotation),
      R.set(getValueLens('authorsKeyWords'), preparedData.fields.authorsKeyWords),
      R.set(getValueLens('authorsCount'), preparedData.fields.authorsCount),
      R.set(getValueLens('fractionalScore'), preparedData.fields.fractionalCount),
      R.set(getValueLens('doi'), preparedData.fields.doi),
      R.set(getValueLens('publicationLink'), preparedData.fields.url),
      R.set(getValueLens('publicationDate'), preparedData.fields.publicationDate),
      R.set(
        getRequiredLens('publicationLink'),
        preparedData.fields.isElectronic === 'true' && preparedData.fields.electronicType === ElectronicType.WEB.code,
      ),
      R.set(getValueLens('year'), preparedData.fields.year),
      R.set(getValueLens('typeEdition'), {
        label: preparedData.fields.typeEditionText,
        id: preparedData.fields.typeEditionCode,
      }),
      R.set(getValueLens('textLanguage'), {
        id: preparedData.fields.textLanguageId || '',
        label: preparedData.fields.textLanguageLabel || '',
      }),
      R.set(getValueLens('printPageCount'), preparedData.fields.printPageCount || undefined),
      R.set(getValueLens('pageFrom'), preparedData.fields.startPage),
      R.set(getValueLens('pageTo'), preparedData.fields.endPage),
      R.set(getValueLens('grnti'), {
        id: preparedData.fields.grntiId || '',
        label: preparedData.fields.grntiLabel || '',
      }),
      R.set(getValueLens('udk'), preparedData.fields.udk),
      R.set(getValueLens('study'), {
        id: preparedData.fields.studyId || '',
        label: preparedData.fields.studyLabel || '',
      }),
      R.set(getValueLens('articleNumber'), preparedData.fields.articleNumber),
      R.set(getValueLens('file'), preparedData.file),
      R.set(getValueLens('electronicMediaSource'), preparedData.fields.electronicMediaSource),
      R.set(getValueLens('electronicSystemRequirements'), preparedData.fields.electronicSystemRequirements),
      R.set(
        getRequiredLens('electronicMediaSource'),
        preparedData.fields.isElectronic === 'true' && preparedData.fields.electronicType === ElectronicType.LOCAL.code,
      ),
      R.set(getValueLens('date'), preparedData.fields.urlDate),
      R.set(getRequiredLens('date'), preparedData.fields.isElectronic === 'true'),
      R.set(getValueLens('bibliographicRecord'), preparedData.fields.bibliographicRecord),

      R.set(getValueLens('foreignName'), preparedData.fields.foreignName),
      R.set(getValueLens('foreignAnnotation'), preparedData.fields.foreignAnnotation),
      R.set(getValueLens('foreignAuthorsKeyWords'), preparedData.fields.foreignAuthorsKeyWords),
      R.set(getValueLens('foreignAuthors'), preparedData.fields.foreignAuthors),
    )(prevFields),
  );
};

export { setByResponse };
