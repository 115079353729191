import { Statistic } from 'types/models';

import { getAttribute, getText } from '../commonConverters';

export function convertCommonStatistic(data: any): Statistic.Common {
  return {
    publications: convertCommonType(data.Publications),
    conferences: convertCommonType(data.Conferences),
    contests: convertCommonType(data.Contests),
    exhibitions: convertCommonType(data.Exhibitions),
    concerts: convertCommonType(data.Concerts),
    recognitions: convertCommonType(data.Recognitions),
    intellectual: ({
      university: convertCommonTypeIntellectual(data.IntellectualProperty.University),
      department: convertCommonTypeIntellectual(data.IntellectualProperty.Department),
      my: convertCommonTypeIntellectual(data.IntellectualProperty.My),
    } as unknown) as Statistic.CommonIntellectual,
  };
}

const convertCommonType = (data: any) => {
  return ({
    university: convertCommonTypeValue(data.University),
    department: convertCommonTypeValue(data.Department),
    ...(data.Drafts ? { drafts: getText(data.Drafts) } : {}),
    notAccepted: getText(data.NotAccepted),
    ...(data.My && data.NotAcceptedMy
      ? {
          my: ({
            common: convertCommonTypeValue(data.My),
            ...(data.DraftsMy ? { drafts: getText(data.DraftsMy) } : {}),
            notAccepted: getText(data.NotAcceptedMy),
          } as unknown) as Statistic.CommonTypeMy,
        }
      : {}),
  } as unknown) as Statistic.CommonType;
};

const convertCommonTypeValue = (data: any): Statistic.CommonTypeValue => {
  return {
    overall: getAttribute({ attributeKey: 'overall', item: data }) ?? '0',
    byYear: getAttribute({ attributeKey: 'currentYear', item: data }) ?? '0',
  };
};

const convertCommonTypeIntellectual = (data: any): Statistic.CommonIntellectualValue => {
  return {
    total: convertCommonTypeValueIntellectual('total', data),
    requests: convertCommonTypeValueIntellectual('requests', data),
    patents: convertCommonTypeValueIntellectual('patents', data),
    evidences: convertCommonTypeValueIntellectual('evidences', data),
    knowHows: convertCommonTypeValueIntellectual('knowHows', data),
  };
};

const convertCommonTypeValueIntellectual = (key: string, data: any): Statistic.CommonTypeValue => {
  return {
    overall: getAttribute({ attributeKey: key, item: data }) ?? '0',
    byYear: getAttribute({ attributeKey: `${key}ByYear`, item: data }) ?? '0',
  };
};
