import React, { useEffect } from 'react';

import { FormComponent, Tab, Tabs, Toolbar } from 'components';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { DataGrid } from 'features/Table';
import { GetProjectAssignmentMessageList } from 'features/Table/specifications/GetProjectAssignmentMessageList';

import { Component as AboutProjectAssignment } from './Tabs/AboutProjectAssignment/AboutProjectAssignment';
import { MessageModal } from 'features/Form/views/MessageModal/MessageModal';
import { submitTextMap, titleTextMap } from 'features/Table/specifications/GetProjectAssignmentList/Toolbar/Toolbar';
import { FileInfo, Form } from 'types/models';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectAssignmentForm({ viewMode, editMode, onClose }: Props) {
  const {
    look: { setTitle, hasPersonalMode },
  } = useFormContext<Form.Look & { hasPersonalMode: boolean }>();

  const {
    projectAssignment,
    projectAssignmentId,
    project,
    buttons,
    workMode,
    makeChangeHandler,
    projectSpecification,
    projectTableRowConverter,
    handleProjectChange,
    isChangeStatusModalOpen,
    setIsChangeStatusModalOpen,
    handleSaveStatusClick,
    newStatus,
    isMessageListShown,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const submitsMap: Record<string, (message: string, file: FileInfo) => void> = {
    IN_ACTION: message => handleSaveStatusClick(message),
    COMPLETE: message => handleSaveStatusClick(message),
    CLOSED: message => handleSaveStatusClick(message),
    CANCELED: message => handleSaveStatusClick(message),
  };

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (workMode === 'addMode') {
            return `Добавление поручения`;
          }

          const details = [
            project ? `по номеру проекта ${project.number}, ${project.startDate} - ${project.endDate}` : '',
            projectAssignment?.status?.label || '',
            projectAssignment?.id ? `ID ${projectAssignment?.id}` : '',
          ]
            .filter(x => x)
            .join(', ');

          const title = [workMode === 'viewMode' ? 'Просмотр поручения' : 'Редактирование поручения', details ? details : '']
            .filter(x => x)
            .join(' ');

          return (
            <>
              <h3>{title}</h3>
            </>
          );
        })(),
      );
    }
  }, [projectAssignment, project, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />
      <FormComponent.Template>
        <Tabs>
          <Tab title="Поручение">
            <AboutProjectAssignment
              workMode={workMode}
              projectAssignment={projectAssignment}
              project={project}
              handleProjectChange={handleProjectChange}
              projectTableRowConverter={projectTableRowConverter}
              makeChangeHandler={makeChangeHandler}
              projectSpecification={projectSpecification}
              onChangeFile={makeChangeHandler('file')}
              hasPersonalMode={hasPersonalMode}
            />
          </Tab>
          <Tab title="Сообщения по поручению">
            {isMessageListShown && (
              <DataGrid
                specification={GetProjectAssignmentMessageList({
                  projectAssignmentId,
                  disabled: workMode !== 'editMode',
                })}
              />
            )}
          </Tab>
        </Tabs>
        <MessageModal
          isOpen={isChangeStatusModalOpen}
          onClose={() => setIsChangeStatusModalOpen(false)}
          onSubmit={submitsMap[newStatus.toString()]}
          titleText={titleTextMap[newStatus.toString()]}
          submitText={submitTextMap[newStatus.toString()]}
          hasFileAttachment={newStatus === 'MESSAGE'}
        />
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectAssignmentForm);
