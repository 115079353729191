import { Specification } from 'types/models/Table';

type Props = {
  estimateId: string;
};

export function GetEstimateStatusHistoryList({ estimateId }: Props): Specification {
  const requestData = { estimateId };
  return {
    apiID: 'GetEstimateStatusHistoryList',
    requestData,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
