import React, { useState, useEffect, useMemo } from 'react';

import { Form } from 'features/Form';
import { Form as F, PublicationTypeProps } from 'types/models';

import { Modal } from 'components';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import PublicationAddTemplateType from './PublicationAddTemplateType';
import { PublicationType } from 'types/models/Publication/publicationTypes';

const PublicationAddTemplate: React.FC<F.TemplateProps & PublicationTypeProps> = (
  props: F.TemplateProps & PublicationTypeProps,
) => {
  const {
    isOpen,
    onClose,
    relatedTableState,
    id,
    name,
    handleChangeParticipation,
    isHidden,
    setPublication,
    setPublicationConferenceSource,
  } = props;

  const [type, setType] = useState<PublicationType | null>(null);
  const [title, setTitle] = useState<string | JSX.Element>('');
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [electronicPublicationType, setElectronicPublicationType] = useState<PublicationType>({
    id: ElectronicType.WEB.id,
    label: ElectronicType.WEB.label,
    labelDeclined: ElectronicType.WEB.label,
    text: ElectronicType.WEB.text,
    code: ElectronicType.WEB.code,
  });

  const look = useMemo(
    () => ({
      id,
      apiID: 'GetPublication',
      type: type?.code || '',
      template: type?.template,
      name,
      isElectronic,
      electronicType: electronicPublicationType.code,
      relatedTableState,
      initialTypeEdition: 'LOCAL',
      handleChangeParticipation,
      setPublication,
      setPublicationConferenceSource,
      setTitle,
    }),
    [
      id,
      type?.code,
      type?.template,
      name,
      isElectronic,
      electronicPublicationType.code,
      relatedTableState,
      handleChangeParticipation,
      setPublication,
      setPublicationConferenceSource,
    ],
  );
  useEffect(() => {
    if (isOpen) {
      setType(null);
      setElectronicPublicationType({
        id: ElectronicType.WEB.id,
        label: ElectronicType.WEB.label,
        labelDeclined: ElectronicType.WEB.label,
        text: ElectronicType.WEB.text,
        code: ElectronicType.WEB.code,
      });
      setIsElectronic(false);
    }
  }, [isOpen]);
  const changeSelectedType = (val: PublicationType) => {
    setType(val);
    if (val.isOnPaperOnly) {
      setIsElectronic(false);
    }
  };

  return (
    <>
      {!isHidden &&
        (type ? (
          <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
            <Form look={look} onClose={onClose} />
          </Modal>
        ) : (
          <Modal isOpen={isOpen} onClose={onClose} title="Выберите тип публикации" size="medium">
            <PublicationAddTemplateType
              onClose={onClose}
              onSelect={changeSelectedType}
              isElectronic={isElectronic}
              setIsElectronic={setIsElectronic}
              electronicPublicationType={electronicPublicationType}
              setElectronicPublicationType={setElectronicPublicationType}
            />
          </Modal>
        ))}
    </>
  );
};

export const Component = React.memo(PublicationAddTemplate);
