import React from 'react';

import { MobileRequest } from 'types/models';
import { FormComponent, InputSelect, Option } from 'components';
import { SelectPerson } from 'features/SelectPerson';

import { useController } from './controller';

type Props = {
  entry: MobileRequest.ApplicantSupervisor | null;
  onFieldChange: React.Dispatch<React.SetStateAction<MobileRequest.ApplicantSupervisor | null>>;
};

function ApplicantSupervisorFields({ entry, onFieldChange }: Props) {
  const { handlePersonChange, handleRoleChange, convertApplicantSupervisorToMember, supervisorRoleOptions } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired={true}>
          <SelectPerson member={convertApplicantSupervisorToMember(entry)} onUpdateMember={handlePersonChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="triplePadded">
        <FormComponent.Field label="Роль" isRequired={true}>
          <InputSelect options={supervisorRoleOptions} value={entry?.role as Option<string>} onSelectChange={handleRoleChange} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { ApplicantSupervisorFields };
