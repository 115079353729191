import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { Table } from 'types/models';
import { State, ThirdLevelButton } from '../model';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

export function useController({
  tableState,
  onSubmitTable,
  customState: {
    openEditor,
    setMode,
    buttonsToHide,
    referenceMetadata,
    loadReferenceFieldsValues,
    setEditableRowId,
    deleteReference,
    loadModificationHistory,
    historyEntries,
  },
}: Props) {
  const streams = useLocalTableStreams();
  const getSelectedRow = useCallback(() => {
    if (tableState.selectedRows.length) {
      const [row] = tableState.selectedRows;
      return row;
    }
    return null;
  }, [tableState.selectedRows]);

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpenConfirmDeleteModal(false);
  }, [setIsOpenConfirmDeleteModal]);

  const closeHistoryModal = useCallback(() => {
    setIsOpenHistoryModal(false);
  }, [setIsOpenHistoryModal]);

  const confirmDelete = useCallback(() => {
    const row = getSelectedRow();
    if (row) {
      deleteReference(row.id);
    }
    closeModal();
  }, [deleteReference, getSelectedRow, closeModal]);

  const isNeedHideButton = useCallback(
    (buttonName: ThirdLevelButton) => {
      const isButtonToHideContainButtonName = buttonsToHide?.some(buttonToHideName => buttonToHideName === buttonName);
      return isButtonToHideContainButtonName;
    },
    [buttonsToHide],
  );

  const isSelectedRow = Boolean(tableState.selectedRows.length);
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.check,
        title: 'Выбрать',
        isDisabled: getSelectedRow() === null,
        isHidden: isNeedHideButton('submit'),
        onClick: () => {
          if (onSubmitTable) {
            onSubmitTable(tableState);
          } else {
            streams.submitTable.push(tableState);
          }
        },
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        isHidden: isNeedHideButton('add'),
        isDisabled: !referenceMetadata?.addable,
        onClick: () => {
          openEditor();
          setMode('add');
        },
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isHidden: isNeedHideButton('edit'),
        isDisabled: !(
          isSelectedRow &&
          referenceMetadata?.editable &&
          (getSelectedRow()?.editable === 'true' || !!referenceMetadata.fields.find(x => x.editable))
        ),
        onClick: () => {
          const row = getSelectedRow();
          if (row) {
            setEditableRowId(row.id);
            loadReferenceFieldsValues(row.id);
          }
          openEditor();
          setMode('edit');
        },
      },
      {
        icons: buttonIcons.delete,
        isHidden: isNeedHideButton('delete'),
        isDisabled: !isSelectedRow || !referenceMetadata?.deletable || getSelectedRow()?.deletable === 'false',
        title: 'Удалить',
        onClick: () => {
          setIsOpenConfirmDeleteModal(true);
        },
      },
      {
        icons: buttonIcons.loop,
        isDisabled: !tableState.selectedRows.length,
        isHidden: isNeedHideButton('view'),
        title: 'Просмотр',
        onClick: () => {
          const row = getSelectedRow();
          if (row) {
            loadReferenceFieldsValues(row.id);
          }
          openEditor();
          setMode('view');
        },
      },
      {
        icons: buttonIcons.bookOpen,
        isDisabled: !tableState.selectedRows.length,
        isHidden: isNeedHideButton('history'),
        title: 'История изменений',
        onClick: () => {
          const row = getSelectedRow();
          if (row) {
            loadModificationHistory(row.id);
          }
          setIsOpenHistoryModal(true);
        },
      },
    ],
    [
      isNeedHideButton,
      tableState,
      streams.submitTable,
      openEditor,
      setMode,
      getSelectedRow,
      referenceMetadata,
      isSelectedRow,
      loadReferenceFieldsValues,
      setEditableRowId,
      loadModificationHistory,
      setIsOpenHistoryModal,
      onSubmitTable,
    ],
  );

  return {
    buttons,
    isOpenConfirmDeleteModal,
    closeModal,
    confirmDelete,
    historyEntries,
    isOpenHistoryModal,
    closeHistoryModal,
  };
}
