import { Dispatch, SetStateAction } from 'react';
import { Form } from 'types/models';
import { Participation } from 'types/models/Participation';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  data: Participation;
};

const setByResponse = ({ setFormFields, data }: Props) => {
  setFormFields((prevState: Form.Fields) => ({
    ...prevState,
    note: { ...prevState.note, value: data.note },
    teamName: { ...prevState.teamName, value: data.teamName },
    form: { ...prevState.form, value: data.form },
  }));
};

export { setByResponse };
