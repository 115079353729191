import React, { useCallback, useMemo } from 'react';

import { TextArea, TextAreaMode, TextInput, FormComponent, TextDateTime, UploadFile } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  report: Project.StateRegistrationReport;
  stages: Project.Stage[];
  setReport(report: Project.StateRegistrationReport): void;
  udkList: Project.RefElement[];
  isFieldsHidden: boolean;
  disabled: boolean;
};

export function Fields(props: Props) {
  const { report, stages, setReport, udkList, isFieldsHidden, disabled } = props;

  const stage = stages.find(x => x.number === report.stageNumber);

  const makeChangeHandler = useCallback(
    (key: keyof Project.StateRegistrationReport) => (value: ValueOf<Project.StateRegistrationReport>) => {
      setReport({ ...report, [key]: value });
    },
    [report, setReport],
  );

  const udkListData = useMemo(() => udkList.map(udk => udk.ref?.label).join(', '), [udkList]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Этап">
          <div>{stage ? getStageTitle(stage) : ''}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Название этапа"
          tooltip="Название этапа должно соответствовать названию этапа в заявке на проект. Объем до 2000 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Название этапа' }}
            value={report.stageName}
            onChange={makeChangeHandler('stageName')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Полученные результаты и их применимость"
          // eslint-disable-next-line
          tooltip="В краткой свободной форме дается описание нового материала, устройства, продукта и других результатов проекта в рамках данного этапа, в также возможного метода использования результатов проекта. Объем до 5000 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Полученные результаты и их применимость' }}
            value={report.expectedResults}
            onChange={makeChangeHandler('expectedResults')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Обоснование междисциплинарного подхода"
          // eslint-disable-next-line
          tooltip="Данное поле заполняется только при наличии указания разных тематических рубрик первого уровня (ГРНТИ и Кодов Международной Классификации). В краткой свободной форме обосновывается междисциплинарный характер работы, относящйися к разным тематическим уровням. Объем - до 3000 знаков"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Обоснование междисциплинарного подхода' }}
            value={report.interdisciplinaryAproachJustification}
            onChange={makeChangeHandler('interdisciplinaryAproachJustification')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Тип отчёта">
          <div>{report.reportType?.label}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="УДК">
          <FormComponent.Description>{udkListData}</FormComponent.Description>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Название отчёта">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Название отчёта' }}
            value={report.reportName}
            onChange={makeChangeHandler('reportName')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Реферат">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Реферат' }}
            value={report.referat}
            onChange={makeChangeHandler('referat')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ключевые слова">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Ключевые слова' }}
            value={report.keyWords}
            onChange={makeChangeHandler('keyWords')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!isFieldsHidden && (
        <>
          <FormComponent.Line lineSize="padded" hasGap>
            <FormComponent.Field label="Отчет сдан в ЦИТиС">
              <TextDateTime value={report.citisDate} onChange={makeChangeHandler('citisDate')} isDisabled={disabled} />
            </FormComponent.Field>
            <FormComponent.Field label="Номер отчёта (ИК)" labelSize="fit">
              <TextInput value={report.reportIkNumber} onChange={makeChangeHandler('reportIkNumber')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line hasGap>
            <FormComponent.Field label="Инвентарный номер отчёта в архиве">
              <TextInput
                value={report.reportArchiveNumber}
                onChange={makeChangeHandler('reportArchiveNumber')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Отправлен заказчику" labelSize="fit">
              <TextDateTime
                value={report.reportCustomerSendDate}
                onChange={makeChangeHandler('reportCustomerSendDate')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Файл отчёта">
          <UploadFile file={report?.file} onChange={makeChangeHandler('file')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
