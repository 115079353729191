import { GetLinkedHistoryEntitiesRequestData } from 'services/BackendAPI/configurations/author/types';
import { Table } from 'types/models';
import { Toolbar } from './Toolbar/Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = { personId?: string } & GetLinkedHistoryEntitiesRequestData;

export const GetLinkedHistoryEntities = ({
  id,
  type,
  personId,
}: Props): Table.Specification<State, GetLinkedHistoryEntitiesRequestData> => ({
  apiID: 'GetLinkedHistoryEntities',
  requestData: { id, type },
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: Toolbar,
    },
  },
  useCustomController: makeUseCustomController({ personId, type, id }),
});
