import React from 'react';
import block from 'bem-cn';

import { Modal } from 'components';

import { DataGrid } from 'features/Table';
import { GetImpactFactorList } from 'features/Table/specifications';

const b = block('toolbar-form-template');

type Props = {
  onClose: () => void;
  isOpen: boolean;
  id: string;
  isFiveYear: boolean;
};

const UpperFormPublications = ({ isOpen, onClose, id, isFiveYear }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title={`Публикации для расчета совокупного ${isFiveYear ? 'пятилетнего ' : ''}импакт-фактора`}
    size="large"
  >
    <div className={b()}>
      <h4 className={b('subtitle')}>
        Показатель рассчитывается на основе данных в системе по публикациям автора, индексируемым в зарубежных базах данных
        цитирования Scopus и/или WoS в изданиях. Имеющих текущее значение импакт-факторов.
      </h4>
      <div className={b('table')}>
        <DataGrid specification={GetImpactFactorList({ scientistId: id, workMode: 'viewMode', isFiveYear })} />
      </div>
    </div>
  </Modal>
);

export default React.memo(UpperFormPublications);
