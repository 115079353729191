import React from 'react';

import {
  CustomSelect,
  FormComponent,
  InputSelect,
  ListEdit,
  Radio,
  Reference,
  ReferenceItem,
  SectionTitle,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  UploadImage,
} from 'components';

import { TenderCustomerInputs } from 'features/Form/looks/tender/TenderForm/TenderCustomerInputs/TenderCustomerInputs';
import { showNotification } from 'features/Notifications';
import { Form } from 'types/models';
import { Customer, Lot, Tender } from 'types/models/Tender';
import { GetReferenceScientistListSpecification, GetReferenceElementList } from 'features/Table/specifications';
import { useController } from './controller';
import { TenderRequestType } from 'utils/Enums';
import { getMockPerson } from 'features/Form/looks/person/helpers';
import { Lots } from './Lots/Lots';
import { WorkMode } from 'types/models/Table';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  viewMode?: boolean;
  workMode: WorkMode;
  formFields: Form.Fields;
  onProgramIdChange(value: string): void;
  onProgramEventIdChange(value: string): void;
  customers: Customer[];
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  categories: ReferenceItem[];
  participants: ReferenceItem[];
  scienceDomainInterrests: ReferenceItem[];
  handleCustomersFieldChange(value: Customer[]): void;
  handleCategoriesChange(value: ReferenceItem[]): void;
  handleParticipantsChange(value: ReferenceItem[]): void;
  handleScienceDomainInterrestChange(value: ReferenceItem[]): void;
  requestType: TenderRequestType;
  tender: Tender;
  setTender: React.Dispatch<React.SetStateAction<Tender>>;
  lots: Lot[];
  handleLotsChange(value: Lot[]): void;
};

export function TenderTab(props: Props) {
  const {
    viewMode,
    workMode,
    formFields,
    onProgramIdChange,
    onProgramEventIdChange,
    customers,
    categories,
    participants,
    scienceDomainInterrests,
    handleCustomersFieldChange,
    handleCategoriesChange,
    handleParticipantsChange,
    handleScienceDomainInterrestChange,
    requestType,
    tender,
    setTender,
    lots,
    handleLotsChange,
    setCustomers,
  } = props;

  const {
    typeOptions,
    collectiveTypeOptions,
    programSpecification,
    modalTableRowConverter,
    programEventSpecification,
    modalProgramEventTableRowConverter,
    onProgramChange,
    backgroundImageId,
    setBackgroundImageId,
  } = useController({
    viewMode,
    onProgramIdChange,
    onProgramEventIdChange,
    formFields,
    tender,
    setTender,
    setCustomers,
    workMode,
  });

  const { settings } = useAppDataContext();

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column specialStyles={{ width: '65%' }}>
        {/* <SectionTitle title="Конкурс" /> */}
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column specialStyles={{ width: '20%', height: '150px' }}>
              <UploadImage
                viewMode={!!viewMode}
                text="Логотип конкурса"
                id={formFields.logo.value?.id}
                onChange={formFields.logo.onChange}
                backgroundId={backgroundImageId}
              />
            </FormComponent.Column>
            <FormComponent.Column specialStyles={{ width: '80%' }}>
              <FormComponent.Line>
                <FormComponent.Field>
                  <CustomSelect
                    disabled={!!viewMode}
                    title={formFields.program.title}
                    relationTableModalTitle="Выберите программу из списка"
                    original={formFields.program.value}
                    onChange={onProgramChange}
                    onDeleteFunction={() => {
                      formFields.program.onChange(null);
                      setBackgroundImageId('');
                    }}
                    modalTableSpecification={programSpecification}
                    modalTableRowConverter={modalTableRowConverter}
                    isRequired={requestType === TenderRequestType.NIR_REQUEST}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field>
                  <CustomSelect
                    disabled={!!viewMode || !formFields.program.value?.id}
                    title={formFields.programEvent.title}
                    relationTableModalTitle="Мероприятие (вид конкурса)"
                    original={formFields.programEvent.value}
                    onChange={formFields.programEvent.onChange}
                    onDeleteFunction={() => {
                      formFields.programEvent.onChange(null);
                    }}
                    modalTableSpecification={programEventSpecification}
                    modalTableRowConverter={modalProgramEventTableRowConverter}
                    isRequired={requestType === TenderRequestType.NIR_REQUEST}
                  />

                  {formFields.programEvent.value?.direction?.name && (
                    <FormComponent.Line lineSize="doublePadded">
                      <FormComponent.Field label="">
                        <FormComponent.Description mode="warning" classMixin="is-hint">
                          <div>
                            <strong>Направление (категория конкурса): </strong> {formFields.programEvent.value?.direction?.name}
                          </div>
                        </FormComponent.Description>
                      </FormComponent.Field>
                    </FormComponent.Line>
                  )}
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label={formFields.name.title} isRequired>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3 }}
              value={formFields.name.value}
              onChange={formFields.name.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label={formFields.description.title}>
            <TextArea
              mode={TextAreaMode.wysiwyg}
              settings={{ rows: 3 }}
              value={formFields.description.value}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column specialStyles={{ width: '50%' }}>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label={formFields.collectiveType.title} isRequired={formFields.collectiveType.required}>
                  <InputSelect
                    disabled={!!viewMode}
                    options={collectiveTypeOptions}
                    value={formFields.collectiveType.value}
                    onSelectChange={formFields.collectiveType.onChange}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column specialStyles={{ width: '50%' }}>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label={formFields.url.title}>
                  <TextInput
                    mode={TextInputMode.url}
                    value={formFields.url.value}
                    onChange={formFields.url.onChange}
                    isDisabled={!!viewMode}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column specialStyles={{ width: '33.3%' }}>
              <FormComponent.Line lineSize="padded">
                <ListEdit
                  rows={categories || []}
                  isToolbarDisabled={!!viewMode}
                  onChange={handleCategoriesChange}
                  columns={[{ label: 'Категории конкурса', formatValue: ref => ref?.label }]}
                  isDeleteConfirmEnabled
                  title=""
                  withMessages
                  visibleToolbarButtons={['add', 'delete']}
                  specification={{
                    mode: 'relationTableModal',
                    relationTableModalTitle: 'Справочник "Категории конкурса"',
                    modalTableRowConverter: row => ({ label: row.label, id: row.id }),
                    modalTableSpecification: GetReferenceElementList({
                      requestData: {
                        filters: [],
                        name: 'RefTenderCategory',
                      },
                    }),
                  }}
                />
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column specialStyles={{ width: '33.3%' }}>
              <FormComponent.Line lineSize="padded">
                <ListEdit
                  rows={participants || []}
                  isToolbarDisabled={!!viewMode}
                  onChange={handleParticipantsChange}
                  columns={[{ label: 'Участники конкурса', formatValue: ref => ref?.label }]}
                  isDeleteConfirmEnabled
                  title=""
                  withMessages
                  visibleToolbarButtons={['add', 'delete']}
                  specification={{
                    mode: 'relationTableModal',
                    relationTableModalTitle: 'Справочник "Участники конкурса"',
                    modalTableRowConverter: row => ({ label: row.label, id: row.id }),
                    modalTableSpecification: GetReferenceElementList({
                      requestData: {
                        filters: [],
                        name: 'RefTenderParticipant',
                      },
                    }),
                  }}
                />
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column specialStyles={{ width: '33.3%' }}>
              <FormComponent.Line lineSize="padded">
                <ListEdit
                  rows={scienceDomainInterrests || []}
                  isToolbarDisabled={!!viewMode}
                  onChange={handleScienceDomainInterrestChange}
                  columns={[{ label: 'Области науки', formatValue: ref => ref?.label }]}
                  isDeleteConfirmEnabled
                  title=""
                  withMessages
                  visibleToolbarButtons={['add', 'delete']}
                  specification={{
                    mode: 'relationTableModal',
                    relationTableModalTitle: 'Справочник "Области науки"',
                    modalTableRowConverter: row => ({ label: row.label, id: row.id }),
                    modalTableSpecification: GetReferenceElementList({
                      requestData: {
                        filters: [],
                        name: 'RefScienceDomainInterest',
                      },
                    }),
                  }}
                />
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
        <FormComponent.Line>
          <SectionTitle title="Список лотов конкурса" />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <Lots
            viewMode={viewMode}
            lots={lots}
            handleLotsChange={handleLotsChange}
            tenderName={formFields.name?.value}
            tenderStartDate={formFields.dateSummingUp?.value}
          />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <SectionTitle title="Заказчики" />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <ListEdit
            title=""
            rows={customers}
            onChange={handleCustomersFieldChange}
            withMessages
            isDeleteConfirmEnabled
            // maxHeight="320px"
            isToolbarDisabled={!!viewMode}
            columns={[
              { label: 'Организация', formatValue: row => row.fullName },
              { label: 'Адрес', formatValue: row => row.address },
              { label: 'Контакты', formatValue: row => row.contacts },
            ]}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => <TenderCustomerInputs customer={row} onCustomerChange={onChange as any} />,
              validation: {
                checkIsValid: row => !!row?.customerId,
                onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
              },
            }}
          />
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label={`План по заявкам ${settings?.organization?.shortName}`}>
            <div style={{ maxWidth: '70px' }}>
              <TextInput
                mode={TextInputMode.number}
                value={formFields.requestsPlan.value}
                onChange={formFields.requestsPlan.onChange}
                settings={{ max: 99999, decimal: false }}
                isDisabled={!!viewMode}
              />
            </div>
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Дополнительная информация">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3, title: 'Дополнительная информация' }}
              value={formFields.marketplaceNote.value}
              onChange={formFields.marketplaceNote.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column specialStyles={{ width: '30%' }}>
        <SectionTitle title="Порядок размещения заявок" />
        <FormComponent.Line>
          <FormComponent.Field label="Дата объявления" isRequired={formFields.announcementDate.required}>
            <TextDateTime
              value={formFields.announcementDate.value}
              onChange={formFields.announcementDate.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.dateUniversity.title} isRequired={formFields.dateUniversity.required}>
            <TextDateTime
              value={formFields.dateUniversity.value}
              onChange={formFields.dateUniversity.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата окончания приема заявок" isRequired={formFields.dateOpenEnvelope.required}>
            <TextDateTime
              value={formFields.dateOpenEnvelope.value}
              onChange={formFields.dateOpenEnvelope.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Время (МСК)">
            <TextDateTime
              type={['time']}
              onChange={formFields.timeOpenEnvelope.onChange}
              value={formFields.timeOpenEnvelope.value ?? ''}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата рассмотрения заявок" isRequired={formFields.dateExamenation.required}>
            <TextDateTime
              value={formFields.dateExamenation.value}
              onChange={formFields.dateExamenation.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата подведения итогов" isRequired={formFields.dateSummingUp.required}>
            <TextDateTime
              value={formFields.dateSummingUp.value}
              onChange={formFields.dateSummingUp.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <SectionTitle title="Определение победителя (исполнителя)" />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Способ определения победителя" isRequired={formFields.isMarketplace.value}>
            <Reference
              disabled={!!viewMode}
              relationTableModalTitle="Способ определения победителя"
              name="RefKindTrade"
              value={formFields.kindTrade.value}
              onChange={formFields.kindTrade.onChange}
              theme="big"
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.type.title} isRequired={formFields.isMarketplace.value}>
            <InputSelect
              disabled={!!viewMode}
              options={typeOptions}
              value={formFields.type.value}
              onSelectChange={formFields.type.onChange}
              isClearable
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <SectionTitle title="Электронная торговая площадка" />
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="">
            <Radio
              isDisabled={!!viewMode}
              value={formFields.isMarketplace.value ? 'true' : 'false'}
              list={[
                { label: 'Да', value: 'true' },
                { label: 'Нет', value: 'false' },
              ]}
              onChange={(value: string) => formFields.isMarketplace.onChange(value === 'true')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Название ЭТП">
            <Reference
              value={formFields.marketplace.value}
              name="RefMarketplace"
              relationTableModalTitle="Электронные торговые площадки"
              onChange={formFields.marketplace.onChange}
              disabled={!!viewMode || !formFields.isMarketplace.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Тариф ЭТП">
            <TextInput
              mode={TextInputMode.number}
              value={formFields.marketplaceAmount.value ? formFields.marketplaceAmount.value.toString() : '0'}
              onChange={formFields.marketplaceAmount.onChange}
              isDisabled={!!viewMode || !formFields.isMarketplace.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <SectionTitle title="Консультанты по заявке" />
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <ListEdit
            rows={tender.contacts}
            onChange={x => setTender(prevState => ({ ...prevState, contacts: x }))}
            withMessages
            isModalHintHidden
            isDeleteConfirmEnabled
            isToolbarDisabled={!!viewMode}
            visibleToolbarButtons={['add', 'delete']}
            columns={[
              {
                label: 'Контактные данные',
                formatValue: row =>
                  [
                    row.fullName,
                    row.scientist?.email,
                    [row.scientist?.phone, row.scientist?.mobilePhone].filter(x => x).join(', '),
                  ]
                    .filter(i => i)
                    .join(', '),
              },
              { label: 'Фото', formatValue: row => !!row.avatar, styles: { width: '5%', textAlign: 'center' } },
            ]}
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "Персоны"',
              modalTableRowConverter: row => ({ ...getMockPerson({ initialCountryCode: null }), id: row.id }),
              modalTableSpecification: GetReferenceScientistListSpecification({ hasSelectButton: true }),
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Очные консультации по адресу">
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3 }}
              value={formFields.contactFace.value || ''}
              onChange={formFields.contactFace.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {/*
        <FormComponent.Line>
          <FormComponent.Field label="Подписант">
            <SelectMarketplaceSignatory
              marketplaceSignatory={formFields.marketplaceSignatory.value}
              onMarketplaceSignatoryChange={formFields.marketplaceSignatory.onChange}
              isDisabled={!!viewMode || !formFields.isMarketplace.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        */}
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
