import React from 'react';

import { Modal } from 'components';
import { Props as ComponentProps, Component } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & ComponentProps;

export const Container = ({ personIds, isOpen, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Объединение дубликатов" size="full">
    <Component personIds={personIds} onClose={onClose} />
  </Modal>
);
