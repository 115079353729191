import { Project } from 'types/models';

type GetPerformerNameByIndexArguments = {
  performers: Project.Performer[];
  index: number | null;
};

export const getPerformerNameByIndex = ({ performers, index }: GetPerformerNameByIndexArguments) => {
  return index !== null ? performers[index].fio : '';
};
