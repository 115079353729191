import React from 'react';

import {
  ListEdit,
  FormComponent,
  Tabs,
  Tab,
  UploadImage,
  ConfirmPopup,
  TextDateTime,
  UploadFile,
  TextArea,
  TextInput,
  TextInputMode,
} from 'components';
import {
  GetProgramEventList,
  GetProgramIndicatorsList,
  GetProgramIndicesList,
  GetProgramDomainKnowledgeList,
} from 'features/Table/specifications';

import { DataGrid } from 'features/Table';
import { showNotification } from 'features/Notifications';
import { useController } from './controller';
import { CustomerInputs } from './CustomerInputs/CustomerInputs';
import { DirectionInputs } from './DirectionInputs/DirectionInputs';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type HeaderProps = {
  viewMode?: boolean;
  editMode?: boolean;
  id?: string;
};

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

export const ProgramMHeader = ({ viewMode, editMode, id = '' }: HeaderProps) => {
  const result = [];
  if (viewMode) {
    result.push('Просмотр');
  } else if (editMode) {
    result.push('Редактирование');
  } else {
    result.push('Добавление');
  }

  result.push('программы');

  if (viewMode || editMode) {
    result.push(`(ID ${id})`);
  }

  return result.join(' ');
};

function ProgramForm({ viewMode, editMode, onClose }: Props) {
  const {
    customers,
    directions,
    formFields,
    handleCustomersFieldChange,
    handleDirectionsFieldChange,
    handleFormSubmit,
    programId,
    workMode,
    program,
    getProgram,
    saveConfirmText,
    isConfirmPopupOpened,
    setIsConfirmPopupOpened,
    handleOpenConfirm,
    handleSaveConfirm,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О программе">
            <FormComponent.Line>
              <FormComponent.Field label={formFields.name.title} isRequired>
                <TextArea settings={{ rows: 3 }} value={formFields.name.value} onChange={formFields.name.onChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.shortName.title} isRequired>
                <TextInput value={formFields.shortName.value} onChange={formFields.shortName.onChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.ColumnWrapper>
                <FormComponent.Column specialStyles={{ width: '80%' }}>
                  <FormComponent.Line>
                    <FormComponent.Field label="Сроки действия">
                      <TextDateTime
                        isRange
                        value={`${formFields.startDate.value || ''}-${formFields.endDate.value || ''}`}
                        onChange={(value: string) => {
                          const dates = value.split('-');
                          formFields.startDate.onChange(dates[0] || '');
                          formFields.endDate.onChange(dates[1] || '');
                        }}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label={formFields.url.title}>
                      <TextInput
                        mode={TextInputMode.url}
                        value={formFields.url.value}
                        onChange={formFields.url.onChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label={formFields.file.title}>
                      <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={!!viewMode} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </FormComponent.Column>
                <FormComponent.Column specialStyles={{ width: '20%', height: '130px' }}>
                  <UploadImage
                    text={formFields.logo.title}
                    id={formFields.logo.value?.id}
                    viewMode={!!viewMode}
                    onChange={formFields.logo.onChange}
                  />
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                title="Заказчики"
                rows={customers}
                onChange={handleCustomersFieldChange}
                withMessages
                isDeleteConfirmEnabled
                isToolbarDisabled={!!viewMode}
                columns={[
                  { label: 'Организация', formatValue: row => row.refCustomer?.label || '' },
                  { label: 'Тип источника финансирования', formatValue: row => row?.investmentSourceType?.label || '' },
                ]}
                specification={{
                  mode: 'customComponent',
                  renderComponent: (row, onChange) => <CustomerInputs customer={row} onCustomerChange={onChange as any} />,
                  validation: {
                    checkIsValid: row => !!row?.refCustomer?.id,
                    onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                  },
                }}
              />
            </FormComponent.Field>
          </Tab>
          <Tab title="Направления программы">
            <ListEdit
              title="Направления программы"
              rows={directions}
              onChange={handleDirectionsFieldChange}
              withMessages
              isDeleteConfirmEnabled
              isToolbarDisabled={!!viewMode}
              isFullScreenedTable={true}
              columns={[
                { label: 'Название направления', formatValue: row => row.name },
                { label: 'Краткое название', formatValue: row => row.code },
              ]}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <DirectionInputs direction={row} onDirectionChange={onChange as any} />,
                validation: {
                  checkIsValid: row => !!(row?.code && row?.name),
                  onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                },
              }}
            />
          </Tab>
          <Tab title="Мероприятия">
            <DataGrid
              key="GetProgramEventList"
              specification={GetProgramEventList({ programId: programId || '-1', isClearToolbar: workMode !== 'editMode' })}
            />
          </Tab>
          <Tab title="Индикаторы">
            <DataGrid
              key="GetProgramIndicatorsList"
              specification={GetProgramIndicatorsList({ programId: programId || '-1', program, getProgram, handleOpenConfirm })}
            />
          </Tab>
          <Tab title="Показатели">
            <DataGrid
              key="GetProgramIndicesList"
              specification={GetProgramIndicesList({ programId: programId || '-1', program, getProgram, handleOpenConfirm })}
            />
          </Tab>
          <Tab title="Области знаний">
            <DataGrid
              key="GetProgramDomainKnowledgeList"
              specification={GetProgramDomainKnowledgeList({
                programId: programId || '-1',
                program,
                getProgram,
                handleOpenConfirm,
              })}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <ConfirmPopup
        title="Предупреждение"
        text={saveConfirmText}
        isOpen={isConfirmPopupOpened}
        okButtonText="Сохранить и продолжить"
        resetButtonText="Отмена"
        onClose={() => setIsConfirmPopupOpened(false)}
        onConfirm={handleSaveConfirm}
        icon="warning"
      />
    </>
  );
}

export const Component = React.memo(ProgramForm);
