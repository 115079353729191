import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';

import { InputSelect, FormComponent, Option, Reference, ReferenceItem, TextInput } from 'components';

import { Person } from 'types/models';
import { EnumMap } from 'types/models/Table';
import { JobStatus } from 'utils/Enums';
import { Item } from 'types/models/common';
import { getEnum } from 'utils/Helpers';

type Props = {
  job: Person.ScientistJob | null;
  enumMap: EnumMap;
  onChange: React.Dispatch<React.SetStateAction<Person.ScientistJob | null>>;
};

export function Fields(props: Props) {
  const { job, onChange, enumMap } = props;

  const options = getEnum('JobStatus', enumMap);

  const makeChangeHandler = (fieldName: keyof Person.ScientistJob) => (value: Option | null | ReferenceItem | string) => {
    onChange(R.set(R.lensProp(fieldName), value, job!));
  };

  const handleChangeDefaultStatus = useCallback(() => {
    onChange(R.set(R.lensProp('status'), options.find(x => x.value === JobStatus.OTHER) || null, job!));
  }, [onChange, job, options]);

  const handleChangeRefAppointment = useCallback(
    (ref: ReferenceItem) => {
      onChange(R.set(R.lensProp('refAppointment'), ref, job!));
    },
    [onChange, job],
  );

  const handleChangeStatus = useCallback(
    (status: Item | null) => {
      if (!status?.value) return;
      onChange(prevState => ({
        ...prevState!,
        status,
        ...(status?.value !== JobStatus.LOCAL ? { staffState: null } : null),
      }));
    },
    [onChange],
  );

  useEffect(() => {
    if (job === null) {
      handleChangeDefaultStatus();
    }
  }, [job, handleChangeDefaultStatus]);

  const isDisabled = job?.isReadOnly ?? false;

  const renderExternalEmployeeFields = () => {
    return (
      <>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Статус" isRequired>
            <InputSelect
              disabled
              options={options}
              value={options.find(x => x.value === job?.status?.value) ?? options.find(x => x.value === JobStatus.OTHER) ?? null}
              onSelectChange={handleChangeStatus}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Организация" isRequired tooltip="Введите полное имя организации не используя аббревиатуру">
            <TextInput value={job?.enterpise} onChange={makeChangeHandler('enterpise')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Должность" isRequired>
            <Reference
              name="RefAppointment"
              value={job?.refAppointment ?? null}
              relationTableModalTitle='Справочник "Должности"'
              onChange={handleChangeRefAppointment}
              disabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {job?.status.value === JobStatus.LOCAL && (
          <FormComponent.Line hasGap lineSize="doublePadded">
            <FormComponent.Field label="Штатное состояние" isRequired>
              <Reference
                name="RefStaffState"
                value={job?.staffState ?? null}
                relationTableModalTitle='Справочник "Штатное состояние"'
                onChange={makeChangeHandler('staffState')}
                disabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Подразделение">
            <TextInput value={job?.department || undefined} onChange={makeChangeHandler('department')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
      </>
    );
  };

  return (
    <>
      {renderExternalEmployeeFields()}
      <>
        {(job?.creationSource || job?.modificationSource) && (
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Actions>
              <span>
                <strong>Создано: </strong>
                {job?.creationSource ? `Зарплата ${job?.creationSource} (${job?.creationDate})` : 'нет данных'}
              </span>
              <span>
                <strong> Изменено: </strong>
                {job?.modificationSource ? `Зарплата ${job?.modificationSource} (${job?.modificationDate})` : 'нет данных'}
              </span>
            </FormComponent.Actions>
          </FormComponent.Line>
        )}
      </>
    </>
  );
}
