import React from 'react';

import { InputSelect, TextInput, FormComponent } from 'components';

import { Project } from 'types/models';
import { useController } from './controller';

type Props = {
  stages: Project.Stage[];
  performerStage: Project.PerformerStage;
  setPerformerStage(performerStage: Project.PerformerStage): void;
};

export function Fields(props: Props) {
  const { stages, performerStage, setPerformerStage } = props;

  const { handleChangeStage, makeChangeHandler, stagesOptions } = useController({ performerStage, setPerformerStage, stages });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Этап">
          <InputSelect
            options={stagesOptions}
            value={stagesOptions.find(x => x.value === performerStage.stage?.number) ?? null}
            onSelectChange={handleChangeStage}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Описание работ">
          <TextInput value={performerStage.jobDescription} onChange={makeChangeHandler('jobDescription')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
