import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';
import { Table } from 'types/models';

import * as modalTemplates from './modalTemplates';
import { useController } from './controller';
import { DetailProps } from '..';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
  customState: DetailProps;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    isAddFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    isViewButtonDisabled,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    handleCloseApproveConfirmPopup,
    handleConfirmApproveConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    isApproved,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController(tableState, customState);
  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.CommunityViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.Community}
          relatedTableState={tableState}
          templateName={templateName}
          isApproved={isApproved}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.CommunityEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.Community}
          relatedTableState={tableState}
          templateName={templateName}
          isApproved={isApproved}
        />
      )}
      <modalTemplates.CommunityAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
        templateName={templateName}
      />
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранного члена? (${tableState.selectedRows[0].Person})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {!isApproveButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title={isApproved ? 'Перевод в статус "Добавлено"' : 'Утверждение'}
          text={
            isApproved
              ? // eslint-disable-next-line max-len
                `Вы действительно хотите перевести в статус "Добавлена" запись о выбранном члене сообщества: ${tableState.selectedRows[0].Person}?`
              : `Вы действительно хотите утвердить выбранного члена сообщества ${tableState.selectedRows[0].Person}?`
          }
          isOpen={isApproveConfirmPopupOpen}
          onClose={handleCloseApproveConfirmPopup}
          onConfirm={handleConfirmApproveConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ConfirmPopup
        title="Помощь в добавлении членов сообществ"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            В этом разделе отображается список участников научных сообществ (коллабораций, научно-технических советов, редакции
            научных журналов) университета и сторонних организаций. Информацию о своём участии в работе научных сообществ
            сторонних организаций вносят пользователи в Личном кабинете на вкладке «Персональные достижения».
          </p>
        </div>
      </ConfirmPopup>
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        scientistId={tableState.selectedRows[0]?.scientistId || ''}
      />
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
