import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  setKindConsumptionId(kindConsumptionId: string): void;
};

export function GetKindConsumptionSelectList({ setKindConsumptionId }: Props): Table.Specification<State> {
  return {
    apiID: 'GetKindConsumptionList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ setKindConsumptionId }),
  };
}

type ListProps = {
  hasSelectButton?: boolean;
};

export function GetKindConsumptionList({ hasSelectButton }: ListProps): Table.Specification<State> {
  return {
    apiID: 'GetKindConsumptionList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton,
      },
    },
  };
}
