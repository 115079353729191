import { buttonIcons, IconButtonProps } from 'components';
import { useMemo, useState, useCallback } from 'react';
import { Props } from './';

const useController = ({ isExportButtonDisabled, exportTableData, isWithQna, isWithExport }: Props) => {
  const [isAddQuestionFormOpen, setIsAddQuestioFormOpen] = useState(false);

  const handleAddQuestionButtonClick = useCallback(() => {
    setIsAddQuestioFormOpen(true);
  }, []);

  const handleTemplateCloseAddQuestionForm = useCallback(() => {
    setIsAddQuestioFormOpen(false);
  }, []);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.XMLDocument,
        title: 'Выгрузить в файл',
        onClick: exportTableData,
        isHidden: !isWithExport,
        isDisabled: isExportButtonDisabled,
      },
      {
        icons: buttonIcons.toolbarQuestion,
        title: 'Задать вопрос администратору',
        code: 'addQnaQuestion',
        isHidden: !isWithQna,
        onClick: handleAddQuestionButtonClick,
      },
    ],
    [exportTableData, handleAddQuestionButtonClick, isExportButtonDisabled, isWithQna, isWithExport],
  );
  return {
    buttons,
    handleTemplateCloseAddQuestionForm,
    isAddQuestionFormOpen,
  };
};

export default useController;
