import React from 'react';

import { FormComponent, ListEdit, TextDateTime, TextInput, TextInputMode } from 'components';

import { getMockFounder, getPercentOfAmountByPart } from 'features/EditMipPartner/helpers';
import { MipPartner } from 'types/models';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { Fields as FounderFields } from './Fields';
import { validate } from './validate';
import useController from './controller';

type Props = {
  charterCapital: MipPartner.CharterCapital;
  setCharterCapital: (charterCapital: MipPartner.CharterCapital) => void;
};

export const Fields = ({ charterCapital, setCharterCapital }: Props) => {
  const { makeHandler } = useController({ charterCapital, setCharterCapital });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Template>
        <FormComponent.Line hasGap>
          <FormComponent.Field isRequired label="Уставной капитал">
            <TextInput mode={TextInputMode.number} value={charterCapital.amount ?? ''} onChange={makeHandler('amount')} />
          </FormComponent.Field>
          <FormComponent.Field label="Дата изменения" isRequired>
            <TextDateTime value={charterCapital.editDate} onChange={makeHandler('editDate')} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEdit
            title="Учредители"
            isRequired
            withMessages
            isSaveAndAddCustomComponent
            isDeleteConfirmEnabled
            rows={charterCapital.founders}
            onChange={makeHandler('founders')}
            defaultRowsCount={10}
            maxHeight="none"
            columns={[
              {
                label: 'ФИО/Название организации',
                formatValue: x => (x.scientist ? x.scientist.fullName : x.enterpriseCustomer?.label ?? ''),
                styles: { width: '35%' },
              },
              {
                label: 'Доля в УК, %',
                formatValue: x => getPercentOfAmountByPart({ part: x.amount, amount: charterCapital.amount }),
                styles: { width: '15%' },
              },
              {
                label: 'Сумма',
                formatValue: x => formatNumber(x.amount),
                styles: { width: '15%' },
              },
              {
                label: 'Активы',
                formatValue: x => x.insertionAsserts ?? '',
                styles: { width: '35%' },
              },
            ]}
            columnIndexesForSumTotal={[1, 2]}
            specification={{
              mode: 'customComponent',
              renderComponent: (founder, setFounder) => (
                <FounderFields founder={founder ?? getMockFounder()} setFounder={setFounder} amount={charterCapital.amount} />
              ),
              validation: {
                checkIsValid: (founder, index, list, mode) => validate(founder, index, list, mode).every(x => x.isValid),
                onInvalidate: (founder, mode, index, list) => {
                  const validationInfo = validate(founder, index, list, mode);
                  showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
                },
              },
            }}
          />
        </FormComponent.Line>
      </FormComponent.Template>
    </FormComponent.Wrapper>
  );
};
