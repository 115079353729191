import { Project } from 'types/models';
import { ProjectScientistRole } from 'utils/Enums';
import { areIntervalsOverlapping, parse, isWithinInterval, differenceInDays, subDays } from 'date-fns';

import { formatStr } from 'utils/Constants/FormatStr';
import { getPerformerIndexByLastJobPeriodRole, getPerformersPeriodsByRole } from '.';
import { formatStringToDate } from 'utils/Helpers';

export function validate(project: Project.Project | null): { isValid: boolean; invalidMessage: string }[] {
  if (!project) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }
  const isValidCustomers = Boolean(project.customers.length);
  const isValidNumber = Boolean(project.number);
  const isValidTheme = Boolean(project.name);
  const isValidStartDate = Boolean(project.startDate);
  const isValidEndDate = Boolean(project.endDate);
  const isValidContractStatus = Boolean(project.projectContractStatus);
  const isValidDepartments = Boolean(project.departments.length);
  const isValidFinancings = Boolean(project.financings.length);
  const isValidStages = Boolean(project.stages.length);
  const isStopMoneyDateValid = project.isFinancingStopped ? Boolean(project.stopMoneyDate) : true;
  const isStopMoneyReasonValid = project.isFinancingStopped ? Boolean(project.stopMoneyReason) : true;

  return [
    { isValid: isValidCustomers, invalidMessage: 'Не добавлен ни один заказчик' },
    { isValid: isValidNumber, invalidMessage: 'Не введен номер проекта' },
    { isValid: isValidTheme, invalidMessage: 'Не указана тема проекта' },
    { isValid: isValidStartDate, invalidMessage: 'Не указана дата начала проекта' },
    { isValid: isValidEndDate, invalidMessage: 'Не указана дата окончания проекта' },
    { isValid: isValidContractStatus, invalidMessage: 'Не указан статус договора' },
    { isValid: isValidDepartments, invalidMessage: 'Не добавлено ни одно подразделение' },
    { isValid: isValidFinancings, invalidMessage: 'Не добавлен ни один источник финансирования' },
    { isValid: isValidStages, invalidMessage: 'Не добавлен ни один этап в календарный план' },
    { isValid: isStopMoneyDateValid, invalidMessage: 'Не выбрана дата прекращения финансирования' },
    { isValid: isStopMoneyReasonValid, invalidMessage: 'Не выбрана причина прекращения финансирования' },
    getIsProjectHasLeaders(project),
    getProjectHasCrossedLeaders(project),
    getProjectHasCrossedResponsiblePerformers(project),
    ...(isValidStartDate && isValidEndDate ? [getIsEveryStageWithinInterval(project)] : []),
    getIsEveryFinancingHasSource(project),
    getIsProjectFullfiledByLeaders(project),
    getIsPerformersPeriodsOverlapping(project),
  ];
}

export function optionalValidate(project: Project.Project | null): { isValid: boolean; invalidMessage: string }[] {
  if (!project) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  return [getIsLeadersPeriodFullfiled(project), getIsDuplicatePerformers(project)];
}

const getIsDuplicatePerformers = (project: Project.Project) => {
  const personsId = project.performers.map(x => x.person?.id);
  const filteresPersonsId = personsId.filter((x, i) => personsId.indexOf(x) !== i);

  if (filteresPersonsId.length) {
    const result = filteresPersonsId
      .map(id => {
        return project.performers
          .map(performer => {
            if (performer.person?.id == id) {
              return performer.person?.fullName;
            }
          })
          .filter(x => x)
          .join(' - ');
      })
      .join('<br />');
    return {
      isValid: false,
      invalidMessage: `В списке исполнителей присутствуют дубликаты персон:<br />${result}`,
    };
  }
  return { isValid: true, invalidMessage: '' };
};

const getIsSomePerformerJobPeriodsOverlapping = (jobPeriods: Project.JobPeriod[]) => {
  const isSomePerformerJobPeriodsOverlapping = jobPeriods.some(({ startDate, endDate }, index) =>
    jobPeriods.some((jobPeriodToCompare, jobPeriodToCompareIndex) => {
      if (jobPeriodToCompareIndex === index) return false;

      return areIntervalsOverlapping(
        { start: parse(startDate, formatStr, new Date()), end: parse(endDate, formatStr, new Date()) },
        {
          start: parse(jobPeriodToCompare.startDate, formatStr, new Date()),
          end: parse(jobPeriodToCompare.endDate, formatStr, new Date()),
        },
        { inclusive: true },
      );
    }),
  );

  return isSomePerformerJobPeriodsOverlapping;
};

const getProjectHasCrossedLeaders = (project: Project.Project) => {
  const periods = getPerformersPeriodsByRole(project.performers, ProjectScientistRole.LEADER);
  const isValid = !getIsSomePerformerJobPeriodsOverlapping(periods);

  return {
    isValid,
    invalidMessage:
      'В одно время может быть только один руководитель. Проверьте указанные периоды работ всех руководителей проекта',
  };
};

const getIsProjectFullfiledByLeaders = (project: Project.Project) => {
  const periods = getPerformersPeriodsByRole(project.performers, ProjectScientistRole.LEADER);
  const periodsDaysSum = periods.reduce(
    (sum, x) => sum + differenceInDays(formatStringToDate(x.endDate), formatStringToDate(x.startDate)) + 1,
    0,
  );
  const dateDiff = differenceInDays(formatStringToDate(project.endDate), formatStringToDate(project.startDate)) + 1;
  const isValid = periodsDaysSum >= dateDiff;

  return {
    isValid,
    invalidMessage: 'Руководитель должен быть назначен на протяжении всего проекта',
  };
};

const getProjectHasCrossedResponsiblePerformers = (project: Project.Project) => {
  const periods = getPerformersPeriodsByRole(project.performers, ProjectScientistRole.RESPONSIBLE_PERFORMER);

  const isValid = !getIsSomePerformerJobPeriodsOverlapping(periods);

  return {
    isValid,
    invalidMessage:
      'В одно время может быть только один отв. исполнитель. Проверьте указанные периоды работ всех отв. исполнителей проекта',
  };
};

const getIsLeadersPeriodFullfiled = (project: Project.Project) => {
  const leaders = project.performers.filter(performer =>
    performer.jobPeriods.some(jobPeriod => jobPeriod.role?.value === ProjectScientistRole.LEADER),
  );

  const invalidLeaders = leaders.filter(leader => {
    const leaderJobPeriod = leader.jobPeriods.find(jobPeriod => jobPeriod.role?.value === ProjectScientistRole.LEADER);
    const exLeaderJobPeriod = leader.jobPeriods.find(jobPeriod => jobPeriod.role?.value === ProjectScientistRole.EX_LEADER);
    const endTime = formatStringToDate(project.endDate).getTime();
    if (!!exLeaderJobPeriod?.endDate)
      return (
        endTime !== formatStringToDate(exLeaderJobPeriod?.endDate || '').getTime() ||
        subDays(formatStringToDate(exLeaderJobPeriod.startDate || ''), 1).getTime() !==
          formatStringToDate(leaderJobPeriod?.endDate || '').getTime()
      );
    else if (!!leaderJobPeriod?.endDate) return endTime !== formatStringToDate(leaderJobPeriod?.endDate || '').getTime();
    return false;
  });

  const invalidLeadersInfo = invalidLeaders.map(x => x.fio).join('<br />');

  return {
    isValid: !invalidLeaders.length,
    // eslint-disable-next-line max-len
    invalidMessage: `Дата окончания периода работы руководителя не соответствует дате окончания проекта или существует неучтенный период между периодами работы в роли руководителя:<br /><strong>${invalidLeadersInfo}</strong><br /><br />Если данные заполнены корректно - нажмите <strong>Сохранить</strong>`,
  };
};

const getIsPerformersPeriodsOverlapping = (project: Project.Project) => {
  const invalidPerformers = project.performers.filter(performer => getIsSomePerformerJobPeriodsOverlapping(performer.jobPeriods));
  const invalidPerformersInfo = invalidPerformers.map(x => x.fio).join('<br />');

  return {
    isValid: !invalidPerformers.length,
    invalidMessage: `Периоды работы участника колектива пересекаются:<br />${invalidPerformersInfo}`,
  };
};

const getIsProjectHasLeaders = (project: Project.Project) => {
  const leaderIndexes = getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.LEADER);

  return {
    isValid: Boolean(leaderIndexes.length),
    invalidMessage: 'Добавьте руководителя',
  };
};

const getIsEveryStageWithinInterval = (project: Project.Project) => {
  const startProjectDate = parse(project.startDate, formatStr, new Date());
  const endProjectDate = parse(project.endDate, formatStr, new Date());

  const projectInterval = { start: startProjectDate, end: endProjectDate };
  try {
    const validations = project.stages.map(({ startDate, endDate }) => {
      const startStageDate = parse(startDate, formatStr, new Date());
      const endStageDate = parse(endDate, formatStr, new Date());

      const isStartStageDateWithinInterval = isWithinInterval(startStageDate, projectInterval);
      const isEndStageDateWithinInterval = isWithinInterval(endStageDate, projectInterval);
      return {
        isValid: isStartStageDateWithinInterval && isEndStageDateWithinInterval,
        invalidMessage: 'Дата окончания последнего этапа в календарном плане не соответствует срокам проекта',
      };
    });
    return validations && validations?.length > 0 ? validations[0] : { isValid: true, invalidMessage: '' };
  } catch (e) {
    console.error(e);
    return {
      isValid: false,
      invalidMessage: 'Ошибка в датах проекта',
    };
  }
};

const getIsEveryFinancingHasSource = ({ financings }: Project.Project) => {
  const isEveryFinancingHasInvestmentSource = financings.every(({ source }) => Boolean(source?.id));

  return {
    isValid: isEveryFinancingHasInvestmentSource,
    invalidMessage: 'Не указаны источники финансирования',
  };
};
