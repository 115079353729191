import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent(
    [
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Показывать записи за год',
        checked: false,
      },
    ],
    'personal',
  );
});
