import React, { useState } from 'react';
import { block } from 'bem-cn';

import { Checkbox, TextInput, TextInputMode } from 'components';

import './style.scss';

const b = block('password-input');

type Props = {
  value: string;
  isDisabled?: boolean;
  onChange(val: string): void;
};

function PasswordField(props: Props) {
  const { value, isDisabled, onChange } = props;

  const [checked, setChecked] = useState(false);

  return (
    <div className={b()}>
      <div className={b('input')}>
        <TextInput
          mode={checked ? TextInputMode.text : TextInputMode.password}
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </div>
      <Checkbox label="Показать пароль" checked={checked} onChange={setChecked} />
    </div>
  );
}

export { PasswordField };
