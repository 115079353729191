export enum ContestRequestTab {
  REQUEST = 'REQUEST',

  INDICATORS = 'INDICATORS',

  PERFORMERS = 'PERFORMERS',

  ESTIMATE = 'ESTIMATE',

  DOCUMENTS = 'DOCUMENTS',
}
