import { GetLinkedHistoryEntitiesRequestData } from 'services/BackendAPI/configurations/author/types';

export type State = {
  personId?: string;
} & GetLinkedHistoryEntitiesRequestData;

type Props = {
  personId?: string;
} & GetLinkedHistoryEntitiesRequestData;

export function makeUseCustomController({ personId, id, type }: Props) {
  return function useCustomController(): State {
    return {
      personId,
      id,
      type,
    };
  };
}
