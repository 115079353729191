import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';

type Arguments = {
  personId: string;
};

type RequestData = {
  scientistId: string;
};

export function GetScientistSecurityDocumentList({ personId }: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetScientistSecurityDocumentList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Объекты интеллектуальной собственности',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    requestData: { scientistId: personId },
  };
}
