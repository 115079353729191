import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Arguments = {
  personId: string;
  workMode: T.WorkMode;
  isFiltersHidden?: boolean;
};

type RequestData = {
  scientistId: string;
};

export function GetPublicationListByScientist({
  personId,
  workMode,
  isFiltersHidden = false,
}: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetPublicationListByScientist',
    workMode,
    requestData: { scientistId: personId },
    isFiltersHidden: isFiltersHidden,
    header: {
      firstLevel: {
        title: 'Публикации',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    enumFilterValues: {
      enumName: 'PublicationType',
      excludeValues: [PublicationTypes.MAGAZINE.code],
    },
  };
}
