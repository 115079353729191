import * as BackendAPI from 'services/BackendAPI';
import { useMemo, useCallback, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { RecordStatus } from 'utils/Enums';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();
  const { methods: ApproveOtherProjectAPI } = BackendAPI.useBackendAPI('ApproveOtherProject');

  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);
  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState.selectedRows]);

  const isApproved = useMemo<boolean>(() => Boolean(row) && row!['id:Status'] === RecordStatus.APPROVED, [row]);
  const isApproveButtonDisabled = useMemo(() => !row, [row]);
  const isViewButtonDisabled = useMemo(() => !row, [row]);

  const onApprove = useCallback(() => {
    if (row?.id) {
      ApproveOtherProjectAPI.callAPI(
        { id: row.id, isDisapprove: isApproved },
        {
          onSuccessfullCall: () => {
            showNotification({
              message: isApproved ? 'Проект успешно переведен в статус "Добавлена"' : 'Проект успешно "Утвержден"',
              theme: 'success',
            });
            tableStreams.reloadTable.push();
          },
        },
      );
    }
  }, [ApproveOtherProjectAPI, isApproved, row?.id, tableStreams.reloadTable]);

  const onApproveClick = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const onApprovePopupSubmit = useCallback(() => {
    onApprove();
    setIsApprovePopupOpen(false);
  }, [onApprove]);

  const onApprovePopupClose = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const onViewClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const onViewClose = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: isViewButtonDisabled,
        onClick: onViewClick,
      },
      {
        icons: buttonIcons.approve,
        title: row ? (isApproved ? 'Перевести в статус "Добавлена"' : 'Утвердить') : '',
        permissionName: Permits.FOREIGN_PROJECT_APPROVE,
        isDisabled: isApproveButtonDisabled,
        onClick: onApproveClick,
      },
    ],
    [isApproveButtonDisabled, isApproved, isViewButtonDisabled, onApproveClick, onViewClick, row],
  );
  return {
    buttons,
    isViewFormOpen,
    row,
    isApproved,
    isApprovePopupOpen,
    onViewClose,
    onApprovePopupSubmit,
    onApprovePopupClose,
  };
};

export default useController;
