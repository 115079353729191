import {
  Form as F,
  SecurityDocument,
  SecurityDocumentMember,
  SecurityDocumentMemberTypes,
  SecurityDocumentTypes,
  Table as T,
} from 'types/models';
import React, { useCallback, useMemo } from 'react';
import { useAppDataContext } from 'features/AppData/context';
import { Project } from 'types/models/Project';
import { Original } from 'types/models/Form';
import { getOptionsFromEnum } from 'features/Form/looks/project/ProjectForm/helpers';
import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';
import { ReferenceItem } from 'components';

type Props = {
  patent?: SecurityDocument | null;
  setPatent?: React.Dispatch<React.SetStateAction<SecurityDocument | null | undefined>>;
};

export function useController(props: Props) {
  const { setPatent, patent } = props;

  const { enumMap } = useAppDataContext();
  const requestStatusOptions = useMemo(() => getOptionsFromEnum(enumMap.SecurityDocumentRequestStatus), [enumMap]);
  const projectRowConverter = useCallback<(row: T.Entry) => F.Translation>(
    row => ({
      ...row,
      type: { label: row.projectType },
      number: row.projectNumber,
      startDate: row.period.split(' - ')[0],
      endDate: row.period.split(' - ')[1],
      id: row.id,
      name: row.name,
    }),
    [],
  );

  const projectOriginal = useCallback(
    (project: Project): Original => ({
      id: project.id || '',
      name: `${project.type?.label}, ${project.number}, ${project.startDate} - ${project.endDate}, ${project.name}`,
    }),
    [],
  );

  const cloneAuthorsToAdmittedPersons = useCallback(() => {
    if (setPatent) {
      setPatent(prevPatent => {
        const prevAdmittedPersonIds =
          prevPatent?.members
            ?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.ADMITTED_PERSON)
            .map(member => member.person?.id || '') || [];
        const authors = prevPatent?.members?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.AUTHOR) || [];
        const filteredAuthorsFromDuplicatedPersons = authors.filter(
          member => !prevAdmittedPersonIds.includes(member.person?.id || ''),
        );
        const admitted = filteredAuthorsFromDuplicatedPersons.map(a => {
          return { ...a, id: null, type: { value: SecurityDocumentMemberTypes.ADMITTED_PERSON, label: '' } };
        });
        return { ...prevPatent!, members: [...(prevPatent?.members || []), ...admitted] };
      });
    }
  }, [setPatent]);

  const projectSpecification = GetFullScienceProjectList({});

  const handleAuthorsChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      if (setPatent) {
        setPatent(prevPatent => {
          const members = prevPatent?.members?.filter(p => p?.type?.value !== SecurityDocumentMemberTypes.AUTHOR) || [];
          return { ...prevPatent!, members: [...members, ...value] };
        });
      }
    },
    [setPatent],
  );
  const handleApplicantChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      if (setPatent) {
        setPatent(prevPatent => {
          const members = prevPatent?.members?.filter(p => p?.type?.value !== SecurityDocumentMemberTypes.APPLICANT) || [];
          return { ...prevPatent!, members: [...members, ...value] };
        });
      }
    },
    [setPatent],
  );

  const handleAdmittedChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      if (setPatent) {
        setPatent(prevPatent => {
          const members = prevPatent?.members?.filter(p => p?.type?.value !== SecurityDocumentMemberTypes.ADMITTED_PERSON) || [];
          return { ...prevPatent!, members: [...members, ...value] };
        });
      }
    },
    [setPatent],
  );

  const handleCountriesChange = useCallback(
    (value: ReferenceItem[]) => {
      if (setPatent) {
        setPatent(prevPatent => ({ ...prevPatent!, countries: value }));
      }
    },
    [setPatent],
  );

  const authorsList = useMemo(() => patent?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.AUTHOR) || [], [
    patent,
  ]);

  const admittedPersonList = useMemo(
    () => patent?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.ADMITTED_PERSON) || [],
    [patent],
  );

  const applicantPersonList = useMemo(
    () => patent?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.APPLICANT) || [],
    [patent],
  );

  const entityTitles: Record<string, string> = {
    PATENT: 'патента',
    EVIDENCE: 'свидетельства',
    KNOW_HOW: 'ноу-хау',
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const declinedTypeTitle = useMemo(() => (patent?.type ? entityTitles[patent.type.value] : ''), [patent?.type]);

  const documentTitles: Record<string, string> = {
    PATENT: 'Заявка',
    EVIDENCE: 'Свидетельство',
    KNOW_HOW: 'Ноу-хау',
  };
  const isHasDocumentType = useMemo(() => !!patent?.type?.value, [patent]);
  const isPatentDocument = useMemo(() => patent?.type?.value === SecurityDocumentTypes.PATENT, [patent]);
  const isKnowHowDocument = useMemo(() => patent?.type?.value === SecurityDocumentTypes.KNOW_HOW, [patent]);
  const isEvidenceDocument = useMemo(() => patent?.type?.value === SecurityDocumentTypes.EVIDENCE, [patent]);

  const leftColumnWidth = useMemo(() => (isPatentDocument || isKnowHowDocument ? '50%' : 'calc(100% - 690px)'), [
    isPatentDocument,
    isKnowHowDocument,
  ]);
  const rightColumnWidth = useMemo(() => (isPatentDocument || isKnowHowDocument ? '50%' : '690px'), [
    isPatentDocument,
    isKnowHowDocument,
  ]);
  const documentDatesTooltipText = useMemo(
    () =>
      isPatentDocument
        ? // eslint-disable-next-line max-len
          'По умолчанию система устанавливает срок действия - 10 лет. Для редактирования срока окончания поставьте курсор в поле года, удалите значение и введите новое'
        : // eslint-disable-next-line max-len
          'По умолчанию система устанавливает срок действия - 5 лет. Для редактирования срока окончания поставьте курсор в поле года, удалите значение и введите новое',
    [isPatentDocument],
  );
  return {
    requestStatusOptions,
    projectRowConverter,
    projectSpecification,
    projectOriginal,
    handleAuthorsChange,
    handleApplicantChange,
    handleAdmittedChange,
    handleCountriesChange,
    cloneAuthorsToAdmittedPersons,
    documentTitles,
    isPatentDocument,
    isKnowHowDocument,
    isEvidenceDocument,
    leftColumnWidth,
    rightColumnWidth,
    isHasDocumentType,
    authorsList,
    declinedTypeTitle,
    admittedPersonList,
    applicantPersonList,
    documentDatesTooltipText,
  };
}
