import { RecordStatus as RS } from 'types/models';

export class RecordStatus {
  static APPROVED: RS.Code = 'APPROVED';

  static DRAFT: RS.Code = 'DRAFT';

  static ADDED: RS.Code = 'ADDED';

  static DRAFT_LOWER_CASE: RS.Code = 'Draft';
}
