import React, { useState, useCallback, useEffect } from 'react';

import { Button, ButtonMode, Checkbox, FormComponent, TextInput } from 'components';

import { AddNewGridSettingData } from 'features/Table/specifications/GetGridSettings/streams';
import { Column, Setting } from 'types/models/Table';

type Props = {
  setting?: Setting;
  isSettingEditing: boolean;
  relatedColumns: Column[];
  submitCallback: (args: AddNewGridSettingData) => void;
  isDescriptionShowed?: boolean;
  resetComponentStateCallback: () => void;
};

export const Form = ({
  setting,
  relatedColumns,
  isSettingEditing,
  isDescriptionShowed,
  submitCallback,
  resetComponentStateCallback,
}: Props) => {
  const [editingSettingID, setEditingSettingID] = useState('');
  const [editingSettingName, setEditingSettingName] = useState('');
  const [settingName, setSettingName] = useState('');
  const [settingPurpose, setSettingPurpose] = useState('');
  const [isSettingShared, setIsSettingShared] = useState(false);

  useEffect(() => {
    if (isSettingEditing && setting) {
      setSettingName(setting.name);
      setEditingSettingName(setting.name);
      setSettingPurpose(setting.purpose);
      setEditingSettingID(setting.id);
      setIsSettingShared(setting.isShared);
    }
  }, [isSettingEditing, setting]);

  const resetComponentState = useCallback(() => {
    resetComponentStateCallback();
    setIsSettingShared(false);
    setSettingPurpose('');
    setSettingName('');
  }, [resetComponentStateCallback]);

  const handleFormSubmit = useCallback(() => {
    if (!settingName) {
      return;
    }

    submitCallback({
      columns: relatedColumns,
      settingName,
      purpose: settingPurpose,
      shared: isSettingShared,
      editedSettingID: isSettingEditing ? editingSettingID : undefined,
      editingSettingName: isSettingEditing ? editingSettingName : undefined,
    });
    resetComponentState();
  }, [
    settingName,
    submitCallback,
    relatedColumns,
    settingPurpose,
    isSettingShared,
    isSettingEditing,
    editingSettingID,
    editingSettingName,
    resetComponentState,
  ]);

  const handleNameFieldChange = useCallback((nextName: string) => {
    setSettingName(nextName);
  }, []);

  const handlePurposeFieldChange = useCallback((nextSettingPurpose: string) => {
    setSettingPurpose(nextSettingPurpose);
  }, []);

  return (
    <FormComponent.Wrapper>
      {!!isDescriptionShowed && (
        <FormComponent.Description mode="warning">
          Текущие настройки таблицы, в том числе, порядок размещения столбцов, сортировка и условия фильтрации в столбцах,
          сохранить в шаблоне
        </FormComponent.Description>
      )}
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field isRequired label="Название шаблона">
          <TextInput value={settingName} onChange={handleNameFieldChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Описание шаблона">
          <TextInput value={settingPurpose} onChange={handlePurposeFieldChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="">
          <Checkbox label="Поделиться со всеми" checked={!!isSettingShared} onChange={setIsSettingShared} />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Actions>
        <Button mode={ButtonMode.primary} text="Сохранить шаблон" onClick={handleFormSubmit} isDisabled={!settingName} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};
