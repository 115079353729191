import React from 'react';

import { Modal, TextArea, FormComponent } from 'components';

import { ValueOf } from 'types/helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { PurchaseRequestMessage } from 'types/models/PurchaseRequest';

type Props = {
  isOpen: boolean;
  message: PurchaseRequestMessage | null;
  changeMessage(key: keyof PurchaseRequestMessage, value: ValueOf<PurchaseRequestMessage>): void;
  onClose(): void;
  onSave(): void;
};

export function MessageModal(props: Props) {
  const { isOpen, onClose, message, onSave, changeMessage } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Отправить сообщение" size={['medium']}>
      <FormComponent.Template>
        <ControlPanel handleSave={onSave} />

        <FormComponent.Wrapper>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label="Сообщение">
              <TextArea settings={{ rows: 5 }} value={message?.message ?? ''} onChange={val => changeMessage('message', val)} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
}
