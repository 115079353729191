import React, { memo, useCallback } from 'react';
import { block } from 'bem-cn';

import { Select, Radio, FormComponent, TextInput, TextInputMode } from 'components';

import useController from './controller';
import { BuilderProps } from 'features/BuildReportPopup/models';
import { MONTH_LIST, QUARTER_LIST } from './helpers';

import './style.scss';

const b = block('payment-request-period');

const PaymentRequestPeriod = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const {
    year,
    periodRadioValue,
    currentQuarter,
    monthFrom,
    monthTo,
    periodParameter,
    canBuild,
    periodRadioList,
    isQuarterPeriod,
    isMonthPeriod,
    handleUpdatePeriodRadioValue,
    handleUpdateYear,
    handleUpdateMonth,
    handleUpdateQuarter,
  } = useController({ parameters, setParameterValueByName, parameterName });

  const renderEntry = useCallback(x => x, []);

  return (
    <>
      {canBuild && (
        <div className={b()}>
          <FormComponent.Line>
            <FormComponent.Field label={periodParameter?.description || ''} isRequired={!!periodParameter?.required}>
              <FormComponent.Line>
                <FormComponent.Field>
                  <FormComponent.Line>
                    <FormComponent.Field label="Год" labelSize="fit">
                      <TextInput mode={TextInputMode.year} value={year} onChange={handleUpdateYear} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <div className={b('periods')}>
                    <FormComponent.Line>
                      <FormComponent.Field>
                        <Radio
                          isInRow={false}
                          value={periodRadioValue}
                          list={periodRadioList}
                          onChange={handleUpdatePeriodRadioValue}
                          classMixin="line-offset"
                        />
                      </FormComponent.Field>
                      <FormComponent.Field>
                        <FormComponent.Line>
                          <FormComponent.Field labelSize="fit" isRequired={isQuarterPeriod} label="Квартал">
                            <Select.Component
                              disabled={!isQuarterPeriod}
                              value={currentQuarter?.name || ''}
                              variant="outlined"
                              defaultPlaceholder="Не задан"
                              entries={QUARTER_LIST.map(({ name }) => name)}
                              onChange={handleUpdateQuarter}
                              renderEntry={renderEntry}
                            />
                          </FormComponent.Field>
                        </FormComponent.Line>
                        <FormComponent.Line hasGap>
                          <FormComponent.Field labelSize="fit" isRequired={isMonthPeriod} label="Месяц с">
                            <Select.Component
                              disabled={!isMonthPeriod}
                              value={monthFrom?.label || ''}
                              variant="outlined"
                              defaultPlaceholder="Не задан"
                              entries={MONTH_LIST.map(({ label }) => label)}
                              onChange={handleUpdateMonth({ isFrom: true })}
                              renderEntry={renderEntry}
                            />
                          </FormComponent.Field>
                          <FormComponent.Field labelSize="fit" isRequired={isMonthPeriod} label=" - по">
                            <Select.Component
                              disabled={!isMonthPeriod}
                              value={monthTo?.label || ''}
                              variant="outlined"
                              defaultPlaceholder="Не задан"
                              entries={MONTH_LIST.map(({ label }) => label)}
                              onChange={handleUpdateMonth({ isFrom: false })}
                              renderEntry={renderEntry}
                            />
                          </FormComponent.Field>
                        </FormComponent.Line>
                      </FormComponent.Field>
                    </FormComponent.Line>
                  </div>
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
      )}
    </>
  );
};

const Component = memo(PaymentRequestPeriod);

export { Component as PaymentRequestPeriod };
