import React from 'react';
import { FormComponent, InputSelect } from 'components';
import { Item } from 'types/models/common';
import useController from './controller';

type Props = {
  role: Item<string> | null;
  isDisabled: boolean;
  isError?: boolean;
  onFocus: () => void;
  setRole: (role: Item<string> | null) => void;
};

export const SelectRole = ({ role, setRole, isDisabled, isError = false, onFocus }: Props) => {
  const { roleOptions } = useController();
  return (
    <FormComponent.Field isRequired label="Роль в проекте">
      <InputSelect
        isRequired
        disabled={isDisabled}
        options={roleOptions}
        value={role}
        onSelectChange={setRole}
        isClearable
        onFocus={onFocus}
        isError={isError}
      />
    </FormComponent.Field>
  );
};
