import { useState } from 'react';
import { useStream } from 'StreamRx';
import * as BackendAPI from 'services/BackendAPI';

import { TemplatesTableDependencies } from 'types/models/Table';
import { Form } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { AddNewMessageData, streams } from '../streams';
import { showNotification } from 'features/Notifications';

export type State = {
  isFormOpen: boolean;
  publicationMessage: Form.MagazineMessage;
  messageLabel: Form.MessageLabel;
  publicationId?: string;
};

const initMessage: Form.MagazineMessage = {
  id: '',
  message: '',
};

const initMessageLabel: Form.MessageLabel = {
  id: '1',
  label: '',
};

export function makeUseCustomController(deps: TemplatesTableDependencies) {
  return function useCustomController(): State {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [publicationMessage, setPublicationMessage] = useState<Form.MagazineMessage>(initMessage);
    const [messageLabel, setMessageLabel] = useState<Form.MessageLabel>(initMessageLabel);

    const tableStreams = useLocalTableStreams();

    const { methods: addNewMessage } = BackendAPI.useBackendAPI('SavePublicationUserFeedline', {
      onSuccessfullCall: ({ data }) => {
        if (!data.success) {
          showNotification({ theme: 'danger', message: data.message });

          setPublicationMessage(initMessage);
          setMessageLabel(initMessageLabel);
          return;
        }
        if (deps.relatedTableAPIID) {
          tableStreams.reloadTable.push();
        }
        setPublicationMessage(initMessage);
        setMessageLabel(initMessageLabel);
      },
    });

    useStream(
      () => streams.addNewGridSetting,
      ({ message, messageId, publicationId, labelId }: AddNewMessageData) => {
        if (message !== undefined && labelId && publicationId) {
          addNewMessage.callAPI({
            message,
            messageId,
            labelId,
            publicationId,
          });
        }
      },
      [],
    );

    useStream(
      () => streams.changeMessageState,
      ({ id, message }: Partial<Form.MagazineMessage>) => {
        setPublicationMessage({
          id: id || publicationMessage.id,
          message: message !== undefined ? message : publicationMessage.message,
        });
      },
      [setPublicationMessage, publicationMessage],
    );

    useStream(
      () => streams.changeMessageLabelState,
      (label: Form.MessageLabel) => setMessageLabel(label),
      [],
    );

    useStream(
      () => streams.toggleMessageForm,
      () => setIsFormOpen(prev => !prev),
      [setIsFormOpen],
    );

    useStream(
      () => streams.clearMessageState,
      () => setPublicationMessage(initMessage),
      [],
    );

    useStream(
      () => streams.clearMessageLabelState,
      () => setMessageLabel(initMessageLabel),
      [],
    );

    return {
      isFormOpen,
      publicationMessage,
      messageLabel,
      // eslint-disable-next-line max-len
      publicationId: deps.relatedRecordId, // to refactor заменить передачу айдишника публикации из relatedRecordId на проп publicationI
    };
  };
}
