import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { ReferenceItem } from 'components';

import { showNotification } from 'features/Notifications';
import { Table, Document, SecurityDocumentContract, Table as T } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { IdItem } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { validate } from '../validate';
import { Original } from 'types/models/Form';
import { GetEnterpriseCustomerSelectList } from 'features/Table/specifications';
import { EnterpriseFields } from 'services/BackendAPI/configurations/mips/types';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const [endDate, setEndDate] = useState<string>('');
  const [conclusionDate, setConclusionDate] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [paymentCondition, setPaymentCondition] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [isTransfered, setIsTransfered] = useState<boolean>(false);
  const [enterpriseCustomer, setEnterpriseCustomer] = useState<ReferenceItem | null>(null);
  const [kind, setKind] = useState<ReferenceItem | null>(null);
  const [paymentKind, setPaymentKind] = useState<ReferenceItem | null>(null);
  const [payments, setPayments] = useState<SecurityDocumentContract.Payment[]>([]);
  const [securityDocuments, setSecurityDocuments] = useState<IdItem<string>[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedEnterpriseTitle, setSelectedEnterpriseTitle] = useState<string>('');
  const [selectedEnterpriseCustomFields, setSelectedEnterpriseCustomFields] = useState<EnterpriseFields | null>(null);
  const [selectedSector, setSelectedSector] = useState<string>('');
  const [selectedEnterpriseType, setSelectedEnterpriseType] = useState<string>('');
  const [registrationDate, setRegistrationDate] = useState<string>('');
  const [registrationNumber, setRegistrationNumber] = useState<string>('');
  const [note, setNote] = useState<string>('');

  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { userToken } = useAppDataContext();

  const updateEnterpriseFromReference = useCallback((ref: ReferenceItem) => {
    const { customFields = Object } = ref;
    const enterpriseFields = customFields as EnterpriseFields;
    // @ts-ignore
    const { sectorActivity = Object, lksdEnterpriseType = Object } = customFields;
    setSelectedEnterpriseTitle(
      [
        enterpriseFields.fullName || '',
        enterpriseFields.inn ? `ИНН: ${enterpriseFields.inn}` : '',
        enterpriseFields.kpp ? `КПП: ${enterpriseFields.kpp}` : '',
      ]
        .filter(x => x)
        .join(' '),
    );
    setSelectedEnterpriseCustomFields(enterpriseFields);
    setSelectedSector(sectorActivity.label || '');
    setSelectedEnterpriseType(lksdEnterpriseType.label || '');
  }, []);

  const { methods: getSecurityDocumentContract } = BackendAPI.useBackendAPI('GetSecurityDocumentContract', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as SecurityDocumentContract.SecurityDocumentContract;
      setPaymentCondition(preparedData.paymentCondition);
      setNumber(preparedData.number);
      setStartDate(preparedData.startDate);
      setEndDate(preparedData.endDate);
      setConclusionDate(preparedData.conclusionDate);
      setSecurityDocuments(
        preparedData.securityDocuments?.map(doc => {
          return { id: doc.id || '', value: doc.label || '' };
        }) || [],
      );
      setPrice(preparedData.price);
      setIsTransfered(preparedData.isTransfered);
      setEnterpriseCustomer(preparedData.enterpriseCustomer);
      if (preparedData.enterpriseCustomer !== null) {
        updateEnterpriseFromReference(preparedData.enterpriseCustomer);
      }
      setKind(preparedData.kind);
      setPaymentKind(preparedData.paymentKind);
      setPayments(preparedData.payments);
      setDocuments(preparedData.documents);
      if (preparedData.enterpriseCustomer) handleEnterpriseCustomerFieldChange(preparedData.enterpriseCustomer);
      setRegistrationDate(preparedData.registrationDate || '');
      setRegistrationNumber(preparedData.registrationNumber || '');
      setNote(preparedData.note || '');
    },
  });

  const { methods: saveSecurityDocumentContract } = BackendAPI.useBackendAPI('SaveSecurityDocumentContract', {
    onSuccessfullCall: ({ data }) => {
      if (data.Response._attributes.success)
        showNotification({ message: 'Договор на использование ИС успешно сохранен', theme: 'success' });
      tableStreams.reloadTable.push();
    },
  });

  const onSubmit = useCallback((): boolean => {
    const errors: { isValid: boolean; invalidMessage: string }[] = validate({
      number,
      kindId: kind?.id,
      startDate,
      endDate,
      conclusionDate,
      enterpriseCustomerId: enterpriseCustomer?.id,
    });
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        showNotification({ message: error.invalidMessage, theme: 'danger' });
      });
      return false;
    }

    saveSecurityDocumentContract.callAPI({
      id: id || null,
      documents,
      endDate,
      conclusionDate,
      enterpriseCustomer,
      isTransfered,
      kind,
      number,
      paymentCondition,
      paymentKind,
      startDate,
      price,
      payments,
      registrationDate,
      registrationNumber,
      note,
      securityDocuments,
    });
    return true;
  }, [
    number,
    kind,
    startDate,
    endDate,
    conclusionDate,
    enterpriseCustomer,
    price,
    saveSecurityDocumentContract,
    id,
    documents,
    isTransfered,
    paymentCondition,
    paymentKind,
    payments,
    registrationDate,
    registrationNumber,
    note,
    securityDocuments,
  ]);

  const handlePaymentConditionFieldChange = useCallback((value: string) => {
    setPaymentCondition(value);
  }, []);

  const handleNumberFieldChange = useCallback((value: string) => {
    setNumber(value);
  }, []);

  const handleIsTransferedFieldChange = useCallback((e: boolean) => {
    setIsTransfered(e);
  }, []);

  const handlePriceFieldChange = useCallback((e: string) => {
    setPrice(e);
  }, []);

  const handleRangeDateFieldChange = useCallback((from: string, to: string) => {
    setStartDate(from);
    setEndDate(to);
  }, []);

  const enterpriseCustomerOriginal = useMemo((): Original => {
    return {
      id: enterpriseCustomer?.id || '',
      name: selectedEnterpriseTitle,
    };
  }, [enterpriseCustomer, selectedEnterpriseTitle]);

  const enterpriseCustomerRowConverter = useCallback<(row: T.Entry) => ReferenceItem>(row => {
    return {
      id: row.id,
      name: `${row.Name}`,
      label: `${row.Name}`,
    };
  }, []);

  const enterpriseCustomerSpecification = GetEnterpriseCustomerSelectList();

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const handleEnterpriseCustomerFieldChange = useCallback(
    (e: ReferenceItem | null) => {
      loadReferenceAPI.callAPI(
        { filters: [], referenceName: 'RefEnterpriseCustomer' },
        {
          onSuccessfullCall: ({ data }) => {
            const refElements = (data as ReferenceItem[]).filter(o => o.id === e?.id);
            if (refElements && refElements.length > 0) {
              updateEnterpriseFromReference(refElements[0]);
            }
          },
        },
      );

      setEnterpriseCustomer(e);
    },
    [loadReferenceAPI, updateEnterpriseFromReference],
  );

  const handlePaymentKindFieldChange = useCallback((e: ReferenceItem) => {
    setPaymentKind(e);
  }, []);

  const handleKindFieldChange = useCallback((e: ReferenceItem) => {
    setKind(e);
  }, []);

  const handleConclusionDateChange = useCallback((value: string | null) => {
    if (value !== null) {
      setConclusionDate(value);
    } else {
      setConclusionDate('');
    }
  }, []);

  const handleSecurityDocumentsChange = useCallback((value: IdItem[] | null) => {
    setSecurityDocuments(value || []);
  }, []);

  const handlePaymentsFieldChange = useCallback((e: any) => {
    const value = e as SecurityDocumentContract.Payment[];
    setPayments(value);
  }, []);

  const handleDocumentsFieldChange = useCallback((e: any) => {
    const value = e as Document[];
    setDocuments(value);
  }, []);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const handleFormClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleRegistrationDateChange = useCallback((value: string | null) => {
    setRegistrationDate(value || '');
  }, []);

  const handleRegistrationNumberChange = useCallback((value: string) => {
    setRegistrationNumber(value);
  }, []);

  const handleNoteChange = useCallback((nextValue: string) => {
    setNote(nextValue || '');
  }, []);

  useLayoutEffect(() => {
    if (id) getSecurityDocumentContract.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    id,
    documents,
    endDate,
    enterpriseCustomer,
    handleDocumentsFieldChange,
    handleEnterpriseCustomerFieldChange,
    handleFormClose,
    handleFormSubmit,
    handleIsTransferedFieldChange,
    handleKindFieldChange,
    handleNumberFieldChange,
    handlePaymentConditionFieldChange,
    handlePaymentKindFieldChange,
    handlePriceFieldChange,
    handleRangeDateFieldChange,
    isTransfered,
    kind,
    number,
    paymentCondition,
    paymentKind,
    payments,
    price,
    startDate,
    workMode,
    handlePaymentsFieldChange,
    userToken,
    selectedEnterpriseTitle,
    selectedEnterpriseCustomFields,
    selectedSector,
    selectedEnterpriseType,
    conclusionDate,
    handleConclusionDateChange,
    handleRegistrationDateChange,
    handleRegistrationNumberChange,
    handleNoteChange,
    registrationDate,
    registrationNumber,
    note,
    securityDocuments,
    handleSecurityDocumentsChange,
    enterpriseCustomerOriginal,
    enterpriseCustomerRowConverter,
    enterpriseCustomerSpecification,
  };
}
