import { useEffect, useRef } from 'react';

const useController = () => {
  const selectedHourRef = useRef<HTMLButtonElement | null>(null);
  const selectedMinuteRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    selectedHourRef.current?.scrollIntoView({ block: 'center' });
    selectedMinuteRef.current?.scrollIntoView({ block: 'center' });
  }, []);

  return {
    selectedHourRef,
    selectedMinuteRef,
  };
};

export default useController;
