import React from 'react';

import { FormComponent, SectionTitle, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { NirRequest } from 'types/models';
import { isNumberValid } from 'utils/Validators';
import { ValueOf } from 'types/helpers';

type Props = {
  performer: NirRequest.Performer;
  onChangePerformer(performer: NirRequest.Performer): void;
  disabled: boolean;
  hasTitle?: boolean;
};

export function EditPerformerFields(props: Props) {
  const { performer, onChangePerformer, disabled, hasTitle } = props;

  const handleOnlyNumberChange = (key: keyof NirRequest.Performer) => (value: ValueOf<NirRequest.Performer>) => {
    if (isNumberValid(value as string)) {
      makeHandleChange(key)(value);
    }
  };

  const makeHandleChange = (key: keyof NirRequest.Performer) => (value: ValueOf<NirRequest.Performer>) => {
    const updatedPerformer: NirRequest.Performer = { ...performer, [key]: value };
    onChangePerformer(updatedPerformer);
  };
  /* eslint-disable max-len */
  const tooltipsMap = {
    awards:
      'Награды и премии за научную деятельность, членство в ведущих начных сообществах, участие в редколлегиях ведущих рецензируемых научных изданий. Не более 2000 знаков с пробелами',
    keyWords: 'Ключевые слова, позволяющие адекватно описать узкую область исследования (научное направление), не более 15',
    scientificGroundwork:
      'Имеющийся научный задел по теме данного проекта за последние 5 полных лет, конкретные научные результаты со ссылкой на научный проект. Не более 3000 знаков с пробелами',
    primePublications:
      'Список основных публикаций за 5 полных последних лет (не более 10 публикаций), на языке публикации, индексируемых в международных базах цитирования Scopus, Web of Science, с указанием числа цитирований каждой публикации, DOI (при наличии), квартиля журнала, принадлежности к высокоцитируемым публикациям (Highly-Cited Papers). Не более 3000 знаков с пробелами.',
    rids: 'Список результатов интеллектуальной деятельности, имеющие правовую охрану. Не более 3000 знаков с пробелами',
    projects:
      'Опыт выполнения научных проектов за последние 5 лет с указанием роли (исполнитель/руководитель), названия фонда (организации), номера, названия и сроков выполнения проекта. Не более 3000 знаков с пробелами',
    note:
      'Другая дополнительная информация, которая, по мнению руководителя проекта, может быть полезна для принятия решения о целесообразности финансирования данного проекта. Не более 500 знаков с пробелами',
  };
  /* eslint-enable max-len */

  return (
    <FormComponent.Wrapper>
      {hasTitle ? <SectionTitle title={performer.person?.fullName || 'Редактирование персоны'} /> : <></>}
      <FormComponent.Line>
        <FormComponent.Field tooltip={tooltipsMap.awards} label="Награды и премии за научную деятельность">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: tooltipsMap.awards }}
            value={performer.awards ?? ''}
            onChange={makeHandleChange('awards')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field tooltip={tooltipsMap.keyWords} label="Ключевые слова">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: tooltipsMap.keyWords }}
            value={performer.keyWords ?? ''}
            onChange={makeHandleChange('keyWords')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field tooltip={tooltipsMap.scientificGroundwork} label="Научный задел по теме">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: tooltipsMap.scientificGroundwork }}
            value={performer.scientificGroundwork ?? ''}
            onChange={makeHandleChange('scientificGroundwork')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Общее кол-во публикаций за 5 лет" contentSize="small">
          <TextInput
            mode={TextInputMode.number}
            value={performer.totalPublicationQty ?? ''}
            onChange={handleOnlyNumberChange('totalPublicationQty')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Из них Q1 Web of Science, Scopus" contentSize="small" labelStyle="thin">
          <TextInput
            mode={TextInputMode.number}
            value={performer.q1PublicationQty ?? ''}
            onChange={handleOnlyNumberChange('q1PublicationQty')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field tooltip={tooltipsMap.primePublications} label="Список основных публикаций">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: tooltipsMap.primePublications }}
            value={performer.primePublications ?? ''}
            onChange={makeHandleChange('primePublications')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field tooltip={tooltipsMap.rids} label="Список РИД">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: tooltipsMap.rids }}
            value={performer.rids ?? ''}
            onChange={makeHandleChange('rids')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field tooltip={tooltipsMap.projects} label="Список Выполненные научные проекты">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: tooltipsMap.projects }}
            value={performer.projects ?? ''}
            onChange={makeHandleChange('projects')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field tooltip={tooltipsMap.note} label="Дополнительная информация">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: tooltipsMap.note }}
            value={performer.note ?? ''}
            onChange={makeHandleChange('note')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
