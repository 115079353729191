import React, { useEffect } from 'react';

import { FormComponent, Tabs, Tab } from 'components';

import { Documents } from 'features/Documents';
import { DataGrid } from 'features/Table';
import { GetForeignSecurityDocumentFeedlineList } from 'features/Table/specifications/GetForeignSecurityDocumentFeedlineList';
import { AboutIPK } from './Tabs/AboutIPK/AboutIPK';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useAppDataContext } from 'features/AppData/context';
import { useFormContext } from 'features/Form/hooks';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ForeignSecurityDocumentForm({ viewMode, editMode, onClose }: Props) {
  const {
    foreignSecurityDocumentId,
    foreignSecurityDocument,
    formFields,
    workMode,
    documents,
    setDocuments,
    handleFormSubmit,
    setForeignSecurityDocument,
    relatedTableState,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const { settings } = useAppDataContext();

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          const orgName = settings?.organization?.shortName || '';

          if (workMode === 'addMode') {
            return `Добавление ОИС не аффилированного с ${orgName || ''}`;
          }
          const foreignSecurityDocumentDetails = [
            foreignSecurityDocument?.id ? `ID ${foreignSecurityDocument?.id}` : '',
            foreignSecurityDocument?.status?.label ? `Статус: ${foreignSecurityDocument?.status?.label}` : '',
          ]
            .filter(x => x)
            .join(', ');

          return [
            workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
            'ОИС не аффилированного с',
            orgName,
            foreignSecurityDocumentDetails ? `(${foreignSecurityDocumentDetails})` : '',
          ]
            .filter(x => x)
            .join(' ');
        })(),
      );
    }
  }, [
    foreignSecurityDocument?.id,
    foreignSecurityDocument?.status?.label,
    setTitle,
    settings?.organization?.shortName,
    workMode,
  ]);

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={() => handleFormSubmit(true)} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Сведения об ОИС">
            <AboutIPK
              disabled={viewMode}
              formFields={formFields}
              foreignSecurityDocument={foreignSecurityDocument}
              setForeignSecurityDocument={setForeignSecurityDocument}
            />
          </Tab>
          <Tab title="Прикреплённые документы">
            <Documents documents={documents} setDocuments={setDocuments} disabled={workMode === 'viewMode'} />
          </Tab>
          <Tab title="Журнал сообщений">
            {!!relatedTableState && (
              <DataGrid
                specification={GetForeignSecurityDocumentFeedlineList(
                  {
                    relatedTableAPIID: 'GetForeignSecurityDocumentList',
                    relatedTableState,
                    relatedRecordId: foreignSecurityDocumentId || undefined,
                  },
                  workMode,
                )}
                hasPersonalMode
              />
            )}
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ForeignSecurityDocumentForm);
