import React, { memo } from 'react';

import { PersonStatus as PersonStatusEnum } from 'utils/Enums/PersonStatus';
import { BuilderProps, RadioItem } from 'features/BuildReportPopup/models';
import { DefaultRadioList } from '../DefaultRadioList';

const PersonStatus = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const initialRadioList: RadioItem[] = [
    { label: PersonStatusEnum.LOCAL.name, value: PersonStatusEnum.LOCAL.value },
    { label: PersonStatusEnum.OTHER.name, value: PersonStatusEnum.OTHER.value },
    { label: PersonStatusEnum.STUDENTS.name, value: PersonStatusEnum.STUDENTS.value },
    { label: PersonStatusEnum.ASPIRANTS.name, value: PersonStatusEnum.ASPIRANTS.value },
  ];
  return (
    <DefaultRadioList
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
      parameterName={parameterName}
      initialRadioList={initialRadioList}
      defaultValue={PersonStatusEnum.LOCAL.value}
    />
  );
};

const Component = memo(PersonStatus);

export { Component as PersonStatus };
