import React, { useCallback, useMemo, useState } from 'react';
import { block } from 'bem-cn';

import { buttonIcons, IconButtonProps, TextArea, Toolbar, ListEditTable, Column, Modal, FormComponent } from 'components';

import './style.scss';

type Props<Row> = {
  value: Row | null;
  rows: Row[];
  columns: Column<Row>[];
  tableModalTitle: string;
  formatValue(value: Row | null): string;
  onChange(rows: Row | null): void;
  isDisabledListIcon?: boolean;
};

const b = block('table-select');

function TableSelect<Row>(props: Props<Row>) {
  const { formatValue, value, onChange, columns, rows, tableModalTitle, isDisabledListIcon } = props;

  const [isOpenTableModal, setIsOpenTableModal] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const handleClose = useCallback(() => {
    setIsOpenTableModal(false);
  }, [setIsOpenTableModal]);

  const handleClear = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const handleAdd = (event: any) => {
    event?.currentTarget.blur();
    setIsOpenTableModal(true);
  };

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.list,
        title: 'Добавить',
        code: 'list',
        isDisabled: isDisabledListIcon,
        onClick: handleAdd,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        isDisabled: isDisabledListIcon,
        onClick: handleClear,
      },
    ],
    [handleClear, isDisabledListIcon],
  );

  const handleChange = useCallback(
    (index: number) => {
      onChange(rows[index]);
      handleClose();
    },
    [onChange, handleClose, rows],
  );

  return (
    <div className={b()}>
      <div className={b('textarea-wrapper')} onClick={handleAdd}>
        <TextArea
          settings={{ rows: 2, isFixed: true }}
          value={formatValue(value)}
          onClick={() => setIsOpenTableModal(true)}
          isDisabled
          placeholder="Нажмите чтобы выбрать..."
        />
      </div>
      <div className={b('buttons')}>
        <Toolbar buttons={buttons} />
      </div>
      <Modal isOpen={isOpenTableModal} onClose={handleClose} title={tableModalTitle} size="extralarge">
        <FormComponent.Wrapper>
          <ListEditTable
            rows={rows}
            columns={columns}
            defaultRowsCount={8}
            selectedRowIndex={selectedRowIndex}
            selectRow={setSelectedRowIndex}
            onDoubleRowClick={handleChange}
          />
        </FormComponent.Wrapper>
      </Modal>
    </div>
  );
}

export { TableSelect };
