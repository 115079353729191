import { buttonIcons, IconButtonProps } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { Table } from 'types/models';
import { ProjectType } from 'utils/Enums';
import { State } from '../makeUseCustomController';
import { showNotification } from 'features/Notifications';

type Props = {
  customState: State;
  tableState: Table.State;
};
export function useController(props: Props) {
  const { customState, tableState } = props;
  const { toggleVisibleForm, setMode, setProjectType } = customState;

  const [selectedRow] = tableState.selectedRows;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isOpenNoAccessWindow, setIsOpenNoAccessWindow] = useState(false);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);
  const handleCloseNoAccessWindow = useCallback(() => {
    setIsOpenNoAccessWindow(false);
  }, []);

  const isHasAccessToForm = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer && selectedRow?.['id:Type']) {
      setProjectType((selectedRow?.['id:Type'] as ProjectType | null) || null);
      return true;
    }
    setIsOpenNoAccessWindow(true);
    return false;
  }, [selectedRow, setProjectType]);

  const handleViewButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true') {
      if (isHasAccessToForm()) {
        setMode('view');
        toggleVisibleForm();
      }
    } else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [selectedRow?.IsLeaderOrResponsiblePerformer, isHasAccessToForm, setMode, toggleVisibleForm]);
  const handleEditButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true') {
      if (isHasAccessToForm()) {
        setMode('edit');
        toggleVisibleForm();
      }
    } else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [selectedRow?.IsLeaderOrResponsiblePerformer, isHasAccessToForm, setMode, toggleVisibleForm]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        isDisabled: !selectedRow,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        isDisabled: !selectedRow,
        onClick: handleEditButtonClick,
      },
    ],
    [handleHelpButtonClick, selectedRow, handleViewButtonClick, handleEditButtonClick],
  );
  return {
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isOpenNoAccessWindow,
    handleCloseNoAccessWindow,
  };
}
