export type State = {
  projectId?: string;
  projectSelectable?: boolean;
};

type Props = {
  projectId?: string;
  projectSelectable?: boolean;
};

export function makeUseCustomController({ projectId, projectSelectable }: Props) {
  return function useCustomController(): State {
    return {
      projectId,
      projectSelectable,
    };
  };
}
