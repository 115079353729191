import { PublicationInput } from 'services/BackendAPI/configurations/formData';
import { Author, FileInfo, Form } from 'types/models';
import { PublicationProject } from 'types/models/Form';

type Props = {
  isElectronic: boolean | undefined;
  electronicType: string | undefined;
  formFields: Form.Fields;
  projects: PublicationProject[];
  mobileRequests: Form.PublicationMobileRequest[];
  type: string | undefined;
  authors: Author[];
  status: string;
};

const formatToRequest = ({
  isElectronic,
  electronicType,
  formFields,
  projects,
  mobileRequests,
  type,
  authors,
  status,
}: Props): PublicationInput => ({
  simpleFields: {
    type: type?.toUpperCase() || '',
    status,
    typeEdition: 'LOCAL',
    isElectronic: !!isElectronic,
    isLocal: Boolean(formFields.isLocal.value),
    electronicType: (!!isElectronic && electronicType) || '',
    name: formFields.name.value || '',
    printPageCount: formFields.printPageCount.value || undefined,
    doi: formFields.doi.value as string,
    address: formFields.address.value as string,
    defencePlace: formFields.defencePlace.value as string,
    defenceDate: formFields.defenceDate.value as string,
    year: formFields.year.value as string,
    pageCount: formFields.pageCount.value as string,
    libraryLink: formFields.libraryLink.value as string,
    bibliographicRecord: formFields.bibliographicRecord.value as string,
    foreignName: formFields.foreignName.value || '',
    foreignAnnotation: formFields.foreignAnnotation.value || '',
    foreignAuthorsKeyWords: formFields.foreignAuthorsKeyWords.value || '',
    foreignAuthors: formFields.foreignAuthors.value || '',
    publicationDate: formFields.publicationDate.value as string,
  },
  attrIdFields: {
    textLanguage: formFields.textLanguage.value?.id,
    vakSpeciality: formFields.vakSpeciality.value?.id,
    degree: formFields.degree.value?.id,
    council: formFields.council.value?.id,
  },
  arrayFields: {
    translations: { translation: [] },
  },
  affiliation: formFields.affiliation.value as Form.Affiliation | null,
  authors,
  mobileRequests: { mobileRequest: mobileRequests },
  projects,
  file: formFields.file.value as FileInfo,
});

export { formatToRequest };
