import React, { useMemo } from 'react';

import { Column, ListEditTable } from 'components';

import { Event, Table } from 'types/models';
// eslint-disable-next-line max-len
import { TypeControllerIsForm } from 'features/Table/specifications/GetExpositionEventList/LeftPanelForThirdLevel/modalTemplates/ExpositionParticipationTemplate/models/typeControllerIsForm';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import ControlPanelParticipations from '../ControlPanelEventParticipations/controlPanelParticipations';

type Props = {
  participations: Event.Participations[];
  setIsFormOpen: TypeControllerIsForm;
  selectedRowIndex: number | null;
  setSelectedRowIndex: React.Dispatch<number | null>;
  workMode: Table.WorkMode;
  fetch: () => void;
};

const EventParticipations = ({
  workMode,
  participations,
  setIsFormOpen,
  selectedRowIndex,
  setSelectedRowIndex,
  fetch,
}: Props) => {
  const columns = useMemo<Column<Event.Participations>[]>(
    () => [
      { label: 'Статус', formatValue: row => row.status.name || '', styles: { width: '35px' } },
      {
        label: 'Руководитель',
        formatValue: row => row.supervisor,
        styles: { width: '35px' },
      },
      {
        label: 'Участники',
        formatValue: row => row.participant,
        styles: { width: '35px' },
      },
      {
        label: 'Награды',
        formatValue: row => row.awards || '',
        styles: { width: '35px' },
      },
      {
        label: 'Доклад',
        formatValue: row => row.report || '',
        styles: { width: '35px' },
      },
      {
        label: 'Подразделения',
        formatValue: row => (row.departments ? row.departments : ''),
        styles: { width: '35px' },
      },
      { label: 'Факультеты/Институты', formatValue: row => row.faculties || '', styles: { width: '35px' } },
      { label: 'Примечания', formatValue: row => row.note || '', styles: { width: '35px' } },
      { label: 'Добавлено', formatValue: row => row.createdBy || '', styles: { width: '35px' } },
      { label: 'Дата добавления', formatValue: row => row.createdDate || '', styles: { width: '35px' } },
      { label: 'Изменено', formatValue: row => row.modifiedBy || '', styles: { width: '35px' } },
      { label: 'Дата изменения', formatValue: row => row.modifiedDate || '', styles: { width: '35px' } },
    ],
    [],
  );

  return (
    <>
      <ControlPanelParticipations
        participationType={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        workMode={workMode}
        participations={participations}
        setIsFormOpen={setIsFormOpen}
        selectedRowIndex={selectedRowIndex}
        isApproved={
          !(selectedRowIndex === null) && selectedRowIndex
            ? participations[selectedRowIndex!].status.status === RecordStatus.APPROVED
            : false
        }
        fetch={fetch}
      />

      <ListEditTable
        rows={participations}
        defaultRowsCount={participations.length > 10 ? participations.length : 10}
        columns={columns}
        selectRow={setSelectedRowIndex}
        selectedRowIndex={selectedRowIndex}
      />
    </>
  );
};

export const Component = React.memo(EventParticipations);
