import React, { useState, useCallback, useMemo } from 'react';

import { IconButtonProps, ConfirmPopup, ListEditTable, FormComponent, Toolbar, buttonIcons } from 'components';

import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { getEnum } from 'utils/Helpers';
import { TenderRequestType } from 'utils/Enums';
import * as modalTemplates from './modalTemplates';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isTypeModalOpen, setIsTypeModalOpen] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<TenderRequestType | null>(null);
  const [typeIndex, setTypeIndex] = useState<number | null>(null);

  const typeOptions = getEnum('TenderRequestType');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Tender',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setRequestType(null);
    setIsTypeModalOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const selectType = useCallback(
    (i: number) => {
      setRequestType((typeOptions[i].value as TenderRequestType) || null);
      setIsTypeModalOpen(false);
      setIsAddFormOpen(true);
    },
    [typeOptions],
  );

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const reports = useMemo<Report[]>(() => [Reports.MobileProtocol], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        permissionName: Permits.TENDER_VIEW,
        isDisabled: !selectedRow,
        onClick: () => setIsViewFormOpen(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        permissionName: Permits.TENDER_ADD,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        permissionName: Permits.TENDER_EDIT,
        isDisabled: !selectedRow,
        onClick: () => setIsEditFormOpen(true),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: Permits.TENDER_DELETE,
        isDisabled: !selectedRow,
        onClick: handleDeleteButtonClick,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [selectedRow, handleAddButtonClick, handleDeleteButtonClick, handleHelpButtonClick, getReports, handleSetCurrentReport],
  );

  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.TenderViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.TenderEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isAddFormOpen && requestType && (
        <modalTemplates.TenderAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          requestType={requestType}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный тендер? (ID: ${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isTypeModalOpen && (
        <ConfirmPopup
          title="Выберите вид формы заявки"
          isOpen={isTypeModalOpen}
          okButtonText="Продолжить"
          onClose={() => setIsTypeModalOpen(false)}
          onConfirm={() => selectType(typeIndex || 0)}
          isDisabled={typeIndex === null}
        >
          <FormComponent.Field>
            <>
              <strong>Внимание:</strong> выбор вида формы заявки влияет на состав полей формы заявки. Коллекции заявок, собранные
              по видам заявок размещаются в соответствующих пунктах меню
            </>
          </FormComponent.Field>
          <ListEditTable
            rows={typeOptions}
            columns={[{ label: 'Тип заявки', formatValue: row => row?.label }]}
            onDoubleRowClick={selectType}
            selectedRowIndex={typeIndex}
            selectRow={setTypeIndex}
          />
        </ConfirmPopup>
      )}
      <ConfirmPopup
        title="Помощь в работе с разделом «Список программ поддержки научной деятельности»"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <h3>Список конкурсов</h3>
          <p>
            <strong className="primary">Важно</strong>
          </p>
          <p>
            <strong className="primary">
              При добавлении конкурса необходимо вначале указать какой вид формы заявки будет использоваться для данного конкурса.
            </strong>
            &nbsp;Выбор вида формы заявки влияет на состав полей формы заявки. В системе предусмотрено 3 вида форм заявки:
          </p>
          <ul>
            <li>
              «Заявка по академической мобильности» — для конкурсов из категории: Стажировки, повышение квалификации, Участие в
              научных мероприятиях;
            </li>
            <li>
              «Заявка на конкурс» — для конкурсов из категории: Проведение научных мероприятий, Издание научных трудов, на
              выполнение НИОКР, Проекты в области предпринимательства и инноваций, Стипендии и премии, Прочие проекты;
            </li>
            <li>
              «Заявка на внутренний конкурс» — для участия в конкурсе на выполнение НИР и ОКР, организованном внутри университета.
            </li>
          </ul>
          <p>Форма добавления конкурса содержит три вкладки:</p>
          <ul>
            <li>Конкурс содержит основные сведения о конкурсе</li>
            <li>
              Лоты – заполняется для конкурсов из категории: Проведение научных мероприятий, Издание научных трудов, на выполнение
              НИОКР, Проекты в области предпринимательства и инноваций, Стипендии и премии, Прочие проекты.
            </li>
            <li>
              Документы – для прикрепления файлов конкурсной документации. Файлы с атрибутом
              <strong className="primary"> «Отображать на формах Заявки и Проекта»</strong> - будут доступны для просмотра на
              форме заявки, в списке документов
            </li>
          </ul>
        </div>
      </ConfirmPopup>
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
