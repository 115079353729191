import { Table as T } from 'types/models';
import { Filters } from './filters';
import { EventTypeName } from 'features/Form/looks/participation/views/MembersList/eventTypes';
import { Component as Toolbar } from './Toolbar/Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Color } from 'constants/colors';

export type DetailProps = {
  eventType?: EventTypeName;
};

export function GetPersonalExpositionEventList({ eventType }: DetailProps): T.Specification<State> {
  return {
    apiID: 'GetPersonalExpositionEventList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: '',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController({ eventType }),
    highlightEntryPredicate: entry => ({ rowTheme: !!entry.LibraryNote ? Color.danger : '' }),
    isWithQna: true,
  };
}
