import { useState, useLayoutEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FileInfo, Form, Program } from 'types/models';
import { Option } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { validateProgram } from './validate';
import workModeHook from 'features/Form/hooks/workModeHook';
import { EMPTY_FILE } from 'utils/Constants';
import { getEnum } from 'utils/Helpers';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const { enumMap } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const options = getEnum('ProgramType', enumMap);

  const [program, setProgram] = useState<Program.Program | null>(null);
  const [programId, setProgramId] = useState<string | null>(null);
  const [customers, setCustomers] = useState<Program.Customer[]>([]);
  const [directions, setDirections] = useState<Program.Direction[]>([]);
  const [indicators, setIndicators] = useState<Program.Indicator[]>([]);
  const [indices, setIndices] = useState<Program.Indicator[]>([]);
  const [domainKnowledges, setDomaikKnowledges] = useState<Program.DomainKnowledge[]>([]);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState<boolean>(false);
  const [saveConfirmText, setSaveConfirmText] = useState<string>('');

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название программы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    shortName: {
      value: '',
      isValid: true,
      required: true,
      title: 'Краткое название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          shortName: { ...prevState.shortName, value },
        }));
      },
    },
    startDate: {
      value: '',
      isValid: true,
      title: 'Дата начала',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          startDate: { ...prevState.startDate, value: nextDate || '' },
        }));
      },
    },
    endDate: {
      value: '',
      isValid: true,
      title: 'Дата окончания',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          endDate: { ...prevState.endDate, value: nextDate || '' },
        }));
      },
    },
    url: {
      value: '',
      isValid: true,
      title: 'Адрес сайта',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          url: { ...prevState.url, value },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      title: 'Положение о программе',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    logo: {
      value: { ...EMPTY_FILE, id: program?.logo?.id || '' },
      isValid: true,
      title: 'Логотип программы',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          logo: {
            ...prevState.logo,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
  });

  const { methods: getProgram } = BackendAPI.useBackendAPI('GetProgram', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Program.Program;
      setProgramId(preparedData.id);
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        name: {
          ...prevState.name,
          value: preparedData.name,
        },
        shortName: {
          ...prevState.shortName,
          value: preparedData.shortName,
        },
        startDate: {
          ...prevState.startDate,
          value: preparedData.startDate,
        },
        endDate: {
          ...prevState.endDate,
          value: preparedData.endDate,
        },
        url: {
          ...prevState.url,
          value: preparedData.url,
        },
        file: {
          ...prevState.file,
          value: preparedData.file,
        },
        logo: {
          ...prevState.logo,
          value: preparedData.logo,
        },
      }));
      setCustomers(preparedData.customers);
      setDirections(preparedData.directions);
      setIndicators(preparedData.indicators);
      setIndices(preparedData.indices);
      setDomaikKnowledges(preparedData.domainKnowledges);
      setProgram(preparedData);
    },
  });

  const { methods: saveProgram } = BackendAPI.useBackendAPI('SaveProgram', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Программа успешно сохранена', theme: 'success' });
    },
  });

  const refreshList = useCallback(() => {
    tableStreams.reloadTable.push();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (needClose: boolean = false) => {
      const validationInfo = validateProgram(formFields);
      if (validationInfo.some(x => !x.isValid)) {
        validationInfo.forEach(({ isValid, invalidMessage }) => {
          if (!isValid) {
            setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
          }
        });
        return;
      }
      saveProgram.callAPI(
        {
          id: id || programId || null,
          name: formFields.name.value,
          shortName: formFields.shortName.value,
          startDate: formFields.startDate.value,
          endDate: formFields.endDate.value,
          url: formFields.url.value,
          file: formFields.file.value,
          logo: formFields.logo.value,
          directions,
          customers,
          indicators,
          indices,
          domainKnowledges,
        },
        {
          onSuccessfullCall: ({ data }) => {
            if (needClose) {
              refreshList();
              onClose();
            } else if (data.id) {
              getProgram.callAPI({ id: data.id });
              setProgramId(data.id);
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      saveProgram,
      onClose,
      customers,
      directions,
      domainKnowledges,
      formFields,
      id,
      programId,
      indicators,
      indices,
      refreshList,
      getProgram,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  useLayoutEffect(() => {
    if (id) {
      getProgram.callAPI({ id });
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    (needClose: boolean = false) => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  const handleFormClose = useCallback(() => {
    onClose();
    refreshList();
  }, [onClose, refreshList]);

  const handleTypeFieldChange = useCallback(
    (e: Option) => {
      formFields.type.onChange(e);
    },
    [formFields.type],
  );

  const handleCustomersFieldChange = useCallback(
    (value: Program.Customer[]) => {
      setCustomers(value);
    },
    [setCustomers],
  );

  const handleDirectionsFieldChange = useCallback(
    (value: Program.Direction[]) => {
      setDirections(value);
    },
    [setDirections],
  );

  const handleSaveConfirm = useCallback(() => {
    handleFormSubmit(false);
    setIsConfirmPopupOpened(false);
  }, [handleFormSubmit]);

  const handleOpenConfirm = useCallback((confirmText: string) => {
    setSaveConfirmText(confirmText);
    setIsConfirmPopupOpened(true);
  }, []);

  return {
    customers,
    directions,
    formFields,
    handleCustomersFieldChange,
    handleDirectionsFieldChange,
    handleFormClose,
    handleFormSubmit,
    handleTypeFieldChange,
    options,
    programId: id ?? programId ?? null,
    workMode,
    program,
    getProgram,
    saveConfirmText,
    setSaveConfirmText,
    isConfirmPopupOpened,
    setIsConfirmPopupOpened,
    handleOpenConfirm,
    handleSaveConfirm,
  };
}
