import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

const ToolbarTemplate = ({ tableState, onSubmitTable }: Props) => {
  const {
    buttons,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    tenderId,
    isDeleteConfirmPopupOpen,
    isViewButtonDisabled,
    isEditButtonDisabled,
    handleTemplateCloseAddForm,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    nextPublicationName,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isDeleteButtonDisabled,
  } = useController({
    tableState,
    onSubmitTable,
  });

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.ProgramTenedViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tenderId}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.ProgramTenedEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tenderId}
          relatedTableState={tableState}
        />
      )}
      <modalTemplates.ProgramTenedAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный конкурс? (ID: ${tenderId})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
};

export const Component = React.memo(ToolbarTemplate);
