import React, { memo, useCallback, useMemo } from 'react';

import { CustomSelect, ListEdit, Column, FormComponent, SectionTitle } from 'components';

import { Form as F, Table as T, Event } from 'types/models';
import {
  GetSimpleMagazineListSpecification,
  GetMagazineReleaseListSpecification,
  GetCompilationListByTypeSpecification,
} from 'features/Table/specifications';
import { showNotification } from 'features/Notifications';
import { Component as ConferenceDataFormView } from 'features/Form/looks/event/views/ConferenceDataFormView';

type Props = {
  viewMode?: boolean;
  refs: any; //eslint-disable-line
  sourceMagazine: F.Original | null;
  relatedTableState: T.State | undefined;
  magazineReleases: Event.Source[];
  changeSourceMagazine: (original: F.Original | null) => void;
  changeMagazineReleases: (val: Event.Source[]) => void;
  onDeleteSourceMagazine: () => void;
  compilations: Event.Compilations[];
  changeCompilations: (val: Event.Compilations[]) => void;
  isDisableSource: boolean;
  participationPublications: Event.Source[];
  eventId?: string;
  hint?: string;
};

const Material = ({
  eventId,
  viewMode,
  relatedTableState,
  sourceMagazine,
  magazineReleases,
  changeMagazineReleases,
  changeSourceMagazine,
  onDeleteSourceMagazine,
  compilations,
  changeCompilations,
  isDisableSource,
  participationPublications,
  hint,
}: Props) => {
  const modalTableRowToSourceMagazineConventer = useCallback<(row: T.Entry) => F.Original>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
    }),
    [],
  );

  const sourceCompilationTableConfig = GetCompilationListByTypeSpecification({
    requestData: { type: 'COMPILATION_CONFERENCE' },
    eventId,
    hasSelectButton: true,
  });

  const sourceMagazineTableConfig = GetSimpleMagazineListSpecification({
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedTableState,
        relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
      },
    },
    hasSelectButton: true,
  });
  const sourceMagazineReleaseTableConfig = GetMagazineReleaseListSpecification({
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedRecordId: sourceMagazine?.id,
        relatedTableState,
      },
    },
    eventId,
    hasSelectButton: true,
  });
  const compilationColumns = useMemo<Column<Event.Compilations>[]>(
    () => [
      { label: 'ID', formatValue: row => row.id, styles: { width: '45px' } },
      { label: 'Статус', formatValue: row => row.status ?? '', styles: { width: '90px' } },
      { label: 'Название', formatValue: row => row.name, styles: { width: '35%', minWidth: '360px' } },
      { label: 'Тип сборника', formatValue: row => row.type, styles: { width: '150px' } },
      { label: 'Тип издания', formatValue: row => row.typeEdition, styles: { width: '150px' } },
      { label: 'Подразделения', formatValue: row => row.departments ?? '', styles: { width: '90px' } },
    ],
    [],
  );
  const magazineReleaseColumns = useMemo<Column<Event.Source>[]>(
    () => [
      { label: 'ID Журнала', formatValue: row => row.id, styles: { width: '20%' } },
      { label: 'Журнал', formatValue: row => row.upperSourceName || '', styles: { width: '25%' } },
      { label: 'Выпуск', formatValue: row => row.name, styles: { width: '35%', minWidth: '360px' } },
      { label: 'Статус выпуска', formatValue: row => row.status || '', styles: { width: '20%' } },
    ],
    [],
  );
  const modalTableRowConverter = useCallback<(row: T.Entry) => Event.Compilations>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      type: row.type,
      typeEdition: row.typeEdition,
      departments: row.Departments,
      bibliographicRecord: '',
    }),
    [],
  );

  const modalTableMagazineRowConverter = useCallback<(row: T.Entry) => Event.Source>(
    row => ({ id: row.id, name: row.label, status: row.Status }),
    [],
  );

  const preCallback = useCallback(
    (nextReleases: Event.Source[], submit: (releases: Event.Source[]) => void) => {
      const isSomeReleaseIsAlredyAdded = nextReleases.some(({ id: nextReleaseId }) =>
        magazineReleases.some(({ id: currentReleaseId }) => currentReleaseId === nextReleaseId),
      );

      if (!isSomeReleaseIsAlredyAdded) {
        const preparedReleases = nextReleases.map(release => ({
          upperSourceId: sourceMagazine?.id || '',
          upperSourceName: sourceMagazine?.name || '',
          upperSourceStatus: sourceMagazine?.status || '',
          ...release,
        }));
        submit(preparedReleases);
      } else {
        showNotification({ message: 'Один из элементов уже находится в списке', theme: 'danger' });
      }
    },
    [magazineReleases, sourceMagazine?.id, sourceMagazine?.name, sourceMagazine?.status],
  );
  return (
    <form>
      <input type="submit" hidden />
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Description mode="warning">{hint}</FormComponent.Description>

          <SectionTitle
            // eslint-disable-next-line max-len
            tooltip='Издания, в которых опубликованы материалы могут быть двух типов: сборники материалов конференций и специальные выпуски журналов. В сборнике материалов конференции  связь с конференцией добавляется на вкладке " Мероприятия и проекты"; для журнала  на  форме выпуска журнала.'
            title="Издания, в которых опубликованы материалы конференции:"
          />
          {!isDisableSource && (
            <FormComponent.Line>
              <ListEdit
                // eslint-disable-next-line max-len
                tooltipText="Здесь отображены сборники, у которых есть связь с конференцией. Выберите  сборник из списка сборников материалов конференции. Если сборника нет в списке, вы можете заполнить  основные сведения о сборнике с помощью кнопки Добавить. "
                title="Сборники материалов конференций"
                rows={compilations}
                isToolbarDisabled={viewMode}
                // withoutHead
                maxHeight="400px"
                defaultRowsCount={3}
                columns={compilationColumns}
                onChange={changeCompilations}
                specification={{
                  mode: 'relationTableModal',
                  modalTableRowConverter,
                  relationTableModalTitle: 'Сборники материалов конференций',
                  modalTableSpecification: sourceCompilationTableConfig,
                }}
              />
            </FormComponent.Line>
          )}
          <SectionTitle title="Опубликованные материалы конференции в специальных выпусках журнала:" />
          {!isDisableSource && (
            <>
              <FormComponent.Line>
                <CustomSelect
                  disabled={!!viewMode}
                  isStatusText
                  title="Название журнала"
                  original={sourceMagazine}
                  relationTableModalTitle="Журналы"
                  onDeleteFunction={onDeleteSourceMagazine}
                  onChange={changeSourceMagazine}
                  modalTableRowConverter={modalTableRowToSourceMagazineConventer}
                  modalTableSpecification={sourceMagazineTableConfig}
                  // eslint-disable-next-line max-len
                  titleTooltip="Выбрать журнал, из списка журналов и  указать номер выпуска. Если данных нет, то добавьте  новый журнал (выпуск) в справочник с помощью кнопки Добавить."
                />
              </FormComponent.Line>
              <FormComponent.Line lineSize="doublePadded">
                <FormComponent.Field label="">
                  <ListEdit
                    title="Выпуски журнала"
                    rows={magazineReleases}
                    getIsAddDisabled={() => !sourceMagazine}
                    getIsEditDisabled={() => !sourceMagazine}
                    isToolbarDisabled={viewMode}
                    columns={magazineReleaseColumns}
                    maxHeight="400px"
                    defaultRowsCount={3}
                    onChange={changeMagazineReleases}
                    specification={{
                      mode: 'relationTableModal',
                      modalTableRowConverter: modalTableMagazineRowConverter,
                      relationTableModalTitle: 'Выпуски журнала',
                      modalTableSpecification: sourceMagazineReleaseTableConfig,
                      onPreSubmit: preCallback,
                      onPreEdit: preCallback,
                    }}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
        </FormComponent.Column>
        <FormComponent.Column>
          <ConferenceDataFormView
            viewMode={viewMode}
            compilations={compilations}
            participationPublications={participationPublications}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </form>
  );
};

export const MaterialComponent = memo(Material);
