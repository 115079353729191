import React, { ReactElement } from 'react';
import { renderToString } from 'react-dom/server';
import * as modalTemplates from './modalTemplates';
import * as icons from 'icons';

import {
  buttonIcons,
  ConfirmPopup,
  FormComponent,
  IconButton,
  InputSelect,
  ListEditTable,
  Modal,
  Reference,
  TextArea,
  Toolbar,
} from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { DataGrid } from 'features/Table';
import { TenderRequestType } from 'utils/Enums';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal/ExpertEstimateModal';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTenderSelect,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isTenderModalOpen,
    isViewFormOpen,
    nextPublicationName,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsTenderModalOpen,
    setIsViewFormOpen,
    tenderInfo,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    isCopyFormOpen,
    setIsCopyFormOpen,
    isNumberPrefixModalOpen,
    setIsNumberPrefixModalOpen,
    numberPrefix,
    setNumberPrefix,
    saveNumberPrefix,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({ tableState });

  type Custom = {
    value: ReactElement;
    status: string;
  };

  const renderFields = (args: Custom[] | Custom) => {
    const fields = Array.isArray(args) ? args : [args];

    return renderToString(
      <>
        {fields.map((item, index) => {
          return (
            <div key={index}>
              <FormComponent.ColumnWrapper>
                <FormComponent.Column specialStyles={{ width: '75%' }}>
                  <>{item.value}</>
                </FormComponent.Column>
                <FormComponent.Column specialStyles={{ width: '25%' }}>
                  <>{item.status}</>
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
              {fields.length - 1 !== index && <hr />}
            </div>
          );
        })}
      </>,
    );
  };

  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.RequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.RequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.RequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          tenderInfo={tenderInfo}
          relatedTableState={tableState}
          name={nextPublicationName}
        />
      )}
      {isCopyFormOpen && (
        <modalTemplates.RequestCopyTemplate.Component
          isOpen={isCopyFormOpen}
          onClose={() => setIsCopyFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {isDeleteConfirmPopupOpen && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную заявку? (ID ${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}

      <ConfirmPopup
        title='Помощь в работе с разделом "Заявки на конкурс"'
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Функциональный блок Заявки на конкурс предназначен для учёта заявок поданных от университета на конкурсы, гранты и
            тендеры из категории: Проведение научных мероприятий, Издание научных трудов, на выполнение НИОКР, Проекты в области
            предпринимательства и инноваций, Стипендии и премии, Прочие проекты.
          </p>
          <p>
            <strong className="primary">Назначение функциональных элементов интерфейса</strong>
          </p>
          <ListEditTable
            rows={[
              {
                element: 'Год ',
                description:
                  // eslint-disable-next-line max-len
                  'Это фильтр показывает список зарегистрированных в системе в указанном году заявок на конкурсы. Если год не указан, то показывает весь список заявок.',
              },
              {
                element: renderToString(
                  <>
                    Шаблон выборки:
                    <InputSelect<string>
                      value={[]}
                      onSelectChange={() => {}}
                      options={[]}
                      disabled={true}
                      placeholder={'Не задан'}
                    />
                  </>,
                ),
                description:
                  'Для режимов «ввода данных» и «режима анализ данных» списки шаблонов представления данных в таблице РАЗНЫЕ',
              },
              {
                element: renderToString(
                  <>
                    Выбрать столбцы:
                    <IconButton icons={{ default: icons.PickColumns }} hasNoHover />
                  </>,
                ),
                description: 'Добавить или скрыть столбцы из списка доступных',
              },
              {
                element: renderToString(
                  <>
                    Фильтр включить:
                    <IconButton icons={buttonIcons.filter} hasNoHover />
                  </>,
                ),
                description: 'Перевод строки заголовков таблицы в режим фильтрации данных по столбцу',
              },
              {
                element: renderToString(
                  <>
                    Фильтр отключить: <IconButton icons={buttonIcons.resetFilter} hasNoHover />
                  </>,
                ),
                description: 'Отключить режим фильтрации строки заголовков таблицы',
              },
              {
                element: renderToString(
                  <>
                    Помощь: <IconButton icons={buttonIcons.toolbarHelp} hasNoHover />
                  </>,
                ),
                description: 'Открывает окно справки по работе с разделом «Заявки на конкурсы»',
              },
              {
                element: renderToString(
                  <>
                    Просмотр: <IconButton icons={buttonIcons.loop} hasNoHover />
                  </>,
                ),
                description: 'Открывает форму просмотра Заявки. Кнопка активна при выделении строки в таблице',
              },
              {
                element: renderToString(
                  <>
                    Добавить:
                    <IconButton icons={buttonIcons.plus} hasNoHover />
                  </>,
                ),
                description: 'Открывает форму для добавления заявки',
              },
              {
                element: renderToString(
                  <>
                    Редактировать:
                    <IconButton icons={buttonIcons.edit} hasNoHover />
                  </>,
                ),
                description: 'Открывает форму редактирования Заявки. Кнопка активна при выделении строки в таблице',
              },
              {
                element: renderToString(
                  <>
                    Удалить: <IconButton icons={buttonIcons.delete} hasNoHover />
                  </>,
                ),
                description: 'Удаляет выделенную в таблице запись',
              },
              {
                element: renderToString(
                  <>
                    На согласование: <IconButton icons={buttonIcons.like} hasNoHover />
                  </>,
                ),
                description:
                  // eslint-disable-next-line max-len
                  'Перевод заявки в статус На Согласование. Предназначена для передачи заявки на проверку ответственному за Заявки. Для заявителя в этом статусе заявка доступна только в режиме просмотра',
              },
              {
                element: renderToString(
                  <>
                    Рекомендовать на конкурс: <IconButton icons={buttonIcons.star} hasNoHover />
                  </>,
                ),
                description:
                  // eslint-disable-next-line max-len
                  'Перевод заявки в статус Рекомендована на конкурс. Этот статус заявки обозначает, что заявка проверена экспертами внутри университета на полноту, соответствие требованиям конкурса и рекомендована Университетом для участия во внешнем конкурсе',
              },
              {
                element: renderToString(
                  <>
                    Поддержать: <IconButton icons={buttonIcons.activation} hasNoHover />
                  </>,
                ),
                description:
                  // eslint-disable-next-line max-len
                  'Перевод заявки в статус Поддержана. Этот статус — итоговый в жизненном цикле заявки. Обозначает, что заявка одобрена и получит финансирование. На основе Заявки в статусе Поддержана можно создать проект (этап проекта) и/или скопировать данные заявки в проект. Рекомендована для роли Ответственный за заявки',
              },
              {
                element: renderToString(
                  <>
                    Экспертная оценка: <IconButton icons={buttonIcons.approve} hasNoHover />
                  </>,
                ),
                description:
                  // eslint-disable-next-line max-len
                  'Вызывает форму добавления экспертной оценки и замечаний. Рекомендована для роли Конкурсная комиссия. Статус заявки не меняет',
              },
              {
                element: renderToString(
                  <>
                    На доработку: <IconButton icons={buttonIcons.update} hasNoHover />
                  </>,
                ),
                description:
                  // eslint-disable-next-line max-len
                  'Перевод заявки в статус На доработку. Рекомендована для роли Ответственный за заявки. Заявка в статусе На доработку доступна для редактирования заявителем',
              },
              {
                element: renderToString(
                  <>
                    Отклонить:
                    <IconButton icons={buttonIcons.dislike} hasNoHover />
                  </>,
                ),
                description:
                  // eslint-disable-next-line max-len
                  'Перевод заявки в статус Отклонить. Этот статус — итоговый в жизненном цикле заявки; обозначает, что заявка проверена экспертами и Конкурсной комиссией. Рекомендована для роли Ответственный за заявки',
              },
              {
                element: renderToString(
                  <>
                    Печать документов: <IconButton icons={buttonIcons.print} hasNoHover />
                  </>,
                ),
                description: 'Открывает список шаблонов для формирования регламентированных документов',
              },
              {
                element: renderToString(
                  <>
                    Выгрузить в файл: <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
                  </>,
                ),
                description:
                  'Список записей, которые отображаются в таблице, выгружает в файл .xls для последующей обработки в Excel',
              },
            ]}
            columns={[
              { label: 'Элемент интерфейса', formatValue: row => row.element || '', styles: { width: '30%' } },
              { label: 'Описание', formatValue: row => row.description || '', styles: { width: '70%' } },
            ]}
            selectedRowIndex={null}
            selectRow={() => {}}
          />
          <p>
            <strong className="primary">Статусы заявки на конкурс и краткое описание бизнес-процесса</strong>
          </p>
          <ListEditTable
            rows={[
              {
                role: 'Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета',
                action: renderFields([
                  {
                    value: <>Добавить заявку на конкурс</>,
                    status: 'Черновик',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку с помощью кнопки <strong>На согласование</strong>
                      </>
                    ),
                    status: 'Заявка',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки на конкурс',
                action: renderFields([
                  {
                    value: <>Просмотр заявки; Добавить сообщение для заявителя</>,
                    status: 'Заявка',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отправить на доработку</strong>
                      </>
                    ),
                    status: 'На доработку',
                  },
                ]),
              },
              {
                role: 'Конкурская комиссия',
                action: renderFields({
                  value: <>Просмотр заявки; Добавить визу</>,
                  status: 'Заявка',
                }),
              },
              {
                role: 'Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета',
                action: renderFields([
                  {
                    value: (
                      <>
                        Редактирование заявки в статусе <strong>На Доработку</strong>
                      </>
                    ),
                    status: '',
                  },
                  {
                    value: (
                      <>
                        Отправить заявку на проверку в с помощью кнопки <strong>На согласование</strong>
                      </>
                    ),
                    status: '',
                  },
                ]),
              },
              {
                role: 'Ответственный за заявки на конкурс',
                action: renderFields([
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Отклонить</strong>; написать сообщение — обоснование
                      </>
                    ),
                    status: 'Отклонена',
                  },
                  {
                    value: (
                      <>
                        Выделить заявку в списке, Кнопка <strong>Рекомендовать на конкурс</strong>; написать сообщение для
                        заявителя
                      </>
                    ),
                    status: 'Рекомендована на конкурс',
                  },
                  {
                    value: (
                      <>
                        После регистрации заявки в ИАС Организатора конкурса. На форме редактирования Заявки на конкурс заполнить
                        поля:
                        <strong>Подана на конкурс</strong>; и <strong>Шифр заявки</strong>
                      </>
                    ),
                    status: 'Подана',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки{' '}
                        <strong>Поддержать</strong>
                      </>
                    ),
                    status: 'Поддержана',
                  },
                  {
                    value: (
                      <>
                        По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки{' '}
                        <strong>Отклонить</strong>
                      </>
                    ),
                    status: 'Отклонена',
                  },
                ]),
              },
            ]}
            columns={[
              { label: 'Роль', formatValue: row => row.role || '', styles: { width: '30%' } },
              { label: 'Действие и Статус', formatValue: row => row.action || '', styles: { width: '70%' } },
            ]}
            selectedRowIndex={null}
            selectRow={() => {}}
          />
          <br />
          <div className="warning">
            <div className="warning__left">ВАЖНО!</div>
            <div className="warning__right">
              Заявитель — участник коллектива в роли <strong>Руководитель проекта</strong> или&nbsp;
              <strong>Ответственный исполнитель</strong>. <br />
              Для всех участников коллектива заявки (в роли: <strong>Исполнитель</strong>, <strong>Руководитель проекта</strong>,
              <strong>Ответственный исполнитель</strong>) в Личном кабинете и в карточке персоны заявка отобразится в списке на
              вкладке <strong>Заявки на конкурсы</strong>. Список заявок можно выгрузить в файл с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.XMLDocument} hasNoHover />.<br />
              Для заявителя (в заявке по академической мобильности) и для <strong>руководителя проекта</strong> и{' '}
              <strong>ответственного исполнителя</strong> (в заявке на конкурс) форма заявки доступна для редактирования в
              статусах:
              <strong>Черновик</strong> или <strong>На доработку</strong>. В других статусах — форма заявки доступна для
              просмотра. Удалить заявку можно только в статусе <strong>Черновик</strong>.
            </div>
          </div>
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
      <Modal
        isOpen={isTenderModalOpen}
        onClose={() => setIsTenderModalOpen(false)}
        title="Список конкурсов"
        size={['extralarge', 'large-height']}
      >
        <DataGrid
          specification={GetTenderListForRequest({
            tenderRequestType: TenderRequestType.PROGRAM_REQUEST,
            selectRow: handleTenderSelect,
          })}
        />
      </Modal>

      {isStatusModalOpen && (
        <ConfirmPopup
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          onConfirm={changeStatus}
          okButtonText={statusLabel}
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </ConfirmPopup>
      )}

      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />

      {isNumberPrefixModalOpen && (
        <ConfirmPopup
          title="Присвоить Код для группы выделенных заявок"
          isOpen={isNumberPrefixModalOpen}
          onClose={() => setIsNumberPrefixModalOpen(false)}
          onConfirm={saveNumberPrefix}
          okButtonText="Присвоить код"
          onReset={() => setIsNumberPrefixModalOpen(false)}
          resetButtonText="Отмена"
        >
          <FormComponent.Line>
            <div>
              <strong>Внимание:</strong> Код будет присвоен для всех выделенных заявок. Если у какой-либо заявки уже есть код,
              система его заменит
            </div>
          </FormComponent.Line>
          <Reference
            name="RefProjectRequestNumberPrefix"
            onChange={setNumberPrefix}
            value={numberPrefix}
            relationTableModalTitle='Справочник "Префикс для шифра заявок и номера проектов"'
          />
        </ConfirmPopup>
      )}
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        requestId={tableState.selectedRows[0]?.id}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
