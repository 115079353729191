import React from 'react';
import { FormComponent, TextGridSelect } from 'components';
import { Item } from 'types/models/common';
import { GetProgramList } from 'features/Table/specifications';
import { tableRowConverter } from './helpers';

type Props = {
  program: Item<string> | null;
  setProgram: (program: Item<string> | null) => void;
  isDisabled: boolean;
};

export const SelectProgram = ({ program, setProgram, isDisabled }: Props) => (
  <FormComponent.Field label="Программа\Грант">
    <TextGridSelect<Item<string>>
      value={program}
      onChange={setProgram}
      disabled={isDisabled}
      specification={GetProgramList({ withoutMenu: true })}
      settings={{
        mode: 'selectTableRow',
        tableRowConverter,
      }}
      valueFormatter={x => x.label}
      title="Программы"
    />
  </FormComponent.Field>
);
