import { useCallback, useState } from 'react';
import * as R from 'ramda';

import { Mode } from 'components/ListEdit/model';

import { areIntervalsOverlapping, parse } from 'date-fns';
import { Project } from 'types/models';
import { Member } from 'features/SelectPerson';
import { ScientistPerson } from 'types/models/Person';
import { formatStr } from 'utils/Constants/FormatStr';
import { getMockPerformerStage } from 'features/Form/looks/project/ProjectForm/helpers';
import { PersonHistoryMode } from 'features/SelectPerson/PersonHistoryModal/model';

type Arguments = {
  jobPeriods: Project.JobPeriod[];
  setJobPeriods(jobPeriods: Project.JobPeriod[]): void;
  person: ScientistPerson | null;
  performerStages: Project.PerformerStage[];
  projectStages: Project.Stage[];
  setPerformerStages: (performerStages: Project.PerformerStage[]) => void;
};

export function useController(args: Arguments) {
  const { jobPeriods, person, performerStages, projectStages, setJobPeriods, setPerformerStages } = args;

  const [personHistoryMode, setPersonHistoryMode] = useState<PersonHistoryMode>('edit');
  const [isNeedAskUserAddStages, setIsNeedAskUserAddStages] = useState<boolean>(false);
  const [isOpenHistoryPersonModal, setIsOpenHistoryPersonModal] = useState(false);
  const [selectedJobPeriodIndex, setSelectedJobPeriodIndex] = useState<number | null>(null);
  const [performerStagesToAdd, setPerformerStagesToAdd] = useState<Project.PerformerStage[]>([]);
  const [isAddPerformerStagesPoupOpen, setIsAddPerformerStagesPoupOpen] = useState<boolean>(false);

  const onClickHistoryIcon = useCallback((_, index: number | null) => {
    if (index !== null) {
      setSelectedJobPeriodIndex(index);
      setIsOpenHistoryPersonModal(true);
    }
  }, []);

  const submitHistoryPersonModal = useCallback(
    (member: Partial<Member>) => {
      if (selectedJobPeriodIndex !== null) {
        const updatedJobPeriods = R.over(
          R.lensIndex(selectedJobPeriodIndex),
          (jobPeriod: Project.JobPeriod) => ({ ...jobPeriod, ...member }),
          jobPeriods,
        );
        setJobPeriods(updatedJobPeriods);
      }
    },
    [setJobPeriods, jobPeriods, selectedJobPeriodIndex],
  );

  const onJobPeriodSubmit = useCallback(
    (row: Project.JobPeriod, submit: (updatedRow: Project.JobPeriod) => void, index: null | number, mode: Mode) => {
      const nextRow = row;
      const isAddMode = mode === 'add';

      if (isAddMode) {
        nextRow.person = person;
      }

      const stagesToAdd = projectStages.filter(projectStage => {
        const getIsStageInJobPeriodInterval = () => {
          const isStageInJobPeriodInterval = areIntervalsOverlapping(
            {
              start: parse(projectStage.startDate, formatStr, new Date()),
              end: parse(projectStage.endDate, formatStr, new Date()),
            },
            {
              start: parse(nextRow.startDate, formatStr, new Date()),
              end: parse(nextRow.endDate, formatStr, new Date()),
            },
            { inclusive: true },
          );
          return isStageInJobPeriodInterval;
        };

        return performerStages.length
          ? performerStages.every(performerStage => {
              const isEqualNumber = performerStage.stage?.number === projectStage.number;
              const isStageInJobPeriodInterval = getIsStageInJobPeriodInterval();
              return !isEqualNumber && isStageInJobPeriodInterval;
            })
          : getIsStageInJobPeriodInterval();
      });

      if (stagesToAdd.length) {
        setPerformerStagesToAdd(stagesToAdd.map(stageToAdd => ({ ...getMockPerformerStage(), stage: stageToAdd })));
      }

      if (isAddMode) {
        setIsNeedAskUserAddStages(Boolean(stagesToAdd.length));
        setSelectedJobPeriodIndex(jobPeriods.length);
        setPersonHistoryMode('add');
        setIsOpenHistoryPersonModal(true);
      } else if (stagesToAdd.length) {
        setIsAddPerformerStagesPoupOpen(true);
      }

      submit(nextRow);
    },
    [jobPeriods.length, performerStages, person, projectStages],
  );

  const confirmAddPerformerStagesPopup = useCallback(() => {
    setPerformerStages([...performerStages, ...performerStagesToAdd]);
    setIsAddPerformerStagesPoupOpen(false);
  }, [performerStages, performerStagesToAdd, setPerformerStages]);

  const closeAddPerformerStagesPoup = useCallback(() => {
    setIsAddPerformerStagesPoupOpen(false);
  }, []);

  const closePersonHistoryMode = useCallback(() => {
    setPersonHistoryMode('edit');
    setIsOpenHistoryPersonModal(false);
    if (isNeedAskUserAddStages) {
      setIsNeedAskUserAddStages(false);
      setIsAddPerformerStagesPoupOpen(true);
    }
  }, [isNeedAskUserAddStages]);

  return {
    personHistoryMode,
    performerStagesToAdd,
    selectedJobPeriodIndex,
    isOpenHistoryPersonModal,
    isAddPerformerStagesPoupOpen,
    onJobPeriodSubmit,
    onClickHistoryIcon,
    closePersonHistoryMode,
    submitHistoryPersonModal,
    closeAddPerformerStagesPoup,
    confirmAddPerformerStagesPopup,
  };
}
