import { useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';

const useController = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [],
  );

  return {
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
};

export default useController;
