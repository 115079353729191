import React from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { Finance } from 'types/models/ProgramRequest';
import { useController } from './controller';

type Props = {
  finance: Finance | null;
  onFinanceChange: React.Dispatch<React.SetStateAction<Finance | null>>;
};

function FinanceInputs({ finance, onFinanceChange }: Props) {
  const { handleYearChange, handleFbAmoutChange, handleSfAmoutChange } = useController({
    onFinanceChange,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Год">
          <TextInput mode={TextInputMode.year} value={finance?.year} onChange={handleYearChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Финансирование, руб.">
          <TextInput
            mode={TextInputMode.number}
            value={finance?.fbAmount ? finance?.fbAmount.toString() : '0'}
            onChange={val => handleFbAmoutChange(parseFloat(val))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Софинансирование, руб.">
          <TextInput
            mode={TextInputMode.number}
            value={finance?.sfAmount ? finance?.sfAmount.toString() : '0'}
            onChange={val => handleSfAmoutChange(parseFloat(val))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { FinanceInputs };
