import { useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { useStream } from 'StreamRx';
import { TemplatesTableDependencies } from 'types/models/Table';
import { Form, RefFeedlineCommand } from 'types/models';

import { AddNewMessageData, streams } from '../streams';
import { useLocalTableStreams } from 'features/Table/hooks';

export type State = {
  isFormOpen: boolean;
  publicationMessage: Form.MagazineMessage;
  messageLabel: Form.MessageLabel;
  command?: RefFeedlineCommand;
};

const initMessage: Form.MagazineMessage = {
  id: '',
  message: '',
};

const initMessageLabel: Form.MessageLabel = {
  id: '1',
  label: '',
};

export function makeUseCustomController(deps: TemplatesTableDependencies, command?: RefFeedlineCommand) {
  return function useCustomController(): State {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [publicationMessage, setPublicationMessage] = useState<Form.MagazineMessage>(initMessage);
    const [messageLabel, setMessageLabel] = useState<Form.MessageLabel>(initMessageLabel);
    const tableStreams = useLocalTableStreams();
    const { methods: addNewMessage } = BackendAPI.useBackendAPI('SaveEventUserFeedline', {
      onSuccessfullCall: () => {
        tableStreams.reloadTable.push();
        setPublicationMessage(initMessage);
        setMessageLabel(initMessageLabel);
      },
    });

    useStream(
      () => streams.addNewGridSetting,
      ({ message, messageId, eventId, labelId }: AddNewMessageData) => {
        if (message !== undefined && labelId && eventId) {
          addNewMessage.callAPI({
            message,
            messageId,
            labelId,
            eventId,
          });
        }
      },
      [],
    );

    useStream(
      () => streams.changeMessageState,
      ({ id, message }: Partial<Form.MagazineMessage>) => {
        setPublicationMessage({
          id: id || publicationMessage.id,
          message: message !== undefined ? message : publicationMessage.message,
        });
      },
      [setPublicationMessage, publicationMessage],
    );

    useStream(
      () => streams.changeMessageLabelState,
      (label: Form.MessageLabel) => setMessageLabel(label),
      [],
    );

    useStream(
      () => streams.toggleMessageForm,
      () => setIsFormOpen(prev => !prev),
      [setIsFormOpen],
    );

    useStream(
      () => streams.clearMessageState,
      () => setPublicationMessage(initMessage),
      [],
    );

    useStream(
      () => streams.clearMessageLabelState,
      () => setMessageLabel(initMessageLabel),
      [],
    );

    return {
      isFormOpen,
      publicationMessage,
      messageLabel,
      command,
    };
  };
}
