import React, { useCallback } from 'react';
import { ListEdit } from 'components';
import { Table } from 'types/models';
import { PublicationMobileRequest } from 'types/models/Form';
import { GetAcceptableMobileRequestList } from 'features/Table/specifications/GetAcceptableMobileRequestList';
import { Permits } from 'utils/Permissions';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';

type Props = {
  workMode: Table.WorkMode;
  requests: PublicationMobileRequest[];
  relatedTableState: Table.State | undefined;
  setLocalMobileRequest: (rows: PublicationMobileRequest[]) => void;
  setLocalMobileRequestAccept: (id: string) => void;
  setLocalMobileRequestCancelAcception: (id: string) => void;
};

function Requests({
  workMode,
  requests,
  relatedTableState,
  setLocalMobileRequest,
  setLocalMobileRequestAccept,
  setLocalMobileRequestCancelAcception,
}: Props) {
  const specifications = GetAcceptableMobileRequestList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const getIsEditMobileRequestButtonDisabled = useCallback((row: PublicationMobileRequest | null) => !!row?.accepted, []);

  const getIsDeleteMobileRequestButtonDisabled = useCallback((row: PublicationMobileRequest | null) => !!row?.accepted, []);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => PublicationMobileRequest>(
    row => ({
      id: null,
      mobileRequest: {
        id: row.id,
        label: row.Label,
      },
      accepted: '',
      acceptedBy: null,
    }),
    [],
  );

  return (
    <div>
      <ListEdit
        specification={{
          mode: 'relationTableModal',
          modalTableRowConverter,
          relationTableModalTitle: 'Заявки по мобильности',
          modalTableSpecification: specifications,
        }}
        isModalHintHidden
        title="Заявки по программам академической мобильности"
        rows={requests}
        isToolbarDisabled={workMode === 'viewMode'}
        columns={[
          { label: 'Заявка', formatValue: row => row.mobileRequest?.label || '', styles: { width: '75%' } },
          { label: 'Принята к отчету', formatValue: getAcceptInfo, styles: { width: '25%' } },
        ]}
        onChange={rows => setLocalMobileRequest(rows)}
        withAcceptButtons
        onAccept={({ mobileRequest }) => setLocalMobileRequestAccept(mobileRequest?.id || '')}
        onAcceptanceCancel={({ mobileRequest }) => setLocalMobileRequestCancelAcception(mobileRequest?.id || '')}
        addPermissionName={Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT}
        editPermissionName={Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT}
        acceptPermissionName={Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING}
        getIsDeleteDisabled={getIsEditMobileRequestButtonDisabled}
        getIsEditDisabled={getIsDeleteMobileRequestButtonDisabled}
        visibleToolbarButtons={['add', 'delete']}
      />
    </div>
  );
}

export { Requests };
