import React, { useMemo } from 'react';

import { Component as GridComponent } from './component';

import { Specification } from 'types/models/Table';
import { GridContext, GridContextType } from './context';

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = {
  specification: Specification<CustomState, RequestData>;
  hasPersonalMode?: boolean;
};
export function DataGrid<CustomState extends {} = {}, RequestData extends {} = {}>({
  specification,
  hasPersonalMode,
}: Props<CustomState, RequestData>) {
  const contextValue = useMemo<GridContextType<CustomState, RequestData>>(() => {
    return { specification, hasPersonalMode };
  }, [specification, hasPersonalMode]);

  return (
    <GridContext.Provider value={contextValue}>
      <GridComponent<CustomState, RequestData> />
    </GridContext.Provider>
  );
}
