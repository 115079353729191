import { ListEdit } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { SecurityDocumentPayment } from 'types/models';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { PaymentInputs } from './PaymentInputs/PaymentInputs';
import { validatePaymentByDocument } from './validatePaymentByDocument';
import useDownloadButton from './useDownloadButton';

type Props = {
  disabled?: boolean;
  payments: SecurityDocumentPayment[];
  handlePaymentsChange: (value: SecurityDocumentPayment[]) => void;
  paymentExtraToolbarButtons: ExtraToolbarButton<SecurityDocumentPayment>[];
};

export function PaymentByDocument({ payments, handlePaymentsChange, disabled, paymentExtraToolbarButtons }: Props) {
  const { extraToolbarButtons } = useDownloadButton();
  return (
    <ListEdit
      withMessages
      isDeleteConfirmEnabled
      extraToolbarButtons={[...paymentExtraToolbarButtons, ...extraToolbarButtons]}
      rows={payments}
      isToolbarDisabled={disabled}
      isFullScreenedTable
      onChange={handlePaymentsChange}
      columnIndexesForSumTotal={[2, 3]}
      columns={[
        {
          label: '№',
          formatValue: (_, index) => index + 1,
          styles: { width: '5%' },
        },
        {
          label: 'Оплатить до',
          formatValue: x => x?.payBeforeDate || '',
        },
        {
          label: 'Назначение',
          formatValue: x => x?.paymentPurpose?.label || '',
        },
        {
          label: 'Сумма',
          formatValue: x => formatNumber(x?.amount || '0'),
        },
        {
          label: 'Оплачено',
          formatValue: x => x?.payDate || '',
        },
        {
          label: 'Файл документа',
          formatValue: x => x?.file?.name || '',
        },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => <PaymentInputs payment={row} onPaymentChange={onChange as any} />,
        validation: {
          checkIsValid: (row, index, list, mode) =>
            validatePaymentByDocument(row, mode, list, index).every(({ isValid }) => isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePaymentByDocument(row, mode, list, index);
            showErrorsMessages(validationInfo?.filter(({ isValid }) => !isValid).map(({ invalidMessage }) => invalidMessage));
          },
        },
      }}
    />
  );
}
