import React from 'react';
import { SubmitTable } from 'features/Table/streams';
import { Entry } from 'types/models/Table';
import useController from './controller';
import { Toolbar } from 'components';

export type Props = {
  selectedRows: Entry[];
  onSubmitTable?: ({ selectedRows }: SubmitTable) => void;
};

export const SelectDataControl = ({ selectedRows, onSubmitTable }: Props) => {
  const { buttons } = useController({ selectedRows, onSubmitTable });
  return <Toolbar buttons={buttons} />;
};
