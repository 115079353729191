import React from 'react';

import { Reference, TextInput, FormComponent } from 'components';

import { Tender } from 'types/models';
import { useController } from './controller';

type Props = {
  customer: Tender.Customer | null;
  onCustomerChange(row: Tender.Customer): void;
};

function TenderCustomerInputs({ customer, onCustomerChange }: Props) {
  const { handleChange, handleCustomerIdChange } = useController({
    customer,
    onCustomerChange,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Организация" isRequired>
          <Reference
            relationTableModalTitle="Список организаций"
            name="RefEnterpriseCustomer"
            value={customer?.customerId ? { id: customer.customerId, label: customer?.fullName } : { id: '', label: '' }}
            onChange={value => handleCustomerIdChange()(value)}
            theme="big"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Description mode="warning">
        В поле по умолчанию отображается официальный адрес и контакты организации, указанные на вкладке Адрес и контакты в
        карточке организации. Вы можете ввести адрес и контакты, указанные в конкурсной документации. Внимание: адрес и контакты
        для конкурса хранятся только для конкретного конкурса.
      </FormComponent.Description>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Адрес">
          <TextInput value={customer?.address} onChange={handleChange('address')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Контакты">
          <TextInput value={customer?.contacts} onChange={handleChange('contacts')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { TenderCustomerInputs };
