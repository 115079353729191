import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { ConferenceTypeProps } from 'types/models/Event';
import { Modal } from 'components';

const ContestParticipationAddTemplate: React.FC<F.TemplateProps & ConferenceTypeProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  disableUpdateAfterSubmit,
  sourceEvent,
  disabledEvent,
}: F.TemplateProps & ConferenceTypeProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook = {
    apiID: 'GetParticipation',
    template: 'ParticipationFormContest',
    relatedTableState,
    viewMode: false,
    editMode: false,
    sourceEvent,
    disableUpdateAfterSubmit,
    disabledEvent,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ContestParticipationAddTemplate);
