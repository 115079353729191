import React, { useMemo } from 'react';
import { block } from 'bem-cn';

import { FormComponent, TextDateTime, TextInput, TextInputMode } from 'components';
import { Mode } from 'components/ListEdit/model';

import { Project } from 'types/models';
import { calcNdsSumm, formatNumber } from 'utils/Helpers';
import { InfoDescription, useController } from './controller';

import './style.scss';

const b = block('research-job-stages-fields');

type Props = {
  stage: Project.Stage;
  setStage(stage: Project.Stage): void;
  financings: Project.Financing[];
  mode: Mode;
};

export function Fields(props: Props) {
  const { stage, setStage, financings, mode } = props;
  const { makeChangeHandler, sumFields } = useController({ stage, setStage, mode, financings });

  const renderSumField = (item: InfoDescription) => {
    const { label, percentKey, sumKey, financingType } = item;
    const financing = financings.find(x => x.type?.value === financingType);
    const isHasFinancing = useMemo(() => Boolean(financing), [financing]);

    const disabled = useMemo(() => !isHasFinancing, [isHasFinancing]);
    const isFinancingWithNds = useMemo(() => Boolean(financing?.isNds), [financing?.isNds]);

    return (
      <FormComponent.Line key={item.label} lineSize="padded">
        <FormComponent.Field label={label} isRequired={isHasFinancing}>
          <div className={b('line')}>
            <TextInput
              mode={TextInputMode.number}
              value={stage[sumKey] as string}
              onChange={makeChangeHandler(sumKey)}
              isDisabled={disabled}
            />
            <div className={b('separator')}>в т. ч. НДС%</div>
            <TextInput
              mode={TextInputMode.number}
              value={stage[percentKey] as string}
              onChange={makeChangeHandler(percentKey)}
              isDisabled={disabled || !isFinancingWithNds}
            />
            <div className={b('separator')}>НДС, руб.:</div>
            <div className={b('separator', { left: true })}>
              {formatNumber(calcNdsSumm(stage[sumKey] as string, stage[percentKey] as string))}
            </div>
          </div>
        </FormComponent.Field>
      </FormComponent.Line>
    );
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field isRequired label="Номер этапа">
          <TextInput mode={TextInputMode.number} value={stage.number} onChange={makeChangeHandler('number')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap>
        <FormComponent.Field isRequired label="Сроки этапа">
          <TextDateTime
            isRange
            value={`${stage.startDate || ''}-${stage.endDate || ''}`}
            onChange={(value: string) => {
              const dates = value.split('-');
              setStage({ ...stage, startDate: dates[0] || '', endDate: dates[1] || '' });
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <>{sumFields.map(renderSumField)}</>
    </FormComponent.Wrapper>
  );
}
