import { Table, SecurityDocumentType, SecurityDocumentTypes } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export const DocumentTitles = {
  PATENT: 'Список заявок и патентов',
  EVIDENCE: 'Свидетельства',
  KNOW_HOW: 'Ноу-хау',
  NON_REGISTERED: 'Объекты авторского права',
};

type Arguments = {
  documentType?: SecurityDocumentType | null;
  hasSelectButton?: boolean;
};

export function GetSecurityDocumentList(args?: Arguments): Table.Specification<State, Arguments> {
  return {
    apiID: 'GetSecurityDocumentList',
    requestData: { documentType: args?.documentType },
    header: {
      firstLevel: {
        title: DocumentTitles[args?.documentType ?? SecurityDocumentTypes.PATENT],
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: args?.hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController(args?.documentType as SecurityDocumentTypes),
  };
}
