import { Person } from 'types/models';

export function validate(education: Person.ScientistEducation | null) {
  if (!education) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidStatus = Boolean(education.status);
  const isValidEnteprise = Boolean(education.enterprise);

  return [
    { isValid: isValidStatus, invalidMessage: 'Заполните статус' },
    { isValid: isValidEnteprise, invalidMessage: 'Заполните организацию' },
  ];
}
