import React from 'react';

import { buttonIcons, IconButtonProps, Toolbar as SharedToolbar } from 'components';

export type Props = {
  handleSave: () => void;
  handleSaveAsDraft: () => void;
  handleSaveAndContinue: () => void;
  viewMode?: boolean;
  isOnlyEditing?: boolean;
  handleOpenPublicationPreprintRelations: () => void;
  isDisabled: boolean;
};

function Toolbar({
  handleSave,
  handleSaveAsDraft,
  handleSaveAndContinue,
  handleOpenPublicationPreprintRelations,
  viewMode: viewModeProp,
  isOnlyEditing,
  isDisabled,
}: Props) {
  const viewMode = isOnlyEditing ? false : viewModeProp;

  const buttons: IconButtonProps[] = [
    {
      icons: buttonIcons.save,
      title: 'Сохранить',
      code: 'add',
      isDisabled: !!viewMode || isDisabled,
      onClick: handleSave,
    },
    {
      icons: buttonIcons.saveDraft,
      title: 'Сохранить как черновик',
      code: 'add',
      isDisabled: !!viewMode || isDisabled,
      isHidden: isOnlyEditing,
      onClick: handleSaveAsDraft,
    },
    {
      icons: buttonIcons.saveAndContinue,
      title: 'Сохранить и продолжить',
      code: 'addAndSave',
      isDisabled: !!viewMode || isDisabled,
      isHidden: isOnlyEditing,
      onClick: handleSaveAndContinue,
    },
    {
      icons: buttonIcons.articleRelations,
      title: 'Указать связь между препринтом и публикацией',
      code: 'articleRelations',
      isDisabled,
      isHidden: isOnlyEditing,
      onClick: handleOpenPublicationPreprintRelations,
      permissionName: 'PublicationArticleTranslationEdit',
    },
  ];

  return <SharedToolbar buttons={buttons} mode="form" />;
}

export const ControlPanel = React.memo(Toolbar);
