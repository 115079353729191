import React, { useCallback } from 'react';
import block from 'bem-cn';

import { buttonIcons, IconButton } from 'components';

import { HelpfulStuff } from 'types/models/HelpfulStuff';
import { downloadFile, getAuthToken } from 'utils/Helpers';

import '../style.scss';

const b = block('helpful');

type Props = {
  props: HelpfulStuff;
};

function Block({ props }: Props) {
  const { createdDate, data, file } = props;

  const token = getAuthToken();

  const handleDownloadButtonClick = useCallback(() => {
    downloadFile(file.id, token);
  }, [file.id, token]);

  return (
    <div className={b('block')}>
      <div className={b('block-icon')}>
        <IconButton icons={buttonIcons.downloadTextLink} onClick={handleDownloadButtonClick} title="Скачать" />
      </div>

      <div className={b('block-data')}>
        <em>({createdDate})</em> {data}
      </div>
    </div>
  );
}

export const Component = React.memo(Block);
