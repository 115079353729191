import React from 'react';
import { block } from 'bem-cn';

import { QnaItem } from 'features/Form/looks/person/Tabs/QuestionsAndAnswers/controller';

import './style.scss';

const b = block('question-item');

type Props = {
  question: QnaItem;
};

export function QuestionAndAnswersItem(props: Props) {
  const { question } = props;

  return (
    <div className={b()}>
      <h5 className={b('title')}>{question.message}</h5>
      <p className={b('text')}>{question.answer}</p>
      {question?.hashtags.length > 0 && (
        <p className={b('text', { gray: true })}>{question.hashtags.map((tag: string) => `#${tag} `)}</p>
      )}
    </div>
  );
}
