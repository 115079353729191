import { ReferenceItem } from 'components';

import { FileInfo, Document } from 'types/models';
import { SecurityDocumentContract } from 'types/models/SecurityDocumentContract';
import { EditableNominee } from 'features/IncentivePayments';
import { Department as PaymentDepartment, TopLevelDepartment } from 'types/models/Payment';
import { Item } from '../common';
import { Department, RefElement, Project } from '../Project';
import {
  ScientistAcademicRank,
  ScientistDegree,
  ScientistEducation,
  ScientistJob,
  ScientistPerson,
  ScientistRank,
} from '../Person';

export type SecurityDocumentType = 'PATENT' | 'EVIDENCE' | 'KNOW_HOW' | 'NON_REGISTERED';

export enum SecurityDocumentTypes {
  PATENT = 'PATENT',
  EVIDENCE = 'EVIDENCE',
  KNOW_HOW = 'KNOW_HOW',
  NON_REGISTERED = 'NON_REGISTERED',
}

export type SecurityDocument = {
  id: string | null;
  label: string;
  type: Item<string> | null;
  intellectualPropertyName: string;
  intellectualPropertyKind: ReferenceItem | null;
  intellectualPropertyKindNonRegistered?: ReferenceItem | null;
  referat: string;
  requestNumber: string;
  requestStatus: Item<string> | null;
  requestDate: string;
  requestSendDate: string;
  documentDate: string;
  documentReceiveDate: string;
  balanceOrderNumber?: number;
  loDate?: string;
  hiDate?: string;
  countries: ReferenceItem[];
  isWorldwide: boolean;
  isRus: boolean;
  balancePrice?: number;
  documentNumber?: string;
  internalNumber?: string;
  documentStartDate?: string;
  documentEndDate?: string;
  stateNumber?: string;
  stateRegistrationDate?: string;
  mpks?: ReferenceItem[] | null;
  members: SecurityDocumentMember[];
  grntis: SecurityDocumentRefElement[];
  udks: SecurityDocumentRefElement[];
  okveds: SecurityDocumentRefElement[];
  lksetss: SecurityDocumentRefElement[];
  scienceBrunches: SecurityDocumentRefElement[];
  scienceDomainInterrests: SecurityDocumentRefElement[];
  priorityTechnologies: SecurityDocumentRefElement[];
  pnrs: SecurityDocumentRefElement[];
  pnmitrs: SecurityDocumentRefElement[];
  pnis: SecurityDocumentRefElement[];
  ntrStrategies: SecurityDocumentRefElement[];
  technologyPlatforms: SecurityDocumentRefElement[];
  domainKnowledges: SecurityDocumentRefElement[];
  oecds: SecurityDocumentRefElement[];
  ntiEteTechnologies: SecurityDocumentRefElement[];
  ntiMarkets: SecurityDocumentRefElement[];
  contracts: SecurityDocumentContract[];
  payments: SecurityDocumentPayment[];
  project: Project | null;
  documents: Document[];
  departments: Department[];
};

export type SecurityDocumentRefElement = {
  isProject?: boolean;
  isActual?: boolean;
} & RefElement;

export enum SecurityDocumentMemberTypes {
  APPLICANT = 'APPLICANT',
  AUTHOR = 'AUTHOR',
  ADMITTED_PERSON = 'ADMITTED_PERSON',
}

export type SecurityDocumentMember = {
  id: string | null;
  contribution: number;
  type: Item<SecurityDocumentMemberTypes> | null;
  person: ScientistPerson | null;
  name: string;
  historyLabel: string;
  contractNumber?: string;
  contractDate?: string;
  contractPortion?: string;
  contractNote?: string;
  enterprise?: ReferenceItem | null;
  job: ScientistJob | null;
  education: ScientistEducation | null;
  degree: ScientistDegree | null;
  citizenship: ReferenceItem | null;
  rank: ScientistRank | null;
  academicRank: ScientistAcademicRank | null;
};

export type SecurityDocumentPayment = {
  id: string | null;
  securityDocument: SecurityDocumentInfo | null;
  paymentPurposeRequest?: ReferenceItem;
  paymentPurpose?: ReferenceItem;
  payDate?: string;
  payBeforeDate?: string;
  amount: number;
  file?: FileInfo;
};

export type SecurityDocumentInfo = {
  id: string;
  type?: Item<SecurityDocumentType>;
  fullName?: string;
  documentNumber?: string;
  value?: string;
};

export type PaymentRequestPerson = {
  id: string | null;
  fullName: string;
  shortName?: string;
};

export type Nominee = {
  id?: string | null;
  person: ScientistPerson;
  isAffiliated: boolean;
  isPaid: boolean;
  planMoney: string;
  factMoney: string;
  weight: string;
  name: string;
  job: NomineeJob | ScientistJob | null;
  citizenship?: ReferenceItem | null;
  topLevelDepartment: TopLevelDepartment | null;
} & EditableNominee;

export type NomineeJob = {
  id?: string;
  originalId: string;
  status: Item<string>;
  enterprise: string;
  refAppointment: ReferenceItem;
  isStaff: boolean;
  refDepartment: ReferenceItem;
  loDate: string;
  appointment: string;
};

export type SecurityDocumentPaymentRequest = {
  id: string | null;
  patent: SecurityDocument | null;
  type: Item<string> | null;
  year: string;
  quarter: Item<string> | null;
  month: string;
  status: Item<string> | null;
  statusChangedDate: string;
  statusChangedTime: string;
  statusChanger: PaymentRequestPerson | null;
  creator: PaymentRequestPerson | null;
  regulationParagraph: string;
  regulationName: string;
  nominees: Nominee[];
  documents: Document[];
  distributionMethodBetweenNominees: string;
  distributionMethodBetweenFunds: string;
  maxFundMoney: string;
  factFundMoney: string;
  remainderFundMoney: string;
  factNomineesMoney: string;
  maxNomineesMoney: string;
  remainderNomineesMoney: string;
  requestsByFoundation: ReferenceItem[];
  foreignDocuments: Document[];

  isManualEdit?: boolean;
  departments?: PaymentDepartment[];
};

export type ForeignSecurityDocument = {
  id?: string | null;
  name: string;
  number?: string;
  documentType: Item<string> | null;
  status: Item<string> | null;
  kind: ReferenceItem | null;
  startDate: string;
  endDate: string;
  documents: Document[];
  members: SecurityDocumentMember[];
};
