import { Filters } from './filters';
import { Toolbar } from 'features/IncentivePayments/Toolbar/Toolbar';
import { Form } from 'features/IncentivePayments/Form';

import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';

export function GetExhibitionAwardsPaymentRequests(): Table.Specification<State> {
  return {
    apiID: 'GetExhibitionAwardsPaymentRequests',
    header: {
      firstLevel: {
        Panel: Filters,
        title: 'Заявки на оплату получения наград',
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController('MEDAL'),
    NeighborTableComponent: Form,
  };
}
