import React, { useMemo, useState } from 'react';

import { Table } from 'types/models';
import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons, ConfirmPopup } from 'components';

import { MessageModal } from '../MessageModal/MessageModal';

import { State } from '../makeUseCustomController';
// import { RequestFeedlineType } from 'utils/Enums';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;
  const {
    isOpenModal,
    message,
    isDisabledToolbar,
    openModal,
    closeModal,
    // getMessage,
    changeMessage,
    saveMessage,
    deleteMessage,
  } = customState;

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);

  // const selectedRowsLength = useMemo(() => tableState.selectedRows.length, [tableState]);
  // const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(() => {
  //   return {
  //     ALWAYS: true,
  //     SINGLE_SELECTED: selectedRowsLength === 1,
  //     MULTIPLE_SELECTED: selectedRowsLength >= 1,
  //   };
  // }, [selectedRowsLength]);

  // const isEditButtonDisabled = useMemo(
  //   () => rowsAvailability.SINGLE_SELECTED && tableState.selectedRows[0]['id:Type'] !== RequestFeedlineType.USER_MESSAGE,
  //   [rowsAvailability.SINGLE_SELECTED, tableState.selectedRows],
  // );
  // const isDeleteButtonDisabled = useMemo(
  //   () => rowsAvailability.SINGLE_SELECTED && tableState.selectedRows[0]['id:Type'] !== RequestFeedlineType.USER_MESSAGE,
  //   [rowsAvailability.SINGLE_SELECTED, tableState.selectedRows],
  // );

  const [row] = tableState.selectedRows;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        isDisabled: isDisabledToolbar,
        onClick: () => {
          openModal();
        },
      },
      // {
      //   icons: buttonIcons.edit,
      //   title: 'Редактировать',
      //   isDisabled: isEditButtonDisabled,
      //   onClick: () => {
      //     // getMessage(row.id);
      //     changeMessage('id', tableState.selectedRows[0].id);
      //     changeMessage('message', tableState.selectedRows[0].Message);
      //     openModal();
      //   },
      // },
      // {
      //   icons: buttonIcons.delete,
      //   title: 'Удалить',
      //   isDisabled: isDeleteButtonDisabled,
      //   onClick: () => {
      //     setIsOpenConfirmDeleteModal(true);
      //   },
      // },
    ],
    [isDisabledToolbar, openModal],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <MessageModal
        isOpen={isOpenModal}
        onClose={closeModal}
        changeMessage={changeMessage}
        onSave={saveMessage}
        message={message}
      />
      <ConfirmPopup
        title="Удаление сообщения"
        text="Вы точно хотите удалить выбранное сообщение?"
        isOpen={isOpenConfirmDeleteModal}
        okButtonText="Да"
        resetButtonText="Отмена"
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        onConfirm={() => {
          deleteMessage(row.id);
          setIsOpenConfirmDeleteModal(false);
        }}
        icon="warning"
      />
    </>
  );
}

export { Toolbar };
