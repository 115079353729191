import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Form, RecordStatus as RS, Author } from 'types/models';
import { ScientistPerson } from 'types/models/Person';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { checkBibliographicRecord } from 'utils/Validators';
import { showNotification } from 'features/Notifications';
import { tabsStreams } from 'components/Tabs/streams';
import { Notification } from 'features/Notifications';

type Props = {
  currentStatusText: string;
  currentStatus: RS.Code;
  prepareFormFields: Form.PrepareFormFieldsAction;
  startCheckDuplicates: (data: Form.StartCheckDuplicatesData) => void;
  savePublication: (data: Form.SavePublicationData) => void;
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  fetchBibliographicRecord?: Form.FetchBibliographicRecordAction;
  initialLanguageCode: string;
  authors?: Author[];
  setAuthors?: Dispatch<SetStateAction<Author[]>>;
  isProfile?: boolean;
  currentPerson?: ScientistPerson | null;
  type?: string;
  tabsId?: string;
  authorsTabIndex?: number;
};

const useSavePublicationHook = ({
  currentStatus,
  currentStatusText,
  setFormFields,
  fetchBibliographicRecord,
  prepareFormFields,
  savePublication,
  startCheckDuplicates,
  initialLanguageCode,
  authors,
  setAuthors,
  isProfile,
  currentPerson,
  type,
  tabsId,
  authorsTabIndex,
}: Props) => {
  const isAuthorsValid = !!(authors || []).find(x => x.person?.id === currentPerson?.id);
  const isAuthorsCheckable = !!authors && !!setAuthors && !!isProfile && !!currentPerson && !!type;
  const { draftValidationResult, validationResult, preparedFormFields } = prepareFormFields();

  const [bibliographicRecordErrorText, setBibliographicRecordErrorText] = useState<string>('');
  const [isBibliographicErrorPopupOpen, setIsBibliographicErrorPopupOpen] = useState<boolean>(false);
  const [isDraftWarningPopupOpen, setIsDraftWarningPopupOpen] = useState<boolean>(false);
  const [isCantSaveInPreviousStatusPopupOpen, setIsCantSaveInPreviousStatusPopupOpen] = useState<boolean>(false);
  const [cantSaveInPreviousStatusText, setIsCantSaveInPreviousStatusText] = useState<string>('');
  const [isAuthorsPopupOpen, setIsAuthorsPopupOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<RS.Code>(currentStatus);
  const [saveActionCode, setSaveActionCode] = useState<Form.SaveActionCode>('saveAsDraft');

  const { methods: getPublication, state: loadPersonAuthorState } = BackendAPI.useBackendAPI('GetPublication');

  const onFormInvalidate = useCallback(
    ({
      invalidFieldKeys,
      nextNotification,
      formFields,
    }: {
      invalidFieldKeys: string[];
      nextNotification: Pick<Notification, 'message' | 'theme'>;
      formFields: Form.Fields;
    }) => {
      if (invalidFieldKeys.length) {
        invalidFieldKeys.forEach((key: string) => {
          const preparedKey = key as keyof typeof formFields;
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            [preparedKey]: { ...prevState[preparedKey], isValid: false },
          }));
        });
      }
      if (nextNotification.message) {
        showNotification(nextNotification);
      }
    },
    [setFormFields],
  );

  const checkBiblio = useCallback(
    async ({ textLanguageId }: Form.CheckBibliographicRecordArguments) => {
      if (fetchBibliographicRecord) {
        const nextBibliographicRecord = await fetchBibliographicRecord();
        const bibliographicRecordValidateResult = checkBibliographicRecord({
          textLanguageId,
          bibliographicRecord: nextBibliographicRecord || '',
          cyrillicLanguageCode: initialLanguageCode,
        });

        if (!bibliographicRecordValidateResult.isValid) {
          setIsBibliographicErrorPopupOpen(true);
          setBibliographicRecordErrorText(bibliographicRecordValidateResult.text);
        }

        return bibliographicRecordValidateResult.isValid;
      }

      return true;
    },
    [fetchBibliographicRecord, initialLanguageCode],
  );

  const startSaveForm = useCallback(
    async ({ saveActionCode: saveActionCodeArg }: Form.StartSavePublicationData) => {
      const isSave = saveActionCodeArg === 'save';
      const isSaveAsDraft = saveActionCodeArg === 'saveAsDraft';
      const nextStatus = currentStatus === RecordStatus.APPROVED ? RecordStatus.APPROVED : RecordStatus.ADDED;
      if (isSaveAsDraft && validationResult.isFormValid) {
        setIsDraftWarningPopupOpen(true);
      } else if (draftValidationResult?.isFormValid && !validationResult.isFormValid) {
        if ([RecordStatus.ADDED, RecordStatus.APPROVED].includes(currentStatus)) {
          setIsCantSaveInPreviousStatusText(
            // eslint-disable-next-line max-len
            `Нельзя сохранить в текущем статусе "${currentStatusText}". ${validationResult.nextNotification.message} Сохранить в статусе "Черновик"?`,
          );
          setIsCantSaveInPreviousStatusPopupOpen(true);
        } else {
          if (draftValidationResult?.isFormValid && !validationResult?.isFormValid && isSave) {
            setIsCantSaveInPreviousStatusText(
              // eslint-disable-next-line max-len
              `${validationResult.nextNotification.message} Публикация будет сохранена в статусе "Черновик".`,
            );
            setIsCantSaveInPreviousStatusPopupOpen(true);
          } else {
            savePublication({ nextStatus: RecordStatus.DRAFT, isNeedClose: isSave || isSaveAsDraft, preparedFormFields });
          }
        }
      } else if (validationResult.isFormValid) {
        setStatus(nextStatus);
        if (saveActionCodeArg === 'saveAndContinue') startCheckDuplicates({ nextStatus, isNeedClose: false, preparedFormFields });
        else if (isSave) {
          if (isAuthorsCheckable && !isAuthorsValid) setIsAuthorsPopupOpen(true);
          else if (await checkBiblio({ textLanguageId: preparedFormFields.textLanguage?.value?.id }))
            startCheckDuplicates({ nextStatus, isNeedClose: true, preparedFormFields });
        }
      } else {
        onFormInvalidate({
          invalidFieldKeys: validationResult.invalidFieldKeys,
          nextNotification: validationResult.nextNotification,
          formFields: preparedFormFields,
        });
      }
    },
    [
      currentStatus,
      validationResult.isFormValid,
      validationResult.nextNotification,
      validationResult.invalidFieldKeys,
      draftValidationResult?.isFormValid,
      currentStatusText,
      savePublication,
      preparedFormFields,
      startCheckDuplicates,
      isAuthorsCheckable,
      isAuthorsValid,
      checkBiblio,
      onFormInvalidate,
    ],
  );

  const handleCloseDraftWarningPopup = useCallback(() => {
    setIsDraftWarningPopupOpen(false);
  }, []);

  const handleCloseCantSaveInPreviousStatusPopup = useCallback(() => {
    setIsCantSaveInPreviousStatusPopupOpen(false);
  }, []);

  const handleCloseBibliographicErrorPopup = useCallback(() => {
    setIsBibliographicErrorPopupOpen(false);
  }, []);

  const handleCloseAuthorsPopup = useCallback(() => {
    setIsAuthorsPopupOpen(false);
  }, []);

  const handleConfirmCantSaveInPreviousStatusPopup = useCallback(() => {
    setIsCantSaveInPreviousStatusPopupOpen(false);
    startCheckDuplicates({
      nextStatus: status,
      isNeedClose: ['save', 'saveAsDraft'].includes(saveActionCode),
      preparedFormFields,
    });
  }, [preparedFormFields, saveActionCode, startCheckDuplicates, status]);

  const handleConfirmDraftWarningPopup = useCallback(() => {
    setIsDraftWarningPopupOpen(false);
    savePublication({ nextStatus: RecordStatus.DRAFT, isNeedClose: true });
  }, [savePublication]);

  const handleConfirmBibliographicRecordError = useCallback(() => {
    setIsBibliographicErrorPopupOpen(false);
    startCheckDuplicates({ nextStatus: status, isNeedClose: true });
  }, [startCheckDuplicates, status]);

  const checkBiblioAsync = useCallback(async () => {
    if (await checkBiblio({ textLanguageId: preparedFormFields.textLanguage?.value?.id }))
      startCheckDuplicates({ nextStatus: RecordStatus.DRAFT, isNeedClose: true });
    else setIsBibliographicErrorPopupOpen(true);
  }, [checkBiblio, preparedFormFields.textLanguage?.value?.id, startCheckDuplicates]);

  const handleConfirmAuthorsPopup = useCallback(() => {
    setIsAuthorsPopupOpen(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const a = (async function () {
      await checkBiblioAsync();
    })();
  }, [checkBiblioAsync]);

  const handleResetAuthorsPopup = useCallback(() => {
    setIsAuthorsPopupOpen(false);
    if (authorsTabIndex && tabsId) tabsStreams.setCurrentTab.push({ nextSelectedTab: authorsTabIndex, tabsId });
    getPublication.callAPI(
      {
        simpleFields: {
          status: RecordStatus.DRAFT,
          typeEdition: 'LOCAL',
          type,
          isElectronic: false,
          presetAuthorCategory: 'author',
        },
        attrIdFields: {},
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
        mobileRequests: { mobileRequest: [] },
        projects: [],
        arm: 'pc',
      },
      {
        onSuccessfullCall: ({ data }) => {
          const userPublicationMember = data.members[0];
          if (userPublicationMember) setAuthors!((prevState: Author[]) => [...prevState, userPublicationMember]);
          setTimeout(function () {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const a = (async function () {
              await checkBiblioAsync();
            })();
          }, 0);
        },
      },
    );
  }, [authorsTabIndex, checkBiblioAsync, getPublication, setAuthors, tabsId, type]);

  const handlePublicationSave = useCallback(() => {
    setSaveActionCode('save');
    startSaveForm({ saveActionCode: 'save' });
  }, [startSaveForm]);

  const handlePublicationSaveAsDraft = useCallback(() => {
    setSaveActionCode('saveAsDraft');
    startSaveForm({ saveActionCode: 'saveAsDraft' });
  }, [startSaveForm]);

  const handlePublicationSaveAndContinue = useCallback(() => {
    setSaveActionCode('saveAndContinue');
    startSaveForm({ saveActionCode: 'saveAndContinue' });
  }, [startSaveForm]);

  // использутеся для тех форм, которые ищут дубликаты не через общий функционал suchlike
  const handleContinueSave = useCallback(
    ({ isWithMessage }: { isWithMessage: boolean }) => {
      savePublication({ nextStatus: status, isNeedClose: true, isWithMessage });
    },
    [savePublication, status],
  );

  return {
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleContinueSave,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    loadPersonAuthorState,
  };
};

export default useSavePublicationHook;
