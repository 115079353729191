import { ReferenceItem, Tabs, Tab } from 'components';

import { EnumMap } from 'types/models/Table';
import { EditableMetadataField, Mode } from 'features/Table/specifications/GetReferenceElementList/model';
import { Fields } from 'features/Table/specifications/GetReferenceElementList/Editor/Fields/Fields';

type Props = {
  mode: Mode | null;
  fields: EditableMetadataField[];
  handleChangeField(name: string, value: string | ReferenceItem): void;
  enumMap: EnumMap;
  isRecordEditable: boolean;
};

function CustomersForms(props: Props) {
  const { handleChangeField, mode, fields, enumMap, isRecordEditable } = props;

  const mainFields = [
    'name',
    'fullName',
    'ogrn',
    'inn',
    'kpp',
    'sectorActivity',
    'lksdEnterpriseType',
    'phone',
    'email',
    'website',
    'details',
    'isMipTgu',
  ];

  return (
    <Tabs>
      <Tab title="Основные">
        <Fields
          mode={mode}
          fields={fields.filter(x => mainFields.includes(x.name))}
          onChange={handleChangeField}
          enumMap={enumMap}
          isRecordEditable={isRecordEditable}
        />
      </Tab>
      <Tab title="Адрес">
        <Fields
          mode={mode}
          fields={fields.filter(x => !mainFields.includes(x.name))}
          onChange={handleChangeField}
          enumMap={enumMap}
          isRecordEditable={isRecordEditable}
        />
      </Tab>
    </Tabs>
  );
}

export { CustomersForms };
