import { SecurityDocument, Form, SecurityDocumentTypes } from 'types/models';

export function validate(p: SecurityDocument | null | undefined, formFields: Form.Fields) {
  const invalidTitles = [];
  if (p?.type?.value === SecurityDocumentTypes.KNOW_HOW && !formFields.documentNumber.value) {
    invalidTitles.push('Номер приказа');
  }
  if (p?.type?.value !== SecurityDocumentTypes.KNOW_HOW && p?.type?.value !== SecurityDocumentTypes.NON_REGISTERED) {
    if (formFields.documentNumber.value) {
      Object.keys(formFields).forEach(fieldName => {
        if ((fieldName === 'documentEndDate' || fieldName === 'documentStartDate') && !formFields[fieldName]?.value) {
          invalidTitles.push(formFields[fieldName].title);
        }
      });
    }
  }
  if (p?.type?.value === 'NON_REGISTERED') {
    if (!formFields.intellectualPropertyKindNonRegistered.value) {
      invalidTitles.push(formFields.intellectualPropertyKindNonRegistered.title);
    }
  }
  const required = Object.keys(formFields)
    .filter((fieldName: string) => {
      if (
        p?.type?.value === SecurityDocumentTypes.KNOW_HOW &&
        (fieldName === 'intellectualPropertyKind' ||
          fieldName === 'requestStatus' ||
          fieldName === 'requestDate' ||
          fieldName === 'requestNumber')
      ) {
        return false;
      }
      if (
        p?.type?.value === SecurityDocumentTypes.NON_REGISTERED &&
        (fieldName === 'intellectualPropertyKind' ||
          fieldName === 'requestNumber' ||
          fieldName === 'requestDate' ||
          fieldName === 'documentEndDate')
      ) {
        return false;
      }
      if (p?.type?.value === SecurityDocumentTypes.NON_REGISTERED && fieldName === 'internalNumber') {
        return !formFields[fieldName]?.value;
      }
      return formFields[fieldName].required && !formFields[fieldName]?.value;
    })
    .map((fieldName: string) => {
      return formFields[fieldName].title;
    });
  if (p?.type?.value === SecurityDocumentTypes.NON_REGISTERED) {
    if (!formFields.documentDate?.value) {
      invalidTitles.push('Дата создания');
    }
    if (!formFields.loDate?.value) {
      invalidTitles.push('Дата начала');
    }
  }
  if (!p?.members?.length) {
    invalidTitles.push('Авторы');
  }

  return [...required, ...invalidTitles];
}
