import { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';

import { EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';
import { Program, Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { ProjectScientistRole } from 'utils/Enums';
import { getPerformerIndexByLastJobPeriodRole, updateStageIndicators } from 'features/Form/looks/project/ProjectForm/helpers';
import { getEnum } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';

type Arguments = {
  project: Project.Project | null;
  setProject(job: Project.Project): void;
  indicators: EditableIndicator[];
  indices: EditableIndicator[];
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
};

const CLEAR_PROGRAM_WARNING_TEXT =
  'Было очищено поле  <b>программа</b>' +
  ', данное изменение повлечет изменения в списках <b>индикаторов и показателей</b> на вкладке ' +
  "<b>'Результаты проекта'</b>. Все элементы результативности будут удалены.\n" +
  "Аналогичные изменения коснутся списка <b>областей знаний</b> на вкладке <b>'Описание проекта'</b>";
const CLEAR_PROGRAM_WARNING_TITLE = 'Очищена программа';

const CHANGE_PROGRAM_WARNING_TEXT =
  'Была изменена <b>программа</b>' +
  ', данное изменение повлечет изменения в списках <b>индикаторов и показателей</b> на вкладке ' +
  '<b>"Результаты проекта"</b>. Элементы результативности, которых нет в новой выбранной программе, будут удалены.\n' +
  'Аналогичные изменения коснутся списка <b>областей знаний</b> на вкладке <b>"Описание проекта"</b>';
const CHANGE_PROGRAM_WARNING_TITLE = 'Изменена программа';

type ClearArguments = {
  isChanged: boolean;
  newProgram: Program.Program | null;
};

type ClearResultElementArguments = ClearArguments;

type ClearDomainKnowledgesArguments = Pick<ClearArguments, 'newProgram'>;

export function useController(args: Arguments) {
  const { setProject, project, indicators, indices, makeChangeHandler } = args;
  const [isChangeProgramWarningOpen, setIsChangeProgramWarningOpen] = useState<boolean>(false);
  const [changeProgramWarningText, setChangeProgramWarningText] = useState<string>('');
  const [changeProgramWarningTitle, setChangeProgramWarningTitle] = useState<string>('');

  const { enumMap } = useAppDataContext();
  const stopMoneyReasonOptions = getEnum('StopMoneyReason');

  const performers = useMemo(() => project?.performers ?? [], [project?.performers]);

  const leader = useMemo(() => {
    const leaderIndexes = project ? getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.LEADER) : [];
    return leaderIndexes.length ? performers[leaderIndexes[0]] : null;
  }, [performers, project]);

  const responsiblePerformer = useMemo(() => {
    const responsiblePerformersIndexes = project
      ? getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.RESPONSIBLE_PERFORMER)
      : [];
    return responsiblePerformersIndexes.length ? performers[responsiblePerformersIndexes[0]] : null;
  }, [performers, project]);

  const getStagesWithClearIndicators = useCallback(
    ({ isChanged, newProgram }: ClearResultElementArguments): Project.Stage[] => {
      if (newProgram) {
        if (isChanged) {
          const nextIndicators = indicators.filter(indicator =>
            newProgram.indicators.some(({ refResultItem: { id } }) => id === indicator.ref?.id),
          );
          const nextIndices = indices.filter(index =>
            newProgram.indices.some(({ refResultItem: { id } }) => id === index.ref?.id),
          );
          const stagesWithUpdatedIndicators = updateStageIndicators(nextIndicators, project?.stages ?? [], 'indicators', 'reset');
          const stagesWithUpdatedIndicatorsAndIndices = updateStageIndicators(
            nextIndices,
            stagesWithUpdatedIndicators,
            'indices',
            'reset',
          );

          return stagesWithUpdatedIndicatorsAndIndices;
        }
      } else {
        const stagesWithResetedIndicators = updateStageIndicators([], project?.stages ?? [], 'indicators', 'reset');
        const stagesWithResetedIndicatorsAndIndices = updateStageIndicators([], stagesWithResetedIndicators, 'indices', 'reset');

        return stagesWithResetedIndicatorsAndIndices;
      }

      return project?.stages ?? [];
    },
    [indicators, indices, project],
  );

  const getClearDomainKnowledges = useCallback(
    ({ newProgram }: ClearDomainKnowledgesArguments): Project.RefElement[] => {
      if (newProgram) {
        const filteredDomainKnowledges = project!.domainKnowledges.filter(domainKnowledge =>
          newProgram.domainKnowledges.some(({ id }) => id === domainKnowledge.id),
        );

        const isDomainKnowledgesChanged = filteredDomainKnowledges.length === project!.domainKnowledges.length;
        if (isDomainKnowledgesChanged) {
          return filteredDomainKnowledges;
        }
      } else {
        return [];
      }

      return project!.domainKnowledges;
    },
    [project],
  );

  const handleChangeProgram = useCallback(
    (program: Program.Program | null) => {
      const prevProgram = project?.program;
      const nextProject = R.clone(project!);
      nextProject.program = program;
      nextProject.programEvent = null;

      if (prevProgram) {
        if (!program) {
          setChangeProgramWarningText(CLEAR_PROGRAM_WARNING_TEXT);
          setChangeProgramWarningTitle(CLEAR_PROGRAM_WARNING_TITLE);
          setIsChangeProgramWarningOpen(true);

          nextProject.stages = getStagesWithClearIndicators({ newProgram: program, isChanged: false });
          nextProject.domainKnowledges = getClearDomainKnowledges({ newProgram: program });
        } else if (program.id !== prevProgram.id) {
          setChangeProgramWarningText(CHANGE_PROGRAM_WARNING_TEXT);
          setChangeProgramWarningTitle(CHANGE_PROGRAM_WARNING_TITLE);
          setIsChangeProgramWarningOpen(true);

          nextProject.stages = getStagesWithClearIndicators({ newProgram: program, isChanged: true });
          nextProject.domainKnowledges = getClearDomainKnowledges({ newProgram: program });
        }
      }

      setProject(nextProject);
    },
    [getClearDomainKnowledges, getStagesWithClearIndicators, project, setProject],
  );

  const getContacts = useCallback(
    (performer: Project.Performer | null): string =>
      [performer?.person?.scientist?.email, performer?.person?.scientist?.phone, performer?.person?.scientist?.mobilePhone]
        .filter(x => x)
        .join(' '),
    [],
  );

  const onCloseChangeProgramWarning = useCallback(() => {
    setIsChangeProgramWarningOpen(false);
  }, []);

  const onFinancingStoppedChange = useCallback(
    (checked: boolean) => {
      const nextIsFinancingStopped = checked;

      makeChangeHandler('stopMoneyDate')('');
      makeChangeHandler('stopMoneyReason')(null);

      makeChangeHandler('isFinancingStopped')(nextIsFinancingStopped);
    },
    [makeChangeHandler],
  );

  return {
    enumMap,
    stopMoneyReasonOptions,
    performers,
    leader,
    responsiblePerformer,
    isChangeProgramWarningOpen,
    changeProgramWarningText,
    changeProgramWarningTitle,
    onFinancingStoppedChange,
    onCloseChangeProgramWarning,
    handleChangeProgram,
    getContacts,
  };
}
