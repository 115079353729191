import React from 'react';

import { Toolbar as SharedToolbar } from 'components';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';
import { useController } from './controller';

type Props = {
  saveForm?(): void;
};

function Toolbar(props: Props) {
  const { saveForm } = props;
  const { buttons, isHelpFormOpen, handleTemplateCloseHelpForm } = useController({ saveForm });
  return (
    <>
      <QnaAddTemplate.Component isOpen={isHelpFormOpen} onClose={handleTemplateCloseHelpForm} />
      <SharedToolbar buttons={buttons} mode="form" />
    </>
  );
}

export { Toolbar };
