import { PublicationType } from 'types/models/Form';

export class PublicationTypes {
  static MAGAZINE: PublicationType = {
    label: 'Журнал',
    code: 'MAGAZINE',
    nominativeCaseLabel: 'журнал',
    genitiveCaseLabel: 'журнала',
    template: 'MagazineForm',
  };

  static MAGAZINE_ARTICLE: PublicationType = {
    label: 'Статья в журнале',
    code: 'MAGAZINE_ARTICLE',
    nominativeCaseLabel: 'статья в журнале',
    genitiveCaseLabel: 'статьи в журнале',
    template: 'PublicationFormArticle',
  };

  static MONOGRAPH: PublicationType = {
    label: 'Монография',
    code: 'MONOGRAPH',
    nominativeCaseLabel: 'монография',
    genitiveCaseLabel: 'монографии',
    template: 'PublicationFormMonography',
  };

  static MONOGRAPH_CHAPTER: PublicationType = {
    label: 'Глава в коллективной монографии',
    code: 'MONOGRAPH_CHAPTER',
    nominativeCaseLabel: 'глава в коллективной монографии',
    genitiveCaseLabel: 'главы в коллективной монографии',
    template: 'PublicationFormMonographyArticle',
  };

  static TEXTBOOK: PublicationType = {
    label: 'Учебник',
    code: 'TEXTBOOK',
    nominativeCaseLabel: 'учебник',
    genitiveCaseLabel: 'учебника',
    template: 'PublicationFormTextbook',
  };

  static TUTORIAL: PublicationType = {
    label: 'Учебное пособие',
    code: 'TUTORIAL',
    nominativeCaseLabel: 'учебное пособие',
    genitiveCaseLabel: 'учебного пособия',
    template: 'PublicationFormTextbook',
  };

  static METHODICAL_EDITION: PublicationType = {
    label: 'Учебно-методическое пособие',
    code: 'METHODICAL_EDITION',
    nominativeCaseLabel: 'учебно-методическое пособие',
    genitiveCaseLabel: 'учебно-методического пособия',
    template: 'PublicationFormTextbook',
  };

  static METHODICAL_EDITION_OTHER: PublicationType = {
    label: 'Другое учебное издание',
    code: 'METHODICAL_EDITION_OTHER',
    nominativeCaseLabel: 'другое учебное издание',
    genitiveCaseLabel: 'другого учебного издания',
    template: 'PublicationFormTextbook',
  };

  static COMPILATION_SCIENTIFIC_WORK: PublicationType = {
    label: 'Сборник научных трудов',
    code: 'COMPILATION_SCIENTIFIC_WORK',
    nominativeCaseLabel: 'сборник научных трудов',
    genitiveCaseLabel: 'сборника научных трудов',
    template: 'CompilationForm',
  };

  static COMPILATION_CONFERENCE: PublicationType = {
    label: 'Сборник материалов конференций, симпозиумов и др.',
    code: 'COMPILATION_CONFERENCE',
    nominativeCaseLabel: 'сборник материалов конференций, симпозиумов и др.',
    genitiveCaseLabel: 'сборника материалов конференций, симпозиумов и др.',
    template: 'CompilationForm',
  };

  static COMPILATION_OTHER: PublicationType = {
    label: 'Справочное издание',
    code: 'COMPILATION_OTHER',
    nominativeCaseLabel: 'справочное издание',
    genitiveCaseLabel: 'справочного издания',
    template: 'CompilationForm',
  };

  static THESIS: PublicationType = {
    label: 'Тезисы',
    code: 'THESIS',
    nominativeCaseLabel: 'тезисы',
    genitiveCaseLabel: 'тезисов',
    template: 'PublicationThesis',
  };

  static ARTICLE_SCIENTIFIC_WORK: PublicationType = {
    label: 'Статья в сборнике научных трудов',
    code: 'ARTICLE_SCIENTIFIC_WORK',
    nominativeCaseLabel: 'статья в сборнике научных трудов',
    genitiveCaseLabel: 'статьи в сборнике научных трудов',
    template: 'CompilationFormArticle',
  };

  static ARTICLE_CONFERENCE: PublicationType = {
    label: 'Статья в сборнике материалов конференций, симпозиумов и др.',
    code: 'ARTICLE_CONFERENCE',
    nominativeCaseLabel: 'статья в сборнике материалов конференций, симпозиумов и др.',
    genitiveCaseLabel: 'статьи в сборнике материалов конференций, симпозиумов и др.',
    template: 'CompilationFormArticle',
  };

  static ARTICLE_OTHER: PublicationType = {
    label: 'Статья в справочном издании',
    code: 'ARTICLE_OTHER',
    nominativeCaseLabel: 'статья в справочном издании',
    genitiveCaseLabel: 'статьи в справочном издании',
    template: 'CompilationFormArticle',
  };

  static DISSERTATION: PublicationType = {
    label: 'Диссертация',
    code: 'DISSERTATION',
    nominativeCaseLabel: 'диссертация',
    genitiveCaseLabel: 'диссертации',
    template: 'PublicationFormDissertation',
  };

  static SYNOPSIS: PublicationType = {
    label: 'Автореферат диссертации',
    code: 'SYNOPSIS',
    nominativeCaseLabel: 'автореферат диссертации',
    genitiveCaseLabel: 'автореферата диссертации',
    template: 'PublicationFormDissertation',
  };

  static OTHER_BOOK: PublicationType = {
    label: 'Другое издание',
    code: 'OTHER_BOOK',
    nominativeCaseLabel: 'другое издание',
    genitiveCaseLabel: 'другого издания',
    template: 'PublicationFormOther',
  };

  static OTHER_PART: PublicationType = {
    label: 'Статья в другом издании',
    code: 'OTHER_PART',
    nominativeCaseLabel: 'статья в другом издании',
    genitiveCaseLabel: 'статьи в другом издании',
    template: 'PublicationFormOtherArticle',
  };

  static DEPOSITED: PublicationType = {
    label: 'Депонированная научная работа',
    code: 'DEPOSITED',
    nominativeCaseLabel: 'депонированная научная работа',
    genitiveCaseLabel: 'депонированной научной работы',
  };

  static COMPILATION_ARTICLES: PublicationType = {
    label: 'Сборник статей',
    code: 'COMPILATION_ARTICLES',
    nominativeCaseLabel: 'сборник статей',
    genitiveCaseLabel: 'сборника статей',
  };

  static ARTICLE_ARTICLES: PublicationType = {
    label: 'Статья в сборнике статей',
    code: 'ARTICLE_ARTICLES',
    nominativeCaseLabel: 'статья в сборнике статей',
    genitiveCaseLabel: 'статьи в сборнике статей',
  };

  static PREPRINT_ARTICLE: PublicationType = {
    label: 'Препринт',
    code: 'PREPRINT_ARTICLE',
    nominativeCaseLabel: 'препринт',
    genitiveCaseLabel: 'препринта',
    template: 'PreprintFormArticle',
  };
}
