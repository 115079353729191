import React, { useCallback, useState } from 'react';

import { DataGrid } from 'features/Table';
import { GetPersonalCommunityMemberList, GetPersonalRecognitionList } from 'features/Table/specifications';

import { Tabs, Tab } from 'components';
import { GetPersonalDissertationCouncilMemberList } from 'features/Table/specifications/GetPersonalDissertationCouncilMemberList';
import { CommunityType } from 'utils/Enums';

export function Recognitions() {
  const [recognitionsFilter, setRecognitionsFilter] = useState<string>('mine');
  const [communitiesFilter, setCommunitiesFilter] = useState<string>('mine');

  const flushFilters = useCallback(() => {
    setRecognitionsFilter('mine');
    setCommunitiesFilter('mine');
  }, []);

  return (
    <Tabs onTabClick={flushFilters}>
      <Tab title="Признания и награды">
        <DataGrid specification={GetPersonalRecognitionList({ recognitionsFilter, setRecognitionsFilter })} hasPersonalMode />
      </Tab>
      <Tab title="Работа в составе диссертационных советов">
        <DataGrid
          specification={GetPersonalDissertationCouncilMemberList({ communitiesFilter, setCommunitiesFilter })}
          hasPersonalMode
        />
      </Tab>
      <Tab title="Членство в научных сообществах и организациях">
        <DataGrid
          specification={GetPersonalCommunityMemberList({
            communitiesFilter,
            setCommunitiesFilter,
            types: [
              CommunityType.SCIENTIFIC_COUNCIL,
              CommunityType.COLLABORATION,
              CommunityType.FUND,
              CommunityType.MAGAZINE_REDACTION,
            ],
          })}
          hasPersonalMode
        />
      </Tab>
    </Tabs>
  );
}
