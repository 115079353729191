import React from 'react';
import { block } from 'bem-cn';

import { ConfirmPopup, Toolbar } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { Table } from 'types/models';
import { ApproveEvent } from 'features/Form/looks/event/views/ApproveEvent';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { EventTypes } from 'utils/Enums/EventTypes';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import ExpositionParticipationTemplate from './modalTemplates/ExpositionParticipationTemplate';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants/TextConstants';

type Props = {
  tableState: Table.State;
};

const b = block('toolbar');
function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isApprovePopupOpen,
    handleCloseApprovePopup,
    handleContinueStep,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    controllerIsForm,
    sourceEvent,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    publicationMessageType,
    updatePublicationMessage,
    setPublicationMessageType,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    buttons,
    settings,
  } = useController({ tableState });
  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={EventTypes.EVENT_EXPOSITION.code}
          />
        </div>
      )}
      {isViewFormOpen && (
        <modalTemplates.ExpositionViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.ExpositionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.ExpositionAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранное мероприятие? (${tableState.selectedRows[0]?.EventName})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isApprovePopupOpen && (
        <ApproveEvent
          typeCode={EventTypes.EVENT_EXPOSITION.code}
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isApprovePopupOpen}
          onClose={handleCloseApprovePopup}
        />
      )}
      {isCancelMessagePopupOpen && (
        <AdministratorMessagePopup
          title={ADD_PUB_FEEDBACK_LABEL}
          isOpen={isCancelMessagePopupOpen}
          publicationMessage={publicationMessage}
          updatePublicationMessage={updatePublicationMessage}
          publicationMessageType={publicationMessageType}
          setPublicationMessageType={setPublicationMessageType}
          onConfirm={onCancelMessageConfirmClick}
          onConfirmWithoutMessage={onCancelMessageConfirmWithoutMessageClick}
        />
      )}
      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          exhibitionEventId={tableState.selectedRows[0]?.id || ''}
          department={settings?.userDepartment}
        />
      )}
      <ExpositionParticipationTemplate controllerIsForm={controllerIsForm} sourceEvent={sourceEvent} />

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
