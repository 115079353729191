import { Quarters } from 'utils/Enums/Quarters';
import { Months } from 'utils/Enums/Months';

export const QUARTER_PERIOD_RADIO = 'quarter';
export const MONTH_PERIOD_RADIO = 'month';

export const MONTH_LIST = [
  Months.JANUARY,
  Months.FEBRUARY,
  Months.MARCH,
  Months.APRIL,
  Months.MAY,
  Months.JUNE,
  Months.JULY,
  Months.AUGUST,
  Months.SEPTEMBER,
  Months.OCTOBER,
  Months.NOVEMBER,
  Months.DECEMBER,
];

export const QUARTER_LIST = [Quarters.FIRST_QUARTER, Quarters.SECOND_QUARTER, Quarters.THIRD_QUARTER, Quarters.FOURTH_QUARTER];
