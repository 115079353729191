import React, { useMemo } from 'react';

import { IconButtonProps, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isViewButtonDisabled,
    isHandleErrorButtonDisabled,
    handleViewButtonClick,
    handleHandleErrorButtonClick,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isHandleErrorFormOpen,
    handleTemplateCloseHandleErrorForm,
  } = useController({ tableState });
  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isViewButtonDisabled,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.arrowLoop,
        title: 'Обработать ошибку интеграции с НБ',
        code: 'handle-error',
        isDisabled: isHandleErrorButtonDisabled,
        onClick: handleHandleErrorButtonClick,
      },
    ],
    [isViewButtonDisabled, isHandleErrorButtonDisabled, handleViewButtonClick, handleHandleErrorButtonClick],
  );

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.PublicationId}
          relatedTableState={tableState}
        />
      )}
      {!isHandleErrorButtonDisabled && (
        <modalTemplates.HandleErrorTemplate.Component
          isOpen={isHandleErrorFormOpen}
          onClose={handleTemplateCloseHandleErrorForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
