import React, { useEffect, useMemo, useState } from 'react';
import { setup } from 'bem-cn';

import { buttonIcons, FormComponent, IconButtonProps, Toolbar } from 'components';

import { InputAttributes } from 'types/models/common';
import MaskedInput from 'react-text-mask';

import { NumberMask, NumberProps } from './masks/NumberMask';
import { YearMask, YearProps } from './masks/YearMask';
import { IssnMask } from './masks/IssnMask';
import { IsbnMask } from './masks/IsbnMask';
import { SnilsMask } from './masks/SnilsMask';

import { showNotification } from 'features/Notifications';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const w = block('text-input-wrapper');
const b = block('text-input');

const maxSymbols = 4096;

type TextProps = {
  /** @property {number} type - (optional) Max length of string */
  maxLength?: number;
};

export enum TextInputMode {
  /** Any text */
  text = 'text',
  /** Any password */
  password = 'password',
  /** Any number (int, float) */
  number = 'number',
  /** Day only */
  day = 'day',
  /** Month only */
  month = 'month',
  /** Year only */
  year = 'year',
  /** Site URL */
  url = 'url',
  /** ISSN */
  issn = 'issn',
  /** ISBN */
  isbn = 'isbn',
  /** DOI */
  doi = 'doi',
  /** SNILS */
  snils = 'snils',
}

/**
 * Settings for input component
 */
export type Props = InputAttributes & {
  /** @property {TextInputMode} type - (optional) Type of Mask (TextInputMode.text by default) */
  mode?: TextInputMode;
  /** @property {TextProps | NumberProps | YearProps} settings - (optional) Settings for type TextInputMode */
  settings?: any;
  /** @property {string} value - (optional) Value for input */
  value?: string;
  /** @property {function} onChange - (optional) Callback on text changed */
  onChange?(val: string): void;
  /** @property {boolean} isDisabled - (optional) Set is disabled input (false by default) */
  isDisabled?: boolean;
  /** @property {boolean} isError - (optional) Set is error input (false by default) */
  isError?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
} & (
    | {
        mode?: TextInputMode.text;
        /** @property {TextProps} settings - (optional) Settings for text */
        settings?: TextProps;
      }
    | {
        mode: TextInputMode.number;
        /** @property {TextProps} settings - (optional) Settings for number */
        settings?: NumberProps;
      }
    | {
        mode: TextInputMode.year;
        /** @property {TextProps} settings - (optional) Settings for year */
        settings?: YearProps;
      }
    | {
        mode:
          | TextInputMode.password
          | TextInputMode.day
          | TextInputMode.month
          | TextInputMode.url
          | TextInputMode.issn
          | TextInputMode.isbn
          | TextInputMode.doi
          | TextInputMode.snils;
      }
  );

/** Examples
<TextInput
  settings={{ maxLength: 40 }}
  value={formFields.requestNumber.value}
  onChange={formFields.requestNumber.onChange}
  isDisabled={disabled}
/>
<TextInput mode={TextInputMode.year} settings={{ min: 1950, max: 2050 }} onChange={refs.setYear} value={refs.year} />
<TextInput
  mode={TextInputMode.number}
  settings={{
    decimal: isIntegerFilter
      ? null
      : {
          limit: columnPrecision,
        },
    isThousands: isMoneyFilter,
  }}
  onChange={onFromInputChange}
  value={inputValueFrom}
/>
 */

/**
 * Text input component
 *
 * @param {TextInputMode} mode - Typeof component (See TextInputMode enum)
 * @param {TextProps | NumberProps | YearProps} settings - (optional) Settings for type TextInputMode
 * @param {string} value - (optional) Value for input
 * @param {function} onChange - (optional) Callback on text changed
 * @param {boolean} isDisabled - (optional) Set is disabled input (false by default)
 * @param {boolean} isError - (optional) Set is error input (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const TextInput = React.memo((props: Props) => {
  const {
    mode = TextInputMode.text,
    settings = {},
    onClick,
    onFocus,
    onChange,
    isDisabled = false,
    isError = false,
    classMixin,
    value,
    ...restProps
  } = props;

  const [valueState, setValueState] = useState<string>(value || '');

  useEffect(() => {
    setValueState(value || '');
  }, [value]);

  const className = useMemo(
    () => b({ 'is-disabled': isDisabled, 'is-error': isError, 'is-clickabled': !!onClick }).mix(classMixin),
    [classMixin, isDisabled, isError, onClick],
  );

  const calculatedProps = {
    readOnly: isDisabled,
    disabled: isDisabled && !onClick,
    onClick: onClick,
  };

  const getValue = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => e.target.value;

  // TextInputMode.text | TextInputMode.doi
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val: string = getValue(e);

    if (onChange) {
      // TextInputMode.doi
      if (mode === TextInputMode.doi) {
        const DOI_START_SEQUENCE = '10.';
        const DOI_MAX_LENGTH = 100;

        if (!!val && val.length >= 3 && val.indexOf(DOI_START_SEQUENCE) !== 0) {
          showNotification({
            message: `DOI должен начинаться с "${DOI_START_SEQUENCE}"`,
            theme: 'danger',
          });
          return;
        }

        if (val.length > DOI_MAX_LENGTH) {
          showNotification({
            message: 'Максимальная длина DOI 100 символов',
            theme: 'danger',
          });
          onChange(val.slice(0, DOI_MAX_LENGTH));
          return;
        }
      }

      const { maxLength = maxSymbols } = settings as TextProps;
      if (maxLength && val.length > maxLength) {
        showNotification({
          message: `Превышено максимально количество символов (${maxLength}), сократите строку`,
          theme: 'danger',
        });
        onChange(val.slice(0, maxLength));
        return;
      }
      onChange(val);
    }
    setValueState(val);
  };

  // Remove value if ['0', '0.0', '0.00', ao]
  const onFocusIfIsEmpty = (e: React.FocusEvent<HTMLInputElement>) => {
    const val: string = getValue(e);

    if (Number(val) === parseFloat(val) && !parseFloat(val) && onChange) {
      setValueState('');
      onChange('');
    }
    if (onFocus) {
      onFocus(e);
    }
  };

  // TextInputMode.number
  if (mode === TextInputMode.number) {
    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let val: string = getValue(e);

      val = val.replaceAll(',', '.').replaceAll(' ', '');

      if (onChange && (!!val ? !isNaN(Number(val)) : true)) {
        onChange(!!val ? Number(val).toString() : val);
      }
      setValueState(val);
    };

    return (
      <MaskedInput
        className={className}
        onChange={handleNumberChange}
        onFocus={onFocusIfIsEmpty}
        value={valueState}
        {...NumberMask(settings as NumberProps)}
        {...calculatedProps}
        {...(restProps as InputAttributes)}
      />
    );
  }

  // TextInputMode.day | TextInputMode.month
  if (mode === TextInputMode.day || mode === TextInputMode.month) {
    const handleDayMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val: string = getValue(e);

      if (onChange && (!!val ? Number(val).toString() === val : true) && (val.length === 0 || val.length === 2)) {
        onChange(val);
      }
      setValueState(val);
    };

    const settingsProps = (mode === TextInputMode.day
      ? {
          max: 31,
          isThousands: false,
        }
      : {
          max: 12,
          isThousands: false,
        }) as NumberProps;

    return (
      <MaskedInput
        className={className}
        onChange={handleDayMonthChange}
        onFocus={onFocusIfIsEmpty}
        value={valueState}
        {...NumberMask(settingsProps)}
        {...calculatedProps}
        {...(restProps as InputAttributes)}
      />
    );
  }

  // TextInputMode.year
  if (mode === TextInputMode.year) {
    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val: string = getValue(e);

      if (onChange && (!!val ? Number(val).toString() === val : true) && (val.length === 0 || val.length === 4)) {
        onChange(val);
      }
      setValueState(val);
    };

    return (
      <MaskedInput
        className={className}
        onChange={handleYearChange}
        onFocus={onFocusIfIsEmpty}
        value={valueState}
        {...YearMask(settings as YearProps)}
        {...calculatedProps}
        {...(restProps as InputAttributes)}
      />
    );
  }

  // TextInputMode.issn | TextInputMode.isbn | TextInputMode.snils
  const modeMask = {
    [TextInputMode.issn]: IssnMask,
    [TextInputMode.isbn]: IsbnMask,
    [TextInputMode.snils]: SnilsMask,
  };

  if (Object.keys(modeMask).includes(mode)) {
    return (
      <MaskedInput
        className={className}
        onChange={handleChange}
        value={valueState}
        {...Object.values(modeMask)[Object.keys(modeMask).indexOf(mode)]()}
        {...calculatedProps}
        {...(restProps as InputAttributes)}
      />
    );
  }

  const input = (
    <input
      type={mode === TextInputMode.password ? TextInputMode.password : TextInputMode.text}
      className={className}
      onChange={handleChange}
      onFocus={onFocusIfIsEmpty}
      title={mode === TextInputMode.password ? '' : valueState}
      value={valueState}
      {...calculatedProps}
      {...(restProps as InputAttributes)}
    />
  );

  // TextInputMode.url
  if (mode === TextInputMode.url) {
    if (isDisabled) {
      return <FormComponent.Link href={valueState} />;
    }

    const buttons: IconButtonProps[] = [
      {
        icons: buttonIcons.loop,
        title: 'Перейти',
        isDisabled: !valueState.length,
        onClick: () => {
          window.open(valueState, '_blank');
        },
      },
    ];

    return (
      <div className={w({}).mix(classMixin)}>
        {input}
        <Toolbar buttons={buttons} />
      </div>
    );
  }

  return input;
});
