import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    handleCloseApproveConfirmPopup,
    isDepartmentsButtonDisabled,
    isDepartmentsFormOpen,
    handleTemplateCloseDepartmentsForm,
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController(tableState);
  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.RecognitionViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.RecognitionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <modalTemplates.RecognitionAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную награду? (${tableState.selectedRows[0].award})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {!isApproveButtonDisabled && (
        <modalTemplates.ApproveConfirmPopup.Component
          isOpen={isApproveConfirmPopupOpen}
          onClose={handleCloseApproveConfirmPopup}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isDepartmentsButtonDisabled && (
        <modalTemplates.RecognitionDepartmentsTemplate.Component
          isOpen={isDepartmentsFormOpen}
          onClose={handleTemplateCloseDepartmentsForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      <ConfirmPopup
        title="Помощь в разделе Признания и награды"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">Раздел Признания и награды </strong>предназначен для ввода и хранения информации о
            персональных наградах или признаниях (награды министерств и ведомств, правительственные награды, областной и городской
            администрации, университета и пр.).
          </p>
        </div>
      </ConfirmPopup>
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        scientistId={tableState.selectedRows[0]?.scientistId || ''}
      />
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
