import { useState, useLayoutEffect, useCallback } from 'react';

import { showNotification } from 'features/Notifications';
import { Form, Table as T } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { validate } from './validate';
import { ContestRequestKindConsumption } from 'types/models/KindConsumption';
import * as R from 'ramda';
import { ValueOf } from 'types/helpers';
import { Original } from 'types/models/Form';
import { GetKindConsumptionList } from 'features/Table/specifications/GetKindConsumptionSelectList';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const [requestKindConsumption, setRequestKindConsumption] = useState<ContestRequestKindConsumption>();

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Наименование',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    position: {
      value: '',
      isValid: true,
      required: true,
      title: 'Позиция',
      tooltipText:
        'Порядковый номер позиции в смете на форме заявки. Статьи размещаются в смете в порядке возрастания значений "Позиция"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          position: { ...prevState.position, value },
        }));
      },
    },
    isActive: {
      value: '',
      isValid: true,
      required: false,
      title: 'Активный',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isActive: { ...prevState.isActive, value: !prevState.isActive.value },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      required: false,
      title: 'Описание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
    },
    kindConsumption: {
      value: '',
      isValid: true,
      required: false,
      title: 'Виды расходов из бухгалтерии',
      tooltipText:
        // eslint-disable-next-line max-len
        'Используется при формировании консолидированной сметы затрат по всем заявкам на год; важно, если данные по смете передаются в бухгалтерию',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          kindConsumption: { ...prevState.kindConsumption, value },
        }));
      },
    },
  });

  const { methods: getContestRequestKindConsumption } = BackendAPI.useBackendAPI('GetContestRequestKindConsumption', {
    onSuccessfullCall: ({ data }) => {
      setRequestKindConsumption(data);
      const getValueLens = (fieldName: string) => R.lensPath([fieldName, 'value']);
      setFormFields(prevFields =>
        (R.pipe as any)(
          R.set(getValueLens('name'), data.name),
          R.set(getValueLens('description'), data.description),
          R.set(getValueLens('position'), data.position),
          R.set(getValueLens('isActive'), data.isActive),
          R.set(getValueLens('kindConsumption'), data.kindConsumption),
        )(prevFields),
      );
    },
  });
  const { methods: saveRequestKindConsumption } = BackendAPI.useBackendAPI('SaveContestRequestKindConsumption', {
    onSuccessfullCall: () => {
      tableStreams.reloadTable.push();
    },
  });

  const onSubmit = useCallback((): boolean => {
    const errors: { isValid: boolean; invalidMessage: string }[] = validate({
      formFields,
      requestKindConsumption,
    });
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        showNotification({ message: error.invalidMessage, theme: 'danger' });
      });
      return false;
    }

    saveRequestKindConsumption.callAPI({
      id: id || requestKindConsumption?.id || null,
      name: formFields.name.value,
      position: formFields.position.value,
      isActive: formFields.isActive.value,
      description: formFields.description.value,
      kindConsumption: formFields.kindConsumption.value,
    });
    return true;
  }, [saveRequestKindConsumption, id, formFields, requestKindConsumption]);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const makeChangeHandler = useCallback(
    (key: keyof ContestRequestKindConsumption) => (value: ValueOf<ContestRequestKindConsumption>) => {
      if (requestKindConsumption) {
        setRequestKindConsumption({ ...requestKindConsumption, [key]: value });
      }
    },
    [requestKindConsumption, setRequestKindConsumption],
  );

  const modalTableRowKindConsumptionConverter = useCallback<(row: T.Entry) => Original>(row => {
    return {
      id: row.id,
      name: row.name,
    };
  }, []);

  const kindConsumptionTableConfig = GetKindConsumptionList({ hasSelectButton: true });

  const incrimentKindConsumptionsTooltipTxt =
    // eslint-disable-next-line max-len
    'Если статья является консолидирующей по группе статей, укажите все статьи расходов, которые должны учитываться для расчёта суммы';

  useLayoutEffect(() => {
    if (id) getContestRequestKindConsumption.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    id,
    viewMode,
    editMode,
    handleFormSubmit,
    formFields,
    requestKindConsumption,
    makeChangeHandler,
    modalTableRowKindConsumptionConverter,
    kindConsumptionTableConfig,
    incrimentKindConsumptionsTooltipTxt,
  };
}
