import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Rid } from 'types/models';
import { Project } from 'types/models/Project';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  rntd?: Rid | null;
};

export function useController(props: Props) {
  const { onSave, rntd } = props;

  const [isSaveConfirmPopupOpen, setIsSaveConfirmPopupOpen] = useState<boolean>(false);
  const [isSaveAndClose, setIsSaveAndClose] = useState<boolean>(false);
  const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  const onSaveConfirmPopupConfirm = useCallback(() => {
    if (isSaveAndClose) {
      onSave(true);
    }
    setIsSaveConfirmPopupOpen(false);
  }, [isSaveAndClose, onSave]);

  const isProjectInfoButtonDisabled = !rntd?.project?.id;

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');

  const handleProjectInfoButtonClick = useCallback(() => {
    const projectId = rntd?.project?.id;
    if (projectId) {
      getProjectAPI.callAPI(
        { id: projectId },
        {
          onSuccessfullCall: ({ data }) => {
            setProject(data);
            setIsProjectInfoModalOpen(true);
          },
        },
      );
    }
  }, [rntd, getProjectAPI]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        onClick: () => {
          onSave(true);
        },
      },
      {
        icons: buttonIcons.info,
        title: 'Краткая информация о проекте, в рамках которого получен РИД',
        isDisabled: isProjectInfoButtonDisabled,
        onClick: handleProjectInfoButtonClick,
      },
    ],
    [onSave, isProjectInfoButtonDisabled, handleProjectInfoButtonClick],
  );
  return {
    buttons,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
    setIsSaveAndClose,
    rntd,
    project,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
  };
}
