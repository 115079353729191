import { Form } from 'types/models';
import { AuthorPayment } from 'types/models/Payment';

export function validate(p: AuthorPayment | null, formFields: Form.Fields) {
  const invalid = [];
  if (parseFloat(formFields.maxFundMoney.value) <= 0) {
    invalid.push({
      isValid: false,
      invalidMessage: 'Сумма в фонд должна быть больше нуля',
    });
  }
  if (parseFloat(formFields.maxNomineesMoney.value) <= 0) {
    invalid.push({
      isValid: false,
      invalidMessage: 'Сумма в номинантам должна быть больше нуля',
    });
  }
  const required = Object.keys(formFields)
    .filter((fieldName: string) => formFields[fieldName].required && !formFields[fieldName]?.value)
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  return [...required, ...invalid];
}
