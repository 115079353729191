import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const HandleErrorTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetPublicationLibraryLog',
    template: 'HandleErrorForm',
    id: String(id),
    relatedTableState,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Обработка ошибки интеграции с НБ" size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(HandleErrorTemplate);
