import React from 'react';

import { Toolbar as SharedToolbar, ConfirmPopup } from 'components';

import { Rid } from 'types/models';
import { ProjectInfoModal } from 'features/ProjectInfoModal';
import { useController } from './controller';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  rntd?: Rid | null;
};

function Toolbar(props: Props) {
  const { onSave, rntd } = props;

  const {
    buttons,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
    project,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
  } = useController({
    onSave,
    rntd,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />
      <ProjectInfoModal
        isOpen={isProjectInfoModalOpen}
        onClose={() => setIsProjectInfoModalOpen(false)}
        project={project ?? null}
      />
      <ConfirmPopup
        onClose={() => setIsSaveConfirmPopupOpen(false)}
        isOpen={isSaveConfirmPopupOpen}
        icon="warning"
        title="Предупреждение"
        // eslint-disable-next-line max-len
        text="Дата снятия патента с баланса равна дате подачи заявки. Продолжить сохранение?"
        onConfirm={onSaveConfirmPopupConfirm}
        okButtonText="Да"
        resetButtonText="Отмена"
      />
    </>
  );
}

export { Toolbar };
