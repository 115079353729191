import { EventTypes } from 'utils/Enums/EventTypes';
import { EventNameMap } from './types';

export const eventNameMap: EventNameMap = {
  CONFERENCE: {
    nominative: EventTypes.EVENT_CONFERENCE.nominativeCaseLabel,
    genitive: EventTypes.EVENT_CONFERENCE.genitiveCaseLabel,
    upperCaseNominative: EventTypes.EVENT_CONFERENCE.label,
    labelPlural: EventTypes.EVENT_CONFERENCE.labelPlural,
  },
  CONCERT: {
    nominative: EventTypes.EVENT_CONCERT.nominativeCaseLabel,
    genitive: EventTypes.EVENT_CONCERT.genitiveCaseLabel,
    upperCaseNominative: EventTypes.EVENT_CONCERT.label,
    labelPlural: EventTypes.EVENT_CONCERT.labelPlural,
  },
  EXPOSITION: {
    nominative: EventTypes.EVENT_EXPOSITION.nominativeCaseLabel,
    genitive: EventTypes.EVENT_EXPOSITION.labelDeclined,
    upperCaseNominative: EventTypes.EVENT_EXPOSITION.label,
    labelPlural: EventTypes.EVENT_EXPOSITION.labelPlural,
  },
  CONTEST: {
    nominative: EventTypes.EVENT_CONTEST.nominativeCaseLabel,
    genitive: EventTypes.EVENT_CONTEST.labelDeclined,
    upperCaseNominative: EventTypes.EVENT_CONTEST_SINGLE_LABEL,
    labelPlural: EventTypes.EVENT_CONTEST.labelPlural,
  },
};
