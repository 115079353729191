import React, { useCallback } from 'react';

import { ListEdit } from 'components';

import { Event } from 'types/models';
import { formatEnterprise } from './helpers';
import { EnterprisesComponentForm } from './EnterprisesComponentForm';
import { showNotification } from 'features/Notifications';

type Props = {
  enterprises: Event.Enterprise[];
  setEnterprises: (docs: Event.Enterprise[]) => void;
  disabled?: boolean;
  isMiniRows?: boolean;
};

const EnterprisesComponent = ({ enterprises, setEnterprises, disabled, isMiniRows = true }: Props) => {
  const documentHandler = useCallback((value: Event.Enterprise[]) => {
    setEnterprises(value);
    // eslint-disable-next-line
  }, []);

  const throwErrorNotification = useCallback((message: string) => {
    showNotification({ message, theme: 'danger' });
  }, []);

  const isValidFields = (row: Event.Enterprise | null) => {
    if (row) {
      const isFormValid = row.organization.id && row.role;
      if (isFormValid) {
        return true;
      }
    }
    throwErrorNotification('Заполните обязательные поля');
    return false;
    // eslint-disable-next-line
  }
  return (
    <>
      <ListEdit
        isToolbarDisabled={disabled}
        maxHeight="400px"
        defaultRowsCount={isMiniRows ? 3 : 8}
        rows={enterprises}
        isRequired
        withoutHead
        isCanMovingRows
        title="Внешние организации: организаторы и участники мероприятия"
        onChange={documentHandler}
        columns={[{ label: '', formatValue: row => formatEnterprise(row) }]}
        specification={{
          mode: 'customComponent',
          validation: {
            checkIsValid: row => isValidFields(row),
          },
          renderComponent: (row, onChange) => <EnterprisesComponentForm document={row} onChange={onChange as any} />,
        }}
        withMessages
        isDeleteConfirmEnabled
      />
    </>
  );
};

export { EnterprisesComponent };
