import React from 'react';
import { Tab, Tabs } from 'components';
import { MyDocuments } from './MyDocuments/MyDocuments';
import { UniversityDocuments } from './UniversityDocuments/UniversityDocuments';
import { MyRequests } from './MyRequests/MyRequests';
import { ForeignDocuments } from './ForeignDocuments/ForeignDocuments';

type Props = {
  personId: string | null;
};

export function SecurityDocuments(props: Props) {
  const { personId } = props;

  if (!personId) {
    return null;
  }

  return (
    <Tabs>
      <Tab title="Мои заявки на ОИС">
        <MyRequests />
      </Tab>
      <Tab title="Мои ОИС">
        <MyDocuments />
      </Tab>
      <Tab title="ОИС Университета">
        <UniversityDocuments />
      </Tab>
      <Tab title="ОИС не аффилированные с Университетом">
        <ForeignDocuments />
      </Tab>
    </Tabs>
  );
}
