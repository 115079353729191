import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { ParticipationEventSource } from 'types/models/Participation';
import { Participations } from 'types/models/Event';
import { Report, Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { buttonIcons, IconButtonProps, ReferenceItem } from 'components';
import { Permits } from 'utils/Permissions';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { TypeControllerIsForm } from './modalTemplates/ConcertParticipationTemplate/models/typeControllerIsForm';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';
import { isHasPermission } from 'features/AppData';

type Props = {
  tableState: Table.State;
};
export function useController({ tableState }: Props) {
  const { userPermission } = useAppDataContext();
  const suchLikeEventStreams = useLocalSuchLikeEventStreams();

  const openDuplicatesForm = useCallback(() => {
    suchLikeEventStreams.openSuchLikeEvents.push();
  }, [suchLikeEventStreams.openSuchLikeEvents]);
  const tableStreams = useLocalTableStreams();
  const [selectedRow] = tableState.selectedRows;
  const selectedRowsLength = tableState.selectedRows.length;

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);

  const [sourceEvent, setSourceEvent] = useState<ParticipationEventSource | null>(null);
  const [isAddParticipationFormOpen, setIsAddParticipationFormOpen] = useState<boolean>(false);
  const [isApproveParticipationModal, setIsApproveParticipationModal] = useState<boolean>(false);
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [isEditParticipationFormOpen, setIsEditParticipationFormOpen] = useState<boolean>(false);
  const [isEditSubdivisionModal, setIsEditSubdivisionModal] = useState<boolean>(false);
  const [isShowAwards, setIsShowAwards] = useState<boolean>(false);
  const [isViewParticipationFormOpen, setIsViewParticipationFormOpen] = useState<boolean>(false);
  const [participation, setParticipation] = useState<Participations | null>(null);

  const [isCancelMessagePopupOpen, setIsCancelMessagePopupOpen] = useState<boolean>(false);
  const [publicationMessage, setPublicationMessage] = useState<string>('');
  const [publicationMessageType, setPublicationMessageType] = useState<ReferenceItem>({ id: '', label: '' });

  const { settings } = useAppDataContext();

  const controllerIsForm: TypeControllerIsForm = {
    sourceEvent,
    isAdd: isAddParticipationFormOpen,
    isApproveParticipationModal,
    isDelete: isDeletePopup,
    isEdit: isEditParticipationFormOpen,
    isEditSubdivisionModal,
    isShowAwards,
    isView: isViewParticipationFormOpen,
    participation,
    setAdd: setIsAddParticipationFormOpen,
    setDelete: setIsDeletePopup,
    setEdit: setIsEditParticipationFormOpen,
    setSourceEvent,
    setIsApproveParticipationModal,
    setIsEditSubdivisionModal,
    setIsShowAwards,
    setParticipation,
    setView: setIsViewParticipationFormOpen,
  };

  const isDeletePermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits[`CONCERT_DELETE_${selectedRow?.['id:RecordStatus']}` as keyof Permits]);
  }, [selectedRow, userPermission]);

  const handleCloseApprovePopup = useCallback(() => {
    setIsCancelMessagePopupOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Event',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleApproveButtonClick = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const reports = useMemo<Report[]>(
    () => [Reports.DepartmentConcertParticipation, Reports.DepartmentConcertConsolidatedList, Reports.DepartmentConcert],
    [],
  );

  const { getReports, handleSetCurrentReport, isReportOpen, onReportClose, currentReport } = useReportsHook({ reports });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: !selectedRow,
        permissionName: Permits.CONCERT_VIEW,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        code: 'add',
        permissionName: Permits.CONCERT_ADD,
        isDisabled: false,
        onClick: handleAddButtonClick,
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        code: 'edit',
        permissionName: Permits.CONCERT_EDIT_ADDED,
        isDisabled: !selectedRow,
        onClick: handleEditButtonClick,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        code: 'remove',
        permissionName: [Permits.CONCERT_DELETE_ADDED, Permits.CONCERT_DELETE_APPROVED],
        isDisabled: !selectedRow || !isDeletePermitted,
        onClick: handleDeleteButtonClick,
      },
      {
        icons: buttonIcons.approve,
        title: 'Процедура утверждения',
        code: 'approve',
        permissionName: Permits.CONCERT_APPROVE,
        isDisabled: !selectedRow,
        onClick: handleApproveButtonClick,
      },
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      getReports,
      handleAddButtonClick,
      handleApproveButtonClick,
      handleDeleteButtonClick,
      handleEditButtonClick,
      handleSetCurrentReport,
      handleViewButtonClick,
      isDeletePermitted,
      selectedRow,
    ],
  );

  const updatePublicationMessage = useCallback(
    (nextPublicationMessage: string) => {
      setPublicationMessage(nextPublicationMessage);
    },
    [setPublicationMessage],
  );

  const { methods: addNewMessage } = BackendAPI.useBackendAPI('SaveEventUserFeedline');

  const onCloseMessageConfirm = useCallback(() => {
    setIsCancelMessagePopupOpen(false);
    setIsApprovePopupOpen(false);
    tableStreams.reloadTable.push();
  }, [tableStreams]);

  const onCancelMessageConfirmClick = useCallback(() => {
    addNewMessage.callAPI(
      {
        message: publicationMessage,
        messageId: '',
        labelId: publicationMessageType?.id,
        eventId: tableState.selectedRows[0]?.id,
      },
      {
        onSuccessfullCall: () => {
          onCloseMessageConfirm();
        },
      },
    );
  }, [tableState, addNewMessage, publicationMessageType, onCloseMessageConfirm, publicationMessage]);

  const onCancelMessageConfirmWithoutMessageClick = useCallback(() => {
    onCloseMessageConfirm();
  }, [onCloseMessageConfirm]);

  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  return {
    isApprovePopupOpen,
    handleCloseApprovePopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    controllerIsForm,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    nextPublicationName,
    handleTemplateCloseAddForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    sourceEvent,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    setPublicationMessage,
    publicationMessageType,
    setPublicationMessageType,
    updatePublicationMessage,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    settings,
  };
}
