import * as R from 'ramda';

import { Project } from 'types/models';

export function validate(
  financing: Project.PartnerFinancing | null,
  list: Project.PartnerFinancing[],
  mode: 'view' | 'edit' | 'add' | null,
) {
  if (!financing) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidYear = Boolean(financing.year);
  const isValidAmount = Boolean(financing.amount);

  const financings = mode === 'edit' ? R.reject(x => Boolean((x as any)?.year === financing.year), list) : list;

  const isDublicateYear = financings.find(x => x.year === financing.year);
  const isValidDublicateYear = !isDublicateYear;

  return [
    { isValid: isValidYear, invalidMessage: 'Не указан год' },
    { isValid: isValidAmount, invalidMessage: 'Не введена сумма' },
    { isValid: isValidDublicateYear, invalidMessage: 'Данная запись уже существует в списке' },
  ];
}
