import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { format } from 'date-fns';
import { useAppDataContext } from 'features/AppData/context';
import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { formatDateTimeStr } from 'utils/Constants/FormatStr';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { EditableHonor, Honor } from 'features/Form/looks/person/model';
import { getHonorLinkedHistoryType } from './helpers';

type Props = {
  person: Person.ScientistPerson | null;
  onChangeHonors(honors: Honor[]): void;
};

const useController = ({ person, onChangeHonors }: Props) => {
  const { currentPerson, userPermission } = useAppDataContext();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isDeleteHistoryWarningOpen, setIsDeleteHistoryWarningOpen] = useState<boolean>(false);
  const [isEntityRelationOpen, setIsEntityRelationOpen] = useState<boolean>(false);
  const [degreeIds, setDegreeIds] = useState<string[]>([]);
  const [selectedHonors, setSelectedHonors] = useState<EditableHonor[]>([]);
  const [isMergeDuplicatesOpen, setIsMergeDuplicatesOpen] = useState<boolean>(false);

  const handleSelectedRows = useCallback((honors: EditableHonor[]) => {
    setSelectedHonors(honors);
  }, []);

  const handleCloseMergeDuplicatesForm = useCallback(() => {
    setIsMergeDuplicatesOpen(false);
  }, []);

  const isMergeDisabled = useCallback(() => {
    return selectedHonors.length !== 2;
  }, [selectedHonors]);

  const { methods: GetIsLinkedHistoryAPI, state: GetIsLinkedHistoryState } = BackendAPI.useBackendAPI('IsLinkedHistory');
  const isLinkedHistoryPending = useMemo(() => getIsPending(GetIsLinkedHistoryState), [GetIsLinkedHistoryState]);

  const checkDegree = (item: Person.ScientistDegree | Person.ScientistRank | Person.ScientistAcademicRank) => {
    if (Person.isScientistDegree(item)) {
      return 'degree';
    }
    if (Person.isScientistAcademicRank(item)) {
      return 'academicRank';
    }
    if (Person.isScientistRank(item)) {
      return 'rank';
    }
  };

  const honors = useMemo<EditableHonor[]>(
    () =>
      [...(person?.degrees ?? []), ...(person?.academicRanks ?? []), ...(person?.ranks ?? [])].map(item => {
        return { ...item, type: checkDegree(item) };
      }),
    [person?.academicRanks, person?.degrees, person?.ranks],
  );

  const onDeleteButtonClick = useCallback((nextDeletedIndex: number) => {
    setSelectedIndex(nextDeletedIndex);
    setIsDeleteConfirmOpen(true);
  }, []);

  const onDeleteReset = useCallback(() => {
    setSelectedIndex(null);
    setIsDeleteConfirmOpen(false);
  }, []);

  const selectedHonor = useMemo(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex) {
      return honors[selectedIndex!];
    }

    return null;
  }, [selectedIndex, honors]);

  const selectedHonorLinkedType = useMemo(() => getHonorLinkedHistoryType(selectedHonor), [selectedHonor]);

  const removeHonor = useCallback(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex) {
      const honorsCopy = [...honors];
      const isReadonlyHonor = Boolean(selectedHonor?.isReadOnly);

      if (isReadonlyHonor) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.SCIENTIST_JOB_EDUCATION_DEGREE_RANK_DELETE);
        if (!isUserHasPermission) {
          showNotification({ message: 'Нет прав на удаление ученых степеней и званий пришедших по интеграции', theme: 'danger' });
          return;
        }
        const deletionDate = `${format(new Date(), formatDateTimeStr)}`;
        const deletedBy = currentPerson?.fullName || '';
        const nextHonor: Honor = {
          ...selectedHonor!,
          isActive: false,
          deletionDate,
          deletedBy,
        };

        honorsCopy.splice(selectedIndex, 1, nextHonor);
        onChangeHonors(honorsCopy as Honor[]);
        showNotification({ message: 'Запись помечена удаленной', theme: 'success' });
      } else {
        honorsCopy.splice(selectedIndex, 1);
        onChangeHonors(honorsCopy);
        showNotification({ message: 'Запись удалена', theme: 'success' });
      }
    }
  }, [currentPerson?.fullName, honors, onChangeHonors, selectedHonor, selectedIndex, userPermission]);

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmOpen(false);

    if (selectedIndex === null || selectedHonorLinkedType === null) {
      return;
    }

    if (selectedHonor?.id) {
      GetIsLinkedHistoryAPI.callAPI(
        { type: selectedHonorLinkedType, id: selectedHonor?.id },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.isSuccess) {
              setIsDeleteHistoryWarningOpen(true);
            } else {
              removeHonor();
            }
          },
        },
      );
    } else {
      removeHonor();
    }
  }, [selectedIndex, selectedHonorLinkedType, selectedHonor?.id, GetIsLinkedHistoryAPI, removeHonor]);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<EditableHonor>[]>(
    () => [
      {
        title: 'Удалить',
        icons: buttonIcons.delete,
        isDisabled: false,
        checkIsDisabled: (honor: EditableHonor | null) => {
          if (honor === null || isLinkedHistoryPending || (selectedHonors && selectedHonors?.length > 1)) {
            return true;
          }

          const isHonorReadonly = honor?.isReadOnly;
          const isHonorActive = honor?.isActive;
          if (isHonorReadonly) {
            if (isHonorActive) {
              return false;
            }

            return true;
          }

          return false;
        },
        onClick: (_, honorIndex: number | null) => {
          if (honorIndex !== null) {
            onDeleteButtonClick(honorIndex);
          }
        },
      },
      {
        title: 'Связь',
        icons: buttonIcons.editRelations,
        checkIsDisabled: (honor: EditableHonor | null) => !honor?.id || (selectedHonors && selectedHonors?.length > 1),
        onClick: (_, honorIndex: number | null) => {
          if (honorIndex !== null) {
            setSelectedIndex(honorIndex);
            setIsEntityRelationOpen(true);
          }
        },
      },
      {
        icons: buttonIcons.merge,
        title: 'Объединение дубликатов',
        checkIsDisabled: isMergeDisabled,
        isHidden: !isHasPermission(userPermission, Permits.SCIENTIST_MERGE),
        onClick: () => {
          const ids: string[] = selectedHonors
            .map((degree: EditableHonor) => {
              return degree?.id || '';
            })
            .filter(x => !!x);
          setDegreeIds(ids);
          setIsMergeDuplicatesOpen(true);
        },
      },
    ],
    [isLinkedHistoryPending, onDeleteButtonClick, isMergeDisabled, selectedHonors, setDegreeIds, userPermission],
  );

  const closeDeleteHistoryWarning = useCallback(() => {
    setIsDeleteHistoryWarningOpen(false);
  }, []);

  const confirmDeleteHistoryWarning = useCallback(() => {
    closeDeleteHistoryWarning();
    setIsEntityRelationOpen(true);
  }, [closeDeleteHistoryWarning]);

  const closeEntityRelation = useCallback(() => {
    setIsEntityRelationOpen(false);
  }, []);

  const deleteConfirmPopupTitle = useMemo(() => {
    switch (selectedHonorLinkedType) {
      case LinkedHistoryType.DEGREE:
        return 'Удаление ученой степени';
      case LinkedHistoryType.RANK:
        return 'Удаление ученого звания';
      case LinkedHistoryType.ACADEMIC_RANK:
        return 'Удаление академического звания';
      default:
        return 'Удаление сущности';
    }
  }, [selectedHonorLinkedType]);

  return {
    extraToolbarButtons,
    isDeleteConfirmOpen,
    honors,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedHonor,
    selectedHonorLinkedType,
    deleteConfirmPopupTitle,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    degreeIds,
  };
};

export default useController;
