import * as R from 'ramda';
import { convertMobileRequests, convertPublication } from 'services/BackendAPI/makeBackendFormAPIConfiguration';
import {
  Enterprise,
  Participation,
  ParticipationEventSource,
  ParticipationProject,
  ParticipationProjectAcceptPerson,
  ReportType,
} from 'types/models/Participation';
import { Award, ResponseData, Author } from 'types/models';

import { Settings } from 'types/models/common';
import { convertServerExponent } from 'services/BackendAPI/configurations/exponents/converters';
import { sortByPosition } from 'utils/Helpers/sortByPosition';
import { ParticipationProjectType } from 'utils/Enums/ParticipationProjectType';
import { convertMember } from '../author/converters';
import { convertEventParticipation, convertSource, convertSourceMagazineRelease } from '../event/converters';

import {
  convertDocument,
  convertFile,
  convertIdItem,
  convertItem,
  convertMaybeArray,
  convertReference,
  getId,
  getLabel,
  getText,
} from '../commonConverters';
import { convertPaymentRequest } from '../converters';

export const convertDepartment = (department: any) => ({
  id: getId(department),
  code: department?.code?._text || '',
  name: department?.name?._text || '',
});

export function convertServerParticipation(serverParticipation: any, settings: Settings): Participation {
  const id = serverParticipation._attributes?.id;
  const isAllowEdit = getText(serverParticipation.AllowEdit) === 'true';
  const serverAwards = serverParticipation.Awards?.Award || [];
  const serverEvent = serverParticipation.Event || null;
  const serverExposition = serverParticipation.Exposition;
  const serverReportType = serverParticipation.ReportType;
  const serverReportTheme = serverParticipation.ReportTheme;
  const serverReportDate = serverParticipation.ReportDate;
  const serverRecipientNote = serverParticipation.Note;
  const serverIsCollectiveExhibit = serverParticipation.IsCollectiveExhibit;
  const serverCollectiveExhibitName = serverParticipation.CollectiveExhibitName;
  const serverDevelopment = serverParticipation.Development;
  const serverPublication = serverParticipation.Publication;
  const serverDepartments = serverParticipation.Departments?.Department || [];
  const serverForeignDocuments = serverParticipation.ForeignDocuments?.Document || [];
  const serverDocuments = serverParticipation.Documents?.Document || [];
  const serverTeamName = serverParticipation.TeamName;
  const serverParticipationProcedure = serverParticipation.ParticipationProcedure;
  const serverStatus = serverParticipation.Status;

  const mobileRequests = convertMobileRequests(serverParticipation);
  const members = convertMembers(serverParticipation.Members?.Member || [], settings);
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, R.partial(convertDocument, ['foreign']));
  const localDocuments = convertMaybeArray(serverDocuments, R.partial(convertDocument, ['local']));
  const departments = convertMaybeArray(serverDepartments, convertDepartment);
  const awards = convertMaybeArray(serverAwards, a => convertServerAward(a, settings));
  const event = convertServerEvent(serverEvent, settings);
  const exhibitType = convertServerReportType(serverExposition);
  const incentivePaymentRequest = serverParticipation?.MobileRequests?.MobileRequests
    ? convertEventRequest(serverParticipation?.MobileRequests?.MobileRequests)
    : '';
  const serverPaymentRequest = serverParticipation.PaymentRequest;
  const paymentRequest = serverPaymentRequest ? convertMaybeArray(serverPaymentRequest, convertPaymentRequest) : [];
  const createdBy = getText(serverParticipation.CreatedBy);
  const exponent = serverParticipation.Exponent ? convertServerExponent(serverParticipation.Exponent, settings) : null;
  const form = serverParticipation.Form ? convertReference(serverParticipation.Form) : null;
  const projects = convertMaybeArray(serverParticipation.Projects?.Project, convertParticipationProject);

  const financingProjects: ParticipationProject[] = projects.filter(
    ({ type }) => type?.value === ParticipationProjectType.FINANCING,
  );
  const presentationProject: ParticipationProject | null =
    projects.find(({ type }) => type?.value === ParticipationProjectType.PRESENTATION) ?? null;

  return {
    id,
    isAllowEdit,
    departments,
    awards,
    event,
    exhibitType,
    exponent,
    reportType: convertServerReportType(serverReportType),
    reportTheme: getText(serverReportTheme),
    reportDate: getText(serverReportDate),
    documents: [...foreignDocuments, ...localDocuments],
    mobileRequests,
    projects,
    members,
    note: getText(serverRecipientNote),
    isCollectiveExhibit: getText(serverIsCollectiveExhibit) === 'true',
    collectiveExhibitName: getText(serverCollectiveExhibitName),
    development: getText(serverDevelopment),
    publication: serverPublication ? convertPublication(serverPublication, settings) : null,
    status: convertStatus(serverStatus),
    teamName: getText(serverTeamName),
    participationProcedure: getText(serverParticipationProcedure),
    presentationForms: convertMaybeArray(serverParticipation.PresentationForms?.PresentationForm, getText),
    incentivePaymentRequest,
    paymentRequest,
    createdBy,
    form,
    financingProjects,
    presentationProject,
  };
}

const convertMembers = (members: any, settings: Settings): Author[] =>
  sortByPosition<Author>(convertMaybeArray(members || [], m => convertMember(m, settings)));

const convertParticipationProject = (project: any): ParticipationProject => ({
  id: getId(project),
  project: convertIdItem(project.Project),
  type: project.Type ? convertItem(project.Type) : null,
  accepted: getText(project.Accepted),
  acceptedBy: project.AcceptedBy ? convertParticipationProjectAcceptPerson(project.AcceptedBy) : null,
});

const convertParticipationProjectAcceptPerson = (person: any): ParticipationProjectAcceptPerson => ({
  id: getId(person),
  fullName: getText(person.FullName),
});

function convertServerAward(serverAward: any, settings: Settings): Award {
  return {
    id: serverAward._attributes.id,
    name: getText(serverAward.Name) ?? '',
    category: serverAward.Category ? convertItem(serverAward.Category) : null,
    file: convertFile(serverAward.File),
    contest: serverAward.Contest ? convertReference(serverAward.Contest) : null,
    recipients: convertMaybeArray(serverAward.Recipients.Recipient || [], m => convertMember(m, settings)),
    nomination: serverAward.Nomination ? getText(serverAward.Nomination) : null,
  };
}
function convertEnterprise(serverEnterprise: any): Enterprise {
  return {
    name: getText(serverEnterprise.name),
    id: serverEnterprise._attributes?.id || '',
    address: getText(serverEnterprise.address),
  };
}
function convertEventRequest(request: any) {
  const converter = (req: any) =>
    `${req.Status ? `${req.Status._attributes?.label}` : ''} ${getText(req.StatusChanged)} (п. ${getText(
      req.RegulationParagraph,
    )}: ${getText(req.RegulationName)})`;
  return Array.isArray(request) ? request.map(converter).join('; ') : converter(request);
}

function convertStatus(serverStatus: any): Participation['status'] {
  return {
    label: serverStatus?._attributes?.label || '',
    value: getText(serverStatus),
  };
}

function convertServerEvent(serverEvent: any, settings: Settings): ParticipationEventSource | null {
  if (serverEvent) {
    const id = getId(serverEvent);
    const name = getText(serverEvent.Name);
    const status = getLabel(serverEvent.Status);
    const participations = convertEventParticipation(serverEvent, settings);
    const eventStatus = convertReference(serverEvent.EventStatus);
    const isOnline = getText(serverEvent.IsOnline) === 'true';
    const site = getText(serverEvent.Site);
    const isCompilationPlanned = serverEvent.IsCompilationPlanned?._text || 'false';
    const isMagazineReleasePlanned = serverEvent.IsMagazineReleasePlanned?._text || 'false';

    const eventCity = getText(serverEvent.City);
    const eventCountry = getLabel(serverEvent.Country);

    const eventStartDate = getText(serverEvent.StartDate);
    const eventEndDate = getText(serverEvent.EndDate);
    const eventDateString = eventStartDate && eventEndDate ? ` ${eventStartDate} - ${eventEndDate}` : ` ${eventStartDate}`;
    const eventCountryString = `${eventCountry ? ` ${eventCountry}${eventCity ? '' : '.'}` : ''}`;
    const eventCityString = `${eventCity ? `, ${eventCity}.` : ''}`;
    const fullName = `${name}.${eventCountryString}${eventCityString}${eventDateString}`;
    const format = convertItem<string>(serverEvent.Format);

    const magazineReleases = serverEvent.MagazineReleases?.MagazineRelease
      ? convertMaybeArray(serverEvent.MagazineReleases.MagazineRelease, convertSourceMagazineRelease)
      : [];
    const compilations = serverEvent.Compilations?.Compilation
      ? convertMaybeArray(serverEvent.Compilations.Compilation, convertSource)
      : [];

    const magazineSource = magazineReleases.length
      ? {
          id: magazineReleases[0].upperSourceId || '',
          status: magazineReleases[0].status || '',
          name: magazineReleases[0].upperSourceName || '',
        }
      : null;
    const enterprises = convertMaybeArray(serverEvent.Enterprises?.Enterprise?.Element || [], convertEnterprise);
    const departments = convertMaybeArray(serverEvent.Departments?.Department?.Element || [], convertDepartment);
    return {
      id,
      name,
      status,
      eventStatus,
      fullName,
      isOnline,
      site,
      startDate: eventStartDate,
      endDate: eventEndDate,
      format,
      entity: {
        isCompilationPlanned,
        isMagazineReleasePlanned,
        magazineReleases,
        compilations,
        magazineSource,
        participations,
      },
      departments,
      enterprises,
    };
  }

  return null;
}

export function convertServerReportType(serverReportType: any): ReportType {
  return {
    id: serverReportType?._attributes?.id || '',
    label: getLabel(serverReportType),
    code: serverReportType?._attributes?.code || '',
  };
}

export const convertBindResponseXMLElementToOutput = (xml: any): ResponseData => ({
  success: xml.Response._attributes.success === 'true',
  message: xml.Response._attributes.errorMessage || xml.Response._attributes.serverErrorMessage || '',
});

export const convertApproveParticipationResponseXMLElementToOutput = (xml: any): ResponseData => ({
  success: xml.Response._attributes.success === 'true',
  message: xml.Response._attributes.errorMessage || xml.Response._attributes.serverErrorMessage || '',
});

export const convertDeleteParticipationResponseXMLElementToOutput = (xml: any): ResponseData => ({
  success: xml.Response._attributes.success === 'true',
  message: xml.Response._attributes.errorMessage || xml.Response._attributes.serverErrorMessage || '',
});
