import React, { memo } from 'react';

import { FormComponent } from 'components';

import { DataGrid } from 'features/Table';
import { GetParticipationFeedlineList } from 'features/Table/specifications';
import { Table as T } from 'types/models';

type Props = {
  relatedTableState: T.State | undefined;
  participationId?: string | null;
  workMode: T.WorkMode;
};

function Messages({ relatedTableState, participationId, workMode }: Props) {
  return (
    <>
      <FormComponent.Description mode="warning">
        Здесь сохраняются все системные и пользовательские сообщения, которые были добавлены в процессе работы с записью о данном
        участии в мероприятии. Редактировать или удалить пользовательское сообщение имеет право только автор этого сообщения.
      </FormComponent.Description>

      {relatedTableState && (
        <DataGrid
          specification={GetParticipationFeedlineList(
            {
              relatedTableAPIID: 'GetConferenceParticipationList',
              relatedTableState,
              relatedRecordId: participationId || undefined,
            },
            workMode,
          )}
        />
      )}
    </>
  );
}

export const Component = memo(Messages);
