import React from 'react';

import { CustomSelect, FormComponent, TextDateTime, TextInput, TextInputMode } from 'components';

import { ValueOf } from 'types/helpers';
import { MipPartner } from 'types/models';
import { GetPartnerMipList } from 'features/Table/specifications';
import { Table } from 'types/models';
import { useCallback } from 'react';
import { Original } from 'types/models/Form';

type Props = {
  indicator: MipPartner.Indicator;
  setIndicator(indicator: MipPartner.Indicator): void;
  isPartnerDisplayed?: boolean;
  workMode?: Table.WorkMode;
};

export const Fields = ({ indicator, setIndicator, isPartnerDisplayed, workMode }: Props) => {
  const makeHandler = (key: keyof MipPartner.Indicator) => (value: ValueOf<MipPartner.Indicator> | Original) => {
    setIndicator({
      ...indicator,
      [key]: value,
    });
  };

  const mipPartnerRowConverter = useCallback<(row: Table.Entry) => Original>(row => {
    return {
      id: row.id || '',
      name: row.FullName,
      fullName: row.FullName,
    };
  }, []);

  return (
    <>
      {isPartnerDisplayed && (
        <FormComponent.Line hasGap lineSize="padded">
          <FormComponent.Field isRequired label="МИП">
            <CustomSelect
              disabled={workMode !== 'addMode'}
              original={indicator.partner ? { id: indicator.id || '', name: indicator.partner?.fullName || '' } : null}
              relationTableModalTitle="Партнёры"
              onChange={val => makeHandler('partner')(val)}
              modalTableRowConverter={mipPartnerRowConverter}
              modalTableSpecification={GetPartnerMipList({ hasSelectButton: true })}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line hasGap lineSize="padded">
        <FormComponent.Field isRequired label="Год">
          <TextInput mode={TextInputMode.year} value={indicator.year ?? ''} onChange={makeHandler('year')} />
        </FormComponent.Field>
        <FormComponent.Field isRequired label="Среднесписочная численность, чел.">
          <TextInput
            mode={TextInputMode.number}
            value={indicator.averageHeadcount ?? ''}
            onChange={makeHandler('averageHeadcount')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasGap lineSize="padded">
        <FormComponent.Field isRequired label="Чистая прибыль">
          <TextInput
            mode={TextInputMode.number}
            value={indicator.revenue ?? ''}
            onChange={makeHandler('revenue')}
            settings={{ isNatural: false }}
          />
        </FormComponent.Field>
        <FormComponent.Field isRequired label="Выручка">
          <TextInput
            mode={TextInputMode.number}
            value={indicator.profit ?? ''}
            onChange={makeHandler('profit')}
            settings={{ isNatural: false }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата сдачи отчёта">
          <TextDateTime value={indicator.reportDate} onChange={makeHandler('reportDate')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};
