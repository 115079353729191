import React from 'react';
import { formatNumber } from 'utils/Helpers';
import block from 'bem-cn';
import * as icons from 'icons';
import { ColumnType } from 'types/models/Table';
import { IconButton } from 'components';
import './style.scss';

const b = block('grid-cell');

type Props = {
  columnType: ColumnType;
  cellValue: string;
};

export const Cell = ({ columnType, cellValue }: Props) => {
  switch (columnType) {
    case 'MONEY':
      return <div className={b()}>{cellValue ? formatNumber(cellValue) : ''}</div>;
    case 'BOOLEAN':
      return (
        <div className={b({ boolean: true })}>
          {cellValue === 'true' ? <IconButton hasNoHover icons={{ default: icons.Check }} /> : ''}
        </div>
      );
    case 'HTML':
      return <div dangerouslySetInnerHTML={{ __html: cellValue }} />;
    default:
      return <div className={b()}>{cellValue}</div>;
  }
};
