import React, { useCallback } from 'react';

import { InputSelect, TextArea, TextAreaMode, TextInput, FormComponent } from 'components';

import { Member, SelectPerson } from 'features/SelectPerson';

import { NirRequest } from 'types/models';
import { EnumValue } from 'types/models/Table';
import { ValueOf } from 'types/helpers';

import { isNumberValid } from 'utils/Validators';
import { TenderType } from 'utils/Enums';

type Props = {
  performer: NirRequest.Performer;
  setPerformer(performer: NirRequest.Performer): void;
  roles: EnumValue[];
  tenderType: NirRequest.TenderType | null;
};

export function Fields(props: Props) {
  const { performer, roles, tenderType, setPerformer } = props;

  const handleOnlyNumberChange = (key: keyof NirRequest.Performer) => (value: ValueOf<NirRequest.Performer>) => {
    if (isNumberValid(value as string)) {
      makeChangeHandler(key)(value);
    }
  };

  const makeChangeHandler = (key: keyof NirRequest.Performer) => (value: ValueOf<NirRequest.Performer>) => {
    setPerformer({ ...performer, [key]: value });
  };

  const updateMember = useCallback(
    (member: Member) => {
      setPerformer({ ...performer, ...member });
    },
    [performer, setPerformer],
  );

  const renderTextAreaField = () => {
    const label = `Участие ${performer.role?.value === 'LEADER' ? 'руководителя' : 'исполнителя'} в хоздоговорных работах`;
    return tenderType === TenderType.PromPartner ? (
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field
          label={label}
          tooltip="Укажите название организации-партнёра, название проекта,
      сроки выполнения проекта, общую сумму, руб. Не более 3500 знаков с пробелами"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: label }}
            value={performer.economicContractParticipationExperience}
            onChange={makeChangeHandler('economicContractParticipationExperience')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    ) : (
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field
          label="Участие в международных научных сообществах"
          tooltip="Укажите участие в международных научных сообществах и коллаборациях,
        редколлегиях ведущих иностранных рецензируемых научных изданий. Не более 500 знаков с пробелами"
        >
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Участие в международных научных сообществах' }}
            value={performer.collaborationParticipation}
            onChange={makeChangeHandler('collaborationParticipation')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    );
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" isRequired>
          <InputSelect
            options={roles}
            value={roles.find(x => x.value === performer.role?.value) ?? null}
            onSelectChange={makeChangeHandler('role') as any}
          />
        </FormComponent.Field>
        <FormComponent.Field label="ФИО" isRequired>
          <SelectPerson member={performer} onUpdateMember={updateMember} />
        </FormComponent.Field>
      </FormComponent.Line>

      {renderTextAreaField()}
      <FormComponent.Line lineSize="triplePadded">
        <FormComponent.Field
          label="Количество публикаций Scopus/WoS Q1, Q2 (для сотрудников других организаций"
          tooltip="Это поле заполнять для сотрудников других организаций, за ИСКЛЮЧЕНИЕМ тех,
            кто в роли 'Руководитель проекта' или 'Ведущий учёный'"
        >
          <TextInput
            value={performer.foreignPerformerQ1Q2PublicationQty}
            onChange={handleOnlyNumberChange('foreignPerformerQ1Q2PublicationQty')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
