import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Form } from './Form/Form';

export type Arguments = {
  hasToolbarButtonMode?: boolean;
  hasSelectButton?: boolean;
  hasOnlyLeaders?: boolean;
};

export function GetPersonalProjectList({
  hasToolbarButtonMode = false,
  hasSelectButton,
  hasOnlyLeaders,
}: Arguments): T.Specification<State> {
  return {
    apiID: 'GetPersonalProjectList',
    isFiltersHidden: true,
    requestData: { onlyLeaderOrResponsiblePerformer: !!hasOnlyLeaders },
    header: {
      firstLevel: {
        title: hasToolbarButtonMode ? '' : 'Список научных проектов',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
        hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController({ hasToolbarButtonMode }),
    NeighborTableComponent: Form,
    isWithQna: true,
  };
}
