import React, { useEffect } from 'react';
import * as R from 'ramda';

import {
  ConfirmPopup,
  FormComponent,
  ListEdit,
  Reference,
  SectionTitle,
  TextArea,
  TextAreaMode,
  TextGridSelect,
} from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { AuthorInputs } from 'features/Form/looks/rntd/RntdForm/Tabs/AboutRntd/AuthorInputs/AuthorInputs';
import { validateAuthor } from 'features/Form/looks/rntd/RntdForm/Tabs/AboutRntd/validate';
import { showErrors } from 'utils/Validators';
import { Subject } from 'types/models';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { GetPartnerList, GetSubjectForExponentList } from 'features/Table/specifications';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ExponentForm({ viewMode, editMode, onClose }: Props) {
  const {
    exponent,
    formFields,
    workMode,
    handleFormSubmit,
    handleAuthorsChange,
    modalTableSubjectConverter,
    partnerTableRowConverter,
    handlePartnersChange,
    handleSubjectChange,
    securityDocumentsRow,
    presentationFormsRow,
    settings,
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleConfirmPartnerButtonClick,
    checkPartnerStatus,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      null,
    );

  useEffect(() => {
    if (setTitle) {
      setTitle(
        (() => {
          if (workMode === 'addMode') {
            return 'Добавление разработки';
          }
          return [
            workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
            'разработки, представленной на выставке',
            exponent?.id ? `(ID ${exponent?.id})` : '',
          ]
            .filter(x => x)
            .join(' ');
        })(),
      );
    }
  }, [exponent?.id, setTitle, workMode]);

  return (
    <>
      <FormComponent.Template>
        <ControlPanel
          handleSave={() => handleFormSubmit(true)}
          handleSaveAndContinue={() => handleFormSubmit(false)}
          viewMode={viewMode}
        />

        <FormComponent.Wrapper>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <FormComponent.Line>
                <FormComponent.Field label={formFields.type.title} isRequired={formFields.type.required}>
                  <Reference
                    name="RefExhibitType"
                    value={formFields.type.value ?? null}
                    relationTableModalTitle='Справочник "Типы разработок"'
                    onChange={formFields.type.onChange}
                    disabled={!!viewMode}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label={formFields.name.title} isRequired>
                  <TextArea
                    mode={TextAreaMode.modal}
                    settings={{ rows: 3, title: formFields.name.title }}
                    value={formFields.name.value}
                    onChange={formFields.name.onChange}
                    isDisabled={!!viewMode}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field
                  label="Продукт для коммерциализации (ОИС)"
                  tooltip="Поле заполняется, если разработка связана с объектами интеллектуальной собственности"
                >
                  <TextGridSelect<Subject>
                    value={exponent?.subject || null}
                    onChange={handleSubjectChange}
                    specification={GetSubjectForExponentList()}
                    settings={{
                      mode: 'selectTableRow',
                      tableRowConverter: modalTableSubjectConverter,
                    }}
                    valueFormatter={x => x.name}
                    disabled={!!viewMode}
                    title="Продукт для коммерциализации (ОИС)"
                    isTextarea
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line lineSize="padded">
                <FormComponent.Field label={formFields.note.title}>
                  <TextArea
                    mode={TextAreaMode.modal}
                    settings={{ rows: 3, title: formFields.note.title }}
                    value={formFields.note.value}
                    onChange={formFields.note.onChange}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <>
                {securityDocumentsRow && (
                  <FormComponent.Field label="Объекты интеллектуальной собственности">
                    <div>{securityDocumentsRow}</div>
                  </FormComponent.Field>
                )}
                {presentationFormsRow && (
                  <FormComponent.Field label="Формы представления на выставках">
                    <div>{presentationFormsRow}</div>
                  </FormComponent.Field>
                )}
              </>
            </FormComponent.Column>
            <FormComponent.Column>
              <SectionTitle title="Контактные лица" />
              <ListEdit
                rows={exponent?.authors || []}
                onChange={handleAuthorsChange}
                maxHeight="300px"
                defaultRowsCount={10}
                columns={[
                  { label: 'ФИО', formatValue: x => x?.person?.fullName || '', styles: { width: '30%' } },
                  {
                    label: 'Должность',
                    formatValue: x => {
                      const result = getSplittedHistoryLabel(x);
                      return result.rank || result.error || '';
                    },
                    styles: { width: '25%' },
                  },
                  {
                    label: 'Информация о работе/обучении',
                    formatValue: x => {
                      const result = getSplittedHistoryLabel(x);
                      return result.job || result.error || '';
                    },
                    styles: { width: '45%' },
                  },
                ]}
                specification={{
                  mode: 'customComponent',
                  renderComponent: (row, onChange) => <AuthorInputs author={row} onAuthorChange={onChange as any} />,
                  validation: {
                    checkIsValid: a => validateAuthor(a).every(x => x.isValid),
                    onInvalidate: R.partial(showErrors, [validateAuthor]),
                  },
                }}
              />
              <SectionTitle title="Организации-партнёры" />
              <ListEdit
                rows={exponent?.partners || []}
                onChange={handlePartnersChange}
                maxHeight="300px"
                defaultRowsCount={5}
                withoutHead
                columns={[{ label: 'Название организации', formatValue: x => `${x.name} (${x.address})` }]}
                specification={{
                  mode: 'relationTableModal',
                  modalTableRowConverter: partnerTableRowConverter,
                  relationTableModalTitle: 'Партнёры',
                  onPreSubmit: checkPartnerStatus,
                  modalTableSpecification: GetPartnerList({
                    withMenu: false,
                    hasSelectButton: true,
                    isCanSelectOnlyOneRow: true,
                  }),
                }}
              />
              <ConfirmPopup
                title="Предупреждение"
                /* eslint-disable-next-line max-len */
                text={`Выбранная организация не имеет статуса "Партнёр ${settings?.organization?.shortName}". Присвоить статус партнёрской организации?`}
                isOpen={isConfirmPopupOpen}
                okButtonText="Да"
                resetButtonText="Нет"
                onClose={() => setIsConfirmPopupOpen(false)}
                onConfirm={handleConfirmPartnerButtonClick}
                icon="warning"
              />
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </>
  );
}

export const Component = React.memo(ExponentForm);
