import { Form } from 'types/models';

export enum ValidateType {
  REQUIRED = 'required',
  ISVALID = 'valid',
}

export function validate(formFields: Form.Fields, type: ValidateType) {
  const required = Object.keys(formFields)
    .filter((fieldName: string) => {
      if (type === ValidateType.REQUIRED) {
        return formFields[fieldName].required && !formFields[fieldName]?.value;
      }
      if (type === ValidateType.ISVALID) {
        return !formFields[fieldName]?.isValid;
      }
    })
    .map((fieldName: string) => {
      return {
        isValid: false,
        invalidMessage: `${formFields[fieldName].title}`,
      };
    });
  return required;
}
