import { Person } from 'types/models';

export type GetSuggestionsRequest = {
  searchValue: string;
};

export type GetScientistDataRequest = {
  personId: string;
  modules?: string[];
};

export type SaveScientistBibliographicNameRequest = {
  id?: string;
  scientistId: string;
  bibliographicName: string;
  languageId: string;
  isDefault: string;
};

export type SetDefaultBibliographicNameRequest = {
  id: string;
  scientistId: string;
  bibliographicName: string;
  languageId: string;
};

export type SaveScientistsRequest = {
  person: Person.ScientistPerson;
};

export enum LinkedHistoryType {
  JOB = 'JOB',
  EDUCATION = 'EDUCATION',
  DEGREE = 'DEGREE',
  RANK = 'RANK',
  ACADEMIC_RANK = 'ACADEMIC_RANK',
}

export type IsLinkedHistory = {
  type: LinkedHistoryType;
  id: string;
};

export type IsLinkedHistoryResponse = {
  isSuccess: boolean;
};

export type GetLinkedHistoryEntitiesRequestData = {
  type: LinkedHistoryType;
  id: string;
};

export type GetPersonAvailableHistoryElementsRequestData = {
  personId?: string;
} & GetLinkedHistoryEntitiesRequestData;

export type ChangePersonHistoryElementLinkRequestData = {
  type: LinkedHistoryType;
  id?: string;
  historyEntities: HistoryEntity[];
  keepHistory: boolean;
  nullify: boolean;
};

export type HistoryEntity = {
  id: string;
  type: string;
  label: string;
};
