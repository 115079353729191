import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Form, SecurityDocument, SecurityDocumentTypes } from 'types/models';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  onViewProjectInfo(): void;
  patent: SecurityDocument | null | undefined;
  formFields: Form.Fields;
  disabled?: boolean;
};

export function useController(props: Props) {
  const { onSave, onSaveDraft, onViewProjectInfo, patent, formFields, disabled } = props;

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const onClickPrint = useCallback(() => {
    handleSetCurrentReport({ name: Reports.SecurityDocumentAuthors.caption, value: Reports.SecurityDocumentAuthors.name });
  }, [handleSetCurrentReport]);

  const [isSaveConfirmPopupOpen, setIsSaveConfirmPopupOpen] = useState<boolean>(false);
  const [isSaveAndClose, setIsSaveAndClose] = useState<boolean>(false);

  const onSaveConfirmPopupConfirm = useCallback(() => {
    if (isSaveAndClose) {
      onSave(true);
    } else {
      onSaveDraft();
    }
    setIsSaveConfirmPopupOpen(false);
  }, [isSaveAndClose, onSave, onSaveDraft]);

  const datesMatched = useCallback(() => {
    if (patent?.type?.value === SecurityDocumentTypes.KNOW_HOW) return false;
    return formFields?.balanceOrderNumber.value && formFields?.requestDate.value === formFields?.hiDate.value;
  }, [patent, formFields]);

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        isHidden: disabled,
        onClick: () => {
          if (datesMatched()) {
            setIsSaveAndClose(true);
            setIsSaveConfirmPopupOpen(true);
          } else {
            onSave(true);
          }
        },
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        isHidden: disabled,
        onClick: () => {
          if (datesMatched()) {
            setIsSaveAndClose(false);
            setIsSaveConfirmPopupOpen(true);
          } else {
            onSaveDraft();
          }
        },
      },
      {
        icons: buttonIcons.info,
        title: 'Посмотреть описание проекта',
        onClick: onViewProjectInfo,
      },
      {
        icons: buttonIcons.print,
        title: 'Авториский коллектив РИД',
        onClick: onClickPrint,
      },
    ],
    [onClickPrint, onViewProjectInfo, datesMatched, onSave, onSaveDraft, disabled],
  );
  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
  };
}
