import React, { useMemo } from 'react';

import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons } from 'components';

import { User } from 'types/models/User';

type Props = {
  user: User | null;
  disabledEdit: boolean;
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  onActivate(): void;
  onDeactivate(): void;
};

function Toolbar(props: Props) {
  const { user, disabledEdit, onSave, onSaveDraft, onActivate, onDeactivate } = props;

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.save,
        title: 'Сохранить',
        isDisabled: false,
        isHidden: disabledEdit || !user?.isActive,
        onClick: () => onSave(true),
      },
      {
        icons: buttonIcons.saveAndContinue,
        title: 'Сохранить и продолжить',
        isDisabled: false,
        isHidden: disabledEdit || !user?.isActive,
        onClick: onSaveDraft,
      },
      {
        icons: buttonIcons.activation,
        title: 'Активировать',
        isHidden: user?.isActive,
        onClick: () => onActivate(),
      },
      {
        icons: buttonIcons.deactivation,
        title: 'Деактивировать',
        isHidden: !user?.isActive,
        onClick: () => onDeactivate(),
      },
    ],
    [onSave, onSaveDraft, disabledEdit, onActivate, onDeactivate, user?.isActive],
  );

  return <SharedToolbar buttons={buttons} mode="form" />;
}

export { Toolbar };
