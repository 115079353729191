import React from 'react';

import { Table } from 'types/models';
import { Toolbar as SharedToolbar } from 'components';
import { EditRelations } from '../views/EditRelations';
import { State } from '../makeUseCustomController';

import useController from './controller';

type Props = {
  tableState: Table.State;
  customState: State;
};

function Toolbar({ tableState, customState: { personId, id, type } }: Props) {
  const { isOpenRelationModal, buttons, historyEntities, closeRelationsModal } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <EditRelations
        isOpen={isOpenRelationModal}
        onClose={closeRelationsModal}
        personId={personId}
        id={id}
        type={type}
        historyEntities={historyEntities}
      />
    </>
  );
}

export { Toolbar };
