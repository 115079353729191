import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { EntityLocker } from 'features/EntityLocker';

import { Modal } from 'components';

const RecognitionEditTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look = {
    apiID: 'GetRecognition',
    template: 'RecognitionForm',
    editMode: true,
    id: String(id),
    relatedTableState,
    setTitle,
  };

  return (
    <EntityLocker onClose={onClose} isOpen={isOpen} entity="Event" id={look.id}>
      <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
        <Form look={look} onClose={onClose} />
      </Modal>
    </EntityLocker>
  );
};

export const Component = React.memo(RecognitionEditTemplate);
