import React, { useState, useEffect, useMemo } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { PublicationType } from 'types/models/Publication/publicationTypes';

const MagazineAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id, name } = props;
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [electronicPublicationType, setElectronicPublicationType] = useState<PublicationType>({
    id: '1',
    label: 'Сетевой ресурс',
    labelDeclined: 'Сетевой ресурс',
    text: 'Публикация размещена в сети Интернет',
    code: 'WEB',
  });
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look = useMemo(
    () =>
      ({
        id,
        apiID: 'GetPublication',
        type: 'OTHER_BOOK',
        template: 'PublicationFormOther',
        name,
        isElectronic,
        electronicType: electronicPublicationType.code,
        relatedTableState,
        initialTypeEdition: 'LOCAL',
        setTitle,
      } as F.Look),
    [name, isElectronic, electronicPublicationType, id, relatedTableState],
  );

  useEffect(() => {
    if (isOpen) {
      setElectronicPublicationType({
        id: '1',
        label: 'Сетевой ресурс',
        labelDeclined: 'Сетевой ресурс',
        text: 'Публикация размещена в сети Интернет',
        code: 'WEB',
      });
      setIsElectronic(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'medium-height']}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineAddTemplate);
