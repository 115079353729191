import { useLayoutEffect, useCallback, useState } from 'react';

import { useStream } from 'StreamRx';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { streams as localFormStreams } from 'features/Form/streams';
import { Form, QnaFrequentQuestion } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  onClose: () => void;
};

export const useController = ({ onClose }: Props) => {
  const {
    look: { id, relatedTableState },
  } = useFormContext();

  const tableStreams = useLocalTableStreams();

  const { methods: getQnaFrequentQuestions } = BackendAPI.useBackendAPI('GetQnaFrequentQuestions');
  const { methods: saveQnaFrequentQuestion } = BackendAPI.useBackendAPI('SaveQnaFrequentQuestion');

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push();
  }, [tableStreams]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    questionTheme: {
      value: '',
      required: true,
      isValid: true,
      title: 'Тема',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          questionTheme: { ...prevState.questionTheme, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          questionTheme: { ...prevState.questionTheme, isValid: true },
        }));
      },
    },
    question: {
      value: '',
      required: true,
      isValid: true,
      title: 'Вопрос',
      maxSymbols: 2049,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          question: { ...prevState.question, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          question: { ...prevState.question, isValid: true },
        }));
      },
    },
    answer: {
      value: '',
      required: true,
      isValid: true,
      title: 'Ответ',
      maxSymbols: 2049,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          answer: { ...prevState.answer, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          answer: { ...prevState.answer, isValid: true },
        }));
      },
    },
    hashtags: {
      value: '',
      required: false,
      isValid: true,
      title: 'Тэги',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          hashtags: { ...prevState.hashtags, value },
        }));
      },
    },
    priority: {
      value: '',
      required: false,
      isValid: true,
      title: 'Приоритет',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          priority: { ...prevState.priority, value },
        }));
      },
    },
  });

  useStream(
    () => localFormStreams.loadForm,
    ({ currentId }) => {
      loadQnaFrequentQuestion(currentId);
    },
    // eslint-disable-next-line
    [],
  );
  const loadQnaFrequentQuestion = useCallback(
    (currentId?: string) => {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        question: {
          ...prevState.question,
          value: '',
        },
      }));
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        answerText: {
          ...prevState.answerText,
          value: '',
        },
      }));
      if (id !== 'undefined') {
        getQnaFrequentQuestions.callAPI(
          {
            id: currentId || id || undefined,
          },
          {
            onSuccessfullCall: (result: any) => {
              const qnaFrequent = result.data[0] as QnaFrequentQuestion;
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                questionTheme: {
                  ...prevState.questionTheme,
                  value: qnaFrequent.theme,
                },
              }));
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                question: {
                  ...prevState.question,
                  value: qnaFrequent.message,
                },
              }));
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                hashtags: {
                  ...prevState.hashtags,
                  value: qnaFrequent.hashtags && qnaFrequent.hashtags.length > 0 ? qnaFrequent.hashtags.join(' ') : '',
                },
              }));
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                priority: {
                  ...prevState.priority,
                  value: qnaFrequent.priority,
                },
              }));
              if (qnaFrequent.answer) {
                setFormFields((prevState: Form.Fields) => ({
                  ...prevState,
                  answer: {
                    ...prevState.answer,
                    value: qnaFrequent.answer,
                  },
                }));
              }
            },
          },
        );
      }
    },
    [getQnaFrequentQuestions, id],
  );

  const normalizeTags = useCallback(() => {
    const hashTags = formFields.hashtags?.value
      ? formFields.hashtags.value?.split(' ').map((tag: string) => {
          tag.trim();
          tag.replace(' ', '');
          tag.replace(/^#/, '');
          return tag;
        })
      : [];

    function capitalizeFirstLetter(string: any) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const themeLabel = formFields.questionTheme?.value
      ? formFields.questionTheme?.value?.label
          ?.split(' ')
          .map((word: string) => capitalizeFirstLetter(word))
          .join('')
      : null;

    if (themeLabel && hashTags.indexOf(themeLabel) <= -1) {
      hashTags.push(themeLabel);
    }
    return hashTags.length > 0 ? hashTags : undefined;
  }, [formFields]);

  const saveQnaFrequentHandler = useCallback(() => {
    if (!formFields.questionTheme?.value) {
      showNotification({ message: 'Выберите тему', theme: 'danger' });
      return false;
    }
    if (!formFields.question?.value) {
      showNotification({ message: 'Напишите вопрос', theme: 'danger' });
      return false;
    }
    if (!formFields.answer?.value) {
      showNotification({ message: 'Напишите ответ', theme: 'danger' });
      return false;
    }

    saveQnaFrequentQuestion.callAPI(
      {
        id,
        themeId: formFields.questionTheme?.value?.id ? (formFields.questionTheme.value?.id as string) : '',
        message: formFields.question?.value ? formFields.question.value : undefined,
        answerText: formFields.answer?.value,
        hashtags: normalizeTags(),
        priority: formFields.priority?.value ? formFields.priority?.value : undefined,
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Вопрос сохранен', theme: 'success' });
          updateTable();
          onClose();
        },
      },
    );
    return true;
  }, [saveQnaFrequentQuestion, normalizeTags, id, formFields, updateTable, onClose]);

  useLayoutEffect(() => {
    loadQnaFrequentQuestion();
    // eslint-disable-next-line
  }, []);

  return {
    relatedTableState,
    id,
    formFields,
    saveQnaFrequentHandler,
  };
};
