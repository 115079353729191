import React, { useEffect } from 'react';

import { FormComponent, ListEdit, CustomSelect, Tabs, Tab, SectionTitle, TextDateTime, TextInput } from 'components';

import { GetScienceProjectList, GetStageProjectList } from 'features/Table/specifications';
import { Documents } from 'features/Documents';
import { showErrorsMessages } from 'utils/Common';
import { useController } from './controller';
import { CustomerFields } from './CustomerFields';
import { PerformerFields } from './PerformerFields';
import { getContacts } from './helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { validatePatentMember } from './validate';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function PatentResearchForm({ viewMode, editMode, onClose }: Props) {
  const {
    workMode,
    name,
    number,
    target,
    loDate,
    hiDate,
    project,
    stage,
    customers,
    performers,
    documents,
    handleNameFieldChange,
    handleNumberFieldChange,
    handleTargetFieldChange,
    handleLoDateFieldChange,
    handleHiDateFieldChange,
    handleProjectFieldChange,
    handleStageFieldChange,
    handleCustomersFieldChange,
    handlePerformersFieldChange,
    handleDocumentsFieldChange,
    convertProjectRow,
    convertStageRow,
    handleFormSubmit,
    getJobLabel,
    projectLeader,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();
  useEffect(() => {
    if (setTitle) {
      setTitle(
        [
          (() => {
            if (workMode === 'addMode') {
              return 'Добавление';
            }
            if (workMode === 'viewMode') {
              return 'Просмотр';
            }
            return 'Редактирование';
          })(),
          'патентного исследования',
          (() => {
            if (workMode === 'addMode') {
              return '';
            }
            return [project?.label ? `"${project.label}"` : '', project?.id ? `ID (${project.id})` : ''].filter(x => x).join(' ');
          })(),
        ]
          .filter(x => x)
          .join(' '),
      );
    }
  }, [project?.id, project?.label, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            <FormComponent.ColumnWrapper>
              <FormComponent.Column>
                <SectionTitle title="Патентное исследование" />

                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Номер задания" isRequired>
                    <TextInput value={number} onChange={handleNumberFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Название отчета" isRequired>
                    <TextInput value={name} onChange={handleNameFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line lineSize="doublePadded">
                  <FormComponent.Field label="Цель исследования">
                    <TextInput value={target} onChange={handleTargetFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Дата начала" isRequired>
                    <TextDateTime value={loDate} onChange={handleLoDateFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                  <FormComponent.Field label="Дата сдачи отчета">
                    <TextDateTime value={hiDate} onChange={handleHiDateFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field>
                    <CustomSelect
                      title="Выполняется в рамках научного проекта"
                      disabled={workMode === 'viewMode'}
                      original={
                        project?.id
                          ? {
                              name: project.label || '',
                              id: project.id,
                            }
                          : null
                      }
                      relationTableModalTitle="Выполняется в рамках научного проекта"
                      onChange={handleProjectFieldChange}
                      modalTableRowConverter={convertProjectRow}
                      modalTableSpecification={GetScienceProjectList}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field>
                    <CustomSelect
                      title="Этап проекта"
                      isRequired={!!project?.id}
                      disabled={workMode === 'viewMode' || !project?.id}
                      original={
                        stage?.id
                          ? {
                              name: stage.label || '',
                              id: stage.id,
                            }
                          : null
                      }
                      relationTableModalTitle="Этап проекта"
                      onChange={handleStageFieldChange}
                      modalTableRowConverter={convertStageRow}
                      modalTableSpecification={GetStageProjectList({ projectId: project?.id || '-1' })}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Руководитель">
                    <span>
                      {projectLeader?.person?.fullName}, {projectLeader?.person?.scientist?.phone || '*'},&nbsp;
                      {projectLeader?.person?.scientist?.mobilePhone || '*'},{projectLeader?.person?.scientist?.email || '*'}
                    </span>
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column>
                <SectionTitle title="Контактные лица" />
                <FormComponent.Line>
                  <FormComponent.Field>
                    <ListEdit
                      title="Заказ на проведение патентного исследования от"
                      withMessages
                      defaultRowsCount={10}
                      isDeleteConfirmEnabled
                      isToolbarDisabled={workMode === 'viewMode'}
                      rows={customers}
                      onChange={handleCustomersFieldChange}
                      columns={[
                        { label: 'ФИО', formatValue: x => x.person?.fullName || '' },
                        {
                          label: 'Место работы',
                          formatValue: x => (x.job ? getJobLabel(x.job) : ''),
                        },
                        { label: 'Контакты', formatValue: x => getContacts(x) },
                      ]}
                      specification={{
                        mode: 'customComponent',
                        renderComponent: (row, onFieldChange) => <CustomerFields entry={row} onFieldChange={onFieldChange} />,
                        validation: {
                          checkIsValid: (row, index, list, mode) =>
                            validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
                          onInvalidate: (row, mode, index, list) => {
                            const validationInfo = validatePatentMember(row, list, mode, index);
                            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
                          },
                        },
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field>
                    <ListEdit
                      title="Исполнители заказа"
                      withMessages
                      defaultRowsCount={10}
                      isDeleteConfirmEnabled
                      isToolbarDisabled={workMode === 'viewMode'}
                      rows={performers}
                      onChange={handlePerformersFieldChange}
                      columns={[
                        { label: 'ФИО', formatValue: x => x.person.fullName },
                        {
                          label: 'Место работы',
                          formatValue: x => (x.job ? getJobLabel(x.job) : ''),
                        },
                        { label: 'Контакты', formatValue: x => getContacts(x) },
                      ]}
                      specification={{
                        mode: 'customComponent',
                        renderComponent: (row, onFieldChange) => <PerformerFields entry={row} onFieldChange={onFieldChange} />,
                        validation: {
                          checkIsValid: (row, index, list, mode) =>
                            validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
                          onInvalidate: (row, mode, index, list) => {
                            const validationInfo = validatePatentMember(row, list, mode, index);
                            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
                          },
                        },
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </Tab>
          <Tab title="Прикрепленные документы">
            <Documents documents={documents} setDocuments={handleDocumentsFieldChange} disabled={workMode === 'viewMode'} />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(PatentResearchForm);
