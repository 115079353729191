import React, { useMemo } from 'react';
import { buttonIcons, IconButton, IconButtonProps } from 'components';

export function PersonalHelpContents() {
  const button = useMemo<IconButtonProps>(() => {
    return {
      icons: buttonIcons.activation,
      title: 'Передать на рассмотрение',
      isDisabled: false,
      onClick: () => {},
    };
  }, []);

  return (
    <span>
      <h2>Заявки на закупки для научных проектов</h2>
      Раздел содержит список заявок на закупки для научных проектов, в которых Вы указаны в роли <strong>
        руководитель
      </strong> или <strong>ответственный исполнитель</strong> проекта.
      <br />
      <br />
      Для оформления Заявки на закупку:
      <br />
      <br />
      <h3>Этап 1. Добавление заявки на закупку</h3>
      Заполнение формы Заявки, добавление файла к заявке (например, требования к поставщику, техническое задание на выполнение
      работ и пр.)
      <ul>
        <li>
          Заявка, не зависимо от статуса, будет отображаться в Личном кабинете у руководителя проекта, ответственного исполнителя
          проекта и материально-ответственного лица.
        </li>
      </ul>
      <h3>
        Этап 2. Отправка заявки на рассмотрение с помощью КНОПКИ <IconButton {...button} />
      </h3>
      <ul>
        <li>
          Заявка меняет статус <strong>Черновик → На рассмотрении</strong> и система фиксирует дату подачи заявки на рассмотрение.
        </li>
        <li>
          Ответственное лицо за сбор заявок на закупки зарегистрирует заявку, присвоив ей номер в соответствии с внутренним
          регламентом.
        </li>
        <li>Заявку просматривают эксперты и добавляют экспертное замечание (визу).</li>
        <li>
          Заявка в статусе <strong>На рассмотрении</strong> не доступна для редактирования.
        </li>
      </ul>
      <h3>Этап 3. При необходимости, заявка может быть переведена в статус На доработку</h3>
      Внесение исправлений по замечаниям, и повторная отправка заявителем заявки на рассмотрение.
      <br />
      <br />
      <strong className="primary">Важно!</strong>
      <br />
      Заявитель может удалить заявку только в статусе <strong>Черновик</strong>.
      <br />
      <br />
      <h2>Процедуры по заявкам на закупку:</h2>
      <table className="list-edit-table">
        <thead>
          <tr className="list-edit-table__row list-edit-table__row_head">
            <th className="list-edit-table__column">Статус закупки</th>
            <th className="list-edit-table__column">Доступные действия с заявкой в текущем статусе</th>
            <th className="list-edit-table__column">Доступный статус для перевода</th>
          </tr>
        </thead>
        <tbody>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">Черновик</td>
            <td className="list-edit-table__column">
              <strong>Создание новой заявки</strong>
              <br /> Кто: Заявитель (Руководитель, Ответственный исполнитель)
            </td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> На рассмотрение
              <br />
              <strong>Кто переводит:</strong> Заявитель
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">На рассмотрении</td>
            <td className="list-edit-table__column">
              <strong>Просмотр заявки и возможность оставить визу:</strong>
              <ul>
                <li>Управляющий комитет</li>
                <li>Ценовая комиссия</li>
                <li>Управление логистики</li>
              </ul>
              Функциональные кнопки <strong>«Комитет»</strong>, <strong>«Комиссия»</strong> и <strong>«Логистика»</strong>,
              доступны, если статус закупки — <strong>"На рассмотрении"</strong>.<br />
              <strong>Примечание:</strong> Порядок рассмотрения управляющим комитетом, ценовой комиссией и управлением логистики,
              определяется внутренним регламентом университета.
            </td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong>
              <ul>
                <li>На доработку</li>
                <li>Отклонена</li>
                <li>В работе (поставщик)</li>
                <li>Выполнена</li>
                <li>Черновик</li>
              </ul>
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">На доработку</td>
            <td className="list-edit-table__column">
              Доступно <strong>редактирование</strong> заявки
              <br />
              <strong>Кто может редактировать:</strong> Заявитель, Ответственное лицо по заявке
            </td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> На рассмотрение
              <br />
              <strong>Кто переводит:</strong> Заявитель, Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">Отклонена</td>
            <td className="list-edit-table__column">Заявка закрыта для редактирования, доступен только просмотр.</td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> На доработку
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">В работе (Поставщик)</td>
            <td className="list-edit-table__column">Заявка закрыта для редактирования, доступен только просмотр.</td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> Выполнена, Отклонена
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
          <tr className="list-edit-table__row">
            <td className="list-edit-table__column">Выполнена</td>
            <td className="list-edit-table__column">Заявка закрыта для редактирования, доступен только просмотр.</td>
            <td className="list-edit-table__column">
              <strong>Перевод в статус:</strong> Черновик
              <br />
              <strong>Кто переводит:</strong> Ответственное лицо по заявке
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <strong className="primary">Важно!</strong>
      <br />
      Сообщения, добавленные при переводе в любой статус, отображаются в столбце <strong>Сообщение</strong> в&nbsp;
      <strong>Журнале сообщений</strong> закупки. Сообщения, добавленные по кнопкам:&nbsp;
      <strong>Виза управляющего комитета, Виза ценовой комиссии</strong> и <strong>Виза управления логистики</strong>&nbsp;
      отображаются в столбце <strong>Замечание эксперта</strong> в <strong>Журнале сообщений</strong> закупки. В таблице&nbsp;
      <strong>Список закупок</strong> в столбце <strong>Сообщение</strong> отображается последнее добавленное сообщение, в
      столбце$nbsp;
      <strong>Замечание эксперта</strong> — все визы: Управляющего комитета, Ценовой комиссии и Управления логистики.&nbsp;
    </span>
  );
}
