import { Project } from 'types/models';

export function formatFioWithChanges(performer: Project.Performer): string {
  const currentName = performer?.person?.fullName?.trim() || '';
  let nameTokens = [currentName];
  performer?.person?.changes.forEach(c => {
    if (c.patronymic) {
      let newFio = `${c.firstName?.trim()} ${c.patronymic?.trim()} ${c.lastName?.trim()}`,
        newFio2 = `${c.lastName?.trim()} ${c.patronymic?.trim()} ${c.firstName?.trim()}`,
        newFio3 = `${c.lastName?.trim()} ${c.firstName?.trim()} ${c.patronymic?.trim()}`;
      if (nameTokens.indexOf(newFio) < 0 && nameTokens.indexOf(newFio2) < 0 && nameTokens.indexOf(newFio3) < 0) {
        nameTokens.push(newFio3);
      }
    } else {
      let newFio = `${c.firstName?.trim()} ${c.lastName?.trim()}`,
        newFio2 = `${c.lastName?.trim()} ${c.firstName?.trim()}`;
      if (nameTokens.indexOf(newFio) < 0 && nameTokens.indexOf(newFio2) < 0) {
        nameTokens.push(newFio2);
      }
    }
  });
  nameTokens = nameTokens.filter(n => n !== currentName);
  return nameTokens.length > 0 ? `${currentName} (${nameTokens.join(', ')})` : currentName;
}
