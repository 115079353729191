import { Table } from 'types/models';

type Arguments = {
  roleId: string;
  isWithoutHeader?: boolean;
};

type RequestData = {
  roleId: string;
};

export function GetRoleUserList({ roleId, isWithoutHeader = false }: Arguments): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetRoleUserList',
    header: {
      firstLevel: {
        title: isWithoutHeader ? '' : 'Пользователи с ролью',
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { roleId },
  };
}
