import { Table, Report } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { buttonIcons, IconButtonProps } from 'components';
import { Permits } from 'utils/Permissions';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

import { useLocalTableStreams } from 'features/Table/hooks';
import { TenderInfo } from 'types/models/Form/look/programRequest';
import { getMobileRequestStatusButtons } from '../helpers';
import { MobileRequestStatus } from 'utils/Enums';
import { isHasSomePermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { MobileRequestTab } from 'utils/Permissions/MobileRequestTab';

export type FormProps = {
  type: string;
};

export function useController(tableState: Table.State) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;
  const status = (selectedRow?.['id:Status'] as MobileRequestStatus) || null;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isTenderModalOpen, setIsTenderModalOpen] = useState<boolean>(false);
  const [tenderInfo, setTenderInfo] = useState<TenderInfo | null>(null);
  const [mobileRequestId, setMobileRequestId] = useState<string | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const isEditPermitted = useMemo(() => {
    if (!status) return false;
    return isHasSomePermission(
      userPermission,
      (Object.keys(MobileRequestTab) as Array<MobileRequestTab>).map(
        tabKey => Permits[`MOBILE_REQUEST_EDIT_${status}_${tabKey}`],
      ),
    );
  }, [status, userPermission]);

  const reports = useMemo<Report[]>(
    () => [
      Reports.MobileRequest,
      Reports.MobileRequestAnalytic,
      Reports.MobileRequestOrderOtherAppointment,
      Reports.MobileRequestMoneyOrder,
      Reports.MobileRequestTaskOtherAppointment,
      Reports.MobileRequestEstimate,
      Reports.MobileRequestOrderProject,
    ],
    [],
  );
  const { getReports, handleSetCurrentReport, isReportOpen, onReportClose, currentReport } = useReportsHook({ reports });

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    if (mobileRequestId) {
      tableStreams.deleteRow.push({
        deleteRowId: mobileRequestId,
        command: 'DeleteMobileRequest',
        deletedItemPropName: 'MobileRequest',
      });
      setIsDeleteConfirmPopupOpen(false);
    }
  }, [tableStreams.deleteRow, mobileRequestId]);

  const handleDeleteButtonClick = useCallback(() => {
    setMobileRequestId(tableState.selectedRows[0].id);
    setIsDeleteConfirmPopupOpen(true);
  }, [tableState]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setTenderInfo(null);
    setIsAddFormOpen(false);
  }, []);

  const handleTenderSelect = useCallback((row: Table.Entry) => {
    setTenderInfo({ tenderId: row.id, lotId: row.lotId });
    setIsTenderModalOpen(false);
    setIsAddFormOpen(true);
  }, []);

  const {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons: statusChangeButtons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = getMobileRequestStatusButtons({
    rows: tableState.selectedRows.map(x => ({ id: x.id, status: (x?.['id:Status'] as MobileRequestStatus) || null })),
  });

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'help',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        permissionName: Permits.MOBILE_REQUEST_VIEW,
        isDisabled: !selectedRow,
        onClick: () => setIsViewFormOpen(true),
      },
      {
        icons: buttonIcons.plus,
        title: 'Добавить',
        permissionName: Permits.MOBILE_REQUEST_ADD,
        onClick: () => setIsTenderModalOpen(true),
      },
      {
        icons: buttonIcons.edit,
        title: 'Редактировать',
        permissionName: (Object.keys(MobileRequestStatus) as Array<MobileRequestStatus>).flatMap(statusKey =>
          (Object.keys(MobileRequestTab) as Array<MobileRequestTab>).map(
            tabKey => Permits[`MOBILE_REQUEST_EDIT_${statusKey}_${tabKey}`],
          ),
        ),
        isDisabled: !selectedRow || !isEditPermitted,
        onClick: () => setIsEditFormOpen(true),
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить',
        permissionName: Permits.MOBILE_REQUEST_DELETE_DRAFT,
        isDisabled: !selectedRow || status !== MobileRequestStatus.DRAFT,
        onClick: handleDeleteButtonClick,
      },
      ...statusChangeButtons,
      {
        icons: buttonIcons.print,
        title: 'Печать документов',
        code: 'print',
        isDisabled: !selectedRow,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [
      handleHelpButtonClick,
      selectedRow,
      isEditPermitted,
      status,
      handleDeleteButtonClick,
      statusChangeButtons,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    buttons,
    currentReport,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isReportOpen,
    isViewFormOpen,
    mobileRequestId,
    onReportClose,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsViewFormOpen,
    handleTenderSelect,
    isTenderModalOpen,
    setIsTenderModalOpen,
    tenderInfo,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
  };
}
