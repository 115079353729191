import React from 'react';

import { Department } from 'types/models/ProgramRequest';
import { FormComponent, Reference } from 'components';

import { useController } from './controller';

type Props = {
  department: Department | null;
  onDepartmentChange(row: Department): void;
};

function DepartmentInputs({ department, onDepartmentChange }: Props) {
  const { handleFieldChange } = useController({
    department,
    onDepartmentChange,
  });

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Факультет/Институт">
          <Reference
            name="RefDepartment"
            onChange={handleFieldChange('governance')}
            value={
              department?.governance
                ? { id: department.governance?.id ? department.governance?.id : '', label: department.governance.label }
                : null
            }
            relationTableModalTitle="Список подразделений университета"
            isRequired
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Подразделение">
          <Reference
            name="RefDepartment"
            onChange={handleFieldChange('unit')}
            value={department?.unit ? { id: department.unit?.id ? department.unit?.id : '', label: department.unit.label } : null}
            relationTableModalTitle="Список подразделений университета"
            isRequired
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

export { DepartmentInputs };
