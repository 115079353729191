import { Author } from 'types/models';

export function formatAuthor(author: Author) {
  const { person, job, isReporter } = author;
  const jobString = job?.status
    ? `${job.status.label}${
        job.refAppointment?.label || job.appointment ? `, ${job.refAppointment?.label || job.appointment}` : ''
      }`
    : '';
  return `
    <span><b>${person?.fullName}</b> ${jobString} ${isReporter ? '<b>(Докладчик)</b>' : ''}</span>
  `;
}
