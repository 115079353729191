import React, { memo, useCallback } from 'react';

import {
  CustomSelect,
  Reference,
  Button,
  ButtonMode,
  SectionTitle,
  FormComponent,
  TextArea,
  TextInput,
  TextInputMode,
} from 'components';

import { Table as T, Form as F } from 'types/models';
import { GetSimpleMagazineListSpecification, GetMagazineReleaseListSpecification } from 'features/Table/specifications';

type Props = {
  formFields: F.Fields;
  publicationId: string;
  onCreateBibliographicRecordClick: () => void;
  workMode: T.WorkMode;
  isElectronic: boolean;
  sourceMagazine: F.Original | null;
  sourceMagazineRelease: F.Original | null;
  changeSourceMagazine: (original: F.Original | null) => void;
  changeSourceMagazineRelease: (original: F.Original | null) => void;
  onDeleteSourceMagazine: () => void;
  onDeleteSourceMagazineRelease: () => void;
  relatedTableState: T.State | undefined;
  magazineReleaseConference: string[];
  sourceMagazineOriginal: F.Original | null;
  sourceMagazineTranslates: F.Translation[];
  magazineReleaseReport: string[];
  filterMagazineReleaseId: string;
  sourcePublicationDate: string;
  eventId?: string;
};

const Source = ({
  isElectronic,
  publicationId,
  formFields,
  sourceMagazine,
  sourceMagazineRelease,
  onCreateBibliographicRecordClick,
  changeSourceMagazineRelease,
  changeSourceMagazine,
  onDeleteSourceMagazine,
  onDeleteSourceMagazineRelease,
  relatedTableState,
  magazineReleaseConference,
  sourceMagazineOriginal,
  sourceMagazineTranslates,
  magazineReleaseReport,
  filterMagazineReleaseId,
  sourcePublicationDate,
  workMode,
  eventId,
}: Props) => {
  const modalTableRowToSourceMagazineConventer = useCallback<(row: T.Entry) => F.Original>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      typeEdition: row.typeEdition,
      foreignName: row.ForeignName || '',
    }),
    [],
  );

  const modalTableRowToSourceMagazineReleaseConventer = useCallback<(row: T.Entry) => F.Original>(
    row => ({
      id: row.id,
      name: row.label,
      status: row.Status,
      events: row.Events || null,
    }),
    [],
  );

  const sourceMagazineTableConfig = GetSimpleMagazineListSpecification({
    hasSelectButton: true,
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedTableState,
        relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0].id : '',
      },
    },
    articleIds: publicationId,
    isElectronic,
    filterMagazineReleaseId,
  });

  const sourceMagazineReleaseTableConfig = GetMagazineReleaseListSpecification({
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedRecordId: sourceMagazine?.id,
        relatedTableState,
      },
    },
    eventId,
    filterMagazineReleaseId,
    isDisableEventSource: true,
    hasSelectButton: true,
  });

  return (
    <form>
      <input type="submit" hidden />
      <SectionTitle title="Издание" />
      <FormComponent.Line>
        <FormComponent.Field>
          <CustomSelect
            disabled={workMode === 'viewMode'}
            isRequired
            isStatusText
            title="Название журнала"
            original={sourceMagazine}
            relationTableModalTitle="Журналы"
            onDeleteFunction={onDeleteSourceMagazine}
            onChange={changeSourceMagazine}
            modalTableRowConverter={modalTableRowToSourceMagazineConventer}
            modalTableSpecification={sourceMagazineTableConfig}
          />
          {(sourceMagazineOriginal || !!sourceMagazineTranslates?.length) && (
            <FormComponent.Line lineSize="doublePadded">
              <FormComponent.Field label="">
                <FormComponent.Description mode="warning" classMixin="is-hint">
                  {sourceMagazineOriginal && (
                    <div>
                      <strong>Оригинальный журнал: </strong>
                      {`${sourceMagazineOriginal.name}${
                        sourceMagazineOriginal.edition
                          ? ` (ID ${sourceMagazineOriginal.id}, издательство: ${sourceMagazineOriginal.edition})`
                          : ''
                      }`}
                    </div>
                  )}
                  {!!sourceMagazineTranslates?.length &&
                    sourceMagazineTranslates.map(translate => (
                      <>
                        <strong>Переводной журнал:</strong>
                        {`${translate.name}${
                          translate.edition ? ` (ID ${translate.id}, издательство: ${translate.edition})` : ''
                        }`}
                      </>
                    ))}
                </FormComponent.Description>
              </FormComponent.Field>
            </FormComponent.Line>
          )}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field>
          <CustomSelect
            disabled={workMode === 'viewMode' || !sourceMagazine}
            isStatusText
            isDependent
            isRequired
            title="Выпуск журнала"
            original={sourceMagazineRelease}
            relationTableModalTitle="Выпуск журнала"
            onDeleteFunction={onDeleteSourceMagazineRelease}
            onChange={changeSourceMagazineRelease}
            modalTableRowConverter={modalTableRowToSourceMagazineReleaseConventer}
            modalTableSpecification={sourceMagazineReleaseTableConfig}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="">
          <FormComponent.Description
            mode="warning"
            tooltip="Конференцию можно добавить на форме редактирования Выпуска журнала"
            classMixin="is-hint"
          >
            <div>
              <strong>Опубликованы материалы конференции:</strong> {magazineReleaseConference.join('; ') || 'Нет'}
            </div>
            <div>
              <strong>Доклады:</strong> {magazineReleaseReport.join('; ') || 'Нет'}
            </div>
          </FormComponent.Description>
        </FormComponent.Field>
      </FormComponent.Line>
      {!!sourcePublicationDate && (
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label="Дата публикации">
            <span>{sourcePublicationDate}</span>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <Reference
          // disabled
          value={formFields.textLanguage.value}
          isRequired
          theme="big"
          title="Язык текста"
          relationTableModalTitle="Язык"
          refButtonTitle="Язык"
          name="RefLanguage"
          onChange={formFields.textLanguage.onChange}
          isDisabledDelete
          disabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>
      <FormComponent.Line hasGap hasFreeFormat>
        <FormComponent.Field label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.number}
            value={formFields.printPageCount.value}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageFrom.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={formFields.pageFrom.value}
            onChange={formFields.pageFrom.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageTo.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.number}
            value={formFields.pageTo.value}
            onChange={formFields.pageTo.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.articleNumber.title} labelSize="fit">
          <TextInput
            value={formFields.articleNumber.value}
            onChange={formFields.articleNumber.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-number-mini"
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />
      <FormComponent.Line hasGap hasFreeFormat growIndexes={[2, 0]}>
        <FormComponent.Field>
          <TextArea
            settings={{ rows: 3 }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
        <Button
          mode={ButtonMode.secondary}
          text="Построить"
          onClick={onCreateBibliographicRecordClick}
          isDisabled={workMode === 'viewMode'}
        />
      </FormComponent.Line>

      <SectionTitle title="Классификаторы" />
      <FormComponent.Line>
        <Reference
          disabled={workMode === 'viewMode'}
          value={formFields.grnti.value}
          title={formFields.grnti.title}
          relationTableModalTitle="ГРНТИ"
          tooltipText={formFields.grnti.tooltipText}
          refButtonTitle="ГРНТИ"
          theme="big"
          name="RefGrnti"
          onChange={formFields.grnti.onChange}
        />
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
          <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <Reference
          tooltipText={formFields.study.tooltipText}
          disabled={workMode === 'viewMode'}
          value={formFields.study.value}
          title={formFields.study.title}
          relationTableModalTitle={formFields.study.title}
          theme="big"
          refButtonTitle="Область науки"
          name="RefStudy"
          onChange={formFields.study.onChange}
        />
      </FormComponent.Line>
    </form>
  );
};

export const SourceComponent = memo(Source);
