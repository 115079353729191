import React from 'react';

import { FormComponent, Reference, TextArea, TextInput, TextDateTime } from 'components';

import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  hirshIndex: Person.HirshIndex | null;
  onChange(hirshIndex: Person.HirshIndex): void;
};

function Fields(props: Props) {
  const { hirshIndex, onChange } = props;

  const makeChangeHandler = (fieldName: keyof Person.HirshIndex) => (value: ValueOf<Person.HirshIndex>) => {
    onChange({ ...hirshIndex!, [fieldName]: value });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field isRequired label="Дата расчета">
          <TextDateTime value={hirshIndex?.date} onChange={date => makeChangeHandler('date')(date ?? '')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Система цитирования">
          <Reference
            name="RefCitationSystem"
            value={hirshIndex?.citationSystem ?? null}
            onChange={makeChangeHandler('citationSystem')}
            relationTableModalTitle='Справочник "Системы цитирования"'
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Значение">
          <TextInput value={hirshIndex?.value ?? ''} onChange={makeChangeHandler('value')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Примечание">
          <TextArea settings={{ isFixed: true }} value={hirshIndex?.note ?? ''} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { Fields };
