import React, { useMemo } from 'react';
import * as R from 'ramda';

import { buttonIcons, ListEdit, SectionTitle } from 'components';

import { CitationSystem } from 'types/models';
import { showErrors } from 'utils/Validators';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { Fields } from './Fields/Fields';
import { getMockCitationSystem } from 'features/EditPartner/helpers';
import { validate } from './validate';

type Props = {
  citationSystems: CitationSystem[];
  setCitationSystems(citationSystems: CitationSystem[]): void;
  disabled: boolean;
};

export function CitationSystems(props: Props) {
  const { citationSystems, setCitationSystems, disabled } = props;
  const extraToolbarButtons = useMemo<ExtraToolbarButton<CitationSystem>[]>(
    () => [
      {
        icons: buttonIcons.link,
        title: 'Перейти по ссылке',
        code: 'openLink',
        checkIsDisabled: doc => doc === null || !doc.url,
        onClick: identifier => {
          if (identifier) window.open(`${identifier.url}`, '_blank');
        },
      },
    ],
    [],
  );
  return (
    <>
      <SectionTitle title="Идентификаторы организации в базах цитирования" />
      <ListEdit
        withMessages
        isDeleteConfirmEnabled
        rows={citationSystems}
        isToolbarDisabled={disabled}
        onChange={setCitationSystems}
        defaultRowsCount={10}
        maxHeight="50vh"
        extraToolbarButtons={extraToolbarButtons}
        columns={[
          { label: 'База цитирования', formatValue: x => x.refCitationSystem?.label || '' },
          { label: 'Идентификатор', formatValue: x => x.identifier },
          { label: 'Название', formatValue: x => x.name },
          { label: 'Ссылка', formatValue: x => x.url },
          { label: 'Создано', formatValue: x => x.createdBy },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (citationSystem, setCitationSystem) => (
            <Fields citationSystem={citationSystem || getMockCitationSystem()} setCitationSystem={setCitationSystem} />
          ),
          validation: {
            checkIsValid: citationSystem => validate(citationSystem).every(x => x.isValid),
            onInvalidate: R.partial(showErrors, [validate]),
          },
        }}
      />
    </>
  );
}
