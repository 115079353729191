import React, { useEffect, useState } from 'react';

import { Tabs, Tab, ConfirmPopup, FormComponent } from 'components';

import { EventTypes } from 'utils/Enums/EventTypes';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { DocumentsWarningPopup } from 'features/Form/looks/event/views/DocumentsWarningPopup';
import { Component as TitleErrorPopup } from 'features/Form/views/TitleErrorPopup';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { EventHeader } from 'features/Form/views/ModalHeader';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const EventFormExposition = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    isDocumentsWarningPopupOpen,
    tabsId,
    formFields,
    fetchEvent,
    handleFormSubmit,
    documents,
    setDocuments,
    termsList,
    isSelectedPeriod,
    departments,
    changeDepartments,
    enterprises,
    changeEnterprises,
    eventData,
    relatedTableState,
    refs,
    isTSUSelected,
    isOtherSelected,
    workMode,
    eventId,
    projects,
    changeProjects,
    isTitleWarningPopup,
    onConfirmTitleWarningPopup,
    onCloseTitleWarningPopup,
    titleWarningText,
    participations,
    controllerIsForm,
    onCloseDocumentsWarningPopup,
    onConfirmDocumentsWarningPopup,
    withHeaderId,
    isAuthorsPopupOpen,
    onCloseAuthorsPopup,
    onConfirmAuthorsPopup,
    onResetAuthorsPopup,
    organizationName,
    status,
    userPermission,
  } = useController({
    onClose,
  });

  const {
    look: { id, setTitle },
  } = useFormContext();

  const isApproved = status.status === 'APPROVED';
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  useEffect(() => {
    if (controllerIsForm && !(selectedRowIndex === null)) {
      controllerIsForm.setParticipation(participations[selectedRowIndex] || null);
    }
    // eslint-disable-next-line
  }, [selectedRowIndex]);
  const renderAboutInfo = (props?: { disabled: boolean }) => (
    <TabsContent.AboutComponent
      formFields={formFields}
      viewMode={viewMode || props?.disabled || false}
      termsList={termsList}
      isSelectedPeriod={isSelectedPeriod}
      departments={departments}
      changeDepartments={changeDepartments}
      enterprises={enterprises}
      changeEnterprises={changeEnterprises}
      refs={refs}
      isTSUSelected={isTSUSelected}
      isOtherSelected={isOtherSelected}
      relatedTableState={relatedTableState}
      withHeaderId={workMode !== 'addMode' ? withHeaderId : undefined}
    />
  );

  useEffect(() => {
    if (setTitle) {
      setTitle(
        EventHeader({
          workMode,
          departments,
          status: status.text || '',
          eventId: id || '',
          eventTypeCode: EventTypes.EVENT_EXPOSITION.code,
        }),
      );
    }
  }, [departments, id, setTitle, status.text, workMode]);

  return (
    <>
      <ConfirmPopup
        title="Внимание!"
        icon="info"
        isOpen={isAuthorsPopupOpen}
        okButtonText="Нет"
        resetButtonText="Добавить"
        onConfirm={onConfirmAuthorsPopup}
        onClose={onCloseAuthorsPopup}
        onReset={onResetAuthorsPopup}
      >
        <div>
          <div>
            <strong>В списке «Мои выставки», отображаются только те выставки, в которых вы в составе оргкомитета.</strong>
          </div>
          <br />
          <div>В списке «Выставки {organizationName}» — организованные Университетом</div>
          <br />
          <div>В списке «Другие выставки» — организованные другими организациями</div>
          <br />
          <span>Вашей персоны нет в списке участников оргкомитета. Добавить?</span>
        </div>
      </ConfirmPopup>

      <FormComponent.Template>
        <ControlPanel
          handleSave={() => handleFormSubmit(true)}
          handleSaveAndContinue={() => handleFormSubmit(false)}
          viewMode={viewMode}
          isDisabled={isLoading}
        />

        <SuchLikeEvent
          mode="edit"
          handleCloseOuterForm={onClose}
          command="RefEventFeedlineMessage"
          eventTypeCode={EventTypes.EVENT_EXPOSITION.code}
          eventName={formFields.name.value as string}
          startDate={formFields.startDate.value as string}
          endDate={formFields.endDate.value as string}
        />

        <FormComponent.Template>
          <Tabs id={tabsId}>
            <Tab
              title="О мероприятии"
              isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.EXPOSITION_EDIT_APPROVED_ABOUT)}
            >
              {renderAboutInfo()}
            </Tab>
            <Tab title="Участники">
              <TabsContent.Participations
                workMode={workMode}
                participations={participations}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
                setIsFormOpen={controllerIsForm}
                fetch={fetchEvent}
              />
            </Tab>
            <Tab
              title="Финансовая поддержка"
              isDisabled={!viewMode && isApproved && !isHasPermission(userPermission, Permits.EXPOSITION_EDIT_APPROVED_FINANCING)}
            >
              <TabsContent.FinancialSupport
                projects={projects}
                setProjects={changeProjects}
                workMode={workMode}
                relatedTableState={relatedTableState}
                incentivePaymentRequest={eventData?.status?.incentivePaymentRequest || ''}
              />
            </Tab>
            <Tab title="Документы">
              <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
            </Tab>
            <Tab title="Журнал сообщений">
              <TabsContent.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={eventId} />
            </Tab>
          </Tabs>
        </FormComponent.Template>

        <DocumentsWarningPopup
          isOpen={isDocumentsWarningPopupOpen}
          onConfirm={onConfirmDocumentsWarningPopup}
          onClose={onCloseDocumentsWarningPopup}
        />
        <TitleErrorPopup
          onConfirm={onConfirmTitleWarningPopup}
          onClose={onCloseTitleWarningPopup}
          errorText={titleWarningText}
          isOpen={isTitleWarningPopup}
        />
      </FormComponent.Template>
    </>
  );
};

export const Component = React.memo(EventFormExposition);
