import { useCallback, useState } from 'react';

import { Program, Project } from 'types/models';
import { showNotification } from 'features/Notifications';
import {
  generateIndicatorsByStages,
  removeDublicatesIndicators,
  updateStageIndicators,
} from 'features/Form/looks/project/ProjectForm/helpers';
import { EditableIndicator, IndicatorKey } from 'features/Form/looks/project/ProjectForm/model';

type Arguments = {
  stages: Project.Stage[];
  setStages(stages: Project.Stage[]): void;
  type: IndicatorKey;
  program: Program.Program | null;
};

export function useController(args: Arguments) {
  const { stages, setStages, type, program } = args;

  const [isOpenAddIndicatorsModal, setIsOpenAddIndicatorsModal] = useState(false);
  const [isOpenSelectStageModal, setIsOpenSelectStageModal] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [contestRequest, setContestRequest] = useState<Project.FullContestRequest | null>(null);

  const toggleAddIndicatorsModal = useCallback(() => {
    if (!program?.id) {
      showNotification({
        message:
          // eslint-disable-next-line max-len
          'Программа не указана. Выберите программу на вкладке "О проекте", сохраните проект с помощью кнопки "Сохранить и продолжить"',
        theme: 'danger',
      });
    } else {
      setIsOpenAddIndicatorsModal(prev => !prev);
    }
  }, [program]);

  const handleChangeIndicators = useCallback(
    (indicators: EditableIndicator[]) => {
      const updatedStages = updateStageIndicators(indicators, stages, type);
      setStages(updatedStages);
    },
    [setStages, stages, type],
  );

  const handleAddIndicatorsFromProgram = useCallback(
    (editableIndicator: EditableIndicator[]) => {
      const indicatorsForAdd = removeDublicatesIndicators(type, editableIndicator, stages);

      if (indicatorsForAdd.length) {
        const updatedStages = updateStageIndicators(indicatorsForAdd, stages, type, 'add');
        setStages(updatedStages);
        showNotification({
          message: `Выбранные элементы успешно добавлены, всего - ${indicatorsForAdd.length}`,
          theme: 'success',
        });
      } else {
        showNotification({ message: 'Не добавлен ни один выделенный элемент, все они уже есть в списке', theme: 'danger' });
      }
    },
    [setStages, stages, type],
  );

  const handleSubmitAddIndicatorsModal = useCallback(
    (selectedStages: Project.Stage[], indicators: Program.Indicator[]) => {
      const generatedIndicators = generateIndicatorsByStages(indicators, selectedStages);
      handleAddIndicatorsFromProgram(generatedIndicators);
    },
    [handleAddIndicatorsFromProgram],
  );

  const handleCopyIndicatorsFromProgram = useCallback(
    (stage: Project.Stage) => {
      const editableIndicators = (contestRequest?.[type] ?? []).map<EditableIndicator>(x => ({
        ...x,
        stageNumber: stage.number,
      }));
      handleAddIndicatorsFromProgram(editableIndicators);
      setContestRequest(null);
    },
    [contestRequest, type, handleAddIndicatorsFromProgram],
  );

  return {
    handleCopyIndicatorsFromProgram,
    handleSubmitAddIndicatorsModal,
    handleChangeIndicators,
    toggleAddIndicatorsModal,
    isOpenAddIndicatorsModal,
    isOpenSelectStageModal,
    contestRequest,
    setIsOpenSelectStageModal,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}
