import {
  SecurityDocument,
  SecurityDocumentMember,
  SecurityDocumentMemberTypes,
  SecurityDocumentPayment,
  SecurityDocumentRefElement,
} from 'types/models';

import * as R from 'ramda';
import { formatStr } from 'utils/Constants/FormatStr';
import { parse } from 'date-fns';
import { Settings } from 'types/models/common';
import { convertSecurityDocumentContract } from 'services/BackendAPI/configurations/securityDocumentContract/converters';
import {
  convertMaybeArray,
  convertReference,
  getId,
  getText,
  convertItem,
  convertDocument,
  convertFile,
} from '../commonConverters';
import { convertRefElement, convertProject } from '../project/converters';
import { convertServerPerson } from '../author/converters';
import { convertDepartment, convertPersonHistory } from '../converters';
import { convertSecurityDocumentInfo } from 'services/BackendAPI/configurations/securityDocumentPayment/converters';

export function convertSecurityDocument(p: any, settings: Settings): SecurityDocument {
  const type = convertItem<string>(p.Type);
  return {
    id: getId(p),
    label: getText(p.Label),
    contracts: convertMaybeArray(p.Contracts.Contract, c => convertSecurityDocumentContract(c, settings)),
    balanceOrderNumber: parseFloat(getText(p.BalanceOrderNumber)),
    loDate: type?.value === 'KNOW_HOW' ? getText(p.DocumentStartDate) : getText(p.BalanceLoDate),
    hiDate: type?.value === 'KNOW_HOW' ? getText(p.DocumentEndDate) : getText(p.BalanceHiDate),
    balancePrice: parseFloat(getText(p.BalancePrice)),
    documentNumber: getText(p.DocumentNumber),
    internalNumber: getText(p.InternalNumber),
    documentStartDate: getText(p.DocumentStartDate),
    documentEndDate: getText(p.DocumentEndDate),
    stateNumber: getText(p.StateNumber),
    isRus: getText(p.IsRus) === 'true',
    isWorldwide: getText(p.IsWorldwide) === 'true',
    stateRegistrationDate: getText(p.StateRegistrationDate),
    ...(p.RequestMpk ? { mpk: convertReference(p.RequestMpk) } : {}),
    domainKnowledges: convertMaybeArray(p.DomainKnowledges.DomainKnowledge, convertSecurityDocumentRefElement),
    grntis: convertMaybeArray(p.Grntis.Grnti, convertSecurityDocumentRefElement),
    intellectualPropertyKind: p.IntellectualPropertyKind
      ? convertReference(p.IntellectualPropertyKind)
      : p.IntellectualPropertyKindEvidence
      ? convertReference(p.IntellectualPropertyKindEvidence)
      : null,
    intellectualPropertyKindNonRegistered: p.IntellectualPropertyKindNonRegistered
      ? convertReference(p.IntellectualPropertyKindNonRegistered)
      : null,
    intellectualPropertyName: getText(p.IntellectualPropertyName),
    referat: getText(p.Referat),
    lksetss: convertMaybeArray(p.Lksetss.Lksets, convertSecurityDocumentRefElement),
    members: convertMaybeArray(p.Members.Member, m => convertSecurityDocumentMember(m, settings)).sort(
      (a, b) => parseFloat(a?.id || '0') - parseFloat(b?.id || '0'),
    ),
    countries: convertMaybeArray(p.Countries?.Country || [], convertReference),
    ntiEteTechnologies: convertMaybeArray(p.NtiEteTechnologies.NtiEteTechnology, convertSecurityDocumentRefElement),
    ntiMarkets: convertMaybeArray(p.NtiMarkets.NtiMarket, convertSecurityDocumentRefElement),
    ntrStrategies: convertMaybeArray(p.NtrStrategies.NtrStrategy, convertSecurityDocumentRefElement),
    oecds: convertMaybeArray(p.Oecds.Oecd, convertSecurityDocumentRefElement),
    okveds: convertMaybeArray(p.Okveds.Okved, convertSecurityDocumentRefElement),
    payments: convertMaybeArray(p.Payments.Payment, convertSecurityDocumentPayment).sort(
      (a, b) =>
        parse(a?.payBeforeDate || '', formatStr, new Date()).getTime() -
        parse(b?.payBeforeDate || '', formatStr, new Date()).getTime(),
    ),
    pnis: convertMaybeArray(p.Pnis.Pni, convertSecurityDocumentRefElement),
    pnmitrs: convertMaybeArray(p.Pnmitrs.Pnmitr, convertSecurityDocumentRefElement),
    pnrs: convertMaybeArray(p.Pnrs.Pnr, convertSecurityDocumentRefElement),
    priorityTechnologies: convertMaybeArray(p.PriorityTechnologies.PriorityTechnology, convertSecurityDocumentRefElement),
    project: p.Project ? convertProject(p.Project, settings) : null,
    requestDate: getText(p.RequestDate),
    requestSendDate: getText(p.RequestSendDate),
    documentDate: getText(p.DocumentDate),
    documentReceiveDate: getText(p.DocumentReceiveDate),
    requestNumber: getText(p.RequestNumber),
    requestStatus: p.RequestStatus ? convertItem<string>(p.RequestStatus) : null,
    scienceBrunches: convertMaybeArray(p.ScienceBrunches.ScienceBrunch, convertSecurityDocumentRefElement),
    scienceDomainInterrests: convertMaybeArray(
      p.ScienceDomainInterrests.ScienceDomainInterrest,
      convertSecurityDocumentRefElement,
    ),
    technologyPlatforms: convertMaybeArray(p.TechnologyPlatforms.TechnologyPlatform, convertSecurityDocumentRefElement),
    udks: convertMaybeArray(p.Udks.Udk, convertSecurityDocumentRefElement),
    type,
    departments: [
      ...(p.Departments && p.Departments.Department ? convertMaybeArray(p.Departments.Department, convertDepartment) : []),
    ],
    documents: [
      ...(p.Documents && p.Documents.Document
        ? convertMaybeArray(p.Documents.Document, R.partial(convertDocument, ['local']))
        : []),
    ],
    mpks: convertMaybeArray(p.Mpks?.Mpk || [], convertReference),
  };
}

export function convertSecurityDocumentRefElement(re: any): SecurityDocumentRefElement {
  return {
    ...convertRefElement(re),
    isProject: getText(re.IsProject) === 'true',
    isActual: getText(re.IsActual) === 'true',
  };
}

export function convertSecurityDocumentMember(m: any, settings: Settings): SecurityDocumentMember {
  const type = getText(m.Type);
  const person = m.Person || m.Scientist ? convertServerPerson(m.Person ?? m.Scientist, settings) : null;

  const enterprise = type === SecurityDocumentMemberTypes.APPLICANT ? convertReference(m.Enterprise) : undefined;
  return {
    id: getId(m),
    contribution: parseFloat(getText(m.Contribution)),
    type: convertItem(m.Type),
    name: getText(m.Name),
    person: person !== undefined ? person : null,
    ...convertPersonHistory(m),
    ...(type === SecurityDocumentMemberTypes.APPLICANT
      ? {
          contractNumber: getText(m.ContractNumber),
          contractDate: getText(m.ContractDate),
          contractPortion: getText(m.ContractPortion),
          contractNote: getText(m.ContractNote),
          enterprise,
        }
      : {}),
    historyLabel: m.HistoryLabel ? getText(m.HistoryLabel) : '',
  };
}

export function convertSecurityDocumentPayment(p: any): SecurityDocumentPayment {
  return {
    id: getId(p),
    amount: parseFloat(getText(p.Amount)),
    payDate: getText(p.PayDate),
    ...(p.PayBeforeDate ? { payBeforeDate: getText(p.PayBeforeDate) } : {}),
    paymentPurposeRequest: convertReference(p.PaymentPurposeRequest),
    ...(p.PaymentPurpose ? { paymentPurpose: convertReference(p.PaymentPurpose) } : {}),
    securityDocument: convertSecurityDocumentInfo(p.SecurityDocument),
    file: convertFile(p.File),
  };
}
