import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';

import { detPeriodHighlights } from 'shared/detPerdiodHighlights';
import { ProjectAssignmentStatus, ProjectAssignmentStatusCode } from 'types/models/Project';
import { Color } from 'constants/colors';
import { makeUseCustomController } from './makeUseCustomController';
import { State } from './makeUseCustomController';

type Args = {
  isFiltersHidden?: boolean;
  disabled?: boolean;
};

export function GetPersonalProjectAssignmentList({ isFiltersHidden, disabled }: Args): Table.Specification<State, Args> {
  return {
    apiID: 'GetPersonalProjectAssignmentList',
    isFiltersHidden,
    header: {
      firstLevel: {
        title: 'Список поручений по проектам',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    isWithQna: true,
    useCustomController: makeUseCustomController({ disabled }),
    highlightEntryPredicate: (entry: Table.Entry) => {
      const status = entry['id:Status'] as ProjectAssignmentStatusCode;
      if (
        !status ||
        !entry.UpToDate ||
        [ProjectAssignmentStatus.CANCELED, ProjectAssignmentStatus.CLOSED, ProjectAssignmentStatus.COMPLETE].indexOf(status) > -1
      ) {
        return {
          rowTheme: Color.primary,
        };
      }
      return detPeriodHighlights(entry, 3);
    },
  };
}
