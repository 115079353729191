import React from 'react';

import { Toolbar as SharedToolbar, ConfirmPopup, IconButton, buttonIcons } from 'components';

import { Table } from 'types/models';
import { AwardsModal } from 'features/AwardsModal';
import { EditRelationsProfileModal } from 'features/EditRelationsProfileModal';
import { State } from '../makeUseCustomController';
import { useController } from './controller';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import * as modalTemplates from './modalTemplates';
import { BindParticipation } from 'features/Form/views';

type Props = {
  tableState: Table.State;
  customState: State;
};

function Toolbar(props: Props) {
  const {
    tableState,
    customState: { loadParticipation, closeAwards, isShowAwards, participation },
  } = props;

  const {
    buttons,
    isEditDepartmentsOpen,
    handleCloseEditDepartments,
    isConfirmDeleteOpen,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    author,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isWarningPopupOpen,
    handleCloseWarningPopup,
    handleAttachParticipation,
    userSystemDepartment,
    selectedRow,
  } = useController({ tableState, loadParticipation });

  return (
    <>
      {isEditDepartmentsOpen && (
        <EditRelationsProfileModal
          type="PARTICIPATION"
          isOpen={isEditDepartmentsOpen}
          onClose={handleCloseEditDepartments}
          id={tableState.selectedRows[0]?.id || ''}
          errorText="У вас есть право добавить связь только со своими подразделениями.
Внимание: право редактировать запись об участии мероприятии (конференции, выставке, творческом мероприятии/олимпиаде,
творческом мероприятии)
 у вас появится при добавлении Основного подразделения.
Если вы видите ошибки в публикации в статусе «Утверждена», то напишите сообщение Администратору.
 В тексте сообщения обязательно укажите ID записи об участии в мероприятии."
          title="Редактировать связь публикации с подразделениями"
          participationType={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        />
      )}
      <ConfirmPopup
        isOpen={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        title="Подтверждение удаления"
        text="Вы действительно хотите удалить выбранное участие?"
        icon="warning"
        okButtonText="Удалить"
        resetButtonText="Отмена"
        onConfirm={handleDeleteParticipation}
      />
      {isEditFormOpen && (
        <modalTemplates.PaticipationEditTemplate.Component
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
        />
      )}
      {isViewFormOpen && (
        <modalTemplates.PaticipationViewTemplate.Component
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.ParticipationAddTemplate.Component
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          author={author}
        />
      )}
      <AwardsModal isOpen={isShowAwards} onClose={closeAwards} awards={participation?.awards || []} />
      <ConfirmPopup
        title="Помощь в работе с разделом «Участие в конкурсах»"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">Раздел Участие в конкурсах</strong> предназначен для добавления, редактирования и удаления
            участия в конкурсах, конкурсных проектов и наград.
          </p>
          <p>
            <strong className="primary">В разделе Участие в конкурсах вы можете:</strong>
          </p>
          <ul>
            <li>
              Просмотреть запись об участии в конкурсе и информацию на всех вкладках с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.loop} hasNoHover />
            </li>
            <li>
              Добавить новое участие в конкурсе с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover />
            </li>
            <li>
              Добавить новый конкурс с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover /> на вкладке «Участие в
              конкурсе» при создании нового участия.
            </li>
            <li>
              Редактировать запись об участии в конкурсе с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.edit} hasNoHover /> в статусе Добавлена
            </li>
            <li>
              Удалить запись об участии в конкурсах, которую вы создавали, если она в статусе Добавлена с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.delete} hasNoHover />
            </li>
            <li>
              Выгрузить список участий в конкурсах в файл Excel с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
            </li>
            <li>Добавить информацию о конкурсном проекте (работе) на вкладке «Участие в конкурсе»</li>
            <li>
              Добавить награды при редактировании записи об участии в конкурсе или посмотреть награды участников конкурса с
              помощью кнопки <IconButton icons={buttonIcons.medal} hasNoHover />
            </li>
            <li>
              Редактировать связь с подразделением с помощью кнопки <IconButton icons={buttonIcons.editRelations} hasNoHover />
            </li>
          </ul>
          <p>
            <strong className="primary">Важно</strong>
          </p>
          <p>При сохранении записи об участии присваивается статус Добавлена.</p>
          <p>
            На вкладке «Документы» обязательно прикрепите документ подтверждающий участие. Ответственное лицо проверит и утвердит
            участие.
          </p>
          <ul>
            <li>
              <strong>Фильтр «Мои участия»</strong> содержит список ваших участий. Если вы создавали запись об участии в конкурсе,
              но не являетесь участником, запись будет отображаться в другом разделе.
            </li>
            <li>
              <strong>Фильтр «Участия по подразделению»</strong> содержит список участий, связанных с вашим подразделением.
            </li>
            <li>
              <strong>Фильтр «Все участия»</strong> отображает список всех участий в конкурсах.
            </li>
            <li>
              <strong>Фильтр «Показывать участия за год»</strong> формирует список участий в конкурсах, сроки проведения которых
              содержат указанный год. По умолчанию выбран текущий год.
            </li>
          </ul>
          <p>
            Если у вас есть вопрос или вы нашли ошибку в Утвержденном участий - напишите Администратору&nbsp;
            <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
          </p>
        </div>
      </ConfirmPopup>
      {isWarningPopupOpen && (
        <BindParticipation
          isWarningPopupOpen={isWarningPopupOpen}
          handleCloseWarningPopup={handleCloseWarningPopup}
          handleAttachParticipation={handleAttachParticipation}
          selectedRow={selectedRow}
          userSystemDepartment={userSystemDepartment}
          eventType="EVENT_CONTEST"
        />
      )}
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
