import { TenderProtocol } from 'types/models/TenderProtocol';

export function getMockTenderProtocol(): TenderProtocol {
  const tenderProtocol: TenderProtocol = {
    id: '',
    number: '',
    tender: null,
    protocolDate: '',
    protocolCommission: null,
    commissionMembers: [],
    documents: [],
    reporter: '',
  };
  return tenderProtocol;
}
