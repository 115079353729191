import React from 'react';

import { Person } from 'types/models';
import { EnumMap } from 'types/models/Table';

import { showNotification } from 'features/Notifications';
import { ConfirmPopup, ListEdit } from 'components';
import { useMainScientistsContentContext } from 'App/ScientistsPage/context';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { LinkedHistoryEntities } from 'features/Form/views/LinkedHistoryEntities';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import useController from './controller';
import { Fields } from './Fields/Fields';
import { validate } from './validate';
import { Color } from 'constants/colors';
import { MergePersonCollection } from 'features/MergePersonCollection';

type Props = {
  person: Person.ScientistPerson | null;
  enumMap: EnumMap;
  disabled: boolean;
  isAddMode?: boolean;
  onChangeEducations(educations: Person.ScientistEducation[]): void;
  onUpdate?: () => void;
};

function Component(props: Props) {
  const { person, onChangeEducations, enumMap, disabled, isAddMode, onUpdate } = props;
  const { isProfile } = usePrivatePageContext();
  const { isMainScientistsContent } = useMainScientistsContentContext();

  const {
    userPermission,
    extraToolbarButtons,
    isDeleteConfirmOpen,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedEducation,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    educationIds,
  } = useController({
    onChangeEducations,
    person,
  });

  return (
    <div>
      <ListEdit
        isRequired
        withMessages
        isFullScreenedTable
        isDeleteConfirmEnabled
        hideToolbar={isAddMode ? false : disabled || !isMainScientistsContent}
        rows={person?.scientistEducations ?? []}
        onChange={onChangeEducations}
        visibleToolbarButtons={['add', 'edit']}
        isToolbarDisabled={isLinkedHistoryPending}
        extraToolbarButtons={extraToolbarButtons}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        onSelectedRowsCallback={handleSelectedRows}
        columns={[
          { label: 'Статус', formatValue: row => row.status.label },
          { label: 'Организация', formatValue: row => row.enterprise },
          { label: 'Специальность', formatValue: row => row.refSpeciality?.label ?? row.speciality ?? '' },
          { label: 'Подразделение', formatValue: row => row.refDepartment?.label ?? row.department ?? '' },
          { label: 'Дата начала', formatValue: row => row.dateReceipt ?? '' },
          { label: 'Дата окончания', formatValue: row => row.dateDismissal ?? '' },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) => (creationSource ? `${creationSource} (${creationDate})` : ''),
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationSource ? `${modificationSource} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletionDate && deletedBy ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        specification={{
          mode: 'customComponent',
          validation: {
            checkIsValid: education => validate(education).every(x => x.isValid),
            onInvalidate: education => {
              const info = validate(education);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
          renderComponent: (education, setEducation, index, mode) => (
            <Fields
              education={education || undefined}
              enumMap={enumMap}
              onChange={setEducation}
              isProfile={isProfile}
              mode={mode}
              userPermission={userPermission}
            />
          ),
        }}
      />
      <ConfirmPopup
        title="Предупреждение"
        text="Вы точно хотите удалить выбранный элемент?"
        isOpen={isDeleteConfirmOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={onDeleteConfirm}
        onClose={onDeleteReset}
        icon="warning"
      />
      <ConfirmPopup
        title="Удаление обучения"
        isOpen={isDeleteHistoryWarningOpen}
        okButtonText="Да"
        resetButtonText="Отмена"
        onConfirm={confirmDeleteHistoryWarning}
        onClose={closeDeleteHistoryWarning}
        icon="warning"
      >
        <span>
          Выбранный на удаление элемент используется в историческом срезе.
          <br />
          Удаление на данный момент невозможно. Вы можете изменить связь в историческом срезе, указав другой элемент. Продолжить?
        </span>
      </ConfirmPopup>
      <LinkedHistoryEntities
        personId={person?.id}
        isOpen={isEntityRelationOpen}
        onClose={closeEntityRelation}
        id={selectedEducation?.id ?? '-1'}
        type={LinkedHistoryType.EDUCATION}
      />
      <MergePersonCollection
        entity="ScientistEducation"
        entityIds={educationIds}
        isOpen={isMergeDuplicatesOpen}
        onClose={handleCloseMergeDuplicatesForm}
        onUpdate={onUpdate}
      />
    </div>
  );
}

export const EducationDetails = React.memo(Component);
