import React, { useCallback, useMemo } from 'react';
import {
  ForeignSecurityDocument,
  SecurityDocumentMember,
  SecurityDocumentMemberTypes,
  SecurityDocumentTypes,
} from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { SecurityDocumentMemberType } from 'utils/Enums';

type Props = {
  foreignSecurityDocument?: ForeignSecurityDocument | null;
  setForeignSecurityDocument: React.Dispatch<React.SetStateAction<ForeignSecurityDocument | null | undefined>>;
};

export function useController(props: Props) {
  const { setForeignSecurityDocument, foreignSecurityDocument } = props;

  const { enumMap } = useAppDataContext();

  const documentTypeOptions = enumMap.SecurityDocumentType.values.filter(
    x => x.value === SecurityDocumentTypes.EVIDENCE || x.value === SecurityDocumentTypes.PATENT,
  );

  const handleAuthorsChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      setForeignSecurityDocument(prevPatent => {
        const members = prevPatent?.members?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.APPLICANT) || [];
        return { ...prevPatent!, members: [...members, ...value] };
      });
    },
    [setForeignSecurityDocument],
  );

  const handleApplicantChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      setForeignSecurityDocument(prevPatent => {
        const members = prevPatent?.members?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.AUTHOR) || [];
        return { ...prevPatent!, members: [...members, ...value] };
      });
    },
    [setForeignSecurityDocument],
  );

  const applicantPersonList = useMemo(
    () => foreignSecurityDocument?.members?.filter(m => m.type?.value === SecurityDocumentMemberType.APPLICANT) || [],
    [foreignSecurityDocument],
  );

  return {
    handleAuthorsChange,
    documentTypeOptions,
    handleApplicantChange,
    applicantPersonList,
  };
}
