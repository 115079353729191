import React, { useMemo } from 'react';

import { IconButtonProps, Toolbar as SharedToolbar } from 'components';

function Toolbar() {
  const buttons = useMemo<IconButtonProps[]>(() => [], []);

  return <SharedToolbar buttons={buttons} />;
}

export { Toolbar };
