import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as BackendAPI from 'services/BackendAPI';

import { validateRegistration } from 'features/Auth/validate';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { TextInputMode } from 'components';

const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_LOCAL;
const domain = window.location.origin;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function userAuthorization(data: object, rememberMe: boolean) {
  const reqOpts: any = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
    redirect: 'follow',
  };

  const url = isDev ? '' : `${domain}${process.env.REACT_APP_AUTH_PORT}`;
  const response = await fetch(`${url}${process.env.REACT_APP_AUTH_TOKEN}`, reqOpts);

  if (response.status === 200) {
    let token = '';
    if (isDev) {
      token = response.headers.get('Auth') || '';
    } else {
      const contentType = response.headers.get('content-type');
      if (contentType === null) {
        token = await response.text();
      }
    }

    if (token) {
      if (rememberMe) {
        localStorage.setItem('token', token);
      }
      return { type: 'ok', token };
    }

    return { type: 'error', token: null };
  }
  return {
    type: 'errorConnect',
    token: null,
  };
}

export function useController() {
  const [isSentRegister, setIsSentRegister] = useState(false);
  const [login, setLogin] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [secondname, setSecondname] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState('');
  const [loader, setLoader] = useState<boolean>(false);
  const [loginChanged, setLoginChanged] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [passwordType, setPasswordType] = useState<string>(TextInputMode.password);
  const [passwordRepeatType, setPasswordRepeatType] = useState<string>(TextInputMode.password);

  const history = useHistory();

  const { setUserToken, fetchInitialData, userToken } = useAppDataContext();

  const errAuth = 'Введены неверные логин или пароль';
  const errConnect = 'Ошибка соединения. Пожалуйста попробуйте позже или свяжитесь с разработчиком платформы.';

  const writeLogin = useCallback((value: string) => {
    setLoginChanged(true);
    setLogin(value);
  }, []);

  const { methods: register } = BackendAPI.useBackendAPI('Register', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Регистрация прошла успешно', theme: 'success' });
      setIsSentRegister(true);
    },
  });

  const handleRegisterSubmit = useCallback(() => {
    const errors = validateRegistration({ surname, firstname, secondname, login, password, passwordRepeat, birthDate, email });
    const errorValues = Object.values(errors);
    if (errorValues.length) {
      setFieldErrors({ ...fieldErrors, ...errors });
      showNotification({
        message: errorValues.reduce<string>((accum, current) => `${accum}${accum ? '</br>' : ''}${current}`, ''),
        theme: 'danger',
      });
    } else {
      setLoader(true);
      register.callAPI(
        { firstName: firstname, secondName: secondname, surname, login, password, email, birthDate: birthDate! },
        {
          onSuccessfullCall: () => {
            setLoader(false);
            setIsSentRegister(true);
            window.location.hash = 'success';
          },
        },
      );
    }
  }, [
    login,
    password,
    passwordRepeat,
    surname,
    firstname,
    secondname,
    email,
    birthDate,
    register,
    setIsSentRegister,
    fieldErrors,
  ]);

  const updateErrorField = (obj: Record<string, string>) => {
    const objKey: string = Object.keys(obj)[0];
    const validateRes: Record<string, string> = validateRegistration(obj);
    const result: Record<string, string> = {};
    result[objKey] = validateRes[objKey] || '';
    setFieldErrors(Object.assign(fieldErrors, result));
  };

  const validate = useCallback(
    fields => {
      setFieldErrors(validateRegistration(fields));
      if (Object.keys(fieldErrors).length > 0) {
        setTextError('Пожалуйста, заполните все поля');
      } else {
        setTextError('');
      }
    },
    [setTextError, fieldErrors],
  );

  const handleLoginSubmit = () => {
    const data: any = JSON.stringify({ login, password });

    setLoader(true);
    const userPromise = userAuthorization(data, rememberMe);
    userPromise.then(res => {
      if (res.type === 'error') {
        setError(true);
        setTextError(errAuth);
      } else if (res.type === 'errorConnect') {
        setTextError(errConnect);
      } else if (res.type === 'ok') {
        setUserToken(res.token!);
        fetchInitialData(res.token!);
        history.push('/');
      }
    });
    setLoader(false);
  };

  useEffect(() => {
    if (userToken) history.push('/');
    // eslint-disable-next-line
  }, []);

  return {
    writeLogin,
    loginChanged,
    validate,
    firstname,
    setFirstname,
    secondname,
    setSecondname,
    surname,
    setSurname,
    handleRegisterSubmit,
    textError,
    error,
    fieldErrors,
    login,
    setLogin,
    email,
    setEmail,
    birthDate,
    setBirthDate,
    password,
    setPassword,
    passwordRepeat,
    setPasswordRepeat,
    handleLoginSubmit,
    rememberMe,
    setRememberMe,
    loader,
    isSentRegister,
    setIsSentRegister,
    updateErrorField,
    passwordType,
    setPasswordType,
    passwordRepeatType,
    setPasswordRepeatType,
  };
}
