import { Table } from 'types/models';

import { Filters } from './filters';
import { Toolbar } from './Toolbar/Toolbar';
import { Form } from './Form/Form';

import { makeUseCustomController, State } from './makeUseCustomController';
import { getEnumItemLabel } from 'utils/Helpers';
import { ProjectType } from 'utils/Enums';
import { EnumMap } from 'types/models/Table';

export function GetGovermentContractsList(enums: EnumMap): Table.Specification<State> {
  return {
    apiID: 'GetGovermentContractsList',
    header: {
      firstLevel: {
        title: `Список проектов типа: ${getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enums)}`,
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    useCustomController: makeUseCustomController(),
    NeighborTableComponent: Form,
  };
}
