import { useMemo, useState } from 'react';

import { useStream } from 'StreamRx';
import * as BackendAPI from 'services/BackendAPI';
import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';

import { Column, CurrentSetting } from 'types/models/Table';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams as panelStreams } from '../streams';
import { CrudCallbackArguments, Operation } from 'features/Table/ThirdLevelHeader/TemplatesPanel/types';
import { useAppDataContext } from 'features/AppData/context';

export type State = {
  isChangeFormOpen: boolean;
  isSettingEditing: boolean;
  selectedRow: Record<string, string> | undefined;
  currentSetting: CurrentSetting | null;
  relatedColumns: Table.Column[];
};

export type Props = {
  currentSetting: CurrentSetting | null;
  gridName: string;
  isTableExtended: boolean;
  columns: Column[];
  crudCallback: ({ operation, settingName, settingAuthor }: CrudCallbackArguments) => Promise<void>;
};

export function makeUseCustomController({ currentSetting, gridName, isTableExtended, columns, crudCallback }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    const tableStreams = useLocalTableStreams();
    const { userPermission } = useAppDataContext();
    const relatedTableSettingName = currentSetting?.name;
    const [isChangeFormOpen, setIsChangeFormOpen] = useState(false);
    const [isSettingEditing, setIsSettingEditing] = useState(false);

    const { methods: deleteSettingAPIMethods } = BackendAPI.useBackendAPI('DeleteGridSetting', {
      onSuccessfullCall: () => {
        showNotification({ message: 'Шаблон успешно удален', theme: 'success' });
        tableStreams.reloadTable.push();
      },
    });

    const { methods: addNewSettingAPIMethods } = BackendAPI.useBackendAPI('SaveGridSetting');

    useStream(
      () => panelStreams.addNewGridSetting,
      ({ columns: newColumns, purpose, settingName, editedSettingID, shared }) => {
        addNewSettingAPIMethods.callAPI(
          {
            gridName: gridName,
            columns: newColumns,
            settingName,
            purpose,
            settingID: editedSettingID,
            shared,
          },
          {
            onSuccessfullCall: async () => {
              if (editedSettingID) {
                showNotification({ message: 'Шаблон успешно отредактирован', theme: 'success' });
                await crudCallback({ operation: Operation.ADD, settingName, settingAuthor: userPermission?.userLogin ?? '' });
              } else {
                showNotification({ message: 'Шаблон успешно добавлен', theme: 'success' });
                await crudCallback({ operation: Operation.EDIT, settingName, settingAuthor: userPermission?.userLogin ?? '' });
              }
              tableStreams.reloadTable.push();
            },
          },
        );
      },
      [isTableExtended, relatedTableSettingName],
    );

    useStream(
      () => panelStreams.deleteTableSetting,
      ({ settingName }) => {
        deleteSettingAPIMethods.callAPI(
          {
            gridName,
            settingName,
          },
          {
            onSuccessfullCall: async () => {
              await crudCallback({ operation: Operation.DELETE, settingName, settingAuthor: userPermission?.userLogin ?? '' });
            },
          },
        );
      },
      [deleteSettingAPIMethods, isTableExtended],
    );

    useStream(
      () => panelStreams.toggleChangeForm,
      () => {
        setIsChangeFormOpen(prev => !prev);
        tableStreams.toggleSecondLevelPanel.push();
      },
      [setIsChangeFormOpen],
    );

    useStream(
      () => panelStreams.toggleSettingEditing,
      isEditing => setIsSettingEditing(isEditing),
      [setIsSettingEditing],
    );

    const selectedRow = useMemo(() => {
      if (selectedRows.length > 0) {
        return selectedRows[0];
      }
    }, [selectedRows]);

    return {
      isChangeFormOpen,
      isSettingEditing,
      selectedRow,
      relatedColumns: columns,
      currentSetting,
    };
  };
}
