import * as BackendAPI from 'services/BackendAPI';
import { useCallback } from 'react';
import { Table } from 'types/models';
import { Item } from 'types/models/common';

const useController = () => {
  const { methods: getRequestAPI } = BackendAPI.useBackendAPI('GetRequest');

  const onSelectRow = useCallback(
    (row: Table.Entry, onSuccessLoad: (loadedRow: Item<string>) => void) => {
      getRequestAPI.callAPI(
        {
          id: row.id,
        },
        {
          onSuccessfullCall({ data }) {
            const programShortName = data.tender?.program?.shortName ?? '';
            const kindTradeLabel = data.tender?.kindTrade?.label ?? '';
            const requestCode = data.code;
            const preparedLabel = `Заявка № ${requestCode} на конкурс ${programShortName} (${kindTradeLabel})`;
            onSuccessLoad({ label: preparedLabel, value: row.id });
          },
        },
      );
    },
    [getRequestAPI],
  );

  return {
    onSelectRow,
  };
};

export default useController;
