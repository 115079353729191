import React from 'react';
import { block } from 'bem-cn';

import { RequiredBadge, Tooltip } from 'components';

import './style.scss';

const b = block('form-component-field');

type LabelSize = 'fit' | 'small';
type FieldSize = 'fit' | 'small';
type ContentSize = 'fit' | 'small';
type LabelStyle = 'bold' | 'thin';
type Align = 'center' | 'right';

type Props = {
  align?: Align;
  label?: string;
  labelSize?: LabelSize;
  labelStyle?: LabelStyle;
  fieldSize?: FieldSize;
  contentSize?: ContentSize;
  isRequired?: boolean;
  tooltip?: string;
  labelWidth?: number;
  children?: (React.ReactNode | JSX.Element | false)[] | JSX.Element | null | false | string;
  hasLeftAlign?: boolean;
  isHidden?: boolean;
};

export function Field(props: Props) {
  const {
    label,
    isRequired,
    children,
    tooltip,
    labelSize,
    fieldSize,
    labelStyle,
    labelWidth,
    contentSize,
    align,
    hasLeftAlign = false,
    isHidden,
  } = props;
  return (
    <>
      {!isHidden && (
        <div className={b({ small: fieldSize === 'small', center: align === 'center', right: align === 'right' })}>
          {label !== undefined && (
            <div
              className={b('label', {
                fit: labelSize === 'fit',
                small: labelSize === 'small',
                thin: labelStyle === 'thin',
                bold: labelStyle === 'bold',
                left: hasLeftAlign,
              })}
              style={{ width: labelWidth }}
            >
              {label}
              {tooltip && (
                <span className={b('label-tooltip')}>
                  <Tooltip text={tooltip} />
                </span>
              )}
              {isRequired && <RequiredBadge />}
            </div>
          )}

          <div
            className={b('content', {
              'no-labeled': !label || labelSize === 'fit',
              fit: contentSize === 'fit',
              small: contentSize === 'small',
            })}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
}
