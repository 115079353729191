import { GetCompilationListByTypeRequest } from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';

import { LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  requestData: GetCompilationListByTypeRequest;
  eventId?: string;
  hasSelectButton?: boolean;
};

export const GetCompilationListByTypeSpecification = ({
  requestData,
  eventId,
  hasSelectButton,
}: Props): Table.Specification<State> => ({
  apiID: 'GetCompilationListByType',
  requestData,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
      hasSelectButton,
    },
  },
  useCustomController: makeUseCustomController({ eventId, compilationTypeCode: requestData.type }),
});
