import * as XML from 'xml-js';
import * as T from './types';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { Settings } from 'types/models/common';
import { getMaybeField, getText, makeParametersXMLElement, makeTableRequestXMLConverter } from '../commonConverters';
import { convertProjectFeedline, convertProject, convertCheckResult, getPerformersFromSecurityDocuments } from './converters';
import { convertProjectToRequest } from './requestConverters';
import { ProjectType } from 'utils/Enums';

export type ProjectEditPartialType = {
  projectIds: string[];
  mode: string;
  elementIds: string[];
};

export const tableProjectsConfigurations = {
  GetProjectList: makeBackendTableAPIConfiguration(
    `GetProjectList?type=${ProjectType.NIR}`,
    makeTableRequestXMLConverter('GetProjectList', ({ panelState, isTableExtended }: T.GetProjectsRequest) =>
      makeParametersXMLElement(
        [
          { name: 'type', value: ProjectType.NIR },
          { name: 'extended', value: (!!isTableExtended).toString() },
          { name: 'withoutStateRegistration', value: (!!panelState.isWithoutStateRegistration).toString() },
        ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ),
    ),
  ),
  GetProjectPublicationList: makeBackendTableAPIConfiguration(
    'GetProjectPublicationList',
    makeTableRequestXMLConverter('GetProjectPublicationList', ({ requestData }: T.GetProjectPublicationListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectDissertationList: makeBackendTableAPIConfiguration(
    'GetProjectBaseDissertationList',
    makeTableRequestXMLConverter('GetProjectBaseDissertationList', ({ requestData }: T.GetProjectDissertationListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectParticipationEventList: makeBackendTableAPIConfiguration(
    'GetProjectParticipationEventList',
    makeTableRequestXMLConverter(
      'GetProjectParticipationEventList',
      ({ requestData }: T.GetProjectParticipationEventListRequest) =>
        makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetProjectSecurityDocumentList',
    makeTableRequestXMLConverter('GetProjectSecurityDocumentList', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectPatentResearchList: makeBackendTableAPIConfiguration(
    'GetProjectPatentResearchList',
    makeTableRequestXMLConverter('GetProjectPatentResearchList', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectFeedlineList: makeBackendTableAPIConfiguration(
    'GetProjectFeedlineList',
    makeTableRequestXMLConverter('GetProjectFeedlineList', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectCodeChangesInProject: makeBackendTableAPIConfiguration(
    'GetProjectCodeChangesInProject',
    makeTableRequestXMLConverter('GetProjectCodeChangesInProject', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement(
        [{ name: 'projectId', value: requestData?.projectId }].concat(
          requestData?.projectCodeId ? [{ name: 'projectCodeId', value: requestData.projectCodeId }] : [],
        ),
      ),
    ),
  ),
  GetProjectBoAccompliceList: makeBackendTableAPIConfiguration(
    'GetProjectBoAccompliceList',
    makeTableRequestXMLConverter('GetProjectBoAccompliceList', ({ requestData }: T.GetProjectBoAccompliceListRequest) =>
      makeParametersXMLElement([
        { name: 'projectId', value: requestData?.projectId ?? '-1' },
        { name: 'projectCodeId', value: requestData?.projectCodeId ?? '-1' },
      ]),
    ),
  ),
};

const configurations = {
  AcceptPublicationProject: makeBackendAPIConfiguration({
    id: 'AcceptPublicationProject',
    endpoint: '/msa/service/commands/AcceptPublicationProject',
    convertInputToXMLElement: ({ id }: T.AcceptRequest) => ({
      Request: { _attr: { commandName: 'AcceptPublicationProject' }, PublicationProject: { _attr: { id: id } } },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  AcceptParticipationProject: makeBackendAPIConfiguration({
    id: 'AcceptParticipationProject',
    endpoint: '/msa/service/commands/AcceptParticipationProject',
    convertInputToXMLElement: ({ id }: T.AcceptRequest) => ({
      Request: { _attr: { commandName: 'AcceptParticipationProject' }, ParticipationProject: { _attr: { id: id } } },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  AcceptEventProject: makeBackendAPIConfiguration({
    id: 'AcceptEventProject',
    endpoint: '/msa/service/commands/AcceptEventProject',
    convertInputToXMLElement: ({ id }: T.AcceptRequest) => ({
      Request: { _attr: { commandName: 'AcceptEventProject' }, EventProject: { _attr: { id: id } } },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  GetProject: makeBackendAPIConfiguration({
    id: 'GetProject',
    endpoint: '/msa/service/commands/GetProject',
    convertInputToXMLElement: ({
      id,
      modules,
      type,
      nirRequestId,
      securityDocumentContractId,
      programRequestId,
    }: {
      id?: string;
      modules?: string[];
      type?: ProjectType;
      nirRequestId?: string;
      securityDocumentContractId?: string;
      programRequestId?: string;
    }) => ({
      Request: {
        _attr: { commandName: 'GetProject' },
        Project: {
          _attr: { id, ...(modules ? { modules: modules.join(', ') } : {}) },
          ...getMaybeField('Type', type),
          ...(!!nirRequestId && { NirRequest: { _attr: { id: nirRequestId } } }),
          ...(!!securityDocumentContractId && { SecurityDocumentContract: { _attr: { id: securityDocumentContractId } } }),
          ...(!!programRequestId && { ProgramRequest: { _attr: { id: programRequestId } } }),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertProject(response.Response.Project, settings),
  }),
  SaveProject: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.NIR}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  CheckActiveProjectCodesForFinancing: makeBackendAPIConfiguration({
    id: 'CheckActiveProjectCodesForFinancing',
    endpoint: '/msa/service/commands/CheckActiveProjectCodesForFinancing',
    convertInputToXMLElement: ({ projectId, projectFinancingId }: T.CheckActiveProjectCodesForFinancing) => ({
      Request: {
        _attr: { commandName: 'CheckActiveProjectCodesForFinancing' },
        CheckRequest: {
          _attr: { projectId, projectFinancingId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertCheckResult(response),
  }),
  CheckActiveProjectCodesForDepartment: makeBackendAPIConfiguration({
    id: 'CheckActiveProjectCodesForDepartment',
    endpoint: '/msa/service/commands/CheckActiveProjectCodesForDepartment',
    convertInputToXMLElement: ({ projectId, projectDepartmentId }: T.CheckActiveProjectCodesForDepartment) => ({
      Request: {
        _attr: { commandName: 'CheckActiveProjectCodesForDepartment' },
        CheckRequest: {
          _attr: { projectId, projectDepartmentId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertCheckResult(response),
  }),
  SaveEconomicAgreement: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.ECONOMIC_AGREEMENT}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  SaveGovermentContract: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.GOVERMENT_CONTRACT}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  SaveGrant: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.GRANT}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  GetProjectFeedline: makeBackendAPIConfiguration({
    id: 'GetProjectFeedline',
    endpoint: '/msa/service/commands/GetProjectFeedline',
    convertInputToXMLElement: ({ feedlineId }: T.GetProjectFeedline) => ({
      Request: {
        _attr: { commandName: 'GetProjectFeedline' },
        ProjectFeedline: { _attr: { id: feedlineId } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertProjectFeedline(response.Response.ProjectFeedline),
  }),
  SaveProjectUserFeedline: makeBackendAPIConfiguration({
    id: 'SaveProjectUserFeedline',
    endpoint: '/msa/service/commands/SaveProjectUserFeedline',
    convertInputToXMLElement: ({ feedline, projectId }: T.SaveProjectFeedline) => ({
      Request: {
        _attr: { commandName: 'SaveProjectUserFeedline' },
        ProjectFeedline: {
          ...(feedline.id ? { _attr: { id: feedline.id } } : {}),
          Message: { _attr: { id: feedline.message?.id ?? '' } },
          Project: { _attr: { id: projectId } },
          ...getMaybeField('Detail', feedline.detail),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetPerformersFromSecurityDocuments: makeBackendAPIConfiguration({
    id: 'GetSecurityDocumentAuthors',
    endpoint: '/msa/service/commands/GetSecurityDocumentAuthors',
    convertInputToXMLElement: ({ projectId }: T.GetSecurityDocumentAuthorsRequest) => ({
      Request: {
        _attr: { commandName: 'GetSecurityDocumentAuthors' },
        ScienceProject: {
          _attr: { id: projectId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      getPerformersFromSecurityDocuments(response.Response.SecurityDocuments?.SecurityDocument ?? [], settings),
  }),
  DeleteProject: makeBackendAPIConfiguration({
    id: 'DeleteProject',
    endpoint: '/msa/service/commands/DeleteProject',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'DeleteProject' },
        DeleteProject: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

const projectEditConfiguration = (name: string) =>
  makeBackendAPIConfiguration({
    id: name,
    endpoint: `/msa/service/commands/${name}`,
    convertInputToXMLElement: (props: ProjectEditPartialType) => ({
      Request: {
        _attr: {
          commandName: name,
        },
        ProjectsGroup: {
          Projects: {
            Project: props.projectIds.map(x => ({
              _attr: x ? { id: x } : {},
            })),
          },
          Data: {
            Elements: {
              _attr: { type: props.mode },
              Element: props.elementIds.map((x, index) => ({
                Element: {
                  _attr: x ? { id: x } : {},
                },
                Position: index,
              })),
            },
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  });

const groupProjectsConfigurations = {
  EditGroupProjectsCriticalTechnologies: projectEditConfiguration('EditGroupProjectsCriticalTechnologies'),
  EditGroupProjectsGrntis: projectEditConfiguration('EditGroupProjectsGrntis'),
  EditGroupProjectsNtrStrategies: projectEditConfiguration('EditGroupProjectsNtrStrategies'),
  EditGroupProjectsPnis: projectEditConfiguration('EditGroupProjectsPnis'),
  EditGroupProjectsPnmitrs: projectEditConfiguration('EditGroupProjectsPnmitrs'),
  EditGroupProjectsPnrs: projectEditConfiguration('EditGroupProjectsPnrs'),
  EditGroupProjectsScienceDomainInterrests: projectEditConfiguration('EditGroupProjectsScienceDomainInterrests'),
  EditGroupProjectsTechnologyPlatforms: projectEditConfiguration('EditGroupProjectsTechnologyPlatforms'),
  EditGroupProjectsUdks: projectEditConfiguration('EditGroupProjectsUdks'),
  EditGroupProjectsLksetss: projectEditConfiguration('EditGroupProjectsLksetss'),
  EditGroupProjectsOkved: projectEditConfiguration('EditGroupProjectsOkved'),
  EditGroupProjectsScienceBrunches: projectEditConfiguration('EditGroupProjectsScienceBrunches'),
  EditGroupProjectsNationalProjectExpenseCode: projectEditConfiguration('EditGroupProjectsNationalProjectExpenseCode'),
};

export const projectEditPartialConfigurations = {
  ...tableProjectsConfigurations,
  ...configurations,
  ...groupProjectsConfigurations,
};
