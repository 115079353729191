import { Project } from 'types/models';

import { EditableIndicator } from '../model';
import { ProjectType } from 'utils/Enums';
import { Item } from 'types/models/common';
import { EMPTY_FILE } from 'utils/Constants';

export function getMockDepartment(): Project.Department {
  return {
    id: null,
    governance: null,
    unit: null,
  };
}

export function getMockFinancing(): Project.Financing {
  return {
    id: null,
    source: null,
    type: null,
    amount: '0',
    factAmount: '',
    isNds: false,
  };
}

export function getMockStage(): Project.Stage {
  return {
    amountMain: '0.00',
    amountFact: '0.00',
    mainNdsPercent: '0.00',

    amountCofinancing1: '0.00',
    cofinancing1NdsPercent: '0.00',

    amountCofinancing2: '0.00',
    cofinancing2NdsPercent: '0.00',

    amountLocal: '0.00',
    localNdsPercent: '0.00',

    amountAccomplice: '0.00',
    accompliceNdsPercent: '0.00',

    amountExternalAccomplices: '0.00',
    endDate: '',
    id: null,
    indicators: [],
    indices: [],
    isLocked: false,
    isPatentResearchPlanned: false,
    number: '',
    reports: [],
    startDate: '',

    annotation: '',
    expectedResults: '',
    goal: '',
    kindWork: null,
    name: '',
    task: '',
    workDescription: '',
  };
}

export function getMockEditableIndicator(): EditableIndicator {
  return {
    id: null,
    ref: null,
    fact: '0',
    note: '',
    plan: '0',
    year: '',
    stageNumber: '',
  };
}

export function getMockStateRegistrationReport(): Project.StateRegistrationReport {
  return {
    id: null,
    file: EMPTY_FILE,
    reportType: null,
    citisDate: '',
    expectedResults: '',
    interdisciplinaryAproachJustification: '',
    keyWords: '',
    referat: '',
    reportArchiveNumber: '',
    reportCustomerSendDate: '',
    reportIkNumber: '',
    reportName: '',
    stageName: '',
    stageNumber: '',
    stageId: null,
  };
}

export function getMockPerformer(): Project.Performer {
  return {
    id: null,
    person: null,
    fio: '',
    createdBy: '',
    createdDate: '',
    stages: [],
    jobPeriods: [],
    paymentMethods: '',
  };
}

export function getMockJobPeriod(): Project.JobPeriod {
  return {
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    historyLabel: '',
    id: null,
    job: null,
    person: null,
    rank: null,
    endDate: '',
    endDateOrder: null,
    role: null,
    startDate: '',
    startDateOrder: null,
    note: '',
  };
}

export function getMockPerformerStage(): Project.PerformerStage {
  return {
    id: null,
    stage: null,
    jobDescription: '',
    approved: false,
    approvements: [],
    payments: [],
    paymentMethods: '',
  };
}

export function getMockRefElement(): Project.RefElement {
  return {
    id: null,
    createdBy: '',
    createdDate: '',
    position: '0',
    ref: null,
    isActual: false,
    isProject: false,
  };
}

export function getMockStageReport(): Project.StageReport {
  return {
    id: null,
    udk: null,
    file: EMPTY_FILE,
    type: null,
    status: null,
    keyWords: '',
    name: '',
    number: '',
    ontiNumber: '',
    referat: '',
    registrationDate: '',
    registrationOntiDate: '',
    reportDate: '',
    reportOntiDate: '',
    sendCustomerDate: '',
  };
}

type GetMockProjectType = {
  projectTypeItem?: Item<ProjectType>;
};

export function getMockProject({ projectTypeItem }: GetMockProjectType = {}): Project.Project {
  return {
    activityKind: null,
    annotation: '',
    contestRequests: [],
    contractKind: null,
    criticalTechnologies: [],
    nirRequest: null,
    customers: [],
    departments: [],
    documents: [],
    endDate: '',
    expectedResults: '',
    expendituresBudgets: null,
    financialNote: '',
    financings: [],
    financingsByYear: [],
    partners: [],
    goal: '',
    grntis: [],
    id: null,
    interdisciplinaryAproachJustification: '',
    isBuyMaterial: false,
    isFinancingStopped: false,
    stopMoneyReason: null,
    isNirRequestDataConsumed: false,
    isSpecTheme: false,
    isYouth: false,
    keyWords: '',
    kindResearch: null,
    lksetss: [],
    name: '',
    nationalProjectExpenseCode: null,
    ntiEteTechnologies: [],
    ntiMarkets: [],
    ntrStrategies: [],
    number: '',
    numberPrefix: null,
    oecds: [],
    okved: null,
    okvedIncome: null,
    overheadPercent: '0',
    performers: [],
    domainKnowledges: [],
    periodNote: '',
    pnis: [],
    pnmitrs: [],
    pnrs: [],
    program: null,
    programEvent: null,
    programRequest: null,
    projectCodes: [],
    projectContractStatus: null,
    result: '',
    scienceBrunches: [],
    scienceDomainInterrests: [],
    scientificGroundwork: '',
    specification: null,
    stages: [],
    startDate: '',
    stateNumber: '',
    stateRegistrationAnnotation: '',
    stateRegistrationDate: '',
    stateRegistrationInterdisciplinaryAproachJustification: '',
    stateRegistrationReports: [],
    stateRegistrationScientificGroundwork: '',
    status: '',
    techPlatforms: [],
    workSpaces: [],
    type: projectTypeItem || null,
    udks: [],
    patentResearches: [],
    internationalProgram: null,
    foreignEnterprise: null,
    partnerCountries: [],
    securityDocumentContract: null,
    resourceUsageDepartments: [],

    isRequestProvision: false,
    requestProvisionAmount: '0.00',
    requestProvisionType: null,
    requestProvisionNote: '',

    isContractProvision: false,
    contractProvisionAmount: '0.00',
    contractProvisionType: null,
    contractProvisionNote: '',
    vaks: [],
    nirRequestDataCopyDetail: '',
    programRequestDataCopyDetail: '',
    purchaseRequests: [],
    securityDocuments: [],
  };
}

export const getMockResourceUsageDepartment = (): Project.ResourceUsageDepartment => ({
  id: null,
  type: null,
  department: null,
  note: '',
});

export function getMockProjectPartner(): Project.Partner {
  return {
    contractNumber: '',
    contractType: null,
    endDate: '',
    financings: [],
    id: null,
    kindWork: '',
    partner: null,
    role: null,
    startDate: '',
    note: '',
    contractName: '',
    leaderAppointment: '',
    leaderContacts: '',
    leaderDegree: '',
    leaderFullName: '',
    leaderJobEnterprise: null,
    leaderRank: '',
  };
}

export function getMockPartnerFinancing(): Project.PartnerFinancing {
  return {
    id: null,
    amount: '',
    year: '',
  };
}

export function getMockContestRequest(): Project.ContestRequest {
  return {
    id: null,
    status: null,
    createdBy: '',
    projectNote: '',
    requestStageEndDate: '',
    requestStageStartDate: '',
    projectStageId: null,
    year: '',
    type: null,
    dataCopyDetail: '',
  };
}
