import { Form, Table } from 'types/models';
import { useCallback, useMemo, useState } from 'react';
import { getIsCanEdit } from 'utils/Helpers/feedline/getIsCanEdit';
import { getIsCanDelete } from 'utils/Helpers/feedline/getIsCanDelete';
import { ReferenceItem } from 'components';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams } from '../streams';

type Props = {
  tableState: Table.State;
  publicationMessage: Form.MagazineMessage;
  messageLabel: Form.MessageLabel;
};

const useController = ({ tableState, publicationMessage, messageLabel }: Props) => {
  const tableStreams = useLocalTableStreams();
  const formContext = useFormContext();
  const eventId = formContext.look.id;
  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const isDeleteButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanDelete(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleAddButtonClick = useCallback(() => {
    streams.toggleMessageForm.push();
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    streams.clearMessageState.push();
    streams.clearMessageLabelState.push();
    streams.toggleMessageForm.push();
  }, []);

  const handleMessageChange = useCallback((message: string) => {
    streams.changeMessageState.push({
      message,
    });
  }, []);

  const handleMessageLabelChange = useCallback((label: ReferenceItem) => {
    streams.changeMessageLabelState.push(label);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    const editingMessage = tableState.selectedRows[0];
    streams.changeMessageState.push({
      id: editingMessage.id,
      message: editingMessage.Detail,
    });
    streams.changeMessageLabelState.push({
      id: editingMessage['id:Message'],
      label: editingMessage.Message,
    });
    streams.toggleMessageForm.push();
  }, [tableState.selectedRows]);

  const handleFormSubmit = useCallback(() => {
    streams.addNewGridSetting.push({
      message: publicationMessage.message,
      messageId: publicationMessage.id,
      labelId: messageLabel.id,
      eventId: eventId || '',
    });
    streams.toggleMessageForm.push();
  }, [publicationMessage.message, publicationMessage.id, messageLabel.id, eventId]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0].id,
      command: 'DeleteEntity',
      deletedItemPropName: 'EventFeedline',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  return {
    handleAddButtonClick,
    handleEditButtonClick,
    handleDeleteButtonClick,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    handleFormSubmit,
    handleTemplateCloseAddForm,
    handleMessageChange,
    handleMessageLabelChange,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
  };
};

export default useController;
