import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isAddButtonDisabled,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  } = useController({ tableState });

  const { settings } = useAppDataContext();

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.ForeignSecurityDocumentViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.ForeignSecurityDocumentEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
      {!isAddButtonDisabled && (
        <modalTemplates.ForeignSecurityDocumentAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
        />
      )}
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранный документ? (${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ConfirmPopup
        title={`Помощь в разделе Реестр ОИС не аффилированных с ${settings?.organization?.shortName}`}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            Раздел содержит реестр добавленных пользователями личного кабинета сведения о своих авторских патентах и
            свидетельствах на изобретения, промышленные образцы, базы данных, программные продукты и прочие объекты, в которых
            университету не принадлежит право собственности.
          </p>
          <p>
            Вы можете добавить сведения о патентах и свидетельствах, переданных Университету, по лицензионному договору, с целью
            формирования Продукта для коммерциализации.
          </p>
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
