import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { ProjectCodeFormLook } from 'types/models/Form/look';
import { Modal } from 'components';

type ProjectCodeAddTemplateProps = {
  projectId: string;
} & F.TemplateProps;

const ProjectCodeAddTemplate: React.FC<ProjectCodeAddTemplateProps> = ({
  isOpen,
  onClose,
  projectId,
  relatedTableState,
  name,
}: ProjectCodeAddTemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: ProjectCodeFormLook = {
    apiID: 'GetProjectCode',
    template: 'ProjectCodeForm',
    projectId,
    relatedTableState,
    name,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ProjectCodeAddTemplate);
