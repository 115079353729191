import React from 'react';

import { block } from 'bem-cn';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import { Line } from './Line';

import './style.scss';
import { FormComponent } from 'components';

const b = block('form-event');
const performance = block('performance-indicators');

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function PerformanceIndicators(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;
  const amountStages = nirRequest?.stages.length ?? 0;

  const renderTitleLine = (type: 'indices' | 'indicators') => {
    const titleStr = type === 'indices' ? 'Название показателя результативности' : 'Название индикатора результативности';
    return (
      <>
        <div className={performance('column', { title: true })}>{titleStr}</div>
        <div className={performance('column', { title: true })}>План 1 этапа</div>
        {amountStages > 1 && <div className={b('column', { title: true })}>План 2 этапа</div>}
      </>
    );
  };

  const indices = nirRequest?.indices ?? [];
  const indicators = nirRequest?.indicators ?? [];

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <div className={performance('grid', { 'two-level': amountStages > 1 })}>
          {renderTitleLine('indices')}
          {indices.map((x, index) => (
            <Line
              key={index}
              disabled={disabled}
              indicator={x}
              indicators={indices}
              setIndicators={makeChangeHandler('indices')}
              amountStages={amountStages}
            />
          ))}
        </div>
      </FormComponent.Column>
      <FormComponent.Column>
        <div className={performance('grid', { 'two-level': amountStages > 1 })}>
          {renderTitleLine('indicators')}
          {indicators.map((x, index) => (
            <Line
              key={index}
              indicator={x}
              indicators={indicators}
              setIndicators={makeChangeHandler('indicators')}
              amountStages={amountStages}
              disabled={disabled}
            />
          ))}
        </div>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
