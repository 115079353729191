import React, { memo } from 'react';
import { setup } from 'bem-cn';

import { Button, ButtonMode, ListEditTable } from 'components';

import { RecordStatus as RS } from 'types/models';
import { Department } from 'types/models/Participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { formatAuthor } from '../MembersList/helpers';
import useController from './controller';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('approve-participation');

type Props = {
  id: string;
  type: string;
  onClose: () => void;
  afterSubmit?: ({ nextRecordStatus }: { nextRecordStatus: RS.Status }) => void;
};

const ApproveParticipationComponent = ({ id, type, onClose, afterSubmit }: Props) => {
  const { handleApproveParticipation, participationData, departmentColumns } = useController({
    onClose,
    id,
    afterSubmit,
  });
  return (
    <div className={b()}>
      <ul className={b('prop-list')}>
        <li className={b('prop')}>
          {type === ParticipationTypes.PARTICIPATION_CONFERENCE.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Конференция: ${participationData?.event ? `${participationData?.event?.fullName} (ID: ${participationData?.event?.id}. Статус записи: ${participationData?.event?.status})` : ''}`}
              </span>
            </>
          )}
          {type === ParticipationTypes.PARTICIPATION_CONCERT.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Творческое мероприятие: ${participationData?.event ? `${participationData?.event?.fullName} (ID: ${participationData?.event?.id}. Статус записи: ${participationData?.event?.status})` : ''}`}
              </span>
            </>
          )}
          {type === ParticipationTypes.PARTICIPATION_CONTEST.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Конкурс: ${participationData?.event ? `${participationData?.event?.fullName} (ID: ${participationData?.event?.id}. Статус записи: ${participationData?.event?.status})` : ''}`}
              </span>
            </>
          )}
          {type === ParticipationTypes.PARTICIPATION_EXPOSITION.code && (
            <>
              <span className={b('prop-key')}>Мероприятие:&nbsp;</span>
              <span className={b('prop-value')}>
                {/* eslint-disable-next-line */}
                {`Выставка: ${participationData?.event ? `${participationData?.event?.fullName} (ID: ${participationData?.event?.id}. Статус записи: ${participationData?.event?.status})` : ''}`}
              </span>
            </>
          )}
        </li>
        {type === ParticipationTypes.PARTICIPATION_CONFERENCE.code &&
          (participationData?.reportTheme || participationData?.publication?.fields.name) && (
            <li className={b('prop')}>
              <span className={b('prop-key')}>Доклад:&nbsp;</span>
              <span className={b('prop-value')}>
                {participationData?.reportTheme || participationData?.publication?.fields.name}
              </span>
            </li>
          )}
        <li className={b('prop')}>
          <span className={b('prop-key')}>Участники:&nbsp;</span>
          <span className={b('prop-value')}>
            {participationData?.members.map((member, index) => (
              <div key={index} className={b('participant')} dangerouslySetInnerHTML={{ __html: formatAuthor(member) }} />
            ))}
          </span>
        </li>
      </ul>
      <div className={b('table-wrapper')}>
        <ListEditTable
          rows={(participationData?.departments || []) as Department[]}
          columns={departmentColumns}
          selectedRowIndex={null}
          selectRow={() => {}}
        />
      </div>
      <ul className={b('action-list')}>
        <li className={b('action-item')}>
          <Button
            mode={ButtonMode.primary}
            text={participationData?.status.value === RecordStatus.APPROVED ? 'Перевести в статус Добавлена' : 'Утвердить'}
            onClick={handleApproveParticipation}
          />
        </li>
        <li className={b('action-item')}>
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </li>
      </ul>
    </div>
  );
};

export const Component = memo(ApproveParticipationComponent);
