import * as R from 'ramda';
import { Event, ResponseData } from 'types/models';
import { Settings } from 'types/models/common';
import { convertServerExponent } from 'services/BackendAPI/configurations/exponents/converters';
import { IncentivePaymentRequestType } from 'types/models/Payment';
import { EventParticipation, EventProjectAcceptPerson } from 'types/models/Event';
import {
  convertDocument,
  convertItem,
  convertMaybeArray,
  convertReference,
  getAttributes,
  getId,
  getLabel,
  getText,
} from '../commonConverters';
import { convertMember } from '../author/converters';

export function convertServerEvent(event: any, settings: Settings): Event.Data {
  const isAllowEdit = getText(event.AllowEdit) === 'true';
  const type: string = getText(event.Type);
  const status: Event.DataStatusInfo = {
    typeLabel: event.Type?._attributes.label || '',
    text: event.Status?._attributes.label || '',
    name: getText(event.Name),
    id: getId(event),
    status: getText(event.Status),
    incentivePaymentRequest: event?.ConferenceRequests?.ConferenceRequest
      ? convertEventRequest(event?.ConferenceRequests?.ConferenceRequest)
      : event?.ContestRequests?.ContestRequest
      ? convertEventRequest(event?.ContestRequests?.ContestRequest)
      : event?.ExpositionRequests?.ExpositionRequests
      ? convertEventRequest(event?.ExpositionRequests?.ExpositionRequests)
      : '',
  };

  const fields: Event.DataStatusInfo = {
    eventSeriesId: event.EventSeries?._attributes.id || '',
    eventSeriesLabel: event.EventSeries?._attributes.label || '',
    conferenceTypeId: event.ConferenceType?._attributes.id || '',
    conferenceTypeLabel: event.ConferenceType?._attributes.label || '',
    concertTypeId: event.ConcertType?._attributes.id || '',
    concertTypeLabel: event.ConcertType?._attributes.label || '',
    contestTypeId: event.ContestType?._attributes.id || '',
    contestTypeLabel: event.ContestType?._attributes.label || '',
    expositionTypeId: event.ExpositionType?._attributes.id || '',
    expositionTypeLabel: event.ExpositionType?._attributes.label || '',
    eventStatusId: event.EventStatus?._attributes.id || '',
    eventStatusLabel: event.EventStatus?._attributes.label || '',
    isStudent: event.IsStudent?._text || 'false',
    isInSummaryPlan: event.IsInSummaryPlan?._text || 'false',
    orderDate: getText(event.OrderDate),
    orderNumber: getText(event.OrderNumber),
    isCompilationPlanned: event.IsCompilationPlanned?._text || 'false',
    isMagazineReleasePlanned: event.IsMagazineReleasePlanned?._text || 'false',
    name: getText(event.Name),
    startDate: getText(event.StartDate),
    endDate: getText(event.EndDate),
    description: getText(event.Description),
    site: getText(event.Site),
    countryId: event.Country?._attributes.id || '',
    countryLabel: event.Country?._attributes.label || '',
    city: getText(event.City),
    address: getText(event.Address),
    contact: getText(event.Contact),
    keyWords: getText(event.KeyWords),

    memberCountPlan: getText(event.MemberCountPlan),
    memberCountFact: getText(event.MemberCountFact),
  };

  const attractedFinancing = getText(event.AttractedFinancing);

  const serverDocuments = event.Documents?.Document || null;
  const documents = serverDocuments ? convertMaybeArray(serverDocuments, R.partial(convertDocument, ['local'])) : [];

  const departments = convertEventDepartments(event);
  const enterprises = convertEventEnterprises(event);
  const memberCounts = convertEventCountMembers(event);
  const serverMagazineReleases = event.MagazineReleases?.MagazineRelease;
  const serverCompilations = event.Compilations?.Compilation;
  const magazineReleases = serverMagazineReleases ? convertMaybeArray(serverMagazineReleases, convertSourceMagazineRelease) : [];
  const compilations = serverCompilations ? convertMaybeArray(serverCompilations, convertServerCompilation) : [];
  const serverMembers = event.CommitteMembers?.CommitteMember;
  const committeMembers = serverMembers ? convertMaybeArray(serverMembers, m => convertMember(m, settings)) : [];
  const projects = convertMaybeArray(event.Projects?.Project || [], convertEventProject);
  const participationPublications = convertParticipationPublications(event);
  const serverParticipation = event.Participations?.Participation;
  const participations = serverParticipation ? convertEventParticipation(serverParticipation, settings) : [];
  const format = event?.Format ? convertItem<string>(event.Format) : null;

  const eventDateString =
    fields.startDate && fields.endDate ? ` ${fields.startDate} - ${fields.endDate}` : ` ${fields.startDate}`;
  const eventCountryString = `${fields.countryLabel ? ` ${fields.countryLabel}${fields.city ? '' : '.'}` : ''}`;
  const eventCityString = `${fields.city ? `, ${fields.city}.` : ''}`;

  const fullName = `${fields.name}.${eventCountryString}${eventCityString}${eventDateString}`;

  const grntis = event?.Grntis?.Grnti || [];
  const ministryEducations = event?.MinistryEducations?.MinistryEducation || [];
  const criticalTechnologies = event?.CriticalTechnologies?.CriticalTechnology || [];
  const pnrs = event?.Pnrs?.Pnr || [];
  const pnis = event?.Pnis?.Pni || [];
  const pnmitrs = event?.Pnmitrs?.Pnmitr || [];

  return {
    isAllowEdit,
    fullName,
    type,
    status,
    fields,
    attractedFinancing,
    message: '',
    success: true,
    documents,
    departments,
    enterprises,
    committeMembers,
    magazineReleases,
    compilations,
    memberCounts,
    projects,
    participationPublications,
    participations,
    grntis: convertMaybeArray(grntis, convertReference),
    ministryEducations: convertMaybeArray(ministryEducations, convertReference),
    criticalTechnologies: convertMaybeArray(criticalTechnologies, convertReference),
    pnrs: convertMaybeArray(pnrs, convertReference),
    pnis: convertMaybeArray(pnis, convertReference),
    pnmitrs: convertMaybeArray(pnmitrs, convertReference),
    format,
  };
}

function convertEventProject(project: any): Event.EventProject {
  return {
    id: getId(project),
    project: project.Project ? convertEventProjectItem(project.Project) : null,
    accepted: getText(project.Accepted),
    acceptedBy: project.AcceptedBy ? convertEventProjectAcceptPerson(project.AcceptedBy) : null,
  };
}

const convertEventProjectAcceptPerson = (person: any): EventProjectAcceptPerson => ({
  id: getId(person),
  fullName: getText(person.FullName),
});

function convertEventProjectItem(project: any): Event.EventProjectItem {
  return {
    id: getId(project),
    number: getText(project.Number),
    name: getText(project.Name),
    program: getText(project.Program),
    leader: getText(project.Leader),
    governances: getText(project.Governances),
    financings: getText(project.Financings),
  };
}

function convertEventCountMembers(event: any): Event.MemberCount[] {
  if (event.MemberCounts?.MemberCount) {
    const convertAmountMembers = (amountItem: any) => {
      const id = getId(amountItem);
      const amount = getText(amountItem.Amount);
      const city = getText(amountItem.City);
      const professors = getText(amountItem.Professors);
      const students = getText(amountItem.Students);
      const country = { id: getId(amountItem.Country), label: getLabel(amountItem.Country) };
      const enterprise = { id: getId(amountItem.Enterprise), label: getLabel(amountItem.Enterprise) };
      const department = { id: getId(amountItem.Department), label: getLabel(amountItem.Department) };
      return { id, amount, city, professors, students, country, enterprise, department };
    };
    const converter = (member: any) => {
      const id = getId(member);
      const amount = getText(member.Amount);
      const type = getText(member.Type);
      const label = getLabel(member.Type);
      const serverAmounts = member.Amounts?.Amount || [];
      const amounts = convertMaybeArray(serverAmounts, convertAmountMembers);
      return { id, amount, label, type, amounts };
    };
    const member = event.MemberCounts.MemberCount;
    return convertMaybeArray(member, converter);
  }
  return [];
}

function convertEventEnterprises(event: any): Event.Enterprise[] {
  if (event.Enterprises?.Enterprise) {
    const converter = (enterprise: any) => {
      const mainId = getId(enterprise);
      const { id, label } = getAttributes(enterprise.Element);
      const role = getText(enterprise?.Role);
      const roleLabel = getLabel(enterprise?.Role);
      return { role: { code: role, label: roleLabel }, mainId, organization: { id, label } };
    };
    const enterprise = event.Enterprises.Enterprise;
    return convertMaybeArray(enterprise, converter);
  }
  return [];
}

export function convertServerCompilation(compilation: any): Event.Compilations {
  return {
    id: compilation._attributes?.id || '',
    bibliographicRecord: getText(compilation.BibliographicRecord),
    name: getText(compilation.BibliographicRecord),
    typeEdition: compilation.TypeEdition?._attributes?.label || '',
    departments: getText(compilation.Departments),
    status: getLabel(compilation.Status),
    type: getLabel(compilation.Type),
  };
}

export function convertSource(source: any): Event.Compilations {
  return {
    id: source._attributes?.id || '',
    bibliographicRecord: getText(source.BibliographicRecord),
    name: getText(source.BibliographicRecord),
    typeEdition: source.TypeEdition?._attributes?.label || '',
    departments: getText(source.Departments),
    status: getLabel(source.Status),
    type: getLabel(source.Type),
  };
}

export function convertSourceMagazineRelease(source: any) {
  const nameConverter = (m: any) => `
      ${m.Number ? `Выпуск №${m.Number._text}` : ''}
      ${m.Part ? `${m.Number ? ', т' : 'Т'}ом-${m.Part._text}` : ''}
      ${m.Year ? `${m.Number || m.Part ? ', ' : ''}${m.Year._text}г.` : ''}`;
  return {
    id: source._attributes?.id || '',
    name: nameConverter(source),
    year: getText(source.Year),
    number: getText(source.Number),
    part: getText(source.Part),
    status: source.Status._attributes?.label || '',
    upperSourceId: source.Magazine?._attributes.id || '',
    upperSourceName: source.Magazine?.Name._text || '',
    upperSourceStatus: source.Magazine?.Status?._attributes.label || '',
  };
}

function convertEventDepartments(event: any) {
  if (event.Departments?.Department) {
    const converter = (department: any) => {
      const mainId = department._attributes.id;
      const { id } = department.Element._attributes;
      const name = getText(department.Element.name);
      const position = getText(department.Position);
      const isFinancing = getText(department.IsFinancing) === 'true';
      return { id, name, position, mainId, isFinancing };
    };
    const department = event.Departments.Department;
    return Array.isArray(department) ? department.map(converter) : [converter(department)];
  }
  return [];
}

function convertPublicationParticipation(participation: any): EventParticipation {
  return {
    id: getId(participation),
    participationStatus: { label: participation.Status?._attributes?.label || '', value: getText(participation.Status) },
    eventFullName: getText(participation.EventFullName),
    reporter: getText(participation.Reporter),
    reportTheme: getText(participation.ReportTheme),
    reportDate: getText(participation.ReportDate),
    reportType: {
      id: participation.ReportType?._attributes?.id,
      label: getLabel(participation.ReportType),
      code: getText(participation.ReportType?.code),
    },
  };
}

function convertParticipationPublications(event: any) {
  const reportString = (participation: any) => `${getLabel(participation.ReportType)} (${getText(participation.Reporter)})`;
  if (event.Publications?.Publication) {
    const converter = (ParticipationPublication: any) => {
      const id = getId(ParticipationPublication);
      const bibliographicRecord = getText(ParticipationPublication.BibliographicRecord);
      const name = bibliographicRecord;
      const departments = getText(ParticipationPublication.Departments);
      const typePublication = getLabel(ParticipationPublication.Type);
      const status = getLabel(ParticipationPublication.Status);
      const reporters = ParticipationPublication.Participations?.Participation
        ? Array.isArray(ParticipationPublication.Participations.Participation)
          ? ParticipationPublication.Participations.Participation.map(reportString).join('; ')
          : reportString(ParticipationPublication.Participations.Participation)
        : '';
      const participations = convertMaybeArray(
        ParticipationPublication.Participations.Participation,
        convertPublicationParticipation,
      );
      return { id, name, reporters, bibliographicRecord, departments, typePublication, status, participations };
    };
    const ParticipationPublication = event.Publications.Publication;
    return convertMaybeArray(ParticipationPublication, converter);
  }

  return [];
}

function convertEventRequest(request: any) {
  const converter = (req: any) =>
    `${req.Status ? `${req.Status._attributes?.label}` : ''} ${getText(req.StatusChanged)} (п. ${getText(
      req.RegulationParagraph,
    )}: ${getText(req.RegulationName)})`;
  return Array.isArray(request) ? request.map(converter).join('; ') : converter(request);
}

function getExhibitRequest(participationPaymentRequest: any) {
  return getText(participationPaymentRequest?.Type) === IncentivePaymentRequestType.EXHIBIT
    ? convertEventRequest(participationPaymentRequest)
    : '';
}

function getMedalRequest(participationPaymentRequest: any) {
  return getText(participationPaymentRequest?.Type) === IncentivePaymentRequestType.MEDAL
    ? convertEventRequest(participationPaymentRequest)
    : '';
}

export function convertEventParticipation(participations: any, settings: Settings): Event.Participations[] {
  if (participations) {
    const converter = (Participation: any) => ({
      createdBy: getText(Participation.CreatedBy),
      modifiedDate: getText(Participation.ModifiedDate),
      modifiedBy: getText(Participation.ModifiedBy),
      createdDate: getText(Participation.CreatedDate),
      report: getText(Participation.Report),
      participant: getText(Participation.Participants),
      awards: getText(Participation.Awards),
      supervisor: getText(Participation.Supervisors),
      status: { name: Participation.Status?._attributes?.label, status: getText(Participation.Status) },
      faculties: getText(Participation.Faculties),
      note: getText(Participation.Note),
      departments: getText(Participation.Departments),
      id: Participation._attributes?.id,
      rntd: getText(Participation.Rntd),
      exhibit: getText(Participation?.Exponent?.Name) || '',
      collectiveName: getText(Participation.TeamName),
      ...(Participation.Exponent ? { exponent: convertServerExponent(Participation.Exponent, settings) } : {}),
      members: Participation?.Members?.Member
        ? convertMaybeArray(Participation.Members.Member, m => convertMember(m, settings))
        : [],
      development: getText(Participation.Development),
      incentiveRequestPayment: getExhibitRequest(Participation.PaymentRequest),
      incentiveAwardPayment: getMedalRequest(Participation.PaymentRequest),
      form: convertReference(Participation.Form),
      presentationForm: getText(Participation.PresentationForm),
      formLabel: Participation.Form?._attributes.label || '',
    });
    return Array.isArray(participations) ? participations.map(converter) : [converter(participations)];
  }
  return [];
}

export const convertApproveEventResponseXMLElementToOutput = (xml: any): ResponseData => ({
  success: xml.Response._attributes.success === 'true',
  message: xml.Response._attributes.errorMessage || xml.Response._attributes.serverErrorMessage || '',
});
