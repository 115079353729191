import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { CommunityType } from 'utils/Enums';

const CommunityEditTemplate: React.FC<F.TemplateProps & { memberEditLocked: boolean; communityTypes?: CommunityType[] }> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  templateName,
  memberEditLocked,
  communityTypes,
}: F.TemplateProps & { memberEditLocked: boolean; communityTypes?: CommunityType[] }) => {
  const look: F.Look & { memberEditLocked: boolean; communityTypes?: CommunityType[] } = {
    apiID: 'GetCommunity',
    template: templateName,
    editMode: true,
    id,
    relatedTableState,
    memberEditLocked,
    communityTypes,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Редактирование члена диссертационного совета (ID: ${id})`}
      size={['large', 'small-height']}
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityEditTemplate);
