import React, { Dispatch, SetStateAction } from 'react';

import { CustomSelect, ListEdit, Radio, ReferenceItem, FormComponent, TextArea, TextDateTime, TextInput } from 'components';

import { GetPartnerList, GetReferenceElementList } from 'features/Table/specifications';
import { Table, Community } from 'types/models';
import { Settings } from 'types/models/common';
import { useController } from './controller';

type Props = {
  workMode: Table.WorkMode;
  community: Community.Community | null;
  setCommunity: Dispatch<SetStateAction<Community.Community | null>>;
  isNotDissertationCouncil: boolean;
  settings: Settings | null;
};

export function AboutFields(props: Props) {
  const { workMode, community, setCommunity, isNotDissertationCouncil, settings } = props;
  const {
    handleAffiliationFieldChange,
    handleNoteFieldChange,
    handleIsLocalFieldChange,
    affiliationRowConverter,
    handleCodeFieldChange,
    handleFoundationDateFieldChange,
    handleOrderNumberFieldChange,
    handleOrderDateFieldChange,
    handleVaksFieldChange,
    handleGrntisFieldChange,
  } = useController({
    community,
    setCommunity,
  });

  return (
    <>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Полное название (краткое название)" isRequired>
          <TextInput value={community?.code} onChange={handleCodeFieldChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      {!isNotDissertationCouncil && (
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Дата основания">
            <TextDateTime
              value={community?.foundationDate}
              onChange={handleFoundationDateFieldChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line hasFreeFormat growIndexes={[1, 100]}>
        <FormComponent.Field label="Организация">
          <Radio
            isDisabled={workMode === 'viewMode'}
            value={community?.isLocal ? 'true' : 'false'}
            list={[
              { label: settings?.organization?.shortName, value: 'true' },
              { label: 'Другая организация', value: 'false' },
            ]}
            onChange={handleIsLocalFieldChange}
          />
        </FormComponent.Field>
        <FormComponent.Field>
          {!community?.isLocal && (
            <CustomSelect
              disabled={workMode === 'viewMode' || community?.isLocal}
              original={
                community?.affiliation?.partner?.id
                  ? {
                      name: community.affiliation.partner.shortName,
                      id: community.affiliation.partner.id,
                    }
                  : community?.affiliation?.enterprise?.id
                  ? {
                      name: community?.affiliation.enterprise.label,
                      id: community?.affiliation.enterprise.id,
                    }
                  : community?.affiliation?.enterpriseCustomer?.id
                  ? {
                      name: community?.affiliation.enterpriseCustomer.label,
                      id: community?.affiliation.enterpriseCustomer.id,
                    }
                  : null
              }
              relationTableModalTitle="Партнёры"
              onChange={handleAffiliationFieldChange}
              modalTableRowConverter={affiliationRowConverter}
              modalTableSpecification={GetPartnerList({ withMenu: false })}
            />
          )}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat growIndexes={[10, 1]}>
        <FormComponent.Field label="Номер приказа">
          <TextInput
            value={community?.orderNumber}
            onChange={handleOrderNumberFieldChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Дата приказа">
          <TextDateTime value={community?.orderDate} onChange={handleOrderDateFieldChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Примечание">
          <TextArea
            settings={{ rows: 3 }}
            value={community?.note}
            onChange={handleNoteFieldChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!isNotDissertationCouncil && (
        <>
          <FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                rows={community?.vaks || []}
                isToolbarDisabled={workMode === 'viewMode'}
                onChange={handleVaksFieldChange}
                columns={[
                  { label: 'Код', formatValue: ref => ref.element.code || '', styles: { width: '10%' } },
                  { label: 'Наименование', formatValue: ref => ref.element.label, styles: { width: '40%' } },
                  {
                    label: 'Группа специальностей',
                    formatValue: ref => (ref.element.customFields?.specialityGroup as string) || '',
                    styles: { width: '25%' },
                  },
                  {
                    label: 'Отрасль науки',
                    formatValue: ref => (ref.element.customFields?.specialityBrunch as string) || '',
                    styles: { width: '25%' },
                  },
                ]}
                isDeleteConfirmEnabled
                title="Специальности ВАК"
                withMessages
                specification={{
                  mode: 'relationTableModal',
                  relationTableModalTitle: 'Специальности ВАК',
                  modalTableRowConverter: row => ({
                    id: null,
                    element: {
                      label: row.label,
                      id: row.id,
                      customFields: { specialityBrunch: row.specialityBrunch, specialityGroup: row.specialityGroup },
                    },
                  }),
                  modalTableSpecification: GetReferenceElementList({
                    requestData: {
                      filters: [],
                      name: 'RefVakSpeciality',
                    },
                  }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                rows={community?.grntis || []}
                isToolbarDisabled={workMode === 'viewMode'}
                onChange={handleGrntisFieldChange}
                columns={[
                  { label: 'Код', formatValue: ref => ref.element.code || '', styles: { width: '10%' } },
                  { label: 'Наименование', formatValue: ref => ref.element.label, styles: { width: '50%' } },
                  {
                    label: 'Группа',
                    formatValue: ref => (((ref.element.customFields?.parent as ReferenceItem) || '').label as string) || '',
                    styles: { width: '40%' },
                  },
                ]}
                isDeleteConfirmEnabled
                title="ГРНТИ"
                withMessages
                specification={{
                  mode: 'relationTableModal',
                  relationTableModalTitle: 'ГРНТИ',
                  modalTableRowConverter: row => ({
                    id: null,
                    element: {
                      label: row.label,
                      id: row.id,
                      customFields: { parent: { id: row['id:parent'], label: row.parent } },
                    },
                  }),
                  modalTableSpecification: GetReferenceElementList({
                    requestData: {
                      filters: [],
                      name: 'RefGrnti',
                    },
                  }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
    </>
  );
}
