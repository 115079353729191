import { AuthorPayment } from 'types/models/Payment';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { getEnumItem } from 'utils/Helpers';

export function getEmptyAuthorPayment(): AuthorPayment {
  return {
    id: null,
    type: null,
    year: '',
    quarter: null,
    month: '',
    status: getEnumItem('AuthorPaymentRequestStatus', AuthorPaymentRequestStatus.DRAFT),
    statusChanger: null,
    creator: null,
    distributionMethodBetweenNominees: null,
    distributionMethodBetweenFunds: null,
    maxFundMoney: '',
    factFundMoney: '',
    remainderFundMoney: '',
    factNomineesMoney: '',
    maxNomineesMoney: '',
    remainderNomineesMoney: '',
    documents: [],
    nominees: [],
    departments: [],
    regulationParagraph: null,
    regulationName: null,
    statusChanged: null,
    article: null,
    event: null,
    publication: null,
    participation: null,
    isManualEdit: false,
  };
}
