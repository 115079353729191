import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'actual',
      list: [
        {
          label: 'Текущий состав',
          value: 'actual',
        },
        {
          label: 'Все',
          value: 'all',
        },
      ],
      value: 'actual',
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: false,
    },
  ]);
});
