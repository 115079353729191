import React from 'react';

import { Button, ButtonMode, InputSelect, FormComponent, Modal, TextDateTime } from 'components';

import { Item } from 'types/models/common';
import { Project } from 'types/models';
import { useController } from './controller';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(stageNumber: string, reportType: Item, date: string): void;
  stages: Project.Stage[];
  reports: Project.StateRegistrationReport[];
};

export function AddModal(props: Props) {
  const { isOpen, stages, onClose, onSubmit, reports } = props;

  const {
    date,
    options,
    reportTypeOptions,
    reportTypeOption,
    stageNumberOption,
    handleSubmit,
    setDate,
    setReportTypeOption,
    handleStageChange,
  } = useController({ onClose, onSubmit, stages, reports });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавьте планируемые сроки по отчёту проекта" size="medium">
      <FormComponent.Wrapper>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field isRequired label="Выберите этап проекта">
            <InputSelect options={options} value={stageNumberOption} onSelectChange={handleStageChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label="Тип отчёта" isRequired>
            <InputSelect options={reportTypeOptions} value={reportTypeOption} onSelectChange={setReportTypeOption} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Сдать отчет в ЦИТиС до">
            <TextDateTime value={date} onChange={x => setDate(x || '')} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Добавить" onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
