import React, { useCallback } from 'react';

import { Button, ButtonMode, Select, TextArea, TextAreaMode, FormComponent } from 'components';

import useController from './controller';

import './style.scss';

type Props = {
  onClose(): void;
};

const HandleErrorForm = ({ onClose }: Props) => {
  const {
    errorReasonOptions,
    handleFormSubmit,
    handleCancelButtonClick,
    errorReason,
    handleErrorReasonChange,
    errorReasonDetail,
    setErrorReasonDetail,
    libraryNote,
    setLibraryNote,
  } = useController({ onClose });

  const renderEntry = useCallback(x => x, []);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line lineSize="padded">
        <FormComponent.Field label="Причина ошибки" isRequired>
          <Select.Component
            value={errorReason.label}
            onChange={handleErrorReasonChange}
            entries={errorReasonOptions.map(e => e.label)}
            renderEntry={renderEntry}
            variant="outlined"
            defaultPlaceholder="Не задан"
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Детализация причины ошибки">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Детализация причины ошибки' }}
            value={errorReasonDetail}
            onChange={setErrorReasonDetail}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Комментарий">
          <TextArea
            mode={TextAreaMode.modal}
            settings={{ rows: 3, title: 'Комментарий' }}
            value={libraryNote}
            onChange={setLibraryNote}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Actions>
        <Button mode={ButtonMode.primary} text="Отправить" onClick={handleFormSubmit} isDisabled={!errorReason.value} />
        <Button mode={ButtonMode.secondary} text="Отмена" onClick={handleCancelButtonClick} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = React.memo(HandleErrorForm);
