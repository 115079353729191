import React, { useEffect, useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import MagazineAddTemplateType from './MagazineAddTemplateType';

const MagazineAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id } = props;
  const [isPreprint, setIsPreprint] = useState<boolean | null>(null);
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    id,
    apiID: 'GetPublication',
    template: 'MagazineForm',
    type: 'Magazine',
    relatedTableState,
    isPreprint,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) setIsPreprint(null);
  }, [isOpen]);

  return (
    <>
      {isPreprint === null ? (
        <Modal isOpen={isOpen} onClose={onClose} title="Создание нового журнала" size="small">
          <MagazineAddTemplateType onSelect={setIsPreprint} />
        </Modal>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
          <Form look={look} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};
export const Component = React.memo(MagazineAddTemplate);
