import { Project } from 'types/models';

export function validate(department: Project.Department | null) {
  if (!department) {
    return [{ isValid: false, invalidMessage: 'Заполните обязательные поля' }];
  }

  const isValidGovernance = Boolean(department.governance?.id);
  const isValidUnit = Boolean(department.unit?.id);

  return [
    { isValid: isValidGovernance, invalidMessage: 'Выберите Факультет/Институт' },
    { isValid: isValidUnit, invalidMessage: 'Выберите подразделение' },
  ];
}
