import { useCallback } from 'react';

import { createNumberMask } from 'text-mask-addons';

type DecimalNumberProps = {
  isRequired?: boolean;
  limit?: number;
};

export type NumberProps = {
  min?: number;
  max?: number;
  decimal?: DecimalNumberProps | false;
  isNatural?: boolean;
  isThousands?: boolean;
};

export const NumberMask = (number: NumberProps) => {
  const { min, max = Math.pow(10, 15), decimal = {}, isNatural = true, isThousands = true } = number;

  return {
    // Mask
    mask: (rawValue: string) =>
      createNumberMask({
        prefix: '',
        suffix: '',
        decimalSymbol: rawValue.indexOf(',') > -1 ? ',' : '.',
        thousandsSeparatorSymbol: ' ',
        allowDecimal: !!decimal,
        includeThousandsSeparator: isThousands,
        requireDecimal: !!decimal ? !!decimal?.isRequired : false,
        decimalLimit: !!decimal && decimal?.limit ? decimal?.limit : 2,
        allowNegative: !isNatural,
      })(rawValue),

    // Validate
    pipe: useCallback(
      (conformedValue: any) => {
        const preparedValue = parseFloat(conformedValue.replaceAll(' ', '').replaceAll(',', '.') || 0);

        if (typeof min === 'number' && preparedValue < min) {
          return { value: String(min), indexesOfPipedChars: [] };
        }
        if (typeof max === 'number' && preparedValue > max) {
          return { value: String(max), indexesOfPipedChars: [] };
        }

        return { value: conformedValue, indexesOfPipedChars: [] };
      },
      [max, min],
    ),
  };
};
