import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

type Props = {
  isOpen: boolean;
  onClose(): void;
  id: string | null;
  shortMode?: boolean;
};

export function PortfolioCard(props: Props) {
  const { isOpen, onClose, id, shortMode } = props;
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook & { isOpen: boolean; shortMode: boolean | undefined } = {
    apiID: 'GetScientistData',
    template: 'PersonForm',
    relatedTableState: {} as any,
    id: String(id),
    isOpen,
    viewMode: true,
    setTitle,
    shortMode,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" viewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
}
