import React from 'react';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

const ToolbarTemplate = ({ tableState, onSubmitTable }: Props) => {
  const { buttons, isEditFormOpen, isEditButtonDisabled, handleTemplateCloseEditForm } = useController({
    tableState,
    onSubmitTable,
  });

  return (
    <>
      {!isEditButtonDisabled && (
        <modalTemplates.KindConsumptionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Toolbar buttons={buttons} />
    </>
  );
};

export const Component = React.memo(ToolbarTemplate);
