import React, { useState, useCallback } from 'react';
import { Form, Table } from 'types/models';

import {
  Button,
  ButtonMode,
  Reference,
  Select,
  Option,
  InputSelect,
  ConfirmPopup,
  TextArea,
  TextInput,
  TextInputMode,
  FormComponent,
  SectionTitle,
  TextDateTime,
  ReferenceItem,
  Modal,
} from 'components';

import { useAppDataContext } from 'features/AppData/context';
import { DataGrid } from 'features/Table';
import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  formFields: Form.Fields;
  workMode: Table.WorkMode;
  editionTypes: Table.EnumValue[];
  onCreateBibliographicRecordClick: () => void;
  isAddressLoading: boolean;
  addressOptions: Option[];
  setIsAddressLoading: (isLoading: boolean) => void;
};

const Info = ({
  formFields,
  workMode,
  editionTypes,
  onCreateBibliographicRecordClick,
  isAddressLoading,
  addressOptions,
  setIsAddressLoading,
}: Props) => {
  const { initialLanguageCode } = useAppDataContext();

  const [isPublisherWarningOpen, setIsPublisherWarningOpen] = useState<boolean>(false);
  const [isRefPublisherModalOpen, setIsRefPublisherModalOpen] = useState<boolean>(false);

  const handleLanguageChange = useCallback(
    (value: ReferenceItem) => {
      if (
        initialLanguageCode &&
        initialLanguageCode?.id !== value.id &&
        !formFields.publisher.value?.foreignName &&
        !!formFields.publisher.value?.id
      )
        setIsPublisherWarningOpen(true);
      formFields.textLanguage.onChange(value);
    },
    [formFields.publisher.value?.foreignName, formFields.publisher.value?.id, formFields.textLanguage, initialLanguageCode],
  );

  return (
    <>
      <form>
        <input type="submit" hidden />
        <SectionTitle title="Издание" />
        <FormComponent.Line lineSize="doublePadded" hasGap>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={formFields.isbn.title}>
              <TextInput
                mode={TextInputMode.isbn}
                value={formFields.isbn.value}
                onChange={formFields.isbn.onChange}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Field label={formFields.eisbn.title}>
            <TextInput
              mode={TextInputMode.isbn}
              value={formFields.eisbn.value}
              onChange={formFields.eisbn.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded" hasGap>
          <Reference
            disabled={workMode === 'viewMode'}
            value={{
              label:
                formFields.textLanguage.value?.id !== initialLanguageCode?.id
                  ? formFields.publisher.value?.foreignName
                    ? formFields.publisher.value?.foreignName
                    : formFields.publisher.value?.label
                  : formFields.publisher.value?.label,
              id: formFields.publisher.value?.id,
            }}
            theme="big"
            title="Издательство"
            relationTableModalTitle="Издательство"
            name="RefPublisher"
            onChange={formFields.publisher.onChange}
          />
          <InputSelect
            options={addressOptions}
            value={{ label: formFields.address.value, value: '' }}
            disabled={workMode === 'viewMode'}
            isClearable
            isRequired={formFields.address.required}
            label="Место издания"
            isLoading={isAddressLoading}
            isError={!formFields.address.isValid}
            onSelectChange={(option, actionMeta) => {
              // eslint-disable-line
              if (actionMeta.action === 'clear') {
                formFields.address.onChange('');
              } else {
                formFields.address.onChange(option?.label ?? '');
              }
            }}
            onInputChange={(newValue, actionMeta) => {
              if (actionMeta.action !== 'input-blur' && actionMeta.action !== 'menu-close') {
                if (actionMeta.action !== 'set-value') {
                  setIsAddressLoading(true);
                }
                formFields.address.onChange(newValue);
              }
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line hasGap>
          <FormComponent.Line lineSize="padded">
            <Reference
              // disabled
              value={formFields.textLanguage.value}
              isRequired
              theme="big"
              title="Язык текста"
              relationTableModalTitle="Язык"
              refButtonTitle="Язык"
              name="RefLanguage"
              onChange={handleLanguageChange}
              isDisabledDelete
              disabled={workMode === 'viewMode'}
            />
          </FormComponent.Line>
          <Reference
            theme="big"
            value={formFields.originalLanguage.value}
            disabled={workMode === 'viewMode'}
            title={formFields.originalLanguage.title}
            relationTableModalTitle={formFields.originalLanguage.title}
            name="RefLanguage"
            onChange={formFields.originalLanguage.onChange}
          />
        </FormComponent.Line>

        <FormComponent.Line hasGap lineSize="doublePadded">
          <FormComponent.Field isRequired={formFields.pageCount.required} label={formFields.pageCount.title}>
            <TextInput
              mode={TextInputMode.number}
              value={formFields.pageCount.value ?? ''}
              onChange={formFields.pageCount.onChange}
              settings={{ max: 100000, decimal: false }}
              isDisabled={workMode === 'viewMode'}
              isError={!formFields.pageCount.isValid}
            />
          </FormComponent.Field>
          <FormComponent.Field isRequired={formFields.printPageCount.required} label={formFields.printPageCount.title}>
            <TextInput
              mode={TextInputMode.number}
              value={formFields.printPageCount.value ?? ''}
              onChange={formFields.printPageCount.onChange}
              isDisabled={workMode === 'viewMode'}
              isError={!formFields.printPageCount.isValid}
            />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.tirage.title} isRequired={formFields.tirage.required} labelSize="fit">
            <TextInput
              mode={TextInputMode.number}
              value={formFields.tirage.value ?? ''}
              onChange={formFields.tirage.onChange}
              settings={{ decimal: false }}
              isDisabled={workMode === 'viewMode'}
              isError={!formFields.tirage.isValid}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line hasGap lineSize="doublePadded">
          <Reference
            disabled={workMode === 'viewMode'}
            value={formFields.categoryEdition.value}
            tooltipText={formFields.categoryEdition.tooltipText}
            isRequired
            theme="big"
            title={formFields.categoryEdition.title}
            refButtonTitle="Вид издания"
            relationTableModalTitle="Вид издания по целевому назначению"
            name="RefCategoryEdition"
            onChange={formFields.categoryEdition.onChange}
          />
          <FormComponent.Field label={formFields.typeEdition.title} isRequired={formFields.typeEdition.required}>
            <Select.Component
              disabled={workMode === 'viewMode'}
              value={formFields.typeEdition.value?.label}
              variant="outlined"
              labelWidth={137}
              title={formFields.typeEdition.title}
              defaultPlaceholder="Не задан"
              entries={editionTypes.map(({ label }) => label)}
              onChange={formFields.typeEdition.onChange}
              renderEntry={Select.helpers.renderEntry}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.republishData.title}>
            <TextInput
              value={formFields.republishData.value}
              onChange={formFields.republishData.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded" hasGap>
          <FormComponent.Line lineSize="doublePadded">
            <FormComponent.Field label={formFields.volumeNumber.title}>
              <TextInput
                value={formFields.volumeNumber.value}
                onChange={formFields.volumeNumber.onChange}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Field label={formFields.seriesTitle.title}>
            <TextInput
              value={formFields.seriesTitle.value}
              onChange={formFields.seriesTitle.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line hasGap lineSize="doublePadded">
          <FormComponent.Field label={formFields.year.title} isRequired>
            <TextInput
              mode={TextInputMode.year}
              value={formFields.year.value}
              onChange={formFields.year.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.month.title}>
            <TextInput
              mode={TextInputMode.month}
              value={formFields.month.value}
              onChange={formFields.month.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.day.title}>
            <TextInput
              mode={TextInputMode.day}
              value={formFields.day.value}
              onChange={formFields.day.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field label={formFields.publicationDate.title}>
            <TextDateTime
              value={formFields.publicationDate.value}
              onChange={formFields.publicationDate.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <SectionTitle title="Библиографическая запись" />
        <FormComponent.Line hasGap hasFreeFormat growIndexes={[2, 0]}>
          <FormComponent.Field>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.bibliographicRecord.value}
              onChange={formFields.bibliographicRecord.onChange}
              isDisabled
            />
          </FormComponent.Field>
          <Button
            mode={ButtonMode.secondary}
            text="Построить"
            onClick={onCreateBibliographicRecordClick}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Line>

        <SectionTitle title="Классификаторы" />
        <FormComponent.Line>
          <Reference
            disabled={workMode === 'viewMode'}
            value={formFields.grnti.value}
            title={formFields.grnti.title}
            relationTableModalTitle="ГРНТИ"
            tooltipText={formFields.grnti.tooltipText}
            refButtonTitle="ГРНТИ"
            theme="big"
            name="RefGrnti"
            onChange={formFields.grnti.onChange}
          />
        </FormComponent.Line>
        <FormComponent.Line lineSize="doublePadded">
          <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
            <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <Reference
            tooltipText={formFields.study.tooltipText}
            disabled={workMode === 'viewMode'}
            value={formFields.study.value}
            title={formFields.study.title}
            relationTableModalTitle={formFields.study.title}
            theme="big"
            refButtonTitle="Область науки"
            name="RefStudy"
            onChange={formFields.study.onChange}
          />
        </FormComponent.Line>
      </form>

      <ConfirmPopup
        isOpen={isPublisherWarningOpen}
        onClose={() => setIsPublisherWarningOpen(false)}
        title="Название издательства на английском языке"
        // eslint-disable-next-line
        text='Для выбранного издательства необходимо заполнить данные в столбце "Наименование на английском" в справочнике "Издательств"'
        okButtonText="Открыть справочник"
        onConfirm={() => {
          setIsPublisherWarningOpen(false);
          setIsRefPublisherModalOpen(true);
        }}
      />
      <Modal
        isOpen={isRefPublisherModalOpen}
        onClose={() => setIsRefPublisherModalOpen(false)}
        title='Справочник "Издательства"'
        size="extralarge"
      >
        <DataGrid specification={GetReferenceElementList({ requestData: { filters: [], name: 'RefPublisher' } })} />
      </Modal>
    </>
  );
};

export const Component = React.memo(Info);
