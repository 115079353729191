import React from 'react';

import { Toolbar as SharedToolbar, ConfirmPopup } from 'components';
import { Table } from 'types/models';
import { useController } from './controller';

import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
};
function Toolbar(props: Props) {
  const { tableState } = props;
  const {
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    buttons,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    requestType,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    tenderInfo,
    setTenderInfo,
    nirRequestTenderType,
    handleSubmitTenderTypeModal,
    tenderRequestType,
    setTenderRequestType,
  } = useController({
    tableState,
  });

  return (
    <>
      {isViewFormOpen && (
        <modalTemplates.RequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          requestType={requestType}
          specification={tableState.selectedRows[0] ? tableState.selectedRows[0]['id:Specification'] : null}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.RequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          requestType={requestType}
          specification={tableState.selectedRows[0] ? tableState.selectedRows[0]['id:Specification'] : null}
        />
      )}
      <modalTemplates.RequestAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        tenderInfo={tenderInfo}
        setTenderInfo={setTenderInfo}
        nirRequestTenderType={nirRequestTenderType}
        handleSubmitTenderTypeModal={handleSubmitTenderTypeModal}
        tenderRequestType={tenderRequestType}
        setTenderRequestType={setTenderRequestType}
      />
      <ConfirmPopup title="Мои заявки на НИОКР" isOpen={isHelpFormOpen} onClose={handleTemplateCloseHelpForm} size="medium">
        <div className="modal-help">
          <p>
            <strong className="primary">Помощь для вкладки « Заявки на конкурс» в Личном кабинете:</strong>
          </p>
          <p>Этот раздел предназначен для оформления заявки для участия в конкурсе, таких как:</p>
          <ul>
            <li>
              <strong>«Заявка по академической мобильности»</strong> — для конкурсов из категории: Стажировки, повышение
              квалификации, Участие в научных мероприятиях;
            </li>
            <li>
              <strong>«Заявка на конкурс»</strong> » — для конкурсов из категории: Проведение научных мероприятий, Издание научных
              трудов, на выполнение НИОКР, Проекты в области предпринимательства и инноваций, Стипендии и премии, Прочие проекты;
            </li>
            <li>
              <strong>«Заявка на внутренний конкурс»</strong> — для участия в конкурсе на выполнение НИР и ОКР, организованном
              внутри университета.
            </li>
          </ul>
          <p>
            Для всех участников коллектива заявки (в роли: «исполнитель», «руководитель проекта», «ответственный исполнитель») в
            Личном кабинете и в карточке персоны заявка отобразится в списке на вкладке «Заявки на конкурсы». Список заявок можно
            выгрузить в файл с помощью кнопки Выгрузить в файл.
          </p>
          <p>
            <strong className="primary">Важно:</strong>{' '}
            <strong>
              Для заявителя (в заявке по академической мобильности ) и для руководителя проекта и ответственного исполнителя (в
              заявке на конкурс)
            </strong>{' '}
            форма заявки доступна для редактирования в статусах: «Черновик» или «На доработку». В других статусах — форма заявки
            доступна для просмотра. Удалить заявку можно только в статусе «Черновик».
          </p>
        </div>
      </ConfirmPopup>
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную заявку? (ID ${tableState.selectedRows[0].id})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
