import React, { useCallback } from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { Department } from 'types/models/Payment';

type Props = {
  department: Department | null;
  setDepartment(department: Department | null): void;
};

export function Fields(props: Props) {
  const { department, setDepartment } = props;

  const changeAmount = useCallback(
    (money: string) => {
      setDepartment({ ...department!, amountMoney: money });
    },
    [department, setDepartment],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="">
          <strong>{department?.topLevelDepartment.name}</strong>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма" isRequired>
          <TextInput
            mode={TextInputMode.number}
            value={department?.amountMoney}
            onChange={changeAmount}
            settings={{ decimal: false }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
