import React, { useCallback } from 'react';

import { Button, ButtonMode, Modal, Reference, FormComponent, TextArea, TextAreaMode } from 'components';

import { showNotification } from 'features/Notifications';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  isOpen: boolean;
  feedline: Project.Feedline;
  onClose(): void;
  changeFeedline(feedline: Project.Feedline): void;
  onSubmit(feedline: Project.Feedline): void;
};

export function MessageModal(props: Props) {
  const { isOpen, onClose, feedline, changeFeedline, onSubmit } = props;

  const makeChangeHandler = (key: keyof Project.Feedline) => (value: ValueOf<Project.Feedline>) => {
    changeFeedline({ ...feedline, [key]: value });
  };

  const handleSubmit = useCallback(() => {
    if (feedline.message === null) {
      showNotification({ message: 'Выберите сообщение', theme: 'danger' });
      return;
    }
    onSubmit(feedline);
    onClose();
  }, [feedline, onClose, onSubmit]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Сообщение" size="large">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field>
            <Reference
              isRequired
              relationTableModalTitle="Сообщения журнала"
              name="RefProjectFeedlineMessage"
              onChange={makeChangeHandler('message')}
              value={feedline?.message ?? null}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line lineSize="padded">
          <FormComponent.Field>
            <TextArea
              mode={TextAreaMode.modal}
              settings={{ rows: 3 }}
              value={feedline.detail}
              onChange={makeChangeHandler('detail')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.primary} text="Сохранить" onClick={handleSubmit} />
          <Button mode={ButtonMode.secondary} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </Modal>
  );
}
