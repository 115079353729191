import { Table } from 'types/models';
import { TemplatesTableDependencies } from 'types/models/Table';

type Deps = {
  templatesTableDependencies?: TemplatesTableDependencies;
};

export function GetTranslatesListSpecification(deps: Deps): Table.Specification {
  return {
    apiID: 'GetMagazineTranslationList',
    templatesTableDependencies: deps.templatesTableDependencies,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}
