import React, { useCallback } from 'react';

import { InputSelect, Option, TextInput, TextInputMode, FormComponent, Reference } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getYear } from 'utils/Helpers';

import { EditableIndicator, IndicatorKey } from 'features/Form/looks/project/ProjectForm/model';
import { getStagesOptions } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  stages: Project.Stage[];
  indicator: EditableIndicator;
  setIndicator(indicator: EditableIndicator): void;
  programId: string;
  type: IndicatorKey;
};

export function Fields(props: Props) {
  const { type, stages, indicator, setIndicator } = props;

  const makeChangeHandler = (key: keyof EditableIndicator) => (value: ValueOf<EditableIndicator>) => {
    setIndicator({ ...indicator, [key]: value });
  };

  const stagesOptions = getStagesOptions(stages);

  const handleChangeStage = useCallback(
    (option: Option | null) => {
      const stage = stages.find(x => x.number === option?.value ?? '');
      if (stage) {
        setIndicator({ ...indicator, stageNumber: stage.number, year: String(getYear(stage.startDate)) });
      }
    },
    [setIndicator, indicator, stages],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line hasGap lineSize="doublePadded">
        <FormComponent.Field label="Этап" isRequired>
          <InputSelect
            options={stagesOptions}
            label=""
            value={stagesOptions.find(x => x.value === indicator.stageNumber) ?? null}
            onSelectChange={handleChangeStage}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Год" isRequired labelSize="fit">
          <TextInput mode={TextInputMode.year} value={indicator.year} onChange={makeChangeHandler('year')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={type === 'indicators' ? 'Индикатор' : 'Показатель'} isRequired>
          <Reference
            name={type === 'indicators' ? 'RefResultIndicator' : 'RefResultIndex'}
            onChange={val => makeChangeHandler('ref')(val)}
            value={indicator.ref}
            relationTableModalTitle={
              type === 'indicators'
                ? 'Справочник индикаторов эффективности выполнения научных проектов'
                : 'Справочник показателей результативности выполнения научных проектов'
            }
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded" hasGap>
        <FormComponent.Field label="План">
          <TextInput mode={TextInputMode.number} value={indicator.plan} onChange={val => makeChangeHandler('plan')(val || '0')} />
        </FormComponent.Field>
        <FormComponent.Field label="Факт" labelSize="fit">
          <TextInput mode={TextInputMode.number} value={indicator.fact} onChange={val => makeChangeHandler('fact')(val || '0')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line lineSize="doublePadded">
        <FormComponent.Field label="Примечание">
          <TextInput value={indicator.note} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}
