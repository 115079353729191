import React, { useCallback, useMemo, useState } from 'react';
import { block } from 'bem-cn';

import { IconButtonProps, Toolbar, buttonIcons, RelationTableModal, TextArea, TextInput } from 'components';

import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';

import './style.scss';

const b = block('text-grid-select');

export type ExtraButton<T> = Omit<IconButtonProps, 'onClick' | 'isDisabled'> & {
  checkIsDisabled?(value: T | null): boolean;
  onClick: ExtraClickHandler<T>;
};
type ExtraClickHandler<T> = (value: T | null) => void;

type Props<T, CustomState extends {} = {}> = {
  value: T | null;
  onChange(value: T | null): void;
  specification: Table.Specification<CustomState>;
  valueFormatter(value: T): string;
  title: string;
  isTextarea?: boolean;
  disabled?: boolean;
  settings: WorkSettings<T>;
  extraButtons?: ExtraButton<T>[];
  rowsMin?: number;
};

type SelectRowWorkSettings<Row> = {
  mode: 'selectTableRow';
  tableRowConverter(row: Table.Entry): Row | Promise<Row>;
};

type LoadRowWorkSettings<Row> = {
  mode: 'loadTableRow';
  onSelectRow(tableRow: Table.Entry, onSuccessLoad: (loadedRow: Row) => void): void;
};

type WorkSettings<Row> = SelectRowWorkSettings<Row> | LoadRowWorkSettings<Row>;

export function TextGridSelect<T, CustomState extends {} = object>(props: Props<T, CustomState>) {
  const {
    onChange,
    value,
    specification,
    isTextarea,
    settings,
    valueFormatter,
    title,
    rowsMin,
    disabled = false,
    extraButtons = [],
  } = props;
  const [isOpenTableModal, setIsOpenTableModal] = useState(false);

  const closeTableModal = useCallback(() => {
    setIsOpenTableModal(false);
  }, [setIsOpenTableModal]);

  const openTableModal = useCallback(() => {
    setIsOpenTableModal(true);
  }, []);

  const onSubmitTable = useCallback(
    async ({ selectedRows: [row] }: SubmitTable) => {
      if (row) {
        if (settings.mode === 'selectTableRow') {
          const convertedRow = await settings.tableRowConverter(row);
          onChange(convertedRow);
          closeTableModal();
        }

        if (settings.mode === 'loadTableRow') {
          settings.onSelectRow(row, loadedRow => {
            onChange(loadedRow);
            closeTableModal();
          });
        }
      }
    },
    [closeTableModal, onChange, settings],
  );

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.list,
        title,
        code: 'add',
        isDisabled: disabled,
        onClick: event => {
          event?.currentTarget.blur();
          openTableModal();
        },
      },
      {
        icons: buttonIcons.delete,
        title: 'Очистить',
        code: 'remove',
        isDisabled: disabled || !value || !valueFormatter(value),
        onClick: () => onChange(null),
      },
      ...extraButtons.map<IconButtonProps>(item => ({
        ...item,
        isDisabled: (() => {
          if (item.checkIsDisabled) {
            return item.checkIsDisabled(value);
          }
          return false;
        })(),
        onClick: () => item.onClick(value),
      })),
    ],
    [extraButtons, title, disabled, value, valueFormatter, openTableModal, onChange],
  );

  return (
    <div className={b()}>
      <div className={b('input')}>
        {isTextarea ? (
          <TextArea
            settings={{ rows: rowsMin, isFixed: true }}
            value={value ? valueFormatter(value) : ''}
            onChange={() => {}}
            isDisabled
          />
        ) : (
          <TextInput value={value ? valueFormatter(value) : ''} isDisabled={disabled} />
        )}
      </div>

      <Toolbar buttons={buttons} />

      <RelationTableModal
        specification={{ ...specification, onSubmitTable }}
        relationTableModalTitle={title}
        isOpen={isOpenTableModal}
        onClose={closeTableModal}
      />
    </div>
  );
}
