import { Specification } from 'types/models/Table';
import { TypeEditionCode } from 'utils/Enums/TypeEditionCode';

type Props = {
  period: string;
  typeEdition: TypeEditionCode | null;
};
export function GetDashboardChartByTypeEditionMagazineArticleList({ period, typeEdition }: Props): Specification {
  return {
    apiID: 'GetDashboardChartByTypeEditionMagazineArticleList',
    workMode: 'viewMode',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData: {
      period,
      typeEdition,
    },
  };
}
