import React, { SetStateAction, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { block } from 'bem-cn';

import { Tabs, Tab, ReferenceItem, FormComponent, ConfirmPopup } from 'components';
import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { LogoComponent } from 'features/PageLayout/Logo';
import { Component as Topbar } from 'features/Topbar';
import * as Blocks from 'features/Home/blocks';

import { EditableHonor, HonorType } from './model';
import { Toolbar } from './Toolbar/Toolbar';
import {
  Main,
  AdditionalInfo,
  Publications,
  ParticipationsEvents,
  EventList,
  RequestAndProjects,
  SecurityDocuments,
  Recognitions,
  ScientificInterests,
  Colleagues,
} from './Tabs';
import { useController } from './controller';
import { WelcomeModal } from './WelcomeModal/WelcomeModal';
import { GetQuestion, QuestionAndAnswersList } from './Tabs/QuestionsAndAnswers/Tabs';

import { DataGrid } from 'features/Table';
import { GetPersonalDissertationManagementList, GetPersonalRequestList } from 'features/Table/specifications';

import './style.scss';

const b = block('edit-person-form');

type Props = {
  isOpenForm: boolean;
  onClose?(): void;
  personId: string | null;
  disabled?: boolean;
};

function PersonCard(props: Props) {
  const { disabled = false, isOpenForm, onClose, personId } = props;
  const {
    localPerson,
    setLocalPerson,
    submitForm,
    setIsThereChanges,
    reloadPerson,
    isOpenWelcomeModal,
    onCloseWelcomeModal,
    isOpenLogoutNotify,
    goOutBySaving,
    tabsId,
  } = useController({
    personId,
    onClose,
    isOpen: isOpenForm,
  });
  const { settings, enumMap, userPermission } = useAppDataContext();

  const changeScientificField = useCallback(
    (fieldName: string, value: string | ReferenceItem | null) => {
      setLocalPerson(R.set(R.lensPath(['scientist', fieldName]), value));
    },
    [setLocalPerson],
  );

  const makeChangeHandler = (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => {
    setIsThereChanges(true);
    setLocalPerson(R.set(R.lensProp(fieldName), value));
  };

  const changeHonors = useCallback(
    (honors: EditableHonor[]) => {
      const map = R.groupBy(honor => honor.type!, honors) as Record<HonorType, EditableHonor[]>;
      const updatePerson = R.pipe(
        R.set(R.lensProp('academicRanks'), map.academicRank),
        R.set(R.lensProp('ranks'), map.rank),
        R.set(R.lensProp('degrees'), map.degree),
      );
      setLocalPerson(updatePerson as SetStateAction<Person.ScientistPerson | null>);
    },
    [setLocalPerson],
  );

  useEffect(() => {
    if (!localPerson?.scientist?.phoneOther) {
      changeScientificField('phoneOther', localPerson?.scientist?.phoneIntegration || '');
    }
    if (!localPerson?.scientist?.mobilePhoneOther) {
      changeScientificField('mobilePhoneOther', localPerson?.scientist?.mobilePhoneIntegration || '');
    }
    if (!localPerson?.scientist?.emailOther) {
      changeScientificField('emailOther', localPerson?.scientist?.emailIntegration || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changeScientificField,
    localPerson?.scientist?.phoneIntegration,
    localPerson?.scientist?.mobilePhoneIntegration,
    localPerson?.scientist?.emailIntegration,
  ]);

  return (
    <>
      <div
        className={b({
          'is-visible': localPerson !== null,
          'is-fullscreen': true,
          'is-animated': true,
        })}
      >
        <div className={b('wrapper')}>
          <div className={b('header-wrapper')}>
            <div className={b('header-logo')}>
              <LogoComponent />
            </div>

            <div className={b('header-title')}>
              <span className={b('header-title-hint')}>Система &laquo;УНИД НИ {settings?.organization?.shortName}&raquo;</span>
              <h4 className={b('header-title-user')}>
                Личный кабинет пользователя:{' '}
                {`${localPerson?.scientist?.lastName ?? ''} ${
                  localPerson?.scientist?.firstName ? `${localPerson.scientist.firstName} ` : ''
                } ${localPerson?.scientist?.patronymic ? `${localPerson.scientist.patronymic}` : ''}`}
              </h4>
            </div>
            <Topbar />
          </div>
          <div className={b('content')}>
            <Tabs id="account" isStrip>
              <Tab title="Новости">
                <Tabs id={tabsId} defaultTabIndex={1}>
                  <Tab title="Объявления">
                    <Blocks.Notices />
                  </Tab>
                  <Tab title="Конкурсы">
                    <Blocks.Notices isTenders />
                  </Tab>
                  <Tab title="Вопрос-ответ">
                    <GetQuestion />
                  </Tab>
                  <Tab title="Часто задаваемые вопросы">
                    <QuestionAndAnswersList isWithTitle={false} />
                  </Tab>
                  <Tab title="Справочные материалы и ссылки">
                    <FormComponent.ColumnWrapper>
                      <FormComponent.Column>
                        <Blocks.HelpfulStuff />
                      </FormComponent.Column>
                      <FormComponent.Column>
                        <Blocks.UsefulLinks />
                      </FormComponent.Column>
                    </FormComponent.ColumnWrapper>
                  </Tab>
                </Tabs>
              </Tab>
              <Tab title="Мой профиль">
                <FormComponent.Template>
                  <Toolbar saveForm={disabled ? undefined : submitForm} personId={personId} />

                  <FormComponent.Template>
                    <Tabs id="profile">
                      <Tab title="Мой профиль">
                        <Main
                          person={localPerson}
                          reloadPerson={reloadPerson}
                          userPermission={userPermission}
                          makeSetPersonField={makeChangeHandler}
                          onChange={changeScientificField}
                        />
                      </Tab>
                      <Tab title="Научные интересы">
                        <ScientificInterests person={localPerson} disabled={disabled} makeSetPersonField={makeChangeHandler} />
                      </Tab>
                      <Tab title="Дополнительные сведения">
                        <AdditionalInfo
                          enumMap={enumMap}
                          person={localPerson}
                          disabled={disabled}
                          makeSetPersonField={makeChangeHandler}
                          onChangeHonors={changeHonors}
                          userPermission={userPermission}
                          onChange={changeScientificField}
                          onChangeHirshIndexes={makeChangeHandler('hirshIndexes')}
                        />
                      </Tab>
                    </Tabs>
                  </FormComponent.Template>
                </FormComponent.Template>
              </Tab>
              <Tab title="Коллеги">
                <Colleagues />
              </Tab>
              <Tab title="Публикации">
                <Publications />
              </Tab>
              <Tab title="Участие в мероприятиях">
                <ParticipationsEvents />
              </Tab>
              <Tab title={`Мероприятия ${settings?.organization?.shortName}`}>
                <EventList />
              </Tab>
              <Tab title="Персональные достижения">
                <Recognitions />
              </Tab>
              <Tab title="Заявки на конкурсы">
                <DataGrid specification={GetPersonalRequestList({ hasToolbarButtonMode: true })} hasPersonalMode />
              </Tab>
              <Tab title="НИОКР">
                <RequestAndProjects personId={personId} />
              </Tab>
              <Tab title="ОИС">
                <SecurityDocuments personId={personId} />
              </Tab>
              {!!personId && (
                <Tab title="Руководство защитой">
                  <DataGrid specification={GetPersonalDissertationManagementList} hasPersonalMode />
                </Tab>
              )}
            </Tabs>
          </div>

          <ConfirmPopup
            title="Предупреждение"
            text="Сохранить изменения на вкладке 'Профиль' перед тем как выйти?"
            isOpen={isOpenLogoutNotify}
            okButtonText="Сохранить и выйти"
            resetButtonText="Выйти"
            onClose={() => goOutBySaving(false)}
            onConfirm={() => goOutBySaving(true)}
            icon="info"
          />
          <WelcomeModal isOpen={isOpenWelcomeModal} onClose={onCloseWelcomeModal} person={localPerson} />
        </div>
      </div>
    </>
  );
}

export { PersonCard };
