import React, { useMemo } from 'react';

import { Column, ListEditTable } from 'components';

import { Table, Event } from 'types/models';
// eslint-disable-next-line max-len
import { TypeControllerIsForm } from 'features/Table/specifications/GetExpositionEventList/LeftPanelForThirdLevel/modalTemplates/ExpositionParticipationTemplate/models/typeControllerIsForm';
import { RecordStatus } from 'utils/Enums/RecordStatus';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import ControlPanelParticipations from '../ControlPanelEventParticipations/controlPanelParticipations';

type Props = {
  participations: Event.Participations[];
  selectedRowIndex: number | null;
  setIsFormOpen: TypeControllerIsForm;
  setSelectedRowIndex: React.Dispatch<number | null>;
  workMode: Table.WorkMode;
  fetch: () => void;
};

const EventParticipations = ({
  participations,
  setIsFormOpen,
  workMode,
  selectedRowIndex,
  setSelectedRowIndex,
  fetch,
}: Props) => {
  const columns = useMemo<Column<Event.Participations>[]>(
    () => [
      { label: 'Статус', formatValue: row => row.status.name || '', styles: { width: '100px' } },
      {
        label: 'Руководитель',
        formatValue: row => row.supervisor,
        styles: { width: '100px' },
      },
      {
        label: 'Участники',
        formatValue: row => row.participant,
        styles: { width: '140px' },
      },
      {
        label: 'Название коллектива',
        formatValue: row => row.collectiveName || '',
        styles: { width: '140px' },
      },
      {
        label: 'Подразделения',
        formatValue: row => (row.departments ? row.departments : ''),
        styles: { width: '160px' },
      },
      { label: 'Факультеты/Институты', formatValue: row => row.faculties || '', styles: { width: '100px' } },
      { label: 'Примечания', formatValue: row => row.note || '', styles: { width: '100px' } },
      { label: 'Добавлено', formatValue: row => row.createdBy || '', styles: { width: '100px' } },
      { label: 'Дата добавления', formatValue: row => row.createdDate || '', styles: { width: '110px' } },
      { label: 'Изменено', formatValue: row => row.modifiedBy || '', styles: { width: '120px' } },
      { label: 'Дата изменения', formatValue: row => row.modifiedDate || '', styles: { width: '110px' } },
    ],
    [],
  );

  return (
    <>
      {setIsFormOpen && (
        <ControlPanelParticipations
          participationType={ParticipationTypes.PARTICIPATION_CONCERT.code}
          isApproved={
            !(selectedRowIndex === null) ? participations[selectedRowIndex!].status.status === RecordStatus.APPROVED : false
          }
          participations={participations}
          selectedRowIndex={selectedRowIndex}
          setIsFormOpen={setIsFormOpen}
          workMode={workMode}
          fetch={fetch}
        />
      )}

      <ListEditTable
        columns={columns}
        defaultRowsCount={participations.length > 10 ? participations.length : 10}
        rows={participations}
        selectedRowIndex={selectedRowIndex}
        selectRow={setSelectedRowIndex}
      />
    </>
  );
};

export const Component = React.memo(EventParticipations);
