import React from 'react';

import { Tabs, Tab, ConfirmPopup, FormComponent } from 'components';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { CantSaveInPreviousStatusPopup } from 'features/Form/views/CantSaveInPreviousStatusPopup';
import { WarningDraftStatusPopup } from 'features/Form/views/WarningDraftStatusPopup';
import { Component as BibliographicRecordErrorPopup } from 'features/Form/views/BibliographicRecordErrorPopup';
import { AuthorsComponent } from 'features/Form/views/AuthorsComponent';
import { Component as PublicPreprintPublicationsRelations } from './views/PreprintPublicationsRelations';
import { ControlPanel } from './views/ControlPanel';
import { useController } from './controller';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const PreprintFormArticle = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    workMode,
    formFields,
    onCreateBibliographicRecordClick,
    relatedTableState,
    sourceMagazine,
    sourceMagazineRelease,
    changeSourceMagazine,
    changeSourceMagazineRelease,
    onDeleteSourceMagazine,
    onDeleteSourceMagazineRelease,
    isElectronic,
    electronicType,
    publicationId,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    projects,
    mobileRequests,
    authors,
    setAuthors,
    documents,
    setDocuments,
    topMessage,
    setTopMessage,
    sourceMagazineOriginal,
    sourceMagazineTranslates,
    isPreprintPublicationsOpened,
    handleClosePublicationPreprintRelations,
    handleOpenPublicationPreprintRelations,
    isRequestPaymentView,
    isShowDetailedTitle,
    magazineReleaseReport,
    magazineReleaseConference,
    changePrintArticle,
    printArticle,
    onConfirmRelationsWarningPopup,
    onCloseRelationsWarningPopup,
    isRelationsWarningPopup,
    tabsId,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
  } = useController({
    onClose,
  });
  const RELATIONS_WARNING_TEXT = 'Для установки связи с публикацией заполните поле "Год" из справочника на вкладке "Источник"';

  return (
    <FormComponent.Template>
      <CantSaveInPreviousStatusPopup
        isOpen={isCantSaveInPreviousStatusPopupOpen}
        onConfirm={handleConfirmCantSaveInPreviousStatusPopup}
        onClose={handleCloseCantSaveInPreviousStatusPopup}
        text={cantSaveInPreviousStatusText}
      />
      <WarningDraftStatusPopup
        isOpen={isDraftWarningPopupOpen}
        onClose={handleCloseDraftWarningPopup}
        onConfirm={handleConfirmDraftWarningPopup}
      />
      <BibliographicRecordErrorPopup
        errorText={bibliographicRecordErrorText}
        textLanguageId={formFields.textLanguage.value?.id || ''}
        onConfirm={handleConfirmBibliographicRecordError}
        onClose={handleCloseBibliographicErrorPopup}
        isOpen={isBibliographicErrorPopupOpen}
      />
      <ConfirmPopup
        title="Внимание!"
        icon="info"
        isOpen={isAuthorsPopupOpen}
        okButtonText="Нет"
        resetButtonText="Добавить"
        onConfirm={handleConfirmAuthorsPopup}
        onClose={handleCloseAuthorsPopup}
        onReset={handleResetAuthorsPopup}
      >
        <div>
          <div>
            <strong>
              В списке «Мои публикации», отображаются только те, в которых вы указаны в разделе Сведения об авторстве, иначе в
              списке «Все публикации»
            </strong>
          </div>
          <br />
          <span>Вашей персоны нет в списке авторов. Добавить?</span>
        </div>
      </ConfirmPopup>

      {!viewMode && (
        <ControlPanel
          handleSave={handlePublicationSave}
          handleSaveAsDraft={handlePublicationSaveAsDraft}
          handleSaveAndContinue={handlePublicationSaveAndContinue}
          viewMode={viewMode}
          isOnlyEditing={isRequestPaymentView}
          handleOpenPublicationPreprintRelations={handleOpenPublicationPreprintRelations}
          isDisabled={isLoading}
        />
      )}

      <SuchLikePublication mode="edit" handleCloseOuterForm={onClose} componentId={SUCH_LIKE_PUBLICATION_ID} />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="О препринте">
            <TabsContent.BibliographyComponent
              formFields={formFields}
              workMode={workMode}
              isElectronic={isElectronic}
              electronicType={electronicType}
              publicationInfo={publicationInfo}
              isShowDetailedTitle={isShowDetailedTitle ?? false}
              userDepartments={userDepartments}
              publicationId={publicationId}
              publicationDepartment={publicationDepartment}
              setPublicationDepartment={setPublicationDepartment}
              arm={arm}
            />
          </Tab>
          <Tab title="Сведения об авторстве">
            <AuthorsComponent workMode={workMode} formFields={formFields} authors={authors} setAuthors={setAuthors} />
          </Tab>
          <Tab title="Сведения об издании">
            <TabsContent.SourceComponent
              publicationId={publicationInfo?.status.id || ''}
              isElectronic={isElectronic}
              relatedTableState={relatedTableState}
              formFields={formFields}
              onCreateBibliographicRecordClick={onCreateBibliographicRecordClick}
              workMode={workMode}
              magazineReleaseConference={magazineReleaseConference}
              changeSourceMagazine={changeSourceMagazine}
              changeSourceMagazineRelease={changeSourceMagazineRelease}
              onDeleteSourceMagazine={onDeleteSourceMagazine}
              onDeleteSourceMagazineRelease={onDeleteSourceMagazineRelease}
              sourceMagazine={sourceMagazine}
              sourceMagazineRelease={sourceMagazineRelease}
              sourceMagazineOriginal={sourceMagazineOriginal}
              sourceMagazineTranslates={sourceMagazineTranslates}
              magazineReleaseReport={magazineReleaseReport}
            />
          </Tab>
          <Tab title="О препринте(анг.)">
            <TabsContent.AboutEnglishComponent formFields={formFields} viewMode={viewMode} />
          </Tab>
          <Tab title="Индексация в базах">
            <TabsContent.DatabaseIndexing.Component
              citationSystems={publicationInfo?.citationSystems ?? []}
              relatedTableState={relatedTableState}
              publicationId={publicationId || null}
              publicationType={publicationInfo?.type ?? ''}
              addCitationSystem={addCitationSystem}
              editCitationSystem={editCitationSystem}
              removeCitationSystem={removeCitationSystem}
              workMode={workMode}
              sourceId={publicationInfo?.magazine?.id}
              releaseId={publicationInfo?.magazineRelease?.id}
              topMessage={topMessage}
              setTopMessage={setTopMessage}
              isVak={publicationInfo?.fields.isVak === 'true'}
            />
          </Tab>
          <Tab title="Финансовая поддержка">
            <TabsContent.FinancialSupport.Component
              projects={projects}
              mobileRequests={mobileRequests}
              incentivePaymentRequest={publicationInfo?.status?.incentivePaymentRequest || ''}
              relatedTableState={relatedTableState}
              workMode={workMode}
            />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent
              relatedTableState={relatedTableState}
              workMode={workMode}
              publicationId={publicationId}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <PublicPreprintPublicationsRelations
        isOpen={isPreprintPublicationsOpened}
        onClose={handleClosePublicationPreprintRelations}
        changePrintArticle={changePrintArticle}
        // onDeletePrintArticle={onDeletePrintArticle}
        printArticle={printArticle}
        viewMode={viewMode || false}
        relatedTableState={relatedTableState}
        sourceRelease={sourceMagazineRelease}
      />
      <ConfirmPopup
        title="Предупреждение"
        okButtonText="Перейти на вкладку 'Источник'"
        isOpen={isRelationsWarningPopup}
        onClose={onCloseRelationsWarningPopup}
        text={RELATIONS_WARNING_TEXT}
        onConfirm={onConfirmRelationsWarningPopup}
        resetButtonText="Закрыть"
        icon="info"
      />
    </FormComponent.Template>
  );
};

export const Component = React.memo(PreprintFormArticle);
