import React from 'react';

import { FormComponent, TextInput } from 'components';

import { MobileRequest } from 'types/models';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.TripPlan | null;
  onFieldChange(row: MobileRequest.TripPlan | null): void;
};

function TripPlanFields({ entry, onFieldChange }: Props) {
  const { handleQuestionChange, handleImplementationFormChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Перечень разрабатываемых (изучаемых) вопросов" isRequired>
        <TextInput value={entry?.question} onChange={handleQuestionChange} />
      </FormComponent.Field>
      <FormComponent.Field label="Форма реализации" isRequired>
        <TextInput value={entry?.implementationForm} onChange={handleImplementationFormChange} />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}

export { TripPlanFields };
