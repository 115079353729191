import { Table } from 'types/models';
import { Filters } from './filters';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  setProjectCodeId(projectCodeId: string): void;
};

export function GetProjectCodeListByYear({ setProjectCodeId }: Props): Table.Specification<State> {
  return {
    apiID: 'GetProjectCodeListByYear',
    header: {
      firstLevel: {
        title: 'Шифры',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    uniqKeys: ['id', 'year'],
    useCustomController: makeUseCustomController({ setProjectCodeId }),
  };
}
