import { EventType } from 'types/models/Payment';

export class EventTypeIncentivePayment {
  static readonly ATICLE: EventType = 'ARTICLE';

  static readonly CONFERENCE: EventType = 'CONFERENCE';

  static readonly MEDAL: EventType = 'MEDAL';

  static readonly MONOGRAPH: EventType = 'MONOGRAPH';

  static readonly TEXTBOOK: EventType = 'TEXTBOOK';
}
