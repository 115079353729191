import { useState, useLayoutEffect, useCallback, useMemo, useRef, useEffect } from 'react';

import { Form, Table, Estimate } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';

import { useFormContext } from 'features/Form/hooks';
import { Option } from 'components';

import { EstimateLook, Original } from 'types/models/Form';
import * as R from 'ramda';
import { EstimatePosition, EstimatePositionItem, EstimatePositionForBalance } from 'types/models/Estimate';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { GetProjectCodeSimpleSelectList } from 'features/Table/specifications/GetProjectCodeSimpleSelectList';
import { useAppDataContext } from 'features/AppData/context';
import { formatNumber, getEnum } from 'utils/Helpers';
import { CalendarPlanByStage, CalendarPlanByYear } from 'types/models/Project';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { showErrorsMessages } from 'utils/Common';
import { format } from 'date-fns';
import { useLocalTableStreams } from 'features/Table/hooks';
import { streams as globalStreams } from 'features/Table/streams';
import { useStreamsByApiID } from 'StreamRx';
import { KindConsumption } from 'types/models/KindConsumption';

type EstimatePositionItemExtendedKindConsumption = {
  kindConsumption: KindConsumption;
  quarter: number;
} & EstimatePositionItem;

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, projectCode, setNextPublicationId, saveAndEdit, estimate: initialEstimate, amountWarningMessage },
  } = useFormContext<EstimateLook>();

  const estimatePositionStreams = useStreamsByApiID(globalStreams, 'GetEstimatePositionList');

  const estimateId = useRef<string>('');

  const [estimate, setEstimate] = useState<Estimate | null>(null);
  const [estimatePositions, setEstimatePositions] = useState<EstimatePosition[] | []>([]);
  const [createdDate, setCreatedDate] = useState<string | null>(format(new Date(), formatStr));
  const [projectCodeId, setProjectCodeId] = useState<string | null>('');
  const [calendarPlans, setCalendarPlans] = useState<CalendarPlanByStage[] | []>([]);
  const [year, setYear] = useState<string>('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [projectCodeOriginal, setProjectCodeOriginal] = useState<Form.Original | null>();
  const [calendarPlansByYear, setCalendarPlansByYear] = useState<CalendarPlanByYear[] | []>([]);
  const [isReceivingDocumentModalOpen, setIsReceivingDocumentModalOpen] = useState<boolean>(false);
  const [isEstimateOverheadModalOpen, setIsEstimateOverheadModalOpen] = useState<boolean>(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState<boolean>(false);
  const [isStatusHistoryModalOpen, setIsStatusHistoryModalOpen] = useState<boolean>(false);
  const [isProjectCodeInfoModalOpen, setIsProjectCodeInfoModalOpen] = useState<boolean>(false);
  // const [selectedEstimatePositionItemIndex, setSelectedEstimatePositionItemIndex] = useState<number | null>(null);
  const [estimateStatus, setEstimateStatus] = useState<string>('');
  const [projectCodeEditShown, setProjectCodeEditShown] = useState<boolean>(true);
  const [yearOptions, setYearOptions] = useState<Option<string>[] | undefined>();
  const [isAmountWarningOpen, setIsAmountWarningOpen] = useState<boolean>(false);

  const projectCodeSpecification = GetProjectCodeSimpleSelectList({ setProjectCodeId });

  const { enumMap } = useAppDataContext();

  const estimateStatusOptions = getEnum('EstimateStatus', enumMap);

  const loadYearOptions = useCallback(
    (plans?: CalendarPlanByYear[]) => {
      const targetPlans = plans || calendarPlansByYear;
      const options = targetPlans.map<Option>(x => ({ value: x.year.toString(), label: x.year.toString() }));
      setYearOptions(options);
    },
    [calendarPlansByYear],
  );

  const { methods: createEstimate } = BackendAPI.useBackendAPI('CreateEstimate');

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const { methods: getEstimate } = BackendAPI.useBackendAPI('GetEstimate', {
    onSuccessfullCall: ({ data }) => {
      estimateId.current = data?.id || '';
      setEstimate(data as Estimate);
      setEstimatePositions(data.positions);
      if (data?.projectCode?.id) {
        setProjectCodeId(data?.projectCode?.id);
        setEstimateStatus(data?.status?.value || '');
        setCalendarPlansByYear(data.projectCode.calendarPlansByYears || []);
        loadYearOptions();
      }
    },
    onFailedCall: () => {},
  });

  const onCreateSubmit = useCallback(() => {
    if (projectCodeEditShown && !projectCodeOriginal) {
      showErrorsMessages(['Заполните поле "Дата составления"Шифр проекта"']);
    }
    if (!createdDate) {
      showErrorsMessages(['Заполните поле "Дата составления"']);
    } else {
      createEstimate.callAPI(
        {
          projectCodeId: projectCodeId || projectCode?.id || '',
          createdDate,
          year: year || new Date().getFullYear().toString(),
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Смета успешно сохранена', theme: 'success' });
            if (data?.id) {
              if (setNextPublicationId && data.id) {
                setNextPublicationId(data.id);
              }
              if (saveAndEdit) {
                saveAndEdit();
              } else {
                onClose();
              }
              getEstimate.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
            tableStreams.reloadTable.push();
          },
        },
      );
    }
  }, [
    projectCodeEditShown,
    projectCodeOriginal,
    createdDate,
    createEstimate,
    projectCodeId,
    projectCode?.id,
    year,
    tableStreams.reloadTable,
    setNextPublicationId,
    saveAndEdit,
    getEstimate,
    updateWorkModeAfterSaveAndContinue,
    onClose,
  ]);

  const handleAddFormSubmit = useCallback(() => {
    onCreateSubmit();
  }, [onCreateSubmit]);

  const handleFieldChange = useCallback(
    (fieldName: keyof Estimate) => (value: any) => {
      R.set(R.lensProp(fieldName), value, estimate!);
    },
    [estimate],
  );

  const { methods: getProjectCode } = BackendAPI.useBackendAPI('GetProjectCode', {
    onSuccessfullCall: ({ data }) => {
      setCalendarPlans(data.calendarPlansByStages || []);
      setCalendarPlansByYear(data.calendarPlansByYears || []);
      loadYearOptions(data.calendarPlansByYears || []);
    },
  });

  const handleProjectCodeChange = useCallback(
    (fieldName: keyof Estimate) => (value: Original | null) => {
      R.set(R.lensProp(fieldName), value, estimate!);
      if (value?.id) {
        getProjectCode.callAPI({ id: value.id });
        setProjectCodeOriginal(value);
      }
    },
    [estimate, getProjectCode],
  );

  const handleCreateDateChange = useCallback(
    (value: string | null) => {
      setCreatedDate(value);
    },
    [setCreatedDate],
  );

  const projectCodeRowConverter = useCallback<(row: Table.Entry) => Original>(
    row => ({
      id: row.id || '',
      name: row.projectNumber,
    }),
    [],
  );

  useEffect(() => {
    if (amountWarningMessage) setIsAmountWarningOpen(true);
  }, [amountWarningMessage]);

  useLayoutEffect(() => {
    if (initialEstimate) setEstimate(initialEstimate);
    else {
      if (id) {
        getEstimate.callAPI({ id });
      }
      if (projectCode) {
        loadYearOptions(projectCode.calendarPlansByYears);
        setProjectCodeOriginal({ id: projectCode?.id || '', name: projectCode?.code || '' });
        setProjectCodeEditShown(false);
      }
    }
    // eslint-disable-next-line
  }, [setCalendarPlansByYear]);

  const handleEstimatePositionChange = useCallback((values: EstimatePosition[]) => {
    setEstimatePositions(values);
  }, []);

  const handleYearChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (fieldName: keyof Estimate) => (option: Option<string> | null) => {
      setYear(option?.value || '');
      // R.set(R.lensProp(fieldName), option?.value, estimate!); // Not working
    },
    [],
  );

  const receivingDocumentsModalTitle = useCallback(
    () => `Поступления на шифр ${estimate?.projectCode?.code} за ${estimate?.year}`,
    [estimate],
  );

  const { methods: getBalanceEstimatePosition } = BackendAPI.useBackendAPI('GetBalanceEstimatePosition', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Сумма план, успешно обновлены', theme: 'success' });
      estimatePositionStreams.reloadTable.push();
    },
  });

  const handleReceivingDocumentsClick = useCallback(() => {
    setIsReceivingDocumentModalOpen(true);
  }, [setIsReceivingDocumentModalOpen]);

  const handleEstimateOverheadClick = useCallback(() => {
    setIsEstimateOverheadModalOpen(true);
  }, [setIsEstimateOverheadModalOpen]);

  const handleChangeStatusClick = useCallback(() => {
    setIsChangeStatusModalOpen(true);
  }, [setIsChangeStatusModalOpen]);

  const handleStatusHistoryClick = useCallback(() => {
    setIsStatusHistoryModalOpen(true);
  }, [setIsStatusHistoryModalOpen]);

  const handleProjectCodeInfoClick = useCallback(() => {
    setIsProjectCodeInfoModalOpen(true);
  }, [setIsProjectCodeInfoModalOpen]);

  const handleBalanceClick = useCallback(
    (ids: EstimatePositionForBalance[]) => {
      getBalanceEstimatePosition.callAPI({
        ids: ids.map(x => {
          x.estimateId = estimateId.current;
          return x;
        }),
      });
    },
    [getBalanceEstimatePosition],
  );

  const { methods: changeEstimateStatus } = BackendAPI.useBackendAPI('ChangeEstimateStatus');

  const handleSaveStatusClick = useCallback(() => {
    changeEstimateStatus.callAPI(
      { estimateId: estimate?.id || '', estimateStatus: estimateStatus || '' },
      {
        onSuccessfullCall: () => {
          if (id) {
            getEstimate.callAPI({ id });
          }
        },
      },
    );

    setIsChangeStatusModalOpen(false);
  }, [changeEstimateStatus, estimate?.id, estimateStatus, id, getEstimate]);

  const refreshEstimate = useCallback(() => {
    if (id) {
      getEstimate.callAPI({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const estimateHasPhantomPositions = useCallback(() => {
    const phantom = estimatePositions.filter(ep => !ep.id);
    return phantom && phantom.length > 0;
  }, [estimatePositions]);

  const estimateHasOverheadPositions = useCallback(() => estimatePositions.filter(ep => ep.kindConsumption?.isSendOverhead), [
    estimatePositions,
  ]);

  const handleChangeStatus = useCallback(
    option => {
      if (option.value === 'EXECUTED') {
        if (estimateStatus === 'ACTIVE') {
          if (estimate?.amountByYear === estimate?.amountReceivedByYear) {
            if (estimateHasPhantomPositions()) {
              showNotification({ message: 'Исполнение сметы невозможно. Есть отсутствующие позиции.', theme: 'danger' });
            } else if (estimate?.amountDisbalance !== 0) {
              showNotification({
                message: 'Исполнение сметы невозможно. Сумма по факту не совпадает с плановой.',
                theme: 'danger',
              });
            } else if (estimate?.amountToTransite !== 0) {
              showNotification({
                message: `В смете имеется конечное сальдо: ${formatNumber(
                  estimate.amountToTransite,
                )}.\n Выберите рабочую смету, на которую нужно передать остатки.`,
                theme: 'danger',
              });
            } else {
              setEstimateStatus(option.value);
            }
          }
        } else {
          setEstimateStatus(option.value);
        }
      } else {
        showNotification({
          message: 'Исполнение сметы невозможно. Плановая сумма по шифру не совпадает с поступлением.',
          theme: 'danger',
        });
      }
      if (option.value === 'REJECTED' && estimateStatus === 'ACTIVE' && estimateHasOverheadPositions()) {
        showNotification({
          message: 'Отменить смету невозможно. Необходимо удалить позиции, предполагающие передачу накладных.',
          theme: 'danger',
        });
      }
    },
    [setEstimateStatus, estimateStatus, estimate, estimateHasOverheadPositions, estimateHasPhantomPositions],
  );

  const estimatePositionItems = useMemo(() => {
    let itemList = [] as EstimatePositionItemExtendedKindConsumption[];

    estimatePositions.forEach(position => {
      const items = (position.items || []).map(i => {
        return {
          ...i,
          kindConsumption: position.kindConsumption,
          quarter: Math.floor(parse(i.date || '', formatStr, new Date()).getMonth() / 3 + 1),
        } as EstimatePositionItemExtendedKindConsumption;
      });
      itemList = [...itemList, ...items];
    });

    // sorting by date
    itemList.sort((a, b) => {
      return parse(a.date || '', formatStr, new Date()).getTime() - parse(b.date || '', formatStr, new Date()).getTime();
    });

    // grouping by quarter
    // const result: EstimatePositionItemExtendedKindConsumption[][] = [];

    // itemList.forEach(item => {
    //   result[item.quarter] = [...(result[item.quarter] || []), item];
    // });

    return itemList;
  }, [estimatePositions]);

  const selectEstimateYear = useCallback(() => {
    if (yearOptions !== undefined && yearOptions?.length > 0) {
      const yearToSelect = year || new Date().getFullYear().toString();
      const option = yearOptions?.find(x => x.value === yearToSelect) || yearOptions[0];
      if (!year && option) {
        setYear(option.value);
      }
      return option;
    }
    return { value: '', label: '' } as Option<string>;
  }, [year, setYear, yearOptions]);

  return {
    estimateId: id ?? estimateId.current ?? null,
    refreshEstimate,
    estimate,
    projectCodeId,
    handleBalanceClick,
    handleAddFormSubmit,
    createdDate,
    handleCreateDateChange,
    workMode,
    handleEstimatePositionChange,
    estimatePositions,
    estimatePositionItems,
    projectCodeOriginal,
    setProjectCodeOriginal,
    projectCodeSpecification,
    projectCodeRowConverter,
    handleFieldChange,
    handleProjectCodeChange,
    year,
    yearOptions,
    calendarPlans,
    handleYearChange,
    selectedRowIndex,
    setSelectedRowIndex,
    isReceivingDocumentModalOpen,
    setIsReceivingDocumentModalOpen,
    handleReceivingDocumentsClick,
    receivingDocumentsModalTitle,
    isEstimateOverheadModalOpen,
    setIsEstimateOverheadModalOpen,
    handleEstimateOverheadClick,
    isChangeStatusModalOpen,
    setIsChangeStatusModalOpen,
    handleChangeStatusClick,
    handleSaveStatusClick,
    estimateStatusOptions,
    estimateStatus,
    setEstimateStatus,
    handleChangeStatus,
    isStatusHistoryModalOpen,
    setIsStatusHistoryModalOpen,
    handleStatusHistoryClick,
    isProjectCodeInfoModalOpen,
    setIsProjectCodeInfoModalOpen,
    handleProjectCodeInfoClick,
    selectEstimateYear,
    projectCodeEditShown,
    setYear,
    // selectedEstimatePositionItemIndex,
    // setSelectedEstimatePositionItemIndex,
    isAmountWarningOpen,
    setIsAmountWarningOpen,
    amountWarningMessage,
  };
}
