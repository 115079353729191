import { useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { buttonIcons } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Form as F, Form, Rid, RidAuthor, SecurityDocumentMember, Table as T, SecurityDocumentMemberTypes } from 'types/models';
import { Original } from 'types/models/Form';
import { GetFullScienceProjectList } from 'features/Table/specifications';
import { ValueOf } from 'types/helpers';
import { Department } from 'types/models/Department';
import { copyJob, copyEducation, copyAcademicRank, copyDegree, copyRank } from 'utils/Helpers/getHistoryCopy';
import { showNotification } from 'features/Notifications';

type Props = {
  rntd?: Rid | null;
  formFields: Form.Fields;
  makeChangeHandler(key: keyof Rid): (value: ValueOf<Rid>) => void;
};

export function useController(props: Props) {
  const { formFields, rntd, makeChangeHandler } = props;

  const projectOriginal = useMemo((): Original => {
    const project = formFields.project?.value || null;
    return {
      id: project?.id || '',
      name: project
        ? `${project.type?.label}, ${project.number}, ${project.startDate} - ${project.endDate}, ${project.name}`
        : '',
    };
  }, [formFields]);

  const projectRowConverter = useCallback<(row: T.Entry) => F.Translation>(row => {
    const dates = row.period.split(' - ');
    return {
      id: row.id,
      name: row.name,
      type: { label: row.projectType },
      number: row.projectNumber,
      startDate: dates[0],
      endDate: dates[1],
    };
  }, []);

  const projectSpecification = GetFullScienceProjectList({});

  const handleAuthorsChange = useCallback(
    (value: RidAuthor[]) => {
      makeChangeHandler('authors')(value);
    },
    [makeChangeHandler],
  );

  const handleDepartmentsChange = useCallback(
    (value: Department[]) => {
      makeChangeHandler('departments')(value);
    },
    [makeChangeHandler],
  );

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Department>[]>(
    () => [
      {
        title: 'Скопировть из проекта',
        icons: buttonIcons.clone,
        onClick: () => {
          getProjectAPI.callAPI(
            { id: rntd?.project?.id || '' },
            {
              onSuccessfullCall: ({ data }) => {
                const projectDepartments: Department[] =
                  data?.departments
                    ?.map(d => {
                      if (d.id && d.governance && d.unit && !rntd?.departments.find(pd => pd?.unit?.id === d?.unit?.id)) {
                        return {
                          id: d.id ?? '',
                          governance: d.governance,
                          unit: d.unit,
                        };
                      }
                      return { id: '', governance: { id: '', label: '' }, unit: { id: '', label: '' } };
                    })
                    .filter(d => d.id !== '') || [];
                handleDepartmentsChange([...(rntd?.departments || []), ...(projectDepartments || [])]);
              },
            },
          );
        },
      },
    ],
    [rntd, handleDepartmentsChange, getProjectAPI],
  );

  const authorsExtraToolbarButtons = useMemo<ExtraToolbarButton<RidAuthor>[]>(
    () => [
      {
        title: 'Скопировть из проекта',
        icons: buttonIcons.clone,
        onClick: () => {
          const securityDocumentAuthors = (rntd?.securityDocuments || [])
            .reduce<SecurityDocumentMember[]>(
              (accum, currentSecurityDocument) => [...accum, ...currentSecurityDocument.members],
              [],
            )
            .filter(member => member.type?.value === SecurityDocumentMemberTypes.AUTHOR);

          const isSecurityDocumentsEmpty = rntd?.securityDocuments.length === 0;
          const isSecurityDocumentAuthorsEmpty = securityDocumentAuthors.length === 0;
          const isSecurityDocumentAuthorsAlredyIn = securityDocumentAuthors.every(author =>
            rntd?.authors.some(rntdAuthor => rntdAuthor?.person?.id === author?.person?.id),
          );
          if (isSecurityDocumentsEmpty) {
            showNotification({ message: 'Не добавлен ни один охранный документ', theme: 'danger' });
          } else if (isSecurityDocumentAuthorsEmpty) {
            showNotification({ message: 'Список авторов охранных документов пуст', theme: 'danger' });
          } else if (isSecurityDocumentAuthorsAlredyIn) {
            showNotification({ message: 'Все авторы охранных документов уже есть в списке', theme: 'danger' });
          } else {
            const preparedSecurityDocumentAuthors: RidAuthor[] = securityDocumentAuthors.map(securityDocumentAuthor => ({
              id: null,
              citizenship: securityDocumentAuthor.citizenship,
              name: securityDocumentAuthor.name,
              person: securityDocumentAuthor.person,
              job: copyJob(securityDocumentAuthor.job),
              education: copyEducation(securityDocumentAuthor.education),
              academicRank: copyAcademicRank(securityDocumentAuthor.academicRank),
              degree: copyDegree(securityDocumentAuthor.degree),
              rank: copyRank(securityDocumentAuthor.rank),
              historyLabel: '',
            }));

            makeChangeHandler('authors')([...(rntd?.authors || []), ...preparedSecurityDocumentAuthors]);
            showNotification({ message: 'Авторы охранных документов успешно скопированы', theme: 'success' });
          }
        },
      },
    ],
    [makeChangeHandler, rntd?.authors, rntd?.securityDocuments],
  );
  return {
    projectOriginal,
    projectSpecification,
    extraToolbarButtons,
    authorsExtraToolbarButtons,
    projectRowConverter,
    handleAuthorsChange,
    handleDepartmentsChange,
  };
}
