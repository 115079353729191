import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  setProgramEventId(programEventId: string): void;
  programId?: string;
};

export function GetProgramEventSelectList({ setProgramEventId, programId }: Props): Table.Specification<State> {
  return {
    apiID: 'GetProgramEventSelectList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    requestData: { programId },
    useCustomController: makeUseCustomController({ setProgramEventId, programId }),
  };
}
