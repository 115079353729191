import React, { useMemo } from 'react';

import {
  Button,
  ButtonMode,
  FormComponent,
  Icon,
  IconButton,
  ListEdit,
  Radio,
  Tooltip,
  TextInput,
  TextInputMode,
} from 'components';

import { Column, Mode } from 'components/ListEdit/model';
import { Event } from 'types/models';
import { MemberCountAnother } from 'types/models/Event';
import { MemberCountAnotherForm } from './MemberCountAnotherForm';
import { formatFromText } from './helpers';
import useController from './controller';

type Props = {
  mode: Mode;
  memberCounts: Event.MemberCount[];
  memberCountsAnother: Event.MemberCountAnother[];
  disabled?: boolean;
  documentMember: Event.MemberCount | null;
  onChangeMember(row: Event.MemberCount): Promise<void>;
  hasShortForm?: boolean;
};

const MemberCountComponent = ({
  mode,
  memberCounts,
  memberCountsAnother,
  disabled,
  documentMember,
  onChangeMember,
  hasShortForm,
}: Props) => {
  const {
    documentHandler,
    onMemberCountChange,
    radioRefs,
    isValidFields,
    isOpenedCollapsible,
    changeIsOpenedCollapsible,
    handleAmountField,
    calculateButton,
    isSelectedType,
    selectType,
    checkIsMembersCorrect,
  } = useController({
    memberCountsAnother,
    documentMember,
    onChangeMember,
    memberCounts,
  });
  const isEditMode = useMemo(() => mode === 'edit', [mode]);
  const isShownRadioButtons = useMemo(() => !isEditMode && !isSelectedType, [isEditMode, isSelectedType]);
  const isShownData = useMemo(() => isEditMode || isSelectedType, [isEditMode, isSelectedType]);
  const isThereMembersCountsList = useMemo(() => radioRefs.memberCountList.length, [radioRefs.memberCountList]);

  const columns: Column<Event.MemberCountAnother>[] = useMemo(
    () =>
      [
        { label: 'Откуда', formatValue: (row: MemberCountAnother) => formatFromText(row) },
        { label: 'Всего', formatValue: (row: MemberCountAnother) => row.amount },
      ].concat(
        hasShortForm
          ? []
          : [
              { label: 'Профессоров', formatValue: row => row.professors },
              { label: 'Студентов', formatValue: row => row.students },
            ],
      ),
    [hasShortForm],
  );
  return (
    <FormComponent.Wrapper>
      {isShownRadioButtons && (
        <FormComponent.Line>
          {isThereMembersCountsList ? (
            <Radio
              isDisabled={isOpenedCollapsible || memberCountsAnother.length > 0}
              value={radioRefs.memberCountVal}
              list={radioRefs.memberCountList}
              onChange={onMemberCountChange}
              isInRow={false}
            />
          ) : (
            <FormComponent.Line hasFreeFormat>
              <Icon type="danger" size={40} />
              <strong>
                Участники по всем категориям уже внесены в таблицу. Для изменения или добавления данных отредактируйте уже
                имеющуюся запись
              </strong>
            </FormComponent.Line>
          )}
        </FormComponent.Line>
      )}
      {isShownData ? (
        <>
          <FormComponent.Line hasFreeFormat hasGap>
            <FormComponent.Field label={`Всего в категории ${documentMember?.label}`}>
              <TextInput
                mode={TextInputMode.number}
                value={documentMember?.amount || '0'}
                onChange={handleAmountField}
                settings={{ decimal: false }}
              />
            </FormComponent.Field>
            <IconButton {...calculateButton} key={calculateButton.title} />
            <Tooltip
              text={
                // eslint-disable-next-line max-len
                'После редактирования (добавления/удаления) информации об участниках и их количестве необходимо нажать на кнопку калькулятора «Рассчитать» и далее нажать «Сохранить».'
              }
            />
          </FormComponent.Line>

          <ListEdit
            isToolbarDisabled={disabled}
            maxHeight="400px"
            defaultRowsCount={3}
            rows={memberCountsAnother}
            title={`В том числе в категории ${documentMember?.label}`}
            onOpenedHandler={() => changeIsOpenedCollapsible(true)}
            onClosedHandler={() => changeIsOpenedCollapsible(false)}
            onChange={documentHandler}
            columns={columns}
            columnIndexesForSumTotal={[1, 2, 3]}
            columnIndexesForIntegerTotal={[1, 2, 3]}
            specification={{
              mode: 'customComponent',
              validation: {
                checkIsValid: row => isValidFields(row),
              },
              onPreSubmit: checkIsMembersCorrect,
              renderComponent: (row, onChange) => (
                <MemberCountAnotherForm
                  isOpenedCollapsible={isOpenedCollapsible}
                  actualRef={radioRefs.memberCountVal}
                  document={row}
                  onChange={onChange as any}
                  hasShortForm={hasShortForm}
                />
              ),
            }}
            withMessages
            isDeleteConfirmEnabled
          />
        </>
      ) : (
        <FormComponent.Actions>
          {!!isThereMembersCountsList && (
            <Button mode={ButtonMode.primary} text="Далее" onClick={selectType} isDisabled={!radioRefs.memberCountVal} />
          )}
        </FormComponent.Actions>
      )}
    </FormComponent.Wrapper>
  );
};

export { MemberCountComponent };
