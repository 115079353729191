import React, { useMemo } from 'react';

import { FormComponent, Modal } from 'components';

import { Table as T } from 'types/models';
import { FetchCompilationProcess } from 'services/BackendAPI/configurations/publication/types';
import ChangeCompilationTypes from './ChangeCompilationTypes';
import ChangeArticleTypes from './ChangeArticleTypes';
import ChangeElectronicTypes from './ChangeElectronicTypes';
import useController from './controller';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onSelect: FetchCompilationProcess;
  relatedTableState: T.State;
  type: string | null;
  id: string;
};

const CompilationTransformationTemplate = (props: Props) => {
  const { isOpen, onClose, relatedTableState, onSelect, type, id } = props;
  const { publicationInfo } = useController({ id });

  const title = useMemo(
    () => {
      if (type === 'CHANGE_CONFERENCE_ARTICLE_TYPE') {
        return 'Изменить тип статей в сборнике материалов конференции';
      }
      if (type === 'CHANGE_TYPE') {
        return 'Изменить тип статей';
      }
      if (type === 'SWITCH_ELECTRONIC') {
        return 'Трансформация сборника и статей';
      }

      return '';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Сборник:">
            <strong>{publicationInfo?.fields.name || ''}</strong>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тип сборника:">
            <strong>{publicationInfo?.status.typeLabel || ''}</strong>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид:">
            <strong>
              {publicationInfo?.fields?.electronicTypeLabel
                ? `Электронный ${publicationInfo?.fields.electronicTypeLabel.toLocaleLowerCase()}`
                : 'Печатный'}
            </strong>
          </FormComponent.Field>
        </FormComponent.Line>
        {!!publicationInfo && (
          <>
            {type === 'CHANGE_CONFERENCE_ARTICLE_TYPE' && (
              <ChangeArticleTypes
                onClose={onClose}
                onSelect={onSelect}
                relatedTableState={relatedTableState}
                action={type}
                id={id}
              />
            )}
            {type === 'CHANGE_TYPE' && (
              <ChangeCompilationTypes
                onClose={onClose}
                onSelect={onSelect}
                relatedTableState={relatedTableState}
                action={type}
                id={id}
                currentCompilationTypeLabel={publicationInfo?.status.typeLabel || ''}
              />
            )}
            {type === 'SWITCH_ELECTRONIC' && (
              <ChangeElectronicTypes
                onClose={onClose}
                onSelect={onSelect}
                isElectronic={!!(publicationInfo?.fields.isElectronic === 'true')}
                action={type}
                id={id}
              />
            )}
          </>
        )}
      </FormComponent.Wrapper>
    </Modal>
  );
};

export const Component = React.memo(CompilationTransformationTemplate);
