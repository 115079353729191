import { useMemo, useEffect } from 'react';
import { TS } from 'services/BackendAPI';
import { getIsPending } from 'utils/Helpers/getIsPending';

type Props = {
  callStates: TS.CallState<any>[];
};

const useIsLoading = ({ callStates }: Props) => {
  const isLoading = useMemo(() => callStates.some(callState => getIsPending(callState)), [callStates]);

  useEffect(() => {
    const waitingClass = 'waiting';
    if (isLoading) {
      document.body.classList.add(waitingClass);
    } else {
      document.body.classList.remove(waitingClass);
    }
  }, [isLoading]);
  return {
    isLoading,
  };
};

export default useIsLoading;
