import React from 'react';

import { Checkbox, FormComponent, TextGridSelect } from 'components';

import { UserActivationKind } from 'types/models/UserActivation';
import { GetRoleList } from 'features/Table/specifications';

import { useController } from './controller';

type Props = {
  item: UserActivationKind | null;
  setItem(item: UserActivationKind | null): void;
};

export const Fields = ({ item, setItem }: Props) => {
  const { handleRoleChange, makeHandler, tableRowConverter } = useController({ item, setItem });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Автоактивация">
          <Checkbox checked={item?.activate === 'true'} onChange={val => makeHandler('activate')(val ? 'true' : 'false')} />
        </FormComponent.Field>
        <FormComponent.Field label="Назначить роль">
          <TextGridSelect
            value={item?.roles[0]}
            onChange={handleRoleChange}
            specification={GetRoleList({ hasSelectButton: true })}
            valueFormatter={x => x?.name || ''}
            settings={{
              mode: 'selectTableRow',
              tableRowConverter,
            }}
            title="Роль"
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};
