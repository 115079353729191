import { Community, Affiliation, Vak, Grnti } from 'types/models';
import { getMaybeField } from '../commonConverters';
import { convertDocumentsToRequest } from '../converters';
import { getHistoricalSliceXML } from 'services/BackendAPI/configurations/author/requestConverters';

export function convertCommunityToXML(community: Community.Community) {
  const {
    id,
    type,
    code,
    foundationDate,
    isLocal,
    affiliation,
    vaks,
    grntis,
    members,
    documents,
    orderNumber,
    orderDate,
    note,
    magazine,
  } = community;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Type', type?.value),
    ...getMaybeField('Code', code),
    ...getMaybeField('FoundationDate', foundationDate),
    ...getMaybeField('IsLocal', isLocal),
    ...getMaybeField('OrderNumber', orderNumber),
    ...getMaybeField('OrderDate', orderDate),
    ...getMaybeField('Note', note),
    ...(isLocal && affiliation ? { Affiliation: convertAffiliationToXML(affiliation) } : {}),
    ...(affiliation && !isLocal ? { Affiliation: convertAffiliationToXML(affiliation) } : {}),
    ...(affiliation && !isLocal ? { Affiliation: convertAffiliationToXML(affiliation) } : {}),
    ...(magazine ? { Magazine: { _attr: { id: magazine.id } } } : {}),
    Vaks: vaks.length ? { Vak: vaks.map(convertVakToXML) } : {},
    Grntis: grntis.length ? { Grnti: grntis.map(convertGrntiToXML) } : {},
    Members: members.length ? { Member: members.map(convertMemberToXML) } : {},
    ...(documents?.length ? convertDocumentsToRequest(documents) : {}),
  };
}

export function convertAffiliationToXML(affiliation: Affiliation) {
  const { id, partner, enterprise, enterpriseCustomer } = affiliation;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(partner?.id ? { Partner: { _attr: { id: partner.id } } } : {}),
    ...(enterprise?.id ? { Enterprise: { _attr: { id: enterprise.id } } } : {}),
    ...(enterpriseCustomer?.id ? { EnterpriseCustomer: { _attr: { id: enterpriseCustomer.id } } } : {}),
  };
}

function convertVakToXML(vak: Vak) {
  const { id, element } = vak;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(element.id ? { Element: { _attr: { id: element.id } } } : {}),
  };
}

function convertGrntiToXML(grnti: Grnti) {
  const { id, element } = grnti;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(element.id ? { Element: { _attr: { id: element.id } } } : {}),
  };
}

export function convertMemberToXML(member: Community.Member) {
  const { id, role, joinDate, leaveDate, scientist, vakSpeciality, cooperationType, description, documents } = member;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(role?.value ? { Role: role.value } : {}),
    ...(joinDate ? { JoinDate: joinDate } : {}),
    ...(leaveDate ? { LeaveDate: leaveDate } : {}),
    ...(scientist?.id ? { Scientist: { _attr: { id: scientist?.id } } } : {}),
    ...getHistoricalSliceXML(member),
    ...(vakSpeciality?.id ? { VakSpeciality: { _attr: { id: vakSpeciality.id } } } : {}),
    ...(cooperationType?.value ? { CooperationType: cooperationType.value } : {}),
    ...getMaybeField('Description', description),
    ...(documents?.length ? convertDocumentsToRequest(documents) : {}),
  };
}
