import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'filter',
      list: [
        { label: 'Все', value: 'isAllRequests' },
        { label: 'По подразделению (Университет)', value: 'isUserDepartmentRequests' },
        { label: 'Мои заявки', value: 'isUserRequests' },
      ],
      value: 'isUserRequests',
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: false,
    },
  ]);
});
