import { Table } from 'types/models';

import { Form } from 'features/IncentivePayments/Form';
import { Toolbar } from 'features/IncentivePayments/Toolbar/Toolbar';
import { Filters } from 'features/IncentivePayments/filters';
import { State } from 'features/IncentivePayments';

import { makeUseCustomController } from './makeUseCustomController';

export function GetConferencePaymentRequests(): Table.Specification<State> {
  return {
    apiID: 'GetConferencePaymentRequests',
    header: {
      firstLevel: {
        title: 'Заявки на оплату конференций',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController('CONFERENCE'),
    NeighborTableComponent: Form,
  };
}
