import { useCallback, useEffect, useMemo, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';

import { Department, Publication, Form } from 'types/models';

import { showNotification } from 'features/Notifications';

import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { useLocalTableStreams } from 'features/Table/hooks';

type Arguments = {
  publication: Publication | null;
};

export function useController(args: Arguments) {
  const { publication } = args;
  const tableStreams = useLocalTableStreams();
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    setDepartments(publication?.departments ?? []);
  }, [publication]);

  const { methods: saveDepartmentsAPI } = BackendAPI.useBackendAPI('SavePublicationDepartments');

  const publicationType = useMemo<Form.PublicationType | null>(() => {
    const publicationTypesArray = Object.entries(PublicationTypes);

    const currentPublicationTypeEntrie = publicationTypesArray.find(([, { code }]) => code === publication?.type.value);
    if (currentPublicationTypeEntrie) {
      const currentPublicationType: Form.PublicationType = currentPublicationTypeEntrie[1];
      return currentPublicationType;
    }

    return null;
  }, [publication]);

  const saveDepartments = useCallback(() => {
    if (publication) {
      saveDepartmentsAPI.callAPI(
        { publicationId: publication.id, departments },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Связь с подразделениями успешно сохранена', theme: 'success' });
            tableStreams.reloadTable.push();
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publication, departments]);

  return {
    setDepartments,
    saveDepartments,
    publicationType,
    departments,
  };
}
