import { Project } from 'types/models';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants/FormatStr';
import { JobPeriod } from 'types/models/Project/index';

export function getActualJobPeriod(performer: Project.Performer): JobPeriod | null {
  const jobPeriods = performer?.jobPeriods || [];
  const actualDate = new Date().getTime();

  let actualJobPeriod: JobPeriod | null = null;
  jobPeriods.forEach(jobPeriod => {
    const startDate = jobPeriod?.startDate ? parse(jobPeriod?.startDate || '', formatStr, new Date()).getTime() || null : null;
    const endDate = jobPeriod?.endDate ? parse(jobPeriod?.endDate || '', formatStr, new Date()).getTime() || null : null;
    if ((startDate === null || startDate < actualDate) && (endDate === null || endDate > actualDate)) {
      actualJobPeriod = jobPeriod;
    }
  });
  return actualJobPeriod || null;
}
