// Delimiters
export const delimiterDate = '.';
export const delimiterTime = ':';
export const delimiterDateTime = ' ';
export const delimiterRange = '-';

// DATA
// Date
// format(date, str, { locale: ru }), where 'ru' - import { ru } from 'date-fns/locale';
export const formatStrDay = 'dd';
export const formatStrMonth = 'MM';
export const formatStrMonthAsWord = 'LLLLLLLL';
export const formatStrMonthAsWordGenitive = 'MMMMMMMM';
export const formatStrYear = 'yyyy';
export const formatStr = [formatStrDay, formatStrMonth, formatStrYear].join(delimiterDate);

// Time
export const formatTimeStrHour = 'HH';
export const formatTimeStrMinute = 'mm';
export const formatTimeStrSecond = 'ss';
export const formatTimeStr = [formatTimeStrHour, formatTimeStrMinute, formatTimeStrSecond].join(delimiterTime);

// Common
export const formatDateTimeStr = [formatStr, formatTimeStr].join(delimiterDateTime);

// MOCKS
const reg = /\w/gim;
export const formatToMock = (val: string) => val.replace(new RegExp(reg), '_');
