import { Table } from 'types/models';

import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export function GetPersonalPurchaseRequestList(): Table.Specification<State> {
  return {
    apiID: 'GetPersonalPurchaseRequestList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Закупки по проектам',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController(),
  };
}
