import React, { useMemo } from 'react';

import { Table } from 'types/models';
import { IconButtonProps, Toolbar as SharedToolbar, buttonIcons } from 'components';

import { BuildReportPopup, Reports } from 'features/BuildReportPopup';
import { buildPaymentRequestPeriodQuery } from 'features/BuildReportPopup/helpers';
import { getUniqArray } from 'utils/Helpers/getUniqArray';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const {
    customState: { isReportOpen, currentReport, getReports, handleSetCurrentReport, onReportClose },
    tableState,
  } = props;

  const [row] = tableState.selectedRows;
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.print,
        title: 'Печать',
        code: 'print',
        isDisabled: !row,
        onClick: () => {},
        getExpandedList: getReports,
        expandedItemCallback: handleSetCurrentReport,
      },
    ],
    [row, getReports, handleSetCurrentReport],
  );

  const requestTypeOrdinal = useMemo(() => {
    if (row) {
      switch (currentReport?.name) {
        case Reports.MemorandumSummary.name:
        case Reports.DepartmentsNominations.name:
        case Reports.ConsolidatedStatement.name:
          const requestTypeOrdinals = getUniqArray(tableState.selectedRows.map(({ typeOrd }) => typeOrd));
          return requestTypeOrdinals.join(',');
        default:
      }
    }
  }, [currentReport?.name, row, tableState.selectedRows]);

  const userDepartments = useMemo(() => {
    if (row) {
      switch (currentReport?.name) {
        case Reports.MemorandumSummary.name:
          const departments = getUniqArray(tableState.selectedRows.map(({ 'id:faculty': idFaculty }) => idFaculty));
          return departments.join(',');
        default:
      }
    }
  }, [currentReport?.name, row, tableState.selectedRows]);

  const paymentRequestPeriod = useMemo(() => {
    if (row) {
      switch (currentReport?.name) {
        case Reports.MemorandumSummary.name:
        case Reports.DepartmentsNominations.name:
        case Reports.ConsolidatedStatement.name:
          const { year } = row;
          const months = getUniqArray(tableState.selectedRows.map(({ month }) => month));
          return buildPaymentRequestPeriodQuery({ periodKey: 'month', period: months, year });
        default:
      }
    }
  }, [currentReport?.name, row, tableState.selectedRows]);

  return (
    <>
      <SharedToolbar buttons={buttons} />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        authorRequestStatus="-1"
        requestStatus="-1"
        requestType={requestTypeOrdinal}
        userDepartment={userDepartments}
        paymentRequestPeriod={paymentRequestPeriod}
      />
    </>
  );
}

export { Toolbar };
