import React, { useCallback, useMemo, useState } from 'react';

import { buttonIcons, ConfirmPopup, IconButton, IconButtonProps, Toolbar as SharedToolbar } from 'components';

function Toolbar() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.toolbarHelp,
        title: 'Помощь',
        code: 'personEmail',
        isDisabled: false,
        onClick: handleHelpButtonClick,
      },
    ],
    [handleHelpButtonClick],
  );
  return (
    <>
      <ConfirmPopup
        title="Список  научных проектов  Университета"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">
              Раздел содержит открытые данные о выполненных и текущих научных проектах Университета
            </strong>
          </p>
          <p>
            Используйте строку поиска по словам в столбцах таблицы для отбора группы проектов по тематике, направлению
            исследований, проекты конкретного подразделения
          </p>
          <p>
            Список выбранных проектов вы можете выгрузить в файл с помощью кнопки:
            <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
          </p>
        </div>
      </ConfirmPopup>
      <SharedToolbar buttons={buttons} />
    </>
  );
}

export { Toolbar };
