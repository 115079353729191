import React from 'react';

import {
  FormComponent,
  Tabs,
  Tab,
  Reference,
  Checkbox,
  InputSelect,
  TextDateTime,
  Radio,
  SectionTitle,
  ListEdit,
  IconButton,
  TextArea,
  TextInput,
  TextInputMode,
} from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { Documents } from 'features/Documents';
import { GetFullScienceProjectList, GetJointEventFeedlineList, GetReferenceElementList } from 'features/Table/specifications';
import { showNotification } from 'features/Notifications';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { DataGrid } from 'features/Table';
import { useController } from './controller';
import { EnterpriseForm } from './EnterpriseForm';
import {
  getContactsInfo,
  getHistoryInfo,
  convertToEventMemberCountType,
  convertToEventAmountType,
  getAwardList,
} from './helpers';
import { CommitteMemberFields } from './CommitteMembersFields';
import { MemberCountComponent } from 'features/Form/looks/event/views/MemberCount';
import { formatMember } from 'features/Form/looks/event/EventFormContest/Tabs/Organization/helpers';
import { ParticipantFields } from './ParticipantFields';
import { formatAuthor } from 'features/Form/looks/participation/views/MembersList/helpers';
import { AwardFields } from './AwardFields';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function JointEventForm({ viewMode, editMode, onClose }: Props) {
  const {
    feedlineMessage,
    feedlineMessageType,
    calculateButton,
    departmentRowConverter,
    formatList,
    handleFeedlineMessageChange,
    handleFeedlineMessageTypeChange,
    handleAwardsChange,
    handleCommitteMembersChange,
    handleDepartmentsChange,
    handleDescriptionChange,
    handleDocumentsChange,
    handleEnterprisesChange,
    handleFormatChange,
    handleIsStudentChange,
    handleKeyWordsChange,
    handleLevelChange,
    handleMemberCountChange,
    handleMemberCountFactChange,
    handleNameChange,
    handleOrderDateChange,
    handleOrderNumberChange,
    handleParticipantChange,
    handleParticipantsChange,
    handleProjectAccept,
    handleProjectCancelAcceptance,
    handleProjectsChange,
    handleRangeDateChange,
    handleSiteChange,
    handleTypeChange,
    isFeedlineMessagePopupOpen,
    isOtherSelected,
    isTSUSelected,
    jointEvent,
    jointEventId,
    jointEventTypeOptions,
    onSubmit,
    participant,
    participantList,
    projectRowConverter,
    relatedTableState,
    handleSaveButtonClick,
    settings,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <>
      <FormComponent.Template>
        <ControlPanel
          handleSave={() => handleSaveButtonClick(true)}
          handleSaveAndContinue={() => handleSaveButtonClick(false)}
          viewMode={viewMode}
        />

        <FormComponent.Template>
          <Tabs>
            <Tab title="О мероприятии">
              <FormComponent.ColumnWrapper fitContent>
                <FormComponent.Column>
                  <FormComponent.Line>
                    <FormComponent.Field label="Название" isRequired>
                      <TextArea settings={{ rows: 3 }} value={jointEvent.name} onChange={handleNameChange} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Дата проведения" isRequired>
                      <TextDateTime
                        isRange
                        value={`${jointEvent.startDate}-${jointEvent.endDate}`}
                        onChange={(value: string) => {
                          const dates = value.split('-');
                          handleRangeDateChange(dates[0] || '', dates[1] || '');
                        }}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Field label="Уровень мероприятия" isRequired>
                    <Reference
                      disabled={viewMode}
                      name="RefEventStatus"
                      value={jointEvent.level}
                      onChange={handleLevelChange}
                      relationTableModalTitle='Справочник "Уровни мероприятия"'
                    />
                  </FormComponent.Field>
                  <FormComponent.Field label="Тип" isRequired>
                    <InputSelect
                      disabled={viewMode}
                      value={jointEvent.type}
                      options={jointEventTypeOptions}
                      onSelectChange={handleTypeChange}
                    />
                  </FormComponent.Field>
                  <FormComponent.Field label="Формат проведения" isRequired>
                    <Radio
                      isDisabled={viewMode}
                      value={jointEvent.format?.value}
                      list={formatList}
                      onChange={handleFormatChange}
                    />
                  </FormComponent.Field>
                  <FormComponent.Field label="Мероприятие для студентов">
                    <Checkbox checked={jointEvent.isStudent} onChange={handleIsStudentChange} />
                  </FormComponent.Field>
                  <FormComponent.Line>
                    <FormComponent.Field label="Приказ">
                      <TextInput value={jointEvent.orderNumber} onChange={handleOrderNumberChange} />
                    </FormComponent.Field>
                    <FormComponent.Field label="Дата приказа">
                      <TextDateTime value={jointEvent.orderDate} onChange={handleOrderDateChange} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Field label="Описание мероприятия">
                    <TextArea settings={{ rows: 3 }} value={jointEvent.description} onChange={handleDescriptionChange} />
                  </FormComponent.Field>
                  <FormComponent.Field label="Ключевые слова">
                    <TextArea settings={{ rows: 3 }} value={jointEvent.keyWords} onChange={handleKeyWordsChange} />
                  </FormComponent.Field>
                  <FormComponent.Field label="Публикация о мероприятии">
                    <TextInput mode={TextInputMode.url} value={jointEvent.site} onChange={handleSiteChange} />
                  </FormComponent.Field>
                  <SectionTitle title="Организовано при финансовой поддержке" />
                  <ListEdit
                    maxHeight="300px"
                    defaultRowsCount={3}
                    specification={{
                      mode: 'relationTableModal',
                      modalTableRowConverter: projectRowConverter,
                      relationTableModalTitle: 'Научные проекты',
                      modalTableSpecification: GetFullScienceProjectList({
                        templatesTableDependencies: relatedTableState && {
                          relatedTableAPIID: 'GetMagazineList',
                          relatedTableState,
                        },
                      }),
                    }}
                    rows={jointEvent.projects}
                    columns={[
                      { label: 'Номер проекта', formatValue: row => row.project?.number || '' },
                      { label: 'Название проекта', formatValue: row => row.project?.name || '' },
                      { label: 'Программа/Тип гранта', formatValue: row => row.project?.program || '' },
                      { label: 'Рук-ль, отв. исполнитель', formatValue: row => row.project?.leader || '' },
                      { label: 'Факультет/Институт', formatValue: row => row.project?.governances || '' },
                      { label: 'Финансирование', formatValue: row => row.project?.financings || '' },
                      {
                        label: 'Принят к отчету',
                        formatValue: row => !!row.accepted,
                      },
                    ]}
                    isToolbarDisabled={viewMode}
                    onChange={handleProjectsChange}
                    withAcceptButtons
                    onAccept={({ project }) => {
                      if (project?.id) handleProjectAccept(project.id);
                    }}
                    onAcceptanceCancel={({ project }) => {
                      if (project?.id) handleProjectCancelAcceptance(project.id);
                    }}
                    isDeleteConfirmEnabled
                    withMessages
                  />
                </FormComponent.Column>
                <FormComponent.Column>
                  <SectionTitle title="Организаторы мероприятия" />
                  <FormComponent.Field>
                    <Radio isDisabled={viewMode} value={participant} list={participantList} onChange={handleParticipantChange} />
                  </FormComponent.Field>
                  <>
                    {isTSUSelected && (
                      <FormComponent.Field>
                        <ListEdit
                          isRequired
                          maxHeight="300px"
                          title={`Подразделения ${settings?.organization?.shortName}`}
                          defaultRowsCount={isOtherSelected ? 5 : 12}
                          isToolbarDisabled={viewMode}
                          rows={jointEvent.departments}
                          withoutHead
                          columns={[{ label: '', formatValue: row => row.element?.label || '' }]}
                          onChange={handleDepartmentsChange}
                          specification={{
                            mode: 'relationTableModal',
                            modalTableRowConverter: departmentRowConverter,
                            relationTableModalTitle: `Справочник "Подразделения  ${settings?.organization?.shortName}"`,
                            modalTableSpecification: GetReferenceElementList({
                              requestData: {
                                filters: [],
                                name: 'RefDepartment',
                              },
                            }),
                          }}
                        />
                      </FormComponent.Field>
                    )}
                    {isOtherSelected && (
                      <FormComponent.Field>
                        <ListEdit
                          isToolbarDisabled={viewMode}
                          maxHeight="300px"
                          defaultRowsCount={isTSUSelected ? 5 : 12}
                          rows={jointEvent.enterprises}
                          isRequired
                          withoutHead
                          title="Внешние организации: организаторы и участники мероприятия"
                          onChange={handleEnterprisesChange}
                          columns={[{ label: '', formatValue: row => row.element?.label || '' }]}
                          specification={{
                            mode: 'customComponent',
                            validation: {
                              checkIsValid: row => !!row?.role?.value && !!row.element?.id,
                              onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                            },
                            renderComponent: (row, onChange) => <EnterpriseForm document={row} onChange={onChange} />,
                          }}
                          withMessages
                          isDeleteConfirmEnabled
                        />
                      </FormComponent.Field>
                    )}
                  </>
                  <SectionTitle title="Ответственные за мероприятие" />
                  <ListEdit
                    maxHeight="300px"
                    defaultRowsCount={3}
                    rows={jointEvent.committeMembers}
                    columns={[
                      { label: 'ФИО', formatValue: x => x.person?.fullName || '' },
                      { label: 'Сведения о работе/обучении', formatValue: x => getHistoryInfo(x) },
                      { label: 'Роль', formatValue: x => x.role?.label || '' },
                      { label: 'Контакты', formatValue: x => getContactsInfo(x) },
                    ]}
                    onChange={handleCommitteMembersChange}
                    isDeleteConfirmEnabled
                    isToolbarDisabled={viewMode}
                    withMessages
                    specification={{
                      mode: 'customComponent',
                      validation: {
                        checkIsValid: author => !!author?.person?.id && !!author?.role?.id,
                        onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                      },
                      renderComponent: (row, onChange) => <CommitteMemberFields committeMember={row} onChange={onChange} />,
                    }}
                  />
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </Tab>
            <Tab title="Участники">
              <FormComponent.ColumnWrapper fitContent>
                <FormComponent.Column>
                  <SectionTitle title="Показатели результативности" />
                  <FormComponent.Line hasFreeFormat hasGap>
                    <FormComponent.Field label="Всего участников мероприятия:" labelSize="fit" isRequired />
                    <FormComponent.Field label="ФАКТ" labelSize="fit">
                      <TextInput
                        mode={TextInputMode.number}
                        value={jointEvent.memberCountFact}
                        onChange={handleMemberCountFactChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                    <IconButton {...calculateButton} key={calculateButton.title} />
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <ListEdit
                      defaultRowsCount={6}
                      maxHeight="300px"
                      isToolbarDisabled={viewMode}
                      title="В том числе по категориям и организациям:"
                      rows={jointEvent.memberCounts.map(convertToEventMemberCountType)}
                      columns={[
                        {
                          label: 'Откуда',
                          styles: { width: '80%' },
                          formatValue: row => formatMember(row),
                        },
                        { label: 'Количество участников', styles: { width: '20%' }, formatValue: row => row.amount },
                      ]}
                      columnIndexesForSumTotal={[1]}
                      columnIndexesForIntegerTotal={[1]}
                      onChange={handleMemberCountChange}
                      specification={{
                        mode: 'customModalComponent',
                        modalTitle: 'Добавить количество участников по категории:',
                        onPreSubmit: (member, submit) => {
                          submit(member);
                        },
                        renderComponent: (member, onChange, index, mode) => (
                          <MemberCountComponent
                            mode={mode}
                            memberCounts={jointEvent.memberCounts.map(convertToEventMemberCountType)}
                            memberCountsAnother={(member?.amounts || []).map(convertToEventAmountType)}
                            disabled={viewMode}
                            documentMember={member}
                            onChangeMember={onChange as any}
                            hasShortForm
                          />
                        ),
                      }}
                    />
                  </FormComponent.Line>
                  <SectionTitle title="Награды участников" />
                  <ListEdit
                    maxHeight="300px"
                    defaultRowsCount={6}
                    rows={getAwardList(jointEvent.participants)}
                    isCanMovingRows
                    columns={[
                      {
                        label: 'Участник',
                        formatValue: x => x.person?.shortName || x.person?.fullName || '',
                      },
                      {
                        label: 'Категория награды',
                        formatValue: x => x.award.category?.label || '',
                      },
                      {
                        label: 'Награда',
                        formatValue: x => x.award.name,
                      },
                    ]}
                    onChange={handleAwardsChange}
                    isDeleteConfirmEnabled
                    isToolbarDisabled={viewMode}
                    withMessages
                    specification={{
                      mode: 'customComponent',
                      validation: {
                        checkIsValid: row => !!row?.person?.id && !!row.award.category?.value && !!row.award.name,
                        onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                      },
                      renderComponent: (row, onChange) => (
                        <AwardFields row={row} onChange={onChange} participants={jointEvent.participants} />
                      ),
                    }}
                  />
                </FormComponent.Column>
                <FormComponent.Column>
                  <SectionTitle title="Участники мероприятия" />
                  <ListEdit
                    maxHeight="400px"
                    defaultRowsCount={20}
                    rows={jointEvent.participants}
                    isCanMovingRows
                    withoutHead
                    columns={[
                      {
                        label: '',
                        formatValue: x =>
                          formatAuthor({
                            ...x,
                            category: null,
                            name: '',
                            affilateCount: 0,
                            isAffilated: false,
                            position: 0,
                            enterprise: null,
                            memberAffilations: [],
                          }),
                      },
                    ]}
                    onChange={handleParticipantsChange}
                    isDeleteConfirmEnabled
                    isToolbarDisabled={viewMode}
                    withMessages
                    specification={{
                      mode: 'customComponent',
                      validation: {
                        checkIsValid: row => !!row?.person?.id,
                        onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                      },
                      renderComponent: (row, onChange) => <ParticipantFields participant={row} onChange={onChange} />,
                    }}
                  />
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </Tab>
            <Tab title="Документы">
              <FormComponent.Wrapper>
                <Documents documents={jointEvent.documents} setDocuments={handleDocumentsChange} disabled={!!viewMode} />
              </FormComponent.Wrapper>
            </Tab>
            <Tab title="Журнал сообщений">
              {relatedTableState && (
                <DataGrid
                  specification={GetJointEventFeedlineList(
                    {
                      relatedTableAPIID: 'GetMagazineList',
                      relatedTableState,
                      relatedRecordId: jointEventId || undefined,
                    },
                    viewMode ? 'viewMode' : editMode ? 'editMode' : 'addMode',
                  )}
                />
              )}
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>

      <AdministratorMessagePopup
        title="Добавьте стандартное и/или текстовое сообщение администратору"
        isOpen={isFeedlineMessagePopupOpen}
        onConfirm={() => {
          onSubmit(true);
        }}
        publicationMessage={feedlineMessage}
        updatePublicationMessage={handleFeedlineMessageChange}
        publicationMessageType={feedlineMessageType}
        setPublicationMessageType={handleFeedlineMessageTypeChange}
        onConfirmWithoutMessage={() => {
          onSubmit(false);
        }}
        fieldVisibleMode="SYSTEM"
        command="RefJointEventFeedlineMessage"
      />
    </>
  );
}

export const Component = React.memo(JointEventForm);
