import { useState } from 'react';

function useController() {
  const [isOpenHistoryPersonModal, setIsOpenHistoryPersonModal] = useState(false);
  const [selectedAuthorIndex, setSelectedAuthorIndex] = useState<number | null>(null);

  return {
    isOpenHistoryPersonModal,
    selectedAuthorIndex,
    setSelectedAuthorIndex,
    setIsOpenHistoryPersonModal,
  };
}

export { useController };
