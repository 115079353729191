import React from 'react';

import { InputSelect } from 'components';

import { useController } from './controllerSuggestion';

type Props = {
  fieldName: string;
  limit: number;
  value: string;
  onChange(value: string): void;
  disabled?: boolean;
};

export function SuggestionInput(props: Props) {
  const { fieldName, limit, value, onChange, disabled } = props;
  const { onInputChange, onSelectChange, options, isLoading } = useController({ value, fieldName, limit, onChange });

  return (
    <InputSelect
      value={{ value, label: value }}
      options={options}
      onInputChange={onInputChange}
      isLoading={isLoading}
      onSelectChange={onSelectChange}
      disabled={disabled}
    />
  );
}
