import { useLayoutEffect, useState, useMemo } from 'react';

import { Form } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  onClose(): void;
};

export function useController({ onClose }: Props) {
  const {
    look: { id },
  } = useFormContext();
  const { userPermission } = useAppDataContext();

  const isHasEditFieldPermission = useMemo(() => isHasPermission(userPermission, Permits.PUBLICATION_LIBRARY_FIELD_EDIT), [
    userPermission,
  ]);
  const isHasEditDoiPermission = useMemo(() => isHasPermission(userPermission, Permits.PUBLICATION_DOI_EDIT), [userPermission]);
  const tableStreams = useLocalTableStreams();

  const [magazineName, setMagazineName] = useState<string>('');
  const [formFields, setFormFields] = useState<Form.Fields>({
    libraryId: {
      value: '',
      isValid: true,
      title: 'Идентификатор журнала в библиотеке',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          libraryId: { ...prevState.libraryId, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          libraryId: { ...prevState.libraryId, isValid: true },
        }));
      },
    },
    doi: {
      value: '',
      isValid: true,
      title: 'DOI',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, isValid: true },
        }));
      },
    },
    libraryLink: {
      value: '',
      isValid: true,
      title: 'Поле связи для статей',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          libraryLink: { ...prevState.libraryLink, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          libraryLink: { ...prevState.libraryLink, isValid: true },
        }));
      },
    },
  });

  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: editPublicationField } = BackendAPI.useBackendAPI('EditPublicationField');

  useLayoutEffect(() => {
    if (id) {
      getPublication.callAPI(
        {
          simpleFields: {
            status: '',
            typeEdition: '',
          },
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          attrIdFields: {},
          id,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              doi: {
                ...prevState.doi,
                value: data.fields.doi,
              },
              libraryId: {
                ...prevState.libraryId,
                value: data.status.libraryId,
              },
              libraryLink: {
                ...prevState.libraryLink,
                value: data.fields.libraryLink,
              },
            }));
            setMagazineName(data.fields.name);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const saveForm = () => {
    if (!id) {
      showNotification({ theme: 'danger', message: 'Произошла ошибка при загрузке' });
      return;
    }
    editPublicationField.callAPI(
      {
        id,
        parameters: [
          {
            name: 'DOI',
            value: formFields.doi.value,
          },
          {
            name: 'LIBRARY_ID',
            value: formFields.libraryId.value,
          },
          {
            name: 'LIBRARY_LINK',
            value: formFields.libraryLink.value,
          },
        ],
      },
      {
        onSuccessfullCall: ({ data }) => {
          if (data.success) {
            onClose();
            tableStreams.reloadTable.push();
          }
          showNotification({
            theme: data.success ? 'success' : 'danger',
            message: data.success ? 'Идентификаторы журнала успешно сохранены' : 'Произошла ошибка при сохранении',
          });
        },
        onFailedCall: () => {},
      },
    );
  };

  return { magazineName, formFields, saveForm, isHasEditFieldPermission, isHasEditDoiPermission };
}
