import { useCallback, useMemo, useState } from 'react';

import { buttonIcons, IconButtonProps } from 'components';

import { showNotification } from 'features/Notifications';
import { Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { getLinksMap } from 'utils/Helpers';

type Props = {
  tableState: Table.State;
};
export function useController({ tableState }: Props) {
  const { settings } = useAppDataContext();
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });
    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const isSelectedRowsLength = !tableState.selectedRows.length;
  const buttons = useMemo<IconButtonProps[]>(
    () => [
      {
        icons: buttonIcons.loop,
        title: 'Просмотр',
        code: 'view',
        isDisabled: isSelectedRowsLength,
        onClick: handleViewButtonClick,
      },
      {
        icons: buttonIcons.link,
        title: 'Открыть публикацию во внешнем источнике',
        code: 'openPublicationOuterSource',
        isDisabled: isSelectedRowsLength,
        onClick: () => {},
        getExpandedList: getLinks,
      },
    ],
    [getLinks, handleViewButtonClick, isSelectedRowsLength],
  );
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  return {
    buttons,
    isViewFormOpen,
    handleTemplateCloseViewForm,
  };
}
