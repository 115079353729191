import React from 'react';
import { FormComponent, TextGridSelect } from 'components';
import { GetPartnerList } from 'features/Table/specifications';
import { State } from 'features/Table/specifications/GetPartnerList/makeUseCustomController';
import { Affiliation } from 'types/models';
import { tableRowConverter } from './helpers';

type Props = {
  affiliation: Affiliation | null;
  setAffiliation: (affiliation: Affiliation | null) => void;
  isDisabled: boolean;
  label: string;
  modalTitle: string;
  tooltip?: string;
};

export const AffiliationSelect = ({ affiliation, setAffiliation, isDisabled, label, modalTitle, tooltip }: Props) => (
  <FormComponent.Field tooltip={tooltip} label={label}>
    <TextGridSelect<Affiliation, State>
      value={affiliation}
      onChange={setAffiliation}
      disabled={isDisabled}
      specification={GetPartnerList({ withMenu: false, hasSelectButton: true })}
      settings={{
        mode: 'selectTableRow',
        tableRowConverter,
      }}
      valueFormatter={x =>
        x.enterprise?.label || x.enterpriseCustomer?.label || x.partner?.shortName || x.partner?.fullName || ''
      }
      title={modalTitle}
    />
  </FormComponent.Field>
);
