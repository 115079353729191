import React from 'react';

import { ConfirmPopup, Toolbar } from 'components';
import { Table, Community } from 'types/models';

import * as modalTemplates from './modalTemplates';
import { useController } from './controller';

import { State, UsedNames } from '../makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';

export type CustomProps = {
  communityTypes: Community.CommunityType[];
  usedNames: UsedNames;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { communityTypes, usedNames, isMembersTabHidden } = customState;

  const { settings } = useAppDataContext();

  const {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    isAddFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    isViewButtonDisabled,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    handleCloseApproveConfirmPopup,
    handleConfirmApproveConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    isApproved,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isDissertationCouncil,
  } = useController(tableState, communityTypes);

  return (
    <>
      {!isViewButtonDisabled && (
        <modalTemplates.CommunityViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.Code}
          relatedTableState={tableState}
          templateName={templateName}
          communityTypes={communityTypes}
          usedNames={usedNames}
          isApproved={isApproved}
          isMembersTabHidden={isMembersTabHidden}
        />
      )}
      {!isEditButtonDisabled && (
        <modalTemplates.CommunityEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.Code}
          relatedTableState={tableState}
          templateName={templateName}
          communityTypes={communityTypes}
          usedNames={usedNames}
          isApproved={isApproved}
          isMembersTabHidden={isMembersTabHidden}
        />
      )}
      <modalTemplates.CommunityAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
        templateName={templateName}
        communityTypes={communityTypes}
        usedNames={usedNames}
        isMembersTabHidden={isMembersTabHidden}
      />
      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранное сообщество? (${tableState.selectedRows[0].label})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {!isApproveButtonDisabled && tableState.selectedRows.length > 0 && (
        <ConfirmPopup
          title={isApproved ? 'Перевод в статус "Добавлено"' : 'Утверждение'}
          text={
            isApproved
              ? // eslint-disable-next-line max-len
                `Вы действительно хотите перевести в статус "Добавлена" ${tableState.selectedRows[0].label}?`
              : `Вы действительно хотите утвердить ${tableState.selectedRows[0].label}?`
          }
          isOpen={isApproveConfirmPopupOpen}
          onClose={handleCloseApproveConfirmPopup}
          onConfirm={handleConfirmApproveConfirmPopup}
          okButtonText="Да"
        />
      )}
      <ConfirmPopup
        title={`Помощь в ${isDissertationCouncil ? 'Диссертационных советах' : 'Научных сообществах и организациях'}`}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {isDissertationCouncil ? (
          <div className="modal-help">
            <p>
              Раздел предназначен для учёта общих сведений о диссертационных советах {settings?.organization?.shortName}, а также
              сторонних организаций, в работе которых принимают участие сотрудники университета.
            </p>
          </div>
        ) : (
          <div className="modal-help">
            <p>
              Раздел содержит общие сведения о научных сообществах: коллаборациях, научно-технических советах, редакциях научных
              журналов {settings?.organization?.shortName}, а также сторонних организаций, в работе которых принимают участие
              сотрудники университета.
            </p>
            <p>
              <strong className="primary">ВАЖНО:</strong> Сведения о научно-техническом совете университета и членах совета
              используются при оформлении АКТа приёмки научно-исследовательской работы в научных проектах.
            </p>
          </div>
        )}
      </ConfirmPopup>
      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
