import { useState, useCallback } from 'react';
import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isHandleErrorButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isHandleErrorFormOpen, setIsHandleErrorFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleHandleErrorButtonClick = useCallback(() => {
    setIsHandleErrorFormOpen(true);
  }, []);

  const handleTemplateCloseHandleErrorForm = useCallback(() => {
    setIsHandleErrorFormOpen(false);
  }, []);

  return {
    isViewButtonDisabled,
    isHandleErrorButtonDisabled,
    handleViewButtonClick,
    handleHandleErrorButtonClick,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isHandleErrorFormOpen,
    handleTemplateCloseHandleErrorForm,
  };
};

export default useController;
