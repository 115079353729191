import { CalendarPlanByStage, CalendarPlanByYear, ProjectCode, Project } from 'types/models/Project';

export function getMockProjectCodeCalendarPlansByYears(project: Project | null): CalendarPlanByYear[] | null {
  if (project) {
    return project.financingsByYear.map(fin => ({
      year: fin.year,
      amount: 0,
      factAmount: 0,
      ndsPercent: 0,
      ndsAmount: 0,
      accompliceAmount: 0,
      hasEstimate: false,
      isInProjectStages: true,
    }));
  }
  return null;
}
export function getMockProjectCodeCalendarPlansByStages(project: Project | null): CalendarPlanByStage[] | null {
  if (project) {
    return project.stages.map(stage => ({
      stage,
      amount: 0,
      ndsPercent: 0,
      ndsAmount: 0,
    }));
  }
  return null;
}
export function getProjectCodeMock(project?: Project | null): ProjectCode {
  return {
    accompliceAmount: '',
    calendarPlansByStages: [],
    calendarPlansByYears: [],
    calendarPlansTotalAmount: 0,
    code: '',
    codeDepartmentPart: '',
    codeManualPart: '',
    codeYearPart: '',
    departments: [],
    documents: [],
    expenseFactAmount: '',
    expensePlanAmount: '',
    financeProvide: undefined,
    financing: undefined,
    fundCard: undefined,
    incomeFactAmount: '',
    incomePlanAmount: '',
    isActive: false,
    isFundCardUsed: false,
    isOutsideBudget: false,
    isRefersToEconomicAgreement: false,
    letter: undefined,
    overheadFactAmount: '',
    overheadPercent: '',
    project,
    receivingDocuments: [],
    responsiblePerformer: undefined,
    year: '',
  };
}
