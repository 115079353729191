import React, { memo } from 'react';

import { buttonIcons, ConfirmPopup, IconButton, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import * as modalTemplates from './modalTemplates';

type WrapperProps = {
  communitiesFilter: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ communitiesFilter }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isViewButtonDisabled,
      isViewFormOpen,
      handleTemplateCloseViewForm,
      isEditButtonDisabled,
      isEditFormOpen,
      handleTemplateCloseEditForm,
      isAddFormOpen,
      handleTemplateCloseAddForm,
      nextPublicationName,
      isDeleteConfirmPopupOpen,
      isDeleteButtonDisabled,
      handleCloseDeleteConfirmPopup,
      handleConfirmDeleteConfirmPopup,
      buttons,
      member,
      errorStatus,
      handleCloseErrorModal,
      isErrorModal,
      handleTemplateCloseHelpForm,
      isHelpFormOpen,
      templateName,
      communityTypes,
      organizationName,
    } = useController({ tableState, communitiesFilter });
    return (
      <>
        {!isViewButtonDisabled && (
          <modalTemplates.CommunityViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
          />
        )}
        {!isEditButtonDisabled && (
          <modalTemplates.CommunityEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
            memberEditLocked={true}
            communityTypes={communityTypes}
          />
        )}
        <modalTemplates.CommunityAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          member={member}
          templateName={templateName}
          memberEditLocked={true}
          communityTypes={communityTypes}
        />

        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <ConfirmPopup
            title="Предупреждение"
            text={`Вы точно хотите удалить выбранное участие?`}
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            onConfirm={handleConfirmDeleteConfirmPopup}
            okButtonText="Да"
          />
        )}
        <ConfirmPopup
          title={errorStatus.type}
          text={errorStatus.text}
          isOpen={isErrorModal}
          resetButtonText="Закрыть"
          onClose={handleCloseErrorModal}
          icon="warning"
        />
        <ConfirmPopup
          title="Помощь в разделе Работа в составе диссертационных советов"
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          <div className="modal-help">
            <p>
              <strong className="primary">Раздел Работа в составе диссертационных советов</strong> предназначен для ввода и
              хранения информации об участии и работе в составе диссоветов.
            </p>
            <ul>
              <li>
                <strong>Фильтр «Мои диссоветы»</strong> содержит список диссертационных советов, в составе которых вы работали
              </li>
              <li>
                <strong>Фильтр «Все диссоветы»</strong> содержит список участников всех диссертационных советов {organizationName}
                &nbsp;и других организаций
              </li>
            </ul>
            <p>
              Информацию о работе диссертационного совета Университета и составе его участников в систему заносят ответственные
              лица. Если вы не видите запись о своем участии в работе диссовета или являетесь членом диссертационного совета в
              другом университете, внесите об этом информацию в данном разделе Личного кабинета.
            </p>
            <p>
              <strong className="primary">В разделе Работа в составе диссертационных советов вы можете:</strong>
            </p>
            <ul>
              <li>
                Посмотреть запись о работе с помощью кнопки <IconButton icons={buttonIcons.loop} hasNoHover />
              </li>
              <li>
                Добавить новую запись о работе в диссовете с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover />
              </li>
              <li>
                Редактировать запись о работе в составе диссовета с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.edit} hasNoHover />
              </li>
              <li>
                Удалить запись, которую вы создавали, с помощью кнопки <IconButton icons={buttonIcons.delete} hasNoHover />
              </li>
              <li>
                Скачать прикрепленный файл диссовета с помощью кнопки <IconButton icons={buttonIcons.download} hasNoHover />
              </li>
              <li>
                Выгрузить список ваших работ в составах диссоветов в файл Excel с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
              </li>
              <li>
                Задать вопрос или написать сообщение администратору в отдельном окне с помощью кнопки&nbsp;
                <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
              </li>
            </ul>
            <p>
              При сохранении записи присваивается статус <strong>Добавлена</strong>. Добавленную запись можно редактировать,
              вносить информацию или удалить.
            </p>
            <p>
              Добавленную вами запись о работе в составе диссовета проверит и переведет в статус <strong>Утверждена</strong>
              &nbsp;Администратор данных. Утвержденная запись недоступна для внесения изменений. Если вы нашли ошибку в
              утвержденной записи - напишите письмо Администратору.
            </p>
          </div>
        </ConfirmPopup>

        <Toolbar buttons={buttons} />
      </>
    );
  });
