import { FormComponent } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { RefList } from 'features/Form/looks/project/ProjectForm/views';
import { DomainKnowledgeList } from './DomainKnowlegeClassifiers/DomainKnowlegeClassifiers';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
};

export function Classifiers(props: Props) {
  const { project, makeChangeHandler, disabled } = props;
  const { settings } = useAppDataContext();
  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Line>
          <RefList
            modalTitle='Справочник "Области научных интересов"'
            refName="RefScienceDomainInterest"
            rows={project?.scienceDomainInterrests ?? []}
            onChange={makeChangeHandler('scienceDomainInterrests')}
            title="Области научных интересов"
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            modalTitle='Справочник "ПНР"'
            refName="RefPnr"
            rows={project?.pnrs ?? []}
            onChange={makeChangeHandler('pnrs')}
            title={`Приоритетные направления развития ${settings?.organization?.shortName}`}
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            modalTitle='Справочник "ПНР модернизации РФ"'
            refName="RefPnmitr"
            rows={project?.pnmitrs ?? []}
            onChange={makeChangeHandler('pnmitrs')}
            title="Приоритетные направления развития модернизации и технического развития экономики России"
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <DomainKnowledgeList
            rows={project?.domainKnowledges ?? []}
            onChange={makeChangeHandler('domainKnowledges')}
            programId={project?.program?.id || ''}
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Line>
          <RefList
            title="Сквозные технологии Национальной технологической инициативы"
            modalTitle='Справочник "Сквозные технологии Национальной технологической инициативы"'
            refName="RefNtiEteTechnology"
            rows={project?.ntiEteTechnologies ?? []}
            onChange={makeChangeHandler('ntiEteTechnologies')}
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            title="Рынки Национальной технологической инициативы"
            modalTitle='Справочник "Рынки Национальной технологической инициативы"'
            refName="RefNtiMarket"
            rows={project?.ntiMarkets ?? []}
            onChange={makeChangeHandler('ntiMarkets')}
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            title="Технологические платформы"
            modalTitle='Справочник "Технологические платформы"'
            refName="RefTechnologyPlatform"
            rows={project?.techPlatforms ?? []}
            onChange={makeChangeHandler('techPlatforms')}
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            title="Номенклатура научных специальностей, по которым присуждаются ученые степени"
            modalTitle='Справочник "Номенклатура научных специальностей, по которым присуждаются ученые степени"'
            refName="RefVakSpeciality"
            rows={project?.vaks ?? []}
            onChange={makeChangeHandler('vaks')}
            disabled={disabled}
            isCanMovingRows
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}
