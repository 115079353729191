import { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { IconButtonProps, buttonIcons } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRow = useMemo(() => tableState.selectedRows[0], [tableState.selectedRows]);
  const isMagazineSelected = useMemo(() => selectedRow && selectedRow['id:Type'] === PublicationTypes.MAGAZINE.code, [
    selectedRow,
  ]);

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const { methods: editPublicationField } = BackendAPI.useBackendAPI('EditPublicationField');
  const tableStreams = useLocalTableStreams();

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isConfirmPopupDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleConfirmPopupClick = useCallback(() => {
    setIsConfirmPopupOpen(true);
  }, []);

  const resetDelete = useCallback(() => {
    setIsConfirmPopupOpen(false);
  }, []);
  const deleteLibraryId = useCallback(() => {
    editPublicationField.callAPI(
      {
        id: selectedRow?.id || '',
        parameters: [
          {
            name: 'LIBRARY_ID',
            value: '',
          },
        ],
      },
      {
        onSuccessfullCall: ({ data }) => {
          if (data.success) {
            tableStreams.reloadTable.push();
          }
          showNotification({
            theme: data.success ? 'success' : 'danger',
            message: data.success ? 'Элемент успешно удален' : 'Произошла ошибка при удалении',
          });
        },
        onFailedCall: () => {},
      },
    );
    // eslint-disable-next-line
  }, [selectedRow]);

  const confirmDelete = useCallback(() => {
    setIsConfirmPopupOpen(false);
    deleteLibraryId();
  }, [deleteLibraryId]);

  const buttons: IconButtonProps[] = useMemo(
    () => [
      {
        icons: buttonIcons.edit,
        title: 'Редактировать ID НБ',
        code: 'edit',
        isDisabled: isEditButtonDisabled,
        onClick: handleEditButtonClick,
        permissionName: isMagazineSelected ? Permits.MAGAZINE_LIBRARY_FIELD_EDIT : Permits.PUBLICATION_LIBRARY_FIELD_EDIT,
      },
      {
        icons: buttonIcons.delete,
        title: 'Удалить ID НБ',
        code: 'remove',
        isDisabled: isConfirmPopupDisabled,
        onClick: handleConfirmPopupClick,
        permissionName: isMagazineSelected ? Permits.MAGAZINE_LIBRARY_FIELD_EDIT : Permits.PUBLICATION_LIBRARY_FIELD_EDIT,
      },
    ],
    [isEditButtonDisabled, isConfirmPopupDisabled, handleEditButtonClick, handleConfirmPopupClick, isMagazineSelected],
  );

  return {
    isEditButtonDisabled,
    isConfirmPopupDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isConfirmPopupOpen,
    buttons,
    resetDelete,
    confirmDelete,
  };
};

export default useController;
