import { Form, Document, Author } from 'types/models';
import { Notification } from 'features/Notifications';
import { ParticipationEventSource, ParticipationProject } from 'types/models/Participation';
import * as validator from 'utils/Validators';
import { getIsDateInPeriod } from 'utils/Validators/getIsDateInPeriod';
import { getIsEqualDates } from 'utils/Validators/getIsEqualDates';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

// key: value key shoul be equal formfield key,
// value is validator function (val) => boolean
const specialValidators = {
  file: validator.isFileValid,
};

const getError = (formFields: Form.Fields) => {
  const nextNotification: Pick<Notification, 'message' | 'theme'> = { message: '', theme: 'danger' };
  let isFormValid = true;

  const invalidFieldTitles: string[] = [];
  const incorrectFieldTitles: string[] = [];
  const outOfMaxRangeFieldTitles: string[] = [];
  const outOfSymbolsRangeFieldTitles: string[] = [];
  const invalidFieldKeys: string[] = [];

  const keysToValidate = Object.keys(formFields);

  keysToValidate.forEach((key: string) => {
    const isFieldRequired = formFields[key].required;

    let isFieldValid = true;
    let isFieldCorrect = true;
    let isFieldInValueRange = true;
    let isFieldInSymbolsRange = true;

    const isFieldObject = formFields[key].value instanceof Object;
    const isFieldString = typeof formFields[key].value === 'string';

    const isFieldKeyMatchSpecialValidator = Object.keys(specialValidators).some(
      specialValidatorKey => key === specialValidatorKey,
    );

    if (isFieldKeyMatchSpecialValidator) {
      isFieldValid = specialValidators[key as keyof typeof specialValidators](formFields[key]);
    } else if (isFieldObject && isFieldRequired) {
      isFieldValid = validator.isReferenceValid(formFields[key]);
    } else if (isFieldString) {
      if (formFields[key].value === '') {
        isFieldValid = validator.isStringFieldValid(formFields[key]);
      } else {
        isFieldCorrect = validator.isStringFieldWithValidationTypeValid(formFields[key]);
      }
      if (isFieldValid && isFieldCorrect) {
        const fieldHasMaxSymbols = !!formFields[key].maxSymbols;
        const fieldHasMaxValue = !!formFields[key].maxValue;

        if (fieldHasMaxSymbols) {
          isFieldInSymbolsRange = validator.isOutOfMaxSymbolsRange(formFields[key]);
        } else if (fieldHasMaxValue) {
          isFieldInValueRange = validator.isOutOfMaxValueRange(formFields[key]);
        }
      }
    } else if (isFieldRequired) {
      isFieldValid = !!formFields[key].value;
    }

    if (!isFieldValid || !isFieldCorrect || !isFieldInSymbolsRange || !isFieldInValueRange) {
      isFormValid = false;
      invalidFieldKeys.push(key);
      if (formFields[key].title) {
        if (!isFieldValid) {
          invalidFieldTitles.push(formFields[key].title);
        } else if (!isFieldCorrect) {
          incorrectFieldTitles.push(formFields[key].title);
        } else if (!isFieldInSymbolsRange) {
          outOfSymbolsRangeFieldTitles.push(formFields[key].title);
        } else if (!isFieldInValueRange) {
          outOfMaxRangeFieldTitles.push(formFields[key].title);
        }
      }
    }
  });

  const invalidMessage = invalidFieldTitles.length
    ? `Заполните ${invalidFieldTitles.length > 1 ? 'обязательные поля' : 'обязательное поле'}: ${invalidFieldTitles
        .map(title => `"${title}"`)
        .join(', ')}.`
    : '';

  const incorrectMessage = incorrectFieldTitles.length
    ? `${
        incorrectFieldTitles.length > 1 ? 'Поля заполнены некорректно' : 'Поле заполненно некорректно'
      }: ${incorrectFieldTitles.map(title => `"${title}"`).join(', ')}.`
    : '';

  const outOfSymbolsRangeMessage = outOfSymbolsRangeFieldTitles.length
    ? `${
        outOfSymbolsRangeFieldTitles.length > 1
          ? 'Превышено максимальное количество символов у полей'
          : 'Превышено максимальное количество символов у поля'
      }: ${outOfSymbolsRangeFieldTitles.map(title => `"${title}"`).join(', ')}.`
    : '';
  const outOfMaxRangeMessage = outOfMaxRangeFieldTitles.length
    ? `${
        outOfMaxRangeFieldTitles.length > 1 ? 'Превышено максимальное значение у полей' : 'Превышено максимальное значение у поля'
      }: ${outOfMaxRangeFieldTitles.map(title => `"${title}"`).join(', ')}.`
    : '';

  if (invalidMessage || incorrectMessage || outOfSymbolsRangeMessage || outOfMaxRangeMessage) {
    const messages = [invalidMessage, incorrectMessage, outOfSymbolsRangeMessage, outOfMaxRangeMessage];

    const preparedMessage = messages.filter(message => message !== '').join('<br/>');
    nextNotification.message = preparedMessage;
    nextNotification.theme = 'danger';
  }

  return { nextNotification, invalidFieldKeys, isFormValid };
};

export const validateReportDate = ({ reportDate, event }: { reportDate: string; event: ParticipationEventSource }): Result => {
  const isSingleDateEvent = !!event.startDate && !event.endDate;
  const isPeriodDateEvent = !!event.startDate && !!event.endDate;
  const result: Result = { isFormValid: true, nextNotification: { message: '', theme: 'danger' }, invalidFieldKeys: [] };

  if (isSingleDateEvent) {
    const isEqualDates = getIsEqualDates({ dateString: reportDate, dateStringToCompare: event.startDate });

    result.isFormValid = isEqualDates;
    if (!isEqualDates) {
      result.nextNotification = { theme: 'danger', message: 'Дата доклада должна быть такой же, как в мероприятии' };
      result.isFormValid = false;
      result.invalidFieldKeys = ['reportDate'];
    }
  } else if (isPeriodDateEvent) {
    const isDateInPeriod = getIsDateInPeriod({
      dateString: reportDate,
      dateStringPeriod: { from: event.startDate, to: event.endDate },
    });

    if (!isDateInPeriod) {
      result.nextNotification = {
        theme: 'danger',
        message: `Дата доклада не должна выходить за сроки проекта от ${event.startDate} до ${event.endDate}`,
      };
      result.invalidFieldKeys = ['reportDate'];
      result.isFormValid = false;
    }
  }

  return result;
};

export function validate({
  formFields,
  event,
  members,
  isConferenceWithReportParticipation,
}: {
  formFields: Form.Fields;
  event: ParticipationEventSource | null;
  members: Author[];
  isConferenceWithReportParticipation: boolean;
}) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };

  const defaultResult = getError(formFields);

  result.nextNotification = defaultResult.nextNotification;
  result.isFormValid = defaultResult.isFormValid;
  result.invalidFieldKeys = defaultResult.invalidFieldKeys;

  if (!event) {
    result.nextNotification = { theme: 'danger', message: 'Поле конференции обязательно для заполнения' };
    result.isFormValid = false;
  }

  if (
    isConferenceWithReportParticipation &&
    formFields.isReportPublished.value === 'true' &&
    !formFields.publicationReportTheme.value
  ) {
    result.nextNotification = { theme: 'danger', message: 'Поле "Тема доклада" обязательно для заполнения' };
    result.isFormValid = false;
  }

  if (isConferenceWithReportParticipation && formFields.isReportPublished.value === 'false' && !formFields.reportTheme.value) {
    result.nextNotification = { theme: 'danger', message: 'Поле "Тема доклада" обязательно для заполнения' };
    result.isFormValid = false;
  }

  if (!members.length) {
    result.nextNotification = { theme: 'danger', message: 'В участии должен быть указан хотя бы один участник' };
    result.isFormValid = false;
  }

  if (!!event && !!formFields.reportDate.value) {
    const reportDateValidateResult = validateReportDate({ reportDate: formFields.reportDate.value, event });
    if (!reportDateValidateResult.isFormValid) {
      result.isFormValid = reportDateValidateResult.isFormValid;
      result.nextNotification = reportDateValidateResult.nextNotification;
      result.invalidFieldKeys.push(...reportDateValidateResult.invalidFieldKeys);
    }
  }

  return result;
}

export function validateReport({ formFields, members }: { formFields: Form.Fields; members: Author[] }) {
  const isSomeMemberReporter = members.some(({ isReporter }) => isReporter);
  if (formFields.isReportPublished.value === 'true' && formFields.publicationReportTheme.value) {
    const isValidReportPart = isSomeMemberReporter && formFields.reportType.value && formFields.reportDate.value;

    return isValidReportPart;
  }

  return true;
}

export function validateDocuments({ documents }: { documents: Document[] }) {
  const isValidDocuemtns = !!documents.length;

  return isValidDocuemtns;
}

export function validateProjectReport({
  financingProjects,
  formFields,
  members,
}: {
  formFields: Form.Fields;
  financingProjects: ParticipationProject[];
  members: Author[];
}) {
  const isSomeMemberReporter = members.some(({ isReporter }) => isReporter);
  const isProject = Boolean(financingProjects.length);

  if (isProject) {
    const isValidReportPart = isSomeMemberReporter && formFields.reportType.value?.id && formFields.reportDate.value;

    return isValidReportPart;
  }

  return true;
}
