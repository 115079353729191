import React from 'react';

import { Project } from 'types/models';

import { Partners as PartnerFields } from './Partners/Partners';

export type Props = {
  disabled: boolean;
  partners: Project.Partner[];
  setPartners(partners: Project.Partner[]): void;
};

export function Partners(props: Props) {
  const { disabled, partners, setPartners } = props;

  return <PartnerFields disabled={disabled} partners={partners} setPartners={setPartners} />;
}
