import { useCallback, useMemo, Dispatch, SetStateAction } from 'react';

import { Form, Community } from 'types/models';

import { Member } from 'types/models/Community';
import { getEnum, getPersonHistory } from 'utils/Helpers';
import { Item } from 'types/models/common';
import { getRoleOptionsByCommunityType } from 'features/Form/looks/community/CommunityForm/helpers';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  setPublicationInfo: Dispatch<SetStateAction<Form.Publication | null>> | null;
  publicationInfo: Form.Publication | null;
};

export function useController(props: Props) {
  const { publicationInfo, setPublicationInfo } = props;
  const formatPersonWithHistory = useCallback((m: Member) => {
    const personHistory = getPersonHistory({ person: m.scientist });
    return [m.scientist?.fullName, personHistory.rank?.refRank.label, personHistory.degree?.refDegree?.label]
      .filter(e => e)
      .join(', ');
  }, []);

  const handleMembersFieldChange = useCallback(
    (e: Community.Member[]) => {
      if (publicationInfo && setPublicationInfo) {
        setPublicationInfo({ ...publicationInfo, communityMembers: e });
      }
    },
    [publicationInfo, setPublicationInfo],
  );

  const { enumMap } = useAppDataContext();

  const allRoleOptions = useMemo(() => {
    return getEnum('CommunityMemberRole', enumMap);
  }, [enumMap]);

  const roleOptions = useMemo<Item[]>(() => {
    return getRoleOptionsByCommunityType(allRoleOptions, 'MAGAZINE_REDACTION');
  }, [allRoleOptions]);

  return {
    formatPersonWithHistory,
    handleMembersFieldChange,
    roleOptions,
  };
}
