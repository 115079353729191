import { CommissionMember, ProtocolCommission } from 'types/models/ProtocolCommission';
import { getMockPersonHistory } from 'features/Form/looks/mobileRequest/MobileRequestForm/helpers';
import { EMPTY_FILE } from 'utils/Constants';

export function getMockProtocolCommission(): ProtocolCommission {
  return {
    id: null,
    name: null,
    orderNumber: null,
    orderDate: null,
    note: null,
    members: [],
  };
}
export function getMockCommissionMember(): CommissionMember {
  return {
    ...getMockPersonHistory(),
    person: getMockCommissionMemberPerson(),
    id: '',
    role: null,
    priority: 1,
    protocolName: null,
  };
}
export function getMockCommissionMemberPerson() {
  return {
    fullName: '',
    shortName: '',
    id: '',
    bibliographicNames: [],
    scientist: null,
    scientistJobs: [],
    scientistEducations: [],
    degrees: [],
    ranks: [],
    academicRanks: [],
    identifiers: [],
    hirshIndexes: [],
    grntis: [],
    udks: [],
    oecds: [],
    domainKnowledges: [],
    vakSpecialities: [],
    communityMembers: [],
    changes: [],
    activities: '',
    scientificResults: '',
    keywordsRus: '',
    keywordsEnglish: '',
    affiliations: [],
    calculations: null,
    avatar: EMPTY_FILE,
  };
}
