import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { SecurityDocumentPaymentRequestSaveInput } from './types';
import { convertSecurityDocumentPaymentRequestToXML } from './requestConverters';

const configurations = {
  SaveSecurityDocumentPaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveSecurityDocumentPaymentRequest',
    endpoint: '/msa/service/commands/SavePatentPaymentRequest',
    convertInputToXMLElement: (inputData: SecurityDocumentPaymentRequestSaveInput) => ({
      Request: {
        _attr: {
          commandName: 'SavePatentPaymentRequest',
        },
        PaymentRequest: { ...convertSecurityDocumentPaymentRequestToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
};

export const securityDocumentPaymentRequestConfigurations = { ...configurations };
