import React, { useMemo } from 'react';
import { block } from 'bem-cn';

import { ConfirmPopup, IconButton, Toolbar, buttonIcons } from 'components';

import { Table } from 'types/models';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import EventParticipationTemplate from './modalTemplates/EventParticipationTemplate';
import eventTypes from './eventTypes';
import * as modalTemplates from './modalTemplates';

type Props = {
  tableState: Table.State;
  customState: State;
};

const b = block('toolbar');
function LeftPanelForThirdLevel({ tableState, customState: { eventType: gridEventType } }: Props) {
  const actualType = useMemo(
    () =>
      eventTypes.filter(event => event.code === gridEventType).length
        ? eventTypes.filter(event => event.code === gridEventType)[0]
        : eventTypes[0],
    [gridEventType],
  );
  const {
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    eventTypeCode,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    event,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    organizationName,
  } = useController({ tableState, actualType });

  return (
    <>
      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={eventTypeCode}
          />
        </div>
      )}
      {isViewFormOpen && (
        <modalTemplates.EventViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isEditFormOpen && (
        <modalTemplates.EventEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isAddFormOpen && (
        <modalTemplates.EventAddTemplate.Component
          eventTypeCode={eventTypeCode}
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          controllerIsForm={controllerIsForm}
        />
      )}
      {isDeleteConfirmPopupOpen && (
        <ConfirmPopup
          title="Предупреждение"
          text={`Вы точно хотите удалить выбранную конференцию? (${tableState.selectedRows[0]?.EventName})`}
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          onConfirm={handleConfirmDeleteConfirmPopup}
          okButtonText="Да"
        />
      )}
      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          event={event}
        />
      )}
      {gridEventType && <EventParticipationTemplate controllerIsForm={controllerIsForm} eventTypeName={gridEventType} />}
      <ConfirmPopup
        title="Помощь в работе с конференциями"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        <div className="modal-help">
          <p>
            <strong className="primary">Раздел Конференции</strong> предназначен для поддержки процессов
            организационно-технического и финансового сопровождения научных мероприятий университета: формирования Сводного плана
            научных мероприятий университета на год и отчетности по результатам проведения.
          </p>
          <p>
            <strong className="primary">В разделе Конференции вы можете:</strong>
          </p>
          <ul>
            <li>
              Просмотреть запись о конференции и информацию на всех вкладках с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.loop} hasNoHover />
            </li>
            <li>
              Добавить новую конференцию с помощью кнопки <IconButton icons={buttonIcons.plus} hasNoHover />
            </li>
            <li>
              Редактировать конференцию в статусе Добавлена с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.edit} hasNoHover />
            </li>
            <li>
              Удалить запись о конференции, которую вы создавали, если она в статусе Добавлена с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.delete} hasNoHover />
            </li>
            <li>
              Выгрузить список конференций в файл Excel с помощью кнопки&nbsp;
              <IconButton icons={buttonIcons.XMLDocument} hasNoHover />
            </li>
            <li>
              Распечатать выбранную конференцию в готовом шаблоне отчета для печати&nbsp;
              <IconButton icons={buttonIcons.print} hasNoHover />
              <ul>
                <li>Заявка в сводный план на проведение конференции</li>
                <li>Заявка подразделения в сводный план мероприятий университета</li>
                <li>Сводный перечень научных мероприятий</li>
                <li>Отчет по конференции</li>
              </ul>
            </li>
          </ul>
          <p>
            <strong className="primary">Важно</strong>
          </p>
          <p>
            При сохранении конференции присваивается статус <strong>Добавлена.</strong>
          </p>
          <p>
            На вкладке «Документы» обязательно прикрепите подтверждающий документ. Ответственное лицо проверит и утвердит
            конференцию.
          </p>
          <p>
            Если университет является организатором или соорганизатором мероприятия, то все вкладки на форме обязательны к
            заполнению. Подробнее в Инструкции по конференциям университета в разделе Справочные материалы и ссылки.
          </p>
          <p>
            Для добавления участия в конференции, организованной другой организацией , необходимо внести эту конференцию, заполнив
            обязательные поля на вкладке «О мероприятии» и прикрепить Программу или информационное письмо на вкладке «Документы».
          </p>
          <ul>
            <li>
              <strong>Фильтр «Мои конференции»</strong> содержит список конференций, в которых вы указаны в составе Оргкомитета.
              Если вы создавали запись о конференции, но не являетесь участником оргкомитета, конференция будет отображаться в
              другом разделе.
            </li>
            <li>
              <strong>Фильтр «Конференции подразделения»</strong> содержит список конференций, связанных с вашим подразделением.
            </li>
            <li>
              <strong>Фильтр «Конференции {organizationName}»</strong> содержит список конференций, в которых организатором или
              соорганизатором является {organizationName}.
            </li>
            <li>
              <strong>Фильтр «Другие конференции»</strong> показывает список конференций других организаций, в которых принимают
              участие сотрудники, аспиранты и студенты {organizationName}.
            </li>
            <li>
              <strong>Фильтр «Показывать конференции за год»</strong> формирует список конференций, сроки проведения которых
              содержат указанный год. По умолчанию выбран текущий год.
            </li>
          </ul>
          <p>Добавленное вами мероприятие проверит и утвердит Администратор данных.</p>
          <p>
            Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору&nbsp;
            <IconButton icons={buttonIcons.toolbarQuestion} hasNoHover />
          </p>
          <p>Полное руководство по работе с конференциями вы можете скачать в разделе Справочные материалы и ссылки.</p>
        </div>
      </ConfirmPopup>

      <Toolbar buttons={buttons} />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);
