import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { format, parse, subMonths } from 'date-fns';

import * as formats from 'utils/Constants/FormatStr';
import { formatToOut, parseDate, parseTime, prepareTime } from './helpers';
import { Props as ComponentProps } from './index';
import { defaultTime, DEFAULT_HOURS, DEFAULT_MINUTES } from './Timepicker/helpers';
import { RangeTime, Time } from './Timepicker/types';
import { RANGE_SEPARATOR } from './mask/helpers';

export enum Tabs {
  DATE = 'DATE',
  TIME = 'TIME',
}

type Props = {
  isWithDate: boolean;
  isWithTime: boolean;
} & ComponentProps;

const useController = ({ isRange, value, isWithDate, isWithTime, onChange, mode }: Props) => {
  const [inputDateValue, setInputDateValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Date | undefined>(undefined);
  const [timeValue, setTime] = useState<Time>({ ...defaultTime });
  const [rangeDateValue, setRangeDateValue] = useState<DateRange>({ from: undefined, to: undefined });
  const [rangeTimeValue, setRangeTimeValue] = useState<RangeTime>({ from: { ...defaultTime }, to: { ...defaultTime } });

  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.DATE);

  const dateFilterRef = useRef<HTMLDivElement | null>(null);

  const formatString = useMemo(() => {
    if (isWithDate && isWithTime) {
      return formats.formatDateTimeStr;
    } else if (isWithDate) {
      return formats.formatStr;
    }
    return formats.formatTimeStr;
  }, [isWithDate, isWithTime]);

  const placeholder = useMemo(() => formats.formatToMock(formatString), [formatString]);

  const defaultMonth = <DateRange>useMemo(() => {
    return {
      from: rangeDateValue.from ?? subMonths(rangeDateValue.to ?? new Date(), 1),
      to: rangeDateValue.to ?? new Date(),
    };
  }, [rangeDateValue.from, rangeDateValue.to]);

  useEffect(() => {
    if (isRange) {
      const [from = '', to = ''] = (value || '').split(RANGE_SEPARATOR);

      const [parsedDateFrom, parsedDateTo] = [from, to].map(i => (i === '' ? undefined : parse(i, formatString, new Date())));

      const fromHour = parsedDateFrom?.getHours();
      const fromMinute = parsedDateFrom?.getMinutes();

      const toHour = parsedDateTo?.getHours();
      const toMinute = parsedDateTo?.getMinutes();

      const isInfiniteToDate = parsedDateTo && !parsedDateFrom;
      const isSingleDateRange = parsedDateFrom && parsedDateTo && parsedDateFrom.getTime() === parsedDateTo.getTime();
      const isDateToInfinite = parsedDateFrom && !parsedDateTo;

      if (isInfiniteToDate) {
        setRangeDateValue({ from: parsedDateTo, to: undefined });
        setRangeTimeValue({
          to: {
            hour: prepareTime(parsedDateTo.getHours()) ?? DEFAULT_HOURS,
            minute: prepareTime(parsedDateTo.getMinutes()) ?? DEFAULT_MINUTES,
          },
          from: { hour: DEFAULT_HOURS, minute: DEFAULT_MINUTES },
        });
      } else if (isSingleDateRange) {
        setRangeDateValue({ from: parsedDateFrom, to: parsedDateTo });
        setRangeTimeValue({
          to: {
            hour: prepareTime(parsedDateTo.getHours()) ?? DEFAULT_HOURS,
            minute: prepareTime(parsedDateTo.getMinutes()) ?? DEFAULT_MINUTES,
          },
          from: {
            hour: prepareTime(parsedDateFrom.getHours()) ?? DEFAULT_HOURS,
            minute: prepareTime(parsedDateFrom.getMinutes()) ?? DEFAULT_MINUTES,
          },
        });
      } else if (isDateToInfinite) {
        setRangeDateValue({ from: parsedDateFrom, to: undefined });
        setRangeTimeValue({
          from: {
            hour: prepareTime(parsedDateFrom.getHours()) ?? DEFAULT_HOURS,
            minute: prepareTime(parsedDateFrom.getMinutes()) ?? DEFAULT_MINUTES,
          },
          to: { hour: DEFAULT_HOURS, minute: DEFAULT_MINUTES },
        });
      } else {
        setRangeDateValue({ from: parsedDateFrom, to: parsedDateTo });
        setRangeTimeValue({
          to: {
            hour: prepareTime(parsedDateTo?.getHours()) ?? DEFAULT_HOURS,
            minute: prepareTime(parsedDateTo?.getMinutes()) ?? DEFAULT_MINUTES,
          },
          from: {
            hour: prepareTime(parsedDateFrom?.getHours()) ?? DEFAULT_HOURS,
            minute: prepareTime(parsedDateFrom?.getMinutes()) ?? DEFAULT_MINUTES,
          },
        });
      }

      setRangeTimeValue({
        from: {
          hour: prepareTime(fromHour) || DEFAULT_HOURS,
          minute: prepareTime(fromMinute) || DEFAULT_MINUTES,
        },
        to: {
          hour: prepareTime(toHour) || DEFAULT_HOURS,
          minute: prepareTime(toMinute) || DEFAULT_MINUTES,
        },
      });

      setInputDateValue(`${parsedDateFrom ? from : placeholder}-${parsedDateTo ? to : placeholder}`);
    } else {
      const preparedValue = (value || '').slice(0, formatString.length);
      const parsedDate = parse(preparedValue, formatString, new Date());
      const hour = parsedDate?.getHours();
      const minute = parsedDate?.getMinutes();

      setTime({ hour: prepareTime(hour) || DEFAULT_HOURS, minute: prepareTime(minute) || DEFAULT_MINUTES });

      setDateValue(parsedDate);
      setInputDateValue(preparedValue);
    }
  }, [formatString, isRange, placeholder, value]);

  useEffect(() => {
    setCurrentTab(isWithDate ? Tabs.DATE : Tabs.TIME);
  }, [isWithDate]);

  const openCalendar = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeCalendar = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onInputChange = useCallback(
    (nextValue: string) => {
      if (isRange) {
        const [from = '', to = ''] = nextValue.split(RANGE_SEPARATOR).map(x => x.trim());

        const fromDate = parse(from, formatString, new Date());
        const toDate = parse(to, formatString, new Date());

        const isFromValid = !Number.isNaN(fromDate.getFullYear());
        const isToValid = !Number.isNaN(toDate.getFullYear());
        const isFromEmpty = from === placeholder;
        const isToEmpty = to === placeholder;

        if (isFromValid || isToValid) {
          if (isFromValid && !isToValid && isToEmpty) {
            setRangeDateValue({ from: fromDate, to: undefined });
            setRangeTimeValue({
              from: {
                hour: prepareTime(fromDate.getHours()) ?? DEFAULT_HOURS,
                minute: prepareTime(fromDate.getMinutes()) ?? DEFAULT_MINUTES,
              },
              to: {
                hour: DEFAULT_HOURS,
                minute: DEFAULT_MINUTES,
              },
            });
            onChange(formatToOut({ from, to: undefined, type: 'range', isWithTime }));
          } else if (isToValid && !isFromValid && isFromEmpty) {
            setRangeDateValue({ from: toDate, to: undefined });
            setRangeTimeValue({
              from: {
                hour: DEFAULT_HOURS,
                minute: DEFAULT_MINUTES,
              },
              to: {
                hour: prepareTime(toDate.getHours()) ?? DEFAULT_HOURS,
                minute: prepareTime(toDate.getMinutes()) ?? DEFAULT_MINUTES,
              },
            });
            onChange(formatToOut({ from: undefined, to, type: 'range', isWithTime }));
          } else if (isFromValid && isToValid && !isFromEmpty && !isToEmpty) {
            setRangeDateValue({ from: fromDate, to: toDate });
            setRangeTimeValue({
              from: {
                hour: prepareTime(fromDate.getHours()) ?? DEFAULT_HOURS,
                minute: prepareTime(fromDate.getMinutes()) ?? DEFAULT_MINUTES,
              },
              to: {
                hour: prepareTime(toDate.getHours()) ?? DEFAULT_HOURS,
                minute: prepareTime(toDate.getMinutes()) ?? DEFAULT_MINUTES,
              },
            });
            onChange(formatToOut({ from, to, type: 'range', isWithTime }));
          }
        } else if (nextValue === `${placeholder}-${placeholder}` && nextValue !== inputDateValue) {
          setRangeDateValue({ from: undefined, to: undefined });
          onChange(formatToOut({ from: undefined, to: undefined, type: 'range', isWithTime }));
        }
      } else {
        const date = parse(nextValue, formatString, new Date());
        const isValidYear = !Number.isNaN(date.getFullYear());
        const isEmpty = nextValue === placeholder;
        if (isValidYear) {
          setDateValue(date);
          setTime({
            hour: prepareTime(date?.getHours()) ?? DEFAULT_HOURS,
            minute: prepareTime(date?.getMinutes()) ?? DEFAULT_MINUTES,
          });
          onChange(formatToOut({ value: nextValue, type: 'single', isWithTime }));
        } else if (isEmpty && nextValue !== inputDateValue) {
          setTime({
            hour: DEFAULT_HOURS,
            minute: DEFAULT_MINUTES,
          });
          onChange(formatToOut({ value: '', type: 'single', isWithTime }));
        }
      }
    },
    [isRange, formatString, placeholder, inputDateValue, onChange, isWithTime],
  );

  const onDateChange = useCallback((date: Date | undefined) => {
    setDateValue(date);
  }, []);

  const updateTimeValue = useCallback(
    (key: keyof Time) => (nextValue?: string) => {
      setTime(prevTime => ({ ...prevTime, [key]: nextValue }));
    },
    [],
  );

  const updateRangeTimeValue = useCallback(
    (rangeKey: keyof RangeTime, timeKey: keyof Time) => (nextValue?: string) => {
      setRangeTimeValue(prevRangeTimeValue => ({
        ...prevRangeTimeValue,
        [rangeKey]: { ...prevRangeTimeValue[rangeKey], [timeKey]: nextValue },
      }));
    },
    [],
  );

  const onMultipleDateChange = useCallback((dateRange?: DateRange) => {
    const { from, to } = dateRange || { from: undefined, to: undefined };
    setRangeDateValue({ from, to });
  }, []);

  const isInputValueEmpty = useMemo(() => {
    const emptyValue = `${placeholder}${isRange ? `-${placeholder}` : ''}`;

    const isInputEmpty = !inputDateValue || inputDateValue === emptyValue;
    return isInputEmpty;
  }, [inputDateValue, isRange, placeholder]);

  const reset = useCallback(() => {
    setInputDateValue('');
    setDateValue(undefined);
    setRangeTimeValue({ from: { ...defaultTime }, to: { ...defaultTime } });
    setTime({ ...defaultTime });
    setRangeDateValue({ from: undefined, to: undefined });

    onChange('');
  }, [onChange]);

  const canAcceptRange = useMemo(
    () =>
      !isWithDate || (rangeDateValue.from && rangeDateValue.to && rangeDateValue.from.getTime() !== rangeDateValue.to.getTime()),
    [isWithDate, rangeDateValue.from, rangeDateValue.to],
  );

  const canAcceptSingleRange = useMemo(
    () =>
      (rangeDateValue.from && !rangeDateValue.to) ||
      (rangeDateValue.from && rangeDateValue.to && rangeDateValue.from.getTime() === rangeDateValue.to.getTime()),
    [rangeDateValue.from, rangeDateValue.to],
  );

  const canAcceptFromDate = useMemo(() => rangeDateValue.from && !rangeDateValue.to, [rangeDateValue.from, rangeDateValue.to]);
  const canAcceptToDate = useMemo(() => rangeDateValue.from && !rangeDateValue.to, [rangeDateValue.from, rangeDateValue.to]);

  const onSubmit = useCallback(
    (date: Date | undefined, needClose: boolean = true) => {
      if (!date) {
        closeCalendar();
        return;
      }
      setDateValue(date);
      const preparedDateValue = parseDate(date);
      preparedDateValue.setMinutes(parseTime(timeValue.minute));

      const formattedDateValue = format(preparedDateValue, formatString);
      if (needClose) {
        closeCalendar();
      }
      onChange(formatToOut({ value: formattedDateValue, type: 'single', isWithTime }));
    },
    [closeCalendar, formatString, isWithTime, onChange, timeValue.minute],
  );

  const submitRange = useCallback(() => {
    const preparedDateFromValue = parseDate(rangeDateValue.from);
    const preparedDateToValue = parseDate(rangeDateValue.to);

    if (!isWithDate || (rangeDateValue.from && rangeDateValue.to)) {
      preparedDateFromValue.setHours(parseTime(rangeTimeValue.from.hour));
      preparedDateFromValue.setMinutes(parseTime(rangeTimeValue.from.minute));
      const formattedDateFrom = format(preparedDateFromValue, formatString);

      preparedDateToValue.setHours(parseTime(rangeTimeValue.to.hour));
      preparedDateToValue.setMinutes(parseTime(rangeTimeValue.to.minute));
      const formattedDateTo = format(preparedDateToValue, formatString);

      onChange(formatToOut({ from: formattedDateFrom, to: formattedDateTo, type: 'range', isWithTime }));
      closeCalendar();
    }
  }, [rangeDateValue, rangeTimeValue, isWithDate, formatString, onChange, isWithTime, closeCalendar]);

  const submitFromDateRange = useCallback(() => {
    if (rangeDateValue.from) {
      const preparedDateFromValue = new Date(rangeDateValue.from);
      preparedDateFromValue.setHours(parseTime(rangeTimeValue.from.hour));
      preparedDateFromValue.setMinutes(parseTime(rangeTimeValue.from.minute));
      const formattedDateFrom = format(preparedDateFromValue, formatString);

      onChange(formatToOut({ from: formattedDateFrom, to: '', type: 'range', isWithTime }));
      closeCalendar();
    }
  }, [
    rangeDateValue.from,
    rangeTimeValue.from.hour,
    rangeTimeValue.from.minute,
    formatString,
    onChange,
    isWithTime,
    closeCalendar,
  ]);

  const submitToDateRange = useCallback(() => {
    if (rangeDateValue.from) {
      const preparedDateFromValue = new Date(rangeDateValue.from);
      preparedDateFromValue.setHours(parseTime(rangeTimeValue.from.hour));
      preparedDateFromValue.setMinutes(parseTime(rangeTimeValue.from.minute));
      const formattedDateFrom = format(preparedDateFromValue, formatString);

      onChange(formatToOut({ from: '', to: formattedDateFrom, type: 'range', isWithTime }));
      closeCalendar();
    }
  }, [rangeDateValue, rangeTimeValue.from.hour, rangeTimeValue.from.minute, formatString, onChange, isWithTime, closeCalendar]);

  const submitSingleRange = useCallback(() => {
    if (rangeDateValue.from) {
      const preparedDateFromValue = new Date(rangeDateValue.from);
      const preparedDateToValue = new Date(rangeDateValue.from);

      preparedDateFromValue.setHours(parseTime(rangeTimeValue.from.hour));
      preparedDateFromValue.setMinutes(parseTime(rangeTimeValue.from.minute));
      const formattedDateFrom = format(preparedDateFromValue, formatString);

      preparedDateToValue.setHours(parseTime(rangeTimeValue.to.hour));
      preparedDateToValue.setMinutes(parseTime(rangeTimeValue.to.minute));
      const formattedDateTo = format(preparedDateToValue, formatString);

      onChange(formatToOut({ from: formattedDateFrom, to: formattedDateTo, type: 'range', isWithTime }));
      closeCalendar();
    }
  }, [
    rangeDateValue.from,
    rangeTimeValue.from.hour,
    rangeTimeValue.from.minute,
    rangeTimeValue.to.hour,
    rangeTimeValue.to.minute,
    formatString,
    onChange,
    isWithTime,
    closeCalendar,
  ]);

  useLayoutEffect(() => {
    if (isRange && !!mode) {
      onMultipleDateChange(defaultMonth);
    }
  }, [defaultMonth, isRange, mode, onMultipleDateChange]);

  return {
    isOpen,
    dateValue,
    timeValue,
    currentTab,
    defaultMonth,
    dateFilterRef,
    rangeDateValue,
    rangeTimeValue,
    inputDateValue,
    canAcceptRange,
    canAcceptToDate,
    isInputValueEmpty,
    canAcceptFromDate,
    canAcceptSingleRange,
    reset,
    onSubmit,
    submitRange,
    submitFromDateRange,
    submitToDateRange,
    submitSingleRange,
    openCalendar,
    onDateChange,
    closeCalendar,
    onInputChange,
    setCurrentTab,
    updateTimeValue,
    onMultipleDateChange,
    updateRangeTimeValue,
  };
};

export default useController;
