import { useEffect } from 'react';

import { Table } from 'types/models';

export type State = {
  setFundCardId?(fundCardId: string): void;
};

type Props = {
  setFundCardId?(fundCardId: string): void;
};

export function makeUseCustomController({ setFundCardId }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    useEffect(() => {
      if (selectedRows.length) setFundCardId?.(selectedRows[0].id);
    }, [selectedRows]);
    return {
      setFundCardId,
    };
  };
}
